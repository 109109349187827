import { Center } from '@chakra-ui/react';
import React from 'react';

function NoDataPlaceholder({ small, data }: { small: Boolean, data?: String }) {
  const wrapperTextStyles = {
    height: small ? '40px' : '295px',
    width: '100%',
    color: 'brand.neutral.700',
  };
  return <Center {...wrapperTextStyles}>No {data} available</Center>;
}

NoDataPlaceholder.defaultProps = {
  data: 'data',
};

export default NoDataPlaceholder;
