import React from 'react';
import { useFormikContext } from 'formik';
import { Icon16ChevronRight, Icon16ChevronLeft } from '@icons/16px';
import { BaselaneButton, TwoFactorVerificationPopUp } from '@shared/components';
import useOtpVerification from '@hooks/useOtpVerification';
import useBankSummary from '@hooks/useBankSummary';
import useTransfersAccountList from '@hooks/useTransfersAccountList';
import { useSetCurrentStep, useIsCurrentStepLoading } from '../../sendFundsStore';
import { steps } from '../../helpers/formHelpers';

const ReviewPaymentFooter = () => {
  const setCurrentStep = useSetCurrentStep();
  const formik = useFormikContext();

  const { submitForm, values } = formik;
  const isCurrentStepLoading = useIsCurrentStepLoading();
  const handleGoBack = () => {
    setCurrentStep(steps.BOOKKEPPING_DETAILS);
  };
  // For OTP
  const { banksById } = useBankSummary();

  const { transferAccountsByBankAccountId } = useTransfersAccountList();

  const bankId = transferAccountsByBankAccountId[values?.fromTransferAccountId]?.group?.id;
  const { props, isTokenExpired, handleOtpPopupOpen } = useOtpVerification({
    bankId,
    phoneNumber: banksById[bankId]?.unitAccount?.phoneNumber,
    callback: submitForm,
  });

  return (
    <>
      <BaselaneButton
        size="md"
        variant="outline"
        palette="neutral"
        onClick={handleGoBack}
        leftIcon={<Icon16ChevronLeft />}
      >
        Back
      </BaselaneButton>
      <BaselaneButton
        size="md"
        variant="filled"
        palette="primary"
        width="100%"
        ml={1.5}
        flex={1}
        onClick={() => {
          if (isTokenExpired) {
            handleOtpPopupOpen();
          } else {
            submitForm();
          }
        }}
        rightIcon={<Icon16ChevronRight />}
        isLoading={isCurrentStepLoading}
      >
        Save and continue
      </BaselaneButton>
      <TwoFactorVerificationPopUp {...props} />
    </>
  );
};
export default ReviewPaymentFooter;
