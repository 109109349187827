export const updateFileNamePopupStyles = {
  error: {
    container: {
      alignItems: 'center',
    },
    msg: {
      textStyle: 'xs',
      color: 'red.800AA',
    },
  },
  footer: ({ isMinXL }) => {
    return {
      container: {
        w: isMinXL && '100%',
      },
      button: {
        w: isMinXL && '50%',
      },
    };
  },
};
