import React from 'react';
import { Stack } from '@chakra-ui/react';
import { BaselaneAccordionWrapper, BaselaneAccordion } from '@shared/components';
import IconCarretDown from '@icons/legacy/IconCarretDown';
import IconCarretRight from '@icons/legacy/IconCarretRight';
import AccordionHeader from './AccordionHeader';
import AccordionContent from './AccordionContent';
import { failedPaymentAccordionStyles } from '../styles/failedPaymentAccordion.styles';

type FailedPaymentAccordionProps = {
  failedPaymentsData: Array<Object>,
  tenantData: Object,
  propertiesData: Array<Object>,
};

function FailedPaymentAccordion({
  failedPaymentsData,
  tenantData,
  propertiesData,
}: FailedPaymentAccordionProps) {
  const { accordionWrapper, accordion } = failedPaymentAccordionStyles ?? {};
  const {
    containerStyles,
    buttonStyles,
    contentStyles,
    expandedContentStyles,
    iconContainerStyles,
  } = accordion;

  return (
    <BaselaneAccordionWrapper
      allowToggle
      allowMultiple
      defaultIndex={[-1]}
      styles={accordionWrapper}
    >
      <Stack gap="12px">
        {failedPaymentsData.map((payment) => {
          const { id, numberOfData } = payment ?? {};
          return (
            numberOfData > 0 && (
              <BaselaneAccordion
                {...{
                  key: id,
                  id,
                  containerStyles,
                  buttonStyles,
                  contentStyles,
                  expandedContentStyles,
                  iconContainerStyles,
                }}
                headerElements={<AccordionHeader {...{ payment }} />}
                contentElements={<AccordionContent {...{ payment, tenantData, propertiesData }} />}
                IconDown={IconCarretRight}
                IconUp={IconCarretDown}
              />
            )
          );
        })}
      </Stack>
    </BaselaneAccordionWrapper>
  );
}

export default FailedPaymentAccordion;
