// @flow
import React, { forwardRef, useState } from 'react';
import { merge } from 'lodash';
import { LocationDescriptor, useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import './styles/button.style.scss';

import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import {
  buttonThemeGenerator,
  getIconSpacingValue,
  getNegativeMargin,
} from '@core/theme/component/Button/buttonStyleHelpers';
import BaselaneConditionalTooltip from '../BaselaneConditionalTooltip';

type BaselaneButtonProps = {
  pullLeft?: boolean,
  pullRight?: boolean,
  palette: string,
  variant: string,
  styles: Object,
  size?: string,
  buttonType: string,
  leftIcon: any,
  rightIcon: any,
  isDisabled: boolean,
  children: any,
  onChange: Function,
  onClick: Function,
  onBlur: Function,
  to?: LocationDescriptor,
  isLoading?: boolean,
  segmentEventName?: string,
  segmentEventData?: any,
  tooltipText?: string,
  isFullWidth?: boolean,
};

const BaselaneButton: any = forwardRef(
  (
    {
      pullLeft,
      pullRight,
      palette,
      styles,
      variant,
      size,
      leftIcon,
      rightIcon,
      isDisabled,
      children,
      onChange,
      onClick,
      onBlur,
      buttonType,
      isLoading,
      to,
      segmentEventName,
      segmentEventData,
      tooltipText,
      isFullWidth,
      ...rest
    }: BaselaneButtonProps,
    ref: any
  ) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const hasTooltip = tooltipText && tooltipText?.length;

    const sendIfSegmentEvent = () => {
      if (segmentEventName !== null) {
        if (!segmentEventData) {
          sendSegmentEvent(segmentEventName);
        } else {
          sendSegmentEvent(segmentEventName, segmentEventData);
        }
      }
    };

    // NOTE: If a "to" LocationDescriptor is provided, the button will generate an onClick
    // that navigates with the react Router.
    const navigate = useNavigate();

    const clickHandler = to
      ? () => {
          if (!isDisabled) {
            sendIfSegmentEvent();
            navigate(to);
          } else if (hasTooltip) {
            setIsTooltipOpen((prev) => !prev);
          }
        }
      : (e) => {
          if (!isDisabled) {
            sendIfSegmentEvent();
            onClick?.(e);
          } else if (hasTooltip) {
            setIsTooltipOpen((prev) => !prev);
          }
        };

    const blurHandler = (e) => {
      if (!isDisabled) {
        onBlur?.(e);
      } else if (hasTooltip) {
        setIsTooltipOpen(false);
      }
    };

    const buttonThemeStyles = merge(
      buttonThemeGenerator(variant, palette),
      pullLeft && getNegativeMargin(size, 'left'),
      pullRight && getNegativeMargin(size, 'right'),
      { width: isFullWidth ? '100%' : 'initial' }
    );

    // uses standard disabled html attribute when no tooltip,
    // if tooltip provided, uses aria-disabled
    const disabled = {
      ...(hasTooltip ? { [`aria-disabled`]: isDisabled } : { isDisabled }),
    };

    return (
      // NOTE: Refer to theme.js on options for variant and size props
      <BaselaneConditionalTooltip
        condition={tooltipText}
        tooltipText={tooltipText}
        isOpen={isTooltipOpen}
      >
        <Button
          {...{
            leftIcon,
            size,
            rightIcon,
            ...disabled,
            ref,
            onChange,
            onClick: clickHandler,
            onBlur: blurHandler,
            isLoading,
            variant,
            type: buttonType,
          }}
          {...getIconSpacingValue(size)}
          {...buttonThemeStyles}
          {...styles}
          {...rest}
        >
          {children}
        </Button>
      </BaselaneConditionalTooltip>
    );
  }
);

BaselaneButton.defaultProps = {
  isLoading: false,
  to: false,
  size: 'sm',
  segmentEventName: null,
  segmentEventData: null,
  pullLeft: false,
  pullRight: false,
  tooltipText: null,
  isFullWidth: false,
};

export default BaselaneButton;
