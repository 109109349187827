import React from 'react';
import { Outlet } from 'react-router-dom';
import { TransactionProvider } from '@contexts/TransactionContext';

/**
 * Note: This wrapper allows the provider to be used as a route element.
 */

const TransactionWrapper = () => {
  return (
    <TransactionProvider>
      <Outlet />
    </TransactionProvider>
  );
};

export default TransactionWrapper;
