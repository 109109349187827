import { isMobile } from 'react-device-detect';

export const containerPaddingStyles = {
  px: isMobile ? '8px' : '0',
};

export const imageStyles = {
  objectFit: 'cover',
  width: '100%',
  height: 'auto',
};
