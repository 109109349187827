const actionbtn = {
  size: 'sm',
  styles: { m: '0 !important', w: 'fit-content', minW: 'fit-content' },
};

export const baselaneAlertStyles = ({ variant, actionType, isVertical, isTextVertical }) => {
  return {
    container: {
      flex: 1,
      gap: 1.5,
      alignItems: isVertical || isTextVertical ? 'flex-start' : 'center',
    },
    innercontainer: {
      w: '100%',
      ml: 0,
      gap: actionType === 'link' ? 0.5 : 1,
      flexDir: isVertical ? 'column' : 'row',
      alignItems: isVertical ? 'flex-start' : 'center',
    },
    textcontainer: {
      gap: isTextVertical ? 0.5 : 1,
      flexDir: isTextVertical ? 'column' : 'row',
      flexWrap: 'wrap',
    },
    illustration: {
      w: '32px',
      h: '32px',
      color: 'initial',
      m: '0 !important',
    },
    iconcontainer: {
      w: '16px',
      h: '20px',
      justifyContent: 'center',
      gap: 0,
    },
    icon: {
      w: '16px',
      h: '16px',
      color: 'currentColor',
      m: '0 !important',
    },
    linkbtn: {
      size: 'md',
      variant: 'link',
      palette: variant,
      styles: {
        m: '0 !important',
        w: 'fit-content',
        minW: 'fit-content',
        // Delete below once link button component style is fixed
        lineHeight: '20px',
        fontWeight: 'normal',
        textDecoration: 'underline',
      },
    },
    actionbtnprimary: {
      ...actionbtn,
      variant: 'filled',
      palette: 'primary',
    },
    actionbtnsecondary: {
      ...actionbtn,
      variant: 'outline',
      palette: 'neutral',
    },
    closebutton: {
      size: 'xs',
      variant: 'transparent',
      styles: { color: 'inherit', ml: '12px !important' },
      palette: variant,
    },
  };
};
