import React from 'react';
import { AlertHeader, BaselaneAlert } from '@shared/components';
import { unsavedDetailsAlertFooter } from '../../../helpers/invoiceDetails.helper';

type InvoiceDetailsAlertsProp = {
  saveAlert: Object,
  deleteAlert: Object,
  unsavedChangesAlert: Object,
  markAsPaidAlert: Object,
};

const InvoiceDetailsAlerts = ({
  saveAlert,
  deleteAlert,
  unsavedChangesAlert,
  markAsPaidAlert,
}: InvoiceDetailsAlertsProp) => {
  return (
    <>
      <BaselaneAlert
        size="2xl"
        isOpen={saveAlert.isSaveAlertOpen}
        leastDestructiveRef={saveAlert.cancelSaveAlertRef}
        onClose={saveAlert.onSaveAlertClose}
        isCentered
        header={saveAlert.saveAlertHeader}
        body={saveAlert.saveAlertBodyText}
        footer={saveAlert.saveAlertFooter}
      />

      <BaselaneAlert
        size="2xl"
        isOpen={deleteAlert.isDeleteAlertOpen}
        leastDestructiveRef={deleteAlert.cancelDeleteAlertRef}
        onClose={deleteAlert.onDeleteAlertClose}
        isCentered
        header={deleteAlert.deleteAlertHeader}
        body={deleteAlert.deleteAlertBodyText}
        footer={deleteAlert.deleteAlertFooter}
      />

      <BaselaneAlert
        isOpen={unsavedChangesAlert.isUnsavedChangesAlertOpen}
        leastDestructiveRef={unsavedChangesAlert.cancelAlertRef}
        onClose={unsavedChangesAlert.onUnsavedChangesAlertClose}
        isCentered
        header={<AlertHeader title="You Have Unsaved Changes" />}
        body="Are you sure you want to exit without saving?"
        footer={unsavedDetailsAlertFooter(
          unsavedChangesAlert.cancelAlertRef,
          unsavedChangesAlert.onUnsavedChangesAlertClose,
          unsavedChangesAlert.handleContinueButtonClick
        )}
      />
      <BaselaneAlert
        size="2xl"
        isOpen={markAsPaidAlert.isMarkAsPaidAlertOpen}
        leastDestructiveRef={markAsPaidAlert.cancelMarkAsPaidAlertRef}
        onClose={markAsPaidAlert.onMarkAsPaidClose}
        isCentered
        header={markAsPaidAlert.markAsPaidAlertHeader}
        body={markAsPaidAlert.markAsPaidAlertBodyText}
        footer={markAsPaidAlert.markAsPaidAlertFooter}
      />
    </>
  );
};

export default InvoiceDetailsAlerts;
