import T1WithTitle from '../components/T1-Title';
import T1 from '../components/T1';
import T2 from '../components/T2';
import T2WithTitle from '../components/T2-Title';
import DatePicker from '../components/DatePicker';
import RangePicker from '../components/Rangepicker';

export const DROPDOWN_TYPES = Object.freeze({
  tier1: T1,
  'tier1-title': T1WithTitle,
  date: DatePicker,
  tier2: T2,
  'tier2-title': T2WithTitle,
  range: RangePicker,
});
