/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Stack, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import { BaselaneButton, BaselaneDrawer, BaselaneSimpleProgressBar } from '@shared/components';
import BaselaneDisclaimer from '@core/components/NativeBankingPage/components/BaselaneDisclaimer';
import { disclaimerText } from '@core/components/NativeBankingPage/components/BaselaneDisclaimer/const/disclaimerText';
import getBreakPoints from '@core/utils/getBreakPoints';
import { IconChevronRight, IconChevronLeft } from '@icons';
import TransactionContext from '@contexts/TransactionContext';
import UserContext from '@contexts/UserContext';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';
import UnsavedChangesAlert from '../UnsavedChangesAlert';
import BankTransfer from '../../../BankTransfer';
import ReviewTransfer from '../../../BankTransfer/ReviewTransfer';
import TransferConfirmation from '../../../BankTransfer/TransferConfirmation';
import PropertyAndCategoryTaggingForm from '../../../PropertyAndCategoryTaggingForm';
import {
  containerStyle,
  disclaimerTextStyles,
  drawerBodyStyles,
  drawerBodyStylesTransfer,
  achTitleStyles,
  title,
  drawerFooterStyles,
} from '../../styles/makeTransferOrPaymentDrawer.styles';

type SendFundsAndInternalTransferDrawerProps = {
  bankTransferDrawerRef: any,
  handleBankTransferClose: Function,
  paymentMethod: string,
  isValidAccount: boolean,
  setIsValidAccount: Function,
  setIsValidBankTransfer: Function,
  setTransfer: Function,
  isValidBankTransfer: boolean,
  onCloseReviewTransferDrawer: Function,
  createTransferLoading: boolean,
  checkToken: Function,
  transferConfirmationDrawerRef: any,
  transfer: Object,
  handleCloseAllDrawers: Function,
  cleanup: Function,
  onOpenReviewTransferDrawer: Function,
  reviewTransferDrawerRef: any,
  refetchBankSummary: Function,
  hasBEError: Boolean,
  setHasBEError: Boolean,
  setPropertyId?: Function,
  setUnitId?: Function,
  setCategoryId?: Function,
  onCloseTransferConfirmationDrawer: Function,
  onClose: Function,
};

const SendFundsAndInternalTransferDrawer = ({
  bankTransferDrawerRef,
  handleBankTransferClose,
  paymentMethod,
  isValidAccount,
  setIsValidAccount,
  setIsValidBankTransfer,
  setTransfer,
  isValidBankTransfer,
  onCloseReviewTransferDrawer,
  createTransferLoading,
  checkToken,
  transferConfirmationDrawerRef,
  transfer,
  handleCloseAllDrawers,
  cleanup,
  onOpenReviewTransferDrawer,
  reviewTransferDrawerRef,
  refetchBankSummary,
  hasBEError,
  setHasBEError,
  setPropertyId,
  setUnitId,
  setCategoryId,
  onCloseTransferConfirmationDrawer,
  onClose, // Callback on closing drawer.
}: SendFundsAndInternalTransferDrawerProps) => {
  const { DrawerBody, DrawerFooter } = BaselaneDrawer;
  const bankTransferDrawerBodyRef = useRef(null);
  const { isMinXL } = getBreakPoints();
  const isACHDrawer = paymentMethod === 'TRANSFER_OUT';
  const drawerTitle = isACHDrawer ? 'ACH Transfer' : 'Internal Transfer';
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({});
  const [resetBankForm, setResetBankForm] = useState(false);
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const userHasEnteredData = Object.keys(transfer).length > 2;

  const {
    setShowMobileDropdownPopup,
    showMobileDropdownPopup,
    setShowMobileDropdownPopupAnimation,
  } = useContext(UserContext);

  const { categoryMap, propertiesData, categoryIdsMap, categoryWithSubOptions } = useContext(
    TransactionContext
  );
  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);
  const propertyOptions = getPropertyData(propertiesData);

  const cleanUpACHDrawer = () => {
    setValues({});
    setPropertyId(null);
    setUnitId(null);
    setCategoryId(null);
    setStep(1);
  };

  const handlePropertySubmit = (newPropertyId: string) => {
    const [newPId, newUId] = newPropertyId.split('-');
    setPropertyId(newPId);
    setUnitId(newUId);

    const updatedValues = {
      ...values,
      propertyId: newPId,
      propertyUnitId: newUId ? `${newPId}-${newUId}` : newPId,
    };

    setValues(updatedValues);
  };

  function handleCategorySubmit(newCategoryId: number, dropDown) {
    const [parentId, subId] = (newCategoryId ?? '').split('-');
    setCategoryId(subId ?? parentId);
    setValues({ ...values, categoryId: subId ?? parentId });
    setTimeout(() => {
      dropDown('close');
    }, 10);
  }

  const handleBankTransferDrawerClose = () => {
    if (!isACHDrawer && userHasEnteredData) {
      onAlertOpen();
      return;
    }

    handleBankTransferClose();
  };

  useEffect(() => {
    if (hasBEError) {
      onCloseReviewTransferDrawer();
    }
  }, [hasBEError]);

  return (
    <>
      {/* Bank Transfer Drawer */}
      <BaselaneDrawer
        ref={bankTransferDrawerRef}
        title={drawerTitle}
        size="md"
        closeEvent={handleBankTransferDrawerClose}
        onClose={handleBankTransferDrawerClose}
        hideOverlay
      >
        <DrawerBody
          ref={bankTransferDrawerBodyRef}
          {...drawerBodyStyles(isMinXL)}
          id="transfer-drawer"
        >
          {/* Title */}
          {!isACHDrawer && (
            <Text {...title(isMinXL)}>
              {paymentMethod === 'INTERNAL_TRANSFER'
                ? 'Internal Transfer'
                : 'Enter transfer details'}
            </Text>
          )}
          {isACHDrawer && step === 1 && (
            <Stack direction={!isMinXL} {...achTitleStyles(isMinXL)}>
              <Text {...title(isMinXL)} mb="0 !important">
                Enter transfer details
              </Text>
              <BaselaneSimpleProgressBar
                {...{
                  step: 1,
                  totalSteps: 2,
                }}
              />
            </Stack>
          )}

          {/* Bank Transfer Form */}
          {!(isACHDrawer && step === 2) && (
            <BankTransfer
              {...{
                setIsValidAccount,
                setIsValidBankTransfer,
                setTransfer,
                refetchBankSummary,
                ...transfer,
                hasBEError,
                bankTransferDrawerBodyRef,
                setHasBEError,
                setPropertyId,
                setUnitId,
                setCategoryId,
                resetForm: resetBankForm,
              }}
              transferType={paymentMethod}
            />
          )}

          {/* Optional Property/Category Tagging */}
          {isACHDrawer && step === 2 && (
            <PropertyAndCategoryTaggingForm
              {...{
                handlePropertySubmit,
                handleCategorySubmit,
                propertyOptions,
                categoryOptions,
                categoryIdsMap,
                categoryMap,
                setShowMobileDropdownPopup,
                setShowMobileDropdownPopupAnimation,
                showMobileDropdownPopup,
                isValidBankTransfer: true,
                renderCategoryDropdownItem: (item) => item.name,
                drawerTitle: 'Check Deposit',
                transferTypeText: 'ACH transfer',
                totalSteps: 2,
                currentStep: 2,
                values,
                cleanUpACHDrawer,
              }}
            />
          )}
        </DrawerBody>

        {/* Drawer Footer */}
        {isACHDrawer ? (
          <DrawerFooter {...drawerFooterStyles(isMinXL)}>
            <Stack
              w="100%"
              flexDirection={isMinXL ? 'column' : 'row'}
              justifyContent="space-between"
            >
              <Stack direction="row" justifyContent="space-between" align="center">
                <BaselaneButton
                  variant="outline"
                  palette="neutral"
                  size="md"
                  isFullWidth={isMinXL}
                  onClick={() => {
                    if (step === 2) {
                      setStep(1);
                    } else {
                      if (step === 1) {
                        cleanUpACHDrawer();
                      }
                      handleBankTransferClose();
                    }
                  }}
                  leftIcon={step !== 1 && <IconChevronLeft />}
                >
                  {step === 1 ? 'Close' : 'Back'}
                </BaselaneButton>
                <Spacer />
                <BaselaneButton
                  size="md"
                  variant="filled"
                  palette="primary"
                  isFullWidth={isMinXL}
                  isDisabled={!isValidBankTransfer || !isValidAccount}
                  onClick={() => {
                    if (step === 1) {
                      setStep(2);
                    } else {
                      setHasBEError(false);
                      onOpenReviewTransferDrawer();
                    }
                  }}
                  rightIcon={<IconChevronRight />}
                >
                  {step === 1 ? 'Next' : 'Review Transfer'}
                </BaselaneButton>
              </Stack>
              {/* <SSLEncryptionInfo /> */}
            </Stack>
          </DrawerFooter>
        ) : (
          <DrawerFooter {...drawerFooterStyles(isMinXL)}>
            <Stack
              w="100%"
              flexDirection={isMinXL ? 'column' : 'row'}
              justifyContent="space-between"
            >
              <Stack direction="row" justifyContent="space-between" align="center">
                <BaselaneButton
                  variant="outline"
                  palette="neutral"
                  size="md"
                  onClick={() => {
                    if (userHasEnteredData) {
                      onAlertOpen();
                    } else {
                      handleBankTransferClose();
                    }
                  }}
                  leftIcon={<IconChevronLeft />}
                >
                  Back
                </BaselaneButton>

                <Spacer />
                <BaselaneButton
                  variant="filled"
                  palette="primary"
                  size="md"
                  isDisabled={!isValidBankTransfer || !isValidAccount}
                  onClick={() => {
                    setHasBEError(false);
                    onOpenReviewTransferDrawer();
                  }}
                  rightIcon={<IconChevronRight />}
                >
                  Review Transfer
                </BaselaneButton>
              </Stack>
              {/* <SSLEncryptionInfo /> */}
            </Stack>
          </DrawerFooter>
        )}
      </BaselaneDrawer>

      {/* Review Transfer Drawer */}
      <BaselaneDrawer
        ref={reviewTransferDrawerRef}
        title={drawerTitle}
        size="md"
        closeEvent={onCloseReviewTransferDrawer}
        hideOverlay
      >
        <DrawerBody {...drawerBodyStylesTransfer(isMinXL)}>
          <Box>
            <Text {...{ ...title(isMinXL), mb: '16px' }}>
              {paymentMethod === 'INTERNAL_TRANSFER' && 'Internal Transfer'}
              {isACHDrawer && 'Review & Send Transfer'}
              {paymentMethod === 'WIRE_TRANSFER' && 'Send Funds to External Account'}
            </Text>
            <ReviewTransfer
              {...{
                transfer,
                optionalTags: values,
                propertyOptions,
                categoryOptions,
                categoryIdsMap,
              }}
            />
            <Spacer />
          </Box>
          <BaselaneDisclaimer
            textStyle={disclaimerTextStyles}
            containerStyle={containerStyle}
            text={
              paymentMethod === 'INTERNAL_TRANSFER'
                ? disclaimerText.makeTransferInternal.text
                : disclaimerText.makeTransferExternal.text
            }
            showPrivacyPolicy={
              paymentMethod === 'INTERNAL_TRANSFER'
                ? disclaimerText.makeTransferInternal.showPrivacyPolicy
                : disclaimerText.makeTransferExternal.showPrivacyPolicy
            }
          />
        </DrawerBody>
        <DrawerFooter {...drawerFooterStyles(isMinXL)}>
          <Stack w="100%" flexDirection={isMinXL ? 'column' : 'row'} justifyContent="space-between">
            <Stack direction="row" justifyContent="space-between" align="center">
              <BaselaneButton
                variant="outline"
                palette="neutral"
                size="md"
                leftIcon={<IconChevronLeft />}
                onClick={onCloseReviewTransferDrawer}
              >
                Back
              </BaselaneButton>

              <Spacer />
              <BaselaneButton
                size="md"
                variant="filled"
                palette="primary"
                isLoading={createTransferLoading}
                onClick={() => {
                  checkToken();
                  setResetBankForm(false);
                }}
                rightIcon={<IconChevronRight />}
              >
                {paymentMethod === 'INTERNAL_TRANSFER' ? 'Make Transfer' : 'Send Transfer '}
              </BaselaneButton>
            </Stack>
            {/* <SSLEncryptionInfo /> */}
          </Stack>
        </DrawerFooter>
      </BaselaneDrawer>

      {/* Transfer Confirmation Drawer */}
      <BaselaneDrawer
        ref={transferConfirmationDrawerRef}
        title={drawerTitle}
        size="md"
        closeEvent={() => {
          handleCloseAllDrawers();
          cleanup();
        }}
        hideOverlay
      >
        <DrawerBody {...drawerBodyStyles(isMinXL)}>
          <TransferConfirmation />
        </DrawerBody>
        <DrawerFooter {...drawerFooterStyles(isMinXL)}>
          <Stack w="100%" flexDirection={isMinXL ? 'column' : 'row'} justifyContent="space-between">
            <Stack direction="row" justifyContent="space-between" align="center">
              <BaselaneButton
                variant="outline"
                palette="neutral"
                size="md"
                onClick={() => {
                  handleCloseAllDrawers();
                  cleanup();
                  onClose();
                }}
              >
                Close
              </BaselaneButton>
              <BaselaneButton
                variant="filled"
                palette="primary"
                size="md"
                onClick={() => {
                  onClose();
                  onCloseTransferConfirmationDrawer();
                  onCloseReviewTransferDrawer();
                  setResetBankForm(true);
                  if (isACHDrawer) {
                    cleanUpACHDrawer();
                  }
                }}
              >
                Send More Funds
              </BaselaneButton>
            </Stack>
          </Stack>
        </DrawerFooter>
      </BaselaneDrawer>
      <UnsavedChangesAlert
        isAlertOpen={isAlertOpen}
        onAlertClose={onAlertClose}
        rightButtonEvent={() => {
          onAlertClose();
          handleBankTransferClose();
        }}
      />
    </>
  );
};

SendFundsAndInternalTransferDrawer.defaultProps = {
  setPropertyId: () => {},
  setUnitId: () => {},
  setCategoryId: () => {},
};

export default SendFundsAndInternalTransferDrawer;
