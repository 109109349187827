/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Spacer, Stack, HStack, Text } from '@chakra-ui/react';
import { BaselaneDivider } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import { dateFormatter } from '@core/utils/formatDate';
import { baselaneSummaryCardStyles } from '@shared/components/BaselaneSummaryCard/styles/baselaneSummaryCard.styles';
import { getName } from '@core/components/Shared/components/TransferFunds/helpers';
import checkIsTransfer from '@core/utils/checkIsTransfer.helper';

const ScheduledPaymentsPaymentPayeeDetails = ({ isOneTimePayment, startDate, noP, details }) => {
  const { dividerStyles } = baselaneSummaryCardStyles;

  const formatBankLabel = (account) => {
    let groupName = account?.isExternal
      ? account?.institution?.plaidInstitutionName
      : account?.institution?.name || `Baselane ${account?.institution?.id}`;

    if (groupName === 'manual') groupName = 'Manually Added Accounts';
    return account?.isExternal ? account?.institution?.plaidInstitutionName : 'Baselane';
  };

  const isTransfer = checkIsTransfer(details);

  return (
    <Box gap={1}>
      {!isOneTimePayment && !isTransfer && (
        <>
          <BaselaneDivider styles={dividerStyles} />

          <Stack w="100%" gap={1}>
            {startDate && (
              <Stack w="100%" gap={1}>
                <HStack>
                  <Text textStyle="xs">Start date</Text>
                  <Spacer />
                  <Stack gap={0}>
                    <Text textStyle="sm">{dateFormatter(startDate)}</Text>
                  </Stack>
                </HStack>
              </Stack>
            )}

            <Stack w="100%" gap={1}>
              <HStack>
                <Text textStyle="xs">
                  {details?.endDate && `Ends`}
                  {noP && `Ends after`}
                  {details?.lastPaymentDate === null && 'Ends'}
                </Text>
                <Spacer />

                <Stack gap={0}>
                  <Text textStyle="sm">
                    {details?.endDate && dateFormatter(details?.endDate)}
                    {noP && `${noP} payments`}
                    {details?.lastPaymentDate === null && 'Never'}
                  </Text>
                </Stack>
              </HStack>
            </Stack>
          </Stack>
        </>
      )}
      <BaselaneDivider styles={dividerStyles} gap={1} />
      {!isTransfer && (
        <Stack w="100%" my={1}>
          <HStack>
            <Text textStyle="xs">Processing fee</Text>
            <Spacer />
            <Stack gap={0}>
              <Text textStyle="sm">
                {details?.type === 'CHECK_PAYMENT'
                  ? formatCurrency(2).inDollars
                  : formatCurrency(0).inDollars}
              </Text>
            </Stack>
          </HStack>
        </Stack>
      )}
      <Stack w="100%">
        <HStack>
          <Text textStyle="xs">From account</Text>
          <Spacer />
          <Stack textAlign="right" gap={0}>
            <Text textStyle="xs">{formatBankLabel(details?.fromTransferAccount)}</Text>
            <Text textStyle="xs">
              {getName(
                details?.fromTransferAccount?.name,
                details?.fromTransferAccount?.accountNumber
              )}
            </Text>
          </Stack>
        </HStack>
      </Stack>
      {isTransfer && (
        <Stack w="100%" mt={1}>
          <HStack>
            <Text textStyle="xs">To account</Text>
            <Spacer />
            <Stack textAlign="right" gap={0}>
              <Text textStyle="xs">{formatBankLabel(details?.toTransferAccount)}</Text>
              <Text textStyle="xs">
                {getName(
                  details?.toTransferAccount?.name,
                  details?.toTransferAccount?.accountNumber
                )}
              </Text>
            </Stack>
          </HStack>
        </Stack>
      )}
    </Box>
  );
};

export default ScheduledPaymentsPaymentPayeeDetails;
