import React, { useContext, useEffect } from 'react';
import { Heading, Text, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import CenterContentWrapper from '@shared/layouts/CenterContentWrapper';
import { BaselaneButton } from '@shared/components';
import { IconCelebration } from '@icons/index';
import userContext from '@contexts/UserContext';
import { ONBOARDING_TRIAGE } from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import {
  handleFinishLater,
  handleGetUserInfo,
  handleRouteChange,
} from '@core/components/OnboardingTriage/helpers/onboardingFunctions.helpers';

import HeaderContent from '../../HeaderContent';

const BankingFirstSuccessUI = ({
  status,
  banksListData,
}: {
  status: string,
  banksListData: Array<object>,
}) => {
  const { user, refetchUser } = useContext(userContext);
  const [updateUser] = useMutation(UPDATE_USER);

  // Banking-first new check for onboardingTriageSelection
  const { metadata } = user?.userMetadata || {};
  // There will be users with no selection. Default to banking (first)
  const { onboardingTriageSelection = 'banking' } = metadata || {};

  const navigate = useNavigate();

  const applicationStatus =
    banksListData?.bankSummary?.bank?.[0]?.unitAccount?.unitApplicationStatus;
  const applicationType = banksListData?.bankSummary?.bank?.[0]?.unitAccount?.applicationType;

  useEffect(() => {
    if (applicationType === 'Business' && applicationStatus !== 'COMPLETED') {
      handleFinishLater({
        user: handleGetUserInfo(user, 'landlord_banking'),
        refetchUser,
        updateUser,
        handleRouteChange: () => {
          handleRouteChange(navigate, 'landlord_banking');
        },
      });
    }
  }, [banksListData]);

  return (
    <HeaderNavWrapper
      hideBackButton
      hideFinishLater
      headerContent={
        <HeaderContent onboardingTriageSelection applicationStatus={status ?? applicationStatus} />
      }
    >
      <CenterContentWrapper innerContainerStyles={{ p: 2, spacing: 3, alignItems: 'center' }}>
        <Stack alignItems="center" py={2} spacing={2}>
          <IconCelebration />
          <Stack alignItems="center">
            <Heading size="headline-xl">Your banking account is approved</Heading>
            <Text textStyle="sm" color="brand.neutral.700">
              Your Baselane Visa Debit Card will be delivered to your home address within 10
              business days.
            </Text>
          </Stack>
        </Stack>
        <BaselaneButton
          palette="primary"
          variant="filled"
          size="md"
          onClick={() => {
            sendSegmentEvent('banking_first_bank_approval_screen_continue', {
              onboardingTriageSelection,
            });
            navigate(ONBOARDING_TRIAGE);
          }}
        >
          Continue
        </BaselaneButton>
      </CenterContentWrapper>
    </HeaderNavWrapper>
  );
};

export default BankingFirstSuccessUI;
