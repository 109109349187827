import React, { useContext, useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import { useToast, Stack, Text, Box } from '@chakra-ui/react';

import {
  BaselaneResponsiveCell,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveCellDatePicker,
  BaselaneResponsiveTableSpacer,
  BaselaneResponsiveCellAmount,
  BaselaneResponsiveCellInput,
  BaselaneChip,
  BaselaneResponsiveCellText,
  BaselaneResponsiveCellTitleContent,
  BaselaneResponsiveCellAmountContent,
  T2WithTitleDropdown,
  T2Dropdown,
  NoPropertyTooltip,
} from '@shared/components';

import formatCurrency from '@core/utils/formatCurrency';
import { GET_TRANSACTIONS, GET_TRANSACTIONS_SUMMARY } from '@core/apollo/queries';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';
import TransactionContext from '@contexts/TransactionContext';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';

import {
  getPropertyData,
  renderPropertyDropdownParentItem,
} from '@shared/helpers/propertiesFilter.helpers';

import { Icon16Split, Icon16Attachment, Icon16Hide } from '@icons/16px';
import getBreakPoints from '@core/utils/getBreakPoints';
import { truncatedText } from '@shared/styles/text.style';
import '@core/styles/reactDatepicker.scss';

import { findAccount } from './SplitTransactionsDrawer/helpers/transactionsListItem.helper';
import { sortAndOrderTransactions } from './SplitTransactionsDrawer/helpers/updateCache.helper';
import { addPropertyNamesToTransactions, addPropertyNameToTransaction } from './helpers/helpers';
import TransactionListItemCheckbox from './TransactionListItemCheckbox';
import TransactionDetailsDrawer from './transaction-details-drawer/TransactionDetailsDrawer';
import {
  DisplayInputTrxCategoryItem,
  DisplayInputTrxCategoryItemContent,
  DisplayInputTrxPropertyItem,
  DisplayInputTrxPropertyItemContent,
} from './DisplayInputVariations';
import { transactionStyles } from './styles/transactionsTable.styles';
import type { Transaction } from './types';
import './styles/transactionsCell.styles.scss';

const dummyDataComponent = (title) => {
  return (
    <BaselaneResponsiveCellTitleContent
      title={title}
      titleSize="sm"
      subtitleTextStyle="xs"
      styles={{
        titleTextStyle: { color: 'brand.neutral.700' },
      }}
    />
  );
};

type TransactionsListItemProps = {
  selectedTransactions: Array<Transaction>,
  transaction: Transaction,
  setSelectedTransactions: Function,
  externalFilters?: Object,
  setHasUpdatedSplitTrx: Function,
  actionDeselectAll: Boolean,
  setActionDeselectAll: Function,
  onSelectAll: Function,
  allSelected: Boolean,
  handleSelectedTransactions: Function,
  showDummyData: Boolean,
  showHiddenTransactions: Boolean,
  showOnlyWithAttachments: Boolean,
  index: number,
};

const TransactionsListItem = ({
  transaction,
  selectedTransactions,
  setSelectedTransactions,
  externalFilters,
  setHasUpdatedSplitTrx,
  actionDeselectAll,
  setActionDeselectAll,
  onSelectAll,
  allSelected,
  handleSelectedTransactions,
  showDummyData,
  showOnlyWithAttachments,
  showHiddenTransactions,
  index,
}: TransactionsListItemProps) => {
  const { isMin1150 } = getBreakPoints();

  // Get values from TransactionContext
  const {
    categoryWithSubOptions,
    updateTransactions,
    filters,
    propertyIdsMap,
    allAccountsMap,
    accountMap,
    categoryMap,
    propertiesData,
    categoryIdsMap,
    summaryFilter,
  } = useContext(TransactionContext);

  // Check if DOM is ready
  const [domReady, setDomReady] = React.useState(false);
  useEffect(() => {
    setDomReady(true);
  }, []);

  // Transaction item properties
  const {
    id,
    date,
    name,
    merchantName,
    amount,
    tagId,
    propertyId,
    unitId,
    bankAccountId,
    pending: isPending,
    note,
    parentTransaction,
    categoryDummyName,
    isDocumentUploaded,
    bankAccountDummyName,
    propertyDummyName,
    hidden: isHidden,
  } = transaction || {};

  const { isSplit } = parentTransaction || { isSplit: false };
  const categorySelected = tagId ? { id: categoryIdsMap[tagId], name: categoryMap[tagId] } : null;

  const [fieldNote, setFieldNote] = useState(note?.text ?? '');

  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);
  const propertyOptions = getPropertyData(propertiesData);

  const { chevron, note: noteCellStyles } = transactionStyles ?? {};
  const splitTrxRef = useRef(null);
  const trxDetailsRef = useRef(null);
  const [isTrxDetailsDrawerOpen, setIsTrxDetailsDrawerOpen] = useState(
    trxDetailsRef?.current?.isOpen ?? false
  );
  const initialDate = date ? moment(date).format('MMM D YYYY') : '';
  const [selectedDate, setDate] = useState(initialDate);
  const defaultElapsedTime = transaction?.note?.date
    ? moment(transaction?.note?.date).fromNow(true)
    : null;
  const [elapsedTime, setElapsedTime] = useState(defaultElapsedTime);
  const account = findAccount(bankAccountId, allAccountsMap);

  const { isNegative: isFormattedAmountNegative, inDollars: formattedAmount } =
    formatCurrency(amount, true, false, true) || {};

  const isMigration = name?.includes('MIGRATION_OUT');

  const isFromBankMigration = isMigration;

  // original account name display logic
  const getAccountNames = () => {
    const map = accountMap()[bankAccountId];
    const subAccNameTag = (n) => {
      return isFromBankMigration ? `[OLD] ${n}` : n;
    };
    return {
      accountName: typeof map === 'object' ? map.name : map,
      accountNameSubText: typeof map === 'object' ? subAccNameTag(map.subAccountName) : null,
    };
  };

  const { accountName, accountNameSubText } = getAccountNames();

  // Toasters
  const toast = useToast();
  const showSuccessToast = (updatedField) =>
    toast({
      description: updatedField ? `${updatedField} Updated Successfully` : 'Updated Successfully',
      status: 'success',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });
  const showErrorToast = (updatedField) =>
    toast({
      description: updatedField ? `${updatedField} Failed to Update` : 'Failed to Update',
      status: 'error',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  // Update Transaction Fn
  async function updateTransaction(
    parameter,
    hasNoteUpdated,
    toastProps,
    onSuccess,
    optimisticResponse
  ) {
    const { showErrToast, showSuccessfulToast, updatedField } = toastProps ?? {};

    await updateTransactions({
      variables: { input: { id, ...parameter } },
      ...(optimisticResponse ? { ...optimisticResponse } : {}),
      update: (cache, { data: { updateTransactions: update } }) => {
        let updatedTransaction = update;
        if (Array.isArray(update)) {
          const [t] = update;
          updatedTransaction = t;
        }

        // Update elapsedTime for notes
        if (hasNoteUpdated) {
          const days = moment(updatedTransaction?.note?.date).fromNow(true);
          setElapsedTime(days);
        }

        const { transaction: cachedTransactions } =
          cache.readQuery({
            query: GET_TRANSACTIONS,
            variables: { input: { ...filters } },
          }) ?? {};

        const updatedTransactionWithPropertyName = addPropertyNameToTransaction(
          updatedTransaction,
          propertyIdsMap
        );

        const transactionsWithProperties = addPropertyNamesToTransactions(
          cachedTransactions,
          propertyIdsMap
        );

        if (selectedTransactions) {
          setSelectedTransactions((prev) =>
            prev.map((t) =>
              t.id === updatedTransactionWithPropertyName.id
                ? {
                    ...updatedTransactionWithPropertyName,
                    isChecked: true,
                  }
                : t
            )
          );
        }

        // remove items from end of list if it is greater than the page limit trx
        const orderedTransactions = sortAndOrderTransactions(
          transactionsWithProperties.map((t) =>
            t.id === updatedTransactionWithPropertyName.id ? updatedTransactionWithPropertyName : t
          ),
          filters
        );

        // account for category change
        if (tagId !== updatedTransaction.tagId) {
          const res = cache.readQuery({
            query: GET_TRANSACTIONS_SUMMARY,
            variables: { input: summaryFilter },
          });

          if (res) {
            // eslint-disable-next-line
            let absoluteAmount = res.transactionSummary.totalUncategorized.absoluteAmount;
            // eslint-disable-next-line
            let count = res.transactionSummary.totalUncategorized.count;
            if (tagId && !updatedTransaction.tagId) {
              absoluteAmount += Math.abs(updatedTransaction.amount);
              count += 1;
            } else if (!tagId && updatedTransaction.tagId) {
              absoluteAmount -= Math.abs(updatedTransaction.amount);
              count -= 1;
            }

            cache.writeQuery({
              query: GET_TRANSACTIONS_SUMMARY,
              data: {
                transactionSummary: {
                  ...res.transactionSummary,
                  totalUncategorized: {
                    ...res.transactionSummary.totalUncategorized,
                    count,
                    absoluteAmount,
                  },
                },
              },
              variables: { input: summaryFilter },
            });
          }
        }

        cache.writeQuery({
          query: GET_TRANSACTIONS,
          variables: { input: { ...filters } },
          data: {
            transaction: orderedTransactions,
          },
        });
      },
      onCompleted: () => {
        if (showSuccessfulToast) showSuccessToast(updatedField);
        if (onSuccess) onSuccess();
      },
      onError: () => {
        if (showErrToast) showErrorToast(updatedField);
      },
    });
  }

  // Submit handlers
  function handleMerchantSubmit(newMerchantName: string) {
    if (merchantName?.trim() !== newMerchantName?.trim()) {
      updateTransaction({ merchantName: newMerchantName }, null, null, null, {
        optimisticResponse: {
          updateTransactions: {
            ...transaction,
            merchantName: newMerchantName,
          },
        },
      });
    }
  }

  function handleNoteSubmit(newNote: string, toastProps = null) {
    if (note?.text?.trim() !== newNote?.trim()) {
      updateTransaction({ note: newNote }, true, toastProps, null, {
        optimisticResponse: { updateTransactions: { ...transaction, note: newNote } },
      });
    }
  }

  function handleNoteFieldUpdate(newNote: string, toastProps = null) {
    if (fieldNote?.trim() !== newNote?.trim()) {
      setFieldNote(newNote);
      handleNoteSubmit(newNote, toastProps);
    }
  }

  function handleDateSubmit(newDate: string, toastProps = null) {
    setDate(newDate || '');
    updateTransaction({ date: newDate }, false, toastProps, null, {
      optimisticResponse: { updateTransactions: { ...transaction, date: newDate } },
    });
  }

  function handlePropertySubmit(
    newPropertyId: string,
    toastProps = { showToast: false, updatedField: 'Property' }
  ) {
    const [newPId = null, newUId = null] =
      typeof newPropertyId === 'string' ? newPropertyId.split('-') : [];
    updateTransaction({ propertyId: newPId, unitId: newUId }, false, toastProps, null, {
      optimisticResponse: {
        updateTransactions: { ...transaction, propertyId: newPId, unitId: newUId },
      },
    });
  }

  async function handleCategorySubmit(
    newCategoryId: string,
    toastProps = { showToast: false, updatedField: 'Category' },
    onSuccess = null
  ) {
    const [parentId = null, subId = null] =
      typeof newCategoryId === 'string' ? newCategoryId.split('-') : [];
    updateTransaction({ tagId: subId ?? parentId }, false, toastProps, onSuccess, {
      optimisticResponse: { updateTransactions: { ...transaction, tagId: subId ?? parentId } },
    });
  }

  const getSelectedProperty = () => {
    let selectedProperty = null;
    if (propertyId && unitId) {
      selectedProperty = {
        id: `${propertyId}-${unitId}`,
        showValueName: `${propertyIdsMap[`p-${propertyId}`]?.name}, ${
          propertyIdsMap[`u-${unitId}`]?.name
        }`,
      };
    } else if (propertyId && !unitId) {
      selectedProperty = {
        id: `${propertyId}`,
        showValueName: propertyIdsMap[`p-${propertyId}`]?.name,
      };
    }

    return selectedProperty;
  };

  const categoryDropdownCell = (
    <BaselaneResponsiveCell
      stopEventPropagation
      configIndex={6}
      contentContainerStyles={{ display: 'block' }}
      id="category-dropdown-cell"
    >
      {showDummyData ? (
        dummyDataComponent(categoryDummyName)
      ) : (
        <T2WithTitleDropdown
          additionalProps={{ id: 'category-dropdown' }}
          classNames={[
            'border-on-hover',
            'clear-on-hover',
            'carret-on-hover',
            'fixed-width-dropdown',
            'auto-select-input-width',
          ]}
          data={categoryOptions}
          title="Category"
          showValueByFields={['name']}
          placeholder="Select Category"
          parentItemRenderer={itemRenderer}
          childItemRenderer={itemRenderer}
          handleSubmit={(newId, item, dropDown) =>
            handleCategorySubmit(newId, {
              showErrToast: true,
              showSuccessfulToast: false,
              updatedField: 'Category',
            })
          }
          selectedItem={categorySelected}
          CustomDisplayInput={DisplayInputTrxCategoryItem}
        />
      )}
    </BaselaneResponsiveCell>
  );

  // Update list ui when note is updated from drawer
  useEffect(() => {
    setFieldNote(note?.text);
  }, [note]);

  const descriptionTitle = merchantName || name;

  return (
    <>
      {/* Mobile table row */}
      <BaselaneResponsiveTableRow
        onClick={() => {
          setIsTrxDetailsDrawerOpen(true);
        }}
        chevronCellStyles={chevron}
        id={`table-row-${index}`}
        contentContainerStyles={{
          alignItems: 'space-between',
          display:
            (!showHiddenTransactions && transaction.hidden) ||
            (showOnlyWithAttachments && !transaction.isDocumentUploaded)
              ? 'none'
              : { lg: 'none', base: 'table-row' },
        }}
      >
        <BaselaneResponsiveCell
          configIndex={0}
          isVertical
          {...{
            py: 1.5,
            px: 1.5,
            overflow: 'initial',
          }}
          contentContainerStyles={{
            spacing: 0.75,
          }}
        >
          {/* description + amount */}
          <Stack
            direction="row"
            justifyContent="space-between"
            w="100%"
            {...{ overflow: 'initial' }}
          >
            <BaselaneResponsiveCellTitleContent
              title={descriptionTitle}
              rightTitleElement={
                isPending && <BaselaneChip size="sm" label="Pending" variant="status-primary" />
              }
              titleSize="sm-heavy"
              styles={{
                titleTextStyle: { color: 'brand.neutral.700' },
                subtitleTextStyle: { color: 'brand.neutral.600' },
                textContainer: { width: 'auto' },
                titleContainer: { gap: 0.5 },
              }}
            />

            <Stack direction="row" alignItems="center">
              <BaselaneResponsiveCellAmountContent
                leftGraphicElement={
                  isSplit ? (
                    <Box mr={0.5}>
                      <Icon16Split color="neutral.500" />
                    </Box>
                  ) : null
                }
                textStyle="data-sm"
                color={isFormattedAmountNegative ? 'brand.neutral.700' : 'green.800AA'}
              >
                {formattedAmount}
              </BaselaneResponsiveCellAmountContent>
            </Stack>
          </Stack>

          {/* property + date */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            overflow="initial"
            {...truncatedText}
          >
            {showDummyData ? (
              dummyDataComponent(propertyDummyName)
            ) : (
              <DisplayInputTrxPropertyItemContent
                {...{
                  selectedOptions: getSelectedProperty() ? [getSelectedProperty()] : [],
                  placeholder: 'Property not selected',
                  showValueByFields: ['showValueName'],
                  color: 'brand.neutral.500',
                }}
              />
            )}

            {/* Date */}
            <Text {...{ textStyle: 'sm', color: 'brand.neutral.700' }}>
              {selectedDate?.toString() ?? '-'}
            </Text>
          </Stack>

          {/* category */}
          <Stack direction="row" alignItems="center" w="100%" overflow="initial" {...truncatedText}>
            {showDummyData ? (
              dummyDataComponent(propertyDummyName)
            ) : (
              <DisplayInputTrxCategoryItemContent
                {...{
                  placeholder: 'Category not selected',
                  selectedOptions: categorySelected ? [categorySelected] : [],
                  categoryMap,
                  color: 'brand.neutral.500',
                }}
              />
            )}
          </Stack>
        </BaselaneResponsiveCell>
      </BaselaneResponsiveTableRow>

      {/* Desktop table row */}
      <BaselaneResponsiveTableRow
        onClick={() => {
          setIsTrxDetailsDrawerOpen(true);
        }}
        chevronCellStyles={chevron}
        id={`table-row-${index}`}
        contentContainerStyles={{
          display:
            (!showHiddenTransactions && transaction.hidden) ||
            (showOnlyWithAttachments && !transaction.isDocumentUploaded)
              ? 'none'
              : { lg: 'table-row', base: 'none' },
        }}
      >
        <TransactionListItemCheckbox
          {...{
            transaction,
            actionDeselectAll,
            setActionDeselectAll,
            onSelectAll,
            allSelected,
            selectedTransactions,
            handleSelectedTransactions,
            index,
          }}
        />
        {/* Date  */}
        {/* TODO: during trx details drawer revamp this will not be interactable anymore, remove condition check */}
        {isMin1150 ? (
          <BaselaneResponsiveCellDatePicker
            stopEventPropagation
            name="date"
            configIndex={1}
            selectedDate={selectedDate}
            dateFormat="MMM dd yyyy"
            onDateSubmit={(newDate) =>
              handleDateSubmit(newDate, {
                showErrToast: true,
                showSuccessfulToast: false,
                updatedField: 'Date',
              })
            }
            p="8px" // update padding only for body cell while not touching header padding
          />
        ) : (
          <BaselaneResponsiveCellText text={selectedDate?.toString() ?? '-'} />
        )}

        {/* Account */}
        {showDummyData ? (
          <BaselaneResponsiveCell configIndex={2}>
            {dummyDataComponent(bankAccountDummyName)}
          </BaselaneResponsiveCell>
        ) : (
          <BaselaneResponsiveCellTitle
            title={accountName || 'Manually Added'}
            subtitle={accountNameSubText}
            titleSize="sm"
            subtitleTextStyle="xs"
            configIndex={2}
            styles={{
              titleTextStyle: { color: 'brand.neutral.700' },
              subtitleTextStyle: { color: 'brand.neutral.600' },
            }}
          />
        )}

        {/* Description */}
        <BaselaneResponsiveCell configIndex={3}>
          <BaselaneResponsiveCellTitleContent
            title={descriptionTitle}
            subtitle={descriptionTitle === name ? null : name}
            rightTitleElement={
              isPending && <BaselaneChip size="sm" label="Pending" variant="status-primary" />
            }
            titleSize="sm-heavy"
            styles={{
              titleTextStyle: { color: 'brand.neutral.700' },
              subtitleTextStyle: { color: 'brand.neutral.600' },
            }}
          />
        </BaselaneResponsiveCell>

        {/* 4. Amount */}
        <BaselaneResponsiveCellAmount
          configIndex={4}
          textStyle="data-sm"
          color={isFormattedAmountNegative ? 'brand.neutral.700' : 'green.800AA'}
          leftGraphicElement={
            (isSplit || isHidden) && (
              <Stack direction="row">
                {isHidden ? <Icon16Hide /> : null}
                {isSplit ? <Icon16Split color="neutral.500" /> : null}
              </Stack>
            )
          }
        >
          {formattedAmount}
        </BaselaneResponsiveCellAmount>

        {/* 5. Spacer */}
        <BaselaneResponsiveTableSpacer configIndex={6} />

        {/* 6. Category / Category & property */}
        {isMin1150 ? (
          categoryDropdownCell
        ) : (
          <BaselaneResponsiveCell
            configIndex={6}
            stopEventPropagation
            isVertical
            id="category-property-cell"
            contentContainerStyles={{
              alignItems: 'flex-start!important',
              overflow: 'hidden',
              spacing: 0.75,
            }}
          >
            <DisplayInputTrxPropertyItemContent
              {...{
                selectedOptions: getSelectedProperty() ? [getSelectedProperty()] : [],
                placeholder: 'Property not selected',
                showValueByFields: ['showValueName'],
                color: 'brand.neutral.500',
              }}
            />
            <DisplayInputTrxCategoryItemContent
              {...{
                placeholder: 'Category not selected',
                selectedOptions: categorySelected ? [categorySelected] : [],
                categoryMap,
                color: 'brand.neutral.500',
              }}
            />
          </BaselaneResponsiveCell>
        )}

        {/* 7. Property placeholder  */}
        {showDummyData ? (
          <BaselaneResponsiveCell stopEventPropagation configIndex={7} p="0">
            {dummyDataComponent(propertyDummyName)}
          </BaselaneResponsiveCell>
        ) : (
          <BaselaneResponsiveCell
            configIndex={7}
            stopEventPropagation
            contentContainerStyles={{ display: 'block' }}
            id="property-dropdown-cell"
          >
            {propertyOptions?.length === 0 ? (
              <NoPropertyTooltip>
                <T2Dropdown
                  classNames={['auto-select-input-width', 'carret-on-hover']}
                  placeholder="Select Property"
                  CustomDisplayInput={DisplayInputTrxPropertyItem}
                  isDisabled
                />
              </NoPropertyTooltip>
            ) : (
              <T2Dropdown
                additionalProps={{ id: 'property-dropdown' }}
                classNames={[
                  'border-on-hover',
                  'clear-on-hover',
                  'carret-on-hover',
                  'fixed-width-dropdown',
                  'auto-select-input-width',
                ]}
                data={propertyOptions}
                title="Property"
                placeholder="Select Property"
                showValueByFields={['showValueName']}
                parentItemRenderer={({ item }) => renderPropertyDropdownParentItem(item)}
                childItemRenderer={itemRenderer}
                handleSubmit={(newPropertyId) =>
                  handlePropertySubmit(newPropertyId, {
                    showErrToast: true,
                    showSuccessfulToast: false,
                    updatedField: 'Property',
                  })
                }
                selectedItem={getSelectedProperty()}
                CustomDisplayInput={DisplayInputTrxPropertyItem}
              />
            )}
          </BaselaneResponsiveCell>
        )}

        {/* 8. Note   */}
        {showDummyData ? (
          <BaselaneResponsiveCell configIndex={7} textStyle="sm">
            {dummyDataComponent(note?.text)}
          </BaselaneResponsiveCell>
        ) : (
          <BaselaneResponsiveCellInput
            name="note"
            stopEventPropagation
            enableReinitialize
            configIndex={8}
            placeholder="Add note"
            value={fieldNote}
            handleStateUpdate={(newNote) =>
              handleNoteFieldUpdate(newNote, {
                showErrToast: true,
                showSuccessfulToast: false,
                updatedField: 'Note',
              })
            }
            rightGraphicElement={isDocumentUploaded ? <Icon16Attachment /> : null}
            styles={
              note?.text
                ? { ...noteCellStyles, ...noteCellStyles.activeText }
                : { ...noteCellStyles, ...noteCellStyles.placeholderText }
            }
          />
        )}

        {/* 9. Amount */}
        <BaselaneResponsiveCellAmount
          configIndex={9}
          textStyle="data-sm"
          color={isFormattedAmountNegative ? 'brand.neutral.700' : 'green.800AA'}
          leftGraphicElement={isSplit ? <Icon16Split color="neutral.500" /> : null}
        >
          {formattedAmount}
        </BaselaneResponsiveCellAmount>
      </BaselaneResponsiveTableRow>
      {isTrxDetailsDrawerOpen &&
        domReady &&
        createPortal(
          <TransactionDetailsDrawer
            {...{
              trxDetailsRef,
              splitTrxRef,
              transaction,
              isTrxDetailsDrawerOpen,
              setIsTrxDetailsDrawerOpen,
              handleMerchantSubmit,
              handleDateSubmit,
              handleNoteSubmit,
              externalFilters,
              setHasUpdatedSplitTrx,
              account,
              elapsedTime,
              filters,
              categoryMap,
              categoryOptions,
              propertyOptions,
              getSelectedProperty,
              handleCategorySubmit,
              handlePropertySubmit,
              pending: isPending,
            }}
          />,
          document.getElementById('root')
        )}
    </>
  );
};

TransactionsListItem.defaultProps = {
  externalFilters: ({}: { ... }),
};
export default TransactionsListItem;
