export const flowLocationPoints = {
  rent_collection: {
    easy_steps: 'onboarding_rc_how_it_works',
    before_you_begin: 'onboarding_rc_what_you_need',
    finishLater: 'onboarding_rc_finish_later',
  },
  landlord_banking: {
    easy_steps: 'onboarding_banking_how_it_works',
    before_you_begin: 'onboarding_banking_what_you_need',
    finishLater: 'onboarding_banking_finish_later',
  },
  bookkeeping: {
    easy_steps: 'onboarding_bookkeeping_how_it_works',
    before_you_begin: 'onboarding_bookkeeping_what_you_need',
    finishLater: 'onboarding_bookkeeping_finish_later',
  },
};
