import { React } from 'react';
import { Spinner } from '@chakra-ui/react';

const BaselaneSpinner = ({ ...rest }): any => {
  return (
    <Spinner
      {...{
        color: 'brand.blue.500',
        size: 'xl',
        position: 'absolute',
        top: '50%',
        left: '50%',
        ml: '-22px',
        mt: '-22px',
        ...rest,
      }}
    />
  );
};

export default BaselaneSpinner;
