import React, { useRef } from 'react';
import { UnorderedList, ListItem, Text } from '@chakra-ui/react';
import { AlertHeader, AlertFooter, BaselaneAlert } from '@shared/components';
import { IconInfo } from '@icons';

type ConfirmationAlertProps = {
  failedPayment: Object,
  isConfirmationAlertOpen: boolean,
  onConfirmationlertClose: Function,
  handleConfirmUpdateAccClick: Function,
};

function ConfirmationAlert({
  failedPayment,
  isConfirmationAlertOpen,
  onConfirmationlertClose,
  handleConfirmUpdateAccClick,
}: ConfirmationAlertProps) {
  const cancelRef = useRef();

  const { list } = failedPayment ?? {};
  const { FAILED, PROCESSING, leases, UNPAID } = list ?? {};

  const failedPaymentCount = FAILED?.length ?? 0;
  const processingPaymentCount = PROCESSING?.length ?? 0;
  const leasesCount = leases?.length ?? 0;
  const unpaidPaymentCount = UNPAID?.length ?? 0;

  return (
    <BaselaneAlert
      {...{
        isOpen: isConfirmationAlertOpen,
        onClose: onConfirmationlertClose,
        leastDestructiveRef: cancelRef,
        isCentered: true,
        showCloseButton: true,
        size: '2xl',
        header: (
          <AlertHeader
            title="Update receiving bank account?"
            icon={<IconInfo />}
            iconBgStyles={{ backgroundColor: 'brand.blue.100' }}
          />
        ),
        body: (
          <UnorderedList pl="8px">
            {!!failedPaymentCount && (
              <ListItem>{`${failedPaymentCount} failed invoice${
                failedPaymentCount === 1 ? '' : 's'
              } will be re-processed with the updated account`}</ListItem>
            )}

            {!!processingPaymentCount && (
              <ListItem>
                {`${processingPaymentCount} processing payment${
                  processingPaymentCount === 1 ? '' : 's'
                } will be re-processed with the updated account if
        they fail`}
              </ListItem>
            )}

            {(!!leasesCount || !!unpaidPaymentCount) && (
              <ListItem>
                {!!leasesCount && (
                  <Text as="span">{`${leasesCount} rent collection${
                    leasesCount === 1 ? '' : 's'
                  }`}</Text>
                )}
                {!!leasesCount && !!unpaidPaymentCount && <Text as="span">{` and `}</Text>}
                {!!unpaidPaymentCount && (
                  <Text as="span">{`${unpaidPaymentCount} other unpaid invoice${
                    unpaidPaymentCount === 1 ? '' : 's'
                  }`}</Text>
                )}
                <Text as="span">{` will be updated with the new account`}</Text>
              </ListItem>
            )}
          </UnorderedList>
        ),
        footer: (
          <AlertFooter
            {...{
              leftButtonText: 'Cancel',
              rightButtonText: 'Update receiving account',
              leftButtonProps: {
                variant: 'outline',
                palette: 'neutral',
              },
              leftButtonEvent: () => onConfirmationlertClose(),
              rightButtonEvent: () => handleConfirmUpdateAccClick(),
              cancelRef,
            }}
          />
        ),
        containerStyles: { m: '16px !important' },
      }}
    />
  );
}

export default ConfirmationAlert;
