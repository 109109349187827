import React from 'react';
import { BaselaneButton } from '@shared/components';
import { Icon16Refresh } from '@icons/16px';

type TransactionsListLoadMoreProps = {
  hasMoreTransactions: boolean,
  handleLoadMore: Function,
  transactionsLoading: boolean,
  remainingTransactionsCount: number,
};

const TransactionsListLoadMore = ({
  hasMoreTransactions,
  handleLoadMore,
  transactionsLoading,
  remainingTransactionsCount,
}: TransactionsListLoadMoreProps): any => {
  return (
    hasMoreTransactions && (
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="lg"
        onClick={handleLoadMore}
        isLoading={transactionsLoading}
        leftIcon={<Icon16Refresh />}
      >
        {remainingTransactionsCount} more transactions
      </BaselaneButton>
    )
  );
};

export default TransactionsListLoadMore;
