// @flow
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Box } from '@chakra-ui/react';
import { BaselaneSpinner } from '@shared/components';
import useBankListQuery from '@core/components/NativeBankingPage/MainContent/hooks/useBankListQuery';
import AccountsAndApplications from './BaselaneAccounts/components/AccountsAndApplications';
import EmptyStateView from './BaselaneAccounts/EmptyStateView';

function Accounts() {
  const { bankList, refetchBankList, listLoading } = useBankListQuery();

  // Note: same as hasActiveBaselaneAccounts/hasVisibleApplications in BanksContext
  const hasActiveBaselaneAccounts = bankList.some(
    (bank) => bank.unitApplicationStatus === 'COMPLETED'
  );
  const hasVisibleApplications =
    bankList.filter((bank) => {
      return bank.unitApplicationStatus !== 'COMPLETED' && !bank.isHidden;
    })?.length > 0;

  const containerStyles = {
    bg: isMobile && 'brand.blue.50',
    // overlap mobile padding in this case
    m: '-24px',
  };

  if (listLoading) return <BaselaneSpinner />;

  return hasActiveBaselaneAccounts || hasVisibleApplications ? (
    <Box {...containerStyles}>
      <AccountsAndApplications bankList={bankList} refetchBankList={refetchBankList} />
    </Box>
  ) : (
    <EmptyStateView />
  );
}

export default Accounts;
