export const titleStyles = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
};

export const descriptionStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
};

export const formContainerStyles = {
  mt: '16px',
};

export const fieldContainerStyles = {
  w: '100%',
  position: 'relative',
  mb: '16px',
};

export const formLabelWithTooltipStyles = {
  lineHeight: '16px',
};

export const formTooltipTextStyles = {
  textAlign: 'center',
};

export const spinnerStyles = {
  color: 'brand.darkBlue.150',
  w: '16px',
  h: '16px',
};
