import React from 'react';

type IconSearchProps = {
  w?: Number,
  h?: Number,
  color?: String,
};

function IconSearch({ w, h, color }: IconSearchProps) {
  return (
    <svg
      width={w}
      height={h}
      viewBox={`0 0 ${w} ${h}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66732 2.99788C5.60645 2.99788 4.58904 3.41931 3.83889 4.16946C3.08875 4.9196 2.66732 5.93702 2.66732 6.99788C2.66732 7.52317 2.77078 8.04332 2.9718 8.52862C3.17282 9.01392 3.46746 9.45488 3.83889 9.82631C4.21033 10.1977 4.65128 10.4924 5.13658 10.6934C5.62189 10.8944 6.14203 10.9979 6.66732 10.9979C7.19261 10.9979 7.71275 10.8944 8.19805 10.6934C8.68335 10.4924 9.12431 10.1977 9.49574 9.82631C9.86718 9.45488 10.1618 9.01392 10.3628 8.52862C10.5639 8.04332 10.6673 7.52317 10.6673 6.99788C10.6673 5.93702 10.2459 4.9196 9.49574 4.16946C8.7456 3.41931 7.72818 2.99788 6.66732 2.99788ZM2.89608 3.22665C3.89628 2.22645 5.25283 1.66455 6.66732 1.66455C8.08181 1.66455 9.43836 2.22645 10.4386 3.22665C11.4387 4.22684 12.0007 5.5834 12.0007 6.99788C12.0007 7.69827 11.8627 8.39179 11.5947 9.03886C11.4121 9.47959 11.1717 9.89288 10.8804 10.2681L14.4721 13.8598C14.7324 14.1202 14.7324 14.5423 14.4721 14.8026C14.2117 15.063 13.7896 15.063 13.5292 14.8026L9.93757 11.2109C9.56231 11.5022 9.14902 11.7427 8.7083 11.9252C8.06123 12.1933 7.3677 12.3312 6.66732 12.3312C5.96694 12.3312 5.27341 12.1933 4.62634 11.9252C3.97927 11.6572 3.39133 11.2644 2.89608 10.7691C2.40084 10.2739 2.00799 9.68593 1.73996 9.03886C1.47194 8.39179 1.33398 7.69827 1.33398 6.99788C1.33398 5.5834 1.89589 4.22684 2.89608 3.22665Z"
        fill={color}
      />
    </svg>
  );
}

IconSearch.defaultProps = {
  w: 16,
  h: 17,
  color: '#6C7884',
};

export default IconSearch;
