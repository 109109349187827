import React from 'react';
import { Box } from '@chakra-ui/react';
import LeaseTerms from './LeaseTerms';
import DepositsFees from './DepositsFees';
import ReceivingBankAccounts from './ReceivingBankAccounts';
import TenantDetails from './TenantDetails';
import type { PropertyUnitType } from '../../../types';
import { generalFormContainerStyles } from '../../styles/shared.styles';

type ReviewLeaseFormProps = {
  unit: PropertyUnitType,
  leaseCreated: boolean,
  setTabIndex: Function,
  bankProps: Object,
};

function ReviewLeaseForm({ unit, leaseCreated, setTabIndex, bankProps }: ReviewLeaseFormProps) {
  const components = [
    LeaseTerms,
    DepositsFees,
    'InvoicesDummy',
    ReceivingBankAccounts,
    TenantDetails,
  ];
  const items = components.map((item, i) => {
    return { view: item, id: i };
  });
  return (
    <Box {...generalFormContainerStyles} pt={0} pb={2} w="100%" overflowX="hidden">
      {items
        .filter((item) => item.view !== 'InvoicesDummy')
        .map((item) => {
          const { view: View, id } = item;
          return (
            <View
              {...{
                key: id,
                id,
                lease: unit?.lease,
                setTabIndex,
                leaseCreated,
                bankProps,
              }}
            />
          );
        })}
    </Box>
  );
}

export default ReviewLeaseForm;
