import React, { forwardRef, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
} from '@chakra-ui/react';
import { listStyles } from '@shared/components/BaselaneDropdown/components/styles/dropdown.styles';
import { IconCarretDown, IconCarretUp } from '@icons';
import ParentListItem from '../../ParentListItem';
import {
  setIsSelectedItem,
  getChildCounterVisualBgColor,
} from '../../helpers/dropdown.shared.helpers';
import { parentTextStyles } from '../../styles/parentListItem.styles';
import {
  accordionButtonStyles,
  accordionItemChildContainerStyles,
  accordionItemChildStyles,
  accordionItemContainerStyles,
  accordionPanelStyles,
  accordionToggleContainerStyles,
  accordionToggleCounterContainerStyles,
  accordionToggleIconContainerStyles,
} from '../../styles/accordion.styles';

type ListOfParentAndChildrenWithTitleProps = {
  options: Array<any>,
  parentItemRenderer: Function,
  childItemRenderer: Function,
  hasFilterWrapper: Boolean,
  search: String,
  hideSearch: Boolean,
  dropDown: Function,
  handleListItemClick: Function,
  selectedStagedOptions: Array,
  childrenSelectAllState: Object,
};

const ListOfParentAndChildrenWithTitle = forwardRef(
  (
    {
      options,
      parentItemRenderer,
      childItemRenderer,
      hasFilterWrapper,
      search,
      hideSearch,
      handleListItemClick,
      selectedStagedOptions,
      dropDown,
      childrenSelectAllState,
    }: ListOfParentAndChildrenWithTitleProps,
    ref
  ) => {
    return (
      <Box key="option-wrap" {...listStyles(hasFilterWrapper, hideSearch)} ref={ref}>
        {options.map((option) => {
          const availableItems = option.items?.filter((item) => {
            const isParent = Array.isArray(item.searchValues);
            return isParent
              ? item.searchValues?.some(
                  (value) => value.toLowerCase().indexOf(search.toLowerCase()) !== -1
                )
              : item.searchValues.toLowerCase().indexOf(search.toLowerCase()) !== -1;
          });

          return (
            <Fragment key={`option-fragment-${option.title}`}>
              {/* NOTE: Not adding elements inside ParentListItem because it will re-render list causing the accordion to close */}
              {/* onChange of checkboxes */}
              <ParentListItem
                styles={{ py: '8px', display: availableItems.length > 0 ? 'block' : 'none' }}
                key={`parent-${option.title}-${uuidv4()}`}
                title={option.title}
                listItem={
                  <Box key={option.title} {...parentTextStyles}>
                    {option.title}
                  </Box>
                }
              />
              {availableItems.reduce((acc, item, index, allItems) => {
                const { id, isChild, hasChildren } = item;
                const parentChildren = hasChildren
                  ? allItems.filter((cItem) => {
                      const { isChild: cIsChild } = cItem;

                      const [childParentId] = cItem.id.split('-');
                      return childParentId === id && cIsChild;
                    })
                  : [];

                if (isChild) {
                  return acc;
                }

                acc.push(
                  hasChildren ? (
                    <Accordion
                      key={item.id}
                      allowToggle
                      allowMultiple
                      {...(search ? { index: [0] } : {})}
                    >
                      <AccordionItem border="none">
                        {({ isExpanded }) => (
                          <>
                            <AccordionButton {...accordionButtonStyles} height="40px" width="100%">
                              <HStack
                                tabIndex="-1"
                                className="element"
                                key={item.id}
                                id={item.id}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleListItemClick(item, e, dropDown);
                                }}
                                bgColor={
                                  setIsSelectedItem(item, selectedStagedOptions)
                                    ? 'brand.blue.50'
                                    : 'initial'
                                }
                                {...accordionItemContainerStyles}
                              >
                                {parentItemRenderer({ item, search })}
                              </HStack>

                              <HStack {...accordionToggleContainerStyles(search)}>
                                <HStack
                                  id="child-counter-visual"
                                  bgColor={getChildCounterVisualBgColor(
                                    childrenSelectAllState?.[item.id],
                                    isExpanded
                                  )}
                                  {...accordionToggleCounterContainerStyles}
                                >
                                  <HStack {...accordionToggleIconContainerStyles}>
                                    {isExpanded ? (
                                      <IconCarretUp
                                        width="8"
                                        height="5"
                                        color={search ? '#E5E9EF' : '#3A4B5B'}
                                      />
                                    ) : (
                                      <IconCarretDown
                                        width="8"
                                        height="5"
                                        color={search ? '#E5E9EF' : '#3A4B5B'}
                                      />
                                    )}
                                  </HStack>
                                </HStack>
                              </HStack>
                            </AccordionButton>
                            <AccordionPanel {...accordionPanelStyles} pl="16px">
                              {parentChildren.map((pChild) => (
                                <HStack key={pChild.id} {...accordionItemChildContainerStyles}>
                                  <HStack
                                    tabIndex="-1"
                                    className="element"
                                    key={item.id}
                                    id={item.id}
                                    onClick={(e) => {
                                      handleListItemClick(pChild, e, dropDown);
                                    }}
                                    bgColor={
                                      setIsSelectedItem(pChild, selectedStagedOptions)
                                        ? 'brand.blue.50'
                                        : 'initial'
                                    }
                                    {...accordionItemChildStyles}
                                  >
                                    {childItemRenderer({ item: pChild, search })}
                                  </HStack>
                                </HStack>
                              ))}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    <HStack key={item.id} align="center" width="100%" height="40px">
                      <HStack
                        tabIndex="-1"
                        className="element"
                        key={item.id}
                        id={item.id}
                        onClick={(e) => {
                          handleListItemClick(item, e, dropDown);
                        }}
                        bgColor={
                          setIsSelectedItem(item, selectedStagedOptions)
                            ? 'brand.blue.50'
                            : 'initial'
                        }
                        {...accordionItemChildStyles}
                      >
                        {parentItemRenderer({ item, search })}
                      </HStack>
                    </HStack>
                  )
                );

                return acc;
              }, [])}
            </Fragment>
          );
        })}
      </Box>
    );
  }
);

export default ListOfParentAndChildrenWithTitle;
