import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { BaselaneCard, BaselaneDivider } from '@shared/components';
import {
  RenderWarningMessage,
  showWarningInDesktop,
  showWarningInMobile,
} from '@core/components/App/RenderWarningMessage';
import TopCard from './components/TopCard';
import InfoCard from './components/InfoCard';
import InfoBoxList from './components/InfoBoxList';
import MiddleCard from './components/MiddleCard';
import CompareTable from './components/CompareTable';
import NoteList from './components/NoteList';
import BottomCard from './components/BottomCard';
import { insurancePageStyles } from './styles/insurancePage.styles';

// data
import {
  topCard,
  infoCard,
  infoBoxData,
  middleCard,
  tableData,
  noteListData,
  bottomCard,
} from './helpers/insurance.helpers';

function InsurancePage() {
  const currLocation = useLocation();

  const { card, container, divider } = insurancePageStyles() ?? {};
  if (showWarningInMobile(currLocation) || showWarningInDesktop(currLocation)) {
    return <RenderWarningMessage />;
  }
  return (
    <BaselaneCard styles={card}>
      <Stack {...container}>
        <TopCard
          smallIcon={topCard?.smallIcon}
          rightImage={topCard?.rightImage}
          title={topCard?.title}
          description={topCard?.description}
          buttonProps={topCard?.buttonProps}
        >
          <InfoCard
            icon={infoCard?.icon}
            title={infoCard?.title}
            description={infoCard?.description}
          />
        </TopCard>
        <BaselaneDivider styles={divider} />

        <InfoBoxList listContent={infoBoxData} />
        <BaselaneDivider styles={divider} />

        <MiddleCard
          title={middleCard?.title}
          mobile={middleCard?.mobileTitle}
          description={middleCard?.description}
          buttonProps={middleCard?.buttonProps}
        >
          <CompareTable
            title={bottomCard?.title}
            description={bottomCard?.description}
            buttonProps={bottomCard?.buttonProps}
            tableData={tableData}
          />
          <NoteList listContent={noteListData} />
        </MiddleCard>

        <BaselaneDivider styles={divider} />
        <BottomCard
          title={bottomCard?.title}
          description={bottomCard?.description}
          buttonProps={bottomCard?.buttonProps}
        />
      </Stack>
    </BaselaneCard>
  );
}

export default InsurancePage;
