import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { PopupButton } from '@typeform/embed-react';

import { Heading, VStack, HStack, Text, Box } from '@chakra-ui/react';
import { IconArrowRightAlternate } from '@icons';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { BaselaneButton, BaselaneLink } from '@core/components/Shared/components';

import {
  signupButtonStyles,
  signinLinkContainerStyles,
  signinLinkTextStyles,
} from '../../styles/signup.styles';

import {
  tenantFormHeadingStyles,
  tenantFormTextStyles,
  tenantFormPointStyles,
  hiddenContainerStyles,
} from '../../styles/tenantFormText.styles';

const TenantFormText = () => {
  const typeformRef = useRef();
  const url = 'https://tenant.baselane.com/login';
  return (
    <VStack {...{ alignItems: 'flex-start' }}>
      <Heading {...tenantFormHeadingStyles}>
        Renters can only sign up to Baselane with an invitation from a Landlord.
      </Heading>
      <Text {...{ ...tenantFormTextStyles }}>Have you already been invited?</Text>
      <Text {...{ ...tenantFormPointStyles }}>
        <Text as="span" {...{ mr: '12px', pt: '2px' }}>
          <IconArrowRightAlternate />
        </Text>
        Please check your email or texts for an invitation with instructions to set up your account.
      </Text>
      <Text {...{ ...tenantFormTextStyles }}>Looking to get your Landlord on Baselane?</Text>
      {/* This is a hidden Typeform Embed button, so that we can
          use a properly styled BaselaneButton to open the Typeform overlay */}
      <Box {...hiddenContainerStyles}>
        <PopupButton
          ref={typeformRef}
          id="WEzrIIvM"
          onReady={() => {
            sendSegmentEvent('signup_click_share_with_landlord');
          }}
        />
      </Box>
      <BaselaneButton
        variant="filled"
        palette="primary"
        size="lg"
        onClick={() => {
          typeformRef?.current?.open();
        }}
        styles={signupButtonStyles}
      >
        {isMobile ? `Share with Landlord` : `Share Baselane with your Landlord`}
      </BaselaneButton>
      <HStack {...signinLinkContainerStyles}>
        <Text>Already have an account?&nbsp;</Text>
        <Text {...signinLinkTextStyles}>
          <BaselaneLink href={url} variant="primary" isExternal>
            Sign In
          </BaselaneLink>
        </Text>
      </HStack>
    </VStack>
  );
};

export default TenantFormText;
