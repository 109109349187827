import React from 'react';
import { UnorderedList, ListItem, Text } from '@chakra-ui/react';
import { IconRefresh, IconX, IconCheck, IconUpload } from '@icons';
import { IllustrationIdentityverificationinprogress } from '@illustrations';
import customTheme from '@core/theme';
import BaselaneLink from '@shared/components/BaselaneLink';

const { brand, red, green } = customTheme.colors;
const { 100: blue100, 700: blue700 } = brand.blue;
const { 100: green100, '800AA': green800AA } = green;
const { 200: red200, '800AA': red800AA } = red;

// TODO: Update status key names
export const KYC_STATUS = Object.freeze({
  APPROVED: {
    title: 'Identity Verified!',
    description: 'Your account is now enabled to collect rent.',
    description2: (
      <>
        <Text>In addition to collecting rent, we also encourage you to:</Text>
        <UnorderedList marginInlineStart="1.5em">
          <ListItem>Open a Baselane Banking account to seamlessly manage your finances</ListItem>
          <ListItem>Tag Transactions to streamline your bookkeeping</ListItem>
          <ListItem>
            View your Cash Flow and financial metrics to asses your portfolio&apos;s performance
          </ListItem>
        </UnorderedList>
      </>
    ),
    icon: <IconCheck w={20} h={16} color={green800AA} />,
    iconBg: green100,
  },
  DENIED: {
    title: "Unfortunately, we weren't able to verify your identity.",
    description: 'Baselane takes precautions to protect the safety and security of our customers.',
    description2: (
      <Text>
        If you believe you incorrectly inputted your information, you may contact customer support
        at{' '}
        <BaselaneLink variant="primary" href="mailto:support@baselane.com">
          support@baselane.com
        </BaselaneLink>
        .
      </Text>
    ),
    icon: <IconX w={18} h={18} color={red800AA} />,
    iconBg: red200,
  },
  PENDING: {
    title: 'Your Identity Verification is Under Review',
    description:
      'We will email you when your identity has been verified or if we encounter any issues. This is usually resolved within 48 hours.',
    icon: <IconRefresh w={22} h={22} color={blue700} />,
    iconBg: blue100,
  },
  VOUCH_PENDING: {
    title: 'Identity verification in progress',
    description:
      "We are currently verifying your identity. This process may take up to 24 hours. While we work diligently to confirm your identity, feel free to explore Baselane's features.",
    icon: <IllustrationIdentityverificationinprogress />,
  },
  ADDITIONAL_INFO: {
    title: 'You need to provide more information',
    description:
      'We weren’t able to verify your identity with the information you’ve provided us. Please provide additional information to help us verify your identity.',
    icon: <IconUpload w={30} h={30} color={blue700} />,
    iconBg: blue100,
  },
  // By default, show PENDING status drawer when UNVERIFIED is returned since the status changes from UNVERIFIED to PENDING shortly after submit
  UNVERIFIED: {
    title: 'Your Identity Verification is Under Review',
    description:
      'We will email you when your identity has been verified or if we encounter any issues. This is usually resolved within 48 hours.',
    icon: <IconRefresh w={22} h={22} color={blue700} />,
    iconBg: blue100,
  },
});
