const boxShadow = '0 0 0 2px #9FD8FF';

const buttonCommmonStyles = {
  justifySelf: 'flex-end',
  margin: '0 auto 0 0',
};

export const buttonRentCollectionCTA = {
  ...buttonCommmonStyles,
  bg: 'green.600',
  _hover: {
    bg: '#70D03C',
  },
  _focusVisible: {
    bg: '#70D03C',
    boxShadow,
  },
  _active: {
    bg: 'green.700',
    boxShadow: 'none !important',
  },
};

export const buttonChangeAccountCTA = {
  ...buttonCommmonStyles,
  color: 'green.600',
  fontWeight: 'semibold',
  _hover: {
    bg: 'green.50',
  },
  _focusVisible: {
    boxShadow,
  },
  _active: {
    bg: 'green.100',
    boxShadow: 'none !important',
  },
};

export const bannerContainerStyles = (hasCollectedRentWithinNeededPeriod) => {
  return {
    bgColor: 'green.100',
    justify: 'space-between',
    padding: !hasCollectedRentWithinNeededPeriod ? '12px 16px' : '12px 12px 12px 16px',
    borderRadius: '4px',
    mt: '20px',
  };
};

export const bannerTextContainerStyles = {
  alignItems: 'flex-start',
  alignSelf: 'flex-start',
  ml: '12px !important',
  mr: 'auto !important',
};

export const bannerHeadingStyles = {
  textStyle: 'headline-sm',
  m: '0 !important',
  color: 'brand.neutral.900',
};

export const bannerTextStyles = {
  textStyle: 'xs',
  m: '0 !important',
  color: 'brand.neutral.800',
};
