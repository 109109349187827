import {
  ONBOARDING_GS_BEFORE_BEGINNING,
  UNIFIED_BOOKKEEPING_ANALYTICS,
  UNIFIED_LANDLORD_BANKING,
  UNIFIED_RENT_COLLECTION,
} from '@routes';

const baseFlow = {
  easy_steps: {
    next: ONBOARDING_GS_BEFORE_BEGINNING,
    rent_collection: { next: UNIFIED_RENT_COLLECTION },
    landlord_banking: { next: UNIFIED_LANDLORD_BANKING },
    bookkeeping: { next: UNIFIED_BOOKKEEPING_ANALYTICS },
  },
};

const routeFlow = {
  ...baseFlow,
  before_you_begin: {
    landlord_banking: { next: UNIFIED_LANDLORD_BANKING },
    bookkeeping: { next: UNIFIED_BOOKKEEPING_ANALYTICS },
  },
};

export default routeFlow;
