const EMAIL_ALERT_TITLE = 'Please verify your email';
const EMAIL_ALERT_BODY =
  'Before proceeding to identity verification, you must verify your email by clicking on the verification link that was sent to your email at sign-up. If you cannot find the email in your inbox or if the link has expired, you can resend the email to verify your email.';
const EMAIL_ALERT_BUTTON1 = 'Cancel';
const EMAIL_ALERT_BUTTON2 = 'Resend Verification Email';

const ALERT_TITLE = 'You Have Unsaved Changes';
const ALERT_BODY = 'Are you sure you want to exit without saving?';
const ALERT_BUTTON1 = 'Continue Editing';
const ALERT_BUTTON2 = 'Exit Without Saving';

const KYC_ALERT_BODY1 = 'We are verifying your identity.';
const KYC_ALERT_BODY2 = 'This may take up to 20 seconds.';

export {
  ALERT_TITLE,
  ALERT_BODY,
  ALERT_BUTTON1,
  ALERT_BUTTON2,
  EMAIL_ALERT_TITLE,
  EMAIL_ALERT_BODY,
  EMAIL_ALERT_BUTTON1,
  EMAIL_ALERT_BUTTON2,
  KYC_ALERT_BODY1,
  KYC_ALERT_BODY2,
};
