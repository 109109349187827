import moment from 'moment';

export const checkIsSectionValid = ({
  newCompletedTags,
  leaseMenuOptions,
  setCompletedTags,
  setLeaseMenuOptions,
  isPropertyPanelValid,
}) => {
  if (newCompletedTags) {
    const newOptions = leaseMenuOptions?.map((item, index) => {
      const newItem = item;
      const isServerValid = newCompletedTags.filter((n) => n === leaseMenuOptions[index].name);
      if (isPropertyPanelValid && newItem.label === 'Select rental property') {
        newItem.isValid = true;
      } else if (isServerValid.length) {
        newItem.isValid = true;
      } else {
        newItem.isValid = false;
      }
      return newItem;
    });
    setCompletedTags(newCompletedTags);
    setLeaseMenuOptions(newOptions);
  } else {
    const newOptions = leaseMenuOptions?.map((item) => {
      const newItem = item;
      if (isPropertyPanelValid && newItem.label === 'Select rental property') {
        newItem.isValid = true;
      } else {
        newItem.isValid = false;
      }
      return newItem;
    });
    setLeaseMenuOptions(newOptions);
  }
};

export const resetCreateLeaseValues = ({
  variables,
  initialVariables,
  setLeaseId,
  setLeaseVariables,
  setLeaseMenuOptions,
  setCompletedTags,
  initialMenuState,
  clearVars,
}) => {
  Object.keys(variables).forEach((k) => {
    // eslint-disable-next-line no-param-reassign
    if (k !== 'propertyUnitId') variables[k] = null;
  });
  setLeaseId(null);
  if (clearVars) {
    setLeaseVariables({ ...initialVariables });
  } else {
    setLeaseVariables({ ...initialVariables, ...variables });
  }
  setLeaseMenuOptions(initialMenuState());
  setCompletedTags(null);
};

export const handleCreateLeaseErr = ({
  error,
  setCompletedTags,
  showCreateLeaseErrorToast,
  setLeaseVariables,
  variables,
  completedTags,
}) => {
  const hasErrorFromBankAccountTab = Array.from(error).filter((e) =>
    e?.extensions?.exception?.response?.message?.includes('Stripe bank account id null')
  );

  // NOTE: if error from bank account tab with stripe id null,
  // do not show toast + delete 'BANK_ACCOUNT' from completedTags
  if (hasErrorFromBankAccountTab && hasErrorFromBankAccountTab.length > 0) {
    const newCompletedTags = completedTags?.filter((tag) => tag !== 'BANK_ACCOUNT');
    setCompletedTags(newCompletedTags);
  } else {
    showCreateLeaseErrorToast();
  }

  console.error(error);
  // Delete leaseType & action fields to enable individual save separate from create lease
  const { leaseType: tmp, action: tmp2, ...rest } = variables;
  setLeaseVariables(rest);
};

export const segmentEventNames = [
  'add_property_started',
  'add_lease_lease_terms_complete',
  'add_lease_invoices_complete',
  'add_lease_bank_account_complete',
  'add_lease_tenant_details_complete',
  'add_confirm_complete',
];

// Unified RC events
// these are part of the flow as well but added elsewhere
// 'onboarding_rc_click_add_property',
// 'onboarding_rc_click_connect_account',
// 'onboarding_rc_click_verify_identity',
// 'onboarding_rc_click_verify_later',
export const unifiedLeaseSegmentEventNames = (onboardingCompleted) => [
  `${onboardingCompleted ? 'baselane' : 'onboarding'}_rc_complete_property_selection`,
  `${onboardingCompleted ? 'baselane' : 'onboarding'}_rc_complete_unit_selection`,
  `${onboardingCompleted ? 'baselane' : 'onboarding'}_rc_complete_lease_terms`,
  `${onboardingCompleted ? 'baselane' : 'onboarding'}_rc_complete_deposit_fees`,
  `${onboardingCompleted ? 'baselane' : 'onboarding'}_rc_complete_invoices`,
  `${onboardingCompleted ? 'baselane' : 'onboarding'}_rc_complete_bank_account`,
  `${onboardingCompleted ? 'baselane' : 'onboarding'}_rc_complete_tenant_details`,
  `${onboardingCompleted ? 'baselane' : 'onboarding'}_rc_complete_review_lease`,
];

export const cleanUpPayments = ({ payments }) => {
  const rent = payments.find((payment) => payment.name === 'RENT');

  const result = payments?.map((data) => {
    // eslint-disable-next-line no-underscore-dangle,no-param-reassign
    delete data.__typename;
    // eslint-disable-next-line no-underscore-dangle,no-param-reassign
    delete data.category;

    const date = data.dueDate ? moment(data.dueDate, moment.defaultFormat).toDate() : null;

    // eslint-disable-next-line no-param-reassign
    data.dueDate = data.dueDate ? moment(date).format('YYYY-MM-DD') : null;

    if (data.name === 'RENT' && data.dueDays === null) {
      // eslint-disable-next-line no-param-reassign
      delete data.dueDays;
      // eslint-disable-next-line no-param-reassign
      data.dueDays = 1;
    }

    if (
      ['DEPOSIT', 'PRORATED_RENT', 'LAST_MONTH_RENT', 'LATE_FEE'].indexOf(data.name) > -1 &&
      data.enabled === null
    ) {
      // eslint-disable-next-line no-param-reassign
      data.enabled = false;
    }

    if (data.name === 'LAST_MONTH_RENT') {
      // eslint-disable-next-line no-param-reassign
      data.amount = rent?.amount ?? data.amount;
    }

    return data;
  });

  return result;
};

export const cleanUpSave = ({
  variables,
  newVariables,
  isFromModal,
  isStartNewLease,
  clickedUnit,
  unit,
  tabIndex,
  LEASE_TABS,
  leaseId,
  isCreateLeaseSave,
}) => {
  // TODO: now that reset variables is fixed, see if some of this is still needed
  let result = { ...variables, ...newVariables };
  if (isFromModal && !isStartNewLease) {
    // just loading an existing lease
    result = { id: clickedUnit?.lease?.id, propertyUnitId: clickedUnit?.id };
  } else if (
    isStartNewLease ||
    (tabIndex === LEASE_TABS.PROPERTY && clickedUnit?.id && !clickedUnit?.lease)
  ) {
    // if we have no lease at all for this clickedUnit
    result = {
      id: null,
      propertyUnitId: clickedUnit?.id,
    };
  } else if (tabIndex === LEASE_TABS.PROPERTY && clickedUnit?.id) {
    // if we have a lease we can add propertyUnitId in case we're moving it
    result.propertyUnitId = clickedUnit.id;
  } else if (unit && unit.lease && unit.lease.id === leaseId) {
    result.propertyUnitId = unit.id;
  }

  // cleanup payments inside result
  if (result.payments) {
    delete result.payments;
    result.payments = cleanUpPayments({ payments: variables.payments });
  }

  // if invoicesConfirmed and we are on Terms we remove it
  // TODO: should FE ever set this to false? Or is this BE initiated?
  if (tabIndex === LEASE_TABS.TERMS && result.invoicesConfirmed === null) {
    delete result.invoicesConfirmed;
  } else if (result.invoicesConfirmed === null) {
    result.invoicesConfirmed = false;
  }

  // no null tenantProfileId (should be not possible now that we're using below leaseRes data)
  if (tabIndex < LEASE_TABS.TENANT && result.tenantProfileId === null) {
    delete result.tenantProfileId;
  }

  // If user partly filled out a new tenant data we don't save the form yet
  const tenantProfileMetadataFull =
    result.tenantProfileMetadata?.firstName &&
    result.tenantProfileMetadata?.lastName &&
    result.tenantProfileMetadata?.email;
  const tenantProfileMetadataPartly =
    !tenantProfileMetadataFull &&
    (result.tenantProfileMetadata?.firstName ||
      result.tenantProfileMetadata?.lastName ||
      result.tenantProfileMetadata?.email);

  if (tenantProfileMetadataFull) {
    result.tenantProfileId = null;
  } else if (tenantProfileMetadataPartly) {
    // send the whole thing or nothing at all
    delete result.tenantProfileMetadata;
  }

  // Remove the connectionState from the bank account object(s)
  if (result?.rentAndFeesBankAccount?.connectionState) {
    delete result.rentAndFeesBankAccount.connectionState;
  }
  if (result?.depositBankAccount?.connectionState) {
    delete result.depositBankAccount.connectionState;
  }

  // Remove the whole object if it's from create response
  if (result?.rentAndFeesBankAccount?.accountNumber) {
    delete result.rentAndFeesBankAccount;
    delete result.rentAndFeesBankAccountId;
  }
  if (result?.depositBankAccount?.accountNumber) {
    delete result.depositBankAccount;
    delete result.depositBankAccountId;
  }

  if (!isCreateLeaseSave && !newVariables.payments && result.payments && !result.payments[1]) {
    // Make sure payments does not contain remnants of previously selected lease
    delete result.payments;
  }

  // make sure to only add lease id if not existing
  if (!result.id) {
    result.id = isStartNewLease ? null : leaseId;
  }

  return result;
};
