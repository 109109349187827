import React from 'react';
import { DrawerBody as DrawerBodyContainer } from '@chakra-ui/react';
import DrawerPanel from './DrawerPanel';
import { drawerBodyStyle } from '../styles/drawer.style';

type DrawerBodyProps = {
  isDirty: boolean,
  setIsDirty: Function,
  setIsValid: Function,
  setIsError: Function,
  setHideFooter: Function,
  detailsComplete: boolean,
  setDetailsComplete: Function,
  property: Object,
  setFormVariables: Function,
  initialValues: Object,
  variables: Object,
  setShowZillow: Function,
  setShowMortgage: Function,
  financials: boolean,
  propertyFormRef: Object,
};

function DrawerBody({
  isDirty,
  setIsDirty,
  setIsValid,
  setIsError,
  setHideFooter,
  detailsComplete,
  setDetailsComplete,
  property,
  setFormVariables,
  initialValues,
  variables,
  setShowZillow,
  setShowMortgage,
  financials,
  propertyFormRef,
}: DrawerBodyProps): any {
  return (
    <DrawerBodyContainer {...drawerBodyStyle}>
      <DrawerPanel
        {...{
          isDirty,
          setIsDirty,
          setIsValid,
          setIsError,
          setHideFooter,
          detailsComplete,
          setDetailsComplete,
          property,
          setShowZillow,
          setShowMortgage,
          setFormVariables,
          initialValues,
          variables,
          financials,
          propertyFormRef,
        }}
      />
    </DrawerBodyContainer>
  );
}

export default DrawerBody;
