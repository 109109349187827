import { DateTime } from 'luxon';
import useCalendarExcludedDates from '../pages/Transfers/hooks/useCalendarExcludedDates';

// Custom hook to get the valid start and end date for transfers, payments, etc.
// Valid date is a date that is not a weekend or a holiday.
const useValidDates = () => {
  const { excludedDates = [], excludedDatesWithoutToday = [] } = useCalendarExcludedDates();

  // Argument is a DateTime object.
  const overlapsWithExcludedDates = (date) => {
    return excludedDates.some((excludedDate) => {
      const excludedDateAsDateTime = DateTime.fromJSDate(excludedDate); // Convert to DateTime object.
      return date.hasSame(excludedDateAsDateTime, 'day');
    });
  };

  // Recursive function to find the first valid date.
  // The argument, and the return value, are both DateTime objects.
  const getValidDate = (today) => {
    const isWeekend = today.weekday === 6 || today.weekday === 7;
    const isHoliday = overlapsWithExcludedDates(today);

    if (isWeekend || isHoliday) {
      return getValidDate(today.plus({ days: 1 }));
    }

    return today;
  };

  const today = Date.now();
  const startDate = getValidDate(DateTime.now());
  const endDate = getValidDate(startDate.plus({ days: 1 }));

  // Return the start and end date as JS dates.
  return {
    today,
    startDate: startDate.toJSDate(),
    endDate: endDate.toJSDate(),
    excludedDates,
    excludedDatesWithoutToday,
    getValidDate,
  };
};

export default useValidDates;
