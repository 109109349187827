// @flow
import React from 'react';
import { Accordion } from '@chakra-ui/react';
import { containerStyles } from '../BaselaneAccordion/styles/accordion.style';

type Props = {
  allowToggle?: Boolean,
  allowMultiple?: Boolean,
  defaultIndex?: any,
  styles?: Object,
  children: any,
  onChange?: Function,
};

function BaselaneAccordionWrapper({
  children,
  allowToggle,
  allowMultiple,
  defaultIndex,
  styles,
  onChange,
}: Props): any {
  const accordionContainerStyles = {
    ...containerStyles,
    ...styles,
  };

  // NOTE: if using button inside AccordionButton/headerElements. You will need to use event.stopPropogation for now. Will need to confirm UI/UX with designer.
  return (
    <Accordion
      allowToggle={allowToggle}
      allowMultiple={allowMultiple}
      defaultIndex={defaultIndex}
      {...accordionContainerStyles}
      onChange={onChange}
    >
      {children}
    </Accordion>
  );
}

BaselaneAccordionWrapper.defaultProps = {
  allowToggle: false,
  allowMultiple: false,
  defaultIndex: Array,
  styles: Object,
  onChange: () => {},
};

export default BaselaneAccordionWrapper;
