import React, { useRef } from 'react';
import { Stack } from '@chakra-ui/react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import getBreakPoints from '@core/utils/getBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import BaselaneDrawer from '../BaselaneDrawer';
import GeneralLandlordBankingCard from './GeneralLandlordBankingCard';
import GeneralExternalBankingCard from './GeneralExternalBankingCard';
import AddAccountManuallyDrawer from '../AddAccountManuallyDrawer';

type AddAccountsDrawerProps = {
  from?: string,
};

const AddAccountsDrawer = ({ from }: AddAccountsDrawerProps) => {
  const {
    handleExternalAccountConnectDrawerClose = () => {},
    handleAddAccountManuallyOnSubmit = () => {},
    handleAddAccountManuallySuccess = () => {},
  } = useOutletContext() ?? {};
  const { isMax576 } = getBreakPoints();
  const navigate = useNavigate();
  const addAccountManuallyDrawerRef = useRef(null);

  return (
    <>
      <BaselaneDrawer
        isOpen
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        title="Add account"
        footer={null}
        closeEvent={() => onDrawerClose(navigate, from)}
        onOverlayClick={() => onDrawerClose(navigate, from)}
        newDesignDrawer
      >
        <Stack spacing={2}>
          <GeneralLandlordBankingCard from={from} />
          <GeneralExternalBankingCard
            closeDrawer={() => onDrawerClose(navigate, from)}
            handleExternalAccountConnectDrawerClose={handleExternalAccountConnectDrawerClose}
            addAccountManuallyDrawerRef={addAccountManuallyDrawerRef}
          />
        </Stack>
      </BaselaneDrawer>
      <AddAccountManuallyDrawer
        addAccountManuallyDrawerRef={addAccountManuallyDrawerRef}
        handleAddAccountManuallySuccess={handleAddAccountManuallySuccess}
        handleAddAccountManuallyOnSubmit={handleAddAccountManuallyOnSubmit}
        from={{ section: 'my_accounts' }}
      />
    </>
  );
};

AddAccountsDrawer.defaultProps = {
  from: null,
};

export default AddAccountsDrawer;
