import isNil from '@core/utils/validators/isNil';
import { getLeaseRemainingTime } from '@pages/LeasesPage/helpers/unit.helpers';

export function getDaysRemainingUntil(
  startDate: string,
  endDate: ?string,
  leaseType: string,
  status: string
): string {
  let remainingTime = '';

  if (status === 'outside_baselane') {
    remainingTime = 'External rent collection';
    return remainingTime;
  }

  if (isNil(startDate) || isNil(endDate) || status === 'unoccupied') {
    return remainingTime; // No unit.leaseEnd date available
  }

  remainingTime = getLeaseRemainingTime({ startDate, endDate, leaseType });

  return remainingTime;
}
export function rentStatusHelper(unit) {
  if (unit?.status === 'outside_baselane') {
    return '';
  }
  const paymentState = unit?.lease && unit?.lease?.paymentState;
  if (paymentState === null || !unit?.lease) {
    return 'No Active Rent Collection';
  }
  if (paymentState === 'CURRENT') {
    return 'Current';
  }
  if (paymentState === 'OVERDUE') {
    return 'Overdue';
  }
  return '';
}

export const getOverallRentStatus = (property) => {
  const { units } = property;
  const hasUnits = units.length > 1; // If property has only one unit than consider the unit as the property
  if (!hasUnits) {
    const [unit] = property.units;
    return rentStatusHelper(unit);
  }

  const totalUnits = units.length;

  const totalOverdue = units.reduce((acc, current) => {
    if (current.lease && current.lease.paymentState === 'OVERDUE') {
      return acc + 1;
    }
    return acc + 0;
  }, 0);
  const totalCurrent = units.reduce((acc, current) => {
    if (current.lease && current.lease.paymentState === 'CURRENT') {
      return acc + 1;
    }
    return acc + 0;
  }, 0);
  const totalOutsideBaselane = units.reduce((acc, current) => {
    if (current.status === 'outside_baselane') {
      return acc + 1;
    }
    return acc + 0;
  }, 0);

  if (totalUnits === totalOutsideBaselane) {
    return '';
  }

  if (totalUnits === totalCurrent || (totalOverdue === 0 && totalCurrent > 0)) {
    return 'All Current';
  }

  if (totalOverdue > 0) {
    return `${totalOverdue} Overdue`;
  }

  if (totalOverdue === 0 && totalCurrent === 0) {
    return 'No Active Rent Collection';
  }
  return '';
};

export const getOverallOccupancyStatus = (property) => {
  const { units } = property;
  const hasUnits = units.length > 1; // If property has only one unit than consider the unit as the property
  if (!hasUnits) {
    const [unit] = property.units;
    return unit?.status === 'occupied' || unit?.status === 'outside_baselane'
      ? 'Occupied'
      : 'Vacant';
  }
  const totalNum = units.length;
  const totalOccupied = units.reduce((acc, current) => {
    if (current.status === 'occupied' || current.status === 'outside_baselane') {
      return acc + 1;
    }
    return acc + 0;
  }, 0);
  if (totalOccupied === 0) {
    return `Vacant`;
  }
  return `${totalOccupied}/${totalNum} Occupied`;
};

export const getIcon = (item, type) => {
  if (type === 'PROPERTY') {
    const rentLabelOutsideBaselane = getOverallRentStatus(item) === '';
    const rentLabelOverdue =
      getOverallRentStatus(item).includes('Overdue') && getOverallRentStatus(item) !== '';

    const rentLabelCurrent = getOverallRentStatus(item).includes('Current');
    const rentLabelNoActiveLease = getOverallRentStatus(item).includes('No Active Rent Collection');
    if (rentLabelOutsideBaselane) {
      return '';
    }
    if (rentLabelCurrent) {
      return 'rentstatuscurrent';
    }
    if (rentLabelOverdue) {
      return 'rentstatusoverdue';
    }
    if (rentLabelNoActiveLease) {
      return 'rentstatusnoactivelease';
    }
  } else if (type === 'UNIT') {
    const rentLabelOutsideBaselane = rentStatusHelper(item) === '';
    const rentLabelOverdue =
      rentStatusHelper(item).includes('Overdue') && rentStatusHelper(item) !== '';

    const rentLabelCurrent = rentStatusHelper(item).includes('Current');
    const rentLabelNoActiveLease = rentStatusHelper(item).includes('No Active Rent Collection');

    if (rentLabelOutsideBaselane) {
      return '';
    }
    if (rentLabelCurrent) {
      return 'rentstatuscurrent';
    }
    if (rentLabelOverdue) {
      return 'rentstatusoverdue';
    }
    if (rentLabelNoActiveLease) {
      return 'rentstatusnoactivelease';
    }
  }
  return '';
};

export const getIconStyles = (status) => {
  if (status.toLowerCase().includes('current')) {
    return {
      fontSize: 'sm',
      lineHeight: '20px',
      color: 'green.800AA',
    };
  }

  if (status.toLowerCase().includes('overdue')) {
    return {
      fontSize: 'sm',
      lineHeight: '20px',
      color: 'red.800AA',
    };
  }
  if (status.toLowerCase().includes('no active rent collection')) {
    return {
      fontSize: 'sm',
      lineHeight: '20px',
      color: 'yellow.700',
    };
  }
  return status;
};
