import React from 'react';

type IconRefreshProps = { w: string, h: string, color: string };

function IconRefresh({ w = '12', h = '13', color = 'currentColor' }: IconRefreshProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.974 1.818a6 6 0 018.98 4.432.667.667 0 11-1.323.167 4.668 4.668 0 00-8.462-2.084H4a.667.667 0 110 1.334H.667A.667.667 0 010 5V1.667a.667.667 0 111.333 0v1.56a6 6 0 011.64-1.41zM.624 7.005a.667.667 0 01.745.578 4.669 4.669 0 008.462 2.084H8a.667.667 0 010-1.334h3.333c.368 0 .667.299.667.667v3.333a.667.667 0 01-1.333 0v-1.56A6.002 6.002 0 01.047 7.75a.667.667 0 01.577-.745z"
        fill={color}
      />
    </svg>
  );
}

export default IconRefresh;
