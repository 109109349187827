import React from 'react';
import customTheme from '@core/theme';
import { BaselaneResponsiveBanner } from '@shared/components';
import { IconInvoiceFailed, IconUIArrowRight } from '@core/components/Icons';

type InvoicesFailedBannerProps = {
  failedAmount: string,
  failedCount: number,
  showFailedInvoices: Function,
};

function InvoicesFailedBanner({
  failedAmount,
  failedCount,
  showFailedInvoices,
}: InvoicesFailedBannerProps) {
  return (
    <BaselaneResponsiveBanner
      icon={
        <IconInvoiceFailed
          {...{
            w: '40px',
            h: '40px',
            color: customTheme.colors.brand.blue['300'],
          }}
        />
      }
      indicatorColor={customTheme.colors.red['100']}
      title="Failed tenant payments"
      description={`${failedAmount} from ${failedCount} invoice${failedCount === 1 ? '' : 's'}`}
      callToActionButtonvariant="tonal"
      palette="primary"
      callToActionLabel="View Invoices"
      callToActionRightIcon={<IconUIArrowRight w="18" />}
      onCallToActionClick={() => {
        showFailedInvoices();
      }}
      styles={{
        title: { color: customTheme.colors.brand.neutral['700'] },
        description: { color: customTheme.colors.brand.neutral['600'] },
      }}
      borderColor={customTheme.colors.brand.darkBlue['150']}
      m="16px"
    />
  );
}

export default InvoicesFailedBanner;
