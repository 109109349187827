import React from 'react';
import { isMobile } from 'react-device-detect';
import { Stack, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  TwoFactorVerificationPopUp,
  useTwoFactor,
} from '@shared/components';
import { IconExclamationCircle } from '@icons';
import { GET_CARD_SUMMARY, TERMINATE_CARD } from '@core/components/NativeBankingPage/queries';
import { FRAGMENT_CARD_STATUS } from '@core/components/NativeBankingPage/queries/fragments';

import { CARD_STATUS } from '../helpers/card.helper';
import { alertPopupIconBgStyles, alertPopupIconContainerStyles } from '../styles/lockCard.styles';
import {
  setTokenData,
  getIsTokenExpired,
  getIsTokenValid,
  getSensitiveToken,
  getUnitOTPVerification,
  handleOTPPopupClose,
  handleOTPPopupOpen,
  handleNBOnVerifyClick,
} from '../../../../../helpers/otp.helpers';

type TerminateCardProps = {
  bankId: string,
  card: Object,
  phoneNumber: string,
  cardFilters: Function,
  handleDrawerClose: Function,
};

const TerminateCard = ({
  bankId,
  card,
  phoneNumber,
  cardFilters,
  handleDrawerClose,
}: TerminateCardProps) => {
  const { cache } = useApolloClient();
  const { cardStatus, id } = card ?? {};

  const [terminateCard, { loading }] = useMutation(TERMINATE_CARD, {
    refetchQueries: [
      {
        query: GET_CARD_SUMMARY,
        variables: {
          page: 1,
          pageSize: 99,
          filter: {
            ...(cardFilters ? cardFilters() : {}),
            includeHiddenCards: false,
          },
        },
        options: {
          awaitRefetchQueries: true,
        },
      },
    ],
  });

  const cardId = Number(id);
  const { isTerminateButtonDisabled } = CARD_STATUS[cardStatus] ?? {};

  // Lock Card pop status
  const {
    isOpen: isTerminateCardPopupOpen,
    onOpen: onTerminateCardPopupOpen,
    onClose: onTerminateCardPopupClose,
  } = useDisclosure();

  // two factor verification states
  // NOTE: see AddEditSubAccountDrawer/index.jsx for some comments about OTP and setting the information for sensitive token/time
  const { states, stateFunctions } = useTwoFactor();
  const { setOTPErrorCode } = stateFunctions;

  // OTP: Check if token of the bank expired
  const isTokenExpired = getIsTokenExpired(bankId);

  const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

  const onUserSensitiveTokenComplete = (unitAPISensitiveToken) => {
    handleOTPPopupClose(states, stateFunctions);

    const isTokenValid = getIsTokenValid(unitAPISensitiveToken);
    setTokenData(unitAPISensitiveToken, bankId);

    if (isTokenValid) {
      // after successfully getting sensitive token open popup
      onTerminateCardPopupOpen();
    }
  };

  const getSensitiveTokenProps = {
    onUserSensitiveTokenComplete,
    getOTP,
    bankId,
    states,
    stateFunctions,
  };

  const [getUserSensitiveTokenData] = getSensitiveToken(getSensitiveTokenProps);

  // Toasts
  const toast = useToast();
  const showSuccessToast = () =>
    toast({
      position: isMobile ? 'bottom' : 'bottom-left',
      description: 'Card Terminated Successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showErrorToast = () =>
    toast({
      position: isMobile ? 'bottom' : 'bottom-left',
      description: 'Something went wrong',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleLockCardClick = () => {
    terminateCard({ variables: { cardId } })
      .then(({ data, errors }) => {
        if (data) {
          onTerminateCardPopupClose();
          showSuccessToast();
          cache.writeFragment({
            id: cache.identify(card),
            fragment: FRAGMENT_CARD_STATUS,
            data: {
              __typename: 'Card',
              cardStatus: 'ClosedByCustomer',
            },
          });
          handleDrawerClose();
        } else if (errors?.length > 0) {
          showErrorToast();
          onTerminateCardPopupClose();
        }
      })
      .catch((error) => console.error(error));
  };

  const title = <>Are you sure you want to terminate this card?</>;

  const terminateCardPopupContent = (
    <Text>
      Terminating this card is non reversible and all future transactions will be declined. You will
      be able to access your past transactions.
    </Text>
  );

  const activatePopupFooter = (
    <Stack direction="row" justify="space-between" w="100%">
      <Spacer />
      <Stack direction="row" align="center" spacing={2}>
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={onTerminateCardPopupClose}
        >
          Cancel
        </BaselaneButton>
        <BaselaneButton
          variant="filled"
          palette="danger"
          size="md"
          onClick={handleLockCardClick}
          isLoading={loading}
        >
          Terminate Card
        </BaselaneButton>
      </Stack>
    </Stack>
  );

  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP,
    bankId,
    phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleNBOnVerifyClick(otpCode, bankId, getUserSensitiveTokenData),
  };

  return (
    <>
      <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />
      <BaselaneButton
        variant="outline"
        palette="danger"
        size={isMobile ? 'md' : 'sm'}
        isDisabled={isTerminateButtonDisabled}
        isFullWidth={isMobile}
        onClick={(e) => {
          e.stopPropagation();
          if (isTokenExpired) {
            handleOTPPopupOpen(getOTP, bankId, stateFunctions);
          } else {
            onTerminateCardPopupOpen();
          }
        }}
      >
        Terminate Card
      </BaselaneButton>

      <BaselaneAlert
        showCloseButton
        header={
          <AlertHeader
            icon={<IconExclamationCircle />}
            title={title}
            iconBgStyles={alertPopupIconBgStyles}
            iconContainerStyles={alertPopupIconContainerStyles}
          />
        }
        isOpen={isTerminateCardPopupOpen}
        onClose={onTerminateCardPopupClose}
        isCentered
        body={terminateCardPopupContent}
        footer={activatePopupFooter}
        size="2xl"
      />
    </>
  );
};

export default TerminateCard;
