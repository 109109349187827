import React from 'react';
import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { baselaneSummaryCardStyles } from '../styles/baselaneSummaryCard.styles';

type BaselaneSummaryCardDefaultHeaderProps = {
  title: string | React.Node,
  subtitle?: string | React.Node,
  prefix?: string | React.Node,
  addon?: React.Node,
  rightElem?: string | React.Node,
  styles?: Object,
};

const BaselaneSummaryCardDefaultHeader = ({
  title,
  subtitle,
  prefix,
  addon,
  rightElem,
  styles,
}: BaselaneSummaryCardDefaultHeaderProps) => {
  const { cardTitleStyles, cardSubtitleStyles } = baselaneSummaryCardStyles;

  return (
    <HStack>
      <Stack gap={0}>
        <Text {...{ ...cardSubtitleStyles, ...styles?.prefix }}>{prefix}</Text>
        <HStack gap={0.5}>
          <Text {...{ ...cardTitleStyles, ...styles?.title }} as="span">
            {title}
          </Text>
          {addon}
        </HStack>
        <Text {...{ ...cardSubtitleStyles, ...styles?.subtitle }}>{subtitle}</Text>
      </Stack>
      <Spacer />

      {rightElem}
    </HStack>
  );
};

BaselaneSummaryCardDefaultHeader.defaultProps = {
  subtitle: null,
  prefix: null,
  addon: null,
  rightElem: null,
  styles: {},
};

export default BaselaneSummaryCardDefaultHeader;
