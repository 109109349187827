const unitTokenTime = 'unitTokenTime';

export default {
  write: (data, id) => localStorage.setItem(`${unitTokenTime}_${id}`, data),
  read: (id) => localStorage.getItem(`${unitTokenTime}_${id}`) || '',
  delete: () =>
    Object.keys(localStorage).forEach((localStorageItemKey) => {
      if (localStorageItemKey.includes('unitTokenTime')) {
        localStorage.removeItem(localStorageItemKey);
      }
    }),
};
