// @flow
import React, { useContext, useState } from 'react';
import moment from 'moment';
import { Box, Text } from '@chakra-ui/react';
import { BaselaneButton, BaselaneGrid, BaselaneGridTextItems } from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import getBreakPoints from '@core/utils/getBreakPoints';

import {
  updatedItemStyles,
  updatedItemTitleStyles,
  updatedItemThirdColumnStyles,
  gridStyles,
} from './styles/updatedItem.styles';
import { column } from './helpers/updatedItem.helper';

type UpdatedItemProps = {
  title: string,
  original: any,
  updated: any,
  pending: boolean,
  handleMerchantSubmit: Function,
  handleDateSubmit: Function,
  isOriginalNull: boolean,
};

function UpdatedItem({
  title,
  original,
  updated,
  pending,
  handleMerchantSubmit,
  handleDateSubmit,
  isOriginalNull,
}: UpdatedItemProps): any {
  const { isMinXL } = getBreakPoints();

  const { updateTransactionLoading } = useContext(TransactionContext);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const firstColumn = column('Original', original);
  const secondColumn = column('Current (Updated)', updated);

  const handleResetToOriginal = () => {
    setIsButtonClicked(true);
    if (title === 'Merchant') {
      const originalValue = isOriginalNull ? null : original;
      handleMerchantSubmit(originalValue);
    } else {
      const formatDate = isOriginalNull ? null : moment(original).toDate();
      handleDateSubmit(formatDate);
    }
  };

  const thirdColumn = (
    <BaselaneButton
      variant="outline"
      palette="neutral"
      size="md"
      onClick={handleResetToOriginal}
      isLoading={updateTransactionLoading && isButtonClicked}
      isDisabled={pending}
    >
      Reset To Original
    </BaselaneButton>
  );

  const gridItems = [
    {
      element: <BaselaneGridTextItems items={firstColumn} />,
    },
    {
      element: <BaselaneGridTextItems items={secondColumn} />,
    },
    {
      element: thirdColumn,
      styles: updatedItemThirdColumnStyles({ isMinXL }),
    },
  ];

  return (
    <Box {...updatedItemStyles}>
      <Text {...updatedItemTitleStyles({ isMinXL })}>{title}</Text>
      <BaselaneGrid
        gridStyles={gridStyles({ isMinXL })}
        gridItems={gridItems}
        templateColumns={isMinXL ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'}
        gap={0}
      />
    </Box>
  );
}

export default UpdatedItem;
