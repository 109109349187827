import React from 'react';
import { Flex } from '@chakra-ui/react';
import SlideIndicatorButton from './SlideIndicatorButton';

interface SlideIndicatorButtonsProps {
  isDarkMode: Boolean;
  scrollSnaps: Array;
  scrollTo: Function;
  selectedIndex: Number;
}

const SlideIndicatorButtons = ({
  isDarkMode,
  scrollSnaps,
  scrollTo,
  selectedIndex,
}: SlideIndicatorButtonsProps) => {
  return (
    <Flex direction="row" justifyContent="center">
      {scrollSnaps.map((_, index) => (
        <SlideIndicatorButton
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={() => scrollTo(index)}
          index={index}
          selectedIndex={selectedIndex}
          isDarkMode={isDarkMode}
        />
      ))}
    </Flex>
  );
};

export default SlideIndicatorButtons;
