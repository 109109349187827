import React from 'react';

type IconChevronUpProps = {
  w: Number,
  h: Number,
  color: String,
  isBold: Boolean,
};

function IconChevronUp({ w = 11, h = 7, color = '#257ED0', isBold = false }: IconChevronUpProps) {
  return isBold ? (
    <svg width={w} height={h} viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0303 5.53037C9.73744 5.82327 9.26256 5.82327 8.96967 5.53037L5.5 2.0607L2.03033 5.53037C1.73744 5.82326 1.26256 5.82326 0.969669 5.53037C0.676776 5.23747 0.676777 4.7626 0.96967 4.46971L4.92515 0.514234C5.24263 0.196752 5.75737 0.196753 6.07485 0.514234L10.0303 4.46971C10.3232 4.76261 10.3232 5.23748 10.0303 5.53037Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg width={w} height={h} viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.02843 0.861888C5.28878 0.601539 5.71089 0.601539 5.97124 0.861888L10.6379 5.52856C10.8983 5.7889 10.8983 6.21102 10.6379 6.47136C10.3776 6.73171 9.95545 6.73171 9.6951 6.47136L5.49984 2.2761L1.30458 6.47136C1.04423 6.73171 0.622116 6.73171 0.361766 6.47136C0.101417 6.21102 0.101417 5.7889 0.361766 5.52856L5.02843 0.861888Z"
        fill={color}
      />
    </svg>
  );
}

export default IconChevronUp;
