import React from 'react';
import moment from 'moment';
import { Text } from '@chakra-ui/react';
import {
  IconCheckCircleOutline,
  IconCircleDottedArrow,
  IconCircleOutlineNoPadding,
  IconClose,
  IconExclamationCircle,
} from '@icons';
import formatCurrency from '@core/utils/formatCurrency';
import getPluralizedNoun from '@core/utils/getPluralizedNoun';
import { getBottomPartStyles } from '../styles/paymentSummary.styles';

const getCurrencyDecimalSeparated = (amount, isMinXL) => {
  const [beforeDecimal, afterDecimal] = amount.split('.');
  const bottomPartStyles = getBottomPartStyles(isMinXL);

  return (
    <>
      <Text as="span" {...bottomPartStyles.amount.beforedecimal}>{`${beforeDecimal}.`}</Text>
      <Text as="span" {...bottomPartStyles.amount.afterdecimal}>
        {afterDecimal}
      </Text>
    </>
  );
};

export const getFormattedPaymentSummary = (summaryItem, isMinXL) => {
  const { state, amount, count } = summaryItem ?? {};

  const formattedAmount = formatCurrency(amount).inDollars;
  const decimalSeparatedAmount = getCurrencyDecimalSeparated(formattedAmount, isMinXL);
  const formattedNumInvoice = getPluralizedNoun(count, 'invoice');

  switch (state) {
    case 'PAYMENT_COMPLETE':
      return {
        text: 'Completed Payments',
        icon: <IconCheckCircleOutline color="#6C7884" w={13.33} h={13.33} />,
        timeStatus: 'This Month',
        amount: decimalSeparatedAmount,
        count: formattedNumInvoice,
        filters: { state, dateType: 'PAYMENT_DATE', date: 'THIS_MONTH' },
        total: { amount, count },
        segmentEvent: 'completed',
        input: {
          state,
          dateType: 'PAYMENT_DATE',
          toDate: moment().endOf('month').format('YYYY-MM-DD'),
          fromDate: moment().startOf('month').format('YYYY-MM-DD'),
        },
      };

    case 'PAYMENT_PROCESSING':
      return {
        text: 'Processing Payments',
        icon: <IconCircleDottedArrow color="#6C7884" w={13.33} h={13.33} />,
        timeStatus: 'In Transit',
        amount: decimalSeparatedAmount,
        count: formattedNumInvoice,
        filters: { state, dateType: 'PAYMENT_DATE', date: 'ALL' },
        total: { amount, count },
        segmentEvent: 'processing',
        input: {
          state,
          dateType: 'PAYMENT_DATE',
        },
      };

    case 'PAYMENT_OUTSTANDING':
      return {
        text: 'Unpaid Invoices',
        icon: (
          <IconCircleOutlineNoPadding color="#6C7884" bgColor="transparent" w={13.33} h={13.33} />
        ),
        timeStatus: 'This Month',
        amount: decimalSeparatedAmount,
        count: formattedNumInvoice,
        filters: { state, dateType: 'DUE_DATE', date: 'THIS_MONTH' },
        total: { amount, count },
        segmentEvent: 'unpaid',
        input: {
          state,
          dateType: 'DUE_DATE',
          toDate: moment().endOf('month').format('YYYY-MM-DD'),
          fromDate: moment().startOf('month').format('YYYY-MM-DD'),
        },
      };

    case 'PAYMENT_OVERDUE':
      return {
        text: 'Overdue Invoices',
        icon: <IconExclamationCircle color="#6C7884" w={13.33} h={13.33} />,
        timeStatus: 'All Time',
        amount: decimalSeparatedAmount,
        count: formattedNumInvoice,
        filters: { state, dateType: 'DUE_DATE', date: 'ALL' },
        total: { amount, count },
        segmentEvent: 'overdue',
        input: {
          state,
          dateType: 'DUE_DATE',
        },
      };

    case 'PAYMENT_FAILED':
      return {
        text: 'Failed Payment',
        icon: <IconClose color="#C93A3A" w={14} h={14} />,
        timeStatus: 'All Time', // Not being displayed in UI
        amount: formatCurrency(amount).inDollars,
        count: getPluralizedNoun(count, 'Failed Payment'),
        filters: { state, dateType: 'DUE_DATE', date: 'ALL' },
        total: { amount, count },
        segmentEvent: 'failed',
        input: {
          state,
          dateType: 'DUE_DATE',
        },
      };

    default:
      return {};
  }
};
