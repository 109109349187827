import React from 'react';
import { Box, Text, Stack, UnorderedList, ListItem, Image } from '@chakra-ui/react';
import { useShallow } from 'zustand/react/shallow';

import { BaselaneSummaryCard, BaselaneCardNew, BaselaneTooltip } from '@shared/components';
import { BaselaneSummaryCardDefaultHeader } from '@shared/components/BaselaneSummaryCard/Headers';
import { Icon12Info } from '@icons/12px';
import useCheckbookStore from '@store/Checkbook';
import formatCurrency from '@core/utils/formatCurrency';
import { formattedAccountDisplay } from '../helpers/form.helper';
import defaultCheckImg from '../assets/default-check.jpg';

const CheckPreview = () => {
  const [selectedAccount, checkbookFee] = useCheckbookStore(
    useShallow((state) => [state.selectedAccount, state.checkbookFee])
  );

  const cardSubtitle = formattedAccountDisplay(selectedAccount);

  const tooltipText = (
    <>
      <Text textStyle="xs" fontWeight="500">
        High security features
      </Text>
      <UnorderedList textStyle="xs" stylePosition="inside">
        <ListItem>Safety Hologram</ListItem>
        <ListItem>Thermochromic Ink</ListItem>
        <ListItem>Complex border and pantograph</ListItem>
      </UnorderedList>
    </>
  );

  return (
    <>
      <Box>
        <Text textStyle="xs" mb={0.75}>
          Account
        </Text>
        <BaselaneSummaryCard
          header={
            <BaselaneSummaryCardDefaultHeader
              title={selectedAccount?.nickName}
              subtitle={cardSubtitle}
            />
          }
        />
      </Box>

      <Box mt={3}>
        <Text textStyle="xs" mb={0.75}>
          Check preview
        </Text>
        <BaselaneCardNew p={0} flexDirection="column">
          <Stack
            justifyContent="center"
            alignItems="center"
            minH={201}
            w="100%"
            bg="brand.darkBlue.100"
            borderTopRadius="8px"
            gap="0"
          >
            <Image
              alt=""
              src={defaultCheckImg}
              borderTopRadius="8px"
              w="100%"
              fallback={
                <Text textStyle="sm" color="brand.neutral.600">
                  Preview failed to load
                </Text>
              }
            />
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            w="100%"
            p={1.5}
            color="brand.neutral.900"
          >
            <Stack direction="row" alignItems="center">
              <Text textStyle="sm">80 high-security checks</Text>
              <BaselaneTooltip label={tooltipText} placement="top">
                <Icon12Info />
              </BaselaneTooltip>
            </Stack>
            <Text fontWeight="500">{formatCurrency(checkbookFee).rounded}</Text>
          </Stack>
        </BaselaneCardNew>
      </Box>
    </>
  );
};

export default CheckPreview;
