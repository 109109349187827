import React from 'react';

import { BaselaneButton, BaselaneDrawer, BaselaneFlowComplete } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';

type SuccessProps = {
  closeEvent: Function,
};

const Success = ({ closeEvent }: SuccessProps) => {
  const { DrawerBody, DrawerFooter } = BaselaneDrawer;
  const { isMax576 } = getBreakPoints();

  return (
    <>
      <DrawerBody p={isMax576 ? 2 : 3}>
        <BaselaneFlowComplete
          type="success"
          title="Order received"
          description1="Once the order is processed, you will receive an email and the checks will be delivered in 10-14 business days."
        />
      </DrawerBody>
      <DrawerFooter
        px={isMax576 ? 2 : 3}
        py={2}
        w="100%"
        boxShadow="none"
        borderTop="1px solid"
        borderColor="brand.darkBlue.200"
      >
        <BaselaneButton size="md" variant="outline" palette="neutral" onClick={closeEvent}>
          Done
        </BaselaneButton>
      </DrawerFooter>
    </>
  );
};

export default Success;
