import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Text, HStack, Stack, Spacer } from '@chakra-ui/react';
import IconX from '@icons/legacy/IconX';
import BaselaneButton from '../../BaselaneButton';
import {
  filterWrapperHeaderStyles,
  filterByTextStyles,
  footerContainerStyles,
  footerSelectedTextStyles,
} from './styles/filterWrapper.styles';

type FilterWrapperProps = {
  title: string,
  isMulti: boolean,
  selectedStagedOptions: Array<Object>,
  filterCustomComponent?: any,
  onClear?: Function,
  onApply?: Function,
  children: any,
  isInvalidInput?: Boolean,
  clearButtonText: String,
  animationVariant?: String,
  hideClearButton?: Boolean,
  filterCustomHeaderComponentRenderer?: Function,
  dropDown?: Function,
};

const FilterWrapper = forwardRef(
  (
    {
      title,
      isMulti,
      selectedStagedOptions,
      filterCustomComponent,
      onClear,
      onApply,
      children,
      isInvalidInput,
      clearButtonText,
      animationVariant,
      hideClearButton,
      filterCustomHeaderComponentRenderer,
      dropDown,
    }: FilterWrapperProps,
    ref
  ) => {
    const firstChildRef = useRef();
    const [parentElement, setParentElement] = useState(null);

    /**
     * Note: The transition code below is only activated if
     * animationVariant prop has a value matching transition
     * function names found below.
     */

    /**
     * Clears transition classes after the animation completes.
     */
    const handleTransitionEnd = (e) => {
      e.target.classList.remove('dropdown-close-transition', 'dropdown-open-transition');
    };

    /**
     * Sets up transition end listeners, and cleans up when component unmounts.
     * */
    useLayoutEffect(() => {
      if (firstChildRef) {
        setParentElement(firstChildRef?.current?.parentElement);
        firstChildRef?.current?.parentElement.addEventListener(
          'transitionend',
          handleTransitionEnd
        );
      }
      return () => {
        if (firstChildRef?.current?.parentElement) {
          firstChildRef.current.parentElement.removeEventListener(
            'transitionend',
            handleTransitionEnd
          );
        }
      };
    }, [firstChildRef]);

    /**
     * Transition functions for closing/opening
     * the area defined by the filter wrapper.
     */

    const expandedSize = 580;
    const collapsedSize = 320;
    const openTransition = 'dropdown-open-transition';
    const closeTransition = 'dropdown-close-transition';

    const beginOpened = () => {
      parentElement.classList.remove(openTransition, closeTransition);
      parentElement.style.height = `${expandedSize}px`;
      parentElement.style.minHeight = `${expandedSize}px`;
    };

    const beginClosed = () => {
      parentElement.classList.remove(openTransition, closeTransition);
      parentElement.style.height = `${collapsedSize}px`;
      parentElement.style.minHeight = `${collapsedSize}px`;
    };

    const open = () => {
      if (parentElement.clientHeight < expandedSize) {
        parentElement.classList.add(openTransition);
        parentElement.style.height = `${expandedSize}px`;
        parentElement.style.minHeight = `${expandedSize}px`;
      } else {
        beginOpened();
      }
    };

    const close = () => {
      if (parentElement.clientHeight > collapsedSize) {
        parentElement.classList.add(closeTransition);
        parentElement.style.height = `${collapsedSize}px`;
        parentElement.style.minHeight = `${collapsedSize}px`;
      } else {
        beginClosed();
      }
    };

    /**
     * Transition functions for closing/opening
     * the area defined by the filter wrapper.
     */
    const transitions = {
      beginOpened,
      beginClosed,
      open,
      close,
    };

    /**
     * Applies appropriate transition function
     * if parent component provides an animationVariant.
     */
    useEffect(() => {
      if (!parentElement) return;
      if (!animationVariant) return;
      transitions[animationVariant]();
    }, [animationVariant, parentElement]);

    useImperativeHandle(ref, () => ({
      onClear,
    }));

    return (
      <>
        {/* Header */}
        <Stack ref={firstChildRef} direction="row" {...filterWrapperHeaderStyles}>
          {(filterCustomHeaderComponentRenderer &&
            filterCustomHeaderComponentRenderer(dropDown)) || (
            <>
              <Text {...filterByTextStyles}>Filter By {title}</Text>
              <Spacer />
              {!hideClearButton && (
                <BaselaneButton
                  variant="transparent"
                  palette="neutral"
                  leftIcon={<IconX color="#9BA9C1" />}
                  onClick={onClear}
                >
                  {clearButtonText}
                </BaselaneButton>
              )}
            </>
          )}
        </Stack>

        {/* Optional Custom Component */}
        {filterCustomComponent}

        {/* List */}
        {children}

        {/* Footer */}
        {isMulti && (
          <HStack {...footerContainerStyles}>
            <Spacer />
            {selectedStagedOptions?.length > 0 && (
              <Text {...footerSelectedTextStyles}>{selectedStagedOptions.length} selected</Text>
            )}
            <BaselaneButton
              variant="filled"
              palette="primary"
              isDisabled={isInvalidInput && hideClearButton}
              onClick={onApply}
            >
              Apply
            </BaselaneButton>
          </HStack>
        )}
      </>
    );
  }
);

FilterWrapper.defaultProps = {
  filterCustomComponent: null,
  onClear: () => {},
  onApply: () => {},
  isInvalidInput: false,
  animationVariant: null,
  hideClearButton: false,
  filterCustomHeaderComponentRenderer: null,
  dropDown: () => {},
};

export default FilterWrapper;
