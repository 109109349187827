import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';
// import './loader.scss'; <-- breaks storybook

const defaultStyles = (className) => {
  if (className === 'sl-center') {
    return {
      position: 'relative',
      top: '50%',
      left: '50%',
    };
  }
  return {
    position: 'fixed',
    width: '64px',
    height: '64px',
    left: '50%',
    marginLeft: '-32px',
    top: '50%',
    marginTop: '-32px',
  };
};

type SlLoaderProps = {
  showOverlay: boolean,
  className: string,
  overlayStyles: Object,
  styles: Object,
  size: String,
};

function SlLoader({
  showOverlay = false,
  className = 'sl-spinner',
  overlayStyles = {},
  styles = {},
  size = 'xl',
}: SlLoaderProps) {
  return (
    <>
      {showOverlay && (
        <Box
          zIndex={1}
          position="fixed"
          top={0}
          left={0}
          w="100%"
          h="100%"
          bg="brand.darkBlue.250"
          opacity="0.5"
          {...overlayStyles}
        />
      )}
      <Spinner
        {...defaultStyles(className)}
        zIndex={1}
        thickness="4px"
        speed="1.00s"
        emptyColor="brand.darkBlue.250"
        color="brand.neutral.700"
        size={size}
        {...styles}
      />
    </>
  );
}

export default SlLoader;
