import React from 'react';
import { BaselaneButton, BaselaneMessageCard } from '@shared/components';
import SlLoader from '../../../../Loader';

type EmptyBodyComponentProps = {
  handleMissingUnitDataListClose: Function,
  isLoadingDataCollectionList: Boolean,
};

const EmptyBodyComponent = ({
  handleMissingUnitDataListClose,
  isLoadingDataCollectionList,
}: EmptyBodyComponentProps) => {
  if (isLoadingDataCollectionList) {
    return <SlLoader />;
  }

  return (
    <>
      <BaselaneMessageCard
        isVertical
        title="All done!"
        text="You can close the dialog now."
        containerStyles={{ mt: '16px', mb: '24px', w: '100%' }}
      />
      <BaselaneButton
        variant="filled"
        palette="primary"
        size="lg"
        onClick={() => {
          handleMissingUnitDataListClose();
        }}
      >
        Close
      </BaselaneButton>
    </>
  );
};

export default EmptyBodyComponent;
