import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HStack } from '@chakra-ui/react';
import { BaselaneButton, BaselaneCardStack } from '@shared/components';
import { Icon16PlusCircle } from '@icons/16px';
import PaymentMethodCard from './PaymentMethodCard';
import {
  hasAPaymentMethod,
  hasSinglePaymentMethod,
  isPaymentMethodDefined,
} from '../helpers/validation.helpers';
import { generateDescription } from '../helpers/content.helpers';

type Address = {
  address: String,
  unitNumber?: String,
  city: String,
  state: String,
  zipcode: String,
};

type RecipientPaymentMethodACH = {
  id?: String,
  name: String,
  routingNumber: String,
  accountNumber: String,
  accountType: 'checking' | 'savings' | 'not_sure',
};

type RecipientPaymentMethodWire = Address & {
  id?: String,
  name: String,
  routingNumber: String,
  accountNumber: String,
};

type RecipientPaymentMethodCheck = Address & {
  id?: String,
  name: String,
};

export type PaymentMethods = {
  achPaymentMethods: RecipientPaymentMethodACH | null,
  wirePaymentMethods: RecipientPaymentMethodWire | null,
  checkPaymentMethods: RecipientPaymentMethodCheck | null,
};

type PaymentMethodStackProps = {
  paymentMethods?: PaymentMethods,
  setPaymentMethods?: Function,
};

const PaymentMethodStack = ({ paymentMethods, setPaymentMethods }: PaymentMethodStackProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Navigate to payment method details drawer, any
   * data matching form fields for the payment method in the state parameter
   * will be passed on to that drawer to prefill the forms on it.
   */
  const handleDetails = (method) => {
    navigate(
      {
        pathname: `${method.split('PaymentMethods').join('')}`,
      },
      {
        state: {
          from: location?.pathname,
          paymentMethods: { [method]: paymentMethods[method] },
          isOnlyPaymentMethod: hasSinglePaymentMethod(paymentMethods),
        },
      }
    );
  };

  /**
   * When the location state paymentMethod changes,
   * after returning from a paymnent type details
   * drawer, we update the overall payment types
   * stored by this component to overwrite the old data.
   */
  useEffect(() => {
    const { state } = location || {};
    const { paymentMethod } = state || {};
    setPaymentMethods((originalPaymentMethods: PaymentMethods) => ({
      ...originalPaymentMethods,
      ...paymentMethod,
    }));
  }, [location?.state?.paymentMethod]);

  const items = [];

  // ACH Payment Method - Card with Button

  (!hasAPaymentMethod(paymentMethods) ||
    isPaymentMethodDefined(paymentMethods?.achPaymentMethods)) &&
    items.push({
      id: 'achPaymentMethods',
      content: (
        <PaymentMethodCard
          name="ACH"
          description={generateDescription(
            paymentMethods?.achPaymentMethods?.bankName,
            paymentMethods?.achPaymentMethods?.accountType,
            paymentMethods?.achPaymentMethods?.accountNumber
          )}
          onAddClick={() => handleDetails('achPaymentMethods')}
          onEditClick={() => handleDetails('achPaymentMethods')}
          paymentMethodExists={isPaymentMethodDefined(paymentMethods?.achPaymentMethods)}
        />
      ),
    });

  // Wire Payment Method - Card with Button

  (!hasAPaymentMethod(paymentMethods) ||
    isPaymentMethodDefined(paymentMethods?.wirePaymentMethods)) &&
    items.push({
      id: 'wirePaymentMethods',
      content: (
        <PaymentMethodCard
          name="Wire"
          description={generateDescription(
            paymentMethods?.wirePaymentMethods?.bankName,
            paymentMethods?.wirePaymentMethods?.accountType,
            paymentMethods?.wirePaymentMethods?.accountNumber
          )}
          onAddClick={() => handleDetails('wirePaymentMethods')}
          onEditClick={() => handleDetails('wirePaymentMethods')}
          paymentMethodExists={isPaymentMethodDefined(paymentMethods?.wirePaymentMethods)}
        />
      ),
    });

  // Check Payment Method - Card with Button

  const checkPaymentMethodsDescription = paymentMethods?.checkPaymentMethods?.address;

  (!hasAPaymentMethod(paymentMethods) ||
    isPaymentMethodDefined(paymentMethods?.checkPaymentMethods)) &&
    items.push({
      id: 'checkPaymentMethods',
      content: (
        <PaymentMethodCard
          name="Check"
          description={checkPaymentMethodsDescription}
          onAddClick={() => handleDetails('checkPaymentMethods')}
          onEditClick={() => handleDetails('checkPaymentMethods')}
          paymentMethodExists={isPaymentMethodDefined(paymentMethods?.checkPaymentMethods)}
        />
      ),
    });

  return (
    <>
      <BaselaneCardStack items={items} />
      {hasAPaymentMethod(paymentMethods) && (
        <HStack mt={1}>
          {!isPaymentMethodDefined(paymentMethods?.achPaymentMethods) && (
            // ACH Link Button
            <BaselaneButton
              size="md"
              variant="transparent"
              palette="primary"
              leftIcon={<Icon16PlusCircle />}
              onClick={() => handleDetails('achPaymentMethods')}
            >
              ACH details
            </BaselaneButton>
          )}
          {!isPaymentMethodDefined(paymentMethods?.wirePaymentMethods) && (
            // Wire Link Button
            <BaselaneButton
              size="md"
              variant="transparent"
              palette="primary"
              leftIcon={<Icon16PlusCircle />}
              onClick={() => handleDetails('wirePaymentMethods')}
            >
              Wire details
            </BaselaneButton>
          )}
          {!isPaymentMethodDefined(paymentMethods?.checkPaymentMethods) && (
            // Check Link Button
            <BaselaneButton
              size="md"
              variant="transparent"
              palette="primary"
              leftIcon={<Icon16PlusCircle />}
              onClick={() => handleDetails('checkPaymentMethods')}
            >
              Check details
            </BaselaneButton>
          )}
        </HStack>
      )}
    </>
  );
};

PaymentMethodStack.defaultProps = {
  paymentMethods: null,
  setPaymentMethods: () => {},
};

export default PaymentMethodStack;
