import React from 'react';

function IconTag({ w, h, color }: { w?: string, h?: string, color?: string }) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.889 4.111v.008M15 4.111v3.89c0 .397-.152.796-.456 1.099L9.1 14.544a1.555 1.555 0 01-2.2 0L1.455 9.1a1.556 1.556 0 010-2.2L6.9 1.456A1.55 1.55 0 018 1h3.889A3.111 3.111 0 0115 4.111z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconTag.defaultProps = {
  w: '16',
  h: '16',
  color: '#398311',
};

export default IconTag;
