import React from 'react';
import { HStack, ListItem } from '@chakra-ui/react';

export const ListItemElement = ({ children, ...props }: { children: any }) => (
  <ListItem color="brand.neutral.700" fontSize="sm" textAlign="left" {...props}>
    {children}
  </ListItem>
);

const customListItemStyles = {
  align: 'center',
  justify: 'flex-start',
  textStyle: 'sm',
  color: 'brand.neutral.700',
};

export const CustomListItemElement = ({ children, ...props }: { children: any }) => (
  <HStack {...customListItemStyles} {...props}>
    {children}
  </HStack>
);
