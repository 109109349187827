import React from 'react';
import { Icon16Accounts } from '@icons/16px';
import getBreakPoints from '@core/utils/getBreakPoints';
import DisplayInputDefaultAccountContent from './DisplayInputDefaultAccountContent';

type DisplayInputDefaultAccountProps = {
  selectedOptions: Array<Object>,
  handleContentRendererClick: Function,
  placeholder: string,
  title: string,
  handleClearClick: Function,
  classNames: Array<string>,
};

const DisplayInputDefaultAccount = ({
  selectedOptions,
  handleContentRendererClick,
  placeholder,
  title,
  handleClearClick,
  classNames,
}: DisplayInputDefaultAccountProps) => {
  const { isMin1150 } = getBreakPoints();
  return (
    <DisplayInputDefaultAccountContent
      selectedOptions={selectedOptions}
      handleContentRendererClick={handleContentRendererClick}
      placeholder={placeholder}
      title={title}
      handleClearClick={handleClearClick}
      classNames={classNames}
    >
      {isMin1150 && <Icon16Accounts />}
    </DisplayInputDefaultAccountContent>
  );
};

export default DisplayInputDefaultAccount;
