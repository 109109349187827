import React, { useEffect, useRef, useState } from 'react';
import { Text, Stack, useToast } from '@chakra-ui/react';
import {
  BaselaneDivider,
  BaselaneFilterCard,
  BaselaneFilterPill,
  BaselaneRangeDatePicker,
} from '@core/components/Shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  PAYMENT_STATUS,
  VIEW_BY,
  DATE_RANGE,
  PAYMENT_STATUS_SEGMENT_EVENTS,
  VIEW_BY_SEGMENT_EVENTS,
  DATE_RANGE_SEGMENT_EVENTS,
  getFormattedInput,
  checkIsDirty,
} from './helpers/filterPopup.helpers';
import { popupStyles, animationStyles } from './styles/filterPopup.styles';

type FilterPopupProps = {
  invoiceFilters: Object,
  showFilterPopup: boolean,
  showFilterPopupAnimation: boolean,
  handleFilterPopupClose: Function,
  setInvoiceFilters: Function,
  getLeaseInvoices: Function,
  leaseInvoicesLoading: boolean,
  setHasFilterChanged: Function,
};

function FilterPopup({
  invoiceFilters,
  showFilterPopup,
  showFilterPopupAnimation,
  handleFilterPopupClose,
  setInvoiceFilters,
  getLeaseInvoices,
  leaseInvoicesLoading,
  setHasFilterChanged,
}: FilterPopupProps): any {
  const { isMinXL } = getBreakPoints();
  const datepickerRef = useRef();
  const toast = useToast();

  const { state, dateType, date, customDate } = invoiceFilters ?? {};
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(state);
  const [selectedViewBy, setSelectedViewBy] = useState(dateType);
  const [selectedDateRange, setSelectedDateRange] = useState(date);
  const [selectedDate, setSelectedDate] = useState({
    start: customDate?.start,
    end: customDate?.end,
  });

  // Toast
  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: `Something went wrong, failed to update the filter`,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  // Clean-up Func
  const cleanup = () => {
    setSelectedPaymentStatus(state);
    setSelectedViewBy(dateType);
    setSelectedDateRange(date);
    setSelectedDate({
      start: customDate?.start,
      end: customDate?.end,
    });
  };

  // Handle Functions
  const handlePaymentStatusChange = (status) => {
    setSelectedPaymentStatus(status.id);
    if (status.id !== 'PAYMENT_COMPLETE' && status.id !== 'PAYMENT_PROCESSING') {
      setSelectedViewBy('DUE_DATE');
    }
  };

  const handleViewByChange = (view) => {
    setSelectedViewBy(view.id);
  };

  const handleDateRangeChange = (range) => {
    setSelectedDateRange(range.id);

    if (range.id === 'CUSTOM') {
      setTimeout(() => {
        datepickerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    } else {
      setSelectedDate({ start: null, end: null });
    }
  };

  const handleSubmit = () => {
    const input = getFormattedInput({
      selectedPaymentStatus,
      selectedViewBy,
      selectedDateRange,
      selectedDate,
    });
    const newInvoiceFilters = {
      state: selectedPaymentStatus,
      dateType: selectedViewBy,
      date: selectedDateRange,
    };
    if (selectedDate.start && selectedDate.end) {
      newInvoiceFilters.customDate = {
        start: selectedDate.start,
        end: selectedDate.end,
      };
    }
    setInvoiceFilters(newInvoiceFilters);
    setSelectedPaymentStatus(selectedPaymentStatus);
    setSelectedViewBy(selectedViewBy);
    setSelectedDateRange(selectedDateRange);
    setSelectedDate({
      start: selectedDate.start,
      end: selectedDate.end,
    });
    const isDirty = checkIsDirty({
      invoiceFilters,
      selectedPaymentStatus,
      selectedViewBy,
      selectedDateRange,
    });
    if (isDirty) {
      setHasFilterChanged(true);
    }

    getLeaseInvoices({ variables: { input } }).then((res) => {
      // this code gets triggered only on submit - not on refetch
      if (res?.error) {
        showErrorToast();
      } else {
        sendSegmentEvent('rc_page_invoice_drawer_apply_filter', {
          date_range: DATE_RANGE_SEGMENT_EVENTS[selectedDateRange],
          payment_status: PAYMENT_STATUS_SEGMENT_EVENTS[selectedPaymentStatus],
          view_by: VIEW_BY_SEGMENT_EVENTS[selectedViewBy],
        });

        handleFilterPopupClose();
      }
    });
  };

  // Helper Functions - Get Data Ready
  const getPaymentStatus = () => {
    const paymentStatusWithOnClick = PAYMENT_STATUS.map((status) => {
      return { ...status, onClick: () => handlePaymentStatusChange(status) };
    });

    return paymentStatusWithOnClick;
  };

  const getViewBy = () => {
    const viewByWithOnClick = VIEW_BY.map((view) => {
      return { ...view, onClick: () => handleViewByChange(view) };
    });

    return viewByWithOnClick;
  };

  const getDateRange = () => {
    const dateRangeWithOnClick = DATE_RANGE.map((range) => {
      return { ...range, onClick: () => handleDateRangeChange(range) };
    });

    return dateRangeWithOnClick;
  };

  const paymentStatusArr = getPaymentStatus();
  const viewByArr = getViewBy();
  const dateRangeArr = getDateRange();

  // Set Disabled States
  const isViewByDisabled =
    selectedPaymentStatus !== 'PAYMENT_COMPLETE' && selectedPaymentStatus !== 'PAYMENT_PROCESSING';

  const isSubmitDisabled =
    selectedDateRange === 'CUSTOM' && (!selectedDate.start || !selectedDate.end);

  // Destructure Imported Styles
  const { filter, divider } = popupStyles({ isMinXL });

  useEffect(() => {
    cleanup();
  }, []);

  return (
    <BaselaneFilterCard
      {...{
        handleFilterPopupClose: () => {
          cleanup();
          handleFilterPopupClose();
        },
        showFilterPopup,
        showFilterPopupAnimation,
        headerText: 'Filter Invoices',
        animationStyles,
        handleSubmit,
        customStyles: filter.customStyles,
        isSubmitting: leaseInvoicesLoading,
        isSubmitDisabled,
      }}
    >
      {/* Payment Status */}
      <Stack {...filter.container}>
        <Text {...filter.title}>Payment Status</Text>
        <BaselaneFilterPill
          {...{
            name: 'payment-status',
            options: paymentStatusArr,
            selectedId: selectedPaymentStatus,
            type: 'vertical',
            hasIcon: true,
            numColumn: isMinXL ? 2 : 4,
          }}
        />
      </Stack>

      {/* View By */}
      <Stack {...filter.container}>
        <Text {...filter.title}>View By</Text>
        <BaselaneFilterPill
          {...{
            name: 'view-by',
            options: viewByArr,
            selectedId: selectedViewBy,
            type: 'toggle',
            numColumn: 2,
            isDisabled: isViewByDisabled,
          }}
        />
      </Stack>

      <BaselaneDivider styles={divider} />

      {/* Date Range */}
      <Stack {...filter.container}>
        <Text {...filter.title}>Date Range</Text>
        <BaselaneFilterPill
          {...{
            name: 'date-range',
            options: dateRangeArr,
            selectedId: selectedDateRange,
            numColumn: isMinXL ? 3 : 5,
          }}
        />
      </Stack>

      {/* Custom Date */}
      {selectedDateRange === 'CUSTOM' && (
        <Stack ref={datepickerRef} {...filter.custom.container}>
          <BaselaneRangeDatePicker
            {...{
              startDate: selectedDate?.start,
              endDate: selectedDate?.end,
              years: { start: 2000, end: 2043 },
              hideTodayButton: true,
              handleCalendarClose: ({ startDate, endDate }) => {
                setSelectedDate({
                  start: startDate,
                  end: endDate,
                });
              },
            }}
          />
        </Stack>
      )}
    </BaselaneFilterCard>
  );
}

export default FilterPopup;
