export const filterWrapperHeaderStyles = {
  mb: 0,
  px: 2,
  pt: 2,
  pb: 0,
  alignItems: 'center',
};

export const filterByTextStyles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.900',
};

export const footerContainerStyles = {
  borderBottomRightRadius: 'sm',
  borderBottomLeftRadius: 'sm',
  borderTop: '1px solid',
  borderTopColor: 'brand.darkBlue.200',
  py: 1,
  px: 2,
};

export const footerSelectedTextStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.500',
};

export const footerButtonStyles = {
  ml: '16px !important',
};
