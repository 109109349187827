import { gql } from '@apollo/client';

export const USER_VERIFICATION = gql`
  query getOTP($bankId: Float!) {
    unitAPIVerification(bankId: $bankId)
  }
`;

export const USER_SENSITIVE_TOKEN = gql`
  query getUserSensitiveTokenData($otpCode: String!, $bankId: Float!) {
    unitAPISensitiveToken(otpCode: $otpCode, bankId: $bankId) {
      expiresIn
      token
    }
  }
`;

export const NON_SENSITIVE_TOKEN = gql`
  query unitAPINonSensitiveToken($bankId: Float!) {
    unitAPINonSensitiveToken(bankId: $bankId) {
      expiresIn
      token
    }
  }
`;
