import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Stack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { uniq } from 'lodash';

import { GET_PARTNERS } from './queries/partners';
import Header from './Header';
import FeaturePartners from './FeaturePartners';
import MorePartners from './MorePartners';

const Partnerships = () => {
  const [partnerData, setPartnerData] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [chipFilterMenuItems, setChipFilterMenuItems] = useState([]);
  const [refreshHeader, setRefreshHeader] = useState(false);

  // call to BE to get the partner cards and renove nock
  const { loading, data } = useQuery(GET_PARTNERS);

  useEffect(() => {
    if (partnerData.length > 0) {
      // First extract all the tags, uniquefy them
      const rawTags = [];
      partnerData.forEach((item) => {
        item.tags.forEach((tag) => {
          rawTags.push(tag);
        });
      });
      const newAllTags = uniq(rawTags);
      setAllTags(newAllTags);
      setSelectedTags(newAllTags);
      // define chip filter menu items
      const newChipFilterMenuItems = [];
      newAllTags.forEach((item, index) => {
        newChipFilterMenuItems.push({
          id: `${index}`,
          label: item.charAt(0) + item.substring(1).toLowerCase(),
          isSelected: false,
        });
      });
      setChipFilterMenuItems(newChipFilterMenuItems);
    }
  }, [partnerData, loading]);

  useEffect(() => {
    if (data?.partner?.length > 0) {
      const sortedPartners = data.partner.map((item) => item);
      sortedPartners.sort((a, b) => {
        return b.importanceOrder - a.importanceOrder;
      });
      setPartnerData(sortedPartners);
    }
  }, [data?.partner]);

  // filter menu change
  const handleFilterSelection = (index) => {
    const newItems = chipFilterMenuItems;
    newItems[index].isSelected = !newItems[index].isSelected;
    const newSelectedTags = [];
    newItems
      .filter((item) => item.isSelected)
      .forEach((item) => {
        newSelectedTags.push(item.label.charAt(0) + item.label.substring(1).toLowerCase());
      });
    setSelectedTags(newSelectedTags.length > 0 ? newSelectedTags : allTags);
    setChipFilterMenuItems(newItems);
    setRefreshHeader(!refreshHeader);
  };

  return (
    <Stack gap="0">
      <Header
        tags={chipFilterMenuItems}
        handleFilterSelection={handleFilterSelection}
        refreshHeader={refreshHeader}
      />
      <FeaturePartners selectedTags={selectedTags} partnerCards={partnerData} />
      <MorePartners selectedTags={selectedTags} tags={allTags} partnerCards={partnerData} />
      <Outlet />
    </Stack>
  );
};

export default Partnerships;
