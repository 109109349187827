// @flow
import React from 'react';
import { Stack, Spacer } from '@chakra-ui/react';
import Card from './BaselaneCard';
import { fontStyles, cardOneStyles } from './styles/card.style';

type Props = {
  children?: any,
  headerRight?: any,
  headerLeft?: any,
  containerStyles?: Object,
  headerStyles?: Object,
  contentStyles?: Object,
  headerRightStyles?: Object,
  headerLeftStyles?: Object,
  customHeaderStyles?: Object,
  spacerStyles?: Object,
};

function BaselaneHeaderCard({
  children,
  headerRight,
  headerLeft,
  containerStyles: cStyles,
  headerRightStyles,
  headerLeftStyles: hlStyles,
  headerStyles: hStyles,
  contentStyles: bStyles,
  customHeaderStyles,
  spacerStyles,
}: Props): any {
  const { header, content } = cardOneStyles;
  const containerStyles = {
    ...(cStyles || {}),
  };
  const headerStyles = {
    ...header,
    ...(hStyles || {}),
  };
  const contentStyles = {
    ...content,
    ...(bStyles || {}),
  };

  const headerLeftStyles = {
    ...fontStyles.header,
    ...hlStyles,
  };
  return (
    <Card styles={containerStyles}>
      <Stack direction="row" {...headerStyles} {...customHeaderStyles}>
        <Stack direction="row" {...headerLeftStyles}>
          {headerLeft}
        </Stack>
        <Spacer {...spacerStyles} />
        <Stack direction="row" {...headerRightStyles}>
          {headerRight}
        </Stack>
      </Stack>
      <Stack {...contentStyles}>{children}</Stack>
    </Card>
  );
}

BaselaneHeaderCard.defaultProps = {
  children: null,
  headerRight: null,
  headerLeft: null,
  spacerStyles: Object,
  containerStyles: Object,
  headerStyles: Object,
  contentStyles: Object,
  headerRightStyles: Object,
  headerLeftStyles: Object,
  customHeaderStyles: Object,
};

export default BaselaneHeaderCard;
