// @flow
import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { BaselaneErrorCard } from '@shared/components';
import { emailVerificaton } from '@core/Services/CreateAccount';
import { signOut } from '@core/Services/Firebase/firebase';
import '@shared/components/BaselaneErrorCard/styles/errorcard.style.scss';

function UnverifiedUserPage() {
  const { state = {} } = useLocation();
  const [showResendError, setShowResendError] = useState(false);
  const [showResendSuccess, setShowResendSuccess] = useState(false);

  const handleLogin = () => {
    signOut().then(() => {
      window.location.href = '/login';
    });
  };

  const handleResendVerificationEmail = () => {
    setShowResendError(false);
    setShowResendSuccess(false);
    const bodyParams = { email: state.email };
    emailVerificaton(bodyParams)
      .then(() => {
        setShowResendError(false);
        setShowResendSuccess(true);
      })
      .catch((error) => {
        console.error(error);
        setShowResendSuccess(false);
        setShowResendError(true);
      });
  };

  return (
    <Box mt="32px">
      <BaselaneErrorCard
        title="Email Not Verified"
        subtitle1="Please verify your email to login to Baselane"
        subtitle2={null}
        btn1Label="Click Here to Login Once Verified"
        handleBtn1Click={handleLogin}
        handleBtn2Click={handleResendVerificationEmail}
        btn2Label="Resend Verification Email"
        hasError={showResendError}
        errorMessage="Please try again in a minute."
        onSuccess={showResendSuccess}
        successMessage="Verification email has been resent successfully."
      />
    </Box>
  );
}

export default UnverifiedUserPage;
