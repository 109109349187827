import React from 'react';

type IconOfficeBuildingProps = {
  w: Number,
  h: Number,
  color: String,
};

function IconOfficeBuilding({ w = 24, h = 24, color = '#9FD8FF' }: IconOfficeBuildingProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21C2 20.4477 2.44772 20 3 20H4V5C4 4.20435 4.31607 3.44129 4.87868 2.87868ZM6 20V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H17C17.2652 4 17.5196 4.10536 17.7071 4.29289C17.8946 4.48043 18 4.73478 18 5V20H15V16C15 15.4696 14.7893 14.9609 14.4142 14.5858C14.0391 14.2107 13.5304 14 13 14H11C10.4696 14 9.96086 14.2107 9.58579 14.5858C9.21071 14.9609 9 15.4696 9 16V20H6ZM11 20H13V16H11V20ZM8 7C8 6.44772 8.44772 6 9 6H10C10.5523 6 11 6.44772 11 7C11 7.55228 10.5523 8 10 8H9C8.44772 8 8 7.55228 8 7ZM13 7C13 6.44772 13.4477 6 14 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H14C13.4477 8 13 7.55228 13 7ZM8 11C8 10.4477 8.44772 10 9 10H10C10.5523 10 11 10.4477 11 11C11 11.5523 10.5523 12 10 12H9C8.44772 12 8 11.5523 8 11ZM13 11C13 10.4477 13.4477 10 14 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H14C13.4477 12 13 11.5523 13 11Z"
        fill={color}
      />
    </svg>
  );
}

export default IconOfficeBuilding;
