import React from 'react';
import { Flex, VStack, useMediaQuery } from '@chakra-ui/react';
import BaselaneHeader from '../BaselaneHeader';
import ReferralDetails from './ReferralDetails';
import SignUpA from '../SignUpA';
import SignUpC from '../SignUpC';
import { signupContainerStyles, signupPageStyles } from '../../styles/signup.styles';
import useReferralSource from '../../useReferralSource';

const ReferralWrapper = () => {
  // this breakpoint is determined by the minimum height of the form, making sure that the
  // footer doesn't overlap it
  const [isMobile] = useMediaQuery('(max-width: 767px)', { ssr: false });
  const signUpVariant = isMobile ? 'C' : 'A';

  const { referrer, isReferral } = useReferralSource();

  const signupView = () => {
    const props = { referrer, isReferral, signUpVariant };
    if (isMobile) {
      return <SignUpC {...props} />;
    }
    return <SignUpA {...props} />;
  };

  return (
    <Flex {...signupPageStyles(isReferral)}>
      <VStack {...signupContainerStyles}>
        <BaselaneHeader signUpVariant={signUpVariant} />
        {/* A/B section start */}
        {signupView()}
        {/* A/B section end */}
      </VStack>
      {isReferral && <ReferralDetails />}
    </Flex>
  );
};

export default ReferralWrapper;
