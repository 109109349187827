import React from 'react';

type IconPropertyAnalyticsProps = {
  w?: Number,
  h?: Number,
  color?: String,
};

function IconPropertyAnalytics({
  w = 24,
  h = 24,
  color = 'currentColor',
}: IconPropertyAnalyticsProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} fill="none">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 1v10m16 6H2.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C1 16.24 1 15.96 1 15.4V11m0 0 2.58-2.211c.33-.283.494-.424.681-.486a1 1 0 0 1 .512-.031c.193.039.374.16.736.4L7.98 10.32c.365.244.547.365.742.404a1 1 0 0 0 .516-.034c.188-.063.353-.208.683-.497l7.065-6.181m0 0v0c.004-.004.001-.012-.005-.012H12m4.987.012L17 9"
      />
    </svg>
  );
}

IconPropertyAnalytics.defaultProps = {
  w: 24,
  h: 24,
  color: 'currentColor',
};

export default IconPropertyAnalytics;
