const beforeBeginningPointers = {
  rent_collection: {
    subMessage: '2 mins to set up rent collection',
    points: [
      { text: 'Rental property address' },
      {
        text: 'Rent collection terms',
        subText:
          'Rent start & end date, rent & security deposit amounts, due date, fee amounts, etc.',
      },
      {
        text: 'Bank account to deposit rent',
        subText:
          'Open a Baselane Banking account or securely connect an external account to collect rent',
      },
      {
        text: 'Tenant information',
        subText: 'Tenant first name, last name, and email',
      },
    ],
  },
  landlord_banking: {
    subMessage: '3 mins to apply for a banking account',
    sectionedPoints: [
      {
        heading: 'If individual account (sole proprietorship)',
        innerPoints: [
          {
            text: 'Individual information',
            subText: 'Personal and contact information',
          },
        ],
      },
      {
        heading: 'If business account',
        innerPoints: [
          {
            text: 'Business Information',
            subText: 'Entity type, EIN and contact information',
          },
          {
            text: 'Officer & Owner Information',
            subText:
              'Personal information of one officer and owner(s): name, contact information and SSN',
          },
        ],
      },
    ],
  },
  bookkeeping: {
    subMessage: '1 min to get started with bookkeeping',
    points: [
      { text: 'Rental property address' },
      {
        text: 'Online banking credentials',
        subText:
          'Securely connect any external bank account to automatically track all transactions',
      },
    ],
  },
};

export default beforeBeginningPointers;
