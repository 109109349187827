export const contentContainerStyles = (isMobile) => {
  return {
    flex: '1 1 auto',
    justifyContent: 'space-between',
    cursor: isMobile ? 'pointer' : 'initial',
    alignItems: isMobile ? 'flex-start' : 'center',
    color: 'white',
    spacing: 0.5,
  };
};

export const contentStyles = (isMobile) => {
  return {
    fontSize: 'xs',
    lineHeight: '20px',
    px: isMobile ? 0 : 3,
    position: 'relative',
    w: '100%',
  };
};

export const contentTitleStyles = (isMobile) => {
  return {
    fontWeight: 'medium',
    fontSize: isMobile ? 'sm' : 'lg',
  };
};

export const textSemiBoldStyle = {
  fontWeight: 'medium',
};

export const relativePositionStyling = (shouldMove) => {
  return {
    position: { lg: 'relative', base: 'initial' },
    top: shouldMove ? '-24px' : 'initial',
    left: shouldMove ? '-32px' : 'initial',
    w: { lg: shouldMove && 'calc(100% + 64px)', base: '100%' },
  };
};
