export const headingStyles = {
  size: 'xs',
  color: 'brand.neutral.900',
  borderRadius: '2px',
  alignSelf: 'start',
  lineHeight: '16px',
  marginBottom: '6px',
};

export const captureButtonStyles = (flip) => ({
  w: '100%',
  h: '120px',
  mt: '0 !important',
  transform: flip ? 'scale(-1,-1)' : 'none',
  position: 'relative',
  borderColor: 'brand.darkBlue.200',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '4px',
  background: 'brand.darkBlue.50',
});

export const iconFrameStyles = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  bg: 'brand.blue.800A',
  borderRadius: '50%',
  color: 'brand.neutral.white',
  p: '14px',
};

export const viewFinderStyles = {
  display: 'block',
  w: '100%',
  h: '100%',
  backgroundColor: 'brand.neutral.600',
};

export const hintMessageStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  p: '8px 12px',
  backgroundColor: 'brand.neutral.white',
  borderRadius: '8px',
};

export const hintMessageTextStyles = {
  fontSize: 'md',
  textAlign: 'center',
};

export const drawerFooterStyles = () => ({
  p: '16px 24px',
  boxShadow: '1px -2px 6px 0px rgba(3, 24, 64, 0.04)',
});
