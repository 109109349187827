import searchStorage from '@core/storage/searchStorage';

const getSearchParams = (checkForUTM) => {
  let searchParams = window.location.search;

  const checkUTMOrNot = checkForUTM
    ? searchStorage.read()?.includes('utm')
    : searchStorage.read() !== 'null';
  const searchStorageExists = searchParams.includes(searchStorage.read());
  // we only want to append if it doesn't already exists in current search params
  if (!searchStorageExists && checkUTMOrNot && !window.location.search.includes('utm')) {
    const querySymbol = searchParams.includes('?') ? '&' : '?';
    searchParams = `${searchParams}${querySymbol}${searchStorage.read()}`;
  }

  return searchParams;
};

export default getSearchParams;
