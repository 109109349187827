import { maxBy, sortedUniq } from 'lodash';
import moment from 'moment';
import { ANALYTICS_REPORTING, CASH_FLOW_STATEMENT, CASH_FLOW_TAX_PACKAGE } from '@routes';
import {
  POSITIVE_AMOUNT_BAR_COLOR,
  NEGATIVE_AMOUNT_BAR_COLOR,
  UNCATEGORIZED_AMOUNT_BAR_COLOR,
} from '../styles/cashFlowByType.styles';

// Internal helper functions
const getAbsoluteAmount = (categories) =>
  categories.map((cat) => {
    const clonedItem = { ...cat };
    clonedItem.amount = Math.abs(cat.amount);
    return clonedItem;
  });

const getMaxAmount = (categories) => maxBy(getAbsoluteAmount(categories), 'amount');

const getSplitMonthYear = (activeMonthYear) => {
  return activeMonthYear?.split('-');
};

const getMonthInNum = (month) => {
  const monthInNum = Number(month);
  return monthInNum;
};

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

// exported helper functions
export const UNCATEGORIZED_ID = '0';
const UNCATEGORIZED_LABEL = 'Uncategorized';

export const uncategorizedOption = {
  title: UNCATEGORIZED_LABEL,
  items: [
    {
      id: UNCATEGORIZED_ID,
      name: UNCATEGORIZED_LABEL,
    },
  ],
};

export const TABS = [
  {
    key: 'analytics',
    title: 'Cash Flow Analytics',
    isDisabled: false,
    useDimension: false,
    to: { pathname: ANALYTICS_REPORTING },
  },
  {
    key: 'cashflow_statement',
    title: 'Cash Flow Statement',
    isDisabled: false,
    useDimension: false,
    to: { pathname: CASH_FLOW_STATEMENT },
    segmentEventName: 'analytics_click_tab_cashflow_statement',
  },
  {
    key: 'cashflow_tax_package',
    title: 'Tax Package',
    isDisabled: false,
    useDimension: false,
    to: { pathname: CASH_FLOW_TAX_PACKAGE },
  },
];

export const getCalculatedValue = (subCategory) => {
  const { isMax, amount } = subCategory ?? {};
  if (isMax.amount === 0) {
    return 0;
  }
  const absoluteSubCategoryAmount = Math.abs(amount) * 100;
  const value = absoluteSubCategoryAmount / isMax.amount;
  return value;
};

export const getOptionsCategories = (categoryOptions) => {
  const optionsCategories = [...categoryOptions, uncategorizedOption];
  return optionsCategories;
};

export const getOptionsWithSubCategories = (categoryOptions) => {
  return categoryOptions.map((co) => ({
    ...co,
    items: co.items.map((cso) =>
      cso.parentId ? { ...cso, id: `${cso.parentId}-${cso.id}` } : cso
    ),
  }));
};

export const getSubOptionsCategories = (categoryOptions) => {
  const optionsCategories = getOptionsCategories(categoryOptions);

  const subOptionsCategories = optionsCategories.reduce((acc, cat) => {
    cat.items.forEach((subcat) => acc.push({ ...subcat, type: cat.title }));
    return acc;
  }, []);

  return subOptionsCategories;
};

export const getMappedCategories = (categoryMap) => {
  return { ...categoryMap, 0: UNCATEGORIZED_LABEL };
};

export const getCategories = (allCategories) => {
  const filteredCategories = allCategories.every((category) => category.amount === 0)
    ? allCategories
    : allCategories.filter((category) => category.amount !== 0);

  const categories = filteredCategories.map((item, index) => {
    const isT1MaxAmount = getMaxAmount(allCategories);
    const isT2MaxAmount = getMaxAmount(item.subCategories);

    return {
      amount: item.amount,
      name: item.name,
      subCategories: item?.subCategories.map((cat) => {
        return { ...cat, isMax: isT2MaxAmount };
      }),
      isMax: isT1MaxAmount.name === item.name,
      value: item.amount === 0 ? 0 : (Math.abs(item.amount) / isT1MaxAmount.amount) * 100,
    };
  });
  return categories;
};

export const getProgressBarColorScheme = (item) => {
  let progressBarColorScheme =
    item.amount > 0 ? POSITIVE_AMOUNT_BAR_COLOR : NEGATIVE_AMOUNT_BAR_COLOR;

  if (item.name === 'Uncategorized') {
    progressBarColorScheme = UNCATEGORIZED_AMOUNT_BAR_COLOR;
  }

  return progressBarColorScheme;
};

export const getMonthYearInName = (activeMonth) => {
  const splitMonthYear = getSplitMonthYear(activeMonth);
  const month = splitMonthYear && getMonthInNum(splitMonthYear[1]);
  const year = splitMonthYear && splitMonthYear[0];
  const d = new Date();
  d.setMonth(month - 1);
  const monthName = d.toLocaleString('default', { month: 'long' });

  return monthName && year && `${monthName} ${year}`;
};

export const getTag = (categoryMap, category) => {
  const tag = getKeyByValue(categoryMap, category);
  return tag;
};

export const getTags = (categoryMap, categories) => {
  const tagsArr = categories.map((cat) => getTag(categoryMap, cat.name));
  const tags = tagsArr.length > 0 ? tagsArr : null;
  return tags;
};

export const getCategorySubOptionTags = (categoriesWithSubOptions, activeCategory) => {
  const activeTypeCategories = categoriesWithSubOptions
    .filter((item) => item.title === activeCategory[0]?.type)
    ?.map((i) => i?.items);
  return activeTypeCategories[0] ? activeTypeCategories[0].map((i) => i.id) : [];
};

export const getOnlySubCategoryTags = (
  categoriesWithSubOptions,
  activeCategory,
  selectedSubOptionName,
  subOptions
) => {
  const activeTypeCategories = categoriesWithSubOptions.filter(
    (item) => item.title === activeCategory[0]?.type
  );
  const selectedSubOption = activeTypeCategories[0]?.items.filter(
    (i) => i.name === selectedSubOptionName
  );
  const relatedSubCatOptions = selectedSubOption[0]?.searchValues; // Array of strings
  const tagIds = [];
  subOptions.forEach((item) => {
    relatedSubCatOptions.filter((x) => item.name === x && tagIds.push(item.id));
  });
  return sortedUniq(tagIds);
};

export const getPresetSubCategoriesTags = (
  selectedPresetCats,
  allCatsWithSubOptions,
  categoryMap
) => {
  const searchValues = [];
  allCatsWithSubOptions?.forEach((cat) => {
    cat?.items.forEach((item) => {
      selectedPresetCats.tagId.forEach((c) => {
        if (c === item.id && item?.hasChildren === true) {
          searchValues.push(...item.searchValues);
        }
      });
    });
  });
  const sortedTags = sortedUniq(searchValues).map((i) => {
    return getTag(categoryMap, i);
  });

  return sortedTags;
};

export const getGlobalTagFilter = (categories, item) => {
  return categories?.filter((cat) => cat.type === item.name).map((cat) => cat.id);
};

/**
 * Converts moment formatted string into a Date object.
 *
 * Note: Filters object uses a formatted string to store dates,
 * while the UI elements use Date objects, so conversion is necessary.
 */
export const convertToDateObject = (dateFormatted) => {
  return new Date(moment(dateFormatted).valueOf());
};
