import React from 'react';

function IconCheckCircleThin({ w, h, color }: { w?: string, h?: string, color?: string }) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        d="M10.6693 6 7.0026 9.66667 5.33594 8"
        stroke="#fff"
        strokeWidth=".625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconCheckCircleThin.defaultProps = {
  w: '16',
  h: '16',
  color: 'currentColor',
};
export default IconCheckCircleThin;
