import React from 'react';
import { Stack, Text, ChakraProvider } from '@chakra-ui/react';
import habitatTheme from '@core/themeHabitat';
import BaselaneRadioCardList from '../../../BaselaneRadioCardList';
import { formatAddress } from '../../helpers/shared.helpers';
import { selectPropertyStyles } from './styles/selectProperty.styles';

type SelectPropertyProps = {
  sortedProperties: Array,
  selectedProperty: String,
  setSelectedProperty: Function,
  setSelectedUnit: Function,
  search: String,
  setSearch: Function,
};

const SelectProperty = ({
  sortedProperties,
  selectedProperty,
  setSelectedProperty,
  setSelectedUnit,
  search,
  setSearch,
}: SelectPropertyProps) => {
  const { textcontainer, title, description } = selectPropertyStyles ?? {};

  const handlePropertyClick = (property) => {
    const { id, units } = property ?? {};
    const isSingleUnitProperty = units?.length === 1;

    setSelectedProperty(id);
    if (isSingleUnitProperty) {
      setSelectedUnit(units[0]?.id);
    }
  };

  const formattedProperties = sortedProperties.map((property) => {
    const { address: addressObj } = property ?? {};
    const propertyAddress = formatAddress(addressObj);

    return { ...property, propertyAddress };
  });

  return (
    <>
      <Stack {...textcontainer}>
        <Text {...title}>Select the property to create lease document for</Text>
        <Text {...description}>
          For multi-unit properties, select the property, then continue to choose the unit.
        </Text>
      </Stack>
      <ChakraProvider theme={habitatTheme}>
        <BaselaneRadioCardList
          {...{
            list: formattedProperties,
            onListItemClick: handlePropertyClick,
            searchBy: 'name',
            showValueBy: 'propertyAddress',
            selectedListItem: selectedProperty,
            name: 'Property',
            search,
            setSearch,
          }}
        />
      </ChakraProvider>
    </>
  );
};

export default SelectProperty;
