import React, { useState } from 'react';
import { FormLabel, Stack, Box } from '@chakra-ui/react';
import { formLabelStyles } from '@shared/styles/input.style';
import BaselaneTooltip from '@shared/components/BaselaneTooltip';
import { IconInfo } from '@icons';

type FormLabelWithTooltipProps = {
  htmlFor: string,
  text: string,
  tooltipText: string,
  textStyles?: string,
  tooltipTextStyles?: string,
  containerStyles?: string,
};

const FormLabelWithTooltip = ({
  htmlFor,
  text,
  tooltipText,
  textStyles,
  tooltipTextStyles,
  containerStyles,
}: FormLabelWithTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const tooltip = (
    <BaselaneTooltip
      label={tooltipText}
      styles={{ w: '233px', ...tooltipTextStyles }}
      placement="top"
      isOpen={isOpen}
    >
      <Box
        onMouseEnter={() => {
          setIsOpen((prevState) => !prevState);
        }}
        onMouseLeave={() => {
          if (isOpen) {
            setIsOpen(false);
          }
        }}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <IconInfo width="16" height="16" color="#3A4B5B" />
      </Box>
    </BaselaneTooltip>
  );

  return (
    <Stack direction="row" align="center" mb={0.75} spacing="9px !important" {...containerStyles}>
      <FormLabel htmlFor={htmlFor} {...{ ...formLabelStyles.xs, m: 0, ...textStyles }}>
        {text}
      </FormLabel>
      {tooltip}
    </Stack>
  );
};

FormLabelWithTooltip.defaultProps = {
  textStyles: {},
  tooltipTextStyles: {},
  containerStyles: {},
};

export default FormLabelWithTooltip;
