import React from 'react';
import { Tbody, Tr, Td } from '@chakra-ui/react';
import SlLoader from '@core/components/Loader';

type BaselaneResponsiveTableLoaderProps = {
  /**
   * Flag to show/hide table loader
   */
  isLoading: boolean,
  /**
   * if you want to pass in different loader
   */
  children?: React.Node,
};

/**
 * Loader component that can be used inside table
 *
 * @returns {JSX.Element}
 * @component
 */
const BaselaneResponsiveTableLoader = ({
  isLoading,
  children,
}: BaselaneResponsiveTableLoaderProps) => {
  return (
    isLoading && (
      <Tbody>
        <Tr position="relative" height="100px">
          <Td>{children || <SlLoader styles={{ position: 'absolute' }} />}</Td>
        </Tr>
      </Tbody>
    )
  );
};

BaselaneResponsiveTableLoader.defaultProps = {
  children: null,
};

export default BaselaneResponsiveTableLoader;
