import React from 'react';
import { AlertDialogFooter, Stack } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';

type DeleteTenantAlertFooterProps = {
  onAlertClose: Function,
  onDelete: Function,
  hasActiveLease: Boolean,
};

const DeleteTenantAlertFooter = ({
  onAlertClose,
  onDelete,
  hasActiveLease,
}: DeleteTenantAlertFooterProps) => {
  return (
    <AlertDialogFooter p={0} mt={3}>
      <Stack direction="row" w="100%" spacing={1.5}>
        <BaselaneButton
          size="md"
          variant="outline"
          palette="neutral"
          onClick={onAlertClose}
          isFullWidth
        >
          Cancel
        </BaselaneButton>
        <BaselaneButton
          size="md"
          variant="filled"
          palette="danger"
          onClick={() => {
            onAlertClose();
            onDelete();
          }}
          isFullWidth
        >
          {hasActiveLease ? 'End & delete' : 'Delete tenant'}
        </BaselaneButton>
      </Stack>
    </AlertDialogFooter>
  );
};

export default DeleteTenantAlertFooter;
