export const badgeTagStyles = {
  backgroundStyles: {
    boxSizing: 'border-box',
    borderRadius: '4px',
    p: '0',
    h: 'auto',
  },
  fontStyles: {
    textStyle: 'xs',
    textTransform: 'capitalize',
    p: '2px 8px',
  },
};

export const badgeButtonStyles = ({ isMinXL }) => {
  return {
    backgroundStyles: {
      whiteSpace: 'nowrap',
      display: 'inline-block',
      h: '100%',
      p: '0',
      m: '0 !important',
      borderRadius: '6px',
      mt: isMinXL ? '16px !important' : '0 !important',
      alignSelf: isMinXL ? 'flex-start' : 'flex-end',
    },
    fontStyles: {
      textStyle: 'xs',
      textTransform: 'capitalize',
      p: '6px 12px',
    },
  };
};

export const badgeButtonIconStyles = {
  display: 'inline-block',
  m: '0 8px 0 0 !important',
};

export const supportingContentStyles = ({ isMinXL }) => {
  return {
    container: {
      flexDir: isMinXL ? 'column' : 'row',
      alignItems: isMinXL ? 'flex-start' : 'flex-end',
    },
    leftcontent: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'flex-start',
      h: '20px',
    },
    textcontainer: {
      textStyle: 'sm',
    },
  };
};

export const adBannerStyles = ({ isMinXL }) => {
  return {
    primarycontent: {
      m: isMinXL ? '16px 0 !important' : '16px 0 4px 0 !important',
    },
    supportingcontent: {
      m: '0 !important',
    },
  };
};
