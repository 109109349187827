import React from 'react';
import { Stack, Tbody, Text } from '@chakra-ui/react';
import {
  BaselaneResponsiveTable,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellText,
} from '../../BaselaneResponsiveTable';
import { propertyTableHeaderItems, propertyTableConfig } from './helpers/propertyTable.helpers';
import { propertyTableStyles } from './styles/propertyTable.styles';
import { LeaseDataType } from '../type/types';

const { headerrow, bodyrow, cell, chevroncell, celltext } = propertyTableStyles;

function PropertyTable({ sortedLeases }: Array<LeaseDataType>) {
  return (
    <BaselaneResponsiveTable config={propertyTableConfig}>
      <BaselaneResponsiveTableHeader
        items={propertyTableHeaderItems}
        renderItem={(item, index) => (
          <BaselaneResponsiveTableHeading key={item.key} index={index} title={item.label} />
        )}
        contentContainerStyles={headerrow}
        chevronCellStyles={chevroncell}
      />

      <Tbody>
        <BaselaneResponsiveTableRows
          customRow
          items={sortedLeases}
          renderItem={(lease) => {
            const {
              id,
              propertyName,
              propertyUnitName,
              totalUnits,
              rentAndFeesBankAccount,
            } = lease;

            const isMultiUnit = totalUnits > 1;
            const propertyColumnText = propertyName;
            const propertyColumnSubtext = isMultiUnit ? propertyUnitName : null;

            return (
              <BaselaneResponsiveTableRow
                id={id}
                contentContainerStyles={bodyrow}
                chevronCellStyles={chevroncell}
              >
                {/* Property  */}
                <BaselaneResponsiveCell configIndex={0}>
                  <Stack {...cell.container}>
                    <Text {...celltext.bold}>{propertyColumnText}</Text>
                    <Text {...celltext.xs}>{propertyColumnSubtext}</Text>
                  </Stack>
                </BaselaneResponsiveCell>

                {/* Receiving BA  */}
                <BaselaneResponsiveCellText
                  text={rentAndFeesBankAccount.nickName ?? rentAndFeesBankAccount.accountName}
                  configIndex={1}
                  styles={cell.regular}
                />
              </BaselaneResponsiveTableRow>
            );
          }}
        />
      </Tbody>
    </BaselaneResponsiveTable>
  );
}

export default PropertyTable;
