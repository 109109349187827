import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Switch,
  Text,
} from '@chakra-ui/react';
import { getEnableTransactionInfoMessage } from '@shared/helpers/enableTrxMessage.helper';
import AutoTagTable from '@core/components/NativeBankingPage/MainContent/components/Accounts/components/AutoTagTable';
import {
  formErrorStyles,
  formInputStyles,
  formLabelStyles,
  formSwitchRowStyles,
  formSwitchStyles,
} from './styles/addEditSubAccountDrawer.styles';

type FormComponentProps = {
  values?: Object,
  handleFormUpdate: Function,
  handleBlur: Function,
  handleChange: Function,
  setFieldValue: Function,
  setFormVariables: Function,
  dirty: boolean,
  errors?: Object,
};

const FormComponent = ({
  values,
  handleFormUpdate,
  handleBlur,
  handleChange,
  setFieldValue,
  setFormVariables,
  dirty,
  errors,
}: FormComponentProps) => {
  const [autoTagIsEnabled, setAutoTagIsEnabled] = useState(!!values?.autoTag?.enabled);

  const enableTransactionInfoMessage = getEnableTransactionInfoMessage(false);

  return (
    <Box flex="1">
      {/* Name */}
      <FormControl isInvalid={errors.name}>
        <FormLabel htmlFor="name" {...formLabelStyles.xs} mb="8px">
          Virtual Account Nickname
        </FormLabel>
        <Input
          id="name"
          name="name"
          value={values?.name}
          onChange={handleChange}
          onBlur={(e) => {
            handleFormUpdate('name', values, dirty);
            handleBlur(e);
          }}
          placeholder="Security Deposits, Unit 2F"
          {...formInputStyles}
        />
        <FormErrorMessage {...formErrorStyles}>
          <Text>{errors.name}</Text>
        </FormErrorMessage>
      </FormControl>

      <>
        {/* Auto-Tagging Switch */}
        <FormControl {...formSwitchRowStyles}>
          <FormLabel {...formLabelStyles.sm} m="0 !important">
            Enable Transaction Auto-Tagging to a Property or Unit (Optional)
          </FormLabel>
          <Spacer />
          <Switch
            className="primary-500"
            {...formSwitchStyles}
            id="autoTag.enabled"
            isChecked={autoTagIsEnabled}
            name="autoTag.enabled"
            value={autoTagIsEnabled}
            onChange={(event) => {
              setAutoTagIsEnabled(!autoTagIsEnabled);
              handleFormUpdate('autoTag', values, true);
              handleChange(event);
            }}
          />
        </FormControl>

        {/* Disclaimer for Auto-Tagging */}
        {enableTransactionInfoMessage}

        {/* Auto-Tagging Enabled */}
        {autoTagIsEnabled && (
          <AutoTagTable
            {...{
              dirty,
              setFieldValue,
              handleChange,
              handleBlur,
              setFormVariables,
              handleFormUpdate,
            }}
            autoTag={values?.autoTag}
          />
        )}
      </>
    </Box>
  );
};

FormComponent.defaultProps = {
  values: {},
  errors: {},
};

export default FormComponent;
