const hasValidCharacter = (value) => value.trim() === '';

const personalDetailsFormValidation = (values, showMessage, setShowMessage) => {
  // TODO: do we need showMessage and setShowMessage
  if (showMessage) setShowMessage(false);

  const errors = {};
  if (!values.lastName || values.lastName === '' || hasValidCharacter(values.lastName)) {
    errors.lastName = 'Please enter last name';
  }

  if (!values.firstName || values.firstName === '' || hasValidCharacter(values.firstName)) {
    errors.firstName = 'Please enter first name';
  }

  const validDOB =
    /(?:0[1-9]|1[012])([/.-])(?:0[1-9]|[12]\d|3[01])([/.-])(?:19|20)\d\d/g.test(values.dob) &&
    Number(values.dob.substring(values.dob.length - 4, values.dob.length)) < 2022;
  if (!values.dob) {
    errors.dob = 'Please enter date of birth';
  }

  // if it is a tokenized value just return current errors
  if (values.dob.includes('tok')) {
    return errors;
  }

  if (values.dob && !validDOB) {
    errors.dob = 'Please enter valid date of birth';
  }

  return errors;
};

const personalDetailsFormInitialValues = (personalDetailsData) => ({
  firstName: personalDetailsData?.firstName || '',
  lastName: personalDetailsData?.lastName || '',
  dob: personalDetailsData?.dob || '',
});

const dobMask = [/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /\d/, /\d/, /\d/, /\d/];

export { personalDetailsFormValidation, personalDetailsFormInitialValues, dobMask };
