import { isMobile } from 'react-device-detect';

export const headerStyles = {
  fontSize: 'xs',
  color: 'brand.neutral.600',
  lineHeight: isMobile ? '24px' : '32px',
  pl: isMobile ? '24px' : '40px',
  pt: isMobile ? '0' : '8px',
  mt: isMobile ? '8px' : '36px',
};

export const cStyles = {
  pb: '0',
  pr: '32px',
};

export const tabMenuTitleTextStyles = {
  ...headerStyles,
  mt: 0,
  pl: '10px',
  pt: 0,
  textTransform: 'uppercase',
};

export const dashStyles = { display: 'flex', borderColor: 'brand.darkBlue.150', flex: '1 1' };

export const tabPanelsStyles = {
  h: isMobile ? 'auto' : 'calc(100% - 59px)',
  overflowY: 'auto',
};

export const tabPanelsWrapperStyles = {
  width: '100%',
  height: isMobile ? 'unset' : '',
  alignItems: 'flex-start',
};

export const submitErrorStyles = {
  container: {
    ml: isMobile ? '3' : '4',
    w: '100%',
    maxWidth: isMobile ? 'calc(100% - 60px)' : '640px',
  },
  title: {
    textStyle: 'xs-heavy',
    color: 'brand.neutral.900',
    mb: 0.5,
  },
  innercontainer: { py: 1, px: 2 },
  containerStyles: {
    py: '0px',
    mb: '24px',
    w: '100%',
  },
};

export const iconContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  w: '24px',
  mr: 1,
};

export const submitErrorTextStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
};
