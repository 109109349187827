export const externalTabStyles = (isMinXL) => {
  return {
    container: {
      p: isMinXL ? '16px' : '0',
      bgColor: isMinXL ? 'inherit' : 'brand.neutral.white',
      borderRadius: '8px',
    },
    tabs: {
      position: 'relative',
      bgColor: 'brand.neutral.white',
      borderRadius: '8px',
      overflow: 'hidden',
    },
    list: {
      position: 'inherit',
      top: 'auto',
      left: 'auto',
      overflow: 'hidden',
    },
    panels: {
      p: '0',
    },
  };
};

export const tabPanelHeadingStyles = {
  size: 'headline-lg',
  color: 'brand.neutral.900',
};

export const sectionHeadingStyles = {
  size: 'headline-sm',
  color: 'brand.neutral.900',
  m: '0px !important',
};

export const descriptionTextStyles = (isMinXL) => ({
  textStyle: 'sm',
  color: 'brand.neutral.700',
  mt: '0px !important',
  mb: '0px !important',
  mx: isMinXL ? '20px' : '0',
  p: '0 16px 16px 16px',
});

export const panelHeaderContainerStyles = (isMin768) => ({
  justify: 'space-between',
  alignItems: 'center',
  borderBottom: isMin768 ? '1.5px solid #E1F4FF' : 'none',
  p: isMin768 ? '16px' : '24px 16px 0 16px',
  m: '0 !important',
  bg: isMin768 ? 'brand.neutral.white' : 'transparent',
});

export const sectionHeadingContainerStyles = {
  p: '16px',
};

export const addButtonStyles = (isMinXL) => ({
  w: isMinXL && '100%',
});

export const tooltipStyles = {
  styles: {
    backgroundColor: 'brand.neutral.700',
    color: 'brand.neutral.white',
    fontSize: 'xs',
    lineHeight: '16px',
    p: '8px 12px',
    borderRadius: '4px',
    maxWidth: '200px',
  },
  container: {
    ml: '0 !important',
  },
};
