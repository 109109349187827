export const baselaneSummaryCardStyles = {
  cardStyles: {
    palette: 'dark',
    size: 'md',
  },
  cardSubtitleStyles: {
    textStyle: 'xs',
    color: 'brand.neutral.600',
  },
  cardTitleStyles: {
    textStyle: 'headline-sm',
    color: 'brand.neutral.900',
    fontWeight: 'semibold',
  },
  cardStateTitleStyles: {
    textStyle: 'headline-lg',
  },
  cardStateContainerStyles: {
    gap: 1,
    alignItems: 'flex-end',
  },
  amountStyles: {
    textStyle: 'headline-lg',
    color: 'brand.neutral.700',
  },
  subtextLightStyles: {
    textStyle: 'xs',
    color: 'brand.neutral.600',
  },
  dividerStyles: {
    my: 1,
  },
  groupStyles: {
    alignItems: 'flex-start',
  },
  labelStyles: {
    mt: 0.25,
    textStyle: 'xs',
    color: 'brand.neutral.700',
  },
  valueContainerStyles: {
    flex: '2',
    alignItems: 'flex-end',
    textAlign: 'right',
  },
  smValueStyles: {
    textStyle: 'sm',
    color: 'brand.neutral.900',
  },
  xsValueStyles: {
    textStyle: 'xs',
    color: 'brand.neutral.700',
  },
};
