import React from 'react';

type IconBankProps = { w?: Number, h?: Number, color?: string };

function IconBank({ w, h, color }: IconBankProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3231 3.47714C19.754 3.28561 20.2459 3.28561 20.6769 3.47714L35.6769 10.1438C36.3962 10.4635 36.7938 11.2428 36.6304 12.0128C36.467 12.7828 35.7871 13.3335 35 13.3335H4.99999C4.21286 13.3335 3.53304 12.7828 3.36963 12.0128C3.20623 11.2428 3.6038 10.4635 4.3231 10.1438L19.3231 3.47714ZM12.8537 10.0002H27.1463L20 6.82403L12.8537 10.0002ZM3.33333 16.6668C3.33333 15.7464 4.07952 15.0002 4.99999 15.0002H35C35.9205 15.0002 36.6667 15.7464 36.6667 16.6668C36.6667 17.5873 35.9205 18.3335 35 18.3335V33.3335C35.9205 33.3335 36.6667 34.0797 36.6667 35.0002C36.6667 35.9206 35.9205 36.6668 35 36.6668H4.99999C4.07952 36.6668 3.33333 35.9206 3.33333 35.0002C3.33333 34.0797 4.07952 33.3335 4.99999 33.3335V18.3335C4.07952 18.3335 3.33333 17.5873 3.33333 16.6668ZM8.33333 18.3335V33.3335H31.6667V18.3335H8.33333ZM13.3333 21.6668C14.2538 21.6668 15 22.413 15 23.3335V28.3335C15 29.254 14.2538 30.0002 13.3333 30.0002C12.4129 30.0002 11.6667 29.254 11.6667 28.3335V23.3335C11.6667 22.413 12.4129 21.6668 13.3333 21.6668ZM20 21.6668C20.9205 21.6668 21.6667 22.413 21.6667 23.3335V28.3335C21.6667 29.254 20.9205 30.0002 20 30.0002C19.0795 30.0002 18.3333 29.254 18.3333 28.3335V23.3335C18.3333 22.413 19.0795 21.6668 20 21.6668ZM26.6667 21.6668C27.5871 21.6668 28.3333 22.413 28.3333 23.3335V28.3335C28.3333 29.254 27.5871 30.0002 26.6667 30.0002C25.7462 30.0002 25 29.254 25 28.3335V23.3335C25 22.413 25.7462 21.6668 26.6667 21.6668Z"
        fill={color}
      />
    </svg>
  );
}

IconBank.defaultProps = {
  w: 40,
  h: 40,
  color: 'currentColor',
};

export default IconBank;
