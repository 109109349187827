/* eslint-disable react/prop-types */
import React from 'react';
import { DateTime } from 'luxon';
import { Box } from '@chakra-ui/react';
import BaselaneChip from '@shared/components/BaselaneChip';

const UpcomingTransferChip = ({ transfer }) => {
  if (transfer.transferDate === transfer.actualDate) {
    return null;
  }

  const actualDate = DateTime.fromISO(transfer.actualDate).toFormat('MMM d');

  const label = transfer.isHoliday ? `${actualDate} is a holiday` : `${actualDate} is on a weekend`;

  return (
    <Box ml={0.75} display="flex">
      <BaselaneChip size="sm" label={label} />
    </Box>
  );
};

export default UpcomingTransferChip;
