import { gql } from '@apollo/client';

export const GET_TENANT_INFO_LEASES = gql`
  query tenantInfo($leaseId: ID) {
    leases(input: { leaseId: $leaseId }) {
      leases {
        id
        tenantProfile {
          firstName
          lastName
          phoneNumber
        }
      }
    }
  }
`;
