import React, { useState, useEffect, useCallback } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import useEmblaCarousel from 'embla-carousel-react';
import SlideIndicatorButtons from './SlideIndicatorButtons';
import {
  carouselWrapperStyles,
  slideContainerStyles,
  slideWrapperStyles,
} from './styles/slide.styles';

interface BaselaneCarouselProps {
  slides: Array<Object>;
  SlideElement: React.Node;
  ElementsBeforeIndicator?: React.Node | null;
  ElementsAfterIndicator?: React.Node | null;
  ToggleButtonElements?: React.Node | null;
  showToggleBesideIndicator?: Boolean;
  isDarkMode?: Boolean;
  styles?: Object;
}

const BaselaneCarousel = ({
  slides,
  SlideElement,
  ElementsBeforeIndicator,
  ElementsAfterIndicator,
  ToggleButtonElements,
  showToggleBesideIndicator,
  isDarkMode,
  styles,
}: BaselaneCarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({});
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onInit = useCallback(() => {
    if (!emblaApi) return;

    setScrollSnaps(emblaApi.scrollSnapList());
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;

    setSelectedIndex(emblaApi?.selectedScrollSnap());
    setPrevBtnDisabled(!emblaApi?.canScrollPrev());
    setNextBtnDisabled(!emblaApi?.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;

    onInit();
    onSelect();
    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
    // eslint-disable-next-line consistent-return
    return () => {
      emblaApi.off('reInit', onInit);
      emblaApi.off('select', onSelect);
      emblaApi.off('reInit', onSelect);
    };
  }, [emblaApi, onInit, onSelect]);

  useEffect(() => {
    if (!emblaApi) return;
    if (slides?.length > 0) {
      emblaApi.scrollTo(0);
    }
  }, [emblaApi, slides]);

  const {
    customCarouselWrapperStyles = {},
    customSlideWrapperStyles = {},
    customSlideContainerStyles = {},
    customArrowButtonContainerStyles = {},
    customSlideIndicatorButtonsContainerStyles = {},
  } = styles;

  return (
    <Box {...{ ...carouselWrapperStyles, ...customCarouselWrapperStyles }}>
      <Box {...{ ...slideWrapperStyles, ...customSlideWrapperStyles }} ref={emblaRef}>
        <Box {...{ ...slideContainerStyles, ...customSlideContainerStyles }}>
          {(slides ?? []).map((slide, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SlideElement key={index} index={index} slide={slide} />
          ))}
        </Box>
      </Box>

      {(ToggleButtonElements || showToggleBesideIndicator) && (
        <>
          {ElementsBeforeIndicator && showToggleBesideIndicator && (
            <ElementsBeforeIndicator selectedIndex={selectedIndex} slides={slides} />
          )}
          <HStack justifyContent="space-between" pt={3} {...customArrowButtonContainerStyles}>
            {ToggleButtonElements && (
              <ToggleButtonElements
                scrollPrev={scrollPrev}
                prevBtnDisabled={prevBtnDisabled}
                scrollNext={scrollNext}
                nextBtnDisabled={nextBtnDisabled}
                isDarkMode={isDarkMode}
              />
            )}

            {showToggleBesideIndicator && (
              <Box>
                <SlideIndicatorButtons
                  isDarkMode={isDarkMode}
                  scrollSnaps={scrollSnaps}
                  scrollTo={scrollTo}
                  selectedIndex={selectedIndex}
                />
              </Box>
            )}
          </HStack>
        </>
      )}

      {ElementsBeforeIndicator && !showToggleBesideIndicator && (
        <ElementsBeforeIndicator selectedIndex={selectedIndex} slides={slides} />
      )}

      {!showToggleBesideIndicator && (
        <Box pt={ToggleButtonElements ? 0 : 3} {...customSlideIndicatorButtonsContainerStyles}>
          <SlideIndicatorButtons
            isDarkMode={isDarkMode}
            scrollSnaps={scrollSnaps}
            scrollTo={scrollTo}
            selectedIndex={selectedIndex}
          />
        </Box>
      )}

      {ElementsAfterIndicator && (
        <ElementsAfterIndicator selectedIndex={selectedIndex} slides={slides} />
      )}
    </Box>
  );
};

BaselaneCarousel.defaultProps = {
  ElementsBeforeIndicator: null,
  ElementsAfterIndicator: null,
  ToggleButtonElements: null,
  showToggleBesideIndicator: false,
  isDarkMode: false,
  styles: {},
};

export default BaselaneCarousel;
