import React from 'react';

function IconReturnedCircle({ w, h, color }: { w?: string, h?: string, color?: string }) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_773_21788)">
        <path
          d="M4.52892 2.29011C4.82369 1.99498 4.82369 1.51648 4.52892 1.22135C4.23414 0.926217 3.75622 0.926217 3.46144 1.22135L0.221082 4.46562C-0.0736939 4.76076 -0.0736939 5.23926 0.221082 5.53439L3.46144 8.77865C3.75622 9.07378 4.23414 9.07378 4.52892 8.77865C4.82369 8.48352 4.82369 8.00502 4.52892 7.70989L2.57139 5.75H10.625C12.7651 5.75 14.5 7.4849 14.5 9.625V10C14.5 11.933 12.933 13.5 11 13.5C10.5858 13.5 10.25 13.8358 10.25 14.25C10.25 14.6642 10.5858 15 11 15C13.7614 15 16 12.7614 16 10V9.625C16 6.65647 13.5935 4.25 10.625 4.25H2.5714L4.52892 2.29011Z"
          fill={color}
        />
        <path
          d="M1.75 14.25C1.75 13.8358 2.08579 13.5 2.5 13.5H3C3.41421 13.5 3.75 13.8358 3.75 14.25C3.75 14.6642 3.41421 15 3 15H2.5C2.08579 15 1.75 14.6642 1.75 14.25Z"
          fill={color}
        />
        <path
          d="M6 13.5C5.58579 13.5 5.25 13.8358 5.25 14.25C5.25 14.6642 5.58579 15 6 15H8C8.41421 15 8.75 14.6642 8.75 14.25C8.75 13.8358 8.41421 13.5 8 13.5H6Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_773_21788">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconReturnedCircle.defaultProps = {
  w: '16',
  h: '16',
  color: 'currentColor',
};

export default IconReturnedCircle;
