export const backgroundStyles = {
  active: {
    bg: 'brand.blue.100',
    color: 'brand.blue.700',
  },
  expired: {
    bg: 'yellow.100',
    color: 'yellow.900',
  },
  archived: {
    bg: 'green.100',
    color: 'green.800AA',
  },
  overdue: {
    bg: 'red.200',
    color: 'red.800AA',
  },
};

export const containerStyles = {
  maxWidth: '100% !important',
  padding: '2px 6px !important',
  width: 'auto',
  height: '16px',
  m: '10px 16px 0 0 !important',
  borderRadius: '2px',
};

export const fontStyles = {
  fontSize: '3xs',
  fontWeight: 'semibold',
  lineHeight: '16px',
};
