import React from 'react';

type IconClockProps = {
  w: Number,
  h: Number,
  color: string,
};

function IconClock({ w = 20, h = 21, color = 'currentColor' }: IconClockProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.5C7.87827 2.5 5.84344 3.34285 4.34315 4.84315C2.84285 6.34344 2 8.37827 2 10.5C2 11.5506 2.20693 12.5909 2.60896 13.5615C3.011 14.5321 3.60028 15.414 4.34315 16.1569C5.08601 16.8997 5.96793 17.489 6.93853 17.891C7.90914 18.2931 8.94942 18.5 10 18.5C11.0506 18.5 12.0909 18.2931 13.0615 17.891C14.0321 17.489 14.914 16.8997 15.6569 16.1569C16.3997 15.414 16.989 14.5321 17.391 13.5615C17.7931 12.5909 18 11.5506 18 10.5C18 8.37827 17.1571 6.34344 15.6569 4.84315C14.1566 3.34285 12.1217 2.5 10 2.5ZM2.92893 3.42893C4.8043 1.55357 7.34784 0.5 10 0.5C12.6522 0.5 15.1957 1.55357 17.0711 3.42893C18.9464 5.3043 20 7.84784 20 10.5C20 11.8132 19.7413 13.1136 19.2388 14.3268C18.7362 15.5401 17.9997 16.6425 17.0711 17.5711C16.1425 18.4997 15.0401 19.2362 13.8268 19.7388C12.6136 20.2413 11.3132 20.5 10 20.5C8.68678 20.5 7.38642 20.2413 6.17317 19.7388C4.95991 19.2362 3.85752 18.4997 2.92893 17.5711C2.00035 16.6425 1.26375 15.5401 0.761205 14.3268C0.258658 13.1136 0 11.8132 0 10.5C5.96046e-08 7.84784 1.05357 5.3043 2.92893 3.42893ZM10 5.5C10.5523 5.5 11 5.94772 11 6.5V10.0858L13.7071 12.7929C14.0976 13.1834 14.0976 13.8166 13.7071 14.2071C13.3166 14.5976 12.6834 14.5976 12.2929 14.2071L9.29289 11.2071C9.10536 11.0196 9 10.7652 9 10.5V6.5C9 5.94772 9.44771 5.5 10 5.5Z"
        fill={color}
      />
    </svg>
  );
}

export default IconClock;
