import { isMobile } from 'react-device-detect';
import customTheme from '@core/theme';
import blurredCard from '../assets/blurredCard.png';

export const cardLabelStyles = {
  textStyle: 'xs',
  color: customTheme.colors.brand.neutral['600'],
};

export const cardValueStyles = {
  textStyle: 'headline-md',
  fontFamily: '"Ease Variable", Helvetica, Arial',
  color: customTheme.colors.brand.neutral['900'],
  width: '100%',
  height: '20px',
  display: 'block',
  boxSizing: 'border-box',
};

export const copyButtonStyles = {
  backgroundColor: customTheme.colors.brand.darkBlue['50'],
  fontSize: 'md',
  color: customTheme.colors.brand.neutral['600'],
  fontFamily: '"Ease Variable", Helvetica, Arial',
  height: '20px',
  width: '20px',
  lineHeight: '20px',
  outline: 'none',
  cursor: 'pointer',
  borderRadius: '4px',
  ':hover': {
    color: customTheme.colors.brand.neutral['700'],
  },
};

export const loaderStyles = (showCardDetailsLoader) => ({
  position: 'absolute',
  width: '40px',
  height: '40px',
  left: '42%',
  top: '32%',
  margin: 0,
  display: showCardDetailsLoader.cvv2 && showCardDetailsLoader.cardNumber ? 'block' : 'none',
});

export const cardContainerStyles = (cardStatus, showCardDetails) => {
  const padding = isMobile ? '30px 16px' : '16px';
  const baseStyles = {
    w: isMobile ? '100%' : '256px',
    h: isMobile ? '201px' : '163px',
    p: showCardDetails ? padding : '0',
    minWidth: '256px',
    borderWidth: '1px',
    borderRadius: '4px',
    position: 'relative',
  };

  if (cardStatus === 'Frozen' || cardStatus === 'SuspectedFraud') {
    return {
      ...baseStyles,
      borderColor: customTheme.colors.red['500A'],
      bgColor: customTheme.colors.brand.neutral.white,
      color: customTheme.colors.red['500A'],
      alignItems: 'center',
      justifyContent: 'center',
      textStyle: 'sm',
    };
  }

  const activeCardStyles = {
    ...baseStyles,
    borderColor: customTheme.colors.brand.darkBlue['150'],
    spacing: isMobile ? '30px' : '18px',
    bgColor: customTheme.colors.brand.darkBlue['50'],
  };

  if (!showCardDetails) {
    return {
      ...activeCardStyles,
      backgroundImage: blurredCard,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative',
    };
  }
  return activeCardStyles;
};

export const iconContainerStyles = {
  justifyContent: 'space-between',
  color: 'brand.blue.800A',
  alignItems: 'center',
};
