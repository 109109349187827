import React, { useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { BaselaneEmptyStateCard } from '@shared/components';
import { NATIVE_BANK } from '@routes';
import AddAccount from '../NativeBankingPage/MainContent/components/Accounts/components/AddAccount';

type EmptyStateProps = {
  noTransactionsFound: boolean,
  title: string,
  paragraph: string,
  styles?: Object,
  // used to override existing button action and
  // use the new external accounts flow
  isDirectToPlaid?: Boolean,
  onAddedPlaidSuccessfully?: Function,
};

function EmptyState({
  noTransactionsFound,
  title,
  paragraph,
  styles,
  isDirectToPlaid,
  onAddedPlaidSuccessfully,
}: EmptyStateProps) {
  if (noTransactionsFound) {
    return (
      <BaselaneEmptyStateCard title={title} styles={{ boxShadow: 'none', h: '430px' }}>
        <BaselaneEmptyStateCard.Paragraph>{paragraph}</BaselaneEmptyStateCard.Paragraph>
      </BaselaneEmptyStateCard>
    );
  }

  const addExternalAccountDrawerRef = useRef();

  return (
    <BaselaneEmptyStateCard
      title="Keep All Your Property Transactions in One Place"
      styles={{ boxShadow: 'none', h: '350px', ...styles }}
    >
      <BaselaneEmptyStateCard.Paragraph>
        The Transactions page is your consolidated ledger for all your property transactions. Keep
        your property finances organized by tagging transactions to Schedule E categories and
        specific properties.
      </BaselaneEmptyStateCard.Paragraph>
      <BaselaneEmptyStateCard.Paragraph>
        Automatically import transactions by securely connecting external banks or credit cards with
        Plaid™.
      </BaselaneEmptyStateCard.Paragraph>
      {!isDirectToPlaid ? (
        <BaselaneEmptyStateCard.Button route={NATIVE_BANK} variant="filled" palette="primary">
          Connect external account
        </BaselaneEmptyStateCard.Button>
      ) : (
        <Box
          {...{
            position: 'relative',
          }}
        >
          <AddAccount
            {...{
              isFromLease: false,
              isDirectToPlaid: true,
              hideButton: true,
              isDirectToPlaidButtonProps: {
                variant: 'filled',
                palette: 'primary',
                size: 'lg',
              },
              handleDrawerCloseExternally: onAddedPlaidSuccessfully,
              addExternalAccountDrawerRef,
              // override to place the loader at the center
              // of the screen as the accounts are added/reloaded
              loaderStyles: {
                w: '0px !important',
                h: '0px !important',
                top: '50%',
                left: '50%',
              },
            }}
          />
        </Box>
      )}
    </BaselaneEmptyStateCard>
  );
}

EmptyState.defaultProps = {
  styles: {},
  isDirectToPlaid: false,
  onAddedPlaidSuccessfully: () => {},
};

export default EmptyState;
