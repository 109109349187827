import { GET_STARTED, HOME } from '@routes';
import { getTitleContent } from './config.helper';

export const getTitle = (user, isDashboardHeader, dashboardTitle, homeTitle, page, pathname) => {
  const isHome = pathname === HOME || pathname.includes(GET_STARTED);
  const isLeasesPage = pathname.toLowerCase().includes('leases');
  const isBankingPage = pathname.indexOf('banking') !== -1;
  const isTransfersPage = pathname.indexOf('transfers_payments') !== -1;
  const isCashflowPage = pathname.indexOf('cashflow') !== -1;
  const isTenantsPage = pathname.indexOf('tenants') !== -1;

  let title = '';
  const titleContent = getTitleContent(user);
  if (user && isHome) {
    title = homeTitle.title;
  } else if (user && isDashboardHeader) {
    title = dashboardTitle.title;
  } else if (isBankingPage) {
    title = titleContent.banking.title;
  } else if (isLeasesPage) {
    title = titleContent.leases.title;
  } else if (isTransfersPage) {
    title = titleContent.transfers.title;
  } else if (isCashflowPage) {
    title = titleContent.cashflow.title;
  } else if (isTenantsPage) {
    title = titleContent.tenants.title;
  } else if (user) {
    title = titleContent[page] ? titleContent[page].title : 'Page Not Found';
  }

  return title;
};

export const getSubTitle = (isDashboardHeader, dashboardTitle, page) => {
  const titleContent = getTitleContent();
  return isDashboardHeader && !titleContent[page] ? dashboardTitle.subtitle : '';
};
