export const normalText = {
  color: 'brand.neutral.700',
  fontSize: 'xs',
  lineHeight: '20px',
};

export const truncatedText = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const boldedText = {
  fontWeight: 'semibold',
};
