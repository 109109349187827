/* eslint-disable no-param-reassign, no-underscore-dangle */
import { useEffect, useState } from 'react';

const useDepositAndFees = ({
  deposit,
  lastMonthRent,
  proratedRent,
  activeTab,
  setFormVariables,
  finishedLeaseTerm = false,
  hasDepositsAndFeesTag,
  saveLease,
}) => {
  const [pageFirstVisit, setPageFirstVisit] = useState(false);
  const isDepositsEnabled = deposit.enabled === null || deposit.enabled === undefined;
  const isLastMonthRentEnabled =
    lastMonthRent.enabled === null || lastMonthRent.enabled === undefined;
  const isProratedRentEnabled = proratedRent.enabled === null || proratedRent.enabled === undefined;

  const shouldSetDeposits = finishedLeaseTerm;

  const deposits = () => {
    if (isDepositsEnabled) return {};

    delete deposit.__typename;
    delete deposit.category;

    return deposit;
  };

  const lastMonthRents = () => {
    if (isLastMonthRentEnabled) return {};

    delete lastMonthRent.__typename;
    delete lastMonthRent.category;

    return lastMonthRent;
  };

  const proratedRents = () => {
    if (isProratedRentEnabled) return {};

    delete proratedRent.__typename;
    delete proratedRent.category;

    return proratedRent;
  };

  useEffect(() => {
    if (shouldSetDeposits) {
      setPageFirstVisit(true);
      const newVars = {
        payments: [
          { id: deposit.id, name: 'DEPOSIT', enabled: false, ...deposits() },
          { id: lastMonthRent.id, name: 'LAST_MONTH_RENT', enabled: false, ...lastMonthRents() },
          { id: proratedRent.id, name: 'PRORATED_RENT', enabled: false, ...proratedRents() },
        ],
      };
      setFormVariables(newVars);
      if (!hasDepositsAndFeesTag) {
        saveLease(newVars);
      }
    } else {
      setPageFirstVisit(false);
    }
  }, [activeTab, shouldSetDeposits]);

  return { pageFirstVisit, setPageFirstVisit };
};

export default useDepositAndFees;
