// @flow
import React, { useRef } from 'react';
import { Box, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { BaselaneButton } from '@core/components/Shared/components';
import { IconArrowRight, IconLightBulb } from '@icons';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import getBreakPoints from '@core/utils/getBreakPoints';
import LearnMoreDrawer from './LearnMoreDrawer';
import { getFormattedPaymentSummary } from './helpers/paymentSummary.helpers';
import { staticPaymentSummaryData } from '../helpers/emptyState.helpers';
import { getPaymentSummaryStyles } from './styles/paymentSummary.styles';

type PaymentSummaryProps = {
  isEmpty: boolean,
  invoiceSummaryData: Object,
  invoiceDrawerActionBtnRef: any,
  setInvoiceFilters: Function,
  handleInvoiceListDrawerOpen: Function,
  getLeaseInvoices: Function,
};

const PaymentSummary = ({
  isEmpty,
  invoiceSummaryData,
  invoiceDrawerActionBtnRef,
  setInvoiceFilters,
  handleInvoiceListDrawerOpen,
  getLeaseInvoices,
}: PaymentSummaryProps) => {
  const { isMinXL } = getBreakPoints();

  const learnMoreDrawerRef = useRef();

  const {
    invoiceCompletedSummary,
    invoiceOutstandingSummary,
    invoiceOverdueSummary,
    invoiceProcessingSummary,
  } = isEmpty ? staticPaymentSummaryData : invoiceSummaryData?.invoiceSummary ?? {};

  const paymentSummaryData = [
    { ...invoiceCompletedSummary, state: 'PAYMENT_COMPLETE' },
    { ...invoiceProcessingSummary, state: 'PAYMENT_PROCESSING' },
    { ...invoiceOutstandingSummary, state: 'PAYMENT_OUTSTANDING' },
    { ...invoiceOverdueSummary, state: 'PAYMENT_OVERDUE' },
  ];

  const handleSelectedFilterClick = (selectedFilters) => {
    const { filters, input, segmentEvent } = selectedFilters ?? {};
    sendSegmentEvent('rc_page_summary_click_widget', { payment_status: segmentEvent });
    setInvoiceFilters(filters);
    handleInvoiceListDrawerOpen({ input, filters });
  };

  const handleLearnMoreClick = () => {
    sendSegmentEvent('rc_page_summary_click_informational_drawer');
    learnMoreDrawerRef.current?.open();
  };

  const {
    paymentSummaryContainerStyles,
    titleStyles,
    paymentItemContainerStyles,
    summaryItemStyles,
    topPartStyles,
    bottomPartStyles,
  } = getPaymentSummaryStyles(isMinXL);

  return (
    <Stack {...paymentSummaryContainerStyles}>
      {/* Title */}
      <HStack>
        <Text {...titleStyles}>Payment Summary</Text>
        <Spacer />
        <BaselaneButton
          onClick={handleLearnMoreClick}
          variant="transparent"
          palette="primary"
          size="sm"
          leftIcon={<IconLightBulb />}
        >
          {isMinXL ? 'Learn More' : 'Learn About Payment Statuses'}
        </BaselaneButton>
        <LearnMoreDrawer {...{ learnMoreDrawerRef }} />
      </HStack>

      {/* Payment Items */}
      <HStack {...paymentItemContainerStyles}>
        {paymentSummaryData.map((item) => {
          const selectedFilters = getFormattedPaymentSummary(item);
          const { text, icon, timeStatus, amount, count } = selectedFilters;

          return (
            <Stack
              key={text}
              {...summaryItemStyles}
              onClick={() => handleSelectedFilterClick(selectedFilters)}
              ref={invoiceDrawerActionBtnRef}
              onMouseOver={() => {
                return getLeaseInvoices({ variables: { input: selectedFilters?.input || {} } });
              }}
            >
              {/* Top Part */}
              <Stack {...topPartStyles.container}>
                {icon}
                <Text {...topPartStyles.status}>{text}</Text>
              </Stack>
              {/* Bottom Part */}
              <Stack {...bottomPartStyles.container}>
                <Text {...bottomPartStyles.timeStatus}>{timeStatus}</Text>
                <Text {...bottomPartStyles.amount.container}>{amount}</Text>
                <HStack {...bottomPartStyles.numInvoice}>
                  <Text as="span">{count}</Text>
                  <Box as="span" {...bottomPartStyles.rightArrowContainer}>
                    <IconArrowRight color="#257ED0" w={13.33} h={6.67} />
                  </Box>
                </HStack>
              </Stack>
            </Stack>
          );
        })}
      </HStack>
    </Stack>
  );
};

export default PaymentSummary;
