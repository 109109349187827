import React from 'react';
import { BaselaneAlertNew } from '@shared/components';
import { Icon16Lightbulb } from '@icons/16px';

type UpdateReceivingAccAlertProps = {
  handleUpdateRecievingAccClick: Function,
};

const UpdateReceivingAccAlert = ({
  handleUpdateRecievingAccClick,
}: UpdateReceivingAccAlertProps) => {
  return (
    <BaselaneAlertNew
      {...{
        title: 'Supercharge your rent collection with Baselane Banking',
        body:
          'Rent is being deposited into your external account. Switch rent collection into your Baselane Banking account for free ACH payments, automated bookkeeping, 4.19% APY, virtual cards, and more.',
        visual: 'icon',
        iconName: Icon16Lightbulb,
        isVertical: true,
        actionProps: {
          secondary: {
            label: 'Update receiving account',
            onClick: handleUpdateRecievingAccClick,
          },
        },
      }}
    />
  );
};

export default UpdateReceivingAccAlert;
