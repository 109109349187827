export const toastVariants = {
  'left-accent': {
    container: {
      paddingStart: 1.5,
    },
  },
  'top-accent': {
    container: {
      pt: 1,
    },
  },
};
