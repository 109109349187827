/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Text, Spacer } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { BaselaneChip } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import { Icon16ChevronRight } from '@icons/16px';
import { labelByTransferState, chipVariantByTransferState } from './constants';

const ScheduledPayment = ({ scheduledPayment, recurringPaymentId }) => {
  if (!scheduledPayment) {
    return null;
  }
  let date = DateTime.fromISO(scheduledPayment.transferDate).toFormat('MMM d, yyyy');
  const navigate = useNavigate();

  if (scheduledPayment?.actualDate !== scheduledPayment?.transferDate) {
    const actualDate = DateTime.fromISO(scheduledPayment.actualDate).toFormat('MMM d');
    const explanation = scheduledPayment.isHoliday
      ? `(${actualDate} is a holiday)`
      : `(${actualDate} is on a weekend)`;

    date = `${date} ${explanation}`;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      cursor="pointer"
      onClick={() => navigate(scheduledPayment.id)}
      my={1.5}
    >
      <Box>
        <BaselaneChip
          size="sm"
          label={labelByTransferState[scheduledPayment?.state]}
          variant={chipVariantByTransferState[scheduledPayment?.state]}
        />
        <Text textStyle="xs">{date} </Text>
      </Box>
      <Spacer />

      <Text
        textStyle="sm-heavy"
        color={
          scheduledPayment?.state === 'TRANSFER_CANCELED'
            ? 'brand.neutral.500'
            : 'brand.neutral.900'
        }
      >
        {formatCurrency(scheduledPayment.amount).inDollars}
      </Text>

      <Box ml={1}>
        <Icon16ChevronRight />
      </Box>
    </Box>
  );
};

export default ScheduledPayment;
