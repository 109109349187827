import React from 'react';

function IconMobileCheckDeposit() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="22" y="20" width="22" height="22" rx="11" fill="#6ADA9B" />
      <path
        d="M35.2047 26.6016L28.6047 34.3016L25.3047 30.4516"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="30.8008"
        cy="31"
        r="11"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M34.4767 11.7109H19.7148" stroke="black" strokeLinecap="round" />
      <path d="M30.6672 14.5703H19.7148" stroke="black" strokeLinecap="round" />
      <path d="M27.8101 17.4297H19.7148" stroke="black" strokeLinecap="round" />
      <path
        d="M7.80859 19.4557C7.80859 20.7444 9.10199 21.7891 10.6975 21.7891H13.38C15.0895 21.7891 16.4753 20.6698 16.4753 19.2891C16.4753 17.9084 15.0895 16.7891 13.38 16.7891H10.9038C9.19438 16.7891 7.80859 15.6698 7.80859 14.2891C7.80859 12.9084 9.19438 11.7891 10.9038 11.7891H13.5864C15.1819 11.7891 16.4753 12.8337 16.4753 14.1224M12.1419 10.2891V11.7891M12.1419 21.7891V23.2891"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 18.913V10C39 7.79086 37.2091 6 35 6H8C5.79086 6 4 7.79086 4 10V24C4 26.2091 5.79086 28 8 28H17.125"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconMobileCheckDeposit;
