import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Text } from '@chakra-ui/react';
import { PROPERTIES } from '@routes';
import getBreakPoints from '@core/utils/getBreakPoints';
import BaselaneButton from '../../../BaselaneButton';
import { addPropertyStyles } from './styles/addProperty.styles';

const AddProperty = () => {
  const { isMinXL } = getBreakPoints();

  const { container, title, description } = addPropertyStyles({ isMinXL }) ?? {};

  return (
    <Stack {...container}>
      <Text {...title}>Add A Property To Create Lease</Text>
      <Text {...description}>
        To create a lease document, please add one or more of your properties.
      </Text>
      <RouterLink to={{ pathname: PROPERTIES }} style={{ margin: '0' }}>
        <BaselaneButton variant="filled" palette="primary" size="lg" onClick={() => {}}>
          Add Property
        </BaselaneButton>
      </RouterLink>
    </Stack>
  );
};

export default AddProperty;
