import React from 'react';
import ListOfParentAndChildrenWithTitle from './ListOfParentAndChildrenWithTitle';
import ListOfParentAndChildrenCheckboxesWithTitle from './ListOfParentAndChildrenCheckboxesWithTitle';
import DropdownSearchWrapper from '../../DropdownSearch/DropdownSearchWrapper';

type CustomDropdownProps = {
  isMulti: boolean,
  options: Array<any>,
  selectedStagedOptions: Array<any>,
  parentItemRenderer: Function,
  childItemRenderer: Function,
  showCustomCategories?: Boolean,
  dropdownElementRef: any,
  hasFilterWrapper: Boolean,
  title: String,
  search: String,
  setSearch: Function,
  searchRef: any,
  animationVariant?: String,
  scrollTop?: Number,
  hideSearch?: Boolean,
  dropDown?: Function,
  handleListItemClick: Function,
  handleSelectAll: Function,
  childrenSelectAllState: Object,
  showDivider: Boolean,
};

const CustomDropdown = ({
  isMulti,
  options,
  selectedStagedOptions,
  parentItemRenderer,
  childItemRenderer,
  showCustomCategories,
  dropdownElementRef,
  hasFilterWrapper,
  title,
  search,
  setSearch,
  searchRef,
  animationVariant,
  scrollTop,
  hideSearch,
  dropDown,
  handleListItemClick,
  handleSelectAll,
  childrenSelectAllState,
  showDivider,
}: CustomDropdownProps) => {
  return (
    <DropdownSearchWrapper
      {...{
        showCustomCategories,
        title,
        search,
        setSearch,
        searchRef,
        animationVariant,
        scrollTop,
        hideSearch,
        dropdownElementRef,
      }}
    >
      {isMulti ? (
        <ListOfParentAndChildrenCheckboxesWithTitle
          ref={dropdownElementRef}
          {...{
            options,
            selectedStagedOptions,
            parentItemRenderer,
            childItemRenderer,
            hasFilterWrapper,
            search,
            handleListItemClick,
            handleSelectAll,
            childrenSelectAllState,
            showDivider,
          }}
        />
      ) : (
        <ListOfParentAndChildrenWithTitle
          {...{
            isMulti,
            options,
            parentItemRenderer,
            childItemRenderer,
            dropdownElementRef,
            hasFilterWrapper,
            search,
            dropDown,
            selectedStagedOptions,
            handleListItemClick,
            childrenSelectAllState,
            showDivider,
          }}
        />
      )}
    </DropdownSearchWrapper>
  );
};

CustomDropdown.defaultProps = {
  showCustomCategories: null,
  animationVariant: null,
  scrollTop: 0,
  hideSearch: false,
  dropDown: () => {},
};

export default CustomDropdown;
