import React, { useEffect, useState } from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneButtonCard, BaselaneModal } from '@shared/components';
import { IllustrationRCResumeDraft, IllustrationRCSwapProperties } from '@illustrations';
import {
  existingDraftCardBodyStyles,
  existingDraftCardTitleStyles,
  existingDraftContainerStyles,
  existingDraftTitleStyles,
  existingDraftCardStyles,
  existingDraftContentStyles,
  existingDraftBodyStyles,
} from '../styles/unifiedLease.styles';

type ExistingDraftModalProps = {
  handleExistingDraft: Function,
  onCloseBtnClick: Function,
  isNewEmpty: boolean, // if user clicked an empty unit
};

function ExistingDraftModal({
  handleExistingDraft,
  onCloseBtnClick,
  isNewEmpty,
}: ExistingDraftModalProps): any {
  const { isMax768: isMobile } = getBreakPoints();
  const [isNewEmptyChanged, setIsNewEmptyChanged] = useState(false);

  useEffect(() => {
    // if isNewEmpty changes, close modal instead of showing changing text
    if (isNewEmptyChanged) {
      onCloseBtnClick();
    } else {
      setIsNewEmptyChanged(true);
    }
  }, [isNewEmpty]);

  return (
    <BaselaneModal
      defaultOpen
      showCloseButton
      onCloseBtnClick={onCloseBtnClick}
      showFooter={false}
      showHeader={false}
      isCentered
      modalBodyStyles={existingDraftBodyStyles(isMobile)}
      modalContentStyles={existingDraftContentStyles(isMobile)}
      hideButton
    >
      <Stack {...existingDraftContainerStyles(isMobile)}>
        <Box>
          {isNewEmpty ? (
            <Text {...existingDraftTitleStyles(isMobile)}>
              We see that you already started setting up rent collection
            </Text>
          ) : (
            <Text {...existingDraftTitleStyles(isMobile)}>
              We see that you already started setting up rent collection for this property/unit
            </Text>
          )}
        </Box>
        <Stack spacing="16px">
          <BaselaneButtonCard
            type="clean"
            onClick={() => handleExistingDraft('continue')}
            buttonStyles={existingDraftCardStyles(isMobile)}
          >
            <Flex>
              <IllustrationRCResumeDraft />
            </Flex>
            <Box marginInlineStart="0">
              {isNewEmpty ? (
                <Text className="hover-white" {...existingDraftCardTitleStyles}>
                  Start a new rent collection for this property/unit
                </Text>
              ) : (
                <Text className="hover-white" {...existingDraftCardTitleStyles}>
                  Resume with saved setup details
                </Text>
              )}
              <Text className="hover-white" {...existingDraftCardBodyStyles}>
                The current setup details will be saved with the previously selected property.
              </Text>
            </Box>
          </BaselaneButtonCard>
          <BaselaneButtonCard
            type="clean"
            onClick={() => handleExistingDraft('replace')}
            buttonStyles={existingDraftCardStyles(isMobile)}
          >
            <Flex>
              <IllustrationRCSwapProperties />
            </Flex>
            <Box>
              {isNewEmpty ? (
                <Text className="hover-white" {...existingDraftCardTitleStyles}>
                  Move current setup details
                </Text>
              ) : (
                <Text className="hover-white" {...existingDraftCardTitleStyles}>
                  Replace with current setup details
                </Text>
              )}
              {isNewEmpty ? (
                <Text className="hover-white" {...existingDraftCardBodyStyles}>
                  The in-progress setup details will be moved.
                </Text>
              ) : (
                <Text className="hover-white" {...existingDraftCardBodyStyles}>
                  The in-progress saved setup details will be replaced.
                </Text>
              )}
            </Box>
          </BaselaneButtonCard>
        </Stack>
      </Stack>
    </BaselaneModal>
  );
}

export default ExistingDraftModal;
