import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import { cardSummaryOverviewValueStyles } from '@core/components/NativeBankingPage/Summary/styles/newsummary.styles';

/**
 * Helper function current apy depending on user state with baselane bank.
 */
export const getCurrentAPY = ({
  isUserEligibleForPromo,
  isEmptyState,
  isLargerThan899,
  currentApy,
  userPromoApy,
  maxTier,
}) => {
  if (isEmptyState) {
    return isUserEligibleForPromo ? (
      <Box {...cardSummaryOverviewValueStyles(!isLargerThan899)}>
        {formatCurrency(userPromoApy, true).noDollar}
        <Text
          as="span"
          fontSize={{
            base: 'inherit',
            lg: 'lg',
          }}
        >
          %
        </Text>
      </Box>
    ) : (
      <Box {...cardSummaryOverviewValueStyles(!isLargerThan899)}>
        Up to {maxTier?.product?.value ?? '--'}
        <Text as="span" fontSize={{ base: 'inherit', lg: 'lg' }}>
          %
        </Text>
      </Box>
    );
  }

  return (
    <Box {...cardSummaryOverviewValueStyles(!isLargerThan899)}>
      {currentApy ? formatCurrency(currentApy, true).noDollar : '--'}
      <Text as="span" fontSize={{ base: 'inherit', lg: 'lg' }}>
        %
      </Text>
    </Box>
  );
};

/**
 * Helper function to get title for section below divder in apy section of overview.
 */
export const getTierTitle = ({
  isEmptyState,
  isUserEligibleForPromo,
  isLargerThan899,
  maxTier,
  hasSavingsAccount,
  showNewSavingAccountUI,
}) => {
  let tierTitle = showNewSavingAccountUI && !hasSavingsAccount ? null : "Next Month's APY";
  if (isLargerThan899 && isEmptyState && !isUserEligibleForPromo) {
    tierTitle = `Unlock up to ${maxTier?.product?.value ?? '--'}% APY`;
  } else if (isEmptyState && isUserEligibleForPromo && isLargerThan899) {
    tierTitle = null;
  } else if (!isLargerThan899 && isEmptyState && isUserEligibleForPromo) {
    tierTitle = 'APY for the first 3 months';
  } else if (!isLargerThan899 && isEmptyState && !isUserEligibleForPromo) {
    tierTitle = 'Eligible APY';
  }

  return tierTitle;
};
