// @flow
import React from 'react';

import {
  Grid,
  GridItem,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { BaselaneBadge } from '@shared/components';
import {
  textStyles,
  textLightStyles,
  buttonStyles,
  iconStyles,
  gridStyles,
  nestedGridStyles,
} from './styles/propertyList.styles';
import {
  getDaysRemainingUntil,
  rentStatusHelper,
  getOverallRentStatus,
  getOverallOccupancyStatus,
  getIcon,
  getIconStyles,
} from './helpers/propertyList.helpers';

type PropertyListRowProps = { property: Array, columns: string };

function PropertyListRow({ property, columns }: PropertyListRowProps): any {
  const { units } = property;
  const hasUnits = units.length > 1; // If property has only one unit than consider the unit as the property
  const status = getOverallRentStatus(property);
  // const isCurrent = status.toLowerCase().indexOf('current') !== -1;

  return hasUnits ? (
    <Accordion allowToggle allowMultiple>
      <AccordionItem border="0" p="0">
        <AccordionButton {...buttonStyles}>
          <AccordionIcon {...iconStyles} />
          <Grid templateColumns={columns} {...gridStyles}>
            <GridItem>
              <Text {...textStyles} fontWeight="medium">
                {property.name}
              </Text>
            </GridItem>
            <GridItem>
              <Text {...textStyles}>{getOverallOccupancyStatus(property)}</Text>
            </GridItem>
            <GridItem>
              <BaselaneBadge
                text={status}
                iconName={getIcon(property, 'PROPERTY')}
                iconClassName="icon-xl"
                fontStyles={getIconStyles(status)}
              />
            </GridItem>
          </Grid>
        </AccordionButton>

        <AccordionPanel p="0">
          {units.map((unit) => {
            const unitStatus = rentStatusHelper(unit);

            return (
              <Grid key={unit.id} templateColumns={columns} {...nestedGridStyles}>
                <GridItem pl="16px">
                  <Text {...textStyles}>{unit.name}</Text>
                </GridItem>
                <GridItem>
                  <Text {...textStyles}>
                    {unit.status === 'occupied' || unit.status === 'outside_baselane'
                      ? 'Occupied'
                      : 'Vacant'}
                  </Text>
                  <Text {...textLightStyles}>
                    {unit.lease &&
                      getDaysRemainingUntil(
                        unit.lease.startDate,
                        unit.lease.endDate,
                        unit.lease.leaseType,
                        unit.status
                      )}
                  </Text>
                </GridItem>
                <GridItem>
                  <BaselaneBadge
                    text={unitStatus}
                    iconName={getIcon(unit, 'UNIT')}
                    iconClassName="icon-xl"
                    fontStyles={getIconStyles(unitStatus)}
                  />
                </GridItem>
              </Grid>
            );
          })}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : (
    <Grid templateColumns={columns} {...gridStyles}>
      <GridItem>
        <Text {...textStyles} fontWeight="medium">
          {property.name}
        </Text>
      </GridItem>
      <GridItem>
        <Text {...textStyles}>{getOverallOccupancyStatus(property)}</Text>
        <Text {...textLightStyles}>
          {property.units[0]?.lease &&
            getDaysRemainingUntil(
              property.units[0].lease.startDate,
              property.units[0].lease.endDate,
              property.units[0].lease.leaseType,
              property.units[0].status
            )}
        </Text>
      </GridItem>
      <GridItem>
        <BaselaneBadge
          text={status}
          iconClassName="icon-xl"
          iconName={getIcon(property, 'PROPERTY')}
          fontStyles={getIconStyles(status)}
        />
      </GridItem>
    </Grid>
  );
}

export default PropertyListRow;
