export const pageHeaderStyles = (isMin768) => ({
  justifyContent: 'flex-start',
  py: 2,
  px: isMin768 ? 4 : 2,
  mb: 0,
});

export const pageWrapperStyles = ({ isMax576, isMin768 }) => {
  let py = 2;
  if (isMin768) py = 10;
  else if (!isMax576) py = 4;

  return {
    py,
    px: 2,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  };
};

export const cardStyles = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  p: 2,
  gap: 1.5,
};
