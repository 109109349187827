import { defaultHeaderRowStyles } from './header.styles';
import { wFull, hFull } from './shared.styles';

const disabledRowStyles = {
  borderBottom: 'brand.neutral.200',
  background: 'brand.neutral.white',
  color: 'brand.neutral.400',
};

const focusRowStyles = {
  borderTopColor: 'brand.darkBlue.100',
  background: 'brand.darkBlue.100',
  color: 'brand.neutral.500',
  outline: 'none',
};

const sharedRowStyles = {
  ...wFull,
  alignItems: 'left',
  borderTop: 'solid 1px',
  borderTopColor: 'brand.neutral.white',
  borderBottom: 'solid 1px',
  borderBottomColor: 'brand.darkBlue.100',
  color: 'brand.neutral.500',
};

export const defaultRowStyles = (isHeader, isClickable) => {
  return isHeader
    ? { ...sharedRowStyles, ...defaultHeaderRowStyles }
    : {
        ...sharedRowStyles,
        _hover: {
          borderTopColor: 'brand.darkBlue.50',
          background: isClickable && 'brand.darkBlue.50',
          color: 'brand.neutral.700',
        },
        _active: {
          borderTopColor: 'brand.darkBlue.100',
          background: isClickable && 'brand.darkBlue.100',
          color: 'brand.neutral.700',
        },
        _focus: {
          ...focusRowStyles,
        },
        _focusVisible: {
          ...focusRowStyles,
        },
        _disabled: {
          ...disabledRowStyles,

          _hover: {
            ...disabledRowStyles,
          },
          _active: {
            ...disabledRowStyles,
          },
          _focus: {
            ...disabledRowStyles,
          },
          _focusVisible: {
            ...disabledRowStyles,
          },
        },
      };
};

export const indicatorStyles = (color) => {
  return {
    w: '0px !important',
    ...hFull,
    p: '0 !important',
    m: '0 !important',
    position: 'relative',
    left: 'unset',
    _before: {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      w: '3px',
      h: 'calc(100% - 16px)',
      backgroundColor: color,
      borderRadius: '0 4px 4px 0',
    },
  };
};

export const clickableStyles = (isClickable) => {
  return {
    w: '48px !important',
    ...hFull,
    p: isClickable ? '0 16px !important' : '0 !important',
    m: '0 !important',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  };
};
