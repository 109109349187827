export const summaryContainerStyles = (isDesktop) => ({
  h: 'auto',
  w: isDesktop ? '868px' : '',
  my: 3,
  mx: 2,
});

export const summaryContentStyles = (isDesktop) => ({
  display: 'flex',
  justifyContent: isDesktop ? 'space-around' : 'space-between',
  padding: { md: '8px 24px', base: '0 16px' },
});

export const summaryCardTitleStyles = {
  textStyle: 'md',
  color: 'brand.neutral.700',
};

export const summaryCardSubtitleStyles = {
  textStyle: 'xs',
  m: '2px 0 0 4px',
  color: 'brand.neutral.600',
};

export const emptyStateCardStyles = {
  boxShadow: 'none',
  mt: '40px',
  p: '80px 24px 0',
};

export const emptyStateCardHeaderStyles = {
  textAlign: 'left',
  fontSize: '2xl',
  lineHeight: '32px',
};

export const emptyStateCardButtonStyles = {
  size: 'lg',
  width: '100%',
  mt: '32px',
  bg: 'brand.blue.800A',
};

export const currencyTextStyle = (isDesktop) => ({
  fontSize: isDesktop ? '2xl' : 'md',
  fontWeight: 'medium',
  lineHeight: isDesktop ? '32px' : '24px',
  color: 'brand.neutral.700',
  marginTop: '0px',
});

export const elementStyles = (isDesktop) => ({
  pl: isDesktop ? '24px' : '8px',
});

export const firstItemStyles = (isDesktop) => ({
  pl: isDesktop ? '24px' : '0px',
});

export const customBorderStyles = (isDesktop) => ({
  pl: isDesktop ? '32px' : '0px',
  borderLeftWidth: isDesktop ? '1px' : '0px',
});

export const customFontStyles = (isDesktop) => ({
  fontWeight: 'normal',
  color: isDesktop ? 'brand.neutral.700' : 'brand.neutral.600',
});

export const propertyCardContainerStyles = {
  height: 'auto',
  marginTop: '0px !important',
  borderColor: 'brand.darkBlue.100',
  borderBottomWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '0',
  boxShadow: 'none',
};

export const propertyCardHeaderStyles = {
  border: 'none',
};

export const propertyCardCustomHeaderStyles = (isDesktop) => ({
  padding: isDesktop ? '24px' : '24px 16px 0 16px',
  border: 'none',
});

export const propertyCardHeaderTitleStyles = (isDesktop) => ({
  textStyle: isDesktop ? 'headline-lg' : 'sm-heavy',
  color: 'brand.neutral.900',
});

export const propertyCardContentStyles = (isDesktop) => ({
  padding: isDesktop ? '24px' : '0 16px 12px',
  fontSize: isDesktop ? 'sm' : 'xs',
  lineHeight: isDesktop ? '24px' : '20px',
});

export const baselaneMessageCardContainerStyles = {
  m: '16px 16px 88px',
  fontSize: 'sm',
};

export const financialCardHeaderStyles = (isDesktop) => ({
  padding: isDesktop ? '24px' : '24px 20px 24px 12px',
});

export const financialCardHeaderTitleStyles = (isDesktop) => ({
  textStyle: isDesktop ? 'lg' : 'md',
  color: 'brand.neutral.700',
});

export const financialCardDetailsLinkStyles = (isDesktop) => ({
  fontSize: isDesktop ? 'md' : 'xs',
  color: 'brand.blue.700',
  background: 'none',
  border: 'none',
});

export const financialCardContentStyles = (isDesktop) => ({
  padding: isDesktop ? '24px' : '16px',
  fontSize: isDesktop ? 'sm' : 'xs',
  lineHeight: isDesktop ? '24px' : '20px',
});

export const menuItemStyles = {
  _hover: { color: 'brand.blue.700', bg: 'brand.blue.50', borderRadius: '4px' },
  fontSize: 'xs',
  p: '12px',
  color: 'brand.blue.700',
  lineHeight: '20px',
  _notLast: {
    mb: '8px',
  },
  width: '100%',
};

export const menuListStyles = {
  p: '16px',
  borderRadius: '4px',
  boxShadow: '0px 4px 12px rgba(4, 39, 101, 0.12)',
  borderWidth: '0',
  zIndex: 1000,
};

export const menuButtonStyles = {
  p: '6px 12px 8px !important',
};

export const menuButtonIconStyles = {
  fontSize: 'md',
  fontWeight: 'medium',
  position: 'relative',
  top: '-4px',
};

export const addPropertyHeaderTextStyles = {
  fontSize: '2xl',
  lineHeight: '32px',
  fontWeight: 'medium',
  color: 'brand.neutral.700',
  m: '62px 72px 16px 0',
};

export const textLabelStyle = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
  m: '18px 0 16px',
};

export const formLabelStyle = {
  fontWeight: 'normal',
  fontSize: 'xs',
  lineHeight: '20px',
  color: 'brand.neutral.700',
  height: '24px',
  m: '8px 0 8px',
};

export const cFormControlStyles = {
  p: '0',
};

export const cFormControlBorder = {
  borderBottom: '1px',
  borderBottomColor: 'brand.darkBlue.250',
  mb: '16px',
};

export const cDetailsContainerStyles = {
  pl: '0',
  pr: '0',
  mt: '0',
};

export const cfieldContainer = {
  minHeight: 'auto',
  position: 'relative',
  mb: '8px',
};

export const mobileTitleTextStyles = {
  lineHeight: '26px',
  fontWeight: 'medium',
  color: 'brand.neutral.700',
  p: '0',
  mt: '2px',
  ml: '8px!important',
};

export const mobileBodyStyles = {
  p: '0',
  m: '16px 0 0',
  fontSize: 'sm',
  lineHeight: '20px',
  fontWeight: 'normal',
  color: 'brand.neutral.600',
};

export const mobileIconBgStyles = { bg: 'brand.blue.100' };
export const mobileIconSecondaryBgStyles = { bg: 'red.200' };
export const mobileIconContainerStyles = {
  ml: '-1px',
  mt: '-1px',
  color: 'red.500A',
};

export const alertCreatePropertyBody = {
  p: '0',
  m: '32px',
  fontSize: 'sm',
  lineHeight: '20px',
  color: 'brand.neutral.600',
  width: '98%',
};

export const mobileAlertContainerStyles = {
  p: '32px 16px 24px',
  m: '6px',
  justifyContent: 'left',
  borderRadius: '12px',
};

export const mobileAlertheaderStyles = {
  fontSize: 'lg',
  lineHeight: '26px',
  fontWeight: 'medium',
  color: 'brand.neutral.700',
  pl: '0',
};

export const propertyStepMenuContainerStyles = {
  width: '100%',
  p: '0',
  overflow: 'auto',
  align: 'stretch',
  justify: 'flex-start',
};

export const fontTitleStyle1 = {
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
  mt: '12px',
  mb: '8px',
};

export const fontListStyle1 = {
  fontSize: 'xs',
};

export const textStyles = {
  textStyle: 'headline-sm',
  textAlign: 'right',
  color: 'brand.neutral.700',
};
