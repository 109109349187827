import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Text } from '@chakra-ui/react';
import {
  darkDescriptionTextStyles,
  listItemStyles,
  listStyles,
  sectionSubTitleStyles,
  sectionTitleStyles,
} from '@core/components/NativeBankingPage/components/styles/emptyState.shared.styles';
import { IconBankingAccount, IconBankPlus } from '@icons';
import { BaselaneButton, BaselaneList, BaselaneListItem } from '@shared/components';
import {
  baselaneAccList,
  subAccList,
} from '@core/components/NativeBankingPage/components/AddBaselaneAccount/helpers';

const listItemRenderer = (item) => {
  const key = uuidv4();
  const iconLabel = item.label ? <item.label /> : <Box w="14px" />;

  return (
    <BaselaneListItem
      key={key}
      label={iconLabel}
      value={item.value}
      containerStyle={{ ...listItemStyles.containerStyle, _notFirst: { mt: '16px !important' } }}
      labelStyle={{ ...listItemStyles.labelStyle }}
      valueStyle={{ ...listItemStyles.valueStyle }}
    />
  );
};

const vaDescription = (isMin768) => (
  <Box p={isMin768 ? '40px' : '30px'} pb="0">
    <IconBankPlus w="30" h="30" color="#257ED0" />
    <Text {...sectionTitleStyles} my="16px">
      Add a Virtual Account
    </Text>
    <Text {...darkDescriptionTextStyles}>
      For an existing Baselane Banking account, you can go into your account and press “Add Virtual
      Account” button to open up a new Virtual Account.
    </Text>
  </Box>
);
const openApplication = (isMin768, userHasDraftApplication, onBaselaneApplicationTypePopupOpen) => {
  let buttonText = 'Open Baselane Banking Account';
  if (userHasDraftApplication) {
    buttonText = 'Continue Application';
  }

  return (
    <Box p={isMin768 ? '40px' : '30px'} pb="0">
      <IconBankingAccount w="30" h="30" />
      <Text {...sectionTitleStyles} my="16px">
        Open a New Baselane Banking Account
      </Text>
      <Text {...darkDescriptionTextStyles} mb="16px">
        For a different legal entity or individual
      </Text>
      <BaselaneButton
        id={
          userHasDraftApplication ? 'continue-baselane-app-button' : 'open-baselane-account-button'
        }
        variant="tonal"
        palette="primary"
        size="md"
        onClick={onBaselaneApplicationTypePopupOpen}
      >
        {buttonText}
      </BaselaneButton>
    </Box>
  );
};

const vaDescriptionPoints = (isMin768) => (
  <Box p={isMin768 ? '40px' : '30px'}>
    <Text {...sectionSubTitleStyles} mb="16px">
      When to add a virtual account:
    </Text>
    <BaselaneList
      data={subAccList}
      itemRenderer={listItemRenderer}
      listStyles={{ ...listStyles, border: 'none', p: '0' }}
    />
  </Box>
);

const continueApplicationDescriptionPoints = (isMin768) => (
  <Box p={isMin768 ? '40px' : '30px'}>
    <Text {...sectionSubTitleStyles} mb="16px">
      When to open a new Baselane Banking account:
    </Text>
    <BaselaneList
      data={baselaneAccList}
      itemRenderer={listItemRenderer}
      listStyles={{ ...listStyles, border: 'none', p: '0' }}
    />
  </Box>
);

export const desktopGridItems = ({
  isMin768,
  userHasDraftApplication,
  onBaselaneApplicationTypePopupOpen,
}) => [
  {
    element: vaDescription(isMin768),
    styles: {
      borderRightWidth: '1px',
    },
  },
  {
    element: openApplication(isMin768, userHasDraftApplication, onBaselaneApplicationTypePopupOpen),
    styles: {},
  },
  {
    element: vaDescriptionPoints(isMin768),
    styles: {
      borderRightWidth: '1px',
    },
  },
  {
    element: continueApplicationDescriptionPoints(isMin768),
  },
];

export const mobileGridItems = ({
  isMin768,
  userHasDraftApplication,
  onBaselaneApplicationTypePopupOpen,
}) => [
  {
    element: openApplication(isMin768, userHasDraftApplication, onBaselaneApplicationTypePopupOpen),
  },
  {
    element: continueApplicationDescriptionPoints(isMin768),
    styles: { borderBottomWidth: '1px' },
  },
  {
    element: vaDescription(isMin768),
    styles: {},
  },
  {
    element: vaDescriptionPoints(isMin768),
    styles: {},
  },
];
