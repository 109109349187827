export const checkIsAllowedFileName = ({ name }) => {
  const allowedPattern = /^[A-Za-z0-9!.()'*_-\s]*$/;
  const isAllowedName = !!name.match(allowedPattern);
  return isAllowedName;
};

export const checkIsAllowedFileNameLength = ({ name }) => {
  const isAllowedNameLength = name.replace(/\.[^/.]+$/, '').length > 0;
  return isAllowedNameLength;
};

export const checkIsAllowedFileType = ({ allowedFileType, selectedFileType }) => {
  const fileExtension = selectedFileType?.split('/')[1]?.toUpperCase();
  return allowedFileType.some((type) => type === fileExtension);
};

export const checkIsUniqueFileName = ({ documents, name }) => {
  const hasDuplicateName = documents.some((doc) => doc.name === name);
  return !hasDuplicateName;
};

export const getValidationMessage = ({ errorType, allowedFileType = {} }) => {
  switch (errorType) {
    case 'INVALID_FILE_TYPE':
      return `Could not add attachment, invalid file type. Supported file types: ${allowedFileType.text.join(
        ', '
      )}.`;

    case 'EMPTY_FILE_NAME':
      return 'File name should not be empty or spaces only.';

    case 'INVALID_FILE_NAME':
      return "File contains invalid special characters, only the following special characters are allowed: ! - _ . * ' ( )";

    case 'MAX_FILE_SIZE_ERROR':
      return 'Could not add attachment, over max size: 10MB';

    case 'MAX_DOCUMENT_UPLOAD_LIMIT_REACHED':
      return 'Failed to upload attachment, please try again. If problem persists contact support';

    case 'DUPLICATE_FILE_NAME':
      return 'Cannot have the same file name in the same Transaction';

    case 'MAX_FILE_NUMBER_ERROR':
      return 'Only 1 attachment can be uploaded at a time';

    case 'GENERIC':
      return 'Failed to upload attachment, please try again. If problem persists contact support';

    default:
      return null;
  }
};
