// imported from recipients.js
export const recipientsStyles = {
  pageContainerStyles: {
    my: 4,
  },
  headerContainerStyles: {
    w: '100%',
    px: 1.5,
  },
};

const textOverflowWrapStyles = {
  textOverflow: 'inherit',
  whiteSpace: 'wrap',
  overflow: 'visible',
};

// imported from RecipientsTable > TableBody.jsx
export const recipientsTableBodyStyles = {
  nameStyles: {
    avatar: {
      bg: 'brand.neutral.150',
    },
    styles: {
      graphicElementContainer: { w: '40px' },
      titleTextStyle: textOverflowWrapStyles,
      color: 'red',
    },
  },
  paymentMethodsStyles: {
    text: {
      textStyle: 'sm',
      color: 'brand.neutral.700',
      ...textOverflowWrapStyles,
    },
  },
  lastPaidStyles: {
    container: {
      gap: 0,
      w: 'inherit',
    },
    text: {
      textStyle: 'sm',
      color: 'brand.neutral.700',
    },
    subtext: {
      textStyle: 'xs',
      color: 'brand.neutral.600',
      ...textOverflowWrapStyles,
    },
  },
  sharedCellStyles: {
    px: 1.5,
    py: 2,
  },
};

// imported from NoRecipients.jsx
export const noRecipientStyles = {
  containerStyles: {
    mx: 3,
    my: 10,
    py: 5,
    alignItems: 'center',
    gap: 2,
  },
  textContainerStyles: {
    gap: 1,
    alignItems: 'center',
    textAlign: 'center',
  },
};
