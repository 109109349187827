import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Stack,
} from '@chakra-ui/react';
import { BaselaneSpinner } from '@shared/components';
import DeleteTenantAlertBody from './DeleteTenantAlertBody';
import DeleteTenantAlertFooter from './DeleteTenantAlertFooter';

type ArchiveTenantAlertProps = {
  isAlertOpen: boolean,
  onAlertClose: Function,
  onDelete: Function,
  leases: Array<Object>,
  leasesLoading: Boolean,
};

const ArchiveTenantAlert = ({
  isAlertOpen,
  onAlertClose,
  onDelete,
  leases,
  leasesLoading,
}: ArchiveTenantAlertProps) => {
  const alertCancelRef = useRef(null);

  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      onClose={onAlertClose}
      isOpen={isAlertOpen}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="sm"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4} minHeight="168px" maxHeight="calc(100% - 100px)" overflow="auto">
        <Stack spacing={2}>
          <AlertDialogCloseButton />
          {leasesLoading ? <BaselaneSpinner /> : <DeleteTenantAlertBody leases={leases} />}
        </Stack>
        {!leasesLoading && (
          <DeleteTenantAlertFooter
            onAlertClose={onAlertClose}
            onDelete={onDelete}
            hasActiveLease={leases.length}
          />
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ArchiveTenantAlert;
