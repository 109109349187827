import { keyframes } from '@chakra-ui/react';

// keyframes
const slideInAnimation = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 168px
    }
`;

const slideOutAnimation = keyframes`
    from {
        top: 168px
    }
    to {
        top: 100%;
    }
`;

const slideInAnimationMobile = keyframes`
    from {
        bottom: -100%;
    }
    to {
        bottom: 0;
    }
`;

const slideOutAnimationMobile = keyframes`
    from {
        bottom: 0;
    }
    to {
        bottom: -100%;
    }
`;

const desktopStyles = {
  top: '168px',
};

const mobileStyles = {
  bottom: 0,
};

export const animationStyles = {
  slideInAnimation,
  slideOutAnimation,
  slideInAnimationMobile,
  slideOutAnimationMobile,
  desktopStyles,
  mobileStyles,
};
