import React, { Fragment } from 'react';
import { Checkbox, Stack, Text } from '@chakra-ui/react';
import BaselaneLink from '@shared/components/BaselaneLink';
import { links } from './helpers/link.helpers';
import { checkboxTextStyles } from './styles/terms.styles';

type AgreeToTermsProps = {
  onChange: Function,
  isChecked: boolean,
  linkVariant?: string,
  isSavings?: boolean,
};

const AgreeToTerms = ({ onChange, isChecked, linkVariant, isSavings }: AgreeToTermsProps) => {
  return (
    <Stack direction="row" spacing={1}>
      <Checkbox
        id="agreeToTerms"
        name="agreeToTerms"
        size="md"
        className="primary-500"
        isChecked={isChecked}
        onChange={onChange}
        h="16px"
      />
      <Text {...checkboxTextStyles}>
        I agree to the&nbsp;
        {links(isSavings).map((linkData, index) => {
          const { linkText, href } = linkData;

          const link = (
            <BaselaneLink href={href} target="_blank" isExternal variant={linkVariant}>
              {linkText}
            </BaselaneLink>
          );

          if (index === links.length - 1) {
            return <Fragment key={linkText}>{link}.</Fragment>;
          }

          return (
            <Fragment key={linkText}>
              {link}
              ,&nbsp;
            </Fragment>
          );
        })}
      </Text>
    </Stack>
  );
};

AgreeToTerms.defaultProps = {
  linkVariant: 'primary',
  isSavings: false,
};

export default AgreeToTerms;
