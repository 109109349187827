import React from 'react';

function IconApartment({
  w = 40,
  h = 47,
  color = 'currentColor',
}: {
  w?: string,
  h?: string,
  color?: string,
}) {
  return (
    <svg width={w} height={h} viewBox="0 0 40 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.289.244h14.933a5.689 5.689 0 015.69 5.69v35.2a5.689 5.689 0 01-5.69 5.688H19.29a5.689 5.689 0 01-5.689-5.689v-35.2A5.689 5.689 0 0119.29.244zm0 2.845a2.844 2.844 0 00-2.845 2.844v35.2a2.844 2.844 0 002.845 2.845h14.933a2.844 2.844 0 002.845-2.845v-35.2a2.844 2.844 0 00-2.845-2.844H19.29z"
        fill={color}
      />
      <path
        d="M2.933 26.45c0-1.213.77-2.293 1.918-2.689l14.933-5.15a2.844 2.844 0 013.772 2.69v19.832a2.844 2.844 0 01-2.845 2.845H5.778a2.844 2.844 0 01-2.845-2.845V26.45z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.923 21.072l14.934-5.15c3.694-1.273 7.543 1.47 7.543 5.379v19.832a5.689 5.689 0 01-5.689 5.69H5.778a5.689 5.689 0 01-5.69-5.69V26.45a5.69 5.69 0 013.835-5.378zm.927 2.689a2.844 2.844 0 00-1.917 2.69v14.682a2.844 2.844 0 002.845 2.845H20.71a2.844 2.844 0 002.845-2.845V21.301a2.844 2.844 0 00-3.772-2.69L4.85 23.762z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.955 46.111a1.422 1.422 0 01-1.422-1.422V36.51a1.422 1.422 0 012.845 0v8.178c0 .785-.637 1.422-1.423 1.422z"
        fill={color}
      />
    </svg>
  );
}

IconApartment.defaultProps = {
  w: '40',
  h: '47',
  color: 'currentColor',
};

export default IconApartment;
