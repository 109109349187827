import React from 'react';
import { Box, TabPanels, VStack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { FormTitle } from '@shared/components/BaselaneFlow';
import { BaselaneMessageCard } from '@shared/components';
import DisclaimerText from '@shared/components/DisclaimerText';
import type { FlowPanelProps } from '@shared/components/BaselaneFlow/types/FlowTabPanelProps';
import {
  cStyles,
  headerStyles,
  tabPanelsStyles,
  tabPanelsWrapperStyles,
  submitErrorStyles,
  submitErrorTextStyles,
  iconContainerStyles,
} from '@core/components/OnboardingTriage/styles/flowSections.styles';

import TabRoutes from './TabRoutes';
import { disclaimerContainerStyles } from '../styles/unifiedBankingFlow.styles';

type SolePropPanelsProps = {
  applicationMenuOptions: Array<FlowPanelProps>,
  tabIndex: Number,
  setTabIndex: Function,
  formRefs: any,
  handleSolePropContinue: Function,
  formData: Object,
  submitLBDataLoading: boolean,
  isRefetchUserLoading: boolean,
  showVerifyPopup: boolean,
  setShowVerifyPopup: Function,
  onVerifyOtpSuccess: Function,
  hasSubmitErrors: boolean,
  setHasSubmitErrors: Function,
  submitErrors: Array<String>,
  setSubmitErrors: Function,
  submitErrorsTitle: String,
  setSubmitErrorsTitle: Function,
};

const SolePropPanels = ({
  applicationMenuOptions,
  tabIndex,
  setTabIndex,
  formRefs,
  handleSolePropContinue,
  formData,
  submitLBDataLoading,
  isRefetchUserLoading,
  showVerifyPopup,
  setShowVerifyPopup,
  onVerifyOtpSuccess,
  hasSubmitErrors,
  setHasSubmitErrors,
  submitErrors,
  setSubmitErrors,
  submitErrorsTitle,
  setSubmitErrorsTitle,
}: SolePropPanelsProps) => {
  return (
    <VStack {...tabPanelsWrapperStyles} {...{ height: '100%' }}>
      {tabIndex === 3 && hasSubmitErrors === true && (
        <BaselaneMessageCard
          iconName="close"
          iconColor="red"
          borderColor="red"
          isVertical
          title={submitErrorsTitle ?? 'Application could not be submitted'}
          text={submitErrors ?? 'Please try again later or contact support if this issue persists.'}
          styles={{ ...submitErrorStyles }}
          textStyles={{ ...submitErrorTextStyles }}
          iconWidth="14"
          iconHeight="14"
          iconContainerStyles={{ ...iconContainerStyles }}
        />
      )}

      <FormTitle
        title={`STEP ${tabIndex + 1} of 4`}
        textStyles={{ ...headerStyles, mt: 0, pt: 0 }}
        containerStyles={cStyles}
      />
      <TabPanels id="landlord-banking-panels" {...tabPanelsStyles}>
        <TabRoutes
          {...{
            formRefs,
            tabIndex,
            setTabIndex,
            applicationMenuOptions,
            handleSolePropContinue,
            formData,
            submitLBDataLoading,
            isRefetchUserLoading,
            showVerifyPopup,
            setShowVerifyPopup,
            onVerifyOtpSuccess,
            setHasSubmitErrors,
            setSubmitErrors,
            setSubmitErrorsTitle,
          }}
        />
        {!isMobile && tabIndex === 3 && (
          <Box {...disclaimerContainerStyles}>
            <DisclaimerText />
          </Box>
        )}
      </TabPanels>
    </VStack>
  );
};

export default SolePropPanels;
