import React, { useContext } from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { BaselaneAutoTagPropertyDropdown } from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import { headingSectionTextStyles } from './styles/font.styles';

type AutoTagDropdownProps = {
  values: Object,
  handleSave: Function,
  isDisabled: boolean,
};

const AutoTagDropdown = ({ values, handleSave, isDisabled }: AutoTagDropdownProps) => {
  const { propertiesData } = useContext(TransactionContext);
  const propertyOptions = getPropertyData(propertiesData);
  const hasNoPropertyOptions = propertyOptions?.length === 0;
  return (
    <Stack spacing="8px" paddingLeft={hasNoPropertyOptions ? '2px' : '0'}>
      <Text {...headingSectionTextStyles}>Auto-tag all transactions to a property or unit</Text>
      <BaselaneAutoTagPropertyDropdown values={values} isDisabled={false} onChange={handleSave} />
    </Stack>
  );
};

export default AutoTagDropdown;
