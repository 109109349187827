export const successToastConfig = {
  description: 'Saved changes',
  status: 'success',
  duration: '3000',
  isClosable: true,
  position: 'bottom-left',
};

export const errorToastConfig = {
  description: 'Something went wrong. Please try again',
  status: 'error',
  duration: '3000',
  isClosable: true,
  position: 'bottom-left',
};

export const getDynamicPopupCaptions = (hasLeaseCollectingRentOutsideBaselane) => {
  return hasLeaseCollectingRentOutsideBaselane
    ? {
        title: 'Apply to all upcoming payments?',
        list: [
          {
            id: 0,
            caption: 'The new setting will apply to any future  payments into an external account.',
          },
          { id: 1, caption: 'We’ll notify the tenants about the change.' },
          { id: 2, caption: 'This won’t impact processing or completed payments.' },
        ],
      }
    : {
        title: 'Apply to future payments?',
        list: [
          {
            id: 0,
            caption: 'The new setting will apply to any future payments into an external account.',
          },
          {
            id: 1,
            caption: 'This won’t impact processing or completed payments.',
          },
        ],
      };
};
