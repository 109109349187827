export const successContainerStyles = (isMobile) => ({
  spacing: isMobile ? '32px' : '60px',
  overflow: 'auto',
  h: '100%',
  bgColor: 'brand.neutral.white',
});

export const successBannerContainerStyles = (isMobile) => ({
  maxWidth: { lg: '736px', base: 'initial' },
  my: isMobile ? '32px' : '38px',
  mx: { lg: 'auto', base: isMobile ? '24px' : '32px' },
  spacing: '82px',
  align: 'center',
});

export const successBannerTextContainerStyles = (isMobile) => ({
  w: isMobile ? '100%' : { base: '100%', sm: '50%' },
  align: isMobile ? 'center' : 'initial',
});

export const successBottomSectionContainerStyles = (isMobile) => ({
  maxWidth: { lg: '736px', base: 'initial' },
  mb: isMobile ? '24px' : '32px',
  mx: { lg: 'auto', base: isMobile ? '24px' : '32px' },
  justifyContent: isMobile ? 'space-between' : 'initial',
  height: 'calc(100% - 32px)',
});

export const successBottomTextContainerStyles = {
  spacing: '8px',
  mb: '30px !important',
};

export const buttonContainerStyles = (isMobile) => ({
  flexDirection: isMobile ? 'column-reverse' : 'initial',
  spacing: '10px',
});

export const protectedMessageContainerStyles = {
  color: 'brand.neutral.700',
  align: 'center',
};
