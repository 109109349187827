import { create } from 'zustand';

const useCreateTransactionDrawerStore = create((set) => ({
  isAddTransactionDrawerOpen: false,
  isDrawerAlertOpen: false,
  toggleAddTransactionDrawer: () =>
    set((state) => ({ isAddTransactionDrawerOpen: !state.isAddTransactionDrawerOpen })),
  onAlertOpen: () => set({ isDrawerAlertOpen: true }),
  onAlertClose: () => set({ isDrawerAlertOpen: false }),
}));

export const useIsAddTransactionDrawerOpen = () =>
  useCreateTransactionDrawerStore((state) => state.isAddTransactionDrawerOpen);
export const useToggleAddTransactionDrawer = () =>
  useCreateTransactionDrawerStore((state) => state.toggleAddTransactionDrawer);
export const useIsDrawerAlertOpen = () =>
  useCreateTransactionDrawerStore((state) => state.isDrawerAlertOpen);
export const useOnAlertOpen = () => useCreateTransactionDrawerStore((state) => state.onAlertOpen);
export const useOnAlertClose = () => useCreateTransactionDrawerStore((state) => state.onAlertClose);

export default useCreateTransactionDrawerStore;
