export const propertyTableHeaderItems = [
  { key: 'unit', label: 'UNIT' },
  {
    key: 'receivingaccount',
    label: 'RECEIVING ACCOUNT',
  },
];

const sharedConfig = {
  styles: {
    px: 1.5,
    py: 2,
    header: {
      title: { fontSize: '3xs', lineHeight: '12px', color: 'brand.neutral.700' },
    },
  },
};

export const propertyTableConfig = {
  columns: [
    {
      ...sharedConfig,
    },
    {
      ...sharedConfig,
    },
  ],
};
