import React from 'react';

function IconTownhome({
  w = 32,
  h = 47,
  color = 'currentColor',
}: {
  w?: string,
  h?: string,
  color?: string,
}) {
  return (
    <svg width={w} height={h} viewBox="0 0 32 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.289 16.4376V41.2609C31.289 44.4028 28.742 46.9498 25.6001 46.9498H6.40007C3.25818 46.9498 0.711182 44.4028 0.711182 41.2609V16.4376C0.711182 14.9834 1.268 13.5845 2.26727 12.5282L11.8673 2.37958C14.1117 0.00693953 17.8885 0.0069322 20.1329 2.37958L29.7329 12.5282C30.7321 13.5845 31.289 14.9834 31.289 16.4376ZM4.33367 14.4829C3.83404 15.011 3.55563 15.7105 3.55563 16.4376V41.2609C3.55563 42.8319 4.82913 44.1054 6.40007 44.1054H25.6001C27.171 44.1054 28.4445 42.8319 28.4445 41.2609V16.4376C28.4445 15.7105 28.1661 15.011 27.6665 14.4829L18.0665 4.33428C16.9443 3.14796 15.0559 3.14796 13.9337 4.33429L4.33367 14.4829Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6446 22.0608C14.8592 22.0608 14.2224 21.424 14.2224 20.6386L14.2224 16.7275C14.2224 15.942 14.8592 15.3052 15.6446 15.3052C16.4301 15.3052 17.0669 15.942 17.0669 16.7275L17.0669 20.6386C17.0669 21.424 16.4301 22.0608 15.6446 22.0608Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0445 46.9497H9.6001V35.2164C9.6001 32.86 11.5103 30.9497 13.8668 30.9497H17.7779C20.1343 30.9497 22.0445 32.86 22.0445 35.2164V46.9497ZM13.8668 33.7942C13.0813 33.7942 12.4445 34.4309 12.4445 35.2164V44.1053H19.2001V35.2164C19.2001 34.4309 18.5633 33.7942 17.7779 33.7942H13.8668Z"
        fill={color}
      />
    </svg>
  );
}

IconTownhome.defaultProps = {
  w: '32',
  h: '47',
  color: 'currentColor',
};

export default IconTownhome;
