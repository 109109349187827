export const limitInnerContainerStyles = {
  align: 'center',
};

export const tooltipWarningBackgroundStyles = {
  bgColor: 'red.200',
  p: '5px',
  color: 'red.500A',
  borderRadius: '50%',
};
