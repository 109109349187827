import React from 'react';
import { HStack, Stack, Text, Spacer } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { Icon16Subcategory } from '@icons/16px';
import BaselaneSummaryCard from '../../../BaselaneSummaryCard';
import {
  BaselaneSummaryCardDefaultHeader,
  BaselaneSummaryCardAmountHeader,
} from '../../../BaselaneSummaryCard/Headers';
import { getSplitPropertyUnitId, getSplitTagId } from '../../helpers';

type Step3Props = {
  unsplitTagId: String,
  accountsMap: Object,
  propertyIdsMap: Object,
  categoryMap: Object,
  transferType: String,
};

const Step3 = ({
  unsplitTagId,
  accountsMap,
  propertyIdsMap,
  categoryMap,
  transferType,
}: Step3Props) => {
  const { values } = useFormikContext();

  const { propertyId, unitId } = getSplitPropertyUnitId(values.propertyId);
  const { parentTagId, subTagId } = getSplitTagId(unsplitTagId);

  const getSelectedProperty = () => {
    let selectedProperty = null;
    let selectedUnit = null;
    if (propertyId) {
      selectedProperty = `${propertyIdsMap[`p-${propertyId}`]?.name}`;
    }
    if (unitId) {
      selectedUnit = `${propertyIdsMap[`u-${unitId}`]?.name}`;
    }

    return { selectedProperty, selectedUnit };
  };

  const getSelectedCategory = () => {
    let selectedParentTag = null;
    let selectedSubTag = null;
    if (parentTagId) {
      selectedParentTag = categoryMap[parentTagId];
    }
    if (subTagId) {
      selectedSubTag = categoryMap[subTagId];
    }

    return { selectedParentTag, selectedSubTag };
  };

  const { selectedProperty, selectedUnit } = getSelectedProperty();
  const { selectedParentTag, selectedSubTag } = getSelectedCategory();
  const notes = values.note?.trim();

  const showBookkeepingDetails =
    selectedProperty || selectedUnit || selectedParentTag || selectedSubTag || notes !== '';

  const hasPropertySelected = selectedProperty || selectedUnit;
  const hasCategorySelected = selectedParentTag;

  return (
    <Stack justifyContent="space-between" direction="column" h="100%">
      <Stack gap={3}>
        {/* First Card: amount, fromAccount, toAccount */}
        <BaselaneSummaryCard
          isCustom
          {...{
            header: (
              <BaselaneSummaryCardAmountHeader
                {...{
                  title: 'Your accounts',
                  prefix: 'Transfer between',
                  amount: parseFloat(values.amount.replace(/,/g, '')),
                }}
              />
            ),
            list: [
              {
                items: [
                  {
                    label: 'From account',
                    value: accountsMap[values.fromTransferAccountId]?.institutionName,
                    subtext:
                      accountsMap[values.fromTransferAccountId]?.name ??
                      accountsMap[values.fromTransferAccountId]?.nickName,
                  },
                  {
                    label: 'To account',
                    value: accountsMap[values.toTransferAccountId]?.institutionName,
                    subtext:
                      accountsMap[values.toTransferAccountId]?.name ??
                      accountsMap[values.toTransferAccountId]?.nickName,
                  },
                ],
              },
            ],
          }}
        />
        {/* Second Card - optional fields: property, category, notes */}
        {showBookkeepingDetails && (
          <BaselaneSummaryCard
            isCustom
            {...{
              header: (
                <BaselaneSummaryCardDefaultHeader
                  {...{
                    title: 'Bookkeeping details',
                  }}
                />
              ),
              list: [
                {
                  items: [
                    {
                      label: 'Property',
                      value: `${selectedProperty} ${selectedUnit ? `· ${selectedUnit}` : ''}`,
                      isHidden: !hasPropertySelected,
                    },
                    {
                      label: 'Category',
                      value: selectedParentTag,
                      subtext: selectedSubTag ? (
                        <HStack gap={1}>
                          <Icon16Subcategory color="#6C7884" />
                          <Text textStyle="sm" color="brand.neutral.700">
                            {selectedSubTag}
                          </Text>
                        </HStack>
                      ) : null,
                      isHidden: !hasCategorySelected,
                    },
                    {
                      label: 'Notes',
                      value: notes,
                      isHidden: !notes,
                    },
                  ],
                },
              ],
            }}
          />
        )}{' '}
      </Stack>

      <Spacer />
      {transferType === 'TRANSFER_IN' && (
        <Text textStyle="xs" color="brand.neutral.500">
          By clicking the &lsquo;Transfer&lsquo; button, I authorize Baselane to debit the above
          amount from my bank account on the date shown. If this transaction is for a future date, I
          acknowledge that this authorization will remain valid unless canceled through my Baselane
          online account at least 1 day before the transfer date. Once I authorize this payment, I
          understand there cannot be any changes or corrections.
        </Text>
      )}
    </Stack>
  );
};

export default Step3;
