// @flow
import React from 'react';
import { Text } from '@chakra-ui/react';
import BaselaneMessageCard from './BaselaneCard/BaselaneMessageCard';

type UnavailableRentCollectionMessageCardProps = {
  isRentCollectionSupported: boolean,
  containerStyles: Object,
};

// will eventually be changed to add other verifications
function UnavailableRentCollectionMessageCard({
  isRentCollectionSupported,
  containerStyles,
}: UnavailableRentCollectionMessageCardProps) {
  const rentCollectionDisabledText = (
    <Text>
      This external account is not eligible for rent collection or payments. Baselane will only use
      this account to import transactions so that you can manage them on Baselane.
    </Text>
  );
  return (
    !isRentCollectionSupported && (
      <BaselaneMessageCard
        title="Account not eligible for Rent Collection or Payments"
        isVertical
        iconName="info"
        iconColor="yellow"
        borderColor="yellow"
        text={rentCollectionDisabledText}
        iconContainerStyles={{ w: '20px', ml: '7px', mr: '10px' }}
        containerStyles={containerStyles}
      />
    )
  );
}

export default UnavailableRentCollectionMessageCard;
