import React, { useContext } from 'react';
import { Box } from '@chakra-ui/react';
import UserContext from '@contexts/UserContext';
import BaselaneFilterCard from '../../BaselaneFilterCard';
import { animationStyles } from './styles/popup.styles';

/**
 * Helper component used to pre-place popup element for mobile dropdown so it can be found when dom loads.
 * Putting it in the same drawer will not be enough time for this element to be available for the dropdown to find.
 * See example in Transactions/index.jsx + TransactionDetailsDRawer.jsx
 *
 * In BaselaneDropdown if you want to popup to appear you will need to add portalRef, handleDropdownOpen,
 * keepDropdownOpen, and filterCustomHeaderComponentRenderer.
 *
 * @returns {JSX.Element}
 */

const MobileDropdownPortalPopup = () => {
  const {
    setShowMobileDropdownPopup,
    setShowMobileDropdownPopupAnimation,
    showMobileDropdownPopupAnimation,
    showMobileDropdownPopup,
  } = useContext(UserContext);

  return (
    <BaselaneFilterCard
      customStyles={{
        popupContainer: { overflow: 'hidden' },
        popupInnerContainer: { overflow: 'hidden', p: '0' },
      }}
      showFooter={false}
      showHeader={false}
      enableOnOverlayClose={false}
      handleFilterPopupClose={() => {
        setShowMobileDropdownPopup(false);
        setShowMobileDropdownPopupAnimation(() => ({ slidein: false, slideout: true }));
      }}
      showFilterPopupAnimation={showMobileDropdownPopupAnimation}
      headerText="All Accounts"
      animationStyles={animationStyles}
      showFilterPopup={showMobileDropdownPopup}
    >
      <Box className="portal-width-dropdown" id="mobile-dropdown-category" height="600px" />
    </BaselaneFilterCard>
  );
};

export default MobileDropdownPortalPopup;
