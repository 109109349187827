// @flow
import React from 'react';
import { BaselaneAlertNew } from '@shared/components';
import { Icon16Lightbulb } from '@icons/16px';

type UpdateReceivingAccountsAlertProp = {
  handleUpdateReceivingAccountsClick: Function,
  numberOfLeasesCollectingRentOutsideBaselane: Number,
};

function UpdateReceivingAccountsAlert({
  handleUpdateReceivingAccountsClick,
  numberOfLeasesCollectingRentOutsideBaselane,
}: UpdateReceivingAccountsAlertProp): any {
  return (
    <BaselaneAlertNew
      title="Get free tenant ACH payments by collecting rent into Baselane Banking"
      body={`${numberOfLeasesCollectingRentOutsideBaselane} unit(s) collecting rent into an external account. Collect into a Baselane Banking account to get free tenant ACH payments.`}
      visual="icon"
      iconName={Icon16Lightbulb}
      isVertical
      actionProps={{
        secondary: {
          label: 'Update receiving accounts',
          onClick: handleUpdateReceivingAccountsClick,
        },
      }}
    />
  );
}

export default UpdateReceivingAccountsAlert;
