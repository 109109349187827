import React from 'react';
import type { IconPropsType } from './types/icon.types';

function IconUIUpload({ w = 18, h = 10, color = 'currentColor' }: IconPropsType) {
  return (
    <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 18.4194L3.5 14.6665C3.5 14.3904 3.72386 14.1665 4 14.1665C4.27614 14.1665 4.5 14.3904 4.5 14.6665V18.4001C4.5 18.6883 4.50039 18.8744 4.51196 19.016C4.52306 19.1518 4.54189 19.2022 4.5545 19.2269C4.60243 19.321 4.67892 19.3975 4.77301 19.4454C4.79775 19.458 4.8481 19.4769 4.98385 19.488C5.12545 19.4995 5.31149 19.4999 5.59966 19.4999H18.4003C18.6885 19.4999 18.8745 19.4995 19.0161 19.488C19.1519 19.4769 19.2023 19.458 19.227 19.4454C19.3211 19.3975 19.3976 19.321 19.4455 19.2269C19.4581 19.2022 19.4769 19.1518 19.488 19.016C19.4996 18.8743 19.5 18.6882 19.5 18.3999L19.5 14.6665C19.5 14.3904 19.7239 14.1665 20 14.1665C20.2761 14.1665 20.5 14.3904 20.5 14.6665L20.5 18.4192C20.5 18.6828 20.5 18.9102 20.4847 19.0974C20.4686 19.2951 20.4329 19.4917 20.3365 19.6809C20.1927 19.9631 19.9632 20.1926 19.681 20.3364C19.4918 20.4328 19.2952 20.4685 19.0976 20.4846C18.9103 20.4999 18.683 20.4999 18.4195 20.4999H5.58039C5.31693 20.4999 5.08962 20.4999 4.90239 20.4846C4.70475 20.4685 4.50818 20.4328 4.31902 20.3364C4.03677 20.1926 3.8073 19.9631 3.66349 19.6809C3.5671 19.4917 3.53144 19.2951 3.51529 19.0975C3.49998 18.9102 3.49999 18.6829 3.5 18.4194Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20199 8.79799C7.00673 8.60273 7.00673 8.28615 7.20199 8.09089L11.6464 3.64645C11.8417 3.45118 12.1583 3.45118 12.3535 3.64645L16.798 8.09089C16.9932 8.28615 16.9932 8.60273 16.798 8.79799C16.6027 8.99326 16.2861 8.99326 16.0909 8.79799L12.5 5.20711V14.6667C12.5 14.9428 12.2761 15.1667 12 15.1667C11.7238 15.1667 11.5 14.9428 11.5 14.6667V5.20711L7.9091 8.79799C7.71383 8.99326 7.39725 8.99326 7.20199 8.79799Z"
        fill={color}
      />
    </svg>
  );
}

IconUIUpload.defaultProps = {
  w: 24,
  h: 24,
  color: 'currentColor',
};

export default IconUIUpload;
