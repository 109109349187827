export const cardStyles = {
  h: 'auto',
  borderRadius: 'md',
  m: {
    base: '20px 16px !important',
    sm: '20px !important',
    md: '32px !important',
    lg: '0 !important',
    '2lg': '0 !important',
  },
};

export const container = { padding: '40px' };

export const divider = {
  my: { base: '40px !important', lg: '60px !important', '2lg': '60px !important' },
};

export const titleStyles = {
  textStyle: 'headline-xl',
  color: 'brand.neutral.900',
  lineHeight: '32px',
  marginBottom: '8px',
};

export const textStyles = {
  fontSize: '14px',
  color: 'brand.neutral.700',
  fontWeight: 'normal',
  lineHeight: '20px',
};

export const insurancePageStyles = () => {
  const styles = {
    card: {
      h: 'auto',
      m: {
        base: '20px 16px !important',
        sm: '20px !important',
        md: '32px !important',
        lg: '0 !important',
        '2lg': '0 !important',
      },
    },
    container: {
      p: { base: '16px', lg: '40px', '2lg': '40px' },
    },
    divider: {
      my: '40px!important',
    },
  };
  return styles;
};
