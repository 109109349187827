import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useDisclosure, useToast } from '@chakra-ui/react';
import {
  GET_PROPERTY_SUMMARY,
  FRAGMENT_PROPERTY,
  CREATE_PROPERTY,
  UPDATE_PROPERTY,
  DELETE_PROPERTY,
} from '@core/apollo/queries';
import {
  BaselaneButton,
  AlertHeader,
  BaselaneAlert,
  AlertFooter,
  BaselaneButtonGroup,
  BaselaneDrawer,
} from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import Body from './DrawerBody';
import Footer from './DrawerFooter';
import ZillowSetUp from './DrawerPanel/Zillow/ZillowSetUp';
import MortgageSetUp from './DrawerPanel/Mortgage/MortgageSetUp';
import AddPropertyButton from './AddPropertyButton';
import { getInitialValues, handlePropertySave, setFormData } from '../helpers/detail.helper';

type AddEditPropertyProps = {
  property?: Object,
  buttonStyles?: Object,
  variant?: String,
  palette?: String,
  buttonLabel?: String,
  isOnboarding?: boolean,
  from?: Object,
  setNewProperty?: Function,
  successDrawerRef?: any,
  totalProperties: Number,
  totalUnits: Number,
  shortForm?: Boolean,
  setShortForm?: Function,
  onPropertyUnitSelected?: Function,
  handleClose?: Function,
  handleCreatePropertySuccess?: Function,
};

function AddEditProperty({
  property,
  buttonStyles,
  variant,
  palette,
  buttonLabel,
  isOnboarding,
  from = { page: '', section: '', configs: {} },
  setNewProperty,
  successDrawerRef,
  totalProperties,
  totalUnits,
  shortForm,
  setShortForm,
  onPropertyUnitSelected,
  handleClose,
  handleCreatePropertySuccess,
}: AddEditPropertyProps) {
  // drawer states
  const { isOpen, onOpen, onClose } = useDisclosure();
  // automatically open shortForm
  if (shortForm && !isOpen) {
    onOpen();
  }
  const btnRef = useRef();
  const formRef = useRef();

  // tab states
  const [tabIndex, setTabIndex] = useState(property ? 1 : 0);

  // alert for drawer
  const [isDrawerAlertOpen, setIsDrawerAlertOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onAlertIsClose = () => setIsDrawerAlertOpen(false);
  const cancelRef = useRef();

  // property states
  const isInitialDetailsForm = !!property;
  const initialValues = () => getInitialValues(property, shortForm);

  // form states
  const [detailsComplete, setDetailsComplete] = useState(isInitialDetailsForm);
  const [isDirty, setIsDirty] = useState({
    details: false,
    financials: false,
    currentMarketValue: false,
    currentMortgageBalance: false,
  });
  const [isValid, setIsValid] = useState(detailsComplete);

  // value/footer states
  const [variables, setVariables] = useState(initialValues());
  const [hideFooter, setHideFooter] = useState(false);

  // market value / mortgage balance drawer states
  const [showZillow, toggleShowZillow] = useState(false);
  const [showMortgage, toggleShowMortgage] = useState(false);

  // query/mutation
  const [updateProperty, { loading: updatePropertyLoading }] = useMutation(UPDATE_PROPERTY, {
    refetchQueries: [{ query: GET_PROPERTY_SUMMARY }],
    update: (cache, { data: { updateProperty: updatedProperty } }) => {
      cache.modify({
        id: cache.identify(updatedProperty),
        fields: {
          property(existingProperty = []) {
            const updatedPropertyRef = cache.writeFragment({
              data: updatedProperty,
              fragment: FRAGMENT_PROPERTY,
            });
            return [...existingProperty, updatedPropertyRef];
          },
        },
      });
    },
  });

  const [createProperty, { loading: createPropertyLoading }] = useMutation(CREATE_PROPERTY, {
    refetchQueries: [{ query: GET_PROPERTY_SUMMARY }],
    update: (cache, { data: { createProperty: newProperty } }) => {
      cache.modify({
        fields: {
          property(existingProperties = []) {
            const newPropertyRef = cache.writeFragment({
              data: newProperty,
              fragment: FRAGMENT_PROPERTY,
            });
            return [...existingProperties, newPropertyRef];
          },
        },
      });
    },
    onCompleted: () => {
      handleCreatePropertySuccess();
    },
  });

  const [deleteProperty, { loading: deletePropertyLoading }] = useMutation(DELETE_PROPERTY, {
    refetchQueries: [{ query: GET_PROPERTY_SUMMARY }],
  });

  const isLoading = updatePropertyLoading || createPropertyLoading || deletePropertyLoading;

  // toaster
  const toast = useToast();

  // ref for children component so we can call back button function from drawer
  const zillowRef = useRef();
  const mortgageRef = useRef();

  const setShowZillow = (value) => {
    toggleShowZillow(value);
  };
  const setShowMortgage = (value) => {
    toggleShowMortgage(value);
  };

  const cleanUp = () => {
    setTabIndex(0);
    onAlertIsClose();
    setIsDirty({
      details: false,
      financials: false,
      currentMarketValue: false,
      currentMortgageBalance: false,
    });
    setIsValid(detailsComplete);
    setHideFooter(false);
  };

  const setFormVariables = (newVariables) => setFormData(newVariables, setVariables, variables);

  /**
   * Clean up property states.
   *
   * @param isSave a boolean to differentiate between save & close vs just closing drawer
   */
  const handleDrawerClose = (isSave = false) => {
    if (showZillow) {
      zillowRef?.current?.handleOnBackButtonClick();
      return;
    }
    if (showMortgage) {
      mortgageRef?.current?.handleOnBackButtonClick();
      return;
    }
    setShowMortgage(false);
    setShowZillow(false);

    // check if an event is passed in instead
    const checkIsSave = typeof isSave === 'boolean' && isSave;
    const {
      details: isDetailsDirty,
      financials: isFinancialsDirty,
      currentMarketValue: isCurrentMarketValueDirty,
      currentMortgageBalance: isCurentMortgageBalanceDirty,
    } = isDirty;

    const isFormDirty =
      isDetailsDirty ||
      isFinancialsDirty ||
      isCurrentMarketValueDirty ||
      isCurentMortgageBalanceDirty;

    if (!checkIsSave && isFormDirty) {
      setIsDrawerAlertOpen(true);
    } else {
      cleanUp();
      onClose();
      handleClose();
    }
  };

  const handleContinueClick = () => {
    cleanUp();
    setVariables(initialValues());
    onClose();
    setShortForm(false);
    handleClose();
  };

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  /**
   * Show Success Toast.
   *
   * @param isAddNewPropertyForm a boolean to differentiate between create & update property
   */
  const handleOnShowSuccessToast = (isAddNewPropertyForm) =>
    toast({
      position: 'bottom-left',
      description: isAddNewPropertyForm
        ? 'Property created successfully'
        : 'Property details updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const handleOnShowErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleSave = () =>
    handlePropertySave({
      property,
      variables,
      createProperty,
      updateProperty,
      handleOnShowSuccessToast,
      handleOnShowErrorToast,
      successDrawerRef,
      setNewProperty,
      setVariables,
      shortForm,
      onPropertyUnitSelected,
      setShortForm,
      onClose,
      cleanUp,
    });

  const addPropertyOnClick = (e) => {
    e.stopPropagation();
    if (!property) {
      sendSegmentEvent('add_property_started', {
        title: document.title,
        properties_count: totalProperties,
        units_count: totalUnits,
        isMobile: false,
      });
    }

    onOpen(e);
    setTabIndex(property ? 1 : 0);
    setDetailsComplete(isInitialDetailsForm);
    setIsDirty({
      details: false,
      financials: false,
      currentMarketValue: false,
      currentMortgageBalance: false,
    });
    setVariables(initialValues());
  };

  useEffect(() => {
    setIsValid(detailsComplete);
  }, [detailsComplete]);

  const alertDrawerCloseFooter = (
    <AlertFooter
      cancelRef={cancelRef}
      leftButtonEvent={onAlertIsClose}
      rightButtonEvent={handleContinueClick}
    />
  );

  const closeDeletePropertyAlert = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteProperty = () => {
    const { id } = property;
    deleteProperty({
      variables: { id },
      update: (cache) => {
        cache.modify({
          fields: {
            property(existingProperty, { readField }) {
              return existingProperty.filter((p) => id !== readField('id', p));
            },
          },
        });
        cache.evict({ id: `Property:${id}` });
        cache.gc();
      },
    }).then(() => {
      closeDeletePropertyAlert();
      toast({
        position: 'bottom-left',
        description: 'Property deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const canDeleteProperty = () => property?.units?.every((u) => u?.isUnitDeletable);

  const alertDeletePropertyFooter = (
    <BaselaneButtonGroup size="md">
      <BaselaneButton variant="outline" palette="neutral" onClick={closeDeletePropertyAlert}>
        Cancel
      </BaselaneButton>
      <BaselaneButton
        id="delete-property-confirmation-button"
        variant="filled"
        palette="danger"
        onClick={handleDeleteProperty}
      >
        Delete Property
      </BaselaneButton>
    </BaselaneButtonGroup>
  );

  let drawerSize = property ? '2lg' : 'lg';
  if (shortForm) {
    drawerSize = 'smd';
  }
  const handleOnBackButtonClick = () => {
    zillowRef.current.handleOnBackButtonClick();
  };
  return (
    <>
      <AddPropertyButton
        {...{
          btnRef,
          addPropertyOnClick,
          property,
          variant,
          palette,
          buttonStyles,
          buttonLabel,
          isOnboarding,
          from,
        }}
      />

      {/* Original Drawer */}
      <BaselaneDrawer
        size={drawerSize}
        isOpen={isOpen}
        placement="right"
        title={property ? 'Edit Property' : 'Add Property'}
        onClose={handleDrawerClose}
        onOverlayClick={handleDrawerClose}
        finalFocusRef={btnRef}
        footer={
          <Footer
            onClose={handleDrawerClose}
            canDeleteProperty={canDeleteProperty()}
            isLoading={isLoading}
            {...{
              property,
              isDirty,
              isValid,
              handleSave,
              hideFooter,
              handleDelete,
              shortForm,
              tabIndex,
              setTabIndex,
              formRef,
            }}
          />
        }
        newDesignDrawer
      >
        <Body
          {...{
            setIsDirty,
            setIsValid,
            setHideFooter,
            tabIndex,
            isDirty,
            detailsComplete,
            setDetailsComplete,
            formRef,
            property,
            setFormVariables,
            initialValues: initialValues(),
            variables,
            handleDrawerClose,
            setShowMortgage,
            setShowZillow,
            shortForm,
          }}
          onTabChange={handleTabChange}
        />
      </BaselaneDrawer>

      {/* Sub-Drawer from Financials tab: Market Value  */}
      <BaselaneDrawer
        isOpen={showZillow}
        size="2lg"
        placement="right"
        onClose={handleDrawerClose}
        onOverlayClick={handleDrawerClose}
        title={property ? 'Edit Property' : 'Add Property'}
        footer={
          <BaselaneButton
            variant="outline"
            palette="primary"
            size="md"
            onClick={handleOnBackButtonClick}
          >
            Cancel
          </BaselaneButton>
        }
        newDesignDrawer
      >
        <ZillowSetUp
          {...{
            property,
            detailsComplete,
            setFormVariables,
            isDirty,
            setIsDirty,
            setHideFooter,
            zillowRef,
          }}
          onBackClick={setShowZillow}
          initialValues={variables}
        />
      </BaselaneDrawer>

      {/* Sub-Drawer from Financials tab: Mortgage Balance  */}
      <BaselaneDrawer
        isOpen={showMortgage}
        size="2lg"
        placement="right"
        onClose={handleDrawerClose}
        onOverlayClick={handleDrawerClose}
        title={property ? 'Edit Property' : 'Add Property'}
        newDesignDrawer
        footer={
          <BaselaneButton variant="outline" palette="primary" size="md" onClick={handleDrawerClose}>
            Cancel
          </BaselaneButton>
        }
      >
        <MortgageSetUp
          {...{
            property,
            detailsComplete,
            setFormVariables,
            isDirty,
            setIsDirty,
            setHideFooter,
            mortgageRef,
          }}
          onBackClick={setShowMortgage}
          initialValues={variables}
        />
      </BaselaneDrawer>

      <BaselaneAlert
        isOpen={isDrawerAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertIsClose}
        isCentered
        header={<AlertHeader title="You Have Unsaved Changes" />}
        body="Are you sure you want to exit without saving?"
        footer={alertDrawerCloseFooter}
      />
      <BaselaneAlert
        isOpen={showDeleteModal}
        onClose={closeDeletePropertyAlert}
        header="Are you sure you want to delete this property?"
        body="Deleting a property will remove all property data from Baselane; this includes all property details, financial information, tagging of transactions, and work in progress leases. This action is permanent and can't be reversed."
        footer={alertDeletePropertyFooter}
        showCloseButton
        containerStyles={{ minWidth: '672px' }}
        closeButtonStyles={{ border: '0px' }}
      />
    </>
  );
}

AddEditProperty.defaultProps = {
  property: null,
  buttonStyles: {},
  variant: 'filled',
  palette: 'primary',
  buttonLabel: 'Add Property',
  isOnboarding: false,
  from: { page: '', section: '', configs: ({}: { ... }) },
  setNewProperty: () => {},
  shortForm: false,
  setShortForm: () => {},
  onPropertyUnitSelected: () => {},
  successDrawerRef: null,
  handleClose: () => {},
  handleCreatePropertySuccess: () => {},
};

export default AddEditProperty;
