/* eslint-disable no-unused-expressions */
import React, { useContext, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Stack, useDisclosure } from '@chakra-ui/react';
import { BaselaneButton, BaselaneDrawer } from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import UserContext from '@contexts/UserContext';
import { CREATE_UPDATE_SPLIT_TX } from '../queries';
import OriginalTransaction from './OriginalTransaction';
import TransactionSplits from './TransactionSplits';
import UnsavedChangesAlert from '../UnsavedChangesAlert';
import SlLoader from '../../Loader';
import { formatDataForAPI } from './helpers/formatData.helper';
import { updateCacheAfterCreateOrUpdate } from './helpers/updateCache.helper';
import { SplitTransaction, Transaction } from '../types';

type SplitTransactionsProps = {
  splitTrxRef: any,
  transaction: Transaction,
  splitTransactions: Array<SplitTransaction>,
  setSplitTransactions: Function,
  isLoading: boolean,
  externalFilters?: Object,
  setHasUpdatedSplitTrx: Function,
  propertyOptions: Array<Object>,
};

const SplitTransactionsDrawer = ({
  splitTrxRef,
  transaction,
  splitTransactions,
  setSplitTransactions,
  isLoading,
  externalFilters,
  setHasUpdatedSplitTrx,
  propertyOptions,
}: SplitTransactionsProps) => {
  const { filters, setHasDataUpdated } = useContext(TransactionContext);
  const { user } = useContext(UserContext);

  const [deletedSplitTransactions, setDeletedSplitTransactions] = useState([]);
  const formikRef = useRef(null);

  const [createOrUpdateSplitTx, { loading: updateLoading }] = useMutation(CREATE_UPDATE_SPLIT_TX);

  // Alert State
  const { isOpen: isDrawerAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  const [splitTrxDirty, setSplitTrxDirty] = useState(false);
  const [splitTrxValid, setSplitTrxValid] = useState(false);

  const handleDrawerClose = () => splitTrxRef?.current?.close();

  const handleFormikSubmit = (e) => formikRef.current.handleSubmit(e);

  const cleanup = () => {
    if (splitTrxDirty) setSplitTrxDirty(false);
    setSplitTransactions(splitTransactions);
  };

  const handleAlertContinueClick = () => {
    onAlertClose();
    handleDrawerClose();
    cleanup();
  };

  const handleOnSaveAndClose = (values) => {
    const formatTransactions = formatDataForAPI(values.splitTransactions);
    const formatDeletedTransactions = formatDataForAPI(deletedSplitTransactions);

    const variables = {
      transactionSplitInputs: formatTransactions.concat(formatDeletedTransactions),
      parentTransactionId: transaction?.parentId || transaction?.id,
    };

    createOrUpdateSplitTx({
      variables,
      update: (cache, { data: { createOrUpdateSplitTx: splitTrx } }) =>
        updateCacheAfterCreateOrUpdate({
          cache,
          splitTrx,
          splitTransactions,
          deletedSplitTransactions,
          filters: { ...filters, filter: { ...filters.filter, ...externalFilters } },
          user,
          isEdit: transaction?.parentId,
        }),
    }).then(() => {
      setHasUpdatedSplitTrx(true);
      setHasDataUpdated(true);
      handleDrawerClose();
    });
  };

  const handleCloseWithoutSaving = () => {
    if (splitTrxDirty) {
      onAlertOpen();
    } else {
      cleanup();
      handleDrawerClose();
    }
  };

  return (
    <>
      <UnsavedChangesAlert
        {...{
          isDrawerAlertOpen,
          onAlertClose,
          onAlertOpen,
          onAlertContinue: handleAlertContinueClick,
        }}
      />

      <BaselaneDrawer
        ref={splitTrxRef}
        title={transaction?.parentId ? 'Edit Split Transactions' : 'Split Transaction'}
        closeEvent={handleCloseWithoutSaving}
        onClose={handleCloseWithoutSaving}
        newDesignDrawer
        footer={
          <Stack direction="row">
            <BaselaneButton
              variant="outline"
              palette="transparent"
              size="md"
              onClick={handleCloseWithoutSaving}
            >
              Cancel
            </BaselaneButton>
            <BaselaneButton
              variant="filled"
              palette="primary"
              size="md"
              isDisabled={!splitTrxValid || !splitTrxDirty}
              onClick={handleFormikSubmit}
              isLoading={updateLoading}
            >
              Save & Close
            </BaselaneButton>
          </Stack>
        }
      >
        {isLoading ? (
          <SlLoader />
        ) : (
          <Stack spacing="28px">
            <OriginalTransaction
              {...{
                filters: { ...filters, filter: { ...filters.filter, ...externalFilters } },
                transaction: transaction?.parentTransaction || transaction,
                isEdit: !!transaction?.parentId,
                splitTransactions,
                setHasUpdatedSplitTrx,
                setHasDataUpdated,
              }}
            />

            <TransactionSplits
              {...{
                splitTransactions,
                formikRef,
                handleOnSaveAndClose,
                setSplitTrxDirty,
                setSplitTrxValid,
                totalAmount: transaction?.parentTransaction?.amount || transaction?.amount,
                setDeletedSplitTransactions,
                propertyOptions,
              }}
            />
          </Stack>
        )}
      </BaselaneDrawer>
    </>
  );
};

SplitTransactionsDrawer.defaultProps = {
  externalFilters: ({}: { ... }),
};

export default SplitTransactionsDrawer;
