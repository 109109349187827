/* eslint-disable react/prop-types */
import { React } from 'react';
import { Box, HStack, Text, Stack, Spacer } from '@chakra-ui/react';
import useProperties from '@features/Property/useProperties';
import useCategories from '@features/Category/useCategories';
import { Icon16Subcategory } from '@icons/16px';
import { BaselaneDivider } from '@shared/components';
import { baselaneSummaryCardStyles } from '@shared/components/BaselaneSummaryCard/styles/baselaneSummaryCard.styles';

const ScheduledPaymentsBookeeping = ({ details, isOneTimePayment }) => {
  const { dividerStyles } = baselaneSummaryCardStyles;

  const { propertiesByIdForDropdownById } = useProperties();
  const { categoriesForDropdownById } = useCategories();

  const oneTimePropertyId = details?.metadata?.propertyId ? details?.metadata?.propertyId : null;

  const recurringPropertyId = !isOneTimePayment ? details?.propertyId : null;

  const propertyId = isOneTimePayment ? oneTimePropertyId : recurringPropertyId;
  let property = null;
  if (propertyId) {
    const currentProperty = propertiesByIdForDropdownById[propertyId];
    const [parentPropertyId, unitId] = propertyId.split('-');
    const parentProperty = propertiesByIdForDropdownById[parentPropertyId];
    property = unitId
      ? `${parentProperty?.name} · ${currentProperty?.name}`
      : currentProperty?.name;
  }
  const oneTimeTagId = details?.metadata?.tagId ? details?.metadata?.tagId : null;
  const recurringTagId = !isOneTimePayment ? details?.tagId : null;
  const tagId = isOneTimePayment ? oneTimeTagId : recurringTagId;

  let category = null;
  let subCategory = null;
  if (tagId) {
    const selectedCategoryId = Object.keys(categoriesForDropdownById)
      .sort()
      .reverse()
      .find((categoryKey) => {
        const [parentCategoryId, subCategoryId] = categoryKey.split('-');
        return tagId === parentCategoryId || tagId === subCategoryId;
      });

    const [parentCategoryId, subCategoryId] = selectedCategoryId.split('-');

    category = categoriesForDropdownById[parentCategoryId];
    subCategory = categoriesForDropdownById[`${parentCategoryId}-${subCategoryId}`];
  }

  const bookKeepingNotes = isOneTimePayment
    ? details?.metadata?.bookKeepingNote?.trim()
    : details?.bookKeepingNote;

  return (
    (property || category || bookKeepingNotes) && (
      <>
        <BaselaneDivider styles={dividerStyles} />

        <Stack w="100%" gap={1}>
          {property ? (
            <HStack>
              <Text textStyle="xs">Property</Text>
              <Spacer />
              <Stack gap={0}>
                <Text textStyle="sm">{property}</Text>
              </Stack>
            </HStack>
          ) : null}
          {category ? (
            <HStack alignItems="flex-start">
              <Text textStyle="xs">Category</Text>
              <Spacer />
              <Stack gap={0}>
                <Box display="flex" flexDirection="column" flex={2} alignItems="flex-end">
                  <Text textStyle="sm">{category?.name}</Text>

                  {subCategory && (
                    <HStack display="flex" justifyContent="flex-end">
                      <Icon16Subcategory color="#6C7884" />
                      <Text textStyle="xs">{subCategory?.name}</Text>
                    </HStack>
                  )}
                </Box>
              </Stack>
            </HStack>
          ) : null}
          {bookKeepingNotes ? (
            <HStack>
              <Text textStyle="xs">Notes</Text>
              <Spacer />
              <Stack gap={0}>
                <Text textStyle="sm" textAlign="right">
                  {bookKeepingNotes}
                </Text>
              </Stack>
            </HStack>
          ) : null}
        </Stack>
      </>
    )
  );
};

export default ScheduledPaymentsBookeeping;
