import React from 'react';

import { Box, Image } from '@chakra-ui/react';
import customTheme from '@core/theme';
import { TITLES_MAP } from '@core/constants/titles';
import { IconArrowRight, IconBankingBusiness } from '@icons';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import BaselaneBadge from '../BaselaneBadge';
import NorthWest from '../../../Dashboard/assets/NorthWest.png';
import NorthWest2x from '../../../Dashboard/assets/NorthWest2x.png';
import {
  badgeTagStyles,
  badgeButtonStyles,
  badgeButtonIconStyles,
  providerImageStyles,
} from './styles/insuranceBanner.styles';

import BaselaneAdvertisingBanner from '.';

type LLCBannerProps = {
  isNarrowOnly: Boolean,
};

const LLCBanner = ({ isNarrowOnly, ...rest }: LLCBannerProps) => {
  const onOpenNorthWest = () => {
    // ensure home page registers as 'get_started'
    const { pathname } = window.location;
    const isHome = pathname === '/';
    const url = isHome ? `/get_started` : pathname;
    const title = isHome
      ? `${document.title} | ${TITLES_MAP.find((item) => item.path === '/get_started').title}`
      : document.title;

    sendSegmentEvent('northwest_quote_started', {
      title,
      url,
      in_app: true,
    });

    window.open(
      'https://shareasale.com/r.cfm?b=2107348&u=1005106&m=69959&urllink=&afftrack=%2Aw%3Abaselane%2Ap%3AYOURINFO'
    );
  };

  const getTopRightContent = () => {
    return (
      <BaselaneBadge
        {...{
          color: 'blue',
          type: 'badge',
          ...badgeTagStyles,
        }}
      >
        Baselane Feature
      </BaselaneBadge>
    );
  };

  const getBottomRightContent = () => {
    return (
      <>
        <BaselaneBadge
          {...{
            color: 'blue',
            type: 'badge',
            ...badgeButtonStyles(false),
          }}
        >
          Get Started Now
          <Box as="span" {...badgeButtonIconStyles}>
            <IconArrowRight color={customTheme.colors.brand.blue['700']} />
          </Box>
        </BaselaneBadge>
        <Image
          {...providerImageStyles(isNarrowOnly)}
          src={NorthWest}
          srcSet={`${NorthWest2x} 2x`}
          alt="NorthWest"
        />
      </>
    );
  };

  return (
    <BaselaneAdvertisingBanner
      {...{
        icon: <IconBankingBusiness w="48" h="48" />,
        primaryContent: `Protect Your Assets by Forming an LLC`,
        supportingContent: `Free registered agent for 1st year. Fast filing in your state. $29 plus state fee.`,
        topRightContent: getTopRightContent(),
        bottomRightContent: getBottomRightContent(),
        onClick: onOpenNorthWest,
        isNarrowOnly,
        isBannerClickable: true,
        ...rest,
      }}
    />
  );
};

export default LLCBanner;
