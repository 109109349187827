export const containerStyle = (isEntitiesPage) => ({
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'flex-end',
  p: {
    lg: isEntitiesPage ? '0 32px 0 32px' : '24px 0 0 0',
    md: isEntitiesPage ? '0 32px 24px 32px' : '24px 32px 24px 32px',
    sm: isEntitiesPage ? '0 20px 20px 20px' : '20px',
    base: '16px',
  },
  mb: isEntitiesPage ? '32px' : '0',
});
