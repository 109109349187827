export const secondAndThirdColumnStyles = {
  pl: '54px',
  pr: '32px',
};

export const secondColumnStyles = {
  ...secondAndThirdColumnStyles,
  borderLeftWidth: '1px',
  borderRightWidth: '1px',
  borderColor: 'brand.darkBlue.150',
};

export const customButtonBorderStyles = {
  w: { lg: 'null', base: '100%' },
};

export const mobileEmptyCardStyles = {
  p: '16px',
  border: 'solid 1pt',
  borderColor: 'brand.darkBlue.150',
  borderRadius: '4px',
};

export const mobileEmptyCardContainerStyles = (hasNoAccounts) => {
  return {
    background: 'brand.neutral.white',
    p: hasNoAccounts ? '0 16px 16px' : '16px',
  };
};
