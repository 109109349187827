/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { Formik } from 'formik';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { Icon16PlusCircle } from '@icons/16px';
import { BaselaneButton, BaselaneButtonGroup } from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import ToggleWrapper from '@pages/LeasesPage/LeaseSection/components/ToggleWrapper';
import { currencyMask } from '@core/utils/masks';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';

import DisplayFee from './DisplayFee';
import {
  additionalFeesValidation,
  feesFormInitialValues,
} from '../../formHelpers/leaseTermForm.helper';
import { feeBox, fieldContainer } from '../../styles/shared.styles';

type Field = {
  label: string,
  type: string,
};
type Fields = {
  [key: string]: Field,
};

type RecurringMonthlyFeesSectionProps = {
  sectionInfo: Array<Fields>,
  sectionId: string,
  handleChange: Function,
  setFieldValue: Function,
  setFormVariables: Function,
  isAdditionalFeesDb: Array<Object>,
  values: Object,
  monthlyFeeFormRef: any,
  unifiedRC: boolean,
};

function RecurringMonthlyFeesSection({
  sectionInfo = [],
  sectionId = '',
  handleChange = () => {},
  setFieldValue = () => {},
  setFormVariables = () => {},
  isAdditionalFeesDb = [],
  values = {},
  monthlyFeeFormRef = null,
  unifiedRC,
}: RecurringMonthlyFeesSectionProps) {
  const recurringDbFees = isAdditionalFeesDb.filter((f) => f.frequency === 'MONTHLY');
  const additionalRecurringFeesFields = values.additionalFeesFields.filter(
    (f) => f.frequency === 'MONTHLY'
  );

  const undeletedFees = isAdditionalFeesDb.filter((f) => f.frequency === 'MONTHLY' && !f.delete);
  const feesExist = undeletedFees?.length > 0;
  const [showAdditionalFees, setShowAdditionalFees] = useState(feesExist);
  const [showAddFeeForm, setShowAddFeeForm] = useState(false);

  const handleSetShowAdditionalFees = (feesEnabled) => {
    setShowAdditionalFees(feesEnabled);
    setShowAddFeeForm(!feesExist && feesEnabled);
  };

  const handleSave = (formValues, actions) => {
    const payments = {
      name: 'FEE',
      description: formValues.description,
      amount: stripCurrency(formValues.amount),
      frequency: 'MONTHLY',
      enabled: true,
    };

    setFormVariables({
      payments: [{ ...payments }],
    });

    actions.setSubmitting(false);
    actions.resetForm();
    setShowAddFeeForm(false);
  };

  const handleFeeDelete = (fee) => {
    const deletedPayment = { ...fee, delete: true };
    if (recurringDbFees.length === 1) {
      setShowAddFeeForm(true);
    } else {
      setShowAddFeeForm(false);
    }
    setFormVariables({ payments: [deletedPayment] });
  };

  useEffect(() => {
    if (feesExist !== showAdditionalFees) {
      setShowAdditionalFees(feesExist);
    }
  }, [feesExist]);

  useEffect(() => {
    if (recurringDbFees.length !== additionalRecurringFeesFields.length)
      setFieldValue('additionalFeesFields', isAdditionalFeesDb);
  });

  return (
    <ToggleWrapper key="additional_fees_container" showBottomBorder={false} isNested>
      {(!showAdditionalFees || !showAddFeeForm) && undeletedFees.length < 1 && (
        <ToggleWrapper.Toggle
          key="recurring_fees_switch"
          id="recurringMonthlyFees"
          {...{
            title: sectionInfo.title.label,
            htmlFor: sectionId,
            switchProps: {
              id: sectionId,
              isDisabled: feesExist,
              onClick: (e) => {
                handleSetShowAdditionalFees(!showAdditionalFees);
                handleChange(e);
              },
            },
            hideToggle: true,
          }}
        />
      )}
      {showAdditionalFees ? (
        <ToggleWrapper.Content styles={{ spacing: '16px', mt: '0', mb: '16px' }}>
          <DisplayFee
            {...{
              additionalFeesFields: recurringDbFees,
              handleFeeDelete,
              unifiedRC,
            }}
          />

          {showAddFeeForm ? (
            <Formik
              innerRef={monthlyFeeFormRef}
              validate={(formValues) => additionalFeesValidation(formValues)}
              initialValues={feesFormInitialValues}
              onSubmit={handleSave}
              validateOnBlur
            >
              {({
                values: formValues,
                handleSubmit,
                isSubmitting,
                handleChange: handleFeeChange,
                handleBlur: handleFeeBlur,
                errors,
                touched,
              }) => (
                <Flex {...feeBox} mb="16px">
                  <Stack
                    direction={!isMobile ? 'row' : 'column'}
                    spacing="16px"
                    mb="0"
                    id="monthly-fees-box"
                  >
                    {/* Fee Name */}
                    <FormControl
                      {...fieldContainer}
                      mb="8px"
                      marginTop={isMobile ? '0px!important' : '0'}
                      isInvalid={errors.description && touched.description}
                    >
                      <FormLabel {...formLabelStyles.xs} htmlFor={sectionInfo.description.label}>
                        {sectionInfo.description.label}
                      </FormLabel>

                      <Input
                        {...formInputStyles}
                        {...sectionInfo.description.props}
                        id="description"
                        name="description"
                        value={formValues.description || ''}
                        maxLength="32"
                        type="text"
                        onChange={handleFeeChange}
                        onBlur={handleFeeBlur}
                        bg="brand.neutral.white"
                      />
                      <FormErrorMessage {...formErrorStyles}>
                        <Box as="span">{errors.description}</Box>
                      </FormErrorMessage>
                    </FormControl>

                    {/* Fee Amount */}
                    <FormControl {...fieldContainer} isInvalid={errors.amount && touched.amount}>
                      <FormLabel {...formLabelStyles.xs} htmlFor={sectionInfo.amount.label}>
                        {sectionInfo.amount.label}
                      </FormLabel>
                      <Box>
                        <Input
                          {...formInputStyles}
                          {...sectionInfo.amount.props}
                          id="amount"
                          name="amount"
                          value={formValues.amount}
                          as={MaskedInput}
                          mask={currencyMask()}
                          onChange={handleFeeChange}
                          onBlur={handleFeeBlur}
                          placeholder="$"
                          inputMode="decimal"
                          bg="brand.neutral.white"
                        />
                        <FormErrorMessage {...formErrorStyles}>
                          <Box as="span">{errors.amount}</Box>
                        </FormErrorMessage>
                      </Box>
                    </FormControl>
                  </Stack>
                  <BaselaneButtonGroup size="sm">
                    <BaselaneButton
                      variant="filled"
                      palette="primary"
                      size="sm"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                      id="save_fee_button"
                    >
                      Save Fee
                    </BaselaneButton>
                    <BaselaneButton
                      variant="transparent"
                      palette="danger"
                      size="sm"
                      onMouseDown={() => {
                        setShowAddFeeForm(false);
                        if (!feesExist) {
                          handleSetShowAdditionalFees(false);
                        }
                      }}
                      id="cancel_fee_button"
                    >
                      Cancel
                    </BaselaneButton>
                  </BaselaneButtonGroup>
                </Flex>
              )}
            </Formik>
          ) : (
            <Box mx="0 !important" my={1}>
              {!monthlyFeeFormRef?.current?.isSubmitting && (
                <BaselaneButton
                  variant="transparent"
                  palette="primary"
                  size="sm"
                  onClick={() => setShowAddFeeForm(true)}
                  leftIcon={<Icon16PlusCircle />}
                  id="add_a_fee_button"
                >
                  {feesExist ? 'Add another fee' : 'Add a fee'}
                </BaselaneButton>
              )}
            </Box>
          )}
        </ToggleWrapper.Content>
      ) : null}
    </ToggleWrapper>
  );
}

export default RecurringMonthlyFeesSection;
