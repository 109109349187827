import React, { useRef, forwardRef } from 'react';
import { Box, InputGroup, Input, InputRightElement, InputLeftElement } from '@chakra-ui/react';

import { Icon16Calendar, Icon16ChevronUp, Icon16ChevronDown } from '@icons/16px';
import { Icon12Close } from '@icons/12px';
import { DatePickerInputProps } from '../types/datePickerInput.types';
import { BaselaneButtonIcon } from '../../BaselaneButtonIcon';

const DatePickerFormInput = forwardRef(
  (
    {
      isCalendarOpen,
      id,
      name,
      onBlur,
      onClick,
      onChange,
      handleInputChange,
      onFocus,
      onKeyDown,
      placeholder,
      readOnly,
      disabled,
      required,
      size,
      tabIndex,
      title,
      value,
      className,
      autoFocus,
      autoComplete,
      label,
      hideInputIcon = false,
    }: DatePickerInputProps,
    ref
  ) => {
    const defaultRef = useRef();
    const inputRef = ref || defaultRef;
    const props = {
      id,
      name,
      onBlur,
      onClick,
      onChange,
      onFocus,
      onKeyDown,
      placeholder,
      readOnly,
      disabled,
      required,
      size,
      tabIndex,
      title,
      value,
      className,
      autoFocus,
      autoComplete,
    };

    const hasDatesSelected = value;
    const handleClearClick = () => {
      handleInputChange(null);
    };

    return (
      <InputGroup>
        {!hideInputIcon && (
          <InputLeftElement onClick={onClick} color="brand.neutral.500">
            <Box>
              <Icon16Calendar />
            </Box>
          </InputLeftElement>
        )}
        <Input
          readOnly={readOnly}
          variant="outline"
          paddingLeft={!hideInputIcon ? '36px !important' : '12px !important'}
          paddingRight={!hideInputIcon ? '36px !important' : '12px !important'}
          {...{
            ...props,
            onTouchEnd: onClick,
            ref: inputRef,
            type: 'text',
            className: `baselane-datepicker-input ${size} ${className}`,
          }}
        />

        <InputRightElement onClick={onClick}>
          {hasDatesSelected && !disabled && (
            <BaselaneButtonIcon
              variant="transaparent"
              palette="neutral"
              size="xs"
              icon={<Icon12Close />}
              onClick={handleClearClick}
              styles={{ position: 'absolute', right: !hideInputIcon ? '26px' : '10px' }}
            />
          )}
          {!hideInputIcon && (
            <Box color="brand.neutral.500">
              {!isCalendarOpen ? <Icon16ChevronDown /> : <Icon16ChevronUp />}
            </Box>
          )}
        </InputRightElement>
      </InputGroup>
    );
  }
);

export default DatePickerFormInput;
