import { body } from '@core/theme/text';

export const supportingTextStyles = {
  ...body.xs,
  color: 'brand.neutral.600',
  mb: 0.75,
};

export const labelWrapperStyles = {
  display: 'inline-block',
};

export const tooltipIconStyles = (hasSupportingText) => ({
  mb: hasSupportingText ? 0 : 0.75,
});

export const labelTooltipWrapperStyles = {
  position: 'relative',
  alignItems: 'center',
  gap: 0.5,
};
