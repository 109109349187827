export const headingStyles = {
  fontSize: { xs: 'md', sm: 'lg', md: 'xl', lg: '2xl' },
  fontWeight: 'semibold',
  mb: { xxs: '0', xs: '0', sm: '0', md: '0', lg: '16px', xl: '16px' },
  mt: { xxs: '24px', xs: '24px', sm: '24px', md: '24px', lg: 'initial', xl: 'initial' },
  color: 'brand.neutral.700',
};

export const labelStyles = {
  fontSize: { xxs: 'sm', xs: 'sm', sm: 'sm', md: 'sm', lg: 'xs', xl: 'xs' },
  fontWeight: 'normal',
  color: 'brand.neutral.700',
};

export const errorStyles = {
  fontSize: 'xs',
  color: 'red.800AA',
  marginTop: '8px',
  marginLeft: '16px',
};

export const textStyles = {
  pb: '24px',
  textAlign: 'center',
};

export const buttonStyles = {
  w: '100%',
  marginTop: '24px !important',
};

export const passwordTextStyles = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginBottom: '24px',
};

export const resetButtonStyles = {
  w: '100%',
  h: '48px',
};

export const containerStyles = {
  mt: '40px',
  mb: '72px',
  w: 'auto',
  pr: '40px',
};

export const dividerStyles = {
  my: 0,
};

export const loginStackStyles = (isLogin) => {
  return {
    width: isLogin ? '77%' : '100%',
    alignItems: 'center',
    minWidth: '200px',
    my: '24px !important',
  };
};
