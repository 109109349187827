// @flow
import React from 'react';
import { Stack } from '@chakra-ui/react';
import { listContainerStyles } from './styles/list.style';

type BaselaneListProps = {
  data: Object,
  listStyles: Object,
  itemRenderer: Function,
  title?: ReactNode,
  listAs?: 'ol' | 'ul' | 'dl' | 'div',
};

function BaselaneList({ data, listStyles, itemRenderer, title, listAs }: BaselaneListProps): any {
  const listStyle = { ...listContainerStyles, ...listStyles };
  return (
    <Stack as={listAs} {...listStyle}>
      {title}
      {data?.map((item, index) => itemRenderer(item, index))}
    </Stack>
  );
}

BaselaneList.defaultProps = {
  title: null,
  listAs: 'div',
};

export default BaselaneList;
