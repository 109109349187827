export const obieModalStyles = {
  modalContentStyles: {
    p: '0',
    maxW: '636px',
    margin: 'auto',
    w: {
      xxs: 'calc(100% - 40px)',
      xs: 'calc(100% - 40px)',
      sm: 'calc(100% - 40px)',
      md: 'calc(100% - 40px)',
      lg: '636px',
    },
    borderWidth: '1px',
    borderColor: 'brand.darkBlue.150',
    textAlign: 'left',
    mt: '420px',
    position: 'fixed',
    maxHeight: 'calc(100% - 48px)',
  },
  modalTitleStyles: {
    textStyle: 'headline-md',
    color: 'brand.neutral.900',
    m: '0',
    p: '24px 24px 0',
  },
  modalSubtitleStyles: {
    textStyle: 'headline-sm',
    color: 'brand.neutral.700',
    m: '0',
    p: '0 24px',
  },
  modalBodyStyles: { p: '16px 24px', mt: 1.5, overflow: 'auto' },
  modalFooter: {
    textStyle: 'headline-sm',
    color: 'brand.neutral.700',
    m: '0',
    p: '12px',
    borderTop: '1px solid',
    borderTopColor: 'brand.darkBlue.200',
  },
};

export const emptyStateStyles = {
  container: {
    p: '24px 0 8px',
    color: 'brand.neutral.900',
    borderTopWidth: '1px',
    borderColor: 'brand.darkBlue.150',
    alignItems: 'center',
  },
  title: {
    mt: '16px!important',
    textStyle: 'headline-md',
  },
  description: {
    textStyle: 'sm',
    m: '0 0 16px 0 !important',
  },
  button: {
    m: '0 !important',
  },
};
