// @flow
import React, { useState, useEffect, useRef, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { cloneDeep } from 'lodash';
import { useLazyQuery, useMutation, useApolloClient } from '@apollo/client';
import { Form, Formik } from 'formik';
import {
  FormControl,
  FormLabel,
  Spacer,
  Stack,
  Text,
  Textarea,
  HStack,
  ChakraProvider,
  Box,
  Divider,
} from '@chakra-ui/react';
import {
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  BaselaneDrawer,
  BaselaneMessageCard,
  NoPropertyTooltip,
  T2Dropdown,
  T2WithTitleDropdown,
} from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import { GET_DOCUMENTS, DELETE_DOCUMENT, GET_TRANSACTIONS } from '@core/apollo/queries';
import { IconExclamationCircle } from '@icons';

import { renderPropertyDropdownParentItem } from '@shared/helpers/propertiesFilter.helpers';
import TransactionContext from '@contexts/TransactionContext';

import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import habitatTheme from '@core/themeHabitat';
import {
  DisplayInputDefaultPropertyItem,
  DisplayInputDefaultCategoryItem,
} from '../DisplayInputVariations';
import SplitTransactionsDrawer from '../SplitTransactionsDrawer';
import TransactionDataUpdate from '../TransactionDataUpdate';
import AutoSave from '../components/AutoSave';
import FileUploader from '../components/FileUploader';
import DocumentCard from '../components/DocumentCard';
import type { Transaction } from './types';
import { getTransactionById } from '../helpers/getTransactionById.helper';
import {
  textAreaStyles,
  transactionDetailsDrawerStyles,
} from '../styles/transactionDetailsDrawer.style';
import PreviewTransaction from './PreviewTransaction';
import TransactionActions from './TransactionActions';
import EditTransaction from './EditTransaction';

type TransactionDetailsDrawerProps = {
  transaction: Transaction,
  splitTrxRef: any,
  trxDetailsRef: any,
  isTrxDetailsDrawerOpen: boolean,
  setIsTrxDetailsDrawerOpen: Function,
  handleMerchantSubmit: Function,
  handleDateSubmit: Function,
  handleNoteSubmit: Function,
  externalFilters?: Object,
  setHasUpdatedSplitTrx: Function,
  account: Object,
  elapsedTime: String,
  filters: Object,
  categoryMap: Object,
  categoryOptions: Array<Object>,
  propertyOptions: Array<Object>,
  getSelectedProperty: Function,
  handleCategorySubmit: Function,
  handlePropertySubmit: Function,
  pending: boolean,
};

function TransactionDetailsDrawer({
  transaction,
  splitTrxRef,
  trxDetailsRef,
  isTrxDetailsDrawerOpen,
  setIsTrxDetailsDrawerOpen,
  handleMerchantSubmit,
  handleDateSubmit,
  handleNoteSubmit,
  externalFilters,
  setHasUpdatedSplitTrx,
  account,
  elapsedTime,
  filters,
  categoryMap,
  categoryOptions,
  propertyOptions,
  getSelectedProperty: selectedProperty,
  handleCategorySubmit,
  handlePropertySubmit,
  pending,
}: TransactionDetailsDrawerProps): any {
  const [isOnEditMode, setIsOnEditMode] = useState(false);
  const { categoryIdsMap } = useContext(TransactionContext);
  const { isMinXL, isMax576, isMax768 } = getBreakPoints();
  const { cache } = useApolloClient();
  const formikRef = useRef();
  const btnRef = useRef();
  const cancelRef = useRef();

  const [
    transactionById,
    { data: { transactionById: parentTransaction = {} } = {}, loading },
  ] = getTransactionById({
    id: transaction.parentId,
  });

  const [getdocuments] = useLazyQuery(GET_DOCUMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: { entityId: transaction?.id },
  });
  const [deleteDocument, { loading: isDeleteDocLoading }] = useMutation(DELETE_DOCUMENT);

  const { id: propertyId, showValueName: propertyName } = selectedProperty() ?? {};
  const initialValues = {
    note: transaction.note?.text || '',
    hidden: transaction.hidden,
    categoryId: transaction.tagId,
    propertyId,
    propertyName,
  };
  const initialSplitTransactions = parentTransaction?.splitTransactions || [];
  const [splitTransactions, setSplitTransactions] = useState(initialSplitTransactions);
  const [isDeleteDocAlertOpen, setIsDeleteDocAlertOpen] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [documents, setDocuments] = useState([]);

  const handleDrawerOpen = () => {
    trxDetailsRef?.current?.open();
  };
  const handleDrawerClose = () => {
    trxDetailsRef?.current?.close?.();
  };

  const handleDeleteDocAlertOpen = ({ id }) => {
    setSelectedDocId(id);
    setIsDeleteDocAlertOpen(true);
  };

  const handleDeleteDocAlertClose = () => {
    setSelectedDocId(null);
    setIsDeleteDocAlertOpen(false);
  };

  const handleClose = () => {
    const { note: updatedNote } = formikRef.current?.values ?? {};
    if (updatedNote !== initialValues.note) {
      formikRef.current?.submitForm();
    }
    setIsTrxDetailsDrawerOpen(false);
    handleDrawerClose();
  };

  const handleDeleteDocInCache = ({ deleteUserDocument }) => {
    cache.updateQuery(
      {
        query: GET_TRANSACTIONS,
        variables: { input: { ...filters, filter: { ...filters.filter, ...externalFilters } } },
      },
      (data) => {
        const { transaction: transactionsInCache } = data ?? {};
        const clonedTrxs = cloneDeep(transactionsInCache);
        const updatedTrx = clonedTrxs.map((trx) => {
          const clonedTrx = cloneDeep(trx);
          if (trx.id === transaction?.id) {
            const updatedDocuments = documents?.filter((doc) => doc.id !== deleteUserDocument?.id);
            setDocuments(updatedDocuments);

            if (!updatedDocuments?.length) {
              clonedTrx.isDocumentUploaded = false;
            }
          }
          return clonedTrx;
        });

        return {
          transaction: updatedTrx,
        };
      }
    );
  };

  const onDeleteDocSuccss = ({ deleteUserDocument }) => {
    handleDeleteDocInCache({ deleteUserDocument });
    handleDeleteDocAlertClose();
  };

  const handleGetDocs = async () => {
    const documentsData = await getdocuments();
    const { fetchDocuments } = documentsData?.data ?? {};
    setDocuments(fetchDocuments);
  };

  const handleDeleteDoc = async () => {
    const deletedDocumentData = await deleteDocument({ variables: { id: selectedDocId } });
    const { data: deletedDocData } = deletedDocumentData ?? {};
    const { deleteUserDocument } = deletedDocData ?? {};
    onDeleteDocSuccss({ deleteUserDocument });
  };

  const { id, date, isDocumentUploaded } = transaction ?? {};

  useEffect(() => {
    if (isTrxDetailsDrawerOpen) {
      handleDrawerOpen();
    }
    if (isTrxDetailsDrawerOpen && transaction?.parentId) {
      transactionById();
    }
  }, [isTrxDetailsDrawerOpen]);

  useEffect(() => {
    if (parentTransaction?.splitTransactions) {
      setSplitTransactions(parentTransaction?.splitTransactions || []);
    }
  }, [parentTransaction]);

  useEffect(() => {
    if (isTrxDetailsDrawerOpen) {
      handleGetDocs();
    }
  }, [isTrxDetailsDrawerOpen]);

  const drawerTitle = 'Transaction Details';

  // Destructure Imported Styles
  const { bannerStyle, fileUploadStyle, docDeleteAlert } = transactionDetailsDrawerStyles ?? {};

  const deleteDocAlertFooter = (
    <HStack {...docDeleteAlert({ isMinXL }).container}>
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="lg"
        onClick={handleDeleteDocAlertClose}
      >
        Cancel
      </BaselaneButton>
      <BaselaneButton
        variant="filled"
        palette="danger"
        size="lg"
        onClick={handleDeleteDoc}
        isLoading={isDeleteDocLoading}
      >
        Delete
      </BaselaneButton>
    </HStack>
  );

  const getSelectedProperty = (values) => {
    const { propertyId: pId, propertyName: showValueName } = values;

    let selected = null;
    if (showValueName) {
      selected = { id: pId, showValueName };
    }
    return selected;
  };

  const propertyDropdownProps = (values: Object, setFieldValue: Function) => ({
    additionalProps: { id: 'property-dropdown', name: 'property' },
    classNames: ['input-form-md', 'is-full-width'],
    data: propertyOptions,
    placeholder: 'Select Property',
    title: 'Property',
    showValueByFields: ['showValueName'],
    parentItemRenderer: ({ item }) => renderPropertyDropdownParentItem(item),
    childItemRenderer: itemRenderer,
    handleSubmit: (selectedValue) => {
      const selectedPropertyName = propertyOptions.find((option) => option.id === selectedValue)
        ?.showValueName;
      setFieldValue('propertyId', selectedValue);
      setFieldValue('propertyName', selectedPropertyName ?? '');
      handlePropertySubmit(selectedValue, {
        showErrToast: true,
        showSuccessfulToast: false,
        updatedField: 'Property',
      });
    },
    isMulti: false,
    hasFilterWrapper: false,
    selectedItem: getSelectedProperty(values),
    hideSearch: isMobile,
    parentId: 'drawer-body',
    CustomDisplayInput: DisplayInputDefaultPropertyItem,
    isMobile: isMax768,
  });
  const getSelectedCategory = (values) => {
    return values?.categoryId && values?.categoryId !== ''
      ? {
          id: categoryIdsMap[values.categoryId],
          name: categoryMap[values.categoryId],
        }
      : null;
  };
  const getCategoryPlaceholder = (values) => {
    return !values?.categoryId || values?.categoryId === '' ? 'Select Category' : '';
  };

  const handleCategoryDropdownSubmit = (selectedValue, setFieldValue) => {
    if (selectedValue.length === 0) {
      setFieldValue('categoryId', null);
    } else {
      const [parentId, subId] = selectedValue.split('-');
      setFieldValue('categoryId', subId ?? parentId);
    }

    handleCategorySubmit(selectedValue, {
      showErrToast: true,
      showSuccessfulToast: true,
      updatedField: 'Category',
    });
  };
  const categoryDropdownProps = (values: Object, setFieldValue: Function) => ({
    additionalProps: { id: 'category-dropdown' },
    classNames: ['input-form-md', 'is-full-width'],
    data: categoryOptions,
    title: 'Category',
    showValueByFields: ['name'],
    placeholder: getCategoryPlaceholder(values),
    parentItemRenderer: itemRenderer,
    childItemRenderer: itemRenderer,
    handleSubmit: (selectedValue) => handleCategoryDropdownSubmit(selectedValue, setFieldValue),
    selectedItem: getSelectedCategory(values),
    isMulti: false,
    hasFilterWrapper: false,
    CustomDisplayInput: DisplayInputDefaultCategoryItem,
    isMobile: isMax768,
  });
  return (
    <ChakraProvider theme={habitatTheme}>
      <BaselaneDrawer
        ref={trxDetailsRef}
        finalFocusRef={btnRef}
        title={drawerTitle}
        onClose={handleClose}
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        newDesignDrawer
      >
        {isOnEditMode ? (
          <EditTransaction
            transaction={transaction}
            onCancel={() => {
              setIsOnEditMode(false);
            }}
            onSuccess={() => {
              setIsOnEditMode(false);
            }}
          />
        ) : (
          <>
            <PreviewTransaction transaction={transaction} account={account} />
            <TransactionActions
              transaction={transaction}
              isOnEditMode={isOnEditMode}
              onEdit={() => {
                setIsOnEditMode(true);
              }}
              onSplit={() => {
                splitTrxRef?.current?.open();
              }}
            />
          </>
        )}

        <Box paddingY={1} mt={2}>
          <Divider borderColor="brand.darkBlue.200" />
        </Box>

        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={() => {
            const { note: newNote } = formikRef.current?.values || '';
            handleNoteSubmit(newNote);
            formikRef.current?.setSubmitting(false);
          }}
          enableReinitialize
        >
          {({ values, handleChange, handleBlur, setFieldValue, getFieldProps }) => (
            <Form noValidate>
              {/* Split Trx Message Card */}
              {transaction?.parentId && (
                <BaselaneMessageCard
                  iconName="info"
                  iconColor="blue"
                  borderColor="blue"
                  title="This transaction is part of a split transaction"
                  isMinXL={isMinXL}
                  styles={{ ...bannerStyle({ isMinXL }) }}
                />
              )}

              {/* Category */}
              <FormControl>
                <FormLabel htmlFor="category">Category</FormLabel>
                <T2WithTitleDropdown {...categoryDropdownProps(values, setFieldValue)} />
              </FormControl>

              {/* Property */}
              <FormControl>
                <FormLabel htmlFor="property">Property</FormLabel>
                {propertyOptions?.length === 0 ? (
                  <NoPropertyTooltip>
                    <T2Dropdown {...propertyDropdownProps(values, setFieldValue)} isDisabled />
                  </NoPropertyTooltip>
                ) : (
                  <T2Dropdown {...propertyDropdownProps(values, setFieldValue)} />
                )}
              </FormControl>

              {/* Notes */}
              <FormControl mt={2}>
                <HStack>
                  <FormLabel htmlFor="note">Notes</FormLabel>
                  <Spacer />
                  <AutoSave {...{ formikRef, debounceMs: 2000, elapsedTime }} />
                </HStack>

                <Textarea
                  id="note"
                  name="note"
                  placeholder="Add Note..."
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  {...textAreaStyles}
                />
              </FormControl>

              <Box paddingY={1} mt={2}>
                <Divider borderColor="brand.darkBlue.200" />
              </Box>

              {/* File Upload */}
              <Stack mt={2}>
                <FileUploader
                  {...{
                    entityId: id,
                    entityDate: date,
                    documents,
                    setDocuments,
                    filters,
                    externalFilters,
                  }}
                />

                {/* Document List */}
                {isDocumentUploaded && (
                  <Stack {...fileUploadStyle({ isMinXL }).documents.container}>
                    <Text {...fileUploadStyle({ isMinXL }).documents.title}>Saved Attachments</Text>
                    {documents?.map((doc) => (
                      <DocumentCard {...{ key: doc.id, doc, handleDeleteDocAlertOpen }} />
                    ))}
                  </Stack>
                )}
              </Stack>

              {/* Updated Trx Data: Show if Merchant Name or Date's updated */}
              {!pending && (
                <TransactionDataUpdate
                  {...{
                    transaction,
                    handleMerchantSubmit,
                    handleDateSubmit,
                  }}
                />
              )}
            </Form>
          )}
        </Formik>

        <SplitTransactionsDrawer
          {...{
            splitTrxRef,
            transaction,
            splitTransactions,
            setSplitTransactions,
            loading,
            externalFilters,
            setHasUpdatedSplitTrx,
            propertyOptions,
          }}
        />

        <BaselaneAlert
          size={isMinXL ? 'xs' : '2xl'}
          showCloseButton
          isCentered
          isOpen={isDeleteDocAlertOpen}
          onClose={() => handleDeleteDocAlertClose()}
          leastDestructiveRef={cancelRef}
          header={
            <AlertHeader
              title="Are you sure you want to delete the attachment?"
              icon={<IconExclamationCircle w={20} h={20} color="#EA6868" />}
              iconBgStyles={{ bg: 'red.100' }}
              isHorizontalCenter
            />
          }
          footer={deleteDocAlertFooter}
          isMinXL={isMinXL}
        />
      </BaselaneDrawer>
    </ChakraProvider>
  );
}

TransactionDetailsDrawer.defaultProps = {
  externalFilters: ({}: { ... }),
};

export default TransactionDetailsDrawer;
