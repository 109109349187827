// @flow
import React, { useEffect, useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
  Checkbox,
  Box,
  Stack,
  Switch,
  Center,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import type { Property } from '../../Properties/types';

function getButtonTitle(numberOfCheckedProperties: number): string {
  switch (numberOfCheckedProperties) {
    case 0:
      return 'No properties';
    case 1:
      return `${numberOfCheckedProperties} Property`;
    default:
      return `${numberOfCheckedProperties} Properties`;
  }
}

function getCheckedPropertyIDs(checkedProperties): string[] {
  return Object.keys(checkedProperties).filter((propertyKey) => checkedProperties[propertyKey]);
}

function buildCheckboxesFor(
  properties: Property[],
  checked = true
): { [key: string]: boolean, checked: boolean } {
  return properties.reduce((acc, property) => {
    acc[property.id] = checked;
    return acc;
  }, {});
}

function PropertiesFilter({
  onSelectProperties,
  initialProperties,
}: {
  onSelectProperties: Function,
  initialProperties: Property[],
}): any {
  const [properties, setProperties] = useState([]);

  const [checkboxOptions, setCheckboxOptions] = useState({});

  const [allPropertiesAllSelected, setAllPropertiesAreSelected] = useState(true);

  const { onOpen, onClose, isOpen } = useDisclosure();

  const initialFocusRef = React.useRef(null);

  const [buttonTitle, setButtonTitle] = useState(null);

  const [isAggregatingProperties, setIsAggregatingProperties] = useState(false);

  function updatePropertiesFilter() {
    if (isOpen) {
      const checkedIDs = getCheckedPropertyIDs(checkboxOptions);

      onSelectProperties(checkedIDs, isAggregatingProperties);

      onClose();
    }
  }

  useEffect(() => {
    setProperties(initialProperties);

    const initialCheckboxOptions = buildCheckboxesFor(initialProperties);

    setCheckboxOptions(initialCheckboxOptions);
  }, [initialProperties]);

  useEffect(() => {
    if (allPropertiesAllSelected) {
      const initialCheckboxOptions = buildCheckboxesFor(properties);

      setCheckboxOptions(initialCheckboxOptions);
    }
  }, [allPropertiesAllSelected, properties]);

  useEffect(() => {
    const shouldBeChecked = Object.values(checkboxOptions).every(Boolean);

    setAllPropertiesAreSelected(shouldBeChecked);

    const numberOfCheckedProperties = getCheckedPropertyIDs(checkboxOptions).length;

    const newButtonTitle = getButtonTitle(numberOfCheckedProperties);

    setButtonTitle(newButtonTitle);
  }, [checkboxOptions]);

  const handleAllPropertiesChecked = (checked) => {
    setAllPropertiesAreSelected(checked);
    if (!checked) {
      const initialCheckedProperties = buildCheckboxesFor(properties, false);

      setCheckboxOptions(initialCheckedProperties);
    }
  };

  return (
    <Popover
      isLazy
      returnFocusOnClose={false}
      initialFocusRef={initialFocusRef}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={updatePropertiesFilter}
      placement="bottom-end"
    >
      <PopoverTrigger>
        <BaselaneButton variant="outline" palette="neutral">
          {buttonTitle}
        </BaselaneButton>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          p="16px"
          color="brand.neutral.700"
          boxShadow="0px 4px 12px rgb(4 39 101 / 12%)"
        >
          <PopoverHeader pb="16px">
            {/* Show Everything */}
            <Checkbox
              className="primary-500"
              isChecked={allPropertiesAllSelected}
              onChange={(e) => handleAllPropertiesChecked(e.target.checked)}
            >
              <Text textStyle="sm" color="brand.neutral.700">
                Show Everything
              </Text>
            </Checkbox>
          </PopoverHeader>
          <PopoverBody h="300px" overflow="auto">
            {/* Properties */}
            <Stack>
              {properties.map((property) => (
                <Box key={property.id}>
                  <Checkbox
                    className="primary-500"
                    isChecked={checkboxOptions[property.id]}
                    onChange={(e) =>
                      setCheckboxOptions({
                        ...checkboxOptions,
                        [property.id]: e.target.checked,
                      })
                    }
                  >
                    <Text textStyle="sm" color="brand.neutral.700">
                      {property.name}
                    </Text>
                  </Checkbox>
                </Box>
              ))}
            </Stack>
          </PopoverBody>
          <PopoverFooter pt="16px">
            <Center direction="row">
              <Text textStyle="sm" color="brand.neutral.700">
                Aggregate properties
              </Text>
              <Spacer />
              <Switch
                colorScheme="scheme.blue"
                size="md"
                isChecked={isAggregatingProperties}
                onChange={() => setIsAggregatingProperties(!isAggregatingProperties)}
              />
            </Center>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default PropertiesFilter;
