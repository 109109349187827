import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { IconCheckCircle, IconCrossCircle } from '@icons';

function Checkitem({ item }: { item: Object }) {
  const { isValid, name } = item;
  return (
    <Stack direction="row" alignItems="center">
      {isValid ? (
        <Text as="span" {...{ color: 'green.500A' }}>
          <IconCheckCircle color="currentColor" w="16" h="16" viewBox="0 0 20 20" />
        </Text>
      ) : (
        <Text as="span" {...{ color: 'brand.neutral.500', px: '2px' }}>
          <IconCrossCircle color="currentColor" w="16" h="16" viewBox="0 0 16 16" />
        </Text>
      )}
      <Text
        textStyle="xs"
        p="2px 0"
        whiteSpace="nowrap"
        w="100%"
        color={isValid ? 'brand.neutral.700' : 'brand.neutral.500'}
      >
        {name}
      </Text>
    </Stack>
  );
}

export default Checkitem;
