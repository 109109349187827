import React from 'react';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import {
  BaselaneAddress,
  BaselaneAutoCompleteAddress,
  BaselaneButtonToggle,
  BaselaneRadioCard,
} from '@shared/components';
import {
  ONBOARDING_ADD_PROPERTY,
  UNIFIED_RENT_COLLECTION,
  UNIFIED_BOOKKEEPING_ANALYTICS,
} from '@routes';
import MultipleUnits from '@pages/PropertiesPage/AddEditProperty/DrawerPanel/Details/MultipleUnits';
import { zipcodeMask } from '@core/utils/masks';
import {
  formValidation,
  propertyTypeOptions,
  setUnitNames,
} from '@pages/PropertiesPage/helpers/detail.helper';
import { formErrorStyles, formLabelStyles } from '@shared/styles/input.style';
import { formInputStyles } from '@pages/PropertiesPage/styles/form.style';
import {
  dynamicFieldContainer,
  fieldsStyles,
} from '@pages/PropertiesPage/styles/addPropertyFormLayout.styles';

type AddPropertyFormLayoutProps = {
  setFormVariables: Function,
  setIsValid?: Function,
  setDetailsComplete?: Function,
  formRef: any,
  setIsDirty?: Function,
  isDirty?: boolean,
  property: Object,
  initialValues: Object,
  shortForm: Boolean,
  handleFormSubmit?: Boolean,
  hasEnteredAddress?: Boolean,
  setHasEnteredAddress?: Function,
};

const AddPropertyFormLayout = ({
  setFormVariables,
  setIsValid,
  setDetailsComplete,
  formRef,
  setIsDirty,
  isDirty,
  property,
  initialValues,
  shortForm,
  handleFormSubmit,
  hasEnteredAddress,
  setHasEnteredAddress,
}: AddPropertyFormLayoutProps) => {
  const { getRootProps } = useRadioGroup();
  const group = getRootProps();
  const { pathname = '' } = useLocation();

  const isAddProperty =
    [ONBOARDING_ADD_PROPERTY, UNIFIED_RENT_COLLECTION, UNIFIED_BOOKKEEPING_ANALYTICS].indexOf(
      pathname
    ) > -1;
  const multiUnitPt = pathname === ONBOARDING_ADD_PROPERTY ? '0' : '10px';
  const multiUnitMt = pathname === ONBOARDING_ADD_PROPERTY ? '0 !important' : '4px';
  const fieldStylesProps = {
    hasEnteredAddress,
    shortForm,
    isAddProperty,
    multiUnitPt,
    multiUnitMt,
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validate={(values) => formValidation(values, setDetailsComplete)}
      validateOnBlur
      onSubmit={handleFormSubmit}
    >
      {({
        dirty,
        values,
        errors,
        touched,
        isValid,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          {/* Property Type */}
          {!shortForm && (
            <Box>
              <Stack
                direction="row"
                {...group}
                flexWrap="wrap"
                spacing={0}
                position="relative"
                pb="24px"
                onBlur={() => {
                  setIsDirty({ ...isDirty, ...{ details: dirty } });
                  setFormVariables({ type: values.type });
                  setIsValid(isValid);
                }}
              >
                {propertyTypeOptions.map((option) => {
                  const { value, label, icon } = option;
                  const id = label.replace(/\s+/g, '-').toLowerCase();
                  return (
                    <BaselaneRadioCard
                      id={id}
                      key={value}
                      name="type"
                      isChecked={value === values.type}
                      value={value}
                      onChange={handleChange}
                    >
                      <Stack alignItems="center" justifyContent="flex-end" h="100%">
                        {icon}
                        <Box h="40px" mt="16px !important">
                          <Text textStyle="xs" textAlign="center">
                            {label}
                          </Text>
                        </Box>
                      </Stack>
                    </BaselaneRadioCard>
                  );
                })}
              </Stack>
              <Text {...formErrorStyles} position="relative" top="-16px">
                {errors.type && touched.type && <Box as="span">{errors.type}</Box>}
              </Text>
            </Box>
          )}
          {values.type === 'other' && (
            <FormControl
              {...dynamicFieldContainer(isAddProperty)}
              isInvalid={errors.customPropertyType && touched.customPropertyType}
            >
              <FormLabel htmlFor="customPropertyType" {...formLabelStyles.xs}>
                Custom Property Type
              </FormLabel>
              <Input
                {...formInputStyles}
                id="customPropertyType"
                name="customPropertyType"
                value={values.customPropertyType}
                placeholder="Skyscraper"
                onChange={(e) => {
                  setFieldTouched('customPropertyType', true);
                  handleChange(e);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  setIsDirty({ ...isDirty, ...{ details: dirty } });
                  setFormVariables({ customPropertyType: values.customPropertyType });
                  setIsValid(isValid);
                }}
              />
              <FormErrorMessage {...formErrorStyles}>
                <Box as="span">{errors.customPropertyType}</Box>
              </FormErrorMessage>
            </FormControl>
          )}

          {/* Property Name */}
          {!shortForm && (
            <FormControl
              {...dynamicFieldContainer(isAddProperty)}
              isInvalid={errors.propertyName && touched.propertyName}
            >
              <FormLabel htmlFor="propertyName" {...formLabelStyles.xs}>
                Property Name
              </FormLabel>
              <Input
                {...formInputStyles}
                id="propertyName"
                name="propertyName"
                value={values.propertyName}
                placeholder="e.g. Downtown Duplex"
                onChange={(e) => {
                  setFieldTouched('propertyName', true);
                  handleChange(e);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  setIsDirty({ ...isDirty, ...{ details: dirty } });
                  setFormVariables({ propertyName: values.propertyName });
                  setIsValid(isValid);
                }}
              />
              <FormErrorMessage {...formErrorStyles}>
                <Box as="span">{errors.propertyName}</Box>
              </FormErrorMessage>
            </FormControl>
          )}

          {/* AutoComplete/Street Address */}
          <BaselaneAutoCompleteAddress
            {...{
              values,
              errors,
              touched,
              isValid,
              handleChange,
              handleBlur,
              setFormVariables,
              setIsValid,
              formLabelStyles: formLabelStyles.xs,
              formInputStyles,
              formErrorStyles,
              formContainerStyles: dynamicFieldContainer(
                isAddProperty,
                pathname === ONBOARDING_ADD_PROPERTY && !hasEnteredAddress ? '0' : '90px'
              ),
              setIsDirty: () => setIsDirty({ ...isDirty, ...{ details: dirty } }),
              setHasEnteredAddress,
            }}
          />

          {hasEnteredAddress && (
            <>
              <Box
                {...(isAddProperty || shortForm
                  ? fieldsStyles(fieldStylesProps).addressFields
                  : {})}
              >
                {/* Unit Number */}
                <FormControl
                  {...dynamicFieldContainer(isAddProperty)}
                  isInvalid={errors.unitNumber && touched.unitNumber}
                >
                  <FormLabel htmlFor="unitNumber" {...formLabelStyles.xs}>
                    Apartment, Unit, Suite, or Floor #
                  </FormLabel>
                  <Input
                    {...formInputStyles}
                    id="unitNumber"
                    name="unitNumber"
                    value={values.unitNumber}
                    placeholder="e.g. Floor 1"
                    onChange={handleChange}
                    onBlur={(e) => {
                      handleBlur(e);
                      setIsDirty({ ...isDirty, ...{ details: dirty } });
                      setFormVariables({ unitNumber: values.unitNumber });
                      setIsValid(isValid);
                    }}
                  />
                  <FormErrorMessage {...formErrorStyles}>
                    <Box as="span">{errors.unitNumber}</Box>
                  </FormErrorMessage>
                </FormControl>

                <Stack
                  {...dynamicFieldContainer(isAddProperty)}
                  {...fieldsStyles(fieldStylesProps).cspContainer}
                >
                  {/* City */}
                  <FormControl
                    {...dynamicFieldContainer(isAddProperty)}
                    {...fieldsStyles(fieldStylesProps).cFormField}
                    isInvalid={errors.city && touched.city}
                    w={isMobile ? '45%' : '50%'}
                  >
                    <FormLabel htmlFor="city" {...formLabelStyles.xs}>
                      City
                    </FormLabel>
                    <Input
                      {...formInputStyles}
                      id="city"
                      name="city"
                      value={values.city}
                      placeholder="e.g. Philadelphia"
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setIsDirty({ ...isDirty, ...{ details: dirty } });
                        setFormVariables({ city: values.city });
                        setIsValid(isValid);
                      }}
                    />
                    <FormErrorMessage {...formErrorStyles}>
                      <Box as="span">{errors.city}</Box>
                    </FormErrorMessage>
                  </FormControl>

                  {/* State */}
                  <FormControl
                    position="relative"
                    w={isMobile ? '40%' : '25%'}
                    isInvalid={errors.state && touched.state}
                  >
                    <FormLabel htmlFor="state" {...formLabelStyles.xs}>
                      State
                    </FormLabel>
                    <Select
                      {...formInputStyles}
                      id="state"
                      name="state"
                      value={values.state}
                      placeholder="Select"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        setFieldTouched('state', true);
                        handleBlur(e);
                        setIsDirty({ ...isDirty, ...{ details: dirty } });
                        setFormVariables({ state: values.state });
                        setIsValid(isValid);
                      }}
                    >
                      <BaselaneAddress.StateOptions />
                    </Select>
                    <FormErrorMessage {...formErrorStyles}>
                      <Box as="span">{errors.state}</Box>
                    </FormErrorMessage>
                  </FormControl>

                  {/* Zip Code */}
                  <FormControl
                    position="relative"
                    w={isMobile ? '25%' : '25%'}
                    isInvalid={errors.zipcode && touched.zipcode}
                  >
                    <FormLabel htmlFor="zipcode" {...formLabelStyles.xs}>
                      Zip Code
                    </FormLabel>
                    <Input
                      {...formInputStyles}
                      as={MaskedInput}
                      mask={zipcodeMask}
                      id="zipcode"
                      name="zipcode"
                      value={values.zipcode}
                      placeholder="e.g. 19123"
                      onChange={handleChange}
                      type="text"
                      onBlur={(e) => {
                        handleBlur(e);
                        setIsDirty({ ...isDirty, ...{ details: dirty } });
                        setFormVariables({ zipcode: values.zipcode });
                        setIsValid(isValid);
                      }}
                    />
                    <FormErrorMessage {...formErrorStyles}>
                      <Box as="span">{errors.zipcode}</Box>
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </Box>

              {/* Property Auto Nickname */}
              <FormControl
                {...dynamicFieldContainer(isAddProperty)}
                {...(!isAddProperty && shortForm
                  ? fieldsStyles(fieldStylesProps).nickname
                  : { display: 'none' })}
                isInvalid={errors.propertyName && touched.propertyName}
              >
                <FormLabel htmlFor="propertyName" {...formLabelStyles.xs}>
                  Property Nickname
                </FormLabel>
                <Input
                  {...formInputStyles}
                  id="propertyName"
                  name="propertyName"
                  value={values.propertyName}
                  placeholder="e.g. Downtown Duplex"
                  onChange={(e) => {
                    setFieldTouched('propertyName', true);
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    setIsDirty({ ...isDirty, ...{ details: dirty } });
                    setFormVariables({ propertyName: values.propertyName });
                    setIsValid(isValid);
                  }}
                />
                <FormErrorMessage {...formErrorStyles}>
                  <Box as="span">{errors.propertyName}</Box>
                </FormErrorMessage>
              </FormControl>

              {/* Single/Multiple Units Toggle Button */}
              <Box
                {...(isAddProperty || shortForm
                  ? fieldsStyles(fieldStylesProps).unitsToggle
                  : { pt: '40px' })}
                pb={isMobile && (!values?.unitType || values.unitType === 'single') ? '0' : '0'}
              >
                <BaselaneButtonToggle
                  firstId="single-unit-button"
                  firstLabel="Single Unit"
                  secondId="multiple-units-button"
                  secondLabel="Multiple Units"
                  isDisabled={property ? property.units.length > 1 : false}
                  activeButton={values.unitType === 'single' ? 0 : 1}
                  onClick={(i) => {
                    const type = Number(i) === 0 ? 'single' : 'multiple';
                    setFieldValue('unitType', type);
                    setUnitNames(
                      initialValues,
                      values,
                      property,
                      type,
                      setFieldValue,
                      setFieldTouched,
                      setDetailsComplete
                    );
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    setIsDirty({ ...isDirty, ...{ details: dirty } });
                    setFormVariables({ unitType: values.unitType });
                    setIsValid(isValid);
                  }}
                  id="unitType"
                  name="unitType"
                />
              </Box>
              {/* )} */}
              {/* Unit Names if Multiple Units Selected */}
              {values.unitType === 'multiple' && (
                <Box my={shortForm && !hasEnteredAddress ? '0' : '8px'}>
                  <MultipleUnits
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setIsDirty={setIsDirty}
                    setFormVariables={setFormVariables}
                    setIsValid={setIsValid}
                    isValid={isValid}
                    dirty={dirty}
                    isDirty={isDirty}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Formik>
  );
};

AddPropertyFormLayout.defaultProps = {
  handleFormSubmit: () => {},
  isDirty: false,
  setIsDirty: () => {},
  setDetailsComplete: () => {},
  setIsValid: () => {},
  hasEnteredAddress: false,
  setHasEnteredAddress: () => {},
};

export default AddPropertyFormLayout;
