import React, { useRef } from 'react';
import { Box, Stack, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import {
  BaselaneBadge,
  BaselaneButton,
  BaselaneDivider,
  BaselaneRadioCard,
  TwoFactorVerificationPopUp,
  useTwoFactor,
} from '@shared/components';
import { truncatedText } from '@shared/styles/text.style';
import { InProgress } from '@core/components/GetStartedPage/components/Steps';

import ActivatePopup from './ActivatePopup';
import CardDetails from './CardDetails';
import VirtualCardProgressBar from './VirtualCardProgressBar';
import { CARD_STATUS } from './helpers/card.helper';
import { getFormattedCurrency } from '../helpers/currency.helpers';
import {
  getIsTokenExpired,
  getIsTokenValid,
  getSensitiveToken,
  getUnitOTPVerification,
  handleNBOnVerifyClick,
  handleOTPPopupClose,
  handleOTPPopupOpen,
  setTokenData,
} from '../../../../helpers/otp.helpers';
import {
  cardStyles,
  cardContainerStyles,
  cardContainerCheckedStyles,
  cardDetailsButtonStyles,
  cardInnerContainerStyles,
  cardHeaderStyles,
  cardHeaderContainer,
  cardTextStyles,
  cardMasterAccNameStyles,
  cardMainAccNameStyles,
  cardNumberStyles,
  cardMTDContainerStyles,
  cardMTDLabelStyles,
  cardMTDValueStyles,
  cardStatusStyles,
} from './styles/card.styles';

type CardProps = {
  card: Object,
  onActivationSuccessPopupOpen: Function,
  isGetStarted?: boolean,
  getStartedConfig?: Object,
  startDetailsOpened?: Boolean,
  bankInfo: Object,
};

function Card({
  card,
  onActivationSuccessPopupOpen,
  isGetStarted,
  getStartedConfig,
  bankInfo,
  startDetailsOpened,
}: CardProps) {
  const {
    bankId,
    phoneNumber = '',
    bankName: masterAccountName,
    availableBalance: bankAccountBalance,
  } = bankInfo;

  // Alert State
  const {
    isOpen: isActivatePopupOpen,
    onOpen: onActivatePopupOpen,
    onClose: onActivatePopupClose,
  } = useDisclosure();
  // two factor verification states
  // NOTE: see AddEditSubAccountDrawer/index.jsx for some comments about OTP and setting the information for sensitive token/time
  const { states, stateFunctions } = useTwoFactor();
  const { setOTPErrorCode } = stateFunctions;

  // OTP: Check if token of the bank expired
  const isTokenExpired = getIsTokenExpired(bankId);

  // card props
  const { accountName, cardFinancials, cardStatus, last4Digits, limit, nickname } = card ?? {};
  const { badge } = CARD_STATUS[cardStatus] ?? {};

  const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

  const onUserSensitiveTokenComplete = (unitAPISensitiveToken) => {
    handleOTPPopupClose(states, stateFunctions);

    const isTokenValid = getIsTokenValid(unitAPISensitiveToken);
    setTokenData(unitAPISensitiveToken, bankId);

    if (isTokenValid) {
      // after successfully getting sensitive token open popup
      onActivatePopupOpen();
    }
  };

  const getSensitiveTokenProps = {
    onUserSensitiveTokenComplete,
    getOTP,
    bankId,
    states,
    stateFunctions,
  };
  const [getUserSensitiveTokenData] = getSensitiveToken(getSensitiveTokenProps);

  const mtdSpent = getFormattedCurrency(cardFinancials?.mtdSpent);

  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP,
    bankId,
    phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleNBOnVerifyClick(otpCode, bankId, getUserSensitiveTokenData),
  };

  const cardInfo = (
    <Text {...cardNumberStyles(isMobile)} {...truncatedText} alignItems="flex-start">
      {card.isPhysical ? 'Physical Card' : 'Virtual Card'} - {last4Digits}
    </Text>
  );

  const cardName = (
    <Text {...cardMasterAccNameStyles(isMobile)} {...truncatedText}>
      {nickname ?? `${masterAccountName} ${accountName}`}
    </Text>
  );

  const cardPhysicalFinancialInfo = (
    <Stack {...cardMTDContainerStyles}>
      <Box {...cardMTDValueStyles(isMobile)}>{mtdSpent}</Box>
      {!isMobile && <Box {...cardMTDLabelStyles}>This Month’s Amount Spent</Box>}
    </Stack>
  );

  const cardFooter = card.isPhysical ? (
    cardPhysicalFinancialInfo
  ) : (
    <VirtualCardProgressBar
      {...{ cardFinancials, limit, bankAccountAvailableBalance: bankAccountBalance }}
    />
  );

  // card details drawer ref + functions
  const cardDetailsDrawerRef = useRef(null);
  const onOpenCardDetailsDrawer = () => cardDetailsDrawerRef?.current?.open();
  const onCloseCardDetailsDrawer = () => cardDetailsDrawerRef?.current?.close();

  return (
    <>
      <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />

      {isGetStarted ? (
        <InProgress
          status={getStartedConfig.status}
          handleTrackerInProgStepClick={(e) => {
            e.stopPropagation();
            if (isTokenExpired) {
              handleOTPPopupOpen(getOTP, bankId, stateFunctions);
            } else {
              onActivatePopupOpen();
            }
          }}
        />
      ) : (
        <BaselaneRadioCard
          containerStyles={cardContainerStyles(isMobile)}
          styles={cardStyles(isMobile)}
          checkedStyles={cardContainerCheckedStyles}
          focusedStyles={{ boxShadow: 'none' }}
          direction="row"
          onClick={isMobile ? onOpenCardDetailsDrawer : null}
        >
          <Stack direction="row" height="100%">
            <Stack {...cardInnerContainerStyles(isMobile)}>
              {/* Above Divider */}
              <Stack {...cardHeaderStyles} textAlign="flex-start">
                <Stack {...cardTextStyles(isMobile)}>
                  <Stack direction="row" {...cardHeaderContainer}>
                    {isMobile ? cardName : cardInfo}
                    {!isMobile && <Spacer />}
                    {!isMobile && badge && (
                      <Stack alignItems="flex-end" flex="1">
                        <BaselaneBadge
                          type="badge"
                          color={badge.color}
                          text={badge.text}
                          backgroundStyles={cardStatusStyles}
                        />
                      </Stack>
                    )}
                  </Stack>

                  <Stack direction="row" m="0 !important">
                    {isMobile ? cardInfo : cardName}
                  </Stack>

                  {isMobile && badge && (
                    <Stack>
                      <BaselaneBadge
                        type="badge"
                        color={badge.color}
                        text={badge.text}
                        backgroundStyles={cardStatusStyles}
                      />
                    </Stack>
                  )}

                  {!isMobile && cardStatus !== 'Inactive' && (
                    <Stack {...cardTextStyles(isMobile)}>
                      <Box {...cardMainAccNameStyles}>
                        <CardDetails
                          {...{
                            card,
                            bankInfo,
                            startOpened: startDetailsOpened,
                            onOpenCardDetailsDrawer,
                            onCloseCardDetailsDrawer,
                            cardDetailsDrawerRef,
                          }}
                        />
                      </Box>
                    </Stack>
                  )}
                </Stack>
              </Stack>

              {!isMobile && <Spacer />}

              {!isMobile && <BaselaneDivider styles={{ my: '14px !important' }} />}

              {/* Below Divider */}
              {cardStatus === 'Inactive' ? (
                <BaselaneButton
                  variant="tonal"
                  palette="primary"
                  styles={{ alignSelf: 'flex-start' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isTokenExpired) {
                      handleOTPPopupOpen(getOTP, bankId, stateFunctions);
                    } else {
                      onActivatePopupOpen();
                    }
                  }}
                >
                  Activate Card
                </BaselaneButton>
              ) : (
                cardFooter
              )}
            </Stack>
            {isMobile && cardStatus !== 'Inactive' && (
              <Stack {...cardDetailsButtonStyles}>
                <CardDetails
                  {...{
                    card,
                    bankInfo,
                    startOpened: startDetailsOpened,
                    onOpenCardDetailsDrawer,
                    onCloseCardDetailsDrawer,
                    cardDetailsDrawerRef,
                  }}
                />
              </Stack>
            )}
          </Stack>
        </BaselaneRadioCard>
      )}

      <ActivatePopup
        {...{
          card,
          bankInfo: { bankId },
          isActivatePopupOpen,
          onActivatePopupClose,
          onActivationSuccessPopupOpen,
        }}
      />
    </>
  );
}

Card.defaultProps = {
  isGetStarted: false,
  getStartedConfig: {},
  startDetailsOpened: false,
};

export default Card;
