import { gql } from '@apollo/client';

export const GET_UNIT_APPLICATION = gql`
  query UserUnitAccount($id: ID!) {
    userUnitAccount(id: $id) {
      id
      applicationType
      dba
      completedTags
      unitApplicationStatus
      unitApplicationId
      website
      businessVertical
      contact {
        dateOfBirth
        ssn
        ein
        email
        fullName {
          first
          last
        }
        ip
        phone {
          countryCode
          number
        }
        dateOfBirth
      }
      legalAddress {
        street
        city
        state
        postalCode
        country
        unit
      }
    }
  }
`;

export const CREATE_OR_UPDATE_LB_APPLICATION = gql`
  mutation createIndividualUnitApplication(
    $id: ID
    $fullName: FullNameInput
    $dateOfBirth: String
    $phone: PhoneInput
    $email: String
    $address: UnitAddressInput
    $ssn: String
    $ip: String
    $ein: String
    $dba: String
    $businessVertical: UnitBusinessVertical
    $website: String
    $action: String!
    $iovation: String
  ) {
    createIndividualUnitApplication(
      input: {
        id: $id
        fullName: $fullName
        dateOfBirth: $dateOfBirth
        phone: $phone
        email: $email
        address: $address
        ssn: $ssn
        ein: $ein
        dba: $dba
        businessVertical: $businessVertical
        website: $website
        ip: $ip
        action: $action
        iovation: $iovation
      }
    ) {
      id
      applicationType
      dba
      completedTags
      unitApplicationStatus
      unitApplicationId
      businessVertical
      website
      contact {
        dateOfBirth
        ssn
        ein
        email
        industry
        fullName {
          first
          last
        }
        ip
        phone {
          countryCode
          number
        }
        dateOfBirth
      }
      legalAddress {
        street
        city
        state
        postalCode
        country
        unit
      }
    }
  }
`;
