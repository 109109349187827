import React from 'react';

function IconDocumentCheck() {
  return (
    <svg width="40" height="43" viewBox="0 0 40 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31 20.5V4.2c0-1.1201 0-1.68016-.218-2.10798-.1917-.37633-.4977-.68229-.874-.87403C29.4802 1 28.9201 1 27.8 1H4.2c-1.1201 0-1.68016 0-2.10798.21799-.37633.19174-.68229.4977-.87403.87403C1 2.51984 1 3.07989 1 4.2v29.6c0 1.1201 0 1.6802.21799 2.108.19174.3763.4977.6823.87403.874C2.51984 37 3.07989 37 4.2 37H16M5 12h22M5 16h22M5 20h11"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8418 41.6708c.3114-.0411.6259.0433.8739.2344l1.1431.8771c.3779.2903.9035.2903 1.2803 0l1.1863-.9109c.2217-.17.5014-.2449.7779-.208l1.484.1953c.4718.0623.9267-.2005 1.1093-.6407l.571-1.3806c.1066-.2586.3114-.4634.57-.57l1.3805-.571c.4401-.1816.7029-.6376.6407-1.1094l-.1879-1.4302c-.0412-.3114.0433-.626.2343-.874l.8771-1.1432c.2903-.3778.2903-.9035 0-1.2803l-.9109-1.1864c-.1699-.2217-.2448-.5014-.2079-.7779l.1953-1.4841c.0622-.4718-.2006-.9268-.6407-1.1094l-1.3805-.571c-.2586-.1066-.4634-.3114-.57-.57l-.571-1.3806c-.1815-.4402-.6375-.703-1.1093-.6407l-1.484.1952c-.2765.038-.5562-.0369-.7768-.2058l-1.1864-.9109c-.3778-.2903-.9034-.2903-1.2802 0l-1.1864.9109c-.2216.1689-.5013.2438-.7779.208l-1.484-.1953c-.4717-.0623-.9267.2005-1.1093.6407l-.5699 1.3806c-.1077.2576-.3124.4623-.57.57l-1.3805.57c-.4401.1826-.7029.6375-.6407 1.1093l.1953 1.4841c.0359.2766-.0391.5563-.2079.7769l-.9109 1.1864c-.2903.3779-.2903.9035 0 1.2803l.9109 1.1865c.1699.2216.2448.5013.2079.7779l-.1953 1.4841c-.0622.4718.2006.9267.6407 1.1093l1.3805.5711c.2586.1066.4634.3113.57.5699l.571 1.3807c.1815.4401.6375.7029 1.1093.6407l1.4291-.189Z"
        fill="#6ADA9B"
      />
      <path
        d="m24.129 32.6881 2.8494 2.8494 5.6989-5.6989m-6.8355 10.8322c.3114-.0411.6259.0433.8739.2344l1.1431.8771c.3779.2903.9035.2903 1.2803 0l1.1863-.9109c.2217-.17.5014-.2449.7779-.208l1.484.1953c.4718.0623.9267-.2005 1.1093-.6407l.571-1.3806c.1066-.2586.3114-.4634.57-.57l1.3805-.571c.4401-.1816.7029-.6376.6407-1.1094l-.1879-1.4302c-.0412-.3114.0433-.626.2343-.874l.8771-1.1432c.2903-.3778.2903-.9035 0-1.2803l-.9109-1.1864c-.1699-.2217-.2448-.5014-.2079-.7779l.1953-1.4841c.0622-.4718-.2006-.9268-.6407-1.1094l-1.3805-.571c-.2586-.1066-.4634-.3114-.57-.57l-.571-1.3806c-.1815-.4402-.6375-.703-1.1093-.6407l-1.484.1952c-.2765.038-.5562-.0369-.7768-.2058l-1.1864-.9109c-.3778-.2903-.9034-.2903-1.2802 0l-1.1864.9109c-.2216.1689-.5013.2438-.7779.208l-1.484-.1953c-.4717-.0623-.9267.2005-1.1092.6407l-.57 1.3806c-.1077.2576-.3124.4623-.57.57l-1.3805.57c-.4401.1826-.7029.6375-.6407 1.1093l.1953 1.4841c.0359.2766-.0391.5563-.2079.7769l-.9109 1.1864c-.2903.3779-.2903.9035 0 1.2803l.9109 1.1865c.1699.2216.2448.5013.2079.7779l-.1953 1.4841c-.0622.4718.2006.9267.6407 1.1093l1.3805.571c.2586.1067.4634.3114.57.57l.571 1.3807c.1815.4401.6375.7029 1.1093.6407l1.4291-.189Z"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="7.5"
        cy="6.5"
        r="2.5"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconDocumentCheck;
