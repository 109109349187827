import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import { truncatedText } from '@core/components/Shared/styles/text.style';
import { Placeholder, DisplayInputWrapper } from '.';

type DropDownDisplayInputProps = {
  selectedOptions: Array<Object>,
  handleContentRendererClick: Function,
  handleClearClick: Function,
  hideInputClearBtn: boolean,
  placeholder: string,
  title: string,
  children: React.Node,
  classNames: Array<string>,
};

const DropDownDisplayInputWithOutRightButton = ({
  selectedOptions,
  handleContentRendererClick,
  handleClearClick,
  hideInputClearBtn,
  placeholder,
  title,
  classNames,
}: DropDownDisplayInputProps) => {
  return (
    <DisplayInputWrapper
      handleContentRendererClick={handleContentRendererClick}
      handleClearClick={handleClearClick}
      selectedOptions={selectedOptions}
      classNames={classNames}
    >
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder placeholder={placeholder} title={title} />
      ) : (
        <HStack
          color="brand.neutral.500"
          overflow="hidden"
          justifyContent="space-between"
          width="100%"
        >
          <Text color="brand.neutral.900" {...truncatedText}>
            {selectedOptions[0].showValueName ?? selectedOptions[0].name}{' '}
          </Text>
          {selectedOptions[0]?.provider !== 'MANUAL' && (
            <Text color="brand.neutral.500">
              {formatCurrency(selectedOptions[0].availableBalance).inDollars}
            </Text>
          )}
        </HStack>
      )}
    </DisplayInputWrapper>
  );
};

export default DropDownDisplayInputWithOutRightButton;
