import React from 'react';
import { DrawerBody as DrawerBodyContainer, Tabs } from '@chakra-ui/react';
import DrawerList from './DrawerList';
import DrawerPanel from './DrawerPanel';
import { drawerBodyStyle } from '../styles/drawer.style';

type DrawerBodyProps = {
  isDirty: boolean,
  setIsDirty: Function,
  setIsValid: Function,
  onTabChange: Function,
  setIsError: Function,
  setHideFooter: Function,
  detailsComplete: boolean,
  formRef: any,
  setDetailsComplete: Function,
  tabIndex: number,
  property: Object,
  setFormVariables: Function,
  initialValues: Object,
  variables: Object,
  setShowZillow: Function,
  setShowMortgage: Function,
  shortForm?: Boolean,
};

function DrawerBody({
  isDirty,
  setIsDirty,
  setIsValid,
  setIsError,
  setHideFooter,
  tabIndex,
  onTabChange,
  detailsComplete,
  setDetailsComplete,
  formRef,
  property,
  setFormVariables,
  initialValues,
  variables,
  setShowZillow,
  setShowMortgage,
  shortForm,
}: DrawerBodyProps): any {
  return (
    <DrawerBodyContainer {...drawerBodyStyle}>
      <Tabs
        orientation="vertical"
        variant="unstyled"
        index={tabIndex}
        onChange={onTabChange}
        h="calc(100% - 9px)"
      >
        {!!property && <DrawerList {...{ detailsComplete }} />}
        <DrawerPanel
          {...{
            isDirty,
            setIsDirty,
            setIsValid,
            setIsError,
            setHideFooter,
            detailsComplete,
            setDetailsComplete,
            formRef,
            property,
            setShowZillow,
            setShowMortgage,
            setFormVariables,
            initialValues,
            variables,
            shortForm,
          }}
        />
      </Tabs>
    </DrawerBodyContainer>
  );
}

DrawerBody.defaultProps = {
  shortForm: false,
};

export default DrawerBody;
