const sectionStyles = (orientation, spacing) => {
  return {
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      h: '100%',
      flexBasis: spacing !== '' ? 'auto' : '33.33%',
      pl: 'inherit',
    },
    content: {
      justifyContent: 'center',
      alignItems: 'center',
      direction: orientation === 'vertical' ? 'column' : 'row',
      w: 'max-content',
      gap: 0,
    },
  };
};

const leftSectionStyles = (orientation, spacing) => {
  return {
    container: {
      ...sectionStyles(orientation, spacing).container,
      justifyContent: 'flex-start',
      pl: spacing !== '' ? spacing : '24px',
      ml: spacing !== '' ? `${spacing} !important` : 0,
    },
    content: {
      ...sectionStyles(orientation).content,
      alignItems: orientation === 'vertical' ? 'flex-start' : 'center',
    },
  };
};

export const containerStyles = {
  h: '80px',
  w: '100%',
  justifyContent: 'space-between',
};

export const borderStyles = {
  borderLeftWidth: '1px',
  borderLeftColor: 'brand.darkBlue.150',
};

export const headerTitleContainerStyles = { display: 'flex' };
export const headerStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
  display: 'flex',
};

export const contentStyles = (title, orientation) => {
  return {
    fontSize: '2xl',
    lineHeight: '32px',
    color: 'brand.neutral.700',
    fontWeight: 'semibold',
    marginTop: title && orientation === 'vertical' ? { md: '16px', base: '8px !important' } : 0,
    width: '100%',
  };
};

export const getSectionStyles = (position, orientation, spacing) => {
  return position === 'left' || spacing !== ''
    ? leftSectionStyles(orientation, spacing)
    : sectionStyles(orientation, spacing);
};
