import { isMobile } from 'react-device-detect';

export const headingTextStyles = () => {
  return {
    fontSize: isMobile ? 'lg' : '2xl',
    lineHeight: isMobile ? '26px' : '32px',
    fontWeight: 'semibold',
    color: 'brand.neutral.900',
  };
};

export const addPropertyButtonContainerStyles = () => ({
  position: isMobile ? 'fixed' : 'relative',
  bottom: '0',
  left: '0',
  background: isMobile ? 'brand.neutral.white' : 'transparent',
  w: '100%',
  p: isMobile ? '12px 24px 24px' : '0',
  boxShadow: isMobile ? '0 0 12px #ffffff' : 'none',
});

export const continueButtonStyles = (hasEnteredAddress) => ({
  width: isMobile ? '100%' : 'initial',
  height: hasEnteredAddress ? '40px' : '0px',
  p: hasEnteredAddress ? '10px 24px' : '0px',
  visibility: hasEnteredAddress ? 'visible' : 'hidden',
  opacity: hasEnteredAddress ? '1' : '0',
  transition: 'visibility 0s, opacity 0.5s linear',
});
