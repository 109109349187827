import React from 'react';
import DatePicker from 'react-datepicker';
import DatePickerInput from './DatePickerInput';
import DatePickerFormInput from './DatePickerFormInput';
import Header from './Header';
import MonthYearPicker from './MonthYearPicker';
import BackToToday from './BackToToday';
import {
  getYearsArr,
  getMonthsArr,
  getParsedDate,
  checkIsCurrentMonthYear,
} from '../helpers/shared.helpers';
import '../styles/datePicker.scss';
import { BaselaneDatePickerBaseProps } from '../types/datePickerBase.types';

const DatePickerBase = ({
  startDate,
  endDate,
  selected,
  pickerRef,
  portalId,
  disabled,
  years,
  minDate,
  maxDate,
  placeholderText,
  dateFormat,
  handleFocus,
  onKeyDown,
  onChange,
  onCalendarOpen,
  onCalendarClose,
  onBlur,
  onClickOutside,
  onSelect,
  onInputClick,
  selectsStart,
  selectsEnd,
  fixedHeight,
  shouldCloseOnSelect,
  hideInputIcon,
  size,
  inputLabel,
  className,
  calendarClassName,
  dayClassName,
  showInModal,
  showTodayButton,
  setShowTodayButton,
  hideTodayButton,
  showMonthYearSelector,
  setShowMonthYearSelector,
  handleBackToTodayClick,
  isInsideForm,
  isCalendarOpen,
  ...rest
}: BaselaneDatePickerBaseProps) => {
  const yearsArr = getYearsArr(years);
  const monthsArr = getMonthsArr();

  const onFocus = (e) => {
    if (handleFocus) {
      handleFocus(e);
    }
  };

  const onMonthChange = (val) => {
    if (!hideTodayButton) {
      checkIsCurrentMonthYear({
        dateToBeChecked: val,
        setShowTodayButton,
      });
    }
  };

  const onYearChange = (val) => {
    if (!hideTodayButton) {
      checkIsCurrentMonthYear({
        dateToBeChecked: val,
        setShowTodayButton,
      });
    }
  };

  return (
    <DatePicker
      {...{
        ref: pickerRef,
        startDate,
        endDate,
        selected,
        portalId,
        placeholderText,
        minDate,
        maxDate,
        dateFormat,
        disabled,
        selectsStart,
        fixedHeight,
        onKeyDown,
        onChange,
        onFocus,
        onBlur,
        onSelect,
        onInputClick,
        onClickOutside,
        onMonthChange,
        onYearChange,
        onCalendarOpen,
        onCalendarClose,
        calendarClassName,
        dayClassName,
        shouldCloseOnSelect,
        showPopperArrow: false,
        ...rest,
      }}
      customInput={
        isInsideForm ? (
          <DatePickerFormInput
            {...{
              isCalendarOpen,
              className,
              handleInputChange: onChange,
              hideInputIcon,
              size,
              label: inputLabel,
            }}
          />
        ) : (
          <DatePickerInput
            {...{
              className,
              hideInputIcon,
              size,
              label: inputLabel,
            }}
          />
        )
      }
      renderCustomHeader={({
        date: renderedDate,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        const { year: parsedYear, month: parsedMonth } = getParsedDate(renderedDate);

        const isFirstMonthAvailInCalendar = yearsArr[0] === parsedYear && parsedMonth === 0;
        const isLastMonthAvailInCalendar =
          yearsArr[yearsArr.length - 1] === parsedYear && parsedMonth === 11;

        return (
          <>
            <Header
              {...{
                increaseMonth,
                decreaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
                parsedMonth,
                parsedYear,
                showMonthYearSelector,
                setShowMonthYearSelector,
                isFirstMonthAvailInCalendar,
                isLastMonthAvailInCalendar,
              }}
            />

            {showMonthYearSelector && (
              <MonthYearPicker
                {...{
                  yearsArr,
                  monthsArr,
                  parsedMonth,
                  parsedYear,
                  changeYear,
                  changeMonth,
                  setShowMonthYearSelector,
                  showInModal,
                }}
              />
            )}
          </>
        );
      }}
    >
      {!showMonthYearSelector && showTodayButton && <BackToToday {...{ handleBackToTodayClick }} />}
    </DatePicker>
  );
};

DatePickerBase.defaultProps = {
  startDate: null,
  endDate: null,
  selected: null,
  portalId: 'root',
  years: {
    start: null,
    end: null,
  },
  placeholderText: 'Select date',
  handleFocus: () => {},
  onBlur: () => {},
  onClickOutside: () => {},
  onSelect: () => {},
  onInputClick: () => {},
  // setting min/maxDate to null disables arrow key navigation
  // seems to be a bug from the react-datepicker library
  // https://stackoverflow.com/questions/63558051/cant-make-keyboard-arrows-work-on-react-datepicker
  minDate: undefined,
  maxDate: undefined,
  disabled: false,
  className: '',
  calendarClassName: '',
  dayClassName: () => {},
  hideInputIcon: false,
  size: 'lg',
  inputLabel: null,
  dateFormat: 'MMM dd, yyyy',
  selectsStart: false,
  selectsEnd: false,
  fixedHeight: false,
  shouldCloseOnSelect: true,
  showInModal: false,
};

export default DatePickerBase;
