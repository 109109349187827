import { addressFormValidation } from '@shared/helpers/validations.helpers';

const handleRecipientFormValidation = (values) => {
  const errors = {};
  if (values.recipientName?.trim() === '') {
    errors.recipientName = 'Please enter in recipient name';
  }

  if (values.recipientName?.trim()?.length > 50) {
    errors.recipientName = 'Name cannot be more than 50 characters';
  }

  if (values.routingNumber?.trim()?.length < 9) {
    errors.routingNumber = 'Enter a valid domestic routing number';
  }

  if (values.accountNumber?.trim() === '') {
    errors.accountNumber = 'Please enter in account number';
  }

  if (values.accountNumber?.trim() !== values.retypeAccountNumber) {
    errors.retypeAccountNumber = 'Account numbers do not match';
  }
  return errors;
};

export const handleValidation = (values, currentStep, resetApiErrors) => {
  let errors = {};

  // note: values.stepNumber is for when a specific validation needs to be done that is
  //       not onblur. e.g here it is for when users click on prev btn and to revalidate form
  //       on moving to next form. See onNextClicked + onPrevClicked.
  if (currentStep === 1 || values.stepNumber === 1) {
    errors = {
      ...errors,
      ...handleRecipientFormValidation(values),
    };
  } else if (currentStep === 2 || values.stepNumber === 2) {
    errors = { ...errors, ...addressFormValidation(values) };
  }

  if (Object.keys(errors).length === 0) {
    resetApiErrors();
  }

  return errors;
};
