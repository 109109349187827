// @flow
import React from 'react';
import { isMobile } from 'react-device-detect';
import BaselaneBadge from '../BaselaneBadge';
import {
  currentBackgroundStyles,
  mobileContainerStyles,
  mobileFontStyles,
} from './styles/leaseBadge.style';

type Props = {
  text?: string,
  styles?: Object,
};

function CurrentStatusBadge({ text, styles }: Props): any {
  return isMobile ? (
    <BaselaneBadge
      backgroundStyles={{ ...currentBackgroundStyles, ...mobileContainerStyles, ...styles }}
      fontStyles={{ ...mobileFontStyles, ...styles }}
      type="master"
      color="green"
      isMobile
      text={text?.toUpperCase()}
    />
  ) : (
    <BaselaneBadge
      backgroundStyles={{ ...currentBackgroundStyles, ...styles }}
      type="master"
      color="green"
      text={text}
      iconName="check"
      iconClassName="icon-xsm"
    />
  );
}

CurrentStatusBadge.defaultProps = {
  text: 'Current',
  styles: {},
};

export default CurrentStatusBadge;
