import React from 'react';
import { Box, Divider } from '@chakra-ui/react';
import isNil from '@core/utils/validators/isNil';

function ConfigurableProgress({
  value,
  color,
  left,
  hasBorder,
  margin,
  name,
}: {
  value: number,
  color: string,
  left?: number,
  hasBorder: Boolean,
  margin: string,
  name: string,
}): any {
  const FALLBACK_WIDTH = 100;
  const dividerStyles = {
    borderLeftWidth: '1px',
    borderColor: 'brand.neutral.500',
    h: '75px',
    w: '1px',
    opacity: '1',
    orientation: 'vertical',
    position: 'absolute',
    top: name === 'Market Value' ? 'inherit' : '0',
    bottom: name === 'Market Value' ? '0' : 'inherit',
    borderStyle: 'dashed',
  };
  return (
    <Box w="100%" h="100%" position="relative">
      <Box
        as="div"
        h="20px"
        bg={color}
        w={`${isNil(value) ? FALLBACK_WIDTH : value}%`}
        ml={`${left}%`}
      />
      {hasBorder && value > 0 ? (
        <Divider
          {...dividerStyles}
          className="divider"
          marginLeft={`${margin}% !important`}
          left={`${value || FALLBACK_WIDTH}%`}
        />
      ) : null}
    </Box>
  );
}

ConfigurableProgress.defaultProps = {
  left: 0,
};

export default ConfigurableProgress;
