import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { accordionItemChildStyles } from '@shared/components/BaselaneDropdown-new/styles/accordion.styles';
import { DisplayInputSharedProps } from '../types/displayInputVariations.types';

const DisplayInputDropdownOption = ({ onOpen, text, ...rest }: DisplayInputSharedProps) => {
  return (
    <HStack key={text} align="center" width="100%" height="40px" cursor="pointer" {...rest}>
      <HStack tabIndex="-1" className="element" onClick={onOpen} {...accordionItemChildStyles}>
        <Text>{text}</Text>
      </HStack>
    </HStack>
  );
};

export default DisplayInputDropdownOption;
