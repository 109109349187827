import React from 'react';

type IconGoEditProps = {
  w?: Number,
  h?: Number,
  color?: String,
};

function IconGoEdit({ w = 14, h = 10, color = 'currentColor' }: IconGoEditProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1694 2.37496C14.067 2.33306 13.9572 2.31198 13.8466 2.31294C13.7359 2.3139 13.6266 2.33689 13.5249 2.38056C13.4232 2.42424 13.3313 2.48772 13.2544 2.56731L13.2443 2.57764L6.33333 9.48857V10.6667H7.51149L14.4224 3.7558L14.4327 3.74565C14.5123 3.66877 14.5758 3.57682 14.6195 3.47515C14.6632 3.37348 14.6862 3.26413 14.6871 3.15348C14.6881 3.04284 14.667 2.9331 14.6251 2.83069C14.5832 2.72827 14.5213 2.63523 14.4431 2.55698C14.3648 2.47874 14.2718 2.41686 14.1694 2.37496ZM13.8321 0.646335C14.164 0.64345 14.4932 0.706705 14.8005 0.832407C15.1077 0.958109 15.3868 1.14374 15.6216 1.37847C15.8563 1.6132 16.0419 1.89233 16.1676 2.19958C16.2933 2.50681 16.3566 2.83601 16.3537 3.16796C16.3508 3.49991 16.2819 3.82796 16.1508 4.13297C16.0209 4.43553 15.8324 4.7094 15.5963 4.93888L8.44592 12.0893C8.28964 12.2456 8.07768 12.3334 7.85667 12.3334H5.5C5.03976 12.3334 4.66667 11.9603 4.66667 11.5001V9.14339C4.66667 8.92237 4.75446 8.71041 4.91074 8.55413L12.0611 1.40373C12.2906 1.16764 12.5645 0.97918 12.8671 0.849207C13.1721 0.718184 13.5001 0.649219 13.8321 0.646335ZM1.23223 3.06562C1.70107 2.59678 2.33696 2.33339 3 2.33339H7.16667C7.6269 2.33339 8 2.70648 8 3.16672C8 3.62696 7.6269 4.00005 7.16667 4.00005H3C2.77899 4.00005 2.56702 4.08785 2.41074 4.24413C2.25446 4.40041 2.16667 4.61237 2.16667 4.83339V14.0001C2.16667 14.2211 2.25446 14.433 2.41074 14.5893C2.56702 14.7456 2.77899 14.8334 3 14.8334H12.1667C12.3877 14.8334 12.5996 14.7456 12.7559 14.5893C12.9122 14.433 13 14.2211 13 14.0001V9.83339C13 9.37315 13.3731 9.00005 13.8333 9.00005C14.2936 9.00005 14.6667 9.37315 14.6667 9.83339V14.0001C14.6667 14.6631 14.4033 15.299 13.9344 15.7678C13.4656 16.2367 12.8297 16.5001 12.1667 16.5001H3C2.33696 16.5001 1.70107 16.2367 1.23223 15.7678C0.763392 15.299 0.5 14.6631 0.5 14.0001V4.83339C0.5 4.17035 0.763392 3.53446 1.23223 3.06562Z"
        fill={color}
      />
    </svg>
  );
}

IconGoEdit.defaultProps = {
  w: 17,
  h: 17,
  color: 'currentColor',
};

export default IconGoEdit;
