export const transferStates = {
  TRANSFER_COMPLETED: 'TRANSFER_COMPLETED',
  TRANSFER_SCHEDULED: 'TRANSFER_SCHEDULED',
  TRANSFER_PROCESSING: 'TRANSFER_PROCESSING',
  TRANSFER_FAILED: 'TRANSFER_FAILED',
  TRANSFER_RETURNED: 'TRANSFER_RETURNED',
  TRANSFER_INVALID: 'TRANSFER_INVALID',
  TRANSFER_AWAITING_IMAGES: 'TRANSFER_AWAITING_IMAGES',
  TRANSFER_CANCELED: 'TRANSFER_CANCELED',
  TRANSFER_IN_TRANSIT: 'TRANSFER_IN_TRANSIT',
  TRANSFER_DELIVERED: 'TRANSFER_DELIVERED',
  TRANSFER_DEPOSITED: 'TRANSFER_DEPOSITED',
  UPCOMING: 'UPCOMING',
};

export const labelByTransferState = {
  [transferStates.TRANSFER_COMPLETED]: 'Completed',
  [transferStates.TRANSFER_DELIVERED]: 'Delivered',
  [transferStates.TRANSFER_FAILED]: 'Failed',
  [transferStates.TRANSFER_RETURNED]: 'Returned',
  [transferStates.TRANSFER_PROCESSING]: 'Processing',
  [transferStates.TRANSFER_IN_TRANSIT]: 'In Transit',
  [transferStates.TRANSFER_SCHEDULED]: 'Scheduled',
  [transferStates.TRANSFER_INVALID]: 'Invalid',
  [transferStates.TRANSFER_AWAITING_IMAGES]: 'Awaiting Images',
  [transferStates.TRANSFER_CANCELED]: 'Canceled',
  [transferStates.TRANSFER_DEPOSITED]: 'Deposited',
  [transferStates.UPCOMING]: 'Upcoming',
};

export const chipVariantByTransferState = {
  [transferStates.TRANSFER_COMPLETED]: 'status-success',
  [transferStates.TRANSFER_DELIVERED]: 'status-success',
  [transferStates.TRANSFER_FAILED]: 'status-danger',
  [transferStates.TRANSFER_RETURNED]: 'status-danger',
  [transferStates.TRANSFER_PROCESSING]: 'status-primary',
  [transferStates.TRANSFER_IN_TRANSIT]: 'status-primary',
  [transferStates.TRANSFER_SCHEDULED]: 'status-warning',
  [transferStates.TRANSFER_INVALID]: 'status-danger',
  [transferStates.TRANSFER_AWAITING_IMAGES]: 'status-warning',
  [transferStates.TRANSFER_CANCELED]: 'status-neutral',
  [transferStates.TRANSFER_DEPOSITED]: 'status-success',
  [transferStates.UPCOMING]: 'status-primary',
};
