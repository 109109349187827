import React from 'react';

function IconSkeletonBar1({
  width = 45,
  height = 8,
  color = 'currentColor',
  borderRadius = 4,
}: {
  width?: string,
  height?: string,
  color?: string,
  borderRadius?: string,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx={borderRadius} fill={color} />
    </svg>
  );
}

IconSkeletonBar1.defaultProps = {
  width: '45',
  height: '8',
  color: '#F0F2F6',
  borderRadius: '4',
};

export default IconSkeletonBar1;
