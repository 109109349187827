import React, { useState } from 'react';
import { ChakraProvider, useMediaQuery } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { v4 } from 'uuid';
import habitatTheme from '@core/themeHabitat';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import CheckDepositDrawerFooter from './CheckDepositDrawerFooter';
import DepositChecksFromMobile from './DepositChecksFromMobile';
import ScanACheck from './steps/ScanACheck';
import BookkeepingDetails from './steps/BookkeepingDetails';
import ReviewAndDeposit from './steps/ReviewAndDeposit';
import SignAndEndorseCheck from './steps/SignAndEndorseCheck';
import useValidation from './hooks/useValidation';
import useFormSubmission from './hooks/form-submission/useFormSubmission';
import CheckDepositSuccess from './steps/CheckDepositSuccess';

const CheckDepositDrawer = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 900px)');
  const validate = useValidation();
  const navigate = useNavigate();
  const [xIdempotencyKey, setXIdempotencyKey] = useState(v4());
  const { submitForm, loading } = useFormSubmission(xIdempotencyKey);

  const closeDrawer = () => {
    navigate(-1);
  };

  // The steps of current feature.
  const steps = {
    SIGN_AND_ENDORSE_CHECK: 1,
    SCAN_A_CHECK: 2,
    BOOKKEEPING_DETAILS: 3,
    REVIEW_AND_DEPOSIT: 4,
    SUCCESS: 5,
  };

  const initialValues = {
    writeOnBackOfCheck: false,
    signTheCheck: false,
    frontOfCheck: '',
    backOfCheck: '',
    amount: '',
    accountId: '',
    categoryId: '', // Optional.
    propertyId: '', // Optional.
    notes: '', // Optional.
  };

  const handleSubmit = (values, { resetForm }) => {
    submitForm(values)
      .then((isSubmissionSuccessful) => {
        if (isSubmissionSuccessful) {
          setCurrentStep(steps.SUCCESS);
          resetForm();
        }
      })
      .catch((error) => {
        console.error('Error submitting the form', error);
      })
      .finally(() => {
        setXIdempotencyKey(v4());
      });
  };

  const [currentStep, setCurrentStep] = useState(steps.SIGN_AND_ENDORSE_CHECK);

  const renderDrawerBody = (step, featureIsUnavailable) => {
    if (featureIsUnavailable) {
      return <DepositChecksFromMobile />;
    }
    if (step === steps.SIGN_AND_ENDORSE_CHECK) {
      return <SignAndEndorseCheck />;
    }
    if (step === steps.SCAN_A_CHECK) {
      return <ScanACheck />;
    }
    if (step === steps.BOOKKEEPING_DETAILS) {
      return <BookkeepingDetails />;
    }
    if (step === steps.REVIEW_AND_DEPOSIT) {
      return <ReviewAndDeposit />;
    }
    return <CheckDepositSuccess />;
  };

  const isMobile = navigator.userAgent.indexOf('Mobi') > -1;

  const drawerBody = renderDrawerBody(currentStep, !isMobile);

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik validate={validate} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values }) => (
          <Form>
            <BaselaneDrawer
              isOpen
              size={isLargerThan768 ? 'newdrawersm' : 'newdrawerfull'}
              title={isLargerThan768 ? 'Mobile check deposit' : 'Deposit a check'}
              closeEvent={closeDrawer}
              onOverlayClick={closeDrawer}
              closeOnOverlayClick={false}
              newDesignDrawer
              footer={
                <CheckDepositDrawerFooter
                  steps={steps}
                  currentStep={currentStep}
                  isLoading={loading}
                  formValues={values}
                  onNextClicked={(step) => setCurrentStep(step)}
                />
              }
            >
              {drawerBody}
            </BaselaneDrawer>
          </Form>
        )}
      </Formik>
    </ChakraProvider>
  );
};

export default CheckDepositDrawer;
