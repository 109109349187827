import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import {
  panelDescriptionStyles,
  panelFormStyles,
  panelHeaderContainerStyles,
  panelHeaderStyles,
  panelHeaderWrapperStyles,
  tabPanelStyles,
} from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import { FormDescription, FormTitle } from '@shared/components/BaselaneFlow';

import { HomeAddressForm } from '../forms';
import FooterWrapper from '../FooterWrapper';

const HomeAddressRootView = () => {
  const footerWrapperProps = useOutletContext();
  return (
    <Box {...tabPanelStyles}>
      <Box {...panelHeaderWrapperStyles()}>
        <FormTitle
          title="Where do you live?"
          textStyles={panelHeaderStyles(isMobile)}
          containerStyles={panelHeaderContainerStyles(isMobile)}
        />
        <FormDescription styles={panelDescriptionStyles(isMobile)}>
          We need to verify your home address. This is also where we’ll send your new Baselane Visa
          debit card.
        </FormDescription>
      </Box>
      <Box {...panelFormStyles}>
        <HomeAddressForm
          {...{
            homeAddressRef: footerWrapperProps.formRefs[1],
            formData: footerWrapperProps.formData,
          }}
        />
      </Box>
      <FooterWrapper {...footerWrapperProps} />
    </Box>
  );
};

export default HomeAddressRootView;
