import { isMobile } from 'react-device-detect';

export const redeemDrawerFormStyles = {
  height: '100%',
};

export const redeemDrawerBodyStyles = {
  h: 'calc(100% - 80px)',
  p: isMobile ? '16px 24px' : '68px 64px',
};

export const titleStyles = {
  color: 'brand.neutral.700',
  fontSize: isMobile ? 'xl' : 'lg',
  lineHeight: '26px',
  fontWeight: 'semibold',
  mb: '40px',
};

export const availableCashBackStyles = {
  fontSize: '4xl',
  lineHeight: '48px',
  fontWeight: 'medium',
};

export const transferAmountLabelStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
};

export const redeemToStyles = {
  mt: '32px',
  mb: '36px',
};
