export const placeholderText = (value, inputName, selectedTrxs) => {
  const trxCount = selectedTrxs?.length;
  const merchantHasName = selectedTrxs?.find((trx) => trx.merchantName !== '');

  if (value === '') {
    if (trxCount === 1 && !merchantHasName) return 'Add Merchant';
    if (trxCount === 1 && inputName === 'Merchants') return 'Merchant';
    if (trxCount > 1 && !merchantHasName) return 'Add Merchants';
    return `Multiple ${inputName}`;
  }

  if (value === null || value === undefined) {
    return inputName === 'Properties' ? 'No property selected' : 'Uncategorized';
  }

  return '';
};

export const formatMultipleTransactions = (
  values,
  selectedTransactions,
  hasMerchantChanged,
  hasNotesChanged
) => {
  const { merchant, categoryId, notes, propertyId, unitId } = values;
  return selectedTransactions.map((trx) => {
    const updateTrx = { id: trx.id };
    if (hasMerchantChanged) updateTrx.merchantName = merchant;
    if (categoryId && categoryId !== '') {
      updateTrx.tagId = categoryId;
    } else if (categoryId === undefined) {
      updateTrx.tagId = null;
    }
    if (propertyId && propertyId !== '') {
      updateTrx.propertyId = propertyId;
    } else if (propertyId === null) {
      updateTrx.propertyId = null;
    }
    if (unitId && unitId !== '') {
      updateTrx.unitId = unitId;
    }
    if (hasNotesChanged) updateTrx.note = notes;
    return updateTrx;
  });
};

export const getEditTransactionsInitialValues = (selectedTransactions) =>
  selectedTransactions.reduce(
    (acc, trx, index) => {
      if (index === 0) {
        return {
          merchant: trx.merchantName === '' ? null : trx.merchantName,
          categoryId: trx.tagId,
          propertyId: trx.propertyId === '' ? null : trx.propertyId,
          unitId: trx.unitId === '' ? null : trx.unitId,
          notes: trx.note?.text,
        };
      }

      if (acc.merchant === trx.merchantName) {
        acc.merchant = trx.merchantName;
      } else {
        acc.merchant = '';
      }

      if (acc.categoryId === trx.tagId) {
        acc.categoryId = trx.tagId;
      } else {
        acc.categoryId = '';
      }

      if (acc.propertyId === trx.propertyId && acc.unitId === trx.unitId) {
        acc.propertyId = trx.propertyId ?? null;
        acc.unitId = trx.unitId ?? null;
      } else {
        acc.propertyId = '';
        acc.unitId = '';
      }

      if (acc.notes === trx.note?.text) {
        acc.notes = trx.note?.text;
      } else {
        acc.notes = '';
      }

      return acc;
    },
    {
      merchant: '',
      categoryId: '',
      propertyId: '',
      unitId: '',
      notes: '',
    }
  );
