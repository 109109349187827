export const containerStyles = (isMinXL, index, config, shouldShowBlockInMobile, isEmptyState) => {
  let display = 'none';
  if (config[index].isActive || shouldShowBlockInMobile || isEmptyState) {
    display = 'flex';
  }

  return {
    display: isMinXL ? display : 'flex',
    position: 'relative',
    alignItems: isMinXL ? 'flex-start' : 'center',
    w: '100%',
    _notLast: { pb: '16px' },
  };
};

export const blockContainerStyles = (isMinXL) => ({
  h: isMinXL ? '44px' : '56px',
  w: isMinXL ? '100%' : '70%',
  alignItems: 'center',
  textAlign: 'left',
  spacing: '4px',
});

export const connectorStyles = (showSolidConnector, hideConnector) => {
  return {
    borderLeftWidth: hideConnector ? '0' : '2px',
    borderColor: showSolidConnector ? 'brand.blue.800A' : 'brand.blue.400',
    borderStyle: showSolidConnector ? 'solid' : 'dashed',
    position: 'absolute',
    left: '9px',
    top: '20px',
    bottom: '-20px',
    zIndex: '-1',
  };
};

export const titleStyles = {
  textAlign: 'left',
  textStyle: 'headline-sm',
  color: 'brand.neutral.900',
};

export const productStyles = (item, isMinXL) => ({
  h: '100%',
  color: item.isActive ? 'brand.neutral.white' : 'brand.neutral.700',
  bgColor: item.isActive ? 'brand.blue.700' : '#F0F4FD',
  borderRadius: item.bonus ? '6px 0 0 6px' : '6px',
  p: isMinXL ? '5px 12px' : '10px 12px',
  spacing: 0,
  textStyle: 'xs',
  justifyContent: 'space-between',
});

export const bonusStyles = (item, isMinXL) => ({
  h: '100%',
  w: '72px',
  bgColor: item?.bonus[0]?.isActive ? 'green.500A' : 'green.100',
  borderRadius: '0 6px 6px 0 ',
  spacing: 0,
  textStyle: 'xs',
  p: isMinXL ? '5px 12px' : '10px 12px',
  color: item?.bonus[0]?.isActive ? 'brand.neutral.white' : 'brand.neutral.700',
  justifyContent: 'space-between',
});
