// @flow
export const TimePeriod = Object.freeze({
  ThisMonth: 'ThisMonth',
  LastMonth: 'LastMonth',
  Last3Months: 'Last3Months',
  Last6Months: 'Last6Months',
  Last12Months: 'Last12Months',
  YearToDate: 'YearToDate',
  LastYear: 'LastYear',
  SelectDate: 'SelectDate',
});
