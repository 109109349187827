import React from 'react';
import { IconChevronLeft, IconChevronRight, IconPoweredByRocketLawyer } from '@icons';
import BaselaneButton from '../../../BaselaneButton';

type FooterProps = {
  showSelectProperty: Boolean,
  showSelectUnit: Boolean,
  handleBackClick: Function,
  handleContinueClick: Function,
  selectedProperty: String,
  selectedUnit: String,
};

const Footer = ({
  showSelectProperty,
  showSelectUnit,
  handleBackClick,
  handleContinueClick,
  selectedProperty,
  selectedUnit,
}: FooterProps) => {
  return showSelectProperty || showSelectUnit ? (
    <>
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="md"
        leftIcon={<IconChevronLeft w={7.5} h={13.33} />}
        onClick={handleBackClick}
      >
        Back
      </BaselaneButton>
      <BaselaneButton
        variant="filled"
        palette="primary"
        size="md"
        rightIcon={<IconChevronRight w={7.5} h={13.33} />}
        onClick={handleContinueClick}
        isDisabled={
          (showSelectProperty && !showSelectUnit && !selectedProperty) ||
          (!showSelectProperty && showSelectUnit && !selectedUnit)
        }
      >
        Continue
      </BaselaneButton>
    </>
  ) : (
    <IconPoweredByRocketLawyer />
  );
};

export default Footer;
