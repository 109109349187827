import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Heading, VStack, Text, HStack, Flex, Box } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BaselaneDrawer,
  BaselaneButton,
  BaselaneList,
  BaselaneListItem,
  BaselaneDivider,
} from '@shared/components';
import { IconLightBulb, IconPlus } from '@icons';
import { ADD_BASELANE_BANK_ACCOUNT } from '@routes';
import customTheme from '@core/theme';
import getBreakPoints from '@core/utils/getBreakPoints';
import { bookkeepingSteps, whyCategorize } from './benefitsContent';

import {
  noMarginStyles,
  headingStyles,
  sectionHeadingStyles,
  tipHeadingStyles,
  tipTextStyles,
  tipContainerStyles,
  listStyles,
  orderedListStyles,
  listItemStyles,
  descriptionStyles,
  dividerStyles,
} from './styles/DrawerBenefitsOfCategorization.styles';

const DrawerBenefitsOfCategorization = ({
  drawerRef,
  drawerTitle,
  onCancelEvent,
}: {
  drawerRef: any,
  drawerTitle?: string,
  onCancelEvent?: Function,
}) => {
  const navigate = useNavigate();
  const currLocation = useLocation();
  const { isMinXL } = getBreakPoints();

  const listItemRenderer = (item) => {
    const key = uuidv4();

    let label;
    let itemStyle = listItemStyles.containerStyle;
    if (item?.label === 'step') {
      label = null;
      itemStyle = listItemStyles.orderedContainerStyle;
    } else {
      label = (
        <Flex {...{ mt: '4px', ml: '16px' }}>
          <item.label w="16" h="16" />
        </Flex>
      );
    }

    return (
      <BaselaneListItem
        itemAs="li"
        key={key}
        label={label}
        value={item.value}
        containerStyle={{ ...itemStyle }}
        labelStyle={{ ...listItemStyles.labelStyle }}
        valueStyle={{ ...listItemStyles.valueStyle }}
      />
    );
  };

  const onClose = () => {
    onCancelEvent();
    drawerRef?.current?.close();
  };

  return (
    <BaselaneDrawer
      ref={drawerRef}
      size="2lg"
      title={drawerTitle}
      closeButtonEvent={onCancelEvent}
      onOverlayClick={onCancelEvent}
      showCloseButton
      newDesignDrawer
      footer={
        <BaselaneButton size="lg" variant="filled" palette="primary" onClick={onClose}>
          Close
        </BaselaneButton>
      }
    >
      <Box alignItems="left" display="flex" flexDirection="column">
        <Text {...headingStyles(isMinXL)}>Why categorize your transactions?</Text>
        <Text {...descriptionStyles}>
          Categorizing transactions is an essential step to manage your rental property finances.
        </Text>
        <BaselaneList
          listAs="ul"
          data={whyCategorize}
          itemRenderer={listItemRenderer}
          listStyles={listStyles}
        />
        <Heading as="h2" {...sectionHeadingStyles}>
          Additional Tips
        </Heading>
        <Text {...headingStyles(isMinXL)}>
          How do I automatically import my transactions to Baselane?
        </Text>
        <Text {...descriptionStyles}>
          All transactions from Baselane Banking and External accounts are automatically imported to
          the Transactions page.
        </Text>
        <Box>
          <BaselaneButton
            palette="primary"
            variant="filled"
            leftIcon={<IconPlus w="12" h="12" />}
            size="sm"
            onClick={() =>
              navigate({
                pathname: `${currLocation.pathname}/${ADD_BASELANE_BANK_ACCOUNT}`,
              })
            }
          >
            Add account
          </BaselaneButton>
        </Box>
        <BaselaneDivider styles={dividerStyles} />
        <Text {...{ ...headingStyles(isMinXL) }}>
          How do I categorize a transaction or tag a property?
        </Text>
        <Text {...descriptionStyles}>
          Categorizing and tagging properties is easy and takes seconds.
        </Text>
        <BaselaneList
          listAs="ol"
          data={bookkeepingSteps}
          itemRenderer={listItemRenderer}
          listStyles={orderedListStyles}
        />
        <BaselaneDivider styles={dividerStyles} />
        <HStack {...tipContainerStyles}>
          <Flex {...{ pr: '12px', pl: '10px' }}>
            <IconLightBulb w="24" h="24" color={customTheme.colors.brand.neutral['600']} />
          </Flex>
          <VStack {...noMarginStyles}>
            <Heading as="h4" {...tipHeadingStyles}>
              Pro Tip: Automatically tag transactions to your properties
            </Heading>
            <Text {...tipTextStyles}>
              Click on the details of your Baselane Banking or External account to enable
              auto-tagging for that account’s transactions.
            </Text>
          </VStack>
        </HStack>
      </Box>
    </BaselaneDrawer>
  );
};

DrawerBenefitsOfCategorization.defaultProps = {
  drawerTitle: 'How to Guide: Managing Your Transactions',
  onCancelEvent: () => {},
};

export default DrawerBenefitsOfCategorization;
