import React from 'react';
import { IconSmallPaypalLogo, IconSmallVenmoLogo } from '@icons';

export const instructionGroups = {
  paypal: {
    instructionTitle: 'Instructions to add funds via Paypal',
    instructionPoints: [
      'Go to your Wallet and click “Link a bank”',
      'Select “Enter your bank details” from the search bar, and enter the Routing Number and Account Number for your Baselane Banking account',
      'After you click on the “Agree and Link” button, PayPal will send 2 small deposits to your Baselane Banking account in the next 3 business days',
      'Once you see the deposits, log into PayPal and enter the deposit amounts on the “Confirm your bank” screen from the Wallet tab',
      'Once your account is linked, click “transfer money” and select “Transfer to your bank”',
      'Select your Baselane Banking account and enter the transfer amount to initiate the transfer',
      'Payment will arrive in your Baselane Banking account within 1-5 business days',
    ],
    showInformationNeededSection: true,
    bankingAccountInfoList: [
      { label: 'Account Number', key: 'accountNumber' },
      { label: 'Bank Routing Number', key: 'routingNumber' },
    ],
    buttonIconLeft: <IconSmallPaypalLogo />,
    buttonIconRight: <IconSmallVenmoLogo />,
  },
  venmo: {
    instructionTitle: 'Instructions to add funds via Venmo',
    instructionPoints: [
      'Click on the “Transfer Balance” button on the top right',
      'If you do not have a Baselane account linked to Venmo, click “Add Bank” and enter your Baselane login credentials via “Instant Verification”',
      'Once linked, select your Baselane Banking account, enter the transfer amount, and click the “Transfer” button',
      'Payment will arrive in your Baselane Banking account within 1-3 business days',
    ],
    showInformationNeededSection: false,
    buttonIconLeft: <IconSmallPaypalLogo />,
    buttonIconRight: <IconSmallVenmoLogo />,
  },
  wire: {
    texts: [
      'Use the below account details to set up a wire transfer to your Baselane Banking account from another bank. Contact the bank you are transferring money from if you need help setting up the transfer.',
      'Please note that inbound wire transfers are processed at 6 PM ET daily.',
    ],
    showInformationNeededSection: true,
    bankingAccountInfoList: [
      { label: 'Legal Account Name', key: 'legalBusinessName' },
      { label: 'Bank Name', key: 'bankName' },
      { label: 'Account Number', key: 'accountNumber' },
      { label: 'Bank Routing Number', key: 'routingNumber' },
      { label: 'Account Address', key: 'legalAddress' },
      { label: 'Bank Address', key: 'bankAddress' },
    ],
    buttonIconLeft: null,
    buttonIconRight: null,
  },
  ach: {
    texts: [
      'Use the below account details to set up an ACH transfer to your Baselane Banking account from another bank. Contact the bank you are transferring money from if you need help setting up the transfer.',
    ],
    showInformationNeededSection: true,
    bankingAccountInfoList: [
      { label: 'Legal Account Name', key: 'legalBusinessName' },
      { label: 'Bank Name', key: 'bankName' },
      { label: 'Account Number', key: 'accountNumber' },
      { label: 'Bank Routing Number', key: 'routingNumber' },
      { label: 'Account Address', key: 'legalAddress' },
      { label: 'Bank Address', key: 'bankAddress' },
    ],
    buttonIconLeft: null,
    buttonIconRight: null,
  },
};
