import React, { useContext } from 'react';
import { Td, Stack } from '@chakra-ui/react';
import BaselaneResponsiveTableContext from '../BaselaneResponsiveTableContext';
import { defaultCellStyles, defaultContentContainerStyles } from '../styles/cell.styles';

type BaselaneResponsiveCellProps = {
  /**
   * Cell children
   */
  children: React.Node,
  /**
   * Styles that can be used to modify the cell content container styles
   */
  contentContainerStyles?: Object,
  /**
   * Index to be used to grab information specific in config
   */
  configIndex?: number,
  /**
   * Cell layout - display children vertically
   */
  isVertical?: String,
  /**
   * Function when cell is inside of clickable Row
   */
  stopEventPropagation?: boolean,
};

/**
 * Renders a generic cell that can be used within BaselaneResponsiveTableRow.
 * Styles of this generic cell can be overwritten through config or through ...rest in main cell or cell variations.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveCell = ({
  children,
  contentContainerStyles,
  configIndex,
  isVertical,
  stopEventPropagation,
  ...rest
}: BaselaneResponsiveCellProps) => {
  const { columns } = useContext(BaselaneResponsiveTableContext);

  let configStyle = {};
  let configContentContainerStyle = {};

  if (configIndex !== null) {
    configStyle = { ...columns[configIndex]?.styles };
    if (configStyle?.contentContainerStyles) {
      configContentContainerStyle = { ...configStyle.contentContainerStyles };
      delete configStyle.contentContainerStyles;
    }
  }

  return (
    <Td
      {...{
        ...defaultCellStyles,
        ...configStyle,
        ...rest,
      }}
    >
      <Stack
        direction={!isVertical ? 'row' : 'column'}
        onClick={(e) => {
          stopEventPropagation && e?.stopPropagation();
        }}
        {...{
          ...defaultContentContainerStyles,
          ...configContentContainerStyle,
          ...contentContainerStyles,
        }}
      >
        {children}
      </Stack>
    </Td>
  );
};

BaselaneResponsiveCell.defaultProps = {
  configIndex: null,
  contentContainerStyles: {},
  isVertical: false,
  stopEventPropagation: false,
};

export default BaselaneResponsiveCell;
