// BaseLane: Styles for BankBalances dashboard widget elements
export const bankBalancesWidget = (isMobile) => ({
  pt: !isMobile ? '36px' : '4px',
  pb: !isMobile ? 0 : '4px',
  borderBottomLeftRadius: !isMobile ? '0' : '8px',
  borderBottomRightRadius: !isMobile ? '0' : '8px',
});

export const currencyTextStyle = (isMobile) => ({
  isRounded: true,
  textStyle: isMobile ? 'headline-md' : 'headline-xl',
  negativeColor: 'red.500A',
  color: 'brand.neutral.700',
  marginTop: '0px',
});

export const elementStyles = (isMobile) => ({
  pl: !isMobile ? '24px' : '8px',
});

export const firstItemStyles = (isMobile) => ({
  pl: !isMobile ? '24px' : '16px',
});

export const customBorderStyles = (isMobile) => ({
  borderLeftWidth: !isMobile ? '1px' : '0px',
});

export const customFontStyles = (isMobile) => ({
  fontWeight: 'normal',
  color: !isMobile ? 'brand.neutral.700' : 'brand.neutral.600',
});
