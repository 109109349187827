import React, { useContext, useEffect } from 'react';
import { Text, Spacer, useDisclosure, useToast } from '@chakra-ui/react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BaselaneApplicationTypePopup, BaselaneButton, BaselaneGrid } from '@shared/components';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import BanksContext from '@contexts/BanksContext';
import getBreakPoints from '@core/utils/getBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import DisclaimerText from '@shared/components/DisclaimerText';
import historyStateStorage from '@core/storage/historyStateStorage';
import {
  GET_STARTED,
  HOME,
  NATIVE_BANK,
  UNIFIED_LB_PERSONAL_INFO,
  UNIFIED_RENT_COLLECTION,
} from '@routes';
import {
  darkDescriptionTextStyles,
  sectionTitleStyles,
  emptyStateCardStyles,
  drawerBodyStyles,
  containerStyle,
} from '../styles/emptyState.shared.styles';
import { disclaimerText } from '../BaselaneDisclaimer/const/disclaimerText';
import BaselaneDisclaimer from '../BaselaneDisclaimer';
import { desktopGridItems, mobileGridItems } from './helpers/grid.helpers';

type AddBaselaneAccountOldProps = {
  from: string,
};

function AddBaselaneAccountOld({ from }: AddBaselaneAccountOldProps): any {
  const { isEmptyState = false } = useOutletContext() ?? {};
  const navigate = useNavigate();
  const { isMin768 } = getBreakPoints();

  const { userHasDraftApplication, getKYCUrl } = useContext(BanksContext);

  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const { DrawerBody, DrawerFooter } = BaselaneDrawer;

  const gridItems = isMin768
    ? desktopGridItems({
        isMin768,
        userHasDraftApplication,
        onBaselaneApplicationTypePopupOpen,
      })
    : mobileGridItems({
        isMin768,
        userHasDraftApplication,
        onBaselaneApplicationTypePopupOpen,
      });

  useEffect(() => {
    if (isEmptyState) {
      onBaselaneApplicationTypePopupOpen();
    }
  }, [isEmptyState]);

  const toast = useToast();
  const showToast = () =>
    toast({
      description: 'Your Rent Collection setup progress has been saved',
      status: 'success',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const isGetStarted = from.includes('get_started');
  const isFromLease = from.includes('leases');

  const openLBFlow = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    let state = { from: isGetStarted ? GET_STARTED : NATIVE_BANK };

    // if user opened lb flow from another flow from state will be an object
    if (isFromLease) {
      const historyStateData = historyStateStorage.read('baselane-state');
      state = {
        from: { main: historyStateData?.from ?? HOME, flow: UNIFIED_RENT_COLLECTION },
      };
    }

    if (type === 'business') {
      getKYCUrl();
    } else {
      if (isFromLease) {
        showToast();
      }
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  return (
    <>
      <BaselaneDrawer
        title="Add Account"
        size="2lg"
        isMobileHeader={!isMin768}
        showBackButton={!isMin768}
        hideBackText
        closeEvent={() => onDrawerClose(navigate, from)}
        isOpen={!isEmptyState}
      >
        <DrawerBody {...drawerBodyStyles(isMin768)}>
          {isMin768 && (
            <Text {...sectionTitleStyles} mb="18px">
              Add Account
            </Text>
          )}
          <Text {...darkDescriptionTextStyles} mb={isMin768 ? '52px' : '32px'}>
            You can either add a virtual account for an existing banking account or open an
            additional banking account.
          </Text>
          <BaselaneGrid
            gridItems={gridItems}
            templateColumns={isMin768 ? '1fr 1fr' : '1fr'}
            templateRows=" repeat(1, 1fr)"
            gridStyles={{ ...emptyStateCardStyles, p: 0 }}
            gridItemStyles={{ h: '100%' }}
          />
          <Spacer />
          <BaselaneDisclaimer
            text={<DisclaimerText />}
            containerStyle={containerStyle(isMin768)}
            showPrivacyPolicy={disclaimerText.banksAndCards.showPrivacyPolicy}
          />
        </DrawerBody>
        <DrawerFooter>
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            onClick={() => onDrawerClose(navigate, from)}
          >
            Back
          </BaselaneButton>
        </DrawerFooter>
      </BaselaneDrawer>
      <BaselaneApplicationTypePopup
        handleActionButton={openLBFlow}
        isOpen={isBaselaneApplicationTypePopupOpen}
        onClose={() => {
          onBaselaneApplicationTypePopupClose();
          if (isEmptyState) {
            onDrawerClose(navigate, from);
          }
        }}
      />
    </>
  );
}

export default AddBaselaneAccountOld;
