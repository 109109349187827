export const baselanePromotionBannerStyles = {
  container: {
    my: '28px',
  },
  button: {
    alignSelf: 'flex-start',
    mt: '8px !important',
  },
  messagecard: {
    containerStyles: {
      marginBottom: '0px',
      w: '100%',
    },
    styles: {
      innercontainer: {
        p: '12px 16px 12px 20px',
      },
      title: {
        textStyle: 'md',
        mb: '8px',
      },
    },
  },
};
