import React from 'react';
import { Box, Stack, HStack, Text } from '@chakra-ui/react';
import { IconDottedCircularProgress } from '@icons';
import {
  stepContainerStyles,
  stepIconContainerStyles,
  stepTitleStyles,
  inactiveStepTextStyles,
} from '../../styles/steps.styles';

type InactiveProps = {
  step: Object,
};

const Inactive = ({ step }: InactiveProps) => {
  return (
    <HStack {...stepContainerStyles}>
      <Stack {...stepIconContainerStyles}>
        <IconDottedCircularProgress width={16} height={16} />
      </Stack>
      <Box {...stepTitleStyles}>
        <Text {...inactiveStepTextStyles}>{step?.title}</Text>
      </Box>
    </HStack>
  );
};

export default Inactive;
