function formatTitleCase(value) {
  if (!value) {
    throw new Error('value argument is null, undefined, or empty string!');
  }

  const formattedValue = value
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(' ');

  return formattedValue;
}

export default formatTitleCase;
