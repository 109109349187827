import { gql } from '@apollo/client';

const FIELDS_ADDRESS = gql`
  fragment FieldsAddress on PayeeAddress {
    street
    unit
    city
    state
    postalCode
    country
  }
`;

const FIELDS_ACH_PAYMENT = gql`
  fragment FieldsAchPayment on PayeeAchPaymentMethod {
    accountHolderName
    routingNumber
    accountNumber
    accountType
    bankName
  }
`;

const FIELDS_WIRE_PAYMENT = gql`
  ${FIELDS_ADDRESS}
  fragment FieldsWirePayment on PayeeWirePaymentMethod {
    accountHolderName
    routingNumber
    accountNumber
    bankName
    address {
      ...FieldsAddress
    }
  }
`;

const FIELDS_CHECK_PAYMENT = gql`
  ${FIELDS_ADDRESS}
  fragment FieldsCheckPayment on PayeeCheckPaymentsPaymentMethod {
    name
    address {
      ...FieldsAddress
    }
  }
`;

const FIELDS_ALL_PAYMENT_METHODS = gql`
  ${FIELDS_ACH_PAYMENT}
  ${FIELDS_WIRE_PAYMENT}
  ${FIELDS_CHECK_PAYMENT}
  fragment FieldsAllPaymentMethods on PayeePaymentMethod {
    achPaymentMethods {
      id
      ...FieldsAchPayment
      isDeletable
    }
    wirePaymentMethods {
      id
      ...FieldsWirePayment
      isDeletable
    }
    checkPaymentMethods {
      id
      ...FieldsCheckPayment
      isDeletable
    }
  }
`;

const FIELDS_PAYEE_BASIC = gql`
  fragment FieldsPayeeBasic on Payee {
    name
    type
    email
    isDeletable
    lastTransfer {
      id
      transferDate
      completedDate
      createdAt
      amount
      typeName
    }
  }
`;

export const GET_PAYEE = gql`
  ${FIELDS_ALL_PAYMENT_METHODS}
  ${FIELDS_PAYEE_BASIC}
  query Payee($id: ID) {
    payee(id: $id) {
      id
      ...FieldsPayeeBasic
      paymentMethods {
        ...FieldsAllPaymentMethods
      }
    }
  }
`;

export const GET_PAYEES = gql`
  ${FIELDS_PAYEE_BASIC}
  query Payees($input: PayeeSortInput) {
    payees(input: $input) {
      id
      ...FieldsPayeeBasic
      paymentMethods {
        achPaymentMethods {
          id
          type
        }
        wirePaymentMethods {
          id
          type
        }
        checkPaymentMethods {
          id
          type
        }
      }
    }
  }
`;

export const ADD_PAYEE = gql`
  ${FIELDS_ALL_PAYMENT_METHODS}
  ${FIELDS_PAYEE_BASIC}
  mutation AddPayee($input: CreatePayeeInput!) {
    addPayee(input: $input) {
      id
      ...FieldsPayeeBasic
      paymentMethods {
        ...FieldsAllPaymentMethods
      }
    }
  }
`;

export const UPDATE_PAYEE = gql`
  ${FIELDS_PAYEE_BASIC}
  mutation UpdatePayee($input: UpdatePayeeInput!) {
    updatePayee(input: $input) {
      id
      ...FieldsPayeeBasic
    }
  }
`;

export const ADD_PAYEE_PAYMENT_METHOD_ACH = gql`
  ${FIELDS_ACH_PAYMENT}
  mutation AddPayeePaymentMethod($payeeId: Float!, $input: CreatePayeePaymentMethodInput!) {
    addPayeePaymentMethod(payeeId: $payeeId, input: $input) {
      achPaymentMethods {
        id
        ...FieldsAchPayment
      }
    }
  }
`;

export const ADD_PAYEE_PAYMENT_METHOD_WIRE = gql`
  ${FIELDS_WIRE_PAYMENT}
  mutation AddPayeePaymentMethod($payeeId: Float!, $input: CreatePayeePaymentMethodInput!) {
    addPayeePaymentMethod(payeeId: $payeeId, input: $input) {
      wirePaymentMethods {
        id
        ...FieldsWirePayment
      }
    }
  }
`;

export const ADD_PAYEE_PAYMENT_METHOD_CHECK = gql`
  ${FIELDS_CHECK_PAYMENT}
  mutation AddPayeePaymentMethod($payeeId: Float!, $input: CreatePayeePaymentMethodInput!) {
    addPayeePaymentMethod(payeeId: $payeeId, input: $input) {
      checkPaymentMethods {
        id
        ...FieldsCheckPayment
      }
    }
  }
`;

export const UPDATE_PAYEE_PAYMENT_METHOD_ACH = gql`
  ${FIELDS_ACH_PAYMENT}
  mutation UpdatePayeePaymentMethod($input: UpdatePayeePaymentMethodInput!) {
    updatePayeePaymentMethod(input: $input) {
      achPaymentMethods {
        id
        ...FieldsAchPayment
      }
    }
  }
`;

export const UPDATE_PAYEE_PAYMENT_METHOD_WIRE = gql`
  ${FIELDS_WIRE_PAYMENT}
  mutation UpdatePayeePaymentMethod($input: UpdatePayeePaymentMethodInput!) {
    updatePayeePaymentMethod(input: $input) {
      wirePaymentMethods {
        id
        ...FieldsWirePayment
      }
    }
  }
`;

export const UPDATE_PAYEE_PAYMENT_METHOD_CHECK = gql`
  ${FIELDS_CHECK_PAYMENT}
  mutation UpdatePayeePaymentMethod($input: UpdatePayeePaymentMethodInput!) {
    updatePayeePaymentMethod(input: $input) {
      checkPaymentMethods {
        id
        ...FieldsCheckPayment
      }
    }
  }
`;

export const DELETE_PAYEE = gql`
  mutation DeletePayee($id: ID!) {
    deletePayee(id: $id) {
      id
    }
  }
`;

export const DELETE_PAYMENT_METHOD = gql`
  ${FIELDS_ACH_PAYMENT}
  ${FIELDS_WIRE_PAYMENT}
  ${FIELDS_CHECK_PAYMENT}
  mutation DeletePayeePaymentMethod($id: ID!) {
    deletePayeePaymentMethod(id: $id) {
      achPaymentMethods {
        id
        ...FieldsAchPayment
      }
      wirePaymentMethods {
        id
        ...FieldsWirePayment
      }
      checkPaymentMethods {
        id
        ...FieldsCheckPayment
      }
    }
  }
`;
