import { useState, useRef } from 'react';

const useSuccessPropertyDrawer = () => {
  const [newProperty, setNewProperty] = useState({});

  const successDrawerRef = useRef(null);

  return {
    newProperty,
    setNewProperty,
    successDrawerRef,
  };
};

export default useSuccessPropertyDrawer;
