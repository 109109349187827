import React from 'react';
import { Stack, Heading, Text } from '@chakra-ui/react';
import { BaselaneChipFilterMenu } from '@core/components/Shared/components';

const Header = ({ tags, handleFilterSelection }: any) => {
  return (
    <Stack pt={{ md: 5, base: 3 }} px={{ sm: 2.5, md: 4, base: 2 }} spacing={{ md: 3, base: 2 }}>
      <Stack spacing={1}>
        <Heading size="headline-2xl">
          Grow your rental business with the help of trusted Baselane partners
        </Heading>
        <Text textStyle="md">
          We&apos;ve hand selected partners that can help you save time, money and grow your
          portfolio.
        </Text>
      </Stack>
      <BaselaneChipFilterMenu
        id="partner-filter-menu"
        items={tags}
        onClick={handleFilterSelection}
      />
    </Stack>
  );
};

export default Header;
