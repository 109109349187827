// @flow
import React from 'react';
import { HStack, Box } from '@chakra-ui/react';
import icons from '../icons';
import {
  getBadgeIconStyles,
  getFontStyles,
  getBackgroundStyles,
  getIconContainerStyles,
} from './styles/badge.style';

type Props = {
  children: any,
  type: string,
  color: string,
  isCustom: boolean,
  isMobile: boolean,
  iconName: string,
  text: string,
  backgroundStyles: Object,
  iconClassName: string,
  className: string,
  fontStyles: Object,
  iconContainerStyles: Object,
  id: Object,
};

function BaselaneBadge({
  children = null,
  iconName = '',
  type = '',
  color = 'blue',
  isCustom = false,
  isMobile = false,
  text = '',
  fontStyles = {},
  backgroundStyles = {},
  iconClassName = '',
  className = '',
  iconContainerStyles = {},
  id = null,
}: Props): any {
  const badgeBackgroundStyles = {
    ...getBackgroundStyles(iconName, type, color, isMobile),
    ...backgroundStyles,
  };
  const badgeTextFontStyles = { ...getFontStyles(iconName, type, color, isMobile), ...fontStyles };

  const iconClass = iconClassName || 'icon-md';

  const badgeIconContainerStyles = {
    ...getIconContainerStyles(iconName, type, color, isMobile),
    ...iconContainerStyles,
  };

  const name = iconName.toLowerCase();
  const Icon = icons[name];

  if (isCustom && children !== null) {
    return (
      <HStack {...badgeBackgroundStyles} className={className}>
        {children}
      </HStack>
    );
  }

  return (
    <HStack id={id} {...badgeBackgroundStyles} className={className}>
      {iconName !== '' ? (
        <Box {...badgeIconContainerStyles} className={iconClass}>
          <Icon {...getBadgeIconStyles(iconClass)} />
        </Box>
      ) : null}
      <Box {...badgeTextFontStyles} id="badge-content">
        {text !== '' ? text : children}
      </Box>
    </HStack>
  );
}

export default BaselaneBadge;
