import stripCurrency from '@core/utils/stripCurrency';

export const formatDataForApi = (values) => {
  const {
    city,
    zipcode: postalCode,
    state,
    address: street,
    unit,
    propertyId,
    unitId,
    categoryId: tagId,
    transfer: { transferAmount: amount = '', selectedAccountFrom = {}, memo = '' },
    recipientName,
    recipientEmail,
  } = values;

  return {
    recipientAddress: {
      city,
      country: 'US',
      postalCode,
      state,
      street,
      unit,
    },
    tagId: tagId === '' ? null : tagId,
    propertyId: propertyId === '' ? null : propertyId,
    unitId: unitId === '' ? null : unitId,
    amount: stripCurrency(amount),
    fromTransferAccountId: selectedAccountFrom?.accountId,
    recipientName,
    recipientEmail,
    memo,
  };
};

export const propertyAndCategoryInitialValues = {
  propertyId: '',
  propertyUnitId: '',
  unitId: '',
  categoryId: '',
};

export const recipientDetailsInitialValues = {
  recipientName: '',
  recipientEmail: '',
  address: '',
  unit: '',
  city: '',
  state: '',
  zipcode: '',
};

export const bankTransferInitialValues = {
  recipientName: '',
  recipientEmail: '',
  address: '',
  unit: '',
  city: '',
  state: '',
  zipcode: '',
};
