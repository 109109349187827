export const accordionButtonStyles = {
  p: 0,
  justifyContent: 'space-between',
  _hover: { bgColor: 'transparent' },
  outline: 'none !important',
  _focus: { boxShadow: 'none' },
};

export const accordionToggleContainerStyles = (search) => {
  return {
    p: '8px 4px',
    align: 'center',
    borderRadius: '8px',
    _hover: { bgColor: 'brand.blue.50' },
    minHeight: '40px',
    pointerEvents: search ? 'none' : 'auto',
    cursor: search ? 'default' : 'pointer',
    sx: {
      ':hover': {
        bgColor: 'brand.blue.50',
        '#child-counter-visual': {
          bgColor: 'brand.blue.50',
        },
      },
    },
  };
};

export const accordionToggleCounterContainerStyles = {
  p: '4px 8px',
  borderRadius: '40px',
  spacing: '0',
  align: 'baseline',
};

export const accordionToggleTextStyles = {
  lineHeight: '14px',
  fontSize: '3xs',
};

export const accordionToggleIconContainerStyles = {
  w: '16px',
  h: '16px',
  align: 'center',
  justifyContent: 'center',
};

export const accordionPanelStyles = {
  py: 0,
  pl: '32px',
};

// multi select checkboxes styles
export const accordionSelectAllItemContainerStyles = {
  align: 'center',
  borderLeft: '1px solid #E5E9EF',
  pl: '5px',
};

export const accordionSelectAllTextStyles = {
  textStyle: 'headline-xs',
};

export const accordionChildCheckboxContainerStyles = {
  align: 'center',
  borderLeft: '1px solid #E5E9EF',
  pl: '5px',
};

// single select item styles
export const accordionItemContainerStyles = {
  p: '5px 8px',
  _hover: { bgColor: 'brand.blue.50' },
  width: '100%',
  textAlign: 'left',
  borderRadius: '8px',
  height: '100%',
};

export const accordionItemChildContainerStyles = {
  align: 'center',
  borderLeft: '1px solid #E5E9EF',
  pl: '5px',
  minHeight: '40px',
  width: '100%',
};

export const accordionItemChildStyles = {
  p: '5px 8px',
  _hover: { bgColor: 'brand.blue.50' },
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  textAlign: 'left',
};
