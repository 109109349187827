// @flow
import React, { useContext, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';
import { GET_TENANT_PROFILE } from '@pages/LeasesPage/queries';
import { AlertHeader, BaselaneAlert, BaselaneDrawer } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import InvoiceContext from '@contexts/InvoiceContext';
import { INVOICE_STATUS_TYPES } from '../../../constants';
import { paymentTypeInvoiceStyles, statusLabelStyles } from '../../../styles/invoice.styles';
import type { Invoices as InvoicesType } from '../../../types';
import DrawerBody from './Body';
import { unsavedDetailsAlertFooter, initialValues } from '../../../helpers/invoiceDetails.helper';

type InvoiceDetailsProps = {
  invoiceDetails: InvoicesType,
  invoiceDetailsDrawerRef: any,
  handleInvoiceDetailsDrawerClose: Function,
  setHasUpdatedInvoice: Function,
  isPropertyDeleted: boolean,
  isMultiUnit: boolean,
  lease: Object,
  nextPaymentsClicked: boolean,
  setInvoiceList: Function,
  refreshInvoiceDetails: Function,
};

function InvoiceDetails({
  invoiceDetails: initialInvoiceDetails,
  invoiceDetailsDrawerRef,
  handleInvoiceDetailsDrawerClose,
  setHasUpdatedInvoice,
  isPropertyDeleted,
  isMultiUnit,
  lease,
  nextPaymentsClicked,
  setInvoiceList,
  refreshInvoiceDetails: leaseRefreshInvoiceDetails,
}: InvoiceDetailsProps): any {
  const { isMax767 } = getBreakPoints();

  const { setInvoiceTotal, invoiceList } = useContext(InvoiceContext);

  const [isDirty, setIsDirty] = useState(false);
  const [editState, setEditState] = useState(false);
  const [isUnsavedChangesAlertOpen, setIsUnsavedChangesAlertOpen] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(initialInvoiceDetails);
  const onUnsavedChangesAlertOpen = () => setIsUnsavedChangesAlertOpen(true);
  const onUnsavedChangesAlertClose = () => setIsUnsavedChangesAlertOpen(false);

  const btnRef = useRef();
  const cancelUnsavedChangesAlertRef = useRef();
  const formikRef = useRef();

  const { loading: tenantLoading, data: tenantData, error: tenantError } = useQuery(
    GET_TENANT_PROFILE,
    {
      fetchPolicy: 'cache-and-network',
      skip: lease?.tenantProfileMetadata?.firstName,
    }
  );
  if (tenantLoading || tenantError) return null;

  const { landlordTenant } = tenantData ?? {};
  const leaseTenant = lease?.tenantProfileMetadata?.firstName
    ? lease?.tenantProfileMetadata
    : landlordTenant.find(
        (tenant) =>
          tenant.id === invoiceDetails?.leaseTenantProfileId || tenant.id === lease?.tenantProfileId
      );

  const cleanUp = () => {
    setEditState(false);
    formikRef.current?.resetForm(initialValues(invoiceDetails));
  };

  const handleCancelCloseButton = (e) => {
    if (editState && isDirty) {
      cleanUp();
      if (isUnsavedChangesAlertOpen) onUnsavedChangesAlertClose();
      handleInvoiceDetailsDrawerClose(e);
    } else {
      handleInvoiceDetailsDrawerClose(e);
      onUnsavedChangesAlertClose();
    }
  };

  const handleCloseDrawer = () => {
    if (isDirty && editState) {
      onUnsavedChangesAlertOpen();
    } else {
      cleanUp();
      handleInvoiceDetailsDrawerClose();
    }
  };

  const handleSetInvoiceDetails = (invoice) => {
    if (invoice) {
      const { id, state, dueDate, originalDueDate, description, amount } = invoice;

      const isDeleted = state === 'DELETED';
      const isCompleted = state === 'COMPLETED';
      const isProcessing = state === 'PROCESSING';
      const isScheduled = state === 'SCHEDULED';

      const paymentDate = invoice?.invoiceCharge?.paymentDate;
      const paymentTriggerType = invoice?.invoiceCharge?.paymentTriggerType;

      const pDate = moment(paymentDate).startOf('day').toISOString();
      const dDate = moment(dueDate).startOf('day').toISOString();

      const latePayment = (isProcessing || isCompleted) && pDate > dDate;
      const showAutoPayLabel = isScheduled && paymentTriggerType === 'autoPay';

      const { icon, label, color } = INVOICE_STATUS_TYPES[state] ?? {};

      const paymentType = (
        <>
          {description}
          <Box {...paymentTypeInvoiceStyles(isDeleted)}>{`#${id}`}</Box>
        </>
      );

      setInvoiceDetails({
        ...invoice,
        amount: formatCurrency(amount).inDollars,
        dueDate: moment(dueDate, moment.defaultFormat).toDate(),
        formattedDueDate: formatDate(dueDate),
        originalDueDate: moment(originalDueDate, moment.defaultFormat).toDate(),
        formattedOriginalDueDate: formatDate(originalDueDate),
        paymentType,
        paymentDate: formatDate(paymentDate, 'MMM D, YYYY'),
        state: showAutoPayLabel ? 'AUTOPAY' : state,
        stateElement: (
          <HStack alignItems="center">
            <Box>{icon}</Box>
            <Stack m="0 0 0 6px">
              <Text {...statusLabelStyles(color, isDeleted)} fontWeight="medium">
                {!showAutoPayLabel ? label : 'Auto-Pay'}
              </Text>
            </Stack>
          </HStack>
        ),
        latePayment,
        isPropertyDeleted,
        isMultiUnit,
      });
    }
  };

  const refreshInvoiceDetails = (inv) => {
    const { id: invoiceId } = inv; // invoice
    let total = 0;
    let newInvoiceList = invoiceList.map((item) => {
      if (item.id === invoiceId) {
        total += inv.amount;
        return inv;
      }
      total += item.amount;
      return item;
    });
    if (nextPaymentsClicked) {
      const nextPaymentIdArray = [];
      lease.invoiceDetails?.nextPayment.forEach((item) => nextPaymentIdArray.push(item.id));
      newInvoiceList = newInvoiceList.filter((item) => nextPaymentIdArray.indexOf(item.id) > -1);
    }
    setInvoiceList(newInvoiceList);
    setInvoiceTotal({ amount: total, count: invoiceList?.length });
    handleSetInvoiceDetails(inv);
  };

  return (
    <>
      <BaselaneDrawer
        finalFocusRef={btnRef}
        size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
        contentStyles={isMax767 && { top: 'auto !important' }}
        title="Invoice Details"
        hideBackText
        ref={invoiceDetailsDrawerRef}
        onClose={handleCloseDrawer}
        closeEvent={handleCloseDrawer}
        hideOverlay
        newDesignDrawer
      >
        <DrawerBody
          bankingAccount={lease?.rentAndFeesBankAccount}
          invoiceDetails={invoiceDetails || initialInvoiceDetails}
          setIsDirty={setIsDirty}
          editState={editState}
          setEditState={setEditState}
          formikRef={formikRef}
          onClose={handleInvoiceDetailsDrawerClose}
          leaseTenant={leaseTenant}
          setHasUpdatedInvoice={setHasUpdatedInvoice}
          isPropertyDeleted={isPropertyDeleted}
          isMultiUnit={isMultiUnit}
          refreshInvoiceDetails={leaseRefreshInvoiceDetails || refreshInvoiceDetails}
          handleSetInvoiceDetails={handleSetInvoiceDetails}
          isQuickPay={lease?.isQuickPay}
        />
      </BaselaneDrawer>

      <BaselaneAlert
        isOpen={isUnsavedChangesAlertOpen}
        leastDestructiveRef={cancelUnsavedChangesAlertRef}
        onClose={onUnsavedChangesAlertClose}
        isCentered
        header={<AlertHeader title="You Have Unsaved Changes" />}
        body="Are you sure you want to exit without saving?"
        footer={unsavedDetailsAlertFooter(
          cancelUnsavedChangesAlertRef,
          onUnsavedChangesAlertClose,
          handleCancelCloseButton
        )}
      />
    </>
  );
}

export default InvoiceDetails;
