import React from 'react';
import { getEnableTransactionInfoMessage } from '@shared/helpers/enableTrxMessage.helper';
import { NickName, ImportTrxs, AutoTag } from './FormControls';

type EditAccountFormProps = {
  values: Object,
  handleFormUpdate: Function,
  handleBlur: Function,
  handleChange: Function,
  importTransactionLabelRef: any,
  setFieldValue: Function,
  setIsImportingAlertOpen: Function,
  setIsCheckedDuplicateTransactions?: Function,
  setIsImportDisabledAlertOpen?: Function,
  dirty: boolean,
  isPlaidAccount?: boolean,
  errors?: Object,
  isInitialImportTransactionTypeNull?: boolean,
  setIsDeleteImportedTransactionsAlertOpen?: Function,
};

const EditAccountForm = ({
  values,
  handleFormUpdate,
  handleBlur,
  handleChange,
  importTransactionLabelRef,
  setFieldValue,
  setIsImportingAlertOpen,
  setIsCheckedDuplicateTransactions,
  setIsImportDisabledAlertOpen,
  dirty,
  isPlaidAccount,
  errors,
  isInitialImportTransactionTypeNull,
  setIsDeleteImportedTransactionsAlertOpen,
}: EditAccountFormProps) => {
  const enableTransactionInfoMessage = getEnableTransactionInfoMessage(isPlaidAccount);

  return (
    <>
      <NickName
        label="External Account Nickname"
        {...{
          values,
          errors,
          handleFormUpdate,
          handleBlur,
          handleChange,
          dirty,
        }}
      />
      {isPlaidAccount && (
        <>
          <ImportTrxs
            {...{
              values,
              errors,
              handleFormUpdate,
              handleBlur,
              handleChange,
              setFieldValue,
              setIsImportingAlertOpen,
              setIsCheckedDuplicateTransactions,
              setIsImportDisabledAlertOpen,
              importTransactionLabelRef,
              dirty,
              isInitialImportTransactionTypeNull,
              setIsDeleteImportedTransactionsAlertOpen,
            }}
          />
          <AutoTag
            {...{
              values,
              handleFormUpdate,
              handleBlur,
              handleChange,
              setFieldValue,
              enableTransactionInfoMessage,
              dirty,
            }}
          />
        </>
      )}
    </>
  );
};

EditAccountForm.defaultProps = {
  isPlaidAccount: false,
  setIsCheckedDuplicateTransactions: null,
  errors: {},
  isInitialImportTransactionTypeNull: false,
  setIsImportDisabledAlertOpen: () => {},
  setIsDeleteImportedTransactionsAlertOpen: () => {},
};

export default EditAccountForm;
