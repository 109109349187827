export const mobileNavHolderStyles = {
  display: 'flex',
  width: '100%',
  background: 'brand.neutral.white',
  height: '64px',
  maxHeight: '64px',
  px: 2,
  py: 1.5,
  justify: 'flex-start',
  position: 'fixed',
  zIndex: '1001',
  top: '0',
  left: '0',
  borderBottomWidth: '1px',
  borderBottomColor: 'brand.neutral.200',
};

export const mobileDrawerStyles = {
  background: 'brand.neutral.50',
  position: 'relative',
  zIndex: '1002',
  boxShadow: '0px 8px 24px 0px rgba(77, 77, 90, 0.12)',
};

export const mobileNavHeaderStyles = {
  color: 'brand.neutral.white',
  p: 0,
};
