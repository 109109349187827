import React from 'react';

function IconGlobeCheck({ w, h }: { w?: Number, h?: Number }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 43 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 1c4.1271 4.51828 6.4725 10.3819 6.6 16.5-.0349 1.6766-.2364 3.3341-.5955 4.95H1.825M17.5 1c-4.1271 4.51828-6.4725 10.3819-6.6 16.5.1275 6.1181 2.4729 11.9817 6.6 16.5-1.3276 0-2.6186-.1568-3.8555-.4529C6.39159 31.8109 1 25.2851 1 17.5 1 8.3873 8.3873 1 17.5 1Zm0 0C26.6127 1 34 8.3873 34 17.5c0 .9278-.0766 1.8378-.2238 2.7239M1.82503 12.55H33.175"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 44c5.5228 0 10-4.4772 10-10s-4.4772-10-10-10-10 4.4772-10 10 4.4772 10 10 10Z"
        fill="#6ADA9B"
      />
      <path
        d="m26.5 33 3 3 6-6m5.5 3c0 5.5228-4.4772 10-10 10s-10-4.4772-10-10 4.4772-10 10-10 10 4.4772 10 10Z"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconGlobeCheck.defaultProps = {
  w: 43,
  h: 44,
};

export default IconGlobeCheck;
