import React from 'react';
import { Stack } from '@chakra-ui/react';
import BaselaneDivider from '../BaselaneDivider';
import { baselaneSummaryCardStyles } from './styles/baselaneSummaryCard.styles';

type BaselaneSummaryCardListGroupProps = {
  id?: String,
  isDividerHidden: Boolean,
  children: React.Node,
};

const BaselaneSummaryCardListGroup = ({
  id,
  isDividerHidden,
  children,
}: BaselaneSummaryCardListGroupProps) => {
  const isAllItemsHidden =
    !children || (Array.isArray(children) && children?.every((item) => item?.props?.isHidden));
  const { dividerStyles } = baselaneSummaryCardStyles;

  return (
    <Stack w="100%" gap={1} display={isAllItemsHidden ? 'none' : 'flex'}>
      {children}
      <BaselaneDivider styles={{ ...dividerStyles, display: isDividerHidden && 'none' }} />
    </Stack>
  );
};

BaselaneSummaryCardListGroup.defaultProps = {
  id: null,
};

export default BaselaneSummaryCardListGroup;
