const cashFlowDummyData = {
  cashFlow: {
    byCategory: [
      { name: 'Revenue', amount: 12600, subCategories: [] },
      { name: 'Operating Expenses', amount: -9340, subCategories: [] },
      { name: 'Loan Payments & Capex', amount: -2370, subCategories: [] },
      { name: 'Property Transactions', amount: 7200, subCategories: [] },
      { name: 'Transfers & Other', amount: -5234, subCategories: [] },
      { name: 'Uncategorized', amount: 7200, subCategories: [] },
    ],
    byCategoryMonth: [
      { date: '2021-11-01', categories: [] },
      { date: '2021-12-01', categories: [] },
      { date: '2022-01-01', categories: [] },
      { date: '2022-02-01', categories: [] },
      { date: '2022-03-01', categories: [] },
      { date: '2022-04-01', categories: [] },
      { date: '2022-05-01', categories: [] },
      { date: '2022-06-01', categories: [] },
      { date: '2022-07-01', categories: [] },
    ],
    byMonth: [
      { date: '2021-11-01', income: 65000, expense: -26000 },
      { date: '2021-12-01', income: 78000, expense: -81000 },
      { date: '2022-01-01', income: 80000, expense: -76000 },
      { date: '2022-02-01', income: 84000, expense: -72000 },
      { date: '2022-03-01', income: 89000, expense: -67000 },
      { date: '2022-04-01', income: 91000, expense: -68000 },
      { date: '2022-05-01', income: 93000, expense: -62000 },
      { date: '2022-06-01', income: 96000, expense: -61000 },
      { date: '2022-07-01', income: 99000, expense: -60000 },
    ],
  },
};

const cashFlowSummaryDummyData = {
  cashFlow: {
    summary: {
      netCashFlow: 180027,
      totalExpenseAmount: 40520,
      totalRevenueAmount: 220547,
    },
  },
};

const transactionsDummyData = {
  transaction: [
    {
      amount: -1280.2,
      date: '2022-07-06',
      id: '1',
      isSplit: false,
      pending: false,
      merchantName: 'Maids of Manhattan',
      note: 'Banner Ads',
      bankAccountDummyName: 'Amex- Main Card',
      categoryDummyName: 'Advertising',
      propertyDummyName: 'San Bernardino Duplex',
    },
    {
      amount: 2600,
      date: '2022-07-06',
      id: '2',
      isSplit: false,
      pending: false,
      merchantName: 'Maids of Manhattan',
      note: 'Banner Ads',
      bankAccountDummyName: 'Amex- Main Card',
      categoryDummyName: 'Advertising',
      propertyDummyName: 'San Bernardino Duplex',
    },
    {
      amount: -2013.5,
      date: '2022-07-03',
      id: '3',
      isSplit: false,
      pending: false,
      merchantName: 'Maids of Manhattan',
      note: 'Banner Ads',
      bankAccountDummyName: 'Amex- Main Card',
      categoryDummyName: 'Advertising',
      propertyDummyName: 'San Bernardino Duplex',
    },
    {
      amount: 40,
      date: '2022-06-30',
      id: '4',
      isSplit: false,
      pending: false,
      merchantName: 'Maids of Manhattan',
      note: 'Banner Ads',
      bankAccountDummyName: 'Amex- Main Card',
      categoryDummyName: 'Advertising',
      propertyDummyName: 'San Bernardino Duplex',
    },
    {
      amount: 99.15,
      date: '2022-06-23',
      id: '5',
      isSplit: false,
      pending: false,
      merchantName: 'Maids of Manhattan',
      note: 'Banner Ads',
      bankAccountDummyName: 'Amex- Main Card',
      categoryDummyName: 'Advertising',
      propertyDummyName: 'San Bernardino Duplex',
    },
    {
      amount: -200,
      date: '2022-06-19',
      id: '6',
      isSplit: false,
      pending: false,
      merchantName: 'Maids of Manhattan',
      note: 'Banner Ads',
      bankAccountDummyName: 'Amex- Main Card',
      categoryDummyName: 'Advertising',
      propertyDummyName: 'San Bernardino Duplex',
    },
  ],
};

export { cashFlowDummyData, cashFlowSummaryDummyData, transactionsDummyData };
