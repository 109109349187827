/**
 * Finds and returns the account by id. If the account is a subAccount, it will return the parent account in the property `parentAccount`.
 * @param {String} bankAccountId - The id of the account to find.
 * @param {Object} allAccountsMap - The map of all accounts by id.
 * @returns {Object} - The found account. If the account is a subAccount, it will return the parent account as well.
 */
export const findAccount = (bankAccountId, allAccountsMap) => {
  let account = allAccountsMap()[bankAccountId];

  const checkedBankAccountId =
    typeof bankAccountId === 'number' ? bankAccountId?.toString() : bankAccountId;

  if (!account) {
    Object.values(allAccountsMap()).every((accountMapItem) => {
      const foundSubAccount = accountMapItem?.subAccounts?.find(
        (subAccount) => subAccount.id === checkedBankAccountId
      );

      if (foundSubAccount) {
        account = { ...foundSubAccount, parentAccount: accountMapItem };
        return false;
      }

      return true;
    });
  }

  return account;
};
