import { sortWithString } from '@core/utils/sort';

const getEnrichedForSortLeases = ({ data }) => {
  return (
    data
      ?.filter((lease) => lease?.propertyName)
      ?.map((lease) => {
        return {
          ...lease,
          sortProp: lease?.propertyName?.match(/^\d/)
            ? Number(lease?.propertyName?.split(' ')[0].replace(/\D/g, ''))
            : lease?.propertyName,
        };
      }) || []
  );
};

export const getSortedLeaseData = ({ data }) => {
  const enrichedForSortLeases = getEnrichedForSortLeases({ data });

  return enrichedForSortLeases?.length > 0
    ? sortWithString(enrichedForSortLeases, 'propertyName').sort((a, b) => a.sortProp - b.sortProp)
    : [];
};
