import spacing from '@core/theme/spacing';
import { colorPreset } from './buttonColorPresets';

export const buttonPaddings = (y, x) => `${spacing.space[y]} ${spacing.space[x]}`;
export const transparentButtonPaddings = (size) => {
  const sizeMap = {
    xs: { x: 0, y: 0 },
    sm: { x: '0.5', y: '1' },
    md: { x: '0.75', y: '1' },
    lg: { x: '1', y: '1.5' },
  };

  return buttonPaddings(sizeMap[size]?.y, sizeMap[size]?.x);
};

export const getNegativeMargin = (size, position) => {
  const sizeMap = {
    sm: '0.5',
    md: '0.75',
    lg: '1',
  };

  return position === 'left'
    ? { marginRight: '0', marginLeft: `-${spacing.space[sizeMap[size]]}` }
    : { marginLeft: '0', marginRight: `-${spacing.space[sizeMap[size]]}` };
};

export const getIconSpacingValue = (size) => {
  const sizeMap = {
    sm: '0.75',
    md: '1',
    lg: '1.5',
  };
  return { iconSpacing: spacing.space[sizeMap[size] || '1'] };
};

export const buttonThemeGenerator = (variant, style) => {
  const themeMap = {
    filled: {
      danger: colorPreset.danger,
      primary: colorPreset.primary,
    },
    tonal: {
      danger: colorPreset.dangerTonal,
      primary: colorPreset.primaryTonal,
    },
    outline: {
      danger: colorPreset.dangerOutline,
      neutral: colorPreset.neutralOutline,
    },
    transparent: {
      danger: colorPreset.dangerTransparent,
      neutral: colorPreset.neutralTransparent,
      primary: colorPreset.primaryTransparent,
      success: colorPreset.successTransparent,
      warning: colorPreset.warningTransparent,
    },
    link: {
      danger: colorPreset.dangerLink,
      neutral: colorPreset.neutralLink,
      primary: colorPreset.primaryLink,
      success: colorPreset.successLink,
      warning: colorPreset.warningLink,
      reverse: colorPreset.reverseLink,
    },
    toggle: {
      // This variant is only used inside BaselaneButtonToggle
      neutral: colorPreset.neutralToggle,
      primary: colorPreset.primaryToggle,
    },
    reverse: {
      neutral: colorPreset.reverse,
    },
  };

  return themeMap[variant]?.[style] || colorPreset.neutralTransparent;
};
