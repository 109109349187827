// @flow
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';
import * as ROUTES from '@routes';
import { Icon16Info } from '@icons/16px';
import getBreakPoints from '@core/utils/getBreakPoints';
import BaselaneAlertNew from '../BaselaneAlert-new';
import BaselaneDrawer from '../BaselaneDrawer';
import BaselaneDivider from '../BaselaneDivider';
import { getLearnMoreDrawerStyles } from './styles/learnMoreDrawer.styles';
import quickpayImageOne from './assets/quickpayImageOne.svg';
import quickpayImageTwo from './assets/quickpayImageTwo.svg';
import quickpayImageFooterOne from './assets/quickpayImageFooterOne.svg';
import quickpayImageFooterTwo from './assets/quickpayImageFooterTwo.svg';

const QuickPayLearnMoreDrawer = () => {
  const { isMax767 } = getBreakPoints();
  const navigate = useNavigate() || {};
  const location = useLocation() || {};
  const { state } = location || {};

  const { from, originalState } = state || {};
  const { DrawerBody } = BaselaneDrawer;

  const handleCloseLearnMoreDrawer = () => {
    const { invoiceId, leaseId } = originalState || {};
    let pathname = `${from || ROUTES.LEASES}?leaseId=`;
    if (invoiceId && leaseId) {
      pathname = `${from || ROUTES.LEASES}?leaseId=${leaseId}&invoiceId=${invoiceId}`;
    } else if (leaseId) {
      pathname = `${from || ROUTES.LEASES}?leaseId=${leaseId}`;
    } else if (invoiceId) {
      pathname = `${from || ROUTES.LEASES}?invoiceId=${invoiceId}`;
    }

    navigate(pathname, {
      state: originalState,
    });
  };

  // Destructure Imported Styles
  const {
    drawer,
    content: { titlesection, bodysection },
  } = getLearnMoreDrawerStyles();

  return (
    <BaselaneDrawer
      title="QuickPay"
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      contentStyles={isMax767 && { top: 'auto !important' }}
      closeEvent={handleCloseLearnMoreDrawer}
      isOpen
      newDesignDrawer
    >
      <DrawerBody {...drawer}>
        <>
          {/* Content Title/Description */}
          <Stack {...titlesection.container}>
            <Text {...titlesection.title}>What is QuickPay?</Text>
            <Text {...titlesection.description}>
              QuickPay is a Beta feature that Baselane is testing so that you can receive rent
              within 2 business days (rather than standard payments that arrive 4-5 business days
              later).
            </Text>
          </Stack>

          {/* Example Images */}
          <Stack gap="16px">
            <Image
              src={quickpayImageOne}
              alt="Baselane, the solution for Landlords AND Tenants."
              w="100%"
              borderRadius="8px"
            />
            <Image
              src={quickpayImageTwo}
              alt="Baselane, the solution for Landlords AND Tenants."
              w="100%"
              borderRadius="8px"
            />
          </Stack>

          <BaselaneDivider />

          {/* Content Title/Description */}
          <Stack {...titlesection.container}>
            <Text {...titlesection.title}>How do I get QuickPay?</Text>
            <Text {...titlesection.description}>
              While in the free Beta phase, QuickPay is enabled for all eligible tenant payments.
            </Text>
          </Stack>

          <BaselaneDivider />

          {/* Content Title/Description */}
          <Stack {...titlesection.container} gap="16px">
            <Text {...titlesection.title}>Which tenant payments are processed with QuickPay?</Text>
            <Box>
              <BaselaneAlertNew
                body="As this feature is in Beta, we might adjust this logic in the future to enable QuickPay for more of your tenant payments."
                variant="neutral"
                visual="icon"
                iconName={Icon16Info}
              />
            </Box>
            <Box>
              <Text {...bodysection.bodytitle}>Invoices</Text>
              <Text {...bodysection.bodytext}>
                Baselane uses a number of factors to determine if a tenant&apos;s payment is
                eligible for QuickPay, including their payment method, balance at time of payment
                (if available), and payment history (must have at least one successful payment).
              </Text>
              <Text {...bodysection.bodytext}>
                If an invoice is processed with QuickPay, you will see a green lightning bolt icon
                next to the invoice.
              </Text>
            </Box>
            <Box>
              <Text {...bodysection.bodytitle}>Tenants</Text>
              <Text {...bodysection.bodytext}>
                If a tenant is eligible for QuickPay, you will see a grey lightning bolt next to
                their name. Note that an eligible tenant may have an invoice that is not processed
                on QuickPay, depending on the payment method used.
              </Text>
            </Box>

            {/* Example Images */}
            <HStack>
              <Image
                src={quickpayImageFooterTwo}
                alt="Baselane, the solution for Landlords AND Tenants."
                w="49%"
                borderRadius="8px"
              />
              <Image
                src={quickpayImageFooterOne}
                alt="Baselane, the solution for Landlords AND Tenants."
                w="49%"
                borderRadius="8px"
              />
            </HStack>
          </Stack>
        </>
      </DrawerBody>
    </BaselaneDrawer>
  );
};

export default QuickPayLearnMoreDrawer;
