// @flow
import React, { useRef } from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { Stack, useDisclosure, useToast } from '@chakra-ui/react';
import { BaselaneButton, BaselaneRadioGroup, BaselaneRadio } from '@shared/components';
import { UPDATE_INVOICE_PROCESSING_FEE_PAID_BY } from '@core/components/UserProfilePage/queries';
import WhoPaysACHPopup from './WhoPaysACHPopup';
import { successToastConfig, errorToastConfig } from '../../helpers/whoPaysACH.helpers';
import { whoPaysACHStyles } from '../../styles/whoPaysACH.styles';

type WhoPaysACHProps = {
  userMetadata: Object,
  hasLeaseCollectingRentOutsideBaselane: boolean,
};

const { container, radiogroup, savebtn } = whoPaysACHStyles;

function WhoPaysACH({ userMetadata, hasLeaseCollectingRentOutsideBaselane }: WhoPaysACHProps): any {
  const formikRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateAccountWideProcessingFeePaidBy, { loading: isUpdateLoading }] = useMutation(
    UPDATE_INVOICE_PROCESSING_FEE_PAID_BY
  );

  const toast = useToast();
  const showSuccessToast = () => toast(successToastConfig);
  const showErrorToast = () => toast(errorToastConfig);

  const handleFormSubmit = () => {
    onOpen();
  };

  const handleConfirmSaveClick = () => {
    updateAccountWideProcessingFeePaidBy({
      variables: {
        invoiceProcessingFeePaidBy: formikRef.current?.values?.isInvoiceFeePaidByTenant
          ? 'TENANT'
          : 'LANDLORD',
      },
      update: (
        cache,
        { data: { updateInvoiceProcessingFeesPaidBy: updatedInvoiceProcessingFeesPaidBy } }
      ) => {
        onClose();
        const { userId, invoiceProcessingFeePaidBy } = updatedInvoiceProcessingFeesPaidBy ?? {};
        cache.modify({
          id: cache.identify(userId),
          fields: {
            userMetadata: () => {
              return {
                ...userMetadata,
                invoiceProcessingFeePaidBy,
              };
            },
          },
        });
      },
    })
      .then(() => {
        showSuccessToast();
      })
      .catch((err) => {
        onClose();
        showErrorToast();
        console.debug(err);
      });
  };

  const initialFormValues = {
    isInvoiceFeePaidByTenant: userMetadata?.invoiceProcessingFeePaidBy === 'TENANT',
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialFormValues}
        onSubmit={handleFormSubmit}
        validateOnChange
        enableReinitialize
      >
        {({ values, dirty, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          return (
            <>
              <Stack {...container}>
                <BaselaneRadioGroup
                  name="isInvoiceFeePaidByTenant"
                  className="primary-500 custom-radio"
                  onChange={(e) => {
                    const updatedVal = e === '0';
                    setFieldValue('isInvoiceFeePaidByTenant', updatedVal);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  defaultValue="0"
                  title="Who pays the ACH fee (if rent is collected into an external account)?"
                  value={values.isInvoiceFeePaidByTenant ? '0' : '1'}
                  {...radiogroup}
                >
                  <>
                    <BaselaneRadio
                      value="0"
                      label="Tenant pays"
                      description="$2 will be added to your tenant's invoice"
                    />
                    <BaselaneRadio
                      value="1"
                      label="I will cover the fee"
                      description="$2 will be deducted from my payouts"
                    />
                  </>
                </BaselaneRadioGroup>
              </Stack>

              <BaselaneButton
                variant="filled"
                palette="primary"
                size="lg"
                styles={savebtn}
                onClick={handleSubmit}
                isDisabled={!dirty}
              >
                Save changes
              </BaselaneButton>
            </>
          );
        }}
      </Formik>

      <WhoPaysACHPopup
        {...{
          isOpen,
          onClose,
          handleConfirmSaveClick,
          isLoading: isUpdateLoading,
          hasLeaseCollectingRentOutsideBaselane,
        }}
      />
    </>
  );
}

export default WhoPaysACH;
