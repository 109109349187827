import { isMobile } from 'react-device-detect';
import { truncatedText } from '@shared/styles/text.style';

export const feeBoxTitleStyles = {
  textStyle: 'md',
  color: 'brand.neutral.600',
  verticalAlign: 'baseline',
};

export const feeBoxValueStyles = {
  textStyle: 'headline-xs',
  color: 'brand.neutral.700',
  ml: '8px',
  textTransform: 'uppercase',
  ...truncatedText,
};

export const feeBoxHeaderStyles = (unifiedRC) => ({
  alignItems: 'center',
  h: '32px',
  p: isMobile && unifiedRC ? '32px 0' : '0',
});

export const feeBoxContainerStyles = {
  mt: '16px !important',
  _first: {
    mt: '0 !important',
  },
};

export const feeBoxStyles = {
  background: 'brand.blue.100',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'brand.blue.250',
  borderRadius: '4px',
  p: '16px',
};

export const tooltipContainerStyles = {
  display: 'flex',
  alignItems: 'center',
};
