// @flow
import React from 'react';
import { Stack } from '@chakra-ui/react';
import { BaselaneButtonIcon } from '../BaselaneButtonIcon/index';
import { wrapperStyles } from './styles/toggle.styles';
import BaselaneButtonGroup from '../BaselaneButtonGroup';

type ButtonIconToggleProps = {
  firstId: string,
  secondId: string,
  activeButton: number,
  isDisabled?: boolean,
  onClick?: Function,
  onBlur?: Function,
  hasNoData?: boolean,
  hideIndex?: number,
  size: string,
  palette?: string,
  icon: any,
};

function BaselaneButtonIconToggle({
  firstId,
  secondId,
  activeButton = 0,
  isDisabled,
  onClick,
  onBlur,
  hasNoData,
  hideIndex,
  size,
  palette,
  icon,
}: ButtonIconToggleProps): any {
  return (
    <Stack direction="row" {...wrapperStyles} {...(hasNoData && { visibility: 'hidden' })}>
      <BaselaneButtonGroup size={size}>
        <BaselaneButtonIcon
          size={size}
          variant="toggle"
          palette={palette}
          isDisabled={isDisabled}
          isActive={activeButton === 0}
          onClick={(e) => {
            e.currentTarget?.focus();
            if (onClick !== null) {
              onClick(0);
            }
          }}
          id={firstId}
          name={firstId}
          onBlur={(e) => onBlur(e)}
          display={hideIndex === 0 ? 'none' : 'flex'}
          icon={icon?.length > 1 ? icon[0] : icon}
        />
        <BaselaneButtonIcon
          size={size}
          variant="toggle"
          palette={palette}
          isDisabled={isDisabled}
          isActive={activeButton === 1}
          onClick={(e) => {
            e.currentTarget?.focus();
            if (onClick !== null) {
              onClick(1);
            }
          }}
          id={secondId}
          name={secondId}
          onBlur={(e) => onBlur(e)}
          display={hideIndex === 1 ? 'none' : 'flex'}
          icon={icon?.length > 1 ? icon[1] : icon}
        />
      </BaselaneButtonGroup>
    </Stack>
  );
}

BaselaneButtonIconToggle.defaultProps = {
  isDisabled: false,
  onBlur: () => {},
  onClick: null,
  hasNoData: false,
  hideIndex: null,
  palette: 'neutral',
};

export default BaselaneButtonIconToggle;
