import React from 'react';

type IconCheckProps = {
  w?: Number,
  h?: Number,
  color?: String,
};

function IconDotsVertical({ w = 4, h = 18, color = 'currentColor' }: IconCheckProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4 18"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.585787.585787C.960859.210714 1.46957 0 2 0s1.03914.210714 1.41421.585787C3.78929.960859 4 1.46957 4 2s-.21071 1.03914-.58579 1.41421C3.03914 3.78929 2.53043 4 2 4S.960859 3.78929.585787 3.41421C.210714 3.03914 0 2.53043 0 2S.210714.960859.585787.585787zm0 7.000003C.960859 7.21071 1.46957 7 2 7s1.03914.21071 1.41421.58579C3.78929 7.96086 4 8.46957 4 9s-.21071 1.0391-.58579 1.4142C3.03914 10.7893 2.53043 11 2 11s-1.039141-.2107-1.414213-.5858C.210714 10.0391 0 9.53043 0 9s.210714-1.03914.585787-1.41421zm0 7.00001C.960861 14.2107 1.46957 14 2 14s1.03914.2107 1.41421.5858C3.78929 14.9609 4 15.4696 4 16c0 .5304-.21071 1.0391-.58579 1.4142C3.03914 17.7893 2.53043 18 2 18s-1.039139-.2107-1.414213-.5858C.210714 17.0391 0 16.5304 0 16c0-.5304.210714-1.0391.585787-1.4142z"
        fill={color}
      />
    </svg>
  );
}

IconDotsVertical.defaultProps = {
  w: 14,
  h: 10,
  color: 'currentColor',
};

export default IconDotsVertical;
