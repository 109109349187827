import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Box, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import moment from 'moment';
import { cloneDeep, sortBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ADD_FUNDS_BANK_TRANSFER } from '@routes';
import getBreakPoints from '@core/utils/getBreakPoints';
import { IconStar, IconTransfer } from '@icons';
import {
  AlertFooter,
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  BaselaneDrawer,
  TwoFactorVerificationPopUp,
  useTwoFactor,
} from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import TransactionContext from '@contexts/TransactionContext';
import UserContext from '@contexts/UserContext';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import BankTransferDrawer from './components/BankTransferDrawer';
import CheckDepositDrawer from './components/CheckDepositDrawer';
import EducationalDrawerWithOtherMethods from './components/EducationalDrawer/EducationalDrawerWithOtherMethods';
import AddFundsDrawerFooter from './AddFundsDrawerFooter';
import TriggerButton from './TriggerButton';
import { CREATE_TRANSFER, GET_TRANSFERS } from '../../../queries';
import {
  getIsTokenExpired,
  getIsTokenValid,
  getSensitiveToken,
  getUnitOTPVerification,
  handleOTPPopupClose,
  handleOTPPopupOpen,
  handleNBOnVerifyClick,
  setTokenData,
} from '../../../helpers/otp.helpers';
import { methods } from './components/EducationalDrawer/helpers/button.helper';
import { title, subtitleTextStyles, drawerBodyStyles } from './styles/addFundsDrawer.styles';

type AddFundsDrawerProps = {
  banks: Array<Object>,
  refetchBankSummary: Function,
  sendOnboardingSegmentEvent?: boolean,
  buttonProps?: Object,
  handleDrawerClose?: Function,
  isGetStarted?: boolean,
  showMobileButton?: boolean,
  getStartedConfig?: Object,
  initialTransferData?: Object,
  variant: string,
  palette: string,
  size: string,
  isOpenTransferFunds?: boolean, // lb success flow, if true, close right after transfer
  isOpenExternalAccount?: boolean, // lb success flow, if true, close right after creating account
  setIsOpenExternalAccount?: Function, // lb success flow, sets checkmark in steps
  setIsExternalAccountChecked?: Function, // lb success flow, sets checkmark in steps
  setIsAddFundsChecked?: Function, // lb success flow, sets checkmark in steps
};

const AddFundsDrawer = forwardRef(
  (
    {
      banks,
      refetchBankSummary,
      sendOnboardingSegmentEvent,
      buttonProps,
      handleDrawerClose,
      isGetStarted,
      showMobileButton,
      getStartedConfig,
      initialTransferData,
      variant,
      palette,
      size,
      isOpenTransferFunds = false,
      isOpenExternalAccount = false,
      setIsOpenExternalAccount = () => {},
      setIsExternalAccountChecked = () => {},
      setIsAddFundsChecked = () => {},
    }: AddFundsDrawerProps,
    ref
  ) => {
    const [bankTransferDrawerIsOpen, setBankTransferDrawerIsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const removeAddFundsParam = () => {
      if (searchParams.has('add_funds')) {
        searchParams.delete('add_funds');
        setSearchParams(searchParams);
      }
    };

    const {
      totalTransactionCount,
      startPollingTransactionsSummary,
      stopPollingTransactionsSummary,
    } = useContext(TransactionContext);

    // needed for when we are in check-deposit and don't want to refresh
    const {
      setIsDesktopCheckDepositOpen,
      setIsTabletCheckDepositOpen,
      setIsMobileCheckDepositOpen,
    } = useContext(UserContext);

    const { isMinXL } = getBreakPoints();

    const navigate = useNavigate();

    // Alert State
    const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

    // State variables
    const [lastTotalTransactionCount, setLastTotalTransactionCount] = useState(
      totalTransactionCount
    );

    const [transfer, setTransfer] = useState(initialTransferData);
    const [isValidAccount, setIsValidAccount] = useState(false);
    const [isValidBankTransfer, setIsValidBankTransfer] = useState(false);
    const [hasBEError, setHasBEError] = useState(false);
    // Every transaction needs a unique idempotency key. This is used to prevent duplicate transactions.
    // It changes every time a transaction finishes.
    const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());
    // Mutation
    const [createNewTransfer, { loading: createTransferLoading }] = useMutation(CREATE_TRANSFER);

    // Add Funds Drawer
    const addFundsDrawerRef = useRef(null);
    const onOpenAddFundsDrawer = () => {
      if (sendOnboardingSegmentEvent) {
        sendSegmentEvent('onboarding_tracker_banking_add_fund', {});
      }
      navigate(`/${ADD_FUNDS_BANK_TRANSFER}`);
    };
    const onCloseAddFundsDrawer = () => {
      setXIdempotencyKey(uuidv4());
      setIsOpenExternalAccount(false);
      addFundsDrawerRef.current.close();
      removeAddFundsParam();
    };

    const plaidBanks = banks?.filter(
      (b) =>
        b.plaidInstitutionName?.toLowerCase() !== 'baselane' &&
        b.plaidInstitutionName?.toLowerCase() !== 'manual'
    );

    const showEmptyState = () =>
      plaidBanks?.filter((b) =>
        b.bankAccounts.find((ba) => {
          return ba.accountType === 'depository' && ba.isExternal && ba.isConnected;
        })
      ).length === 0;

    // Bank Transfer drawer
    const bankTransferDrawerRef = useRef(null);
    const onCloseBankTransferDrawer = () => {
      setXIdempotencyKey(uuidv4());
      if (hasBEError) {
        setHasBEError(false);
      }

      bankTransferDrawerRef.current?.close();
      removeAddFundsParam();
      if (isOpenTransferFunds) {
        addFundsDrawerRef.current.close();
      }
    };

    const sendBaselaneBankingSelectAddFundEvent = () =>
      sendSegmentEvent('baselane_banking_select_add_fund', {
        method: 'initiate_ach',
        platform: isMobile ? 'mobile' : 'desktop',
        hasCounterparty: !showEmptyState(),
      });

    const onOpenBankTransferDrawer = () => {
      sendBaselaneBankingSelectAddFundEvent();
      bankTransferDrawerRef.current?.open();
    };

    // Review Transfer Drawer
    const reviewTransferDrawerRef = useRef(null);
    const onCloseReviewTransferDrawer = () => {
      reviewTransferDrawerRef.current.close();
    };
    const onOpenReviewTransferDrawer = () => reviewTransferDrawerRef.current.open();

    // Transfer Confirmation Drawer
    const transferConfirmationDrawerRef = useRef(null);
    const onCloseTransferConfirmationDrawer = () => transferConfirmationDrawerRef.current.close();
    const onOpenTransferConfirmationDrawer = () => transferConfirmationDrawerRef.current.open();

    // Educational Drawer
    const educationalDrawerRefs = methods.map(() => useRef(null));
    const handleEducationalDrawerClose = (whichMethod) => {
      educationalDrawerRefs[whichMethod]?.current?.close();
      removeAddFundsParam();
    };
    const handleEducationalDrawerOpen = (whichMethod) =>
      educationalDrawerRefs[whichMethod]?.current?.open();

    // Check Deposit drawer
    const mobileCheckDepositDrawerRef = useRef(null);
    const checkDepositDrawerRef = useRef(null);
    const onCloseCheckDepositDrawer = () => {
      removeAddFundsParam();
      mobileCheckDepositDrawerRef.current.close();
    };
    const onOpenMobileCheckDepositDrawer = () => mobileCheckDepositDrawerRef.current?.open();

    // Check Deposit Tagging drawer
    const checkDepositTaggingDrawerRef = useRef(null);

    // Check Deposit Review drawer
    const checkDepositEndorsementDrawerRef = useRef(null);

    // Check Deposit Review drawer
    const checkDepositReviewDrawerRef = useRef(null);

    // Check Deposit Success drawer
    const successDrawerRef = useRef(null);

    useImperativeHandle(ref, () => ({
      onOpenAddFundsDrawer,
      onOpenBankTransferDrawer,
      onOpenCheckDepositDrawer: () => checkDepositDrawerRef.current?.handleInitialClick(),
      onOpenEducationalDrawer: handleEducationalDrawerOpen,
      setTransfer,
    }));

    // Local variables
    const bankId = () => transfer?.selectedAccountTo?.group?.id;
    const phoneNumber = () => banks?.find((b) => b.id === bankId())?.unitAccount?.phoneNumber || '';

    // OTP: Check if token of the bank expired
    const isTokenExpired = getIsTokenExpired(bankId());

    // two factor verification states
    // NOTE: see AddEditSubAccountDrawer/index.jsx for some comments about OTP and setting the information for sensitive token/time
    const { states, stateFunctions } = useTwoFactor();
    const { setOTPErrorCode } = stateFunctions;

    const { DrawerBody } = BaselaneDrawer;

    useEffect(() => {
      if (totalTransactionCount !== lastTotalTransactionCount) {
        stopPollingTransactionsSummary();
        setLastTotalTransactionCount(totalTransactionCount);
        // this will update the balance with the latest transaction result
        // refetchBankSummary();
      }
      // make sure if component is destroyed that we stop polling as well
      return () => stopPollingTransactionsSummary();
    }, [totalTransactionCount, lastTotalTransactionCount, setLastTotalTransactionCount]);

    useEffect(() => {
      setTransfer(initialTransferData);
    }, [initialTransferData]);

    const cleanUp = () => {
      setTransfer({ resetForm: true });
    };

    const handleBankTransferClose = () => {
      // TODO: Update logic we allow other transfer dates
      if (Object.keys(transfer).length > 2) {
        onAlertOpen();
      } else {
        onCloseBankTransferDrawer();
        setTimeout(() => setBankTransferDrawerIsOpen(false), 100);
      }
    };

    const handleCheckDepositClose = () => {
      setXIdempotencyKey(uuidv4());
      setIsDesktopCheckDepositOpen(false);
      setIsTabletCheckDepositOpen(false);
      setIsMobileCheckDepositOpen(false);
      onCloseCheckDepositDrawer();
      removeAddFundsParam();
    };

    const handleCloseAllDrawers = () => {
      setXIdempotencyKey(uuidv4());
      onCloseTransferConfirmationDrawer();
      onCloseReviewTransferDrawer();
      onCloseCheckDepositDrawer();
      onCloseBankTransferDrawer();
      onCloseAddFundsDrawer();
      handleDrawerClose();
      cleanUp();
      removeAddFundsParam();
    };

    // Toast
    const toast = useToast();
    const showErrorToast = () =>
      toast({
        description: `Failed to add funds, try again later`,
        status: 'error',
        duration: '3000',
        isClosable: true,
        position: 'bottom-left',
      });

    const handleNewTransfer = () => {
      createNewTransfer({
        context: {
          headers: {
            'x-idempotency-key': xIdempotencyKey,
          },
        },
        variables: {
          input: {
            amount: stripCurrency(transfer.transferAmount),
            fromTransferAccountId: transfer.selectedAccountFrom.accountId,
            toTransferAccountId: transfer.selectedAccountTo.accountId,
            transferDate: moment(transfer.transferDate).format('YYYY-MM-DD'),
            type: transfer.transferType,
          },
        },
        update: (cache, { data: createTransfer, errors }) => {
          const rootObject = cache?.data?.data?.ROOT_QUERY;
          const findTransferProperty = Object.keys(rootObject).filter((i) =>
            i.includes('transfers')
          );

          const variablesArray = findTransferProperty?.map((v) => {
            const firstParenthesis = (v || '').indexOf('(') + 1;
            const lastParenthesis = (v || '').length - 1;
            return JSON.parse(v?.substring(firstParenthesis, lastParenthesis));
          });

          const defaultVariables = {
            input: {
              page: 1,
              pageLimit: 100,
              sort: { direction: 'DESC', field: 'transferDate' },
              filter: {
                fromTransferDate: null,
                toTransferDate: null,
              },
            },
          };

          variablesArray.forEach((vars) => {
            const { transfers } =
              cache.readQuery({
                query: GET_TRANSFERS,
                variables: vars || defaultVariables,
              }) || {};

            if (transfers && !errors) {
              const newT = [...transfers.data, createTransfer?.createTransfer];
              const updatedTransfers = sortBy(cloneDeep(newT), ['transferDate']).reverse();
              cache.writeQuery({
                query: GET_TRANSFERS,
                variables: vars || defaultVariables,
                data: {
                  transfers: { data: updatedTransfers, total: transfers.total },
                },
              });
            }
          });
        },
      }).then(
        (dataResponse) => {
          if (dataResponse?.data && !dataResponse?.errors) {
            refetchBankSummary();
            onOpenTransferConfirmationDrawer();
            // this will change total transactions, triggering refetch transactions in transactions list
            startPollingTransactionsSummary(2000);
            setTimeout(() => {
              stopPollingTransactionsSummary();
            }, 20000);
            setIsAddFundsChecked(true);
          } else {
            showErrorToast();
            setHasBEError(true);
          }
        },
        (err) => {
          console.error(err);
          showErrorToast();
          setHasBEError(true);
        }
      );
    };

    const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

    const onUserSensitiveTokenComplete = (unitAPISensitiveToken) => {
      const isTokenValid = getIsTokenValid(unitAPISensitiveToken);
      setTokenData(unitAPISensitiveToken, bankId());

      if (isTokenValid) {
        handleNewTransfer();
      }

      handleOTPPopupClose(states, stateFunctions);
    };

    const getSensitiveTokenProps = {
      onUserSensitiveTokenComplete,
      getOTP,
      bankId: bankId(),
      states,
      stateFunctions,
    };

    const [getUserSensitiveTokenData] = getSensitiveToken(getSensitiveTokenProps);

    const checkToken = () => {
      if (isTokenExpired) {
        handleOTPPopupOpen(getOTP, bankId(), stateFunctions);
      } else {
        handleNewTransfer();
      }
    };

    const alertFooter = (
      <AlertFooter
        rightButtonText="Exit without Saving"
        rightButtonEvent={() => {
          onCloseBankTransferDrawer();
          onCloseCheckDepositDrawer();
          onAlertClose();
          setTimeout(() => setBankTransferDrawerIsOpen(false), 100);
          cleanUp();
        }}
        leftButtonText="Continue Editing"
        leftButtonEvent={onAlertClose}
      />
    );

    const twoFactorVerificationProps = {
      ...states,
      ...stateFunctions,
      getOTP,
      bankId: bankId(),
      phoneNumber: phoneNumber(),
      handleVerifyOnClick: (otpCode) =>
        handleNBOnVerifyClick(otpCode, bankId(), getUserSensitiveTokenData),
    };

    const bankTransferProps = {
      reviewTransferDrawerRef,
      transferConfirmationDrawerRef,
      bankTransferDrawerRef,
      onOpenReviewTransferDrawer,
      isValidAccount,
      setIsValidAccount,
      setIsValidBankTransfer,
      setTransfer,
      checkToken,
      onCloseReviewTransferDrawer,
      handleCloseAllDrawers,
      isValidBankTransfer,
      createTransferLoading,
      transfer,
      onAlertOpen,
      onOpenBankTransferDrawer,
      onCloseBankTransferDrawer,
      handleBankTransferClose,
      cleanUp,
      onCloseTransferConfirmationDrawer,
      showEmptyState,
      refetchBankSummary,
      hasBEError,
      setHasBEError,
      isOpenExternalAccount,
      setIsOpenExternalAccount,
      setIsExternalAccountChecked,
      onCloseAddFundsDrawer,
      sendBaselaneBankingSelectAddFundEvent,
      bankTransferDrawerIsOpen,
      setBankTransferDrawerIsOpen,
    };

    const checkDepositProps = {
      banks,
      setIsValidBankTransfer,
      isValidBankTransfer,
      setTransfer,
      mobileCheckDepositDrawerRef,
      onOpenMobileCheckDepositDrawer,
      handleCheckDepositClose,
      checkDepositTaggingDrawerRef,
      checkDepositEndorsementDrawerRef,
      checkDepositReviewDrawerRef,
      successDrawerRef,
      isInEmptyState: showEmptyState(),
      onAlertOpen,
    };

    const drawerHeaderProps = {
      title: isMinXL ? '' : 'Add Funds',
      isMobileHeader: isMinXL,
      showBackButton: isMinXL,
      hideBackText: false,
      hasMobileShadow: false,
    };

    return (
      <>
        <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />

        <TriggerButton
          {...{
            isGetStarted,
            getStartedConfig,
            onOpenAddFundsDrawer,
            buttonProps,
            showMobileVersion: showMobileButton,
            variant,
            palette,
            size,
          }}
        />

        <BaselaneAlert
          isOpen={isAlertOpen}
          onClose={onAlertClose}
          header={<AlertHeader title="You Have Unsaved Changes" />}
          body="Are you sure you want to exit without saving?"
          footer={alertFooter}
          size="xl"
        />
        <BaselaneDrawer
          ref={addFundsDrawerRef}
          size="md"
          closeEvent={onCloseAddFundsDrawer}
          {...drawerHeaderProps}
        >
          <DrawerBody {...drawerBodyStyles(isMinXL)} id="transfer-drawer">
            <Text {...title(isMinXL)}>Select Your Funding Method</Text>
            <Stack spacing="8px" mt="0">
              <Stack direction="row" align="center">
                <IconStar />
                <Text {...subtitleTextStyles}>Recommended Methods</Text>
              </Stack>

              <BankTransferDrawer
                {...bankTransferProps}
                onClose={() => {
                  setXIdempotencyKey(uuidv4());
                }}
              />
              <CheckDepositDrawer ref={checkDepositDrawerRef} {...checkDepositProps} />
            </Stack>

            <Stack spacing="8px" mt={isMinXL ? '40px' : '24px'}>
              <Stack direction="row" align="center">
                <IconTransfer />
                <Text {...subtitleTextStyles}>More Methods</Text>
              </Stack>
              {methods.map((method) => (
                <EducationalDrawerWithOtherMethods
                  key={method.id}
                  buttonContent={method}
                  educationalDrawerRef={educationalDrawerRefs[method.id]}
                  handleDrawerClose={handleEducationalDrawerClose}
                  handleDrawerOpen={handleEducationalDrawerOpen}
                  isInEmptyState={showEmptyState()}
                >
                  <Stack>
                    <Text {...{ textStyle: 'headline-lg' }}>
                      Still not sure? Try our recommended methods
                    </Text>
                    <BankTransferDrawer
                      {...bankTransferProps}
                      onClose={() => {
                        setXIdempotencyKey(uuidv4());
                      }}
                    />
                    <CheckDepositDrawer {...checkDepositProps} />
                    <Box>
                      <BaselaneButton
                        variant="transparent"
                        pallete="primary"
                        onClick={() => handleEducationalDrawerClose(method.id)}
                      >
                        View All Methods
                      </BaselaneButton>
                    </Box>
                  </Stack>
                </EducationalDrawerWithOtherMethods>
              ))}
            </Stack>
          </DrawerBody>
          <AddFundsDrawerFooter
            {...{
              onCloseAddFundsDrawer,
            }}
          />
        </BaselaneDrawer>
      </>
    );
  }
);

AddFundsDrawer.defaultProps = {
  sendOnboardingSegmentEvent: false,
  buttonProps: { variant: 'filled', palette: 'primary' },
  handleDrawerClose: () => {},
  isGetStarted: false,
  getStartedConfig: {},
  showMobileButton: false,
  initialTransferData: {},
  isOpenTransferFunds: false,
  isOpenExternalAccount: false,
  setIsOpenExternalAccount: () => {},
  setIsExternalAccountChecked: () => {},
  setIsAddFundsChecked: () => {},
};

export default AddFundsDrawer;
