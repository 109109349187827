import React from 'react';

function IconProcessing() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.08" cx="12" cy="12" r="12" fill="#0968BF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16298 7.1414C10.2383 6.51348 11.4915 6.26008 12.7263 6.4209C13.9611 6.58172 15.1076 7.14768 15.9862 8.03008C16.8649 8.91247 17.4259 10.0614 17.5814 11.2969C17.6245 11.6394 17.3818 11.952 17.0393 11.9951C16.6968 12.0382 16.3842 11.7955 16.3411 11.453C16.2202 10.4921 15.7838 9.59838 15.1005 8.91205C14.4171 8.22572 13.5253 7.78552 12.5649 7.66043C11.6045 7.53535 10.6297 7.73244 9.79331 8.22084C9.24081 8.54347 8.76933 8.98116 8.40827 9.49996H10.125C10.4702 9.49996 10.75 9.77978 10.75 10.125C10.75 10.4701 10.4702 10.75 10.125 10.75H7C6.65482 10.75 6.375 10.4701 6.375 10.125V6.99996C6.375 6.65478 6.65482 6.37496 7 6.37496C7.34518 6.37496 7.625 6.65478 7.625 6.99996V8.4626C8.05058 7.93619 8.57055 7.48734 9.16298 7.1414ZM6.96026 12.0049C7.3027 11.9616 7.61545 12.204 7.6588 12.5465C7.78041 13.5071 8.21712 14.4002 8.90057 15.0861C9.58401 15.772 10.4756 16.2119 11.4358 16.337C12.3959 16.462 13.3705 16.2652 14.2068 15.7772C14.7589 15.455 15.2303 15.018 15.5914 14.5H13.875C13.5298 14.5 13.25 14.2201 13.25 13.875C13.25 13.5298 13.5298 13.25 13.875 13.25H17C17.3452 13.25 17.625 13.5298 17.625 13.875V17C17.625 17.3451 17.3452 17.625 17 17.625C16.6548 17.625 16.375 17.3451 16.375 17V15.5365C15.9492 16.0626 15.4292 16.5112 14.8367 16.8568C13.7616 17.4842 12.5087 17.7373 11.2743 17.5765C10.04 17.4158 8.89373 16.8502 8.0151 15.9684C7.13647 15.0866 6.57504 13.9384 6.4187 12.7035C6.37535 12.361 6.61781 12.0483 6.96026 12.0049Z"
        fill="#257ED0"
      />
    </svg>
  );
}

export default IconProcessing;
