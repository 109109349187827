import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@chakra-ui/react';
import { UNIFIED_BA_ACCOUNTS } from '@routes';
import AddBankAccountButtonCard from '@core/components/AddBankAccount/AddBankAccountButtonCard';
import BankAccountToTag from './components/BankAccountToTag';

type BanksListViewProps = {
  accountsAutoTaggedList: Array<Object>,
  setAccountsAutoTaggedList: Function,
  propertyOptions: Array<Object>,
};

const BanksListView = ({
  accountsAutoTaggedList,
  setAccountsAutoTaggedList,
  propertyOptions,
}: BanksListViewProps) => {
  const navigate = useNavigate();

  return accountsAutoTaggedList?.length > 0 ? (
    <Stack spacing={2}>
      {accountsAutoTaggedList.map((item, index) => {
        return (
          <BankAccountToTag
            key={item.id}
            {...{
              numberInList: index,
              bankAccount: item,
              propertyOptions,
              accountsAutoTaggedList,
              setAccountsAutoTaggedList,
            }}
          />
        );
      })}
    </Stack>
  ) : (
    <Box mt="32px">
      <AddBankAccountButtonCard
        handleClick={() => {
          navigate(UNIFIED_BA_ACCOUNTS);
        }}
      />
    </Box>
  );
};

export default BanksListView;
