import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { BaselaneButton, BaselaneMessageCard } from '@shared/components';
import { expiringSoonBannerStyles } from '../../styles/expiringSoonBanner.styles';

type ExpireSoonBannerProps = {
  handleTabsChange: Function,
  isTablet: boolean,
};

function ExpireSoonBanner({ handleTabsChange, isTablet }: ExpireSoonBannerProps) {
  // Destructure Imported Style
  const { container, messagecard } = expiringSoonBannerStyles({ isTablet }) ?? {};

  return (
    <Box {...container}>
      <BaselaneMessageCard
        iconName={isTablet ? 'info' : ''}
        iconColor="blue"
        borderColor="blue"
        title="Want to extend or renew?"
        text={
          isTablet ? (
            <Text>This rent collection is expiring soon.</Text>
          ) : (
            <Stack>
              <Text>This rent collection is expiring soon.</Text>
              <BaselaneButton
                variant="filled"
                palette="primary"
                onClick={() => {
                  handleTabsChange(1);
                }}
              >
                Extend or Renew
              </BaselaneButton>
            </Stack>
          )
        }
        textContainerStyles={messagecard.textContainerStyles}
        containerStyles={messagecard.containerStyles}
        styles={messagecard.styles}
        rightElement={
          isTablet ? (
            <BaselaneButton
              variant="filled"
              palette="primary"
              onClick={() => {
                handleTabsChange(1);
              }}
            >
              Extend or Renew
            </BaselaneButton>
          ) : null
        }
        isVertical
      />
    </Box>
  );
}

export default ExpireSoonBanner;
