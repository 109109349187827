import React from 'react';

type IconCameraProps = {
  w?: Number,
  h?: Number,
};

function IconCamera({ w = 24, h = 24 }: IconCameraProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0698 5C9.90526 4.99998 9.7432 5.04058 9.59807 5.11821C9.45303 5.19578 9.32937 5.30793 9.23804 5.44471C9.23798 5.44481 9.23811 5.44461 9.23804 5.44471L8.42585 6.665C8.1518 7.07575 7.78061 7.4125 7.3452 7.64538C6.90983 7.87824 6.42373 8.00005 5.93 8C5.92995 8 5.93005 8 5.93 8H5C4.73478 8 4.48043 8.10536 4.29289 8.29289C4.10536 8.48043 4 8.73478 4 9V18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18V9C20 8.73478 19.8946 8.48043 19.7071 8.29289C19.5196 8.10536 19.2652 8 19 8H18.0701C18.0701 8 18.0702 8 18.0701 8C17.5764 8.00005 17.0902 7.87824 16.6548 7.64538C16.2194 7.4125 15.8482 7.07575 15.5742 6.665L14.7621 5.445C14.762 5.44483 14.7623 5.44517 14.7621 5.445C14.6708 5.30831 14.5469 5.19574 14.4019 5.11821C14.2568 5.04058 14.0947 4.99998 13.9302 5H10.0698ZM10.07 3C9.57627 2.99995 9.09017 3.12176 8.6548 3.35462C8.21939 3.5875 7.8482 3.92425 7.57415 4.335L6.76215 5.555C6.76224 5.55487 6.76206 5.55513 6.76215 5.555C6.67083 5.69174 6.54693 5.80424 6.40193 5.88179C6.2568 5.95942 6.09474 6.00002 5.93015 6H5C4.20435 6 3.44129 6.31607 2.87868 6.87868C2.31607 7.44129 2 8.20435 2 9V18C2 18.7956 2.31607 19.5587 2.87868 20.1213C3.44129 20.6839 4.20435 21 5 21H19C19.7957 21 20.5587 20.6839 21.1213 20.1213C21.6839 19.5587 22 18.7957 22 18V9C22 8.20435 21.6839 7.44129 21.1213 6.87868C20.5587 6.31607 19.7957 6 19 6H18.07C17.9054 6.00002 17.7432 5.95942 17.5981 5.88179C17.4531 5.80426 17.3295 5.69219 17.2382 5.5555C17.2383 5.55566 17.2381 5.55533 17.2382 5.5555L16.4265 4.33593L16.4258 4.335C16.1518 3.92425 15.7806 3.5875 15.3452 3.35462C14.9098 3.12176 14.4237 2.99995 13.93 3C13.93 3 13.93 3 13.93 3H10.07Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11C11.4696 11 10.9609 11.2107 10.5858 11.5858C10.2107 11.9609 10 12.4696 10 13C10 13.5304 10.2107 14.0391 10.5858 14.4142C10.9609 14.7893 11.4696 15 12 15C12.5304 15 13.0391 14.7893 13.4142 14.4142C13.7893 14.0391 14 13.5304 14 13C14 12.4696 13.7893 11.9609 13.4142 11.5858C13.0391 11.2107 12.5304 11 12 11ZM9.17157 10.1716C9.92172 9.42143 10.9391 9 12 9C13.0609 9 14.0783 9.42143 14.8284 10.1716C15.5786 10.9217 16 11.9391 16 13C16 14.0609 15.5786 15.0783 14.8284 15.8284C14.0783 16.5786 13.0609 17 12 17C10.9391 17 9.92172 16.5786 9.17157 15.8284C8.42143 15.0783 8 14.0609 8 13C8 11.9391 8.42143 10.9217 9.17157 10.1716Z"
        fill="currentColor"
      />
    </svg>
  );
}

IconCamera.defaultProps = {
  w: 24,
  h: 24,
};

export default IconCamera;
