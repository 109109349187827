import React, { useContext, useState } from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { IconVerifyEmail, IconExclamationCircle } from '@icons';
import { numOfDigitsMask } from '@core/utils/masks';
import OTPContext from '@contexts/OTPContext';
import BaselaneButton from '../BaselaneButton';
import BaselaneModal from '../BaselaneModal';
import BaselaneMessageCard from '../BaselaneCard/BaselaneMessageCard';
import { formErrorStyles, formInputStyles, formLabelStyles } from '../../styles/input.style';
import { verifyEmailModalStyles } from './styles/verifyEmailModal.styles';

type VerifyEmailModalProps = {
  email: String,
  onCloseBtnClick: Function,
  onVerifyEmailOtpSuccess: Function,
};

const VerifyEmailModal = ({
  email,
  onCloseBtnClick,
  onVerifyEmailOtpSuccess,
}: VerifyEmailModalProps) => {
  const {
    emailOtpError,
    setEmailOtpError,
    isSendEmailOtpLoading,
    isVerifyEmailOtpLoading,
    handleSendOtpRegisteredUser,
    handleVerifyOtpRegisteredUser,
  } = useContext(OTPContext);

  const [otpCode, setOtpCode] = useState('');
  const toast = useToast();

  const handleResendEmailOtp = () => {
    handleSendOtpRegisteredUser({
      isTriggeredFromModal: true,
      handleSendEmailOtpSuccess: () => {
        toast({
          description: 'Sent code.',
          status: 'success',
          duration: '3000',
          isClosable: true,
          position: 'bottom-left',
        });
      },
      handleSendEmailOtpError: () => {
        toast({
          description: 'Failed to send code. Try again.',
          status: 'error',
          duration: '3000',
          isClosable: true,
          position: 'bottom-left',
        });
      },
    });
  };

  const handleSubmitCode = () => {
    handleVerifyOtpRegisteredUser({
      code: otpCode,
      handleVerifyEmailOtpSuccess: onVerifyEmailOtpSuccess,
    });
  };

  const { modal, icon, content, spinner } = verifyEmailModalStyles ?? {};

  return (
    <BaselaneModal
      btnSize="xl"
      isCentered
      defaultOpen
      showCloseButton
      onCloseBtnClick={onCloseBtnClick}
      btnClick={() => {}}
      modalContentStyles={modal.content}
      modalBodyStyles={modal.body}
      closeOnOverlayClick={false}
    >
      {isSendEmailOtpLoading ? (
        <Box {...spinner.container}>
          <Spinner {...spinner.icon} />
        </Box>
      ) : (
        <>
          <Box {...icon.container}>
            <IconVerifyEmail />
          </Box>

          <Stack {...content.container}>
            <Stack {...content.top.container}>
              <Box {...content.top.title}>Verify your email address</Box>
              <Box {...content.top.description}>
                Please enter the verification code sent to{' '}
                <Box as="span" {...content.top.email}>
                  {email || ''}
                </Box>
                <br />
                The code will expire in 10 minutes.
              </Box>
              {emailOtpError?.isGenericError && (
                <BaselaneMessageCard
                  title="Something went wrong. Please try again."
                  iconName="close"
                  iconColor="red"
                  borderColor="red"
                  iconWidth="14px"
                  iconHeight="14px"
                  iconContainerStyles={{ display: 'flex', justifyContent: 'center', w: '20px' }}
                  containerStyles={{ width: '100%' }}
                  {...content.top.messagecard}
                />
              )}
              {emailOtpError?.isTooManyAttempts && (
                <BaselaneMessageCard
                  title="Too many attempts"
                  iconName="exclamationcircle"
                  iconColor="warningyellow"
                  borderColor="warningyellow"
                  text="Enter the last code you received, or try again later."
                  iconWidth="24px"
                  iconHeight="24px"
                  isVertical
                  {...content.top.messagecard}
                />
              )}
            </Stack>

            <Stack {...content.bottom.container}>
              <FormControl
                isInvalid={emailOtpError?.showErrorMessage}
                {...content.bottom.form.control}
              >
                <FormLabel htmlFor="code" {...formLabelStyles.xs}>
                  Enter code
                </FormLabel>
                <Input
                  {...formInputStyles}
                  id="code"
                  name="code"
                  placeholder="XXXXXX"
                  value={otpCode}
                  onChange={(e) => {
                    // Hide inline error msg once user starts typing
                    if (emailOtpError?.showErrorMessage && e.target.value !== otpCode) {
                      setEmailOtpError(null);
                    }
                    setOtpCode(e.target.value);
                  }}
                  as={MaskedInput}
                  mask={numOfDigitsMask(6)}
                />
                <FormErrorMessage {...{ ...formErrorStyles, ml: '0 !important', gap: '8px' }}>
                  <IconExclamationCircle w="13.33" h="13.33" color="#C93A3A" />
                  <Text>{emailOtpError?.message}</Text>
                </FormErrorMessage>
              </FormControl>
              <BaselaneButton
                pullLeft
                variant="transparent"
                palette="neutral"
                onClick={() => {
                  handleResendEmailOtp();
                }}
                isDisabled={emailOtpError?.isTooManyAttempts || emailOtpError?.isGenericError}
                styles={{ alignSelf: 'flex-start' }}
              >
                Resend Verification Code
              </BaselaneButton>
            </Stack>

            <BaselaneButton
              id="email-otp-button"
              variant="filled"
              palette="primary"
              size="sm"
              isLoading={isVerifyEmailOtpLoading}
              styles={{ alignSelf: 'flex-start' }}
              onClick={() => {
                handleSubmitCode();
              }}
            >
              Submit Code
            </BaselaneButton>

            <Box {...content.disclaimer}>Can’t find the email? Please check your spam folder</Box>
          </Stack>
        </>
      )}
    </BaselaneModal>
  );
};

export default VerifyEmailModal;
