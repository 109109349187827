export const popoverTriggerStyles = {
  position: 'absolute',
  bottom: '20px',
  right: '0',
};

export const popoverContentStyles = {
  w: 'auto',
  border: 'none',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
};

export const checkerStyles = {
  display: {
    xxs: 'initial',
    xs: 'initial',
    sm: 'grid',
    md: 'grid',
    lg: 'initial',
    xl: 'initial',
  },
};
