import React, { useState, useRef } from 'react';
import { Text, UnorderedList, ListItem, Box } from '@chakra-ui/react';
import { IconExclamationCircle } from '@icons';
import {
  BaselaneAlert,
  BaselaneButton,
  AlertHeader,
  BaselaneButtonGroup,
} from '@shared/components';
import {
  alertPopupHeaderStyles,
  alertPopupIconBgStyles,
  alertPopupIconContainerStyles,
} from '../styles/deleteAccount.styles';

type DeleteManualAccountAlertProps = {
  handleTerminateAccount: Function,
  id: Number,
};

function DeleteManualAccountAlert({ handleTerminateAccount, id }: DeleteManualAccountAlertProps) {
  const cancelRef = useRef();

  // alert for Archive
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onAlertIsClose = () => setIsAlertOpen(false);
  const handleAlertIsOpen = () => setIsAlertOpen(true);

  const handleAlertContinue = () => {
    handleTerminateAccount(id);
  };

  const header = (
    <AlertHeader
      icon={<IconExclamationCircle />}
      title="Remove account?"
      iconBgStyles={alertPopupIconBgStyles}
      iconContainerStyles={alertPopupIconContainerStyles}
    />
  );

  const footer = (
    <BaselaneButtonGroup size="md">
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="md"
        ref={cancelRef}
        onClick={onAlertIsClose}
      >
        Cancel
      </BaselaneButton>
      <BaselaneButton
        variant="filled"
        palette="danger"
        onClick={() => handleAlertContinue()}
        size="md"
        id="popup-remove-external-account-button"
      >
        Yes, remove
      </BaselaneButton>
    </BaselaneButtonGroup>
  );

  const message = (
    <>
      <Text>This will NOT cancel</Text>
      <UnorderedList ml="22px">
        <ListItem>active rent collection payments being deposited into this account</ListItem>
        <ListItem>scheduled payments from this account</ListItem>
      </UnorderedList>
    </>
  );

  return (
    <Box mt={1.5}>
      <BaselaneAlert
        showCloseButton
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertIsClose}
        isCentered
        header={header}
        body={message}
        footer={footer}
        headerStyles={alertPopupHeaderStyles}
        bodyStyles={{ m: '0 !important' }}
        footerStyles={{ m: '0 !important' }}
        containerStyles={{
          maxWidth: '672px',
          gap: { xs: '32px', base: '16px' },
          m: { xs: '16px', base: '8px' },
          p: { xs: '40px 32px 32px', base: '40px 16px 16px' },
        }}
      />
      <BaselaneButton
        id="remove-external-account-button"
        onClick={handleAlertIsOpen}
        variant="filled"
        palette="danger"
        size="md"
      >
        Remove External Account
      </BaselaneButton>
    </Box>
  );
}

export default DeleteManualAccountAlert;
