import { gql } from '@apollo/client';

export const UPDATE_ACCOUNT_WIDE_PROCESSING_FEE = gql`
  mutation resetFeesPaidByForLeaseLevel($id: ID!) {
    resetProcessingFeesPaidByForLease(id: $id) {
      id
      invoiceProcessingFeePaidBy
      invoiceProcessingFeePaidByOverridden
    }
  }
`;

export const UPDATE_PROCESSING_FEE_LEASE = gql`
  mutation updateProcessingFeePaidByForLease(
    $id: ID!
    $invoiceProcessingFeePaidBy: InvoiceProcessingFeePaidBy!
  ) {
    updateProcessingFeePaidByForLease(
      id: $id
      invoiceProcessingFeePaidBy: $invoiceProcessingFeePaidBy
    ) {
      id
    }
  }
`;
