import React from 'react';

type IllustrationErrorProps = { w?: number, h?: number };

function IllustrationError({ w, h }: IllustrationErrorProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33.5996" cy="33.6001" r="24" fill="#F4B0AF" />
      <circle cx="30.4004" cy="30.3999" r="24" stroke="#000619" strokeLinecap="round" />
      <path d="M31.2002 24L31.2002 32" stroke="#000619" strokeLinecap="round" />
      <ellipse cx="31.2004" cy="36" rx="0.8" ry="0.799999" fill="#000619" />
    </svg>
  );
}

IllustrationError.defaultProps = {
  w: 64,
  h: 64,
};

export default IllustrationError;
