import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { BaselaneButton } from '@shared/components';
import { IconCheck, IconChevronLeft } from '@icons';
import { UNIFIED_BA_ALL_SET, UNIFIED_BA_SUCCESS } from '@routes';
import {
  buttonStyles,
  footerContainerStyles,
} from '@core/components/OnboardingTriage/styles/success.styles';

type FooterButtonsProps = {
  hasUserAutotagged: boolean,
  handleOnDoneClick: Function,
  updateMultiBankAccountLoading: boolean,
};

const FooterButtons = ({
  hasUserAutotagged,
  handleOnDoneClick,
  updateMultiBankAccountLoading,
}: FooterButtonsProps) => {
  const navigate = useNavigate();

  const doneBtnProps = {
    variant: 'filled',
    palette: 'primary',
    leftIcon: <IconCheck />,
    onClick: handleOnDoneClick,
    styles: buttonStyles,
  };

  const skipBtnProps = {
    variant: 'tonal',
    palette: 'primary',
    leftIcon: null,
    onClick: () => navigate(UNIFIED_BA_ALL_SET),
    styles: buttonStyles,
  };

  return (
    <Stack {...footerContainerStyles}>
      <BaselaneButton
        id="previous-button"
        variant="outline"
        palette="neutral"
        size="md"
        onClick={() => navigate(UNIFIED_BA_SUCCESS)}
        leftIcon={<IconChevronLeft />}
      >
        Previous
      </BaselaneButton>
      <BaselaneButton
        id={hasUserAutotagged ? 'done-button' : 'skip-button'}
        size="lg"
        {...(hasUserAutotagged ? doneBtnProps : skipBtnProps)}
        isLoading={hasUserAutotagged && updateMultiBankAccountLoading}
      >
        {hasUserAutotagged ? 'Done' : 'Skip'}
      </BaselaneButton>
    </Stack>
  );
};

export default FooterButtons;
