import React, { forwardRef } from 'react';
import { DROPDOWN_TYPES } from './helpers/dropdown.helpers';

type BaselaneDropdownProps = {
  type?: string,
  isNew?: boolean,
};

const BaselaneDropdown = forwardRef(({ type, isNew, ...rest }: BaselaneDropdownProps, ref) => {
  const Dropdown = DROPDOWN_TYPES[type];

  return <Dropdown ref={ref} {...rest} />;
});

BaselaneDropdown.defaultProps = {
  type: 'tier1-title',
  isNew: false,
};

export default BaselaneDropdown;
