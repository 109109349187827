import React from 'react';
import { Box, Spinner, Stack, Text } from '@chakra-ui/react';
import { IconImageProcessing } from '@icons';
import BaselaneAlert from '@shared/components/BaselaneAlert';
import { GENERIC_BODY2, GENERIC_BODY1 } from './constants';
import {
  processingAlertBodyStyles,
  processingAlertContainerStyles,
  processingBodyContainerStyles,
  processingBodySpinnerStyles,
  processingBodyTextStyles,
} from './styles/processingPopup.styles';

type ProcessingPopupProps = {
  isProcessingPopupOpen: boolean,
  onProcessingPopupClose: Function,
  messageOne?: string,
  messageTwo?: string,
  showGraphic?: boolean,
};

const ProcessingPopup = ({
  isProcessingPopupOpen,
  onProcessingPopupClose,
  messageOne,
  messageTwo,
  showGraphic,
}: ProcessingPopupProps) => {
  const processingBody = (
    <Stack {...processingBodyContainerStyles}>
      {showGraphic && <IconImageProcessing />}
      <Box {...processingBodySpinnerStyles}>
        <Spinner />
      </Box>

      <Text {...processingBodyTextStyles}>
        {messageOne} <br />
        {messageTwo}
      </Text>
    </Stack>
  );

  return (
    <BaselaneAlert
      isOpen={isProcessingPopupOpen}
      onClose={onProcessingPopupClose}
      isCentered
      body={processingBody}
      size="xl"
      bodyStyles={processingAlertBodyStyles}
      containerStyles={processingAlertContainerStyles}
    />
  );
};

ProcessingPopup.defaultProps = {
  messageOne: GENERIC_BODY1,
  messageTwo: GENERIC_BODY2,
  showGraphic: true,
};

export default ProcessingPopup;
