import { useQuery } from '@apollo/client';
import { GET_BANKS } from '@core/apollo/queries';

const useConnectedSelfOwned = () => {
  const {
    loading: isConnectedSelfOwnedLoading,
    error: hasConnectedSelfOwnedError,
    data: connectedSelfOwnedData,
    refetch: refetchConnectedSelfOwned,
  } = useQuery(GET_BANKS, {
    fetchPolicy: 'network-only',
    variables: {
      isManualAccount: true,
      isConnectedAccount: true,
      isExternal: true,
      accountOwnershipType: 'SELF',
    },
  });

  return {
    isConnectedSelfOwnedLoading,
    hasConnectedSelfOwnedError,
    connectedSelfOwnedData,
    refetchConnectedSelfOwned,
  };
};

export default useConnectedSelfOwned;
