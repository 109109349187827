export const selectedText = {
  textStyle: 'headline-sm',
  m: '0 !important',
};

export const selectedAmount = {
  display: 'inline-block',
  textStyle: 'sm',
  ml: '8px',
};

export const iconWrapper = {
  mr: '8px',
};

export const buttonStyles = {
  p: '10px 16px !important',
};

export const dividerStyles = {
  h: '24px',
  mx: '16px !important',
};
