import React from 'react';
import { FormControl, FormLabel, HStack, Spacer } from '@chakra-ui/react';
import { BaselaneSelect } from '@shared/components';
import { selectStyles, labelStyles } from './styles/filter.styles';

const Filters = ({
  filterFunctions,
  filterValues,
  filterOptions,
}: {
  filterFunctions: Object,
  filterValues: Object,
  filterOptions: Object,
}) => {
  return (
    <HStack justifyContent="flex-end" mb="24px">
      <Spacer />
      <HStack>
        <FormControl as={HStack}>
          <FormLabel {...labelStyles}>Account</FormLabel>
          <BaselaneSelect
            placeholder="All"
            selectedOption={filterValues.account}
            menuOptions={filterOptions.accountOptions}
            setSelectedOption={filterFunctions.onAccountChange}
            inputStyles={selectStyles('200px', '300px').input}
            listStyles={selectStyles('200px', '300px').list}
            iconStyles={selectStyles('200px', '300px').icon}
          />
        </FormControl>

        <FormControl as={HStack} ml="32px !important">
          <FormLabel {...labelStyles}>Year</FormLabel>
          <BaselaneSelect
            placeholder="All"
            selectedOption={filterValues.year}
            menuOptions={filterOptions.yearOptions}
            setSelectedOption={filterFunctions.onYearChange}
            inputStyles={selectStyles('120px').input}
            listStyles={selectStyles('120px').list}
            iconStyles={selectStyles('120px').icon}
          />
        </FormControl>

        <FormControl as={HStack} ml="32px !important">
          <FormLabel {...labelStyles}>Month</FormLabel>
          <BaselaneSelect
            placeholder="All"
            selectedOption={filterValues.month}
            menuOptions={filterOptions.monthOptions}
            setSelectedOption={filterFunctions.onMonthChange}
            inputStyles={selectStyles('120px').input}
            listStyles={selectStyles('120px').list}
            iconStyles={selectStyles('120px').icon}
            isDisabled={!filterValues.year || filterValues.year?.id === 0}
          />
        </FormControl>
      </HStack>
    </HStack>
  );
};
export default Filters;
