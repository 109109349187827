import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const parts = ['container', 'track', 'thumb', 'label'];
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Switch = defineMultiStyleConfig({
  baseStyle: {
    track: {
      _focus: {
        boxShadow: 'none',
      },
    },
    thumb: {
      _disabled: {
        border: '1px solid grey',
      },
    },
    label: defineStyle((props) => {
      const { textStyles } = props.theme;

      return { ...textStyles.sm };
    }),
    container: { display: 'flex', alignContent: 'center' },
  },
  sizes: {
    md: {
      track: {
        height: '18px',
        width: '33px',
        p: '3px',
        bg: 'brand.darkBlue.200',
        _active: {
          bg: 'brand.darkBlue.300',
          _disabled: {
            bg: 'brand.neutral.200',
            _hover: {
              bg: 'brand.neutral.200',
            },
          },
        },
        _disabled: {
          bg: 'brand.neutral.200',
          _hover: {
            bg: 'brand.neutral.200',
          },
        },
        _hover: {
          bg: 'brand.darkBlue.250',
        },
        _checked: {
          bg: 'brand.blue.800A',
          _hover: {
            bg: 'brand.blue.700',
          },
          _active: {
            bg: 'brand.blue.900',
          },
        },
      },
      thumb: {
        height: '1.1rem',
        width: '1.1rem',
      },
    },
  },
});
