export const badgeButtonContainerStyles = {
  position: 'absolute',
  right: '10px',
  p: '4px 8px',
  top: '-12px',
  bgColor: 'green.100',
  borderRadius: '100px',
  textStyle: 'headline-sm',
  color: 'green.600',
  cursor: 'pointer',
};

export const offTextStyles = {
  color: '#010113',
  fontWeight: 'normal',
  display: { base: 'none', '2lg': 'inline' },
};
