import React, { useContext } from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import {
  IllustrationBankingBusinessAccount,
  IllustrationBankingSolePropAccount,
} from '@illustrations';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import userContext from '@contexts/UserContext';
import BaselaneButtonCard from '../BaselaneButtonCard';
import {
  cardBodyStyles,
  cardTextContainerStyles,
  cardTitleStyles,
  iconContainerStyles,
  typeCardStyles,
} from '../../styles/typePopupButtons.styles';

type ApplicationTypeButtonsProps = {
  handleActionButton: Function,
  buttonStyles?: Object,
};

const ApplicationTypeButtons = ({
  handleActionButton,
  buttonStyles = {},
}: ApplicationTypeButtonsProps) => {
  const { user } = useContext(userContext);

  return (
    <Stack spacing="8px">
      <BaselaneButtonCard
        id="sole-proprietor-app-button"
        type="clean"
        onClick={() => {
          sendSegmentEvent(
            `${
              user?.onboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_complete_application_type`,
            {
              application_type: 'SoleProprietorship',
            }
          );

          handleActionButton('sole');
        }}
        buttonStyles={{ ...typeCardStyles, ...buttonStyles }}
      >
        <Flex {...iconContainerStyles}>
          <IllustrationBankingSolePropAccount />
        </Flex>
        <Box {...cardTextContainerStyles}>
          <Text {...cardTitleStyles}>Sole Proprietor (individual)</Text>
          <Text {...cardBodyStyles}>
            Open an account as an individual owner of an unincorporated business.
          </Text>
        </Box>
      </BaselaneButtonCard>
      <BaselaneButtonCard
        id="business-app-button"
        type="clean"
        onClick={() => {
          sendSegmentEvent(
            `${
              user?.onboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_complete_application_type`,
            {
              application_type: 'Business',
            }
          );

          handleActionButton('business');
        }}
        buttonStyles={{ ...typeCardStyles, ...buttonStyles }}
      >
        <Flex {...iconContainerStyles}>
          <IllustrationBankingBusinessAccount />
        </Flex>
        <Box {...cardTextContainerStyles}>
          <Text {...cardTitleStyles}>Business</Text>
          <Text {...cardBodyStyles}>
            Open an account on behalf of an LLC, Corporation, or Partnership.
          </Text>
        </Box>
      </BaselaneButtonCard>
    </Stack>
  );
};

export default ApplicationTypeButtons;
