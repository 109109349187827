import { gql } from '@apollo/client';

// Fragments
export const FRAGMENT_TENANTS_CONTENTS = gql`
  fragment TenantsContentsObj on TenantProfile {
    id
    status
    firstName
    lastName
    phoneNumber
    email
    notes
  }
`;

export const GET_TENANT_ACTIVE_PROFILES_NUMBER = gql`
  query landLordTenantSummary {
    landLordTenantSummary {
      numberOfActiveTenants
    }
  }
`;

export const GET_TENANT_ACTIVE_PROFILES = gql`
  ${FRAGMENT_TENANTS_CONTENTS}
  query activeTenantProfiles {
    landLordTenantSummary {
      activeTenants {
        ...TenantsContentsObj
      }
    }
  }
`;

export const GET_TENANT_LEASES = gql`
  query tenantLeases($tenantProfileId: ID, $state: [LeaseState]) {
    leases(input: { tenantProfileId: $tenantProfileId, state: $state }) {
      totalCount
      leases {
        id
        startDate
        endDate
        state
        propertyUnitId
        leaseType
        propertyName
        propertyUnitName
      }
    }
  }
`;

export const ARCHIVE_TENANT_PROFILE = gql`
  ${FRAGMENT_TENANTS_CONTENTS}
  mutation archiveTenantProfile($tenantProfileId: Float!) {
    archiveTenantProfile(tenantProfileId: $tenantProfileId) {
      ...TenantsContentsObj
    }
  }
`;

export const UPDATE_TENANT_PROFILE = gql`
  mutation updateTenantProfile(
    $id: ID!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $email: String
    $notes: [TenantProfileNote]
  ) {
    updateTenantProfile(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        email: $email
        notes: $notes
      }
    ) {
      id
      tenantId
      landlordId
      firstName
      lastName
      phoneNumber
      email
      notes
    }
  }
`;
