import React, { useRef, useState, useEffect } from 'react';
import Select from 'react-dropdown-select';
import FilterWrapper from '../FilterWrapper';
import CustomCategoryDropdown from './components/CustomCategoryDropdown';
import { DisplayInputDefault } from '../DisplayInputVariations';
import MobilePopup from '../MobilePopup';

import {
  onHandleKeyDownFn,
  customDropdownHandleRenderer,
  getMultiSelectedItems,
  getDropdownSize,
} from '../helpers/dropdown.shared.helpers';
import customContentRenderHelperHelper from '../helpers/customContentRenderHelper.helper';
import { handlePopupClose } from '../helpers/popup.shared.helpers';
import '../styles/dropdowns.styles.scss';
import { T1Props } from '../types/dropdown.types';

const T1 = ({
  classNames,
  data,
  title,
  itemRenderer,
  isMulti,
  isDisabled,
  searchTerm,
  selectedItem,
  handleSubmit,
  showValueByFields,
  hasFilterWrapper,
  filterCustomComponent,
  hasActiveParentFilter,
  setHasActiveParentFilter,
  parentId,
  portalRef,
  placeholder,
  hasDropdownClearedExternally,
  setHasDropdownClearedExternally,
  clearButtonText,
  onClearClick,
  dropdownPosition,
  hasError,
  hideSearch,
  hideClearButton,
  CustomDisplayInput,
  isMobile,
  additionalProps,
}: T1Props) => {
  const searchRef = useRef();
  const dropdownElementRef = useRef();

  let defaultValue = selectedItem ?? [];
  if (selectedItem) defaultValue = isMulti ? selectedItem : [selectedItem];

  const [selectedOptions, setSelectedOptions] = useState(defaultValue);
  const [selectedStagedOptions, setSelectedStagedOptions] = useState(defaultValue);

  // mobile popup states
  const [search, setSearch] = useState({ target: { value: '' } });
  const [showMobileDropdownPopup, setShowMobileDropdownPopup] = useState(false);
  const [showMobileDropdownPopupAnimation, setShowMobileDropdownPopupAnimation] = useState({
    slidein: false,
    slideout: false,
  });
  // ---

  const handleClearClick = (_setSearch) => {
    _setSearch({ target: { value: '' } });
    setHasActiveParentFilter(false);

    setSelectedStagedOptions([]);

    if (onClearClick) {
      onClearClick();
    }
  };

  const handleExternalClear = ({ dropDown = null, setSearchParam } = {}) => {
    handleClearClick(setSearchParam);
    setSelectedOptions([]);
    handleSubmit([], dropDown);
  };

  const customContentRenderer = ({ state, methods }) => {
    return customContentRenderHelperHelper({
      state,
      methods,
      CustomDisplayInput,
      selectedOptions,
      placeholder,
      title,
      showValueByFields,
      handleExternalClear,
      isDisabled,
      classNames,
    });
  };

  const handleBlurOnToggle = (id) => {
    const elem = Array.from(dropdownElementRef.current.childNodes).find((c) => c.id === id);
    elem.blur();
  };

  const handleListItemClick = (item, e) => {
    const itemToToggle = selectedStagedOptions.find((opt) => opt.id === item.id);

    if (hasActiveParentFilter) setHasActiveParentFilter(false);

    if (isMulti) {
      const itemsSelected = hasActiveParentFilter
        ? [item]
        : getMultiSelectedItems({ itemToToggle, item, selectedStagedOptions });
      setSelectedStagedOptions(itemsSelected);

      // NOTE: To fix updated styles not getting applied right away,
      //       blur the item to make it lose focus if user deselects it (only for click event)
      if (e?.type === 'click' && itemToToggle) handleBlurOnToggle(item.id);
    } else if (!itemToToggle) {
      if (isMobile) {
        handlePopupClose({
          setShowMobileDropdownPopup,
          setShowMobileDropdownPopupAnimation,
        });
      }
      setSelectedStagedOptions([item]);
      setSelectedOptions([item]);
      handleSubmit(item.id);
    }
  };
  const handleApplyClick = ({ dropDown = null } = {}) => {
    if (isMulti) setSelectedOptions(selectedStagedOptions);
    if (dropDown) dropDown('close');
    handleSubmit(selectedStagedOptions);
  };

  const customDropdownRenderer = ({ props, state, methods }) => {
    const { options } = props ?? {};
    const { search: desktopSearch } = state ?? {};
    const { setSearch: desktopSetSearch, dropDown } = methods ?? {};

    return hasFilterWrapper ? (
      <FilterWrapper
        title={title}
        isMulti={isMulti}
        selectedStagedOptions={selectedStagedOptions}
        filterCustomComponent={filterCustomComponent}
        onClear={() => handleClearClick(desktopSetSearch)}
        onApply={() => handleApplyClick({ dropDown })}
        clearButtonText={clearButtonText}
        hideClearButton={hideClearButton}
        isMobile={isMobile}
        dropDown={dropDown}
      >
        <CustomCategoryDropdown
          isMulti={isMulti}
          options={options}
          selectedStagedOptions={selectedStagedOptions}
          selectedOptions={selectedOptions}
          itemRenderer={itemRenderer}
          dropdownElementRef={dropdownElementRef}
          handleListItemClick={handleListItemClick}
          hasFilterWrapper={hasFilterWrapper}
          title={title}
          searchTerm={searchTerm}
          search={desktopSearch}
          setSearch={desktopSetSearch}
          searchRef={searchRef}
          hideSearch={hideSearch}
        />
      </FilterWrapper>
    ) : (
      <CustomCategoryDropdown
        isMulti={isMulti}
        options={options}
        selectedStagedOptions={selectedStagedOptions}
        selectedOptions={selectedOptions}
        itemRenderer={itemRenderer}
        dropdownElementRef={dropdownElementRef}
        handleListItemClick={handleListItemClick}
        hasFilterWrapper={hasFilterWrapper}
        title={title}
        searchTerm={searchTerm}
        search={desktopSearch}
        setSearch={desktopSetSearch}
        searchRef={searchRef}
        hideSearch={hideSearch}
      />
    );
  };

  const handleClearAndApply = () => {
    setSelectedStagedOptions([]);
    setSelectedOptions([]);
  };

  const handleKeyDownFn = (eventResponse) => {
    const setOptions = (results, id) => {
      const option = results.reduce((acc, opt) => {
        if (opt.id === id) {
          acc.itemSelected = opt;
        }

        return acc;
      }, {});

      handleListItemClick(option.itemSelected);
    };

    const helpers = { dropdownElementRef, searchRef, setOptions };
    onHandleKeyDownFn(eventResponse, helpers);
  };

  const calculateDropdownSize = () => {
    const selectDropdown = document.querySelector('.react-dropdown-select-dropdown');
    if (!selectDropdown) return;
    const selectElem = selectDropdown?.parentElement;
    const parentElem = document.querySelector(`#${parentId}`);
    if (!parentElem) return;

    getDropdownSize({
      selectElem,
      parentElem,
      selectDropdown,
    });

    // focus on search if is not in mobile
    if (!portalRef) searchRef.current?.focus();

    setSelectedStagedOptions(selectedOptions);
  };

  useEffect(() => {
    if (hasDropdownClearedExternally) {
      handleClearAndApply();
      setHasDropdownClearedExternally(false);
    }
  }, [hasDropdownClearedExternally]);

  useEffect(() => {
    setSelectedStagedOptions(defaultValue);
    if (!hasActiveParentFilter) {
      setSelectedOptions(defaultValue);
    }
  }, [selectedItem]);

  // to fix case where if users resizes browser if user has opened mobile popup before
  // slideout would be set to true so when going back from a bigger screen to smaller screen
  // we could briefly see the dropdown for a second.
  useEffect(() => {
    if (!isMobile) {
      setShowMobileDropdownPopupAnimation({ slidein: false, slideout: false });
    }
  }, [isMobile]);

  return isMobile ? (
    <MobilePopup
      DisplayInput={CustomDisplayInput ?? DisplayInputDefault}
      selectedOptions={selectedOptions}
      selectedStagedOptions={selectedStagedOptions}
      placeholder={placeholder}
      title={title}
      hideClearButton={hideClearButton}
      isDisabled={isDisabled}
      isMobile={isMobile}
      isMulti={isMulti}
      hasError={hasError}
      showValueByFields={showValueByFields}
      showMobileDropdownPopup={showMobileDropdownPopup}
      showMobileDropdownPopupAnimation={showMobileDropdownPopupAnimation}
      handleClearClick={handleClearClick}
      handleExternalClear={handleExternalClear}
      onApply={handleApplyClick}
      setShowMobileDropdownPopup={setShowMobileDropdownPopup}
      setShowMobileDropdownPopupAnimation={setShowMobileDropdownPopupAnimation}
      setSelectedStagedOptions={setSelectedStagedOptions}
      dropdownElementRef={dropdownElementRef}
      searchRef={searchRef}
      setSearch={setSearch}
      classNames={classNames}
    >
      <CustomCategoryDropdown
        search={search.target.value}
        hasFilterWrapper={false}
        setSearch={setSearch}
        isMulti={isMulti}
        options={data ?? []}
        selectedStagedOptions={selectedStagedOptions}
        selectedOptions={selectedOptions}
        handleListItemClick={handleListItemClick}
        title={title}
        dropdownElementRef={dropdownElementRef}
        searchRef={searchRef}
        hideSearch={hideSearch}
        searchTerm={searchTerm}
        itemRenderer={itemRenderer}
        setShowMobileDropdownPopup={setShowMobileDropdownPopup}
        setShowMobileDropdownPopupAnimation={setShowMobileDropdownPopupAnimation}
      />
    </MobilePopup>
  ) : (
    <Select
      className={`new tier1-dropdown ${hasError ? 'input-invalid' : ''}  ${classNames.join(' ')}`}
      dropdownPosition={dropdownPosition}
      options={data}
      values={selectedOptions}
      disabled={isDisabled}
      dropdownGap={0}
      closeOnSelect={!isMulti}
      multi={isMulti}
      backspaceDelete={false}
      dropdownRenderer={customDropdownRenderer}
      contentRenderer={customContentRenderer}
      dropdownHandleRenderer={({ state }) => customDropdownHandleRenderer({ state, isMobile })}
      onDropdownOpen={calculateDropdownSize}
      handleKeyDownFn={handleKeyDownFn}
      portal={portalRef}
      additionalProps={additionalProps}
    />
  );
};

T1.defaultProps = {
  classNames: ['fixed-width-dropdown'],
  isMulti: false,
  isDisabled: false,
  searchTerm: ['name'],
  selectedItem: null,
  handleSubmit: () => {},
  hasFilterWrapper: false,
  filterCustomComponent: null,
  hasActiveParentFilter: false,
  setHasActiveParentFilter: () => {},
  parentId: 'page-wrapper',
  portalRef: false,
  placeholder: null,
  hasDropdownClearedExternally: false,
  setHasDropdownClearedExternally: () => {},
  clearButtonText: 'Clear all',
  onClearClick: null,
  dropdownPosition: 'auto',
  hasError: false,
  hideSearch: false,
  hideClearButton: false,
  CustomDisplayInput: null,
  isMobile: false,
  additionalProps: {},
};

export default T1;
