export const diagonalStripeBgStyles = {
  bgImage:
    'linear-gradient(45deg, #ffffff 12.50%, #8fa0bb 12.50%, #8fa0bb 50%, #ffffff 50%, #ffffff 62.50%, #8fa0bb 62.50%, #8fa0bb 100%)',
  bgSize: '5.66px 5.66px',
};

export const progressBarStyles = {
  container: {
    w: '100%',
    h: '30px',
    alignItems: 'baseline',
    mb: '28px',
    gap: 0,
  },
  barStyles: {
    h: '30px',
    w: '100%',
    m: '0 !important',
  },
  barContainerStyles: {
    m: '0 !important',
  },
  ...diagonalStripeBgStyles,
  leftBorderStyles: {
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
  },
  rightBorderStyles: {
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  },
};
