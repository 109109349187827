// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=4763%3A116979
import React, { useState, useRef, useContext } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useDisclosure, useToast, ChakraProvider } from '@chakra-ui/react';

import {
  BaselaneButton,
  BaselaneButtonGroup,
  BaselaneDrawer,
  TwoFactorVerificationPopUp,
  useTwoFactor,
} from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import onDrawerClose from '@core/utils/onDrawerClose';
import getBreakPoints from '@core/utils/getBreakPoints';
import habitatTheme from '@core/themeHabitat';
import {
  getIsTokenExpired,
  getIsTokenValid,
  getSensitiveToken,
  getUnitOTPVerification,
  handleOTPPopupClose,
  handleOTPPopupOpen,
  setTokenData,
  handleNBOnVerifyClick,
} from '@core/components/NativeBankingPage/helpers/otp.helpers';
import Body from './Body';
import UnsavedChangesAlert from './UnsavedChangesAlert';
import { drawerFooterStyles } from './styles/body.styles';
import useEntityByIdQuery from '../shared/useEntityByIdQuery';
import { UPDATE_BANK_ENTITY } from '../queries';

type EditMasterAccountDrawerProps = {
  from?: String,
};

const EditMasterAccountDrawer = ({ from }: EditMasterAccountDrawerProps) => {
  const location = useLocation();
  const { pathname = '' } = location;
  const isMailingDrawer = pathname.indexOf('edit_entity_address') !== -1;
  const [isClosingDelay, setIsClosingDelay] = useState(false);
  const { isMax576 } = getBreakPoints();
  const { DrawerFooter } = BaselaneDrawer;
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { refetchAccountsData } = useContext(TransactionContext);
  const { account } = useOutletContext() ?? {};
  const { refetchEntity } = useEntityByIdQuery();

  // Alert State
  const { isOpen: isDrawerAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  // Mutations
  const [updateBank] = useMutation(UPDATE_BANK_ENTITY);

  // Toast
  const toast = useToast();
  const showToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Account updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  // two factor verification states
  // NOTE: see AddEditSubAccountDrawer/index.jsx for some comments about OTP and setting the information for sensitive token/time
  const { states, stateFunctions } = useTwoFactor();
  const { setOTPErrorCode } = stateFunctions;

  const { bankId, phoneNumber } = account || {};

  // OTP: Check if token of the bank expired
  const isTokenExpired = getIsTokenExpired(bankId);

  // State variables
  const [isDirty, setIsDirty] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [valuesToSubmit, setValuestoSubmit] = useState({});

  const closeEvent = () => {
    onDrawerClose(navigate, from);
  };

  const cleanup = () => {
    if (isDirty) setIsDirty(false);
    if (isFormValid) setIsFormValid(false);
    if (isSubmitting) setIsSubmitting(false);
  };

  const onCloseWithoutSaving = () => {
    if (isDirty) {
      onAlertOpen();
    } else {
      cleanup();
      closeEvent();
    }
  };

  const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

  const onUserSensitiveTokenComplete = (unitAPISensitiveToken) => {
    setIsSubmitting(true);

    const isTokenValid = getIsTokenValid(unitAPISensitiveToken);
    setTokenData(unitAPISensitiveToken, bankId);

    if (isTokenValid) {
      updateBank({ ...valuesToSubmit }).then(async () => {
        setIsSubmitting(true);
        await refetchEntity();
        await refetchAccountsData();
        setIsSubmitting(false);
        cleanup();
        closeEvent();
        showToast();
      });
    }

    handleOTPPopupClose(states, stateFunctions);
  };

  const getSensitiveTokenProps = {
    onUserSensitiveTokenComplete,
    getOTP,
    bankId,
    states,
    stateFunctions,
  };

  const [getUserSensitiveTokenData] = getSensitiveToken(getSensitiveTokenProps);

  const handleValidation = (values) => {
    const errors = {};
    if (values.name === '' || values?.name?.trim() === '') errors.name = 'Please enter a nickname.';

    setIsFormValid(Object.keys(errors).length === 0);

    return errors;
  };

  const handleSubmit = (values) => {
    const editedVariables = {
      variables: {
        id: bankId,
        name: values?.name,
      },
    };

    if (!isTokenExpired) {
      updateBank(editedVariables).then(async () => {
        setIsSubmitting(true);

        await refetchEntity();
        await refetchAccountsData();
        setIsSubmitting(false);
        cleanup();
        closeEvent();
        showToast();
      });
    } else {
      setValuestoSubmit(editedVariables);
      handleOTPPopupOpen(getOTP, bankId, stateFunctions);
    }
  };

  const handleAlertContinueClick = (e) => {
    onAlertClose();
    closeEvent(e);
    cleanup();
  };

  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP,
    bankId,
    phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleNBOnVerifyClick(otpCode, bankId, getUserSensitiveTokenData),
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />
      <UnsavedChangesAlert
        isDrawerAlertOpen={isDrawerAlertOpen}
        onAlertClose={onAlertClose}
        onAlertOpen={onAlertOpen}
        onAlertContinue={(e) => handleAlertContinueClick(e)}
      />

      <BaselaneDrawer
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        isOpen
        title="Entity details"
        closeEvent={onCloseWithoutSaving}
        onClose={onCloseWithoutSaving}
        newDesignDrawer
        newDrawerUseDefaultBodyFooter
        contentStyles={{ opacity: isMailingDrawer && !isClosingDelay ? '0' : '1' }}
      >
        <Body
          account={account}
          formikRef={formikRef}
          isDirty={isDirty}
          isFormValid={isFormValid}
          setIsDirty={setIsDirty}
          handleValidation={handleValidation}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          onClose={onCloseWithoutSaving}
          setIsClosingDelay={setIsClosingDelay}
        />
        <DrawerFooter {...drawerFooterStyles}>
          <BaselaneButtonGroup size="md" styles={{ width: '100%' }}>
            <BaselaneButton
              size="md"
              variant="outline"
              palette="neutral"
              onClick={onCloseWithoutSaving}
            >
              Cancel
            </BaselaneButton>
            <BaselaneButton
              size="md"
              variant="filled"
              palette="primary"
              isDisabled={!isFormValid || !isDirty}
              isLoading={isSubmitting}
              onClick={(e) => formikRef.current.handleSubmit(e)}
              width="100%"
            >
              Save changes
            </BaselaneButton>
          </BaselaneButtonGroup>
        </DrawerFooter>
        <Outlet context={{ account }} />
      </BaselaneDrawer>
    </ChakraProvider>
  );
};

export default EditMasterAccountDrawer;

EditMasterAccountDrawer.defaultProps = {
  from: null,
};
