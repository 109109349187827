import { truncatedText } from '@shared/styles/text.style';

export const menuItemTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  w: '100%',
  textAlign: 'left',
  ...truncatedText,
};

export const menuItemAddressTextStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
  ...truncatedText,
};
