import { isMobile } from 'react-device-detect';

export const noDataContainerStyles = (isLargerThan1537) => {
  const defaultStyles = {
    position: 'fixed',
    right: 0,
    bottom: 0,
    background: 'rgba(255,255,255,.7)',
    zIndex: '2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    m: '0 !important',
    top: '64px',
  };

  return isMobile
    ? {
        ...defaultStyles,
        left: 0,
      }
    : {
        ...defaultStyles,
        left: isLargerThan1537 ? '260px' : '80px',
      };
};

export const noDataPopupStyles = {
  borderRadius: isMobile ? '12px' : '4px',
  p: isMobile ? '16px' : '40px',
  zIndex: '2',
  backgroundColor: 'brand.neutral.white',
  maxW: isMobile ? '348px' : '480px',
  textAlign: 'center',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  borderWidth: '1px',
  borderColor: isMobile ? 'brand.darkBlue.150' : 'transparent',
  m: isMobile && '0 6px !important',
};

export const noDataPopupTitleStyles = {
  fontSize: isMobile ? 'lg' : '2xl',
  lineHeight: isMobile ? '26px' : '32px',
  fontWeight: 'medium',
  color: 'brand.neutral.700',
};

export const noDataPopupDescriptionStyles = {
  fontSize: isMobile ? 'sm' : 'md',
  lineHeight: isMobile ? '20px' : '24px',
  mt: '24px',
  mb: '32px',
  color: 'brand.neutral.700',
};
