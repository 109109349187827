import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Heading, Stack, Text, Box } from '@chakra-ui/react';

import { BaselaneCardNew } from '@shared/components/BaselaneCard-new';
import IllustrationReferral from '@icons/legacy/IllustrationReferral';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserContext from '@contexts/UserContext';
import { REFERRAL } from '@routes';

import BaselaneButton from './BaselaneButton';
import BaselaneShareLink from './BaselaneShareLink';

const TransferReferralSuccessCard = ({ source, ...rest }: { source: string }) => {
  const { user } = useContext(UserContext);
  const { userMetadata } = user ?? {};
  const { referralLink } = userMetadata ?? {};

  const navigate = useNavigate();

  const handleButtonClickOnMobile = () => {
    if (navigator?.share) {
      navigator
        .share({
          title: 'Sign up to Baselane',
          url: referralLink,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleSegmentEvent = () => {
    sendSegmentEvent('referral_success_drawer_copy_link_clicked', { source });
  };

  return (
    <BaselaneCardNew size="md" variant="normal" palette="primary" width="100%" {...rest}>
      <Stack spacing={2} width="100%">
        <Stack direction="row" spacing={2}>
          {/* TODO: to be added properly once merged in to rebranding branch or vise versa */}
          <Box>
            <IllustrationReferral />
          </Box>
          <Stack spacing={0.5}>
            <Heading size="headline-md">Earn $100 for every landlord you refer</Heading>
            <Text>
              Share your personal invitation link and earn $100 when someone signs up using this
              link.
            </Text>
            <Box>
              <BaselaneButton
                variant="link"
                palette="primary"
                styles={{ textDecoration: 'none', ml: 0 }}
                onClick={() => navigate({ pathname: REFERRAL })}
              >
                Learn more
              </BaselaneButton>
            </Box>
          </Stack>
        </Stack>
        <BaselaneShareLink
          link={referralLink}
          text={{
            default: isMobile ? 'Share' : 'Copy Link',
            copied: isMobile ? 'Share' : 'Copy Link',
          }}
          buttonProps={{
            variant: 'filled',
            palette: 'primary',
            size: isMobile ? 'lg' : 'md',
            icon: {
              color: '#fff',
            },
          }}
          handleButtonClickOnMobile={handleButtonClickOnMobile}
          sendDesktopSegmentEvent={handleSegmentEvent}
          sendMobileSegmentEvent={handleSegmentEvent}
        />
      </Stack>
    </BaselaneCardNew>
  );
};

export default TransferReferralSuccessCard;
