import React from 'react';
import { Stack } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneButton, BaselaneDrawer } from '@shared/components';

type MailCheckDrawerFooterProps = {
  leftBtnClick: Function,
  rightBtnClick: Function,
  leftBtnText?: string,
  rightBtnText?: string,
  isRightBtnDisabled?: boolean,
  leftBtnLeftIcon?: any,
  rightBtnRightIcon?: any,
  rightBtnLeftIcon?: any,
  containerStyles?: Object,
  leftbtnVariant?: string,
  leftbtnPalette?: string,
  rightbtnVariant?: string,
  rightbtnPalette?: string,
};

const MailCheckDrawerFooter = ({
  leftBtnClick,
  rightBtnClick,
  leftBtnText,
  rightBtnText,
  isRightBtnDisabled,
  leftBtnLeftIcon,
  rightBtnRightIcon,
  rightBtnLeftIcon,
  leftbtnVariant,
  leftbtnPalette,
  rightbtnVariant,
  rightbtnPalette,
  containerStyles,
}: MailCheckDrawerFooterProps) => {
  const { DrawerFooter } = BaselaneDrawer;
  const { isMinXL } = getBreakPoints();

  return (
    <DrawerFooter>
      <Stack
        direction="row"
        spacing={2}
        w="100%"
        justifyContent={isMinXL ? 'space-between' : 'flex-start'}
        {...containerStyles}
      >
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={leftBtnClick}
          leftIcon={leftBtnLeftIcon}
        >
          {leftBtnText}
        </BaselaneButton>
        <BaselaneButton
          variant="filled"
          palette="primary"
          size="md"
          isDisabled={isRightBtnDisabled}
          onClick={rightBtnClick}
          rightIcon={rightBtnRightIcon}
          leftIcon={rightBtnLeftIcon}
        >
          {rightBtnText}
        </BaselaneButton>
      </Stack>
    </DrawerFooter>
  );
};

MailCheckDrawerFooter.defaultProps = {
  isRightBtnDisabled: false,
  rightBtnRightIcon: null,
  leftBtnLeftIcon: null,
  rightBtnLeftIcon: null,
  leftBtnText: 'Cancel',
  rightBtnText: 'Next',
  leftbtnVariant: 'outline',
  leftbtnPalette: 'neutral',
  rightbtnVariant: 'filled',
  rightbtnPalette: 'primary',
  containerStyles: {},
};

export default MailCheckDrawerFooter;
