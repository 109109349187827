/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import BaselaneFormErrorMessage from '@core/components/Shared/components/BaselaneForm/BaselaneFormErrorMessage';
import getBreakPoints from '@core/utils/getBreakPoints';
import T1WithTitleDropdown from '../../../../BaselaneDropdown-new/T1-Title';
import DropDownDisplayInputWithOutRightButton from '../../../../BaselaneDropdown-new/DisplayInputVariations/DropDownDisplayInputWithOutRightButton';
import BaselaneFormHelperText from '../../../../BaselaneForm/BaselaneFormHelperText';
import AccountDropdownItemWithBalanceAndAccountType from '../AccountDropdownItemWithBalanceAndAccountType';
import useDepositToAccountValidation from './useDepositToAccountValidation';
import useDepositToAccountSubmit from './useDepositToAccountSubmit';
import getTransferType from '../../../helpers/getTransferType';

const DepositToAccount = ({
  openEducationalDrawer,
  updatedLinkTokenData,
  updateLinkTokenIsLoading,
  openManualAccountDrawer,
  updateLinkToken,
}) => {
  const { isMax576 } = getBreakPoints();
  const { values } = useFormikContext();
  const [hasDropdownClearedExternally, setHasDropdownClearedExternally] = useState(false);

  // Setup validation.
  const { validate } = useDepositToAccountValidation({
    openEducationalDrawer,
    updatedLinkTokenData,
    updateLinkTokenIsLoading,
    openManualAccountDrawer,
  });

  // Setup value submission.
  const { submitValue, resetValue } = useDepositToAccountSubmit({ updateLinkToken });

  const { toDropdownList, bankAccountsById } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
  });

  const [filtereredDropdownList, setFilteredDropdownList] = React.useState(toDropdownList);

  useEffect(() => {
    // Filter the dropdown list.
    const filterDropdownList = () => {
      const depositToAccount = bankAccountsById[values.depositToAccountId];
      const transferFromAccount = bankAccountsById[values.transferFromAccountId];
      const transferType = getTransferType(transferFromAccount, depositToAccount);

      // Filtering the accounts for 'TRANSFER_IN' type.
      if (transferType === 'TRANSFER_IN') {
        const filteredAccounts = [];
        toDropdownList.forEach((accountProvider, index) => {
          const accounts = accountProvider.items
            ?.filter((account) => {
              return account?.accountSubType === 'checking';
            })
            ?.filter((account) => {
              return account?.institutionName === 'Baselane';
            });

          if (accounts.length > 0) {
            filteredAccounts[index] = {
              ...accountProvider,
              items: accounts,
            };
          }
        });

        return filteredAccounts;
      }

      // Filtering the accounts for 'TRANSFER_OUT' and 'INTERNAL_TRANSFER' type.
      if (transferFromAccount?.accountSubType === 'savings') {
        const filteredAccounts = [];
        toDropdownList.forEach((accountProvider) => {
          const accounts = accountProvider.items?.filter((account) => {
            return account?.institutionName === 'Baselane';
          });

          if (accounts.length > 0) {
            filteredAccounts.push({
              ...accountProvider,
              items: accounts,
            });
          }
        });

        return filteredAccounts;
      }

      // Default return.
      return toDropdownList;
    };

    const filteredList = filterDropdownList();

    setFilteredDropdownList(filteredList);
  }, [values.transferFromAccountId, values.depositToAccountId, toDropdownList]);

  useEffect(() => {
    const checkIfSelectedAccountIsValid = () => {
      const filteredAccountsById = filtereredDropdownList.reduce((acc, institution) => {
        institution.items.forEach((account) => {
          acc[account.id] = account;
        });
        return acc;
      }, {});

      return !!filteredAccountsById[values.depositToAccountId];
    };

    if (!values.transferFromAccountId || !values.depositToAccountId) {
      return;
    }

    const isValid = checkIfSelectedAccountIsValid();

    if (!isValid) {
      resetValue();
      setHasDropdownClearedExternally(true);
    }
  }, [values.transferFromAccountId, values.depositToAccountId, filtereredDropdownList]);

  return (
    <Field name="depositToAccountId" validate={validate}>
      {({ field, form }) => (
        <FormControl
          isInvalid={form.errors.depositToAccountId && form.touched.depositToAccountId}
          isRequired
          mt={3}
        >
          <FormLabel htmlFor="depositToAccountId">Deposit to</FormLabel>
          <T1WithTitleDropdown
            data={filtereredDropdownList}
            placeholder="Select"
            title="Account"
            searchTerm={['name', 'nickName']}
            showValueByFields={['name', 'nickName']}
            itemRenderer={AccountDropdownItemWithBalanceAndAccountType}
            isMulti={false}
            hasFilterWrapper={false}
            CustomDisplayInput={DropDownDisplayInputWithOutRightButton}
            parentId="drawer-body"
            isMobile={isMax576}
            showDivider
            name="depositToAccountId"
            additionalProps={{ id: 'transfer-funds-deposit-to-dropdown' }}
            classNames={[
              'input-form-lg',
              'auto-select-input-width',
              ...(form.errors.depositToAccountId && form.touched.depositToAccountId
                ? ['input-invalid']
                : []),
            ]}
            selectedItem={bankAccountsById[field.value]}
            handleSubmit={submitValue}
            hasDropdownClearedExternally={hasDropdownClearedExternally}
            setHasDropdownClearedExternally={setHasDropdownClearedExternally}
          />
          {!form.errors.depositToAccountId &&
            (bankAccountsById[field.value]?.isExternal ||
              bankAccountsById[field.value]?.provider === 'MANUAL') && (
              <BaselaneFormHelperText type="isError" customStyles={{ color: 'brand.neutral.600' }}>
                External transfers will take 3 business days
              </BaselaneFormHelperText>
            )}
          <BaselaneFormErrorMessage>{form.errors.depositToAccountId}</BaselaneFormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default DepositToAccount;
