export const headerRowStyles = {
  bg: 'brand.blue.50',
  py: '16px',
  px: '45px',
  borderRadius: '4px',
  alignItems: 'center',
};

export const headerTitleStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
};

export const rowStyles = {
  borderBottomWidth: '1px',
  borderColor: 'brand.darkblue.100',
  alignItems: 'center',
  color: 'brand.neutral.700',
  textStyle: 'xs',
  _last: { borderBottom: 0 },
};

export const skeletonRowStyles = {
  ...rowStyles,
  justifyContent: 'space-between',
  height: '65px',
  px: '16px',
};
