// NOTE: Once B.E. returns isExternal, ACCOUNT_TYPES can be deleted/replaced
export const ACCOUNT_TYPES = Object.freeze({
  INTERNAL_TRANSFER: {
    from: {
      isExternal: false,
    },
    to: {
      isExternal: false,
    },
  },
  TRANSFER_OUT: {
    from: {
      isExternal: false,
    },
    to: {
      isExternal: true,
    },
  },
  TRANSFER_IN: {
    from: {
      isExternal: true,
    },
    to: {
      isExternal: false,
    },
  },
  WIRE_TRANSFER: {
    from: {
      isExternal: false,
    },
    to: null,
  },
  CHECK_PAYMENT: {
    from: {
      isExternal: false,
    },
    to: null,
  },
});

export const accountTypesMapper = {
  CHECK_DEPOSIT: 'check deposit',
  INTERNAL_TRANSFER: 'internal',
  THIRD_PARTY_PAYMENT: 'third party payment',
  TRANSFER_IN: 'add',
  TRANSFER_OUT: 'external',
  WIRE_TRANSFER: 'wire transfer',
  CHECK_PAYMENT: 'check payment',
};

export const transferCheck = (type, selectedAccountTo, selectedAccountFrom) => {
  if (type === 'WIRE_TRANSFER') {
    return selectedAccountFrom?.accountId && !selectedAccountFrom?.isAccountFraud;
  }
  return !(
    selectedAccountTo?.accountId === selectedAccountFrom?.accountId &&
    selectedAccountFrom?.accountId !== undefined &&
    !selectedAccountFrom?.isAccountFraud &&
    !selectedAccountTo?.isAccountFraud
  );
};

export const inputMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
  allowDecimal: true,
};

export const getUpdateLinkTokenInput = ({
  transferType,
  selectedAccountFrom,
  selectedAccountTo,
}) => {
  let bankAccountIdVar = {};

  switch (transferType) {
    case 'TRANSFER_IN':
      bankAccountIdVar = {
        variables: { bankAccountId: selectedAccountFrom?.bankAccountId },
        skip:
          !selectedAccountFrom ||
          selectedAccountFrom?.bankAccountId === undefined ||
          selectedAccountFrom?.bankAccountId === null,
      };
      break;

    case 'TRANSFER_OUT':
      bankAccountIdVar = {
        variables: { bankAccountId: selectedAccountTo?.bankAccountId },
        skip:
          !selectedAccountTo ||
          selectedAccountTo?.bankAccountId === undefined ||
          selectedAccountTo?.bankAccountId === null,
      };
      break;

    default:
      bankAccountIdVar = {
        skip: true,
      };
      break;
  }

  return bankAccountIdVar;
};
