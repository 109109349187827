export const gridStyles = {
  gridWrapper: {
    w: '100%',
    position: 'relative',
    overflowX: 'auto',
    overflowY: 'hidden',
    mt: '12px',
    css: {
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
      },
      '&::-webkit-scrollbar-track': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        width: '4px',
        height: '4px',
        background: '#E5E9EF',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#ACB8C7',
      },
    },
  },
  grid: {
    w: '100%',
    pb: '10px',
    pt: '3px',
  },
};
