import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import IllustrationTransferScheduled from '@icons/legacy/IllustrationTransferScheduled';
import MoveMoneyMenu from '@core/components/Shared/components/MoveMoneyMenu';
import { noRecipientStyles } from '../Recipients/styles';

type NoPaymentProps = {
  handleScheduledPaymentClick: Function,
};

export function NoPayment({ handleScheduledPaymentClick }: NoPaymentProps) {
  const { containerStyles, textContainerStyles } = noRecipientStyles;

  return (
    <Stack {...containerStyles}>
      <IllustrationTransferScheduled />
      <Stack {...textContainerStyles}>
        <Text textStyle="headline-sm">No scheduled transfers or payments</Text>
        <Text textStyle="sm">
          Set up transfers and payments for a future date or on a recurring schedule.
        </Text>
      </Stack>
      <MoveMoneyMenu entryPoint="scheduled_tab" />
    </Stack>
  );
}
