const tableBaseStyles = {
  h: '48px',
  p: '15px 24px',
};

export const tableHeaderStyles = {
  ...tableBaseStyles,
  bg: 'brand.blue.50',
  borderRadius: '4px',
  fontSize: 'xs',
  color: 'brand.neutral.600',
};

export const tableBodyStyles = {
  ...tableBaseStyles,
  fontSize: 'xs',
  color: 'brand.neutral.700',
};

export const titleTextStyles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
};

const inputHeight = '32px';

export const formInputStyles = {
  size: 'xs',
  color: 'brand.neutral.700',
  borderRadius: '4px',
  h: inputHeight,
  borderWidth: '1px',
  borderColor: 'brand.darkBlue.150',
};
