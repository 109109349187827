// @flow
import React from 'react';
import { Text } from '@chakra-ui/react';
import moment from 'moment';
import isNil from '@core/utils/validators/isNil';
import { contentTextStyles } from '../styles/tenantInfo.style';

function LeaseDuration({
  startDate,
  endDate,
  leaseState,
  leaseType,
}: {
  startDate: string,
  endDate: string,
  leaseState: string,
  leaseType: string,
}): any {
  const dateFormat = (d) => {
    return isNil(d) && leaseState === 'DRAFT' ? '' : moment(d).format('MMM DD, YYYY');
  };

  const getEndDate = () => {
    return !endDate && leaseType === 'MONTHLY' ? 'Month-to-month' : dateFormat(endDate);
  };

  return <Text {...contentTextStyles}>{`${dateFormat(startDate)} - ${getEndDate()}`}</Text>;
}

export default LeaseDuration;
