// @flow
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Stack } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { IconArrowRight, IconArrowLeft } from '@icons';
import { UNIFIED_LANDLORD_BANKING } from '@routes';
import userContext from '@contexts/UserContext';
import { applicationSegmentEventNames } from './helpers/createApplication.helpers';
import { MenuState } from './initialMenuState';

type FooterWrapperProps = {
  handleSolePropContinue: Function,
  applicationMenuOptions: Array<MenuState>,
  tabIndex: number,
  setTabIndex: Function,
  formRefs: any,
  formData: Object,
  submitLBDataLoading: boolean,
  isRefetchUserLoading: boolean,
  showEmailVerifyPopup: boolean,
  setHasSubmitErrors: Function,
  setSubmitErrors: Function,
  setSubmitErrorsTitle: Function,
};

function FooterWrapper({
  handleSolePropContinue,
  applicationMenuOptions,
  tabIndex,
  setTabIndex,
  formRefs,
  formData,
  submitLBDataLoading,
  isRefetchUserLoading,
  showEmailVerifyPopup,
  setHasSubmitErrors,
  setSubmitErrors,
  setSubmitErrorsTitle,
}: FooterWrapperProps): any {
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const segmentEvents = applicationSegmentEventNames(user?.onboardingCompleted);

  const resetErrors = () => {
    setHasSubmitErrors(false);
    setSubmitErrors([]);
    setSubmitErrorsTitle('');
  };

  const handlePreviousNavigation = () => {
    resetErrors();

    if (tabIndex > 0) {
      setTabIndex(tabIndex - 1);
      if (formData && formData.id) {
        navigate(applicationMenuOptions[tabIndex - 1]?.route, {
          search: `?applicationId=${formData?.id}`,
        });
      }
    } else if (tabIndex === 0) {
      setTabIndex(null);
      if (formData && formData.id) {
        navigate(UNIFIED_LANDLORD_BANKING, {
          search: `?applicationId=${formData?.id}`,
        });
      }
    }
  };

  const handleFooterNavigation = () => {
    resetErrors();
    handleSolePropContinue({ isCreateApplicationSave: tabIndex === 3 });
    const form = formRefs[tabIndex];
    if (form) {
      form?.current?.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          if (segmentEvents[tabIndex]) sendSegmentEvent(segmentEvents[tabIndex]);
        }
      });
    }
  };

  return (
    <Stack
      direction="row"
      spacing="16px"
      px={isMobile ? '24px' : 0}
      m="32px 0 24px 0"
      position={isMobile && 'sticky'}
      bottom={isMobile && '16px'}
    >
      <BaselaneButton
        id="previous-button"
        variant="outline"
        palette="neutral"
        size="md"
        leftIcon={<IconArrowLeft />}
        onClick={() => handlePreviousNavigation()}
        isDisabled={tabIndex === null}
      >
        Previous
      </BaselaneButton>

      {applicationMenuOptions?.length && tabIndex === (applicationMenuOptions?.length || 0) - 1 ? (
        <BaselaneButton
          id="submit-app-button"
          variant="filled"
          palette="primary"
          size="md"
          onClick={() => handleFooterNavigation()}
          isLoading={showEmailVerifyPopup ? false : submitLBDataLoading || isRefetchUserLoading}
        >
          Submit Application
        </BaselaneButton>
      ) : (
        <BaselaneButton
          id="continue-button"
          variant="filled"
          palette="primary"
          size="md"
          onClick={() => handleFooterNavigation()}
          rightIcon={!isMobile && <IconArrowRight />}
        >
          Continue
        </BaselaneButton>
      )}
    </Stack>
  );
}

export default FooterWrapper;
