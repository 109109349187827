import { useLazyQuery } from '@apollo/client';
import { GET_TRANSACTION_BY_ID } from '../queries';

const getTransactionById = ({
  onGetTransactionByIdComplete = () => {},
  onGetTransactionByIdError = () => {},
  id,
}) =>
  useLazyQuery(GET_TRANSACTION_BY_ID, {
    variables: { id },
    onCompleted: ({ transaction }) => onGetTransactionByIdComplete(transaction),
    onError: (res) => onGetTransactionByIdError(res),
  });

export { getTransactionById };
