import React from 'react';

function IconEmailOpen({
  width = 16,
  height = 16,
  color = 'currnetColor',
}: {
  width?: string,
  height?: string,
  color?: string,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 6C5.5 5.17157 6.17157 4.5 7 4.5H17C17.8284 4.5 18.5 5.17157 18.5 6V10H18.5986L12.8321 13.8444C12.3282 14.1803 11.6718 14.1803 11.1679 13.8444L5.40142 10H5.5V6ZM4.5 9.39905V6C4.5 4.61929 5.61929 3.5 7 3.5H17C18.3807 3.5 19.5 4.61929 19.5 6V9.39905L19.9453 9.10219C20.6099 8.65915 21.5 9.13554 21.5 9.93424V18C21.5 19.3807 20.3807 20.5 19 20.5H5C3.61929 20.5 2.5 19.3807 2.5 18V9.93424C2.5 9.13554 3.39015 8.65915 4.0547 9.10219L4.5 9.39905ZM3.5 9.93424L8.84861 13.5L3.5 17.0657V9.93424ZM3.52131 18.2534L9.75 14.1009L10.6132 14.6764C11.453 15.2362 12.547 15.2362 13.3868 14.6764L14.25 14.1009L20.4787 18.2534C20.3583 18.9611 19.7421 19.5 19 19.5H5C4.25793 19.5 3.64172 18.9611 3.52131 18.2534ZM20.5 17.0657L15.1514 13.5L20.5 9.93424V17.0657Z"
        fill={color}
      />
    </svg>
  );
}

IconEmailOpen.defaultProps = {
  width: '16',
  height: '16',
  color: 'currentColor',
};

export default IconEmailOpen;
