import React from 'react';
import { Flex, Link, Stack, Text } from '@chakra-ui/react';
import { linkStyle } from '../styles/footerSignUpText.styles';

type FooterProps = {
  styles: Object,
};

const Footer = ({ styles }: FooterProps) => {
  return (
    <Flex
      direction="column"
      flex="1 0 0"
      w="100%"
      alignItems={{ base: 'flex-start', xl: 'center' }}
      m="8px 0 0 0 !important"
      {...styles}
    >
      <Stack mt="0 !important">
        <Text>
          By creating an account, you agree to Baselane’s&nbsp;
          <Link
            href="https://www.baselane.com/terms-of-use/"
            style={{ linkStyle, textDecoration: 'underline' }}
            target="_blank"
          >
            Terms of Use
          </Link>
          &nbsp;and&nbsp;
          <Link
            href="https://www.baselane.com/privacy-policy/"
            target="_blank"
            style={{ linkStyle, textDecoration: 'underline' }}
          >
            Privacy Policy
          </Link>
          .
        </Text>
      </Stack>
    </Flex>
  );
};

export default Footer;
