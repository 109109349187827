import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, HStack } from '@chakra-ui/react';
import { LEASES_QUICKPAY_LEARN_MORE } from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneBadge, BaselaneBannerNew } from '@shared/components';
import { relativePositionStyling } from '@shared/styles/banner.style';

const QuickPayAnnounceBanner = () => {
  const navigate = useNavigate();
  const { isMin768: isDesktop } = getBreakPoints();

  const title = (
    <HStack>
      <Box>Get your rent faster with QuickPay</Box>
      <BaselaneBadge type="outline" color="blue" text="BETA" />
    </HStack>
  );
  const body = 'Receive tenant payments in your bank account up to 2 business days earlier.';
  const btnLabel = 'Learn more';

  const onButtonClick = () => {
    sendSegmentEvent('quickpay_click_learn_more');
    navigate({ pathname: LEASES_QUICKPAY_LEARN_MORE });
  };

  const onCloseClick = () => localStorage.setItem('isQuickPayAnnounceBannerClosed', 'true');

  return (
    <BaselaneBannerNew
      {...{
        title,
        body,
        btnLabel,
        onButtonClick,
        onCloseClick,
        styles: { ...relativePositionStyling(isDesktop) },
        isVertical: !isDesktop,
      }}
    />
  );
};

export default QuickPayAnnounceBanner;
