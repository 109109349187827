import { truncatedText } from '@core/components/Shared/styles/text.style';

export const propertyUnitAdddressStyles = {
  propertyunit: {
    textStyle: 'headline-lg',
    color: 'brand.neutral.700',
    m: '0 !important',
    textTransform: 'capitalize',
    ...truncatedText,
  },
  address: {
    textStyle: 'sm',
    m: '0',
    ...truncatedText,
  },
};
