import { React } from 'react';
import { Text, Stack, useMediaQuery } from '@chakra-ui/react';
import { Icon24SendFunds1 } from '@icons/24px';
import { BaselaneButton, BaselaneButtonIcon } from '@shared/components';
import customTheme from '@theme';
import {
  triggerBtnWrapperStyles,
  triggerBtnLableStyles,
} from './styles/makeTransferOrPaymentDrawer.styles';

type TriggerButtonProps = {
  onOpenMakeTransferOrPaymentDrawer: Function,
  buttonProps: Object,
  showMobileVersion: Boolean,
};

const TriggerButton = ({
  onOpenMakeTransferOrPaymentDrawer,
  buttonProps,
  showMobileVersion,
}: TriggerButtonProps) => {
  const [showMobileButton] = useMediaQuery(`(max-width: ${customTheme.breakpoints.sm} )`, {
    ssr: false,
  });

  const handleClick = () => {
    return onOpenMakeTransferOrPaymentDrawer();
  };

  if (showMobileButton && showMobileVersion) {
    return (
      <Stack {...triggerBtnWrapperStyles}>
        <BaselaneButtonIcon icon={<Icon24SendFunds1 />} onClick={handleClick} {...buttonProps} />
        <Text {...triggerBtnLableStyles}>Send Funds</Text>
      </Stack>
    );
  }

  return (
    <BaselaneButton onClick={handleClick} {...buttonProps}>
      Send Funds
    </BaselaneButton>
  );
};

export default TriggerButton;
