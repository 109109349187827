export function getAccountModel(
  { id, accountName, nickName, isExternal, accountNumber, accountSubType },
  bankAccountItems,
  bankId,
  bankName,
  index
) {
  const item = { id, name: nickName || accountName, accountNumber, accountSubType };
  const accountType = accountSubType.charAt(0).toUpperCase() + accountSubType.slice(1);

  if (bankAccountItems) {
    return {
      ...item,
      name: bankName || `Baselane ${bankId}`,
      subAccountName: nickName || `${accountType} Account ${index + 1}`,
    };
  }

  if (!isExternal) {
    return {
      ...item,
      name: bankName || `Baselane ${bankId}`,
      subAccountName: nickName || `${accountType} Account`,
    };
  }

  return item;
}
