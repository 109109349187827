import useBankAccountsActive from '@hooks/useBankAccountsActive';
import formatCurrency from '@core/utils/formatCurrency';

/**
 * This hook is used to validate the form values for Check Deposit.
 */
const useValidation = () => {
  const { bankAccountsById } = useBankAccountsActive();

  const validate = (values) => {
    const errors = {};

    if (!values.amount) {
      errors.amount = 'Amount is required';
    }

    if (!values.accountId) {
      errors.accountId = 'Account is required';
    }

    // For some reason the clear function in dropdown assigns an empty array to the value :(
    const accountValueIsEmptyArray =
      Array.isArray(values.accountId) && values.accountId.length === 0;

    // The validation of amount is a bit complex as it depends on the selected account.
    if (values.accountId && values.amount && !accountValueIsEmptyArray) {
      const selectedAccount = bankAccountsById[values.accountId];

      const {
        checkDepositMonthlyLimit,
        checkDepositMonthlyTotal,
        checkDepositDailyLimit,
        checkDepositDailyTotal,
      } = selectedAccount.limits;

      const remainingDailyLimit = checkDepositDailyLimit - checkDepositDailyTotal;
      const remainingMonthlyLimit = checkDepositMonthlyLimit - checkDepositMonthlyTotal;

      if (values.amount > checkDepositMonthlyLimit) {
        errors.amount = `This check cannot be deposited as it exceeds the ${
          formatCurrency(checkDepositMonthlyLimit).inDollars
        } monthly limit. Contact us for an exception.`;
      }

      if (values.amount > remainingMonthlyLimit) {
        errors.amount = `Depositing this check at this time exceeds the ${
          formatCurrency(checkDepositMonthlyLimit).inDollars
        } monthly limit. Please try again later.`;
      }

      if (values.amount > remainingDailyLimit) {
        errors.amount = `This check cannot be deposited as it exceeds the ${
          formatCurrency(checkDepositDailyLimit).inDollars
        } daily limit. Contact us for an exception.`;
      }

      if (values.amount > checkDepositDailyLimit) {
        errors.amount = `Depositing this check at this time exceeds the ${
          formatCurrency(checkDepositDailyLimit).inDollars
        } daily limit. Please try again later.`;
      }
    }

    return errors;
  };

  return validate;
};

export default useValidation;
