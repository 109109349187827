import { IconCheck } from '@icons';

export const whyCategorize = [
  {
    label: IconCheck,
    value: 'Track income and expenses by Schedule E category and property',
  },
  {
    label: IconCheck,
    value: 'Monitor your monthly cash flow with interactive analytics',
  },
  {
    label: IconCheck,
    value: 'View income statements & performance for each unit, property, or portfolio',
  },
  {
    label: IconCheck,
    value: 'Make tax time a breeze with auto-completed tax forms (Coming Soon)',
  },
];

export const bookkeepingSteps = [
  {
    label: 'step',
    value:
      'On the Transactions page, click on the “category” or “property” box on any transaction row',
  },
  {
    label: 'step',
    value: 'Select a schedule E category or property in the drop-down menu',
  },
  {
    label: 'step',
    value: 'All set, the category or property selection will be auto-saved',
  },
];
