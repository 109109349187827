import React from 'react';
import { Box } from '@chakra-ui/react';
import { parentListItemStyles } from './styles/parentListItem.styles';

type ParentListItemProps = {
  title: String,
  listItem: React.Node,
  children?: React.Node,
  styles?: Object,
};

const ParentListItem = ({ title, listItem, children, styles }: ParentListItemProps) => {
  return (
    <>
      <Box key={`parent-list-${title}`} {...parentListItemStyles(children?.length > 0)} {...styles}>
        {listItem}
      </Box>
      {children}
    </>
  );
};

ParentListItem.defaultProps = {
  children: null,
  styles: {},
};

export default ParentListItem;
