import React, { useState, useRef } from 'react';
import { createSearchParams } from 'react-router-dom';
import {
  Box,
  Flex,
  List,
  ListItem,
  Switch,
  Stack,
  Text,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import {
  BaselaneAlert,
  BaselaneButton,
  BaselaneButtonGroup,
  BaselaneChip,
  BaselaneDrawer,
  BaselaneLink,
  BaselaneRadio,
  BaselaneRadioGroup,
} from '@core/components/Shared/components';
import * as ROUTES from '@routes';
import {
  leaseDetailsHeader,
  leaseMediumText,
  leaseSmallText,
} from '@pages/LeasesPage/LeaseResponsiveDetail/styles/leaseResponsiveDetail.styles';
import { IconChevronRight } from '@icons';
import { Icon16Calendar } from '@core/components/Icons/16px';
import { useUserContext } from '@core/contexts/UserContext';
import {
  UPDATE_ACCOUNT_WIDE_PROCESSING_FEE,
  UPDATE_PROCESSING_FEE_LEASE,
} from '@pages/LeasesPage/queries';
import getBreakPoints from '@core/utils/getBreakPoints';
import { ProcessingFeeAlert } from './ProcessingFeeAlert';

interface ProcessingFeeFormProps {
  externalAccountCollectionCount: number;
  handleOpenRecievingBankAccount: () => void;
  invoiceProcessingFeePaidBy: 'LANDLORD' | 'TENANT';
  invoiceProcessingFeePaidByOverridden: boolean;
  isExternalBankAccount: boolean;
  isOpen: boolean;
  leaseId: string;
  onClose: () => void;
  refetchSingleLease: (variables: object) => void;
}

const cardStyle = {
  w: '100%',
  mx: '0',
  mt: '1.5',
  border: 'solid 1pt',
  borderColor: 'brand.darkBlue.200',
  borderRadius: '8px',
};

export function ProcessingFeeForm({
  externalAccountCollectionCount,
  handleOpenRecievingBankAccount,
  invoiceProcessingFeePaidBy,
  invoiceProcessingFeePaidByOverridden,
  isExternalBankAccount,
  isOpen,
  leaseId,
  onClose,
  refetchSingleLease,
}: ProcessingFeeFormProps) {
  const { user } = useUserContext();
  const { startDate, endDate } =
    user.userPromotion.find(
      (p) => p.promotion.type === 'FEES_WAIVER' && p.promotion.feeType === 'ACH_FEE'
    ) ?? {};
  const { invoiceProcessingFeePaidBy: accountWideProcessingFeePaidBy } = user.userMetadata;
  const isUserEligibleForAchFeePromo = moment() < moment(endDate) && moment() >= moment(startDate);

  const initialFeePaidBy = invoiceProcessingFeePaidByOverridden
    ? invoiceProcessingFeePaidBy
    : accountWideProcessingFeePaidBy;

  const [isUsingAccountWideSetting, setIsUsingAccountWideSetting] = useState(
    !invoiceProcessingFeePaidByOverridden
  );
  const [feePaidBy, setFeePaidBy] = useState(initialFeePaidBy);
  const [showAlert, setShowAlert] = useState(false);

  const cancelButtonRef = useRef(null);

  const { isMax767 } = getBreakPoints();
  const toast = useToast();

  const [updateProcessingFee, { loading }] = useMutation(UPDATE_PROCESSING_FEE_LEASE);
  const [setFeeToAccountWideSetting, { loading: accountWideLoading }] = useMutation(
    UPDATE_ACCOUNT_WIDE_PROCESSING_FEE
  );

  function showSuccessToast() {
    refetchSingleLease({ variables: { input: { leaseId } } });
    setShowAlert(false);

    toast({
      position: 'bottom-left',
      description: `Saved changes`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }

  function showErrorToast() {
    toast({
      position: 'bottom-left',
      description: 'Failed to save changes',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  }

  const handleSave = async () => {
    // if the processing fee had previously been overriden and the landlord decides to return to account wide setting
    if (invoiceProcessingFeePaidByOverridden && isUsingAccountWideSetting) {
      try {
        await setFeeToAccountWideSetting({
          variables: {
            id: leaseId,
          },
          update: (cache, { data: { resetProcessingFeesPaidByForLease } }) => {
            cache.modify({
              id: cache.identify(resetProcessingFeesPaidByForLease),
              fields: {
                invoiceProcessingFeePaidBy: () => accountWideProcessingFeePaidBy,
                invoiceProcessingFeePaidByOverridden: () => false,
              },
            });
          },
        });
        showSuccessToast();
      } catch (error) {
        console.error(error);
        showErrorToast();
      } finally {
        onClose();
      }

      return;
    }

    try {
      await updateProcessingFee({
        variables: {
          id: leaseId,
          invoiceProcessingFeePaidBy: feePaidBy,
        },
        update: (cache, { data: { updateProcessingFeePaidByForLease } }) => {
          cache.modify({
            id: cache.identify(updateProcessingFeePaidByForLease),
            fields: {
              invoiceProcessingFeePaidBy: () => feePaidBy,
            },
          });
        },
      });
      showSuccessToast();
    } catch (error) {
      console.error(error);
      showErrorToast();
    } finally {
      onClose();
    }
  };

  const { DrawerBody } = BaselaneDrawer;

  return (
    <>
      <BaselaneDrawer
        isOpen={isOpen}
        title="Rent Collection Details"
        hideBackText
        size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
        contentStyles={isMax767 && { top: 'auto !important' }}
        onClose={onClose}
        closeEvent={onClose}
        hideOverlay
        newDesignDrawer
        footer={
          (!isUsingAccountWideSetting || invoiceProcessingFeePaidByOverridden) && (
            <BaselaneButtonGroup size="md">
              <BaselaneButton size="md" variant="outline" palette="neutral" onClick={onClose}>
                Cancel
              </BaselaneButton>
              <BaselaneButton
                size="md"
                isDisabled={
                  !(isUsingAccountWideSetting && invoiceProcessingFeePaidByOverridden) &&
                  !(!isUsingAccountWideSetting && feePaidBy !== initialFeePaidBy)
                }
                variant="filled"
                palette="primary"
                onClick={() => setShowAlert(true)}
              >
                Save Changes
              </BaselaneButton>
            </BaselaneButtonGroup>
          )
        }
      >
        <DrawerBody p="0">
          <Flex direction="column" gap="2" mb="3">
            <Text {...leaseDetailsHeader} mt="12px">
              Fee for tenant ACH payments
            </Text>
            <Text {...{ fontSize: 'sm' }}>
              A $2 fee will be charged to tenant ACH payments. Fees will be waived for tenant ACH
              payments deposited into a Baselane Banking account. If collecting rent into an
              external bank account, you can choose to cover this fee or pass it on to your tenant.
            </Text>

            {isUserEligibleForAchFeePromo && (
              <BaselaneChip
                variant="simple-primary"
                label={`Beginning ${moment(endDate).format('MMM D, YYYY')}`}
                size="lg"
                leftIcon={Icon16Calendar}
              />
            )}

            <ProcessingFeeAlert
              externalAccountCollectionCount={externalAccountCollectionCount}
              handleOpenRecievingBankAccount={handleOpenRecievingBankAccount}
              isExternalBankAccount={isExternalBankAccount}
            />

            {isExternalBankAccount && (
              <Box {...{ mt: 2 }}>
                <Text {...leaseDetailsHeader}>Who pays the ACH fee for this rent collection?</Text>
                <Box {...cardStyle}>
                  <Box {...{ borderBottom: 'solid 1pt', borderColor: 'brand.darkBlue.200', p: 2 }}>
                    <FormControl display="flex" justifyContent="space-between" alignItems="center">
                      <FormLabel {...{ fontSize: 'sm' }} htmlFor="ach-who-pays" mb="0">
                        Use account-wide setting
                      </FormLabel>
                      <Switch
                        className="primary-500"
                        id="ach-who-pays"
                        isChecked={isUsingAccountWideSetting}
                        onChange={() => {
                          setIsUsingAccountWideSetting(!isUsingAccountWideSetting);
                          setFeePaidBy(accountWideProcessingFeePaidBy);
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box {...{ px: 2, py: !isUsingAccountWideSetting ? 1 : 2 }}>
                    {!isUsingAccountWideSetting ? (
                      <BaselaneRadioGroup
                        className="primary-500 custom-radio"
                        isDisabled={isUsingAccountWideSetting}
                        onChange={(v) => setFeePaidBy(v)}
                        value={
                          isUsingAccountWideSetting ? accountWideProcessingFeePaidBy : feePaidBy
                        }
                      >
                        <Stack>
                          <BaselaneRadio
                            isChecked={feePaidBy === 'TENANT'}
                            label="Tenant pays"
                            description="$2 will be added to your tenant's invoice"
                            value="TENANT"
                          />
                          <BaselaneRadio
                            isChecked={feePaidBy === 'LANDLORD'}
                            label="I will cover the fee"
                            description="$2 will be deducted from my payouts"
                            value="LANDLORD"
                          />
                        </Stack>
                      </BaselaneRadioGroup>
                    ) : (
                      <>
                        <Text {...{ fontWeight: 'normal', ...leaseMediumText }}>
                          {accountWideProcessingFeePaidBy === 'TENANT'
                            ? 'Tenant pays'
                            : 'I will cover the fee'}
                        </Text>
                        <Text {...leaseSmallText}>
                          {accountWideProcessingFeePaidBy === 'TENANT'
                            ? "$2 will be added to your tenant's invoice"
                            : '$2 will be deducted from my payouts'}
                        </Text>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Flex>

          <BaselaneLink
            to={{
              pathname: ROUTES.USER_PROFILE,
              search: createSearchParams({ activeTabIndex: 3 }).toString(),
            }}
            variant="primary"
            rightIcon={<IconChevronRight />}
          >
            <Text textStyle="sm" fontWeight="medium" as="span">
              Edit account-wide setting
            </Text>
          </BaselaneLink>
        </DrawerBody>
      </BaselaneDrawer>

      <BaselaneAlert
        cancelRef={cancelButtonRef}
        body={
          <List spacing="1">
            <ListItem>
              The new setting will apply to any future payments made by the tenants.
            </ListItem>
            <ListItem>We&apos;ll notify the tenants about the change.</ListItem>
            <ListItem>This won&apos;t impact processing or completed payments.</ListItem>
          </List>
        }
        footer={
          <BaselaneButtonGroup size="md">
            <BaselaneButton
              variant="outline"
              palette="neutral"
              size="md"
              ref={cancelButtonRef}
              onClick={() => setShowAlert(false)}
              isDisabled={loading || accountWideLoading}
            >
              Cancel
            </BaselaneButton>
            <BaselaneButton
              size="md"
              variant="filled"
              palette="primary"
              onClick={handleSave}
              isDisabled={loading || accountWideLoading}
              isLoading={loading || accountWideLoading}
            >
              Confirm
            </BaselaneButton>
          </BaselaneButtonGroup>
        }
        header="Apply changes for this unit?"
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
        showCloseButton
      />
    </>
  );
}
