import { isMobile } from 'react-device-detect';
import customTheme from '@theme';

export const containerStyles = {
  p: '12px',
  bgColor: customTheme.colors.brand.blue['100'],
  borderRadius: '4px',
  mt: isMobile ? '20px' : '32px',
};

export const textStyles = {
  fontSize: '3xs',
  color: customTheme.colors.brand.neutral['700'],
};
