// NOTE: Delete below import line when you want to bring back dynamic YTD
import IconSkeletonBar1 from '@icons/legacy/IconSkeletonBar1';

export const getCoreMetricsList = (data) => {
  const { currentMarketValue, historicalPurchaseValue, totalAppreciation } = data.kpi.summary;
  const list = [
    {
      label: 'Market Value',
      value: currentMarketValue,
      format: 'rounded',
    },
    {
      label: 'Purchase Price',
      value: historicalPurchaseValue,
      format: 'rounded',
    },
    {
      label: 'Appreciation',
      value: totalAppreciation,
      format: 'rounded',
    },
  ];

  return list;
};

export const getYTDResultsList = () => {
  // NOTE: Uncomment all the commented code below, Delete value w/ Icon and width, Pass data as a param when you want to bring back dynamic YTD
  // const { capRate, cashOnCashReturn, returnOnEquity } = data.kpi.summary;

  const list = [
    {
      label: 'Net Operating Income',
      // value: netOperatingIncome,
      value: IconSkeletonBar1,
      width: '66',
      format: 'rounded',
    },
    {
      label: 'Return on Equity',
      // value: returnOnEquity,
      value: IconSkeletonBar1,
      format: 'percentage',
    },
    {
      label: 'Cap Rate',
      // value: capRate,
      value: IconSkeletonBar1,
      width: '86',
      format: 'percentage',
    },
    {
      label: 'Cash-On-Cash Return',
      // value: cashOnCashReturn,
      value: IconSkeletonBar1,
      width: '72',
      format: 'percentage',
    },
  ];

  return list;
};

export const getAssetLabelTooltip = () => {
  return `Asset Value includes only those properties which have both Purchase Price and Market Value.`;
};
