import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { useMutation } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';

import { getQueryParams } from '@core/Services/Firebase/firebase';
import userContext from '@contexts/UserContext';
import { BaselaneButton } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { flowLocationPoints } from '@core/components/OnboardingTriage/helpers/segmentEvents.helpers';
import {
  HOME,
  ONBOARDING_TRIAGE,
  UNIFIED_LANDLORD_BANKING,
  UNIFIED_RENT_COLLECTION,
} from '@routes';
import useOnboardingStore from '@store/Onboarding';
import { UPDATE_USER, UPDATE_USER_WITHOUT_NAME } from '@core/components/UserProfilePage/queries';
import {
  handleFinishLater,
  handleGetUserInfo,
} from '@core/components/OnboardingTriage/helpers/onboardingFunctions.helpers';
import historyStateStorage from '@core/storage/historyStateStorage';

import FinishLaterPopup from './FinishLaterPopup';
import FinishLaterBankingFirstPopup from './FinishLaterBankingFirstPopup';

type FinishLaterButtonProps = {
  handleFinishEvent: Function,
};

const FinishLaterButton = ({ handleFinishEvent }: FinishLaterButtonProps) => {
  const { selection: onboardingTriageSelection } = useOnboardingStore();

  const { pathname = '' } = useLocation();
  const navigate = useNavigate();
  const formattedHistoryState = historyStateStorage.read('baselane-state');

  const { user, refetchUser, setSkippedBankingFirst } = useContext(userContext);
  const [updateUser] = useMutation(UPDATE_USER);
  const [updateUserNoName] = useMutation(UPDATE_USER_WITHOUT_NAME);

  const selection = getQueryParams(window.location, 'selection');

  // Finish Later survey popup
  const [isOpen, setIsOpen] = useState(false);
  // Finish Later banking first popup
  const {
    isOpen: isBankingFirstAlertOpen,
    onOpen: onBankingFirstAlertOpen,
    onClose: onBankingFirstAlertClose,
  } = useDisclosure();

  // Finish Later survey popup Statsig Feature gate
  const featureOn = useGate('finish-later-survey-gate')?.value;
  const isInUnifiedRCFlow = pathname === UNIFIED_RENT_COLLECTION;
  const isInUnifiedBankingFlow = pathname.includes(UNIFIED_LANDLORD_BANKING);

  const handleFinishClick = () => {
    // handleFinishEvent called in Finish Later survey popup
    setIsOpen(true);
  };

  const redirect = () => {
    if (formattedHistoryState?.from?.flow) {
      navigate(formattedHistoryState.from.flow);
      historyStateStorage.write({
        from: formattedHistoryState.from.main,
        params: formattedHistoryState.from.params,
      });
    } else if (formattedHistoryState?.from && typeof formattedHistoryState?.from === 'string') {
      navigate(formattedHistoryState.from);
      localStorage.removeItem('applicationId');
    } else {
      navigate(HOME);
      localStorage.removeItem('applicationId');
    }
  };

  const onboardingRedirect = () => {
    if (pathname !== HOME) {
      navigate(HOME);
    }
  };

  const hasNoName = !user?.firstName && !user?.lastName;

  const finishEventObject = {
    user: handleGetUserInfo(user, selection, pathname),
    refetchUser,
    updateUser: hasNoName ? updateUserNoName : updateUser,
    setFlowTrue: () => {
      if (flowLocationPoints?.[selection]?.finishLater) {
        sendSegmentEvent(flowLocationPoints[selection].finishLater);
      }
      if (selection) {
        sendSegmentEvent('onboarding_path_selection', { feature: selection });
      }
    },
    handleRouteChange: () => {
      if (
        handleFinishEvent &&
        pathname !== UNIFIED_LANDLORD_BANKING &&
        (pathname.includes('unified_landlord_banking') || pathname === UNIFIED_RENT_COLLECTION)
      ) {
        handleFinishEvent({ onFinishEvent: onboardingRedirect });
      } else {
        onboardingRedirect();
      }
    },
  };

  return (
    <>
      <BaselaneButton
        id="later-button"
        variant="transparent"
        palette="neutral"
        size="md"
        pullRight
        onClick={() => {
          // 1. user is in app (finished onboarding flow) or user is in rent collection flow going into landlord banking flow
          if (user.onboardingCompleted || formattedHistoryState?.from?.flow) {
            if (
              handleFinishEvent &&
              pathname.includes('unified_landlord_banking') &&
              pathname !== UNIFIED_LANDLORD_BANKING
            ) {
              handleFinishEvent({ onFinishEvent: redirect });
            } else {
              redirect();
            }
          } else if (featureOn && isInUnifiedRCFlow) {
            handleFinishClick();
          } else if (
            !user?.onboardingCompleted &&
            isInUnifiedBankingFlow &&
            onboardingTriageSelection === 'bookkeeping'
          ) {
            sendSegmentEvent('banking_first_bank_flow_finish_later');
            onBankingFirstAlertOpen();
          } else {
            // 2. in onboarding flows
            handleFinishLater(finishEventObject);
          }
        }}
      >
        Finish Later
      </BaselaneButton>
      {isOpen && (
        <FinishLaterPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleFinishLater={() => handleFinishLater(finishEventObject)}
        />
      )}
      <FinishLaterBankingFirstPopup
        isOpen={isBankingFirstAlertOpen}
        onClose={(type) => {
          onBankingFirstAlertClose();

          if (type === 'skip' && onboardingTriageSelection === 'bookkeeping') {
            sendSegmentEvent('banking_first_bank_flow_finish_later_skip_anyway');
            setSkippedBankingFirst(true);
            localStorage.setItem('skippedBankingFirst', 'true');
            navigate(ONBOARDING_TRIAGE);
          } else {
            sendSegmentEvent('banking_first_bank_flow_finish_later_bank_to_application');
          }
        }}
      />
    </>
  );
};

export default FinishLaterButton;
