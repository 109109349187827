export const carouselWrapperStyles = {
  padding: 4,
  position: 'relative',
};

export const slideWrapperStyles = {
  overflow: 'hidden',
};

export const slideContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  height: 'auto',
  marginLeft: 'calc(16px * -1)',
};
