import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import checkIconUri from './assets/check.svg';

const DepositChecksFromMobile = () => {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Box display="flex" justifyContent="center">
          <Image src={checkIconUri} alt="A check mockup" />
        </Box>
        <Text textStyle="headline-xl" textAlign="center" marginTop={5}>
          Deposit checks from your mobile device
        </Text>
        <Text textStyle="sm" textAlign="center" marginTop={3}>
          Sign in to Baselane on your mobile device to deposit checks from anywhere, anytime. Simply
          endorse your check, take a picture of the front and back, then follow the prompts to
          submit your deposit.
        </Text>
      </Box>
    </Box>
  );
};

export default DepositChecksFromMobile;
