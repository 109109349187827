export const handleValidation = (values) => {
  const errors = {};

  if (values.address === '') {
    errors.address = 'Please enter in address';
  }
  if (values.city === '') {
    errors.city = 'Please enter in city';
  }
  if (values.state === '') {
    errors.state = 'Please enter in state';
  }
  if (values.zipcode === '') {
    errors.zipcode = 'Please enter in zipcode';
  }
  const isAllDigits = /^\d+$/;
  if (!isAllDigits.test(values.zipcode) || values.zipcode.length !== 5) {
    errors.zipcode = 'Please enter valid zipcode';
  }

  return errors;
};

export const initialVariables = (formData) => ({
  address: formData?.address ?? '',
  unit: formData?.unit ?? '',
  city: formData?.city ?? '',
  state: formData?.state ?? '',
  zipcode: formData?.zipcode ?? '',
});
