// @flow
import React from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { Icon16PlusCircle } from '@icons/16px';
import { BaselaneButton } from '@shared/components';
import { invoiceStyles } from '../../styles/invoice.styles';

function InvoiceHeader({
  title,
  subTitle,
  handleAddInvoiceClick,
}: {
  title: string,
  subTitle: Object,
  handleAddInvoiceClick: Function,
}): any {
  const { isMax767 } = getBreakPoints();

  const { header } = invoiceStyles({ isMax767 });

  return (
    <HStack {...header} direction={isMax767 ? 'column' : 'row'}>
      <Flex direction="column" w="70%">
        <Text {...header.address}>{title}</Text>
        {subTitle}
      </Flex>
      {!isMax767 && (
        <Box display="flex" w="30%" justifyContent="right">
          <BaselaneButton
            onClick={handleAddInvoiceClick}
            variant="filled"
            palette="primary"
            size="md"
            align="right"
            leftIcon={<Icon16PlusCircle />}
          >
            Add Invoice
          </BaselaneButton>
        </Box>
      )}
    </HStack>
  );
}

export default InvoiceHeader;
