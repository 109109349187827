export const baselaneBannerStyles = ({ variant, isVertical }) => {
  const variantArr = variant.split('-');
  const buttonPalette = variantArr[0];
  const buttonVariant = variantArr[1];
  const isMedium = buttonVariant === 'medium';

  return {
    bannercontainer: {
      minW: '100%',
      p: 0,
      m: 0,
    },
    innercontainer: {
      flex: 1,
      gap: 1.5,
      alignItems: 'flex-start',
    },
    textcontainer: {
      w: '100%',
      ml: 0,
      gap: 1.5,
      flexDir: isVertical ? 'column' : 'row',
      alignItems: isVertical ? 'flex-start' : 'center',
    },
    iconcontainer: {
      display: 'inline-block',
      minH: '24px',
      position: 'relative',
      top: '2px',
    },
    icon: {
      w: '32px',
      h: '32px',
      color: 'initial',
      m: '0 !important',
    },
    chip: {
      size: 'sm',
    },
    actionbtn: {
      size: 'md',
      variant: 'outline',
      palette: 'neutral',
      styles: { m: '0 !important', w: 'fit-content', minW: 'fit-content' },
    },
    closebtn: {
      size: 'xs',
      variant: isMedium ? 'transparent' : 'reverse',
      styles: isMedium ? { color: 'inherit', ml: '12px !important' } : { ml: '12px !important' },
      palette: isMedium ? buttonPalette : 'neutral',
    },
  };
};
