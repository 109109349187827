import customTheme from '@core/theme';

export const title = (isMobile) => ({
  fontSize: isMobile ? '2xl' : 'lg',
  lineHeight: isMobile ? '32px' : '26px',
  mb: '42px',
  fontWeight: 'medium',
});

export const title4 = {
  textStyle: 'headline-lg',
  mb: '32px !important',
};

export const title2 = {
  textStyle: 'headline-lg',
};

export const descriptionTextStyles = {
  color: customTheme.colors.brand.neutral['600'],
  textStyle: 'xs',
  mb: '32px !important',
};

export const drawerBodyStyles = (isMobile) => ({
  p: isMobile ? '16px 24px' : '48px 32px',
});

export const drawerBodyStylesTransfer = (isMobile) => ({
  height: '100%',
  p: isMobile ? '16px 24px' : '48px 32px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
});

export const achTitleStyles = (isMobile) => ({
  justifyContent: 'space-between',
  mb: isMobile ? '16px !important' : '32px !important',
  flexFlow: isMobile && 'column-reverse',
});

export const containerStyle = {
  marginTop: '74px',
  p: 0,
};

export const disclaimerTextStyles = {
  mx: '0px',
};

export const footerStyles = (isMobile) => ({
  p: isMobile ? '8px 24px' : '20px 32px',
  minH: isMobile ? '64px' : '80px',
  spacing: '16px',
});

export const drawerFooterStyles = () => ({
  p: '16px 24px',
  boxShadow: '1px -2px 6px 0px rgba(3, 24, 64, 0.04)',
});

export const triggerBtnWrapperStyles = { w: '64px', alignItems: 'center' };

export const triggerBtnLableStyles = {
  fontSize: '3xs',
  lineHeight: '14px',
  fontWeight: 'normal',
  color: 'brand.blue.800A',
  textAlign: 'center',
};
