import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { IconDefaultBank } from '@icons';
import BaselaneIconLogo from '@core/assets/BaselaneIconLogo';
import customTheme from '@theme';

const getBankLogo = (bankLogo, plaidInstitutionName) => {
  let bankIcon;
  if (bankLogo) {
    bankIcon = (
      <Image
        width="40px"
        src={`data:image/png;base64,${bankLogo.toString()}`}
        alt={plaidInstitutionName}
        draggable={false}
      />
    );
  } else if (!bankLogo && plaidInstitutionName === 'Baselane') {
    const { blue } = customTheme.colors.brand;
    bankIcon = <BaselaneIconLogo color={blue['800A']} />;
  } else {
    bankIcon = (
      <Box w="40px">
        <IconDefaultBank w={40} h={40} />
      </Box>
    );
  }
  return bankIcon;
};

export default getBankLogo;
