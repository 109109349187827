// @flow
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { countContainerStyles, countTextStyles } from './styles/count.style';

function BaselaneCount({
  count,
  containerStyles,
  textStyles,
}: {
  count: any,
  containerStyles?: Object,
  textStyles?: Object,
}): any {
  const countContainerStyle = { ...countContainerStyles, ...containerStyles };
  const countTextStyle = { ...countTextStyles, ...textStyles };
  return (
    <Box {...countContainerStyle}>
      <Text {...countTextStyle}>{count}</Text>
    </Box>
  );
}

BaselaneCount.defaultProps = {
  containerStyles: null,
  textStyles: null,
};

export default BaselaneCount;
