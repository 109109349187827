import { defineStyleConfig } from '@chakra-ui/react';

/**
 * This overrides Chakra's default <FormLabel /> styles.
 *
 * see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/form-label.ts for implementation details
 */
export const FormLabel = defineStyleConfig({
  baseStyle: {
    mb: 0.75,
    marginEnd: 1.5,
    fontSize: 'xs',
    lineHeight: '16px',
    fontWeight: 'normal',
  },
});
