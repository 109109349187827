import hasOnlyWhitespace from '@core/utils/hasOnlyWhitespace';

const tenantFormValidation = (values, emails, landlordEmail, showMessage, setShowMessage) => {
  const filterPhoneNumber = values?.phoneNumber?.replace(/[^0-9]/g, '');

  if (showMessage) setShowMessage(false);

  const errors = {};
  if (!/^[A-Z0-9'-\s]+$/i.test(values.lastName)) {
    errors.lastName = "Only alphanumeric characters, - and ' allowed";
  }
  if (!values.lastName || values.lastName === '' || hasOnlyWhitespace(values.lastName)) {
    errors.lastName = 'Please enter last name';
  }
  if (!/^[A-Z0-9'-\s]+$/i.test(values.firstName)) {
    errors.firstName = "Only alphanumeric characters, - and ' allowed";
  }
  if (!values.firstName || values.firstName === '' || hasOnlyWhitespace(values.firstName)) {
    errors.firstName = 'Please enter first name';
  }
  if (values.phoneNumber && values.phoneNumber !== '' && filterPhoneNumber.length < 10) {
    errors.phoneNumber = 'Invalid phone number';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  } else if (values.email === landlordEmail) {
    errors.email = 'You cannot invite yourself as a Tenant';
  } else if (emails.includes(values.email)) {
    errors.email = 'Email matches existing tenant.';
  }

  return errors;
};

const resetTenantFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};

const tenantFormInitialValues = (tenantProfileData) => ({
  firstName: tenantProfileData?.firstName || '',
  lastName: tenantProfileData?.lastName || '',
  email: tenantProfileData?.email || '',
  phoneNumber: tenantProfileData?.phoneNumber || '',
});

export { tenantFormValidation, tenantFormInitialValues, resetTenantFormValues };
