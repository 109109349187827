import React, { useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import SignUpLoginButtons from '@core/pages/LoginPage/components/SignUpLoginButtons';
import CreateAccountForm from '@core/pages/SignUpPage/components/Form/CreateAccountForm';
import TenantFormText from '@core/pages/SignUpPage/components/Form/TenantFormText';
import UserType from '@core/pages/SignUpPage/components/Form/UserType';
import { signupFormContainerStyles } from '../../styles/signup.styles';

type FormWrapperProps = {
  isReferral: Boolean,
  signUpVariant: String,
};

const FormWrapper = ({ isReferral, signUpVariant }: FormWrapperProps) => {
  const [userType, setUserType] = useState('landlord');

  const isUserType = (type) => {
    return userType === type;
  };

  if (isReferral) {
    return (
      <VStack {...signupFormContainerStyles(isReferral)} m="0">
        <SignUpLoginButtons isLogin={false} isMobile={isMobile} />
        <CreateAccountForm signUpVariant={signUpVariant} />
      </VStack>
    );
  }

  return (
    <VStack {...signupFormContainerStyles(isReferral)} m="0">
      <UserType {...{ userType, setUserType }} />
      {isUserType('landlord') && (
        <Box w="100%" mt={{ base: '8px', sm: '8px' }}>
          <SignUpLoginButtons isLogin={false} isMobile={isMobile} />
          <CreateAccountForm signUpVariant={signUpVariant} />
        </Box>
      )}
      {isUserType('renter') && <TenantFormText />}
    </VStack>
  );
};

export default FormWrapper;
