import { truncatedText } from '@shared/styles/text.style';

export const menuItemTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  w: '100%',
  ...truncatedText,
};

export const menuItemSubAccountStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
  m: '0 !important',
  w: '100%',
  ...truncatedText,
};

export const cardDisabledStyles = (isDisabled) => ({
  ...(isDisabled ? { opacity: 0.6 } : {}),
});
