import { titleStyles } from '@shared/styles/popup.styles';

export const noPhoneNumberPopupStyles = {
  title: {
    ...titleStyles,
    pb: 2,
  },
  messagecard: {
    container: {
      mt: '0 !important',
      mb: '24px !important',
    },
    text: {
      textStyle: 'xs',
    },
    email: {
      textStyle: 'headline-xs',
      color: 'brand.blue.800A',
    },
    styles: {
      title: {
        size: 'headline-xs',
        mb: 1,
      },
    },
  },
  description: {
    m: '0 !important',
  },
  alert: {
    container: {
      p: 4,
    },
    footer: {
      display: 'none',
    },
  },
};
