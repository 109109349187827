// @flow
import React from 'react';
import { BaselaneTable } from '@shared/components';
import BankBalancesListRow from './BankBalancesListRow';
import type { Bank } from './types';

function BankBalancesList({ banks, hasNoData }: { banks: Bank[], hasNoData: boolean }): any {
  const banksWithConnectedAndImportingAccounts = banks.filter(
    (b) =>
      b.bankAccounts.length > 0 &&
      b.bankAccounts.filter((a) => a.isConnected === true && a.importTransaction.enabled === true)
        .length > 0
  );

  banksWithConnectedAndImportingAccounts.map((bank) => {
    // Note: made this edit as the re-assignment to the bank parameter
    // was throwing runtime errors under certain circumstances
    const processedBank = { ...bank };
    processedBank.bankAccounts = bank.bankAccounts.filter(
      (a) => a.isConnected === true && a.importTransaction.enabled === true
    );
    return processedBank;
  });

  const tableHeader = [
    { title: 'Bank', width: '130px' },
    { title: 'Account Nickname' },
    { title: 'Balance', align: 'right' },
  ];

  const bankBalancesTableRow = (item, customGrid) => (
    <BankBalancesListRow key={item.plaidInstitutionId} bank={item} columns={customGrid} />
  );

  return (
    <BaselaneTable
      tableHeader={tableHeader}
      tableContent={banksWithConnectedAndImportingAccounts}
      tableRow={bankBalancesTableRow}
      rowWithoutPadding
      headerStyles={{ px: '24px' }}
      hasNoData={hasNoData}
    />
  );
}

export default BankBalancesList;
