export const handlePopupClose = ({
  setShowMobileDropdownPopup,
  setShowMobileDropdownPopupAnimation,
}) => {
  setShowMobileDropdownPopup(false);
  setShowMobileDropdownPopupAnimation(() => ({ slidein: false, slideout: true }));
};

export const handlePopupToggle = ({
  setShowMobileDropdownPopup,
  showMobileDropdownPopup,
  setShowMobileDropdownPopupAnimation,
}) => {
  setShowMobileDropdownPopup((prevState) => !prevState);
  const animationObject = {
    slidein: !showMobileDropdownPopup,
    slideout: false,
  };
  setShowMobileDropdownPopupAnimation(animationObject);
};
