import React from 'react';

function IconReturned({
  width,
  height,
  viewBox,
  color,
  bgColor,
}: {
  width?: string,
  height?: string,
  viewBox?: string,
  color?: string,
  bgColor?: string,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={bgColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8536 7.64645C11.0488 7.84171 11.0488 8.15829 10.8536 8.35355L8.70711 10.5H12.5C13.6935 10.5 14.8381 10.9741 15.682 11.818C16.5259 12.6619 17 13.8065 17 15V16C17 16.2761 16.7761 16.5 16.5 16.5C16.2239 16.5 16 16.2761 16 16V15C16 14.0717 15.6313 13.1815 14.9749 12.5251C14.3185 11.8687 13.4283 11.5 12.5 11.5H8.70711L10.8536 13.6464C11.0488 13.8417 11.0488 14.1583 10.8536 14.3536C10.6583 14.5488 10.3417 14.5488 10.1464 14.3536L7.14645 11.3536C6.95118 11.1583 6.95118 10.8417 7.14645 10.6464L10.1464 7.64645C10.3417 7.45118 10.6583 7.45118 10.8536 7.64645Z"
        fill={color}
      />
    </svg>
  );
}

IconReturned.defaultProps = {
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  bgColor: '#FEF8E9',
  color: '#92630C',
};

export default IconReturned;
