export const titleStyles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.600',
};

export const itemStyles = {
  display: 'flex',
  alignItems: 'center',
  h: '32px',
};

export const keyStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
};

export const subValueStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
  display: 'flex',
  justifyContent: 'end',
  marginBottom: '8px',
};

export const valueStyles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
};

export const mobileKeyStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
  textAlign: 'left',
};

export const mobileValueStyles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
  textAlign: 'left',
  ml: 'auto',
  mr: '0',
};
