// @flow
import React, { useState, useRef, useEffect } from 'react';
import LogRocket from 'logrocket';
import {
  Box,
  Text,
  /* useMediaQuery, */ VStack,
  useToast,
  DrawerFooter,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { IconCheck, IconCamera } from '@icons';

import { Icon12Close } from '@core/components/Icons/12px';
import * as mitekScienceSDK from './library/mitek-science-sdk';
import BaselaneButton from '../BaselaneButton';
import BaselaneDrawer from '../BaselaneDrawer';
import ButtonCheckCapture from './components/ButtonCheckCapture';

import './styles/mitekOverrides.style.scss';

import {
  viewFinderStyles,
  hintMessageStyles,
  hintMessageTextStyles,
} from './styles/mobileCheckUploader.styles';

import {
  CAPTURE_MODE,
  MITEK_SDK_PATH,
  AUTO_CAPTURE_HINTS,
  CUSTOM_HINTS,
  DEFAULT_CUSTOM_HINT,
  MITEK_EVENTS,
  MITEK_COMMANDS,
  MITEK_SDK_ERROR_CODES,
  DOCUMENT_TYPES,
  AllowedDocumentTypes,
  mitekCaptureOptions,
  getImageStyle,
} from './helpers/mobileCheckUpload.helpers';

type BaselaneMobileCheckUploaderProps = {
  checkFrontImageData: any,
  setCheckFrontImageData: Function,
  checkBackImageData: any,
  setCheckBackImageData: Function,
  handleUnsupportedBrowserError: Function,
  frontRotated180: boolean,
  setFrontRotated180: Function,
  backRotated180: boolean,
  setBackRotated180: Function,
  isRequired?: boolean,
};

function BaselaneMobileCheckUploader({
  checkFrontImageData,
  setCheckFrontImageData,
  checkBackImageData,
  setCheckBackImageData,
  handleUnsupportedBrowserError,
  frontRotated180,
  setFrontRotated180,
  backRotated180,
  setBackRotated180,
  isRequired,
}: BaselaneMobileCheckUploaderProps): any {
  const viewfinderRef = useRef();

  const [isCapturing, setIsCapturing] = useState(false);
  const [currentDocumentType, setCurrentDocumentType] = useState(null);
  const [lastDocumentType, setLastDocumentType] = useState(null);
  const [hintMessage, setHintMessage] = useState(DEFAULT_CUSTOM_HINT);
  const toast = useToast();

  const previewImageStyle =
    currentDocumentType === DOCUMENT_TYPES.CHECK_FRONT
      ? getImageStyle(checkFrontImageData, true)
      : getImageStyle(checkBackImageData, true);

  const isDisabled =
    currentDocumentType === DOCUMENT_TYPES.CHECK_FRONT ? !checkFrontImageData : !checkBackImageData;

  const handleMitekError = (error) => {
    LogRocket.log(`SDK error: ${error}`);

    // leave this in for debugging purposes
    console.debug('Mitek SDK Error:', JSON.stringify(error, 2));
    mitekScienceSDK.cmd(MITEK_COMMANDS.SDK_STOP);
    setIsCapturing(false);
    viewfinderRef.current.close();
    if (error?.find(({ code }) => code === MITEK_SDK_ERROR_CODES.CAMERA_PERMISSION_DENIED)) {
      toast({
        description: 'Check Deposit requires camera access.',
        status: 'error',
        isClosable: true,
      });
    } else if (error?.find(({ code }) => code === MITEK_SDK_ERROR_CODES.WASM_NOT_SUPPORTED)) {
      handleUnsupportedBrowserError();
    }
  };

  const handleMitekCameraDisplayStarted = () => {
    LogRocket.log('Camera started');
    setHintMessage(CUSTOM_HINTS[currentDocumentType]?.firstMessage);
  };

  const initializeApplicableImageData = (doctype) => {
    if (doctype === DOCUMENT_TYPES.CHECK_FRONT) {
      LogRocket.log('Initial front image data');
      setCheckFrontImageData(null);
      setFrontRotated180(false);
    } else {
      LogRocket.log('Initial back image data');
      setCheckBackImageData(null);
      setBackRotated180(false);
    }
  };

  const handleViewfinderClose = () => {
    LogRocket.log('Closing camera');
    mitekScienceSDK.cmd(MITEK_COMMANDS.SDK_STOP);
    setIsCapturing(false);
    setLastDocumentType(currentDocumentType);
    setCurrentDocumentType(null);
    setHintMessage(DEFAULT_CUSTOM_HINT);
    viewfinderRef?.current?.close();
    LogRocket.log(`viewFinderRef: ${viewfinderRef}`);
  };

  const onCapture = (documentType: AllowedDocumentTypes) => {
    LogRocket.log(`Image captured with type: ${documentType}`);
    setCurrentDocumentType(documentType);
  };

  const handleRetakePhoto = () => {
    LogRocket.log('Retake photo started');
    initializeApplicableImageData(currentDocumentType);
    mitekScienceSDK.cmd(MITEK_COMMANDS.SDK_STOP);
    setIsCapturing(false);
    setLastDocumentType(currentDocumentType);
    setCurrentDocumentType(null);
  };

  const handleMitekFrameProcessingStarted = () => {
    setIsCapturing(true);
  };

  const handleMitekFrameProcessingFeedback = (result) => {
    LogRocket.log(`SDK processing feedkback: ${result}`);

    const hint = result.key;
    if (hint) {
      const message =
        hint === 'MITEK_ERROR_FOUR_CORNER'
          ? CUSTOM_HINTS[currentDocumentType]?.fourCornerMessage
          : AUTO_CAPTURE_HINTS[hint];
      setHintMessage(message);
    }
  };

  const handleMitekFrameProcessingResult = (result) => {
    if (currentDocumentType === DOCUMENT_TYPES.CHECK_FRONT) {
      LogRocket.log(`SDK found front of check: ${result}`);
      setCheckFrontImageData(result.response.imageData);
    } else {
      LogRocket.log(`SDK found back of check: ${result}`);
      setCheckBackImageData(result.response.imageData);
    }
    setHintMessage(null);
  };

  const applyMitekHandlers = () => {
    mitekScienceSDK?.on(MITEK_EVENTS.SDK_ERROR, handleMitekError);
    mitekScienceSDK?.on(MITEK_EVENTS.CAMERA_DISPLAY_STARTED, handleMitekCameraDisplayStarted);
    mitekScienceSDK?.on(MITEK_EVENTS.FRAME_PROCESSING_STARTED, handleMitekFrameProcessingStarted);
    mitekScienceSDK?.on(MITEK_EVENTS.FRAME_PROCESSING_FEEDBACK, handleMitekFrameProcessingFeedback);
    mitekScienceSDK?.on(MITEK_EVENTS.FRAME_CAPTURE_RESULT, handleMitekFrameProcessingResult);
  };

  const onOpenViewfinder = () => {
    LogRocket.log('Open viewfinder');
    viewfinderRef?.current?.open();
    LogRocket.log(`viewFinderRef: ${viewfinderRef}`);
    // this is apparently needed for certain browsers that
    // won't allow the video to start automatically.
    // I put it here just in case it makes a difference.
    document
      .querySelector('div video[autoplay="true"]')
      .play()
      .then(() => null)
      .catch((e) => LogRocket.log(`Video autoplay error: ${e}`));
  };

  const handleCaptureClick = (documentType: AllowedDocumentTypes) => {
    onOpenViewfinder();
    onCapture(documentType);
  };

  const handleRetakeClick = (documentType: AllowedDocumentTypes) => {
    initializeApplicableImageData(documentType);
    onOpenViewfinder();
    onCapture(documentType);
  };

  const handleImageRotation = (type) => {
    if (type === DOCUMENT_TYPES.CHECK_FRONT) {
      setFrontRotated180(!frontRotated180);
    } else {
      setBackRotated180(!backRotated180);
    }
  };

  useEffect(() => {
    // viewfinder closed, nothing to do here
    if (!viewfinderRef?.current || !viewfinderRef.current.isOpen) return;

    // user is re-taking the image, intialize the state
    if (!currentDocumentType && lastDocumentType) {
      setCurrentDocumentType(lastDocumentType);
      setLastDocumentType(null);
      return;
    }

    // no document type chosen, can't capture
    if (!currentDocumentType) return;

    // already capturing, no need to start it again
    if (isCapturing) return;

    // start capturing the image
    applyMitekHandlers();
    setHintMessage(DEFAULT_CUSTOM_HINT);
    mitekScienceSDK.cmd(MITEK_COMMANDS.CAPTURE_AND_PROCESS_FRAME, {
      mode: CAPTURE_MODE,
      documentType: currentDocumentType,
      mitekSDKPath: MITEK_SDK_PATH,
      options: mitekCaptureOptions,
    });
  }, [currentDocumentType]);

  useEffect(() => {
    return () => {
      mitekScienceSDK.cmd(MITEK_COMMANDS.SDK_STOP);
    };
  }, []);

  return (
    <>
      <VStack {...{ w: '100%', gap: 0 }}>
        {Object.keys(DOCUMENT_TYPES).map((type) => {
          let name = type.split('CHECK_').join('').toLowerCase();
          name = name[0].toUpperCase() + name.substring(1);

          return (
            <ButtonCheckCapture
              {...{
                key: `check-button-${name}`,
                label: `${name} of Check`,
                ariaButtonDescription: `Capture image of the ${name.toLowerCase()} of a check.`,
                imageData:
                  type === DOCUMENT_TYPES.CHECK_FRONT ? checkFrontImageData : checkBackImageData,
                onCaptureClick: () => {
                  handleCaptureClick(type);
                },
                onRetakeClick: () => {
                  handleRetakeClick(type);
                },
                onImageRotation: () => {
                  handleImageRotation(type);
                },
                frontRotated180,
                setFrontRotated180,
                backRotated180,
                setBackRotated180,
                isFront: type === DOCUMENT_TYPES.CHECK_FRONT,
              }}
              isRequired={isRequired}
            />
          );
        })}
      </VStack>

      <BaselaneDrawer
        ref={viewfinderRef}
        title={
          currentDocumentType === DOCUMENT_TYPES.CHECK_FRONT ? 'Front of Check' : 'Back of Check'
        }
        showBackButton
        showCloseButton
        closeEvent={() => {
          initializeApplicableImageData(currentDocumentType);
          handleViewfinderClose();
        }}
        hideHeader
      >
        <Box
          // temporary solution for backgroundImage issue with chakra https://github.com/chakra-ui/chakra-ui/issues/7548
          {...{
            id: mitekCaptureOptions.videoContainerId,
            ...viewFinderStyles,
            backgroundSize: previewImageStyle.backgroundSize,
            backgroundPosition: `center center !important`,
            backgroundRepeat: 'no-repeat !important',
          }}
          style={previewImageStyle}
        />
        {hintMessage && (
          <Box {...hintMessageStyles}>
            <Text {...hintMessageTextStyles}>{hintMessage}</Text>
          </Box>
        )}
        <DrawerFooter height="56px" padding="8px 16px">
          <Flex width="100%" gap="12px">
            <BaselaneButton
              variant="text"
              palette="primary"
              size="md"
              color="brand.blue.800A"
              leftIcon={<Icon12Close color="brand.blue.800A" />}
              onClick={() => {
                initializeApplicableImageData(currentDocumentType);
                if (currentDocumentType === DOCUMENT_TYPES.CHECK_FRONT) {
                  setFrontRotated180(false);
                  setCheckFrontImageData(null);
                } else {
                  setBackRotated180(false);
                  setCheckBackImageData(null);
                }
                handleViewfinderClose();
              }}
            >
              Cancel
            </BaselaneButton>
            <Spacer />
            <BaselaneButton
              variant="outline"
              palette="neutral"
              isDisabled={isDisabled}
              onClick={handleRetakePhoto}
              leftIcon={<IconCamera />}
              size="md"
            >
              Retake photo
            </BaselaneButton>
            <BaselaneButton
              variant="filled"
              palette="primary"
              isDisabled={isDisabled}
              size="md"
              leftIcon={<IconCheck />}
              onClick={handleViewfinderClose}
            >
              Done
            </BaselaneButton>
          </Flex>
        </DrawerFooter>
      </BaselaneDrawer>
    </>
  );
}

BaselaneMobileCheckUploader.defaultProps = {
  isRequired: false,
};

export default BaselaneMobileCheckUploader;
