export const headerContainerStyles = {
  bg: 'brand.neutral.white',
  maxHeight: '64px',
  h: '64px',
  px: 4,
  py: 1.5,
  spacing: 0,
  zIndex: 11,
  borderBottomWidth: '1px',
  borderColor: 'brand.neutral.200',
};

export const titleStyles = {
  color: 'brand.neutral.900',
  size: 'data-xl',
  fontWeight: 'semibold',
  mr: '24px',
};

export const subTitleStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  alignSelf: 'flex-end',
};
