import stripCurrency from '@core/utils/stripCurrency';

export const formatDataForApi = (values) => {
  const {
    city,
    zipcode: postalCode,
    state,
    address: street,
    unit,
    propertyId,
    unitId,
    categoryId: tagId,
    transfer: { transferAmount: amount = '', selectedAccountFrom = {}, description = '' },
    routingNumber,
    recipientName: name,
    accountNumber,
  } = values;

  return {
    recipientAddress: {
      city,
      country: 'US',
      postalCode,
      state,
      street,
      unit,
    },
    tagId: tagId === '' ? null : tagId,
    propertyId: propertyId === '' ? null : propertyId,
    unitId: unitId === '' ? null : unitId,
    amount: stripCurrency(amount),
    fromTransferAccountId: selectedAccountFrom?.accountId,
    routingNumber,
    name,
    accountNumber,
    description,
  };
};

export const recipientFormInitialValues = {
  recipientName: '',
  routingNumber: '',
  accountNumber: '',
  retypeAccountNumber: '',
  bankName: '',
};

export const addressFormInitialValues = {
  address: '',
  unit: '',
  city: '',
  state: '',
  zipcode: '',
};

export const propertyAndCategoryInitialValues = {
  propertyId: '',
  propertyUnitId: '',
  unitId: '',
  categoryId: '',
};
