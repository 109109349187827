import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Text, Stack } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import StripeButton from '@shared/components/StripeButton';
import BaselaneKYCForm from '@shared/components/BaselaneKYCForm';
import KYCReturnStatusDrawer from '@shared/components/BaselaneKYCForm/components/KYCReturnStatusDrawer';
import useReturnStatus from '@shared/components/BaselaneKYCForm/hooks/useReturnStatus';
import VouchedIdentificationDrawer from '@shared/components/VouchedIdentificationDrawer';
import { KYC_STATUS } from './helpers/idVerification.helpers';
import {
  idVerificationContainerStyles,
  idVerificationTitleStyles,
  idVerificationDescriptionStyles,
  statusContainer,
  badgeTextStyles,
} from './styles/idVerification.styles';

type IDVerificationProps = {
  userKycStatus: string,
  userKyc: Object,
  user: Object,
  refetchUser: Function,
};

const IDVerification = ({ userKycStatus, userKyc, user, refetchUser }: IDVerificationProps) => {
  const status = userKycStatus ?? 'UNVERIFIED';
  const { description, icon: Icon, badgeText, badgeTextColor, showLearnMoreButton } = KYC_STATUS[
    status
  ];
  const {
    kycReturnStatusDrawerRef,
    handleKYCReturnStatusDrawerClose,
    handleKYCReturnStatusDrawerOpen,
  } = useReturnStatus();

  // Vouched Ref
  const vouchedDrawerRef = useRef();
  const handleVouchedDrawerOpen = () => vouchedDrawerRef?.current?.open();

  return (
    <>
      <KYCReturnStatusDrawer
        kycStatus={status}
        drawerRef={kycReturnStatusDrawerRef}
        handleKYCReturnStatusDrawerClose={handleKYCReturnStatusDrawerClose}
      />

      <Box {...idVerificationContainerStyles(isMobile)}>
        <Text {...idVerificationTitleStyles}>Identity Verification</Text>
        <Text {...idVerificationDescriptionStyles}>{description}</Text>
        {status === 'UNVERIFIED' ? (
          <BaselaneKYCForm
            button={{
              text: 'Start Verification',
              variant: 'filled',
              palette: 'primary',
              size: 'md',
            }}
            kycStatus={status}
            handleKYCReturnStatusDrawerOpen={handleKYCReturnStatusDrawerOpen}
            handleVouchedDrawerOpen={handleVouchedDrawerOpen}
          />
        ) : (
          <Stack direction="row" {...statusContainer}>
            <Icon />
            <Text {...badgeTextStyles(badgeTextColor)}>{badgeText}</Text>
            {status === 'VOUCH_PENDING' && (
              <BaselaneButton variant="filled" palette="primary" onClick={handleVouchedDrawerOpen}>
                Verify ID
              </BaselaneButton>
            )}

            {showLearnMoreButton && (
              <BaselaneButton
                variant="outline"
                palette="neutral"
                onClick={handleKYCReturnStatusDrawerOpen}
              >
                Learn More
              </BaselaneButton>
            )}

            {status === 'ADDITIONAL_INFO' && <StripeButton size="sm" text="Start" />}
          </Stack>
        )}

        <VouchedIdentificationDrawer
          {...{
            userKyc,
            user,
            refetchUser,
            vouchedDrawerRef,
          }}
        />
      </Box>
    </>
  );
};

export default IDVerification;
