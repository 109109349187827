import moment from 'moment';
import type { BarChartDataSet, CashFlowByMonth } from '../../CashFlow/types';

function transformToBarChartDataset(data: CashFlowByMonth[]): BarChartDataSet[] {
  return data
    .map(({ date, expense = 0, income = 0 }) => {
      const netIncomeNumber = income + expense;

      return {
        month: moment(date).format('MMM YYYY').toUpperCase(),
        monthLabel: moment(date).format('MMM YYYY'),
        discriminator: moment(date).format('YYYY-MM'),
        revenues: income,
        expenses: expense * -1, // TODO: Remove abs when BE return only positive numbers
        netIncome: parseFloat(netIncomeNumber.toFixed(2), 10),
      };
    })
    .sort((a, b) => new Date(a.month) - new Date(b.month));
}

export default transformToBarChartDataset;
