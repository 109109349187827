export const t1Search = ({ options, searchTerm, search }) => {
  return options?.filter((item) => {
    return (
      item[searchTerm[0]]?.toLowerCase()?.indexOf(search.toLowerCase()) !== -1 ||
      (item[searchTerm[1]] &&
        item[searchTerm[1]]?.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    );
  });
};

export const t2Search = ({ options, search }) => {
  return options?.filter((item) => {
    const isParent = Array.isArray(item.searchValues);
    return isParent
      ? item.searchValues?.some((value) => value.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      : item.searchValues.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });
};
