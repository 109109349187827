export const headingTextStyles = {
  textStyle: 'headline-xl',
  color: 'brand.neutral.900',
};

export const bodyTextSmallLightTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
};

export const bodyTextStyles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.900',
};

export const bodyTextMediumBoldStyles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
};

export const bodyMdTextMediumBoldStyles = {
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
};
