import { transformRecipientPaymentMethods, steps } from './formHelpers';

const payeePaymentMethods = (values) => {
  let paymentMethods = {};
  if (values?.paymentMethodType === 'ACH' || values?.paymentMethodId === 'achPaymentMethods') {
    paymentMethods = {
      achPaymentMethods: {
        accountType: values?.accountType || '',
        accountHolderName: values?.accountHolderName || '',
        routingNumber: values?.routingNumber || '',
        accountNumber: values?.accountNumber || '',
        type: 'ACH',
      },
    };
  }
  if (
    values?.paymentMethodType === 'WIRE_TRANSFER' ||
    values?.paymentMethodId === 'wirePaymentMethods'
  ) {
    paymentMethods = {
      wirePaymentMethods: {
        accountHolderName: values?.accountHolderName || '',
        routingNumber: values?.routingNumber || '',
        accountNumber: values?.accountNumber || '',
        address: {
          street: values?.address || '',
          city: values?.city || '',
          state: values?.state || '',
          postalCode: values?.zipcode || '',
          unit: values?.unit || '',
          country: 'US',
        },
        type: 'WIRE_TRANSFER',
      },
    };
  }
  if (
    values?.paymentMethodType === 'CHECK_PAYMENT' ||
    values?.paymentMethodId === 'checkPaymentMethods'
  ) {
    paymentMethods = {
      checkPaymentMethods: {
        name: values?.name || '',
        address: {
          street: values?.address || '',
          city: values?.city || '',
          state: values?.state || '',
          postalCode: values?.zipcode || '',
          unit: values?.unit || '',
          country: 'US',
        },

        type: 'CHECK_PAYMENT',
      },
    };
  }
  return paymentMethods;
};

export const newPayeeObject = (values) => {
  const newPayee = {
    name: values?.name,
    email: values?.email,
    type: values?.type,
    paymentMethods: payeePaymentMethods(values),
  };
  delete newPayee?.paymentMethods?.achPaymentMethods?.bankName;
  delete newPayee?.paymentMethods?.wirePaymentMethods?.bankName;
  delete newPayee?.paymentMethods?.checkPaymentMethods?.bankName;
  if (newPayee?.paymentMethods?.achPaymentMethods) {
    delete newPayee?.paymentMethods?.achPaymentMethods?.address;
  }
  return newPayee;
};

export const setNewPayeeFormValuesHelper = (
  res,
  setFieldValue,
  updateRecipient,
  setIsCurrentStepLoading,
  setIsCurrentStepDisabled,
  setSelectedPaymentMethod,
  setCurrentStep,
  currentStep
) => {
  updateRecipient(transformRecipientPaymentMethods(res.data.addPayee));
  const newPaymentMethodId =
    res.data.addPayee?.paymentMethods?.achPaymentMethods?.id ||
    res.data.addPayee?.paymentMethods?.wirePaymentMethods?.id ||
    res.data.addPayee?.paymentMethods?.checkPaymentMethods?.id;
  setFieldValue('paymentMethodId', newPaymentMethodId);
  const newSelectedPaymentMethod = transformRecipientPaymentMethods(res.data.addPayee)
    ?.paymentMethods?.flatMap((method) => method.items)
    .filter((m) => m?.id === newPaymentMethodId);
  setFieldValue('payeeId', res.data.addPayee?.id);
  setFieldValue('scheduledPaymentType', 'one-time');
  setFieldValue('recurringPayment.startDate', new Date());
  setFieldValue('recurringPayment.repeatEvery.value', 1);
  setFieldValue('recurringPayment.repeatEvery.unit', 'month');
  setFieldValue('recurringPayment.repeatOnMonthDate', '28');
  setFieldValue('recurringPayment.repeatOnWeekday', 'monday');
  setFieldValue('recurringPayment.endBy', 'manual');
  setFieldValue('recurringPayment.endDate', new Date());
  setFieldValue('recurringPayment.numberOfPayments', 12);
  setIsCurrentStepLoading(false);
  setIsCurrentStepDisabled(false);
  setSelectedPaymentMethod(newSelectedPaymentMethod[0]);
  setCurrentStep(steps.SELECT_PAYMENT_METHOD);
};
