// @flow
import React from 'react';
import AddBankAccount from '@core/components/AddBankAccount';

type DrawerActionProps = {
  banks: Array<Object>,
  handleSuccessFn: Function,
  plaidText: Object,
  setPlaidText: Function,
  onPlaidConfirmationClose?: Function,
  isLoading: boolean,
};

function DrawerAction({
  banks,
  handleSuccessFn,
  plaidText,
  setPlaidText,
  onPlaidConfirmationClose,
  isLoading,
}: DrawerActionProps): any {
  return (
    <AddBankAccount
      titleText={plaidText.error ? 'Connect Different Account' : 'Start'}
      handleSuccessFn={handleSuccessFn}
      setPlaidText={setPlaidText}
      size="md"
      variant={!plaidText.error && 'filled'}
      palette={!plaidText.error && 'primary'}
      hasRightChevronIcon
      banks={banks}
      onPlaidConfirmationClose={onPlaidConfirmationClose}
      isDisabled={isLoading}
    />
  );
}

DrawerAction.defaultProps = {
  onPlaidConfirmationClose: () => {},
};

export default DrawerAction;
