import React from 'react';

const circleConfig = {
  SMALL: {
    w: '24',
    h: '24',
    circleSize: '12',
    pathd:
      'M17.1382 8.19526C17.3985 8.45561 17.3985 8.87772 17.1382 9.13807L10.4715 15.8047C10.2111 16.0651 9.78903 16.0651 9.52868 15.8047L6.86201 13.1381C6.60166 12.8777 6.60166 12.4556 6.86201 12.1953C7.12236 11.9349 7.54447 11.9349 7.80482 12.1953L10.0001 14.3905L16.1953 8.19526C16.4557 7.93491 16.8778 7.93491 17.1382 8.19526Z',
  },
  LARGE: {
    w: '32',
    h: '32',
    circleSize: '16',
    pathd:
      'M23.7071 10.2929C24.0976 10.6834 24.0976 11.3166 23.7071 11.7071L13.7071 21.7071C13.3166 22.0976 12.6834 22.0976 12.2929 21.7071L8.29289 17.7071C7.90237 17.3166 7.90237 16.6834 8.29289 16.2929C8.68342 15.9024 9.31658 15.9024 9.70711 16.2929L13 19.5858L22.2929 10.2929C22.6834 9.90237 23.3166 9.90237 23.7071 10.2929Z',
  },
};

type IconCurrentCircleProps = { bgfill?: string, fillColor?: string, bigIcon?: boolean };

function IconCurrentCircle({ bgfill, fillColor, bigIcon }: IconCurrentCircleProps) {
  const { w, h, circleSize, pathd } = bigIcon ? circleConfig.LARGE : circleConfig.SMALL;
  return (
    <svg
      width={w}
      height={h}
      fill="none"
      viewBox={`0 0 ${w} ${h}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={circleSize} cy={circleSize} r={circleSize} fill={bgfill} />
      <path fillRule="evenodd" clipRule="evenodd" d={pathd} fill={fillColor} />
    </svg>
  );
}

IconCurrentCircle.defaultProps = {
  bgfill: '#D9F5CA',
  fillColor: '#398311',
  bigIcon: false,
};

export default IconCurrentCircle;
