// @flow
import React, { useContext, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { Spinner, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';

import { GET_LEASE_METADATA, GET_LEASE_RECEIVING_BA_ACH } from '@pages/LeasesPage/queries';
import {
  GET_LEASE_BANKS,
  GET_UPDATE_PLAID_LINK_TOKEN,
  UPDATE_BANK_ACCOUNT_FOR_LEASES_AND_INVOICES,
} from '@core/apollo/queries';

import getBreakPoints from '@core/utils/getBreakPoints';
import { getConnectedAccountsFilterOptions } from '@core/contexts/TransactionContext/helpers';
import AddBankAccount from '@core/components/AddBankAccount';
import UserContext from '@contexts/UserContext';
import useTwoFactor from '@shared/components/TwoFactorVerificationPopUp/hooks/useTwoFactor';
import { resyncLinkStyles } from '@pages/LeasesPage/LeaseSection/styles/receivingBankAccount.styles';
import AlertHeader from '../AlertHeader';
import AlertFooter from '../AlertFooter';
import BaselaneAlert from '../BaselaneAlert';
import BaselaneReceivingAccounts from '../BaselaneReceivingAccounts';
import BaselaneButton from '../BaselaneButton';
import BaselaneDrawer from '../BaselaneDrawer';
import PropertyList from './PropertyList';
import TwoFactorVerificationPopUp from '../TwoFactorVerificationPopUp';
import { getSortedLeaseData } from '../../helpers/leases.helpers';
import { getFlattenedAccounts, validateFormik } from './helpers/updateBankAccountDrawer.helpers';
import { spinnerStyles } from './styles/updateBankAccountDrawer.styles';

function UpdateBankAccountDrawer() {
  const { isMax576 } = getBreakPoints();

  const formikRef = useRef();
  const unsavedChangesAlertRef = useRef();
  const unsavedChangesAlertCancelRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  // account phone OTP double check
  const { user } = useContext(UserContext);
  const { states, stateFunctions } = useTwoFactor(false);
  const {
    onOTPPopupOpen,
    onOTPPopupClose,
    setOTPErrorCode,
    handleSendText,
    handleVerifyOtp,
  } = stateFunctions;

  const [selectedAccount, setSelectedAccount] = useState();
  const [receivingPlaidLinkToken, setReceivingPlaidLinkToken] = useState();

  const { leaseIds: stateLeaseIds } = location.state ?? {};
  const hasStateLeaseIds = stateLeaseIds?.length > 0;

  const {
    loading: isLeaseMetaDataLoading,
    error: hasLeaseMetaDataError,
    data: leaseMetaData,
  } = useQuery(GET_LEASE_METADATA, {
    fetchPolicy: 'cache-and-network',
    skip: hasStateLeaseIds,
  });

  const { leaseCollectingRentOutsideBaselane } = leaseMetaData?.leaseMetaData ?? {};
  const hasLeaseCollectingRentOutsideBaselane = leaseCollectingRentOutsideBaselane?.length > 0;
  const leaseIds = stateLeaseIds ?? leaseCollectingRentOutsideBaselane;

  const {
    loading: isLeaseLoading,
    error: hasLeaseError,
    data: leaseData,
    refetch: refetchLeaseData,
  } = useQuery(GET_LEASE_RECEIVING_BA_ACH, {
    variables: {
      input: {
        state: ['SCHEDULED'],
        leaseIds,
      },
    },
    skip: hasStateLeaseIds ? false : !hasLeaseCollectingRentOutsideBaselane,
  });

  const {
    loading: banksLoading,
    error: banksError,
    data: banksData,
    refetch: refetchBankAccounts,
  } = useQuery(GET_LEASE_BANKS, {
    variables: {
      isConnectedBank: true,
      accountType: 'depository',
      isConnectedAccount: true,
      accountStatus: 'Open',
      accountOwnershipType: 'SELF',
    },
    fetchPolicy: 'cache-and-network',
  });

  const [
    updateBankAccountForLeasesAndInvoices,
    { loading: isUpdateBAForLeasesAndInvoicesLoading },
  ] = useMutation(UPDATE_BANK_ACCOUNT_FOR_LEASES_AND_INVOICES);

  const [getResyncToken] = useLazyQuery(GET_UPDATE_PLAID_LINK_TOKEN);

  if (hasLeaseError || hasLeaseMetaDataError || banksError) return null;

  const sortedLeases = getSortedLeaseData({ data: leaseData?.leases?.leases });
  const connectedAccounts = getConnectedAccountsFilterOptions(banksData);
  const accounts = connectedAccounts?.filter((ac) => !!ac.items.length);
  const connectedAccountsArr = getFlattenedAccounts(accounts);

  // Toasters
  const toast = useToast();
  const showSuccessToast = () =>
    toast({
      description: 'Updated receiving bank accounts',
      status: 'success',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });
  const showErrorToast = () =>
    toast({
      description: 'Something went wrong. Please try again',
      status: 'error',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const handleDrawerClose = () => {
    const pathnameArr = location.pathname.split('/');
    navigate({ pathname: `/${pathnameArr[1]}` });
  };

  const handleSubmit = async () => {
    try {
      await updateBankAccountForLeasesAndInvoices({
        variables: {
          modifyOnlyRentAndFeeAccount: true,
          newBankAccountId: selectedAccount?.id,
          leaseIds,
        },
        update: (cache) => {
          const baselaneAccounts = accounts.find((acc) => acc.id === 'baselane_account')?.items;
          const updatingToBaselaneAcc = baselaneAccounts.find(
            (acc) => acc.id === selectedAccount?.id
          );

          // if updating to a baselane acc, leaseCollectingRentOutsideBaselane should be empty
          if (updatingToBaselaneAcc) {
            cache.writeQuery({
              query: GET_LEASE_METADATA,
              data: {
                leaseMetaData: {
                  leaseCollectingRentOutsideBaselane: [],
                },
              },
            });
          } else {
            refetchLeaseData();
          }
        },
      });
      showSuccessToast();
    } catch (error) {
      console.error(error);
      showErrorToast();
    } finally {
      handleDrawerClose();
    }
  };

  const handleDisconnectedAccount = (id) => {
    const disconnectedAccount = connectedAccountsArr.find((acc) => acc?.id === id);
    if (disconnectedAccount?.connectionState === 'ITEM_LOGIN_REQUIRED') {
      getResyncToken({
        variables: { bankAccountId: id },
      }).then((res) => {
        setReceivingPlaidLinkToken(res?.data?.updatePlaidLinkToken);
      });
    }
  };

  const handleSendTextSuccess = () => {
    setOTPErrorCode(false);
  };

  const handleSendTextFail = (error) => {
    setOTPErrorCode(error);
  };

  const handleVerifyOtpSuccess = () => {
    onOTPPopupClose();
    handleSubmit();
  };

  // otp
  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP: () => handleSendText(user.phoneNumber, true, handleSendTextSuccess, handleSendTextFail),
    phoneNumber: user.phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleVerifyOtp({ recipient: user.phoneNumber, code: otpCode }, handleVerifyOtpSuccess),
  };

  // drawer size is wider within the rc details
  let drawerSize = isMax576 ? 'newdrawerfull' : 'newdrawersm';
  if (location.pathname === '/leases/updateaccounts') {
    drawerSize = isMax576 ? 'full' : 'md';
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ rentAndFeesBankAccountId: selectedAccount?.id }}
      onSubmit={handleSubmit}
      validate={(values) => validateFormik(accounts, values)}
    >
      {({ values, errors, dirty, setFieldValue, setFieldTouched, validateForm }) => {
        /* setFieldValue doesn't update dirty check properly outside Formik
           adding below func within the Formik to get properly updated dirty check */
        const checkUnsavedChanges = () => {
          if (dirty) {
            onAlertOpen();
          } else {
            handleDrawerClose();
          }
        };

        return (
          <>
            <BaselaneDrawer
              isOpen
              size={drawerSize}
              title="Update receiving bank account(s)"
              footer={
                <>
                  <BaselaneButton
                    size="md"
                    variant="outline"
                    palette="neutral"
                    onClick={checkUnsavedChanges}
                  >
                    Cancel
                  </BaselaneButton>
                  <BaselaneButton
                    size="md"
                    variant="filled"
                    palette="primary"
                    width="100%"
                    ml={1.5}
                    flex={1}
                    onClick={onOTPPopupOpen}
                    isLoading={isUpdateBAForLeasesAndInvoicesLoading}
                    isDisabled={!dirty || Object.keys(errors).length}
                  >
                    Save changes
                  </BaselaneButton>
                </>
              }
              closeEvent={checkUnsavedChanges}
              onOverlayClick={checkUnsavedChanges}
              closeOnOverlayClick={false}
              newDesignDrawer
            >
              {isLeaseLoading || isLeaseMetaDataLoading || banksLoading ? (
                <Spinner {...spinnerStyles} />
              ) : (
                sortedLeases?.length > 0 && (
                  <Stack gap={4}>
                    <PropertyList {...{ sortedLeases }} />
                    <BaselaneReceivingAccounts
                      {...{
                        accounts,
                        selectedAccount,
                        onSelectAccount: async (id) => {
                          const updatedAccount = connectedAccountsArr.find((acc) => acc.id === id);
                          setSelectedAccount(updatedAccount);
                          await setFieldValue('rentAndFeesBankAccountId', id);
                          setFieldTouched('rentAndFeesBankAccountId', true);
                          handleDisconnectedAccount(id);
                        },
                        refetchBankAccounts,
                        hideExternalAccounts: true,
                        from: location.pathname,
                        // error message: same logic as RC edit/create RC
                        errorMessage:
                          errors.rentAndFeesBankAccountId === 'Disconnected' ? (
                            <Text>
                              {' '}
                              Account connection expired.{' '}
                              {receivingPlaidLinkToken && (
                                <AddBankAccount
                                  bankAccId={values?.rentAndFeesBankAccountId}
                                  mode="RESYNC"
                                  titleText="Resync your account"
                                  size="sm"
                                  variant="link"
                                  palette="danger"
                                  showCustomButton
                                  hasIconLock={false}
                                  hasRightChevronIcon={false}
                                  styles={resyncLinkStyles}
                                  containerStyles={{ as: 'span' }}
                                  banks={banksData.bank}
                                  state="ITEM_LOGIN_REQUIRED"
                                  handleSuccessFn={(res) => {
                                    const { connectionState } =
                                      res?.data?.reSyncExternalBankAccount ?? {};
                                    if (connectionState === 'CONNECTED') {
                                      refetchBankAccounts().then(() => {
                                        validateForm();
                                      });
                                    }
                                  }}
                                  refetchFunction={() => {
                                    refetchBankAccounts().then(() =>
                                      formikRef?.current?.validateForm()
                                    );
                                  }}
                                  updateLinkToken={receivingPlaidLinkToken}
                                />
                              )}{' '}
                              {!receivingPlaidLinkToken && 'Resync '}
                              to receive payments.
                            </Text>
                          ) : (
                            errors.rentAndFeesBankAccountId
                          ),
                      }}
                    />
                    <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />
                  </Stack>
                )
              )}
            </BaselaneDrawer>

            <BaselaneAlert
              leastDestructiveRef={unsavedChangesAlertRef}
              isOpen={isAlertOpen}
              onClose={onAlertClose}
              header={<AlertHeader title="You Have Unsaved Changes" />}
              body="Are you sure you want to exit without saving?"
              footer={
                <AlertFooter
                  cancelRef={unsavedChangesAlertCancelRef}
                  leftButtonEvent={onAlertClose}
                  rightButtonEvent={handleDrawerClose}
                />
              }
            />
          </>
        );
      }}
    </Formik>
  );
}

export default UpdateBankAccountDrawer;
