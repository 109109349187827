const formatPhoneNumber = (phoneNumberString, useMask = false, areaCode = false) => {
  if (!phoneNumberString) return null;

  const cleaned = phoneNumberString.toString().replace(/\D/g, '');
  const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    if (areaCode) {
      const areaCodeMatch = `(${match[1]})`;

      return `+1 ${areaCodeMatch} ${match[2]}-${match[3]}`;
    }

    const firstMatch = useMask ? match[1].replace(/\S/g, 'x') : match[1];
    const secondMatch = useMask ? match[2].replace(/\S/g, 'x') : match[2];

    return `${firstMatch}-${secondMatch}-${match[3]}`;
  }
  return null;
};

export default formatPhoneNumber;
