import { gql } from '@apollo/client';

export const CASHFLOW_QUERY = gql`
  query CashFlow($input: CashFlowInput) {
    cashFlow(input: $input) {
      totalDocuments
      summary {
        netCashFlow
        totalExpenseAmount
        totalRevenueAmount
        categorizedTrxCount
        uncategorizedTrxCount
      }
      byMonth {
        date
        income
        expense
      }
      byCategory {
        name
        subCategories {
          name
          type
          amount
        }
        amount
      }
      byCategoryMonth {
        date
        cashFlowStatement {
          netOperatingIncome
          netCashFlow
          totalInflowOutflow
        }
        categories {
          name
          subCategories {
            name
            type
            amount
          }
          amount
        }
      }
    }
  }
`;

export const CASHFLOW_SUMMARY_QUERY = gql`
  query CashFlowSummary($input: CashFlowInput) {
    cashFlow(input: $input) {
      summary {
        netCashFlow
        totalExpenseAmount
        totalRevenueAmount
      }
    }
  }
`;

export const CASHFLOW_QUERY_BY_MONTH = gql`
  query CashFlowByMonth($input: CashFlowInput) {
    cashFlow(input: $input) {
      byMonth {
        date
        income
        expense
      }
    }
  }
`;

export const GET_PRESETS = gql`
  query Presets {
    preset {
      name
      tagId
      uncategorized
    }
  }
`;
