import customTheme from '@core/theme';

export const headingSectionTextStyles = {
  textStyle: 'headline-sm',
};

export const subTextTextStyles = {
  textStyle: 'sm',
  color: customTheme.colors.brand.neutral['700'],
};
