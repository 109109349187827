export const countContainerStyles = {
  fontSize: '3xs',
  fontWeight: 'semibold',
  bg: 'brand.blue.700',
  color: 'brand.neutral.white',
  marginTop: '-16px !important',
  marginLeft: '-22px !important',
  zIndex: '1',
  minWidth: '16px',
  width: 'auto',
  height: '16px',
  maxHeight: '16px',
  borderRadius: '9999px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const countTextStyles = {
  fontSize: '2xs',
  lineHeight: '11px',
  padding: '3px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
