export const drawerStyles = {
  body: ({ isMinXL }) => {
    return {
      p: isMinXL ? '32px 24px' : '40px 32px',
      background: 'brand.neutral.white',
    };
  },
  footer: ({ isMinXL }) => {
    return {
      gap: '16px',
      p: isMinXL ? '16px 32px' : '20px 32px',
      minH: isMinXL ? '72px' : '80px',
    };
  },
  logofooter: ({ isMinXL }) => {
    return {
      p: isMinXL ? '16px 32px' : '20px 32px',
      pb: '24px !important',
      minH: isMinXL ? '72px' : '80px',
      alignItems: 'flex-end',
      justifyContent: 'center',
      boxShadow: 'none',
      border: 'none',
    };
  },
  secondarybtn: {
    borderRadius: '4px',
  },
};
