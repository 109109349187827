import React, { useEffect } from 'react';
import { HStack, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useGate } from 'statsig-react';
import {
  BaselaneButton,
  BaselaneErrorCard,
  BaselaneResponsiveTable,
} from '@core/components/Shared/components';
import * as ROUTES from '@routes';
import { GET_PAYEES } from '@core/apollo/queries';
import SlLoader from '@core/components/Loader';
import clearLocationState from '@core/utils/clearLocationState';

import { NoRecipient } from './NoRecipient';
import { TableHeader } from './RecipientsTable/TableHeader';
import { TableBody } from './RecipientsTable/TableBody';
import { config, DEFAULT_SORT_FIELD, DEFAULT_SORT_DIR } from './helpers';
import { recipientsStyles } from './styles';

export function Recipients() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location || {};
  const { isAddRecipientSuccessful, isDeleteRecipientSuccessful } = state || {};
  const toast = useToast();

  const [searchParams, setSearchParams] = useSearchParams();

  const { value, isLoading } = useGate('transfers_payments_page');

  // feature gate redirect
  if (!value) navigate('/', { replace: true });

  const { data: recipientsData, loading, error, refetch: refetchRecipients } = useQuery(
    GET_PAYEES,
    {
      variables: {
        input: {
          field: searchParams.get('sort') ?? DEFAULT_SORT_FIELD,
          direction: searchParams.get('direction') ?? DEFAULT_SORT_DIR,
        },
      },
      onError: (err) => console.error(err),
    }
  );

  const showSuccessToast = (message) =>
    toast({
      position: 'bottom-left',
      description: message,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  useEffect(() => {
    if (!searchParams.get('direction') || !searchParams.get('sort')) {
      setSearchParams(
        {
          sort: DEFAULT_SORT_FIELD,
          direction: DEFAULT_SORT_DIR,
        },
        { replace: true }
      );
    }
  }, []);

  /**
   * Detect any toasts/messages base on location state
   */
  useEffect(() => {
    if (isDeleteRecipientSuccessful) {
      showSuccessToast(`Your recipient has been deleted successfully.`);
      refetchRecipients();
    }

    if (isAddRecipientSuccessful) {
      showSuccessToast(`Your recipient has been added successfully.`);
      refetchRecipients();
    }

    clearLocationState();
  }, [state]);

  const { payees: recipients } = recipientsData ?? {};
  const hasRecipients = recipients?.length > 0;

  const handleEditRecipientClick = (recipient) => {
    const { id } = recipient;
    navigate(
      {
        pathname: id,
        search: searchParams.toString(),
      },
      { state: { from: location?.pathname } }
    );
  };

  const handleAddRecipientClick = () => {
    navigate({
      pathname: ROUTES.TRANSFERS_RECIPIENT_ADD,
      search: searchParams.toString(),
    });
  };

  const { pageContainerStyles, headerContainerStyles } = recipientsStyles;

  return (
    <>
      {(isLoading || loading) && <SlLoader />}
      {error && <BaselaneErrorCard />}
      {hasRecipients
        ? !isLoading &&
          !loading &&
          !error && (
            <Stack {...pageContainerStyles}>
              <HStack {...headerContainerStyles}>
                <Text textStyle="headline-md">Your Recipients</Text>
                <Spacer />
                <BaselaneButton
                  variant="filled"
                  palette="primary"
                  size="md"
                  onClick={handleAddRecipientClick}
                >
                  Add recipient
                </BaselaneButton>
              </HStack>

              <BaselaneResponsiveTable id="recipient-list" config={config}>
                <TableHeader {...{ searchParams, setSearchParams }} />
                <TableBody {...{ recipients, handleEditRecipientClick }} />
              </BaselaneResponsiveTable>
            </Stack>
          )
        : !isLoading && !loading && !error && <NoRecipient {...{ handleAddRecipientClick }} />}
      <Outlet />
    </>
  );
}
