import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGate } from 'statsig-react';

import {
  EDIT_ENTITY_ACCOUNT,
  EDIT_ENTITY_ADDRESS,
  ADD_VIRTUAL_ACCOUNT,
  ADD_VIRTUAL_CARD,
  ORDER_CHECKBOOK,
} from '@routes';
import { BaselaneMenu } from '@shared/components';
import {
  Icon16AddBankingAccount,
  Icon16AddVirtualCard,
  Icon16Edit,
  Icon16Mail,
  Icon16CheckPayment,
} from '@icons/16px';
import getBreakPoints from '@core/utils/getBreakPoints';
import useUserSignUpExperiment from '@core/hooks/useUserSignUpExperiment';

const MoreMenu = () => {
  const navigate = useNavigate();
  const currLocation = useLocation();
  const { value: isCheckbooksEnabled } = useGate('checkbook_gate');

  const { isMin768 } = getBreakPoints();
  const { isUserEmailUnverified } = useUserSignUpExperiment();

  const moreMenuListItems = [
    {
      id: 'entity_name',
      name: 'Edit entity name',
      icon: <Icon16Edit />,
      onClick: () => navigate({ pathname: `${currLocation.pathname}/${EDIT_ENTITY_ACCOUNT}` }),
    },
    {
      id: 'mailing_address',
      name: 'Edit mailing address',
      icon: <Icon16Mail />,
      onClick: () =>
        navigate({
          pathname: `${currLocation.pathname}/${EDIT_ENTITY_ACCOUNT}/${EDIT_ENTITY_ADDRESS}`,
        }),
      hasDivider: isMin768 && isCheckbooksEnabled,
    },
  ];

  const orderCheckbookListItem = {
    id: 'order_checkbook',
    name: 'Order a checkbook',
    icon: <Icon16CheckPayment />,
    onClick: () => navigate({ pathname: `${currLocation.pathname}/${ORDER_CHECKBOOK}` }),
    hasDivider: !isMin768,
    isDisabled: isUserEmailUnverified,
  };

  if (!isMin768) {
    const virtualMenuItems = [
      {
        id: 'virtual_account',
        name: 'Add virtual account',
        icon: <Icon16AddBankingAccount />,
        onClick: () => navigate({ pathname: `${currLocation.pathname}/${ADD_VIRTUAL_ACCOUNT}` }),
      },
      {
        id: 'virtual_card',
        name: 'Add virtual card',
        icon: <Icon16AddVirtualCard />,
        onClick: () => navigate({ pathname: `${currLocation.pathname}/${ADD_VIRTUAL_CARD}` }),
      },
      ...(isCheckbooksEnabled ? [orderCheckbookListItem] : []),
    ];
    moreMenuListItems.unshift(...virtualMenuItems);
  } else if (isCheckbooksEnabled) {
    moreMenuListItems.push(orderCheckbookListItem);
  }

  return (
    <BaselaneMenu
      label="More"
      buttonPalette="neutral"
      buttonVariant="outline"
      buttonSize={!isMin768 ? 'lg' : 'md'}
      listItems={moreMenuListItems}
    />
  );
};

export default MoreMenu;
