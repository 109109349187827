import React from 'react';

function IconLogOut({ w = 18, h = 18 }: { w: Number, h: Number }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5556 13.4444L17 9M17 9L12.5556 4.55556M17 9H6.33337M6.33337 1C5.35892 1 3.78562 1 2.59979 1C2.03988 1 1.75992 1 1.54601 1.10899C1.35785 1.20487 1.20487 1.35785 1.10899 1.54601C1 1.75992 1 2.03983 1 2.59966C1 3.46846 1 4.51249 1 5.24929V12.7329C1 13.4723 1 14.5253 1 15.4003C1 15.9601 1 16.2401 1.10899 16.454C1.20487 16.6421 1.35785 16.7951 1.54601 16.891C1.75992 17 2.03994 17 2.59999 17C3.77805 17 5.34123 17 6.33337 17"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconLogOut;
