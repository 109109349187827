// @flow
import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { BaselaneLeaseResponsiveBadge, BaselanePropertyUnitAddress } from '@shared/components';
import { getLeaseRemainingTime } from '../helpers/unit.helpers';
import { titleWrapperStyles, leaseRemainingStyles } from './styles/leaseResponsiveDetail.styles';

function LeaseResponsivePropertyAddress({
  lease,
  isDesktop,
}: {
  lease: Object,
  isDesktop: boolean,
}): any {
  const { propertyName, propertyAddress: address, propertyUnitName: unitName } = lease;
  const isMultiUnit = lease?.totalUnits > 1;
  const uniqName = isEqual(unitName, propertyName) && !isMultiUnit ? null : unitName;

  const leaseRemainingTime = lease?.archived ? 'Archived' : getLeaseRemainingTime(lease);

  let leaseStatusType = lease?.archived ? 'Archived' : 'Active';
  if (leaseRemainingTime === 'Lease expired') {
    leaseStatusType = 'Expired';
  }

  return (
    <Flex {...titleWrapperStyles(isDesktop)} direction="column">
      <BaselanePropertyUnitAddress
        propertyName={propertyName}
        propertyAddress={address}
        unitName={unitName ? uniqName : null}
      />
      <Flex>
        <BaselaneLeaseResponsiveBadge leaseStatusType={leaseStatusType} />
        {leaseStatusType === 'Active' && (
          <Text {...leaseRemainingStyles}>{leaseRemainingTime}</Text>
        )}
      </Flex>
    </Flex>
  );
}

export default LeaseResponsivePropertyAddress;
