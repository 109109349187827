// @flow
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import { BaselaneLegend, BaselaneLegendItem } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import getCurrentMonth from '@core/utils/getCurrentMonth';
import {
  dashboardCurrencyTextStyle,
  propertyLegendContainerStyles,
  propertyLegendItemStyles,
} from './styles/responsive.style';

function PropertyLegend({
  rentCollected,
  rentPending,
  rentOverdue,
  isLeasePage,
}: {
  rentCollected: number,
  rentPending: number,
  rentOverdue: number,
  isLeasePage: boolean,
}): any {
  const month = getCurrentMonth();

  const getCurrency = (amount) => {
    if (!isMobile) {
      return amount === 0 ? '$0' : formatCurrency(amount).inDollars;
    }
    return amount === 0 ? '$0' : formatCurrency(amount).rounded;
  };

  const legend = [
    {
      color: 'brand.blue.700',
      label: !isMobile ? `Rent Collected ${month}` : 'Collected',
      value: getCurrency(rentCollected),
    },
    {
      color: 'brand.blue.300',
      label: !isMobile ? `Rent Pending ${month}` : 'Pending',
      value: getCurrency(rentPending),
    },
    {
      color: 'red.200',
      label: !isMobile ? 'Overdue Total' : 'Overdue',
      value: getCurrency(rentOverdue),
    },
  ];

  const legendItemRenderer = (item) => {
    const key = uuidv4();
    return (
      <BaselaneLegendItem
        key={key}
        label={item.label}
        indicatorColor={item.color}
        value={item.value}
        labelContainerStyle={isLeasePage ? { flexBasis: '200px' } : { mb: '4px' }}
        containerStyle={propertyLegendContainerStyles(isLeasePage, isMobile)}
        valueStyle={dashboardCurrencyTextStyle(isMobile)}
      />
    );
  };

  return (
    <BaselaneLegend
      legendStyles={propertyLegendItemStyles(isMobile)}
      data={legend}
      itemRenderer={legendItemRenderer}
    />
  );
}

export default PropertyLegend;
