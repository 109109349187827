export const containerStyles = (isMobile) => {
  return {
    mb: isMobile ? '0' : '20px',
    mt: '0',
    pb: isMobile ? '0' : '34px',
    height: 'auto',
  };
};

export const headerStyles = (isMobile) => {
  return {
    display: isMobile ? 'none' : 'flex',
  };
};

export const headerRightStyles = {
  flex: 1,
  justifyContent: 'flex-end',
};

export const summaryContainerStyles = {
  m: { lg: '0 0 24px 0', md: '0 32px 24px 32px', sm: '0 20px 20px', base: '0 16px 16px' },
};

export const summaryInnerContainerStyles = (isLargerThan899) => {
  return {
    mb: isLargerThan899 && '24px!important',
  };
};

const headerLeftTitleBaseStyles = {
  color: 'brand.neutral.700',
  pb: '16px',
};

export const headerLeftStyles = (isLargerThan899, isLargerThan576) => {
  if (isLargerThan899) {
    return {
      ...headerLeftTitleBaseStyles,
      textStyle: 'headline-lg',
      py: '3px',
    };
  }
  if (isLargerThan576) {
    return {
      ...headerLeftTitleBaseStyles,
      textStyle: 'headline-md',
      py: '20px',
    };
  }
  return {
    ...headerLeftTitleBaseStyles,
    textStyle: 'headline-sm',
    py: '16px',
  };
};

export const cardSummaryOverviewStyles = {
  height: 'auto',
  p: '0',
  verticalAlign: 'top',
};

export const cardSummaryLeftFirstItemStyles = {
  textStyle: 'xs',
  pl: '0',
};

export const cardSummaryOverviewValueStyles = (isSmaller) => {
  return {
    fontSize: { base: isSmaller ? 'sm' : 'xl', lg: '2xl' },
    lineHeight: { base: isSmaller ? '20px' : '24px', lg: '32px' },
    fontWeight: 'semibold',
    color: 'brand.neutral.700',
    pb: '0',
    mt: '0',
  };
};

export const overViewFooterTitleStyles = {
  textStyle: 'xs',
};

export const overViewFooterContentStyles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.900',
  pb: '0px',
};

export const overviewContentStyles = {
  marginTop: { md: '16px', base: '0px !important' },
  verticalAlign: 'top',
  flexDirection: 'column',
};

export const overviewTitleStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  pb: '4px',
};
