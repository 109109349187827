import React from 'react';
import { Text, Stack, Box } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneTooltip, IncentiveBadge } from '@shared/components';
import { IconInfo } from '@icons';
import formatCurrency from '@core/utils/formatCurrency';
import {
  getPropertyName,
  getPropertyAddress,
  getCategoryName,
} from '@core/utils/propertyAndCategoryHelpers';

import { descriptionTextStyles, title2 } from '../../styles/makeTransferOrPaymentDrawer.styles';
import {
  sectionBodyTextSmStyles,
  sectionBodyTextXsStyles,
  sectionTitleTextStyles,
} from './styles/review.styles';

type ReviewWireViewProps = {
  values: Object,
  propertyOptions: Array<Object>,
  categoryOptions: Array<Object>,
  transfer: Object,
  categoryIdsMap: Object,
};

const ReviewWireView = ({
  values,
  propertyOptions,
  categoryOptions,
  transfer,
  categoryIdsMap,
}: ReviewWireViewProps) => {
  const { isMinXL } = getBreakPoints();

  const addressUnit = values?.unit && values.unit !== '' ? `, ${values.unit}` : '';
  const address = values?.address
    ? `${values.address}${addressUnit}, ${values.city}, ${values.state} ${values.zipcode}`
    : '';

  const stripAmount = transfer?.transferAmount?.substring(1).replace(/,/g, '');
  const transferAmount = Number(stripAmount);

  const [baselaneBankName = '', baselaneCustomAccountName = ''] =
    transfer?.selectedAccountFrom?.formattedAccountData?.accLabel?.split('>') || [];

  return (
    <Stack spacing={3}>
      <Stack spacing={isMinXL ? 0 : 2}>
        <Text {...title2}>Review & Send Wire</Text>
        <Text {...{ ...descriptionTextStyles, mb: '24px' }}>
          Domestic wire instructions received after 4:45 PM ET, on weekends, or on federal holidays
          will be processed the next business day. There is no fee for this wire transfer.
        </Text>
      </Stack>

      {/* Transfer Amount */}
      <Box>
        <Text {...sectionTitleTextStyles}>Transfer Amount</Text>
        <Text {...sectionBodyTextSmStyles}>{formatCurrency(transferAmount).inDollars}</Text>
        <Stack direction="row">
          <Text {...sectionBodyTextXsStyles}>Transfer Fee - $0.00</Text>
          <IncentiveBadge showTooltip={false} label="No Fee for a Limited Time" size="sm" />
        </Stack>
      </Box>

      {/* Transfer From */}
      <Box>
        <Stack direction="row" align="center">
          <Text {...sectionTitleTextStyles}>Transfer From</Text>
          <BaselaneTooltip
            placement="top"
            label="Your wire will be debited from this banking account"
            innerContainerStyles={{ ml: 'auto' }}
            styles={{ w: '205px' }}
          >
            <IconInfo width="16" height="16" color="#052765" />
          </BaselaneTooltip>
        </Stack>
        <Text {...sectionBodyTextSmStyles}>{baselaneBankName?.trim()}</Text>
        <Text {...sectionBodyTextXsStyles}>{baselaneCustomAccountName?.trim()}</Text>
      </Box>

      {/* Recipient Details */}
      <Box>
        <Text {...sectionTitleTextStyles}>Recipient Details</Text>
        <Text {...sectionBodyTextSmStyles}>{values?.recipientName}</Text>
        <Text {...sectionBodyTextXsStyles}>{address}</Text>
      </Box>

      {/* Routing Number */}
      <Box>
        <Text {...sectionTitleTextStyles}>Routing Number</Text>
        <Text {...sectionBodyTextSmStyles}>{values?.routingNumber}</Text>
        <Text {...sectionBodyTextXsStyles}>{values?.bankName}</Text>
      </Box>

      {/* Recipient Account Number */}
      <Box>
        <Text {...sectionTitleTextStyles}>Recipient Account Number</Text>
        <Text {...sectionBodyTextSmStyles}>{values?.accountNumber}</Text>
      </Box>

      {/* Property Tag */}
      <Box>
        <Text {...sectionTitleTextStyles}>Property Tag</Text>
        {values.propertyId ? (
          <>
            <Text {...sectionBodyTextSmStyles}>
              {getPropertyName(values.propertyId, values.unitId, propertyOptions)}
            </Text>
            <Text {...sectionBodyTextXsStyles}>
              {getPropertyAddress(values.propertyId, propertyOptions)}
            </Text>
          </>
        ) : (
          <Text>&mdash;</Text>
        )}
      </Box>

      {/* Category Tag */}
      <Box>
        <Text {...sectionTitleTextStyles}>Category Tag</Text>
        {values.categoryId ? (
          <Text {...sectionBodyTextSmStyles}>
            {getCategoryName(values.categoryId, categoryOptions, categoryIdsMap)}
          </Text>
        ) : (
          <Text>&mdash;</Text>
        )}
      </Box>
    </Stack>
  );
};

export default ReviewWireView;
