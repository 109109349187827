import React from 'react';
import { Box, Text, useMediaQuery } from '@chakra-ui/react';
import {
  noDataContainerStyles,
  noDataPopupStyles,
  noDataPopupTitleStyles,
  noDataPopupDescriptionStyles,
} from './styles/emptyState.styles';

type EmptyStateProps = {
  getPropertyPicker: Function,
};

function EmptyState({ getPropertyPicker }: EmptyStateProps) {
  const [isLargerThan1537] = useMediaQuery('(min-width: 1537px)', { ssr: false });

  return (
    <Box {...noDataContainerStyles(isLargerThan1537)}>
      <Box {...noDataPopupStyles}>
        <Text {...noDataPopupTitleStyles}>
          Get started with automated and <br />
          free rent collection
        </Text>
        <Text {...noDataPopupDescriptionStyles}>
          Never chase down checks or late payments again. Accept rent by ACH or card directly into
          your bank account. Automated payments, fees & reminders.
        </Text>
        {getPropertyPicker(true)}
      </Box>
    </Box>
  );
}

export default EmptyState;
