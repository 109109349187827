import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { buttonPaddings, transparentButtonPaddings } from './buttonStyleHelpers';

/**
 * This overrides Chakra's default <Button /> styles.
 *
 * see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts for implementation details
 */

export const baseStyle = {
  outlineWidth: '1px',
  outlineStyle: 'dashed',
  outlineOffset: '1px',
  alignSelf: 'left',
  _disabled: {
    opacity: 1,
    cursor: 'default',
  },
  _focus: {
    boxShadow: 'none',
    outline: 'none !important',
  },
  _focusVisible: {
    boxShadow: 'none',
    outlineWidth: '1px',
    outlineStyle: 'dashed',
  },
  _focusWithin: {
    boxShadow: 'none',
    outlineWidth: '1px',
    outlineStyle: 'dashed',
  },

  _active: {
    boxShadow: 'none',
    outline: 'none',
  },
};

const sizes = {
  xs: defineStyle((props) => {
    const { textStyles, radii } = props.theme;
    return {
      ...textStyles['button-xs'],
      p: buttonPaddings('0.75', '0.75'),
      borderRadius: radii.sm,
    };
  }),
  sm: defineStyle((props) => {
    const { textStyles, radii } = props.theme;
    return {
      ...textStyles['button-xs'],
      p: buttonPaddings('1', '1.5'),
      borderRadius: radii.sm,
    };
  }),
  md: defineStyle((props) => {
    const { textStyles, radii } = props.theme;
    return {
      ...textStyles['button-sm'],
      p: buttonPaddings('1', '2'),
      borderRadius: radii.sm,
    };
  }),
  lg: defineStyle((props) => {
    const { textStyles, radii } = props.theme;
    return {
      ...textStyles['button-md'],
      p: buttonPaddings('1.5', '3'),
      borderRadius: radii.sm,
    };
  }),
};

export const Button = defineStyleConfig({
  baseStyle,
  sizes,
  variants: {
    transparent: (props) => ({
      p: transparentButtonPaddings(props.size),
    }),
    link: {
      p: '0',
      fontWeight: 'normal',
      textDecoration: 'underline',
    },
  },
});
