import React from 'react';
import { Box, forwardRef } from '@chakra-ui/react';
import { listStyles } from './styles/dropdown.styles';

type DropdownContentWrapperProps = {
  hasFilterWrapper: boolean,
  children: React.Node,
};

const DropdownContentWrapper = forwardRef(
  ({ hasFilterWrapper, children }: DropdownContentWrapperProps, ref) => {
    return (
      <Box key="option-wrap" {...listStyles(hasFilterWrapper)} ref={ref}>
        <Box py={1.5} w="100%">
          {children}
        </Box>
      </Box>
    );
  }
);

export default DropdownContentWrapper;
