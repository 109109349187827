export const drawerHeader = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'brand.darkBlue.800A',
  color: 'brand.neutral.white',
  minHeight: '87px',
};

export const drawerHeaderCloseButtonStyle = {
  right: '24px',
  top: '24px',
  color: 'brand.neutral.white',
};

export const drawerFooterStyle = {
  h: '80px',
  p: '20px 32px',
  bg: 'brand.neutral.white',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  zIndex: 1,
  justifyContent: 'flex-start',
};

export const drawerBodyStyle = {
  p: '40px 32px',
  bg: 'brand.neutral.white',
};

export const formTitleStyles = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
  mb: '32px',
};

export const daysAgoStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.500',
  fontStyle: 'italic',
  verticalAlign: 'middle',
};

export const formLabelStyles = {
  textStyle: 'xs-heavy',
  color: 'brand.neutral.700',
  mb: '10px',
};
