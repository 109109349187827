// Hook for fetching recipients
import { useQuery } from '@apollo/client';
import { GET_PAYEES } from '@core/apollo/queries';
import { DEFAULT_SORT_FIELD, DEFAULT_SORT_DIR } from '../components/Recipients/helpers';

const useRecipientsHook = () => {
  const {
    data: recipientsData,
    loading: isRecipientsLoading,
    error: hasRecipientsError,
  } = useQuery(GET_PAYEES, {
    variables: {
      input: {
        field: DEFAULT_SORT_FIELD,
        direction: DEFAULT_SORT_DIR,
      },
    },
    onError: (err) => console.error(err),
  });

  const { payees: recipients } = recipientsData ?? {};
  const hasRecipients = recipients?.length > 0;

  return {
    recipients,
    isRecipientsLoading,
    hasRecipientsError,
    hasRecipients,
  };
};

export default useRecipientsHook;
