import React from 'react';
import { Icon16Sparkle, Icon16Accounts, Icon16LifetimeInterest, Icon16Dollar } from '@icons/16px';

export const defaultComponentCopy = {
  heading: 'Select a new receiving account',
  formLabel: 'Account to receive rent & fees',
  tooltipText: 'Only accounts that are eligible to collect rent are shown here.',
  baselaneAccountHeading: 'Open a Baselane Banking account',
  baselaneAccountDetails: [
    {
      id: 1,
      text: 'Free tenant payments via ACH (bank transfer)',
      icon: <Icon16Dollar />,
    },
    {
      id: 2,
      text: 'Unlimited checking accounts to keep your rent organized',
      icon: <Icon16Accounts />,
    },
    {
      id: 3,
      text: `Up to 4.19% APY on savings accounts`,
      icon: <Icon16LifetimeInterest />,
    },
    {
      id: 4,
      text: `Automated bookkeeping for your rent`,
      icon: <Icon16Sparkle />,
    },
  ],
  baselaneAccountButtonLabel: 'Create account',
  connectedAccountHeading: 'Connect your external account',
  connectedAccountDetails: '$2 fee for tenant payments via ACH (bank transfer)',
  connectedAccountButtonLabel: 'Connect external account',
  // Only overrides above copy if hideExternalAccounts is true
  hideExternalAccounts: {
    heading: 'Select or add a Baselane Banking account',
  },
};
