import React from 'react';
import { BaselaneGrid } from '@shared/components';

import Checkitem from './Checkitem';

function Checker({ checklist, styles }: { checklist: Array, styles: Object }) {
  const gridItems = Object.keys(checklist).map((check) => {
    return {
      element: <Checkitem key={check} item={checklist[check]} />,
    };
  });
  return (
    <BaselaneGrid
      gridItems={gridItems}
      templateColumns="repeat(2, 1fr)"
      gap={1.5}
      gridStyles={styles}
    />
  );
}

export default Checker;
