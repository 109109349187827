import { create } from 'zustand';

const useOnboardingStore = create((set, get) => ({
  // states
  step: 0,
  firstStep: 0,
  lastStep: 4,
  email: null,
  questionnaire: [],
  questionnaireObject: {},
  selection: null,
  // setters
  setEmail: (newEmail) => set({ email: newEmail }),
  addResponse: (question, answer, otherEditValue) => {
    const newQuestionnaire = [];
    newQuestionnaire.push({
      question,
      answer: answer.value || answer,
    });
    if (answer.type === 'text') {
      newQuestionnaire.push({
        question: 'otherSource',
        answer: otherEditValue,
      });
    }
    const newQuestionnaireObject = {};
    newQuestionnaireObject[question] = answer;
    if (answer.type === 'text') {
      newQuestionnaireObject.otherSource = otherEditValue;
    }
    set((state) => ({
      questionnaire: [...state.questionnaire, ...newQuestionnaire],
      questionnaireObject: { ...state.questionnaireObject, ...newQuestionnaireObject },
    }));
  },
  setStep: (step) =>
    set(() => ({
      step,
    })),
  setSelection: (selection) =>
    set(() => ({
      selection,
    })),
}));

export default useOnboardingStore;
