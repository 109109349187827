import process from 'process';
import React, {
  forwardRef,
  useContext,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import LogRocket from 'logrocket';
import { isMobile } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  VStack,
  DrawerBody,
  DrawerFooter,
  Heading,
  Text,
  Box,
  Image,
  Stack,
  Checkbox,
  CheckboxGroup,
  useCheckboxGroup,
  ChakraProvider,
} from '@chakra-ui/react';

import { useMutation } from '@apollo/client';
import UAParser from 'ua-parser-js';

import {
  BaselaneDrawer,
  BaselaneButton,
  BaselaneSimpleProgressBar,
  useTwoFactor,
  TwoFactorVerificationPopUp,
  BaselaneAlert,
  AlertHeader,
  BaselaneMessageCard,
} from '@shared/components';

import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';

import TransactionContext from '@contexts/TransactionContext';
import UserContext from '@contexts/UserContext';

import IconCheck from '@icons/legacy/IconCheck';
import IconCheckDepositMobileDevice from '@icons/legacy/IconCheckDepositMobileDevice';
import IconCheckDepositSuccess from '@icons/legacy/IconCheckDepositSuccess';
import IconChevronLeft from '@icons/legacy/IconChevronLeft';
import IconChevronRight from '@icons/legacy/IconChevronRight';
import IconDollarCircleOutline from '@icons/legacy/IconDollarCircleOutline';
import IconMobileCheckDeposit from '@icons/legacy/IconMobileCheckDeposit';

import {
  CHECK_DEPOSIT_STEP,
  getImageStyle,
  UNIT_CHECK_DEPOSIT_STATUS,
  UNIT_CHECK_SIDE,
  CHECK_UPLOAD_ERROR_KEYS,
  CHECK_UPLOAD_ERRORS,
} from '@shared/components/BaselaneMobileCheckUploader/helpers/mobileCheckUpload.helpers';

import { captureButtonStyles } from '@shared/components/BaselaneMobileCheckUploader/styles/mobileCheckUploader.styles';

import stripCurrency from '@core/utils/stripCurrency';
import SlLoader from '@core/components/Loader';
import { Icon16ChevronLeft, Icon16ChevronRight } from '@icons/16px';
import { INITIATE_CHECK_DEPOSIT } from '@core/components/NativeBankingPage/queries';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import habitatTheme from '@core/themeHabitat';
import {
  getCategoryName,
  getPropertyAddress,
  getPropertyName,
} from '@core/utils/propertyAndCategoryHelpers';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';

import {
  footerStyles,
  reviewLabelStyles,
  reviewPrimaryValueStyles,
  reviewSecondaryValueStyles,
  reviewLabelContainerStyles,
  disabledBoxCard,
  descriptionTextStyles,
  headingStyles,
  mobileDrawerBodyStyles,
} from '../../styles/checkDepositDrawer.styles';
import ButtonMethod from '../../../ButtonMethod';
import CheckDeposit from '../../../CheckDeposit';
import PropertyAndCategoryTaggingForm from '../../../PropertyAndCategoryTaggingForm';
import {
  setTokenData,
  getSensitiveToken,
  getTokenData,
  getIsTokenExpired,
  getUnitOTPVerification,
  handleNBOnVerifyClick,
  handleOTPPopupOpen,
  handleOTPPopupClose,
} from '../../../../../helpers/otp.helpers';
import CheckEndorsementExampleThread from './assets/CheckEndorsementExampleThread.png';
import CheckEndorsementExampleThread2x from './assets/CheckEndorsementExampleThread@2x.png';

/**
 * This enables verbose console logging for the purpose
 * of debugging the Check Deposit flow.
 */
const DEBUG_MODE = false;
// start at an arbitrary drawer in the sequence (in debug mode)
const DEBUG_START_AT_STEP = CHECK_DEPOSIT_STEP.SCAN_CHECK;
// debug logging function
const log = DEBUG_MODE ? (value, ...rest) => console.debug(value, ...rest) : () => {};

type CheckDepositDrawerProps = {
  banks: Array<Object>,
  mobileCheckDepositDrawerRef: any,
  handleCheckDepositClose: Function,
  onOpenMobileCheckDepositDrawer: Function,
  setIsValidBankTransfer: Function,
  isValidBankTransfer: Boolean,
  setTransfer: Function,
  checkDepositTaggingDrawerRef: any,
  checkDepositEndorsementDrawerRef: any,
  checkDepositReviewDrawerRef: any,
  successDrawerRef: any,
  isDisabled: boolean,
  isInEmptyState: Boolean,
  onAlertOpen: Function,
};

const CheckDepositDrawer = forwardRef(
  (
    {
      banks,
      mobileCheckDepositDrawerRef,
      handleCheckDepositClose,
      onOpenMobileCheckDepositDrawer,
      setIsValidBankTransfer,
      isValidBankTransfer,
      setTransfer,
      checkDepositTaggingDrawerRef,
      checkDepositEndorsementDrawerRef,
      checkDepositReviewDrawerRef,
      successDrawerRef,
      isDisabled,
      isInEmptyState,
      onAlertOpen,
    }: CheckDepositDrawerProps,
    ref
  ) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const removeAddFundsParam = () => {
      if (searchParams.has('add_funds')) {
        searchParams.delete('add_funds');
        setSearchParams(searchParams);
      }
    };

    const partnerBank = 'Thread Bank';

    const [initiateCheckDeposit, { loading: initiateCheckDepositLoading }] = useMutation(
      INITIATE_CHECK_DEPOSIT
    );

    const {
      setShowMobileDropdownPopup,
      showMobileDropdownPopup,
      setShowMobileDropdownPopupAnimation,
    } = useContext(UserContext);

    const { propertiesData, categoryMap, categoryIdsMap, categoryWithSubOptions } = useContext(
      TransactionContext
    );
    const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);

    const desktopInfoDrawerRef = useRef(null);

    const [currentStep, setCurrentStep] = useState(
      DEBUG_MODE ? DEBUG_START_AT_STEP : CHECK_DEPOSIT_STEP.ENDORSMENT
    );

    const TOTAL_PROGRESS_BAR_STEPS = 3;

    const [selectedAccountTo, setSelectedAccountTo] = useState();
    const [transferAmount, setTransferAmount] = useState('');
    const [amountErrors, setAmountErrors] = useState();
    const [checkImageUploading, setCheckImageUploading] = useState(false);

    const [checkFrontImageData, setCheckFrontImageData] = useState(null);
    const [checkBackImageData, setCheckBackImageData] = useState(null);

    const [propertyId, setPropertyId] = useState(null);
    const [unitId, setUnitId] = useState(null);
    const [categoryId, setCategoryId] = useState(null);

    const [checkDepositDetails, setCheckDepositDetails] = useState(null);

    const [lastUploadResponse, setLastUploadResponse] = useState(false);

    const propertyOptions = getPropertyData(propertiesData);

    const [isUnsupportedBrowser, setIsUnsupportedBrowser] = useState(false);
    const [showUnsupportedBrowserWarning, setShowUnsupportedBrowserWarning] = useState(false);
    const [isMobileChrome, setIsMobileChrome] = useState(false);

    const [error, setError] = useState(null);

    const [frontRotated180, setFrontRotated180] = useState(false);
    const [backRotated180, setBackRotated180] = useState(false);

    const [xIdempotencyKey, setXIdempotencyKey] = useState(v4());

    const {
      value: signAndEndorseChecks,
      getCheckboxProps,
      setValue: setSignAndEndorseChecks,
    } = useCheckboxGroup({
      defaultValue: [],
    });

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const stripBase64Meta = (data) => {
      return data.split('data:image/jpeg;base64,').join('');
    };

    const uploadCheckImage = async (checkId, token, checkSide = 'front') => {
      log(`>> uploadCheckImage: ${checkSide} -> start`, { checkId, token, checkSide });
      const unitAPI = process?.env?.REACT_APP_UNIT_SERVICE_URL?.split('https://')?.join('');
      const requestUrl = `https://${unitAPI}/check-deposits/${checkId}/${checkSide}`;
      const body =
        checkSide === UNIT_CHECK_SIDE.FRONT
          ? b64toBlob(stripBase64Meta(checkFrontImageData), 'image/jpeg')
          : b64toBlob(stripBase64Meta(checkBackImageData), 'image/jpeg');

      try {
        const response = await fetch(requestUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'image/jpeg',
            authorization: `Bearer ${token}`,
          },
          body,
        });
        return response !== null ? response.json() : response;
      } catch (e) {
        LogRocket.log(e);
        log(`>> uploadCheckImage: ${checkSide} -> error`, { e });
      }
      return null;
    };

    /**
     * OTP Functions
     */

    const phoneNumber = () =>
      banks?.find((bank) => {
        return bank?.id === selectedAccountTo?.group?.id;
      })?.unitAccount?.phoneNumber;

    const bankId = () => selectedAccountTo?.group?.id;

    const { states, stateFunctions } = useTwoFactor();
    const { setOTPErrorCode } = stateFunctions;
    const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

    /**
     * Attempts to start uploading image, but if OTP token is expired
     * will first pop that up then continue upload after completion.
     */
    const attemptImageUpload = async (checkId, checkSide = UNIT_CHECK_SIDE.FRONT) => {
      if (!checkId) {
        LogRocket.log('No checkId');
        log(`attemptImageUpload -> warning: no checkId`, { checkId });
        return;
      }

      const { sensitive } = getTokenData(bankId());
      const isTokenExpired = getIsTokenExpired(bankId());

      log(`attemptImageUpload -> token validation`, {
        sensitive,
        isTokenExpired,
        bankId: bankId(),
      });

      if (isTokenExpired) {
        log(`attemptImageUpload -> token validation: failed`, { sensitive, isTokenExpired });
        log(`attemptImageUpload -> opening OTP popup`);
        setCheckImageUploading(true);
        handleOTPPopupOpen(getOTP, bankId(), stateFunctions);
      } else {
        log('attemptImageUpload -> starting check front upload');
        try {
          setCheckImageUploading(true);
          const uploadResponse = await uploadCheckImage(checkId, sensitive.token, checkSide);
          if (!uploadResponse) {
            LogRocket.log('Image upload no repsonse');
            sendSegmentEvent('baselane_banking_check_deposit_error');
            setError({
              title: CHECK_UPLOAD_ERRORS[CHECK_UPLOAD_ERROR_KEYS.PROCESSING_FAILED].title,
              message: CHECK_UPLOAD_ERRORS[CHECK_UPLOAD_ERROR_KEYS.PROCESSING_FAILED].message,
            });
            return;
          }
          setLastUploadResponse(uploadResponse);
        } catch (e) {
          LogRocket.log(`Image upload failure: ${e}`);
          log(`attemptImageUpload -> check ${checkSide} upload failed`, { e });
          sendSegmentEvent('baselane_banking_check_deposit_error');
          setError({
            title: CHECK_UPLOAD_ERRORS[CHECK_UPLOAD_ERROR_KEYS.PROCESSING_FAILED].title,
            message: CHECK_UPLOAD_ERRORS[CHECK_UPLOAD_ERROR_KEYS.PROCESSING_FAILED].message,
          });
        }
      }
    };

    /**
     * Calls the backend to initiate a new check deposit
     * transfer.  This will not occur is OTP token is expired
     * as we don't want users cancelling after the check deposit
     * has started.
     */
    const startCheckDeposit = async () => {
      const isTokenExpired = getIsTokenExpired(bankId());
      if (isTokenExpired) return; // can't proceed without valid OTP

      const amount = stripCurrency(transferAmount);
      LogRocket.log(`Deposit started with amount: ${amount}`);
      log('onDepositClicked', { amount });

      let checkId = checkDepositDetails?.externalId;
      LogRocket.log(`checkId: ${checkId}`);

      let wasAmountChanged = false;
      let wasAccountChanged = false;
      if (checkDepositDetails) {
        wasAmountChanged = checkDepositDetails.amount !== amount;
        wasAccountChanged = checkDepositDetails.toTransferAccountId !== selectedAccountTo.id;
      }

      if (!checkId || wasAmountChanged || wasAccountChanged) {
        try {
          const dataResponse = await initiateCheckDeposit({
            context: {
              headers: {
                'x-idempotency-key': xIdempotencyKey,
              },
            },
            variables: {
              amount,
              note: 'Check Deposit',
              propertyId,
              tagId: categoryId,
              toTransferAccountId: selectedAccountTo.id,
              unitId,
            },
          });

          if (!dataResponse?.data?.initiateCheckDeposit?.externalId) {
            LogRocket.log(`Response error from deposot: ${dataResponse}`);
            sendSegmentEvent('baselane_banking_check_deposit_error');
            setError({
              title: CHECK_UPLOAD_ERRORS[CHECK_UPLOAD_ERROR_KEYS.PROCESSING_FAILED].title,
              message: CHECK_UPLOAD_ERRORS[CHECK_UPLOAD_ERROR_KEYS.PROCESSING_FAILED].message,
            });
            return;
          }

          log('initiateCheckDeposit -> response', { dataResponse });
          const { externalId } = dataResponse?.data?.initiateCheckDeposit || {};
          checkId = externalId;

          const newCheckDepositDetails = {
            externalId,
            toTransferAccountId: selectedAccountTo.id,
            amount,
          };
          setCheckDepositDetails(newCheckDepositDetails);
        } catch (e) {
          LogRocket.log(`depositing error ${e}`);

          log(`initiateCheckDeposit -> error`, { e });
          sendSegmentEvent('baselane_banking_check_deposit_error');
          setError({
            title: CHECK_UPLOAD_ERRORS[CHECK_UPLOAD_ERROR_KEYS.PROCESSING_FAILED].title,
            message: CHECK_UPLOAD_ERRORS[CHECK_UPLOAD_ERROR_KEYS.PROCESSING_FAILED].message,
          });
        }
      }

      // if we get this far- begin uploading the front image
      attemptImageUpload(checkId, UNIT_CHECK_SIDE.FRONT);
    };

    /**
     * OTP Complete - this will continue the flow
     * if it was already initiated
     */
    const onUserSensitiveTokenComplete = async (token) => {
      log('got token', { token });
      setTokenData(token, selectedAccountTo?.group?.id, true);
      const status = lastUploadResponse?.data?.attributes?.status;

      if (!status) {
        // check deposit has not yet been initiated
        startCheckDeposit();
      } else if (status === UNIT_CHECK_DEPOSIT_STATUS.AWAITING_FRONT_IMAGE) {
        setCheckImageUploading(true);
        const response = await uploadCheckImage(
          checkDepositDetails?.externalId,
          token.token,
          UNIT_CHECK_SIDE.FRONT
        );
        log('onUserSensitiveTokenComplete - Front', { response });
        setLastUploadResponse(response);
      } else if (status === UNIT_CHECK_DEPOSIT_STATUS.AWAITING_BACK_IMAGE) {
        setCheckImageUploading(true);
        const response = await uploadCheckImage(
          checkDepositDetails?.externalId,
          token.token,
          UNIT_CHECK_SIDE.BACK
        );
        log('onUserSensitiveTokenComplete - Back', { response });
        setLastUploadResponse(response);
      }

      handleOTPPopupClose(states, stateFunctions);
    };

    const [getUserSensitiveTokenData] = getSensitiveToken({
      onUserSensitiveTokenComplete,
      getOTP,
      bankId: bankId(),
      states,
      stateFunctions,
    });

    const handlePropertySubmit = (newPropertyId: string) => {
      LogRocket.log(`Select property ${newPropertyId}`);
      const [newPId, newUId] = newPropertyId.split('-');
      setPropertyId(newPId);
      setUnitId(newUId);
    };

    function handleCategorySubmit(newCategoryId: number, dropDown) {
      LogRocket.log(`Select category ${newCategoryId}`, dropDown);
      const [parentId, subId] = (newCategoryId ?? '').split('-');
      setCategoryId(subId ?? parentId);
      setTimeout(() => {
        dropDown('close');
      }, 10);
    }

    const renderCategoryDropdownItem = (item) => item.name;

    const reinitialize = () => {
      setCurrentStep(DEBUG_MODE ? DEBUG_START_AT_STEP : CHECK_DEPOSIT_STEP.ENDORSMENT);
      setSelectedAccountTo(null);
      setTransferAmount('');
      setAmountErrors(null);
      setCheckFrontImageData(null);
      setCheckBackImageData(null);
      setPropertyId(null);
      setUnitId(null);
      setCategoryId(null);
      setFrontRotated180(false);
      setBackRotated180(false);
      setXIdempotencyKey(v4());
    };

    const closeAll = (exceptFirst = false) => {
      // adding this here because closing scan check drawer also calls reinitialize which will then
      // reset the checkboxes on the first endorsement step
      setSignAndEndorseChecks([]);

      successDrawerRef?.current?.close();
      mobileCheckDepositDrawerRef?.current?.close();
      checkDepositTaggingDrawerRef?.current?.close();
      checkDepositReviewDrawerRef?.current?.close();
      if (!exceptFirst) {
        checkDepositEndorsementDrawerRef?.current?.close();
      }
    };

    const handleDepositClicked = () => {
      const isTokenExpired = getIsTokenExpired(bankId());

      if (isTokenExpired) {
        log(`handleDepositClicked -> token validation: failed`, { isTokenExpired });
        log(`handleDepositClicked -> opening OTP popup`);
        handleOTPPopupOpen(getOTP, bankId(), stateFunctions);
      } else {
        startCheckDeposit();
      }
    };

    const openDrawer = (step) => {
      switch (step) {
        case CHECK_DEPOSIT_STEP.ENDORSMENT:
          checkDepositEndorsementDrawerRef?.current?.open();
          return;
        case CHECK_DEPOSIT_STEP.SCAN_CHECK:
          onOpenMobileCheckDepositDrawer();
          break;
        case CHECK_DEPOSIT_STEP.TAGGING:
          checkDepositTaggingDrawerRef?.current?.open();
          break;
        case CHECK_DEPOSIT_STEP.REVIEW:
          checkDepositReviewDrawerRef?.current?.open();
          break;
        case CHECK_DEPOSIT_STEP.SUCCESS:
          successDrawerRef?.current?.open();
          break;
        default:
        // do nothing
      }
    };

    const closeDrawer = (step) => {
      switch (step) {
        case CHECK_DEPOSIT_STEP.ENDORSMENT:
          checkDepositEndorsementDrawerRef?.current?.close();
          return;
        case CHECK_DEPOSIT_STEP.TAGGING:
          onAlertOpen();
          // checkDepositTaggingDrawerRef?.current?.close();
          break;
        case CHECK_DEPOSIT_STEP.REVIEW:
          onAlertOpen();
          checkDepositReviewDrawerRef?.current?.close();
          break;
        case CHECK_DEPOSIT_STEP.SUCCESS:
          successDrawerRef?.current?.close();
          break;
        case CHECK_DEPOSIT_STEP.SCAN_CHECK:
          mobileCheckDepositDrawerRef?.current?.close();
          break;
        default:
        // do nothing
      }
    };

    /**
     * Drawer Navigation
     */
    const onNextClicked = () => {
      switch (currentStep) {
        case CHECK_DEPOSIT_STEP.ENDORSMENT:
          setCurrentStep(CHECK_DEPOSIT_STEP.SCAN_CHECK);
          sendSegmentEvent('baselane_banking_check_deposit_complete_endorse');
          openDrawer(CHECK_DEPOSIT_STEP.SCAN_CHECK);
          return;
        case CHECK_DEPOSIT_STEP.SCAN_CHECK:
          setCurrentStep(CHECK_DEPOSIT_STEP.TAGGING);
          sendSegmentEvent('baselane_banking_check_deposit_complete_step1');
          openDrawer(CHECK_DEPOSIT_STEP.TAGGING);
          return;
        case CHECK_DEPOSIT_STEP.TAGGING:
          setCurrentStep(CHECK_DEPOSIT_STEP.REVIEW);
          sendSegmentEvent('baselane_banking_check_deposit_complete_step2');
          openDrawer(CHECK_DEPOSIT_STEP.REVIEW);
          break;
        case CHECK_DEPOSIT_STEP.REVIEW:
          handleDepositClicked();
          break;
        default:
        // do nothing
      }
    };

    const onPrevClicked = () => {
      switch (currentStep) {
        case CHECK_DEPOSIT_STEP.SCAN_CHECK:
          setCurrentStep(CHECK_DEPOSIT_STEP.ENDORSMENT);
          closeDrawer(CHECK_DEPOSIT_STEP.SCAN_CHECK);
          return;
        case CHECK_DEPOSIT_STEP.TAGGING:
          setCurrentStep(CHECK_DEPOSIT_STEP.SCAN_CHECK);
          closeDrawer(CHECK_DEPOSIT_STEP.TAGGING);
          setPropertyId(null);
          setUnitId(null);
          setCategoryId(null);
          return;
        case CHECK_DEPOSIT_STEP.REVIEW:
          setCurrentStep(CHECK_DEPOSIT_STEP.TAGGING);
          closeDrawer(CHECK_DEPOSIT_STEP.REVIEW);
          break;
        case CHECK_DEPOSIT_STEP.ENDORSMENT:
          closeDrawer(CHECK_DEPOSIT_STEP.ENDORSMENT);
          break;
        default:
        // do nothing
      }
    };

    const again = () => {
      reinitialize();
      closeAll(true);
    };

    const complete = () => {
      reinitialize();
      closeAll();
    };

    useEffect(() => {
      log('useEffect [lastUploadResponse] -> lastUploadResponse', { lastUploadResponse });

      if (!lastUploadResponse) return;

      const { id } = lastUploadResponse?.data || {};
      const status = lastUploadResponse?.data?.attributes?.status;

      if (!status) return;

      let checkId = id;

      if (!checkId) {
        checkId = checkDepositDetails?.externalId;
      }

      if (!checkId) return;

      switch (status) {
        case UNIT_CHECK_DEPOSIT_STATUS.AWAITING_FRONT_IMAGE:
          setCheckImageUploading(true);
          attemptImageUpload(checkId, UNIT_CHECK_SIDE.FRONT);
          break;
        case UNIT_CHECK_DEPOSIT_STATUS.AWAITING_BACK_IMAGE:
          setCheckImageUploading(true);
          attemptImageUpload(checkId, UNIT_CHECK_SIDE.BACK);
          break;
        case UNIT_CHECK_DEPOSIT_STATUS.PENDING:
        case UNIT_CHECK_DEPOSIT_STATUS.PENDING_REVIEW:
        case UNIT_CHECK_DEPOSIT_STATUS.SENT:
        case UNIT_CHECK_DEPOSIT_STATUS.CLEARING:
          setCheckImageUploading(false);
          setCheckDepositDetails(null);
          setLastUploadResponse(null);
          setCurrentStep(CHECK_DEPOSIT_STEP.SUCCESS);
          sendSegmentEvent('baselane_banking_check_deposit_success');
          openDrawer(CHECK_DEPOSIT_STEP.SUCCESS);
          break;
        default:
          setCheckImageUploading(false);
          setCurrentStep(CHECK_DEPOSIT_STEP.REVIEW);
          log(`useEffect [lastUploadResponse] -> unhandled unit status: `, {
            status,
            lastUploadResponse,
          });
          sendSegmentEvent('baselane_banking_check_deposit_error');
          setError({
            title: CHECK_UPLOAD_ERRORS[CHECK_UPLOAD_ERROR_KEYS.PROCESSING_FAILED].title,
            message: CHECK_UPLOAD_ERRORS[CHECK_UPLOAD_ERROR_KEYS.PROCESSING_FAILED].message,
          });
          break;
      }
    }, [lastUploadResponse]);

    const handleInitialClick = () => {
      if (!isDisabled) {
        if (isMobile) {
          if (isUnsupportedBrowser) {
            setShowUnsupportedBrowserWarning(true);
          } else {
            openDrawer(currentStep);
            if (currentStep === CHECK_DEPOSIT_STEP.SCAN_CHECK) {
              sendSegmentEvent('baselane_banking_select_add_fund', {
                method: 'mobile_check_deposit',
                platform: isMobile ? 'mobile' : 'desktop',
                hasCounterparty: !isInEmptyState,
              });
            }
          }
        } else {
          desktopInfoDrawerRef?.current?.open();
          sendSegmentEvent('baselane_banking_select_add_fund', {
            method: 'mobile_check_deposit',
            platform: isMobile ? 'mobile' : 'desktop',
            hasCounterparty: !isInEmptyState,
          });
        }
      }
    };

    useImperativeHandle(ref, () => ({
      handleInitialClick,
    }));

    const handleUnsupportedBrowserError = () => {
      setIsMobileChrome(false);
      setIsUnsupportedBrowser(true);
    };

    useEffect(() => {
      const parser = new UAParser(navigator.userAgent);
      const { browser, device } = parser.getResult();
      const version = parseInt(browser.major, 10);

      if (device.type === 'mobile' && browser.name === 'Chrome' && version < 107) {
        setIsMobileChrome(true);
        setIsUnsupportedBrowser(true);
      } else {
        setIsMobileChrome(false);
        setIsUnsupportedBrowser(false);
      }
    }, []);

    const twoFactorVerificationProps = {
      ...states,
      ...stateFunctions,
      getOTP,
      bankId: bankId(),
      phoneNumber: phoneNumber(),
      handleVerifyOnClick: (otpCode) =>
        handleNBOnVerifyClick(otpCode, bankId(), getUserSensitiveTokenData),
    };

    const onAlertClose = () => {
      setShowUnsupportedBrowserWarning(false);
    };

    const frontImageStyle = getImageStyle(checkFrontImageData);
    const backImageStyle = getImageStyle(checkBackImageData);

    return (
      <ChakraProvider theme={habitatTheme}>
        <BaselaneAlert
          isOpen={showUnsupportedBrowserWarning}
          onClose={onAlertClose}
          header={<AlertHeader title="Unsupported Browser" />}
          body={
            isMobileChrome ? (
              <Text {...{ p: '8px' }}>
                Please update your Chrome to the latest version in order to do Mobile Check Deposit
                function
              </Text>
            ) : (
              <Text {...{ p: '8px' }}>
                Your browser does not support this function, please use latest version of Chrome or
                Safari on your mobile
              </Text>
            )
          }
          footer={
            <BaselaneButton variant="filled" palette="primary" size="lg" onClick={onAlertClose}>
              OK
            </BaselaneButton>
          }
        />
        <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />
        <VStack {...{ p: '0', m: '0' }}>
          <ButtonMethod
            {...{
              handleOnClick: handleInitialClick,
              title: isDisabled ? 'MOBILE CHECK DEPOSIT (COMING SOON)' : 'MOBILE CHECK DEPOSIT',
              description:
                'Deposit checks into your Baselane account directly from a mobile device',
              icon: <IconMobileCheckDeposit />,
              styles: isDisabled ? disabledBoxCard : '',
            }}
          />
          {/* Desktop Info Drawer - This is a mobile-only feature */}
          <BaselaneDrawer
            ref={desktopInfoDrawerRef}
            title="Mobile Check Deposit"
            size="md"
            closeEvent={() => {
              desktopInfoDrawerRef?.current?.close();
            }}
            onClose={() => {
              desktopInfoDrawerRef?.current?.close();
              removeAddFundsParam();
            }}
            hideOverlay
          >
            <DrawerBody {...{ p: '64px', m: '0', bgColor: 'brand.neutral.white' }}>
              <VStack>
                <Box {...{ w: '100%', justifySelf: 'flex-start' }}>
                  <IconCheckDepositMobileDevice />
                </Box>
                <Heading {...headingStyles}>Deposit checks from your mobile device</Heading>
                <Text {...descriptionTextStyles}>
                  Sign in to Baselane on your mobile device to deposit checks from anywhere,
                  anytime. Simply endorse your check, take a picture of the front and back, then
                  follow the prompts to submit your deposit.
                </Text>
              </VStack>
            </DrawerBody>
          </BaselaneDrawer>
          {/* Endorsement Drawer - Step 1 */}
          <BaselaneDrawer
            ref={checkDepositEndorsementDrawerRef}
            title="Check Deposit"
            size="md"
            hideOverlay
            closeEvent={() => setSignAndEndorseChecks([])}
          >
            <DrawerBody {...mobileDrawerBodyStyles}>
              <BaselaneSimpleProgressBar
                {...{
                  step: 1,
                  totalSteps: TOTAL_PROGRESS_BAR_STEPS,
                }}
              />
              <VStack spacing={1}>
                <Heading {...headingStyles}>Sign and endorse the check</Heading>
                <Image
                  src={CheckEndorsementExampleThread}
                  srcSet={`${CheckEndorsementExampleThread2x} 2x`}
                  alt={`Example: Jane Doe, for remote deposit with ${partnerBank}`}
                  {...{ minW: '100%', h: 'auto' }}
                />
              </VStack>
              <Text textStyle="xs" mt={3}>
                In order to avoid delays or rejections, please make sure both of these items are
                completed:
              </Text>
              <CheckboxGroup value={signAndEndorseChecks} defaultValue={[]}>
                <VStack alignItems="flex-start" mt={2} spacing={1.5}>
                  <Checkbox className="primary-500" {...getCheckboxProps({ value: '1' })}>
                    <Text textStyle="sm">
                      Write &quot;for remote deposit with Thread Bank&quot; on back of check
                    </Text>
                  </Checkbox>
                  <Checkbox className="primary-500" {...getCheckboxProps({ value: '2' })}>
                    <Text textStyle="sm">Sign the check</Text>
                  </Checkbox>
                </VStack>
              </CheckboxGroup>
            </DrawerBody>
            <DrawerFooter {...footerStyles}>
              <Stack direction="row" justifyContent="flex-end" align="center" w="100%">
                <BaselaneButton
                  size="md"
                  variant="filled"
                  palette="primary"
                  onClick={onNextClicked}
                  rightIcon={<Icon16ChevronRight />}
                  isDisabled={signAndEndorseChecks.length !== 2}
                >
                  Next
                </BaselaneButton>
              </Stack>
            </DrawerFooter>
          </BaselaneDrawer>
          {/* Scan Check Drawer - Step 2 */}
          <BaselaneDrawer
            ref={mobileCheckDepositDrawerRef}
            title="Check Deposit"
            size="md"
            closeEvent={() => {
              reinitialize();
              handleCheckDepositClose();
            }}
            onClose={() => {
              reinitialize();
              handleCheckDepositClose();
            }}
            hideOverlay
          >
            <DrawerBody {...mobileDrawerBodyStyles}>
              <BaselaneSimpleProgressBar
                {...{
                  step: 2,
                  totalSteps: TOTAL_PROGRESS_BAR_STEPS,
                }}
              />
              <Heading {...headingStyles}>Details of the check</Heading>
              <VStack spacing={2}>
                <CheckDeposit
                  {...{
                    transferType: 'TRANSFER_IN',
                    setIsValidBankTransfer,
                    setTransfer,
                    checkFrontImageData,
                    setCheckFrontImageData,
                    checkBackImageData,
                    setCheckBackImageData,
                    selectedAccountTo,
                    setSelectedAccountTo,
                    transferAmount,
                    setTransferAmount,
                    setAmountErrors,
                    amountErrors,
                    handleUnsupportedBrowserError,
                    frontRotated180,
                    setFrontRotated180,
                    backRotated180,
                    setBackRotated180,
                  }}
                />
              </VStack>
            </DrawerBody>
            <DrawerFooter {...footerStyles}>
              <Stack direction="row" justifyContent="space-between" align="center" w="100%">
                <BaselaneButton
                  variant="outline"
                  palette="neutral"
                  size="md"
                  onClick={onPrevClicked}
                  leftIcon={<Icon16ChevronLeft />}
                >
                  Back
                </BaselaneButton>
                <BaselaneButton
                  size="md"
                  variant="filled"
                  palette="primary"
                  isDisabled={
                    !isValidBankTransfer ||
                    amountErrors ||
                    !checkFrontImageData ||
                    !checkBackImageData
                  }
                  onClick={onNextClicked}
                  rightIcon={<IconChevronRight />}
                >
                  Next
                </BaselaneButton>
              </Stack>
            </DrawerFooter>
          </BaselaneDrawer>
          {/* Property & Category Tagging - Step 3 */}
          <BaselaneDrawer
            ref={checkDepositTaggingDrawerRef}
            title="Check Deposit"
            size="md"
            closeEvent={onPrevClicked}
            onClose={onPrevClicked}
            hideOverlay
          >
            <DrawerBody {...mobileDrawerBodyStyles} id="transfer-drawer">
              <PropertyAndCategoryTaggingForm
                {...{
                  handlePropertySubmit,
                  handleCategorySubmit,
                  propertyOptions,
                  categoryOptions,
                  categoryIdsMap,
                  categoryMap,
                  setShowMobileDropdownPopup,
                  setShowMobileDropdownPopupAnimation,
                  showMobileDropdownPopup,
                  isValidBankTransfer: true,
                  renderCategoryDropdownItem,
                  drawerTitle: 'Check Deposit',
                  transferTypeText: 'check deposit',
                  totalSteps: TOTAL_PROGRESS_BAR_STEPS,
                  currentStep: 3,
                  titleStyles: { fontSize: 'lg' },
                }}
              />
            </DrawerBody>
            <DrawerFooter {...footerStyles}>
              <Stack direction="row" justifyContent="space-between" align="center" w="100%">
                <BaselaneButton
                  size="md"
                  variant="outline"
                  palette="neutral"
                  onClick={onPrevClicked}
                  leftIcon={<IconChevronLeft />}
                >
                  Back
                </BaselaneButton>
                <BaselaneButton
                  size="md"
                  variant="filled"
                  palette="primary"
                  onClick={onNextClicked}
                  rightIcon={<IconChevronRight />}
                >
                  Review
                </BaselaneButton>
              </Stack>
            </DrawerFooter>
          </BaselaneDrawer>
          {/* Review Check Deposit - Step 3 */}
          <BaselaneDrawer
            ref={checkDepositReviewDrawerRef}
            title="Check Deposit"
            size="md"
            closeEvent={onPrevClicked}
            onClose={onPrevClicked}
            hideOverlay
          >
            <DrawerBody {...mobileDrawerBodyStyles}>
              {initiateCheckDepositLoading || checkImageUploading ? (
                <SlLoader />
              ) : (
                <VStack>
                  {error && (
                    <BaselaneMessageCard
                      containerStyles={{
                        w: '100%',
                        bg: 'red.200',
                        borderColor: 'red.800AA',
                      }}
                      title={error.title}
                      borderColor="red"
                      textStyles={{ color: 'brand.neutral.700' }}
                      text={error.message}
                      isVertical
                    />
                  )}
                  <Heading {...headingStyles}>Review & Deposit Check</Heading>
                  <Text {...descriptionTextStyles}>
                    Your funds will be available within 3 business days.
                  </Text>
                  <BaselaneMessageCard
                    title="Signature & endorsement required"
                    borderColor="blue"
                    text={`Sign & write "for remote deposit with ${partnerBank}" on back of check before taking picture to avoid rejection.`}
                    isVertical
                  />
                  <VStack {...reviewLabelContainerStyles}>
                    <Text {...reviewLabelStyles}>Deposit To</Text>
                    <Text {...reviewPrimaryValueStyles}>
                      {selectedAccountTo?.name.split(':')[0]}
                    </Text>
                    <Text {...reviewSecondaryValueStyles}>
                      {selectedAccountTo?.name.split(':')[1]}
                    </Text>
                  </VStack>

                  <VStack {...reviewLabelContainerStyles}>
                    <Text {...reviewLabelStyles}>Check Amount</Text>
                    <Text {...reviewPrimaryValueStyles}>{transferAmount}</Text>
                  </VStack>

                  <VStack {...reviewLabelContainerStyles}>
                    <Text {...reviewLabelStyles}>Property Tag</Text>
                    <Text {...reviewPrimaryValueStyles}>
                      {getPropertyName(propertyId, unitId, propertyOptions)}
                    </Text>
                    <Text {...reviewSecondaryValueStyles}>
                      {getPropertyAddress(propertyId, propertyOptions)}
                    </Text>
                  </VStack>

                  <VStack {...reviewLabelContainerStyles}>
                    <Text {...reviewLabelStyles}>Category Tag</Text>
                    <Text {...reviewPrimaryValueStyles}>
                      {getCategoryName(categoryId, categoryOptions, categoryIdsMap)}
                    </Text>
                  </VStack>

                  <VStack {...reviewLabelContainerStyles}>
                    <Text {...reviewLabelStyles}>Front of Check</Text>
                    <Box
                      {...{
                        ...captureButtonStyles(frontRotated180),
                        ...frontImageStyle,
                        mt: '8px',
                      }}
                      // temporary solution for backgroundImage issue with chakra https://github.com/chakra-ui/chakra-ui/issues/7548
                      style={{ backgroundImage: frontImageStyle.backgroundImage }}
                    />
                  </VStack>

                  <VStack {...reviewLabelContainerStyles}>
                    <Text {...reviewLabelStyles}>Back of Check</Text>
                    <Box
                      {...{
                        ...captureButtonStyles(backRotated180),
                        ...backImageStyle,
                        mt: '8px',
                      }}
                      // temporary solution for backgroundImage issue with chakra https://github.com/chakra-ui/chakra-ui/issues/7548
                      style={{ backgroundImage: backImageStyle.backgroundImage }}
                    />
                  </VStack>
                </VStack>
              )}
            </DrawerBody>
            <DrawerFooter {...footerStyles}>
              <Stack direction="row" justifyContent="space-between" align="center" w="100%">
                <BaselaneButton
                  size="md"
                  variant="outline"
                  palette="neutral"
                  isDisabled={checkImageUploading}
                  onClick={onPrevClicked}
                  leftIcon={<IconChevronLeft />}
                >
                  Back
                </BaselaneButton>
                <BaselaneButton
                  size="md"
                  variant="filled"
                  palette="primary"
                  isDisabled={!isValidBankTransfer || checkImageUploading}
                  onClick={onNextClicked}
                  leftIcon={<IconCheck />}
                >
                  Deposit
                </BaselaneButton>
              </Stack>
            </DrawerFooter>
          </BaselaneDrawer>
          {/* Success Drawer */}
          <BaselaneDrawer
            ref={successDrawerRef}
            title="Check Deposit"
            size="md"
            closeEvent={complete}
            onClose={complete}
            hideOverlay
          >
            <DrawerBody {...mobileDrawerBodyStyles}>
              <VStack>
                <Box {...{ w: '100%', justifySelf: 'flex-start', mt: '54px' }}>
                  <IconCheckDepositSuccess />
                </Box>
                <Heading {...headingStyles}>We are processing your check</Heading>
                <Text {...descriptionTextStyles}>
                  Funds will be available within 3 business days. We will notify you if we encounter
                  any issues.
                </Text>
              </VStack>
            </DrawerBody>
            <DrawerFooter {...footerStyles}>
              <VStack {...{ w: '100%' }}>
                <BaselaneButton
                  size="md"
                  variant="filled"
                  palette="primary"
                  isDisabled={!isValidBankTransfer}
                  onClick={again}
                  leftIcon={<IconDollarCircleOutline />}
                >
                  Deposit Another Check
                </BaselaneButton>
                <BaselaneButton
                  size="md"
                  variant="outline"
                  palette="neutral"
                  isDisabled={!isValidBankTransfer}
                  onClick={complete}
                >
                  Close
                </BaselaneButton>
              </VStack>
            </DrawerFooter>
          </BaselaneDrawer>
        </VStack>
      </ChakraProvider>
    );
  }
);
export default CheckDepositDrawer;
