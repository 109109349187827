import React from 'react';
import { Outlet } from 'react-router-dom';
import { BanksProvider } from '@contexts/BanksContext';

/**
 * Note: This wrapper allows the provider to be used as a route element.
 */

const BankWrapper = () => {
  return (
    <BanksProvider>
      <Outlet />
    </BanksProvider>
  );
};

export default BankWrapper;
