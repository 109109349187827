import { truncatedText } from '@core/components/Shared/styles/text.style';

export const tableStyles = {
  cell: {
    text: {
      text: {
        textStyle: 'xs',
        color: 'brand.neutral.700',
        ...truncatedText,
      },
      subtext: {
        textStyle: 'xs',
        color: 'brand.neutral.600',
        ...truncatedText,
      },
    },
    nopadding: {
      paddingInlineEnd: '0 !important',
    },
  },
  chevroncell: {
    display: 'none',
  },
  custominvoicecell: {
    lighttext: {
      m: '0 !important',
      color: 'brand.neutral.600',
      ...truncatedText,
    },
    text: {
      m: '0 !important',
      color: 'brand.neutral.700',
      ...truncatedText,
    },
  },
};
