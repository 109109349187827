import React from 'react';
import BaselaneButton from '../BaselaneButton';

type ClearAllButtonProps = {
  clearButtonText?: string,
  selectedStagedOptions?: Array<object>,
  onClear: Function,
};

const ClearAllButton = ({
  clearButtonText,
  selectedStagedOptions,
  onClear,
}: ClearAllButtonProps) => (
  <BaselaneButton
    variant="transparent"
    palette="primary"
    isDisabled={selectedStagedOptions.length === 0}
    onClick={onClear}
  >
    {clearButtonText}
  </BaselaneButton>
);

ClearAllButton.defaultProps = {
  clearButtonText: 'Clear all',
  selectedStagedOptions: [],
};

export default ClearAllButton;
