// @flow
import React, { useRef } from 'react';
import { Formik } from 'formik';
import { Box, Text } from '@chakra-ui/react';
import { BaselaneDivider } from '@shared/components';
import EditAccountForm from '../../EditAccountForm';
import {
  DeleteImportedTransactionsAlert,
  ImportDisableAlert,
  ImportTransactionAlert,
  DeleteExternalAccountAlert,
} from '../../EditAccountForm/Alerts';
import { formatBankAccountData, handleValidation } from './helpers/accountEditDrawer.helpers';
import { updateVariablesHelper } from '../../EditAccountForm/helpers/editAccountForm.helpers';
import { smFormLabelStyles } from '../styles/accountEditDrawer.styles';

type AccountEditFormProps = {
  formikRef: any,
  initialAccount: Object,
  setFormVariables: Function,
  handleTerminateAccount: Function,
  setIsCheckedDuplicateTransactions: Function,
  setIsImportingAlertOpen: Function,
  setIsImportDisabledAlertOpen?: Function,
  isImportingAlertOpen: boolean,
  isImportDisabledAlertOpen?: boolean,
  isAlertOpenFromFooter: boolean,
  handleSaveAndClose: Function,
  handleDirtyChange?: Function,
  isDeleteImportedTransactionsAlertOpen: boolean,
  setIsDeleteImportedTransactionsAlertOpen?: Function,
};

function AccountEditForm({
  formikRef,
  initialAccount,
  setFormVariables,
  handleTerminateAccount,
  setIsCheckedDuplicateTransactions,
  setIsImportingAlertOpen,
  setIsImportDisabledAlertOpen,
  isImportingAlertOpen,
  isImportDisabledAlertOpen,
  isAlertOpenFromFooter,
  handleSaveAndClose,
  handleDirtyChange,
  isDeleteImportedTransactionsAlertOpen,
  setIsDeleteImportedTransactionsAlertOpen,
}: AccountEditFormProps): any {
  const { id: accountId } = initialAccount;
  const bankAccountFormValues = formatBankAccountData(initialAccount);
  const importTransactionLabelRef = useRef();

  const onAlertClose = () => setIsImportingAlertOpen(false);
  const cancelAlert = useRef();

  const onDisableAlertClose = () => setIsImportDisabledAlertOpen(false);
  const deleteImportedTransactionsRef = useRef();

  const onDeleteImportedTransactionsAlertClose = () =>
    setIsDeleteImportedTransactionsAlertOpen(false);
  const cancelDisableAlert = useRef();

  const handleFormUpdate = (fieldName, formValues, dirty) => {
    const variables = updateVariablesHelper(fieldName, formValues);
    setFormVariables(variables, dirty);
    handleDirtyChange(dirty);
  };

  const disableImportTransaction = () => {
    const { importTransaction } = bankAccountFormValues;

    formikRef.current?.setFieldValue('importTransaction', {
      enabled: false,
      importTransactionType: importTransaction.importTransactionType,
      updatedAt: importTransaction.updatedAt,
    });

    // Note: setTimeout or useEffect is the only solution to get the updated formik values
    //       as setFieldValue uses setState which is async
    setTimeout(() => {
      handleFormUpdate('importTransaction', formikRef.current?.values, formikRef.current?.dirty);
    }, 1);

    onAlertClose();
    onDisableAlertClose();
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={bankAccountFormValues}
        validate={(values) => handleValidation(values)}
        validateOnChange
      >
        {({ dirty, values, handleChange, handleBlur, setFieldValue, errors }) => (
          <form noValidate>
            <EditAccountForm
              {...{
                values,
                handleFormUpdate,
                handleBlur,
                handleChange,
                importTransactionLabelRef,
                dirty,
                setFieldValue,
                setIsImportingAlertOpen,
                setIsCheckedDuplicateTransactions,
                setIsImportDisabledAlertOpen,
                errors,
                setIsDeleteImportedTransactionsAlertOpen,
                isPlaidAccount: true,
              }}
            />

            {/* Terminate Button */}
            <Box>
              <BaselaneDivider />
              <Text {...smFormLabelStyles}>
                Remove this external account from Baselane. You will no longer be able to set up new
                rent collection, transfers & payments, and import transactions from this account.
              </Text>
              <DeleteExternalAccountAlert {...{ id: accountId, handleTerminateAccount }} />
            </Box>
          </form>
        )}
      </Formik>

      <ImportTransactionAlert
        {...{
          isImportingAlertOpen,
          onAlertClose,
          cancelAlert,
          disableImportTransaction,
          importTransactionLabelRef,
          isAlertOpenFromFooter,
          handleSaveAndClose,
        }}
      />

      <ImportDisableAlert
        {...{
          isAlertOpen: isImportDisabledAlertOpen,
          onAlertClose: onDisableAlertClose,
          cancelAlert: cancelDisableAlert,
          disableImportTransaction,
        }}
      />
      <DeleteImportedTransactionsAlert
        {...{
          isDeleteImportedTransactionsAlertOpen,
          onDeleteImportedTransactionsAlertClose,
          cancelAlert,
          disableImportTransaction,
          deleteImportedTransactionsRef,
          isAlertOpenFromFooter,
          handleSaveAndClose,
        }}
      />
    </>
  );
}

AccountEditForm.defaultProps = {
  handleDirtyChange: () => {},
  setIsImportDisabledAlertOpen: () => {},
  setIsDeleteImportedTransactionsAlertOpen: () => {},
  isImportDisabledAlertOpen: false,
};

export default AccountEditForm;
