export const containerStyles = {
  bgColor: 'brand.neutral.white',
  minHeight: '100%',
  height: '100%',
  spacing: 0,
  width: '100%',
  overflow: 'hidden',
};

export const headerContainerStyles = (isMobile) => ({
  width: '100%',
  direction: 'row',
  p: isMobile ? 2 : '16px 32px',
  borderBottom: '1px solid',
  borderBottomColor: 'brand.darkBlue.150',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'brand.darkBlue.800A',
});
