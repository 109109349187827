import process from 'process';

import {
  getCustomClaims,
  signInWithCustomEmail,
  signInWithGoogle,
  signUpWithCustomEmail,
} from '@core/Services/Firebase/firebase';

import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { UserRole } from './constant.helpers';
import { AuthenticationError } from './error.helpers';

type FirebaseSignInProps = {
  email: string,
  password: string,
  handleSuccess: Function,
  handleError: Function,
};

/**
 * Signs a user into firebase with email and password.
 */
export const firebaseEmailSignIn = ({
  email,
  password,
  handleSuccess,
  handleError,
}: FirebaseSignInProps) => {
  signInWithCustomEmail(email, password)
    .then(async (credential) => {
      getCustomClaims(credential.user).then((claims) => {
        const { role } = claims;
        if (role === UserRole.TENANT) {
          sendSegmentEvent('user_login_on_wrong_portal');
          handleError({ code: AuthenticationError.tenant });
        } else {
          handleSuccess(credential, claims);
        }
      });
    })
    .catch(handleError);
};

type FirebaseGoogleSignInProps = {
  handleSuccess: Function,
  handleError: Function,
};

/**
 * Signs a user up on firebase using Google Single Sign On.
 */
export const firebaseGoogleSignIn = ({ handleSuccess, handleError }: FirebaseGoogleSignInProps) => {
  signInWithGoogle()
    .then((credential) => {
      getCustomClaims(credential.user).then((claims) => {
        const { role } = claims;
        if (role === UserRole.TENANT) {
          sendSegmentEvent('user_login_on_wrong_portal');
          handleError({ code: AuthenticationError.tenant });
        } else {
          handleSuccess(credential, claims);
        }
      });
    })
    .catch(handleError);
};

type FirebaseGoogleSignUpProps = {
  email: string,
  password: string,
  handleSuccess: Function,
  handleError: Function,
};
/**
 * Signs a user up on firebase using email and password.
 */
export const firebaseEmailSignUp = ({
  email,
  password,
  handleSuccess,
  handleError,
}: FirebaseGoogleSignUpProps) => {
  signUpWithCustomEmail(email, password)
    .then((credential) => {
      getCustomClaims(credential.user).then((claims) => {
        const { role } = claims;
        if (role === UserRole.TENANT) {
          sendSegmentEvent('user_login_on_wrong_portal');
          handleError({ code: AuthenticationError.tenant });
        } else {
          handleSuccess(credential, claims);
        }
      });
    })
    .catch(handleError);
};

type GenerateSessionCookieProps = {
  token: String,
  handleSuccess: Function,
  handleError: Function,
};
/**
 * Signs a user up on firebase using email and password.
 */
export const generateSessionCookie = ({
  token,
  handleSuccess,
  handleError,
}: GenerateSessionCookieProps) => {
  const REACT_APP_ORCHESTRATION_ROOT = process?.env?.REACT_APP_ORCHESTRATION_URL?.split(
    '/graphql'
  ).join('');
  const url = `${REACT_APP_ORCHESTRATION_ROOT}/api/sessionLogin`;
  fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      idToken: token,
    }),
  })
    .then((response) => {
      response
        .json()
        .then((data) => {
          if (data?.error) {
            handleError(data?.error);
          }
          handleSuccess(data);
        })
        .catch((error) => {
          handleError(error);
        });
    })
    .catch((error) => {
      handleError(error);
    });
};
