import React from 'react';
import { Stack } from '@chakra-ui/react';

import { containerPageStyles, innerPageContainerStyles } from './styles/wrapper.styles';

type CenterContentWrapperProps = {
  children: any,
  containerStyles: Object,
  innerContainerStyles: Object,
};

const CenterContentWrapper = ({
  children,
  containerStyles = {},
  innerContainerStyles = {},
}: CenterContentWrapperProps) => {
  return (
    <Stack id="center-content-wrapper" {...{ ...containerPageStyles, ...containerStyles }}>
      <Stack {...{ ...innerPageContainerStyles, ...innerContainerStyles }}>{children}</Stack>
    </Stack>
  );
};

export default CenterContentWrapper;
