import React from 'react';
import { isMobile } from 'react-device-detect';
import { IconExclamationTriangle, IconExclamationCircle } from '@icons';
import {
  AlertHeader,
  AlertFooter,
  BaselaneAlert,
  BaselaneButton,
  BaselaneButtonGroup,
} from '@shared/components';
import customTheme from '@theme';

type ImportTransactionAlertProps = {
  isImportingAlertOpen: boolean,
  onAlertClose: Function,
  cancelAlert: Function,
  disableImportTransaction: Function,
  importTransactionLabelRef: any,
  isAlertOpenFromFooter: boolean,
  handleSaveAndClose: Function,
};

const ImportTransactionAlert = ({
  isImportingAlertOpen,
  onAlertClose,
  cancelAlert,
  disableImportTransaction,
  importTransactionLabelRef,
  isAlertOpenFromFooter,
  handleSaveAndClose,
}: ImportTransactionAlertProps) => {
  const handleYesOnAlertClick = () => {
    onAlertClose();
    if (isAlertOpenFromFooter) {
      handleSaveAndClose();
    }
  };

  const footer = (
    <BaselaneButtonGroup size="md">
      <BaselaneButton
        ref={cancelAlert}
        variant="outline"
        palette="neutral"
        size="md"
        onClick={disableImportTransaction}
      >
        No
      </BaselaneButton>
      <BaselaneButton variant="filled" palette="primary" size="md" onClick={handleYesOnAlertClick}>
        Yes
      </BaselaneButton>
    </BaselaneButtonGroup>
  );

  const mobileFooter = (
    <AlertFooter
      {...{ justify: 'stretch' }}
      cancelRef={cancelAlert}
      leftButtonEvent={handleYesOnAlertClick}
      leftButtonText="Yes, Import"
      rightButtonEvent={disableImportTransaction}
      rightButtonText="No, Back"
    />
  );

  const header = <AlertHeader icon={<IconExclamationTriangle />} />;

  const mobileHeader = (
    <AlertHeader
      {...{ justify: 'stretch' }}
      icon={<IconExclamationCircle {...{ color: customTheme.colors.red['500A'] }} />}
      title="Warning"
    />
  );

  return (
    <BaselaneAlert
      isOpen={isImportingAlertOpen}
      onClose={onAlertClose}
      leastDestructiveRef={cancelAlert}
      isCentered
      header={isMobile ? mobileHeader : header}
      body="Importing all transactions from a previously connected account may result in duplicate transactions. Are you sure you want to proceed?"
      footer={isMobile ? mobileFooter : footer}
      finalFocusRef={importTransactionLabelRef}
    />
  );
};

export default ImportTransactionAlert;
