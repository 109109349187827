// @flow

import React, { useEffect } from 'react';
import Select from 'react-dropdown-select';
import { Box, HStack, Text } from '@chakra-ui/react';
import { truncatedText } from '@shared/styles/text.style';
import { Icon16ChevronUp, Icon16ChevronDown, Icon16Calendar } from '@icons/16px';
import { Icon12Close } from '@icons/12px';
import { formatDate } from '@core/utils/formatDate';
import '../styles/dropdown.styles.scss';
import {
  dropdownContentContainerStyles,
  dropdownIconContainerStyles,
} from '../styles/dropdown.styles';
import { rangepickerStyles } from '../styles/rangepicker.styles';

type RangePickerProps = {
  classNames?: Array<string>,
  title?: string,
  finalDate: Object,
  setFinalDate: Function,
  handleCalendarClose: Function,
  isDisabled?: boolean,
  parentId?: any,
  hasDropdownClearedExternally?: boolean,
  setHasDropdownClearedExternally?: Function,
  placeholder?: string,
  onClearClick?: Function,
  dropdownPosition?: string,
  isOpen: boolean,
  onOpen: Function,
};

const RangePicker = ({
  classNames,
  title,
  finalDate,
  setFinalDate,
  handleCalendarClose,
  isDisabled,
  parentId,
  hasDropdownClearedExternally,
  setHasDropdownClearedExternally,
  placeholder,
  onClearClick,
  dropdownPosition,
  isOpen,
  onOpen,
}: RangePickerProps) => {
  const { value, calendaricon, closeicon, chevronicon } = rangepickerStyles ?? {};

  const handleClearClick = () => {
    if (onClearClick) {
      onClearClick();
    }
  };

  const handleClearAndApply = () => {
    setFinalDate({ start: null, end: null });
    handleClearClick();
  };

  const calculateDropdownSize = () => {
    const selectDropdown = document.querySelector('.react-dropdown-select-dropdown');
    const selectElem = selectDropdown.parentElement;
    const parentElem = document.querySelector(`#${parentId}`);

    // If there isn't enough space on the right, open the dropdown to the left
    const availableWidth = parentElem.clientWidth;
    const availableSpaceOnLeft = selectElem.offsetLeft;
    const availableSpaceForDropdown = availableWidth - availableSpaceOnLeft;
    const dropdownWidth = selectDropdown.clientWidth;

    // If there isn't enough space on the bottom/top, adjust the height of the dropdown
    const availableHeight = parentElem.clientHeight;
    const selectHeight = selectElem.offsetHeight;
    const availableSpaceOnTopForParent = parentElem.getBoundingClientRect().top;
    const availableSpaceOnTopForSelect = selectElem.getBoundingClientRect().top;
    const availableSpaceOnTop = availableSpaceOnTopForSelect - availableSpaceOnTopForParent;
    const availableSpaceBetweenSelectAndDropdown =
      selectDropdown.offsetTop - selectElem.clientHeight;

    const availableSpaceOnBottomForDropdown =
      availableHeight - availableSpaceOnTop - selectHeight - availableSpaceBetweenSelectAndDropdown;
    const availableSpaceOnTopForDropdown =
      availableHeight -
      availableSpaceOnBottomForDropdown -
      selectHeight -
      availableSpaceBetweenSelectAndDropdown;
    const dropdownHeight = selectDropdown.clientHeight;

    const positionName =
      Array.from(selectDropdown.classList).find((classname) => classname.includes('position')) ??
      '';
    const position = positionName.includes('top') ? 'top' : 'bottom';

    if (availableSpaceForDropdown < dropdownWidth) {
      selectDropdown.style.right = '0';
      selectDropdown.style.left = 'auto';
    }

    if (position === 'bottom' && availableSpaceOnBottomForDropdown < dropdownHeight) {
      selectDropdown.style.minHeight = `${availableSpaceOnBottomForDropdown}px`;
      selectDropdown.style.height = `${availableSpaceOnBottomForDropdown}px`;
    } else if (position === 'top' && availableSpaceOnTopForDropdown < dropdownHeight) {
      selectDropdown.style.minHeight = `${availableSpaceOnTopForDropdown}px`;
      selectDropdown.style.height = `${availableSpaceOnTopForDropdown}px`;
    }
  };

  const selectInputRenderer = () => {
    const handleDatepickerOpen = () => {
      onOpen();
    };

    const hasDatesSelected = finalDate.end && finalDate.start;

    const inputValues = hasDatesSelected
      ? `${formatDate(finalDate.start, 'MMM DD, YYYY')} - ${formatDate(
          finalDate.end,
          'MMM DD, YYYY'
        )}`
      : title;

    const values =
      inputValues === title ? (
        // when select shows generic title
        <Box className="placeholder" {...truncatedText}>
          {placeholder ?? title}
        </Box>
      ) : (
        // specific selected item
        <Text {...truncatedText}>{inputValues}</Text>
      );

    const icon = isOpen ? <Icon16ChevronUp /> : <Icon16ChevronDown />;

    return (
      <HStack>
        <Box {...calendaricon} onClick={handleDatepickerOpen}>
          <Icon16Calendar />
        </Box>

        <Box {...{ ...dropdownContentContainerStyles, ...value }} onClick={handleDatepickerOpen}>
          {values}
        </Box>

        {hasDatesSelected && (
          <Box {...closeicon(isDisabled)} onClick={handleClearAndApply}>
            <Icon12Close />
          </Box>
        )}
        <Box
          className="icon-carret"
          {...{ ...dropdownIconContainerStyles, ...chevronicon }}
          onClick={handleDatepickerOpen}
        >
          {icon}
        </Box>
      </HStack>
    );
  };

  useEffect(() => {
    if (hasDropdownClearedExternally) {
      setHasDropdownClearedExternally(false);
      handleClearAndApply();
    }
  }, [hasDropdownClearedExternally]);

  useEffect(() => {
    if (finalDate.start === null && finalDate.end === null) {
      handleCalendarClose({ startDate: null, endDate: null, isFromApplyButton: true });
    }
  }, [finalDate]);

  return (
    <Select
      className={`date-dropdown hide-dropdown ${classNames.join(' ')}`}
      dropdownPosition={dropdownPosition}
      closeOnSelect={false}
      multi={false}
      disabled={isDisabled}
      closeOnScroll
      dropdownGap={0}
      contentRenderer={selectInputRenderer}
      onDropdownOpen={calculateDropdownSize}
      dropdownHandleRenderer={() => null}
    />
  );
};

RangePicker.defaultProps = {
  classNames: ['fixed-width-dropdown'],
  title: 'Date',
  isDisabled: false,
  parentId: 'page-wrapper',
  placeholder: null,
  onClearClick: null,
  dropdownPosition: 'auto',
  hasDropdownClearedExternally: false,
  setHasDropdownClearedExternally: () => {},
};

export default RangePicker;
