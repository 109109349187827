import React from 'react';
import { Flex } from '@chakra-ui/react';
import { activeContainerStyles } from './stepNumber.styles';

type StepNumberActiveProps = {
  element: string | React.ReactElement,
};

const StepNumberActive = ({ element }: StepNumberActiveProps) => {
  return <Flex {...activeContainerStyles}>{element}</Flex>;
};

export default StepNumberActive;
