import { React } from 'react';
import { steps } from '../../helpers/formHelpers';

import SelectRecipientFooter from './SelectRecipientFooter';
import SelectPaymentMethodFooter from './SelectPaymentMethodFooter';
import BookkeepingDetailsFooter from './BookkeepingDetailsFooter';
import ReviewPaymentFooter from './ReviewPaymentFooter';
import { useCurrentStep } from '../../sendFundsStore';
import SuccessScreenFooter from './SuccessScreenFooter';
import AddRecipientFooter from './AddRecipientFooter';
import UpdateOrAddPaymentMethodFooter from './UpdateOrAddPaymentMethodFooter';
import PaymentDetailsFooter from './PaymentDetailsFooter';

type FooterProps = {
  banks: Array<Object>,
  accountsMap: Object,
  handleMakeAnotherTransfer: Function,
  handleDrawerClose: Function,
};

const Footer = ({ handleMakeAnotherTransfer, handleDrawerClose }: FooterProps) => {
  // store values
  const currentStep = useCurrentStep();
  const getFooterForCurrentStep = () => {
    switch (true) {
      case currentStep === steps.SELECT_RECIPIENT:
        return <SelectRecipientFooter handleDrawerClose={handleDrawerClose} />;

      case currentStep === steps.ADD_RECIPIENT:
        return <AddRecipientFooter />;

      case currentStep === steps.SELECT_PAYMENT_METHOD:
        return <SelectPaymentMethodFooter />;

      case currentStep === steps.UPDATE_OR_ADD_PAYMENT_METHOD:
        return <UpdateOrAddPaymentMethodFooter />;

      case currentStep === steps.PAYMENT_DETAILS:
        return <PaymentDetailsFooter />;

      case currentStep === steps.BOOKKEPPING_DETAILS:
        return <BookkeepingDetailsFooter />;

      case currentStep === steps.REVIEW_PAYMENT:
        return <ReviewPaymentFooter />;

      case currentStep === steps.ONE_TIME_SUCCESS_SCREEN:
        return (
          <SuccessScreenFooter
            handleDrawerClose={handleDrawerClose}
            handleMakeAnotherTransfer={handleMakeAnotherTransfer}
          />
        );
      case currentStep === steps.RECCURING_SUCCESS_SCREEN:
        return (
          <SuccessScreenFooter
            handleDrawerClose={handleDrawerClose}
            handleMakeAnotherTransfer={handleMakeAnotherTransfer}
          />
        );
      default:
        return null;
    }
  };

  return getFooterForCurrentStep();
};

export default Footer;
