import customTheme from '@core/theme';

export const titleStyles = {
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
  pb: '16px',
};

export const popupMessageStyles = {
  pb: '14px',
};

export const popupFormLabelStyles = {
  fontSize: 'xs',
  lineHeight: '20px',
  color: 'brand.neutral.700',
  fontWeight: 'normal',
};

export const popupFooterContainerStyles = {
  w: '100%',
  justify: 'space-between',
};

// actual css, no Chakra shorthands
export const popupFormInputStyles = {
  fontFamily: '"Ease Variable", Helvetica, Arial',
  boxSizing: 'border-box',
  lineHeight: '24px',
  fontSize: '14px',
  fontWeight: '200',
  borderRadius: '4px',
  border: '1px solid #E5E9EF',
  padding: '12px 16px',
  color: '#31325F',
  width: '100%',
  height: '48px',
  '&::placeholder': {
    color: customTheme.colors.brand.neutral['500'],
  },
  '&:focus': {
    borderColor: 'brand.darkBlue.250',
    color: customTheme.colors.brand.neutral['600'],
    boxShadow: 'inset 0px 0px 1px 1px #E1F4FF',
  },
  '&:hover': {
    borderColor: customTheme.colors.brand.darkBlue['250'],
    color: customTheme.colors.brand.neutral['600'],
  },
  '&:disabled': {
    borderColor: customTheme.colors.brand.darkBlue['100'],
    color: customTheme.colors.brand.darkBlue['300'],
  },
  '&:invalid': {
    borderColor: customTheme.colors.red['800AA'],
    '&:focus': {
      borderColor: customTheme.colors.red['800AA'],
      color: customTheme.colors.brand.neutral['700'],
      boxShadow: 'none',
    },
  },
};
