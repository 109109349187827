// @flow
import React, { useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import BaselaneButton from '../BaselaneButton';
import { modalContentStyles, modalHeaderStyles, modalBodyStyles } from './styles/modal.style';

type BaselaneModalProps = {
  defaultOpen: boolean,
  btnText: string,
  btnVariant?: string,
  btnSize?: string,
  btnClick?: Function,
  onCloseBtnClick?: Function,
  modalTitleStyles?: Object,
  modalContentStyles?: Object,
  modalBodyStyles?: Object,
  closeOnOverlayClick: boolean,
  showCloseButton: boolean,
  showHeader: boolean,
  showFooter: boolean,
  modalTitle: string,
  children?: Object,
  shouldBeClosed?: boolean,
  shouldBeOpened?: boolean,
  hasNoOverlay?: boolean,
  isCentered?: boolean,
  size?: string,
  palette?: String,
  hideButton?: boolean,
};

function BaselaneModal({
  defaultOpen,
  btnText,
  btnVariant: variant,
  btnSize,
  btnClick,
  palette,
  onCloseBtnClick,
  modalTitleStyles: mTitleStyles,
  modalContentStyles: mContentStyles,
  modalBodyStyles: mBodyStyles,
  closeOnOverlayClick,
  showCloseButton,
  showHeader,
  showFooter,
  modalTitle,
  children,
  shouldBeClosed,
  shouldBeOpened,
  hasNoOverlay,
  isCentered,
  size,
  hideButton,
}: BaselaneModalProps): any {
  const closeButtonRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: defaultOpen || shouldBeClosed,
  });

  const [isLandscape] = useMediaQuery(
    '(max-width: 824px) and (max-height: 541px) and (orientation: landscape)',
    {
      ssr: false,
    }
  );

  const buttonClick = () => {
    if (btnClick !== null) {
      onOpen();
      btnClick();
    } else {
      onOpen();
    }
  };

  useEffect(() => {
    if (shouldBeClosed) {
      onClose();
    }
  }, [shouldBeClosed]);

  useEffect(() => {
    if (shouldBeOpened) {
      onOpen();
    }
  }, [shouldBeOpened]);

  const handleClose = () => {
    onCloseBtnClick();
    onClose();
  };

  return (
    <>
      {!defaultOpen && !hideButton && (
        <BaselaneButton variant={variant} palette={palette} size={btnSize} onClick={buttonClick}>
          {btnText || 'Open'}
        </BaselaneButton>
      )}

      <Modal
        {...{ isOpen, isCentered }}
        onClose={handleClose}
        closeOnOverlayClick={closeOnOverlayClick}
        size={size}
      >
        {!hasNoOverlay && <ModalOverlay />}

        <ModalContent {...{ ...modalContentStyles(isLandscape), ...mContentStyles }}>
          {showHeader && (
            <ModalHeader {...{ ...modalHeaderStyles, ...mTitleStyles }}>
              {modalTitle || ''}
            </ModalHeader>
          )}
          {showCloseButton && <ModalCloseButton />}

          <ModalBody {...modalBodyStyles} {...mBodyStyles}>
            {children || null}
          </ModalBody>

          {showFooter && (
            <ModalFooter>
              <Button mr={1.5} onClick={handleClose} ref={closeButtonRef}>
                Close
              </Button>
              <Button variant="filled" palette="primary">
                Secondary Action
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

BaselaneModal.defaultProps = {
  children: null,
  shouldBeClosed: false,
  shouldBeOpened: false,
  hasNoOverlay: false,
  btnSize: 'md',
  btnVariant: 'filled',
  modalTitleStyles: ({}: { ... }),
  modalContentStyles: ({}: { ... }),
  modalBodyStyles: ({}: { ... }),
  btnClick: null,
  isCentered: true,
  size: 'xl',
  palette: 'primary',
  onCloseBtnClick: () => {},
  hideButton: false,
};

export default BaselaneModal;
