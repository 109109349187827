import React from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import BaselaneButton from '../../BaselaneButton';
import { monthsObj, handleEscapeKeyDown } from '../helpers/shared.helpers';
import { datePickerStyles } from '../styles/datePicker.styles';
import { MonthYearPickerProps } from '../types/monthYearPicker.types';

const MonthYearPicker = ({
  yearsArr,
  monthsArr,
  parsedMonth,
  parsedYear,
  changeYear,
  changeMonth,
  setShowMonthYearSelector,
  showInModal,
}: MonthYearPickerProps) => {
  const { monthyearpicker } = datePickerStyles ?? {};

  return (
    <HStack {...monthyearpicker.container(showInModal)}>
      <Stack {...monthyearpicker.selectcontainer}>
        {yearsArr.map((option) => {
          const isSelected = option === parsedYear;

          return (
            <BaselaneButton
              variant={isSelected ? 'filled' : 'transparent'}
              palette="primary"
              size="md"
              key={option}
              value={option}
              className={isSelected ? 'selected-year' : ''}
              {...monthyearpicker.select(isSelected)}
              onClick={(e) => {
                changeYear(e.target.value);
              }}
              onKeyDown={(e) => {
                handleEscapeKeyDown(e);
                setShowMonthYearSelector(false);
              }}
            >
              {option}
            </BaselaneButton>
          );
        })}
      </Stack>

      <Stack {...monthyearpicker.selectcontainer}>
        {monthsArr.map((option) => {
          const isSelected = option === parsedMonth;

          return (
            <BaselaneButton
              variant={isSelected ? 'filled' : 'transparent'}
              palette="primary"
              size="md"
              key={option}
              value={option}
              className={isSelected ? 'selected-month' : ''}
              {...monthyearpicker.select(isSelected)}
              onClick={(e) => {
                changeMonth(e.target.value);
                setShowMonthYearSelector(false);
              }}
              onKeyDown={(e) => {
                handleEscapeKeyDown(e);
                setShowMonthYearSelector(false);
              }}
            >
              {monthsObj[option]}
            </BaselaneButton>
          );
        })}
      </Stack>
    </HStack>
  );
};

export default MonthYearPicker;
