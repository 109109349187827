import React from 'react';
import { Box, Heading, HStack, Spacer } from '@chakra-ui/react';
import { headerContainerStyles, titleStyles, subTitleStyles } from './styles/header.style';
import RightHeaderNav from './components/RightHeaderNav';

function BaselaneHeader({ title, subtitle }: { title: any, subtitle: any }): any {
  return (
    <HStack {...headerContainerStyles}>
      <Heading {...titleStyles}>{title}</Heading>
      <Box {...subTitleStyles}>{subtitle}</Box>
      <Spacer />
      <RightHeaderNav />
    </HStack>
  );
}

export default BaselaneHeader;
