import { truncatedText } from '@core/components/Shared/styles/text.style';

export const propertyTableStyles = {
  headerrow: {
    borderColor: 'brand.darkBlue.200',
  },
  bodyrow: {
    _last: {
      border: 'none',
    },
  },
  cell: {
    container: {
      gap: 0,
      w: 'inherit',
    },
    nopadding: {
      paddingInlineEnd: '0 !important',
    },
  },
  chevroncell: {
    display: 'none',
  },
  celltext: {
    regular: {
      textStyle: 'sm',
      m: '0 !important',
      color: 'brand.neutral.700',
      ...truncatedText,
    },
    bold: {
      textStyle: 'headline-sm',
      m: '0 !important',
      color: 'brand.neutral.900',
      ...truncatedText,
    },
    xs: {
      textStyle: 'xs',
      m: '0 !important',
      color: 'brand.neutral.600',
      ...truncatedText,
    },
  },
};
