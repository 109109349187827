import React from 'react';
import { Text } from '@chakra-ui/react';
import { enableTrxMessageStyles } from '../styles/enableTrxMessage.style';

export const getEnableTransactionInfoMessage = (isEdit) => (
  <Text {...enableTrxMessageStyles}>
    Have all transactions for this account be automatically tagged to a specific property or unit to
    keep your finances organized.{' '}
    {isEdit && (
      <Text as="span">
        If auto-tagging is already enabled, any changes will only impact future transactions.
      </Text>
    )}
  </Text>
);
