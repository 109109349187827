import React from 'react';

import { useQuery } from '@apollo/client';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import { GET_PROPERTIES_DROPDOWN } from '@core/apollo/queries';
import AutoTagCommonUI from './components/AutoTagCommonUI';

type BaselaneAutoTagPropertyDropdownProps = {
  values: Object,
  onChange: Function,
  isDisabled: boolean,
};

const BaselaneAutoTagPropertyDropdown = ({
  values = {},
  onChange = () => {},
  isDisabled,
}: BaselaneAutoTagPropertyDropdownProps) => {
  const { data } = useQuery(GET_PROPERTIES_DROPDOWN);
  const propertyOptions = data ? getPropertyData(data?.property) : [];

  const getSelectedProperty = (propertyData) => {
    const { propertyId, unitId } = propertyData || {};
    const matchId = unitId ? `${propertyId}-${unitId}` : `${propertyId}`;
    return propertyOptions.find((option) => option.id === matchId);
  };

  const onDropdownSubmit = (selectedOption) => {
    onChange(selectedOption);
  };

  return (
    <AutoTagCommonUI
      selectedItem={getSelectedProperty(values)}
      onDropdownSubmit={onDropdownSubmit}
      isDisabled={isDisabled}
    />
  );
};

export default BaselaneAutoTagPropertyDropdown;
