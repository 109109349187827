// @flow
import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import { InputGroup, Input, InputLeftElement } from '@chakra-ui/react';
import { Icon16Search } from '@icons/16px';
import {
  searchBarInputGroupStyles,
  searchBarInputStyles,
} from '../TransactionsFilters/styles/transactionsFilters.styles';
import { clientSearch, emptySearchId } from './helpers/search.helpers';

type TransactionsSearchProps = {
  updateSearchText: Function,
  setInputValue: Function,
  inputValue: string,
  setFilters: Function,
  filters: Object,
  showingUncategorizedTransactions: Boolean,
  currSort: Object,
  clientSearchableData: Object,
};

function TransactionsSearch({
  updateSearchText,
  inputValue,
  setInputValue,
  filters,
  setFilters,
  showingUncategorizedTransactions,
  currSort,
  clientSearchableData,
}: TransactionsSearchProps): any {
  const debouncedSearch = useCallback(
    debounce(
      (
        nextValue,
        searchId,
        isCategorized,
        currSorts,
        accountFilters,
        tagIdFilters,
        propertyFilters,
        unitFilters,
        toFilter,
        fromFilter
      ) => {
        let updatedSearchId = { ...searchId };
        if (nextValue !== '') {
          updatedSearchId = clientSearch(nextValue, clientSearchableData);
        }

        updateSearchText(
          nextValue,
          updatedSearchId,
          isCategorized,
          currSorts,
          accountFilters,
          tagIdFilters,
          propertyFilters,
          unitFilters,
          toFilter,
          fromFilter
        );
      },
      1000
    ),
    [clientSearchableData]
  );
  const handleChange = (event) => {
    let isCategorized;
    if (showingUncategorizedTransactions) {
      isCategorized = false;
    } else {
      isCategorized = null;
    }

    const newFilter = filters;
    const { value: nextValue } = event.target;
    const accountFilter = newFilter.filter.bankAccountId;
    const tagFilter = newFilter.filter.tagId;
    const toDate = newFilter.filter.to;
    const fromDate = newFilter.filter.from;
    const propertyFilter = newFilter.filter.propertyId;

    setInputValue(nextValue);

    if (!nextValue.length) {
      debouncedSearch(
        '',
        { ...emptySearchId },
        isCategorized,
        currSort,
        accountFilter,
        tagFilter,
        propertyFilter,
        toDate,
        fromDate
      );
      setFilters(newFilter);
    }

    if (nextValue.length >= 3) {
      setFilters(newFilter);

      debouncedSearch(
        nextValue,
        { ...emptySearchId },
        isCategorized,
        currSort,
        accountFilter,
        tagFilter,
        propertyFilter,
        toDate,
        fromDate
      );
    }
  };

  return (
    <InputGroup size="lg" {...searchBarInputGroupStyles}>
      <InputLeftElement pointerEvents="none" h="40px" w="36px" ml="2px">
        <Icon16Search color="#6C7884" />
      </InputLeftElement>
      <Input
        id="search-input-field"
        {...searchBarInputStyles}
        placeholder="Search Transactions..."
        value={inputValue}
        onChange={handleChange}
      />
    </InputGroup>
  );
}

export default TransactionsSearch;
