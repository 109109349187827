import React from 'react';

function IconInitiateABankTransfer() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.22222 9.64857V18.5495M10.2222 9.64857V18.5495M15.7778 9.64857V18.5495M20.7778 9.64857V18.5495M3 20.3297L3 21.2198C3 21.8429 3 22.1545 3.1211 22.3925C3.22763 22.6019 3.39761 22.7721 3.60668 22.8787C3.84436 23 4.1555 23 4.77778 23H21.2222C21.8445 23 22.1556 23 22.3933 22.8787C22.6024 22.7721 22.7724 22.6019 22.8789 22.3925C23 22.1545 23 21.8429 23 21.2198V20.3297C23 19.7066 23 19.395 22.8789 19.157C22.7724 18.9477 22.6024 18.7775 22.3933 18.6708C22.1556 18.5495 21.8445 18.5495 21.2222 18.5495H4.77778C4.1555 18.5495 3.84436 18.5495 3.60668 18.6708C3.39761 18.7775 3.22763 18.9477 3.1211 19.157C3 19.395 3 19.7066 3 20.3297ZM12.6143 3.05868L4.39212 4.88832C3.89539 4.99885 3.64702 5.05412 3.46163 5.18786C3.2981 5.30583 3.1697 5.46612 3.09017 5.65156C3 5.8618 3 6.11657 3 6.62611L3 7.86838C3 8.49151 3 8.80307 3.1211 9.04107C3.22763 9.25042 3.39761 9.42063 3.60668 9.5273C3.84436 9.64857 4.1555 9.64857 4.77778 9.64857H21.2222C21.8445 9.64857 22.1556 9.64857 22.3933 9.5273C22.6024 9.42063 22.7724 9.25043 22.8789 9.04107C23 8.80307 23 8.49151 23 7.86838V6.62612C23 6.11657 23 5.8618 22.9098 5.65156C22.8303 5.46612 22.7019 5.30584 22.5384 5.18787C22.353 5.05412 22.1046 4.99885 21.6079 4.88832L13.3857 3.05868C13.2417 3.02665 13.1698 3.01064 13.0971 3.00425C13.0325 2.99858 12.9675 2.99858 12.9029 3.00425C12.8302 3.01064 12.7583 3.02665 12.6143 3.05868Z"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3026 6.31516L33.0811 6.76339L33.3026 6.31516ZM43.7603 21.1269C43.9857 21.2864 44.2978 21.233 44.4573 21.0076L47.0565 17.3342C47.216 17.1088 47.1626 16.7967 46.9372 16.6372C46.7118 16.4777 46.3997 16.5311 46.2402 16.7566L43.9298 20.0218L40.6645 17.7113C40.4391 17.5518 40.1271 17.6053 39.9676 17.8307C39.8081 18.0561 39.8615 18.3682 40.0869 18.5277L43.7603 21.1269ZM26.5504 3.86192C26.2756 3.8341 26.0304 4.03426 26.0025 4.309C25.9747 4.58374 26.1749 4.82901 26.4496 4.85683L26.5504 3.86192ZM33.0811 6.76339C35.8127 8.1137 38.1855 10.0894 40.0058 12.5292L40.8073 11.9312C38.8926 9.3649 36.397 7.28702 33.5242 5.86693L33.0811 6.76339ZM40.0058 12.5292C41.8262 14.9691 43.0427 17.8041 43.5563 20.8032L44.5419 20.6344C44.0017 17.4796 42.7221 14.4976 40.8073 11.9312L40.0058 12.5292ZM26.4496 4.85683C28.7512 5.08991 30.998 5.73372 33.0811 6.76339L33.5242 5.86693C31.3335 4.78405 28.9707 4.10702 26.5504 3.86192L26.4496 4.85683Z"
        fill="#000619"
      />
      <path
        d="M7.23229 34.5829L6.80481 34.8423L7.23229 34.5829ZM22.8895 43.7239C23.0679 43.9348 23.0416 44.2502 22.8307 44.4286L19.3952 47.335C19.1844 47.5134 18.8689 47.4871 18.6906 47.2762C18.5122 47.0654 18.5386 46.7499 18.7494 46.5716L21.8032 43.9881L19.2197 40.9343C19.0413 40.7235 19.0676 40.408 19.2785 40.2296C19.4893 40.0513 19.8048 40.0776 19.9831 40.2884L22.8895 43.7239ZM4.31552 28.6022C4.2569 28.3324 4.42813 28.0661 4.69798 28.0075C4.96783 27.9489 5.23411 28.1201 5.29273 28.39L4.31552 28.6022ZM7.65976 34.3236C9.24023 36.9286 11.4128 39.122 14.0004 40.7251L13.4737 41.5752C10.752 39.8889 8.46698 37.5819 6.80481 34.8423L7.65976 34.3236ZM14.0004 40.7251C16.5879 42.3282 19.5172 43.2956 22.5494 43.5486L22.4662 44.5451C19.2767 44.2791 16.1954 43.2614 13.4737 41.5752L14.0004 40.7251ZM5.29273 28.39C5.74671 30.4798 6.54473 32.4858 7.65976 34.3236L6.80481 34.8423C5.63214 32.9095 4.79292 30.7999 4.31552 28.6022L5.29273 28.39Z"
        fill="#000619"
      />
      <circle cx="35" cy="35" r="10" fill="#BEE5FF" />
      <circle
        cx="33"
        cy="34"
        r="10"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.8574 31.687L37.8574 31.687L37.8569 31.6865L35.4752 29.1685C35.4751 29.1684 35.4751 29.1683 35.475 29.1683C35.3709 29.0519 35.2029 28.975 35.0347 28.975C34.8669 28.975 34.7112 29.0394 34.5946 29.1681L33.0001 30.8158L31.4178 29.1681C31.3012 29.0394 31.1456 28.975 30.9777 28.975C30.8095 28.975 30.6415 29.0519 30.5374 29.1683L28.1434 31.6986C28.0395 31.8022 27.975 31.9566 27.975 32.1111V37.4198C27.975 37.7547 28.2469 38.025 28.5831 38.025H37.4169C37.7531 38.025 38.025 37.7547 38.025 37.4198V32.1111C38.025 31.9562 37.9603 31.8023 37.8574 31.687ZM31.2698 32.6123L31.2698 32.6123L31.2692 32.613C31.1663 32.7282 31.1016 32.8821 31.1016 33.037V35.037C31.1016 35.3719 31.3734 35.6423 31.7097 35.6423H34.2779C34.6142 35.6423 34.886 35.3719 34.886 35.037V33.037C34.886 32.8821 34.8213 32.7282 34.7184 32.613L34.7184 32.613L34.7177 32.6123L33.5435 31.3951L35.0345 29.8633L37.2306 32.1828V37.2219H28.7818V32.1951L30.9778 29.8635L32.4442 31.395L31.2698 32.6123ZM33 31.9867L34.0916 33.1212V34.8392H31.9084V33.1212L33 31.9867Z"
        fill="#000619"
        stroke="#000619"
        strokeWidth="0.05"
      />
    </svg>
  );
}

export default IconInitiateABankTransfer;
