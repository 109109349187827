import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import UserContext from '@contexts/UserContext';
import previousPageStorage from '@core/storage/previousPageStorage';
import searchStorage from '@core/storage/searchStorage';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';

/**
 * Note: This wrapper will navigate to a stored location if
 * user has a verified phone number, otherwise it passes rendering
 * on to the outlet which should handle all other scenarios.
 */
const LoginNavigationLogic = () => {
  const { user } = useContext(UserContext);
  const { phoneNumber, phoneNumberVerified } = user || {};

  // handle existing redirect data and search contents if available
  const prevPageData = previousPageStorage.read()?.split('?');
  const [previousPagePath = null, search = null] = prevPageData ?? [];

  const currentSearch = window.location.search?.split('?')?.[1] ?? null;
  const formatCurrentSearch = currentSearch ? `&${currentSearch}` : '';
  const currentSearchValue = search ? `${search}${formatCurrentSearch}` : currentSearch;

  // Store any search parameters to local storage. To be used when sending events to segment to provide utm metadata
  searchStorage.write(currentSearchValue);

  const to = previousPagePath && { pathname: previousPagePath, search };
  const isPhoneSetAndVerified = !!phoneNumber && !!phoneNumberVerified;

  // Erase the data so we aren't stuck in a redirect loop
  previousPageStorage.delete();
  inActivityLogoutStorage.delete();
  localStorage.removeItem('redirectLogin');

  return isPhoneSetAndVerified && to ? <Navigate to={to} /> : <Outlet />;
};

export default LoginNavigationLogic;
