import React from 'react';

function IconUploadCircle() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.08" cx="12" cy="12" r="12" fill="#0968BF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5581 6.55806C11.8021 6.31398 12.1979 6.31398 12.4419 6.55806L14.9419 9.05806C15.186 9.30214 15.186 9.69786 14.9419 9.94194C14.6979 10.186 14.3021 10.186 14.0581 9.94194L12.625 8.50888V14.5C12.625 14.8452 12.3452 15.125 12 15.125C11.6548 15.125 11.375 14.8452 11.375 14.5V8.50888L9.94194 9.94194C9.69786 10.186 9.30214 10.186 9.05806 9.94194C8.81398 9.69786 8.81398 9.30214 9.05806 9.05806L11.5581 6.55806ZM7 13.875C7.34518 13.875 7.625 14.1548 7.625 14.5V15.125C7.625 15.4565 7.7567 15.7745 7.99112 16.0089C8.22554 16.2433 8.54348 16.375 8.875 16.375H15.125C15.4565 16.375 15.7745 16.2433 16.0089 16.0089C16.2433 15.7745 16.375 15.4565 16.375 15.125V14.5C16.375 14.1548 16.6548 13.875 17 13.875C17.3452 13.875 17.625 14.1548 17.625 14.5V15.125C17.625 15.788 17.3616 16.4239 16.8928 16.8928C16.4239 17.3616 15.788 17.625 15.125 17.625H8.875C8.21196 17.625 7.57607 17.3616 7.10723 16.8928C6.63839 16.4239 6.375 15.788 6.375 15.125V14.5C6.375 14.1548 6.65482 13.875 7 13.875Z"
        fill="#257ED0"
      />
    </svg>
  );
}

export default IconUploadCircle;
