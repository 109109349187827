import customTheme from '@core/theme';

export const graphicsDefaultColors = {
  barOne: customTheme.colors.brand.blue['700'],
  barTwo: customTheme.colors.brand.blue['250'],
  lineOne: customTheme.colors.red['800AA'],
};

export const xTickStyle = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '11px',
  textTransform: 'capitalize',
  fill: customTheme.colors.brand.neutral['600'],
  textAnchor: 'middle',
};

export const yTickStyle = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '12px',
};

export const scrollingContainerStyle = {
  w: '100%',
  h: `100%`,
};
