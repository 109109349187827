import React from 'react';
import { BaselaneButtonIcon } from '@shared/components';
import { Icon16Edit } from '@icons/16px';

type EditBtnProps = {
  onClick: Function,
  isDisabled?: boolean,
  variant: string,
  palette: string,
  size: string,
};

const EditBtn = ({ onClick, isDisabled, variant, palette, size }: EditBtnProps) => {
  return (
    <BaselaneButtonIcon
      icon={<Icon16Edit />}
      variant={variant}
      palette={palette}
      size={size}
      isDisabled={isDisabled}
      onClick={onClick}
    />
  );
};

EditBtn.defaultProps = {
  isDisabled: false,
};

export default EditBtn;
