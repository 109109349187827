import { isMobile } from 'react-device-detect';
import referralImage from '../assets/referral-bg.svg';

export const referralStyles = () => {
  const desktopStyles = {
    card: {
      h: 'auto',
      m: {
        base: '20px 16px !important',
        sm: '20px !important',
        md: '32px !important',
        lg: '0 !important',
        '2lg': '0 !important',
      },
    },
    container: {
      p: { base: '16px', lg: '40px', '2lg': '40px' },
    },
    top: {
      container: {
        flexDir: { base: 'column-reverse', lg: 'row', '2lg': 'row' },
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: { base: '40px', lg: '40px', '2lg': '80px' },
        h: '100%',
        textAlign: { base: 'center', lg: 'left', '2lg': 'left' },
        mt: { base: '8px !important', lg: '0 !important', '2lg': '0 !important' },
      },
      left: {
        container: {
          maxWidth: { base: '100%', lg: 'calc(60% - 20px)', '2lg': 'calc(50% - 40px)' },
          width: { base: '100%', lg: 'calc(60% - 20px)', '2lg': 'calc(50% - 40px)' },
        },
        title: {
          textStyle: 'headline-2xl',
          color: '#000',
        },
        description: {
          textStyle: 'sm',
          color: '#000',
          mt: '16px !important',
        },
        sharecontainer: {
          m: '40px 0 16px 0 !important',
        },
        socialcontainer: {
          m: '0 !important',
          justifyContent: { base: 'center', sm: 'space-between' },
        },
        sendtophone: {
          display: { base: 'none', sm: 'flex' },
        },
        spacer: {
          display: { base: 'none', sm: 'flex' },
        },
      },
      right: {
        maxWidth: { base: '100%', lg: 'calc(40% - 20px)', '2lg': 'calc(50% - 40px)' },
        width: { base: '100%', lg: 'calc(40% - 20px)', '2lg': 'calc(50% - 40px)' },
        h: { base: '120px', lg: 'inherit', '2lg': 'inherit' },
        m: '0 !important',
        backgroundImage: `url(${referralImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'top center',
      },
    },
    divider: {
      my: { base: '40px !important', lg: '60px !important', '2lg': '60px !important' },
    },
    bottom: {
      container: {
        m: '0 !important',
      },
      title: {
        fontSize: { base: 'lg', lg: '2xl', '2lg': '2xl' },
        lineHeight: { base: '26px', lg: '36px', '2lg': '36px' },
        fontWeight: 'semibold',
        color: '#000',
      },
      infobox: {
        container: {
          flexDir: { base: 'column', lg: 'row', '2lg': 'row' },
          my: '24px !important',
          gap: '24px',
        },
        box: {
          background: 'brand.blue.50',
          borderRadius: '8px',
          p: '24px',
          m: '0 !important',
          w: { base: '100%', lg: '33.33%', '2lg': '33.33%' },
          h: '100%',
          display: 'flex',
          flexDir: { base: 'row', lg: 'column', '2lg': 'column' },
          gap: '16px',
        },
        step: {
          border: '1px solid',
          borderColor: 'brand.blue.700',
          boxSizing: 'border-box',
          color: 'brand.blue.700',
          padding: '5px',
          borderRadius: '50%',
          height: '32px',
          minHeight: '32px',
          width: '32px',
          minWidth: '32px',
          textAlign: 'center',
          textStyle: 'headline-xs',
          bg: 'transparent',
        },
        titleContainer: {
          gap: '4px',
          flex: 1,
        },
        title: {
          textStyle: 'headline-sm',
          color: 'brand.neutral.700',
        },
        description: {
          textStyle: 'sm',
          color: 'brand.neutral.700',
          m: '0 !important',
        },
      },
      disclaimer: {
        textStyle: 'xs',
        color: 'brand.neutral.700',
        m: '0 !important',
      },
    },
  };

  const mobileStyles = {
    card: {
      h: 'auto',
      m: {
        base: '20px 16px !important',
        sm: '20px !important',
        md: '32px !important',
        lg: '0 !important',
      },
    },
    container: {
      p: '16px',
    },
    top: {
      container: {
        flexDir: 'column-reverse',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: '40px',
        h: '100%',
        textAlign: 'center',
        mt: '10px !important',
      },
      left: {
        container: {
          maxWidth: '100%',
          width: '100%',
        },
        title: {
          textStyle: 'headline-2xl',
          color: '#000',
        },
        description: {
          textStyle: 'sm',
          color: '#000',
          mt: '16px !important',
        },
        sharecontainer: {
          m: '40px 0 0 0 !important',
        },
        socialcontainer: {
          display: 'none',
        },
      },
      right: {
        maxWidth: '100%',
        width: '100%',
        h: '120px',
        m: '0 !important',
        backgroundImage: `url(${referralImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'top center',
      },
    },
    divider: {
      my: '40px !important',
    },
    bottom: {
      container: {
        m: '0 !important',
      },
      title: {
        textStyle: 'headline-lg',
        color: '#000',
      },
      infobox: {
        container: {
          flexDir: 'column',
          my: '24px !important',
          gap: '24px',
        },
        box: {
          background: 'brand.blue.50',
          borderRadius: '8px',
          p: '24px',
          m: '0 !important',
          w: '100%',
          h: '100%',
          display: 'flex',
          flexDir: 'row',
          gap: '16px',
        },
        step: {
          border: '1px solid',
          borderColor: 'brand.blue.700',
          boxSizing: 'border-box',
          color: 'brand.blue.700',
          padding: '5px',
          borderRadius: '50%',
          height: '32px',
          width: '32px',
          textAlign: 'center',
          textStyle: 'headline-xs',
          bg: 'transparent',
        },
        titleContainer: {
          gap: '4px',
          flex: 1,
        },
        title: {
          textStyle: 'headline-sm',
          color: 'brand.neutral.700',
        },
        description: {
          textStyle: 'sm',
          color: 'brand.neutral.700',
          m: '0 !important',
        },
      },
      disclaimer: {
        textStyle: 'xs',
        color: 'brand.neutral.700',
        m: '0 !important',
      },
    },
  };

  return isMobile ? mobileStyles : desktopStyles;
};
