import { isMobile } from 'react-device-detect';
import customTheme from '@core/theme';

export const linkedAccountsTextStyles = {
  fontSize: 'xs',
  lineHeight: '20px',
  fontWeight: isMobile ? 'semibold' : 'normal',
  color: isMobile
    ? customTheme.colors.brand.neutral['700']
    : customTheme.colors.brand.neutral['600'],
};

export const textLgMdStyles = {
  textStyle: 'headline-lg',
  color: customTheme.colors.brand.neutral['700'],
};

export const textSmMdStyles = {
  textStyle: 'headline-sm',
  color: customTheme.colors.brand.neutral['700'],
};

export const textXsMdStyles = {
  textStyle: 'headline-xs',
  color: customTheme.colors.brand.neutral['700'],
};

export const truncateStyles = {
  width: '50%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};
