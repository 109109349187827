export const listContainerStyles = {
  flexDir: { base: 'column', lg: 'row' },
  m: '0!important',
  gap: '24px',
};

export const infobox = {
  boxStyles: {
    m: '0 !important',
    w: { base: '100%', lg: '33.33%' },
    h: '100%',
    display: 'flex',
    flexDir: { base: 'column' },
    gap: '16px',
  },
  textContainerStyles: {
    gap: '4px',
    flex: 1,
  },
  titleStyles: {
    textStyle: 'headline-sm',
    color: 'brand.neutral.700',
  },
  descriptionStyles: {
    textStyle: 'sm',
    color: 'brand.neutral.700',
    m: '0 !important',
  },
};
