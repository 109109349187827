export const getLeaseData = (unit) => {
  return {
    existingLeaseId: unit?.lease ? unit?.lease?.id : null,
    unitState: unit?.lease ? unit?.lease?.state : null,
    unitLeaseEndDate: unit?.lease ? unit?.lease?.endDate : null,
    leaseCreated:
      unit?.status === 'occupied' &&
      unit?.lease?.state !== 'DRAFT' &&
      unit?.lease?.state !== 'READY_TO_BE_REVIEWED',
    initialCompletedTags: unit?.lease && unit?.lease.completedTags,
  };
};

export const getInitialTabIndex = (leaseCreated, nonValidSections, unit) => {
  let initialTabIndex = 0;
  if (leaseCreated || unit?.lease?.state === 'READY_TO_BE_REVIEWED') {
    // only used for create lease, should be last tab
    initialTabIndex = 5;
  } else if (
    nonValidSections?.length >= 1 &&
    (unit?.lease?.state !== 'DRAFT' || unit?.lease?.state !== 'READY_TO_BE_REVIEWED')
  ) {
    const index = nonValidSections[0].id - 1;
    initialTabIndex = index > 0 ? index : initialTabIndex;
  }

  return initialTabIndex;
};
