import React, { forwardRef } from 'react';
import { InputGroup, InputLeftElement, Input, InputRightElement } from '@chakra-ui/react';
import { Icon16Clear, Icon16Search } from '@icons/16px';
import { BaselaneButtonIcon } from '../BaselaneButtonIcon';

import { searchGroupStyles, searchStyles } from './styles/dropdown.styles';

type SearchInputProps = {
  title: String,
  search: String,
  setSearch: Function,
};

const SearchInput = forwardRef(({ title, search, setSearch, ...rest }: SearchInputProps, ref) => {
  return (
    <InputGroup {...{ ...searchGroupStyles, ...rest }}>
      <InputLeftElement pointerEvents="none">
        <Icon16Search color="#6C7884" />
      </InputLeftElement>
      <Input
        ref={ref}
        {...{ ...searchStyles, pl: '36px' }}
        placeholder={`Search ${title}...`}
        value={search}
        onChange={setSearch}
      />
      {search && (
        <InputRightElement>
          <BaselaneButtonIcon
            {...{
              size: 'sm',
              type: 'newMinimal',
              icon: <Icon16Clear color="#001843" />,
              onClick: () => setSearch({ target: { value: '' } }),
            }}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
});

export default SearchInput;
