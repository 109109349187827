import { useQuery } from '@apollo/client';
import { GET_BANK_LIST } from '../../queries';

const useBankListQuery = (fetchPolicy = 'cache-first') => {
  const { data, loading, error, refetch } = useQuery(GET_BANK_LIST, { fetchPolicy });

  return {
    bankList: data?.bankList ?? [],
    listLoading: loading,
    listError: error,
    refetchBankList: refetch,
  };
};

export default useBankListQuery;
