export const emptyStateCardStyles = {
  py: { xl: '46px', lg: '24px' },
  h: 'auto',
  display: 'flex',
  align: 'center',
  justify: 'center',
  color: 'brand.neutral.700',
};

export const emptyStateContentStyles = {
  align: 'center',
  justify: 'center',
  w: { xl: '60%', lg: '90%' },
};

export const emptyStateTitleStyles = {
  textStyle: 'headline-md',
  mb: '24px',
  color: 'brand.neutral.700',
};
