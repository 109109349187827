import React from 'react';
import { HStack, Box } from '@chakra-ui/react';
import Placeholder from '@shared/components/BaselaneDropdown-new/DisplayInputVariations/Placeholders/Placeholder';
import DisplayInputShowingSelectedValueNames from '@shared/components/BaselaneDropdown-new/DisplayInputVariations/DisplayInputDefault/DisplayInputShowingSelectedValueNames';
import { Icon16Property } from '@icons/16px';

type DisplayInputDefaultPropertyItemProps = {
  selectedOptions: Array<Object>,
  showValueByFields: Array<string>,
  placeholder: string,
  title: string,
};

const DisplayInputDefaultPropertyItem = ({
  selectedOptions,
  placeholder,
  title,
  showValueByFields,
  ...rest
}: DisplayInputDefaultPropertyItemProps) => {
  return (
    <HStack overflow="hidden">
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder
          {...{
            placeholder,
            title,
            color: 'brand.neutral.500',
            _hover: { color: 'brand.neutral.500' },
            textStyle: 'sm',
            ...rest,
          }}
        >
          <Box>
            <Icon16Property />
          </Box>
        </Placeholder>
      ) : (
        <HStack overflow="hidden" width="inherit">
          <Box color="brand.neutral.500">
            <Icon16Property />
          </Box>
          <DisplayInputShowingSelectedValueNames
            {...{
              showValueByFields,
              selectedOptions,
              textStyle: 'sm',
            }}
          />
        </HStack>
      )}
    </HStack>
  );
};

export default DisplayInputDefaultPropertyItem;
