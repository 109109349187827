export const emptyStateCardStyles = {
  alignItems: 'flex-start',
  borderWidth: '1px',
  borderColor: 'brand.darkBlue.150',
  borderRadius: '4px',
  mt: '21px',
};

export const triggerBtnWrapperStyles = { w: '64px', alignItems: 'center' };

export const triggerBtnLableStyles = {
  fontSize: '3xs',
  lineHeight: '14px',
  fontWeight: 'normal',
  color: 'brand.blue.800A',
  textAlign: 'center',
};
