import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import { EDIT_ENTITY_ACCOUNT, NB_ACCOUNTS } from '@routes';
import { BaselaneMobileHeader, BaselaneButtonIcon } from '@shared/components';
import BankEntityContext from '@contexts/BankEntityContext';
import { IconNoteList } from '@icons';
import { Icon16ChevronLeft } from '@icons/16px';

const MobilePageHeader = () => {
  const { account } = useContext(BankEntityContext);
  const navigate = useNavigate();

  return (
    <Box borderBottom="1px solid" borderColor="brand.darkBlue.100">
      <BaselaneMobileHeader
        title={account?.bankName ?? 'Main Account'}
        linkIcon={Icon16ChevronLeft}
        shadow={false}
        routerLinkTo={{
          pathname: NB_ACCOUNTS,
          state: { showUncategorizedFromLink: true },
        }}
      >
        <BaselaneButtonIcon
          variant="transparent"
          palette="neutral"
          size="md"
          icon={<IconNoteList w={16} h={16} color="#0968BF" />}
          onClick={() => navigate({ pathname: EDIT_ENTITY_ACCOUNT })}
        />
      </BaselaneMobileHeader>
    </Box>
  );
};

export default MobilePageHeader;
