import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import formatCurrency from '../../../../../../../utils/formatCurrency';

export const getAccounts = (baselaneAccounts) => {
  const getConnectedAccount = (acc, currentBank, accumulator) => {
    if (!acc.isExternal && acc.isConnected && acc.accountStatus === 'Open') {
      accumulator.push({
        ...acc,
        institution: {
          id: currentBank.id,
          name: currentBank.name,
          plaidInstitutionName: currentBank.plaidInstitutionName,
        },
      });
    }
  };

  return baselaneAccounts?.reduce((banksAccumulator, currentBank) => {
    currentBank.bankAccounts.forEach((bankAccount) => {
      getConnectedAccount(bankAccount, currentBank, banksAccumulator);

      (bankAccount?.subAccounts || []).forEach((subAccount) => {
        getConnectedAccount(subAccount, currentBank, banksAccumulator);
      });
    });

    return banksAccumulator;
  }, []);
};

export const menuOptionsConverter = (accounts) => {
  const accountsWithoutSavings = accounts?.filter((acc) => acc.accountSubType !== 'savings');
  return accountsWithoutSavings?.map((item) => {
    const { accountName, accountNumber, nickName, institution, id, availableBalance } = item ?? {};
    const { id: institutionId, name: institutionName } = institution ?? {};

    const last4Digits = accountNumber.substring(accountNumber.length - 4, accountNumber.length);
    const label =
      nickName && nickName.match(/-[ ]?\d/g) ? nickName : `${nickName} - ${last4Digits}`;
    const optionLabel = nickName ? label : `${accountName} - ${last4Digits}`;

    return {
      id,
      label: optionLabel,
      value: formatCurrency(availableBalance).inDollars,
      group: {
        name: institutionName || `Baselane ${institutionId}`,
        id: institutionId,
      },
      groupId: institutionId,
    };
  });
};

const inputMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
  allowDecimal: true,
};
export const currencyMask = createNumberMask(inputMaskOptions);

export const MIN_REDEMPTION_AMOUNT = 10;
