export const survey = {
  version: '1.3',
  questions: [
    {
      question: 'How many rental units (doors) do you own?',
      eventString: 'number_of_doors',
      answers: ['0', '1-3', '4-10', '11+'],
    },
    {
      question: 'How do you manage your properties?',
      eventString: 'manage_property',
      answers: ['Self-manage', 'Use a property manager', 'Combination of both'],
    },
    {
      question: 'How much experience do you have owning rental properties?',
      eventString: 'landlord_experience',
      answers: ['Just getting started', '1-4 years', '5-10 years', '11+ years'],
    },
    {
      question: 'How did you hear about us?',
      eventString: 'source',
      answers: [
        {
          label: 'Search engine (Google, Bing, etc.)',
          value: 'Search engine',
        },
        'Facebook or Instagram',
        'YouTube',
        'Friend or colleague',
        'Podcast or Webinar',
        'BiggerPockets',
        {
          label: 'Influencer',
          value: 'Influencer',
          type: 'text',
        },
        {
          label: 'Online forums (FB groups, Reddit, etc.)',
          value: 'Online forums',
        },
      ]
        .sort(() => Math.random() - 0.5)
        .concat([{ label: 'Other', value: 'Other', type: 'text' }]),
    },
  ],
};
