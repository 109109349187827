import IconCheck from '@icons/legacy/IconCheck';

const baselaneAccList = [
  {
    label: IconCheck,
    value: 'If you are opening an account for a different legal entity.',
  },
  {
    label: null,
    value: 'OR',
  },
  {
    label: IconCheck,
    value: 'If you are opening an account for a different individual. ',
  },
];

export default baselaneAccList;
