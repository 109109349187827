import { isMobile } from 'react-device-detect';
import customTheme from '@core/theme';

export const disclaimerContainerStyles = {
  pl: isMobile ? '0' : '40px',
  maxWidth: '640px',
  minWidth: isMobile ? 'initial' : '580px',
};

export const applicationContainerStyles = {
  p: isMobile ? '0 16px 24px 16px' : '0px',
  spacing: isMobile ? '70px' : '32px',
};

export const applicationTitleStyles = {
  fontSize: isMobile ? 'lg' : '2xl',
  fontWeight: 'medium',
  lineHeight: isMobile ? '26px' : '32px',
  color: customTheme.colors.brand.neutral['700'],
};

export const applicationSubTextStyles = {
  textStyle: 'xs',
  color: customTheme.colors.brand.neutral['600'],
  mt: '8px',
};

export const tabPanelStyles = {
  p: isMobile ? '0' : '0 0 16px 40px',
  position: 'relative',
  left: '0',
  maxWidth: '640px',
  minWidth: isMobile ? 'initial' : '580px',
  width: '100%',
};

export const footerStyles = () => ({
  justifyContent: 'left',
  width: isMobile ? '100%' : '100%',
  margin: '36px 0',
  padding: isMobile ? '0 24px' : '0',
  position: 'relative',
  background: 'brand.neutral.white',
  bottom: '0px',
  display: 'block',
  height: '120px',
});
