import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { size } from './input';

/**
 * This overrides Chakra's default <Select /> styles.
 *
 * Note: _field_ sets the styles for the actual select.
 * _icon_ sets the styles for the icon, usually a chevron.
 *
 * see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/select.ts for implementation details
 */

const parts = ['field', 'icon'];
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Select = defineMultiStyleConfig({
  baseStyle: {
    field: {
      textStyle: 'sm',
      paddingInlineStart: 1,
    },
  },
  sizes: {
    lg: {
      field: {
        px: size.lg.px,
      },
    },
    md: {
      field: {
        px: size.md.px,
      },
    },
    sm: {
      field: {
        px: size.sm.px,
      },
    },
    xs: {
      field: {
        px: size.xs.px,
      },
    },
  },
});
