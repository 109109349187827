import React from 'react';
import { IconExclamationCircle } from '@icons';
import { AlertHeader, AlertFooter, BaselaneAlert } from '@shared/components';
import customTheme from '@theme';

type ImportDisableAlertProps = {
  isAlertOpen: boolean,
  onAlertClose: Function,
  cancelAlert: Function,
  disableImportTransaction: Function,
};

const ImportDisableAlert = ({
  isAlertOpen,
  onAlertClose,
  cancelAlert,
  disableImportTransaction,
}: ImportDisableAlertProps) => {
  const footer = (
    <AlertFooter
      cancelRef={cancelAlert}
      leftButtonEvent={disableImportTransaction}
      leftButtonText="Yes, Disable"
      rightButtonEvent={onAlertClose}
      rightButtonText="No, Keep Enabled"
    />
  );

  const header = (
    <AlertHeader
      icon={<IconExclamationCircle {...{ color: customTheme.colors.red['500A'] }} />}
      title="Are you sure you want to disable importing?"
    />
  );

  return (
    <BaselaneAlert
      isOpen={isAlertOpen}
      onClose={onAlertClose}
      leastDestructiveRef={cancelAlert}
      isCentered
      header={header}
      body="Future transactions will no longer be imported, previous transactions will remain visible."
      footer={footer}
    />
  );
};

export default ImportDisableAlert;
