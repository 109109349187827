import React from 'react';
import { NB_ACCOUNTS, HOME } from '@routes';
import {
  IllustrationBanking,
  IllustrationBookkeeping,
  IllustrationRentcollection,
} from '@illustrations';

export const ONBOARDING_CARDS = [
  {
    id: 'landlord_banking-option',
    title: 'Open a Landlord Banking Account ',
    description:
      'Multiple accounts per property, no account fees or minimum balances, rewards & so much more.',
    segmentEvent: 'landlord_banking',
    buttonText: 'Get Started',
    linkRoute: NB_ACCOUNTS,
    action: 'baselane-account',
    image: <IllustrationBanking />,
  },
  {
    id: 'rent_collection-option',
    title: 'Start Collecting Rent Online',
    description: 'The most convenient way to get paid. Rent deposited into your bank account.',
    segmentEvent: 'rent_collection',
    buttonText: 'Get Started',
    linkRoute: HOME,
    action: 'add-property',
    image: <IllustrationRentcollection />,
  },

  {
    id: 'bookkeeping-option',
    title: 'Set Up Bookkeeping & Analytics',
    description:
      'Keep track of your property income & expenses with automated reporting & analytics.',
    segmentEvent: 'bookkeeping',
    buttonText: 'Get Started',
    linkRoute: NB_ACCOUNTS,
    action: 'connect-account',
    image: <IllustrationBookkeeping />,
  },
];
