// @flow
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useGate } from 'statsig-react';
import * as ROUTES from '@routes';

type FeatureGateGuardProps = {
  name: String,
};

const FeatureGateGuard = ({ name }: FeatureGateGuardProps): any => {
  const { value } = useGate(name);
  return value ? <Outlet /> : <Navigate replace to={ROUTES.HOME} />;
};

export default FeatureGateGuard;
