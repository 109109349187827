// import { convertBusinessVertical } from '../forms/BusinessInformationForm/helpers/businessInformation.helpers';

import process from 'process';

export const convertApplicationDataToFormVariables = (application = {}) => {
  const {
    id,
    completedTags,
    contact = {},
    legalAddress = {},
    dba,
    businessVertical,
    website,
  } = application;
  const { dateOfBirth: dob, ssn, ein, fullName = {} } = contact || {};
  const { first: firstName = '', last: lastName = '' } = fullName || {};
  const { street: address, city, state, postalCode: zipcode, country, unit } = legalAddress || {};

  const businessInformation = completedTags?.includes('HOME_ADDRESS')
    ? { dba, ein, businessVertical, website }
    : {
        ...(dba ? { dba } : {}),
        ...(ein ? { ein } : {}),
        ...(businessVertical ? { businessVertical } : {}),
        ...(website ? { website } : {}),
      };

  return {
    ...(completedTags ? { completedTags } : {}),
    ...(id ? { id } : {}),
    ...(dob ? { dob } : {}),
    ...(ssn ? { ssn } : {}),
    ...(firstName ? { firstName } : {}),
    ...(lastName ? { lastName } : {}),
    ...(address ? { address } : {}),
    ...(city ? { city } : {}),
    ...(state ? { state } : {}),
    ...(unit ? { unit } : {}),
    ...(zipcode ? { zipcode } : {}),
    ...(country ? { country } : {}),
    ...businessInformation,
  };
};

export const getTokenizeData = async (unitAccountData) => {
  const {
    ssn: ssnData = null,
    ein: einData = null,
    dateOfBirth: dateOfBirthData = null,
  } = unitAccountData;

  // tokenize sensitive data
  const vgsProxyObj = process.env.REACT_APP_VGS_PROXY;
  const [password, username] = vgsProxyObj.split('/').reverse();
  const basicAuth = `${username}:${password}`;

  // ssn and ein need to have same classifer we are using format to differentiate the two
  // if we don't do this data will overlap with each other.
  const bodyParam = {
    data: [
      { value: ssnData, name: 'ssn', format: 'UUID' },
      { value: dateOfBirthData, name: 'dob', format: 'UUID' },
      { value: einData, name: 'ssn', format: 'VGS_FIXED_LEN_GENERIC' },
    ].reduce((acc, { value, name, format }) => {
      const data = {
        value,
        classifiers: [name],
        format,
      };

      if (value && value.length <= 10) {
        acc.push(data);
        return acc;
      }

      return acc;
    }, []),
  };

  if (bodyParam.data.length === 0) {
    return null;
  }

  const requestUrl = process.env.REACT_APP_VGS_API_URL;

  const response = await fetch(`${requestUrl}/aliases`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(basicAuth)}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyParam),
  });

  return response.json();
};

export const sanitizeTokenizedData = (res, tabIndex) => {
  const aliasGroups = res?.data ?? [];
  // find ein alias
  const einGroup = aliasGroups.find((ag) =>
    ag.aliases.find((a) => a.format === 'VGS_FIXED_LEN_GENERIC')
  );
  const einToken = einGroup
    ? einGroup.aliases.find((a) => a.format === 'VGS_FIXED_LEN_GENERIC')
    : einGroup;

  // find ssn alias
  const ssnGroup = aliasGroups.find(
    (ag) => ag.aliases.find((a) => a.format === 'UUID') && ag.classifiers[0] === 'ssn'
  );
  const ssnToken = ssnGroup ? ssnGroup.aliases.find((a) => a.format === 'UUID') : ssnGroup;
  const dobGroup = aliasGroups.find(
    (ag) => ag.aliases.find((a) => a.format === 'UUID') && ag.classifiers[0] === 'dob'
  );

  // find dob alias
  const dobToken = dobGroup ? dobGroup.aliases.find((a) => a.format === 'UUID') : dobGroup;

  let ein = null;
  let ssn = null;
  let dateOfBirth = null;

  const piiData = {};

  if (einToken && tabIndex === 2) {
    ein = einToken?.alias;
    piiData.ein = ein;
  }
  if (dobToken) {
    dateOfBirth = dobToken?.alias;
    piiData.dateOfBirth = dateOfBirth;
  }
  if (ssnToken && tabIndex === 3) {
    ssn = ssnToken?.alias;
    piiData.ssn = ssn;
  }

  return piiData;
};

export const convertFormVariablesToSubmit = (variables) => {
  const {
    firstName,
    lastName,
    dob,
    address: street,
    city,
    state,
    zipcode: postalCode,
    country = 'US',
    ssn: ssnValue,
    ein: einValue,
    dba,
    businessVertical,
    website: websiteValue,
    ip,
    unit,
  } = variables;

  const ssn = ssnValue ? ssnValue.replace(/-/g, '') : ssnValue;
  const ein = einValue ? einValue.replace('-', '') : einValue;

  const website = websiteValue?.trim() === 'N/A' ? null : websiteValue?.trim();

  const [month, day, year] = dob?.split('/') ?? [];
  const dateOfBirth = dob?.length <= 10 && dob.includes('/') ? `${year}-${month}-${day}` : dob;

  const fullName = firstName && lastName ? { first: firstName, last: lastName } : null;
  const address = street ? { street, city, state, postalCode, country, unit } : null;

  // this needs phone, email and ip inserted from elsewhere, added into variables
  // this also needs applicationId added (not if form) same for action
  // OR create application with different save and add phone, email and ip then

  return {
    ...(fullName ? { fullName } : {}),
    ...(dateOfBirth ? { dateOfBirth } : {}),
    ...(address ? { address } : {}),
    ...(ssn ? { ssn } : {}),
    ein,
    dba,
    businessVertical,
    website,
    ip,
  };
};

export const checkIsSectionValid = ({
  newCompletedTags,
  applicationMenuOptions,
  setCompletedTags,
  setApplicationMenuOptions,
}) => {
  if (newCompletedTags) {
    const newOptions = applicationMenuOptions.map((item, index) => {
      const newItem = item;
      const isServerValid = newCompletedTags.filter(
        (n) => n === applicationMenuOptions[index].name
      );

      if (isServerValid.length) {
        newItem.isValid = true;
      } else {
        newItem.isValid = false;
      }
      return newItem;
    });
    setCompletedTags(newCompletedTags);
    setApplicationMenuOptions(newOptions);
  } else {
    const newOptions = applicationMenuOptions.map((item) => {
      const newItem = item;
      newItem.isValid = false;
      return newItem;
    });
    setApplicationMenuOptions(newOptions);
  }
};

export const applicationSegmentEventNames = (isOnboardingCompleted) => [
  `${isOnboardingCompleted ? 'baselane' : 'onboarding'}_banking_complete_personal_info`,
  `${isOnboardingCompleted ? 'baselane' : 'onboarding'}_banking_complete_personal_address`,
  `${isOnboardingCompleted ? 'baselane' : 'onboarding'}_banking_complete_business_info`,
  `${isOnboardingCompleted ? 'baselane' : 'onboarding'}_banking_complete_ssn`,
];
