// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=2925%3A97142
import React from 'react';
import { Box, Text, Stack } from '@chakra-ui/react';
import { BaselaneButton, BaselaneGrid } from '@shared/components';
import {
  sectionTitleStyles,
  noMarginStyles,
  listStyles,
} from '../../components/styles/emptyState.shared.styles';
import { emptyStateCardStyles } from './styles/emptyState';
import ConnectBankAccountList from '../../components/EmptyStateExternalAccountList/ConnectBankAccountList';
import ConnectCreditCardList from '../../components/EmptyStateExternalAccountList/ConnectCreditCardList';

type ExternalEmptyStateViewProps = {
  onCloseAddAccountDrawer: Function,
  addExternalAccountDrawerRef: any,
};

function ExternalEmptyStateView({
  onCloseAddAccountDrawer,
  addExternalAccountDrawerRef,
}: ExternalEmptyStateViewProps): any {
  const gridItems = [
    {
      element: (
        <Stack h="100%" py="32px" px="42px" justifyContent="space-between">
          <Text {...sectionTitleStyles}>External Account</Text>
          <Box>
            <BaselaneButton
              variant="outline"
              palette="neutral"
              size="md"
              onClick={(e) => {
                addExternalAccountDrawerRef?.current.open(e);
                onCloseAddAccountDrawer();
              }}
            >
              Connect External Account
            </BaselaneButton>
          </Box>
        </Stack>
      ),
    },
    {
      element: (
        <Stack {...noMarginStyles} py="24px" pr="42px">
          <Box {...listStyles}>
            <Box pl="42px">
              <ConnectBankAccountList styles={{ mb: '17px' }} />
              <ConnectCreditCardList styles={{ mb: '17px' }} />
            </Box>
          </Box>
        </Stack>
      ),
    },
  ];
  return (
    <Box mt="32px">
      <BaselaneGrid
        gridItems={gridItems}
        templateColumns="1fr 1fr"
        templateRows="repeat(1, 1fr)"
        gridStyles={{ ...emptyStateCardStyles }}
        gridItemStyles={{ h: '100%' }}
      />
    </Box>
  );
}

export default ExternalEmptyStateView;
