import React from 'react';
import { Box, HStack, Text, FormErrorMessage } from '@chakra-ui/react';
import { IconExclamationCircle } from '@icons';
import {
  errorContainer,
  transferDropdownErrorText,
} from '@core/components/NativeBankingPage/MainContent/components/BankTransfer/styles/bankTransfer.styles';

type ErrorMessageProps = {
  message?: string,
  containerStyles: object,
};

const ErrorMessage = ({ message, containerStyles }: ErrorMessageProps) => {
  return (
    <HStack {...errorContainer} {...containerStyles}>
      <Box>
        <IconExclamationCircle w={13.33} h={13.33} color="#C93A3A" />
      </Box>
      <FormErrorMessage {...transferDropdownErrorText} as="span">
        <Text as="span">{message}</Text>{' '}
      </FormErrorMessage>
    </HStack>
  );
};

ErrorMessage.defaultProps = {
  message: null,
};

export default ErrorMessage;
