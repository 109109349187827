import React from 'react';
import { InputGroup, InputRightElement } from '@chakra-ui/react';
import IconLock from '@icons/legacy/IconLock';

type LockInputWrapperProps = { children: any };

const LockInputWrapper = ({ children }: LockInputWrapperProps) => (
  <InputGroup>
    {children}
    <InputRightElement color="brand.darkBlue.250">
      <IconLock />
    </InputRightElement>
  </InputGroup>
);

export default LockInputWrapper;
