import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_SCHEDULED_PAYMENTS } from '@core/apollo/queries';
import { DEFAULT_SORT_FIELD, DEFAULT_SORT_DIR } from './helpers';

const useScheduledPayments = (options) => {
  const DEFAULT_SORT_OPTIONS = {
    sort: {
      field: DEFAULT_SORT_FIELD,
      direction: DEFAULT_SORT_DIR,
    },
  };

  // Check if options is undefined or empty, set to default options if true
  const effectiveOptions =
    options && Object.keys(options).length !== 0 ? options : DEFAULT_SORT_OPTIONS;

  const { data, loading, error, refetch } = useQuery(GET_SCHEDULED_PAYMENTS, {
    variables: {
      input: {
        ...effectiveOptions,
      },
    },
  });

  // Memoize the returned values
  const result = useMemo(
    () => ({
      data,
      loading,
      error,
      refetch,
    }),
    [data, loading, error, refetch]
  );

  return result;
};

export default useScheduledPayments;
