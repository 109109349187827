// @flow
import React, { useContext, useEffect, useRef } from 'react';
import { Box, CircularProgress, Text, Spacer, Spinner, Stack } from '@chakra-ui/react';
import TransactionContext from '@contexts/TransactionContext';
import { IconArrowRight, IconStar, IconLightBulb } from '@icons';
import { BaselaneButton, BaselaneDivider } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { getStatus } from './helpers/categorizedTrxsBanner.helpers';
import DrawerBenefitsOfCategorization from '../DrawerBenefitsOfCategorization';

import {
  bannerContainerStyles,
  spinnerStyles,
  statusStyles,
  categorizedContainerStyles,
  categorizedValueStyles,
  uncategorizedContainerStyles,
  uncategorizedValueStyles,
  sectionTitleStyles,
  boldedTextStyles,
  dividerStyles,
} from './styles/categorizedTrxsBanner.styles';

const CategorizedTrxsBanner = ({ isOutsideTrxPage }: { isOutsideTrxPage: boolean }): any => {
  const {
    transactionsSummaryLoading,
    categorizedTxCountInPercentage,
    uncategorizedAbsoluteAmount,
    uncategorizedCount,
    uncategorizedHiddenTxCount,
    setSummaryFilter,
  } = useContext(TransactionContext);

  const status = getStatus(categorizedTxCountInPercentage);
  const isMVP = categorizedTxCountInPercentage === 100;
  const uncategorizedTrxCount = uncategorizedCount ?? 0;

  const benefitsOfCategorizationDrawer = useRef();

  const onHandleWhyCategorizeTrxsClick = () => {
    sendSegmentEvent('click_transaction_why_categorize');
    benefitsOfCategorizationDrawer?.current?.open();
  };

  useEffect(() => {
    if (!isOutsideTrxPage) {
      setSummaryFilter({});
    }
  }, [isOutsideTrxPage]);

  return (
    <>
      <Box {...bannerContainerStyles(transactionsSummaryLoading)}>
        {transactionsSummaryLoading ? (
          <Spinner {...spinnerStyles} />
        ) : (
          <Stack direction="row">
            <Stack direction="row">
              {isMVP ? (
                <IconStar w="40" h="40" color="#77BEFE" />
              ) : (
                <CircularProgress
                  min={0}
                  max={100}
                  value={categorizedTxCountInPercentage}
                  size="40px"
                  thickness="12px"
                  trackColor="brand.darkBlue.150"
                  {...statusStyles(status)}
                />
              )}

              <Stack {...categorizedContainerStyles}>
                <Text {...sectionTitleStyles}>CATEGORIZED</Text>
                <Text {...categorizedValueStyles}>
                  <Box as="span" {...boldedTextStyles}>
                    {`${categorizedTxCountInPercentage}%`}
                  </Box>{' '}
                  of transactions{' '}
                  <Box as="span" {...boldedTextStyles} {...statusStyles(status)}>
                    {status}
                  </Box>
                </Text>
              </Stack>
            </Stack>

            <Spacer />

            <Stack direction="row">
              {!isMVP && (
                <Stack>
                  <Text {...sectionTitleStyles}>UNCATEGORIZED</Text>
                  <Stack direction="row" {...uncategorizedContainerStyles}>
                    <Text {...uncategorizedValueStyles}>
                      <Box as="span" {...boldedTextStyles}>
                        {uncategorizedTrxCount - uncategorizedHiddenTxCount}
                      </Box>
                      {` transaction${
                        uncategorizedTrxCount - uncategorizedHiddenTxCount === 1 ? '' : 's'
                      }`}
                    </Text>
                    <IconArrowRight w="16" h="16" color="#6C7884" />
                    <Text {...uncategorizedValueStyles}>
                      <Box as="span" {...boldedTextStyles}>
                        {formatCurrency(uncategorizedAbsoluteAmount).inDollars}
                      </Box>
                    </Text>
                  </Stack>
                </Stack>
              )}

              <BaselaneDivider isVertical styles={dividerStyles} />
              <BaselaneButton
                variant="transparent"
                palette="primary"
                size="sm"
                leftIcon={<IconLightBulb w="16" h="16" />}
                onClick={onHandleWhyCategorizeTrxsClick}
              >
                Why Categorize Transactions
              </BaselaneButton>
            </Stack>
          </Stack>
        )}
      </Box>
      <DrawerBenefitsOfCategorization drawerRef={benefitsOfCategorizationDrawer} />
    </>
  );
};

export default CategorizedTrxsBanner;
