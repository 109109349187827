import React from 'react';
import { Outlet } from 'react-router-dom';
import { CashFlowProvider } from '@contexts/CashFlowContext';

/**
 * Note: This wrapper allows the provider to be used as a route element.
 */

const CashFlowWrapper = () => {
  return (
    <CashFlowProvider>
      <Outlet />
    </CashFlowProvider>
  );
};

export default CashFlowWrapper;
