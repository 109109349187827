import React from 'react';
import BaselaneAlert from '../BaselaneAlert';
import ApplicationTypeButtons from './ApplicationTypeButtons';
import AlertHeader from '../AlertHeader';

type BaselaneApplicationTypePopupProps = {
  handleActionButton: Function,
  isOpen: Function,
  onClose: Function,
  buttonStyles?: Object,
};

const BaselaneApplicationTypePopup = ({
  handleActionButton,
  isOpen,
  onClose,
  buttonStyles,
}: BaselaneApplicationTypePopupProps) => {
  const header = (
    <AlertHeader
      {...{
        title: 'Select an Account Type',
        icon: null,
        subtitle: 'Opening a Baselane Banking account takes less than 3 minutes.',
        isInline: false,
      }}
    />
  );

  return (
    <BaselaneAlert
      {...{ isOpen, onClose, size: '2xl', header, showCloseButton: true }}
      body={<ApplicationTypeButtons {...{ handleActionButton, buttonStyles }} />}
    />
  );
};

BaselaneApplicationTypePopup.defaultProps = {
  buttonStyles: {},
};

export default BaselaneApplicationTypePopup;
