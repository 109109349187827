import React from 'react';
import { TableCaption } from '@chakra-ui/react';

type BaselaneResponsiveTableCaptionProps = {
  /**
   * Content of caption
   */
  children?: React.Node,
  textAlign?: string /* Places content. Default value left */,
  placement?: string /* Accepts values "top" or "bottom" */,
};

/**
 * Renders a single caption of the table. Caption tag element should always be placed after Table tag element
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveTableCaption = ({
  children,
  textAlign,
  placement,
  ...rest
}: BaselaneResponsiveTableCaptionProps) => {
  return (
    <TableCaption {...rest} textAlign={textAlign} placement={placement}>
      {children}
    </TableCaption>
  );
};

BaselaneResponsiveTableCaption.defaultProps = {
  children: null,
  textAlign: 'left',
  placement: 'top',
};

export default BaselaneResponsiveTableCaption;
