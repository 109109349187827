import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { AgreeToTerms } from '@shared/components';
import { IconLock } from '@icons';
import { Icon12Error } from '@icons/12px/index';
import { ssnMask } from '@core/utils/masks';
import { formErrorStyles, formLabelStyles } from '@shared/styles/input.style';
import DisclaimerText from '@shared/components/DisclaimerText';
import { VGSEditInput } from '../../components';
import { useVGSShowField } from '../../hooks/useVGSShowField';
import { handleValidation, initialVariables } from './helpers/ssn.helpers';
import { textStyles } from './styles/ssn.styles';
import { disclaimerContainerStyles } from '../../styles/unifiedBankingFlow.styles';

type SocialSecurityNumberFormProps = {
  socialSecurityNumberRef: any,
  formData: Object,
};

const SocialSecurityNumberForm = ({
  socialSecurityNumberRef,
  formData,
}: SocialSecurityNumberFormProps) => {
  const { fieldToggleButton, isEdit, showField } = useVGSShowField(formData.ssn, 'ssn');

  return (
    <Box h="100%">
      <Formik
        innerRef={socialSecurityNumberRef}
        validate={(values) => handleValidation(values)}
        initialValues={initialVariables(formData)}
        validateOnBlur
        enableReinitialize
      >
        {({ values, setFieldTouched, setFieldValue, handleChange, errors, touched }) => (
          <Stack h="100%" spacing="40px">
            <FormControl isRequired isInvalid={errors.ssn && touched.ssn && isEdit} className="vgs">
              <HStack justifyContent="space-between">
                <FormLabel htmlFor="ssn" {...formLabelStyles.xs}>
                  Social Security Number
                </FormLabel>
                {fieldToggleButton}
              </HStack>
              {formData.ssn && !isEdit ? (
                showField
              ) : (
                <VGSEditInput
                  {...{
                    inputProps: { mask: ssnMask, placeholder: 'xxx-xx-xxxx' },
                    fieldName: 'ssn',
                    isEdit,
                  }}
                />
              )}

              <FormErrorMessage {...formErrorStyles} ml={0}>
                <Box as="span" mr={0.5}>
                  <Icon12Error />
                </Box>
                {errors.ssn}
              </FormErrorMessage>
              <Stack direction="row" {...textStyles} align="center">
                <IconLock w={10} h={11} />
                <Text>Your information is secured with bank-level 256-bit encryption.</Text>
              </Stack>
            </FormControl>
            <FormControl isInvalid={errors.agreeToTerms && touched.agreeToTerms}>
              <AgreeToTerms
                onChange={(e) => {
                  handleChange(e);
                  setFieldTouched('agreeToTerms', e.target.checked);
                  setFieldValue('agreeToTerms', e.target.checked);
                }}
                isChecked={values.agreeToTerms}
              />
              <FormErrorMessage {...formErrorStyles}>{errors.agreeToTerms}</FormErrorMessage>
            </FormControl>
            {isMobile && (
              <Box {...disclaimerContainerStyles}>
                <DisclaimerText />
              </Box>
            )}
          </Stack>
        )}
      </Formik>
    </Box>
  );
};

export default SocialSecurityNumberForm;
