/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Spacer, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import UpcomingPayment from './UpcomingPayment';

const UpcomingPaymentsList = ({ payments }) => {
  const untilUserCancels = !payments?.lastPaymentDate;

  const transfers = payments?.transfers ?? [];

  const showEllipsis = untilUserCancels || (!untilUserCancels && transfers.length > 12);

  let upcomingPayments = transfers?.slice(0, 12);

  if (untilUserCancels) {
    upcomingPayments = transfers?.slice(0, 11);
  }

  if (transfers.length > 12) {
    upcomingPayments = transfers.slice(0, 10);
  }

  return (
    <>
      {upcomingPayments.map((upcomingPayment) => (
        <UpcomingPayment key={upcomingPayment.transferDate} upcomingPayment={upcomingPayment} />
      ))}
      {/* Ellipsis. */}
      <Box>
        {showEllipsis ? (
          <Text textStyle="sm" textAlign="left">
            ...
          </Text>
        ) : null}
      </Box>
      {/* Last payment. */}
      {!untilUserCancels && transfers.length > 12 ? (
        <UpcomingPayment upcomingPayment={transfers[transfers.length - 1]} />
      ) : null}
      {/* Ends. */}
      <Box display="flex">
        <Text textStyle="xs-heavy">Ends</Text>
        <Spacer />
        {payments?.lastPaymentDate ? (
          <Text textStyle="sm">
            {DateTime.fromISO(payments.lastPaymentDate).toFormat('MMM d, yyyy')}
          </Text>
        ) : (
          <Text textStyle="sm">Never</Text>
        )}
      </Box>
    </>
  );
};

export default UpcomingPaymentsList;
