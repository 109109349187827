import { gql } from '@apollo/client';

export const GET_TRANSFERS = gql`
  query Transfer($input: TransferQueryOption) {
    transfers(input: $input) {
      data {
        amount
        createdAt
        bankPartner
        fromTransferAccount {
          accountName
          accountNumber
          amount
          id
          name
          bankAccountId
          userId
          institution {
            id
            name
            plaidInstitutionName
          }
          isExternal
        }
        fromTransferAccountId
        id
        externalId
        note
        status
        toTransferAccount {
          accountName
          accountNumber
          amount
          id
          name
          bankAccountId
          userId
          institution {
            id
            name
            plaidInstitutionName
          }
          isExternal
        }
        toTransferAccountId
        transferDate
        completedDate
        type
        typeName
        userId
        metadata {
          name
        }
      }
      total
    }
  }
`;

export const CREATE_TRANSFER = gql`
  mutation createTransfer($input: CreateTransferInput!) {
    createTransfer(input: $input) {
      amount
      createdAt
      fromTransferAccount {
        accountName
        accountNumber
        amount
        id
        institution {
          name
        }
        isExternal
        name
        bankAccountId
        userId
      }
      fromTransferAccountId
      id
      note
      status
      toTransferAccount {
        accountName
        accountNumber
        amount
        id
        institution {
          name
        }
        isExternal
        name
        bankAccountId
        userId
      }
      toTransferAccountId
      transferDate
      type
      typeName
      userId
    }
  }
`;

export const INITIATE_PAYEE_TRANSFER = gql`
  mutation initiatePayeeTransfer($input: PayeeTransferInput!) {
    initiatePayeeTransfer(input: $input) {
      id
      userId
      note
      fromTransferAccountId
      fromTransferAccount {
        accountName
        accountNumber
        amount
        id
        institution {
          name
        }
        isExternal
        name
        bankAccountId
        userId
      }
      toTransferAccountId
      toTransferAccount {
        accountName
        accountNumber
        amount
        id
        institution {
          name
        }
        isExternal
        name
        bankAccountId
        userId
      }
      transferDate
      completedDate
      createdAt
      amount
      externalId
      type
      typeName
      bankPartner
      status
      metadata {
        routingNumber
        accountNumber
        name
        address {
          street
          unit
          city
          state
          postalCode
          country
        }
        tagId
        unitId
        propertyId
        checkNumber
        email
      }
    }
  }
`;
