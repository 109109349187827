import isNil from '@core/utils/validators/isNil';
import isEmpty from '@core/utils/validators/isEmpty';

export const HEADER_ITEMS = [
  'Account',
  'Date',
  'Description',
  'Amount',
  'Category',
  'Property',
  'Notes',
];

export const FORM_CONFIGURATION = {
  account: {
    name: 'account',
    id: 'account',
    type: 'string',
    initialValue: {
      id: 'manual',
      bankName: 'Manually Added',
      account: 'Manually Added',
    },
  },
  date: {
    name: 'date',
    id: 'date',
    type: 'date',
    initialValue: null,
  },
  description: {
    name: 'description',
    id: 'description',
    type: 'string',
    initialValue: '',
  },
  amount: {
    name: 'amount',
    id: 'amount',
    type: 'number',
    initialValue: null,
  },
  amountSign: {
    name: 'amountSign',
    id: 'amountSign',
    type: 'string',
    initialValue: 'in',
  },
  category: {
    name: 'category',
    id: 'category',
    type: 'string',
    initialValue: '',
  },
  property: {
    name: 'property',
    id: 'property',
    type: 'object',
    initialValue: '',
  },
  notes: {
    name: 'notes',
    id: 'notes',
    type: 'string',
    initialValue: '',
  },
};

export function validateDateField(value) {
  // TODO: For now generic field validation, later specific for each field
  let error;

  if (isNil(value)) {
    error = 'Required';
  }

  return error;
}

export function validatePropertyField(values) {
  let error;

  if (isNil(values.propertyId) && isNil(values.unitId)) {
    error = 'Required';
  }

  return error;
}

export function validateGenericField(value) {
  let error;

  if (isNil(value)) {
    error = 'Invalid value!';
  }

  if (isEmpty(value)) {
    error = 'Required';
  }

  return error;
}

export function getInitialValues() {
  return Object.keys(FORM_CONFIGURATION).reduce((accumulator, currentValue) => {
    accumulator[currentValue] = FORM_CONFIGURATION[currentValue].initialValue;
    return accumulator;
  }, {});
}
