import { gql } from '@apollo/client';

export const DELETE_ALERT = gql`
  mutation DeleteAlert($id: ID) {
    deleteAlert(id: $id) {
      id
      title
      description
      createdAt
      deleted
    }
  }
`;
