import { stepIconContainerStyles } from './steps.styles';

export const stepTogglerContainerStyles = {
  p: '0 8px',
  m: '0 !important',
};

export const stepTogglerTextStyles = {
  fontSize: '3xs',
  lineHeight: '14px',
  color: 'brand.blue.700',
  fontWeight: 'normal',
  mr: '12px !important',
};

export { stepIconContainerStyles };
