// @flow
import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLazyQuery } from '@apollo/client';
import { Box, Stack, Text } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import { BaselaneCard, BaselaneErrorCard, BaselaneMessageCard } from '@shared/components';
import type { Sort } from '@shared/types/Sort';
import { GET_TRANSACTIONS } from '@core/apollo/queries';
import getBreakPoints from '@core/utils/getBreakPoints';
import TransactionContext from '@contexts/TransactionContext';
import useBulkActionsStore from '@store/Transactions/bulkActionsStore';
import { TRANSACTIONS } from '@routes';
import type { Transaction } from './types';
import CategorizedTrxsBanner from './components/CategorizedTrxsBanner';
import FilterSummary from './components/FilterSummary';
import TransactionsDummyLoader from './components/TransactionsDummyLoader';
import TransactionsFilters from './components/TransactionsFilters';
import TransactionList from './TransactionList';
import { transactionsStyles } from './styles/transactions.styles';

type TransactionsPageProps = {
  externalFilters?: Object,
  hideCategorizedTrxBanner?: boolean,
  hideFilters?: boolean,
  hideFilterSummary?: boolean,
  filterSummary?: Object,
  isExternalFilterLoading?: boolean,
  showDummyData?: boolean,
  styles?: Object,
  isEmbeddedInPage?: Boolean,
};

const TransactionsPage = ({
  externalFilters = {},
  hideCategorizedTrxBanner,
  hideFilters,
  hideFilterSummary,
  filterSummary,
  isExternalFilterLoading,
  showDummyData,
  styles,
  isEmbeddedInPage,
}: TransactionsPageProps): any => {
  const { isMin899 } = getBreakPoints();

  const {
    accountsLoading,
    accountsError,
    accountsData,
    refetchAccountsData,
    accountMap,
    connectedAccountsFilterOptions,
    filters,
    setFilters,
    DEFAULT_FILTERS,
    isTransactionSummaryOrAccountsLoading,
  } = useContext(TransactionContext);

  const currentUrl = useLocation();
  const { pathname } = currentUrl;

  const [transactions, setTransactions] = useState(([]: Array<Transaction>));

  const isOutsideTrxPage = Object.keys(externalFilters).length > 0 || pathname !== TRANSACTIONS;
  const { showBulkActions, setShowBulkActions } = useBulkActionsStore();
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [actionDeselectAll, setActionDeselectAll] = useState(false);
  const [showUncategorized, setShowUncategorized] = useState(false);
  const [hasManualTransactions, setHasManualTransactions] = useState(false);
  const [hasLoadedMore, setHasLoadedMore] = useState(false);
  const [currSort, setCurrSort] = useState({ direction: 'DESC', field: 'date' });
  const [hasDropdownClearedExternally, setHasDropdownClearedExternally] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    dates: { start: null, end: null },
    timePeriod: null,
    categories: null,
    appliedPreset: null,
    properties: [],
    accounts: [],
  });

  const [
    getTransactions,
    { loading: transactionsLoading, error, data, networkStatus, refetch },
  ] = useLazyQuery(GET_TRANSACTIONS, {
    variables: {
      input: {
        ...filters,
        filter: { ...externalFilters, ...filters.filter },
      },
    },
  });

  useEffect(() => {
    // default filters include external ones when coming
    const defaultFilters =
      externalFilters && Object.keys(externalFilters).length > 0
        ? {
            ...DEFAULT_FILTERS,
            filter: {
              ...DEFAULT_FILTERS.filter,
              ...externalFilters,
            },
          }
        : DEFAULT_FILTERS;

    setFilters({
      ...defaultFilters,
      page: 1,
    });

    // when the component unmounts, set the filters back to DEFAULT_FILTERS
    // this fixes filters not resetting when changing pages
    return () => {
      setFilters(DEFAULT_FILTERS);
    };
  }, []);

  const [showHiddenTransactions, setShowHiddenTransactions] = useState(false);
  const [showOnlyWithAttachments, setShowOnlyWithAttachments] = useState(false);

  const [showingUncategorizedTransactions, setShowingUncategorizedTransactions] = useState(
    showUncategorized
  );
  const [inputValue, setInputValue] = useState(filters.filter.search);

  const errorComponent = <BaselaneErrorCard />;
  if (accountsError) {
    return errorComponent;
  }

  // Go through the Landlord's banks to find whether there is a bank that is importing Tx.
  const isImporting =
    accountsData &&
    accountsData?.bank?.some((bank) =>
      bank?.bankAccounts?.some((account) => account.importTransaction.enabled === true)
    );

  const handleSelectedTransactions = (trxs) => {
    setSelectedTransactions(trxs);
    if (trxs?.length > 0) {
      setShowBulkActions(true);
    } else {
      setShowBulkActions(false);
    }
  };

  // clear button functionality
  const handleClear = () => {
    const newFilters =
      externalFilters && Object.keys(externalFilters).length > 0
        ? {
            ...DEFAULT_FILTERS,
            filter: {
              ...DEFAULT_FILTERS.filter,
              ...externalFilters,
            },
          }
        : DEFAULT_FILTERS;

    handleSelectedTransactions([]);
    setShowHiddenTransactions(false);
    setShowOnlyWithAttachments(false);
    setShowUncategorized(false);
    setFilters(newFilters);
    setInputValue('');
    setSelectedFilters({
      dates: { start: null, end: null },
      timePeriod: null,
      categories: null,
      appliedPreset: null,
      properties: [],
      accounts: [],
    });
    setShowingUncategorizedTransactions(false);
    setHasDropdownClearedExternally(true);
    setActionDeselectAll(true);
  };

  function handleSearch(
    searchTerm: string,
    searchId: Array,
    isCategorized: Boolean,
    currSorts: Object,
    accountFilter: Object,
    tagFilter: Object,
    propertyFilter: Object,
    toFilter: String,
    fromFilter: String
  ) {
    const filter = {
      ...filters.filter,
      search: searchTerm,
      searchId,
      isCategorized,
      bankAccountId: accountFilter,
      tagId: tagFilter,
      propertyId: propertyFilter,
      to: toFilter,
      from: fromFilter,
    };

    // Reset all selected trx and hide bulk actions - the search results may not include the selected trx.
    setActionDeselectAll(true);
    setSelectedTransactions([]);
    setShowBulkActions(false);

    const newFilters = {
      ...DEFAULT_FILTERS,
      filter,
      sort: currSorts,
    };
    handleSelectedTransactions([]);
    setFilters(newFilters);
  }

  const handleSort = (newSort: Sort) => {
    setCurrSort(newSort);

    const newFilters = {
      ...DEFAULT_FILTERS,
      filter: filters.filter,
      sort: newSort,
    };
    handleSelectedTransactions([]);
    setFilters(newFilters);
  };

  const handleShowUncategorizedTransactions = (showUncategorizedTransactions: boolean) => {
    let isCategorized;

    if (showUncategorizedTransactions) {
      isCategorized = null;
    } else {
      isCategorized = false;
    }

    // It's the property filter of "filters"
    const filter = { ...filters.filter, isCategorized };

    const newFilters = {
      ...DEFAULT_FILTERS,
      filter,
      sort: currSort,
    };

    setShowUncategorized(!showUncategorizedTransactions);
    handleSelectedTransactions([]);
    setFilters(newFilters);
  };

  const handleToggleHiddenTransactions = (showHidden: boolean) => {
    setShowHiddenTransactions(showHidden);
  };

  const handleToggleOnlyWithAttachments = (showOnlyTransactionsWithAttachments: boolean) => {
    const newFilters = {
      ...DEFAULT_FILTERS,
      filter: showOnlyTransactionsWithAttachments
        ? { ...filters.filter, isDocumentUploaded: true }
        : { ...filters.filter, isDocumentUploaded: null },
      sort: currSort,
    };

    handleSelectedTransactions([]);
    setFilters(newFilters);
    setShowOnlyWithAttachments(showOnlyTransactionsWithAttachments);
  };

  const handleLoadMore = () => {
    setHasLoadedMore(true);

    const newFilters = {
      ...filters,
      page: filters.page + 1,
    };

    setFilters(newFilters);
  };

  const handleDeselectTransactions = (allDeselected) => {
    if (allDeselected) {
      setShowBulkActions(false);
      setSelectedTransactions([]);
      setActionDeselectAll(true);
    }
  };

  const { container, list } =
    transactionsStyles(
      isMin899,
      isMobile,
      isEmbeddedInPage,
      isEmbeddedInPage ||
        ((transactionsLoading || isTransactionSummaryOrAccountsLoading) && filters.page === 1)
    ) ?? {};

  return (
    <Stack {...container}>
      {isMin899 && (
        <>
          <Outlet />
          {!isImporting && hasManualTransactions && !isOutsideTrxPage && !accountsLoading && (
            <BaselaneMessageCard
              iconName="info"
              iconColor="blue"
              borderColor="blue"
              title="Automatically Import Your Transactions"
              containerStyles={{
                mb: '16px  !important',
                mt: '0 !important',
                position: 'relative',
                top: '-8px',
              }}
              hasCloseButton
              onCloseButtonClick={() => setHasManualTransactions(false)}
            >
              <Text>
                To start automatically importing your transactions, add a bank or credit card
                account connection securely with Plaid™ and enable transaction importing.
              </Text>
            </BaselaneMessageCard>
          )}

          {!hideCategorizedTrxBanner && <CategorizedTrxsBanner {...{ isOutsideTrxPage }} />}

          {!hideFilterSummary && <FilterSummary {...{ transactions, filterSummary }} />}
        </>
      )}

      <BaselaneCard
        styles={{
          height: 'auto',
          boxShadow: (isOutsideTrxPage || isEmbeddedInPage) && 'none',
          borderRadius: !isMin899 || isEmbeddedInPage ? 'none' : '8px',
          background: !isMin899 ? 'brand.blue.50' : 'brand.neutral.white',
        }}
      >
        <TransactionsFilters
          onToggleHiddenTransactions={handleToggleHiddenTransactions}
          onToggleOnlyWithAttachments={handleToggleOnlyWithAttachments}
          onShowUncategorizedTransactions={handleShowUncategorizedTransactions}
          onSearch={handleSearch}
          inputValue={inputValue}
          setInputValue={setInputValue}
          showUncategorized={showUncategorized}
          isOutsideTrxPage={isOutsideTrxPage}
          defaultFilters={DEFAULT_FILTERS}
          showHiddenTransactions={showHiddenTransactions}
          showOnlyWithAttachments={showOnlyWithAttachments}
          showBulkActions={showBulkActions}
          handleDeselectTransactions={handleDeselectTransactions}
          handleSelectedTransactions={handleSelectedTransactions}
          selectedTransactions={selectedTransactions}
          setSelectedTransactions={setSelectedTransactions}
          handleClear={handleClear}
          showingUncategorizedTransactions={showingUncategorizedTransactions}
          setShowingUncategorizedTransactions={setShowingUncategorizedTransactions}
          currSort={currSort}
          hasDropdownClearedExternally={hasDropdownClearedExternally}
          setHasDropdownClearedExternally={setHasDropdownClearedExternally}
          hideFilters={hideFilters}
          styles={styles}
          accountMap={accountMap}
          connectedAccountsFilterOptions={connectedAccountsFilterOptions}
          refetch={refetch}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          defaultPresetName="Net Operating Cashflow"
          data={data}
          transactionsLoading={transactionsLoading}
        />

        <Box {...list.container()} {...styles?.transactionsListContainerStyles}>
          {isExternalFilterLoading ? (
            <TransactionsDummyLoader
              {...{
                filters,
                externalFilters,
                actionDeselectAll,
                setActionDeselectAll,
                selectedTransactions,
                onSort: handleSort,
              }}
            />
          ) : (
            <TransactionList
              onSort={handleSort}
              onLoadMore={handleLoadMore}
              {...{
                showHiddenTransactions,
                showOnlyWithAttachments,
                showUncategorized,
                setHasManualTransactions,
                setSelectedTransactions,
                setActionDeselectAll,
                actionDeselectAll,
                selectedTransactions,
                handleSelectedTransactions,
                isOutsideTrxPage,
                externalFilters,
                transactions,
                setTransactions,
                getTransactions,
                transactionsLoading,
                data,
                error,
                networkStatus,
                refetch,
                accountsLoading,
                accountMap,
                refetchAccountsData,
                setHasLoadedMore,
                hasLoadedMore,
                showDummyData,
              }}
            />
          )}
        </Box>
      </BaselaneCard>
    </Stack>
  );
};

TransactionsPage.defaultProps = {
  externalFilters: ({}: { ... }),
  hideCategorizedTrxBanner: false,
  hideFilters: false,
  styles: ({}: { ... }),
  hideFilterSummary: true,
  isExternalFilterLoading: false,
  showDummyData: false,
  filterSummary: ({}: { ... }),
  isEmbeddedInPage: false,
};

export default TransactionsPage;
