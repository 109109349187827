import { gql } from '@apollo/client';

export const GET_TASKS = gql`
  query Task {
    task {
      id
      title
      description
      createdAt
    }
  }
`;
