import React from 'react';
import { Stack, Tbody, Text } from '@chakra-ui/react';
import {
  BaselaneResponsiveTable,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellText,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableRow,
} from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';
import customTheme from '@core/theme';
import getBreakPoints from '@core/utils/getBreakPoints';
import { invoiceTableHeaderItems, invoiceTableConfig } from '../helpers/invoiceTable.helpers';
import { tableStyles } from '../styles/tables.styles';

type InvoiceTableProps = {
  payment: Object,
  propertiesData: Array<Object>,
};

function InvoiceTable({ payment, propertiesData }: InvoiceTableProps) {
  const { isMinXL } = getBreakPoints();

  const { cell, chevroncell, custominvoicecell } = tableStyles ?? {};

  return (
    <BaselaneResponsiveTable config={invoiceTableConfig}>
      <BaselaneResponsiveTableHeader
        items={invoiceTableHeaderItems}
        renderItem={(item, index) => (
          <BaselaneResponsiveTableHeading key={item.key} index={index} title={item.label} />
        )}
        chevronCellStyles={chevroncell}
      />

      <Tbody>
        <BaselaneResponsiveTableRows
          customRow
          items={payment?.invoices}
          renderItem={(invoiceData) => {
            const { id, propertyUnit, amount, dueDate, description, state } = invoiceData;
            const { propertyId, propertyName, unitName } = propertyUnit ?? {};
            const property = propertiesData?.find((p) => p?.id === propertyId);
            const isMultiUnit = property?.units?.length > 1;

            const propertyColumnText = propertyName;
            const propertyColumnSubtext = isMultiUnit ? unitName : null;
            const dueDateColumn = formatDate(dueDate);
            const amountColumn = formatCurrency(amount).inDollars;

            return (
              <BaselaneResponsiveTableRow
                id={id}
                indicatorColor={state === 'FAILED' && customTheme.colors.red['500A']}
                chevronCellStyles={chevroncell}
              >
                {/* Property  */}
                <BaselaneResponsiveCellText
                  text={propertyColumnText}
                  subtext={propertyColumnSubtext}
                  configIndex={0}
                  styles={cell.text}
                />
                {/* Invoice  */}
                <BaselaneResponsiveCell configIndex={1}>
                  <Stack {...cell.text.text}>
                    {isMinXL ? (
                      <>
                        <Text
                          {...{
                            ...custominvoicecell.text,
                            ...custominvoicecell.lighttext,
                          }}
                        >
                          {description}
                        </Text>
                        <Text {...custominvoicecell.text}>{propertyColumnText}</Text>
                        <Text {...custominvoicecell.lighttext}>{propertyColumnSubtext}</Text>
                      </>
                    ) : (
                      <>
                        <Text {...custominvoicecell.text}>{description}</Text>
                        <Text
                          {...{
                            ...custominvoicecell.text,
                            ...custominvoicecell.lighttext,
                          }}
                        >{`#${id}`}</Text>
                      </>
                    )}
                  </Stack>
                </BaselaneResponsiveCell>
                {/* DueDate  */}
                <BaselaneResponsiveCellText
                  text={dueDateColumn}
                  configIndex={2}
                  styles={cell.text}
                  {...cell.nopadding}
                />
                {/* Amount  */}
                <BaselaneResponsiveCellText
                  text={amountColumn}
                  configIndex={3}
                  styles={cell.text}
                />
              </BaselaneResponsiveTableRow>
            );
          }}
        />
      </Tbody>
    </BaselaneResponsiveTable>
  );
}

export default InvoiceTable;
