import { isMobile } from 'react-device-detect';
import { ONBOARDING_TRIAGE, ONBOARDING_PROPERTY_SURVEY, ONBOARDING_ADD_PROPERTY } from '@routes';
import customTheme from '@core/theme';

export const headerTextContainerStyles = {
  spacing: 0,
};

export const onboardingContainerWrapperStyles = (pathname) => {
  const mobileHeight = pathname !== ONBOARDING_TRIAGE ? 'auto' : '100%';
  const desktopHeight = pathname !== ONBOARDING_TRIAGE ? 'auto' : { xxs: '100%', xl: 'auto' };
  return {
    backgroundColor: 'brand.neutral.white',
    minHeight: '100%',
    height: isMobile ? mobileHeight : desktopHeight,
  };
};

export const onboardingHeaderStyles = () => ({
  textStyle: 'headline-xl',
  color: 'brand.neutral.700',
  maxWidth: '740px',
});

export const subTextStyles = (pathname, isBookkeeping, isMaxHeight720) => ({
  display: pathname === ONBOARDING_TRIAGE ? 'block' : 'none',
  fontSize: isBookkeeping ? '16px' : { xxs: 'xs', md: 'md', base: 'md' },
  lineHeight: '24px',
  marginTop: isBookkeeping ? '16px' : '0',
  px: '16px',
  color: customTheme.colors.brand.neutral['700'],
  pb: pathname === ONBOARDING_TRIAGE && isBookkeeping && !isMaxHeight720 ? '40px' : '0px',
});

export const onboardingCardWrapperStyles = () => {
  const baseStyles = {
    borderStyle: 'solid',
    borderWidth: '1pt',
    borderColor: customTheme.colors.brand.neutral['500'],
    borderRadius: '4px',
    bgColor: 'brand.neutral.white',
  };

  return {
    ...baseStyles,
    minWidth: '288px',
    maxWidth: '380px',
    minHeight: 'initial',
    height: isMobile ? '118px' : 'auto',
    p: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    spacing: 0,
  };
};

export const onboardingCardTitleStyles = {
  textStyle: { xxs: 'headline-sm', md: 'headline-md' },
  color: 'brand.neutral.700',
  py: '0',
};

export const onboardingCardTextStyles = {
  textStyle: 'xs',
  color: customTheme.colors.brand.neutral['600'],
};

export const cardContainerWrapperStyles = {
  position: 'relative',
  mt: { md: 0, base: 2 },
};

export const cardContainerStyles = (pathname) => ({
  direction: 'column',
  justifyContent: 'start',
  p: pathname !== ONBOARDING_TRIAGE ? '0 16px 16px 16px' : '0 0 16px',
  maxWidth: '1440px',
  margin: '0 auto',
  height: 'auto',
  minHeight: pathname !== ONBOARDING_TRIAGE ? 'unset' : '420px',
  spacing: { lg: '8px', xl: '16px', base: '16px' },
  gap: pathname !== ONBOARDING_TRIAGE ? '16px' : '8px',
});

export const onboardingContentContainerStyles = (pathname) => {
  const desktopMb = pathname !== ONBOARDING_TRIAGE ? '40px' : '0';
  const isPropertySurvey =
    pathname === ONBOARDING_PROPERTY_SURVEY || pathname === ONBOARDING_ADD_PROPERTY;

  return {
    alignItems: 'center',
    w: '100%',
    pt: { xxs: '27px', lg: '33px' },
    pb: '24px',
    position: 'relative',
    justifyContent: 'space-between',
    mb: isMobile || isPropertySurvey ? 0 : desktopMb,
  };
};

export const onboardingContainerStyles = {
  direction: 'column',
  height: '100vh',
};

export const mobileFooterStyles = {
  position: 'fixed',
  bottom: '0',
  left: '0',
  background: 'brand.neutral.white',
  w: '100%',
  p: '16px 24px',
  borderTop: '1px',
  borderColor: 'brand.darkBlue.200',
  boxShadow: '0 0 12px #ffffff',
};

export const infoCardStyles = (isCarousel) => ({
  height: isCarousel ? 'auto' : '100%',
  minWidth: '0',
  border: '1px solid',
  borderColor: 'brand.darkBlue.200',
  p: '20px 24px',
  fontSize: '14px',
  lineHeight: '20px',
  maxWidth: isCarousel ? 'calc(100% - 32px)' : '365px',
  flex: isCarousel ? '0 0 100%' : 'none',
  width: 'calc((100% / 3) - 10px)',
});

export const infoCardClaimerStyles = {
  textStyle: 'xs',
  color: 'green.800AA',
};
