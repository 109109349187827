// @flow
const SortFieldEnum = {
  Account: 'accountName',
  BankAccountName: 'bankAccountName',
  Date: 'date',
  Merchant: 'merchantName',
  Amount: 'amount',
  Category: 'category',
  Property: 'propertyName',
  Notes: 'note',
  Attachments: 'isDocumentUploaded',
  TransferDate: 'transferDate',
  From: 'fromTransferAccountId',
  To: 'toTransferAccountId',
  Type: 'type',
  Status: 'state',
  Period: 'period',
};

export default SortFieldEnum;
