import { gql } from '@apollo/client';
import { FRAGMENT_CARD_DATA_IN_ACCOUNT, FRAGMENT_CARD_STATUS } from './fragments';

export const GET_CARD_SUMMARY = gql`
  query CardSummary($page: Float, $pageSize: Float, $filter: CardFilter) {
    cardSummary(input: { page: $page, pageSize: $pageSize, filter: $filter }) {
      cardFinancials {
        dailySpent
        mtdSpent
        ytdCashBack
        dailySpent
      }
      data {
        externalId
        accountName
        limit {
          amount
          frequency
        }
        billingAddress
        cardFinancials {
          dailySpent
          mtdSpent
          ytdCashBack
        }
        userId
        nameOnCard
        nickname
        expirationDate
        limit {
          amount
          frequency
        }
        propertyId
        tagId
        unitId
        ...CardStatus
        ...CardDataInAccount
      }
      page
      pageSize
      total
    }
  }
  ${FRAGMENT_CARD_STATUS}
  ${FRAGMENT_CARD_DATA_IN_ACCOUNT}
`;

export const REDEEM_CASHBACK = gql`
  mutation RedeemCashBack($accountId: Float!, $amount: Float!) {
    cashbackRedemption(accountId: $accountId, amount: $amount) {
      accountId
      amount
    }
  }
`;

export const TERMINATE_CARD = gql`
  mutation TerminateCard($cardId: ID!) {
    terminateCard(cardId: $cardId) {
      id
    }
  }
`;

export const UPDATE_CARD = gql`
  mutation UpdateCard($id: ID!, $limits: CardLimitInput, $nickname: String, $tags: CardTagsInput) {
    updateCard(input: { id: $id, limits: $limits, nickname: $nickname, tags: $tags }) {
      externalId
      accountName
      billingAddress
      cardFinancials {
        mtdSpent
        ytdCashBack
      }
      userId
      nameOnCard
      nickname
      expirationDate
      limit {
        amount
        frequency
      }
      propertyId
      tagId
      unitId
      ...CardStatus
      ...CardDataInAccount
    }
  }
  ${FRAGMENT_CARD_STATUS}
  ${FRAGMENT_CARD_DATA_IN_ACCOUNT}
`;

export const CREATE_VIRTUAL_CARD = gql`
  mutation CreateVirtualCard(
    $limits: CardLimitInput!
    $nickname: String
    $tags: CardTagsInput!
    $bankAccountId: ID!
  ) {
    createVirtualCard(
      input: { limits: $limits, nickname: $nickname, tags: $tags, bankAccountId: $bankAccountId }
    ) {
      accountName
      bankAccountId
      billingAddress
      cardFinancials {
        dailySpent
        mtdSpent
        ytdCashBack
      }
      ...CardStatus
      expirationDate
      externalId
      id
      isPhysical
      last4Digits
      limit {
        amount
        frequency
      }
      nameOnCard
      nickname
      propertyId
      tagId
      unitId
      userId
    }
  }
  ${FRAGMENT_CARD_STATUS}
`;
