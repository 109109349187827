export const successMessageContainerStyles = {
  align: 'center',
  spacing: '8px',
};

export const iconBackgroundStyles = {
  color: 'green.800AA',
  h: '32px',
  w: '32px',
  bgColor: 'green.100',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
};

export const successMessageTextStyles = {
  textStyle: 'headline-md',
};

export const nextStepsTextStyles = {
  fontSize: '2xl',
  fontWeight: 'medium',
  lineHeight: '32px',
  mt: '48px',
  mb: '32px',
};

export const buttonCardContentContainerStyles = {
  width: '100%',
  align: 'center',
  spacing: '24px',
};

export const buttonCardContentTextContainerStyles = {
  textAlign: 'left',
  flex: '1 1 auto',
};

export const buttonCardContentTextHeaderStyles = {
  textStyle: 'headline-sm',
  textTransform: 'uppercase',
  color: 'brand.neutral.700',
  mb: '8px',
};

export const buttonCardContentTextBodyStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
};

export const spinnerStyles = {
  color: 'brand.darkBlue.150',
  position: 'absolute',
  top: '50%',
  left: '50%',
  w: '56px',
  h: '56px',
};

export const badgeBackgroundStyles = { bg: 'transparent', flex: '1 1 auto', ml: '0' };

export const badgeFontStyles = { color: 'brand.neutral.700' };
