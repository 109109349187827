import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { BaselaneAlert, BaselaneMessageCard } from '@shared/components';
import { noPhoneNumberPopupStyles } from '../styles/noPhoneNumberPopup.styles';

type NoPhoneNumberPopupProps = {
  isOpen: boolean,
  onClose: Function,
};

const NoPhoneNumberPopup = ({ isOpen, onClose }: NoPhoneNumberPopupProps) => {
  const { title, messagecard, description, alert } = noPhoneNumberPopupStyles ?? {};

  const messageCardText = (
    <Text>
      To send you a code to verify your login, Baselane needs your mobile phone number. Please email{' '}
      <Text as="span" {...messagecard?.email}>
        support@baselane.com
      </Text>{' '}
      to add your phone number.
    </Text>
  );

  const popupContent = (
    <Stack spacing={0.5}>
      <Text {...title}>2-step verification</Text>
      <BaselaneMessageCard
        iconName=""
        title="Account has no phone number"
        text={messageCardText}
        borderColor="red"
        iconColor="red"
        containerStyles={messagecard?.container}
        textStyles={messagecard?.text}
        styles={messagecard?.styles}
        isVertical
      />
      <Text {...description}>
        Alternatively, you can sign in with Google to avoid the 2-step verification. Please note
        that it should be the same email you use on Baselane.
      </Text>
    </Stack>
  );

  return (
    <BaselaneAlert
      showCloseButton
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      body={popupContent}
      size="xl"
      containerStyles={alert?.container}
      footerStyles={alert?.footer}
    />
  );
};

export default NoPhoneNumberPopup;
