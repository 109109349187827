export const unitSpecificSettingsTableStyles = {
  card: { p: 0, overflow: 'hidden' },
  nochevron: { display: 'none' },
  header: { contentContainerStyles: { borderColor: 'brand.darkBlue.200' } },
  row: { contentContainerStyles: { h: '72px', _last: { border: 'none' } } },
  cell0: {
    titleTextStyle: { whiteSpace: 'normal' },
    textContainer: { gap: 0 },
  },
  cell3: {
    contentContainerStyles: { justifyContent: 'flex-end', gap: 0 },
  },
};
