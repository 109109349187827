import React from 'react';
import { Flex, Stack, Heading } from '@chakra-ui/react';
import FormWrapper from '../Form/FormWrapper';
import ReferralBox from '../Referral/ReferralBox';
import { signupColumnContainerStyles } from '../../styles/signup.styles';
import {
  signupTestsHeadingStyles,
  signupTestsPrimaryColumnStyles,
} from '../../styles/signupTests.styles';

type SignUpCProps = {
  referrer: String,
  isReferral: Boolean,
  signUpVariant: String,
};

const SignUpC = ({ referrer, isReferral, signUpVariant }: SignUpCProps) => {
  return (
    <Stack
      {...signupColumnContainerStyles}
      pt={{ base: 0.5, lg: 2, xl: '48px' }}
      pb={{ base: 3, lg: 4, xl: 8 }}
      justifyContent="center"
    >
      <Flex direction="column" {...signupTestsPrimaryColumnStyles(true)} justifyContent="center">
        {isReferral && <ReferralBox referrer={referrer} signUpVariant={signUpVariant} />}
        <Heading {...signupTestsHeadingStyles} mt={0} mb={3}>
          Get started with Baselane
        </Heading>
        <FormWrapper isReferral={isReferral} signUpVariant={signUpVariant} />
      </Flex>
    </Stack>
  );
};

export default SignUpC;
