import React from 'react';

function IconDocumentCheckOutline() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 12.5C13.5147 12.5 11.5 14.5147 11.5 17C11.5 19.4853 13.5147 21.5 16 21.5C18.4853 21.5 20.5 19.4853 20.5 17C20.5 14.5147 18.4853 12.5 16 12.5ZM10.5 17C10.5 13.9624 12.9624 11.5 16 11.5C19.0376 11.5 21.5 13.9624 21.5 17C21.5 20.0376 19.0376 22.5 16 22.5C12.9624 22.5 10.5 20.0376 10.5 17Z"
        fill="#3A4B5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3322 15.1263C18.5386 15.3098 18.5572 15.6258 18.3737 15.8322L15.707 18.8322C15.6122 18.9389 15.4762 19 15.3333 19C15.1905 19 15.0545 18.9389 14.9596 18.8322L13.6263 17.3322C13.4428 17.1258 13.4614 16.8098 13.6678 16.6263C13.8742 16.4428 14.1903 16.4614 14.3737 16.6678L15.3333 17.7474L17.6263 15.1678C17.8098 14.9614 18.1258 14.9428 18.3322 15.1263Z"
        fill="#3A4B5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2457 2.51713C12.1912 2.50349 12.1269 2.49999 11.828 2.49999H4.71429C4.40601 2.49999 4.20487 2.50038 4.05129 2.51293C3.90355 2.525 3.84437 2.54578 3.81201 2.56227C3.70448 2.61706 3.61707 2.70447 3.56228 2.812C3.54579 2.84436 3.52501 2.90354 3.51294 3.05128C3.50039 3.20486 3.5 3.406 3.5 3.71428V19.2857C3.5 19.594 3.50039 19.7951 3.51294 19.9487C3.52501 20.0964 3.54579 20.1556 3.56228 20.188C3.61707 20.2955 3.70448 20.3829 3.81201 20.4377C3.84437 20.4542 3.90355 20.475 4.05129 20.4871C4.20487 20.4996 4.40601 20.5 4.71429 20.5H10.5C10.7761 20.5 11 20.7238 11 21C11 21.2761 10.7761 21.5 10.5 21.5H4.69474C4.41138 21.5 4.16904 21.5 3.96986 21.4837C3.76027 21.4666 3.55485 21.429 3.35802 21.3287C3.06233 21.1781 2.82193 20.9377 2.67128 20.642C2.57099 20.4451 2.53338 20.2397 2.51626 20.0301C2.49998 19.8309 2.49999 19.5886 2.5 19.3052V3.69474C2.49999 3.41137 2.49998 3.16903 2.51626 2.96985C2.53338 2.76026 2.57099 2.55483 2.67128 2.35801C2.82193 2.06232 3.06233 1.82192 3.35802 1.67127C3.55485 1.57098 3.76027 1.53337 3.96986 1.51625C4.16904 1.49997 4.41139 1.49998 4.69475 1.49999L11.828 1.49999C11.8432 1.49999 11.8583 1.49998 11.8733 1.49996C12.1027 1.49977 12.2991 1.49959 12.4887 1.54712C12.6554 1.58889 12.8142 1.65772 12.9586 1.75088C13.1229 1.85685 13.257 2.00036 13.4136 2.16795C13.4238 2.17888 13.4341 2.18991 13.4445 2.20105L18.4023 7.49726C18.4117 7.5074 18.4212 7.51743 18.4305 7.52737C18.5739 7.68035 18.6968 7.81139 18.787 7.96665C18.8664 8.10319 18.9248 8.25092 18.9601 8.40485C19.0003 8.57989 19.0002 8.75953 19 8.96923C19 8.98286 19 8.99661 19 9.01049V10.4444C19 10.7206 18.7761 10.9444 18.5 10.9444C18.2239 10.9444 18 10.7206 18 10.4444V9.01049C18 8.73759 17.9971 8.67897 17.9855 8.62866C17.9727 8.57268 17.9514 8.51897 17.9226 8.46931C17.8966 8.4247 17.8587 8.37989 17.6722 8.18066L12.7145 2.88444C12.5102 2.66619 12.4637 2.62164 12.4165 2.59122C12.364 2.55735 12.3063 2.53232 12.2457 2.51713Z"
        fill="#3A4B5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 7C4.5 6.72386 4.72386 6.5 5 6.5H13.5C13.7761 6.5 14 6.72386 14 7C14 7.27614 13.7761 7.5 13.5 7.5H5C4.72386 7.5 4.5 7.27614 4.5 7Z"
        fill="#3A4B5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 10C4.5 9.72386 4.72386 9.5 5 9.5H15.5C15.7761 9.5 16 9.72386 16 10C16 10.2761 15.7761 10.5 15.5 10.5H5C4.72386 10.5 4.5 10.2761 4.5 10Z"
        fill="#3A4B5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 13C4.5 12.7239 4.72386 12.5 5 12.5H11C11.2761 12.5 11.5 12.7239 11.5 13C11.5 13.2761 11.2761 13.5 11 13.5H5C4.72386 13.5 4.5 13.2761 4.5 13Z"
        fill="#3A4B5B"
      />
    </svg>
  );
}

export default IconDocumentCheckOutline;
