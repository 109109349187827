// @flow
import React, { useRef } from 'react';
import { useMutation } from '@apollo/client';
import { Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { BaselaneDivider } from '@shared/components';
import { UPDATE_ACCOUNT_WIDE_PROCESSING_FEE, GET_LEASE_ACH_FEE } from '@pages/LeasesPage/queries';
import UnitSpecificSettingsTable from './UnitSpecificSettingsTable';
import UnitSpecificSettingsPopup from './UnitSpecificSettingsPopup';
import { successToastConfig, errorToastConfig } from '../../helpers/unitSpecificSettings.helpers';
import { unitSpecificSettingsStyles } from '../../styles/unitSpecificSettings.styles';

type UnitSpecificSettingsProps = {
  sortedLeases: Array<Object>,
};
const { container, textcontainer, title, description, divider } = unitSpecificSettingsStyles;

function UnitSpecificSettings({ sortedLeases }: UnitSpecificSettingsProps): any {
  const leaseIdObj = useRef({ leaseId: null });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [
    resetFeesPaidByForLeaseLevel,
    { loading: isResetFeePaidByForLeaseLevelLoading },
  ] = useMutation(UPDATE_ACCOUNT_WIDE_PROCESSING_FEE);

  const toast = useToast();
  const showSuccessToast = () => toast(successToastConfig);
  const showErrorToast = () => toast(errorToastConfig);

  const handleResetClick = ({ leaseId }) => {
    leaseIdObj.current.leaseId = leaseId;
    onOpen();
  };

  const handleConfirmSaveClick = () => {
    resetFeesPaidByForLeaseLevel({
      variables: {
        id: leaseIdObj.current.leaseId,
      },
      update: (cache, { data: { resetProcessingFeesPaidByForLease } }) => {
        onClose();
        cache.updateQuery(
          {
            query: GET_LEASE_ACH_FEE,
            variables: {
              input: {
                invoiceProcessingFeePaidByOverridden: true,
                state: ['SCHEDULED', 'EXPIRED'],
              },
            },
          },
          ({ leases: { leases } }) => {
            const updatedLeases = leases?.filter(
              (lease) => lease.id !== resetProcessingFeesPaidByForLease?.id
            );
            return {
              leases: {
                leases: updatedLeases,
              },
            };
          }
        );
      },
    })
      .then(() => {
        showSuccessToast();
      })
      .catch((err) => {
        onClose();
        showErrorToast();
        console.debug(err);
      });
  };

  return (
    <>
      <BaselaneDivider {...{ styles: divider }} />
      <Stack {...container}>
        <Stack {...textcontainer}>
          <Text {...title}>Unit-specific settings</Text>
          <Text {...description}>
            Settings for below units take precedence over the account-wide setting above.
          </Text>
        </Stack>

        <UnitSpecificSettingsTable {...{ sortedLeases, handleResetClick }} />
      </Stack>

      <UnitSpecificSettingsPopup
        {...{
          isOpen,
          onClose,
          handleConfirmSaveClick,
          isLoading: isResetFeePaidByForLeaseLevelLoading,
        }}
      />
    </>
  );
}

export default UnitSpecificSettings;
