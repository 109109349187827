// @flow
import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { drawerStyles } from '../../../styles/invoice.styles';

function ListEmptyState(): any {
  const { isMinXL } = getBreakPoints();
  const {
    body: {
      emptybody: { container, title, description },
    },
  } = drawerStyles(false, isMinXL);

  return (
    <Stack {...container}>
      <Text {...title}>No Invoices Found</Text>
      <Text {...description}>Try changing or clearing your filters to view invoices</Text>
    </Stack>
  );
}

export default ListEmptyState;
