import { isMobile } from 'react-device-detect';

export const buttonCardStyles = (isBannerClickable) => {
  const defaultStyles = {
    borderRadius: isMobile ? '0' : 'md',
    p: '18px 24px',
    minH: 'auto',
    h: 'auto',
    m: '24px 0',
    bg: 'brand.neutral.white',
    border: '1px solid #DADFE6',
    boxShadow: 'none',
  };

  return isBannerClickable
    ? {
        ...defaultStyles,
        cursor: 'pointer',
        _hover: {
          borderColor: 'brand.blue.800A',
          bg: 'brand.blue.50',
        },
        _active: {
          bg: 'brand.blue.100',
        },
      }
    : {
        ...defaultStyles,
        cursor: 'default',
        _hover: {
          background: 'brand.neutral.white',
        },
        _active: {
          background: 'brand.neutral.white',
        },
      };
};

export const containerStyles = {
  w: '100%',
  h: '100%',
  alignItems: 'flex-start',
};

export const headerRowStyles = {
  w: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const contentRowStyles = {
  w: '100%',
  h: '100%',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 0,
};

export const buttonAndProviderRowStyles = (isNarrow = false) => {
  return {
    m: '0 !important',
    boxSizing: 'border-box',
    w: isNarrow ? '100%' : { base: '100%', md: '50%' },
    justifyContent: isNarrow ? 'space-between' : { base: 'space-between', md: 'flex-end' },
    alignItems: 'center',
  };
};

export const primaryContentHeadingStyles = (isNarrow = false) => {
  return {
    fontSize: '2xl',
    fontWeight: 'semibold',
    color: 'brand.neutral.700',
    lineHeight: '32px',
    mb: isNarrow ? '8px !important' : { base: '8px !important', md: '0px !important' },
  };
};

export const supportingContentTextStyles = (isNarrow = false) => {
  return {
    textStyle: 'sm',
    color: 'brand.neutral.700',
    mb: isNarrow ? '16px !important' : { base: '16px !important', md: '0 !important' },
    w: isNarrow ? '100%' : { base: '100%', md: '50%' },
    boxSizing: 'border-box',
  };
};
