import React from 'react';
import { Box, FormLabel, Stack, Switch } from '@chakra-ui/react';
import { BaselaneButton, BaselaneTooltip } from '@shared/components';
import { IconInfo } from '@icons';
import { Icon16PlusCircle } from '@icons/16px';
import {
  switchContainerStyles,
  textLabelStyles,
  toggleContentContainerStyles,
  toggleWrapperStyles,
} from './styles/toggleWrapper.styles';

type ToggleWrapperProps = {
  children: any,
  showTopBorder?: boolean,
  showBottomBorder?: boolean,
  isNested?: boolean,
};

type ToggleProps = {
  title: any,
  htmlFor: string,
  showTopBorder?: Boolean,
  showBottomBorder?: Boolean,
  showTooltip?: Boolean,
  tooltipLabel?: string,
  switchProps: Object,
  labelStyles?: Object,
  hideToggle?: Boolean,
};

type ToggleContentProps = { children: any, isInline?: boolean, styles?: Object };

const ToggleWrapper = ({
  children,
  showTopBorder,
  showBottomBorder,
  isNested,
}: ToggleWrapperProps) => (
  <Box {...toggleWrapperStyles({ showTopBorder, showBottomBorder, isNested })}>{children}</Box>
);

ToggleWrapper.Toggle = ({
  title,
  htmlFor,
  showTooltip,
  tooltipLabel,
  switchProps,
  labelStyles,
  hideToggle,
}: ToggleProps) => {
  return (
    <Stack direction="row" {...switchContainerStyles} pr="3px">
      <Stack direction="row" alignItems="center" spacing={2}>
        {hideToggle ? (
          <BaselaneButton
            variant="transparent"
            palette="primary"
            pullLeft
            size="sm"
            leftIcon={<Icon16PlusCircle />}
            {...switchProps}
            id="add_a_fee_button"
          >
            {title}
          </BaselaneButton>
        ) : (
          <FormLabel {...textLabelStyles} {...labelStyles} htmlFor={htmlFor}>
            {title}
          </FormLabel>
        )}
        {showTooltip && (
          <BaselaneTooltip label={tooltipLabel} styles={{ w: '250px' }}>
            <IconInfo width="16" height="16" color="#3A4B5B" />
          </BaselaneTooltip>
        )}
      </Stack>
      {hideToggle ? (
        <Box position="fixed" top="-1000px" right="-1000px">
          <Switch
            as="span"
            className="primary-500"
            isFocusable
            alignSelf="center"
            {...switchProps}
          />
        </Box>
      ) : (
        <Switch as="span" className="primary-500" isFocusable alignSelf="center" {...switchProps} />
      )}
    </Stack>
  );
};

ToggleWrapper.Content = ({ children, isInline, styles }: ToggleContentProps) => (
  <Stack direction={isInline ? 'row' : 'column'} {...toggleContentContainerStyles} {...styles}>
    {children}
  </Stack>
);

ToggleWrapper.defaultProps = {
  showTopBorder: false,
  showBottomBorder: true,
  isNested: false,
};

ToggleWrapper.Toggle.defaultProps = {
  showTopBorder: false,
  showBottomBorder: true,
  showTooltip: false,
  tooltipLabel: '',
  labelStyles: {},
  hideToggle: false,
};

ToggleWrapper.Content.defaultProps = {
  styles: {},
  isInline: false,
};

export default ToggleWrapper;
