import React from 'react';
import { Box, Divider, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { BaselaneCardNew } from '@shared/components';
import { infoCardStyles, infoCardClaimerStyles } from '../styles/onboardingTriage.styles';

type InfoCardProps = {
  slide: Object,
  isCarousel: boolean,
};

const InfoCard = ({ slide, isCarousel = true }: InfoCardProps) => {
  return (
    <BaselaneCardNew id={slide.id} key={slide.id} {...infoCardStyles(isCarousel)}>
      <Flex direction="column" justifyContent="space-between" height="100%">
        <VStack spacing={0} alignItems="left">
          <Heading size="headline-sm" mb="24px">
            {slide.title}
          </Heading>
          <Box>
            <HStack alignItems="start">
              <Box>{slide.iconOne}</Box>
              <Text>{slide.infoLabelOne}</Text>
            </HStack>
            <HStack alignItems="start" mt="16px">
              <Box>{slide.iconTwo}</Box>
              <Text>{slide.infoLabelTwo}</Text>
            </HStack>
          </Box>
        </VStack>
        <VStack spacing={0} alignItems="left">
          <Divider my="20px" />
          <Text {...infoCardClaimerStyles}>{slide.claimer}</Text>
        </VStack>
      </Flex>
    </BaselaneCardNew>
  );
};

export default InfoCard;
