import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import BaselaneButton from '../../BaselaneButton';
import { DatePickerModalProps } from '../types/datePickerModal.types';
import { datePickerModalStyles } from '../styles/datePickerModal.styles';

const DatePickerModal = ({
  isOpen,
  isDisabled,
  modalTitle,
  handleClose,
  handleApplyAndClose,
  children,
}: DatePickerModalProps) => {
  const { content, body, footer, btn } = datePickerModalStyles ?? {};

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent {...content}>
        <ModalHeader py={1.5} px={2}>
          {modalTitle}
        </ModalHeader>
        <ModalCloseButton mt={0.5} onTouchEnd={handleClose} onClick={handleClose} />
        <ModalBody className="range-picker-modal" {...body} p={0} pb={2}>
          {children}
        </ModalBody>
        <ModalFooter {...footer} p={2} letterSpacing={1.5}>
          <BaselaneButton
            {...{
              variant: 'transparent',
              palette: 'neutral',
              size: 'md',
              onClick: handleClose,
              onTouchEnd: handleClose,
              styles: btn,
              mr: 1.5,
            }}
          >
            Cancel
          </BaselaneButton>
          <BaselaneButton
            {...{
              variant: 'tonal',
              palette: 'primary',
              size: 'md',
              isDisabled,
              onClick: handleApplyAndClose,
              onTouchEnd: handleApplyAndClose,
              styles: btn,
            }}
          >
            Apply
          </BaselaneButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

DatePickerModal.defaultProps = {
  isDisabled: false,
  modalTitle: 'Select a date',
};

export default DatePickerModal;
