import { useMutation } from '@apollo/client';
import { INITIATE_CHECK_DEPOSIT } from '@core/components/NativeBankingPage/queries';
import { getTokenData } from '@shared/helpers/otp.helpers';
import useUploadCheckImage from './useCheckImageUpload';
import useTransfersAccountList from '../../../../../../../hooks/useTransfersAccountList';

const useFormSubmission = (xIdempotencyKey) => {
  const { transferAccountsByBankAccountId } = useTransfersAccountList();
  const { uploadCheckImage, isLoading: imageUploadInProgress } = useUploadCheckImage();

  const [initiateCheckDeposit, { loading: checkDepositInProgress }] = useMutation(
    INITIATE_CHECK_DEPOSIT
  );

  /**
   * @typedef CheckDepositFormVlaues
   * @type {Object}
   * @property {boolean} writeOnBackOfCheck - User compliance (irrelevant for form submission).
   * @property {boolean} signTheCheck - User compliance (irrelevant for form submission).
   * @property {string} frontOfCheck - The base64 encoded image data for the front of the check.
   * @property {string} backOfCheck - The base64 encoded image data for the back of the check.
   * @property {string} propertyId - The property id.
   * @property {string} categoryId - The category id.
   * @property {string} accountId - The account id.
   * @property {number} amount - The amount of the check.
   * @property {string} notes - The notes for the check deposit.
   */

  /**
   * Submits the form, initiates the check deposit, and uploads the check images.
   * @param {CheckDepositFormVlaues} values - The form values as described by {@link CheckDepositFormVlaues}.
   * @returns {boolean} Returns `true` if the submission is successful, `false` otherwise.
   */
  const submitForm = async (values) => {
    const [propertyId, unitId] = values.propertyId.split('-');
    const categoryId =
      values.categoryId.split('-').length > 1 ? values.categoryId.split('-')[1] : values.categoryId;

    const { data, errors } = await initiateCheckDeposit({
      context: {
        headers: {
          'x-idempotency-key': xIdempotencyKey,
        },
      },
      variables: {
        amount: values.amount,
        note: values.notes ?? 'Check Deposit',
        propertyId,
        tagId: categoryId,
        toTransferAccountId: transferAccountsByBankAccountId[values.accountId].accountId,
        unitId,
      },
    });

    if (errors) {
      throw new Error('Errors initiating check deposit', errors);
    }

    // Start uploading the front and back of the check in parallel.
    const checkId = data?.initiateCheckDeposit?.externalId;
    const bankId = transferAccountsByBankAccountId[values.accountId]?.group?.id;
    // Getting a token from local storage (?).
    const {
      sensitive: { token },
    } = getTokenData(bankId);

    try {
      await Promise.all([
        uploadCheckImage({ imageData: values.frontOfCheck, checkId, token, checkSide: 'front' }),
        uploadCheckImage({ imageData: values.backOfCheck, checkId, token, checkSide: 'back' }),
      ]);
    } catch (error) {
      throw new Error('Error uploading check images', error);
    }

    return true;
  };

  return {
    submitForm,
    loading: checkDepositInProgress || imageUploadInProgress,
  };
};

export default useFormSubmission;
