import { isEmpty } from 'lodash';

/**
 * @param {Object} transaction - A transaction
 * @param propertyIdsMap {Object} - An id -> property name map
 * @returns {Object} - The updated transaction with the propertyName field
 */
export const addPropertyNameToTransaction = (transaction, propertyIdsMap = {}) => {
  if (isEmpty(propertyIdsMap)) {
    return transaction;
  }

  let propertyName = null;

  if (transaction.propertyId) {
    const propertyId = `p-${transaction.propertyId}`;

    if (!(propertyId in propertyIdsMap)) {
      throw new Error('Missing property name!');
    }

    propertyName = propertyIdsMap[propertyId].name;
  }

  if (transaction.unitId) {
    const unitId = `u-${transaction.unitId}`;

    if (!(unitId in propertyIdsMap)) {
      throw new Error('Missing property name!');
    }

    const dash = propertyName ? ' - ' : '';

    propertyName += `${dash}${propertyIdsMap[unitId].name}`;
  }

  return {
    ...transaction,
    propertyName,
  };
};

/**
 * @param {Array} transactions - An array of transactions
 * @param propertyIdsMap {Object} - An id -> property name map
 * @returns {Array} The updated transactions with propertyName field
 */
export const addPropertyNamesToTransactions = (transactions = [], propertyIdsMap = {}) => {
  if (isEmpty(propertyIdsMap)) {
    return transactions;
  }

  return transactions.map((transaction) =>
    addPropertyNameToTransaction(transaction, propertyIdsMap)
  );
};
