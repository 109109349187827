// @flow
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Box, Stack } from '@chakra-ui/react';
import AddBankAccount from '@core/components/AddBankAccount';
import { BaselaneButton } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { IconArrowLeft, IconArrowRight, IconCheck } from '@icons';
import userContext from '@contexts/UserContext';
import { UNIFIED_BOOKKEEPING_ANALYTICS, UNIFIED_BA_ACCOUNTS, UNIFIED_BA_SUCCESS } from '@routes';
import { bookkeepingSegmentEventNames } from './helpers/bookkeepingAnalytics.helpers';
import { MenuState } from './initialMenuState';
import { footerWrapperStyles } from './styles/footerWrapper.styles';

type FooterWrapperProps = {
  bookkeepingAnalyticsMenuOptions: Array<MenuState>,
  tabIndex: number,
  addingPropertyPanel: boolean,
  setAddingPropertyPanel: Function,
  hasAccounts: boolean,
  handleLinkAccountsSuccess: Function,
  isLoading: boolean,
  setIsLoading: Function,
};

function FooterWrapper({
  bookkeepingAnalyticsMenuOptions,
  tabIndex,
  addingPropertyPanel,
  setAddingPropertyPanel,
  hasAccounts,
  handleLinkAccountsSuccess,
  isLoading,
  setIsLoading,
}: FooterWrapperProps): any {
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const segmentEvents = bookkeepingSegmentEventNames(user?.onboardingCompleted);

  const handleFooterState = (prevState, isPreviousOnClick) => {
    if (!isPreviousOnClick && segmentEvents[prevState]) {
      sendSegmentEvent(segmentEvents[prevState]);
    }
    if (prevState === 3) return isPreviousOnClick ? prevState - 1 : prevState;

    return isPreviousOnClick ? prevState - 1 : prevState + 1;
  };

  const handleFooterNavigation = (isPreviousOnClick) => {
    if (!isPreviousOnClick && tabIndex === 0) {
      if (!addingPropertyPanel) {
        // on property panel, go to next tab
        handleFooterState(0, false);
        navigate(UNIFIED_BA_ACCOUNTS);
      }
      if (addingPropertyPanel) {
        // selected a property, now go back to property panel
        setAddingPropertyPanel(false);
      }
    } else if (isPreviousOnClick && tabIndex === 0) {
      setAddingPropertyPanel(false);
    } else if (isPreviousOnClick && tabIndex === 1) {
      setAddingPropertyPanel(false);
      navigate(UNIFIED_BOOKKEEPING_ANALYTICS);
    } else if (tabIndex === 1) {
      handleFooterState(1, false);
      navigate(UNIFIED_BA_SUCCESS);
    }
  };

  return (
    <Stack direction="row" {...footerWrapperStyles}>
      {tabIndex !== 0 && (
        <BaselaneButton
          id="previous-button"
          variant="outline"
          palette="neutral"
          size="md"
          leftIcon={<IconArrowLeft />}
          onClick={() => handleFooterNavigation(true)}
          isDisabled={tabIndex === null}
        >
          Previous
        </BaselaneButton>
      )}

      {tabIndex === (bookkeepingAnalyticsMenuOptions?.length || 0) - 1 ? (
        <>
          {!hasAccounts && (
            <Box w="100%">
              <AddBankAccount
                hasIconLock
                size="md"
                titleText="Link Accounts"
                handleSuccessFn={(res) => {
                  sendSegmentEvent('onboarding_bookeeping_click_link_accounts');
                  handleLinkAccountsSuccess(res);
                }}
                isDisabled={isLoading}
                onPlaidConfirmationClose={() => {
                  setIsLoading(true);
                }}
              />
            </Box>
          )}
          {hasAccounts && (
            <BaselaneButton
              id="done-button"
              variant="filled"
              palette="primary"
              size="md"
              onClick={() => handleFooterNavigation()}
              leftIcon={<IconCheck w="16" h="16" />}
              isDisabled={isLoading}
            >
              Done
            </BaselaneButton>
          )}
        </>
      ) : (
        <BaselaneButton
          id="continue-button"
          variant="filled"
          palette="primary"
          size="md"
          onClick={() => handleFooterNavigation()}
          styles={{ w: isMobile ? '100%' : 'auto' }}
          rightIcon={!isMobile && <IconArrowRight />}
        >
          Continue
        </BaselaneButton>
      )}
    </Stack>
  );
}

export default FooterWrapper;
