export const datePickerModalStyles = {
  content: {
    w: '356px !important',
  },
  body: {
    position: 'relative',
    h: '514px !important',
    minH: '514px !important',
  },
  footer: {
    borderTopWidth: '1px',
    borderColor: 'brand.darkBlue.200',
  },
  btn: {
    fontWeight: 'semibold',
    borderRadius: 'sm',
  },
};
