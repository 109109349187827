import React from 'react';
import { Checkbox } from '@chakra-ui/react';
import BaselaneResponsiveCell from './BaselaneResponsiveCell';

type BaselaneResponsiveCellCheckboxProps = {
  /**
   * cell boolean to check checkbox
   */
  isChecked: Object,
  /**
   * cell boolean to disable checkbox
   */
  isDisabled?: Object,
  /**
   * On checkbox change
   */
  onChange: Object,
  /**
   * Cell styles
   */
  styles?: Object,
  /**
   * Index to be used to grab information specific in config
   */
  configIndex: number,
  /**
   * className for cypress tests
   */
  className?: string,
};

/**
 * Renders a variation of BaselaneReponsiveCell to be used inside BaselaneResponsiveTableRow
 * For dropdpown elements
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveCellCheckbox = ({
  isChecked,
  isDisabled,
  onChange,
  styles,
  configIndex,
  className,
  ...rest
}: BaselaneResponsiveCellCheckboxProps) => {
  return (
    <BaselaneResponsiveCell stopEventPropagation {...{ overflow: 'initial', ...rest }}>
      <Checkbox
        {...{
          className: className ? `${className} primary-500` : 'primary-500',
          onChange,
          isChecked,
          isDisabled,
        }}
      />
    </BaselaneResponsiveCell>
  );
};

BaselaneResponsiveCellCheckbox.defaultProps = {
  isDisabled: false,
  styles: {},
  className: null,
};

export default BaselaneResponsiveCellCheckbox;
