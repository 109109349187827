export const alternatePhoneContainerStyles = {
  backgroundColor: 'brand.neutral.white',
  minHeight: '100vh',
  height: '100vh',
  width: '100%',
  direction: 'column',
  alignItems: 'center',
};

export const alternatePhoneContentContainerStyles = (isMobile) => ({
  alignItems: 'center',
  w: '100%',
  px: 4,
  py: 2,
  position: 'relative',
  justifyContent: 'space-between',
  mb: isMobile ? 5 : '60px',
  borderBottom: '1px',
  borderColor: 'brand.darkBlue.150',
});

export const headingTextStyles = {
  size: 'headline-xl',
  color: 'brand.neutral.900',
  mb: 1,
};

export const phoneTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  mb: 3,
};
