import React from 'react';
import { Spinner, Stack, Text } from '@chakra-ui/react';

const Loader = () => {
  return (
    <Stack height="153px" alignItems="center" justifyItems="center">
      <Stack
        height="100%"
        alignItems="center"
        fontWeight="medium"
        my="auto"
        justifyContent="center"
        fontSize="lg"
        spacing="16px"
      >
        <Spinner size="lg" />
        <Text color="brand.neutral.900">Loading requirements...</Text>
      </Stack>
    </Stack>
  );
};

export default Loader;
