import React from 'react';
import { Stack, Text, Box } from '@chakra-ui/react';
import ObieModal from '../ObieModal';

import { titleStyles, descriptionStyles, buttonContainer } from './styles/bottomCompareCard';

type BottomCardProps = {
  title: string,
  description: string,
  buttonProps: string,
};

function BottomCard({ title, description, buttonProps }: BottomCardProps) {
  return (
    <Stack>
      <Text {...titleStyles}>{title}</Text>
      <Text {...descriptionStyles}>{description}</Text>
      <Box {...buttonContainer}>
        <ObieModal {...buttonProps} />
      </Box>
    </Stack>
  );
}

export default BottomCard;
