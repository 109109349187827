export const iconContainerStyles = {
  mr: '18px',
};

export const containerStyles = {
  border: 'none',
  my: '24px',
};

export const headerStyles = {
  border: 'none',
};

export const buttonStyles = {
  borderRadius: '4px',
  bg: 'brand.neutral.white',
  p: '24px',
  color: 'brand.neutral.700',
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: '20px',
  maxHeight: '64px',
  _hover: { bg: 'brand.blue.50' },
  cursor: 'pointer',
};

export const iconStyles = {
  color: '#3A4B5B',
};

export const contentStyles = {
  bg: 'brand.neutral.white',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: 'brand.darkBlue.250',
  borderBottomRadius: '4px',
};
