import { truncatedText } from '@core/components/Shared/styles/text.style';

export const fileUploaderStyles = {
  input: ({ dragAndDropId }) => {
    return {
      // Hide Input
      w: '0.1px',
      h: '0.1px',
      opacity: 0,
      overflow: 'hidden',
      position: 'absolute',
      zIndex: '-1',
      sx: {
        [`&:focus + #${dragAndDropId}`]: {
          boxShadow: '0 0 0 2px #9FD8FF',
        },
      },
    };
  },
  dragAndDrop: {
    container: ({ isUploading }) => {
      const hoverStyles = isUploading
        ? {}
        : {
            sx: {
              '&.dragging': {
                bgColor: 'brand.blue.50',
              },
            },
            _hover: {
              bgColor: 'brand.blue.50',
            },
            _active: {
              bgColor: 'brand.blue.100',
            },
          };

      return {
        m: '0 !important',
        w: '100%',
        p: isUploading ? '24px' : 2,
        cursor: isUploading ? 'default' : 'pointer',
        background: 'brand.neutral.50',
        borderRadius: '6px',
        border: '1px dashed',
        borderColor: 'brand.darkBlue.200',
        backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
        backgroundSize: '1px 100%, 100% 1px, 1px 100% , 100% 1px',
        backgroundRepeat: 'no-repeat',
        ...hoverStyles,
      };
    },
    title: {
      textStyle: 'headline-sm',
      color: 'brand.neutral.600',
    },
    description: {
      textStyle: 'xs',
      color: 'brand.neutral.600',
      m: '0 !important',
    },
    loading: {
      container: {
        display: 'flex',
        alignItems: 'center',
        m: '8px 0 0 0 !important',
      },
      filename: {
        textStyle: 'xs',
        height: '16px',
        ...truncatedText,
      },
      bar: {
        w: '100%',
        borderRadius: '4px',
        h: '8px',
      },
    },
  },
  error: {
    container: {
      alignItems: 'center',
    },
    msg: {
      textStyle: 'xs',
      color: 'red.800AA',
    },
  },
};
