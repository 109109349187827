export const drawerBodyStyles = {
  color: 'brand.neutral.700',
  p: '64px',
};

export const drawerFooterStyles = {
  justifyContent: 'flex-start',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'brand.neutral.white',
  minHeight: '80px',
  p: '20px 32px',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
};

export const smFormLabelStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  _invalid: { color: 'red.800AA' },
};
