export type BaselaneMobileContentWrapperType = 'widget' | 'infobox';

const commonStyles = {
  margin: '16px !important',
  backgroundColor: 'brand.neutral.white',
  borderRadius: '6px',
  width: 'calc( 100% - 32px )',
};

export const containerStyles = (contentType: BaselaneMobileContentWrapperType) => {
  switch (contentType) {
    case 'infobox':
      return {
        ...commonStyles,
        padding: '16px !important',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'brand.darkBlue.150',
        align: 'flex-start',
        margin: '24px !important',
        width: 'calc( 100% - 48px )',
      };
    case 'widget':
    default:
      return {
        ...commonStyles,
        padding: '0 !important',
        boxShadow: '1px 2px 6px rgba(3, 24, 64, 0.04)',
      };
  }
};
