import React from 'react';
import { Box, Flex, Text, HStack, Stack, Heading, VStack } from '@chakra-ui/react';
import { LocationDescriptor } from 'react-router-dom';
import { IconChevronLeft } from '@icons';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import { BaselaneButtonIcon } from '../BaselaneButtonIcon';
import BaselaneButton from '../BaselaneButton';

import {
  headerStyles,
  headerTextStyles,
  headerBackTextStyles,
  headerBarStyles,
  headerLogoBarStyles,
  headerBarShadowStyles,
  underlineStyles,
  buttonStyles,
  iconStyles,
  backButtonContainerStyles,
  mobileLogoButtonStyles,
  mobileLogoContainerStyles,
} from './styles/BaselaneMobileHeader.style';

type BaselaneMobileHeaderProps = {
  title: String,
  showTitle?: Boolean,
  underLine?: String,
  routerLinkTo?: LocationDescriptor,
  actionLink?: Function,
  linkIcon?: Object,
  shadow?: Boolean,
  stay?: Boolean,
  onTop?: Boolean,
  back?: Boolean,
  hideBackText?: Boolean,
  children?: Object,
  variant?: String,
  palette?: String,
  size?: string,
  isMobileLogoHeader?: boolean,
};

function BaselaneMobileHeader({
  title,
  showTitle,
  underLine,
  routerLinkTo,
  actionLink,
  linkIcon,
  shadow,
  stay,
  onTop,
  back,
  hideBackText,
  children,
  isMobileLogoHeader,
  palette,
  variant,
  size,
  ...rest
}: BaselaneMobileHeaderProps): any {
  let barStyles = headerBarStyles;
  let headerTitleStyles = headerTextStyles;

  if (shadow) {
    barStyles = { ...barStyles, ...headerBarShadowStyles };
  }

  if (stay) {
    barStyles = { ...barStyles, position: 'sticky', top: 0 };
  }

  if (back) {
    headerTitleStyles = headerBackTextStyles;
  }

  if (isMobileLogoHeader) {
    return (
      <HStack {...headerLogoBarStyles} zIndex={onTop ? '15' : '12'}>
        <BaselaneButtonIcon
          styles={mobileLogoButtonStyles}
          iconStyles={iconStyles}
          onClick={actionLink}
          variant={variant}
          palette={palette}
          size={size}
          icon={<IconChevronLeft w="16" h="16" color="#6C7884" />}
        />

        <HStack {...mobileLogoContainerStyles}>
          <BaselaneFullLogo w={100} />
        </HStack>
      </HStack>
    );
  }

  return (
    <Stack {...barStyles} zIndex={onTop ? '15' : '12'}>
      <Flex direction="row" alignItems="center" {...{ ...headerStyles, ...rest }}>
        {!back && (
          <>
            <BaselaneButtonIcon
              styles={(buttonStyles, { alignSelf: 'center' })}
              iconStyles={iconStyles}
              to={routerLinkTo}
              onClick={actionLink}
              variant={variant}
              palette={palette}
              size={size}
              icon={linkIcon}
            />

            <Box>
              <Heading {...headerTitleStyles}>{title}</Heading>
              {underLine && <Text {...underlineStyles}>{underLine}</Text>}
            </Box>
          </>
        )}
        {back && (
          <>
            <VStack {...backButtonContainerStyles}>
              <BaselaneButton
                variant="transparent"
                palette="neutral"
                onClick={actionLink}
                {...headerBackTextStyles}
              >
                {showTitle && title ? (
                  <IconChevronLeft w={16} h={16} color="#6C7884" />
                ) : (
                  <IconChevronLeft w={16} h={16} />
                )}
                <Text ml="12px" display={hideBackText ? 'none' : 'auto'}>
                  Back
                </Text>
              </BaselaneButton>
            </VStack>
            {showTitle && title && (
              <Box>
                <Heading {...headerTextStyles}>{title}</Heading>
              </Box>
            )}
          </>
        )}
        <Box marginLeft="auto" marginRight="0">
          {children}
        </Box>
      </Flex>
    </Stack>
  );
}

BaselaneMobileHeader.defaultProps = {
  showTitle: true,
  routerLinkTo: null,
  actionLink: () => {},
  linkIcon: IconChevronLeft,
  shadow: true,
  stay: false,
  onTop: false,
  back: false,
  variant: 'trasnparent',
  palette: 'neutral',
  size: 'md',
  hideBackText: false,
  children: null,
  isMobileLogoHeader: false,
  // onClick: null,
  underLine: null,
};

export default BaselaneMobileHeader;
