import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { useShallow } from 'zustand/react/shallow';

import useCheckbookStore from '@store/Checkbook';

const StepHeader = () => {
  const { currentStep, lastStep } = useCheckbookStore(
    useShallow((state) => ({
      currentStep: state.step,
      lastStep: state.lastStep,
    }))
  );
  const title = {
    1: 'Select account',
    2: 'Checkbook details',
    3: 'Confirm mailing address',
    4: 'Review and place order',
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text textStyle="headline-md" color="brand.neutral.900">
          {title[currentStep]}
        </Text>
        <Text textStyle="xs" color="brand.neutral.600">
          Step {currentStep} of {lastStep}
        </Text>
      </Stack>
      {currentStep === lastStep && (
        <Text textStyle="xs" color="brand.neutral.600" mt="1">
          Checks are delivered in 10-14 business days once the order is processed.
        </Text>
      )}
    </>
  );
};

export default StepHeader;
