import { formInputStyles } from '@shared/styles/input.style';
import customTheme from '@core/theme';

export const inputFieldStyles = {
  ...formInputStyles,
  h: '32px',
  fontSize: 'xs',
  lineHeight: '20px',
  backgroundColor: customTheme.colors.brand.neutral.white,
  borderColor: customTheme.colors.brand.blue['700'],
  _hover: {
    borderColor: customTheme.colors.brand.blue['700'],
  },
};
