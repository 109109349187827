import React from 'react';

type IconAddDottedRectangleProps = {
  shouldSetDimensions?: Boolean,
  w?: string,
  h?: string,
  color?: string,
};

const IconAddDottedRectangle = ({
  shouldSetDimensions,
  w,
  h,
  color,
}: IconAddDottedRectangleProps) => {
  return (
    <svg
      {...(shouldSetDimensions ? { width: w, height: h } : {})}
      viewBox="0 0 38 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x=".5" y=".5" width="37" height="25" rx="1.5" stroke="#06F" strokeDasharray="4 4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4453 9.4c0-.22091-.2238-.4-.5-.4-.2761 0-.5.17909-.5.4v3.0453H15.4c-.2209 0-.4.2239-.4.5 0 .2762.1791.5.4.5h3.0453V16.6c0 .2209.2239.4.5.4.2762 0 .5-.1791.5-.4v-3.1547H22.6c.2209 0 .4-.2238.4-.5 0-.2761-.1791-.5-.4-.5h-3.1547V9.4Z"
        fill={color}
      />
    </svg>
  );
};

IconAddDottedRectangle.defaultProps = {
  shouldSetDimensions: true,
  w: '38',
  h: '36',
  color: 'currentColor',
};
export default IconAddDottedRectangle;
