import React from 'react';
import { Text } from '@chakra-ui/react';
import stripCurrency from '@core/utils/stripCurrency';
import formatCurrency from '@core/utils/formatCurrency';
import AddBankAccount from '@core/components/AddBankAccount';
import ConditionalAddAccount from '@core/components/NativeBankingPage/MainContent/components/BankTransfer/ConditionalAddAccount';
import { resyncLinkStyles } from '@pages/LeasesPage/LeaseSection/styles/receivingBankAccount.styles';

const linkStyles = {
  textDecoration: 'underline',
  _hover: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

const validateAmountField = ({ values, accountsMap }) => {
  const errors = {};

  const { amount, fromTransferAccountId, toTransferAccountId } = values;

  let type = '';
  const formattedAmount = stripCurrency(amount);
  const selectedFromAccObj = accountsMap[fromTransferAccountId];
  const selectedToAccObj = accountsMap[toTransferAccountId];

  let transferLimits = {};
  if (selectedFromAccObj?.isExternal) {
    type = 'TRANSFER_IN';
    transferLimits = selectedToAccObj?.limits;
  } else if (selectedToAccObj?.isExternal) {
    transferLimits = selectedFromAccObj?.limits;
    type = 'TRANSFER_OUT';
  } else {
    type = 'INTERNAL_TRANSFER';
  }

  const hasTransferLimits = type !== 'INTERNAL_TRANSFER';

  const {
    dailyCreditTotal,
    monthlyCreditTotal,
    dailyDebitTotal,
    monthlyDebitTotal,
    dailyCreditLimit,
    monthlyCreditLimit,
  } = transferLimits ?? {};

  const dailyTransfer =
    type === 'TRANSFER_OUT'
      ? parseFloat(dailyCreditTotal, 10) + parseFloat(formattedAmount, 10)
      : parseFloat(dailyDebitTotal, 10) + parseFloat(formattedAmount, 10);

  const monthlyTransfer =
    type === 'TRANSFER_OUT'
      ? parseFloat(monthlyCreditTotal, 10) + parseFloat(formattedAmount, 10)
      : parseFloat(monthlyDebitTotal, 10) + parseFloat(formattedAmount, 10);

  if (!formattedAmount || formattedAmount === 0) {
    errors.amount = 'Amount must be greater than $0.00';
  } else if (formattedAmount !== null && formattedAmount > selectedFromAccObj?.availableBalance) {
    errors.amount =
      'Insufficient funds to make this transfer, please edit the transfer amount to continue.';
  } else if (
    dailyTransfer > dailyCreditLimit &&
    monthlyTransfer < monthlyCreditLimit &&
    hasTransferLimits
  ) {
    errors.amount = `This amount cannot be transferred as it exceeds the ${
      formatCurrency(dailyCreditLimit).inDollars
    } daily limit. Initiate a transfer from your external account or contact us for an exception.`;
  } else if (
    dailyTransfer > dailyCreditLimit &&
    monthlyTransfer > monthlyCreditLimit &&
    hasTransferLimits
  ) {
    errors.amount = `This amount cannot be transferred as it exceeds the ${
      formatCurrency(dailyCreditLimit).inDollars
    } daily limit. Initiate a transfer from your external account or contact us for an exception.`;
  } else if (
    dailyTransfer < dailyCreditLimit &&
    monthlyTransfer > monthlyCreditLimit &&
    hasTransferLimits
  ) {
    errors.amount = `This amount cannot be transferred as it exceeds the ${
      formatCurrency(monthlyCreditLimit).inDollars
    } monthly limit. Initiate a transfer from your external account or contact us for an exception.`;
  }

  return errors;
};

const validateFromAccountField = ({
  formikRef,
  values,
  accountsMap,
  handleEducationalDrawerOpen,
  banks,
  refetch,
  updateLinkTokenData,
  isUpdateLinkTokenLoading,
  resyncBankAccountBalance,
}) => {
  const errors = {};

  const { fromTransferAccountId } = values;

  const selectedFromAccObj = accountsMap[fromTransferAccountId];
  const isBalanceUnavailableFrom = () => {
    return (
      selectedFromAccObj &&
      (!selectedFromAccObj?.availableBalance || selectedFromAccObj?.availableBalance === null)
    );
  };

  if (fromTransferAccountId === '') {
    errors.fromTransferAccountId = 'Please select account';
  }

  if (selectedFromAccObj?.isExternal && selectedFromAccObj?.errorCodes?.isGenericError) {
    errors.fromTransferAccountId = (
      <Text as="span">
        <Text as="span">
          This account can&apos;t be used to initiate a transfer from Baselane. Use a different
          account or initiate the transfer from your external account using your
        </Text>{' '}
        <Text as="span" {...linkStyles} onClick={handleEducationalDrawerOpen}>
          Baselane Banking account & routing numbers.
        </Text>
      </Text>
    );
  }

  if (
    selectedFromAccObj?.isExternal &&
    selectedFromAccObj?.errorCodes?.isInstitutionAuthNotSupported
  ) {
    errors.fromTransferAccountId = (
      <Text as="span">
        <Text as="span">
          This account can&apos;t be used to initiate a transfer from Baselane. Use a different
          account or initiate the transfer from your external account using your
        </Text>{' '}
        <Text as="span" {...linkStyles} onClick={handleEducationalDrawerOpen}>
          Baselane Banking account & routing numbers.
        </Text>
      </Text>
    );
  }

  if (selectedFromAccObj?.isExternal && selectedFromAccObj?.errorCodes?.isNameCheckFailed) {
    errors.fromTransferAccountId = (
      <Text as="span">
        <Text as="span">
          This account can&apos;t be used to initiate a transfer from Baselane. Its legal name does
          not match your Baselane Banking account(s) name. Use a different account or initiate the
          transfer from your external account using your
        </Text>{' '}
        <Text as="span" {...linkStyles} onClick={handleEducationalDrawerOpen}>
          Baselane Banking account & routing numbers.
        </Text>
      </Text>
    );
  }

  if (
    selectedFromAccObj?.isExternal &&
    selectedFromAccObj?.errorCodes?.isOAuthNamePermissionMissing
  ) {
    errors.fromTransferAccountId = (
      <Text as="span">
        <Text as="span">
          This account can&apos;t be used to initiate a transfer from Baselane. You did not grant
          sufficient permissions to verify the account.
        </Text>{' '}
        <ConditionalAddAccount
          refetchTransferAccountsList={() => {
            refetch().then(() => formikRef.current?.validateForm());
          }}
          transferType="TRANSFER_IN"
          accountProps={{
            isDirectToPlaid: true,
            hideButton: true,
            isDirectToPlaidButtonProps: {
              showCustomButton: true,
              hasIconLock: false,
              hasRightChevronIcon: false,
              titleText: 'Resync your account and update permissions.',
              containerStyles: { as: 'span' },
              type: 'link',
              variant: 'link',
              palette: 'danger',
              size: 'sm',
              styles: resyncLinkStyles,
            },
          }}
          styles={{ container: { as: 'span' } }}
        />
      </Text>
    );
  }

  if (
    selectedFromAccObj?.isExternal &&
    selectedFromAccObj?.institutionName !== 'manual' &&
    isBalanceUnavailableFrom()
  ) {
    errors.fromTransferAccountId = (
      <Text as="span">
        <Text as="span">We were unable to fetch the balance for this account.</Text>{' '}
        <Text
          as="span"
          {...linkStyles}
          onClick={() => {
            resyncBankAccountBalance({
              variables: { id: values?.fromTransferAccountId },
            });
          }}
        >
          Click here to resync your account balance.
        </Text>
      </Text>
    );
  }

  if (selectedFromAccObj?.isExternal && selectedFromAccObj?.errorCodes?.isAccountDisconnected) {
    errors.fromTransferAccountId = (
      <Text as="span">
        Account connection expired.{' '}
        <AddBankAccount
          bankAccId={fromTransferAccountId}
          mode="RESYNC"
          titleText="Resync your account"
          size="sm"
          variant="link"
          palette="danger"
          styles={resyncLinkStyles}
          showCustomButton
          hasIconLock={false}
          hasRightChevronIcon={false}
          containerStyles={{ as: 'span' }}
          banks={banks}
          state={selectedFromAccObj?.isBankConnected ? 'CONNECTED' : 'ITEM_LOGIN_REQUIRED'}
          isLoading={isUpdateLinkTokenLoading}
          handleSuccessFn={(res) => {
            const { connectionState } = res?.data?.reSyncExternalBankAccount ?? {};
            if (connectionState === 'CONNECTED') {
              refetch().then(() => formikRef.current?.validateForm());
            }
          }}
          updateLinkToken={updateLinkTokenData && updateLinkTokenData.updatePlaidLinkToken}
        />{' '}
        to enable transfers.
      </Text>
    );
  }

  return errors;
};

const validateToAccountField = ({
  formikRef,
  values,
  accountsMap,
  handleOpenManualAccountDrawer,
  banks,
  refetch,
  updateLinkTokenData,
  isUpdateLinkTokenLoading,
}) => {
  const errors = {};

  const { fromTransferAccountId, toTransferAccountId } = values;

  const selectedFromAccObj = accountsMap[fromTransferAccountId];
  const selectedToAccObj = accountsMap[toTransferAccountId];

  if (toTransferAccountId === '') {
    errors.toTransferAccountId = 'Please select account';
  }

  if (selectedToAccObj?.isExternal && selectedToAccObj?.errorCodes?.isGenericError) {
    errors.toTransferAccountId = (
      <Text as="span">
        <Text as="span">This account does not support transfers.</Text>{' '}
        <Text as="span" {...linkStyles} onClick={handleOpenManualAccountDrawer}>
          Add this account manually
        </Text>{' '}
        <Text as="span">to initiate a transfer.</Text>
      </Text>
    );
  }

  if (selectedToAccObj?.isExternal && selectedToAccObj?.errorCodes?.isInstitutionAuthNotSupported) {
    errors.toTransferAccountId = (
      <Text as="span">
        <Text as="span">
          This account was added using Plaid, which does not support transfers for this financial
          institution.
        </Text>{' '}
        <Text as="span" {...linkStyles} onClick={handleOpenManualAccountDrawer}>
          Add this account manually
        </Text>{' '}
        <Text as="span">to initiate a transfer.</Text>
      </Text>
    );
  }

  if (selectedToAccObj?.isExternal && selectedToAccObj?.errorCodes?.isNameCheckFailed) {
    errors.toTransferAccountId = (
      <Text as="span">
        <Text as="span">
          This account can&apos;t be used to initiate a transfer from Baselane. Its legal name does
          not match your Baselane Banking account(s) name.
        </Text>{' '}
        <Text as="span" {...linkStyles} onClick={handleOpenManualAccountDrawer}>
          Add this account manually
        </Text>{' '}
        <Text as="span">to initiate a transfer.</Text>
      </Text>
    );
  }

  if (selectedToAccObj?.isExternal && selectedToAccObj?.errorCodes?.isOAuthNamePermissionMissing) {
    errors.toTransferAccountId = (
      <Text as="span">
        <Text as="span">
          This account does not support transfers, as you did not grant sufficient permission to
          verify the account.{' '}
        </Text>
        <ConditionalAddAccount
          refetchTransferAccountsList={() => {
            refetch().then(() => formikRef.current?.validateForm());
          }}
          transferType="TRANSFER_OUT"
          accountProps={{
            isDirectToPlaid: true,
            hideButton: true,
            isDirectToPlaidButtonProps: {
              type: 'link',
              variant: 'link',
              palette: 'danger',
              size: 'sm',
              showCustomButton: true,
              hasIconLock: false,
              hasRightChevronIcon: false,
              titleText: 'Resync your account and update permissions.',
              containerStyles: { as: 'span' },
              styles: resyncLinkStyles,
            },
          }}
          styles={{ container: { as: 'span' } }}
        />
      </Text>
    );
  }

  if (selectedToAccObj?.isExternal && selectedToAccObj?.errorCodes?.isAccountDisconnected) {
    errors.toTransferAccountId = (
      <Text as="span">
        Account connection expired.{' '}
        <AddBankAccount
          bankAccId={toTransferAccountId}
          mode="RESYNC"
          titleText="Resync your account"
          size="sm"
          variant="link"
          palette="danger"
          styles={resyncLinkStyles}
          showCustomButton
          hasIconLock={false}
          hasRightChevronIcon={false}
          containerStyles={{ as: 'span' }}
          banks={banks}
          state={selectedFromAccObj?.isBankConnected ? 'CONNECTED' : 'ITEM_LOGIN_REQUIRED'}
          isLoading={isUpdateLinkTokenLoading}
          handleSuccessFn={(res) => {
            const { connectionState } = res?.data?.reSyncExternalBankAccount ?? {};
            if (connectionState === 'CONNECTED') {
              refetch().then(() => formikRef.current?.validateForm());
            }
          }}
          updateLinkToken={updateLinkTokenData && updateLinkTokenData.updatePlaidLinkToken}
        />{' '}
        to enable transfers.
      </Text>
    );
  }

  if (
    fromTransferAccountId !== '' &&
    toTransferAccountId !== '' &&
    fromTransferAccountId === toTransferAccountId
  ) {
    errors.toTransferAccountId = 'Cannot transfer within the same account';
  }

  if (selectedFromAccObj?.isExternal && selectedToAccObj?.isExternal) {
    errors.toTransferAccountId = 'You cannot transfer between two external accounts';
  }

  return errors;
};

export const handleValidation = ({
  formikRef,
  values,
  accountsMap,
  handleEducationalDrawerOpen,
  handleOpenManualAccountDrawer,
  banks,
  refetch,
  updateLinkTokenData,
  isUpdateLinkTokenLoading,
  getUpdateLinkToken,
  resyncBankAccountBalance,
}) => {
  const errors = {
    ...validateAmountField({ values, accountsMap }),
    ...validateFromAccountField({
      formikRef,
      values,
      accountsMap,
      handleEducationalDrawerOpen,
      banks,
      refetch,
      updateLinkTokenData,
      isUpdateLinkTokenLoading,
      getUpdateLinkToken,
      resyncBankAccountBalance,
    }),
    ...validateToAccountField({
      formikRef,
      values,
      accountsMap,
      handleOpenManualAccountDrawer,
      banks,
      refetch,
      updateLinkTokenData,
      isUpdateLinkTokenLoading,
      getUpdateLinkToken,
    }),
  };

  return errors;
};
