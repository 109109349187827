import React, { Fragment } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Stack,
  Text,
} from '@chakra-ui/react';
import { IconChevronUp, IconChevronDown } from '@icons';
import { BaselaneDivider } from '@core/components/Shared/components';
import { faqStyles } from '../styles/faq.styles';

// Destructure Imported Styles
const {
  title,
  question,
  answer,
  wrapper,
  accordion,
  accordionbutton,
  accordioncontent,
  iconcontainer,
  divider,
} = faqStyles;

const rcDrawerConst = {
  title: 'FAQs',
  q1: 'How does adding an invoice work?',
  q2: 'What will my tenant see?',
  a1:
    'Adding an invoice lets you request one-time payments from your tenants for things like utilities or fees.',
  a2:
    'Your tenant will be notified by email. Adding a new invoice will not automatically charge your tenant. They will need to log in to schedule a new payment.',
};

const rcDrawerComponents = () => {
  // FAQ Const
  const { title: titleText, q1, q2, a1, a2 } = rcDrawerConst;

  return {
    title: <Text {...title}>{titleText}</Text>,
    q1: <Text {...question}>{q1}</Text>,
    q2: <Text {...question}>{q2}</Text>,
    a1: (
      <Stack {...answer}>
        <Text>{a1}</Text>
      </Stack>
    ),
    a2: (
      <Stack {...answer}>
        <Text>{a2}</Text>
      </Stack>
    ),
  };
};

export const getFAQComponent = () => {
  const { title: titleText, q1, q2, a1, a2 } = rcDrawerComponents();
  const pairs = [
    { key: '1', header: q1, content: a1 },
    { key: '2', header: q2, content: a2 },
  ];

  return (
    <>
      {/* Title for Mobile  */}
      {titleText}

      {/* Questions & Answers */}
      <Accordion {...wrapper} allowMultiple>
        {pairs.map((item) => (
          <Fragment key={item.key}>
            <AccordionItem {...accordion}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton {...accordionbutton}>
                    {item.header}
                    <Box {...iconcontainer}>
                      {isExpanded ? (
                        <IconChevronUp color="#3A4B5B" />
                      ) : (
                        <IconChevronDown color="#3A4B5B" />
                      )}
                    </Box>
                  </AccordionButton>
                  <AccordionPanel {...accordioncontent}>{item.content}</AccordionPanel>
                  <BaselaneDivider styles={divider} />
                </>
              )}
            </AccordionItem>
          </Fragment>
        ))}
      </Accordion>
    </>
  );
};
