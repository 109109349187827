import React from 'react';

type IllustrationSuccessProps = { w?: number, h?: number };

function IllustrationSuccess({ w, h }: IllustrationSuccessProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33.5996" cy="33.6" r="24" fill="#8AD95F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4004 30.4C53.4004 43.1026 43.1029 53.4 30.4004 53.4C17.6978 53.4 7.40039 43.1026 7.40039 30.4C7.40039 17.6975 17.6978 7.40002 30.4004 7.40002C43.1029 7.40002 53.4004 17.6975 53.4004 30.4ZM30.4004 54.4C43.6552 54.4 54.4004 43.6549 54.4004 30.4C54.4004 17.1452 43.6552 6.40002 30.4004 6.40002C17.1456 6.40002 6.40039 17.1452 6.40039 30.4C6.40039 43.6549 17.1456 54.4 30.4004 54.4ZM41.154 23.5536C41.3492 23.3583 41.3492 23.0417 41.154 22.8465C40.9587 22.6512 40.6421 22.6512 40.4469 22.8465L26.4218 36.8715L20.3741 30.0678C20.1907 29.8615 19.8746 29.8429 19.6682 30.0263C19.4618 30.2098 19.4433 30.5258 19.6267 30.7322L25.6744 37.5359C26.0567 37.9659 26.7221 37.9855 27.1289 37.5786L41.154 23.5536Z"
        fill="#000619"
      />
    </svg>
  );
}

IllustrationSuccess.defaultProps = {
  w: 64,
  h: 64,
};

export default IllustrationSuccess;
