import React from 'react';
import { Flex, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { BaselaneCardNew, BaselaneChip } from '@shared/components';
import { IconChevronRight } from '@core/components/Icons';
import { useUserContext } from '@core/contexts/UserContext';
import {
  leaseSectionHeader,
  leaseSmallTextGray,
  leaseTermsContainer,
  leaseTermsLargeText,
  processingFeeStyles,
} from '../../../LeaseResponsiveDetail/styles/leaseResponsiveDetail.styles';

interface ProcessFeeProps {
  handleClick: () => void;
  invoiceProcessingFeePaidBy: 'LANDLOARD' | 'TENANT';
  isArchived: boolean;
  isExternalBankAccount: boolean;
}

export function ProcessingFee({
  handleClick,
  invoiceProcessingFeePaidBy,
  isArchived,
  isExternalBankAccount,
}: ProcessFeeProps) {
  const { user } = useUserContext();

  const { startDate, endDate } =
    user.userPromotion.find(
      (p) => p.promotion.type === 'FEES_WAIVER' && p.promotion.feeType === 'ACH_FEE'
    ) ?? {};
  const isUserEligibleForAchFeePromo = moment() < moment(endDate) && moment() >= moment(startDate);

  const {
    headerContainerStyles,
    cardContainerStyles,
    textContainerStyles,
    chevronContainerStyles,
  } = processingFeeStyles;
  return (
    <>
      <Flex {...headerContainerStyles}>
        <Text {...leaseSectionHeader}>Tenant ACH payment fee</Text>
        {isUserEligibleForAchFeePromo && (
          <BaselaneChip
            variant="simple-primary"
            label={`Beginning ${moment(endDate).format('MMM D, YYYY')}`}
            size="sm"
          />
        )}
      </Flex>

      <BaselaneCardNew
        variant={isArchived ? 'normal' : 'clickable'}
        {...leaseTermsContainer()}
        onClick={isArchived ? () => undefined : handleClick}
      >
        <Stack {...cardContainerStyles}>
          {!isExternalBankAccount ? (
            <Stack {...textContainerStyles}>
              <Text {...leaseTermsLargeText}>Waived</Text>
              <Text {...{ ...leaseSmallTextGray, whiteSpace: 'normal' }}>
                Fees are waived for tenant ACH payments since rent is collected into a Baselane
                Banking account.
              </Text>
            </Stack>
          ) : (
            <Stack {...textContainerStyles}>
              <Text {...leaseSmallTextGray}>Charged to</Text>
              <Text {...leaseTermsLargeText}>
                {invoiceProcessingFeePaidBy === 'LANDLORD' ? 'You' : 'Tenant'}
              </Text>
            </Stack>
          )}

          <Flex {...chevronContainerStyles}>
            <IconChevronRight color="#257ED0" />
          </Flex>
        </Stack>
      </BaselaneCardNew>
    </>
  );
}
