import React from 'react';

function IconPadlock({ w, h, color }: { w?: Number, h?: Number, color?: String }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.438 1.02a2.917 2.917 0 0 1 4.979 2.063v1.75H9a1.75 1.75 0 0 1 1.75 1.75v3.5A1.75 1.75 0 0 1 9 11.833H2a1.75 1.75 0 0 1-1.75-1.75v-3.5A1.75 1.75 0 0 1 2 4.833h.583v-1.75c0-.773.308-1.515.855-2.062Zm.312 3.813h3.5v-1.75a1.75 1.75 0 1 0-3.5 0v1.75ZM2 6a.583.583 0 0 0-.583.583v3.5a.583.583 0 0 0 .583.583h7a.583.583 0 0 0 .583-.583v-3.5A.583.583 0 0 0 9 6H2Zm3.5 1.167c.322 0 .583.26.583.583v1.166a.583.583 0 1 1-1.166 0V7.75c0-.322.26-.583.583-.583Z"
        fill={color}
      />
    </svg>
  );
}

IconPadlock.defaultProps = {
  w: 11,
  h: 12,
  color: '#3A4B5B',
};

export default IconPadlock;
