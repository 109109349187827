export const bankInfoBoxContainerStyles = {
  minH: '100%',
  borderRadius: '6px',
  border: '1px solid',
  borderColor: 'brand.darkBlue.200',
  spacing: 0,
  cursor: 'pointer',
  w: '100%',
  g: 0,
  p: 1.5,
  justifyContent: 'space-between',
  minHeight: '100%',
};

export const bankInfoLabelTextStyles = {
  color: 'brand.neutral.600',
  textStyle: 'xs',
};

export const bankInfoContentContainerStyles = {
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  color: 'brand.neutral.600',
};

export const bankInfoContentTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.900',
};
