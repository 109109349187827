import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import { CREATE_OR_UPDATE_LEASE, GET_INVOICE_LIST } from '@pages/LeasesPage/queries';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { getInvoicesForUnit } from '@pages/LeasesPage/helpers/invoice.helpers';
import {
  checkIsSectionValid,
  handleCreateLeaseErr,
  resetCreateLeaseValues,
} from '@pages/LeasesPage/LeaseSection/helpers/createLease.helpers';
import {
  getInitialTabIndex,
  getLeaseData,
} from '@pages/LeasesPage/LeaseSection/formHelpers/createLease.helper';
import toasts from '@pages/LeasesPage/LeaseSection/helpers/toasts';

// TODO: convert to context in the future? didn't wanna change too much in one go for this PR
const useCreateLeaseVariables = (
  unit,
  initialMenuState,
  LEASE_TABS,
  bankProps,
  isPropertyPanelValid = false,
  isEditExistingLease = false,
  isUnifiedRC = false
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [setLeaseData] = useMutation(CREATE_OR_UPDATE_LEASE);

  // Retrieve Invoice data
  const {
    loading: leaseInvoicesLoading,
    data: leaseInvoices,
    error: leaseInvoicesError,
    refetch: refreshLeaseInvoices,
  } = useQuery(GET_INVOICE_LIST, {
    fetchPolicy: 'cache-first',
    skip: unit?.lease?.isDummyData || !unit?.lease?.id || isUnifiedRC,
    variables: { input: { dateType: 'DUE_DATE', leaseId: unit?.lease?.id } },
  });

  const { invoiceList } = leaseInvoices ?? {};
  const invoicesData = invoiceList?.invoices;
  const invoicesForUnit = getInvoicesForUnit(invoicesData);

  const {
    existingLeaseId,
    unitState,
    unitLeaseEndDate,
    leaseCreated,
    initialCompletedTags,
  } = getLeaseData(unit);

  const [completedTags, setCompletedTags] = useState(
    isPropertyPanelValid ? initialCompletedTags?.concat(['PROPERTY']) : initialCompletedTags
  );

  const [leaseMenuOptions, setLeaseMenuOptions] = useState(
    initialMenuState(completedTags, isPropertyPanelValid)
  );
  const [tenantProfileId, setTenantProfileId] = useState();
  const [leaseId, setLeaseId] = useState(existingLeaseId);

  const nonValidSections = () =>
    leaseMenuOptions?.filter((leaseMenuOption) => !leaseMenuOption.isValid);

  const [tabIndex, setTabIndex] = useState(
    getInitialTabIndex(leaseCreated, nonValidSections(), unit)
  );

  const [existingTenantState, setExistingTenantState] = useState({
    changeTab: false,
    isInvalid: false,
    existingTenantToggle: false,
  });

  const [buttonTxt, setButtonTxt] = useState(
    leaseId ? 'Resume Rent Collection Set Up' : 'Set Up Rent Collection'
  );

  const connectedBanks = bankProps?.banksData?.bank ?? {};

  const [hasConnectedBank, setHasConnectedBank] = useState(connectedBanks.length > 0);

  useEffect(() => {
    const numberOfConnectedBanks = bankProps?.banksData?.bank?.length ?? 0;
    if (numberOfConnectedBanks !== hasConnectedBank) {
      setHasConnectedBank(numberOfConnectedBanks > 0);
    }
  }, [bankProps?.banksData]);

  const [showReceivingBAEmptyState, setShowReceivingBAEmptyState] = useState(
    connectedBanks.length <= 0
  );

  const initialVariables = {
    id: existingLeaseId || null,
    propertyUnitId: unit?.id,
    // ...(existingLeaseId && tenantProfileId && { tenantProfileId }),
  };

  const [variables, setLeaseVariables] = useState(initialVariables);

  function isSectionValid(newCompletedTags) {
    checkIsSectionValid({
      newCompletedTags,
      leaseMenuOptions,
      setCompletedTags,
      setLeaseMenuOptions,
      isPropertyPanelValid,
    });
  }

  function resetValue(clearVars) {
    resetCreateLeaseValues({
      variables,
      initialVariables,
      setLeaseId,
      setLeaseVariables,
      setLeaseMenuOptions,
      setCompletedTags,
      initialMenuState,
      clearVars,
    });
  }

  const { showCreateLeaseErrorToast } = toasts();

  function handleCreateLeaseError(error) {
    handleCreateLeaseErr({
      error,
      setCompletedTags,
      showCreateLeaseErrorToast,
      setLeaseVariables,
      variables,
      completedTags,
    });
  }

  function saveTenantProfileId(tenantProfile) {
    setTenantProfileId(tenantProfile);
    const newVariables = { ...variables, tenantProfileId: tenantProfile };
    setLeaseVariables(newVariables);
  }

  const handleCreateLeaseSetUp = () => {
    const {
      lease: { leaseType },
    } = unit;

    let newVariables = { action: 'REVIEWED' };
    if (leaseType === null) {
      newVariables = { ...newVariables, leaseType: 'FIXED' };
    }
    setLeaseVariables({ ...variables, ...newVariables });
    return newVariables;
  };

  // new setFormVariables saves all fields
  const setAllFormVariables = (newVariables, isTenantFormFields) => {
    let newVars;
    // we need a flag to say whether we deleted from variables based on newVariables,
    // otherwise we might delete more than one with the same name for non-BE saved ones
    let deleteOrMergeFee;
    // payments is an array of object, concat first
    if (newVariables.payments) {
      newVars = { ...variables, ...newVariables };
      newVars.payments = [];
      if (newVariables.payments[0].name === 'FEE' && !newVariables.payments[0].delete) {
        // adding a new FEE
        newVars.payments.push(newVariables.payments[0]);
      } else if (
        (newVariables.payments[0].name === 'RENT' ||
          newVariables.payments[0].name === 'LATE_FEE') &&
        newVariables.payments[0].id &&
        !variables.payments?.filter((fee) => fee.id === newVariables.payments[0].id)[0]
      ) {
        // for edit lease, existing RENT or LATE_FEE (with id!), we can edit that now
        newVars.payments.push(newVariables.payments[0]);
      }

      if (variables.payments && variables.payments.length === 0) {
        newVars.payments = cloneDeep(newVariables.payments);
      }

      // now add existing variables, both BE and non-saved yet.
      variables.payments?.forEach((object, index) => {
        if (object.name === newVariables.payments[0].name && object.name !== 'FEE') {
          // merging non-FEE objects
          newVars.payments.push({
            ...variables.payments[index],
            ...newVariables.payments[0],
          });
        } else if (
          object.name === 'FEE' &&
          object.id &&
          object.id === newVariables.payments[0].id &&
          !deleteOrMergeFee
        ) {
          // merging BE FEE object if changed (deleted), only once max
          newVars.payments.push({
            ...variables.payments[index],
            ...newVariables.payments[0],
          });
          deleteOrMergeFee = true;
        } else if (
          object.name === 'FEE' &&
          object.description === newVariables.payments[0].description &&
          object.amount === newVariables.payments[0].amount &&
          !object.id &&
          !deleteOrMergeFee
        ) {
          // don't push FEE not yet in BE that is marked for delete, only once max
          deleteOrMergeFee = true;
        } else {
          newVars.payments.push(variables.payments[index]);
        }
      });
    } else if (isTenantFormFields) {
      newVars = {
        ...variables,
        tenantProfileMetadata: { ...variables.tenantProfileMetadata, ...newVariables },
      };
    } else {
      newVars = { ...variables, ...newVariables };
    }
    setLeaseVariables(newVars);
    return newVars;
  };

  const resetCreateLeaseStates = () => {
    localStorage.removeItem('unit');
    setLeaseId(null);
    setTenantProfileId(null);
    setExistingTenantState({
      changeTab: false,
      isInvalid: false,
    });

    setLeaseVariables({
      ...initialVariables,
      id: null,
      tenantProfileId: null,
      tenantProfileMetadata: null,
      propertyUnitId: unit?.id,
    });
  };

  const { cache } = useApolloClient();
  const { totalProperties = 0, totalUnits = 0 } = cache.data.data.ROOT_QUERY?.propertySummary || {};
  const { activeLease = 0 } = cache.data.data.ROOT_QUERY?.leaseDetail?.summary || {};
  const sendTenantPendingEvent = () => {
    sendSegmentEvent('tenant_pending_fe', {
      title: document.title,
      properties_count: totalProperties,
      units_count: totalUnits,
      rent_collection_count: activeLease,
      isMobile,
    });
    // reddit tracking
    window?.rdt('track', 'Purchase');
  };

  // This is for existing lease, might still happen if we go back in onboarding flow
  // and re-select an earlier selecting unit?
  useEffect(() => {
    if (unit) {
      const existingLId = leaseId;

      const iCompletedTags = completedTags;

      setLeaseId(existingLId);
      if (setButtonTxt) {
        setButtonTxt(existingLId ? 'Resume Rent Collection Set Up' : 'Set Up Rent Collection');
      }

      const newVariables = {
        ...variables,
        id: existingLId || null,
        propertyUnitId: unit?.id,
        ...(unit?.lease?.tenantProfileId && { tenantProfileId: unit?.lease?.tenantProfileId }),
      };

      setLeaseVariables(newVariables);

      setTenantProfileId(unit?.lease?.tenantProfileId);

      setCompletedTags(iCompletedTags);
      setLeaseMenuOptions(initialMenuState(iCompletedTags, isPropertyPanelValid));
      // remove queryParam if in unified lease flow
      if (!isEditExistingLease) {
        navigate(location.pathname, { replace: true, state: location.state });
      }
    }
  }, [unit]);

  useEffect(() => {
    if (!leaseId) {
      setCompletedTags(null);
      setLeaseMenuOptions(initialMenuState(completedTags, isPropertyPanelValid));
    }
  }, [setLeaseData, leaseId, variables]);

  useEffect(() => {
    isSectionValid(completedTags);
  }, [completedTags]);

  useEffect(() => {
    setShowReceivingBAEmptyState(tabIndex === LEASE_TABS.ACCOUNTS && !hasConnectedBank);
  }, [tabIndex, hasConnectedBank]);

  const addPropertyRef = useRef(null);
  const leaseTermsRef = useRef(null);
  const depositsFeesRef = useRef(null);
  const invoicesRef = useRef(null);
  const bankAccountsRef = useRef(null);
  const tenantDetailsRef = useRef(null);
  const confirmRef = useRef(null);

  return {
    variablesAndStates: {
      unitState,
      unitLeaseEndDate,
      variables,
      setLeaseVariables,
      existingTenantState,
      setExistingTenantState,
      tabIndex,
      setTabIndex,
      leaseId,
      setLeaseId,
      tenantProfileId,
      setTenantProfileId,
      leaseMenuOptions,
      setLeaseMenuOptions,
      completedTags,
      setCompletedTags,
      buttonTxt,
      setButtonTxt,
      initialVariables,
      leaseCreated,
      existingLeaseId,
      hasConnectedBank,
      setHasConnectedBank,
      showReceivingBAEmptyState,
      setShowReceivingBAEmptyState,
      invoicesForUnit,
      leaseInvoicesError,
      leaseInvoicesLoading,
      refreshLeaseInvoices,
    },

    helperFunctions: {
      nonValidSections,
      isSectionValid,
      resetValue,
      handleCreateLeaseError,
      saveTenantProfileId,
      handleCreateLeaseSetUp,
      setAllFormVariables,
      sendTenantPendingEvent,
      resetCreateLeaseStates,
    },

    refVariables: {
      addPropertyRef,
      leaseTermsRef,
      depositsFeesRef,
      invoicesRef,
      bankAccountsRef,
      tenantDetailsRef,
      confirmRef,
    },
  };
};

export default useCreateLeaseVariables;
