export const expiringSoonBannerStyles = ({ isTablet }) => {
  const desktopStyle = {
    container: {
      my: '36px',
    },
    button: {
      alignSelf: 'center',
    },
    messagecard: {
      containerStyles: {
        marginBottom: '0px',
        w: '100%',
      },
      styles: {
        titleContainer: {
          m: '0 !important',
        },
        innercontainer: {
          p: '8px 16px !important',
        },
        title: {
          textStyle: 'xs',
        },
      },
    },
  };

  const mobileStyles = {
    container: {
      my: '24px',
    },
    button: {
      alignSelf: 'flex-start',
    },
    messagecard: {
      containerStyles: {
        marginBottom: '0px',
        w: '100%',
      },
      styles: {
        titleContainer: {
          gap: '8px',
        },
        innercontainer: {
          p: '12px 20px !important',
        },
        title: {
          textStyle: 'md',
          m: '0 !important',
        },
      },
    },
  };

  return isTablet ? desktopStyle : mobileStyles;
};
