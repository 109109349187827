import historyStateStorage from '@core/storage/historyStateStorage';

export const calculateTabIndex = (completedTags) => {
  if (!completedTags) {
    return 0;
  }
  if (completedTags.indexOf('LEASE_TERM') === -1) {
    return 1;
  }
  if (completedTags.indexOf('DEPOSITS_FEES') === -1) {
    return 2;
  }
  if (completedTags.indexOf('INVOICES') === -1) {
    return 3;
  }
  if (completedTags.indexOf('BANK_ACCOUNT') === -1) {
    return 4;
  }
  if (completedTags.indexOf('TENANT') === -1) {
    return 5;
  }
  return 6;
};

export const generalRedirectToURC = ({ navigate, pathname, state }) => {
  localStorage.removeItem('unit');
  historyStateStorage.write(state);
  navigate(pathname);
};

export const specificRedirectToURC = ({ navigate, pathname, state, search }) => {
  historyStateStorage.write(state);
  navigate(pathname, { search });
};
