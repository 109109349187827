import { gql } from '@apollo/client';

export const GET_BANK_UNIT_ACCOUNT = gql`
  query BankSummary(
    $isConnectedBank: Boolean
    $isConnectedAccount: Boolean
    $accountType: AccountType
    $isImporting: Boolean
    $isExternal: Boolean
    $unitApplicationIds: [String!]
  ) {
    bankSummary(
      input: {
        isConnectedBank: $isConnectedBank
        isConnectedAccount: $isConnectedAccount
        accountType: $accountType
        isImporting: $isImporting
        isExternal: $isExternal
        unitApplicationIds: $unitApplicationIds
      }
    ) {
      baselaneBankBalance
      bank {
        id
        isExternal
        plaidItemId
        unitAccount {
          id
          phoneNumber
          unitApplicationStatus
          unitApplicationId
          applicationFormId
        }
        bankAccounts {
          id
          accountNumber
        }
      }
    }
  }
`;
