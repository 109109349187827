export const processingBodyContainerStyles = {
  alignItems: 'center',
  h: '100%',
  justifyContent: 'center',
};

export const processingBodySpinnerStyles = {
  mt: '22px !important',
};

export const processingBodyTextStyles = {
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
  mt: '4px !important',
  textAlign: 'center',
};

export const processingAlertBodyStyles = {
  m: '0 !important',
};

export const processingAlertContainerStyles = {
  w: '576px',
  h: '310px',
  pt: '32px',
};
