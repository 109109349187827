import React from 'react';
import {
  useRecipient,
  useIsSameDayPayment,
} from '@core/pages/Transfers/components/SendFunds/sendFundsStore';
import BaselaneFlowComplete from '../../../BaselaneFlowComplete';
import TransferReferralSuccessCard from '../../../TransferReferralSuccessCard';

type SuccessProps = {
  transferType: String,
};

const Success = ({ transferType }: SuccessProps) => {
  const recipient = useRecipient();
  const isSameDayPayment = useIsSameDayPayment();
  const toMessage = recipient ? `${recipient?.name}` : `external account`;
  const getDynamicSuccessCaption = () => {
    switch (transferType) {
      case 'INTERNAL':
        return {
          title: 'Completed internal transfer',
          description1: 'The funds have been transferred between your Baselane Banking accounts.',
          description2: null,
        };

      // Send Funds: ACH
      case 'TRANSFER_OUT':
        return {
          title: isSameDayPayment ? `Initiated ACH payment to` : 'Scheduled ACH payment to',
          title2: toMessage,
          description1: isSameDayPayment
            ? 'The money should arrive in 3-5 business days.'
            : 'The money should arrive in 3-5 business days from the payment date. ',
          description2: 'You can track the status on the Transfers & Payments page.',
        };

      // Add Funds: Bank Transfer
      case 'TRANSFER_IN':
        return {
          title: 'Initiated transfer from external account',
          description1: 'The money should arrive in 3-5 business days.',
          description2: 'You can track the status on the Transfers & Payments page.',
        };

      // Send Funds: Wire
      case 'WIRE_TRANSFER':
        return {
          title: isSameDayPayment ? `Initiated wire payment to` : 'Scheduled wire payment to',
          title2: toMessage,
          description1: isSameDayPayment
            ? 'The money should arrive in 1 business day.'
            : 'The money should arrive in 1 business day from the payment date.',
          description2: 'You can track the status on the Transfers & Payments page.',
        };

      // Send Funds: Mail a check
      case 'CHECK_PAYMENT':
        return {
          title: isSameDayPayment ? `Initiated check payment to` : 'Scheduled check payment to',
          title2: toMessage,
          description1: isSameDayPayment
            ? 'The check should arrive in 5-8 business days.'
            : 'The check should arrive in 5-8 business days from the payment date.',
          description2:
            'We will email you with further updates. You can also track the status on the Transfers & payments page. ',
        };

      default:
        return null;
    }
  };

  const getSource = () => {
    switch (transferType) {
      // Send Funds: ACH
      case 'TRANSFER_OUT':
        return 'send_funds';

      // Add Funds: Bank Transfer
      case 'TRANSFER_IN':
        return 'add_funds';

      // Send Funds: Wire
      case 'WIRE_TRANSFER':
        return 'send_funds';

      // Send Funds: Mail a check
      case 'CHECK_PAYMENT':
        return 'send_funds';

      default:
        return null;
    }
  };

  const { title, title2, description1, description2 } = getDynamicSuccessCaption();

  return (
    <BaselaneFlowComplete
      type="success"
      title={title}
      title2={title2}
      description1={description1}
      description2={description2}
    >
      <TransferReferralSuccessCard source={getSource()} mt={2} />
    </BaselaneFlowComplete>
  );
};

export default Success;
