import React from 'react';
import getBreakPoints from '@core/utils/getBreakPoints';
import BaselaneCarousel from '@shared/components/BaselaneCarousel';
import ArrowToggleButtons from '@shared/components/BaselaneCarousel/components/ArrowToggleButtons';
import InfoCard from './InfoCard';

const InfoCardCarousel = ({ infoCards }: any) => {
  const { isMin899 } = getBreakPoints();

  const customStyles = {
    customCarouselWrapperStyles: { p: '0', pb: { base: '40px', xl: '0' }, w: '100%' },
    customSlideContainerStyles: { m: '0', gap: '8px', width: 'calc(100% - 16px)' },
    customArrowButtonContainerStyles: {
      justifyContent: { base: 'center', lg: 'space-between' },
      pt: '16px',
    },
  };

  // removed 'isDarkMode' just fyi
  return (
    <BaselaneCarousel
      showToggleBesideIndicator
      slides={infoCards}
      SlideElement={InfoCard}
      ToggleButtonElements={isMin899 && ArrowToggleButtons}
      styles={customStyles}
    />
  );
};

export default InfoCardCarousel;
