// @flow
import React from 'react';
import { TabPanels, TabPanel } from '@chakra-ui/react';
import Details from './Details';
import Financials from './Financials/index';
import Documents from './Documents';

type DrawerPanelProps = {
  isDirty: boolean,
  setIsDirty: Function,
  setIsValid: Function,
  setIsError: Function,
  onClose: Function,
  setDetailsComplete: Function,
  detailsComplete: boolean,
  formRef: any,
  property: Object,
  setShowZillow: Function,
  setShowMortgage: Function,
  setFormVariables: Function,
  setHideFooter: Function,
  initialValues: Object,
  variables: Object,
  shortForm?: Boolean,
};

function DrawerPanel({
  isDirty,
  setIsDirty,
  setIsValid,
  setIsError,
  onClose,
  setDetailsComplete,
  detailsComplete,
  formRef,
  property,
  setShowZillow,
  setShowMortgage,
  setFormVariables,
  setHideFooter,
  initialValues,
  variables,
  shortForm,
}: DrawerPanelProps): any {
  const { marketPrice, mortgageBalance } = variables;

  return (
    <TabPanels h="calc(100% - 31px)">
      <TabPanel h="100%" p="0">
        <Details
          {...{
            isDirty,
            setIsDirty,
            setIsValid,
            setIsError,
            onClose,
            setDetailsComplete,
            detailsComplete,
            formRef,
            property,
            setFormVariables,
            initialValues,
            shortForm,
          }}
        />
      </TabPanel>
      <TabPanel h="100%" p="0">
        <Financials
          {...{
            isDirty,
            property,
            onClose,
            setDetailsComplete,
            detailsComplete,
            setShowZillow,
            setShowMortgage,
            setFormVariables,
            setIsDirty,
            setHideFooter,
            initialValues,
            marketPrice,
            mortgageBalance,
          }}
        />
      </TabPanel>
      <TabPanel h="100%" p="0 0 24px 24px">
        <Documents />
      </TabPanel>
    </TabPanels>
  );
}

DrawerPanel.defaultProps = {
  shortForm: false,
};

export default DrawerPanel;
