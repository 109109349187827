export const getEditLeaseFormArray = (variables) => {
  let editLeaseFormArray = [
    'review_button',
    'cancel_button',
    'startDate',
    'endDate',
    'isMonthToMonth',
    'monthlyRentAmount',
    'rentCollectionStartDate',
    'hasLateFee',
    'numberOfDaysForLateFee',
    'lateFeeAmount',
  ];

  const monthlyFees = [];
  let i = 0;
  variables.payments?.forEach((fee) => {
    if (fee.frequency === 'MONTHLY' && !fee.delete) {
      monthlyFees.push(`delete_fee_button_${i}`);
      i += 1;
    }
  });
  const monthlyFeesArray = monthlyFees.length > 0 ? [...monthlyFees] : ['recurringMonthlyFees'];

  editLeaseFormArray = editLeaseFormArray.concat(monthlyFeesArray);

  const newMonthlyFeeArray = [
    'description',
    'amount',
    'save_fee_button',
    'cancel_fee_button',
    'add_a_fee_button',
  ];

  editLeaseFormArray = editLeaseFormArray.concat(newMonthlyFeeArray);
  return editLeaseFormArray;
};
