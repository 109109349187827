export const calculateTotalAmount = (transactions) => {
  const amount = transactions.reduce((acc, obj) => {
    return obj.pending ? acc : acc + obj.amount;
  }, 0);
  return amount;
};

export const selectionTextHelper = (total, transactions) => {
  const hiddenCount = transactions.filter((t) => t.hidden)?.length;
  const pendingTransactions = transactions.filter((t) => t.pending)?.length || 0;
  const updatedTotal = total - pendingTransactions;

  if (hiddenCount >= 1) {
    return updatedTotal > 1
      ? `${updatedTotal} Transactions Selected (${hiddenCount} Hidden)`
      : `${updatedTotal} Transaction Selected (${hiddenCount} Hidden)`;
  }
  return updatedTotal > 1
    ? `${updatedTotal} Transactions Selected`
    : `${updatedTotal} Transaction Selected`;
};

export const actionHelper = (transactions) => {
  // if any of the selected transactions is visible then action is 'HIDE'
  const includesVisibleTransactions = transactions.filter((t) => !t.hidden);
  return includesVisibleTransactions.length > 0 ? 'HIDE' : 'UNHIDE';
};
