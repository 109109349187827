import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import type { FlowPanelProps } from '@shared/components/BaselaneFlow/types/FlowTabPanelProps';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

type TabRoutesProps = {
  tabIndex: number,
  bookkeepingAnalyticsMenuOptions: Array<FlowPanelProps>,
  handleNavigation: Function,
  addingPropertyPanel: boolean,
  setAddingPropertyPanel: Function,
  properties: Array,
  refreshProperties: Function,
  bankProps: Object,
  banks: Array,
  accounts: Array,
  hasAccounts: boolean,
};

const TabRoutes = ({
  tabIndex,
  bookkeepingAnalyticsMenuOptions,
  handleNavigation,
  addingPropertyPanel,
  setAddingPropertyPanel,
  properties,
  refreshProperties,
  bankProps,
  banks,
  accounts,
  hasAccounts,
}: TabRoutesProps): any => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAddedProperty = () => {
    refreshProperties();
    setAddingPropertyPanel(false);
  };

  const handleLinkAccountsSuccess = (res) => {
    // to be used in auto-tagging page
    const formattedDataToSubmit = res?.data?.linkBankWithUser?.bankAccounts;
    const banksToAutoTagData = sessionStorage.getItem('banksToAutoTag');
    const banksToAutoTagList = banksToAutoTagData ? JSON.parse(banksToAutoTagData) : [];
    const newBanksToAutoTag = banksToAutoTagList.concat(formattedDataToSubmit);
    sessionStorage.setItem('banksToAutoTag', JSON.stringify(newBanksToAutoTag));

    if (!banks || banks?.length === 0) {
      // only trigger on first bank account
      sendSegmentEvent('any_bank_added_fe', {
        title: document.title,
      });
      // reddit tracking
      window?.rdt('track', 'AddToCart');
    }

    bankProps?.refetchBankAccounts().then(() => {
      setIsLoading(false);
    });
  };

  const footerVars = {
    handleNavigation,
    bookkeepingAnalyticsMenuOptions,
    tabIndex,
    addingPropertyPanel,
    setAddingPropertyPanel,
    bankProps,
    hasAccounts,
    accounts,
    handleLinkAccountsSuccess,
    isLoading,
    setIsLoading,
  };

  return (
    <Outlet
      context={{
        addingPropertyPanel,
        properties,
        footerVars,
        setAddingPropertyPanel,
        handleNavigation,
        handleAddedProperty,
        hasAccounts,
        isLoading,
        banks,
        handleLinkAccountsSuccess,
        setIsLoading,
        accounts,
      }}
    />
  );
};

export default TabRoutes;
