import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_BANK_ENTITY_BY_ID } from '../queries';

const useEntityByIdQuery = (fetchPolicy = 'cache-first') => {
  const { bankaccount } = useParams();
  const accountId = bankaccount.split('_')[1];
  const { data, error, refetch, loading } = useQuery(GET_BANK_ENTITY_BY_ID, {
    variables: {
      accountId: [Number(accountId)],
      isConnectedAccount: true,
      accountStatus: 'Open',
      connectionState: 'CONNECTED',
    },
    fetchPolicy,
  });

  return {
    entityData: data?.bank ? data.bank : null,
    entityError: error,
    entityLoading: loading,
    refetchEntity: refetch,
  };
};

export default useEntityByIdQuery;
