import React, { useRef } from 'react';
import { HStack, Stack, Box, Text } from '@chakra-ui/react';
import { BaselaneAlert, BaselaneButton, BaselaneLink } from '@shared/components';
import IconCheckCircleOutline from '@icons/legacy/IconCheckCircleOutline';

import {
  badgeIconBgStyles,
  badgeIconContainerStyles,
  titleTextStyles,
  textStyles,
  containerStyles,
  bodyStyles,
  closeButtonStyles,
} from './styles/emailSuccessStyles.styles';

function EmailSentSuccess({
  onOKClick,
  isAlertOpen,
  setIsAlertOpen,
}: {
  onOKClick: Function,
  isAlertOpen: boolean,
  setIsAlertOpen: Function,
}) {
  const onAlertClose = () => setIsAlertOpen(false);
  const cancelAlert = useRef();

  const onhandleOKClick = () => {
    onAlertClose();
    onOKClick();
  };

  const alertTitle = (
    <HStack>
      <Stack {...badgeIconBgStyles}>
        <Box {...badgeIconContainerStyles}>
          <IconCheckCircleOutline />
        </Box>
      </Stack>
      <Text {...titleTextStyles}>Password Reset Instructions Sent</Text>
    </HStack>
  );

  const alertMessage = (
    <Text {...textStyles}>
      Instructions to reset your password have been sent to your email. If you don’t recieve it,
      please contact us at{' '}
      <BaselaneLink href="mailto:support@baselane.com" variant="primary" size="md">
        support@baselane.com
      </BaselaneLink>
    </Text>
  );

  const alertFooter = (
    <BaselaneButton variant="outline" palette="neutral" size="md" onClick={onhandleOKClick}>
      Close
    </BaselaneButton>
  );

  return (
    <BaselaneAlert
      showCloseButton
      isOpen={isAlertOpen}
      onClose={onAlertClose}
      leastDestructiveRef={cancelAlert}
      isCentered
      header={alertTitle}
      body={alertMessage}
      footer={alertFooter}
      size="xl"
      containerStyles={containerStyles}
      bodyStyles={bodyStyles}
      closeButtonStyles={closeButtonStyles}
    />
  );
}

export default EmailSentSuccess;
