import { isMobile } from 'react-device-detect';

export const toggleWrapperStyles = ({ showTopBorder, showBottomBorder, isNested }) => {
  let styles = { py: '8px', px: isMobile && !isNested ? '24px' : 'initial' };

  if (showBottomBorder) {
    styles = {
      ...styles,
      borderBottom: '1px',
      borderBottomColor: 'brand.darkBlue.150',
    };
  }

  if (showTopBorder) {
    styles = {
      ...styles,
      borderTop: '1px',
      borderTopColor: 'brand.darkBlue.150',
    };
  }

  return styles;
};

export const switchContainerStyles = {
  alignItems: 'center',
  w: '100%',
  justifyContent: 'space-between',
};

export const toggleContentContainerStyles = {
  mt: '24px',
  mb: isMobile ? '0px' : '24px',
  spacing: 0,
};

export const textLabelStyles = {
  fontWeight: 'medium',
  fontSize: 'sm',
  lineHeight: '24px',
  color: 'brand.neutral.700',
  my: isMobile ? '0' : '8px',
};
