import React, { useState } from 'react';
import { Box, Flex, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { IconChevronLeft, IconGoEdit } from '@icons';

import { Icon16Delete } from '@icons/16px';
import { BaselaneButton, BaselaneMobileHeader } from '@shared/components';
import AddEditProperty from './AddEditProperty';
import Property from './Property';
import {
  menuButtonIconStyles,
  menuButtonStyles,
  menuListStyles,
  menuItemStyles,
} from './styles/responsive.style';

function PropertyDetails({
  property: initialProperty,
  deselectProperty,
}: {
  property: Array<Object>,
  deselectProperty: Function,
}) {
  const [property, setProperty] = useState(initialProperty);

  const propertyHeaderMenu = (
    <Menu autoSelect={false} placement="bottom-end">
      <MenuButton
        className="icon-button"
        leftIcon={<Box {...menuButtonIconStyles}>...</Box>}
        as={BaselaneButton}
        aria-label="Property Details Menu List"
        size="md"
        onClick={(e) => e.stopPropagation()}
        variant="primary"
        {...menuButtonStyles}
      >
        Menu
      </MenuButton>
      <MenuList {...menuListStyles}>
        <AddEditProperty
          buttonStyles={menuItemStyles}
          variant="transparent"
          palette="neutral"
          buttonLabel={
            <Flex direction="row" justifyContent="space-between" width="100%">
              <Box textStyle="sm">Edit</Box>
              <Box>
                <IconGoEdit />
              </Box>
            </Flex>
          }
          property={property}
          setProperty={setProperty}
        />
        <AddEditProperty
          buttonStyles={menuItemStyles}
          variant="transparent"
          palette="neutral"
          buttonLabel={
            <Flex direction="row" justifyContent="space-between" width="100%">
              <Box textStyle="sm">Delete</Box>
              <Box>
                <Icon16Delete />
              </Box>
            </Flex>
          }
          isDelete
          property={property}
          setProperty={setProperty}
          deselectProperty={deselectProperty}
        />
      </MenuList>
    </Menu>
  );

  return (
    <>
      <BaselaneMobileHeader
        title={property?.name}
        linkIcon={<IconChevronLeft w="16" h="16" />}
        actionLink={deselectProperty}
      >
        {propertyHeaderMenu}
      </BaselaneMobileHeader>
      <Property {...{ property, setProperty }} />
    </>
  );
}

export default PropertyDetails;
