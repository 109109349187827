const getBadgeButtonBackgroundHeight = (badgeSize) => {
  switch (badgeSize) {
    case 'lg':
      return { h: '100%' };
    case 'md':
      return { h: '32px' };
    default:
      return { h: '100%' };
  }
};

const getBadgeButtonFontSize = (badgeSize) => {
  switch (badgeSize) {
    case 'lg':
      return { fontSize: 'sm', p: '10px 16px' };
    case 'md':
      return { fontSize: 'xs', p: '6px 8px 6px 12px', display: 'flex', alignItems: 'center' };
    default:
      return { fontSize: 'sm', p: '10px 16px' };
  }
};

export const badgeTagStyles = {
  backgroundStyles: {
    boxSizing: 'border-box',
    borderRadius: '4px',
    p: '0',
    h: 'auto',
  },
  fontStyles: {
    textTransform: 'capitalize',
    fontSize: 'xs',
    lineHeight: '20px',
    p: '2px 8px',
  },
};

export const badgeButtonStyles = (isNarrow = false, badgeSize = 'lg') => {
  return {
    backgroundStyles: {
      whiteSpace: 'nowrap',
      display: 'inline-block',
      p: '0',
      order: isNarrow ? '1' : { base: '1', md: '2' },
      ...getBadgeButtonBackgroundHeight(badgeSize),
    },
    fontStyles: {
      textTransform: 'capitalize',
      fontWeight: 'normal',
      lineHeight: '20px',
      ...getBadgeButtonFontSize(badgeSize),
    },
  };
};

export const badgeButtonIconStyles = {
  display: 'inline-block',
  ml: '8px',
};

export const providerImageStyles = (isNarrow = false) => {
  return {
    order: isNarrow ? '2' : { base: '2', md: '1' },
    mr: isNarrow ? '0' : { base: '0', md: '8px !important' },
  };
};
