import React from 'react';
import { useGate } from 'statsig-react';
import { Stack } from '@chakra-ui/react';

import { BaselaneButtonGroup } from '@shared/components';
import MoveMoneyMenu from '@shared/components/MoveMoneyMenu';
import getBreakPoints from '@core/utils/getBreakPoints';
import VirtualCardTriggerButton from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/AddVirtualCard/TriggerButton';
import AddAccountTrigger from '@pages/BankEntity/AddAccount/AddAccountTrigger';

import MoreMenu from '@pages/BankEntity/MoreMenu';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';

const DesktopPageHeader = () => {
  const { value: isTransfersPaymentsPageFeatureFlagged } = useGate('transfers_payments_page');

  const { isMin1150 } = getBreakPoints();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing="24px"
      flexWrap={!isMin1150 ? 'wrap' : 'nowrap'}
      p="24px 32px 24px 32px"
    >
      <HeaderLeft />
      {isTransfersPaymentsPageFeatureFlagged ? (
        <BaselaneButtonGroup>
          <MoveMoneyMenu />
          <AddAccountTrigger size="md" />
          <VirtualCardTriggerButton />
          <MoreMenu />
        </BaselaneButtonGroup>
      ) : (
        <HeaderRight />
      )}
    </Stack>
  );
};

export default DesktopPageHeader;
