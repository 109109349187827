import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const percentageMaskConfig = {
  prefix: '',
  suffix: '%',
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 3, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const createPercentageMask = (props = {}) => {
  const config = {
    ...percentageMaskConfig,
    ...props,
  };
  return createNumberMask(config);
};

export default createPercentageMask;
