import { truncatedText } from '@core/components/Shared/styles/text.style';

export const drawerBodyStyles = {
  background: 'brand.darkBlue.50',
  p: '32px',
};

export const textAreaStyles = {
  h: '64px',
  minH: '64px',
  resize: 'none',
  color: 'brand.neutral.600',
  p: '18px 16px',
  fontSize: 'sm',
  lineHeight: '24px',
  borderRadius: '4px',
  borderColor: 'brand.darkBlue.250',
  focusBorderColor: 'brand.darkBlue.300',
  background: 'brand.neutral.white',
  _hover: { borderColor: 'brand.darkBlue.300' },
  _placeholder: {
    color: 'brand.neutral.600',
  },
};

export const buttonContainerStyles = ({ isMinXL }) => {
  return { justifyContent: 'flex-start', my: isMinXL ? '24px' : '32px', gap: '8px' };
};

export const formLabelStyles = {
  fontSize: 'sm',
  lineHeight: '24px',
  fontWeight: 'semibold',
  color: 'brand.neutral.700',
};

export const transactionDetailsDrawerStyles = {
  containerStyle: {
    textStyle: 'xs',
    gap: '4px',
  },
  sharedStyle: {
    m: '0 !important',
    ...truncatedText,
  },
  nameStyle: {
    textStyle: 'headline-md',
    ...truncatedText,
  },
  amountStyle: {
    textStyle: 'md',
  },
  accountStyle: {
    container: {
      alignItems: 'flex-start',
    },
    iconcontainer: {
      display: 'flex',
      h: '20px',
      alignItems: 'center',
    },
    line2: {
      fontSize: '3xs',
      lineHeight: '14px',
      color: 'brand.neutral.600',
      textTransform: 'capitalize',
    },
  },
  bannerStyle: ({ isMinXL }) => {
    return {
      container: {
        m: isMinXL ? '24px 0' : '32px 0',
        w: '100%',
      },
      innercontainer: {
        py: '18px',
      },
      iconcontainer: {
        display: isMinXL && 'none',
        ml: '8px',
        mr: '8px!important',
      },
      title: {
        fontWeight: 'semibold',
        fontSize: isMinXL ? 'xs' : 'md',
        lineHeight: isMinXL ? '20px' : '24px',
        flex: isMinXL ? '1 1 auto' : '1 0 auto',
      },
    };
  },
  migrationBannerStyle: ({ isMinXL }) => {
    return {
      container: {
        m: '24px 0 0!important',
        w: '100%',
      },
      innercontainer: {
        py: '8px',
      },
      iconcontainer: {
        display: isMinXL && 'none',
        ml: '8px',
        mr: '8px!important',
      },
      title: {
        fontSize: isMinXL ? 'xs' : 'sm',
        lineHeight: isMinXL ? '20px' : '20px',
        flex: isMinXL ? '1 1 auto' : '1 0 auto',
      },
    };
  },
  fileUploadStyle: ({ isMinXL }) => {
    return {
      container: {
        my: isMinXL ? '24px' : '32px',
      },
      documents: {
        container: {
          mt: '16px !important',
        },
        title: {
          textStyle: 'xs',
        },
      },
    };
  },
  docDeleteAlert: ({ isMinXL }) => {
    return {
      container: {
        w: isMinXL && '100%',
      },
      button: {
        w: isMinXL && '50%',
      },
    };
  },
};
