// @flow
import React from 'react';
import { Portal, Box } from '@chakra-ui/react';
import { containerStyles } from './styles/footer.styles';

function BaselaneFooter({
  children,
  styles,
  local = false,
}: {
  children: Object,
  styles?: Object,
  local: Boolean,
}): any {
  const mergedStyles = { ...containerStyles(local), styles };

  return local ? (
    <Box {...mergedStyles}>{children}</Box>
  ) : (
    <Portal>
      <Box {...mergedStyles}>{children}</Box>
    </Portal>
  );
}
BaselaneFooter.defaultProps = {
  styles: ({}: { ... }),
};

export default BaselaneFooter;
