import React from 'react';
import { ONBOARDING_CARDS } from '@core/components/OnboardingTriage/helpers/onboardingTriageHelper';
import { onboardingCardWrapperStyles } from '@core/components/OnboardingTriage/styles/onboardingTriage.styles';
import CardOption from './CardOption';

interface OnboardingOptionsProps {
  handleActionButton: Function;
}

const OnboardingOptions = ({ handleActionButton }: OnboardingOptionsProps) => {
  const currentOnboardingCards = [...ONBOARDING_CARDS];

  return currentOnboardingCards.map((card) => {
    return (
      <CardOption
        key={card.id}
        card={card}
        onClick={(e) => handleActionButton(e, card)}
        styles={onboardingCardWrapperStyles()}
      />
    );
  });
};

export default OnboardingOptions;
