import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import userContext from '@contexts/UserContext';
import { UNIFIED_BOOKKEEPING_ANALYTICS, UNIFIED_BA_ACCOUNTS, UNIFIED_BA_SUCCESS } from '@routes';

import BookkeepingAnalyticsFormTabs from './BookkeepingAnalyticsFormTabs';
import { initialMenuState } from './initialMenuState';
import HeaderContent from '../HeaderContent';

type UnifiedBookkeepingAnalyticsFlowProps = {
  properties: Array,
  bankProps: Object,
  refreshProperties: Function,
};

function UnifiedBookkeepingAnalyticsFlow({
  properties,
  bankProps,
  refreshProperties,
}: UnifiedBookkeepingAnalyticsFlowProps): any {
  const currLocation = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(userContext);

  // banks and accounts
  const banks = [];
  const accounts = [];
  bankProps?.allBanksData
    ?.filter((bank) => bank.isConnected && bank.isExternal)
    .map((bank) =>
      bank?.bankAccounts?.map((account) => {
        banks.push(bank);
        accounts.push({
          ...account,
          logo: bank.logo,
          plaidInstitutionName: bank.plaidInstitutionName,
        });
        return null;
      })
    );

  const hasAccounts = accounts?.length > 0;

  // properties
  const [addingPropertyPanel, setAddingPropertyPanel] = useState(false);

  // menu
  const [bookkeepingAnalyticsMenuOptions] = useState(initialMenuState(accounts?.length > 0));
  const [tabIndex, setTabIndex] = useState(null);

  // routes useEffect
  useEffect(() => {
    // useEffect effecting routing changes into tabIndex
    if (currLocation.pathname === '/unified_bookkeeping_analytics') {
      setTabIndex(0);
    }
    if (currLocation.pathname.includes('add_accounts')) {
      setTabIndex(1);
    }
  }, [currLocation.pathname]);

  const handleNavigation = (isPreviousOnClick) => {
    if (isPreviousOnClick && tabIndex === 0) {
      setAddingPropertyPanel(false);
    } else if (isPreviousOnClick && tabIndex === 1) {
      navigate(UNIFIED_BOOKKEEPING_ANALYTICS);
    } else if (tabIndex === 0 && properties.length > 0) {
      // if on add property and there are properties, go to add accounts
      navigate(UNIFIED_BA_ACCOUNTS);
    } else if (tabIndex === 1 && accounts.length > 0) {
      // continue to success route
      navigate(UNIFIED_BA_SUCCESS);
    }
  };

  return (
    <HeaderNavWrapper
      onClickBack={() => handleNavigation(true)}
      hideBackButton
      handleFinishEvent={handleNavigation}
      headerContent={<HeaderContent onboardingCompleted={user?.onboardingCompleted} />}
      // TODO: to be removed once flow is refactored to not use device detect
      isMobile={isMobile}
    >
      <BookkeepingAnalyticsFormTabs
        {...{
          bookkeepingAnalyticsMenuOptions,
          bankProps,
          properties,
          refreshProperties,
          tabIndex,
          handleNavigation,
          addingPropertyPanel,
          setAddingPropertyPanel,
          hasAccounts,
          banks,
          accounts,
        }}
      />
    </HeaderNavWrapper>
  );
}

export default UnifiedBookkeepingAnalyticsFlow;
