import React from 'react';
import { IconCheckCircleOutline, IconDollarCircleOutline, IconTimer } from '../../../Icons';

const LENDENCY_QUOTE_ITEMS = [
  {
    id: '1',
    icon: <IconTimer w="25" h="24" viewBox="0 -1 15 16" color="#257ED0" />,
    text: 'Get an instant quote in 1 minute',
  },
  {
    id: '2',
    icon: <IconCheckCircleOutline w="25" h="24" viewBox="-2 -2 25 24" color="#257ED0" />,
    text: 'No income verification',
  },
  {
    id: '3',
    icon: <IconDollarCircleOutline color="#257ED0" />,
    text: 'Receive funding within 10 days',
  },
];

const LENDENCY_ADVANTAGES_PART1 = [
  {
    id: '1',
    text: 'Loan Size $100K - $3M',
  },
  {
    id: '2',
    text: '10-30-year term fixed rates',
  },
  {
    id: '3',
    text: '5/1 + 7/1 ARMS',
  },
  {
    id: '4',
    text: 'Up to 80% LTV (loan-to-value)',
  },
  {
    id: '5',
    text: 'No prepayment penalties',
  },
  {
    id: '6',
    text: 'Competitive Rates',
  },
  {
    id: '7',
    text: 'Award Winning Service',
  },
];
const LENDENCY_ADVANTAGES_PART2 = [
  {
    id: '1',
    text: 'Loan Size $100K - $3M',
  },
  {
    id: '2',
    text: '30 year term + fixed rates',
  },
  {
    id: '3',
    text: 'Up to 80% LTV (loan-to-value)',
  },
  {
    id: '4',
    text: 'Interest Only Options Available',
  },
  {
    id: '5',
    text: 'Competitive Rates',
  },
  {
    id: '6',
    text: 'Award Winning Service',
  },
];

export { LENDENCY_QUOTE_ITEMS, LENDENCY_ADVANTAGES_PART1, LENDENCY_ADVANTAGES_PART2 };
