import customTheme from '@core/theme';

export const titleStyles = (isMinXL) => ({
  fontSize: isMinXL ? 'lg' : '2xl',
  lineHeight: isMinXL ? '26px' : '32px',
  fontWeight: 'semibold',
});

export const faqTextStyles = {
  textStyle: 'headline-lg',
};

export const bodyTextStyles = (isMinXL) => ({
  textStyle: isMinXL ? 'xs' : 'sm',
  color: customTheme.colors.brand.neutral['600'],
});

export const accordionQuestionTextStyles = {
  textStyle: 'headline-sm',
};

export const accordionContentTextStyles = {
  textStyle: 'xs',
  color: customTheme.colors.brand.neutral['600'],
};
