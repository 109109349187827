import React from 'react';
import { Stack, Spacer, Box, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { Icon16Delete } from '@icons/16px';
import {
  BaselaneButtonIcon,
  BaselaneAccordion,
  BaselaneAccordionWrapper,
  CurrencyText,
} from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import {
  feeBoxHeaderStyles,
  feeBoxContainerStyles,
  feeBoxStyles,
  feeBoxTitleStyles,
  feeBoxValueStyles,
} from '../../styles/depositsAndFees.styles';

type DisplayLateFeeProps = {
  lateFee: Object,
  handleFeeDelete: Function,
  unifiedRC: boolean,
};

function DisplayLateFee({ lateFee, handleFeeDelete, unifiedRC }: DisplayLateFeeProps) {
  const containerStyles = {
    m: isMobile ? '0' : '16px 0',
    border: '1px solid #9FD8FF',
    borderRadius: '4px',
    bg: 'brand.blue.100',
  };

  const headerEl = (fee) => {
    return (
      <Stack direction="row" {...feeBoxHeaderStyles(unifiedRC)} w="100%">
        <Text {...feeBoxValueStyles} ml="0 !important">
          LATE FEE
        </Text>
        <Spacer />
        <Box pt={isMobile ? '0' : '16px'}>
          <BaselaneButtonIcon
            variant="transparent"
            icon={<Icon16Delete />}
            id="delete_fee_button"
            palette="neutral"
            onClick={() => handleFeeDelete()}
          />
        </Box>
      </Stack>
    );
  };
  const contentEl = (amount, dueDays) => (
    <Stack direction={!isMobile ? 'row' : 'column'} align={isMobile ? 'flex-start' : 'center'}>
      {/* Fee Amount */}
      <Stack direction="row" ml="0!important">
        <Text lineHeight="24px">
          <CurrencyText
            textStyle="headline-sm"
            amount={stripCurrency(amount)}
            styles={{
              as: 'span',
              verticalAlign: 'baseline',
            }}
          />
          {/* Fee Due By */}
          <Text
            as="span"
            {...{
              ...feeBoxTitleStyles,
              ml: 1,
              verticalAlign: 'baseline',
            }}
          >
            if overdue by {dueDays} days
          </Text>
        </Text>
      </Stack>
    </Stack>
  );

  return (
    <Box {...feeBoxContainerStyles} w="100%" mb="16px">
      {unifiedRC && isMobile ? (
        <BaselaneAccordionWrapper allowToggle styles={{ my: '0' }}>
          <BaselaneAccordion
            containerStyles={containerStyles}
            contentStyles={{
              p: '24px 0 24px 54px',
            }}
            buttonStyles={{ bg: 'brand.blue.100' }}
            headerElements={headerEl(lateFee)}
            contentElements={contentEl(lateFee.amount, lateFee.dueDays)}
          />
        </BaselaneAccordionWrapper>
      ) : (
        <Box {...feeBoxStyles}>
          {headerEl(lateFee)}
          {contentEl(lateFee.amount, lateFee.dueDays)}
        </Box>
      )}
    </Box>
  );
}

export default DisplayLateFee;
