import React from 'react';
import { HStack } from '@chakra-ui/react';
import BaselaneChip from '../BaselaneChip';

type BaselaneChipItem = {
  id: String,
  label: String,
  isSelected: boolean,
  onClick: Function,
};

type BaselaneChipFilterMenuProps = {
  id: String,
  items: Array<BaselaneChipItem>,
  onClick: Function,
};

const BaselaneChipFilterMenu = ({ id, items, onClick }: BaselaneChipFilterMenuProps) => {
  return (
    <HStack id={id} overflowX="auto">
      {items.map((item, index) => (
        <BaselaneChip
          id={item.id}
          key={item.id}
          label={item.label}
          isSelectable
          isSelected={item.isSelected}
          onClick={() => onClick(index)}
        />
      ))}
    </HStack>
  );
};

export default BaselaneChipFilterMenu;
