export const getTotalBalance = (bankAccounts) => {
  return bankAccounts.reduce((totalSum, bankAccount) => {
    bankAccount.subAccounts.forEach((subAccount) => {
      // eslint-disable-next-line no-param-reassign
      totalSum += subAccount.availableBalance;
    });
    return totalSum + bankAccount.availableBalance;
  }, 0);
};

/* Flat Account List
 * Called by: getFlatAccounts func.
 * Returns: Array of Accounts */
export const getFlatAccounts = (banks) => {
  return (
    banks &&
    banks
      ?.map((bank) =>
        (bank.bankAccounts || []).reduce((acc, account) => {
          if (account.revokedByUser) {
            return acc;
          }

          acc.push({
            bankName: bank?.name || `Baselane ${bank.id}`,
            logo: bank.logo,
            isBankConnected: bank.isConnected,
            inTransitBalance: bank.inTransitBalance + bank.pendingReceivedPayments,
            isBankAccountConnected: account.connectionState !== 'ITEM_LOGIN_REQUIRED',
            totalBalance: getTotalBalance(bank.bankAccounts),
            plaidItemId: bank.plaidItemId,
            legalBusinessName: bank?.unitAccount?.legalBusinessName,
            legalAddress: bank?.unitAccount?.legalAddress,
            mailingAddress: bank?.unitAccount?.mailingAddress,
            phoneNumber: bank?.unitAccount?.phoneNumber,
            applicationType: bank?.unitAccount?.applicationType,
            bankId: bank.id,
            ...account,
            subAccounts: account.subAccounts.map((subAccount) => ({
              ...subAccount,
              legalAddress: bank?.unitAccount?.legalAddress,
              legalBusinessName: bank?.unitAccount?.legalBusinessName,
              mailingAddress: bank?.unitAccount?.mailingAddress,
            })),
          });

          return acc;
        }, [])
      )
      .flat()
  );
};
