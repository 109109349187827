import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import customTheme from '@core/theme';
import { DELETE_DRAFT, GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import { NATIVE_BANK, UNIFIED_LB_PERSONAL_INFO } from '@core/constants/routes';
import historyStateStorage from '@core/storage/historyStateStorage';
import { Icon16ArrowForward } from '@icons/16px';
import { IllustrationBankingNewaccount } from '@illustrations';
import BaselaneResponsiveBanner from './BaselaneResponsiveBanner';
import BaselaneResponsiveConfirmDeleteAlert from '../BaselaneResponsiveConfirmDeleteAlert';

type BaselaneResponsiveAccountDraftBannerProps = {
  /**
   * @prop {Object} - Unit application object that contains data used to populate banner
   */
  unitApplication: Object,
  /**
   * @prop {Function} - Function that reloads application banner data after a delete
   */
  refetchBankList: Function,
};

/**
 * Renders a responsive banner for accounts in the 'draft' state.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveAccountDraftBanner = ({
  unitApplication,
  refetchBankList,
  ...rest
}: BaselaneResponsiveAccountDraftBannerProps) => {
  const { id: bankId } = unitApplication;

  const ownership =
    unitApplication?.applicationType === 'SoleProprietorship' ? 'Sole Proprietor' : 'Business';

  const navigate = useNavigate();

  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    variables: { bankId },
    onCompleted: ({ unitOnBoarding: unitBankUrl }) => window.open(unitBankUrl, '_self'),
    onError: (error) => console.error(error),
  });

  const [deleteDraft] = useMutation(DELETE_DRAFT, {
    variables: { id: Number(bankId) },
    onCompleted: () => refetchBankList(),
    onError: (error) => console.error(error),
  });

  const handleContinueWithApplication = (unitAccount) => {
    if (unitAccount?.applicationFormId && unitAccount.applicationType === null) {
      getKYCUrl();
    } else {
      historyStateStorage.write({ from: NATIVE_BANK });
      navigate(UNIFIED_LB_PERSONAL_INFO, {
        search: `?applicationId=${bankId}`,
      });
    }
  };

  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose,
  } = useDisclosure();

  const handleConfirmDelete = () => {
    deleteDraft();
  };

  return (
    <>
      <BaselaneResponsiveBanner
        icon={<IllustrationBankingNewaccount />}
        indicatorColor={customTheme.colors.brand.blue['50']}
        title={`Complete your ${ownership} Banking application`}
        description="You’re almost there. Pick up from where you left off."
        onDismissClick={onConfirmDeleteOpen}
        dismissLabel="Delete"
        callToActionLabel="Continue Application"
        callToActionRightIcon={<Icon16ArrowForward />}
        onCallToActionClick={() => {
          handleContinueWithApplication(unitApplication);
        }}
        {...rest}
      />
      <BaselaneResponsiveConfirmDeleteAlert
        isOpen={isConfirmDeleteOpen}
        onClose={onConfirmDeleteClose}
        onConfirmDeleteClick={() => {
          handleConfirmDelete(unitApplication);
        }}
        deletePromptText="Are you sure you want to delete the application?"
        confirmButtonLabel="Yes, Delete Draft"
      />
    </>
  );
};

export default BaselaneResponsiveAccountDraftBanner;
