import React from 'react';

function IconWarningMobile() {
  return (
    <svg
      width="150"
      height="168"
      viewBox="0 0 150 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M106.361 48.6519L89.0505 100.289H143.972L126.662 48.6519C125.783 46.0298 125.343 44.7188 124.532 43.7489C123.811 42.8867 122.884 42.2198 121.837 41.8099C120.659 41.3489 119.277 41.3489 116.511 41.3489C113.746 41.3489 112.363 41.3489 111.186 41.8099C110.139 42.2198 109.212 42.8867 108.491 43.7489C107.679 44.7188 107.24 46.0298 106.361 48.6519Z"
          fill="#FF6700"
        />
        <path
          d="M79.0039 105.312C79.0039 101.428 82.1525 98.2797 86.0365 98.2797H142.967C146.851 98.2797 150 101.428 150 105.312C150 109.196 146.851 112.345 142.967 112.345H86.0365C82.1525 112.345 79.0039 109.196 79.0039 105.312Z"
          fill="#FF6700"
        />
      </g>
      <path
        d="M98.7763 50.0564H122.218L125.902 61.4426H95.0925L98.7763 50.0564Z"
        fill="white"
        stroke="#000619"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.4098 72.1582H129.587L133.422 83.5444H87.7261L91.4098 72.1582Z"
        fill="white"
        stroke="#000619"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119 31V20.2C119 13.4794 119 10.1191 117.692 7.55211C116.542 5.29417 114.706 3.4584 112.448 2.30792C109.881 1 106.521 1 99.8 1H50.2C43.4794 1 40.1191 1 37.5521 2.30792C35.2942 3.4584 33.4584 5.29417 32.3079 7.55211C31 10.1191 31 13.4794 31 20.2V147.8C31 154.521 31 157.881 32.3079 160.448C33.4584 162.706 35.2942 164.542 37.5521 165.692C40.1191 167 43.4794 167 50.2 167H99.8C106.521 167 109.881 167 112.448 165.692C114.706 164.542 116.542 162.706 117.692 160.448C119 157.881 119 154.521 119 147.8V121M32 141H118M83.037 96.9402L100.347 45.303C101.226 42.681 101.666 41.3699 102.477 40.4001C103.198 39.5378 104.125 38.871 105.172 38.4611C106.35 38 107.732 38 110.498 38C113.263 38 114.646 38 115.823 38.4611C116.87 38.871 117.797 39.5378 118.518 40.4001C119.33 41.3699 119.769 42.681 120.648 45.303L137.959 96.9402H83.037ZM82.0326 111.005H138.964C142.848 111.005 145.996 107.856 145.996 103.972C145.996 100.088 142.848 96.9398 138.964 96.9398H82.0326C78.1486 96.9398 75 100.088 75 103.972C75 107.856 78.1486 111.005 82.0326 111.005ZM82 154C82 157.866 78.866 161 75 161C71.134 161 68 157.866 68 154C68 150.134 71.134 147 75 147C78.866 147 82 150.134 82 154ZM60 1H93C93 2.86128 93 3.79192 92.7553 4.54508C92.2607 6.06727 91.0673 7.26069 89.5451 7.75528C88.7919 8 87.8613 8 86 8H67C65.1387 8 64.2081 8 63.4549 7.75528C61.9327 7.26069 60.7393 6.06727 60.2447 4.54508C60 3.79192 60 2.86128 60 1Z"
        stroke="#000619"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconWarningMobile;
