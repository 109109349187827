import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { BaselaneMessageCard } from '@shared/components';
import ArchiveLease from './ArchiveLease';
import { expiringSoonBannerStyles } from '../../styles/expiringSoonBanner.styles';

type ExpireSoonBannerProps = {
  handleArchiveLease: Function,
  isTablet: boolean,
};

function ExpiredBanner({ handleArchiveLease, isTablet }: ExpireSoonBannerProps) {
  // Destructure Imported Style
  const { container, button, messagecard } = expiringSoonBannerStyles({ isTablet }) ?? {};

  return (
    <Box {...container}>
      <BaselaneMessageCard
        iconName={isTablet ? 'info' : ''}
        iconColor="blue"
        borderColor="blue"
        title="All Payments Completed"
        text={
          isTablet ? (
            <Text>Archive to set up a new rent collection on this property.</Text>
          ) : (
            <Stack>
              <Text>Archive to set up a new rent collection on this property.</Text>
              <ArchiveLease
                {...{
                  handleArchiveLease,
                  showCustomButton: true,
                  customButtonProps: {
                    variant: 'solid',
                    palette: 'primary',
                    styles: button,
                    title: 'Archive',
                  },
                }}
              />
            </Stack>
          )
        }
        textContainerStyles={messagecard.textContainerStyles}
        containerStyles={messagecard.containerStyles}
        styles={messagecard.styles}
        rightElement={
          isTablet ? (
            <ArchiveLease
              {...{
                handleArchiveLease,
                showCustomButton: true,
                customButtonProps: {
                  variant: 'solid',
                  palette: 'primary',
                  styles: button,
                  title: 'Archive',
                },
              }}
            />
          ) : null
        }
        isVertical
      />
    </Box>
  );
}

export default ExpiredBanner;
