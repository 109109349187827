export const badgeIconBgStyles = {
  borderRadius: '50%',
  p: '6px',
  w: '32px',
  minW: '32px',
  h: '32px',
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'red.200',
};

export const badgeIconContainerStyles = {
  bg: 'transparent',
  w: '18px',
  h: '18px',
  color: 'red.800AA',
};

export const titleTextStyles = {
  ml: '16px !important',
};

export const alertPopupHeaderStyles = {
  mt: { xs: '16px', base: '0' },
};

export const alertPopupIconBgStyles = {
  bg: 'red.200',
};

export const alertPopupIconContainerStyles = {
  color: 'red.500A',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
