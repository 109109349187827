import { useQuery } from '@apollo/client';
import { GET_PROPERTIES_DROPDOWN } from '@core/apollo/queries';

const useCashFlowProperties = () => {
  const {
    loading: cashFlowPropertiesLoading,
    error: cashFlowPropertiesError,
    data: cashFlowPropertiesData,
    refetch: cashFlowPropertiesRefetch,
  } = useQuery(GET_PROPERTIES_DROPDOWN, {
    onError: (error) => console.error(error),
  });

  return {
    cashFlowPropertiesLoading,
    cashFlowPropertiesError,
    cashFlowPropertiesData,
    cashFlowPropertiesRefetch,
  };
};

export default useCashFlowProperties;
