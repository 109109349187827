import React from 'react';
import { useFormikContext } from 'formik';
import { BaselaneButton } from '@shared/components';
import { Icon16ChevronRight } from '@icons/16px';
import { steps } from '../../helpers/formHelpers';
import { sendSegmentEventsForSteps } from '../../helpers/segmentEvents';
import {
  useSetCurrentStep,
  useIsCurrentStepDisabled,
  useIsCurrentStepLoading,
  useSelectedPaymentMethod,
  useCurrentStep,
  useIsOneTimePayment,
} from '../../sendFundsStore';

type SelectRecipientFooterProps = {
  handleDrawerClose: Function,
};
const SelectRecipientFooter = ({ handleDrawerClose }: SelectRecipientFooterProps) => {
  const formik = useFormikContext();
  const { values, dirty, resetForm } = formik;
  const setCurrentStep = useSetCurrentStep();
  const isCurrentStepDisabled = useIsCurrentStepDisabled();
  const isCurrentStepLoading = useIsCurrentStepLoading();
  const currentStep = useCurrentStep();
  const selectedPaymentMethod = useSelectedPaymentMethod();
  const isOneTimePayment = useIsOneTimePayment();

  return (
    <>
      <BaselaneButton
        size="md"
        variant="outline"
        palette="neutral"
        onClick={() => handleDrawerClose({ dirty, resetForm })}
      >
        Cancel
      </BaselaneButton>
      <BaselaneButton
        size="md"
        variant="filled"
        palette="primary"
        width="100%"
        ml={1.5}
        flex={1}
        onClick={() => {
          setCurrentStep(steps.SELECT_PAYMENT_METHOD);
          sendSegmentEventsForSteps(values, currentStep, selectedPaymentMethod, isOneTimePayment);
        }}
        rightIcon={<Icon16ChevronRight />}
        isLoading={isCurrentStepLoading}
        isDisabled={!values?.payeeId || isCurrentStepDisabled}
      >
        Next
      </BaselaneButton>
    </>
  );
};
export default SelectRecipientFooter;
