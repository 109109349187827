import React from 'react';
import { Stack } from '@chakra-ui/react';

import { BaselaneButton } from '@shared/components';

type EditMultipleTransactionsDrawerFooterProps = {
  onCancel: Function,
  onSaveAndClose: Function,
};

const EditMultipleTransactionsDrawerFooter = ({
  onCancel,
  onSaveAndClose,
}: EditMultipleTransactionsDrawerFooterProps) => {
  return (
    <Stack direction="row">
      <BaselaneButton variant="outline" palette="neutral" size="md" onClick={onCancel}>
        Cancel
      </BaselaneButton>
      <BaselaneButton
        id="save-close-drawer-button"
        variant="filled"
        palette="primary"
        size="md"
        onClick={onSaveAndClose}
      >
        Save & Close
      </BaselaneButton>
    </Stack>
  );
};
export default EditMultipleTransactionsDrawerFooter;
