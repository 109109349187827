import React from 'react';

function IconVerifiedBadge({
  w = '16',
  h = '19',
  color = '#fff',
}: {
  w?: Number,
  h?: Number,
  color?: String,
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} fill="none">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11 7.5 7.818 11 6 9.182M8 18s7-3.4 7-8.5V3.55L8 1 1 3.55V9.5C1 14.6 8 18 8 18Z"
      />
    </svg>
  );
}

IconVerifiedBadge.defaultProps = {
  w: '16',
  h: '19',
  color: '#fff',
};

export default IconVerifiedBadge;
