import React from 'react';
import { useField, useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import { Input } from '@chakra-ui/react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const noDecimalMaskOptions = {
  allowDecimal: false,
};

type AmountInputProps = {
  allowdecimal: string,
  placeholder?: string,
};

function AmountInput({ ...props }: AmountInputProps) {
  const { allowdecimal, placeholder } = props;

  const currencyMask =
    allowdecimal === 'false' && allowdecimal !== undefined
      ? createNumberMask(Object.assign(defaultMaskOptions, noDecimalMaskOptions))
      : createNumberMask(defaultMaskOptions);

  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <Input
      as={MaskedInput}
      {...field}
      {...props}
      onChange={(e) => {
        // TODO: see if there is a better way
        const sanitizeInput = e.target.value.split('$').pop().split(',').join('');
        const inputConversion = Number(sanitizeInput);

        setFieldValue(field.name, inputConversion);
      }}
      mask={currencyMask}
      {...{ placeholder }}
    />
  );
}

AmountInput.defaultProps = {
  placeholder: '$',
};

export default AmountInput;
