export const fontStyle5 = {
  textStyle: 'xs',
  color: 'brand.neutral.500',
  mb: '8px',
};

export const errorFormFontStyles = {
  color: 'red.500A',
  fontSize: '2xs',
};

export const accordionText1 = {
  fontWeight: 'semibold',
};

export const accordionText2 = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
  textTransform: 'capitalize',
};

export const linkStyles = {
  color: 'brand.blue.700',
  m: '0px !important',
  _hover: {
    textDecoration: 'underline',
  },
};

export const normalFontWeightStyles = {
  fontWeight: 'normal',
};
