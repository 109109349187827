import React, { useRef, useState, useMemo } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { LINK_BANK_WITH_USER } from '@core/apollo/queries';
import {
  getPartitionedAccounts,
  sortWithString,
  getConnectedAccounts,
  getBaselaneConnectedAccounts,
} from '../../components/NativeBankingPage/MainContent/components/Accounts/helpers/accounts.helpers';
import { GET_BANK_SUMMARY, GET_KYC_URL } from '../../components/NativeBankingPage/queries';

const BanksContext = React.createContext({});

export const BanksProvider = ({ children }: any) => {
  const addExternalAccountDrawerMobileRef = useRef(null);
  const addExternalAccountSummaryDrawerRef = useRef(null);
  const linkedAccountsDrawerRef = useRef(null);

  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding }) => window.open(unitOnBoarding, '_self'),
    onError: (kycUrlError) => console.error(kycUrlError),
  });

  const [addAccountFlag, setAddAccountFlag] = useState(false);
  const [linkBankWithUser] = useMutation(LINK_BANK_WITH_USER);

  const { error, loading, data, refetch: refetchBankSummary } = useQuery(GET_BANK_SUMMARY, {
    fetchPolicy: 'no-cache',
  });

  // get bankSummary, banks, rest
  const { bankSummary } = data || {};
  const { bank: banks = [], ...rest } = bankSummary || {};

  // get accounts
  const [baselaneAccounts, externalAccounts, manualAccounts] = getPartitionedAccounts(banks);

  const sortedBaselaneBanks =
    (baselaneAccounts &&
      baselaneAccounts?.sort((x, y) => {
        return Number(x.createdAt) - Number(y.createdAt);
      })) ||
    [];
  // get  oldest bank created
  const [oldestBaselaneBank] = sortedBaselaneBanks;

  // oldest sole prop baselane bank
  const [SPBaselaneBankDraft] = sortedBaselaneBanks.filter(
    (spbb) =>
      spbb?.unitAccount?.applicationType === 'SoleProprietorship' &&
      spbb?.unitAccount?.unitApplicationStatus === 'DRAFT' &&
      spbb?.unitAccount?.isHidden !== true
  );

  const userHasDraftApplication = sortedBaselaneBanks.find(
    (bAccount) =>
      bAccount?.unitAccount?.unitApplicationStatus === 'DRAFT' &&
      bAccount?.unitAccount?.isHidden !== true
  );

  const baselaneConnectedAccounts = getBaselaneConnectedAccounts(baselaneAccounts);

  // Empty state checks
  // Need hasBaselaneAccounts as well for empty state title
  const hasNoBaselaneAccounts = baselaneAccounts.length === 0;

  const hasVisibleApplications =
    baselaneAccounts.filter((account) => {
      const { isHidden = false, unitApplicationStatus = null } = account?.unitAccount || {};
      return unitApplicationStatus !== 'COMPLETED' && !isHidden;
    })?.length > 0;

  // Used to gate Virtual Cards if no physical cards exist
  const hasActivatedAPhysicalCard = baselaneAccounts?.some((b) =>
    b.bankAccounts.some((ba) =>
      ba?.cards?.some((c) => c?.isPhysical && c?.cardStatus !== 'Inactive')
    )
  );

  // TODO: Need to sort it based on bank account name or another field other than plaidInstitutionName
  const sortedBanks =
    externalAccounts.length > 0 ? sortWithString(externalAccounts, 'plaidInstitutionName') : [];
  const connectedBanksWithConnectedAccounts =
    sortedBanks.length > 0 ? getConnectedAccounts(sortedBanks) : [];
  const hasNoConnectedAccounts = connectedBanksWithConnectedAccounts.length === 0;
  const hasActiveBaselaneAccounts = baselaneAccounts.some(
    (bank) => bank.unitAccount?.unitApplicationStatus === 'COMPLETED'
  );

  const sortedManualBanks =
    manualAccounts.length > 0 ? sortWithString(manualAccounts, 'plaidInstitutionName') : [];
  const connectedManualBanksWithConnectedAccounts =
    sortedManualBanks.length > 0 ? getConnectedAccounts(sortedManualBanks) : [];
  const hasConnectedManualAccounts = connectedManualBanksWithConnectedAccounts.length > 0;

  const allConnectedAccounts = baselaneConnectedAccounts
    .concat(connectedBanksWithConnectedAccounts)
    .concat(connectedManualBanksWithConnectedAccounts);

  const state = useMemo(() => {
    const newState = {};
    newState.loading = loading;
    if (data) {
      newState.banks = banks;
      newState.allConnectedAccounts = allConnectedAccounts;
      newState.baselaneAccounts = baselaneAccounts;
      newState.baselaneConnectedAccounts = baselaneConnectedAccounts;
      newState.hasVisibleApplications = hasVisibleApplications;
      newState.hasActiveBaselaneAccounts = hasActiveBaselaneAccounts;
      newState.externalAccounts = connectedBanksWithConnectedAccounts;
      newState.hasNoBaselaneAccounts = hasNoBaselaneAccounts;
      newState.hasNoConnectedAccounts = hasNoConnectedAccounts;
      newState.hasActivatedAPhysicalCard = hasActivatedAPhysicalCard;
      newState.connectedManualBanksWithConnectedAccounts = connectedManualBanksWithConnectedAccounts;
      newState.hasConnectedManualAccounts = hasConnectedManualAccounts;
      newState.refetchBankSummary = refetchBankSummary;
      newState.getKYCUrl = getKYCUrl;
      // add account data and flags, stored
      newState.addAccountFlag = addAccountFlag;
      newState.setAddAccountFlag = setAddAccountFlag;
      newState.addExternalAccountDrawerMobileRef = addExternalAccountDrawerMobileRef;
      newState.addExternalAccountSummaryDrawerRef = addExternalAccountSummaryDrawerRef;
      newState.linkedAccountsDrawerRef = linkedAccountsDrawerRef;
      newState.getKYCUrl = getKYCUrl;
      newState.linkBankWithUser = linkBankWithUser;
      newState.oldestBaselaneBank = oldestBaselaneBank;
      newState.SPBaselaneBankDraft = SPBaselaneBankDraft;
      newState.userHasDraftApplication = userHasDraftApplication;
      newState.rest = rest;
    }
    if (error) {
      newState.error = error;
    }

    return newState;
  }, [data, loading, error]);

  return <BanksContext.Provider value={state}>{children}</BanksContext.Provider>;
};
export const BanksConsumer = BanksContext.Consumer;
export default BanksContext;
