export const iconContainerStyles = {
  flex: '0',
  alignItems: 'flex-start',
  justify: 'center',
};

export const outerContainerStyles = (desktopLayout) => {
  return {
    maxWidth: { base: '100%', sm: '736px' },
    p: { base: '24px', sm: '16px 24px' },
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '4px',
    flexDirection: desktopLayout ? 'row' : 'column',
    boxShadow: desktopLayout ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '',
    bg: 'brand.blue.50',
    justify: 'space-between',
    mt: desktopLayout ? '' : '24px !important',
  };
};

export const innerContainerStyles = (desktopLayout, isAlignLeft) => {
  return {
    zIndex: '10',
    position: 'relative',
    w: '100%',
    justify: isAlignLeft ? 'flex-start' : 'space-between',
    flexDirection: desktopLayout ? 'row' : 'column',
    alignItems: desktopLayout ? 'center' : 'flex-start',
  };
};

export const textStyles = (desktopLayout) => {
  return {
    color: 'brand.neutral.600',
    p: '0px !important',
    m: '0 4px 0 0!important',
    textStyle: desktopLayout ? 'md' : 'sm',
  };
};

export const headingStyles = {
  color: 'brand.neutral.900',
  size: 'headline-md',
  p: '0px !important',
  m: '0px !important',
};

export const textContainerStyles = (desktopLayout) => {
  return {
    alignItems: 'flex-start',
    mt: { base: '10px !important', md: '0px !important' },
    ml: { base: '0px !important', md: '0px !important' },
  };
};

export const apyButtonStyles = (desktopLayout) => {
  return {
    minWidth: desktopLayout ? null : '100% !important',
    mt: !desktopLayout && '24px!important',
    alignSelf: desktopLayout ? 'end' : 'center',
  };
};

export const backToRCVuttonStyles = (desktopLayout) => {
  return {
    mt: desktopLayout ? '40px' : '24px!important',
    alignSelf: desktopLayout ? 'start' : 'center',
  };
};
