import React from 'react';

function IconEmail({
  w = 16,
  h = 16,
  color = 'currnetColor',
}: {
  w?: string,
  h?: string,
  color?: string,
}) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.92 3.252a2 2 0 011.413-.585h9.334a2 2 0 012 2v6.666a2 2 0 01-2 2H3.333a2 2 0 01-2-2v-5.99-.677a2 2 0 01.586-1.414zm.747 3.327v4.754a.667.667 0 00.666.667h9.334a.667.667 0 00.666-.667V6.58L9.11 9.394a2 2 0 01-2.22 0L2.667 6.58zm10.666-1.603L8.37 8.286a.667.667 0 01-.74 0l-4.963-3.31v-.31A.667.667 0 013.333 4h9.334a.667.667 0 01.666.667v.31z"
        fill={color}
      />
    </svg>
  );
}

IconEmail.defaultProps = {
  w: '16',
  h: '16',
  color: 'currentColor',
};

export default IconEmail;
