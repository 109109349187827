import React from 'react';

type IconBankErrorProps = { w?: Number, h?: Number };

function IconBankError({ w, h }: IconBankErrorProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 22.9121H4.44445C3.82217 22.9121 3.51103 22.9121 3.27335 22.7908C3.06428 22.6842 2.8943 22.514 2.78778 22.3046C2.66667 22.0666 2.66667 21.755 2.66667 21.1319L2.66667 20.2418C2.66667 19.6187 2.66667 19.3071 2.78778 19.0691C2.8943 18.8598 3.06428 18.6896 3.27335 18.5829C3.51103 18.4616 3.82217 18.4616 4.44445 18.4616H14M4.88889 9.56068V18.4616M9.88889 9.56068V18.4616M15.4444 9.56068V16.5788M20.4444 9.56068V13.9121M12.281 2.97079L4.0588 4.80043C3.56206 4.91096 3.31369 4.96623 3.1283 5.09997C2.96477 5.21794 2.83637 5.37823 2.75684 5.56367C2.66667 5.77391 2.66667 6.02868 2.66667 6.53822L2.66667 7.78049C2.66667 8.40362 2.66667 8.71518 2.78778 8.95318C2.8943 9.16253 3.06428 9.33274 3.27335 9.43941C3.51103 9.56068 3.82217 9.56068 4.44445 9.56068H20.8889C21.5112 9.56068 21.8223 9.56068 22.06 9.43941C22.2691 9.33274 22.439 9.16253 22.5456 8.95318C22.6667 8.71518 22.6667 8.40362 22.6667 7.78049V6.53822C22.6667 6.02868 22.6667 5.77391 22.5765 5.56367C22.497 5.37823 22.3686 5.21794 22.205 5.09997C22.0196 4.96623 21.7713 4.91096 21.2745 4.80043L13.0523 2.97079C12.9084 2.93876 12.8364 2.92275 12.7637 2.91636C12.6991 2.91069 12.6342 2.91069 12.5696 2.91636C12.4969 2.92275 12.4249 2.93876 12.281 2.97079Z"
        stroke="#192C3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.7" cx="22.6667" cy="22.9128" r="6.66667" fill="#F09393" />
      <circle
        cx="21.3333"
        cy="22.2467"
        r="6.66667"
        stroke="#192C3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 18.9141V23.1886"
        stroke="#192C3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 24.9141H21.3376"
        stroke="#192C3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconBankError.defaultProps = {
  w: 32,
  h: 33,
};

export default IconBankError;
