import React from 'react';

type IconAddDottedCircleProps = {
  shouldSetDimensions?: Boolean,
  w?: string,
  h?: string,
  color?: string,
};

const IconAddDottedCircle = ({ shouldSetDimensions, w, h, color }: IconAddDottedCircleProps) => {
  return (
    <svg
      {...(shouldSetDimensions ? { width: w, height: h } : {})}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x=".5" y=".5" width="31" height="31" rx="15.5" stroke={color} strokeDasharray="4 4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5332 10.6c0-.3314-.2686-.6-.6-.6s-.6.2686-.6.6v4.7352H10.6c-.3314 0-.6.2686-.6.6 0 .3313.2686.6.6.6h4.7332V21.4c0 .3314.2686.6.6.6s.6-.2686.6-.6v-4.8648H21.4c.3314 0 .6-.2687.6-.6 0-.3314-.2686-.6-.6-.6h-4.8668V10.6Z"
        fill={color}
      />
    </svg>
  );
};

IconAddDottedCircle.defaultProps = {
  shouldSetDimensions: true,
  w: '32',
  h: '32',
  color: 'currentColor',
};
export default IconAddDottedCircle;
