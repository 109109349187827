import React from 'react';

import getBreakPoints from '@core/utils/getBreakPoints';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const PageHeader = () => {
  const { isMin768 } = getBreakPoints();

  return isMin768 ? <DesktopHeader /> : <MobileHeader />;
};

export default PageHeader;
