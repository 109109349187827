// Figma: https://www.figma.com/file/IzR58By56T8Dvo2tLLPCbP/*Baselane---Design-System-(Habitat)?node-id=2084%3A60108&mode=dev

export const headlines = {
  'headline-2xl': {
    fontSize: { base: '2xl', md: '4xl' },
    lineHeight: { base: '32px', md: '40px' },
    letterSpacing: { base: '-0.96px', md: '-1.28px' },
    fontStyle: 'normal',
    fontWeight: 'semibold',
  },
  'headline-xl': {
    fontSize: { base: 'xl', md: '2xl' },
    lineHeight: { base: '28px', md: '32px' },
    letterSpacing: { base: '-0.8px', md: '-0.96px' },
    fontStyle: 'normal',
    fontWeight: 'semibold',
  },
  'headline-lg': {
    fontSize: 'lg',
    lineHeight: '24px',
    letterSpacing: '-0.72px',
    fontStyle: 'normal',
    fontWeight: 'semibold',
  },
  'headline-md': {
    fontSize: 'md',
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    fontStyle: 'normal',
    fontWeight: 'semibold',
  },
  'headline-sm': {
    fontSize: 'sm',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 'semibold',
  },
  'headline-xs': {
    fontSize: 'xs',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 'semibold',
  },
};

export const bodyBase = {
  md: {
    fontSize: 'md',
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  sm: {
    fontSize: 'sm',
    lineHeight: '20px',
    letterSpacing: '-0.14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  xs: {
    fontSize: 'xs',
    lineHeight: '16px',
    letterSpacing: '-0.12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
};

// since we only use two font weights we will set the name to heavy
// referring to the boldest version of text we have in the system at the moment
// it is possible to introduce bolder versions in the future. When that
// happens it can be under a different name.
export const body = {
  ...bodyBase,
  'md-heavy': {
    ...bodyBase.md,
    lineHeight: '24px',
    fontWeight: 'semibold',
  },
  'sm-heavy': {
    ...bodyBase.sm,
    lineHeight: '20px',
    fontWeight: 'semibold',
  },
  'xs-heavy': {
    ...bodyBase.xs,
    lineHeight: '16px',
    fontWeight: 'semibold',
  },
};

export const buttons = {
  'button-md': {
    fontSize: 'md',
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    fontStyle: 'normal',
    fontWeight: 'medium',
  },
  'button-sm': {
    fontSize: 'sm',
    lineHeight: '24px',
    letterSpacing: '-0.14px',
    fontStyle: 'normal',
    fontWeight: 'medium',
  },
  'button-xs': {
    fontSize: 'xs',
    lineHeight: '16px',
    letterSpacing: '-0.12px',
    fontStyle: 'normal',
    fontWeight: 'medium',
  },
};

export const inputs = {
  'input-sm': {
    fontSize: 'sm',
    lineHeight: '24px',
    letterSpacing: '-0.14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
};

export const data = {
  'data-xl': {
    fontSize: { md: '2xl', base: 'xl' },
    lineHeight: { md: '32px', base: '28px' },
    letterSpacing: { md: '-0.96px', base: '-0.8px' },
    fontStyle: 'normal',
    fontWeight: 'semibold',
  },
  'data-lg': {
    fontSize: 'lg',
    lineHeight: '24px',
    letterSpacing: '-0.72px',
    fontStyle: 'normal',
    fontWeight: 'semibold',
  },
  'data-md': {
    fontSize: 'md',
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    fontStyle: 'normal',
    fontWeight: 'semibold',
  },
  'data-sm': {
    fontSize: 'sm',
    lineHeight: '20px',
    letterSpacing: '-0.14px',
    fontStyle: 'normal',
    fontWeight: 'semibold',
  },
};

const textStyles = {
  ...headlines,
  ...body,
  ...buttons,
  ...inputs,
  ...data,
};

export default textStyles;
