import React from 'react';
import {
  IconScheduled,
  IconCircleDottedArrowWithBg,
  IconWarningCircle,
  IconCompleted,
  IconOutstanding,
  IconDeleted,
  IconXCircle,
  IconTimerCircle,
} from '@icons';

import { diagonalStripeBgStyles } from './styles/summarySection.style';

export const COLOR_TYPES = {
  primary: {
    bg: `brand.neutral.600`,
  },
  secondary: { ...diagonalStripeBgStyles },
  tertiary: {
    bg: `brand.darkBlue.150`,
  },
};

export const INVOICE_STATUS_TYPES = {
  SCHEDULED: {
    label: 'Scheduled',
    icon: <IconScheduled />,
    color: 'brand.blue.700',
  },
  UPCOMING: {
    label: 'Unpaid',
    icon: <IconOutstanding />,
    color: 'brand.blue.700',
  },
  AWAITING_VERIFICATION: {
    label: 'Pending Verification',
    icon: <IconTimerCircle />,
    color: 'brand.blue.700',
  },
  OVERDUE: {
    label: 'Overdue',
    icon: <IconWarningCircle color="#C93A3A" bgColor="#C93A3A" opacity={0.08} />,
    color: 'red.800AA',
  },
  PROCESSING: {
    label: 'Processing',
    icon: <IconCircleDottedArrowWithBg />,
    color: 'brand.blue.700',
  },
  FAILED: {
    label: 'Failed',
    icon: <IconXCircle />,
    color: 'red.800AA',
  },
  COMPLETED: {
    label: 'Completed',
    icon: <IconCompleted />,
    color: 'green.800AA',
  },
  DELETED: {
    label: 'Deleted',
    icon: <IconDeleted />,
    color: 'brand.darkBlue.300',
  },
};

export const UNPAID = ['AWAITING_VERIFICATION', 'OVERDUE', 'UPCOMING', 'FAILED', 'DELETED'];
