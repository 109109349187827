import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { IconBankError } from '@icons';
import FailedPaymentAccordion from './FailedPaymentAccordion';
import { BA_ERROR_ENUMS } from '../../../../../helpers/sharedBAErrors.helpers';
import { failedPaymentsStyles } from './styles/failedPayments.styles';

type FailedPaymentsProps = {
  tenantData: Object,
  failedPayment: Object,
  propertiesData: Array<Object>,
};

function FailedPayments({ tenantData, failedPayment, propertiesData }: FailedPaymentsProps) {
  const { account, list } = failedPayment ?? {};
  const { nickName, bankAccountMetadata } = account ?? {};
  const { lastPayoutFailed, payoutFailedReason } = bankAccountMetadata ?? {};
  const { FAILED, PROCESSING, leases, UNPAID } = list ?? {};

  const failedPaymentsData = [
    {
      id: 1,
      type: 'FAILED_PAYMENT',
      numberOfData: FAILED?.length,
      invoices: FAILED,
    },
    {
      id: 2,
      type: 'PROCESSING_PAYMENT',
      numberOfData: PROCESSING?.length,
      invoices: PROCESSING,
    },
    {
      id: 3,
      type: 'RENT_COLLECTION',
      numberOfData: leases?.length,
      leases,
    },
    {
      id: 4,
      type: 'UNPAID_INVOICE',
      numberOfData: UNPAID?.length,
      invoices: UNPAID,
    },
  ];

  const { card, header, content } = failedPaymentsStyles ?? {};

  return (
    <Stack {...card.container}>
      {/* Header */}
      <Stack direction="row" {...header.container}>
        <Box {...header.icon.container}>
          <IconBankError />
        </Box>

        <Stack m="0 !important">
          <Text {...header.title}>{`${nickName} unable to receive funds`}</Text>
          {lastPayoutFailed && (
            <Text {...header.msg}>{BA_ERROR_ENUMS[payoutFailedReason?.failureCode] ?? ''}</Text>
          )}
        </Stack>
      </Stack>

      {/* Content */}
      <Stack {...content}>
        <FailedPaymentAccordion {...{ failedPaymentsData, tenantData, propertiesData }} />
      </Stack>
    </Stack>
  );
}

export default FailedPayments;
