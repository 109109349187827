import React, { useContext } from 'react';
import { Box, HStack, Spacer, Text, Stack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { BaselaneDivider, BaselaneOverview, BaselaneTitle, CurrencyText } from '@shared/components';
import CashFlowContext from '@contexts/CashFlowContext';
import isNil from '@core/utils/validators/isNil';
import {
  currencyStyle,
  currencyTextStyle,
  firstItemStyles,
  customContentStyles,
  customContainerStyles,
  customBorderStyles,
  customFontStyles,
  overviewElemStyles,
  overviewLabelIndicatorStyles,
  overviewLabelSmallIndicatorStyles,
  titleDividerStyles,
  titleNoResultsTextStyles,
  titleStyles,
  detailsTextStyles,
  detailsTextBoldStyles,
} from '../styles/newKPIs.styles';

type NewKPIsProps = {
  hasNoData: boolean,
  isDashboard: boolean,
  filters: any,
  dataset: Array<Object>,
  selectedMonth: String,
  totalUncategorizedUnhiddenTxCount?: Number,
};

function NewKPIs({
  hasNoData,
  isDashboard,
  dataset,
  selectedMonth,
  totalUncategorizedUnhiddenTxCount,
}: NewKPIsProps) {
  const { cashFlowLoading, cashFlowError, cashFlowData, cashFlowSummaryDummyData } = useContext(
    CashFlowContext
  );

  const data = hasNoData ? cashFlowSummaryDummyData : cashFlowData;
  const selectedMonthObj = dataset.find((d) => d.discriminator === selectedMonth);

  const {
    netIncome: selectedMonthNetIncome,
    expenses: selectedMonthExpenses,
    revenues: selectedMonthRevenues,
  } = selectedMonthObj ?? {};

  if (cashFlowLoading || cashFlowError) return null;
  if (isNil(data?.cashFlow?.summary)) return null; // TODO: Till BE fixes the issue

  const { netCashFlow, totalExpenseAmount, totalRevenueAmount } = data?.cashFlow?.summary || {};
  const hasNoCashFlow = netCashFlow === 0 && totalExpenseAmount === 0 && totalRevenueAmount === 0;

  const netIncome = selectedMonthObj ? selectedMonthNetIncome : netCashFlow;
  const expense = selectedMonthObj ? selectedMonthExpenses : totalExpenseAmount;
  const revenue = selectedMonthObj ? selectedMonthRevenues : totalRevenueAmount;

  const cashFlow = (
    <HStack {...currencyStyle(isDashboard, isMobile)}>
      <Box as="span">{netIncome < 0 && '-'}</Box>
      <CurrencyText amount={Math.abs(netIncome)} {...currencyTextStyle(isDashboard, isMobile)} />
    </HStack>
  );

  const revenues = <CurrencyText amount={revenue} {...currencyTextStyle(isDashboard, isMobile)} />;

  const expenses = (
    <CurrencyText amount={Math.abs(expense)} {...currencyTextStyle(isDashboard, isMobile)} />
  );

  const uncategorizedTrxs = totalUncategorizedUnhiddenTxCount?.toLocaleString();

  const cashflowOverview = [
    {
      id: 'net_cash_flow',
      title: (
        <HStack as="span">
          <Box as="span" {...{ ...overviewLabelIndicatorStyles, bgColor: 'brand.darkBlue.250' }}>
            <Box as="span" {...{ ...overviewLabelSmallIndicatorStyles, bgColor: 'red.800AA' }} />
          </Box>
          <Box as="span">Net Cash Flow</Box>
        </HStack>
      ),
      content: cashFlow,
      style: overviewElemStyles(isDashboard),
    },
    {
      id: 'cash_inflow',
      title: (
        <HStack as="span">
          <Box as="span" {...{ ...overviewLabelIndicatorStyles, bgColor: 'brand.blue.700' }} />
          <Box as="span">Cash Inflow</Box>
        </HStack>
      ),
      content: revenues,
      style: overviewElemStyles(isDashboard),
    },
    {
      id: 'cash_outflow',
      title: (
        <HStack as="span">
          <Box as="span" {...{ ...overviewLabelIndicatorStyles, bgColor: 'brand.blue.250' }} />
          <Box as="span">Cash Outflow</Box>
        </HStack>
      ),
      content: expenses,
      style: overviewElemStyles(isDashboard),
    },
  ];

  const cashFlowDetails = (
    <Stack mb="8px" flexDir={{ lg: 'column', xl: 'row' }}>
      <Text {...detailsTextStyles}>
        Showing{' '}
        <Box as="span" {...detailsTextBoldStyles}>
          operating cashflow
        </Box>{' '}
        for{' '}
        <Box as="span" {...detailsTextBoldStyles}>
          all properties
        </Box>{' '}
        in{' '}
        <Box as="span" {...detailsTextBoldStyles}>
          last 6 months
        </Box>
        .
      </Text>
      <Spacer />
      <Text {...detailsTextStyles}>
        <Box as="span" {...detailsTextBoldStyles}>
          {uncategorizedTrxs}
        </Box>{' '}
        {`Uncategorized Transaction${uncategorizedTrxs === 1 ? '' : 's'}`}
      </Text>
    </Stack>
  );

  return (
    <Box w="100%" px={isDashboard && !hasNoData && '24px'}>
      <Box display={isMobile ? 'none' : 'block'}>
        {isDashboard && !hasNoData && cashFlowDetails}

        <BaselaneTitle
          title={
            <HStack>
              {!isDashboard && <Box>Cash Flow Chart</Box>}
              {hasNoCashFlow && !isDashboard && (
                <>
                  <BaselaneDivider isVertical styles={titleDividerStyles} />
                  <Box {...titleNoResultsTextStyles}>No results found</Box>
                </>
              )}
            </HStack>
          }
          titleStyles={titleStyles}
        />
      </Box>
      <BaselaneOverview
        customFontStyles={customFontStyles(isDashboard, hasNoData, isMobile)}
        customBorderStyles={customBorderStyles(isDashboard, isMobile)}
        customContentStyles={customContentStyles}
        styles={customContainerStyles(isDashboard)}
        elements={cashflowOverview}
        firstItemStyles={firstItemStyles(isMobile)}
      />
    </Box>
  );
}

NewKPIs.defaultProps = {
  totalUncategorizedUnhiddenTxCount: null,
};

export default NewKPIs;
