import {
  IllustrationRCAddproperty,
  IllustrationRCSetuprentcollection,
  IllustrationBankingNewaccount,
  IllustrationBankingAddexternalAccount,
} from '@illustrations';

export const quickActionsData = [
  {
    key: 'add_property',
    icon: IllustrationRCAddproperty,
    title: 'Add another property',
  },
  {
    key: 'set_up_rc',
    icon: IllustrationRCSetuprentcollection,
    title: 'Set up rent collection',
  },
  {
    key: 'open_baselane_acc',
    icon: IllustrationBankingNewaccount,
    title: 'Open a Baselane Banking account',
  },
  {
    key: 'connect_external_acc',
    icon: IllustrationBankingAddexternalAccount,
    title: 'Connect an external account',
  },
];
