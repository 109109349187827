import React from 'react';
import { Text, Image, HStack } from '@chakra-ui/react';
import PoweredByVouched from './assets/powerByVouched.png';
import { poweredByVouchedStyles, vouchedImageStyles } from './styles/poweredByVouched.style';

const PowerByVouched = () => {
  return (
    <HStack {...poweredByVouchedStyles}>
      <Text>Powered by</Text>

      <Image {...vouchedImageStyles} src={PoweredByVouched} alt="Powered By Vouched" />
    </HStack>
  );
};

export default PowerByVouched;
