export const accordionHeaderStyles = {
  text: {
    textStyle: 'sm',
    color: 'brand.neutral.700',
  },
  subtext: {
    textStyle: 'sm',
    color: 'brand.neutral.600',
    m: '0 !important',
  },
};
