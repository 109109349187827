import React from 'react';
import { Flex, Box, Heading } from '@chakra-ui/react';

import { BaselaneDivider } from '@shared/components';

const Dashboard = () => {
  return (
    <Flex h="100%" direction="column">
      <Box
        px={{ '2lg': 4, lg: 3, base: 2 }}
        pt={{ '2lg': 4, base: 3 }}
        pb={{ '2lg': 4, lg: 5, base: 3 }}
        flex="1 1 auto"
      >
        widgets
      </Box>

      <BaselaneDivider styles={{ my: 0 }} />

      <Box
        px={{ '2lg': 4, base: 3 }}
        pt={{ '2lg': 4, base: 3 }}
        pb={{ '2lg': 4, base: 5 }}
        flex="1 1 auto"
        bgColor="brand.darkBlue.50"
      >
        <Heading size="headline-xl">Get more done with our trusted partners</Heading>
      </Box>
    </Flex>
  );
};

export default Dashboard;
