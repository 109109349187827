import React from 'react';

type IconBankingBusinessProps = { w?: string, h?: string, color?: string };

function IconBankingBusiness({ w, h, color, ...rest }: IconBankingBusinessProps) {
  const width = w.split('px').join('').trim();
  const height = h.split('px').join('').trim();

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...rest}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="9" opacity="0.7" fill="#6ADA9B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 28C29.4659 28 28.2223 29.2437 28.2223 30.7778C28.2223 32.3119 29.4659 33.5556 31 33.5556C32.5342 33.5556 33.7778 32.3119 33.7778 30.7778C33.7778 29.2437 32.5342 28 31 28ZM27.1111 30.7778C27.1111 28.63 28.8523 26.8889 31 26.8889C33.1478 26.8889 34.8889 28.63 34.8889 30.7778C34.8889 32.9256 33.1478 34.6667 31 34.6667C28.8523 34.6667 27.1111 32.9256 27.1111 30.7778Z"
        fill="#192C3E"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 41.8889C35.9092 41.8889 39.8889 37.9092 39.8889 33C39.8889 28.0908 35.9092 24.1111 31 24.1111C26.0908 24.1111 22.1111 28.0908 22.1111 33C22.1111 37.9092 26.0908 41.8889 31 41.8889ZM31 43C36.5228 43 41 38.5228 41 33C41 27.4772 36.5228 23 31 23C25.4772 23 21 27.4772 21 33C21 38.5228 25.4772 43 31 43Z"
        fill="#192C3E"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4883 38C26.2837 38 26.0955 38.1126 25.9988 38.2929L24.8229 40.4849L23.8438 39.9596L25.0197 37.7677C25.31 37.2265 25.8743 36.8889 26.4883 36.8889H35.1088C35.6681 36.8889 36.19 37.1694 36.4987 37.6358L38.13 40.1008L37.2034 40.714L35.5721 38.249C35.4692 38.0935 35.2952 38 35.1088 38H26.4883Z"
        fill="#192C3E"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.05516 9.96204H34.7303C34.9906 9.96203 35.2152 9.96202 35.4002 9.97714C35.5954 9.99309 35.7896 10.0283 35.9764 10.1235C36.2552 10.2655 36.4818 10.4922 36.6239 10.7709C36.7191 10.9578 36.7543 11.152 36.7702 11.3472C36.7853 11.5322 36.7853 11.7567 36.7853 12.0171V20.8292C36.7853 21.1019 36.5642 21.323 36.2915 21.323C36.0188 21.323 35.7977 21.1019 35.7977 20.8292V12.0361C35.7977 11.7514 35.7973 11.5676 35.7859 11.4276C35.7749 11.2935 35.7563 11.2437 35.7439 11.2193C35.6965 11.1264 35.621 11.0509 35.528 11.0035C35.5036 10.9911 35.4539 10.9725 35.3197 10.9615C35.1798 10.9501 34.996 10.9497 34.7113 10.9497H6.07419C5.78947 10.9497 5.60564 10.9501 5.46572 10.9615C5.33156 10.9725 5.28183 10.9911 5.25741 11.0035C5.16449 11.0509 5.08894 11.1264 5.0416 11.2193C5.02916 11.2437 5.01055 11.2935 4.99959 11.4276C4.98816 11.5676 4.98778 11.7514 4.98778 12.0361V31.7301C4.98778 32.0148 4.98816 32.1987 4.99959 32.3386C5.01055 32.4727 5.02916 32.5225 5.0416 32.5469C5.08894 32.6398 5.16449 32.7154 5.25741 32.7627C5.28183 32.7751 5.33156 32.7938 5.46572 32.8047C5.60564 32.8161 5.78948 32.8165 6.07419 32.8165H18.4656C18.7383 32.8165 18.9594 33.0376 18.9594 33.3104C18.9594 33.5831 18.7383 33.8042 18.4656 33.8042H6.05515C5.79483 33.8042 5.57026 33.8042 5.38529 33.7891C5.19006 33.7731 4.99588 33.7379 4.80902 33.6427C4.53027 33.5007 4.30363 33.274 4.16159 32.9953C4.06639 32.8084 4.03117 32.6142 4.01522 32.419C4.00011 32.234 4.00011 32.0095 4.00012 31.7491V12.0171C4.00011 11.7568 4.00011 11.5322 4.01522 11.3472C4.03117 11.152 4.06639 10.9578 4.16159 10.7709C4.30363 10.4922 4.53027 10.2655 4.80902 10.1235C4.99588 10.0283 5.19006 9.99309 5.38529 9.97714C5.57026 9.96202 5.79484 9.96203 6.05516 9.96204Z"
        fill="#192C3E"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.01739 17.779C4.08889 17.5158 4.3602 17.3604 4.6234 17.4319L17.1497 20.8344C17.4129 20.9059 17.5683 21.1773 17.4968 21.4404C17.4253 21.7036 17.154 21.859 16.8908 21.7876L4.3645 18.385C4.1013 18.3135 3.9459 18.0422 4.01739 17.779ZM36.7681 17.779C36.8396 18.0422 36.6842 18.3135 36.421 18.385L23.8946 21.7876C23.6314 21.859 23.3601 21.7036 23.2886 21.4404C23.2171 21.1773 23.3725 20.9059 23.6357 20.8344L36.1621 17.4319C36.4253 17.3604 36.6966 17.5158 36.7681 17.779Z"
        fill="#192C3E"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9919 4H24.7936C25.0539 3.99999 25.2785 3.99998 25.4634 4.0151C25.6587 4.03105 25.8528 4.06627 26.0397 4.16147C26.3185 4.30351 26.5451 4.53014 26.6871 4.8089C26.7823 4.99575 26.8175 5.18994 26.8335 5.38517C26.8486 5.57015 26.8486 5.79473 26.8486 6.05507V10.4559C26.8486 10.7286 26.6275 10.9497 26.3548 10.9497C26.082 10.9497 25.8609 10.7286 25.8609 10.4559V6.07407C25.8609 5.78935 25.8606 5.60551 25.8491 5.46559C25.8382 5.33144 25.8196 5.2817 25.8071 5.25729C25.7598 5.16437 25.6842 5.08882 25.5913 5.04148C25.5669 5.02903 25.5172 5.01043 25.383 4.99947C25.2431 4.98804 25.0592 4.98765 24.7745 4.98765H16.0109C15.7262 4.98765 15.5424 4.98804 15.4025 4.99947C15.2683 5.01043 15.2186 5.02903 15.1941 5.04148C15.1012 5.08882 15.0257 5.16437 14.9783 5.25729C14.9659 5.2817 14.9473 5.33144 14.9363 5.46559C14.9249 5.60551 14.9245 5.78935 14.9245 6.07407V10.4559C14.9245 10.7286 14.7034 10.9497 14.4307 10.9497C14.158 10.9497 13.9369 10.7286 13.9369 10.4559L13.9369 6.05503C13.9368 5.79471 13.9368 5.57014 13.952 5.38517C13.9679 5.18994 14.0031 4.99575 14.0983 4.8089C14.2404 4.53014 14.467 4.30351 14.7458 4.16147C14.9326 4.06627 15.1268 4.03105 15.322 4.0151C15.507 3.99998 15.7316 3.99999 15.9919 4Z"
        fill="#192C3E"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5815 18.89H22.204C22.4643 18.89 22.6889 18.89 22.8739 18.9051C23.0691 18.9211 23.2633 18.9563 23.4501 19.0515C23.7289 19.1936 23.9555 19.4202 24.0975 19.6989C24.1928 19.8858 24.228 20.08 24.2439 20.2752C24.259 20.4602 24.259 20.6848 24.259 20.9451V21.6769C24.259 21.9372 24.259 22.1618 24.2439 22.3468C24.228 22.542 24.1928 22.7362 24.0975 22.923C23.9555 23.2018 23.7289 23.4284 23.4501 23.5705C23.2633 23.6657 23.0691 23.7009 22.8739 23.7169C22.6889 23.732 22.4643 23.732 22.204 23.7319H18.5815C18.3212 23.732 18.0966 23.732 17.9116 23.7169C17.7164 23.7009 17.5222 23.6657 17.3353 23.5705C17.0566 23.4284 16.8299 23.2018 16.6879 22.923C16.5927 22.7362 16.5575 22.542 16.5415 22.3468C16.5264 22.1618 16.5264 21.9372 16.5264 21.6769V20.9451C16.5264 20.6848 16.5264 20.4602 16.5415 20.2752C16.5575 20.08 16.5927 19.8858 16.6879 19.6989C16.8299 19.4202 17.0566 19.1936 17.3353 19.0515C17.5222 18.9563 17.7164 18.9211 17.9116 18.9051C18.0966 18.89 18.3211 18.89 18.5815 18.89ZM17.992 19.8895C17.8579 19.9005 17.8081 19.9191 17.7837 19.9315C17.6908 19.9789 17.6153 20.0544 17.5679 20.1473C17.5555 20.1717 17.5369 20.2215 17.5259 20.3556C17.5145 20.4956 17.5141 20.6794 17.5141 20.9641V21.6579C17.5141 21.9426 17.5145 22.1264 17.5259 22.2664C17.5369 22.4005 17.5555 22.4502 17.5679 22.4747C17.6153 22.5676 17.6908 22.6431 17.7837 22.6905C17.8081 22.7029 17.8579 22.7215 17.992 22.7325C18.1319 22.7439 18.3158 22.7443 18.6005 22.7443H22.185C22.4697 22.7443 22.6535 22.7439 22.7934 22.7325C22.9276 22.7215 22.9773 22.7029 23.0017 22.6905C23.0947 22.6431 23.1702 22.5676 23.2175 22.4747C23.23 22.4502 23.2486 22.4005 23.2596 22.2664C23.271 22.1264 23.2714 21.9426 23.2714 21.6579V20.9641C23.2714 20.6794 23.271 20.4956 23.2596 20.3556C23.2486 20.2215 23.23 20.1717 23.2175 20.1473C23.1702 20.0544 23.0947 19.9789 23.0017 19.9315C22.9773 19.9191 22.9276 19.9005 22.7934 19.8895C22.6535 19.8781 22.4697 19.8777 22.185 19.8777H18.6005C18.3158 19.8777 18.1319 19.8781 17.992 19.8895Z"
        fill="#192C3E"
      />
    </svg>
  );
}
IconBankingBusiness.defaultProps = {
  w: '24',
  h: '24',
  color: 'currentColor',
};

export default IconBankingBusiness;
