import { bodyBase } from '@core/theme/text';

export const radioGroupTitleStyles = {
  ...bodyBase.md,
  fontWeight: 'semibold',
  color: 'brand.neutral.700',
  mb: 0.5,
};

export const radioGroupDescriptionStyles = {
  ...bodyBase.sm,
  color: 'brand.neutral.700',
  mb: 1,
};

export const radioGroupHelpTextStyles = {
  ...bodyBase.xs,
  color: 'brand.neutral.600',
  mt: 1,
};
