import { useCallback } from 'react';
import useTwoFactor from '@core/components/Shared/components/TwoFactorVerificationPopUp/hooks/useTwoFactor';
import {
  setTokenData,
  getIsTokenExpired,
  getIsTokenValid,
  getSensitiveToken,
  getUnitOTPVerification,
  handleOTPPopupClose,
  handleOTPPopupOpen,
  handleNBOnVerifyClick,
} from '@shared/helpers/otp.helpers';

/**
 * @typedef OtpVerificationParams
 * @type {Object}
 * @property {string} bankId - The bank id. NOTE: need more info on that param :/
 * @property {string} phoneNumber - The phone number. NOTE: need more info on that param :/
 * @property {Function} callback - The callback to be called after the OTP is verified.
 */

/**
 * @typedef OtpVerificationResult
 * @type {Object}
 * @property {Object} props - The props to be passed to the OTP verification component [TwoFactorVerificationPopUp].
 * @property {boolean} isTokenExpired - Whether the token is expired.
 * @property {Function} handleOtpPopupOpen - The function to open the OTP popup.
 */

/**
 * This hook is used to verify the OTP. It actually wraps much of the logic in the OTP verification process and only exposes the necessary functions and states.
 * @param {OtpVerificationParams} params - The parameters as described by {@link OtpVerificationParams}.
 * @returns {OtpVerificationResult} The result object as described by {@link OtpVerificationResult}.
 */
const useOtpVerification = ({ bankId, phoneNumber, callback }) => {
  const { states, stateFunctions } = useTwoFactor();
  const { setOTPErrorCode } = stateFunctions;
  const isTokenExpired = getIsTokenExpired(bankId);
  const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

  const onUserSensitiveTokenComplete = (unitAPISensitiveToken) => {
    const isTokenValid = getIsTokenValid(unitAPISensitiveToken);
    setTokenData(unitAPISensitiveToken, bankId);

    if (isTokenValid) {
      callback();
    }

    handleOTPPopupClose(states, stateFunctions);
  };

  const getSensitiveTokenProps = {
    onUserSensitiveTokenComplete,
    getOTP,
    bankId,
    states,
    stateFunctions,
  };

  const [getUserSensitiveTokenData] = getSensitiveToken(getSensitiveTokenProps);

  const props = {
    ...states,
    ...stateFunctions,
    getOTP,
    bankId,
    phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleNBOnVerifyClick(otpCode, bankId, getUserSensitiveTokenData),
  };

  const handleOtpPopupOpen = useCallback(() => handleOTPPopupOpen(getOTP, bankId, stateFunctions), [
    getOTP,
    bankId,
    stateFunctions,
  ]);

  const handleOtpPopupClose = useCallback(() => handleOTPPopupClose(states, stateFunctions));

  return {
    props,
    isTokenExpired,
    handleOtpPopupOpen,
    handleOtpPopupClose,
  };
};

export default useOtpVerification;
