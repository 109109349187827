/**
 * Determines the type of transfer based on the accounts involved.
 *
 * @param {Object} transferFromAccount - The account from which the transfer is made.
 * @param {Object} depositToAccount - The account to which the deposit is made.
 * @returns {string} The type of transfer: 'TRANSFER_IN', 'TRANSFER_OUT', or 'INTERNAL_TRANSFER'.
 */
const getTransferType = (transferFromAccount, depositToAccount) => {
  if (transferFromAccount?.isExternal) {
    return 'TRANSFER_IN';
  }
  if (depositToAccount?.isExternal) {
    return 'TRANSFER_OUT';
  }
  return 'INTERNAL_TRANSFER';
};

export default getTransferType;
