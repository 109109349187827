import React from 'react';
import BaselaneCardStackLayoutWithButton from '@shared/components/BaselaneCardStack/components/BaselaneCardStackLayoutWithButton';
import { BaselaneButton } from '@core/components/Shared/components';
import { Icon16ChevronRight, Icon16PlusCircle } from '@icons/16px';

type PaymentMethodCardProps = {
  name: String,
  description?: String,
  onAddClick: Function,
  onEditClick: Function,
  paymentMethodExists?: Boolean,
};

const PaymentMethodCard = ({
  name,
  description,
  onAddClick,
  onEditClick,
  paymentMethodExists,
}: PaymentMethodCardProps) => {
  return (
    <BaselaneCardStackLayoutWithButton title={name} description={description}>
      {!paymentMethodExists ? (
        <BaselaneButton
          size="md"
          variant="tonal"
          palette="primary"
          onClick={onAddClick}
          styles={{ ml: 'auto' }}
          leftIcon={<Icon16PlusCircle />}
        >
          Add
        </BaselaneButton>
      ) : (
        <BaselaneButton
          size="md"
          variant="transparent"
          palette="primary"
          onClick={onEditClick}
          styles={{ ml: 'auto' }}
          rightIcon={<Icon16ChevronRight />}
        >
          Edit
        </BaselaneButton>
      )}
    </BaselaneCardStackLayoutWithButton>
  );
};

PaymentMethodCard.defaultProps = {
  paymentMethodExists: false,
  description: null,
};
export default PaymentMethodCard;
