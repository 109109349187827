// @flow
import process from 'process';
import React from 'react';
import * as jose from 'jose';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import {
  Stack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { UPDATE_VOUCHED } from '@shared/components/BaselaneKYCForm/queries';
import VouchedInfoDrawer from './VouchedInfoDrawer';
import PowerByVouched from './PowerByVouched';
import { modalStyles } from './styles/vouchedModal.styles';

function VouchedDrawer({
  vouchedDrawerRef,
  handleVouchedDrawerOpen,
  handleVouchedDrawerClose,
  user,
  handleVouchedReturnDrawerOpen,
  handleVerifyProcess,
  showiFrame,
  setShowiFrame,
  refetchUser,
}: {
  vouchedDrawerRef: any,
  handleVouchedDrawerOpen: Function,
  handleVouchedDrawerClose: Function,
  user: Object,
  handleVouchedReturnDrawerOpen: Function,
  handleVerifyProcess: Function,
  showiFrame: Boolean,
  setShowiFrame: Function,
  refetchUser: Function,
}): any {
  const { header, closeBtn, content, iframe } = modalStyles ?? {};

  const [updateVouched] = useMutation(UPDATE_VOUCHED);

  const handleCloseiFrame = () => {
    refetchUser();
    setShowiFrame(false);
  };

  /**
   *  vouched config data
   */
  const { firstName, lastName, email, phoneNumber, userKyc } = user ?? {};
  const [orchestrationUrl] = process?.env?.REACT_APP_ORCHESTRATION_URL?.split('/graphql') || [];
  const callbackUrl = `${orchestrationUrl}/api/vouched/status`;
  const { vouchedToken = '' } = userKyc ?? '';

  const isTokenValid = (token) => {
    let isValid = false;
    try {
      const claims = jose.decodeJwt(token) ?? '';
      const expDate = new Date((claims?.exp || 0) * 1000);

      isValid = moment.utc(expDate).isValid() && moment.utc(expDate).isAfter();
    } catch (error) {
      return false;
    }

    return isValid;
  };
  const validToken = vouchedToken && isTokenValid(vouchedToken) ? vouchedToken : null;

  const sandbox =
    process?.env?.REACT_APP_NODE_ENV === 'development' ||
    process?.env?.REACT_APP_NODE_ENV === 'develop' ||
    process?.env?.REACT_APP_NODE_ENV === 'uat';

  const appId = process?.env?.REACT_APP_VOUCHED;

  const config = {
    /**
     *  optional verification information for comparison
     */
    verification: {
      firstName,
      lastName,
      /**
       *  used for the crosscheck feature
       */
      email,
      phone: phoneNumber,
    },
    liveness: 'mouth',
    id: 'camera',
    showUploadFirst: true,
    includeBackId: true,
    manualCaptureTimeout: 20000,
    includeBarcode: true,
    sandbox,
    appId,
    /**
     *  your webhook for POST verification processing
     */
    callbackURL: callbackUrl,
    token: validToken,
    /**
     *  mobile handoff fields, a job will be created automatically if true
     */
    crossDevice: true,
    crossDeviceQRCode: !isMobile,
    crossDeviceSMS: !isMobile,
    content: {
      qrDesktopLink: 'finish the verification from your computer',
      qrDesktopInstructions:
        'Please follow the instructions below to continue the verification process on your phone. Alternatively, you may be able {qrDesktopLink} if the camera is compatible.',
      crossDeviceInstructions:
        'In order to verify your identity, please make sure that you have a non-expired, government-issued photo ID available, and that you have appropriate lighting to take a selfie photo of your face.<br><br>',
    },
    /**
     *  have the user confirm information
     */
    disableCssBaseline: true,
    userConfirmation: {
      confirmData: true,
      confirmImages: true,
    },
    /**
     *  callback during initialization of the web app
     */
    onInit: ({ token, job }) => {
      console.debug('initialization: ', token, job);
      updateVouched({
        variables: {
          vouchedToken: token,
          vouchedEntityId: job.id,
        },
      }).then(() => {
        console.debug(`Successfully update token`);
      });
    },
    /**
     *  callback when a user submits a photo
     */
    onSubmit: ({ stage, attempts, job }) => {
      console.debug('photo submitted: ', stage, attempts, job);
    },
    /**
     *  called when a job is completed.
     */
    onDone: (job) => {
      /**
       *  token used to query jobs
       */
      console.debug('scanning complete: ', job, { token: job.token });

      if (job?.result?.success != null) {
        const vouchedKycStatus = job?.result?.success ? 'VERIFIED' : 'DENIED';
        updateVouched({
          variables: {
            vouchedKycStatus,
          },
        }).then(() => {
          console.debug(`Successfully update job status`);

          setTimeout(() => {
            refetchUser();
            handleCloseiFrame();
            handleVouchedReturnDrawerOpen();
          }, 400);
        });
      }
      /**
       *  An alternative way to update your system based on the
       *  results of the job. Your backend could perform the following:
       *  1. query jobs with the token
       *  2. store relevant job information such as the id and
       *      success property into the user's profile
       *  fetch(`/yourapi/idv?job_token=${job.token}`);
       *
       *  Redirect to the next page based on the job success
       *  if (job.result.success) {
       *    window.location.replace("https://www.vouched.id/");
       *  } else {
       *    window.location.replace("https://www.vouched.id/");
       *  }
       */
    },

    /**
     *  callback executed after attempt to find camera device
     */
    onCamera: ({ hasCamera, hasPermission }) => {
      console.debug('attempted to find camera: ', hasCamera, hasPermission);
    },

    /**
     *  callback when there are changes to the Camera DOM element
     */
    onCameraEvent: (cameraEvent) => {
      console.debug('camera DOM element updated: ', cameraEvent);
    },

    /**
     *  callback when a reverification job is complete
     */
    onReverify: (job) => {
      console.debug('reverification complete: ', job);
    },

    /**
     *  callback when a survey is submitted, (per customer basis)
     */
    onSurveyDone: (job) => {
      console.debug('survey done: ', job);
    },

    /**
     *  callback when user confirms data and photos
     */
    onConfirm: (userConfirmEvent) => {
      console.debug('user confirmation: ', userConfirmEvent);
    },

    /**
     *  theme
     */
    theme: {
      name: 'verbose',
      fontFamily: '"Ease Variable", Helvetica, Arial',
      fontColor: '#192C3E',
    },
  };

  const loadVouched = () => {
    const script = document.createElement('script');
    script.src = 'https://static.vouched.id/widget/vouched-2.0.0.js';
    script.id = 'vouched';
    script.async = true;
    document.head.appendChild(script);
    script.onload = () => {
      /* eslint-disable dot-notation */
      const vouched = window['Vouched']({ ...config });
      vouched.mount('#vouched-element');
    };
  };

  const clickVouched = () => {
    handleVerifyProcess();
    loadVouched();
  };

  return (
    <>
      <VouchedInfoDrawer
        {...{ vouchedDrawerRef, handleVouchedDrawerOpen, handleVouchedDrawerClose, clickVouched }}
      />

      <Modal isOpen={showiFrame} onClose={handleCloseiFrame} size="full" padding="0">
        <ModalOverlay />
        <ModalContent {...content}>
          <ModalHeader {...header}>Verify Identity</ModalHeader>
          <ModalCloseButton {...closeBtn} />
          <ModalBody padding={0} mb="0" {...iframe}>
            <Stack m="0!important" p="0" height="100%" overflow="hidden">
              {/* eslint-disable react/self-closing-comp  */}
              <div id="vouched-element"></div>
            </Stack>

            {!isMobile && <PowerByVouched />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default VouchedDrawer;
