import React from 'react';
import { BaselaneCreditCard } from '@shared/components';
import { creditCardStyles } from './styles/wallet.styles';

type WalletCreditCardProps = {
  status: string,
  bankProps: Object,
  isPromotionalCard: Boolean,
};

const WalletCreditCard = ({
  status,
  bankProps,
  isPromotionalCard,
  ...rest
}: WalletCreditCardProps) => {
  const isGrayedOut = status === 'DENIED' || status === 'IN_PROGRESS';
  const hasWallet = status === 'DENIED' || status === 'AWAITING_DOCUMENTS';

  return (
    <BaselaneCreditCard
      {...{
        isGrayedOut,
        hasWallet,
        containerStyles: creditCardStyles(status),
        textStyles: creditCardStyles(status),
        accountStyles: creditCardStyles(status),
        valueStyles: creditCardStyles(status, isGrayedOut),
        subtitleLeftStyles: creditCardStyles(status, isGrayedOut),
        maskAllAccountNumbers: status === 'IN_PROGRESS',
        ...bankProps,
        isPromotionalCard,
        ...rest,
      }}
    />
  );
};

export default WalletCreditCard;
