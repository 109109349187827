import React from 'react';
import { Image, Box } from '@chakra-ui/react';
import {
  formatAccount,
  getBalanceHelper,
} from '../../../../NativeBankingPage/MainContent/helpers/accounts.shared.helpers';

export const IconLogo = (account, styles) => {
  if (account?.logo !== null) {
    return () => (
      <Image
        src={`data:image/png;base64,${account?.logo?.toString()}`}
        alt={account?.bankName}
        {...styles}
      />
    );
  }

  return () => (
    <Box fontSize="2xl" pl="8px">
      {account?.bankName?.charAt(0) || '-'}
    </Box>
  );
};

export { formatAccount, getBalanceHelper };
