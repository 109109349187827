export const containerStyle = {
  justifyContent: 'center',
  margin: 'auto !important',
  maxWidth: '636px',
  borderRadius: '12px',
  w: { xl: '636px', lg: '500px', md: '100%', sm: '100%', xs: '100%' },
  h: { xl: '504px', lg: '460px', md: '100%', sm: '100%', xs: '100%' },
  p: { xl: '80px 100px', lg: '40px 60px', md: '40px 16px', sm: '40px 16px', xs: '16px' },
};

export const msgContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mb: 1,
};

export const circleStyle = {
  width: '96px',
  height: '96px',
  borderRadius: '50%',
  textAlign: 'center',
  verticalAlign: 'middle',
  color: 'yellow.700',
  bg: 'yellow.100',
  mb: '48px',
  lineHeight: '96px',
};

export const circleStyleGreen = {
  width: '96px',
  height: '96px',
  borderRadius: '50%',
  textAlign: 'center',
  verticalAlign: 'middle',
  color: 'green.800AA',
  bg: 'green.100',
  mb: '24px',
  lineHeight: '96px',
};

export const iconCardStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
};

export const textBoldStyle = {
  fontSize: { xl: '4xl', lg: '3xl', md: '2xl', sm: '2xl', xs: 'lg' },
  fontWeight: 'semibold',
  lineHeight: '48px',
  color: 'brand.neutral.700',
  mb: '24px',
};

export const textSmallStyle = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
  textAlign: 'center',
};
