/**
 * Combines all the data that is searchable on the client side in a conveninet way
 * @param {Object} categoryOptions Data representing the possible categories and subcategories
 * @param {Object} propertyOptions Date representing the possible properties and units
 * @returns An object that combines the searchable data in a convenient way
 */

export const compileSearchData = (
  connectedAccountsFilterOptions,
  categoryOptions,
  propertyOptions
) => {
  const updatedData = {};

  if (connectedAccountsFilterOptions?.length) {
    const accountItems = connectedAccountsFilterOptions.reduce((allItems, account) => {
      return allItems.concat(account.items);
    }, []);
    updatedData.accounts = [...accountItems];
  }

  if (propertyOptions?.length) {
    updatedData.properties = [...propertyOptions];
  }

  if (categoryOptions?.length) {
    const categoryItems = categoryOptions.reduce((allItems, category) => {
      return allItems.concat(category.items);
    }, []);
    updatedData.categories = [...categoryItems];
  }

  return updatedData;
};

/**
 * Normalizes a string, to make a text search more likely to succeed
 * @param {string} text The text to normalize
 * @returns The normalized version of the string
 */
const normalize = (text) =>
  text
    ?.split(' ')
    ?.filter((fragment) => fragment !== ' ') // delete multiples of whitespace
    ?.join(' ')
    ?.toLowerCase(); // lowercase to make search case-insensitive

/**
 * Searches for a string within another string, after normalizing both.
 * @param {string} needle The string to look for
 * @param {string} haystack The string to look within
 * @returns Boolean value indicating if the needle was found in the haystack
 */
const compare = (needle, haystack) => {
  const norm1 = normalize(needle);
  const norm2 = normalize(haystack);
  return norm1?.includes(norm2);
};

/**
 * Searches through accounts for a specific term
 * Note: This is currently unused, but keeping it here in case it's needed
 * for future search improvements.
 * @param {string} term The search term to match within accounts
 * @param {Array} accounts A flat array of accounts
 * @returns Ids of matched accounts
 */
// eslint-disable-next-line no-unused-vars
const searchAccounts = (term, accounts) => {
  const matchedAccounts = accounts?.filter(
    (item) => compare(item?.bankName, term) || compare(item?.account, term)
  );
  const ids = matchedAccounts?.map((item) => item?.id);
  return ids;
};

/**
 * Searches through properties for a specific term
 * @param {string} term The search term to match within properties
 * @param {Array} properties A flat array of properties and units
 * @returns Ids of matched properties
 */
const searchProperties = (term, properties) => {
  const matchedProperties = properties?.filter((item) => {
    if (item?.hasChildren) {
      return compare(item?.name, term);
    }
    return compare(item?.searchValues?.join(' '), term);
  });
  const ids = matchedProperties?.map((item) => item?.id);
  return ids;
};

/**
 * Searches through categories for a specific term
 * @param {string} term The search term to match within categories
 * @param {Array} categories A flat array of categories and subcategories
 * @returns
 */
const searchCategories = (term, categories) => {
  const matchedCategories = categories?.filter((item) => {
    if (item?.hasChildren) {
      return compare(item?.name, term);
    }
    return compare(item?.searchValues?.join(' '), term);
  });
  const ids = matchedCategories?.map((item) => item?.id);
  return ids;
};
/**
 * Separate ids and sub-ids, removing main ids if they are only referenced by a child element
 * @param {Array} ids An array of ids, possibling includeing id-subId pairs
 * @returns Separate arrays of ids and sub ids, with hyphens removed
 */
const extractSubIds = (ids) => {
  if (!ids || ids.length === 0) return null;

  const mainIds = [];
  const subIds = [];

  ids.forEach((element) => {
    const [mainId, subId] = element.split('-') || [];
    if (subId && !subIds.includes(subId)) {
      subIds.push(subId);
    } else if (mainId && !mainIds.includes(mainId)) {
      mainIds.push(mainId);
    }
  });

  return { mainIds, subIds };
};

/**
 * Converts [] into null, for backend consumption
 * @param {array} dataArray The array to check for emptiness
 * @returns null if array is empty, original array otherwise
 */
const nullIfEmpty = (dataArray) => {
  if (dataArray && dataArray.length > 0) return dataArray;
  return null;
};

export const emptySearchId = {
  bankAccountId: null,
  propertyId: null,
  unitId: null,
  tagId: null,
};

/**
 * Performs a text search of a search term against all the client-searchable data
 * @param {string} termOriginal The unaltered search term entered by the user
 * @param {Object} clientSearchData Object representing the client-side searchable data
 * @returns Ids of all matched properties and categories
 */
export const clientSearch = (termOriginal, clientSearchData) => {
  const termTrimmed = termOriginal.trim();
  const term = normalize(termTrimmed);

  // stop here on empty search
  if (term.length === 0) return { ...emptySearchId };

  const empty = { mainIds: [], subIds: [] };

  const rawAccountIds = [].concat(searchAccounts(term, clientSearchData?.accounts));
  const rawPropertyIds = [].concat(searchProperties(term, clientSearchData?.properties));
  const rawCategoryIds = [].concat(searchCategories(term, clientSearchData?.categories));

  const bankAccountId = rawAccountIds;
  const { mainIds: propertyId, subIds: unitId } = extractSubIds(rawPropertyIds) || empty;
  const { mainIds: categoryId, subIds: subCategoryId } = extractSubIds(rawCategoryIds) || empty;
  const tagId = [...categoryId, ...subCategoryId];

  return {
    bankAccountId: nullIfEmpty(bankAccountId),
    propertyId: nullIfEmpty(propertyId),
    unitId: nullIfEmpty(unitId),
    tagId: nullIfEmpty(tagId),
  };
};
