// BaseLane: Styles for CashFlow dashboard widget elements
export const currencyStyle = (isDashboard, isMobile) => {
  return {
    textStyle: isMobile ? 'headline-md' : 'headline-2xl',
    color: !isDashboard || (isDashboard && !isMobile) ? 'brand.neutral.700' : 'brand.neutral.white',
    mt: '0px',
  };
};

export const currencyTextStyle = (isDashboard, isMobile) => ({
  ...currencyStyle(isDashboard, isMobile),
  isRounded: true,
  negativeColor: !isDashboard || (isDashboard && !isMobile) ? 'red.500A' : 'brand.neutral.white',
});

export const firstItemStyles = (isMobile) => ({
  pl: !isMobile ? '0' : '16px',
});

export const customBorderStyles = (isDashboard, isMobile) => ({
  borderLeftWidth: isDashboard && !isMobile ? { xl: '1px', lg: '0' } : '0px',
});

export const customFontStyles = (isDashboard, hasNoData, isMobile) => {
  if (!isDashboard || (isDashboard && !isMobile)) {
    return {
      fontWeight: 'normal',
      color: 'brand.neutral.700',
    };
  }
  return {
    fontWeight: 'normal',
    color: hasNoData ? 'brand.neutral.600' : 'brand.darkBlue.300',
  };
};

export const customContentStyles = {
  mt: '4px !important',
};

export const customContainerStyles = (isDashboard) => {
  return {
    justifyContent: isDashboard ? { xl: 'space-between', lg: 'flex-start' } : 'flex-start',
    gap: isDashboard ? { xl: 0, lg: '60px' } : '60px',
  };
};

export const overviewElemStyles = (isDashboard) => {
  return {
    w: 'auto',
    flexBasis: isDashboard ? { xl: '33.33%', lg: 'auto' } : 'auto',
    borderLeftColor: isDashboard
      ? { xl: 'brand.darkBlue.150', lg: 'transparent' }
      : 'brand.darkBlue.150',
    ml: '0 !important',
    p: '0',
    px: isDashboard ? { xl: '24px', lg: '0' } : '0',
    _first: {
      p: '0',
    },
  };
};

export const overviewLabelIndicatorStyles = {
  w: '12px',
  minW: '12px',
  h: '12px',
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const overviewLabelSmallIndicatorStyles = {
  ...overviewLabelIndicatorStyles,
  w: '8px',
  minW: '8px',
  h: '8px',
};

export const titleDividerStyles = {
  h: '24px',
  mx: '16px !important',
};

export const titleNoResultsTextStyles = {
  fontWeight: 'normal',
  m: '0 !important',
};

export const titleStyles = {
  p: '0 !important',
  lineHeight: '24px',
  mb: '16px !important',
};

export const detailsTextStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.700',
  m: '0 !important',
};

export const detailsTextBoldStyles = {
  ...detailsTextStyles,
  fontWeight: 'semibold',
};
