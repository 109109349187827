// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=2925%3A97142
import React from 'react';
import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import {
  sectionSmTitleStyles,
  listItemTextStyles,
  boldTextStyles,
} from '../styles/emptyState.shared.styles';

const ConnectBankAccountList = ({ styles }: { styles?: Object }): any => (
  <Box {...styles}>
    <Text {...sectionSmTitleStyles}>
      You can connect{' '}
      <Box as="span" {...boldTextStyles}>
        Bank Accounts{' '}
      </Box>
      to:
    </Text>
    <UnorderedList>
      <ListItem {...listItemTextStyles}>Collect rent, fees & security deposits</ListItem>
      <ListItem {...listItemTextStyles}>Import transactions</ListItem>
      <ListItem {...listItemTextStyles}>Fund a Baselane Banking account</ListItem>
    </UnorderedList>
  </Box>
);

ConnectBankAccountList.defaultProps = {
  styles: ({}: { ... }),
};

export default ConnectBankAccountList;
