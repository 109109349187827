import React from 'react';
import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { BaselaneButton, TwoFactorVerificationPopUp } from '@core/components/Shared/components';
import { IconCheck, IconChevronLeft, IconChevronRight } from '@core/components/Icons';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useOtpVerification from '../../../../../hooks/useOtpVerification';
import useBankSummary from '../../../../../hooks/useBankSummary';
import useTransfersAccountList from '../../../../../hooks/useTransfersAccountList';

const CheckDepositDrawerFooter = ({ steps, currentStep, isLoading, onNextClicked }) => {
  const navigate = useNavigate();
  const { values, submitForm } = useFormikContext();
  const { banksById } = useBankSummary();
  const { transferAccountsByBankAccountId } = useTransfersAccountList();

  const bankId = transferAccountsByBankAccountId[values.accountId]?.group?.id;

  const { props, isTokenExpired, handleOtpPopupOpen } = useOtpVerification({
    bankId,
    phoneNumber: banksById[bankId]?.unitAccount?.phoneNumber,
    callback: submitForm,
  });

  const closeDrawer = () => {
    navigate(-1);
  };

  const handleDeposit = () => {
    if (isTokenExpired) {
      handleOtpPopupOpen();
    } else {
      submitForm();
    }
  };

  // The component to render in the footer. For each step, the footer will be different.
  let renderFooter = null;

  switch (currentStep) {
    case steps.SIGN_AND_ENDORSE_CHECK:
      renderFooter = (
        <Box display="flex" gap={2} width="100%">
          <BaselaneButton variant="outline" palette="neutral" size="md" onClick={closeDrawer}>
            Cancel
          </BaselaneButton>
          <BaselaneButton
            isDisabled={!values.writeOnBackOfCheck || !values.signTheCheck}
            isFullWidth
            variant="filled"
            palette="primary"
            size="md"
            rightIcon={<IconChevronRight />}
            onClick={() => {
              sendSegmentEvent('baselane_banking_check_deposit_complete_endorse');
              onNextClicked(steps.SCAN_A_CHECK);
            }}
          >
            Next
          </BaselaneButton>
        </Box>
      );
      break;
    case steps.SCAN_A_CHECK:
      renderFooter = (
        <Box display="flex" gap={2} width="100%">
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            onClick={() => onNextClicked(steps.SIGN_AND_ENDORSE_CHECK)}
          >
            Back
          </BaselaneButton>
          <BaselaneButton
            isDisabled={
              !values.frontOfCheck || !values.backOfCheck || !values.amount || !values.accountId
            }
            isFullWidth
            variant="filled"
            palette="primary"
            size="md"
            rightIcon={<IconChevronRight />}
            onClick={() => {
              onNextClicked(steps.BOOKKEEPING_DETAILS);
              sendSegmentEvent('baselane_banking_check_deposit_complete_step1');
            }}
          >
            Next
          </BaselaneButton>
        </Box>
      );
      break;
    case steps.BOOKKEEPING_DETAILS:
      renderFooter = (
        <Box display="flex" gap={2} width="100%">
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            onClick={() => onNextClicked(steps.SCAN_A_CHECK)}
          >
            Back
          </BaselaneButton>
          <BaselaneButton
            isFullWidth
            variant="filled"
            palette="primary"
            size="md"
            rightIcon={<IconChevronRight />}
            onClick={() => {
              onNextClicked(steps.REVIEW_AND_DEPOSIT);
              sendSegmentEvent('baselane_banking_check_deposit_complete_step2');
            }}
          >
            Next
          </BaselaneButton>
        </Box>
      );
      break;
    case steps.REVIEW_AND_DEPOSIT:
      renderFooter = (
        <Box display="flex" gap={2} width="100%">
          <BaselaneButton variant="outline" palette="neutral" size="md" onClick={closeDrawer}>
            Cancel
          </BaselaneButton>
          <BaselaneButton
            type="submit"
            isFullWidth
            variant="filled"
            palette="primary"
            size="md"
            leftIcon={<IconCheck />}
            isLoading={isLoading}
            onClick={handleDeposit}
          >
            Deposit
          </BaselaneButton>
        </Box>
      );
      break;
    case steps.SUCCESS:
      renderFooter = (
        <Box display="flex" gap={2} width="100%">
          <BaselaneButton variant="outline" palette="neutral" size="md" onClick={closeDrawer}>
            Done
          </BaselaneButton>
          <BaselaneButton
            isFullWidth
            variant="tonal"
            palette="primary"
            size="md"
            onClick={() => onNextClicked(steps.SIGN_AND_ENDORSE_CHECK)}
          >
            Deposit Another Check
          </BaselaneButton>
        </Box>
      );
      break;
    default:
      renderFooter = (
        <BaselaneButton variant="outline" leftIcon={<IconChevronLeft />} onClick={closeDrawer}>
          Back
        </BaselaneButton>
      );
      break;
  }

  return (
    <>
      {renderFooter}
      <TwoFactorVerificationPopUp {...props} />
    </>
  );
};

CheckDepositDrawerFooter.propTypes = {
  steps: PropTypes.objectOf(PropTypes.number).isRequired,
  currentStep: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onNextClicked: PropTypes.func.isRequired,
};

export default CheckDepositDrawerFooter;
