import { gql, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import getTransferType from '../helpers/getTransferType';

const useAddRecurringPayment = (xIdempotencyKey) => {
  const { bankAccountsById } = useBankAccountsActive();
  const ADD_RECURRING_PAYMENT = gql`
    mutation AddRecurringPayment($input: CreateRecurringPaymentsInput!) {
      addRecurringPayment(input: $input) {
        id
        internalUserId
        userId
        type
        fromTransferAccountId
        toTransferAccountId
        fromTransferAccount {
          id
          userId
          name
          accountName
          accountNumber
          permission
          amount
          isExternal
          provider
          institution {
            id
          }
          bankAccountId
          isBankConnected
          counterPartyConnectionHistoryCode
        }
        toTransferAccount {
          id
          userId
          name
          accountName
          accountNumber
          permission
          amount
          isExternal
          provider
          institution {
            id
          }
          bankAccountId
          isBankConnected
          counterPartyConnectionHistoryCode
        }
        payeePaymentMethodId
        wireTransferAccount {
          name
          routingNumber
          accountNumber
          recipientAddress {
            street
            unit
            city
            state
            postalCode
            country
          }
          tagId
          propertyId
          unitId
          description
        }
        amount
        startDate
        endDate
        recurrencePattern {
          type
          interval
          monthlyByDate
          lastDayOfMonth
          daysOfMonth
          daysOfWeek
          numberOfPayments
        }
        transfers {
          transferDate
          amount
          actualDate
          isHoliday
          isWeekend
          state
        }
        nextPaymentDate
        lastPaymentDate
        tagId
        propertyId
        unitId
        nextPaymentAmount
        totalAmount
        totalNumberOfPayments
        numberOfPaymentsProcessed
        numberOfCancelledPayments
      }
    }
  `;

  const [mutate, { loading }] = useMutation(ADD_RECURRING_PAYMENT, {
    refetchQueries: ['GetScheduledPayments'],
  });

  const addRecurringPayment = async (values) => {
    // Resolving the propertyId and unitId from the values.
    const [propertyId, unitId] = !values.propertyId
      ? [undefined, undefined]
      : values.propertyId.split('-');

    // Resolving the tagId from the values.
    const tagId = !values.categoryId
      ? undefined
      : values.categoryId.split('-')[values.categoryId.split('-').length - 1]; // Its ugly, in case of "123" or "123-456" it will return "123" or "456" respectively.

    const fromTransferAccount = bankAccountsById[values.transferFromAccountId];

    const depositToAccount = bankAccountsById[values.depositToAccountId];

    const transferType = getTransferType(fromTransferAccount, depositToAccount);

    const { data, errors } = await mutate({
      context: {
        headers: {
          'x-idempotency-key': xIdempotencyKey,
        },
      },
      variables: {
        input: {
          type: transferType,
          fromTransferAccountId: bankAccountsById[values.transferFromAccountId].transferAccountId,
          toTransferAccountId: bankAccountsById[values.depositToAccountId].transferAccountId,
          amount: parseFloat(values.amount.replace(/,/g, '')),
          startDate: DateTime.fromJSDate(values.recurringTransfer.startDate).toISODate(),
          endDate:
            values.recurringTransfer.endBy === 'date'
              ? DateTime.fromJSDate(values.recurringTransfer.endDate).toISODate()
              : undefined,
          recurrencePattern: {
            type: values.recurringTransfer.repeatEvery.unit === 'month' ? 'MONTHLY' : 'WEEKLY',
            interval: values.recurringTransfer.repeatEvery.value,
            monthlyByDate:
              values.recurringTransfer.repeatEvery.unit === 'month' &&
              values.recurringTransfer.repeatOnMonthDate !== 'last'
                ? +values.recurringTransfer.repeatOnMonthDate
                : undefined,
            lastDayOfMonth:
              values.recurringTransfer.repeatEvery.unit === 'month' &&
              values.recurringTransfer.repeatOnMonthDate === 'last',
            daysOfWeek:
              values.recurringTransfer.repeatEvery.unit === 'week'
                ? values.recurringTransfer.repeatOnWeekday.toUpperCase()
                : undefined,
            numberOfPayments:
              values.recurringTransfer.endBy === 'number-of-transfers'
                ? values.recurringTransfer.numberOfTransfers
                : undefined,
          },
          tagId,
          propertyId,
          unitId,
          sameDay: DateTime.now().hasSame(values.recurringTransfer.startDate, 'day'),
          bookKeepingNote: values.notes ? values.notes : undefined,
        },
      },
    });

    if (errors) {
      throw new Error('Errors creating recurring payments: ', errors);
    }

    return data;
  };

  return {
    addRecurringPayment,
    loading,
  };
};

export default useAddRecurringPayment;
