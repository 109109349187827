import React from 'react';

type IconCircleDottedArrowWithBgProps = {
  w?: Number,
  h?: Number,
  color?: String,
  bgColor?: String,
};

function IconCircleDottedArrowWithBg({
  w = 24,
  h = 24,
  color = '#257ED0',
  bgColor = '#0968BF',
}: IconCircleDottedArrowWithBgProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.08" cx="12" cy="12" r="12" fill={bgColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2461 6.45039C11.2461 6.06358 11.5596 5.75 11.9465 5.75C15.4278 5.75 18.25 8.57219 18.25 12.0535C18.25 12.686 18.1566 13.298 17.9824 13.8757C17.973 13.907 17.9615 13.9372 17.9483 13.9662C17.4892 15.4095 16.5113 16.694 15.0959 17.5117C12.4504 19.04 9.15762 18.43 7.21634 16.218L7.12712 16.5511C7.02704 16.9248 6.64301 17.1466 6.26937 17.0465C5.89572 16.9464 5.67394 16.5624 5.77402 16.1887L6.25628 14.3881C6.3436 14.0621 6.651 13.8447 6.98747 13.8711L8.84934 14.0168C9.23497 14.047 9.52313 14.3841 9.49295 14.7697C9.46277 15.1553 9.12568 15.4435 8.74004 15.4133L8.34937 15.3827C9.86837 17.0292 12.3747 17.466 14.3952 16.2988C15.8049 15.4844 16.6576 14.0752 16.8179 12.568C16.8206 12.5429 16.8245 12.5182 16.8297 12.494C16.8426 12.349 16.8492 12.2021 16.8492 12.0535C16.8492 9.34583 14.6542 7.15079 11.9465 7.15079C11.5596 7.15079 11.2461 6.83721 11.2461 6.45039Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9781 7.1496L11.9478 7.15149C11.5618 7.17669 11.2285 6.88422 11.2033 6.49822C11.1781 6.11223 11.4705 5.77888 11.8565 5.75368L11.8952 5.75127C12.2814 5.72839 12.613 6.02288 12.6358 6.40902C12.6587 6.79516 12.3642 7.12673 11.9781 7.1496Z"
        fill={color}
      />
      <path
        d="M9.99379 6.73908C10.2095 7.11275 10.0815 7.59055 9.70783 7.80629C9.33417 8.02203 8.85636 7.894 8.64062 7.52033C8.42489 7.14667 8.55292 6.66886 8.92658 6.45312C9.30025 6.23739 9.77805 6.36542 9.99379 6.73908Z"
        fill={color}
      />
      <path
        d="M7.99382 8.77033C8.20956 9.144 8.08153 9.6218 7.70786 9.83754C7.3342 10.0533 6.85639 9.92525 6.64066 9.55158C6.42492 9.17792 6.55295 8.70011 6.92661 8.48438C7.30028 8.26864 7.77808 8.39667 7.99382 8.77033Z"
        fill={color}
      />
      <path
        d="M7.33754 11.5828C7.55328 11.9565 7.42525 12.4343 7.05158 12.65C6.67792 12.8658 6.20011 12.7377 5.98438 12.3641C5.76864 11.9904 5.89667 11.5126 6.27033 11.2969C6.644 11.0811 7.1218 11.2092 7.33754 11.5828Z"
        fill={color}
      />
    </svg>
  );
}

export default IconCircleDottedArrowWithBg;
