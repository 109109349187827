import React, { Fragment, forwardRef } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
} from '@chakra-ui/react';
import { Icon16ChevronDown, Icon16ChevronUp } from '@icons/16px';
import DropdownContentWrapper from '../../DropdownContentWrapper';

import {
  setIsSelectedItem,
  getChildCounterVisualStyles,
} from '../../helpers/dropdown.shared.helpers';
import { t2Search } from '../../utils/search';
import {
  accordionButtonStyles,
  accordionChildCheckboxContainerStyles,
  accordionItemChildStyles,
  accordionItemContainerStyles,
  accordionPanelStyles,
  accordionToggleContainerStyles,
  accordionToggleCounterContainerStyles,
  accordionToggleIconContainerStyles,
  accordionToggleTextStyles,
  itemContainerStyles,
} from '../../styles/accordion.styles';

type ListOfParentAndChildrenProps = {
  isMulti: boolean,
  options: Array<any>,
  parentItemRenderer: Function,
  childItemRenderer: Function,
  dropdownElementRef: any,
  hasFilterWrapper: Boolean,
  search: String,
  dropDown: Function,
  handleListItemClick: Function,
  selectedStagedOptions: Array,
  childrenSelectAllState: Object,
};

const ListOfParentAndChildren = forwardRef(
  (
    {
      options,
      parentItemRenderer,
      childItemRenderer,
      hasFilterWrapper,
      search,
      dropDown,
      handleListItemClick,
      selectedStagedOptions,
      childrenSelectAllState,
    }: ListOfParentAndChildrenProps,
    ref
  ) => {
    const shouldOpenAccordion = { ...(search ? { index: [0] } : {}) };
    return (
      <DropdownContentWrapper hasFilterWrapper={hasFilterWrapper} ref={ref}>
        {t2Search({ options, search }).reduce((acc, item, index, allItems) => {
          const { id, isChild, hasChildren } = item;
          const parentChildren = hasChildren
            ? allItems.filter((cItem) => cItem.id.includes(id) && cItem.isChild)
            : [];

          if (isChild) {
            return acc;
          }

          acc.push(
            hasChildren ? (
              <Accordion key={item.id} allowMultiple {...shouldOpenAccordion}>
                <AccordionItem border="none">
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton {...accordionButtonStyles}>
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          overflow="hidden"
                          w="100%"
                        >
                          <HStack
                            tabIndex="-1"
                            className="element"
                            key={item.id}
                            id={item.id}
                            onClick={(e) => {
                              e.preventDefault();
                              handleListItemClick(item, e, dropDown);
                            }}
                            bgColor={
                              setIsSelectedItem(item, selectedStagedOptions)
                                ? 'brand.blue.50'
                                : 'initial'
                            }
                            {...accordionItemContainerStyles}
                          >
                            {parentItemRenderer({ item, search })}
                          </HStack>
                        </Box>

                        <HStack
                          {...accordionToggleContainerStyles(
                            search,
                            childrenSelectAllState?.[item.id]
                          )}
                        >
                          <HStack
                            {...getChildCounterVisualStyles(
                              childrenSelectAllState?.[item.id],
                              isExpanded
                            )}
                            {...accordionToggleCounterContainerStyles}
                          >
                            {childrenSelectAllState?.[item.id]?.length > 0 && (
                              <Text {...accordionToggleTextStyles}>
                                {childrenSelectAllState[item.id].length}
                              </Text>
                            )}
                            <HStack {...accordionToggleIconContainerStyles}>
                              {isExpanded ? <Icon16ChevronUp /> : <Icon16ChevronDown />}
                            </HStack>
                          </HStack>
                        </HStack>
                      </AccordionButton>
                      <AccordionPanel {...accordionPanelStyles}>
                        {parentChildren.map((pChild) => (
                          <HStack key={pChild.id} {...accordionChildCheckboxContainerStyles}>
                            <HStack
                              tabIndex="-1"
                              className="element"
                              key={item.id}
                              id={item.id}
                              onClick={(e) => {
                                handleListItemClick(pChild, e, dropDown);
                              }}
                              bgColor={
                                setIsSelectedItem(pChild, selectedStagedOptions)
                                  ? 'brand.blue.50'
                                  : 'initial'
                              }
                              {...accordionItemChildStyles}
                            >
                              {childItemRenderer({ item: pChild, search })}
                            </HStack>
                          </HStack>
                        ))}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            ) : (
              <HStack key={item.id} {...itemContainerStyles}>
                <HStack
                  tabIndex="-1"
                  className="element"
                  key={item.id}
                  id={item.id}
                  onClick={(e) => {
                    handleListItemClick(item, e, dropDown);
                  }}
                  bgColor={
                    setIsSelectedItem(item, selectedStagedOptions) ? 'brand.blue.50' : 'initial'
                  }
                  {...accordionItemChildStyles}
                >
                  {parentItemRenderer({ item, search })}
                </HStack>
              </HStack>
            )
          );

          return acc;
        }, [])}
      </DropdownContentWrapper>
    );
  }
);

export default ListOfParentAndChildren;
