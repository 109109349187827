import React from 'react';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

const FormDescription = ({ children, styles }: { children: any, styles?: Object }) => (
  <Box
    lineHeight="20px"
    fontSize={isMobile ? 'xs' : 'sm'}
    color={isMobile ? 'brand.neutral.600' : 'brand.neutral.500'}
    mb={isMobile ? '0' : '32px'}
    mr={isMobile ? '0' : '32px'}
    p={isMobile ? '4px 24px 8px' : '0'}
    fontWeight={isMobile ? 'normal' : 'inherit'}
    {...styles}
  >
    {children}
  </Box>
);

FormDescription.defaultProps = {
  styles: {},
};

export default FormDescription;
