import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import {
  Placeholder,
  DisplayInputWrapperWithRightButton,
} from '../../../BaselaneDropdown-new/DisplayInputVariations';
import { truncatedText } from '../../../../styles/text.style';

type DropDownDisplayInputProps = {
  selectedOptions: Array<Object>,
  handleContentRendererClick: Function,
  handleClearClick: Function,
  placeholder: string,
  title: string,
  children: React.Node,
  classNames: Array<string>,
};

const DropDownDisplayInput = ({
  selectedOptions,
  handleContentRendererClick,
  handleClearClick,
  placeholder,
  title,
  classNames,
}: DropDownDisplayInputProps) => {
  return (
    <DisplayInputWrapperWithRightButton
      handleContentRendererClick={handleContentRendererClick}
      handleClearClick={handleClearClick}
      selectedOptions={selectedOptions}
      classNames={classNames}
    >
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder placeholder={placeholder} title={title} />
      ) : (
        <HStack color="brand.neutral.500" overflow="hidden">
          <Text color="brand.neutral.900" {...truncatedText}>
            {selectedOptions[0].showValueName ?? selectedOptions[0].name}
          </Text>
        </HStack>
      )}
    </DisplayInputWrapperWithRightButton>
  );
};

export default DropDownDisplayInput;
