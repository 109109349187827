/* eslint-disable no-param-reassign */
import React from 'react';
import moment from 'moment';
import { cloneDeep, orderBy, unset } from 'lodash';
import IconSingleFamilyHouse from '@icons/legacy/IconSingleFamilyHouse';
import IconApartment from '@icons/legacy/IconApartment';
import IconDuplexTriplex from '@icons/legacy/IconDuplexTriplex';
import IconTownhome from '@icons/legacy/IconTownhome';
import IconOther from '@icons/legacy/IconOther';
import formatTitleCase from '@core/utils/formatTitleCase';
import stripCurrency from '@core/utils/stripCurrency';

export const formValidation = (values, setDetailsComplete) => {
  const errors = {};

  if (values.type === '') {
    errors.type = 'Please select property type';
  }
  if (values.propertyName?.trim() === '') {
    errors.propertyName = 'Please enter property name';
  }
  if (values.address?.trim() === '') {
    errors.address = 'Please enter address';
  }
  if (values.city?.trim() === '') {
    errors.city = 'Please enter city';
  }
  if (values.state === '') {
    errors.state = 'Please enter state';
  }
  if (values.zipcode === '') {
    errors.zipcode = 'Please enter zipcode';
  }
  if (values.zipcode !== '' && values.zipcode?.length < 5) {
    errors.zipcode = 'Please enter 5-digit zip code';
  }
  if (values.type === 'other' && values.customPropertyType === '') {
    errors.customPropertyType = 'Please enter custom property type';
  }
  if (values.unitNumber?.length > 0 && values.unitNumber?.trim()?.length === 0) {
    errors.unitNumber = 'Unit number cannot be left blank';
  }

  const indexOfIncompleteUnitName = values.unitNames?.findIndex((unit) => unit.name?.trim() === '');
  if (values.unitType === 'multiple' && indexOfIncompleteUnitName !== -1) {
    errors.unitNames = 'Please enter all unit names';
  }
  if (Object.keys(errors).length === 0) {
    setDetailsComplete(true);
  } else {
    setDetailsComplete(false);
  }

  return errors;
};

export const typeMapper = {
  condo: 'Condo', // TODO: Delete Condo once things are more stable
  singlefh: 'Single Family Home',
  apartment: 'Apartment',
  duplex: 'Duplex/Triplex',
  townhome: 'Townhome',
  other: 'Other',
};

export const propertyTypes = [
  'Condo', // TODO: Delete Condo once things are more stable
  'Single Family Home',
  'Apartment',
  'Duplex/Triplex',
  'Townhome',
];

const getPropertyType = (isKey, data) => {
  if (isKey) {
    return propertyTypes.indexOf(data) === -1
      ? 'other'
      : Object.keys(typeMapper).find((key) => {
          return typeMapper[key] === data;
        });
  }
  return typeMapper[data];
};

export const propertyTypeOptions = [
  { value: 'singlefh', label: 'Single Family Home', icon: <IconSingleFamilyHouse /> },
  { value: 'apartment', label: 'Apartment', icon: <IconApartment /> },
  { value: 'duplex', label: 'Duplex/Triplex', icon: <IconDuplexTriplex /> },
  { value: 'townhome', label: 'Townhome', icon: <IconTownhome /> },
  { value: 'other', label: 'Other', icon: <IconOther /> },
];

export const zillowLinkMapper = {
  terms_of_use: 'https://www.zillow.com/z/corp/terms/',
  zestimate: 'https://www.zillow.com/z/zestimate/',
};

export const formatValuesForAPI = (values, property) => {
  const { id = '', units: unitsData = [] } = property || {};
  const {
    type,
    customPropertyType,
    propertyName: name,
    unitNames,
    address: streetAddress,
    city,
    state,
    zipcode,
    unitType,
    unitNumber,
    purchasePrice,
    closingCosts,
    mortgageBalance,
    mortgageDownpayment,
    mortgageTerm,
    mortgagePrincipleBalance,
    mortgageInterestRate,
    annualPropertyTaxes,
    annualPropertyInsurance,
    ...restOfValues
  } = values;

  let units = unitNames.map((unit) => {
    delete unit.hasLease;
    delete unit.isUnitDeletable;
    return unit;
  });

  if (property) {
    const ids = unitNames.map((unit) => unit.id);

    unitsData.forEach((unitD) => {
      const unitTemp = { id: unitD.id, status: unitD.status, name: unitD.name };
      if (!ids.includes(unitD.id) && unitD.isUnitDeletable) {
        unitTemp.isDelete = true;
        units.push(unitTemp);
      }
    });
  }

  if (unitType === 'single' && !property) {
    units = [{ name }];
  } else if (unitType === 'single' && unitNames.length > 1) {
    units = [{ id: unitNames[0].id, name }];
  }

  const address = {
    address: formatTitleCase(streetAddress.trim()),
    city: formatTitleCase(city.trim()),
    state,
    zipCode: zipcode.toString(),
    unit: unitNumber,
  };

  const propertyType = type === 'other' ? customPropertyType : typeMapper[type];

  const formattedPurchasePrice = !purchasePrice ? null : purchasePrice;
  const formattedClosingCosts = !closingCosts ? null : closingCosts;
  const formattedAnnualPropertyTaxes = !annualPropertyTaxes ? null : annualPropertyTaxes;
  const formattedAnnualPropertyInsurance = !annualPropertyInsurance
    ? null
    : annualPropertyInsurance;

  const formattedMortgageDownpayment = !mortgageDownpayment ? null : mortgageDownpayment;
  const formattedMortgageTerm = !mortgageTerm ? null : mortgageTerm;
  const formattedMortgagePrincipleBalance = !mortgagePrincipleBalance
    ? null
    : stripCurrency(mortgagePrincipleBalance);
  const formattedMortgageInterestRate = !mortgageInterestRate
    ? null
    : stripCurrency(mortgageInterestRate);
  const formattedMortgageBalance = [
    {
      date: moment.utc().format(),
      balance: formattedMortgagePrincipleBalance,
      interestRate: formattedMortgageInterestRate,
    },
  ];

  if (property) {
    return {
      id,
      type: propertyType,
      name,
      units,
      address,
      mortgageBalance,
      purchasePrice: formattedPurchasePrice,
      closingCosts: formattedClosingCosts,
      mortgageDownpayment: formattedMortgageDownpayment,
      mortgageTerm: formattedMortgageTerm,
      annualPropertyTaxes: formattedAnnualPropertyTaxes,
      annualPropertyInsurance: formattedAnnualPropertyInsurance,
      ...restOfValues,
    };
  }
  return {
    type: propertyType,
    name,
    units,
    address,
    purchasePrice: formattedPurchasePrice,
    closingCosts: formattedClosingCosts,
    mortgageBalance: formattedMortgageBalance,
    mortgageTerm: formattedMortgageTerm,
    mortgageDownpayment: formattedMortgageDownpayment,
    annualPropertyTaxes: formattedAnnualPropertyTaxes,
    annualPropertyInsurance: formattedAnnualPropertyInsurance,
    ...restOfValues,
  };
};

export const getInitialValues = (property, isShortForm) => {
  if (property) {
    const {
      id,
      type,
      name,
      address,
      units,
      purchasePrice,
      purchaseDate,
      closingCosts,
      mortgageDownpayment,
      marketPrice,
      mortgageBalance,
      mortgageTerm,
      annualPropertyTaxes,
      annualPropertyInsurance,
    } = property;

    const {
      address: streetAddress = '',
      city = '',
      state = '',
      zipCode = '',
      unit: unitNumber = '',
    } = address || {};

    let unitNames = units?.map((unit) => {
      const { id: unitId, name: unitName, status } = unit;
      const hasLease = status === 'occupied';
      const isUnitDeletable = unit?.isUnitDeletable;
      return { id: unitId, name: unitName, hasLease, isUnitDeletable };
    });

    unitNames = orderBy(unitNames, ['name'], ['asc']);

    const unitType = !units || units?.length <= 1 ? 'single' : 'multiple';
    const propertyType = getPropertyType(true, type);

    const formattedPurchaseDate = purchaseDate ? new Date(purchaseDate) : purchaseDate;
    const formattedMortgageTerm = mortgageTerm || '';
    const mortgageInterestRate = mortgageBalance != null ? mortgageBalance[0]?.interestRate : null;
    const mortgagePrincipleBalance = mortgageBalance != null ? mortgageBalance[0]?.balance : null;

    return {
      id,
      type: propertyType,
      customPropertyType: propertyType.toLowerCase() === 'other' ? type : '',
      propertyName: name,
      address: streetAddress,
      city,
      state,
      zipcode: zipCode.toString(),
      unitNames,
      unitType,
      unitNumber,
      purchasePrice,
      purchaseDate: formattedPurchaseDate,
      closingCosts,
      mortgageDownpayment,
      marketPrice,
      mortgageBalance,
      mortgageInterestRate,
      mortgagePrincipleBalance,
      mortgageTerm: formattedMortgageTerm,
      annualPropertyTaxes,
      annualPropertyInsurance,
    };
  }

  return {
    type: isShortForm ? 'singlefh' : '',
    propertyName: '',
    customPropertyType: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    unitNames: [{ name: '' }],
    unitType: 'single',
    unitNumber: '',
    purchasePrice: '',
    purchaseDate: null,
    closingCosts: '',
    mortgageDownpayment: '',
    marketPrice: [],
    mortgageBalance: [],
    mortgageInterestRate: '',
    mortgagePrincipleBalance: '',
    mortgageTerm: '',
    annualPropertyTaxes: '',
    annualPropertyInsurance: '',
  };
};

export const isAddressComplete = (address) => {
  const { unit, ...rest } = address;
  return Object.values(rest).indexOf('') === -1;
};

export const getAddressObject = (values) => ({
  address: values.address,
  city: values.city,
  state: values.state,
  zipCode: values.zipcode,
  unit: values.unitNumber,
});

export const setUnitNames = (
  initialValues,
  values,
  property,
  type,
  setFieldValue,
  setFieldTouched,
  setDetailsComplete
) => {
  if (property) {
    if (type === 'single') {
      setFieldValue('unitNames', initialValues.unitNames);
    } else if (type === 'multiple' && property.units.length === 1) {
      setFieldValue('unitNames', [
        { name: property.units[0].name, id: property.units[0].id },
        { name: 'Unit B' },
      ]);
      setFieldTouched('unitNames', true);
    }
  } else if (type === 'single') {
    setFieldValue('unitNames', [
      {
        name: values.propertyName,
      },
    ]);
  } else {
    setDetailsComplete(false);
    setFieldValue('unitNames', [{ name: 'Unit A' }, { name: 'Unit B' }]);
    setFieldTouched('unitNames', true);
  }
};

export const handlePropertyUpdate = ({
  newProperty,
  updateProperty,
  setVariables,
  variables,
  handleOnShowSuccessToast,
  handleOnShowErrorToast,
  onClose,
}) => {
  updateProperty({
    variables: newProperty,
    update(cache) {
      const allData = cloneDeep(cache.data.data);
      const oldProperty = allData[`Property:${newProperty.id}`];
      const updatedProperty = { ...oldProperty, ...newProperty };
      setVariables(variables);
      // eslint-disable-next-line no-param-reassign
      return (allData[`Property:${newProperty.id}`] = updatedProperty); // eslint-disable-line no-return-assign
    },
  })
    .then((res) => {
      if (!res.data?.updateProperty && res.errors?.length > 0) {
        handleOnShowErrorToast();
      } else {
        handleOnShowSuccessToast(false);
        onClose();
      }
    })
    .catch(() => handleOnShowErrorToast());
};

export const handlePropertySave = ({
  property,
  variables,
  createProperty,
  updateProperty,
  handleOnShowSuccessToast = () => {},
  handleOnShowErrorToast = () => {},
  successDrawerRef,
  setNewProperty,
  setVariables,
  isInUnifiedRCFlow,
  isInUnifiedBAFlow,
  shortForm,
  onPropertyUnitSelected = () => {},
  setShortForm,
  onClose = () => {},
  cleanUp,
}) => {
  if (property) {
    const newProperty = formatValuesForAPI(variables, property);
    handlePropertyUpdate({
      newProperty,
      updateProperty,
      setVariables,
      variables,
      handleOnShowSuccessToast,
      handleOnShowErrorToast,
      onClose,
    });
  } else {
    const formattedVariables = formatValuesForAPI(variables);
    if (variables.id) {
      handlePropertyUpdate({
        newProperty: formattedVariables,
        updateProperty,
        setVariables,
        variables,
        handleOnShowSuccessToast,
        handleOnShowErrorToast,
        onClose,
      });
    } else {
      createProperty({ variables: formattedVariables })
        .then(({ data: { createProperty: createPropertyRes }, errors }) => {
          if (successDrawerRef?.current) {
            setNewProperty(createPropertyRes);
            successDrawerRef.current.open();
          }

          if (setVariables) {
            setVariables((prevState) => ({
              ...prevState,
              id: createPropertyRes.id,
              units: createPropertyRes.units?.map((unit) => {
                const tempUnit = { ...unit };
                unset(tempUnit, '__typename');

                return tempUnit;
              }),
            }));
          }

          if (shortForm || isInUnifiedRCFlow || isInUnifiedBAFlow) {
            onPropertyUnitSelected(createPropertyRes, createPropertyRes.units[0]);
            setShortForm(false);
          }

          if (!createPropertyRes && errors?.length > 0) {
            handleOnShowErrorToast();
          } else {
            handleOnShowSuccessToast(true);
            onClose();
          }
        })
        .catch(() => handleOnShowErrorToast());
    }
  }
  cleanUp();
};

export const handleValidateSave = (formRef, save) => {
  formRef?.current?.validateForm().then((errors) => {
    formRef?.current.setTouched(errors, true);
    if (Object.keys(errors).length === 0) {
      save();
    }
  });
};

export const setFormData = (newVariables, setVariables, variables) => {
  setVariables({
    ...variables,
    ...newVariables,
  });
};
