import React from 'react';
import { Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import BaselaneSummaryCardDefaultHeader from './BaselaneSummaryCardDefaultHeader';
import { baselaneSummaryCardStyles } from '../styles/baselaneSummaryCard.styles';

type BaselaneSummaryCardAmountHeaderProps = {
  title: string | React.Node,
  subtitle?: string | React.Node,
  prefix?: string | React.Node,
  addon?: React.Node,
  amount: string | Number | React.Node,
  styles?: Object,
};

const BaselaneSummaryCardAmountHeader = ({
  title,
  subtitle,
  prefix,
  addon,
  amount,
  styles,
}: BaselaneSummaryCardAmountHeaderProps) => {
  const { amountStyles } = baselaneSummaryCardStyles;

  return (
    <BaselaneSummaryCardDefaultHeader
      {...{
        title,
        subtitle,
        prefix,
        addon,
        rightElem: (
          <Text {...{ ...amountStyles, ...styles?.amount }}>
            {formatCurrency(amount).inDollars}
          </Text>
        ),
      }}
    />
  );
};

BaselaneSummaryCardAmountHeader.defaultProps = {
  subtitle: null,
  prefix: null,
  addon: null,
  styles: {},
};

export default BaselaneSummaryCardAmountHeader;
