import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

/**
 * This overrides Chakra's default <Accordion /> styles.
 *
 * see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/accordion.ts for implementation details
 */

const parts = ['root', 'container', 'button', 'panel', 'icon'];
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Accordion = defineMultiStyleConfig({
  baseStyle: {
    panel: {
      pt: 1,
      px: 2,
      pb: 2.5,
    },
    button: {
      px: 2,
      py: 1,
    },
  },
});
