import React from 'react';

type IconKeyThinProps = { w?: Number, h?: Number, color?: string };

function IconKeyThin({ w, h, color }: IconKeyThinProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4444 6.33328c0-.45495-.1735-.90991-.5207-1.25703-.3471-.34713-.8021-.52069-1.257-.52069m0 7.11114C14.6122 11.6667 17 9.27885 17 6.33333 17 3.38781 14.6122 1 11.6667 1 8.72115 1 6.33333 3.38781 6.33333 6.33333c0 .24327.01629.48274.04783.71737.05189.3859.07783.57886.06037.70094-.01819.12716-.04135.19569-.10403.30783-.06017.10764-.1662.21368-.37827.42575L1.41656 13.0279c-.15374.1537-.2306.2306-.28557.3203-.04874.0795-.08466.1662-.10643.2569C1 13.7074 1 13.8161 1 14.0335v1.5443c0 .4978 0 .7467.09688.9369.08522.1672.22121.3032.38846.3884C1.67549 17 1.9244 17 2.42222 17h2.13334v-1.7778h1.77777v-1.7778h1.77778l1.40367-1.4036c.21207-.2121.31811-.3181.42575-.3783.11217-.0627.18067-.0858.30787-.104.122-.0175.315.0085.7009.0603.2346.0316.4741.0479.7174.0479Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconKeyThin.defaultProps = {
  w: 18,
  h: 18,
  color: 'currentColor',
};

export default IconKeyThin;
