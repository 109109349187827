import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Stack, Text, Spacer, HStack } from '@chakra-ui/react';
import { BaselaneCardNew } from '@shared/components';
import SlLoader from '@core/components/Loader';
import { titleStyle, overviewLabelStyle, overviewValueStyle } from './styles/summary.style';
import { GET_TENANT_ACTIVE_PROFILES_NUMBER } from './queries';

function Summary() {
  const { loading: tenantLoading, data: tenants } = useQuery(GET_TENANT_ACTIVE_PROFILES_NUMBER);
  const { numberOfActiveTenants = 0 } = tenants?.landLordTenantSummary ?? {};

  return (
    <BaselaneCardNew
      {...{
        px: 3,
        py: 2,
        borderColor: 'brand.darkBlue.250',
      }}
    >
      <Stack direction="row" width="100%" h="40px">
        <Text {...titleStyle}>Summary</Text>
        <Spacer />
        <HStack>
          <Box {...overviewLabelStyle}>Invited Tenants</Box>
          <Box {...overviewValueStyle}>{tenantLoading ? <SlLoader /> : numberOfActiveTenants}</Box>
        </HStack>
      </Stack>
    </BaselaneCardNew>
  );
}

export default Summary;
