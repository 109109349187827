import React from 'react';

type IconArrowRightTwoProps = {
  w?: number,
  h?: number,
  color?: string,
};

const IconArrowRightTwo = ({ w = 17, h = 14, color = '#fff' }: IconArrowRightTwoProps) => {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57481.577574c.32543-.325437.85309-.325437 1.17849 0l5.8334 5.833336c.3254.32543.3254.85307 0 1.17851l-5.8334 5.83338c-.3254.3254-.85306.3254-1.17849 0-.32544-.3255-.32544-.8531 0-1.1786l4.41079-4.4107H.997396c-.460237 0-.833334-.3731-.833334-.83334 0-.46023.373097-.83333.833334-.83333H13.9856L9.57481 1.75609c-.32544-.32544-.32544-.853079 0-1.178516Z"
        fill={color}
      />
    </svg>
  );
};

IconArrowRightTwo.defaultProps = {
  w: 17,
  h: 14,
  color: '#fff',
};

export default IconArrowRightTwo;
