import React from 'react';
import { IllustrationBookkeeping } from '@illustrations';

export const getTrackerData = () => {
  const trackerData = Object.freeze({
    title: 'Bookkeeping',
    icon: <IllustrationBookkeeping />,
    elements: {
      ADD_PROPERTY: {
        title: 'Add a property',
        not_started: {
          title: 'Add a property',
          description: 'Let’s get started by adding your first rental property.',
          linkText: 'Add Property',
          hasNotStarted: true,
        },
        in_progress: {
          title: 'Add a property',
          description: 'Let’s get started by adding your first rental property.',
          linkText: 'Add Property',
        },
        completed: {
          title: 'Add a property',
        },
      },
      IMPORT_TRANSACTIONS: {
        title: 'Import your transactions',
        not_started: {
          title: 'Import your transactions',
          description:
            'Open a Baselane Banking account or connect an External account to auto import transactions.',
          linkText: 'Add Account',
          hasNotStarted: true,
        },
        in_progress: {
          title: 'Import your transactions',
          description:
            'Open a Baselane Banking account or connect an External account to auto import transactions.',
          linkText: 'Add Another Account',
        },
        completed: {
          title: 'Import your transactions',
        },
      },
      CATEGORIZE_FIRST_TRANSACTION: {
        title: 'Categorize your first transaction',
        not_started: {
          title: 'Categorize your first transaction',
          description:
            'Track income and expenses by Schedule E categories, view income statement and cash flow. ',
          linkText: 'Categorize Transactions',
          hasNotStarted: true,
        },
        completed: {
          title: 'Categorize your first transaction',
        },
      },
    },
  });

  return trackerData;
};

export const getLinkStatus = (data) => {
  const { ADD_PROPERTY, IMPORT_TRANSACTIONS, CATEGORIZE_FIRST_TRANSACTION } = data;
  const {
    not_started: propertyNotStartedLink,
    in_progress: propertyInProgLink,
    completed: propertyCompletedLink,
  } = ADD_PROPERTY;
  const {
    not_started: importTrxsNotStartedLink,
    in_progress: importTrxsInProgressLink,
    completed: importTrxsCompletedLink,
  } = IMPORT_TRANSACTIONS;
  const {
    not_started: categorizeTrxsNotStartedLink,
    completed: categorizeTrxsCompletedLink,
  } = CATEGORIZE_FIRST_TRANSACTION;

  const linkStatus = Object.freeze({
    ADD_PROPERTY: {
      not_started: propertyNotStartedLink,
      in_progress: propertyInProgLink,
      completed: propertyCompletedLink,
    },
    IMPORT_TRANSACTIONS: {
      not_started: importTrxsNotStartedLink,
      in_progress: importTrxsInProgressLink,
      completed: importTrxsCompletedLink,
    },
    CATEGORIZE_FIRST_TRANSACTION: {
      not_started: categorizeTrxsNotStartedLink,
      completed: categorizeTrxsCompletedLink,
    },
  });

  return linkStatus;
};
