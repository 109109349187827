import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { truncatedText } from '@shared/styles/text.style';
import { containerStyles } from '@shared/components/BaselaneDropdown-new/DisplayInputVariations/Placeholders/styles/placeholder.styles';

type PlaceholderProps = {
  placeholder: string,
  title: string,
  children?: React.Node,
};

const Placeholder = ({ placeholder, title, children = null, ...rest }: PlaceholderProps) => {
  return (
    <HStack className="placeholder-container" {...containerStyles} {...rest}>
      {children}
      <Text {...truncatedText}>{placeholder ?? title}</Text>
    </HStack>
  );
};

export default Placeholder;
