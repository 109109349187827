import { isMobile } from 'react-device-detect';
import customTheme from '@core/theme';

export const subTitleTextStyles = {
  textStyle: 'sm',
  color: customTheme.colors.brand.neutral['600'],
};

export const rowStyles = (isInline) => {
  const mobileAlignStyles = isInline ? { align: 'center' } : { align: 'flex-start' };

  return isMobile
    ? { ...mobileAlignStyles, justify: 'flex-start', spacing: 2, width: '100%' }
    : { align: 'flex-start', spacing: 2 };
};
