import customTheme from '@core/theme';

export const tooltipInnerWrapperStyles = {
  spacing: 0,
  align: 'center',
  justifyContent: 'center',
  w: '40px',
  h: '40px',
  p: '8px',
  _hover: {
    bgColor: customTheme.colors.brand.blue['100'],
    color: customTheme.colors.brand.blue['700'],
  },
  borderRadius: '4px',
};
