const defaultLinks = [
  {
    linkText: 'Baselane’s Business Deposit Account Agreement',
    href: 'https://www.baselane.com/baselane-business-deposit-account-agreement/',
  },
  {
    linkText: 'Electronic Signature Disclosure and Consent',
    href: 'https://www.baselane.com/electronic-signature-disclosure-and-consent/',
  },
  {
    linkText: 'Business Visa Debit Cardholder Agreement',
    href: 'https://www.baselane.com/baselane-business-visa-debit-cardholder-agreement/',
  },
  {
    linkText: 'Cash Back Agreement',
    href: 'https://www.baselane.com/baselane-cash-back-agreement/',
  },
];

const savingsLink = {
  linkText: 'Baselane’s Business Savings Account Agreement',
  href: 'https://www.baselane.com/baselane-business-savings-account-agreement/',
};

export const links = (isSavings = false) =>
  defaultLinks.map((item, index) => {
    if (isSavings && index === 0) return savingsLink;
    return item;
  });
