import { defineStyleConfig, defineStyle } from '@chakra-ui/react';

const getPadding = (size) => {
  switch (size) {
    case 'lg':
      return 3;
    case 'md':
      return 2;
    case 'sm':
    default:
      return 1.5;
  }
};

const palettes = {
  light: defineStyle({
    bgColor: 'brand.neutral.white',
  }),
  dark: defineStyle({
    bgColor: 'brand.darkBlue.50',
  }),
  empty: defineStyle({
    height: 'auto',
    borderStyle: 'dashed',
    borderColor: 'brand.darkBlue.200',
    background: 'brand.neutral.50',
  }),
  primary: defineStyle({
    borderColor: 'brand.blue.200',
    background: 'brand.blue.50',
  }),
};

export const sizes = {
  sm: defineStyle({
    p: getPadding('sm'),
  }),
  md: defineStyle({
    p: getPadding('md'),
  }),
  lg: defineStyle({
    p: getPadding('lg'),
  }),
};

const baseStyle = (props) => ({
  p: props.size,
  borderRadius: '8px',
  borderColor: 'brand.darkBlue.200',
  borderStyle: 'solid',
  borderWidth: '1px',
  display: 'flex',
  flexDirection: 'row',
  textStyle: 'md',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const baseStyleClickable = (props) => ({
  h: 'auto',
  w: 'full',
  textAlign: 'left',
  _hover: {
    borderColor: 'brand.darkBlue.250',
    background: 'brand.darkBlue.50',
  },
  _active: {
    borderColor: 'brand.darkBlue.250',
    background: 'brand.darkBlue.100',
  },
  _focusVisible: {
    borderColor: 'brand.darkBlue.250',
    background: 'brand.darkBlue.50',
    outlineColor: 'brand.blue.800A',
    outlineOffset: '1px',
    outlineStyle: 'dashed',
  },
});

const inner = (props) => ({
  ml: -getPadding(props.size),
  mt: -getPadding(props.size),
  mb: -getPadding(props.size),
  mr: -getPadding(props.size),
  w: 'full',
  h: 'full',
  flexGrow: 1,
  border: 'none',
  borderRadius: '0px',
});

const variants = {
  normal: {},
  inner,
  clickable: (props) => baseStyleClickable(props),
  innerClickable: (props) => ({
    ...inner(props),
    ...baseStyleClickable(props),
  }),
};
export const BaselaneCard = defineStyleConfig({
  baseStyle,
  sizes,
  variants,
  palettes, // this is a custom config prop, not part of Chakra
  defaultProps: {
    variant: 'normal',
    size: 'md',
  },
});
