import { gql } from '@apollo/client';

export const FRAGMENT_TRX_ITEM_DETAIL = gql`
  fragment trxItemDetail on Transaction {
    id
    tagId
    date
    propertyId
    unitId
    note
  }
`;

export const GET_TRANSACTIONS = gql`
  query Transactions($input: SortsAndFilters) {
    transaction(input: $input) {
      ...trxItemDetail
      description
      bankAccountId
      bankPartner
      amount
      isCredit
      authorizedDate
      accountOwner
      currencyCode
      location {
        address
        city
        country
        lat
        lon
        postal_code
        region
        store_number
      }
      merchantName
      name
      paymentChannel
      pending
      createdAt
      updatedAt
      time
      hidden
      isDeleted
      isExternal
      isManual
      isSplit
      parentId
      originalTransaction
      isDocumentUploaded
      parentTransaction {
        id
        isSplit
        hidden
        isDocumentUploaded
        amount
        merchantName
        propertyId
        unitId
        tagId
        date
      }
    }
  }
  ${FRAGMENT_TRX_ITEM_DETAIL}
`;

export const UPDATE_TRANSACTIONS = gql`
  mutation UpdateTransaction($input: [UpdateTransaction!]) {
    updateTransactions(input: $input) {
      ...trxItemDetail
      description
      bankAccountId
      bankPartner
      amount
      isCredit
      authorizedDate
      accountOwner
      currencyCode
      location {
        address
        city
        country
        lat
        lon
        postal_code
        region
        store_number
      }
      merchantName
      name
      paymentChannel
      pending
      createdAt
      updatedAt
      time
      hidden
      isDeleted
      isExternal
      isManual
      isSplit
      parentId
      originalTransaction
      isDocumentUploaded
      parentTransaction {
        id
        isSplit
        hidden
        isDocumentUploaded
        amount
        merchantName
        propertyId
        unitId
        tagId
        date
      }
    }
  }
  ${FRAGMENT_TRX_ITEM_DETAIL}
`;

export const GET_TRANSACTIONS_SUMMARY = gql`
  query TransactionsSummary($input: TransactionSummaryFilter) {
    transactionSummary(input: $input) {
      totalUncategorized {
        amount
        count
        uncategorizedHiddenTxCount
        absoluteAmount
      }
      mtdUncategorized {
        amount
        count
        uncategorizedHiddenTxCount
      }
      total {
        count
        amount
        categorizedHiddenTxCount
        uncategorizedHiddenTxCount
      }
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation createTransaction(
    $merchantName: String!
    $note: String!
    $tagId: ID
    $propertyId: ID
    $unitId: ID
    $date: String!
    $bankAccountId: ID
    $amount: Float!
  ) {
    createTransaction(
      input: {
        merchantName: $merchantName
        note: $note
        tagId: $tagId
        propertyId: $propertyId
        unitId: $unitId
        date: $date
        bankAccountId: $bankAccountId
        amount: $amount
      }
    ) {
      merchantName
      note
      tagId
      propertyId
      unitId
      date
      bankAccountId
      amount
      id
      isManual
    }
  }
`;

export const GET_SLIM_TRANSACTIONS = gql`
  query Transactions($input: SortsAndFilters) {
    transaction(input: $input) {
      id
      bankAccountId
      amount
      tagId
      isCredit
      date
      name
      merchantName
      note
      propertyId
      hidden
      isDocumentUploaded
      isManual
    }
  }
`;
