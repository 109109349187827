// @flow
import React, { useEffect, useRef, useState } from 'react';
import { BaselaneResponsiveTableRow, BaselaneResponsiveCellTitle } from '@shared/components';
import { EditManualDrawer } from '../../../EditAccountDrawer';

type AccountProps = {
  account: Object,
  refetchConnectedSelfOwned: Function,
};

function Account({ account, refetchConnectedSelfOwned }: AccountProps): any {
  const editManualAccDrawerRef = useRef(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { id, nickName, routingNumber } = account ?? {};

  useEffect(() => {
    // opening and closing the drawer
    if (isDrawerOpen) {
      editManualAccDrawerRef?.current?.open();
    } else {
      editManualAccDrawerRef?.current?.close();
    }
  }, [isDrawerOpen]);

  return (
    <>
      <BaselaneResponsiveTableRow id={id} onClick={() => setIsDrawerOpen(true)}>
        {/* Account  */}
        <BaselaneResponsiveCellTitle
          title={nickName}
          subtitle={`Routing number: ${routingNumber}`}
          configIndex={0}
        />
      </BaselaneResponsiveTableRow>
      <EditManualDrawer
        {...{
          account,
          refetch: refetchConnectedSelfOwned,
          accountEditDrawerRef: editManualAccDrawerRef,
          onClose: () => setIsDrawerOpen(false),
        }}
      />
    </>
  );
}

export default Account;
