export const cardStyles = (isMobile) => ({
  w: isMobile ? 'auto' : '320px',
  h: isMobile ? '100%' : '200px',
  borderWidth: isMobile ? '100%' : '1px',
  borderBottom: isMobile && '1px solid #E5E9EF',
  borderColor: !isMobile && 'brand.darkBlue.150',
  boxShadow: !isMobile && '1px 2px 6px rgba(3, 24, 64, 0.04)',
  p: isMobile ? '16px' : '24px 16px',
  m: '0 !important',
  borderRadius: isMobile ? '0' : '4px',
  position: 'relative',
  _hover: {
    cursor: 'default',
  },
});

export const cardContainerStyles = (isMobile) => ({
  m: '0 !important',
  _notFirst: { ml: !isMobile && '16px !important ' },
});

export const cardContainerCheckedStyles = {
  background: 'none',
  color: 'brand.neutral.500',
  borderColor: 'none',
  boxShadow: 'none',
};

export const cardDetailsButtonStyles = {
  h: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  right: '16px',
  transform: 'translateY(-50%, -50%)',
};

export const cardInnerContainerStyles = (isMobile) => ({
  w: isMobile ? 'calc(100% - 42px)' : '100%',
  h: '100%',
});

export const cardHeaderStyles = {
  w: '100%',
  justify: 'space-between',
};

export const cardHeaderContainer = {
  minW: '100%',
};

export const cardTextStyles = (isMobile) => ({
  textStyle: 'md',
  color: 'brand.neutral.700',
  w: isMobile ? 'calc(100% + 42px)' : '100%',
});

export const cardNumberStyles = (isMobile) => ({
  fontSize: 'sm',
  lineHeight: isMobile ? '24px' : '20px',
  color: 'brand.neutral.600',
});

export const cardMasterAccNameStyles = (isMobile) => ({
  textStyle: isMobile ? 'headline-sm' : 'headline-md',
  mt: '2px !important',
});

export const cardMainAccNameStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.500',
  mt: '-2px !important',
};

export const cardMTDContainerStyles = {
  m: '0!important',
};

export const cardMTDLabelStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.700',
  opacity: '0.7',
  display: 'block',
  w: '100%',
  mt: '0!important',
};

export const cardMTDValueStyles = (isMobile, isExceeded) => ({
  textStyle: isMobile ? 'headline-lg' : 'headline-sm',
  color: isExceeded ? 'red.800AA' : 'brand.neutral.700',
  display: 'block',
  w: '100%',
  mt: isMobile ? '4px' : '0',
});

export const cardVirtualLimitLabelStyles = (isMobile) => ({
  mt: '0 !important',
  mb: '4px',
  fontSize: isMobile ? 'xs' : 'sm',
  alignSelf: 'flex-end',
  textAlign: 'right',
  color: '#3A4B5B',
});

export const cardStatusStyles = {
  w: 'min-content',
};

export const cardProgressBarStyles = {
  w: '100%',
  bg: 'brand.blue.100',
  minH: '4px',
  maxH: '4px',
  borderRadius: '8px !important',
};

export const tooltipIconOuterContainerStyles = (isMobile) => ({
  bg: 'red.800AA',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  opacity: '0.08',
  zIndex: '1',
  position: 'absolute',
  right: '0px',
  top: isMobile ? '6px' : '2px',
});

export const tooltipIconInnerContainerStyles = (isMobile) => ({
  position: 'absolute',
  zIndex: '2',
  top: isMobile ? '11px' : '6px',
  right: '3px',
});
