// @flow
import React from 'react';
import { orderBy } from 'lodash';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import {
  BaselaneBadge,
  BaselaneGrid,
  BaselaneAccordion,
  BaselaneAccordionWrapper,
  BaselaneSpinner,
} from '@shared/components';
import AccountStatus from './AccountStatus';
import PropertyDescription from './PropertyDescription';
import LeaseDuration from './LeaseDuration';
import TenantContextMenu from './TenantContextMenu';
import {
  headerElementsStyles,
  gridStyles,
  headerGridItemStyles,
  contentItemsStyles,
  contentContainerStyles,
  contentInnerContainerStyles,
  noLeaseRecordTextStyles,
  badgeContainerStyles,
  badgeBackgroundStyles,
  accordionContainerStyles,
  accordionButtonStyles,
  accordionContentStyles,
} from '../styles/tenantInfo.style';
import useTenantLeasesQuery from '../useTenantLeasesQuery';

type TenantInfoProps = {
  tenant: Object,
  existingEmails: Array<String>,
  landlordEmail: String,
};

function TenantInfo({ tenant, existingEmails, landlordEmail }: TenantInfoProps): any {
  const { firstName, lastName, phoneNumber, email, status, leases, id } = tenant;
  const { getTenantLeases, tenantLeasesData, tenantLeasesLoading } = useTenantLeasesQuery({
    tenantProfileId: id,
  });

  const sortedLeases = orderBy(tenantLeasesData, ['endDate', 'startDate'], ['desc', 'desc']);
  const otherTenantEmails = existingEmails.filter((item) => item !== email);

  const formatPhoneNumber = (phoneNumberString) => {
    if (!phoneNumberString) return null;

    const cleaned = phoneNumberString?.toString().replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  };

  const hasLease = sortedLeases.some((l) => l.state === 'SCHEDULED');

  const renderHeaderElements = () => {
    const headerItems = [
      {
        key: 'name',
        value: `${firstName} ${lastName}`,
      },
      {
        key: 'email',
        value: email,
      },
      {
        key: 'phoneNumber',
        value: formatPhoneNumber(phoneNumber),
      },
      {
        key: 'status',
        value: (
          <Stack direction="row">
            <AccountStatus status={status} tenantId={id} />
          </Stack>
        ),
      },
    ];

    const headerElements = headerItems.map((header) => {
      const { key, value } = header;
      let element = '';

      switch (key) {
        case 'menu':
        case 'status':
          element = value;
          break;
        default:
          element = (
            <Box key={key} textStyle={key === 'name' ? 'md' : 'sm'} {...headerElementsStyles}>
              {value}
            </Box>
          );
      }

      return { element };
    });

    return (
      <>
        <BaselaneGrid
          gridItems={headerElements}
          templateColumns="repeat(3, minmax(0, 1fr)) 215px"
          templateRows="repeat(1, 1fr)"
          gap={1}
          gridStyles={gridStyles}
          gridItemStyles={headerGridItemStyles}
        />
        <TenantContextMenu
          tenant={tenant}
          landlordEmail={landlordEmail}
          otherTenantEmails={otherTenantEmails}
        />
      </>
    );
  };

  const renderContentElements = () => {
    if (tenantLeasesLoading)
      return <BaselaneSpinner margin="20px auto" display="block" position="static" />;

    const contentItems = [
      {
        element: <Text {...contentItemsStyles}>Property</Text>,
      },
      {
        element: leases?.length ? <Text {...contentItemsStyles}>Lease Duration</Text> : '',
      },
    ];

    const contentElements = (lease) => [
      {
        element: (
          <PropertyDescription
            propertyName={lease?.propertyName}
            propertyUnitName={lease?.propertyUnitName}
          />
        ),
      },
      {
        element: (
          <LeaseDuration
            startDate={lease?.startDate}
            endDate={lease?.endDate}
            leaseState={lease?.state}
            leaseType={lease?.leaseType}
          />
        ),
      },
    ];

    return (
      <HStack {...contentContainerStyles}>
        <Stack {...contentInnerContainerStyles}>
          {/* First & Second Columns: Property & Lease Duration if there are leases */}
          {/* Otherwise => First Column: show informational text */}
          <BaselaneGrid
            gridItems={contentItems}
            templateColumns="1fr 1fr"
            templateRows="repeat(1, 1fr)"
            gap={2}
            gridStyles={{ ...gridStyles }}
          />
          {sortedLeases.length ? (
            sortedLeases.map((lease) => (
              <BaselaneGrid
                key={lease.id}
                gridItems={contentElements(lease)}
                templateColumns="1fr 1fr"
                templateRows="repeat(1, 1fr)"
                gap={2}
                gridStyles={{ ...gridStyles }}
              />
            ))
          ) : (
            <Text {...noLeaseRecordTextStyles}>No Lease Record</Text>
          )}
        </Stack>

        {/* Third Column: Show Badge */}
        <Box {...badgeContainerStyles} borderLeft={!hasLease && '1px solid #E5E9EF'}>
          {!hasLease && (
            <Box w="155px">
              <BaselaneBadge
                backgroundStyles={{ ...badgeBackgroundStyles }}
                type="master"
                color="yellow"
                text="No Active Rent Collection"
                iconName="exclamationtriangle"
                iconClassName="icon-md"
              />
            </Box>
          )}
        </Box>
      </HStack>
    );
  };

  return (
    <BaselaneAccordionWrapper
      allowToggle
      onChange={(open) => {
        if (open !== -1) getTenantLeases(); // only fetch onOpen
      }}
      styles={{ ...accordionContainerStyles }}
    >
      <BaselaneAccordion
        allowToggle
        headerElements={renderHeaderElements()}
        contentElements={renderContentElements()}
        containerStyles={{ ...gridStyles }}
        buttonStyles={{ ...accordionButtonStyles }}
        contentStyles={{ ...accordionContentStyles }}
      />
    </BaselaneAccordionWrapper>
  );
}

export default TenantInfo;
