import React, { useRef } from 'react';
import { HStack, Stack, Box, Text } from '@chakra-ui/react';
import { BaselaneAlert, BaselaneButton } from '@shared/components';
import IconCheckCircleOutline from '@icons/legacy/IconCheckCircleOutline';
import {
  badgeIconBgStyles,
  badgeIconContainerStyles,
  titleTextStyles,
} from './styles/emailSuccessStyles.styles';

function ResetPasswordSuccess({
  continueUrl,
  isAlertOpen,
  setIsAlertOpen,
}: {
  continueUrl: string,
  isAlertOpen: boolean,
  setIsAlertOpen: Function,
}) {
  const onAlertClose = () => setIsAlertOpen(false);
  const cancelAlert = useRef();
  const alertTitle = (
    <HStack>
      <Stack {...badgeIconBgStyles}>
        <Box {...badgeIconContainerStyles}>
          <IconCheckCircleOutline />
        </Box>
      </Stack>
      <Text {...titleTextStyles}>Password Updated</Text>
    </HStack>
  );

  const handleOnClick = () => {
    window.location.href = continueUrl;
  };

  const alertMessage = <Text>You can now sign in with your new password.</Text>;

  const alertFooter = (
    <BaselaneButton variant="outline" palette="neutral" onClick={handleOnClick}>
      OK
    </BaselaneButton>
  );

  return (
    <BaselaneAlert
      isOpen={isAlertOpen}
      onClose={onAlertClose}
      leastDestructiveRef={cancelAlert}
      isCentered
      header={alertTitle}
      body={alertMessage}
      footer={alertFooter}
      size="xl"
    />
  );
}

export default ResetPasswordSuccess;
