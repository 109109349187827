import { isMobile } from 'react-device-detect';
import customTheme from '@core/theme';

export const contentContainerStyles = (desktopLayout) => {
  return {
    direction: desktopLayout ? 'row' : 'column',
    align: 'center',
    justifyContent: 'space-between',
    spacing: isMobile ? '16px' : '70px',
    maxWidth: '736px',
    margin: '0 auto',
  };
};

export const headerContainerStyles = {
  textAlign: 'center',
  alignItems: 'center',
};

export const subTextStyles = {
  textStyle: 'headline-lg',
  color: customTheme.colors.brand.neutral['600'],
};

export const titleTextStyles = {
  size: 'headline-xl',
  color: 'brand.neutral.900',
};

export const descriptionTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
};

export const amountTextStyles = {
  textStyle: 'md',
  fontWeight: 'normal',
  color: 'brand.neutral.700',
  bg: 'white',
  marginRight: '8px',
  border: '1pt solid',
  borderRadius: '100px',
};

export const amountContainerStyles = {
  padding: 3,
  border: '1pt solid',
  borderColor: 'brand.darkBlue.200',
  borderRadius: '8px',
  direction: 'row',
  w: '100%',
};

export const amountContainerIconStyles = {
  color: customTheme.colors.brand.neutral['900'],
};

export const amountContainerHeaderStyles = {
  textStyle: 'headline-lg',
  color: customTheme.colors.brand.neutral['900'],
};

export const insuredTextStyles = {
  textStyle: 'sm',
  color: customTheme.colors.green['900'],
  fontWeight: 'normal',
};

export const footerContainerStyles = {
  direction: !isMobile ? 'row' : 'column',
  flexDirection: isMobile ? 'column-reverse' : 'initial',
  position: isMobile ? 'fixed' : 'relative',
  bottom: '0',
  left: '0',
  background: isMobile ? 'brand.neutral.white' : 'transparent',
  w: '100%',
  p: isMobile ? '12px 24px 24px' : '0',
  boxShadow: isMobile ? '0 0 12px #ffffff' : 'none',
  mt: !isMobile ? '32px !important' : '0px',
};

export const leftSuccessBtnStyles = {
  mt: isMobile ? '1' : '0',
};

export const buttonStyles = {
  w: isMobile ? '100%' : 'initial',
  size: isMobile ? 'lg' : 'md',
};
