import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BaselaneMobileCheckUploader from '@core/components/Shared/components/BaselaneMobileCheckUploader';

const CheckScanner = ({
  frontImage,
  backImage,
  onCheckFrontImageChange,
  onCheckBackImageChange,
}) => {
  const [checkFrontImage, setCheckFrontImage] = useState(frontImage);
  const [checkBackImage, setCheckBackImage] = useState(backImage);
  const [frontRotated180, setFrontRotated180] = useState(false);
  const [backRotated180, setBackRotated180] = useState(false);

  return (
    <BaselaneMobileCheckUploader
      checkFrontImageData={checkFrontImage}
      checkBackImageData={checkBackImage}
      setCheckFrontImageData={(imageData) => {
        setCheckFrontImage(imageData);
        onCheckFrontImageChange(imageData);
      }}
      setCheckBackImageData={(imageData) => {
        setCheckBackImage(imageData);
        onCheckBackImageChange(imageData);
      }}
      frontRotated180={frontRotated180}
      backRotated180={backRotated180}
      setFrontRotated180={setFrontRotated180}
      setBackRotated180={setBackRotated180}
      handleUnsupportedBrowserError={() => {
        console.log('Unsupported browser error');
      }}
      isRequired
    />
  );
};

CheckScanner.propTypes = {
  frontImage: PropTypes.string,
  backImage: PropTypes.string,
  onCheckFrontImageChange: PropTypes.func.isRequired,
  onCheckBackImageChange: PropTypes.func.isRequired,
};

CheckScanner.defaultProps = {
  frontImage: '',
  backImage: '',
};

export default CheckScanner;
