import React from 'react';
import { Checkbox } from '@chakra-ui/react';
import { defaultCheckboxItemStyles } from '@shared/components/BaselaneDropdown/components/styles/checkboxItem.styles';

type CheckboxListItemProps = {
  id: String,
  children: any,
  onChange: Function,
  isChecked: Boolean,
  isIndeterminate?: Boolean,
  styles?: Object,
};

/**
 * Helper Component to create checkbox element to be used with dropdown
 *
 * @param id
 * @param children
 * @param onChange
 * @param isChecked
 * @param isIndeterminate
 * @param styles
 * @returns {JSX.Element}
 */

const CheckboxListItem = ({
  id,
  children,
  onChange,
  isChecked,
  isIndeterminate,
  styles,
}: CheckboxListItemProps) => {
  return (
    <Checkbox
      key={`checkbox-list-${id}`}
      className="primary-500 neutral-200"
      isChecked={isChecked}
      isIndeterminate={isIndeterminate}
      onChange={onChange}
      {...{ ...defaultCheckboxItemStyles, ...styles }}
    >
      {children}
    </Checkbox>
  );
};

CheckboxListItem.defaultProps = {
  isIndeterminate: false,
  styles: {},
};

export default CheckboxListItem;
