import { isMobile } from 'react-device-detect';
import { use100vh } from 'react-div-100vh';
import customTheme from '@core/theme';

export const drawerFooterStyle = {
  h: '80px',
  p: '20px 32px',
  bg: 'brand.neutral.white',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  zIndex: 1,
  justifyContent: 'flex-start',
  position: 'relative',
};

export const footerStyles = (isMax768) => ({
  justifyContent: 'left',
  width: '100%',
  margin: '24px 0',
  padding: isMobile || isMax768 ? '0' : '0',
  background: 'brand.neutral.white',
  height: '120px',
});

export const footerDeleteStyles = (isMax768) => ({
  h: 'auto',
  margin: '0 0 8px',
  w: isMobile || isMax768 ? '100%' : 'auto',
});

export const footerButtonStyles = (isMax768) => ({
  width: isMobile || isMax768 ? '100%' : 'unset',
});

export const dashStyles = { display: 'flex', borderColor: 'brand.darkBlue.150', flex: '1 1' };

export const tabListStyles = {
  w: isMobile ? '100%' : '28%',
  maxWidth: '307px',
  minW: isMobile ? '100%' : '280px',
  minH: isMobile ? '0' : '400px',
  pt: isMobile ? '0' : '48px',
  pl: isMobile ? '0' : '24px',
  pr: isMobile ? '0' : '24px',
  h: isMobile ? 'auto' : '100%',
  borderRight: '1px solid',
  borderRightColor: 'brand.darkBlue.100',
};

export const tabPanelStyles = {
  p: isMobile ? '0' : '0 0 16px 40px',
  position: 'relative',
  left: '0',
  maxWidth: '640px',
  minWidth: isMobile ? 'initial' : '580px',
  width: '100%',
};

export const tabPanelResponsiveStyles = (isMax576, isMax768) => ({
  p: isMax768 ? '0' : '0 0 16px 40px',
  position: 'relative',
  left: '0',
  maxWidth: isMax768 ? '100%' : '640px',
  minWidth: isMax576 ? 'initial' : '580px',
  width: '100%',
});

export const tabsStyles = {
  p: '0',
  position: 'relative',
  overflowY: 'hidden',
  overFlowX: 'hidden',
};

export const tabPanelsStyles = () => {
  const mobile100vh = use100vh() - 120;
  return {
    h: isMobile ? mobile100vh : '100%',
    overflowY: 'auto',
  };
};

export const tabPanelsResponsiveStyles = (isMax768) => {
  const mobile100vh = use100vh() - 120;
  return {
    h: isMax768 ? mobile100vh : '100%',
    overflowY: 'auto',
    overFlowX: 'hidden',
  };
};

export const tabPanelsWrapperStyles = {
  width: '100%',
};

export const addAnotherLinkStyles = {
  width: '100%',
  margin: '0 auto 0 0',
  padding: isMobile ? '8px 0 24px' : '8px 24px 0 0',
  button: { fontSize: 'sm', color: customTheme.colors.brand.blue['700'] },
};

export const panelHeaderStyles = () => ({
  fontSize: isMobile ? 'lg' : '2xl',
  lineHeight: isMobile ? '26px' : '32px',
  fontWeight: 'semibold',
  color: 'brand.neutral.700',
  marginTop: '0px',
});

export const panelHeaderResponsiveStyles = (isMax768) => ({
  fontSize: isMax768 ? 'lg' : '2xl',
  lineHeight: isMax768 ? '26px' : '32px',
  fontWeight: 'semibold',
  color: 'brand.neutral.700',
  marginTop: '0px',
  pl: '0',
});

export const panelHeaderWrapperStyles = () => ({
  width: '100%',
  background: 'brand.neutral.white',
  position: isMobile ? 'relative' : 'static',
  top: isMobile ? '0' : '164px',
  zIndex: '2',
  mb: 1,
});

export const panelHeaderWrapperResponsiveStyles = (isMax768) => ({
  width: '100%',
  background: 'brand.neutral.white',
  position: isMax768 ? 'relative' : 'static',
  top: isMax768 ? '0' : '164px',
  paddingLeft: isMax768 ? '24px' : '0',
  zIndex: '2',
  mb: 1,
});

export const panelHeaderContainerStyles = () => ({
  paddingBottom: '8px',
});

export const panelDescriptionStyles = () => ({
  mt: isMobile ? '0' : '4px',
  mb: isMobile ? '16px' : '24px',
  maxWidth: '640px',
  minWidth: '58px',
  fontSize: 'xs',
  color: 'brand.neutral.600',
});

export const panelDescriptionResponsiveStyles = (isMax768) => ({
  mt: isMax768 ? '0' : '4px',
  mb: isMax768 ? '16px' : '24px',
  maxWidth: '640px',
  minWidth: '58px',
  fontSize: 'xs',
  color: 'brand.neutral.600',
  pl: '0',
});

export const panelInlineLoaderStyles = () => ({
  position: 'relative',
  marginTop: '20px',
});

export const panelFormStyles = {
  m: isMobile ? '0 24px 56px' : '0 0 56px',
};

export const panelFormResponsiveStyles = (isMax768) => ({
  m: isMax768 ? '0 24px 56px 24px' : '0 0 56px',
});

export const panelContentResponsiveStyles = (top, isMax768) => {
  const topDesktop = top || '80px';

  return {
    position: 'relative',
    top: isMax768 ? '0' : topDesktop,
    mb: '40px',
    zIndex: '1',
    px: isMax768 ? '24px' : '0',
  };
};

export const addressSectionStyles = () => ({
  mt: '32px',
});

export const addressSectionLabelTextStyles = () => ({
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
  m: '0 !important',
  p: 0,
});

export const addressSectionLabelContainerStyles = () => ({
  paddingBottom: '0',
});

export const addressSectionFormStyles = {
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: 0,
  mb: 1,
  h: '40px',
};

export const addressSectionContentStyles = () => ({
  direction: 'row',
  justifyContent: 'space-between',
  background: isMobile ? 'brand.neutral.white' : 'brand.blue.50',
  padding: isMobile ? '0' : '16px!important',
  mt: isMobile ? '12px' : '8px',
  ml: '0',
  borderRadius: '8px',
});

export const addressSectionContentLeftStyles = () => ({
  textStyle: isMobile ? 'xs' : 'sm',
  color: 'brand.neutral.600',
});

export const addressSectionContentRightStyles = () => ({
  textStyle: 'headline-sm',
  maxWidth: '60%',
  textAlign: 'right',
  color: 'brand.neutral.700',
});

export const spinnerStyles = {
  color: 'brand.darkBlue.150',
  position: 'absolute',
  mt: '100px',
  top: '50%',
  left: '50%',
  w: '56px',
  h: '56px',
};

export const propertiesSpinnerStyles = {
  color: 'brand.darkBlue.150',
  mt: '100px',
  w: '56px',
  h: '56px',
};

export const invoicesSpinnerStyles = {
  color: 'brand.darkBlue.150',
  margin: '0 0 0 100px',
  w: '56px',
  h: '56px',
};
