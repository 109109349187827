export const fieldAutotagStyles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    textStyle: 'headline-sm',
    fontWeight: '500',
  },
};
