import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Select from 'react-dropdown-select';
import { v4 as uuidv4 } from 'uuid';

import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { dropdownIconContainerStyles } from '@shared/components/BaselaneDropdown/components/styles/dropdown.styles';
import { IconCarretDown, IconCarretUp } from '@icons';

import { optionButtonStyles } from '../../styles/addVirtualCard.styles';

import './styles/periodDropdown.styles.scss';

type PeriodOption = {
  label: String,
  description: String,
  value: any,
};

type PeriodDropdownProps = {
  options?: Array<PeriodOption>,
  startOption?: any,
  isInvalid?: Boolean,
  onChange: Function,
  classNames?: Array<String>,
};

function PeriodDropdown({
  options,
  startOption,
  isInvalid,
  onChange,
  classNames,
}: PeriodDropdownProps): any {
  const [selectedOption, setSelectedOption] = useState(startOption);

  const handleClick = (option: PeriodOption) => {
    if (option.value !== selectedOption.value) {
      setSelectedOption(option);
      onChange(option);
    }
  };

  const dropdownRenderer = ({ props }: any) => {
    const { options: periodOptions } = props;
    return (
      <VStack {...{ p: '16px', spacing: '8px' }}>
        {periodOptions.map((option) => {
          const dataSelected = option.value === selectedOption.value ? true : null;

          const buttonOptions = {
            ...optionButtonStyles,
            key: uuidv4(),
            onClick: () => {
              handleClick({ ...option });
            },
          };

          if (dataSelected) buttonOptions['data-selected'] = true;

          return (
            <Button {...buttonOptions}>
              <Text {...{ color: 'inherit' }}>{option.label}</Text>
              <Text {...{ color: 'brand.neutral.500' }}>{option.description}</Text>
            </Button>
          );
        })}
      </VStack>
    );
  };

  const dropdownHandleRenderer = ({ state }) => {
    const icon = state.dropdown ? (
      <IconCarretUp width="16" height="16" viewBox="-2 -6 16 16" color="#6C7884" />
    ) : (
      <IconCarretDown width="16" height="16" viewBox="-2 -6 16 16" color="#6C7884" />
    );

    return (
      <Box
        className="icon-carret"
        {...{
          ...dropdownIconContainerStyles,
          p: '0px !important',
        }}
      >
        {icon}
      </Box>
    );
  };

  const className = isInvalid
    ? [
        'baselane-frequency-dropdown',
        'color-neutral-500',
        'input-form-xl',
        'invalid',
        ...classNames,
      ].join(' ')
    : ['baselane-frequency-dropdown', 'color-neutral-500', 'input-form-xl', ...classNames].join(
        ' '
      );

  const selectOptions = {
    searchable: false,
    flex: isMobile ? '2' : '1',
    className,
    dropdownPosition: 'auto',
    closeOnSelect: true,
    dropdownRenderer,
    dropdownHandleRenderer,
    options,
    values: selectedOption ? [selectedOption] : [],
  };

  return <Select {...selectOptions} />;
}

// default options- for card spending limits
export const defaultPeriodOptions = [
  {
    label: 'Daily',
    description: 'Limit resets every day at 00:00am',
    value: 'DAILY',
  },
  {
    label: 'Monthly',
    description: 'Limit resets every calendar month',
    value: 'MONTHLY',
  },
];

PeriodDropdown.defaultProps = {
  options: defaultPeriodOptions,
  startOption: defaultPeriodOptions[0],
  isInvalid: false,
  classNames: [],
};

export default PeriodDropdown;
