export const poweredByVouchedStyles = {
  position: 'fixed',
  botttom: '0',
  width: '180px',
  bottom: '24px',
  left: 'calc(50% - 80px)',
  color: '#3A4B5B',
  textStyle: 'sm',
};

export const vouchedImageStyles = {
  w: '80px',
  h: '18px',
  ml: '16px',
};
