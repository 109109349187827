import React from 'react';
import EducationalDrawerWrapper from './EducationDrawerWrapper';
import DrawerContent from './DrawerContent';

type EducationalDrawerProps = {
  educationalDrawerRef: any,
  handleDrawerClose: Function,
  handleDrawerOpen: Function,
  buttonContent: Object,
  bankTransferProps: Object,
  hideButton?: boolean,
};

const EducationalDrawer = ({
  educationalDrawerRef,
  handleDrawerClose,
  handleDrawerOpen,
  buttonContent,
  bankTransferProps,
  hideButton,
}: EducationalDrawerProps) => {
  return (
    <EducationalDrawerWrapper
      isInEmptyState
      {...{
        educationalDrawerRef,
        handleDrawerClose,
        handleDrawerOpen,
        buttonContent,
        bankTransferProps,
        hideButton,
      }}
    >
      <DrawerContent id={buttonContent.id} />
    </EducationalDrawerWrapper>
  );
};

EducationalDrawer.defaultProps = { hideButton: false };

export default EducationalDrawer;
