import React from 'react';
import { Icon16Info } from '@icons/16px';
import { FRAGMENT_INSURANCE_ESTIMATE } from '@core/apollo/queries';

export const modalTextContent = {
  title: 'Select a property to start quote',
  subtitle: 'Available in all 50 states',
  infoCard: {
    icon: <Icon16Info color="#0968BF" />,
    title: 'Just a few more steps...',
    description:
      'Answer a few simple questions about your property and get a quote instantly from our insurance partner Obie.',
    mobileDescription:
      'Answer a few simple questions about your property and get a quote instantly from our insurance partner Obie.',
  },
  cancelBtnText: 'Cancel',
  startQuoteBtnText: 'Start quote with Obie',
};

export const formatAddress = (addressObj) => {
  const { address, city, unit, state, zipCode } = addressObj ?? {};
  const propertyUnit = unit ? `${unit},` : '';
  const propertyAddress = `${address}, ${propertyUnit} ${city}, ${state} ${zipCode}`;

  return propertyAddress;
};

export const readFragment = (cache, property) =>
  cache.readFragment({
    id: cache.identify(property),
    fragment: FRAGMENT_INSURANCE_ESTIMATE,
  });

export const writeFragment = (cache, property, insurance) =>
  cache.writeFragment({
    id: cache.identify(property),
    fragment: FRAGMENT_INSURANCE_ESTIMATE,
    data: {
      insurance,
    },
  });
