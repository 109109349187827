// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=2925%3A97142
import React, { useContext } from 'react';
import { Box, Text, Stack } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BaselaneButton, BaselaneGrid } from '@shared/components';
import { ADD_BASELANE_BANK_ACCOUNT } from '@routes';
import BanksContext from '@contexts/BanksContext';
import BaselaneIconLogo from '@core/assets/BaselaneIconLogo';
import {
  sectionTitleStyles,
  noMarginStyles,
  darkDescriptionTextStyles,
} from '../../components/styles/emptyState.shared.styles';
import { emptyStateCardStyles } from './styles/emptyState';
import EmptyStateFeatureList from '../../components/EmptyStateFeatureList';

type BaselaneEmptyStateViewProps = {
  isGetStarted: boolean,
};

function BaselaneEmptyStateView({ isGetStarted }: BaselaneEmptyStateViewProps): any {
  const navigate = useNavigate();
  const currLocation = useLocation();

  const { userHasDraftApplication, hasActiveBaselaneAccounts } = useContext(BanksContext);

  const gridItems = [
    {
      element: (
        <Stack h="100%" py="32px" px="42px" spacing="36px">
          <Box color="brand.blue.800A">
            <BaselaneIconLogo />
            <Text {...sectionTitleStyles} my="18px !important">
              Baselane Banking Account
            </Text>
            <Text {...darkDescriptionTextStyles} {...noMarginStyles}>
              Open banking accounts built for landlords in minutes, included at no additional cost.
            </Text>
          </Box>
          <Box>
            <BaselaneButton
              variant="filled"
              palette="primary"
              size={isGetStarted ? 'lg' : 'sm'}
              onClick={() =>
                navigate({
                  pathname: `${
                    currLocation.pathname !== '/' ? currLocation.pathname : ''
                  }/${ADD_BASELANE_BANK_ACCOUNT}`,
                })
              }
            >
              {userHasDraftApplication && !hasActiveBaselaneAccounts
                ? 'Continue Application'
                : 'Open Baselane Banking Account'}
            </BaselaneButton>
          </Box>
        </Stack>
      ),
    },
    {
      element: (
        <Box bg="brand.blue.50" py="24px" px="42px" h="100%">
          <EmptyStateFeatureList isInDrawer />
        </Box>
      ),
    },
  ];

  return (
    <BaselaneGrid
      gridItems={gridItems}
      templateColumns="1fr 1fr"
      templateRows="repeat(1, 1fr)"
      gridStyles={{ ...emptyStateCardStyles }}
      gridItemStyles={{ h: '100%' }}
    />
  );
}

export default BaselaneEmptyStateView;
