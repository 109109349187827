import { uniq } from 'lodash';

const STATUSES = Object.freeze({
  IN_PROGRESS: {
    label: {
      text: 'Application in Review',
      color: 'blue',
    },
    description: 'Our review takes up to 2 business hours',
  },
  DENIED: {
    label: {
      text: 'Contact us',
      color: 'red',
    },
    description: 'We have encountered an issue',
  },
  AWAITING_DOCUMENTS: {
    label: {
      text: 'Documents Required',
      color: 'yellow',
    },
    description: 'Upload documents to continue application',
  },
  DRAFT: {
    label: {
      text: 'Draft Application',
      color: 'lightblue',
    },
    description: 'Continue application where you left off',
  },
  COMPLETED: null,
});

export const getPropertiesUnits = (info, leaseDetailProperties) =>
  Object.keys(info).length > 0
    ? Object.keys(info).map((propertyId) => {
        const propertyAndUnitsInfo = info[propertyId];
        const propertyWithId = leaseDetailProperties.find((property) => property.id === propertyId);
        const linkedUnits =
          propertyWithId &&
          propertyWithId.units &&
          propertyWithId.units.length > 0 &&
          propertyAndUnitsInfo.length > 0
            ? uniq(
                propertyAndUnitsInfo.map((unitId) =>
                  propertyWithId.units.length > 0
                    ? propertyWithId.units.find((unit) => unit.id === unitId)
                    : {}
                )
              )
            : [];
        return { property: propertyWithId, units: linkedUnits };
      })
    : { property: [], units: [] };

export const getUnitApplicationStatus = (status) => {
  return STATUSES[status];
};
