import process from 'process';

export default async function getResetPasswordLink(bodyParam) {
  const [orchestrationUrl] = process.env.REACT_APP_ORCHESTRATION_URL?.split('/graphql') || [];
  const requestUrl = `${orchestrationUrl}/api/resetPasswordLink`;
  const response = await fetch(requestUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyParam),
    credentials: 'include',
  });
  return response !== null ? response.json() : response;
}
