// @flow
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Spacer,
  HStack,
} from '@chakra-ui/react';
import {
  formItemStyles,
  formErrorStyles,
  formInputStyles,
  formLabelStyles,
} from '@shared/styles/input.style';
import { Icon12Error } from '@icons/12px/index';
import { VGSEditInput } from '../../components';
import { useVGSShowField } from '../../hooks/useVGSShowField';
import {
  personalDetailsFormValidation,
  personalDetailsFormInitialValues,
  dobMask,
} from './helpers/personalDetailsForm.helper';
import '@core/styles/form.scss';

type PersonalDetailsFormProps = {
  personalDetailsRef: any,
  formData: Object,
};

function PersonalDetailsForm({ personalDetailsRef, formData }: PersonalDetailsFormProps): any {
  const { fieldToggleButton, isEdit, showField } = useVGSShowField(formData?.dob, 'dob');

  return (
    <Formik
      innerRef={personalDetailsRef}
      validate={(values) => personalDetailsFormValidation(values)}
      enableReinitialize
      initialValues={personalDetailsFormInitialValues(formData)}
      validateOnBlur
    >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <>
          <Flex direction={isMobile ? 'column' : 'row'}>
            {/* First Name */}
            <FormControl
              {...formItemStyles}
              isInvalid={errors.firstName && touched.firstName}
              isRequired
            >
              <FormLabel htmlFor="firstName" {...formLabelStyles.xs}>
                Legal First Name
              </FormLabel>
              <Input
                {...formInputStyles}
                id="firstName"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                placeholder="e.g. John"
              />

              <FormErrorMessage {...formErrorStyles} ml={0}>
                {' '}
                <Box as="span" mr={0.5}>
                  <Icon12Error />
                </Box>
                {errors.firstName}
              </FormErrorMessage>
            </FormControl>

            <Spacer minWidth="16px" />

            {/* Last Name */}
            <FormControl
              {...formItemStyles}
              isInvalid={errors.lastName && touched.lastName}
              isRequired
            >
              <FormLabel htmlFor="lastName" {...formLabelStyles.xs}>
                Legal Last Name
              </FormLabel>
              <Input
                {...formInputStyles}
                id="lastName"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                placeholder="e.g. Smith"
              />

              <FormErrorMessage {...formErrorStyles} ml={0}>
                <Box as="span" mr={0.5}>
                  <Icon12Error />
                </Box>
                {errors.lastName}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          {/* DOB */}
          <FormControl isRequired isInvalid={errors.dob && touched.dob && isEdit} className="vgs">
            <HStack justifyContent="space-between">
              <FormLabel htmlFor="dob" {...formLabelStyles.xs}>
                Date of Birth (MM/DD/YYYY)
              </FormLabel>
              {fieldToggleButton}
            </HStack>
            {formData?.dob && !isEdit ? (
              showField
            ) : (
              <VGSEditInput
                {...{
                  inputProps: { mask: dobMask, placeholder: 'e.g. 01/22/1990' },
                  fieldName: 'dob',
                  isEdit,
                }}
              />
            )}
            <FormErrorMessage {...formErrorStyles} ml={0}>
              {' '}
              <Box as="span" mr={0.5}>
                <Icon12Error />
              </Box>
              {errors.dob}
            </FormErrorMessage>
          </FormControl>
        </>
      )}
    </Formik>
  );
}

export default PersonalDetailsForm;
