export const titleStyle = {
  display: 'flex',
  alignItems: 'center',
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
};

export const overviewLabelStyle = {
  color: 'brand.neutral.600',
  textStyle: 'xs',
};

export const overviewValueStyle = {
  color: 'brand.neutral.700',
  fontSize: 'data-2xl',
  ml: '12px !important',
};
