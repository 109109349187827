import React, { useContext } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import InvoiceContext from '@contexts/InvoiceContext';
import { achFeeEnabledFeatureFlag } from '@core/constants/envVars';
import BaselanePromotionBanner from './BaselanePromotionBanner';
import UpdateReceivingAccAlert from './UpdateReceivingAccAlert';
import ExpiringSoonBanner from './ExpiringSoonBanner';
import ExpiredBanner from './ExpiredBanner';
import Payments from './Payments';
import TenantDetails from './TenantDetails';
import LeaseTerms from './LeaseTerms';
import ReceivingBankAccounts from './ReceivingBankAccounts';
import {
  getLeaseStatusType,
  getRemainingDays,
  getIsRentExternalAcc,
} from '../../helpers/leaseResponsiveReview.helpers';
import { ProcessingFee } from './ProcessingFee';

type LeaseResponsiveReviewProps = {
  lease: Object,
  leaseCreated: boolean,
  setTabIndex: Function,
  bankProps: Object,
  variablesAndStates: Object,
  helperFunctions: Object,
  invoiceListDrawerRef: any,
  refetchSingleLease: Function,
  hasBaselaneBank: boolean,
  handleTabsChange: Function,
  handleArchiveLease: Function,
  handleUpdateRecievingAccClick: Function,
  isTablet: boolean,
  setIsAccountsEdit: Function,
  tenantData: Object,
};

function LeaseResponsiveReview({
  lease,
  leaseCreated,
  setTabIndex,
  bankProps,
  variablesAndStates,
  helperFunctions,
  invoiceListDrawerRef,
  refetchSingleLease,
  hasBaselaneBank,
  handleTabsChange,
  handleArchiveLease,
  handleUpdateRecievingAccClick,
  isTablet,
  setIsAccountsEdit,
  tenantData,
}: LeaseResponsiveReviewProps) {
  const [isDesktop] = useMediaQuery('(min-width: 899px)', { ssr: false });
  const leaseStatusType = getLeaseStatusType({ lease });
  const remainingDays = getRemainingDays({ lease });
  const isRentExternalAcc = getIsRentExternalAcc({
    rentAndFeesBankAccountId: lease?.rentAndFeesBankAccountId,
    bankProps,
  });

  const { tenantStatus } = useContext(InvoiceContext);

  const { nextPayment, invoiceFailedSummary, invoiceOverdueSummary, invoiceProcessingSummary } =
    lease?.invoiceDetails ?? {};

  const showBaselanePromotionBanner =
    hasBaselaneBank &&
    (leaseStatusType === 'Active' || leaseStatusType === 'Expired') &&
    isRentExternalAcc;

  const showExpiringSoonBanner =
    leaseStatusType === 'Active' && lease?.leaseType === 'FIXED' && remainingDays <= 60;

  const showExpiredBanner =
    lease?.state === 'EXPIRED' &&
    lease?.leaseType === 'FIXED' &&
    nextPayment &&
    nextPayment.length === 0 &&
    !invoiceFailedSummary?.count &&
    !invoiceOverdueSummary?.count &&
    !invoiceProcessingSummary?.count;

  return (
    <Box pb="42px" w="100%">
      {/* Baselane Bank Promotion Banner */}
      {showBaselanePromotionBanner &&
        (achFeeEnabledFeatureFlag ? (
          <Box mt={4}>
            <UpdateReceivingAccAlert
              handleUpdateRecievingAccClick={handleUpdateRecievingAccClick}
            />
          </Box>
        ) : (
          <BaselanePromotionBanner {...{ showBaselanePromotionBanner, handleTabsChange }} />
        ))}
      {/* Expiring Soon Banner */}
      {showExpiringSoonBanner && <ExpiringSoonBanner {...{ handleTabsChange, isTablet }} />}
      {/* Expired Banner */}
      {showExpiredBanner && <ExpiredBanner {...{ handleArchiveLease, isTablet }} />}
      {/* Tenant Status Banner */}
      {tenantStatus && isDesktop && tenantStatus}
      {tenantStatus && !isDesktop && tenantStatus}

      {[Payments, TenantDetails, LeaseTerms, ReceivingBankAccounts].map((Component, i) => {
        return (
          <Component
            {...{
              key: i,
              id: i,
              lease,
              setTabIndex,
              leaseCreated,
              bankProps,
              viewMode: true,
              variablesAndStates,
              helperFunctions,
              invoiceListDrawerRef,
              refetchSingleLease,
              setIsAccountsEdit,
              tenantData,
            }}
          />
        );
      })}
      {achFeeEnabledFeatureFlag && (
        <ProcessingFee
          handleClick={() => setTabIndex(3)}
          invoiceProcessingFeePaidBy={lease.invoiceProcessingFeePaidBy}
          isArchived={lease.archived}
          isExternalBankAccount={lease.rentAndFeesBankAccount.isExternal}
        />
      )}
    </Box>
  );
}

export default LeaseResponsiveReview;
