import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';

import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import getBreakPoints from '@core/utils/getBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { BaselaneButton } from '@shared/components';
import { DASHBOARD, UNIFIED_LANDLORD_BANKING, UNIFIED_BOOKKEEPING_ANALYTICS } from '@routes';
import { Icon16VerifiedBadge } from '@icons/16px';
import userContext from '@contexts/UserContext';
import { mobileFooterStyles } from '@core/components/OnboardingTriage/styles/onboardingTriage.styles';

import InfoCardCarousel from './InfoCardCarousel';
import InfoCard from './InfoCard';
import BankingFirstSkipPopup from './BankingFirstSkipPopup';
import { getOnboardingInfoCards } from '../helpers/onboardingCardsHelper';

const OptionCardsScreens = ({ selection }: { selection: string }) => {
  const { isMin768 } = getBreakPoints();
  const navigate = useNavigate();
  const { user, refetchUser, skippedBankingFirst, setSkippedBankingFirst } = useContext(
    userContext
  );

  const [updateUser] = useMutation(UPDATE_USER);

  const {
    isOpen: isBankingFirstSkipAlertOpen,
    onOpen: onBankingFirstSkipAlertOpen,
    onClose: onBankingFirstSkipAlertClose,
  } = useDisclosure();

  const [onboardingBankingFirstCards, setOnboardingBankingFirstCards] = useState([]);

  const { bankingTracker } = user?.userMetadata || {};
  const onboardingTriageSelection = selection;
  const isBankingFinished = (bankingTracker || [])[0].state === 'COMPLETED';

  const handleBankingFirstActionButton = () => {
    sendSegmentEvent('onboarding_baselane_overview_continue', {
      feature: onboardingTriageSelection,
    });
    if (!skippedBankingFirst && !isBankingFinished) {
      sendSegmentEvent('banking_first_bank_step_open_bank_account');
      navigate(UNIFIED_LANDLORD_BANKING);
    } else if (
      onboardingTriageSelection === 'bookkeeping' &&
      (skippedBankingFirst || isBankingFinished)
    ) {
      sendSegmentEvent('banking_first_bookkeeping_step_set_up');
      navigate(UNIFIED_BOOKKEEPING_ANALYTICS);
    }
  };

  const handleBankingFirstSkipButton = (type) => {
    // onboarding flow segment event
    sendSegmentEvent('onboarding_baselane_overview_skip', {
      feature: onboardingTriageSelection,
    });
    // banking first skip segment events
    if (!skippedBankingFirst && !isBankingFinished) {
      sendSegmentEvent('banking_first_bank_step_click_skip');
    } else if (onboardingTriageSelection === 'bookkeeping') {
      sendSegmentEvent('banking_first_bookkeeping_step_click_skip');
    }
    if (type === 'bookkeeping') {
      updateUser({
        variables: {
          onboardingCompleted: true,
        },
      }).then(() => {
        refetchUser().then(() => navigate(DASHBOARD));
      });
    } else {
      onBankingFirstSkipAlertOpen();
    }
  };

  useEffect(() => {
    setOnboardingBankingFirstCards(
      getOnboardingInfoCards(onboardingTriageSelection, isBankingFinished, skippedBankingFirst)
    );
    if (onboardingTriageSelection === 'banking') {
      setSkippedBankingFirst(false);
      localStorage.removeItem('skippedBankingFirst');
    }
  }, [onboardingTriageSelection, skippedBankingFirst]);

  if (onboardingBankingFirstCards.length === 0) {
    return null;
  }

  return (
    <>
      <Flex direction="column" justifyContent="start" gap={isMin768 ? '48px' : '54px'} w="100%">
        <VStack gap="32px">
          <Box width="100%" display="flex" direction="row" justifyContent="center">
            {isMin768 ? (
              <HStack marginX="auto" gap="16px" px="16px">
                {onboardingBankingFirstCards?.map((item) => (
                  <InfoCard key={item.id} slide={item} isCarousel={false} />
                ))}
              </HStack>
            ) : (
              <InfoCardCarousel infoCards={onboardingBankingFirstCards} />
            )}
          </Box>
          {isMin768 && (
            <HStack>
              <Box>
                <Icon16VerifiedBadge />
              </Box>
              <Text textStyle="md">No hidden, monthly, or minimum balance fees</Text>
            </HStack>
          )}
        </VStack>
        <Flex
          direction="column"
          {...(isMin768 ? {} : mobileFooterStyles)}
          gap="16px"
          alignItems="center"
        >
          <BaselaneButton
            size="lg"
            variant="filled"
            palette="primary"
            onClick={handleBankingFirstActionButton}
            isFullWidth={!isMin768}
          >
            {!skippedBankingFirst && !isBankingFinished && 'Open banking account'}
            {(skippedBankingFirst || isBankingFinished) && 'Set up bookkeeping'}
          </BaselaneButton>
          {(skippedBankingFirst || isBankingFinished) && (
            <BaselaneButton
              size="sm"
              variant="transparent"
              onClick={() => handleBankingFirstSkipButton(onboardingTriageSelection)}
            >
              Set up later
            </BaselaneButton>
          )}
          {!skippedBankingFirst && !isBankingFinished && (
            <BaselaneButton size="sm" variant="transparent" onClick={handleBankingFirstSkipButton}>
              Skip for now
            </BaselaneButton>
          )}
        </Flex>
      </Flex>
      <BankingFirstSkipPopup
        isOpen={isBankingFirstSkipAlertOpen}
        onClose={onBankingFirstSkipAlertClose}
        onContinue={(isBankingClick) => {
          if (isBankingClick) {
            sendSegmentEvent('banking_first_skip_bank_click_open_bank_account');
            navigate(UNIFIED_LANDLORD_BANKING);
          } else {
            sendSegmentEvent('banking_first_skip_bank_click_continue_without');
            setSkippedBankingFirst(true);
            localStorage.setItem('skippedBankingFirst', 'true');
          }
        }}
      />
    </>
  );
};

export default OptionCardsScreens;
