import React, { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Stack, Text } from '@chakra-ui/react';
import { UNIFIED_RENT_COLLECTION } from '@routes';
import { generalRedirectToURC } from '@pages/UnifiedRentCollection/UnifiedLeaseUI/helpers/unifiedLeaseFlow.helpers';
import getBreakPoints from '@core/utils/getBreakPoints';

import ActionBox from './ActionBox';
import { quickActionsData } from '../../helpers/quickActions.helpers';
import { getPropertyLink } from '../../helpers/links/addProperty.helpers';
import { getExternalAccLink } from '../../helpers/links/addExternalAcc.helpers';
import { containerStyles } from '../../styles/shared.styles';
import { titleStyles, listStyles } from '../../styles/quickActions.styles';

type QuickActionsProps = {
  refetchUser: Function,
  handleStepUpdate: Function,
  propertiesProps: Object,
  kycProps: Object,
  onBaselaneApplicationTypePopupOpen: Function,
};

const QuickActions = ({
  refetchUser,
  handleStepUpdate,
  propertiesProps,
  onBaselaneApplicationTypePopupOpen,
}: QuickActionsProps) => {
  const { isMax768: isMobile } = getBreakPoints();
  const navigate = useNavigate();
  const location = useLocation();
  const addExternalAccountDrawerRef = useRef();

  const handleStartRentCollectionClick = () => {
    const state = { from: location.pathname };
    const pathname = UNIFIED_RENT_COLLECTION;
    generalRedirectToURC({ navigate, pathname, state });
  };

  const getLinkTo = (action) => {
    const { key, icon, title } = action;
    switch (key) {
      case 'add_property':
        return getPropertyLink({
          key: 'quick_actions_add_property',
          from: { page: 'get_started', section: 'quick_actions', configs: { icon, title } },
          handleStepUpdate,
          refetchUser,
          propertiesProps,
        });

      case 'set_up_rc':
        return (
          <ActionBox
            key="quick_actions_set_up_rc"
            action={{
              icon,
              title,
              onClick: handleStartRentCollectionClick,
            }}
          />
        );

      case 'open_baselane_acc':
        return (
          <ActionBox
            key="quick_actions_open_baselane_acc"
            action={{
              icon,
              title,
              onClick: onBaselaneApplicationTypePopupOpen,
            }}
          />
        );

      case 'connect_external_acc':
        return getExternalAccLink({
          key: 'quick_actions_add_external_acc',
          from: { page: 'get_started', section: 'quick_actions', configs: { icon, title } },
          refetchUser,
          addExternalAccountDrawerRef,
        });

      default:
        return null;
    }
  };

  return (
    <Stack {...containerStyles(isMobile)}>
      {/* Title */}
      <Text {...titleStyles}>Quick Actions</Text>

      {/* Quick Actions */}
      <Box {...listStyles()}>{quickActionsData.map((listItem) => getLinkTo(listItem))}</Box>
    </Stack>
  );
};

export default QuickActions;
