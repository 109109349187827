import React from 'react';
import { HStack, InputGroup, Input, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { Formik } from 'formik';
import BaselaneResponsiveCell from './BaselaneResponsiveCell';
import { wFull } from '../styles/shared.styles';

type BaselaneResponsiveCellInputProps = {
  /**
   * Cell value
   */
  value: String | Number,
  /**
   * Input name
   */
  name: String,
  /**
   * Function that updates cell value
   */
  handleSubmit?: Function,
  /**
   * Function for optional onBlur behaviour
   */
  handleBlur?: Function,
  /**
   * Placeholder text for input. Default would be empty string.
   */
  placeholder?: string,
  /**
   * Cell input type. Default would be text.
   */
  type?: String,
  /**
   * Element for left icon
   */
  leftGraphicElement?: React.Node,
  /**
   * Element for right icon
   */
  rightGraphicElement?: React.Node,
  /**
   * Cell element alignment
   */
  align?: String,
  /**
   * Cell element styles
   */
  styles?: Object,
  /**
   * Cell children
   */
  children?: React.Node,
  /**
   * Pass value to parent
   */
  handleStateUpdate?: Function,
  enableReinitialize: boolean,
};

/**
 * Renders a variation of BaselaneReponsiveCell to be used inside BaselaneResponsiveTableRow.
 *
 * @returns {JSX.Element}
 */
const BaselaneResponsiveCellInput = ({
  value,
  placeholder,
  name,
  type,
  handleSubmit,
  handleBlur,
  handleStateUpdate,
  leftGraphicElement,
  rightGraphicElement,
  children,
  align,
  styles,
  enableReinitialize,
  ...rest
}: BaselaneResponsiveCellInputProps) => {
  const initialInputValues = Object.freeze({
    [`${name}`]: value || '',
  });

  const { input: inputStyles, icon: iconStyles } = styles;

  return (
    <BaselaneResponsiveCell {...rest}>
      <HStack {...{ ...wFull }}>
        <Formik initialValues={initialInputValues} enableReinitialize={enableReinitialize}>
          {({ values, handleChange }) => (
            <form>
              <InputGroup>
                {leftGraphicElement && (
                  <InputLeftElement pointerEvents="none" {...iconStyles}>
                    {leftGraphicElement}
                  </InputLeftElement>
                )}
                <Input
                  {...{ height: '32px', ...inputStyles }}
                  type={type}
                  placeholder={placeholder}
                  id={name}
                  name={name}
                  value={values?.[`${name}`]}
                  onChange={handleChange}
                  onBlur={(e) => {
                    const newValue = e.target.value;
                    handleStateUpdate(newValue);
                  }}
                />
                {rightGraphicElement && (
                  <InputRightElement pointerEvents="none" {...iconStyles}>
                    {rightGraphicElement}
                  </InputRightElement>
                )}
              </InputGroup>
            </form>
          )}
        </Formik>
      </HStack>
      {children}
    </BaselaneResponsiveCell>
  );
};

BaselaneResponsiveCellInput.defaultProps = {
  leftGraphicElement: null,
  rightGraphicElement: null,
  handleBlur: null,
  handleSubmit: null,
  handleStateUpdate: null,
  styles: {},
  align: 'left',
  children: null,
  type: 'text',
  placeholder: '',
};

export default BaselaneResponsiveCellInput;
