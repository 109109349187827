import React from 'react';
import { Heading, HStack, VStack, Text, Box } from '@chakra-ui/react';
import {
  contentContainer,
  graphicElementContainer,
  subtextStyle,
  titleTextStyle,
} from '../styles/cell.styles';
import { wFull } from '../styles/shared.styles';

type BaselaneResponsiveCellTitleContentProps = {
  /**
   * Cell title
   */
  title: String,
  /**
   * Cell preheader
   */
  preheader: String,
  /**
   * Cell subtitle
   */
  subtitle?: String,
  /**
   * Title size token
   */
  titleSize?: String,
  /**
   * Cell element on left of title and subtitle. Can be used to any type of element
   * at the moment most common case is image/icon
   */
  graphicElement?: React.Node,
  /**
   * Cell element on right of title
   */
  rightTitleElement?: React.Node,
  /**
   * Cell layout - display children vertically
   */
  isVertical?: String,
  /**
   * Cell element styles
   */
  styles?: Object,
  /**
   * Cell element text styles
   */
  subtitleTextStyle?: Object,
  /**
   * Cell element - display element next to the title
   */
  rightElement?: React.Node,
  /**
   * Cell children
   */
  children?: React.Node,
};

/**
 * Renders title cell content that is reusable.
 *
 * @returns {JSX.Element}
 */
const BaselaneResponsiveCellTitleContent = ({
  title,
  preheader,
  subtitle,
  titleSize,
  graphicElement,
  rightTitleElement,
  isVertical,
  styles,
  subtitleTextStyle,
  rightElement,
  children,
}: BaselaneResponsiveCellTitleContentProps) => {
  return (
    <>
      <HStack {...{ overflow: 'hidden', w: 'inherit', gap: 0, ...styles?.container }}>
        {graphicElement && (
          <Box {...{ ...graphicElementContainer, ...styles?.graphicElementContainer }}>
            {graphicElement}
          </Box>
        )}
        <VStack {...{ ...contentContainer({ isVertical }), ...styles?.textContainer }}>
          {preheader && (
            <Text
              {...{ ...subtextStyle, ...styles?.subtitleTextStyle }}
              textStyle={subtitleTextStyle}
            >
              {preheader}
            </Text>
          )}
          <HStack {...{ overflow: 'hidden', ...wFull, ...styles?.titleContainer }}>
            <Heading as="h4" {...{ ...titleTextStyle, ...styles?.titleTextStyle }} size={titleSize}>
              {title}
            </Heading>
            {rightTitleElement}
          </HStack>
          {subtitle && (
            <Text
              {...{ ...subtextStyle, ...styles?.subtitleTextStyle }}
              textStyle={subtitleTextStyle}
            >
              {subtitle}
            </Text>
          )}
        </VStack>
        {rightElement}
      </HStack>
      {children}
    </>
  );
};

BaselaneResponsiveCellTitleContent.defaultProps = {
  subtitle: null,
  titleSize: 'sm-heavy',
  graphicElement: null,
  rightTitleElement: null,
  isVertical: false,
  styles: {},
  subtitleTextStyle: 'xs',
  rightElement: null,
  children: null,
};

export default BaselaneResponsiveCellTitleContent;
