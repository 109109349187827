export const footerAsFeaturedInTextStyles = {
  color: 'brand.neutral.200',
  fontSize: 'xs',
  mb: { base: '0', md: '6px' },
};

export const linkStyle = {
  color: 'brand.primary.500',
  textDecoration: 'underline',
  fontSize: { xs: '3xs', sm: '3xs', md: 'sm' },

  _hover: {
    textDecoration: 'underline',
    fontSize: { xs: '3xs', sm: '3xs', md: 'sm' },
  },
};
