import React from 'react';
import { Text } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { useFormikContext } from 'formik';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import AddBankAccount from '@core/components/AddBankAccount';
import ConditionalAddAccount from '@core/components/NativeBankingPage/MainContent/components/BankTransfer/ConditionalAddAccount';
import { resyncLinkStyles } from '@pages/LeasesPage/LeaseSection/styles/receivingBankAccount.styles';
import useBankSummary from '@core/hooks/useBankSummary';
import { RESYNC_BANK_ACCOUNT_BALANCE } from '@core/components/NativeBankingPage/queries';

const useTransferFromAccountValidation = ({
  openEducationalDrawer,
  updatedLinkTokenData,
  updateLinkTokenIsLoading,
}) => {
  const { values, validateForm } = useFormikContext();
  const { bankAccountsById, refetch: refetchActiveBankAccounts } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
    fetchPolicy: 'network-only',
  });
  const { banks } = useBankSummary();
  const [resyncBankAccountBalance] = useMutation(RESYNC_BANK_ACCOUNT_BALANCE, {
    onCompleted: (res) => {
      if (res && res?.resyncBankAccountBalance && !res?.errors) {
        refetchActiveBankAccounts().then(() => validateForm());
      }
    },
    onError: (err) => console.error(err),
  });

  const validate = (value) => {
    let error;
    const transferFromAccount = bankAccountsById[value];
    const isBalanceUnavailableFrom = () => {
      return (
        transferFromAccount &&
        (!transferFromAccount?.availableBalance || transferFromAccount?.availableBalance === null)
      );
    };

    if (!value) {
      error = 'Please select account';
    }

    if (transferFromAccount?.isExternal && transferFromAccount?.errorCodes?.isGenericError) {
      error = (
        <Text as="span">
          <Text as="span">
            This account can&apos;t be used to initiate a transfer from Baselane. Use a different
            account or initiate the transfer from your external account using your
          </Text>{' '}
          <Text
            as="span"
            textDecoration="underline"
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={openEducationalDrawer}
          >
            Baselane Banking account & routing numbers.
          </Text>
        </Text>
      );
    }

    if (
      transferFromAccount?.isExternal &&
      transferFromAccount?.errorCodes?.isInstitutionAuthNotSupported
    ) {
      error = (
        <Text as="span">
          <Text as="span">
            This account can&apos;t be used to initiate a transfer from Baselane. Use a different
            account or initiate the transfer from your external account using your
          </Text>{' '}
          <Text
            as="span"
            textDecoration="underline"
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={openEducationalDrawer}
          >
            Baselane Banking account & routing numbers.
          </Text>
        </Text>
      );
    }

    if (transferFromAccount?.isExternal && transferFromAccount?.errorCodes?.isNameCheckFailed) {
      error = (
        <Text as="span">
          <Text as="span">
            This account can&apos;t be used to initiate a transfer from Baselane. Its legal name
            does not match your Baselane Banking account(s) name. Use a different account or
            initiate the transfer from your external account using your
          </Text>{' '}
          <Text
            as="span"
            textDecoration="underline"
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={openEducationalDrawer}
          >
            Baselane Banking account & routing numbers.
          </Text>
        </Text>
      );
    }

    if (
      transferFromAccount?.isExternal &&
      transferFromAccount?.errorCodes?.isOAuthNamePermissionMissing
    ) {
      error = (
        <Text as="span">
          <Text as="span">
            This account can&apos;t be used to initiate a transfer from Baselane. You did not grant
            sufficient permissions to verify the account.
          </Text>{' '}
          <ConditionalAddAccount
            refetchTransferAccountsList={() => {
              refetchActiveBankAccounts().then(() => validateForm());
            }}
            transferType="TRANSFER_IN"
            accountProps={{
              isDirectToPlaid: true,
              hideButton: true,
              isDirectToPlaidButtonProps: {
                showCustomButton: true,
                hasIconLock: false,
                hasRightChevronIcon: false,
                titleText: 'Resync your account and update permissions.',
                containerStyles: { as: 'span' },
                type: 'link',
                variant: 'link',
                palette: 'danger',
                size: 'sm',
                styles: resyncLinkStyles,
              },
            }}
            styles={{ container: { as: 'span' } }}
          />
        </Text>
      );
    }

    if (
      transferFromAccount?.isExternal &&
      transferFromAccount?.institutionName !== 'manual' &&
      isBalanceUnavailableFrom()
    ) {
      error = (
        <Text as="span">
          <Text as="span">We were unable to fetch the balance for this account.</Text>{' '}
          <Text
            as="span"
            textDecoration="underline"
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              resyncBankAccountBalance({
                variables: { id: values?.transferFromAccountId },
              });
            }}
          >
            Click here to resync your account balance.
          </Text>
        </Text>
      );
    }

    if (transferFromAccount?.isExternal && transferFromAccount?.errorCodes?.isAccountDisconnected) {
      error = (
        <Text as="span">
          Account connection expired.{' '}
          <AddBankAccount
            bankAccId={value}
            mode="RESYNC"
            titleText="Resync your account"
            size="sm"
            variant="link"
            palette="danger"
            styles={resyncLinkStyles}
            showCustomButton
            hasIconLock={false}
            hasRightChevronIcon={false}
            containerStyles={{ as: 'span' }}
            banks={banks}
            state={transferFromAccount?.isBankConnected ? 'CONNECTED' : 'ITEM_LOGIN_REQUIRED'}
            isLoading={updateLinkTokenIsLoading}
            handleSuccessFn={(res) => {
              const { connectionState } = res?.data?.reSyncExternalBankAccount ?? {};
              if (connectionState === 'CONNECTED') {
                refetchActiveBankAccounts().then(() => validateForm());
              }
            }}
            updateLinkToken={updatedLinkTokenData && updatedLinkTokenData.updatePlaidLinkToken}
          />{' '}
          to enable transfers.
        </Text>
      );
    }

    return error;
  };

  return { validate };
};

export default useTransferFromAccountValidation;
