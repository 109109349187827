import { isMobile } from 'react-device-detect';
import customTheme from '@core/theme';

export const containerStyles = {
  maxWidth: isMobile ? 'none' : '300px',
  align: isMobile ? 'center' : 'flex-start',
};

export const textContainerStyles = {
  textStyle: 'sm',
  color: customTheme.colors.brand.neutral['700'],
};
