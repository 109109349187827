// @flow
import React from 'react';
import { Box, HStack, Spacer, Stack } from '@chakra-ui/react';
import { BaselaneButtonIcon } from '@core/components/Shared/components';
import { Icon16Filter } from '@icons/16px';
import getBreakPoints from '@core/utils/getBreakPoints';
import { getFilterItem } from './helpers/filterBar.helpers';
import { invoiceFilterBarStyles } from '../../../styles/invoice.styles';

type FilterBarProps = {
  invoiceFilters: Object,
  handleFilterClick: Function,
};

function FilterBar({ invoiceFilters, handleFilterClick }: FilterBarProps): any {
  const { isMinXL } = getBreakPoints();

  // Destructure Imported Styles
  const { mobilecontainer, container, filter: filterStyles } = invoiceFilterBarStyles({
    isMinXL,
  });

  // Destructure filters Data
  const { state, dateType, date } = invoiceFilters ?? {};

  const filterBar = (
    <HStack {...container}>
      {isMinXL ? (
        <Stack {...filterStyles.container}>
          <HStack gap="16px">
            {/* Payment Filter */}
            {getFilterItem('payment', state, filterStyles, isMinXL)}
            {/* View By Filter */}
            {getFilterItem('dateType', dateType, filterStyles, isMinXL)}
          </HStack>
          {/* Date Range Filter */}
          {getFilterItem('date', date, filterStyles, isMinXL)}
        </Stack>
      ) : (
        <HStack {...filterStyles.container}>
          {/* Payment Filter */}
          {getFilterItem('payment', state, filterStyles)}
          {/* View By Filter */}
          {getFilterItem('dateType', dateType, filterStyles)}
          {/* Date Range Filter */}
          {getFilterItem('date', date, filterStyles)}
        </HStack>
      )}

      <Spacer />

      <BaselaneButtonIcon
        variant="filled"
        palette="primary"
        size="lg"
        icon={<Icon16Filter color="#fff" />}
        onClick={handleFilterClick}
      />
    </HStack>
  );

  return isMinXL ? <Box {...mobilecontainer}>{filterBar}</Box> : filterBar;
}

export default FilterBar;
