import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

/**
 * This overrides Chakra's default Radio styles.
 *
 * Note: _control sets the styles for the actual radio
 *
 * see: node_modules/@chakra-ui/theme/src/components/radio.ts for implementation details
 *
 */

const parts = ['container', 'control', 'label'];
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts);

export const Radio = defineMultiStyleConfig({
  baseStyle: definePartsStyle((props) => {
    const { colors } = props.theme;

    return {
      container: { pt: 0.5, mr: 1 },
      control: {
        border: '1px solid',
        borderColor: 'brand.darkBlue.200',
        _checked: {
          border: 'none',
          bg: 'brand.blue.800A',
          _hover: {
            background: 'brand.blue.700',
          },

          _focusVisible: {
            boxShadow: `0 0 0 1px white inset, 0 0 0 1.5px ${colors.brand.blue['800A']}`,
          },
          _focus: {
            background: 'brand.blue.900',
          },
          _disabled: {
            color: 'brand.neutral.400',
            background: 'brand.neutral.200',
            cursor: 'not-allowed',
            opacity: '1',
          },
          _invalid: {
            background: 'red.700',
            borderColor: 'red.700',
            boxShadow: 'none',
          },
        },
        _disabled: {
          background: 'brand.neutral.200',
          borderColor: 'brand.neutral.200',
          cursor: 'not-allowed',
          opacity: '1',
          _hover: {
            background: 'brand.neutral.200',
            borderColor: 'brand.neutral.200',
          },
          _focusVisible: {
            background: 'brand.neutral.200',
            borderColor: 'brand.neutral.200',
            boxShadow: 'none',
          },
          _focus: {
            background: 'brand.neutral.200',
            borderColor: 'brand.neutral.200',
            boxShadow: 'none',
          },
        },
        _invalid: {
          borderColor: 'red.700',
          _hover: {
            borderColor: 'red.700',
          },
          _focusVisible: {
            border: '1px solid',
            borderColor: 'red.700',
            boxShadow: `0 0 0 1px ${colors.red['300']} inset`,
            outline: '0 !important',
          },
          _focus: {
            border: '1px solid',
            borderColor: 'red.700',
            boxShadow: `0 0 0 1px ${colors.red['300']} inset`,
            outline: '0 !important',
          },
        },
        _hover: {
          borderColor: 'brand.darkBlue.300',
        },
        _active: {
          borderColor: 'brand.darkBlue.300',
        },
        _focusVisible: {
          border: 'none',
          boxShadow: `0 0 0 1px ${colors.brand.blue['100']} inset, 0 0 0 1px ${colors.brand.blue['800A']}`,
          outline: '0 !important',
        },
        _focus: {
          border: 'none',
          boxShadow: `0 0 0 1px ${colors.brand.blue['100']} inset, 0 0 0 1px ${colors.brand.blue['800A']}`,
          outline: '0 !important',
        },
      },
      label: {
        color: 'brand.red.700',
        _isRequired: {
          color: 'red.700', // not sure if it works
        },
        _isInvalid: {
          color: 'red.700', // not sure if it works
        },
      },
    };
  }),
});
