import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Text, Box, UnorderedList } from '@chakra-ui/react';
import customTheme from '@theme';
import BaselaneBadge from '../BaselaneBadge';
import BaselaneButton from '../BaselaneButton';
import BaselaneCard from './BaselaneCard';
import { CustomListItemElement, ListItemElement } from './BaselaneEmptyStateCard.helper';
import icons from '../icons';
import {
  emptyStateCardStyles,
  emptyStateContentStyles,
  emptyStateTitleStyles,
} from './styles/emptyState.styles';

/**
 * Parent container of the empty state card.
 * @param title (optional) if we only want to show title
 * @param children
 * @param styles (optional) to update original styles
 * @returns {JSX.Element}
 */
const BaselaneEmptyStateCard = ({
  title,
  children,
  styles,
  headerStyles,
}: {
  title?: String,
  children: any,
  styles?: Object,
  headerStyles?: Object,
}) => (
  <BaselaneCard
    styles={{
      ...emptyStateCardStyles,
      ...styles,
    }}
  >
    <Box {...emptyStateContentStyles}>
      {title && (
        <Text {...emptyStateTitleStyles} {...headerStyles}>
          {title}
        </Text>
      )}
      {children}
    </Box>
  </BaselaneCard>
);

/**
 * Use this when we want to add more elements along with the title.
 * @param children
 * @param props
 * @returns {JSX.Element}
 */
BaselaneEmptyStateCard.TitleSection = ({ children, ...props }: { children: any }) => (
  <Text {...emptyStateTitleStyles} {...props}>
    {children}
  </Text>
);

/**
 * Simple text element that will display text in a certain style. Can choose to add props to modify
 * when needed
 * @param children
 * @param props
 * @returns {JSX.Element}
 */
BaselaneEmptyStateCard.Paragraph = ({ children, ...props }: { children: any }) => (
  <Text textStyle="sm" mb="24px" {...props}>
    {children}
  </Text>
);

/**
 * Simple badge element
 * @param type (default: master) otherwise pass in the type when needed.
 * @param color
 * @param text
 * @param props
 * @returns {JSX.Element}
 */
BaselaneEmptyStateCard.Badge = ({
  type = 'master',
  color,
  text,
  ...props
}: {
  type?: string,
  color?: string,
  text?: string,
}) => {
  return <BaselaneBadge type={type} color={color} text={text} {...props} />;
};

/**
 * Takes in children of elements to create a list.
 * Using icons.js file to populate custom icons. Feel free to add to it if there is a new icon.
 * @param type (default: bullet) otherwise pass in the icon that is needed.
 * @param children
 * @param props
 * @param iconMarginRight
 * @param iconWidth
 * @param iconHeight
 * @param spacing: spacing between the bullet points
 * @returns {JSX.Element|*}
 */
BaselaneEmptyStateCard.BulletPoints = ({
  type = 'bullet',
  children,
  iconMarginRight,
  iconWidth,
  iconHeight,
  spacing,
  ...props
}: {
  type?: string,
  iconMarginRight?: string,
  iconWidth?: string,
  iconHeight?: string,
  spacing?: string,
  children: any,
}) => {
  const { 700: blue } = customTheme.colors.brand.blue;

  if (type === 'bullet') {
    return (
      <UnorderedList {...props}>
        {children.length ? (
          children.map((child) => {
            const key = uuidv4();
            return (
              <ListItemElement key={key} mb={spacing}>
                {child}
              </ListItemElement>
            );
          })
        ) : (
          <ListItemElement {...props}>{children}</ListItemElement>
        )}
      </UnorderedList>
    );
  }
  const IconElement = icons[type];
  const icon = (
    <Box mr={iconMarginRight}>
      <IconElement
        {...(iconWidth ? { w: iconWidth } : {})}
        {...(iconHeight ? { w: iconHeight } : {})}
        color={blue}
      />
    </Box>
  );

  return children.length ? (
    children.map((child) => {
      const key = uuidv4();

      return (
        <CustomListItemElement key={key} {...props}>
          {icon}
          {child}
        </CustomListItemElement>
      );
    })
  ) : (
    <CustomListItemElement {...props}>
      {icon}
      {children}
    </CustomListItemElement>
  );
};

/**
 * Simple button element
 * @param route
 * @param type
 * @param onClick
 * @param children
 * @param props
 * @returns {JSX.Element}
 */
BaselaneEmptyStateCard.Button = ({
  route,
  variant,
  type,
  palette,
  onClick,
  children,
  ...props
}: {
  route?: string,
  type?: string,
  variant: string,
  palette: string,
  onClick?: string,
  children: any,
}) => {
  const navigate = useNavigate();

  return (
    <BaselaneButton
      type={type}
      variant={variant}
      palette={palette}
      size="lg"
      onClick={() => {
        if (route !== '') navigate(route);
        else onClick();
      }}
      {...props}
    >
      {children}
    </BaselaneButton>
  );
};

BaselaneEmptyStateCard.defaultProps = {
  title: null,
  styles: {},
  headerStyles: {},
};

BaselaneEmptyStateCard.Badge.defaultProps = {
  type: 'master',
  color: '',
  text: '',
};

BaselaneEmptyStateCard.BulletPoints.defaultProps = {
  type: 'bullet',
  iconMarginRight: '26px',
  iconWidth: null,
  iconHeight: null,
  spacing: '12px',
};

BaselaneEmptyStateCard.Button.defaultProps = {
  type: 'primary',
  route: '',
  onClick: () => {},
};

export default BaselaneEmptyStateCard;
