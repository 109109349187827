import React from 'react';

type IconVerifyEmailProps = { w?: Number, h?: Number, color?: string, fillColor?: string };

function IconVerifyEmail({
  w = 41,
  h = 37,
  color = '#192C3E',
  fillColor = '#86ABF8',
}: IconVerifyEmailProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 41 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.576 2.23503C1.72236 2.00087 2.03083 1.92968 2.265 2.07603L18.265 12.076C18.4992 12.2224 18.5704 12.5309 18.424 12.765C18.2776 12.9992 17.9692 13.0704 17.735 12.924L1.735 2.92403C1.50084 2.77768 1.42965 2.4692 1.576 2.23503Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.424 2.23503C34.2776 2.00087 33.9692 1.92968 33.735 2.07603L17.735 12.076C17.5008 12.2224 17.4296 12.5309 17.576 12.765C17.7224 12.9992 18.0308 13.0704 18.265 12.924L34.265 2.92403C34.4992 2.77768 34.5704 2.4692 34.424 2.23503Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 4.5C0.5 2.01472 2.51472 0 5 0H31.3137C33.799 0 35.8137 2.01472 35.8137 4.5V15C35.8137 15.2761 35.5899 15.5 35.3137 15.5C35.0376 15.5 34.8137 15.2761 34.8137 15V4.5C34.8137 2.567 33.2467 1 31.3137 1H5C3.067 1 1.5 2.56701 1.5 4.5V18.0686C1.5 20.0016 3.067 21.5686 5 21.5686H17C17.2761 21.5686 17.5 21.7925 17.5 22.0686C17.5 22.3448 17.2761 22.5686 17 22.5686H5C2.51472 22.5686 0.5 20.5539 0.5 18.0686V4.5Z"
        fill={color}
      />
      <circle cx="30.9999" cy="26.5" r="10" fill={fillColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3081 16.322C23.3719 16.322 19.3404 20.4762 19.3404 25.6382C19.3404 30.8001 23.3719 34.9543 28.3081 34.9543C31.1181 34.9543 33.6304 33.6117 35.2779 31.5007C35.444 31.2879 35.7513 31.25 35.9641 31.4161C36.177 31.5822 36.2148 31.8895 36.0487 32.1023C34.2289 34.4341 31.4397 35.9321 28.3081 35.9321C22.7989 35.9321 18.3626 31.3065 18.3626 25.6382C18.3626 19.9698 22.7989 15.3442 28.3081 15.3442C33.8174 15.3442 38.2537 19.9698 38.2537 25.6382C38.2537 25.6705 38.2535 25.7028 38.2533 25.735L39.1991 24.7541C39.3865 24.5597 39.696 24.5541 39.8903 24.7415C40.0847 24.9289 40.0904 25.2384 39.9029 25.4328L37.8021 27.6117C37.71 27.7072 37.583 27.7612 37.4502 27.7612C37.3175 27.7612 37.1905 27.7073 37.0983 27.6118L34.9962 25.4329C34.8087 25.2386 34.8143 24.9291 35.0086 24.7416C35.2029 24.5541 35.5124 24.5597 35.6999 24.754L37.2494 26.3601C37.267 26.1219 37.2759 25.8812 37.2759 25.6382C37.2759 20.4762 33.2444 16.322 28.3081 16.322ZM28.3081 19.702C28.5782 19.702 28.797 19.9209 28.797 20.1909V25.3818L31.7384 27.4149C31.9605 27.5684 32.0161 27.8729 31.8625 28.095C31.709 28.3171 31.4045 28.3727 31.1824 28.2192L28.0302 26.0403C27.8981 25.949 27.8193 25.7987 27.8193 25.6382V20.1909C27.8193 19.9209 28.0381 19.702 28.3081 19.702Z"
        fill={color}
      />
    </svg>
  );
}

export default IconVerifyEmail;
