import customTheme from '@core/theme';

export const badgeContainerStyles = (size) => ({
  width: 'fit-content',
  p: size === 'sm' ? '2px 8px' : '6px 10px',
  spacing: '5px',
  bgColor: customTheme.colors.yellow['100'],
  borderColor: 'brand.darkBlue.250',
  borderWidth: '1px',
  color: customTheme.colors.yellow['900'],
  fontWeight: 'semibold',
  lineHeight: size === 'sm' ? '14px' : '20px',
  borderRadius: '3px',
});
