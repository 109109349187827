import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';

type BalanceSummaryProps = {
  account: Object,
};

const BalanceSummary = ({ account }: BalanceSummaryProps) => {
  const pendingBalance = account.currentBalance - account.availableBalance;

  return (
    <Stack gap={1}>
      <Stack direction="row" justifyContent="space-between">
        <Text textStyle="xs" color="brand.neutral.700">
          Available Balance
        </Text>
        <Text textStyle="sm" color="brand.neutral.900">
          {formatCurrency(account.availableBalance).inDollars}
        </Text>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Text textStyle="xs" color="brand.neutral.700">
          Processing Payments
        </Text>
        <Text textStyle="sm" color="brand.neutral.900">
          {formatCurrency(pendingBalance).inDollars}
        </Text>
      </Stack>
    </Stack>
  );
};

export default BalanceSummary;
