import { isMobile } from 'react-device-detect';

export const optionButtonStyles = {
  borderRadius: '4px',
  border: 'solid 1px',
  borderColor: 'transparent',
  bg: 'transparent',
  p: '12px !important',
  h: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  color: 'brand.neutral.600',
  _hover: {
    bg: 'brand.blue.50',
    color: 'brand.blue.800A',
    borderColor: 'brand.blue.100',
  },
  _focus: {
    bg: 'brand.blue.50',
    color: 'brand.blue.800A',
    borderColor: 'brand.blue.100',
  },
  _selected: {
    bg: 'brand.blue.50',
    color: 'brand.blue.800A',
    borderColor: 'brand.blue.100',
  },
};

export const footerStyles = {
  display: 'flex',
  boxShadow: '1px -2px 6px 0px rgba(3, 24, 64, 0.04);',
  justifyContent: 'flex-start',
  flexDirection: 'row',
};

export const cancelButtonStyles = {
  color: 'brand.blue.800A',
  borderColor: 'brand.blue.800A',
};

export const headingStyles = {
  alignSelf: 'flex-start',
  fontSize: isMobile ? '2xl' : 'lg',
  lineHeight: isMobile ? '32px' : '26px',
  fontWeight: 'semibold',
};

export const triggerBtnWrapperStyles = { w: '64px', alignItems: 'center' };

export const triggerBtnLableStyles = {
  fontSize: '3xs',
  lineHeight: '14px',
  fontWeight: 'normal',
  color: 'brand.blue.800A',
  textAlign: 'center',
};
