import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useUTMParams = () => {
  const location = useLocation();
  const { search } = location || {};

  // eslint-disable-next-line camelcase
  const [utm_campaign, setUtmCampaign] = useState(null);
  // eslint-disable-next-line camelcase
  const [utm_source, setUtmSource] = useState(null);
  const [irclickid, setIrClickId] = useState(null);
  const [partnernid, setPartnerNid] = useState(null);

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      const utmSource = params.get('utm_source');
      const utmCampaign = params.get('utm_campaign');
      const irClickId = params.get('irclickid');
      const partnerNId = params.get('partnernid');

      if (utmSource) {
        setUtmSource(utmSource);
      }
      if (utmCampaign) {
        setUtmCampaign(utmCampaign);
      }
      if (irClickId) {
        setIrClickId(irClickId);
      }
      if (partnerNId) {
        setPartnerNid(partnerNId);
      }
    }
  }, [search]);

  return {
    // eslint-disable-next-line camelcase
    utm_campaign,
    // eslint-disable-next-line camelcase
    utm_source,
    irclickid,
    partnernid,
  };
};

export default useUTMParams;
