import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Text, useDisclosure } from '@chakra-ui/react';
import {
  BaselaneApplicationTypePopup,
  BaselaneButton,
  BaselaneEmptyStateCard,
  BaselaneLink,
} from '@shared/components';
import CardImage from '@core/components/NativeBankingPage/MainContent/components/Accounts/BaselaneAccounts/EmptyStateView/CardImage';
import getBreakPoints from '@core/utils/getBreakPoints';
import { TRANSFERS_PAYMENTS, UNIFIED_LB_PERSONAL_INFO } from '@routes';
import historyStateStorage from '@core/storage/historyStateStorage';
import BanksContext from '@contexts/BanksContext';
import { IllustrationBankingNewaccount } from '@illustrations';

import { emptyStateViewStyles } from './styles';

export function EmptyStateView() {
  const { isMin768 } = getBreakPoints();
  const navigate = useNavigate();

  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const { getKYCUrl } = useContext(BanksContext);

  const openLBFlow = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const state = { from: `/${TRANSFERS_PAYMENTS}` };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  const handleOpenBaselaneAccountClick = () => {
    onBaselaneApplicationTypePopupOpen();
  };

  const {
    conatinerStyles,
    titleTextStyles,
    emptyStateContainerStyles,
    headingTextStyles,
    imageContainerStyles,
    leftElementContainerStyles,
    rightElementContainerStyles,
    subtextStyles,
    disclaimerStyles,
  } = emptyStateViewStyles;

  return (
    <Stack {...conatinerStyles}>
      <Stack gap={2}>
        <Text {...titleTextStyles}>Get started with Baselane Banking</Text>
        <Stack direction={isMin768 ? 'row' : 'column'} {...emptyStateContainerStyles}>
          <Stack {...leftElementContainerStyles}>
            <Stack gap={1}>
              <IllustrationBankingNewaccount />
              <Text {...headingTextStyles}>Open Baselane Banking Account</Text>
              <Text {...subtextStyles}>Open in minutes. No account fees or minimum balances.</Text>
            </Stack>

            <Box>
              <BaselaneButton
                variant="filled"
                palette="primary"
                size="md"
                onClick={handleOpenBaselaneAccountClick}
              >
                Open Baselane Banking Account
              </BaselaneButton>
            </Box>
            <BaselaneApplicationTypePopup
              handleActionButton={openLBFlow}
              isOpen={isBaselaneApplicationTypePopupOpen}
              onClose={onBaselaneApplicationTypePopupClose}
            />
          </Stack>
          <Box {...rightElementContainerStyles}>
            <Box>
              <BaselaneEmptyStateCard.TitleSection fontSize="sm" mb={1.5}>
                Banking built for rental property finance
              </BaselaneEmptyStateCard.TitleSection>
              <BaselaneEmptyStateCard.BulletPoints
                type="check"
                iconMarginRight={0}
                alignItems="flex-start"
                mb={1.5}
                gap={1}
                _last={{ mb: 0 }}
              >
                <Text>
                  Separate accounts for properties, security deposits, savings buffers & more opened
                  with one click
                </Text>
                <Text>4.19% APY on savings accounts, FDIC insured</Text>
                <Text>Physical & virtual debit cards with spend controls</Text>
                <Text>Up to 5% cash back on debit spent card</Text>
              </BaselaneEmptyStateCard.BulletPoints>
            </Box>
          </Box>
          <Stack {...imageContainerStyles}>
            <CardImage />
          </Stack>
        </Stack>
      </Stack>

      <Stack mt={5} gap={1}>
        <Text {...disclaimerStyles}>
          Baselane is a financial technology company and not a bank. Banking services provided by
          Blue Ridge Financial; Member FDIC. The Baselane Visa Debit Card is issued by Blue Ridge
          Bank pursuant to a license from Visa U.S.A Inc. and may be used everywhere Visa debit
          cards are accepted. Cash back terms apply.
        </Text>
        <Text {...disclaimerStyles}>
          When Connecting an Account, your information is encrypted end-to-end. Therefore, your
          credentials will never be made accessible to Baselane. For more information, please see
          our{' '}
          <BaselaneLink
            variant="primary"
            size="sm"
            href="https://www.baselane.com/privacy-policy/"
            target="_blank"
          >
            Privacy Policy
          </BaselaneLink>
          .
        </Text>
      </Stack>
    </Stack>
  );
}
