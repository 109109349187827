import React from 'react';

type IconCircleOutlineProps = {
  w: Number,
  h: Number,
  color: String,
};

function IconCircleOutline({ w = 8, h = 8, color = '#C8D0DA' }: IconCircleOutlineProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="3.5" fill="white" stroke={color} />
    </svg>
  );
}

export default IconCircleOutline;
