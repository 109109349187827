import customTheme from '@core/theme';

export const cardContainerStyles = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: customTheme.colors.brand.darkBlue['150'],
  borderRadius: '4px',
  p: '6px 16px',
  _first: {
    paddingTop: '6px',
  },
  m: 'inherit',
};
