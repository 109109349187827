// @flow
import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { Text, Spinner } from '@chakra-ui/react';
import { autoSaveStyles } from './styles/autoSave.styles';

type AutoSaveProps = {
  formikRef: any,
  debounceMs: Number,
  elapsedTime: String,
};

const AutoSave = ({ formikRef, debounceMs, elapsedTime }: AutoSaveProps) => {
  const debouncedSubmit = useCallback(
    _.debounce(() => {
      const { initialValues, values } = formikRef.current ?? {};
      if (values?.note !== initialValues?.note) {
        formikRef.current?.submitForm();
      }
    }, debounceMs),
    [formikRef.current?.submitForm, debounceMs]
  );

  useEffect(() => debouncedSubmit, [debouncedSubmit, formikRef.current?.values.note]);

  // Destructure Imported Styles
  const { text, spinner } = autoSaveStyles ?? {};

  return formikRef.current?.isSubmitting ? (
    <Spinner {...spinner} />
  ) : (
    <Text {...text({ isNoteUpdated: elapsedTime })}>{`Saved - Last edit ${elapsedTime} ago`}</Text>
  );
};

export default AutoSave;
