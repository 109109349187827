import React from 'react';
import MaskedInput from 'react-text-mask';
import { Box, Input, Progress, Stack, Text } from '@chakra-ui/react';
import { BaselaneTooltip, BaselaneMessageCard } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import { currencyMask } from '@core/utils/masks';
import { IconInfo, IconWarningTriangleOutline } from '@icons';
import customTheme from '@core/theme';
import PeriodDropdown, {
  defaultPeriodOptions,
} from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/AddVirtualCard/components/PeriodDropdown';
import { formLabelStyles, formErrorStyles } from '@shared/styles/input.style';

import CancelEditBtn from '../../../../../components/IndividualInputEditButtons/CancelEditBtn';
import SaveChangesBtn from '../../../../../components/IndividualInputEditButtons/SaveChangesBtn';
import EditBtn from '../../../../../components/IndividualInputEditButtons/EditBtn';
import { frequencyMapper } from './helpers/format.helpers';
import {
  drawerLabel3Styles,
  drawerLabelStyles,
  drawerSectionStyles,
  drawerValue4Styles,
  mtdSpentTextStyles,
  progressBarStyles,
  spendingLimitTextStyles,
  moneySpentTextStyles,
  disabledTextStyles,
} from './styles/cardDetailsDrawer.styles';
import { inputFieldStyles } from './styles/editMode.styles';
import { limitInnerContainerStyles, tooltipWarningBackgroundStyles } from './styles/limits.styles';

type SpendingSectionProps = {
  editModeValues: Object,
  mtdSpent: string,
  isPhysicalCard: boolean,
  accountName: string,
  last4Digits: string,
  bankAccountBalance: number,
  handleOnEditChange: Function,
  handleEditModeToggleOrSave: Function,
  bankAccountLimits: Object,
  cardStatus: String,
};

const SpendingSection = ({
  editModeValues,
  mtdSpent,
  isPhysicalCard,
  accountName,
  last4Digits,
  bankAccountBalance,
  handleOnEditChange,
  handleEditModeToggleOrSave,
  bankAccountLimits = 0,
  cardStatus,
}: SpendingSectionProps) => {
  const moneySpent =
    editModeValues.spendingLimit.frequency === 'Daily'
      ? bankAccountLimits.cardDailyPurchaseTotal
      : mtdSpent;
  const limitAmount = editModeValues.spendingLimit.amount;
  const leftToSpend = limitAmount - moneySpent;
  const hasEnoughFunds = bankAccountBalance >= limitAmount;

  const progressPercent = limitAmount === 0 ? 0 : (moneySpent * 100) / limitAmount;
  const isExceeded90 = progressPercent >= 90;
  const isExceededMainDaily =
    !!bankAccountLimits?.cardDailyPurchaseTotal &&
    !!bankAccountLimits?.cardDailyPurchaseLimit &&
    (bankAccountLimits.cardDailyPurchaseTotal * 100) / bankAccountLimits.cardDailyPurchaseLimit >=
      90;
  const isExceededMainDailyEdit =
    editModeValues.spendingLimit.amount > bankAccountLimits?.cardDailyPurchaseLimit;
  const remainingBankAccountDailyLimit =
    bankAccountLimits.cardDailyPurchaseLimit - bankAccountLimits.cardDailyPurchaseTotal;

  const isSpendingFrequencyInvalid =
    editModeValues.spendingLimit.frequency === 'DAILY' && isExceededMainDailyEdit;

  return (
    <Box spacing={4}>
      {isExceededMainDaily && (
        <BaselaneMessageCard
          isVertical
          iconName="exclamationcircle"
          iconColor="warningyellow"
          borderColor="warningyellow"
          title="Approaching daily account limit"
          text={`This card is linked to ${accountName} - ${last4Digits}, which has ${
            formatCurrency(bankAccountLimits.cardDailyPurchaseLimit).inDollars
          } daily spend limit.
          Any card purchases surpassing this value may be declined. Remaining daily limit: ${
            formatCurrency(remainingBankAccountDailyLimit).inDollars
          }`}
          iconContainerStyles={{ w: '18px' }}
          containerStyles={{ mb: '32px' }}
        />
      )}

      {editModeValues.spendingLimit.isEdit ? (
        <Stack {...drawerSectionStyles}>
          <Stack direction="row" align="center">
            <Text {...formLabelStyles.xs}>Spending Limit</Text>
            <BaselaneTooltip
              label="Set a monthly or daily spending limit for the card. You can update the limit at any time."
              placement="auto"
              innerContainerStyles={{ ml: '9px !important' }}
            >
              <IconInfo width="16" height="16" color={customTheme.colors.brand.neutral['600']} />
            </BaselaneTooltip>
          </Stack>
          <Stack direction="row" align="center">
            <Input
              as={MaskedInput}
              mask={currencyMask({ decimalSymbol: '', decimalLimit: 0 })}
              id="spendingLimit.amount"
              name="amount"
              value={editModeValues.spendingLimit.amount?.toString()}
              isInvalid={isSpendingFrequencyInvalid}
              onChange={(e) => {
                const { name, id, value: tValue } = e.target;
                const [value] = tValue.split('$').reverse();
                const cleanValue = value.replace(/,/g, '');
                handleOnEditChange({ target: { name, id, value: Number(cleanValue) } });
              }}
              {...inputFieldStyles}
            />
            <Text {...drawerLabel3Styles}>/</Text>
            <PeriodDropdown
              {...{
                id: 'spendingLimit.frequency',
                startOption: defaultPeriodOptions.find(
                  (option) => option.value === editModeValues.spendingLimit.frequency
                ),
                onChange: handleOnEditChange,
                classNames: [
                  'input-form-md',
                  'fontSize-xs',
                  'color-neutral-900',
                  ...(isSpendingFrequencyInvalid ? ['input-invalid'] : []),
                ],
              }}
            />

            <CancelEditBtn
              onClick={() => handleEditModeToggleOrSave('spendingLimit', false)}
              variant="outline"
              palette="neutral"
              size="sm"
            />
            <SaveChangesBtn
              variant="filled"
              palette="primary"
              size="sm"
              isDisabled={
                editModeValues.spendingLimit.amount === 0 ||
                (editModeValues.spendingLimit.frequency === 'DAILY' && isExceededMainDailyEdit)
              }
              onClick={() => handleEditModeToggleOrSave('spendingLimit', false, true)}
            />
          </Stack>

          {editModeValues.spendingLimit.frequency === 'DAILY' && isExceededMainDailyEdit ? (
            <Text {...formErrorStyles}>
              Your daily limit cannot surpass account level purchase limit of{' '}
              {formatCurrency(bankAccountLimits.cardDailyPurchaseLimit).rounded}.
            </Text>
          ) : (
            <Text {...drawerLabelStyles} mt="8px">
              All cards will have a maximum spending limit of{' '}
              {formatCurrency(bankAccountLimits.cardDailyPurchaseLimit).rounded} a day.
            </Text>
          )}
        </Stack>
      ) : (
        <Box {...drawerSectionStyles}>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Stack spacing={1}>
              <Text {...drawerLabelStyles}>
                {editModeValues.spendingLimit.frequency === 'MONTHLY' || isPhysicalCard
                  ? 'This Month’s'
                  : 'Daily'}{' '}
                Amount Spent
              </Text>
              <Text
                {...mtdSpentTextStyles}
                {...moneySpentTextStyles(isExceeded90, isPhysicalCard)}
                {...disabledTextStyles(cardStatus)}
              >
                {formatCurrency(moneySpent).inDollars}
              </Text>
            </Stack>
            {!isPhysicalCard && (
              <Stack direction="row">
                <Stack spacing={1}>
                  <Text {...drawerLabelStyles} ml="30px">
                    Total Limit
                  </Text>
                  <Stack direction="row" {...limitInnerContainerStyles}>
                    <BaselaneTooltip
                      label="The available balance of the linked Baselane Banking account is less than the spending limit set for this card. Transactions over the available balance will be declined."
                      placement="auto"
                      innerContainerStyles={{
                        visibility:
                          hasEnoughFunds ||
                          cardStatus === 'Frozen' ||
                          cardStatus === 'SuspectedFraud'
                            ? 'hidden'
                            : 'visible',
                      }}
                    >
                      <Stack {...tooltipWarningBackgroundStyles}>
                        <IconWarningTriangleOutline />
                      </Stack>
                    </BaselaneTooltip>

                    <Text
                      {...drawerValue4Styles}
                      {...spendingLimitTextStyles(hasEnoughFunds)}
                      {...disabledTextStyles(cardStatus)}
                    >
                      {formatCurrency(editModeValues.spendingLimit.amount).inDollars}/
                      {frequencyMapper[editModeValues.spendingLimit.frequency]}
                    </Text>
                    <EditBtn
                      variant="transparent"
                      palette="primary"
                      size="sm"
                      isDisabled={cardStatus === 'Frozen' || cardStatus === 'SuspectedFraud'}
                      onClick={() => handleEditModeToggleOrSave('spendingLimit', true)}
                    />
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
          {!isPhysicalCard && (
            <>
              <Progress {...progressBarStyles(progressPercent, isExceeded90, cardStatus)} />
              <Text
                {...drawerLabel3Styles}
                {...moneySpentTextStyles(isExceeded90, isPhysicalCard, '600')}
                {...disabledTextStyles(cardStatus)}
              >
                {formatCurrency(leftToSpend).inDollars} left to spend
                {isExceeded90 ? '. Transactions exceeding limit will be declined.' : ''}
              </Text>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SpendingSection;
