import React from 'react';
import {
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  BaselaneButtonGroup,
} from '@shared/components';
import { IconExclamationCircle } from '@icons';
import {
  alertPopupIconBgStyles,
  alertPopupIconContainerStyles,
} from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/styles/lockCard.styles';

type ConfirmEditLimitChangesAlertProps = {
  isConfirmEditLimitPopupOpen: boolean,
  onConfirmEditLimitPopupClose: Function,
  handleUpdateCard: Function,
  updateCardLoading: boolean,
};

const ConfirmEditLimitChangesAlert = ({
  isConfirmEditLimitPopupOpen,
  onConfirmEditLimitPopupClose,
  handleUpdateCard,
  updateCardLoading,
}: ConfirmEditLimitChangesAlertProps) => {
  const title = 'Are you sure you want to make this change?';

  const footer = (
    <BaselaneButtonGroup size="md">
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="md"
        onClick={onConfirmEditLimitPopupClose}
      >
        Cancel
      </BaselaneButton>
      <BaselaneButton
        variant="filled"
        palette="danger"
        size="md"
        onClick={() => handleUpdateCard('spendingLimit', false, true)}
        isLoading={updateCardLoading}
      >
        Yes, Confirm
      </BaselaneButton>
    </BaselaneButtonGroup>
  );

  return (
    <BaselaneAlert
      showCloseButton
      header={
        <AlertHeader
          icon={<IconExclamationCircle />}
          title={title}
          iconBgStyles={alertPopupIconBgStyles}
          iconContainerStyles={alertPopupIconContainerStyles}
        />
      }
      isOpen={isConfirmEditLimitPopupOpen}
      onClose={onConfirmEditLimitPopupClose}
      isCentered
      body={null}
      footer={footer}
      size="2xl"
    />
  );
};

export default ConfirmEditLimitChangesAlert;
