export const actionButtonStyles = (iconSize, orientation) => {
  let spacing = '12px';
  let padding = '12px';
  if (iconSize === 'lg') {
    spacing = '12px';
    padding = '8px 12px';
  }

  return {
    padding,
    spacing,
    justifyContent: orientation === 'left' ? 'flex-start' : 'center',
  };
};

export const iconContainerStyles = (iconSize, iconContainerSize) => ({
  align: 'center',
  justifyContent: 'center',
  p: iconSize === 'lg' ? '4px' : '2px',
  h: iconContainerSize,
  w: iconContainerSize,
});
