import React from 'react';
import { isMobile } from 'react-device-detect';
import { useQuery } from '@apollo/client';
import { HStack, Text } from '@chakra-ui/react';
import BaselaneBadge from '@shared/components/BaselaneBadge';
import { IconRefresh } from '@icons';
import BaselaneResponsiveCard from '@shared/components/BaselaneResponsiveCard/BaselaneResponsiveCard';
import { GET_UPDATE_PLAID_LINK_TOKEN } from '@core/apollo/queries';

import AddBankAccount from '../../../../../../AddBankAccount';
import LinkedAccountStatus from './LinkedAccountStatus';
import { textXsMdStyles, truncateStyles } from './styles/text.styles';
import { cardContainerStyles } from './styles/linkedAccount.styles';

type LinkedAccountProps = {
  bankAccId?: string,
  bankAccountNickname: string,
  supportingTrx?: boolean,
  supportingTransfer?: boolean,
  supportingRC?: boolean,
  shouldResync?: boolean,
  handleResyncFn?: Function,
  onPlaidConfirmationClose?: Function,
  state: String,
};

const LinkedAccount = ({
  bankAccId,
  bankAccountNickname,
  supportingTrx,
  supportingTransfer,
  supportingRC,
  shouldResync,
  handleResyncFn,
  onPlaidConfirmationClose,
  state,
}: LinkedAccountProps) => {
  const { error: updateError, loading: updateLoading, data: updateData } = useQuery(
    GET_UPDATE_PLAID_LINK_TOKEN,
    {
      variables: { bankAccountId: bankAccId },
      skip: state !== 'ITEM_LOGIN_REQUIRED' || bankAccId === undefined || bankAccId === null,
    }
  );
  if (updateError && updateLoading) return null;

  const updateLinkToken = updateData && updateData.updatePlaidLinkToken;

  return (
    <BaselaneResponsiveCard {...cardContainerStyles}>
      <HStack w="100%" justifyContent="space-between">
        <Text {...textXsMdStyles} {...truncateStyles} my="10px">
          {bankAccountNickname}
        </Text>
        <HStack spacing={2}>
          {shouldResync ? (
            <AddBankAccount
              bankAccId={bankAccId}
              mode="RESYNC"
              titleText="Resync"
              variant="tonal"
              palette="primary"
              leftIcon={<IconRefresh />}
              handleSuccessFn={handleResyncFn}
              onPlaidConfirmationClose={onPlaidConfirmationClose}
              updateLinkToken={updateLinkToken}
              isLoading={updateLoading}
            />
          ) : (
            <LinkedAccountStatus {...{ supportingTrx, supportingTransfer, supportingRC }} />
          )}
          {isMobile && shouldResync ? null : (
            <BaselaneBadge
              type="badge"
              color={shouldResync ? 'red' : 'green'}
              text={shouldResync ? 'disconnected' : 'connected'}
            />
          )}
        </HStack>
      </HStack>
    </BaselaneResponsiveCard>
  );
};

LinkedAccount.defaultProps = {
  supportingTrx: false,
  supportingTransfer: false,
  supportingRC: false,
  shouldResync: true,
  bankAccId: null,
  handleResyncFn: () => {},
  onPlaidConfirmationClose: () => {},
};

export default LinkedAccount;
