import React from 'react';

function IconStatusOverdue() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.08" cx="12" cy="12" r="12" fill="#C93A3A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2679 6.3332C11.0379 5.00026 12.9619 5.00026 13.7318 6.3332L18.3505 14.3332C19.1206 15.6664 18.1584 17.3333 16.6186 17.3333H7.38122C5.8414 17.3333 4.87925 15.6665 5.6492 14.3333C5.64923 14.3333 5.64918 14.3334 5.6492 14.3333L10.2679 6.3332ZM11.4225 7.00012L6.80391 15C6.54734 15.4442 6.86777 16 7.38122 16H16.6186C17.132 16 17.4525 15.4443 17.1959 15.0001L12.5773 7.00012C12.3206 6.55573 11.6792 6.55573 11.4225 7.00012ZM11.9999 9.33333C12.3681 9.33333 12.6666 9.63181 12.6666 10V11.3333C12.6666 11.7015 12.3681 12 11.9999 12C11.6317 12 11.3332 11.7015 11.3332 11.3333V10C11.3332 9.63181 11.6317 9.33333 11.9999 9.33333ZM11.3332 14C11.3332 13.6318 11.6317 13.3333 11.9999 13.3333H12.0066C12.3747 13.3333 12.6732 13.6318 12.6732 14C12.6732 14.3682 12.3747 14.6667 12.0066 14.6667H11.9999C11.6317 14.6667 11.3332 14.3682 11.3332 14Z"
        fill="#C93A3A"
      />
    </svg>
  );
}

export default IconStatusOverdue;
