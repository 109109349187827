import React from 'react';
import { Box, Image, Spinner, Stack, Text } from '@chakra-ui/react';
import { BaselaneChip, BaselaneTooltip } from '@shared/components';
import AddBankAccount from '@core/components/AddBankAccount';
import isDisplayedBalanceNegative from '@core/utils/isDisplayedBalanceNegative';
import getBalanceToDisplay from '@core/utils/getBalanceToDisplay';
import formatCurrency from '@core/utils/formatCurrency';
import formatDateTime from '@core/utils/formatDateTime';
import { formatDate } from '@core/utils/formatDate';
import { accountStyles } from '../../../styles/account.styles';

const { image, logo, amount, connection } = accountStyles ?? {};

export const getLogo = ({ account, isMinXL }) => {
  if (account.logo !== null) {
    return (
      <Image
        src={`data:image/png;base64,${account.logo.toString()}`}
        alt={account.bankName}
        {...image}
      />
    );
  }

  if (account.bankName.length > 5) {
    return (
      <BaselaneTooltip
        label={account.bankName}
        styles={logo.tooltip.styles}
        innerContainerStyles={logo.tooltip.container}
      >
        <Stack {...logo.background}>
          <Text {...logo.text}>{isMinXL ? account.bankName.charAt(0) : account.bankName}</Text>
        </Stack>
      </BaselaneTooltip>
    );
  }

  return (
    <Stack {...logo.background}>
      <Text {...logo.text}>{account.bankName}</Text>
    </Stack>
  );
};

export const getBalance = ({ account, isResyncSuccessfull }) => {
  const accountBalance = getBalanceToDisplay(account);
  const getAmountColor = () => {
    return isDisplayedBalanceNegative(accountBalance, account.accountType)
      ? amount.negative
      : amount.positive;
  };

  return (
    <Box justifyContent={isResyncSuccessfull ? 'center' : 'left'}>
      {accountBalance !== null && account.isBankAccountConnected && account.isConnected ? (
        <Text {...{ ...getAmountColor(), ...amount.shared }}>
          {formatCurrency(accountBalance).inDollars}
        </Text>
      ) : (
        <>
          {isResyncSuccessfull && (
            <Box textAlign="center">
              <Spinner />
            </Box>
          )}
          {!isResyncSuccessfull && <Text {...amount.unavailable}>Balance Unavailable</Text>}
        </>
      )}
    </Box>
  );
};

export const getAccountDetails = ({ account }) => {
  const accountDetails = `${account.accountName}${
    account.accountNumber ? ` - ${account?.accountNumber?.slice(-4)}` : ''
  }`;
  return accountDetails;
};

export const getAccountNickname = ({ account }) => {
  const accountDetails = getAccountDetails({ account });
  const accountNickname = account.nickName || accountDetails;
  return accountNickname;
};

export const getAccountConnection = ({
  localupdatedAt,
  accountConnectionState,
  isMinXL,
  accountId,
}) => {
  const date = new Date(Number(localupdatedAt));
  const showLastUpdated = !isMinXL;

  return (
    <Stack gap="4px" overflow="hidden">
      {accountConnectionState === 'CONNECTED' ? (
        <BaselaneChip
          size={isMinXL ? 'lg' : 'md'}
          label="Connected"
          id={`connected-${accountId}`}
          variant="status-success"
        />
      ) : (
        <BaselaneChip
          size={isMinXL ? 'lg' : 'md'}
          label="Disconnected"
          id={`disconnected-${accountId}`}
          variant="status-danger"
        />
      )}
      {showLastUpdated && (
        <Text {...connection.text}>{`Last Update ${formatDateTime(date, true)}, ${formatDate(
          date
        )}`}</Text>
      )}
    </Stack>
  );
};

export const getResync = ({
  account,
  banks,
  updateLoading,
  updateLinkToken,
  setAccountConnectionState,
  setLocalUpdatedAt,
  setIsResyncSuccessfull,
  refetchBankSummary,
  accountConnectionState,
  isMinXL,
}) => {
  const handleSuccessFn = (res) => {
    const { connectionState, updatedAt } = res?.data?.reSyncExternalBankAccount || {};
    if (connectionState === 'CONNECTED') {
      setAccountConnectionState(connectionState);
      setLocalUpdatedAt(updatedAt);
      setTimeout(() => {
        refetchBankSummary();
      }, '3000');
    }
  };

  const refetchFunction = (res) => {
    const { connectionState } = res?.data?.reSyncExternalBankAccount || {};
    setIsResyncSuccessfull(connectionState);
  };

  return accountConnectionState === 'ITEM_LOGIN_REQUIRED' ? (
    <AddBankAccount
      bankAccId={account.id}
      mode="RESYNC"
      titleText="Resync"
      variant="tonal"
      palette="primary"
      size={isMinXL ? 'sm' : 'md'}
      banks={banks}
      state={accountConnectionState}
      handleSuccessFn={handleSuccessFn}
      refetchFunction={refetchFunction}
      isLoading={updateLoading}
      updateLinkToken={updateLinkToken}
      containerStyles={{ w: 'auto' }}
    />
  ) : (
    ''
  );
};
