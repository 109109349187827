import React from 'react';
import MaskedInput from 'react-text-mask';
import {
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Box,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import IconInfo from '@icons/legacy/IconInfo';
import { BaselaneTooltip, BaselaneSingleDatePicker } from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import { currencyMask } from '@core/utils/masks';
import getBreakPoints from '@core/utils/getBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import ToggleWrapper from '@pages/LeasesPage/LeaseSection/components/ToggleWrapper';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import { fieldContainer } from '../../styles/shared.styles';
import { tooltipContainerStyles } from '../../styles/depositsAndFees.styles';

type Field = {
  label: string,
  type: string,
};
type Fields = {
  [key: string]: Field,
};

type SecurityDepositSectionProps = {
  sectionInfo: Array<Fields>,
  sectionId: string,
  depositId: string,
  handleChange: Function,
  setFieldTouched: Function,
  setFieldValue: Function,
  setFormVariables: Function,
  values: Object,
  errors: Object,
  touched: Object,
  bankAccountsRef: any,
};

function SecurityDepositSection({
  sectionInfo = [],
  sectionId = '',
  depositId = '',
  handleChange = () => {},
  setFieldTouched = () => {},
  setFieldValue = () => {},
  setFormVariables = () => {},
  values = {},
  errors = {},
  touched = {},
  bankAccountsRef,
}: SecurityDepositSectionProps) {
  const { isMin768 } = getBreakPoints();
  const years = { start: 2022, end: 2043 };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCalendarClose = ({ date: dueDate, isFromApplyButton }) => {
    if (isMin768 || (!isMin768 && isFromApplyButton)) {
      setFieldValue('securityDepositDueDate', dueDate);
      const securityDepositDueDate = dueDate ? formatDate(dueDate, 'YYYY-MM-DD') : null;
      setFormVariables({
        payments: [{ id: depositId, name: 'DEPOSIT', dueDate: securityDepositDueDate }],
      });
    }
  };

  const titleSwitch = (label) => (
    <ToggleWrapper.Toggle
      direction="row"
      key="security_deposit_switch"
      title={label}
      htmlFor={sectionId}
      switchProps={{
        id: sectionId,
        isChecked: values.securityDeposit,
        value: values.securityDeposit,
        onChange: (e) => {
          handleChange(e);

          const shouldRemoveDepositBankValue =
            !values.securityDeposit && bankAccountsRef?.current?.values.depositBankAccountId;

          const variables = {
            ...(shouldRemoveDepositBankValue ? { depositBankAccount: null } : {}),
            payments: [
              {
                id: depositId,
                name: 'DEPOSIT',
                enabled: e.target.checked,
                amount: null,
                dueDate: null,
                paid: false,
              },
            ],
          };

          if (shouldRemoveDepositBankValue) {
            setFieldTouched('depositBankAccountId', false);
            setFieldValue('depositBankAccountId', null);
          }

          setFormVariables({ ...variables });

          if (!values.securityDeposit) {
            setFieldTouched('securityDepositAmount', false);
            setFieldTouched('securityDepositDueDate', false);
            setFieldValue('securityDepositAmount', '');
            setFieldValue('securityDepositDueDate', null);
            setFieldValue('securityDepositPaid', false);
          }
        },
      }}
    />
  );

  const tooltipText = (
    <Text textAlign="center">
      By marking as paid, no invoice to
      <br />
      collect this deposit will be generated.
      <br />
      However, a record of this payment will be
      <br /> available for you and your tenant.
    </Text>
  );

  const hasError =
    (errors.securityDepositDueDate && touched.securityDepositDueDate) ||
    (errors.securityDepositDueDateRequired && touched.securityDepositDueDate);

  return (
    <ToggleWrapper key="security_deposit_container" showBottomBorder={false} isNested>
      {titleSwitch(sectionInfo.title.label)}
      {values.securityDeposit && (
        <ToggleWrapper.Content
          key="security_deposit_switch_content"
          styles={{ spacing: '0', mt: isMobile ? '16px' : '24px', mb: '0' }}
        >
          <Stack
            direction={!isMobile ? 'row' : 'column'}
            spacing={isMobile ? '0' : '24px'}
            mb="8px"
          >
            {/* Security Deposit Amount */}
            <FormControl
              {...fieldContainer}
              isInvalid={errors.securityDepositAmount && touched.securityDepositAmount}
            >
              <FormLabel {...formLabelStyles.xs} htmlFor={sectionInfo.amount.label}>
                {sectionInfo.amount.label}
              </FormLabel>
              <Input
                {...formInputStyles}
                {...sectionInfo.amount.props}
                value={values.securityDepositAmount}
                as={MaskedInput}
                mask={currencyMask()}
                placeholder="$"
                inputMode="decimal"
                onChange={(e) => {
                  setFieldTouched('securityDepositAmount', true);
                  handleChange(e);
                }}
                onBlur={(e) => {
                  const amount = stripCurrency(e.target.value);
                  setFormVariables({
                    payments: [
                      {
                        id: depositId,
                        name: 'DEPOSIT',
                        amount,
                        dueDate: values.securityDepositDueDate,
                      },
                    ],
                  });
                }}
              />
              <FormErrorMessage {...formErrorStyles}>
                {errors.securityDepositAmount}
              </FormErrorMessage>
            </FormControl>

            {/* Security Deposit Due Date */}
            <FormControl
              {...fieldContainer}
              isInvalid={errors.securityDepositDueDate && touched.securityDepositDueDate}
            >
              <FormLabel {...formLabelStyles.xs} htmlFor={sectionInfo.dueDate.label}>
                {sectionInfo.dueDate.label}
              </FormLabel>
              <BaselaneSingleDatePicker
                {...{
                  ...sectionInfo.dueDate.props,
                  date: values.securityDepositDueDate,
                  value: values.securityDepositDueDate
                    ? `${formatDate(values.securityDepositDueDate, 'MMM DD, YYYY')}`
                    : '',
                  dateFormat: 'MMM dd, yyyy',
                  handleCalendarClose,
                  size: 'lg',
                  years,
                  isOpen,
                  onOpen,
                  onClose,
                  hideInputIcon: true,
                  fixedHeight: true,
                  placement: 'bottom-end',
                  showInModal: !isMin768,
                  className: `form-datepicker ${hasError ? 'has-error' : ''}`,
                }}
              />
              <FormErrorMessage {...formErrorStyles}>
                {errors.securityDepositDueDate}
              </FormErrorMessage>
            </FormControl>
          </Stack>

          {/* Mark as Paid */}
          <FormControl
            as={Stack}
            direction="row"
            mt={isMobile ? '16px !important' : '0px !important'}
            mb={isMobile ? '4px !important' : '8px !important'}
          >
            {!isMobile && (
              <Box {...tooltipContainerStyles}>
                <BaselaneTooltip label={tooltipText} styles={{ w: '303px' }} placement="top">
                  <IconInfo width="16" height="16" color="#3A4B5B" _hover={{ color: '#192C3E' }} />
                </BaselaneTooltip>
              </Box>
            )}
            {!isMobile && (
              <FormLabel {...formLabelStyles.sm} htmlFor={sectionInfo.paid.label} mb="0">
                {sectionInfo.paid.label}
              </FormLabel>
            )}
            <Checkbox
              size={isMobile ? 'lg' : 'md'}
              as="span"
              className="primary-500"
              {...sectionInfo.paid.props}
              isChecked={values.securityDepositPaid}
              onChange={handleChange}
              onBlur={(e) => {
                const paid = e.target.checked;
                const shouldRemoveDepositBankValue =
                  paid &&
                  values.securityDeposit &&
                  bankAccountsRef?.current?.values.depositBankAccountId;

                setFormVariables({
                  ...(shouldRemoveDepositBankValue ? { depositBankAccount: null } : {}),
                  payments: [{ id: depositId, name: 'DEPOSIT', paid }],
                });
              }}
            />
            {isMobile && (
              <FormLabel {...formLabelStyles.sm} htmlFor={sectionInfo.paid.label} mb="0">
                {sectionInfo.paid.label}
              </FormLabel>
            )}
          </FormControl>
        </ToggleWrapper.Content>
      )}
    </ToggleWrapper>
  );
}

export default SecurityDepositSection;
