import { useLazyQuery } from '@apollo/client';
import { GET_TENANT_LEASES } from './queries';

const useTenantLeasesQuery = ({
  tenantProfileId,
  leasesState = null,
  fetchPolicy = 'cache-first',
}) => {
  const [getTenantLeases, { loading, data, error }] = useLazyQuery(GET_TENANT_LEASES, {
    variables: { tenantProfileId, ...(leasesState ? { state: leasesState } : {}) },
    fetchPolicy,
  });

  return {
    getTenantLeases,
    tenantLeasesData: data?.leases?.leases ?? [],
    tenantLeasesLoading: loading,
    tenantLeasesError: error,
  };
};

export default useTenantLeasesQuery;
