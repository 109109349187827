// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=1640%3A79334
import React from 'react';
import { sortBy } from 'lodash';
import { BaselaneResponsiveTableRows } from '@shared/components';
import Account from './Account';

type AccountsProps = {
  banks: Array<Object>,
  refetchBankSummary: Function,
  isMinXL: String,
};

function Accounts({ banks, refetchBankSummary, isMinXL }: AccountsProps): any {
  const bankAccounts = banks
    .map((bank) =>
      bank.bankAccounts.reduce((acc, account) => {
        if (account.revokedByUser) {
          return acc;
        }

        acc.push({
          bankName: bank.plaidInstitutionName,
          logo: bank.logo,
          isBankConnected: bank.isConnected,
          isBankAccountConnected: account.connectionState !== 'ITEM_LOGIN_REQUIRED',
          isBankExternal: bank.isExternal,
          isRentCollectionSupported: bank.rentCollectionSupported,
          ...account,
        });

        return acc;
      }, [])
    )
    .flat();

  const sortedBankAccounts = sortBy(bankAccounts, ['bankName', 'nickName']);

  return (
    <BaselaneResponsiveTableRows
      customRow
      items={sortedBankAccounts}
      renderItem={(account) => {
        return (
          <Account
            {...{
              account,
              banks,
              refetchBankSummary,
              isMinXL,
            }}
          />
        );
      }}
    />
  );
}

export default Accounts;
