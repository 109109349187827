import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Stack, Text } from '@chakra-ui/react';
import { BaselaneButtonToggle } from '@shared/components';
import InformationNeeded from './InformationNeeded';
import InstructionsList from './InstructionsList';
import { instructionGroups } from '../helpers/instructions.helpers';
import { methods } from '../helpers/button.helper';
import { titleTextStyles } from '../styles/content.styles';

const DrawerContent = ({ id }: { id: number }) => {
  const [instructionType, setInstructionType] = useState(methods[id].defaultType);
  const instructions = instructionGroups[instructionType];

  const handleDigitalWalletSelection = () => {
    if (instructionType === 'paypal') {
      setInstructionType('venmo');
    } else {
      setInstructionType('paypal');
    }
  };

  const handleBankTransferType = () => {
    if (instructionType === 'ach') {
      setInstructionType('wire');
    } else {
      setInstructionType('ach');
    }
  };

  const handleTypeChange = () => {
    if (methods[id].defaultType === 'paypal') {
      handleDigitalWalletSelection();
    } else {
      handleBankTransferType();
    }
  };

  const {
    instructionTitle,
    instructionPoints,
    bankingAccountInfoList,
    showInformationNeededSection,
  } = instructions;

  return (
    <Box>
      <Text {...titleTextStyles}>{methods[id].methodTitle}</Text>
      <Box my={2}>
        <BaselaneButtonToggle
          firstLabel={methods[id].defaultType === 'ach' ? 'ACH' : 'PayPal'}
          secondLabel={methods[id].defaultType === 'ach' ? 'Wire' : 'Venmo'}
          activeButton={instructionType === 'ach' || instructionType === 'paypal' ? 0 : 1}
          onClick={handleTypeChange}
          size="lg"
          leftIcons={[instructions.buttonIconLeft, instructions.buttonIconRight]}
        />
      </Box>

      {instructions.texts && (
        <Stack>
          {instructions.texts.map((text) => (
            <Text key={uuidv4()} textStyle="sm">
              {text}
            </Text>
          ))}
        </Stack>
      )}

      {instructionTitle && <InstructionsList {...{ instructionTitle, instructionPoints }} />}

      {showInformationNeededSection && <InformationNeeded {...{ bankingAccountInfoList }} />}
    </Box>
  );
};

export default DrawerContent;
