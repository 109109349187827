import React from 'react';
import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { baselaneSummaryCardStyles } from './styles/baselaneSummaryCard.styles';

type BaselaneSummaryCardListItemProps = {
  id?: String,
  label: string | React.Node,
  value: string | React.Node,
  subtext: string | React.Node,
  isHidden: boolean,
};

const BaselaneSummaryCardListItem = ({
  id,
  label,
  value,
  subtext,
  isHidden,
}: BaselaneSummaryCardListItemProps) => {
  const {
    groupStyles,
    labelStyles,
    valueContainerStyles,
    smValueStyles,
    xsValueStyles,
  } = baselaneSummaryCardStyles;

  return isHidden ? null : (
    <HStack {...groupStyles}>
      <Text {...labelStyles}>{label}</Text>
      <Spacer />
      <Stack gap={0} {...valueContainerStyles}>
        <Text {...smValueStyles}>{value}</Text>
        <Text {...xsValueStyles}>{subtext}</Text>
      </Stack>
    </HStack>
  );
};

BaselaneSummaryCardListItem.defaultProps = {
  id: null,
};

export default BaselaneSummaryCardListItem;
