export const radioCardStyles = {
  bg: 'brand.neutral.white',
  w: '96px',
  h: '36px',
  mb: '4px',
  mt: '0',
  borderColor: 'brand.darkBlue.100',
};

export const radioCardBoxStyles = {
  mr: '4px !important',
  ml: '0 !important',
};

export const radioCardCheckedStyles = {
  bgColor: 'brand.blue.50',
  borderColor: 'brand.blue.100',
};

export const radioCardFocusedStyles = {
  ...radioCardCheckedStyles,
  boxShadow: 'none',
};

export const radioCardLabelStyles = (isChecked) => {
  return {
    textStyle: 'xs',
    color: isChecked ? 'brand.blue.800A' : 'brand.neutral.600',
    textAlign: 'center',
  };
};
