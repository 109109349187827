import moment from 'moment';
import {
  IconCheckCircleOutline,
  IconCircleDottedArrow,
  IconCircleOutlineNoPadding,
  IconClose,
  IconExclamationCircle,
  IconGrid,
} from '@icons';

const defaultIconProps = {
  w: 13.33,
  h: 13.33,
  color: 'currentColor',
};

export const PAYMENT_STATUS = [
  {
    id: 'PAYMENT_COMPLETE',
    label: 'Completed Payments',
    icon: IconCheckCircleOutline,
    iconProps: {
      ...defaultIconProps,
    },
  },
  {
    id: 'PAYMENT_PROCESSING',
    label: 'Processing Payments',
    icon: IconCircleDottedArrow,
    iconProps: {
      ...defaultIconProps,
    },
  },
  {
    id: 'PAYMENT_OUTSTANDING',
    label: 'Unpaid Invoices',
    icon: IconCircleOutlineNoPadding,
    iconProps: {
      ...defaultIconProps,
    },
  },
  {
    id: 'PAYMENT_OVERDUE',
    label: 'Overdue Invoices',
    icon: IconExclamationCircle,
    iconProps: {
      ...defaultIconProps,
    },
  },
  {
    id: 'PAYMENT_FAILED',
    label: 'Failed Payment',
    icon: IconClose,
    iconProps: {
      ...defaultIconProps,
      w: 9.33,
      h: 9.33,
    },
  },
  {
    id: 'ALL',
    label: 'All Invoices',
    icon: IconGrid,
    iconProps: {
      ...defaultIconProps,
      w: 12,
      h: 12,
    },
  },
];

export const VIEW_BY = [
  {
    id: 'PAYMENT_DATE',
    label: 'Payment Date',
  },
  {
    id: 'DUE_DATE',
    label: 'Due Date',
  },
];

export const DATE_RANGE = [
  {
    id: 'LAST_MONTH',
    label: 'Last Month',
  },
  {
    id: 'THIS_MONTH',
    label: 'This Month',
  },
  {
    id: 'NEXT_MONTH',
    label: 'Next Month',
  },
  {
    id: 'ALL',
    label: 'All Time',
  },
  {
    id: 'CUSTOM',
    label: 'Custom',
  },
];

export const PAYMENT_STATUS_SEGMENT_EVENTS = {
  PAYMENT_COMPLETE: 'completed',
  PAYMENT_PROCESSING: 'processing',
  PAYMENT_OUTSTANDING: 'unpaid',
  PAYMENT_OVERDUE: 'overdue',
  PAYMENT_FAILED: 'failed',
  ALL: 'all',
};

export const VIEW_BY_SEGMENT_EVENTS = {
  PAYMENT_DATE: 'payment date',
  DUE_DATE: 'due date',
};

export const DATE_RANGE_SEGMENT_EVENTS = {
  LAST_MONTH: 'last month',
  THIS_MONTH: 'this month',
  NEXT_MONTH: 'next month',
  ALL: 'all',
  CUSTOM: 'custom',
};

const getDatesInput = ({ selectedDateRange, selectedDate }) => {
  const { start, end } = selectedDate ?? {};
  const hasNoDateSelected = !start && !end;

  switch (selectedDateRange) {
    case 'LAST_MONTH':
      return {
        toDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
        fromDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
      };

    case 'THIS_MONTH':
      return {
        toDate: moment().endOf('month').format('YYYY-MM-DD'),
        fromDate: moment().startOf('month').format('YYYY-MM-DD'),
      };

    case 'NEXT_MONTH':
      return {
        toDate: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD'),
        fromDate: moment().add(1, 'months').startOf('month').format('YYYY-MM-DD'),
      };

    case 'CUSTOM':
      return hasNoDateSelected
        ? {}
        : {
            toDate: moment(selectedDate.end || selectedDate.start).format('YYYY-MM-DD'),
            fromDate: moment(selectedDate.start).format('YYYY-MM-DD'),
          };

    default:
      return {};
  }
};

export const getFormattedInput = ({
  selectedPaymentStatus,
  selectedViewBy,
  selectedDateRange,
  selectedDate,
}) => {
  const isAllPayment = selectedPaymentStatus === 'ALL';

  const defaultInput = {
    dateType: selectedViewBy,
  };

  const input =
    selectedDateRange === 'ALL'
      ? { ...defaultInput }
      : {
          ...defaultInput,
          ...getDatesInput({ selectedDateRange, selectedDate }),
        };

  return isAllPayment ? input : { ...input, state: selectedPaymentStatus };
};

export const checkIsDirty = ({
  invoiceFilters,
  selectedPaymentStatus,
  selectedViewBy,
  selectedDateRange,
}) => {
  const { state, dateType, date } = invoiceFilters || {};

  const isDirty =
    selectedPaymentStatus !== state || selectedViewBy !== dateType || selectedDateRange !== date;

  return isDirty;
};
