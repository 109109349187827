import { find } from 'lodash';
import stripCurrency from '@core/utils/stripCurrency';

export const feesFormInitialValues = {
  description: '',
  amount: '',
  dueDate: '',
  frequency: 'FIXED',
};

export const securityDepositValidationChecks = (values) => {
  const { securityDeposit, securityDepositDueDate, securityDepositAmount } = values;
  const errors = {};

  if (securityDeposit && (securityDepositAmount === '' || securityDepositDueDate === null)) {
    errors.securityDeposit = 'Fill fields';
  }

  if (!securityDeposit) {
    delete errors.securityDeposit;
    delete errors.securityDepositDueDate;
    delete errors.securityDepositAmount;
  }

  if (securityDeposit && (!securityDepositDueDate || securityDepositDueDate === null)) {
    errors.securityDepositDueDate = 'Please enter security deposit due date';
  }

  if (securityDeposit && (!securityDepositAmount || securityDepositAmount === '')) {
    errors.securityDepositAmount = 'Please enter security deposit amount';
  }

  if (securityDeposit && stripCurrency(securityDepositAmount) <= 2) {
    errors.securityDepositAmount = 'Amount must be greater than $2.00';
  }

  return errors;
};

export const formValidation = (values) => {
  const {
    lastMonthRent,
    lastMonthRentDueDate,
    proratedRent,
    proratedRentDueDate,
    proratedRentAmount,
    additionalFees,
  } = values;

  const errors = { ...securityDepositValidationChecks(values) };

  if (proratedRent && (proratedRentAmount === '' || proratedRentDueDate === null)) {
    errors.proratedRent = 'Fill fields';
  }

  if (!proratedRent) {
    delete errors.proratedRent;
    delete errors.proratedRentDueDate;
    delete errors.proratedRentAmount;
  }

  if (!additionalFees) {
    delete errors.additionalFees;
    delete errors.additionalFeesFields;
  }

  if (lastMonthRent && lastMonthRentDueDate === null) {
    errors.lastMonthRentDueDate = 'Please enter last month rent due date';
  }

  if (proratedRent && (!proratedRentDueDate || proratedRentDueDate === null)) {
    errors.proratedRentDueDate = 'Please enter prorated rent due date';
  }

  if (proratedRent && (!proratedRentAmount || proratedRentAmount === '')) {
    errors.proratedRentAmount = 'Please enter prorated rent amount';
  }

  if (proratedRent && stripCurrency(proratedRentAmount) <= 2) {
    errors.proratedRentAmount = 'Amount must be greater than $2.00';
  }

  return errors;
};

export const additionalFeesValidation = (values) => {
  const { description, amount, dueDate, frequency } = values;
  const errors = {};

  if (description === '') {
    errors.description = 'Please enter description';
  }

  if (amount === '') {
    errors.amount = 'Please enter amount';
  }

  if (stripCurrency(amount) <= 2) {
    errors.amount = 'Amount must be greater than $2.00';
  }

  if (frequency === 'FIXED' && dueDate === '') {
    errors.dueDate = 'Please enter date';
  }

  if (frequency === 'MONTHLY') {
    delete errors.dueDate;
  }
  return errors;
};

export const getDepositAndFeesData = (lease, variables) => {
  const depositFeesData =
    lease && lease.payments && lease.payments.length > 0
      ? lease.payments.filter((payment) => payment.category.toLowerCase() === 'deposits_fees')
      : [];

  const securityDepositData = find(depositFeesData, { name: 'DEPOSIT' });
  const lastMonthRentData = find(depositFeesData, { name: 'LAST_MONTH_RENT' });
  const proratedRentData = find(depositFeesData, { name: 'PRORATED_RENT' });
  // add BE and unsaved FEE values if any (so if not initing)
  const addedFees = variables?.payments?.filter((data) => data.name === 'FEE');
  let additionalFeesData = depositFeesData.filter((data) => data.name === 'FEE' && data.id);
  if (addedFees?.length > 0) {
    additionalFeesData = addedFees;
  }

  return {
    deposit: { ...securityDepositData },
    lastMonthRent: { ...lastMonthRentData },
    proratedRent: { ...proratedRentData },
    fees: additionalFeesData,
  };
};

export default {
  formValidation,
  getDepositAndFeesData,
  feesFormInitialValues,
  additionalFeesValidation,
};
