import React from 'react';
import { Stack, Text } from '@chakra-ui/react';

// https://stackoverflow.com/questions/29652862/highlight-text-using-reactjs
export const Highlighted = ({ text = '', highlight = '' }: { text: string, highlight: string }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  // eslint-disable-next-line no-undef
  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          // eslint-disable-next-line react/no-array-index-key
          regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
        )}
    </span>
  );
};

export const accountItemRenderer = ({ item }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Text>{item.name}</Text>
      <Text>{item.value}</Text>
    </Stack>
  );
};

export const itemRenderer = ({ item, search }: { item: Object, search: String }) => {
  return (
    <Text
      {...{
        textStyle: 'xs',
      }}
      color="inherit"
    >
      <Highlighted text={item.name} highlight={search} />
    </Text>
  );
};
