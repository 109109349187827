// @flow
import React from 'react';
import { Tbody } from '@chakra-ui/react';
import {
  BaselaneButton,
  BaselaneButtonIcon,
  BaselaneCardNew,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveCellText,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveCell,
} from '@shared/components';
import { Icon16Clear } from '@icons/16px';
import getBreakPoints from '@core/utils/getBreakPoints';
import { tableHeaderItems, getTableConfig } from '../../helpers/unitSpecificSettings.helpers';
import { unitSpecificSettingsTableStyles } from '../../styles/unitSpecificSettingsTable.styles';

type UnitSpecificSettingsTableProps = {
  sortedLeases: Array<Object>,
  handleResetClick: Function,
};

const { card, nochevron, header, row, cell0, cell3 } = unitSpecificSettingsTableStyles;

function UnitSpecificSettingsTable({
  sortedLeases,
  handleResetClick,
}: UnitSpecificSettingsTableProps): any {
  const { isMax576 } = getBreakPoints();
  const tableConfig = getTableConfig({ isMax576 });

  return (
    <BaselaneCardNew {...card}>
      <BaselaneResponsiveTable config={tableConfig}>
        <BaselaneResponsiveTableHeader
          items={tableHeaderItems}
          renderItem={(item, index) => (
            <BaselaneResponsiveTableHeading
              key={item.key}
              index={index}
              title={item.label.toUpperCase()}
            />
          )}
          chevronCellStyles={nochevron}
          contentContainerStyles={header.contentContainerStyles}
        />

        <Tbody>
          <BaselaneResponsiveTableRows
            customRow
            items={sortedLeases}
            renderItem={(
              {
                id,
                totalUnits,
                propertyName,
                propertyUnitName,
                tenantProfile,
                invoiceProcessingFeePaidBy,
              },
              index
            ) => {
              const isMultiUnit = totalUnits > 1;

              const propertySubTitle =
                ((isMultiUnit && propertyUnitName) ||
                  (!isMultiUnit && propertyUnitName && propertyUnitName !== propertyName)) &&
                propertyUnitName;

              return (
                <BaselaneResponsiveTableRow
                  id={id}
                  contentContainerStyles={row.contentContainerStyles}
                  chevronCellStyles={nochevron}
                >
                  <BaselaneResponsiveCellTitle
                    title={propertyName}
                    subtitle={propertySubTitle}
                    styles={cell0}
                    isVertical
                    configIndex={0}
                  />

                  <BaselaneResponsiveCellText
                    text={`${tenantProfile?.firstName} ${tenantProfile?.lastName}`}
                    configIndex={1}
                  />

                  <BaselaneResponsiveCellText
                    text={invoiceProcessingFeePaidBy === 'TENANT' ? 'Tenant' : 'You'}
                    configIndex={2}
                  />

                  <BaselaneResponsiveCell
                    configIndex={3}
                    contentContainerStyles={cell3.contentContainerStyles}
                  >
                    {isMax576 ? (
                      <BaselaneButtonIcon
                        size="md"
                        variant="outline"
                        palette="neutral"
                        icon={<Icon16Clear />}
                        onClick={() => handleResetClick({ leaseId: id, index })}
                      />
                    ) : (
                      <BaselaneButton
                        size="md"
                        variant="outline"
                        palette="neutral"
                        leftIcon={<Icon16Clear />}
                        onClick={() => handleResetClick({ leaseId: id, index })}
                      >
                        Clear
                      </BaselaneButton>
                    )}
                  </BaselaneResponsiveCell>
                </BaselaneResponsiveTableRow>
              );
            }}
          />
        </Tbody>
      </BaselaneResponsiveTable>
    </BaselaneCardNew>
  );
}

export default UnitSpecificSettingsTable;
