import formatCurrency from '@core/utils/formatCurrency';
import { getActiveBonusObject } from '@core/components/NativeBankingPage/Summary/helpers/util.helpers';

const tierBlockWidthsDesktop = ['125px', '201px', '277px', '376px'];
const tierBlockWidthsSmallerScreens = ['78px', '118px', '190px', '296px'];

// Transform tiers data for learn more modal
export const getApyLearnMoreConfig = ({
  tiersData,
  baselaneBankBalance,
  hasCollectedRentWithinNeededPeriod,
  isUserOnPromo,
  isEmptyState,
  isUserInLastMonthOfPromo,
  isMinXL,
}) => {
  return tiersData.map((tier, index) => {
    let isActive = isEmptyState
      ? false
      : baselaneBankBalance <= tier.maxValue && baselaneBankBalance >= tier.minValue;
    // only show active state for last tier if user is on promo
    if (isUserOnPromo && !isUserInLastMonthOfPromo) {
      isActive = index === tiersData.length - 1;
    }

    const isCompleted = isEmptyState
      ? false
      : baselaneBankBalance > tier.maxValue || (isUserOnPromo && !isUserInLastMonthOfPromo);
    const isBonusActive = isEmptyState ? false : isActive && hasCollectedRentWithinNeededPeriod;

    const prefixText = index === 0 ? 'Less than' : 'More than';
    const firstAndLastPrefix =
      index === 0
        ? `${prefixText} ${formatCurrency(tier.maxValue, true).noDecimal}`
        : `${prefixText} ${formatCurrency(tier.minValue, true).noDecimal}`;

    const bonusInfo = getActiveBonusObject(tier);
    const tierObject = {
      ...tier,
      width: isMinXL ? tierBlockWidthsSmallerScreens[index] : tierBlockWidthsDesktop[index],
      isActive,
      isCompleted,
      text:
        index === 0 || index === tiersData.length - 1
          ? firstAndLastPrefix
          : `${formatCurrency(tier.minValue, true).noDecimal} - ${
              formatCurrency(tier.maxValue, true).noDecimal
            }`,
      product: {
        ...tier.product,
        subText: index === tiersData.length - 1 ? 'Total APY' : 'Base APY',
      },
      // NOTE: bonus is in array because in future we may introduce more bonuses
      bonus: [
        {
          ...bonusInfo,
          subText: 'Bonus',
          isActive: isBonusActive,
        },
      ],
    };

    if (tier.bonus.length === 0) {
      delete tierObject.bonus;
    }

    return tierObject;
  });
};
