import React from 'react';
import { FormControl, FormLabel, Input, Box } from '@chakra-ui/react';
import {
  formInputStyles,
  formLabelStyles,
  disabledInputBoldText,
} from '@shared/styles/input.style';
import { fieldContainer } from '../../styles/shared.styles';

type ExistingTenantFormProps = {
  tenantProfileId: string,
  existingTenants: Array<Object>,
};

const ExistingTenantForm = ({ tenantProfileId, existingTenants }: ExistingTenantFormProps) => {
  const selectedTenant = existingTenants.find((tenant) => tenant.id === tenantProfileId);

  const inputClasses = { ...formInputStyles, ...disabledInputBoldText };

  return selectedTenant ? (
    <>
      <FormControl {...fieldContainer}>
        <FormLabel {...formLabelStyles.xs}>Email</FormLabel>
        <Input isDisabled value={selectedTenant?.email} {...inputClasses} />
      </FormControl>
      <FormControl {...fieldContainer}>
        <FormLabel {...formLabelStyles.xs}>
          Mobile phone (optional, needed to send SMS invite)
        </FormLabel>
        {selectedTenant?.phoneNumber ? (
          <Input isDisabled value={selectedTenant?.phoneNumber} {...inputClasses} />
        ) : (
          <Box>&mdash;</Box>
        )}
      </FormControl>
    </>
  ) : null;
};

export default ExistingTenantForm;
