import { React, useEffect, useRef, useState } from 'react';
import { Box, HStack, VisuallyHidden } from '@chakra-ui/react';
import { Icon24Refresh } from '@core/components/Icons/24px';
import { BaselaneButton, BaselaneSpinner } from '@core/components/Shared/components';

type ButtonLoadMoreProps = {
  id: string,
  totalCount: Number,
  currentCount: Number,
  fetchMore: Function,
  leaseState: Array<String>,
};

export const LEASES_PAGE_LIMIT = 10;

const ButtonLoadMore = ({
  id,
  totalCount = 0,
  currentCount = 0,
  fetchMore = () => {},
  leaseState = ['SCHEDULED'],
  ...rest
}: ButtonLoadMoreProps): any => {
  const pageBottomRef = useRef();
  const [loadingMore, setLoadingMore] = useState(false);
  const pageLimit = LEASES_PAGE_LIMIT;

  // this is for the displayed value, it is intentionally
  // independent of the actual query pagination variables
  const nextPageLimit = Math.min(pageLimit, totalCount - currentCount);

  useEffect(() => {
    if (currentCount && loadingMore) {
      pageBottomRef?.current?.scrollIntoView({
        behavior: 'smooth',
      });
      setLoadingMore(false);
    }
  }, [currentCount]);

  return (
    <>
      {nextPageLimit > 0 && (
        <HStack w="full" justifyContent="center" position="relative">
          {loadingMore && <BaselaneSpinner mt={2} />}
          {!loadingMore && (
            <BaselaneButton
              id="load-more-active-leases"
              size="lg"
              variant="outline"
              palette="primary"
              mt={2}
              onClick={() => {
                setLoadingMore(true);
                const lastPage = Math.ceil(totalCount / pageLimit);
                const currentPage = Math.ceil(currentCount / pageLimit);
                const page = Math.min(currentPage + 1, lastPage);
                fetchMore({
                  variables: {
                    input: { state: leaseState, page, pageLimit },
                  },
                });
              }}
            >
              <Box mr={1}>
                <Icon24Refresh />
              </Box>{' '}
              {nextPageLimit} more rent collection{nextPageLimit > 1 && 's'}
            </BaselaneButton>
          )}
        </HStack>
      )}
      <Box ref={pageBottomRef} id="list-bottom-anchor">
        <VisuallyHidden>Bottom of Page</VisuallyHidden>
      </Box>
    </>
  );
};

export default ButtonLoadMore;
