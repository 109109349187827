// @flow
import React from 'react';
import { Stack } from '@chakra-ui/react';
import { getLegendContainerStyle } from './styles/legend.style';

function BaselaneLegend({
  data,
  legendStyles,
  itemRenderer,
  isHorizontal,
}: {
  data: Object,
  legendStyles: Object,
  itemRenderer: Function,
  isHorizontal: Boolean,
}): any {
  const legendContainerStyles = getLegendContainerStyle(isHorizontal);
  const styles = { ...legendContainerStyles, ...legendStyles };
  return <Stack {...styles}>{data.map((item) => itemRenderer(item))}</Stack>;
}

export default BaselaneLegend;
