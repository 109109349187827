export const formStyles = {
  labelSubtextStyles: {
    color: 'brand.neutral.500',
  },
  dividerStyles: {
    my: 1,
  },
};

export const spinnerStyles = {
  color: 'brand.blue.700',
  size: 'xl',
  position: 'absolute',
  top: '45%',
  left: '45%',
};

export const reviewStyles = {
  cardStyles: {
    background: 'grey',
    padding: 'md',
  },
  card1SubtitleStyles: {
    textStyle: 'xs',
    color: 'brand.neutral.600',
  },
  cardTitleStyles: {
    textStyle: 'headline-sm',
    color: 'brand.neutral.900',
  },
  amountStyles: {
    textStyle: 'headline-lg',
    color: 'brand.neutral.700',
  },
  dividerStyles: {
    my: 1,
  },
  groupStyles: {
    alignItems: 'flex-start',
  },
  labelStyles: {
    mt: 0.25,
    textStyle: 'xs',
    color: 'brand.neutral.700',
  },
  valueContainerStyles: {
    alignItems: 'flex-end',
  },
  smValueStyles: {
    textStyle: 'sm',
    color: 'brand.neutral.900',
  },
  xsValueStyles: {
    textStyle: 'xs',
    color: 'brand.neutral.700',
  },
};
