import React from 'react';

import {
  IllustrationBankingBusinessAccount,
  IllustrationBankingBusinessAccountclosed,
  IllustrationBankingSolePropAccount,
  IllustrationBankingSolePropAccountclosed,
} from '@illustrations';

type AccountGraphicElementProps = {
  /**
   * @prop {Boolean} - Switches on "closed" account styling when set to true.
   */
  isClosed: Boolean,
  /**
   * @prop {SoleProprietorship | null} - Determines which icon to show based on type of account.
   */
  applicationType: 'SoleProprietorship' | null,
};

/**
 * Renders an Icon for Business or Sole Proprietor accounts.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const AccountGraphicElement = ({
  isClosed,
  applicationType = null,
}: AccountGraphicElementProps) => {
  if (applicationType === 'SoleProprietorship') {
    return isClosed ? (
      <IllustrationBankingSolePropAccountclosed />
    ) : (
      <IllustrationBankingSolePropAccount />
    );
  }
  return isClosed ? (
    <IllustrationBankingBusinessAccountclosed />
  ) : (
    <IllustrationBankingBusinessAccount />
  );
};

export default AccountGraphicElement;
