export const accordionContentStyles = {
  msgCard: {
    container: {
      m: '8px 12px !important',
    },
    iconcontainer: {
      display: 'flex',
      minW: '24px',
      minH: '24px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleContainer: {
      m: '0 !important',
    },
  },
};
