import React from 'react';

function IconOutstanding() {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#E1F4FF" />
      <path
        d="M16 9.333a6.667 6.667 0 100 13.334 6.667 6.667 0 000-13.334zm-5.893.774a8.333 8.333 0 1111.786 11.785 8.333 8.333 0 01-11.786-11.784z"
        fill="#06F"
      />
    </svg>
  );
}

export default IconOutstanding;
