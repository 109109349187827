import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Heading,
  Spacer,
  Stack,
  Text,
  Box,
} from '@chakra-ui/react';

import { BaselaneCardNew, BaselaneChip } from '@shared/components';
import { Icon24AddBankingAccount, Icon24DoubleArrowRight } from '@icons/24px';

type FinishLaterBankingFirstPopupProps = {
  isOpen: boolean,
  onClose: Function,
  onContinue: Function,
};

const BankingFirstSkipPopup = ({
  isOpen,
  onClose,
  onContinue,
}: FinishLaterBankingFirstPopupProps) => {
  const alertCancelRef = useRef(null);

  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4}>
        <Stack spacing={2}>
          <AlertDialogCloseButton />
          <Stack spacing={0.5}>
            <AlertDialogHeader p={0}>
              <Heading size="headline-lg">Continue without Baselane Banking?</Heading>
            </AlertDialogHeader>
            <AlertDialogBody p={0}>
              <Text textStyle="sm">
                Baselane Banking is built to integrate and save you time with rent collection. It
                takes only 2 minutes to open a banking account.
              </Text>
            </AlertDialogBody>
          </Stack>
        </Stack>
        <AlertDialogFooter p={0} mt={2}>
          <Stack direction="column" w="100%" spacing={2}>
            <BaselaneCardNew
              variant="clickable"
              onClick={() => {
                onClose();
                onContinue(true);
              }}
              gap={1.5}
              alignItems="flex-start"
              flexDirection={{ md: 'row', base: 'column' }}
            >
              <Stack gap={1.5} direction="row">
                <Box>
                  <Icon24AddBankingAccount />
                </Box>
                <Stack spacing={0.5}>
                  <Heading size="headline-md">Open a Baselane Banking account</Heading>
                  <Text textStyle="sm">
                    Includes faster payments & waived fees for rent collection
                  </Text>
                </Stack>
              </Stack>
              <Spacer />
              <Box ml={{ md: 0, base: 4 }}>
                <BaselaneChip label="Recommended" size="md" variant="simple-primary" />
              </Box>
            </BaselaneCardNew>
            <BaselaneCardNew
              variant="clickable"
              onClick={() => {
                onClose();
                onContinue();
              }}
              gap={1.5}
              alignItems="flex-start"
            >
              <Box>
                <Icon24DoubleArrowRight />
              </Box>
              <Stack spacing={0.5}>
                <Heading size="headline-md">Continue without Baselane Banking</Heading>
                <Text textStyle="sm">
                  $2 fee per rent payment via ACH deposited into an external bank account
                </Text>
              </Stack>
            </BaselaneCardNew>
          </Stack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default BankingFirstSkipPopup;
