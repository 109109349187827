import React from 'react';
import { Box } from '@chakra-ui/react';
import { getDefaultAtomStateStyles } from '@shared/components/BaselaneAtoms/styles/atom.styles';
import formatCurrency from '@core/utils/formatCurrency';
import { getActiveBonusObject } from '@core/components/NativeBankingPage/Summary/helpers/util.helpers';

const getProductFooterAction = ({ isLastAtom, isEmptyState }) => {
  if (isEmptyState) {
    return 'emptyState';
  }

  return isLastAtom ? 'unlockedMax' : 'addFunds';
};

const getBonusTooltipContent = ({
  isActive,
  isCompleted,
  isRentCollectionStarted,
  hasCollectedRentWithinNeededPeriod,
  tooltipFundsToNextTier,
  bonusPercent,
}) => {
  // Note: special case added on line 195

  if (isActive && hasCollectedRentWithinNeededPeriod) {
    return "Next Month's APY";
  }

  if (
    (isActive || !isCompleted) &&
    isRentCollectionStarted &&
    !hasCollectedRentWithinNeededPeriod
  ) {
    return (
      <Box>
        <Box as="span" fontWeight="medium" color="green.250">
          Collect Rent
        </Box>{' '}
        to unlock {bonusPercent}%
      </Box>
    );
  }

  if (
    (isActive || !isCompleted) &&
    !isRentCollectionStarted &&
    !hasCollectedRentWithinNeededPeriod
  ) {
    return (
      <Box>
        <Box as="span" fontWeight="medium" color="green.250">
          Set Up Rent Collection
        </Box>{' '}
        get extra {bonusPercent}%
      </Box>
    );
  }

  return (
    <Box>
      <Box fontWeight="medium" color="#8CCFFF" as="span">
        Add {formatCurrency(tooltipFundsToNextTier).inDollars}
      </Box>{' '}
      to unlock APY tier
    </Box>
  );
};

const emptyStateTooltipContent = {
  tier1: 'For balance up to $10,000',
  tier1Bonus: (
    <>
      For balance up to $10,000 and{' '}
      <Box as="span" fontWeight="medium" color="green.250">
        Collecting Rent
      </Box>
    </>
  ),
  tier2: 'For balance of $10,000 - $24,999',
  tier2Bonus: 'For balance of $10,000 - $24,999',
  tier3: 'For balance of $25,000 - $49,999',
  tier3Bonus: (
    <>
      For balance of $25,000 - $49,999 and{' '}
      <Box as="span" fontWeight="medium" color="green.250">
        Collecting Rent
      </Box>
    </>
  ),
  tier4: 'More than $50,000',
};

const getEmptyStateTooltipContent = (type) => {
  return emptyStateTooltipContent[type];
};

// Transform tier data for atoms component
const apyAtomsConfig = ({
  tiersData,
  baselaneBankBalance,
  isRentCollectionStarted,
  hasCollectedRentWithinNeededPeriod,
  isUserOnPromo,
  isEmptyState,
  isUserInLastMonthOfPromo,
}) => {
  return tiersData.reduce((acc, tier, index) => {
    const isActive = baselaneBankBalance <= tier.maxValue && baselaneBankBalance >= tier.minValue;
    const isCompleted = baselaneBankBalance > tier.maxValue;

    const tooltipFundsToNextTier = tiersData[index]?.minValue
      ? tiersData[index].minValue - baselaneBankBalance
      : 0;
    const footerFundsToNextTier = tiersData[index + 1]?.minValue
      ? tiersData[index + 1].minValue - baselaneBankBalance
      : 0;

    const isProductActive =
      index === tiersData.length - 1 ? isActive : isActive && !hasCollectedRentWithinNeededPeriod;

    const product = {
      ...tier,
      // !hasCollectedRentWithinNeededPeriod because rent is a atom by itself
      isActive: isProductActive,
      isCompleted: isCompleted || (isActive && hasCollectedRentWithinNeededPeriod),
      value: `${formatCurrency(tier?.product?.value || 0, true).noDollar}%`,
      valueAmount: tier?.product?.value,
      alwaysShowText: index === tiersData.length - 1,
      styles:
        index === tiersData.length - 1
          ? getDefaultAtomStateStyles({
              alwaysShowText: true,
            })
          : getDefaultAtomStateStyles(),
      showTooltip: isProductActive || (!isCompleted && !isActive),
      // For footer text of atoms
      footerAction: getProductFooterAction({
        isLastAtom: index === tiersData.length - 1,
        isEmptyState,
      }),
      footerAmount: formatCurrency(footerFundsToNextTier, false, true).inDollars,
      tooltipContent: isProductActive ? (
        "Next Month's APY"
      ) : (
        <Box>
          <Box fontWeight="medium" color="#8CCFFF" as="span">
            Add {formatCurrency(tooltipFundsToNextTier).inDollars}
          </Box>{' '}
          to unlock APY tier
        </Box>
      ),
    };

    const bonusInfo = getActiveBonusObject(tier);
    const bonus = {
      ...(bonusInfo ?? {}),
      name: tier.name,
      isSeparate: true,
      value: `${
        formatCurrency((tier?.product?.value || 0) + (bonusInfo?.value || 0), true).noDollar
      }%`,
      valueAmount: (tier?.product?.value || 0) + (bonusInfo?.value || 0),
      isCompleted,
      isActive: isActive && hasCollectedRentWithinNeededPeriod,
      styles: getDefaultAtomStateStyles({
        activeStyles: { bgColor: 'green.500A', _hover: { bgColor: '#69BE8E' } },
        notCompletedStyles: {
          _hover: { bgColor: '#C9E7D6', color: 'green.600', fontSize: 'xs' },
        },
      }),
      showTooltip: (isActive && hasCollectedRentWithinNeededPeriod) || !isCompleted,
      footerAction: 'addFunds',
      footerAmount: formatCurrency(footerFundsToNextTier).inDollars,
      tooltipContent: getBonusTooltipContent({
        isActive,
        isCompleted,
        isRentCollectionStarted,
        hasCollectedRentWithinNeededPeriod,
        tooltipFundsToNextTier,
        bonusPercent: formatCurrency(bonusInfo?.value || 0, true).noDollar,
      }),
    };

    if (isUserOnPromo && !isUserInLastMonthOfPromo) {
      product.isActive = false;
      product.isCompleted = true;
      product.showTooltip = product.footerAction === 'unlockedMax';
      product.tooltipContent = "Next Month's APY";
      bonus.isActive = false;
      bonus.isCompleted = true;
      bonus.showTooltip = false;
    } else if (isEmptyState) {
      product.isActive = false;
      product.isCompleted = false;
      product.showTooltip = true;
      product.tooltipContent = getEmptyStateTooltipContent(
        product.name.toLowerCase().replace(/\s/g, '')
      );
      bonus.isActive = false;
      bonus.isCompleted = false;
      bonus.showTooltip = true;
      bonus.styles = getDefaultAtomStateStyles();
      bonus.tooltipContent = getEmptyStateTooltipContent(
        `${product.name.toLowerCase().replace(/\s/g, '')}Bonus`
      );
    }

    // Special Cases that does not fix within general runs above -- start
    if ((!isUserOnPromo || (isUserOnPromo && isUserInLastMonthOfPromo)) && !isEmptyState) {
      // Note: if there are two ways of going to tier for 3.50. The route by adding balance to reach it has more priority
      //      so by default 3.50 will be shows as blue.
      // Case for hover styles: on hover show blue until user is on same tier, if user is recieving rent on hover
      //       is it is green. Otherwise it is automatically set to green active because user is collecting rent.
      const activeAtom = acc.find((a) => a.isActive);
      // if tier info is greater than active tier value set bonus styles to be default blue
      if (
        activeAtom &&
        (tier?.product?.value || 0) + (bonusInfo?.value || 0) > activeAtom?.valueAmount
      ) {
        bonus.styles = getDefaultAtomStateStyles();
        if (!hasCollectedRentWithinNeededPeriod) {
          bonus.tooltipContent = (
            <Box>
              <Box as="span" fontWeight="medium" color="#8CCFFF">
                Add {formatCurrency(tooltipFundsToNextTier).inDollars}
              </Box>{' '}
              <Box as="span">and</Box>{' '}
              <Box as="span" fontWeight="medium" color="green.250">
                Collect Rent
              </Box>{' '}
              to unlock APY tier
            </Box>
          );
        }
      }

      // logic change for https://baselane.atlassian.net/browse/OMEGA-3484 if user is with RC bonus active
      // there should not be a tooltip for 3.0 (see in figma)
      if (
        activeAtom?.name === 'Tier 1' &&
        product.name === 'Tier 2' &&
        hasCollectedRentWithinNeededPeriod
      ) {
        product.showTooltip = false;
      }

      //  if adding the previous product + bonus (total apy) is equal to the base apy of next tier. Only push bonus
      //  to avoid double of the same percent. Where `bonus` object is product + bonus added together.
      const previousProductInfo = tiersData?.[index - 1];
      const prevBonusInfo = getActiveBonusObject(previousProductInfo ?? {});
      if (hasCollectedRentWithinNeededPeriod) {
        if (
          tiersData?.[index - 1] &&
          parseFloat(
            ((previousProductInfo?.product?.value || 0) + (prevBonusInfo?.value || 0)).toFixed(2)
          ) === tier?.product?.value
        ) {
          acc.push(bonus);
          return acc;
        }
        acc.push(product);
        if (tier?.bonus?.length > 0) {
          acc.push(bonus);
        }
        return acc;
      }
    }
    // Special Cases -- end

    // default case: push both product + bonus
    acc.push(product);

    if (
      tier?.bonus?.length > 0 &&
      parseFloat(((tier?.product?.value || 0) + (bonusInfo?.value || 0)).toFixed(2)) !==
        (tiersData?.[index + 1]?.product?.value || 0)
    ) {
      acc.push(bonus);
    }

    return acc;
  }, []);
};

const footerConfig = (maxTier, userPromoApy) => ({
  addFunds: ' to unlock next APY tier',
  unlockedMax: "Congratulations! You've unlocked maximum APY",
  emptyState: ' to start saving more money',
  emptyStateEligible: `${
    formatCurrency(userPromoApy, true).noDollar
  }% APY for the first 3 months of opening your account.`,
});

export { footerConfig, apyAtomsConfig };
