import React from 'react';
import { groupBy, orderBy } from 'lodash';
import moment from 'moment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { HStack, Stack, Text } from '@chakra-ui/react';
import customTheme from '@theme';
import { Icon16Person, Icon16Property } from '@icons/16px';
import { Highlighted } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { getName } from '@core/components/Shared/components/TransferFunds/helpers';

const specialErrorCodes = [
  'AUTH_NOT_SUPPORTED',
  'NAME_VERIFICATION_FAILED',
  'IDENTITY_PERMISSION_NOT_GIVEN',
  'COUNTER_PARTY_CREATED',
];

const formatErrorCodes = ({ id, errorCode, isBankConnected }) => {
  const errorCodes = {
    isInstitutionAuthNotSupported: errorCode === 'AUTH_NOT_SUPPORTED',
    isNameCheckFailed: errorCode === 'NAME_VERIFICATION_FAILED',
    isOAuthNamePermissionMissing: errorCode === 'IDENTITY_PERMISSION_NOT_GIVEN',
    isAccountDisconnected: !isBankConnected,
    isGenericError:
      (errorCode && !specialErrorCodes.find((code) => code === errorCode)) ||
      (id === null && !errorCode),
  };

  return errorCodes;
};

const getFormattedBaselaneAcc = (baselaneAcc) => {
  // Step 1: Group accounts by accountName
  const mainAccountGroups = groupBy(baselaneAcc, 'accountName');

  // Step 2: Format and sort each group
  const formattedBaselaneAccGroups = Object.entries(mainAccountGroups).map(([groupName, group]) => {
    // Format and sort the accounts within the current group
    const formattedAndSortedGroup = orderBy(
      group
        .map((account) => {
          const { nickName, accountName, accountNumber, subAccounts, ...rest } = account;

          // Main account item with formatted name
          const mainAccountItem = {
            ...rest,
            name: getName(nickName, accountNumber),
            title: accountName, // Adding title to each item
            errorCodes: formatErrorCodes({
              id: account.id,
              errorCode: account.counterPartyConnectionHistoryCode,
              isBankConnected: account.isBankConnected,
            }),
          };

          return [mainAccountItem];
        })
        .flat(), // Flatten the array of arrays
      [(acc) => acc.name?.toLowerCase()],
      ['asc']
    );

    // Return formatted group
    return {
      id: groupName,
      title: groupName,
      items: formattedAndSortedGroup.filter((acc) => acc.accountSubType !== 'savings'),
    };
  });

  return formattedBaselaneAccGroups;
};

const getFlattenedAccounts = (accounts) => {
  const flattenedAccounts = [];
  accounts?.forEach((account) => {
    flattenedAccounts.push(...account.items);
  });
  return flattenedAccounts;
};

const getAccountsMap = (accounts) => {
  const accountsMap = Object.assign({}, ...accounts.map((acc) => ({ [acc.id]: acc })));
  return accountsMap;
};

const getPropertyId = (unsplitPropertyId) => {
  const [propId, unitId] = unsplitPropertyId.split('-');
  return { propId, unitId };
};

export const getDropdownList = (accounts) => {
  const { UNIT } = groupBy(accounts, (acc) => acc.provider);

  const formattedBaselaneAcc = getFormattedBaselaneAcc(UNIT);

  const fromDropdownList = [...formattedBaselaneAcc];
  const flattenedAccounts = getFlattenedAccounts(fromDropdownList); // pass all accounts
  const accountsMap = getAccountsMap(flattenedAccounts);

  return { fromDropdownList, accountsMap };
};

export const renderAccountDropdownItem = ({ item, search }) => {
  return (
    <Stack gap={0}>
      <Text color="inherit">
        <Highlighted text={item?.name ?? item?.nickName} highlight={search} />
      </Text>
    </Stack>
  );
};

export const renderRecipientsDropdownItem = ({ item, search }) => {
  return (
    <HStack gap={1.5}>
      {item.type === 'INDIVIDUAL' ? (
        <Icon16Person color={customTheme.colors.brand.neutral['700']} />
      ) : (
        <Icon16Property color={customTheme.colors.brand.neutral['700']} />
      )}
      <Text color="inherit">
        <Highlighted text={item?.name ?? item?.nickName} highlight={search} />
      </Text>
    </HStack>
  );
};

export const renderPaymentMethodItem = ({ item, search }) => {
  const { title, description } = item;
  return (
    <Stack gap={0}>
      <Text textStyle="sm" color={customTheme.colors.brand.neutral['700']}>
        {title}
      </Text>
      <Text textStyle="xs" color={customTheme.colors.brand.neutral['600']}>
        {description}
      </Text>
    </Stack>
  );
};

export const getSplitPropertyUnitId = (unsplitPropertyId) => {
  const propertyIdObj =
    Array.isArray(unsplitPropertyId) || !unsplitPropertyId
      ? null
      : getPropertyId(unsplitPropertyId);
  const propertyId = propertyIdObj?.propId ?? null;
  const unitId = propertyIdObj?.unitId ?? null;

  return { propertyId, unitId };
};

export const getBankId = ({ values, accountsMap }) => {
  const { fromTransferAccountId } = values;
  const selectedFromAccObj = fromTransferAccountId && accountsMap[fromTransferAccountId];

  return selectedFromAccObj?.userInstitutionId;
};

export const getPhoneNumber = ({ banks, bankId }) => {
  const phoneNumber = banks?.find((b) => b.id === bankId)?.unitAccount?.phoneNumber || '';
  return phoneNumber;
};

const inputMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
  allowDecimal: true,
};

export const currencyMask = createNumberMask(inputMaskOptions);

export const getFormInitialValues = ({ startDate, endDate }) => {
  return {
    payeeId: '',
    fromTransferAccountId: '',
    paymentMethodId: '',
    amount: '',
    tagId: null,
    propertyId: null,
    unitId: null,
    note: '',
    paymentMethodType: '', // paymentMethodType
    type: 'INDIVIDUAL', // Recipient type
    description: '',
    sendOnDate: new Date(),
    recurringPaymentId: null,
    scheduledPaymentType: 'one-time', // Possible values: "one-time", "recurring"
    recurringPayment: {
      startDate,
      repeatEvery: {
        value: 1,
        unit: 'month', // Possible values: "month", "week"
      },
      repeatOnMonthDate: '28', // Possible values: 1-28 and "last" (for last day of the month)
      repeatOnWeekday: 'monday', // Possible values: "monday", "tuesday", "wednesday", "thursday", "friday"
      endBy: 'manual', // Possible values: "manual", "date", "numberOfPayments"
      endDate, // NOTE: This field has a meaning only when "endBy" is set to "date"!
      numberOfPayments: 12, // NOTE: This field has a meaning only when "endBy" is set to "numberOfPayments"!
    },
    id: '', // paymentMethod ID
    accountType: '',
    accountHolderName: '', // TO DO prefill with name
    routingNumber: '',
    accountNumber: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    unit: '',
    name: '', // Recipient's name
    email: '', // Recipient's email
  };
};

// Enum for steps of the send funds flow.
export const steps = {
  SELECT_RECIPIENT: 1,
  ADD_RECIPIENT: 2,
  SELECT_PAYMENT_METHOD: 3,
  UPDATE_OR_ADD_PAYMENT_METHOD: 4,
  PAYMENT_DETAILS: 5,
  BOOKKEPPING_DETAILS: 6,
  REVIEW_PAYMENT: 7,
  ONE_TIME_SUCCESS_SCREEN: 8,
  RECCURING_SUCCESS_SCREEN: 9,
};
// Get step number for each form screen (1/5 string)
export const formStep = (step) => {
  if (step === steps.SELECT_RECIPIENT) return 1;
  if (step === steps.ADD_RECIPIENT) return 2;
  if (step === steps.SELECT_PAYMENT_METHOD) return 2;
  if (step === steps.UPDATE_OR_ADD_PAYMENT_METHOD) return 2;
  if (step === steps.PAYMENT_DETAILS) return 3;
  if (step === steps.BOOKKEPPING_DETAILS) return 4;
  if (step === steps.REVIEW_PAYMENT) return 5;
  return 1;
};
export const getDropDownPaymentMethods = (savedPaymentMethods) => {
  // CleanUp server paymentMethods
  const savedPaymentMethodsClone = { ...savedPaymentMethods };
  // eslint-disable-next-line no-underscore-dangle
  delete savedPaymentMethodsClone.__typename;

  // Return an array combining payment methods the existing recipient has saved and unsaved if user wants to add a new one
  const notSavedPaymentMethods = [
    {
      items: [
        {
          title: 'ACH',
          type: 'ACH',
          id: 'achPaymentMethods',
          description: '3 business days · No fees',
          status: 'unsaved',
        },
        {
          title: 'Wire transfer',
          type: 'Wire',
          id: 'wirePaymentMethods',
          description: '0-1 business days · No fees for a limited time',
          status: 'unsaved',
        },
        {
          title: 'Mail a check',
          type: 'Check',
          id: 'checkPaymentMethods',
          description: '7-10 business days · $2 fee per check',
          status: 'unsaved',
        },
      ],
    },
  ];
  const getPaymentMethodName = (key) => {
    const name = {
      achPaymentMethods: 'ACH',
      wirePaymentMethods: 'Wire',
      checkPaymentMethods: 'Check',
    };
    return name[key];
  };
  const getPaymentMethodDescription = (key) => {
    const name = {
      achPaymentMethods: '3 business days · No fees',
      wirePaymentMethods: '0-1 business days · No fees for a limited time',
      checkPaymentMethods: '7-10 business days · $2 fee per check',
    };
    return name[key];
  };
  const getUnsaved = () => {
    const unsaved = Object.keys(savedPaymentMethodsClone)
      .filter((item) => !savedPaymentMethodsClone[item])
      .map((i) => notSavedPaymentMethods[0].items.filter((m) => m.id === i)[0]);

    return unsaved;
  };
  // Group saved and unsaved payment methods. Unsaved are displayed in the More section. Initially they are all unsaved
  const paymentMethodsDropDownList = [
    {
      id: 'saved',
      title: 'Saved methods',
      items: Object.keys(savedPaymentMethodsClone)
        .filter((item) => savedPaymentMethodsClone[item]) // only keep objects that are not null
        .map((key) => ({
          title: getPaymentMethodName(key),
          id: key,
          description: getPaymentMethodDescription(key),
          status: 'saved',
          type: getPaymentMethodName(key),
          ...savedPaymentMethodsClone[key],
        })),
    },
    {
      id: 'unsaved',
      title: 'Other methods',
      items: getUnsaved(),
    },
  ];

  if (!savedPaymentMethods) {
    // show all the available payment methods that a new recipient can choose to save
    return notSavedPaymentMethods;
  }
  return paymentMethodsDropDownList;
};
export const transformRecipientPaymentMethods = (recipient) => {
  const transformedRecipient = {
    ...recipient,

    paymentMethods: getDropDownPaymentMethods(recipient?.paymentMethods),
  };

  return transformedRecipient;
};
export const isFutureDate = (d) => {
  const today = moment();
  const dDiff = today.diff(d);
  let isInTheFuture;
  if (dDiff < 0) {
    isInTheFuture = true;
  } else {
    isInTheFuture = false;
  }
  return isInTheFuture;
};
export const isSameDay = (d) => {
  return moment(d).isSame(moment(), 'day');
};

export const setPaymentMethodFormValues = (selected, setFieldValue, name) => {
  setFieldValue('paymentMethodId', selected?.id);
  setFieldValue(
    'paymentMethodType',
    (selected?.type === 'Check' && 'CHECK_PAYMENT') ||
      (selected?.type === 'Wire' && 'WIRE_TRANSFER') ||
      (selected?.type === 'ACH' && 'ACH')
  );
  setFieldValue('id', selected?.id || '');
  setFieldValue('accountType', selected?.accountType || '');
  setFieldValue('accountHolderName', selected?.accountHolderName || name || '');
  setFieldValue('routingNumber', selected?.routingNumber || '');
  setFieldValue('accountNumber', selected?.accountNumber || '');
  setFieldValue('address', selected?.address?.street || '');
  setFieldValue('city', selected?.address?.city || '');
  setFieldValue('state', selected?.address?.state || '');
  setFieldValue('zipcode', selected?.address?.postalCode || '');
  setFieldValue('unit', selected?.address?.unit || '');
};

export const parseTagId = (tagId) => {
  return !tagId ? undefined : tagId.split('-')[tagId.split('-').length - 1]; // Its ugly, in case of "123" or "123-456" it will return "123" or "456" respectively.
};
