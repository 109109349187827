import React from 'react';
import {
  Icon24Accounts,
  Icon24LifetimeInterest,
  Icon24CreditCard,
  Icon24Sparkle,
  Icon24Bolt,
  Icon24AutoPay,
  Icon24AddFunds2,
  Icon24Dashboard,
  Icon24Checklist,
  Icon24Refresh,
  Icon24Details,
  Icon24Analytics,
  Icon24Download,
  Icon24Document,
  Icon24Notification,
  Icon24Calendar,
  Icon24Mobile,
  Icon24LineChart,
} from '@icons/24px';

const onboardingCards = {
  banking: {
    banking: [
      {
        id: 'base-banking-1',
        title: 'Organize & grow your money',
        iconOne: <Icon24Accounts />,
        infoLabelOne: 'Unlimited checking accounts to organize your property’s finances',
        iconTwo: <Icon24LifetimeInterest />,
        infoLabelTwo: 'Savings account with up to 4.19% APY, 60x the national average',
        claimer: 'FDIC insured up to $3M via Thread Bank',
      },
      {
        id: 'base-banking-2',
        title: 'Manage & control spend',
        iconOne: <Icon24CreditCard />,
        infoLabelOne: 'Physical and virtual debit cards with smart limits',
        iconTwo: <Icon24Sparkle />,
        infoLabelTwo: 'Automated expense tagging so bookkeeping is done for you',
        claimer: 'Up to 5% cash back on debit card spend',
      },
      {
        id: 'base-banking-3',
        title: 'Automate bills & payments',
        iconOne: <Icon24Bolt />,
        infoLabelOne: 'Easily make payments via ACH, wire, or mailed check',
        iconTwo: <Icon24AutoPay />,
        infoLabelTwo: 'Save & manage payees, set up one-time or recurring payments',
        claimer: 'Save countless hours every month',
      },
    ],
    rentCollection: [
      {
        id: 'rc-banking-1',
        title: 'Waived fees & faster rent payments',
        iconOne: <Icon24AddFunds2 />,
        infoLabelOne:
          'No-fee ACH rent payments with Baselane Banking (vs. $2 fee with an external account)',
        iconTwo: <Icon24Bolt />,
        infoLabelTwo:
          'Eligible for QuickPay — rent payments in 2 business days (vs. standard 4-5 business days)',
        claimer: 'Streamline your rental income',
      },
      {
        id: 'rc-banking-2',
        title: 'Banking built for real estate investors',
        iconOne: <Icon24Accounts />,
        infoLabelOne:
          'Unlimited accounts to organize finances by property, control expenses and easily pay bills',
        iconTwo: <Icon24LifetimeInterest />,
        infoLabelTwo: 'Savings account with up to 4.19% APY, 60x the national average',
        claimer: 'FDIC insured up to $3M via Thread Bank',
      },
      {
        id: 'rc-banking-3',
        title: 'All-in-one property finances',
        iconOne: <Icon24Dashboard />,
        infoLabelOne: 'No more juggling between different platforms and tools ',
        iconTwo: <Icon24Sparkle />,
        infoLabelTwo:
          'Automate bookkeeping and accounting with auto expense tagging in one seamless solution',
        claimer: 'Save 150+ hours a year ',
      },
    ],
    bookkeeping: [
      {
        id: 'bk-banking-1',
        title: 'Banking built for real estate investors',
        iconOne: <Icon24Accounts />,
        infoLabelOne:
          'Accounts to organize property your finances, control expenses and save time paying bills.',
        iconTwo: <Icon24LifetimeInterest />,
        infoLabelTwo: 'Savings account with up to 4.19% APY, 60x the national average',
        claimer: 'FDIC insured up to $3M via Thread Bank',
      },
      {
        id: 'bk-banking-2',
        title: 'Real-time & accurate bookkeeping ',
        iconOne: <Icon24Checklist />,
        infoLabelOne: 'Get instant & precise transaction data with Baselane banking',
        iconTwo: <Icon24Refresh />,
        infoLabelTwo:
          'Forget about lost transactions, re-syncing disconnected external accounts, and data lags.',
        claimer: 'More accurate & timely investment decisions ',
      },
      {
        id: 'bk-banking-3',
        title: 'All-in-one property finances',
        iconOne: <Icon24Dashboard />,
        infoLabelOne: 'No more juggling between different platforms and tools ',
        iconTwo: <Icon24Sparkle />,
        infoLabelTwo: 'All property banking and finances integrated under one roof',
        claimer: 'Save 150+ hours a year ',
      },
    ],
  },
  rentCollection: [
    {
      id: 'rentCollection-1',
      title: 'Rent collection on autopilot',
      iconOne: <Icon24Sparkle />,
      infoLabelOne: 'Automated invoicing, deposits, and integrated bookkeeping',
      iconTwo: <Icon24Notification />,
      infoLabelTwo: 'Real-time payment notifications and payment tracking ',
      claimer: 'Save time & never lose track of rent',
    },
    {
      id: 'rentCollection-2',
      title: 'Reliable, on-time rent',
      iconOne: <Icon24Calendar />,
      infoLabelOne: 'Automated reminders, and late fees to reduce late rent payments',
      iconTwo: <Icon24AddFunds2 />,
      infoLabelTwo: 'Fast payouts deposited within 2-5 business days',
      claimer: 'Increase on-time payments',
    },
    {
      id: 'rentCollection-3',
      title: 'Easy for you, easy for your tenant',
      iconOne: <Icon24Mobile />,
      infoLabelOne: 'Tenants can set up payments in minutes, from any device.',
      iconTwo: <Icon24AutoPay />,
      infoLabelTwo: 'One-time or automated payments via ACH & card',
      claimer: 'Flexibility and convenience for your tenants ',
    },
  ],
  bookkeeping: [
    {
      id: 'bookkeeping-1',
      title: 'Easy transaction management',
      iconOne: <Icon24Details />,
      infoLabelOne: 'One simple, consolidated ledger for all your property transactions',
      iconTwo: <Icon24LifetimeInterest />,
      infoLabelTwo:
        'Accurately tag transactions with 120+ real estate specific categories based on Schedule E',
      claimer: 'No more manual bookkeeping and data entry',
    },
    {
      id: 'bookkeeping-2',
      title: 'Accurate analytics & reporting ',
      iconOne: <Icon24Analytics />,
      infoLabelOne: 'Real-time cash flow insights to analyze income and expenses. ',
      iconTwo: <Icon24LineChart />,
      infoLabelTwo:
        'Full visibility of your property performance with real estate-specific financial reports',
      claimer: 'Make smarter decisions ',
    },
    {
      id: 'bookkeeping-3',
      title: 'Maximize tax returns, less busy work',
      iconOne: <Icon24Download />,
      infoLabelOne: 'Auto-generate Schedule E reports at the click of a button',
      iconTwo: <Icon24Document />,
      infoLabelTwo: 'Customizable tax package to send accountants',
      claimer: 'Save time for you and your accountant',
    },
  ],
};

export const getOnboardingInfoCards = (
  onboardingTriageSelection,
  isBankingFinished,
  skippedBankingFirst
) => {
  if (!isBankingFinished && !skippedBankingFirst) {
    return onboardingCards.banking[onboardingTriageSelection];
  }
  return onboardingCards[onboardingTriageSelection];
};
