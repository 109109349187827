import React from 'react';
import { FormControl, FormLabel, Text, Input } from '@chakra-ui/react';
import { formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import { textStyles } from './styles/businessInformation.styles';

type DBAInputProps = {
  values: Object,
  handleChange: Function,
  handleBlur: Function,
};

const DBAInput = ({ values, handleChange, handleBlur }: DBAInputProps) => {
  return (
    <FormControl>
      <FormLabel htmlFor="dba" {...formLabelStyles.xs}>
        Doing Business As (Optional)
      </FormLabel>
      <Input
        id="dba"
        name="dba"
        placeholder="Doing Business As Name"
        value={values.dba}
        onChange={handleChange}
        onBlur={handleBlur}
        {...formInputStyles}
      />
      <Text {...textStyles}>
        DBA will appear on your Debit Card and will be used as your account nickname.
      </Text>
    </FormControl>
  );
};

export default DBAInput;
