import { DateTime } from 'luxon';

const buildUpcomingPaymentsCalculationParams = (values) => {
  const params = {
    amount: values?.amount ? parseFloat(values.amount.replace(/,/g, '')) : 0, // Einai string TODO: Fix
    startDate: DateTime.fromJSDate(values?.recurringPayment?.startDate).toISODate(),
    recurrencePattern: {
      type: values?.recurringPayment?.repeatEvery?.unit === 'month' ? 'MONTHLY' : 'WEEKLY',
      interval: values?.recurringPayment?.repeatEvery?.value,
    },
  };

  // If the user selects an end date, we need to set the endDate value.
  if (values?.recurringPayment?.endBy === 'date') {
    params.endDate = DateTime.fromJSDate(values?.recurringPayment?.endDate).toISODate();
  }

  // Otherwise, if the user selects a number of payments, we need to set the numberOfPayments value.
  if (values?.recurringPayment?.endBy === 'numberOfPayments') {
    params.recurrencePattern.numberOfPayments = values?.recurringPayment?.numberOfPayments;
  }

  // If the user selects the last day of the month, we need to set the lastDayOfMonth flag.
  if (
    values?.recurringPayment?.repeatEvery?.unit === 'month' &&
    values?.recurringPayment?.repeatOnMonthDate === 'last'
  ) {
    params.recurrencePattern.lastDayOfMonth = true;
  }

  // If the user selects a specific day of the month, we need to set the monthlyByDate value.
  if (
    values?.recurringPayment?.repeatEvery?.unit === 'month' &&
    values?.recurringPayment?.repeatOnMonthDate !== 'last'
  ) {
    // Converting the string to a number. The form value is e.g. '1', '2', '3', ..., '28'.
    // NOTE: Form uses string values for the dropdown, because the last value is 'last' (a string).
    params.recurrencePattern.monthlyByDate = Number(values?.recurringPayment?.repeatOnMonthDate);
  }

  if (values?.recurringPayment?.repeatEvery?.unit === 'week') {
    params.recurrencePattern.daysOfWeek = values?.recurringPayment?.repeatOnWeekday?.toUpperCase();
  }

  return params;
};

export default buildUpcomingPaymentsCalculationParams;
