import React from 'react';
import { useGate } from 'statsig-react';
import { useLocation } from 'react-router-dom';
import { Box, Flex, HStack, Link, Text } from '@chakra-ui/react';

import { BaselaneList } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import * as ROUTES from '@routes';

import {
  groupOneNavElements,
  groupTwoNavElements,
  groupThreeNavElements,
  groupFourNavElements,
  groupFiveNavElements,
  transfersPayments,
} from './navElements';
import {
  linkStyles,
  navItemContainerStyles,
  navBarIconContainerStyles,
  navTextStyles,
  navGroupTitleStyles,
} from './styles/nav.styles';

type NavListProps = {
  isOpen: boolean,
  onClick?: Function,
  isMobileNav?: boolean,
  showTransfersPage?: boolean,
};

function NavList({
  isOpen,
  onClick = null,
  isMobileNav = false,
  showTransfersPage = false,
}: NavListProps) {
  const { value: showPartnershipsPage } = useGate('partnerships-page');
  const { value: showPropertyMetrics } = useGate('property_metrics_page_gate');
  const currLocation = useLocation();
  const { pathname } = currLocation;
  const { isLargerThan1537 } = getBreakPoints();

  if (showTransfersPage && groupTwoNavElements.length === 2) {
    groupTwoNavElements.splice(1, 0, transfersPayments);
  }

  const listItemRenderer = (item) => {
    const { Icon, label, props, id = '' } = item;
    const key = label;
    const itemLabel = label.toLowerCase();

    const iconProps =
      itemLabel === 'insurance' || itemLabel === 'loans'
        ? { color: 'currentColor', w: '20', h: '20' }
        : {};

    if (label === 'Profile') {
      return null;
    }

    if (label === 'Analytics & Reporting') {
      return null;
    }

    if (label === 'Property metrics' && !showPropertyMetrics) {
      return null;
    }

    if (label === 'All partners' && !showPartnershipsPage) {
      return null;
    }

    const highlightGetStartedNav =
      label === 'Getting started' && (pathname === ROUTES.HOME || pathname === ROUTES.GET_STARTED);

    return (
      <Link
        key={key}
        {...props}
        {...linkStyles}
        onClick={() => onClick(label)}
        id={id}
        className={`nav-item ${highlightGetStartedNav ? 'active' : ''}`}
      >
        <HStack {...navItemContainerStyles}>
          <Box {...navBarIconContainerStyles}>
            <Icon {...iconProps} />
          </Box>
          <Text {...navTextStyles(isOpen)}>{label}</Text>
        </HStack>
      </Link>
    );
  };

  const navGroupTitleSharedStyles = navGroupTitleStyles({
    isOpen: isMobileNav ? isOpen : isOpen || isLargerThan1537,
  });
  const navGroupTitleRenderer = ({ title }) => <Text {...navGroupTitleSharedStyles}>{title}</Text>;

  const navGroups = [
    { id: 'group1', group: groupOneNavElements, title: null },
    {
      id: 'group2',
      group: groupTwoNavElements,
      title: navGroupTitleRenderer({ title: 'Banking' }),
    },
    {
      id: 'group3',
      group: groupThreeNavElements,
      title: navGroupTitleRenderer({ title: 'Bookkeeping & analytics' }),
    },
    {
      id: 'group4',
      group: groupFourNavElements,
      title: navGroupTitleRenderer({ title: 'Property management' }),
    },
    {
      id: 'group5',
      group: groupFiveNavElements,
      title: navGroupTitleRenderer({ title: 'Partners' }),
    },
  ];

  return (
    <Flex flexDirection="column" width="100%" gap={2} mb={1.5}>
      {navGroups.map((navGroup) => (
        <BaselaneList
          key={navGroup.id}
          data={navGroup.group}
          itemRenderer={listItemRenderer}
          listStyles={{ spacing: 0 }}
          title={navGroup.title}
        />
      ))}
    </Flex>
  );
}

export default NavList;
