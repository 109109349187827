import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneButton, BaselaneMessageCard } from '@shared/components';
import { IconGlobeCheck } from '@icons';
import SlLoader from '@core/components/Loader';
import RecipientForm from '../RecipientForm';
import AddressForm from '../AddressForm';
import TransferForm from '../TransferForm';
import ReviewWireView from '../ReviewWireView';
import SuccessView from '../SuccessView';
import PropertyAndCategoryTaggingForm from '../../../../PropertyAndCategoryTaggingForm';

const handleError = (error) => {
  const { message = '' } = error?.[0] ?? {};
  const errorCode = message.replace(/\s/g, '');

  const defaultMessages = {
    title: 'We are unable to process this wire',
    text: 'Go back to review entered information and try again.',
  };

  const errorConfig = {
    AccountFrozen: {
      title: 'You entered an inactive routing number',
      text: 'Go back to review entered information and try again.',
    },
    SuspectedFraud: defaultMessages,
    InsufficientFunds: {
      ...defaultMessages,
      step: 3,
    },
    InvalidRoutingNumber: {
      title: 'You entered an invalid routing number',
      text: 'Go back to review entered information and try again.',
      step: 1,
    },
    WireRejected: defaultMessages,
    AccountClosed: {
      title: 'You entered an inactive routing number',
      text: 'Go back to review entered information and try again.',
    },
    ClientRequest: defaultMessages,
  };

  return { errorCode, errorConfig: errorConfig[errorCode] ?? defaultMessages };
};

const handleFormViews = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  totalSteps,
  currentStep,
  paymentMethod,
  setIsValidAccount,
  setIsValidBankTransfer,
  setTransfer,
  propertyOptions,
  categoryOptions,
  categoryMap,
  categoryIdsMap,
  handlePropertySubmit,
  handleCategorySubmit,
  handleErrorRedirect,
  transfer,
  error,
  setShowMobileDropdownPopup,
  setShowMobileDropdownPopupAnimation,
  showMobileDropdownPopup,
}) => {
  const sharedProps = {
    totalSteps,
    currentStep,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
  };
  const { errorConfig } = handleError(error);
  const { isMinXL } = getBreakPoints();

  switch (currentStep) {
    case 1:
      return <RecipientForm {...sharedProps} />;
    case 2:
      return <AddressForm {...sharedProps} />;
    case 3:
      return (
        <TransferForm
          {...{
            ...sharedProps,
            paymentMethod,
            setIsValidAccount,
            setIsValidBankTransfer,
            setTransfer,
            transfer,
          }}
        />
      );
    case 4:
      return (
        <PropertyAndCategoryTaggingForm
          {...{
            handlePropertySubmit,
            handleCategorySubmit,
            propertyOptions,
            categoryOptions,
            categoryIdsMap,
            categoryMap,
            setShowMobileDropdownPopup,
            setShowMobileDropdownPopupAnimation,
            showMobileDropdownPopup,
            isValidBankTransfer: true,
            renderCategoryDropdownItem: (item) => item.name,
            drawerTitle: 'Domestic Wire Transfer',
            transferTypeText: 'wire transfer',
            ...sharedProps,
          }}
        />
      );

    case 5:
      return (
        <Stack spacing="30px">
          {error && (
            <BaselaneMessageCard
              title={errorConfig?.title}
              text={errorConfig?.text}
              iconName="close"
              iconColor="red"
              borderColor="red"
              isVertical
              rightElement={
                errorConfig?.step &&
                !isMinXL && (
                  <BaselaneButton
                    variant="filled"
                    palette="primary"
                    onClick={() => {
                      handleErrorRedirect(errorConfig?.step);
                    }}
                  >
                    Edit
                  </BaselaneButton>
                )
              }
              bottomElement={
                errorConfig?.step &&
                isMinXL && (
                  <Box>
                    <BaselaneButton
                      variant="filled"
                      palette="primary"
                      onClick={() => {
                        handleErrorRedirect(errorConfig?.step);
                      }}
                    >
                      Edit
                    </BaselaneButton>
                  </Box>
                )
              }
              iconHeight="14"
              iconWidth="14"
              iconContainerStyles={{ w: '20px', ml: '10px', mr: '10px' }}
            />
          )}
          <ReviewWireView
            {...{
              values,
              propertyOptions,
              categoryOptions,
              transfer,
              categoryIdsMap,
            }}
          />
        </Stack>
      );
    case 6:
      return (
        <SuccessView
          {...{
            icon: <IconGlobeCheck />,
            title: "You've scheduled a wire transfer",
            message: 'You can view the status of this wire on the Transfers & Payments page.',
          }}
        />
      );
    default:
      return <SlLoader className="" />;
  }
};

export default handleFormViews;
