import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { sortWithString } from '@core/utils/sort';
import { BaselaneErrorCard } from '@shared/components';
import { GET_PROPERTIES_ADDRESS } from '@core/apollo/queries';
import useLeaseBanks from '@pages/LeasesPage/hooks/useLeaseBanks';
import SlLoader from '@core/components/Loader';
import UnifiedBookkeepingAnalyticsFlow from './UnifiedBookkeepingAnalyticsFlow';

function UnifiedBookkeepingAnalyticsUI() {
  const { location } = useLocation();
  const historyState = location?.state;

  const { data, error, loading, refetch: refreshProperties } = useQuery(GET_PROPERTIES_ADDRESS);
  const sortedProperties = data?.property?.length > 0 ? sortWithString(data.property, 'name') : [];

  // bank data
  const { bankProps, banksError, banksLoading, allBanksError, allBanksLoading } = useLeaseBanks();

  if (loading || banksLoading || allBanksLoading) return <SlLoader />;
  if (error || banksError || allBanksError) {
    return <BaselaneErrorCard />;
  }

  return (
    <UnifiedBookkeepingAnalyticsFlow
      {...{
        properties: sortedProperties,
        refreshProperties,
        bankProps,
        historyState,
      }}
    />
  );
}

export default UnifiedBookkeepingAnalyticsUI;
