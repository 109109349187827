import { gql } from '@apollo/client';

export const KPI_QUERY = gql`
  query($input: KpiInput) {
    kpi(input: $input) {
      byProperty {
        name
        data {
          propertyId
          data {
            name
            date
            value
          }
        }
      }
      byPropertyAggregate {
        name
        data {
          propertyIds
          data {
            name
            date
            value
          }
        }
      }
    }
  }
`;
