/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import useOtpVerification from '@hooks/useOtpVerification';
import useBankSummary from '@hooks/useBankSummary';
import { BaselaneButton, TwoFactorVerificationPopUp } from '@core/components/Shared/components';
import { Icon16Check, Icon16ChevronLeft } from '@icons/16px';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import steps from '../steps';
import getTransferType from '../helpers/getTransferType';

const ReviewAndTransferFooter = ({ goToStep, isLoading: somethingOnParentIsLoading }) => {
  const { values, submitForm } = useFormikContext();
  const { bankAccountsById, loading: activeBankAccountsAreLoading } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
  });
  const transferFromAccount = useMemo(() => bankAccountsById[values.transferFromAccountId], [
    bankAccountsById,
  ]);
  const depositToAccount = useMemo(() => bankAccountsById[values.depositToAccountId], [
    bankAccountsById,
  ]);

  const transferType = getTransferType(transferFromAccount, depositToAccount);

  const bankId =
    transferType === 'TRANSFER_IN'
      ? depositToAccount?.userInstitutionId
      : transferFromAccount?.userInstitutionId;
  const { banksById } = useBankSummary();

  const { props, isTokenExpired, handleOtpPopupOpen } = useOtpVerification({
    bankId,
    phoneNumber: banksById[bankId]?.unitAccount?.phoneNumber,
    callback: submitForm,
  });

  const handleDeposit = () => {
    if (isTokenExpired) {
      handleOtpPopupOpen();
    } else {
      submitForm();
    }
  };

  return (
    <>
      <Box display="flex" gap={2} width="100%">
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={() => goToStep(steps.BOOKKEEPING_DETAILS)}
          leftIcon={<Icon16ChevronLeft />}
        >
          Back
        </BaselaneButton>
        <BaselaneButton
          type="submit"
          isFullWidth
          variant="filled"
          palette="primary"
          size="md"
          leftIcon={<Icon16Check />}
          isLoading={somethingOnParentIsLoading || activeBankAccountsAreLoading}
          onClick={handleDeposit}
        >
          Transfer
        </BaselaneButton>
      </Box>
      <TwoFactorVerificationPopUp {...props} />
    </>
  );
};

export default ReviewAndTransferFooter;
