import React from 'react';
import { Stack } from '@chakra-ui/react';
import { getFAQComponent } from './helpers/faq.helpers';
import { cashflowTaxPackageStyles } from './styles/faq.styles';

function FAQ() {
  // Destructure Imported Styles
  const { container } = cashflowTaxPackageStyles;

  const FAQs = getFAQComponent();

  return <Stack {...container}>{FAQs}</Stack>;
}

export default FAQ;
