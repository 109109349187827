// @flow
import React, { useContext, useState, useEffect, memo } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import CashFlowContext from '@contexts/CashFlowContext';

import KPIs from './KPIs';
import CashFlowDataWrapper from './CashFlowDataWrapper';
import EmptyState from './components/EmptyState';
import { cashFlowAmountBox, spinnerStyles } from './styles/responsive.style';

type CashFlowDashboardWidgetProps = {
  noBankData: boolean,
  hasBA?: boolean,
  totalTransactionCount: Number,
  transactionsSummaryLoading: boolean,
  startPollingTransactionsSummary: Function,
  stopPollingTransactionsSummary: Function,
  totalUncategorizedUnhiddenTxCount: Number,
  presetOptions: Array<Object>,
  presetsLoading: boolean,
  handleAccountConnectDrawerClose: Function,
};

function CashFlowDashboardWidget({
  noBankData,
  hasBA,
  totalTransactionCount,
  transactionsSummaryLoading,
  startPollingTransactionsSummary,
  stopPollingTransactionsSummary,
  totalUncategorizedUnhiddenTxCount,
  presetOptions,
  presetsLoading,
  handleAccountConnectDrawerClose,
}: CashFlowDashboardWidgetProps): any {
  const {
    filters,
    setFilters,
    isConnectBALoading,
    setIsConnectBALoading,
    isLoading,
    setIsLoading,
    cashFlowLoading,
    cashFlowRefetch,
  } = useContext(CashFlowContext);

  const [selectedMonth, setSelectedMonth] = useState(null);
  const [subFilters, setSubFilters] = useState({});

  useEffect(() => {
    if (hasBA) {
      cashFlowRefetch();
      startPollingTransactionsSummary(1500);
      setTimeout(() => {
        stopPollingTransactionsSummary();
      }, 4500);
    }
  }, [hasBA]);

  useEffect(() => {
    const loadingTrxs = !totalTransactionCount && totalTransactionCount !== 0;
    const noData = !loadingTrxs && totalTransactionCount === 0;
    setIsLoading(loadingTrxs);
    if (!noData) setIsConnectBALoading(false);
  }, [totalTransactionCount]);

  useEffect(() => {
    if (presetOptions) {
      setFilters({
        filter: { ...filters.filter, tagId: presetOptions[0]?.tagId, uncategorized: true },
      });
    }
  }, [presetOptions]);

  // Style imported from CashFlow's own styles/responsive.style.js
  const cashFlowAmountStyles = cashFlowAmountBox(noBankData, isMobile);

  const handleSubFiltersUpdate = (subfilters) => {
    const {
      categorySelected,
      subCategorySelected,
      dateSelected,
      ...subFiltersSelected
    } = subfilters;

    if (dateSelected) setSelectedMonth(dateSelected?.date);
    setSubFilters({ ...subFilters, ...subFiltersSelected });
  };

  const desktopCashFlow =
    isLoading || cashFlowLoading || transactionsSummaryLoading || presetsLoading ? (
      <Spinner {...spinnerStyles} />
    ) : (
      <>
        <CashFlowDataWrapper
          {...{
            handleSubFiltersUpdate,
            onSelectMonth: setSelectedMonth,
            selectedMonth,
            totalUncategorizedUnhiddenTxCount,
            hasNoData: noBankData,
            isDashboard: true,
          }}
        />
        {noBankData && (
          <EmptyState
            isConnectBALoading={isConnectBALoading}
            handleAccountConnectDrawerClose={handleAccountConnectDrawerClose}
            totalTransactionCount={totalTransactionCount}
            setIsConnectBALoading={setIsConnectBALoading}
          />
        )}
      </>
    );

  const mobileCashFlow = !presetsLoading && (
    <KPIs hasNoData={noBankData} isDashboard filters={filters} />
  );

  return (
    <Box
      key="cashflow_widget"
      display="flex"
      flexDirection="column"
      flex="1"
      {...cashFlowAmountStyles}
    >
      {isMobile ? mobileCashFlow : desktopCashFlow}
    </Box>
  );
}

CashFlowDashboardWidget.defaultProps = {
  hasBA: false,
};

export default memo(CashFlowDashboardWidget);
