import React from 'react';
import { BaselaneResponsiveBanner } from '@shared/components';
import { IconUIArrowRight, IconBankError } from '@core/components/Icons';
import formatCurrency from '@core/utils/formatCurrency';
import customTheme from '@core/theme';

type FailedPaymentBannerProps = {
  account: Object,
  setReceivingBankAccountId: Function,
};

function FailedPaymentBanner({ account, setReceivingBankAccountId }: FailedPaymentBannerProps) {
  const { id, nickName, payoutFailedInvoice } = account ?? {};
  const { count, amount } = payoutFailedInvoice ?? {};

  return (
    <BaselaneResponsiveBanner
      icon={
        <IconBankError
          {...{
            w: '40',
            h: '40',
          }}
        />
      }
      indicatorColor={customTheme.colors.red['100']}
      title={`${nickName} unable to receive funds`}
      description={`${formatCurrency(amount).inDollars} from ${count} invoice${
        count === 1 ? '' : 's'
      } could not be paid out`}
      callToActionLabel="Review account"
      callToActionRightIcon={<IconUIArrowRight w="18" />}
      onCallToActionClick={() => {
        setReceivingBankAccountId(id);
      }}
      styles={{
        title: { color: customTheme.colors.brand.neutral['700'] },
        description: { color: customTheme.colors.brand.neutral['600'] },
      }}
      borderColor={customTheme.colors.brand.darkBlue['150']}
      m="16px"
    />
  );
}

export default FailedPaymentBanner;
