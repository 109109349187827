import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';

import { Icon24AddFunds1 } from '@icons/24px';
import { Icon16Check, Icon16Security } from '@icons/16px';
import userContext from '@contexts/UserContext';
import BanksContext from '@contexts/BanksContext';
import OTPContext from '@contexts/OTPContext';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import formatCurrency from '@core/utils/formatCurrency';
import historyStateStorage from '@core/storage/historyStateStorage';
import { currencyMask } from '@core/utils/masks/index';
import stripCurrency from '@core/utils/stripCurrency';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneButton, VerifyEmailModal } from '@shared/components';
import { ADD_FUNDS_BANK_TRANSFER } from '@routes';
import { reload } from '@core/Services/Firebase/firebase';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import CenterContentWrapper from '@shared/layouts/CenterContentWrapper';
import {
  headerContainerStyles,
  titleTextStyles,
  descriptionTextStyles,
  amountContainerStyles,
  amountContainerIconStyles,
  amountContainerHeaderStyles,
  contentContainerStyles,
  insuredTextStyles,
  amountTextStyles,
} from '@core/components/OnboardingTriage/styles/success.styles';
import useUserSignUpExperiment from '@core/hooks/useUserSignUpExperiment';

import { successConfig } from './helpers/successConfig.helpers';

const SuccessUIB = () => {
  const [selected, setSelected] = useState(null); // dummy amount for zero state, no selected box, no input box
  const [amount, setAmount] = useState(null);
  const [customAddFundsAmount, setCustomAddFundsAmount] = useState(null);
  const [touched, setTouched] = useState(false);

  // This is the bank data needed here, for Add Funds drawer etc
  const { loading: isBanksLoading, error: hasBanksError } = useContext(BanksContext);

  const { user, setEmailVerified } = useContext(userContext);
  const { isMin899 } = getBreakPoints();

  // 2024-07_sign_up_page_experiment
  const { handleSendOtpRegisteredUser } = useContext(OTPContext);
  const { isUserEmailUnverified } = useUserSignUpExperiment();
  const [showEmailVerifyPopup, setEmailVerifyPopup] = useState(false);
  const onVerifyEmailOtpSuccess = async () => {
    const emailVerifiedByFirebase = await reload();
    if (emailVerifiedByFirebase) {
      setEmailVerifyPopup(false);
      setEmailVerified(true);
      setTimeout(() => handleNavigate(stripCurrency(amount)), 0);
    }
  };

  // if user opened lb flow from another flow from state will be an object
  const historyStateData = historyStateStorage.read('baselane-state');
  const fromStateFlow =
    typeof historyStateData?.from === 'string'
      ? historyStateData?.from
      : historyStateData?.from?.flow;

  const config = successConfig(user?.onboardingCompleted, fromStateFlow);
  // this is Success B flow, only showing Approval state, not denied or docs needed, so hardcoding status
  const { icon, subText, title, description, segmentEventOnLoad } =
    config.COMPLETED_B ?? config.EMPTY_STATE;
  const isOnboardingCompleted = user?.onboardingCompleted;

  // The selectable amounts, 0 for Custom amount (if there is no amount, there will be an input box)
  const amounts = [100, 5000, 10000, 0];

  const handleChange = (id) => {
    setSelected(id);
    if (amounts[id]) {
      setAmount(amounts[id]);
    }
    if (id === 3) {
      setAmount(null);
    }
  };

  const onContinue = async () => {
    const drawerAmount = amount || customAddFundsAmount;
    if (drawerAmount > 0) {
      sendSegmentEvent(`${isOnboardingCompleted ? 'baselane' : 'onboarding'}_approval_click_cta`, {
        action: 'prefill_add_funds',
        amount: amount || customAddFundsAmount,
      });
    }

    const emailVerifiedByFirebase = await reload();
    if (!emailVerifiedByFirebase && isUserEmailUnverified) {
      setEmailVerifyPopup(true);
      handleSendOtpRegisteredUser({ isTriggeredFromModal: false });
    } else {
      handleNavigate(stripCurrency(amount));
    }
  };

  useEffect(() => {
    // console.log('amount or custom amount changed, update Add Funds drawer');
  }, [amount, customAddFundsAmount]);

  useEffect(() => {
    if (segmentEventOnLoad) {
      sendSegmentEvent(segmentEventOnLoad, { test_variant: 'B' });
    }
  }, []);

  const mobileTitle = subText ? `${subText} ${title}` : title;

  const navigate = useNavigate();
  const handleNavigate = () => {
    const drawerAmount = amount || customAddFundsAmount;
    if (drawerAmount > 0) {
      sendSegmentEvent(
        `${isOnboardingCompleted ? 'baselane' : 'onboarding'}_approval_version_b_click_cta`,
        {
          action: 'prefill_add_funds',
          amount: drawerAmount,
        }
      );
      navigate(ADD_FUNDS_BANK_TRANSFER, { state: { amount: drawerAmount } });

      setTouched(false);
    } else {
      setTouched(true);
    }
  };

  return (
    <>
      <CenterContentWrapper
        containerStyles={{
          justifyContent: 'flex-start',
          paddingTop: isMobile ? 2 : '50px',
          overflow: 'auto',
        }}
        innerContainerStyles={{
          spacing: isMin899 ? '50px' : '0px',
          width: '100%',
        }}
      >
        <Flex {...contentContainerStyles(isMin899)} direction="column">
          <Stack {...headerContainerStyles} mb="54px">
            {icon}
            {title && <Heading {...titleTextStyles}>{isMobile ? mobileTitle : title}</Heading>}
            <Text {...descriptionTextStyles}>{description}</Text>
          </Stack>
          <Stack {...amountContainerStyles} mb="24px" spacing={3}>
            <Box minWidth="24px">
              <Icon24AddFunds1 {...amountContainerIconStyles} />
            </Box>
            <VStack alignItems="left">
              <Text {...amountContainerHeaderStyles}>Fund your account to get started</Text>
              <Text {...descriptionTextStyles}>
                Securely transfer funds from any of your external accounts with a few clicks.
              </Text>
              <Flex direction="row" pt="16px" pb="24px" flexWrap="wrap" rowGap="8px">
                {amounts.map((value, index) => {
                  const isChecked = index === selected;
                  return (
                    <Button
                      key={value ?? 'Custom amount'}
                      ariaChecked={isChecked}
                      leftIcon={isChecked ? <Icon16Check color="#50A0E8" /> : null}
                      role="radio"
                      {...amountTextStyles}
                      borderColor={isChecked ? '#50A0E8' : '#DADFE6'} // brand blue 500 : 200
                      color={isChecked ? '#50A0E8' : '#192C3E'} // brand blue 500 : neutral 700
                      onClick={() => handleChange(index)}
                    >
                      {value ? formatCurrency(value).rounded : 'Custom amount'}
                    </Button>
                  );
                })}
              </Flex>
              {selected === 3 && (
                <FormControl isInvalid={touched && !customAddFundsAmount > 0} pb="24px">
                  <FormLabel {...formLabelStyles.xs} htmlFor="custom-add-funds-amount">
                    Enter custom amount
                  </FormLabel>
                  <Input
                    {...formInputStyles}
                    id="custom-add-funds-amount"
                    name="custom-add-funds-amount"
                    placeholder="$"
                    as={MaskedInput}
                    mask={currencyMask()}
                    value={customAddFundsAmount}
                    onChange={(e) => setCustomAddFundsAmount(stripCurrency(e.target.value))}
                    inputMode="decimal"
                  />
                  <FormErrorMessage {...formErrorStyles}>Enter a valid amount</FormErrorMessage>
                </FormControl>
              )}

              <BaselaneButton
                text="Continue"
                variant="filled"
                palette="primary"
                size="lg"
                style={{
                  fontWeight: 'medium',
                  width: '120px',
                }}
                isLoading={isBanksLoading || hasBanksError}
                isDisabled={!amount && !customAddFundsAmount}
                onClick={() => onContinue()}
              >
                Continue
              </BaselaneButton>
            </VStack>
          </Stack>
          <HStack {...insuredTextStyles}>
            <Icon16Security />
            <Text>Your funds are FDIC insured by Thread Bank up to $2.5 million</Text>
          </HStack>
        </Flex>
      </CenterContentWrapper>
      <Outlet />

      {isUserEmailUnverified && showEmailVerifyPopup && (
        <VerifyEmailModal
          email={user?.email}
          onVerifyEmailOtpSuccess={onVerifyEmailOtpSuccess}
          onCloseBtnClick={() => setEmailVerifyPopup(false)}
        />
      )}
    </>
  );
};

export default SuccessUIB;
