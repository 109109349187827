/* eslint-disable react/self-closing-comp */
import process from 'process';
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import RocketDocument from './RocketDocument';
import { modalStyles } from './styles/rocketLawyeriFrame.styles';

type RocketLawyeriFrameProps = {
  showiFrame: Boolean,
  selectedPropertyDoc: Object,
  isDocReadyToSign: Boolean,
  setIsDocReadyToSign: Function,
  handleCloseiFrame: Function,
};

const RocketLawyeriFrame = ({
  showiFrame,
  selectedPropertyDoc,
  isDocReadyToSign,
  setIsDocReadyToSign,
  handleCloseiFrame,
}: RocketLawyeriFrameProps) => {
  const { binderId, serviceToken, interviewId } = selectedPropertyDoc ?? {};
  const environment =
    process?.env?.REACT_APP_NODE_ENV === 'production' ||
    process?.env?.REACT_APP_NODE_ENV === 'pre-prod'
      ? 'live'
      : 'sandbox';
  const { header, closeBtn, content, iframe } = modalStyles ?? {};

  /**
   * NOTE: in order to update specific styles of Rocket Document, we need to fill in a google doc form to request the changes.
   * https://docs.google.com/forms/d/e/1FAIpQLSddJPPg0onclKYf2IIyRehCYwlTtlcogXXXxo0ZlwtZLd3ZZQ/viewform?fbzx=5836941555539130795
   */
  return (
    <Modal
      isOpen={showiFrame}
      onClose={handleCloseiFrame}
      size="full"
      padding={0}
      overflow="hidden"
    >
      <ModalOverlay />
      <ModalContent {...content} overflow="hidden">
        <ModalHeader {...header}>Lease Document</ModalHeader>
        <ModalCloseButton {...closeBtn} />
        <ModalBody padding={0} {...iframe}>
          {isDocReadyToSign ? (
            <iframe
              id="rocketSignIFrame"
              title="rocketSignIFrame"
              src={
                environment === 'sandbox'
                  ? `https://document-manager.sandbox.rocketlawyer.com/${binderId}#serviceToken=${serviceToken}`
                  : `https://document-manager.rocketlawyer.com/${binderId}#serviceToken=${serviceToken}`
              }
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            <RocketDocument {...{ serviceToken, interviewId, setIsDocReadyToSign }} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RocketLawyeriFrame;
