// @flow
import { React } from 'react';
import { Text, Radio } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { radioLabelStyles, radioDescriptionStyles } from './styles/radio.styles';

// https://www.figma.com/file/IzR58By56T8Dvo2tLLPCbP/%F0%9F%8C%80-*Baselane---Design-System-(Habitat)?type=design&node-id=1100-43126&mode=design&t=cfj65iXqrJcg7CD4-4

const BaselaneRadio = ({ label, description, children, ...props }) => {
  return (
    <Radio {...props}>
      {label && <Text {...radioLabelStyles}>{label}</Text>}
      {description && <Text {...radioDescriptionStyles}>{description}</Text>}
      {children}
    </Radio>
  );
};

BaselaneRadio.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  ...Radio.PropTypes,
};

BaselaneRadio.defaultProps = {
  description: null,
  label: null,
};

export default BaselaneRadio;
