import React from 'react';
import { Flex, Text, Spacer, Box, Divider, SkeletonText } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import formatCurrency from '@core/utils/formatCurrency';
import { Icon16Calendar } from '@icons/16px';
import { BaselaneChip } from '@shared/components';
import BookkeepingReview from './BookkeepingReview';
import RecurringIcon from './RecurringIcon';
import UpcomingTransfersAccordion from '../transfer-schedule/upcoming-transfers/UpcomingTransfersAccordion';
import Recurrance from './Recurrance';

const ReviewAndTransfer = () => {
  const { values } = useFormikContext();
  const { bankAccountsById, loading: activeBankAccountsAreLoading } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
  });
  const transferFromAccount = bankAccountsById[values.transferFromAccountId];
  const depositToAccount = bankAccountsById[values.depositToAccountId];
  const isOneTimeTransfer = values.transferType === 'one-time';
  const isSameDay = isOneTimeTransfer
    ? DateTime.fromJSDate(values.oneTimeTransfer.date).hasSame(DateTime.now(), 'day')
    : false;
  const externalAccountIsInvolved = transferFromAccount?.isExternal || depositToAccount?.isExternal;

  return (
    <>
      <Flex>
        <Text textStyle="md-heavy">Review and transfer</Text>
        <Spacer />
        <Text textStyle="xs">Step 4 of 4</Text>
      </Flex>
      <Text textStyle="sm" color="brand.neutral.700" mt={1}>
        {externalAccountIsInvolved
          ? 'External transfers typically take 3 business days.'
          : 'Transfers between your Baselane accounts are instant.'}
      </Text>
      <Text textStyle="sm" color="brand.neutral.700" mt={1}>
        {isSameDay
          ? 'Transfer requests received after 3:00 PM ET, on weekends, or on federal holidays will be processed the next business day.'
          : null}
      </Text>
      <Box display="flex" flexDirection="column" gap={3} mt={3}>
        <Box
          padding={2}
          bg="brand.darkBlue.50"
          border="1px solid"
          borderColor="brand.darkBlue.200"
          borderRadius="8px"
        >
          {/* Summary */}
          <Box display="flex" alignItems="center">
            <Box flexGrow="1">
              <Text color="brand.neutral.600" textStyle="xs">
                Transfer between
              </Text>
              <Text color="brand.neutral.900" textStyle="sm-heavy">
                Your accounts
              </Text>
              {/* Date for one-time transfer (not same day). */}
              {isOneTimeTransfer && !isSameDay ? (
                <Text color="brand.neutral.600" textStyle="xs">
                  Once at{' '}
                  {DateTime.fromJSDate(values.oneTimeTransfer.date).toLocaleString(
                    DateTime.DATE_FULL
                  )}
                </Text>
              ) : null}
              {/* Description of recurrance for recurring transfer */}
              {!isOneTimeTransfer ? <Recurrance /> : null}
            </Box>
            <Box display="flex" flexDirection="column" gap={1} alignItems="flex-end">
              {/* Icon for recurring transfer. */}
              {isOneTimeTransfer && !isSameDay ? (
                <BaselaneChip
                  size="sm"
                  label="Scheduled"
                  variant="simple-neutral"
                  leftIcon={Icon16Calendar}
                  iconColor="brand.neutral.500"
                />
              ) : null}
              {/* Icon for recurring transfer. */}
              {!isOneTimeTransfer ? (
                <BaselaneChip size="sm" label="Recurring" leftIcon={RecurringIcon} />
              ) : null}
              <Text textStyle="headline-lg">
                {formatCurrency(values.amount.replace(/,/g, '')).inDollars}
              </Text>
            </Box>
          </Box>
          {/* Upcoming transfers for recurring transfer */}
          {!isOneTimeTransfer ? <UpcomingTransfersAccordion /> : null}
          <Divider my={2} opacity={1} color="brand.darkBlue.200" />
          {/* Transfer from account. */}
          <Box display="flex" mt={1}>
            <Text textStyle="xs">From account</Text>
            <Spacer />
            <Box display="flex" flexDirection="column" textAlign="right" gap={0.5}>
              <SkeletonText
                isLoaded={!activeBankAccountsAreLoading}
                noOfLines={2}
                spacing="1"
                skeletonHeight={3}
                style={{
                  // Flip loading skeleton horizontally because it fits better UX-wise.
                  transform: activeBankAccountsAreLoading ? 'scale(-1, 1)' : 'scale(1, 1)',
                  WebkitTransform: activeBankAccountsAreLoading ? 'scale(-1, 1)' : 'scale(1, 1)',
                }}
              >
                <Text textStyle="sm" color="brand.neutral.900">
                  {transferFromAccount?.name}
                </Text>
                <Text textStyle="xs" color="brand.neutral.600">
                  {`${transferFromAccount?.institutionName} ${transferFromAccount?.accountSubType}`}
                </Text>
              </SkeletonText>
            </Box>
          </Box>
          {/* Deposit to account. */}
          <Box display="flex" mt={1}>
            <Text textStyle="xs">To account</Text>
            <Spacer />
            <Box display="flex" flexDirection="column" textAlign="right">
              <SkeletonText
                isLoaded={!activeBankAccountsAreLoading}
                noOfLines={2}
                spacing="1"
                skeletonHeight={3}
                style={{
                  // Flip loading skeleton horizontally because it fits better UX-wise.
                  transform: activeBankAccountsAreLoading ? 'scale(-1, 1)' : 'scale(1, 1)',
                  WebkitTransform: activeBankAccountsAreLoading ? 'scale(-1, 1)' : 'scale(1, 1)',
                }}
              >
                <Text textStyle="sm" color="brand.neutral.900">
                  {depositToAccount?.name}
                </Text>
                <Text textStyle="xs" color="brand.neutral.600">
                  {`${depositToAccount?.institutionName} ${depositToAccount?.accountSubType}`}
                </Text>
              </SkeletonText>
            </Box>
          </Box>
        </Box>
        <BookkeepingReview />
      </Box>
    </>
  );
};

export default ReviewAndTransfer;
