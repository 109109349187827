// @flow
export const TextVariant = {
  Normal: 'normal',
  Strong: 'strong',
  Secondary: 'secondary',
  LightSecondary: 'lightSecondary',
};

// eslint-disable-next-line no-undef
export type TextVariantType = $Values<typeof TextVariant>;
