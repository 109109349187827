import React from 'react';
import { useFormikContext } from 'formik';
import { Box, Text, Divider, Spacer } from '@chakra-ui/react';
import PropertyCardItem from '@core/features/Property/PropertyCardItem';
import CategoryCardItem from '@core/features/Category/CategoryCardItem';

const BookkeepingReview = () => {
  const { values } = useFormikContext();

  // NOTE: When category or property are cleared from dropdowns, the values are set to an empty array (?)
  const selectedProperty = Array.isArray(values.propertyId) ? null : values.propertyId;
  const categoryId = Array.isArray(values.categoryId) ? null : values.categoryId;

  // If there are no values, we don't need to show the bookkeeping details
  if (!selectedProperty && !categoryId && !values.note) {
    return null;
  }

  const [propertyId, unitId] = (selectedProperty || '').split('-');

  const tagId = categoryId?.split('-').length > 1 ? categoryId?.split('-')[1] : categoryId;

  return (
    <Box
      padding={2}
      bg="brand.darkBlue.50"
      border="1px solid"
      borderColor="brand.darkBlue.200"
      borderRadius="8px"
    >
      <Text textStyle="sm-heavy">Bookkeeping Details</Text>
      <Divider my={2} opacity={1} color="brand.darkBlue.200" />
      <PropertyCardItem propertyId={propertyId} unitId={unitId} />
      <CategoryCardItem tagId={tagId} />
      {values?.notes ? (
        <Box display="flex" mt={2}>
          <Text textStyle="xs">Notes</Text>
          <Spacer />
          <Text flex={2} textStyle="sm" textAlign="right">
            {values?.notes}
          </Text>
        </Box>
      ) : null}
    </Box>
  );
};

export default BookkeepingReview;
