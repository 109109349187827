/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@chakra-ui/react';
import { BaselaneButton } from '@core/components/Shared/components';
import { Icon16ChevronRight, Icon16ChevronLeft } from '@icons/16px';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import steps from '../steps';

const TransferScheduleFooter = ({ goToStep }) => {
  const [upcomingTransfersLength, setUpcomingTransfersLength] = useState(0);
  const [generateRecurringPaymentIsLoading, setGenerateRecurringPaymentIsLoading] = useState(false);
  const { values } = useFormikContext();

  useEffect(() => {
    document.addEventListener('recurring-transfer', (event) => {
      setUpcomingTransfersLength(event.detail?.upcomingTransfersLength);
      setGenerateRecurringPaymentIsLoading(event.detail?.generateRecurringPaymentIsLoading);
    });

    return () => {
      document.removeEventListener('recurring-transfer', (event) => {
        setUpcomingTransfersLength(event.detail?.upcomingTransfersLength);
        setGenerateRecurringPaymentIsLoading(event.detail?.generateRecurringPaymentIsLoading);
      });
    };
  }, []);
  return (
    <Box display="flex" gap={2} width="100%">
      <BaselaneButton
        variant="outline"
        palette="neutral"
        size="md"
        onClick={() => goToStep(steps.TRANSFER_DETAILS)}
        leftIcon={<Icon16ChevronLeft />}
      >
        Back
      </BaselaneButton>
      <BaselaneButton
        isFullWidth
        variant="filled"
        palette="primary"
        size="md"
        rightIcon={<Icon16ChevronRight />}
        isDisabled={
          (values.transferType === 'one-time' && !values.oneTimeTransfer.date) ||
          (values.transferType === 'recurring' && upcomingTransfersLength === 0)
        }
        isLoading={generateRecurringPaymentIsLoading}
        onClick={() => {
          sendSegmentEvent('baselane_banking_transfer_funds_step_2_complete', {
            payment_frequency: values.transferType,
          });
          goToStep(steps.BOOKKEEPING_DETAILS);
        }}
      >
        Next
      </BaselaneButton>
    </Box>
  );
};

export default TransferScheduleFooter;
