// @flow
import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { getFlatAccounts } from '../Accounts/helpers/bank.helpers';
import { GET_CARD_SUMMARY } from '../../../queries';
import BanksContext from '../../../../../contexts/BanksContext';
import CardsTable from './CardsTable';

function Cards() {
  const cardsQueryItems = useQuery(GET_CARD_SUMMARY, {
    variables: {
      page: 1,
      pageSize: 99,
      filter: {
        includeHiddenCards: false,
      },
    },
  });
  const { baselaneConnectedAccounts, refetchBankSummary } = useContext(BanksContext);
  const subAccountIds = getFlatAccounts(baselaneConnectedAccounts)
    ?.subAccounts?.filter(
      (subA) =>
        subA.connectionState === 'CONNECTED' && subA.isConnected && subA.accountStatus === 'Open'
    )
    ?.map((subAccount) => Number(subAccount.id));

  return (
    <CardsTable
      cardsQueryItems={cardsQueryItems}
      allBankAccountIdsFilter={subAccountIds}
      refetchBankSummary={refetchBankSummary}
    />
  );
}

export default Cards;
