import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BaselaneChip,
  BaselaneSummaryCard,
  BaselaneSummaryCardAmountHeader,
} from '@shared/components';

const Account = ({ account }) => {
  const { accountName, nickName } = account;

  const nickNameElement = nickName ? (
    <Text textStyle="xs" color="brand.neutral.600">
      {nickName}
    </Text>
  ) : null;

  return (
    <Flex direction="column" alignItems="flex-end">
      <Text textStyle="sm">{accountName}</Text>
      {nickNameElement}
    </Flex>
  );
};

Account.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    nickName: PropTypes.string,
  }).isRequired,
};

const Name = ({ merchantName, name }) => {
  const merchantNameElement = <Text textStyle="md-heavy">{merchantName || name}</Text>;

  const nameElement =
    merchantName && merchantName !== name ? (
      <Text textStyle="xs" color="brand.neutral.600">
        {name}
      </Text>
    ) : null;

  return (
    <Box gap={0}>
      {merchantNameElement}
      {nameElement}
    </Box>
  );
};

Name.propTypes = {
  merchantName: PropTypes.string.isRequired,
  name: PropTypes.string,
};

Name.defaultProps = {
  name: null,
};

const PreviewTransaction = ({ transaction, account }) => {
  const header = (
    <BaselaneSummaryCardAmountHeader
      {...{
        title: (
          <Flex gap={1} alignItems="center">
            <Name merchantName={transaction.merchantName} name={transaction.name} />
            {transaction.pending ? (
              <BaselaneChip size="sm" label="Pending" variant="status-primary" />
            ) : null}
          </Flex>
        ),

        amount: transaction.amount,
      }}
    />
  );
  const list = [
    {
      items: [
        {
          label: ' Account',
          value: account ? (
            <Account account={account} />
          ) : (
            <Text textStyle="sm">Manually Added</Text>
          ),
        },
        {
          label: 'Date',
          value: <Text textStyle="sm">{moment(transaction.date).format('MMM DD, YYYY')}</Text>,
        },
      ],
    },
  ];

  return <BaselaneSummaryCard isCustom header={header} list={list} />;
};

PreviewTransaction.propTypes = {
  transaction: PropTypes.shape({
    merchantName: PropTypes.string,
    name: PropTypes.string,
    amount: PropTypes.number,
    date: PropTypes.string,
    pending: PropTypes.bool,
  }).isRequired,
  account: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    nickName: PropTypes.string,
  }),
};

PreviewTransaction.defaultProps = {
  account: null,
};

export default PreviewTransaction;
