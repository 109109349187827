import React from 'react';

function IconArrowLeft({
  w = 18,
  h = 10,
  color = '#6C7884',
}: {
  w: string,
  h: string,
  color: string,
}) {
  return (
    <svg
      fill="none"
      width={`${w}px`}
      height={`${h}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 10"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.42194 1.07757c.32544.32544.32544.85308 0 1.17852L3.51119 4.16683H16.4993c.4603 0 .8334.3731.8334.83333 0 .46024-.3731.83334-.8334.83334H3.51119l1.91075 1.91074c.32544.32544.32544.85307 0 1.17851-.32544.32544-.85308.32544-1.17851 0l-3.333005-3.333c-.000111-.00011-.000221-.00022-.000332-.00033a1.46937282 1.46937282 0 0 1-.001764-.00177c-.079021-.07949-.138715-.17094-.179081-.2685-.040744-.09826-.063232-.206-.063232-.31899 0-.11299.022488-.22073.063232-.31898.040666-.09829.100948-.19038.180845-.27027L4.24343 1.07757c.32543-.325433.85307-.325433 1.17851 0Z"
        fill={color}
      />
    </svg>
  );
}

export default IconArrowLeft;
