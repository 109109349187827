import { isMobile } from 'react-device-detect';
import customTheme from '@core/theme';

export const titleTextStyles = {
  color: customTheme.colors.brand.neutral['700'],
  fontSize: isMobile ? 'lg' : '2xl',
  lineHeight: isMobile ? '26px' : '32px',
  fontWeight: 'medium',
};

export const subTitleTextStyles = {
  color: customTheme.colors.brand.neutral['600'],
  textStyle: 'xs',
};

export const centerContentWrapperStyles = {
  containerStyles: {
    justifyContent: 'flex-start',
    pt: isMobile ? '0' : '80px',
    pb: '40px',
    overflow: 'auto',
  },
  innerContainerStyles: {
    w: isMobile ? 'auto' : '600px',
    spacing: '40px',
    justifyContent: 'flex-start',
    height: 'auto',
  },
};
