import React, { useContext, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Stack, Text, useDisclosure } from '@chakra-ui/react';

import {
  BaselaneApplicationTypePopup,
  BaselaneButton,
  BaselaneEmptyStateCard,
} from '@shared/components';
import CardImage from '@core/components/NativeBankingPage/MainContent/components/Accounts/BaselaneAccounts/EmptyStateView/CardImage';
import getBreakPoints from '@core/utils/getBreakPoints';
import { NATIVE_BANK, UNIFIED_LB_PERSONAL_INFO } from '@routes';
import historyStateStorage from '@core/storage/historyStateStorage';
import BanksContext from '@contexts/BanksContext';
import { IllustrationBankingNewaccount } from '@illustrations';

import {
  emptyStateContainerStyles,
  headingTextStyles,
  imageContainerStyles,
  leftElementContainerStyles,
  rightElementContainerStyles,
  subtextStyles,
} from './styles/emptyState.styles';

const EmptyStateView = () => {
  const { isMin768 } = getBreakPoints();

  const navigate = useNavigate();

  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const addBaselaneAccountDrawerRef = useRef(null);
  const handleOpenBaselaneAccountDrawer = () => addBaselaneAccountDrawerRef.current.open();
  const { userHasDraftApplication, hasActiveBaselaneAccounts, getKYCUrl } = useContext(
    BanksContext
  );

  const openLBFlow = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const state = { from: NATIVE_BANK };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  const handleOpenBaselaneAccountClick = () => {
    if (hasActiveBaselaneAccounts) {
      handleOpenBaselaneAccountDrawer();
    } else {
      onBaselaneApplicationTypePopupOpen();
    }
  };

  let buttonText = 'Open Baselane Banking Account';
  if (!hasActiveBaselaneAccounts && userHasDraftApplication) {
    buttonText = 'Continue Application';
  }

  return (
    <Stack direction={isMin768 ? 'row' : 'column'} {...emptyStateContainerStyles}>
      <Outlet context={{ isEmptyState: true }} />
      <Stack {...leftElementContainerStyles}>
        <Stack spacing="8px">
          <IllustrationBankingNewaccount />
          <Text {...headingTextStyles}>Open Baselane Banking Account</Text>
          <Text {...subtextStyles}>Open in minutes. No account fees or minimum balances.</Text>
        </Stack>

        <Box>
          <BaselaneButton
            variant="filled"
            palette="primary"
            size="lg"
            onClick={handleOpenBaselaneAccountClick}
          >
            {buttonText}
          </BaselaneButton>
        </Box>
        <BaselaneApplicationTypePopup
          handleActionButton={openLBFlow}
          isOpen={isBaselaneApplicationTypePopupOpen}
          onClose={onBaselaneApplicationTypePopupClose}
        />
      </Stack>
      <Box {...rightElementContainerStyles}>
        <Box>
          <BaselaneEmptyStateCard.TitleSection fontSize="sm" mb="12px">
            Banking built for rental property finance
          </BaselaneEmptyStateCard.TitleSection>
          <BaselaneEmptyStateCard.BulletPoints
            type="check"
            iconMarginRight="8px"
            alignItems="flex-start"
            mb="14px"
            _last={{ mb: 0 }}
          >
            <Text>
              Separate accounts for properties, security deposits, savings buffers & more opened
              with one click
            </Text>
            <Text>Up to 4.19% APY on savings accounts, FDIC-insured by Thread Bank</Text>
            <Text>Physical & virtual debit cards with spend controls</Text>
            <Text>Up to 5% cash back on debit spent card</Text>
          </BaselaneEmptyStateCard.BulletPoints>
        </Box>
      </Box>
      <Stack {...imageContainerStyles}>
        <CardImage />
      </Stack>
    </Stack>
  );
};

export default EmptyStateView;
