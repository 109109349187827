import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

/**
 * This overrides Chakra's default <Tabs /> styles.
 *
 * see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/tabs.ts for implementation details
 */

const parts = ['root', 'tab', 'tablist', 'tabpanel', 'tabpanels', 'indicator'];
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Tabs = defineMultiStyleConfig({
  baseStyle: {
    tabpanel: {
      p: 2,
    },
  },
  sizes: {
    sm: {
      tab: {
        py: 0.5,
        px: 2,
      },
    },
    md: {
      tab: {
        py: 1,
        px: 2,
      },
    },
    lg: {
      tab: {
        py: 1.5,
        px: 2,
      },
    },
  },
});
