import React from 'react';
import BaselaneResponsiveCellTitleContent from '@shared/components/BaselaneResponsiveTable/BaslaneResponsiveCell/BaselaneResponsiveCellTitleContent';
import BaselaneResponsiveCell from './BaselaneResponsiveCell';
import { titleCellContainerStyle } from '../styles/cell.styles';

type BaselaneResponsiveCellTitleProps = {
  /**
   * Cell title
   */
  title: String,
  /**
   * Cell preheader
   */
  preheader: String,
  /**
   * Cell subtitle
   */
  subtitle?: String,
  /**
   * Title size token
   */
  titleSize?: String,
  /**
   * Subtitle text styles
   */
  subtitleTextStyle?: String,
  /**
   * Cell element on left of title and subtitle. Can be used to any type of element
   * at the moment most common case is image/icon
   */
  graphicElement?: React.Node,
  /**
   * Cell element on right of title
   */
  rightTitleElement?: React.Node,
  /**
   * Cell layout - display children vertically
   */
  isVertical?: String,
  /**
   * Cell element styles
   */
  styles?: Object,
  /**
   * Cell element - display element next to the title
   */
  rightElement?: React.Node,
  /**
   * Cell children
   */
  children?: React.Node,
};

/**
 * Renders a variation of BaselaneReponsiveCell to be used inside BaselaneResponsiveTableRow.
 *
 * @returns {JSX.Element}
 */
const BaselaneResponsiveCellTitle = ({
  preheader,
  title,
  subtitle,
  titleSize,
  subtitleTextStyle,
  graphicElement,
  rightTitleElement,
  isVertical,
  styles,
  rightElement,
  children,
  ...rest
}: BaselaneResponsiveCellTitleProps) => {
  const cellProps = {
    ...titleCellContainerStyle({ isVertical }),
    ...rest,
  };

  return (
    <BaselaneResponsiveCell {...cellProps}>
      <BaselaneResponsiveCellTitleContent
        preheader={preheader}
        title={title}
        subtitle={subtitle}
        titleSize={titleSize}
        graphicElement={graphicElement}
        rightTitleElement={rightTitleElement}
        isVertical={isVertical}
        styles={styles}
        subtitleTextStyle={subtitleTextStyle}
        rightElement={rightElement}
      >
        {children}
      </BaselaneResponsiveCellTitleContent>
    </BaselaneResponsiveCell>
  );
};

BaselaneResponsiveCellTitle.defaultProps = {
  subtitle: null,
  titleSize: 'sm-heavy',
  subtitleTextStyle: 'xs',
  graphicElement: null,
  rightTitleElement: null,
  isVertical: false,
  styles: {},
  rightElement: null,
  children: null,
};

export default BaselaneResponsiveCellTitle;
