import React from 'react';

function IconExternalBankTransfer({ w, h }: { w?: number, h?: number }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13"
        cy="13"
        r="10"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m17.8574 10.687-.0005-.0005-2.3817-2.51797c-.0001-.00009-.0001-.00018-.0002-.00027-.1041-.11634-.2721-.19326-.4403-.19326-.1678 0-.3235.06436-.4401.19311l-1.5945 1.64772-1.5823-1.6477c-.1166-.12876-.2722-.19313-.4401-.19313-.1682 0-.3362.07692-.4403.19326L8.14339 10.6986c-.10392.1036-.16839.258-.16839.4125v5.3087c0 .3349.27186.6052.60813.6052h8.83377c.3362 0 .6081-.2703.6081-.6052v-5.3087c0-.1549-.0647-.3088-.1676-.4241Zm-6.5876.9253-.0006.0007c-.1029.1152-.1676.2691-.1676.424v2c0 .3349.2718.6053.6081.6053h2.5682c.3363 0 .6081-.2704.6081-.6053v-2c0-.1549-.0647-.3088-.1676-.424l-.0007-.0007-1.1742-1.2172 1.491-1.53184 2.1961 2.31954v5.0391H8.78182v-5.0268l2.19598-2.33163 1.4664 1.53153-1.1744 1.2173ZM13 10.9867l1.0916 1.1345v1.718h-2.1832v-1.718L13 10.9867Z"
        fill="#000619"
        stroke="#000619"
        strokeWidth=".05"
      />
      <path
        d="m33.3026 6.31516-.2215.44823.2215-.44823ZM43.7603 21.1269c.2254.1595.5375.1061.697-.1193l2.5992-3.6734c.1595-.2254.1061-.5375-.1193-.697-.2254-.1595-.5375-.1061-.697.1194l-2.3104 3.2652-3.2653-2.3105c-.2254-.1595-.5374-.106-.6969.1194-.1595.2254-.1061.5375.1193.697l3.6734 2.5992ZM26.5504 3.86192c-.2748-.02782-.52.17234-.5479.44708-.0278.27474.1724.52001.4471.54783l.1008-.99491Zm6.5307 2.90147c2.7316 1.35031 5.1044 3.32601 6.9247 5.76581l.8015-.598c-1.9147-2.5663-4.4103-4.64418-7.2831-6.06427l-.4431.89646Zm6.9247 5.76581c1.8204 2.4399 3.0369 5.2749 3.5505 8.274l.9856-.1688c-.5402-3.1548-1.8198-6.1368-3.7346-8.7032l-.8015.598ZM26.4496 4.85683c2.3016.23308 4.5484.87689 6.6315 1.90656l.4431-.89646c-2.1907-1.08288-4.5535-1.75991-6.9738-2.00501l-.1008.99491ZM5.25408 32.2152l-.44126.2351.44126-.2351Zm15.12352 9.9993c.1664.2204.1225.5339-.0979.7002l-3.5921 2.7105c-.2205.1663-.534.1225-.7003-.0979-.1664-.2205-.1225-.534.0979-.7003l3.193-2.4093-2.4093-3.193c-.1663-.2205-.1224-.534.098-.7003.2204-.1664.5339-.1225.7003.0979l2.7104 3.5922ZM2.67511 26.0812c-.04349-.2726.14232-.529.41501-.5725.2727-.0435.52902.1424.57251.4151l-.98752.1574Zm3.02024 5.8989c1.43286 2.689 3.47986 5 5.97405 6.7448l-.5732.8194c-2.62351-1.8352-4.77645-4.266-6.28338-7.094l.88253-.4702Zm5.97405 6.7448c2.4942 1.7448 5.365 2.874 8.3784 3.2955l-.1386.9904c-3.1697-.4434-6.1895-1.6312-8.813-3.4665l.5732-.8194ZM3.66263 25.9238c.33682 2.1119 1.02183 4.1592 2.03272 6.0563l-.88253.4702c-1.06314-1.9951-1.78351-4.1482-2.13771-6.3691l.98752-.1574Z"
        fill="#000619"
      />
      <path
        d="M43.2222 45c.6223 0 .9334 0 1.1711-.1213.2091-.1066.3791-.2768.4856-.4862.1211-.238.1211-.5496.1211-1.1727v-.8901c0-.6231 0-.9347-.1211-1.1727-.1065-.2093-.2765-.3795-.4856-.4862-.2377-.1213-.5488-.1213-1.1711-.1213H26.7778c-.6223 0-.9334 0-1.1711.1213-.2091.1067-.3791.2769-.4856.4862C25 41.395 25 41.7066 25 42.3297v.8901c0 .6231 0 .9347.1211 1.1727.1065.2094.2765.3796.4856.4862.2377.1213.5488.1213 1.1711.1213h16.4444ZM43.2222 31.6486c.6223 0 .9334 0 1.1711-.1213.2091-.1067.3791-.2769.4856-.4862.1211-.238.1211-.5496.1211-1.1727v-1.2423c0-.5095 0-.7643-.0902-.9745-.0795-.1855-.2079-.3458-.3714-.4637-.1854-.1338-.4338-.189-.9305-.2996l-8.2222-1.8296c-.144-.032-.2159-.0481-.2886-.0544-.0646-.0057-.1296-.0057-.1942 0-.0727.0063-.1446.0224-.2886.0544l-8.2222 1.8296c-.4967.1106-.7451.1658-.9305.2996-.1635.1179-.2919.2782-.3714.4637-.0902.2102-.0902.465-.0902.9745v1.2423c0 .6231 0 .9347.1211 1.1727.1065.2093.2765.3795.4856.4862.2377.1213.5488.1213 1.1711.1213h16.4444Z"
        fill="#6ADA9B"
      />
      <path
        d="M27.4242 30h4.8485v11.875h-4.8485V30ZM37.7273 30h4.8485v11.875h-4.8485V30Z"
        fill="#6ADA9B"
      />
      <path
        d="M26.2222 30.6486v8.9009m5-8.9009v8.9009m5.5556-8.9009v8.9009m5-8.9009v8.9009M24 41.3297v.8901c0 .6231 0 .9347.1211 1.1727.1065.2094.2765.3796.4856.4862.2377.1213.5488.1213 1.1711.1213h16.4444c.6223 0 .9334 0 1.1711-.1213.2091-.1066.3791-.2768.4856-.4862.1211-.238.1211-.5496.1211-1.1727v-.8901c0-.6231 0-.9347-.1211-1.1727-.1065-.2093-.2765-.3795-.4856-.4862-.2377-.1213-.5488-.1213-1.1711-.1213H25.7778c-.6223 0-.9334 0-1.1711.1213-.2091.1067-.3791.2769-.4856.4862C24 40.395 24 40.7066 24 41.3297Zm9.6143-17.271-8.2222 1.8296c-.4967.1106-.7451.1658-.9305.2996-.1635.1179-.2919.2782-.3714.4637-.0902.2102-.0902.465-.0902.9745v1.2423c0 .6231 0 .9347.1211 1.1727.1065.2093.2765.3795.4856.4862.2377.1213.5488.1213 1.1711.1213h16.4444c.6223 0 .9334 0 1.1711-.1213.2091-.1067.3791-.2769.4856-.4862.1211-.238.1211-.5496.1211-1.1727v-1.2423c0-.5095 0-.7643-.0902-.9745-.0795-.1855-.2079-.3458-.3714-.4637-.1854-.1338-.4338-.189-.9305-.2996l-8.2222-1.8296c-.144-.032-.2159-.0481-.2886-.0544-.0646-.0057-.1296-.0057-.1942 0-.0727.0063-.1446.0224-.2886.0544Z"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconExternalBankTransfer.defaultProps = {
  w: 48,
  h: 48,
};

export default IconExternalBankTransfer;
