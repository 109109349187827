import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

// Note: border adds 2px, ends up making the height 50px, hence applying hard coded height
export const size = {
  lg: {
    px: 2,
    py: 1.5,
    h: '48px',
  },
  md: {
    px: 2,
    py: 1.5,
    h: '40px',
  },
  sm: {
    px: 1.5,
    py: 1.5,
    h: '32px',
  },
  xs: {
    px: 1.5,
    py: 1.5,
  },
};

/**
 * This overrides Chakra's default Input styles.
 *
 * Note: _field_ sets the styles for the actual input field
 * while _addon_ sets the styles for left/right addon elements.
 *
 * see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/input.ts for implementation details
 */

const parts = ['addon', 'field', 'element', 'group'];
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Input = defineMultiStyleConfig({
  baseStyle: {
    field: {
      paddingInlineStart: 1,
      fontWeight: '100',
    },
  },
  sizes: {
    lg: {
      field: size.lg,
    },
    md: {
      field: size.md,
    },
    sm: {
      field: size.sm,
    },
    xs: {
      field: size.xs,
    },
  },
});
