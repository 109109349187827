import { Text } from '@chakra-ui/react';
import React from 'react';
import formatCurrency from '@core/utils/formatCurrency';
import formatPercentage from '@core/utils/formatPercentage';

type CurrencyTextProps = {
  amount: Number,
  color: String,
  negativeColor: String,
  textAlign: String,
  isRounded: Boolean,
  isPercentage: Boolean,
  isNewFormat: Boolean,
  textStyle: Object,
  styles: Object,
};

function CurrencyText({
  amount,
  color,
  negativeColor,
  isRounded,
  isPercentage,
  textAlign,
  isNewFormat = false,
  textStyle = 'data-xl',
  styles = {},
}: CurrencyTextProps) {
  const defaultPositiveAmountColor = isNewFormat ? 'green.800AA' : 'brand.neutral.700';
  const defaultNegativeAmountColor = isNewFormat ? 'brand.neutral.700' : 'red.500A';
  const positiveAmountStyle = color || defaultPositiveAmountColor;
  const negativeAmountStyle = negativeColor || defaultNegativeAmountColor;
  const formattedAmount = formatCurrency(amount, isNewFormat);

  const textStyles = {
    textAlign: textAlign || 'left',
    textStyle: textStyle || 'xl',
    color: formattedAmount.isNegative ? negativeAmountStyle : positiveAmountStyle,
    ...styles,
  };

  let textContent;

  if (isPercentage) {
    textContent = formatPercentage(amount).isPercentage;
  } else {
    textContent = isRounded ? formattedAmount.rounded : formattedAmount.inDollars;
  }

  return <Text {...textStyles}>{textContent}</Text>;
}

export default CurrencyText;
