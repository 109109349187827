export const transactionStyles = {
  chevron: {
    display: { lg: 'table-cell', base: 'none' },
  },

  note: {
    activeText: {
      color: 'brand.neutral.900',
    },
    placeholderText: {
      color: 'brand.neutral.500',
    },
    input: {
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: { lg: '20px', base: '16px' },
      textStyle: 'sm',
      color: 'brand.neutral.900',
      _hover: {
        borderColor: 'brand.darkBlue.200',
        bg: 'white',
      },
      _focus: {
        borderColor: 'brand.blue.700',
        boxShadow: '0px 0px 0px 2px #E1F4FF inset',
      },
      borderColor: 'transparent',
      p: '12px 32px 12px 12px',
      boxSizing: 'border-box',
    },
    icon: {
      height: '30px',
      lineHeight: '20px',
      marginTop: '2px', // to even out the border
      zIndex: 'auto',
    },
  },
};
