import * as React from 'react';
import customTheme from '@core/theme';
import { IllustrationBankingApplicationinreview } from '@illustrations';
import BaselaneResponsiveBanner from './BaselaneResponsiveBanner';

type BaselaneResponsiveAccountInProgressBannerProps = {
  /**
   * @prop {Object} - Unit application object that contains data used to populate banner
   */
  unitApplication: Object,
};

/**
 * Renders a responsive banner for accounts in the 'in progress' state.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveAccountInProgressBanner = ({
  unitApplication,
  ...rest
}: BaselaneResponsiveAccountInProgressBannerProps) => {
  // TODO: this needs to be a dynamic title based on what is currently missing data
  const { name: bankName } = unitApplication;

  return (
    <BaselaneResponsiveBanner
      icon={<IllustrationBankingApplicationinreview />}
      indicatorColor={customTheme.colors.brand.blue['50']}
      title={bankName}
      description="We are currently reviewing your application. It may take up to 2 business hours to review."
      {...rest}
    />
  );
};

export default BaselaneResponsiveAccountInProgressBanner;
