import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useExperiment } from 'statsig-react';
import { checkEmail } from '@core/Services/Firebase/firebase';
import useReferralSource from '@pages/SignUpPage/useReferralSource';
import { BaselaneSpinner } from '@core/components/Shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useOnboardingStore from '@core/store/Onboarding';
import { EMAIL_EXISTS, SIGN_UP } from '@core/constants/routes';
import DeferredSignupPropertySurvey from '@core/pages/DeferredSignupPropertySurvey';
import SignUpPage from '@core/pages/SignUpPage';

/**
 * Effectively checks for an email in the URL, checks if it already exists
 * and if not applies the experiment logic to signup flow.  Otherwise
 * allows normal flow through the outlet.
 */

const DeferredSignUpExperiment = () => {
  const { isReferral } = useReferralSource();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const { email, setEmail } = useOnboardingStore();

  // URL email state
  const [emailExists, setEmailExists] = useState(false);
  const [showExperiment, setShowExperiment] = useState(false);

  // experiment A/B state
  const { config, isLoading: isExperimentLoading } = useExperiment(
    '2024-07_sign_up_page_experiment'
  );

  // URL email logic
  useEffect(() => {
    const encodedEmail = searchParams.get('email');
    if (encodedEmail && encodedEmail.length) {
      const decodedEmail = decodeURIComponent(encodedEmail);
      setEmail(decodedEmail);
      checkEmail(decodedEmail)
        .then((signInMethods) => {
          setIsLoading(false);
          if (signInMethods.length) {
            setEmailExists(true);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log('invalid email:', error);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  // experiment logic
  useEffect(() => {
    if (config?.isExperimentActive && !isLoading && !isExperimentLoading && !emailExists) {
      const flowName = config?.get('flow_name');
      if (flowName) {
        sendSegmentEvent(`sign_up_variant_${flowName.toLowerCase()}`);
      }
      setShowExperiment(email && !isReferral && flowName === 'Test');
    } else if (!config?.isExperimentActive && !isLoading && !isExperimentLoading) {
      setShowExperiment(false);
    } else if (emailExists) {
      navigate(`${SIGN_UP}/${EMAIL_EXISTS}`, { state: { email } });
    }
  }, [isLoading, emailExists, email, isReferral, config?.isExperimentActive, isExperimentLoading]);

  useEffect(() => {
    setShowLoader(isLoading || isExperimentLoading);
  }, [isLoading, isExperimentLoading]);

  return (
    <>
      {showLoader && <BaselaneSpinner />}
      {!showLoader && showExperiment && <DeferredSignupPropertySurvey />}
      {!showLoader && !showExperiment && <SignUpPage />}
    </>
  );
};

export default DeferredSignUpExperiment;
