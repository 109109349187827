import React from 'react';
import { HStack, Box } from '@chakra-ui/react';
import DisplayInputWrapperWithRightButton from '../DisplayInputWrapperWithRightButton';
import Placeholder from '../Placeholders/Placeholder';
import DisplayInputShowingSelectedValueNames from './DisplayInputShowingSelectedValueNames';

type DisplayInputDefaultWithRightBtnWrapperProps = {
  selectedOptions: Array<Object>,
  handleContentRendererClick: Function,
  placeholder: string,
  title: string,
  handleClearClick: Function,
  showValueByFields: Array<string>,
  hideInputClearBtn?: boolean,
  classNames: Array<string>,
  children: React.Node,
};

const DisplayInputDefaultWithRightBtnWrapper = ({
  selectedOptions,
  handleContentRendererClick,
  placeholder,
  title,
  handleClearClick,
  showValueByFields,
  hideInputClearBtn,
  classNames,
  children,
}: DisplayInputDefaultWithRightBtnWrapperProps) => {
  return (
    <DisplayInputWrapperWithRightButton
      key="customOption"
      selectedOptions={selectedOptions}
      handleContentRendererClick={handleContentRendererClick}
      handleClearClick={handleClearClick}
      showValueByFields={showValueByFields}
      hideInputClearBtn={hideInputClearBtn}
      classNames={classNames}
    >
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder placeholder={placeholder} title={title}>
          {children}
        </Placeholder>
      ) : (
        <HStack color="brand.neutral.500" overflow="hidden">
          {children && <Box>{children}</Box>}
          <DisplayInputShowingSelectedValueNames
            selectedOptions={selectedOptions}
            showValueByFields={showValueByFields}
          />
        </HStack>
      )}
    </DisplayInputWrapperWithRightButton>
  );
};

DisplayInputDefaultWithRightBtnWrapper.defaultProps = {
  hideInputClearBtn: false,
};

export default DisplayInputDefaultWithRightBtnWrapper;
