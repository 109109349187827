import React, { useContext } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { USER_PROFILE } from '@routes';
import { UPDATE_USER_METADATA } from '@core/components/UserProfilePage/queries';
import UserContext from '@contexts/UserContext';
import getBreakPoints from '@core/utils/getBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import { BaselaneBannerNew } from '@shared/components';
import { relativePositionStyling } from '@shared/styles/banner.style';

type ACHFeePromotionBannerProps = {
  userAchFeePromoEndDate: string,
};

const ACHFeePromotionBanner = ({ userAchFeePromoEndDate }: ACHFeePromotionBannerProps) => {
  const navigate = useNavigate();
  const { isMin768: isDesktop } = getBreakPoints();

  const { user } = useContext(UserContext);
  const [updateUser] = useMutation(UPDATE_USER_METADATA);

  const title = 'Fee for tenant ACH payments';
  const body =
    'A $2 fee will be charged to tenant ACH payments collected into an external bank account. The fee will be waived if collected into Baselane Banking.';
  const btnLabel = 'Review setting';

  const onButtonClick = () => {
    navigate({
      pathname: USER_PROFILE,
      search: createSearchParams({ activeTabIndex: 3 }).toString(),
    });
  };

  const onCloseClick = () => {
    updateUser({
      variables: { metadata: { ...user?.metadata, hideACHFeePromotionBanner: true } },
      update: (cache, { data: { updateUser: updatedUser } }) => {
        cache.modify({
          id: cache.identify(updatedUser),
          fields: {
            metadata: () => updatedUser?.metadata,
          },
        });
      },
    })
      .then(() => {})
      .catch((err) => console.debug(err));
  };

  return (
    <BaselaneBannerNew
      hasChip
      chipLabel={`Beginning ${formatDate(userAchFeePromoEndDate, 'MMM D, YYYY')}`}
      title={title}
      body={body}
      btnLabel={btnLabel}
      onButtonClick={onButtonClick}
      onCloseClick={onCloseClick}
      styles={relativePositionStyling(isDesktop)}
      isVertical={!isDesktop}
    />
  );
};

export default ACHFeePromotionBanner;
