// BaseLane: Styles for Properties dashboard widget elements
export const propertiesWidget = (isMobile) => ({
  pt: !isMobile ? '36px' : '4px',
  pb: !isMobile ? 0 : '16px',
  borderBottomLeftRadius: !isMobile ? '0' : '8px',
  borderBottomRightRadius: !isMobile ? '0' : '8px',
  width: isMobile ? '100%' : 'auto',
});

export const dashboardCurrencyTextStyle = (isMobile) => ({
  fontSize: !isMobile ? 'sm' : 'xs',
  fontWeight: 'semibold',
  lineHeight: !isMobile ? '24px' : '20px',
  color: 'brand.neutral.700',
  margin: !isMobile ? '0' : '0 auto 0 0',
});

export const currencyTextStyle = (isMobile) => ({
  ...dashboardCurrencyTextStyle(isMobile),
  isRounded: true,
  negativeColor: 'red.500A',
});

export const containerStyles = (isMobile) => ({
  px: !isMobile ? '24px' : '16px',
});

export const listItemStyles = {
  _notFirst: {
    mt: '16px !important',
  },
};

const mobileFirstListItemStyle = {
  pl: '16px',
  position: 'relative',
  top: '-4px',
};

export const firstItemStyles = (isMobile) => {
  return isMobile ? { ...mobileFirstListItemStyle } : { pl: '24px' };
};

export const customFontStyles = {
  textStyle: 'xs',
};

export const customContentStyles = (isMobile) => ({
  textStyle: isMobile ? 'headline-md' : 'headline-xl',
});

export const customBorderStyles = (isMobile) => ({
  marginTop: !isMobile ? '0px' : '4px !important',
  borderLeftWidth: !isMobile ? '1px' : '0px',
});

export const rentCollectionSummaryBoxStyle = (isMobile) => ({
  pt: !isMobile ? '36px' : '8px',
});

export const titleStyles = (isMobile) => ({
  color: !isMobile ? 'brand.neutral.700' : 'brand.neutral.600',
  fontSize: !isMobile ? 'sm' : 'xs',
  fontWeight: 'normal',
  pl: !isMobile ? '24px' : '16px',
  mb: !isMobile ? '16px' : '0px',
});

export const progressBarContainerStyles = (isMobile) => ({
  p: !isMobile ? '0 24px' : '0 16px',
  h: !isMobile ? '30px' : '16px',
  mb: !isMobile ? '28px' : '14px',
  height: '12px',
});

export const progressBarStyles = (isMobile) => ({
  height: !isMobile ? '32' : '16',
});

export const propertyLegendContainerStyles = (isLeasePage, isMobile) => ({
  justifyContent: isLeasePage
    ? {
        xl: 'flex-start',
        lg: 'flex-start',
        md: 'space-between',
        sm: 'space-between',
        xs: 'space-between',
      }
    : 'space-between',
  direction: !isMobile ? 'row' : 'column',
  marginTop: '12px',
});

export const propertyLegendItemStyles = (isMobile) => ({
  p: !isMobile ? '0 24px' : '0 16px',
  fontWeight: 'normal',
  direction: !isMobile ? 'column' : 'row',
  justifyContent: !isMobile ? 'space-around' : 'space-between',
});
