import React from 'react';
import {
  Box,
  Container,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  HStack,
  Spacer,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { Icon12Close } from '@icons/12px';
import BaselaneButton from '../BaselaneButton';
import BaselaneChip from '../BaselaneChip';
import { BaselaneButtonIcon } from '../BaselaneButtonIcon';
import { getDynamicIcon, getDynamicChipVariant } from './helpers/baselaneBanner.helpers';
import { baselaneBannerStyles } from './styles/baselaneBanner.styles';

type BaselaneBannerNewProps = () => {
  title: string | ReactElement,
  body: any,
  variant?: string,
  btnLabel?: string,
  isVertical?: boolean,
  bannerIconName?: React.Node,
  hasBannerIcon?: boolean,
  hasActionButton?: boolean,
  hasCloseButton?: boolean,
  hasChip?: boolean,
  chipLabel?: string,
  onButtonClick?: Function,
  onCloseClick?: Function,
  styles?: Object,
};

const BaselaneBannerNew = ({
  title,
  body,
  variant = 'primary-medium',
  btnLabel = 'Secondary',
  isVertical = false,
  bannerIconName = null,
  hasBannerIcon = true,
  hasActionButton = true,
  hasCloseButton = true,
  hasChip = false,
  chipLabel = 'Chip',
  onButtonClick = () => {},
  onCloseClick = null,
  styles = {},
}: BaselaneBannerNewProps) => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });
  const dynamicIconName = bannerIconName ?? getDynamicIcon({ variant });
  const chipVariant = getDynamicChipVariant({ variant });

  const {
    bannercontainer,
    innercontainer,
    textcontainer,
    iconcontainer,
    icon,
    chip,
    actionbtn,
    closebtn,
  } = baselaneBannerStyles({
    variant,
    isVertical,
  });

  return (
    isVisible && (
      <Container {...{ ...bannercontainer, ...styles?.container }}>
        <Alert {...{ variant: `banner-${variant}` }}>
          <HStack {...innercontainer}>
            {hasBannerIcon && <AlertIcon as={dynamicIconName} {...icon} />}
            <Stack {...textcontainer}>
              <Stack gap={0}>
                <Box as="span">
                  <AlertTitle as="span" mr={hasChip ? 0.75 : 0}>
                    {title}
                  </AlertTitle>

                  {hasChip && (
                    <Box as="span" {...iconcontainer}>
                      <BaselaneChip label={chipLabel} variant={chipVariant} {...chip} />
                    </Box>
                  )}
                </Box>
                <AlertDescription>{body}</AlertDescription>
              </Stack>

              {!isVertical && <Spacer />}
              {hasActionButton && (
                <BaselaneButton {...actionbtn} onClick={onButtonClick}>
                  {btnLabel}
                </BaselaneButton>
              )}
            </Stack>
          </HStack>

          {hasCloseButton && (
            <BaselaneButtonIcon
              icon={<Icon12Close color="currentColor" />}
              onClick={() => {
                onClose();
                if (onCloseClick) {
                  onCloseClick();
                }
              }}
              {...closebtn}
            />
          )}
        </Alert>
      </Container>
    )
  );
};

export default BaselaneBannerNew;
