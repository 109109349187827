import React, { ReactElement } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import BaselaneIconLogo from '@core/assets/BaselaneIconLogo';

const HeaderContentIconWrapper = ({
  children = null,
  ...rest
}: {
  children: ReactElement | null,
}) => {
  return (
    <HStack spacing={3} {...rest}>
      <BaselaneIconLogo />
      {children && <Box>{children}</Box>}
    </HStack>
  );
};

export default HeaderContentIconWrapper;
