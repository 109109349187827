import React from 'react';
import { FormErrorMessage, Box } from '@chakra-ui/react';
import { Icon12Error } from '@icons/12px/index';

function BaselaneFormErrorMessage({ children, ...props }: any) {
  return (
    <FormErrorMessage {...props}>
      <Box as="span" mr={0.5}>
        <Icon12Error />
      </Box>
      {children}
    </FormErrorMessage>
  );
}

export default BaselaneFormErrorMessage;
