import React, { useContext } from 'react';
import { DisplayInputWrapperWithRightButton } from '@shared/components';
import transactionContext from '@contexts/TransactionContext';
import DisplayInputTrxCategoryItemContent from './DisplayInputTrxCategoryItemContent';

type DisplayInputTrxCategoryItemProps = {
  selectedOptions: Array<Object>,
  handleContentRendererClick: Function,
  placeholder: string,
  title: string,
  handleClearClick: Function,
  classNames: Array<string>,
};

const DisplayInputTrxCategoryItem = ({
  handleContentRendererClick,
  selectedOptions,
  placeholder,
  title,
  handleClearClick,
  classNames,
}: DisplayInputTrxCategoryItemProps) => {
  const { categoryMap } = useContext(transactionContext);

  return (
    <DisplayInputWrapperWithRightButton
      handleContentRendererClick={handleContentRendererClick}
      handleClearClick={handleClearClick}
      selectedOptions={selectedOptions}
      classNames={classNames}
    >
      <DisplayInputTrxCategoryItemContent
        placeholder={placeholder}
        title={title}
        selectedOptions={selectedOptions}
        categoryMap={categoryMap}
      />
    </DisplayInputWrapperWithRightButton>
  );
};

export default DisplayInputTrxCategoryItem;
