import React from 'react';
import { Box } from '@chakra-ui/react';

import {
  BaselaneResponsiveAccountDeniedBanner,
  BaselaneResponsiveAccountDraftBanner,
  BaselaneResponsiveAccountInProgressBanner,
  BaselaneResponsiveAccountAwaitingDocumentsBanner,
} from '@shared/components';

import { bannerContainerStyles } from '../styles/accountsApplicationBanner.style';

type AccountApplicationBannerseProps = {
  /**
   * @prop {Array} - An array of Baselane applications.
   */
  incompleteApplications: Array,
  /**
   * @prop {Function} - Function that reloads application banner data after a delete
   */
  refetchBankList: Function,
};

/**
 * Renders a set of Baselane account application banners and associated graphics.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const AccountApplicationBanners = ({
  incompleteApplications,
  refetchBankList,
}: AccountApplicationBannerseProps) => {
  if (incompleteApplications?.length < 1) return null;
  return (
    <Box id="accountApplicationBanners" {...bannerContainerStyles}>
      {incompleteApplications.map((incompleteApplication) => {
        const { unitApplicationStatus: status } = incompleteApplication;
        switch (status) {
          case 'DENIED':
            return (
              <BaselaneResponsiveAccountDeniedBanner
                key={`incomplete-application-${incompleteApplication?.id}`}
                unitApplication={incompleteApplication}
                refetchBankList={refetchBankList}
              />
            );
          case 'DRAFT':
            return (
              <BaselaneResponsiveAccountDraftBanner
                key={`incomplete-application-${incompleteApplication?.id}`}
                unitApplication={incompleteApplication}
                refetchBankList={refetchBankList}
              />
            );
          case 'IN_PROGRESS':
            return (
              <BaselaneResponsiveAccountInProgressBanner
                key={`incomplete-application-${incompleteApplication?.id}`}
                unitApplication={incompleteApplication}
              />
            );
          case 'AWAITING_DOCUMENTS':
            return (
              <BaselaneResponsiveAccountAwaitingDocumentsBanner
                key={`incomplete-application-${incompleteApplication?.id}`}
                unitApplication={incompleteApplication}
                refetchBankList={refetchBankList}
              />
            );
          default:
            return null;
        }
      })}
    </Box>
  );
};

export default AccountApplicationBanners;
