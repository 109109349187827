import React from 'react';
import { Navigate } from 'react-router-dom';
import { Box, ChakraProvider, Flex, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import { HOME } from '@routes';
import { BaselaneCardNew } from '@shared/components';
import { Icon24Mobile } from '@icons/24px';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import SlLoader from '@core/components/Loader';
import useUserSignUpExperiment from '@core/hooks/useUserSignUpExperiment';
import getBreakPoints from '@core/utils/getBreakPoints';
import habitatTheme from '@core/themeHabitat';

import PhoneForm from './PhoneForm';
import {
  alternatePhoneContainerStyles,
  alternatePhoneContentContainerStyles,
  headingTextStyles,
  phoneTextStyles,
} from './alternatePhone.styles';

/**
 * 2024-07_sign_up_page_experiment
 * alternate phone page for the a/b test
 */
const AlternatePhonePage = () => {
  const { requiresPhoneNumber, loading } = useUserSignUpExperiment();

  const { isMax767 } = getBreakPoints();

  if (loading) return <SlLoader />;
  // users that already have a phone number or aren't part of the a/b test should be redirected away
  if (!loading && !requiresPhoneNumber) return <Navigate to={HOME} />;

  return (
    <ChakraProvider theme={habitatTheme}>
      <Flex {...alternatePhoneContainerStyles}>
        <Stack {...alternatePhoneContentContainerStyles(isMax767)}>
          <Box w="100%" color="brand.neutral.800A">
            <BaselaneFullLogo />
          </Box>
        </Stack>
        <VStack alignItems="flex-start" px="20px" gap="24px">
          <BaselaneCardNew size="lg" width={{ xxs: '100%', md: '648px', base: '100%' }}>
            <Flex direction={isMax767 ? 'column' : 'row'}>
              <Box mr="24px" my="4px">
                <Icon24Mobile />
              </Box>
              <VStack
                alignItems="start"
                pr={isMax767 ? '0' : '8px'}
                pb={isMax767 ? '0' : '8px'}
                w="100%"
                gap="0"
              >
                <Heading as="h1" {...headingTextStyles}>
                  Alternate phone number required
                </Heading>
                <Text {...phoneTextStyles}>
                  The number you entered earlier has been saved with your bank account, but a
                  different number is needed for verifying your logins to Baselane.
                </Text>
                <PhoneForm />
              </VStack>
            </Flex>
          </BaselaneCardNew>
        </VStack>
      </Flex>
    </ChakraProvider>
  );
};

export default AlternatePhonePage;
