import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
// referenced in multiple places

export const inputContainerStyles = {
  w: '100%',
  mt: '12px !important',
  _first: {
    mt: '0px !important',
  },
  mb: '0px',
};

export const inputStyles = {
  h: '48px',
  borderRadius: '4px',
  fontSize: 'sm',
  color: 'brand.neutral.900',
};

// referenced only in CreateAccountForm

export const formlabelCustomized = {
  ...formLabelStyles.xs,
  mt: '5px',
  mb: '3px',
};

export const formErrorStylesCustomized = {
  ...formErrorStyles,
  ml: '0px',
};

export const formInputCustomized = {
  ...formInputStyles,
  _focus: {
    borderColor: 'brand.darkBlue.900',
    color: 'brand.darkBlue.900',
    boxShadow: 'inset 0px 0px 1px 1px #e5f0ff',
  },
};

export const firstLastNameContainerStyle = (signUpVariant) => ({
  w: '100%',
  flexDirection: { base: signUpVariant === 'C' ? 'row' : 'column', md: 'row', xl: 'column' },
});
