import React from 'react';
import { Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';

export const getFormattedCurrency = (value, isExceeded) => {
  const { isNegative, inDollars } = formatCurrency(value);
  return <Text {...(isNegative || (isExceeded && { color: 'red.800AA' }))}>{inDollars}</Text>;
};

export const getFormattedLimit = (value, isMonthlyLimit, isRed) => {
  const { inDollars } = formatCurrency(value);
  const dailyLimit = <Text color={isRed ? 'red.800AA' : 'brand.neutral.600'}>{inDollars}/day</Text>;
  const monthlyLimit = (
    <Text color={isRed ? 'red.800AA' : 'brand.neutral.600'}>{inDollars}/month</Text>
  );
  return isMonthlyLimit ? monthlyLimit : dailyLimit;
};

export const getCurrencyDecimalSeparated = (amount, size) => {
  const { inDollars } = formatCurrency(amount, true);
  const [beforeDecimal, afterDecimal] = inDollars.split('.');

  return (
    <>
      {/* <Text as="span" fontSize={size === 1 ? 'calc(100% - 6px)' : 'calc(100% - 2px)'}>
        $
      </Text> */}
      <Text as="span">{beforeDecimal}.</Text>
      <Text as="span" fontSize={size === 1 ? 'calc(100% - 6px)' : 'calc(100% - 2px)'}>
        {afterDecimal}
      </Text>
    </>
  );
};
