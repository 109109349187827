export const infoStyles = ({ isMinXL }) => {
  return {
    container: {
      mb: isMinXL ? '32px' : '40px',
      flexDir: isMinXL ? 'column-reverse' : 'row',
      alignItems: isMinXL ? 'flex-start' : 'center',
    },
    title: {
      textStyle: 'headline-lg',
      color: 'brand.neutral.700',
      minH: isMinXL ? '26px' : '40px',
      alignItems: 'center',
      display: 'flex',
    },
    description: {
      textStyle: 'xs',
      color: 'brand.neutral.600',
    },
    iconcontainer: {
      w: '88px',
      h: '88px',
      ml: isMinXL ? '0 !important' : '32px !important',
      mb: isMinXL ? '16px !important' : '0 !important',
    },
  };
};
