import React from 'react';

function IconCheckCircleOutline({
  color = 'currentColor',
  w = 20,
  h = 20,
  viewBox = '0 0 20 20',
}: {
  color?: string,
  w?: number,
  h?: number,
  viewBox?: string,
}) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height={`${h}px`}
      width={`${w}px`}
      viewBox={viewBox}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a8 8 0 100 16 8 8 0 000-16zm-7.071.929A10 10 0 1117.07 17.07 10 10 0 012.93 2.93zm10.778 4.364a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L9 10.586l3.293-3.293a1 1 0 011.414 0z"
        fill={color}
      />
    </svg>
  );
}

IconCheckCircleOutline.defaultProps = {
  color: 'currentColor',
  w: 20,
  h: 20,
  viewBox: '0 0 20 20',
};

export default IconCheckCircleOutline;
