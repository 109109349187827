import React, { useContext } from 'react';
import { Flex, Heading } from '@chakra-ui/react';

import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import HeaderContentIconWrapper from '@shared/layouts/HeaderNavWrapper/HeaderContentIconWrapper';
import { BaselaneHorizontalStepper } from '@shared/components';
import userContext from '@contexts/UserContext';
import getBreakPoints from '@core/utils/getBreakPoints';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';

import useOnboardingStore from '@store/Onboarding';
import TriageContent from './TriageContent';
import { cardContainerWrapperStyles } from '../styles/onboardingTriage.styles';

const OnboardingTriageContentView = () => {
  const { selection: onboardingTriageSelection } = useOnboardingStore();

  const { user, skippedBankingFirst } = useContext(userContext);
  const { isMin768 } = getBreakPoints();

  const { bankingTracker } = user?.userMetadata || {};
  const isBookkeepingSelection = onboardingTriageSelection === 'bookkeeping';
  const steps = [{ title: 'Banking' }, { title: 'Bookkeeping' }];

  const isBankingFinished = (bankingTracker || [])[0].state === 'COMPLETED';
  const activeStep = skippedBankingFirst || isBankingFinished ? 1 : 0;

  return (
    <HeaderNavWrapper
      hideBackButton
      hideFinishLater
      headerContent={
        isBookkeepingSelection ? (
          <>
            {isMin768 && (
              <HeaderContentIconWrapper flex={1} spacing={3}>
                <Heading size="headline-lg">Let&apos;s set up your account</Heading>
              </HeaderContentIconWrapper>
            )}
            <BaselaneHorizontalStepper
              steps={steps}
              activeStep={activeStep}
              isActiveStep={(index) => index === activeStep}
              isCompleteStep={(index) => {
                if (skippedBankingFirst && !isBankingFinished) {
                  return false;
                }
                return index < activeStep;
              }}
              overflowX="initial"
            />
          </>
        ) : (
          <BaselaneFullLogo />
        )
      }
    >
      {/* content */}
      <Flex direction="row" height="100%" {...cardContainerWrapperStyles}>
        <TriageContent />
      </Flex>
    </HeaderNavWrapper>
  );
};

export default OnboardingTriageContentView;
