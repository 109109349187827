import React from 'react';
import { BaselaneButtonIcon } from '@shared/components';
import { Icon16Close } from '@icons/16px';

type CancelEditBtnProps = {
  onClick: Function,
  isDisabled?: boolean,
  variant: string,
  palette: string,
  size: string,
};

const CancelEditBtn = ({ onClick, isDisabled, variant, palette, size }: CancelEditBtnProps) => {
  return (
    <BaselaneButtonIcon
      isDisabled={isDisabled}
      icon={<Icon16Close />}
      variant={variant}
      palette={palette}
      size={size}
      onClick={onClick}
    />
  );
};

CancelEditBtn.defaultProps = {
  isDisabled: false,
};

export default CancelEditBtn;
