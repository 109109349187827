// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogLogs } from '@datadog/browser-logs';

export function initializeDatadogLogs() {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    forwardErrorsToLogs: true,
    env: process.env.REACT_APP_NODE_ENV,
    service: 'portal',
  });
}

const originalConsoleLog = console.log;
console.log = (...params) => {
  datadogLogs.logger.log(params?.[0], { params }, 'info');
  originalConsoleLog(...params);
};

const originalConsoleError = console.error;
console.error = (...params) => {
  datadogLogs.logger.error(params?.[0], { params });
  originalConsoleError(...params);
};
