// @flow
import React, { useEffect } from 'react';
import { Stack, Text } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { IconInvoiceSubmitted } from '@icons';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import {
  msgWrapper,
  bodyTextStyles,
  title2TextStyles,
  titleTextStyles,
  body2TextStyles,
} from '../AddFundsDrawer/styles/reviewTransfer.styles';

function TransferConfirmation(): any {
  useEffect(() => {
    sendSegmentEvent('baselane_banking_add_ach_success');
  }, []);

  const { isMinXL } = getBreakPoints();
  return (
    <Stack {...msgWrapper} spacing="12px">
      <IconInvoiceSubmitted />
      <Stack>
        <Text {...(isMinXL ? titleTextStyles : title2TextStyles)}>
          Your transfer has been scheduled
        </Text>
        <Text {...(isMinXL ? bodyTextStyles : body2TextStyles)}>
          You can view the status of this transfer on your Transfers & Payments page.
        </Text>
      </Stack>
    </Stack>
  );
}

export default TransferConfirmation;
