// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=2925%3A97142
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import { BaselaneButton, BaselaneGrid } from '@shared/components';
import { ADD_BASELANE_BANK_ACCOUNT } from '@routes';
import {
  emptyStateCardStyles,
  sectionTitleStyles,
  buttonContainerStyles,
  noMarginStyles,
  darkDescriptionTextStyles,
  mobileEmptyStateHeaderStyles,
} from '@core/components/NativeBankingPage/components/styles/emptyState.shared.styles';
import EmptyStateFeatureList from '@core/components/NativeBankingPage/components/EmptyStateFeatureList';
import BaselaneIconLogo from '@core/assets/BaselaneIconLogo';

import { leftSectionStyles, mobileEmptyStateContainerStyles } from '../styles/emptyState.styles';
import MobileEmptyStateWarningBanner from './MobileEmptyStateWarningBanner';

type EmptyStateProps = {
  skipTitle: boolean,
  isGetStarted: boolean,
};

function EmptyState({ skipTitle, isGetStarted }: EmptyStateProps): any {
  const navigate = useNavigate();
  const currLocation = useLocation();

  const gridItems = [
    {
      element: (
        <Stack {...leftSectionStyles}>
          <Flex direction={isMobile ? 'row' : 'column'} alignItems="center" color="brand.blue.800A">
            <BaselaneIconLogo />
            <Text
              {...sectionTitleStyles}
              my={isMobile ? '8px 0' : '18px !important'}
              pl={isMobile ? '8px' : ''}
            >
              {isMobile ? 'BASELANE ACCOUNT' : 'Open Your First Baselane Account'}
            </Text>
          </Flex>
          <Text {...darkDescriptionTextStyles} {...noMarginStyles}>
            Open banking accounts built for landlords in minutes, included at no additional cost.
          </Text>
        </Stack>
      ),
    },
    {
      element: isMobile ? <MobileEmptyStateWarningBanner isMobile /> : <EmptyStateFeatureList />,
    },
    {
      element: (
        <Box {...buttonContainerStyles}>
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size={isGetStarted ? 'lg' : 'sm'}
            styles={{ ml: !isMobile ? '16px' : '0', w: !isMobile ? null : '100%' }}
            onClick={() =>
              navigate({
                pathname: `${currLocation.pathname}/${ADD_BASELANE_BANK_ACCOUNT}`,
              })
            }
          >
            Add Bank Account
          </BaselaneButton>
        </Box>
      ),
    },
  ];
  const emptyStateHeaderStyles = isMobile ? mobileEmptyStateHeaderStyles : sectionTitleStyles;
  const mainContent = (
    <BaselaneGrid
      gridItems={gridItems}
      templateColumns={!isMobile ? 'repeat(1, 30% minmax(0, 1fr)) min-content' : 'repeat(1, auto)'}
      templateRows={!isMobile ? 'repeat(1, 1fr)' : 'repeat(3, auto)'}
      gridStyles={{ ...emptyStateCardStyles }}
      gridItemStyles={{ h: '100%' }}
    />
  );
  return (
    <>
      {!skipTitle && <Text {...emptyStateHeaderStyles}>Baselane Accounts</Text>}
      {isMobile ? <Box {...mobileEmptyStateContainerStyles}>{mainContent}</Box> : mainContent}
    </>
  );
}

export default EmptyState;
