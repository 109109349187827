import { cloneDeep, groupBy, orderBy } from 'lodash';
import { oppositeSortMapper } from '@core/utils/sort';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { readQuery, writeQuery, cacheEvict as evict } from '@shared/apollo/cacheFunctions';
import { GET_TRANSACTIONS } from '@core/apollo/queries';

/**
 * Helper Functions to help update cache
 * */

const getIndexes = (splitTransactions, clonedTrxs) =>
  splitTransactions.map((splitTx) => clonedTrxs.findIndex((tx) => tx.id === splitTx.id)).sort();

const getIndex = (clonedTrxs, id) => clonedTrxs.findIndex((tx) => tx.id === id);

const removeSplitTrx = (splitTransactions, clonedTrxs) =>
  splitTransactions.forEach((t) => {
    const txIndex = getIndex(clonedTrxs, t.id);
    if (txIndex !== -1) clonedTrxs.splice(txIndex, 1);
  });

export const sortAndOrderTransactions = (clonedTrxs, filters) => {
  const { direction = '', field } = filters.sort ?? {};

  const { true: pendingTransactions, false: nonPendingTransactions } = groupBy(
    clonedTrxs,
    'pending'
  );

  const orderedPendingTransactions = orderBy(
    pendingTransactions,
    [field],
    [direction.toLowerCase(), oppositeSortMapper[direction.toLowerCase()]]
  );

  const orderedNonPendingTransactions = orderBy(
    nonPendingTransactions,
    [field],
    [direction.toLowerCase(), oppositeSortMapper[direction.toLowerCase()]]
  );

  return [...orderedPendingTransactions, ...orderedNonPendingTransactions];
};

// apollo cache functions to get and update cache
const getTransactionsFromCache = (cache, filters) =>
  readQuery(cache, {
    query: GET_TRANSACTIONS,
    variables: { input: { ...filters } },
  });

const editTransactionInCache = (cache, filters, transaction) =>
  writeQuery(cache, {
    query: GET_TRANSACTIONS,
    variables: { input: { ...filters } },
    data: {
      transaction,
    },
  });

/**
 * This eviction tells the cache that the existing Query.transaction data can be safely ignored,
 * because you're handling its transformation yourself. If you do not evict the field, the cache has no way
 * of knowing the new data you are writing is a filtered version of the existing data, so it warns about potential
 * loss of the existing data.
 * https://github.com/apollographql/apollo-client/issues/6451
 * */
const cacheEvict = (cache) =>
  evict(cache, {
    fieldName: 'transaction',
  });

/**
 * Functions to update cache
 * */
export const updateCacheAfterMerge = ({ cache, parentTrx, splitTransactions, filters, user }) => {
  const cachedTransactions = getTransactionsFromCache(cache, filters);

  const clonedTrxs = cloneDeep(cachedTransactions?.transaction || []);

  // get list of indexes of split transaction and sort it
  const splitTrxIndexes = getIndexes(splitTransactions, clonedTrxs);

  if (splitTrxIndexes !== '') {
    // delete split trx separately because there could be normal tx in between
    removeSplitTrx(splitTransactions, clonedTrxs);
    // add parent back into list
    clonedTrxs.splice(splitTrxIndexes[0], 0, parentTrx);
  } else {
    console.log('was not able to find split transaction');
  }

  // remove items from end of list if it is greater than the page limit trx
  const orderedTransactions = sortAndOrderTransactions(clonedTrxs, filters);

  cacheEvict(cache);

  editTransactionInCache(cache, filters, orderedTransactions);

  sendSegmentEvent('split_transaction_merge', { landlord_id: user.id });
};

export const updateCacheAfterCreateOrUpdate = ({
  cache,
  splitTrx,
  splitTransactions,
  deletedSplitTransactions,
  filters,
  user,
  isEdit,
}) => {
  let splitTrxData = cloneDeep(splitTrx.splitTransactions);

  const cachedTransactions = getTransactionsFromCache(cache, filters);

  const clonedTrxs = cloneDeep(cachedTransactions?.transaction || []);

  // check if all split transactions exists
  splitTrxData = splitTrxData.map((splitTx) => {
    const trxExists = clonedTrxs.some((tx) => tx.id === splitTx.id);
    return { ...splitTx, trxExists };
  });
  const notAllSplitTrxExists = splitTrxData.some((tx) => !tx.trxExists);
  const deletedSplitTrxExists = deletedSplitTransactions.length > 0;

  if (notAllSplitTrxExists || deletedSplitTrxExists) {
    let newTrxs;
    const parentId = splitTrx.id;
    // remove parent trx from cache
    const parentTrxIndex = getIndex(clonedTrxs, parentId);
    if (parentTrxIndex !== -1) {
      clonedTrxs.splice(parentTrxIndex, 1);
      // add split trx to the cache
      newTrxs = [...splitTrx.splitTransactions, ...clonedTrxs];
    } else {
      // get list of indexes of split transaction and sort it
      const allSplitTransactions = splitTransactions.concat(deletedSplitTransactions);
      const splitTrxIndex = getIndexes(allSplitTransactions, clonedTrxs);

      // delete split trx separately because there could be normal tx in between
      removeSplitTrx(allSplitTransactions, clonedTrxs);

      // add split trx in
      clonedTrxs.splice(splitTrxIndex[0], 0, ...splitTrx.splitTransactions);
      newTrxs = clonedTrxs;
    }

    // remove items from end of list if it is greater than the page limit trx
    const orderedTransactions = sortAndOrderTransactions(newTrxs, filters);

    if (deletedSplitTrxExists) {
      cacheEvict(cache);
    }

    editTransactionInCache(cache, filters, orderedTransactions);
  }

  const eventName = isEdit ? 'split_transaction_edit' : 'split_transaction_create';
  sendSegmentEvent(eventName, { landlord_id: user.id });
};
