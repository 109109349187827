export const mainContainerStyles = (isMobile) => {
  return {
    minW: isMobile ? '100%' : { lg: 'auto', base: '819px' },
    maxW: !isMobile && '986px',
    p: isMobile ? '16px 0 0' : { lg: '24px 0 !important', base: '24px 16px !important' },
  };
};

export const titleContainerStyles = (isMobile) => {
  return {
    px: isMobile && '16px',
  };
};

export const titleStyles = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
};

export const subTitleContainerStyles = (isMobile) => {
  return {
    mb: !isMobile && '44px',
  };
};

export const subTitleStyles = (isMobile) => {
  return {
    fontSize: isMobile ? 'sm' : '2xl',
    lineHeight: isMobile ? '24px' : '20px',
    fontWeight: isMobile ? 'normal' : 'semibold',
  };
};

export const trackersContainerStyles = (isMobile) => {
  return {
    alignItems: !isMobile && 'stretch',
    flexDir: isMobile ? 'column' : 'row',
    p: isMobile && '0 16px',
  };
};

export const twoColumnContainerStyles = (isMobile) => {
  return {
    w: '100%',
    gap: isMobile ? '24px' : '12px',
    flexFlow: isMobile && 'column-reverse',
  };
};
