export const cardStyles = (bannerBorder) => ({
  boxShadow: 'none',
  borderRadius: 'none',
  mb: '16px !important',
  borderColor: bannerBorder,
  borderWidth: '1px',
  h: 'auto',
});

export const iconBackgroundStyles = (iconBackgroundColor) => ({
  w: '56px',
  bg: iconBackgroundColor,
  justifyContent: 'center',
  alignItems: 'center',
});

export const contentContainerStyles = (bannerBackground) => ({
  p: '8px 15px',
  bg: bannerBackground,
  flex: '1 1 auto',
  justifyContent: 'space-between',
});

export const contentStyles = {
  textStyle: 'xs',
};

export const contentTitleStyles = (titleColor) => ({
  color: titleColor,
  textStyle: 'xs-heavy',
});

export const contentBodyStyles = {
  color: 'brand.neutral.700',
};
