import React from 'react';

type IconXOutlineProps = { color?: string, w?: number, h?: number };

function IconXOutline({ color, w, h }: IconXOutlineProps) {
  return (
    <svg
      height={`${h}px`}
      width={`${w}px`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 2.66732C6.58519 2.66732 5.22863 3.22922 4.22844 4.22941C3.22824 5.22961 2.66634 6.58616 2.66634 8.00065C2.66634 8.70103 2.80429 9.39456 3.07232 10.0416C3.34034 10.6887 3.73319 11.2766 4.22844 11.7719C4.72368 12.2671 5.31163 12.66 5.9587 12.928C6.60577 13.196 7.29929 13.334 7.99967 13.334C8.70006 13.334 9.39358 13.196 10.0407 12.928C10.6877 12.66 11.2757 12.2671 11.7709 11.7719C12.2662 11.2766 12.659 10.6887 12.927 10.0416C13.1951 9.39456 13.333 8.70103 13.333 8.00065C13.333 6.58616 12.7711 5.22961 11.7709 4.22941C10.7707 3.22922 9.41416 2.66732 7.99967 2.66732ZM3.28563 3.28661C4.53587 2.03636 6.23156 1.33398 7.99967 1.33398C9.76779 1.33398 11.4635 2.03636 12.7137 3.28661C13.964 4.53685 14.6663 6.23254 14.6663 8.00065C14.6663 8.87613 14.4939 9.74304 14.1589 10.5519C13.8238 11.3607 13.3328 12.0956 12.7137 12.7147C12.0947 13.3338 11.3597 13.8248 10.5509 14.1598C9.74206 14.4949 8.87515 14.6673 7.99967 14.6673C7.1242 14.6673 6.25729 14.4949 5.44845 14.1598C4.63961 13.8248 3.90469 13.3338 3.28563 12.7147C2.66657 12.0956 2.17551 11.3607 1.84048 10.5519C1.50545 9.74304 1.33301 8.87613 1.33301 8.00065C1.33301 6.23254 2.03539 4.53685 3.28563 3.28661ZM6.19494 6.19591C6.45529 5.93556 6.8774 5.93556 7.13775 6.19591L7.99967 7.05784L8.8616 6.19591C9.12195 5.93556 9.54406 5.93556 9.80441 6.19591C10.0648 6.45626 10.0648 6.87837 9.80441 7.13872L8.94248 8.00065L9.80441 8.86258C10.0648 9.12293 10.0648 9.54504 9.80441 9.80539C9.54406 10.0657 9.12195 10.0657 8.8616 9.80539L7.99967 8.94346L7.13775 9.80539C6.8774 10.0657 6.45529 10.0657 6.19494 9.80539C5.93459 9.54504 5.93459 9.12293 6.19494 8.86258L7.05687 8.00065L6.19494 7.13872C5.93459 6.87837 5.93459 6.45626 6.19494 6.19591Z"
        fill={color}
      />
    </svg>
  );
}

IconXOutline.defaultProps = {
  color: 'currentColor',
  w: 16,
  h: 16,
};

export default IconXOutline;
