import React from 'react';

import SuccessWrapper from './SuccessWrapper';
import SuccessFlow from './SuccessFlow';

const UnifiedLandlordBankingSuccess = () => {
  return <SuccessWrapper ChildComponent={SuccessFlow} />;
};

export default UnifiedLandlordBankingSuccess;
