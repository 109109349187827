import { isMobile } from 'react-device-detect';

export const leaseTermsDirectionContainerStyles = {
  flexDirection: isMobile ? 'column' : 'row',
  spacing: isMobile ? 0 : '16px',
  p: isMobile ? '0' : '8px 0 0!important',
};

export const leaseTermsInputWidthContainer = {
  w: isMobile ? '100%' : '50%',
};

export const paymentsBox = {
  textStyle: 'xs',
  p: '16px 16px 6px',
  background: 'brand.blue.100',
  borderRadius: '4px',
};

export const paymentsBoxInApp = {
  textStyle: 'xs',
  p: '16px 16px 6px',
  mb: '32px',
  background: 'brand.blue.100',
  borderRadius: '4px',
};

export const verticalDashedLine = (isEdit) => ({
  w: '1px',
  h: isEdit ? '60px' : '36px',
  borderRight: 'dashed 1pt #ACB8C7',
  paddingLeft: '8.5px',
  marginRight: '12px',
  position: 'relative',
  top: isEdit ? '6px' : '-6px',
});

export const stepCircle = (filled) => ({
  w: '8px',
  h: '8px',
  border: 'solid 1pt',
  borderColor: 'brand.blue.700',
  background: filled ? 'brand.blue.700' : 'brand.neutral.white',
  borderRadius: '50%',
  m: '5px 9px 5px 5px',
});
