import { isMobile } from 'react-device-detect';

export const containerStyles = {
  spacing: 0,
  height: '100%',
  w: '100%',
  overflow: 'hidden',
};

export const leftUIContainerStyles = {
  height: isMobile ? '100%' : 'auto',
  align: 'center',
  justifyContent: 'center',
  spacing: 0,
  overflowY: isMobile ? 'hidden' : 'auto',
  flex: '1',
  sx: !isMobile
    ? {
        '@media screen and (max-height: 700px)': {
          justifyContent: 'flex-start',
        },
      }
    : {},
};

export const leftUIInnerContainerStyles = {
  position: 'relative',
  height: isMobile ? '100%' : 'auto',
  maxHeight: isMobile ? '100%' : 'none',
  w: isMobile ? '100%' : { xl: '52%', base: '100%' },
  px: isMobile ? '0px' : { xl: '0px', base: '32px' },
  justifyContent: 'space-between',
  spacing: '40px',
  sx: !isMobile
    ? {
        '@media screen and (max-height: 700px)': {
          height: 'auto',
        },
      }
    : {},
};

const mobileButtonContainerStyles = {
  position: 'absolute',
  bottom: '0',
  bg: 'brand.neutral.white',
  p: '16px',
  boxShadow: isMobile ? '-24px 0px 64px rgba(4, 39, 101, 0.08)' : 'none',
  zIndex: '1',
};

export const buttonContainerStyles = {
  spacing: 2,
  w: '100%',
  ...(isMobile ? mobileButtonContainerStyles : {}),
};

export const imageContainerStyles = {
  bgColor: 'brand.darkBlue.50',
  justifyContent: 'center',
  align: 'center',
  width: '55%',
  minHeight: '100%',
  px: '32px',
};

export const contentContainerStyles = (isBeforeYouBegin) => {
  const mobilePadding = isBeforeYouBegin ? '0 24px 24px 24px' : '0 16px 16px 16px';
  return {
    spacing: isMobile ? 3 : 4,
    p: isMobile ? mobilePadding : '0',
    mt: isMobile ? 2 : 0,
  };
};

export const contentWrapperContainerStyles = {
  direction: 'row',
  overflowY: isMobile ? 'auto' : 'initial',
  height: isMobile ? 'calc(100% - 80px)' : '100%',
};
