import * as React from 'react';
import { Text } from '@chakra-ui/react';
import { getPropertyAddressArr } from './helpers/baselanePropertyUnitAddress.helpers';
import { propertyUnitAdddressStyles } from './styles/baselanePropertyUnitAddress.styles';

type BaselanePropertyUnitAddressProps = {
  propertyName?: string,
  unitName?: string,
  propertyAddress: Object,
  tenantFullName?: string,
};

function BaselanePropertyUnitAddress({
  propertyName,
  unitName,
  propertyAddress,
  tenantFullName,
}: BaselanePropertyUnitAddressProps): any {
  const [propertyAddress1, propertyAddress2] = propertyAddress
    ? getPropertyAddressArr(propertyAddress)
    : [null, null];

  // Destructure Imported Styles
  const { propertyunit, address } = propertyUnitAdddressStyles ?? {};

  return (
    <>
      {propertyName && <Text {...propertyunit}>{propertyName}</Text>}
      {unitName && <Text {...propertyunit}>{unitName}</Text>}
      {propertyAddress1 && (
        <Text {...address} mt="6px !important">
          {propertyAddress1}
        </Text>
      )}
      {propertyAddress2 && <Text {...address}>{propertyAddress2}</Text>}
      {tenantFullName && <Text {...address}>{tenantFullName}</Text>}
    </>
  );
}

BaselanePropertyUnitAddress.defaultProps = {
  propertyName: null,
  unitName: null,
  tenantFullName: null,
};

export default BaselanePropertyUnitAddress;
