import React from 'react';

type IconCarretRightProps = {
  w: Number,
  h: Number,
  color: string,
};

function IconCarretRight({ w = 8, h = 13, color = '#3A4B5B' }: IconCarretRightProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999999 0.740234C0.579999 0.740234 0.249999 1.07023 0.25 1.49023L0.25 11.9902C0.25 12.4102 0.58 12.7402 1 12.7402C1.195 12.7402 1.36 12.6652 1.495 12.5452L7.495 7.29523C7.645 7.16023 7.75 6.96523 7.75 6.74023C7.75 6.51523 7.645 6.32023 7.495 6.18523L1.495 0.935234C1.36 0.815234 1.195 0.740234 0.999999 0.740234Z"
        fill={color}
      />
    </svg>
  );
}

export default IconCarretRight;
