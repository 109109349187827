import moment from 'moment';
import { formatDate } from '@core/utils/formatDate';
import { radioCardBoxStyles } from '../styles/datepickerDropdown.styles';

const lastYear = moment().subtract(1, 'year');

export const DATE_OPTIONS = [
  {
    label: 'This Month',
    value: 'this_month',
    from: new Date(moment().startOf('month').valueOf()),
    to: new Date(moment().valueOf()),
    radioCardBoxStyles,
  },
  {
    label: 'Last Month',
    value: 'last_month',
    from: new Date(moment().subtract(1, 'month').startOf('month').valueOf()),
    to: new Date(moment().subtract(1, 'month').endOf('month').valueOf()),
    radioCardBoxStyles,
  },
  {
    label: 'Last 6 Months',
    value: 'last_6_months',
    from: new Date(moment().subtract(5, 'months').startOf('month').valueOf()),
    to: new Date(moment().valueOf()),
    radioCardBoxStyles,
  },
  {
    label: 'Last 12 Months',
    value: 'last_12_months',
    from: new Date(moment().subtract(11, 'months').startOf('month').valueOf()),
    to: new Date(moment().valueOf()),
    radioCardBoxStyles,
  },
  {
    label: `${lastYear.format('YYYY')} (Jan-Dec)`,
    value: `${lastYear.format('YYYY')} (Jan-Dec)`,
    from: new Date(lastYear.startOf('year').valueOf()),
    to: new Date(lastYear.endOf('year').valueOf()),
    radioCardBoxStyles: { ...radioCardBoxStyles, mr: '0 !important' },
  },
  {
    label: 'Year To Date',
    value: 'year_to_date',
    from: new Date(moment().startOf('year').valueOf()),
    to: new Date(moment().valueOf()),
    radioCardBoxStyles: { ...radioCardBoxStyles, mr: '0 !important' },
  },
];

export const getCustomDateOption = (startDate, endDate) => ({
  label: `${formatDate(startDate)} - ${formatDate(endDate)}`,
  value: 'custom',
  from: startDate,
  to: endDate,
  radioCardBoxStyles,
});

export const isSameDay = (dateA: any, dateB: any) =>
  dateA.getDate() === dateB.getDate() &&
  dateA.getMonth() === dateB.getMonth() &&
  dateA.getFullYear() === dateB.getFullYear();

export const getOption = (from, to) => {
  return DATE_OPTIONS.find((option) => {
    return isSameDay(option.to, to) && isSameDay(option.from, from);
  });
};
