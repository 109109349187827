import React, { useEffect } from 'react';
import { Text, Box, Stack } from '@chakra-ui/react';

import { Outlet } from 'react-router-dom';

import { isMobile } from 'react-device-detect';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import * as ROUTES from '@routes';
import { BaselaneLink } from '@shared/components';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import { loginPageContainerStyles } from './styles/login.style';

import SignUpLoginButtons from './components/SignUpLoginButtons';
import LoginForm from './components/LoginForm';
import { headingStyles } from './styles/font.style';

function LoginPage() {
  const numbersToLogin = localStorage.getItem('redirectLogin');

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  useEffect(() => {
    zendeskAPI('messenger', 'show');

    const [performanceObject] = performance.getEntriesByType('navigation');
    localStorage.setItem('redirectLogin', '1');
    if (
      (performanceObject.type === 'reload' ||
        performanceObject.type === 'navigate' ||
        performanceObject.type === 'back_forward') &&
      numbersToLogin &&
      numbersToLogin === '2'
    ) {
      inActivityLogoutStorage.delete();
      localStorage.removeItem('redirectLogin');
    } else {
      localStorage.setItem('redirectLogin', '2');
    }
  }, []);

  return (
    <Stack {...loginPageContainerStyles}>
      <Stack alignItems={{ md: 'flex-start', base: 'center' }}>
        <BaselaneFullLogo />
      </Stack>
      <Box width="100%" mt={isMobile ? '0px' : '95px !important'}>
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
          <Text {...headingStyles} maxWidth="784px" mb="32px !important">
            Welcome to Baselane
          </Text>
          <Outlet />
          <SignUpLoginButtons isLogin isMobile={isMobile} />
          <LoginForm />
          <Stack direction="row" my="24px !important" justifyContent="center" textStyle="sm">
            <Text>
              Don&apos;t have an account? &nbsp;{' '}
              <BaselaneLink variant="primary" size="md" to={ROUTES.SIGN_UP}>
                Sign Up
              </BaselaneLink>
            </Text>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}

export default LoginPage;
