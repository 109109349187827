import React from 'react';
import { Spinner, Stack, Text } from '@chakra-ui/react';
import { loadingViewStyles } from './styles/loadingView.styles';

type LoadingViewProps = { loadingObj: Object };

const LoadingView = ({ loadingObj }: LoadingViewProps) => {
  const { container, innercontainer, spinner, caption } = loadingViewStyles ?? {};
  return (
    <Stack {...container}>
      <Stack {...innercontainer}>
        <Spinner {...spinner} />
        <Text {...caption}>{loadingObj.caption}</Text>
      </Stack>
    </Stack>
  );
};

export default LoadingView;
