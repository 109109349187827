import React from 'react';

type IconAddFundDollarSignProps = { w?: string, h?: string };

function IconAddFundDollarSign({ w, h }: IconAddFundDollarSignProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12592_291888)">
        <path
          d="M0.985868 6.73892C1.31317 6.85584 1.54193 7.17993 1.51698 7.5266C1.50572 7.68293 1.5 7.8408 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C7.8408 1.5 7.68293 1.50572 7.52659 1.51698C7.17993 1.54193 6.85584 1.31317 6.73892 0.985868C6.58926 0.566941 6.80807 0.0757263 7.25102 0.0345992C7.49761 0.0117044 7.74744 7.21462e-07 8 6.99382e-07C12.4183 3.13124e-07 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 1.08564e-06 12.4183 6.99382e-07 8C6.77302e-07 7.74744 0.0117041 7.49761 0.034599 7.25103C0.0757262 6.80808 0.566941 6.58927 0.985868 6.73892Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.75C8.41421 2.75 8.75 3.08579 8.75 3.5V3.7979C9.4034 3.88259 10.0066 4.07738 10.4992 4.36473C11.1574 4.74867 11.75 5.38926 11.75 6.25C11.75 6.66421 11.4142 7 11 7C10.5858 7 10.25 6.66421 10.25 6.25C10.25 6.14424 10.171 5.90983 9.74342 5.6604C9.33422 5.4217 8.71951 5.25 8 5.25C7.28049 5.25 6.66578 5.4217 6.25658 5.6604C5.82898 5.90983 5.75 6.14424 5.75 6.25C5.75 6.35576 5.82898 6.59017 6.25658 6.8396C6.66578 7.0783 7.28049 7.25 8 7.25C8.93735 7.25 9.82263 7.47005 10.4992 7.86473C11.1574 8.24867 11.75 8.88926 11.75 9.75C11.75 10.6107 11.1574 11.2513 10.4992 11.6353C10.0066 11.9226 9.4034 12.1174 8.75 12.2021V12.5C8.75 12.9142 8.41421 13.25 8 13.25C7.58579 13.25 7.25 12.9142 7.25 12.5V12.2021C6.5966 12.1174 5.99338 11.9226 5.50078 11.6353C4.8426 11.2513 4.25 10.6107 4.25 9.75C4.25 9.33579 4.58579 9 5 9C5.41421 9 5.75 9.33579 5.75 9.75C5.75 9.85576 5.82898 10.0902 6.25658 10.3396C6.66578 10.5783 7.28049 10.75 8 10.75C8.71951 10.75 9.33422 10.5783 9.74342 10.3396C10.171 10.0902 10.25 9.85576 10.25 9.75C10.25 9.64424 10.171 9.40983 9.74342 9.1604C9.33422 8.9217 8.71951 8.75 8 8.75C7.06265 8.75 6.17737 8.52995 5.50078 8.13527C4.8426 7.75133 4.25 7.11074 4.25 6.25C4.25 5.38926 4.8426 4.74867 5.50078 4.36473C5.99338 4.07738 6.5966 3.88259 7.25 3.7979V3.5C7.25 3.08579 7.58579 2.75 8 2.75Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L1.93934 3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H3.75C4.16421 4.5 4.5 4.16421 4.5 3.75V1C4.5 0.585786 4.16421 0.25 3.75 0.25C3.33579 0.25 3 0.585786 3 1V1.93934L1.28033 0.21967Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12592_291888">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconAddFundDollarSign.defaultProps = {
  w: '16',
  h: '16',
};

export default IconAddFundDollarSign;
