import React from 'react';

function IconCheckCircle({
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  color = '#E1F4FF',
}: {
  width: string,
  height: string,
  viewBox: string,
  color: string,
}) {
  return (
    <svg width={width} height={height} {...{ width, height, viewBox }} fill="none">
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
        fill={color}
      />
    </svg>
  );
}

export default IconCheckCircle;
