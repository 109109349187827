import { React, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Text, HStack, Box } from '@chakra-ui/react';
import { Icon16Edit } from '@icons/16px';
import { BaselaneButton, BaselaneCardNew } from '@shared/components';
import PaymentMethodForm from './PaymentMethodForm';
import { steps, setPaymentMethodFormValues } from '../../../helpers/formHelpers';
import { useSelectedPaymentMethod, useSetCurrentStep } from '../../../sendFundsStore';

const PaymentMethodDetails = () => {
  const selectedPaymentMethod = useSelectedPaymentMethod();
  const setCurrentStep = useSetCurrentStep();
  const formik = useFormikContext();
  const { values, setFieldValue } = formik;
  const { paymentMethodId } = values;
  const [showPaymentMethodForm, setShowPaymentMethodForm] = useState(false);

  const { bankName, accountType, accountNumber, routingNumber, address, type, name } =
    selectedPaymentMethod || {};
  const bankNameorAccountType = () => {
    if (bankName) {
      return bankName;
    }
    if (accountType && accountType !== "I'm not sure") {
      return accountType;
    }
    return 'Account';
  };

  const { street, addressUnit, city, state, postalCode } = address || {};
  const displayAddress = `${street}${addressUnit || ''}, ${city}, ${state}, ${postalCode}`;

  const maskedAccountNumber = accountNumber ? `xxxx${accountNumber?.slice(-4)}` : `xxxx`;
  const maskedRoutingNumber = routingNumber ? `xxxx${routingNumber?.slice(-4)}` : `xxxx`;

  const handleEditPaymentMethod = () => {
    if (values?.payeeId) {
      setCurrentStep(steps.UPDATE_OR_ADD_PAYMENT_METHOD);
    }

    setShowPaymentMethodForm(true);
    setPaymentMethodFormValues(selectedPaymentMethod, setFieldValue, values?.name);
  };

  useEffect(() => {
    setShowPaymentMethodForm(false);
    if (selectedPaymentMethod?.status === 'unsaved' && values?.payeeId) {
      setCurrentStep(steps.UPDATE_OR_ADD_PAYMENT_METHOD);
    }
  }, [selectedPaymentMethod]);

  return selectedPaymentMethod?.id || paymentMethodId ? (
    <Box pb={3}>
      <BaselaneCardNew>
        <Box width="100%">
          {selectedPaymentMethod?.status === 'unsaved' || showPaymentMethodForm ? (
            <PaymentMethodForm />
          ) : (
            <HStack spacing={0} gap={0} justifyContent="space-between">
              {type === 'Check' ? (
                <Box>
                  <Text textStyle="headline-sm">{name}</Text>
                  <Text textStyle="sm">{displayAddress}</Text>
                </Box>
              ) : (
                <Box>
                  <Text textStyle="headline-sm">{bankNameorAccountType()}</Text>
                  <Text textStyle="sm">
                    {`Account: ${maskedAccountNumber} · Routing: ${maskedRoutingNumber}`}
                  </Text>
                </Box>
              )}
              <BaselaneButton
                size="md"
                variant="transparent"
                palette="primary"
                leftIcon={<Icon16Edit />}
                onClick={() => handleEditPaymentMethod()}
              >
                Edit
              </BaselaneButton>
            </HStack>
          )}
        </Box>
      </BaselaneCardNew>
    </Box>
  ) : null;
};

export default PaymentMethodDetails;
