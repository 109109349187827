import React from 'react';
import BaselaneTooltip from './BaselaneTooltip';

const NoPropertyTooltip = ({ children, ...rest }: { children: React.Node }) => {
  return (
    <BaselaneTooltip
      label="No properties"
      placement="top"
      styles={{ width: '243px', align: 'center', borderRadius: 'sm' }}
      {...rest}
    >
      {children}
    </BaselaneTooltip>
  );
};

export default NoPropertyTooltip;
