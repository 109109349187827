export const containerStyles = { p: '52px 32px 26px' };
export const successIconContainerStyles = { p: '0', m: '12px 0!important' };

export const textHeadingStyles = {
  fontStyle: 'normal',
  fontWeight: 'semibold',
  fontSize: '2xl',
  lineHeight: '32px',
  color: '#192C3E',
  p: '0!important',
  m: '12px 0!important',
  mt: '0!important',
};

export const textStyles = {
  textStyle: 'sm',
  fontStyle: 'normal',
  color: '#3A4B5B',
  p: '0!important',
  m: '12px 0!important',
  mt: '0!important',
};
