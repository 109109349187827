export const containerStyles = {
  align: 'center',
  justify: 'center',
  h: '100%',
};

export const successIconContainerStyles = {
  align: 'center',
  justify: 'center',
  w: '48px',
  h: '48px',
  bg: 'green.100',
  borderRadius: '50%',
};

export const textStyles = {
  textStyle: 'headline-2xl',
  textAlign: 'center',
};
