import React from 'react';
import { Stack } from '@chakra-ui/react';
import { BaselaneCardNew } from '../BaselaneCard-new';
import BaselaneDivider from '../BaselaneDivider';
import BaselaneSummaryCardListGroup from './BaselaneSummaryCardListGroup';
import BaselaneSummaryCardListItem from './BaselaneSummaryCardListItem';
import { baselaneSummaryCardStyles } from './styles/baselaneSummaryCard.styles';

type BaselaneSummaryCardItemsType = {
  id?: String,
  label: string | React.Node,
  value: string | React.Node,
  subtext?: string | React.Node,
  isHidden?: boolean,
};
type BaselaneSummaryCardProps = {
  header: string | React.Node,
  list: Array<BaselaneSummaryCardItemsType>,
  isCustom: boolean,
  isGroupDividerHidden: boolean,
};

const BaselaneSummaryCard = ({
  header,
  list,
  isCustom,
  isGroupDividerHidden,
}: BaselaneSummaryCardProps) => {
  const { cardStyles, dividerStyles } = baselaneSummaryCardStyles;

  return (
    <BaselaneCardNew {...cardStyles}>
      {isCustom ? (
        <Stack w="100%" gap={1}>
          {header}
          {list && <BaselaneDivider styles={dividerStyles} />}

          {list?.map((listGroup, index) => {
            const { id, items } = listGroup;
            return (
              <BaselaneSummaryCardListGroup key={id} isDividerHidden={index === list.length - 1}>
                {items.map((item) => {
                  const { id: itemId, label, value, subtext, isHidden } = item;
                  return (
                    <BaselaneSummaryCardListItem
                      key={itemId}
                      {...{
                        label,
                        value,
                        subtext,
                        isHidden,
                      }}
                    />
                  );
                })}
              </BaselaneSummaryCardListGroup>
            );
          })}
        </Stack>
      ) : (
        <Stack w="100%" gap={1}>
          {header}
          {list && <BaselaneDivider styles={dividerStyles} />}
          {list?.map((listGroup, index) => {
            const { id, items } = listGroup;
            return (
              <BaselaneSummaryCardListGroup
                key={id}
                isDividerHidden={isGroupDividerHidden || index === list.length - 1}
              >
                {items.map((item) => {
                  return item;
                })}
              </BaselaneSummaryCardListGroup>
            );
          })}
        </Stack>
      )}
    </BaselaneCardNew>
  );
};

export default BaselaneSummaryCard;
