/* eslint-disable no-underscore-dangle */
// @flow
import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  BaselaneAlert,
  BaselaneButton,
  AlertHeader,
  AlertFooter,
  UnavailableRentCollectionMessageCard,
  BaselaneDrawer,
} from '@shared/components';
import { IconExclamationTriangle } from '@icons';
import { UPDATE_MULITPLE_BANK_ACCOUNTS } from '@core/components/NativeBankingPage/queries';
import AccountEditForm from './AccountEditForm';

const EditExternalDrawer = forwardRef(
  (
    {
      title,
      header,
      account,
      refetchBankSummary,
      accountEditDrawerRef,
      onClose,
      onDirtyEdit,
    }: {
      title?: String,
      header?: Object,
      account: Object,
      refetchBankSummary: Function,
      accountEditDrawerRef: any,
      onClose: Function,
      onDirtyEdit?: Function,
    },
    ref
  ) => {
    const { isMinXL, isMax576 } = getBreakPoints();
    const formikRef = useRef(null);
    const [initialAccount, setInitialAccount] = useState(account);
    const [variables, setVariables] = useState<Object>({ id: account.id });
    const [isDirty, setIsDirty] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isCheckedDuplicateTransactions, setIsCheckedDuplicateTransactions] = useState(false);
    const [isImportingAlertOpen, setIsImportingAlertOpen] = useState(false);
    const [isImportDisabledAlertOpen, setIsImportDisabledAlertOpen] = useState(false);
    const [isAlertOpenFromFooter, setIsAlertOpenFromFooter] = useState(false);
    const [
      isDeleteImportedTransactionsAlertOpen,
      setIsDeleteImportedTransactionsAlertOpen,
    ] = useState(false);

    // Apollo update mutation
    const [updateBankAccount, { loading: updateBankLoading, error: updateBankError }] = useMutation(
      UPDATE_MULITPLE_BANK_ACCOUNTS
    );

    // Alert for drawer
    const [isDrawerAlertOpen, setIsDrawerAlertOpen] = useState(false);
    const onAlertIsClose = () => setIsDrawerAlertOpen(false);
    const cancelRef = useRef();

    // Toaster on Error
    if (updateBankError) {
      const toast = useToast();
      toast({
        description: updateBankError,
        status: 'error',
        isClosable: true,
      });
    }

    const cleanUpOnClose = (e) => {
      onClose(e);
      setIsDirty(false);
      setIsAlertOpenFromFooter(false);
    };

    const saveData = (newVariables, dirty) => {
      const result = dirty ? { ...variables, ...newVariables } : { id: account.id };
      setVariables(result);
      setIsDirty(dirty);
      const updatedAccount = { ...initialAccount, ...result };
      setInitialAccount(updatedAccount);

      const isNicknameValid =
        updatedAccount.nickName !== '' &&
        updatedAccount.nickName !== undefined &&
        updatedAccount.nickName?.trim() !== '';

      const isAutoTagValid = updatedAccount?.autoTag?.enabled
        ? !!updatedAccount?.autoTag?.propertyId
        : true;

      setIsFormValid(isNicknameValid && isAutoTagValid);
    };

    const setFormVariables = (newVariables, dirty) => {
      saveData(newVariables, dirty);
    };

    const handleDrawerClose = () => {
      if (isDirty) {
        setIsDrawerAlertOpen(true);
      } else {
        cleanUpOnClose();
      }
    };

    const handleDrawerAlertContinue = (e) => {
      setIsDrawerAlertOpen(false);
      cleanUpOnClose(e);
      setInitialAccount(account);
    };

    const handleTerminateAccount = (id, deleteImportedTrx) => {
      updateBankAccount({
        variables: {
          input: {
            id,
            isConnected: false,
            nickName: null,
            importTransaction: {
              deleted: deleteImportedTrx || false,
            },
            autoTag: { enabled: false },
          },
        },
      })
        .then(() => {
          refetchBankSummary();
          cleanUpOnClose();
        })
        .catch((err) => console.log(err));
    };

    const handleSaveAndClose = (isDeletedTrx) => {
      const { id, nickName, isConnected, autoTag, importTransaction } = initialAccount;

      delete initialAccount.__typename;
      delete initialAccount.autoTag.__typename;
      delete initialAccount.importTransaction.__typename;

      const deleted = isDeletedTrx || false;
      const formattedAccounts = [
        {
          id,
          nickName,
          isConnected,
          autoTag,
          importTransaction: { ...importTransaction, deleted },
        },
      ];

      updateBankAccount({
        variables: { input: formattedAccounts },
      })
        .then(() => {
          refetchBankSummary();
          cleanUpOnClose();
        })
        .catch((err) => console.log(err));
    };

    const handleSaveOnly = () => {
      const { id, nickName, isConnected, autoTag, importTransaction } = initialAccount;

      delete initialAccount.__typename;
      delete initialAccount.autoTag.__typename;
      delete initialAccount.importTransaction.__typename;

      const formattedAccounts = [
        {
          id,
          nickName,
          isConnected,
          autoTag,
          importTransaction,
        },
      ];

      updateBankAccount({
        variables: { input: formattedAccounts },
      })
        .then(() => {
          refetchBankSummary();
        })
        .catch((err) => console.log(err));
    };

    const checkWillGenerateDuplicateTransactions = () => {
      const { importTransaction } = variables;

      if (
        importTransaction &&
        importTransaction.enabled &&
        importTransaction.importTransactionType === 'ALL' &&
        account.importTransaction.importTransactionType === 'ALL'
      ) {
        setIsImportingAlertOpen(true);
      } else {
        handleSaveAndClose();
      }
    };

    const handleSaveButton = (e) => {
      e.stopPropagation();
      setIsAlertOpenFromFooter(true);

      if (isDirty && !isCheckedDuplicateTransactions) {
        checkWillGenerateDuplicateTransactions();
      } else {
        handleSaveAndClose();
      }
    };

    useImperativeHandle(ref, () => ({
      save() {
        handleSaveOnly();
      },
      terminate(id) {
        handleTerminateAccount(id);
      },
      setImport() {
        checkWillGenerateDuplicateTransactions();
      },
    }));

    const { isRentCollectionSupported } = account || {};

    const alertDrawerCloseFooter = (
      <AlertFooter
        cancelRef={cancelRef}
        leftButtonEvent={onAlertIsClose}
        rightButtonEvent={handleDrawerAlertContinue}
      />
    );

    const drawerFooter = (
      <>
        <BaselaneButton size="md" variant="outline" palette="neutral" onClick={handleDrawerClose}>
          Cancel
        </BaselaneButton>
        <BaselaneButton
          size="md"
          width="100%"
          ml="12px"
          variant="filled"
          palette="primary"
          onClick={handleSaveButton}
          isDisabled={!isFormValid || !isDirty}
          isLoading={updateBankLoading}
        >
          Save & Close
        </BaselaneButton>
      </>
    );

    return (
      <BaselaneDrawer
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        ref={accountEditDrawerRef}
        title={title}
        header={header}
        footer={drawerFooter}
        closeEvent={handleDrawerClose}
        closeOnOverlayClick={false}
        onOverlayClick={handleDrawerClose}
        newDesignDrawer
      >
        <>
          <UnavailableRentCollectionMessageCard
            isRentCollectionSupported={isRentCollectionSupported}
            containerStyles={{ m: isMinXL ? '0 0 32px 0' : '32px 0 0 0', w: '100%' }}
          />

          <AccountEditForm
            {...{
              formikRef,
              initialAccount,
              setFormVariables,
              handleTerminateAccount,
              setIsCheckedDuplicateTransactions,
              isImportingAlertOpen,
              isImportDisabledAlertOpen,
              setIsImportingAlertOpen,
              setIsImportDisabledAlertOpen,
              isAlertOpenFromFooter,
              handleSaveAndClose,
              handleDirtyChange: onDirtyEdit,
              isDeleteImportedTransactionsAlertOpen,
              setIsDeleteImportedTransactionsAlertOpen,
            }}
          />

          <BaselaneAlert
            isOpen={isDrawerAlertOpen}
            leastDestructiveRef={cancelRef}
            onClose={onAlertIsClose}
            isCentered
            header={
              <AlertHeader icon={<IconExclamationTriangle />} title="You Have Unsaved Changes" />
            }
            body="Are you sure you want to exit without saving?"
            footer={alertDrawerCloseFooter}
          />
        </>
      </BaselaneDrawer>
    );
  }
);

EditExternalDrawer.defaultProps = {
  title: 'Edit External Account',
  header: null,
  onDirtyEdit: () => {},
};

export default EditExternalDrawer;
