import React, { useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { BaselaneMenu } from '@shared/components';
import { Icon16DotsMenuVertical } from '@icons/16px';
import { ARCHIVE_TENANT_PROFILE } from '../queries';
import useTenantLeasesQuery from '../useTenantLeasesQuery';
import EditTenantDrawer from './EditTenantDrawer';
import NotesDrawer from './NotesDrawer';
import DeleteTenantAlert from './DeleteTenantAlert';

function TenantContextMenu({
  tenant,
  otherTenantEmails,
  landlordEmail,
}: {
  tenant: Object,
  otherTenantEmails: Array<String>,
  landlordEmail: string,
}) {
  const { notes, id } = tenant;
  const { getTenantLeases, tenantLeasesData, tenantLeasesLoading } = useTenantLeasesQuery({
    tenantProfileId: id,
    leasesState: 'SCHEDULED',
  });
  // acts as a soft delete for simplicity
  const [deleteTenant] = useMutation(ARCHIVE_TENANT_PROFILE);

  const { isOpen: isNotesOpen, onOpen: onNotesOpen, onClose: onNotesClose } = useDisclosure();
  const {
    isOpen: isDeleteTenantAlertOpen,
    onOpen: onDeleteTenantAlertOpen,
    onClose: onDeleteTenantAlertClose,
  } = useDisclosure();

  const editTenantDrawerRef = useRef();
  const handleEditTenantDrawerOpen = () => editTenantDrawerRef.current?.open();
  const handleEditTenantDrawerClose = () => editTenantDrawerRef.current?.close();

  const handleMenuOpen = (e) => {
    e.stopPropagation();
  };

  const handleEditOpen = (e) => {
    e.stopPropagation();
    handleEditTenantDrawerOpen();
  };

  const handleNotesOpen = (e) => {
    e.stopPropagation();
    onNotesOpen();
  };

  const toast = useToast();

  const showDeleteTenantSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: `Deleted tenant`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showDeleteTenantErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: `Failed to delete tenant`,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleDeleteTenant = () => {
    // archive will for now act as a soft delete for simplicity
    deleteTenant({
      variables: { tenantProfileId: Number(id) },
      update: (cache, { data: archiveTenantData, errors }) => {
        const { archiveTenantProfile = {} } = archiveTenantData ?? {};
        if (archiveTenantProfile?.id) {
          cache.evict({ id: `TenantProfile:${archiveTenantProfile.id}` });
          cache.gc();
          showDeleteTenantSuccessToast();
        }
        if (errors && errors.length > 0) {
          showDeleteTenantErrorToast();
        }
      },
    });
  };

  const menuItems = [
    {
      id: 'edit_details',
      name: 'Edit details',
      onClick: handleEditOpen,
    },
    {
      id: 'notes',
      name: 'Add or edit notes',
      onClick: handleNotesOpen,
    },
    {
      id: 'delete',
      name: 'Delete',
      onClick: (e) => {
        e.stopPropagation();
        getTenantLeases();
        onDeleteTenantAlertOpen();
      },
    },
  ];

  return (
    <>
      <BaselaneMenu
        buttonVariant="transparent"
        buttonPalette="neutral"
        listItems={menuItems}
        customButton="BaselaneButtonIcon"
        buttonIcon={<Icon16DotsMenuVertical />}
        onClick={handleMenuOpen}
      />

      {/* Drawers */}
      <NotesDrawer tenantId={id} notes={notes} onClose={onNotesClose} isOpen={isNotesOpen} />
      <EditTenantDrawer
        tenant={tenant}
        editTenantDrawerRef={editTenantDrawerRef}
        handleEditTenantDrawerClose={handleEditTenantDrawerClose}
        otherTenantEmails={otherTenantEmails}
        landlordEmail={landlordEmail}
      />

      {/* Alerts */}
      <DeleteTenantAlert
        isAlertOpen={isDeleteTenantAlertOpen}
        onAlertClose={onDeleteTenantAlertClose}
        onDelete={handleDeleteTenant}
        leases={tenantLeasesData}
        leasesLoading={tenantLeasesLoading}
      />
    </>
  );
}

export default TenantContextMenu;
