/* eslint-disable no-param-reassign, no-underscore-dangle */
import React, { useEffect } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import BaselaneTooltip from '@shared/components/BaselaneTooltip';
import { getAtomBackgroundColor, getAtomClassName, getAtomStyles } from './helpers/atom.helpers';
import { atomContainerStyles, atomBaseStyles } from './styles/atom.styles';
import { atomsConfigType } from './types/atoms.types';

import './styles/atom.styles.scss';

/**
 * A helper function to add tooltip around text if it is specified in config.
 *
 * @param a: Object of atom config info, see atomsConfigType
 * @param index: to set id of the tooltip to it can be found properly and apply hover state dynamically
 * @returns {JSX.Element|*}
 */
const handleShowTooltipOrContent = (a, index) => {
  if (a?.showTooltip) {
    return (
      <BaselaneTooltip
        id={index}
        placement="top"
        label={a.tooltipContent}
        innerContainerStyles={{ ml: '0' }}
      >
        {a.value}
      </BaselaneTooltip>
    );
  }

  return a.value;
};

type BaselaneAtomsProps = { config: atomsConfigType };

const BaselaneAtoms = ({ config }: BaselaneAtomsProps) => {
  /**
   * There are two sets of helper function to dynamically add and remove the hover state. Due to the
   * design where we have to have hover styles in the previous atoms as well.
   */

  /**
   * To dynamically add completed hover styles that is specified in config/or default completed styles.
   *
   * @param e: event that gets passed onMouseEnter
   * @param isActiveStateSeparate: when the node is active and it is specified in config that it is separate from other atoms
   */
  const addCompletedHoverStyles = (e, isActiveStateSeparate) => {
    const completedAtoms = document.getElementsByClassName('atom-completed');
    const { completed: completedStyles, active: activeStyles } = config[e.target.id].styles;
    const [activeAtom] = document.getElementsByClassName('atom-active');

    if (isActiveStateSeparate && activeAtom?.parentElement) {
      const activeAtomParentElementSibling = activeAtom?.parentElement?.previousSibling;
      activeAtomParentElementSibling.style.backgroundColor = activeStyles._hover.bgColor;
    }

    const elementId = Number(e.target.id);
    if (!isActiveStateSeparate) {
      Array.from(completedAtoms).forEach((ca, caIndex) => {
        if (caIndex <= elementId) {
          ca.style.backgroundColor = completedStyles._hover.bgColor;
        }
      });
    }
  };

  /**
   * To dynamically remove completed hover styles that is specified in config/or default completed styles.
   *
   * @param e: event that gets passed onMouseLeave
   * @param isActiveStateSeparate: when the node is active and it is specified in config that it is separate from other atoms
   */
  const removeCompletedHoverStyles = (e, isActiveStateSeparate) => {
    const completedAtoms = document.getElementsByClassName('atom-completed');
    const [activeAtom] = document.getElementsByClassName('atom-active');

    if (isActiveStateSeparate && activeAtom?.parentElement) {
      const activeAtomParentElementSibling = activeAtom?.parentElement?.previousSibling;
      activeAtomParentElementSibling.style.backgroundColor = '';
    }

    Array.from(completedAtoms).forEach((ca) => {
      ca.style.backgroundColor = '';
    });
  };

  /**
   * To dynamically add not completed hover styles that is specified in config/or default completed styles.
   *
   * @param e: event that gets passed onMouseEnter
   */
  const addNotCompletedHoverStyles = (e) => {
    const notCompletedAtoms = document.getElementsByClassName('atom');
    const [activeAtom] = document.getElementsByClassName('atom-active');
    const { notCompleted: notCompletedStyles } = config[e.target.id].styles;

    if (activeAtom?.parentElement) {
      const activeAtomParentElement = activeAtom?.parentElement;
      activeAtomParentElement.style.backgroundColor = notCompletedStyles._hover.bgColor;
    }

    const elementId = Number(e.target.id);
    const startingIndex = config.length - notCompletedAtoms.length - 1;
    Array.from(notCompletedAtoms).forEach((a, aIndex) => {
      if (startingIndex + aIndex <= elementId) {
        a.style.backgroundColor = notCompletedStyles._hover.bgColor;
      }
    });
  };

  /**
   * To dynamically add not completed hover styles that is specified in config/or default completed styles.
   *
   */
  const removeNotCompletedHoverStyles = () => {
    const notCompletedAtoms = document.getElementsByClassName('atom');
    const [activeAtom] = document.getElementsByClassName('atom-active');

    if (activeAtom?.parentElement) {
      const activeAtomParentElement =
        activeAtom?.parentElement ?? notCompletedAtoms[0]?.parentElement;
      activeAtomParentElement.style.backgroundColor = '';
    }

    Array.from(notCompletedAtoms).forEach((ca) => {
      ca.style.backgroundColor = '';
    });
  };

  // remove inline styles on change of config. Inline styles should only appear on hover
  useEffect(() => {
    if (config.length > 0) {
      const notCompletedAtoms = document.getElementsByClassName('atom');
      const completedAtoms = document.getElementsByClassName('atom-completed');
      const lastAtom = document.getElementsByClassName('atom-last');
      const [activeAtom] = document.getElementsByClassName('atom-active');
      const atoms = [
        ...Array.from(notCompletedAtoms),
        ...Array.from(completedAtoms),
        ...Array.from(lastAtom),
      ];
      if (activeAtom) {
        atoms.push(activeAtom);
      }

      atoms.forEach((a) => {
        if (a?.style) {
          a.style.backgroundColor = '';
        }
      });
    }
  }, [config]);

  /**
   * Logic behind the structure of an atom. There are two main layers:
   * 1. Container of the atom this will be the helper to connect the color of the atom before of after it.
   * 2. The actual atom that has the text + tooltip
   */
  return (
    <HStack position="relative" spacing={0} maxWidth="100%">
      {config.map((a, index) => {
        // Based on the state the atom is in it will grab the proper styling and classname
        const atomStyles = getAtomStyles(a);
        const atomBgColor = getAtomBackgroundColor(config, index);
        const className = getAtomClassName(a, config, index);

        return (
          // atom container
          <Box key={a.value} {...atomContainerStyles(index, atomBgColor, config)}>
            {/* atom */}
            <Box
              onMouseEnter={
                a.isCompleted || a.isActive
                  ? (e) => addCompletedHoverStyles(e, a.isActive && a.isSeparate)
                  : (e) => addNotCompletedHoverStyles(e)
              }
              onMouseLeave={
                a.isCompleted || a.isActive
                  ? (e) => removeCompletedHoverStyles(e, a.isActive && a.isSeparate)
                  : (e) => removeNotCompletedHoverStyles(e)
              }
              id={index}
              className={className}
              {...{
                ...atomBaseStyles(index, config),
                ...atomStyles,
              }}
            >
              {handleShowTooltipOrContent(a, index)}
            </Box>
          </Box>
        );
      })}
    </HStack>
  );
};

export default BaselaneAtoms;
