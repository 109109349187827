import React from 'react';
import { Box } from '@chakra-ui/react';
import customTheme from '@theme';

import { disclaimerTextStyles } from './styles';

const DisclaimerText = ({ styles = {} }: { styles: Object }) => (
  <Box {...disclaimerTextStyles} {...styles}>
    Baselane is a financial technology company, and is not a bank. Banking services provided by
    Thread Bank, Member FDIC. The Baselane Visa Debit Card is issued by Thread Bank, pursuant to a
    license from Visa U.S.A. Inc. and may be used everywhere Visa is accepted. FDIC insurance is
    available for funds on deposit up to a maximum of $3,000,000 in FDIC insurance coverage when
    placed at program banks in the Thread Bank deposit sweep program. Your deposits at each program
    bank become eligible for FDIC insurance up to $250,000, inclusive of any other deposits you may
    already hold at the bank in the same ownership capacity. You can access the terms and conditions
    of the sweep program at{' '}
    <a
      className="disclaimerlink"
      href="https://go.thread.bank/sweepdisclosure"
      target="_blank"
      rel="noreferrer noopener"
      style={{ color: customTheme.colors.brand.blue['800A'] }}
    >
      https://go.thread.bank/sweepdisclosure
    </a>{' '}
    and a list of program banks at{' '}
    <a
      className="disclaimerlink"
      href="https://go.thread.bank/programbanks"
      target="_blank"
      rel="noreferrer noopener"
      style={{ color: customTheme.colors.brand.blue['800A'] }}
    >
      https://go.thread.bank/programbanks
    </a>
    . Please contact customerservice@thread.bank with questions on the sweep program.
  </Box>
);

export default DisclaimerText;
