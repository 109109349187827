import { truncatedText } from '@core/components/Shared/styles/text.style';
import radii from '@core/theme/radius';

export const menuStyles = {
  profileButton: {
    borderRadius: '24px',
    py: 0.5,
    pl: 0.5,
    pr: 1.5,
    m: '0 !important',
    cursor: 'pointer',
    iconSpacing: '4px !important',
    _active: {
      borderColor: 'brand.darkBlue.250',
      bg: 'brand.darkBlue.100',
    },
    sx: {
      '&.open': {
        borderColor: 'brand.darkBlue.200',
        bg: 'brand.darkBlue.50',
      },
      '&.open.pressed': {
        borderColor: 'brand.darkBlue.250',
        bg: 'brand.darkBlue.100',
      },
    },
  },
  username: {
    display: { base: 'none', '2lg': 'block' },
    maxW: '324px',
    ...truncatedText,
  },
  divider: {
    borderColor: 'brand.darkBlue.100',
    my: 1.5,
  },
  list: {
    border: '1px solid',
    borderColor: 'brand.darkBlue.200',
    borderRadius: radii.sm,
    paddingX: '2',
    paddingY: '1.5',
    maxWidth: '480px',
    maxHeight: '640px',
    minWidth: '200px',
    boxShadow: '-4px 8px 24px 0px rgba(219, 219, 224, 0.2)',
  },
  item: (isDisabled) => ({
    borderRadius: radii.sm,
    padding: '1',
    _hover: { bg: 'brand.blue.50' },
    ...(isDisabled && {
      _hover: { bg: 'none' },
      _active: { bg: 'none' },
      _focus: { bg: 'none ' },
    }),
  }),
};
