/* eslint-disable react/prop-types */
import React from 'react';
import { FormControl, FormLabel, Text, HStack } from '@chakra-ui/react';
import { Field, useField } from 'formik';
import { T2WithTitleDropdown, BaselaneFormErrorMessage } from '@shared/components';
import useCategories from '@features/Category/useCategories';
import { itemRenderer } from '@core/components/Shared/components/BaselaneDropdown-new/helpers/itemRenderer.helpers';
import {
  DisplayInputWrapperWithRightButton,
  Placeholder,
} from '@shared/components/BaselaneDropdown-new/DisplayInputVariations';

const DropDownDisplayInput = ({
  selectedOptions,
  handleContentRendererClick,
  handleClearClick,
  placeholder,
  title,
  classNames,
}) => {
  return (
    <DisplayInputWrapperWithRightButton
      handleContentRendererClick={handleContentRendererClick}
      handleClearClick={handleClearClick}
      selectedOptions={selectedOptions}
      classNames={classNames}
    >
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder placeholder={placeholder} title={title} />
      ) : (
        <HStack color="brand.neutral.500" overflow="hidden">
          <Text
            color="brand.neutral.900"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {selectedOptions[0].showValueName ?? selectedOptions[0].name}
          </Text>
        </HStack>
      )}
    </DisplayInputWrapperWithRightButton>
  );
};

const Category = () => {
  const { categoriesForDropdown, categoriesForDropdownById } = useCategories({
    sortCategories: false,
  });

  const setCategoryId = useField('categoryId')[2].setValue;

  return (
    <Field name="categoryId">
      {({ field, form }) => (
        <FormControl isInvalid={form.errors.categoryId && form.touched.categoryId} mt={3}>
          <FormLabel display="flex">
            Category
            <Text color="brand.neutral.500" ml={0.5}>
              (optional)
            </Text>
          </FormLabel>
          <T2WithTitleDropdown
            additionalProps={{
              id: 'categoryId',
            }}
            classNames={['input-form-lg', 'is-full-width']}
            data={categoriesForDropdown}
            title="Category"
            showValueByFields={['name']}
            placeholder="Select"
            parentItemRenderer={itemRenderer}
            childItemRenderer={itemRenderer}
            handleSubmit={(value) => {
              // For some reason, clicking clear makes dropdown return an empty array instead of null.
              const newValue = Array.isArray(value) && value.length === 0 ? null : value;
              setCategoryId(newValue);
            }}
            selectedItem={categoriesForDropdownById[field.value]}
            isMulti={false}
            hasFilterWrapper={false}
            CustomDisplayInput={DropDownDisplayInput}
          />
          <BaselaneFormErrorMessage isInvalid={form.errors.categoryId && form.touched.categoryId}>
            {form.errors.categoryId}
          </BaselaneFormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default Category;
