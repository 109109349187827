import { gql } from '@apollo/client';

export const GET_STATEMENTS = gql`
  query statements($filter: StatementFilter, $page: Float, $pageSize: Float, $sort: StatementSort) {
    statements(input: { filter: $filter, page: $page, pageSize: $pageSize, sort: $sort }) {
      data {
        bankAccountId
        period
        id
        unitStatementId
        userId
        bankPartner
      }
      page
      pageSize
      total
    }
  }
`;
