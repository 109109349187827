import React from 'react';
import DisplayInputWrapper from '../DisplayInputWrapper';
import DisplayInputShowingSelectedValueNames from './DisplayInputShowingSelectedValueNames';
import Placeholder from '../Placeholders/Placeholder';

type DisplayInputDefaultProps = {
  handleContentRendererClick: Function,
  showValueByFields: Array<string>,
  selectedOptions: Array<Object>,
  title: string,
  placeholder: string,
  classNames: Array<string>,
};

const DisplayInputDefault = ({
  handleContentRendererClick,
  selectedOptions,
  showValueByFields,
  placeholder,
  title,
  classNames,
}: DisplayInputDefaultProps) => {
  return (
    <DisplayInputWrapper
      handleContentRendererClick={handleContentRendererClick}
      classNames={classNames}
    >
      {selectedOptions.length > 0 ? (
        <DisplayInputShowingSelectedValueNames
          showValueByFields={showValueByFields}
          selectedOptions={selectedOptions}
        />
      ) : (
        <Placeholder placeholder={placeholder} title={title} />
      )}
    </DisplayInputWrapper>
  );
};

export default DisplayInputDefault;
