export const getAccountsForDropDown = ({ accounts, hideExternalAccounts, hideSavingsAccounts }) => {
  let accountsForDropDown = accounts.map((item) => {
    if (item?.id === 'baselane_account') {
      const accountsWithoutSavings = item.items.filter((acc) => acc.accountSubType !== 'savings');
      return {
        ...item,
        title: `${item.title} (Free tenant ACH payments)`,
        items: hideSavingsAccounts ? accountsWithoutSavings : item.items,
      };
    }

    return {
      ...item,
      title: `${item.title} ($2 fee for tenant ACH payments)`,
    };
  });

  if (hideExternalAccounts) {
    accountsForDropDown = accountsForDropDown.filter((acc) => acc?.id !== 'external_account');
  }

  return accountsForDropDown;
};
