import { gql } from '@apollo/client';

export const GET_LEASE_DETAIL = gql`
  query LeaseDetail {
    leaseDetail {
      property {
        id
        name
        address
        description
        units {
          id
          name
          description
          lease {
            id
            propertyUnitId
            tenantProfileId
            rentAndFeesBankAccountId
            depositBankAccountId
          }
        }
      }
    }
  }
`;
