export const headerStyles = {
  p: 0,
  pl: 2,
};

export const bodyStyles = (isMinXL) => ({
  pt: 0,
  pl: isMinXL ? 0 : 1,
  pr: isMinXL ? 0 : 1,
  pb: isMinXL ? 0 : 1,
  m: 0,
  overflow: 'auto',
});

export const footerStyles = (isMinXL) => ({
  mt: isMinXL ? 0 : 1,
  borderTop: '1px solid',
  borderTopColor: 'brand.darkBlue.200',
});

export const containerStyles = (isMinXL) => ({
  m: 0,
  p: 0,
  pt: isMinXL ? 2 : '3',
  borderRadius: '8px',
  maxHeight: 'calc(100% - 48px)',
  overflow: 'hidden',
});

export const tabPanelStyles = {};
