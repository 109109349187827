// @flow
import { Editable, EditableInput, EditablePreview } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  editableInputDefaultStyles,
  editablePreviewDefaultStyles,
  editablePreviewOnHoverStyles,
} from '../styles/editable.style';
import { normalText } from '../styles/text.style';

type Props = {
  model: ?string,
  onSubmit: Function,
  placeholder?: string,
  editableInputCustomStyles?: Object,
  editablePreviewCustomStyles?: Object,
  isDisabled?: boolean,
};

function FocusTextInput({
  model,
  onSubmit,
  placeholder,
  editableInputCustomStyles,
  editablePreviewCustomStyles,
  isDisabled,
}: Props): any {
  const initialValue = model || '';

  // Overriding text color for Editable -> Preview just to color the placeholder
  const overrideTextColor = {
    color: model ? 'brand.neutral.700' : 'brand.neutral.600',
  };

  const editablePreviewStyles = {
    ...editablePreviewDefaultStyles,
    ...normalText,
    ...editablePreviewCustomStyles,
    ...overrideTextColor,
  };

  const editableInputStyles = { ...editableInputDefaultStyles, ...editableInputCustomStyles };

  const [value, setValue] = useState(initialValue);

  const [editablePreviewHoverStyles, setEditablePreviewHoverStyles] = useState(
    editablePreviewOnHoverStyles
  );

  useEffect(() => {
    setValue(initialValue);
  }, [model, initialValue]);

  useEffect(() => {
    const hoverStyles = isDisabled ? null : editablePreviewHoverStyles;
    setEditablePreviewHoverStyles(hoverStyles);
  }, [isDisabled]);

  const handleTextInputChange = (event) => setValue(event.target.value);

  return (
    <Editable
      {...{
        placeholder,
        onSubmit,
        value,
        selectAllOnFocus: false,
        isDisabled,
        isPreviewFocusable: !isDisabled,
      }}
    >
      <EditablePreview
        color={value ? 'brand.neutral.700' : 'brand.darkBlue.250'}
        _hover={editablePreviewHoverStyles}
        {...editablePreviewStyles}
      />
      <EditableInput {...editableInputStyles} onChange={handleTextInputChange} />
    </Editable>
  );
}

FocusTextInput.defaultProps = {
  placeholder: '',
  editableInputCustomStyles: null,
  editablePreviewCustomStyles: null,
  isDisabled: false,
};

export default FocusTextInput;
