import React from 'react';
import { useApolloClient } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import { Stack, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import {
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  BaselaneButtonGroup,
  TwoFactorVerificationPopUp,
  useTwoFactor,
} from '@shared/components';
import { IconExclamationCircle, IconUnlock } from '@icons';

import { lockCard, CARD_STATUS } from '../helpers/card.helper';
import { FRAGMENT_CARD_STATUS } from '../../../../../queries/fragments';
import { alertPopupIconBgStyles, alertPopupIconContainerStyles } from '../styles/lockCard.styles';
import {
  getTokenData,
  setTokenData,
  getIsTokenExpired,
  getIsTokenValid,
  getSensitiveToken,
  getUnitOTPVerification,
  handleOTPPopupClose,
  handleOTPPopupOpen,
  handleNBOnVerifyClick,
} from '../../../../../helpers/otp.helpers';

type LockCardProps = {
  bankId: string,
  card: Object,
  phoneNumber: string,
  setViewCardDetailsInfo: Function,
};

const LockCard = ({ bankId, card, phoneNumber, setViewCardDetailsInfo }: LockCardProps) => {
  const { cache } = useApolloClient();
  const { cardStatus, externalId } = card ?? {};

  const id = Number(externalId);
  const isCardStatusFrozen = cardStatus === 'Frozen';
  const { isLockButtonDisabled } = CARD_STATUS[cardStatus] ?? {};

  // Lock Card pop status
  const {
    isOpen: isLockCardPopupOpen,
    onOpen: onLockCardPopupOpen,
    onClose: onLockCardPopupClose,
  } = useDisclosure();

  // two factor verification states
  // NOTE: see AddEditSubAccountDrawer/index.jsx for some comments about OTP and setting the information for sensitive token/time
  const { states, stateFunctions } = useTwoFactor();
  const { setOTPErrorCode } = stateFunctions;

  const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

  const onUserSensitiveTokenComplete = (unitAPISensitiveToken) => {
    handleOTPPopupClose(states, stateFunctions);

    const isTokenValid = getIsTokenValid(unitAPISensitiveToken);
    setTokenData(unitAPISensitiveToken, bankId);

    if (isTokenValid) {
      // after successfully getting sensitive token open popup
      onLockCardPopupOpen();
    }
  };

  const getSensitiveTokenProps = {
    onUserSensitiveTokenComplete,
    getOTP,
    bankId,
    states,
    stateFunctions,
  };

  const [getUserSensitiveTokenData] = getSensitiveToken(getSensitiveTokenProps);

  const handleLockCardClick = () => {
    const { token } = getTokenData(bankId).sensitive;

    lockCard(id, token, isCardStatusFrozen)
      .then((response) => {
        if (response.status === 200) {
          cache.writeFragment({
            id: cache.identify(card),
            fragment: FRAGMENT_CARD_STATUS,
            data: {
              __typename: 'Card',
              cardStatus: isCardStatusFrozen ? 'Active' : 'Frozen',
            },
          });
          setViewCardDetailsInfo((prevState) => ({
            ...prevState,
            card: {
              ...prevState.card,
              cardStatus: isCardStatusFrozen ? 'Active' : 'Frozen',
            },
          }));
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((error) => console.error(error));

    onLockCardPopupClose();
  };

  const title = <>Are you sure you want to {isCardStatusFrozen ? 'unlock' : 'lock'} this card?</>;

  const lockCardPopupContent = isCardStatusFrozen ? (
    <Text>Transactions on this card will no longer be automatically declined.</Text>
  ) : (
    <Text>
      Any transactions on this card will be automatically declined. You may unlock this card at any
      time and allow new transactions.
    </Text>
  );

  const activatePopupFooter = (
    <Stack direction="row" justify="space-between" w="100%">
      <Spacer />
      <BaselaneButtonGroup size="lg">
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={onLockCardPopupClose}
        >
          Cancel
        </BaselaneButton>
        <BaselaneButton
          variant="filled"
          palette="danger"
          size="md"
          {...(isCardStatusFrozen && {
            ...{ rightIcon: <IconUnlock /> },
          })}
          onClick={handleLockCardClick}
        >
          {isCardStatusFrozen ? 'Unlock' : 'Lock Card'}
        </BaselaneButton>
      </BaselaneButtonGroup>
    </Stack>
  );

  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP,
    bankId,
    phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleNBOnVerifyClick(otpCode, bankId, getUserSensitiveTokenData),
  };

  return (
    <>
      <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />
      <BaselaneButton
        variant={isCardStatusFrozen ? 'filled' : 'outline'}
        palette={isCardStatusFrozen ? 'danger' : 'neutral'}
        size={isMobile ? 'md' : 'sm'}
        isFullWidth={isMobile}
        isDisabled={isLockButtonDisabled}
        {...(isCardStatusFrozen && {
          ...{ leftIcon: <IconUnlock /> },
        })}
        onClick={(e) => {
          e.stopPropagation();
          // OTP: Check if token of the bank expired
          const isTokenExpired = getIsTokenExpired(bankId);

          if (isTokenExpired) {
            handleOTPPopupOpen(getOTP, bankId, stateFunctions);
          } else {
            onLockCardPopupOpen();
          }
        }}
      >
        {isCardStatusFrozen ? 'Unlock' : 'Lock Card'}
      </BaselaneButton>

      <BaselaneAlert
        showCloseButton
        header={
          <AlertHeader
            icon={<IconExclamationCircle />}
            title={title}
            iconBgStyles={alertPopupIconBgStyles}
            iconContainerStyles={alertPopupIconContainerStyles}
          />
        }
        isOpen={isLockCardPopupOpen}
        onClose={onLockCardPopupClose}
        isCentered
        body={lockCardPopupContent}
        footer={activatePopupFooter}
        size="2xl"
      />
    </>
  );
};

export default LockCard;
