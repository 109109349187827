import React, { useEffect } from 'react';
import moment from 'moment';
import { Box, FormControl, FormErrorMessage, FormLabel, useDisclosure } from '@chakra-ui/react';
import { formatDate } from '@core/utils/formatDate';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneSingleDatePicker } from '@shared/components';
import { formErrorStyles, formLabelStyles } from '@shared/styles/input.style';
import { truncatedText } from '@shared/styles/text.style';
import { firstDateIsSmaller, getOnDateOptions } from '../../formHelpers/leaseTermForm.helper';
import { fieldContainer } from '../../styles/shared.styles';
import './form-datepicker.scss';

type LeaseEndDateInputProps = {
  name: string,
  errors: Object,
  touched: Object,
  values: Object,
  setFormVariables: Function,
  setFieldValue: Function,
  setRCStartOptions: Function,
  setNoRCStartOptions: Function,
  invoicesForUnit: Array,
};

function LeaseEndDateInput({
  name,
  errors,
  touched,
  values,
  setFormVariables,
  setFieldValue,
  setRCStartOptions,
  setNoRCStartOptions,
  invoicesForUnit,
}: LeaseEndDateInputProps) {
  const hasError = (errors[name] && touched[name]) || (errors[`${name}Required`] && touched[name]);
  const { isMin768 } = getBreakPoints();
  const years = { start: 2022, end: 2043 };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEndDateCalendarClose = ({ date: endDate, isFromApplyButton }) => {
    if (isMin768 || (!isMin768 && isFromApplyButton)) {
      const leaseEndDate = endDate ? formatDate(endDate, 'YYYY-MM-DD') : null;
      setFieldValue('endDate', endDate);
      const sortedInvoices = invoicesForUnit
        ?.filter(
          (item) =>
            (item.name === 'RENT' || item.name === 'RENT_AND_FEE') &&
            (item.state === 'UPCOMING' || item.state === 'SCHEDULED' || item.state === 'FAILED')
        )
        ?.sort(
          (a, b) =>
            moment(a?.originalDueDate).format('YYYYMMDD') -
            moment(b?.originalDueDate).format('YYYYMMDD')
        );
      const firstUnpaidInvoiceDate = sortedInvoices && sortedInvoices[0]?.dueDate;
      const firstPossibleStartDate =
        firstUnpaidInvoiceDate && firstDateIsSmaller(values.startDate, firstUnpaidInvoiceDate)
          ? firstUnpaidInvoiceDate
          : values.startDate;

      const newRCStartOptions = getOnDateOptions(
        values.dueDays,
        moment(firstPossibleStartDate),
        endDate,
        false,
        values.isMonthToMonth
      );

      setRCStartOptions(newRCStartOptions);
      setNoRCStartOptions(!newRCStartOptions || newRCStartOptions.length === 0);
      // if the current rc start option is not valid, as in, not in the array of options, we change that as well
      const valid = newRCStartOptions.find(
        (item) => item.dateValue === values.rentCollectionStartDate
      );
      if ((!valid || values?.rentCollectionStartDate === null) && newRCStartOptions[0]?.dateValue) {
        setFieldValue('rentCollectionStartDate', newRCStartOptions[0]?.dateValue);
        setFormVariables({
          endDate: values.isMonthToMonth ? null : leaseEndDate,
          rentCollectionStartDate: newRCStartOptions[0]?.dateValue,
        });
      } else {
        setFormVariables({ endDate: values.isMonthToMonth ? null : leaseEndDate });
      }
    }
  };

  useEffect(() => {
    if (values.isMonthToMonth) {
      setFieldValue('endDate', '');
      setFormVariables({ endDate: '' });
    }
  }, [values.isMonthToMonth, values]);

  // const startDatePlusOne = moment(values.startDate).add(1, 'days');
  // const minDate = startDatePlusOne.toDate();
  const minDate = values.startDate;

  return (
    <FormControl {...fieldContainer} isInvalid={errors.endDate && touched.endDate}>
      <FormLabel {...formLabelStyles.xs} htmlFor={name}>
        Lease end date
      </FormLabel>
      <Box w="100%" {...truncatedText}>
        <BaselaneSingleDatePicker
          {...{
            id: name,
            name,
            date: values.endDate,
            value: values.endDate ? `${formatDate(values.endDate, 'MMM DD, YYYY')}` : '',
            dateFormat: 'MMM dd, yyyy',
            handleCalendarClose: handleEndDateCalendarClose,
            isDisabled: values.isMonthToMonth,
            size: 'lg',
            years,
            minDate,
            isOpen,
            onOpen,
            onClose,
            hideInputIcon: true,
            fixedHeight: true,
            placement: 'bottom-end',
            showInModal: !isMin768,
            className: `form-datepicker ${hasError ? 'has-error' : ''}`,
          }}
        />
      </Box>
      <FormErrorMessage {...formErrorStyles}>{errors.endDate}</FormErrorMessage>
    </FormControl>
  );
}

export default LeaseEndDateInput;
