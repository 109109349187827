import React from 'react';
import { Box, Spacer, Text, Flex, Image, Checkbox, FormControl } from '@chakra-ui/react';
import { Field } from 'formik';
import BaselaneFormErrorMessage from '@core/components/Shared/components/BaselaneForm/BaselaneFormErrorMessage';
import endorseHereImgUri from './assets/endorse-here.svg';

const SignAndEndorseCheck = () => {
  return (
    <Box>
      <Flex>
        <Text textStyle="md-heavy">Sign and endorse check</Text>
        <Spacer />
        <Text textStyles="xs">Step 1 of 4</Text>
      </Flex>

      <Box
        mt={2}
        padding="10px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="brand.blue.50"
      >
        <Image src={endorseHereImgUri} alt="Endorse here" />
      </Box>
      <Box mt={2}>
        <Text textStyle="sm">
          In order to avoid delays or rejections, please make sure both of these items are
          completed:
        </Text>
      </Box>

      <Box mt={2}>
        <Field name="writeOnBackOfCheck">
          {({ field, form }) => (
            <FormControl
              isInvalid={form.errors.writeOnBackOfCheck && form.touched.writeOnBackOfCheck}
              isRequired
            >
              <Checkbox
                id="writeOnBackOfCheck"
                className="primary-500"
                {...field}
                isChecked={field.value}
              >
                <Text textStyle="sm">
                  Write &quot;for remote deposit with Thread Bank&quot; on back of check
                </Text>
              </Checkbox>
              <BaselaneFormErrorMessage
                isInvalid={form.errors.writeOnBackOfCheck && form.touched.writeOnBackOfCheck}
              >
                {form.errors.writeOnBackOfCheck}
              </BaselaneFormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Box>

      <Box mt={2}>
        <Field name="signTheCheck">
          {({ field, form }) => (
            <FormControl
              isInvalid={form.errors.signTheCheck && form.touched.signTheCheck}
              isRequired
            >
              <Checkbox
                id="signTheCheck"
                className="primary-500"
                {...field}
                isChecked={field.value}
              >
                <Text textStyle="sm">Sign the check</Text>
              </Checkbox>
              <BaselaneFormErrorMessage
                isInvalid={form.errors.signTheCheck && form.touched.signTheCheck}
              >
                {form.errors.signTheCheck}
              </BaselaneFormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Box>
    </Box>
  );
};

export default SignAndEndorseCheck;
