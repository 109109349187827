import LogRocket from 'logrocket';
import type { UserCredential } from 'firebase/auth';
import { CREATE_USER_RETRY_COUNT_DEFAULT } from './constant.helpers';
import { identifyUser } from './tracking.helpers';
import { generateSessionCookie } from './firebase.helpers';

type AttemptToCreateUserProps = {
  firstName?: string,
  lastName?: string,
  credential: UserCredential,
  attemptsRemaining: Number,
  requestId: string,
  utmInputs: Object,
  location: Object,
  inviteCode: string,
  createUser: Function,
  setIsLoading: Function,
  onSuccess: Function,
  onQueryFail: Function,
  onLastAttemptFail: Function,
};

/**
 * Attempts to create a new user on the backend, and if server returns an error, will continue to retry if
 * there are still attempts left in the createAttemptsRemaining counter variable
 * @param {AttemptToCreateUserProps}  props
 * @param {string} props.firstName user's firstname (optional)
 * @param {string} props.lastName user's lastname (optional)
 * @param {UserCredential} props.credential the firebase credential that was generated via admin API
 * @param {Number} props.attemptsRemaining how many times to re-try should the user creation query fail
 * @param {Object} props.utmInputs the Universal Tracking Marker data that will be submitted with the new user
 * @param {Object} props.location the react-router object representing the current URL
 * @param {string} props.inviteCode email invite code for email verification
 * @param {Function} props.createUser the actual query function to execute
 * @param {Function} props.setIsLoading setter for any loader UI
 * @param {Function} props.onSuccess handler to call when user creation is successful
 * @param {Function} props.onQueryFail handler to call when a graphQL error occurs
 * @param {Function} props.onLastAttemptFail handler to call when user creation fails on the final attempt
 */

export const attemptToCreateUser = ({
  firstName,
  lastName,
  credential,
  attemptsRemaining,
  requestId,
  utmInputs,
  location,
  inviteCode,
  createUser,
  setIsLoading,
  onSuccess,
  onQueryFail,
  onLastAttemptFail,
}: AttemptToCreateUserProps) => {
  setIsLoading(true);
  credential.user.getIdToken().then((token) => {
    if (attemptsRemaining === CREATE_USER_RETRY_COUNT_DEFAULT) {
      // This ensures that only the first attempt makes an identify user call
      identifyUser(credential.user);
    }
    generateSessionCookie({
      token,
      handleSuccess: (sessionResponse) => {
        createUser({
          variables: {
            ...(firstName && { firstName }),
            ...(lastName && { lastName }),
            requestId,
            inviteCode,
            utmInputs,
          },
        })
          .then((response) => {
            if (response?.errors) {
              LogRocket.log(
                `CreateUser error: ${JSON.stringify(
                  response?.error
                )}, requestId:${requestId}, email: ${credential?.user?.email}`
              );
              if (attemptsRemaining > 0) {
                // try again if allowed
                attemptToCreateUser({
                  credential,
                  attemptsRemaining: attemptsRemaining - 1,
                  requestId,
                  utmInputs,
                  location,
                  inviteCode,
                  createUser,
                  onSuccess,
                  onQueryFail,
                  onLastAttemptFail,
                });
              } else {
                setIsLoading(false);
                onLastAttemptFail(response?.errors);
              }
            } else {
              onSuccess();
            }
          })
          .catch((error) => {
            onQueryFail(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      },
      handleError: (error) => {
        console.debug('generateSessionCookie error: ', { error });
        // TODO: Login should not progress from here once SessionCookie generation works
        // but if there is a legitimate error, this should show a generic error message for now.
      },
    });
  });
};
