// @flow
import React, { useContext } from 'react';
import { max } from 'lodash';
import { Box, Stack } from '@chakra-ui/react';
import { NoDataPlaceholder } from '@shared/components';
import CashFlowContext from '@contexts/CashFlowContext';
import { getMaxNumberBy } from '@core/utils/getNumber';
import transformToBarChartDataset from './services/transformToBarChartDataset';
import NewKPIs from './components/NewKPIs';
import CashFlowCompositeChart from './components/CashFlowCompositeChart';
import { barChartContainerStyles } from './styles/cashflowDataWrapper.styles';

type CashFlowDataWrapperProps = {
  handleSubFiltersUpdate: Function,
  onSelectMonth: Function,
  selectedMonth: string,
  hasNoData: boolean,
  lastPreset?: String,
  isDashboard?: boolean,
  totalUncategorizedUnhiddenTxCount?: Number,
};
function CashFlowDataWrapper({
  handleSubFiltersUpdate,
  onSelectMonth,
  selectedMonth,
  hasNoData,
  lastPreset,
  isDashboard,
  totalUncategorizedUnhiddenTxCount,
}: CashFlowDataWrapperProps): any {
  const { filters, cashFlowData, cashFlowDummyData } = useContext(CashFlowContext);
  const data = hasNoData ? cashFlowDummyData : cashFlowData;
  const cashFlow = isDashboard ? data?.cashFlow?.byMonth.slice(-6) : data?.cashFlow?.byMonth;
  const dataset = transformToBarChartDataset(cashFlow);

  let barChart = <NoDataPlaceholder small />;
  if (dataset) {
    const maxNumberRevenue = getMaxNumberBy(dataset, 'revenues');
    const maxNumberExpense = getMaxNumberBy(dataset, 'expenses');
    const maxNumberNetIncome = getMaxNumberBy(dataset, 'netIncome');
    const maxNumber = max([maxNumberRevenue, maxNumberExpense, maxNumberNetIncome]);

    // this eliminates the "ghost" date when picking "Last Month"
    let processedDataset = dataset;
    if (lastPreset === 'Last Month' && dataset.length > 1) {
      processedDataset = [dataset[0]];
    }

    barChart = (
      <CashFlowCompositeChart
        dataset={processedDataset}
        filters={filters}
        handleSubFiltersUpdate={handleSubFiltersUpdate}
        onSelectMonth={onSelectMonth}
        selectedMonth={selectedMonth}
        config={{
          values: {
            barOne: 'revenues',
            barTwo: 'expenses',
            lineOne: 'netIncome',
          },
        }}
        isZero={maxNumber === 0}
        maxNumber={maxNumber}
        dataPointsInViewport={6}
      />
    );
  }

  return (
    <Stack flex="1" px={isDashboard && hasNoData && '24px'}>
      <NewKPIs
        {...{
          filters,
          dataset,
          selectedMonth,
          isDashboard,
          hasNoData,
          totalUncategorizedUnhiddenTxCount,
        }}
      />
      <Box {...barChartContainerStyles(isDashboard)}>{barChart}</Box>
    </Stack>
  );
}

CashFlowDataWrapper.defaultProps = {
  lastPreset: null,
  isDashboard: false,
  totalUncategorizedUnhiddenTxCount: null,
};

export default CashFlowDataWrapper;
