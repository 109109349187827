import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { GET_HOLIDAYS } from '@core/apollo/queries';

const useCalendarExcludedDates = () => {
  const { data, loading } = useQuery(GET_HOLIDAYS);

  const excludedDates = useMemo(() => {
    return data?.holidays?.map((d) => {
      const day = DateTime.fromISO(d);
      const utcDate = day.toUTC();
      return new Date(utcDate.toISO());
    });
  }, [data]);

  const excludedDatesWithoutToday = useMemo(() => {
    const today = DateTime.now().startOf('day').toUTC().toISO();
    return excludedDates?.filter((date) => date.toISOString() !== today);
  }, [excludedDates]);

  return { excludedDates, excludedDatesWithoutToday, loading };
};

export default useCalendarExcludedDates;
