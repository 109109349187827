import React from 'react';

function IconX({ color, w, h }: { color?: string, w?: number, h?: number }) {
  return (
    <svg
      height={`${h}px`}
      width={`${w}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 10"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.528758.528514c.26035-.260349.682462-.260349.942812 0L5.00016 4.05711 8.52876.528514c.26035-.260349.68246-.260349.94281 0 .26035.26035.26035.682456 0 .942806l-3.5286 3.5286 3.5286 3.52859c.26035.26035.26035.68246 0 .94281-.26035.26035-.68246.26035-.94281 0l-3.5286-3.52859-3.52859 3.52859c-.26035.26035-.682462.26035-.942812 0-.260349-.26035-.260349-.68246 0-.94281L4.05735 4.99992.528758 1.47132c-.260349-.26035-.260349-.682456 0-.942806z"
        fill={color}
      />
    </svg>
  );
}

IconX.defaultProps = {
  color: 'currentColor',
  w: 10,
  h: 10,
};

export default IconX;
