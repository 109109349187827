import React, { useRef } from 'react';
import { Input, InputGroup, InputLeftElement, Stack } from '@chakra-ui/react';
import { IconSearch } from '@icons';
import BaselaneRadioCard from '../BaselaneRadioCard';
import BaselaneRadio from '../BaselaneRadio';
import { baselaneRadioCardListStyles } from './styles/baselaneRadioCardList.styles';

type BaselaneRadioCardListProps = {
  name: String,
  list: Array<Object>,
  selectedListItem: String,
  onListItemClick: Function,
  showValueBy: String,
  search: String,
  setSearch: Function,
  searchBy: String,
  showSearch: Boolean,
};

function BaselaneRadioCardList({
  name,
  list,
  selectedListItem,
  onListItemClick,
  showValueBy,
  search = '',
  setSearch = () => {},
  searchBy = showValueBy,
  showSearch = true,
}: BaselaneRadioCardListProps): any {
  const searchRef = useRef();

  const { listcontainer, list: listStyles, search: searchStyles } =
    baselaneRadioCardListStyles ?? {};

  return (
    <>
      {/* Search */}
      {showSearch && (
        <InputGroup>
          <InputLeftElement pointerEvents="none" w="48px" h="48px">
            <IconSearch w={16} h={16} color="#ACB8C7" />
          </InputLeftElement>
          <Input
            ref={searchRef}
            {...{ ...searchStyles, pl: '40px' }}
            placeholder="Search Property"
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
        </InputGroup>
      )}

      {/* List of Properties */}
      <Stack {...listcontainer}>
        {list
          ?.filter((item) => {
            return item[searchBy].toLowerCase().indexOf(search?.toLowerCase()) !== -1;
          })
          .map((item) => {
            return (
              item && (
                <BaselaneRadioCard
                  {...{ id: item.id, key: item.id, name, tabindex: '-1' }}
                  isChecked={selectedListItem === item?.id}
                  value={item?.id}
                  onClick={() => onListItemClick(item)}
                  containerStyles={{ m: '0 !important' }}
                  activeStyles={listStyles.card}
                  checkedStyles={listStyles.card}
                  focusedStyles={listStyles.card}
                  styles={{
                    ...listStyles.card,
                  }}
                  direction="row"
                >
                  <BaselaneRadio
                    value={item.id}
                    isChecked={selectedListItem === item?.id}
                    label={item[showValueBy]}
                  />
                </BaselaneRadioCard>
              )
            );
          })}
      </Stack>
    </>
  );
}

export default BaselaneRadioCardList;
