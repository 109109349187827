export const drawerHeaderStyle = {
  bg: 'brand.neutral.white',
  p: '8px 16px',
  color: 'brand.neutral.700',
  fontSize: 'lg',
  fontWeight: 'medium',
  lineHeight: '26px',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
};

export const drawerBodyStyle = {
  p: '0',
  bg: 'brand.neutral.white',
};

export const tabListStyles = {
  w: '280px',
  minW: '280px',
  h: 'calc(100% - 32px)',
};
