import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLazyQuery } from '@apollo/client';
import { Box, HStack, Input, Progress, Stack, Text, Flex } from '@chakra-ui/react';
import { ReactComponent as UploadIcon } from '@icons/32px/upload.svg';
import IconWarningCircleOutline from '@icons/legacy/IconWarningCircleOutline';
import { GET_DOCUMENT_UPLOAD_URL } from '@core/apollo/queries';
import formatBytes from '@core/utils/formatBytes';
import { body } from '@core/theme/text';
import UpdateFileNamePopup from './UpdateFileNamePopup';
import {
  checkIsAllowedFileName,
  checkIsAllowedFileNameLength,
  checkIsAllowedFileType,
  checkIsUniqueFileName,
  getValidationMessage,
} from './helpers/baselaneFileUploader.helpers';
import { fileUploaderStyles } from './styles/baselaneFileUploader.styles';

type BaselaneFileUploaderProps = {
  id?: String,
  dragAndDropId?: String,
  accept?: String,
  entityData: Object,
  allowedFileType: Object,
  documents?: Array,
  onChange?: Function,
  onClick?: Function,
  onDrop?: Function,
  onDragOver?: Function,
  onDragEnter?: Function,
  onDragLeave?: Function,
  onUploadSuccess?: Function,
  onUploadFail?: Function,
};

function BaselaneFileUploader({
  id,
  dragAndDropId,
  accept,
  entityData,
  allowedFileType,
  documents,
  onChange,
  onClick,
  onDrop,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onUploadSuccess,
  onUploadFail,
}: BaselaneFileUploaderProps): any {
  // Queries
  const [getDocumentUploadUrl] = useLazyQuery(GET_DOCUMENT_UPLOAD_URL, {
    fetchPolicy: 'network-only',
  });

  // Local State Vars
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [fileNameErrorMsg, setFileNameErrorMsg] = useState(null);
  const [isFileNameUpdateAlertOpen, setIsFileNameUpdateAlertOpen] = useState(false);

  // Local Vars
  let counter = 0;

  // Helper Func
  const handleCleanFiles = () => {
    const fileUploader = document.querySelector(`#${id}`);
    fileUploader.value = '';
  };

  const cleanUpAfterFileUpload = () => {
    handleCleanFiles();
    setSelectedFile(null);
    setFileName(null);
    setIsUploading(false);
  };

  const displayBEValidationMessage = (errors) => {
    Array.from(errors).forEach((e) => {
      const code = e.extensions.exception.response.messageCode;

      if (
        code === 'MAX_FILE_SIZE_ERROR' ||
        code === 'DUPLICATE_FILE_NAME' ||
        code === 'MAX_DOCUMENT_UPLOAD_LIMIT_REACHED'
      ) {
        const message = getValidationMessage({ errorType: code });
        setErrorMsg(message);
      } else {
        const message = getValidationMessage({ errorType: 'GENERIC' });
        setErrorMsg(message);
      }
    });
  };

  const handleUploadDocumentError = ({ docId, response }) => {
    const { ok, status, statusText, type, url: responseUrl, bodyUsed } = response ?? {};
    const message = getValidationMessage({ errorType: 'GENERIC' });
    setErrorMsg(message);
    onUploadFail({
      docId,
      failureReason: {
        status,
        ok,
        statusText,
        type,
        url: responseUrl,
        bodyUsed,
      },
    });
    cleanUpAfterFileUpload();
  };

  const getFileWithUpdatedName = ({ file, updatedFileName }) => {
    return new File([file], updatedFileName, {
      type: file.type,
    });
  };

  // Alert Helper Func
  const handleFileNameUpdateAlertOpen = () => setIsFileNameUpdateAlertOpen(true);
  const handleFileNameUpdateAlertClose = () => {
    handleCleanFiles();
    setSelectedFile(null);
    setFileNameErrorMsg(null);
    setIsFileNameUpdateAlertOpen(false);
  };

  // API Calls
  const getUploadUrl = async ({ file, fileSize }) => {
    let result = null;

    const { name: filename, type } = file;
    const fileExtension = type?.split('/')[1]?.toUpperCase();
    const { entityType, entityId, entityDate } = entityData ?? {};

    try {
      const response = await getDocumentUploadUrl({
        variables: {
          input: {
            filename,
            fileExtension,
            fileSize,
            entityType,
            entityId,
            entityDate,
          },
        },
      });

      if (response.errors?.length) {
        displayBEValidationMessage(response.errors);
        cleanUpAfterFileUpload();
      } else {
        result = response.data;
      }
    } catch (error) {
      const message = getValidationMessage({ errorType: 'GENERIC' });
      setErrorMsg(message);
      cleanUpAfterFileUpload();
    }

    return result;
  };

  const uploadDocument = async ({ docId, url, file, name }) => {
    let result = null;

    const { entityId } = entityData ?? {};

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/octet-stream',
          'Content-disposition': `attachment; filename=${entityId}_${name}`,
        },
        body: file,
      });

      if (response.status === 200) {
        result = response;
      } else {
        handleUploadDocumentError({ docId, response });
      }
    } catch (error) {
      handleUploadDocumentError({ docId, response: error });
    }

    return result;
  };

  // Handle Files and Call APIs
  const handleFile = async ({ file, fileSize }) => {
    setFileName(file.name);
    setIsUploading(true);

    const { generateDocumentUploadUrl } = (await getUploadUrl({ file, fileSize })) ?? {};
    const { id: docId, url, ...rest } = generateDocumentUploadUrl ?? {};

    if (url) {
      const uploadedDocument = await uploadDocument({ docId, url, file, name: rest?.name });
      if (uploadedDocument) {
        onUploadSuccess({ newDocument: { id: docId, ...rest } });
        cleanUpAfterFileUpload();
      }
    }
  };

  const processFile = (file) => {
    const { kiloBytes } = formatBytes(file.size);

    // If dropped item is not allowed type, reject it
    const isAllowedFileType = checkIsAllowedFileType({
      allowedFileType: allowedFileType.enum,
      selectedFileType: file.type,
    });
    if (!isAllowedFileType) {
      const message = getValidationMessage({
        errorType: 'INVALID_FILE_TYPE',
        allowedFileType,
      });
      setErrorMsg(message);
    }

    // if file looks good, call APIs
    if (isAllowedFileType) {
      setErrorMsg(null);
      setSelectedFile({ file, fileSize: kiloBytes });
      handleFileNameUpdateAlertOpen();
    }
  };

  const handleFiles = ({ files, isDroppedFile = false }) => {
    [...files].forEach((file) => {
      // If dropped item is not file, reject it
      if (isDroppedFile && file.kind === 'file') {
        const formattedFile = file.getAsFile();
        processFile(formattedFile);
      } else if (!isDroppedFile) {
        processFile(file);
      }
    });
  };

  const handleUploadFile = () => {
    // Update File Name to Inputted Name
    const { file, fileSize } = selectedFile ?? {};
    const inputtedFileName = document.querySelector('#file-name')?.value;
    const trimmedInputtedFileName = inputtedFileName.replace(/^\s+|\s+$/gm, '');

    const { name } = file ?? {};
    const indexOfDot = name?.lastIndexOf('.');
    const fileExtension = name?.slice(indexOfDot);

    const updatedFileName = trimmedInputtedFileName + fileExtension;

    // If dropped item name contains special chars not allowed, reject it
    const isAllowedFileNameLength = checkIsAllowedFileNameLength({ name: updatedFileName });
    const isAllowedFileName = checkIsAllowedFileName({ name: updatedFileName });
    if (!isAllowedFileNameLength) {
      const message = getValidationMessage({ errorType: 'EMPTY_FILE_NAME' });
      setFileNameErrorMsg(message);
    } else if (!isAllowedFileName) {
      const message = getValidationMessage({ errorType: 'INVALID_FILE_NAME' });
      setFileNameErrorMsg(message);
    }

    // If fileList has a file with the same name as dropped item, reject it
    const isUniqueFileName = checkIsUniqueFileName({ documents, name: updatedFileName });
    if (!isUniqueFileName) {
      const message = getValidationMessage({ errorType: 'DUPLICATE_FILE_NAME' });
      setFileNameErrorMsg(message);
    }

    // Call APIs
    if (isAllowedFileNameLength && isAllowedFileName && isUniqueFileName) {
      setFileNameErrorMsg(null);
      setIsFileNameUpdateAlertOpen(false);
      const updatedFile = getFileWithUpdatedName({ file, updatedFileName });
      handleFile({ file: updatedFile, fileSize });
    }
  };

  // Called when File is Selected
  const changeHandler = (ev) => {
    ev.preventDefault();

    const { files } = ev.target ?? {};

    if (files) {
      if (files.length > 1) {
        const message = getValidationMessage({ errorType: 'MAX_FILE_NUMBER_ERROR' });
        setErrorMsg(message);
      } else {
        handleFiles({ files });
      }
    }
  };

  // Called when Drag/Drop box is Clicked
  const clickHandler = (ev) => {
    ev.preventDefault();
    document.getElementById(id).click();
  };

  // Called when File is Dropped
  const dropHandler = async (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    const dropzone = document.querySelector(`#${dragAndDropId}`);
    dropzone?.classList?.remove('dragging');

    const data = ev.dataTransfer;

    if (data.items) {
      if (data.items.length > 1) {
        const message = getValidationMessage({ errorType: 'MAX_FILE_NUMBER_ERROR' });
        setErrorMsg(message);
      } else {
        handleFiles({ files: data.items, isDroppedFile: true });
      }
    }
  };

  const dragOverHandler = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  };

  const dragEnterHandler = (ev) => {
    ev.preventDefault();
    // eslint-disable-next-line no-plusplus, no-unused-vars
    counter++;
    const dropzone = document.querySelector(`#${dragAndDropId}`);
    dropzone?.classList?.add('dragging');
  };

  const dragLeaveHandler = (ev) => {
    ev.preventDefault();
    // eslint-disable-next-line no-plusplus, no-unused-vars
    counter--;
    if (counter === 0) {
      const dropzone = document.querySelector(`#${dragAndDropId}`);
      dropzone?.classList?.remove('dragging');
    }
  };

  const handleChange = onChange ?? changeHandler;
  const handleClick = onClick ?? clickHandler;
  const handleDrop = onDrop ?? dropHandler;
  const handleDragOver = onDragOver ?? dragOverHandler;
  const handleDragEnter = onDragEnter ?? dragEnterHandler;
  const handleDragLeave = onDragLeave ?? dragLeaveHandler;

  // Destructure Imported Styles
  const { input, dragAndDrop, error } = fileUploaderStyles ?? {};

  return (
    <>
      <Input
        type="file"
        {...{
          id,
          accept,
          onChange: (event) => {
            handleChange(event);
          },
          ...input({ dragAndDropId }),
        }}
      />

      {isUploading ? (
        <Box {...dragAndDrop.container({ isUploading })}>
          <Stack>
            <Text {...dragAndDrop.loading.filename}>{fileName}</Text>
            <Box {...dragAndDrop.loading.container}>
              <Progress
                size="xs"
                colorScheme="scheme.blue"
                isIndeterminate
                {...dragAndDrop.loading.bar}
              />
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box
          {...{ id: dragAndDropId, ...dragAndDrop.container({ isUploading }) }}
          onDrop={(event) => handleDrop(event)}
          onDragOver={(event) => handleDragOver(event)}
          onDragEnter={(event) => handleDragEnter(event)}
          onDragLeave={(event) => handleDragLeave(event)}
          onClick={(event) => handleClick(event)}
        >
          <HStack gap={1.5}>
            <UploadIcon />
            <Flex direction="column">
              {isMobile ? (
                <Text {...body.sm}>Take picture or upload file</Text>
              ) : (
                <Text {...body.sm}>
                  <Text as="u" color="brand.blue.800A">
                    Click to upload
                  </Text>{' '}
                  or drag file here
                </Text>
              )}

              <Text {...body.xs}>{`Supported file types: ${allowedFileType.text.join(', ')}`}</Text>
            </Flex>
          </HStack>
        </Box>
      )}

      {errorMsg && (
        <HStack {...error.container}>
          <Box>
            <IconWarningCircleOutline w={13.33} h={13.33} color="#C93A3A" />
          </Box>

          <Text {...error.msg}>{errorMsg}</Text>
        </HStack>
      )}

      <UpdateFileNamePopup
        {...{
          selectedFile,
          isFileNameUpdateAlertOpen,
          handleFileNameUpdateAlertClose,
          handleUploadFile,
          fileNameErrorMsg,
        }}
      />
    </>
  );
}

BaselaneFileUploader.defaultProps = {
  id: 'file',
  dragAndDropId: 'drop_zone',
  accept: 'image/jpeg,image/png,.pdf',
  documents: [],
  onChange: null,
  onClick: null,
  onDrop: null,
  onDragOver: null,
  onDragEnter: null,
  onDragLeave: null,
  onUploadSuccess: () => {},
  onUploadFail: () => {},
};

export default BaselaneFileUploader;
