export const editableInputDefaultStyles = {
  backgroundColor: 'brand.neutral.white',
  p: '10px 5px',
  _focus: {
    border: '1px',
    borderStyle: 'solid',
    borderRadius: '4px',
    borderColor: 'brand.neutral.500',
  },
};

export const editablePreviewDefaultStyles = {
  width: '100%',
  p: '10px 5px',
};

export const editablePreviewOnHoverStyles = {
  backgroundColor: 'brand.neutral.white',
};
