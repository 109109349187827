/**
 *
 * @param cache: Object
 * @param props: Object  { query: string, variables: object }
 * @returns Array of objects
 */
const readQuery = (cache, props) => cache.readQuery(props);

/**
 * @param cache: Object
 * @param props: Object { query: string, variables: object, data: array }
 * @returns void
 */
const writeQuery = (cache, props) => cache.writeQuery(props);

/**
 * This eviction tells the cache that the existing Query.transaction data can be safely ignored,
 * because you're handling its transformation yourself. If you do not evict the field, the cache has no way
 * of knowing the new data you are writing is a filtered version of the existing data, so it warns about potential
 * loss of the existing data.
 * https://github.com/apollographql/apollo-client/issues/6451
 * */
const cacheEvict = (cache, props) => {
  const { broadcast = 'false', fieldName, ...rest } = props;

  return cache.evict({
    fieldName,
    broadcast,
    ...rest,
  });
};

export { readQuery, writeQuery, cacheEvict };
