// @flow

import React from 'react';
import DatePicker from 'react-datepicker';
import { Box, Stack, Text } from '@chakra-ui/react';
import BaselaneRadioCard from '@shared/components/BaselaneRadioCard';
import { IconCalendar } from '@icons';
import { DATE_OPTIONS } from '../helpers/datepickerDropdown.helpers';
import {
  radioCardStyles,
  radioCardCheckedStyles,
  radioCardFocusedStyles,
  radioCardLabelStyles,
} from '../styles/datepickerDropdown.styles';
import {
  searchInputStyles,
  fakeHyphenStyles,
  datepickerStyles,
} from '../../../../DatepickerWithMaskedInput/styles/datepickerWithMaskedInput.styles';
import MaskedDateInput from '../../../../DatepickerWithMaskedInput/MaskedDateInput';

type DropdownWithNoHeaderProps = {
  hasFilterWrapper: Boolean,
  methods: Object,
  inputDate: Object,
  deferredSelectedDateOption: Object,
  selectedStagedDate: Object,
  selectedDateOption: Object,
  startInputRef: Object,
  endInputRef: Object,
  dateInputRef: Object,
  onDatePickerValueChange: Function,
  handleLabelClick: Function,
  handleApplyClick: Function,
};
const DropdownWithNoHeader = ({
  hasFilterWrapper,
  methods,
  inputDate,
  deferredSelectedDateOption,
  selectedStagedDate,
  selectedDateOption,
  startInputRef,
  endInputRef,
  dateInputRef,
  onDatePickerValueChange,
  handleLabelClick,
  handleApplyClick,
}: DropdownWithNoHeaderProps) => (
  <>
    <Stack direction="row" flexWrap="wrap" justifyContent="center">
      {DATE_OPTIONS.map((option) => {
        const { value, label, from, to, radioCardBoxStyles: containerStyles } = option;
        const isChecked =
          undefined === deferredSelectedDateOption
            ? value === selectedDateOption?.value
            : value === deferredSelectedDateOption?.value;
        return (
          <BaselaneRadioCard
            key={value}
            styles={radioCardStyles}
            focusedStyles={radioCardFocusedStyles}
            checkedStyles={radioCardCheckedStyles}
            isChecked={isChecked}
            onClick={() => {
              handleLabelClick(option, from, to);
            }}
            {...{ value, containerStyles }}
          >
            <Text {...radioCardLabelStyles(isChecked)}>{label}</Text>
          </BaselaneRadioCard>
        );
      })}
    </Stack>

    {/**
     * Note: the inputs are currently disabled, meaning they are read-only.
     * If manual input is required, then using a single input for both start and
     * end with an appropriate input mask will be a better solution that is compatible
     * with react-datepicker but will require a non-.
     */}
    <Stack direction="row" {...searchInputStyles}>
      <Box>
        <IconCalendar color="#ACB8C7" w={16} h={16} />
      </Box>
      <MaskedDateInput
        tabIndex="-1"
        disabled
        ref={startInputRef}
        dateValue={inputDate.start}
        name="dateInputStart"
      />
      <Box tabIndex="-1" {...fakeHyphenStyles(inputDate.start || inputDate.end)} />
      <MaskedDateInput
        tabIndex="-1"
        disabled
        ref={endInputRef}
        dateValue={inputDate.end}
        name="dateInputEnd"
      />
    </Stack>

    <Box {...datepickerStyles(hasFilterWrapper)}>
      <DatePicker
        ref={dateInputRef}
        placeholderText="Select date"
        dateFormat="MMM dd, yyyy"
        selected={
          // this ensures that the calendar doesn't jump to start
          // date when a used selects a date range
          selectedStagedDate.end && !deferredSelectedDateOption
            ? selectedStagedDate.end
            : selectedStagedDate.start
        }
        onChange={onDatePickerValueChange}
        startDate={selectedStagedDate.start}
        endDate={selectedStagedDate.end}
        selectsRange
        inline
        fixedHeight
        shouldCloseOnSelect={false}
      />
    </Box>
  </>
);

export default DropdownWithNoHeader;
