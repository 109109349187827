// @flow

import * as React from 'react';
import { Box } from '@chakra-ui/react';

type Props = { children: React.Node, padding?: string };

function BaselanePageBody({ children, padding }: Props): any {
  return <Box p={padding}>{children}</Box>;
}

BaselanePageBody.defaultProps = {
  padding: '24px 32px',
};

export default BaselanePageBody;
