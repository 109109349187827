import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Box, HStack, Text, Stack, useDisclosure, useToast, Heading } from '@chakra-ui/react';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { IconClock } from '@icons';
import {
  Icon16Sparkle,
  Icon16Accounts,
  Icon16LifetimeInterest,
  Icon16Dollar,
  Icon16Bolt,
} from '@icons/16px';
import { IllustrationBankingNewaccount } from '@illustrations';
import UserContext from '@contexts/UserContext';
import { BaselaneApplicationTypePopup, BaselaneButton, BaselaneCard } from '@shared/components';
import { HOME, LEASES, UNIFIED_LB_PERSONAL_INFO, UNIFIED_RENT_COLLECTION } from '@routes';
import historyStateStorage from '@core/storage/historyStateStorage';
import { GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import {
  cardBadgeTextStyles,
  cardSummaryStyles,
  cardTitleStyles,
  cardContainerStyles,
  iconContainerTopStyles,
  cardLinkBoxStyles,
  nonButtonCardBadgeContainerStyles,
} from '@shared/styles/typePopupButtons.styles';
import getBreakPoints from '@core/utils/getBreakPoints';

type LandlordBankingCardProps = {
  isFromUpdateAccount?: boolean,
  failedBankAccountId?: String,
  banksCount: Object,
};

const LandlordBankingCard = ({
  isFromUpdateAccount = false,
  failedBankAccountId = null,
  banksCount = {},
}: LandlordBankingCardProps) => {
  const { user } = useContext(UserContext);
  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const url = 'https://baselane.com/landlord-banking';

  const navigate = useNavigate();

  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding }) => window.open(unitOnBoarding, '_self'),
    onError: (kycUrlError) => console.error(kycUrlError),
  });

  const toast = useToast();
  const showToast = () =>
    toast({
      description: 'Your Rent Collection setup progress has been saved',
      status: 'success',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const handleActionButton = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const historyStateData = historyStateStorage.read('baselane-state');

    // if user opened lb flow from another flow from state will be an object
    const state = {
      from: {
        main: historyStateData?.from ?? HOME,
        flow: isFromUpdateAccount ? `${LEASES}` : UNIFIED_RENT_COLLECTION,
        params: isFromUpdateAccount ? { isFromUpdateAccount: true, failedBankAccountId } : null,
      },
    };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
      showToast();
    }
  };

  const handleOpenLandlordBankingPopup = () => {
    sendSegmentEvent(
      `${user.onboardingCompleted ? 'baselane' : 'onboarding'}_rc_click_connect_bank_account`,
      {
        landlord_uuid: user.id,
        landlord_name: user.firstName,
        landlord_email: user.email,
        bank_type: 'baselane',
        baselane_bank_account_count: banksCount.baselane,
      }
    );
    onBaselaneApplicationTypePopupOpen();
  };

  const { isMax576 } = getBreakPoints();

  const landLordBankingSummary = [
    {
      id: 1,
      text: 'Virtual accounts to keep your rent organized per property',
      icon: <Icon16Accounts />,
    },
    {
      id: 2,
      text: `Automated bookkeeping for all rent payments`,
      icon: <Icon16Sparkle />,
    },
    {
      id: 3,
      text: `Up to 4.19% APY on all deposits`,
      icon: <Icon16LifetimeInterest />,
    },
    {
      id: 4,
      text: 'No fees, unlimited free payments',
      icon: <Icon16Dollar />,
    },
  ];

  const cardLinkBoxModifiedStyles = { ...cardLinkBoxStyles, w: 'auto' };

  return (
    <>
      <BaselaneApplicationTypePopup
        handleActionButton={handleActionButton}
        isOpen={isBaselaneApplicationTypePopupOpen}
        onClose={onBaselaneApplicationTypePopupClose}
      />

      <BaselaneCard styles={cardContainerStyles}>
        <Box {...iconContainerTopStyles}>
          <IllustrationBankingNewaccount />
        </Box>
        <Stack flexDirection="column">
          <Box mb={3}>
            <Text {...cardTitleStyles}>Baselane Banking Account</Text>
            <Heading size="md" fontWeight="medium" mt={2}>
              Supercharge your rent collection
            </Heading>
            <HStack
              {...{ ...nonButtonCardBadgeContainerStyles, mt: -4, color: 'brand.neutral.700' }}
            >
              <IconClock w={12} h={12} />
              <Text {...cardBadgeTextStyles}>Only takes 2 minutes</Text>
            </HStack>
            {landLordBankingSummary.map((item, index) => (
              <React.Fragment key={`${item.id}-frag`}>
                {index === 2 && (
                  <Heading
                    key={`${item.id}-heading`}
                    size="md"
                    {...{ fontWeight: 'medium', mt: 2 }}
                  >
                    Rewarding and transparent banking
                  </Heading>
                )}
                <HStack key={item.id} marginTop={1.5}>
                  <Box
                    key={`${item.id}-icon`}
                    {...{ w: '16px', h: '16px', color: 'brand.neutral.700' }}
                  >
                    {item.icon}
                  </Box>
                  <Text key={`${item.id}-text`} {...cardSummaryStyles}>
                    {item.text}
                  </Text>
                </HStack>
              </React.Fragment>
            ))}
          </Box>
          <Stack
            {...(isMax576
              ? { flexDirection: 'column', alignItems: 'flex-end' }
              : { flexDirection: 'row', alignItems: 'flex-start' })}
            mt="0 !important"
            flex="1"
            w="full"
          >
            <Box textAlign="right" mr={isMax576 ? 0 : 3} w={isMax576 ? 'full' : 'auto'}>
              <BaselaneButton
                id="baselane-banking-button"
                size="md"
                variant="filled"
                palette="primary"
                w={isMax576 ? 'full' : 'auto'}
                leftIcon={<Icon16Bolt />}
                onClick={handleOpenLandlordBankingPopup}
              >
                Open banking account
              </BaselaneButton>
            </Box>

            <Box {...cardLinkBoxModifiedStyles} pr={2} w={isMax576 ? 'full' : 'auto'}>
              <BaselaneButton
                variant="transparent"
                palette="primary"
                onClick={() => {
                  window.open(url, '_blank', 'noopener');
                }}
              >
                Learn more
              </BaselaneButton>
            </Box>
          </Stack>
        </Stack>
      </BaselaneCard>
    </>
  );
};

LandlordBankingCard.defaultProps = {
  isFromUpdateAccount: false,
  failedBankAccountId: null,
};

export default LandlordBankingCard;
