import React from 'react';
import { DateTime } from 'luxon';
import { useFormikContext } from 'formik';
import { Skeleton } from '@chakra-ui/react';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import BaselaneFlowComplete from '@shared/components/BaselaneFlowComplete';
import TransferReferralSuccessCard from '@shared/components/TransferReferralSuccessCard';

const Success = () => {
  const { values } = useFormikContext();
  const { bankAccountsById, loading: activeBankAccountsAreLoading } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
  });
  const transferFromAccount = bankAccountsById[values.transferFromAccountId];
  const depositToAccount = bankAccountsById[values.depositToAccountId];
  const isOneTimeTransfer = values.transferType === 'one-time';
  const isRecurringTransfer = values.transferType === 'recurring';
  const isSameDay = isOneTimeTransfer
    ? DateTime.fromJSDate(values.oneTimeTransfer.date).hasSame(DateTime.now(), 'day')
    : false;
  const isInternalTransfer = !transferFromAccount?.isExternal && !depositToAccount?.isExternal;
  const isTransferOut = !transferFromAccount?.isExternal && depositToAccount?.isExternal;
  const isTransferIn = transferFromAccount?.isExternal && !depositToAccount?.isExternal;

  const resolveTexts = () => {
    // Same day transfer.
    if (isSameDay && isTransferIn) {
      return {
        title: 'Initiated transfer from external account',
        description1: 'The money should arrive in 3-5 business days.',
        description2: 'You can track the status on the Transfers & payments page.',
      };
    }
    if (isSameDay && isTransferOut) {
      return {
        title: 'Initiated transfer to external account',
        description1: 'The money should arrive in 3-5 business days.',
        description2: 'You can track the status on the Transfers & payments page.',
      };
    }
    if (isSameDay && isInternalTransfer) {
      return {
        title: 'Completed internal transfer',
        description1: 'The funds have been transferred between your Baselane Banking accounts.',
        description2: '',
      };
    }

    // One-time transfer.
    if (isOneTimeTransfer && isTransferIn) {
      return {
        title: 'Scheduled transfer from external account',
        description1: 'The money should arrive in 3-5 business days from the transfer date.',
        description2: 'You can track the status on the Transfers & payments page.',
      };
    }
    if (isOneTimeTransfer && isTransferOut) {
      return {
        title: 'Scheduled transfer to external account',
        description1: 'The money should arrive in 3-5 business days from the transfer date.',
        description2: 'You can track the status on the Transfers & payments page.',
      };
    }
    if (isOneTimeTransfer && isInternalTransfer) {
      return {
        title: 'Scheduled internal transfers',
        description1:
          'The funds will be transferred between your Baselane Banking accounts on the scheduled date.',
        description2: 'You can track the status on the Transfers & payments page.',
      };
    }

    // Recurring transfer.
    if (isRecurringTransfer && isTransferIn) {
      return {
        title: 'Scheduled recurring transfers from external account',
        description1: 'The money should arrive in 3-5 business days from the transfer date.',
        description2: 'You can track the status on the Transfers & payments page.',
      };
    }
    if (isRecurringTransfer && isTransferOut) {
      return {
        title: 'Scheduled recurring transfers to external account',
        description1: 'The money should arrive in 3-5 business days from the transfer date.',
        description2: 'You can track the status on the Transfers & payments page.',
      };
    }
    if (isRecurringTransfer && isInternalTransfer) {
      return {
        title: 'Scheduled recurring internal transfers',
        description1:
          'The funds will be transferred between your Baselane Banking accounts on the scheduled dates.',
        description2: 'You can track the status on the Transfers & payments page.',
      };
    }

    // General case. Should never happen.
    return {
      title: 'Your transfer is complete',
      description1: '',
      description2: 'You can track the status on the Transfers & payments page.',
    };
  };

  const { title, description1, description2 } = resolveTexts();

  return (
    <Skeleton isLoaded={!activeBankAccountsAreLoading} height="100%">
      <BaselaneFlowComplete
        type="success"
        title={title}
        description1={description1}
        description2={description2}
      >
        <TransferReferralSuccessCard source="transfer_between_my_accounts" mt={2} />
      </BaselaneFlowComplete>
    </Skeleton>
  );
};

export default Success;
