import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { BaselaneButton, BaselaneCard, BaselaneDivider } from '@shared/components';
import { cardSkeletonStyles } from '@shared/components/BaselaneCard/styles/card.style';
import * as ROUTES from '@routes';
import { useBanks } from '@core/components/Shared/hooks';
import BankBalancesSummary from './BankBalancesSummary';
import BankBalancesList from './BankBalancesList';
import BankBalancesGraph from './BankBalancesGraph';
import type { Bank } from './types';
import { bankBalancesWidget } from './styles/responsive.style';

function BankBalances() {
  const { accountsLoading: loading, accountsError: error, accountsData: data } = useBanks({
    variables: {
      isConnectedBank: true,
      isConnectedAccount: true,
      accountType: 'depository',
      isImporting: true,
    },
    fetchPolicy: 'no-cache',
  });
  if (loading || error) return null;
  const banks: Bank[] = data ? [...data.bank] : [];
  const hasBankAccounts = banks.filter((b) => b.bankAccounts.length > 0);
  const hasNoData = hasBankAccounts.length === 0;

  return (
    <Box {...bankBalancesWidget(isMobile)}>
      {hasNoData && (
        <BaselaneCard styles={{ ...cardSkeletonStyles.container }}>
          <Text {...cardSkeletonStyles.title}>View Your Property Banking in One Place</Text>
          <Text {...cardSkeletonStyles.description}>
            Open a Baselane Banking account built for landlords or connect your external bank
            accounts to view your balances in one place.
          </Text>
          <Box>
            <RouterLink to={{ pathname: ROUTES.NATIVE_BANK }}>
              <BaselaneButton variant="filled" palette="primary">
                Add Account
              </BaselaneButton>
            </RouterLink>
          </Box>
        </BaselaneCard>
      )}
      <BankBalancesSummary hasNoData={hasNoData} />
      {!isMobile && (
        <Box>
          <BankBalancesGraph hasNoData={hasNoData} />
          <BaselaneDivider styles={{ m: '32px 0' }} />
          <Box px="24px">
            <BankBalancesList banks={banks} hasNoData={hasNoData} />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default BankBalances;
