import React, { useContext, useRef, useState } from 'react';
import {
  Box,
  HStack,
  Text,
  Stack,
  Heading,
  VStack,
  FormControl,
  useDisclosure,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Icon12Error } from '@icons/12px';
import { Icon16Dollar, Icon16Plus, Icon16PlusCircle } from '@icons/16px';

import {
  IllustrationBankingNewaccount,
  IllustrationBankingConnectExternalAccountWithPlaid,
} from '@illustrations';

import getBreakPoints from '@core/utils/getBreakPoints';
import { Highlighted } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import BanksContext from '@contexts/BanksContext';
import { UNIFIED_LB_PERSONAL_INFO } from '@core/constants/routes';
import historyStateStorage from '@core/storage/historyStateStorage';
import FormLabelWithTooltip from '@pages/LeasesPage/LeaseSection/components/FormLabelWithTooltip';

import { iconContainerTopStyles } from '../../styles/typePopupButtons.styles';
import BaselaneButton from '../BaselaneButton';
import T1WithTitle from '../BaselaneDropdown-new/T1-Title';
import { BaselaneCardNew } from '../BaselaneCard-new';
import BaselaneApplicationTypePopup from '../BaselaneApplicationTypePopup';
import BaselaneExternalBankingTypePopup from '../BaselaneExternalBankingTypePopup';
import AddAccountManuallyDrawer from '../AddAccountManuallyDrawer';
import { defaultComponentCopy } from './helpers/componentCopy';
import { getAccountsForDropDown } from './helpers/baselaneReceivingAccounts.helpers';

import {
  menuItemTextStyles,
  menuItemSubAccountStyles,
  cardDisabledStyles,
} from './styles/receivingAccounts.style';

type Item = {
  id: String,
  bankName: String,
  account: String,
  // needed below props for an error state
  connectionState?: 'CONNECTED' | 'ITEM_LOGIN_REQUIRED' | null,
  stripeBankAccountId?: String,
  bankAccountMetadata?: {
    payoutFailedReason: {
      failureCode: String,
      failureMessage: String,
    },
    lastPayoutFailed: Boolean,
  },
};

type Account = {
  id: String,
  items: Array<Item>,
  title: String,
};

type Props = {
  isFromResponsiveMode: Boolean,
  accounts: Array<Account>,
  selectedAccount?: Item,
  isBaselaneAccountDisabled?: Boolean,
  isExternalAccountDisabled?: Boolean,
  refetchBankAccounts?: Function,
  hideExternalAccounts?: Boolean,
  hideSavingsAccounts?: Boolean,
  // event handlers
  onSelectAccount?: Function,
  onActionButtonClick?: Function,
  onAddConnectedAccountSuccess?: Function,
  onAddManualAccountSuccess?: Function,
  from?: String,
  section?: String,
  errorMessage?: String,
  hideHeading?: Boolean,
  hideButtonAndCards?: Boolean,
  // copy overrides
  heading?: String,
  formLabel?: String,
  tooltipText?: String,
  baselaneAccountHeading?: String,
  baselaneAccountDetails?: Array<Any>,
  baselaneAccountButtonLabel?: String,
  baselaneAccountButtonSiblings?: FunctionComponent,
  connectedAccountHeading?: String,
  connectedAccountDetails?: String,
  connectedAccountButtonLabel?: String,
};

const BaselaneReceivingAccounts = ({
  isFromResponsiveMode,
  accounts,
  selectedAccount,
  isBaselaneAccountDisabled,
  isExternalAccountDisabled,
  refetchBankAccounts,
  hideExternalAccounts,
  hideSavingsAccounts,
  onSelectAccount,
  onActionButtonClick,
  onAddConnectedAccountSuccess,
  onAddManualAccountSuccess,
  from,
  section,
  errorMessage,
  hideHeading,
  hideButtonAndCards,
  heading,
  formLabel,
  tooltipText,
  baselaneAccountHeading,
  baselaneAccountDetails,
  baselaneAccountButtonLabel,
  baselaneAccountButtonSiblings,
  connectedAccountHeading,
  connectedAccountDetails,
  connectedAccountButtonLabel,
}: Props) => {
  const addExternalAccountDrawerRef = useRef();
  const addAccountManuallyDrawerRef = useRef();

  const [showAllAccountTypes, setShowAllAccountTypes] = useState(false);

  const { getKYCUrl } = useContext(BanksContext);

  const navigate = useNavigate();

  const hasBaselaneAccount = accounts.reduce(
    (prev, current) => prev || current.id === 'baselane_account',
    false
  );

  const hasExternalAccount = accounts.reduce(
    (prev, current) => prev || current.id === 'external_account',
    false
  );

  const formattedAccounts = getAccountsForDropDown({
    accounts,
    hideExternalAccounts,
    hideSavingsAccounts,
  });

  const showAddAccountButtonAndDropdown = hideExternalAccounts
    ? hasBaselaneAccount
    : hasBaselaneAccount || hasExternalAccount;

  const hasOnlyExternalAccount = hasExternalAccount && !hasBaselaneAccount;

  const {
    isOpen: isBaselaneAccountPopupOpen,
    onOpen: onBaselaneAccountPopupOpen,
    onClose: onBaselaneAccountPopupClose,
  } = useDisclosure();

  const {
    isOpen: isExternalAccountPopupOpen,
    onOpen: onExternalAccountPopupOpen,
    onClose: onExternalAccountPopupClose,
  } = useDisclosure();

  const { isMax768 } = getBreakPoints();

  const handleOpenBaselaneBankingPopup = () => {
    onBaselaneAccountPopupOpen();
  };

  const handleCloseBaselaneBankingPopup = () => {
    onBaselaneAccountPopupClose();
    setShowAllAccountTypes(false);
  };

  const handleOpenExternalBankingPopup = () => {
    onExternalAccountPopupOpen();
  };

  const handleCloseExternalAccountPopup = () => {
    onExternalAccountPopupClose();
    setShowAllAccountTypes(false);
  };

  const handleDropdownSubmit = (selected) => {
    onSelectAccount(selected);
  };

  const handleAddAccountManuallySuccess = (account) => {
    onAddManualAccountSuccess(account);
    refetchBankAccounts();
  };

  const handleAddConnectedAccountSuccess = (account) => {
    onAddConnectedAccountSuccess(account);
  };

  const handleActionButton = (type) => {
    onActionButtonClick(type);
    switch (type) {
      case 'manual':
        handleCloseExternalAccountPopup();
        addAccountManuallyDrawerRef?.current?.open();
        break;
      case 'business':
        historyStateStorage.write({ from });
        getKYCUrl();
        break;
      case 'sole':
        historyStateStorage.write({ from });
        navigate(UNIFIED_LB_PERSONAL_INFO);
        break;
      default:
        break;
    }
  };

  const handleAddAccountButtonClick = () => {
    if (hideExternalAccounts) {
      handleOpenBaselaneBankingPopup();
    } else if (hasOnlyExternalAccount) {
      handleOpenBaselaneBankingPopup();
    } else {
      setShowAllAccountTypes(true);
    }
  };

  const componentCopy = {
    heading:
      heading ||
      (hideExternalAccounts || !hasBaselaneAccount
        ? defaultComponentCopy?.hideExternalAccounts?.heading
        : defaultComponentCopy?.heading),
    formLabel: formLabel || defaultComponentCopy?.formLabel,
    tooltipText: tooltipText || defaultComponentCopy?.tooltipText,
    baselaneAccountHeading: baselaneAccountHeading || defaultComponentCopy?.baselaneAccountHeading,
    baselaneAccountDetails: baselaneAccountDetails || defaultComponentCopy?.baselaneAccountDetails,
    baselaneAccountButtonLabel:
      baselaneAccountButtonLabel || defaultComponentCopy?.baselaneAccountButtonLabel,
    connectedAccountHeading:
      connectedAccountHeading || defaultComponentCopy?.connectedAccountHeading,
    connectedAccountDetails:
      connectedAccountDetails || defaultComponentCopy?.connectedAccountDetails,
    connectedAccountButtonLabel:
      connectedAccountButtonLabel || defaultComponentCopy?.connectedAccountButtonLabel,
  };

  const handleLinkedAccountsDrawerClose = (isClosed) => {
    // for some reason this function will be called BEFORE drawer closes so adding check
    if (isClosed) {
      handleCloseExternalAccountPopup();
    }
  };

  const renderAccountDropdownItem = ({ item, search }) => {
    return (
      <Stack gap={0}>
        <Text {...menuItemTextStyles} color="inherit">
          <Highlighted text={item?.bankName} highlight={search} />
        </Text>
        <Text {...menuItemSubAccountStyles}>
          <Highlighted text={item?.account} highlight={search} />
        </Text>
      </Stack>
    );
  };

  return (
    <>
      <BaselaneApplicationTypePopup
        handleActionButton={handleActionButton}
        isOpen={isBaselaneAccountPopupOpen}
        onClose={handleCloseBaselaneBankingPopup}
      />
      <BaselaneExternalBankingTypePopup
        handleActionButton={handleActionButton}
        isOpen={isExternalAccountPopupOpen}
        onClose={handleCloseExternalAccountPopup}
        linkedAccountsDrawerOnClose={handleLinkedAccountsDrawerClose}
        addAccountProps={{
          isFromLease: true,
          isDirectToPlaid: true,
          hideButton: true,
          handleAddAccountSuccessCallback: handleAddConnectedAccountSuccess,
          refetchLeaseBankAccounts: refetchBankAccounts,
          addExternalAccountDrawerRef,
        }}
      />
      <AddAccountManuallyDrawer
        {...{
          addAccountManuallyDrawerRef,
          handleAddAccountManuallySuccess,
          from: { section: section ?? from },
        }}
      />
      <VStack alignItems="flex-start" gap={2}>
        {!hideHeading && (
          <Text textStyle="sm" fontWeight="medium">
            {componentCopy?.heading}
          </Text>
        )}
        {accounts?.length > 0 && showAddAccountButtonAndDropdown && (
          <FormControl mb={0} isInvalid={errorMessage} w="100%">
            <FormLabelWithTooltip
              htmlFor="account-dropdown"
              text={componentCopy?.formLabel}
              tooltipText={componentCopy?.tooltipText}
            />
            <T1WithTitle
              additionalProps={{ id: 'account-dropdown' }}
              classNames={[
                'new',
                'input-form-md',
                'auto-width-dropdown',
                'select-auto-width',
                'account',
              ]}
              data={formattedAccounts}
              selectedItem={selectedAccount}
              searchTerm={['account', 'bankName']}
              showValueByFields={['bankName', 'account']}
              itemRenderer={renderAccountDropdownItem}
              handleSubmit={handleDropdownSubmit}
              isMulti={false}
              hasFilterWrapper={false}
              hasCheckboxes={false}
              showDivider={false}
              showTitleInSearch={false}
              hideClearButton
              parentId={isFromResponsiveMode ? 'drawer-body' : 'page-feature'}
              placeholder="Select account"
              title="account"
              hasError={errorMessage}
              dropdownPosition="bottom"
              isMobile={isMax768}
            />
            <FormErrorMessage ml={0}>
              <Box as="span" mr={0.5}>
                <Icon12Error />
              </Box>
              {errorMessage}
            </FormErrorMessage>
          </FormControl>
        )}

        {!hideButtonAndCards && (
          <>
            {(!hasBaselaneAccount || showAllAccountTypes) && (
              <BaselaneCardNew
                size="lg"
                {...{ w: 'full', ...cardDisabledStyles(isBaselaneAccountDisabled) }}
              >
                <HStack>
                  <VStack {...iconContainerTopStyles}>
                    <IllustrationBankingNewaccount />
                  </VStack>
                  <VStack alignItems="flex-start" justifyContent="flex-start">
                    <Heading size="headline-md" fontWeight="medium">
                      {componentCopy?.baselaneAccountHeading}
                    </Heading>
                    {componentCopy?.baselaneAccountDetails?.map((item, index) => (
                      <React.Fragment key={`${item.id}-frag`}>
                        <HStack key={item.id} alignItems="flex-start">
                          <Box
                            key={`${item.id}-icon`}
                            {...{ w: '16px', h: '16px', color: 'brand.neutral.700' }}
                          >
                            {item.icon}
                          </Box>
                          <Text key={`${item.id}-text`} textStyle="sm">
                            {item.text}
                          </Text>
                        </HStack>
                      </React.Fragment>
                    ))}
                    <HStack h="100%" alignItems="center" mt={3} flexWrap="wrap">
                      <BaselaneButton
                        id="baselane-banking-button"
                        size="lg"
                        variant="filled"
                        palette="primary"
                        w={isMax768 ? 'full' : 'auto'}
                        leftIcon={<Icon16Plus />}
                        onClick={handleOpenBaselaneBankingPopup}
                        isDisabled={isBaselaneAccountDisabled}
                        mr={1}
                      >
                        {componentCopy?.baselaneAccountButtonLabel}
                      </BaselaneButton>
                      {baselaneAccountButtonSiblings}
                    </HStack>
                  </VStack>
                </HStack>
              </BaselaneCardNew>
            )}
            {((!hasExternalAccount && !hasBaselaneAccount && !hideExternalAccounts) ||
              showAllAccountTypes) && (
              <BaselaneCardNew
                size="lg"
                {...{ w: 'full', ...cardDisabledStyles(isBaselaneAccountDisabled) }}
              >
                <HStack>
                  <VStack {...iconContainerTopStyles}>
                    <IllustrationBankingConnectExternalAccountWithPlaid />
                  </VStack>
                  <VStack alignItems="flex-start">
                    <Heading size="headline-md" fontWeight="medium">
                      {componentCopy?.connectedAccountHeading}
                    </Heading>
                    <HStack alignItems="flex-start">
                      <Box
                        {...{
                          w: '16px',
                          h: '16px',
                          color: 'brand.neutral.700',
                        }}
                      >
                        <Icon16Dollar />
                      </Box>
                      <Text textStyle="sm">{componentCopy?.connectedAccountDetails}</Text>
                    </HStack>
                    <BaselaneButton
                      variant="tonal"
                      size="md"
                      palette="primary"
                      mt={2}
                      id="external-account-button"
                      onClick={handleOpenExternalBankingPopup}
                      isDisabled={isExternalAccountDisabled}
                    >
                      {componentCopy?.connectedAccountButtonLabel}
                    </BaselaneButton>
                  </VStack>
                </HStack>
              </BaselaneCardNew>
            )}
            {showAddAccountButtonAndDropdown && !showAllAccountTypes && (
              <BaselaneButton
                leftIcon={<Icon16PlusCircle />}
                variant="transparent"
                palette="primary"
                size="sm"
                onClick={
                  hasOnlyExternalAccount
                    ? handleOpenExternalBankingPopup
                    : handleAddAccountButtonClick
                }
              >
                {hasOnlyExternalAccount ? 'Connect an external account' : 'Add a new account'}
              </BaselaneButton>
            )}
          </>
        )}
      </VStack>
    </>
  );
};

BaselaneReceivingAccounts.defaultProps = {
  selectedAccount: null,
  isBaselaneAccountDisabled: false,
  isExternalAccountDisabled: false,
  refetchBankAccounts: () => {},
  hideExternalAccounts: false,
  hideSavingsAccounts: true,
  onSelectAccount: () => {},
  onActionButtonClick: () => {},
  onAddConnectedAccountSuccess: () => {},
  onAddManualAccountSuccess: () => {},
  from: '',
  section: null,
  errorMessage: null,
  heading: null,
  hideHeading: false,
  hideButtonAndCards: false,
  formLabel: null,
  tooltipText: null,
  baselaneAccountHeading: null,
  baselaneAccountDetails: null,
  baselaneAccountButtonLabel: null,
  baselaneAccountButtonSiblings: null,
  connectedAccountHeading: null,
  connectedAccountDetails: null,
  connectedAccountButtonLabel: null,
};

export default BaselaneReceivingAccounts;
