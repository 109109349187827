// @flow
import React, { forwardRef, useState } from 'react';
import { IconButton, Link } from '@chakra-ui/react';
import { LocationDescriptor, Link as RouterLink } from 'react-router-dom';

import {
  buttonThemeGenerator,
  getIconSpacingValue,
} from '@core/theme/component/Button/buttonStyleHelpers';

import BaselaneConditionalTooltip from '../BaselaneConditionalTooltip';

import { iconLinkStyles } from './styles/buttonIcon.style';

type BaselaneButtonIconProps = {
  variant: string,
  palette: string,
  styles: Object,
  iconStyles?: Object,
  icon: any,
  isDisabled: boolean,
  onChange: Function,
  onClick: Function,
  onBlur: Function,
  to?: LocationDescriptor,
  size: string,
  isActive: boolean,
  value: any,
  tooltipText?: string,
};

const BaselaneButtonIcon: any = forwardRef(function BaselaneButtonIconElement(
  {
    variant,
    palette,
    styles,
    iconStyles,
    icon,
    isDisabled,
    onChange,
    onClick,
    onBlur,
    to,
    size,
    isActive,
    value,
    tooltipText,
    ...rest
  }: BaselaneButtonIconProps,
  ref: any
): any {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const hasTooltip = tooltipText?.length;

  // NOTE: If a "to" LocationDescriptor is provided, the icon will be generated
  // within a routerlink instead of a Button.

  const linkIconStyles = {
    ...{
      w: variant?.includes('primary') ? '21px' : '18px',
      h: variant?.includes('primary') ? '21px' : '18px',
      display: 'flex-item',
    },
    ...iconStyles,
  };
  const LinkIcon = icon;

  if (to) {
    return (
      <Link {...{ ...iconLinkStyles, ...styles }} as={RouterLink} to={to}>
        <LinkIcon {...linkIconStyles} />
      </Link>
    );
  }

  const clickHandler = (e) => {
    if (!isDisabled) {
      onClick?.(e);
    } else if (hasTooltip) {
      setIsTooltipOpen((prev) => !prev);
    }
  };

  const blurHandler = (e) => {
    if (!isDisabled) {
      onBlur?.(e);
    } else if (hasTooltip) {
      setIsTooltipOpen(false);
    }
  };

  // uses standard disabled html attribute when no tooltip,
  // if tooltip provided, uses aria-disabled
  const disabled = {
    ...(hasTooltip ? { [`aria-disabled`]: isDisabled } : { isDisabled }),
  };
  return (
    <BaselaneConditionalTooltip
      condition={tooltipText}
      tooltipText={tooltipText}
      isOpen={isTooltipOpen}
    >
      <IconButton
        {...{
          variant,
          palette,
          icon,
          ...disabled,
          ref,
          onChange,
          onClick: clickHandler,
          onBlur: blurHandler,
          size,
          isActive,
          value,
          ...rest,
        }}
        {...getIconSpacingValue(size)}
        {...buttonThemeGenerator(variant, palette)}
        {...{ ...styles, ...iconStyles }}
      />
    </BaselaneConditionalTooltip>
  );
});

BaselaneButtonIcon.defaultProps = {
  to: null,
  iconStyles: {},
  tooltipText: null,
};

export { BaselaneButtonIcon };
