import React from 'react';
import { Image, Stack, Text } from '@chakra-ui/react';
import { IconLink, IconLock } from '@icons';

import linkingBankAccount from '../../assets/bookkeeping-link-accounts.svg';
import { containerStyles, textContainerStyles } from '../styles/emptyState.styles';

const EmptyState = () => {
  return (
    <Stack {...containerStyles}>
      <Image src={linkingBankAccount} alt="link bank account" h="250px" w="264px" />
      <Stack direction="row" {...textContainerStyles}>
        <Stack justify="center">
          <IconLink />
        </Stack>
        <Text>Link any external bank account to automatically track income & expenses</Text>
      </Stack>
      <Stack direction="row" {...textContainerStyles}>
        <Stack justify="center">
          <IconLock w={15} h={15} />
        </Stack>
        <Text>Your information is protected with 256-bit SSL encryption</Text>
      </Stack>
    </Stack>
  );
};
export default EmptyState;
