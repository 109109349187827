/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Box,
  Spacer,
  Skeleton,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import formatCurrency from '@core/utils/formatCurrency';
import useGenerateRecurringPayment from './useGenerateRecurringPayment';
import buildGenerateRecurringPatternInput from './buildGenerateRecurringPatternInput';
import UpcomingTransfersList from './UpcomingTransfersList';

const UpcomingTransfersAccordion = ({ subheader, ...rest }) => {
  const { values, errors } = useFormikContext();
  const thereAreValidationErrors = errors?.recurringPayment;
  const {
    generateRecurringPayment,
    loading: generateRecurringPaymentIsLoading,
  } = useGenerateRecurringPayment();
  const [recurringPattern, setRecurringPattern] = useState(null);
  const [debouncedValues, setDebouncedValues] = useState(values);
  // Because on debounced upcoming payments calculation, component for a couple of seconds shows previous data. We nee this to show loading state.
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const generateRecurringPatternInput = useMemo(
    () => buildGenerateRecurringPatternInput(debouncedValues),
    [debouncedValues]
  );
  const amount = values.amount ? parseFloat(values.amount.replace(/,/g, '')) : 0;
  const untilUserCancels = values.recurringTransfer?.endBy === 'manual';

  // The values in form in some cases are changing too fast (e.g. when user types in the amount field).
  // We need to debounce the values to avoid unnecessary API calls.
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValues(values);
    }, 660);

    return () => {
      clearTimeout(timeout);
    };
  }, [values]);

  useEffect(() => {
    if (!isFakeLoading) {
      setIsFakeLoading(true);
    }
  }, [values.recurringTransfer.endBy]);

  useEffect(() => {
    if (thereAreValidationErrors) {
      return;
    }
    generateRecurringPayment({
      variables: {
        input: generateRecurringPatternInput,
      },
    })
      .then((response) => {
        setRecurringPattern(response?.data?.generateRecurringPayment ?? null);
      })
      .finally(() => {
        setIsFakeLoading(false);
      });
  }, [generateRecurringPayment, generateRecurringPatternInput]);

  useEffect(() => {
    const event = new CustomEvent('recurring-transfer', {
      detail: {
        upcomingTransfersLength: recurringPattern?.transfers?.length,
        generateRecurringPaymentIsLoading,
      },
    });

    document.dispatchEvent(event);
  }, [recurringPattern, generateRecurringPaymentIsLoading]);

  const numberOfUpcomingTransfers = recurringPattern?.transfers?.filter(
    (transfer) => transfer.state === 'UPCOMING'
  ).length;

  const thereAreNoResults =
    Array.isArray(recurringPattern?.transfers) && numberOfUpcomingTransfers === 0;

  return (
    <Accordion allowToggle {...rest}>
      <AccordionItem border={0}>
        <AccordionButton p={0} _hover={{ bg: 'transparent', width: '100%' }}>
          <Box display="flex" flexDirection="column" width="100%">
            {/* Accordion header */}
            <Box display="flex" py={1} alignItems="center">
              <Skeleton isLoaded={!generateRecurringPaymentIsLoading && !isFakeLoading}>
                <Text
                  textStyle="sm-heavy"
                  textAlign="left"
                  color={thereAreNoResults ? 'red.800AA' : 'inherit'}
                >
                  {untilUserCancels
                    ? 'Upcoming transfers'
                    : `${numberOfUpcomingTransfers} upcoming transfers`}
                </Text>
              </Skeleton>
              <Skeleton isLoaded={!generateRecurringPaymentIsLoading && !isFakeLoading} ml={1}>
                <AccordionIcon
                  ml={1}
                  height="16px"
                  width="16px"
                  color={thereAreNoResults ? 'red.800AA' : 'inherit'}
                />
              </Skeleton>
              <Spacer />
              <Skeleton isLoaded={!generateRecurringPaymentIsLoading && !isFakeLoading}>
                {untilUserCancels ? (
                  <Box display="flex">
                    <Text
                      height="18px"
                      width="18px"
                      color={thereAreNoResults ? 'red.800AA' : 'inherit'}
                      textStyle="sm-heavy"
                    >
                      ♾️
                    </Text>
                  </Box>
                ) : (
                  <Text
                    color={thereAreNoResults ? 'red.800AA' : 'inherit'}
                    textStyle="md-heavy"
                    textAlign="right"
                  >
                    {formatCurrency(numberOfUpcomingTransfers * amount).inDollars}
                  </Text>
                )}
              </Skeleton>
            </Box>
            {/* Accordion subheader */}
            {subheader ? (
              <Text textAlign="left" textStyle="xs">
                {subheader}
              </Text>
            ) : null}
          </Box>
        </AccordionButton>
        <AccordionPanel p="16px 0" display="flex" flexDirection="column" gap={1}>
          <UpcomingTransfersList recurringPattern={recurringPattern} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default UpcomingTransfersAccordion;
