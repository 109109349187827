import React from 'react';
import { isMobile } from 'react-device-detect';
import { FormControl, FormLabel, FormErrorMessage, Box, Select } from '@chakra-ui/react';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import { fieldContainer } from '../../styles/shared.styles';

type ExistingTenantSelectProps = {
  tenantProfileId: string,
  handleTenantIdChange: Function,
  existingTenants: Array,
  existingTenantState: Object,
};

function ExistingTenantSelect({
  tenantProfileId,
  handleTenantIdChange,
  existingTenants,
  existingTenantState,
}: ExistingTenantSelectProps) {
  const id = tenantProfileId ?? '';

  return (
    <FormControl
      {...fieldContainer}
      width={isMobile ? '100%' : '50%'}
      isInvalid={existingTenantState.changeTab && existingTenantState.isInvalid}
    >
      <FormLabel htmlFor="existingTenant" {...formLabelStyles.xs}>
        {isMobile ? 'Choose an Existing Tenant ' : 'Existing tenant'}
      </FormLabel>
      {/* TODO: to be replaced with BaselaneDropdown */}
      <Select {...formInputStyles} width="100%" p="0" value={id} onChange={handleTenantIdChange}>
        <option>Select Tenant</option>
        {existingTenants.map((o) => (
          <option key={o.id} value={o.id} defaultValue={id}>
            {o.firstName}&nbsp;{o.lastName}
          </option>
        ))}
      </Select>
      <FormErrorMessage {...formErrorStyles}>
        <Box as="span">Please select an existing tenant</Box>
      </FormErrorMessage>
    </FormControl>
  );
}

export default ExistingTenantSelect;
