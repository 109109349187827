const borderColor = 'brand.darkBlue.250';

export const defaultOuterContainerStyles = {
  border: 'solid 1px',
  borderColor,
  p: { base: '12px', md: '16px' },
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '8px',
  mb: '12px',
  flexDirection: { base: 'column', md: 'row' },
};

export const innerContainerStyles = {
  position: 'relative',
  w: '100%',
  justify: 'space-between',
  alignItems: { base: 'flex-start', md: 'center' },
  flexDirection: { base: 'column', md: 'row' },
};

export const contentContainerStyles = {
  alignSelf: 'flex-start',
  flexDirection: { base: 'column', md: 'row' },
  mb: { base: '0', md: '0' },
};

export const iconContainerStyles = {
  flex: '0',
  alignItems: { base: 'flex-start', md: 'center' },
  justify: 'center',
};

export const textContainerStyles = {
  alignItems: 'flex-start',
  gap: 0,
  mt: { base: '10px !important', md: '0px !important' },
  ml: { base: '0px !important', md: '12px !important' },
};

export const buttonContainerStyles = {
  alignItems: 'center',
  ml: 'auto',
  mr: '20px',
  pl: { base: '0px', md: '12px' },
  mt: { base: '12px !important', md: '0 !important' },
};

export const textStyles = {
  color: 'brand.neutral.600',
  p: '0px !important',
  m: '0px !important',
  textStyle: 'sm',
};

export const headingStyles = {
  size: 'headline-sm',
  color: 'brand.neutral.700',
};

export const indicatorDiscStyles = (color) => {
  return {
    _before: {
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      w: '120px',
      h: '120px',
      bg: color,
      left: { base: '-50px', md: '-40px' },
      top: { base: '5px', md: '50%' },
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },
  };
};
export const bannerChevronStyles = {
  m: '0 !important',
  position: 'absolute',
  top: '50%',
  right: '16px',
  transform: 'translateY(-50%)',
};
