import React from 'react';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { IllustrationRCLeaseDocument } from '@illustrations';
import { infoStyles } from './styles/info.styles';

const Info = () => {
  const { isMinXL } = getBreakPoints();

  const { container, title, description, iconcontainer } = infoStyles({ isMinXL }) ?? {};

  return (
    <HStack {...container}>
      <Stack>
        <Text {...title}>Fastest Way to Create & Sign Lease Documents</Text>
        <Text {...description}>
          Create your lease document by answering a few quick questions, sign it online and send to
          tenants for signing. All in one place.
        </Text>
      </Stack>

      <Box {...iconcontainer}>
        <IllustrationRCLeaseDocument />
      </Box>
    </HStack>
  );
};

export default Info;
