export const tableHeaderItems = [
  { key: 'property', label: 'Property' },
  { key: 'tenant', label: 'Tenant' },
  { key: 'feePaidBy', label: 'ACH Fee Paid By' },
  { key: 'action', label: '' },
];

export const getTableConfig = ({ isMax576 }) => {
  return {
    columns: [
      {
        styles: {
          display: 'table-cell',
          position: 'relative',
          width: 'auto',
          h: 'auto',
          minH: '72px',
          px: 1.5,
          header: {
            title: {
              fontSize: '10px',
              lineHeight: '12px',
            },
          },
        },
      },
      {
        styles: {
          display: isMax576 ? 'none' : 'table-cell',
          width: 'auto',
          px: 1.5,
          header: {
            title: {
              fontSize: '10px',
              lineHeight: '12px',
            },
          },
        },
      },
      {
        styles: {
          width: { base: '104px', xs: '120px' },
          px: 1.5,
          header: {
            title: {
              fontSize: '10px',
              lineHeight: '12px',
            },
          },
        },
      },
      {
        styles: {
          width: isMax576 ? '64px' : '118px',
          px: 1.5,
        },
      },
    ],
  };
};

export const successToastConfig = {
  description: 'Reset unit settings',
  status: 'success',
  duration: '3000',
  isClosable: true,
  position: 'bottom-left',
};

export const errorToastConfig = {
  description: 'Something went wrong. Please try again',
  status: 'error',
  duration: '3000',
  isClosable: true,
  position: 'bottom-left',
};
