import React, { useContext, useRef } from 'react';
import { Box, Text, Spacer } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import { BaselaneButton } from '@shared/components';
import AddAccountMobile from '@core/components/NativeBankingPage/MainContent/components/Accounts/components/AddAccountMobile';
import BanksContext from '@contexts/BanksContext';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import DisclaimerText from '@shared/components/DisclaimerText';
import AddAccountMenu from '../../components/AddAccountMenu';
import BaselaneDisclaimer from '../../components/BaselaneDisclaimer';
import { disclaimerText } from '../../components/BaselaneDisclaimer/const/disclaimerText';
import BaselaneEmptyStateView from './BaselaneEmptyStateView';
import ExternalEmptyStateView from './ExternalEmptyStateView';
import AddAccount from '../../MainContent/components/Accounts/components/AddAccount';
import AddAccountButton from './AddAccountButton';
import {
  darkDescriptionTextStyles,
  sectionTitleStyles,
  drawerBodyStyles,
  containerStyle,
} from '../../components/styles/emptyState.shared.styles';

type AddAccountDrawerProps = {
  isOnboarding?: boolean,
  isGetStarted?: boolean,
  getStartedConfig?: Object,
  buttonLeftIcon?: Function,
  buttonSize?: string,
  buttonText?: string,
  buttonType?: string,
  buttonStyles?: Object,
  buttonIsLoading?: boolean,
  hasBankAccounts: Boolean,
  refetchLeaseBankAccounts?: Function,
  isFromLease?: Boolean,
  banks: Array<Object>,
  handleDrawerOpenExternally?: Function,
  handleExternalAccountDrawerCloseExternally?: Function,
  showMobileVersion?: Boolean,
  variant: string,
  palette: string,
  size: string,
};

const AddAccountDrawer = ({
  banks,
  isOnboarding,
  isGetStarted,
  getStartedConfig,
  buttonLeftIcon,
  buttonSize,
  buttonText,
  buttonType,
  buttonStyles,
  buttonIsLoading,
  hasBankAccounts,
  refetchLeaseBankAccounts = () => {},
  isFromLease,
  handleDrawerOpenExternally,
  handleExternalAccountDrawerCloseExternally,
  showMobileVersion,
  palette,
  variant,
  size,
}: AddAccountDrawerProps) => {
  const { addExternalAccountSummaryDrawerRef } = useContext(BanksContext);
  const addAccountDrawerRef = useRef(null);
  const onOpenAddAccountDrawer = () => {
    sendSegmentEvent('add_lease_bank_account_add', {});
    addAccountDrawerRef.current.open();
    handleDrawerOpenExternally();
  };
  const onCloseAddAccountDrawer = () => {
    addAccountDrawerRef.current.close();

    if (isMobile) {
      handleExternalAccountDrawerCloseExternally();
      if (isFromLease) {
        refetchLeaseBankAccounts();
      }
    }
  };

  const { DrawerBody, DrawerFooter } = BaselaneDrawer;

  return (
    <>
      <AddAccountButton
        {...{
          isOnboarding,
          isGetStarted,
          getStartedConfig,
          hasBankAccounts,
          onOpenAddAccountDrawer,
          buttonType,
          buttonStyles,
          buttonSize,
          buttonText,
          buttonLeftIcon,
          buttonIsLoading,
          banks,
          showMobileVersion,
          palette,
          variant,
          size,
        }}
      />

      {!isMobile ? (
        <AddAccount
          hideButton
          {...{
            buttonStyles: {},
            palette,
            variant,
            size,
            helperFunction: onOpenAddAccountDrawer,
            addExternalAccountDrawerRef: addExternalAccountSummaryDrawerRef,
            refetchLeaseBankAccounts,
            isFromLease,
            handleDrawerCloseExternally: handleExternalAccountDrawerCloseExternally,
          }}
        />
      ) : (
        <AddAccountMobile
          hideButton
          {...{
            buttonStyles: {},
            palette,
            variant,
            size,
            isFromLease,
            refetchLeaseBankAccounts,
            helperFunction: onOpenAddAccountDrawer,
          }}
        />
      )}

      <BaselaneDrawer
        ref={addAccountDrawerRef}
        title="Add Account"
        size="2lg"
        closeEvent={onCloseAddAccountDrawer}
      >
        <DrawerBody {...drawerBodyStyles} display="flex" flexDirection="column">
          {isMobile ? (
            <AddAccountMenu isGetStarted={isGetStarted} />
          ) : (
            <>
              <Box>
                <Box>
                  <Text {...sectionTitleStyles} mb="18px">
                    Add Account
                  </Text>
                  <Text {...darkDescriptionTextStyles} mb="52px">
                    Open a Baselane Banking account or connect an external account.
                  </Text>
                </Box>
                <BaselaneEmptyStateView isGetStarted={isGetStarted} />
                <ExternalEmptyStateView
                  onCloseAddAccountDrawer={onCloseAddAccountDrawer}
                  addExternalAccountDrawerRef={addExternalAccountSummaryDrawerRef}
                  isInDrawer
                />
              </Box>
              <Spacer />
              <BaselaneDisclaimer
                containerStyle={containerStyle}
                text={<DisclaimerText />}
                showPrivacyPolicy={disclaimerText.addAccountDrawer.showPrivacyPolicy}
              />
            </>
          )}
        </DrawerBody>
        <DrawerFooter>
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            onClick={onCloseAddAccountDrawer}
          >
            Cancel
          </BaselaneButton>
        </DrawerFooter>
      </BaselaneDrawer>
    </>
  );
};

AddAccountDrawer.defaultProps = {
  buttonText: 'Add Account',
  buttonType: 'primary',
  buttonSize: 'md',
  buttonStyles: ({}: { ... }),
  buttonLeftIcon: null,
  buttonIsLoading: false,
  isOnboarding: false,
  isGetStarted: false,
  getStartedConfig: {},
  isFromLease: false,
  showMobileVersion: false,
  refetchLeaseBankAccounts: () => {},
  handleDrawerOpenExternally: () => {},
  handleExternalAccountDrawerCloseExternally: () => {},
};
export default AddAccountDrawer;
