import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { sharedSizeStyles } from './input';

/**
 * This overrides Chakra's default <Select /> styles.
 *
 * Note: _field_ sets the styles for the actual select.
 * _icon_ sets the styles for the icon, usually a chevron.
 *
 * see: node_modules/@chakra-ui/theme/src/components/select.ts for implementation details
 */
const parts = ['field', 'icon'];
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Select = defineMultiStyleConfig({
  baseStyle: {
    field: {
      textStyle: 'sm',
      paddingInlineStart: 1,
    },
  },
  sizes: {
    lg: {
      field: {
        px: sharedSizeStyles.lg.px,
      },
    },
    md: {
      field: {
        px: sharedSizeStyles.md.px,
      },
    },
    sm: {
      field: {
        px: sharedSizeStyles.sm.px,
        paddingInlineEnd: 1,
      },
    },
    xs: {
      field: {
        px: sharedSizeStyles.xs.px,
        paddingInlineEnd: 1,
      },
    },
  },
});
