import { React } from 'react';
import { Text, FormControl } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { Icon12Info } from '@icons/12px';
import { BaselaneFormLabel, BaselaneFormHelperText } from '@shared/components';
import { steps } from '../../../helpers/formHelpers';

import { useRecipient, useSelectedPaymentMethod, useCurrentStep } from '../../../sendFundsStore';
import RecipientDetailsFields from '../../../../Recipients/components/fields/RecipientDetailsFields';
import PaymentMethodsOptions from './PaymentMethodsOptions';
import RecipientDetailsCard from './RecipientDetailsCard';
import PaymentMethodDetails from './PaymentMethodDetails';

const SelectPaymentMethod = () => {
  const formik = useFormikContext();
  const { errors, touched, values } = formik;
  const recipient = useRecipient();
  const selectedPaymentMethod = useSelectedPaymentMethod();
  const currentStep = useCurrentStep();

  return (
    <>
      {values?.payeeId ? (
        <RecipientDetailsCard recipient={recipient} />
      ) : (
        <RecipientDetailsFields />
      )}
      <FormControl isRequired>
        <BaselaneFormLabel textStyle="sm" htmlFor="paymentMethod">
          Payment method
        </BaselaneFormLabel>

        <PaymentMethodsOptions hasError={errors.paymentMethodId && touched.paymentMethodId} />
        {currentStep === steps.ADD_RECIPIENT && (
          <BaselaneFormHelperText customIcon={<Icon12Info />}>
            You can add more payment methods to the recipient later
          </BaselaneFormHelperText>
        )}
      </FormControl>
      {selectedPaymentMethod?.type && (
        <Text as="h2" textStyle="headline-xs" fontWeight="normal" mb={0.5}>
          {selectedPaymentMethod?.type} details
        </Text>
      )}

      <PaymentMethodDetails />
    </>
  );
};

export default SelectPaymentMethod;
