export const relativePositionStyling = (shouldMove) => {
  return {
    container: {
      position: { lg: 'relative', base: 'initial' },
      top: shouldMove ? '-24px' : 'initial',
      left: shouldMove ? '-32px' : 'initial',
      w: { lg: shouldMove && 'calc(100% + 64px)', base: '100%' },
      minW: { lg: shouldMove && 'calc(100% + 64px)', base: '100%' },
    },
  };
};
