import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_BANK_SUMMARY } from '../components/NativeBankingPage/queries';

const buildBankAccountsById = (banks) => {
  const bankAccountsById = {};

  banks.forEach((bank) => {
    bank.bankAccounts.forEach((bankAccount) => {
      bankAccountsById[bankAccount.id] = bankAccount;

      if (bankAccount?.subAccounts && bankAccount.subAccounts.length) {
        bankAccount.subAccounts.forEach((subAccount) => {
          bankAccountsById[subAccount.id] = subAccount;
        });
      }
    });
  });

  return bankAccountsById;
};

/**
 * @typedef UseBankSummaryParams
 * @type {Object}
 * @property {Object} [variables] - The variables for the query.
 */

// TODO: I don't know what the params above mean. Maybe someone writes better description.

/**
 * @typedef UseBankSummaryResult
 * @type {Object}
 * @property {Error} error - The error, if any.
 * @property {boolean} loading - Indication that the query is loading.
 * @property {Array} banks - The list of banks. The response is an array of banks, and each bank has a list of bank accounts.
 * @property {Object} banksById - The banks by id.
 * @property {Object} bankAccountsById - The bank accounts by id. This is a flat object that contains all bank accounts and sub-accounts.
 */

/**
 * This hook fetches the banks and their accounts. It returns the banks as is, and also returns the banks and bank accounts by id.
 * @param {UseBankSummaryParams} params - The parameters as described by {@link UseBankSummaryParams}.
 * @returns {UseBankSummaryResult} The result object as described by {@link UseBankSummaryResult}.
 */
const useBankSummary = ({
  variables = {
    isExternal: false,
    isManualAccount: false,
    isConnectedBank: true,
    isConnectedAccount: true,
  },
} = {}) => {
  const { error, loading, data, refetch } = useQuery(GET_BANK_SUMMARY, {
    variables,
  });

  const banks = data?.bankSummary?.bank || [];

  const bankAccountsById = useMemo(() => buildBankAccountsById(banks), [banks]);

  const banksById = useMemo(() => {
    return banks.reduce((acc, bank) => {
      acc[bank.id] = bank;
      return acc;
    }, {});
  }, [banks]);

  return { error, loading, banks, banksById, bankAccountsById, refetch };
};

export default useBankSummary;
