import React, { Fragment } from 'react';
import { HStack } from '@chakra-ui/react';
import BaselaneDivider from '../../../BaselaneDivider';
import DropdownContentWrapper from '../../DropdownContentWrapper';

import { setIsSelectedItem } from '../../helpers/dropdown.shared.helpers';
import { accordionItemChildStyles, itemContainerStyles } from '../../styles/accordion.styles';
import { t1Search } from '../../utils/search';

type ListOfOptionsProps = {
  options: Array<any>,
  itemRenderer: Function,
  handleListItemClick: Function,
  hasFilterWrapper: boolean,
  searchTerm: Array<String>,
  search: string,
  selectedStagedOptions: Array<Object>,
  setShowMobileDropdownPopup: Function,
  setShowMobileDropdownPopupAnimation: Function,
};

const ListOfOptions = ({
  hasFilterWrapper,
  options,
  searchTerm,
  search,
  handleListItemClick,
  itemRenderer,
  selectedStagedOptions,
  setShowMobileDropdownPopup,
  setShowMobileDropdownPopupAnimation,
}: ListOfOptionsProps) => {
  return (
    <DropdownContentWrapper hasFilterWrapper={hasFilterWrapper}>
      {t1Search({ options, searchTerm, search }).map((item, index) => {
        const { customDropdownOption, showDivider } = item;

        if (customDropdownOption) {
          return (
            <Fragment key={item.id}>
              {showDivider && <BaselaneDivider styles={{ my: 1, color: 'brand.darkBlue.100' }} />}
              {customDropdownOption(
                setShowMobileDropdownPopup,
                setShowMobileDropdownPopupAnimation
              )}
            </Fragment>
          );
        }

        return (
          <Fragment key={item.id}>
            {showDivider && index !== 0 && (
              <BaselaneDivider styles={{ my: 1, color: 'brand.darkBlue.100' }} />
            )}

            <HStack {...itemContainerStyles}>
              <HStack
                tabIndex="-1"
                className="element"
                id={item.id}
                onClick={(e) => {
                  handleListItemClick(item, e);
                }}
                bgColor={
                  setIsSelectedItem(item, selectedStagedOptions) ? 'brand.blue.50' : 'initial'
                }
                {...accordionItemChildStyles}
              >
                {itemRenderer({ item, search })}
              </HStack>
            </HStack>
          </Fragment>
        );
      })}
    </DropdownContentWrapper>
  );
};

export default ListOfOptions;
