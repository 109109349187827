// @flow
import React, { useRef, useContext, useEffect } from 'react';
import { HStack, Heading, Stack, useDisclosure } from '@chakra-ui/react';
import {
  BaselaneButton,
  BaselaneTooltip,
  BaselaneErrorCard,
  BaselaneExternalBankingTypePopup,
  AddAccountManuallyDrawer,
} from '@shared/components';
import BanksContext from '@contexts/BanksContext';
import { Icon16Info } from '@icons/16px';
import SlLoader from '@core/components/Loader';
import getBreakPoints from '@core/utils/getBreakPoints';
import useConnectedSelfOwned from '../../hooks/useConnectedSelfOwned';
import ExternalAccounts from './components/ExternalAccounts';
import ManuallyAdded from './components/ManuallyAdded';
import {
  tabPanelHeadingStyles,
  sectionHeadingStyles,
  addButtonStyles,
  panelHeaderContainerStyles,
  sectionHeadingContainerStyles,
  tooltipStyles,
} from '../../styles/externalAccounts.styles';

import { getManualAccountsArray } from '../../helpers/externalAccount.helpers';

function MyAccountsPanel(): any {
  const { isMinXL, isMin768 } = getBreakPoints();

  const addAccountManuallyDrawerRef = useRef();
  const addExternalAccountDrawerRef = useRef();

  const {
    isOpen: isExternalBankingPopupOpen,
    onOpen: onExternalBankingPopupOpen,
    onClose: onExternalBankingPopupClose,
  } = useDisclosure();

  // Manually Added Accounts
  const {
    isConnectedSelfOwnedLoading,
    hasConnectedSelfOwnedError,
    connectedSelfOwnedData,
    refetchConnectedSelfOwned,
  } = useConnectedSelfOwned();

  // Plaid External Accounts
  const { externalAccounts, refetchBankSummary } = useContext(BanksContext);

  useEffect(() => {
    refetchBankSummary();
  }, []);

  const selfOwned = getManualAccountsArray(connectedSelfOwnedData?.bank);

  const addExternalAccountProps = {
    isFromLease: false,
    isDirectToPlaid: true,
    hideButton: true,
    handleAddAccountSuccessCallback: () => {
      refetchConnectedSelfOwned();
    },
    addExternalAccountDrawerRef,
  };

  const onAddButtonClick = () => {
    addAccountManuallyDrawerRef?.current?.open();
  };

  const handleAddAccountManuallySuccess = () => {
    refetchConnectedSelfOwned();
  };

  if (isConnectedSelfOwnedLoading) return <SlLoader />;
  if (hasConnectedSelfOwnedError) {
    if (hasConnectedSelfOwnedError.message.includes('Failed to fetch')) {
      return <BaselaneErrorCard />;
    }
  }

  return (
    <Stack gap="24px">
      <Stack>
        <HStack {...panelHeaderContainerStyles(!isMin768)}>
          {isMin768 && (
            <Heading as="h3" {...tabPanelHeadingStyles}>
              Manage external accounts
            </Heading>
          )}
          <BaselaneButton
            id="add-external-account-button"
            {...{
              variant: 'filled',
              palette: 'primary',
              size: isMin768 ? 'md' : 'lg',
              styles: addButtonStyles(!isMin768),
            }}
            onClick={onExternalBankingPopupOpen}
          >
            Add External Account
          </BaselaneButton>
          <BaselaneExternalBankingTypePopup
            handleActionButton={() => {
              onExternalBankingPopupClose();
              onAddButtonClick();
            }}
            helperFunction={() => {
              onExternalBankingPopupClose();
            }}
            isOpen={isExternalBankingPopupOpen}
            onClose={onExternalBankingPopupClose}
            addAccountProps={addExternalAccountProps}
          />
        </HStack>

        <HStack {...sectionHeadingContainerStyles} m="0 !important" gap="8px">
          <Heading as="h4" {...sectionHeadingStyles}>
            Connected with Plaid
          </Heading>
          <BaselaneTooltip
            label="Connected accounts can add funds, be sent funds, collect rent, and import transactions. Connected cards can only import transactions."
            styles={tooltipStyles.styles}
            innerContainerStyles={tooltipStyles.container}
          >
            <Icon16Info color="#6C7884" />
          </BaselaneTooltip>
        </HStack>

        {externalAccounts && (
          <ExternalAccounts
            {...{
              banks: externalAccounts,
              refetchBankSummary,
              addExternalAccountAction: onExternalBankingPopupOpen,
              isMinXL,
            }}
          />
        )}
      </Stack>

      {selfOwned?.length > 0 && (
        <Stack gap={0}>
          <HStack {...sectionHeadingContainerStyles} gap="8px">
            <Heading as="h4" {...sectionHeadingStyles}>
              Manually Added
            </Heading>
            <BaselaneTooltip
              label="Manually added accounts can be sent funds and collect rent."
              styles={tooltipStyles.styles}
              innerContainerStyles={tooltipStyles.container}
            >
              <Icon16Info color="#6C7884" />
            </BaselaneTooltip>
          </HStack>

          {selfOwned && (
            <ManuallyAdded
              {...{
                selfOwned,
                refetchConnectedSelfOwned,
                isMinXL,
              }}
            />
          )}
        </Stack>
      )}

      <AddAccountManuallyDrawer
        {...{
          addAccountManuallyDrawerRef,
          handleAddAccountManuallySuccess,
          from: { section: 'my_accounts' },
        }}
      />
    </Stack>
  );
}
export default MyAccountsPanel;
