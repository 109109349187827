import React, { useRef } from 'react';
import { HStack, Box, useToast } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import SlLoader from '@core/components/Loader';
import { BaselaneCardNew, BaselaneButton } from '@shared/components';
import { GET_PAYEE, UPDATE_PAYEE } from '@core/apollo/queries';
import * as ROUTES from '@routes';
import { handleCancel } from '../helpers/navigation.helpers';
import { recipientValidation } from '../helpers/validation.helpers';
import RecipientDetailsFields from './fields/RecipientDetailsFields';

const RecipientDetailsFormEdit = () => {
  const location = useLocation() || {};
  const { state } = location || {};
  const { from } =
    state ||
    location?.pathname?.split(`/${ROUTES.TRANSFERS_RECIPIENTS_DETAILS_EDIT}`)?.join('') ||
    {};

  const { recipientId } = useParams();

  const { error, loading: isRecipientLoading, data } = useQuery(GET_PAYEE, {
    variables: { id: recipientId },
  });

  const [updatePayee, { loading: isRecipientSaving }] = useMutation(UPDATE_PAYEE);

  const toast = useToast();

  const showUpdateRecipientErrorToast = (message) =>
    toast({
      position: 'bottom-left',
      description: 'Error, could not save recipient.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const initialValues = {
    id: data?.payee?.id,
    name: data?.payee?.name,
    email: data?.payee?.email,
    type: data?.payee?.type,
  };

  const navigate = useNavigate() || {};
  const formikRef = useRef();

  const saveRecipient = (values, { setErrors }) => {
    const payee = { ...values };

    updatePayee({
      variables: {
        input: payee,
      },
    })
      .then((res) => {
        // close drawer with "success" state
        if (!res?.errors) {
          navigate(
            {
              pathname: `${from}`,
            },
            {
              replace: true,
              state: {
                isUpdateRecipientSuccessful: true,
              },
            }
          );
        } else if (res.errors[0].message.includes('already exists.')) {
          // show this error in the name field
          setErrors({
            name: `Recipient already exists, please use a different name.`,
          });
        } else {
          showUpdateRecipientErrorToast();
        }
      })
      .catch((err) => {
        showUpdateRecipientErrorToast();
      });
  };

  return (
    <>
      {(isRecipientLoading || isRecipientSaving) && (
        <Box>
          <SlLoader styles={{ position: 'absolute !important' }} />
        </Box>
      )}
      {!error && (
        <BaselaneCardNew palette="dark" flexDirection="column">
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validateOnChange
            validateOnBlur
            validate={recipientValidation}
            onSubmit={saveRecipient}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              errors,
              touched,
              dirty,
              isSubmitting,
              isValid,
            }) => (
              <Form style={{ width: '100%' }}>
                <RecipientDetailsFields />
                <HStack justifyContent="flex-end" gap={2}>
                  <BaselaneButton
                    variant="outline"
                    palette="primary"
                    size="md"
                    onClick={() => handleCancel(navigate, from)}
                  >
                    Cancel
                  </BaselaneButton>
                  <BaselaneButton
                    variant="filled"
                    palette="primary"
                    size="md"
                    onClick={handleSubmit}
                  >
                    Save
                  </BaselaneButton>
                </HStack>
              </Form>
            )}
          </Formik>
        </BaselaneCardNew>
      )}
    </>
  );
};

export default RecipientDetailsFormEdit;
