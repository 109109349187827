import CancelEditBtn from './CancelEditBtn';
import EditBtn from './EditBtn';
import SaveChangesBtn from './SaveChangesBtn';

// on change of any editable field in card details gets updated in this function
const handleEditModeChange = (e, setEditModeValues) => {
  // react-select-dropdown does not let us add id, manually adding it, see PeriodDropdown
  const field = e?.target?.id
    ? e
    : {
        target: { id: 'spendingLimit.frequency', ...e },
      };
  const [key1, key2] = field?.target.id.split('.') || [];

  setEditModeValues((prevState) => ({
    ...prevState,
    [key1]: { ...prevState[key1], [key2]: field.target.value },
  }));
};

// helper function to help toggle the state of the inputs that are editable
const handleEditModeState = (
  inputName,
  shouldToggleOn,
  shouldSave,
  setEditModeValues,
  initialValues
) => {
  setEditModeValues((prevState) => ({
    ...prevState,
    [inputName]: {
      ...(shouldSave ? prevState[inputName] : initialValues[inputName]),
      isEdit: shouldToggleOn,
    },
  }));
};

export { CancelEditBtn, EditBtn, SaveChangesBtn, handleEditModeChange, handleEditModeState };
