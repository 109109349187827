import React from 'react';
import { Stack } from '@chakra-ui/react';
import SelectField from './SelectField';
import Popup from './Popup';
import PopupHeader from './PopupHeader';
import DropdownFooter from '../DropdownFooter';
import { handlePopupClose, handlePopupToggle } from '../helpers/popup.shared.helpers';

type MobilePopupProps = {
  // custom view for what the dropdown select field will look like and how the information will be displayed
  DisplayInput: React.Node,
  // options that are currently selected and applied
  selectedOptions: Array<object>,
  // options that are currently selected but not applied yet
  selectedStagedOptions: Array<object>,
  placeholder: string,
  title: string,
  // helper props to disable button if input is invalid
  // TODO: currently only used in cashflow, once new dropdowns are getting applied to cashflow check to see
  // if we can utilize other props and remove this one
  isInvalidInput?: boolean,
  hideClearButton?: boolean,
  isDisabled: boolean,
  isMobile: boolean,
  isMulti: boolean,
  hasError: boolean,
  // from the dropdown data use these fields to displayed in dropdown as an option
  showValueByFields: boolean,
  showMobileDropdownPopup: boolean,
  // to set the animation of the mobile popup
  showMobileDropdownPopupAnimation: boolean,
  // only clear what is currently selected in dropodown that is open
  handleClearClick: Function,
  // clear dropdown and set currently values to none or what it is initially
  handleExternalClear: Function,
  // actions on click of apply button or if it is single select, of the option
  onApply: Function,
  setShowMobileDropdownPopup: Function,
  setShowMobileDropdownPopupAnimation: Function,
  setSelectedStagedOptions: Function,
  // set the current value in the search input
  setSearch: Function,
  // the dropdown list
  children: React.Node,
  // classes used to help determine what the mobile version of the dropdown will look like
  classNames: Array<string>,
};

const MobilePopup = ({
  DisplayInput,
  selectedOptions,
  selectedStagedOptions,
  placeholder,
  title,
  isInvalidInput,
  hideClearButton,
  isDisabled,
  isMobile,
  isMulti,
  hasError,
  showValueByFields,
  showMobileDropdownPopup,
  showMobileDropdownPopupAnimation,
  handleClearClick,
  handleExternalClear,
  onApply,
  setShowMobileDropdownPopup,
  setShowMobileDropdownPopupAnimation,
  setSelectedStagedOptions,
  setSearch,
  children,
  classNames,
}: MobilePopupProps) => {
  const handleApply = () => {
    onApply();
    handlePopupClose({
      setShowMobileDropdownPopup,
      setShowMobileDropdownPopupAnimation,
    });
  };

  return (
    <>
      <SelectField
        showMobileDropdownPopup={showMobileDropdownPopup}
        DisplayInput={DisplayInput}
        selectedOptions={selectedOptions}
        handleContentRendererClick={() => {
          if (!showMobileDropdownPopup) {
            setSelectedStagedOptions(selectedOptions);
          }
          handlePopupToggle({
            setShowMobileDropdownPopup,
            showMobileDropdownPopup,
            setShowMobileDropdownPopupAnimation,
          });
        }}
        placeholder={placeholder}
        title={title}
        showValueByFields={showValueByFields}
        handleClearClick={() => {
          handleExternalClear({ setSearchParam: setSearch });
        }}
        isDisabled={isDisabled}
        isMobile={isMobile}
        hasError={hasError}
        classNames={classNames}
      />
      <Popup
        handlePopupClose={() =>
          handlePopupClose({
            setShowMobileDropdownPopup,
            setShowMobileDropdownPopupAnimation,
          })
        }
        showMobileDropdownPopupAnimation={showMobileDropdownPopupAnimation}
        showMobileDropdownPopup={showMobileDropdownPopup}
      >
        <Stack height="100%" bgColor="white" overflow="hidden">
          {/* Header */}
          <PopupHeader
            handlePopupClose={() =>
              handlePopupClose({
                setShowMobileDropdownPopup,
                setShowMobileDropdownPopupAnimation,
              })
            }
            selectedStagedOptions={selectedStagedOptions}
            title={title}
            handleClearClick={() => handleClearClick(setSearch)}
            hideClearButton={hideClearButton}
          />

          {/* List */}
          {children}

          {/*  Footer */}
          {isMulti && (
            <DropdownFooter
              isDisabled={isInvalidInput && hideClearButton}
              selectedStagedOptions={selectedStagedOptions}
              onApply={handleApply}
            />
          )}
        </Stack>
      </Popup>
    </>
  );
};

MobilePopup.defaultProps = {
  isInvalidInput: false,
  hideClearButton: false,
};

export default MobilePopup;
