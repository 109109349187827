import React from 'react';
import { FormControl, Flex, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { renderPropertyDropdownParentItem } from '@shared/helpers/propertiesFilter.helpers';
import BaselaneFormLabel from '../../../BaselaneForm/BaselaneFormLabel';
import BaselaneTextArea from '../../../BaselaneTextArea';
import T2Dropdown from '../../../BaselaneDropdown-new/T2';
import T2WithTitleDropdown from '../../../BaselaneDropdown-new/T2-Title';
import { itemRenderer } from '../../../BaselaneDropdown/components/helpers/itemRenderer.helpers';
import DropDownDisplayInput from './DropDownDisplayInput';
import NoPropertyTooltip from '../../../NoPropertyTooltip';
import { getSplitTagId } from '../../helpers';
import { formStyles } from '../../styles';

type Step2Props = {
  setUnsplitTagId: Function,
  propertyOptions: Array<Object>,
  propertyIdsMap: Object,
  categoryOptions: Array<Object>,
  categoryMap: Object,
  categoryIdsMap: Object,
};

const Step2 = ({
  setUnsplitTagId,
  propertyOptions,
  propertyIdsMap,
  categoryOptions,
  categoryMap,
  categoryIdsMap,
}: Step2Props) => {
  const { values, setFieldValue, handleChange, handleBlur } = useFormikContext();

  const categoryDropdownProps = {
    name: 'tagId',
    placeholder: 'Select Category',
    title: 'Category',
    additionalProps: { id: 'category-dropdown' },
    data: categoryOptions,
    showValueByFields: ['name'],
    parentItemRenderer: itemRenderer,
    childItemRenderer: itemRenderer,
    handleSubmit: (selectedValue) => {
      setUnsplitTagId(selectedValue);
      const { parentTagId, subTagId } = getSplitTagId(selectedValue);
      setFieldValue('tagId', Array.isArray(selectedValue) ? null : subTagId ?? parentTagId);
    },
    selectedItem:
      values?.tagId && values?.tagId !== ''
        ? {
            id: categoryIdsMap[values.tagId],
            name: categoryMap[values.tagId],
          }
        : null,
  };

  const getSelectedProperty = (selectedValue) => {
    const getPropertyId = () => {
      const [propertyId, unitId] = selectedValue.split('-');
      return unitId ? { propertyId, unitId } : { propertyId };
    };
    const property = Array.isArray(selectedValue) ? null : getPropertyId();

    const { propertyId, unitId } = property ?? {};
    let selectedProperty = null;
    if (propertyId && unitId) {
      selectedProperty = {
        id: `${propertyId}-${unitId}`,
        showValueName: `${propertyIdsMap[`p-${propertyId}`]?.name}, ${
          propertyIdsMap[`u-${unitId}`]?.name
        }`,
      };
    } else if (propertyId && !unitId) {
      selectedProperty = {
        id: `${propertyId}`,
        showValueName: propertyIdsMap[`p-${propertyId}`]?.name,
      };
    }

    return selectedProperty;
  };

  const propertyDropdownProps = {
    name: 'propertyId',
    placeholder: 'Select Property',
    title: 'Property',
    additionalProps: { id: 'property-dropdown' },
    data: propertyOptions,
    searchTerm: 'name',
    showValueByFields: ['showValueName'],
    parentItemRenderer: ({ item }) => renderPropertyDropdownParentItem(item),
    childItemRenderer: itemRenderer,
    handleSubmit: (selectedValue) => {
      setFieldValue('propertyId', selectedValue);
    },
    selectedItem: getSelectedProperty(values.propertyId ?? []),
  };

  const sharedDropdownProps = {
    classNames: ['input-form-lg', 'auto-select-input-width'],
    isMulti: false,
    hasFilterWrapper: false,
    parentId: 'drawer-body',
    CustomDisplayInput: DropDownDisplayInput,
  };

  const { controlStyles, labelSubtextStyles } = formStyles;

  return (
    <>
      {/* Category */}
      <FormControl id="transfer-funds-transfer-category" {...controlStyles}>
        <BaselaneFormLabel textStyle="sm">
          <Flex as="span" gap={0.5}>
            Category
            <Text {...labelSubtextStyles}>(optional)</Text>
          </Flex>
        </BaselaneFormLabel>
        <T2WithTitleDropdown {...{ ...categoryDropdownProps, ...sharedDropdownProps }} />
      </FormControl>

      {/* Property */}
      <FormControl id="transfer-funds-transfer-property" {...controlStyles}>
        <BaselaneFormLabel textStyle="sm">
          <Flex as="span" gap={0.5}>
            Property
            <Text {...labelSubtextStyles}>(optional)</Text>
          </Flex>
        </BaselaneFormLabel>
        {propertyOptions?.length === 0 ? (
          <NoPropertyTooltip {...{ innerContainerStyles: { m: '0 !important' } }}>
            <T2Dropdown {...{ ...propertyDropdownProps, ...sharedDropdownProps }} isDisabled />
          </NoPropertyTooltip>
        ) : (
          <T2Dropdown {...{ ...propertyDropdownProps, ...sharedDropdownProps }} />
        )}
      </FormControl>

      {/* Notes */}
      <FormControl id="transfer-funds-transfer-note" {...controlStyles}>
        <BaselaneFormLabel textStyle="sm">
          <Flex as="span" gap={0.5}>
            Notes
            <Text {...labelSubtextStyles}>(optional)</Text>
          </Flex>
        </BaselaneFormLabel>
        <BaselaneTextArea
          size="lg"
          id="note"
          name="note"
          placeholder="Add any notes for yourself"
          value={values.note}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormControl>
    </>
  );
};

export default Step2;
