export const handleValidation = (values) => {
  const { nickName } = values;
  const errors = {};

  if (!nickName || nickName?.trim() === '') {
    errors.nickName = 'Please enter account name';
  }

  return errors;
};
