// @flow
import React from 'react';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import {
  Box,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import {
  BaselaneAddress,
  BaselaneAutoCompleteAddress,
  BaselaneButtonToggle,
} from '@shared/components';
import { zipcodeMask } from '@core/utils/masks';
import { formLabelStyles, formErrorStyles } from '@shared/styles/input.style';
import {
  formValidation,
  propertyTypeOptions,
  setUnitNames,
} from '@pages/PropertiesPage/helpers/detail.helper';
import MultipleUnits from './MultipleUnits';
import { fieldContainer, formInputStyles } from '../../../styles/form.style';
import {
  textLabelStyle,
  formLabelStyle,
  cFormControlStyles,
  cDetailsContainerStyles,
  cfieldContainer,
  cFormControlBorder,
} from '../../../styles/responsive.style';

type DetailsProps = {
  setFormVariables: Function,
  setIsValid: Function,
  setDetailsComplete: Function,
  setIsDirty: Function,
  isDirty: boolean,
  property: Object,
  initialValues: Object,
  propertyFormRef: Object,
};

function Details({
  setFormVariables,
  setIsValid,
  setDetailsComplete,
  setIsDirty,
  isDirty,
  property,
  initialValues,
  propertyFormRef,
}: DetailsProps): any {
  return (
    <Box {...cDetailsContainerStyles}>
      <Formik
        initialValues={initialValues}
        validate={(values) => formValidation(values, setDetailsComplete)}
        validateOnBlur
        innerRef={propertyFormRef}
      >
        {({
          dirty,
          values,
          errors,
          touched,
          isValid,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => (
          <>
            {/* Property Name */}
            <FormControl
              {...fieldContainer}
              {...cFormControlBorder}
              isInvalid={errors.propertyName && touched.propertyName}
            >
              <Text {...textLabelStyle}>What would you like to name this property?</Text>
              <FormLabel htmlFor="propertyName" {...formLabelStyles.xs} {...formLabelStyle}>
                Property Name
              </FormLabel>
              <Input
                {...formInputStyles}
                id="propertyName"
                name="propertyName"
                value={values.propertyName}
                placeholder="e.g. Downtown Duplex"
                onChange={(e) => {
                  setFieldTouched('propertyName', true);
                  handleChange(e);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  setIsDirty({ ...isDirty, ...{ details: dirty } });
                  setFormVariables({ propertyName: values.propertyName });
                  setIsValid(isValid);
                }}
              />
              <FormErrorMessage {...formErrorStyles}>
                <Box as="span">{errors.propertyName}</Box>
              </FormErrorMessage>
            </FormControl>
            {/* Property Type */}
            <Box {...cFormControlBorder}>
              <FormControl {...fieldContainer} isInvalid={touched.type && errors.type}>
                <Text {...textLabelStyle}>What type property is this?</Text>
                <FormLabel htmlFor="type" {...formLabelStyles.xs} {...formLabelStyle}>
                  Property Type
                </FormLabel>
                <Select
                  {...formInputStyles}
                  width="100%"
                  p="0"
                  name="type"
                  value={values.type}
                  placeholder="Select property type"
                  onChange={(e) => {
                    setFieldTouched('type', true);
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    setFieldTouched('type', true);
                    handleBlur(e);
                    setIsDirty({ ...isDirty, ...{ details: dirty } });
                    setFormVariables({ type: values.type });
                    setIsValid(isValid);
                  }}
                >
                  {propertyTypeOptions.map((option) => {
                    const { value, label } = option;
                    return (
                      <option key={value} value={value} defaultValue={value}>
                        {label}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage {...formErrorStyles}>
                  <Box as="span">{errors.type}</Box>
                </FormErrorMessage>
              </FormControl>
              {values.type === 'other' && (
                <FormControl
                  {...fieldContainer}
                  {...cFormControlStyles}
                  isInvalid={errors.customPropertyType && touched.customPropertyType}
                >
                  <FormLabel
                    htmlFor="customPropertyType"
                    {...formLabelStyles.xs}
                    {...formLabelStyle}
                  >
                    Custom Property Type
                  </FormLabel>
                  <Input
                    {...formInputStyles}
                    id="customPropertyType"
                    name="customPropertyType"
                    value={values.customPropertyType}
                    placeholder="Skyscraper"
                    onChange={(e) => {
                      setFieldTouched('customPropertyType', true);
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      setIsDirty({ ...isDirty, ...{ details: dirty } });
                      setFormVariables({ customPropertyType: values.customPropertyType });
                      setIsValid(isValid);
                    }}
                  />
                  <FormErrorMessage {...formErrorStyles}>
                    <Box as="span">{errors.customPropertyType}</Box>
                  </FormErrorMessage>
                </FormControl>
              )}
            </Box>

            <Box>
              <Text {...textLabelStyle}>What is the property address?</Text>
              {/* AutoComplete/Street Address */}
              <BaselaneAutoCompleteAddress
                {...{
                  values,
                  errors,
                  touched,
                  isValid,
                  handleChange,
                  handleBlur,
                  setFormVariables,
                  setIsValid,
                  formLabelStyles: formLabelStyles.xs,
                  formInputStyles,
                  formErrorStyles,
                  formContainerStyles: fieldContainer,
                  cfieldContainer,
                  setIsDirty: () => setIsDirty({ ...isDirty, ...{ details: dirty } }),
                }}
              />

              {/* Unit Number */}
              <FormControl {...cfieldContainer} isInvalid={errors.unitNumber && touched.unitNumber}>
                <FormLabel htmlFor="unitNumber" {...formLabelStyles.xs}>
                  Apartment, Unit, Suite, or Floor #
                </FormLabel>
                <Input
                  {...formInputStyles}
                  id="unitNumber"
                  name="unitNumber"
                  value={values.unitNumber}
                  placeholder="e.g. Floor 1"
                  onChange={handleChange}
                  onBlur={(e) => {
                    handleBlur(e);
                    setIsDirty({ ...isDirty, ...{ details: dirty } });
                    setFormVariables({ unitNumber: values.unitNumber });
                    setIsValid(isValid);
                  }}
                />
                <FormErrorMessage {...formErrorStyles}>
                  <Box as="span">{errors.unitNumber}</Box>
                </FormErrorMessage>
              </FormControl>

              {/* City */}
              <FormControl
                position="relative"
                w="100%"
                isInvalid={errors.city && touched.city}
                {...cfieldContainer}
              >
                <FormLabel htmlFor="city" {...formLabelStyles.xs}>
                  City
                </FormLabel>
                <Input
                  {...formInputStyles}
                  id="city"
                  name="city"
                  value={values.city}
                  placeholder="e.g. Philadelphia"
                  onChange={handleChange}
                  onBlur={(e) => {
                    handleBlur(e);
                    setIsDirty({ ...isDirty, ...{ details: dirty } });
                    setFormVariables({ city: values.city });
                    setIsValid(isValid);
                  }}
                />
                <FormErrorMessage {...formErrorStyles}>
                  <Box as="span">{errors.city}</Box>
                </FormErrorMessage>
              </FormControl>

              <Stack direction="row" {...cfieldContainer} spacing="24px">
                {/* State */}
                <FormControl
                  position="relative"
                  w="50%"
                  isInvalid={errors.state && touched.state}
                  {...cfieldContainer}
                >
                  <FormLabel htmlFor="state" {...formLabelStyles.xs}>
                    State
                  </FormLabel>
                  <Select
                    {...formInputStyles}
                    id="state"
                    name="state"
                    value={values.state}
                    placeholder="Select state"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      setFieldTouched('state', true);
                      handleBlur(e);
                      setIsDirty({ ...isDirty, ...{ details: dirty } });
                      setFormVariables({ state: values.state });
                      setIsValid(isValid);
                    }}
                  >
                    <BaselaneAddress.StateOptions />
                  </Select>
                  <FormErrorMessage {...formErrorStyles}>
                    <Box as="span">{errors.state}</Box>
                  </FormErrorMessage>
                </FormControl>

                {/* Zip Code */}
                <FormControl
                  position="relative"
                  w="50%"
                  isInvalid={errors.zipcode && touched.zipcode}
                  {...cfieldContainer}
                >
                  <FormLabel htmlFor="zipcode" {...formLabelStyles.xs}>
                    Zip Code
                  </FormLabel>
                  <Input
                    {...formInputStyles}
                    as={MaskedInput}
                    mask={zipcodeMask}
                    id="zipcode"
                    name="zipcode"
                    value={values.zipcode}
                    placeholder="e.g. 19123"
                    onChange={handleChange}
                    type="text"
                    onBlur={(e) => {
                      handleBlur(e);
                      setIsDirty({ ...isDirty, ...{ details: dirty } });
                      setFormVariables({ zipcode: values.zipcode });
                      setIsValid(isValid);
                    }}
                  />
                  <FormErrorMessage {...formErrorStyles}>
                    <Box as="span">{errors.zipcode}</Box>
                  </FormErrorMessage>
                </FormControl>
              </Stack>

              {/* Single/Multiple Units Toggle Button */}
              <Box pt="48px">
                <BaselaneButtonToggle
                  firstLabel="Single Unit"
                  secondLabel="Multiple Units"
                  isDisabled={property ? property.units.length > 1 : false}
                  activeButton={values.unitType === 'single' ? 0 : 1}
                  onClick={(i) => {
                    const type = Number(i) === 0 ? 'single' : 'multiple';
                    setFieldValue('unitType', type);
                    setUnitNames(
                      initialValues,
                      values,
                      property,
                      type,
                      setFieldValue,
                      setFieldTouched,
                      setDetailsComplete
                    );
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    setIsDirty({ ...isDirty, ...{ details: dirty } });
                    setFormVariables({ unitType: values.unitType });
                    setIsValid(isValid);
                  }}
                  id="unitType"
                  name="unitType"
                />
              </Box>

              {/* Unit Names if Multiple Units Selected */}
              <Box my="32px">
                {values.unitType === 'multiple' && (
                  <MultipleUnits
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setIsDirty={setIsDirty}
                    setFormVariables={setFormVariables}
                    setIsValid={setIsValid}
                    isValid={isValid}
                    dirty={dirty}
                    isDirty={isDirty}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                )}
              </Box>
            </Box>
          </>
        )}
      </Formik>
    </Box>
  );
}

export default Details;
