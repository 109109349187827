import customTheme from '@core/theme';

export const drawerBodyStyles = (isMobile) => ({
  p: isMobile ? '16px 24px' : '32px',
  m: isMobile && '0',
  bgColor: isMobile && 'brand.neutral.white',
});

export const iconContainerStyles = { w: '100%', justifySelf: 'flex-start', mt: '22px' };

export const titleStyles = {
  fontSize: 'lg',
  lineHeight: '26px',
  fontWeight: 'medium',
};

export const descriptionStyles = { fontSize: 'xs', lineHeight: '16px', fontWeight: 'normal' };

export const title2 = {
  textStyle: 'headline-lg',
};

export const title3 = {
  textStyle: 'headline-sm',
  mb: 2,
  mt: 5,
};

export const descriptionTextStyles = {
  color: customTheme.colors.brand.neutral['600'],
  textStyle: 'xs',
  mb: '40px !important',
};
export const title4 = {
  textStyle: 'headline-lg',
  mb: 5,
  mt: 0,
};

export const sectionTitleTextStyles = {
  textStyle: 'headline-sm',
  color: customTheme.colors.brand.neutral['700'],
};

export const sectionBodyTextSmStyles = {
  textStyle: 'sm',
  color: customTheme.colors.brand.neutral['700'],
};

export const sectionBodyTextXsStyles = {
  textStyle: 'xs',
  color: customTheme.colors.brand.neutral['600'],
};
