export const columnTitleContainerStyles = {
  px: '24px',
  ml: '30px',
};

export const columnTitleElementStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.500',
};

export const columnTitleGridStyles = {
  w: 'calc(100% - 48px)',
  h: 'inherit',
};

export const columnTitleGridItemStyles = {
  h: 'inherit',
  display: 'flex',
  alignItems: 'center',
  _last: { display: 'flex', justifyContent: 'flex-end' },
};
