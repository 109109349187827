import { truncatedText } from '@shared/styles/text.style';
import { dropdownDividerStyles } from '../../styles/divider.styles';

export const menuItemTextStyles = {
  textStyle: 'headline-xs',
  ...truncatedText,
};

export const menuOptionStyles = (isSelectedOption, isChild) => {
  return {
    textStyle: 'xs',
    p: '4px 8px',
    pl: isChild ? '24px' : '4px',
    borderRadius: '4px',
    borderWidth: '1px',
    bgColor: isSelectedOption ? 'brand.blue.50' : 'brand.neutral.white',
    color: isSelectedOption ? 'brand.blue.700' : 'brand.neutral.600',
    borderColor: isSelectedOption ? 'brand.blue.100' : 'transparent',
    minH: '28px',
    alignItems: 'center',
    height: 'auto',
    _active: {
      bgColor: 'brand.blue.50',
      color: 'brand.blue.700',
      borderColor: 'brand.blue.100',
    },
    _focus: {
      bgColor: 'brand.blue.50',
      color: 'brand.blue.700',
      borderColor: 'brand.blue.100',
    },
    _hover: {
      bgColor: 'brand.blue.50',
      color: 'brand.blue.700',
      borderColor: 'brand.blue.100',
      cursor: 'pointer',
    },
    _disabled: {
      bgColor: 'brand.neutral.white',
      color: 'brand.neutral.600',
      border: 'none',
      cursor: 'default',

      _hover: {
        bgColor: 'brand.neutral.white',
        color: 'brand.neutral.600',
        border: 'none',
        cursor: 'default',
      },
      _focus: {
        bgColor: 'brand.neutral.white',
        color: 'brand.neutral.600',
        border: 'none',
        cursor: 'default',
      },
      _active: {
        bgColor: 'brand.neutral.white',
        color: 'brand.neutral.600',
        border: 'none',
        cursor: 'default',
      },
    },
  };
};

export const dividerStyles = (isFirstItem) => {
  return {
    ...dropdownDividerStyles,
    display: isFirstItem && 'none',
  };
};
