import { React } from 'react';
import { FormControl, FormErrorMessage, Select } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import { numbersOnlyMask } from '@core/utils/masks';
import { BaselaneInput, BaselaneFormLabel, BaselaneRoutingNumberField } from '@shared/components';
import { AccountType } from '../../helpers/constants.helpers';
import { formInputStyles } from '../../styles/form.styles';

const PaymentMethodFieldsACH = () => {
  const { values, handleChange, handleBlur, setFieldTouched, errors, touched } = useFormikContext();

  const accountTypeOptions = [
    { id: AccountType.SAVINGS, name: 'Savings' },
    { id: AccountType.CHECKING, name: 'Checking' },
    { id: AccountType.NOT_SURE, name: "I'm not sure" },
  ];

  return (
    <>
      {/* Account Holder's Name */}
      <FormControl
        id="account-holder-name"
        isRequired
        isInvalid={errors.accountHolderName && touched.accountHolderName}
      >
        <BaselaneFormLabel textStyle="sm">Account holder&lsquo;s name</BaselaneFormLabel>
        <BaselaneInput
          size="lg"
          id="accountHolderName"
          name="accountHolderName"
          value={values.accountHolderName}
          type="text"
          onChange={(e) => {
            setFieldTouched('accountHolderName');
            handleChange(e);
          }}
          onBlur={handleBlur}
          maxLength={50}
        />
        <FormErrorMessage>{errors.accountHolderName}</FormErrorMessage>
      </FormControl>

      {/* Routing Number */}
      <FormControl
        id="routing-number"
        isRequired
        isInvalid={errors.routingNumber && touched.routingNumber}
      >
        <BaselaneFormLabel>Routing number</BaselaneFormLabel>
        <BaselaneRoutingNumberField
          id="routingNumber"
          routingNumberFieldName="routingNumber"
          bankNameFieldName="bankName"
        />
        <FormErrorMessage>{errors.routingNumber}</FormErrorMessage>
      </FormControl>

      {/* Account Number */}
      <FormControl
        id="account-number"
        isRequired
        isInvalid={errors.accountNumber && touched.accountNumber}
      >
        <BaselaneFormLabel>Account number</BaselaneFormLabel>
        <BaselaneInput
          size="lg"
          id="accountNumber"
          name="accountNumber"
          value={values.accountNumber}
          as={MaskedInput}
          mask={numbersOnlyMask}
          onChange={(e) => {
            setFieldTouched('accountNumber');
            handleChange(e);
          }}
          onBlur={handleBlur}
          maxLength={50}
        />
        <FormErrorMessage>{errors.accountNumber}</FormErrorMessage>
      </FormControl>

      {/* Account Type */}
      <FormControl
        id="account-type"
        isRequired
        isInvalid={errors.accountType && touched.accountType}
      >
        <BaselaneFormLabel>Account type</BaselaneFormLabel>
        <Select
          {...formInputStyles}
          id="accountType"
          name="accountType"
          value={values.accountType}
          placeholder="Select account type"
          onChange={(e) => {
            setFieldTouched('accountType');
            handleChange(e);
          }}
          onBlur={handleBlur}
        >
          {accountTypeOptions.map((option) => (
            <option key={option.id} defaultValue={values.accountType}>
              {option.name}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors.accountType}</FormErrorMessage>
      </FormControl>
    </>
  );
};

export default PaymentMethodFieldsACH;
