import { useContext, useEffect, useState } from 'react';
import UserContext from '@contexts/UserContext';

/**
 * Checks whether users are part of the 2024-07_sign_up_page_experiment, and whether their email and/or phone are verified
 * Rather than initiating the A/B test again, check the survey version completed by the user
 * If the user completed version 1.3, it means they completed the onboarding survey from the A/B Test variant
 */
const useUserSignUpExperiment = () => {
  const [loading, setLoading] = useState(true);
  const [isTestVariant, setTestVariant] = useState(false);
  const [isUserEmailUnverified, setUserEmailUnverified] = useState(false);
  const [requiresPhoneNumber, setRequiresPhoneNumber] = useState(false);

  const { emailVerified, user } = useContext(UserContext);

  useEffect(() => {
    const { userMetadata, phoneNumber, phoneNumberVerified } = user || {};
    const { onboardingSurvey } = userMetadata || {};
    const testSurvey = onboardingSurvey?.find((survey) => survey.version === '1.3'); // undefined if no survey found
    setTestVariant(typeof testSurvey === 'object');
    setUserEmailUnverified(typeof testSurvey === 'object' && !emailVerified);
    setRequiresPhoneNumber(typeof testSurvey === 'object' && !phoneNumber && !phoneNumberVerified);
    setLoading(false);
  }, [user?.phoneNumber, user?.userMetadata, emailVerified]);

  return { isTestVariant, isUserEmailUnverified, requiresPhoneNumber, loading };
};

export default useUserSignUpExperiment;
