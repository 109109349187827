import React from 'react';
import { Center, Tbody, Td, Text } from '@chakra-ui/react';
import BaselaneResponsiveTableRow from './BaselaneResponsiveTableRow';

type BaselaneResponsiveTableEmptyStateProps = {
  /**
   * Flag used to show/hide empty state component
   */
  showEmptyState: Boolean,
  /**
   * Children of this component can be used to show in empty state
   */
  children?: React.Node | null,
  /**
   * Text to appear in empty state
   */
  text?: String,
  /**
   * External styles for row
   */
  styles?: String,
  /**
   * Increase length of td
   */
  colSpan?: String,
};

/**
 * Renders empty state that can be used with the table
 *
 * @returns {JSX.Element}
 * @component
 */
const BaselaneResponsiveTableEmptyState = ({
  showEmptyState,
  children,
  text,
  styles,
  colSpan,
}: BaselaneResponsiveTableEmptyStateProps) => {
  return (
    showEmptyState && (
      <Tbody>
        <BaselaneResponsiveTableRow
          isHeader
          contentContainerStyles={{ position: 'relative', ...styles }}
        >
          <Td textAlign="center" colSpan={colSpan}>
            <Center>{children || <Text>{text}</Text>}</Center>
          </Td>
        </BaselaneResponsiveTableRow>
      </Tbody>
    )
  );
};

BaselaneResponsiveTableEmptyState.defaultProps = {
  children: null,
  text: '',
  styles: {},
  colSpan: 1,
};

export default BaselaneResponsiveTableEmptyState;
