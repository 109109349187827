export const titleStyles = {
  textStyle: 'headline-lg',
  m: '0 !important',
  mb: '16px !important',
};

export const listStyles = () => {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    m: '0 !important',
    gap: '12px',
  };
};
