export const badgeIconBgStyles = {
  borderRadius: '50%',
  p: '6px',
  w: '32px',
  h: '32px',
  bg: 'green.100',
  alignItems: 'center',
  justifyContent: 'center',
};

export const badgeIconContainerStyles = {
  bg: 'transparent',
  color: 'green.800AA',
  w: '20px',
  h: '26px',
};

export const titleTextStyles = {
  ml: '16px',
};

export const textStyles = {
  textStyle: 'sm',
};

export const containerStyles = {
  marginTop: '0px',
  width: 'inherit',
  mb: '0px',
};

export const bodyStyles = {
  mx: '32px',
};

export const closeButtonStyles = {
  border: 'none',
};
