import { fontColors } from '../../colors';

export const baseStyles = {
  bg: 'brand.neutral.white',
  borderRadius: '5px',
  height: '100%',
  direction: 'column',
  boxShadow: '1px 2px 6px rgba(3, 24, 64, 0.04)',
  stripe: {
    h: '10px',
    w: '100%',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
};

export const fontStyles = {
  header: {
    textStyle: 'headline-lg',
  },
};

export const cardOneStyles = {
  header: {
    p: { md: '19px 24px', base: '16px' },
    alignItems: 'center',
    borderColor: 'brand.darkBlue.150',
    borderBottomWidth: '1px ',
    borderStyle: ' solid',
    color: 'brand.neutral.700',
    maxHeight: '64px',
  },
  content: {
    w: '100%',
    height: '100%',
  },
};

export const cardTwoStyles = (isMobile) => {
  return {
    container: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderRadius: '4px',
      borderColor: 'brand.darkBlue.150',
      borderWidth: '1px ',
      boxShadow: 'none',
      color: 'brand.neutral.600',
      textStyle: 'xs',
      h: 'auto',
      position: 'relative',
      mb: isMobile ? '16px' : '0',
    },
    innerContainer: {
      w: '100%',
      justify: 'space-between',
      p: '10px 16px',
    },
    borderContainer: {
      position: 'absolute',
      borderTopLeftRadius: '3px',
      borderBottomLeftRadius: '3px',
      h: '100%',
      borderLeftWidth: '4px',
    },
    closeButton: {
      outline: 'none',
      ml: '16px !important',
    },
  };
};

export const getIconContainerStyles = (color) => {
  return fontColors[color];
};

export const cardSkeletonStyles = {
  container: {
    mb: '24px',
    mx: '24px',
    p: '24px',
    bg: 'brand.blue.50',
    h: 'auto',
  },
  title: {
    textStyle: 'headline-sm',
    color: 'brand.neutral.700',
    mb: '8px',
  },
  description: {
    textStyle: 'xs',
    color: 'brand.neutral.600',
    mb: '16px',
  },
};

export const messageCardTitleContainerStyles = (isMobile) => ({
  color: 'brand.neutral.700',
  flex: '1 0 auto',
  size: isMobile ? 'headline-md' : 'headline-sm',
  mt: isMobile ? '4px' : '0',
  mb: isMobile ? '8px' : '0',
  textAlign: 'left',
});

export const messageCardDescriptionStyles = {
  textAlign: 'left',
  flex: '1 1 auto',
  mt: '0 !important',
  fontWeight: 'normal',
  color: 'brand.neutral.600',
};
