import React from 'react';

const BaselaneIconLogo = ({
  color = 'currentColor',
  w = 32,
  h = 32,
}: {
  color: string,
  w: number,
  h: number,
}) => {
  return (
    <svg width={w} height={h} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <g clipPath="url(#c)" fill={color}>
            <path d="m9.6369 11.4032 6.9384-2.86114c.5687-.20124 1.2336-.11374 1.7849.23624l3.2898 2.0124c.0612.035.1312 0 .1312-.0788V6.69591c0-.06124-.0262-.11374-.0787-.14874l-3.3423-2.16988c-.5513-.36748-1.2162-.47247-1.7849-.28873L9.62815 6.62592c-.07.02625-.11375.08749-.11375.16624v4.53224c0 .0613.06125.105.1225.0788ZM23.7502 19.8989l-5.0047-2.8523c-.84-.4813-1.8462-.56-2.7036-.21L5.11374 21.2288C5.04375 21.2551 5 21.3163 5 21.3951v6.5184c0 .0612.06125.105.12249.0787L16.0331 23.6c.8575-.385 1.8637-.35 2.7036.0874l4.9698 2.6074c.0612.0262.1312-.0088.1312-.0787v-6.1685c0-.0612-.035-.1224-.0875-.1487Z" />
            <path d="m7.41469 19.0765 8.89821-3.5873c.7175-.28 1.5487-.21 2.2487.1925l4.1385 2.3886c.0612.035.1312 0 .1312-.0787v-5.1185c0-.0612-.035-.1137-.0875-.1487l-4.1822-2.5724c-.7-.42871-1.5312-.5337-2.2487-.27997L7.39719 13.4681c-.07.0262-.11374.0875-.11374.1662v5.3635c0 .0612.06124.105.12249.0787h.00875Z" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
        <clipPath id="c">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BaselaneIconLogo;
