import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { Box, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { useShallow } from 'zustand/react/shallow';

import {
  BaselaneCardNew,
  BaselaneDivider,
  BaselaneDrawer,
  BaselaneSummaryCard,
  TwoFactorVerificationPopUp,
  useTwoFactor,
} from '@shared/components';
import { OTPProvider } from '@contexts/OTPContext';
import TransactionContext from '@contexts/TransactionContext';
import BankEntityContext from '@contexts/BankEntityContext';
import {
  getIsTokenExpired,
  getIsTokenValid,
  getSensitiveToken,
  getUnitOTPVerification,
  handleNBOnVerifyClick,
  handleOTPPopupClose,
  handleOTPPopupOpen,
  setTokenData,
} from '@core/components/NativeBankingPage/helpers/otp.helpers';
import {
  CLOSE_BASELANE_SUBACCOUNT,
  UPDATE_MULITPLE_BANK_ACCOUNTS,
} from '@core/components/NativeBankingPage/queries';
import getBreakPoints from '@core/utils/getBreakPoints';
import SlLoader from '@core/components/Loader';
import { Icon16ChevronRight, Icon16Delete } from '@icons/16px';
import { Icon24CheckPayment } from '@icons/24px';
import {
  handleEditModeChange,
  handleEditModeState,
} from '@core/components/NativeBankingPage/components/IndividualInputEditButtons';
import { ORDER_CHECKBOOK } from '@routes';
import useCheckbookStore from '@store/Checkbook';
import useUserSignUpExperiment from '@core/hooks/useUserSignUpExperiment';

import AutoTagDropdown from './AutoTagDropdown';
import BalanceSummary from './BalanceSummary';
import AccountDetails from './AccountDetails';
import CloseAccountButton from './CloseAccount';
import CloseSubAccountAlert from './CloseAccount/CloseSubAccountAlert';
import AccountName from './AccountName';
import UnableToOrderCheckbookAlert from './UnableToOrderCheckbookAlert';
import MustVerifyEmailAlert from './MustVerifyEmailAlert';
import { refetchQueries } from './helpers/mutation.helper';
import { headingSectionTextStyles, subTextTextStyles } from './styles/font.styles';
import useEntityByIdQuery from '../shared/useEntityByIdQuery';

type AccountDetailsDrawerProps = {
  phoneNumber: String,
  itemId: String,
  bankId: String,
};

const AccountDetailsDrawer = ({ phoneNumber, itemId, bankId }: AccountDetailsDrawerProps) => {
  const navigate = useNavigate();
  const currLocation = useLocation();
  const { value: isCheckbooksEnabled } = useGate('checkbook_gate');
  const [setStep, setSelectedAccount] = useCheckbookStore(
    useShallow((state) => [state.setStep, state.setSelectedAccount])
  );

  const { refetchAccountsData } = useContext(TransactionContext);
  const { selectedAccountToView: account, editAccountRef } = useContext(BankEntityContext);
  const { refetchEntity } = useEntityByIdQuery();
  const { isUserEmailUnverified } = useUserSignUpExperiment();
  const [isSaving, setIsSaving] = useState(false);

  const { isMax576, isMin768: showDesktopView } = getBreakPoints();

  // Mutation
  const [closeBaselaneSubAccount, { loading }] = useMutation(CLOSE_BASELANE_SUBACCOUNT, {
    refetchQueries,
  });

  const [updateMultipleBankAccounts, { loading: updateBankAccountLoading }] = useMutation(
    UPDATE_MULITPLE_BANK_ACCOUNTS,
    {
      refetchQueries,
    }
  );

  // Alert State
  const { isOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const {
    isOpen: isUnableToOrderCheckbookAlertOpen,
    onOpen: onUnableToOrderCheckbookAlertOpen,
    onClose: onUnableToOrderCheckbookAlertClose,
  } = useDisclosure();
  const {
    isOpen: isEmailUnverifiedAlertOpen,
    onOpen: onEmailUnverifiedAlertOpen,
    onClose: onEmailUnverifiedAlertClose,
  } = useDisclosure();

  // State variables
  const [canCloseSubAccount, setCanCloseSubAccount] = useState(true);
  const [action, setAction] = useState(null);

  const getFormattedAutoTagData = (propertyId, unitId) => {
    const formattedPropertyId = propertyId?.toString() ?? propertyId;
    const puid = propertyId && unitId ? `${propertyId}-${unitId}` : formattedPropertyId;
    return { formattedPropertyId, puid };
  };

  const initialValues = () => {
    // set up values for card details form => editModeValues variable state
    const { propertyId = null, propertyUnitId: unitId = null } = account?.autoTag ?? {};
    const { formattedPropertyId, puid } = getFormattedAutoTagData(propertyId, unitId);
    return {
      propertyId: formattedPropertyId,
      unitId: unitId?.toString() ?? unitId,
      propertyUnitId: propertyId ? puid : null,
      nickname: { isEdit: false, value: account?.nickName ?? account?.accountName },
    };
  };
  const [editModeValues, setEditModeValues] = useState(initialValues());

  // to update the initial values when drawer is opened because initially it was set
  //  as users go to entity page
  useEffect(() => {
    if (account.autoTag || account.nickName) {
      setEditModeValues(initialValues());
    }
  }, [account.autoTag, account.nickName]);

  // OTP for this file is for terminating subaccount/virtual account
  const { states, stateFunctions } = useTwoFactor();
  const { setOTPErrorCode } = stateFunctions;

  // OTP: Check if token of the bank expired
  const isTokenExpired = getIsTokenExpired(bankId);

  const handleCloseSubAccount = () => {
    onAlertOpen();
  };

  // Toast
  const toast = useToast();
  const showErrorToast = () =>
    toast({
      description: `Failed to close account: ${account?.name}`,
      status: 'error',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const showUpdateErrorToast = () =>
    toast({
      description: `Failed to update account`,
      status: 'error',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const showSuccessToast = () =>
    toast({
      position: !showDesktopView ? 'bottom' : 'bottom-left',
      description: 'Updated information successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const onCloseSubAccount = () => {
    closeBaselaneSubAccount({
      variables: { id: account.id, plaidItemId: itemId },
    })
      .then(async (data) => {
        const hasError = data?.errors?.length > 0;
        if (hasError) {
          setCanCloseSubAccount(false);
          showErrorToast();
        } else {
          onAlertClose();
          editAccountRef?.current?.close();
          setIsSaving(true);
          await refetchEntity();
          await refetchAccountsData();
          setIsSaving(false);
        }
      })
      .catch(() => {
        setCanCloseSubAccount(false);
        showErrorToast();
      });
  };

  // on change of any editable field in card details gets updated in this function
  const handleOnEditChange = (e) => {
    handleEditModeChange(e, setEditModeValues);
  };

  const handleSave = (newPropertyId) => {
    const [newPId, newUId] = newPropertyId?.length > 0 ? newPropertyId.split('-') : [];

    setIsSaving(true);
    updateMultipleBankAccounts({
      variables: {
        input: {
          id: account.id,
          nickName: editModeValues.nickname.value,
          ...(newPropertyId
            ? {
                autoTag: {
                  propertyId: newPId,
                  propertyUnitId: newUId || null,
                  enabled: true,
                },
              }
            : {}),
        },
      },
    })
      .then(async ({ data, errors }) => {
        if (errors?.length > 0) {
          showUpdateErrorToast();
          setIsSaving(false);
        } else {
          const { nickName: newNickname, autoTag: newAutotag } =
            data?.updateMultipleBankAccount?.[0] || {};

          await refetchEntity();
          await refetchAccountsData();
          setIsSaving(false);

          const { propertyId = null, propertyUnitId: unitId = null } = newAutotag ?? {};
          const { formattedPropertyId, puid } = getFormattedAutoTagData(propertyId, unitId);
          setEditModeValues({
            propertyId: formattedPropertyId,
            unitId: unitId?.toString() ?? unitId,
            propertyUnitId: propertyId ? puid : null,
            nickname: { isEdit: false, value: newNickname ?? account?.accountName },
          });
          showSuccessToast();
        }
      })
      .catch((error) => {
        console.error(error);
        showUpdateErrorToast();
        setIsSaving(false);
      });
  };

  const onCloseAlert = () => {
    if (!canCloseSubAccount) setCanCloseSubAccount(true);
    onAlertClose();
  };

  const onUserSensitiveTokenComplete = (unitAPISensitiveToken) => {
    const isTokenValid = getIsTokenValid(unitAPISensitiveToken);
    setTokenData(unitAPISensitiveToken, bankId);

    if (isTokenValid) {
      if (action === 'close') {
        onCloseSubAccount();
      } else {
        handleSave();
      }
    }

    handleOTPPopupClose(states, stateFunctions);
  };

  const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

  const getSensitiveTokenProps = {
    onUserSensitiveTokenComplete,
    getOTP,
    bankId,
    states,
    stateFunctions,
  };

  const [getUserSensitiveTokenData] = getSensitiveToken(getSensitiveTokenProps);

  // helper function to help toggle the state of the inputs that are editable
  const handleEditModeToggle = (
    inputName,
    shouldToggleOn,
    shouldSave,
    initvalues = initialValues()
  ) => {
    handleEditModeState(inputName, shouldToggleOn, shouldSave, setEditModeValues, initvalues);
  };

  // toggles state of the inputs that are editable and saves when shouldSave is true
  const handleEditModeToggleOrSave = (inputName, shouldToggleOn, shouldSave) => {
    if (shouldSave && !shouldToggleOn) {
      if (isTokenExpired) {
        setAction('save');
        handleOTPPopupOpen(getOTP, bankId, stateFunctions);
      } else {
        handleSave();
      }
    } else {
      handleEditModeToggle(inputName, shouldToggleOn, shouldSave);
    }
  };

  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP,
    bankId,
    phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleNBOnVerifyClick(otpCode, bankId, getUserSensitiveTokenData),
  };

  const handleDrawerClose = () => {
    editAccountRef.current.close();
    setEditModeValues(initialValues);
  };

  const { isAvailable = false, nextOrderDate = '' } = account?.checkBookAvailability ?? {};

  const { DrawerBody } = BaselaneDrawer;
  return (
    <>
      <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />
      <CloseSubAccountAlert
        onCancel={onCloseAlert}
        onCloseSubAccount={() => {
          if (!isTokenExpired) {
            onCloseSubAccount();
          } else {
            setAction('close');
            handleOTPPopupOpen(getOTP, bankId, stateFunctions);
          }
        }}
        isOpen={isOpen}
        canCloseSubAccount={canCloseSubAccount}
        loading={loading}
      />
      <UnableToOrderCheckbookAlert
        isDrawerAlertOpen={isUnableToOrderCheckbookAlertOpen}
        onAlertClose={onUnableToOrderCheckbookAlertClose}
        nextOrderDate={nextOrderDate}
      />
      {isUserEmailUnverified && (
        <OTPProvider>
          <MustVerifyEmailAlert
            isDrawerAlertOpen={isEmailUnverifiedAlertOpen}
            onAlertClose={onEmailUnverifiedAlertClose}
          />
        </OTPProvider>
      )}

      <BaselaneDrawer
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        ref={editAccountRef}
        title="Account Details"
        closeEvent={handleDrawerClose}
        newDesignDrawer
      >
        <DrawerBody id="account-details-drawer" position="relative" p="0">
          {isSaving && <SlLoader showOverlay overlayStyles={{ top: !isMax576 ? '64px' : '0' }} />}
          <Stack spacing={3}>
            <BaselaneSummaryCard
              header={
                <AccountName
                  accountSubType={account.accountSubType}
                  editModeValues={editModeValues}
                  accountNumber={account?.accountNumber}
                  handleEditModeToggleOrSave={handleEditModeToggleOrSave}
                  handleOnEditChange={handleOnEditChange}
                  isDisabled={isSaving || updateBankAccountLoading}
                />
              }
              list={[
                {
                  id: 'balance-summary',
                  items: [<BalanceSummary account={account} />],
                },
              ]}
            />

            <AutoTagDropdown
              values={editModeValues}
              handleSave={handleSave}
              isDisabled={isSaving}
            />

            {isCheckbooksEnabled && account.accountSubType === 'checking' && (
              <Stack spacing={1}>
                <Text textStyle="headline-sm">Account services</Text>
                <BaselaneCardNew
                  variant="clickable"
                  size="md"
                  onClick={() => {
                    if (isUserEmailUnverified) {
                      onEmailUnverifiedAlertOpen();
                    } else if (isAvailable) {
                      setSelectedAccount(account);
                      setStep(1);
                      handleDrawerClose();
                      navigate(`${currLocation.pathname}/${ORDER_CHECKBOOK}`, {
                        state: {
                          customCheckbookInitialValues: {
                            bankAccountId: account?.id,
                            address: account?.mailingAddress?.street ?? '',
                            unit: account?.mailingAddress?.unit ?? '',
                            state: account?.mailingAddress?.state ?? '',
                            zipcode: account?.mailingAddress?.postalCode ?? '',
                            city: account?.mailingAddress?.city ?? '',
                            country: account?.mailingAddress?.country ?? '',
                          },
                        },
                      });
                    } else {
                      onUnableToOrderCheckbookAlertOpen();
                    }
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    <Box mt={0.75}>
                      <Icon24CheckPayment />
                    </Box>
                    <Stack spacing={0.5}>
                      <Text textStyle="headline-sm">Order a checkbook</Text>
                      <Text textStyle="xs" sx={{ textWrap: 'wrap' }}>
                        $15 for 80 checks · Delivered in 10-14 business days
                      </Text>
                    </Stack>
                  </Stack>
                  <Box ml="auto">
                    <Icon16ChevronRight />
                  </Box>
                </BaselaneCardNew>
              </Stack>
            )}
          </Stack>

          <BaselaneDivider my={3} />

          <AccountDetails account={account} />

          {/* Close Account */}
          {!account.isMainAccount && <BaselaneDivider styles={{ mt: '32px', mb: '32px' }} />}
          {!account.isMainAccount && (
            <Stack direction="column" justifyContent="space-between" align="flex-start" spacing={3}>
              <Stack spacing={showDesktopView ? 0 : 1}>
                <Text {...headingSectionTextStyles}>Close account</Text>
                <Text {...subTextTextStyles}>
                  To close this account, it must have a zero balance and no pending rent collection
                  or transfers/payments.
                </Text>
              </Stack>
              <CloseAccountButton
                handleCloseSubAccount={handleCloseSubAccount}
                buttonProps={{
                  size: 'md',
                  leftIcon: <Icon16Delete />,
                  isDisabled: isSaving || loading,
                  isFullWidth: !showDesktopView,
                }}
              />
            </Stack>
          )}
        </DrawerBody>
      </BaselaneDrawer>
    </>
  );
};

export default AccountDetailsDrawer;
