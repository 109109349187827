import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import { Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { CREATE_PROPERTY_SIMPLE, FRAGMENT_PROPERTY_SIMPLE } from '@core/apollo/queries';
import { BaselaneButton, BaselaneButtonGroup, BaselaneCardNew } from '@shared/components';
import { IconArrowLeft, IconArrowRightTwo } from '@icons';
import { Icon24Home } from '@icons/24px';
import AddPropertyFormLayout from '@pages/PropertiesPage/AddPropertyFormLayout';
import { ONBOARDING_TRIAGE, UNIFIED_BOOKKEEPING_ANALYTICS, UNIFIED_RENT_COLLECTION } from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import {
  getInitialValues,
  handlePropertySave,
  handleValidateSave,
  setFormData,
} from '@pages/PropertiesPage/helpers/detail.helper';
import {
  addPropertyButtonContainerStyles,
  continueButtonStyles,
  headingTextStyles,
} from './styles/addProperty.styles';

const AddProperty = ({
  handleAddedProperty,
  handleFooterNavigation,
  isInUnifiedRCFlow,
  isInUnifiedBAFlow,
}: {
  handleAddedProperty?: Function,
  handleFooterNavigation?: Function,
  isInUnifiedRCFlow: boolean,
  isInUnifiedBAFlow: boolean,
  title?: String,
  description?: String,
}) => {
  const formRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const [shortForm, setShortForm] = useState(false);
  const initialValues = () => getInitialValues(null, true);
  const [variables, setVariables] = useState(initialValues());
  const [hasEnteredAddress, setHasEnteredAddress] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  let cleanUpRoute = ONBOARDING_TRIAGE;
  if (isInUnifiedRCFlow) {
    cleanUpRoute = UNIFIED_RENT_COLLECTION;
  } else if (isInUnifiedBAFlow) {
    cleanUpRoute = UNIFIED_BOOKKEEPING_ANALYTICS;
  }

  const [createProperty] = useMutation(CREATE_PROPERTY_SIMPLE, {
    update: (cache, { data: { createProperty: newProperty } }) => {
      const newPropertyRef = cache.writeFragment({
        data: newProperty,
        fragment: FRAGMENT_PROPERTY_SIMPLE,
      });
      cache.modify({
        fields: {
          property(existingProperties = []) {
            return [...existingProperties, newPropertyRef];
          },
        },
      });
    },
  });

  const handleOnContinue = () => {
    setHasSubmitted(true);
    handlePropertySave({
      property: null,
      variables,
      createProperty,
      onPropertyUnitSelected: handleAddedProperty,
      isInUnifiedRCFlow,
      isInUnifiedBAFlow,
      shortForm,
      setShortForm,
      cleanUp: () => navigate(cleanUpRoute, { state: location?.state }),
    });
  };

  const handleSaveAndContinue = () => {
    handleValidateSave(formRef, handleOnContinue);
  };

  const setFormVariables = (newVariables) => setFormData(newVariables, setVariables, variables);

  useEffect(() => {
    if (isInUnifiedRCFlow) {
      sendSegmentEvent('onboarding_rc_click_add_property');
    } else if (isInUnifiedBAFlow) {
      sendSegmentEvent('onboarding_ba_click_add_property');
    } else {
      sendSegmentEvent('add_property_started');
    }
  }, []);

  useEffect(() => {
    if (variables.address !== '' && formRef?.current) {
      const pAddress = variables.address.trim();
      formRef?.current.setFieldValue('propertyName', pAddress);
      setFormVariables({ propertyName: pAddress });
    }
  }, [variables.address]);

  return (
    <BaselaneCardNew mt="0 !important" mb="24px !important">
      <Flex direction={isMobile ? 'column' : 'row'}>
        <VStack m={isMobile ? '8px 24px 8px 0' : '2px 24px 0 8px'} alignItems="left">
          <Icon24Home w="24px" h="24px" />
        </VStack>
        <VStack alignItems="start" pr={isMobile ? '0' : '8px'} pb={isMobile ? '0' : '8px'} w="100%">
          <Heading as="h2" {...headingTextStyles(isMobile)} textAlign="left">
            Enter your rental property address
          </Heading>
          <Text
            {...{
              textStyle: 'xs',
              color: 'brand.neutral.600',
              mb: '16px !important',
            }}
          >
            You will be able to edit this information and add more properties later.
          </Text>

          <AddPropertyFormLayout
            shortForm
            {...{
              showTitle: false,
              initialValues: initialValues(),
              setFormVariables,
              formRef,
              property: null,
              hasEnteredAddress,
              setHasEnteredAddress,
            }}
          />

          <Flex {...addPropertyButtonContainerStyles()} mt="16px !important">
            <BaselaneButtonGroup size="md">
              {(isInUnifiedRCFlow || isInUnifiedBAFlow) && !isMobile && (
                <BaselaneButton
                  variant="outline"
                  palette="neutral"
                  size="md"
                  leftIcon={<IconArrowLeft />}
                  onClick={() => handleFooterNavigation(true)}
                >
                  Previous
                </BaselaneButton>
              )}
              <BaselaneButton
                id="continue-button"
                size="md"
                variant="filled"
                palette="primary"
                rightIcon={!isMobile && <IconArrowRightTwo />}
                onClick={handleSaveAndContinue}
                styles={continueButtonStyles(hasEnteredAddress)}
                isDisabled={hasSubmitted}
              >
                Continue
              </BaselaneButton>
            </BaselaneButtonGroup>
          </Flex>
        </VStack>
      </Flex>
    </BaselaneCardNew>
  );
};

AddProperty.defaultProps = {
  handleAddedProperty: () => {},
  handleFooterNavigation: () => {},
  title: null,
  description: null,
};

export default AddProperty;
