import React, { useState, useRef, useEffect } from 'react';
import { Box, HStack, Spinner, Stack } from '@chakra-ui/react';
import { BaselaneErrorCard } from '@shared/components';

import CashFlowDataWrapper from '../CashFlowDataWrapper';
import CashFlowByTypeWrapper from '../CashFlowByTypeWrapper';
import TransactionsWrapper from '../TransactionsWrapper';
import { spinnerStyles } from '../styles/shared.styles';

type CashflowAnalyticsProps = {
  filters: Object,
  setActiveMonth: Function,
  activeMonth: string,
  lastPreset?: String,
  hasNoData: boolean,
  loading: boolean,
  error: boolean,
  data: Object,
  refetch: Function,
};

function CashflowAnalytics({
  filters,
  setActiveMonth,
  activeMonth,
  lastPreset,
  hasNoData,
  loading,
  error,
  data,
  refetch,
}: CashflowAnalyticsProps) {
  if (loading) return <Spinner {...spinnerStyles} />;
  if (error) {
    if (error.message.includes('Failed to fetch')) {
      return <BaselaneErrorCard />;
    }
    return null;
  }

  const { uncategorized, ...defaultTrxFilters } = filters.filter;

  const filterSummaryRef = useRef({});
  const [isCashFlowByTypeAnimating, setIsCashFlowByTypeAnimating] = useState(false);
  const [subFilters, setSubFilters] = useState({});
  const [trxFilters, setTrxFilters] = useState({
    ...defaultTrxFilters,
    isCategorized: !uncategorized,
  });

  const onAnimationStart = () => {
    setIsCashFlowByTypeAnimating(true);
  };

  const onAnimationComplete = () => {
    setIsCashFlowByTypeAnimating(false);
  };

  const handleSubFiltersUpdate = (subfilters) => {
    const {
      categorySelected,
      subCategorySelected,
      dateSelected,
      ...subFiltersSelected
    } = subfilters;

    filterSummaryRef.current = {
      ...filterSummaryRef.current,
      ...((categorySelected || categorySelected === null) && { categorySelected }),
      ...((subCategorySelected || subCategorySelected === null) && { subCategorySelected }),
      ...((dateSelected?.dateFormatted || dateSelected?.dateFormatted === null) && {
        dateSelected: dateSelected?.dateFormatted,
      }),
    };

    if (dateSelected) setActiveMonth(dateSelected?.date);
    setSubFilters({ ...subFilters, ...subFiltersSelected });
  };

  useEffect(() => {
    const hasUncategorized = Array.isArray(subFilters?.tagId)
      ? subFilters?.tagId?.find((id) => id === '0')
      : subFilters?.tagId === '0';
    const isCategorized = subFilters?.tagId ? !hasUncategorized : !uncategorized;

    setTrxFilters({ ...defaultTrxFilters, isCategorized, ...subFilters });
  }, [subFilters]);

  useEffect(() => {
    refetch();
    setActiveMonth(null);
    setTrxFilters({
      ...defaultTrxFilters,
      isCategorized: !uncategorized,
    });
    filterSummaryRef.current = {};
    setSubFilters({});
  }, [filters]);

  return (
    <Stack {...{ pt: '24px' }}>
      <HStack alignItems="stretch">
        <Box w="62%">
          <CashFlowDataWrapper
            {...{
              filters,
              data,
              lastPreset,
              handleSubFiltersUpdate,
              hasNoData,
            }}
            onSelectMonth={setActiveMonth}
            selectedMonth={activeMonth}
          />
        </Box>
        <Box w="38%">
          <CashFlowByTypeWrapper
            {...{
              handleSubFiltersUpdate: (subFilterParams) => {
                if (!subFilterParams) setIsCashFlowByTypeAnimating(true);
                handleSubFiltersUpdate(subFilterParams);
              },
              onAnimationStart,
              onAnimationComplete,
              hasNoData,
            }}
            selectedMonth={activeMonth}
          />
        </Box>
      </HStack>
      <TransactionsWrapper
        {...{
          trxFilters,
          filterSummary: filterSummaryRef.current,
          hasNoData,
          isCashFlowByTypeAnimating,
        }}
      />
    </Stack>
  );
}

CashflowAnalytics.defaultProps = {
  lastPreset: null,
};

export default CashflowAnalytics;
