import React from 'react';
import { Flex, FormControl, FormLabel, Text, Spacer } from '@chakra-ui/react';
import { Field } from 'formik';
import { BaselaneTextArea, BaselaneFormErrorMessage } from '@shared/components';
import Category from './Category';
import Property from './Property';

const BookkeepingDetails = () => {
  return (
    <>
      <Flex>
        <Text textStyle="md-heavy">Bookkeeping details</Text>
        <Spacer />
        <Text textStyle="xs">Step 3 of 4</Text>
      </Flex>
      <Category />
      <Property />
      {/* Notes */}
      <Field name="notes">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.notes && form.touched.notes} mt={3}>
            <FormLabel display="flex">
              Notes
              <Text color="brand.neutral.500" ml={0.5}>
                (optional)
              </Text>
            </FormLabel>
            <BaselaneTextArea {...field} placeholder="Add any notes for yourself" />
            <BaselaneFormErrorMessage isInvalid={form.errors.notes && form.touched.notes}>
              {form.errors.notes}
            </BaselaneFormErrorMessage>
          </FormControl>
        )}
      </Field>
    </>
  );
};

export default BookkeepingDetails;
