import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useShallow } from 'zustand/react/shallow';
import MaskedInput from 'react-text-mask';
import {
  Text,
  Divider,
  FormControl,
  FormErrorMessage,
  Input,
  FormLabel,
  Select,
  Stack,
} from '@chakra-ui/react';

import {
  BaselaneRadioGroup,
  BaselaneRadio,
  BaselaneFormLabel,
  BaselaneAutoCompleteAddress,
  BaselaneAddress,
} from '@shared/components';
import { formInputStyles, formErrorStyles } from '@shared/styles/input.style';
import useCheckbookStore from '@store/Checkbook';
import { zipcodeMask } from '@core/utils/masks';
import { formattedAddress } from '../helpers/form.helper';

const MailingAddress = () => {
  const { handleChange, handleBlur, setValues, values, errors, touched } = useFormikContext();
  const { account } = useOutletContext() ?? {};

  const [selectedMailing, setSelectedMailing, otherAddress, setOtherAddress] = useCheckbookStore(
    useShallow((state) => [
      state.selectedMailing,
      state.setSelectedMailing,
      state.otherAddress,
      state.setOtherAddress,
    ])
  );

  const [hasEnteredAddress, setHasEnteredAddress] = useState(false);

  const defaultAddress = {
    address: account?.mailingAddress.street,
    unit: account?.mailingAddress.unit,
    state: account?.mailingAddress.state,
    zipcode: account?.mailingAddress.postalCode,
    city: account?.mailingAddress.city,
  };

  const formattedDefaultAddress = formattedAddress(defaultAddress);

  const handleMailingOption = (e) => {
    setSelectedMailing(e);
    if (e === 'default') setValues({ ...values, ...defaultAddress });
    if (e === 'other') setValues({ ...values, ...otherAddress });
  };

  const fieldBlur = (e, field) => {
    setOtherAddress({ [field]: e.target.value });
  };

  const formAddressUpdate = (variables) => {
    setOtherAddress(variables);
  };

  return (
    <>
      <BaselaneFormLabel>
        <Text textStyle="headline-sm">Where would you like your checkbook delivered?</Text>
      </BaselaneFormLabel>
      <BaselaneRadioGroup value={selectedMailing} onChange={handleMailingOption}>
        <>
          <BaselaneRadio
            label={formattedDefaultAddress}
            description="Mailing address on file"
            name="mailing-option"
            value="default"
          />
          <BaselaneRadio label="Different address" name="mailing-option" value="other" />
        </>
      </BaselaneRadioGroup>

      {selectedMailing === 'other' && (
        <>
          <Divider my={2} />

          <BaselaneAutoCompleteAddress
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFormVariables={formAddressUpdate}
            setHasEnteredAddress={setHasEnteredAddress}
            formInputStyles={formInputStyles}
            formErrorStyles={formErrorStyles}
          />

          {hasEnteredAddress && (
            <>
              {/* Unit number */}
              <FormControl position="relative" isInvalid={errors.unit && touched.unit}>
                <FormLabel htmlFor="unitNumber">Apartment, Unit, Suite, or Floor #</FormLabel>
                <Input
                  id="unitNumber"
                  name="unit"
                  value={values.unit}
                  placeholder="e.g. Floor 1"
                  onChange={handleChange}
                  onBlur={(e) => fieldBlur(e, 'unit')}
                  {...formInputStyles}
                />
                <FormErrorMessage {...formErrorStyles}>
                  <Text>{errors.unit}</Text>
                </FormErrorMessage>
              </FormControl>

              <Stack direction="row" spacing={2}>
                {/* City */}
                <FormControl
                  position="relative"
                  w="50%"
                  isInvalid={errors.city && touched.city}
                  isRequired
                >
                  <FormLabel htmlFor="city">City</FormLabel>
                  <Input
                    id="city"
                    name="city"
                    value={values.city}
                    placeholder="e.g. Philadelphia"
                    onChange={handleChange}
                    onBlur={(e) => fieldBlur(e, 'city')}
                    {...formInputStyles}
                  />
                  <FormErrorMessage {...formErrorStyles}>
                    <Text>{errors.city}</Text>
                  </FormErrorMessage>
                </FormControl>

                {/* State */}
                <FormControl
                  position="relative"
                  w="25%"
                  isInvalid={errors.state && touched.state}
                  isRequired
                >
                  <FormLabel htmlFor="state">State</FormLabel>
                  <Select
                    p="0"
                    id="state"
                    name="state"
                    value={values.state}
                    placeholder="Select state"
                    onChange={handleChange}
                    onBlur={(e) => fieldBlur(e, 'state')}
                    {...formInputStyles}
                  >
                    <BaselaneAddress.StateOptions />
                  </Select>
                  <FormErrorMessage {...formErrorStyles}>
                    <Text>{errors.state}</Text>
                  </FormErrorMessage>
                </FormControl>

                {/* Zip Code */}
                <FormControl
                  position="relative"
                  w="25%"
                  isInvalid={errors.zipcode && touched.zipcode}
                  isRequired
                >
                  <FormLabel htmlFor="zipcode" mr="0">
                    Zip Code
                  </FormLabel>
                  <Input
                    as={MaskedInput}
                    mask={zipcodeMask}
                    id="zipcode"
                    name="zipcode"
                    value={values.zipcode}
                    placeholder="e.g. 19123"
                    type="text"
                    onChange={handleChange}
                    onBlur={(e) => fieldBlur(e, 'zipcode')}
                    {...formInputStyles}
                  />
                  <FormErrorMessage {...formErrorStyles}>
                    <Text>{errors.zipcode}</Text>
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MailingAddress;
