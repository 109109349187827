import React from 'react';
import { Box, Text, Spacer } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useSelectedPaymentMethod } from '../../../../../sendFundsStore';

const PaymentMethodDetails = () => {
  const { values } = useFormikContext();
  const selectedPaymentMethod = useSelectedPaymentMethod();

  const formatAddress = (addressObj) => {
    const { street, city, unit, state, postalCode } = addressObj ?? {};
    const propertyUnit = unit ? `${unit},` : '';
    const propertyAddress = `${street}, ${propertyUnit} ${city}, ${state} ${postalCode}`;

    return propertyAddress;
  };
  return (
    <>
      {values?.paymentMethodType === 'ACH' && (
        <>
          <Box display="flex" mt={2}>
            <Text textStyle="xs">Account holder’s name</Text>
            <Spacer />
            <Text flex="2" textAlign="right" textStyle="sm">
              {selectedPaymentMethod?.accountHolderName}
            </Text>
          </Box>
          <Box display="flex" mt={1}>
            <Text textStyle="xs">Routing number</Text>
            <Spacer />
            <Text flex="2" textAlign="right" textStyle="sm">
              {selectedPaymentMethod?.routingNumber}
            </Text>
          </Box>
          <Box display="flex" mt={1}>
            <Text textStyle="xs">Account number</Text>
            <Spacer />
            <Text flex="2" textAlign="right" textStyle="sm">
              {selectedPaymentMethod?.accountNumber}
            </Text>
          </Box>
        </>
      )}
      {values?.paymentMethodType === 'CHECK_PAYMENT' && (
        <>
          <Box display="flex" mt={2}>
            <Text textStyle="xs">Recipient’s name</Text>
            <Spacer />
            <Text textStyle="sm" textAlign="right">
              {selectedPaymentMethod?.name}
            </Text>
          </Box>
          <Box display="flex" mt={1}>
            <Text textStyle="xs">Address</Text>
            <Spacer />
            <Text flex="2" textAlign="right" textStyle="sm">
              {formatAddress(selectedPaymentMethod?.address)}
            </Text>
          </Box>
          {values?.description && (
            <Box display="flex" mt={1}>
              <Text textStyle="xs">Memo</Text>
              <Spacer />
              <Text flex="2" textAlign="right" textStyle="sm">
                {values?.description}
              </Text>
            </Box>
          )}
        </>
      )}
      {values?.paymentMethodType === 'WIRE_TRANSFER' && (
        <>
          <Box display="flex" mt={2}>
            <Text textStyle="xs">Account holder’s name</Text>
            <Spacer />
            <Text flex="2" textAlign="right" textStyle="sm">
              {selectedPaymentMethod?.accountHolderName}
            </Text>
          </Box>
          <Box display="flex" mt={1}>
            <Text textStyle="xs">Routing number</Text>
            <Spacer />
            <Text flex="2" textAlign="right" textStyle="sm">
              {selectedPaymentMethod?.routingNumber}
            </Text>
          </Box>
          <Box display="flex" mt={1}>
            <Text textStyle="xs">Account number</Text>
            <Spacer />
            <Text flex="2" textAlign="right" textStyle="sm">
              {selectedPaymentMethod?.accountNumber}
            </Text>
          </Box>
          <Box display="flex" mt={1}>
            <Text textStyle="xs">Address</Text>
            <Spacer />
            <Text flex="2" textAlign="right" textStyle="sm">
              {formatAddress(selectedPaymentMethod?.address)}
            </Text>
          </Box>
          {values?.description && (
            <Box display="flex" mt={1}>
              <Text textStyle="xs">Message to recipient</Text>
              <Spacer />
              <Text flex="2" textAlign="right" textStyle="sm">
                {values?.description}
              </Text>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default PaymentMethodDetails;
