export const fakeHyphenStyles = (hasInput) => ({
  margin: '0px !important',
  width: '8px !important',
  borderTop: '1px solid',
  borderTopColor: hasInput ? 'initial' : 'inherit',
  opacity: hasInput ? '0.4' : '1',
});

export const searchInputStyles = {
  m: '16px 16px 10px',
  h: '40px',
  padding: '12px 16px  !important',
  borderRadius: '4px',
  border: '1px solid',
  borderTopColor: 'brand.darkBlue.250',
  borderBottomColor: 'brand.darkBlue.250',
  borderLeftColor: 'brand.darkBlue.250',
  borderRightColor: 'brand.darkBlue.250',
  background: 'inherit',
  color: 'brand.neutral.700',
  borderColor: 'brand.darkBlue.250',
  textStyle: 'sm',
  alignItems: 'center',
  gap: '4px',
  _invalid: {
    borderColor: 'red.800AA',
    _focus: {
      borderColor: 'red.800AA',
      color: 'brand.neutral.700',
      boxShadow: 'none',
    },
  },
  _focus: {
    borderColor: 'brand.darkBlue.250',
    color: 'brand.neutral.600',
    boxShadow: 'inset 0px 0px 1px 1px #E1F4FF',
  },
  _hover: { borderColor: 'brand.darkBlue.250', color: 'brand.neutral.600' },
  _disabled: { borderColor: 'brand.darkBlue.100', color: 'brand.darkBlue.300' },
  _placeholder: { color: 'brand.neutral.500' },
};

export const datepickerStyles = (hasFilterWrapper) => {
  return {
    overflowX: 'hidden',
    overflowY: 'auto',
    top: hasFilterWrapper ? '136px' : '72px',
    p: '6px 16px 8px',
    m: '0 !important',
    flex: 1,
    css: {
      '&::-webkit-scrollbar': {
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#E5E9EF',
        backgroundClip: 'padding-box',
        border: '4px solid transparent',
        borderRadius: '8px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#C8D0DA',
        backgroundClip: 'padding-box',
        border: '4px solid transparent',
        borderRadius: '8px',
      },
    },
  };
};
