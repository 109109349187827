// Date formatting functions, using built-in browser APIs

export const toDateRangeFormat = (date: Date) => {
  return new Intl.DateTimeFormat([], {
    timeZone: 'UTC',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(date);
};

export const currentMonth = () => Intl.DateTimeFormat([], { month: 'long' }).format(Date.now());
