import React, { useState } from 'react';
import { Formik } from 'formik';
import { BaselaneDrawer, BaselaneMessageCard, AgreeToTerms } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';

import FormComponent from './FormComponent';
import Footer from './Footer';

type BodyProps = {
  isDirty: boolean,
  formikRef: any,
  initialAccountValues: Object,
  handleValidation: Function,
  handleOnSubmit: Function,
  handleFormUpdate: Function,
  isFormValid: boolean,
  onCloseWithoutSaving: Function,
  isSubmitting: boolean,
  showErrorBanner: boolean,
};

const Body = ({
  isDirty,
  formikRef,
  initialAccountValues,
  handleValidation,
  handleOnSubmit,
  handleFormUpdate,
  isFormValid,
  onCloseWithoutSaving,
  isSubmitting,
  showErrorBanner,
}: BodyProps) => {
  const { DrawerBody } = BaselaneDrawer;
  const { isMax576 } = getBreakPoints();
  const [isCheckboxChecked, setIsCheckBoxChecked] = useState(false);

  return (
    <>
      <DrawerBody p={isMax576 ? 2 : 3} display="flex" flexDirection="column">
        {showErrorBanner && (
          <BaselaneMessageCard
            title="Couldn't add virtual account"
            isVertical
            iconName="close"
            iconColor="red"
            borderColor="red"
            text="Please try again later or contact support"
            iconContainerStyles={{ w: '20px', ml: '7px', mr: '10px' }}
            containerStyles={{ w: '100%', mb: 3 }}
          />
        )}

        <Formik
          innerRef={formikRef}
          initialValues={initialAccountValues}
          enableReinitialize
          validate={handleValidation}
          onSubmit={handleOnSubmit}
        >
          {({ dirty, values, handleChange, handleBlur, setFieldValue, errors, touched }) => (
            <FormComponent
              {...{
                values,
                handleFormUpdate,
                handleBlur,
                handleChange,
                dirty,
                setFieldValue,
                errors,
                touched,
              }}
            />
          )}
        </Formik>
        <AgreeToTerms
          onChange={() => setIsCheckBoxChecked((prevState) => !prevState)}
          isChecked={isCheckboxChecked}
        />
      </DrawerBody>

      <Footer
        isFormValid={isFormValid}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCloseWithoutSaving={onCloseWithoutSaving}
        formikRef={formikRef}
        isCheckboxChecked={isCheckboxChecked}
      />
    </>
  );
};

export default Body;
