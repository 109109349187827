// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=16096%3A177141
import React from 'react';
import { Text, Stack } from '@chakra-ui/react';
import { IconCheckCircleOutline } from '@icons';
import { BaselaneButton, BaselaneDrawer } from '@shared/components';
import customTheme from '@core/theme';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  redeemSuccessContainerStyles,
  redeemSuccessIconContainerStyles,
  redeemSuccessMessageContainerStyles,
  redeemSuccessMessageStyles,
} from './styles/redeemSuccess.styles';

type RedeemProps = {
  onCloseRedeemSuccessDrawer: Function,
  redeemSuccessDrawerRef: any,
};

function Redeem({ onCloseRedeemSuccessDrawer, redeemSuccessDrawerRef }: RedeemProps): any {
  const { isMax767 } = getBreakPoints();

  return (
    <BaselaneDrawer
      ref={redeemSuccessDrawerRef}
      title="Redeem Cash Back"
      closeEvent={() => {
        onCloseRedeemSuccessDrawer();
      }}
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      footer={
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={() => {
            onCloseRedeemSuccessDrawer();
          }}
        >
          Close
        </BaselaneButton>
      }
      newDesignDrawer
    >
      <Stack {...redeemSuccessContainerStyles}>
        <Stack align="center" spacing={4}>
          <Stack {...redeemSuccessIconContainerStyles}>
            <IconCheckCircleOutline w={50} h={50} color={customTheme.colors.green['800AA']} />
          </Stack>
          <Stack align="center" {...redeemSuccessMessageContainerStyles}>
            <Text {...redeemSuccessMessageStyles}>Thank you.</Text>
            <Text {...redeemSuccessMessageStyles}>Your cash back has been redeemed.</Text>
          </Stack>
        </Stack>
      </Stack>
    </BaselaneDrawer>
  );
}

export default Redeem;
