import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, HStack, Image, List, ListItem, VStack } from '@chakra-ui/react';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import getBreakPoints from '@core/utils/getBreakPoints';
import customTheme from '@core/theme';
import { IconCheck } from '@icons';
import { Icon16ChevronRight } from '@icons/16px';
import BaselaneBadge from '../BaselaneBadge';
import BaselaneButtonCard from '../BaselaneButtonCard';
import BaselaneChip from '../BaselaneChip';

import {
  buttonCardStyles,
  containerStyles,
  logoBoxStyles,
  logoStyles,
  primaryContentHeadingStyles,
  supportingContentTextStyles,
  checkListStyles,
  checkListItemStyles,
  checkedListItemIconContainerStyles,
  checkListItemTextContainerStyles,
  badgeButtonStyles,
  badgeButtonIconStyles,
} from './styles/partnerCard.style';

type BaselanePartnerCardProps = {
  /* partner id */
  id: String,
  /* the main text of the ad, either as a string or custom styled component. */
  title: String,
  /* the name of the partner */
  name: String,
  /* the tags for the ad, an array of strings. */
  tags: Array<String>,
  /* the secondary text of the ad, an array of strings or React components. */
  details: Array<any>,
  /* the cta copy for the partner card button */
  ctaCopy: String,
  /* the cta url for the ad. */
  ctaUrl: String,
  /* the cta url for the ad. */
  ctaBehaviour: String,
  /* a base64 string to identify the partner logo. */
  logo: String,
  /* An optional flag to apply styles */
  styles: Object,
};

const BaselanePartnerCard = ({
  id,
  title,
  name,
  tags,
  details = null,
  ctaCopy,
  ctaUrl = '',
  ctaBehaviour = '',
  logo = '',
  styles = {},
  ...rest
}: BaselanePartnerCardProps) => {
  const { isMin768, isMin899 } = getBreakPoints();
  const navigate = useNavigate();
  const handleClickPartnerCard = () => {
    sendSegmentEvent('partnerships_page_partner_clicked', {
      partner: name,
    });
    if (ctaBehaviour && ctaUrl.indexOf('https') > -1) {
      window.open(ctaUrl);
    } else {
      navigate(ctaUrl);
    }
  };

  return (
    <BaselaneButtonCard
      id={`partner-card-${id}`}
      {...{
        type: 'no-border',
        onClick: handleClickPartnerCard,
        buttonStyles: {
          ...buttonCardStyles(ctaUrl, isMin899, isMin768),
          ...styles?.buttoncard,
          ...rest,
        },
      }}
    >
      <HStack w="100%" h="100%">
        <VStack {...{ ...containerStyles, ...styles?.container }} w="100%">
          {/* Header Items - Title in Row or below logo depending on screen size */}
          {/* Title */}
          {isMin768 && (
            <Heading {...{ ...primaryContentHeadingStyles, ...styles?.primarycontent }}>
              {title}
            </Heading>
          )}
          {/* Partner Logo */}
          {!isMin768 && logo && (
            <Box {...logoBoxStyles(isMin768)}>
              <Image
                {...logoStyles}
                src={`data:image/png;base64,${logo.toString()}`}
                alt={title}
                draggable={false}
              />
            </Box>
          )}
          {/* Title */}
          {!isMin768 && (
            <Heading {...{ ...primaryContentHeadingStyles, ...styles?.primarycontent }}>
              {title}
            </Heading>
          )}
          <HStack>
            {tags.length > 0 &&
              tags.map((tag) => (
                <BaselaneChip
                  key={`partner-${id}-tag-${uuidv4()}`}
                  label={tag.charAt(0) + tag.substring(1).toLowerCase()}
                />
              ))}
          </HStack>
          {/* Supporting Content */}
          <Box {...{ ...supportingContentTextStyles, ...styles?.supportingcontent }}>
            <List {...checkListStyles} spacing={1}>
              {details &&
                details.map((contentItem, index) => (
                  <ListItem key={`partner-${id}-detail-${uuidv4()}`} {...checkListItemStyles}>
                    <Box {...checkedListItemIconContainerStyles}>
                      <IconCheck w={16} h={16} color="#257ED0" />
                    </Box>
                    <Box {...checkListItemTextContainerStyles}>{contentItem}</Box>
                  </ListItem>
                ))}
            </List>
          </Box>
          {/* Bottom Right Content */}
          <Box mt="auto">
            <BaselaneBadge
              {...{
                color: 'blue',
                type: 'badge',
                ...badgeButtonStyles(false),
              }}
            >
              {ctaCopy}
              <Box {...badgeButtonIconStyles}>
                <Icon16ChevronRight color={customTheme.colors.brand.blue['700']} />
              </Box>
            </BaselaneBadge>
          </Box>
        </VStack>
        {/* Partner Logo */}
        {isMin768 && logo && (
          <Box h="100%" display="flex" justifyContent="flex-start" direction="column">
            <Box {...logoBoxStyles(isMin768)}>
              <Image
                {...logoStyles}
                src={`data:image/png;base64,${logo.toString()}`}
                alt={title}
                draggable={false}
              />
            </Box>
          </Box>
        )}
      </HStack>
    </BaselaneButtonCard>
  );
};

export default BaselanePartnerCard;
