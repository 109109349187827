export const invoiceTableHeaderItems = [
  { key: 'property', label: 'Property' },
  {
    key: 'invoice',
    label: 'Invoice',
  },
  {
    key: 'duedate',
    label: 'Due Date',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
];

export const invoiceTableConfig = {
  columns: [
    {
      styles: {
        display: { lg: 'table-cell', base: 'none' },
        width: { base: 'auto' },
        maxWidth: { base: 'auto' },
      },
    },
    {
      styles: {
        width: { base: 'auto' },
        maxWidth: { base: 'auto' },
      },
    },
    {
      styles: {
        display: { lg: 'table-cell', base: 'none' },
        width: { base: '120px' },
        maxWidth: { base: '120px' },
      },
    },
    {
      styles: {
        width: { base: '120px' },
        maxWidth: { base: '120px' },
        justifyContent: 'flex-end',
        textAlign: 'right',
      },
    },
  ],
};
