import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import habitatTheme from '@core/themeHabitat';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import DrawerContent from '@core/components/Shared/components/TransferFunds/components/EducationalDrawer/DrawerContent';

type DigitalWalletProps = {
  id: number,
  from?: string,
};
const DigitalWallet = ({ id, from }: DigitalWalletProps) => {
  const { isMax576 } = getBreakPoints();
  const navigate = useNavigate();

  return (
    <ChakraProvider theme={habitatTheme}>
      <BaselaneDrawer
        isOpen
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        title={id === 0 ? 'Account & Routing Numbers' : 'PayPal / Venmo'}
        closeEvent={() => onDrawerClose(navigate, from)}
        onOverlayClick={() => onDrawerClose(navigate, from)}
        closeOnOverlayClick={false}
        newDesignDrawer
      >
        <DrawerContent id={id} />
      </BaselaneDrawer>
    </ChakraProvider>
  );
};

DigitalWallet.defaultProps = {
  from: null,
};

export default DigitalWallet;
