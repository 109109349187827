/**
 * New Button & Mobile Drawer Styles
 * TODO: These should eventually become the new styles in the button
 * component.  They are implemented here so that existing flows aren't
 * affected but this should be moved to the button component.
 */

// DrawerFooter override
export const footerStyles = {
  p: '16px 24px',
  boxShadow: '1px -2px 6px 0px rgba(3, 24, 64, 0.04);',
};

export const reviewLabelContainerStyles = {
  m: '0 0 24px 0',
  w: '100%',
  spacing: 0,
  _first: {
    m: '24px 0 !important',
  },
};

export const reviewLabelStyles = {
  w: '100%',
  color: 'brand.neutral.900',
  textStyle: 'xs',
  lineHeight: '20px',
};

export const reviewPrimaryValueStyles = {
  color: 'brand.neutral.700',
  fontSize: 'sm',
  lineHeight: '20px',
  w: '100%',
  m: '0 !important',
};

export const reviewSecondaryValueStyles = {
  ...reviewPrimaryValueStyles,
  color: 'brand.neutral.600',
  fontSize: 'xs',
};

export const disabledBoxCard = {
  bgColor: 'brand.darkBlue.100',
  borderColor: 'brand.darkBlue.150',
  color: 'brand.neutral.600',
  _active: {
    borderColor: 'brand.darkBlue.150',
    bgColor: 'brand.darkBlue.100',
    color: 'brand.neutral.600',
  },
  _hover: {
    cursor: 'not-allowed',
    borderColor: 'brand.darkBlue.150',
    color: 'brand.neutral.600',
  },
};

export const headingStyles = {
  size: 'headline-lg',
  alignSelf: 'flex-start',
};

export const descriptionTextStyles = {
  textStyle: 'xs',
  w: '100%',
  color: 'brand.neutral.600',
  textAlign: 'left',
  mb: '4px !important',
  mt: '0 !important',
};

export const mobileDrawerBodyStyles = {
  p: '16px 24px',
  m: '0',
  bgColor: 'brand.neutral.white',
  overflowY: 'auto',
};
