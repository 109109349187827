export const logoFlexStyles = (isOpen) => {
  return {
    flexDirection: 'column',
    width: '100%',
    w: { '3xl': '260px', base: isOpen ? '260px' : '80px' },
    position: 'sticky',
    top: '0',
    bg: 'brand.neutral.50',
    zIndex: 1003,
  };
};

const getWidth = (isOpen) => (isOpen ? '260px' : '80px');

export const linkStyles = {
  w: '100%',
  h: '48px',
  textStyle: 'sm',
  color: 'brand.darkBlue.900',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  pos: 'relative',
  _before: {
    pos: 'absolute',
    bg: 'transparent',
    content: '""',
    w: '4px',
    h: '100%',
    borderRadius: '0px 4px 4px 0px',
  },
  _hover: {
    bg: 'brand.darkBlue.100',

    _active: {
      bg: 'brand.darkBlue.150',
      boxShadow: 'none',
    },
  },
  _focus: {
    bg: 'brand.darkBlue.100',
    boxShadow: 'none',
  },
  _focusVisible: {
    bg: 'brand.darkBlue.100',
    boxShadow: '0 0 0 2px #F5F5F7, 0 0 0 3px #0968BF',
  },
  _active: {
    bg: 'brand.darkBlue.100',
    boxShadow: 'none',
  },
  sx: {
    '&.active': {
      bg: 'brand.darkBlue.100',
      fontWeight: 'semibold',

      _before: {
        pos: 'absolute',
        bg: 'brand.darkBlue.800A',
        content: '""',
        w: '4px',
        h: '100%',
        borderRadius: '0px 8px 8px 0px',
      },
    },
  },
};

export const navItemContainerStyles = {
  px: 4,
  py: 1.5,
  gap: 1.5,
  w: '100%',
};

export const logoContainerStyles = {
  px: 3,
  py: 2,
};

export const logoInnerContainerStyles = {
  direction: 'row',
  alignItems: 'flex-end',
  color: 'brand.darkBlue.800A',
};

export const navBarContainerStyles = (isOpen) => {
  return {
    flexDirection: 'column',
    p: 0,
    m: 0,
    w: { '3xl': '260px', base: getWidth(isOpen) },
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  };
};

export const navBarSettings = (isOpen) => {
  return {
    pos: 'fixed',
    h: '100%',
    w: { '3xl': '260px', base: getWidth(isOpen) },
    left: '0',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
    bg: 'brand.neutral.50',
    zIndex: 13,
    overflowY: 'visible',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    borderRightWidth: '1px',
    borderColor: 'brand.neutral.200',
    transition: 'width .1s ease-in-out',
    boxShadow: {
      '3xl': 'none',
      base: isOpen ? '0px 8px 24px 0px rgba(77, 77, 90, 0.12)' : 'none',
    },
    gap: 2,
  };
};

export const navBarIconContainerStyles = {
  boxSizing: 'border-box',
};

export const navTextStyles = (isOpen) => {
  return {
    display: { '3xl': 'inline-block', base: isOpen ? 'inline-block' : 'none' },
    m: '0 !important',
  };
};

export const navGroupTitleStyles = ({ isOpen }) => {
  return {
    px: 3,
    pb: 1,
    textStyle: 'xs-heavy',
    color: 'brand.neutral.600',
    visibility: isOpen ? 'visible' : 'hidden',
  };
};
