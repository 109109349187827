import React from 'react';
import { BaselaneChip } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

export const paymentMethods = [
  {
    id: 'INTERNAL_TRANSFER',
    title: 'INTERNAL TRANSFER',
    description: 'Instantly transfer funds between your Baselane Banking accounts',
  },
  {
    id: 'TRANSFER_OUT',
    title: 'ACH TRANSFER',
    description: 'Send money to any external account in 3 business days. No fees.',
  },
  {
    id: 'WIRE_TRANSFER',
    title: 'DOMESTIC WIRE TRANSFER',
    description:
      'Send money to any external bank account. 0-1 business days. No fees for a limited time.',
    segmentEvent: () => {
      sendSegmentEvent('baselane_banking_start_wire_transfer');
    },
  },
  {
    id: 'CHECK_MAIL',
    title: (
      <>
        MAIL A CHECK <BaselaneChip size="sm" label="New" variant="simple-primary" />
      </>
    ),

    description: '5-8 business days. $2 fee per check.',
    // segmentEvent: () => {
    //   sendSegmentEvent('baselane_banking_start_mail_checking');
    // },
  },
];
