// @flow
import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_TENANT_PROFILE } from '@pages/LeasesPage/queries';
import { AlertHeader, BaselaneAlert, BaselaneDrawer } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';

import DrawerBody from './Body';
import {
  unsavedDetailsAlertFooter,
  initialValuesAddInvoice,
} from '../../../helpers/invoiceDetails.helper';

type AddInvoiceProps = {
  propertyName: string,
  unitName: string,
  addInvoiceDrawerRef: any,
  handleAddInvoiceDrawerClose: Function,
  isPropertyDeleted: boolean,
  isMultiUnit: boolean,
  bankProps: Object,
  tenantId: string,
  receivingAccountId: string,
  leaseId: string,
  refetchLeaseInvoices: Function,
};

function AddInvoice({
  propertyName,
  unitName,
  addInvoiceDrawerRef,
  handleAddInvoiceDrawerClose,
  isPropertyDeleted,
  isMultiUnit,
  bankProps,
  tenantId,
  receivingAccountId,
  leaseId,
  refetchLeaseInvoices,
}: AddInvoiceProps): any {
  const { isMax767, isMinXL } = getBreakPoints();

  const [isDirty, setIsDirty] = useState(false);
  const [isUnsavedChangesAlertOpen, setIsUnsavedChangesAlertOpen] = useState(false);
  const onUnsavedChangesAlertOpen = () => setIsUnsavedChangesAlertOpen(true);
  const onUnsavedChangesAlertClose = () => setIsUnsavedChangesAlertOpen(false);

  const btnRef = useRef();
  const cancelUnsavedChangesAlertRef = useRef();
  const formikRef = useRef();

  const { loading: tenantLoading, data: tenantData, error: tenantError } = useQuery(
    GET_TENANT_PROFILE,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  if (tenantLoading || tenantError) return null;
  const { landlordTenant } = tenantData ?? {};
  const leaseTenant = landlordTenant.find((tenant) => tenant.id === tenantId);

  const cleanUp = () => {
    formikRef.current?.resetForm(initialValuesAddInvoice());
  };

  const handleCancelCloseButton = (e) => {
    if (isDirty) {
      cleanUp();
      if (isUnsavedChangesAlertOpen) onUnsavedChangesAlertClose();
      handleAddInvoiceDrawerClose(e);
    } else {
      handleAddInvoiceDrawerClose(e);
      onUnsavedChangesAlertClose();
    }
  };

  const handleCloseDrawer = () => {
    if (isDirty) {
      onUnsavedChangesAlertOpen();
    } else {
      cleanUp();
      handleAddInvoiceDrawerClose();
    }
  };

  return (
    <>
      <BaselaneDrawer
        finalFocusRef={btnRef}
        size="newdrawermd"
        contentStyles={isMax767 && { top: 'auto !important' }}
        title="Add One-Time Invoice"
        isMobileHeader={isMinXL}
        showBackButton={isMinXL}
        hideBackText
        ref={addInvoiceDrawerRef}
        onClose={handleCloseDrawer}
        closeEvent={handleCloseDrawer}
        hideOverlay
        newDesignDrawer
      >
        <DrawerBody
          setIsDirty={setIsDirty}
          formikRef={formikRef}
          onClose={handleAddInvoiceDrawerClose}
          leaseTenant={leaseTenant}
          isPropertyDeleted={isPropertyDeleted}
          isMultiUnit={isMultiUnit}
          propertyName={propertyName}
          unitName={unitName}
          bankProps={bankProps}
          receivingAccountId={receivingAccountId}
          leaseId={leaseId}
          refetchLeaseInvoices={refetchLeaseInvoices}
        />
      </BaselaneDrawer>

      <BaselaneAlert
        isOpen={isUnsavedChangesAlertOpen}
        leastDestructiveRef={cancelUnsavedChangesAlertRef}
        onClose={onUnsavedChangesAlertClose}
        isCentered
        header={<AlertHeader title="You Have Unsaved Changes" />}
        body="Are you sure you want to exit without saving?"
        footer={unsavedDetailsAlertFooter(
          cancelUnsavedChangesAlertRef,
          onUnsavedChangesAlertClose,
          handleCancelCloseButton
        )}
      />
    </>
  );
}

export default AddInvoice;
