import React from 'react';
import { isMobile } from 'react-device-detect';
import AddAccount from '@core/components/NativeBankingPage/MainContent/components/Accounts/components/AddAccount';
import AddAccountMobile from '@core/components/NativeBankingPage/MainContent/components/Accounts/components/AddAccountMobile';

export const getExternalAccLink = ({ key, from, refetchUser, addExternalAccountDrawerRef }) => {
  return isMobile ? (
    <AddAccountMobile
      key={key}
      from={from}
      addExternalAccountDrawerRef={addExternalAccountDrawerRef}
      handleDrawerCloseExternally={refetchUser}
    />
  ) : (
    <AddAccount
      key={key}
      addExternalAccountDrawerRef={addExternalAccountDrawerRef}
      from={from}
      handleDrawerCloseExternally={refetchUser}
    />
  );
};
