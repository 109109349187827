import React from 'react';
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { IconChevronRight } from '@icons';
import { BaselaneCardNew, BaselaneHighlightedDataDisplay } from '@shared/components';
import LeaseSection from './LeaseSection';
import {
  leaseSectionHeader,
  leaseTermsContainer,
  leaseSmallTextCapitalized,
  leaseSmallTextGray,
  leaseTermsLargeText,
} from '../../../LeaseResponsiveDetail/styles/leaseResponsiveDetail.styles';

type ReceivingBankAccountsProps = {
  lease: Object,
  setTabIndex: Function,
  viewMode: boolean,
  leaseCreated: boolean,
  id: string,
  setIsAccountsEdit: Function,
};

function ReceivingBankAccounts({
  lease,
  setTabIndex,
  viewMode,
  leaseCreated,
  id,
  setIsAccountsEdit = () => {},
}: ReceivingBankAccountsProps) {
  const [isDesktop] = useMediaQuery('(min-width: 576px)', { ssr: false });
  const [isSmallTablet] = useMediaQuery('(min-width: 576px)', { ssr: false });

  const handleClickAccountWidget = () => {
    setIsAccountsEdit(true);
    setTabIndex(2);
  };

  const { rentAndFeesBankAccount, depositBankAccount } = lease || {};

  const rentFeesBA = rentAndFeesBankAccount;
  const rentFeesBAName = rentFeesBA?.accountName || rentFeesBA?.nickName || rentFeesBA?.name;
  // const rentFeesType = rentFeesBA?.accountType;
  const rentFeesBankAndType = `${rentFeesBA?.institutionName} ${
    rentFeesBA?.accountSubType
  } - ${rentFeesBA?.accountNumber?.slice(-4)}`;
  const depositBA = depositBankAccount;
  const depositBAName = depositBA?.accountName || depositBA?.nickName || depositBA?.name;
  // const depositType = depositBA?.accountType;
  const depositBankAndType = `${depositBA?.institutionName} ${
    depositBA?.accountSubType
  } - ${depositBA?.accountNumber?.slice(-4)}`;

  const title = 'Receiving bank accounts';

  const configuration = {
    items: [
      {
        key: !isDesktop ? 'To receive rent & fees' : 'Bank account to receive rent & fees',
        value: rentFeesBAName,
      },
      {
        key: !isDesktop
          ? 'To receive security deposit'
          : 'Bank account to receive security deposit',
        value: depositBAName,
      },
    ],
  };

  const displayedData = (
    <BaselaneHighlightedDataDisplay
      configuration={configuration}
      defaultDataDisplayPropStyles={{ mt: '0' }}
    />
  );

  return viewMode ? (
    <>
      <Text {...leaseSectionHeader} mt="32px">
        Receiving bank accounts
      </Text>
      <BaselaneCardNew
        variant="clickable"
        {...leaseTermsContainer(lease.archived)}
        onClick={() => (lease.archived ? {} : handleClickAccountWidget())}
      >
        <Flex w="100%" direction="row" justifyContent="space-between">
          <Flex
            w="100%"
            direction={isSmallTablet ? 'row' : 'column'}
            justifyContent="space-between"
          >
            <Box w={isSmallTablet && !!depositBAName ? '50%' : '100%'}>
              <Text {...leaseSmallTextGray} mb="4px">
                For rent & fees
              </Text>
              <Text {...leaseTermsLargeText}>{rentFeesBAName}</Text>
              <Text {...leaseSmallTextCapitalized} mt="4px">
                {rentFeesBankAndType}
              </Text>
            </Box>
            {lease?.depositBankAccountId && (
              <Box w={isSmallTablet ? '50%' : '100%'} mt={isSmallTablet ? '0px' : '16px'}>
                <Text {...leaseSmallTextGray} mb="4px">
                  For security deposit
                </Text>
                <Text {...leaseTermsLargeText}>{depositBAName}</Text>
                <Text {...leaseSmallTextCapitalized} mt="4px">
                  {depositBankAndType}
                </Text>
              </Box>
            )}
          </Flex>
          {!lease.archived && (
            <Flex direction="column" justifyContent="center">
              <IconChevronRight color="#257ED0" />
            </Flex>
          )}
        </Flex>
      </BaselaneCardNew>
    </>
  ) : (
    <LeaseSection {...{ id, title, setTabIndex, leaseCreated }}>{displayedData}</LeaseSection>
  );
}

export default ReceivingBankAccounts;
