export const tablesStyles = {
  container: {
    m: '0 !important',
    borderTopWidth: { lg: '0', base: '1px' },
    borderColor: 'brand.darkBlue.100',
  },
  chevron: {
    w: '48px',
    display: { lg: 'table-cell', base: 'none' },
  },
  nochevron: {
    w: '0',
    display: 'none',
  },
};
