export const containerStyles = (isMobile) => {
  return {
    bg: 'brand.neutral.white',
    display: 'flex',
    alignItems: !isMobile && 'center',
    justifyContent: isMobile && 'center',
    minHeight: isMobile && '100%',
  };
};

export const mobileContainer = (isPortrait) => {
  return {
    padding: '18px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: !isPortrait && 'space-between',
    backgroundColor: 'brand.neutral.white',
    w: '100%',
    flexDirection: 'column',
    gap: '40px',
  };
};

export const mobileMainContentStyles = (isPortrait) => {
  return {
    position: 'relative',
    display: 'flex',
    flexDir: 'column',
    flexGrow: isPortrait && '1',
    alignItems: 'center',
    textAlign: 'center',
    mb: '24px',
    mt: isPortrait && { xs: 0, '2xs': '52px', sm: '218px' },
  };
};

export const descriptionTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
};

export const descriptionBoldTextStyles = {
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
  mt: '48px',
  mb: '16px',
};

export const headerStyles = {
  h: '48px',
  alignItems: 'center',
  justifyContent: 'center',
};

export const cardStyles = {
  m: '24px',
  p: '24px',
  w: 'auto',
};

export const textStyles = {
  textStyle: 'sm',
  mt: '24px',
  color: 'brand.neutral.700',
};
