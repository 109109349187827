import React, { useEffect } from 'react';
import { modalStyles } from './styles/rocketLawyeriFrame.styles';

type RocketLawyeriFrameProps = {
  serviceToken: String,
  interviewId: String,
  setIsDocReadyToSign: Function,
};

const RocketLawyeriFrame = ({
  serviceToken,
  interviewId,
  setIsDocReadyToSign,
}: RocketLawyeriFrameProps) => {
  const { rocketDocument } = modalStyles ?? {};

  useEffect(() => {
    document.querySelector('rocket-document').addEventListener('interview-completed', (e) => {
      // if interview-complete's called successfully,
      // show iframe (sign ux) instead of shadow dom (interview ux)
      if (e.detail) {
        setIsDocReadyToSign(true);
      }
    });
  }, []);

  return <rocket-document {...{ serviceToken, interviewId }} style={rocketDocument} />;
};

export default RocketLawyeriFrame;
