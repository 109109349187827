export const containerStyles = (isMobile) => {
  return {
    mb: isMobile ? '0' : '20px',
    mt: '0',
    pb: isMobile ? '0' : '34px',
    height: 'auto',
  };
};

export const headerStyles = (isMobile) => {
  return {
    display: isMobile ? 'none' : 'flex',
  };
};

export const addFundsSectionStyles = {
  bgColor: 'brand.neutral.100',
  justify: 'space-between',
  padding: '18px 16px',
  borderRadius: '4px',
  m: '0 0 20px 0!important',
};

export const addFundsDescriptionStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.900',
};
