import React from 'react';
import { Box, Stack, Spacer, Progress } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { BaselaneTooltip } from '@shared/components';
import { IconExclamationTriangle } from '@icons';
import { getFormattedCurrency, getFormattedLimit } from '../helpers/currency.helpers';
import {
  cardMTDValueStyles,
  cardVirtualLimitLabelStyles,
  cardProgressBarStyles,
  tooltipIconOuterContainerStyles,
  tooltipIconInnerContainerStyles,
} from './styles/card.styles';

type VirtualCardProgressBarProps = {
  cardFinancials: Object,
  limit: Object,
  bankAccountAvailableBalance: Number,
};

const VirtualCardProgressBar = ({
  cardFinancials,
  limit,
  bankAccountAvailableBalance,
}: VirtualCardProgressBarProps) => {
  const isMonthlyLimit = limit?.frequency === 'MONTHLY';

  const moneySpent = isMonthlyLimit ? cardFinancials?.mtdSpent : cardFinancials?.dailySpent;
  const limitAmount = limit?.amount || 0;

  const progressPercent = limitAmount === 0 ? 0 : (moneySpent * 100) / limitAmount;

  const isExceeded = progressPercent >= 90;

  const hasNotEnoughBalance = bankAccountAvailableBalance < limitAmount;
  const CARD_PROGRESS_BAR_COLOR = !isExceeded ? 'scheme.blue' : 'red';

  const formattedMoneySpent = isMonthlyLimit
    ? getFormattedCurrency(cardFinancials?.mtdSpent, isExceeded)
    : getFormattedCurrency(cardFinancials?.dailySpent);

  const isRed = hasNotEnoughBalance || isExceeded;

  const moneyFormattedLimit = getFormattedLimit(limit?.amount, isMonthlyLimit, isRed);

  const tooltipText =
    'The available balance of the linked Baselane Banking account is less than the spending limit set for this card.  Transactions over the available balance will be declined. ';

  return (
    <>
      <Stack m={isMobile ? '12px 0 0 !important' : '!important'} direction="row">
        <Box {...cardMTDValueStyles(isMobile, isExceeded)}>{formattedMoneySpent}</Box>
        <Spacer />

        {hasNotEnoughBalance && (
          <Box position="relative">
            <Box {...tooltipIconOuterContainerStyles(isMobile)} />
            <Box {...tooltipIconInnerContainerStyles(isMobile)}>
              <BaselaneTooltip
                label={tooltipText}
                placement="top"
                styles={{ w: '345px', textAlign: 'center' }}
              >
                <IconExclamationTriangle w="17" h="16" color="#C93A3A" />
              </BaselaneTooltip>
            </Box>
          </Box>
        )}

        <Box {...cardVirtualLimitLabelStyles(isMobile)} position="relative">
          {moneyFormattedLimit}
        </Box>
      </Stack>

      <Progress
        value={progressPercent}
        colorScheme={CARD_PROGRESS_BAR_COLOR}
        {...cardProgressBarStyles}
      />
    </>
  );
};

export default VirtualCardProgressBar;
