// @flow
import React from 'react';
import { VStack, HStack, Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { progressBarStyles } from './styles/progressbar.style';
import { getPercentage } from './helper';
import { COLOR_TYPES } from './constants';
import type { ProgressBarModel } from '../../types/ProgressBarModel';
import SkeletonProgressBar from './SkeletonProgressBar';

type ProgressBarType = ProgressBarModel;

function BaselaneProgressBar({
  progressBarItems,
  hasNoData,
  containerStyles: customContainerStyles,
  barStyles: customBarStyles,
}: {
  progressBarItems: Array<ProgressBarType>,
  hasNoData: boolean,
  containerStyles: Object,
  barStyles: Object,
}): any {
  const {
    barStyles,
    barContainerStyles,
    leftBorderStyles,
    rightBorderStyles,
    container,
  } = progressBarStyles;
  const containerStyles = {
    ...container,
    ...(customContainerStyles || {}),
  };
  const filteredItems = progressBarItems.filter((item) => item.value !== 0);
  const total = filteredItems.reduce((a, b) => a + (b.value || 0), 0);
  return (
    <HStack {...containerStyles}>
      {hasNoData ? (
        <SkeletonProgressBar barStyles={customBarStyles} />
      ) : (
        filteredItems.map((item, index, arr) => {
          const leftBorderRadius = index === 0 ? leftBorderStyles : {};
          const rightBorderRadius = index === arr.length - 1 ? rightBorderStyles : {};

          const progressItemStyles = {
            ...barStyles,
            ...leftBorderRadius,
            ...rightBorderRadius,
            bg: item.type ? COLOR_TYPES[item.type] : item.barColor,
          };

          return (
            item.value && (
              <VStack
                {...barContainerStyles}
                width={getPercentage(item.value, total)}
                key={item.id}
              >
                <Box {...progressItemStyles} h={!isMobile ? '30' : '4'} />
              </VStack>
            )
          );
        })
      )}
    </HStack>
  );
}

export default BaselaneProgressBar;
