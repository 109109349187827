// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { useQuery } from '@apollo/client';
import { GET_PLAID_LINK_TOKEN } from '@core/apollo/queries';
import AddBankAccountButton from './AddBankAccountButton';

type AddBankAccountProps = {
  bankAccId?: string,
  state: string,
  type: string,
  titleText: string,
  handleSuccessFn?: Function,
  onExit?: Function,
  setPlaidText?: Function,
  size: string,
  hasIconLock: boolean,
  leftIcon: any,
  hasRightChevronIcon: boolean,
  styles?: Object,
  onPlaidConfirmationClose?: Function,
  mode: string,
  isLoading: boolean,
  isDisabled?: boolean,
  refetchFunction: Function,
  updateLinkToken: String,
  setIsPlaidButtonLoading: Function,
  segmentEvent?: Function,
  containerStyles?: Object,
  showCustomButton?: boolean,
  variant?: String,
  palette?: String,
};

function AddBankAccount({
  bankAccId,
  state,
  type,
  titleText,
  onExit,
  setPlaidText,
  size,
  hasIconLock,
  leftIcon,
  hasRightChevronIcon,
  onPlaidConfirmationClose,
  styles,
  mode,
  isLoading,
  handleSuccessFn,
  isDisabled,
  refetchFunction,
  updateLinkToken,
  setIsPlaidButtonLoading,
  containerStyles,
  segmentEvent,
  showCustomButton,
  variant,
  palette,
}: AddBankAccountProps): any {
  const { error, loading, data, refetchPlaid } = useQuery(GET_PLAID_LINK_TOKEN);
  if (error && loading) return null;

  const linkToken = data && data.plaidLinkToken;
  const userLinkToken = mode === 'RESYNC' ? updateLinkToken : linkToken;

  return (
    <Box width={isMobile ? '100%' : '100%'} {...containerStyles}>
      {userLinkToken && !showCustomButton && (
        <AddBankAccountButton
          bankAccId={bankAccId}
          linkToken={userLinkToken}
          titleText={titleText}
          handleSuccessFn={handleSuccessFn}
          refetchFunction={refetchFunction}
          onExit={onExit}
          setPlaidText={setPlaidText}
          type={type}
          state={state}
          size={size}
          hasIconLock={hasIconLock}
          hasRightChevronIcon={hasRightChevronIcon}
          leftIcon={leftIcon}
          refetchPlaid={refetchPlaid}
          onPlaidConfirmationClose={onPlaidConfirmationClose}
          styles={styles}
          mode={mode}
          isLoading={isLoading}
          isDisabled={isDisabled}
          setIsPlaidButtonLoading={setIsPlaidButtonLoading}
          segmentEvent={segmentEvent}
          showCustomButton={showCustomButton}
          variant={variant}
          palette={palette}
        />
      )}

      {/* NOTE: Show button without any delay regardless of Plaid being ready OR userLinkToken available
          Instead, disable the button until Plaid is ready */}
      {showCustomButton && (
        <AddBankAccountButton
          bankAccId={bankAccId}
          linkToken={userLinkToken}
          titleText={titleText}
          handleSuccessFn={handleSuccessFn}
          refetchFunction={refetchFunction}
          onExit={onExit}
          setPlaidText={setPlaidText}
          type={type}
          state={state}
          size={size}
          hasIconLock={hasIconLock}
          hasRightChevronIcon={hasRightChevronIcon}
          leftIcon={leftIcon}
          refetchPlaid={refetchPlaid}
          onPlaidConfirmationClose={onPlaidConfirmationClose}
          styles={styles}
          mode={mode}
          isLoading={isLoading}
          isDisabled={isDisabled}
          setIsPlaidButtonLoading={setIsPlaidButtonLoading}
          segmentEvent={segmentEvent}
          showCustomButton={showCustomButton}
          variant={variant}
          palette={palette}
        />
      )}
    </Box>
  );
}

AddBankAccount.defaultProps = {
  setPlaidText: () => {},
  handleSuccessFn: () => {},
  onPlaidConfirmationClose: () => {},
  onExit: () => {},
  segmentEvent: () => {},
  styles: {},
  isDisabled: false,
  bankAccId: null,
  containerStyles: {},
  showCustomButton: false,
  variant: 'tonal',
  palette: 'primary',
};

export default AddBankAccount;
