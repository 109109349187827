import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import IconVerifyIdentity from '@icons/legacy/IconVerifyIdentity';
import BaselaneKYCForm from '@shared/components/BaselaneKYCForm';
import KYCReturnStatusDrawer from '@shared/components/BaselaneKYCForm/components/KYCReturnStatusDrawer';
import useReturnStatus from '@shared/components/BaselaneKYCForm/hooks/useReturnStatus';
import VouchedIdentificationDrawer from '@shared/components/VouchedIdentificationDrawer';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneBanner, BaselaneButton, BaselaneMessageCard } from '../../components';

type KYCBannerProps = {
  user: Object,
  userKyc: Object,
  userKycStatus: string,
  refetchUser: Function,
};

const KYCBanner = ({ user, userKyc, userKycStatus, refetchUser, ...rest }: KYCBannerProps) => {
  const title = 'VERIFY YOUR IDENTITY';
  const description =
    userKycStatus === 'VOUCH_PENDING'
      ? 'You will need to upload ID before you can collect rent on Baselane'
      : 'You will not be able to receive your rent payments until you have verified your identity in Baselane';
  const cta = userKycStatus === 'VOUCH_PENDING' ? 'Verify ID' : 'Learn More';

  const location = useLocation();
  const { pathname } = location;

  const isKYCSubmitted = userKycStatus !== 'UNVERIFIED' && userKycStatus !== null;
  const { isMin768: isDesktop } = getBreakPoints();

  const isDashboardPage = pathname.includes('dashboard');
  const isTransactionsPage = pathname.includes('transactions');

  const {
    kycReturnStatusDrawerRef,
    handleKYCReturnStatusDrawerClose,
    handleKYCReturnStatusDrawerOpen,
  } = useReturnStatus();

  const vouchedDrawerRef = useRef(null);

  const handleVouchedDrawerOpen = () => {
    vouchedDrawerRef?.current?.open();
  };

  const kycForProps = {
    button: {
      variant: 'filled',
      palette: 'primary',
      text: isKYCSubmitted ? 'Learn More' : 'Verify Identity',
      leftIcon: isKYCSubmitted ? null : <IconVerifyIdentity />,
    },
    user,
    userKyc,
    refetchUser,
    kycStatus: userKycStatus,
    handleKYCReturnStatusDrawerOpen,
    handleVouchedDrawerOpen,
  };

  const handleLearnMoreOpen = () => {
    if (userKycStatus === 'VOUCH_PENDING') {
      handleVouchedDrawerOpen();
    } else {
      handleKYCReturnStatusDrawerOpen();
    }
  };

  const rightElement = isKYCSubmitted ? (
    <BaselaneButton {...kycForProps.button} onClick={handleLearnMoreOpen}>
      {cta}
    </BaselaneButton>
  ) : (
    <BaselaneKYCForm {...kycForProps} />
  );

  const bottomElement = isKYCSubmitted ? (
    <Box width="100%" alignItems="left">
      <BaselaneButton {...kycForProps.button} onClick={handleLearnMoreOpen}>
        {cta}
      </BaselaneButton>
    </Box>
  ) : (
    <Box width="100%" alignItems="left">
      <BaselaneKYCForm {...kycForProps} />
    </Box>
  );

  return (
    <Box {...rest}>
      {isDesktop ? (
        <BaselaneBanner
          iconName="close"
          color="secondaryred"
          title={title}
          body={description}
          rightElement={rightElement}
        />
      ) : (
        <BaselaneMessageCard
          title={title}
          borderColor="secondaryred"
          iconName=""
          isVertical
          containerStyles={{
            background: 'red.200',
            marginBottom: isDashboardPage || isTransactionsPage ? '16px!important' : '0px',
            width: '100%',
          }}
          text={description}
          bottomElement={bottomElement}
        />
      )}
      <KYCReturnStatusDrawer
        kycStatus={userKycStatus}
        drawerRef={kycReturnStatusDrawerRef}
        handleKYCReturnStatusDrawerClose={handleKYCReturnStatusDrawerClose}
      />
      <VouchedIdentificationDrawer
        {...{
          userKyc,
          user,
          refetchUser,
          vouchedDrawerRef,
        }}
      />
    </Box>
  );
};

export default KYCBanner;
