import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Heading, Box, Stack, Text } from '@chakra-ui/react';
import { ADD_FUNDS_BANK_TRANSFER, ADD_FUNDS_CHECK_DEPOSIT } from '@routes';
import BaselaneCardStack from '@shared/components/BaselaneCardStack';
import { Icon24Transfer, Icon24CheckPayment } from '@icons/24px';
import TransferCard from '@pages/Transfers/components/AddFunds/components/TransferCard';
import InformationNeeded from './InformationNeeded';
import InstructionsList from './InstructionsList';
import BaselaneButtonToggle from '../../../../BaselaneButtonToggle';
import BaselaneButton from '../../../../BaselaneButton';
import { instructionGroups } from '../helpers/instructions.helpers';
import { methods } from '../helpers/button.helper';
import { titleTextStyles } from '../styles/content.styles';

const DrawerContent = ({ id }: { id: number }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [instructionType, setInstructionType] = useState(methods[id].defaultType);
  const instructions = instructionGroups[instructionType];

  const handleDigitalWalletSelection = () => {
    if (instructionType === 'paypal') {
      setInstructionType('venmo');
    } else {
      setInstructionType('paypal');
    }
  };

  const handleBankTransferType = () => {
    if (instructionType === 'ach') {
      setInstructionType('wire');
    } else {
      setInstructionType('ach');
    }
  };

  const handleTypeChange = () => {
    if (methods[id].defaultType === 'paypal') {
      handleDigitalWalletSelection();
    } else {
      handleBankTransferType();
    }
  };

  const {
    instructionTitle,
    instructionPoints,
    bankingAccountInfoList,
    showInformationNeededSection,
  } = instructions;

  const CustomNavigate = (newLastPart) => {
    const previousRoute = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
    return navigate(previousRoute);
  };

  const itemsRecommendedMethods = [
    {
      id: 'bank-transfer',
      content: (
        <TransferCard
          icon={<Icon24Transfer />}
          title="Bank transfer"
          description="Deposits in 3-5 business days"
          onClick={() => {
            CustomNavigate(ADD_FUNDS_BANK_TRANSFER);
          }}
        />
      ),
    },
    {
      id: 'mobile-check-deposit',
      content: (
        <TransferCard
          icon={<Icon24CheckPayment />}
          title="Mobile check deposit"
          description="Deposits in 3 business days"
          onClick={() => {
            CustomNavigate(ADD_FUNDS_CHECK_DEPOSIT);
          }}
        />
      ),
    },
  ];

  return (
    <Box>
      <Text {...titleTextStyles}>{methods[id].methodTitle}</Text>
      <Box my={2}>
        <BaselaneButtonToggle
          firstLabel={methods[id].defaultType === 'ach' ? 'ACH' : 'PayPal'}
          secondLabel={methods[id].defaultType === 'ach' ? 'Wire' : 'Venmo'}
          activeButton={instructionType === 'ach' || instructionType === 'paypal' ? 0 : 1}
          onClick={handleTypeChange}
          size="lg"
          leftIcons={[instructions.buttonIconLeft, instructions.buttonIconRight]}
        />
      </Box>
      {instructions.texts && (
        <Stack>
          {instructions.texts.map((text) => (
            <Text key={uuidv4()} textStyle="sm">
              {text}
            </Text>
          ))}
        </Stack>
      )}
      {instructionTitle && <InstructionsList {...{ instructionTitle, instructionPoints }} />}
      {showInformationNeededSection && <InformationNeeded {...{ bankingAccountInfoList }} />}
      <Box>
        <Heading as="h3" size="headline-md" fontWeight="medium" my={2}>
          Still not sure? Try our recommended methods
        </Heading>

        <BaselaneCardStack items={itemsRecommendedMethods} />
        <BaselaneButton
          variant="transparent"
          palette="primary"
          onClick={() => CustomNavigate('')}
          size="md"
          pullLeft
          styles={{ mt: 1 }}
        >
          View all methods
        </BaselaneButton>
      </Box>{' '}
      <Outlet />
    </Box>
  );
};

export default DrawerContent;
