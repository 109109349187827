import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { GET_STARTED, HOME } from '@core/constants/routes';
import customTheme from '@core/theme';
import { IconArrowRight, IconRocketLawyer } from '@icons';
import { IllustrationRCLeaseDocument } from '@illustrations';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import BaselaneBadge from '../BaselaneBadge';
import RocketLawyerDocumentDrawer from '../RocketLawyerDocumentDrawer';
import {
  badgeTagStyles,
  badgeButtonStyles,
  badgeButtonIconStyles,
  providerImageStyles,
} from './styles/insuranceBanner.styles';
import BaselaneAdvertisingBanner from '.';

type RocketLawyerBannerProps = {
  isNarrowOnly: Boolean,
  badgeSize: String,
  bannerStyles: Object,
};

const TopRightContent = () => {
  return (
    <BaselaneBadge
      {...{
        color: 'blue',
        type: 'badge',
        ...badgeTagStyles,
      }}
    >
      Baselane Feature
    </BaselaneBadge>
  );
};

const BottomRightContent = ({
  isNarrowOnly,
  badgeSize = 'lg',
  bannerStyles = {},
}: RocketLawyerBannerProps) => {
  return (
    <>
      <BaselaneBadge
        {...{
          color: 'blue',
          type: 'badge',
          ...badgeButtonStyles(isNarrowOnly, badgeSize),
        }}
      >
        Create Lease Document
        <Box as="span" {...{ ...badgeButtonIconStyles, ...bannerStyles?.arrowiconcontainer }}>
          <IconArrowRight
            color={customTheme.colors.brand.blue['700']}
            w={badgeSize === 'md' ? '16' : '18'}
            h={badgeSize === 'md' ? '16' : '10'}
          />
        </Box>
      </BaselaneBadge>
      <Box {...providerImageStyles(true)} {...bannerStyles?.rocketlawyerlogocontainer}>
        <IconRocketLawyer />
      </Box>
    </>
  );
};

const RocketLawyerBanner = ({
  isNarrowOnly,
  badgeSize = 'lg',
  bannerStyles = {},
  ...rest
}: RocketLawyerBannerProps) => {
  const drawerRef = useRef();

  const currLocation = useLocation();
  const { pathname } = currLocation;

  const onOpenRocketLawyer = () => {
    const pageTitle = pathname === HOME || pathname === GET_STARTED ? GET_STARTED : pathname;
    const title = pageTitle.substring(1);
    sendSegmentEvent('rocketlawyer_click_button', { title });
    drawerRef.current?.open();
  };

  return (
    <>
      <BaselaneAdvertisingBanner
        {...{
          icon: <IllustrationRCLeaseDocument />,
          primaryContent: `Fastest Way to Create & Sign Lease Documents`,
          supportingContent: `Create your lease in a few simple steps.`,
          topRightContent: TopRightContent(),
          bottomRightContent: BottomRightContent({ isNarrowOnly, badgeSize, bannerStyles }),
          onClick: onOpenRocketLawyer,
          isNarrowOnly: true,
          isBannerClickable: true,
          ...rest,
        }}
      />
      <RocketLawyerDocumentDrawer {...{ drawerRef }} />
    </>
  );
};

export default RocketLawyerBanner;
