// @flow
import React from 'react';
import { Box, HStack, Stack, Heading } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import IconClose from '@icons/legacy/IconClose';
import icons from '../icons';
import Card from './BaselaneCard';
import {
  cardTwoStyles,
  getIconContainerStyles,
  messageCardTitleContainerStyles,
  messageCardDescriptionStyles,
} from './styles/card.style';

type Props = {
  children?: any,
  borderColor?: string,
  title?: string,
  iconColor?: string,
  iconName?: string,
  text?: string,
  textStyles?: Object,
  hasCloseButton?: boolean,
  onCloseButtonClick?: Function,
  closeButtonStyles?: Object,
  containerStyles?: Object,
  iconContainerStyles?: Object,
  isVertical?: boolean,
  rightElement?: any,
  bottomElement?: any,
  iconWidth?: string,
  iconHeight?: string,
  styles?: Object,
  icon?: React.Node | null,
};

function BaselaneMessageCard({
  children,
  borderColor,
  iconColor,
  iconName,
  text,
  textStyles,
  hasCloseButton,
  onCloseButtonClick,
  closeButtonStyles,
  containerStyles: cStyles,
  iconContainerStyles,
  title,
  isVertical,
  rightElement,
  bottomElement,
  iconHeight,
  iconWidth,
  styles,
  icon,
}: Props): any {
  const name = iconName.toLowerCase();
  const Icon = icons?.[name] ?? icon;

  const borderColorStyle = getIconContainerStyles(borderColor);
  const iconColorStyle = getIconContainerStyles(iconColor);

  const { container, innerContainer, borderContainer, closeButton } = cardTwoStyles(isMobile);

  const containerStyles = {
    ...container,
    ...cStyles,
    ...styles?.container,
  };

  const innerContainerStyles = {
    ...innerContainer,
    pt: isVertical ? '8px' : '10px',
    ...styles?.innercontainer,
  };

  const badgeIconContainerStyles = {
    mr: '16px',
    color: iconColorStyle,
    ...iconContainerStyles,
    ...styles?.iconcontainer,
  };

  const borderContainerStyles = {
    ...borderContainer,
    borderColor: borderColorStyle,
  };

  const titleContainerStyles = {
    alignItems: isVertical ? 'flex-start' : 'center',
    ...styles?.titleContainer,
  };

  const titleStyles = {
    ...messageCardTitleContainerStyles(isMobile),
    ...styles?.title,
  };

  const messageTextStyles = {
    ...messageCardDescriptionStyles,
    ...textStyles,
  };

  const iconWH = {};
  if (iconWidth) {
    iconWH.w = iconWidth;
  }
  if (iconHeight) {
    iconWH.h = iconHeight;
  }

  return (
    <Card styles={containerStyles}>
      <Box {...borderContainerStyles} />
      <HStack {...innerContainerStyles}>
        <HStack align="center">
          {iconName !== '' && !isMobile && (
            <Box {...badgeIconContainerStyles}>
              <Icon {...iconWH} color="currentColor" />
            </Box>
          )}
          <Stack direction={!isVertical ? 'row' : 'column'} {...titleContainerStyles}>
            {title && <Heading {...titleStyles}>{title}</Heading>}
            <Box {...messageTextStyles} ml={title && !isVertical ? '24px !important' : 'initial'}>
              {text || children}
            </Box>
            {bottomElement && bottomElement}
          </Stack>
        </HStack>
        {rightElement && rightElement}
        {hasCloseButton && (
          <Box as="button" onClick={onCloseButtonClick} {...closeButton} {...closeButtonStyles}>
            <IconClose />
          </Box>
        )}
      </HStack>
    </Card>
  );
}

BaselaneMessageCard.defaultProps = {
  children: null,
  borderColor: 'green',
  iconColor: 'green',
  iconName: 'checkcircle',
  text: null,
  title: null,
  hasCloseButton: false,
  onCloseButtonClick: () => {},
  closeButtonStyles: ({}: { ... }),
  containerStyles: ({}: { ... }),
  iconContainerStyles: ({}: { ... }),
  textStyles: ({}: { ... }),
  isVertical: false,
  rightElement: null,
  bottomElement: null,
  iconWidth: null,
  iconHeight: null,
  styles: ({}: { ... }),
  icon: null,
};

export default BaselaneMessageCard;
