export const failedPaymentAccordionStyles = {
  accordionWrapper: {
    my: '0px',
    h: '100%',
  },
  accordion: {
    containerStyles: {
      m: '0 !important',
    },
    buttonStyles: {
      borderRadius: '4px',
      p: '12px 16px',
      background: 'brand.blue.50',
      w: 'calc(100% - 24px)',
      m: '0 auto',
      h: 'auto',
      maxH: 'none',
    },
    contentStyles: {
      border: 'none',
      p: '0',
    },
    expandedContentStyles: {
      border: 'none',
      p: '0',
    },
    iconContainerStyles: {
      minW: '24px',
      minH: '24px',
      alignItems: 'center',
      justifyContent: 'center',
      mr: '8px !important',
    },
  },
};
