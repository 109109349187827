import React, { useContext, useState } from 'react';
import { Select, Stack, Text } from '@chakra-ui/react';
import BanksContext from '@contexts/BanksContext';
import { getBankAccounts } from '@pages/LeasesPage/LeaseSection/formHelpers/receivingBank.helper';
import ElementsToCopy from '@shared/components/TransferFunds/components/EducationalDrawer/ElementsToCopy';
import {
  informationNeededContainerStyles,
  informationNeededTitleTextStyles,
} from '../styles/content.styles';

type InformationNeededProps = {
  bankingAccountInfoList: Array,
};

const InformationNeeded = ({ bankingAccountInfoList }: InformationNeededProps) => {
  const { baselaneConnectedAccounts } = useContext(BanksContext);
  const accounts = getBankAccounts(baselaneConnectedAccounts, null);
  const accountsWithoutSavings = accounts.filter((account) => account.accountSubType !== 'savings');
  const [selectedBaselaneAccount, setSelectedBaselaneAccount] = useState(
    accountsWithoutSavings[0] || {}
  );
  return (
    <Stack {...informationNeededContainerStyles}>
      <Text {...informationNeededTitleTextStyles}>Information you will need</Text>
      <Text textStyle="sm">Select your Baselane Banking account:</Text>

      {/* TODO: to be updated with new select dropdown in another ticket */}
      <Select
        onChange={(e) => {
          const baselaneBankData = accountsWithoutSavings.find(
            (bankData) => bankData.id === e.target.value
          );
          setSelectedBaselaneAccount(baselaneBankData);
        }}
      >
        {accountsWithoutSavings.map((account) => (
          <option key={account.id} value={account.id}>
            {account.nickName
              ? `${account.nickName} - ${account.last4Digits}`
              : `${account.name} ${account.number}`}
          </option>
        ))}
      </Select>

      <ElementsToCopy
        {...{
          bankingAccountInfoList,
          selectedBaselaneAccount,
        }}
      />
    </Stack>
  );
};

export default InformationNeeded;
