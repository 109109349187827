import React from 'react';
import { Image, Stack, VStack, HStack, Text, useMediaQuery } from '@chakra-ui/react';

import biggerPocketsLogo from '../assets/bigger-pockets.png';
import biggerPocketsLogo2x from '../assets/bigger-pockets@2x.png';
import businessInsiderLogo from '../assets/business-insider.png';
import businessInsiderLogo2x from '../assets/business-insider@2x.png';
import forbesLogo from '../assets/forbes.png';
import forbesLogo2x from '../assets/forbes@2x.png';
import nasdaqLogo from '../assets/nasdaq.png';
import nasdaqLogo2x from '../assets/nasdaq@2x.png';
import techCrunchLogo from '../assets/tech-crunch.png';
import techCrunchLogo2x from '../assets/tech-crunch@2x.png';

import { footerAsFeaturedInTextStyles } from '../styles/footerSignUpText.styles';

type FeaturesProps = {
  isDesktop: Boolean,
};

const LogoImage = ({ logo }: any) => (
  <Image
    {...{
      w: 'auto',
      h: logo.height || '20px',
    }}
    src={logo.icon}
    srcSet={`${logo.iconLarge} 2x`}
    alt={logo.alt}
    key={logo.id}
  />
);

const Features = ({ isDesktop }: FeaturesProps) => {
  const [isSmallDesktop] = useMediaQuery('(min-width: 575px)', { ssr: false });

  const logoArray = [
    {
      id: 1,
      icon: forbesLogo,
      iconLarge: forbesLogo2x,
      alt: 'Forbes',
      height: '20px',
    },
    {
      id: 2,
      icon: techCrunchLogo,
      iconLarge: techCrunchLogo2x,
      alt: 'Tech Crunch',
      height: '20px',
    },
    {
      id: 3,
      icon: businessInsiderLogo,
      iconLarge: businessInsiderLogo2x,
      alt: 'Business Insider',
      height: '20px',
    },
    {
      id: 4,
      icon: nasdaqLogo,
      iconLarge: nasdaqLogo2x,
      alt: 'Nasdaq',
      height: '24px',
    },
    {
      id: 5,
      icon: biggerPocketsLogo,
      iconLarge: biggerPocketsLogo2x,
      alt: 'Bigger Pockets',
      height: '20px',
    },
  ];

  const mobileLogoArrayOne = [
    {
      id: 1,
      icon: forbesLogo,
      iconLarge: forbesLogo2x,
      alt: 'Forbes',
      height: '20px',
    },
    {
      id: 2,
      icon: businessInsiderLogo,
      iconLarge: businessInsiderLogo2x,
      alt: 'Business Insider',
      height: '20px',
    },
    {
      id: 3,
      icon: techCrunchLogo,
      iconLarge: techCrunchLogo2x,
      alt: 'Tech Crunch',
      height: '20px',
    },
  ];

  const mobileLogoArrayTwo = [
    {
      id: 4,
      icon: nasdaqLogo,
      iconLarge: nasdaqLogo2x,
      alt: 'Nasdaq',
      height: '24px',
    },
    {
      id: 5,
      icon: biggerPocketsLogo,
      iconLarge: biggerPocketsLogo2x,
      alt: 'Bigger Pockets',
      height: '20px',
    },
  ];

  return (
    <Stack gap={{ base: '32px', xl: '40px' }}>
      {/* Building the security features section */}
      <Stack m="0 !important">
        {isDesktop ? (
          <VStack alignItems="left">
            <Text {...footerAsFeaturedInTextStyles}>As featured in:</Text>
            <HStack justifyContent="space-between" spacing={{ '3xl': 6, '2xl': 5, base: 3 }}>
              {logoArray.map((logo) => (
                <LogoImage logo={logo} key={logo.id} />
              ))}
            </HStack>
          </VStack>
        ) : (
          <VStack justifyContent="flex-start" alignItems="left" w="100%">
            <Text {...footerAsFeaturedInTextStyles}>As featured in:</Text>
            {isSmallDesktop ? (
              <HStack justifyContent="space-between" spacing={6}>
                {logoArray.map((logo) => (
                  <LogoImage logo={logo} key={logo.id} />
                ))}
              </HStack>
            ) : (
              <VStack justifyContent="flex-start" alignItems="left" w="100%" flexWrap="wrap">
                <HStack gap="38px" h="48px">
                  {mobileLogoArrayOne.map((logo) => (
                    <LogoImage logo={logo} key={logo.id} />
                  ))}
                </HStack>
                <HStack gap="38px" h="48px" mt="0 !important">
                  {mobileLogoArrayTwo.map((logo) => (
                    <LogoImage logo={logo} key={logo.id} />
                  ))}
                </HStack>
              </VStack>
            )}
          </VStack>
        )}
      </Stack>
    </Stack>
  );
};

export default Features;
