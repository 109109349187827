export const strongTextStyles = {
  textStyle: 'headline-sm',
  fontStyle: 'normal',
  color: 'brand.neutral.700',
  mb: '10px',
};

export const normalTextStyles = {
  textStyle: 'sm',
  fontStyle: 'normal',
  color: 'brand.neutral.700',
  mb: '10px',
};

export const secondaryTextStyles = {
  textStyle: 'xs',
  fontStyle: 'normal',
  color: 'brand.neutral.700',
  mb: '10px',
};

export const lightSecondaryTextStyles = {
  textStyle: 'xs',
  fontStyle: 'normal',
  color: 'brand.neutral.500',
  mb: '10px',
};
