// @flow
import React from 'react';
import { isMobile } from 'react-device-detect';
import SortFieldEnum from '@shared/components/SortTableHeader/models/SortFieldEnum';
import SortHeaderItem from '@shared/components/SortTableHeader/components/SortHeaderItem';
import { BaselaneGrid } from '@shared/components';
import { sortHeaderContainer } from './helpers/transfers.styles';

type StatementTableHeaderProps = { onSort: Function, filters: Object };

const StatementTableHeader = ({ onSort, filters }: StatementTableHeaderProps) => {
  const headerItems = [
    {
      title: 'Account Name',
      field: SortFieldEnum.BankAccountName,
    },
    {
      title: 'Statement Period',
      field: SortFieldEnum.Period,
    },
    {
      title: 'Download',
    },
  ];

  return (
    <BaselaneGrid
      gridStyles={{ ...sortHeaderContainer, paddingRight: '0' }}
      gridItemStyles={{ p: isMobile ? '8px' : '12px 24px' }}
      gridItems={headerItems.map((headerItem) => ({
        element: (
          <SortHeaderItem
            title={headerItem.title}
            filters={filters}
            field={headerItem.field}
            onSort={onSort}
          />
        ),
      }))}
      templateColumns={isMobile ? '3fr 4fr 1fr' : '7fr 190px 105px'}
    />
  );
};

export default StatementTableHeader;
