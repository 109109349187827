import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDisclosure } from '@chakra-ui/react';
import { useApolloClient } from '@apollo/client';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

export const sendRentCollectionStartedSegmentEvent = (cache) => {
  const { totalProperties = 0, totalUnits = 0 } = cache.data.data.ROOT_QUERY?.propertySummary || {};
  const { activeLease = 0 } = cache.data.data.ROOT_QUERY?.leaseDetail?.summary || {};

  sendSegmentEvent('add_rent_collection_started', {
    title: document.title,
    properties_count: totalProperties,
    units_count: totalUnits,
    rent_collection_count: activeLease,
    isMobile,
  });
};

const useCreateLease = (unit) => {
  const {
    isOpen: isLeaseDrawerOpen,
    onOpen: openLeaseDrawer,
    onClose: closeLeaseDrawer,
  } = useDisclosure();

  const { cache } = useApolloClient();
  useEffect(() => {
    if (isLeaseDrawerOpen && !unit?.lease?.id) {
      sendRentCollectionStartedSegmentEvent(cache);
    }
  }, [isLeaseDrawerOpen]);

  return { isLeaseDrawerOpen, openLeaseDrawer, closeLeaseDrawer };
};

export default useCreateLease;
