import React from 'react';
import { IconCheckCircleNoPadding, IconCircleOutlineNoPadding, IconCircleBlue } from '@icons';
import formatCurrency from '@core/utils/formatCurrency';
import { getActiveBonusObject } from '@core/components/NativeBankingPage/Summary/helpers/util.helpers';

const showShortAMount = (isMinXL, amount) => {
  return isMinXL || amount > 9999999;
};

const getNextMonthsAPY = (activeConfig, hasCollectedRentWithinNeededPeriod) => {
  if (!activeConfig) return '';

  const { product, bonus = [] } = activeConfig;
  return hasCollectedRentWithinNeededPeriod
    ? (product?.value || 0) + (bonus[0]?.value || 0)
    : product?.value || 0;
};

const getTierInfo = (tiersData, totalBalance) => {
  return tiersData?.find((t) => totalBalance <= t.maxValue && totalBalance >= t.minValue) || {};
};

export const getOverviewData = ({
  isMinXL,
  baselaneBankBalance,
  hasCollectedRentWithinNeededPeriod,
  activeConfig,
  lastMonthActivity,
  currentApy,
  tiersData,
  isUserOnPromo,
  userPromoApy = 0,
  isUserInLastMonthOfPromo,
}) => {
  // always sending false for getNextMonthsAPY because we don't want to add bonus for this one
  const nextMonthsAPYWithoutBonus =
    isUserOnPromo && !isUserInLastMonthOfPromo
      ? userPromoApy || 0
      : getNextMonthsAPY(activeConfig, false);
  // calculate next month's apy
  const nextMonthsAPY =
    isUserOnPromo && !isUserInLastMonthOfPromo
      ? userPromoApy || 0
      : getNextMonthsAPY(activeConfig, hasCollectedRentWithinNeededPeriod);
  const activeConfigBonus = getActiveBonusObject(activeConfig);

  const lastMonthTierInfo = getTierInfo(tiersData, lastMonthActivity?.totalBalance || 0);
  const lastMonthAPYWithoutBonus =
    isUserOnPromo && !isUserInLastMonthOfPromo
      ? userPromoApy || 0
      : lastMonthTierInfo?.product?.value;
  const lastMonthBonus = getActiveBonusObject(lastMonthTierInfo);

  const maxTier = tiersData && tiersData[tiersData.length - 1];
  // User has end balance on max tier
  const isUserOnMaxTier = lastMonthTierInfo?.product?.value === maxTier?.product?.value;

  return {
    currentMonthActivity: {
      title: 'Current Month’s Activity',
      left: {
        title: 'Total Balance',
        lineA: isMinXL
          ? formatCurrency(baselaneBankBalance, true).shortFormatInDollars
          : formatCurrency(baselaneBankBalance, true).inDollars,
        lineB: activeConfig ? `${formatCurrency(nextMonthsAPYWithoutBonus).noDollar}% APY` : '',
      },
      center: {
        title: 'Rent Collected*',
        lineA: hasCollectedRentWithinNeededPeriod ? 'Yes' : 'No',
        lineB: hasCollectedRentWithinNeededPeriod
          ? `+${formatCurrency(activeConfigBonus?.value || 0, true).noDollar}% APY`
          : '+0% APY',
      },
      right: {
        title: <>Next Month’s APY&dagger;</>,
        lineA: activeConfig ? `${formatCurrency(nextMonthsAPY).noDollar}%` : '--',
        lineB: 'Total APY',
      },
    },
    lastMonthActivity: {
      title: 'Last Month’s Activity',
      left: {
        title: isMinXL ? 'End Balance' : 'Last Month’s End Balance',
        lineA: showShortAMount(isMinXL, lastMonthActivity?.totalBalance)
          ? formatCurrency(lastMonthActivity?.totalBalance || 0, true).shortFormatInDollars
          : formatCurrency(lastMonthActivity?.totalBalance || 0, true).inDollars,
        lineB: activeConfig ? `${formatCurrency(lastMonthAPYWithoutBonus || 0).noDollar}% APY` : '',
      },
      center: {
        title: 'Rent Collected*',
        lineA: lastMonthActivity?.rentCollection ? 'Yes' : 'No',
        lineB:
          lastMonthActivity?.rentCollection && !isUserOnMaxTier
            ? `+${formatCurrency(lastMonthBonus?.value || 0, true).noDollar}% APY`
            : '+0% APY',
      },
      right: {
        title: 'Current APY',
        lineA: `${formatCurrency(currentApy || 0, true).noDollar}%`,
        lineB: 'Total APY',
      },
    },
  };
};

export const getIcon = (isActive, isCompleted) => {
  if (isActive) {
    return <IconCheckCircleNoPadding width="20" height="20" />;
  }
  if (isCompleted) {
    return <IconCircleBlue />;
  }
  return <IconCircleOutlineNoPadding w={20} h={20} color="#77BEFE" />;
};
