import React from 'react';
import {
  Box,
  useToast,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import SlLoader from '@core/components/Loader';
import { BaselaneButton } from '@shared/components';
import { DELETE_PAYMENT_METHOD, GET_PAYEES } from '@core/apollo/queries/recipients';
import { handleCancel, popSegmentFromPathname } from '../../helpers/navigation.helpers';

const PaymentMethodDelete = () => {
  const location = useLocation() || {};
  const navigate = useNavigate();

  const { state } = location || {};
  const { from, paymentId } = state || {};

  const toast = useToast();

  const showDeleteRecipientErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: `Error, recipient could not be deleted.`,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const [deletePaymentMethod, { loading: isPaymentMethodDeleting }] = useMutation(
    DELETE_PAYMENT_METHOD,
    {
      refetchQueries: [GET_PAYEES],
    }
  );

  const deleteRecipient = () => {
    deletePaymentMethod({
      variables: {
        id: parseFloat(paymentId),
      },
    })
      .then((res) => {
        // close modal with "success" state
        if (!res?.errors) {
          const successDestination = popSegmentFromPathname(from);
          navigate(successDestination, {
            replace: true,
            state: { isDeletePaymentMethodSuccessful: true, deletedPaymentMethodId: paymentId },
          });
        } else {
          // generic error toast- TODO: this may need to be displayed differently
          showDeleteRecipientErrorToast();
        }
      })
      .catch((err) => {
        showDeleteRecipientErrorToast();
      });
  };

  return (
    <>
      {isPaymentMethodDeleting && (
        <Box>
          <SlLoader styles={{ position: 'absolute !important' }} />
        </Box>
      )}
      {!isPaymentMethodDeleting && (
        <Modal isOpen isCentered onClose={() => handleCancel(navigate, from)} w="352px">
          <ModalOverlay />
          <ModalContent p={4}>
            <ModalHeader p={0} textStyle="headline-lg">
              <Text textStyle="headline-lg">Delete payment method?</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody p={0}>
              <Text textStyle="sm" mt={0.5}>
                This will remove the payment method for all future payments, but won’t impact any
                past or processing payments.
              </Text>
            </ModalBody>
            <ModalFooter gap={2} p={0} mt={3}>
              <BaselaneButton
                variant="outline"
                palette="neutral"
                size="md"
                onClick={() => handleCancel(navigate, from)}
                styles={{
                  w: 'full',
                }}
              >
                Cancel
              </BaselaneButton>
              <BaselaneButton
                variant="filled"
                palette="danger"
                size="md"
                onClick={deleteRecipient}
                styles={{
                  w: 'full',
                }}
              >
                Delete
              </BaselaneButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default PaymentMethodDelete;
