import process from 'process';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from '@core/Services/Firebase/firebase';

const useBuildForgotPasswordBodyParams = () => {
  const location = useLocation();
  const type = getQueryParams(location, 'type');
  const url =
    type === 'tenant' ? 'https://tenant.baselane.com/login' : 'https://app.baselane.com/login';

  const buildBodyParams = (email, integrationPartner = null) => {
    const bodyParams = {
      email,
      redirectUrl:
        process.env.REACT_APP_NODE_ENV === 'production' ? url : 'http://localhost:3000/login',
    };

    if (integrationPartner) {
      bodyParams.integrationPartner = integrationPartner;
    }

    return bodyParams;
  };

  return { buildBodyParams };
};

export default useBuildForgotPasswordBodyParams;
