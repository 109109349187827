import React from 'react';
import { Stack } from '@chakra-ui/react';
import Banner from './Banner';
import MainContent from './MainContent';

function LoansPage() {
  return (
    <Stack spacing="38px">
      <Banner />
      <MainContent />
    </Stack>
  );
}

export default LoansPage;
