import React from 'react';

type IconPropertiesProps = { w?: Number, h?: Number, color?: string };

function IconProperties({ w, h, color }: IconPropertiesProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 18 18" fill="none">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.222 17H2.778m12.444 0H17m-1.778 0h-4.444m4.444 0V2a1 1 0 0 0-1-1H3.778c-.553 0-1 .441-1 .994V17m0 0H1m1.778 0h4.444m3.556 0H7.222m3.556 0v-4.333a1 1 0 0 0-1-1H8.222a1 1 0 0 0-1 1V17M6.333 4.556h.89m-.89 3.555h.89m3.555-3.555h.889m-.89 3.555h.89"
      />
    </svg>
  );
}

IconProperties.defaultProps = {
  w: 18,
  h: 18,
  color: 'currentColor',
};

export default IconProperties;
