import React, { useState, useImperativeHandle } from 'react';
import moment from 'moment';
import { orderBy, pull, isEqual } from 'lodash';
import { Spacer, Stack, Text, Box, Grid, GridItem, Input, useDisclosure } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import MaskedInput from 'react-text-mask';
import { Icon16Delete } from '@icons/16px';
import IconCheck from '@icons/legacy/IconCheck';
import {
  BaselaneDivider,
  BaselaneButton,
  BaselaneButtonIcon,
  BaselaneSingleDatePicker,
} from '@shared/components';
import {
  headerTitleStyles,
  headerRowStyles,
} from '@shared/components/BaselaneTable/styles/table.style';
import stripCurrency from '@core/utils/stripCurrency';
import getBreakPoints from '@core/utils/getBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import { currencyMask, percentageMask } from '@core/utils/masks';
import { drawerMenuContentTitleStyle, errorStyles } from '../../../styles/drawer.style';
import { formInputStyles, formInputComponentStyles } from '../../../styles/form.style';

type MortgageSetUpProps = {
  property: Object,
  onBackClick: Function,
  detailsComplete: boolean,
  initialValues: Object,
  isDirty: boolean,
  setIsDirty: Function,
  setFormVariables: Function,
  setHideFooter: Function,
  mortgageRef: Object,
};

function MortgageSetUp({
  property,
  onBackClick,
  initialValues,
  isDirty,
  setIsDirty,
  setFormVariables,
  setHideFooter,
  mortgageRef,
}: MortgageSetUpProps) {
  const { isMin768 } = getBreakPoints();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const tableHeader = [
    { title: 'Date' },
    { title: 'Mortgage Balance' },
    { title: 'Mortgage Interest Rate (Optional)' },
  ];

  const propertyMortgageBalance = property?.mortgageBalance || [];
  const sortedPropertyMortgageBalance = orderBy(propertyMortgageBalance, ['date'], ['desc']);

  const initialMortgageBalance = initialValues?.mortgageBalance || [];
  const sortedInitialMortgageBalance = orderBy(initialMortgageBalance, ['date'], ['desc']);
  const [mortgageBalance, setMortgageBalance] = useState(sortedInitialMortgageBalance);

  // states to store newMortgageBalance
  const [newMortgageBalance, setNewMortgageBalance] = useState({
    date: null,
    balance: null,
    interestRate: null,
  });
  const [date, setDate] = useState(null);
  const [balance, setBalance] = useState(null);
  const [interestRate, setInterestRate] = useState(null);

  // states for errors
  const [showErrors, setShowErrors] = useState(false);
  const [showDateErrors, setShowDateError] = useState(false);

  // state for isItemLocked (empty first row)
  const [isItemLocked, setIsItemLocked] = useState(false);

  const checkIsUniqueDate = (d) => {
    const formattedDate = moment.utc(d).format();
    return !mortgageBalance?.some((p) => p.date === formattedDate);
  };

  const clearFields = () => {
    setIsItemLocked(false);
    setBalance(null);
    setDate(null);
    setInterestRate(null);
    setNewMortgageBalance({
      date: null,
      balance: null,
      interestRate: null,
    });
  };

  const handleShowDateError = (d) => {
    if (checkIsUniqueDate(d)) {
      setShowDateError(false);
    } else {
      setShowDateError(true);
    }
  };

  const handleBalanceChange = (e) => {
    const balanceToBeUpdated = e.target.value;
    setShowErrors(false);
    handleShowDateError(date);
    setBalance(balanceToBeUpdated);

    setNewMortgageBalance({
      date: date ? moment.utc(date).format() : date,
      balance: stripCurrency(balanceToBeUpdated),
      interestRate: stripCurrency(interestRate),
    });
  };

  const handleDateChange = ({ date: d, isFromApplyButton }) => {
    if (isMin768 || (!isMin768 && isFromApplyButton)) {
      const dateToBeUpdated = d;
      const formattedDateToBeUpdated = moment.utc(dateToBeUpdated).format();
      setShowErrors(false);
      handleShowDateError(dateToBeUpdated);
      setDate(dateToBeUpdated);

      setNewMortgageBalance({
        date: dateToBeUpdated ? formattedDateToBeUpdated : null,
        balance: stripCurrency(balance),
        interestRate: stripCurrency(interestRate),
      });
    }
  };

  const handleInterestRateChange = (e) => {
    const interestRateToBeUpdated = e.target.value;
    setShowErrors(false);
    handleShowDateError(date);
    setInterestRate(interestRateToBeUpdated);

    setNewMortgageBalance({
      date: date ? moment.utc(date).format() : date,
      balance: stripCurrency(balance),
      interestRate: stripCurrency(interestRateToBeUpdated),
    });
  };

  const handleOnBackButtonClick = () => {
    let sortedNewMortgageBalances;
    if (isItemLocked && newMortgageBalance.date && newMortgageBalance.balance) {
      const newMortgageBalances = [...mortgageBalance, newMortgageBalance];
      sortedNewMortgageBalances = orderBy(newMortgageBalances, ['date'], ['desc']);
    } else {
      // if user clicked on delete or add without making any edits
      sortedNewMortgageBalances = orderBy(mortgageBalance, ['date'], ['desc']);
    }
    const isSameArr = isEqual(sortedPropertyMortgageBalance, sortedNewMortgageBalances);
    if (!isSameArr) {
      setMortgageBalance(sortedNewMortgageBalances);
      setFormVariables({ mortgageBalance: sortedNewMortgageBalances });
      setIsDirty({ ...isDirty, ...{ currentMortgageBalance: true } });
    } else {
      setMortgageBalance(sortedPropertyMortgageBalance);
      setFormVariables({ mortgageBalance: sortedPropertyMortgageBalance });
      setIsDirty({ ...isDirty, ...{ currentMortgageBalance: false } });
    }

    setHideFooter(false);
    clearFields();
    onBackClick();
  };

  // exposing back button function to parent
  useImperativeHandle(mortgageRef, () => ({
    handleOnBackButtonClick,
  }));

  const handleDeleteMortgage = (item) => {
    const newMortgageBalances = [...mortgageBalance];
    pull(newMortgageBalances, item);
    if (showDateErrors && newMortgageBalance.date === item.date) {
      setShowDateError(false);
    }
    setMortgageBalance(newMortgageBalances);
  };

  const handleAddMortgageBalance = () => {
    if (newMortgageBalance.date && newMortgageBalance.balance) {
      const newMortgageBalances = [...mortgageBalance, newMortgageBalance];
      const sortedNewMortagageBalances = orderBy(newMortgageBalances, ['date'], ['desc']);
      setMortgageBalance(sortedNewMortagageBalances);
      clearFields();
    } else {
      setShowErrors(true);
    }
  };

  const handleLockItem = () => {
    setIsItemLocked(true);
  };

  const handleLockedItemDelete = () => {
    clearFields();
  };

  return (
    <Box h="100%">
      <Stack direction="row" alignItems="center">
        <Text {...drawerMenuContentTitleStyle} ml="13px !important">
          Current Principal Balance
        </Text>
      </Stack>
      <Box m="36px 32px 16px 0" display="flex" justifyContent="flex-end">
        <BaselaneButton
          variant="outline"
          palette="neutral"
          onClick={handleAddMortgageBalance}
          isDisabled={
            showErrors ||
            showDateErrors ||
            !newMortgageBalance.date ||
            !newMortgageBalance.balance ||
            !isItemLocked
          }
        >
          Add New
        </BaselaneButton>
      </Box>
      <Box id="zillowForm" mr="32px" h="88%">
        <Grid templateColumns="repeat(3, 1fr)" {...headerRowStyles}>
          {tableHeader.map((item) => {
            const key = uuidv4();
            return (
              <GridItem key={key}>
                <Text {...headerTitleStyles}>{item.title}</Text>
              </GridItem>
            );
          })}
        </Grid>
        <Box
          position="relative"
          h="calc(100% - 88px)"
          top="0"
          className="test"
          overflow="auto"
          pt="22px"
        >
          <Grid templateColumns="repeat(3, 1fr)" px="45px" mb="20px">
            <GridItem {...formInputStyles}>
              <BaselaneSingleDatePicker
                {...{
                  id: 'date',
                  name: 'date',
                  maxDate: moment().toDate(),
                  date,
                  value: date ? `${formatDate(date, 'MMM DD, YYYY')}` : '',
                  dateFormat: 'MMM dd, yyyy',
                  handleCalendarClose: handleDateChange,
                  size: 'md',
                  years: { start: 2010, end: new Date().getFullYear() },
                  isOpen,
                  onOpen,
                  onClose,
                  hideInputIcon: true,
                  isDisabled: isItemLocked,
                  placement: 'bottom-start',
                  showInModal: !isMin768,
                  className: 'w-200',
                }}
              />
            </GridItem>
            <GridItem>
              <Input
                {...formInputComponentStyles}
                as={MaskedInput}
                value={balance}
                onChange={handleBalanceChange}
                placeholder="$"
                mask={currencyMask()}
                readOnly={isItemLocked}
                color={isItemLocked && 'brand.darkBlue.300'}
              />
            </GridItem>
            <GridItem>
              <Stack direction="row">
                <Input
                  {...formInputComponentStyles}
                  as={MaskedInput}
                  value={interestRate}
                  onChange={handleInterestRateChange}
                  placeholder="Enter Interest"
                  mask={percentageMask({ decimalLimit: 3 })}
                  readOnly={isItemLocked}
                  color={isItemLocked && 'brand.darkBlue.300'}
                />

                <Spacer />

                {isItemLocked ? (
                  <BaselaneButtonIcon
                    variant="outline"
                    palette="neutral"
                    icon={<Icon16Delete />}
                    onClick={handleLockedItemDelete}
                  />
                ) : (
                  <BaselaneButtonIcon
                    variant="filled"
                    palette="primary"
                    icon={<IconCheck />}
                    onClick={handleLockItem}
                    isDisabled={
                      showErrors ||
                      showDateErrors ||
                      !newMortgageBalance.date ||
                      !newMortgageBalance.balance
                    }
                  />
                )}
              </Stack>
            </GridItem>
          </Grid>
          {showErrors && (
            <Text {...errorStyles} pl="45px">
              Please enter all fields
            </Text>
          )}
          {showDateErrors && (
            <Text {...errorStyles} pl="45px">
              Please enter a unique date
            </Text>
          )}

          <BaselaneDivider styles={{ m: '0' }} />

          {mortgageBalance.length > 0 &&
            mortgageBalance.map((item) => {
              const key = uuidv4();
              return (
                <Box key={key}>
                  <Grid templateColumns="repeat(3, 1fr)" px="45px" my="20px">
                    <GridItem>
                      <BaselaneSingleDatePicker
                        {...{
                          id: 'date',
                          name: 'date',
                          date: moment(item.date).toDate(),
                          dateFormat: 'MMM dd, yyyy',
                          size: 'md',
                          years: { start: 2010, end: new Date().getFullYear() },
                          hideInputIcon: true,
                          isDisabled: true,
                          placement: 'bottom-start',
                          className: 'w-200',
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <Input
                        {...formInputComponentStyles}
                        as={MaskedInput}
                        value={item.balance}
                        placeholder="$"
                        mask={currencyMask()}
                        readOnly
                      />
                    </GridItem>
                    <GridItem>
                      <Stack direction="row">
                        <Input
                          {...formInputComponentStyles}
                          as={MaskedInput}
                          value={item.interestRate}
                          mask={percentageMask({ decimalLimit: 3 })}
                          readOnly
                        />
                        <Spacer />
                        <BaselaneButtonIcon
                          variant="outline"
                          palette="neutral"
                          icon={<Icon16Delete />}
                          onClick={() => handleDeleteMortgage(item)}
                        />
                      </Stack>
                    </GridItem>
                  </Grid>
                  <BaselaneDivider styles={{ m: '0' }} />
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
}

export default MortgageSetUp;
