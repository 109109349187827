export const addPropertyStyles = ({ isMinXL }) => {
  return {
    container: {
      p: isMinXL ? '24px 0' : '40px 24px',
      color: 'brand.neutral.700',
      borderTopWidth: '1px',
      borderColor: 'brand.darkBlue.150',
      alignItems: isMinXL ? 'flex-start' : 'center',
    },
    title: {
      textStyle: 'headline-md',
    },
    description: {
      textStyle: 'sm',
      m: '16px 0 24px 0 !important',
    },
    button: {
      m: '0 !important',
    },
  };
};
