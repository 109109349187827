import React from 'react';
import { Text, Stack } from '@chakra-ui/react';
import { Highlighted } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { menuItemTextStyles, menuItemAddressTextStyles } from '../styles/propertiesFilter.styles';

export const getPropertyData = (propertiesData, isMultiunitPropertyDisabled = false) => {
  // Sort properties alphabetically
  const sortedProperties = [...propertiesData].sort((a, b) => {
    const compareName = a.name?.localeCompare(b.name);
    return compareName;
  });

  const properties = sortedProperties.reduce((acc, property) => {
    const { units, ...propertyObj } = property;

    // Sort units alphabetically
    const sortedUnits = [...units]?.sort((a, b) => {
      const compareName = a.name?.localeCompare(b.name);
      return compareName;
    });

    // Add anything user wants to search by to searchValues arr
    propertyObj.searchValues = [propertyObj.name];
    propertyObj.showValueName = propertyObj.name;

    if (isMultiunitPropertyDisabled) {
      propertyObj.isPropertyLevel = true;
      propertyObj.isDisabled = units?.length > 1;

      if (units?.length === 1) {
        propertyObj.id = `${propertyObj.id}, ${units[0]?.id}`;
      }
    }

    const propertyUnits = sortedUnits?.map((unit) => {
      // add unit name to property search values
      propertyObj.searchValues = propertyObj.searchValues.concat(unit.name);

      // unit.id can be same as propertyObj.id so need to make the id unique
      return {
        ...unit,
        id: `${propertyObj.id}-${unit.id}`,
        unitId: unit.id,
        isChild: true,
        showValueName: `${propertyObj.name}, ${unit.name}`,
        searchValues: [propertyObj.name, unit.name],
      };
    }, []);

    acc.push({ ...propertyObj, hasChildren: sortedUnits.length > 1 });

    if (propertyUnits && units.length > 1) {
      return acc.concat(propertyUnits);
    }

    return acc;
  }, []);

  return properties;
};

// TODO: to be removed start to use function below this:
export const renderPropertyDropdownParentItem = (item) => {
  const { name, address } = item;
  return (
    <Stack spacing={0} overflow="hidden">
      <Text {...menuItemTextStyles} color="inherit">
        {name}
      </Text>
      <Text {...menuItemAddressTextStyles}>
        {`${address.address}, ${address.unit} ${address.city}, ${address.state} ${address.zipCode}`}
      </Text>
    </Stack>
  );
};

export const propertyDropdownParentItemRenderer = ({ item, search }) => {
  const { name, address } = item;
  return (
    <Stack spacing={0} overflow="hidden">
      <Text {...menuItemTextStyles} color="inherit">
        <Highlighted text={name} highlight={search} />
      </Text>
      <Text {...menuItemAddressTextStyles}>
        <Highlighted
          text={`${address.address}, ${address.unit} ${address.city}, ${address.state} ${address.zipCode}`}
          highlight={search}
        />
      </Text>
    </Stack>
  );
};

export const renderPropertyDropdownChildItem = (item) => item.name;
