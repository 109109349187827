import { useQuery } from '@apollo/client';
import { GET_BANKS } from '@core/apollo/queries';

const useConnectedManualBank = () => {
  const {
    loading: isConnectedManualBankLoading,
    error: hasConnectedManualBankError,
    data: connectedManualBankData,
    refetch: refetchConnectedManualBank,
  } = useQuery(GET_BANKS, {
    fetchPolicy: 'network-only',
    variables: {
      isManualAccount: true,
      isConnectedAccount: true,
    },
  });

  return {
    isConnectedManualBankLoading,
    hasConnectedManualBankError,
    connectedManualBankData,
    refetchConnectedManualBank,
  };
};

export default useConnectedManualBank;
