import { gql } from '@apollo/client';

export const GET_SCHEDULED_PAYMENTS = gql`
  query GetScheduledPayments($input: TransferQueryOption) {
    scheduledPayments(input: $input) {
      transferId
      transferId
      name
      email
      amount
      transferDate
      recurringPaymentId
      totalNumberOfPayments
      transferType
      totalProcessedTransfers
      recurringPaymentId
      recurrencePattern {
        type
        interval
        monthlyByDate
        lastDayOfMonth
        daysOfMonth
        daysOfWeek
        numberOfPayments
      }
      total
      paymentMethodType
      accountSubType
      accountNumber
      routingNumber
      bankName
      toTransferAccountId
    }
  }
`;

export const CANCEL_UPCOMING_PAYMENT = gql`
  mutation cancelUpcomingPayment($id: Float!) {
    cancelUpcomingPayment(id: $id)
  }
`;

export const CANCEL_RECURRING_PAYMENTS = gql`
  mutation cancelRecurringPayments($id: Float!) {
    cancelRecurringPayments(id: $id)
  }
`;
