export const datePickerStyles = {
  header: {
    container: {
      background: 'brand.neutral.white',
      gap: 0.5,
    },
    innercontainer: {
      justifyContent: 'space-between',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      gap: 1,
    },
    monthnavbutton: {
      m: '0 !important',
      minWidth: '40px !important',
    },
    monthyeardropdown: {
      m: '0 !important',
      w: '100%',
      fontWeight: 'semibold',
    },
    divider: {
      my: '8px !important', // override :not(style) + cannot apply '1 !important'
    },
    todaybutton: {
      m: 0,
      mt: '0 !important', // override :not(style)
      w: '100%',
      iconSpacing: '6px',
      fontWeight: 'semibold',
    },
  },
  monthyearpicker: {
    container: (showInModal) => {
      return {
        position: 'absolute',
        top: '76px',
        bottom: 0,
        left: '1px',
        right: '1px',
        background: 'brand.neutral.white',
        alignItems: 'flex-start',
        px: showInModal ? 0 : 2,
        py: 0,
        mb: 0.75,
        zIndex: 1,
      };
    },
    selectcontainer: {
      w: '50%',
      maxH: '100%',
      gap: 0.5,
      overflowY: 'auto',
      m: '0 !important',
      px: 0,
      py: 0.5,
      css: {
        '&::-webkit-scrollbar': {
          width: '14px',
        },
        '&::-webkit-scrollbar-track': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#E7E9EB',
          backgroundClip: 'padding-box',
          border: '4px solid transparent',
          borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#E7E9EB',
          backgroundClip: 'padding-box',
          border: '4px solid transparent',
          borderRadius: '8px',
        },
      },
    },
    select: (isSelected) => {
      return {
        textAlign: 'center',
        w: 'calc(100% - 8px)',
        m: '0 !important',
        color: isSelected ? 'brand.neutral.white' : '#192C3E !important',
        fontWeight: isSelected ? 'semibold' : 'normal',
      };
    },
  },
  inputdivider: (showInModal) => {
    return {
      w: '16px',
      minW: '16px',
      borderColor: 'brand.darkBlue.200',
      m: '0 8px !important', // cannot apply '1 !important'
      position: 'relative',
      top: showInModal ? '14px' : '0',
    };
  },
  inputcontainer: {
    // fixed width to match the calendar width
    // to make the 2 diferent calendars appear as 1
    w: '338px',
    maxW: '338px',
    minW: '338px',
    m: '0 !important',
    gap: 0,
  },
};
