// export const AUTO_CAPTURE_TIMEOUT = 30000; Note: this is not currently used
export const CAPTURE_MODE = 'AUTO_CAPTURE';
export const MITEK_SDK_PATH = '/library/MWEB/mitekSDK';

export const AUTO_CAPTURE_HINTS = {
  MITEK_ERROR_GLARE: 'Reduce Glare',
  MITEK_ERROR_FOUR_CORNER: 'Check Not Found',
  MITEK_ERROR_TOO_DARK: 'Too Dark. Use brighter lighting.',
  MITEK_ERROR_FOCUS: 'Hold Steady',
  MITEK_ERROR_TOO_FAR: 'The check is too far',
  MITEK_ERROR_TOO_CLOSE: 'The check is too close',
  MITEK_ERROR_NOT_CENTERED: 'Check is not centered',
  MITEK_ERROR_MIN_PADDING: 'Move further away',
  MITEK_ERROR_HORIZONTAL_FILL: 'Move closer',
};

export const CUSTOM_HINTS = {
  CHECK_FRONT: {
    firstMessage: 'Center Check Front on a dark background',
    fourCornerMessage: 'Scanning for the Check Front',
  },
  CHECK_BACK: {
    firstMessage: 'Center Check Back on a dark background',
    fourCornerMessage: 'Scanning for the Check Back',
  },
};

export const DEFAULT_CUSTOM_HINT = 'Starting Camera...';

export const MITEK_EVENTS = {
  SDK_ERROR: 'SDK_ERROR',
  FRAME_PROCESSING_STARTED: 'FRAME_PROCESSING_STARTED',
  FRAME_PROCESSING_FEEDBACK: 'FRAME_PROCESSING_FEEDBACK',
  FRAME_CAPTURE_RESULT: 'FRAME_CAPTURE_RESULT',
  CAMERA_DISPLAY_STARTED: 'CAMERA_DISPLAY_STARTED',
};

export const MITEK_COMMANDS = {
  CAPTURE_AND_PROCESS_FRAME: 'CAPTURE_AND_PROCESS_FRAME',
  SDK_STOP: 'SDK_STOP',
  SHOW_HINT: 'SHOW_HINT',
};

export const MITEK_SDK_ERROR_CODES = {
  CAMERA_PERMISSION_DENIED: 113,
  WASM_NOT_SUPPORTED: 334,
};

export const mitekCaptureOptions = {
  qualityPercent: 80, // value between 50 to 100 recommended
  guidePaddingLevel: 1, // value should be between 1 and 3
  videoContainerId: 'check-viewfinder',
  // debugLevel: 3, - Used for debugging image capture issues
};

export const DOCUMENT_TYPES = {
  CHECK_FRONT: 'CHECK_FRONT',
  CHECK_BACK: 'CHECK_BACK',
};

export type AllowedDocumentTypes = DOCUMENT_TYPES.CHECK_FRONT | DOCUMENT_TYPES.CHECK_BACK;

// returns styles that apply the imageData as a background
export const getImageStyle = (imageData, isPreview = false) => {
  if (!imageData) return {};

  // create an off-screen canvas
  const offScreenCanvas = document.createElement('canvas');
  const offScreenCanvasCtx = offScreenCanvas.getContext('2d');

  // set image and fill img with base64 data and add an onload function
  const img = new Image();
  img.src = imageData;
  img.onload = () => {
    // this dummy function needs to be added so we only return on load
  };

  // set canvas dimension to rotated size
  offScreenCanvas.height = img.width;
  offScreenCanvas.width = img.height;

  // rotate and draw source image into the off-screen canvas:
  offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
  offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
  offScreenCanvasCtx.drawImage(img, 0, 0);

  // encode image to data-uri with base64
  let newImageData = null;
  newImageData = offScreenCanvas.toDataURL('image/jpeg', 100);

  // once img is loaded it means we can do a width vs height check
  return img.onload
    ? {
        backgroundPosition: `center center !important`,
        backgroundSize: isPreview ? `contain !important` : `cover !important`,
        backgroundRepeat: 'no-repeat !important',
        backgroundImage: `url(${img.width < img.height ? newImageData : imageData})`,
      }
    : {};
};

export const CHECK_DEPOSIT_STEP = {
  ENDORSMENT: 1,
  SCAN_CHECK: 2,
  TAGGING: 3,
  REVIEW: 4,
  SUCCESS: 5,
};

export const UNIT_CHECK_SIDE = {
  FRONT: 'front',
  BACK: 'back',
};

export const UNIT_CHECK_DEPOSIT_STATUS = {
  AWAITING_IMAGES: 'AwaitingImages',
  AWAITING_FRONT_IMAGE: 'AwaitingFrontImage',
  AWAITING_BACK_IMAGE: 'AwaitingBackImage',
  PENDING: 'Pending',
  PENDING_REVIEW: 'PendingReview',
  REJECTED: 'Rejected',
  CLEARING: 'Clearing',
  SENT: 'Sent',
  CANCELED: 'Canceled',
  RETURNED: 'Returned',
};

export const CHECK_UPLOAD_ERROR_KEYS = {
  ALTERED: 1,
  COUNTERFEIT: 2,
  NO_ENDORSEMENT: 3,
  UNREADABLE: 4,
  DATE_ISSUE: 5,
  ALREADY_SUBMITTED: 6,
  NO_SIGNATURE: 7,
  NAME_MISMATCH: 8,
  PROCESSING_FAILED: 9,
  UNREADABLE_ACCOUNT_DATA: 10,
  INTERFERENCE_ACCOUNT_DATA: 11,
  INCOMPLETE: 12,
  AMOUNT_MISMATCH: 13,
  BLANK_CHECK: 14,
};

export const CHECK_UPLOAD_ERRORS = {
  [CHECK_UPLOAD_ERROR_KEYS.ALTERED]: {
    title: `We’re unable to process this check`,
    message: `Retake photo of check. Ensure proper focus, lighting, & that all corners are visible.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.COUNTERFEIT]: {
    title: `We’re unable to process this check`,
    message: `Retake photo of check. Ensure proper focus, lighting, & that all corners are visible.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.NO_ENDORSEMENT]: {
    title: `There is an error with your check`,
    message: `It looks like you did not endorse your check. Sign and write "For remote deposit with Thread Bank" on back of check.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.UNREADABLE]: {
    title: `There is an error with your check`,
    message: `Retake photo of check. Ensure proper focus, lighting, & that all corners are visible.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.DATE_ISSUE]: {
    title: `There is an error with your check`,
    message: `It looks like this check is dated in the future or too far in the past. If incorrect, try again.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.ALREADY_SUBMITTED]: {
    title: `There is an error with your check`,
    message: `It looks like this check has already been submitted for deposit.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.NO_SIGNATURE]: {
    title: `There is an error with your check`,
    message: `It looks like your check is not signed. Have check issuer sign and try again.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.NAME_MISMATCH]: {
    title: `There is an error with your check`,
    message: `It looks like the name on the check does not match the name on the account.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.PROCESSING_FAILED]: {
    title: `Check processing failed `,
    message: `We encountered an error, please try again.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.UNREADABLE_ACCOUNT_DATA]: {
    title: `We’re unable to read your check `,
    message: `Retake photo of check. Ensure proper focus, lighting, & that all corners are visible.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.INTERFERENCE_ACCOUNT_DATA]: {
    title: `We’re unable to read your check`,
    message: `We are unable to read account info due to interference from signature or marking.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.INCOMPLETE]: {
    title: `There is an error with your check`,
    message: `It looks like your check has incomplete info.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.AMOUNT_MISMATCH]: {
    title: `There is an error with your check`,
    message: `It looks like the amount on the check does not match entered amount.`,
  },
  [CHECK_UPLOAD_ERROR_KEYS.BLANK_CHECK]: {
    title: `There is an error with your check`,
    message: `It looks like your check has incomplete info.`,
  },
};

/**
 * Debugging Functions for testing Content Security Policy
 * and Permissions Policy settings
 */
// Web Assembly Test
export const wasmSupported = () => {
  console.debug('Starting WebAssembly support test.');
  try {
    console.debug('Testing for: WebAssembly object.');
    if (typeof WebAssembly === 'object') {
      console.debug('Testing for: WebAssembly.instantiate function.');
      if (typeof WebAssembly.instantiate === 'function') {
        console.debug('Attempting to compile simple WebAssembly module.');
        const module = new WebAssembly.Module(
          Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
        );
        if (module instanceof WebAssembly.Module)
          console.debug('Testing to see if simple WebAssembly module is valid.');
        return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
      }
    }
  } catch (e) {
    console.debug('caught error in wasm test: ', { e });
  }
  return false;
};

// Camera Test
export const getUserMediaSupported = () => {
  console.debug('Starting getUserMedia() support test.');
  try {
    console.debug('Testing for: forms of getUserMedia function.');
    return !!(
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia
    );
  } catch (e) {
    console.debug('caught error in camera test: ', { e });
  }
  return false;
};
