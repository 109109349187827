import { truncatedText } from '../../../../../../Shared/styles/text.style';
import { tooltipStyles } from '../../../../../styles/externalAccounts.styles';

export const accountStyles = {
  chevron: {
    display: { lg: 'table-cell', base: 'none' },
  },
  image: {
    w: { lg: '40px', base: '32px' },
    minWidth: { lg: '40px', base: '32px' },
    h: { lg: '40px', base: '32px' },
  },
  logo: {
    background: {
      h: { lg: '40px', base: '32px' },
      w: { lg: '40px', base: '32px' },
      bg: 'brand.neutral.150',
      align: 'center',
      justify: 'center',
      p: '8px',
      borderRadius: '50%',
    },
    text: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: { lg: '32px', base: '24px' },
      lineHeight: { lg: '24px', base: '16px' },
      fontSize: '2xs',
      color: 'brand.neutral.600',
    },
    tooltip: {
      ...tooltipStyles,
    },
  },
  amount: {
    shared: {
      ...truncatedText,
      textStyle: 'headline-sm',
    },
    positive: {
      color: 'brand.neutral.700',
    },
    negative: {
      color: 'red.800AA',
    },
    unavailable: {
      textStyle: 'sm',
      color: 'brand.neutral.400',
      ...truncatedText,
    },
  },
  connection: {
    text: {
      textStyle: 'xs',
      color: 'brand.neutral.600',
      m: '0 !important',
      ...truncatedText,
    },
  },
};
