export const fieldContainer = {
  minHeight: '110px',
  position: 'relative',
};

export const formInputStyles = {
  size: 'sm',
  color: 'brand.neutral.700',
  borderRadius: '4px',
  h: '48px',
};

export const formSectionTitleStyles = {
  textStyle: 'headline-sm',
  minWidth: 'fit-content',
  mr: '24px',
  color: 'brand.neutral.700',
};

export const formErrorStyles = {
  position: 'absolute',
  color: 'red.500A',
  fontSize: '3xs',
};

export const formErrorMessagePosition = {
  position: 'relative',
};
