export const ERROR_MESSAGES = {
  OTP_VERIFY_ERROR: {
    message: 'Invalid code. Try again.',
    showErrorMessage: true,
  },
  TOO_MANY_OTP_REQUESTS: {
    message: '',
    showErrorMessage: false,
    isTooManyAttempts: true,
  },
  OTP_SEND_ERROR: {
    message: '',
    showErrorMessage: false,
    isGenericError: true,
  },
};
