import React from 'react';
import { Stack } from '@chakra-ui/react';
import { dropdownContentContainerStyles } from '@shared/components/BaselaneDropdown-new/styles/dropdown.styles';

type DisplayInputWrapperProps = {
  children: Array<React.Node>,
  handleContentRendererClick: Function,
  classNames: Array<string>,
};
const DisplayInputWrapper = ({
  children,
  handleContentRendererClick,
  classNames,
  ...rest
}: DisplayInputWrapperProps) => {
  return (
    <Stack
      {...{ ...dropdownContentContainerStyles(classNames), ...rest }}
      onClick={handleContentRendererClick}
    >
      {children}
    </Stack>
  );
};

export default DisplayInputWrapper;
