import React from 'react';

import { useLazyQuery } from '@apollo/client';
import { Box, HStack, Spacer, useDisclosure } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import historyStateStorage from '@core/storage/historyStateStorage';
import { UNIFIED_LB_PERSONAL_INFO } from '@routes';
import { GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import getBreakPoints from '@core/utils/getBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { IconStars, IconPlus } from '@icons';
import { IllustrationBankingNewaccount } from '@illustrations';

import BaselaneBadge from '../BaselaneBadge';
import BaselaneApplicationTypePopup from '../BaselaneApplicationTypePopup';
import {
  badgeTagStyles,
  badgeButtonStyles,
  badgeButtonIconStyles,
  supportingContentStyles,
  adBannerStyles,
} from './styles/baselaneBankingBanner.styles';

import BaselaneAdvertisingBanner from '.';

type BaselaneBankingBannerProps = {
  isNarrowOnly: Boolean,
  styles?: Object,
};

const BaselaneBankingBanner = ({ isNarrowOnly, styles, ...rest }: BaselaneBankingBannerProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isMinXL } = getBreakPoints();

  // Application Type Selector Popup
  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding: unitBankUrl }) => window.open(unitBankUrl, '_self'),
    onError: (error) => console.error(error),
  });

  const handleBaselaneApplicationPopupClick = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const state = { from: location.pathname };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  const handleBannerClick = () => {
    sendSegmentEvent('rc_click_banner_open_baselane_bank');
    onBaselaneApplicationTypePopupOpen();
  };

  const getSupportingContent = () => {
    const { container, leftcontent, textcontainer } = supportingContentStyles({ isMinXL });
    return (
      <HStack {...container}>
        <HStack alignItems="flex-start" mt={isMinXL ? '0 !important' : '12px !important'}>
          <Box {...leftcontent}>
            <IconStars w={13.33} h={13.33} />
          </Box>
          <Box {...textcontainer}>
            Multiple accounts per property, automated bookkeeping, 4.19% APY, up to 5% cash back, no
            account fees, no minimum balances
          </Box>
        </HStack>
        {!isMinXL && <Spacer />}
        <BaselaneBadge
          {...{
            color: 'blue',
            type: 'badge',
            ...badgeButtonStyles({ isMinXL }),
          }}
        >
          <Box as="span" {...badgeButtonIconStyles}>
            <IconPlus />
          </Box>
          Open Baselane Banking Account
        </BaselaneBadge>
      </HStack>
    );
  };

  const getTopLeftContent = () => {
    return (
      <HStack w="100%">
        <Box>
          <IllustrationBankingNewaccount />
        </Box>

        {isMinXL && <Spacer />}
        <Box ml="24px !important">
          <BaselaneBadge
            {...{
              color: 'blue',
              type: 'badge',
              ...badgeTagStyles,
            }}
          >
            Baselane Banking
          </BaselaneBadge>
        </Box>
      </HStack>
    );
  };

  return (
    <>
      <BaselaneAdvertisingBanner
        {...{
          primaryContent: `Get banking built for landlords`,
          supportingContent: getSupportingContent(),
          topLeftContent: getTopLeftContent(),
          onClick: handleBannerClick,
          isBannerClickable: true,
          isNarrowOnly,
          styles: { ...adBannerStyles({ isMinXL }), ...styles },
          ...rest,
        }}
      />
      <BaselaneApplicationTypePopup
        handleActionButton={handleBaselaneApplicationPopupClick}
        isOpen={isBaselaneApplicationTypePopupOpen}
        onClose={onBaselaneApplicationTypePopupClose}
      />
    </>
  );
};

BaselaneBankingBanner.defaultProps = {
  styles: {},
};

export default BaselaneBankingBanner;
