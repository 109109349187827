import React from 'react';
import { Box, Heading, Stack } from '@chakra-ui/react';
import { BaselanePartnerCard } from '@core/components/Shared/components';

import { containerStyles } from './container.styles';

const MorePartners = ({ selectedTags, partnerCards }: any) => {
  const filteredPartnerCards =
    partnerCards &&
    partnerCards.filter(
      (item) =>
        !item.isFeatured &&
        selectedTags.filter(
          (tag) =>
            item.tags.map((itemTag) => itemTag.toLowerCase()).indexOf(tag.toLowerCase()) !== -1
        ).length > 0
    );

  return filteredPartnerCards.length > 0 ? (
    <Stack {...containerStyles}>
      <Heading size="headline-lg">More partners</Heading>
      <Stack direction="row" flexWrap="wrap" spacing={{ md: 3, base: 2 }}>
        {filteredPartnerCards &&
          filteredPartnerCards.map((item) => (
            <BaselanePartnerCard
              id
              key={item.title}
              title={item.title}
              name={item.name}
              tags={item.tags}
              details={item.details}
              ctaCopy={item.ctaCopy}
              ctaUrl={item.ctaUrl}
              ctaBehaviour={item.ctaBehaviour}
              logo={item.logo}
              width={{ md: 'calc(50% - 24px)', base: 'calc(50% - 16px)' }}
            />
          ))}
        <Box
          flex={{ md: '1 0 calc(50% - 24px)', base: '1 0 calc(50% - 16px)' }}
          h="1"
          display={filteredPartnerCards.length % 2 === 0 ? 'none' : 'block'}
        >
          {` `}
        </Box>
      </Stack>
    </Stack>
  ) : null;
};

export default MorePartners;
