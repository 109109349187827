export const viewModeTabsStyles = {
  p: '0',
};

export const viewModeTabPanelStyles = {
  p: '0',
  h: '100%',
  overflow: 'none',
};

export const drawerHeader = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'brand.darkBlue.800A',
  color: 'brand.neutral.white',
  minHeight: '80px',
  pl: '32px',
  fontWeight: 'medium',
};

export const titleWrapperStyles = (isDesktop) => ({
  py: '0',
  pl: '0',
  pr: isDesktop ? '1.5' : '0',
});

export const leaseRemainingStyles = {
  color: 'brand.neutral.700',
  fontSize: '2xs',
  fontWeight: 'medium',
  lineHeight: '20px',
  mt: '8px',
};

export const drawerFooterStyle = {
  borderTop: '1px solid #E5E9EF',
  p: '36px 16px 36px 0',
  mb: { base: 8, lg: 0 }, // adds space to prevent overlap of browser bottom bar in mobile browsers
};

export const leaseSectionHeader = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
};

export const leaseSmallText = {
  textStyle: 'xs',
  color: 'brand.neutral.700',
};

export const leaseSmallTextCapitalized = {
  textStyle: 'xs',
  color: 'brand.neutral.700',
  textTransform: 'capitalize',
};

export const leaseSmallTextGray = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
};

export const leaseSmallTextBold = {
  textStyle: 'headline-xs',
  color: 'brand.neutral.700',
};

export const leaseMediumText = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
};

export const leaseTermsLargeText = {
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
};

export const leasePaymentsContainer = {
  mx: '0',
  mt: '1.5',
  mb: '3',
  minHeight: '125px',
  w: '50%',
};

export const leasePaymentsEmptyContainer = {
  mx: '0',
  mt: '1.5',
  mb: '3',
  minHeight: '125px',
  w: '50%',
};

export const leaseEndArchiveHeader = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
};

export const leaseEndArchiveText = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
};

export const leaseTenantContainer = {
  mt: '1.5',
};

export const leaseTenantHeader = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
};

export const leaseTenantText = {
  textStyle: 'xs',
  color: 'brand.neutral.700',
};

export const leaseDetailsHeader = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
};

export const newLeaseTermsText = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
};

export const newLeaseTermsTextDark = {
  color: 'brand.neutral.700',
  textStyle: 'sm',
};

export const newLeaseTermsTextBold = {
  color: 'brand.neutral.700',
  textStyle: 'headline-sm',
};

export const leaseTermsContainer = (isInactive) => ({
  w: '100%',
  mx: '0',
  mt: '1.5',
  mb: isInactive ? '3' : '0',
  borderColor: isInactive ? 'transparent' : 'brand.darkBlue.200', // active leaseTerms borderColor matches BaselaneCardNew
  padding: isInactive ? '0' : '2',
});

export const processingFeeStyles = {
  headerContainerStyles: {
    align: 'center',
    mt: 4,
    gap: 2,
  },
  cardContainerStyles: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    w: '100%',
    gap: 0,
  },
  textContainerStyles: {
    w: '100%',
    gap: 0.5,
  },
  chevronContainerStyles: {
    direction: 'column',
    justifyContent: 'center',
    ml: 1,
  },
};

export const verticalDashedLine = {
  w: '1px',
  h: '32px',
  borderRight: 'dashed 1pt #ACB8C7',
  position: 'relative',
  top: '0px',
  left: '4.5px',
};

export const stepCircle = (filled) => ({
  w: '8px',
  h: '8px',
  zIndex: '2',
  border: 'solid 1pt',
  borderColor: 'brand.blue.700',
  background: filled ? 'brand.blue.700' : 'brand.neutral.white',
  borderRadius: '50%',
  m: '8px 5px 8px 1px',
});

export const viewModeEditableHeaderStyles = (isDesktop) => ({
  mb: isDesktop ? '32px' : '22px',
  alignItems: 'center',
  pt: isDesktop ? '0' : '1.5',
});

export const leaseTenantStatusContainer = ({ isMin899 }) => {
  return {
    mt: isMin899 ? '36px' : '24px',
    padding: '16px 24px',
    background: 'brand.blue.100',
    borderRadius: '6px',
  };
};

export const leaseTenantStatusHeader = {
  color: 'brand.neutral.700',
  textStyle: 'headline-sm',
};

export const leaseTenantStatusSubtitle = {
  color: 'brand.neutral.700',
  textStyle: 'xs',
  marginBottom: '12px',
};

export const leaseTenantStatusText = (checked) => ({
  color: 'brand.neutral.700',
  textStyle: 'headline-xs',
  marginBottom: '12px',
  p: '2px 0',
  opacity: checked ? '100%' : '50%',
});

export const leaseTenantStatusAction = ({ isHidden }) => {
  return {
    display: isHidden ? 'none' : 'block',
  };
};
