export const buttonStyles = (isMobile) => {
  return {
    maxW: 'calc( 100% - 40px )',
    position: isMobile ? 'fixed' : '',
    bottom: isMobile ? '20px' : '',
    mt: '0px !important',
    alignSelf: 'flex-start',
    w: isMobile ? '100%' : '',
  };
};

export const additionalLabelStyle = {
  fontSize: 'xs',
  color: 'brand.neutral.500',
  lineHeight: '20px',
  mt: '10px',
};
