import { chain } from 'lodash';

// used in onPredictionClick and handleAddressChange
export const fetchOptions = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getTempAddressObject = (addressComponents, showFullAddress) => {
  const tempAddressObject = { address: '', city: '', state: '', zipcode: '' };
  tempAddressObject.address = chain(addressComponents)
    .filter(
      (component) => component.types.includes('street_number') || component.types.includes('route')
    )
    .thru((streetAddress) => {
      const number = streetAddress.find((n) => n.types.includes('street_number'));
      const street = streetAddress.find((n) => n.types.includes('route'));
      if (!number) {
        return street.long_name;
      }
      return `${number.long_name} ${street.long_name}`;
    })
    .value();

  tempAddressObject.city = chain(addressComponents)
    .filter(
      (component) => component.types.includes('locality') || component.types.includes('sublocality')
    )
    .thru((types) => {
      const locality = types.find((component) => component.types.includes('locality'));

      if (locality) return locality.long_name;
      return types.length > 0 ? types[0].long_name : '';
    })
    .value();

  tempAddressObject.state = chain(addressComponents)
    .find((component) => component.types.includes('administrative_area_level_1'))
    .thru((c) => (c ? c.short_name : ''))
    .value();

  tempAddressObject.zipcode = chain(addressComponents)
    .find((component) => component.types.includes('postal_code'))
    .thru((c) => (c ? c.long_name : ''))
    .value();

  if (showFullAddress) {
    const { address, city, state, zipcode } = tempAddressObject;
    tempAddressObject.fullAddress = `${address}, ${city} ${state} ${zipcode}`;
  }

  return tempAddressObject;
};
