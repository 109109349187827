import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex, Grid, GridItem, Text, Stack } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import {
  IconArrowRight,
  IconCashFlow,
  IconChevronRight,
  IconPropertyAnalytics,
  IconProperties,
  IconBanksAndCards,
} from '@icons';
import * as ROUTES from '@routes';
import { BaselaneHeaderCard, RocketLawyerBanner } from '@shared/components';
import onDrawerClose from '@core/utils/onDrawerClose';
import PropertySuccessDrawer from '@pages/PropertiesPage/SuccessDrawer';
import useSuccessPropertyDrawer from '@pages/PropertiesPage/SuccessDrawer/hooks/useSuccessPropertyDrawer';
import CashFlowContext from '@contexts/CashFlowContext';
import { GET_PRESETS, GET_TRANSACTIONS_SUMMARY } from '@core/apollo/queries';
import { DASHBOARD } from '@routes';
import getBreakPoints from '@core/utils/getBreakPoints';

import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import CashFlowDashboardWidget from '../CashFlowPage/CashFlowDashboardWidget';
import Properties from '../Properties';
import PortfolioKPIs from '../PortfolioKPIs';
import BankBalances from '../BankBalances';
import Notification from '../Transactions/TransactionNotification';
import GuidedOnboardingTracker from './components/GuidedOnboardingTracker';
import OnboardingPopUp from './OnboardingPopUp';
import {
  dashboardPaddingStyles,
  oneUnitPaddingRight,
  cashFlowContainerHeader,
  cashFlowHeaderIcon,
  cashFlowTitle,
  cashFlowSubtitle,
  cashFlowDetailsLink,
  generalDetailsLinkStyles,
  generalHeaderIconStyles,
  generalTitleStyles,
  dashboardHeaderStyles,
  rocketLawyerBannerStyles,
} from './styles/responsive.style';

function DashboardPage() {
  const { isMax768: isMobileBreakpoint } = getBreakPoints();
  const navigate = useNavigate();
  const headerTitle = 'Account Summary';
  const cashFlowTabTitle = 'Cash Flow';
  const portfolioKPIsTabTitle = 'Property Metrics';
  const propertiesTabTitle = isMobile ? 'Properties & Rent' : 'Properties & Rent Collection';
  const bankBalancesTabTitle = 'Bank Balances';

  // cash flow context
  const { cashFlowRefetch, setIsConnectBALoading } = useContext(CashFlowContext);

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  // queries
  const { data: presets, loading: presetsLoading } = useQuery(GET_PRESETS);
  const {
    loading: transactionsSummaryLoading,
    data: transactionsSummaryData,
    refetch: transactionsRefetch,
    startPolling: startPollingTransactionsSummary,
    stopPolling: stopPollingTransactionsSummary,
  } = useQuery(GET_TRANSACTIONS_SUMMARY, { variables: { input: {} } });

  // prepare data: data
  const { preset: presetOptions } = presets ?? {};

  // prepare data: trx summary
  const { transactionSummary = {} } = transactionsSummaryData || {};
  const { total = {}, totalUncategorized = {} } = transactionSummary;
  const {
    count: totalTransactionCount,
    uncategorizedHiddenTxCount: totalUncategorizedHiddenTxCount,
  } = total || {};
  const { count: uncategorizedCountData } = totalUncategorized || {};
  const totalUncategorizedUnhiddenTxCount =
    uncategorizedCountData - totalUncategorizedHiddenTxCount;

  // variables
  const [hasBA, setHasBA] = useState(false);
  const noBankData = totalTransactionCount === 0;

  // functions
  const handleAccountConnectDrawerClose = () => {
    onDrawerClose(navigate, DASHBOARD);
    setIsConnectBALoading(true);
    cashFlowRefetch();

    startPollingTransactionsSummary(1500);
    setTimeout(() => {
      stopPollingTransactionsSummary();
    }, 4500);
  };

  useEffect(() => {
    cashFlowRefetch();
    transactionsRefetch();
    if (isMobileBreakpoint) zendeskAPI('messenger', 'hide');
  }, []);

  // keeping html clean, use the imported responsive styles
  const boxStyles = { mb: '24px', boxSizing: 'border-box' };
  const invertStyle = !noBankData && isMobile;
  const cashFlowContainerHeaderStyles = cashFlowContainerHeader(isMobile, invertStyle);
  const cashFlowHeaderIconStyles = { ...cashFlowHeaderIcon(noBankData), ...oneUnitPaddingRight };
  const cashFlowTitleStyles = { ...cashFlowTitle(isMobile, invertStyle), ...oneUnitPaddingRight };
  const cashFlowSubtitleStyles = { ...cashFlowSubtitle(noBankData) };
  const cashFlowDetailsLinkStyles = cashFlowDetailsLink(isMobile, noBankData);

  // compose the dashboard titles based on small screen or large screen using breakpoints
  // and based on whether or not there is data
  const generalHeaderTitle = (icon, title) => (
    <Flex direction="row" alignItems="center">
      <Box {...generalHeaderIconStyles} display={!isMobile ? 'none' : 'block'}>
        {icon}
      </Box>
      <Text {...generalTitleStyles(isMobile)}>{title}</Text>
    </Flex>
  );
  const generalHeaderLink = (route, isDisabled) => (
    <Flex
      direction="row"
      display={!isMobile ? 'none' : 'block'}
      {...generalDetailsLinkStyles(isMobile)}
    >
      <RouterLink
        to={{ pathname: route, state: { showUncategorizedFromLink: true } }}
        style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
      >
        {isDisabled ? (
          <>
            <Box display="inline-block" pr="12px" color="brand.neutral.600">
              Details
            </Box>
            <Box display="inline-block" position="relative" top="3px">
              <IconChevronRight w={8} h={15} color="#3A4B5B" />
            </Box>
          </>
        ) : (
          <>
            <Box display="inline-block" pr="12px">
              Details
            </Box>
            <Box display="inline-block" position="relative" top="3px">
              <IconChevronRight w={8} h={15} />
            </Box>
          </>
        )}
      </RouterLink>
    </Flex>
  );
  const notification = <Notification />;
  const cashFlowHeaderTitle = (
    <Flex direction="row">
      <Box {...cashFlowHeaderIconStyles} display={!isMobile ? 'none' : 'block'}>
        <IconCashFlow w={20} h={20} />
      </Box>
      <Text {...cashFlowTitleStyles}>{cashFlowTabTitle}</Text>
      <Box display={!isMobile ? 'none' : 'flex'} alignItems="center">
        <Text {...cashFlowSubtitleStyles}>| Last 6 Months</Text>
      </Box>
    </Flex>
  );
  const cashFlowHeaderLink = (
    <Stack direction="row" alignItems="center" gap="2">
      {!noBankData && (
        <Box display={!isMobile ? 'block' : 'none'}>
          <RouterLink
            to={{ pathname: ROUTES.TRANSACTIONS, state: { showUncategorizedFromLink: true } }}
            style={{ pointerEvents: 'none' }}
          >
            {notification}
          </RouterLink>
        </Box>
      )}
      <Flex
        direction="row"
        {...cashFlowDetailsLinkStyles}
        style={{ marginInlineStart: '0' }}
        minW={!isMobile && '108px'}
      >
        {/* TODO: Update this block when analytics_reporting is stable and we're ready to delete
        original cash flow page */}
        <RouterLink
          to={{
            pathname: ROUTES.ANALYTICS_REPORTING,
            state: { showUncategorizedFromLink: true },
          }}
        >
          <Box display="inline-block" pr="12px">
            {isMobile ? 'Details' : 'View Details'}
          </Box>
          <Box display="inline-block" position="relative" top="3px">
            {isMobile ? (
              <IconChevronRight w={8} h={15} color="#ACB8C7" />
            ) : (
              <IconArrowRight w={16} h={16} color="#77BEFE" />
            )}
          </Box>
        </RouterLink>
      </Flex>
    </Stack>
  );
  const KPIsHeaderTitle = generalHeaderTitle(
    <IconPropertyAnalytics w={20} h={20} />,
    portfolioKPIsTabTitle
  );
  const KPIsHeaderLink = generalHeaderLink(ROUTES.KPIS);
  const propertiesHeaderTitle = generalHeaderTitle(
    <IconProperties w={20} h={20} />,
    propertiesTabTitle
  );
  const propertiesHeaderLink = generalHeaderLink(ROUTES.LEASES);
  const bankBalancesHeaderTitle = generalHeaderTitle(
    <IconBanksAndCards w={20} h={14} />,
    bankBalancesTabTitle
  );

  const bankBalancesHeaderLink = generalHeaderLink(ROUTES.NATIVE_BANK);

  const storageKeyForPopup = 'localStorageOnboardingData';
  const showPopup = JSON.parse(localStorage.getItem(storageKeyForPopup))?.use_landlord_banking;
  const handleHidePopUp = () => {
    localStorage.removeItem('localStorageOnboardingData');
  };

  const { newProperty, successDrawerRef } = useSuccessPropertyDrawer();

  return (
    <>
      <GuidedOnboardingTracker {...{ cashFlowRefetch, setHasBA }} />
      {showPopup && <OnboardingPopUp handleHidePopUp={handleHidePopUp} />}

      <Box {...dashboardPaddingStyles(isMobile)}>
        <Text display={!isMobile ? 'none' : 'block'} {...dashboardHeaderStyles}>
          {headerTitle}
        </Text>
        <PropertySuccessDrawer successDrawerRef={successDrawerRef} property={newProperty} />
        <Grid
          templateRows={
            !isMobile ? { lg: 'repeat(2, auto)', base: 'repeat(4, auto)' } : 'repeat(4, auto)'
          }
          templateColumns={
            !isMobile ? { lg: 'repeat(10, 1fr)', base: 'repeat(1, auto)' } : 'repeat(1, auto)'
          }
        >
          <GridItem
            {...boxStyles}
            mr={{ lg: '24px', base: '0px' }}
            order={!isMobile ? '1' : '1'}
            colSpan={!isMobile ? { lg: '7', base: '10' } : '1'}
            position="relative"
          >
            <BaselaneHeaderCard
              {...cashFlowContainerHeaderStyles}
              headerLeft={cashFlowHeaderTitle}
              headerRight={cashFlowHeaderLink}
            >
              <CashFlowDashboardWidget
                {...{
                  noBankData,
                  hasBA,
                  totalTransactionCount,
                  transactionsSummaryLoading,
                  startPollingTransactionsSummary,
                  stopPollingTransactionsSummary,
                  totalUncategorizedUnhiddenTxCount,
                  handleAccountConnectDrawerClose,
                  presetOptions,
                  presetsLoading,
                }}
              />
            </BaselaneHeaderCard>
          </GridItem>
          <GridItem
            {...boxStyles}
            order={!isMobile ? '2' : '4'}
            colSpan={!isMobile ? { lg: '3', base: '10' } : '1'}
          >
            <Flex flexDirection="column" h="100%">
              {/* Rocket Lawyer Banner */}
              {!isMobile && <RocketLawyerBanner badgeSize="md" {...rocketLawyerBannerStyles} />}
              <BaselaneHeaderCard headerLeft={KPIsHeaderTitle} headerRight={KPIsHeaderLink}>
                <PortfolioKPIs />
              </BaselaneHeaderCard>
            </Flex>
          </GridItem>
          <GridItem
            {...boxStyles}
            mr={{ lg: '24px', base: '0px' }}
            order={!isMobile ? '3' : '2'}
            colSpan={!isMobile ? { lg: '6', base: '10' } : '1'}
          >
            <BaselaneHeaderCard
              headerLeft={propertiesHeaderTitle}
              headerRight={propertiesHeaderLink}
            >
              <Properties />
            </BaselaneHeaderCard>
          </GridItem>
          <GridItem
            {...boxStyles}
            order={!isMobile ? '4' : '3'}
            colSpan={!isMobile ? { lg: '4', base: '10' } : '1'}
          >
            {/* NOTE: Use below code and commented out code to test adding account and refreshing the wdiget */}
            {/* <BaselaneHeaderCard headerLeft={bankBalancesTitle} headerRight={bankBalancesLink}> */}
            <BaselaneHeaderCard
              headerLeft={bankBalancesHeaderTitle}
              headerRight={bankBalancesHeaderLink}
            >
              <BankBalances />
            </BaselaneHeaderCard>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
}

export default DashboardPage;
