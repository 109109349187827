const buttonPadding = '16px 0 16px 16px';
const splitTxInputContainerBaseStyles = {
  w: '100%',
  p: '16px 0 16px 24px',
  spacing: 2.5,
  gap: '10px',
};

export const splitTxInputContainerStyles = (txLength, i) =>
  txLength > i + 1
    ? {
        borderBottomWidth: 1,
        borderBottomColor: 'brand.darkBlue.150',
        ...splitTxInputContainerBaseStyles,
      }
    : splitTxInputContainerBaseStyles;

export const buttonStyles = (txLength, i) =>
  txLength > 2 && txLength > i + 1
    ? {
        borderBottomWidth: 1,
        borderBottomColor: 'brand.darkBlue.150',
        p: buttonPadding,
      }
    : { p: buttonPadding };
