export const accordionButtonStyles = {
  p: 0,
  justifyContent: 'space-between',
  _hover: { bgColor: 'transparent' },
  outline: 'none !important',
  _focus: {
    boxShadow: 'none',
  },
};

export const accordionToggleContainerStyles = (search, hasSelectedItems) => {
  return {
    ml: 0.5,
    flex: hasSelectedItems ? '1 0 auto' : '',
    overflow: 'hidden',
    width: 'auto',
    minWidth: '40px',
    height: '100%',
    align: 'center',
    _hover: {
      bgColor: hasSelectedItems ? 'brand.blue.100' : 'brand.blue.50',
      color: 'brand.blue.800A',
    },
    pointerEvents: search ? 'none' : 'auto',
    cursor: search ? 'default' : 'pointer',
    color: hasSelectedItems ? 'brand.blue.800A' : 'initial',
    sx: {
      ':hover': {
        bgColor: hasSelectedItems ? 'brand.blue.100' : 'brand.blue.50',
        color: 'brand.blue.800A',
        borderRadius: 'sm',
        '#child-counter-visual': {
          bgColor: hasSelectedItems ? 'brand.blue.100' : 'brand.blue.50',
          color: 'brand.blue.800A',
          borderRadius: 'sm',
        },
      },
    },
  };
};

export const accordionToggleCounterContainerStyles = {
  borderRadius: 'sm',
  px: 1.5,
  py: 1.5,
  height: '100%',
  width: '100%',
  spacing: '0',
  align: 'center',
  justifyContent: 'space-between',
  minWidth: '40px',
};

export const accordionToggleTextStyles = {
  textStyle: 'xs-heavy',
  mr: 1,
};

export const accordionToggleIconContainerStyles = {
  w: '16px',
  h: '16px',
  align: 'center',
  justifyContent: 'center',
  color: 'inherit',
};

export const accordionPanelStyles = {
  pl: 2,
  py: 0.5,
};

// multi select checkboxes styles
export const accordionSelectAllItemContainerStyles = {
  align: 'center',
  borderLeft: '1px solid #E5E9EF',
  pl: '5px',
};

export const accordionSelectAllTextStyles = {
  textStyle: 'headline-xs',
};

export const accordionChildCheckboxContainerStyles = {
  align: 'center',
  borderLeft: '1px solid #E5E9EF',
  pl: '5px',
};

// single select item styles
export const accordionItemContainerStyles = {
  py: 1,
  px: 1,
  _hover: { bgColor: 'brand.blue.50' },
  _active: { bgColor: 'brand.blue.100' },
  width: '100%',
  textAlign: 'left',
  borderRadius: 'sm',
  height: '100%',
  minHeight: '40px',
  overflow: 'hidden',
};

export const accordionItemChildContainerStyles = {
  align: 'center',
  borderLeft: '1px solid',
  borderLeftColor: 'brand.darkBlue.100',
  pl: '5px',
  minHeight: '40px',
  width: '100%',
};

export const accordionItemChildStyles = {
  py: 1,
  px: 1,
  _hover: { bgColor: 'brand.blue.50' },
  width: '100%',
  height: '100%',
  borderRadius: 'sm',
  textAlign: 'left',
  minHeight: '40px',
  cursor: 'pointer',
};

export const itemContainerStyles = {
  align: 'center',
  width: '100%',
  minHeight: '40px',
  cursor: 'pointer',
};
