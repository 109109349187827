export const noMarginStyles = {
  m: '0 !important',
};

const contentWidth = '100%';

export const headingStyles = (isMobile) => ({
  fontSize: isMobile ? '2xl' : 'lg',
  lineHeight: isMobile ? '32px' : '26px',
  mb: '16px',
  fontWeight: 'semibold',
  color: 'brand.neutral.700',
  maxW: '100%',
  w: contentWidth,
});

export const sectionHeadingStyles = {
  size: 'xs',
  color: 'brand.neutral.500',
  maxW: '100%',
  w: contentWidth,
  textTransform: 'uppercase',
  marginTop: '24px !important',
  marginBottom: '24px !important',
};

export const tipHeadingStyles = {
  size: 'xs',
  color: 'brand.neutral.600',
  maxW: '100%',
  w: contentWidth,
};

export const tipTextStyles = {
  size: 'xs',
  color: 'brand.neutral.500',
  maxW: '100%',
  w: contentWidth,
  mt: '0 !important',
};

export const tipContainerStyles = {
  p: '8px',
  backgroundColor: 'brand.blue.50',
  borderRadius: '4px',
  m: '0 !important',
};

export const listStyles = {
  p: '0',
  m: '0 0 0 20px !important',
};

export const orderedListStyles = {
  ...listStyles,
  listStyleType: 'decimal',
  marginLeft: '0 !important',
};

const sharedListItemStyles = {
  fontSize: 'sm',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  maxW: '100%',
  width: contentWidth,
  m: '0 !important',
  lineHeight: '20px !important',
};

export const listItemStyles = {
  containerStyle: {
    ...sharedListItemStyles,
    _notLast: {
      mb: '8px !important',
    },
  },
  orderedContainerStyle: {
    ...sharedListItemStyles,
    _notLast: {
      mb: '6px !important',
    },
    display: 'list-item',
    ml: '24px !important',
  },
  labelStyle: {
    position: 'relative',
    color: 'brand.blue.700',
    mr: '8px',
  },
  valueStyle: {
    fontSize: 'sm',
    color: 'brand.neutral.700',
    fontWeight: 'normal',
    lineHeight: '20px !important',
  },
};

export const descriptionStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
  mb: '24px !important',
  mt: '0px !important',
};

export const dividerStyles = {
  mt: '24px !important',
  mb: '24px !important',
};
