import React from 'react';
import { BaselaneButtonIcon } from '@shared/components';
import { Icon16Check } from '@icons/16px';

type SaveChangesBtnProps = {
  onClick: Function,
  isDisabled?: Boolean,
  isLoading?: Boolean,
  variant: string,
  palette: string,
  size: string,
};

const SaveChangesBtn = ({
  onClick,
  isDisabled,
  isLoading,
  variant,
  palette,
  size,
}: SaveChangesBtnProps) => {
  return (
    <BaselaneButtonIcon
      isDisabled={isDisabled}
      isLoading={isLoading}
      icon={<Icon16Check />}
      variant={variant}
      palette={palette}
      size={size}
      onClick={onClick}
    />
  );
};

SaveChangesBtn.defaultProps = {
  isDisabled: false,
  isLoading: false,
};

export default SaveChangesBtn;
