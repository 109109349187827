// @flow
import React, { useContext, useMemo, memo } from 'react';
import * as _ from 'lodash';
import CashFlowContext from '@contexts/CashFlowContext';
import CashFlowByType from './CashFlowByType';
import {
  getSubOptionsCategories,
  getMappedCategories,
  getCategories,
} from './helpers/cashflow.helpers';

type CashFlowByTypeWrapperProps = {
  handleSubFiltersUpdate: Function,
  selectedMonth: string,
  hasNoData: boolean,
  onAnimationStart?: Function,
  onAnimationComplete?: Function,
};

function CashFlowByTypeWrapper({
  handleSubFiltersUpdate,
  selectedMonth,
  hasNoData,
  onAnimationStart,
  onAnimationComplete,
}: CashFlowByTypeWrapperProps): any {
  const {
    filters,
    cashFlowData,
    cashFlowDummyData,
    categoryMap,
    categoryWithSubOptions,
  } = useContext(CashFlowContext);

  const data = hasNoData ? cashFlowDummyData : cashFlowData;

  const { byCategory: byCategoryData } = data.cashFlow;
  const byCategoryMonth = selectedMonth ? data.cashFlow.byCategoryMonth : [];

  const month = _.find(byCategoryMonth, (item) => _.includes(item.date, selectedMonth));
  const getCategoriesByMonth = () => (month ? month.categories : []);
  const byCategory = selectedMonth === null ? byCategoryData : getCategoriesByMonth();

  const subOptionsCategories = useMemo(() => getSubOptionsCategories(categoryWithSubOptions), []);
  const mappedCategories = useMemo(() => getMappedCategories(categoryMap), []);
  const categories = getCategories(byCategory);
  return (
    <CashFlowByType
      {...{
        byCategory,
        handleSubFiltersUpdate,
        filters,
        subOptionsCategories,
        categoryWithSubOptions,
        mappedCategories,
        categories,
        onAnimationStart,
        onAnimationComplete,
      }}
    />
  );
}

CashFlowByTypeWrapper.defaultProps = {
  onAnimationStart: () => {},
  onAnimationComplete: () => {},
};

const areEqual = (prevProp, nextProp) => {
  return _.isEqual(
    {
      data: prevProp.data,
      filters: prevProp.filters,
      selectedMonth: prevProp.selectedMonth,
      hasNoData: prevProp.hasNoData,
    },
    {
      data: nextProp.data,
      filters: nextProp.filters,
      selectedMonth: nextProp.selectedMonth,
      hasNoData: nextProp.hasNoData,
    }
  );
};

const CashFlowByTypeWrapperMemo = memo(CashFlowByTypeWrapper, areEqual);

export default CashFlowByTypeWrapperMemo;
