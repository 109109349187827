import { formInputStyles } from '@shared/styles/input.style';

export const selectPropertyStyles = {
  textcontainer: {
    gap: '8px',
    mb: '40px !important',
  },
  title: {
    textStyle: 'headline-lg',
    color: 'brand.neutral.700',
  },
  description: {
    textStyle: 'sm',
    color: 'brand.neutral.600',
    m: '0 !important',
  },
};

export const searchStyles = {
  ...formInputStyles,
  h: '50px',
  lineHeight: '13px',
  p: '16px',
  mb: '16px',
  borderColor: 'brand.darkBlue.250',

  _placeholder: {
    color: 'brand.neutral.600',
  },

  // do not show invalid styles for search input
  _invalid: {
    borderColor: 'brand.darkBlue.150',
    _focus: {
      borderColor: 'brand.darkBlue.250',
      color: 'brand.neutral.700',
      boxShadow: 'none',
    },
  },

  _disabled: {
    color: 'brand.neutral.600',
    background: 'brand.darkBlue.100',
  },
};
