import React, { ReactElement } from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { getDynamicIcon } from './helpers/baselaneFlowComplete.helpers';
import { baselaneFlowCompleteStyles } from './styles/baselaneFlowComplete.styles';

type BaselaneFlowCompleteProps = {
  type: 'success' | 'neutral' | 'error',
  title: String,
  title2: String,
  description1: String,
  description2: String,
  children: ReactElement,
};

const BaselaneFlowComplete = ({
  type,
  title,
  title2,
  description1,
  description2 = null,
  children = null,
}: BaselaneFlowCompleteProps) => {
  const { containerStyles, titleStyles, descriptionStyles } = baselaneFlowCompleteStyles;

  return (
    <Stack {...containerStyles}>
      {getDynamicIcon({ type })}
      <Stack gap={1.5}>
        <Text {...titleStyles}>
          {title}
          {title2 && (
            <>
              <br />
              {title2}
            </>
          )}
        </Text>
        <Stack alignItems="center" gap={0.75}>
          <Text {...descriptionStyles}>{description1}</Text>
          {description2 && <Text {...descriptionStyles}>{description2}</Text>}
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};

export default BaselaneFlowComplete;
