import React from 'react';
import { IllustrationBanking } from '@illustrations';

export const getTrackerData = (hasPendingAccount) => {
  const trackerData = Object.freeze({
    title: 'Landlord Banking',
    icon: <IllustrationBanking />,
    elements: {
      OPEN_BASELANE_BANKING: {
        title: 'Landlord Banking',
        not_started: {
          title: 'Open a Baselane Banking account',
          description:
            'Multiple accounts per property, no account fees, no minimum balances, rewards & so much more.',
          linkText: 'Start Application',
          hasNotStarted: true,
        },
        in_progress: {
          title: 'Open a Baselane Banking account',
          description: hasPendingAccount
            ? 'We are currently reviewing your application. It may take up to 2 business hours to review.'
            : 'Multiple accounts per property, no account fees, no minimum balances, rewards & so much more.',
          linkText: hasPendingAccount ? null : 'Continue Application',
        },
        warning: {
          title: 'Open a Baselane Banking account',
          description:
            'Almost there! As a final step, we require documentation to approve your application.',
          linkText: 'Upload Documentation',
          state: 'warning',
        },
        alert: {
          title: 'Open a Baselane Banking account',
          description:
            'Based on the information provided, you are unable to open a Baselane Banking account at this time.',
          linkText: 'Contact Support',
          state: 'alert',
        },
        completed: {
          title: 'Open a Baselane Banking account',
        },
      },
      FUND_YOUR_ACCOUNT: {
        title: 'Fund your account',
        not_started: {
          title: 'Fund your account',
          description:
            'Earn up to 60x the national average interest rate on all deposits, currently 4.19% APY.',
          linkText: 'Add Funds',
          hasNotStarted: true,
        },
        in_progress: {
          title: 'Fund your account',
          description:
            'Your funds are on the way. It may take up to 2-3 business days to complete the transfer.',
        },
        alert: {
          title: 'Fund your account',
          description: 'We encountered a problem while transferring your funds. Let’s try again.',
          linkText: 'Add Funds',
          state: 'alert',
        },
        completed: {
          title: 'Fund your account',
        },
      },
      ACTIVATE_DEBIT_CARD: {
        title: 'Activate your debit card',
        not_started: {
          title: 'Activate your debit card',
          description:
            'Activate your debit card as soon as it arrives to start earning up to 5% cash back.',
          linkText: 'Activate Card',
          hasNotStarted: true,
        },
        completed: {
          title: 'Activate your debit card',
        },
      },
    },
    badge: null,
  });

  return trackerData;
};

export const getLinkStatus = (data) => {
  const { OPEN_BASELANE_BANKING, FUND_YOUR_ACCOUNT, ACTIVATE_DEBIT_CARD } = data;
  const {
    not_started: baNotStartedLink,
    in_progress: baInProgressLink,
    warning: baWarningLink,
    alert: baAlertLink,
    completed: baCompletedLink,
  } = OPEN_BASELANE_BANKING;
  const {
    not_started: fundAccNotStartedLink,
    in_progress: fundAccInProgressLink,
    alert: fundAccAlertLink,
    completed: fundAccCompletedLink,
  } = FUND_YOUR_ACCOUNT;
  const {
    not_started: activateDebitCardNotStartedLink,
    completed: activateDebitCardCompletedLink,
  } = ACTIVATE_DEBIT_CARD;

  const linkStatus = Object.freeze({
    OPEN_BASELANE_BANKING: {
      not_started: baNotStartedLink,
      in_progress: baInProgressLink,
      warning: baWarningLink,
      alert: baAlertLink,
      completed: baCompletedLink,
    },
    FUND_YOUR_ACCOUNT: {
      not_started: fundAccNotStartedLink,
      in_progress: fundAccInProgressLink,
      alert: fundAccAlertLink,
      completed: fundAccCompletedLink,
    },
    ACTIVATE_DEBIT_CARD: {
      not_started: activateDebitCardNotStartedLink,
      completed: activateDebitCardCompletedLink,
    },
  });

  return linkStatus;
};
