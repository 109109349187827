import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { IconBankThin } from '@icons';
import { textStyles1, textStyles2 } from '@core/components/AddBankAccount/styles/buttonCard.styles';
import BaselaneButtonCard from '../Shared/components/BaselaneButtonCard';

type AddBankAccountButtonCardProps = {
  handleReconnectBankClick?: Function,
  handleClick?: Function,
};

const AddBankAccountButtonCard = ({
  handleReconnectBankClick,
  handleClick,
}: AddBankAccountButtonCardProps) => {
  const currLocation = useLocation();
  const { pathname } = currLocation;
  const unifiedRC = pathname.includes('unified_rent');

  const onReconnectBankClick = (e) => {
    if (unifiedRC) {
      sendSegmentEvent('onboarding_rc_click_connect_account');
    }

    // onclick function if we want to use this component for general purposes
    if (handleClick) {
      handleClick();
    }

    // default function that gets triggered with this component
    if (handleReconnectBankClick) {
      handleReconnectBankClick(e);
    }
  };

  return (
    <BaselaneButtonCard onClick={onReconnectBankClick} type="clean">
      <Box flexBasis="27px">
        <IconBankThin />
      </Box>
      <Box>
        <Text {...textStyles1}>Connect External Account</Text>
        <Text className="hover-white" {...textStyles2}>
          Securely connect any external bank account with Plaid™
        </Text>
      </Box>
    </BaselaneButtonCard>
  );
};

AddBankAccountButtonCard.defaultProps = {
  handleReconnectBankClick: null,
  handleClick: null,
};

export default AddBankAccountButtonCard;
