export const baselaneFlowCompleteStyles = {
  containerStyles: {
    gap: 3,
    h: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleStyles: {
    textStyle: 'headline-xl',
    color: 'brand.neutral.900',
    textAlign: 'center',
  },
  descriptionStyles: {
    textStyle: 'sm',
    color: 'brand.neutral.700',
    textAlign: 'center',
  },
};
