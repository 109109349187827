/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery } from '@apollo/client';
import { Text } from '@chakra-ui/react';
import { GET_PAYEE } from '@core/apollo/queries';

const Payee = ({ recipientId }) => {
  const { error, loading, data } = useQuery(GET_PAYEE, {
    variables: { id: recipientId },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error ${error.message}</div>;
  }

  return <Text textStyle="sm-heavy">{data.payee.name}</Text>;
};

export default Payee;
