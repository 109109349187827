import React, { useContext } from 'react';
import BaselaneResponsiveCell from './BaslaneResponsiveCell/BaselaneResponsiveCell';
import BaselaneResponsiveTableContext from './BaselaneResponsiveTableContext';

const spacerSizes = {
  sm: '16px',
  md: '32px',
  lg: '64px',
};

type BaselaneResponsiveSpacerProps = {
  configIndex: Number,
};

/**
 * Renders an empty cell of a particular size, set using the size config option.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveTableSpacer = ({ configIndex, ...rest }: BaselaneResponsiveSpacerProps) => {
  const { columns } = useContext(BaselaneResponsiveTableContext);
  const size = columns?.[configIndex]?.size || 'sm';
  const spacerStyle = {
    w: `${spacerSizes[size]} !important`,
    maxW: `${spacerSizes[size]} !important`,
    minW: `${spacerSizes[size]} !important`,
    p: '0 !important',
    m: '0 !important',
  };

  return <BaselaneResponsiveCell {...spacerStyle} {...rest} />;
};

export default BaselaneResponsiveTableSpacer;
