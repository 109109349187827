export const getPropertyAddressArr = (propertyAddress) => {
  const address =
    propertyAddress === null
      ? ''
      : `${propertyAddress.address}, ${!propertyAddress.unit ? '' : `${propertyAddress.unit}, `} ${
          propertyAddress.city
        }, ${propertyAddress.state} ${propertyAddress.zipCode}`;

  const propertyAddressArray = address.split(', ');

  /* Example propertyAddressArrayObjects
    With unit (address line 2):
    Property Address Array: 0: 612 Oct17 Road 1: 3D 2:  New York 3: NY 12081
    Without unit (address line 2):
    Property Address Array: 0: 12 Oct17 Road 1:  Test 2: AZ 14212
     */
  const propertyAddress1 =
    propertyAddressArray.length === 4
      ? `${propertyAddressArray[0]}, ${propertyAddressArray[1]}`
      : propertyAddressArray[0];

  const propertyAddress2 =
    propertyAddressArray.length === 4
      ? `${propertyAddressArray[2]}, ${propertyAddressArray[3]}`
      : `${propertyAddressArray[1]}, ${propertyAddressArray[2]}`;

  return [propertyAddress1, propertyAddress2];
};
