/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Spacer, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import UpcomingTransfer from './UpcomingTransfer';

const UpcomingTransfersList = ({ recurringPattern }) => {
  const untilUserCancels = !recurringPattern?.lastPaymentDate;

  const transfers = recurringPattern?.transfers ?? [];

  const showEllipsis = untilUserCancels || (!untilUserCancels && transfers.length > 12);

  let upcomingTransfers = transfers.slice(0, 12);

  if (untilUserCancels) {
    upcomingTransfers = transfers.slice(0, 11);
  }

  if (transfers.length > 12) {
    upcomingTransfers = transfers.slice(0, 10);
  }

  return (
    <>
      {upcomingTransfers.map((upcomingTransfer) => (
        <UpcomingTransfer key={upcomingTransfer.transferDate} upcomingTransfer={upcomingTransfer} />
      ))}
      {/* Ellipsis. */}
      <Box>
        {showEllipsis ? (
          <Text textStyle="sm" textAlign="left">
            ...
          </Text>
        ) : null}
      </Box>
      {/* Last transfer. */}
      {!untilUserCancels && transfers.length > 12 ? (
        <UpcomingTransfer upcomingTransfer={transfers[transfers.length - 1]} />
      ) : null}
      {/* Ends. */}
      <Box display="flex">
        <Text textStyle="xs-heavy">Ends</Text>
        <Spacer />
        {recurringPattern?.lastPaymentDate ? (
          <Text textStyle="sm">
            {DateTime.fromISO(recurringPattern.lastPaymentDate).toFormat('MMM d, yyyy')}
          </Text>
        ) : (
          <Text textStyle="sm">Never</Text>
        )}
      </Box>
    </>
  );
};

export default UpcomingTransfersList;
