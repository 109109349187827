import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const parts = ['addon', 'field', 'element', 'group'];
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts);

export const sharedSizeStyles = {
  lg: { px: 2 },
  md: {
    px: 1.5,
  },
  sm: {
    px: 1.5,
  },
  xs: {
    px: 1.5,
  },
};

const size = {
  lg: defineStyle((props) => {
    const { textStyles, radii } = props.theme;

    return {
      field: {
        px: sharedSizeStyles.lg.px,
        py: 1.5,
        borderRadius: radii.sm,
        ...textStyles.sm,
        height: '48px',
        boxSizing: 'border-box',
      },
    };
  }),
  md: defineStyle((props) => {
    const { textStyles, radii } = props.theme;

    return {
      field: {
        px: sharedSizeStyles.md.px,
        py: 1.5,
        borderRadius: radii.sm,
        ...textStyles.sm,
        height: '40px',
        boxSizing: 'border-box',
      },
    };
  }),
  sm: defineStyle((props) => {
    const { textStyles, radii } = props.theme;

    return {
      field: {
        px: sharedSizeStyles.sm.px,
        py: 1.5,
        borderRadius: radii.sm,
        ...textStyles.sm,
        height: 4,
        boxSizing: 'border-box',
      },
      element: {
        height: '24px',
        width: '32px', // Equals the `padding-insile-start` of the input when it has an element. After that, it centers itself.
      },
    };
  }),
  xs: defineStyle({
    field: {
      px: sharedSizeStyles.xs.px,
      py: 1.5,
    },
  }),
};

/**
 * This overrides Chakra's default Input styles.
 *
 * Note: _field_ sets the styles for the actual input field
 * while _addon_ sets the styles for left/right addon elements.
 *
 * see: node_modules/@chakra-ui/theme/src/components/input.ts for implementation details
 */
export const Input = defineMultiStyleConfig({
  baseStyle: {
    field: {
      fontWeight: '100',
      _autofill: {
        transition: 'background-color 0s 600000s, color 0s 600000s',
        bg: 'white',
        boxShadow: 'none',
      },
    },
  },
  sizes: {
    lg: definePartsStyle(size.lg),
    md: definePartsStyle(size.md),
    sm: definePartsStyle(size.sm),
    xs: definePartsStyle(size.xs),
  },
  defaultProps: {
    focusBorderColor: 'brand.blue.700',
    errorBorderColor: 'red.500',
  },
  variants: {
    outline: defineStyle((props) => {
      const { colors } = props.theme;

      return {
        field: {
          color: 'brand.neutral.900',
          border: '1px solid',
          background: 'white',
          borderColor: 'brand.darkBlue.200',
          _invalid: {
            boxShadow: 'none',
            _hover: {
              borderColor: 'red.400',
              boxShadow: 'none',
            },
            _active: {
              boxShadow: `0px 0px 0px 2px  ${colors.red[200]} inset`,
              borderColor: 'red.400',
            },
            _focusVisible: {
              boxShadow: `0px 0px 0px 2px  ${colors.red[200]} inset`,
              borderColor: 'red.400',
            },
          },
          _placeholder: { color: 'brand.neutral.500' },
          _hover: {
            borderColor: 'brand.darkBlue.300',
          },
          _active: {
            borderColor: 'brand.blue.700',
          },
          _focus: {
            borderColor: 'brand.blue.700',
            boxShadow: `0px 0px 0px 2px ${colors.brand.blue[100]} inset`,
          },
          _focusVisible: {
            boxShadow: `0 0 0 2px ${colors.brand.blue[100]} inset`,
          },
          _disabled: {
            color: 'brand.neutral.400',
            background: 'brand.neutral.150',
            borderColor: 'brand.neutral.250',
            cursor: 'not-allowed',
            opacity: '1',
            _hover: {
              borderColor: 'brand.neutral.250',
            },
            _active: {
              borderColor: 'brand.neutral.250',
            },
            _focus: {
              boxShadow: 'none',
            },
            _focusVisible: {
              boxShadow: 'none',
            },
          },
        },
      };
    }),
  },
});
