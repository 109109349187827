import React from 'react';
import { DisplayInputDefault } from '../DisplayInputVariations';

const customContentRenderHelperHelper = ({
  state,
  methods,
  CustomDisplayInput,
  selectedOptions,
  placeholder,
  title,
  showValueByFields,
  handleExternalClear,
  isDisabled,
  classNames,
}) => {
  const handleContentRendererClick = () => {
    if (state?.dropdown) {
      methods?.dropDown('close');
    }
  };

  return CustomDisplayInput ? (
    <CustomDisplayInput
      selectedOptions={selectedOptions}
      handleContentRendererClick={handleContentRendererClick}
      placeholder={placeholder}
      title={title}
      showValueByFields={showValueByFields}
      handleClearClick={() => {
        handleExternalClear({
          dropdown: methods?.dropDown,
          setSearchParam: methods?.setSearch,
        });
      }}
      isDisabled={isDisabled}
      classNames={classNames}
    />
  ) : (
    <DisplayInputDefault
      handleContentRendererClick={handleContentRendererClick}
      selectedOptions={selectedOptions}
      showValueByFields={showValueByFields}
      placeholder={placeholder}
      classNames={classNames}
      title={title}
    />
  );
};

export default customContentRenderHelperHelper;
