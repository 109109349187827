export const actionBoxContainerStyles = (isTablet) => {
  return {
    p: '16px 8px',
    cursor: 'pointer',
    borderColor: 'brand.darkBlue.150',
    borderWidth: '1px',
    borderRadius: '6px',
    bg: 'brand.neutral.white',
    flex: isTablet ? '25%' : 'calc(50% - 12px)',
    textAlign: 'left',
    _hover: {
      borderColor: 'brand.blue.800A',
      bg: 'brand.blue.50',
    },
    _focus: {
      borderColor: 'brand.blue.700',
      bg: 'brand.neutral.white',
    },
    _focusWithin: {
      borderColor: 'brand.blue.700',
      bg: 'brand.neutral.white',
    },
    _target: {
      borderColor: 'brand.blue.700',
      bg: 'brand.neutral.white',
    },
    _active: {
      borderColor: 'brand.blue.800A',
      bg: 'brand.blue.100',
    },
    _disabled: {
      opacity: 0.6,
      cursor: 'default',
      _hover: {
        borderColor: 'brand.darkBlue.150',
        bg: 'white',
      },
    },
  };
};

export const actionBoxTitleStyles = {
  textStyle: 'headline-xs',
  color: 'brand.neutral.700',
  flex: '1',
};
