// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=7898%3A126831
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import customTheme from '@theme';
import { disclaimerTextStyles } from './styles/baselaneDisclaimer.styles';

type BaselaneDisclaimerProps = {
  textStyle: Object,
  containerStyle: Object,
  showPrivacyPolicy: boolean,
  text: String,
};

const BaselaneDisclaimer = ({
  text,
  showPrivacyPolicy,
  textStyle,
  containerStyle = { p: '24px' },
}: BaselaneDisclaimerProps): any => {
  return (
    <Box {...containerStyle}>
      <Box {...{ ...disclaimerTextStyles, ...textStyle }}>{text}</Box>
      {showPrivacyPolicy && (
        <Text {...{ ...disclaimerTextStyles, ...textStyle }} mt="8px">
          When Connecting an Account, your information is encrypted end-to-end. Therefore, your
          credentials will never be made accessible to Baselane. For more information, please see
          our{' '}
          <a
            className="disclaimerlink"
            href="https://www.baselane.com/privacy-policy/"
            target="_blank"
            rel="noreferrer noopener"
            style={{ color: customTheme.colors.brand.blue['800A'] }}
          >
            Privacy Policy
          </a>
          .
        </Text>
      )}
    </Box>
  );
};

export default BaselaneDisclaimer;
