import React from 'react';
import { AlertFooter, AlertHeader, BaselaneAlert } from '@shared/components';

type UnsavedChangesAlertProps = {
  isAlertOpen: boolean,
  onAlertClose: Function,
  rightButtonEvent: Function,
};

const UnsavedChangesAlert = ({
  isAlertOpen,
  onAlertClose,
  rightButtonEvent,
}: UnsavedChangesAlertProps) => {
  const alertFooter = (
    <AlertFooter
      leftButtonText="Continue Editing"
      leftButtonEvent={onAlertClose}
      rightButtonText="Exit Without Saving"
      rightButtonEvent={rightButtonEvent}
    />
  );

  return (
    <BaselaneAlert
      isOpen={isAlertOpen}
      onClose={onAlertClose}
      header={<AlertHeader title="You Have Unsaved Changes" />}
      body="Are you sure you want to exit without saving?"
      footer={alertFooter}
      size="xl"
    />
  );
};

export default UnsavedChangesAlert;
