import process from 'process';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  defaultDataIdFromObject,
} from '@apollo/client';
import { init as initApm } from '@elastic/apm-rum';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import clearLocationState from '@core/utils/clearLocationState';
import previousPageStorage from '@core/storage/previousPageStorage';
import { manageStorageAfterLogout } from '@core/storage/helpers/cleanup.helpers';
import getSearchParams from '@core/utils/sendSegmentEvent/helpers/path.helpers';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';

import {
  LOGIN,
  SESSION_EXPIRED,
  HOME,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SIGN_UP,
  VERIFIED,
  UNVERIFIED,
} from '@routes';
import { initializeDatadogLogs } from '@core/datadog/datadogConfig';

export const isPublicPage = (location, publicPages) => {};

let apm;

if (process?.env?.REACT_APP_DATADOG_ENABLED !== 'true') {
  apm = initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'lanlord-portal-client',

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: process?.env?.REACT_APP_APM_SERVER_URL,

    // Set service version (required for sourcemap feature)
    serviceVersion: process?.env?.REACT_APP_APM_SERVICE_VERSION || 'default',

    environment: process?.env?.REACT_APP_NODE_ENV,

    // Set tracing through URLs
    distributedTracingOrigins: [
      `GET ${process?.env?.REACT_APP_ORCHESTRATION_URL}`,
      `POST ${process?.env?.REACT_APP_ORCHESTRATION_URL}`,
      process?.env?.REACT_APP_ORCHESTRATION_URL,
      process?.env?.REACT_APP_ORCHESTRATION_URL?.replace('/graphql', ''),
      'https://develop-orchestration.baselane.com',
      'http://localhost:4500/graphql',
      'http://localhost:4500',
    ],
  });
} else {
  initializeDatadogLogs();
}

const URI = process.env.REACT_APP_ORCHESTRATION_URL;

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
    credentials: 'include',
  };
});

const defaultOptions = {
  watchQuery: {
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

// eslint-disable-next-line consistent-return
const authErrorLink = onError(({ graphQLErrors, networkError }) => {
  // this is the signature that is used to identify
  // the state of a missing session cookie
  // it can be made more specific or general as needed
  const noSessionCookieSignature = {
    result: {
      messageCode: 'UNAUTHORIZED_ACCESS',
    },
    statusCode: 401,
  };

  const noSession =
    networkError?.result?.messageCode === noSessionCookieSignature.result.messageCode &&
    networkError?.statusCode === noSessionCookieSignature.statusCode;

  const { pathname, search } = window.location;

  if (noSession) {
    if (
      !pathname.includes(LOGIN) &&
      !pathname.includes(FORGOT_PASSWORD) &&
      !pathname.includes(RESET_PASSWORD) &&
      !pathname.includes(SIGN_UP) &&
      !pathname.includes(VERIFIED) &&
      !pathname.includes(UNVERIFIED) &&
      // url variable triggers
      !search.includes('mode=verifyEmail') &&
      !search.includes('mode=resetPassword') &&
      !search.includes('activateaccount')
    ) {
      // save last location for when user logs back in
      const searchParams = getSearchParams(true);
      previousPageStorage.write(window.location.pathname + searchParams);
      // delete any data that was stored in location, just in case
      clearLocationState();

      // set inActivityLogoutStorage to 'true'
      inActivityLogoutStorage.write();

      // delete local storage (other than specificically flagged items)
      manageStorageAfterLogout();

      // redirect to login
      window.location.href = pathname.toString() === HOME ? LOGIN : `${LOGIN}/${SESSION_EXPIRED}`;
    }
  }

  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      apm?.captureError({ message, locations, path });
      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.debug(`[Network error]: ${networkError}`);
  }
});

const httpLink = createHttpLink({
  uri: URI,
  credentials: 'include',
});

export const inMemoryCache = new InMemoryCache({
  // for migration it is possible for ids of fromTransferAccount and toTransferAccount to be the same
  typePolicies: {
    TransferAccount: { keyFields: ['id', 'accountNumber'] },
    LandlordTenants: { merge: true },
    Query: {
      fields: {
        leases: {
          keyArgs: ['input', ['state', 'tenantProfileId']],
          merge(existing, incoming) {
            if (!existing) return incoming;
            if (!incoming) return existing;
            const { leases, ...rest } = incoming;
            const result = rest;
            const nonDuplicateLeases = leases.filter(
              (lease) =>
                // eslint-disable-next-line no-underscore-dangle
                !existing.leases.some((existingLease) => existingLease.__ref === lease.__ref)
            );
            result.leases = [...existing.leases, ...nonDuplicateLeases];
            return result;
          },
        },
      },
    },
  },
  dataIdFromObject(responseObject) {
    // eslint-disable-next-line no-underscore-dangle
    switch (responseObject.__typename) {
      case 'ScheduledPayments':
        return `ScheduledPayment:${responseObject.transferId}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

const apolloClient = new ApolloClient({
  link: authLink.concat(authErrorLink).concat(httpLink),
  cache: inMemoryCache,
  defaultOptions,
  credentials: 'include',
});

export default apolloClient;
