import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { UNIFIED_RENT_COLLECTION } from '@routes';
import { TrackerBox } from '@shared/components';
import historyStateStorage from '@core/storage/historyStateStorage';
import { sendRentCollectionStartedSegmentEvent } from '@pages/LeasesPage/hooks/useCreateLease';
import { TrackersConfig } from '../helpers/trackerboxConfig.helper';

type AddPropertyRentCollectionButtonProps = { property?: Object };

function AddPropertyRentCollectionButton({ property }: AddPropertyRentCollectionButtonProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const historyState = location?.state ?? { from: location.pathname };
  const { cache } = useApolloClient();

  return (
    <TrackerBox
      trackerData={{
        ...TrackersConfig.rentcollection,
        onClickTrackerBox: () => {
          historyStateStorage.write(historyState);
          navigate(UNIFIED_RENT_COLLECTION, {
            search: property ? `?unit=${property?.units[0].id}` : null,
          });
          sendRentCollectionStartedSegmentEvent(cache);
        },
        isDisabled: !property,
      }}
    />
  );
}

AddPropertyRentCollectionButton.defaultProps = {
  property: null,
};

export default AddPropertyRentCollectionButton;
