// @flow
import React from 'react';
import { cloneDeep } from 'lodash';
import { useMutation, useApolloClient } from '@apollo/client';
import { FormLabel, VStack } from '@chakra-ui/react';
import { BaselaneFileUploader } from '@shared/components';
import { GET_TRANSACTIONS, UPDATE_DOCUMENT } from '@core/apollo/queries';

type FileUploaderProps = {
  entityId: String,
  entityDate: String,
  documents: Array<Object>,
  setDocuments: Function,
  filters: Object,
  externalFilters: Object,
};

function FileUploader({
  entityId,
  entityDate,
  documents,
  setDocuments,
  filters,
  externalFilters,
}: FileUploaderProps): any {
  const { cache } = useApolloClient();

  // Queries
  const [updateDocument] = useMutation(UPDATE_DOCUMENT);

  // API Callbacks
  const onUploadDocumentSuccess = ({ newDocument }) => {
    if (newDocument?.id) {
      cache.updateQuery(
        {
          query: GET_TRANSACTIONS,
          variables: { input: { ...filters, filter: { ...filters.filter, ...externalFilters } } },
        },
        (data) => {
          const { transaction } = data ?? {};
          const clonedTrxs = cloneDeep(transaction);
          const updatedTrx = clonedTrxs.map((trx) => {
            const clonedTrx = cloneDeep(trx);
            if (trx.id === entityId) {
              const updatedDocuments = cloneDeep(documents);
              updatedDocuments.push(newDocument);
              setDocuments(updatedDocuments);
              clonedTrx.isDocumentUploaded = true;
            }
            return clonedTrx;
          });

          return {
            transaction: updatedTrx,
          };
        }
      );
    }
  };

  const onUploadDocumentFail = ({ docId, failureReason }) => {
    updateDocument({ variables: { input: { id: docId, failureReason } } });
  };

  return (
    <VStack gap={1.5}>
      <FormLabel m={0} alignSelf="flex-start">
        Attachments
      </FormLabel>
      <BaselaneFileUploader
        {...{
          documents,
          allowedFileType: {
            enum: ['PDF', 'JPEG', 'JPG', 'PNG'],
            text: ['PDF', 'JPG', 'PNG'],
          },
          entityData: { entityType: 'TRANSACTION', entityId, entityDate },
          onUploadSuccess: ({ newDocument }) => {
            onUploadDocumentSuccess({ newDocument });
          },
          onUploadFail: ({ docId, failureReason }) => {
            onUploadDocumentFail({ docId, failureReason });
          },
        }}
      />
    </VStack>
  );
}

export default FileUploader;
