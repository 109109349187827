export const headerElementsStyles = {
  color: 'brand.neutral.900',
};

export const gridStyles = {
  w: '100%',
  h: 'inherit',
  borderRadius: 'md',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
};

export const headerGridItemStyles = {
  h: 'inherit',
  display: 'flex',
  alignItems: 'center',
  wordBreak: 'break-word',
};

export const contentItemsStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.500',
  mb: '12px',
};

export const contentContainerStyles = {
  w: '100%',
  pr: '24px',
  pl: '84px',
  alignItems: 'stretch',
};

export const contentInnerContainerStyles = {
  my: '20px',
  w: '100%',
};

export const noLeaseRecordTextStyles = {
  textStyle: 'md',
  color: 'brand.neutral.600',
};

export const badgeContainerStyles = {
  w: '280px',
  minWidth: '280px',
  pl: '24px',
  display: 'flex',
  alignItems: 'center',
};

export const badgeBackgroundStyles = {
  bg: 'yellow.100',
  color: 'yellow.900',
};

export const accordionContainerStyles = {
  display: 'inline-flex',
  background: 'none',
  borderWidth: '1px',
  borderRadius: 'md',
  borderStyle: 'solid',
  borderColor: 'brand.darkBlue.250',
  pl: '0',
  m: '0',
  justifyContent: 'space-between',
};

export const accordionButtonStyles = {
  h: '72px',
  maxHeight: 'unset',
  borderRadius: 'md',
};

export const accordionContentStyles = {
  p: '4px',
  borderTopRadius: 'none',
  borderBottomRadius: 'md',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: 'brand.darkBlue.250',
};

export const contentTextStyles = {
  textStyle: 'md',
  color: 'brand.neutral.700',
  _notLast: { mb: '12px' },
};
