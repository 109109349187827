import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { indicatorColors } from './tagColorPresets';
import {
  getBorderColor,
  getHoverBgColor,
  getActiveBgColor,
  getSharedStyles,
} from './tagStyleHelpers';

const parts = ['container', 'chip', 'indicator', 'closeButton', 'label'];
const { definePartsStyle } = createMultiStyleConfigHelpers(parts);

const sizes = {
  sm: {
    container: {
      textStyle: 'xs',
      h: '20px',
    },
  },
  md: {
    container: {
      textStyle: 'sm',
      minH: '28px',
    },
  },
  lg: {
    container: {
      textStyle: 'md',
      h: '32px',
    },
  },
};

const variants = [
  'simple-neutral',
  'simple-primary',
  'simple-success',
  'simple-danger',
  'simple-warning',
  'simple-reversed',
  'status-neutral',
  'status-primary',
  'status-success',
  'status-danger',
  'status-warning',
  'status-reversed',
];

export const paddings = ({ isDeletable }) => {
  return {
    simple: {
      lg: {
        px: 1.5,
        py: isDeletable ? 0 : 0.5,
        pr: isDeletable ? 0 : 1.5,
      },
      md: {
        px: 1,
        py: isDeletable ? 0 : 0.5,
        pr: isDeletable ? 0 : 1,
      },
      sm: {
        px: 1,
        py: isDeletable ? 0 : 0.25,
        pr: isDeletable ? 0 : 1,
      },
    },
    status: {
      lg: {
        pr: isDeletable ? 0 : 1.5,
        pl: 1,
        py: isDeletable ? 0 : 0.5,
      },
      md: {
        pr: isDeletable ? 0 : 1.5,
        pl: 1,
        py: isDeletable ? 0 : 0.5,
      },
      sm: {
        pr: isDeletable ? 0 : 1,
        pl: 0.75,
        py: isDeletable ? 0 : 0.25,
      },
    },
  };
};

const baseStyle = definePartsStyle((props) => {
  const { chipColor, theme } = props;
  const { radii } = theme;
  const defaultBgColor = 'white';

  return {
    chip: {
      cursor: 'default',
      borderRadius: radii.full,
      border: '1px solid',
      background: chipColor === 'reversed' ? 'transparent' : defaultBgColor,
      fontWeight: 'normal',
      w: 'fit-content',
      m: '0 !important',
    },
    indicator: {
      container: {
        minW: '12px',
        w: '12px',
        h: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      dot: {
        minW: '6px',
        w: '6px',
        h: '6px',
        borderRadius: radii.lg,
      },
    },
    closeButton: {
      opacity: '1',
      boxShadow: 'none !important',
      outline: 'none !important',
      m: '0 !important',
      color: 'currentColor',
      w: '28px',
      h: '28px',
      borderRadius: radii.full,
      _hover: {
        bgColor: 'transparent',
      },
      _active: {
        bgColor: 'transparent',
      },
      _focus: {
        bgColor: 'transparent',
      },
      _disabled: {
        color: 'currentColor',
        opacity: 1,
        _hover: { color: 'currentColor', opacity: 1 },
        _active: { color: 'currentColor', opacity: 1 },
        _focus: { color: 'currentColor', opacity: 1 },
      },
    },
    container: {
      cursor: 'default',
      border: '1px solid',
      borderColor: 'brand.darkBlue.200',
      color: 'brand.neutral.700',
      background: 'transparent',
      fontWeight: 'normal',
      w: 'fit-content',
      m: '0 !important',
      borderRadius: radii.full,
    },
    label: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
    },
  };
});

export const types = {
  simple: definePartsStyle(
    ({ color, isClickable, isDeletable, isDisabled, isSelectable, isSelected }) => {
      const disabledBgStyles = {
        bgColor: 'transparent',
        cursor: 'default',
      };
      const hoverBgStyles = {
        bgColor: getHoverBgColor({ color, isSimpleChip: true, isSelected }),
        cursor: 'pointer',
      };
      const activeBgStyles = {
        bgColor: getActiveBgColor({ color, isSimpleChip: true, isSelected }),
        cursor: 'pointer',
      };

      const chipHoverBg = isClickable ? hoverBgStyles : {};
      const chipActiveBg = isClickable ? activeBgStyles : {};

      const deleteHoverBg = isDeletable ? hoverBgStyles : {};
      const deleteActiveBg = isDeletable ? activeBgStyles : {};

      return {
        chip: {
          ...getSharedStyles({ isDisabled, color, isSimpleChip: true, isSelectable, isSelected }),
          _hover: isDisabled ? disabledBgStyles : chipHoverBg,
          _active: isDisabled ? disabledBgStyles : chipActiveBg,
          sx: {
            '&:has(.delete-chip:hover)': isDisabled ? disabledBgStyles : deleteHoverBg,
            '&:has(.delete-chip:active)': isDisabled ? disabledBgStyles : deleteActiveBg,
            '&:has(.delete-chip:focus)': isDisabled ? disabledBgStyles : deleteActiveBg,
          },
        },
      };
    }
  ),
  status: definePartsStyle(({ color, isClickable, isDeletable, isDisabled }) => {
    const isReversedChip = color === 'reversed';
    const dotBgColor = indicatorColors[color];
    const disabledDotBgColor = isReversedChip ? 'brand.neutral.600' : 'brand.neutral.400';

    const disabledBgStyles = {
      bgColor: 'transparent',
      cursor: 'default',
    };
    const hoverBgStyles = {
      bgColor: getHoverBgColor({ color, isSimpleChip: false }),
      borderColor: getBorderColor({ color, isDisabled, isSimpleChip: false, isActive: true }),
      cursor: 'pointer',
    };
    const activeBgStyles = {
      bgColor: getActiveBgColor({ color, isSimpleChip: false }),
      borderColor: getBorderColor({ color, isDisabled, isSimpleChip: false, isActive: true }),
      cursor: 'pointer',
    };

    const chipHoverBg = isClickable ? hoverBgStyles : {};
    const chipActiveBg = isClickable ? activeBgStyles : {};

    const deleteHoverBg = isDeletable ? hoverBgStyles : {};
    const deleteActiveBg = isDeletable ? activeBgStyles : {};

    return {
      chip: {
        ...getSharedStyles({ isDisabled, color, isSimpleChip: false }),
        gap: 0.5,
        _hover: isDisabled ? disabledBgStyles : chipHoverBg,
        _active: isDisabled ? disabledBgStyles : chipActiveBg,
        sx: {
          '&:has(.delete-chip:hover)': isDisabled ? disabledBgStyles : deleteHoverBg,
          '&:has(.delete-chip:active)': isDisabled ? disabledBgStyles : deleteActiveBg,
          '&:has(.delete-chip:focus)': isDisabled ? disabledBgStyles : deleteActiveBg,
        },
      },
      indicator: {
        dot: {
          backgroundColor: isDisabled ? disabledDotBgColor : dotBgColor,
        },
      },
    };
  }),
};

const getVariants = definePartsStyle((props) => {
  const {
    isClickable,
    isDeletable,
    isDisabled,
    size,
    chipColor: color,
    type,
    isSelectable,
    isSelected,
  } = props;

  const customStyle = type
    ? types[type]({
        color,
        isClickable,
        isDeletable,
        isDisabled,
        size,
        type,
        isSelectable,
        isSelected,
      })
    : {};
  const padding = type ? paddings({ isDeletable })[type][size] : {};

  return {
    chip: {
      ...customStyle?.chip,
      ...padding,
    },
    indicator: {
      ...customStyle?.indicator,
    },
    closeButton: {
      cursor: isDisabled ? 'default' : 'pointer',
    },
  };
});

const mapVariants = () => {
  const variantObj = {};
  variants.forEach((v) => {
    variantObj[v] = getVariants;
  });
  return variantObj;
};

export { parts, sizes, variants, baseStyle, mapVariants };
