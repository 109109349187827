// indicator
const indicatorColors = {
  neutral: 'brand.darkBlue.500',
  primary: 'brand.blue.800A',
  success: 'green.500A',
  danger: 'red.500A',
  warning: 'yellow.500A',
  reversed: 'brand.neutral.white',
};

// border
const borderSimpleColors = {
  neutral: 'brand.darkBlue.200',
  primary: 'brand.blue.500',
  success: 'green.500A',
  danger: 'red.500A',
  warning: 'yellow.500A',
  reversed: 'brand.neutral.white',
};

const borderStatusColors = {
  neutral: 'brand.darkBlue.200',
  primary: 'brand.darkBlue.200',
  success: 'brand.darkBlue.200',
  danger: 'brand.darkBlue.200',
  warning: 'brand.darkBlue.200',
  reversed: 'brand.neutral.white',
};

const borderDisabledColors = {
  neutral: 'brand.neutral.200',
  primary: 'brand.neutral.200',
  success: 'brand.neutral.200',
  danger: 'brand.neutral.200',
  warning: 'brand.neutral.200',
  reversed: 'brand.neutral.600',
};

const borderStatusActiveColors = {
  neutral: 'brand.darkBlue.250',
  primary: 'brand.darkBlue.250',
  success: 'brand.darkBlue.250',
  danger: 'brand.darkBlue.250',
  warning: 'brand.darkBlue.250',
  reversed: 'brand.neutral.white',
};

const borderSelectableColors = {
  neutral: 'brand.neutral.700',
  primary: 'brand.neutral.700',
  success: 'brand.neutral.700',
  danger: 'brand.neutral.700',
  warning: 'brand.neutral.700',
  reversed: 'brand.neutral.700',
};

// text
const textSimpleColors = {
  neutral: 'brand.neutral.700',
  primary: 'brand.blue.800A',
  success: 'green.800AA',
  danger: 'red.800AA',
  warning: 'yellow.800AA',
  reversed: 'brand.neutral.white',
};

const textStatusColors = {
  neutral: 'brand.neutral.700',
  primary: 'brand.neutral.700',
  success: 'brand.neutral.700',
  danger: 'brand.neutral.700',
  warning: 'brand.neutral.700',
  reversed: 'brand.neutral.white',
};

const textStatusDisabledColors = {
  neutral: 'brand.neutral.400',
  primary: 'brand.neutral.400',
  success: 'brand.neutral.400',
  danger: 'brand.neutral.400',
  warning: 'brand.neutral.400',
  reversed: 'brand.neutral.600',
};

// hover
const hoverSimpleBgColors = {
  neutral: 'brand.darkBlue.50',
  primary: 'brand.blue.50',
  success: 'green.50',
  danger: 'red.50',
  warning: 'yellow.50',
  reversed: 'brand.neutral.800',
};

const hoverStatusBgColors = {
  neutral: 'brand.darkBlue.50',
  primary: 'brand.darkBlue.50',
  success: 'brand.darkBlue.50',
  danger: 'brand.darkBlue.50',
  warning: 'brand.darkBlue.50',
  reversed: 'brand.neutral.800',
};

// active
const activeSimpleBgColors = {
  neutral: 'brand.darkBlue.100',
  primary: 'brand.blue.100',
  success: 'green.100',
  danger: 'red.100',
  warning: 'yellow.100',
  reversed: 'brand.neutral.700',
};

const activeStatusBgColors = {
  neutral: 'brand.darkBlue.100',
  primary: 'brand.darkBlue.100',
  success: 'brand.darkBlue.100',
  danger: 'brand.darkBlue.100',
  warning: 'brand.darkBlue.100',
  reversed: 'brand.neutral.700',
};

// selected
const selectedBgColors = {
  neutral: 'brand.blue.800A',
  primary: 'brand.blue.800A',
  success: 'brand.blue.800A',
  danger: 'brand.blue.800A',
  warning: 'brand.blue.800A',
  reversed: 'brand.blue.800A',
};

export {
  indicatorColors,
  borderSimpleColors,
  borderStatusColors,
  borderDisabledColors,
  borderStatusActiveColors,
  borderSelectableColors,
  textSimpleColors,
  textStatusColors,
  textStatusDisabledColors,
  hoverSimpleBgColors,
  hoverStatusBgColors,
  activeSimpleBgColors,
  activeStatusBgColors,
  selectedBgColors,
};
