import React from 'react';

function IconListDown() {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333984 0.666667C0.333984 0.298477 0.632461 0 1.00065 0H9.66732C10.0355 0 10.334 0.298477 10.334 0.666667C10.334 1.03486 10.0355 1.33333 9.66732 1.33333H1.00065C0.632461 1.33333 0.333984 1.03486 0.333984 0.666667ZM0.333984 3.33333C0.333984 2.96514 0.632461 2.66667 1.00065 2.66667H7.00065C7.36884 2.66667 7.66732 2.96514 7.66732 3.33333C7.66732 3.70152 7.36884 4 7.00065 4H1.00065C0.632461 4 0.333984 3.70152 0.333984 3.33333ZM10.334 2.66667C10.7022 2.66667 11.0007 2.96514 11.0007 3.33333V9.72386L12.5292 8.19526C12.7896 7.93491 13.2117 7.93491 13.4721 8.19526C13.7324 8.45561 13.7324 8.87772 13.4721 9.13807L10.8054 11.8047C10.545 12.0651 10.1229 12.0651 9.86258 11.8047L7.19591 9.13807C6.93556 8.87772 6.93556 8.45561 7.19591 8.19526C7.45626 7.93491 7.87837 7.93491 8.13872 8.19526L9.66732 9.72386V3.33333C9.66732 2.96514 9.96579 2.66667 10.334 2.66667ZM0.333984 6C0.333984 5.63181 0.632461 5.33333 1.00065 5.33333H7.00065C7.36884 5.33333 7.66732 5.63181 7.66732 6C7.66732 6.36819 7.36884 6.66667 7.00065 6.66667H1.00065C0.632461 6.66667 0.333984 6.36819 0.333984 6Z"
        fill="#3A4B5B"
      />
    </svg>
  );
}

export default IconListDown;
