import React from 'react';
import { HStack, Box } from '@chakra-ui/react';
import { Icon16Property } from '@icons/16px';
import Placeholder from '../../../Shared/components/BaselaneDropdown-new/DisplayInputVariations/Placeholders/Placeholder';
import { DisplayInputShowingSelectedValueNames } from '../../../Shared/components/BaselaneDropdown-new/DisplayInputVariations';

type DisplayInputTrxPropertyItemProps = {
  selectedOptions: Array<Object>,
  showValueByFields: Array<string>,
  placeholder: string,
  title: string,
  isDisabled: boolean,
};

const DisplayInputTrxPropertyItem = ({
  selectedOptions,
  placeholder,
  title,
  showValueByFields,
  isDisabled,
  ...rest
}: DisplayInputTrxPropertyItemProps) => {
  return (
    <HStack overflow="hidden">
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder
          {...{
            placeholder,
            title,
            color: isDisabled ? 'brand.neutral.400' : 'brand.blue.800A',
            _hover: { color: 'brand.neutral.500' },
            textStyle: 'sm',
            ...rest,
          }}
        >
          <Box>
            <Icon16Property />
          </Box>
        </Placeholder>
      ) : (
        <HStack overflow="hidden">
          <Box color="brand.neutral.500">
            <Icon16Property />
          </Box>
          <DisplayInputShowingSelectedValueNames
            {...{
              showValueByFields,
              selectedOptions,
              textStyle: 'sm',
            }}
          />
        </HStack>
      )}
    </HStack>
  );
};

export default DisplayInputTrxPropertyItem;
