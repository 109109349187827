export const createDocumentStyles = ({ isMinXL }) => {
  return {
    container: {
      gap: '8px',
      alignItems: isMinXL ? 'center' : 'flex-start',
    },
    description: {
      textStyle: 'xs',
      color: 'brand.neutral.600',
      m: '0 !important',
    },
    button: {
      alignSelf: 'flex-start',
      borderRadius: '4px',
      w: isMinXL ? '100%' : 'auto',
    },
  };
};
