import React from 'react';
import { Box } from '@chakra-ui/react';
import { BaselaneDrawer } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';

const AccountFormWrapper = ({
  children,
  isAccountsEdit,
  isFromResponsiveMode,
  handleBackClick,
}: any) => {
  const { isMax767 } = getBreakPoints();

  return isFromResponsiveMode ? (
    <BaselaneDrawer
      height="100%"
      isOpen={isAccountsEdit}
      title="Rent Collection Details"
      onClose={() => handleBackClick()}
      closeEvent={() => handleBackClick()}
      newDesignDrawer
      hideOverlay
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      contentStyles={isMax767 && { top: 'auto !important' }}
    >
      <Box>{children}</Box>
    </BaselaneDrawer>
  ) : (
    <Box>{children}</Box>
  );
};

export default AccountFormWrapper;
