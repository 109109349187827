export const unitSpecificSettingsStyles = {
  container: { gap: 2 },
  textcontainer: { gap: 0, color: 'brand.neutral.700' },
  title: { textStyle: 'headline-sm' },
  description: { textStyle: 'sm' },
  divider: {
    borderColor: 'brand.darkBlue.100',
    my: 1,
  },
};
