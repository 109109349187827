import { formInputStyles } from '@shared/styles/input.style';
import { truncatedText } from '@shared/styles/text.style';

export const listStyles = (hasFilterWrapper, topMargin = false) => {
  return {
    overflowY: 'auto',
    top: hasFilterWrapper ? '136px' : '72px',
    px: '16px',
    flex: 1,
    mt: topMargin ? '16px' : '0',
    css: {
      '&::-webkit-scrollbar': {
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#E5E9EF',
        backgroundClip: 'padding-box',
        border: '4px solid transparent',
        borderRadius: '8px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#C8D0DA',
        backgroundClip: 'padding-box',
        border: '4px solid transparent',
        borderRadius: '8px',
      },
    },
  };
};

export const menuOptionGroupStyles = {
  bgColor: 'brand.darkBlue.100',
  textStyle: 'headline-xs',
  color: 'brand.neutral.600',
  h: '36px',
  borderRadius: '4px',
  m: '0 !important',
  p: '8px',
};

export const menuOptionStyles = (isSelectedOption) => {
  return {
    textStyle: 'xs',
    p: '4px 8px',
    borderRadius: '4px',
    borderWidth: '1px',
    bgColor: isSelectedOption ? 'brand.blue.50' : 'brand.neutral.white',
    color: isSelectedOption ? 'brand.blue.700' : 'brand.neutral.600',
    borderColor: isSelectedOption ? 'brand.blue.100' : 'transparent',
    h: '28px',
    ...truncatedText,
    _active: {
      bgColor: 'brand.blue.50',
      color: 'brand.blue.700',
      borderColor: 'brand.blue.100',
    },
    _focus: {
      bgColor: 'brand.blue.50',
      color: 'brand.blue.700',
      borderColor: 'brand.blue.100',
    },
    _hover: {
      bgColor: 'brand.blue.50',
      color: 'brand.blue.700',
      borderColor: 'brand.blue.100',
      cursor: 'pointer',
    },
    _last: {
      mb: '16px',
    },
  };
};

export const searchGroupStyles = {
  w: 'calc(100% - 32px)',
  alignSelf: 'center',
  mt: '16px',
};

export const searchStyles = {
  ...formInputStyles,

  // do not show invalid styles for search input
  _invalid: {
    borderColor: 'brand.darkBlue.150',
    _focus: {
      borderColor: 'brand.darkBlue.250',
      color: 'brand.neutral.700',
      boxShadow: 'none',
    },
  },
  mb: '16px',
  h: '40px',
};

export const dropdownIconContainerStyles = {
  h: '100%',
  m: '0 !important',
  p: '6px 12px 6px 0 !important', // to enable click to toggle open/close possible from anywhere within icon container
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const dropdownContentContainerStyles = {
  p: '6px 0 6px 12px !important', // to enable click to toggle open/close possible from anywhere within icon container
};
