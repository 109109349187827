import { isMobile } from 'react-device-detect';

export const horizontalLegendContainerStyles = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

export const legendItemHorizontalContainerStyles = {
  _notFirst: {
    ml: '12px',
    mt: '0 !important',
  },
};

export const legendItemContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  _notFirst: {
    mt: '12px',
  },
};

export const legendLabelContainerStyles = {
  display: 'flex',
  alignItems: 'center',
};

export const legendItemIndicatorStyles = {
  w: '12px',
  minWidth: '12px',
  h: '12px',
  borderRadius: '100%',
  bg: 'brand.blue.700',
};

export const legendItemLabelStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
  ml: '12px',
};

export const legendItemValueStyles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
};

export const getLegendContainerStyle = (isHorizontal) => {
  return isHorizontal ? horizontalLegendContainerStyles : null;
};

export const getLegendItemValueStylesMobile = () => {
  if (!isMobile) return {};
  return {
    ml: '20px',
  };
};

export const getLegendItemLabelStylesMobile = () => {
  if (!isMobile) return {};
  return {
    ml: '8px',
  };
};
