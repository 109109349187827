import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = [
  'stepper',
  'step',
  'title',
  'description',
  'indicator',
  'separator',
  'icon',
  'number',
];

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts);

const defaultStepperStyles = definePartsStyle((props) => {
  const { isSpreadInMobile, isExpandedInMobile, isMobile } = props;
  const { textStyles } = props.theme;

  return {
    stepper: {
      gap: 0,
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflowX: 'auto',
      width: isSpreadInMobile ? '100%' : 'auto',
    },
    indicator: { border: 'none', background: 'none !important', width: '24px', height: '24px' },
    step: {
      width: 'auto',
      height: '40px',
      bgColor: 'white',
      borderRadius: isExpandedInMobile || !isMobile ? 'sm' : 'lg',
      flex: 'initial',
      padding: 1,
      gap: 1,
    },
    separator: {
      '&[data-orientation=horizontal]': {
        width: isSpreadInMobile ? '100% !important' : '12px',
        minWidth: '12px',
        flex: isSpreadInMobile ? '1 !important' : 'initial',
        marginInlineStart: 'initial',
        background: 'brand.darkBlue.100',
      },
      '&[data-status=complete]': {
        bg: 'brand.darkBlue.100',
      },
    },
    title: { ...textStyles['headline-sm'] },
  };
});

export const Stepper = defineMultiStyleConfig({
  baseStyle: defaultStepperStyles,
  variants: {
    default: defaultStepperStyles,
    clickable: {
      step: {
        cursor: 'pointer',
        _hover: { bgColor: 'brand.darkBlue.100' },
        _active: { bgColor: 'brand.darkBlue.100' },
        _focus: {
          outlineWidth: '1px',
          outlineStyle: 'dashed',
          outlineColor: 'brand.blue.800A',
        },
        _focusVisible: {
          outlineWidth: '1px',
          outlineStyle: 'dashed',
          outlineColor: 'brand.blue.800A',
        },
      },
    },
  },
});
