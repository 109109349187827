import radii from '@core/theme/radius';

export const mainAccountStyles = {
  mobileContainer: {
    m: '0 !important',
  },
  mobileHeader: {
    m: '0 !important',
    zIndex: '99',
    width: '100%',
  },
};

export const baselaneHeaderCardContentStyles = {
  container: {
    px: '32px',
    mb: '24px',
  },
  innerContainer: {
    spacing: '24px',
    w: '100%',
    position: 'relative',
  },
  mobileInnerContainer: (paymentsFeatureFlag) => ({
    position: 'relative',
    overflowX: paymentsFeatureFlag ? 'unset' : 'auto',
    mt: '0 !important',
    bg: 'brand.neutral.white',
    p: '0',
    borderRadius: radii.sm,
    m: '0 16px!important',
    w: 'auto',
  }),
  gridWrapper: {
    w: '100%',
    position: 'relative',
    overflowX: 'auto',
    overflowY: 'hidden',
    css: {
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
      },
      '&::-webkit-scrollbar-track': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        width: '4px',
        height: '4px',
        background: '#E5E9EF',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#ACB8C7',
      },
    },
  },
  gridStyles: {
    w: '100%',
    pb: '10px',
    pt: '3px',
  },
};
