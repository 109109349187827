import React, { useContext } from 'react';
import { HStack, Text, Th } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { Icon16Decrease, Icon16Increase, Icon16SortMini } from '@core/components/Icons/16px';
import { BaselaneResponsiveSortableTableHeadingBtn } from './BaselaneResponsiveSortableTableHeadingBtn';
import type { Sort, SortField, SortsAndFilters } from '../../../../types/Sort';
import BaselaneResponsiveTableContext from './BaselaneResponsiveTableContext';
import {
  defaultContentContainerStyles,
  defaultTitleTextStyles,
  defaultSortableHeadingStyles,
  defaultSortableHeadingButtonStyles,
} from './styles/header.styles';
import SortDirectionEnum from '../SortTableHeader/models/SortDirectionEnum';

type BaselaneResponsiveSortableTableHeadingProps = {
  /**
   * Used to grab information that is defined in config of the table.
   */
  index: Number,
  /**
   * A text, specified to be the content of the heading.
   */
  title?: String,
  /**
   * A value, sorting enumerator
   */
  field: SortField,
  /**
   *
   */
  filters: SortsAndFilters,
  /**
   *
   */
  onSort: Function,
  /**
   * If a heading were to have more than just a text, children can be used instead of
   * just passing title.
   */
  children?: React.Node,
};

/**
 * Renders a single heading of the table.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveSortableTableHeading = ({
  index,
  title,
  field,
  filters,
  onSort,
  children,
  ...rest
}: BaselaneResponsiveSortableTableHeadingProps) => {
  const { columns } = useContext(BaselaneResponsiveTableContext);
  const configStyle = { ...columns?.[index]?.styles };
  const { header: { container: headerContainerStyles = {}, title: titleTextStyles = {} } = {} } =
    configStyle ?? {};

  let configContentContainerStyle = {};

  if (configStyle?.contentContainerStyles) {
    configContentContainerStyle = { ...configStyle.contentContainerStyles };
    delete configStyle.contentContainerStyles;
  }

  const itsTheActiveField = (filters && filters?.sort?.field) === field;

  function sort() {
    const itsAscending = (filters && filters?.sort?.direction) === SortDirectionEnum.Asc;

    const newSortObject: Sort = {
      field,
      direction: itsAscending ? SortDirectionEnum.Desc : SortDirectionEnum.Asc,
    };

    onSort(newSortObject);
  }

  const arrowDownIcon = <Icon16Decrease />;
  const arrowUpIcon = <Icon16Increase />;
  const defaultIcon = <Icon16SortMini />;

  function getIcon() {
    let icon = defaultIcon;
    const itsAscending = (filters && filters?.sort?.direction) === SortDirectionEnum.Asc;
    if (itsTheActiveField && itsAscending) {
      icon = arrowUpIcon;
    }
    if (itsTheActiveField && !itsAscending) {
      icon = arrowDownIcon;
    }

    return icon;
  }

  return (
    <Th {...{ ...defaultSortableHeadingStyles, ...configStyle, ...rest }} key={uuidv4()}>
      <HStack
        {...{
          ...defaultContentContainerStyles,
          // apply the same content alignment as the cells will use
          justifyContent: configContentContainerStyle.justifyContent,
          ...headerContainerStyles,
        }}
      >
        <BaselaneResponsiveSortableTableHeadingBtn
          size="xs"
          iconStyles={
            !itsTheActiveField
              ? {
                  ...defaultSortableHeadingButtonStyles,
                  color: 'brand.neutral.400',
                }
              : {
                  ...defaultSortableHeadingButtonStyles,
                  color: 'brand.neutral.600',
                }
          }
          rightIcon={getIcon()}
          onClick={sort}
        >
          {title && <Text {...{ ...defaultTitleTextStyles, ...titleTextStyles }}>{title}</Text>}
        </BaselaneResponsiveSortableTableHeadingBtn>
      </HStack>
    </Th>
  );
};

BaselaneResponsiveSortableTableHeading.defaultProps = {
  children: null,
  title: null,
};

export default BaselaneResponsiveSortableTableHeading;
