// import { addressFormValidation } from '@shared/helpers/validations.helpers';

const handleRecipientFormValidation = (values) => {
  const errors = {};

  if (!values.recipientName || values.recipientName.trim() === '') {
    errors.recipientName = 'Please enter the recipient name';
  } else if (!/^[a-zA-Z0-9\s]+$/.test(values.recipientName.trim())) {
    errors.recipientName = 'Invalid recipient name ';
  }

  if (
    values.recipientEmail &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.recipientEmail)
  ) {
    errors.recipientEmail = 'Invalid email address';
  }

  if (values.address?.trim() === '') {
    errors.address = 'Please enter address';
  }

  if (values.city?.trim() === '') {
    errors.city = 'Please enter city';
  }

  if (values.state === '') {
    errors.state = 'Please enter state';
  }

  if (values.zipcode === '') {
    errors.zipcode = 'Please enter zipcode';
  }

  if (values.zipcode !== '' && values.zipcode?.length < 5) {
    errors.zipcode = 'Please enter 5-digit zip code';
  }

  return errors;
};

export const handleValidation = (values, currentStep, resetApiErrors) => {
  let errors = {};

  // note: values.stepNumber is for when a specific validation needs to be done that is
  //       not onblur. e.g here it is for when users click on prev btn and to revalidate form
  //       on moving to next form. See onNextClicked + onPrevClicked.
  if (currentStep === 1 || values.stepNumber === 1) {
    errors = {
      ...errors,
      ...handleRecipientFormValidation(values),
    };
  }

  if (Object.keys(errors).length === 0) {
    resetApiErrors();
  }

  return errors;
};
