import {
  getDeviceType,
  getViewport,
} from '@core/utils/sendSegmentEvent/helpers/sharedAttributes.helpers';

const sendSegmentPageEvent = () => {
  window.analytics.page({
    ...getViewport(),
    ...getDeviceType(),
  });
};

export default sendSegmentPageEvent;
