/* eslint-disable no-underscore-dangle */
import React, { useRef, useState, useContext } from 'react';
import { useOutletContext, useNavigate, useParams, generatePath } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import { BaselaneDrawer, TwoFactorVerificationPopUp, useTwoFactor } from '@shared/components';
import BanksContext from '@contexts/BanksContext';
import { CREATE_BASELANE_SUBACCOUNT } from '@core/components/NativeBankingPage/queries';
import onDrawerClose from '@core/utils/onDrawerClose';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  getUnitOTPVerification,
  getSensitiveToken,
  setTokenData,
  getIsTokenValid,
  getIsTokenExpired,
  handleNBOnVerifyClick,
  handleOTPPopupOpen,
  handleOTPPopupClose,
} from '@core/components/NativeBankingPage/helpers/otp.helpers';
import VirtualAccountCreatedScreen from './SubAccountCreatedScreen';
import UnsavedChangesAlert from '../UnsavedChangesAlert';
import AddVirtualDrawerBody from './Body';

type AddVirtualAccountDrawerProps = {
  from?: String,
};

const AddVirtualAccountDrawer = ({ from }: AddVirtualAccountDrawerProps) => {
  const { isMax576 } = getBreakPoints();
  const formikRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();

  const { account, handleCreateSuccess } = useOutletContext() ?? {};

  const { phoneNumber, id, plaidItemId: itemId, bankId } = account ?? {};
  const parentAccountId = Number(id);

  const { refetchBankSummary } = useContext(BanksContext);

  // Alert State
  const { isOpen: isDrawerAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  // Mutations
  const [createBaselaneSubAccount] = useMutation(CREATE_BASELANE_SUBACCOUNT);

  // Two factor verification states
  const { states, stateFunctions } = useTwoFactor();
  const { setOTPErrorCode } = stateFunctions;

  // State variables
  const [isDirty, setIsDirty] = useState(false);
  const [valuesToSubmit, setValuestoSubmit] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showSubAccountCreatedSuccessScreen, setShowSubAccountCreatedSuccessScreen] = useState(
    false
  );
  const [showErrorBanner, setShowErrorBanner] = useState(false);

  // OTP: Check if token of the bank expired
  const isTokenExpired = getIsTokenExpired(bankId);

  // Local variables
  const initialAccountValues = { name: '', autoTag: { enabled: false } };

  const title = 'Add Virtual Account';

  const onClose = () => {
    if (from) {
      const redirectUrl = generatePath(from, params);
      onDrawerClose(navigate, redirectUrl);
    } else {
      onDrawerClose(navigate, null);
    }
  };

  const cleanup = () => {
    if (isDirty) setIsDirty(false);
    if (isFormValid) setIsFormValid(false);
    setIsSubmitting(false);
    setShowErrorBanner(false);
    if (showSubAccountCreatedSuccessScreen) {
      setShowSubAccountCreatedSuccessScreen(false);
    }
  };

  const handleFormUpdate = (inputName, values, dirty) => {
    setIsDirty(dirty);
    setIsFormValid(formikRef?.current?.isValid);
    if (showErrorBanner) {
      setShowErrorBanner(false);
    }
  };

  const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

  const handleCreateBaselaneSubAccount = (values) => {
    const variables = {
      ...values,
      accountSubType: 'checking',
    };

    createBaselaneSubAccount({ variables }).then(({ errors }) => {
      if (errors && errors.length > 0) {
        setShowErrorBanner(true);
        setIsSubmitting(false);
      } else {
        cleanup();
        setShowSubAccountCreatedSuccessScreen(true);
        if (handleCreateSuccess) handleCreateSuccess();
      }
    });
  };

  const handleExpiredTokenOnSubmit = (valuesToSave) => {
    setValuestoSubmit(valuesToSave);

    // OTP2.2 triggers OTP and opens popup for user to enter the otp code they get on their phone
    handleOTPPopupOpen(getOTP, bankId, stateFunctions);
  };

  // OTP1: onSubmit of a form line191 will check the localstorage to see if the expiredTimeExists and if
  // today's date is smaller than the expiredunitokentime if it is it will perform what is to happen on submit
  // if not the call getOTP
  const handleOnSubmit = (values) => {
    const { name: nickName, autoTag } = values;

    if (autoTag !== null) {
      delete autoTag.__typename;
      if (!autoTag.enabled) {
        autoTag.propertyId = null;
        autoTag.propertyUnitId = null;
      }
    }

    const valuesToSave = { nickName, autoTag, parentAccountId, plaidItemId: itemId };

    // OTP2.1: directly goes into logic to perform what needs to be done onsubmit.
    // (search for 'OTP data' in this file to find where expiredUnitTokenTime is defined)
    if (!isTokenExpired) {
      setIsSubmitting(true);
      handleCreateBaselaneSubAccount(valuesToSave);
    } else {
      handleExpiredTokenOnSubmit(valuesToSave);
    }
  };

  // OTP3. Once user enters otp code into popup and click 'Verify' on success we will come here
  // In this function we set the sensitive expire time and the token in an object
  const onUserSensitiveTokenComplete = (unitAPISensitiveToken) => {
    setIsSubmitting(true);

    const isTokenValid = getIsTokenValid(unitAPISensitiveToken);
    setTokenData(unitAPISensitiveToken, bankId);

    if (isTokenValid) {
      handleCreateBaselaneSubAccount(valuesToSubmit);
    }

    handleOTPPopupClose(states, stateFunctions);
  };

  const getSensitiveTokenProps = {
    onUserSensitiveTokenComplete,
    getOTP,
    bankId,
    states,
    stateFunctions,
  };

  const [getUserSensitiveTokenData] = getSensitiveToken(getSensitiveTokenProps);

  const handleValidation = (values) => {
    const errors = {};
    if (values.name === '') errors.name = 'Please enter a nickname.';

    return errors;
  };

  const onCloseWithoutSaving = () => {
    if (isDirty) {
      onAlertOpen();
    } else {
      cleanup();
      refetchBankSummary();
      onClose();
    }
  };

  const handleAlertContinueClick = (e) => {
    onAlertClose();
    onClose();
    cleanup();
    if (showSubAccountCreatedSuccessScreen) setShowSubAccountCreatedSuccessScreen(false);
  };

  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP,
    bankId,
    phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleNBOnVerifyClick(otpCode, bankId, getUserSensitiveTokenData),
  };

  return (
    <>
      <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />

      <UnsavedChangesAlert
        isDrawerAlertOpen={isDrawerAlertOpen}
        onAlertClose={onAlertClose}
        onAlertOpen={onAlertOpen}
        onAlertContinue={(e) => handleAlertContinueClick(e)}
      />

      <BaselaneDrawer
        title={title}
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        closeOnOverlayClick={false}
        closeEvent={onCloseWithoutSaving}
        onOverlayClick={onCloseWithoutSaving}
        isOpen
        newDesignDrawer
        newDrawerUseDefaultBodyFooter
      >
        {showSubAccountCreatedSuccessScreen ? (
          <VirtualAccountCreatedScreen
            onClose={onClose}
            refetchBankSummary={refetchBankSummary}
            showSubAccountCreatedSuccessScreen={showSubAccountCreatedSuccessScreen}
            setShowSubAccountCreatedSuccessScreen={setShowSubAccountCreatedSuccessScreen}
          />
        ) : (
          <AddVirtualDrawerBody
            isDirty={isDirty}
            formikRef={formikRef}
            initialAccountValues={initialAccountValues}
            handleValidation={handleValidation}
            handleOnSubmit={handleOnSubmit}
            handleFormUpdate={handleFormUpdate}
            isFormValid={isFormValid}
            onCloseWithoutSaving={onCloseWithoutSaving}
            isSubmitting={isSubmitting}
            showErrorBanner={showErrorBanner}
          />
        )}
      </BaselaneDrawer>
    </>
  );
};

export default AddVirtualAccountDrawer;

AddVirtualAccountDrawer.defaultProps = {
  from: null,
};
