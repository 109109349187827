import React from 'react';
import { Stack, Box } from '@chakra-ui/react';
import { Icon12Close } from '@icons/12px';
import { dropdownContentContainerStyles } from '@shared/components/BaselaneDropdown-new/styles/dropdown.styles';
import { BaselaneButtonIcon } from '../../BaselaneButtonIcon';

type DisplayInputWrapperProps = {
  children: Array<React.Node>,
  handleContentRendererClick: Function,
  handleClearClick: Function,
  selectedOptions: Array<Object>,
  hideInputClearBtn?: boolean,
  showValueByFields?: Array<string>,
  classNames: Array<string>,
};

const DisplayInputWrapperWithRightButton = ({
  children,
  handleContentRendererClick,
  handleClearClick,
  selectedOptions,
  hideInputClearBtn = false,
  showValueByFields = ['name'],
  classNames,
  ...rest
}: DisplayInputWrapperProps) => {
  return (
    <Stack
      {...{ ...dropdownContentContainerStyles(classNames), ...rest }}
      onClick={handleContentRendererClick}
    >
      <Stack direction="row" justifyContent="space-between" align="center" spacing={0}>
        {children}
        {selectedOptions?.length > 0 && !hideInputClearBtn && (
          <BaselaneButtonIcon
            as={Box}
            className="clear"
            size="xs"
            variant="transparent"
            icon={<Icon12Close />}
            onClick={(e) => {
              e.stopPropagation();
              handleClearClick();
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default DisplayInputWrapperWithRightButton;
