export const failedPaymentsStyles = {
  card: {
    container: {
      borderWidth: '1px',
      borderRadius: '4px',
      borderColor: 'brand.darkBlue.150',
    },
  },
  header: {
    container: {
      p: '12px 16px',
      gap: '12px',
      alignItems: 'center',
      borderBottomWidth: '1px',
      borderColor: 'brand.darkBlue.150',
    },
    icon: {
      container: {
        w: '32px',
        h: '32px',
      },
    },
    title: {
      textStyle: 'headline-xs',
    },
    msg: {
      textStyle: 'xs',
      color: 'red.800AA',
      m: '0 !important',
    },
  },
  content: {
    py: '12px',
    m: '0 !important',
  },
};
