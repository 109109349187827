import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const daysMaskConfig = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: '',
  allowDecimal: false,
  decimalSymbol: '',
  integerLimit: 2, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export default createNumberMask(daysMaskConfig);
