export const wFull = {
  w: '100%',
};

export const hFull = {
  h: '100%',
};

export const fullSize = {
  ...wFull,
  ...hFull,
};
