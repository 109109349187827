import React from 'react';

const IconVisa = () => {
  return (
    <svg width="49" height="17" viewBox="0 0 49 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.9218.637018c-3.4803 0-6.59 1.817112-6.59 5.175142 0 3.85136 5.5144 4.11611 5.5144 6.05134 0 .8152-.9263 1.5428-2.5086 1.5428-1.3715-.009-2.7196-.359-3.9246-1.019l-.7183 3.3885c1.4338.5699 2.9616.862 4.503.8609 3.8054 0 6.8055-1.9047 6.8055-5.3218 0-4.06848-5.5389-4.32752-5.5389-6.12178 0-.63808.7561-1.3333 2.3384-1.3333 1.1173.01504 2.2189.26752 3.2327.74093l.7032-3.27232c-1.2187-.466824-2.513-.701281-3.8168-.691412ZM.0850717.88463 0 1.37796c.949435.18582 1.88065.45608 2.78272.8076 1.70138.61713 1.81859.97713 2.10405 2.0952L8.0022 16.3834h4.1779L18.6075.88463h-4.1589L10.3142 11.4216 8.62604 2.49032C8.47103 1.46748 7.68083.88463 6.73561.88463H.0850717ZM20.29.88463 17.0215 16.3834h3.9699L24.2486.88463h-3.9699.0113Zm22.1634 0c-.9584 0-1.4651.51618-1.8375 1.42093L34.7934 16.3834h4.1589l.8054-2.3466h5.0758l.4896 2.3466H49L45.7863.88463h-3.3329Zm.5426 4.1904 1.2344 5.81517h-3.3082l2.0795-5.81517h-.0057Z"
        fill="#1434CB"
      />
    </svg>
  );
};

export default IconVisa;
