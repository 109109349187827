import React from 'react';

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
  useMediaQuery,
  Text,
  Box,
  Heading,
} from '@chakra-ui/react';
import IconWarningTriangleYellow from '@icons/legacy/IconWarningTriangleYellow';
import { BaselaneButton, BaselaneButtonGroup } from '@core/components/Shared/components';

type ActivatePhysicalCardAlertProps = {
  isAlertOpen: Boolean,
  onAlertClose: Function,
};

const ActivatePhysicalCardAlert = ({
  isAlertOpen,
  onAlertClose,
}: ActivatePhysicalCardAlertProps) => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onAlertClose}
      isOpen={isAlertOpen}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size={isLargerThan768 ? 'lg' : 'xs'}
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4}>
        <AlertDialogCloseButton />
        <Box mb={2}>
          <IconWarningTriangleYellow />
        </Box>
        <AlertDialogHeader p={0}>
          <Heading size="headline-lg">Activate your physical debit card first</Heading>
        </AlertDialogHeader>
        <AlertDialogBody p={0} mt="4px">
          <Text textStyle="sm">
            Virtual cards are only available after you activate your physical card.
          </Text>
        </AlertDialogBody>
        <AlertDialogFooter pt={3} px={0} pb={0} justifyContent="flex-start">
          <BaselaneButtonGroup styles={{ width: '100%', justifyContent: 'flex-end' }}>
            <BaselaneButton
              variant="outline"
              palette="neutral"
              size="md"
              onClick={() => {
                onAlertClose();
              }}
            >
              Close
            </BaselaneButton>
          </BaselaneButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ActivatePhysicalCardAlert;
