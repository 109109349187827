import customTheme from '@core/theme';

export const normalText = {
  textStyle: 'sm',
};

export const rowStyles = (isMinXL) => ({
  mb: '36px',
  justifyContent: isMinXL ? 'space-between' : 'initial',
  textAlign: isMinXL ? 'right' : 'left',
});

export const labelStyles = (isMinXL) => ({
  lineHeight: '20px',
  fontSize: isMinXL ? 'xs' : 'sm',
  fontWeight: isMinXL ? 'normal' : 'medium',
  color: isMinXL
    ? customTheme.colors.brand.neutral['600']
    : customTheme.colors.brand.neutral['700'],
});

export const helpTextStyles = {
  textStyle: 'xs',
  color: customTheme.colors.brand.neutral['600'],
  textTransform: 'capitalize',
};

export const valueText = (isMinXL) => ({
  fontSize: 'sm',
  lineHeight: '20px',
  color: isMinXL
    ? customTheme.colors.brand.neutral['700']
    : customTheme.colors.brand.neutral['700'],
  fontWeight: isMinXL ? 'medium' : 'normal',
});

export const msgWrapper = { height: '100%' };

export const titleTextStyles = {
  textStyle: 'headline-lg',
};

export const title2TextStyles = {
  fontSize: 'xl',
  lineHeight: '32px',
  fontWeight: 'medium',
};

export const bodyTextStyles = {
  textStyle: 'xs',
  color: customTheme.colors.brand.neutral['600'],
};

export const body2TextStyles = {
  textStyle: 'sm',
  color: customTheme.colors.brand.neutral['600'],
};
