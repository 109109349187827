import React from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { ListItem, UnorderedList, Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';

const getLandlordModifiedDescription = (metadata) => {
  const { oldDueDate, newDueDate, oldAmount, updatedAmount } = metadata ?? {};
  const isDueDateChanged = oldDueDate !== newDueDate;
  const isAmountChanged = oldAmount !== updatedAmount;

  const dueDateChangedMsg = `Due Date changed from ${formatDate(oldDueDate)} to ${formatDate(
    newDueDate
  )}`;
  const amountChangedMsg = `Invoice Amount changed from ${formatCurrency(oldAmount).inDollars} to ${
    formatCurrency(updatedAmount).inDollars
  }`;

  return (
    <>
      <Text>Summary of changes:</Text>
      <UnorderedList marginInlineStart="1.5em">
        {isDueDateChanged && <ListItem>{dueDateChangedMsg}</ListItem>}
        {isAmountChanged && <ListItem>{amountChangedMsg}</ListItem>}
      </UnorderedList>
      <Text>We alerted your tenant of the invoice changes </Text>
    </>
  );
};

const getPaymentOverdueDescription = (metadata) => {
  const { lateFeeEnabled, numOfDaysAfterDueDate } = metadata ?? {};

  const description = lateFeeEnabled
    ? `Your tenant's payment is past due. A late fee will be added if payment is ${numOfDaysAfterDueDate} or more days past due.`
    : "Your tenant's payment is past due";

  return description;
};

const getPaymentProcessingDescription = (metadata) => {
  const { tenantBalanceCheckCleared, tenantPaymentValidated } = metadata ?? {};

  let description;
  if (!tenantPaymentValidated) {
    description = `Since this is your first time collecting rent on Baselane, processing will take up to 5 business days due to a security review`;
  } else if (!tenantBalanceCheckCleared) {
    description = `We are verifying that your tenant has sufficient funds to process this payment. Processing will take up to 5 business days.`;
  } else if (tenantBalanceCheckCleared && tenantPaymentValidated) {
    description = `Processing will take up to 3 business days to be deposited into your bank account`;
  }

  return description;
};

const getPaymentCompleteDescription = (metadata) => {
  const { tenantBalanceCheckCleared, tenantPaymentValidated } = metadata ?? {};

  let description;
  if (!tenantPaymentValidated) {
    description = `Funds are deposited in your bank account within 1 business day. Processing took longer than usual due to a security review for first-time rent collectors.`;
  } else if (!tenantBalanceCheckCleared) {
    description = `Funds are deposited in your bank account within 1 business day. Processing took longer than usual as we waited to verify your tenant had sufficient funds.`;
  } else if (tenantBalanceCheckCleared && tenantPaymentValidated) {
    description = `Funds are deposited in your bank account within 1 business day`;
  }

  return description;
};

const getTimelineContent = (metadata) => {
  return {
    AWAITING_TENANT_PAYMENT: {
      title: 'Waiting for tenant to schedule payment',
      description: '',
    },
    CLEARING_FAILED: {
      title: 'Clearing Failed',
      description: "Your tenant's payment has failed",
    },
    EXPECTED_DEPOSIT: {
      title: 'Expected deposit date',
      description: '',
    },
    PAYMENT_SCHEDULED_TO_BE_PROCESSED: {
      title: 'Payment scheduled to be processed',
      description:
        'The payment should deposit in your bank account in 3-5 business days with standard processing or 2-3 business days with QuickPay. You will see an expected deposit date once we begin processing the payment.',
    },
    INVOICE_CANCELED: {
      title: 'Invoice deleted',
      description: 'This invoice has been deleted',
    },
    INVOICE_CREATED: {
      title: 'Invoice created',
      description: '',
    },
    INVOICE_MARKED_PAID: {
      title: 'Invoice marked as paid',
      description: 'This invoice was marked as paid by the landlord',
    },
    LANDLORD_MODIFIED: {
      title: 'Invoice edited',
      description: getLandlordModifiedDescription(metadata),
    },
    LATE_FEE_CHARGED: {
      title: 'Late fee added',
      description: `A  ${
        formatCurrency(metadata?.lateFeeAmount).inDollars
      } late fee has been added to this invoice. Your tenant's payment was ${
        metadata?.numOfDaysAfterDueDate
      } or more days past due.`,
    },
    PAYMENT_COMPLETE: {
      title: 'Payment complete',
      description: getPaymentCompleteDescription(metadata),
    },
    PAYMENT_FAILED: {
      title: 'Payment failed',
      description: "Your tenant's payment has failed",
    },
    PAYMENT_OVERDUE: {
      title: 'Payment overdue',
      description: getPaymentOverdueDescription(metadata),
    },
    PAYMENT_PROCESSING: {
      title: 'Payment processing',
      description: getPaymentProcessingDescription(metadata),
    },
    PAYMENT_PROCESSING_INITIATED: {
      title: 'Payment processing initiated',
      description: '',
    },
    PAYMENT_SCHEDULED: {
      title: 'Tenant scheduled payment',
      description: `Your tenant has scheduled a payment for ${formatDate(metadata?.paymentDate)}`,
    },
    PAYMENT_REVERSAL_COMPLETED: {
      title: 'Payment reversal completed',
      description: 'Per our Payment Policy, Baselane reversed this payment',
    },
    PAYMENT_REVERSAL_INITIATED: {
      title: 'Payment reversal initiated',
      description: 'Per our Payment Policy, Baselane will be reversing this payment',
    },
  };
};

export const getTimeline = (timeline) => {
  return timeline?.map((item, index) => {
    const { date, isExpandable, isImportant, isError, status, state, metadata } = item ?? {};
    const TIMELINE_CONTENT = getTimelineContent(metadata);
    const { title, description } = TIMELINE_CONTENT[state] ?? {};

    const getDate = () => {
      let newDate;

      const today = new Date().getTime();
      const formattedDate = new Date(date).getTime();

      if (state === 'PAYMENT_PROCESSING' && date === null) {
        newDate = moment(today);
      } else if (state === 'EXPECTED_DEPOSIT' && formattedDate < today) {
        newDate = moment(today);
      } else {
        newDate = date;
      }

      return newDate;
    };

    const newDate = getDate();

    const isLastItem = index === (timeline?.length || 0) - 1;

    const showCircleDot = status === 'COMPLETED';
    const showOutlineDot = status === 'ACTIVE';
    const showDashedDot = status === 'UPCOMING';

    const showSolidConnector =
      timeline[index + 1]?.status === 'COMPLETED' || timeline[index + 1]?.status === 'ACTIVE';

    const hideConnector = isLastItem;

    return {
      key: uuidv4(),
      status,
      date: newDate,
      title,
      isExpandable,
      isImportant,
      isError,
      showSolidConnector,
      hideConnector,
      dotStatus: {
        showCircleDot,
        showOutlineDot,
        showDashedDot,
      },
      description,
    };
  });
};
