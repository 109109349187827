import React from 'react';
import { useGate } from 'statsig-react';

import New from './New';
import Old from './Old';

type AddBaselaneAccountProps = {
  from: string,
};

function AddBaselaneAccount({ from }: AddBaselaneAccountProps): any {
  const { value: showNewSavingAccountUI } = useGate('savings_account_gate');

  return showNewSavingAccountUI ? <New from={from} /> : <Old from={from} />;
}

export default AddBaselaneAccount;
