/**
 * Deletes the entire state that is stored in the location without
 * the need to reload the browser window
 * (the submitted state data to a url via the navigate function)
 */

const clearLocationState = () => {
  window?.history?.replaceState({}, '');
};

export default clearLocationState;
