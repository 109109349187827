import customTheme from '@core/theme';

export const titleContainerStyles = (isDesktop) => ({
  direction: 'row',
  justifyContent: 'space-between',
  p: isDesktop ? '0' : '16px 0 0',
  w: '100%',
  m: '0 !important',
});

export const titleStyles = {
  p: '0 !important',
  lineHeight: '26px',
  textStyle: 'headline-lg',
  color: customTheme.colors.brand.neutral['700'],
};

export const infoStyles = (isDesktop, type) => ({
  container: {
    p: '8px 12px',
    background: type === 'WARNING' ? 'red.200' : 'brand.blue.100',
    borderColor: type === 'WARNING' ? 'red.300' : 'brand.blue.250',
    borderWidth: '1px',
    borderRadius: '6px',
    m: isDesktop ? '8px 0 2px !important' : '8px 0 2px !important',
    cursor: 'pointer',
    _hover: {
      borderColor: type === 'WARNING' ? 'red.900' : 'brand.blue.800A',
    },
    _focus: {
      boxShadow: '0 0 0 2px #9FD8FF',
    },
    _active: {
      background: type === 'WARNING' ? 'red.300' : 'brand.blue.250',
      borderColor: type === 'WARNING' ? 'red.900' : 'brand.blue.800A',
    },
  },
  iconContainer: {
    w: '24px',
    h: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  status: {
    textStyle: 'headline-sm',
    color: 'brand.neutral.700',
  },
  action: {
    color: 'brand.neutral.700',
    textStyle: 'sm',
    m: isDesktop ? '0 0 0 24px !important' : '0 0 0 0 !important',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    whiteSpace: 'nowrap',
  },
  amountAction: {
    color: 'brand.neutral.700',
    textStyle: 'headline-sm',
    m: isDesktop ? '0 0 0 24px !important' : '0 0 0 0 !important',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    whiteSpace: 'nowrap',
  },
});

export const tabListStyles = (isDesktop) => ({
  p: isDesktop ? '0 24px' : '0 16px',
  borderBottom: '1.5px solid #E1F4FF',
  w: '100%',
  direction: 'row',
  borderTop: '1px solid transparent',
  borderRadius: '6px 6px 0 0',
});

export const tabPanelStyles = {
  p: '0',
};

export const baselaneBannerStyles = ({ isMinXL }) => {
  return {
    buttoncard: {
      m: isMinXL ? '8px 0 !important' : '0 0 24px 0 !important',
      borderRadius: '8px',
      boxShadow: 'none',
    },
  };
};
