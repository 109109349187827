import React from 'react';

function IllustrationAddExternalAccount() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0904 12h-5.3463c-.7877 0-1.5432-.3161-2.1002-.8787-.557-.5626-.87-1.32566-.87-2.1213 0-.79565.313-1.55871.87-2.12132C14.2009 6.31607 14.9564 6 15.7441 6c4.1583 0 5.3463 6 5.3463 6Zm0 0s1.1881-6 5.3464-6c.7877 0 1.5432.31607 2.1002.87868.557.56261.87 1.32567.87 2.12132 0 .79564-.313 1.5587-.87 2.1213-.557.5626-1.3125.8787-2.1002.8787h-5.3464ZM10.9033 36h20.4355c1.1045 0 2-.8901 2-1.9946V19.6801H34c1.1045 0 2-.8954 2-2v-3.68c0-1.1045-.8955-2-2-2H8c-1.10457 0-2 .8955-2 2v3.68c0 1.1046.89543 2 2 2h.90332v14.3253c0 1.1045.89543 1.9946 1.99998 1.9946Z"
        fill="#8CCFFF"
      />
      <path
        d="M19.0904 9.99999V34m0-24.00001h-5.3463c-.7877 0-1.5432-.31607-2.1002-.87868-.557-.5626-.87-1.32567-.87-2.12131 0-.79565.313-1.55871.87-2.12132C12.2009 4.31607 12.9564 4 13.7441 4c4.1583 0 5.3463 5.99999 5.3463 5.99999Zm0 0h5.3464c.7877 0 1.5432-.31607 2.1002-.87868.557-.5626.87-1.32567.87-2.12131 0-.79565-.313-1.55871-.87-2.12132C25.98 4.31607 25.2245 4 24.4368 4c-4.1583 0-5.3464 5.99999-5.3464 5.99999Z"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.90332 17.6802v14.3253c0 1.1045.89543 1.9947 2 1.9947H29.3388c1.1045 0 2-.8902 2-1.9947V17.6802"
        stroke="#000619"
      />
      <rect x="4" y="10" width="30" height="7.67999" rx="2" stroke="#000619" />
    </svg>
  );
}

export default IllustrationAddExternalAccount;
