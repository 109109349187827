import { gql } from '@apollo/client';

export const FRAGMENT_CARD_DATA_IN_ACCOUNT = gql`
  fragment CardDataInAccount on Card {
    bankAccountId
    id
    isPhysical
    last4Digits
    nickname
    accountName
  }
`;

export const FRAGMENT_CARD_STATUS = gql`
  fragment CardStatus on Card {
    cardStatus
  }
`;
