import React, { useContext, useEffect, useRef, useState } from 'react';
import { mapValues, groupBy } from 'lodash';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Box, Stack, useDisclosure, useToast } from '@chakra-ui/react';
import {
  UPDATE_BANK_ACCOUNT_FOR_LEASES_AND_INVOICES,
  GET_PROPERTIES_SIMPLE,
  GET_SINGLE_BANK_ACCOUNT,
} from '@core/apollo/queries';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  BaselaneButton,
  BaselaneDrawer,
  BaselaneErrorCard,
  UnsavedChangesAlert,
  TwoFactorVerificationPopUp,
} from '@shared/components';
import UserContext from '@contexts/UserContext';
import useTwoFactor from '@shared/components/TwoFactorVerificationPopUp/hooks/useTwoFactor';
import historyStateStorage from '@core/storage/historyStateStorage';
import SlLoader from '@core/components/Loader';
import { FailedPayments, ReceivingBankAccount, ConfirmationAlert } from './components';
import { getBankAccounts } from '../../../LeaseSection/formHelpers/receivingBank.helper';

type UpdateBankAccountDrawerProps = {
  bankAccountId: Number,
  tenantData: Object,
  banksData: Object,
  refreshLeases: Function,
  refetchInvoiceSummary: Function,
  refetchBankAccounts: Function,
  setIsLeasesPageLoading: Function,
};

function UpdateBankAccountDrawer({
  bankAccountId = null,
  tenantData,
  banksData,
  refreshLeases,
  refetchInvoiceSummary,
  refetchBankAccounts,
  setIsLeasesPageLoading,
}: UpdateBankAccountDrawerProps) {
  const { isMax767, isMinXL } = getBreakPoints();
  const toast = useToast();

  const [connectedAccounts, setConnectedAccounts] = useState();
  const drawerRef = useRef();

  const [
    getSingleBankAccount,
    {
      data: singleBankAccountData,
      loading: isSingleBankAccountLoading,
      error: hasSingleBankAccountError,
    },
  ] = useLazyQuery(GET_SINGLE_BANK_ACCOUNT);

  const handleUpdateReceivingBA = (id) => {
    getSingleBankAccount({
      variables: { id },
    });
    drawerRef.current?.open();
  };

  useEffect(() => {
    if (bankAccountId) {
      handleUpdateReceivingBA(bankAccountId);
    }
  }, [bankAccountId]);

  useEffect(() => {
    let accounts = [];
    if (banksData?.bank?.length > 0) {
      // get all accounts including unconnected ones (ITEM_LOGIN_REQUIRED)
      accounts = getBankAccounts(banksData?.bank, true);
    }
    setConnectedAccounts(accounts);
  }, [banksData]);

  // Unsaved Changes Alert State
  const {
    isOpen: isUnsavedChangesAlertOpen,
    onOpen: onUnsavedChangesAlertOpen,
    onClose: onUnsavedChangesAlertClose,
  } = useDisclosure();

  // Confirmation Alert State
  const {
    isOpen: isConfirmationAlertOpen,
    onOpen: onConfirmationAlertOpen,
    onClose: onConfirmationlertClose,
  } = useDisclosure();

  const baselaneHistoryStateData = historyStateStorage.read('baselane-state');

  const [newBankAccountId, setNewBankAccountId] = useState('');
  const [selectedBankAccountError, setSelectedBankAccountError] = useState(null);
  const [hideSaveChangesBtn, setHideSaveChangesBtn] = useState(false);

  const [filteredAccounts, setFilteredAccounts] = useState();
  const [hasConnectedBank, setHasConnectedBank] = useState();
  const [showReceivingBAEmptyState, setShowReceivingBAEmptyState] = useState();

  const { error: propertiesError, loading: propertiesLoading, data: propertiesData } = useQuery(
    GET_PROPERTIES_SIMPLE
  );

  const [
    updateBankAccountForLeasesAndInvoices,
    { loading: isUpdateBAForLeasesAndInvoicesLoading, error: hasUpdateBAForLeasesAndInvoicesError },
  ] = useMutation(UPDATE_BANK_ACCOUNT_FOR_LEASES_AND_INVOICES);

  const isLoading =
    isSingleBankAccountLoading || propertiesLoading || !Array.isArray(connectedAccounts);
  const hasError = hasSingleBankAccountError || propertiesError;

  const { bankAccount: failedBankAccount } = singleBankAccountData ?? {};
  const { id, invoices: invoiceArray, leases, nickName, bankAccountMetadata } =
    failedBankAccount ?? {};

  const invoices = mapValues(groupBy(invoiceArray, 'state'));
  const { FAILED, PROCESSING, ...rest } = invoices ?? {};
  const UNPAID = Object.values(rest)?.flat(1);
  const failedPayment = {
    account: { nickName, bankAccountMetadata },
    list: { FAILED, PROCESSING, leases, UNPAID },
  };

  const properties = propertiesData?.property || [];

  // phone OTP before making updates
  const { user } = useContext(UserContext);
  const { states, stateFunctions } = useTwoFactor(false);
  const {
    onOTPPopupOpen,
    onOTPPopupClose,
    setOTPErrorCode,
    handleSendText,
    handleVerifyOtp,
  } = stateFunctions;

  useEffect(() => {
    if (
      baselaneHistoryStateData?.params?.isFromUpdateAccount &&
      baselaneHistoryStateData?.params?.failedBankAccountId
    ) {
      handleUpdateReceivingBA(baselaneHistoryStateData?.params?.failedBankAccountId);
    }
  }, [baselaneHistoryStateData?.params, baselaneHistoryStateData?.from?.params]);

  useEffect(() => {
    if (drawerRef.current?.isOpen) {
      historyStateStorage.write({ from: baselaneHistoryStateData?.from });
    }
  }, [drawerRef.current?.isOpen]);

  useEffect(() => {
    const filteredBAs = connectedAccounts?.filter((acc) => acc?.id !== id);
    setFilteredAccounts(filteredBAs);
    setHasConnectedBank(filteredBAs?.length > 0);
    setShowReceivingBAEmptyState(filteredBAs?.length === 0);
  }, [id, connectedAccounts]);

  const { DrawerBody, DrawerFooter } = BaselaneDrawer;

  const cleanup = () => {
    setNewBankAccountId('');
    setSelectedBankAccountError(null);
    setHideSaveChangesBtn(false);
    setShowReceivingBAEmptyState(filteredAccounts?.length === 0);
  };

  const refetchAllData = () => {
    refetchBankAccounts();
    refetchInvoiceSummary();
    refreshLeases();
  };

  const handleDrawerClose = () => {
    cleanup();
    drawerRef.current?.close();
  };

  const handleCancelClick = (isTriggeredByCancelBtn = false) => {
    if (isTriggeredByCancelBtn && hideSaveChangesBtn) {
      setHideSaveChangesBtn(false);
      setShowReceivingBAEmptyState(false);
    } else if (newBankAccountId === '') {
      handleDrawerClose();
    } else {
      onUnsavedChangesAlertOpen();
    }
  };

  const handleSaveChangeClick = () => {
    onConfirmationAlertOpen();
  };

  const handleUnsavedChangesAlertContinueClick = () => {
    onUnsavedChangesAlertClose();
    handleDrawerClose();
  };

  const handleSendTextSuccess = () => {
    setOTPErrorCode(false);
  };

  const handleSendTextFail = (error) => {
    setOTPErrorCode(error);
  };

  const handleVerifyOtpSuccess = () => {
    updateBankAccountForLeasesAndInvoices({
      variables: {
        currentBankAccountId: id,
        newBankAccountId,
      },
    })
      .then(({ data }) => {
        if (
          hasUpdateBAForLeasesAndInvoicesError ||
          data?.updateBankAccountForLeasesAndInvoices !== 'Success'
        ) {
          // Show error toast.
          toast({
            description: 'Something went wrong. Please try again.',
            status: 'error',
            duration: '3000',
            isClosable: true,
            position: 'bottom-left',
          });
        } else {
          onOTPPopupClose();
          handleDrawerClose();
          refetchAllData();
          setIsLeasesPageLoading(true);
          setTimeout(() => {
            setIsLeasesPageLoading(false);
            // Show success toast.
            toast({
              description: 'Receiving accounts updated',
              status: 'success',
              duration: '3000',
              isClosable: true,
              position: 'bottom-left',
            });
          }, 2000);
        }
      })
      .catch((err) => {
        // Show error toast.
        toast({
          description: 'Something went wrong. Please try again.',
          status: 'error',
          duration: '3000',
          isClosable: true,
          position: 'bottom-left',
        });
      });
  };

  const handleConfirmUpdateAccClick = () => {
    handleSendText(user.phoneNumber, false, handleSendTextSuccess, handleSendTextFail);
    onConfirmationlertClose();
    onOTPPopupOpen();
  };

  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP: () => handleSendText(user.phoneNumber, true, handleSendTextSuccess, handleSendTextFail),
    phoneNumber: user.phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleVerifyOtp({ recipient: user.phoneNumber, code: otpCode }, handleVerifyOtpSuccess),
    isVerifyLoading: isUpdateBAForLeasesAndInvoicesLoading,
  };

  return (
    <BaselaneDrawer
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      contentStyles={isMax767 && { top: 'auto !important' }}
      title="Update Receiving Bank Account"
      ref={drawerRef}
      closeEvent={handleCancelClick}
      onClose={handleCancelClick}
      closeOnOverlayClick={!isUpdateBAForLeasesAndInvoicesLoading}
    >
      <DrawerBody p={isMinXL ? '24px' : '32px'}>
        {isLoading && !hasError && <SlLoader />}
        {!isLoading && hasError && (
          <Box h="100%" display="flex" alignItems="center">
            <BaselaneErrorCard />
          </Box>
        )}
        {!isLoading && !hasError && (
          <Stack gap="32px">
            {/* Failed Payments Details Card */}
            <FailedPayments {...{ tenantData, failedPayment, propertiesData: properties }} />

            {/* Bank Account Dropdown */}
            <ReceivingBankAccount
              {...{
                filteredAccounts,
                hasConnectedBank,
                setHasConnectedBank,
                showReceivingBAEmptyState,
                setShowReceivingBAEmptyState,
                setHideSaveChangesBtn,
                newBankAccountId,
                setNewBankAccountId,
                selectedBankAccountError,
                setSelectedBankAccountError,
                refetchBankAccounts,
                failedBankAccountId: id,
              }}
            />
          </Stack>
        )}
      </DrawerBody>
      <DrawerFooter
        gap={2}
        width="100%"
        minH={isMinXL ? '72px' : '80px'}
        p={isMinXL ? '16px 32px' : '20px 32px'}
      >
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={() => handleCancelClick(true)}
        >
          Cancel
        </BaselaneButton>
        {!hideSaveChangesBtn && (
          <BaselaneButton
            variant="filled"
            palette="primary"
            size="md"
            onClick={() => handleSaveChangeClick()}
            isDisabled={newBankAccountId === '' || selectedBankAccountError !== null}
          >
            Save Changes
          </BaselaneButton>
        )}
      </DrawerFooter>

      {/* Alerts Used in the Drawer */}
      <UnsavedChangesAlert
        {...{
          isDrawerAlertOpen: isUnsavedChangesAlertOpen,
          onAlertClose: onUnsavedChangesAlertClose,
          onAlertOpen: onUnsavedChangesAlertOpen,
          onAlertContinue: () => handleUnsavedChangesAlertContinueClick(),
        }}
      />
      <ConfirmationAlert
        {...{
          failedPayment,
          isConfirmationAlertOpen,
          onConfirmationlertClose,
          handleConfirmUpdateAccClick,
        }}
      />
      <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />
    </BaselaneDrawer>
  );
}

export default UpdateBankAccountDrawer;
