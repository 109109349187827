import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, useDisclosure, useToast } from '@chakra-ui/react';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserContext from '@contexts/UserContext';
import BanksContext from '@contexts/BanksContext';
import { BaselaneApplicationTypePopup, BaselaneButtonCard } from '@shared/components';
import { HOME, LEASES, UNIFIED_LB_PERSONAL_INFO, UNIFIED_RENT_COLLECTION } from '@routes';
import historyStateStorage from '@core/storage/historyStateStorage';
import { disabledStylesWithBorder } from '@shared/components/BaselaneButtonCard/styles/baselaneButtonCard.styles';
import { IllustrationBankingNewaccount } from '@illustrations/index';
import {
  cardBodyStyles,
  cardTextContainerStyles,
  cardTitleStyles,
  iconContainerStyles,
  typeCardStyles,
} from '@shared/styles/typePopupButtons.styles';

type LandlordBankingButtonCardProps = {
  isFromUpdateAccount?: boolean,
  failedBankAccountId?: String,
  banksCount: Object,
};

const LandlordBankingButtonCard = ({
  isFromUpdateAccount = false,
  failedBankAccountId = null,
  banksCount = {},
}: LandlordBankingButtonCardProps) => {
  const { user } = useContext(UserContext);
  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const navigate = useNavigate();

  const { getKYCUrl } = useContext(BanksContext);

  const toast = useToast();
  const showToast = () =>
    toast({
      description: 'Your Rent Collection setup progress has been saved',
      status: 'success',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const handleActionButton = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const historyStateData = historyStateStorage.read('baselane-state');

    // if user opened lb flow from another flow from state will be an object
    const state = {
      from: {
        main: historyStateData?.from ?? HOME,
        flow: isFromUpdateAccount ? `${LEASES}` : UNIFIED_RENT_COLLECTION,
        params: isFromUpdateAccount ? { isFromUpdateAccount: true, failedBankAccountId } : null,
      },
    };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
      showToast();
    }
  };

  const handleOpenLandlordBankingPopup = () => {
    sendSegmentEvent(
      `${user.onboardingCompleted ? 'baselane' : 'onboarding'}_rc_click_connect_bank_account`,
      {
        landlord_uuid: user.id,
        landlord_name: user.firstName,
        landlord_email: user.email,
        bank_type: 'baselane',
        baselane_bank_account_count: banksCount.baselane,
      }
    );
    onBaselaneApplicationTypePopupOpen();
  };

  return (
    <>
      <BaselaneApplicationTypePopup
        handleActionButton={handleActionButton}
        isOpen={isBaselaneApplicationTypePopupOpen}
        onClose={onBaselaneApplicationTypePopupClose}
      />

      <BaselaneButtonCard
        onClick={handleOpenLandlordBankingPopup}
        type="clean"
        buttonStyles={{
          ...typeCardStyles,
          ...disabledStylesWithBorder,
        }}
        id="baselane-banking-button"
      >
        <Box {...iconContainerStyles}>
          <IllustrationBankingNewaccount />
        </Box>
        <Box {...cardTextContainerStyles}>
          <Text {...cardTitleStyles}>Baselane Banking Account</Text>
          <Text {...cardBodyStyles}>
            Open a banking account built for landlords in minutes, no fees or minimum balances.
          </Text>
        </Box>
      </BaselaneButtonCard>
    </>
  );
};

LandlordBankingButtonCard.defaultProps = {
  isFromUpdateAccount: false,
  failedBankAccountId: null,
};

export default LandlordBankingButtonCard;
