export const card = {
  container: {
    flexDir: { base: 'column-reverse', lg: 'row' },
    alignItems: 'flex-start',
    gap: { base: '40px', lg: '40px', '2lg': '80px' },
    h: '100%',
    textAlign: 'left',
    mt: { base: '8px !important', lg: '0 !important', '2lg': '0 !important' },
  },
  left: {
    container: {
      maxWidth: { base: '100%', lg: 'calc(60% - 20px)', '2lg': 'calc(50% - 40px)' },
      width: { base: '100%', lg: 'calc(60% - 20px)', '2lg': 'calc(50% - 40px)' },
    },
    title: {
      fontSize: '2xl',
      lineHeight: '32px',
      fontWeight: 'semibold',
      color: 'brand.neutral.900',
    },
    description: {
      textStyle: 'md',
      lineHeight: '24px',
      color: '#000',
      mt: '8px !important',
    },
    buttonContainer: {
      my: { base: '16px', lg: '40px' },
      justifyContent: { base: 'center', sm: 'space-between' },
    },
    spacer: {
      display: { base: 'none', sm: 'flex' },
    },
  },
  right: {
    maxWidth: { base: '100%', lg: 'calc(40% - 20px)', '2lg': 'calc(50% - 40px)' },
    width: { base: '100%', lg: 'calc(40% - 20px)', '2lg': 'calc(50% - 40px)' },
    minH: { base: '180px', sm: '240px' },
    m: '0 !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
