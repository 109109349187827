import React from 'react';
import { Box } from '@chakra-ui/react';
import { Icon16ChevronDown, Icon16ChevronUp } from '@icons/16px';
import { dropdownIconContainerStyles } from '../styles/dropdown.styles';

/**
 * Helper Functions
 */

export const onHandleKeyDownFn = (e, helpers) => {
  const { dropdownElementRef, searchRef, setOptions } = helpers;

  const dropdownElements = dropdownElementRef.current;
  const nodes = Array.from(dropdownElements?.getElementsByClassName('element') ?? []);

  if (
    e.event.key === 'ArrowUp' ||
    e.event.key === 'ArrowDown' ||
    e.event.key === 'Tab' ||
    e.event.key === 'Enter'
  ) {
    const currentIndex = nodes.indexOf(e.event.target);
    let newIndex = currentIndex;

    switch (e.event.key) {
      case 'ArrowDown':
      case 'Tab':
        newIndex = currentIndex + 1;
        break;
      case 'ArrowUp':
        newIndex = currentIndex - 1;
        break;

      default:
    }

    if (nodes[newIndex]) {
      nodes[newIndex].focus();
    } else if (newIndex < 0 && e.event.key === 'ArrowUp') {
      searchRef.current?.focus();
    } else {
      nodes[0]?.focus();
    }

    if (e.event.key === 'Enter' && nodes[currentIndex]?.id) {
      // NOTE: e.state.searchResults doesn't update properly when search is active
      setOptions(e.props.options, nodes[currentIndex]?.id);
    }
  }
};

export const getMultiSelectedItems = ({ itemToToggle, item, selectedStagedOptions }) =>
  itemToToggle
    ? selectedStagedOptions.filter((opt) => opt.id !== item.id)
    : [...selectedStagedOptions, item];

export const setIsSelectedItem = (item, selectedStagedOptions) =>
  selectedStagedOptions.some((opt) => opt.id === item.id);

export const getChildCounterVisualStyles = (hasChildrenSelected) => {
  if (hasChildrenSelected) {
    return { bgColor: 'brand.blue.50', color: 'brand.blue.800A' };
  }

  return { bgColor: 'inherit' };
};

export const getDropdownSize = ({ selectElem, parentElem, selectDropdown: _selectDropdown }) => {
  const selectDropdown = _selectDropdown;
  // If there isn't enough space on the right, open the dropdown to the left
  const availableWidth = parentElem.clientWidth;
  const availableSpaceOnLeft = selectElem.offsetLeft;
  const availableSpaceForDropdown = availableWidth - availableSpaceOnLeft;
  const dropdownWidth = selectDropdown.clientWidth;

  // If there isn't enough space on the bottom/top, adjust the height of the dropdown
  const availableHeight = parentElem.clientHeight;
  const selectHeight = selectElem.offsetHeight;
  const availableSpaceOnTopForParent = parentElem.getBoundingClientRect().top;
  const availableSpaceOnTopForSelect = selectElem.getBoundingClientRect().top;
  const availableSpaceOnTop = availableSpaceOnTopForSelect - availableSpaceOnTopForParent;
  const availableSpaceBetweenSelectAndDropdown = selectDropdown.offsetTop - selectElem.clientHeight;

  const availableSpaceOnBottomForDropdown =
    availableHeight - availableSpaceOnTop - selectHeight - availableSpaceBetweenSelectAndDropdown;
  const availableSpaceOnTopForDropdown =
    availableHeight -
    availableSpaceOnBottomForDropdown -
    selectHeight -
    availableSpaceBetweenSelectAndDropdown;
  const dropdownHeight = selectDropdown.clientHeight;

  const positionName =
    Array.from(selectDropdown.classList).find((classname) => classname.includes('position')) ?? '';
  const position = positionName.includes('top') ? 'top' : 'bottom';

  if (availableSpaceForDropdown < dropdownWidth) {
    selectDropdown.style.right = '0';
    selectDropdown.style.left = 'auto';
  }

  if (position === 'bottom' && availableSpaceOnBottomForDropdown < dropdownHeight) {
    selectDropdown.style.minHeight = `${availableSpaceOnBottomForDropdown}px`;
    selectDropdown.style.height = `${availableSpaceOnBottomForDropdown}px`;
  } else if (position === 'top' && availableSpaceOnTopForDropdown < dropdownHeight) {
    selectDropdown.style.minHeight = `${availableSpaceOnTopForDropdown}px`;
    selectDropdown.style.height = `${availableSpaceOnTopForDropdown}px`;
  }
};

/**
 * Helper Components
 */

export const customDropdownHandleRenderer = ({ state, isMobile }) => {
  let icon = state.dropdown ? <Icon16ChevronUp /> : <Icon16ChevronDown />;

  if (isMobile) {
    icon = <Icon16ChevronDown />;
  }
  return (
    <Box className="icon-carret" {...dropdownIconContainerStyles}>
      {icon}
    </Box>
  );
};
