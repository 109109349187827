import React from 'react';
import { Box } from '@chakra-ui/react';
import { IconChevronLeft, IconChevronRight } from '@icons';
import customTheme from '@core/theme';

type DotButtonProps = {
  index: Number,
  numberOfItems: Number,
  selectedIndex: Number,
  visibleDots: Array<Number>,
};

export const DotButton = ({ index, numberOfItems, selectedIndex, visibleDots }: DotButtonProps) => {
  const selected = index === selectedIndex;

  let type = '';
  if (numberOfItems >= 7) {
    if (visibleDots.indexOf(index) === 4 && index - 2 >= selectedIndex) {
      type = ' embla__dot--last';
    } else if (visibleDots.indexOf(index) >= 3 && index - 1 >= selectedIndex) {
      type = ' embla__dot--secondlast';
    } else if (visibleDots.indexOf(index) === 0 && index + 2 <= selectedIndex) {
      type = ' embla__dot--last';
    } else if (visibleDots.indexOf(index) <= 1 && index + 1 <= selectedIndex) {
      type = ' embla__dot--secondlast';
    }

    if (visibleDots.indexOf(index) === -1) {
      type = ' embla__dot--last embla__dot--hidden';
    }
  }

  return <Box className={'embla__dot'.concat(selected ? ' embla__dot--selected' : type)} />;
};

type PrevButtonProps = {
  enabled: Boolean,
  onClick: Function,
};
export const PrevButton = ({ enabled, onClick }: PrevButtonProps) => {
  const { blue } = customTheme.colors.brand;
  const { 700: blue700 } = blue;

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className="embla__button embla__button--prev"
      aria-label="Previous"
      onClick={onClick}
      disabled={!enabled}
    >
      <IconChevronLeft className="embla__button__svg" color={blue700} w={7} h={12} />
    </button>
  );
};

type NextButtonProps = {
  enabled: Boolean,
  onClick: Function,
};
export const NextButton = ({ enabled, onClick }: NextButtonProps) => {
  const { blue } = customTheme.colors.brand;
  const { 700: blue700 } = blue;

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className="embla__button embla__button--next"
      aria-label="Next"
      onClick={onClick}
      disabled={!enabled}
    >
      <IconChevronRight className="embla__button__svg" color={blue700} />
    </button>
  );
};
