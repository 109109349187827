import React from 'react';

type IconBankWebProps = { w?: string, h?: string, color?: string };
const IconBankWeb = ({ w, h, color }: IconBankWebProps) => {
  return (
    <svg width={w} height={h} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="34" cy="34" r="10" fill="#8CCFFF" />
      <circle cx="32" cy="33" r="10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M32 28c1.2506 1.3692 1.9614 3.146 2 5-.0386 1.854-.7494 3.6308-2 5m0-10c-1.2506 1.3692-1.9614 3.146-2 5 .0386 1.854.7494 3.6308 2 5m0-10c-2.7614 0-5 2.2386-5 5s2.2386 5 5 5m0-10c2.7614 0 5 2.2386 5 5s-2.2386 5-5 5m-4.75-6.5h9.5m-9.5 3h9.5M19.5 34H6.66667c-.93342 0-1.40013 0-1.75665-.1819-.31361-.16-.56858-.4153-.72836-.7294C4 32.7317 4 32.2644 4 31.3297v-1.3351c0-.9347 0-1.4021.18166-1.7591.15978-.314.41475-.5693.72836-.7293.35652-.1819.82323-.1819 1.75665-.1819H21M7.33333 13.9729v13.3514m7.49997-13.3514v13.3514m8.3334-13.3514V24.5m7.5-10.5271V20.5M18.4215 4.08801 6.08819 6.83247c-.74511.16581-1.11766.24871-1.39575.44933-.24529.17695-.43789.41738-.55719.69554C4 8.2927 4 8.67486 4 9.43917v1.86343c0 .9347 0 1.402.18166 1.759.15978.314.41475.5693.72836.7294.35652.1819.82323.1819 1.75665.1819H31.3333c.9335 0 1.4002 0 1.7567-.1819.3136-.1601.5686-.4154.7283-.7294.1817-.357.1817-.8243.1817-1.759V9.43917c0-.76431 0-1.14647-.1353-1.46182-.1192-.27817-.3119-.5186-.5571-.69555-.2781-.20062-.6507-.28352-1.3958-.44932L19.5785 4.08801c-.2159-.04803-.3238-.07205-.4329-.08163-.0969-.00851-.1943-.00851-.2912 0-.1091.00958-.217.0336-.4329.08163Z"
        stroke="#192C3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconBankWeb.defaultProps = {
  w: '48',
  h: '48',
  color: 'currentColor',
};

export default IconBankWeb;
