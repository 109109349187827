const inputPaddings = ({ size }) => {
  switch (size) {
    case 'lg':
      return '14px 16px';

    case 'md':
      return '10px 12px';

    case 'sm':
      return '6px 12px';

    default:
      return '14px 16px';
  }
};

const inputHeight = ({ size }) => {
  switch (size) {
    case 'lg':
      return '48px';

    case 'md':
      return '40px';

    case 'sm':
      return '32px';

    default:
      return '48px';
  }
};

export const datePickerInputStyles = ({ size, disabled }) => {
  const height = inputHeight({ size });

  return {
    input: {
      // TODO: once legacy datepicker fades out,
      //       apply paddings with spacing rule and delete !important
      p: `${inputPaddings({ size })} !important`,
      h: `${height} !important`,
    },
    iconContainer: {
      w: 'auto',
      h: height,
      color: disabled ? 'brand.neutral.400' : 'brand.neutral.500',
      pointerEvents: disabled ? 'none' : 'auto',
    },
    closeicon: {
      display: 'flex',
      minW: '24px',
      h: '24px',
      mx: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      cursor: disabled ? 'default' : 'pointer',
      color: disabled ? 'brand.neutral.400' : 'brand.neutral.700',
      _hover: {
        backgroundColor: disabled ? 'transparent' : 'brand.darkBlue.200',
      },
      _active: {
        backgroundColor: disabled ? 'transparent' : 'brand.darkBlue.250',
      },
    },
    calendaricon: {
      w: '16px',
      h: '16px',
    },
    inputlabel: {
      textStyle: 'xs',
    },
  };
};
