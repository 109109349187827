import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import BaselaneSummaryCardDefaultHeader from './BaselaneSummaryCardDefaultHeader';
import { baselaneSummaryCardStyles } from '../styles/baselaneSummaryCard.styles';

type BaselaneSummaryCardStateHeaderProps = {
  title: string | React.Node,
  subtitle?: string | React.Node,
  prefix?: string | React.Node,
  addon?: React.Node,
  state: string | React.Node,
  subtext?: string | React.Node,
  styles?: Object,
};

const BaselaneSummaryCardStateHeader = ({
  title,
  subtitle,
  prefix,
  addon,
  state,
  subtext,
  styles,
}: BaselaneSummaryCardStateHeaderProps) => {
  const {
    subtextLightStyles,
    cardStateContainerStyles,
    cardStateTitleStyles,
  } = baselaneSummaryCardStyles;

  return (
    <BaselaneSummaryCardDefaultHeader
      {...{
        title,
        subtitle,
        prefix,
        addon,
        rightElem: (
          <Stack {...cardStateContainerStyles}>
            {state}
            <Text {...{ ...subtextLightStyles, ...styles?.amount }}>{subtext}</Text>
          </Stack>
        ),
        styles: {
          title: cardStateTitleStyles,
        },
      }}
    />
  );
};

BaselaneSummaryCardStateHeader.defaultProps = {
  subtitle: null,
  prefix: null,
  addon: null,
  subtext: null,
  styles: {},
};

export default BaselaneSummaryCardStateHeader;
