import React, { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useGate } from 'statsig-react';
import { VStack, FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Icon16ArrowForward } from '@icons/16px';
import { formatNumbers } from '@shared/components/BaselaneKYCForm/helpers/kycForm.helpers';
import { BaselaneButton, TwoFactorVerificationPopUp } from '@shared/components';
import useTwoFactor from '@shared/components/TwoFactorVerificationPopUp/hooks/useTwoFactor';
import { phoneMaskWithCountryCode } from '@core/utils/masks';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import { inputContainerStyles } from '@core/pages/SignUpPage/styles/createaccount.style';
import {
  HOME,
  ONBOARDING_ADD_PROPERTY,
  ONBOARDING_PROPERTY_SURVEY,
  ONBOARDING_TRIAGE,
} from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import { additionalLabelStyle } from '../styles/phone.styles';

function Phone({ user, refetchUser }: { user: any, refetchUser: any }) {
  const { value: showAddProperty } = useGate('onboarding-add-property-gate');

  const { phoneNumber } = user;
  const formikRef = useRef();
  const initialFormValues = { phone: '' };

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [submittedPhoneNumber, setSubmittedPhoneNumber] = useState(null);
  const [finalSubmit, setFinalSubmit] = useState(false);

  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
  const [phoneNumberIsNotVoip, setPhoneNumberIsNotVoip] = useState(false);

  const [customPhoneError, setCustomPhoneError] = useState(null);

  const { states, stateFunctions } = useTwoFactor(true);
  const { OTPErrorCode } = states;
  const { onOTPPopupOpen, setOTPErrorCode, handleSendText, handleVerifyOtp } = stateFunctions;

  const redirect = () => {
    const { onboardingCompleted } = user || {};
    if (onboardingCompleted) {
      navigate({ pathname: HOME });
    } else if (!user.metadata?.onboardingSurvey) {
      navigate({ pathname: ONBOARDING_PROPERTY_SURVEY });
    } else if (showAddProperty) {
      navigate({ pathname: ONBOARDING_ADD_PROPERTY });
    } else {
      navigate({ pathname: ONBOARDING_TRIAGE });
    }
  };

  const handleValidation = (values) => {
    const { phone } = values;
    const errors = {};

    const filterPhoneNumber = phone?.replace(/[^0-9]/g, '');

    if (filterPhoneNumber === '' || filterPhoneNumber?.length < 11) {
      errors.phone = 'Please enter phone number';
      setOTPErrorCode(false);
    } else if (OTPErrorCode === 400) {
      errors.phone = customPhoneError;
    }

    return errors;
  };

  const handleSendTextSuccess = () => {
    setOTPErrorCode(false);
    onOTPPopupOpen();
    setPhoneNumberIsNotVoip(true);
  };

  const handleSendTextFail = (error) => {
    setCustomPhoneError(error);
  };

  const handleVerifyOtpSuccess = () => {
    setPhoneNumberVerified(true);
  };

  const handleFormSubmit = (e) => {
    const { phone } = e;
    const formattedPhoneNumber = phone
      ? formatNumbers(phone).numberWithoutSpaces
      : submittedPhoneNumber;
    setSubmittedPhoneNumber(formattedPhoneNumber);

    if (phoneNumberVerified && phoneNumberIsNotVoip) {
      setIsLoading(true);
      refetchUser().then(() => {
        setIsLoading(false);
        redirect();
      });
    } else {
      handleSendText(formattedPhoneNumber, false, handleSendTextSuccess, handleSendTextFail);
    }
  };

  const onClickSubmit = (e) => {
    sendSegmentEvent('onboarding_phonenumber_continue', {
      email: user.email,
      phone_number: phoneNumber,
    });
    handleFormSubmit(e);
  };

  useEffect(() => {
    // effectuate any new inline errors after OTP (otherwise it doesn't pick up the 101 or 102 after submit)
    if (OTPErrorCode === 101 || OTPErrorCode === 102) {
      formikRef?.current?.validateForm();
    }
    if (user.phoneNumber !== '' && user.phoneNumber !== null && user.phoneNumberVerified) {
      redirect();
    }
    if (phoneNumberVerified && !finalSubmit) {
      handleFormSubmit({ submittedPhoneNumber });
      setFinalSubmit(true);
    }
  });

  // TODO: this is from BaselaneKYCForm, store in helper and reuse
  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP: () => handleSendText(submittedPhoneNumber, true, handleSendTextSuccess),
    phoneNumber: formikRef?.current?.values.phone,
    handleVerifyOnClick: (otpCode) =>
      handleVerifyOtp({ recipient: submittedPhoneNumber, code: otpCode }, handleVerifyOtpSuccess),
  };

  return (
    <>
      <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />
      <Formik
        innerRef={formikRef}
        validate={(values) => handleValidation(values)}
        initialValues={initialFormValues}
        onSubmit={onClickSubmit}
        validateOnChange
        validateOnMount
      >
        {({ values, handleSubmit, isValid, handleBlur, handleChange, errors, touched }) => (
          <VStack {...{ w: '100%' }}>
            <FormControl
              {...{
                ...inputContainerStyles,
                mb: '16px !important',
              }}
              isInvalid={(errors.phone && touched.phone) || customPhoneError}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            >
              <FormLabel htmlFor="phone" {...{ ...formLabelStyles.xs, mb: '10px' }}>
                Mobile Phone (no <abbr title="Voice Over Internet Protocol">VOIP</abbr>)
              </FormLabel>
              <Input
                {...formInputStyles}
                as={MaskedInput}
                mask={phoneMaskWithCountryCode('1')}
                id="phone"
                name="phone"
                value={values.phone}
                onChange={(event) => {
                  if (customPhoneError) {
                    setCustomPhoneError(null);
                  }
                  handleChange(event);
                }}
                onBlur={handleBlur}
                placeholder="+1 (***) *** ****"
                pattern="\d*"
                inputMode="decimal"
                autoFocus
              />
              <FormErrorMessage {...{ ...formErrorStyles, ml: 0 }}>
                {errors.phone || customPhoneError}
              </FormErrorMessage>
              <FormLabel {...additionalLabelStyle}>
                We’ll text you to confirm your number.
              </FormLabel>
            </FormControl>
            <BaselaneButton
              id="signUpButton" // add for consultants for growth team
              variant="filled"
              palette="primary"
              size="lg"
              onClick={handleSubmit}
              isDisabled={!isValid}
              rightIcon={!isMobile && <Icon16ArrowForward />}
              isLoading={isLoading}
              styles={{ alignSelf: 'flex-start' }}
            >
              Continue
            </BaselaneButton>
          </VStack>
        )}
      </Formik>
    </>
  );
}

export default Phone;
