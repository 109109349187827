// @flow
import React from 'react';
import { HStack, Text, Box } from '@chakra-ui/react';
import { IconBanksAndCards, IconCircle } from '@icons';
import { BaselaneBadge } from '@shared/components';
import customTheme from '@core/theme';

type CardStatusBadgeProps = {
  isLocked?: Boolean,
  isInactive?: Boolean,
  isDisabled?: Boolean,
};

const statusTextStyles = {
  p: '0',
  m: '0 0 0 6px !important',
  textTransform: 'capitalize',
  textStyle: 'xs',
  color: 'brand.neutral.700',
};

const statusBadgeStyles = {
  p: '4px 8px !important',
  m: '0 !important',
  h: '24px',
  borderRadius: '4px',
};

const CardStatusBadge = ({
  isLocked = false,
  isInactive = false,
  isDisabled = false,
}: CardStatusBadgeProps) => {
  if (isInactive) {
    return (
      <BaselaneBadge
        type="badge"
        color={isDisabled ? 'lightgray' : 'blue'}
        backgroundStyles={{ ...statusBadgeStyles, p: '6px 12px !important', h: 'auto' }}
      >
        <HStack as="span">
          <IconBanksAndCards {...{ w: 13, h: 13 }} />
          <Box {...{ ...statusTextStyles, color: 'inherit', lineHeight: '20px' }}>
            Activate Card
          </Box>
        </HStack>
      </BaselaneBadge>
    );
  }

  return (
    <BaselaneBadge
      type="badge"
      color={isLocked ? 'red' : 'green'}
      backgroundStyles={statusBadgeStyles}
    >
      <HStack as="span">
        <IconCircle
          {...{
            color: isLocked ? customTheme.colors.red['800AA'] : customTheme.colors.green['800AA'],
            w: 6,
            h: 6,
          }}
        />
        <Text as="span" {...statusTextStyles}>
          {isLocked ? 'Locked' : 'Active'}
        </Text>
      </HStack>
    </BaselaneBadge>
  );
};

export default CardStatusBadge;
