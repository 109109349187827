import React from 'react';

function IconMoneySignRoundArrow({ w, h }: { w?: Number, h?: Number }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 33"
    >
      <g clipPath="url(#a)">
        <path
          d="M1.26 11.61a.9.9 0 0 1 1.14-.54c.47.15.72.65.56 1.11a11.89 11.89 0 0 0-.67 3.93c0 6.7 5.55 12.13 12.37 12.13 6.82 0 12.37-5.44 12.37-12.13 0-6.2-4.79-11.34-10.93-12.04l1.2.66c.44.24.6.77.35 1.2a.9.9 0 0 1-1.22.33l-4.21-2.3a.88.88 0 0 1-.46-.77c0-.32.17-.61.46-.77l4.21-2.3a.9.9 0 0 1 1.22.33c.24.42.09.96-.34 1.2l-1.2.65c3.24.32 6.23 1.71 8.56 4a13.66 13.66 0 0 1 0 19.63A14.21 14.21 0 0 1 14.66 30c-3.78 0-7.34-1.44-10.01-4.07a13.66 13.66 0 0 1-3.39-14.32Z"
          fill="#192C3E"
        />
        <path
          d="M14.69 10.06c-2.25 0-4.06 1.18-4.06 2.63s1.81 2.62 4.06 2.62c2.24 0 4.06 1.18 4.06 2.63s-1.82 2.62-4.06 2.62m0-10.5v10.5m0-10.5c1.5 0 2.81.53 3.52 1.31m-3.52-1.3V8.74m0 11.81v1.32m0-1.32c-1.5 0-2.82-.53-3.52-1.31"
          stroke="#192C3E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="matrix(-1 0 0 1 29.32 0)" d="M0 0h29.32v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconMoneySignRoundArrow.defaultProps = {
  w: 32,
  h: 32,
};

export default IconMoneySignRoundArrow;
