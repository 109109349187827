import React, { useRef } from 'react';
import { Spacer, Text, Flex } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useLazyQuery } from '@apollo/client';
import useBankSummary from '@core/hooks/useBankSummary';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import { GET_UPDATE_PLAID_LINK_TOKEN } from '@core/apollo/queries';
import Amount from './amount/Amount';
import TransferFromAccount from './transfer-from-account/TransferFromAccount';
import DepositToAccount from './deposit-to-account/DepositToAccount';
import EducationalDrawer from '../../../TransferFunds/components/EducationalDrawer';
import AddAccountManuallyDrawer from '../../../AddAccountManuallyDrawer';

const TransferDetails = () => {
  const { validateForm } = useFormikContext();
  const educationalDrawerRef = useRef(null);
  const closeEducationalDrawer = () => educationalDrawerRef?.current?.close();
  const openEducationalDrawer = () => educationalDrawerRef?.current?.open();
  const addAccountManuallyDrawerRef = useRef(null);

  const { refetch: refetchActiveBankAccounts } = useBankAccountsActive({
    variables: {
      isConnectedAccount: true,
      accountStatus: 'Open',
    },
    fetchPolicy: 'network-only',
  });

  const [
    updateLinkToken,
    { data: updatedLinkTokenData, loading: updateLinkTokenIsLoading },
  ] = useLazyQuery(GET_UPDATE_PLAID_LINK_TOKEN, {
    onCompleted: () => validateForm(),
  });

  const { refetch: refetchBankSummary } = useBankSummary();

  const handleAddAccountManuallySuccess = async () => {
    refetchBankSummary();
    refetchActiveBankAccounts();
  };

  return (
    <>
      <Flex>
        <Text textStyle="md-heavy">Transfer details</Text>
        <Spacer />
        <Text textStyle="xs">Step 1 of 4</Text>
      </Flex>
      <Text textStyle="sm">
        Transfer money between your Baselane accounts (instant) or between your Baselane and
        external accounts (3 business days).
      </Text>
      <TransferFromAccount
        openEducationalDrawer={openEducationalDrawer}
        updatedLinkTokenData={updatedLinkTokenData}
        updateLinkTokenIsLoading={updateLinkTokenIsLoading}
        updateLinkToken={updateLinkToken}
      />
      <DepositToAccount
        openEducationalDrawer={openEducationalDrawer}
        updatedLinkTokenData={updatedLinkTokenData}
        updateLinkTokenIsLoading={updateLinkTokenIsLoading}
        openManualAccountDrawer={() => addAccountManuallyDrawerRef.current.open()}
        updateLinkToken={updateLinkToken}
      />
      <Amount />
      <EducationalDrawer
        hideButton
        buttonContent={{
          id: 0,
          title: 'Account & routing numbers',
          description: 'Use another bank to initiate a Wire or ACH transfer to Baselane',
          defaultType: 'ach',
          methodTitle: 'Select Bank Transfer Type',
          segmentEvent: 'baselane_banking_select_add_fund',
          method: 'external_transfer',
        }}
        educationalDrawerRef={educationalDrawerRef}
        handleDrawerClose={closeEducationalDrawer}
        handleDrawerOpen={openEducationalDrawer}
      />
      <AddAccountManuallyDrawer
        addAccountManuallyDrawerRef={addAccountManuallyDrawerRef}
        handleAddAccountManuallySuccess={handleAddAccountManuallySuccess}
        from={{ section: 'add_funds' }}
      />
    </>
  );
};

export default TransferDetails;
