import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { IconCheckCircleOutline, IconPadlock, IconShare } from '@icons';
import getBreakPoints from '@core/utils/getBreakPoints';
import customTheme from '@core/theme';
import { bodyTextMediumBoldStyles } from '@core/components/OnboardingTriage/styles/fonts.styles';
import userContext from '@contexts/UserContext';
import { LEASES } from '@routes';
import { BaselaneButton, BaselaneShareLink } from '@shared/components';
import SuccessCardUI from './SuccessCardUI';
import SuccessActionButtons from './SuccessActionButtons';
import {
  headingTextStyles,
  largeLightBoldTextStyles,
  smallLightBodyTextStyles,
  smallLightDarkBodyTextStyles,
  xxSmallBoldTextStyles,
} from './styles/font.styles';
import {
  protectedMessageContainerStyles,
  successBannerContainerStyles,
  successBannerTextContainerStyles,
  successBottomSectionContainerStyles,
  successBottomTextContainerStyles,
  successContainerStyles,
} from './styles/successUI.styles';

const SuccessUI = () => {
  const { isMax768: isMobile } = getBreakPoints();
  const { user } = useContext(userContext);
  const navigate = useNavigate();
  const tenantInvite = JSON.parse(localStorage.getItem('tenantInvite'));
  const { inviteLink } = tenantInvite || {};
  const isActiveTenant = JSON.parse(localStorage.getItem('isActiveTenant'));

  const copyInviteButton = (
    <BaselaneShareLink
      {...{
        link: inviteLink,
        type: 'copy',
        text: { default: 'Copy Link', copied: 'Copied' },
        buttonProps: {
          variant: 'filled',
          palette: 'primary',
          size: isMobile ? 'lg' : 'md',
          icon: {
            color: '#fff',
          },
        },
      }}
    />
  );

  return (
    <Stack {...successContainerStyles(isMobile)}>
      <Box bgColor={(user?.userKycStatus === 'UNVERIFIED' || !isMobile) && 'brand.darkBlue.50'}>
        <Stack direction="row" {...successBannerContainerStyles(isMobile)}>
          <Stack {...successBannerTextContainerStyles(isMobile)}>
            <IconCheckCircleOutline
              w={isMobile ? 80 : 40}
              h={isMobile ? 80 : 40}
              color={customTheme.colors.green['800AA']}
            />
            <Stack spacing="8px">
              <Text {...largeLightBoldTextStyles}>Congrats!</Text>
              {user?.onboardingCompleted ? (
                <Text {...headingTextStyles(isMobile)}>Rent collection is set up</Text>
              ) : (
                <Text {...headingTextStyles(isMobile)}>
                  You have set up your first rent collection on Baselane
                </Text>
              )}
              {isActiveTenant ? (
                <Text {...smallLightDarkBodyTextStyles}>
                  We have notified your tenant by email & SMS.
                </Text>
              ) : (
                <Text {...smallLightDarkBodyTextStyles}>
                  {`We have sent an email & SMS invitation to your Tenant. ${
                    inviteLink
                      ? 'You can also copy the invitation link below to share directly with your Tenant.'
                      : ''
                  }`}
                </Text>
              )}
            </Stack>
          </Stack>
          <SuccessCardUI />
        </Stack>
        {!isMobile && (
          <Box {...successBannerContainerStyles(isMobile)} align="none">
            {inviteLink && !isActiveTenant && (
              <>
                <HStack>
                  <IconShare w={16} h={16} color="#3A4B5B" />
                  <Text {...bodyTextMediumBoldStyles}>Share with Tenant</Text>
                </HStack>
                <HStack mt="8px" w={{ base: 'auto', sm: 'calc(50% - 41px)' }}>
                  {copyInviteButton}
                </HStack>
              </>
            )}

            {user?.userKycStatus !== 'UNVERIFIED' && (
              <>
                <Divider h="32px" mb="32px" />
                <BaselaneButton
                  id="back-to-rc-button"
                  size="lg"
                  variant="outline"
                  palette="neutral"
                  onClick={() => {
                    localStorage.removeItem('tenantInvite');
                    localStorage.removeItem('isActiveTenant');
                    localStorage.removeItem('unit');
                    navigate(LEASES);
                  }}
                >
                  Back to Rent Collection
                </BaselaneButton>
              </>
            )}
          </Box>
        )}
        {isMobile && inviteLink && !isActiveTenant && (
          <>
            <HStack mx="24px">
              <IconShare w={16} h={16} color="#3A4B5B" />
              <Text {...bodyTextMediumBoldStyles}>Share with Tenant</Text>
            </HStack>
            <HStack m="8px 24px 0 24px !important">{copyInviteButton}</HStack>
          </>
        )}
      </Box>
      <Box h="100%">
        <Stack {...successBottomSectionContainerStyles(isMobile)}>
          <Stack {...successBottomTextContainerStyles}>
            {user?.userKycStatus === 'UNVERIFIED' && (
              <>
                <Text {...headingTextStyles(isMobile)}>
                  One final step required to receive payments
                </Text>
                <Text {...smallLightBodyTextStyles}>
                  We require that you verify your identity before we deposit the rent payments into
                  your bank account.
                </Text>
                <Text {...smallLightBodyTextStyles}>
                  We do not share or store any of your personal information.
                </Text>
              </>
            )}
          </Stack>
          <Stack spacing="10px">
            {user?.userKycStatus === 'UNVERIFIED' && (
              <Stack direction="row" {...protectedMessageContainerStyles}>
                <IconPadlock color="currentColor" />
                <Text {...xxSmallBoldTextStyles}>
                  Your information is protected with 256-bit SSL encryption.
                </Text>
              </Stack>
            )}
            <SuccessActionButtons />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default SuccessUI;
