import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { BaselaneChip, BaselaneTooltip } from '@shared/components';
import { Icon16Property } from '@icons/16px';
import { generateList } from './tagList.helpers';
import { tooltipTitleText } from './accountTag.styles';

type RentCollectionChipProps = {
  activeRentCollection: Array<Object>,
};

const RentCollectionChip = ({ activeRentCollection }: RentCollectionChipProps) => {
  const leaseList = (
    <Stack>
      <Text {...tooltipTitleText}>Collecting Rent & Fees For:</Text>
      {generateList(activeRentCollection)}
    </Stack>
  );

  return (
    <BaselaneTooltip label={leaseList} placement="bottom-start">
      <BaselaneChip size="md" variant="simple-neutral" leftIcon={Icon16Property} />
    </BaselaneTooltip>
  );
};

export default RentCollectionChip;
