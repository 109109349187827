// @flow
import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Box, Flex, Spinner, TabPanels, TabPanel, Text, ChakraProvider } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import formatCurrency from '@core/utils/formatCurrency';
import UserContext from '@contexts/UserContext';
import {
  BaselaneButton,
  BaselaneDivider,
  TabsComponent,
  TabComponent,
  TabListComponent,
  TabNumber,
} from '@shared/components';
import BaselaneResponsiveCard from '@shared/components/BaselaneResponsiveCard/BaselaneResponsiveCard';
import { IconGoEdit } from '@icons';
import { Icon16PlusCircle } from '@icons/16px';
import LeaseTermsForm from '@pages/LeasesPage/LeaseSection/forms/LeaseTermsForm';
import DepositsFeesForm from '@pages/LeasesPage/LeaseSection/forms/LeaseTermsForm/DepositsFeesForm';
import ReceivingBankAccountForm from '@pages/LeasesPage/LeaseSection/forms/ReceivingBankAccountForm';
import ReviewLeaseForm from '@pages/LeasesPage/LeaseSection/forms/LeaseResponsiveReview/CreateLeaseReview';
import TenantDetailsForm from '@pages/LeasesPage/LeaseSection/forms/TenantDetailsForm';
import TabIcon from '@shared/components/BaselaneFlow/Tabs/TabComponent/TabIcon';
import { FormTitle, FormDescription } from '@shared/components/BaselaneFlow';
import {
  isOptionDisabled,
  tabListMenu,
} from '@shared/components/BaselaneFlow/Tabs/helpers/tab.helpers';
import type { LeaseSection, PropertyUnitType } from '@pages/LeasesPage/types';
import { segmentEventNames } from '@pages/LeasesPage/LeaseSection/helpers/createLease.helpers';
import { wordifyDate } from '@pages/LeasesPage/LeaseSection/formHelpers/leaseTermForm.helper';
import AddProperty from '@core/components/OnboardingTriage/AddProperty';
import {
  cStyles,
  headerStyles,
} from '@core/components/OnboardingTriage/styles/flowSections.styles';
import {
  invoiceCardHeaderStyles,
  invoiceCardTextStyles,
  unitCardStyles,
  invoiceListLoadMoreStyles,
} from '@shared/components/BaselaneResponsiveCard/styles/card.style';
import {
  tabListStyles,
  tabPanelResponsiveStyles,
  tabsStyles,
  tabPanelsResponsiveStyles,
  tabPanelsWrapperStyles,
  dashStyles,
  addAnotherLinkStyles,
  panelHeaderResponsiveStyles,
  panelHeaderContainerStyles,
  panelDescriptionResponsiveStyles,
  panelHeaderWrapperResponsiveStyles,
  panelContentResponsiveStyles,
  panelFormResponsiveStyles,
  addressSectionStyles,
  addressSectionLabelTextStyles,
  addressSectionLabelContainerStyles,
  addressSectionFormStyles,
  addressSectionContentStyles,
  addressSectionContentLeftStyles,
  addressSectionContentRightStyles,
  propertiesSpinnerStyles,
  invoicesSpinnerStyles,
} from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import habitatTheme from '@core/themeHabitat';
import BaselaneUnitRadioCard from './components/BaselaneUnitRadioCard';
import FooterWrapper from './FooterWrapper';
import { LEASE_TABS } from './initialMenuState';

type LeaseFormTabsProps = {
  leaseCreated: boolean,
  leaseMenuOptions: Array<LeaseSection>,
  unitState: string,
  saveTenantProfileId: Function,
  formVariables: Object,
  setFormVariables: Function,
  unit: PropertyUnitType,
  selectedProperty: Object,
  tabIndex: number,
  setTabIndex: Function,
  formRefs: any,
  existingTenantState: Object,
  setExistingTenantState: Function,
  bankProps?: Object,
  handlePropertyUnitSelected: Function,
  handleCreateLease: Function,
  saveLease: Function,
  sortedProperties: Array,
  radioValue: String,
  setRadioValue: Function,
  hasConnectedBank: boolean,
  setHasConnectedBank: Function,
  showReceivingBAEmptyState: boolean,
  setShowReceivingBAEmptyState: Function,
  selectingUnitPanel: boolean,
  setSelectingUnitPanel: Function,
  addingPropertyPanel: boolean,
  setAddingPropertyPanel: Function,
  handleAddedProperty: Function,
  saving: boolean,
  handleDeleteLease: Function,
  clickedPropertyUnit: any,
  loadingObject: Object,
};

function LeaseFormTabs({
  leaseCreated,
  leaseMenuOptions,
  unitState,
  saveTenantProfileId,
  formVariables,
  setFormVariables,
  unit,
  selectedProperty,
  tabIndex,
  setTabIndex,
  formRefs,
  existingTenantState,
  setExistingTenantState,
  bankProps,
  handlePropertyUnitSelected,
  handleCreateLease,
  saveLease,
  sortedProperties,
  radioValue,
  setRadioValue,
  hasConnectedBank,
  setHasConnectedBank,
  showReceivingBAEmptyState,
  setShowReceivingBAEmptyState,
  selectingUnitPanel,
  setSelectingUnitPanel,
  addingPropertyPanel,
  setAddingPropertyPanel,
  handleAddedProperty,
  saving,
  handleDeleteLease,
  clickedPropertyUnit,
  loadingObject,
}: LeaseFormTabsProps): any {
  const { isMax576, isMax768 } = getBreakPoints();
  const additionalFeeFormRef = useRef();
  const monthlyFeeFormRef = useRef();
  const { user } = useContext(UserContext);
  const { refetchBankAccounts } = bankProps || {};
  const [loadMoreNumber, setLoadMoreNumber] = useState(1);

  // this is for the fees forms, after they are auto-saved, save the lease
  const [resaveLease, setResaveLease] = useState(false);

  const handleSegmentEventRequest = (tabI) =>
    sendSegmentEvent(segmentEventNames[tabI], {
      landlord_uuid: user.id,
      lease_uuid: unit?.lease?.id,
      property_uuid: unit?.lease?.propertyUnitId,
      unit_uuid: unit.id,
      landlord_name: user.firstName,
      landlord_email: user.email,
    });

  const hasPreviousTabErrors = (i) => {
    return (
      formRefs?.forEach((formTest, index) => {
        let firstError = true;
        if (index < i) {
          return formTest?.current?.validateForm().then((errorsTest) => {
            if (Object.keys(errorsTest).length > 0 && firstError) {
              if (index === LEASE_TABS.TENANT && !existingTenantState?.newTenant) {
                // ignore new tenant form errors if existing tenant set
              } else {
                setTabIndex(index);
                firstError = false;
              }
            }
            return Object.keys(errorsTest).length > 0;
          });
        }
        return false;
      })?.length > 0
    );
  };

  useEffect(() => {
    // Refetch all banks and bank accounts when the bank tab is clicked.
    if (tabIndex === LEASE_TABS.ACCOUNTS) {
      refetchBankAccounts?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  const handleTabChange = (i) => {
    // fee boxes are not part of default form, check these first
    if (tabIndex === LEASE_TABS.TERMS && monthlyFeeFormRef?.current) {
      // block tab clicks, user added a fee but didn't save, can only press Continue
    } else if (tabIndex === LEASE_TABS.DEPOSITS && additionalFeeFormRef?.current) {
      // block tab clicks, user added a fee but didn't save, can only press Continue
    } else {
      const form = formRefs[tabIndex];
      const newTenant =
        Object.keys(unit?.lease?.tenantProfileMetadata || {}).length > 0 ||
        unit?.lease?.tenantProfileId === null;

      // allow back on unit panel
      if (i === LEASE_TABS.PROPERTY) {
        setSelectingUnitPanel(false);
      }

      if (form && formRefs?.length > 0 && unit?.lease?.id) {
        // validate form before moving on to next tab if form is not valid do not move to next tab
        form?.current?.validateForm().then((errors) => {
          if (i < tabIndex) {
            setTabIndex(i);
            // don't allow saving if you have errors on the page
            if (Object.keys(errors).length === 0) {
              saveLease();
            }
          } else if (i > tabIndex && hasPreviousTabErrors(i)) {
            // don't allow going forward if there's an error behind ya
          } else if (
            tabIndex === LEASE_TABS.TENANT &&
            (!newTenant ||
              (existingTenantState.newTenant !== undefined && !existingTenantState.newTenant))
          ) {
            if (newTenant || !existingTenantState.isInvalid) {
              setExistingTenantState((prevState) => ({ ...prevState, changeTab: true }));
              setTabIndex(i);
              if (unitState !== 'READY_TO_BE_REVIEWED') handleSegmentEventRequest(tabIndex);
            }
          } else if (Object.keys(errors).length === 0) {
            setTabIndex(i);
            saveLease();
            if (unitState !== 'READY_TO_BE_REVIEWED') handleSegmentEventRequest(tabIndex);
          } else {
            form?.current.setTouched(errors, true);
          }
        });
        // On UnifiedRCFlow the first tab panel (choose property and unit) is not a form.
        if (
          !form?.current &&
          (tabIndex === LEASE_TABS.PROPERTY || tabIndex === LEASE_TABS.INVOICES) &&
          formRefs?.length > 0
        ) {
          if (!hasPreviousTabErrors(i)) {
            setTabIndex(i);
          }
        }
      } else if (unitState === 'READY_TO_BE_REVIEWED') {
        setTabIndex(i);
      }
    }
  };

  useEffect(() => {
    // resave lease after we clicked Continue and submit-saved an open fee box
    if (resaveLease) {
      const additionalFeesOpenComplete =
        additionalFeeFormRef?.current &&
        additionalFeeFormRef?.current?.values?.description &&
        additionalFeeFormRef?.current?.values?.amount &&
        additionalFeeFormRef?.current?.values?.dueDate;
      const monthlyFeesOpenComplete =
        monthlyFeeFormRef?.current &&
        monthlyFeeFormRef?.current?.values?.description &&
        monthlyFeeFormRef?.current?.values?.amount;
      if (additionalFeesOpenComplete) {
        additionalFeeFormRef.current.submitForm();
      } else if (monthlyFeesOpenComplete) {
        monthlyFeeFormRef.current.submitForm();
      } else if (tabIndex === LEASE_TABS.TERMS && !monthlyFeeFormRef?.current) {
        // we're saving lease and moving one tab further
        setTabIndex(LEASE_TABS.TERMS + 1);
        saveLease();
      } else if (tabIndex === LEASE_TABS.DEPOSITS && !additionalFeeFormRef?.current) {
        // we're saving lease and moving one tab further
        setTabIndex(LEASE_TABS.DEPOSITS + 1);
        saveLease();
      } else {
        saveLease();
      }
      setResaveLease(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resaveLease]);

  useEffect(() => {
    // Rerender once unit lease is loaded.
  }, [unit?.lease?.id]);

  const tabComponentProps = (option, index) => ({
    showDisabledStyles:
      (isOptionDisabled({ option, index, unitState, menuOptions: leaseMenuOptions }) &&
        index > 1 &&
        index !== tabIndex) ||
      (index === 1 && tabIndex === 0 && !unit?.id),
    isValidEnabled:
      (option.isValid &&
        !isOptionDisabled({ option, index, unitState, menuOptions: leaseMenuOptions })) ||
      (index === 0 && !!unit?.id),
    unitState,
    menuOptions: leaseMenuOptions,
    option,
    index,
  });

  const isFirstPanelAndValid = (index) => index === 0 && !!unit?.id;
  const isFirstPanelAndInvalid = (index) => index === 0 && !unit?.id;

  // address
  const address = selectedProperty?.address;
  const propertyAddress =
    address === null
      ? ''
      : `${address?.address}, ${!address?.unit ? '' : `${address?.unit}, `} ${address?.city}, ${
          address?.state
        } ${address?.zipCode}`;

  const footerVars = {
    unit,
    unitState,
    selectedProperty,
    leaseCreated,
    handleCreateLease,
    saveLease,
    leaseMenuOptions,
    tabIndex,
    setTabIndex,
    formRefs,
    existingTenantState,
    setExistingTenantState,
    radioValue,
    selectingUnitPanel,
    setSelectingUnitPanel,
    addingPropertyPanel,
    setAddingPropertyPanel,
    hasConnectedBank,
    showReceivingBAEmptyState,
    setShowReceivingBAEmptyState,
    additionalFeeFormRef,
    monthlyFeeFormRef,
    handleDeleteLease,
    clickedPropertyUnit,
    setResaveLease,
  };
  const sortedInvoices = (formVariables?.invoice || [])
    .sort((a, b) => a.amount - b.amount)
    .sort((a, b) => moment(a.dueDate).format('YYYYMMDD') - moment(b.dueDate).format('YYYYMMDD'));

  return (
    <TabsComponent
      {...{
        tabIndex,
        styles: tabsStyles,
        handleTabChange,
      }}
      styles={{ minHeight: 'unset', height: '100%', p: 0 }}
    >
      {!isMax768 && (
        <TabListComponent styles={tabListStyles}>
          {tabListMenu(leaseMenuOptions).map((option, index) => (
            <TabComponent key={option.id} {...tabComponentProps(option, index)}>
              {((option.isValid && index > 0) || isFirstPanelAndValid(index)) && (
                <TabIcon
                  unitState={unitState}
                  menuOptions={leaseMenuOptions}
                  option={option}
                  index={index}
                  tabIndex={tabIndex}
                  showGreenCircle={option.isValid || isFirstPanelAndValid(index)}
                />
              )}
              {((!option.isValid && index > 0) || isFirstPanelAndInvalid(index)) && (
                <TabNumber
                  unitState={unitState}
                  menuOptions={leaseMenuOptions}
                  option={option}
                  index={index}
                />
              )}
              {isMax768 && index < 5 && <BaselaneDivider styles={{ ...dashStyles }} />}
              {!isMax768 && <Text ml="8px">{option.label}</Text>}
            </TabComponent>
          ))}
        </TabListComponent>
      )}

      <Flex direction="column" justifyContent="left" {...tabPanelsWrapperStyles}>
        <Box pl={isMax768 ? '24px' : '40px'}>
          <FormTitle
            title={`STEP ${tabIndex + 1} of 7`}
            textStyles={{ ...headerStyles, pl: '0' }}
            containerStyles={cStyles}
          />
        </Box>
        <TabPanels {...tabPanelsResponsiveStyles(isMax768)}>
          <TabPanel {...tabPanelResponsiveStyles(isMax576, isMax768)}>
            {!addingPropertyPanel && (
              <Box paddingBottom="32px">
                <Box {...panelHeaderWrapperResponsiveStyles(isMax768)}>
                  <FormTitle
                    title={
                      sortedProperties[0]
                        ? `Select the ${
                            selectingUnitPanel ? 'unit' : 'property'
                          } to set up rent collection`
                        : 'Add your first property'
                    }
                    textStyles={panelHeaderResponsiveStyles(isMax768)}
                    containerStyles={panelHeaderContainerStyles(isMax768)}
                  />
                  <FormDescription styles={panelDescriptionResponsiveStyles(isMax768)}>
                    {selectingUnitPanel
                      ? 'To add or edit units, go to the Properties page, and then return to the rent collection setup.'
                      : 'For multi-unit properties, select the property, then continue to choose the unit.'}
                  </FormDescription>
                </Box>
                {!loadingObject.loading ? (
                  <Box {...panelFormResponsiveStyles(isMax768)} mb={isMax768 ? '96px' : '40px'}>
                    <ChakraProvider theme={habitatTheme}>
                      {sortedProperties?.map(
                        (property) =>
                          property && (
                            <BaselaneUnitRadioCard
                              key={`property-card-${property.id}`}
                              id={`property-card-${property.id}`}
                              property={property}
                              onUnitClick={handlePropertyUnitSelected}
                              setValue={setRadioValue}
                              value={radioValue}
                              selectingUnitPanel={selectingUnitPanel}
                              selectedProperty={selectedProperty}
                              saving={saving}
                              clickedPropertyUnit={clickedPropertyUnit}
                            />
                          )
                      )}
                    </ChakraProvider>
                    {!selectingUnitPanel && (
                      <Box style={{ ...addAnotherLinkStyles }}>
                        <BaselaneButton
                          id="unified-rc-add-property"
                          pullLeft
                          variant="transparent"
                          palette="primary"
                          leftIcon={<Icon16PlusCircle />}
                          size="md"
                          onClick={() => setAddingPropertyPanel(true)}
                        >
                          {sortedProperties[0] ? 'Add another property' : 'Add a property'}
                        </BaselaneButton>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Flex justify="center">
                    <Spinner {...propertiesSpinnerStyles} />
                  </Flex>
                )}
                <Box
                  position={isMax768 ? 'fixed' : 'relative'}
                  bottom={isMax768 ? '16px' : 'none'}
                  px={isMax768 ? '24px' : '0px'}
                  width="100%"
                  height="88px"
                  bg="brand.neutral.white"
                >
                  <FooterWrapper {...footerVars} />
                </Box>
              </Box>
            )}
            {addingPropertyPanel && (
              <Box {...panelFormResponsiveStyles}>
                <AddProperty
                  isInUnifiedRCFlow
                  handleAddedProperty={handleAddedProperty}
                  handleFooterNavigation={() => setAddingPropertyPanel(false)}
                  title="Enter your rental property address"
                  description="Start typing to autofill and select your address."
                />
              </Box>
            )}
          </TabPanel>
          <TabPanel {...tabPanelResponsiveStyles(isMax576, isMax768)}>
            <Box {...panelHeaderWrapperResponsiveStyles(isMax768)}>
              <FormTitle
                title="Enter your rent details"
                textStyles={panelHeaderResponsiveStyles(isMax768)}
                containerStyles={panelHeaderContainerStyles(isMax768)}
              />
            </Box>
            {unit?.lease?.id ? (
              <Box {...panelContentResponsiveStyles('24px', isMax768)}>
                <LeaseTermsForm
                  formVariables={formVariables}
                  setFormVariables={setFormVariables}
                  unit={unit}
                  leaseTermsRef={formRefs[LEASE_TABS.TERMS]}
                  bankAccountsRef={formRefs[LEASE_TABS.ACCOUNTS]}
                  activeTab={tabIndex}
                  saveLease={saveLease}
                  additionalFeeFormRef={additionalFeeFormRef}
                  monthlyFeeFormRef={monthlyFeeFormRef}
                />
                <Box marginTop="8px" width="100%">
                  <FooterWrapper {...footerVars} />
                </Box>
              </Box>
            ) : (
              <Spinner {...invoicesSpinnerStyles} />
            )}
          </TabPanel>
          <TabPanel {...tabPanelResponsiveStyles(isMax576, isMax768)}>
            <Box {...panelHeaderWrapperResponsiveStyles(isMax768)}>
              <FormTitle
                title="Add one-time fees & deposits (optional)"
                textStyles={panelHeaderResponsiveStyles(isMax768)}
                containerStyles={panelHeaderContainerStyles(isMax768)}
              />
            </Box>
            <Box {...panelContentResponsiveStyles('0', isMax768)}>
              {unit?.id && (
                <DepositsFeesForm
                  formVariables={formVariables}
                  setFormVariables={setFormVariables}
                  unit={unit}
                  depositsFeesRef={formRefs[LEASE_TABS.DEPOSITS]}
                  bankAccountsRef={formRefs[LEASE_TABS.ACCOUNTS]}
                  activeTab={tabIndex}
                  saveLease={saveLease}
                  additionalFeeFormRef={additionalFeeFormRef}
                  monthlyFeeFormRef={monthlyFeeFormRef}
                />
              )}
              <Box marginTop="8px" width="100%">
                <FooterWrapper {...footerVars} />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel {...tabPanelResponsiveStyles(isMax576, isMax768)}>
            <Box {...panelHeaderWrapperResponsiveStyles(isMax768)}>
              <FormTitle
                title="Confirm Invoices"
                textStyles={panelHeaderResponsiveStyles(isMax768)}
                containerStyles={panelHeaderContainerStyles(isMax768)}
              />
              <FormDescription styles={panelDescriptionResponsiveStyles(isMax768)}>
                Confirm the following invoices based on Rent Collection Terms entered. Click
                previous to Rent Collection Terms to make any corrections.
              </FormDescription>
            </Box>
            <Box {...panelContentResponsiveStyles('0', isMax768)}>
              <Box paddingTop={isMax768 ? '0px' : '16px'} paddingBottom="40px">
                {sortedInvoices?.length > 0 ? (
                  <>
                    {sortedInvoices?.map((invoice, index) => {
                      if (index < loadMoreNumber * 6) {
                        const description = invoice.name.toLowerCase().replace(/_/g, ' ');
                        return (
                          <BaselaneResponsiveCard
                            id={`invoice-${index}`}
                            key={uuidv4()}
                            {...unitCardStyles(true, true, isMax768)}
                          >
                            <Flex w="100%" justifyContent="space-between">
                              <Text {...invoiceCardHeaderStyles}>
                                {description[0].toUpperCase() + description.substring(1)}
                              </Text>
                              <Text {...invoiceCardHeaderStyles}>Total amount</Text>
                            </Flex>
                            <Flex w="100%" justifyContent="space-between">
                              <Text {...invoiceCardTextStyles}>{wordifyDate(invoice.dueDate)}</Text>
                              <Text {...invoiceCardTextStyles}>
                                {formatCurrency(invoice.amount).inDollars}
                              </Text>
                            </Flex>
                          </BaselaneResponsiveCard>
                        );
                      }
                      return null;
                    })}
                    {loadMoreNumber * 6 < sortedInvoices?.length && (
                      <BaselaneButton
                        variant="transparent"
                        palette="neutral"
                        styles={invoiceListLoadMoreStyles(isMax768)}
                        onClick={() => setLoadMoreNumber(loadMoreNumber + 1)}
                      >
                        Load More
                      </BaselaneButton>
                    )}
                  </>
                ) : (
                  <Spinner {...invoicesSpinnerStyles} />
                )}
              </Box>
              <FooterWrapper {...footerVars} />
            </Box>
          </TabPanel>
          <TabPanel {...tabPanelResponsiveStyles(isMax576, isMax768)}>
            <Box {...panelHeaderWrapperResponsiveStyles(isMax768)}>
              <FormTitle
                title="Select receiving bank account(s)"
                textStyles={panelHeaderResponsiveStyles(isMax768)}
                containerStyles={panelHeaderContainerStyles(isMax768)}
              />
              <FormDescription styles={{ ...panelDescriptionResponsiveStyles(isMax768), mb: 0 }}>
                Receive rent, fees, and one-time payments into a Baselane Banking or external
                account.
              </FormDescription>
            </Box>
            <Box {...panelContentResponsiveStyles('8px', isMax768)}>
              <Box paddingBottom="40px">
                <ReceivingBankAccountForm
                  bankProps={bankProps}
                  setFormVariables={setFormVariables}
                  formVariables={formVariables}
                  unit={unit}
                  leaseTermsRef={formRefs[LEASE_TABS.TERMS]}
                  bankAccountsRef={formRefs[LEASE_TABS.ACCOUNTS]}
                  setHasConnectedBank={setHasConnectedBank}
                  showReceivingBAEmptyState={showReceivingBAEmptyState}
                  setShowReceivingBAEmptyState={setShowReceivingBAEmptyState}
                  tabIndex={tabIndex}
                  accountsPanelIndex={LEASE_TABS.ACCOUNTS}
                  isLeasesPage
                />
              </Box>
              <FooterWrapper {...footerVars} />
            </Box>
          </TabPanel>
          <TabPanel {...tabPanelResponsiveStyles(isMax576, isMax768)}>
            <Box {...panelHeaderWrapperResponsiveStyles(isMax768)}>
              <FormTitle
                title="Tenant details"
                textStyles={panelHeaderResponsiveStyles(isMax768)}
                containerStyles={panelHeaderContainerStyles(isMax768)}
              />
              <FormDescription styles={panelDescriptionResponsiveStyles(isMax768)}>
                We will invite your Tenant to Baselane&apos;s Tenant Platform via email and SMS only
                once you have confirmed details in the next step. You will be copied on the email
                invite.
              </FormDescription>
            </Box>
            <Box {...panelContentResponsiveStyles('24px', isMax768)}>
              <TenantDetailsForm
                style={{ pb: isMax768 ? '180px' : '0' }}
                saveTenantProfileId={saveTenantProfileId}
                formVariables={formVariables}
                setFormVariables={setFormVariables}
                unit={unit}
                tenantDetailsRef={formRefs[LEASE_TABS.TENANT]}
                existingTenantState={existingTenantState}
                setExistingTenantState={setExistingTenantState}
                tenantPanelIndex={LEASE_TABS.TENANT}
                tabIndex={tabIndex}
              />
              <Box marginTop={isMax768 ? '16px' : '32px'}>
                <FooterWrapper {...footerVars} />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel {...tabPanelResponsiveStyles(isMax576, isMax768)}>
            {leaseMenuOptions[tabIndex]?.name === 'REVIEW_LEASE' ? (
              <>
                <Box {...panelHeaderWrapperResponsiveStyles(isMax768)}>
                  <FormTitle
                    title="Review & confirm details"
                    textStyles={panelHeaderResponsiveStyles(isMax768)}
                    containerStyles={panelHeaderContainerStyles(isMax768)}
                  />
                  <FormDescription
                    styles={{
                      ...panelDescriptionResponsiveStyles(isMax768),
                      paddingBottom: isMax768 ? '0' : '8px',
                      marginBottom: isMax768 ? '0' : '16px',
                    }}
                  >
                    Review the details to make sure all fields are correct. Once you confirm, your
                    tenant will receive a rent payment invitation, and invoices will be generated.
                  </FormDescription>
                </Box>
                <Box {...panelContentResponsiveStyles('8px', isMax768)}>
                  <Box {...addressSectionStyles()}>
                    <Flex {...addressSectionFormStyles}>
                      <FormTitle
                        title="Select rental property"
                        textStyles={addressSectionLabelTextStyles()}
                        containerStyles={addressSectionLabelContainerStyles()}
                      />
                      <BaselaneButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectingUnitPanel(false);
                          setTabIndex(0);
                        }}
                        leftIcon={<IconGoEdit />}
                        variant="transparent"
                        palette="neutral"
                        styles={{ m: 0 }}
                      >
                        Edit
                      </BaselaneButton>
                    </Flex>
                    <Flex {...addressSectionContentStyles()}>
                      <Text {...addressSectionContentLeftStyles()}>Address</Text>
                      <Text id="address-review" {...addressSectionContentRightStyles()}>
                        {propertyAddress} - {unit?.name}
                      </Text>
                    </Flex>
                  </Box>
                  <ReviewLeaseForm
                    bankProps={bankProps}
                    leaseCreated={leaseCreated}
                    unit={unit}
                    setTabIndex={setTabIndex}
                  />
                  <FooterWrapper {...footerVars} />
                </Box>
              </>
            ) : null}
          </TabPanel>
        </TabPanels>
      </Flex>
    </TabsComponent>
  );
}

LeaseFormTabs.defaultProps = {
  bankProps: ({}: { ... }),
};

export default LeaseFormTabs;
