import { useFormikContext } from 'formik';
import formatCurrency from '@core/utils/formatCurrency';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import getTransferType from '../../../helpers/getTransferType';

const useAmountValidation = () => {
  const { values } = useFormikContext();
  const { bankAccountsById } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
    fetchPolicy: 'network-only',
  });

  const getTransferLimits = (transferType, transferFromAccount, depositToAccount) => {
    if (transferType === 'TRANSFER_IN') {
      return depositToAccount?.limits;
    }

    if (transferType === 'TRANSFER_OUT') {
      return transferFromAccount?.limits;
    }

    return null;
  };

  const validateAmount = (value) => {
    let error;
    const amount = value ? parseFloat(value.replace(/,/g, '')) : 0;
    const transferFromAccount = bankAccountsById[values.transferFromAccountId];
    const depositToAccount = bankAccountsById[values.depositToAccountId];
    const transferType = getTransferType(transferFromAccount, depositToAccount);
    const transferLimits = getTransferLimits(transferType);
    const {
      dailyCreditTotal,
      monthlyCreditTotal,
      dailyDebitTotal,
      monthlyDebitTotal,
      dailyCreditLimit,
      monthlyCreditLimit,
    } = transferLimits ?? {};
    const hasTransferLimits = transferType !== 'INTERNAL_TRANSFER';

    const dailyTransfer =
      transferType === 'TRANSFER_OUT'
        ? parseFloat(dailyCreditTotal, 10) + parseFloat(amount, 10)
        : parseFloat(dailyDebitTotal, 10) + parseFloat(amount, 10);

    const monthlyTransfer =
      transferType === 'TRANSFER_OUT'
        ? parseFloat(monthlyCreditTotal, 10) + parseFloat(amount, 10)
        : parseFloat(monthlyDebitTotal, 10) + parseFloat(amount, 10);

    if (!amount || amount === 0) {
      error = 'Amount must be greater than $0.00';
    } else if (amount !== null && amount > transferFromAccount?.availableBalance) {
      error =
        'Insufficient funds to make this transfer, please edit the transfer amount to continue.';
    } else if (
      dailyTransfer > dailyCreditLimit &&
      monthlyTransfer < monthlyCreditLimit &&
      hasTransferLimits
    ) {
      error = `This amount cannot be transferred as it exceeds the ${
        formatCurrency(dailyCreditLimit).inDollars
      } daily limit. Initiate a transfer from your external account or contact us for an exception.`;
    } else if (
      dailyTransfer > dailyCreditLimit &&
      monthlyTransfer > monthlyCreditLimit &&
      hasTransferLimits
    ) {
      error = `This amount cannot be transferred as it exceeds the ${
        formatCurrency(dailyCreditLimit).inDollars
      } daily limit. Initiate a transfer from your external account or contact us for an exception.`;
    } else if (
      dailyTransfer < dailyCreditLimit &&
      monthlyTransfer > monthlyCreditLimit &&
      hasTransferLimits
    ) {
      error = `This amount cannot be transferred as it exceeds the ${
        formatCurrency(monthlyCreditLimit).inDollars
      } monthly limit. Initiate a transfer from your external account or contact us for an exception.`;
    }

    return error;
  };

  return {
    validateAmount,
  };
};

export default useAmountValidation;
