import React from 'react';
import { FormControl, Flex, Text } from '@chakra-ui/react';
import { useFormikContext, useField } from 'formik';
import { renderPropertyDropdownParentItem } from '@shared/helpers/propertiesFilter.helpers';
import {
  NoPropertyTooltip,
  BaselaneFormLabel,
  BaselaneTextArea,
  T2Dropdown,
  T2WithTitleDropdown,
} from '@shared/components';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import DropDownDisplayInput from '../PaymentMethodStep/DropDownDisplayInput';
import useCategories from '../../../../../../../features/Category/useCategories';
import useProperties from '../../../../../../../features/Property/useProperties';

import { formStyles } from '../../../styles';

const BookkeepingFields = () => {
  const { values, handleChange, handleBlur } = useFormikContext();
  const { categoriesForDropdown, categoriesForDropdownById } = useCategories({
    sortCategories: false,
  });
  const { propertiesForDropdown, propertiesByIdForDropdownById } = useProperties();
  const categoryIdField = useField('tagId');
  const propertyIdField = useField('propertyId');

  // At position 2, the helpers of fields are returned. More: https://formik.org/docs/api/useField#reference
  const setCategoryId = categoryIdField[2].setValue;
  const getCategoryId = categoryIdField[1].value;
  const getPropertyId = propertyIdField[1].value;
  const setPropertyId = propertyIdField[2].setValue;
  const categoryDropdownProps = {
    name: 'tagId',
    placeholder: 'Select Category',
    title: 'Category',
    additionalProps: { id: 'category-dropdown' },
    data: categoriesForDropdown,
    showValueByFields: ['name'],
    parentItemRenderer: itemRenderer,
    childItemRenderer: itemRenderer,
    handleSubmit: setCategoryId,
    selectedItem: categoriesForDropdownById[getCategoryId],
  };

  const propertyDropdownProps = {
    name: 'propertyId',
    placeholder: 'Select Property',
    title: 'Property',
    additionalProps: { id: 'property-dropdown' },
    data: propertiesForDropdown,
    searchTerm: 'name',
    showValueByFields: ['showValueName'],
    parentItemRenderer: ({ item }) => renderPropertyDropdownParentItem(item),
    childItemRenderer: itemRenderer,
    handleSubmit: setPropertyId,
    selectedItem: propertiesByIdForDropdownById[getPropertyId],
  };

  const sharedDropdownProps = {
    classNames: ['input-form-lg', 'auto-select-input-width'],
    isMulti: false,
    hasFilterWrapper: false,
    parentId: 'drawer-body',
    CustomDisplayInput: DropDownDisplayInput,
  };

  const { controlStyles, labelSubtextStyles } = formStyles;

  return (
    <>
      {/* Category */}
      <FormControl id="transfer-funds-transfer-category" {...controlStyles}>
        <BaselaneFormLabel textStyle="sm">
          <Flex as="span" gap={0.5}>
            Category
            <Text {...labelSubtextStyles}>(optional)</Text>
          </Flex>
        </BaselaneFormLabel>
        <T2WithTitleDropdown {...{ ...categoryDropdownProps, ...sharedDropdownProps }} />
      </FormControl>

      {/* Property */}
      <FormControl id="transfer-funds-transfer-property" {...controlStyles}>
        <BaselaneFormLabel textStyle="sm">
          <Flex as="span" gap={0.5}>
            Property
            <Text {...labelSubtextStyles}>(optional)</Text>
          </Flex>
        </BaselaneFormLabel>
        {propertiesForDropdown?.length === 0 ? (
          <NoPropertyTooltip {...{ innerContainerStyles: { m: '0 !important' } }}>
            <T2Dropdown {...{ ...propertyDropdownProps, ...sharedDropdownProps }} isDisabled />
          </NoPropertyTooltip>
        ) : (
          <T2Dropdown {...{ ...propertyDropdownProps, ...sharedDropdownProps }} />
        )}
      </FormControl>

      {/* Notes */}
      <FormControl id="transfer-funds-transfer-note" {...controlStyles}>
        <BaselaneFormLabel textStyle="sm">
          <Flex as="span" gap={0.5}>
            Notes
            <Text {...labelSubtextStyles}>(optional)</Text>
          </Flex>
        </BaselaneFormLabel>
        <BaselaneTextArea
          size="lg"
          id="note"
          name="note"
          placeholder="Add any notes for yourself"
          value={values.note}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormControl>
    </>
  );
};

export default BookkeepingFields;
