/* eslint-disable no-param-reassign */
import React from 'react';
import {
  IconApartment,
  IconCondo,
  IconDuplexTriplex,
  IconOther,
  IconSingleFamilyHouse,
  IconTownhome,
} from '@icons';

export const mobilePropertyTypeOptions = (type) => {
  switch (type) {
    case 'Condo':
      return <IconCondo w="14px" h="14px" />;
    case 'Apartment':
      return <IconApartment w="14px" h="14px" />;
    case 'Duplex/Triplex':
      return <IconDuplexTriplex w="14px" h="14px" />;
    case 'Single Family Home':
      return <IconSingleFamilyHouse w="14px" h="14px" />;
    case 'Townhome':
      return <IconTownhome w="14px" h="14px" />;
    default:
      return <IconOther w="14px" h="14px" />;
  }
};
