// @flow
import React from 'react';
import BaselaneBadge from '../BaselaneBadge';
import { backgroundStyles, containerStyles, fontStyles } from './styles/leaseResponsiveBadge.style';

type Props = {
  leaseStatusType?: string,
  text: string,
};

function BaselaneLeaseBadge({ leaseStatusType, text, ...styles }: Props): any {
  return (
    <BaselaneBadge
      fontStyles={{ ...backgroundStyles[leaseStatusType], ...fontStyles }}
      backgroundStyles={{ ...backgroundStyles[leaseStatusType], ...containerStyles, ...styles }}
      type="master"
      text={leaseStatusType?.toUpperCase()}
    />
  );
}

BaselaneLeaseBadge.defaultProps = {
  leaseStatusType: 'active',
};

export default BaselaneLeaseBadge;
