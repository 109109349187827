import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import TransferReferralSuccessCard from '@shared/components/TransferReferralSuccessCard';
import successImageUri from './assets/success.svg';

const CheckDepositSuccess = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      justifyContent="center"
    >
      <Box>
        <Image
          src={successImageUri}
          alt="A checkmark inside a circle, indicating a successful check deposit"
        />
      </Box>
      <Text mt={3} textStyle="headline-xl">
        We are processing your check
      </Text>
      <Text mt={1.5} textStyle="sm">
        Funds will be available within 5 business days. We will notify you if we encounter any
        issues.
      </Text>
      <TransferReferralSuccessCard source="add_funds" mt={5} />
    </Box>
  );
};
export default CheckDepositSuccess;
