import React, { useState, useRef } from 'react';
import { orderBy } from 'lodash';
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { IconChevronDown, IconChevronUp, IconExternalLink, IconPropertyAnalytics } from '@icons';
import {
  BaselaneButton,
  BaselaneHeaderCard,
  BaselaneMessageCard,
  CurrencyText,
} from '@shared/components';
import AddEditProperty from './AddEditProperty';
import PropertyFinancialDetails from './PropertyFinancialDetails';
import { mobilePropertyTypeOptions } from './helpers/detail.helper';
import {
  baselaneMessageCardContainerStyles,
  financialCardHeaderTitleStyles,
  financialCardDetailsLinkStyles,
  financialCardContentStyles,
  financialCardHeaderStyles,
} from './styles/responsive.style';

function Property({
  property = {},
  setProperty,
}: {
  property: Array<Object>,
  setProperty?: Function,
}) {
  const [showFinancialDetails, setShowFinancialDetails] = useState(false);
  const btnRef = useRef();

  /**
   * Clean up property states.
   *
   * @param isSave a boolean to differentiate between save & close vs just closing drawer
   */

  const hideFinancialDetails = () => {
    setShowFinancialDetails(false);
  };

  const { marketPrice, mortgageBalance: mortgageBalanceData, purchasePrice } = property;
  const [isViewAll, setViewAll] = useState(false);
  const [isDesktop] = useMediaQuery('(min-width: 899px)', { ssr: false });
  const [latestMarketValue] = orderBy(marketPrice, ['date'], ['desc']);
  const [latestMortgageBalance] = orderBy(mortgageBalanceData, ['date'], ['desc']);
  const marketValue = latestMarketValue ? latestMarketValue.price : null;
  const mortgageBalance = latestMortgageBalance ? latestMortgageBalance.balance : null;
  const hasData = marketValue || purchasePrice || mortgageBalance;

  const list = [
    {
      label: 'Market Value',
      value: marketValue,
    },
    {
      label: 'Purchase Price',
      value: purchasePrice,
    },
    {
      label: 'Appreciation',
      value: marketValue && purchasePrice && marketValue - purchasePrice,
    },
    {
      label: 'Mortgage Balance',
      value: mortgageBalance,
    },
    {
      label: 'Home Equity',
      value: marketValue && mortgageBalance && marketValue - mortgageBalance,
    },
  ];

  const setupPropertyFinancials =
    'Add financial details to help you get accurate metrics for your properties. We recommend filling in this information to get the most value from the Baselane platform.';

  const propertyHeaderSubtext = (
    <Box pb="16px" color="brand.neutral.600" bg="brand.neutral.white">
      <Flex direction="column">
        <Text textStyle="xs">
          {property?.address?.address},{' '}
          {property?.address?.unit ? `${property?.address?.unit}, ` : ''}
        </Text>
        <Text textStyle="xs">
          {property?.address?.city}, {property?.address?.state} {property?.address?.zipCode}
        </Text>
      </Flex>
      <Flex direction="row" h="40px" pt="16px">
        <Box mr="8px" mt="2px">
          {mobilePropertyTypeOptions(property?.type || 'Other')}
        </Box>
        <Text textStyle="headline-xs">{property?.type}</Text>
      </Flex>
    </Box>
  );

  const financialCardHeaderTitle = (title) => (
    <Flex direction="row">
      <Box mt="4px">
        <IconPropertyAnalytics w={28} h={16} color="#3A4B5B" />
      </Box>
      <Text {...financialCardHeaderTitleStyles(isDesktop)}>{title}</Text>
    </Flex>
  );

  const financialCardHeaderLink = () => (
    <Flex direction="row">
      <BaselaneButton
        type="link"
        ref={btnRef}
        onClick={() => {
          setShowFinancialDetails(true);
        }}
        {...financialCardDetailsLinkStyles(isDesktop)}
      >
        <Box display="inline-block" pr="12px">
          Details
        </Box>
        <Box display="inline-block" position="relative" top="1px">
          <IconExternalLink w={12} h={12} />
        </Box>
      </BaselaneButton>
    </Flex>
  );

  const propertyDetailsFinancialSummaryHeader = (
    <Flex
      position="relative"
      zIndex="11"
      direction={isDesktop ? 'row' : 'column'}
      bg="brand.neutral.white"
      p="48px 0px 4px 52px"
      style={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '0px',
        borderColor: 'brand.darkBlue.150',
      }}
    >
      {propertyHeaderSubtext}
      {property.units.length > 1 && (
        <Box textStyle="headline-xs" m="4px 0">{`Units(${property.units.length})`}</Box>
      )}
      {property.units.length > 1 &&
        property.units.map((item, index) => {
          if (isViewAll || index < 4) {
            return (
              <Box key={item.name + (item?.id || null)} textStyle="headline-xs" m="4px 0">
                {item.name}
              </Box>
            );
          }
          return null;
        })}
      {property.units.length > 4 && isViewAll && (
        <Box mb="8px">
          <BaselaneButton
            variant="transparent"
            palette="primary"
            pullLeft
            onClick={() => setViewAll(false)}
            rightIcon={<IconChevronUp />}
          >
            View Less
          </BaselaneButton>
        </Box>
      )}
      {property.units.length > 4 && !isViewAll && (
        <Box mb="8px">
          <BaselaneButton
            variant="transparent"
            palette="primary"
            pullLeft
            onClick={() => setViewAll(true)}
            rightIcon={<IconChevronDown />}
          >
            View All
          </BaselaneButton>
        </Box>
      )}
    </Flex>
  );
  return (
    <Box mb="88px">
      {!showFinancialDetails && (
        <>
          {propertyDetailsFinancialSummaryHeader}
          {/* Property Financial Summary */}
          {!hasData ? (
            <BaselaneMessageCard
              title="Property Financials Missing"
              borderColor="warningyellow"
              iconName=""
              hasCloseButton={false}
              isVertical
              text={setupPropertyFinancials}
              containerStyles={{ ...baselaneMessageCardContainerStyles }}
              bottomElement={
                <Box pb="12px">
                  <AddEditProperty
                    buttonLabel="Add Financial Details"
                    variant="filled"
                    palette="primary"
                    property={property}
                    buttonVariant="primary"
                    financials
                    hasData
                    setProperty={setProperty}
                  />
                </Box>
              }
            />
          ) : (
            <BaselaneHeaderCard
              containerStyles={{ margin: '16px 16px 88px' }}
              customHeaderStyles={financialCardHeaderStyles(isDesktop)}
              contentStyles={financialCardContentStyles(isDesktop)}
              headerLeft={financialCardHeaderTitle('Property Financials')}
              headerRight={financialCardHeaderLink(property)}
            >
              {list.map((item) => (
                <Flex key={item.label} direction="row" style={{ justifyContent: 'space-between' }}>
                  <Text>{item.label}</Text>
                  <CurrencyText textStyle="headline-sm" amount={item.value} />
                </Flex>
              ))}
            </BaselaneHeaderCard>
          )}
        </>
      )}
      {showFinancialDetails && hasData && (
        <PropertyFinancialDetails {...{ property, hideFinancialDetails, setProperty }} />
      )}
    </Box>
  );
}

Property.defaultProps = { setProperty: null };

export default Property;
