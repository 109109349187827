import React, { useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { CSVLink } from 'react-csv';
import { IconDownload } from '@icons';

import '../../../styles/cashflowStatement.styles';

type StatementDownloadProps = {
  className?: String,
  filename: String,
  statementData: any,
  filenameSuffix?: String,
  buttonText?: String,
  CSVRef: any,
  handleStatementDownloadClick?: Function,
};

function StatementDownload({
  className,
  filename,
  statementData,
  filenameSuffix,
  buttonText,
  CSVRef,
  handleStatementDownloadClick = () => {},
}: StatementDownloadProps) {
  const CSVLinkRef = useRef();
  const formattedFileName = filename ?? `cashflow-statement${filenameSuffix}`;

  return (
    statementData && (
      <CSVLink
        id="statementDowloadButton"
        className={className}
        asyncOnClick
        data={statementData}
        ref={CSVRef || CSVLinkRef}
        filename={`${formattedFileName}.csv`}
        onClick={handleStatementDownloadClick}
      >
        <Box as="span" {...{ mr: '6px' }}>
          <IconDownload />
        </Box>
        {buttonText}
      </CSVLink>
    )
  );
}

StatementDownload.defaultProps = {
  className: '',
  filenameSuffix: '',
  buttonText: 'Download Statement',
  handleStatementDownloadClick: () => {},
};

export default StatementDownload;
