import React from 'react';
import { Text, Stack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import {
  normalText,
  rowStyles,
  labelStyles,
  valueText,
  helpTextStyles,
} from '@core/components/NativeBankingPage/MainContent/components/AddFundsDrawer/styles/reviewTransfer.styles';
import {
  getCategoryName,
  getPropertyAddress,
  getPropertyName,
} from '@core/utils/propertyAndCategoryHelpers';

export const reviewSubText = {
  INTERNAL_TRANSFER: (
    <Text {...normalText}>
      Your funds will be transferred immediately between Baselane Banking accounts.
    </Text>
  ),
  TRANSFER_OUT: (
    <Text {...normalText}>
      Transfer requests received after 4:30 PM ET, on weekends, or on federal holidays will be
      processed the next business day.
    </Text>
  ),
  WIRE_TRANSFER: (
    <Text {...normalText}>available within 3 business days of the transfer date.</Text>
  ),
  TRANSFER_IN: (
    <Text {...(isMobile ? { color: 'brand.neutral.600', fontSize: 'xs' } : normalText)}>
      Your funds will be available within 3 business days. <br /> <br /> Transfer requests received
      after 3:00 PM ET, on weekends, or on federal holidays will be processed the next business day.
    </Text>
  ),
};

export const getOptionsTagsReview = ({
  optionalTags,
  propertyOptions,
  categoryOptions,
  isMinXL,
  categoryIdsMap,
}) => {
  const { propertyUnitId, categoryId } = optionalTags;
  const [pId, uId] = propertyUnitId?.split('-') ?? [null, null];

  return (
    <>
      <Stack direction={isMinXL ? 'row' : 'column'} {...rowStyles(isMinXL)}>
        <Text {...labelStyles(isMinXL)}>Property Tag</Text>
        {pId ? (
          <Stack {...rowStyles(isMinXL)}>
            <Text {...valueText(isMinXL)}>{getPropertyName(pId, uId, propertyOptions)}</Text>
            <Text {...{ ...helpTextStyles, m: '0 !important' }}>
              {getPropertyAddress(pId, propertyOptions)}
            </Text>
          </Stack>
        ) : (
          <Stack {...rowStyles(isMinXL)}>
            <Text {...valueText(isMinXL)}>&mdash;</Text>
          </Stack>
        )}
      </Stack>

      <Stack direction={isMinXL ? 'row' : 'column'} {...rowStyles(isMinXL)}>
        <Text {...labelStyles(isMinXL)}>Category Tag</Text>
        {categoryId ? (
          <Text {...valueText(isMinXL)}>
            {getCategoryName(categoryId, categoryOptions, categoryIdsMap)}
          </Text>
        ) : (
          <Text {...valueText(isMinXL)}>&mdash;</Text>
        )}
      </Stack>
    </>
  );
};
