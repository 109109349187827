export const transactionsStyles = (isMin899, isMobile, isEmbedded) => {
  const getPaddings = () => {
    let paddings = 0;
    if (isEmbedded) {
      paddings = 0;
    } else if (!isMin899) {
      paddings = '20px 16px 20px 16px';
    }
    return paddings;
  };

  return {
    container: {
      height: !isMin899 ? '100%' : 'auto',
      p: getPaddings(),
      spacing: 4,
    },
    list: {
      container: () => {
        const getMargins = () => {
          let margins = '0 0 0 0 !important';

          if (isEmbedded) {
            margins = isMobile ? '0 0 32px 0 !important' : '0 24px 32px 24px !important';
          } else if (!isMin899) {
            margins = '0 0 0 0 !important';
          }

          return margins;
        };

        return {
          p: 0,
          m: getMargins(),
        };
      },
    },
  };
};
