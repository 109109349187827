import React from 'react';

type IconCircleOutlineNoPaddingProps = {
  w?: Number,
  h?: Number,
  color?: String,
};

function IconCircleOutlineNoPadding({ w, h, color }: IconCircleOutlineNoPaddingProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99998 1.66665C5.58549 1.66665 4.22894 2.22855 3.22874 3.22874C2.22855 4.22894 1.66665 5.58549 1.66665 6.99998C1.66665 7.70036 1.8046 8.39389 2.07262 9.04096C2.34065 9.68803 2.7335 10.276 3.22874 10.7712C3.72399 11.2665 4.31193 11.6593 4.959 11.9273C5.60607 12.1954 6.2996 12.3333 6.99998 12.3333C7.70036 12.3333 8.39389 12.1954 9.04096 11.9273C9.68803 11.6593 10.276 11.2665 10.7712 10.7712C11.2665 10.276 11.6593 9.68803 11.9273 9.04096C12.1954 8.39389 12.3333 7.70036 12.3333 6.99998C12.3333 5.58549 11.7714 4.22894 10.7712 3.22874C9.77102 2.22855 8.41447 1.66665 6.99998 1.66665ZM2.28593 2.28593C3.53618 1.03569 5.23187 0.333313 6.99998 0.333313C8.76809 0.333313 10.4638 1.03569 11.714 2.28593C12.9643 3.53618 13.6666 5.23187 13.6666 6.99998C13.6666 7.87546 13.4942 8.74237 13.1592 9.5512C12.8241 10.36 12.3331 11.095 11.714 11.714C11.095 12.3331 10.36 12.8241 9.5512 13.1592C8.74237 13.4942 7.87546 13.6666 6.99998 13.6666C6.1245 13.6666 5.25759 13.4942 4.44876 13.1592C3.63992 12.8241 2.90499 12.3331 2.28593 11.714C1.66688 11.095 1.17581 10.36 0.840783 9.5512C0.505751 8.74237 0.333313 7.87546 0.333313 6.99998C0.333313 5.23187 1.03569 3.53618 2.28593 2.28593Z"
        fill={color}
      />
    </svg>
  );
}

IconCircleOutlineNoPadding.defaultProps = {
  w: 14,
  h: 14,
  color: '#6C7884',
};

export default IconCircleOutlineNoPadding;
