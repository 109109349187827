import { gql } from '@apollo/client';

export const SEND_OTP_REGISTERED_USER = gql`
  mutation SendOtpRegisteredUser($channel: String!) {
    sendOtpRegisteredUser(channel: $channel) {
      sendCodeAttempts
      status
    }
  }
`;

export const VERIFY_OTP_REGISTERED_USER = gql`
  mutation VerifyOtpRegisteredUser($channel: String!, $code: String!) {
    verifyOtpRegisteredUser(channel: $channel, code: $code)
  }
`;
