export const sortWithString = (dataset, key, key2) =>
  [...dataset].sort((a, b) => {
    let aStripped = a;
    let bStripped = b;

    if (key) {
      // remove space and set to lowercase
      aStripped =
        a[key] && a[key] !== null
          ? a[key].toLowerCase().replace(/\s/g, '')
          : a[key2].toLowerCase().replace(/\s/g, '');
      bStripped =
        b[key] && b[key] !== null
          ? b[key].toLowerCase().replace(/\s/g, '')
          : b[key2].toLowerCase().replace(/\s/g, '');
    }

    if (aStripped < bStripped) {
      return -1;
    }

    if (aStripped > bStripped) {
      return 1;
    }
    return 0;
  });

export const getConnectedAccounts = (banks) => {
  return (
    banks &&
    banks.reduce((bankAcc, b) => {
      const clonedBank = { ...b };
      const connectedBankAccounts =
        clonedBank.bankAccounts?.length > 0
          ? clonedBank.bankAccounts.filter((ba) => ba.isConnected)
          : [];
      if (connectedBankAccounts?.length > 0) {
        clonedBank.bankAccounts = connectedBankAccounts;
        bankAcc.push(clonedBank);
      }
      return bankAcc;
    }, [])
  );
};

export const getBaselaneConnectedAccounts = (banks) => {
  return (
    banks &&
    banks.reduce((bankAcc, b) => {
      const clonedBank = { ...b };
      if (b.isConnected === true) {
        const connectedBankAccounts = clonedBank.bankAccounts.reduce((allAccounts, bAccount) => {
          const clonedBankAccount = { ...bAccount };
          const subAcc = [];
          if (bAccount.accountStatus === 'Open' && bAccount.connectionState === 'CONNECTED') {
            clonedBankAccount.subAccounts.forEach((sAccount) => {
              if (sAccount.accountStatus === 'Open') {
                subAcc.push(sAccount);
              }
            });
            clonedBankAccount.subAccounts = subAcc;
            allAccounts.push(clonedBankAccount);
          }
          return allAccounts;
        }, []);

        if (connectedBankAccounts?.length > 0) {
          clonedBank.bankAccounts = connectedBankAccounts;
          bankAcc.push(clonedBank);
        }
      }
      return bankAcc;
    }, [])
  );
};

export const getPartitionedAccounts = (banks) => {
  const baselaneAcc = [];
  const externalAcc = [];
  const manualAcc = [];

  banks?.forEach((item) => {
    if (item.isExternal) {
      if (item.plaidInstitutionName === 'manual') {
        manualAcc.push(item);
      } else {
        externalAcc.push(item);
      }
    } else {
      baselaneAcc.push(item);
    }
  });

  return [baselaneAcc, externalAcc, manualAcc];
};
