import { cloneDeep } from 'lodash';
import { AuthenticationError, errorMessages } from './error.helpers';

export const validateLogin = (values) => {
  const trimmedValues = {
    ...values,
    email: values?.email?.trim(),
  };

  const { email, password } = trimmedValues;

  const errors = {};

  if (email === '') {
    errors.email = errorMessages[AuthenticationError.invalidemail];
  }
  if (password === '') {
    errors.password = errorMessages[AuthenticationError.nopassword];
  }

  return errors;
};

export const passwordValidator = (p, checklist) => {
  const lowercasePattern = /.*?[a-z].*?/g;
  const lowercase = lowercasePattern.test(p);
  const uppercasePattern = /.*?[A-Z].*?/g;
  const uppercase = uppercasePattern.test(p);
  const numberPattern = /.*?[0-9].*?/g;
  const number = numberPattern.test(p);
  const specialPattern = /[!@#$%^&*(),.?":{}|<>'~`\-_=+{}[\]\\:;<>/]/g;
  const special = specialPattern.test(p);

  const editChecklist = cloneDeep(checklist);
  if (p.length >= 8) {
    editChecklist.minimum.isValid = true;
  }
  if (p.length < 8) {
    editChecklist.minimum.isValid = false;
  }
  if (lowercase) {
    editChecklist.lowercase.isValid = true;
  }
  if (!lowercase) {
    editChecklist.lowercase.isValid = false;
  }
  if (uppercase) {
    editChecklist.uppercase.isValid = true;
  }
  if (!uppercase) {
    editChecklist.uppercase.isValid = false;
  }
  if (number) {
    editChecklist.number.isValid = true;
  }
  if (!number) {
    editChecklist.number.isValid = false;
  }
  if (special) {
    editChecklist.special.isValid = true;
  }
  if (!special) {
    editChecklist.special.isValid = false;
  }

  return editChecklist;
};

export const initialChecklist = Object.freeze({
  lowercase: { isValid: false, name: 'One lowercase character' },
  special: { isValid: false, name: 'One special character' },
  uppercase: { isValid: false, name: 'One uppercase character' },
  minimum: { isValid: false, name: '8 minimum characters' },
  number: { isValid: false, name: 'One number' },
});

export const initialFormValues = (email) => {
  return {
    email,
    password: '',
    firstName: '',
    lastName: '',
  };
};

export const PW_ERROR = Object.freeze({
  weakPw: 'Password requirements not met',
  pwRequired: 'Please enter Password',
});

export const validateSignUp = (values, weakPassword, setStrongPassword) => {
  const trimmedValues = {
    ...values,
    email: values?.email?.trim(),
    firstName: values?.firstName?.trim(),
    lastName: values?.lastName?.trim(),
  };

  const { email: emailInput, password, firstName, lastName } = trimmedValues;
  const errors = {};

  if (weakPassword()) {
    setStrongPassword(false);
    errors.password = PW_ERROR.weakPw;
  }

  if (!emailInput) {
    errors.email = 'Please enter Email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (firstName === '') {
    errors.firstName = 'Please enter First Name';
  } else if (
    !/^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
      values.firstName
    )
  ) {
    errors.firstName = 'Name contains invalid characters';
  } else if (firstName.length > 50) {
    errors.firstName = 'Max 50 characters';
  }

  if (lastName === '') {
    errors.lastName = 'Please enter Last Name';
  } else if (
    !/^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
      values.lastName
    )
  ) {
    errors.lastName = 'Name contains invalid characters';
  } else if (lastName.length > 50) {
    errors.lastName = 'Max 50 characters';
  }

  if (password === '') {
    errors.password = PW_ERROR.pwRequired;
  }

  return errors;
};

/* export const validateEmail = () => {

}; */
