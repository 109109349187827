// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=2925%3A97142
import React from 'react';
import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import {
  sectionSmTitleStyles,
  listItemTextStyles,
  boldTextStyles,
} from '../styles/emptyState.shared.styles';

const ConnectCreditCardList = ({ styles }: { styles?: Object }): any => (
  <Box {...styles}>
    <Text {...sectionSmTitleStyles}>
      You can connect{' '}
      <Box as="span" {...boldTextStyles}>
        Credit Cards{' '}
      </Box>
      to:
    </Text>
    <UnorderedList>
      <ListItem {...listItemTextStyles}>Import transactions</ListItem>
    </UnorderedList>
  </Box>
);

ConnectCreditCardList.defaultProps = {
  styles: ({}: { ... }),
};

export default ConnectCreditCardList;
