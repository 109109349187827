import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { IconSparkles, IconOtherTransfers, IconInsurance, IconPoweredByObie } from '@icons';
import { Icon16Bolt, Icon16Security, Icon16CheckCircle } from '@icons/16px';
import insuranceImage from '../assets/insurance.svg';

const textStyles = {
  fontSize: '14px',
  color: 'brand.neutral.700',
  fontWeight: 'normal',
  lineHeight: '20px',
};

const notesTextStyles = {
  fontSize: '8px',
  color: 'brand.neutral.500',
  position: 'absolute',
  top: '-4px',
  right: '-6px',
};

export const topCard = {
  smallIcon: <IconPoweredByObie />,
  title: 'Landlord Insurance that is right for you',
  description:
    'Get the right protection for your rental property without breaking the bank. Receive a personalized quote instantly.',
  rightImage: { src: insuranceImage, alt: 'insurance' },
  buttonProps: {
    size: 'lg',
    variant: 'filled',
    palette: 'primary',
    leftIcon: <Icon16Bolt color="#fff" />,
    btnText: 'Get an instant quote',
  },
};

export const infoCard = {
  icon: <Icon16Security color="#0968BF" />,
  title: 'Trusted Insurance Partner',
  description:
    'Obie has insured more than $10 Billion in rental properties and is available in all 50 states.',
  mobileDescription:
    ' Here’s how our Landlord insurance differs from a Homeowners insurance you might have.',
};

export const infoBoxData = [
  {
    id: 1,
    icon: <IconOtherTransfers color="#8CCFFF" w="40" h="40" />,
    title: 'Affordable Rates',
    description:
      'Check if you can save $100’s on your existing properties. We offer industry-best rates for the coverage that is right for you as a landlord.',
  },
  {
    id: 2,
    icon: <IconInsurance color="#8CCFFF" w="40" h="40" />,
    title: 'The Right Coverage',
    description:
      'Reduce your risk and relax knowing that your landlord insurance will offer you protection against the unexpected.',
  },

  {
    id: 3,
    icon: <IconSparkles color="#8CCFFF" w="40" h="40" />,
    title: 'Simple and transparent',
    description:
      'Receive a rental property insurance quote online in less than a minute with zero commitment and no phone call required.',
  },
];

export const middleCard = {
  title: 'Landlord vs. Homeowners insurance',
  mobileTitle: 'Are you a new landlord?',
  description:
    ' Here’s how our Landlord insurance differs from a Homeowners insurance you might have.',
  buttonProps: {
    size: 'lg',
    variant: 'filled',
    palette: 'primary',
    btnText: 'Get an instant quote',
    leftIcon: <Icon16Bolt color="#fff" />,
  },
};

export const tableData = [
  {
    coverage: 'Dwelling',
    withObie: <Icon16CheckCircle color="#50A0E8" />,
    owners: <Icon16CheckCircle color="#50A0E8" />,
  },
  {
    coverage: 'Personal Property',
    withObie: 'Optional',
    owners: <Icon16CheckCircle color="#50A0E8" />,
  },
  { coverage: 'Loss of Use', withObie: '', owners: <Icon16CheckCircle color="#4C83F5" /> },
  {
    coverage: 'Loss of Rent',
    withObie: <Icon16CheckCircle color="#50A0E8" />,

    owners: '',
  },
  {
    coverage: 'Liability Payments',
    withObie: <Icon16CheckCircle color="#50A0E8" />,
    owners: <Icon16CheckCircle color="#50A0E8" />,
  },
  {
    coverage: 'Medical Payments',
    withObie: (
      <HStack position="relative">
        <Box as="span" {...textStyles}>
          <Icon16CheckCircle color="#50A0E8" />
        </Box>
        <Box as="span" {...notesTextStyles}>
          1
        </Box>
      </HStack>
    ),
    owners: <Icon16CheckCircle color="#50A0E8" />,
  },
];

export const noteListData = [
  {
    id: 1,
    text: 'Medical payments: Covers guests’ medical bills if they are injured on the property.',
  },
];

export const bottomCard = {
  title: 'Already insured?',
  description: 'We make it easy to switch. On average, landlords save 25% when they switch.',
  buttonProps: {
    size: 'lg',
    variant: 'tonal',
    palette: 'primary',
    btnText: 'Compare prices',
  },
};
