import React from 'react';
import LocationDescriptor from 'react-router-dom';
import BaselaneButton from '../../../BaselaneButton';

type BaselaneResponsiveCellTextButtonProps = {
  text: String,
  icon?: any,
  onClick?: Function,
  to?: LocationDescriptor,
  isDisabled?: Boolean,
};

const BaselaneResponsiveCellTextButton = ({
  text,
  icon,
  onClick,
  to,
  isDisabled,
}: BaselaneResponsiveCellTextButtonProps) => {
  return (
    <BaselaneButton
      {...{
        variant: 'transparent',
        palette: 'neutral',
        onClick,
        to,
        leftIcon: icon,
        isDisabled,
      }}
    >
      {text}
    </BaselaneButton>
  );
};

BaselaneResponsiveCellTextButton.defaultProps = {
  icon: null,
  onClick: () => {},
  to: null,
  isDisabled: false,
};

export default BaselaneResponsiveCellTextButton;
