import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import '../styles/dropdowns.styles.scss';
import { Icon16ChevronDown, Icon16ChevronUp } from '@icons/16px';
import { dropdownIconContainerStyles } from '../styles/dropdown.styles';
import { base } from '../styles/selectField.styles';

type DropdownTriggerProps = {
  DisplayInput: React.Node,
  selectedOptions: Array<Object>,
  handleContentRendererClick: Function,
  placeholder: string,
  title: string,
  showValueByFields: Array<string>,
  handleClearClick: Function,
  isDisabled: boolean,
  isMobile: boolean,
  hasError: boolean,
  showMobileDropdownPopup: boolean,
  classNames: Array<string>,
};

const SelectField = ({
  DisplayInput,
  selectedOptions,
  handleContentRendererClick,
  placeholder,
  title,
  showValueByFields,
  handleClearClick,
  isDisabled,
  isMobile,
  hasError,
  showMobileDropdownPopup,
  classNames,
}: DropdownTriggerProps) => {
  const isInvalid = classNames.includes('input-invalid') || hasError;
  return (
    <Flex
      as="button"
      {...base(isMobile, classNames)}
      // based on param set disabled and invalid arias so we don't need
      // to check within the style object
      aria-disabled={isDisabled}
      aria-invalid={isInvalid}
      onClick={(e) => {
        handleContentRendererClick(e);
      }}
    >
      <DisplayInput
        selectedOptions={selectedOptions}
        placeholder={placeholder}
        title={title}
        showValueByFields={showValueByFields}
        handleClearClick={handleClearClick}
        isDisabled={isDisabled}
        showMobileDropdownPopup={showMobileDropdownPopup}
        classNames={classNames}
      />

      <Box {...dropdownIconContainerStyles}>
        {showMobileDropdownPopup ? <Icon16ChevronUp /> : <Icon16ChevronDown />}
      </Box>
    </Flex>
  );
};

export default SelectField;
