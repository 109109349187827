import customTheme from '@core/theme';
import radii from '@core/theme/radius';

export const wrapperStyles = {
  borderColor: `${customTheme.colors.brand.darkBlue['200']}`,
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: 0.5,
  borderRadius: radii.sm,
};
