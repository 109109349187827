import React from 'react';

function IconSkeletonLineChart() {
  return (
    <svg width="479" height="127" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64.399 78.364C39.313 82.05 22.024 103.987 1 107.585V127h478V62.928c-23.405 0-26.529 16.35-37.543 11.849-11.014-4.5-22.584-35.444-35.434-33.808-12.85 1.637-19.902-41.788-46.979-37.697-27.077 4.091-47.005 50.822-68.575 30.774-21.569-20.048-26.154-9.47-49.101-13.561-22.946-4.092-32.998 44.921-54.733 43.355-21.734-1.567-27.535 39.199-49.715 35.418-26.877-4.582-24.538-7.148-36.489-13.091-11.95-5.944-22.662-9.769-36.032-7.803z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M1 107c21.024-3.577 38.313-25.391 63.399-29.058 13.37-1.954 24.082 1.85 36.032 7.76 11.951 5.91 9.612 8.462 36.489 13.018 22.18 3.76 27.981-36.778 49.715-35.22 21.735 1.557 31.787-47.181 54.733-43.113 22.947 4.069 27.532-6.45 49.101 13.485 21.57 19.936 41.498-26.533 68.575-30.601 27.077-4.069 34.129 39.114 46.979 37.486 12.85-1.627 24.42 29.143 35.434 33.618 11.014 4.476 14.138-11.782 37.543-11.782"
        stroke="#F0F2F6"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="240"
          y1="3"
          x2="240"
          y2="163.916"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F2F6" stopOpacity=".1" />
          <stop offset=".349" stopColor="#F0F2F6" stopOpacity=".094" />
          <stop offset="1" stopColor="#F0F2F6" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconSkeletonLineChart;
