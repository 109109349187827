import React, { forwardRef } from 'react';
import { HStack } from '@chakra-ui/react';
import CheckboxListItem from '../../CheckboxListItem';
import DropdownContentWrapper from '../../DropdownContentWrapper';

import { setIsSelectedItem } from '../../helpers/dropdown.shared.helpers';
import { t1Search } from '../../utils/search';

type ListOfOptionsWithCheckboxProps = {
  options: Array<any>,
  itemRenderer: Function,
  handleListItemClick: Function,
  hasFilterWrapper: boolean,
  searchTerm: Array<string>,
  search: string,
  selectedStagedOptions: Array<Object>,
};

const ListOfOptionsWithCheckbox = forwardRef(
  (
    {
      hasFilterWrapper,
      options,
      searchTerm,
      search,
      handleListItemClick,
      itemRenderer,
      selectedStagedOptions,
    }: ListOfOptionsWithCheckboxProps,
    ref
  ) => {
    return (
      <DropdownContentWrapper hasFilterWrapper={hasFilterWrapper} ref={ref}>
        {t1Search({ options, searchTerm, search }).map((item) => (
          <HStack key={item.id} align="center">
            <CheckboxListItem
              tabIndex="-1"
              className="element"
              key={item.id}
              id={item.id}
              onChange={(e) => handleListItemClick(item, e)}
              isChecked={setIsSelectedItem(item, selectedStagedOptions)}
            >
              {itemRenderer({ item, search })}
            </CheckboxListItem>
          </HStack>
        ))}
      </DropdownContentWrapper>
    );
  }
);

export default ListOfOptionsWithCheckbox;
