import { gql } from '@apollo/client';

export type SortsAndFiltersInput = {
  title: String,
  read: Boolean,
};

export const UnreadTransactionAlertInput: SortsAndFiltersInput = {
  title: 'TRANSACTIONS',
  read: false,
};

export const TRANSACTION_ALERTS = gql`
  query getAlerts($input: SortsAndFiltersInput) {
    alert(input: $input) {
      id
      title
      message
      read
    }
  }
`;

export const READ_TRANSACTION_ALERTS = gql`
  mutation readAlert($id: ID) {
    readAlert(id: $id) {
      id
      title
      message
      read
    }
  }
`;
