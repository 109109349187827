const importTransactionsFormHelper = (values) => {
  let newValues = {};

  if (!values.importTransaction.enabled) {
    newValues = {
      importTransaction: {
        enabled: values.importTransaction.enabled,
        importTransactionType: values.isInitialImportTransactionTypeNull
          ? null
          : values.importTransaction.importTransactionType,
        updatedAt: values.importTransaction.updatedAt,
      },
      autoTag: {
        enabled: false,
        propertyId: null,
        propertyUnitId: null,
      },
    };
  } else {
    newValues = {
      importTransaction: {
        enabled: values.importTransaction.enabled,
        importTransactionType: values.importTransaction.importTransactionType,
        updatedAt: values.importTransaction.updatedAt,
      },
    };
  }
  return newValues;
};

export const updateVariablesHelper = (fieldName, formValues) => {
  let variables = {};
  if (fieldName === 'importTransaction') {
    variables = importTransactionsFormHelper(formValues);
  } else if (fieldName === 'importTransactionType') {
    variables = {
      importTransaction: {
        enabled: formValues.importTransaction.enabled,
        importTransactionType: formValues.importTransaction.importTransactionType,
        updatedAt: formValues.importTransaction.updatedAt,
      },
    };
  } else if (fieldName === 'autoTag') {
    variables = {
      autoTag: {
        enabled: formValues.autoTag.enabled,
      },
    };
  } else if (fieldName === 'autoTag.propertyId') {
    variables = formValues;
  } else {
    variables = { [fieldName]: formValues[fieldName] };
  }
  return variables;
};
