import React, { useEffect } from 'react';
import { motion, isValidMotionProp, useAnimation } from 'framer-motion';
import { chakra, shouldForwardProp } from '@chakra-ui/react';
import SearchInput from '../SearchInput';

type DropdownSearchWrapperProps = {
  showCustomCategories?: Boolean,
  title: String,
  search: String,
  setSearch: Function,
  searchRef: any,
  animationVariant?: String,
  scrollTop?: Number,
  hideSearch?: Boolean,
  dropdownElementRef?: Boolean,
  selectedOptions: Array,
  children: React.Node,
};

/**
 * The Chakra box that that is used as the animation
 * container for the categories.  Animation-relared properties
 * are forwarded the the underlying Framer Motion element based on
 * the logic below.
 */
const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

/**
 * Reusable search input wrapper component.
 *
 * @param showCustomCategories
 * @param title
 * @param showTitleInSearch
 * @param search
 * @param setSearch
 * @param searchRef
 * @param animationVariant
 * @param scrollTop
 * @param hideSearch
 * @param dropdownElementRef
 * @param selectedOptions
 * @param children
 * @returns {JSX.Element}
 */

const DropdownSearchWrapper = ({
  showCustomCategories,
  title,
  search,
  setSearch,
  searchRef,
  animationVariant,
  scrollTop,
  hideSearch,
  dropdownElementRef,
  selectedOptions,
  children,
}: DropdownSearchWrapperProps) => {
  /**  Exposes ChakraUI's underlying Framer Motion animation
   * controls, so that custom animation can be applied.
   */
  const animate = useAnimation();

  const variantsSearch = {
    open: {
      height: 'initial',
      opacity: 1,
      transition: {
        duration: 0.12,
        ease: 'circIn',
      },
      transitionEnd: {
        overflow: 'unset',
      },
    },
    close: {
      height: 0,
      opacity: 0,
      overflow: 'hidden',
      transition: {
        duration: 0.2,
        ease: 'circOut',
      },
    },
  };

  useEffect(() => {
    if (showCustomCategories && selectedOptions.length === 0) {
      // eslint-disable-next-line no-param-reassign
      dropdownElementRef.current.scrollTop = 0;
    } else if (showCustomCategories && selectedOptions.length > 0) {
      // eslint-disable-next-line no-param-reassign
      dropdownElementRef.current.scrollTop = scrollTop;
    }
  }, [showCustomCategories]);

  /**
   * Applies appropriate animation variant.
   * Written in async function form to ensure
   * scroll position is updated _after_ height is set.
   */
  useEffect(() => {
    (async () => {
      switch (animationVariant) {
        case 'open':
          await animate.set('open');
          break;
        case 'close':
          animate.set('close');
          break;
        case 'beginClosed':
          animate.set('close');
          break;
        default: // do nothing
      }
    })();
  });

  return showCustomCategories !== null ? (
    /* Collapsible category & search blocks */
    <>
      {!hideSearch && (
        <ChakraBox
          {...{
            key: 'custom-collapse-search',
            variants: variantsSearch,
            animate,
          }}
        >
          <SearchInput
            ref={searchRef}
            {...{
              title,
              search,
              setSearch,
              flexShrink: '0',
              flexGrow: '0',
              flexBasis: '74px',
              display: 'block',
              ml: '16px',
              mt: '4px',
            }}
          />
        </ChakraBox>
      )}
      {/* List of Options */}
      {children}
    </>
  ) : (
    <>
      {/* Search */}
      {!hideSearch && <SearchInput ref={searchRef} {...{ title, search, setSearch }} />}
      {/* List of Options */}
      {children}
    </>
  );
};

DropdownSearchWrapper.defaultProps = {
  showCustomCategories: null,
  animationVariant: null,
  scrollTop: 0,
  hideSearch: false,
  dropdownElementRef: null,
};

export default DropdownSearchWrapper;
