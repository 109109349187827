import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useGate } from 'statsig-react';
import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';

import { customerIOApiKey, customerIOSiteId } from '@core/constants/envVars';
import UserContext from '@contexts/UserContext';

export const AnalyticsContext = createContext({ analytics: new AnalyticsBrowser() });

export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
  const { user } = useContext(UserContext);

  const { email, firstName, lastName, id: userId } = user || {};
  const displayName = `${firstName || ''} ${lastName || ''}`;

  const { value: customerIOIntegrationGate } = useGate('customerio-integration-gate');

  const apiKey = customerIOApiKey;
  const analyticsIO = useMemo(() => {
    if (customerIOIntegrationGate) {
      return AnalyticsBrowser.load(
        { writeKey: apiKey, cdnURL: 'https://cdp.customer.io' },
        {
          integrations: {
            'Customer.io In-App Plugin': {
              siteId: customerIOSiteId,
            },
          },
        }
      );
    }
    return null;
  }, [apiKey]);

  const state = useMemo(() => {
    const newState = {};

    newState.analyticsIO = analyticsIO;

    return newState;
  }, [analyticsIO]);

  useEffect(() => {
    if (user && analyticsIO && customerIOIntegrationGate) {
      analyticsIO.identify(userId, {
        name: displayName,
        email,
      });
      analyticsIO.page();
    }
  }, [user, analyticsIO]);

  return <AnalyticsContext.Provider value={state}>{children}</AnalyticsContext.Provider>;
};
