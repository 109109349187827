import React from 'react';
import { Table, Thead, Tr, Th, Text } from '@chakra-ui/react';
import customTheme from '@core/theme';
import formatCurrency from '@core/utils/formatCurrency';

import {
  highlightedRowStyle,
  highlightedHeaderCellStyle,
  tableStyles,
  totalFontStyle,
} from '../../../styles/cashflowStatement.styles';

import { stripDollars } from '../../../helpers/cashflow-statement.helpers';

type HighlightedRowTotalsProps = {
  total: Number,
};

function HighlightedRowTotals({ total }: HighlightedRowTotalsProps) {
  return (
    <Table {...{ ...tableStyles(), position: 'relative', zIndex: '2' }}>
      <Thead>
        <Tr
          {...{
            ...highlightedRowStyle,
            borderRadius: '0 4px 4px 0',
            borderLeftWidth: '1px',
            borderLeftColor: customTheme.colors.brand.neutral['600'],
            borderLeftStyle: 'solid',
          }}
        >
          <Th {...highlightedHeaderCellStyle}>
            <Text
              {...{
                ...totalFontStyle,
                lineHeight: '48px !important',
                color: 'brand.neutral.white',
              }}
            >
              {stripDollars(formatCurrency(total).rounded)}
            </Text>
          </Th>
        </Tr>
      </Thead>
    </Table>
  );
}

export default HighlightedRowTotals;
