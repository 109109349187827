// @flow
import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  lightSecondaryTextStyles,
  normalTextStyles,
  secondaryTextStyles,
  strongTextStyles,
} from './styles/baselaneGridTextItems.styles';
import type { TextVariantType } from './TextVariant.type';
import { TextVariant } from './TextVariant.type';

type Props = {
  items: { text: string, variant: TextVariantType, styles: Object }[],
};

function BaselaneGridTextItems({ items = [] }: Props): any {
  function getStyleForVariant(variantType: TextVariantType): Object {
    switch (variantType) {
      case TextVariant.Strong:
        return strongTextStyles;
      case TextVariant.Normal:
        return normalTextStyles;
      case TextVariant.Secondary:
        return secondaryTextStyles;
      case TextVariant.LightSecondary:
        return lightSecondaryTextStyles;
      default:
        return {};
    }
  }

  return (
    <Box>
      {items.map((item) =>
        item.text === '-' ? (
          <Text key={uuidv4()} {...{ ...getStyleForVariant(item.variant), ...item.styles }}>
            &mdash;
          </Text>
        ) : (
          <Text key={uuidv4()} {...{ ...getStyleForVariant(item.variant), ...item.styles }}>
            {item.text}
          </Text>
        )
      )}
    </Box>
  );
}

export default BaselaneGridTextItems;
