import React from 'react';
import { Stack } from '@chakra-ui/react';
import { IconAddDottedCircle, IconPlus } from '@icons';
import IconAddDottedRectangle from '@icons/legacy/IconAddDottedRectangle';
import type { ButtonPropsType } from '@shared/types/ButtonPropsType';
import BaselaneButtonCard from '../BaselaneButtonCard';
import { actionButtonStyles, iconContainerStyles } from './styles/button.styles';

type ResponsiveActionButtonProps = {
  /**
   * Button children
   */
  children: React.Node,
  /**
   * Button click functionality
   */
  onClick: Function,
  /**
   * Props that can be used to style/modify the button
   */
  buttonProps?: ButtonPropsType,
  /**
   * Specify specific icon that should be used
   */
  iconName?: String,
  /**
   * Specify what icon size the icon should be inside the button
   */
  iconSize?: String,
  /**
   * Specify how the content of the button is shown: left, center
   */
  orientation?: String,
  /**
   * If it is not a common icon, you can override by adding iconElement
   */
  iconElement?: React.Node,
  /**
   * Styles for the icon container. Container is responsible for the overall size of the icon.
   * Size of icon is not hardcoded
   */
  iconContainerStyles?: Object,
  /**
   * Disables the button card if true
   */
  isDisabled?: Boolean,
  /**
   * Adds tooltip with provided text to button card, in both enabled and disabled states
   */
  tooltipText?: string,
};

/**
 * Helper function to grab icon for button
 *
 * @param iconName: string
 * @returns {JSX.Element}
 */
const getIcon = (iconName) => {
  switch (iconName) {
    case 'addDottedRectangle': {
      return <IconAddDottedRectangle shouldSetDimensions={false} />;
    }
    case 'addDottedCircle': {
      return (
        <Stack
          {...{
            border: 'dashed 1px',
            borderColor: 'currentcolor',
            w: '32px',
            h: '32px',
            minH: '32px',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
          }}
        >
          <IconPlus color="currentColor" />
        </Stack>
      );
    }
    case 'plus': {
      return <IconPlus shouldSetDimensions={false} />;
    }
    default:
      return <IconAddDottedCircle shouldSetDimensions={false} />;
  }
};

/**
 * Responsive action button design for tables, but can be used in other scenarios as well if needed
 *
 * @returns {JSX.Element}
 * @component
 */
const ResponsiveActionButton = ({
  children,
  iconSize,
  iconName,
  buttonProps,
  orientation,
  iconElement,
  onClick,
  iconContainerStyles: iContainerStyles,
  isDisabled,
  tooltipText,
}: ResponsiveActionButtonProps) => {
  let iconContainerSize = iconSize === 'lg' ? '40px' : '32px';
  if (iconSize === 'sm') {
    iconContainerSize = '12px';
  }

  const defaultOrientationLogic = iconName === 'plus' ? 'center' : 'left';

  return (
    <BaselaneButtonCard
      type="action"
      buttonStyles={actionButtonStyles(iconSize, orientation ?? defaultOrientationLogic)}
      onClick={onClick}
      {...buttonProps}
      isDisabled={isDisabled}
      tooltipText={tooltipText}
    >
      <Stack {...iconContainerStyles(iconSize, iconContainerSize)} {...iContainerStyles}>
        {iconElement ?? getIcon(iconName)}
      </Stack>
      {children}
    </BaselaneButtonCard>
  );
};

ResponsiveActionButton.defaultProps = {
  buttonProps: {},
  iconSize: 'md',
  iconName: 'addDottedRectangle',
  orientation: null,
  iconElement: null,
  iconContainerStyles: {},
  isDisabled: false,
  tooltipText: null,
};

export default ResponsiveActionButton;
