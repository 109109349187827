import { React } from 'react';
import PaymentMethodFieldsACH from '../../../../Recipients/components/fields/PaymentMethodFieldsACH';
import PaymentMethodFieldsWire from '../../../../Recipients/components/fields/PaymentMethodFieldsWire';
import PaymentMethodFieldsCheck from '../../../../Recipients/components/fields/PaymentMethodFieldsCheck';
import { useSelectedPaymentMethod } from '../../../sendFundsStore';

const PaymentMethodForm = () => {
  const selectedPaymentMethod = useSelectedPaymentMethod();
  if (selectedPaymentMethod?.type === 'ACH') return <PaymentMethodFieldsACH />;
  if (selectedPaymentMethod?.type === 'Wire') return <PaymentMethodFieldsWire />;
  if (selectedPaymentMethod?.type === 'Check') return <PaymentMethodFieldsCheck />;
  return null;
};

export default PaymentMethodForm;
