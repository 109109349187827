import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import customTheme from '@core/theme';
import { copyButtonStyles } from './styles/cardui.styles';

type CopyWrapperProps = {
  children: any,
  id: string,
  showToast?: Function,
};

const CopyWrapper = ({ children, id, showToast }: CopyWrapperProps) => {
  const handleCopyToClipboard = (e, elementId) => {
    const elementToCopy = document.getElementById(elementId).textContent;

    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(elementToCopy)
        .then(() => {
          showToast();
        })
        .catch(() => {
          console.log('was not able to copy');
        });
    }
  };

  return (
    <Stack direction="row" align="center">
      {children}
      <Stack
        direction="row"
        {...{
          color: customTheme.colors.brand.neutral['600'],
          _hover: { color: customTheme.colors.brand.neutral['700'], cursor: 'pointer' },
          align: 'center',
        }}
        onClick={(e) => handleCopyToClipboard(e, id)}
      >
        {id === 'expiryDate' && (
          <Text
            {...copyButtonStyles}
            h="auto"
            w="auto"
            ml="6px"
            _hover={copyButtonStyles[':hover']}
          >
            ❏
          </Text>
        )}
      </Stack>
    </Stack>
  );
};

CopyWrapper.defaultProps = {
  showToast: () => {},
};

export default CopyWrapper;
