import React from 'react';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import themeHabitat from '@core/themeHabitat';
import { BaselaneAlertNew } from '@shared/components';
import { Icon16Info } from '@icons/16px';
import * as ROUTES from '@routes';

const SessionExpiredMessage = () => {
  const navigate = useNavigate();

  return (
    <ChakraProvider theme={themeHabitat}>
      <Box w={{ base: '250px', lg: '33%' }} mb={2}>
        <BaselaneAlertNew
          body="Your session expired due to inactivity"
          variant="warning"
          hasCloseButton
          onCloseClick={() => {
            navigate(ROUTES.LOGIN, { replace: true });
          }}
          visual="icon"
          iconName={Icon16Info}
        />
      </Box>
    </ChakraProvider>
  );
};

export default SessionExpiredMessage;
