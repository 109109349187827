// @flow
import React from 'react';
import { Grid, GridItem, Box, Text } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { TEXT_ALIGN } from '../../constants';
import { headerRowStyles, headerTitleStyles, rowStyles } from './styles/table.style';
import SkeletonTable from './SkeletonTable';

function BaselaneTable({
  tableContent,
  rowWithoutPadding,
  tableHeader,
  tableRow,
  headerStyles,
  overflow,
  hasNoData = false,
}: {
  tableContent: Array,
  tableHeader: Array,
  rowWithoutPadding: boolean,
  tableRow: Function,
  headerStyles: Object,
  overflow: Object,
  hasNoData: boolean,
}): any {
  const paddingValues = rowWithoutPadding ? { px: '0', py: '0' } : { px: '45px', py: '16px' };
  const customGrid = tableHeader.reduce(
    (accumulator, currentValue) => accumulator + (currentValue.width || ' 1fr '),
    ' '
  );
  const columns = `repeat(1, ${customGrid})`;
  const headerRowStyle = { ...headerRowStyles, ...headerStyles };

  return hasNoData ? (
    <SkeletonTable />
  ) : (
    <Box>
      <Grid templateColumns={columns} {...headerRowStyle}>
        {tableHeader.map((item) => {
          const key = uuidv4();
          const headerCellStyles = { justifySelf: TEXT_ALIGN[item.align], p: item.p };
          return (
            <GridItem key={key} {...headerCellStyles}>
              <Text {...headerTitleStyles}>{item.title}</Text>
            </GridItem>
          );
        })}
      </Grid>
      <Box
        maxH={overflow && overflow.enabled ? overflow && overflow.maxHeight : 'auto'}
        overflowY={overflow && overflow.enabled ? 'auto' : 'visible'}
      >
        {tableContent.map((item) => {
          const key = uuidv4();
          return (
            <Box {...rowStyles} {...paddingValues} key={key}>
              {tableRow(item, columns)}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default BaselaneTable;
