import {
  IconApartment,
  IconCondo,
  IconDuplexTriplex,
  IconOther,
  IconSingleFamilyHouse,
  IconTownhome,
} from '@icons';

export const propertyTypeIcon = (type) => {
  switch (type) {
    case 'Condo':
      return IconCondo;
    case 'Apartment':
      return IconApartment;
    case 'Duplex/Triplex':
      return IconDuplexTriplex;
    case 'Single Family Home':
      return IconSingleFamilyHouse;
    case 'Townhome':
      return IconTownhome;
    default:
      return IconOther;
  }
};

export const rentCollectionHelper = (unit) => {
  if (unit?.status === 'outside_baselane') {
    return 'Outside Baselane';
  }

  if (unit?.lease?.state === 'DRAFT' || unit?.lease?.state === 'READY_TO_BE_REVIEWED') {
    return 'Resume Draft';
  }

  const paymentState = unit?.lease && unit?.lease?.paymentState;

  if (paymentState === 'CURRENT' || paymentState === 'OVERDUE') {
    return 'Active Rent Collection';
  }

  return null;
};
