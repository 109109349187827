import React from 'react';
import { Box } from '@chakra-ui/react';
import { BaselaneDivider } from '@core/components/Shared/components';
import StartDateDatepicker from './StartDateDatepicker';
import RepeatEveryField from './RepeatEveryField';
import RepeatOnSelect from './RepeatOnSelect';
import EndRecurringPayment from './EndRecurringPayment';
import UpcomingPaymentsAccordion from './upcoming-payments/UpcomingPaymentsAccordion';

const RecurringPayment = () => {
  return (
    <>
      <Box display="flex" flexDirection="column" mt={2}>
        {/* Start date. */}
        <StartDateDatepicker />

        {/* Repeat every - Repeat on. */}
        <Box display="flex" gap={0.75} flexGrow="1" flexBasis="0">
          <RepeatEveryField />
          <RepeatOnSelect />
        </Box>

        {/* End recurring payment. */}
        <EndRecurringPayment />
      </Box>
      <BaselaneDivider
        styles={{
          mt: 1,
          mb: 1,
        }}
      />
      <UpcomingPaymentsAccordion
        mt={2}
        subheader="If a recurring payment lands on a weekend or holiday, it will be processed the prior
                business day."
      />
    </>
  );
};

export default RecurringPayment;
