import React from 'react';
import { isMobile } from 'react-device-detect';
import { Box } from '@chakra-ui/react';
import SlLoader from '@core/components/Loader';

const UIOverlayLoader = ({ ...rest }) => {
  return (
    <Box
      {...{
        top: -5,
        left: -2,
        position: 'absolute',
        zIndex: '1000',
        bg: 'transparent',
        w: isMobile ? '100%' : '103%',
        height: '113%',
        ...rest,
      }}
    >
      <SlLoader className="" styles={{ position: 'relative' }} />
    </Box>
  );
};

export default UIOverlayLoader;
