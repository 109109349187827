import React from 'react';
import { FormLabel, Box, Switch, FormControl, ChakraProvider } from '@chakra-ui/react';
import themeHabitat from '@core/themeHabitat';
import { descriptionStyles, switchLabelStyles } from './styles/switch.styles';

function BaselaneSwitch({ label, description, isDisabled, htmlFor, ...props }: any) {
  return (
    <ChakraProvider theme={themeHabitat}>
      <FormControl display="flex" alignItems="center">
        <Switch
          {...props}
          // There is a better way for this after upgrating to new version of chackra
          sx={
            isDisabled
              ? {
                  '.chakra-switch__track .chakra-switch__thumb': {
                    bg: 'brand.neutral.400',
                  },
                }
              : {
                  '.chakra-switch__track[data-focus]': {
                    boxShadow: `0 0 0 1px white inset, 0 0 0 1px #0968BF`,
                  },
                }
          }
          isDisabled={isDisabled}
          id={htmlFor}
          mr={1}
        />
        <FormLabel htmlFor={htmlFor}>
          <Box {...switchLabelStyles(isDisabled)}>{label}</Box>
          {description && <Box {...descriptionStyles(isDisabled)}>{description}</Box>}
        </FormLabel>
      </FormControl>
    </ChakraProvider>
  );
}

export default BaselaneSwitch;
