import { GET_CARD_SUMMARY, GET_TRANSFERS } from '@core/components/NativeBankingPage/queries';

export const refetchQueries = [
  {
    query: GET_CARD_SUMMARY,
    variables: {
      page: 1,
      pageSize: 99,
      filter: {
        includeHiddenCards: false,
      },
    },
  },
  { query: GET_TRANSFERS },
];
