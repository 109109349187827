import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

/**
 * This overrides Chakra's default <Table /> styles.
 *
 * see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/table.ts for implementation details
 */

const parts = ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'tfoot', 'caption'];
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Table = defineMultiStyleConfig({
  baseStyle: {
    caption: {
      mt: 2,
    },
    table: {
      fontVariantNumeric: 'normal',
    },
  },
  sizes: {
    sm: {
      th: {
        px: 2,
        py: 0.5,
      },
      td: {
        px: 2,
        py: 1,
      },
      caption: {
        px: 2,
        py: 1,
      },
    },
    md: {
      th: {
        px: 3,
        py: 1.5,
      },
      td: {
        px: 3,
        py: 2,
      },
      caption: {
        px: 2,
        py: 1,
      },
    },
    lg: {
      th: {
        px: 4,
        py: 2,
      },
      td: {
        px: 4,
        py: 2.5,
      },
      caption: {
        px: 3,
        py: 1,
      },
    },
  },
});
