// @flow
import React from 'react';
import { TabList } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { tabListStyles, formWrapper } from '../styles/tabs.styles';

type TabListComponentProps = {
  children: any,
  styles?: Object,
};

function TabListComponent({ children, styles }: TabListComponentProps): any {
  return (
    <TabList {...tabListStyles(isMobile)} {...formWrapper(isMobile)} {...styles}>
      {children}
    </TabList>
  );
}

TabListComponent.defaultProps = {
  styles: {},
};

export default TabListComponent;
