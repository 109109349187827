import React from 'react';
import { Icon16Calendar } from '@icons/16px';
import getBreakPoints from '@core/utils/getBreakPoints';
import DisplayInputDefaultWithRightBtnWrapper from './DisplayInputDefaultWithRightBtnWrapper';

type DisplayInputDefaultDateProps = {
  selectedOptions: Array<Object>,
  handleContentRendererClick: Function,
  placeholder: string,
  title: string,
  handleClearClick: Function,
  showValueByFields: Array<string>,
  hideInputClearBtn?: boolean,
  classNames: Array<string>,
};

const DisplayInputDefaultDate = ({
  selectedOptions,
  handleContentRendererClick,
  placeholder,
  title,
  handleClearClick,
  showValueByFields,
  hideInputClearBtn,
  classNames,
}: DisplayInputDefaultDateProps) => {
  const { isMin1150 } = getBreakPoints();

  return (
    <DisplayInputDefaultWithRightBtnWrapper
      selectedOptions={selectedOptions}
      handleContentRendererClick={handleContentRendererClick}
      placeholder={placeholder}
      title={title}
      handleClearClick={handleClearClick}
      showValueByFields={showValueByFields}
      hideInputClearBtn={hideInputClearBtn}
      classNames={classNames}
    >
      {isMin1150 && <Icon16Calendar />}
    </DisplayInputDefaultWithRightBtnWrapper>
  );
};

DisplayInputDefaultDate.defaultProps = {
  hideInputClearBtn: false,
};

export default DisplayInputDefaultDate;
