import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PROPERTY_SUMMARY } from '@core/apollo/queries';
import { BaselaneButton, TrackerBox } from '@shared/components';
import { TrackersConfig } from '@shared/helpers/onboardingConfig.helper';
import { InProgress } from '@core/components/GetStartedPage/components/Steps';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserContext from '@contexts/UserContext';
import ActionBox from '@core/components/GetStartedPage/components/QuickActions/ActionBox';
import { Icon16PlusCircle } from '@icons/16px';

type AddPropertyButtonProps = {
  btnRef: any,
  addPropertyOnClick: Function,
  property: Object,
  variant: string,
  palette: String,
  buttonStyles: Object,
  buttonLabel: String,
  isOnboarding: boolean,
  from?: Object,
};

const AddPropertyButton = ({
  btnRef,
  addPropertyOnClick,
  property,
  variant,
  palette,
  buttonStyles,
  buttonLabel,
  isOnboarding,
  from = { page: '', section: '', configs: {} },
}: AddPropertyButtonProps) => {
  const { user } = useContext(UserContext);
  const { data: propertyData } = useQuery(GET_PROPERTY_SUMMARY);
  const { totalProperties } = propertyData?.propertySummary || {};
  const hasProperties = totalProperties > 0;

  const { page, section, configs } = from ?? {};

  const sendOnboardingSegmentEvent = () => {
    sendSegmentEvent(TrackersConfig.properties.segmentTrackerId, { landlord_uuid: user.id });
  };

  if (isOnboarding) {
    const config = {
      ...TrackersConfig.properties,
      onClickTrackerBox: (e) => {
        addPropertyOnClick(e);
        sendOnboardingSegmentEvent();
      },
      isCompleted: hasProperties,
    };
    return <TrackerBox trackerData={config} />;
  }

  if (page === 'get_started') {
    if (section === 'rc_tracker' || section === 'bookkeeping_tracker') {
      return (
        <InProgress
          status={configs.status}
          handleTrackerInProgStepClick={(e) => addPropertyOnClick(e)}
        />
      );
    }

    if (section === 'quick_actions') {
      return <ActionBox action={{ ...configs, onClick: addPropertyOnClick }} />;
    }
  }

  return (
    <BaselaneButton
      ref={btnRef}
      onClick={addPropertyOnClick}
      variant={page === 'insurance' ? 'transparent' : variant}
      palette={page === 'insurance' ? 'primary' : palette}
      id={property ? 'details-button' : 'add-property-button'}
      leftIcon={page === 'insurance' && <Icon16PlusCircle />}
      styles={{ marginTop: page === 'insurance' ? '12px' : '0', minWidth: 'auto', width: 'auto' }}
    >
      {buttonLabel}
    </BaselaneButton>
  );
};

AddPropertyButton.defaultProps = {
  from: { page: '', section: '', configs: ({}: { ... }) },
};

export default AddPropertyButton;
