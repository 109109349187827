export const vouchedVerificationDrawerDescription = {
  buttonText: 'Identity Verification',
  drawerTitle: 'Identify Verification',
  title: 'Upload ID to Verify Your Identity',
  text: ' We need to check that you are who you say you are. Here is how it works:',
  note: "* We'll use this information solely for identify verification purposes.",
};

export const vouchedVerificationSteps = [
  {
    key: '1',
    text: 'Click “Verify Identity” to be taken to our secure pop-up',
  },
  {
    key: '2',
    text: 'Take a photo of your government-issued photo ID*',
  },
  {
    key: '3',
    text: 'Follow instructions to take a selfie',
  },
];

export const vouchedVerificationInProgressState = {
  drawerTitle: 'Identify Verification',
  title: 'Identify verification in progress',
  textLine1: 'We are currently verifying your identity. This process may take up to 24 hours.',
  textLine2:
    "While we work diligently to confirm your identity, feel free to explore Baselane's features.",
};
