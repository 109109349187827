import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { HStack, Spacer, Spinner, Stack, Text } from '@chakra-ui/react';
import {
  BaselaneDivider,
  BaselaneDropdown,
  NoPropertyTooltip,
} from '@core/components/Shared/components';
import { CASHFLOW_QUERY } from '@core/apollo/queries';
import CashFlowContext from '@contexts/CashFlowContext';
import TransactionContext from '@contexts/TransactionContext';
import {
  DEFAULT_TAX_PACKAGE_TIME_PERIOD,
  defaultTaxPackageTimePeriod,
} from '@core/components/Shared/helpers/cashFlow.helpers';
import { getOption } from '@shared/components/BaselaneDropdown/components/DatePicker/helpers/datepickerDropdown.helpers';
import { formatDate } from '@core/utils/formatDate';
import {
  getPropertyData,
  renderPropertyDropdownParentItem,
  renderPropertyDropdownChildItem,
} from '@shared/helpers/propertiesFilter.helpers';
import TaxPackageDownload from './TaxPackageDownload';
import FAQ from './FAQ';
import {
  convertToDateObject,
  getOptionsCategories,
  getPresetSubCategoriesTags,
} from '../../helpers/cashflow.helpers';
import { cashflowTaxPackageStyles } from './styles/cashflowTaxPackage.styles';

type CashflowTaxPackageProps = {
  defaultPresetName: String,
  presetOptions: Array<Object>,
  categoryOptions: Array<any>,
};

function CashflowTaxPackage({
  defaultPresetName,
  presetOptions,
  categoryOptions,
}: CashflowTaxPackageProps) {
  // Data from Transactions Context
  const {
    filters: trxFilters,
    setFilters: setTrxFilters,
    propertiesData,
    DEFAULT_FILTERS: defaultTrxFilters,
  } = useContext(TransactionContext);

  // Data from CashFlow Context
  const {
    taxPackageFilters,
    setTaxPackageFilters,
    defaultTaxPackageFilters,
    tagsLoading,
    categoryWithSubOptions,
    categoryMap,
  } = useContext(CashFlowContext);

  // State Vars
  const defaultDate = getOption(
    new Date(moment(defaultTaxPackageFilters.filter.from)),
    new Date(moment(defaultTaxPackageFilters.filter.to))
  );

  const [selectedCustomCategories, setSelectedCustomCategories] = useState([]);
  const [date, setDate] = useState(defaultDate);
  const [datePresetFilter, setDatePresetFilter] = useState(defaultTaxPackageTimePeriod?.name);
  const [hasNoTrxs, setHasNoTrxs] = useState(false);

  // QUERY: Get Cash Flow
  const [
    getTaxPackageCashFlow,
    { data: taxPackageCashFlowData, loading: isTaxPackageCashFlowLoading },
  ] = useLazyQuery(CASHFLOW_QUERY);

  // Categorized/Uncategorized Count
  const { cashFlow } = taxPackageCashFlowData ?? {};
  const { summary } = cashFlow ?? {};
  const { categorizedTrxCount = 0, uncategorizedTrxCount = 0 } = summary ?? {};

  const { totalDocuments = 0 } = taxPackageCashFlowData?.cashFlow ?? {};

  // Get TagId to set filter for Cash flow query
  const defaultPreset =
    defaultPresetName && presetOptions
      ? presetOptions?.find((option) => {
          return option?.name === defaultPresetName;
        })
      : null;

  // Date Filter Change
  const handleDateFilterChange = (selectedStartDate, selectedEndDate, dateFilter) => {
    const updatedTrxFilters = {
      ...defaultTrxFilters,
      filter: {
        ...trxFilters.filter,
        from: formatDate(selectedStartDate, 'YYYY-MM-DD') || null,
        to: formatDate(selectedEndDate, 'YYYY-MM-DD') || null,
      },
      sort: { ...trxFilters.sort },
    };

    setHasNoTrxs(false); // hide the error msg
    setDatePresetFilter(dateFilter);
    setDate(getOption(new Date(moment(selectedStartDate)), new Date(moment(selectedEndDate))));
    setTrxFilters(updatedTrxFilters);
    setTaxPackageFilters({
      filter: {
        ...taxPackageFilters.filter,
        from: formatDate(selectedStartDate, 'YYYY-MM-DD') || null,
        to: formatDate(selectedEndDate, 'YYYY-MM-DD') || null,
      },
    });
  };

  // Property Filter Change
  const handlePropertyFilterChange = (selectedProperties) => {
    const [p, u] = selectedProperties.reduce(
      (acc, trx) => {
        acc[trx.isChild ? 1 : 0].push(trx);
        return acc;
      },
      [[], []]
    );

    const properties = p.map((cat) => cat.id);
    const units = u.map((cat) => cat.unitId);
    const updatedTrxFilters = {
      ...defaultTrxFilters,
      filter: {
        ...trxFilters.filter,
        propertyId: properties.length > 0 ? properties : null,
        unitId: units.length > 0 ? units : null,
      },
      sort: trxFilters.sort,
    };

    setHasNoTrxs(false); // hide the error msg
    setTrxFilters(updatedTrxFilters);
    setTaxPackageFilters({
      filter: {
        ...taxPackageFilters.filter,
        propertyId: properties.length > 0 ? properties : null,
        unitId: units.length > 0 ? units : null,
      },
    });
  };

  // Helper Func
  const propertyOptions = getPropertyData(propertiesData);
  const optionsCategories = getOptionsCategories(categoryOptions);
  const getSelectedCategories = (tagIds) => {
    const selectedCategories = optionsCategories?.reduce((acc, item) => {
      const selectedTags = item.items.filter((i) => tagIds?.find((tagId) => tagId === i.id));
      return acc.concat(selectedTags);
    }, []);
    return selectedCategories;
  };

  useEffect(() => {
    if (defaultPreset && !tagsLoading) {
      const { tagId: defaultTagIds } = defaultPreset || {};
      const tagId =
        [
          ...defaultTagIds,
          ...getPresetSubCategoriesTags(defaultPreset, categoryWithSubOptions, categoryMap),
        ] ?? null;
      setTrxFilters({
        filter: {
          ...defaultTrxFilters.filter,
          from: defaultTaxPackageFilters.filter.from,
          to: defaultTaxPackageFilters.filter.to,
          isCategorized: !defaultTaxPackageFilters.filter.uncategorized,
          tagId,
        },
        sort: defaultTrxFilters.sort,
      });
      setTaxPackageFilters({
        filter: {
          ...defaultTaxPackageFilters.filter,
          tagId,
        },
      });
      setSelectedCustomCategories(getSelectedCategories(tagId));
    }
  }, [defaultPreset, tagsLoading]);

  useEffect(() => {
    getTaxPackageCashFlow({
      fetchPolicy: 'cache-and-network',
      variables: { input: taxPackageFilters },
    });
  }, [taxPackageFilters]);

  const { container, title, description, taxpackage, error } = cashflowTaxPackageStyles;

  return (
    <Stack {...container}>
      <>
        <Text {...title}>Tax Package</Text>
        <Text {...description}>
          Share your property financials with a tax preparer. Download a Schedule E report (CSV) and
          a ledger of transactions (CSV).
        </Text>
      </>

      <Stack {...taxpackage.container}>
        <Text {...taxpackage.title}>Select period and properties to include in tax package</Text>

        <Stack {...taxpackage.groupcontainer}>
          {/* Date */}
          <HStack {...taxpackage.filterrow}>
            <Text {...taxpackage.label}>Time Period</Text>
            <Spacer />
            {/* Date Filter */}
            <BaselaneDropdown
              {...{
                type: 'date',
                title: 'Date',
                isMulti: true,
                hasFilterWrapper: true,
                handleSubmit: handleDateFilterChange,
                defaultTimePeriod: DEFAULT_TAX_PACKAGE_TIME_PERIOD,
                defaultTimePeriodDate: defaultTaxPackageTimePeriod,
                selectedStartDate:
                  date?.from ?? convertToDateObject(defaultTaxPackageTimePeriod?.from),
                selectedEndDate: date?.to ?? convertToDateObject(defaultTaxPackageTimePeriod?.from),
                clearButtonText: 'Reset',
                parentId: 'cashflow-tabs',
              }}
            />
          </HStack>

          {/* Properties */}
          <HStack {...taxpackage.filterrow}>
            <Text {...taxpackage.label}>Properties</Text>
            <Spacer />
            {/* Properties/Units Filter */}
            {propertyOptions?.length === 0 ? (
              <NoPropertyTooltip>
                <BaselaneDropdown
                  {...{
                    isDisabled: true,
                    type: 'tier2',
                    title: 'All Properties',
                    placeholder: 'All Properties',
                  }}
                />
              </NoPropertyTooltip>
            ) : (
              <BaselaneDropdown
                {...{
                  type: 'tier2',
                  data: propertyOptions,
                  searchTerm: 'name',
                  title: 'All Properties',
                  showValueByFields: ['name'],
                  parentItemRenderer: ({ item }) => renderPropertyDropdownParentItem(item),
                  childItemRenderer: ({ item }) => renderPropertyDropdownChildItem(item),
                  handleSubmit: handlePropertyFilterChange,
                  isMulti: true,
                  hasFilterWrapper: true,
                  hasDropdownClearedExternally: false,
                  setHasDropdownClearedExternally: false,
                  parentId: 'cashflow-tabs',
                }}
              />
            )}
          </HStack>
        </Stack>

        <BaselaneDivider styles={taxpackage.divider} />

        <Stack {...taxpackage.groupcontainer}>
          {/* Categorized Trx Count */}
          <HStack {...taxpackage.countrow}>
            <Text {...taxpackage.label}>Categorized Transactions</Text>
            <Spacer />

            {isTaxPackageCashFlowLoading ? (
              <Spinner {...taxpackage.loader} />
            ) : (
              <Text {...taxpackage.count}>{categorizedTrxCount}</Text>
            )}
          </HStack>

          {/* Uncategorized Trx Count */}
          <HStack {...taxpackage.countrow}>
            <Text {...taxpackage.label}>Uncategorized Transactions</Text>
            <Spacer />
            {isTaxPackageCashFlowLoading ? (
              <Spinner {...taxpackage.loader} />
            ) : (
              <Text {...taxpackage.count}>{uncategorizedTrxCount}</Text>
            )}
          </HStack>

          {/* Attachments */}
          <HStack {...taxpackage.countrow}>
            <Text {...taxpackage.label}>Included Attachments</Text>
            <Spacer />
            {isTaxPackageCashFlowLoading ? (
              <Spinner {...taxpackage.loader} />
            ) : (
              <Text {...taxpackage.count}>{totalDocuments}</Text>
            )}
          </HStack>
        </Stack>

        {/* Download */}
        <HStack {...taxpackage.downloadbutton}>
          {!!selectedCustomCategories.length && (
            <TaxPackageDownload
              {...{
                trxFilters,
                taxPackageCashFlowData,
                selectedCustomCategories,
                categoryOptions,
                isTaxPackageCashFlowLoading,
                categorizedTrxCount,
                uncategorizedTrxCount,
                setHasNoTrxs,
                datePresetFilter,
                totalDocuments,
              }}
            />
          )}
        </HStack>

        {/* Error Message */}
        {hasNoTrxs && (
          <Text {...error}>
            No transactions for the selected period/properties. Update selection and try again.
          </Text>
        )}
      </Stack>

      {/* FAQs */}
      <FAQ />
    </Stack>
  );
}

export default CashflowTaxPackage;
