import React from 'react';
import { BaselaneGrid } from '@shared/components';

const TableRow = ({ items, ...styles }: { items: Array<Object> }) => {
  return (
    <BaselaneGrid
      gridItems={items}
      templateColumns="1fr 1fr 1fr 5%"
      gridStyles={styles}
      gridItemStyles={{ overflow: 'hidden' }}
      gap="10px"
    />
  );
};

export default TableRow;
