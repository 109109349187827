import { React, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Flex, Stack, Text, FormControl } from '@chakra-ui/react';
import customTheme from '@core/theme';
import { Icon12Info } from '@icons/12px';
import {
  BaselaneFormLabel,
  BaselaneTextArea,
  BaselaneDivider,
  BaselaneTooltip,
  BaselaneFormErrorMessage,
} from '@shared/components';
import { useSetIsCurrentStepDisabled } from '../../../sendFundsStore';
import { formStyles } from '../../../styles';
import BookkeepingFields from './BookkeepingFields';

const BookkeepingDetails = () => {
  const formik = useFormikContext();
  const { values, handleChange, handleBlur, errors, touched } = formik;
  const { controlStyles, labelSubtextStyles, dividerStyles } = formStyles;
  const setIsCurrentStepDisabled = useSetIsCurrentStepDisabled();
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setIsCurrentStepDisabled(true);
    } else {
      setIsCurrentStepDisabled(false);
    }
  }, [errors, values]);

  return (
    <Stack gap={3}>
      {/* Description  */}
      {values?.paymentMethodType !== 'ACH' && (
        <Stack gap={3}>
          <FormControl
            isInvalid={errors.description && touched.description}
            id="send-transfer-description"
            {...controlStyles}
          >
            <BaselaneFormLabel textStyle="sm" htmlFor="description">
              <Flex as="span" gap={0.5} align="center">
                {values?.paymentMethodType === 'CHECK_PAYMENT'
                  ? 'Memo'
                  : 'Message for the recipient'}
                <Text {...labelSubtextStyles}>(optional)</Text>
                <BaselaneTooltip
                  label={
                    values?.paymentMethodType === 'CHECK_PAYMENT'
                      ? 'A short message to be printed on the memo line of the check. This is typically used to specify the purpose of the check.'
                      : 'Short message to your recipient. Use this to enter further credit wire instruction as provided by your bank. ie. FFC - Bank Name, Account Owner Name, Account Number'
                  }
                  styles={{ w: '250px' }}
                  placement="top"
                >
                  <Icon12Info color={customTheme.colors.brand.neutral['500']} />
                </BaselaneTooltip>
              </Flex>
            </BaselaneFormLabel>
            <BaselaneTextArea
              size="lg"
              id="description"
              name="description"
              placeholder="Input text"
              maxChars="50"
              hasCounter
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              height="48px"
              minHeight="auto"
              isInvalid={errors.description && touched.description}
            />
            <BaselaneFormErrorMessage>{errors.description}</BaselaneFormErrorMessage>
          </FormControl>
          <BaselaneDivider styles={dividerStyles} />
        </Stack>
      )}

      <Text textStyle="headline-sm" color="brand.neutral.900">
        Bookkeeping
      </Text>
      <BookkeepingFields />
    </Stack>
  );
};

export default BookkeepingDetails;
