// @flow
import React, { useContext, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import { Stack, Box, Text, Input, FormLabel, useToast } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { useMutation } from '@apollo/client';
import { BaselaneButton, BaselaneDivider } from '@shared/components';
import { phoneMaskWithCountryCode } from '@core/utils/masks/index';
import UserContext from '@contexts/UserContext';
import { formDescriptionStyles } from '@shared/components/BaselaneKYCForm/styles/kycForm.styles';
import { UPDATE_USER } from '../../../queries';
import {
  titleStyles,
  formLabelStyles,
  formInputStyles,
  errorStyles,
  profileDataContainerStyles,
} from '../../../styles/userProfileTabsPanels.styles';

function BusinessProfile(): any {
  const formikRef = useRef();

  const { user, refetchUser } = useContext(UserContext);
  const [updateUser] = useMutation(UPDATE_USER);

  const { businessProfile } = user;
  const initialFormValues = businessProfile;

  const [isLoading, setIsLoading] = useState(false);
  const [showEmailError, setEmailError] = useState(false);

  const toast = useToast();

  const showSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Bussiness profile saved successfully. ',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleValidation = (values) => {
    const errors = {};
    setEmailError(false);
    if (
      values.dbaEmail.length > 0 &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.dbaEmail)
    ) {
      errors.dbaEmail = 'Invalid email address';
      setEmailError(true);
    }
    return errors;
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      formikRef.current.handleSubmit();
    }
  };

  const handleFormSubmit = (data) => {
    const { dba, dbaEmail, dbaPhoneNumber } = data;
    setIsLoading(true);
    updateUser({
      variables: {
        ...user,
        businessProfile: { dba, dbaEmail, dbaPhoneNumber },
      },
    })
      .then(() => {
        refetchUser().then(() => {
          setIsLoading(false);
        });
        showSuccessToast();
      })
      .catch((err) => {
        console.log(err);
        showErrorToast();
      });
  };

  return (
    <Stack spacing="32px" {...profileDataContainerStyles(isMobile)}>
      <Text {...titleStyles}>Business Profile Displayed to Tenants</Text>
      <Text {...formDescriptionStyles}>
        Information below will be shown to tenants in place of your primary profile information.
      </Text>
      {isMobile && <BaselaneDivider />}

      <Formik
        innerRef={formikRef}
        validate={(values) => handleValidation(values)}
        initialValues={initialFormValues}
        onSubmit={handleFormSubmit}
        validateOnChange
        validateOnMount
      >
        {({
          values,
          handleSubmit,
          isValid,
          handleBlur,
          handleChange = () => {},
          errors,
          touched,
        }) => (
          <form style={{ width: '100%' }}>
            <Box>
              <Box position="relative">
                <FormLabel htmlFor="dba" {...formLabelStyles}>
                  Name (doing business as)
                </FormLabel>
                <Input
                  {...formInputStyles}
                  id="dba"
                  name="dba"
                  value={values.dba}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="John Doe"
                />
              </Box>
              <Box pt="32px" position="relative">
                <FormLabel htmlFor="dbaEmail" {...formLabelStyles}>
                  Email
                </FormLabel>
                <Input
                  {...formInputStyles}
                  id="dbaEmail"
                  name="dbaEmail"
                  value={values.dbaEmail}
                  isInvalid={errors.dbaEmail && touched.dbaEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleOnKeyDown}
                  placeholder="johndoe@email.com"
                />
                {showEmailError ? <Text {...errorStyles}>{errors.dbaEmail}</Text> : null}
              </Box>
              <Box pt={4} position="relative" mb={1.5}>
                <FormLabel htmlFor="dbaPhoneNumber" {...formLabelStyles}>
                  Phone Number
                </FormLabel>
                <Input
                  {...formInputStyles}
                  as={MaskedInput}
                  mask={phoneMaskWithCountryCode('1')}
                  id="dbaPhoneNumber"
                  name="dbaPhoneNumber"
                  value={values.dbaPhoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="+1 (***) *** ****"
                />
              </Box>

              <BaselaneButton
                variant="filled"
                palette="primary"
                size="md"
                isDisabled={!isValid}
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                Save
              </BaselaneButton>
            </Box>
          </form>
        )}
      </Formik>
    </Stack>
  );
}

export default BusinessProfile;
