import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { Icon16CheckCircle, Icon16Close } from '@icons/16px';

import {
  firstThStyles,
  secondThStyles,
  thirdThStyles,
  textStyles,
  notesTextStyles,
} from '../styles/compareTable.styles';

export const tableData = [
  {
    coverage: 'Building',
    withObie: <Icon16CheckCircle color="#50A0E8" />,
    owners: <Icon16Close color="#000619" />,
  },
  {
    coverage: 'General Liability',
    withObie: <Icon16CheckCircle color="#50A0E8" />,
    owners: <Icon16Close color="#000619" />,
  },
  { coverage: 'Personal Property', withObie: 'N/A', owners: <Icon16Close color="#010113" /> },
  {
    coverage: 'Loss of Rent',
    withObie: <Icon16CheckCircle color="#50A0E8" />,
    owners: <Icon16Close color="#000619" />,
  },
  {
    coverage: 'Other Structures',
    withObie: (
      <HStack position="relative">
        <Icon16CheckCircle color="#50A0E8" />{' '}
        <Box as="span" {...notesTextStyles}>
          1
        </Box>
      </HStack>
    ),
    owners: (
      <HStack position="relative">
        <Icon16Close color="#000619" />{' '}
        <Box as="span" {...notesTextStyles}>
          2
        </Box>
      </HStack>
    ),
  },
  {
    coverage: 'Medical Payments',
    withObie: (
      <HStack position="relative">
        <Box as="span" {...textStyles}>
          N/A
        </Box>
        <Box as="span" {...notesTextStyles}>
          3
        </Box>
      </HStack>
    ),
    owners: <Icon16Close color="#000619" />,
  },
];

export const tableHeaderData = [
  {
    id: '1',
    label: 'Coverage',
    mobileLabel: 'Coverage',
    align: 'left',
    styles: firstThStyles,
  },
  {
    id: '2',
    label: 'Landlord insurance with Baselane + Obie',
    mobileLabel: 'Landlord insurance',
    align: 'center',
    styles: secondThStyles,
  },
  {
    id: '3',
    label: 'Homeowners insurance',
    mobileLabel: 'Homeowners insurance',
    align: 'center',
    styles: thirdThStyles,
  },
];
