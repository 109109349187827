// @flow
export const KPIs = {
  asset: {
    order: 0,
    name: 'Asset Value',
  },
  noi: {
    order: 1,
    name: 'NOI',
  },
  roe: {
    order: 2,
    name: 'ROE',
  },
  capRate: {
    order: 3,
    name: 'CAP Rate',
  },
  cashOnCash: {
    order: 4,
    name: 'Cash-on-Cash',
  },
};
