export const getSortedPropertiesForAutoTag = ({ property }) => {
  const sortedProperties =
    property && property.length > 0
      ? [...property].sort((a, b) => {
          const nameA = a.name;
          const nameB = b.name;
          if (nameA > nameB) {
            return 1;
          }

          if (nameA > nameB) {
            return -1;
          }

          return 0;
        })
      : [];

  return sortedProperties;
};
