import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import AddEditProperty from '@pages/PropertiesPage/AddEditProperty';
import PropertySetUp from '@pages/PropertiesResponsivePage/PropertySetUp';
import SuccessDrawer from '@pages/PropertiesPage/SuccessDrawer';

export const getPropertyLink = ({ key, from, handleStepUpdate, refetchUser, propertiesProps }) => {
  const { propertiesSummaryData, newProperty, setNewProperty, successDrawerRef } =
    propertiesProps ?? {};
  const { propertySummary } = propertiesSummaryData ?? {};
  const { totalProperties = 0, totalUnits = 0 } = propertySummary || {};

  return isMobile ? (
    <PropertySetUp
      {...{ key, from }}
      handleCreatePropertySuccess={() => handleStepUpdate({ isAPIUpdated: true })}
      handleDrawerClose={() => handleStepUpdate({ isDrawerClosed: true })}
    />
  ) : (
    <Fragment key={key}>
      <AddEditProperty
        {...{
          key,
          totalProperties,
          totalUnits,
          setNewProperty,
          successDrawerRef,
          from,
          handleCreatePropertySuccess: refetchUser,
        }}
      />
      <SuccessDrawer successDrawerRef={successDrawerRef} property={newProperty} />
    </Fragment>
  );
};
