import { gql } from '@apollo/client';

export const GET_KYC_URL = gql`
  query getKYCUrl($bankId: ID, $isBankingFirst: Boolean) {
    unitOnBoarding(bankId: $bankId, isBankingFirst: $isBankingFirst)
  }
`;

export const USER_VERIFICATION = gql`
  query getOTP($bankId: Float!) {
    unitAPIVerification(bankId: $bankId)
  }
`;

export const USER_SENSITIVE_TOKEN = gql`
  query getUserSensitiveTokenData($otpCode: String!, $bankId: Float!) {
    unitAPISensitiveToken(otpCode: $otpCode, bankId: $bankId) {
      expiresIn
      token
    }
  }
`;

export const NON_SENSITIVE_TOKEN = gql`
  query unitAPINonSensitiveToken($bankId: Float!) {
    unitAPINonSensitiveToken(bankId: $bankId) {
      expiresIn
      token
    }
  }
`;

export const CREATE_BASELANE_SUBACCOUNT = gql`
  mutation createBaselaneSubAccount(
    $autoTag: AutoTagInput
    $nickName: String!
    $parentAccountId: Float!
    $plaidItemId: String!
    $accountSubType: String
  ) {
    createBaselaneSubAccount(
      autoTag: $autoTag
      nickName: $nickName
      parentAccountId: $parentAccountId
      plaidItemId: $plaidItemId
      accountSubType: $accountSubType
    ) {
      id
      accountNumber
      accountStatus
      routingNumber
      nickName
      accountName
      accountType
      accountSubType
      updatedAt
      availableBalance
      currentBalance
      isConnected
      bankAccountMetadata {
        payoutFailedReason {
          failureCode
          failureMessage
        }
        lastPayoutFailed
      }
      autoTag {
        enabled
        propertyUnitId
        propertyId
      }
      importTransaction {
        enabled
        importTransactionType
        updatedAt
      }
      linkedFor {
        linkFor
        property {
          id
          propertyUnitId
        }
      }
    }
  }
`;

export const INITIATE_CHECK_DEPOSIT = gql`
  mutation initiateCheckDeposit(
    $amount: Float!
    $note: String
    $propertyId: ID
    $tagId: ID
    $toTransferAccountId: ID!
    $unitId: ID
  ) {
    initiateCheckDeposit(
      input: {
        amount: $amount
        note: $note
        propertyId: $propertyId
        tagId: $tagId
        toTransferAccountId: $toTransferAccountId
        unitId: $unitId
      }
    ) {
      externalId
    }
  }
`;
