import React, { useContext } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserContext from '@contexts/UserContext';
import { calculateTabIndex } from '@pages/UnifiedRentCollection/UnifiedLeaseUI/helpers/unifiedLeaseFlow.helpers';
import { unifiedLeaseSegmentEventNames } from '@pages/LeasesPage/LeaseSection/helpers/createLease.helpers';
import { IconArrowRight, IconArrowLeft, IconCheck } from '@icons';

import { Icon16Delete } from '@icons/16px';
import {
  footerStyles,
  footerDeleteStyles,
  footerButtonStyles,
} from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import { MenuState, LEASE_TABS } from './initialMenuState';

type FooterWrapperProps = {
  handleCreateLease: Function,
  unitState: string,
  leaseMenuOptions: Array<MenuState>,
  tabIndex: number,
  setTabIndex: Function,
  formRefs: any,
  existingTenantState: Object,
  setExistingTenantState: Function,
  saveLease: Function,
  unit: Object,
  radioValue: string,
  selectedProperty: Object,
  selectingUnitPanel: boolean,
  setSelectingUnitPanel: Function,
  addingPropertyPanel: boolean,
  setAddingPropertyPanel: Function,
  hasConnectedBank: boolean,
  showReceivingBAEmptyState: boolean,
  setShowReceivingBAEmptyState: Function,
  additionalFeeFormRef: any,
  monthlyFeeFormRef: any,
  handleDeleteLease: Function,
  clickedPropertyUnit: any,
  setResaveLease: Function,
};

function FooterWrapper({
  handleCreateLease,
  unitState,
  leaseMenuOptions,
  tabIndex,
  setTabIndex,
  formRefs,
  existingTenantState,
  setExistingTenantState,
  saveLease,
  unit,
  radioValue,
  selectedProperty,
  selectingUnitPanel,
  setSelectingUnitPanel,
  addingPropertyPanel,
  setAddingPropertyPanel,
  hasConnectedBank,
  showReceivingBAEmptyState,
  setShowReceivingBAEmptyState,
  additionalFeeFormRef,
  monthlyFeeFormRef,
  handleDeleteLease,
  clickedPropertyUnit,
  setResaveLease,
}: FooterWrapperProps): any {
  const { isMax768: isMobile } = getBreakPoints();
  const { user } = useContext(UserContext);
  const { clickedUnit, clickedProperty } = clickedPropertyUnit;
  const handleFooterState = (prevState, isPreviousOnClick) => {
    if (!isPreviousOnClick) {
      sendSegmentEvent(
        unifiedLeaseSegmentEventNames(user?.onboardingCompleted)[
          prevState === LEASE_TABS.PROPERTY && !selectingUnitPanel ? prevState : prevState + 1
        ],
        {
          landlord_uuid: user.id,
          lease_uuid: unit?.lease?.id,
          property_uuid: unit?.lease?.propertyUnitId,
          unit_uuid: unit?.id,
          landlord_name: user.firstName,
          landlord_email: user.email,
        }
      );
    }

    return isPreviousOnClick ? prevState - 1 : prevState + 1;
  };

  const handleFooterNavigation = (isPreviousOnClick) => {
    // monthly and additional fee boxes are not part of default form, check these separately
    const monthlyFeesOpen = document.getElementById('monthly-fees-box');
    const additionalFeesOpen = document.getElementById('additional-fees-box');

    // on Property is treated differently
    if (!isPreviousOnClick && tabIndex === LEASE_TABS.PROPERTY && !selectingUnitPanel) {
      if (
        !addingPropertyPanel &&
        selectedProperty &&
        selectedProperty.units &&
        !selectedProperty.units[1] &&
        radioValue &&
        radioValue === selectedProperty.units[0].id
      ) {
        // selected a property, there is no more than one unit, check correct tab
        handleFooterState(LEASE_TABS.PROPERTY, false);
        if (clickedUnit?.lease) {
          const newTabIndex = calculateTabIndex(clickedUnit?.lease?.completedTags) || 1;
          // set correct tabIndex
          setTabIndex(newTabIndex);
          saveLease();
        } else {
          saveLease({ isStartNewLease: true });
          setTabIndex(1);
        }
      } else if (
        !addingPropertyPanel &&
        selectedProperty &&
        selectedProperty.units &&
        selectedProperty.units[1]
      ) {
        // selected a property, there is more than one unit
        handleFooterState(LEASE_TABS.PROPERTY, false);
        setSelectingUnitPanel(true);
      } else if (!unit && addingPropertyPanel) {
        // selected a property, now selecting a unit
        setSelectingUnitPanel(true);
      }
    } else if (
      !isPreviousOnClick &&
      tabIndex === LEASE_TABS.PROPERTY &&
      selectingUnitPanel &&
      clickedUnit
    ) {
      // selected a unit
      handleFooterState(LEASE_TABS.PROPERTY, false);
      if (clickedUnit?.lease) {
        const newTabIndex = calculateTabIndex(clickedUnit?.lease?.completedTags) || 1;
        // set correct tabIndex
        setTabIndex(newTabIndex);
        saveLease();
      } else {
        saveLease({ isStartNewLease: true });
        setTabIndex(1);
      }
    } else if (isPreviousOnClick && tabIndex === LEASE_TABS.PROPERTY) {
      setSelectingUnitPanel(false);
      setAddingPropertyPanel(false);
    } else if (
      isPreviousOnClick &&
      tabIndex === LEASE_TABS.ACCOUNTS &&
      showReceivingBAEmptyState &&
      hasConnectedBank
    ) {
      setShowReceivingBAEmptyState(false);
    }

    // free pass for invoices
    if (tabIndex === LEASE_TABS.INVOICES && !isPreviousOnClick) {
      handleFooterState(LEASE_TABS.INVOICES, false);
      setTabIndex(tabIndex + 1);
      saveLease({ newVariables: { invoicesConfirmed: true } });
    } else if (
      (tabIndex > 0 && tabIndex !== LEASE_TABS.ACCOUNTS) ||
      (tabIndex === LEASE_TABS.ACCOUNTS &&
        (!showReceivingBAEmptyState || (showReceivingBAEmptyState && !hasConnectedBank)))
    ) {
      // if we are in the lease setup, validate the current form first
      const form = formRefs[tabIndex];
      const formVars = form?.current?.values;
      if (form && !isPreviousOnClick) {
        // validate form before moving on to next tab if form is not valid do not move to next tab
        form?.current
          ?.validateForm()
          .then((errors) => {
            // in case of Tenant Tab we have the new tenant form and the existing tenant
            if (tabIndex === LEASE_TABS.TENANT) {
              const newTenantFormValues =
                !!formVars?.firstName && !!formVars?.lastName && !!formVars?.email;
              const newTenantFormPartlyFilledOut =
                !newTenantFormValues &&
                (!!formVars?.firstName || !!formVars?.lastName || !!formVars?.email);
              const newTenantFormEmpty =
                !formVars?.firstName && !formVars?.lastName && !formVars?.email;
              setExistingTenantState((prevState) => ({ ...prevState, changeTab: true }));
              if (
                (existingTenantState.existingTenantToggle && !existingTenantState.isInvalid) ||
                (!existingTenantState.existingTenantToggle &&
                  !newTenantFormEmpty &&
                  !newTenantFormPartlyFilledOut)
              ) {
                // existing tenant, proceed
                setTabIndex((prevState) => handleFooterState(prevState, isPreviousOnClick));
                saveLease();
              } else if (existingTenantState.isInvalid && !newTenantFormValues) {
                // ignore footer nav press but make sure we display inline error
                form?.current?.submitForm();
              } else {
                // if submit form goes well we can proceed
                form?.current?.submitForm().then(() => {
                  if (
                    (existingTenantState.existingTenantToggle && !existingTenantState.isInvalid) ||
                    (!existingTenantState.existingTenantToggle && newTenantFormValues)
                  ) {
                    setTabIndex((prevState) => handleFooterState(prevState, isPreviousOnClick));
                    setTimeout(() => setResaveLease(true), 100);
                  }
                });
              }
            } else if (Object.keys(errors).length === 0) {
              // submit any open fee boxes that are complete so they're automatically saved
              if (monthlyFeesOpen && tabIndex === LEASE_TABS.TERMS) {
                monthlyFeeFormRef?.current?.validateForm().then((errs) => {
                  if (Object.keys(errs).length === 0) {
                    // if there are no errors, submit and then resave lease
                    monthlyFeeFormRef?.current?.submitForm().then(() => {
                      setTimeout(() => setResaveLease(true), 100);
                    });
                  } else {
                    // submit so errors show up
                    monthlyFeeFormRef?.current?.submitForm();
                    monthlyFeesOpen.scrollIntoView();
                  }
                });
              } else if (additionalFeesOpen && tabIndex === LEASE_TABS.DEPOSITS) {
                additionalFeeFormRef?.current?.validateForm().then((errs) => {
                  if (Object.keys(errs).length === 0) {
                    // if there are no errors, submit and then resave lease
                    additionalFeeFormRef?.current?.submitForm().then(() => {
                      setTimeout(() => setResaveLease(true), 100);
                    });
                  } else {
                    // submit so errors show up
                    additionalFeeFormRef?.current?.submitForm();
                    additionalFeesOpen.scrollIntoView();
                  }
                });
              } else {
                setTabIndex((prevState) => handleFooterState(prevState, isPreviousOnClick));
                saveLease();
              }
            } else {
              // there are errors in the main form, also show any errors in the subforms
              form?.current.setTouched(errors, true);
              // submit any open fee boxes that are complete so they're automatically saved
              if (monthlyFeesOpen) {
                monthlyFeeFormRef?.current?.submitForm();
              }
              if (additionalFeesOpen) {
                additionalFeeFormRef?.current?.submitForm();
              }
            }
          })
          .catch((error) => console.log('error', error));
      } else if (
        (isPreviousOnClick && !selectingUnitPanel && !addingPropertyPanel) ||
        unitState === 'READY_TO_BE_REVIEWED'
      ) {
        setTabIndex((prevState) => handleFooterState(prevState, isPreviousOnClick));
      } else if (isPreviousOnClick && tabIndex > 0) {
        setAddingPropertyPanel(false);
        setTabIndex((prevState) => handleFooterState(prevState, isPreviousOnClick));
      }
    }
  };

  // Disable previous button on first tab (0)
  const isPreviousDisabled = tabIndex === 0 && !selectingUnitPanel && !addingPropertyPanel;

  return (
    <Flex direction={isMobile ? 'column-reverse' : 'row'} justifyContent="space-between">
      <Flex direction="row" {...footerStyles(isMobile)} marginTop="0px">
        {!isPreviousDisabled && (
          <Box marginRight={1.5} marginTop={tabIndex === 0 ? '0' : 3}>
            <BaselaneButton
              variant="outline"
              palette="neutral"
              size="md"
              leftIcon={<IconArrowLeft />}
              onClick={() => handleFooterNavigation(true)}
            >
              Previous
            </BaselaneButton>
          </Box>
        )}
        <Box marginTop={tabIndex === 0 ? '0' : '24px'} width="100%">
          {tabIndex === leaseMenuOptions.length - 1 && unitState === 'READY_TO_BE_REVIEWED' ? (
            <BaselaneButton
              id="confirm-rc-button"
              variant="filled"
              palette="primary"
              size="md"
              onClick={handleCreateLease}
              leftIcon={!isMobile && <IconCheck w={18} h={18} />}
            >
              Confirm & Invite Tenant
            </BaselaneButton>
          ) : (
            <BaselaneButton
              variant="filled"
              palette="primary"
              size="md"
              onClick={() => handleFooterNavigation()}
              styles={{
                ...(tabIndex === 0 ? footerButtonStyles(isMobile) : {}),
                display: showReceivingBAEmptyState && tabIndex === LEASE_TABS.ACCOUNTS && 'none',
              }}
              rightIcon={<IconArrowRight />}
              isDisabled={
                tabIndex === 0 && (!clickedProperty || (selectingUnitPanel && !clickedUnit?.id))
              }
              id={
                tabIndex === leaseMenuOptions.length - 2 ? 'review-rc-button' : 'continue-rc-button'
              }
            >
              {tabIndex === leaseMenuOptions.length - 2 ? 'Review Details' : 'Continue'}
            </BaselaneButton>
          )}
        </Box>
      </Flex>
      {tabIndex === LEASE_TABS.PROPERTY && (
        <Box
          {...footerStyles(isMobile)}
          {...footerDeleteStyles(isMobile)}
          visibility={
            clickedUnit &&
            clickedUnit.lease &&
            clickedUnit.id === radioValue &&
            ((clickedProperty && selectingUnitPanel) ||
              (clickedProperty && !selectedProperty?.units[1]))
              ? 'visible'
              : 'hidden'
          }
        >
          <BaselaneButton
            leftIcon={<Icon16Delete />}
            size="md"
            isFullWidth={isMobile && true}
            variant={isMobile ? 'outline' : 'transparent'}
            palette="danger"
            onClick={() => handleDeleteLease(clickedUnit)}
            id="delete-property-button"
          >
            Delete Draft
          </BaselaneButton>
        </Box>
      )}
    </Flex>
  );
}

export default FooterWrapper;
