const updateVisibleDotsByMany = ({ selectedCarouselIndex, cards, setVisibleDots }) => {
  if (selectedCarouselIndex === cards.length - 2) {
    setVisibleDots([
      cards.length - 5,
      cards.length - 4,
      cards.length - 3,
      cards.length - 2,
      cards.length - 1,
    ]);
  } else if (selectedCarouselIndex === 0) {
    setVisibleDots([0, 1, 2, 3, 4]);
  } else {
    setVisibleDots([
      selectedCarouselIndex - 2,
      selectedCarouselIndex - 1,
      selectedCarouselIndex,
      selectedCarouselIndex + 1,
      selectedCarouselIndex + 2,
    ]);
  }
};

const updateVisibleDotsByOne = ({
  emblaApi,
  visibleDots,
  setVisibleDots,
  selectedCarouselIndex,
  cards,
  cardListUpdated,
}) => {
  const shouldAddUpdateVisibleDots =
    emblaApi.previousScrollSnap() <= selectedCarouselIndex &&
    (visibleDots.indexOf(selectedCarouselIndex) === 3 || cardListUpdated) &&
    visibleDots.indexOf(cards.length - 1) === -1;
  const shouldSubtractUpdateVisibleDots =
    emblaApi.previousScrollSnap() > selectedCarouselIndex &&
    visibleDots.indexOf(selectedCarouselIndex) === 1 &&
    visibleDots.indexOf(0) === -1;

  setTimeout(() => {
    setVisibleDots((prevState) =>
      prevState.map((dot) => {
        if (shouldAddUpdateVisibleDots && dot + 1 !== cards.length) {
          return dot + 1;
        }
        if (shouldSubtractUpdateVisibleDots && dot - 1 !== -1) {
          return dot - 1;
        }
        return dot;
      })
    );
  }, 300);
};

export const updateVisibleDots = ({
  emblaApi,
  visibleDots,
  setVisibleDots,
  cards,
  cardListUpdated = false,
}) => {
  const selectedCarouselIndex = emblaApi.selectedScrollSnap();

  const selectedCardGreaterThanOneDistance =
    selectedCarouselIndex - emblaApi.previousScrollSnap() > 1 ||
    selectedCarouselIndex - emblaApi.previousScrollSnap() < -1;

  if (selectedCardGreaterThanOneDistance) {
    updateVisibleDotsByMany({ selectedCarouselIndex, cards, setVisibleDots });
  } else {
    updateVisibleDotsByOne({
      emblaApi,
      visibleDots,
      setVisibleDots,
      selectedCarouselIndex,
      cards,
      cardListUpdated,
    });
  }
};
