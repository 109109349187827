import React from 'react';

function IconStockExchange({ w = 32, h = 32 }: { w?: Number, h?: Number }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)">
        <path
          d="m8.35 31.53-.1.1.1-.1a1.1 1.1 0 0 1 0-1.56l-.1-.1.1.1 8.69-8.69c.2-.2.48-.32.77-.32H24.18l.05-.04 3.68-3.69.26-.26h-3.04c-1.46-.06-1.46-2.14 0-2.2h5.62c.6 0 1.1.5 1.1 1.1v5.56c-.06 1.47-2.14 1.47-2.2 0v-2.82l-.26.25-3.97 3.98c-.21.2-.49.32-.78.32h-6.37l-.05.05-8.32 8.32.1.1-.1-.1a1.1 1.1 0 0 1-1.55 0Zm6.04-12.5-.1.03v.88a1.1 1.1 0 1 1-2.2 0v-.88l-.1-.04a3.71 3.71 0 0 1-2.12-1.94l-.13.06.13-.06c-.25-.56 0-1.21.55-1.46l-.06-.14.06.14c.56-.25 1.21 0 1.46.55.23.51.76.86 1.3.86h.01c.46-.02.8-.2 1.04-.46s.35-.6.35-.94c0-.68-.47-1.37-1.39-1.4-4.06-.08-4.96-5.6-1.2-7l.1-.03v-.95c.06-1.46 2.14-1.46 2.2 0v.95l.1.04a3.5 3.5 0 0 1 2.06 1.95 1.1 1.1 0 0 1-2.02.86 1.3 1.3 0 0 0-1.24-.82c-.92.04-1.4.72-1.4 1.4 0 .34.12.67.35.94.24.26.59.44 1.04.45 4.06.08 4.97 5.61 1.2 7ZM9.9 24.6a1.1 1.1 0 0 1-1.42.63A13.02 13.02 0 0 1 .15 13.13C.11 2.14 13.2-3.9 21.51 3.22a12.99 12.99 0 0 1 4.4 7.64l.14-.02-.14.02a1.1 1.1 0 1 1-2.17.38c-.9-5.16-5.37-8.9-10.61-8.9-11.93.2-14.9 16.38-3.86 20.84.57.22.85.85.63 1.42Zm19.75 1.83a1.1 1.1 0 1 1 2.2 0v4.3c-.06 1.47-2.14 1.47-2.2 0v-4.3Zm-5 0a1.1 1.1 0 1 1 2.2 0v4.3c-.06 1.47-2.14 1.47-2.2 0v-4.3Zm-5 0a1.1 1.1 0 1 1 2.2 0v4.3c-.06 1.47-2.14 1.47-2.2 0v-4.3Zm-5 3.8a1.1 1.1 0 0 1 2.2 0v.5c-.06 1.47-2.14 1.47-2.2 0v-.5Z"
          fill="#192C3E"
          stroke="#fff"
          strokeWidth=".3"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
IconStockExchange.defaultProps = {
  w: 32,
  h: 32,
};

export default IconStockExchange;
