import React from 'react';

type IconExternalLinkProps = {
  w: Number,
  h: Number,
  color: String,
};

function IconExternalLink({ w = 12, h = 12, color = '#257ED0' }: IconExternalLinkProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33333 1.33333C6.96514 1.33333 6.66667 1.03486 6.66667 0.666667C6.66667 0.298477 6.96514 0 7.33333 0H11.3333C11.7015 0 12 0.298477 12 0.666667V4.66667C12 5.03486 11.7015 5.33333 11.3333 5.33333C10.9651 5.33333 10.6667 5.03486 10.6667 4.66667V2.27614L5.13807 7.80474C4.87772 8.06509 4.45561 8.06509 4.19526 7.80474C3.93491 7.54439 3.93491 7.12228 4.19526 6.86193L9.72386 1.33333H7.33333ZM0.585786 1.91912C0.960859 1.54405 1.46957 1.33333 2 1.33333H4.66667C5.03486 1.33333 5.33333 1.63181 5.33333 2C5.33333 2.36819 5.03486 2.66667 4.66667 2.66667H2C1.82319 2.66667 1.65362 2.7369 1.5286 2.86193C1.40357 2.98695 1.33333 3.15652 1.33333 3.33333V10C1.33333 10.1768 1.40357 10.3464 1.5286 10.4714C1.65362 10.5964 1.82319 10.6667 2 10.6667H8.66667C8.84348 10.6667 9.01305 10.5964 9.13807 10.4714C9.2631 10.3464 9.33333 10.1768 9.33333 10V7.33333C9.33333 6.96514 9.63181 6.66667 10 6.66667C10.3682 6.66667 10.6667 6.96514 10.6667 7.33333V10C10.6667 10.5304 10.456 11.0391 10.0809 11.4142C9.70581 11.7893 9.1971 12 8.66667 12H2C1.46957 12 0.960859 11.7893 0.585786 11.4142C0.210714 11.0391 0 10.5304 0 10V3.33333C0 2.8029 0.210714 2.29419 0.585786 1.91912Z"
        fill={color}
      />
    </svg>
  );
}

export default IconExternalLink;
