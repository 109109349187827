import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Box, Stack } from '@chakra-ui/react';
import IconCalendar from '@icons/legacy/IconCalendar';
import formatDateTwoDigitDay from '@core/utils/formatDateTwoDigitDay';
import MaskedDateInput from './MaskedDateInput';
import {
  searchInputStyles,
  fakeHyphenStyles,
  datepickerStyles,
} from './styles/datepickerWithMaskedInput.styles';

type DatePickerWithInputProps = {
  selectedDate: Object,
  setSelectedDate: Function,
  styles?: Object,
};

const DatePickerWithInput = ({
  selectedDate,
  setSelectedDate,
  styles,
}: DatePickerWithInputProps) => {
  const [inputDate, setInputDate] = useState({ start: null, end: null });

  const startInputRef = useRef(null);
  const endInputRef = useRef(null);
  const dateInputRef = useRef(null);

  const handleDateChange = (dates) => {
    const { start: startDate, end: endDate } = dates ?? {};
    const start = startDate ? formatDateTwoDigitDay(startDate) : null;
    const end = endDate ? formatDateTwoDigitDay(endDate) : start;

    setInputDate({
      start,
      end,
    });
  };

  const onDatePickerValueChange = (dates) => {
    const [start, end] = dates;
    setSelectedDate({ start, end });
    handleDateChange({ start, end });
  };

  useEffect(() => {
    handleDateChange({ start: selectedDate?.start, end: selectedDate?.end });
  }, [selectedDate]);

  return (
    <>
      <Stack direction="row" {...{ ...searchInputStyles, ...styles.input }}>
        <Box>
          <IconCalendar color="#ACB8C7" w={16} h={16} />
        </Box>
        <MaskedDateInput
          tabIndex="-1"
          disabled
          ref={startInputRef}
          dateValue={inputDate.start}
          name="dateInputStart"
        />
        <Box tabIndex="-1" {...fakeHyphenStyles(inputDate.start || inputDate.end)} />
        <MaskedDateInput
          tabIndex="-1"
          disabled
          ref={endInputRef}
          dateValue={inputDate.end}
          name="dateInputEnd"
        />
      </Stack>

      <Box {...{ ...datepickerStyles(false), ...styles.datepicker }}>
        <DatePicker
          ref={dateInputRef}
          placeholderText="Select date"
          dateFormat="MMM dd, yyyy"
          selected={selectedDate.end ? selectedDate.end : selectedDate.start}
          onChange={onDatePickerValueChange}
          startDate={selectedDate.start}
          endDate={selectedDate.end}
          selectsRange
          inline
          fixedHeight
          shouldCloseOnSelect={false}
        />
      </Box>
    </>
  );
};

DatePickerWithInput.defaultProps = {
  styles: {},
};

export default DatePickerWithInput;
