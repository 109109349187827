import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Stack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

import { NB_ACCOUNTS } from '@routes';
import { BaselaneErrorCard } from '@shared/components';
import DisclaimerText from '@shared/components/DisclaimerText';
import BankEntityContext from '@contexts/BankEntityContext';
import BaselaneDisclaimer from '@core/components/NativeBankingPage/components/BaselaneDisclaimer';
import { containerStyle } from '@core/components/NativeBankingPage/styles/nativeBanking.styles';
import { disclaimerText } from '@core/components/NativeBankingPage/components/BaselaneDisclaimer/const/disclaimerText';
import { getFlatAccounts } from '@core/components/NativeBankingPage/MainContent/components/Accounts/helpers/bank.helpers';
import SlLoader from '@core/components/Loader';
import getBreakPoints from '@core/utils/getBreakPoints';

import PageHeader from './PageHeader';
import MainContent from './MainContent';
import useEntityByIdQuery from './shared/useEntityByIdQuery';

const BankAccountPage = () => {
  const editAccountRef = useRef(null);
  const { isMinXL } = getBreakPoints();
  const containerStyles = isMobile && {
    mt: '0',
    height: '100%',
  };

  const { entityData, entityLoading, entityError } = useEntityByIdQuery('cache-and-network');

  const bankAccounts = getFlatAccounts(entityData);
  const account = bankAccounts?.length // since useEntityByIdQuery fetches by only 1 accountId, the bankAccounts array should only contain one object
    ? { ...bankAccounts[0], nickName: bankAccounts[0]?.nickName || 'Main Account' }
    : null;

  const subAccountIds = account?.subAccounts
    ?.filter(
      (subA) =>
        subA.connectionState === 'CONNECTED' && subA.isConnected && subA.accountStatus === 'Open'
    )
    ?.map((subAccount) => Number(subAccount.id));
  const allBankAccountIdsFilter = account ? [Number(account?.id), ...(subAccountIds ?? [])] : null;
  const [activeFilter, setActiveFilter] = useState({
    bankAccountId: allBankAccountIdsFilter,
  });
  const [selectedBankAccountId, setSelectedBankAccountId] = useState('');
  const [selectedAccountToView, setSelectedAccountToView] = useState({});

  // To make sure that the active filter is getting the updated information of allBankAccountIdsFilter onload of page
  // when bankAccountId has not been set yet
  useEffect(() => {
    if (
      activeFilter?.bankAccountId === null &&
      activeFilter?.bankAccountId !== allBankAccountIdsFilter
    ) {
      setActiveFilter({ bankAccountId: allBankAccountIdsFilter });
    }
  }, [allBankAccountIdsFilter]);

  const state = useMemo(() => {
    const newState = {};
    newState.allBankAccountIdsFilter = allBankAccountIdsFilter;
    newState.activeFilter = activeFilter;
    newState.setActiveFilter = setActiveFilter;
    newState.selectedBankAccountId = selectedBankAccountId;
    newState.setSelectedBankAccountId = setSelectedBankAccountId;
    newState.selectedAccountToView = selectedAccountToView;
    newState.setSelectedAccountToView = setSelectedAccountToView;
    newState.editAccountRef = editAccountRef;
    newState.account = account;

    return newState;
  }, [allBankAccountIdsFilter, activeFilter, account]);

  if (entityLoading) return <SlLoader />;
  if (entityError) return <BaselaneErrorCard />;
  // navigate back to bank summary if query did not find any bank/entity by the accountId
  // ie entityData === []
  if (!entityLoading && !entityError && entityData && !entityData.length) {
    return <Navigate to={NB_ACCOUNTS} />;
  }

  return account ? (
    <BankEntityContext.Provider value={state}>
      <Stack {...containerStyles} overflow={isMinXL ? 'hidden' : 'visible'} spacing={0}>
        <PageHeader />
        <Box height="100%" overflow={isMinXL ? 'auto' : 'visible'}>
          <MainContent account={account} />
          <BaselaneDisclaimer
            containerStyle={containerStyle(true)}
            text={<DisclaimerText />}
            showPrivacyPolicy={disclaimerText.banksAndCards.showPrivacyPolicy}
          />
        </Box>
      </Stack>
    </BankEntityContext.Provider>
  ) : null;
};

export default BankAccountPage;
