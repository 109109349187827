import React from 'react';

function IconExclamationCircleFilled({
  color = 'currentColor',
  w = 16,
  h = 16,
}: {
  color?: string,
  w?: number,
  h?: number,
}) {
  return (
    <svg
      height={`${h}px`}
      width={`${w}px`}
      viewBox={`0 0 ${h} ${w}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        d="M7.99379 12C8.55279 12 9 11.5762 9 11.0402C9 10.4917 8.55279 10.0679 7.99379 10.0679C7.4472 10.0679 7 10.4917 7 11.0402C7 11.5762 7.4472 12 7.99379 12ZM7.39752 9.23269H8.61491L8.85093 3H7.17391L7.39752 9.23269Z"
        fill="white"
      />
    </svg>
  );
}

IconExclamationCircleFilled.defaultProps = {
  color: 'currentColor',
  w: 16,
  h: 16,
};

export default IconExclamationCircleFilled;
