// @flow
import React from 'react';
import { Text } from '@chakra-ui/react';
import { drawerMenuContentTitleStyle } from '../../styles/drawer.style';

function Documents(): any {
  return <Text {...drawerMenuContentTitleStyle}>Documents</Text>;
}

export default Documents;
