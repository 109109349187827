const checkIsTransfer = (payment) => {
  // If the payment type is "INTERNAL_TRANSFER" or "TRANSFER_IN", it is "transfer" for sure.
  if (payment.type === 'INTERNAL_TRANSFER' || payment.type === 'TRANSFER_IN') {
    return true;
  }

  // If the payment type is "TRANSFER_OUT", it is "transfer" if it has a "toTransferAccountId", otherwise is "payment".
  if (payment.type === 'TRANSFER_OUT') {
    return !!payment?.toTransferAccountId;
  }

  return false;
};

export default checkIsTransfer;
