// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';

import ListHeader from './ListHeader';
import ListItem from './ListItem';
import ListItemMobile from './ListItemMobile';
import ListEmptyState from './ListEmptyState';
import type { Invoices as InvoicesType } from '../../../types';
import { invoiceTableStyles } from '../../../styles/invoice.styles';

type InvoiceListProps = {
  isLeaseLevel: boolean,
  invoices: InvoicesType,
  refetchLeaseInvoices: Function,
  hasUpdatedInvoice: boolean,
  setHasUpdatedInvoice: Function,
  sortedLeases: Array<Object>,
  hasMultipleUnits: boolean,
};

function InvoiceList({
  isLeaseLevel,
  invoices,
  refetchLeaseInvoices,
  hasUpdatedInvoice,
  setHasUpdatedInvoice,
  sortedLeases,
  hasMultipleUnits,
}: InvoiceListProps): any {
  const { isMin768 } = getBreakPoints();

  const hasNoData = invoices?.length === 0;
  const { container } = invoiceTableStyles();

  const mobileInvoiceList = (
    <Box {...container}>
      {hasNoData ? (
        <ListEmptyState />
      ) : (
        invoices?.map((invoice) => (
          <ListItemMobile
            key={invoice.id}
            invoice={invoice}
            isLeaseLevel={isLeaseLevel}
            refetchLeaseInvoices={refetchLeaseInvoices}
            hasUpdatedInvoice={hasUpdatedInvoice}
            setHasUpdatedInvoice={setHasUpdatedInvoice}
            sortedLeases={sortedLeases}
            hasMultipleUnits={hasMultipleUnits}
          />
        ))
      )}
    </Box>
  );

  const desktopInvoiceList = (
    <Box {...container}>
      <ListHeader isLeaseLevel={isLeaseLevel} />
      <Box m="0 !important">
        {hasNoData ? (
          <ListEmptyState />
        ) : (
          invoices?.map((invoice, index) => (
            <ListItem
              key={invoice.id}
              invoice={invoice}
              isLeaseLevel={isLeaseLevel}
              refetchLeaseInvoices={refetchLeaseInvoices}
              hasUpdatedInvoice={hasUpdatedInvoice}
              setHasUpdatedInvoice={setHasUpdatedInvoice}
              sortedLeases={sortedLeases}
              hasMultipleUnits={hasMultipleUnits}
              index={index}
            />
          ))
        )}
      </Box>
    </Box>
  );

  return isMin768 ? desktopInvoiceList : mobileInvoiceList;
}

export default InvoiceList;
