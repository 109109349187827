import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { FormControl, Text, Box } from '@chakra-ui/react';
import { useShallow } from 'zustand/react/shallow';

import {
  BaselaneSummaryCard,
  BaselaneSummaryCardAmountHeader,
  BaselaneFormLabel,
  BaselaneFormErrorMessage,
  T1WithTitleDropdown,
  BaselaneAlertNew,
} from '@shared/components';
import useCheckbookStore from '@store/Checkbook';
import BanksContext from '@contexts/BanksContext';
import formatCurrency from '@core/utils/formatCurrency';
import { getFlatAccounts } from '@core/components/NativeBankingPage/MainContent/components/Accounts/helpers/bank.helpers';
import { Icon16Info } from '@icons/16px';
import {
  accountItemRenderer,
  getSelectedAccount,
  formatAllAccountsData,
} from '../helpers/accountItem.helper';
import CustomDropdownDisplay from '../CustomDropdownDisplay';
import { formattedAddress, formattedAccountDisplay } from '../helpers/form.helper';
import { formErrorStyles } from '../styles/form.styles';

type CheckbookReviewProps = {
  hasSubmissionError: Boolean,
};

const CheckbookReview = ({ hasSubmissionError }: CheckbookReviewProps) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormikContext();
  const { baselaneConnectedAccounts } = useContext(BanksContext);
  const [
    selectedAccount,
    checkbookFee,
    selectedFeeAccount,
    setSelectedFeeAccount,
  ] = useCheckbookStore(
    useShallow((state) => [
      state.selectedAccount,
      state.checkbookFee,
      state.selectedFeeAccount,
      state.setSelectedFeeAccount,
    ])
  );

  const bankAccounts = getFlatAccounts(baselaneConnectedAccounts);
  const formattedData = formatAllAccountsData(bankAccounts);

  const formattedAccount = formattedAccountDisplay(selectedAccount);

  const formattedMailingAddress = formattedAddress(values);

  const checkNumbersToBeOrdered = selectedAccount?.checkBookAvailability?.lastCheckNumber
    ? {
        startNumber: selectedAccount.checkBookAvailability.lastCheckNumber + 1,
        endNumber: selectedAccount.checkBookAvailability.lastCheckNumber + 80,
      }
    : {
        startNumber: 101,
        endNumber: 180,
      };

  const handleSelection = (selectedValue) => {
    const feeAccount = getSelectedAccount(formattedData, selectedValue);
    setFieldValue('feeFromBankAccountId', selectedValue);
    setSelectedFeeAccount(feeAccount);

    // late timeout so the custom field error is not overwritten by Formik's internal validation
    if (feeAccount.availableBalance < checkbookFee) {
      setFieldTouched('feeFromBankAccountId');
      setTimeout(() => {
        setFieldError(
          'feeFromBankAccountId',
          `Minimum balance of ${formatCurrency(checkbookFee).rounded}
            needed. Select another account.`
        );
      }, 0);
    }
  };

  return (
    <>
      {hasSubmissionError && (
        <Box mb={3}>
          <BaselaneAlertNew
            variant="danger"
            title="Failed to place order"
            body="Please try again, or contact support if the issue persists."
            visual="icon"
            iconName={Icon16Info}
          />
        </Box>
      )}

      <BaselaneSummaryCard
        isCustom
        header={
          <BaselaneSummaryCardAmountHeader
            title="80 high-security checks"
            subtitle={`Check numbers ${checkNumbersToBeOrdered.startNumber} to ${checkNumbersToBeOrdered.endNumber}`}
            amount={checkbookFee}
          />
        }
        list={[
          {
            id: 'review-summary-id',
            items: [
              {
                id: 'account-id',
                label: 'Account',
                value: selectedAccount?.nickName,
                subtext: formattedAccount,
              },
              {
                id: 'mailing-id',
                label: 'Mailing address',
                value: formattedMailingAddress,
              },
            ],
          },
        ]}
      />

      <Text textStyle="headline-sm" mt={3} mb={2}>
        Which account should we charge the {formatCurrency(checkbookFee).rounded} fee to?
      </Text>
      <FormControl
        isInvalid={errors.feeFromBankAccountId && touched.feeFromBankAccountId}
        isRequired
      >
        <BaselaneFormLabel htmlFor="charge-account-dropdown" textStyle="xs">
          Select account
        </BaselaneFormLabel>
        <T1WithTitleDropdown
          hideSearch
          data={formattedData}
          showValueByFields={['nickName', 'availableBalance']}
          placeholder="Select account"
          classNames={['input-form-lg', 'is-full-width', 'auto-width-dropdown']}
          dropdownPosition="bottom"
          itemRenderer={accountItemRenderer}
          selectedItem={selectedFeeAccount}
          handleSubmit={(value) => handleSelection(value)}
          additionalProps={{ id: 'charge-account-dropdown' }}
          hasError={errors.feeFromBankAccountId && touched.feeFromBankAccountId}
          CustomDisplayInput={CustomDropdownDisplay}
        />
        <BaselaneFormErrorMessage {...formErrorStyles}>
          <Text>{errors.feeFromBankAccountId}</Text>
        </BaselaneFormErrorMessage>
      </FormControl>
    </>
  );
};

export default CheckbookReview;
