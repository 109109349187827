import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['container', 'requiredIndicator', 'helperText'];
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts);

export const Form = defineMultiStyleConfig({
  baseStyle: definePartsStyle((props) => {
    const { textStyles } = props.theme;
    return {
      container: {
        mb: 2,
        label: {
          ...textStyles.xs,
          color: 'brand.neutral.700',
          mb: 0.75,
          _isRequired: {
            color: 'red.700',
          },
        },
      },
      requiredIndicator: {
        ml: 0.5,
      },
      helperText: {
        ...textStyles.xs,
        marginTop: '0.75',
        color: 'brand.neutral.600',
      },
    };
  }),
  variants: {
    isLast: {
      container: {
        mb: '0',
      },
    },
  },
});
