const BANNER_PADDING_X = 2;
const BANNER_PADDING_Y = 1.5;

const bannerContainer = {
  px: BANNER_PADDING_X,
  py: BANNER_PADDING_Y,
  justifyContent: 'space-between',
  borderRadius: 'sm',
  borderWidth: '1px',
};
const bannerTitle = {
  fontSize: 'sm',
  lineHeight: '20px',
  fontWeight: 'semibold',
  m: 0,
  color: 'brand.neutral.900',
};
const bannerDescription = {
  display: 'flex',
  fontSize: 'sm',
  lineHeight: '20px',
  m: 0,
  color: 'brand.neutral.700',
};

export const alertVariants = {
  'alert-neutral': {
    container: {
      ...bannerContainer,
      color: 'brand.darkBlue.800A',
      bgColor: 'brand.darkBlue.50',
      borderColor: 'brand.darkBlue.200',
    },
    title: {
      ...bannerTitle,
    },
    description: {
      ...bannerDescription,
    },
  },
  'alert-primary': {
    container: {
      ...bannerContainer,
      color: 'brand.blue.800A',
      bgColor: 'brand.blue.100',
      borderColor: 'brand.blue.200',
    },
    title: {
      ...bannerTitle,
    },
    description: {
      ...bannerDescription,
    },
  },
  'alert-success': {
    container: {
      ...bannerContainer,
      color: 'green.800AA',
      bgColor: 'green.100',
      borderColor: 'green.200',
    },
    title: {
      ...bannerTitle,
    },
    description: {
      ...bannerDescription,
    },
  },
  'alert-warning': {
    container: {
      ...bannerContainer,
      color: 'yellow.800AA',
      bgColor: 'yellow.100',
      borderColor: 'yellow.200',
    },
    title: {
      ...bannerTitle,
    },
    description: {
      ...bannerDescription,
    },
  },
  'alert-danger': {
    container: {
      ...bannerContainer,
      color: 'red.800AA',
      bgColor: 'red.100',
      borderColor: 'red.200',
    },
    title: {
      ...bannerTitle,
    },
    description: {
      ...bannerDescription,
    },
  },
};
