import React from 'react';
import { useFormikContext } from 'formik';
import { FormControl, Text } from '@chakra-ui/react';

import { BaselaneFormLabel, BaselaneInput, BaselaneFormErrorMessage } from '@shared/components';

const AccountFieldName = () => {
  const { values, errors, handleChange, handleBlur } = useFormikContext();

  return (
    <FormControl isRequired isInvalid={errors.nickName} mb={0}>
      <BaselaneFormLabel htmlFor="nickName">Account nickname</BaselaneFormLabel>
      <BaselaneInput
        id="nickName"
        name="nickName"
        values={values.nickName}
        placeholder="e.g. Operating expenses"
        size="lg"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <BaselaneFormErrorMessage>
        <Text>{errors.nickName}</Text>
      </BaselaneFormErrorMessage>
    </FormControl>
  );
};

export default AccountFieldName;
