import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { GET_BANK_ACCOUNTS_ACTIVE, CREATE_TRANSFER } from '@core/apollo/queries';
import { GET_BANK_SUMMARY } from '@core/components/NativeBankingPage/queries';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import getTransferType from '../helpers/getTransferType';

const useCreateTransfer = (xIdempotencyKey) => {
  const { bankAccountsById } = useBankAccountsActive();
  const [mutate, { loading }] = useMutation(CREATE_TRANSFER, {
    refetchQueries: [GET_BANK_SUMMARY, GET_BANK_ACCOUNTS_ACTIVE, 'GetScheduledPayments'],
  });

  const createTransfer = async (values) => {
    // Resolving the propertyId and unitId from the values.
    const [propertyId, unitId] = !values.propertyId
      ? [undefined, undefined]
      : values.propertyId.split('-');

    // Resolving the tagId from the values.
    const tagId = !values.categoryId
      ? undefined
      : values.categoryId.split('-')[values.categoryId.split('-').length - 1]; // Its ugly, in case of "123" or "123-456" it will return "123" or "456" respectively.

    const fromTransferAccount = bankAccountsById[values.transferFromAccountId];

    const depositToAccount = bankAccountsById[values.depositToAccountId];

    const transferType = getTransferType(fromTransferAccount, depositToAccount);

    const { data, errors } = await mutate({
      context: {
        headers: {
          'x-idempotency-key': xIdempotencyKey,
        },
      },
      variables: {
        input: {
          fromTransferAccountId: bankAccountsById[values.transferFromAccountId].transferAccountId,
          toTransferAccountId: bankAccountsById[values.depositToAccountId].transferAccountId,
          amount: parseFloat(values.amount.replace(/,/g, '')),
          bookKeepingNote: values.notes,
          type: transferType,
          transferDate: DateTime.fromJSDate(values.oneTimeTransfer.date).toISODate(),
          tagId,
          propertyId,
          unitId,
          sameDay: DateTime.now().hasSame(values.oneTimeTransfer.date, 'day'),
        },
      },
    });

    if (errors) {
      throw new Error('Errors creating transfer: ', errors);
    }

    return data;
  };

  return { createTransfer, loading };
};

export default useCreateTransfer;
