export const optionsCardDisabled = {
  borderColor: 'brand.darkBlue.200',
  background: 'brand.darkBlue.50',
  pointerEvents: 'none',
};

export const optionsCardStyles = (isDisabled = false) => ({
  whiteSpace: 'normal',
  ...(isDisabled && optionsCardDisabled),
});

export const optionsTextDisabled = {
  color: 'brand.neutral.500',
};

export const optionsTitleStyles = (isDisabled = false) => ({
  textStyle: 'headline-sm',
  color: 'brand.neutral.900',
  mt: '12px',
  mb: '4px',
  ...(isDisabled && optionsTextDisabled),
});

export const optionsDescriptionStyles = (isDisabled = false) => ({
  textStyle: 'xs',
  color: 'brand.neutral.700',
  ...(isDisabled && optionsTextDisabled),
});

export const optionsIconStyles = {
  ml: '12px',
};
