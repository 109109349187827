import React from 'react';
import { useQuery } from '@apollo/client';
import {
  getPropertyData,
  renderPropertyDropdownParentItem,
} from '@shared/helpers/propertiesFilter.helpers';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import getBreakPoints from '@core/utils/getBreakPoints';
import { GET_PROPERTIES_DROPDOWN } from '@core/apollo/queries';
import DisplayInputDefaultPropertyItem from '../../../../Transactions/DisplayInputVariations/DisplayInputDefaultProperty';
import T2Dropdown from '../../BaselaneDropdown-new/T2/index';
import NoPropertyTooltip from '../../NoPropertyTooltip';

type AutoTagCommonUIProps = {
  selectedItem: Object,
  onDropdownSubmit: Function,
  isDisabled: boolean,
};

const AutoTagCommonUI = ({ selectedItem, onDropdownSubmit, isDisabled }: AutoTagCommonUIProps) => {
  const { isMax768 } = getBreakPoints();
  const { data } = useQuery(GET_PROPERTIES_DROPDOWN);
  const propertyOptions = data ? getPropertyData(data?.property) : [];
  const hasNoPropertyOptions = propertyOptions?.length === 0;

  return hasNoPropertyOptions ? (
    <NoPropertyTooltip>
      <T2Dropdown
        additionalProps={{ id: 'account-autotag-dropdown' }}
        classNames={['input-form-lg', 'is-full-width']}
        title="Property"
        placeholder="Select property or unit"
        selectedItem={null}
        isDisabled
      />
    </NoPropertyTooltip>
  ) : (
    <T2Dropdown
      additionalProps={{ id: 'property-dropdown' }}
      classNames={['input-form-lg', 'is-full-width']}
      data={propertyOptions}
      searchTerm="name"
      title="Property"
      placeholder="Select Property"
      showValueByFields={['showValueName']}
      parentItemRenderer={({ item }) => renderPropertyDropdownParentItem(item)}
      childItemRenderer={itemRenderer}
      handleSubmit={onDropdownSubmit}
      isMulti={false}
      hasFilterWrapper={false}
      selectedItem={selectedItem}
      CustomDisplayInput={DisplayInputDefaultPropertyItem}
      isDisabled={isDisabled || hasNoPropertyOptions}
      isMobile={isMax768}
    />
  );
};

export default AutoTagCommonUI;
