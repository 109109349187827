import { useState } from 'react';

const useProcessingPopup = (isOpenByDefault = false) => {
  const [isProcessingPopupOpen, setIsKYCProcessingPopupOpen] = useState(isOpenByDefault);
  const onProcessingPopupClose = () => setIsKYCProcessingPopupOpen(false);
  const onProcessingPopupOpen = () => setIsKYCProcessingPopupOpen(true);

  return { isProcessingPopupOpen, onProcessingPopupOpen, onProcessingPopupClose };
};

export default useProcessingPopup;
