import React from 'react';
import { FormControl, Flex, Box } from '@chakra-ui/react';
import { Field } from 'formik';
import { BaselaneFormErrorMessage, BaselaneRadio, BaselaneRadioGroup } from '@shared/components';
import EndDateDatepicker from './EndDateDatepicker';
import NumberOfTransfersField from './NumberOfTransfersField';

const EndRecurringTransfer = () => {
  return (
    <Field name="recurringTransfer.endBy">
      {({ field, form }) => (
        <FormControl
          isRequired
          isInvalid={
            form.errors?.recurringTransfer?.endBy && form.touched?.recurringTransfer?.endBy
          }
        >
          <Box width="fit-content">
            <BaselaneRadioGroup
              {...field}
              onChange={(selection) => form.setFieldValue('recurringTransfer.endBy', selection)}
            >
              <BaselaneRadio value="manual" label="Continue until I cancel" />
              <Flex justifyContent="space-between">
                <BaselaneRadio value="date" label="End on" />
                <EndDateDatepicker />
              </Flex>
              <Flex justifyContent="space-between">
                <BaselaneRadio value="number-of-transfers" label="End after" />
                <NumberOfTransfersField />
              </Flex>
            </BaselaneRadioGroup>
          </Box>
          <BaselaneFormErrorMessage
            isInvalid={
              form.errors?.recurringTransfer?.endBy && form.touched?.recurringTransfer?.endBy
            }
          >
            {form.errors?.recurringTransfer?.endBy}
          </BaselaneFormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default EndRecurringTransfer;
