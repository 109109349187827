import React from 'react';

function IconStackedCards({ w, h }: { w?: Number, h?: Number }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 33"
    >
      <g clipPath="url(#clip0_19666_228771)">
        <path
          d="M29.2289 9.74628L20.2536 0.771052C19.2255 -0.257076 17.5526 -0.256959 16.5246 0.771052L5.26234 12.0333H2.63683C1.18288 12.0333 0 13.2162 0 14.6702V27.3631C0 28.817 1.18288 29.9999 2.63683 29.9999H22.8302C24.2842 29.9999 25.4671 28.817 25.4671 27.3631V17.2371L29.2289 13.4753C30.257 12.4472 30.257 10.7744 29.2289 9.74628ZM17.7676 2.01405C17.939 1.84272 18.1641 1.75699 18.3892 1.75699C18.6142 1.75699 18.8393 1.84272 19.0106 2.01405L23.6353 6.63875L18.2407 12.0333H7.74829L17.7676 2.01405ZM23.7092 27.3631C23.7092 27.8477 23.3149 28.242 22.8302 28.242H2.63683C2.15218 28.242 1.75788 27.8477 1.75788 27.3631V14.6702C1.75788 14.1855 2.15218 13.7912 2.63683 13.7912H22.8302C23.3149 13.7912 23.7092 14.1855 23.7092 14.6702V27.3631ZM23.1879 12.0583C23.0708 12.0423 22.9516 12.0333 22.8302 12.0333H20.7267L24.8783 7.8817L26.1214 9.12475L23.1879 12.0583ZM27.9859 12.2323L25.4671 14.7512V14.6702C25.4671 14.0021 25.2168 13.3916 24.8057 12.9264L27.3644 10.3678L27.9859 10.9892C28.3286 11.332 28.3286 11.8896 27.9859 12.2323Z"
          fill="#192C3E"
        />
        <path d="M3.51587 23.8232H6.78665V25.5811H3.51587V23.8232Z" fill="#192C3E" />
        <path d="M8.5708 23.8232H11.8416V25.5811H8.5708V23.8232Z" fill="#192C3E" />
        <path d="M13.6257 23.8232H16.8966V25.5811H13.6257V23.8232Z" fill="#192C3E" />
        <path d="M18.6807 23.8232H21.9514V25.5811H18.6807V23.8232Z" fill="#192C3E" />
        <path
          d="M9.44973 16.4512H3.51587V21.7248H9.44973V16.4512ZM7.69184 19.9669H5.27375V18.2091H7.69184V19.9669Z"
          fill="#192C3E"
        />
      </g>
      <defs>
        <clipPath id="clip0_19666_228771">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconStackedCards.defaultProps = {
  w: 32,
  h: 32,
};

export default IconStackedCards;
