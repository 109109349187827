import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { Stack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import {
  RenderWarningMessage,
  showWarningInDesktop,
  showWarningInMobile,
} from '@core/components/App/RenderWarningMessage';

import Summary from './Summary';
import ActiveTenants from './ActiveTenants';

function TenantsPage() {
  const currLocation = useLocation();
  const { cache } = useApolloClient();

  if (showWarningInMobile(currLocation) || showWarningInDesktop(currLocation)) {
    return <RenderWarningMessage />;
  }

  useEffect(() => {
    return () => {
      // erase tenant leases cache so that it reloads on next visit
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'leases' });
      // garbage collect to free up memory
      cache.gc();
    };
  }, []);

  return (
    <Stack spacing={5}>
      <Summary />
      <Stack pb={4} spacing={2}>
        <ActiveTenants />
      </Stack>
    </Stack>
  );
}

export default TenantsPage;
