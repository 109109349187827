import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import BanksContext from '@contexts/BanksContext';
import MyAccountsPanel from '@core/components/ExternalAccountsPage/components/MyAccountsPanel';
import { tabContainerStyles } from '@shared/styles/tab.styles';
import { externalTabStyles } from './styles/externalAccounts.styles';
import SlLoader from '../Loader';
import { BaselaneErrorCard } from '../Shared/components';

function ExternalAccountsPage() {
  const { isMinXL, isMin768 } = getBreakPoints();

  // Plaid External Accounts
  const {
    loading: isExternalBankAccountsLoading,
    error: hasExternalBankAccountsError,
    hasNoConnectedAccounts,
    hasNoBaselaneAccounts,
  } = useContext(BanksContext);

  if (isExternalBankAccountsLoading) return <SlLoader />;
  if (hasExternalBankAccountsError) {
    const error = hasExternalBankAccountsError;
    if (error.message.includes('Failed to fetch')) {
      return <BaselaneErrorCard />;
    }
  }

  const { container } = externalTabStyles(isMinXL) ?? {};

  return (
    <Box
      {...{
        ...tabContainerStyles,
        ...container,
        h: isMin768 && hasNoBaselaneAccounts && hasNoConnectedAccounts ? '100%' : '',
      }}
    >
      <MyAccountsPanel />
      <Outlet />
    </Box>
  );
}

export default ExternalAccountsPage;
