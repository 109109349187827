export const loadingViewStyles = {
  container: {
    w: '100%',
    h: '100%',
    background: 'brand.neutral.white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innercontainer: {
    gap: '40px',
    alignItems: 'center',
  },
  spinner: {
    thickness: '3px',
    size: 'md',
    color: 'brand.neutral.500',
  },
  caption: {
    textStyle: 'headline-lg',
  },
};
