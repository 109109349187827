const disabled = {
  _disabled: {
    color: 'brand.neutral.400',
    _hover: {
      color: 'brand.neutral.400',
    },
  },
};
export const colorPreset = {
  primary: {
    color: 'brand.blue.800A',
    _hover: {
      color: 'brand.blue.700',
    },
    _focusVisible: {
      outlineColor: 'brand.blue.800A',
    },
    _focus: {
      outlineColor: 'brand.blue.800A',
    },
    _active: {
      color: 'brand.blue.900',
    },
    ...disabled,
  },
  danger: {
    color: 'red.800AA',
    _hover: {
      color: 'red.700',
    },
    _focusVisible: {
      outlineColor: 'red.800AA',
    },
    _focus: {
      outlineColor: 'red.800AA',
    },
    _active: {
      color: 'red.900',
    },
    ...disabled,
  },
  success: {
    color: 'green.800AA',
    _hover: {
      color: 'green.700',
    },
    _focusVisible: {
      outlineColor: 'green.800AA',
    },
    _focus: {
      outlineColor: 'green.800AA',
    },
    _active: {
      color: 'green.900',
    },
    ...disabled,
  },
  warning: {
    color: 'yellow.800AA',
    _hover: {
      color: 'yellow.700',
    },
    _focusVisible: {
      outlineColor: 'yellow.800AA',
    },
    _focus: {
      outlineColor: 'yellow.800AA',
    },
    _active: {
      color: 'yellow.900',
    },
    ...disabled,
  },
};
export const linkThemeGenerator = (variant) => {
  switch (variant) {
    case 'primary':
      return colorPreset.primary;
    case 'danger':
      return colorPreset.danger;
    case 'success':
      return colorPreset.success;
    case 'warning':
      return colorPreset.warning;
    default:
      return {};
  }
};
