import { React } from 'react';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { HStack, Stack, Text, Box, Spinner } from '@chakra-ui/react';
import { Icon16Subcategory, Icon16Calendar } from '@icons/16px';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';
import {
  BaselaneChip,
  BaselaneSummaryCard,
  BaselaneSummaryCardDefaultHeader,
} from '@shared/components';
import { GET_BANK_ACCOUNTS_ACTIVE } from '@core/apollo/queries';
import { getDropdownList } from '@shared/components/TransferFunds/helpers';
import { isFutureDate } from '../../helpers/formHelpers';
import useCategories from '../../../../../../features/Category/useCategories';
import useProperties from '../../../../../../features/Property/useProperties';
import { useRecipient, useIsOneTimePayment, useSelectedPaymentMethod } from '../../sendFundsStore';

const ReviewScreen = () => {
  const formik = useFormikContext();
  const { values } = formik;
  const selectedPaymentMethod = useSelectedPaymentMethod();
  const isOneTimePayment = useIsOneTimePayment();
  const recipient = useRecipient();

  const { categoriesForDropdownById } = useCategories({
    sortCategories: false,
  });
  const categoryId = Array.isArray(values.tagId) ? null : values.tagId;
  const [parentCategoryId, subCategoryId] = categoryId?.includes('-')
    ? categoryId.split('-')
    : [categoryId, ''];
  const parentCategory = categoriesForDropdownById[parentCategoryId];
  const subCategory = categoriesForDropdownById[`${parentCategoryId}-${subCategoryId}`];

  const { propertiesByIdForDropdownById } = useProperties();
  const selectedProperty = Array.isArray(values.propertyId) ? null : values.propertyId;
  const [parentPropertyId, selectedUnitId] = selectedProperty?.includes('-')
    ? selectedProperty.split('-')
    : [selectedProperty, ''];

  const parentProperty = propertiesByIdForDropdownById[parentPropertyId];
  const selectedUnit = selectedUnitId ? propertiesByIdForDropdownById[values?.propertyId] : null;

  const formatAddress = (addressObj) => {
    const { street, city, unit, state, postalCode } = addressObj ?? {};
    const propertyUnit = unit ? `${unit},` : '';
    const propertyAddress = `${street}, ${propertyUnit} ${city}, ${state} ${postalCode}`;
    return propertyAddress;
  };

  const paymentMethodItems = () => {
    let items = [];
    if (values?.paymentMethodType === 'ACH') {
      items = [
        {
          label: 'Account holder’s name',
          value: selectedPaymentMethod?.accountHolderName,
        },
        {
          label: 'Routing number',
          value: selectedPaymentMethod?.routingNumber,
        },
        {
          label: 'Account number',
          value: selectedPaymentMethod?.accountNumber,
        },
      ];
    }
    if (values?.paymentMethodType === 'CHECK_PAYMENT') {
      items = [
        {
          label: 'Recipient’s name',
          value: selectedPaymentMethod?.name,
        },
        {
          label: 'Address',
          value: formatAddress(selectedPaymentMethod?.address),
        },
        {
          label: 'Memo',
          value: values?.description,
          isHidden: !values?.description,
        },
      ];
    }
    if (values?.paymentMethodType === 'WIRE_TRANSFER') {
      items = [
        {
          label: 'Account holder’s name',
          value: selectedPaymentMethod?.accountHolderName,
        },
        {
          label: 'Routing number',
          value: selectedPaymentMethod?.routingNumber,
        },
        {
          label: 'Account number',
          value: selectedPaymentMethod?.accountNumber,
        },
        {
          label: 'Address',
          value: formatAddress(selectedPaymentMethod?.address),
        },
        {
          label: 'Message to recipient',
          value: values?.description,
          isHidden: !values?.description,
        },
      ];
    }
    return items;
  };
  const getDynamicHeaderPrefix = () => {
    switch (values?.paymentMethodType) {
      case 'ACH':
        return 'ACH';
      case 'CHECK_PAYMENT':
        return 'Mail a check';
      case 'WIRE_TRANSFER':
        return 'Wire';

      default:
        return null;
    }
  };

  const { data: bankAccountsActiveData, loading } = useQuery(GET_BANK_ACCOUNTS_ACTIVE, {
    variables: {
      isConnectedAccount: true,
      accountStatus: 'Open',
    },
    onError: (err) => console.error(err),
  });

  const accounts = bankAccountsActiveData?.bankAccounts.filter((account) =>
    ['savings', 'checking'].includes(account.accountSubType)
  );
  const { accountsMap } = (accounts && getDropdownList(accounts)) ?? {};
  const showBookkeepingDetails =
    selectedProperty || selectedUnit || parentCategory || subCategory || values.note?.trim();
  const hasPropertySelected = selectedProperty || selectedUnit;
  const hasCategorySelected = parentCategory;
  return (
    <Stack gap={3}>
      {/* First Card */}
      <BaselaneSummaryCard
        isCustom
        {...{
          header: (
            <Box gap={0}>
              <HStack justifyContent="space-between" alignItems="center" fontSize="12px">
                <Text>{`${getDynamicHeaderPrefix()} to`}</Text>
                {isFutureDate(values?.sendOnDate) && (
                  <BaselaneChip
                    size="sm"
                    label="Scheduled"
                    variant="simple-neutral"
                    leftIcon={Icon16Calendar}
                    iconColor="brand.neutral.500"
                  />
                )}
              </HStack>
              <HStack justifyContent="space-between" alignItems="center" fontSize="12px">
                <Box>
                  <Text textStyle="headline-sm" color="brand.neutral.900">
                    {recipient?.name}
                  </Text>
                  <Text textStyle="xs" color="brand.neutral.600">
                    {isOneTimePayment && `Once on ${formatDate(values?.sendOnDate, 'MMM D, YYYY')}`}
                  </Text>
                </Box>
                <Text textStyle="data-lg" color="brand.neutral.900">
                  {
                    formatCurrency(values?.amount ? parseFloat(values.amount.replace(/,/g, '')) : 0)
                      .inDollars
                  }
                </Text>
              </HStack>
            </Box>
          ),
          list: [
            {
              items: [
                {
                  label: 'Processing fee',
                  value: (
                    <HStack as="span" gap={1}>
                      {values?.paymentMethodType === 'WIRE_TRANSFER' && (
                        <BaselaneChip
                          size="sm"
                          label="For a limited time"
                          variant="simple-neutral"
                        />
                      )}
                      <Text>
                        {values?.paymentMethodType === 'CHECK_PAYMENT'
                          ? formatCurrency(2).inDollars
                          : formatCurrency(0).inDollars}
                      </Text>
                    </HStack>
                  ),
                },

                {
                  label: 'From account',
                  value: loading ? (
                    <Spinner />
                  ) : (
                    accountsMap?.[values.fromTransferAccountId]?.institutionName
                  ),
                  subtext: loading
                    ? ''
                    : accountsMap?.[values.fromTransferAccountId]?.name ??
                      accountsMap?.[values.fromTransferAccountId]?.nickName,
                },
              ],
            },
            {
              items: paymentMethodItems(),
            },
          ],
        }}
      />

      {/* Second Card - optional fields: property, category, notes */}
      {showBookkeepingDetails && (
        <BaselaneSummaryCard
          isCustom
          {...{
            header: (
              <BaselaneSummaryCardDefaultHeader
                {...{
                  title: 'Bookkeeping details',
                }}
              />
            ),
            list: [
              {
                items: [
                  {
                    label: 'Property',
                    value: `${parentProperty?.name} ${
                      selectedUnit?.name ? `· ${selectedUnit?.name}` : ''
                    }`,
                    isHidden: !hasPropertySelected,
                  },
                  {
                    label: 'Category',
                    value: parentCategory?.name,
                    subtext: subCategory?.name ? (
                      <HStack gap={1}>
                        <Icon16Subcategory color="#6C7884" />
                        <Text textStyle="sm" color="brand.neutral.700">
                          {subCategory?.name}
                        </Text>
                      </HStack>
                    ) : null,
                    isHidden: !hasCategorySelected,
                  },
                  {
                    label: 'Notes',
                    value: values.note?.trim(),
                    isHidden: !values.note,
                  },
                ],
              },
            ],
          }}
        />
      )}
    </Stack>
  );
};

export default ReviewScreen;
