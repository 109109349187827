import React from 'react';

function IconMoneyCircle({ w, h, color }: { w?: Number, h?: Number, color?: String }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-7.071.929A10 10 0 1 1 17.07 17.07 10 10 0 0 1 2.93 2.93ZM10 4a1 1 0 0 1 1 1v.092c.94.177 1.791.604 2.354 1.253a1 1 0 1 1-1.51 1.31c-.163-.187-.453-.377-.844-.504v1.941a4.534 4.534 0 0 1 1.676.662C13.398 10.234 14 11.009 14 12c0 .99-.602 1.765-1.324 2.246-.48.32-1.054.545-1.676.662V15a1 1 0 1 1-2 0v-.092c-.94-.177-1.792-.604-2.354-1.252a1 1 0 0 1 1.51-1.312c.163.188.453.378.844.505v-1.941a4.534 4.534 0 0 1-1.676-.662C6.602 9.766 6 8.991 6 8c0-.99.602-1.765 1.324-2.246A4.534 4.534 0 0 1 9 5.092V5a1 1 0 0 1 1-1ZM9 7.15c-.22.072-.412.165-.567.268C8.07 7.66 8 7.886 8 8c0 .114.07.34.433.582.155.103.346.196.567.267V7.151Zm2 4v1.7c.22-.072.412-.165.567-.268.364-.242.433-.468.433-.582 0-.114-.07-.34-.433-.582A2.304 2.304 0 0 0 11 11.15Z"
        fill={color}
      />
    </svg>
  );
}

IconMoneyCircle.defaultProps = {
  w: 20,
  h: 20,
  color: 'currentColor',
};

export default IconMoneyCircle;
