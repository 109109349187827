import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const numOfDigitsMask = (integerLimit) => {
  return {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: '',
    allowDecimal: false,
    decimalSymbol: '',
    integerLimit, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: true,
  };
};

const createMask = (integerLimit) => {
  const maskObj = numOfDigitsMask(integerLimit);
  const mask = createNumberMask(maskObj);
  return mask;
};

export default createMask;
