import React from 'react';

function IconDeleted() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.08" cx="12" cy="12" r="12" fill="#192C3E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.866 6.116a1.25 1.25 0 01.884-.366h2.5A1.25 1.25 0 0114.5 7v1.25H17a.625.625 0 110 1.25h-.043l-.5 7.008a1.875 1.875 0 01-1.87 1.742H9.413a1.875 1.875 0 01-1.87-1.742L7.042 9.5H7a.625.625 0 110-1.25h2.5V7c0-.332.132-.65.366-.884zm.884 2.134h2.5V7h-2.5v1.25zM8.296 9.5l.494 6.92a.626.626 0 00.624.58h5.172a.625.625 0 00.624-.58l.494-6.92H8.296zm2.454 1.25c.345 0 .625.28.625.625v3.75a.625.625 0 11-1.25 0v-3.75c0-.345.28-.625.625-.625zm2.5 0c.345 0 .625.28.625.625v3.75a.625.625 0 11-1.25 0v-3.75c0-.345.28-.625.625-.625z"
        fill="#6C7884"
      />
    </svg>
  );
}

export default IconDeleted;
