export const methods = [
  {
    id: 0,
    title: 'ACCOUNT & ROUTING NUMBERS',
    description: 'Use another bank to initiate a Wire or ACH transfer to Baselane',
    defaultType: 'ach',
    methodTitle: 'Select Bank Transfer Type',
    segmentEvent: 'baselane_banking_select_add_fund',
    method: 'external_transfer',
  },
  {
    id: 1,
    title: 'PAYPAL / VENMO',
    description: 'Transfer funds into your Baselane account from your preferred digital wallet',
    defaultType: 'paypal',
    methodTitle: 'Select Your Digital Wallet',
    segmentEvent: 'baselane_banking_select_add_fund',
    method: 'third_party',
  },
];
