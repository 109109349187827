export const textStyles = {
  color: 'brand.neutral.700',
  textStyle: 'xs',
};

export const textLightStyles = {
  color: 'brand.neutral.600',
  textStyle: 'xs',
};

export const buttonStyles = {
  _hover: { backgroundColor: 'brand.darkBlue.50' },
  border: '0',
  p: '0',
  textAlign: 'left',
  position: 'relative',
  w: '100%',
  display: 'block',
};

export const iconStyles = {
  w: '20px',
  position: 'absolute',
  left: '16px',
  top: '50%',
  mt: '-10px',
  color: 'brand.neutral.600',
};

export const gridStyles = { py: '12px', px: '45px', alignItems: 'center', minH: '65px' };
export const nestedGridStyles = {
  ...gridStyles,
  pl: '0',
  ml: '45px',
  borderBottomWidth: '1px',
  borderStyle: 'dashed',
  borderColor: 'brand.darkBlue.150',
  _first: {
    borderTopWidth: '1px',
    borderStyle: 'dashed',
    borderColor: 'brand.darkBlue.150',
  },
  _last: { borderBottom: 0 },
};
