export const drawerHeaderStyles = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'brand.darkBlue.800A',
  color: 'brand.neutral.white',
  minHeight: '80px',
  fontSize: 'lg',
  fontWeight: 'semibold',
  lineHeight: '26px',
  p: '28px 32px',
};

export const mobileDrawerHeaderStyles = {
  bg: 'brand.neutral.white',
  p: '8px 24px',
  color: 'brand.neutral.700',
  fontSize: 'lg',
  fontWeight: 'semibold',
  lineHeight: '26px',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minH: '48px',
};

export const newDrawerHeaderStyles = {
  min769: {
    ...mobileDrawerHeaderStyles,
    boxShadow: 'none',
    borderBottom: '1pt solid #DADFE6',
    p: '16px 24px',
    minH: '64px',
  },
  min577max768: {
    ...mobileDrawerHeaderStyles,
    boxShadow: 'none',
    borderBottom: '1pt solid #DADFE6',
    p: '16px 20px',
    minH: '64px',
  },
  max576: {
    ...mobileDrawerHeaderStyles,
    boxShadow: 'none',
    borderBottom: '1pt solid #DADFE6',
    p: '12px 16px',
    borderTopRadius: '12px',
    minH: '56px',
  },
};

export const mobileDrawerHeaderCloseButtonStyles = {
  right: '24px',
  top: '10px',
  size: 'sm',
  mt: '2px',
  color: 'brand.blue.800A',
  bg: 'brand.neutral.white',
};

export const newDrawerHeaderCloseButtonStyle = {
  position: 'static', // overwrite default chakra style
  color: 'brand.darkBlue.800A',
};

export const drawerFooterStyles = {
  justifyContent: 'flex-start',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'brand.neutral.white',
  minHeight: '80px',
  p: '20px 32px',
  boxShadow: '1px -2px 6px 0px rgba(3, 24, 64, 0.04);',
};

export const newDrawerFooterStyles = {
  min769: {
    ...drawerFooterStyles,
    boxShadow: 'none',
    borderTop: '1pt solid #DADFE6',
    p: '16px 24px',
    minH: '72px',
    w: '100%',
  },
  min577max768: {
    ...drawerFooterStyles,
    boxShadow: 'none',
    borderTop: '1pt solid #DADFE6',
    p: '16px 20px',
    minH: '72px',
    w: '100%',
  },
  max576: {
    ...drawerFooterStyles,
    boxShadow: 'none',
    borderTop: '1pt solid #DADFE6',
    p: '16px 16px',
    minH: '72px',
    w: '100%',
  },
};

export const drawerBodyStyles = {
  color: 'brand.neutral.700',
  p: '64px',
};

export const mobileDrawerBodyStyles = {
  color: 'brand.neutral.700',
  p: '16px',
};

export const newDrawerBodyStyles = {
  min769: {
    color: 'brand.neutral.700',
    p: '24px',
  },
  min577max768: {
    color: 'brand.neutral.700',
    p: '20px',
  },
  max576: {
    color: 'brand.neutral.700',
    p: '16px',
  },
};

export const drawerTitleStyles = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
};

export const drawerCloseBtnStyles = {
  color: 'brand.neutral.white',
  marginTop: '15px',
  mr: '20px',
  w: '32px',
  h: '32px',
};
