import React from 'react';
import { useGate } from 'statsig-react';

import DashboardPageOld from '@core/components/Dashboard';
import DashboardPage from '@pages/Dashboard';

/**
 * Component that renders old ui for dashboard if gate is false, otherwise renders the new dashboard ui.
 * @returns {JSX.Element}
 */
const DashboardFeatureGateGuard = () => {
  const { value: showNewDashboard } = useGate('dashboard-gate');

  return showNewDashboard ? <DashboardPage /> : <DashboardPageOld />;
};

export default DashboardFeatureGateGuard;
