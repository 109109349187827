// BaseLane: Styles for CashFlow dashboard widget elements
export const cashFlowAmountBox = (noBankData, isMobile) => {
  if (!noBankData) {
    return {
      bg: !isMobile ? 'brand.neutral.white' : 'brand.blue.900',
      py: !isMobile ? '36px' : '4px',
      px: '0',
      borderBottomLeftRadius: !isMobile ? '0' : '8px',
      borderBottomRightRadius: !isMobile ? '0' : '8px',
    };
  }
  return {
    pt: !isMobile ? '24px' : '16px',
    pb: !isMobile ? 0 : '16px',
    borderBottomLeftRadius: !isMobile ? '0' : '8px',
    borderBottomRightRadius: !isMobile ? '0' : '8px',
  };
};

export const currencyTextStyle = (isDashboard, isMobile) => ({
  isRounded: true,
  textStyle: isMobile ? 'headline-md' : 'headline-2xl',
  negativeColor: !isDashboard || (isDashboard && !isMobile) ? 'red.500A' : 'brand.neutral.white',
  color: !isDashboard || (isDashboard && !isMobile) ? 'brand.neutral.700' : 'brand.neutral.white',
  mt: '0px',
});

export const firstItemStyles = (isMobile) => ({
  pl: !isMobile ? '24px' : '16px',
});

export const customBorderStyles = (isDashboard, isMobile) => ({
  borderLeftWidth: !isDashboard || (isDashboard && !isMobile) ? '1px' : '0px',
});

export const customFontStyles = (isDashboard, hasNoData, isMobile) => {
  if (!isDashboard || (isDashboard && !isMobile)) {
    return {
      fontWeight: 'normal',
      color: 'brand.neutral.700',
    };
  }
  return {
    fontWeight: 'normal',
    color: hasNoData ? 'brand.neutral.600' : 'brand.darkBlue.300',
  };
};

export const spinnerStyles = {
  color: 'brand.darkBlue.150',
  position: 'absolute',
  top: '50%',
  left: '50%',
};
