import React, { useRef, useContext, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { VStack, FormControl, Input, Text } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { NB_ACCOUNTS } from '@routes';
import { Icon16ArrowForward } from '@icons/16px';
import { formatNumbers } from '@shared/components/BaselaneKYCForm/helpers/kycForm.helpers';
import { BaselaneButton, BaselaneFormLabel, BaselaneFormErrorMessage } from '@shared/components';
import useTwoFactor from '@shared/components/TwoFactorVerificationPopUp/hooks/useTwoFactor';
import UserContext from '@contexts/UserContext';
import { phoneMaskWithCountryCode } from '@core/utils/masks';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import TwoFactorPhonePopUp from './TwoFactorPhonePopUp';

/**
 * 2024-07_sign_up_page_experiment
 * Similar to PhonePage/Phone, with some additional changes including different OTP popup and components
 */
function PhoneForm() {
  const { user, refetchUser } = useContext(UserContext);
  const formikRef = useRef();
  const initialFormValues = { phone: '' };

  const { phoneNumber, email } = user;

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [submittedPhoneNumber, setSubmittedPhoneNumber] = useState(null);
  const [finalSubmit, setFinalSubmit] = useState(false);

  const [phoneNumberIsVerified, setPhoneNumberIsVerified] = useState(false);
  const [phoneNumberIsNotVoip, setPhoneNumberIsNotVoip] = useState(false);

  const [customPhoneError, setCustomPhoneError] = useState(null);

  const { states, stateFunctions } = useTwoFactor(true);
  const { OTPErrorCode } = states;
  const { onOTPPopupOpen, setOTPErrorCode, handleSendText, handleVerifyOtp } = stateFunctions;

  const handleValidation = (values) => {
    const { phone } = values;
    const errors = {};

    const filterPhoneNumber = phone?.replace(/[^0-9]/g, '');

    if (filterPhoneNumber === '' || filterPhoneNumber?.length < 11) {
      errors.phone = 'Please enter phone number';
      setOTPErrorCode(false);
    } else if (OTPErrorCode === 400) {
      errors.phone = customPhoneError;
    }

    return errors;
  };

  const handleSendTextSuccess = () => {
    setOTPErrorCode(false);
    onOTPPopupOpen();
    setPhoneNumberIsNotVoip(true);
  };

  const handleSendTextFail = (error) => {
    setCustomPhoneError(error);
  };

  const handleVerifyOtpSuccess = () => {
    setPhoneNumberIsVerified(true);
  };

  const handleFormSubmit = (e) => {
    const { phone } = e;
    const formattedPhoneNumber = phone
      ? formatNumbers(phone).numberWithoutSpaces
      : submittedPhoneNumber;
    setSubmittedPhoneNumber(formattedPhoneNumber);

    if (phoneNumberIsVerified && phoneNumberIsNotVoip) {
      setIsLoading(true);
      refetchUser().then(() => {
        setIsLoading(false);
        navigate(NB_ACCOUNTS, { replace: true });
      });
    } else {
      handleSendText(formattedPhoneNumber, false, handleSendTextSuccess, handleSendTextFail);
    }
  };

  const onClickSubmit = (e) => {
    sendSegmentEvent('onboarding_phonenumber_continue', {
      email,
      phone_number: phoneNumber,
    });
    handleFormSubmit(e);
  };

  useEffect(() => {
    // effectuate any new inline errors after OTP (otherwise it doesn't pick up the 101 or 102 after submit)
    if (OTPErrorCode === 101 || OTPErrorCode === 102) {
      formikRef?.current?.validateForm();
    }
    if (phoneNumberIsVerified && !finalSubmit) {
      handleFormSubmit({ submittedPhoneNumber });
      setFinalSubmit(true);
    }
  });

  // TODO: this is from BaselaneKYCForm, store in helper and reuse
  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP: () => handleSendText(submittedPhoneNumber, true, handleSendTextSuccess),
    phoneNumber: formikRef?.current?.values.phone,
    handleVerifyOnClick: (otpCode) =>
      handleVerifyOtp({ recipient: submittedPhoneNumber, code: otpCode }, handleVerifyOtpSuccess),
  };

  return (
    <>
      <TwoFactorPhonePopUp {...twoFactorVerificationProps} />
      <Formik
        innerRef={formikRef}
        validate={(values) => handleValidation(values)}
        initialValues={initialFormValues}
        onSubmit={onClickSubmit}
        validateOnChange
        validateOnMount
      >
        {({ values, handleSubmit, isValid, handleBlur, handleChange, errors, touched }) => (
          <VStack w="100%">
            <FormControl
              mb="16px"
              isInvalid={(errors.phone && touched.phone) || customPhoneError}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            >
              <BaselaneFormLabel htmlFor="phone" mb="10px">
                Mobile Phone (no <abbr title="Voice Over Internet Protocol">VOIP</abbr>)
              </BaselaneFormLabel>
              <Input
                size="lg"
                mb="6px"
                as={MaskedInput}
                mask={phoneMaskWithCountryCode('1')}
                id="phone"
                name="phone"
                value={values.phone}
                onChange={(event) => {
                  if (customPhoneError) {
                    setCustomPhoneError(null);
                  }
                  handleChange(event);
                }}
                onBlur={handleBlur}
                placeholder="+1 (***) *** ****"
                pattern="\d*"
                inputMode="decimal"
                autoFocus
              />
              <BaselaneFormErrorMessage ml="0" mt="0" mb="6px">
                {errors.phone || customPhoneError}
              </BaselaneFormErrorMessage>
              <Text textStyle="xs" color="brand.neutral.600">
                We&apos;ll text you to confirm your number.
              </Text>
            </FormControl>
            <BaselaneButton
              id="signUpButton" // add for consultants for growth team
              variant="filled"
              palette="primary"
              size="lg"
              onClick={handleSubmit}
              isDisabled={!isValid}
              rightIcon={!isMobile && <Icon16ArrowForward />}
              isLoading={isLoading}
              styles={{ alignSelf: 'flex-start' }}
            >
              Continue
            </BaselaneButton>
          </VStack>
        )}
      </Formik>
    </>
  );
}

export default PhoneForm;
