import hasOnlyWhitespace from '@core/utils/hasOnlyWhitespace';
import isValidSSN from '@core/utils/isValidSSN';

export const initialValues = {
  firstName: '',
  lastName: '',
  dob: '',
  address: '',
  unitNumber: '',
  city: '',
  zipcode: '',
  state: '',
  ssn: '',
  phoneNumber: '',
};

export const handleValidation = (values) => {
  const { firstName, lastName, dob, address, city, zipcode, state, ssn } = values;
  const validSSN = isValidSSN(ssn);
  const validDOB =
    /(?:0[1-9]|1[012])([/.-])(?:0[1-9]|[12]\d|3[01])([/.-])(?:19|20)\d\d/g.test(dob) &&
    Number(dob.substring(dob.length - 4)) < 2022;

  const errors = {};

  if (firstName === '' || hasOnlyWhitespace(firstName)) {
    errors.firstName = 'Please enter first name';
  }

  if (lastName === '' || hasOnlyWhitespace(lastName)) {
    errors.lastName = 'Please enter last name';
  }

  if (!validDOB) {
    errors.dob = 'Please enter valid date of birth';
  }

  if (address === '' || hasOnlyWhitespace(address)) {
    errors.address = 'Please enter address';
  }

  if (city === '' || hasOnlyWhitespace(city)) {
    errors.city = 'Please enter city';
  }

  if (zipcode === '' || zipcode.length < 5) {
    errors.zipcode = 'Please enter zip code';
  }

  if (state === '') {
    errors.state = 'Please enter state';
  }

  if (!validSSN) {
    errors.ssn = 'Please enter valid SSN';
  }

  return errors;
};

export const formatNumbers = (phoneNumber) => {
  const numberWithSpaces = phoneNumber.replace(/\(|\)/g, '').replace(/-/g, ' ');
  const numberWithoutSpaces = numberWithSpaces.replace(/\s/g, '');

  return {
    numberWithSpaces,
    numberWithoutSpaces,
    e64Format: `+1${numberWithoutSpaces}`,
  };
};

export const getDataToSubmit = (formVariables) => {
  const { address, unitNumber, city, zipcode: zipCode, state, dob, ssn } = formVariables;

  const { userAgent } = navigator;
  const dobSplit = dob.split('-');
  const dobMonth = dobSplit[0];
  const dobDay = dobSplit[1];
  const dobYear = dobSplit[2];
  const dobFormatted = `${dobYear}-${dobMonth}-${dobDay}`;
  const ssnLast4 = ssn.substring(ssn.length - 4, ssn.length);
  const iovation = window?.IGLOO?.getThirdPartybb ? window?.IGLOO?.getThirdPartybb() : null;

  return {
    ...formVariables,
    address: {
      line1: address,
      line2: unitNumber,
      city,
      postalCode: zipCode,
      state,
      // addresses are for United States at the moment for autocomplete. See server.js to update it if needed.
      country: 'US',
    },
    userAgent,
    dob: dobFormatted,
    dobMonth,
    dobDay,
    dobYear,
    ssnLast4,
    iovation,

    // testing code TODO: remove when everything is ready to be merged
    // ssn: '000000000',
    // ssnLast4: '0000',
  };
};
