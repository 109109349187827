import React from 'react';

function IconAccountAndRoutingNumbers() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35 21.5V9.2C35 8.07989 35 7.51984 34.782 7.09202C34.5903 6.71569 34.2843 6.40973 33.908 6.21799C33.4802 6 32.9201 6 31.8 6H8.2C7.0799 6 6.51984 6 6.09202 6.21799C5.71569 6.40973 5.40973 6.71569 5.21799 7.09202C5 7.51984 5 8.07989 5 9.2V38.8C5 39.9201 5 40.4802 5.21799 40.908C5.40973 41.2843 5.71569 41.5903 6.09202 41.782C6.51984 42 7.07989 42 8.2 42H20"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 17H31" stroke="#000619" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 21H31" stroke="#000619" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 25H20" stroke="#000619" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M36 46C41.5228 46 46 41.5228 46 36C46 30.4772 41.5228 26 36 26C30.4772 26 26 30.4772 26 36C26 41.5228 30.4772 46 36 46Z"
        fill="#6ADA9B"
      />
      <path
        d="M34 45C39.5228 45 44 40.5228 44 35C44 29.4772 39.5228 25 34 25C28.4772 25 24 29.4772 24 35C24 40.5228 28.4772 45 34 45Z"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31 36.3462C31 37.2383 31.8954 37.9615 33 37.9615H34.8571C36.0406 37.9615 37 37.1866 37 36.2308C37 35.2749 36.0406 34.5 34.8571 34.5H33.1429C31.9594 34.5 31 33.7251 31 32.7692C31 31.8134 31.9594 31.0385 33.1429 31.0385H35C36.1046 31.0385 37 31.7617 37 32.6538M34 30V31.0385M34 37.9615V39"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.5 10V13" stroke="#000619" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9 10.5312L12 12.4596"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10.5312L9 12.4596"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.5 10V13" stroke="#000619" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15 10.5312L18 12.4596"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3203 10.5312L15.3203 12.4596"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M22.8203 10V13" stroke="#000619" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M21.3203 10.5312L24.3203 12.4596"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3203 10.5312L21.3203 12.4596"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconAccountAndRoutingNumbers;
