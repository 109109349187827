/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Spacer, Text, Progress, Divider } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import formatCurrency from '@core/utils/formatCurrency';
import checkIsTransfer from '@core/utils/checkIsTransfer.helper';
import useScheduledRecurringPaymentDetails from './useScheduledRecurringPaymentDetails.hook';
import ScheduledPaymentsAccordion from './ScheduledPaymentsAccordion';
import { transferStates } from './constants';
import ScheduledPayment from './ScheduledPayment';
import UpcomingPaymentsAccordion from './UpcomingPaymentsAccordion';

const ScheduledPayments = ({ recurringPaymentId }) => {
  const { data } = useScheduledRecurringPaymentDetails(recurringPaymentId);

  const processedTransfers = data?.transfers?.filter(
    (transfer) =>
      transfer.state === transferStates.TRANSFER_COMPLETED ||
      transfer.state === transferStates.TRANSFER_RETURNED ||
      transfer.state === transferStates.TRANSFER_INVALID ||
      transfer.state === transferStates.TRANSFER_FAILED ||
      transfer.state === transferStates.TRANSFER_DEPOSITED ||
      transfer.state === transferStates.TRANSFER_DELIVERED ||
      transfer.state === transferStates.TRANSFER_IN_TRANSIT
  );

  const processingTransfers = data?.transfers?.filter(
    (transfer) => transfer.state === transferStates.TRANSFER_PROCESSING
  );

  const canceledTransfers = data?.transfers?.filter(
    (transfer) => transfer.state === transferStates.TRANSFER_CANCELED
  );

  const nextTransfer = data?.transfers?.find(
    (transfer) => transfer.state === transferStates.TRANSFER_SCHEDULED
  );

  const isTransfer = checkIsTransfer(data);
  const scheduledType = isTransfer ? 'transfers' : 'payments';
  const oneScheduledType = isTransfer ? 'transfer' : 'payment';
  return (
    <>
      <Divider my={3} opacity={1} color="brand.darkBlue.200" />
      <Box display="flex" alignItems="center">
        <Box>
          <Text textStyle="sm-heavy">{`Scheduled ${scheduledType}`}</Text>
          <Text textStyle="xs" color="brand.neutral.600">
            {data?.numberOfPaymentsProcessed} of {data?.transfers.length} processed
          </Text>
        </Box>
        <Spacer />
        <Text textStyle="md-heavy">{formatCurrency(data?.totalAmount).inDollars}</Text>
      </Box>
      <Progress
        sx={{
          mt: 1,
        }}
        value={data?.numberOfPaymentsProcessed}
        max={data?.transfers.length}
        h={1.5}
        borderRadius={5}
      />
      <ScheduledPaymentsAccordion title={`Processed ${scheduledType}`}>
        {processedTransfers?.map((transfer) => (
          <ScheduledPayment key={transfer.transferDate} scheduledPayment={transfer} />
        ))}
      </ScheduledPaymentsAccordion>
      <ScheduledPaymentsAccordion title={`Processing ${scheduledType}`}>
        {processingTransfers?.map((transfer) => (
          <ScheduledPayment key={transfer.transferDate} scheduledPayment={transfer} />
        ))}
      </ScheduledPaymentsAccordion>

      <ScheduledPaymentsAccordion title={`Canceled ${scheduledType}`}>
        {canceledTransfers?.map((transfer) => (
          <ScheduledPayment key={transfer.transferDate} scheduledPayment={transfer} />
        ))}
      </ScheduledPaymentsAccordion>

      {nextTransfer && (
        <ScheduledPaymentsAccordion title={`Next ${oneScheduledType}`}>
          <ScheduledPayment
            key={nextTransfer.id}
            scheduledPayment={nextTransfer}
            recurringPaymentId={recurringPaymentId}
          />
        </ScheduledPaymentsAccordion>
      )}

      <UpcomingPaymentsAccordion title={`Upcoming ${scheduledType}`} data={data} />
      <Outlet />
    </>
  );
};

export default ScheduledPayments;
