import React, { useState } from 'react';
import moment from 'moment';
import { Textarea, Stack, Spacer, Text, FormLabel, useToast } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { BaselaneButton, BaselaneButtonGroup, BaselaneDrawer } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import { formTitleStyles, daysAgoStyles, formLabelStyles } from '../styles/drawer.style';
import { UPDATE_TENANT_PROFILE } from '../queries';

function NotesDrawer({
  isOpen,
  onClose,
  notes: savedNotes,
  tenantId,
}: {
  isOpen: Function,
  onClose: Function,
  notes: string,
  tenantId: string,
}): any {
  const toast = useToast();
  const showToastSave = () =>
    toast({
      position: 'bottom-left',
      description: 'Notes updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  const [note] = savedNotes || [{ text: '', date: new Date() }];
  const initialNote = note ? note.text : '';
  const [updatedNote, setNotes] = useState(initialNote);
  const [updateNotes] = useMutation(UPDATE_TENANT_PROFILE);
  const handleNotesUpdate = (event) => {
    setNotes(event.target.value);
  };
  const days = moment(note.date).fromNow(true);
  const handleNotesSave = () => {
    updateNotes({
      variables: { id: tenantId, notes: [{ date: new Date(), text: updatedNote }] },
    }).then(() => {
      onClose();
      showToastSave();
    });
  };
  const { isMax767 } = getBreakPoints();

  return (
    <BaselaneDrawer
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      trapFocus={false}
      title="Add Notes"
      footer={
        <BaselaneButtonGroup size="md">
          <BaselaneButton variant="outline" palette="transparent" onClick={onClose} size="md">
            Cancel
          </BaselaneButton>
          <BaselaneButton variant="filled" palette="primary" size="md" onClick={handleNotesSave}>
            Save
          </BaselaneButton>
        </BaselaneButtonGroup>
      }
      newDesignDrawer
    >
      <Stack direction="row">
        <Text {...formTitleStyles}>Tenant Notes</Text>
        <Spacer />
        <Text {...daysAgoStyles}>Saved - Last edit {days} ago</Text>
      </Stack>
      <FormLabel {...formLabelStyles}>Notes</FormLabel>
      <Textarea
        minH="250px"
        placeholder="Write something here"
        value={updatedNote}
        onChange={handleNotesUpdate}
      />
    </BaselaneDrawer>
  );
}

export default NotesDrawer;
