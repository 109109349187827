import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Stack } from '@chakra-ui/react';
import {
  BaselaneAlert,
  BaselaneAlertNew,
  BaselaneButtonGroup,
  BaselaneButton,
} from '@core/components/Shared/components';
import { Icon16Lightbulb } from '@core/components/Icons/16px';
import { ReactComponent as Celebrate } from '@core/components/Icons/32px/Celebrate.svg';
import { ReactComponent as BankUpload } from '@core/components/Icons/32px/BankUpload.svg';

interface ProcessingFeeAlertProps {
  externalAccountCollectionCount: numberlike;
  handleOpenRecievingBankAccount: () => void;
  isExternalBankAccount: boolean;
}

export function ProcessingFeeAlert({
  externalAccountCollectionCount,
  handleOpenRecievingBankAccount,
  isExternalBankAccount,
}: ProcessingFeeAlertProps) {
  const cancelRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <BaselaneAlertNew
        variant={!isExternalBankAccount ? 'success' : 'primary'}
        iconName={!isExternalBankAccount ? Celebrate : Icon16Lightbulb}
        visual={!isExternalBankAccount ? 'illustration' : 'icon'}
        title={
          !isExternalBankAccount
            ? 'This rent collection has free tenant ACH payments'
            : 'Get free tenant ACH payments'
        }
        body={
          <Stack>
            <Text textStyle="sm">
              {!isExternalBankAccount
                ? 'Fees for tenant ACH payments are being waived, as rent is currently collected into Baselane Banking.'
                : 'Collect rent into a Baselane Banking account to get free tenant ACH payments.'}
            </Text>
            {isExternalBankAccount && (
              <Box>
                <BaselaneButton
                  onClick={
                    externalAccountCollectionCount > 1
                      ? () => setShowModal(true)
                      : () => {
                          setShowModal(false);
                          handleOpenRecievingBankAccount();
                        }
                  }
                  palette="neutral"
                  size="sm"
                  variant="outline"
                >
                  Update receiving account
                </BaselaneButton>
              </Box>
            )}
          </Stack>
        }
      />

      <BaselaneAlert
        isOpen={showModal}
        leastDestructiveRef={cancelRef}
        onClose={() => setShowModal(false)}
        isCentered
        showCloseButton
        size="sm"
        bodyStyles={{ mt: 0.5 }}
        footerStyles={{ mt: 3 }}
        header={
          <Stack gap={2}>
            <BankUpload />
            <Text textStyle="headline-lg">
              Would you like to enable free tenant ACH payments for all units?
            </Text>
          </Stack>
        }
        body={
          <Stack gap={1}>
            <Text textStyle="sm">
              {externalAccountCollectionCount} units have rent being collected into external
              accounts.
            </Text>
            <Text textStyle="sm">
              Update them all with a Baselane Banking account to get free tenant ACH payments.
            </Text>
          </Stack>
        }
        footer={
          <BaselaneButtonGroup styles={{ minW: '100%', gap: 1.5 }}>
            <BaselaneButton
              palette="neutral"
              variant="outline"
              size="md"
              styles={{ w: '50%' }}
              onClick={() => {
                setShowModal(false);
                handleOpenRecievingBankAccount();
              }}
            >
              Update this unit only
            </BaselaneButton>
            <BaselaneButton
              palette="primary"
              variant="filled"
              size="md"
              styles={{ w: '50%', m: '0 !important' }}
              onClick={() => {
                setShowModal(false);
                navigate('updateaccounts');
              }}
            >
              Update all units
            </BaselaneButton>
          </BaselaneButtonGroup>
        }
      />
    </>
  );
}
