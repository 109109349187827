// @flow

import React from 'react';
import { sortBy } from 'lodash';
import { Box } from '@chakra-ui/react';

import AccountApplicationBanners from './AccountApplicationBanners';
import AccountsTable from './AccountsTable';
import { accountsContentContainerStyles } from '../styles/accounts.style';

type AccountsAndApplicationsProps = {
  bankList: Array<Object>,
  refetchBankList: Function,
};

function AccountsAndApplications({ bankList, refetchBankList }: AccountsAndApplicationsProps): any {
  const filteredBankList = bankList.filter((bank) => {
    // Note: hide soft-deleted applications from the UI, but if account is in completed state AND hidden,
    // that means the account was manually added so it should be shown regardless of hidden status
    return (
      bank.isHidden === false ||
      ((!bank.isHidden || bank.isHidden === true) && bank.unitApplicationStatus === 'COMPLETED')
    );
  });

  const incompleteApplications = filteredBankList.filter(
    (item) => item?.unitApplicationStatus !== 'COMPLETED'
  );
  const activeAccounts = filteredBankList.filter(
    (item) => item?.unitApplicationStatus === 'COMPLETED'
  );

  // sort by name
  const accountListData = sortBy(activeAccounts, ['name']);
  // sort by Open vs Closed accountStatus; closed accounts are at the end
  accountListData.sort((account) => (account?.bankAccountStatus === 'Closed' ? 1 : -1));

  return (
    <Box {...accountsContentContainerStyles}>
      {/* Application Banners */}
      <AccountApplicationBanners
        refetchBankList={refetchBankList}
        incompleteApplications={incompleteApplications}
      />
      {/* Accounts List */}
      <AccountsTable accountListData={accountListData} />
    </Box>
  );
}

export default AccountsAndApplications;
