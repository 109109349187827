import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import IconClose from '@icons/legacy/IconClose';
import { BaselaneButtonIcon } from '../BaselaneButtonIcon/index';
import {
  alertHeader,
  alertMobileHeader,
  alertBody,
  alertMobileBody,
  alertFooter,
  alertMobileFooter,
  alertContainer,
  alertMobileContainer,
} from './styles/alert.styles';

type AlertProps = {
  showCloseButton?: boolean,
  isOpen: Function,
  onClose: Function,
  closeOnOverlayClick?: boolean,
  isCentered?: boolean,
  header?: any,
  body?: any,
  cancelRef?: any,
  footer?: any,
  finalFocusRef?: any,
  returnFocusOnClose?: any,
  size?: string,
  containerStyles?: Object,
  headerStyles?: Object,
  bodyStyles?: Object,
  footerStyles?: Object,
  overlayStyles?: Object,
  closeButtonStyles?: Object,
  closeButtonIconProps?: Object,
  trapFocus?: boolean,
  isMinXL?: boolean,
};

function BaselaneAlert({
  showCloseButton = false,
  isOpen,
  onClose,
  header = null,
  body,
  cancelRef,
  footer,
  finalFocusRef,
  closeOnOverlayClick = false, // Q. should it be false? A. yes
  isCentered = true,
  returnFocusOnClose = false,
  size = 'lg',
  containerStyles = {},
  headerStyles = {},
  bodyStyles = {},
  footerStyles = {},
  overlayStyles = {},
  closeButtonStyles = {},
  closeButtonIconProps = { color: '#3A4B5B' },
  trapFocus = true,
  isMinXL = false,
}: AlertProps) {
  const alertHeaderStyles =
    isMinXL || isMobile
      ? { ...alertMobileHeader(showCloseButton), ...headerStyles }
      : {
          ...alertHeader,
          ...headerStyles,
        };

  const alertBodyStyles =
    isMinXL || isMobile
      ? { ...alertMobileBody, ...bodyStyles }
      : {
          ...alertBody,
          ...bodyStyles,
        };

  const alertFooterStyles =
    isMinXL || isMobile
      ? { ...alertMobileFooter, ...footerStyles }
      : {
          ...alertFooter,
          ...footerStyles,
        };

  const alertContainerStyles =
    isMinXL || isMobile
      ? { ...alertMobileContainer, ...containerStyles }
      : { ...alertContainer, ...containerStyles };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
      isCentered={isCentered}
      size={size}
      finalFocusRef={finalFocusRef}
      returnFocusOnClose={returnFocusOnClose}
      trapFocus={trapFocus}
    >
      <AlertDialogOverlay {...overlayStyles}>
        <AlertDialogContent {...alertContainerStyles}>
          <AlertDialogHeader {...alertHeaderStyles}>{header}</AlertDialogHeader>
          {showCloseButton && (
            <AlertDialogCloseButton as={BaselaneButtonIcon} icon={<IconClose />} />
          )}

          {body && <AlertDialogBody {...alertBodyStyles}>{body}</AlertDialogBody>}

          {footer && <AlertDialogFooter {...alertFooterStyles}>{footer}</AlertDialogFooter>}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

BaselaneAlert.defaultProps = {
  closeOnOverlayClick: false,
  isCentered: true,
  returnFocusOnClose: false,
  size: 'lg',
  containerStyles: {},
  headerStyles: {},
  bodyStyles: {},
  footerStyles: {},
  overlayStyles: {},
  closeButtonStyles: {},
  closeButtonIconProps: { color: '#3A4B5B' },
  header: null,
  body: null,
  footer: null,
  cancelRef: null,
  finalFocusRef: null,
  showCloseButton: false,
  trapFocus: true,
  isMinXL: false,
};

export default BaselaneAlert;
