// @flow
import React from 'react';
import { Select } from '@chakra-ui/react';
import { STATES } from './helpers/stateSelect.helpers';
import { selectStatesStyles } from './stateSelect.styles';
import './stateSelect.styles.scss';

function BaselaneStatesSelect({
  id,
  name,
  value,
  onChange: handleChange,
  onBlur: handleBlur,
  placeholder,
}: {
  id: string,
  name: string,
  value: string,
  onChange: Function,
  onBlur: Function,
  placeholder: string,
}): any {
  const stateOptions = STATES.map((state) => (
    <option key={state} id={state} value={state}>
      {state}
    </option>
  ));

  return (
    <Select
      {...selectStatesStyles(value === '')}
      id={id}
      name={name}
      value={value}
      onChange={(e) => handleChange(e)}
      onBlur={(e) => handleBlur(e)}
      placeholder={placeholder}
    >
      {stateOptions}
    </Select>
  );
}

export default BaselaneStatesSelect;
