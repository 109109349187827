import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Text, Stack, useDisclosure } from '@chakra-ui/react';
import {
  BaselaneButton,
  BaselaneButtonIcon,
  ResponsiveActionButton,
  ResponsiveActionButtonWrapper,
} from '@shared/components';
import { ADD_VIRTUAL_CARD } from '@routes';
import { IconCreditCardImporting } from '@icons';
import BanksContext from '@contexts/BanksContext';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import ActivatePhysicalCardAlert from '@core/components/NativeBankingPage/components/ActivatePhysicalCardAlert';

import { triggerBtnWrapperStyles, triggerBtnLableStyles } from './styles/addVirtualCard.styles';

type TriggerButtonProps = {
  type?: string,
  size?: String,
  iconName?: string,
  variant?: string,
  palette?: string,
  showMobileVersion?: Boolean,
  styles?: Object,
};

const TriggerButton = ({
  type,
  size,
  iconName,
  variant,
  palette,
  showMobileVersion,
  styles,
}: TriggerButtonProps) => {
  const navigate = useNavigate();
  const currLocation = useLocation();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  const { hasActivatedAPhysicalCard } = useContext(BanksContext);

  const onClick = () => {
    navigate({ pathname: `${currLocation.pathname}/${ADD_VIRTUAL_CARD}` });
    // open drawer
    sendSegmentEvent('baselane_banking_start_add_virtual_card');
  };

  const handleOnClick = () => {
    if (hasActivatedAPhysicalCard) {
      onClick();
    } else {
      onAlertOpen();
    }
  };

  if (showMobileVersion) {
    return (
      <>
        <Stack {...triggerBtnWrapperStyles}>
          <BaselaneButtonIcon
            variant={variant}
            palette={palette}
            size={size}
            icon={<IconCreditCardImporting />}
            styles={styles}
            onClick={handleOnClick}
          />
          <Text {...triggerBtnLableStyles}>Add virtual card</Text>
        </Stack>
        <ActivatePhysicalCardAlert isAlertOpen={isAlertOpen} onAlertClose={onAlertClose} />
      </>
    );
  }
  return (
    <>
      {type === 'responsiveButton' ? (
        <ResponsiveActionButtonWrapper p="3px">
          <ResponsiveActionButton iconSize={size} iconName={iconName} onClick={handleOnClick}>
            <Text>Add virtual card</Text>
          </ResponsiveActionButton>
        </ResponsiveActionButtonWrapper>
      ) : (
        <BaselaneButton
          variant={variant}
          palette={palette}
          type={type}
          size={size}
          styles={styles}
          onClick={() => handleOnClick()}
        >
          Add virtual card
        </BaselaneButton>
      )}
      <ActivatePhysicalCardAlert isAlertOpen={isAlertOpen} onAlertClose={onAlertClose} />
    </>
  );
};

TriggerButton.defaultProps = {
  type: '',
  variant: 'outline',
  palette: 'neutral',
  size: 'md',
  showMobileVersion: false,
  iconName: 'addDottedRectangle',
  styles: {},
};
export default TriggerButton;
