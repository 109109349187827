// @flow
export const MortgageTermEnum = Object.freeze({
  FIXED_10_YEAR: 'FIXED_10_YEAR',
  FIXED_15_YEAR: 'FIXED_15_YEAR',
  FIXED_20_YEAR: 'FIXED_20_YEAR',
  FIXED_30_YEAR: 'FIXED_30_YEAR',
  UNSURE: 'UNSURE',
});

export const mortgageTermOptions = [
  { id: 'FIXED_10_YEAR', label: '10-Year Fixed Rate' },
  { id: 'FIXED_15_YEAR', label: '15-Year Fixed Rate' },
  { id: 'FIXED_20_YEAR', label: '20-Year Fixed Rate' },
  { id: 'FIXED_30_YEAR', label: '30-Year Fixed Rate' },
  { id: 'UNSURE', label: "I'm not sure" },
];
