import React, { forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@chakra-ui/react';
import ParentListItem from '../../ParentListItem';

import { setIsSelectedItem } from '../../helpers/dropdown.shared.helpers';
import { listStyles, menuOptionGroupStyles, menuOptionStyles } from '../../styles/dropdown.styles';

type ListOfOptionsProps = {
  onListItemClick: Function,
  options: Array<any>,
  selectedStagedOptions: Array<any>,
  optionStyles: any,
  itemRenderer: Function,
  hasFilterWrapper: Boolean,
  searchTerm: Array<String>,
  search: String,
  hideSearch?: Boolean,
};

const ListOfOptions = forwardRef(
  (
    {
      onListItemClick,
      options,
      selectedStagedOptions,
      optionStyles,
      itemRenderer,
      hasFilterWrapper,
      searchTerm,
      search,
      hideSearch,
    }: ListOfOptionsProps,
    ref
  ) => {
    return (
      <Box key="option-wrap" {...listStyles(hasFilterWrapper, hideSearch)} ref={ref}>
        {options.map((option) => {
          return (
            <ParentListItem
              key={`parent-${option.title}-${uuidv4()}`}
              title={option.title}
              listItem={
                <Box key={option.title} {...menuOptionGroupStyles}>
                  {option.title}
                </Box>
              }
            >
              {option.items
                ?.filter((item) => {
                  return (
                    item[searchTerm[0]]?.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (item[searchTerm[1]] &&
                      item[searchTerm[1]]?.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                  );
                })
                .map((item, index) => (
                  <Box
                    tabIndex="-1"
                    className="element"
                    key={item.id}
                    id={item.id}
                    onClick={(e) => onListItemClick(item, e)}
                    {...{
                      ...menuOptionStyles(setIsSelectedItem(item, selectedStagedOptions)),
                      ...optionStyles,
                    }}
                    mb={index === option.items.length - 1 && '16px'}
                  >
                    {itemRenderer({ item, parentItem: option, index })}
                  </Box>
                ))}
            </ParentListItem>
          );
        })}
      </Box>
    );
  }
);

ListOfOptions.defaultProps = {
  hideSearch: false,
};

export default ListOfOptions;
