import React, { ReactElement } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import BaselaneCardNew from '../../../BaselaneCard-new/BaselaneCardNew';

type BaselaneCardStackLayoutWithButtonProps = {
  title: String,
  description?: String,
  children: ReactElement,
};

const BaselaneCardStackLayoutWithButton = ({
  title,
  description,
  children,
}: BaselaneCardStackLayoutWithButtonProps) => {
  return (
    <BaselaneCardNew variant="inner" size="md" alignItems="center">
      <VStack alignItems="flex-start" justifyContent="center" gap={0.5}>
        <Text textStyle="headline-sm">{title}</Text>
        {description && <Text textStyle="sm">{description}</Text>}
      </VStack>
      {children}
    </BaselaneCardNew>
  );
};

BaselaneCardStackLayoutWithButton.defaultProps = {
  description: null,
};
export default BaselaneCardStackLayoutWithButton;
