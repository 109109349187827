export const formatAddress = (addressObj) => {
  const { address, city, unit, state, zipCode } = addressObj ?? {};
  const propertyUnit = unit ? `${unit},` : '';
  const propertyAddress = `${address}, ${propertyUnit} ${city}, ${state} ${zipCode}`;

  return propertyAddress;
};

// Toasters
export const showSuccessToast = (toast) =>
  toast({
    description: 'Saved changes',
    status: 'success',
    duration: '3000',
    isClosable: true,
    position: 'bottom-left',
  });

export const showErrorToast = (toast) =>
  toast({
    description: "Couldn't save changes. Try again",
    status: 'error',
    duration: '3000',
    isClosable: true,
    position: 'bottom-left',
  });
