import React from 'react';
import { Box } from '@chakra-ui/react';
import { Icon16ArrowBack, Icon16ArrowForward } from '@icons/16px';

import { BaselaneButtonIcon } from '../../BaselaneButtonIcon';

interface ArrowToggleButtonsProps {
  scrollPrev: Function;
  prevBtnDisabled: Boolean;
  scrollNext: Function;
  nextBtnDisabled: Boolean;
  isDarkMode: Boolean;
}

const ArrowToggleButtons = ({
  scrollPrev,
  prevBtnDisabled,
  scrollNext,
  nextBtnDisabled,
  isDarkMode,
}: ArrowToggleButtonsProps) => {
  return (
    <Box>
      <BaselaneButtonIcon
        variant="transparent"
        palette="neutral"
        icon={<Icon16ArrowBack />}
        onClick={scrollPrev}
        isDisabled={prevBtnDisabled}
        size="sm"
        styles={
          isDarkMode
            ? {
                color: 'white',
                _hover: {
                  color: 'brand.neutral.900',
                  bg: 'white',
                },
              }
            : {}
        }
      />
      <BaselaneButtonIcon
        variant="transparent"
        palette="neutral"
        icon={<Icon16ArrowForward />}
        onClick={scrollNext}
        isDisabled={nextBtnDisabled}
        size="sm"
        styles={
          isDarkMode
            ? {
                color: 'white',
                _hover: {
                  color: 'brand.neutral.900',
                  bg: 'white',
                },
              }
            : {}
        }
      />
    </Box>
  );
};

export default ArrowToggleButtons;
