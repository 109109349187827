import { CATEGORIZED_TRX_STATUS_COLOR } from '../helpers/categorizedTrxsBanner.helpers';

export const bannerContainerStyles = (isLoading) => {
  const loadingStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return {
    p: '20px 24px',
    bg: 'brand.neutral.white',
    borderRadius: 'md',
    border: '1px solid #DADFE6',
    boxShadow: 'none',
    minH: '80px',
    ...(isLoading && { ...loadingStyles }),
  };
};

export const spinnerStyles = {
  color: 'brand.darkBlue.150',
};

export const statusStyles = (status) => {
  return {
    color: CATEGORIZED_TRX_STATUS_COLOR[status],
  };
};

export const categorizedContainerStyles = {
  ml: '16px !important',
};

export const categorizedValueStyles = {
  color: 'brand.neutral.700',
  textStyle: 'sm',
  m: '0 !important',
};

export const uncategorizedContainerStyles = {
  m: '0 !important',
  alignItems: 'center',
};

export const uncategorizedValueStyles = {
  textStyle: 'sm',
};

export const sectionTitleStyles = {
  color: 'brand.neutral.500',
  textStyle: 'headline-xs',
};

export const boldedTextStyles = {
  textStyle: 'sm-heavy',
};

export const dividerStyles = {
  mx: '32px !important',
  p: '0',
};
