const COLOR_TEXT = 'brand.neutral.700';

export const accountNameStyles = {
  color: COLOR_TEXT,
  textStyle: 'xs',
};

export const amountStyles = {
  color: COLOR_TEXT,
  textStyle: 'headline-xs',
};

export const gridStyles = { py: '16px', px: '24px', alignItems: 'center', minH: '73px' };
