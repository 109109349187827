export const buttonCardStyles = (isBannerClickable, isMin899, isMin768) => {
  const defaultStyles = {
    borderRadius: 'md',
    p: isMin768 ? '24px' : '16px',
    minH: 'auto',
    h: 'auto',
    bg: 'brand.neutral.white',
    border: '1px solid #DADFE6',
    boxShadow: 'none',
    flex: isMin899 ? '1 0 calc(50% - 24px)' : '1 0 100%',
  };

  return isBannerClickable
    ? {
        ...defaultStyles,
        cursor: 'pointer',
        _hover: {
          borderColor: 'brand.blue.800A',
          bg: 'brand.blue.50',
        },
        _active: {
          bg: 'brand.blue.100',
        },
      }
    : {
        ...defaultStyles,
        cursor: 'default',
        _hover: {
          background: 'brand.neutral.white',
        },
        _active: {
          background: 'brand.neutral.white',
        },
      };
};

export const containerStyles = {
  w: '100%',
  h: '100%',
  alignItems: 'flex-start',
};

export const logoBoxStyles = (isMin768) => ({
  padding: '8px',
  minWidth: '100px',
  w: '100px',
  h: '80px',
  border: '1pt solid',
  borderColor: 'brand.darkBlue.200',
  borderRadius: '8px',
  mb: isMin768 ? 'auto' : '8px',
});

export const logoStyles = {
  display: 'block',
  width: 'auto',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '100%',
  margin: 'auto',
  position: 'relative',
  top: '50%',
  transform: 'translate(0, -50%)',
};

export const primaryContentHeadingStyles = {
  textStyle: 'headline-xl',
  fontWeight: 'semibold',
  color: 'brand.neutral.700',
  mb: '0px !important',
};

export const supportingContentTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  mb: '16px !important',
  w: '100%',
  boxSizing: 'border-box',
};

export const checkListStyles = {
  mt: '8px',
  lineHeight: '20px',
  color: 'brand.neutral.700',
};

export const checkListItemStyles = {
  display: 'flex',
  flexDir: 'row',
  alignItems: 'flex-start',
};

export const checkedListItemIconContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  h: '20px',
};

export const checkListItemTextContainerStyles = {
  pl: '8px',
  flex: '1',
};

const getBadgeButtonBackgroundHeight = (badgeSize) => {
  switch (badgeSize) {
    case 'lg':
      return { h: '100%' };
    case 'md':
      return { h: '32px' };
    default:
      return { h: '100%' };
  }
};

export const badgeButtonStyles = (badgeSize = 'lg') => {
  return {
    backgroundStyles: {
      whiteSpace: 'nowrap',
      display: 'inline-block',
      p: '0',
      order: { base: '1', md: '2' },
      ...getBadgeButtonBackgroundHeight(badgeSize),
    },
    fontStyles: {
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: 'semibold',
      lineHeight: '24px',
      padding: '12px 20px',
    },
  };
};

export const badgeButtonIconStyles = {
  display: 'inline-block',
  ml: '12px',
  height: '13px',
};
