import React from 'react';
import { FormHelperText, Box } from '@chakra-ui/react';
import { Icon12Error, Icon12Warning, Icon12Check, Icon12QuestionMark } from '@icons/12px';

import { textStyles, iconStyles, baseStyles } from './styles/helperText.styles';

function BaselaneFormHelperText({
  children,
  type,
  customIcon,
  customIconStyles,
  customStyles,
}: {
  children: any,
  type: 'assistive' | 'warning' | 'success' | 'error',
  customIcon: any,
  customIconStyles: Object,
  customStyles: Object,
}) {
  const icons = {
    error: <Icon12Error />,
    warning: <Icon12Warning />,
    success: <Icon12Check />,
    assistive: <Icon12QuestionMark />,
  };

  const icon = customIcon || icons[type] || null;

  return (
    <FormHelperText {...baseStyles(type)} {...customStyles}>
      {icon && (
        <Box as="span" {...iconStyles} {...customIconStyles}>
          {icon}
        </Box>
      )}
      <Box as="span" {...textStyles}>
        {children}
      </Box>
    </FormHelperText>
  );
}

export default BaselaneFormHelperText;
