import React, { useState } from 'react';
import { Box, ChakraProvider, Heading, Input, Stack } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import {
  BaselaneAlert,
  BaselaneButton,
  BaselaneButtonGroup,
  BaselaneRadio,
  BaselaneRadioGroup,
} from '@shared/components/';
import getBreakPoints from '@core/utils/getBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import habitatTheme from '@core/themeHabitat';
import { IconQuestion } from '@icons';
import { formInputStyles } from '@shared/styles/input.style';

import {
  headerStyles,
  bodyStyles,
  footerStyles,
  containerStyles,
} from './styles/finishLaterPopup.styles';

type FinishLaterPopupProps = {
  headerIcon: React.FunctionComponent,
  header: React.FunctionComponent,
  survey: React.FunctionComponent,
  footer: React.FunctionComponent,
  isOpen: Boolean,
  setIsOpen: Boolean,
  handleFinishLater: Function,
};

const FinishLaterPopup = ({
  headerIcon,
  header,
  survey = null,
  footer,
  isOpen,
  setIsOpen,
  handleFinishLater,
}: FinishLaterPopupProps) => {
  const { isMinXL } = getBreakPoints();
  const [surveyValue, setSurveyValue] = useState(null);
  const [otherAnswer, setOtherAnswer] = useState(null);

  const handleContinue = () => {
    if (surveyValue !== 'Other') {
      sendSegmentEvent('onboarding_rc_exit_survey', {
        reason: surveyValue,
      });
    } else {
      sendSegmentEvent('onboarding_rc_exit_survey', {
        reason: 'Other',
        otherReason: otherAnswer,
      });
    }
    handleFinishLater();
  };

  const handleSkip = () => {
    sendSegmentEvent('onboarding_rc_exit_survey', {
      reason: 'Skipped',
    });
    handleFinishLater();
  };

  const surveyConfig = survey || {
    question: 'Why did you choose to finish this later?',
    answers: [
      "I don't have the lease terms available",
      "I don't have the tenant details available",
      'I could not connect my bank account',
      'I require additional flexibility on payment / roommate terms',
      'I want to review the rest of the product first',
      'Other',
    ],
  };

  const defaultHeaderIcon = (
    <Box marginLeft="4px">
      <IconQuestion />
    </Box>
  );

  const defaultHeader = (
    <Heading color="brand.neutral.900" size="headline-lg" marginBottom="8px">
      A quick question
    </Heading>
  );

  const defaultFooter = (
    <Stack p="12px">
      <BaselaneButtonGroup size="md">
        <BaselaneButton variant="outline" palette="neutral" size="md" onClick={handleSkip}>
          Skip
        </BaselaneButton>
        <BaselaneButton
          variant="filled"
          palette="primary"
          size="md"
          isDisabled={!surveyValue || (surveyValue === 'Other' && !otherAnswer)}
          onClick={handleContinue}
        >
          Continue
        </BaselaneButton>
      </BaselaneButtonGroup>
    </Stack>
  );

  const body = (
    <Stack p="16px 16px 8px">
      {header || defaultHeader}
      <Box>
        <BaselaneRadioGroup
          name="finishLaterSurvey"
          onChange={setSurveyValue}
          title={surveyConfig?.question || ''}
          defaultValue={null}
        >
          {surveyConfig?.answers?.map((question) => (
            <>
              <BaselaneRadio
                defaultChecked={false}
                key={uuidv4()}
                value={question}
                label={question}
              />
              {question === 'Other' && surveyValue === 'Other' && (
                <ChakraProvider theme={habitatTheme}>
                  <Input
                    {...formInputStyles}
                    width="auto"
                    marginLeft="24px"
                    id="finish-later-reason-other-answer"
                    name="finish-later-reason-other-answer"
                    value={otherAnswer}
                    onChange={(e) => setOtherAnswer(e.target.value)}
                    placeholder="Please specify"
                    maxLength="2000"
                  />
                </ChakraProvider>
              )}
            </>
          ))}
        </BaselaneRadioGroup>
      </Box>
    </Stack>
  );

  return (
    <BaselaneAlert
      {...{
        isOpen,
        onClose: () => setIsOpen(false),
        closeOnOverlayClick: true,
        isCentered: true,
        showCloseButton: true,
        size: isMinXL ? 'sm' : 'lg',
        header: headerIcon || defaultHeaderIcon,
        headerStyles,
        body,
        bodyStyles: bodyStyles(isMinXL),
        footer: footer || defaultFooter,
        footerStyles: footerStyles(isMinXL),
        containerStyles: containerStyles(isMinXL),
      }}
    />
  );
};

export default FinishLaterPopup;
