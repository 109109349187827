import React from 'react';

const IconLockSimple = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <rect x="2" y="5.5" width="8" height="5" rx="0.5" stroke="#56C719" strokeWidth="1.5" />
      <path
        d="M3.75 5.5V3.72222C3.75 3.13285 3.98705 2.56762 4.40901 2.15087C4.83097 1.73413 5.40326 1.5 6 1.5C6.59674 1.5 7.16903 1.73413 7.59099 2.15087C8.01295 2.56762 8.25 3.13285 8.25 3.72222V5.5"
        stroke="#56C719"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconLockSimple;
