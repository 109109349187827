export const containerStyles = (local) => ({
  position: 'fixed',
  right: '0px',
  maxWidth: '100%',
  minHeight: '80px',
  bottom: '0',
  backgroundColor: 'brand.neutral.white',
  p: local ? '24px' : '24px 180px 24px 112px',
  boxSizing: 'border-box',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  left: local ? '0' : { '3xl': '248px', xs: '0' },
});
