import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { linkThemeGenerator } from './linkStyleHelpers';

/**
 * This overrides Chakra's default <Link /> styles.
 *
 * see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/link.ts for implementation details
 */

export const Link = defineStyleConfig({
  baseStyle: {
    outline: '1px dashed transparent',
    outlineOffset: '1px',
    _hover: { textDecoration: 'none' },
  },
  sizes: {
    sm: defineStyle((props) => {
      const { textStyles, radii } = props.theme;
      return {
        ...textStyles['button-xs'],
        borderRadius: radii.sm,
      };
    }),
    md: defineStyle((props) => {
      const { textStyles, radii } = props.theme;
      return {
        ...textStyles['button-sm'],
        borderRadius: radii.sm,
      };
    }),
    lg: defineStyle((props) => {
      const { textStyles, radii } = props.theme;
      return {
        ...textStyles['button-md'],
        borderRadius: radii.sm,
      };
    }),
  },
  variants: {
    primary: {
      ...linkThemeGenerator('primary'),
    },
    danger: {
      ...linkThemeGenerator('danger'),
    },
    success: {
      ...linkThemeGenerator('success'),
    },
    warning: {
      ...linkThemeGenerator('warning'),
    },
  },
});
