import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from '@core/Services/Firebase/firebase';

const useReferralSource = () => {
  const [referrer, setReferrer] = useState(null);
  const [isReferral, setIsReferral] = useState(false);
  const currLocation = useLocation();

  useEffect(() => {
    if (getQueryParams(currLocation, 'utm_source')?.includes('referral')) {
      // if we are referred, url has query params like these
      // utm_source=referral&utm_campaign=referral2023&utm_id={userId}&firstName={referringLLname}&lastName={referringLLname}
      const firstName = getQueryParams(currLocation, 'firstName');
      const lastName = getQueryParams(currLocation, 'lastName');
      const queryParamReferrerFullName = `${firstName === 'null' || !firstName ? '' : firstName}${
        lastName === 'null' || !lastName ? '' : ` ${lastName}`
      }`;
      setReferrer(queryParamReferrerFullName);
      setIsReferral(!!(getQueryParams(currLocation, 'utm_source') && queryParamReferrerFullName));
    }
  }, []);

  return { referrer, isReferral };
};

export default useReferralSource;
