import { useQuery } from '@apollo/client';
import { GET_BANKS } from '@core/apollo/queries';

const useBanks = (options) => {
  const { variables, fetchPolicy = 'network-only', nextFetchPolicy = 'cache-first' } =
    options ?? {};

  const queryOptions = variables
    ? {
        fetchPolicy,
        nextFetchPolicy,
        variables,
      }
    : {
        fetchPolicy,
        nextFetchPolicy,
      };

  const {
    loading: accountsLoading,
    error: accountsError,
    data: accountsData,
    refetch: refetchAccountsData,
  } = useQuery(GET_BANKS, queryOptions);

  return {
    accountsLoading,
    accountsError,
    accountsData,
    refetchAccountsData,
  };
};

export default useBanks;
