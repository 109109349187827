import { isMobile } from 'react-device-detect';

export const buttonStyles = {
  p: '16px',
  height: isMobile ? '112px' : '72px',
  minHeight: '72px',
};

export const buttonInnerContainerStyles = {
  spacing: isMobile ? '20px' : '16px',
  align: 'center',
  height: '100%',
};

export const titleTextStyles = {
  textStyle: 'headline-xs',
  lineHeight: '20px',
};

export const bodyContainerStyles = {
  spacing: isMobile ? 0 : '0',
};

export const bodyTextStyles = {
  textStyle: 'xs',
  lineHeight: '20px',
  color: 'brand.neutral.600',
};
