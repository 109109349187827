export const cashflowTaxPackageStyles = {
  container: {
    p: '40px 12px',
    color: 'brand.neutral.700',
    maxWidth: '604px',
  },
  title: {
    textStyle: 'headline-lg',
  },
  description: {
    textStyle: 'sm',
    mt: '4px !important',
  },
  taxpackage: {
    container: {
      p: '24px 16px',
      background: 'brand.darkBlue.50',
      borderWidth: '1px',
      borderColor: 'brand.darkBlue.150',
      borderRadius: '4px',
      mt: '24px !important',
    },
    title: {
      textStyle: 'headline-sm',
      mb: '24px',
    },
    groupcontainer: {
      m: '0 !important',
      gap: '8px',
    },
    filterrow: {
      m: '0 !important',
    },
    countrow: {
      m: '0 !important',
      h: '32px',
    },
    loader: {
      size: 'xs',
      color: 'brand.darkBlue.250',
    },
    label: {
      textStyle: 'sm',
      color: 'brand.neutral.600',
    },
    count: {
      textStyle: 'headline-sm',
    },
    divider: {
      m: '24px 0 16px 0 !important',
    },
    downloadbutton: {
      mt: '8px !important',
    },
    badge: {
      bg: {
        p: '6px 8px',
        h: 'unset !important',
        ml: '16px !important',
      },
      text: {
        textTransform: 'capitalize',
        textStyle: 'headline-xs',
      },
    },
  },
  error: {
    textStyle: 'xs',
    color: 'red.800AA',
    mt: '8px !important',
  },
};
