const getAnimation = ({ slidein, slideout, animationStyles, isMinXL }) => {
  let animation = {};

  const {
    slideInAnimation,
    slideOutAnimation,
    slideInAnimationMobile,
    slideOutAnimationMobile,
    desktopStyles,
    mobileStyles,
  } = animationStyles;

  if (isMinXL) {
    if (slidein) {
      animation = { animation: `${slideInAnimationMobile} 300ms`, ...mobileStyles };
    }
    if (slideout) {
      animation = {
        animation: `${slideOutAnimationMobile} 300ms`,
        bottom: '-100%',
      };
    }
  } else {
    if (slidein) {
      animation = { animation: `${slideInAnimation} 300ms`, ...desktopStyles };
    }
    if (slideout) {
      animation = { animation: `${slideOutAnimation} 300ms`, top: '100%' };
    }
  }

  return animation;
};

export const filterCardContainerStyles = {
  display: 'flex',
  flexDir: 'column',
  m: '0 !important',
  position: 'absolute',
  left: 0,
  right: 0,
};

export const overlayStyles = (isInDrawer) => {
  return {
    position: 'fixed',
    w: '100vw',
    h: '100vh',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    m: '0 !important',
    zIndex: isInDrawer ? 1500 : 1399,
    background: 'rgba(0, 0, 0, 0.48)',
  };
};

export const popupStyles = ({ isMinXL, showFilterPopup }) => {
  return {
    container: ({ slidein, slideout, animationStyles }) => {
      return {
        display: 'flex',
        flexDir: 'column',
        zIndex: 9999,
        position: 'fixed',
        m: '0 !important',
        bg: showFilterPopup ? 'brand.neutral.white' : 'transparent',
        borderRadius: isMinXL ? '12px 12px 0 0' : '12px',
        maxHeight: isMinXL ? 'calc(100% - 16px)' : 'calc(100% - 216px)',
        opacity: !slidein && !slideout && 0,
        top: !slidein && !slideout && '100%',
        left: 0,
        right: 0,
        ...getAnimation({ slidein, slideout, animationStyles, isMinXL }),
      };
    },
    // scrollable section above footer
    innercontainer: {
      gap: '16px',
      p: '16px 16px 58px 16px',
      overflowY: 'auto',
      flex: 1,
      maxHeight: 'calc(100% - 56px)',
      css: {
        '&::-webkit-scrollbar': {
          width: '16px',
        },
        '&::-webkit-scrollbar-track': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#E5E9EF',
          backgroundClip: 'padding-box',
          border: '4px solid transparent',
          borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#C8D0DA',
          backgroundClip: 'padding-box',
          border: '4px solid transparent',
          borderRadius: '8px',
        },
      },
    },
    header: {
      container: {
        p: '8px 0',
      },
      text: {
        textStyle: 'headline-xs',
        color: 'brand.neutral.500',
        textTransform: 'uppercase',
      },
    },
    footer: {
      container: {
        p: '8px 16px',
        boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
        justifyContent: 'flex-end',
      },
    },
  };
};
