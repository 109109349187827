export const titleStyles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
  mb: '4px',
};

export const descriptionStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.700',
};
