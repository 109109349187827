/* eslint-disable react/prop-types */
import React from 'react';
import { Text } from '@chakra-ui/react';

const ScheduledTransferDetailsDescription = ({ recurrencePattern }) => {
  const isMonthly = recurrencePattern.type === 'MONTHLY';

  // A. For monthly recurring payments.
  if (isMonthly) {
    const monthDate = recurrencePattern.monthlyByDate;

    // Calculating the label for the month date.
    let monthDateLabel = '';

    if (recurrencePattern.lastDayOfMonth) {
      monthDateLabel = 'last day';
    } else if (monthDate === 1) {
      monthDateLabel = '1st';
    } else if (monthDate === 2) {
      monthDateLabel = '2nd';
    } else if (monthDate === 3) {
      monthDateLabel = '3rd';
    } else {
      monthDateLabel = `${monthDate}th`;
    }

    // Calculating the label for the frequency of repetition.
    const { interval } = recurrencePattern;
    const frequencyOfRepetitionLabel =
      interval > 1 ? `Every ${interval} months on the` : 'Monthly on the';

    return (
      <Text color="brand.neutral.600" textStyle="xs">
        {`${frequencyOfRepetitionLabel} ${monthDateLabel}`}
      </Text>
    );
  }

  // B. For weekly recurring payments.
  const weekday =
    recurrencePattern.daysOfWeek.charAt(0) + recurrencePattern.daysOfWeek.slice(1).toLowerCase();

  // Calculating the label for the frequency of repetition.
  const { interval } = recurrencePattern;
  const frequencyOfRepetitionLabel = interval > 1 ? `Every ${interval} weeks on` : 'Weekly on';

  return (
    <Text color="brand.neutral.600" textStyle="xs">
      {`${frequencyOfRepetitionLabel} ${weekday}`}
    </Text>
  );
};

export default ScheduledTransferDetailsDescription;
