// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import BaselaneCreditCard from '@shared/components/BaselaneCreditCard';

type CreditCardProps = {
  fullName: String,
  accountNumber: String,
  value: Number,
  inTransfer?: Number,
  hasWallet?: boolean,
  walletTitle?: String,
  walletSubtitle?: String,
  isGrayedOut?: boolean,
  creditCardStyles?: any,
};

function CreditCard({
  fullName,
  accountNumber,
  value,
  inTransfer,
  hasWallet,
  walletTitle,
  walletSubtitle,
  isGrayedOut,
  creditCardStyles,
}: CreditCardProps): any {
  return (
    <Box {...creditCardStyles}>
      <BaselaneCreditCard
        fullName={fullName}
        accountNumber={accountNumber}
        value={value}
        inTransfer={inTransfer}
        hasWallet={hasWallet}
        walletTitle={walletTitle}
        walletSubtitle={walletSubtitle}
        isGrayedOut={isGrayedOut}
        creditCardStyles={creditCardStyles}
      />
    </Box>
  );
}

CreditCard.defaultProps = {
  inTransfer: 0,
  hasWallet: false,
  walletTitle: null,
  walletSubtitle: null,
  isGrayedOut: false,
  creditCardStyles: null,
};

export default CreditCard;
