import React from 'react';

function IconCheckDepositMobileDevice() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="32.1484" y="20.5664" width="11.8519" height="21.7284" rx="2" fill="#8CCFFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6303 25.9973H35.5903V26.8627H34.4958V27.9573H33.6303V25.9973Z"
        fill="#192C3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5686 30.9355H36.6086V30.07H37.7031V28.9755H38.5686V30.9355Z"
        fill="#192C3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5686 25.9973V27.9573H37.7031V26.8627H36.6086V25.9973H38.5686Z"
        fill="#192C3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6303 30.9355V28.9755H34.4958L34.4958 30.07L35.5903 30.07V30.9355H33.6303Z"
        fill="#192C3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6797 21.059C29.6797 19.6953 30.7852 18.5898 32.1488 18.5898H40.0501C41.4137 18.5898 42.5192 19.6953 42.5192 21.059V38.8368C42.5192 40.2004 41.4137 41.3059 40.0501 41.3059H32.1488C30.7852 41.3059 29.6797 40.2004 29.6797 38.8368V21.059ZM32.1488 19.5775C31.3306 19.5775 30.6673 20.2408 30.6673 21.059V38.8368C30.6673 39.655 31.3306 40.3182 32.1488 40.3182H40.0501C40.8683 40.3182 41.5315 39.655 41.5315 38.8368V21.059C41.5315 20.2408 40.8683 19.5775 40.0501 19.5775H32.1488Z"
        fill="#192C3E"
      />
      <path
        d="M33.6303 19.5775H38.5686C38.5686 20.2321 38.038 20.7627 37.3834 20.7627H34.8155C34.1609 20.7627 33.6303 20.2321 33.6303 19.5775Z"
        fill="#192C3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2165 12.0269C19.2165 11.7542 19.4376 11.5331 19.7104 11.5331H34.0042C34.2769 11.5331 34.498 11.7542 34.498 12.0269C34.498 12.2997 34.2769 12.5208 34.0042 12.5208H19.7104C19.4376 12.5208 19.2165 12.2997 19.2165 12.0269Z"
        fill="#192C3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2165 14.7934C19.2165 14.5207 19.4376 14.2996 19.7104 14.2996H30.3154C30.5882 14.2996 30.8093 14.5207 30.8093 14.7934C30.8093 15.0662 30.5882 15.2873 30.3154 15.2873H19.7104C19.4376 15.2873 19.2165 15.0662 19.2165 14.7934Z"
        fill="#192C3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2165 17.56C19.2165 17.2873 19.4376 17.0662 19.7104 17.0662H27.5489C27.8216 17.0662 28.0427 17.2873 28.0427 17.56C28.0427 17.8328 27.8216 18.0539 27.5489 18.0539H19.7104C19.4376 18.0539 19.2165 17.8328 19.2165 17.56Z"
        fill="#192C3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3783 10.1498C12.651 10.1498 12.8721 10.3709 12.8721 10.6436V11.6022H13.7769C15.4908 11.6022 17.068 12.7416 17.068 14.3554C17.068 14.6281 16.8469 14.8492 16.5742 14.8492C16.3015 14.8492 16.0804 14.6281 16.0804 14.3554C16.0804 13.4736 15.1528 12.5899 13.7769 12.5899H11.1794C9.69321 12.5899 8.67619 13.5459 8.67619 14.5168C8.67619 15.4877 9.69321 16.4437 11.1794 16.4437H13.5771C15.4014 16.4437 17.068 17.6553 17.068 19.3582C17.068 21.0612 15.4014 22.2728 13.5771 22.2728H12.8721V23.2314C12.8721 23.5041 12.651 23.7252 12.3783 23.7252C12.1055 23.7252 11.8845 23.5041 11.8845 23.2314V22.2728H10.9796C9.26575 22.2728 7.68854 21.1334 7.68854 19.5196C7.68854 19.2469 7.90963 19.0258 8.18236 19.0258C8.4551 19.0258 8.67619 19.2469 8.67619 19.5196C8.67619 20.4014 9.60375 21.2851 10.9796 21.2851H13.5771C15.0634 21.2851 16.0804 20.3291 16.0804 19.3582C16.0804 18.3873 15.0634 17.4313 13.5771 17.4313H11.1794C9.3552 17.4313 7.68854 16.2197 7.68854 14.5168C7.68854 12.8139 9.3552 11.6022 11.1794 11.6022H11.8845V10.6436C11.8845 10.3709 12.1055 10.1498 12.3783 10.1498Z"
        fill="#192C3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 10.4444C4 7.98985 5.98984 6 8.44444 6H34.4333C36.8879 6 38.8778 7.98985 38.8778 10.4444V16.6735C38.8778 16.9462 38.6567 17.1673 38.3839 17.1673C38.1112 17.1673 37.8901 16.9462 37.8901 16.6735V10.4444C37.8901 8.53531 36.3424 6.98765 34.4333 6.98765H8.44444C6.53531 6.98765 4.98765 8.53532 4.98765 10.4444V23.8456C4.98765 25.7547 6.53531 27.3023 8.44444 27.3023H27.7037C27.9764 27.3023 28.1975 27.5234 28.1975 27.7962C28.1975 28.0689 27.9764 28.29 27.7037 28.29H8.44444C5.98984 28.29 4 26.3002 4 23.8456V10.4444Z"
        fill="#192C3E"
      />
    </svg>
  );
}

export default IconCheckDepositMobileDevice;
