import React, { forwardRef } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
} from '@chakra-ui/react';
import { IconCarretDown, IconCarretUp } from '@icons';
import CheckboxListItem from '../../CheckboxListItem';

import { listStyles } from '../../styles/dropdown.styles';
import {
  getChildCounterVisualBgColor,
  setIsSelectedItem,
} from '../../helpers/dropdown.shared.helpers';
import {
  accordionButtonStyles,
  accordionChildCheckboxContainerStyles,
  accordionPanelStyles,
  accordionSelectAllItemContainerStyles,
  accordionSelectAllTextStyles,
  accordionToggleContainerStyles,
  accordionToggleCounterContainerStyles,
  accordionToggleIconContainerStyles,
  accordionToggleTextStyles,
} from '../../styles/accordion.styles';

type ListOfCategoryAndSubcategoryOptionsProps = {
  options: Array<any>,
  selectedStagedOptions: Array<any>,
  parentItemRenderer: Function,
  childItemRenderer: Function,
  hasFilterWrapper: Boolean,
  search: String,
  hideSearch?: Boolean,
  handleListItemClick: Function,
  handleAccordionClick: Function,
  handleSelectAll: Function,
  childrenSelectAllState: Object,
};

const ListOfParentAndChildrenCheckboxes = forwardRef(
  (
    {
      options,
      selectedStagedOptions,
      parentItemRenderer,
      childItemRenderer,
      hasFilterWrapper,
      search,
      hideSearch,
      handleListItemClick,
      handleSelectAll,
      childrenSelectAllState,
    }: ListOfCategoryAndSubcategoryOptionsProps,
    ref
  ) => {
    return (
      <Box key="option-wrap" {...listStyles(hasFilterWrapper, hideSearch)} ref={ref}>
        {options
          ?.filter((item) => {
            const isParent = Array.isArray(item.searchValues);
            return isParent
              ? item.searchValues?.some(
                  (value) => value.toLowerCase().indexOf(search.toLowerCase()) !== -1
                )
              : item.searchValues.toLowerCase().indexOf(search.toLowerCase()) !== -1;
          })
          .reduce((acc, item, index, allItems) => {
            const { id, isChild, hasChildren } = item;
            const parentChildren = hasChildren
              ? allItems.filter((cItem) => cItem.id.includes(id) && cItem.isChild)
              : [];

            if (isChild) {
              return acc;
            }

            acc.push(
              hasChildren ? (
                <Accordion
                  key={item.id}
                  allowToggle
                  allowMultiple
                  {...(search ? { index: [0] } : {})}
                >
                  <AccordionItem border="none">
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton {...accordionButtonStyles}>
                          <Box
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            w="100%"
                          >
                            <CheckboxListItem
                              tabIndex="-1"
                              className="element"
                              id={item.id}
                              isChecked={setIsSelectedItem(item, selectedStagedOptions)}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleSelectAll(
                                  parentChildren,
                                  item,
                                  true, // isParentOnChange
                                  isExpanded,
                                  !setIsSelectedItem(item, selectedStagedOptions)
                                );
                              }}
                            >
                              {parentItemRenderer({ item, search })}
                            </CheckboxListItem>
                          </Box>

                          <HStack {...accordionToggleContainerStyles(search)}>
                            <HStack
                              bgColor={getChildCounterVisualBgColor(
                                childrenSelectAllState?.[item.id],
                                isExpanded
                              )}
                              {...accordionToggleCounterContainerStyles}
                            >
                              {childrenSelectAllState?.[item.id]?.length > 0 && (
                                <Text {...accordionToggleTextStyles}>
                                  {childrenSelectAllState[item.id].length}
                                </Text>
                              )}
                              <HStack {...accordionToggleIconContainerStyles}>
                                {isExpanded ? (
                                  <IconCarretUp
                                    width="8"
                                    height="5"
                                    color={search ? '#E5E9EF' : '#3A4B5B'}
                                  />
                                ) : (
                                  <IconCarretDown
                                    width="8"
                                    height="5"
                                    color={search ? '#E5E9EF' : '#3A4B5B'}
                                  />
                                )}
                              </HStack>
                            </HStack>
                          </HStack>
                        </AccordionButton>
                        <AccordionPanel {...accordionPanelStyles}>
                          {!search && (
                            <HStack {...accordionSelectAllItemContainerStyles}>
                              <CheckboxListItem
                                tabIndex="-1"
                                className="element"
                                id={item.id}
                                isChecked={
                                  childrenSelectAllState?.[item.id]?.length ===
                                  parentChildren.length
                                }
                                isIndeterminate={
                                  childrenSelectAllState?.[item.id]?.length > 0 &&
                                  childrenSelectAllState?.[item.id]?.length !==
                                    parentChildren.length
                                }
                                onChange={() => {
                                  handleSelectAll(parentChildren, item);
                                }}
                              >
                                <Text {...accordionSelectAllTextStyles}>SELECT ALL</Text>
                              </CheckboxListItem>
                            </HStack>
                          )}
                          {parentChildren.map((pChild) => (
                            <HStack key={pChild.id} {...accordionChildCheckboxContainerStyles}>
                              <CheckboxListItem
                                tabIndex="-1"
                                className="element"
                                id={pChild.id}
                                isChecked={setIsSelectedItem(pChild, selectedStagedOptions)}
                                isIndeterminate={false}
                                onChange={(e) => {
                                  handleListItemClick(pChild, e);
                                }}
                              >
                                {childItemRenderer({ item: pChild, search })}
                              </CheckboxListItem>
                            </HStack>
                          ))}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
              ) : (
                <HStack key={item.id} align="center">
                  <CheckboxListItem
                    tabIndex="-1"
                    className="element"
                    id={item.id}
                    isChecked={setIsSelectedItem(item, selectedStagedOptions)}
                    isIndeterminate={false}
                    onChange={(e) => {
                      handleListItemClick(item, e);
                    }}
                  >
                    {parentItemRenderer({ item, search })}
                  </CheckboxListItem>
                </HStack>
              )
            );

            return acc;
          }, [])}
      </Box>
    );
  }
);

ListOfParentAndChildrenCheckboxes.defaultProps = {
  hideSearch: false,
};

export default ListOfParentAndChildrenCheckboxes;
