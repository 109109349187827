import React from 'react';

type IconArrowLeftTwoProps = {
  w: number,
  h: number,
  color: string,
};

const IconArrowLeftTwo = ({ w = 18, h = 14, color = '#6C7884' }: IconArrowLeftTwoProps) => {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91998.577574c.32544.325437.32544.853076 0 1.178516L3.50924 6.16683H16.4974c.4602 0 .8333.3731.8333.83333 0 .46024-.3731.83334-.8333.83334H3.50924l4.41074 4.4107c.32544.3255.32544.8531 0 1.1786-.32543.3254-.85307.3254-1.17851 0L.90814 7.58942c-.325437-.32544-.325437-.85308 0-1.17851L6.74147.577574c.32544-.325437.85308-.325437 1.17851 0Z"
        fill={color}
      />
    </svg>
  );
};

export default IconArrowLeftTwo;
