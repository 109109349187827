import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getQueryParams } from '@core/Services/Firebase/firebase';
import inviteCodeStorage from '@core/storage/inviteCodeStorage';

const EmailInviteCodeLogic = () => {
  const location = useLocation();

  const inviteCode = getQueryParams(location, 'inviteCode');
  if (inviteCode !== null) {
    inviteCodeStorage.write(inviteCode);
  } else {
    inviteCodeStorage.write('');
  }

  let redirectPath = null;

  if (location.search.includes('mode=verifyEmail')) {
    const oob = getQueryParams(location, 'oobCode');
    redirectPath = `/verified?oobCode=${oob}`;
  } else if (location.search.includes('mode=resetPassword')) {
    const oob = getQueryParams(location, 'oobCode');
    const continueUrl = getQueryParams(location, 'continueUrl');
    redirectPath = `/reset_password?oobCode=${oob}&continueUrl=${continueUrl}`;
  }

  return redirectPath ? <Navigate to={redirectPath} /> : <Outlet />;
};

export default EmailInviteCodeLogic;
