import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import BaselaneResponsiveTableRow from './BaselaneResponsiveTableRow';

type BaselaneResponsiveTableRowsProps = {
  /**
   * @prop {Boolean} - Enables the use of a custom row, so that ineractivity can be customized.
   * Otherwise the default row below is used.
   * @default false
   */
  customRow?: Boolean,
  /**
   * @prop {Object} - Custom row styles to apply if using the default table row..
   * @default null
   */
  rowStyles?: Object,
  /**
   * @prop {Object} - Collection of data that is used for each row of the table.
   */
  items: Object,
  /**
   * @prop {Function} - Render function that defines how one item from the data is applied to the contents of a row.
   */
  renderItem: Function,
};

/**
 * Renders a set of BaselaneResponsiveTableRows, based on supplied items and the renderItem.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveTableRows = ({
  customRow,
  rowStyles,
  items,
  renderItem,
}: BaselaneResponsiveTableRowsProps) => {
  return items?.map((item: Array, index) => {
    return customRow ? (
      <React.Fragment key={item?.id || uuidv4()}>{renderItem(item, index)}</React.Fragment>
    ) : (
      <BaselaneResponsiveTableRow
        key={item?.id || uuidv4()}
        id={`table-row-${index}`}
        contentContainerStyles={rowStyles}
      >
        {renderItem({ ...item, index })}
      </BaselaneResponsiveTableRow>
    );
  });
};

BaselaneResponsiveTableRows.defaultProps = {
  customRow: false,
  rowStyles: {},
};

export default BaselaneResponsiveTableRows;
