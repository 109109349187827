import React from 'react';

function IconWarningTriangleYellow() {
  return (
    <svg width="34" height="32" viewBox="0 0 34 32" fill="none">
      <g id="illustration">
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.327 4.22153C15.9907 1.25949 20.0093 1.25949 21.673 4.22153L33.383 25.0701C35.096 28.1199 33.0394 32 29.71 32H6.29001C2.96055 32 0.904036 28.1199 2.61703 25.0701L14.327 4.22153Z"
          fill="#FCE3A3"
        />
        <path
          id="Shape_2"
          d="M12.763 2.46639C14.2356 -0.155461 17.7644 -0.155462 19.237 2.46639L30.947 23.315C32.5004 26.0806 30.6023 29.5 27.71 29.5H4.29001C1.39775 29.5 -0.500386 26.0806 1.05297 23.315L12.763 2.46639Z"
          stroke="#000619"
        />
        <path
          id="Icon"
          d="M16 10V16.4M16 22.8H16.016"
          stroke="#000619"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default IconWarningTriangleYellow;
