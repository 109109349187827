const breakpoints = {
  xxs: '240px',
  xs: '360px',
  '2xs': '375px',
  sm: '576px',
  md: '768px',
  lg: '899px',
  '2lg': '1150px',
  xl: '1280px',
  '2xl': '1440px',
  '3xl': '1537px',
};

export default breakpoints;
