import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { IconDocumentCheck, IconInfo, IconArrowRight } from '@icons';
import { BaselaneTooltip, BaselaneAdvertisingBanner, BaselaneBadge } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import customTheme from '@core/theme';
import {
  badgeButtonIconStyles,
  badgeButtonStyles,
  badgeTagStyles,
  providerImageStyles,
} from '@shared/components/BaselaneAdvertisingBanner/styles/insuranceBanner.styles';
import { primaryContentHeadingStyles } from '@shared/components/BaselaneAdvertisingBanner/styles/advertisingBanner.style';

import TransUnionAndSmartMove from '../assets/transunion.png';
import TransUnionAndSmartMove2x from '../assets/transunion@2x.png';

const TenantScreeningBanner = ({ ...rest }) => {
  const getTopRightContent = () => {
    return (
      <BaselaneBadge
        {...{
          color: 'blue',
          type: 'badge',
          ...badgeTagStyles,
        }}
      >
        Baselane Feature
      </BaselaneBadge>
    );
  };

  const getBottomRightContent = () => {
    return (
      <>
        <BaselaneBadge
          {...{
            color: 'blue',
            type: 'badge',
            ...badgeButtonStyles(false),
          }}
        >
          Start Screening
          <Box as="span" {...badgeButtonIconStyles}>
            <IconArrowRight color={customTheme.colors.brand.blue['700']} />
          </Box>
        </BaselaneBadge>
        <Image
          {...providerImageStyles(true)}
          src={TransUnionAndSmartMove}
          srcSet={`${TransUnionAndSmartMove2x} 2x`}
          alt="TransUnionAndSmartMove"
        />
      </>
    );
  };

  const getPrimaryContent = () => {
    return (
      <Box {...primaryContentHeadingStyles(true)}>
        Free Tenant Screening powered by TransUnion
        <BaselaneTooltip
          label="Tenant screening services are free if you request your tenant applicants to pay the fee.   Alternatively, you have the option of paying for the screening yourself. All payments are made directly through SmartMove by TransUnion. Screening services start at $25."
          placement="auto"
          innerContainerStyles={{ display: 'inline-block', ml: '8px!important' }}
        >
          <IconInfo />
        </BaselaneTooltip>
      </Box>
    );
  };

  const handleOnBannerClick = () => {
    sendSegmentEvent('get_started_click_tenant_screening');
    window.open(
      'https://smartmove.pxf.io/c/4046799/1503138/17713?subId1=In-Product&partnerpropertyid=4075990'
    );
  };

  return (
    <BaselaneAdvertisingBanner
      {...{
        icon: <IconDocumentCheck />,
        primaryContent: getPrimaryContent(),
        supportingContent: 'Full credit reports, rental history, and tenant background checks',
        topRightContent: getTopRightContent(),
        bottomRightContent: getBottomRightContent(),
        onClick: handleOnBannerClick,
        isNarrowOnly: true,
        isBannerClickable: true,

        // styles to ensure there is no double spacing in mobile between banners
        mb: '24px !important',
        mt: '24px !important',
        ...rest,
      }}
    />
  );
};

export default TenantScreeningBanner;
