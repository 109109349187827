// @flow
import React, { forwardRef } from 'react';
import { IconButton } from '@chakra-ui/react';

import {
  buttonThemeGenerator,
  getIconSpacingValue,
} from '@core/theme/component/Button/buttonStyleHelpers';
import { defaultSortableHeadingButtonStyles } from './styles/header.styles';

type BaselaneResponsiveSortableTableHeadingBtnProps = {
  variant: string,
  palette: string,
  styles: Object,
  iconStyles?: Object,
  icon: any,
  isDisabled: boolean,
  onChange: Function,
  onClick: Function,
  onBlur: Function,
  size: string,
  isActive: boolean,
  value: any,
};

const BaselaneResponsiveSortableTableHeadingBtn: any = forwardRef(
  function BaselaneResponsiveSortableTableHeadingBtnElement(
    {
      variant,
      palette,
      styles,
      iconStyles,
      icon,
      isDisabled,
      onChange,
      onClick,
      onBlur,
      size,
      isActive,
      value,
      ...rest
    }: BaselaneResponsiveSortableTableHeadingBtnProps,
    ref: any
  ): any {
    return (
      <IconButton
        {...getIconSpacingValue(size)}
        {...buttonThemeGenerator(variant, palette)}
        {...defaultSortableHeadingButtonStyles}
        {...{
          variant,
          palette,
          ...styles,
          ...iconStyles,
          icon,
          isDisabled,
          ref,
          onChange,
          onClick,
          onBlur,
          size,
          isActive,
          value,
          ...rest,
        }}
      />
    );
  }
);

BaselaneResponsiveSortableTableHeadingBtn.defaultProps = {
  iconStyles: {},
};

export { BaselaneResponsiveSortableTableHeadingBtn };
