import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { GET_PROPERTY_SUMMARY, GET_PROPERTIES } from '@core/apollo/queries';
import { BaselaneErrorCard } from '@shared/components';
import { sortWithString } from '@core/utils/sort';
import SlLoader from '@core/components/Loader';

import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import PropertyList from './PropertyList';

function PropertiesResponsivePage() {
  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  useEffect(() => {
    zendeskAPI('messenger', 'hide');
  }, []);

  const { error: summaryError, loading: summaryLoading, data: summaryData } = useQuery(
    GET_PROPERTY_SUMMARY
  );
  const { error: propertiesError, loading: propertiesLoading, data: propertiesData } = useQuery(
    GET_PROPERTIES
  );

  if (propertiesLoading || summaryLoading)
    return (
      <Box>
        <SlLoader />
      </Box>
    );
  if (propertiesError || summaryError) {
    if (
      summaryError?.message?.includes('Failed to fetch') ||
      propertiesError?.message?.includes('Failed to fetch')
    ) {
      return <BaselaneErrorCard />;
    }
    return null;
  }

  const { propertySummary = {} } = summaryData;
  const { totalProperties = 0, totalUnits = 0, vacantUnits = 0 } = propertySummary || {};
  const { property: properties = [] } = propertiesData ?? {};

  const vUnits = vacantUnits === null ? 0 : vacantUnits;
  const sortedProperties = sortWithString(properties, 'name');

  const propertiesWithLeaseDetail = sortedProperties.map((property) => {
    return {
      ...property,
      units:
        properties.find((leaseDetailProperty) => leaseDetailProperty.id === property.id)?.units ||
        property?.units,
    };
  });

  const hasProperties = sortedProperties.length > 0;
  return (
    <Box w="100%" h="100%" className="test">
      <PropertyList
        hasProperties={hasProperties}
        sortedProperties={propertiesWithLeaseDetail}
        numProperties={totalProperties}
        numUnits={totalUnits}
        numVacantUnits={vUnits}
      />
      <Outlet />
    </Box>
  );
}

export default PropertiesResponsivePage;
