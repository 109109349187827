import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { formLabelStyles, formErrorStyles } from '@shared/styles/input.style';
import { einMask } from '@core/utils/masks';
import IndustrySelect from './IndustrySelect';
import WebsiteInput from './WebsiteInput';
import DBAInput from './DBAInput';
import { VGSEditInput } from '../../components';
import { useVGSShowField } from '../../hooks/useVGSShowField';
import { handleValidation, initialVariables } from './helpers/businessInformation.helpers';
import { textStyles } from './styles/businessInformation.styles';
import '@core/styles/form.scss';

type BusinessInformationFormProps = {
  businessInfoRef: any,
  formData: Object,
};

const BusinessInformationForm = ({ businessInfoRef, formData }: BusinessInformationFormProps) => {
  const { fieldToggleButton, isEdit, showField } = useVGSShowField(formData.ein, 'ein');
  return (
    <Box h="100%">
      <Formik
        innerRef={businessInfoRef}
        validate={(values) => handleValidation(values)}
        initialValues={initialVariables(formData)}
        validateOnBlur
        enableReinitialize
      >
        {({
          values,
          setFieldTouched,
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Stack h="100%" spacing="16px">
              <IndustrySelect
                {...{
                  errors,
                  touched,
                  values,
                  setFieldTouched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                }}
              />

              {isMobile && (
                <WebsiteInput
                  {...{
                    values,
                    handleChange,
                    handleBlur,
                    setFieldTouched,
                    setFieldValue,
                    errors,
                    touched,
                  }}
                />
              )}
              {isMobile && <DBAInput {...{ values, handleChange, handleBlur }} />}

              <FormControl isInvalid={errors.ein && touched.ein} className="vgs">
                <HStack justifyContent="space-between">
                  <FormLabel htmlFor="ein" {...{ ...formLabelStyles.xs, whiteSpace: 'nowrap' }}>
                    EIN (Optional)
                  </FormLabel>
                  {fieldToggleButton}
                </HStack>
                {formData.ein && !isEdit ? (
                  showField
                ) : (
                  <VGSEditInput
                    {...{
                      inputProps: { mask: einMask, placeholder: 'xx-xxxxxxx' },
                      fieldName: 'ein',
                      isEdit,
                    }}
                  />
                )}
                <FormErrorMessage {...formErrorStyles}>{errors.ein}</FormErrorMessage>
                <Text {...textStyles}>EIN will be used for verification.</Text>
              </FormControl>

              {!isMobile && (
                <WebsiteInput
                  {...{
                    values,
                    handleChange,
                    handleBlur,
                    setFieldTouched,
                    setFieldValue,
                    errors,
                    touched,
                  }}
                />
              )}
              {!isMobile && <DBAInput {...{ values, handleChange, handleBlur }} />}
            </Stack>
          );
        }}
      </Formik>
    </Box>
  );
};
export default BusinessInformationForm;
