import React from 'react';

type IconPencilProps = { w?: Number, h?: Number, color?: string };

function IconPencil({ w, h, color }: IconPencilProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4331 2.88782C11.1728 2.14807 12.3722 2.14807 13.1119 2.88782C13.8517 3.62757 13.8517 4.82693 13.1119 5.56668L11.4575 7.22112C11.4571 7.22151 11.4567 7.22189 11.4563 7.22227C11.4559 7.22266 11.4556 7.22304 11.4552 7.22342L5.82383 12.8548C5.81668 12.8619 5.80963 12.869 5.80267 12.8759C5.66077 13.0179 5.5569 13.1219 5.43453 13.2026C5.32643 13.2738 5.2092 13.3302 5.08601 13.3701C4.94656 13.4152 4.8005 13.4314 4.60099 13.4535C4.5912 13.4546 4.58128 13.4557 4.57123 13.4568L2.70352 13.6643C2.60288 13.6755 2.50261 13.6403 2.43101 13.5687C2.3594 13.4971 2.32423 13.3968 2.33541 13.2962L2.54294 11.4285C2.54406 11.4184 2.54515 11.4085 2.54624 11.3987C2.56834 11.1992 2.58451 11.0532 2.62966 10.9137C2.66954 10.7905 2.72588 10.6733 2.79716 10.5652C2.87786 10.4428 2.98181 10.3389 3.1238 10.197C3.13076 10.1901 3.13782 10.183 3.14497 10.1759L10.4331 2.88782ZM9.01316 5.25052L3.61637 10.6473C3.44458 10.8191 3.39248 10.8734 3.35372 10.9322C3.3155 10.9901 3.2853 11.053 3.26392 11.119C3.24223 11.186 3.23236 11.2606 3.20553 11.5021L3.04402 12.9557L4.49761 12.7942C4.73907 12.7674 4.81369 12.7575 4.88069 12.7358C4.94673 12.7144 5.00957 12.6842 5.06752 12.646C5.12631 12.6072 5.18064 12.5551 5.35243 12.3833L10.7492 6.98657L9.01316 5.25052ZM11.2206 6.51516L9.48456 4.77911L10.9045 3.35922C11.3839 2.87982 12.1611 2.87983 12.6405 3.35922C13.1199 3.83862 13.1199 4.61588 12.6405 5.09528L11.2206 6.51516Z"
        fill={color}
      />
    </svg>
  );
}

IconPencil.defaultProps = {
  w: 22,
  h: 28,
  color: 'currentColor',
};

export default IconPencil;
