import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { IconPlus } from '@icons';
import BaselaneButton from '../../../BaselaneButton';
import { createDocumentStyles } from './styles/createDocument.styles';

type CreateDocumentProps = {
  setShowSelectProperty: Function,
  documents: Array<Object>,
  maxDocument: Number,
};

const CreateDocument = ({ setShowSelectProperty, documents, maxDocument }: CreateDocumentProps) => {
  const { isMinXL } = getBreakPoints();

  // TODO: Update Hard Coded Value once BE's ready
  const numOfDocuments = documents?.length;
  const hasReachedMaxDoc = numOfDocuments === maxDocument;

  const { container, button, description } = createDocumentStyles({ isMinXL }) ?? {};

  return (
    <Stack {...container}>
      {!hasReachedMaxDoc && (
        <BaselaneButton
          size="md"
          palette="primary"
          variant="tonal"
          styles={button}
          leftIcon={<IconPlus />}
          onClick={() => {
            setShowSelectProperty(true);
          }}
        >
          Create Lease Document
        </BaselaneButton>
      )}
      {!numOfDocuments ? (
        <Text {...description}>Up to {maxDocument} documents (FREE for limited period)</Text>
      ) : (
        <Text {...description}>
          {maxDocument - numOfDocuments} of {maxDocument} free documents left
        </Text>
      )}
    </Stack>
  );
};

export default CreateDocument;
