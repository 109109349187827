export const inputTextStyles = {
  size: 'xs',
  h: '48px',
  px: '12px',
  _placeholder: { color: 'brand.neutral.600' },
};

export const inputLabelTextStyles = {
  fontSize: 'xs',
  fontWeight: 'normal',
};
