import { isNil } from 'lodash';
import { formatDate } from '@core/utils/formatDate';

export const getTransactionDataUpdates = ({
  merchantName,
  originalMerchantName,
  date,
  originalDate,
}) => {
  const formatMerchantName = merchantName || '-';
  const formatOriginalMerchantName = originalMerchantName || '-';
  const isMerchantUpdated = merchantName !== originalMerchantName;

  const dateFormatted = date ? formatDate(date) : '-';
  const originalDateFormatted = originalDate ? formatDate(originalDate) : '-';
  const isTrxDateUpdated = dateFormatted !== originalDateFormatted;

  return [
    {
      title: 'Merchant',
      isUpdated: isMerchantUpdated,
      updated: formatMerchantName,
      original: formatOriginalMerchantName,
      isOriginalNull: isNil(originalMerchantName),
    },
    {
      title: 'Transaction Date',
      isUpdated: isTrxDateUpdated,
      updated: dateFormatted,
      original: originalDateFormatted,
      isOriginalNull: isNil(originalDate),
    },
  ];
};
