// @flow
import { React } from 'react';
import { Spinner, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import { useLazyQuery } from '@apollo/client';
import { GET_BANK_INFO } from '@core/apollo/queries';
import { numbersOnlyMask } from '@core/utils/masks';
import BaselaneInput from '../BaselaneInput';

type BaselaneRoutingNumberFieldProps = {
  id: string,
  routingNumberFieldName: string,
  accountPurpose?: String,
  bankNameFieldName: string,
  setBankInfoError?: Function,
  handleBankInfoAPISuccess?: Function,
  spinnerStyles?: Object,
};

const BaselaneRoutingNumberField = ({
  id,
  routingNumberFieldName,
  accountPurpose,
  bankNameFieldName,
  setBankInfoError,
  handleBankInfoAPISuccess,
  spinnerStyles,
  ...rest
}: BaselaneRoutingNumberFieldProps): any => {
  const { values, setFieldTouched, setFieldValue, handleChange, handleBlur } = useFormikContext();

  const [getBankInfo, { loading }] = useLazyQuery(GET_BANK_INFO, { fetchPolicy: 'network-only' });

  const handleBankInfoAPIError = (apiErrors) => {
    setBankInfoError(apiErrors);
  };

  return (
    <InputGroup>
      <BaselaneInput
        size="lg"
        id={id}
        name={routingNumberFieldName}
        value={values[routingNumberFieldName]}
        maxLength="9"
        as={MaskedInput}
        mask={numbersOnlyMask}
        onCopy={(e) => e.preventDefault()}
        onPaste={(e) => e.preventDefault()}
        onChange={(e) => {
          setFieldValue(routingNumberFieldName, e.target.value);
          handleChange(e);
        }}
        onBlur={async (e) => {
          setFieldTouched(routingNumberFieldName);
          setFieldValue(bankNameFieldName, '');
          if (e.target.value?.length === 9) {
            await getBankInfo({
              variables: { routingNumber: e.target.value, purpose: accountPurpose },
            }).then((res) => {
              if (res?.errors) {
                handleBankInfoAPIError(res?.errors);
              } else {
                handleBankInfoAPISuccess();
              }
              setFieldValue(bankNameFieldName, res.data?.bankInfo?.name ?? values.bankName);
            });
          }
          handleBlur(e);
        }}
        {...rest}
      />
      <InputRightElement h="100%">{loading && <Spinner {...spinnerStyles} />}</InputRightElement>
    </InputGroup>
  );
};

BaselaneRoutingNumberField.defaultProps = {
  setBankInfoError: () => {},
  handleBankInfoAPISuccess: () => {},
  accountPurpose: 'SEND_FUND',
  spinnerStyles: {},
};

export default BaselaneRoutingNumberField;
