import React, { useContext, useEffect } from 'react';
import { Stack, Tab, TabList, Tabs } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { BaselaneErrorCard, BaselaneTooltip } from '@core/components/Shared/components';
import BanksContext from '@contexts/BanksContext';
import getBreakPoints from '@core/utils/getBreakPoints';
import * as ROUTES from '@routes';
import SlLoader from '@core/components/Loader';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { EmptyStateView } from './EmptyStateView';
import { pageContainerStyles, tabContainerStyles, userProfileTabsListStyles } from './styles';

const tabs = [
  { to: ROUTES.TRANSFERS_ACTIVITY, title: 'Activity', segmentEventTarget: 'activity' },
  { to: ROUTES.TRANSFERS_RECIPIENTS, title: 'Recipients', segmentEventTarget: 'recipients' },
  {
    to: ROUTES.TRANSFERS_SCHEDULED,
    title: 'Scheduled',
    segmentEventTarget: 'scheduled_payments',
  },
  { to: ROUTES.TRANSFERS_RULES, title: 'Transfer rules', segmentEventTarget: 'transfer_rules' },
];

export function TransfersPage() {
  const { value: oneTimeScheduledPaymentGate } = useGate('one_time_scheduled_payment_gate');
  const activeTabs = oneTimeScheduledPaymentGate ? 3 : 2;
  const { isMax576 } = getBreakPoints();

  const { loading, error, hasActiveBaselaneAccounts } = useContext(BanksContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === `/${ROUTES.TRANSFERS_PAYMENTS}`) {
      navigate(ROUTES.TRANSFERS_ACTIVITY, { replace: true });
    }
  }, [pathname]);

  if (loading) return <SlLoader />;
  if (error) return <BaselaneErrorCard />;

  return hasActiveBaselaneAccounts ? (
    <Stack {...pageContainerStyles}>
      <Tabs id="transfers" index={tabs.findIndex(({ to }) => pathname.includes(to))}>
        <TabList id="transfers-tablist" {...tabContainerStyles(isMax576)}>
          {tabs.map(({ to, title, segmentEventTarget }, i) => {
            return i < activeTabs ? (
              <Tab
                key={title}
                onClick={() => {
                  sendSegmentEvent('baselane_banking_transfers_payments_navigation_clicked', {
                    target: segmentEventTarget,
                  });
                  navigate(to);
                }}
                {...userProfileTabsListStyles(isMax576)}
              >
                {title}
              </Tab>
            ) : (
              <BaselaneTooltip
                key={title}
                aria-label="Coming Soon"
                label="Coming Soon"
                placement="bottom-start"
              >
                <Tab {...userProfileTabsListStyles(isMax576)} isDisabled>
                  {title}
                </Tab>
              </BaselaneTooltip>
            );
          })}
        </TabList>
      </Tabs>

      <Outlet />
    </Stack>
  ) : (
    <EmptyStateView />
  );
}
