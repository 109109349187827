import React from 'react';

import type IconPropsType from './types/icon.types';

function IconExclamationCircle({ w, h, color }: IconPropsType) {
  return (
    <svg height={h} width={w} viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.333a6.667 6.667 0 100 13.334A6.667 6.667 0 009 2.333zm-5.893.774a8.333 8.333 0 1111.786 11.786A8.333 8.333 0 013.107 3.107zM9 4.833c.46 0 .833.373.833.834V9a.833.833 0 11-1.666 0V5.667c0-.46.373-.834.833-.834zm-.833 7.5c0-.46.373-.833.833-.833h.008a.833.833 0 110 1.667H9a.833.833 0 01-.833-.834z"
      />
    </svg>
  );
}

IconExclamationCircle.defaultProps = {
  w: 18,
  h: 18,
  color: 'currentColor',
};

export default IconExclamationCircle;
