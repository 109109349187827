import React from 'react';
import { Text } from '@chakra-ui/react';
import { truncatedText } from '@shared/styles/text.style';

type DisplayInputShowingSelectedValueNamesProps = {
  showValueByFields: Array<string>,
  selectedOptions: Array<Object>,
};

const DisplayInputShowingSelectedValueNames = ({
  showValueByFields,
  selectedOptions,
  ...rest
}: DisplayInputShowingSelectedValueNamesProps) => {
  const getValues = () => {
    if (showValueByFields) {
      return selectedOptions.map((value) => {
        if (value) {
          return value[showValueByFields[0]] || value[showValueByFields[1]];
        }
        return '';
      });
    }

    return selectedOptions;
  };

  return (
    <Text color="brand.neutral.900" textAlign="left" {...truncatedText} {...rest}>
      {getValues().join(', ')}
    </Text>
  );
};

export default DisplayInputShowingSelectedValueNames;
