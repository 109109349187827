import React from 'react';
import { Stack, HStack, Spacer, Text } from '@chakra-ui/react';

type SharedHeaderProps = {
  currentStep: Number,
  totalSteps: Number,
  transferType: String,
};

const SharedHeader = ({ currentStep, totalSteps, transferType }: SharedHeaderProps) => {
  const getSharedHeaderForCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return {
          title: 'Payment details',
          description:
            'Transfer money between your Baselane accounts (instant) or between your Baselane and external accounts (3 business days).',
        };

      case 2:
        return {
          title: 'Bookkeeping details',
          description: null,
        };

      case 3:
        return {
          title: 'Review and transfer',
          description:
            transferType === 'INTERNAL'
              ? 'Transfers between your Baselane accounts are instant.'
              : 'Your funds will be available within 3 business days.',
          description2:
            transferType === 'INTERNAL'
              ? null
              : 'Transfer requests received after 3:00 PM ET, on weekends, or on federal holidays will be processed the next business day.',
        };

      default:
        return null;
    }
  };

  const { title, description, description2 } = getSharedHeaderForCurrentStep() ?? {};

  return (
    <Stack gap={0.5}>
      <HStack>
        <Text textStyle="headline-md" color="brand.neutral.900">
          {title}
        </Text>
        <Spacer />
        <Text textStyle="xs" color="brand.neutral.600">
          Step {`${currentStep} of ${totalSteps - 1}`}
        </Text>
      </HStack>
      {description && (
        <Text textStyle="sm" color="brand.neutral.700">
          {description}
        </Text>
      )}
      {description2 && (
        <Text textStyle="sm" color="brand.neutral.700">
          {description2}
        </Text>
      )}
    </Stack>
  );
};

export default SharedHeader;
