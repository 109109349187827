import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { BaselaneDivider, BaselaneTitle } from '@shared/components';
import { normalFontWeightStyles } from '@shared/styles/font.style';

const NormalFont = ({ children }: { children: any }) => (
  <Box as="span" {...normalFontWeightStyles}>
    {children}
  </Box>
);

type FilterSummaryProps = {
  transactions: Array<Object>,
  filterSummary: Object,
};

function FilterSummary({ transactions, filterSummary }: FilterSummaryProps) {
  const renderFilterSummaryComponent = () => {
    const trxCount = transactions.length;
    const { categorySelected, subCategorySelected, dateSelected } = filterSummary ?? {};

    return (
      <HStack>
        <Box>Transactions</Box>

        {(categorySelected || dateSelected) && (
          <>
            <BaselaneDivider styles={{ h: '28px', mx: '16px !important' }} isVertical />
            <Box {...{ m: '0 !important' }}>
              <NormalFont>Showing</NormalFont> {trxCount.toLocaleString()}{' '}
              <NormalFont>{`transaction${trxCount === 1 ? '' : 's'}`}</NormalFont>{' '}
              {categorySelected && (
                <>
                  <NormalFont>for</NormalFont> {subCategorySelected || categorySelected}{' '}
                </>
              )}
              {dateSelected && (
                <>
                  <NormalFont>for</NormalFont> {dateSelected}
                </>
              )}
            </Box>
          </>
        )}
      </HStack>
    );
  };

  return (
    <BaselaneTitle
      title={renderFilterSummaryComponent()}
      titleStyles={{ p: 0, mt: '44px !important' }}
    />
  );
}

export default FilterSummary;
