import { PayeePaymentMethodType } from './constants.helpers';
import { restructureForQuery } from './content.helpers';
import { completePaymentMethods } from './validation.helpers';

const successHandler = (res, navigate, from, showUpdatePaymentMethodErrorToast) => {
  // close drawer with "success" state
  if (!res?.errors) {
    if (res?.data?.addPayeePaymentMethod) {
      // Add Payment Method Success
      navigate(
        {
          pathname: `${from}`,
        },
        {
          replace: true,
          state: {
            isAddPaymentMethodSuccessful: true,
            paymentMethod: { ...res.data.addPayeePaymentMethod },
          },
        }
      );
    } else if (res?.data?.updatePayeePaymentMethod) {
      // Update Payment Method Success
      navigate(
        {
          pathname: `${from}`,
        },
        {
          replace: true,
          state: {
            isUpdatePaymentMethodSuccessful: true,
            paymentMethod: { ...res.data.updatePayeePaymentMethod },
          },
        }
      );
    } else {
      showUpdatePaymentMethodErrorToast();
    }
  } else {
    showUpdatePaymentMethodErrorToast();
  }
};

/**
 * ACH
 */

/**
 * Generates the correct submission handler function, based on provided data.
 * @param {string} from pathname of originating location
 * @param {number} id the id of the payment method, if it exists
 * @param {number} recipientId the id of the payee, if it exists
 * @param {object} paymentMethods all the payment methods that currently exist for this payee
 * @param {function} navigate navigate function reference
 * @param {function} updateACHPaymentMethod update function reference (graphQL query)
 * @param {function} addPaymentMethod add function reference (graphQL query)
 * @param {function} showUpdatePaymentMethodErrorToast error toast show function
 * @returns the correct submission handler
 */
export const onSubmitPaymentMethodACH = (
  from,
  id,
  recipientId,
  paymentMethods,
  navigate,
  updatePaymentMethod,
  addPaymentMethod,
  showUpdatePaymentMethodErrorToast
) => {
  // Default - Add payment method to non-existent user - there is no id or recipient id
  let onSubmit = (values) => {
    navigate(from, { state: { paymentMethod: { achPaymentMethods: { ...values } } } });
  };

  if (id && recipientId) {
    // Edit existing payment method - there is an id and recipient id
    onSubmit = (values) => {
      updatePaymentMethod({
        variables: {
          input: {
            ...restructureForQuery(
              completePaymentMethods({
                ...paymentMethods,
                achPaymentMethods: {
                  accountHolderName: values?.accountHolderName,
                  routingNumber: values?.routingNumber,
                  accountNumber: values?.accountNumber,
                  accountType: values?.accountType,
                  id: parseFloat(id),
                },
              })
            ),
          },
        },
      })
        .then((res) => {
          successHandler(res, navigate, from, showUpdatePaymentMethodErrorToast);
        })
        .catch((err) => {
          showUpdatePaymentMethodErrorToast();
        });
    };
  } else if (!id && recipientId) {
    // Add payment method to existing user - there is no id but a recipient exists
    onSubmit = (values) => {
      addPaymentMethod({
        variables: {
          payeeId: parseFloat(recipientId),
          input: {
            achPaymentMethods: {
              type: PayeePaymentMethodType.ACH,
              accountHolderName: values?.accountHolderName,
              routingNumber: values?.routingNumber,
              accountNumber: values?.accountNumber,
              accountType: values?.accountType,
            },
          },
        },
      })
        .then((res) => {
          successHandler(res, navigate, from, showUpdatePaymentMethodErrorToast);
        })
        .catch((err) => {
          // generic error toast- TODO: this may need to be displayed differently
          showUpdatePaymentMethodErrorToast();
        });
    };
  }

  return onSubmit;
};

/**
 * WIRE_TRANSFER
 */

/**
 * Generates the correct submission handler function, based on provided data.
 * @param {string} from pathname of originating location
 * @param {number} id the id of the payment method, if it exists
 * @param {number} recipientId the id of the payee, if it exists
 * @param {object} paymentMethods all the payment methods that currently exist for this payee
 * @param {function} navigate navigate function reference
 * @param {function} updateWirePaymentMethod update function reference (graphQL query)
 * @param {function} addPaymentMethod add function reference (graphQL query)
 * @param {function} showUpdatePaymentMethodErrorToast error toast show function
 * @returns the correct submission handler
 */
export const onSubmitPaymentMethodWire = (
  from,
  id,
  recipientId,
  paymentMethods,
  navigate,
  updatePaymentMethod,
  addPaymentMethod,
  showUpdatePaymentMethodErrorToast
) => {
  // Default - Add payment method to non-existent user - there is no id or recipient id
  let onSubmit = (values) => {
    navigate(from, { state: { paymentMethod: { wirePaymentMethods: { ...values } } } });
  };

  if (id && recipientId) {
    // Update existing payment method - there is an id and recipient id
    onSubmit = (values) => {
      updatePaymentMethod({
        variables: {
          input: {
            ...restructureForQuery(
              completePaymentMethods({
                ...paymentMethods,
                wirePaymentMethods: {
                  accountHolderName: values?.accountHolderName,
                  routingNumber: values?.routingNumber,
                  accountNumber: values?.accountNumber,
                  accountType: values?.accountType,
                  id: parseFloat(id),
                  ...values,
                },
              })
            ),
          },
        },
      })
        .then((res) => {
          successHandler(res, navigate, from, showUpdatePaymentMethodErrorToast);
        })
        .catch((err) => {
          showUpdatePaymentMethodErrorToast();
        });
    };
  } else if (!id && recipientId) {
    // Add payment method to existing user - there is no id but a recipient exists
    onSubmit = (values) => {
      addPaymentMethod({
        variables: {
          payeeId: parseFloat(recipientId),
          input: {
            ...restructureForQuery({
              wirePaymentMethods: {
                type: PayeePaymentMethodType.WIRE_TRANSFER,
                accountHolderName: values?.accountHolderName,
                routingNumber: values?.routingNumber,
                accountNumber: values?.accountNumber,
                accountType: values?.accountType,
                ...values,
              },
            }),
          },
        },
      })
        .then((res) => {
          successHandler(res, navigate, from, showUpdatePaymentMethodErrorToast);
        })
        .catch((err) => {
          // generic error toast- TODO: this may need to be displayed differently
          showUpdatePaymentMethodErrorToast();
        });
    };
  }

  return onSubmit;
};

/**
 * CHECK_PAYMENT
 */

/**
 * Generates the correct submission handler function, based on provided data.
 * @param {string} from pathname of originating location
 * @param {number} id the id of the payment method, if it exists
 * @param {number} recipientId the id of the payee, if it exists
 * @param {object} paymentMethods all the payment methods that currently exist for this payee
 * @param {function} navigate navigate function reference
 * @param {function} updateWirePaymentMethod update function reference (graphQL query)
 * @param {function} addPaymentMethod add function reference (graphQL query)
 * @param {function} showUpdatePaymentMethodErrorToast error toast show function
 * @returns the correct submission handler
 */
export const onSubmitPaymentMethodCheck = (
  from,
  id,
  recipientId,
  paymentMethods,
  navigate,
  updatePaymentMethod,
  addPaymentMethod,
  showUpdatePaymentMethodErrorToast
) => {
  // Default - Add payment method to non-existent user - there is no id or recipient id
  let onSubmit = (values) => {
    navigate(from, { state: { paymentMethod: { checkPaymentMethods: { ...values } } } });
  };

  if (id && recipientId) {
    // Edit existing payment method - there is an id and recipient id
    onSubmit = (values) => {
      updatePaymentMethod({
        variables: {
          input: {
            ...restructureForQuery(
              completePaymentMethods({
                ...paymentMethods,
                checkPaymentMethods: {
                  name: values?.accountHolderName,
                  id: parseFloat(id),
                  ...values,
                },
              })
            ),
          },
        },
      })
        .then((res) => {
          successHandler(res, navigate, from, showUpdatePaymentMethodErrorToast);
        })
        .catch((err) => {
          showUpdatePaymentMethodErrorToast();
        });
    };
  } else if (!id && recipientId) {
    // Add payment method to existing user - there is no id but a recipient exists
    onSubmit = (values) => {
      addPaymentMethod({
        variables: {
          payeeId: parseFloat(recipientId),
          input: {
            ...restructureForQuery({
              checkPaymentMethods: {
                type: PayeePaymentMethodType.CHECK_PAYMENT,
                name: values?.name,
                ...values,
              },
            }),
          },
        },
      })
        .then((res) => {
          successHandler(res, navigate, from, showUpdatePaymentMethodErrorToast);
        })
        .catch((err) => {
          // generic error toast- TODO: this may need to be displayed differently
          showUpdatePaymentMethodErrorToast();
        });
    };
  }

  return onSubmit;
};
