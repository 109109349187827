import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { sortWithString } from '@core/utils/sort';
import { BaselaneErrorCard } from '@shared/components';

import { GET_PROPERTIES_AND_LEASES } from '@pages/LeasesPage/queries';
import useLeaseBanks from '@pages/LeasesPage/hooks/useLeaseBanks';
import UnifiedLeaseFlow from './UnifiedLeaseFlow';

function UnifiedLeaseUI() {
  // states
  const [sortedProperties, setSortedProperties] = useState([]);
  const [singleUnitId, setSingleUnitId] = useState(null);

  // calls
  const { loading, error, data: leasesData, refetch: refetchLeaseDetail } = useQuery(
    GET_PROPERTIES_AND_LEASES,
    {
      fetchPolicy: 'cache-and-network',
      variables: { input: { state: ['DRAFT', 'READY_TO_BE_REVIEWED'] } },
    }
  );

  // bank data & loading
  const { bankProps, banksError, banksLoading, allBanksError, allBanksLoading } = useLeaseBanks();

  const loadingObject = { loading, banksLoading, allBanksLoading };
  const hasError = error || banksError || allBanksError;

  useEffect(() => {
    if (leasesData?.property?.length) {
      const {
        property: properties = [],
        leases: { leases },
      } = leasesData || {};

      // creating a leases object that has the propertyUnitId for a key
      const leasesObject = leases?.reduce((targetObj, item) => {
        const newTargetObj = targetObj;
        newTargetObj[item.propertyUnitId] = item;
        return newTargetObj;
      }, {});

      const sortedPropertiesBare = sortWithString(properties, 'name');
      const unoccupiedUnits = [];
      sortedPropertiesBare.forEach((property) => {
        unoccupiedUnits.push(...property.units.filter((unit) => unit.status === 'unoccupied'));
      });
      const newSingleUnitId = unoccupiedUnits.length === 1 ? unoccupiedUnits[0].id : null;
      setSingleUnitId(newSingleUnitId);

      // enrich properties with leases
      const newSortedProperties = sortedPropertiesBare.map((property) => {
        const newProperty = { ...property };
        newProperty.units = property.units
          .filter((unit) => unit.status === 'unoccupied')
          .map((unit) => {
            // adding lease onto property unit
            const newUnit = { ...unit };
            newUnit.lease = leasesObject[unit.id];
            return newUnit;
          });
        return newProperty;
      });

      if (!(sortedProperties.length === 0 && newSortedProperties.length === 0)) {
        setSortedProperties(newSortedProperties);
      }
    }
  }, [leasesData]);

  return (
    <>
      {!hasError && (
        <UnifiedLeaseFlow
          {...{ bankProps, sortedProperties, singleUnitId, refetchLeaseDetail, loadingObject }}
        />
      )}
      {hasError && <BaselaneErrorCard />}
    </>
  );
}

export default UnifiedLeaseUI;
