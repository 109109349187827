import React from 'react';
import { Flex, Spacer, Text } from '@chakra-ui/react';
import { useField } from 'formik';
import BaselaneButtonToggle from '@shared/components/BaselaneButtonToggle';
import getBreakPoints from '@core/utils/getBreakPoints';
import RecurringTransfer from './RecurringTransfer';
import SendOnDatepicker from './SendOnDatepicker';

const TransferSchedule = () => {
  const { isMax576 } = getBreakPoints();
  // eslint-disable-next-line no-unused-vars
  const [transferTypeField, meta, transferTypeHelpers] = useField('transferType');

  return (
    <>
      <Flex>
        <Text textStyle="md-heavy">Transfer schedule</Text>
        <Spacer />
        <Text textStyle="xs">Step 2 of 4</Text>
      </Flex>
      <BaselaneButtonToggle
        isFullWidth
        firstLabel="One-time transfer"
        secondLabel="Recurring transfer"
        activeButton={transferTypeField.value === 'recurring' ? 1 : 0}
        onClick={(value) => transferTypeHelpers.setValue(value === 1 ? 'recurring' : 'one-time')}
        size={isMax576 ? 'md' : 'lg'}
      />
      {transferTypeField.value === 'recurring' ? <RecurringTransfer /> : <SendOnDatepicker />}
    </>
  );
};

export default TransferSchedule;
