export const updatedItemStyles = {
  borderWidth: '1px',
  borderColor: 'brand.darkBlue.150',
  borderRadius: '6px',
  p: '16px',
  m: '0 !important',
};

export const updatedItemTitleStyles = ({ isMinXL }) => {
  return {
    textStyle: 'headline-sm',
    color: 'brand.neutral.700',
    mb: isMinXL ? '8px' : '16px',
  };
};

export const updatedItemLabelStyles = {
  fontSize: 'xs',
  lineHeight: '20px',
  fontWeight: 'semibold',
  m: '0 !important',
};

export const updatedItemValueStyles = {
  fontSize: 'xs',
  lineHeight: '20px',
  color: 'brand.neutral.600',
  m: '0 !important',
};

export const updatedItemThirdColumnStyles = ({ isMinXL }) => {
  const mobileStyle = {
    gridColumn: '1 / -1',
  };

  const desktopStyles = {
    alignSelf: 'center',
    justifySelf: 'flex-end',
  };

  return isMinXL ? mobileStyle : desktopStyles;
};

export const gridStyles = ({ isMinXL }) => {
  return { w: '100%', rowGap: isMinXL ? '16px' : '0' };
};
