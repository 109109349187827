import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import { NATIVE_BANK, NB_CARDS, ADD_FUNDS_BANK_TRANSFER } from '@routes';
import BanksContext from '@contexts/BanksContext';
import { GET_CARD_SUMMARY } from '@core/components/NativeBankingPage/queries';
import Card from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/Card';
import ActivationSuccessPopup from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/ActivatePopup/ActivationSuccessPopup';
import AddFundsDrawer from '@core/components/NativeBankingPage/MainContent/components/AddFundsDrawer';
import { getBank } from '@core/components/NativeBankingPage/MainContent/helpers/banks.shared';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import Tracker from '../Tracker';
import { InProgress } from '../Steps';
import { getTrackerData, getLinkStatus } from '../../helpers/banking.helpers';

type BankingProps = {
  data: Object,
  refetchUser: Function,
  kycProps: Object,
  onBaselaneApplicationTypePopupOpen: Function,
};

const Banking = ({
  data,
  refetchUser,
  kycProps,
  onBaselaneApplicationTypePopupOpen,
}: BankingProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Activation Success Popup
  const {
    isOpen: isActivationSuccessPopupOpen,
    onOpen: onActivationSuccessPopupOpen,
    onClose: onActivationSuccessPopupClose,
  } = useDisclosure();

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  // bank data
  const { loading: isBanksLoading, error: hasBanksError, banks } = useContext(BanksContext);

  const {
    loading: isCardSummaryLoading,
    error: hasCardSummaryError,
    data: cardSummaryData,
  } = useQuery(GET_CARD_SUMMARY, {
    variables: {
      page: 1,
      pageSize: 99,
    },
    fetchPolicy: 'network-only',
  });

  const { getKYCUrl } = kycProps ?? {};

  const isLoading = isBanksLoading || isCardSummaryLoading;
  const hasError = hasBanksError || hasCardSummaryError;

  const awaitingDocAccounts = banks?.filter(
    (bank) => !bank.isExternal && bank.unitAccount?.unitApplicationStatus === 'AWAITING_DOCUMENTS'
  );

  const pendingAccounts = banks?.filter(
    (bank) => !bank.isExternal && bank.unitAccount?.unitApplicationStatus === 'IN_PROGRESS'
  );

  const hasSingleAwaitingDocAccount = awaitingDocAccounts?.length === 1;
  const hasPendingAccount = pendingAccounts?.length > 0;

  const [showSteps, setShowSteps] = useState(false);
  const trackerData = getTrackerData(hasPendingAccount);
  const LINKS = getLinkStatus(trackerData.elements);

  const getOpenBaselaneBankingLink = (status, state) => {
    switch (state) {
      case 'not_started':
        return (
          <InProgress
            status={status}
            handleTrackerInProgStepClick={onBaselaneApplicationTypePopupOpen}
            key={`open_baselane_banking_landlordbanking_${state}`}
          />
        );

      case 'in_progress':
        return (
          <InProgress
            status={status}
            handleTrackerInProgStepClick={
              hasPendingAccount ? null : onBaselaneApplicationTypePopupOpen
            }
            key={`open_baselane_banking_landlordbanking_${state}`}
          />
        );

      case 'warning':
        return hasSingleAwaitingDocAccount ? (
          <InProgress
            status={status}
            handleTrackerInProgStepClick={() =>
              getKYCUrl({
                variables: { bankId: awaitingDocAccounts[0].id },
              })
            }
            key={`open_baselane_banking_landlordbanking_${state}`}
          />
        ) : (
          <InProgress
            status={status}
            handleTrackerInProgStepClick={() => navigate(NATIVE_BANK)}
            key={`open_baselane_banking_landlordbanking_${state}`}
          />
        );

      case 'alert':
        return (
          <InProgress
            status={status}
            handleTrackerInProgStepClick={() => zendeskAPI('messenger', 'open')}
            key={`open_baselane_banking_landlordbanking_${state}`}
          />
        );

      case 'completed':
        return (
          <InProgress status={status} key={`open_baselane_banking_landlordbanking_${state}`} />
        );

      default:
        return null;
    }
  };

  const getFundYourAccountLink = (status, state) => {
    switch (state) {
      case 'not_started':
        return (
          <AddFundsDrawer
            isGetStarted
            getStartedConfig={{ status }}
            onOpenAddFundsDrawer={() =>
              navigate({
                pathname: `${location.pathname}/${ADD_FUNDS_BANK_TRANSFER}`,
              })
            }
            key={`fund_your_account_landlordbanking_${state}`}
          />
        );

      case 'completed':
        return (
          <InProgress status={status} key={`open_baselane_banking_landlordbanking_${state}`} />
        );

      default:
        return null;
    }
  };

  const getActivateDebitCardLink = (status, state) => {
    const { cardSummary } = cardSummaryData ?? {};

    const physicalCards = cardSummary?.data?.filter(
      (card) => card.isPhysical && card.cardStatus === 'Inactive'
    );

    let activeDebitCardComponent = null;

    switch (state) {
      case 'not_started':
      case 'in_progress':
        if (physicalCards?.length > 1) {
          activeDebitCardComponent = (
            <InProgress
              status={status}
              handleTrackerInProgStepClick={() => navigate(NB_CARDS)}
              key={`open_baselane_banking_landlordbanking_${state}`}
            />
          );
        }

        if (physicalCards?.length === 1) {
          const bank = getBank(banks, physicalCards[0].bankAccountId);
          const bankAccount = bank?.bankAccounts?.find(
            (bAccount) => Number(bAccount.id) === physicalCards[0].bankAccountId
          );

          const bankInfo = {
            ...bank,
            ...bankAccount,
            bankId: bank?.id,
            phoneNumber: bank?.unitAccount?.phoneNumber ?? '',
            bankName: bank?.name ?? '',
          };

          activeDebitCardComponent = (
            <Card
              key={physicalCards[0].id}
              card={physicalCards[0]}
              onActivationSuccessPopupOpen={() => {
                onActivationSuccessPopupOpen();
                refetchUser();
              }}
              isGetStarted
              getStartedConfig={{ status }}
              bankInfo={bankInfo}
            />
          );
        }

        return activeDebitCardComponent;

      case 'completed':
        return (
          <InProgress status={status} key={`open_baselane_banking_landlordbanking_${state}`} />
        );

      default:
        return null;
    }
  };

  const getLinkTo = (step, state) => {
    const status = state === 'disabled' ? LINKS[step].not_started : LINKS[step][state];
    switch (step) {
      case 'OPEN_BASELANE_BANKING':
        return getOpenBaselaneBankingLink(status, state);

      case 'FUND_YOUR_ACCOUNT':
        return getFundYourAccountLink(status, state);

      case 'ACTIVATE_DEBIT_CARD':
        return getActivateDebitCardLink(status, state);

      default:
        return null;
    }
  };

  return (
    <>
      <Tracker
        {...{
          tracker: { data: trackerData, getLinkTo },
          steps: data,
          isLoading,
          hasError,
          showSteps,
          setShowSteps,
          key: 'banking',
        }}
      />

      <ActivationSuccessPopup
        {...{
          isActivationSuccessPopupOpen,
          onActivationSuccessPopupClose,
        }}
      />
    </>
  );
};

export default Banking;
