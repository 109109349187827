import React, { useState } from 'react';
import { Box, Image, Stack, Text } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { BaselaneBadge, BaselaneDropdown } from '@shared/components';
import {
  renderPropertyDropdownChildItem,
  renderPropertyDropdownParentItem,
} from '@shared/helpers/propertiesFilter.helpers';
import { IconBankThin, IconTag } from '@icons';
import {
  bankAccountInfoTextStyles,
  containerStyles,
  dotsStyles,
  iconTagContainerStyles,
  taggedContainerStyles,
} from '../styles/bankAccountToTag.styles';

type BankAccountToTagProps = {
  numberInList: Number,
  bankAccount: Object,
  propertyOptions: Array<Object>,
  accountsAutoTaggedList: Array<Object>,
  setAccountsAutoTaggedList: Function,
};

const BankAccountToTag = ({
  numberInList,
  bankAccount,
  propertyOptions,
  accountsAutoTaggedList,
  setAccountsAutoTaggedList,
}: BankAccountToTagProps) => {
  const { logo, plaidInstitutionName, nickName, tagged = null } = bankAccount;
  const [taggedPropertyId, setTaggedPropertyId] = useState(tagged);

  const getSelectedItem = () => {
    const findoption = propertyOptions.find((option) => option.id === taggedPropertyId);
    return findoption ?? null;
  };

  return (
    <Stack {...{ ...containerStyles, ...taggedContainerStyles(tagged) }}>
      <Stack direction="row" position="relative" justifyContent="space-between">
        <Stack direction="row">
          <Box mr="16px">
            {logo ? (
              <Image
                src={`data:image/png;base64,${logo.toString()}`}
                alt={plaidInstitutionName}
                h="24px"
              />
            ) : (
              <Box w="24px" h="24px">
                <IconBankThin w={24} h={24} />
              </Box>
            )}
          </Box>
          <Text {...bankAccountInfoTextStyles}>{nickName}</Text>
        </Stack>
        {tagged && <BaselaneBadge type="badge" color="green" text="auto-tag enabled" />}
        <Box {...dotsStyles} />
      </Stack>
      <Stack direction="row" align="center" w="100%">
        <Stack {...iconTagContainerStyles}>
          <IconTag color="currentColor" h={20} w={20} />
        </Stack>

        <Box flex="1 1 auto">
          <BaselaneDropdown
            {...{
              classNames: [
                'auto-width',
                'disable-max-width',
                'input-form-lg',
                'auto-width-dropdown',
              ],
              dropdownPosition: numberInList < 2 ? 'bottom' : 'auto',
              parentId: 'onboarding-wrapper',
              type: 'tier2',
              data: propertyOptions,
              searchTerm: 'name',
              title: 'Property',
              placeholder: 'Select a property to auto-tag',
              hasFilterWrapper: true,
              showValueByFields: ['showValueName'],
              parentItemRenderer: ({ item }) => renderPropertyDropdownParentItem(item),
              childItemRenderer: ({ item }) => renderPropertyDropdownChildItem(item),
              handleSubmit: (data) => {
                const clonedList = cloneDeep(accountsAutoTaggedList);
                const index = clonedList.findIndex((item) => item.id === bankAccount.id);

                clonedList[index].tagged = data;
                setAccountsAutoTaggedList(clonedList);
                setTaggedPropertyId(data);
              },
              selectedItem: getSelectedItem(),
              onClearClick: () => {
                const clonedList = cloneDeep(accountsAutoTaggedList);
                const index = clonedList.findIndex((item) => item.id === bankAccount.id);

                clonedList[index].tagged = null;
                setTaggedPropertyId(null);
                setAccountsAutoTaggedList(clonedList);
              },
              clearSelectedAndStaged: true,
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default BankAccountToTag;
