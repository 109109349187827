import { fullSize } from './shared.styles';

export const defaultHeaderRowStyles = {
  background: 'brand.neutral.white',
  borderTop: 'none !important',
  h: 'auto',
  _hover: {
    background: 'brand.neutral.white',
    borderBottomColor: 'brand.darkBlue.150',
  },
};

export const defaultContentContainerStyles = {
  ...fullSize,
  alignItems: 'left',
};

export const defaultHeadingStyles = {
  py: 1.5,
  px: 2,
};

export const defaultSortableHeadingStyles = {
  p: '8px 4px 8px 4px',
};
export const defaultSortableHeadingButtonStyles = {
  borderRadius: '6px',
  padding: '4px 8px !important',
  color: 'brand.neutral.600',
  _hover: { bgColor: 'brand.darkBlue.50', color: 'brand.neutral.600' },
  _active: { bgColor: 'brand.darkBlue.100', color: 'brand.neutral.600' },
  _focus: {
    outline: '1.5px dashed #0968BF',
  },
};

export const defaultTitleTextStyles = {
  textStyle: 'headline-xs',
  color: 'brand.neutral.600',
  p: '0',
  textTransform: 'capitalize',
};
