import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { toastVariants } from './styles/toast.styles';
import { bannerVariants } from './styles/banner.styles';
import { alertVariants } from './styles/alert.styles';

/**
 * This overrides Chakra's default <Alert /> styles.
 *
 * Note: Chakra's Toast utility is actually a re-styled Alert, so changes here will affect that as well.
 *
 * see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/alert.ts for implementation details
 */
const parts = ['title', 'description', 'container', 'icon', 'spinner'];
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Alert = defineMultiStyleConfig({
  baseStyle: {
    container: {
      px: 2,
      py: 1.5,
    },
    title: {
      marginEnd: 1,
    },
    icon: {
      marginEnd: 1.5,
    },
  },
  variants: {
    ...toastVariants, // overrides alert being used in the Chakra Toast component
    ...bannerVariants, // overrides alert being used in the BaselaneBanner component
    ...alertVariants, // overrides alert being used in the BaselaneAlert component
  },
});
