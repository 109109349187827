import process from 'process';

export async function lockCard(id, token, isLockCard) {
  const response = await fetch(
    `${process.env.REACT_APP_UNIT_SERVICE_URL}/cards/${id}/${isLockCard ? 'unfreeze' : 'freeze'}`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    }
  );

  return response;
}

// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=1%3A141772
export const CARD_STATUS = Object.freeze({
  Stolen: {
    badge: {
      text: 'Stolen',
      color: 'darkred',
    },
    description:
      'This card has been reported as stolen. Any transactions on this card will be automatically declined. Please contact support if you have any questions.',
    isLockButtonDisabled: true,
  },
  Lost: {
    badge: {
      text: 'Lost',
      color: 'blue',
    },
    description:
      'This card has been reported as lost. Any transactions on this card will be automatically declined. Please contact support if you have any questions.',
    isLockButtonDisabled: true,
  },
  SuspectedFraud: {
    badge: {
      text: 'Suspected Fraud',
      color: 'yellow',
    },
    description:
      'This card has been flagged for potential fraudlent activities. Any transactions on this card will be automatically declined. Please contact support if you have any questions.',
    isLockButtonDisabled: true,
    isTerminateButtonDisabled: true,
  },
  ClosedByCustomer: {
    badge: {
      text: 'Closed',
      color: 'gray',
    },
    description:
      'This card has been closed by your request. Any transactions on this card will be automatically declined. Please contact support if you have any questions.',
    isLockButtonDisabled: true,
  },
  // When user locks the card from cardDetails drawer
  Frozen: {
    badge: {
      text: 'Locked',
      color: 'red',
    },
    description:
      'Any transactions on this card will be automatically declined. You may unlock this card at any time and allow new transactions.',
    isLockButtonDisabled: false,
  },
  // Not been activated initally
  Inactive: {
    badge: null,
    description: '',
    isLockButtonDisabled: true,
  },
  // Activated w/o any issues
  Active: {
    badge: null,
    description: '',
    isLockButtonDisabled: false,
  },
});

Object.freeze(CARD_STATUS);
