import { NB_ACCOUNTS, NB_CARDS, NB_STATEMENT_DOCUMENTS } from '../../../../constants/routes';

const getTabsList = (hasActiveBaselaneAccounts, isMobile) => {
  return !hasActiveBaselaneAccounts
    ? [{ index: 0, label: 'Accounts', path: '/accounts' }]
    : [
        { index: 0, label: 'Accounts', path: '/accounts' },
        { index: 1, label: 'Cards', path: '/cards' },
        {
          index: 2,
          label: 'Statements',
          path: '/statements',
        },
      ];
};

const getTabsIndex = (hasActiveBaselaneAccounts) => {
  return hasActiveBaselaneAccounts
    ? Object.freeze({
        [NB_ACCOUNTS]: 0,
        [NB_CARDS]: 1,
        [NB_STATEMENT_DOCUMENTS]: 2,
      })
    : Object.freeze({
        [NB_ACCOUNTS]: 0,
      });
};

export const getTabs = (hasActiveBaselaneAccounts, isMobile) => {
  return {
    tabsList: getTabsList(hasActiveBaselaneAccounts, isMobile),
    tabsIndex: getTabsIndex(hasActiveBaselaneAccounts),
  };
};
