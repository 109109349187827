import React from 'react';
import { useNavigate } from 'react-router-dom';

import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { ONBOARDING_GS_EASY_STEPS } from '@routes';
import useOnboardingBookkeepingOption from '@hooks/useOnboardingBookkeepingOption';

import OnboardingOptions from './OnboardingOptions';
import OptionCardsScreens from './OptionCardsScreens';

const OnboardingTriageCards = () => {
  const { selection, setSelection } = useOnboardingBookkeepingOption();
  const navigate = useNavigate();

  const handleActionButton = (e, card) => {
    sendSegmentEvent('onboarding_complete_path_selection', { feature: card.segmentEvent });

    if (card.id !== 'bookkeeping-option') {
      navigate({ pathname: ONBOARDING_GS_EASY_STEPS, search: `?selection=${card.segmentEvent}` });
    }

    setSelection(card.segmentEvent);
    localStorage.setItem('onboarding-selection', card.segmentEvent);
  };

  return selection === 'bookkeeping' ? (
    <OptionCardsScreens selection={selection} />
  ) : (
    <OnboardingOptions {...{ handleActionButton }} />
  );
};

export default OnboardingTriageCards;
