import React from 'react';
import { isMobile } from 'react-device-detect';
import { Flex, Spacer, Stack } from '@chakra-ui/react';
import { IconGoEdit } from '@icons';
import { Icon16PlusCircle } from '@icons/16px';
import { BaselaneButton } from '@shared/components';
import { FormTitle } from '@shared/components/BaselaneFlow';
import {
  addressSectionFormStyles,
  addressSectionLabelTextStyles,
  addressSectionLabelContainerStyles,
} from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';

type LeaseSectionProps = {
  title: string,
  children: any,
  id: string,
  setTabIndex: Function,
  hasNoDepositsFees?: boolean,
  emptyDepositsFees?: any,
};

function LeaseSection({
  title,
  children,
  id,
  setTabIndex,
  hasNoDepositsFees,
  emptyDepositsFees,
}: LeaseSectionProps) {
  const LeftIcon = <IconGoEdit />;
  const header = (
    <Flex {...addressSectionFormStyles}>
      <FormTitle
        title={title}
        textStyles={addressSectionLabelTextStyles()}
        containerStyles={addressSectionLabelContainerStyles()}
      />
      <Spacer />
      <BaselaneButton
        onClick={(e) => {
          e.stopPropagation();
          setTabIndex(id + 1);
        }}
        leftIcon={hasNoDepositsFees ? <Icon16PlusCircle /> : LeftIcon}
        variant="transparent"
        palette="neutral"
        styles={{ m: 0 }}
      >
        {hasNoDepositsFees ? 'Add' : 'Edit'}
      </BaselaneButton>
    </Flex>
  );

  return (
    <Stack
      py={isMobile ? 1 : '20px'}
      _notLast={{ borderBottom: '1px', borderBottomColor: 'brand.darkBlue.100' }}
      gap={0}
    >
      {header}
      {hasNoDepositsFees ? emptyDepositsFees : children}
    </Stack>
  );
}

LeaseSection.defaultProps = {
  hasNoDepositsFees: false,
  emptyDepositsFees: null,
};

export default LeaseSection;
