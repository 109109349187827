export const boxContainerStyles = {
  background: 'brand.blue.50',
  borderRadius: 'sm',
  w: '100%',
  p: 1.5,
  my: { base: '40px!important', lg: '24px!important' },
};

export const iconContainerStyles = {
  pt: '4px',
  alignSelf: 'flex-start',
};

export const titleContainerStyles = {
  alignSelf: 'flex-start',
};

export const titleStyles = {
  textStyle: 'sm-heavy',
  color: 'brand.neutral.700',
};

export const descriptionStyles = {
  fontSize: 'sm',
  lineHeight: '20px',
  fontWeight: 'normal',
  color: 'brand.neutral.700',
};
