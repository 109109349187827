import React from 'react';
import { HStack, Switch, Text } from '@chakra-ui/react';
import { switchLabelStyles } from '@core/components/Transactions/components/TransactionsFilters/styles/transactionsFilters.styles';

type TogglesProps = {
  showingUncategorizedTransactions: boolean,
  handleUncategroziedChange: Function,
  showHiddenTransactions: boolean,
  onToggleHiddenTransactions: Function,
  showOnlyWithAttachments: boolean,
  onToggleOnlyWithAttachments: Function,
};

const Toggles = ({
  showingUncategorizedTransactions,
  handleUncategroziedChange,
  showHiddenTransactions,
  onToggleHiddenTransactions,
  showOnlyWithAttachments,
  onToggleOnlyWithAttachments,
}: TogglesProps) => {
  /* Toggle Switches */
  return (
    <HStack spacing={2}>
      {/* Uncategorized Switch */}
      <HStack>
        <Text htmlFor="uncategorized-switch" {...switchLabelStyles} ml="0px !important">
          Uncategorized
        </Text>
        <Switch
          as="span"
          className="primary-500"
          name="uncategorized-switch"
          id="uncategorized-switch"
          isFocusable
          isChecked={showingUncategorizedTransactions}
          onChange={handleUncategroziedChange}
        />
      </HStack>

      {/* Hidden Switch */}
      <HStack>
        <Text htmlFor="hidden-switch" {...switchLabelStyles}>
          Hidden
        </Text>
        <Switch
          as="span"
          className="primary-500"
          name="hidden-switch"
          id="hidden-switch"
          isFocusable
          isChecked={showHiddenTransactions}
          onChange={() => onToggleHiddenTransactions(!showHiddenTransactions)}
        />
      </HStack>

      {/* Hidden Switch */}
      <HStack flex="1 0 auto">
        <Text htmlFor="attachments-switch" {...switchLabelStyles}>
          Has Attachments
        </Text>
        <Switch
          as="span"
          className="primary-500"
          name="attachments-switch"
          id="attachments-switch"
          isFocusable
          isChecked={showOnlyWithAttachments}
          onChange={() => onToggleOnlyWithAttachments(!showOnlyWithAttachments)}
        />
      </HStack>
    </HStack>
  );
};

export default Toggles;
