// @flow
import React from 'react';
import { Box, Stack, HStack, Text } from '@chakra-ui/react';
import { IconExclamationTriangle } from '@icons';
import { badgeIconBgStyles, badgeIconContainerStyles } from '../BaselaneAlert/styles/alert.styles';
import { subTitleTextStyles, rowStyles } from './styles/header.styles';

type AlertHeaderProps = {
  isInline?: Boolean,
  isHorizontalCenter?: Boolean,
  icon?: any,
  title?: string,
  iconBgStyles?: Object,
  iconContainerStyles?: Object,
  titleTextStyles?: Object,
  subtitle?: string,
};

const AlertHeader = ({
  isInline,
  isHorizontalCenter,
  icon,
  title,
  iconBgStyles,
  iconContainerStyles,
  titleTextStyles,
  subtitle,
}: AlertHeaderProps) => {
  return isHorizontalCenter ? (
    <HStack {...rowStyles}>
      {icon && (
        <Stack {...{ ...badgeIconBgStyles, ...iconBgStyles }}>
          <Box {...{ ...badgeIconContainerStyles, ...iconContainerStyles }}>{icon}</Box>
        </Stack>
      )}
      <Text {...(icon && titleTextStyles)}>{title}</Text>
      {subtitle && <Box {...subTitleTextStyles}>{subtitle}</Box>}
    </HStack>
  ) : (
    <Stack direction={isInline ? 'row' : 'column'} {...rowStyles}>
      {icon && (
        <Stack {...{ ...badgeIconBgStyles, ...iconBgStyles }}>
          <Box {...{ ...badgeIconContainerStyles, ...iconContainerStyles }}>{icon}</Box>
        </Stack>
      )}
      <Text {...(icon && titleTextStyles)}>{title}</Text>
      {subtitle && <Box {...subTitleTextStyles}>{subtitle}</Box>}
    </Stack>
  );
};

AlertHeader.defaultProps = {
  isInline: true,
  isHorizontalCenter: false,
  icon: <IconExclamationTriangle w="24" h="24" />,
  title: 'Warning',
  iconBgStyles: ({}: { ... }),
  iconContainerStyles: ({}: { ... }),
  titleTextStyles: ({}: { ... }),
  subtitle: null,
};

export default AlertHeader;
