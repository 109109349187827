import React, { useEffect } from 'react';
import { FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import { fieldContainer } from '../../styles/shared.styles';

type RentCollectionStartInputProps = {
  name: string,
  errors: Object,
  touched: Object,
  values: Object,
  formVariables: Object,
  handleChange: Function,
  handleBlur: Function,
  setFieldValue: Function,
  setFieldTouched: Function,
  setFormVariables: Function,
  rcStartOptions: Array,
  unifiedRC: boolean,
};

function RentCollectionStartInput({
  name,
  errors,
  touched,
  values,
  formVariables,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setFormVariables,
  rcStartOptions,
  unifiedRC,
}: RentCollectionStartInputProps) {
  const handleChangeRentCollectionStartDate = (e) => {
    setFieldTouched('rentCollectionStartDate', true);
    setFormVariables({ rentCollectionStartDate: e.target.value });
    handleChange(e);
  };

  const handleBlurRentCollectionStartDate = (e) => {
    setFieldTouched('rentCollectionStartDate', true);
    handleBlur(e);
  };

  const handleRentCollectionStartDate = () => {
    if (
      rcStartOptions.length > 0 &&
      !touched.rentCollectionStartDate &&
      formVariables.rentCollectionStartDate !== rcStartOptions[0]?.dateValue
    ) {
      setFieldValue('rentCollectionStartDate', rcStartOptions[0]?.dateValue);
      setFormVariables({
        rentCollectionStartDate: rcStartOptions[0]?.dateValue,
      });
    } else if (rcStartOptions.length === 0) {
      // if rcStartOptions is empty set rentCollectionStartDate to null to prevent Save
      setFieldValue('rentCollectionStartDate', null);
      setFormVariables({
        rentCollectionStartDate: null,
      });
    }
  };
  useEffect(() => {
    handleRentCollectionStartDate();
  }, [rcStartOptions, values]);

  return (
    <FormControl
      {...fieldContainer}
      isInvalid={
        (touched.rentCollectionStartDate && errors.rentCollectionStartDate) ||
        !rcStartOptions?.length
      }
      my="0"
    >
      {!unifiedRC && (
        <FormLabel {...formLabelStyles.xs} htmlFor={name}>
          Changes effective from
        </FormLabel>
      )}
      <Select
        {...formInputStyles}
        h={unifiedRC ? '32px' : '48px'}
        id={name}
        {...{ name }}
        value={values.rentCollectionStartDate || rcStartOptions[0]?.dateValue}
        onChange={handleChangeRentCollectionStartDate}
        onBlur={handleBlurRentCollectionStartDate}
        required
        p="0"
        mb={unifiedRC ? '0' : '8px'}
        color={values.rentCollectionStartDate ? 'brand.neutral.700' : 'brand.neutral.500'}
        bg={
          values.rentCollectionStartDate ? 'brand.neutral.white' : 'brand.darkBlue.150 !important'
        }
      >
        {rcStartOptions.map((o) => (
          <option id={o.dateValue?.replace(/\s|,/g, '')} key={o.dateValue} value={o.dateValue}>
            {o.label}
          </option>
        ))}
      </Select>
      {!rcStartOptions?.length && !unifiedRC && (
        <FormErrorMessage {...formErrorStyles}>
          No Rent Collection Start date within selected period and due day.
        </FormErrorMessage>
      )}
    </FormControl>
  );
}

export default RentCollectionStartInput;
