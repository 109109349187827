import React from 'react';
import { Field } from 'formik';
import { FormControl } from '@chakra-ui/react';
import { BaselaneInput, BaselaneFormErrorMessage } from '@shared/components';

const NumberOfPaymentsField = () => {
  return (
    <Field name="recurringPayment.numberOfPayments">
      {({ field, form }) => {
        return (
          <FormControl
            m={0}
            isRequired
            isInvalid={
              form.errors?.recurringPayment?.numberOfPayments &&
              form.touched?.recurringPayment?.numberOfPayments
            }
            maxWidth="150px"
          >
            <BaselaneInput
              type="number"
              size="sm"
              suffix="payments"
              {...field}
              isDisabled={form.values?.recurringPayment?.endBy !== 'numberOfPayments'}
            />
            <BaselaneFormErrorMessage
              isInvalid={
                form.errors?.recurringPayment?.numberOfPayments &&
                form.touched?.recurringPayment?.numberOfPayments
              }
            >
              {form.errors?.recurringPayment?.numberOfPayments}
            </BaselaneFormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default NumberOfPaymentsField;
