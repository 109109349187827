import React from 'react';
import { Stack, Spacer } from '@chakra-ui/react';
import {
  containerStyles,
  wrapperContainerStyles,
} from '@shared/layouts/BaselaneHeaderLogoWrapper/styles/wrapper.styles';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';

function Wrapper({ children }: { children: any }) {
  return (
    <Stack {...wrapperContainerStyles}>
      <Stack {...containerStyles}>
        <BaselaneFullLogo />
        <Spacer />
        <Stack justifyContent="center" alignItems="center">
          {children}
        </Stack>

        <Spacer />
      </Stack>
    </Stack>
  );
}

export default Wrapper;
