import React from 'react';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

const BoxEmptyState = ({ text, ...styles }: { text: string }) => (
  <Box
    w="100%"
    p="12px"
    textStyle="xs"
    textAlign="center"
    borderRadius="8px"
    border={isMobile ? '0' : '1px dashed'}
    borderColor="brand.darkBlue.150"
    color="brand.neutral.500"
    {...styles}
  >
    {text}
  </Box>
);

export default BoxEmptyState;
