import React from 'react';

type IconMigrationBannerEmptyProps = {
  strokeColor?: string,
  fillColor?: string,
};

const IconMigrationBannerEmpty = ({ strokeColor, fillColor }: IconMigrationBannerEmptyProps) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 34H6.66667C5.73325 34 5.26654 34 4.91002 33.8181C4.59641 33.6581 4.34144 33.4028 4.18166 33.0887C4 32.7317 4 32.2644 4 31.3297L4 29.9946C4 29.0599 4 28.5925 4.18166 28.2355C4.34144 27.9215 4.59641 27.6662 4.91002 27.5062C5.26654 27.3243 5.73325 27.3243 6.66667 27.3243H21M7.33333 13.9729V27.3243M14.8333 13.9729V27.3243M23.1667 13.9729V24.5M30.6667 13.9729V20.5M18.4215 4.08801L6.08819 6.83247C5.34308 6.99828 4.97053 7.08118 4.69244 7.2818C4.44715 7.45875 4.25455 7.69918 4.13525 7.97734C4 8.2927 4 8.67486 4 9.43917L4 11.3026C4 12.2373 4 12.7046 4.18166 13.0616C4.34144 13.3756 4.59641 13.6309 4.91002 13.791C5.26654 13.9729 5.73325 13.9729 6.66667 13.9729H31.3333C32.2668 13.9729 32.7335 13.9729 33.09 13.791C33.4036 13.6309 33.6586 13.3756 33.8183 13.0616C34 12.7046 34 12.2373 34 11.3026V9.43917C34 8.67486 34 8.2927 33.8647 7.97735C33.7455 7.69918 33.5528 7.45875 33.3076 7.2818C33.0295 7.08118 32.6569 6.99828 31.9118 6.83248L19.5785 4.08801C19.3626 4.03998 19.2547 4.01596 19.1456 4.00638C19.0487 3.99787 18.9513 3.99787 18.8544 4.00638C18.7453 4.01596 18.6374 4.03998 18.4215 4.08801Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.7" cx="34" cy="34" r="10" fill={fillColor} />
      <circle
        cx="32"
        cy="33"
        r="10"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M32 28V34.4118" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32 37H32.0065" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

IconMigrationBannerEmpty.defaultProps = {
  strokeColor: 'currentColor',
  fillColor: '#E7898A',
};

export default IconMigrationBannerEmpty;
