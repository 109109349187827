import React from 'react';
import { BaselaneDrawer } from '@shared/components';
import SuccessDrawerBody from '@pages/PropertiesPage/SuccessDrawer/SuccessDrawerBody';
import getBreakPoints from '@core/utils/getBreakPoints';

type SuccessDrawerProps = {
  successDrawerRef: any,
  property: Object,
  handleClose?: Function,
};

const SuccessDrawer = ({ successDrawerRef, property, handleClose }: SuccessDrawerProps) => {
  const handleDrawerClose = () => {
    handleClose();
    successDrawerRef.current.close();
  };
  const { isMax767 } = getBreakPoints();

  return (
    <BaselaneDrawer
      ref={successDrawerRef}
      title="Add Property"
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      closeEvent={handleDrawerClose}
      newDesignDrawer
    >
      <SuccessDrawerBody {...{ property }} />
    </BaselaneDrawer>
  );
};

SuccessDrawer.defaultProps = {
  handleClose: () => {},
};

export default SuccessDrawer;
