export const listItemContainerStyles = {
  alignItems: 'center',
  justifyContent: 'space-between',
  _notFirst: {
    mt: '36px',
  },
};

export const listItemLabelStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
};

export const listItemValueStyles = {
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
};

export const listContainerStyles = { gap: 0 };
