import { keyframes } from '@chakra-ui/react';

export const popupStyles = ({ isMinXL }) => {
  return {
    filter: {
      container: { m: '0 !important' },
      customStyles: {
        popupContainer: {
          left: isMinXL ? 0 : '32px',
          right: isMinXL ? 0 : '32px',
        },
      },
      title: {
        textStyle: 'headline-xs',
        color: 'brand.neutral.600',
        mb: '10px !important',
      },
      custom: {
        container: {
          gap: '8px',
          m: '0 !important',
          alignItems: 'center',
        },
        input: {
          w: '296px',
          m: '0 !important',
        },
        datepicker: {
          p: '0 8px 8px 8px',
        },
      },
    },
    divider: {
      m: '0 !important',
    },
  };
};

// keyframes
const slideInAnimation = keyframes`
    from {
        top: 100%;
    }
    to {
        top: 168px
    }
`;

const slideOutAnimation = keyframes`
    from {
        top: 168px
    }
    to {
        top: 100%;
    }
`;

const slideInAnimationMobile = keyframes`
    from {
        bottom: -100%;
    }
    to {
        bottom: 0px;
    }
`;

const slideOutAnimationMobile = keyframes`
    from {
      bottom: 0px;
    }
    to {
      bottom: -100%;
    }
`;

const desktopStyles = {
  top: '168px',
};

const mobileStyles = {
  bottom: '0px',
};

export const animationStyles = {
  slideInAnimation,
  slideOutAnimation,
  slideInAnimationMobile,
  slideOutAnimationMobile,
  desktopStyles,
  mobileStyles,
};
