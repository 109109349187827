import React from 'react';

type IconCreditCardNotImportingProps = { w?: Number, h?: Number, color?: string };

function IconCreditCardNotImporting({ w, h, color }: IconCreditCardNotImportingProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.625" cy="15.625" r="1.375" stroke={color} />
      <path
        d="m17.2505 17.5492 3-2.9508m0 0 3 2.9508m-3-2.9508V20.5m2.4995-9.8361H3.25m19.5005 1.7214V8.20088c0-.65167 0-.9775-.0751-1.24375-.1889-.66973-.7123-1.19315-1.3821-1.38203-.2662-.07509-.5918-.07509-1.2428-.07509v0s-8.2889-.00002-13.60004 0h-.05234c-1.07058 0-1.60587.00001-2.01837.19948-.40592.1963-.73351.5239-.92981.92982-.19948.4125-.19948.94775-.19948 2.01824V16.123c0 .8164 0 1.2246.11726 1.5517.20182.5632.64486 1.0062 1.208 1.208C4.9029 19 5.31095 19 6.12705 19h7.12345"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8.875" cy="15.625" r="1.375" stroke={color} />
      <path d="M1.26514.734863 23.5 23.5" stroke={color} />
    </svg>
  );
}

IconCreditCardNotImporting.defaultProps = {
  w: 25,
  h: 25,
  color: 'currentColor',
};

export default IconCreditCardNotImporting;
