import React from 'react';
import { Text, Stack } from '@chakra-ui/react';
import { BaselaneButton, BaselaneDrawer } from '@shared/components';
import IconCheckCircleOutline from '@icons/legacy/IconCheckCircleOutline';
import customTheme from '@core/theme';
import {
  containerStyles,
  successIconContainerStyles,
  textStyles,
} from './styles/subAccountCreatedScreen.styles';

type VirtualAccountCreatedScreenProps = {
  onClose: Function,
  refetchBankSummary: Function,
  showSubAccountCreatedSuccessScreen: boolean,
  setShowSubAccountCreatedSuccessScreen: Function,
};

const VirtualAccountCreatedScreen = ({
  onClose,
  refetchBankSummary,
  showSubAccountCreatedSuccessScreen,
  setShowSubAccountCreatedSuccessScreen,
}: VirtualAccountCreatedScreenProps) => {
  const { DrawerBody, DrawerFooter } = BaselaneDrawer;

  const handleCloseButtonClick = () => {
    if (showSubAccountCreatedSuccessScreen) {
      setShowSubAccountCreatedSuccessScreen(false);
    }
    onClose();
    refetchBankSummary();
  };

  return (
    <>
      <DrawerBody>
        <Stack {...containerStyles}>
          <Stack align="center" spacing={4}>
            <Stack {...successIconContainerStyles}>
              <IconCheckCircleOutline color={customTheme.colors.green['800AA']} h={25} w={25} />
            </Stack>
            <Stack align="center" spacing={0.4}>
              <Text {...textStyles}>Thank you.</Text>
              <Text {...textStyles}>Your Virtual Account has been created.</Text>
            </Stack>
          </Stack>
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={handleCloseButtonClick}
        >
          Close
        </BaselaneButton>
      </DrawerFooter>
    </>
  );
};

export default VirtualAccountCreatedScreen;
