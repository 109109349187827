import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HStack } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { BaselaneBanner, BaselaneButton, BaselaneButtonIcon } from '@shared/components';
import { IconX } from '@icons';
import { TRANSACTIONS } from '@routes';
import { READ_TRANSACTION_ALERTS } from './queries/alerts';

const TransactionImportBanner = ({ id, refetch }: { id: String, refetch: Function }) => {
  const [readAlert] = useMutation(READ_TRANSACTION_ALERTS);
  const navigate = useNavigate();

  const dismissBanner = (onComplete: Function) => {
    readAlert({ variables: { id } })
      .then(() => refetch())
      .finally(() => {
        if (onComplete && typeof onComplete === 'function') onComplete();
      });
  };

  const dismissBannerThenNavigate = () => {
    dismissBanner(() => {
      navigate(TRANSACTIONS);
    });
  };

  const rightElement = (
    <HStack>
      <BaselaneButton variant="filled" palette="primary" onClick={dismissBannerThenNavigate}>
        Categorize Transactions
      </BaselaneButton>
      <BaselaneButtonIcon
        icon={<IconX />}
        variant="transparent"
        palette="neutral"
        onClick={dismissBanner}
      />
    </HStack>
  );

  return (
    <BaselaneBanner
      color="blue"
      title="NEW TRANSACTIONS IMPORTED!"
      body="Categorize them to make sure that your Dashboard is up to date."
      rightElement={rightElement}
    />
  );
};

export default TransactionImportBanner;
