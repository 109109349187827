import React from 'react';
import { Box, Spacer, Text, Flex, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { useField, Field } from 'formik';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import BaselaneFormErrorMessage from '@core/components/Shared/components/BaselaneForm/BaselaneFormErrorMessage';
import { T1WithTitleDropdown, BaselaneInput } from '@shared/components';
import DropDownDisplayInput from '@shared/components/TransferFunds/components/Body/DropDownDisplayInput';
import formatCurrency from '@core/utils/formatCurrency';
import { Highlighted } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import CheckScanner from './CheckScanner';

const ScanACheck = () => {
  const { bankAccountsById, formattedBaselaneAcc } = useBankAccountsActive();
  const toDropdownList = formattedBaselaneAcc.map((acc) => {
    const items = acc?.items.filter((item) => item.accountSubType !== 'savings');
    return {
      ...acc,
      items,
    };
  });

  const frontOfCheckField = useField('frontOfCheck');
  const setFrontOfCheck = frontOfCheckField[2].setValue; // At position 2, the helpers of fields are returned. More: https://formik.org/docs/api/useField#reference

  const backOfCheckField = useField('backOfCheck');
  const setBackOfCheck = backOfCheckField[2].setValue;

  const accountIdField = useField('accountId');
  const setAccountId = accountIdField[2].setValue;

  const renderAccountDropdownItemWithBalance = ({ item, search }) => {
    return (
      <Stack gap={0} direction="row" justifyContent="space-between" width="100%">
        <Text color="inherit">
          <Highlighted text={item?.name ?? item?.nickName} highlight={search} />
        </Text>
        <Text color="inherit">{formatCurrency(item?.availableBalance ?? 0).inDollars}</Text>
      </Stack>
    );
  };

  return (
    <Box>
      <Flex>
        <Text textStyle="md-heavy">Scan a check</Text>
        <Spacer />
        <Text textStyle="xs">Step 2 of 4</Text>
      </Flex>

      <Box mb={2}>
        {/* Front and back of check */}
        <CheckScanner
          frontImage={frontOfCheckField[0].value}
          backImage={backOfCheckField[0].value}
          onCheckFrontImageChange={(image) => setFrontOfCheck(image)}
          onCheckBackImageChange={(image) => setBackOfCheck(image)}
        />
      </Box>

      {/* Amount */}
      <Field name="amount">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.amount && form.touched.amount} isRequired>
            <FormLabel>Check amount</FormLabel>
            <BaselaneInput {...field} placeholder="" prefix="$" type="number" size="lg" />
            <BaselaneFormErrorMessage isInvalid={form.errors.amount && form.touched.amount}>
              {form.errors.amount}
            </BaselaneFormErrorMessage>
          </FormControl>
        )}
      </Field>

      {/* Account */}
      <Field name="accountId">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.accountId && form.touched.accountId} isRequired>
            <FormLabel htmlFor="Account">Account</FormLabel>
            <T1WithTitleDropdown
              data={toDropdownList}
              hasError={form.errors.accountId && form.touched.accountId}
              additionalProps={{
                id: 'accountId',
              }}
              classNames={['input-form-lg', 'is-full-width', 'account']}
              searchTerm={['name', 'nickName']}
              showValueByFields={['name', 'nickName']}
              title="Account"
              itemRenderer={renderAccountDropdownItemWithBalance}
              handleSubmit={setAccountId}
              isMulti={false}
              hasFilterWrapper={false}
              hasCheckboxes={false}
              showDivider
              parentId="drawer-body"
              selectedItem={bankAccountsById[field.value]}
              CustomDisplayInput={DropDownDisplayInput}
            />
            <BaselaneFormErrorMessage isInvalid={form.errors.accountId && form.touched.accountId}>
              {form.errors.accountId}
            </BaselaneFormErrorMessage>
          </FormControl>
        )}
      </Field>
    </Box>
  );
};

export default ScanACheck;
