import {
  borderSimpleColors,
  borderStatusColors,
  borderDisabledColors,
  borderStatusActiveColors,
  borderSelectableColors,
  textSimpleColors,
  textStatusColors,
  textStatusDisabledColors,
  hoverSimpleBgColors,
  hoverStatusBgColors,
  activeSimpleBgColors,
  activeStatusBgColors,
  selectedBgColors,
} from './tagColorPresets';

export const getBorderColor = ({
  color,
  isDisabled,
  isSimpleChip,
  isActive = false,
  isSelectable = false,
}) => {
  const simpleOutline = isSelectable ? borderSelectableColors[color] : borderSimpleColors[color];
  const statusOutline = isActive ? borderStatusActiveColors[color] : borderStatusColors[color];
  const outline = isSimpleChip ? simpleOutline : statusOutline;

  return isDisabled ? borderDisabledColors[color] : outline;
};

export const getTextColor = ({ color, isDisabled, isSimpleChip }) => {
  const simpleText = textSimpleColors[color];
  const statusText = textStatusColors[color];
  const txt = isSimpleChip ? simpleText : statusText;

  return isDisabled ? textStatusDisabledColors[color] : txt;
};

export const getHoverBgColor = ({ color, isSimpleChip, isSelected = false }) => {
  const simpleHover = isSelected ? selectedBgColors[color] : hoverSimpleBgColors[color];
  const statusHover = hoverStatusBgColors[color];

  return isSimpleChip ? simpleHover : statusHover;
};

export const getActiveBgColor = ({ color, isSimpleChip, isSelected = false }) => {
  const simpleActive = isSelected ? selectedBgColors[color] : activeSimpleBgColors[color];
  const statusActive = activeStatusBgColors[color];

  return isSimpleChip ? simpleActive : statusActive;
};

export const getSharedStyles = ({
  isDisabled,
  color,
  isSimpleChip,
  isSelectable = false,
  isSelected = false,
}) => {
  return {
    borderColor: isSelected
      ? 'transparent'
      : getBorderColor({ color, isDisabled, isSimpleChip, isSelectable, isSelected }),
    color: isSelected ? 'white' : getTextColor({ color, isDisabled, isSimpleChip }),
    ...(isSelected ? { background: selectedBgColors[color] } : {}),
  };
};
