import React from 'react';

function IconOtherTransfers({ w, h, color }: { w?: Number, h?: Number, color?: String }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0192 3.50096C19.7431 3.50393 19.5217 3.73018 19.5247 4.00631C19.5276 4.28244 19.7539 4.50387 20.03 4.50091L20.0192 3.50096ZM27.3152 5.6544L27.0944 6.10305V6.10305L27.3152 5.6544ZM33.0563 10.4118L32.6562 10.7117V10.7117L33.0563 10.4118ZM35.7115 17.6475C35.9371 17.8068 36.2491 17.7531 36.4084 17.5275L39.0047 13.8521C39.164 13.6265 39.1104 13.3145 38.8848 13.1552C38.6593 12.9959 38.3473 13.0496 38.1879 13.2751L35.8801 16.5422L32.613 14.2344C32.3875 14.075 32.0755 14.1287 31.9161 14.3543C31.7568 14.5798 31.8105 14.8918 32.036 15.0511L35.7115 17.6475ZM19.4015 36.4998C19.6775 36.508 19.9079 36.291 19.9162 36.0149C19.9244 35.7389 19.7074 35.5085 19.4313 35.5002L19.4015 36.4998ZM12.2003 34.0517L12.4391 33.6124L12.2003 34.0517ZM4.30505 21.7274C4.08626 21.5589 3.77231 21.5997 3.60384 21.8185L0.858372 25.3839C0.689896 25.6027 0.730687 25.9167 0.949481 26.0851C1.16827 26.2536 1.48222 26.2128 1.65069 25.994L4.09111 22.8247L7.26039 25.2652C7.47919 25.4336 7.79313 25.3928 7.96161 25.1741C8.13008 24.9553 8.08929 24.6413 7.8705 24.4728L4.30505 21.7274ZM20.03 4.50091C22.4796 4.47456 24.9002 5.02359 27.0944 6.10305L27.5359 5.20575C25.2008 4.05701 22.6253 3.47294 20.0192 3.50096L20.03 4.50091ZM27.0944 6.10305C29.2887 7.1825 31.1943 8.76174 32.6562 10.7117L33.4563 10.1119C31.8997 8.03548 29.871 6.3545 27.5359 5.20575L27.0944 6.10305ZM32.6562 10.7117C34.1181 12.6617 35.0949 14.9273 35.5072 17.3239L36.4928 17.1543C36.0535 14.6014 35.013 12.1883 33.4563 10.1119L32.6562 10.7117ZM19.4313 35.5002C16.9828 35.427 14.5869 34.7801 12.4391 33.6124L11.9614 34.4909C14.2473 35.7337 16.7965 36.4219 19.4015 36.4998L19.4313 35.5002ZM12.4391 33.6124C10.2913 32.4447 8.45235 30.7894 7.07207 28.7816L6.24801 29.3481C7.71786 31.4861 9.67562 33.2482 11.9614 34.4909L12.4391 33.6124ZM7.07207 28.7816C5.69179 26.7738 4.80913 24.4704 4.49583 22.0591L3.50417 22.188C3.83792 24.7567 4.77816 27.2101 6.24801 29.3481L7.07207 28.7816Z"
        fill="#000619"
      />
      <circle cx="22" cy="22" r="11" fill={color} />
      <circle cx="20" cy="20" r="10.5" stroke="#000619" />
      <path
        d="M16 22.4615C16 23.6511 17.1939 24.6154 18.6667 24.6154H21.1429C22.7208 24.6154 24 23.5822 24 22.3077C24 21.0332 22.7208 20 21.1429 20H18.8571C17.2792 20 16 18.9668 16 17.6923C16 16.4178 17.2792 15.3846 18.8571 15.3846H21.3333C22.8061 15.3846 24 16.3489 24 17.5385M20 14V15.3846M20 24.6154V26"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
IconOtherTransfers.defaultProps = {
  w: 40,
  h: 40,
  color: '#8AD95F',
};

export default IconOtherTransfers;
