import React from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Stack, Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import {
  drawerLabel2Styles,
  drawerLabelStyles,
  drawerSectionStyles,
  drawerValue2Styles,
} from './styles/cardDetailsDrawer.styles';

type LinkedAccountSectionProps = {
  masterAccountName: string,
  accountName: string,
  bankAccountNumber: string,
  bankAccountBalance: string,
};

const LinkedAccountSection = ({
  masterAccountName,
  accountName,
  bankAccountNumber,
  bankAccountBalance,
}: LinkedAccountSectionProps) => {
  const balance = isMobile ? '' : `| Balance ${formatCurrency(bankAccountBalance).inDollars}`;

  return (
    <Box {...drawerSectionStyles}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Text {...(isMobile ? drawerLabelStyles : drawerLabel2Styles)}>Linked Bank Account</Text>
        <Stack alignItems="flex-end" spacing={0}>
          {masterAccountName && <Text {...drawerLabel2Styles}>{masterAccountName}</Text>}
          <Text {...drawerValue2Styles}>
            {accountName} - {bankAccountNumber?.slice(-4)} {balance}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LinkedAccountSection;
