export const customFontStyles = {
  height: 'auto',
  p: '0',
  verticalAlign: 'top',
  textStyle: 'xs',
  color: 'brand.neutral.600',
};

export const customBorderStyles = {
  border: 0,
  alignItems: 'flex-start',
};

export const learnMoreModalStyles = {
  modal: {
    title: {
      textStyle: 'headline-2xl',
      color: 'brand.neutral.900',
      mt: '0',
      p: '0',
    },
    mobileTitle: {
      textStyle: 'headline-xl',
      color: 'brand.neutral.900',
      mt: '0',
      p: '0',
    },
    content: {
      p: { lg: '32px', base: '16px 12px' },
      maxW: '636px',
      overflow: 'auto',
      margin: 'auto',
      w: {
        xxs: 'calc(100% - 20px)',
        xs: 'calc(100% - 20px)',
        sm: 'calc(100% - 20px)',
        md: 'calc(100% - 20px)',
        lg: '636px',
      },
      borderWidth: '1px',
      borderColor: 'brand.darkBlue.150',
      textAlign: 'left',
      mt: '20px',
      position: 'relative',
    },
    contentMobile: {
      maxW: '98%',
    },
    body: { p: '0', mt: '0' },
  },
  content: {
    top: {
      container: {
        gap: '0',
        position: 'relative',
        mt: '20px',
        alignItems: 'start!important',
      },
      title: {
        textStyle: 'headline-xs',
        color: 'brand.neutral.900',
        background: '#FFFFFF',
        position: 'relative',
        display: 'inline-flex',
        pr: '4px',
        zIndex: '3',
      },
      borderTitle: {
        border: '0',
        height: '1px',
        background: 'brand.neutral.200',
        position: 'absolute',
        top: '13px',
        width: '100%',
      },
      description: {
        textStyle: 'sm',
        color: 'brand.neutral.600',
        m: '0 !important',
        alignItems: 'start',
      },
    },

    disclaimer: {
      textStyle: 'xs',
      color: 'brand.neutral.600',
      m: '12px 0 0 0 !important',
    },
  },
  overview: {
    container: {
      mt: '20px',
      mb: '20px',
      position: 'relative',
    },
    mobileContainer: {
      mt: '16px',
      mb: '20px',
      position: 'relative',
    },
    title: {
      textStyle: 'headline-xs',
      color: 'brand.neutral.600',
      background: '#FFFFFF',
      position: 'relative',
      display: 'inline-flex',
      pr: '4px',
      zIndex: '3',
    },
    borderTitle: {
      border: '0',
      height: '1px',
      background: 'brand.neutral.200',
      position: 'absolute',
      top: '15px',
      width: '100%',
    },

    card: {
      outerContainer: { m: '16px 0 0 0!important', h: '60px' },
      mobileOuterContainer: { m: '16px 0 0 0!important', h: '64px' },
      container: {
        display: 'flex',
        m: '0!important',
        alignItems: 'flex-start',
      },
      content: {
        ...customFontStyles,
        m: '0px!important',
      },
      firstItem: {
        pl: '0',
        mb: '20px',
        alignItems: 'flex-start',
      },
      footer: {
        container: {
          mt: '0!important',
        },
        lineA: {
          textStyle: 'headline-lg',
          color: 'brand.neutral.900',
          m: '4px  0!important',
          display: 'block',
        },
        lineAMobile: {
          textStyle: 'headline-md',
          color: 'brand.neutral.900',
          m: '4px  0!important',
          display: 'block',
        },
        lineB: {
          textStyle: 'xs',
          color: 'brand.neutral.600',
          mt: '4px!important',
        },
      },
    },
  },
};
