import { ONBOARDING_CARDS } from '@core/components/OnboardingTriage/helpers/onboardingTriageHelper';
import bookkeepingImage from '@pages/UnifiedBookkeepingAnalytics/assets/bookkeepingGraphic.svg';
import { UNIFIED_RENT_COLLECTION, UNIFIED_LANDLORD_BANKING } from '@routes';

import rentCollectionImage from '../assets/rentCollectionGraphic.svg';
import landlordBankingImage from '../assets/landlordBankingGraphic.svg';

export const cleanUpStorage = () => {
  localStorage.removeItem('onboarding-selection');
  sessionStorage.removeItem('banksToAutoTag');
};

export const handleUserUpdate = async ({
  user: rawUser,
  refetchUser,
  updateUser,
  isOnboardingCompleted,
}) => {
  let user;
  if (!rawUser?.firstName && !rawUser?.lastName) {
    user = {
      ...(rawUser?.firstName && { firstName: rawUser?.firstName }),
      ...(rawUser?.lastName && { firstName: rawUser?.lastName }),
      metadata: rawUser?.metadata,
      businessProfile: {
        dbaEmail: rawUser?.businessProfile?.dbaEmail,
        dbaPhoneNumber: rawUser?.businessProfile?.dbaPhoneNumber,
      },
    };
  } else {
    user = rawUser;
  }

  const updatedUser = await updateUser({
    variables: { ...user, onboardingCompleted: isOnboardingCompleted },
  }).catch((err) => console.log(err));
  if (updatedUser && updatedUser.data) await refetchUser();
  return updatedUser;
};

export const handleFinishLater = async ({
  user,
  refetchUser,
  updateUser,
  setFlowTrue,
  handleRouteChange = () => {},
  isOnboardingCompleted = true,
  removeSelection = true,
}) => {
  if (setFlowTrue) setFlowTrue();

  const updatedUser = await handleUserUpdate({
    user,
    refetchUser,
    updateUser,
    isOnboardingCompleted,
  }).catch((err) => console.log(err));

  if (updatedUser && updatedUser.data) {
    // clean up
    if (removeSelection) {
      cleanUpStorage();
    }

    handleRouteChange();
  }
};

export const handleGetUserInfo = (user, selection, pathname = '') => {
  const metadata = {
    ...(user?.metadata ?? {}),
    onboardingTriageSelection: null,
  };

  if (selection === 'rent_collection' || pathname === UNIFIED_RENT_COLLECTION) {
    metadata.onboardingTriageSelection = 'rentCollection';
  } else if (selection === 'landlord_banking' || pathname.includes(UNIFIED_LANDLORD_BANKING)) {
    metadata.onboardingTriageSelection = 'banking';
  } else {
    metadata.onboardingTriageSelection = 'bookkeeping';
  }

  return { ...user, metadata };
};

export const handleRouteChange = (navigate, selection) => {
  const { linkRoute } = ONBOARDING_CARDS.find((card) => card.segmentEvent === selection);
  navigate(linkRoute);
};

export const getImages = (selection, imagePlacement) => {
  const images = {
    rightIllustration: {
      rent_collection: rentCollectionImage,
      landlord_banking: landlordBankingImage,
      bookkeeping: bookkeepingImage,
    },
  };

  return images[imagePlacement][selection];
};

export const getCTABtnText = (selection) => {
  const text = {
    rent_collection: 'Set up rent collection',
    landlord_banking: 'Open banking account',
    bookkeeping: 'Set up bookkeeping & analytics',
  };

  return text[selection];
};

export const setSelection = (metadata) => {
  const { onboardingTriageSelection = null } = metadata || {};

  const search = localStorage.getItem('onboarding-selection');

  if (search) {
    return search;
  }

  switch (onboardingTriageSelection) {
    case 'rentCollection':
      return 'rent_collection';
    case 'banking':
      return 'landlord_banking';
    case 'bookkeeping':
      return 'bookkeeping';
    default:
      return null;
  }
};
