import React, { useContext } from 'react';
import { HStack, Text, Th } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import BaselaneResponsiveTableContext from './BaselaneResponsiveTableContext';
import {
  defaultHeadingStyles,
  defaultContentContainerStyles,
  defaultTitleTextStyles,
} from './styles/header.styles';

type BaselaneResponsiveTableHeadingProps = {
  /**
   * Used to grab information that is defined in config of the table.
   */
  index: Number,
  /**
   * A text, specified to be the content of the heading.
   */
  title?: String,
  /**
   * If a heading were to have more than just a text, children can be used instead of
   * just passing title.
   */
  children?: React.Node,
  justifyContent?: String,
};

/**
 * Renders a single heading of the table.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveTableHeading = ({
  index,
  title,
  children,
  justifyContent,
  ...rest
}: BaselaneResponsiveTableHeadingProps) => {
  const { columns } = useContext(BaselaneResponsiveTableContext);

  const configStyle = { ...columns?.[index]?.styles };
  const { header: { container: headerContainerStyles = {}, title: titleTextStyles = {} } = {} } =
    configStyle ?? {};

  let configContentContainerStyle = {};
  if (configStyle?.contentContainerStyles) {
    configContentContainerStyle = { ...configStyle.contentContainerStyles };
    delete configStyle.contentContainerStyles;
  }

  return (
    <Th {...{ ...defaultHeadingStyles, ...configStyle, ...rest }} key={uuidv4()}>
      <HStack
        {...{
          ...defaultContentContainerStyles,
          // apply the same content alignment as the cells will use
          justifyContent: justifyContent ?? configContentContainerStyle.justifyContent,
          ...headerContainerStyles,
        }}
      >
        {title && <Text {...{ ...defaultTitleTextStyles, ...titleTextStyles }}>{title}</Text>}
        {children}
      </HStack>
    </Th>
  );
};

BaselaneResponsiveTableHeading.defaultProps = {
  children: null,
  title: null,
  justifyContent: null,
};

export default BaselaneResponsiveTableHeading;
