import {
  UNIFIED_LB_PERSONAL_INFO,
  UNIFIED_LB_HOME_ADDRESS,
  UNIFIED_LB_BUSINESS_INFO,
  UNIFIED_LB_SSN,
} from '@routes';

export type MenuState = {
  label: string,
  id: number,
  isValid: boolean,
  name: string,
};

export const initialMenuState = (completedTags) => {
  return [
    {
      label: 'Personal Details',
      id: 1,
      isValid: completedTags?.includes('PERSONAL_INFORMATION') || false,
      name: 'PERSONAL_INFORMATION',
      route: UNIFIED_LB_PERSONAL_INFO,
    },
    {
      label: 'Home Address',
      id: 2,
      isValid: completedTags?.includes('HOME_ADDRESS') || false,
      name: 'HOME_ADDRESS',
      route: UNIFIED_LB_HOME_ADDRESS,
    },
    {
      label: 'Business Information',
      id: 3,
      isValid: completedTags?.includes('BUSINESS_INFORMATION') || false,
      name: 'BUSINESS_INFORMATION',
      route: UNIFIED_LB_BUSINESS_INFO,
    },
    {
      label: 'Social Security Number',
      id: 4,
      isValid: completedTags?.includes('SOCIAL_SECURITY_NUMBER') || false,
      name: 'SOCIAL_SECURITY_NUMBER',
      route: UNIFIED_LB_SSN,
    },
  ];
};
