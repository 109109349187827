import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box, HStack, Spacer, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import {
  addAnotherLinkStyles,
  panelDescriptionStyles,
  panelFormStyles,
  panelHeaderContainerStyles,
  panelHeaderStyles,
  panelInlineLoaderStyles,
  tabPanelStyles,
} from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import { FormDescription, FormTitle } from '@shared/components/BaselaneFlow';
import SlLoader from '@core/components/Loader';
import { BaselaneResponsiveCard } from '@shared/components/BaselaneResponsiveCard';
import {
  addressTextStyles,
  unitCardStyles,
} from '@shared/components/BaselaneResponsiveCard/styles/card.style';
import getBankLogo from '@core/components/BankBalances/helpers/bankBalances.helpers';
import LinkedAccountStatus from '@core/components/NativeBankingPage/MainContent/components/Accounts/components/LinkedAccountsDrawer/LinkedAccountStatus';
import { BaselaneBadge } from '@shared/components';
import AddBankAccount from '@core/components/AddBankAccount';
import { Icon16PlusCircle } from '@icons/16px';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import EmptyState from './EmptyState';
import FooterWrapper from '../FooterWrapper';
import { addBankAccountButtonStyles } from '../styles/addBankAccount.styles';

const BankAccountsView = () => {
  const {
    hasAccounts,
    isLoading,
    banks,
    footerVars,
    handleLinkAccountsSuccess,
    setIsLoading,
    accounts,
  } = useOutletContext();

  return (
    <Box {...tabPanelStyles} pb="100px">
      {hasAccounts ? (
        <>
          <FormTitle
            title="External accounts linked"
            textStyles={panelHeaderStyles(isMobile)}
            containerStyles={panelHeaderContainerStyles(isMobile)}
          />
          <FormDescription styles={panelDescriptionStyles(isMobile)}>
            Link more accounts from another bank below
          </FormDescription>
        </>
      ) : (
        <>
          <FormTitle
            title="Securely link your bank accounts"
            textStyles={panelHeaderStyles(isMobile)}
            containerStyles={panelHeaderContainerStyles(isMobile)}
          />
          <FormDescription styles={panelDescriptionStyles(isMobile)}>
            You will be able to open a Baselane Banking account later
          </FormDescription>
        </>
      )}

      <Box {...panelFormStyles}>
        {!hasAccounts && !isLoading && <EmptyState />}

        {isLoading && <SlLoader styles={panelInlineLoaderStyles()} />}

        {hasAccounts && !isLoading && (
          <Box>
            {accounts.map((account) => (
              <BaselaneResponsiveCard
                key={`account-card-${account.id}`}
                {...unitCardStyles(true)}
                padding="8px"
              >
                <HStack w="100%">
                  {getBankLogo(account.logo, account.plaidInstitutionName)}
                  <Text {...addressTextStyles} ml="8px" fontWeight="medium">
                    {account.accountName}
                  </Text>
                  <Spacer />
                  <LinkedAccountStatus
                    supportingTrx={account.transactionSupported}
                    supportingTransfer={account.transferSupported}
                    supportingRC={account.rentCollectionSupported}
                  />
                  <BaselaneBadge type="badge" color="green" text="connected" />
                </HStack>
              </BaselaneResponsiveCard>
            ))}
            <Box style={{ ...addAnotherLinkStyles }}>
              <AddBankAccount
                leftIcon={<Icon16PlusCircle />}
                type="link"
                variant="transparent"
                palette="neutral"
                size={isMobile ? 'xl' : 'lg'}
                titleText="Link accounts from another bank"
                banks={banks}
                handleSuccessFn={(res) => {
                  sendSegmentEvent('onboarding_bookeeping_click_link_accounts');
                  handleLinkAccountsSuccess(res);
                }}
                styles={addBankAccountButtonStyles}
                isDisabled={isLoading}
                onPlaidConfirmationClose={() => {
                  setIsLoading(true);
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <FooterWrapper {...footerVars} />
    </Box>
  );
};

export default BankAccountsView;
