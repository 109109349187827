import React from 'react';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { BaselaneOverview, BaselaneTitle, CurrencyText, EmptyText } from '@shared/components';
import isNumber from '@core/utils/validators/isNumber';
import isNil from '@core/utils/validators/isNil';
import IconSkeletonBar1 from '@icons/legacy/IconSkeletonBar1';
import { CASHFLOW_SUMMARY_QUERY } from '@core/apollo/queries';
import {
  currencyTextStyle,
  firstItemStyles,
  customBorderStyles,
  customFontStyles,
} from './styles/responsive.style';

function KPIs({
  hasNoData,
  isDashboard,
  filters,
}: {
  filters: any,
  hasNoData: boolean,
  isDashboard: boolean,
}) {
  const currentUrl = useLocation();
  const { pathname } = currentUrl;
  const title = pathname.includes('cashflow') ? 'Overview' : 'Overview - Last 6 Months';

  const { loading, error, data } = useQuery(CASHFLOW_SUMMARY_QUERY, {
    variables: { input: filters },
  });

  if (loading || error) return null;
  if (isNil(data?.cashFlow?.summary)) return null; // TODO: Till BE fixes the issue

  const { netCashFlow, totalExpenseAmount, totalRevenueAmount } = data.cashFlow.summary;
  const emptyAmountBox = () => (
    <Box>
      <Box display={!isDashboard || (isDashboard && !isMobile) ? 'block' : 'none'}>
        <EmptyText />
      </Box>
      <Box pt="8px" display={!isDashboard || (isDashboard && isMobile) ? 'none' : 'block'}>
        <IconSkeletonBar1 height="20" width="72" borderRadius="12px" />
      </Box>
    </Box>
  );

  const cashFlow = isNumber(netCashFlow) ? (
    <CurrencyText amount={netCashFlow} {...currencyTextStyle(isDashboard, isMobile)} />
  ) : (
    emptyAmountBox()
  );
  const revenue = isNumber(totalRevenueAmount) ? (
    <CurrencyText amount={totalRevenueAmount} {...currencyTextStyle(isDashboard, isMobile)} />
  ) : (
    emptyAmountBox()
  );
  const expenses = isNumber(totalExpenseAmount) ? (
    <CurrencyText amount={totalExpenseAmount} {...currencyTextStyle(isDashboard, isMobile)} />
  ) : (
    emptyAmountBox()
  );

  const cashflowOverview = [
    { title: 'Net Cash Flow', content: cashFlow },
    { title: 'Inflows', content: revenue },
    { title: 'Outflows', content: expenses },
  ];

  return (
    <Box w="100%">
      <Box display={!isMobile ? 'block' : 'none'}>
        <BaselaneTitle title={title} />
      </Box>
      <BaselaneOverview
        customFontStyles={customFontStyles(isDashboard, hasNoData, isMobile)}
        customBorderStyles={customBorderStyles(isDashboard, isMobile)}
        elements={cashflowOverview}
        firstItemStyles={firstItemStyles(isMobile)}
        hasNoData={hasNoData}
      />
    </Box>
  );
}

export default KPIs;
