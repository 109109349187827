import { groupBy } from 'lodash';
import { lastFourDigits } from '@core/utils/formatAccountNumber';
import formatCurrency from '../../../../utils/formatCurrency';

const specialErrorCodes = [
  'AUTH_NOT_SUPPORTED',
  'NAME_VERIFICATION_FAILED',
  'IDENTITY_PERMISSION_NOT_GIVEN',
  'COUNTER_PARTY_CREATED',
];

export const getBalanceHelper = (account) => {
  const { currentBalance, availableBalance, accountType } = account ?? {};
  let amount = availableBalance === null ? '' : availableBalance;
  if (accountType === 'credit') {
    amount = currentBalance === null ? '' : currentBalance;
  }

  return amount;
};

/**
 * This function is being used by Add Funds + Send Funds Dropdowns + in mobile bank view as well
 */
export const formatAccount = (account, isExternalAccount) => {
  const {
    accountName,
    accountNumber,
    name: bankAccountName,
    institution,
    amount,
    id,
    bankAccountId,
    nickName,
    isBankConnected,
    counterPartyConnectionHistoryCode: errorCode,
  } = account ?? {};

  const last4Digits = lastFourDigits(accountNumber);
  const formattedlast4Digits = last4Digits ? ` - ${last4Digits}` : '';

  /*
   * if bankAccountName does not already include a - then add last 4 digits to the end
   * if there is no accountNumber return empty string and if bankAccountName is also
   * null then also set it as empty string (OMEGA-1526)
   */
  const label =
    bankAccountName && bankAccountName.match(/-[ ]?\d/g)
      ? bankAccountName
      : `${bankAccountName ?? ''}${formattedlast4Digits}`;

  let groupName = isExternalAccount
    ? institution?.plaidInstitutionName
    : institution?.name || `Baselane ${institution?.id}`;

  if (groupName === 'manual') {
    groupName = 'Manually Added Accounts';
  }

  const name = bankAccountName ? label : `${accountName} - ${last4Digits}`;

  const formattedAccountData = {
    accLabel: isExternalAccount ? name : `${groupName} > ${name}`,
    bankLabel: isExternalAccount ? institution?.plaidInstitutionName : 'Baselane',
  };

  const formattedAmount = amount === 0 ? '0.00' : amount;

  let value = formattedAmount
    ? formatCurrency(amount).inDollars
    : formatCurrency(getBalanceHelper(account)).inDollars;

  if (amount === null) {
    value = '';
  }

  const institutionId = institution?.id;

  return {
    // id in transfers can be null to indicate fraud
    id: accountNumber + institutionId + bankAccountId,
    // actual id
    accountId: id,
    bankAccountId,

    isInstitutionAuthNotSupported: errorCode === 'AUTH_NOT_SUPPORTED',
    isNameCheckFailed: errorCode === 'NAME_VERIFICATION_FAILED',
    isOAuthNamePermissionMissing: errorCode === 'IDENTITY_PERMISSION_NOT_GIVEN',
    isAccountDisconnected: !isBankConnected,
    isGenericError:
      (errorCode && !specialErrorCodes.find((code) => code === errorCode)) ||
      (id === null && !errorCode),
    name,
    value,
    nickName: nickName ?? name,
    group: {
      name: groupName,
      id: institution?.id,
    },
    groupId: institution?.id,
    formattedAccountData,
  };
};

export const menuOptionsConverter = (data, isExternalAccount) => {
  const accountsWithoutSavings = data?.filter((acc) => acc.accountSubType !== 'savings');
  const formattedAccount = accountsWithoutSavings?.map((account) =>
    formatAccount(account, isExternalAccount)
  );

  const optionsByGroupId = groupBy(formattedAccount, 'groupId');
  const groupedOptions = [];
  Object.keys(optionsByGroupId)?.forEach((group) => {
    if (group !== 'undefined') {
      const [commonGroup] = optionsByGroupId[group];
      groupedOptions.push({
        title: commonGroup?.group?.name,
        items: optionsByGroupId[group],
      });
    }
  });

  return groupedOptions;
};
