export const iconContainerStyles = {
  alignItems: 'center',
  width: '28px',
  height: '28px',
  borderRadius: 'lg',
  justifyContent: 'center',
  borderStyle: 'dashed',
  borderWidth: '1px',
  borderColor: 'brand.neutral.600',
};

export const cardStyles = { display: 'flex', alignItems: 'center', gap: 1.5 };
