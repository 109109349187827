import React from 'react';
import { Flex } from '@chakra-ui/react';
import { inactiveClickableContainerStyles, inactiveContainerStyles } from './stepNumber.styles';

type StepNumberInactiveProps = {
  element: string | React.ReactElement,
  variant: string,
};

const StepNumberInactive = ({ variant, element }: StepNumberInactiveProps) => {
  const styles = variant?.includes('clickable')
    ? inactiveClickableContainerStyles
    : inactiveContainerStyles;

  return <Flex {...styles}>{element}</Flex>;
};

export default StepNumberInactive;
