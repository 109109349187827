// @flow
import { Text } from '@chakra-ui/react';
import React from 'react';

type Props = {
  children: string,
  fontSize?: string,
  fontStyle?: string,
  fontWeight?: string,
  lineHeight?: string,
  color?: string,
  styles?: Object,
};

function BaselaneText({
  children,
  styles,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  color,
}: Props): any {
  const defaultStyles = {
    fontSize,
    fontStyle,
    fontWeight,
    lineHeight,
    color,
  };
  return (
    <Text {...styles} {...defaultStyles}>
      {children}
    </Text>
  );
}

BaselaneText.defaultProps = {
  styles: null,
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '24px',
  color: 'brand.neutral.700',
};

export default BaselaneText;
