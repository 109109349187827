import React from 'react';
import { Stack, Tbody, Text } from '@chakra-ui/react';
import { IconRefresh } from '@icons/index';
import {
  BaselaneButton,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveTable,
  BaselaneResponsiveTableRow,
  BaselaneResponsiveTableRows,
} from '@shared/components';

import AccountGraphicElement from './AccountGraphicalElement';
import Loader from '../../Loader';

const config = {
  columns: [
    {
      styles: {
        display: 'table-cell',
        p: { base: '16px 12px', sm: '8px 12px' },
      },
    },
  ],
};
const textStyle = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  mt: '8px',
};
const listHeadingStyle = { ...textStyle, fontWeight: 'medium', m: '8px 0!important' };
const refreshStatusButtonStyles = { m: '8px 0 24px' };
const noteStyle = { textStyle: 'xs', mt: '24px' };

type BodyComponentProps = {
  banksThatNeedDataCollection: Array<Object>,
  onRefreshStatus: Function,
  isLoadingDataCollectionList: Boolean,
};

const BodyComponent = ({
  banksThatNeedDataCollection,
  onRefreshStatus,
  isLoadingDataCollectionList,
}: BodyComponentProps) => {
  if (isLoadingDataCollectionList) {
    return <Loader />;
  }

  return (
    <>
      <Text {...textStyle}>
        The below Baselane Banking account(s) require additional information to prevent account
        closure.
      </Text>
      <BaselaneButton
        {...{
          variant: 'outline',
          palette: 'neutral',
          size: 'md',
          styles: refreshStatusButtonStyles,
          onClick: onRefreshStatus,
          leftIcon: <IconRefresh />,
          isDisabled: isLoadingDataCollectionList,
        }}
      >
        Refresh status
      </BaselaneButton>
      <Text {...listHeadingStyle}>Select an account to continue</Text>
      <Stack id="table-wrapper">
        <BaselaneResponsiveTable id="accountsListTable" config={config}>
          <Tbody>
            <BaselaneResponsiveTableRows
              customRow
              items={banksThatNeedDataCollection}
              renderItem={({ bank, data }) => {
                const { name: bankName, unitAccount } = bank;
                const { url } = data.attributes;
                const applicationType = unitAccount?.applicationType || null;

                return (
                  /* Using a custom row, in order to set indicator and click handler */
                  <BaselaneResponsiveTableRow
                    onClick={() => {
                      window.open(url, '_blank', 'noopener');
                    }}
                    indicatorColor="red.400"
                  >
                    {/* Account  */}
                    <BaselaneResponsiveCellTitle
                      title={bankName}
                      graphicElement={<AccountGraphicElement applicationType={applicationType} />}
                      configIndex={0}
                    />
                  </BaselaneResponsiveTableRow>
                );
              }}
            />
          </Tbody>
        </BaselaneResponsiveTable>
      </Stack>
      <Text {...noteStyle}>
        Please submit information before Dec 15, 2023 to prevent account closure.
      </Text>
    </>
  );
};

export default BodyComponent;
