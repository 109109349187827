// @flow
import React from 'react';
import { Grid, GridItem, Text } from '@chakra-ui/react';
import * as _ from 'lodash';
import formatCurrency from '@core/utils/formatCurrency';
import isNumber from '@core/utils/validators/isNumber';
import getBankLogo from './helpers/bankBalances.helpers';
import type { Bank } from './types';
import { accountNameStyles, amountStyles, gridStyles } from './styles/bankBalancesList.styles';

type BankBalancesListRowProps = { bank: Bank, columns: string };

function getBaselaneBankBalance(account): number {
  const accountBalance = isNumber(account.availableBalance) ? account.availableBalance : 0;
  const subAccountBalance = _.sumBy(account.subAccounts, (o) => {
    return isNumber(o.availableBalance) ? o.availableBalance : 0;
  });
  return formatCurrency(accountBalance + subAccountBalance).rounded;
}

function BankBalancesListRow({ bank, columns }: BankBalancesListRowProps): any {
  const bankLogo = getBankLogo(bank.logo, bank.plaidInstitutionName);

  return (
    <Grid templateColumns={columns} {...gridStyles}>
      <GridItem>{bankLogo}</GridItem>
      <GridItem>
        {bank.plaidInstitutionName === 'Baselane' ? (
          <Text {...accountNameStyles}>{bank.name}</Text>
        ) : (
          bank.bankAccounts.map((account) => (
            // NOTE: Using N/A instead of EmptyText bc GridItem (<p>) cannot contain EmptyText(<p>) as a child
            <Text key={account.accountNumber} {...accountNameStyles}>
              {account.nickName || bank.plaidInstitutionName}
            </Text>
          ))
        )}
      </GridItem>
      <GridItem justifySelf="end" textAlign="right">
        {bank.plaidInstitutionName === 'Baselane'
          ? bank.bankAccounts.map((account) => (
              <Text key={account.accountNumber} {...amountStyles}>
                {getBaselaneBankBalance(account)}
              </Text>
            ))
          : bank.bankAccounts.map((account) => (
              <Text key={account.accountNumber} {...amountStyles}>
                {isNumber(account.availableBalance)
                  ? formatCurrency(account.availableBalance).rounded
                  : '\u2014'}
              </Text>
            ))}
      </GridItem>
    </Grid>
  );
}

export default BankBalancesListRow;
