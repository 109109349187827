import React from 'react';

type IconCashFlowProps = {
  w?: Number,
  h?: Number,
};

function IconCashFlow({ w = 14, h = 16 }: IconCashFlowProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} fill="none">
      <rect width="3" height="7" x=".5" y="8.5" stroke="currentColor" rx=".5" />
      <rect width="3" height="15" x="5.5" y=".5" stroke="currentColor" rx=".5" />
      <rect width="3" height="11" x="10.5" y="4.5" stroke="currentColor" rx=".5" />
    </svg>
  );
}

IconCashFlow.defaultProps = {
  w: 14,
  h: 16,
};

export default IconCashFlow;
