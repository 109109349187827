import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getQueryParams } from '@core/Services/Firebase/firebase';
import SlLoader from '@core/components/Loader';
import { UNIFIED_LANDLORD_BANKING, UNIFIED_LANDLORD_BANKING_SUCCESS } from '@routes';
import BanksContext from '@contexts/BanksContext';
import { GET_UNIT_APPLICATION } from './queries';
import UnifiedLandlordBankingFlow from './UnifiedLandlordBankingFlow';

function UnifiedLandlordBankingUI() {
  const navigate = useNavigate();
  const currLocation = useLocation();
  const { loading: banksLoading, SPBaselaneBankDraft, allConnectedAccounts } = useContext(
    BanksContext
  );
  const [finishedInitialLoading, setFinishedInitialLoading] = useState(false);

  // find local, be draft or url param draft id first
  const oldestDraftId = SPBaselaneBankDraft?.unitAccount?.id;
  const id = oldestDraftId || localStorage.getItem('applicationId');
  if (oldestDraftId) {
    localStorage.setItem('applicationId', id);
  }
  const [applicationId, setApplicationId] = useState(
    getQueryParams(currLocation, 'applicationId') ?? id
  );

  // get draft from be if existing
  const { loading, data, error: errors } = useQuery(GET_UNIT_APPLICATION, {
    variables: {
      id: applicationId,
    },
    skip: applicationId === null || applicationId === undefined,
  });

  const historyPathname = currLocation?.pathname;

  // Note: if the existing application was soft deleted, treat it as though there is no data
  const existingApplication =
    data?.userUnitAccount?.isHidden !== true ? data?.userUnitAccount : null;

  // Note: Pre-process data, so that if the user already selected "no website",
  // the website field will show N/A

  const website =
    existingApplication &&
    existingApplication?.website === null &&
    existingApplication?.completedTags?.includes('BUSINESS_INFORMATION')
      ? `N/A`
      : existingApplication?.website;

  const existingApplicationData = existingApplication
    ? {
        ...existingApplication,
        website,
      }
    : null;

  const localUAppId = localStorage.getItem('unitApplicationId');
  // remove any query parm for application id on first run
  useEffect(() => {
    const uAppId = data?.userUnitAccount?.unitApplicationId;
    const isNotDraftStatus =
      data?.userUnitAccount && data?.userUnitAccount?.unitApplicationStatus !== 'DRAFT';
    const isSoleProp =
      data?.userUnitAccount && data?.userUnitAccount?.applicationType === 'SoleProprietorship';

    // clean query param as it has been set in state
    if (getQueryParams(currLocation, 'applicationId')) {
      navigate(historyPathname, { replace: true });
    }

    if (!loading && (uAppId || localUAppId !== 'null') && isNotDraftStatus && isSoleProp) {
      navigate({
        pathname: UNIFIED_LANDLORD_BANKING_SUCCESS,
      });
    }

    if (errors) {
      setApplicationId(null);
      localStorage.removeItem('applicationId');
      navigate({ pathname: UNIFIED_LANDLORD_BANKING });
    }
  }, [data?.userUnitAccount?.unitApplicationId, localUAppId, currLocation.pathname, errors]);

  // make sure oldestDraft is found before returning app page below or new draft might be created
  useEffect(() => {
    if (!errors && !loading && !banksLoading) {
      // set permanently to true
      setFinishedInitialLoading(true);
    }
    if (oldestDraftId) {
      setApplicationId(oldestDraftId);
    }
  }, [errors, loading, banksLoading, oldestDraftId]);

  return finishedInitialLoading ? (
    <UnifiedLandlordBankingFlow
      {...{
        applicationId,
        setApplicationId,
        existingApplicationData,
        banks: allConnectedAccounts,
      }}
    />
  ) : (
    <SlLoader />
  );
}

export default UnifiedLandlordBankingUI;
