import { isMobile } from 'react-device-detect';

export const occupationStatusBadgeStyles = (occupied) => {
  return {
    textStyle: !isMobile ? 'md' : 'xs',
    marginLeft: !isMobile ? '16px' : '8px',
    background: occupied ? 'green.100' : 'red.200',
    color: occupied ? 'green.800AA' : 'red.500A',
    p: !isMobile ? '6px 12px' : '2px 8px 4px',
    height: !isMobile ? '32px' : '20px',
    borderRadius: !isMobile ? '4px' : '2px',
  };
};

export const occupationStatusBadgeFontStyles = () => ({
  fontSize: !isMobile ? 'md' : 'xs',
});
