import React, { forwardRef, Fragment } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
} from '@chakra-ui/react';
import { Icon16ChevronDown, Icon16ChevronUp } from '@icons/16px';
import ParentListItem from '../../ParentListItem';
import DropdownContentWrapper from '../../DropdownContentWrapper';

import {
  setIsSelectedItem,
  getChildCounterVisualStyles,
} from '../../helpers/dropdown.shared.helpers';
import { t2Search } from '../../utils/search';
import { parentTextStyles } from '../../styles/parentListItem.styles';
import {
  accordionButtonStyles,
  accordionItemChildContainerStyles,
  accordionItemChildStyles,
  accordionItemContainerStyles,
  accordionPanelStyles,
  accordionToggleContainerStyles,
  accordionToggleCounterContainerStyles,
  accordionToggleIconContainerStyles,
  itemContainerStyles,
} from '../../styles/accordion.styles';

type ListOfParentAndChildrenWithTitleProps = {
  options: Array<any>,
  parentItemRenderer: Function,
  childItemRenderer: Function,
  hasFilterWrapper: boolean,
  search: string,
  dropDown: Function,
  handleListItemClick: Function,
  selectedStagedOptions: Array,
  childrenSelectAllState: Object,
  showDivider: boolean,
};

const ListOfParentAndChildrenWithTitle = forwardRef(
  (
    {
      options,
      parentItemRenderer,
      childItemRenderer,
      hasFilterWrapper,
      search,
      handleListItemClick,
      selectedStagedOptions,
      dropDown,
      childrenSelectAllState,
      showDivider,
    }: ListOfParentAndChildrenWithTitleProps,
    ref
  ) => {
    const shouldOpenAccordion = { ...(search ? { index: [0] } : {}) };
    return (
      <DropdownContentWrapper hasFilterWrapper={hasFilterWrapper} ref={ref}>
        {options.map((option, pIndex) => {
          const availableItems = t2Search({ options: option.items, search });

          return (
            <ParentListItem
              styles={{ display: availableItems.length > 0 ? 'block' : 'none' }}
              key={`parent-${option.title}`}
              title={option.title}
              showDivider={showDivider}
              index={pIndex}
              listItem={
                <Box key={option.title} {...parentTextStyles}>
                  {option.title}
                </Box>
              }
            >
              {availableItems.reduce((acc, item, index, allItems) => {
                const { id, isChild, hasChildren } = item;
                const parentChildren = hasChildren
                  ? allItems.filter((cItem) => {
                      const { isChild: cIsChild } = cItem;

                      const [childParentId] = cItem.id.split('-');
                      return childParentId === id && cIsChild;
                    })
                  : [];

                if (isChild) {
                  return acc;
                }

                acc.push(
                  hasChildren ? (
                    <Accordion key={item.id} allowMultiple {...shouldOpenAccordion}>
                      <AccordionItem border="none">
                        {({ isExpanded }) => (
                          <>
                            <AccordionButton {...accordionButtonStyles}>
                              <HStack
                                tabIndex="-1"
                                className="element"
                                key={item.id}
                                id={item.id}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleListItemClick(item, e, dropDown);
                                }}
                                bgColor={
                                  setIsSelectedItem(item, selectedStagedOptions)
                                    ? 'brand.blue.50'
                                    : 'initial'
                                }
                                {...accordionItemContainerStyles}
                              >
                                {parentItemRenderer({ item, search })}
                              </HStack>

                              <HStack
                                {...accordionToggleContainerStyles(
                                  search,
                                  childrenSelectAllState?.[item.id]
                                )}
                              >
                                <HStack
                                  id="child-counter-visual"
                                  {...getChildCounterVisualStyles(
                                    childrenSelectAllState?.[item.id],
                                    isExpanded
                                  )}
                                  {...accordionToggleCounterContainerStyles}
                                >
                                  <HStack {...accordionToggleIconContainerStyles}>
                                    {isExpanded ? <Icon16ChevronUp /> : <Icon16ChevronDown />}
                                  </HStack>
                                </HStack>
                              </HStack>
                            </AccordionButton>
                            <AccordionPanel {...accordionPanelStyles} pl="16px">
                              {parentChildren.map((pChild) => (
                                <HStack key={pChild.id} {...accordionItemChildContainerStyles}>
                                  <HStack
                                    tabIndex="-1"
                                    className="element"
                                    key={item.id}
                                    id={item.id}
                                    onClick={(e) => {
                                      handleListItemClick(pChild, e, dropDown);
                                    }}
                                    bgColor={
                                      setIsSelectedItem(pChild, selectedStagedOptions)
                                        ? 'brand.blue.50'
                                        : 'initial'
                                    }
                                    {...accordionItemChildStyles}
                                  >
                                    {childItemRenderer({ item: pChild, search })}
                                  </HStack>
                                </HStack>
                              ))}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    <HStack key={item.id} {...itemContainerStyles}>
                      <HStack
                        tabIndex="-1"
                        className="element"
                        key={item.id}
                        id={item.id}
                        onClick={(e) => {
                          handleListItemClick(item, e, dropDown);
                        }}
                        bgColor={
                          setIsSelectedItem(item, selectedStagedOptions)
                            ? 'brand.blue.50'
                            : 'initial'
                        }
                        {...accordionItemChildStyles}
                      >
                        {parentItemRenderer({ item, search })}
                      </HStack>
                    </HStack>
                  )
                );

                return acc;
              }, [])}
            </ParentListItem>
          );
        })}
      </DropdownContentWrapper>
    );
  }
);

export default ListOfParentAndChildrenWithTitle;
