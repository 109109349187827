// @flow
import React, { useState, useEffect, memo } from 'react';
import { BaselaneTimeline } from '@core/components/Shared/components';
import { getTimeline } from './helpers/invoiceTImeline.helpers';

type InvoiceTimelineProps = {
  timeline: Array<Object>,
};

function InvoiceTimeline({ timeline }: InvoiceTimelineProps) {
  const [defaultIndex, setDefaultIndex] = useState([-1]);
  const [isLoading, setIsLoading] = useState(true);

  const timelineSteps = getTimeline(timeline);

  useEffect(() => {
    setIsLoading(true);
    const indexOfExpandedItem = timelineSteps.map((step) => step.isExpandable).lastIndexOf(true);
    setDefaultIndex([indexOfExpandedItem]);
  }, [timeline]);

  useEffect(() => {
    setIsLoading(false);
  }, [defaultIndex]);

  return !isLoading && <BaselaneTimeline {...{ timelineSteps, defaultIndex }} />;
}

const InvoiceTimelineMemo = memo(InvoiceTimeline);

export default InvoiceTimelineMemo;
