import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import ElementsToCopy from '@shared/components/TransferFunds/components/EducationalDrawer/ElementsToCopy';
import { headingSectionTextStyles, subTextTextStyles } from '../styles/font.styles';

type AddAccountProps = {
  account: Object,
};

const AccountDetails = ({ account: accountDetails }: AddAccountProps) => {
  const unit = `${
    !accountDetails.legalAddress?.unit ? '' : `${accountDetails.legalAddress.unit}, `
  }`;

  const address = accountDetails.legalAddress
    ? `${accountDetails.legalAddress.street}, ${unit}${accountDetails.legalAddress.city},
    ${accountDetails.legalAddress.state} ${accountDetails.legalAddress.postalCode}`
    : '';

  const bankingAccountInfoList = [
    { label: 'Legal Account Name', key: 'legalBusinessName' },
    { label: 'Bank Name', key: 'bankName' },
    { label: 'Account Number', key: 'accountNumber' },
    { label: 'Bank Routing Number', key: 'routingNumber' },
    { label: 'Account Address', key: 'legalAddress' },
    { label: 'Bank Address', key: 'bankAddress' },
  ];

  const bankName = 'Thread Bank';
  const bankAddress = '210 E Main St, Rogersville, TN 37857';
  const bankingInfo = `These details can be used for domestic ACH/Wires`;

  return (
    <Stack spacing="0px">
      <Box>
        <Text {...headingSectionTextStyles}>Account Details</Text>
        <Text {...subTextTextStyles}>{bankingInfo}</Text>
      </Box>
      <ElementsToCopy
        newDesign
        bankingAccountInfoList={bankingAccountInfoList}
        selectedBaselaneAccount={{
          ...accountDetails,
          legalAddress: address,
          bankName,
          bankAddress,
        }}
      />
    </Stack>
  );
};

export default AccountDetails;
