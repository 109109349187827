function turnBaseModelArrayToMap(arr, key): { [id: string]: string } {
  const result = {};

  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i]?.subAccountName) {
      result[arr[i]?.id] = {
        name: arr[i]?.name,
        subAccountName: arr[i]?.subAccountName,
        accountNumber: arr[i]?.accountNumber,
      };
    } else if (key) {
      result[arr[i]?.id] = arr[i]?.[key];
    } else {
      result[arr[i]?.id] = arr[i]?.name;
    }
  }

  return result;
}

export default turnBaseModelArrayToMap;
