import process from 'process';

export const zendeskKey =
  process && process.env.REACT_APP_ZENDESK_KEY === undefined
    ? ''
    : process.env.REACT_APP_ZENDESK_KEY;

export const zendeskUserKey =
  process && process.env.REACT_APP_ZENDESK_USER_KEY === undefined
    ? ''
    : process.env.REACT_APP_ZENDESK_USER_KEY;

export const statsigClientKey =
  process && process.env.REACT_APP_STATSIG_CLIENT_KEY === undefined
    ? ''
    : process.env.REACT_APP_STATSIG_CLIENT_KEY;

export const serviceBellApiKey =
  process && process.env.REACT_APP_SERVICEBELL_API_KEY === undefined
    ? ''
    : process.env.REACT_APP_SERVICEBELL_API_KEY;

export const customerIOApiKey =
  process && process.env.REACT_APP_CUSTOMER_IO_API_KEY === undefined
    ? ''
    : process.env.REACT_APP_CUSTOMER_IO_API_KEY;

export const customerIOSiteId =
  process && process.env.REACT_APP_CUSTOMER_IO_SITE_ID === undefined
    ? ''
    : process.env.REACT_APP_CUSTOMER_IO_SITE_ID;

export const latchelPartnerKey =
  process && process.env.REACT_APP_LATCHEL_PARTNER_KEY === undefined
    ? ''
    : process.env.REACT_APP_LATCHEL_PARTNER_KEY;

export const firebaseWebKey =
  process && process.env.REACT_APP_FIREBASE_WEB_KEY === undefined
    ? 'eyJhcGlLZXkiOiIxIn0=' // random apiKey as backup
    : process.env.REACT_APP_FIREBASE_WEB_KEY;

export const achFeeEnabledFeatureFlag =
  process && process.env.REACT_APP_ACH_FEE_ENABLED_FEATURE_FLAG === undefined
    ? 'false'
    : process.env.REACT_APP_ACH_FEE_ENABLED_FEATURE_FLAG;
