export const indicatorStepText = (isNextStepActive, isFinalStepActive, indicatorStatus) => {
  let step = '1st Step';
  if (isNextStepActive) {
    step = '2nd Step';
  } else if (isFinalStepActive) {
    step = 'Last Step';
  }

  if (indicatorStatus === 'caution') {
    return 'Alert';
  }

  if (indicatorStatus === 'warning') {
    return 'Incomplete';
  }

  return step;
};

export const landlordBankingGuide = {
  firstStep: {
    step: 'first',
    message: 'Get started by opening a free Baselane Banking account',
    secondMessage: null,
    stepSectionHeading: 'Open Your Free Banking Account',
    stepSectionMessage:
      'Open your first Baselane Banking account by completing a short, 1 minute application.',
    ctaButtonText: 'Open Banking Account',
  },
  firstStep_DRAFT: {
    step: 'first_draft',
    message: 'Get started by opening a free Baselane Banking account',
    secondMessage: null,
    stepSectionHeading: 'Open Your Free Banking Account',
    stepSectionMessage:
      'Complete the application you started to open your first Baselane Banking account.',
    ctaButtonText: 'Continue Application',
  },
  // under review status
  firstStep_IN_PROGRESS: {
    step: 'first_in_progress',
    message: 'Your application has been submitted!',
    secondMessage: null,
    stepSectionHeading: 'Application Under Review',
    stepSectionMessage:
      'Your application may take up to 2 business days to process. We will share updates on your application status via email.',
    ctaButtonText: 'Explore Banking Page',
  },
  firstStep_AWAITING_DOCUMENTS: {
    step: 'first_awaiting_documents',
    message: 'We need some information to complete your application!',
    secondMessage: null,
    stepSectionHeading: 'Documents Required',
    stepSectionMessage:
      'You are almost there! We need some additional documents. Please click the button below to upload the required documents.',
    ctaButtonText: 'Go To Application',
    indicatorStatus: 'warning',
  },
  firstStep_DENIED: {
    step: 'first_denied',
    message: 'Unfortunately, your application was denied',
    secondMessage: null,
    stepSectionHeading: 'Application Denied',
    stepSectionMessage:
      'After reviewing your application, we are unable to open a Baselane Banking account at this time. Please contact support with questions.',
    ctaButtonText: 'Contact Us',
    indicatorStatus: 'caution',
    showCloseBtn: true,
  },
  nextStep: {
    step: 'next',
    message: 'Congratulations!',
    secondMessage: 'Your first Baselane Banking account is open and ready to go',
    stepSectionHeading: 'Add Funds To Your Account',
    stepSectionMessage:
      'Benefit from high APY, make mortgage or utility payments, and get integrated bookkeeping & reporting.',
    ctaButtonText: 'Add Funds',
  },
  nextStep_IN_PROGRESS: {
    step: 'next_in_progress',
    message: 'Your funds transfer has been initiated',
    secondMessage: null,
    stepSectionHeading: 'Your Funds are on the way',
    stepSectionMessage:
      'It may take up to 3 business days to clear your transfer. You can always check the status of the transfer by pressing button below.',
    ctaButtonText: 'Check Transfer Status',
  },
  finalStep: {
    step: 'final',
    message: 'Great! Your account has been funded and is ready to use',
    secondMessage: null,
    stepSectionHeading: 'Make Your First Purchase',
    stepSectionMessage:
      'Activate and use your Baselane Visa debit card to swipe, tap, or purchase online and start earning 1% cash back.',
    ctaButtonText: 'Go to Cards Page',
    showCloseBtn: true,
  },
};

export const rentCollectionGuide = {
  firstStep: {
    step: 'first',
    message: "Let's start by creating your Baselane Bank Account",
    secondMessage: null,
    stepSectionHeading: 'Create Your First Baselane Bank Account',
    stepSectionMessage:
      'Create your first Baselane bank account to... Sit imperdiet pretium sed sit adipiscing vestibulum mi justo.',
  },
  nextStep: {
    step: 'next',
    message: 'Congratulations!',
    secondMessage: 'You now have your first Baselane Bank Account',
    stepSectionHeading: 'Fund Your First Baselane Account',
    stepSectionMessage:
      'Add funds to your bank account to... Sit imperdiet pretium sed sit adipiscing vestibulum mi justo. Eu, elementum turpis quis molestie sed commodo pellentesqu.',
  },
  finalStep: {
    step: 'final',
    message: 'Great!',
    secondMessage: 'Your account has been funded.',
    stepSectionHeading: 'Make Your First Transaction',
    stepSectionMessage:
      'Make your first transaction to... Sit imperdiet pretium sed sit adipiscing vestibulum mi justo. Eu, elementum turpis quis molestie sed commodo pellentesque lacinia purus.',
  },
};

export const bookKeepingGuide = {
  firstStep: {
    step: 'first',
    message: "Let's start by creating your Baselane Bank Account",
    secondMessage: null,
    stepSectionHeading: 'Create Your First Baselane Bank Account',
    stepSectionMessage:
      'Create your first Baselane bank account to... Sit imperdiet pretium sed sit adipiscing vestibulum mi justo.',
  },
  nextStep: {
    step: 'next',
    message: 'Congratulations!',
    secondMessage: 'You now have your first Baselane Bank Account',
    stepSectionHeading: 'Fund Your First Baselane Account',
    stepSectionMessage:
      'Add funds to your bank account to... Sit imperdiet pretium sed sit adipiscing vestibulum mi justo. Eu, elementum turpis quis molestie sed commodo pellentesqu.',
  },
  finalStep: {
    step: 'final',
    message: 'Great!',
    secondMessage: 'Your account has been funded.',
    stepSectionHeading: 'Make Your First Transaction',
    stepSectionMessage:
      'Make your first transaction to... Sit imperdiet pretium sed sit adipiscing vestibulum mi justo. Eu, elementum turpis quis molestie sed commodo pellentesque lacinia purus.',
  },
};

const getLandlordBankingStep = ({
  whichStep,
  bankStatus,
  hasAddedFunds,
  transferInProgress,
  hasCompletedApplication,
}) => {
  const step = `${whichStep}_${bankStatus}`;

  if (hasAddedFunds) {
    return 'finalStep';
  }

  if (bankStatus === 'COMPLETED' || hasCompletedApplication) {
    if (transferInProgress > 0 && hasAddedFunds === 0) {
      return 'nextStep_in_progress';
    }
    return 'nextStep';
  }

  if (bankStatus === null) {
    return whichStep;
  }

  return step;
};

export const getGuide = ({ onboardingTriageSelection, landlordBankingInfo }) => {
  let whichStep = 'firstStep';
  let guide = landlordBankingGuide;

  if (onboardingTriageSelection === 'banking') {
    whichStep = getLandlordBankingStep({
      whichStep,
      ...landlordBankingInfo,
    });
  } else if (onboardingTriageSelection === 'rentCollection') {
    guide = rentCollectionGuide;
    // whichStep = '';
  } else if (onboardingTriageSelection === 'bookkeeping') {
    guide = bookKeepingGuide;
    // whichStep = '';
  }

  return guide[whichStep];
};
