export const formatBankAccountData = (account) => {
  const { id, importTransaction, autoTag, accountSubType, accountNumber, nickName } = account;

  const importTransactionEnabled = importTransaction && importTransaction.enabled;
  const importTransactionType =
    importTransaction && importTransaction.importTransactionType === null
      ? 'ALL'
      : importTransaction.importTransactionType;
  const updatedAt = importTransaction && importTransaction.updatedAt;
  const autoTagEnabled = autoTag && autoTag.enabled;
  const autoTagPropertyUnitId = autoTag && autoTag.propertyUnitId;
  const autoTagPropertyId = autoTag && autoTag.propertyId;
  const isInitialImportTransactionTypeNull = importTransaction.importTransactionType === null;

  const formattedData = {
    id,
    accountSubType,
    accountNumber,
    nickName,
    importTransaction: {
      enabled: importTransactionEnabled,
      importTransactionType,
      updatedAt,
    },
    autoTag: {
      enabled: autoTagEnabled,
      propertyId: autoTagPropertyId,
      propertyUnitId: autoTagPropertyUnitId,
    },
    isInitialImportTransactionTypeNull,
  };
  return formattedData;
};

export const handleValidation = (values) => {
  const { nickName } = values;
  const errors = {};

  if (!nickName || nickName?.trim() === '') {
    errors.nickName = 'Please enter account name';
  }

  return errors;
};
