import { gql } from '@apollo/client';

export const SEND_OTP = gql`
  mutation sendOtp($recipient: String) {
    sendOtp(recipient: $recipient) {
      sendCodeAttempts
      status
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation verifyOtp($recipient: String, $code: String, $operation: String) {
    verifyOtp(recipient: $recipient, code: $code, operation: $operation)
  }
`;
