import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';

import { BaselaneButton } from '@shared/components';
import { IllustrationBanking } from '@illustrations';

type FinishLaterBankingFirstPopupProps = {
  isOpen: boolean,
  onClose: Function,
};

const FinishLaterBankingFirstPopup = ({ isOpen, onClose }: FinishLaterBankingFirstPopupProps) => {
  const alertCancelRef = useRef(null);

  return (
    <AlertDialog
      leastDestructiveRef={alertCancelRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent p={4}>
        <Stack spacing={2}>
          <AlertDialogCloseButton />
          <Stack spacing={0.5}>
            <AlertDialogHeader p={0}>
              <Stack spacing={2.5}>
                <IllustrationBanking />
                <Heading size="headline-lg">Continue without Baselane Banking?</Heading>
              </Stack>
            </AlertDialogHeader>
            <AlertDialogBody p={0}>
              <Text textStyle="sm">
                Baselane Banking is built to integrate and save you time with rent collection and
                bookkeeping. It takes only 2 minutes to open a banking account.
              </Text>
            </AlertDialogBody>
          </Stack>
        </Stack>
        <AlertDialogFooter p={0} mt={3}>
          <Stack direction={{ md: 'row', base: 'column-reverse' }} w="100%" spacing={1.5}>
            <BaselaneButton
              size="md"
              variant="outline"
              palette="neutral"
              onClick={onClose}
              isFullWidth
            >
              Back to opening banking account
            </BaselaneButton>
            <BaselaneButton
              size="md"
              variant="filled"
              palette="primary"
              onClick={() => {
                onClose('skip');
              }}
              isFullWidth
            >
              Skip anyway
            </BaselaneButton>
          </Stack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default FinishLaterBankingFirstPopup;
