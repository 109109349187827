import React, { Fragment } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Stack,
  Text,
} from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { IconChevronDown, IconChevronUp, IconAddFunds } from '@icons';
import { BaselaneDivider } from '@shared/components';
import {
  mobileAccordionButtonStyles,
  mobileAccordionContentStyles,
  mobileAccordionIconContainerStyles,
  mobileAccordionItemStyles,
  mobileAccordionWrapperStyles,
  mobileDividerStyles,
} from '@core/components/GetStartedPage/styles/howToGuidesDrawer.styles';
import { linkStyles } from '@shared/styles/font.style';
import {
  accordionContentTextStyles,
  accordionQuestionTextStyles,
  bodyTextStyles,
  faqTextStyles,
  titleStyles,
} from '../../styles/emptyState.styles';

type EmptyStateDrawerContentProps = { emptyStateEducationalDrawerRef: any };

const EmptyStateDrawerContent = ({
  emptyStateEducationalDrawerRef,
}: EmptyStateDrawerContentProps) => {
  const { isMinXL } = getBreakPoints();

  const pairs = [
    {
      key: '1',
      header: (
        <Text {...accordionQuestionTextStyles}>
          Don&apos;t see an external account I already connected?
        </Text>
      ),
      content: (
        <Text {...accordionContentTextStyles}>
          Ensure that your external account&apos;s legal name matches your Baselane Banking
          account(s) name. External accounts with different legal names can&apos;t be used to add
          funds. Contact us if you need support.
        </Text>
      ),
    },
    {
      key: '2',
      header: (
        <Text {...accordionQuestionTextStyles}>
          Add funds from external account with different legal name?
        </Text>
      ),
      content: (
        <Box {...accordionContentTextStyles}>
          To add funds from an external account with a different legal name, initiate a wire
          transfer from your external account using your&nbsp;
          <Box
            as="span"
            onClick={() => {
              emptyStateEducationalDrawerRef.current.open();
            }}
            {...linkStyles}
          >
            Baselane Banking account & routing numbers
          </Box>
          .
        </Box>
      ),
    },
  ];

  return (
    <Stack spacing={isMinXL ? '48px' : '56px'}>
      <Box>
        <IconAddFunds />
        <Stack mt="12px">
          <Text {...titleStyles(isMinXL)}>Connect an External Account to Add Funds</Text>
          <Text {...bodyTextStyles(isMinXL)}>
            Connect an external account with the same legal name as your Baselane Banking account(s)
            to add funds.
          </Text>
        </Stack>
      </Box>

      <Stack spacing="16px">
        <Text {...faqTextStyles}>FAQs</Text>
        {/* This follows the implementation in HowToGuides for rent collection mobile */}
        <Accordion {...mobileAccordionWrapperStyles} allowMultiple>
          {pairs.map((item) => {
            return (
              <Fragment key={item.key}>
                <AccordionItem {...mobileAccordionItemStyles}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton {...mobileAccordionButtonStyles}>
                        {item.header}
                        <Box {...mobileAccordionIconContainerStyles}>
                          {isExpanded ? (
                            <IconChevronUp w={11} color="#3A4B5B" />
                          ) : (
                            <IconChevronDown w={11} color="#3A4B5B" />
                          )}
                        </Box>
                      </AccordionButton>
                      <AccordionPanel {...mobileAccordionContentStyles}>
                        {item.content}
                      </AccordionPanel>
                      <BaselaneDivider
                        styles={mobileDividerStyles(item.key, pairs.length, false)}
                      />
                    </>
                  )}
                </AccordionItem>
              </Fragment>
            );
          })}
        </Accordion>
      </Stack>
    </Stack>
  );
};

export default EmptyStateDrawerContent;
