import React from 'react';
import ListOfParentAndChildren from '@shared/components/BaselaneDropdown/components/T2/components/ListOfParentAndChildren';
import ListOfParentAndChildrenCheckboxes from './ListOfParentAndChildrenCheckboxes';
import DropdownSearchWrapper from '../../DropdownSearch/DropdownSearchWrapper';

type CustomDropdownProps = {
  isMulti: boolean,
  options: Array<any>,
  selectedStagedOptions: Array<any>,
  optionStyles: any,
  parentItemRenderer: Function,
  childItemRenderer: Function,
  showCustomCategories?: Boolean,
  dropdownElementRef: any,
  onListItemClick: Function,
  hasFilterWrapper: Boolean,
  title: String,
  searchTerm: Array<String>,
  search: String,
  setSearch: Function,
  searchRef: any,
  animationVariant?: String,
  scrollTop?: Number,
  hideSearch?: Boolean,
  hasCheckboxes: Boolean,
  setIsSelectedItem: Function,
  dropDown: Function,
  handleListItemClick: Function,
  handleSelectAll: Function,
  childrenSelectAllState: Object,
};

const CustomDropdown = ({
  isMulti,
  options,
  selectedStagedOptions,
  optionStyles,
  parentItemRenderer,
  childItemRenderer,
  showCustomCategories,
  dropdownElementRef,
  onListItemClick,
  hasFilterWrapper,
  title,
  searchTerm,
  search,
  setSearch,
  searchRef,
  animationVariant,
  scrollTop,
  hideSearch,
  hasCheckboxes,
  setIsSelectedItem,
  dropDown,
  handleListItemClick, // TODO: make function names accross dropdowns the same
  handleSelectAll,
  childrenSelectAllState,
}: CustomDropdownProps) => {
  return (
    <DropdownSearchWrapper
      {...{
        showCustomCategories,
        title,
        search,
        setSearch,
        searchRef,
        animationVariant,
        scrollTop,
        hideSearch,
        dropdownElementRef,
      }}
    >
      {hasCheckboxes ? (
        <ListOfParentAndChildrenCheckboxes
          ref={dropdownElementRef}
          {...{
            options,
            selectedStagedOptions,
            optionStyles,
            parentItemRenderer,
            childItemRenderer,
            onListItemClick,
            hasFilterWrapper,
            searchTerm,
            search,
            hideSearch,
            setIsSelectedItem,
            dropDown,
            handleListItemClick,
            handleSelectAll,
            childrenSelectAllState,
          }}
        />
      ) : (
        <ListOfParentAndChildren
          {...{
            isMulti,
            options,
            optionStyles,
            parentItemRenderer,
            childItemRenderer,
            dropdownElementRef,
            hasFilterWrapper,
            search,
            hideSearch,
            setIsSelectedItem,
            dropDown,
            selectedStagedOptions,
            handleListItemClick,
          }}
        />
      )}
    </DropdownSearchWrapper>
  );
};

CustomDropdown.defaultProps = {
  showCustomCategories: null,
  animationVariant: null,
  scrollTop: 0,
  hideSearch: false,
};

export default CustomDropdown;
