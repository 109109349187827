export const parentListItemStyles = (hasChildNode) => {
  return {
    pt: 1,
    pb: 1,
    display: hasChildNode ? 'block' : 'none',
  };
};

export const parentTextStyles = {
  textStyle: 'headline-xs',
};
