export const input = {
  mt: '8px',
};

export const errorContainer = {
  mt: '8px',
  gap: 0,
};

export const errorText = {
  color: 'red.800AA',
  lineHeight: '12px',
  fontSize: 'xs',
  ml: '8px',
  mt: 1,
  display: 'block',
};

export const transferDropdownErrorText = {
  ...errorText,
  mt: 0,
};
