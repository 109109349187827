export const infoBoxConfig = [
  {
    id: 1,
    step: '1',
    title: 'You Invite A Landlord',
    description: 'Share your referral link. Make sure they use your link to sign up.',
  },
  {
    id: 2,
    step: '2',
    title: 'They Join',
    description:
      'Your referral must open a Baselane Banking account and collect rent into that account via Baselane.',
  },

  {
    id: 3,
    step: '3',
    title: 'You Both Get Paid',
    description: 'You both receive a $100 bonus into your Baselane Banking accounts.',
  },
];

export const TERMS_CONDITIONS_URL =
  'https://www.baselane.com/baselane-rent-collection-referral-program-terms-conditions/';
