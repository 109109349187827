export const defaultCheckboxItemStyles = {
  width: '100%',
  height: '100%',
  borderRadius: 'sm',
  px: 1,
  py: 1,
  _hover: { bgColor: 'brand.blue.50' },
  _focus: { boxShadow: 'none', bgColor: 'brand.blue.100' },
  _active: { boxShadow: 'none', bgColor: 'brand.blue.100' },
  overflow: 'hidden',
  minHeight: '40px',
};
