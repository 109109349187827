import React, { useState } from 'react';
import { Stack, FormLabel, Input, Box, Text } from '@chakra-ui/react';
import { BaselaneHeaderLogoWrapper } from '@shared/layouts';
import { BaselaneButton, BaselaneMessageCard } from '@shared/components';
import { formInputStyles, formLabelStyles, formErrorStyles } from '@shared/styles/input.style';
import { headingStyles, textStyles, buttonStyles } from '../LoginPage/styles/font.style';
import EmailSentSuccess from './EmailSentSuccess';
import useBuildForgotPasswordBodyParams from './hooks/useBuildForgotPasswordBodyParams';
import getResetPasswordLink from './helpers/getResetPasswordLink';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [emailExists, setEmailExistsError] = useState(true);
  const [validEmail, setValidEmailError] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { buildBodyParams } = useBuildForgotPasswordBodyParams();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setValidEmailError(true);
    setEmailExistsError(true);
    setError(false);
  };

  const handleClick = () => {
    // eslint-disable-next-line no-useless-escape
    const re = '^[\\w-+\\.]+@([\\w-]+\\.)+[\\w-]{2,}$';
    if (!email.match(re)) {
      setValidEmailError(false);
      return;
    }
    setIsSubmitting(true);
    const bodyParam = buildBodyParams(email);
    getResetPasswordLink(bodyParam)
      .then((data) => {
        setTimeout(() => setIsSubmitting(false), 2000);
        const { result } = data;
        if (result === 'success') {
          setIsAlertOpen(true);
        }
        setError(true);
      })
      .catch(() => setTimeout(() => setIsSubmitting(false), 2000));
  };

  const handleOKClick = () => {
    setEmail('');
    setValidEmailError(true);
    setEmailExistsError(true);
    setError(false);
  };

  const emailExistsMessage = (
    <Stack>
      <Text>This email is not registered with Baselane.</Text>
      <Box mt="0 !important">Please try again.</Box>
    </Stack>
  );

  const emailNotValidMessage = (
    <Stack>
      <Text>This email is not valid.</Text>
      <Box mt="0 !important">Please try again.</Box>
    </Stack>
  );

  const errorMessage = (
    <Stack>
      <Text>Something went wrong.</Text>
      <Box mt="0 !important">Please try again in a few minutes.</Box>
    </Stack>
  );

  const headStyles = {
    ...headingStyles,
    mb: '24px',
    textStyle: 'headline-2xl',
    textAlign: 'center',
  };

  return (
    <BaselaneHeaderLogoWrapper>
      <Box maxWidth="384px">
        <Text {...headStyles}>Forgot Password</Text>
        <Text {...textStyles}>
          Please enter the email address associated with your account to reset your password
        </Text>
        <Stack>
          {!emailExists && (
            <BaselaneMessageCard
              iconName="exclamationcircle"
              iconColor="blue"
              borderColor="blue"
              text={emailExistsMessage}
              containerStyles={{ mt: '30px' }}
              {...formErrorStyles}
            />
          )}
          {!validEmail ? (
            <BaselaneMessageCard
              {...formErrorStyles}
              iconName="exclamationcircle"
              borderColor="blue"
              iconColor="blue"
              text={emailNotValidMessage}
              containerStyles={{ mt: '30px' }}
            />
          ) : null}
          {error ? (
            <BaselaneMessageCard
              {...formErrorStyles}
              iconName="exclamationcircle"
              borderColor="blue"
              iconColor="blue"
              text={errorMessage}
              containerStyles={{ mt: '30px' }}
            />
          ) : null}
          <Box>
            <FormLabel htmlFor="email" {...formLabelStyles.sm} m="0" p="0" display="initial">
              Email
            </FormLabel>
            <Input
              mt="8px"
              {...formInputStyles}
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter email"
            />
          </Box>
          <BaselaneButton
            variant="filled"
            palette="primary"
            size="lg"
            styles={buttonStyles}
            disabled={isSubmitting}
            onClick={handleClick}
          >
            Send Reset Instructions
          </BaselaneButton>
          <EmailSentSuccess
            onOKClick={handleOKClick}
            isAlertOpen={isAlertOpen}
            setIsAlertOpen={setIsAlertOpen}
          />
        </Stack>
      </Box>
    </BaselaneHeaderLogoWrapper>
  );
}

export default ForgotPassword;
