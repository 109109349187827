import React from 'react';

function IconCondo({
  w = 38,
  h = 35,
  color = 'currentColor',
}: {
  w?: string,
  h?: string,
  color?: string,
}) {
  return (
    <svg width={w} height={h} viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.814 11.165l11.2-9.655c2.765-2.384 7.052-.42 7.052 3.232v25.744a4.267 4.267 0 01-4.266 4.267H4.6a4.267 4.267 0 01-4.267-4.267v-16.09c0-1.24.54-2.42 1.48-3.23zm1.393 1.616c-.47.405-.74.995-.74 1.616v16.089c0 1.178.954 2.133 2.133 2.133h11.2a2.133 2.133 0 002.133-2.133V4.742c0-1.826-2.144-2.808-3.526-1.616l-11.2 9.655z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.413 11.165l11.2-9.655c2.766-2.384 7.053-.42 7.053 3.232v25.744a4.267 4.267 0 01-4.267 4.267H22.2a4.267 4.267 0 01-4.266-4.267v-16.09c0-1.24.54-2.42 1.48-3.23zm1.393 1.616c-.47.405-.74.995-.74 1.616v16.089c0 1.178.955 2.133 2.133 2.133h11.2a2.133 2.133 0 002.134-2.133V4.742c0-1.826-2.144-2.808-3.527-1.616l-11.2 9.655zM10.733 22.753c-.59 0-1.067-.478-1.067-1.067v-2.933a1.067 1.067 0 012.133 0v2.933c0 .589-.477 1.067-1.066 1.067z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.067 22.753c-.59 0-1.067-.478-1.067-1.067v-2.933a1.067 1.067 0 012.133 0v2.933c0 .589-.477 1.067-1.066 1.067z"
        fill={color}
      />
    </svg>
  );
}

IconCondo.defaultProps = {
  w: '38',
  h: '35',
  color: 'currentColor',
};

export default IconCondo;
