// @flow
import type { SortsAndFilters } from '@shared/types/Sort/SortsAndFilters';
import SortFieldEnum from '@shared/components/SortTableHeader/models/SortFieldEnum';
import SortDirectionEnum from '@shared/components/SortTableHeader/models/SortDirectionEnum';

const DEFAULT_PAGE_SIZE = 50;
const PAGE_SIZE_20 = 20;

export const DEFAULT_FILTERS_OPTIONS: SortsAndFilters = {
  default: {
    sort: {
      field: SortFieldEnum.Date,
      direction: SortDirectionEnum.Desc,
    },
    filter: {
      search: '',
      isCategorized: null,
      tagId: null,
      bankAccountId: null,
      propertyId: null,
      unitId: null,
      isDeleted: false,
      isDocumentUploaded: null,
    },
    page: 1,
    pageLimit: DEFAULT_PAGE_SIZE,
  },
  load20: {
    sort: {
      field: SortFieldEnum.Date,
      direction: SortDirectionEnum.Desc,
    },
    filter: {
      search: '',
      isCategorized: null,
      tagId: null,
      bankAccountId: null,
      propertyId: null,
      unitId: null,
      isDeleted: false,
      isDocumentUploaded: null,
    },
    page: 1,
    pageLimit: PAGE_SIZE_20,
  },
  nativeBanking: {
    sort: {
      field: SortFieldEnum.Date,
      direction: SortDirectionEnum.Desc,
    },
    filter: {
      search: '',
      isCategorized: null,
      tagId: null,
      bankAccountId: null,
      propertyId: null,
      unitId: null,
      isDeleted: null,
      isDocumentUploaded: null,
    },
    page: 1,
    pageLimit: DEFAULT_PAGE_SIZE,
  },
};
