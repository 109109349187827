import React from 'react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { Text, Stack } from '@chakra-ui/react';

import { NB_BANK_ACCOUNT, TRANSFER_FUNDS, ADD_FUNDS, NB_ACCOUNTS } from '@routes';
import { BaselaneDrawer, BaselaneFlowComplete, BaselaneButton } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';

type AccountSuccessProps = {
  accountOption: 'checking' | 'savings',
  newAccountId: String,
  closeEvent: Function,
  selectedBankAccount: Object | undefined,
  from: String | undefined,
};

const AccountSuccess = ({
  accountOption,
  newAccountId,
  closeEvent,
  selectedBankAccount,
  from,
}: AccountSuccessProps) => {
  const { DrawerBody, DrawerFooter } = BaselaneDrawer;
  const { isMax576 } = getBreakPoints();
  const navigate = useNavigate();
  const params = useParams();

  const splitPathname = from.split('/');
  splitPathname.pop();
  const updatedFrom = splitPathname.join('/');

  const basePath = selectedBankAccount
    ? updatedFrom ?? NB_ACCOUNTS // if user is coming from list of entity account drawer; setting banking page as backup
    : generatePath(NB_BANK_ACCOUNT, params);
  const redirectFundsUrl =
    accountOption === 'savings' ? `${basePath}/${TRANSFER_FUNDS}` : `${basePath}/${ADD_FUNDS}`;

  const description =
    accountOption === 'savings'
      ? 'Now you can transfer funds from any of your Baselane Banking accounts into this new account.'
      : 'You can use this account to pay bills, send money to external accounts and collect rent.';

  return (
    <>
      <DrawerBody px={isMax576 ? 2 : 3}>
        <BaselaneFlowComplete
          type="success"
          title={
            <>
              <Text as="span" textTransform="capitalize">
                {accountOption}{' '}
              </Text>
              account created
            </>
          }
          description1={description}
        />
      </DrawerBody>
      <DrawerFooter
        px={isMax576 ? 2 : 3}
        py={2}
        w="100%"
        boxShadow="none"
        borderTop="1px solid"
        borderColor="brand.darkBlue.200"
      >
        <Stack direction="row" w="100%">
          <BaselaneButton size="md" variant="outline" palette="neutral" onClick={closeEvent}>
            Done
          </BaselaneButton>
          <BaselaneButton
            isFullWidth
            size="md"
            variant="tonal"
            palette="primary"
            onClick={() =>
              navigate(redirectFundsUrl, { state: { toTransferAccountId: newAccountId } })
            }
          >
            {accountOption === 'savings' ? 'Transfer funds in' : 'Add funds'}
          </BaselaneButton>
        </Stack>
      </DrawerFooter>
    </>
  );
};

export default AccountSuccess;
