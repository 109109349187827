// @flow
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneBadge, BaselaneRadio } from '@shared/components';
import { rentCollectionHelper } from '@shared/components/BaselaneResponsiveCard/helpers/property.helper';
import BaselaneResponsiveCard from '@shared/components/BaselaneResponsiveCard/BaselaneResponsiveCard';
import {
  propertyDisabledTypeTextStyles,
  addressTextStyles,
  unitCardStyles,
} from '@shared/components/BaselaneResponsiveCard/styles/card.style';

const Address = ({ isActivelyCollectingRent, property }: any) => (
  <Text
    {...(isActivelyCollectingRent
      ? { ...addressTextStyles, ...propertyDisabledTypeTextStyles }
      : addressTextStyles)}
  >
    {property.address?.address}, {property.address?.city}, {property.address?.state}{' '}
    {property.address?.zipCode}
  </Text>
);

const UnitName = ({ isActivelyCollectingRent, property }: any) => (
  <Text
    {...(isActivelyCollectingRent
      ? { ...addressTextStyles, ...propertyDisabledTypeTextStyles }
      : addressTextStyles)}
  >
    {property.name}
  </Text>
);

const RentStatus = ({ isActivelyCollectingRent, rentStatus }: any) =>
  isActivelyCollectingRent ? (
    <BaselaneBadge
      isMobile
      text={rentStatus}
      type="badge"
      fontStyles={{ color: 'brand.neutral.500' }}
      backgroundStyles={{ backgroundColor: 'brand.darkBlue.150' }}
    />
  ) : (
    <BaselaneBadge isMobile text={rentStatus} type="badge" />
  );

type Props = {
  isOnlyPropertyAndUnit: boolean,
  property: Object,
  bankProps?: Object,
  isUnit?: Boolean,
  isCollectingRent?: Boolean,
  parentProperty?: Object,
  onComplete?: Function,
  onUnitClick?: Function,
  onShowLeaseTerms?: Function,
  value: String,
  setValue: Function,
  selectingUnitPanel?: Boolean,
  selectedProperty?: Object,
  saving: Boolean,
  id?: string,
  clickedPropertyUnit: any,
};

function BaselaneUnitRadioCard({
  isOnlyPropertyAndUnit,
  property,
  bankProps,
  isUnit,
  isCollectingRent,
  parentProperty,
  onComplete,
  onUnitClick,
  onShowLeaseTerms,
  value,
  setValue,
  selectingUnitPanel,
  selectedProperty,
  saving,
  id,
  clickedPropertyUnit,
  ...rest
}: Props): any {
  const { isMax768: isMobile } = getBreakPoints();
  const { units } = property;
  const { clickedProperty } = clickedPropertyUnit || {};
  const isSingleUnit = units?.length ? units.length < 2 : false;
  let rentStatus = rentCollectionHelper(isSingleUnit ? property.units[0] : property);

  const isActivelyCollectingRent = rentStatus === 'Active Rent Collection';
  // if this is a property, check if one of the units has draft status
  let hasVacantUnits = false;
  if (!isUnit) {
    rentStatus = property.units.find((unit) => unit.lease?.state === 'DRAFT')
      ? 'Resume Draft'
      : null;
    rentStatus = property.units.find((unit) => unit.lease?.state === 'READY_TO_BE_REVIEWED')
      ? 'Resume Draft'
      : rentStatus;
    hasVacantUnits = Boolean(
      property.units.find(
        (unit) =>
          !unit.lease ||
          unit.lease?.state === null ||
          unit.lease?.state === 'DRAFT' ||
          unit.lease?.state === 'READY_TO_BE_REVIEWED'
      )
    );
  }

  return !isUnit ? (
    <>
      {(!selectingUnitPanel || property.id === clickedProperty.id) &&
        hasVacantUnits &&
        (!rentStatus || rentStatus?.toUpperCase() === 'RESUME DRAFT') && (
          <BaselaneResponsiveCard
            id={id}
            {...rest}
            {...unitCardStyles(isMobile, false, isMobile)}
            backgroundColor={
              selectingUnitPanel && property.id === clickedProperty.id
                ? 'brand.darkBlue.100'
                : 'brand.neutral.white'
            }
            onClick={() => onUnitClick(property, property.units[1] ? null : property.units[0])}
          >
            <Flex justifyContent="space-between" w="100%">
              <Box>
                {(!selectingUnitPanel || property.id !== clickedProperty.id) && (
                  <BaselaneRadio
                    className="custom-radio-without-group"
                    value={property.units[0].id}
                    isChecked={property?.id === clickedProperty?.id}
                  >
                    {property.address && <Address {...{ isActivelyCollectingRent, property }} />}
                  </BaselaneRadio>
                )}
                {selectingUnitPanel && property.id === clickedProperty.id && property.address && (
                  <Address {...{ isActivelyCollectingRent, property }} />
                )}
              </Box>
              {rentStatus && (!selectingUnitPanel || property.id !== clickedProperty.id) && (
                <RentStatus {...{ isActivelyCollectingRent, rentStatus }} />
              )}
            </Flex>
          </BaselaneResponsiveCard>
        )}
      {!isSingleUnit &&
        selectingUnitPanel &&
        selectedProperty.id === property.id &&
        units.map((unit) => (
          <BaselaneUnitRadioCard
            key={`unit-card-${unit.id}`}
            id={`unit-card-${unit.id}`}
            isCollectingRent={isActivelyCollectingRent}
            bankProps={bankProps}
            property={unit}
            parentProperty={property}
            isUnit
            onUnitClick={onUnitClick}
            {...unitCardStyles(isMobile, false, isMobile)}
            onComplete={onComplete}
            onShowLeaseTerms={onShowLeaseTerms}
            value={value}
            setValue={setValue}
          />
        ))}
    </>
  ) : (
    (!rentStatus || rentStatus?.toUpperCase() === 'RESUME DRAFT') && (
      <BaselaneResponsiveCard
        id={id}
        {...rest}
        {...unitCardStyles(isMobile, false, isMobile)}
        style={{ cursor: isActivelyCollectingRent || saving ? null : 'pointer' }}
        onClick={() =>
          isActivelyCollectingRent || saving ? null : onUnitClick(parentProperty, property)
        }
      >
        <Flex justifyContent="space-between" w="100%">
          <Box>
            <BaselaneRadio
              className="custom-radio-without-group"
              value={property.id}
              isChecked={value === property?.id}
            >
              {property.name && <UnitName {...{ isActivelyCollectingRent, property }} />}
            </BaselaneRadio>
          </Box>
          {rentStatus && <RentStatus {...{ isActivelyCollectingRent, rentStatus }} />}
        </Flex>
      </BaselaneResponsiveCard>
    )
  );
}

BaselaneUnitRadioCard.defaultProps = {
  bankProps: null,
  isUnit: false,
  isCollectingRent: false,
  parentProperty: null,
  onComplete: null,
  onUnitClick: () => {},
  onShowLeaseTerms: null,
  selectingUnitPanel: false,
  selectedProperty: {},
  id: '',
};

export default BaselaneUnitRadioCard;
