import { React } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { BaselaneInput, BaselaneFormLabel } from '@shared/components';
import PaymentAddressFields from './PaymentAddressFields';

const PaymentMethodFieldsCheck = () => {
  const { values, handleChange, handleBlur, setFieldTouched, errors, touched } = useFormikContext();

  return (
    <>
      {/* Account Holder's Name */}
      <FormControl id="account-holder-name" isRequired isInvalid={errors.name && touched.name}>
        <BaselaneFormLabel textStyle="sm">Check recipient&lsquo;s name</BaselaneFormLabel>
        <BaselaneInput
          size="lg"
          id="name"
          name="name"
          value={values.name}
          type="text"
          onChange={(e) => {
            setFieldTouched('name');
            handleChange(e);
          }}
          onBlur={handleBlur}
          maxLength={40}
        />
        <FormErrorMessage>{errors.name}</FormErrorMessage>
      </FormControl>
      <PaymentAddressFields addressMaxLength={40} />
    </>
  );
};

export default PaymentMethodFieldsCheck;
