// @flow
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabPanels, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import {
  BaselaneDivider,
  TabsComponent,
  TabComponent,
  TabNumber,
  TabListComponent,
} from '@shared/components';
import { FormTitle } from '@shared/components/BaselaneFlow';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import TabIcon from '@shared/components/BaselaneFlow/Tabs/TabComponent/TabIcon';
import { UNIFIED_BOOKKEEPING_ANALYTICS, UNIFIED_BA_ACCOUNTS } from '@routes';
import type { FlowPanelProps } from '@shared/components/BaselaneFlow/types/FlowTabPanelProps';
import { tabListMenu } from '@shared/components/BaselaneFlow/Tabs/helpers/tab.helpers';
import userContext from '@contexts/UserContext';
import {
  cStyles,
  headerStyles,
  tabMenuTitleTextStyles,
  dashStyles,
  tabPanelsStyles,
} from '@core/components/OnboardingTriage/styles/flowSections.styles';

import TabRoutes from './TabRoutes';
import { bookkeepingSegmentEventNames } from '../helpers/bookkeepingAnalytics.helpers';

type BookkeepingAnalyticsFormTabsProps = {
  bookkeepingAnalyticsMenuOptions: Array<FlowPanelProps>,
  bankProps: Object,
  properties: Array,
  refreshProperties: Function,
  tabIndex: Number,
  handleNavigation: Function,
  addingPropertyPanel: boolean,
  setAddingPropertyPanel: Function,
  hasAccounts: boolean,
  banks: Array,
  accounts: Array,
};

function BookkeepingAnalyticsFormTabs({
  bookkeepingAnalyticsMenuOptions,
  bankProps,
  properties,
  refreshProperties,
  tabIndex,
  handleNavigation,
  addingPropertyPanel,
  setAddingPropertyPanel,
  hasAccounts,
  banks,
  accounts,
}: BookkeepingAnalyticsFormTabsProps): any {
  const { user } = useContext(userContext);
  const navigate = useNavigate();

  const segmentEvents = bookkeepingSegmentEventNames(user?.onboardingCompleted);

  const handleSegmentEventRequest = (tabI) => sendSegmentEvent(segmentEvents[tabI]);

  const handleTabChange = (i) => {
    if (i === 1 && tabIndex === 0 && properties?.length > 0) {
      navigate(UNIFIED_BA_ACCOUNTS);
    } else if (i === 0 && tabIndex === 1) {
      navigate(UNIFIED_BOOKKEEPING_ANALYTICS);
    }
    setAddingPropertyPanel(false);
    handleSegmentEventRequest(tabIndex);
  };

  return (
    <TabsComponent
      {...{
        tabIndex,
        handleTabChange,
      }}
      styles={{ mt: 2, minHeight: 'unset', height: '100%' }}
    >
      {tabIndex !== null && !isMobile && (
        <TabListComponent>
          <FormTitle
            title="Steps to set up bookkeeping"
            textStyles={tabMenuTitleTextStyles}
            containerStyles={cStyles}
          />
          {tabListMenu(bookkeepingAnalyticsMenuOptions).map((option, index) => (
            <TabComponent
              key={option.id}
              {...{ menuOptions: bookkeepingAnalyticsMenuOptions, option, index }}
            >
              {option.isValid && (
                <TabIcon
                  {...{ menuOptions: bookkeepingAnalyticsMenuOptions, option, index, tabIndex }}
                />
              )}
              {!option.isValid && (
                <TabNumber {...{ menuOptions: bookkeepingAnalyticsMenuOptions, option, index }} />
              )}
              {isMobile && index < 3 && <BaselaneDivider styles={{ ...dashStyles }} />}
              {!isMobile && <Text ml="8px">{option.label}</Text>}
            </TabComponent>
          ))}
        </TabListComponent>
      )}

      <TabPanels id="bookkeeping-analytics-panels" {...tabPanelsStyles}>
        <FormTitle
          title={`STEP ${tabIndex + 1} of 2`}
          textStyles={{ ...headerStyles, mt: 0, pt: 0 }}
          containerStyles={cStyles}
        />
        <TabRoutes
          {...{
            tabIndex,
            bookkeepingAnalyticsMenuOptions,
            handleNavigation,
            addingPropertyPanel,
            setAddingPropertyPanel,
            properties,
            refreshProperties,
            bankProps,
            banks,
            accounts,
            hasAccounts,
          }}
        />
      </TabPanels>
    </TabsComponent>
  );
}

export default BookkeepingAnalyticsFormTabs;
