import React, { useEffect, useState } from 'react';
import { BaselaneResponsiveCellCheckbox } from '@shared/components';
import type { Transaction } from './types';

const TransactionListItemCheckbox = ({
  transaction,
  actionDeselectAll,
  setActionDeselectAll,
  onSelectAll,
  allSelected,
  selectedTransactions,
  handleSelectedTransactions,
  index,
}: Transaction) => {
  // checkbox states
  const [isSelected, setIsSelected] = useState(transaction?.isChecked);

  function handleCheckboxChange(e) {
    setActionDeselectAll(false);
    setIsSelected(e.target.checked);

    if (e.target.checked) {
      const updatedTransaction = { ...transaction, isChecked: true };
      handleSelectedTransactions([...selectedTransactions, updatedTransaction]);
    } else {
      onSelectAll(false);
      const updatedTransactions = selectedTransactions.filter((t) => t.id !== transaction?.id);
      handleSelectedTransactions(updatedTransactions);
    }
  }

  useEffect(() => {
    if (allSelected && !transaction?.pending) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }

    // eslint-disable-next-line consistent-return,array-callback-return
    selectedTransactions.map((t) => {
      if (t.isChecked && t.id === transaction?.id) {
        setIsSelected(true);
      }
    });
  }, [allSelected, transaction]);

  useEffect(() => {
    // eslint-disable-next-line consistent-return,array-callback-return
    selectedTransactions.map((t) => {
      if (t.isChecked && t.id === transaction?.id) {
        setIsSelected(true);
      }
    });
  }, [selectedTransactions]);

  useEffect(() => {
    if (actionDeselectAll) {
      setIsSelected(false);
    }
  }, [actionDeselectAll]);
  return (
    <BaselaneResponsiveCellCheckbox
      {...{
        className: `checkbox-${index}`,
        isDisabled: transaction?.pending,
        isChecked: !transaction?.pending && isSelected,
        onChange: (e) => handleCheckboxChange(e),
      }}
    />
  );
};

export default TransactionListItemCheckbox;
