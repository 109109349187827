const BANNER_PADDING_Y = 2;

const bannerContainer = {
  px: { base: 2, sm: 2.5, md: 3, '2lg': 4 },
  py: BANNER_PADDING_Y,
  justifyContent: 'space-between',
};
const bannerTitle = {
  textStyle: 'md-heavy',
  m: 0,
};
const bannerDescription = {
  display: 'flex',
  textStyle: 'sm',
  m: 0,
};
const bannerMediumContainer = {
  ...bannerContainer,
};
const bannerHighContainer = {
  ...bannerContainer,
  color: 'brand.neutral.white',
};
const bannerMediumTitle = {
  ...bannerTitle,
  color: 'brand.neutral.900',
};
const bannerHighTitle = {
  ...bannerTitle,
  color: 'brand.neutral.white',
};
const bannerMediumDescription = {
  ...bannerDescription,
  color: 'brand.neutral.700',
};
const bannerHighDescription = {
  ...bannerDescription,
  color: 'brand.neutral.200',
};

export const bannerVariants = {
  'banner-neutral-medium': {
    container: {
      ...bannerMediumContainer,
      color: 'brand.darkBlue.800A',
      bgColor: 'brand.darkBlue.50',
    },
    title: {
      ...bannerMediumTitle,
    },
    description: {
      ...bannerMediumDescription,
    },
  },
  'banner-neutral-high': {
    container: {
      ...bannerHighContainer,
      bgColor: 'brand.darkBlue.800A',
    },
    title: {
      ...bannerHighTitle,
    },
    description: {
      ...bannerHighDescription,
    },
  },
  'banner-primary-medium': {
    container: {
      ...bannerMediumContainer,
      color: 'brand.blue.800A',
      bgColor: 'brand.blue.100',
    },
    title: {
      ...bannerMediumTitle,
    },
    description: {
      ...bannerMediumDescription,
    },
  },
  'banner-primary-high': {
    container: {
      ...bannerHighContainer,
      bgColor: 'brand.blue.800A',
    },
    title: {
      ...bannerHighTitle,
    },
    description: {
      ...bannerHighDescription,
    },
  },
  'banner-success-medium': {
    container: {
      ...bannerMediumContainer,
      color: 'green.800AA',
      bgColor: 'green.100',
    },
    title: {
      ...bannerMediumTitle,
    },
    description: {
      ...bannerMediumDescription,
    },
  },
  'banner-success-high': {
    container: {
      ...bannerHighContainer,
      bgColor: 'green.800AA',
    },
    title: {
      ...bannerHighTitle,
    },
    description: {
      ...bannerHighDescription,
    },
  },
  'banner-warning-medium': {
    container: {
      ...bannerMediumContainer,
      color: 'yellow.800AA',
      bgColor: 'yellow.100',
    },
    title: {
      ...bannerMediumTitle,
    },
    description: {
      ...bannerMediumDescription,
    },
  },
  'banner-warning-high': {
    container: {
      ...bannerHighContainer,
      bgColor: 'yellow.800AA',
    },
    title: {
      ...bannerHighTitle,
    },
    description: {
      ...bannerHighDescription,
    },
  },
  'banner-danger-medium': {
    container: {
      ...bannerMediumContainer,
      color: 'red.800AA',
      bgColor: 'red.100',
    },
    title: {
      ...bannerMediumTitle,
    },
    description: {
      ...bannerMediumDescription,
    },
  },
  'banner-danger-high': {
    container: {
      ...bannerHighContainer,
      bgColor: 'red.800AA',
    },
    title: {
      ...bannerHighTitle,
    },
    description: {
      ...bannerHighDescription,
    },
  },
};
