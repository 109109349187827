import { isMobile } from 'react-device-detect';

export const drawerHeaderStyles = {
  w: '100%',
  direction: !isMobile ? 'row' : 'column',
  ...(!isMobile
    ? {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
      }
    : {}),
  spacing: 0,
};

export const drawerBodyStyles = isMobile
  ? {
      p: '16px 24px',
      m: '0',
      bgColor: 'brand.neutral.white',
      overflow: 'unset',
    }
  : {};

export const headingStyles = {
  size: 'headline-lg',
  mb: '0',
  textAlign: 'left',
  alignSelf: 'flex-start',
};

export const descriptionStyles = {
  w: '100%',
  textStyle: 'xs',
  color: 'brand.neutral.600',
  textAlign: 'left',
  mb: '24px !important',
};
