// @flow
import React from 'react';
import { isMobile } from 'react-device-detect';
import { BaselaneBadge } from '@shared/components';

function RentStatus({
  activeLease,
  isOverdue,
  overdueLease,
}: {
  activeLease: number,
  isOverdue: boolean,
  overdueLease: number,
}): any {
  let status;
  if (activeLease === 0) {
    status = 'noActiveLeases';
  } else {
    status = isOverdue ? 'overdue' : 'current';
  }

  const mobileFontModifications = isMobile
    ? {
        fontSize: '3xs',
        textTransform: 'uppercase',
      }
    : {};

  const mobileBackgroundModifications = isMobile
    ? {
        padding: '2px 6px !important',
        borderRadius: '2px',
        height: 'auto',
        width: 'auto',
        marginBottom: '14px',
      }
    : {};

  const rentStatusOptions = {};
  switch (status) {
    case 'noActiveLeases':
      rentStatusOptions[status] = {
        label: 'No Active Rent Collection',
        backgroundStyles: {
          bg: 'brand.darkBlue.100',
          color: 'brand.neutral.700',
          ...mobileBackgroundModifications,
        },
        fontStyles: {
          ...mobileFontModifications,
        },
        color: 'grey',
        iconName: isMobile ? '' : 'nodata',
        iconClassName: isMobile ? '' : 'icon-sm',
      };
      break;
    case 'overdue':
      rentStatusOptions[status] = {
        label: overdueLease > 1 ? `${overdueLease} Overdue` : 'Overdue',
        backgroundStyles: {
          bg: 'red.200',
          color: 'red.800AA',
          ...mobileBackgroundModifications,
        },
        fontStyles: {
          ...mobileFontModifications,
        },
        color: 'red',
        iconName: isMobile ? '' : 'exclamationtriangle',
        iconClassName: isMobile ? '' : 'icon-md',
      };
      break;
    case 'current':
      rentStatusOptions[status] = {
        label: 'All Current',
        backgroundStyles: {
          bg: 'green.100',
          color: 'green.800AA',
          ...mobileBackgroundModifications,
        },
        fontStyles: {
          ...mobileFontModifications,
        },
        color: 'green',
        iconName: isMobile ? '' : 'check',
        iconClassName: isMobile ? '' : 'icon-xsm',
      };
      break;
    default:
  }

  return (
    <BaselaneBadge
      backgroundStyles={rentStatusOptions[status].backgroundStyles}
      fontStyles={rentStatusOptions[status].fontStyles}
      type="master"
      color={rentStatusOptions[status].color}
      text={rentStatusOptions[status].label}
      iconName={rentStatusOptions[status].iconName}
      iconClassName={rentStatusOptions[status].iconClassName}
    />
  );
}

export default RentStatus;
