import React from 'react';

function IconCheckedFancy() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <circle cx="13" cy="13.5" r="11" fill="#8AD95F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5ZM11 22.5C17.0751 22.5 22 17.5751 22 11.5C22 5.42487 17.0751 0.5 11 0.5C4.92487 0.5 0 5.42487 0 11.5C0 17.5751 4.92487 22.5 11 22.5ZM16.8536 8.35355C17.0488 8.15829 17.0488 7.84171 16.8536 7.64645C16.6583 7.45118 16.3417 7.45118 16.1464 7.64645L9.5 14.2929L6.85355 11.6464C6.65829 11.4512 6.34171 11.4512 6.14645 11.6464C5.95118 11.8417 5.95118 12.1583 6.14645 12.3536L9.14645 15.3536C9.34171 15.5488 9.65829 15.5488 9.85355 15.3536L16.8536 8.35355Z"
        fill="#000619"
      />
    </svg>
  );
}

export default IconCheckedFancy;
