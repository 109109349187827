import { isMobile } from 'react-device-detect';
import customTheme from '@core/theme';

export const iconContainerStyles = {
  w: '40px',
  h: 'auto',
  justifyContent: 'center',
};

export const iconContainerTopStyles = {
  w: '48px',
  h: '100%',
  mb: 'auto',
  justifyContent: 'flex-start',
};

export const cardTextContainerStyles = {
  flex: 1,
};

export const cardLinkBoxStyles = {
  w: isMobile ? 'auto' : '100%',
  textAlign: isMobile ? 'left' : 'right',
  pr: '16px',
};

export const cardTitleStyles = {
  textStyle: 'headline-xs',
  color: customTheme.colors.brand.neutral['700'],
  textTransform: 'uppercase',
  flex: 1,
  mb: 0.5,
};

export const cardBadgeContainerStyles = {
  borderWidth: '1px',
  borderColor: customTheme.colors.brand.darkBlue['150'],
  borderRadius: '4px',
  bgColor: 'brand.neutral.white',
  p: '4px 8px',
  position: 'absolute',
  top: '-12px',
  right: '16px',
};

export const nonButtonCardBadgeContainerStyles = {
  borderWidth: '1px',
  borderColor: customTheme.colors.brand.darkBlue['150'],
  borderRadius: '4px',
  bgColor: 'brand.neutral.white',
  p: '4px 8px',
  position: 'absolute',
  top: '20px',
  right: '16px',
  zIndex: 2,
};

export const cardBadgeTextStyles = {
  fontSize: '3xs',
  fontWeight: 'semibold',
  lineHeight: '14px',
  color: customTheme.colors.brand.neutral['700'],
  ml: '4px !important',
};

export const cardBodyStyles = {
  textStyle: 'xs',
  color: customTheme.colors.brand.neutral['600'],
};

export const cardSummaryStyles = {
  textStyle: 'sm',
  color: customTheme.colors.brand.neutral['700'],
};

export const cardContainerStyles = {
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  gap: '16px',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: 'brand.darkBlue.250',
  background: 'brand.neutral.white',
  padding: '16px',
  marginTop: isMobile ? '16px' : '0',
  position: 'relative',
};

export const typeCardStyles = {
  spacing: 2,
  color: customTheme.colors.brand.neutral['600'],
  h: isMobile ? '92px' : '72px',
  minHeight: isMobile ? '92px' : '72px',
  borderColor: customTheme.colors.brand.darkBlue['150'],
  position: 'relative',
  _hover: {
    borderColor: 'brand.blue.800A',
    bg: 'brand.blue.50',
  },
  _focus: {
    borderColor: 'brand.blue.700',
    bg: 'brand.neutral.white',
    outline: '2px solid',
    outlineColor: 'brand.blue.250',
  },
  _focusWithin: {
    borderColor: 'brand.blue.700',
    bg: 'brand.neutral.white',
    outline: '2px solid',
    outlineColor: 'brand.blue.250',
  },
  _target: {
    borderColor: 'brand.blue.700',
    bg: 'brand.neutral.white',
    outline: '2px solid',
    outlineColor: 'brand.blue.250',
  },
  _active: {
    borderColor: 'brand.blue.800A',
    bg: 'brand.blue.100',
  },
};
