// @flow
import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import * as ROUTES from '@routes';

import UserContext from '@contexts/UserContext';

import { auth } from '@core/Services/Firebase/firebase';
import useUserSignUpExperiment from '@core/hooks/useUserSignUpExperiment';
import SlLoader from '../../../Loader';

const AuthenticationGuard = ({ ...rest }): any => {
  const { loading, user, onboardingConnectAccount, onboardingBaselaneAccount } = useContext(
    UserContext
  );
  const { onboardingCompleted } = user || {};
  const currLocation = useLocation();
  const { pathname } = currLocation;
  const onboardingPage = pathname.includes('onboarding');
  const gettingStarted = pathname.includes('getting_started');

  // 2024-07_sign_up_page_experiment
  const { isTestVariant, loading: experimentLoading } = useUserSignUpExperiment();

  if (loading) return <SlLoader />;

  // No auth or user, go to login
  if (!user || !auth) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  // Redirect for users that just completed onboarding
  if (
    onboardingCompleted &&
    !onboardingConnectAccount &&
    !onboardingBaselaneAccount &&
    (onboardingPage || gettingStarted) &&
    pathname !== ROUTES.HOME
  ) {
    return <Navigate to={ROUTES.HOME} />;
  }

  // Redirect for users without unverified phone number
  // Skip if user completed the a/b test survey v1.3
  if (
    !experimentLoading &&
    !isTestVariant &&
    pathname !== ROUTES.PHONE &&
    (user.phoneNumber === null || !user.phoneNumberVerified)
  ) {
    return <Navigate to={ROUTES.PHONE} />;
  }

  if (
    !onboardingCompleted &&
    !pathname.includes(ROUTES.ONBOARDING) &&
    !pathname.includes(ROUTES.PHONE) &&
    !pathname.includes(ROUTES.ONBOARDING_GETTING_STARTED) &&
    !pathname.includes('unified') &&
    // ISSUE: When user is on /verified page, this code fires and redirects user to /onboarding.
    // Probably a Context API updates, refreshes routes component and checking a guarded route as a result :(
    pathname !== ROUTES.VERIFIED
  ) {
    return <Navigate to={ROUTES.ONBOARDING} />;
  }

  return user ? <Outlet /> : null;
};

export default AuthenticationGuard;
