import React from 'react';
import { useFormikContext } from 'formik';
import { Text } from '@chakra-ui/react';

const Recurrance = () => {
  const { values } = useFormikContext();

  const isMonthly = values.recurringPayment.repeatEvery.unit === 'month';

  // A. For monthly recurring payments.
  if (isMonthly) {
    const monthDate = values.recurringPayment.repeatOnMonthDate;

    // Calculating the label for the month date.
    let monthDateLabel = '';

    if (monthDate === 'last') {
      monthDateLabel = 'last day';
    } else if (monthDate === '1') {
      monthDateLabel = '1st';
    } else if (monthDate === '2') {
      monthDateLabel = '2nd';
    } else if (monthDate === '3') {
      monthDateLabel = '3rd';
    } else if (monthDate === '21') {
      monthDateLabel = '21st';
    } else if (monthDate === '22') {
      monthDateLabel = '22nd';
    } else if (monthDate === '23') {
      monthDateLabel = '23rd';
    } else if (monthDate === '31') {
      monthDateLabel = '31st';
    } else {
      monthDateLabel = `${monthDate}th`;
    }

    // Calculating the label for the frequency of repetition.
    const frequencyOfRepetition = values.recurringPayment.repeatEvery.value;
    const frequencyOfRepetitionLabel =
      frequencyOfRepetition > 1 ? `Every ${frequencyOfRepetition} months on the` : 'Monthly on the';

    return (
      <Text color="brand.neutral.600" textStyle="xs">
        {`${frequencyOfRepetitionLabel} ${monthDateLabel}`}
      </Text>
    );
  }

  // B. For weekly recurring payments.

  // Calculating the label for the weekday, and capitalizing the first letter, e.g. from 'monday' to 'Monday'.
  const weekday = values?.recurringPayment?.repeatOnWeekday
    ? values.recurringPayment.repeatOnWeekday.charAt(0).toUpperCase() +
      values.recurringPayment.repeatOnWeekday.slice(1)
    : '';

  // Calculating the label for the frequency of repetition.
  const frequencyOfRepetition = values.recurringPayment.repeatEvery.value;
  const frequencyOfRepetitionLabel =
    frequencyOfRepetition > 1 ? `Every ${frequencyOfRepetition} weeks on` : 'Weekly on';

  return (
    <Text color="brand.neutral.600" textStyle="xs">
      {`${frequencyOfRepetitionLabel} ${weekday}`}
    </Text>
  );
};

export default Recurrance;
