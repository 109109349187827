import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { GET_BANK_INFO } from '@core/apollo/queries';
import { numbersOnlyMask } from '@core/utils/masks';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';

import DrawerHeaderWrapper from './DrawerHeaderWrapper';
import { descriptionTextStyles, title2 } from '../../styles/makeTransferOrPaymentDrawer.styles';
import { inputRightElementStyles, spinnerStyles } from './styles/recipientName.styles';

type RecipientFormProps = {
  totalSteps: Number,
  currentStep: Number,
  values: Object,
  touched: Object,
  errors: Object,
  handleChange: Function,
  handleBlur: Function,
};

const RecipientForm = ({
  totalSteps,
  currentStep,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}: RecipientFormProps) => {
  const { isMinXL } = getBreakPoints();

  const [getBankInfo, { loading }] = useLazyQuery(GET_BANK_INFO);
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <Stack spacing={isMinXL ? 0 : 2}>
        <DrawerHeaderWrapper {...{ totalSteps, currentStep }}>
          <Text {...title2}>Who are you paying?</Text>
        </DrawerHeaderWrapper>
        <Text {...descriptionTextStyles}>
          Protect yourself from wire fraud: Always confirm wire details with the recipient,
          especially if you received payment information by email.
        </Text>
      </Stack>
      <Stack spacing={isMinXL ? 2 : 3}>
        {/* Recipient Name */}
        <FormControl isInvalid={errors.recipientName && touched.recipientName}>
          <FormLabel htmlFor="recipientName" {...formLabelStyles.xs}>
            Recipient Name
          </FormLabel>
          <Input
            {...formInputStyles}
            id="recipientName"
            name="recipientName"
            value={values.recipientName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage {...formErrorStyles}>{errors.recipientName}</FormErrorMessage>
        </FormControl>

        {/* Routing Number */}
        <FormControl isInvalid={errors.routingNumber && touched.routingNumber}>
          <FormLabel htmlFor="routingNumber" {...formLabelStyles.xs}>
            Routing Number
          </FormLabel>
          <InputGroup>
            <Input
              {...formInputStyles}
              id="routingNumber"
              name="routingNumber"
              value={values.routingNumber}
              maxLength="9"
              as={MaskedInput}
              mask={numbersOnlyMask}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value.length === 9) {
                  getBankInfo({ variables: { routingNumber: e.target.value } }).then((res) => {
                    setFieldValue('bankName', res.data?.bankInfo?.name ?? values.bankName);
                  });
                } else {
                  setFieldValue('bankName', '');
                }
              }}
              onBlur={handleBlur}
            />
            <InputRightElement {...inputRightElementStyles}>
              {loading && <Spinner {...spinnerStyles} />}
              {!loading && values?.bankName !== '' ? <Text>{values?.bankName}</Text> : null}
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage {...formErrorStyles}>{errors.routingNumber}</FormErrorMessage>
        </FormControl>

        {/* Account Number */}
        <FormControl isInvalid={errors.accountNumber && touched.accountNumber}>
          <FormLabel htmlFor="accountNumber" {...formLabelStyles.xs}>
            Account Number
          </FormLabel>
          <Input
            {...formInputStyles}
            id="accountNumber"
            name="accountNumber"
            value={values.accountNumber}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            onChange={handleChange}
            onBlur={handleBlur}
            as={MaskedInput}
            mask={numbersOnlyMask}
          />
          <FormErrorMessage {...formErrorStyles}>{errors.accountNumber}</FormErrorMessage>
        </FormControl>

        {/* Retype Account Number */}
        <FormControl isInvalid={errors.retypeAccountNumber && touched.retypeAccountNumber}>
          <FormLabel htmlFor="retypeAccountNumber" {...formLabelStyles.xs}>
            Retype Account Number
          </FormLabel>
          <Input
            {...formInputStyles}
            id="retypeAccountNumber"
            name="retypeAccountNumber"
            value={values.retypeAccountNumber}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            onChange={handleChange}
            onBlur={handleBlur}
            as={MaskedInput}
            mask={numbersOnlyMask}
          />
          <FormErrorMessage {...formErrorStyles}>{errors.retypeAccountNumber}</FormErrorMessage>
        </FormControl>
      </Stack>
    </>
  );
};

export default RecipientForm;
