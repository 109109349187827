import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTab, useMultiStyleConfig } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import { tabStyles } from '../styles/tabs.styles';

const BaselaneButtonTab = React.forwardRef((props, ref) => {
  // 1. Reuse the `useTab` hook
  const tabProps = useTab({ ...props, ref });

  // 2. Hook into the Tabs `size`, `variant`, props
  const styles = useMultiStyleConfig('Tabs', tabProps);

  return (
    // TODO: REVIEW FOR new DS BUTTONS

    <BaselaneButton {...{ ...styles, ...tabStyles(isMobile) }} {...tabProps}>
      {tabProps.children}
    </BaselaneButton>
  );
});

export default BaselaneButtonTab;
