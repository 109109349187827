import React from 'react';
import { Text, Box } from '@chakra-ui/react';
import {
  amountTextStyles,
  zero,
} from '@shared/components/BaselaneResponsiveTable/styles/cell.styles';

type BaselaneResponsiveCellAmountContentProps = {
  /**
   * Cell element on left of title and subtitle. Can be used to any type of element
   * at the moment most common case is image/icon
   */
  leftGraphicElement?: React.Node,
  /**
   * Cell element on left of title and subtitle. Can be used to any type of element
   * at the moment most common case is image/icon
   */
  rightGraphicElement?: React.Node,
  /**
   * Text style token to apply to the amount
   */
  textStyle?: String,
  /**
   * Text color style token to apply to the amount
   */
  color?: String,
  /**
   * Cell children
   */
  children?: React.Node,
};

/**
 * Renders contents of the amount cell. Seperated to be able to use component separately from parent cell if needed.
 *
 * @returns {JSX.Element}
 */
const BaselaneResponsiveCellAmountContent = ({
  leftGraphicElement,
  rightGraphicElement,
  children,
  color,
  textStyle,
}: BaselaneResponsiveCellAmountContentProps) => {
  return (
    <>
      {leftGraphicElement && <Box {...zero}>{leftGraphicElement}</Box>}
      <Text as="span" {...{ ...amountTextStyles, color, textStyle }}>
        {children}
      </Text>
      {rightGraphicElement && <Box {...zero}>{rightGraphicElement}</Box>}
    </>
  );
};

BaselaneResponsiveCellAmountContent.defaultProps = {
  leftGraphicElement: null,
  rightGraphicElement: null,
  textStyle: 'data-md',
  color: 'brand.neutral.700',
  children: null,
};

export default BaselaneResponsiveCellAmountContent;
