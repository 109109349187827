import React, { useContext } from 'react';
import UserContext from '@contexts/UserContext';
import NavigationAuth from '../NavigationAuth';
import NavigationNonAuth from '../NavigationNonAuth';

function SideBar() {
  const { user } = useContext(UserContext);
  return user ? <NavigationAuth /> : <NavigationNonAuth />;
}

export default SideBar;
