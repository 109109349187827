import { IconCheck, IconCheckCircleOutline } from '@icons';

export const getList = (isMobile) => {
  return [
    {
      label: isMobile ? IconCheckCircleOutline : IconCheck,
      value: 'Built for rental property finances',
    },
    {
      label: isMobile ? IconCheckCircleOutline : IconCheck,
      value: 'Separate virtual accounts for rent, security deposits, etc. opened with one click',
    },
    {
      label: isMobile ? IconCheckCircleOutline : IconCheck,
      value: 'Up to 5% cash back on debit card spend',
    },
    {
      label: isMobile ? IconCheckCircleOutline : IconCheck,
      value: '4.19% APY interest on all balances: 60x the national average.',
    },
  ];
};
