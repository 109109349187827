import { isMobile } from 'react-device-detect';
import bgImage from '../assets/tracker-bg.svg';
import mobileBgImage from '../assets/bg-mobile.svg';
// import arrowImage from '../assets/arrow.svg';

export const trackerContainerStyles = {
  position: 'relative',
  direction: !isMobile ? 'row' : 'column',
  h: isMobile ? '100%' : '260px',
  minHeight: isMobile ? '75%' : '260px',
  w: '100%',
  bg: 'brand.darkBlue.100',
  mb: '25px !important',
  borderRadius: isMobile ? '0' : '8px',
  p: {
    '3xl': '36px 48px 36px 53px',
    '2xl': '36px 71px 36px 53px',
    md: '10px 24px 10px 24px',
    xxs: '24px 42px 24px 42px',
  },
  alignItems: 'center',
  borderWidth: '1px',
  borderColor: 'brand.blue.100',
  backgroundImage: isMobile ? `url(${mobileBgImage})` : `url(${bgImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  // backgroundPosition: 'left',
  backgroundPosition: { '2lg': '50% 50%', lg: '80% 50%' },
  spacing: isMobile ? '24px' : '0',
};

export const slLoaderStyles = {
  position: 'absolute',
  top: isMobile ? '50%' : 'initial',
  left: isMobile ? 'initial' : '50%',
};

export const trackerMessageContainerStyles = {
  flexDirection: { xxs: 'column', '2lg': 'row' },
  flex: { '2lg': '0 0 66%', xxs: '0 0 50%' },
  spacing: isMobile ? '24px' : '15px',
};

export const trackerMessageInnerContainerStyles = {
  flex: isMobile ? '0 1 auto' : '0 0 40%',
  spacing: '12px',
  justifyContent: 'center',
  alignItems: isMobile ? 'center' : 'initial',
  mt: isMobile ? '0 !important' : 'initial',
};

export const trackerMessageHeadingContainerStyles = {
  alignItems: 'center',
};

export const trackerHeadingTextStyles = {
  textStyle: 'headline-sm',
  color: isMobile ? 'brand.neutral.700' : 'brand.neutral.600',
};

export const trackerSectionContainerStyles = {
  display: isMobile ? 'flex' : 'block',
  flex: { '2lg': '0 0 33%', xxs: isMobile ? '0 0 40%' : '0 0 50%' },
  justifyContent: 'space-between',
  flexDirection: 'column',
};

const hideMessage = { display: { xxs: 'none', '2lg': 'block' } };
export const trackerMessageTextStyles = {
  textStyle: 'headline-xl',
  color: 'brand.neutral.700',
  ...hideMessage,
};

export const trackerSecondMessageTextStyles = {
  color: 'brand.neutral.600',
  textStyle: 'headline-lg',
  mt: '8px',
  ...hideMessage,
};

export const creditCardStyles = {
  display: 'flex',
  justifyContent: { '2lg': 'center', lg: 'flex-start' },
  flex: '0 0 55%',
  mr: isMobile ? '0' : '24px !important',
};

export const trackerStepSectionHeadingTextStyles = {
  color: 'brand.neutral.700',
  textStyle: 'headline-md',
};

export const trackerStepSectionMessageTextStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
  mt: '10px',
};

export const ctaButtonStyles = { w: isMobile ? '100%' : 'auto', mt: isMobile ? '16px' : '24px' };

export const addFundsButtonStyles = {
  styles: ctaButtonStyles,
};
