import React from 'react';
import { Formik } from 'formik';
import { Box } from '@chakra-ui/react';
import TransactionSplitItem from './TransactionSplitItem';
import Footer from './Footer';
import {
  getInitialValues,
  getRemainingAmount,
  getTotalSplitTransactionAmount,
  handleValidation,
} from '../helpers/transactionSplits.helper';
import { SplitTransaction } from '../../types';

type TransactionSplitsArrayProps = {
  totalAmount: Number,
  splitTransactions: Array<SplitTransaction>,
  formikRef: any,
  setSplitTrxDirty: Function,
  setSplitTrxValid: Function,
  handleOnSaveAndClose: Function,
  setDeletedSplitTransactions: Function,
  propertyOptions: Array<Object>,
};

const TransactionSplitsArray = ({
  totalAmount,
  splitTransactions,
  formikRef,
  setSplitTrxDirty,
  setSplitTrxValid,
  handleOnSaveAndClose,
  setDeletedSplitTransactions,
  propertyOptions,
}: TransactionSplitsArrayProps) => {
  const initialValues = getInitialValues(splitTransactions);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleOnSaveAndClose}
      validate={(values) => handleValidation({ values, setSplitTrxValid, totalAmount })}
      validateOnChange
      validateOnBlur
      enableReinitialize
    >
      {({ dirty, values, errors, touched, handleChange }) => {
        const totalAmountCalculated = getTotalSplitTransactionAmount(values.splitTransactions);
        const remainingAmount = getRemainingAmount(values.splitTransactions, totalAmount);

        return (
          <Box>
            <TransactionSplitItem
              {...{
                formikRef,
                dirty,
                values,
                touched,
                errors,
                handleChange,
                setSplitTrxDirty,
                setDeletedSplitTransactions,
                propertyOptions,
              }}
            />
            <Footer
              {...{
                totalAmount: totalAmountCalculated,
                remainingAmount,
              }}
            />
          </Box>
        );
      }}
    </Formik>
  );
};

export default TransactionSplitsArray;
