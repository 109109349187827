import React from 'react';

type IconCreditCardImportingProps = { w?: Number, h?: Number, color?: string };

function IconCreditCardImporting({ w, h, color }: IconCreditCardImportingProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.625" cy="10.625" r="1.375" stroke={color} />
      <path
        d="m15.2505 12.5492 3-2.95084m0 0 3 2.95084m-3-2.95084V15.5M20.75 5.66393H1.25m19.5005 1.72132V3.20088c0-.65167 0-.9775-.0751-1.24375-.1889-.66973-.7123-1.193148-1.3821-1.382031-.2662-.07509-.5918-.07509-1.2428-.07509v0s-8.28891-.00002-13.60004 0h-.05234C3.32754.500013 2.79225.500015 2.37975.699494c-.40592.1963-.73351.523896-.92981.929816-.19948.4125-.19948.94775-.19948 2.01824V11.123c0 .8164 0 1.2246.11726 1.5517.20182.5632.64486 1.0062 1.208 1.208C2.9029 14 3.31095 14 4.12705 14h7.12345"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="6.875" cy="10.625" r="1.375" stroke={color} />
    </svg>
  );
}

IconCreditCardImporting.defaultProps = {
  w: 22,
  h: 26,
  color: 'currentColor',
};

export default IconCreditCardImporting;
