import { React, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Icon16ChevronRight, Icon16ChevronLeft } from '@icons/16px';
import { BaselaneButton } from '@shared/components';
import { steps } from '../../helpers/formHelpers';
import { useSetCurrentStep, useIsCurrentStepLoading } from '../../sendFundsStore';

type AddRecipientFooterProps = {
  isOneTimeScheduledPaymentFlagEnabled: Boolean,
};

const AddRecipientFooter = ({ isOneTimeScheduledPaymentFlagEnabled }: AddRecipientFooterProps) => {
  const formik = useFormikContext();

  const { submitForm, isValid, validateForm, touched, setErrors } = formik;
  const isCurrentStepLoading = useIsCurrentStepLoading();
  const setCurrentStep = useSetCurrentStep();
  const handleGoBack = () => {
    setCurrentStep(steps.SELECT_RECIPIENT);
  };

  useEffect(() => {
    validateForm();
    setErrors({});
  }, []);
  return (
    <>
      <BaselaneButton
        size="md"
        variant="outline"
        palette="neutral"
        onClick={handleGoBack}
        leftIcon={<Icon16ChevronLeft />}
      >
        Back
      </BaselaneButton>
      <BaselaneButton
        size="md"
        variant="filled"
        palette="primary"
        width="100%"
        ml={1.5}
        flex={1}
        onClick={() => {
          if (touched) {
            submitForm();
          }
        }}
        rightIcon={<Icon16ChevronRight />}
        isLoading={isCurrentStepLoading}
        isDisabled={!isValid}
      >
        Save and continue
      </BaselaneButton>
    </>
  );
};
export default AddRecipientFooter;
