import React, { Fragment, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BaselaneKYCForm from '@shared/components/BaselaneKYCForm';
import KYCReturnStatusDrawer from '@shared/components/BaselaneKYCForm/components/KYCReturnStatusDrawer';
import VouchedIdentificationDrawer from '@shared/components/VouchedIdentificationDrawer';
import useReturnStatus from '@shared/components/BaselaneKYCForm/hooks/useReturnStatus';
import StripeButton from '@shared/components/StripeButton';
import { generalRedirectToURC } from '@pages/UnifiedRentCollection/UnifiedLeaseUI/helpers/unifiedLeaseFlow.helpers';
import { UNIFIED_RENT_COLLECTION } from '@routes';

import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import Tracker from '../Tracker';
import { InProgress } from '../Steps';
import { getTrackerData, getLinkStatus } from '../../helpers/rentcollection.helpers';
import { getPropertyLink } from '../../helpers/links/addProperty.helpers';

type RentCollectionProps = {
  data: Object,
  userKycStatus: String,
  handleStepUpdate: Function,
  refetchUser: Function,
  propertiesProps: Object,
  user: Object,
  userKyc: Object,
};

const RentCollection = ({
  data,
  userKycStatus,
  handleStepUpdate,
  refetchUser,
  propertiesProps,
  user,
  userKyc,
}: RentCollectionProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showSteps, setShowSteps] = useState(false);

  const { propertiesSummaryError, propertiesSummaryLoading } = propertiesProps ?? {};
  const isLoading = propertiesSummaryLoading;
  const hasError = propertiesSummaryError;

  const {
    kycReturnStatusDrawerRef,
    handleKYCReturnStatusDrawerClose,
    handleKYCReturnStatusDrawerOpen,
  } = useReturnStatus();

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  // Vouched Ref
  const vouchedDrawerRef = useRef();
  const handleVouchedDrawerOpen = () => vouchedDrawerRef?.current?.open();

  const trackerData = getTrackerData(userKycStatus);
  const LINKS = getLinkStatus(trackerData.elements);

  const handleStartRentCollectionClick = () => {
    const state = { from: location.pathname };
    const pathname = UNIFIED_RENT_COLLECTION;
    generalRedirectToURC({ navigate, pathname, state });
  };

  const getRCLink = (status, state) => {
    return (
      <InProgress
        {...{
          status,
          handleTrackerInProgStepClick: (e) => handleStartRentCollectionClick(e),
          key: `set_up_rent_collection_rc_button_${state}`,
        }}
      />
    );
  };

  const getTenantLink = (status, state) => {
    return <InProgress {...{ status, key: `setup_tenant_rc_${state}` }} />;
  };

  const getReceiveFirstPaymentLink = (status, state) => {
    switch (state) {
      case 'not_started':
        return userKycStatus !== 'APPROVED' ? (
          <BaselaneKYCForm
            button={{
              variant: 'filled',
              palette: 'primary',
              text: 'Verify Identity',
              size: 'lg',
            }}
            kycStatus={userKycStatus}
            handleKYCReturnStatusDrawerOpen={handleKYCReturnStatusDrawerOpen}
            isGetStarted
            getStartedConfig={{ status }}
            handleVerifyIdentitySuccess={() => handleStepUpdate({ isAPIUpdated: true })}
            onKYCDrawerClose={() => handleStepUpdate({ isDrawerClosed: true })}
            handleVouchedDrawerOpen={handleVouchedDrawerOpen}
            handleKYCOnCloseCallback={() =>
              setTimeout(() => {
                kycReturnStatusDrawerRef.current.open();
              }, 1000)
            }
            key={`receive_first_payment_rc_${state}`}
          />
        ) : (
          <InProgress
            {...{
              status,
              key: `receive_first_payment_rc_${state}`,
            }}
          />
        );

      case 'warning':
        return (
          <StripeButton
            isGetStarted
            getStartedConfig={{ status }}
            key={`receive_first_payment_rc_${state}`}
          />
        );

      case 'alert':
        return (
          <InProgress
            {...{
              status,
              handleTrackerInProgStepClick: () => zendeskAPI('messenger', 'open'),
              key: `receive_first_payment_rc_${state}`,
            }}
          />
        );

      case 'in_progress' || 'completed':
        return (
          <InProgress
            {...{
              status,
              key: `receive_first_payment_rc_${state}`,
            }}
          />
        );

      default:
        return null;
    }
  };

  const getLinkTo = (step, state) => {
    const status = state === 'disabled' ? LINKS[step].not_started : LINKS[step][state];
    switch (step) {
      case 'ADD_PROPERTY':
        return getPropertyLink({
          key: `add_property_rc_${state}`,
          from: { page: 'get_started', section: 'rc_tracker', configs: { status } },
          handleStepUpdate,
          refetchUser,
          propertiesProps,
        });

      case 'SETUP_RENT_COLLECTION':
        return getRCLink(status, state);

      case 'SETUP_TENANT':
        return getTenantLink(status, state);

      case 'RECEIVE_FIRST_PAYMENT':
        return getReceiveFirstPaymentLink(status, state);

      default:
        return null;
    }
  };

  return (
    <>
      <Tracker
        {...{
          tracker: { data: trackerData, getLinkTo },
          steps: data,
          isLoading,
          hasError,
          showSteps,
          setShowSteps,
          key: 'rc',
        }}
      />

      <KYCReturnStatusDrawer
        kycStatus={userKycStatus}
        drawerRef={kycReturnStatusDrawerRef}
        handleKYCReturnStatusDrawerClose={() => {
          handleKYCReturnStatusDrawerClose();
          refetchUser();
        }}
      />

      <VouchedIdentificationDrawer
        {...{
          userKyc,
          user,
          refetchUser,
          vouchedDrawerRef,
        }}
      />
    </>
  );
};

export default RentCollection;
