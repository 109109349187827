import customTheme from '@core/theme';

export const containerStyles = {
  height: '112px',
  bgColor: customTheme.colors.brand.darkBlue['50'],
  padding: '16px',
  borderRadius: '4px',
  borderWidth: '1px',
  borderColor: customTheme.colors.brand.darkBlue['100'],
  justifyContent: 'space-between',
};

export const taggedContainerStyles = (tagged) => {
  return tagged
    ? {
        bgColor: customTheme.colors.green['50'],
        borderColor: customTheme.colors.green['100'],
      }
    : {};
};

export const bankAccountInfoTextStyles = {
  color: customTheme.colors.brand.neutral['700'],
  textStyle: 'headline-xs',
};

export const dotsStyles = {
  borderLeftStyle: 'dashed',
  borderTopStyle: 'none',
  borderRightStyle: 'none',
  borderBottomStyle: 'none',
  position: 'absolute',
  height: '16px',
  borderWidth: '1px',
  borderColor: customTheme.colors.brand.neutral['600'],
  top: '27.5px',
  left: '3.5px',
};

export const iconTagContainerStyles = {
  w: '24px',
  mr: '16px',
  align: 'center',
  flex: '0 1 24px',
  color: customTheme.colors.brand.neutral['600'],
};
