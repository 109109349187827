import { bodyTextSmallLightTextStyles } from '@core/components/OnboardingTriage/styles/fonts.styles';

export const xsmallLightCardTextStyles = {
  ...bodyTextSmallLightTextStyles,
  lineHeight: '20px',
};

export const smallLightBodyTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
};

export const smallLightDarkBodyTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
};

export const headingTextStyles = (isMobile) => ({
  fontSize: isMobile ? 'lg' : '2xl',
  color: 'brand.neutral.700',
  fontWeight: 'semibold',
});

export const extraLargeBoldTextStyles = {
  textStyle: 'data-xl',
  color: 'brand.neutral.700',
};

export const largeLightBoldTextStyles = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.600',
};

export const mediumTextStyles = {
  textStyle: 'md',
  color: 'brand.neutral.700',
};

export const xxSmallBoldTextStyles = {
  fontSize: '2xs',
  fontWeight: 'semibold',
};
