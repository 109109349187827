export const config = (isMin1150) => ({
  columns: [
    // 0. Checkbox
    {
      isSortable: false,
      styles: { w: '40px' },
    },
    // 1. Date
    {
      isSortable: true,
      styles: {
        w: '112px',
      },
      years: { start: 2022, end: 2033 },
    },
    // 2. Account
    {
      isSortable: true,
      styles: {
        display: { '2lg': 'table-cell', base: 'none' },
        w: { '2xl': '200px', base: '172px' },
      },
    },
    // 3. Description
    { isSortable: true /* styles: { w: 'calc(100% - 142px)' } */ },
    // 4. Amount
    {
      isSortable: true,

      styles: {
        w: '120px',
        display: { '2lg': 'table-cell', base: 'none' },

        contentContainerStyles: {
          justifyContent: 'flex-end',
          gap: '0.5',
        },
      },
    },
    // 5. Spacer
    { size: 'sm', styles: { display: { '2xl': 'table-cell', base: 'none' } } },
    // 6. Category
    {
      isSortable: !!isMin1150,
      styles: {
        w: { '2xl': '200px', xl: '220px', '2lg': '180px', base: '220px' },
        overflow: 'initial',
        pr: 1.5,
        pl: 0,
      },
    },
    // 7. Property
    {
      isSortable: !!isMin1150,
      styles: {
        w: { '2xl': '200px', xl: '220px', '2lg': '180px', base: '220px' },
        display: { '2lg': 'table-cell', base: 'none' },
        overflow: 'initial',
        pr: 1.5,
        pl: 0,
      },
    },
    // 8. Note
    {
      isSortable: true,
      styles: {
        pl: 0,
        pr: 1.5,
        w: '200px',
        display: { '2xl': 'table-cell', base: 'none' },
      },
    },
    // 9. Amount
    {
      isSortable: true,
      styles: {
        w: '120px',
        display: { '2lg': 'none', base: 'table-cell' },
        contentContainerStyles: {
          justifyContent: 'flex-end',
          gap: '0.5',
        },
      },
    },
    // 10. mobile
    {
      styles: {
        w: '100%',
        display: { '2lg': 'none', base: 'table-cell' },
        contentContainerStyles: {
          justifyContent: 'flex-end',
          gap: '0.5',
        },
      },
    },
  ],
});
