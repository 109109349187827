import React from 'react';
import { Stack, HStack, Text } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import { IconCircleOutline, IconChevronUp, IconChevronDown } from '@icons';
import {
  stepTogglerContainerStyles,
  stepTogglerTextStyles,
  stepIconContainerStyles,
} from '../styles/stepToggler.styles';

type StepsTogglerProps = {
  showSteps: boolean,
  setShowSteps: Function,
};

const StepsToggler = ({ showSteps, setShowSteps }: StepsTogglerProps) => {
  return (
    <HStack {...stepTogglerContainerStyles}>
      <Stack {...stepIconContainerStyles}>
        <IconCircleOutline />
      </Stack>

      {showSteps ? (
        <BaselaneButton
          onClick={() => setShowSteps(false)}
          variant="transparent"
          palette="neutral"
          rightIcon={<IconChevronUp w={9} h={6} />}
        >
          <Text {...stepTogglerTextStyles}>Hide steps</Text>
        </BaselaneButton>
      ) : (
        <BaselaneButton
          onClick={() => setShowSteps(true)}
          variant="transparent"
          palette="neutral"
          rightIcon={<IconChevronDown w={9} h={6} />}
        >
          <Text {...stepTogglerTextStyles}>Show all steps</Text>
        </BaselaneButton>
      )}
    </HStack>
  );
};

export default StepsToggler;
