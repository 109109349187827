// @flow
import { React } from 'react';
import { Text, Stack, RadioGroup, ChakraProvider } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import habitatTheme from '../../../../themeHabitat';
import {
  radioGroupTitleStyles,
  radioGroupDescriptionStyles,
  radioGroupHelpTextStyles,
} from './styles/radioGroup.styles';

// https://www.figma.com/file/IzR58By56T8Dvo2tLLPCbP/%F0%9F%8C%80-*Baselane---Design-System-(Habitat)?type=design&node-id=1100-43724&mode=design&t=ZUxH6qjBdtkjNTBn-4

function BaselaneRadioGroup({
  title,
  description,
  helpText,
  name,
  onChange,
  isRequired,
  direction,
  children,
  ...props
}): any {
  return (
    <ChakraProvider theme={habitatTheme}>
      {title && <Text {...radioGroupTitleStyles}>{title}</Text>}
      {description && <Text {...radioGroupDescriptionStyles}>{description}</Text>}
      {children ? (
        <RadioGroup name={name} onChange={onChange} {...props}>
          <Stack spacing={1} my={1} direction={direction}>
            {children}
          </Stack>
        </RadioGroup>
      ) : null}
      {helpText && <Text {...radioGroupHelpTextStyles}>{helpText}</Text>}
    </ChakraProvider>
  );
}

BaselaneRadioGroup.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  helpText: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  children: PropTypes.node,
};

BaselaneRadioGroup.defaultProps = {
  name: null,
  title: null,
  description: null,
  direction: 'column',
  helpText: null,
  defaultValue: null,
  onChange: () => {},
  isRequired: false,
  children: null,
};

export default BaselaneRadioGroup;
