// @flow
import React, { useRef } from 'react';
import { Formik } from 'formik';
import { Box, Text } from '@chakra-ui/react';
import EditAccountForm from '../../EditAccountForm';
import { DeleteManualAccountAlert } from '../../EditAccountForm/Alerts';
import { handleValidation } from '../helpers/editManualDrawer.helpers';
import { updateVariablesHelper } from '../../EditAccountForm/helpers/editAccountForm.helpers';
import { smFormLabelStyles } from '../styles/accountEditDrawer.styles';

type AccountEditFormProps = {
  formikRef: any,
  initialAccount: Object,
  setFormVariables: Function,
  handleTerminateAccount: Function,
  setIsCheckedDuplicateTransactions: Function,
  setIsImportingAlertOpen: Function,
  setIsImportDisabledAlertOpen?: Function,
  handleDirtyChange?: Function,
};

function AccountEditForm({
  formikRef,
  initialAccount,
  setFormVariables,
  handleTerminateAccount,
  setIsCheckedDuplicateTransactions,
  setIsImportingAlertOpen,
  setIsImportDisabledAlertOpen,
  handleDirtyChange,
}: AccountEditFormProps): any {
  const { id: accountId } = initialAccount;
  const bankAccountFormValues = { nickName: initialAccount?.nickName };
  const importTransactionLabelRef = useRef();

  const handleFormUpdate = (fieldName, formValues, dirty) => {
    const variables = updateVariablesHelper(fieldName, formValues);
    setFormVariables(variables, dirty);
    handleDirtyChange(dirty);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={bankAccountFormValues}
      validate={(values) => handleValidation(values)}
      validateOnChange
    >
      {({ dirty, values, handleChange, handleBlur, setFieldValue, errors }) => (
        <form noValidate>
          <EditAccountForm
            {...{
              values,
              handleFormUpdate,
              handleBlur,
              handleChange,
              importTransactionLabelRef,
              dirty,
              setFieldValue,
              setIsImportingAlertOpen,
              setIsCheckedDuplicateTransactions,
              setIsImportDisabledAlertOpen,
              errors,
              isPlaidAccount: false,
            }}
          />

          {/* Terminate Button */}
          <Box mt="32px">
            <Text {...smFormLabelStyles}>
              Remove this external account from Baselane. You will no longer be able to set up new
              rent collection, transfers & payments, and import transactions from this account.
            </Text>
            <DeleteManualAccountAlert {...{ id: accountId, handleTerminateAccount }} />
          </Box>
        </form>
      )}
    </Formik>
  );
}

AccountEditForm.defaultProps = {
  handleDirtyChange: () => {},
  setIsImportDisabledAlertOpen: () => {},
};

export default AccountEditForm;
