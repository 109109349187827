import { isMobile } from 'react-device-detect';

// TODO: most of these are used in ExternalAccounts- delete these once that page is updated

export const accountsContentContainerStyles = {
  background: 'brand.neutral.white',
  mt: isMobile && '16px',
  boxShadow: isMobile && 'inset 0px -1px 0px #F0F2F6',
};

export const sectionTitleStyles = {
  fontSize: { lg: 'lg', base: 'xs' },
  fontWeight: 'semibold',
  lineHeight: { lg: '26px', base: '20px' },
  color: 'brand.neutral.700',
  whiteSpace: 'nowrap',
};
