// @flow
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { cloneDeep } from 'lodash';
import { Box, Stack, useToast } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { BaselaneButton, BaselaneErrorCard, BaselaneGrid } from '@shared/components';
import { Bank } from '@pages/LeasesPage/types';
import { getStatementPdf, generateStatementList } from './helpers/statement.helper';
import EmptyState from '../../../../Transactions/EmptyState';
import { GET_STATEMENTS } from '../../../queries';
import SlLoader from '../../../../Loader';
import getGridItems from './helpers/statementGridList.helper';
import { setTokenData, getNonSensitiveToken } from '../../../helpers/otp.helpers';

type StatementTableBodyProps = {
  banks: Array<Bank>,
  onLoadMore: Function,
  filters: Object,
  accountOptions: Array<Object>,
};

function StatementTableBody({
  banks,
  onLoadMore,
  filters,
  accountOptions,
}: StatementTableBodyProps): any {
  const { data, loading, error } = useQuery(GET_STATEMENTS, {
    variables: filters,
  });
  const [statements, setStatements] = useState([]);
  const [unitStatementId, setUnitStatementId] = useState('');
  const [bankId, setBankId] = useState('');

  useEffect(() => {
    if (filters.page > 1) {
      const clonedStatements = cloneDeep(statements);
      const completeStatements = generateStatementList(
        data?.statements?.data || [],
        accountOptions,
        banks
      );

      completeStatements.forEach((st) => {
        const foundStatementIndex = statements.findIndex((item) => item.id === st.id);
        if (foundStatementIndex === -1) {
          clonedStatements.push({ ...st });
        } else {
          clonedStatements[foundStatementIndex] = { ...st };
        }
      });

      // Sort order that we have is different from lodash
      setStatements(clonedStatements);
    } else {
      const completeStatements = generateStatementList(
        data?.statements?.data || [],
        accountOptions,
        banks
      );

      // Sort order that we have is different from lodash
      setStatements(completeStatements);
    }
  }, [data]);

  const toast = useToast();
  const showErrorToast = (text) =>
    toast({
      description: text,
      status: 'error',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const onNonSensitiveTokenComplete = (unitAPINonSensitiveToken) => {
    setTokenData(unitAPINonSensitiveToken, bankId, false);
    getStatementPdf(unitStatementId, unitAPINonSensitiveToken.token, statements, showErrorToast);
  };

  // will leave error as is until we have a more concrete way of showing
  // errors for this part
  const onNonSensitiveTokenError = () => {
    console.error('onNonSensitiveTokenError');
    showErrorToast('Something went wrong');
  };

  const [unitAPINonSensitiveToken] = getNonSensitiveToken(
    onNonSensitiveTokenComplete,
    onNonSensitiveTokenError
  );

  const gridItems = getGridItems({
    statements,
    setBankId,
    setUnitStatementId,
    unitAPINonSensitiveToken,
    showErrorToast,
  });

  const totalStatementCount = data?.statements.total || 0;
  const hasMoreStatements = statements.length < totalStatementCount;

  if (error) return <BaselaneErrorCard />;
  if (loading) return <SlLoader />;

  return statements.length > 0 ? (
    <Stack justify="center">
      <BaselaneGrid
        gridStyles={{ w: '100%' }}
        gridItemStyles={{
          minH: '65px',
          p: isMobile ? '6px' : '12px 24px',
          display: 'flex',
          alignItems: 'center',
        }}
        gridItems={gridItems}
        templateColumns={isMobile ? '3fr 4fr 1fr' : '7fr 190px 105px'}
      />
      {hasMoreStatements ? (
        <Box my="1" textAlign="center">
          <BaselaneButton
            size="lg"
            variant="outline"
            palette="neutral"
            onClick={onLoadMore}
            isLoading={loading}
          >
            Load more
          </BaselaneButton>
        </Box>
      ) : null}
    </Stack>
  ) : (
    <EmptyState
      noTransactionsFound
      title="No Statements Found"
      paragraph="Try changing or clearing your search filters to view more statements"
    />
  );
}

export default StatementTableBody;
