import React from 'react';
import { Stack, Box } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { bulletTextStyles, listBox } from './styles/noteList.styles';

type NoteListProps = { listContent: Object };

function NoteList({ listContent }: NoteListProps) {
  return (
    !!listContent && (
      <Stack my="24px!important">
        {listContent.map((item, index) => (
          <Stack direction="row" {...listBox.disclaimer} key={uuidv4()}>
            <Box as="span" {...bulletTextStyles}>
              {item.id}
            </Box>
            <Box>{item.text}</Box>
          </Stack>
        ))}
      </Stack>
    )
  );
}

export default NoteList;
