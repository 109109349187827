import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HStack, Flex, Box } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';

import { ADD_FUNDS_BANK_TRANSFER } from '@routes';
import getBreakPoints from '@core/utils/getBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import DisableMoveMoney from '@core/components/DisableMoveMoney';
import { addFundsSectionStyles, addFundsDescriptionStyles } from './styles/summary.styles';

type AddFundsProps = {
  onCloseBtnClick: Function,
  amount: String,
  currentApy: String,
  hasCollectedRentWithinNeededPeriod: Boolean,
  baselaneBankBalance: Number,
  activeConfig: Object,
};

const amountFont = { fontWeight: 'semibold', display: 'inline-block' };
const amountToAdd = (amount) => {
  return <Flex {...amountFont}>{amount}</Flex>;
};

const AddFunds = ({
  onCloseBtnClick,
  amount,
  baselaneBankBalance,
  hasCollectedRentWithinNeededPeriod,
  currentApy,
  activeConfig,
}: AddFundsProps) => {
  const { isMinXL } = getBreakPoints();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <HStack {...addFundsSectionStyles}>
      <Box {...addFundsDescriptionStyles}>
        Add {amountToAdd(amount)} {isMinXL ? <br /> : ''}
        to unlock next APY tier{' '}
      </Box>
      <DisableMoveMoney
        styles={{ flex: 0 }}
        tooltipText="Verify your email to add funds"
        renderItem={(isDisabled) => (
          <BaselaneButton
            isDisabled={isDisabled}
            variant="filled"
            palette="primary"
            onClick={() => {
              sendSegmentEvent('baselane_banking_home_apy_click_add_funds', {
                amount,
                available_balance: baselaneBankBalance,
                collecting_rent: hasCollectedRentWithinNeededPeriod,
                current_apy: currentApy ?? '',
                next_month_apy: activeConfig?.value ?? '',
              });

              navigate(`${location.pathname}/${ADD_FUNDS_BANK_TRANSFER}`, {
                state: { from: location, amount },
              });

              onCloseBtnClick();
            }}
          >
            Add funds
          </BaselaneButton>
        )}
      />
    </HStack>
  );
};

export default AddFunds;
