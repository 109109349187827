export const headingStyles = {
  size: 'headline-md',
  color: 'brand.neutral.700',
  fontFamily: 'fonts.heading',
  ml: '12px',
};

const disabledTrackerStyles = {
  opacity: 1,
  borderColor: 'brand.darkBlue.300',
};

export const trackerStyles = (showHover) => ({
  border: '1px solid #257ED0',
  background: 'brand.neutral.white',
  borderRadius: '12px',
  width: '100%',
  height: { xl: '176px', lg: '172px' },
  padding: '24px',
  cursor: 'pointer',
  display: 'initial',
  flexDirection: 'initial',
  justifyContent: 'initial',
  ml: { xl: '12px !important', lg: '4px  !important' },
  _hover: showHover && { bg: 'brand.blue.700', color: 'brand.neutral.white' },
  _active: showHover && { bg: 'brand.blue.800A', color: 'brand.neutral.white' },
  ...(!showHover && { cursor: 'default', ...disabledTrackerStyles }),
});

export const completedTrackerStyles = {
  background: 'brand.neutral.900',
  borderColor: 'brand.neutral.900',
  cursor: 'pointer',
};

export const swapHeaderItemStyles = {
  flexDirection: 'row',
};

export const mobileContentItemStyles = {
  justifyContent: 'inherit',
};

export const headerContainerStyles = {
  alignItems: 'center',
  height: 'initial',
};

export const mobileContentBoxStyles = {
  ...mobileContentItemStyles,
  w: '100%',
};

export const trackerHeaderStyles = {
  fontSize: 'calc(11px + 1.5 * ((100vw - 1270px) / 530))',
  fontWeight: 'semibold',
  lineHeight: 'calc(20px + 1.5 * ((100vw - 1270px) / 530))',
  marginLeft: 'inherit',
};

export const trackerHideBlockStyles = {
  display: 'flex',
  ml: '10px !important',
};

export const trackerHeaderCompletedStyles = {
  color: 'brand.neutral.white',
};

export const trackerSuccessMessageCompletedStyles = {
  fontSize: 'calc(9px + 3 * ((100vw - 899px) / 371))',
  fontWeight: 'semibold',
  lineHeight: 'calc(20px + 1.5 * ((100vw - 1270px) / 530))',
};

export const disabledTrackerHeaderStyles = {
  color: 'brand.darkBlue.300',
  borderColor: 'brand.darkBlue.300',
};

export const trackerStepStyles = {
  border: '1px solid #257ED0',
  boxSizing: 'border-box',
  color: 'brand.blue.700',
  padding: '5px',
  borderRadius: '50%',
  height: '32px',
  width: '32px',
  textAlign: 'center',
  textStyle: 'headline-xs',
  bg: 'brand.neutral.white',
};

export const trackerStepCompletedStyles = {
  color: 'brand.neutral.white',
};

export const trackerDescriptionStyles = {
  fontSize: 'calc(11px + 2 * ((100vw - 1270px) / 530))',
  fontWeight: 'normal',
  lineHeight: { xl: '20px', lg: '17px' },
  height: '60px',
  display: 'inherit',
  marginTop: '8px',
};

export const descriptionHeaderStyles = {
  fontWeight: 'semibold',
  fontSize: 'calc(12px + 1.5 * ((100vw - 1270px) / 530))',
  lineHeight: { xl: '20px', lg: '17px' },
};

export const trackerDescriptionCompletedStyles = {
  color: 'brand.darkBlue.250',
};

export const trackerTimeStyles = {
  fontSize: '3xs',
  lineHeight: '14px',
  fontWeight: 'normal',
  marginLeft: '4px',
};

export const trackerLowerBlockStyles = {
  marginTop: '8px',
};

export const closeButtonStyles = {
  color: 'brand.neutral.500',
  _hover: { cursor: 'pointer' },
};

export const showDesktopStyles = {
  display: 'flex',
};

export const buttonMobileStyles = {
  fontSize: 'xs',
  height: '26px',
};

export const iconMobileStyles = {
  ml: '10px!important',
};

export const startButtonStyles = {
  height: '26px',
  pt: '8px',
  hover: { color: 'brand.neutral.white' },
};
