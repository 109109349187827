import React from 'react';
import Success from '@shared/components/TransferFunds/components/Body/Success';
import SharedHeader from './SharedHeader';
import Step1 from './Step1';
import Step2 from './Step2';

type BodyProps = {
  formikProps: Object,
  currentStep: Number,
  totalSteps: Number,
  unsplitTagId: Object,
  setUnsplitTagId: Function,
  transferType: String,
  setTransferType: Function,
  getUpdateLinkToken: Function,
  fromDropdownList: Array<Object>,
  formattedPlaidAcc: Array<Object>,
  formattedBaselaneAcc: Array<Object>,
  accountsMap: Object,
  propertyIdsMap: Object,
  categoryMap: Object,
  categoryIdsMap: Object,
  categoryOptions: Array<Object>,
  propertyOptions: Array<Object>,
  refetch: Function,
  state: Object,
};

const Body = ({
  formikProps,
  currentStep,
  totalSteps,
  unsplitTagId,
  setUnsplitTagId,
  transferType,
  setTransferType,
  getUpdateLinkToken,
  fromDropdownList,
  formattedPlaidAcc,
  formattedBaselaneAcc,
  accountsMap,
  propertyIdsMap,
  categoryMap,
  categoryIdsMap,
  categoryOptions,
  propertyOptions,
  refetch,
  state,
}: BodyProps) => {
  const getBodyForCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            {...{
              formikProps,
              setTransferType,
              getUpdateLinkToken,
              fromDropdownList,
              formattedPlaidAcc,
              formattedBaselaneAcc,
              accountsMap,
              refetch,
              state,
            }}
          />
        );
      case 2:
        return (
          <Step2
            {...{
              formikProps,
              transferType,
              unsplitTagId,
              accountsMap,
              propertyIdsMap,
              categoryMap,
            }}
          />
        );

      default:
        return <Success transferType="TRANSFER_IN" />;
    }
  };

  return (
    <>
      {currentStep !== totalSteps && (
        <SharedHeader {...{ currentStep, totalSteps, transferType }} />
      )}
      {getBodyForCurrentStep()}
    </>
  );
};

export default Body;
