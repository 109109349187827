// @flow
import React, { useContext } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import {
  BaselaneDivider,
  TabsComponent,
  TabComponent,
  TabNumber,
  TabListComponent,
} from '@shared/components';
import { FormTitle } from '@shared/components/BaselaneFlow';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import TabIcon from '@shared/components/BaselaneFlow/Tabs/TabComponent/TabIcon';
import type { FlowPanelProps } from '@shared/components/BaselaneFlow/types/FlowTabPanelProps';
import { tabListMenu } from '@shared/components/BaselaneFlow/Tabs/helpers/tab.helpers';
import userContext from '@contexts/UserContext';
import {
  cStyles,
  tabMenuTitleTextStyles,
  dashStyles,
} from '@core/components/OnboardingTriage/styles/flowSections.styles';

import ApplicationType from './ApplicationType';
import SolePropPanels from './SolePropPanels';
import BusinessPanels from './BusinessPanels';
import { applicationSegmentEventNames } from '../helpers/createApplication.helpers';

type BankingFormTabsProps = {
  applicationMenuOptions: Array<FlowPanelProps>,
  unitApplicationStatus: String,
  tabIndex: Number,
  setTabIndex: Function,
  formRefs: any,
  handleSolePropContinue: Function,
  formData: Object,
  submitLBDataLoading: boolean,
  isRefetchUserLoading: boolean,
  applicationType: string,
  setApplicationType: Function,
  showVerifyPopup: boolean,
  setShowVerifyPopup: Function,
  onVerifyOtpSuccess: Function,
  hasSubmitErrors: boolean,
  setHasSubmitErrors: Function,
  submitErrors: Array<String>,
  setSubmitErrors: Function,
  submitErrorsTitle: String,
  setSubmitErrorsTitle: Function,
};

function BankingFormTabs({
  applicationMenuOptions,
  unitApplicationStatus,
  tabIndex,
  setTabIndex,
  formRefs,
  handleSolePropContinue,
  formData,
  submitLBDataLoading,
  isRefetchUserLoading,
  applicationType,
  setApplicationType,
  showVerifyPopup,
  setShowVerifyPopup,
  onVerifyOtpSuccess,
  hasSubmitErrors,
  setHasSubmitErrors,
  submitErrors,
  setSubmitErrors,
  submitErrorsTitle,
  setSubmitErrorsTitle,
}: BankingFormTabsProps): any {
  const { user } = useContext(userContext);
  const segmentEvents = applicationSegmentEventNames(user?.onboardingCompleted);

  const handleSegmentEventRequest = (tabI) => sendSegmentEvent(segmentEvents[tabI]);

  const handleTabChange = (i) => {
    const form = formRefs[tabIndex];

    if (form) {
      // validate form before moving on to next tab if form is not valid do not move to next tab
      form?.current?.validateForm().then((errors) => {
        if (i < tabIndex) {
          // when user goes to previous tab by clicking
          if (Object.keys(errors).length === 0) {
            handleSolePropContinue({ isCreateApplicationSave: false, isTabClick: true });
            setTabIndex(i);
          } else if (tabIndex === 3 && errors?.agreeToTerms && Object.keys(errors).length === 1) {
            handleSolePropContinue({ isCreateApplicationSave: false, isTabClick: true });
            setTabIndex(i);
          }
          form?.current?.setTouched(errors, true);
        } else if (Object.keys(errors).length === 0) {
          // no errors, we can go to previous tabs or next tab
          setTabIndex(i);
          if (unitApplicationStatus !== 'FORM_COMPLETED') handleSegmentEventRequest(tabIndex);
          // save but no submit and no setting tabIndex
          handleSolePropContinue({ isCreateApplicationSave: false, isTabClick: true });
        } else {
          form?.current.setTouched(errors, true);
        }
      });
    } else if (unitApplicationStatus !== 'FORM_COMPLETED') {
      setTabIndex(i);
    }
  };

  return (
    <TabsComponent
      {...{
        tabIndex,
        handleTabChange,
      }}
      styles={{ mt: 2, minHeight: 'unset', height: '100%' }}
    >
      {tabIndex !== null && !isMobile && (
        <TabListComponent>
          <FormTitle
            title="Steps to open a banking account"
            textStyles={tabMenuTitleTextStyles}
            containerStyles={cStyles}
            styles={{ height: '100%' }}
          />
          {tabListMenu(applicationMenuOptions).map((option, index) => {
            return (
              <TabComponent
                key={option.id}
                {...{ menuOptions: applicationMenuOptions, option, index }}
              >
                {option.isValid && (
                  <TabIcon {...{ menuOptions: applicationMenuOptions, option, index, tabIndex }} />
                )}
                {!option.isValid && (
                  <TabNumber {...{ menuOptions: applicationMenuOptions, option, index }} />
                )}
                {isMobile && index < 3 && <BaselaneDivider styles={{ ...dashStyles }} />}
                {!isMobile && <Text ml="8px">{option.label}</Text>}
              </TabComponent>
            );
          })}
        </TabListComponent>
      )}

      {tabIndex === null && (
        <Flex w="100%" h="100%" justifyContent="center">
          <ApplicationType {...{ handleSolePropContinue, setApplicationType }} />
        </Flex>
      )}

      {tabIndex !== null && applicationType === 'sole' && (
        <SolePropPanels
          {...{
            applicationMenuOptions,
            tabIndex,
            setTabIndex,
            formRefs,
            handleSolePropContinue,
            formData,
            submitLBDataLoading,
            isRefetchUserLoading,
            showVerifyPopup,
            setShowVerifyPopup,
            onVerifyOtpSuccess,
            hasSubmitErrors,
            setHasSubmitErrors,
            submitErrors,
            setSubmitErrors,
            submitErrorsTitle,
            setSubmitErrorsTitle,
          }}
        />
      )}

      {tabIndex !== null && applicationType === 'business' && <BusinessPanels />}
    </TabsComponent>
  );
}

export default BankingFormTabs;
