import React from 'react';

function IconMailCheckDeposit() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35 11H7C5.89543 11 5 11.8954 5 13V17.3684V31C5 32.1046 5.89543 33 7 33H17.3636H35C36.1046 33 37 32.1046 37 31V27.5V17.3684V13C37 11.8954 36.1046 11 35 11Z"
        fill="#8CCFFF"
      />
      <path
        d="M8 22.4615C8 23.6511 9.04467 24.6154 10.3333 24.6154H12.5C13.8807 24.6154 15 23.5822 15 22.3077C15 21.0332 13.8807 20 12.5 20H10.5C9.11929 20 8 18.9668 8 17.6923C8 16.4178 9.11929 15.3846 10.5 15.3846H12.6667C13.9553 15.3846 15 16.3489 15 17.5385M11.5 14V15.3846M11.5 24.6154V26"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 9H5C3.89543 9 3 9.89543 3 11V15.3684V29C3 30.1046 3.89543 31 5 31H15.3636H33C34.1046 31 35 30.1046 35 29V25.5V15.3684V11C35 9.89543 34.1046 9 33 9Z"
        stroke="#000619"
        strokeLinecap="round"
      />
      <path d="M19 17H31M19 20H29M19 23H26" stroke="#000619" strokeLinecap="round" />
    </svg>
  );
}

export default IconMailCheckDeposit;
