import React from 'react';

function IconSparkles({ w, h, color }: { w?: Number, h?: Number, color?: String }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1176 5L20.8646 13.1354L29 14.8824L20.8646 16.6293L19.1176 24.7647L17.3707 16.6293L9.23529 14.8824L17.3707 13.1354L19.1176 5Z"
        fill={color}
      />
      <path
        d="M9.94118 19.1177L10.8147 23.1854L14.8824 24.0589L10.8147 24.9323L9.94118 29L9.06769 24.9323L5 24.0589L9.06769 23.1854L9.94118 19.1177Z"
        fill={color}
      />
      <path
        d="M17.1177 3L18.8647 11.1354L27.0001 12.8824L18.8647 14.6293L17.1177 22.7647L15.3707 14.6293L7.23535 12.8824L15.3707 11.1354L17.1177 3Z"
        fill={color}
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.94118 17.1177L8.81466 21.1854L12.8824 22.0589L8.81466 22.9323L7.94118 27L7.06769 22.9323L3 22.0589L7.06769 21.1854L7.94118 17.1177Z"
        fill={color}
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconSparkles.defaultProps = {
  w: 32,
  h: 32,
  color: '#8AD95F',
};

export default IconSparkles;
