import React from 'react';
import { Box } from '@chakra-ui/react';
import { listStyles } from '@shared/components/BaselaneDropdown/components/styles/dropdown.styles';
import {
  dividerStyles,
  menuItemTextStyles,
  menuOptionStyles,
} from '@shared/components/BaselaneDropdown/components/T2/styles/t2Dropdown.styles';
import BaselaneDivider from '../../../../BaselaneDivider';
import { setIsSelectedItem } from '../../helpers/dropdown.shared.helpers';

type ListOfParentAndChildrenProps = {
  isMulti: boolean,
  options: Array<any>,
  optionStyles: any,
  parentItemRenderer: Function,
  childItemRenderer: Function,
  dropdownElementRef: any,
  hasFilterWrapper: boolean,
  search: string,
  hideSearch: boolean,
  dropDown: Function,
  handleListItemClick: Function,
  selectedStagedOptions: Array,
};

const ListOfParentAndChildren = ({
  isMulti,
  options,
  optionStyles,
  parentItemRenderer,
  childItemRenderer,
  dropdownElementRef,
  hasFilterWrapper,
  search,
  hideSearch,
  dropDown,
  handleListItemClick,
  selectedStagedOptions,
}: ListOfParentAndChildrenProps) => {
  return (
    <Box
      {...{
        ...listStyles(hasFilterWrapper),
        pb: '8px',
        mt: hideSearch ? '16px' : '0',
      }}
      ref={dropdownElementRef}
    >
      {options
        ?.filter((item) => {
          const isParent = Array.isArray(item.searchValues);
          return isParent
            ? item.searchValues?.some(
                (value) => value.toLowerCase().indexOf(search.toLowerCase()) !== -1
              )
            : item.searchValues.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        })
        .map((item, index) => {
          const { id, isChild } = item;

          return isChild ? (
            <Box
              tabIndex="-1"
              className="element"
              id={item.id}
              key={id}
              {...{
                ...menuItemTextStyles,
                ...{
                  ...menuOptionStyles(setIsSelectedItem(item, selectedStagedOptions), isChild),
                  ...optionStyles,
                },
              }}
              onClick={(e) => {
                handleListItemClick(item, e);
                if (!isMulti) {
                  dropDown('close');
                }
              }}
            >
              {childItemRenderer({ item, search })}
            </Box>
          ) : (
            <Box key={id} id={item.id}>
              <BaselaneDivider styles={dividerStyles(index === 0)} />
              <Box
                tabIndex="-1"
                className="element"
                id={item.id}
                {...{
                  ...{
                    ...menuOptionStyles(setIsSelectedItem(item, selectedStagedOptions), isChild),
                    ...optionStyles,
                  },
                }}
                onClick={(e) => {
                  handleListItemClick(item, e);
                  if (!isMulti) {
                    dropDown('close');
                  }
                }}
              >
                {parentItemRenderer({ item, search })}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default ListOfParentAndChildren;
