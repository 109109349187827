import React from 'react';
import { Box, Spacer, Text, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { useField, Field } from 'formik';
import BaselaneFormErrorMessage from '@core/components/Shared/components/BaselaneForm/BaselaneFormErrorMessage';
import {
  T2WithTitleDropdown,
  BaselaneTextArea,
  T2Dropdown,
  NoPropertyTooltip,
} from '@shared/components';
import { itemRenderer } from '@core/components/Shared/components/BaselaneDropdown-new/helpers/itemRenderer.helpers';
// The following two components are imported from [Transactions] module - move them to shared one?
import DisplayInputDefaultCategoryItem from '@core/components/Transactions/DisplayInputVariations/DisplayInputDefaultCategory/index';
import DisplayInputDefaultPropertyItem from '@core/components/Transactions/DisplayInputVariations/DisplayInputDefaultProperty/index';
import useCategories from '../../../../../../features/Category/useCategories';
import useProperties from '../../../../../../features/Property/useProperties';

const BookkeepingDetails = () => {
  const { categoriesForDropdown, categoriesForDropdownById } = useCategories({
    sortCategories: false,
  });
  const { propertiesForDropdown, propertiesByIdForDropdownById } = useProperties();

  const categoryIdField = useField('categoryId');
  const propertyIdField = useField('propertyId');

  // At position 2, the helpers of fields are returned. More: https://formik.org/docs/api/useField#reference
  const setCategoryId = categoryIdField[2].setValue;
  const setPropertyId = propertyIdField[2].setValue;

  return (
    <Box>
      <Flex>
        <Text textStyle="md-heavy">Bookkeeping details</Text>
        <Spacer />
        <Text textStyle="xs">Step 3 of 4</Text>
      </Flex>
      <Box mt={0.5} mb={2}>
        <Text textStyle="sm">
          Tag this check deposit to a property & category to automate your analytics & reporting.
        </Text>
      </Box>
      {/* Category */}
      <Field name="categoryId">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.categoryId && form.touched.categoryId}>
            <FormLabel display="flex">
              Category
              <Text color="brand.neutral.500" ml={0.5}>
                (optional)
              </Text>
            </FormLabel>
            <T2WithTitleDropdown
              additionalProps={{
                id: 'categoryId',
              }}
              classNames={['input-form-lg', 'is-full-width']}
              data={categoriesForDropdown}
              title="Category"
              showValueByFields={['name']}
              placeholder="Select"
              parentItemRenderer={itemRenderer}
              childItemRenderer={itemRenderer}
              handleSubmit={setCategoryId}
              selectedItem={categoriesForDropdownById[field.value]}
              isMulti={false}
              hasFilterWrapper={false}
              CustomDisplayInput={DisplayInputDefaultCategoryItem}
            />
            <BaselaneFormErrorMessage isInvalid={form.errors.categoryId && form.touched.categoryId}>
              {form.errors.categoryId}
            </BaselaneFormErrorMessage>
          </FormControl>
        )}
      </Field>

      {/* Property */}
      <Field name="propertyId">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.propertyId && form.touched.propertyId}>
            <FormLabel display="flex">
              Property
              <Text color="brand.neutral.500" ml={0.5}>
                (optional)
              </Text>
            </FormLabel>
            {propertiesForDropdown.length > 0 ? (
              <T2Dropdown
                additionalProps={{
                  id: 'propertyId',
                }}
                classNames={['input-form-lg', 'is-full-width']}
                data={propertiesForDropdown}
                searchTerm={['name']}
                title="Property"
                showValueByFields={['name']}
                placeholder="Select"
                //  The following renderers are copied from "create manual transaction feature".
                parentItemRenderer={itemRenderer}
                childItemRenderer={itemRenderer}
                handleSubmit={setPropertyId}
                selectedItem={propertiesByIdForDropdownById[field.value]}
                isMulti={false}
                hasFilterWrapper={false}
                CustomDisplayInput={DisplayInputDefaultPropertyItem}
              />
            ) : (
              <NoPropertyTooltip>
                <T2Dropdown
                  isDisabled
                  additionalProps={{
                    id: 'propertyId',
                  }}
                  classNames={['input-form-lg', 'is-full-width']}
                  data={propertiesForDropdown}
                  title="Property"
                  placeholder="Select"
                  CustomDisplayInput={DisplayInputDefaultPropertyItem}
                />
              </NoPropertyTooltip>
            )}
            <BaselaneFormErrorMessage isInvalid={form.errors.propertyId && form.touched.propertyId}>
              {form.errors.propertyId}
            </BaselaneFormErrorMessage>
          </FormControl>
        )}
      </Field>

      {/* Notes */}
      <Field name="notes">
        {({ field, form }) => (
          <FormControl isInvalid={form.errors.notes && form.touched.notes}>
            <FormLabel display="flex">
              Notes
              <Text color="brand.neutral.500" ml={0.5}>
                (optional)
              </Text>
            </FormLabel>
            <BaselaneTextArea {...field} placeholder="" />
            <BaselaneFormErrorMessage isInvalid={form.errors.notes && form.touched.notes}>
              {form.errors.notes}
            </BaselaneFormErrorMessage>
          </FormControl>
        )}
      </Field>
    </Box>
  );
};

export default BookkeepingDetails;
