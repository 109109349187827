import React from 'react';
import { IconPropsType } from '@core/components/Icons/legacy/types/icon.types';

function IconLightningOutline({ w, h, color }: IconPropsType) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8908 1.37087C9.15618 1.4656 9.33334 1.71695 9.33334 1.99873V5.99873H13.3333C13.5909 5.99873 13.8255 6.14713 13.9358 6.37991C14.0461 6.61269 14.0124 6.88819 13.8493 7.08756L7.84931 14.4209C7.67088 14.639 7.3746 14.7213 7.10922 14.6266C6.84384 14.5319 6.66667 14.2805 6.66667 13.9987V9.99873H2.66667C2.40908 9.99873 2.17454 9.85033 2.06423 9.61755C1.95392 9.38477 1.98758 9.10927 2.1507 8.90991L8.1507 1.57658C8.32914 1.35849 8.62542 1.27614 8.8908 1.37087ZM4.0735 8.6654H7.33334C7.70153 8.6654 8.00001 8.96388 8.00001 9.33207V12.1311L11.9265 7.33207H8.66667C8.29848 7.33207 8.00001 7.03359 8.00001 6.6654V3.86634L4.0735 8.6654Z"
        fill={color}
      />
    </svg>
  );
}

IconLightningOutline.defaultProps = {
  w: 16,
  h: 16,
  color: 'currentColor',
};

export default IconLightningOutline;
