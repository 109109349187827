import React from 'react';
import { orderBy } from 'lodash';
import { BaselaneButton, TrackerBox } from '@shared/components';
import { TrackersConfig } from '../helpers/trackerboxConfig.helper';

type AddEditPropertyButtonProps = {
  property?: Object,
  buttonLabel?: String,
  buttonStyles?: Object,
  variant?: String,
  palette?: String,
  showTracker: boolean,
  leftIcon: Function,
  financials: boolean,
  isDelete: boolean,
  canDeleteProperty: boolean,
  handleDelete: Function,
  addMobilePropertyOnClick: Function,
  btnRef: any,
};

function AddEditPropertyButton({
  property,
  buttonLabel,
  buttonStyles,
  variant,
  palette,
  leftIcon,
  showTracker = false,
  financials = false,
  isDelete,
  canDeleteProperty,
  handleDelete,
  addMobilePropertyOnClick,
  btnRef,
}: AddEditPropertyButtonProps) {
  const {
    marketPrice,
    mortgageBalance: mortgageBalanceData,
    purchasePrice,
    purchaseDate,
    closingCosts,
    annualPropertyInsurance,
    annualPropertyTaxes,
    mortgageDownpayment,
    mortgageTerm,
  } = property || {};
  const [latestMarketValue] = orderBy(marketPrice, ['date'], ['desc']);
  const [latestMortgageBalance] = orderBy(mortgageBalanceData, ['date'], ['desc']);
  const marketValue = latestMarketValue ? latestMarketValue?.price : null;
  const mortgageBalance = latestMortgageBalance ? latestMortgageBalance?.balance : null;
  const hasFinancialData =
    marketValue ||
    purchasePrice ||
    mortgageBalance ||
    purchaseDate ||
    closingCosts ||
    annualPropertyInsurance ||
    annualPropertyTaxes ||
    mortgageDownpayment ||
    mortgageTerm;

  const configProperties = {
    ...TrackersConfig.properties,
    onClickTrackerBox: (e) => {
      addMobilePropertyOnClick(e);
    },
    isCompleted: property,
    customCompletedTrackerStyles: { m: 0, background: 'brand.darkBlue.800A' },
  };

  const configFinancials = {
    ...TrackersConfig.financials,
    onClickTrackerBox: (e) => {
      addMobilePropertyOnClick(e);
    },
    isDisabled: !property,
    isCompleted: hasFinancialData,
    customCompletedTrackerStyles: { m: 0, background: 'brand.darkBlue.800A' },
  };

  return !showTracker ? (
    <BaselaneButton
      ref={btnRef}
      isDisabled={isDelete ? !canDeleteProperty : false}
      onClick={isDelete ? handleDelete : addMobilePropertyOnClick}
      variant={variant}
      palette={palette}
      leftIcon={leftIcon}
      {...buttonStyles}
    >
      {buttonLabel}
    </BaselaneButton>
  ) : (
    <TrackerBox trackerData={financials ? configFinancials : configProperties} />
  );
}

AddEditPropertyButton.defaultProps = {
  property: null,
  buttonLabel: 'Add Property',
  buttonStyles: {},
  variant: 'filled',
  palette: 'primary',
};

export default AddEditPropertyButton;
