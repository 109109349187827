import { React, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { Stack, Text, FormControl, Spinner } from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/client';
import { Icon24PlusCircle } from '@icons/24px';
import { GET_PAYEE } from '@core/apollo/queries';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import {
  BaselaneButton,
  BaselaneCardNew,
  BaselaneFormLabel,
  BaselaneFormErrorMessage,
  T1Dropdown,
} from '@shared/components';
import { IllustrationBankingAddexternalAccount } from '@illustrations';
import useRecipientsHook from '../../../../hooks/useRecipients';
import DropDownDisplayInput from './PaymentMethodStep/DropDownDisplayInput';
import {
  renderRecipientsDropdownItem,
  transformRecipientPaymentMethods,
  steps,
} from '../../helpers/formHelpers';
import {
  useSetCurrentStep,
  useUpdateRecipient,
  useRecipient,
  useSetSelectedPaymentMethod,
  useSetIsCurrentStepDisabled,
  useSetIsCurrentStepLoading,
  useUpdateServerRecipient,
} from '../../sendFundsStore';

import { spinnerStyles } from '../../styles';

const SelectRecipient = () => {
  const formik = useFormikContext();
  const { recipientId } = useParams();
  const { recipients, isRecipientsLoading } = useRecipientsHook();
  const setIsCurrentStepDisabled = useSetIsCurrentStepDisabled();
  const setIsCurrentStepLoading = useSetIsCurrentStepLoading();
  const setCurrentStep = useSetCurrentStep();
  const updateRecipient = useUpdateRecipient();
  const updateServerRecipient = useUpdateServerRecipient();
  const recipient = useRecipient();
  const setSelectedPaymentMethod = useSetSelectedPaymentMethod();

  const { values, errors, touched, setFieldValue, resetForm } = formik;
  const { payeeId } = values;

  const handleSetRecipient = (newRecipient) => {
    updateRecipient(transformRecipientPaymentMethods(newRecipient));
    updateServerRecipient(newRecipient);
  };

  // Get payee
  const [getPayee, { loading, error }] = useLazyQuery(GET_PAYEE, {
    skip: !payeeId,
    onCompleted: (res) => {
      handleSetRecipient(res.payee);
    },
  });

  // Disable next step if there is an error or selected recipient has not loaded yet
  if (loading || error) {
    setIsCurrentStepDisabled(true);
    setIsCurrentStepLoading(true);
  } else {
    setIsCurrentStepDisabled(false);
    setIsCurrentStepLoading(false);
  }

  useEffect(() => {
    if (payeeId || recipientId) {
      getPayee({
        variables: { id: payeeId },
      });
    }
  }, [payeeId]);

  if (isRecipientsLoading) return <Spinner {...spinnerStyles} />;
  return recipients?.length > 0 ? (
    <>
      <FormControl
        id="send-funds-recipients-list"
        isInvalid={errors.payeeId && touched.payeeId}
        isRequired
      >
        <BaselaneFormLabel textStyle="sm" htmlFor="payeeId">
          Recipient
        </BaselaneFormLabel>
        <T1Dropdown
          name="payeeId"
          additionalProps={{ id: 'send-funds-recipients-dropdown' }}
          classNames={[
            'input-form-xl',
            'auto-select-input-width',
            ...(errors.payeeId && touched.payeeId ? ['input-invalid'] : []),
          ]}
          data={recipients}
          selectedItem={recipients?.find((r) => r.id === values?.payeeId || r.id === recipient?.id)}
          handleSubmit={(selectedValue) => {
            setFieldValue('payeeId', Array.isArray(selectedValue) ? '' : selectedValue);
            setFieldValue('paymentMethodId', '');
            setSelectedPaymentMethod({});
          }}
          placeholder="Select"
          title="Recipient"
          searchTerm={['name']}
          showValueByFields={['name']}
          itemRenderer={renderRecipientsDropdownItem}
          isMulti={false}
          hasFilterWrapper={false}
          hasCheckboxes={false}
          showDivider={false}
          CustomDisplayInput={DropDownDisplayInput}
          parentId="drawer-body"
          onClearClick={() => {
            resetForm();
            setSelectedPaymentMethod({});
            updateRecipient(transformRecipientPaymentMethods({}));
          }}
        />

        <BaselaneFormErrorMessage isInvalid={errors.payeeId && touched.payeeId}>
          {errors.payeeId}
        </BaselaneFormErrorMessage>
      </FormControl>

      <BaselaneButton
        variant="transparent"
        palette="primary"
        size="md"
        pullLeft
        leftIcon={<Icon24PlusCircle />}
        onClick={() => {
          updateRecipient(transformRecipientPaymentMethods({}));
          updateServerRecipient({});
          setCurrentStep(steps.ADD_RECIPIENT);
          setSelectedPaymentMethod({});
          resetForm();
          sendSegmentEvent('baselane_banking_step_1_complete', {
            saved_recipient: false,
          });
        }}
      >
        Add a recipient
      </BaselaneButton>
    </>
  ) : (
    <BaselaneCardNew palette="dark" p={5}>
      <Stack {...{ spacing: 2, align: 'center', w: '100%' }}>
        <IllustrationBankingAddexternalAccount />
        <Text textStyle="sm">To send money, add your first recipient</Text>
        <BaselaneButton
          variant="filled"
          palette="primary"
          size="md"
          onClick={() => {
            updateRecipient(transformRecipientPaymentMethods({}));
            updateServerRecipient({});
            setCurrentStep(steps.ADD_RECIPIENT);
            setSelectedPaymentMethod({});
            resetForm();
            sendSegmentEvent('baselane_banking_step_1_complete', {
              saved_recipient: false,
            });
          }}
        >
          Add recipient
        </BaselaneButton>
      </Stack>
    </BaselaneCardNew>
  );
};

export default SelectRecipient;
