import { gql } from '@apollo/client';

export const GET_CHECKBOOK_PREVIEW = gql`
  query CheckBookPreviewImage($bankAccountId: Float!) {
    checkBookPreviewImage(bankAccountId: $bankAccountId) {
      imageEncodedString
      imageType
    }
  }
`;

export const ORDER_CHECKBOOK = gql`
  mutation OrderCheckbook(
    $bankAccountId: ID!
    $feeFromBankAccountId: ID!
    $recipientAddress: UnitAddressInput!
  ) {
    orderCheckBook(
      input: {
        bankAccountId: $bankAccountId
        feeFromBankAccountId: $feeFromBankAccountId
        recipientAddress: $recipientAddress
      }
    ) {
      id
      bankAccountId
      orderedAt
      externalId
      status
      startNumber
      endNumber
    }
  }
`;
