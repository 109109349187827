/* eslint-disable react/prop-types */
import { React } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { BaselaneDrawer } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import habitatTheme from '@core/themeHabitat';
import RecurringPaymentDetails from './RecurringPaymentDetails';
import OneTimePaymentDetails from './OneTimePaymentDetails';

const ScheduledPaymentDetailsDrawer = ({ from }) => {
  const { isMax576 } = getBreakPoints();
  const navigate = useNavigate();
  const { scheduledPaymentId } = useParams();

  const [transferId, recurringPaymentId] = scheduledPaymentId.split('-');

  return (
    <ChakraProvider theme={habitatTheme}>
      <BaselaneDrawer
        isOpen
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        title="Schedule details"
        closeEvent={() => onDrawerClose(navigate, from)}
        onOverlayClick={() => onDrawerClose(navigate, from)}
        closeOnOverlayClick={false}
        newDesignDrawer
      >
        {recurringPaymentId ? (
          <RecurringPaymentDetails from={from} recurringPaymentId={recurringPaymentId} />
        ) : (
          <OneTimePaymentDetails from={from} transferId={transferId} />
        )}
      </BaselaneDrawer>
    </ChakraProvider>
  );
};

export default ScheduledPaymentDetailsDrawer;
