import React from 'react';
import { Stack, Text, Box } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import ObieModal from '../ObieModal';

import { insuranceStyles, titleSecondStyles } from './styles/middleCard.styles';

type MiddleCardProps = {
  title: string,
  mobileTitle: string,
  description: string,
  buttonProps: string,
  children: any,
};

function MiddleCard({ title, mobileTitle, description, buttonProps, children }: MiddleCardProps) {
  const { isMin899: isDesktop } = getBreakPoints();
  const { bottom } = insuranceStyles() ?? {};

  return (
    <Stack {...bottom.container}>
      {isDesktop ? (
        <Text {...titleSecondStyles}>{title}</Text>
      ) : (
        <>
          <Text {...titleSecondStyles}>{mobileTitle}</Text>
          <Text {...bottom.left.description}>{description}</Text>
        </>
      )}
      {children}
      <Box {...bottom.buttonContainer}>
        <ObieModal {...buttonProps} />
      </Box>
    </Stack>
  );
}

export default MiddleCard;
