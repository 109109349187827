import React from 'react';
import { isMobile, withOrientationChange } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { IconWarningMobile } from '@icons';
import { BaselaneButton, BaselaneCard } from '@shared/components';
import { HOME } from '@routes';

import {
  cardStyles,
  containerStyles,
  mobileContainer,
  mobileMainContentStyles,
  textStyles,
  descriptionTextStyles,
  descriptionBoldTextStyles,
} from './styles/renderWarningMsg.styles';

function RenderWarningPage({ isPortrait }: { isPortrait: boolean }) {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(HOME);
  };

  return (
    <Box h={isMobile && '100%'}>
      <Box {...containerStyles(isMobile)}>
        {isMobile ? (
          <Box {...mobileContainer(isPortrait)}>
            {/* main content */}
            <Box {...mobileMainContentStyles(isPortrait)}>
              {/* TODO: Replace below icon with a new one once Jamil's ready */}
              <IconWarningMobile />
              <Text {...descriptionBoldTextStyles}>
                Unfortunately this feature is not yet
                <br /> available on Mobile
              </Text>
              <Text {...descriptionTextStyles}>
                Head over to Baselane on Desktop
                <br /> to use this feature and much more
              </Text>
            </Box>

            {/* footer: back button */}
            <BaselaneButton
              variant="filled"
              palette="primary"
              size="lg"
              isFullWidth
              onClick={handleBackClick}
            >
              Go to Homepage
            </BaselaneButton>
          </Box>
        ) : (
          <BaselaneCard styles={{ ...cardStyles }}>
            <Flex>
              <Box alignSelf="center">
                <Text {...textStyles}>
                  Baselane is currently not optimized for this screen size, please expand your
                  browser window.
                </Text>
                <Text {...textStyles}>
                  We appreciate your patience as we continue optimizing the product experience.
                </Text>
              </Box>
            </Flex>
          </BaselaneCard>
        )}
      </Box>
    </Box>
  );
}

export default withOrientationChange(RenderWarningPage);
