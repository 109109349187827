import { lastFourDigits } from '@core/utils/formatAccountNumber';

export const initialAddressValues = {
  address: '',
  unit: '',
  state: '',
  zipcode: '',
  city: '',
  country: '',
};

export const initialFormValues = {
  bankAccountId: null,
  feeFromBankAccountId: null,
  ...initialAddressValues,
};

export const handleValidation = (values) => {
  const errors = {};

  if (!values?.feeFromBankAccountId) errors.feeFromBankAccountId = 'Please select an account';

  if (!values?.address?.trim()) {
    errors.address = 'Please enter address';
  }
  if (!values?.city?.trim()) {
    errors.city = 'Please enter city';
  }
  if (!values?.state) {
    errors.state = 'Please enter state';
  }
  if (!values?.zipcode) {
    errors.zipcode = 'Please enter zipcode';
  }
  if (values?.zipcode && values?.zipcode?.length < 5) {
    errors.zipcode = 'Please enter 5-digit zip code';
  }
  if (values?.unit?.length > 0 && values?.unit?.trim().length === 0) {
    errors.unit = 'Unit number cannot be left blank';
  }

  return errors;
};

export const formattedAddress = (addressObj) => {
  const { address, unit, city, state, zipcode } = addressObj;

  return `${address}, ${!unit ? '' : `${unit}, `}${city}, ${state} ${zipcode}`;
};

export const formattedAccountDisplay = (account) => {
  const { accountName, accountNumber, accountSubType } = account;
  const accountType = accountSubType.charAt(0).toUpperCase() + accountSubType.slice(1);

  return `${accountName} · ${accountType} account - ${lastFourDigits(accountNumber)}`;
};

export const formattedValues = (values) => {
  const {
    bankAccountId,
    feeFromBankAccountId,
    address,
    unit,
    city,
    state,
    zipcode,
    country,
  } = values;

  return {
    bankAccountId,
    feeFromBankAccountId,
    recipientAddress: {
      street: address,
      unit,
      city,
      state,
      postalCode: zipcode,
      country,
    },
  };
};
