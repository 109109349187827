import { formInputStyles } from '@shared/styles/input.style';
import { truncatedText } from '@shared/styles/text.style';

export const filtersFilterRowStyles = {
  px: 2,
  pb: { lg: 3, base: 2 },
};

export const filtersSecondRowStyles = (isOutsideTrxPage) => ({
  borderTop: isOutsideTrxPage ? 0 : '1px solid',
  borderTopColor: isOutsideTrxPage ? 'transparent' : 'brand.darkBlue.100',
  pt: 2,
  px: 2,
  w: '100%',
  flexWrap: 'wrap',
  gap: { lg: 2, base: 1.5 },
  spacing: 0,
});

export const bulkActionsTextStyles = {
  display: 'flex',
  alignItems: 'center',
  textStyle: 'sm',
  mr: '16px',
  color: 'brand.neutral.700',
};

export const dropdownContainerStyles = {
  maxWidth: { xxs: 'unset', lg: 'unset', base: '280px' },
  minWidth: { xxs: 'unset', lg: '95px', base: '148px' },
  flex: '1 1 25%',
};

export const filtersContainerStyles = {
  flexWrap: 'nowrap',
  gap: 1,
  spacing: 0,
};

export const searchBarInputGroupStyles = {
  width: { '2lg': '268px', lg: '220px', base: '100%' },
};

export const searchBarInputStyles = {
  ...formInputStyles,
  height: '40px',
  pl: '36px',
  color: 'brand.neutral.500',
  lineHeight: '20px',
  borderRadius: '6px',
  px: '2px',
  paddingBottom: '13px !important',
  fontSize: 'sm',
  _placeholder: {
    fontSize: 'sm',
  },
};

export const switchLabelStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.700',
  ml: '0px',
  mr: '0px',
};

export const menuItemTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  w: '100%',
  ...truncatedText,
};

export const menuItemSubAccountStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
  m: '0 !important',
  w: '100%',
  ...truncatedText,
};

export const filterRowContainerStyles = {
  spacing: 2,
  px: 0,
  pb: 1.5,
  bg: 'brand.neutral.white',
  borderTopRightRadius: 'md',
  borderTopLeftRadius: 'md',
  borderBottom: '1px solid',
  borderBottomColor: 'brand.darkBlue.100',
  // NOTE: REMOVE WHEN TRANSACTIONS SCROLL IS ADDED
  position: 'sticky',
  top: '-30px',
  zIndex: 1,
};

export const filtersAndTogglesMobileContainerStyles = {
  p: 0,
  border: 'none',
  overflowX: 'auto',
  flexWrap: 'nowrap',
  gap: 1,
  css: {
    '&::-webkit-scrollbar': {
      width: '16px',
    },
    '&::-webkit-scrollbar-track': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#E5E9EF',
      backgroundClip: 'padding-box',
      border: '4px solid transparent',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#C8D0DA',
      backgroundClip: 'padding-box',
      border: '4px solid transparent',
      borderRadius: '8px',
    },
  },
};
