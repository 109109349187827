// @flow
import React from 'react';
import { Stack, Text, Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { IconSkeletonBar1, IconInfo } from '@icons';
import BaselaneTooltip from '../BaselaneTooltip';
import {
  getSectionStyles,
  headerStyles,
  borderStyles,
  contentStyles,
  containerStyles,
  headerTitleContainerStyles,
} from './styles/overview.style';
import SkeletonOverview from './SkeletonOverview';

type Props = {
  children?: any,
  styles?: Object,
  customFontStyles?: Object,
  customBorderStyles?: Object,
  customContentStyles?: Object,
  customContentContainerStyles?: Object,
  firstItemStyles?: Object,
  elements?: Array<Object>,
  position?: string,
  orientation?: string,
  spacing?: string,
  hasNoData?: boolean,
};

function BaselaneOverview({
  children = null,
  orientation,
  styles = {},
  customFontStyles = {},
  customBorderStyles = {},
  customContentStyles = {},
  customContentContainerStyles = {},
  firstItemStyles = {},
  position = 'left',
  elements = [],
  spacing = '',
  hasNoData = false,
}: Props): any {
  const containerStyle =
    spacing === '' ? { ...containerStyles, ...styles } : { h: '80px', w: '100%', ...styles };
  const sectionStyle = getSectionStyles(position, orientation, spacing);
  const borderStyle = { ...borderStyles, ...customBorderStyles };
  if (children !== null)
    return (
      <Stack direction="row" {...containerStyle}>
        {children}
      </Stack>
    );
  return (
    <Stack direction="row" {...containerStyle} className="overview-container">
      {elements.map((element, i) => {
        const key = element?.id || element.title;
        const showcontent = () => {
          if (hasNoData)
            return (
              <Box {...customContentStyles}>
                <Box display={!isMobile ? 'block' : 'none'}>
                  <SkeletonOverview />
                </Box>
                <Box pt="8px" display={!isMobile ? 'none' : 'block'}>
                  <IconSkeletonBar1 height="20" width="72" borderRadius="12px" />
                </Box>
              </Box>
            );
          if (element.content !== undefined) {
            return (
              <Box {...contentStyles(element.title, orientation)} {...customContentStyles}>
                {element.content}
              </Box>
            );
          }
          return null;
        };
        if (i > 0) {
          return (
            <Stack
              key={key}
              direction="row"
              {...{ ...sectionStyle.container, ...element?.style }}
              {...borderStyle}
              {...(hasNoData && { colors: 'brand.neutral.500' })}
            >
              <Stack {...{ ...sectionStyle.content, ...customContentContainerStyles }}>
                {element.title ? (
                  <Box {...headerTitleContainerStyles}>
                    <Text
                      {...headerStyles}
                      {...customFontStyles}
                      mr={element.tooltipLabel && '5px'}
                    >
                      {element.title}
                    </Text>
                    {element.tooltipLabel && (
                      <BaselaneTooltip label={element.tooltipLabel} styles={{ w: '250px' }}>
                        <IconInfo width="16" height="16" color="#3A4B5B" />
                      </BaselaneTooltip>
                    )}
                  </Box>
                ) : null}
                {showcontent()}
              </Stack>
            </Stack>
          );
        }
        return (
          <Stack
            key={key}
            direction="row"
            {...{ ...sectionStyle.container, ...element?.style }}
            {...firstItemStyles}
            {...(hasNoData && { colors: 'brand.neutral.500' })}
          >
            <Stack {...sectionStyle.content}>
              {element.title ? (
                <Text {...headerStyles} {...customFontStyles}>
                  {element.title}
                </Text>
              ) : null}
              {showcontent()}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}

BaselaneOverview.defaultProps = {
  children: null,
  position: 'left',
  orientation: 'vertical',
  spacing: '',
  elements: [],
  styles: {},
  customFontStyles: {},
  customBorderStyles: {},
  customContentStyles: {},
  customContentContainerStyles: {},
  firstItemStyles: {},
  hasNoData: false,
};

export default BaselaneOverview;
