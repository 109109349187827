// @flow
import React, { useState, useRef } from 'react';
import { Text, FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { BaselaneButton } from '@shared/components';
import { checkEmail } from '@core/Services/Firebase/firebase';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import { emailVerificaton } from '@core/Services/CreateAccount';
import {
  textBoldStyle,
  textSmallStyle,
} from '@shared/components/BaselaneErrorCard/styles/errorcard.style';
import SuccessAlert from './SuccessAlert';
import '@shared/components/BaselaneErrorCard/styles/errorcard.style.scss';
import { fieldContainerStyle } from '../styles/verifiedUserPage.styles';

function LinkExpiredPage() {
  const [inputEmail, setInputEmail] = useState('');
  const [errors, setErrors] = useState();
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const alertCancelRef = useRef();

  const handleAlertOnClose = () => {
    if (alertIsOpen) setAlertIsOpen(false);
  };

  const handleChange = (e) => {
    if (errors) {
      setErrors();
    }
    setInputEmail(e.target.value);
  };

  const handleBlur = (e) => {
    if (!e.target.value) {
      setErrors('Required');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) {
      setErrors('Invalid email address');
    } else {
      setErrors();
    }
  };

  const handleResendVerificationEmail = () => {
    const bodyParams = { email: inputEmail };
    checkEmail(inputEmail)
      .then((emailSignInType) => {
        if (emailSignInType.length === 0) {
          setErrors('We could not find an account associated to this email');
        } else {
          emailVerificaton(bodyParams).then((res) => {
            if (res?.message && res?.message.includes('TOO_MANY_ATTEMPTS')) {
              setErrors('Too many attempts, please try again later.');
            } else if (res?.message) {
              setErrors('Something went wrong, please try again later.');
            } else {
              setAlertIsOpen(true);
            }
          });
        }
      })
      .catch((errorCatch) => {
        console.log('invalid email:', errorCatch);
      });
  };

  const navigate = useNavigate();

  const handleBackToSignInClick = () => {
    navigate('/login');
  };

  return (
    <>
      <Text {...textBoldStyle}>Link Expired</Text>
      <Text {...textSmallStyle} mb="24px">
        This verification link has expired. Please use the link provided in the most recent
        verification email, or send a new verification to your inbox.
      </Text>
      <FormControl isInvalid={errors} {...fieldContainerStyle} mb="8px">
        <FormLabel htmlFor="Email" {...formLabelStyles.xs}>
          Enter Your Email Address
        </FormLabel>
        <Input
          {...formInputStyles}
          id="Email"
          name="Email"
          value={inputEmail}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e);
          }}
          placeholder="john@gmail.com"
        />
        <FormErrorMessage {...formErrorStyles}>{errors}</FormErrorMessage>
      </FormControl>
      <BaselaneButton
        isDisabled={errors || !inputEmail}
        variant="filled"
        palette="primary"
        size="lg"
        onClick={handleResendVerificationEmail}
      >
        Resend Verification Email
      </BaselaneButton>
      <BaselaneButton variant="transparent" palette="neutral" onClick={handleBackToSignInClick}>
        Back to Sign In
      </BaselaneButton>

      {/* Success Alert */}
      <SuccessAlert {...{ alertCancelRef, alertIsOpen, handleAlertOnClose }} />
    </>
  );
}

export default LinkExpiredPage;
