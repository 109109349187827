export const bannerBackgroundStyles = {
  bg: 'brand.blue.200',
  borderRadius: '8px',
  flexDirection: { base: 'column-reverse', md: 'row' },
};

export const contentContainerStyles = {
  px: { lg: '40px', xs: '20px' },
};

export const titleFontStyles = {
  fontSize: { xl: '46px', lg: '36px', base: '32px' },
  lineHeight: { lg: '56px', base: '40px' },
  fontWeight: 'bold',
  color: 'brand.neutral.800',
};

export const subTextFontStyles = {
  fontSize: { lg: 'lg', xs: 'md' },
  lineHeight: '24px',
  color: 'brand.neutral.800',
  fontWeight: 100,
};

export const bannerContainerStyles = { flexDir: { xl: 'row', lg: 'row', xxs: 'column' } };
