import React from 'react';

import BaselaneBadge from '../BaselaneBadge';
import {
  occupationStatusBadgeStyles,
  occupationStatusBadgeFontStyles,
} from './styles/occupationStatus.styles';

function BaselaneOccupationStatus({ property }: { property: Object }): any {
  let status = 'unoccupied';
  let number = 0;

  // overrule status if any of the properties is occupied
  property?.units.forEach((unit) => {
    if (unit.status === 'occupied') {
      status = 'occupied';
      number += 1;
    }
  });

  const occupationStatusOptions = {};
  switch (status) {
    case 'occupied':
      occupationStatusOptions[status] = {
        label: `${number}/${property.units.length} Occupied`,
        backgroundStyles: occupationStatusBadgeStyles(true),
        color: 'green.800AA',
      };
      break;
    case 'unoccupied':
      occupationStatusOptions[status] = {
        label: 'Unoccupied',
        backgroundStyles: occupationStatusBadgeStyles(false),
        color: 'red.500A',
      };
      break;
    default:
  }

  return (
    <BaselaneBadge
      fontStyles={occupationStatusBadgeFontStyles()}
      backgroundStyles={occupationStatusOptions[status].backgroundStyles}
      type="master"
      color={occupationStatusOptions[status].color}
      text={occupationStatusOptions[status].label}
    />
  );
}

export default BaselaneOccupationStatus;
