import { gql } from '@apollo/client';

export const GET_TAGS = gql`
  query Tag {
    tag {
      type
      subType {
        id
        name
        subType {
          id
          name
        }
      }
    }
  }
`;
