import React from 'react';

type IconDeleteProps = {
  width?: Number,
  height?: Number,
  color?: string,
  circleBackgroundColor?: string,
};

function IconDelete({
  width = 12,
  height = 14,
  color = 'currentColor',
  circleBackgroundColor = 'currentColor',
}: IconDeleteProps) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.08" cx="6" cy="6" r="6" fill={circleBackgroundColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72386 0.72402C3.97391 0.473972 4.31304 0.333496 4.66667 0.333496H7.33333C7.68696 0.333496 8.02609 0.473972 8.27614 0.72402C8.52619 0.974069 8.66667 1.31321 8.66667 1.66683V3.00016H11.3333C11.7015 3.00016 12 3.29864 12 3.66683C12 4.03502 11.7015 4.3335 11.3333 4.3335H11.2874L10.7537 11.8088C10.7536 11.8089 10.7537 11.8088 10.7537 11.8088C10.7177 12.3134 10.4919 12.7857 10.1218 13.1304C9.75158 13.4752 9.26451 13.6668 8.75865 13.6668H3.24135C2.73549 13.6668 2.24842 13.4752 1.87823 13.1304C1.50809 12.7857 1.28231 12.3135 1.24636 11.809C1.24636 11.8089 1.24636 11.809 1.24636 11.809L0.712573 4.3335H0.666667C0.298477 4.3335 0 4.03502 0 3.66683C0 3.29864 0.298477 3.00016 0.666667 3.00016H3.33333V1.66683C3.33333 1.31321 3.47381 0.974069 3.72386 0.72402ZM4.66667 3.00016H7.33333V1.66683H4.66667V3.00016ZM2.0493 4.3335L2.57631 11.714C2.58828 11.8822 2.66355 12.0398 2.78695 12.1547C2.91034 12.2696 3.0727 12.3335 3.24133 12.3335H8.75868C8.9273 12.3335 9.08966 12.2696 9.21305 12.1547C9.33645 12.0398 9.41171 11.8824 9.42368 11.7142L9.9507 4.3335H2.0493ZM4.66667 5.66683C5.03486 5.66683 5.33333 5.96531 5.33333 6.3335V10.3335C5.33333 10.7017 5.03486 11.0002 4.66667 11.0002C4.29848 11.0002 4 10.7017 4 10.3335V6.3335C4 5.96531 4.29848 5.66683 4.66667 5.66683ZM7.33333 5.66683C7.70152 5.66683 8 5.96531 8 6.3335V10.3335C8 10.7017 7.70152 11.0002 7.33333 11.0002C6.96514 11.0002 6.66667 10.7017 6.66667 10.3335V6.3335C6.66667 5.96531 6.96514 5.66683 7.33333 5.66683Z"
        fill={color}
      />
    </svg>
  );
}
IconDelete.defaultProps = {
  width: '12',
  height: '14',
  color: 'currentColor',
  circleBackgroundColor: '#F5F5F7',
};
export default IconDelete;
