import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex, HStack, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import { BaselaneCardNew, BaselaneShareLink } from '@core/components/Shared/components';
import InvoiceContext from '@contexts/InvoiceContext';
import { IconCheckCircle, IconCircleDashed } from '@icons';
import { formatPhoneNumber } from '@pages/LeasesPage/helpers/unit.helpers';
import QuickPayIconOrBadge from '@pages/LeasesPage/components/QuickPayIconOrBadge';
import { BaselaneHighlightedDataDisplay } from '@shared/components/BaselaneHighlightedDataDisplay';
import ResendInviteButton from '@features/Tenant/ResendInviteButton';

import LeaseSection from './LeaseSection';
import { GET_TENANT_PROFILE } from '../../../queries';
import {
  leaseSectionHeader,
  leaseTenantContainer,
  leaseTenantHeader,
  leaseTenantText,
  leaseTenantStatusContainer,
  leaseTenantStatusHeader,
  leaseTenantStatusSubtitle,
  leaseTenantStatusText,
  leaseTenantStatusAction,
} from '../../../LeaseResponsiveDetail/styles/leaseResponsiveDetail.styles';

type TenantDetailsProps = {
  setTabIndex: Function,
  leaseCreated: boolean,
  id: Number,
  viewMode: boolean,
  lease: Object,
  tenantData: Object,
};

function TenantDetails({
  setTabIndex,
  leaseCreated,
  id,
  viewMode,
  lease: draftLease,
  tenantData: tenantExistingData = {},
}: TenantDetailsProps) {
  const [isDesktop] = useMediaQuery('(min-width: 639px)', { ssr: false });
  const [isMin899] = useMediaQuery('(min-width: 899px)', { ssr: false });
  const [tenantProfile, setTenantProfile] = useState(null);
  const [tenantDisplay, setTenantDisplay] = useState(null);

  const {
    loading: tenantLoading,
    error: tenantError,
    data: tenantNewData,
  } = useQuery(GET_TENANT_PROFILE, { skip: tenantExistingData?.landlordTenant });
  const tenantData = tenantExistingData.landlordTenant ? tenantExistingData : tenantNewData;

  function isValid(metadata = {}) {
    if (metadata) {
      const firstNameIsValid = 'firstName' in metadata && metadata.firstName;
      const lastNameIsValid = 'lastName' in metadata && metadata.lastName;
      return firstNameIsValid && lastNameIsValid;
    }

    return false;
  }

  const { selectedLease, setTenantStatus: setTenantState } = useContext(InvoiceContext);
  let setTenantStatus = () => {};
  if (setTenantState) {
    setTenantStatus = setTenantState;
  }
  const lease = draftLease || selectedLease;
  const leaseTenant = lease?.tenantProfileMetadata?.firstName
    ? lease?.tenantProfileMetadata
    : lease?.tenantProfile;

  const firstName = leaseTenant?.firstName;
  const lastName = leaseTenant?.firstName;
  const email = leaseTenant?.email;
  const phoneNumber = leaseTenant?.phoneNumber;
  const isQuickPay = lease?.isQuickPay;

  const inviteLinkArr = lease?.tenantProfile?.inviteLink ?? [];
  const inviteLinkObj = inviteLinkArr?.find((link) => link.leaseId === Number(lease?.id)) ?? {};
  const inviteLink = inviteLinkObj?.link || null;

  useEffect(() => {
    if (tenantData && !tenantLoading) {
      const { tenantProfileId, tenantProfileMetadata } = lease || {};

      const newTenantProfile =
        isValid(tenantProfileMetadata) && !viewMode
          ? tenantProfileMetadata
          : tenantData.landlordTenant?.find((tenant) => tenant.id === tenantProfileId);
      setTenantProfile(newTenantProfile);

      let tenantStatus = null;
      if (!lease?.invoiceDetails?.lastCompletedInvoice) {
        const checked = (
          <Box w="16px" h="16px" mr={isDesktop ? '4px' : '6px'}>
            <IconCheckCircle width="20px" height="20px" color="#257ED0" />
          </Box>
        );
        const unchecked = (
          <Box mt="2px" ml="2px" w="16px" h="16px" mr={isDesktop ? '2px' : '4px'}>
            <IconCircleDashed w="16px" h="16px" color="#C8D0DA" />
          </Box>
        );

        const { status: state, hasPaymentMethod } = newTenantProfile || {};
        const inviteSentStatus = state !== 'INVITE_PENDING' && state !== 'NO_INVITE_SENT';
        const inviteSentLine = inviteSentStatus ? '1pt solid #6C7884' : '1pt dashed #6C7884';
        const inviteActiveLine = state === 'ACTIVE' ? '1pt solid #6C7884' : '1pt dashed #6C7884';
        tenantStatus = (
          <Box {...leaseTenantStatusContainer({ isMin899 })}>
            <Text {...leaseTenantStatusHeader} mb="4px">
              Tenant Onboarding Progress
            </Text>
            <Text {...leaseTenantStatusSubtitle}>
              Track your tenant&apos;s progress as they activate and add a payment method to their
              account.
            </Text>
            <Flex direction={!isDesktop ? 'column' : 'row'} ml={isDesktop ? '0' : '8px'}>
              <Flex direction="row" h="20px">
                {inviteSentStatus ? checked : unchecked}
                <Box ml="4px" fontWeight={inviteSentStatus ? 'medium' : 'normal'}>
                  <Text {...leaseTenantStatusText(inviteSentStatus)}>Invite Sent</Text>
                </Box>
              </Flex>
              {!isDesktop ? (
                <Box h="8px" w="2pt" borderLeft={inviteSentLine} m="0 0 0 9.5px" />
              ) : (
                <Box h="1pt" w="10%" borderBottom={inviteSentLine} m="10px 16px 0 16px" />
              )}
              <Flex direction="row" h="20px">
                {state === 'ACTIVE' ? checked : unchecked}
                <Box ml="4px" fontWeight={state === 'ACTIVE' ? 'medium' : 'normal'}>
                  <Text {...leaseTenantStatusText(state === 'ACTIVE')}>Account Activated</Text>
                </Box>
              </Flex>
              {!isDesktop ? (
                <Box h="8px" w="2pt" borderLeft={inviteActiveLine} m="0 0 0 9.5px" />
              ) : (
                <Box h="1pt" w="10%" borderBottom={inviteActiveLine} m="10px 16px 0 16px" />
              )}
              <Flex direction="row" h="24px">
                {hasPaymentMethod ? checked : unchecked}
                <Box ml="4px" fontWeight={hasPaymentMethod ? 'medium' : 'normal'}>
                  <Text {...leaseTenantStatusText(hasPaymentMethod)}>Payment Method Added</Text>
                </Box>
              </Flex>
            </Flex>
            <Stack mt={1.5} alignItems="center" flexDir={{ base: 'column', xs: 'row' }}>
              <Box {...leaseTenantStatusAction({ isHidden: state === 'ACTIVE' })}>
                <ResendInviteButton
                  tenantId={tenantProfileId}
                  label="Resend Invites"
                  emailIcon
                  size={isMin899 ? 'md' : 'sm'}
                  variant="outline"
                  palette="neutral"
                />
              </Box>
              {state !== 'ACTIVE' && inviteLink && (
                <BaselaneShareLink
                  {...{
                    showButtonOnly: true,
                    link: inviteLink,
                    type: 'copy',
                    text: { default: 'Copy Invitation Link', copied: 'Copied' },
                    buttonProps: {
                      variant: 'filled',
                      palette: 'primary',
                      size: isMin899 ? 'md' : 'sm',
                      icon: {
                        color: '#fff',
                      },
                    },
                  }}
                />
              )}
            </Stack>
          </Box>
        );
      }

      const configuration = {
        items: [
          {
            key: 'Full name',
            value: `${newTenantProfile?.firstName || firstName} ${
              newTenantProfile?.lastName || lastName
            }`,
          },
          {
            key: isDesktop ? 'Email address' : 'Email',
            value: newTenantProfile?.email || email,
          },
          {
            key: 'Phone',
            value: newTenantProfile?.phoneNumber || phoneNumber,
          },
        ],
      };

      const children = <BaselaneHighlightedDataDisplay {...{ configuration }} />;

      setTenantStatus(tenantStatus);
      setTenantDisplay(children);
    } else if (!tenantData) {
      setTenantStatus(null);
    }
  }, [
    tenantNewData,
    tenantLoading,
    isDesktop,
    lease?.tenantProfileId,
    firstName,
    lastName,
    email,
    phoneNumber,
  ]);

  if (tenantLoading) return <div> Loading... </div>;
  if (tenantError) return <div> Error! </div>;

  const title = 'Tenant details';

  return viewMode ? (
    <Box m="8px 0 32px">
      <Text {...leaseSectionHeader}>{title}</Text>
      <BaselaneCardNew {...leaseTenantContainer}>
        <Stack width="100%">
          <HStack justifyContent="space-between">
            <Text {...leaseTenantHeader}>
              {`${tenantProfile?.firstName} ${tenantProfile?.lastName}`}
            </Text>
            {isQuickPay && <QuickPayIconOrBadge />}
          </HStack>
          <Text {...leaseTenantText}>{tenantProfile?.email}</Text>
          <Text {...leaseTenantText}>{formatPhoneNumber(tenantProfile?.phoneNumber)}</Text>
        </Stack>
      </BaselaneCardNew>
    </Box>
  ) : (
    <LeaseSection {...{ id, title, setTabIndex, leaseCreated }}>{tenantDisplay}</LeaseSection>
  );
}

export default TenantDetails;
