const dateMask = [
  /[JFMASONDjfmasond]/,
  /[AUEOPCaueopc]/,
  /[NRYLPVBGTCnrylpvbgtc]/,
  ' ',
  /\d/,
  /\d/,
  ',',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export default dateMask;
