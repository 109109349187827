import process from 'process';
import React from 'react';
import { ChakraProvider, CSSReset, GlobalStyle } from '@chakra-ui/react';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import { BaselaneErrorBoundary } from '@shared/components';
import fonts from '@core/components/Fonts';
import customTheme from '@theme';

type AppWrapperProps = {
  children: any,
};

const AppWrapper = ({ children }: AppWrapperProps) => {
  const fingerPrintAPIKey = process?.env?.REACT_APP_FINGERPRINT_API_KEY;
  const fingerPrintAPIEndpoint = process?.env?.REACT_APP_FINGERPRINT_API_ENDPOINT;
  const fingerPrintOptions = {
    apiKey: fingerPrintAPIKey,
    endpoint: fingerPrintAPIEndpoint,
    scriptUrlPattern: `${fingerPrintAPIEndpoint}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
  };

  return (
    <FpjsProvider loadOptions={fingerPrintOptions}>
      <ChakraProvider theme={customTheme}>
        <CSSReset />
        <GlobalStyle styles={fonts} />
        <BaselaneErrorBoundary>{children}</BaselaneErrorBoundary>
      </ChakraProvider>
    </FpjsProvider>
  );
};

export default AppWrapper;
