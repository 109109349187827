import { React, useState } from 'react';
import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';
import { BaselaneErrorCard, BaselaneResponsiveTable } from '@core/components/Shared/components';
import SlLoader from '@core/components/Loader';
import getBreakPoints from '@core/utils/getBreakPoints';
import { SEND_FUNDS } from '@routes';
import MoveMoneyMenu from '@core/components/Shared/components/MoveMoneyMenu';
import { NoPayment } from './NoPayment';
import { TableHeader } from './ScheduledPaymentsTable/TableHeader';
import { TableBody } from './ScheduledPaymentsTable/TableBody';
import { recipientsStyles } from '../Recipients/styles';
import { config, DEFAULT_SORT_FIELD, DEFAULT_SORT_DIR } from './helpers/helpers';
import useScheduledPayments from './helpers/useScheduledPayments';

export function ScheduledPayments() {
  const DEFAULT_SORT_OPTIONS = {
    sort: {
      field: DEFAULT_SORT_FIELD,
      direction: DEFAULT_SORT_DIR,
    },
  };

  const [sortOptions, setSortOptions] = useState(DEFAULT_SORT_OPTIONS);
  const { data: paymentsData, loading, error } = useScheduledPayments(sortOptions);

  const navigate = useNavigate();
  const { scheduledPayments: payments } = paymentsData ?? {};
  const hasPayments = payments?.length > 0;
  const { pageContainerStyles, headerContainerStyles } = recipientsStyles;

  const { isMax576 } = getBreakPoints();

  const handleScheduledPaymentClick = () => {
    navigate(SEND_FUNDS);
  };

  const handleEditPaymentClick = () => {};

  return (
    <>
      {loading && <SlLoader />}
      {error && <BaselaneErrorCard />}
      {hasPayments
        ? !loading &&
          !error && (
            <Stack {...pageContainerStyles}>
              <HStack {...headerContainerStyles}>
                {!isMax576 && <Text textStyle="headline-md">Your scheduled payments</Text>}
                {!isMax576 && <Spacer />}
                <MoveMoneyMenu entryPoint="scheduled_tab" styles={{ container: { flex: '0' } }} />
              </HStack>
              <BaselaneResponsiveTable id="payment-list" config={config}>
                {!isMax576 && (
                  <TableHeader sortOptions={sortOptions} setSortOptions={setSortOptions} />
                )}
                <TableBody
                  payments={paymentsData.scheduledPayments}
                  handleEditPaymentClick={handleEditPaymentClick}
                />
              </BaselaneResponsiveTable>
            </Stack>
          )
        : !loading &&
          !error && <NoPayment handleScheduledPaymentClick={handleScheduledPaymentClick} />}

      <Outlet />
    </>
  );
}
