// @flow
import React, { useEffect } from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import {
  FormLabel,
  Input,
  Text,
  Box,
  Stack,
  Select,
  FormControl,
  useDisclosure,
} from '@chakra-ui/react';
import AmountInput from '@shared/components/AmountInput';
import { BaselaneMessageCard, BaselaneSingleDatePicker } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import { percentageMask } from '@core/utils/masks';
import { formLabelStyles, formInputStyles } from '@shared/styles/input.style';
import { mortgageTermOptions } from '../../../helpers/financials.helper';
import { formSectionTitleStyles } from '../../../styles/form.style';
import {
  textLabelStyle,
  formLabelStyle,
  cFormControlStyles,
  cfieldContainer,
  cFormControlBorder,
} from '../../../styles/responsive.style';

type FinancialsProps = {
  // property: Object,
  setShowMortgage: Function,
  setFormVariables: Function,
  setHideFooter: Function,
  setIsDirty: Function,
  isDirty: boolean,
  initialValues: Object,
  marketPrice: Array<Object>,
  mortgageBalance: Array<Object>,
  propertyFormRef: any,
};

function Financials({
  // property,
  // setShowMortgage,
  setFormVariables,
  // setHideFooter,
  setIsDirty,
  isDirty,
  initialValues,
  marketPrice,
  mortgageBalance,
  propertyFormRef,
}: FinancialsProps): any {
  const { isMin768 } = getBreakPoints();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [latestMarketPrice] = orderBy(marketPrice, ['date'], ['desc']);
  const [latestMortgageBalance] = orderBy(mortgageBalance, ['date'], ['desc']);
  const lastEditedMarketPriceDate = formatDate(latestMarketPrice?.date);
  const lastEditedMortgageBalanceDate = formatDate(latestMortgageBalance?.date);
  // const mortgageBalanceData = latestMortgageBalance ? latestMortgageBalance.balance : null;

  const drawerDescriptionStyles = {
    textStyle: 'sm',
    color: 'brand.neutral.600',
    pl: '0',
    pr: '0',
    m: '16px 0',
  };

  const containerStyles = {
    textStyle: 'sm',
    mt: '16px',
  };

  const containerStylesNoMargin = {
    textStyle: 'sm',
  };

  const formSelectStyle = {
    fontSize: 'sm',
    lineHeight: '20px',
  };

  useEffect(() => {
    setIsDirty({ ...isDirty, ...{ financials: propertyFormRef.current?.dirty } });
  }, [propertyFormRef.current?.values?.purchaseDate]);

  return (
    <Box h="100%">
      <>
        <Text {...drawerDescriptionStyles}>
          This information is used to calculate performance metrics for your properties. It is
          optional to enter but recommended to get the most value out of the Baselane platform.
        </Text>
        <Formik initialValues={initialValues} innerRef={propertyFormRef}>
          {({ values, dirty, handleChange, handleBlur, setFieldValue }) => (
            <Stack spacing="0">
              {/* Property Details */}
              <Box {...cFormControlStyles} {...cFormControlBorder}>
                <Text {...formSectionTitleStyles} {...textLabelStyle}>
                  Purchase Details
                </Text>

                {/* Purchase Price */}
                <FormControl w="100%" {...cfieldContainer}>
                  <FormLabel {...formLabelStyles.xs} {...formLabelStyle}>
                    Purchase Price
                  </FormLabel>
                  <AmountInput
                    {...formInputStyles}
                    type="text"
                    id="purchasePrice"
                    name="purchasePrice"
                    allowdecimal="false"
                    placeholder="$500,000"
                    onBlur={() => {
                      setIsDirty({ ...isDirty, ...{ financials: dirty } });
                      setFormVariables({ purchasePrice: values.purchasePrice });
                    }}
                  />
                </FormControl>

                {/* Purchase Date */}
                <FormControl w="100%" {...cfieldContainer}>
                  <FormLabel {...formLabelStyles.xs} {...formLabelStyle}>
                    Purchase Date
                  </FormLabel>
                  <BaselaneSingleDatePicker
                    {...{
                      id: 'date',
                      name: 'date',
                      placeholder: 'October 22, 2020',
                      maxDate: moment().toDate(),
                      date: values.purchaseDate,
                      value: values.purchaseDate
                        ? `${formatDate(values.purchaseDate, 'MMM DD, YYYY')}`
                        : '',
                      dateFormat: 'MMM dd, yyyy',
                      handleCalendarClose: async ({ date: newDate, isFromApplyButton }) => {
                        if (isFromApplyButton) {
                          setFieldValue('purchaseDate', newDate);
                          setFormVariables({ purchaseDate: newDate });
                          await Promise.resolve();
                        }
                      },
                      years: { start: 2010, end: new Date().getFullYear() },
                      isOpen,
                      onOpen,
                      onClose,
                      hideInputIcon: true,
                      placement: 'bottom-start',
                      showInModal: !isMin768,
                    }}
                  />
                </FormControl>

                {/* Closing Costs */}
                <FormControl w="100%" {...cfieldContainer}>
                  <FormLabel {...formLabelStyles.xs} {...formLabelStyle}>
                    Closing Costs
                  </FormLabel>
                  <AmountInput
                    {...formInputStyles}
                    type="text"
                    id="closingCosts"
                    name="closingCosts"
                    allowdecimal="false"
                    placeholder="$500,000"
                    onBlur={() => {
                      setIsDirty({ ...isDirty, ...{ financials: dirty } });
                      setFormVariables({ closingCosts: values.closingCosts });
                    }}
                  />
                </FormControl>
              </Box>
              <Box {...cFormControlStyles} {...cFormControlBorder}>
                <Text {...formSectionTitleStyles} {...textLabelStyle}>
                  Annual Taxes & Insurance
                </Text>

                {/* Annual Property Taxes */}
                <FormControl w="100%">
                  <FormLabel {...formLabelStyles.xs} {...formLabelStyle}>
                    Annual Property Taxes
                  </FormLabel>
                  <AmountInput
                    {...formInputStyles}
                    type="text"
                    id="annualPropertyTaxes"
                    name="annualPropertyTaxes"
                    allowdecimal="false"
                    placeholder="$1,000"
                    onBlur={() => {
                      setIsDirty({ ...isDirty, ...{ financials: dirty } });
                      setFormVariables({ annualPropertyTaxes: values.annualPropertyTaxes });
                    }}
                  />
                </FormControl>

                {/* Annual Property Insurance */}
                <FormControl w="100%">
                  <FormLabel {...formLabelStyles.xs} {...formLabelStyle}>
                    Annual Property Insurance
                  </FormLabel>
                  <AmountInput
                    {...formInputStyles}
                    type="text"
                    id="annualPropertyInsurance"
                    name="annualPropertyInsurance"
                    allowdecimal="false"
                    placeholder="$1,000"
                    onBlur={() => {
                      setIsDirty({ ...isDirty, ...{ financials: dirty } });
                      setFormVariables({
                        annualPropertyInsurance: values.annualPropertyInsurance,
                      });
                    }}
                  />
                </FormControl>
              </Box>

              {/* Mortgage Details */}
              <Box {...cFormControlStyles}>
                <Box mt="48px !important">
                  <Text {...formSectionTitleStyles} {...textLabelStyle}>
                    Mortgage Details
                  </Text>
                  {/* Down Payment */}
                  <FormControl w="100%">
                    <FormLabel {...formLabelStyles.xs} {...formLabelStyle}>
                      Down Payment
                    </FormLabel>
                    <AmountInput
                      {...formInputStyles}
                      type="text"
                      id="mortgageDownpayment"
                      name="mortgageDownpayment"
                      allowdecimal="true"
                      value={values.mortgageDownpayment}
                      onBlur={(e) => {
                        handleBlur(e);
                        setIsDirty({ ...isDirty, ...{ financials: dirty } });
                        setFormVariables({ mortgageDownpayment: values.mortgageDownpayment });
                      }}
                    />
                  </FormControl>

                  {/* Loan Terms */}
                  <FormControl w="100%">
                    <FormLabel {...formLabelStyles.xs} {...formLabelStyle}>
                      Loan Terms
                    </FormLabel>
                    <Select
                      {...formInputStyles}
                      p="0"
                      id="mortgageTerm"
                      name="mortgageTerm"
                      value={values.mortgageTerm}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setIsDirty({ ...isDirty, ...{ financials: dirty } });
                        setFormVariables({ mortgageTerm: values.mortgageTerm });
                      }}
                      {...formSelectStyle}
                    >
                      <option>Select Option</option>
                      {mortgageTermOptions.map((o) => (
                        <option key={o.id} value={o.id} defaultValue={values.mortgageTerm}>
                          {o.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Current Principle Balance */}

                  {lastEditedMortgageBalanceDate ? (
                    <BaselaneMessageCard
                      iconName=""
                      hasCloseButton={false}
                      borderColor="blue"
                      text="To edit ‘Current Principle Balance’ & ‘Mortgage Interest’, visit the Baselane on Desktop. "
                      containerStyles={containerStyles}
                    />
                  ) : (
                    <>
                      <FormControl w="100%">
                        <FormLabel {...formLabelStyles.xs} {...formLabelStyle}>
                          Current Principle Balance
                        </FormLabel>
                        <AmountInput
                          type="text"
                          {...formInputStyles}
                          id="mortgagePrincipleBalance"
                          name="mortgagePrincipleBalance"
                          allowdecimal="true"
                          value={values.mortgagePrincipleBalance}
                          onBlur={(e) => {
                            handleBlur(e);
                            setIsDirty({ ...isDirty, ...{ financials: dirty } });
                            setFormVariables({
                              mortgagePrincipleBalance: values.mortgagePrincipleBalance,
                            });
                          }}
                          // readOnly={!!property}
                        />
                      </FormControl>
                      <FormControl w="100%">
                        <FormLabel {...formLabelStyles.xs} {...formLabelStyle}>
                          Mortgage Interest Rate
                        </FormLabel>
                        <Input
                          {...formInputStyles}
                          placeholder="0.000%"
                          as={MaskedInput}
                          mask={percentageMask({ decimalLimit: 3 })}
                          id="mortgageInterestRate"
                          name="mortgageInterestRate"
                          value={values.mortgageInterestRate}
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                            setIsDirty({ ...isDirty, ...{ financials: dirty } });
                            setFormVariables({
                              mortgageInterestRate: values.mortgageInterestRate,
                            });
                          }}
                        />
                      </FormControl>
                    </>
                  )}

                  {/* Add Property ? Mortgage Interest Rate : Mortgage Details Button */}
                </Box>
              </Box>
              {/* Market Value */}
              <Box {...cFormControlStyles}>
                <Text {...formSectionTitleStyles} {...textLabelStyle}>
                  Market Value
                </Text>

                {/* Current Market Value */}
                {lastEditedMarketPriceDate ? (
                  <BaselaneMessageCard
                    iconName=""
                    hasCloseButton={false}
                    borderColor="blue"
                    text="To edit ‘Current Market Value’, visit Baselane on Desktop."
                    containerStyles={containerStylesNoMargin}
                  />
                ) : (
                  <FormControl w="100%">
                    <FormLabel {...formLabelStyles.xs}>Current Market Value</FormLabel>
                    <AmountInput
                      {...formInputStyles}
                      type="text"
                      id="currentMarketValue"
                      name="currentMarketValue"
                      allowdecimal="true"
                      value={latestMarketPrice ? latestMarketPrice?.price : ''}
                      // value={values.marketPrice}
                      onBlur={(e) => {
                        handleBlur(e);
                        setIsDirty({ ...isDirty, ...{ financials: dirty } });
                        setFormVariables({ date: moment().toDate() });
                      }}
                    />
                  </FormControl>
                )}
              </Box>
            </Stack>
          )}
        </Formik>
      </>
    </Box>
  );
}

export default Financials;
