// @flow
import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { Formik } from 'formik';
import IconLock from '@icons/legacy/IconLock';
import { phoneNumberMask } from '@core/utils/masks';
import formatTextSpacing from '@core/utils/formatTextSpacing';
import {
  AlertFooter,
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  BaselaneButtonGroup,
  BaselaneDrawer,
} from '@shared/components';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import getBreakPoints from '@core/utils/getBreakPoints';
import { handleValidation } from '../helpers/editTenantDrawer.helpers';
import { formTitleStyles } from '../styles/drawer.style';
import { UPDATE_TENANT_PROFILE } from '../queries';

type EditTenantDrawerProps = {
  tenant: Object,
  editTenantDrawerRef: any,
  handleEditTenantDrawerClose: Function,
  otherTenantEmails: Array<String>,
  landlordEmail: String,
};

function EditTenantDrawer({
  tenant,
  editTenantDrawerRef,
  handleEditTenantDrawerClose,
  otherTenantEmails,
  landlordEmail,
}: EditTenantDrawerProps) {
  const { firstName, lastName, phoneNumber, email, status, id: tenantId } = tenant;

  const toast = useToast();
  const formikRef = useRef(null);
  const [apiErrors, setApiErrors] = useState(null);
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const showToastSave = () =>
    toast({
      position: 'bottom-left',
      description: 'Tenant updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const [updateTenant] = useMutation(UPDATE_TENANT_PROFILE);

  const tenantFormInitialValues = {
    firstName,
    lastName,
    email,
    phoneNumber: phoneNumber ?? '',
  };

  const handleSaveTenantError = (errors) => {
    if (errors[0]?.extensions?.exception?.status === 403) {
      setApiErrors({ email: 'This email is already in use.' });
    }
  };

  const handleTenantSave = (values, actions) => {
    setTimeout(() => {
      const unmaskedPhoneNumber = values.phoneNumber.replace(/[- )(]/g, '');
      const formattedPhoneNumber = unmaskedPhoneNumber === '' ? null : values.phoneNumber;

      const formValues = {
        id: tenantId,
        firstName: formatTextSpacing(values.firstName),
        lastName: formatTextSpacing(values.lastName),
        phoneNumber: formattedPhoneNumber,
      };

      const tenantEmail =
        tenantFormInitialValues.email !== values.email ? { email: values.email } : {};
      const variables =
        status === 'INVITE_SENT'
          ? {
              ...formValues,
              ...tenantEmail,
            }
          : {
              ...formValues,
            };

      updateTenant({ variables }).then((response) => {
        if (response?.errors) {
          handleSaveTenantError(response.errors);
          actions.setSubmitting(false);
          return;
        }
        actions.setSubmitting(false);
        handleEditTenantDrawerClose();
        showToastSave();
      });
    }, 1000);
  };

  const handleDrawerClose = () => {
    if (formikRef.current?.dirty) {
      onAlertOpen();
    } else {
      handleEditTenantDrawerClose();
    }
  };

  const alertFooter = (
    <AlertFooter
      rightButtonText="Exit without Saving"
      rightButtonEvent={() => {
        onAlertClose();
        handleEditTenantDrawerClose();
      }}
      leftButtonText="Continue Editing"
      leftButtonEvent={onAlertClose}
    />
  );

  const { isMax767 } = getBreakPoints();
  return (
    <>
      <Formik
        innerRef={formikRef}
        validate={(values) =>
          handleValidation({ values, status, landlordEmail, otherTenantEmails, setApiErrors })
        }
        initialValues={tenantFormInitialValues}
        onSubmit={handleTenantSave}
        validateOnBlur
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          dirty,
          isSubmitting,
          isValid,
        }) => {
          const isSaveButtonDisabled = !dirty || !isValid;

          return (
            <BaselaneDrawer
              ref={editTenantDrawerRef}
              title="Edit Tenant"
              size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
              closeEvent={handleDrawerClose}
              onClose={handleDrawerClose}
              footer={
                <BaselaneButtonGroup size="md">
                  <BaselaneButton
                    variant="outline"
                    palette="transparent"
                    onClick={handleDrawerClose}
                    size="md"
                  >
                    Cancel
                  </BaselaneButton>
                  <BaselaneButton
                    variant="filled"
                    palette="primary"
                    size="md"
                    onClick={handleSubmit}
                    isDisabled={isSaveButtonDisabled}
                    isLoading={isSubmitting}
                  >
                    Save
                  </BaselaneButton>
                </BaselaneButtonGroup>
              }
              newDesignDrawer
            >
              <Text {...formTitleStyles}>Tenant Details</Text>

              <Stack gap="24px">
                {/* First name */}
                <FormControl isInvalid={errors.firstName && touched.firstName}>
                  <FormLabel htmlFor="firstName" {...formLabelStyles.xs}>
                    First name
                  </FormLabel>
                  <Input
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...formInputStyles}
                  />
                  <FormErrorMessage height="16px">
                    {errors.firstName ? <Text {...formErrorStyles}>{errors.firstName}</Text> : null}
                  </FormErrorMessage>
                </FormControl>

                {/* Last name */}
                <FormControl isInvalid={errors.lastName && touched.lastName}>
                  <FormLabel htmlFor="lastName" {...formLabelStyles.xs}>
                    Last name
                  </FormLabel>
                  <Input
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...formInputStyles}
                  />
                  <FormErrorMessage height="16px">
                    {errors.lastName ? <Text {...formErrorStyles}>{errors.lastName}</Text> : null}
                  </FormErrorMessage>
                </FormControl>

                {/* Email */}
                <FormControl isInvalid={(errors.email || apiErrors?.email) && touched.email}>
                  <FormLabel htmlFor="email" {...formLabelStyles.xs}>
                    Email
                  </FormLabel>

                  {status === 'INVITE_SENT' ? (
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      {...formInputStyles}
                    />
                  ) : (
                    <InputGroup>
                      <Input
                        id="email"
                        type="text"
                        value={email}
                        readOnly
                        {...formInputStyles}
                        color="brand.darkBlue.300"
                        _hover={{ color: 'brand.darkBlue.300' }}
                        _focus={{ color: 'brand.darkBlue.300' }}
                      />

                      <InputRightElement color="brand.darkBlue.250" w="48px" h="48px">
                        <IconLock />
                      </InputRightElement>
                    </InputGroup>
                  )}

                  <FormErrorMessage height="16px">
                    {errors.email || apiErrors?.email ? (
                      <Text {...formErrorStyles}>{errors.email || apiErrors?.email}</Text>
                    ) : null}
                  </FormErrorMessage>
                </FormControl>

                {/* Phone */}
                <FormControl isInvalid={errors.phoneNumber && touched.phoneNumber}>
                  <FormLabel htmlFor="phoneNumber" {...formLabelStyles.xs}>
                    Phone
                  </FormLabel>
                  <Input
                    id="phoneNumber"
                    name="phoneNumber"
                    as={MaskedInput}
                    mask={phoneNumberMask}
                    value={values.phoneNumber}
                    type="text"
                    onChange={handleChange}
                    {...formInputStyles}
                  />
                  <FormErrorMessage height="16px">
                    {errors.phoneNumber ? (
                      <Text {...formErrorStyles}>{errors.phoneNumber}</Text>
                    ) : null}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </BaselaneDrawer>
          );
        }}
      </Formik>

      <BaselaneAlert
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        header={<AlertHeader title="You Have Unsaved Changes" />}
        body="Are you sure you want to exit without saving?"
        footer={alertFooter}
        size="xl"
      />
    </>
  );
}

export default EditTenantDrawer;
