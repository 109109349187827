import React from 'react';
import { Text, Box } from '@chakra-ui/react';
import { Icon16Minus, Icon16PlusCircle } from '@icons/16px/index';

function AmountOption({ item }: any) {
  return (
    <Text>
      {item.icon.name === 'Icon16PlusCircle' && (
        <Box
          as="span"
          color={item.icon.style.color}
          display="inline-block"
          mr={1}
          verticalAlign="middle"
        >
          <Icon16PlusCircle />
        </Box>
      )}
      {item.icon.name === 'Icon16Minus' && (
        <Box
          as="span"
          color={item.icon.style.color}
          display="inline-block"
          mr={1}
          verticalAlign="middle"
        >
          <Icon16Minus />
        </Box>
      )}
      <Box as="span" verticalAlign="middle">
        {item.name}
      </Box>
    </Text>
  );
}

export default AmountOption;
