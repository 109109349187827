import React from 'react';
import MaskedInput from 'react-text-mask';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import customTheme from '@core/theme';
import { BaselaneAddress, BaselaneAutoCompleteAddress } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import { zipcodeMask } from '@core/utils/masks';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import DrawerHeaderWrapper from '../DomesticWireDrawer/DrawerHeaderWrapper';
import { title2, title3, descriptionTextStyles } from './styles/main.styles';

type RecipientDetailsProps = {
  totalSteps: Number,
  currentStep: Number,
  values: Object,
  touched: Object,
  errors: Object,
  handleChange: Function,
  handleBlur: Function,
  formikRef: any,
};

const RecipientDetails = ({
  totalSteps,
  currentStep,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  formikRef,
}: RecipientDetailsProps) => {
  const { isMinXL } = getBreakPoints();

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      formikRef.current.handleSubmit();
    }
  };

  return (
    <>
      <Stack spacing={isMinXL ? 0 : 2}>
        <DrawerHeaderWrapper {...{ totalSteps, currentStep }}>
          <Text {...title2}>Who are you paying?</Text>
        </DrawerHeaderWrapper>
        <Text {...descriptionTextStyles}>Mail a check to pay a business or an individual.</Text>
      </Stack>
      <Stack spacing={isMinXL ? 2 : 3}>
        {/* Recipient Name */}
        <FormControl isInvalid={errors.recipientName && touched.recipientName}>
          <FormLabel htmlFor="recipientName" {...formLabelStyles.xs}>
            Recipient Name
          </FormLabel>
          <Input
            {...formInputStyles}
            id="recipientName"
            name="recipientName"
            value={values.recipientName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage {...formErrorStyles}>{errors.recipientName}</FormErrorMessage>
        </FormControl>

        {/* Recipient Email */}
        <FormControl isInvalid={errors.recipientEmail && touched.recipientEmail}>
          <FormLabel htmlFor="recipientEmail" {...formLabelStyles.xs}>
            Recipient email (optional)
          </FormLabel>
          <Input
            {...formInputStyles}
            id="recipientEmail"
            name="recipientEmail"
            value={values.recipientEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleOnKeyDown}
          />

          <FormErrorMessage {...formErrorStyles}>{errors.recipientEmail}</FormErrorMessage>
        </FormControl>
      </Stack>
      <Text {...title3}>Recipient’s Address</Text>

      <Stack spacing={2}>
        <BaselaneAutoCompleteAddress
          handleBlur={handleBlur}
          formLabelStyles={formLabelStyles.xs}
          cfieldContainer={{}}
          touched={touched}
          values={values}
          formInputStyles={formInputStyles}
          formErrorStyles={formErrorStyles}
          handleChange={handleChange}
          errors={errors}
          showIsRequired={false}
          maxLength="40"
        />

        {/* Unit Number */}
        <FormControl isInvalid={errors.unit && touched.unit}>
          <FormLabel htmlFor="unitNumber" {...formLabelStyles.xs}>
            Apartment, Unit, Suite, or Floor # (Optional)
          </FormLabel>
          <Input
            {...formInputStyles}
            id="unit"
            name="unit"
            maxLength="10"
            value={values.unit}
            placeholder="e.g. Floor 1"
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Text
            {...{ ...formLabelStyles.xs, color: customTheme.colors.brand.darkBlue['300'] }}
            align="right"
            mt="0.75"
          >
            {10 - values.unit.length} characters
          </Text>

          <FormErrorMessage {...formErrorStyles}>{errors.unit}</FormErrorMessage>
        </FormControl>

        {/* City */}
        <FormControl isInvalid={errors.city && touched.city}>
          <FormLabel htmlFor="city" {...formLabelStyles.xs}>
            City
          </FormLabel>
          <Input
            {...formInputStyles}
            id="city"
            name="city"
            value={values.city}
            placeholder="e.g. Philadelphia"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage {...formErrorStyles}>{errors.city}</FormErrorMessage>
        </FormControl>
        <Stack direction="row" spacing={2}>
          {/* State */}
          <FormControl w="50%" isInvalid={errors.state && touched.state}>
            <FormLabel htmlFor="state" {...formLabelStyles.xs}>
              State
            </FormLabel>
            <Select
              {...formInputStyles}
              p="0"
              id="state"
              name="state"
              value={values.state}
              placeholder="Select state"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <BaselaneAddress.StateOptions />
            </Select>
            <FormErrorMessage {...formErrorStyles}>{errors.state}</FormErrorMessage>
          </FormControl>

          {/* Zip Code */}
          <FormControl position="relative" w="50%" isInvalid={errors.zipcode && touched.zipcode}>
            <FormLabel htmlFor="zipcode" {...formLabelStyles.xs}>
              Zip Code
            </FormLabel>
            <Input
              {...formInputStyles}
              as={MaskedInput}
              mask={zipcodeMask}
              id="zipcode"
              name="zipcode"
              value={values.zipcode}
              placeholder="e.g. 19123"
              onChange={handleChange}
              type="text"
              onBlur={handleBlur}
            />
            <FormErrorMessage {...formErrorStyles}>{errors.zipcode}</FormErrorMessage>
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
};

export default RecipientDetails;
