import React, { useContext, useEffect } from 'react';

import { useLocation, Outlet } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { use100vh } from 'react-div-100vh';
import { useGate } from 'statsig-react';

import UserContext from '@contexts/UserContext';
import Sidebar from '@core/components/Sidebar';
import { HOME, GET_STARTED } from '@routes';
import MobileNav from '@core/components/NavigationAuth/MobileNav';
import getBreakPoints from '@core/utils/getBreakPoints';
import sendSegmentPageEvent from '@core/utils/sendSegmentEvent/sendSegmentPageEvent';
import Banner from '../Banner';
import PageHeader from '../PageHeader';
import { pageContainerStyles, pageSettings } from './styles/pageWrapper.styles';

const PageWrapper = () => {
  const {
    user,
    isDesktopCheckDepositOpen,
    isTabletCheckDepositOpen,
    isMobileCheckDepositOpen,
  } = useContext(UserContext);

  const { value: showNewDashboard } = useGate('dashboard-gate');

  const { isMin768: isDesktop } = getBreakPoints();

  const location = useLocation();
  const { pathname = '' } = location;

  const isHideScroll = pathname.indexOf('cashflow') !== -1;

  const isShowHorizontalScrollOnDesktop =
    !isDesktop && !isMobile && (pathname.includes(GET_STARTED) || pathname === HOME);

  useEffect(() => {
    sendSegmentPageEvent();
  }, [pathname]);

  const vhCalcMobile = use100vh();

  /**
   * Note: Due to the Entities page containing a carousel that requires
   * no padding surrounding the scrollable cards, this flag exists to
   * remove said padding.
   */
  const isEntitiesPage = pathname.includes('/banking/accounts/baselane_');
  const isPartnershipPage = pathname.includes('partners');
  const isDashboardPage = pathname.includes('dashboard');
  const isPropertiesMobilePage = pathname.includes('/properties') && isMobile;
  // for desktop
  const hidePagePadding =
    isEntitiesPage || isPartnershipPage || (showNewDashboard && isDashboardPage);

  return (!isDesktop && !isDesktopCheckDepositOpen) ||
    isMobileCheckDepositOpen ||
    isTabletCheckDepositOpen ? (
    <Stack {...{ ...pageSettings(user, isDesktop) }}>
      <MobileNav />

      <Stack
        {...pageContainerStyles(user, false, false, true, !isDesktop, vhCalcMobile)}
        id="page-wrapper"
      >
        <Banner />
        <Outlet />
      </Stack>
    </Stack>
  ) : (
    <Stack {...pageSettings(user, isDesktop)}>
      <PageHeader user={user} />

      <Stack
        {...pageContainerStyles(
          user,
          isHideScroll,
          isShowHorizontalScrollOnDesktop,
          hidePagePadding || !isDesktop,
          !isDesktop,
          vhCalcMobile,
          isPropertiesMobilePage
        )}
        id="page-wrapper"
      >
        <Sidebar />
        <Banner />
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default PageWrapper;
