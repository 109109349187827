import React, { forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, HStack } from '@chakra-ui/react';
import ParentListItem from '../../ParentListItem';
import CheckboxListItem from '../../CheckboxListItem';

import { listStyles } from '../../styles/dropdown.styles';

type ListOfOptionsWithCheckboxesProps = {
  onListItemClick: Function,
  options: Array<any>,
  selectedStagedOptions: Array<any>,
  itemRenderer: Function,
  hasFilterWrapper: Boolean,
  searchTerm: Array<String>,
  search: String,
  hideSearch?: Boolean,
};

const ListOfOptionsWithCheckboxes = forwardRef(
  (
    {
      onListItemClick,
      options,
      selectedStagedOptions,
      itemRenderer,
      hasFilterWrapper,
      searchTerm,
      search,
      hideSearch,
    }: ListOfOptionsWithCheckboxesProps,
    ref
  ) => {
    const setIsSelectedItem = (itemId) => selectedStagedOptions.some((opt) => opt.id === itemId);

    return (
      <Box key="option-wrap" {...listStyles(hasFilterWrapper, hideSearch)} ref={ref}>
        {options.map((option) => {
          return (
            <ParentListItem
              key={`parent-${option.title}-${uuidv4()}`}
              title={option.title}
              listItem={
                <Box
                  key={option.title}
                  {...{
                    textStyle: 'headline-xs',
                  }}
                >
                  {option.title}
                </Box>
              }
            >
              {option.items
                ?.filter((item) => {
                  return (
                    item[searchTerm[0]]?.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (item[searchTerm[1]] &&
                      item[searchTerm[1]]?.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                  );
                })
                .map((item, index) => (
                  <HStack key={item.id} align="center">
                    <CheckboxListItem
                      tabIndex="-1"
                      className="element"
                      key={item.id}
                      id={item.id}
                      onChange={(e) => onListItemClick(item, e)}
                      isChecked={setIsSelectedItem(item.id)}
                    >
                      {itemRenderer({ item, parentItem: option, index, search })}
                    </CheckboxListItem>
                  </HStack>
                ))}
            </ParentListItem>
          );
        })}
      </Box>
    );
  }
);

ListOfOptionsWithCheckboxes.defaultProps = {
  hideSearch: false,
};

export default ListOfOptionsWithCheckboxes;
