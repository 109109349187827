import React from 'react';
import { BaselaneButton, BaselaneDrawer } from '@shared/components';

type InvoiceDetailsFooterProps = {
  editState: boolean,
  handleCancelButton: Function,
  isValid: boolean,
  dirty: boolean,
  handleSubmit: Function,
  onClose: Function,
};

const InvoiceDetailsFooter = ({
  editState,
  handleCancelButton,
  isValid,
  dirty,
  handleSubmit,
  onClose,
}: InvoiceDetailsFooterProps) => {
  const { DrawerFooter } = BaselaneDrawer;

  return (
    <DrawerFooter gap={2} position="absolute" bottom="0px" width="100%" left="0px">
      {editState && (
        <>
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            onClick={() => {
              if (editState) {
                handleCancelButton({ dirty });
              } else {
                onClose();
              }
            }}
          >
            Cancel
          </BaselaneButton>
          <BaselaneButton
            variant="filled"
            palette="primary"
            size="md"
            isDisabled={!isValid || !dirty}
            onClick={handleSubmit}
          >
            Save
          </BaselaneButton>
        </>
      )}
    </DrawerFooter>
  );
};

export default InvoiceDetailsFooter;
