export const containerStyles = { p: '52px 32px 26px' };
export const successIconContainerStyles = { p: '0', m: '12px 0!important' };
export const paragraphContainerStyles = { p: '0', mt: '0!important' };

export const textHeadingStyles = {
  fontStyle: 'normal',
  fontWeight: 'semibold',
  fontSize: '2xl',
  lineHeight: '32px',
  color: '#192C3E',
  p: '0!important',
  m: '12px 0!important',
  mt: '0!important',
};

export const textStyles = {
  fontStyle: 'normal',
  textStyle: 'sm',
  color: '#3A4B5B',
  p: '0!important',
  m: '12px 0!important',
  mt: '0!important',
};

export const orderedListStyles = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  lineHeight: '20px',
  color: '#192C3E',
  h: '30px',
  mb: '24px',
};

export const bulletOrderedListStyles = {
  background: '#257ED0',
  border: '1px solid #257ED0',
  w: '32px',
  h: '32px',
  borderRadius: '100%',
  justifyContent: 'center',
  textAlign: 'center',
  color: 'brand.neutral.white',
  lineHeight: '30px',
};

export const listTextStyles = (isMobile) => ({
  fontStyle: 'normal',
  textStyle: 'sm',
  lineHeight: isMobile ? '17px' : '30px',
  color: '#192C3E',
  width: isMobile && '280px',
  verticalAlign: 'middle',
});

export const noteTextStyles = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '3xs',
  lineHeight: '14px',
  color: '#3A4B5B',
  p: '0!important',
  m: '12px 0!important',
  mt: '0!important',
  position: 'absolute',
  left: '32px',
  bottom: '80px',
};
