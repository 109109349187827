import React from 'react';

type IconCircleDashedProps = {
  w: Number,
  h: Number,
  color: String,
};

function IconCircleDashed({ w = 8, h = 8, color = '#ACB8C7' }: IconCircleDashedProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="3.5" stroke={color} fill="white" strokeDasharray="2 2" />
    </svg>
  );
}

export default IconCircleDashed;
