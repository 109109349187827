import habitatTheme from '@core/themeHabitat';

export const cardContainerStyles = (isMin768) => ({
  minWidth: '314px', // 312 + 2 to accommodate border
  minHeight: '106px', // 106 + 2 to accommodate border
  width: isMin768 ? '312px' : '100%',
  maxWidth: '100%',
});

export const summaryCardStyles = (isMin768) => ({
  ...cardContainerStyles(isMin768),
  py: '12px',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const summaryCardTitleStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
};

export const summaryCardValueStyles = {
  textStyle: 'data-xl',
  color: 'brand.neutral.900',
};

export const summaryCardCounterStyles = {
  color: 'brand.blue.800A',
  textStyle: 'xs-heavy',
  mt: '12px',
  _hover: {
    textDecoration: 'underline',
  },
};

export const cardInnerContainerStyles = {
  w: '100%',
  h: '100%',
};

export const cardBalanceLabelStyles = {
  textStyle: 'headline-md',
  color: habitatTheme.colors.brand.neutral['900'],
};

export const cardBalanceLabel2Styles = {
  textStyle: 'sm',
  color: habitatTheme.colors.brand.neutral['600'],
};

export const cardBalanceValueStyles = {
  textStyle: 'headline-lg',
  color: habitatTheme.colors.brand.neutral['700'],
};

export const cardButtonStyles = {
  textStyle: 'xs-heavy',
  color: habitatTheme.colors.brand.blue['800A'],
};
