// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=1813%3A0
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  HStack,
} from '@chakra-ui/react';
import { EDIT_ENTITY_ADDRESS } from '@routes';
import { BaselaneButtonIcon, BaselaneDrawer } from '@shared/components';
import { formInputStyles } from '@shared/styles/input.style';
import IconEdit from '@icons/legacy/IconEdit';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  labelTextStyles,
  valueTextStyles,
  xsFormLabelStyles,
  formErrorStyles,
} from './styles/body.styles';

type BodyProps = {
  account: Object,
  formikRef: any,
  handleValidation: Function,
  setIsDirty: Function,
  handleSubmit: Function,
  setIsClosingDelay: Function,
};

const Body = ({
  account,
  formikRef,
  handleValidation,
  setIsDirty,
  handleSubmit,
  setIsClosingDelay,
}: BodyProps) => {
  const { isMax576 } = getBreakPoints();
  const { DrawerBody } = BaselaneDrawer;
  const navigate = useNavigate();
  const { bankName, legalAddress, legalBusinessName, mailingAddress } = account || {};
  const {
    city: legalCity,
    postalCode: legalPostalCode,
    state: legalState,
    street: legalStreet,
    unit: legalUnit,
  } = legalAddress || {};
  const {
    city: mailingCity,
    postalCode: mailingPostalCode,
    state: mailingState,
    street: mailingStreet,
    unit: mailingUnit,
  } = mailingAddress || {};

  return (
    <DrawerBody p={isMax576 ? '16px' : '24px'}>
      <Formik
        innerRef={formikRef}
        initialValues={{ name: bankName }}
        validate={handleValidation}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ dirty, values, handleChange, handleBlur, errors }) => (
          <>
            {/* Name */}
            <FormControl isInvalid={errors.name} isRequired>
              <FormLabel htmlFor="name" {...xsFormLabelStyles} mb="8px">
                Entity nickname
              </FormLabel>
              <Input
                id="name"
                name="name"
                value={values?.name}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  setIsDirty(dirty);
                }}
                {...formInputStyles}
              />
              <FormErrorMessage {...formErrorStyles}>
                <Text>{errors.name}</Text>
              </FormErrorMessage>
            </FormControl>
          </>
        )}
      </Formik>

      {/* Legal Name */}
      <Text {...labelTextStyles}>Legal name</Text>
      {legalBusinessName ? (
        <Text {...valueTextStyles}>{legalBusinessName}</Text>
      ) : (
        <Text {...valueTextStyles}>&mdash;</Text>
      )}

      {/* Legal Address */}
      <Text {...labelTextStyles}>Legal address</Text>
      {legalAddress ? (
        <Text {...valueTextStyles}>
          {legalStreet}, {!legalUnit ? '' : `${legalUnit}, `} {legalCity}, {legalState}{' '}
          {legalPostalCode}
        </Text>
      ) : (
        <Text {...valueTextStyles}>&mdash;</Text>
      )}

      {/* Mailing Address */}
      <HStack mt="24px">
        <Box flex="1">
          <Text {...labelTextStyles} mt="0">
            Mailing address
          </Text>
          {mailingAddress ? (
            <Text {...valueTextStyles}>
              {mailingStreet}, {!mailingUnit ? '' : `${mailingUnit}, `} {mailingCity},{' '}
              {mailingState} {mailingPostalCode}
            </Text>
          ) : (
            <Text {...valueTextStyles}>&mdash;</Text>
          )}
        </Box>
        <BaselaneButtonIcon
          icon={<IconEdit />}
          variant="transparent"
          palette="primary"
          size="sm"
          onClick={() => {
            setIsClosingDelay(true);
            setTimeout(() => setIsClosingDelay(false), 1000);
            navigate({ pathname: EDIT_ENTITY_ADDRESS });
          }}
        />
      </HStack>
    </DrawerBody>
  );
};

export default Body;
