export const blueIconStyles = {
  h: '32px',
  w: '32px',
  bgColor: 'brand.blue.700',
  color: 'white',
  textStyle: 'headline-xs',
  borderRadius: '50%',
  align: 'center',
  justifyContent: 'center',
  flex: '0 0 32px',
  zIndex: 1,
  alignSelf: 'flex-start',
};
