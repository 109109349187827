import { truncatedText } from '@core/components/Shared/styles/text.style';

export const shareLinkStyles = {
  container: {
    color: 'brand.neutral.700',
    textStyle: 'sm',
    borderRadius: '4px',
    borderWidth: '1px',
    borderColor: 'brand.darkBlue.250',
    p: '4px',
    w: '100%',
  },
  innerContainer: {
    justifyContent: 'space-between',
  },
  text: {
    px: '12px',
    ...truncatedText,
  },
};
