/* eslint-disable react/prop-types */
import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { transferStates } from './constants';
import UpcomingPayment from '../../SendFunds/components/FormSteps/PaymentDetailsStep/recurring-payment/upcoming-payments/UpcomingPayment';

const UpcomingPaymentsAccordion = ({ title, data }) => {
  const untilUserCancels = !data?.lastPaymentDate;

  const transfers = data?.transfers ?? [];

  const showEllipsis = untilUserCancels || (!untilUserCancels && transfers.length > 12);

  const upcomingTransfers = data?.transfers?.filter(
    (transfer) => transfer.state === transferStates.UPCOMING
  );

  return (
    <Accordion allowToggle mt={2}>
      <AccordionItem border={0}>
        <h2>
          <AccordionButton
            p={2}
            borderRadius={8}
            border="1px solid"
            borderColor="brand.darkBlue.200"
            _hover={{
              bg: 'transparent',
            }}
            _expanded={{
              borderBottom: 0,
              borderBottomRadius: 0,
            }}
          >
            <Box display="flex" width="100%">
              <Text textStyle="sm-heavy" textAlign="left">
                {title}
              </Text>
              <Spacer />
              <Text textStyle="sm-heavy" textAlign="left">
                {upcomingTransfers.length}
              </Text>
              <AccordionIcon ml={1} height="24px" width="24px" />
            </Box>
          </AccordionButton>
        </h2>
        <AccordionPanel
          p={2}
          borderBottomRadius={8}
          border="1px solid"
          borderColor="brand.darkBlue.200"
        >
          {upcomingTransfers.map((transfer, index) => (
            <UpcomingPayment
              key={transfer.transferDate}
              upcomingPayment={transfer}
              mt={index ? 1 : 0} // Don't add margin-top to the first element.
            />
          ))}
          {/* Ellipsis. */}
          <Box>
            {showEllipsis ? (
              <Text textStyle="sm" textAlign="left">
                ...
              </Text>
            ) : null}
          </Box>
          {/* Last payment. */}
          {!untilUserCancels && transfers.length > 12 ? (
            <UpcomingPayment upcomingPayment={transfers[transfers.length - 1]} mt={1} />
          ) : null}
          {/* Ends. */}
          <Box display="flex" mt={1}>
            <Text textStyle="xs-heavy">Ends</Text>
            <Spacer />
            {data?.lastPaymentDate ? (
              <Text textStyle="sm">
                {DateTime.fromISO(data.lastPaymentDate).toFormat('MMM d, yyyy')}
              </Text>
            ) : (
              <Text textStyle="sm">Never</Text>
            )}
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default UpcomingPaymentsAccordion;
