import { useQuery } from '@apollo/client';
import { useGate } from 'statsig-react';
import { GET_USER_CURRENT_APY } from '@core/apollo/queries';
import { GET_BANK_SUMMARY_CURRENT_APY } from '@core/components/NativeBankingPage/queries';

const useCurrentApy = () => {
  const { value: isSavingsEnabled } = useGate('savings_account_gate');

  const { loading: userLoading, data: userData } = useQuery(GET_USER_CURRENT_APY, {
    skip: !isSavingsEnabled,
    fetchPolicy: 'cache-first',
  });

  const {
    user: {
      userMetadata: { currentApy: userCurrentApy },
    },
  } = userData || {
    user: {
      userMetadata: { currentApy: null },
    },
  };

  /**
   *  Note: This is the old query, and once 'savings_account_gate' is retired,
   *  we should eliminate it.
   */
  const { loading: bankSummaryLoading, data: bankSummaryData } = useQuery(
    GET_BANK_SUMMARY_CURRENT_APY,
    {
      skip: isSavingsEnabled,
      fetchPolicy: 'cache-first',
    }
  );

  const {
    bankSummary: { currentApy: bankSummaryCurrentApy },
  } = bankSummaryData || { bankSummary: { currentApy: {} } };

  const currentApy = isSavingsEnabled ? userCurrentApy : bankSummaryCurrentApy;

  return {
    loading: userLoading || bankSummaryLoading,
    currentApy,
  };
};

export default useCurrentApy;
