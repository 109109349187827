export const title = (isMobile) => ({
  fontSize: isMobile ? '2xl' : 'lg',
  lineHeight: isMobile ? '32px' : '26px',
  mb: isMobile ? '16px' : '42px',
  fontWeight: 'medium',
});

export const subtitleTextStyles = {
  textStyle: 'headline-md',
};

export const mobileTitle = {
  fontSize: '2xl',
  lineHeight: '32px',
  fontWeight: 'medium',
  mb: '16px',
};

export const drawerBodyStyles = (showEmptyState, isMobile) => {
  const baseStyles = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  };

  if (showEmptyState) {
    return { ...baseStyles, p: isMobile ? '32px 28px' : '52px 36px' };
  }
  return {
    ...baseStyles,
    p: isMobile ? '16px 24px' : '32px',
  };
};

export const drawerFooterStyles = () => ({
  p: '16px 24px',
  boxShadow: '1px -2px 6px 0px rgba(3, 24, 64, 0.04)',
});

export const containerStyle = {
  marginTop: '74px',
  p: 0,
};

export const disclaimerTextStyles = {
  mx: '0px',
};

export const triggerBtnWrapperStyles = { w: '64px', alignItems: 'center' };

export const triggerBtnLableStyles = {
  fontSize: '3xs',
  lineHeight: '14px',
  fontWeight: 'normal',
  color: 'brand.blue.800A',
  textAlign: 'center',
};
