import React from 'react';
import BaselaneButton from '../../BaselaneButton';
import { DisplayInputSharedProps } from '../types/displayInputVariations.types';

const DisplayInputButton = ({ onOpen, isDisabled, text, ...rest }: DisplayInputSharedProps) => {
  return (
    <BaselaneButton
      {...{
        onClick: onOpen,
        disabled: isDisabled,
        ...rest,
      }}
    >
      {text || 'Select date'}
    </BaselaneButton>
  );
};

DisplayInputButton.defaultProps = {
  isDisabled: false,
};

export default DisplayInputButton;
