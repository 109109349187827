import React from 'react';
import { Icon16Bolt } from '@icons/16px';
import { BaselaneChip } from '@shared/components';
import { ONBOARDING_PROPERTY_SURVEY, ONBOARDING_ADD_PROPERTY, ONBOARDING_TRIAGE } from '@routes';

const defaultCopy = (user) => ({
  beforeTitleText: `Hi ${user?.firstName?.split(' ')?.[0] || user?.firstName}`,
  title: 'Welcome to Baselane',
});

const onboardingTriageHeader = (user, onboardingTriageSelection, skippedBankingFirst) => {
  const { userMetadata = {} } = user || {};
  const { bankingTracker } = userMetadata || {};
  const isBankingFinished = (bankingTracker || [])[0].state === 'COMPLETED';

  if (!isBankingFinished && !skippedBankingFirst && onboardingTriageSelection === 'bookkeeping') {
    return 'Start with Baselane Banking to supercharge your bookkeeping';
  }

  if (onboardingTriageSelection === 'bookkeeping') {
    return `Now, let's streamline your bookkeeping`;
  }

  return 'Welcome to Baselane!';
};

export const supporting = (skippedBankingFirst, onboardingTriageSelection, isBankingFinished) => {
  if ((isBankingFinished || skippedBankingFirst) && onboardingTriageSelection === 'bookkeeping') {
    return (
      <BaselaneChip
        variant="simple-neutral"
        label="Set up in 2 minutes"
        size="md"
        leftIcon={Icon16Bolt}
      />
    );
  }
  if (onboardingTriageSelection === 'bookkeeping') {
    return (
      <BaselaneChip
        variant="simple-neutral"
        label="Get approved in minutes"
        size="md"
        leftIcon={Icon16Bolt}
      />
    );
  }

  return `All features will be available to you later`;
};

export const text = (user) => ({
  [ONBOARDING_PROPERTY_SURVEY]: {
    beforeTitleText: defaultCopy(user).beforeTitleText,
  },
  [ONBOARDING_ADD_PROPERTY]: {
    beforeTitleText: `Awesome, ${user?.firstName?.split(' ')?.[0] || user?.firstName}!`,
  },
});

export const heading = (user, onboardingTriageSelection, skippedBankingFirst) => {
  return {
    [ONBOARDING_PROPERTY_SURVEY]: {
      title: 'First, tell us about yourself.',
    },
    [ONBOARDING_ADD_PROPERTY]: {
      title: "Let's add your first rental property",
    },
    [ONBOARDING_TRIAGE]: {
      title:
        onboardingTriageSelection === 'bookkeeping'
          ? onboardingTriageHeader(user, onboardingTriageSelection, skippedBankingFirst)
          : 'Where do you want to start?',
    },
  };
};
