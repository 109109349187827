import React, { useContext, useEffect } from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { IllustrationBankingConnectExternalAccountWithPlaid } from '@illustrations';
import { IconCheck } from '@icons';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import userContext from '@contexts/UserContext';
import BaselaneButtonCard from '../BaselaneButtonCard';

import {
  cardBodyStyles,
  cardTextContainerStyles,
  cardTitleStyles,
  cardBadgeContainerStyles,
  cardBadgeTextStyles,
  iconContainerStyles,
  typeCardStyles,
} from '../../styles/typePopupButtons.styles';

type ConnectPlaidButtonProps = {
  handleActionButton: Function,
  setIsPlaidButtonLoading: Function,
  buttonStyles?: Object,
};

const ConnectPlaidButton = ({
  handleActionButton,
  setIsPlaidButtonLoading,
  buttonStyles,
}: ConnectPlaidButtonProps) => {
  const { user } = useContext(userContext);
  const { pathname } = window.location;
  const url = pathname;

  useEffect(() => {
    // There is some gap until the button loads in the popup, show the loader until this component loads
    // (loader in AddAccount/AddAccountMobile)
    setIsPlaidButtonLoading(false);
  }, []);

  return (
    <BaselaneButtonCard
      id="add-plaid-account-button"
      type="clean"
      onClick={() => {
        sendSegmentEvent(
          `${user?.onboardingCompleted ? 'baselane' : 'onboarding'}_click_connect_bank_account`,
          {
            type: 'Plaid',
            url,
          }
        );

        handleActionButton('plaid');
      }}
      buttonStyles={{ ...typeCardStyles, ...buttonStyles }}
    >
      <Flex {...iconContainerStyles}>
        <IllustrationBankingConnectExternalAccountWithPlaid />
      </Flex>
      <Box {...cardTextContainerStyles}>
        <HStack>
          <Text {...cardTitleStyles}>Add Securely with Plaid™</Text>
          <HStack {...cardBadgeContainerStyles}>
            <IconCheck w={12} h={12} color="#398311" />
            <Text {...cardBadgeTextStyles}>Easiest Option!</Text>
          </HStack>
        </HStack>

        <Text {...cardBodyStyles}>
          Instantly add your checking account with your bank credentials using Plaid™
        </Text>
      </Box>
    </BaselaneButtonCard>
  );
};

ConnectPlaidButton.defaultProps = {
  buttonStyles: {},
};

export default ConnectPlaidButton;
