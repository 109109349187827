import React from 'react';
import SelectProperty from './SelectProperty';
import SelectUnit from './SelectUnit';

type LinkPropertyProps = {
  sortedProperties: Array,
  showSelectUnit: Boolean,
  selectedProperty: String,
  setSelectedProperty: Function,
  selectedUnit: String,
  setSelectedUnit: Function,
  search: String,
  setSearch: Function,
};

const LinkProperty = ({
  sortedProperties,
  showSelectUnit,
  selectedProperty,
  setSelectedProperty,
  selectedUnit,
  setSelectedUnit,
  search,
  setSearch,
}: LinkPropertyProps) => {
  return showSelectUnit ? (
    <SelectUnit {...{ sortedProperties, selectedProperty, selectedUnit, setSelectedUnit }} />
  ) : (
    <SelectProperty
      {...{
        sortedProperties,
        selectedProperty,
        setSelectedProperty,
        setSelectedUnit,
        search,
        setSearch,
      }}
    />
  );
};

export default LinkProperty;
