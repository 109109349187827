const getHorizontalStyles = ({ numColumn }) => {
  return {
    grid: {
      gridTemplateColumns: `repeat(${numColumn}, 1fr)`,
      gap: '8px',
      m: '0 !important',
    },
    item: {
      container: {
        m: '0 !important',
      },
      radiocard: {
        default: {
          m: '0 !important',
          width: 'auto',
          height: 'auto',
          p: '6px 12px',
          borderRadius: '100px',
          borderColor: 'brand.blue.800A',
          color: 'brand.blue.800A',
          _hover: {
            background: 'brand.blue.50',
            cursor: 'pointer',
          },
        },
        selected: {
          background: 'brand.blue.800A',
          color: 'brand.neutral.white',
          _hover: {
            background: 'brand.blue.800A',
            color: 'brand.neutral.white',
          },
          _active: {
            background: 'brand.blue.800A',
            color: 'brand.neutral.white',
          },
        },
        focused: {
          boxShadow: '0 0 0 2px #9FD8FF',
          outline: 'none',
        },
        active: {
          background: 'brand.blue.100',
        },
        disabled: {
          background: 'brand.neutral.white',
          color: 'brand.neutral.500',

          _hover: {
            background: 'brand.neutral.white',
            color: 'brand.neutral.500',
          },
        },
      },
      iconcontainer: {
        w: '16px',
        h: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      textcontainer: {
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4px',
      },
      text: {
        textStyle: 'xs',
        m: '0 !important',
      },
    },
  };
};

const getVerticalStyles = ({ hasIcon, numColumn }) => {
  return {
    grid: {
      gridTemplateColumns: `repeat(${numColumn}, 1fr)`,
      gap: '8px',
      m: '0 !important',
    },
    item: {
      container: {
        m: '0 !important',
      },
      radiocard: {
        default: {
          m: '0 !important',
          width: 'auto',
          height: '100%',
          p: hasIcon ? '8px 12px' : '6px 12px',
          borderRadius: '6px',
          background: 'brand.neutral.white',
          borderColor: 'brand.blue.800A',
          color: 'brand.blue.800A',
          justifyContent: 'flex-start',
          _hover: {
            background: 'brand.blue.50',
            cursor: 'pointer',
          },
        },
        selected: {
          background: 'brand.blue.800A',
          color: 'brand.neutral.white',
          _hover: {
            background: 'brand.blue.800A',
            color: 'brand.neutral.white',
          },
          _active: {
            background: 'brand.blue.800A',
            color: 'brand.neutral.white',
          },
        },
        focused: {
          boxShadow: '0 0 0 2px #9FD8FF',
          outline: 'none',
        },
        active: {
          background: 'brand.blue.100',
        },
        disabled: {
          background: 'brand.neutral.white',
          color: 'brand.neutral.500',

          _hover: {
            background: 'brand.neutral.white',
            color: 'brand.neutral.500',
          },
        },
      },
      iconcontainer: {
        w: '16px',
        h: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      textcontainer: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '4px',
      },
      text: {
        textStyle: 'xs',
        m: '0 !important',
      },
    },
  };
};

const getToggleStyles = ({ isDisabled }) => {
  return {
    container: {
      gap: '4px',
      p: '4px',
      borderRadius: '50px',
      background: isDisabled ? 'brand.darkBlue.100' : 'brand.blue.50',
      width: 'fit-content',
      m: '0 !important',
    },
    item: {
      radiocard: {
        default: {
          m: '0 !important',
          width: 'auto',
          height: 'auto',
          p: '6px 12px',
          borderRadius: '100px',
          borderColor: 'transparent',
          color: 'brand.neutral.700',
          _hover: {
            background: 'brand.blue.50',
            color: 'brand.blue.800A',
            cursor: 'pointer',
          },
        },
        container: {
          m: '0 !important',
          pointerEvents: isDisabled && 'none',
        },
        selected: {
          background: 'brand.blue.800A',
          color: 'brand.neutral.white',

          _disabled: {
            background: 'brand.darkBlue.250',
            color: 'brand.neutral.white',
          },
          _hover: {
            background: 'brand.blue.800A',
            color: 'brand.neutral.white',
          },
          _active: {
            background: 'brand.blue.800A',
            color: 'brand.neutral.white',
          },
        },
        focused: (isSelectedItem) => {
          return {
            background: isSelectedItem ? 'brand.blue.800A' : 'brand.blue.50',
            color: isSelectedItem ? 'brand.neutral.white' : 'brand.blue.800A',
            boxShadow: '0 0 0 2px #9FD8FF',
            outline: 'none',
          };
        },
        active: {
          background: 'brand.blue.100',
          color: 'brand.blue.800A',
        },
        disabled: {
          color: 'brand.darkBlue.300',
          borderColor: 'transparent',

          _hover: {
            color: 'brand.darkBlue.300',
            borderColor: 'transparent',
          },
        },
      },
      iconcontainer: {
        w: '16px',
        h: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      textcontainer: {
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4px',
      },
      text: {
        textStyle: 'xs',
        m: '0 !important',
      },
    },
  };
};

export const getFilterPillStyles = ({ type, hasIcon, numColumn, isDisabled }) => {
  switch (type) {
    case 'horizontal':
      return getHorizontalStyles({ numColumn });

    case 'vertical':
      return getVerticalStyles({ hasIcon, numColumn });

    case 'toggle':
      return getToggleStyles({ isDisabled });

    default:
      return null;
  }
};
