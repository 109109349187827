// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { BaselaneDivider } from '@shared/components';
import { IconCheckCircleOutline, IconCurrentCircle } from '@icons';
import {
  checkFormIconStyles,
  checkValidFormCircleStyles,
  dashStylesValid,
} from '../../styles/tabs.styles';
import { isOptionDisabled } from '../helpers/tab.helpers';
import type { FlowPanelProps } from '../../types/FlowTabPanelProps';

type TabIconProps = {
  menuOptions: Array<FlowPanelProps>,
  option: Object,
  unitState: string,
  index: Number,
  tabIndex: Number,
  showGreenCircle?: Boolean,
};

function TabIcon({
  menuOptions,
  option,
  index,
  tabIndex,
  unitState,
  showGreenCircle,
}: TabIconProps): any {
  const shouldShowGreenCircle = showGreenCircle ?? option.isValid;

  return (
    <>
      <Box>
        {!isMobile ? (
          <IconCheckCircleOutline />
        ) : (
          <Box
            {...checkFormIconStyles(isMobile)}
            border="1px solid"
            borderColor={tabIndex === index ? 'brand.blue.700' : 'green.500A'}
          >
            {shouldShowGreenCircle &&
            !isOptionDisabled({ option, index, unitState, menuOptions }) &&
            tabIndex !== index ? (
              <IconCurrentCircle bgfill="#56C719" fillColor="#ffffff" bigIcon />
            ) : (
              <IconCurrentCircle
                bgfill="#ffffff"
                fillColor="#257ED0"
                bigIcon
                {...checkValidFormCircleStyles}
              />
            )}
          </Box>
        )}
      </Box>

      {isMobile && index < 3 && <BaselaneDivider styles={{ ...dashStylesValid }} />}
    </>
  );
}

TabIcon.defaultProps = {
  showGreenCircle: null,
};

export default TabIcon;
