import React from 'react';
import { HStack, Spacer, Text } from '@chakra-ui/react';
import BaselaneButton from '../BaselaneButton';
import {
  footerButtonStyles,
  footerContainerStyles,
  footerSelectedTextStyles,
} from './styles/filterWrapper.styles';

type DropdownFooterProps = {
  isDisabled: boolean,
  selectedStagedOptions: Array<object>,
  onApply: Function,
};

const DropdownFooter = ({ isDisabled, selectedStagedOptions, onApply }: DropdownFooterProps) => (
  <HStack {...footerContainerStyles}>
    {selectedStagedOptions?.length > 0 && (
      <Text {...footerSelectedTextStyles}>{selectedStagedOptions.length} selected</Text>
    )}
    <Spacer />
    <BaselaneButton
      id="apply-dropdown-button"
      size="md"
      variant="filled"
      palette="primary"
      isDisabled={isDisabled}
      onClick={onApply}
      styles={footerButtonStyles}
    >
      Apply
    </BaselaneButton>
  </HStack>
);

export default DropdownFooter;
