import React from 'react';

type IconChevronRightProps = {
  w?: Number,
  h?: Number,
  color?: String,
  className: String,
  isBold?: Boolean,
};

function IconChevronRight({
  w = 8,
  h = 14,
  color = 'currentColor',
  className,
  isBold = false,
}: IconChevronRightProps) {
  return isBold ? (
    <svg
      className={className}
      width={w}
      height={h}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.47036 12.5303C5.17747 12.2374 5.17747 11.7626 5.47036 11.4697L8.94003 8L5.47036 4.53033C5.17747 4.23744 5.17747 3.76256 5.47036 3.46967C5.76326 3.17678 6.23813 3.17678 6.53102 3.46967L10.4865 7.42515C10.804 7.74263 10.804 8.25737 10.4865 8.57485L6.53102 12.5303C6.23813 12.8232 5.76325 12.8232 5.47036 12.5303Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      className={className}
      width={w}
      height={h}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.646447.646447c.195262-.195263.511843-.195263.707103 0l6 6.000003c.19527.19526.19527.51184 0 .7071l-6 6.00005c-.19526.1952-.511841.1952-.707103 0-.195263-.1953-.195263-.5119 0-.7072L6.29289 7 .646447 1.35355c-.195263-.19526-.195263-.511841 0-.707103Z"
        fill={color}
      />
    </svg>
  );
}

IconChevronRight.defaultProps = {
  w: 8,
  h: 14,
  color: 'currentColor',
  isBold: false,
};

export default IconChevronRight;
