export const labelStyles = {
  fontSize: 'xs',
  color: 'brand.neutral.600',
  fontWeight: 'normal',
  mb: 0,
};

export const selectStyles = (width, listWidth = width) => {
  return {
    input: { w: width, maxWidth: width, h: '32px', fontSize: 'xs' },
    list: { w: listWidth, maxWidth: listWidth, minWidth: listWidth },
    icon: { width: '10', height: '7', m: '0 !important' },
  };
};
