import React from 'react';
import BaselaneBadge from '@shared/components/BaselaneBadge';

export const STATUS_TYPES = {
  SCHEDULED: {
    label: 'Scheduled',
    color: 'blue',
  },
  PROCESSING: {
    label: 'Processing',
    color: 'blue',
  },
  FAILED: {
    label: 'Failed',
    color: 'red',
  },
  COMPLETED: {
    label: 'Completed',
    color: 'green',
  },
  RETURNED: {
    label: 'Returned',
    color: 'yellow',
  },
  CANCELED: {
    label: 'Canceled',
    color: 'red',
  },
  IN_TRANSIT: {
    label: 'In Transit',
    color: 'blue',
  },
  DELIVERED: {
    label: 'Delivered',
    color: 'green',
  },
  DEPOSITED: {
    label: 'Deposited',
    color: 'green',
  },
};

export const getStatusElement = (status) => {
  const { label, color } = STATUS_TYPES[status];
  return (
    <BaselaneBadge type="badge" color={color} text={label} backgroundStyles={{ ml: '16px' }} />
  );
};
