import React from 'react';
import { isMobile } from 'react-device-detect';
import { Stack } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { IconAccountAndRoutingNumbers, IconPaypalVenmo } from '@icons';
import ButtonMethod from './ButtonMethod';
import BaselaneButton from '../../../BaselaneButton';
import BaselaneDrawer from '../../../BaselaneDrawer';

type EducationalDrawerWrapperProps = {
  educationalDrawerRef: any,
  handleDrawerClose: Function,
  handleDrawerOpen: Function,
  buttonContent: Object,
  hideButton?: boolean,
  isInEmptyState?: boolean,
  children: any,
};

const EducationalDrawerWrapper = ({
  educationalDrawerRef,
  handleDrawerClose,
  handleDrawerOpen,
  buttonContent,
  hideButton,
  isInEmptyState,
  children,
}: EducationalDrawerWrapperProps) => {
  const { isMax576 } = getBreakPoints();

  const handleEducationDrawerClose = () => handleDrawerClose(buttonContent.id);

  const handleEducationDrawerOpen = () => {
    handleDrawerOpen(buttonContent.id);
    sendSegmentEvent(buttonContent.segmentEvent, {
      method: buttonContent.method,
      platform: isMobile ? 'mobile' : 'desktop',
      hasCounterparty: !isInEmptyState,
    });
  };

  const getIcon = () => {
    if (buttonContent.method === 'initiate_ach_transfer') {
      return <IconAccountAndRoutingNumbers />;
    }

    if (buttonContent.method === 'third_party') {
      return <IconPaypalVenmo />;
    }

    return undefined; // Explicit return because of eslint.
  };

  return (
    <>
      {!hideButton && (
        <ButtonMethod
          icon={getIcon()}
          {...{
            handleOnClick: handleEducationDrawerOpen,
            title: buttonContent.title,
            description: buttonContent.description,
          }}
        />
      )}

      <BaselaneDrawer
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        ref={educationalDrawerRef}
        title={buttonContent.title}
        closeEvent={handleDrawerClose}
        hideOverlay
        isMobileHeader={isMax576}
        showBackButton={isMax576}
        footer={
          <BaselaneButton
            size="md"
            variant="outline"
            palette="neutral"
            onClick={() => handleEducationDrawerClose()}
          >
            Back
          </BaselaneButton>
        }
        newDesignDrawer
      >
        <Stack>{children}</Stack>
      </BaselaneDrawer>
    </>
  );
};

EducationalDrawerWrapper.defaultProps = { hideButton: false, isInEmptyState: false };

export default EducationalDrawerWrapper;
