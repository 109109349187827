import React from 'react';

type IconQuestionProps = {
  w?: Number,
  h?: Number,
};

function IconQuestion({ w = 40, h = 40 }: IconQuestionProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 40 40" fill="none">
      <circle cx="21" cy="21" r="15" fill="#8CCFFF" />
      <circle cx="19" cy="19" r="15" stroke="#000619" />
      <path
        d="M19 21.5C19 18.8529 22.5 18.8529 22.5 15.6765C22.5 13.9222 20.933 12.5 19 12.5C17.067 12.5 15.5 13.9222 15.5 15.6765"
        stroke="#000619"
        strokeLinecap="round"
      />
      <rect x="18.25" y="24" width="1.5" height="1.5" rx="0.75" fill="#000619" />
    </svg>
  );
}

IconQuestion.defaultProps = {
  w: 40,
  h: 40,
};

export default IconQuestion;
