// Figma: https://www.figma.com/file/JwOsv7msdbTUHSFt4NaTrB/Baselane---Design-System?node-id=13755%3A64267&t=wnA3GlZbLOhu1qKc-0
import React from 'react';
import { Box, Grid, GridItem, HStack, Stack, Text, useRadioGroup } from '@chakra-ui/react';
import BaselaneRadioCard from '../BaselaneRadioCard';
import { getFilterPillStyles } from './styles/baselaneFilterPill.styles';

type BaselaneFilterPillProps = {
  name: String,
  type?: String,
  options: Array<Object>,
  selectedId: String,
  hasIcon?: boolean,
  numColumn: Number,
  onOptionClick: Function,
  isDisabled?: boolean,
};

function BaselaneFilterPill({
  name,
  type,
  options,
  selectedId,
  hasIcon,
  numColumn,
  onOptionClick,
  isDisabled,
}: BaselaneFilterPillProps): any {
  const { grid, container, item } = getFilterPillStyles({ type, hasIcon, numColumn, isDisabled });
  const isHorizontal = type === 'horizontal';
  const isToggle = type === 'toggle';

  const { getRootProps } = useRadioGroup();
  const group = getRootProps();

  return isToggle ? (
    <HStack {...group} {...container}>
      {options.map((option) => {
        const { id, label, icon: Icon, iconProps, onClick } = option;

        return (
          <BaselaneRadioCard
            key={id}
            name={name}
            isChecked={id === selectedId}
            value={id}
            styles={item.radiocard.default}
            containerStyles={item.radiocard.container}
            checkedStyles={item.radiocard.selected}
            focusedStyles={item.radiocard.focused(id === selectedId)}
            activeStyles={item.radiocard.active}
            disabledStyles={item.radiocard.disabled}
            onChange={onClick || onOptionClick}
            isDisabled={isDisabled}
          >
            <Stack direction="row" {...item.textcontainer}>
              {Icon && (
                <Box {...item.iconcontainer}>
                  <Icon {...iconProps} />
                </Box>
              )}
              <Text {...item.text}>{label}</Text>
            </Stack>
          </BaselaneRadioCard>
        );
      })}
    </HStack>
  ) : (
    <Grid {...group} {...grid}>
      {options.map((option) => {
        const { id, label, icon: Icon, iconProps, onClick } = option;

        return (
          <GridItem key={id} {...item.container}>
            <BaselaneRadioCard
              key={id}
              name={name}
              isChecked={id === selectedId}
              value={id}
              styles={item.radiocard.default}
              checkedStyles={item.radiocard.selected}
              focusedStyles={item.radiocard.focused}
              activeStyles={item.radiocard.active}
              disabledStyles={item.radiocard.disabled}
              onChange={onClick || onOptionClick}
              isDisabled={isDisabled}
            >
              <Stack {...{ direction: isHorizontal ? 'row' : 'column' }} {...item.textcontainer}>
                {Icon && (
                  <Box {...item.iconcontainer}>
                    <Icon {...iconProps} />
                  </Box>
                )}
                <Text {...item.text}>{label}</Text>
              </Stack>
            </BaselaneRadioCard>
          </GridItem>
        );
      })}
    </Grid>
  );
}

BaselaneFilterPill.defaultProps = {
  type: 'horizontal',
  hasIcon: false,
  isDisabled: false,
};

export default BaselaneFilterPill;
