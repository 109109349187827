import React, { ReactElement } from 'react';
import { useToast } from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/client';
import { BaselaneButton } from '@shared/components';
import { IconEmailOpen } from '@icons';
import { RESEND_INVITE } from './queries';

type ResendInviteButtonProps = {
  tenantId: string,
  leaseId: string,
  variant?: string,
  palette?: string,
  label?: string,
  emailIcon?: boolean,
  size?: string,
  pullLeft: boolean,
  leftIcon?: ReactElement | null,
};

function ResendInviteButton({
  tenantId,
  leaseId,
  variant,
  palette,
  label,
  emailIcon,
  size,
  pullLeft,
  leftIcon,
}: ResendInviteButtonProps) {
  const toast = useToast();
  const Icon = <IconEmailOpen width="18px" height="18px" />;

  const [resendInvite, { loading }] = useLazyQuery(RESEND_INVITE, {
    fetchPolicy: 'no-cache',
    variables: leaseId
      ? { tenantProfileId: parseInt(tenantId, 10), leaseId }
      : { tenantProfileId: parseInt(tenantId, 10) },
    onCompleted: () => {
      toast({
        position: 'bottom-left',
        duration: 3000,
        isClosable: true,
        status: 'success',
        description: 'Email resent successfully',
      });
    },
  });

  const handleResendInvite = (e) => {
    e.stopPropagation();
    resendInvite();
  };

  return (
    <BaselaneButton
      variant={variant}
      pullLeft={pullLeft}
      palette={palette}
      onClick={handleResendInvite}
      disabled={loading}
      leftIcon={emailIcon ? Icon : leftIcon}
      size={size}
    >
      {label}
    </BaselaneButton>
  );
}

ResendInviteButton.defaultProps = {
  variant: 'outline',
  palette: 'neutral',
  label: 'Resend',
  emailIcon: false,
  size: 'sm',
  leftIcon: null,
};

export default ResendInviteButton;
