import React, { useState } from 'react';
import {
  Box,
  ChakraProvider,
  HStack,
  Input,
  Progress,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useMutation } from '@apollo/client';
import { SaveChangesBtn } from '@core/components/NativeBankingPage/components/IndividualInputEditButtons';
import { progressBarStyles } from '@core/components/OnboardingTriage/PropertySurvey/styles/propertySurvey.styles';
import {
  BaselaneLink,
  BaselaneButtonCard,
  BaselaneDivider,
  BaselaneSpinner,
} from '@shared/components';
import habitatTheme from '@core/themeHabitat';
import { formInputStyles } from '@shared/styles/input.style';
import useOnboardingStore from '@core/store/Onboarding';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { CREATE_OR_UPDATE_ONBOARDING_SURVEY } from '@core/components/UserProfilePage/queries';
import { SIGN_UP_AS_RENTER } from '@core/constants/routes';
import {
  buttonCardStyles,
  otherBoxStyles,
  titleTextStyles,
  subTitleTextStyles,
} from './styles/propertySurvey.styles';
import { survey } from './helpers/propertySurveyHelper';
import DeferredSignupPageHeader from './components/DeferredSignupPageHeader';
import DeferredPasswordSignUpForm from './components/DeferredPasswordSignUpForm';

const DeferredSignupPropertySurvey = () => {
  const {
    step,
    lastStep,
    questionnaire,
    questionnaireObject,
    email,
    setStep,
    addResponse,
  } = useOnboardingStore();

  const { questions, version } = survey || {};

  const [isOtherEditState, setIsOtherEditState] = useState(-1);
  const [otherEditValue, setOtherEditValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [updateSurvey] = useMutation(CREATE_OR_UPDATE_ONBOARDING_SURVEY);

  const handleSave = () => {
    setIsLoading(true);
    if (questionnaire?.otherSource) {
      sendSegmentEvent('onboarding_complete_property_survey', {
        version,
        questionnaire: questionnaireObject,
        otherSource: otherEditValue,
      });
    } else {
      sendSegmentEvent('onboarding_complete_property_survey', {
        version,
        questionnaire: questionnaireObject,
      });
    }
    return updateSurvey({ variables: { version, questionnaire } });
  };

  const setAnswer = (question, answer, newPage, eventString) => {
    if (eventString) {
      sendSegmentEvent(`onboarding_survey_${eventString}_continue`);
    }

    addResponse(question, answer, otherEditValue);

    if (newPage) {
      setStep(step + 1);
    }
  };

  const handleOnOtherChange = (e) => {
    setOtherEditValue(e.target.value);
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <VStack
        gap={0}
        justifyContent="flex-start"
        minHeight="none"
        bg="brand.neutral.white"
        minW="100%"
      >
        <DeferredSignupPageHeader email={email} />

        <Box h="100%" w="100%" px={2} pt={5}>
          {step < lastStep && (
            <>
              <Progress
                {...progressBarStyles}
                value={((step + 1) / lastStep) * 100}
                maxW="640px"
                mx="auto"
                mb={3}
                colorScheme="scheme.blue"
              />
              <Tabs index={step} mt="0" mx="auto" maxW="640px">
                <TabPanels>
                  {questions.map((item, index) => {
                    return (
                      <TabPanel key={item?.question} padding="0!important" overflow="auto">
                        {isLoading ? (
                          <BaselaneSpinner />
                        ) : (
                          <Box pb="32px">
                            <Text
                              {...titleTextStyles(index !== 0)}
                              mb={item?.subQuestion ? '8px' : '32px'}
                            >
                              {item?.question}
                            </Text>
                            {item?.subQuestion && (
                              <Text {...subTitleTextStyles} mb="32px">
                                {item?.subQuestion}
                              </Text>
                            )}
                            <Stack spacing={2}>
                              {item.answers.map((answer, answerIndex) =>
                                isOtherEditState !== answerIndex ? (
                                  <BaselaneButtonCard
                                    id={`question${index}-number-option${answerIndex}`}
                                    key={typeof answer === 'string' ? answer : answer?.label}
                                    type="clean"
                                    buttonStyles={buttonCardStyles}
                                    onClick={() => {
                                      if (answer?.type === 'text') {
                                        setIsOtherEditState(answerIndex);
                                      } else {
                                        setIsOtherEditState(-1);
                                        setAnswer(
                                          item?.question,
                                          answer?.value || answer,
                                          index !== lastStep,
                                          item?.eventString
                                        );
                                      }
                                    }}
                                  >
                                    <Text>{answer?.label || answer}</Text>
                                  </BaselaneButtonCard>
                                ) : (
                                  <Box
                                    {...otherBoxStyles}
                                    key={`${typeof answer === 'string' ? answer : answer?.label}`}
                                  >
                                    <HStack spacing={1}>
                                      <Text fontWeight="500">{answer?.label || answer}</Text>
                                      <Input
                                        id="property-survey-other-answer"
                                        name="other"
                                        placeholder="Type your answer"
                                        value={otherEditValue}
                                        onChange={handleOnOtherChange}
                                        {...formInputStyles}
                                        borderColor="brand.blue.700 !important"
                                        bg="brand.neutral.white"
                                        w="100%"
                                        isRequired
                                        autoFocus
                                        maxLength="150"
                                      />
                                      <SaveChangesBtn
                                        variant="filled"
                                        palette="primary"
                                        size="lg"
                                        isDisabled={!otherEditValue}
                                        onClick={() => {
                                          setIsOtherEditState(-1);
                                          setAnswer(
                                            item?.question,
                                            answer,
                                            index !== lastStep,
                                            item?.eventString
                                          );
                                        }}
                                      />
                                    </HStack>
                                  </Box>
                                )
                              )}
                            </Stack>

                            {index === 0 && (
                              <VStack>
                                <BaselaneDivider styles={{ my: 4, py: 0.5 }} />
                                <HStack alignSelf="flex-start">
                                  <Text textStyle="md-heavy">Not a landlord? </Text>
                                  <BaselaneLink to={`${SIGN_UP_AS_RENTER}`} variant="primary">
                                    See how to sign up as a renter
                                  </BaselaneLink>
                                </HStack>
                              </VStack>
                            )}
                          </Box>
                        )}
                      </TabPanel>
                    );
                  })}
                </TabPanels>
              </Tabs>
            </>
          )}
          {step === lastStep && (
            <DeferredPasswordSignUpForm email={email} onUserCreated={handleSave} />
          )}
        </Box>
      </VStack>
    </ChakraProvider>
  );
};

export default DeferredSignupPropertySurvey;
