import { useEffect } from 'react';
import useOnboardingStore from '@store/Onboarding';

/**
 * helper to set selection option if user refreshes page or get timed out.
 * @returns {{setSelection: ((function(*): *)|*), selection: null}}
 */
const useOnboardingBookkeepingOption = () => {
  const { selection, setSelection } = useOnboardingStore();
  const selectionStorage = localStorage.getItem('onboarding-selection');

  useEffect(() => {
    if (selectionStorage === 'bookkeeping') {
      setSelection('bookkeeping');
    }
  }, []);

  return { selection, setSelection };
};

export default useOnboardingBookkeepingOption;
