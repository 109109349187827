import React, { useContext } from 'react';
import BaselaneKYCForm from '@shared/components/BaselaneKYCForm';
import UserContext from '@contexts/UserContext';

type KYCVerifyIdentityProps = {
  vouchedDrawerRef: any,
  handleKYCReturnStatusDrawerOpen: Function,
};

const KYCVerifyIdentity = ({
  vouchedDrawerRef,
  handleKYCReturnStatusDrawerOpen,
}: KYCVerifyIdentityProps) => {
  const { user, kycShowPopup } = useContext(UserContext);
  const { userKycStatus } = user;

  // Vouched Ref
  const handleVouchedDrawerOpen = () => vouchedDrawerRef?.current?.open();

  return (
    kycShowPopup && (
      <BaselaneKYCForm
        isAutoOpen={kycShowPopup}
        hideButton
        kycStatus={userKycStatus}
        handleKYCReturnStatusDrawerOpen={handleKYCReturnStatusDrawerOpen}
        handleVouchedDrawerOpen={handleVouchedDrawerOpen}
      />
    )
  );
};

export default KYCVerifyIdentity;
