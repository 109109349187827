import React from 'react';
import { Text } from '@chakra-ui/react';
import { BaselaneChip } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import useCurrentApy from '@shared/hooks/useCurrentApy';

const CurrentApyChip = () => {
  const { currentApy } = useCurrentApy();

  const apyLabel = (
    <>
      {currentApy ? formatCurrency(currentApy, true).noDollar : '--'}
      <Text as="span">% APY</Text>
    </>
  );

  return <BaselaneChip size="md" variant="simple-neutral" label={apyLabel} />;
};

export default CurrentApyChip;
