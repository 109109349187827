export const sections = {
  securityDeposit: {
    title: {
      label: 'Collect security deposit',
      type: 'switch',
    },
    amount: {
      label: 'Security deposit amount',
      type: 'textbox',
      props: {
        id: 'securityDepositAmount',
        name: 'securityDepositAmount',
        placeholder: '$',
      },
    },
    dueDate: {
      label: 'Security deposit due date',
      type: 'select',
      props: {
        placeholder: 'Select date',
        id: 'securityDepositDueDate',
        name: 'securityDepositDueDate',
      },
      children: true,
    },
    paid: {
      label: 'Mark as paid',
      type: 'checkbox',
      props: {
        id: 'securityDepositPaid',
        name: 'securityDepositPaid',
      },
    },
  },
  lastMonthRent: {
    title: {
      label: 'Collect last month’s rent upfront',
      type: 'switch',
    },
    dueDate: {
      label: 'Last month rent due date',
      type: 'select',
      props: {
        placeholder: 'Select date',
        id: 'lastMonthRentDueDate',
        name: 'lastMonthRentDueDate',
      },
    },
    paid: {
      label: 'Mark as paid',
      type: 'checkbox',
      props: {
        id: 'lastMonthRentPaid',
        name: 'lastMonthRentPaid',
      },
    },
  },
  proratedRent: {
    title: {
      label: 'Collected prorated rent',
      type: 'switch',
    },
    amount: {
      label: 'Prorated rent amount',
      type: 'textbox',
      props: {
        id: 'proratedRentAmount',
        name: 'proratedRentAmount',
        placeholder: '$',
      },
    },
    dueDate: {
      label: 'Prorated rent due date',
      type: 'select',
      props: {
        id: 'proratedRentDueDate',
        name: 'proratedRentDueDate',
        placeholder: 'Select date',
      },
    },
  },
  additionalFees: {
    title: {
      label: 'Custom one-time fee',
      type: 'switch',
    },
    description: {
      label: 'Fee name',
      type: 'textbox',
      props: {
        placeholder: 'e.g. Moving in fee',
      },
    },
    amount: {
      label: 'Fee amount',
      type: 'textbox',
      props: {
        placeholder: '$',
      },
    },
    dueDate: {
      label: 'Fee due date',
      type: 'select',
      props: {
        id: 'dueDate',
        name: 'dueDate',
        placeholder: 'Select date',
      },
    },
    button: {
      label: 'Add another fee',
      type: 'button',
    },
  },
  recurringMonthlyFees: {
    title: {
      label: 'Add recurring fee',
      type: 'switch',
    },
    description: {
      label: 'Fee name',
      type: 'textbox',
      props: {
        placeholder: 'e.g. Parking fee',
      },
    },
    amount: {
      label: 'Fee amount',
      type: 'textbox',
      props: {
        placeholder: '$',
      },
    },
    button: {
      label: 'Add another fee',
      type: 'button',
    },
  },
};

export default sections;
