// @flow
import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { invoiceTableStyles } from '@pages/LeasesPage/styles/invoice.styles';
import type { HeaderItems } from '@pages/LeasesPage/types';

type ListHeaderProps = {
  isLeaseLevel: boolean,
};

const headerItemsArr: HeaderItems[] = [
  { key: 'property', label: 'Property' },
  { key: 'description', label: 'Invoice' },
  { key: 'dueDate', label: 'Due date' },
  { key: 'amount', label: 'Amount' },
  { key: 'spacer', label: '' },
  { key: 'state', label: 'Payment Status' },
  { key: 'details', label: '' },
];

const ListHeader = ({ isLeaseLevel }: ListHeaderProps) => {
  const { header } = invoiceTableStyles(isLeaseLevel);

  const headerItems = isLeaseLevel
    ? headerItemsArr.filter((item) => item.key !== 'property')
    : headerItemsArr.filter((item) => item.key !== 'spacer');

  return (
    <Grid {...header.container}>
      {headerItems.map((item) => (
        <GridItem key={item.key} {...header.item(item.key)}>
          {item.label}
        </GridItem>
      ))}
    </Grid>
  );
};

export default ListHeader;
