export const footerLabelTextStyles = {
  color: 'brand.neutral.600',
  textStyle: 'sm',
};

export const footerAmountStyles = (value) => {
  const style = {
    color: 'brand.neutral.700',
  };

  if (value > 0) {
    return { color: 'brand.blue.800A' };
  }

  if (value === 0) {
    return { color: 'green.800AA' };
  }

  return style;
};
