/* eslint-disable react/prop-types */
import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Spacer,
} from '@chakra-ui/react';

const ScheduledPaymentsAccordion = ({ title, children }) => {
  if (!children || children.length === 0) {
    return null;
  }
  const hasMoreThanOneChild = children?.length > 1;
  return hasMoreThanOneChild ? (
    <Accordion allowToggle mt={2}>
      <AccordionItem border={0}>
        <h2>
          <AccordionButton
            p={2}
            borderRadius={8}
            border="1px solid"
            borderColor="brand.darkBlue.200"
            _hover={{
              bg: 'transparent',
            }}
            _expanded={{
              borderBottom: 0,
              borderBottomRadius: 0,
            }}
          >
            <Box display="flex" width="100%">
              <Text textStyle="sm-heavy" textAlign="left">
                {title}
              </Text>
              <Spacer />
              {hasMoreThanOneChild ? (
                <Text textStyle="sm-heavy" textAlign="left">
                  {children.length}
                </Text>
              ) : null}
              {hasMoreThanOneChild ? <AccordionIcon ml={1} height="24px" width="24px" /> : null}
            </Box>
          </AccordionButton>
        </h2>
        <AccordionPanel
          p={2}
          borderBottomRadius={8}
          border="1px solid"
          borderColor="brand.darkBlue.200"
        >
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : (
    <Box mt={2}>
      <Box p={2} borderTopRadius={8} border="1px solid" borderColor="brand.darkBlue.200">
        <Text textStyle="sm-heavy" textAlign="left">
          {title}
        </Text>
      </Box>
      <Box
        p={2}
        borderBottomRadius={8}
        border="1px solid"
        borderColor="brand.darkBlue.200"
        borderTop={0}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ScheduledPaymentsAccordion;
