import React, { forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, HStack } from '@chakra-ui/react';
import ParentListItem from '../../ParentListItem';
import DropdownContentWrapper from '../../DropdownContentWrapper';

import { setIsSelectedItem } from '../../helpers/dropdown.shared.helpers';
import { t1Search } from '../../utils/search';
import { parentTextStyles } from '../../styles/parentListItem.styles';
import { accordionItemChildStyles, itemContainerStyles } from '../../styles/accordion.styles';

type ListOfOptionsProps = {
  handleListItemClick: Function,
  options: Array<any>,
  selectedStagedOptions: Array<any>,
  itemRenderer: Function,
  hasFilterWrapper: boolean,
  searchTerm: Array<String>,
  search: string,
  showDivider: boolean,
  dropdownRef: React.Node,
};

const ListOfOptions = forwardRef(
  (
    {
      handleListItemClick,
      options,
      selectedStagedOptions,
      itemRenderer,
      hasFilterWrapper,
      searchTerm,
      search,
      showDivider,
      dropdownRef,
    }: ListOfOptionsProps,
    ref
  ) => {
    return (
      <DropdownContentWrapper {...{ hasFilterWrapper, ref }}>
        {options.map((option, index) => {
          const availableItems = t1Search({ options: option.items, searchTerm, search });

          return (
            <ParentListItem
              key={`parent-${option.title}-${uuidv4()}`}
              title={option.title}
              showDivider={showDivider}
              index={index}
              listItem={
                <Box key={option.title} {...parentTextStyles}>
                  {option.title}
                </Box>
              }
            >
              {availableItems.map((item, innerIndex) => (
                <HStack key={item.id} {...itemContainerStyles}>
                  <HStack
                    tabIndex="-1"
                    className="element"
                    key={item.id}
                    id={item.id}
                    onClick={(e) => {
                      handleListItemClick(item, e);
                    }}
                    bgColor={
                      setIsSelectedItem(item, selectedStagedOptions) ? 'brand.blue.50' : 'initial'
                    }
                    {...accordionItemChildStyles}
                  >
                    {itemRenderer({ item, parentItem: option, index: innerIndex, search })}
                  </HStack>
                </HStack>
              ))}
            </ParentListItem>
          );
        })}
      </DropdownContentWrapper>
    );
  }
);

export default ListOfOptions;
