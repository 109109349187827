import React from 'react';
import { Text, HStack } from '@chakra-ui/react';

import { BaselaneCardNew } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  summaryCardCounterStyles,
  summaryCardStyles,
  summaryCardTitleStyles,
  summaryCardValueStyles,
} from './styles/card.styles';

type SummaryCardProps = {
  totalBalance: Number,
  cards: Array<Object>,
  handleFilterPopupOpen: Function,
};

const SummaryCard = ({ totalBalance, cards, handleFilterPopupOpen }: SummaryCardProps) => {
  const { isMin768 } = getBreakPoints();

  return (
    <BaselaneCardNew
      variant="clickable"
      key="cardsummary"
      onClick={handleFilterPopupOpen}
      {...summaryCardStyles(isMin768)}
    >
      <Text {...summaryCardTitleStyles}>Total Available Balance</Text>

      <HStack>
        <Text {...summaryCardValueStyles}>{formatCurrency(totalBalance).inDollars}</Text>
      </HStack>
      <Text {...summaryCardCounterStyles}>{cards.length - 1} Accounts</Text>
    </BaselaneCardNew>
  );
};

export default SummaryCard;
