// @flow
import React from 'react';
import BaselaneButton from '@shared/components/BaselaneButton';
import AddBankAccount from '../../../../../../../AddBankAccount';

type FooterProps = {
  banks: Array<Object>,
  handleDrawerCancel: Function,
  handleSuccessFn: Function,
  plaidText: Object,
  setPlaidText: Function,
  connectedExternalBank: string,
  onPlaidConfirmationClose: Function,
  isLoading: boolean,
  styles: Object,
};

function Footer({
  banks,
  handleDrawerCancel,
  handleSuccessFn,
  plaidText,
  setPlaidText,
  connectedExternalBank,
  onPlaidConfirmationClose,
  isLoading,
  styles,
}: FooterProps): any {
  return (
    <>
      <BaselaneButton onClick={handleDrawerCancel} size="md" variant="outline" palette="neutral">
        Cancel
      </BaselaneButton>
      <AddBankAccount
        titleText={plaidText.error ? 'Connect Different Account' : 'Connect External Account'}
        handleSuccessFn={handleSuccessFn}
        setPlaidText={setPlaidText}
        size="md"
        hasIconLock
        banks={banks}
        bankAccId={connectedExternalBank?.id}
        onPlaidConfirmationClose={onPlaidConfirmationClose}
        isDisabled={isLoading}
        variant="filled"
        palette="primary"
        styles={{ ml: 1.5, flex: 1, width: '100%' }}
      />
    </>
  );
}

export default Footer;
