import React from 'react';
import { Stack } from '@chakra-ui/react';
import BaselaneTooltip from '../BaselaneTooltip';
import { tooltipInnerWrapperStyles } from './styles/tooltip.styles';

type TooltipProps = {
  children: any,
  tooltipText: string,
};

const TooltipWithHover = ({ children, tooltipText }: TooltipProps) => {
  return (
    <BaselaneTooltip
      placement="top"
      label={tooltipText}
      styles={{ w: '218px' }}
      innerContainerStyles={{
        m: 'inherit !important',
      }}
    >
      <Stack {...tooltipInnerWrapperStyles}>{children}</Stack>
    </BaselaneTooltip>
  );
};

export default TooltipWithHover;
