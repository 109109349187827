import React from 'react';
import { Text, Box } from '@chakra-ui/react';

interface TransferOriginProps {
  account: {
    isExternal: boolean,
    name: String,
    accountName: String,
    accountNumber: String,
  };
  externalId: string;
  isDetailsView?: boolean;
  origin: 'from' | 'to';
  transferType: string;
}

const extraSmallText = {
  fontSize: '2xs',
  color: 'brand.neutral.500',
  lineHeight: '12px',
};

export const TransferOrigin = ({
  account,
  externalId,
  isDetailsView = false,
  origin,
  transferType,
}: TransferOriginProps) => {
  let groupName = account?.isExternal
    ? account?.institution?.plaidInstitutionName
    : account?.institution?.name || `Baselane ${account?.institution?.id}`;

  if (groupName === 'manual') groupName = 'Manually Added Accounts';
  let accountLabel = account?.isExternal ? account?.name : `${groupName} > ${account?.name}`;
  const bankLabel = account?.isExternal ? account?.institution?.plaidInstitutionName : 'Baselane';
  const oldAccountPrefix = origin === 'from' && transferType === 'BANK_MIGRATION' ? '[OLD]' : '';

  if (!account) {
    if (transferType === 'CHECK_DEPOSIT') accountLabel = `Mobile Check #${externalId}`;

    return (
      <Box p={isDetailsView ? '' : '12px 24px'}>
        <Text textStyle={isDetailsView ? 'sm' : ''}>{accountLabel}</Text>
        <Text {...extraSmallText}>{bankLabel}</Text>
      </Box>
    );
  }

  if (isDetailsView) {
    return (
      <>
        <Text textStyle="sm">
          {account?.isExternal
            ? `${account?.accountName} ${
                account?.accountName === null || account?.accountNumber === null ? '' : '-'
              }`
            : `${oldAccountPrefix ? `${oldAccountPrefix} >` : ''} ${account?.institution?.name} > ${
                account?.name
              } -`}{' '}
          {account?.accountNumber?.slice(-4) ?? ''}
        </Text>
        <Text {...extraSmallText}>{account?.institution?.plaidInstitutionName}</Text>
      </>
    );
  }

  return (
    <Box p="12px 24px">
      {/* eslint-disable-next-line  */}
      {account ? (
        account?.isExternal ? (
          <>
            <Text>{account?.name}</Text>
            <Text {...extraSmallText}>
              {`${account?.accountName} ${
                account?.accountName === null || account?.accountNumber === null ? '' : '-'
              } ${account?.accountNumber ?? ''}`}
            </Text>
          </>
        ) : (
          <>
            <Text>{account?.institution?.name}</Text>
            <Text {...extraSmallText}> {`${oldAccountPrefix} ${account?.name}`}</Text>
          </>
        )
      ) : (
        <Text>&mdash;</Text>
      )}
    </Box>
  );
};
