import React from 'react';
import customTheme from '@core/theme';
import { BaselaneResponsiveBanner } from '@shared/components';
import { IconUIArrowRight } from '@icons';
import { IllustrationRCSetuprentcollection } from '@illustrations';

type LeaseDraftBannerProps = {
  draftCount: number,
  startRentCollection: Function,
};

function LeaseDraftBanner({ draftCount, startRentCollection }: LeaseDraftBannerProps) {
  return (
    <BaselaneResponsiveBanner
      icon={<IllustrationRCSetuprentcollection />}
      indicatorColor={customTheme.colors.brand.blue['50']}
      title={`You have ${draftCount} draft rent collection${draftCount === 1 ? '' : 's'}`}
      description="You’re almost there. Pick up from where you left off."
      callToActionLabel="Finish set up"
      callToActionRightIcon={<IconUIArrowRight w="18" />}
      onCallToActionClick={() => {
        startRentCollection();
      }}
      styles={{
        title: { color: customTheme.colors.brand.neutral['700'] },
        description: { color: customTheme.colors.brand.neutral['600'] },
      }}
      borderColor={customTheme.colors.brand.darkBlue['150']}
      m="16px"
    />
  );
}

export default LeaseDraftBanner;
