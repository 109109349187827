import React from 'react';
import { BaselaneButton, BaselaneDrawer } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';

type AddFundsDrawerFooterProps = {
  onCloseAddFundsDrawer: Function,
};

const AddFundsDrawerFooter = ({ onCloseAddFundsDrawer }: AddFundsDrawerFooterProps) => {
  const { DrawerFooter } = BaselaneDrawer;
  const { isMinXL } = getBreakPoints();
  return (
    !isMinXL && (
      <DrawerFooter>
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={onCloseAddFundsDrawer}
        >
          Close
        </BaselaneButton>
      </DrawerFooter>
    )
  );
};

export default AddFundsDrawerFooter;
