import React from 'react';

type IconLinkProps = {
  w?: number,
  h?: number,
  color?: string,
};

const IconLink = ({ w, h, color }: IconLinkProps) => {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.10577 1.58978C9.81303.906686 10.7603.528707 11.7435.537251c.9833.008544 1.9238.402928 2.6191 1.098209.6953.69528 1.0897 1.63583 1.0982 2.61906.0085.98324-.3694 1.9305-1.0525 2.63776l-.0092.0093-3 3c-.7032.70302-1.65693 1.09802-2.6513 1.09802-.99436 0-1.94802-.3949-2.65125-1.09794-.29294-.29285-.29301-.76773-.00016-1.06066.29285-.29294.76772-.29301 1.06066-.00016.42194.42181.99413.65877 1.59075.65877.59658 0 1.16874-.23693 1.5907-.65869 0-.00003-.0001.00002 0 0l2.995-2.99508c.4072-.42389.6325-.99036.6274-1.57828-.0052-.58994-.2418-1.15427-.659-1.57144-.4171-.41717-.9815-.6538-1.5714-.65893-.5879-.0051-1.1544.22012-1.5783.62732l-.82007.82007c-.29289.29289-.76777.29289-1.06066 0-.29289-.2929-.29289-.76777 0-1.06067l.825-.825.0093-.00913ZM4.59655 6.09884c.70323-.70302 1.65689-1.09795 2.65125-1.09795.99437 0 1.94802.39493 2.65125 1.09795.29295.29284.29305.76772.00016 1.06066-.29285.29293-.76772.29301-1.06066.00016-.42194-.42181-.99413-.65877-1.59075-.65877-.59658 0-1.16874.23693-1.59067.65869-.00003.00002.00003-.00003 0 0l-3 3.00002-.00929.0091c-.2149.2076-.38631.4558-.50423.7304-.11792.2745-.17999.5697-.18259.8685-.00259.2987.05434.595.16747.8715.11313.2765.2802.5277.49146.739.21126.2113.46247.3783.73899.4915.27651.1131.57279.17.87155.1674.29875-.0026.594-.0646.86851-.1826.2745-.1179.52278-.2893.73034-.5042l.00937-.0095.8265-.8258c.29303-.2927.7679-.2925 1.06066.0005.29276.293.29255.7679-.00048 1.0607l-.82204.8213c-.34492.3557-.75696.6397-1.21231.8353-.45752.1965-.94959.2999-1.44752.3043-.49792.0043-.99172-.0906-1.45258-.2791-.46086-.1886-.87956-.467-1.23165-.8191-.3521-.3521-.63055-.7708-.819103-1.2317-.188553-.4609-.283435-.9547-.279108-1.4526.004327-.4979.107775-.99.304309-1.4475.195628-.45539.479582-.86748.835432-1.21243l2.99573-2.99573Z"
        fill={color}
      />
    </svg>
  );
};

IconLink.defaultProps = {
  w: 16,
  h: 16,
  color: 'currentColor',
};

export default IconLink;
