export const idVerificationContainerStyles = (isMobile) => ({
  w: isMobile ? '100%' : '65%',
  ps: isMobile ? '0px' : '48px',
  pb: isMobile ? '8px' : '0px',
});

export const idVerificationTitleStyles = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
};

export const idVerificationDescriptionStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  my: '16px',
};

export const statusContainer = {
  alignItems: 'center',
};

export const badgeTextStyles = (textColor) => {
  return {
    textStyle: 'sm',
    ml: '6px !important',
    mr: '24px !important',
    color: textColor,
  };
};
