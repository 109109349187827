import { gql } from '@apollo/client';

export const ACTIVE_LEASES = gql`
  query LeaseDetailActiveLease {
    leaseDetail {
      isEmpty
      summary {
        activeLease
      }
    }
  }
`;
