import React from 'react';
import { Text, HStack } from '@chakra-ui/react';
import customTheme from '@core/theme';

type PlaceholderIconProps = {
  icon: any,
  iconProps: String,
  text: String,
  styles?: Object,
};

const PlaceholderIcon = ({
  icon,
  iconProps = { w: 13, h: 13, color: 'currentColor' },
  text,
  styles,
}: PlaceholderIconProps) => {
  const Icon = icon;
  return (
    <HStack>
      <Icon {...iconProps} />
      <Text color={customTheme.colors.brand.neutral['600']} lineHeight="16px" {...styles?.title}>
        {text}
      </Text>
    </HStack>
  );
};

PlaceholderIcon.defaultProps = {
  styles: {},
};

export default PlaceholderIcon;
