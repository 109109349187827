import React, { useRef, useState } from 'react';
import VouchedDrawer from '@shared/components/VouchedIdentificationDrawer/VouchedDrawer';
import VouchedReturnDrawer from '@shared/components/VouchedIdentificationDrawer/VouchedReturnDrawer';

type VouchedIdentificationDrawerProps = {
  userKyc: Object,
  user: Object,
  refetchUser?: Function,
  vouchedDrawerRef: any,
};

const VouchedIdentificationDrawer = ({
  userKyc,
  user,
  refetchUser = () => {},
  vouchedDrawerRef,
}: VouchedIdentificationDrawerProps) => {
  const [showiFrame, setShowiFrame] = useState(false);

  /**
   *  vouch  drawer
   */

  const handleVouchedDrawerOpen = () => {
    vouchedDrawerRef?.current?.open();
  };
  const handleVouchedDrawerClose = () => {
    vouchedDrawerRef?.current?.close();
  };

  /**
   *  vouch response drawer
   */
  const responseDrawerRef = useRef(null);
  const handleVouchedReturnDrawerOpen = () => {
    responseDrawerRef?.current?.open();
  };

  const handleVouchedReturnDrawerClose = () => {
    responseDrawerRef?.current?.close();
  };

  const handleVerifyProcess = () => {
    setShowiFrame(true);
  };

  const handleCloseAll = () => {
    handleVouchedReturnDrawerClose();
    handleVouchedDrawerClose();
  };

  return (
    <>
      <VouchedReturnDrawer
        drawerRef={responseDrawerRef}
        handleVouchedReturnDrawerOpen={handleVouchedReturnDrawerOpen}
        handleVouchedReturnDrawerClose={handleVouchedReturnDrawerClose}
        handleCloseAll={handleCloseAll}
      />
      <VouchedDrawer
        {...{
          userKyc,
          user,
          refetchUser,
          vouchedDrawerRef,
          handleVouchedDrawerOpen,
          handleVouchedDrawerClose,
          showiFrame,
          setShowiFrame,
          handleVerifyProcess,
          handleVouchedReturnDrawerOpen,
        }}
      />
    </>
  );
};

export default VouchedIdentificationDrawer;
