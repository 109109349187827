export const rentCollectionStyles = (isMax576) => {
  return {
    container: {
      gap: 3,
      maxW: { base: '100%', sm: '656px' },
    },
    innercontainer: { gap: 1 },
    header: { size: 'headline-xl', color: 'brand.neutral.900' },
    description: {
      textStyle: isMax576 ? 'sm' : 'md',
      m: '0 !important',
      color: 'brand.neutral.700',
    },
  };
};
