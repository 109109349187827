import { gql } from '@apollo/client';

export const FRAGMENT_ACCOUNT_LIMITS = gql`
  fragment AccountLimitObj on AccountLimits {
    cardDailyDepositLimit
    cardDailyDepositTotal
    cardDailyPurchaseLimit
    cardDailyPurchaseTotal
    cardDailyTransactionLimit
    cardDailyTransactionTotal
    cardDailyWithdrawalLimit
    cardDailyWithdrawalTotal
    checkDepositDailyLimit
    checkDepositDailyTotal
    checkDepositMonthlyLimit
    checkDepositMonthlyTotal
    checkPaymentDailyLimit
    checkPaymentDailyTotal
    checkPaymentMonthlyLimit
    checkPaymentMonthlyTotal
    dailyCreditLimit
    dailyCreditTotal
    monthlyCreditLimit
    monthlyCreditTotal
    dailyDebitLimit
    dailyDebitTotal
    monthlyDebitLimit
    monthlyDebitTotal
  }
`;

export const FRAGMENT_ACTIVE_BANK_ACCOUNTS = gql`
  fragment ActiveBankAccountsObj on BankAccount {
    id
    transferAccountId
    availableBalance
    isExternal
    accountSubType
    institutionName
    nickName
    accountName
    accountNumber
    routingNumber
    isBankConnected
    connectionState
    provider
    userInstitutionId
    counterPartyConnectionHistoryCode
  }
`;

export const GET_BANK_ACCOUNTS_ACTIVE = gql`
  query BankAccountsActive(
    $isConnectedAccount: Boolean
    $accountStatus: BankAccountStatus
    $isTransferable: Boolean
  ) {
    bankAccounts(
      input: {
        isConnectedAccount: $isConnectedAccount
        accountStatus: $accountStatus
        isTransferable: $isTransferable
      }
    ) {
      ...ActiveBankAccountsObj
      limits {
        ...AccountLimitObj
      }
      subAccounts(
        input: {
          isConnectedAccount: $isConnectedAccount
          accountStatus: $accountStatus
          isTransferable: $isTransferable
        }
      ) {
        ...ActiveBankAccountsObj
        limits {
          ...AccountLimitObj
        }
      }
    }
  }
  ${FRAGMENT_ACTIVE_BANK_ACCOUNTS}
  ${FRAGMENT_ACCOUNT_LIMITS}
`;
