// @flow
import React from 'react';
import { HStack } from '@chakra-ui/react';
import ReferralButton from './ReferralButton';
import Profiles from './Profiles';

const RightHeaderNav = () => {
  return (
    <HStack gap={2}>
      <ReferralButton />
      <Profiles />
    </HStack>
  );
};

export default RightHeaderNav;
