import React from 'react';
import { Center, HStack, Text } from '@chakra-ui/react';
import {
  IconCalendar,
  IconCheckCircleOutline,
  IconCircleDottedArrow,
  IconCircleOutlineNoPadding,
  IconDotsCircleHorizontal,
  IconExclamationCircle,
  IconGrid,
  IconNoteList,
  IconX,
} from '@icons';

const getPaymentFilterItem = (key) => {
  switch (key) {
    case 'PAYMENT_COMPLETE':
      return {
        icon: <IconCheckCircleOutline w={13.33} h={13.33} color="#6C7884" />,
        text: 'Completed Payments',
      };

    case 'PAYMENT_PROCESSING':
      return {
        icon: <IconCircleDottedArrow w={13.33} h={13.33} color="#6C7884" />,
        text: 'Processing Payments',
      };

    case 'PAYMENT_OUTSTANDING':
      return {
        icon: <IconCircleOutlineNoPadding w={13.33} h={13.33} color="#6C7884" />,
        text: 'Unpaid Invoices',
      };

    case 'PAYMENT_FAILED':
      return {
        icon: <IconX w={9.33} h={9.33} color="#6C7884" />,
        text: 'Failed Invoices',
      };

    case 'PAYMENT_OVERDUE':
      return {
        icon: <IconExclamationCircle w={13.33} h={13.33} color="#6C7884" />,
        text: 'Overdue Invoices',
      };

    case 'ALL':
      return {
        icon: <IconGrid w={12} h={12} color="#6C7884" />,
        text: 'All Invoices',
      };

    default:
      return null;
  }
};

const getDateTypeFilterItem = (key) => {
  switch (key) {
    case 'DUE_DATE':
      return {
        icon: <IconNoteList />,
        text: 'View by Due Date',
      };

    case 'PAYMENT_DATE':
      return {
        icon: <IconNoteList />,
        text: 'View by Payment Date',
      };

    default:
      return null;
  }
};

const getDateFilterItem = (key) => {
  const defaultDateFilterItem = {
    icon: <IconCalendar color="#6C7884" />,
    text: '',
  };

  switch (key) {
    case 'THIS_MONTH':
      return {
        ...defaultDateFilterItem,
        text: 'This Month',
      };

    case 'LAST_MONTH':
      return {
        ...defaultDateFilterItem,
        text: 'Last Month',
      };

    case 'NEXT_MONTH':
      return {
        ...defaultDateFilterItem,
        text: 'Next Month',
      };

    case 'CUSTOM':
      return {
        icon: <IconDotsCircleHorizontal w={13.33} h={13.33} color="#6C7884" />,
        text: 'Custom',
      };

    case 'ALL':
      return {
        ...defaultDateFilterItem,
        text: 'All Time',
      };

    default:
      return null;
  }
};

export const getFilterItem = (type, filter, filterStyles) => {
  let filterData;
  switch (type) {
    case 'payment':
      filterData = getPaymentFilterItem(filter);
      break;

    case 'dateType':
      filterData = getDateTypeFilterItem(filter);
      break;

    case 'date':
      filterData = getDateFilterItem(filter);
      break;

    default:
      break;
  }

  return (
    <HStack
      m="0 !important"
      key={type}
      {...{ ...filterStyles.datetypefilter({ filterType: type }) }}
    >
      <Center {...filterStyles.icon}>{filterData?.icon}</Center>
      <Text {...filterStyles.text({ filterType: type })}>{filterData?.text}</Text>
    </HStack>
  );
};
