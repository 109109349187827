import React, { useRef } from 'react';
import { Text } from '@chakra-ui/react';
import { BaselaneAlert } from '@shared/components';
import EmptyBodyComponent from './components/EmptyBodyComponent';
import BodyComponent from './components/BodyComponent';
import getBreakPoints from '../../../../utils/getBreakPoints';

type MissingUnitDataPopupProps = {
  isMissingUnitDataListPopupOpen: Function,
  handleMissingUnitDataListClose: Function,
  banksThatNeedDataCollection: Array<Object>,
  isLoadingDataCollectionList: Boolean,
  onRefreshStatus: Function,
};

const MissingUnitDataPopup = ({
  isMissingUnitDataListPopupOpen,
  handleMissingUnitDataListClose,
  banksThatNeedDataCollection,
  isLoadingDataCollectionList,
  onRefreshStatus,
}: MissingUnitDataPopupProps) => {
  const cancelRef = useRef();
  const { isMinXL } = getBreakPoints();

  return (
    <BaselaneAlert
      {...{
        isOpen: isMissingUnitDataListPopupOpen,
        onClose: handleMissingUnitDataListClose,
        leastDestructiveRef: cancelRef,
        isCentered: true,
        showCloseButton: true,
        size: isMinXL ? 'sm' : 'lg',

        header: isLoadingDataCollectionList ? null : (
          <Text {...{ textStyle: 'headline-2xl' }}>
            Information required for your Baselane Banking account(s)
          </Text>
        ),
        body:
          banksThatNeedDataCollection?.length === 0 ? (
            <EmptyBodyComponent
              {...{
                handleMissingUnitDataListClose,
                isLoadingDataCollectionList,
              }}
            />
          ) : (
            <BodyComponent
              {...{ banksThatNeedDataCollection, onRefreshStatus, isLoadingDataCollectionList }}
            />
          ),
        closeButtonStyles: {
          top: isMinXL ? '16px!important' : '32px!important',
          right: isMinXL ? '16px!important' : '32px !important',
        },
        closeButtonIconProps: { w: 15, h: 15 },
        headerStyles: { m: isMinXL ? '24px 0 0 16px' : '24px 0 0 32px' },
        bodyStyles: {
          p: isMinXL ? '0 16px 16px 16px!important' : '0 32px 32px 32px!important',
          m: '16px 0 0 0 ',
          overflow: 'auto',
        },
        containerStyles: {
          m: '0 !important',
          p: isMinXL ? '16px 0  0 0 !important' : '32px 0 0 0!important',
          borderRadius: '12px',
          maxHeight: 'calc(100% - 48px)',
          overflow: 'hidden',
        },
      }}
    />
  );
};

export default MissingUnitDataPopup;
