import React from 'react';
import { FormControl, Flex, Box } from '@chakra-ui/react';
import { Field } from 'formik';
import { BaselaneFormErrorMessage, BaselaneRadio, BaselaneRadioGroup } from '@shared/components';
import EndDateDatepicker from './EndDateDatepicker';
import NumberOfPaymentsField from './NumberOfPaymentsField';

const EndRecurringPayment = () => {
  return (
    <Field name="recurringPayment.endBy">
      {({ field, form }) => (
        <FormControl
          isRequired
          isInvalid={form.errors?.recurringPayment?.endBy && form.touched?.recurringPayment?.endBy}
        >
          <Box width="fit-content">
            <BaselaneRadioGroup
              {...field}
              onChange={(selection) => form.setFieldValue('recurringPayment.endBy', selection)}
            >
              <BaselaneRadio value="manual" label="Continue until I cancel" />
              <Flex justifyContent="space-between">
                <BaselaneRadio value="date" label="End on" />
                <EndDateDatepicker />
              </Flex>
              <Flex justifyContent="space-between">
                <BaselaneRadio value="numberOfPayments" label="End after" />
                <NumberOfPaymentsField />
              </Flex>
            </BaselaneRadioGroup>
          </Box>
          <BaselaneFormErrorMessage
            isInvalid={
              form.errors?.recurringPayment?.endBy && form.touched?.recurringPayment?.endBy
            }
          >
            {form.errors?.recurringPayment?.endBy}
          </BaselaneFormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default EndRecurringPayment;
