// @flow
import React, { useCallback, useEffect, useState, memo } from 'react';
import { Box, HStack, Progress, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import {
  BaselaneButtonIcon,
  BaselaneTitle,
  CurrencyText,
  NoDataPlaceholder,
} from '@shared/components';
import { truncatedText } from '@shared/styles/text.style';
import {
  getCalculatedValue,
  getProgressBarColorScheme,
  getTags,
  getGlobalTagFilter,
  getCategorySubOptionTags,
  getOnlySubCategoryTags,
} from './helpers/cashflow.helpers';
import type { CashFlowByCategory } from './types';
import { IconChevronLeft } from '../Icons';
import {
  titleStyles,
  containerStyles,
  t1ContainerStyles,
  t1BarStyles,
  t2ContainerStyles,
  t2HeaderStyles,
  t2ItemContainerStyles,
  t2ItemStyles,
  t2BarStyles,
  t2AmountStyles,
  categoryNameStyles,
  categoryAmountStyles,
} from './styles/cashFlowByType.styles';
import './styles/cashFlowByType.styles.scss';

type CashFlowByTypeProps = {
  handleSubFiltersUpdate: Function,
  byCategory: CashFlowByCategory,
  filters: Object,
  subOptionsCategories: Array<Object>,
  categoryWithSubOptions: Array<Object>,
  mappedCategories: Object,
  categories: Array<Object>,
  onAnimationStart?: Function,
  onAnimationComplete?: Function,
};

function CashFlowByType({
  handleSubFiltersUpdate,
  byCategory,
  filters,
  subOptionsCategories,
  categoryWithSubOptions,
  mappedCategories,
  categories,
  onAnimationStart = () => {},
  onAnimationComplete = () => {},
}: CashFlowByTypeProps): any {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [filteredSubCategories, setFilteredSubCategories] = useState(null);
  const [activeItem, setActiveItem] = useState({ category: null, subCategory: null });

  const hasData = !byCategory.every((item) => item.amount === 0) || filteredSubCategories;

  const handleBackClick = () => {
    onAnimationStart();
    onClose();
    const tagId = filters.filter.uncategorized
      ? [...filters.filter.tagId, '0']
      : filters.filter.tagId;
    handleSubFiltersUpdate({
      tagId,
      categorySelected: null,
      subCategorySelected: null,
    });
  };

  const escFunction = useCallback(
    (event) => {
      // Close the T2 on ESC key down
      if (event.keyCode === 27 && isOpen) {
        handleBackClick();
      }
    },
    [isOpen]
  );

  useEffect(() => {
    window.addEventListener('keydown', escFunction);

    return () => {
      window.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  useEffect(() => {
    if (isOpen) onClose();
    const { tagId, uncategorized } = filters.filter;
    const filteredSubCat =
      tagId === null && !uncategorized
        ? subOptionsCategories
        : subOptionsCategories.filter(
            (subcat) =>
              (tagId !== null && tagId?.find((id) => id === subcat.id)) ||
              (uncategorized && subcat.id === '0')
          );

    setFilteredSubCategories(filteredSubCat);
    setActiveItem({ category: null, subCategory: null });
  }, [filters]);

  const handleCategoryClick = (item) => {
    onAnimationStart();
    onOpen();
    handleSubFiltersUpdate({
      tagId:
        item.subCategories.length > 0 && item.name !== 'Uncategorized'
          ? [
              ...getTags(mappedCategories, item.subCategories),
              ...getCategorySubOptionTags(categoryWithSubOptions, item.subCategories),
            ]
          : getGlobalTagFilter(filteredSubCategories, item),
      categorySelected: item.name,
    });
    setActiveItem({ ...activeItem, category: item.name });
  };

  const handleSubCategoryClick = (activeCat, subcatSelected) => {
    const isSubCatVisible = activeItem.subCategory === subcatSelected;
    handleSubFiltersUpdate({
      tagId:
        !isSubCatVisible && subcatSelected !== 'Uncategorized'
          ? [
              ...getOnlySubCategoryTags(
                categoryWithSubOptions,
                activeCat?.subCategories,
                subcatSelected,
                subOptionsCategories
              ),
            ]
          : [
              ...getTags(mappedCategories, activeCat.subCategories),
              ...getCategorySubOptionTags(categoryWithSubOptions, activeCat.subCategories),
            ],
      subCategorySelected: isSubCatVisible ? null : subcatSelected,
    });
    setActiveItem({ ...activeItem, subCategory: isSubCatVisible ? null : subcatSelected });
  };

  const handleTransitionEnd = (e) => {
    if (e.propertyName === 'left') {
      onAnimationComplete();

      if (!isOpen) {
        setActiveItem({ category: null, subCategory: null });
      }
    }
  };

  const renderSubCategoriesView = () => {
    const activeCat = categories?.find((cat) => cat.name === activeItem.category);

    return (
      <Box {...t2ContainerStyles(isOpen)} onTransitionEnd={(e) => handleTransitionEnd(e)}>
        {/* Header */}
        <HStack {...t2HeaderStyles}>
          <BaselaneButtonIcon
            variant="transparent"
            palette="neutral"
            onClick={() => handleBackClick()}
            icon={<IconChevronLeft />}
          />

          <Text {...categoryNameStyles} {...truncatedText}>
            {activeCat?.name}
          </Text>
          <Spacer />
          <CurrencyText {...categoryAmountStyles} isRounded amount={activeCat?.amount} />
        </HStack>

        {/* Sub-Categories */}
        <Stack {...t2ItemContainerStyles}>
          {activeCat?.subCategories?.map((subcat) => (
            <Stack
              key={subcat.name}
              {...t2ItemStyles(activeItem.subCategory === subcat.name)}
              onClick={() => handleSubCategoryClick(activeCat, subcat.name)}
            >
              <HStack>
                <Text {...categoryNameStyles} {...truncatedText} key={subcat.name}>
                  {subcat.name}
                </Text>
                <Spacer />
                <CurrencyText {...t2AmountStyles} isRounded amount={subcat.amount} />
              </HStack>

              <Progress
                className="progress-bar-rounded"
                value={getCalculatedValue(subcat)}
                colorScheme={getProgressBarColorScheme(subcat)}
                {...t2BarStyles}
              />
            </Stack>
          ))}
        </Stack>
      </Box>
    );
  };

  return (
    <>
      <BaselaneTitle title={<Box>Breakdown by Category</Box>} {...{ titleStyles }} />
      {hasData ? (
        <Box {...containerStyles}>
          {/* T1 */}
          {categories.map((item) => {
            const categoriesView = (
              <Stack
                key={item.name}
                {...t1ContainerStyles}
                onClick={() => handleCategoryClick(item)}
              >
                <HStack>
                  <Text {...categoryNameStyles} {...truncatedText}>
                    {item.name}
                  </Text>
                  <Spacer />
                  <CurrencyText {...categoryAmountStyles} isRounded amount={item.amount} />
                </HStack>

                <Progress
                  className="progress-bar-rounded"
                  value={item.value}
                  colorScheme={getProgressBarColorScheme(item)}
                  {...t1BarStyles}
                />
              </Stack>
            );

            const showCat =
              filteredSubCategories === null ||
              (filteredSubCategories !== null &&
                filteredSubCategories?.find((cat) => cat.type === item.name));
            return showCat && categoriesView;
          })}

          {/* T2 */}
          {renderSubCategoriesView()}
        </Box>
      ) : (
        <NoDataPlaceholder />
      )}
    </>
  );
}

CashFlowByType.defaultProps = {
  onAnimationStart: () => {},
  onAnimationComplete: () => {},
};

const areEqual = (prevProp, nextProp) => {
  return isEqual(
    {
      byCategory: prevProp.byCategory,
      filters: prevProp.filters,
    },
    {
      byCategory: nextProp.byCategory,
      filters: nextProp.filters,
    }
  );
};

const CashFlowByTypeMemo = memo(CashFlowByType, areEqual);

export default CashFlowByTypeMemo;
