import React from 'react';

function IconExclamationCircleThin({ color, w, h }: { color?: string, w?: number, h?: number }) {
  return (
    <svg height={h} width={w} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.02995 3.08594c.23012 0 .41666.18655.41666.41666v4.33334c0 .23012-.18654.41666-.41666.41666-.23012 0-.41667-.18654-.41667-.41666V3.5026c0-.23011.18655-.41666.41667-.41666Zm-.41667 6.58333c0-.23012.18655-.41667.41667-.41667h.00666c.23012 0 .41667.18655.41667.41667s-.18655.41663-.41667.41663h-.00666c-.23012 0-.41667-.18651-.41667-.41663Zm.41667 3.16663c3.22165 0 5.83335-2.6116 5.83335-5.8333 0-3.22166-2.6117-5.83333-5.83335-5.83333-3.22166 0-5.83334 2.61167-5.83334 5.83333 0 3.2217 2.61168 5.8333 5.83334 5.8333Zm6.66665-5.8333c0 3.6819-2.9848 6.6667-6.66665 6.6667-3.6819 0-6.666669-2.9848-6.666669-6.6667 0-3.68189 2.984769-6.666662 6.666669-6.666662 3.68185 0 6.66665 2.984772 6.66665 6.666662Z"
        fill={color}
      />
    </svg>
  );
}

IconExclamationCircleThin.defaultProps = {
  color: 'currentColor',
  w: 14,
  h: 14,
};

export default IconExclamationCircleThin;
