/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Text, Spacer, Image } from '@chakra-ui/react';
import useCategories from './useCategories';
import subcategoryImageUri from './subcategory.svg';

const CategoryCardItem = ({ tagId }) => {
  const { categoriesById } = useCategories();

  if (!tagId) {
    return null;
  }

  const selectedCategory = categoriesById[tagId];
  const hasParent = selectedCategory?.parentId;
  const parentCategory = hasParent ? categoriesById[selectedCategory.parentId] : selectedCategory;
  const subCategory = hasParent ? selectedCategory : null;

  return (
    <Box display="flex" mt={1}>
      <Text textStyle="xs">Category</Text>
      <Spacer />
      <Box display="flex" flexDirection="column" flex={2} alignItems="flex-end">
        <Text textStyle="sm">{parentCategory.name}</Text>
        {subCategory ? (
          <Box display="flex" justifyContent="flex-end">
            <Image src={subcategoryImageUri} alt="An arrow that points to subcategory" />
            <Text textStyle="xs">{subCategory.name}</Text>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default CategoryCardItem;
