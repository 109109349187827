/**
 * Used to set the proper styles depending on is user isActive or always showing text.
 * An atom will have a minWidth set, but it can stretch to 100% of the parent container.
 *
 * @param index: to grab config of particular atom
 * @param atomBgColor
 * @param config
 * @returns {{borderLeftRadius: string, flexGrow: {'2lg': string, base: (string)}, bgColor, borderRightRadius: string, flexShrink: {'2lg': string, base: (string)}, _hover: {flexGrow: {'2lg': string, base: string}, flexShrink: {'2lg': string, base: string}, transition: {lg: string, base: string}}, width: string, minWidth: {xl: string, '2lg': string, base: string}, position: string, height: string, maxWidth: {'2lg': string, base: string}}}
 */
export const atomContainerStyles = (index, atomBgColor, config) => {
  const { isActive, alwaysShowText = false } = config[index];

  return {
    position: 'relative',
    height: '16px',
    minWidth: { base: '20px', xl: '55px', '2lg': '48px' },
    width: '100%',
    maxWidth: {
      base: `48px`,
      '2lg': 'unset',
    },
    ...(index === 0 ? { borderLeftRadius: '24px' } : {}),
    ...(index === config.length - 1 ? { borderRightRadius: '24px' } : {}),
    bgColor: atomBgColor,
    flexGrow: { base: isActive || alwaysShowText ? '1' : '0', '2lg': '1' },
    flexShrink: { base: isActive || alwaysShowText ? '0' : '3', '2lg': '1' },
    ...(!isActive && !alwaysShowText
      ? {
          _hover: {
            transition: { base: 'none', lg: 'all 2s ease-out' },
            flexGrow: { base: '1', '2lg': '1' },
            flexShrink: { base: '0', '2lg': '1' },
          },
        }
      : {}),
  };
};

export const atomBaseStyles = (index, config) => ({
  cursor: 'pointer',
  position: 'relative',
  color: 'brand.neutral.white',
  height: '100%',
  width: '100%',
  textAlign: 'center',
  overflow: 'hidden',
  textStyle: 'headline-xs',
  ...(index === 0 ? { borderLeftRadius: '24px' } : {}),
  ...(index === config.length - 1 ? { borderRightRadius: '24px' } : {}),
});

export const defaultAtomNotCompleteStyles = (alwaysShowText) => ({
  bgColor: '#F0F4FD',
  color: alwaysShowText ? 'brand.blue.800A' : 'transparent',
  fontSize: alwaysShowText ? 'xs' : '1px',
  _hover: {
    fontSize: 'xs',
    bgColor: 'brand.blue.200',
    color: 'brand.blue.800A',
    borderRightRadius: '24px',
  },
});

export const defaultAtomCompletedStyles = (alwaysShowText) => ({
  bgColor: 'brand.blue.800A',
  color: alwaysShowText ? 'brand.neutral.white' : 'transparent',
  fontSize: alwaysShowText ? 'xs' : '1px',
  _hover: {
    fontSize: 'xs',
    bgColor: '#568AF6',
    color: 'brand.neutral.white',
  },
});

const defaultAtomActiveStyles = {
  ...defaultAtomCompletedStyles(),
  fontSize: 'xs',
  borderRightRadius: '24px',
  color: 'brand.neutral.white',
};

export const getDefaultAtomStateStyles = ({
  completedStyles = {},
  activeStyles = {},
  notCompletedStyles = {},
  alwaysShowText = false,
} = {}) => {
  return {
    completed: { ...defaultAtomCompletedStyles(alwaysShowText), ...completedStyles },
    active: { ...defaultAtomActiveStyles, ...activeStyles },
    notCompleted: { ...defaultAtomNotCompleteStyles(alwaysShowText), ...notCompletedStyles },
  };
};
