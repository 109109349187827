// @flow
import React, { useState, useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import MaskedInput from 'react-text-mask';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Box, HStack, Text, VStack, FormControl, FormLabel, Input } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import customTheme from '@core/theme';
import {
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  BaselaneDrawer,
  BaselaneDropdown,
  BaselaneErrorCard,
  BaselaneTooltip,
  useTwoFactor,
  TwoFactorVerificationPopUp,
} from '@shared/components';
import { IconCheck, IconExclamationCircle, IconInfo, IconBank } from '@icons';
import SlLoader from '@core/components/Loader';
import { accountItemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { stripDollars } from '@core/components/CashFlowPage/helpers/cashflow-statement.helpers';
import PlaceholderIcon from '@core/components/Shared/components/BaselaneDropdown/components/PlaceholderIcon';

import {
  setTokenData,
  getIsTokenExpired,
  getSensitiveToken,
  getUnitOTPVerification,
  handleOTPPopupClose,
  handleOTPPopupOpen,
  handleNBOnVerifyClick,
} from '@core/components/NativeBankingPage/helpers/otp.helpers';

import { formLabelStyles, formInputStyles } from '@shared/styles/input.style';
import { currencyMask } from '@core/utils/masks';
import stripCurrency from '@core/utils/stripCurrency';
import onDrawerClose from '@core/utils/onDrawerClose';

import BanksContext from '@contexts/BanksContext';
import BankEntityContext from '@contexts/BankEntityContext';
import TransactionContext from '@contexts/TransactionContext';
import UserContext from '@contexts/UserContext';

import { CREATE_VIRTUAL_CARD } from '@core/components/NativeBankingPage/queries';
import { getPropertyData } from '@core/components/Shared/helpers/propertiesFilter.helpers';
import formatCurrency from '@core/utils/formatCurrency';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';

import { headingStyles, cancelButtonStyles } from './styles/addVirtualCard.styles';

import { menuOptionsConverter } from '../../../../helpers/accounts.shared.helpers';
import PropertyAndCategoryTaggingForm from '../../../PropertyAndCategoryTaggingForm';
import PeriodDropdown, { defaultPeriodOptions } from './components/PeriodDropdown';
import {
  MAX_CARD_LIMIT_REACHED_FOR_LLC_MESSAGE,
  MAX_CARD_LIMIT_REACHED_FOR_SOLE_PROP_MESSAGE,
  deleteNulls,
} from './helpers/addCard.helpers';

type FormMessageProps = {
  field: String,
  normalMessage: String,
  errorMessages: Object,
};

// Displays a regular form description or error message depending on state
const FormMessage = ({ field, normalMessage, errorMessages }: FormMessageProps) => {
  const hasError = errorMessages && errorMessages[field];
  return (
    <Text
      {...{
        ...formLabelStyles.xs,
        color: hasError ? 'red.800AA' : 'brand.neutral.600',
        display: 'flex',
        pt: '8px',
      }}
    >
      {hasError && (
        <Box as="span" mr="8px">
          <IconExclamationCircle />
        </Box>
      )}
      {hasError ? errorMessages[field] : normalMessage}
    </Text>
  );
};

type AddVirtualCardProps = {
  from?: String,
};

const AddVirtualCard = ({ from }: AddVirtualCardProps) => {
  const { refetch } = useOutletContext() ?? {};
  const [createVirtualCard, { loading: createVirtualCardLoading }] = useMutation(
    CREATE_VIRTUAL_CARD
  );

  const {
    loading: bankLoading,
    error: bankError,
    banks,
    baselaneConnectedAccounts,
    refetchBankSummary,
  } = useContext(BanksContext);

  const { allBankAccountIdsFilter: entityBankIds } = useContext(BankEntityContext);

  const {
    setShowMobileDropdownPopup,
    showMobileDropdownPopup,
    setShowMobileDropdownPopupAnimation,
  } = useContext(UserContext);

  const {
    loading: tagsLoading,
    error: transactionError,
    propertiesData,
    categoryMap,
    categoryIdsMap,
    categoryWithSubOptions,
  } = useContext(TransactionContext);

  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);
  const propertyOptions = getPropertyData(propertiesData);

  const navigate = useNavigate();

  // two factor verification states
  const [bankId, setBankId] = useState(null);
  const [phone, setPhone] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedAccountDailyPurchaseLimit, setSelectedAccountDailyPurchaseLimit] = useState(null);
  const [cardNickname, setCardNickname] = useState('');
  const [propertyTag, setPropertyTag] = useState('');
  const [categoryTag, setCategoryTag] = useState('');
  const [spendingAmount, setSpendingAmount] = useState('');
  const [spendingAmountPeriod, setSpendingAmountPeriod] = useState(defaultPeriodOptions[0]);

  const [errors, setErrors] = useState(null);
  const [dirty, setDirty] = useState({});
  const [createCardError, setCreateCardError] = useState(null);
  const [hasMaxCards, setHasMaxCards] = useState(false);

  const initializeState = () => {
    setSelectedAccount('');
    setCardNickname('');
    setPropertyTag('');
    setCategoryTag('');
    setSpendingAmount('');
    setSpendingAmountPeriod(defaultPeriodOptions[0]);
    setErrors(null);
    setDirty({});
  };

  const accounts = [];
  baselaneConnectedAccounts.forEach((item) => {
    const { id, name, plaidInstitutionName } = item;
    const institution = { id, name, plaidInstitutionName };

    item.bankAccounts.forEach((a) => {
      if (!entityBankIds || entityBankIds?.includes(Number(a.id))) {
        accounts.push({
          ...a,
          bankAccountId: a.id,
          name: a.nickName,
          institution,
        });
      }
      a.subAccounts?.forEach((sa) => {
        if (!entityBankIds || entityBankIds?.includes(Number(sa.id))) {
          accounts.push({
            ...sa,
            bankAccountId: sa.id,
            name: sa.nickName,
            institution,
          });
        }
      });
    });
  });

  const menuOptionsAccount = menuOptionsConverter(accounts, false);

  const renderCategoryDropdownItem = (item) => item.name;

  const onCancel = () => {
    initializeState();
    onDrawerClose(navigate, from);
  };

  const getSpendingLimit = (accountId) => {
    return accounts?.find((bankAccount) => {
      return bankAccount?.id?.toString() === accountId?.toString();
    })?.limits?.cardDailyPurchaseLimit;
  };

  const generateErrorMessage = (field) => {
    let message = null;
    const spendingLimit = getSpendingLimit(selectedAccount?.bankAccountId);
    switch (field) {
      case 'selectedAccount':
        if (!selectedAccount?.id) message = 'Please select a banking account';
        break;
      case 'cardNickname':
        if (!cardNickname || cardNickname?.length < 1) message = 'Please enter a card nickname';
        break;
      case 'spendingAmount':
        if (!spendingAmount || stripCurrency(spendingAmount) < 1) {
          message = 'Please set spending limit and frequency';
        } else if (
          selectedAccount &&
          spendingAmountPeriod?.value === 'DAILY' &&
          stripCurrency(spendingAmount) > spendingLimit
        ) {
          message = `Your daily limit cannot surpass account level purchase limit of
            ${formatCurrency(spendingLimit).inDollars}`;
        }
        break;
      default:
      // do nothing
    }

    return { [field]: message };
  };

  const handleAccountSubmit = (selectedAccountId) => {
    const selectedItem = menuOptionsAccount.reduce((acc, group) => {
      const getSelectedItem = group.items.find((groupItem) => groupItem.id === selectedAccountId);
      if (getSelectedItem) {
        return getSelectedItem;
      }
      return acc;
    }, []);
    setSelectedAccount(selectedItem);
    setSelectedAccountDailyPurchaseLimit(
      formatCurrency(getSpendingLimit(selectedItem.bankAccountId)).inDollars
    );
    const selectedBank = banks.find((bank) => bank?.id === selectedItem.groupId);
    setPhone(selectedBank?.unitAccount.phoneNumber);
    setBankId(selectedBank?.id);

    setDirty({ ...dirty, selectedAccount: true });
  };

  const handleNicknameChange = (e) => {
    setCardNickname(e?.target?.value);
    setDirty({ ...dirty, cardNickname: true });
  };

  const handleSpendingAmountChange = (e) => {
    setSpendingAmount(e?.target?.value);
    setDirty({ ...dirty, spendingAmount: true });
  };

  const handleSpendingAmountPeriodChange = (option) => {
    if (option.value !== spendingAmountPeriod.value) {
      setSpendingAmountPeriod(option);
      setDirty({ ...dirty, spendingAmount: true });
    }
  };

  const handlePropertySubmit = (e) => {
    setPropertyTag(e);
  };

  const handleCategorySubmit = (e, dropDown) => {
    const [parentId, subId] = e.split('-');
    setCategoryTag(subId ?? parentId);
    setTimeout(() => {
      dropDown('close');
    }, 10);
  };

  const isFormDataIncomplete =
    !selectedAccount || !spendingAmount || !cardNickname || !spendingAmountPeriod;

  const hasErrors = Object.keys(errors || {})?.length > 0;

  const createCard = () => {
    // guard for form errors
    if (errors && errors.length > 0) return;

    // assemble form data
    const [propertyId, unitId] = propertyTag.split('-');
    const tags = {
      ...(propertyId && { propertyId }),
      ...(unitId && { unitId }),
      ...(categoryTag && { tagId: categoryTag }),
    };

    const { bankAccountId } = selectedAccount;

    const variables = {
      limits: {
        amount: parseInt(stripDollars(spendingAmount).split(',').join(''), 10),
        frequency: spendingAmountPeriod?.value,
      },
      nickname: cardNickname,
      tags,
      bankAccountId,
    };

    createVirtualCard({
      variables,
    })
      .then((res) => {
        if (res?.errors?.length > 0) {
          if (
            res?.errors?.find(
              (error) =>
                error?.message?.includes(MAX_CARD_LIMIT_REACHED_FOR_LLC_MESSAGE) ||
                error?.message?.includes(MAX_CARD_LIMIT_REACHED_FOR_SOLE_PROP_MESSAGE)
            )
          ) {
            setHasMaxCards(true);
          } else {
            setCreateCardError(true);
          }
        } else {
          setCreateCardError(null);
          refetchBankSummary();
          if (refetch) refetch();
          onCancel();
        }
      })
      .catch((err) => {
        if (
          (err?.length || []).find(
            (error) =>
              error?.message?.includes(MAX_CARD_LIMIT_REACHED_FOR_LLC_MESSAGE) ||
              error?.message?.includes(MAX_CARD_LIMIT_REACHED_FOR_SOLE_PROP_MESSAGE)
          )
        ) {
          setHasMaxCards(true);
        } else {
          setCreateCardError(true);
        }
      });
  };

  const taggingValues = () => {
    const values = {};
    if (propertyTag) {
      values.propertyUnitId = propertyTag || '';
    }
    if (categoryTag) {
      values.categoryId = categoryTag || '';
    }
    return values;
  };

  const onCreateFailedAlertClose = () => {
    setCreateCardError(null);
    onCancel();
  };

  const onCreateFailedAlertTryAgain = () => {
    setCreateCardError(null);
  };

  const onMaxCardAlertClose = () => {
    setHasMaxCards(false);
    onCancel();
  };

  const { states, stateFunctions } = useTwoFactor();
  const { setOTPErrorCode } = stateFunctions;

  // OTP: Check if token of the bank expired
  const isTokenExpired = getIsTokenExpired(bankId);
  const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

  const onUserSensitiveTokenComplete = (unitAPISensitiveToken) => {
    handleOTPPopupClose(states, stateFunctions);

    createCard();
    setTokenData(unitAPISensitiveToken, bankId);
  };

  const getSensitiveTokenProps = {
    onUserSensitiveTokenComplete,
    getOTP,
    bankId,
    states,
    stateFunctions,
  };

  const [getUserSensitiveTokenData] = getSensitiveToken(getSensitiveTokenProps);

  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP,
    bankId,
    phoneNumber: phone,
    handleVerifyOnClick: (otpCode) =>
      handleNBOnVerifyClick(otpCode, bankId, getUserSensitiveTokenData),
  };

  // detect and compile any error messages
  useEffect(() => {
    const newErrors = Object.keys(dirty).reduce((errorMessages, key) => {
      return { ...errorMessages, ...generateErrorMessage(key) };
    }, {});
    setErrors(deleteNulls(newErrors));
  }, [dirty]);

  if (bankError || transactionError) {
    return <BaselaneErrorCard />;
  }

  const { isMax767 } = getBreakPoints();

  const drawerFooter = (
    <>
      <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />
      <BaselaneButton size="md" variant="outline" palette="neutral" onClick={onCancel}>
        Cancel
      </BaselaneButton>
      <BaselaneButton
        size="md"
        variant="filled"
        palette="primary"
        ml="1.5"
        isFullWidth
        isDisabled={isFormDataIncomplete || hasErrors}
        onClick={() => {
          if (isTokenExpired) {
            handleOTPPopupOpen(getOTP, bankId, stateFunctions);
          } else {
            createCard();
          }
        }}
        isLoading={createVirtualCardLoading}
        leftIcon={isMobile ? <IconCheck /> : null}
      >
        Create Card
      </BaselaneButton>
    </>
  );

  return (
    <>
      <BaselaneDrawer
        title="Create Virtual Card"
        closeOnOverlayClick={false}
        closeEvent={onCancel}
        onOverlayClick={onCancel}
        isOpen
        size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
        newDesignDrawer
        hideOverlay
        footer={drawerFooter}
        mobileHeaderChildren={
          <BaselaneButton
            {...{
              variant: 'transparent',
              palette: 'neutral',
              onClick: onCancel,
              styles: { color: 'brand.blue.800A' },
            }}
          >
            Cancel
          </BaselaneButton>
        }
      >
        {bankLoading || tagsLoading ? (
          <SlLoader />
        ) : (
          <VStack spacing="24px" id="add-virtual-card-drawer">
            <Text {...headingStyles}>Let’s create a virtual card</Text>
            {/* Account */}
            <FormControl>
              <FormLabel {...formLabelStyles.xs}>
                Which banking account do you want to link to this card?
              </FormLabel>
              <BaselaneDropdown
                {...{
                  isDisabled: menuOptionsAccount.length === 0,
                  hasError: errors?.selectedAccount,
                  classNames: [
                    'input-form-xl',
                    'fontSize-sm',
                    'auto-width-dropdown',
                    'disable-max-width',
                    'auto-width',
                  ],
                  data: menuOptionsAccount,
                  title: 'Select Bank Account',
                  placeholder: <PlaceholderIcon icon={IconBank} text="Select Banking Account" />,
                  showValueByFields: ['name'],
                  itemRenderer: accountItemRenderer,
                  showSelectedRightElement: true,
                  selectedRightElementValue: selectedAccount?.value ?? '',
                  handleSubmit: handleAccountSubmit,
                  selectedItem: selectedAccount,
                }}
              />
              <FormMessage
                field="selectedAccount"
                normalMessage="Transactions on this card will be debited from the linked banking account."
                errorMessages={errors}
              />
            </FormControl>
            {/* Card Nickname */}
            <FormControl>
              <FormLabel {...formLabelStyles.xs}>Card Nickname</FormLabel>
              <Input
                value={cardNickname}
                onChange={handleNicknameChange}
                onBlur={handleNicknameChange}
                isInvalid={errors?.cardNickname}
                placeholder="e.g. Utilities or Rent Collection"
                {...formInputStyles}
              />
              <FormMessage field="cardNickname" normalMessage="" errorMessages={errors} />
            </FormControl>
            {/* Spending Limit */}
            <FormControl>
              <FormLabel {...{ ...formLabelStyles.xs, w: '100%', flex: '3', display: 'flex' }}>
                Spending Limit
                <BaselaneTooltip
                  label="Set a monthly or daily spending limit for the card. You can update the limit at any time."
                  placement="auto"
                  innerContainerStyles={{
                    ml: '9px !important',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconInfo
                    width="16"
                    height="16"
                    color={customTheme.colors.brand.neutral['600']}
                  />
                </BaselaneTooltip>
              </FormLabel>
              <HStack>
                <Input
                  as={MaskedInput}
                  mask={currencyMask({ decimalSymbol: '', decimalLimit: 0 })}
                  value={spendingAmount}
                  onChange={handleSpendingAmountChange}
                  onBlur={handleSpendingAmountChange}
                  isInvalid={errors?.spendingAmount}
                  placeholder="Enter Amount"
                  {...{
                    ...formInputStyles,
                    flex: '3',
                  }}
                />
                <Text minH="100%" display="inline-block" verticalAlign="baseline" m="8px">
                  /
                </Text>
                <PeriodDropdown
                  {...{
                    isInvalid: !!errors?.spendingAmount,
                    onChange: handleSpendingAmountPeriodChange,
                  }}
                />
              </HStack>
              <FormMessage
                field="spendingAmount"
                normalMessage={
                  selectedAccountDailyPurchaseLimit
                    ? `Cards will have a max spending limit of ${selectedAccountDailyPurchaseLimit} a day.`
                    : ``
                }
                errorMessages={errors}
              />
            </FormControl>
            <PropertyAndCategoryTaggingForm
              {...{
                handlePropertySubmit,
                handleCategorySubmit,
                propertyOptions,
                propertyOptionsTooltipText: `All transactions for this card will be automatically tagged
                  to the selected property to streamline bookkeeping. You can change the property at any
                  time for future transactions. `,
                categoryOptions,
                categoryIdsMap,
                setShowMobileDropdownPopup,
                setShowMobileDropdownPopupAnimation,
                showMobileDropdownPopup,
                categoryOptionsTooltipText: `All transactions for this card will be automatically tagged
                  to the selected category to streamline bookkeeping. You can change the category at any
                  time for future transactions. `,
                categoryMap,
                isValidBankTransfer: true,
                renderCategoryDropdownItem,
                showFieldsOnly: true,
                showOptionalText: true,
                parentId: 'add-virtual-card-drawer',
                values: taggingValues(),
                totalSteps: 1,
                currentStep: 1,
              }}
            />
          </VStack>
        )}
      </BaselaneDrawer>
      <BaselaneAlert
        showCloseButton
        isOpen={createCardError}
        onClose={onCreateFailedAlertTryAgain}
        header={
          <AlertHeader
            icon={<IconExclamationCircle color={customTheme.colors.red['800AA']} />}
            title="Virtual card not created"
          />
        }
        body={
          <Text {...{ p: '8px' }}>
            An unexpected error occurred while creating your virtual card. Please try again or
            contact support.
          </Text>
        }
        footer={
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            onClick={onCreateFailedAlertClose}
          >
            OK
          </BaselaneButton>
        }
      />
      <BaselaneAlert
        showCloseButton
        isOpen={hasMaxCards}
        onClose={onMaxCardAlertClose}
        header={
          <AlertHeader
            icon={<IconExclamationCircle color={customTheme.colors.red['800AA']} />}
            title="Maximum virtual cards reached"
          />
        }
        body={
          <Text {...{ p: '8px' }}>
            You have reached the maximum number of cards for this account. Please delete an existing
            card or reach out to support for more help.
          </Text>
        }
        footer={
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            styles={cancelButtonStyles}
            onClick={onMaxCardAlertClose}
          >
            OK
          </BaselaneButton>
        }
      />
    </>
  );
};

export default AddVirtualCard;

AddVirtualCard.defaultProps = {
  from: null,
};
