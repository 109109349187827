import { useFormikContext } from 'formik';
import useBankAccountsActive from '@hooks/useBankAccountsActive';

const useTransferFromAccountSubmit = ({ updateLinkToken }) => {
  const { setFieldValue, setFieldTouched, validateForm } = useFormikContext();

  const { bankAccountsById } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
  });

  const submitValue = async (value) => {
    // Safeguarding the value.
    const safeValue = Array.isArray(value) ? '' : value;

    // Manually setting the value of the field, marking it as touched, and validating the form.
    await setFieldValue('transferFromAccountId', safeValue, true);
    await setFieldTouched('transferFromAccountId', true);
    await validateForm();

    const selectedAccountIsExternal = bankAccountsById[safeValue]?.isExternal;

    const selectedAccountIsDisconnected =
      bankAccountsById[safeValue]?.errorCodes?.isAccountDisconnected;

    if (selectedAccountIsExternal && selectedAccountIsDisconnected) {
      const params = {
        variables: {
          bankAccountId: safeValue,
        },
        skip: !safeValue,
      };

      updateLinkToken(params);
    }
  };

  return { submitValue };
};

export default useTransferFromAccountSubmit;
