import React from 'react';

type IconNoDataProps = {
  w: Number,
  h: Number,
  color: String,
};

function IconNoData({ w = 14, h = 14, color = '#3A4B5B' }: IconNoDataProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.286 2.286a6.667 6.667 0 119.428 9.428 6.667 6.667 0 01-9.428-9.428zm1.444.501l7.483 7.483A5.334 5.334 0 003.73 2.788zm6.54 8.426L2.787 3.73a5.333 5.333 0 007.483 7.483z"
        fill={color}
      />
    </svg>
  );
}

export default IconNoData;
