import process from 'process';
import { auth } from '@core/Services/Firebase/firebase';

import {
  manageStorageAfterLogout,
  manageLocalStorageAfterLogout,
} from '@core/storage/helpers/cleanup.helpers';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { LOGIN, SESSION_EXPIRED } from '@routes';

export function logout() {
  const [orchestrationUrl] = process.env.REACT_APP_ORCHESTRATION_URL?.split('/graphql') || [];
  const requestUrl = `${orchestrationUrl}/api/logout`;

  return fetch(requestUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

export const logoutOnClick = (isSessionExpired = false) => {
  logout().then(() => {
    auth.signOut().then(() => {
      sendSegmentEvent('landlord_logged_out', {
        user_type: 'landlord',
        event_source: 'landlord_portal',
      });

      manageStorageAfterLogout();
      manageLocalStorageAfterLogout();
      sessionStorage.clear();

      if (window?.analytics?.user) {
        window.analytics.user().reset();
      }
      window.location.href = isSessionExpired ? `${LOGIN}/${SESSION_EXPIRED}` : LOGIN;
    });
  });
};
