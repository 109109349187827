// @flow
import React from 'react';
import { Text, Stack } from '@chakra-ui/react';

function KPIsSummaryHeader({ name, address }: { name: string, address: Object }): any {
  return (
    <div>
      <Stack direction="row" align="center" spacing={1.5}>
        <Text textStyle="headline-sm" color="brand.neutral.700" textTransform="capitalize">
          {name}
        </Text>
        {!address || (address && Object.keys(address).length === 0) || address === null ? null : (
          <Text textStyle="sm" color="brand.neutral.600">
            {address.address}, {address.unit ? `${address.unit}, ` : ''}
            {address.city}, {address.state} {address.zipCode}
          </Text>
        )}
      </Stack>
    </div>
  );
}

export default KPIsSummaryHeader;
