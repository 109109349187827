import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import getBreakPoints from '@core/utils/getBreakPoints';
import SlLoader from '@core/components/Loader';
import { bodyTextMediumBoldStyles } from '@core/components/OnboardingTriage/styles/fonts.styles';
import { GET_LEASE, GET_TENANT_PROFILE } from '@pages/LeasesPage/queries';
import {
  extraLargeBoldTextStyles,
  mediumTextStyles,
  xsmallLightCardTextStyles,
} from './styles/font.styles';
import { cardContainerStyles } from './styles/card.styles';
import { getLeaseInfo, getPropertyWithCompletedLease } from './helpers/success.helpers';

const SuccessCardUI = () => {
  const { isMax768: isMobile } = getBreakPoints();
  const { loading, data } = useQuery(GET_LEASE);
  const { leaseDetail = {} } = data || {};

  const { loading: tenantLoading, data: tenantData } = useQuery(GET_TENANT_PROFILE, {
    fetchPolicy: 'network-only',
  });
  const { landlordTenant = [] } = tenantData || {};

  const propertyWithCompletedLease = getPropertyWithCompletedLease(
    leaseDetail?.property || [],
    localStorage.getItem('unit')
  );

  const {
    unit,
    address,
    tenantName,
    tenantNumber,
    tenantEmail,
    leaseDuration,
    monthlyRentAndFees,
  } = getLeaseInfo(propertyWithCompletedLease, landlordTenant);

  return (
    !isMobile && (
      <Box {...cardContainerStyles}>
        {loading || tenantLoading ? (
          <SlLoader className="sl-center" styles={{ top: '40%', left: '40%' }} />
        ) : (
          <Stack spacing="10px">
            <Stack direction="row" align="center" justifyContent="space-between">
              <Stack direction="row" spacing={0} align="baseline">
                <Text {...extraLargeBoldTextStyles}>{monthlyRentAndFees}</Text>
                <Text {...mediumTextStyles}>/mo</Text>
              </Stack>
              <Text {...xsmallLightCardTextStyles}>{leaseDuration}</Text>
            </Stack>
            <Box>
              <Text {...bodyTextMediumBoldStyles}>{unit}</Text>
              <Text {...xsmallLightCardTextStyles}>{address}</Text>
            </Box>
            <Box>
              <Text {...bodyTextMediumBoldStyles}>{tenantName}</Text>
              <Text {...xsmallLightCardTextStyles}>{tenantNumber || '\u2014'}</Text>
              <Text {...xsmallLightCardTextStyles}>{tenantEmail}</Text>
            </Box>
          </Stack>
        )}
      </Box>
    )
  );
};
export default SuccessCardUI;
