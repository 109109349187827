import { useQuery } from '@apollo/client';
import { GET_LEASE_BANKS } from '@core/apollo/queries';
import {
  getPartitionedAccounts,
  getBaselaneConnectedAccounts,
} from '@core/components/NativeBankingPage/MainContent/components/Accounts/helpers/accounts.helpers';

const useLeaseBanks = () => {
  const variables = {
    isConnectedBank: true,
    accountType: 'depository',
    isConnectedAccount: true,
    accountStatus: 'Open',
    accountOwnershipType: 'SELF',
  };
  const fetchPolicy = 'network-only';
  const nextFetchPolicy = 'cache-first';
  const queryOptions = {
    fetchPolicy,
    nextFetchPolicy,
    variables,
  };

  const {
    loading: banksLoading,
    error: banksError,
    data: banksData,
    refetch: refetchBankAccounts,
  } = useQuery(GET_LEASE_BANKS, queryOptions);

  // get banks, rest
  const { bank: banks = [] } = banksData || {};

  // get accounts
  const [baselaneAccounts, externalAccounts] = getPartitionedAccounts(banks);

  const baselaneConnectedAccounts = getBaselaneConnectedAccounts(baselaneAccounts);

  // TODO: remove allBanksError and allBanksLoading (same as the others now)
  const bankProps = {
    banksData,
    allBanksData: externalAccounts,
    baselaneConnectedAccounts,
    refetchBankAccounts,
  };

  return {
    bankProps,
    banksError,
    banksLoading,
    allBanksError: banksError,
    allBanksLoading: banksLoading,
  };
};

export default useLeaseBanks;
