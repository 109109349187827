export const getAtomStyles = (a) => {
  let atomStyles = a.styles?.active;
  if (a.isCompleted) {
    atomStyles = a.styles?.completed;
  } else if (!a.isCompleted && !a.isActive) {
    atomStyles = a?.styles?.notCompleted;
  }

  return atomStyles;
};

export const getAtomBackgroundColor = (config, index) => {
  let atomBgColor = config[index + 1]?.styles?.notCompleted?.bgColor;
  if (config[index + 1]?.isCompleted) {
    atomBgColor = config[index + 1]?.styles?.completed?.bgColor;
  } else if (config[index + 1]?.isActive) {
    atomBgColor = config[index + 1]?.styles?.active?.bgColor;
  }

  return atomBgColor;
};

export const getAtomClassName = (a, config, index) => {
  let className = 'atom';
  if (a.isCompleted) {
    className = 'atom-completed';
  } else if (a.isActive) {
    className = 'atom-active';
  } else if (index === config.length - 1) {
    className = 'atom-last';
  }

  if (config[index + 1] && config[index + 1]?.isSeparate && config[index + 1]?.isActive) {
    className = `${className} atom-before-active`;
  }

  return className;
};
