import React from 'react';

type IconInvoiceFailedProps = {
  w?: Number,
  h?: Number,
};

function IconInvoiceFailed({ w = 32, h = 32 }: IconInvoiceFailedProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 40 40" fill="none">
      <path
        d="M16.875 3.70361V9.38311C16.875 10.1532 16.875 10.5382 17.0226 10.8323C17.1524 11.0911 17.3596 11.3014 17.6144 11.4332C17.9041 11.5831 18.2833 11.5831 19.0417 11.5831H24.6351M14.1667 30.833H9.83333C7.55811 30.833 6.42051 30.833 5.55149 30.3834C4.78708 29.9879 4.1656 29.3569 3.77611 28.5807C3.33333 27.6983 3.33333 26.5432 3.33333 24.233V9.93301C3.33333 7.62279 3.33333 6.46768 3.77611 5.5853C4.1656 4.80913 4.78708 4.17808 5.55149 3.78261C6.42051 3.33301 7.55811 3.33301 9.83333 3.33301H14.1826C15.1763 3.33301 15.6731 3.33301 16.1406 3.44698C16.5551 3.54803 16.9514 3.7147 17.3149 3.94087C17.7249 4.19596 18.0762 4.55268 18.7788 5.2661L23.0962 9.64991C23.7988 10.3633 24.1501 10.7201 24.4013 11.1363C24.6241 11.5054 24.7882 11.9078 24.8877 12.3287C25 12.8034 25 13.3079 25 14.3168V19.2749"
        stroke="#192C3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="29.1667" cy="29.167" r="7.5" fill="#F6A38E" />
      <ellipse
        cx="27.5"
        cy="28.333"
        rx="7.5"
        ry="7.5"
        stroke="#192C3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83333 12.5H15.3571M5.83333 15.2632H22.5M5.83333 17.6316H22.5M5.83333 20H22.5"
        stroke="#192C3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1667 25L30.8333 31.6667M30.8333 25L24.1667 31.6667"
        stroke="#192C3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconInvoiceFailed.defaultProps = {
  w: 32,
  h: 32,
};

export default IconInvoiceFailed;
