// @flow
import React from 'react';
import { Flex } from '@chakra-ui/react';
import { baseStyles } from './styles/card.style';

type Props = {
  children?: Object,
  styles?: Object,
};
const BaselaneCard = React.forwardRef(({ children, styles }: Props, ref) => {
  const containerStyles = {
    ...baseStyles,
    ...styles,
  };

  return (
    <Flex ref={ref} {...containerStyles}>
      {children}
    </Flex>
  );
});

BaselaneCard.defaultProps = {
  children: null,
  styles: null,
};

export default BaselaneCard;
