import { BA_ERROR_ENUMS } from '../../helpers/sharedBAErrors.helpers';

const getStripeBankAccountId = (accounts, value) => {
  const [acc] = accounts.filter((a) => a.id === value);
  return acc?.stripeBankAccountId;
};

const checkForPayoutFailedError = (id, accounts) => {
  const selectedAccount = accounts.filter((account) => account.id === id);
  const errCode = selectedAccount[0]?.bankAccountMetadata?.payoutFailedReason?.failureCode;
  if (errCode && selectedAccount[0]?.bankAccountMetadata?.lastPayoutFailed) {
    return BA_ERROR_ENUMS[errCode];
  }
  return null;
};

const receivingBankFormValidation = (
  accounts,
  showSecurityDepositDropdown,
  values,
  firstLoad,
  addInvoice
) => {
  const { rentAndFeesBankAccountId, depositBankAccountId } = values ?? {};

  const selectedAccount = accounts.find((account) => account.id === rentAndFeesBankAccountId);
  const selectedDepositAccount = accounts.find((account) => account.id === depositBankAccountId);

  const hasRentAndFeesStripeBankAccountId = getStripeBankAccountId(
    accounts,
    rentAndFeesBankAccountId
  );

  const hasDepositStripeBankAccountId = getStripeBankAccountId(accounts, depositBankAccountId);

  const errors = {};
  if (
    !rentAndFeesBankAccountId ||
    rentAndFeesBankAccountId === 'Select account' ||
    rentAndFeesBankAccountId === ''
  ) {
    if (!firstLoad) {
      errors.rentAndFeesBankAccountId = addInvoice
        ? 'Please select receiving account'
        : 'Please enter rent and fees account';
    }
  } else if (
    rentAndFeesBankAccountId &&
    !hasRentAndFeesStripeBankAccountId &&
    accounts.length > 0
  ) {
    errors.rentAndFeesBankAccountId =
      'Cannot collect rent using this bank account, please try another bank account or contact support';
  } else if (
    rentAndFeesBankAccountId &&
    selectedAccount?.connectionState === 'ITEM_LOGIN_REQUIRED'
  ) {
    errors.rentAndFeesBankAccountId = 'Disconnected';
  }

  if (
    (!depositBankAccountId ||
      depositBankAccountId === 'Select account' ||
      depositBankAccountId === '') &&
    showSecurityDepositDropdown
  ) {
    if (!firstLoad) {
      errors.depositBankAccountId = 'Please enter security deposit account';
    }
  } else if (
    depositBankAccountId &&
    !hasDepositStripeBankAccountId &&
    showSecurityDepositDropdown &&
    accounts.length > 0 &&
    ((!selectedDepositAccount.isExternal && selectedDepositAccount.accountSubType !== 'savings') ||
      selectedDepositAccount.isExternal) // do not show FE error when user selects Baselane Savings, even if account does not have stripe id
  ) {
    errors.depositBankAccountId =
      'Cannot collect rent using this bank account, please try another bank account or contact support';
  } else if (
    depositBankAccountId &&
    selectedDepositAccount?.connectionState === 'ITEM_LOGIN_REQUIRED'
  ) {
    errors.depositBankAccountId = 'Disconnected';
  }

  const payoutFailedRentAndFeesError = checkForPayoutFailedError(
    rentAndFeesBankAccountId,
    accounts
  );
  if (payoutFailedRentAndFeesError) {
    errors.rentAndFeesBankAccountId = payoutFailedRentAndFeesError;
  }

  const payoutFailedDepositError = checkForPayoutFailedError(depositBankAccountId, accounts);
  if (payoutFailedDepositError) {
    errors.depositBankAccountId = payoutFailedDepositError;
  }

  return errors;
};

const receivingBankInitialValues = (unit) => {
  return {
    rentAndFeesBankAccountId: (unit?.lease && unit?.lease.rentAndFeesBankAccountId) || '',
    depositBankAccountId: (unit?.lease && unit?.lease.depositBankAccountId) || '',
  };
};

const getBankAccounts = (banks, hasUnconnected) => {
  const accounts = [];

  banks?.forEach((item) =>
    item.bankAccounts?.forEach((a) => {
      const { legalBusinessName, legalAddress } = item.unitAccount || '';
      const { city, postalCode, state, street, unit } = legalAddress || {};

      const addressUnit = unit && unit !== '' ? `, ${unit}` : '';
      const additionalBankData = {
        legalBusinessName,
        bankName: 'Thread Bank',
        routingNumber: a.routingNumber,
        legalAddress: legalAddress
          ? `${street}${addressUnit}, ${city}, ${state}, ${postalCode}`
          : '',
        bankAddress: '210 E Main St, Rogersville, TN 37857',
      };

      const last4Digits = a.accountNumber?.substring(
        a.accountNumber.length - 4,
        a.accountNumber.length
      );
      const name = `${item.plaidInstitutionName}-${a.accountName}`;
      if (
        !a.revokedByUser &&
        (hasUnconnected || (!hasUnconnected && a.connectionState !== 'ITEM_LOGIN_REQUIRED'))
      ) {
        accounts.push({
          name,
          id: a.id,
          number: `*** **** ${a.accountNumber}`,
          last4Digits,
          nickName: a.nickName,
          payoutFailedReason: a.bankAccountMetadata.payoutFailedReason,
          accountSubType: a.accountSubType,
          accountType: a.accountType,
          stripeBankAccountId: a.stripeBankAccountId,
          accountNumber: a.accountNumber,
          bankAccountMetadata: a.bankAccountMetadata,
          connectionState: a.connectionState,
          isExternal: a.isExternal,
          ...additionalBankData,
        });
      }

      a.subAccounts?.forEach((sa) => {
        const last4DigitsSA = sa.accountNumber?.substring(
          sa.accountNumber.length - 4,
          sa.accountNumber.length
        );
        const saName = `${item.plaidInstitutionName}-${sa.accountName}`;
        if (
          !a.revokedByUser &&
          (hasUnconnected || (!hasUnconnected && a.connectionState !== 'ITEM_LOGIN_REQUIRED'))
        ) {
          accounts.push({
            name: saName,
            id: sa.id,
            number: `*** **** ${sa.accountNumber}`,
            last4Digits: last4DigitsSA,
            nickName: sa.nickName,
            payoutFailedReason: sa.bankAccountMetadata.payoutFailedReason,
            accountSubType: sa.accountSubType,
            accountType: sa.accountType,
            stripeBankAccountId: sa.stripeBankAccountId,
            accountNumber: sa.accountNumber,
            bankAccountMetadata: sa.bankAccountMetadata,
            connectionState: a.connectionState,
            isExternal: sa.isExternal,
            ...additionalBankData,
          });
        }
      });
    })
  );

  return accounts;
};

const getAccountsOnly = (connectedBanks) => {
  const ddd = [];
  connectedBanks?.forEach((bank) => {
    bank.items.forEach((item) => ddd.push(item));
  });
  return ddd;
};

export {
  getStripeBankAccountId,
  receivingBankFormValidation,
  receivingBankInitialValues,
  getBankAccounts,
  getAccountsOnly,
};
