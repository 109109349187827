import { gql } from '@apollo/client';

export const GET_PARTNERS = gql`
  query Partner {
    partner {
      id
      name
      title
      tags
      details
      ctaCopy
      ctaUrl
      ctaBehaviour
      logo
      isFeatured
      importanceOrder
    }
  }
`;
