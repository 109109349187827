// @flow

import React, { useState, useRef } from 'react';
import { Box, Heading, HStack, Stack } from '@chakra-ui/react';
import { IconExclamationCircle } from '@icons';
import getBreakPoints from '@core/utils/getBreakPoints';
import BaselaneAlert from '../BaselaneAlert';
import BaselaneButton from '../BaselaneButton';
import BaselaneButtonGroup from '../BaselaneButtonGroup';

import {
  titleTextStyles,
  iconBgStyles,
  iconContainerStyles,
  alertContainerStyles,
  alertHeaderStyles,
  bodyStyles,
  closeButtonStyles,
  headerContainerStyles,
  headerIconContainerStyles,
} from './styles/baselaneResponsiveConfirmDelete.style';

type BaselaneResponsiveConfirmDeleteAlertProps = {
  isOpen: Boolean,
  onClose: Function,
  onConfirmDeleteClick: Function,
  deletePromptText?: String,
  confirmButtonLabel?: String,
};

const BaselaneResponsiveConfirmDeleteAlert = ({
  isOpen,
  onClose,
  onConfirmDeleteClick,
  deletePromptText,
  confirmButtonLabel,
}: BaselaneResponsiveConfirmDeleteAlertProps) => {
  // used to dismiss the modal once the deletion task is confirmed
  // modal will not open again, so it's important to ensure buttons
  // linking to it are removed from the UI
  const [isCompleted, setIsCompleted] = useState(false);
  const { isMin768 } = getBreakPoints();
  const confirmDeleteAlert = useRef();

  return (
    <BaselaneAlert
      isOpen={isOpen && !isCompleted}
      onClose={onClose}
      leastDestructiveRef={confirmDeleteAlert}
      header={
        <HStack {...headerContainerStyles}>
          <Box {...headerIconContainerStyles}>
            <IconExclamationCircle
              {...{
                w: 20,
                h: 20,
                color: '#EA6868',
              }}
            />
          </Box>
          <Heading as="h2" {...titleTextStyles}>
            {deletePromptText}
          </Heading>
        </HStack>
      }
      footer={
        <Stack mt={4}>
          <BaselaneButtonGroup size="md">
            <BaselaneButton variant="outline" palette="neutral" size="md" onClick={onClose}>
              No, Cancel
            </BaselaneButton>
            <BaselaneButton
              variant="filled"
              palette="danger"
              size="md"
              onClick={() => {
                onConfirmDeleteClick();
                setIsCompleted(true);
              }}
            >
              {confirmButtonLabel}
            </BaselaneButton>
          </BaselaneButtonGroup>
        </Stack>
      }
      showCloseButton
      closeButtonStyles={closeButtonStyles}
      closeButtonIconProps={{
        w: isMin768 ? 14 : 10,
        h: isMin768 ? 14 : 10,
      }}
      containerStyles={alertContainerStyles}
      headerStyles={alertHeaderStyles}
      footerStyles={{ mt: '0px !important' }}
      bodyStyles={bodyStyles}
      titleTextStyles={titleTextStyles}
      iconBgStyles={iconBgStyles}
      iconContainerStyles={iconContainerStyles}
    />
  );
};

BaselaneResponsiveConfirmDeleteAlert.defaultProps = {
  deletePromptText: 'Are you sure you want to delete?',
  confirmButtonLabel: 'Yes, Delete',
};

export default BaselaneResponsiveConfirmDeleteAlert;
