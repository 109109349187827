import React, { useContext, useEffect, useState } from 'react';
import { useGate } from 'statsig-react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { Box, Stack, useDisclosure } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import UserContext from '@contexts/UserContext';
import { OTPProvider } from '@contexts/OTPContext';
import {
  TRANSACTIONS,
  NB_ACCOUNTS,
  DASHBOARD,
  ANALYTICS_REPORTING,
  HOME,
  CASH_FLOW_STATEMENT,
  CASH_FLOW_TAX_PACKAGE,
  LEASES,
  REDEEM_CASHBACK,
  ADD_BASELANE_BANK_ACCOUNT,
  GET_STARTED,
  ADD_VIRTUAL_ACCOUNT,
} from '@routes';
import getBreakPoints from '@core/utils/getBreakPoints';
import sendSegmentPageEvent from '@core/utils/sendSegmentEvent/sendSegmentPageEvent';

import UnitDataBanner from '@core/components/CDD/components/UnitDataBanner';
import UnitDataPopup from '@core/components/CDD/components/UnitDataPopup';
import MissingUnitDataPopup from '@core/components/CDD/components/MissingUnitDataPopup';
import { GET_BANK_SUMMARY } from '@core/components/CDD/queries/bank';
import { fetchData } from '@core/components/CDD/helpers/dataCollection.helpers';
import { useBanks } from '@core/components/Shared/hooks';
import useUserSignUpExperiment from '@core/hooks/useUserSignUpExperiment';
import { ACTIVE_LEASES } from './queries/leases';
import { TRANSACTION_ALERTS, UnreadTransactionAlertInput } from './queries/alerts';

import KYCBanner from './KYCBanner';
import TransactionImportBanner from './TransactionImportBanner';
import ACHFeePromotionBanner from './ACHFeePromotionBanner';
import QuickPayAnnounceBanner from './QuickPayAnnounceBanner';
import EmailVerificationBanner from './EmailVerificationBanner';

const Banner = () => {
  // Use gate for quickPay account
  const { value: quickPayAnnounceBannerValue, isLoading: isStatsigGateLoading = false } = useGate(
    'quick_pay_announce_banner_gate'
  );

  const {
    user,
    isDesktopCheckDepositOpen,
    isTabletCheckDepositOpen,
    isMobileCheckDepositOpen,
  } = useContext(UserContext);

  const { metadata } = user?.userMetadata ?? {};
  const { isUserEmailUnverified } = useUserSignUpExperiment();
  const { accountsLoading, accountsError, accountsData } = useBanks({});

  const hasAllAccountsClosed = () => {
    if (!accountsLoading && !accountsError && accountsData) {
      const accounts = accountsData?.bank.flatMap((b) => b.bankAccounts || []);
      const subAccounts = accounts?.flatMap((a) => a?.subAccounts || []);
      const mergedAccounts = [...accounts, ...subAccounts];
      const closedAccounts = mergedAccounts.filter(
        (acc) => acc.bankPartner === 'BRB' && acc.accountStatus === 'Closed'
      );
      return mergedAccounts.filter((a) => a.bankPartner === 'BRB').length === closedAccounts.length;
    }
    return false;
  };

  useEffect(() => {
    hasAllAccountsClosed();
  }, [accountsData]);
  const { isMin899, isMin768: isDesktop } = getBreakPoints();

  // CDD start
  // UnitMissingDataListPopup Alert State
  const {
    isOpen: isMissingUnitDataListPopupOpen,
    onOpen: onMissingUnitDataListPopupOpen,
    onClose: onMissingUnitDataListPopupClose,
  } = useDisclosure();

  // UnitDataPopup Alert State
  const {
    isOpen: isUnitDataPopupOpen,
    onOpen: onUnitDataPopupOpen,
    onClose: onUnitDataPopupClose,
  } = useDisclosure();

  // data collection popup
  const [banksThatNeedDataCollection, setBanksThatNeedDataCollection] = useState([]);
  const [isLoadingDataCollectionList, setIsLoadingDataCollectionList] = useState(true);
  const [daysToExpire, setDaysToExpire] = useState('');
  const [isExpired, setIsExpired] = useState(true);

  const { data: bankSummaryData } = useQuery(GET_BANK_SUMMARY, {
    variables: { isExternal: false },
  });
  // ------------ CDD end

  const { data, loading, error } = useQuery(ACTIVE_LEASES);

  const location = useLocation();
  const { pathname = '' } = location;

  useEffect(() => {
    sendSegmentPageEvent();
  }, [pathname]);

  const { userKycStatus = null, userPromotion } = user ?? {};
  const { leaseDetail } = data || {};
  const { activeLease = 0 } = leaseDetail?.summary || {};

  const achFeePromotion = userPromotion?.find(
    (p) => p.promotion.type === 'FEES_WAIVER' && p.promotion.feeType === 'ACH_FEE'
  );

  const todaysDate = moment(new Date().toDateString());
  const userAchFeePromoStartDate = moment(achFeePromotion?.startDate);
  const userAchFeePromoEndDate = moment(achFeePromotion?.endDate);

  const isUserEligibleForAchFeePromo =
    achFeePromotion?.promotion &&
    todaysDate < userAchFeePromoEndDate &&
    todaysDate >= userAchFeePromoStartDate &&
    !leaseDetail?.isEmpty;

  const {
    data: transactionAlertsData,
    loading: transactionAlertsLoading,
    error: transactionAlertsError,
    refetch: transactionAlertsRefetch,
  } = useQuery(TRANSACTION_ALERTS, {
    fetchPolicy: 'no-cache',
    variables: { input: UnreadTransactionAlertInput },
  });

  const { alert } = transactionAlertsData || {}; // quick test data { alert: [ { id: '4' } ] };
  const { id: transactionAlertsId } = alert?.length > 0 ? alert[0] : {};

  const transactionImportBannerPages = [
    TRANSACTIONS,
    NB_ACCOUNTS,
    `${NB_ACCOUNTS}/${REDEEM_CASHBACK}`,
    `${NB_ACCOUNTS}/${ADD_BASELANE_BANK_ACCOUNT}`,
    `${NB_ACCOUNTS}/${ADD_BASELANE_BANK_ACCOUNT}/${ADD_VIRTUAL_ACCOUNT}`,
    DASHBOARD,
    ANALYTICS_REPORTING,
    CASH_FLOW_STATEMENT,
    CASH_FLOW_TAX_PACKAGE,
    HOME,
    `/${ADD_BASELANE_BANK_ACCOUNT}`,
    GET_STARTED,
    `${GET_STARTED}/${ADD_BASELANE_BANK_ACCOUNT}`,
    `${GET_STARTED}/${ADD_BASELANE_BANK_ACCOUNT}/${ADD_VIRTUAL_ACCOUNT}`,
  ];

  const showTransactionImportBanner =
    transactionImportBannerPages.includes(pathname) &&
    !!transactionAlertsId &&
    !transactionAlertsLoading &&
    !transactionAlertsError;

  const showACHFeePromotionBanner =
    pathname.includes(LEASES) &&
    !metadata?.hideACHFeePromotionBanner &&
    isUserEligibleForAchFeePromo;

  const showQuickPayAnnounceBanner =
    quickPayAnnounceBannerValue &&
    pathname.includes(LEASES) &&
    localStorage.getItem('isQuickPayAnnounceBannerClosed') !== 'true';

  // CDD start
  // call unit data collection api to see if any baselane banks have incomplete information
  useEffect(() => {
    const bankData = bankSummaryData?.bankSummary?.bank ?? [];

    if (bankData.length > 0) {
      fetchData({
        bankData,
        setBanksThatNeedDataCollection,
        setIsLoadingDataCollectionList,
      });
    }
  }, [bankSummaryData?.bankSummary?.bank]);

  const handleRemindMeLaterClick = () => {
    onUnitDataPopupClose();
    localStorage.setItem('DCPopupFlag', false);
  };

  const handleUnitDataPopupClick = () => {
    onUnitDataPopupClose();
    onMissingUnitDataListPopupOpen();
    localStorage.setItem('DCPopupFlag', false);
  };

  const handleMissingUnitDataListClose = () => {
    onMissingUnitDataListPopupClose();
    fetchData({
      bankData: bankSummaryData?.bankSummary?.bank ?? [],
      setBanksThatNeedDataCollection,
      setIsLoadingDataCollectionList,
    });
  };

  const onRefreshStatus = () => {
    const bankData = bankSummaryData?.bankSummary?.bank ?? [];
    fetchData({
      bankData,
      setBanksThatNeedDataCollection,
      setIsLoadingDataCollectionList,
    });
  };

  useEffect(() => {
    const showUnitPopup = localStorage.getItem('DCPopupFlag');

    // setting expiration date
    const startDate = moment(Date.now()).utcOffset('-0400').format('YYYY-MM-DD HH:mm');
    const endDate = moment('2023-12-15 17:00');
    const isWarningExpired = endDate.diff(startDate, 'minutes');
    setDaysToExpire(endDate.diff(startDate, 'days'));
    setIsExpired(isWarningExpired);

    if (
      metadata?.hideBankMigrationSuccessModal &&
      isWarningExpired > 0 &&
      banksThatNeedDataCollection.length > 0 &&
      !isMissingUnitDataListPopupOpen &&
      !isUnitDataPopupOpen &&
      showUnitPopup !== 'false'
    ) {
      onUnitDataPopupOpen();
    }
  }, [user, banksThatNeedDataCollection, metadata]);
  // -------- CDD end

  useEffect(() => {
    // this reloads when statsig is done
  }, [isStatsigGateLoading]);

  /**
   * Note: Due to the Entities page containing a carousel that requires
   * no padding surrounding the scrollable cards, this flag exists to
   * remove said padding.
   */
  const isEntitiesPage = pathname.includes('/banking/accounts/baselane_');
  const isTransactionsMobilePage = pathname.includes('/transactions') && isMobile;
  const isExternalAccountsMobilePage = pathname.includes('/externalaccounts') && isMobile;
  const isPropertiesMobilePage = pathname.includes('/properties') && isMobile;
  const showMobileBanner =
    (!isDesktop && !isDesktopCheckDepositOpen) ||
    isMobileCheckDepositOpen ||
    isTabletCheckDepositOpen;
  const showKYCBanner =
    !isPropertiesMobilePage &&
    !isExternalAccountsMobilePage &&
    !isTransactionsMobilePage &&
    !isEntitiesPage &&
    userKycStatus !== 'APPROVED' &&
    !loading &&
    !error &&
    activeLease > 0;

  return (
    <>
      {/* CDD Popups */}
      <UnitDataPopup
        {...{
          isUnitDataPopupOpen,
          onUnitDataPopupClose,
          handleUnitDataPopupClick,
          handleRemindMeLaterClick,
          daysToExpire,
        }}
      />
      <MissingUnitDataPopup
        {...{
          isMissingUnitDataListPopupOpen,
          handleMissingUnitDataListClose,
          banksThatNeedDataCollection,
          isLoadingDataCollectionList,
          onRefreshStatus,
        }}
      />

      <Stack>
        {/* CDD Banner */}
        {isExpired > 0 &&
          banksThatNeedDataCollection &&
          banksThatNeedDataCollection?.length > 0 && (
            <UnitDataBanner
              handleUnitDataPopupClick={handleUnitDataPopupClick}
              daysToExpire={daysToExpire}
            />
          )}

        {isUserEmailUnverified && (
          <OTPProvider>
            <EmailVerificationBanner email={user?.email} />
          </OTPProvider>
        )}
        {showACHFeePromotionBanner && <ACHFeePromotionBanner {...{ userAchFeePromoEndDate }} />}
        {showQuickPayAnnounceBanner && <QuickPayAnnounceBanner />}

        {showMobileBanner && showKYCBanner && (
          <Box
            {...{
              px: '16px',
              pt: '16px',
              position: 'relative',
              zIndex: 10,
            }}
          >
            <KYCBanner {...{ userKycStatus }} />
          </Box>
        )}
        {!showMobileBanner && (showKYCBanner || showTransactionImportBanner) && (
          <Box m={isDesktop && !isMin899 && '24px 16px 16px!important'}>
            {showKYCBanner && <KYCBanner {...{ userKycStatus }} />}
            {showTransactionImportBanner && (
              <TransactionImportBanner
                id={transactionAlertsId}
                refetch={transactionAlertsRefetch}
              />
            )}
          </Box>
        )}
      </Stack>
    </>
  );
};

export default Banner;
