import React from 'react';

type IconPlusProps = {
  shouldSetDimensions?: Boolean,
  w?: string,
  h?: string,
  color?: string,
};

function IconPlus({ w, h, color, shouldSetDimensions }: IconPlusProps) {
  return (
    <svg
      {...(shouldSetDimensions ? { width: w, height: h } : {})}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 10"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9987.333496c.36819 0 .66666.298477.66666.666664V4.3335H8.9987c.36819 0 .66666.29847.66666.66666s-.29847.66667-.66666.66667H5.66536v3.33333c0 .36819-.29847.66667-.66666.66667s-.66667-.29848-.66667-.66667V5.66683H.998698c-.36819 0-.666667-.29848-.666667-.66667 0-.36819.298477-.66666.666667-.66666H4.33203V1.00016c0-.368187.29848-.666664.66667-.666664Z"
        fill={color}
      />
    </svg>
  );
}

IconPlus.defaultProps = {
  w: '10',
  h: '10',
  color: 'currentColor',
  shouldSetDimensions: true,
};

export default IconPlus;
