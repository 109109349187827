import React from 'react';

type IconStarsProps = {
  w?: Number,
  h?: Number,
  color?: String,
};

function IconStars({ w = 14, h = 14, color = 'currentColor' }: IconStarsProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33203.333008c.36819 0 .66667.298477.66667.666666v.666666h.66666c.36819 0 .66667.29848.66667.66667 0 .36819-.29848.66666-.66667.66666H2.9987v.66667c0 .36819-.29848.66667-.66667.66667-.36819 0-.66667-.29848-.66667-.66667v-.66667H.998698c-.36819 0-.666667-.29847-.666667-.66666s.298477-.66667.666667-.66667h.666662V.999674c0-.368189.29848-.666666.66667-.666666Zm5.33333 0c.28695 0 .5417.183606.63245.45582L9.72123 5.05847l3.51157 1.31699c.2602.09759.4326.34633.4326.62421 0 .27789-.1724.52663-.4326.62422L9.72123 8.94088 8.29781 13.2105c-.09075.2722-.3455.4558-.63245.4558-.28694 0-.54169-.1836-.63244-.4558L5.6095 8.94088 2.09793 7.62389c-.26019-.09759-.43257-.34633-.43257-.62422 0-.27788.17238-.52662.43257-.62421L5.6095 5.05847 7.03292.788828c.09075-.272214.3455-.45582.63244-.45582Zm0 2.774572-.89155 2.67427c-.06362.19083-.21.34273-.39834.41337l-2.14497.80445 2.14497.80446c.18834.07064.33472.22254.39834.41337l.89155 2.6743.89156-2.6743c.06362-.19083.21-.34273.39834-.41337l2.14494-.80446-2.14494-.80445c-.18834-.07064-.33472-.22254-.39834-.41337l-.89156-2.67427ZM2.9987 9.66634c.36819 0 .66666.29848.66666.66666v.6667h.66667c.36819 0 .66667.2985.66667.6666 0 .3682-.29848.6667-.66667.6667h-.66667v.6667c0 .3682-.29847.6666-.66666.6666s-.66667-.2984-.66667-.6666v-.6667h-.66667c-.36819 0-.666662-.2985-.666662-.6667 0-.3681.298472-.6666.666662-.6666h.66667v-.6667c0-.36818.29848-.66666.66667-.66666Z"
        fill={color}
      />
    </svg>
  );
}

IconStars.defaultProps = {
  w: 14,
  h: 14,
  color: 'currentColor',
};

export default IconStars;
