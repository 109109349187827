import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import {
  panelDescriptionStyles,
  panelFormStyles,
  panelHeaderContainerStyles,
  panelHeaderStyles,
  panelHeaderWrapperStyles,
  tabPanelStyles,
} from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';
import { FormDescription, FormTitle } from '@shared/components/BaselaneFlow';

import { BusinessInformationForm } from '../forms';
import FooterWrapper from '../FooterWrapper';

const BusinessInfoRootView = () => {
  const footerWrapperProps = useOutletContext();
  return (
    <Box {...tabPanelStyles}>
      <Box {...panelHeaderWrapperStyles()}>
        <FormTitle
          title="Business Information"
          textStyles={panelHeaderStyles(isMobile)}
          containerStyles={panelHeaderContainerStyles(isMobile)}
        />
        <FormDescription styles={panelDescriptionStyles(isMobile)}>
          Some business information fields are optional.
        </FormDescription>
      </Box>
      <Box {...panelFormStyles}>
        <BusinessInformationForm
          {...{
            businessInfoRef: footerWrapperProps.formRefs[2],
            formData: footerWrapperProps.formData,
          }}
        />
      </Box>
      <FooterWrapper {...footerWrapperProps} />
    </Box>
  );
};

export default BusinessInfoRootView;
