import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Formik } from 'formik';
import { BaselaneDivider } from '@shared/components';
import {
  SecurityDeposit,
  AdditionalFees,
  LastMonthRent,
  ProratedRent,
  useDepositAndFees,
  sectionData,
} from '../DepositsAndFees';
import { formValidation, getFormInitialValues } from '../../formHelpers/leaseTermForm.helper';
import { getDepositAndFeesData } from '../../formHelpers/depositAndFeesForm.helper';
import type { PropertyUnitType } from '../../../types';

import {
  generalFormContainerStyles,
  unifiedLeaseFormContainerStyles,
} from '../../styles/shared.styles';

type DepositsFeesFormProps = {
  unit: PropertyUnitType,
  lease: Object,
  formVariables?: Object,
  setFormVariables: Function,
  depositsFeesRef: any,
  bankAccountsRef: any,
  activeTab: Number,
  saveLease: Function,
  unifiedRC: boolean,
  additionalFeeFormRef: any,
};

function DepositsFeesForm({
  unit,
  lease,
  formVariables,
  setFormVariables,
  depositsFeesRef,
  bankAccountsRef,
  activeTab,
  saveLease,
  unifiedRC = true,
  additionalFeeFormRef = null,
}: DepositsFeesFormProps) {
  const [formInitialValues] = getFormInitialValues(unit || { lease });

  const recurringFeeData = getDepositAndFeesData(unit?.lease, formVariables);
  const { fees } = recurringFeeData;
  const isAdditionalFeesDb =
    fees.length > 0
      ? fees
          .sort((a, b) => a.description - b.description)
          .map((feeData) => {
            const feesData = { ...feeData };
            // eslint-disable-next-line no-underscore-dangle
            delete feesData.__typename;
            delete feesData.category;

            let { dueDate } = feesData;
            if (dueDate && dueDate !== null) {
              dueDate = new Date(dueDate);
              return Object.assign(feesData, { dueDate });
            }
            return feesData;
          })
      : [];

  const oneTimeFeesExist =
    isAdditionalFeesDb?.filter((fee) => fee.frequency !== 'MONTHLY').length > 0;
  const [showAdditionalFees, setShowAdditionalFees] = useState(oneTimeFeesExist);
  const data = getDepositAndFeesData(unit?.lease || lease);
  const { deposit, lastMonthRent, proratedRent } = data;

  const { id: lastMonthId } = lastMonthRent || {};

  const { id: proratedRentId } = proratedRent || {};

  useEffect(() => {
    if (oneTimeFeesExist !== showAdditionalFees) {
      setShowAdditionalFees(oneTimeFeesExist);
    }
  }, [oneTimeFeesExist]);

  useDepositAndFees({
    deposit,
    lastMonthRent,
    proratedRent,
    setFormVariables,
    activeTab,
    finishedLeaseTerm: (unit?.lease || lease)?.completedTags?.includes('LEASE_TERM'),
    hasDepositsAndFeesTag: (unit?.lease || lease)?.completedTags?.includes('DEPOSITS_FEES'),
    saveLease,
  });

  return (
    <Box
      {...(unifiedRC ? unifiedLeaseFormContainerStyles : generalFormContainerStyles)}
      pt="0px"
      overflowY="none"
    >
      <Formik
        innerRef={depositsFeesRef}
        validate={(values) => formValidation(values)}
        initialValues={formInitialValues}
        validateOnBlur
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldTouched,
          setFieldValue,
          errors,
          touched,
        }) => (
          <>
            <BaselaneDivider styles={{ mb: '8px' }} />

            <SecurityDeposit
              {...{
                sectionInfo: sectionData.securityDeposit,
                sectionId: 'securityDeposit',
                depositId: deposit?.id,
                handleChange,
                setFieldTouched,
                setFieldValue,
                setFormVariables,
                values,
                errors,
                touched,
                isAdditionalFeesDb,
                bankAccountsRef,
              }}
            />

            <BaselaneDivider
              styles={{
                mt: '12px',
                mb: '8px',
              }}
            />

            <LastMonthRent
              {...{
                sectionInfo: sectionData.lastMonthRent,
                sectionId: 'lastMonthRent',
                lastMonthId,
                handleChange,
                setFieldTouched,
                setFieldValue,
                setFormVariables,
                values,
                errors,
                touched,
              }}
            />

            <BaselaneDivider
              styles={{
                mt: '12px',
                mb: '8px',
              }}
            />

            <ProratedRent
              {...{
                sectionInfo: sectionData.proratedRent,
                sectionId: 'proratedRent',
                proratedRentId,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                setFormVariables,
                values,
                errors,
                touched,
              }}
            />

            <BaselaneDivider
              styles={{
                mt: '12px',
                mb: '8px',
              }}
            />

            <AdditionalFees
              {...{
                sectionInfo: sectionData.additionalFees,
                sectionId: 'additionalFees',
                handleChange,
                setFieldValue,
                setFormVariables,
                isAdditionalFeesDb,
                values,
                showAdditionalFees,
                setShowAdditionalFees,
                additionalFeeFormRef,
              }}
            />
          </>
        )}
      </Formik>
    </Box>
  );
}

DepositsFeesForm.defaultProps = {
  formVariables: {},
};

export default DepositsFeesForm;
