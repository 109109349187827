import React, { useContext, useEffect } from 'react';
import { Text, Heading, useDisclosure, useToast, Stack } from '@chakra-ui/react';
import {
  createSearchParams,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';

import {
  BaselaneApplicationTypePopup,
  BaselaneCardNew,
  BaselaneCardStackLayoutClickable,
} from '@shared/components';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';
import BanksContext from '@contexts/BanksContext';
import getBreakPoints from '@core/utils/getBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import historyStateStorage from '@core/storage/historyStateStorage';
import { Icon12Plus } from '@icons/12px';
import { GET_STARTED, NATIVE_BANK, UNIFIED_LB_PERSONAL_INFO } from '@routes';
import formatCurrency from '@core/utils/formatCurrency';
import getPluralizedNoun from '@core/utils/getPluralizedNoun';
import {
  IllustrationBankingBusinessAccount,
  IllustrationBankingSolePropAccount,
} from '@illustrations';
import * as ROUTES from '@routes';
import { getFlatAccounts } from '@core/components/NativeBankingPage/MainContent/components/Accounts/helpers/bank.helpers';

import { cardStyles, iconContainerStyles } from './styles/card.styles';
import BaselaneCardStack from '../../../Shared/components/BaselaneCardStack';

type AddBaselaneAccountProps = {
  from: string,
};

function AddBaselaneAccount({ from }: AddBaselaneAccountProps): any {
  const { isEmptyState = false } = useOutletContext() ?? {};
  const navigate = useNavigate();
  const currLocation = useLocation();
  const { isMax576 } = getBreakPoints();

  const { getKYCUrl, baselaneConnectedAccounts } = useContext(BanksContext);

  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  useEffect(() => {
    if (isEmptyState) {
      onBaselaneApplicationTypePopupOpen();
    }
  }, [isEmptyState]);

  const toast = useToast();
  const showToast = () =>
    toast({
      description: 'Your Rent Collection setup progress has been saved',
      status: 'success',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const isGetStarted = from.includes('get_started');
  const isFromLease = from.includes('leases');

  const openLBFlow = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;
    const state = { from: isGetStarted ? GET_STARTED : NATIVE_BANK };

    if (type === 'business') {
      getKYCUrl();
    } else {
      if (isFromLease) {
        showToast();
      }
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  const getAvailableSubAccountBalance = (mainAccountBalance, subAccounts) => {
    const value = subAccounts.reduce((acc, account) => {
      return acc + account.availableBalance;
    }, mainAccountBalance);

    return formatCurrency(value, true).inDollars;
  };
  const bankAccounts = getFlatAccounts(baselaneConnectedAccounts);
  const items = (bankAccounts ?? []).map((bankAccount) => {
    const { subAccounts = [], applicationType, bankId, bankName } = bankAccount ?? {};
    const mainAccountBalance = bankAccount?.availableBalance ?? 0;
    return {
      id: bankId,
      content: (
        <BaselaneCardStackLayoutClickable
          icon={
            applicationType === 'SoleProprietorship' ? (
              <IllustrationBankingSolePropAccount />
            ) : (
              <IllustrationBankingBusinessAccount />
            )
          }
          title={bankName}
          description={`${getPluralizedNoun(
            subAccounts.length + 1, // +1 to count the main account that is always there
            'account'
          )} · ${getAvailableSubAccountBalance(mainAccountBalance, subAccounts)}`}
          onClick={() =>
            navigate({
              pathname: `${currLocation.pathname}/${ROUTES.ADD_VIRTUAL_ACCOUNT}`,
              search: createSearchParams({ entityId: bankAccount.bankId }).toString(),
            })
          }
        />
      ),
    };
  });

  return (
    <>
      <BaselaneDrawer
        title="Add account"
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        closeEvent={() => onDrawerClose(navigate, from)}
        isOpen={!isEmptyState}
        newDesignDrawer
      >
        <Stack justifyContent="space-between">
          <Stack spacing={3}>
            <Text textStyle="sm">
              Create checking or savings accounts to organize your property’s OpEx, profits,
              security deposits, savings, and more.
            </Text>
            <Stack spacing={1}>
              <Heading size="headline-md">Add virtual account to an existing entity</Heading>
              <BaselaneCardStack id="entity-list" items={items} size="md" />
            </Stack>
            <Stack spacing={1}>
              <Heading size="headline-md">Open account for a new entity</Heading>
              <BaselaneCardNew
                variant="clickable"
                palette="empty"
                onClick={onBaselaneApplicationTypePopupOpen}
                id="baselane-banking-start-application"
                {...cardStyles}
              >
                <Stack {...iconContainerStyles}>
                  <Icon12Plus />
                </Stack>
                <Stack spacing="0.5">
                  <Heading size="headline-sm">Start application</Heading>
                  <Text textStyle="xs">
                    {isMax576
                      ? 'For a different legal entity or individual'
                      : 'For an individual or business (LLC, corporation, or partnership)'}
                  </Text>
                </Stack>
              </BaselaneCardNew>
            </Stack>
          </Stack>
        </Stack>
      </BaselaneDrawer>
      <BaselaneApplicationTypePopup
        handleActionButton={openLBFlow}
        isOpen={isBaselaneApplicationTypePopupOpen}
        onClose={() => {
          onBaselaneApplicationTypePopupClose();
          if (isEmptyState) {
            onDrawerClose(navigate, from);
          }
        }}
      />
      <Outlet />
    </>
  );
}

export default AddBaselaneAccount;
