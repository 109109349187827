import React from 'react';

function IconSingleFamilyHouse({
  w = 46,
  h = 48,
  color = 'currentColor',
}: {
  w?: string,
  h?: string,
  color?: string,
}) {
  return (
    <svg width={w} height={h} viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.867 23.733h36.266v18.489a5.689 5.689 0 01-5.689 5.689H10.556a5.689 5.689 0 01-5.69-5.69V23.734zm2.844 2.844v15.645a2.844 2.844 0 002.845 2.844h24.889a2.844 2.844 0 002.844-2.844V26.577H7.71z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4 47.91H16.6V36.178a4.267 4.267 0 014.266-4.266h4.267a4.267 4.267 0 014.267 4.266v11.734zm-8.534-13.155c-.785 0-1.422.637-1.422 1.422v8.89h7.111v-8.89c0-.785-.637-1.422-1.422-1.422h-4.267zM40.709 23.504c.68 0 .972-.863.432-1.276L24.855 9.767a2.844 2.844 0 00-3.457 0L5.112 22.227c-.54.414-.248 1.277.432 1.277h35.165zM3.383 19.969L19.67 7.508a5.689 5.689 0 016.914 0l16.286 12.46c2.7 2.067 1.24 6.38-2.16 6.38H5.544c-3.4 0-4.86-4.313-2.16-6.38z"
        fill={color}
      />
    </svg>
  );
}

IconSingleFamilyHouse.defaultProps = {
  w: '46',
  h: '48',
  color: 'currentColor',
};

export default IconSingleFamilyHouse;
