import React from 'react';
import { Text, Box } from '@chakra-ui/react';

type Props = {
  errors: any,
  fieldKey: string,
};

function BaselaneErrorText({ errors, fieldKey }: Props): any {
  return (
    <Box h="20px">
      <Text textStyle="xs" color="red">
        {errors[fieldKey]}
      </Text>
    </Box>
  );
}

export default BaselaneErrorText;
