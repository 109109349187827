import { gql } from '@apollo/client';

export const GET_TRANSFERS = gql`
  query Transfer($input: TransferQueryOption) {
    transfers(input: $input) {
      data {
        amount
        createdAt
        bankPartner
        fromTransferAccount {
          accountName
          accountNumber
          amount
          id
          name
          bankAccountId
          userId
          institution {
            id
            name
            plaidInstitutionName
          }
          isExternal
        }
        fromTransferAccountId
        id
        externalId
        note
        status
        toTransferAccount {
          accountName
          accountNumber
          amount
          id
          name
          bankAccountId
          userId
          institution {
            id
            name
            plaidInstitutionName
          }
          isExternal
        }
        toTransferAccountId
        transferDate
        completedDate
        type
        typeName
        userId
        metadata {
          name
          checkNumber
        }
      }
      total
    }
  }
`;

export const GET_TRANSFERS_ACCOUNT_LIST = gql`
  query TransferAccountList($transferType: String) {
    transferAccountList(transferType: $transferType) {
      fromAccount {
        accountName
        accountNumber
        amount
        id
        name
        bankAccountId
        userId
        isExternal
        isBankConnected
        counterPartyConnectionHistoryCode
        institution {
          id
          name
          plaidInstitutionName
        }
      }
      toAccount {
        accountName
        accountNumber
        amount
        id
        name
        bankAccountId
        userId
        isExternal
        isBankConnected
        counterPartyConnectionHistoryCode
        institution {
          id
          name
          plaidInstitutionName
        }
      }
    }
  }
`;

export const CREATE_TRANSFER = gql`
  mutation createTransfer($input: CreateTransferInput!) {
    createTransfer(input: $input) {
      amount
      createdAt
      fromTransferAccount {
        accountName
        accountNumber
        amount
        id
        institution {
          name
        }
        isExternal
        name
        bankAccountId
        userId
      }
      fromTransferAccountId
      id
      note
      status
      toTransferAccount {
        accountName
        accountNumber
        amount
        id
        institution {
          name
        }
        isExternal
        name
        bankAccountId
        userId
      }
      toTransferAccountId
      transferDate
      type
      typeName
      userId
    }
  }
`;
