import React from 'react';
import {
  Box,
  HStack,
  List,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { IconCheck, IconLightBulb } from '@icons';

import {
  titleStyles,
  questionStyles,
  answerStyles,
  orderedListStyles,
  checkListStyles,
  checkListItemStyles,
  checkedListItemIconContainerStyles,
  checkListItemTextContainerStyles,
  bannerContainerStyles,
  bannerMessageStyles,
  bannerTitleStyles,
} from '../../styles/howToGuidesDrawer.styles';

const bookkeepingDrawerConst = {
  mobileTitle: 'How to Guide - Bookkeeping and Tracking Your Income/Expenses',
  q1: 'How can I set up bookkeeping for my properties on Baselane?',
  q2: 'How do I track cash flow for a property (or unit)?',
  q3: 'How can Baselane help me with Bookkeeping and end-of-year Taxes?',
  a1:
    'Staying on top of bookkeeping is vital to monitoring your current rental portfolio and giving you the confidence to grow your rental business. In order to start bookkeeping on Baselane, you will need to:',
  a1List: [
    {
      key: '1',
      text: 'Add a Property',
    },
    {
      key: '2',
      text: 'Set up Baselane Banking or link your existing bank account(s) and/or credit card(s)',
    },
    {
      key: '3',
      text: 'We auto-import your transactions (takes less than 30 seconds!)',
    },
    {
      key: '4',
      text: 'Tag transactions to Schedule E categories and to a property',
    },
  ],
  a2:
    'To track cash flow, you will need to import your rent and expense transactions and assign them to your property.',
  a2List: [
    {
      key: '1',
      text:
        'Setup your rental properties and auto-import related transactions (rent, expenses, credit card spend)',
    },
    {
      key: '2',
      text: 'Categorize your rent and expenses by property and type',
    },
    {
      key: '3',
      text:
        'Go to the ‘Analytics and Reporting’ page to track your performance (filter by property, date range, category, etc.)',
    },
    {
      key: '4',
      text:
        'Now you can track cash flow to identify any odd spikes in expenses or drop in revenue to avoid surprises and fix any issues',
    },
  ],
  a3List: [
    {
      key: '1',
      text: 'Baselane tags according to Schedule E Categories',
    },
    {
      key: '2',
      text:
        'You can easily export your entire transaction ledger (and cash flow report) to share with an accountant',
    },
    {
      key: '3',
      text: 'Roadmap for 2nd half of 2022: Rent Roll, and Schedule E Report',
    },
  ],
  banner: {
    boldedText: 'Pro Tip: Bulk categorize your expenses to save time in tax season.',
    description:
      'You can select multiple recurring transactions (e.g., Mortgage & Utilities) and bulk edit using our “Edit” tool.',
  },
};

export const bookkeepingDrawerComponents = (isMobile) => {
  const {
    mobileTitle,
    q1,
    q2,
    q3,
    a1,
    a1List,
    a2,
    a2List,
    a3List,
    banner,
  } = bookkeepingDrawerConst;
  return {
    mobileTitle: <Text {...titleStyles}>{mobileTitle}</Text>,
    q1: <Text {...questionStyles(isMobile, true)}>{q1}</Text>,
    q2: <Text {...questionStyles(isMobile)}>{q2}</Text>,
    q3: <Text {...questionStyles(isMobile)}>{q3}</Text>,
    a1: (
      <Stack {...answerStyles(isMobile)}>
        <Text>{a1}</Text>
        <List {...checkListStyles(isMobile)} spacing={1}>
          {a1List.map((item) => (
            <ListItem key={item.key} {...checkListItemStyles}>
              <Box {...checkedListItemIconContainerStyles}>
                <IconCheck w={16} h={16} color="#257ED0" />
              </Box>

              <Box {...checkListItemTextContainerStyles}>{item.text}</Box>
            </ListItem>
          ))}
        </List>
      </Stack>
    ),
    a2: (
      <Stack {...answerStyles(isMobile)}>
        <Text>{a2}</Text>
        <OrderedList {...{ ...orderedListStyles(isMobile), mb: isMobile && '0 !important' }}>
          {a2List.map((item) => (
            <ListItem key={item.key}>{item.text}</ListItem>
          ))}
        </OrderedList>
      </Stack>
    ),
    a3: (
      <Stack {...answerStyles(isMobile)}>
        <UnorderedList marginInlineStart="1.5em">
          {a3List.map((item) => (
            <ListItem key={item.key}>{item.text}</ListItem>
          ))}
        </UnorderedList>
      </Stack>
    ),
    banner: (
      <HStack {...{ ...bannerContainerStyles, mt: !isMobile && '48px !important' }}>
        <IconLightBulb w={24} h={24} color="#3A4B5B" />
        <Box {...bannerMessageStyles}>
          <Text {...bannerTitleStyles}>{banner.boldedText}</Text>
          <Text>{banner.description}</Text>
        </Box>
      </HStack>
    ),
  };
};
