const formLabelStyles = {
  fontWeight: 'normal',
  color: 'brand.neutral.700',
  _invalid: { color: 'red.800AA' },
};

export const xsFormLabelStyles = {
  fontSize: 'xs',
  lineHeight: '20px',
  mr: '0',
  ...formLabelStyles,
};

export const formInputStyles = {
  size: 'lg',
};

export const formErrorStyles = {
  fontSize: 'xs',
  lineHeight: '20px',
  ml: '18px', // instead of 16px in the Figma, 18 aligns better with the input/placeholder
  mt: 1,
  color: 'red.800AA',
};

export const labelTextStyles = {
  textStyle: 'headline-sm',
  mt: '24px',
  mb: '8px',
  color: 'brand.neutral.900',
};

export const valueTextStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.900',
};

export const drawerFooterStyles = {
  boxShadow: 'none',
  border: '1px solid',
  borderColor: 'brand.darkBlue.200',
};
