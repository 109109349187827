/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Text, Spacer } from '@chakra-ui/react';
import useProperties from '@features/Property/useProperties';

const PropertyCardItem = ({ propertyId, unitId }) => {
  const { propertiesByIdForDropdownById } = useProperties();

  if (!propertyId) {
    return null;
  }

  const selectedProperty = propertiesByIdForDropdownById[propertyId];

  const selectedUnit = unitId ? propertiesByIdForDropdownById[`${propertyId}-${unitId}`] : null;

  return (
    <Box display="flex" mt={1}>
      <Text textStyle="xs">Property</Text>
      <Spacer />
      <Text textStyle="sm" flex={2} textAlign="right">
        {selectedUnit ? `${selectedProperty.name} · ${selectedUnit.name}` : selectedProperty.name}
      </Text>
    </Box>
  );
};

export default PropertyCardItem;
