// @flow
export function buildInitialYearCheckboxOptions(): { [key: number]: boolean } {
  const DEPTH_OF_YEAR = 3;

  const currentYear = new Date().getFullYear();

  const checkboxOptions = {};

  checkboxOptions[currentYear] = true;

  for (let i = 1; i < DEPTH_OF_YEAR; i += 1) {
    const year = currentYear - i;
    checkboxOptions[year] = i <= 1;
  }

  return checkboxOptions;
}
