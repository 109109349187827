// @flow
import { orderBy } from 'lodash';
import type {
  KpiByProperty,
  KpiByPropertyAggregate,
  PresentationKPIsByProperty,
  PresentationKPIsTab,
} from '../types';
import type { Property } from '../../Properties/types';

import { KPIs } from '../models';
import type { Data, PresentationData } from '../types/Data';

function getPercentageDifference(maxNumber, comparableNumber): number {
  return 100 / (maxNumber / comparableNumber);
}

function buildPresentationData(data: Data[], maxValue: number): PresentationData[] {
  const dataHasDate = data.filter((i) => i.date);

  const presentationData: PresentationData[] = [];

  data.forEach((dataItem) => {
    const roundedValue = Math.abs(Math.round(dataItem.value)); // In case of negative remove ABS
    const hasTheMaxValue = roundedValue === maxValue;

    if (hasTheMaxValue && roundedValue === 0) {
      presentationData.push({
        ...dataItem,
        value: roundedValue,
        amount: dataItem.value,
        percentageValue: 0,
      });
    } else {
      presentationData.push({
        ...dataItem,
        value: roundedValue,
        amount: dataItem.value,
        percentageValue: hasTheMaxValue ? 100 : getPercentageDifference(maxValue, roundedValue),
      });
    }
  });

  if (dataHasDate.length) {
    return orderBy(presentationData, 'date', ['desc']);
  }

  return presentationData;
}

function getAllDataItems(currentTab): Data[] {
  return [...currentTab.data].map((kpiData) => kpiData.data).flat(1);
}

function buildPresentationKPIsByProperty(
  kpisByProperty: KpiByProperty[],
  properties: Property[],
  kpiKey: string
): PresentationKPIsByProperty[] {
  const foundKpi = kpisByProperty.find((tab) => tab.name === kpiKey);

  if (!foundKpi) {
    throw new Error(`Cannot build tab for KPI: ${kpiKey}. Could not find KPI name (?)`);
  }

  const allDataItems = getAllDataItems(foundKpi);

  const maxValue = Math.max(
    ...allDataItems.map((dataItem) => {
      // TODO: Confirm if negative values are valid
      const absoluteValue = Math.abs(dataItem.value);

      return Math.round(absoluteValue);
    })
  );

  return foundKpi.data.map((kpiData, index) => {
    const foundKpiPropertyId = kpiData?.propertyId;

    const foundKpiProperty = properties.find((property) => property?.id === foundKpiPropertyId);

    if (!foundKpiProperty) {
      throw new Error(`Cannot build tab for KPI: ${kpiKey}. Could not find property.`);
    }

    // TODO: Find a better way to build data for Asset Tab
    const presentationData = buildPresentationData(kpiData.data, maxValue);

    return {
      data: presentationData,
      property: foundKpiProperty,
      order: index,
    };
  });
}

function buildPresentationKPIsByPropertyAggregate(
  kpis: KpiByPropertyAggregate[],
  kpiKey: string
): PresentationKPIsByProperty[] {
  const currentTab = kpis.find((tab) => tab.name === kpiKey);

  if (!currentTab) {
    throw new Error(`Cannot build tab for KPI: ${kpiKey}. Could not find it.`);
  }
  const aggregatedDataArray = [];
  const aggregatedData = currentTab.data;
  const maxValue = Math.max(...aggregatedData.data.map((dataItem) => Math.round(dataItem.value)));
  aggregatedDataArray.push(aggregatedData);

  return aggregatedDataArray.map((kpiData, index) => {
    return {
      data: buildPresentationData(kpiData.data, maxValue),
      property: { name: 'All Properties (Aggregate)', type: 'Generic', adress: '' },
      order: index,
    };
  });
}

export function buildPresentationKPIsTabs(
  data: { kpi: { byProperty: KpiByProperty[], byPropertyAggregate: KpiByPropertyAggregate[] } },
  properties: Property[]
): PresentationKPIsTab[] {
  const kpis = KPIs;

  const kpiKeys = Object.keys(kpis);

  const presentationKPIsTabs: PresentationKPIsTab[] = [];

  const kpisByProperty = data.kpi.byProperty;

  const kpisByPropertyAggregate = data.kpi.byPropertyAggregate;

  kpiKeys.forEach((kpiKey) => {
    const presentationKPIsByProperty = buildPresentationKPIsByProperty(
      kpisByProperty,
      properties,
      kpiKey
    );

    const presentationKPIsByPropertyAggregate = buildPresentationKPIsByPropertyAggregate(
      kpisByPropertyAggregate,
      kpiKey
    );

    const presentationKPI: PresentationKPIsTab = {
      tabName: kpis[kpiKey].name,
      order: kpis[kpiKey].order,
      kpisByProperty: presentationKPIsByProperty,
      kpisByPropertyAggregate: presentationKPIsByPropertyAggregate,
    };

    presentationKPIsTabs.push(presentationKPI);
  });

  return presentationKPIsTabs;
}
