import React, { useContext } from 'react';
import { BaselaneButton } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserContext from '@contexts/UserContext';

type InstantQuoteButtonProps = {
  btnVariant?: string,
  palette?: String,
  btnSize: String,
  btnText: String,
};

const InstantQuoteButton = ({ palette, btnVariant, btnSize, btnText }: InstantQuoteButtonProps) => {
  const { user } = useContext(UserContext);
  const url =
    'https://www.simplist.com/landing/baselane?utm_source=Baselane&utm_medium=website&utm_campaign=loans_page';

  const sendGetQuoteSegmentEvent = () => {
    sendSegmentEvent('Loans_quote_started', {
      landlord_uuid: user?.id,
      title: document.title,
    });
  };

  const onBtnClick = () => {
    sendGetQuoteSegmentEvent();
    setTimeout(() => {
      window.open(url, '_blank', 'noopener');
    });
  };

  return (
    <BaselaneButton variant={btnVariant} palette={palette} size={btnSize} onClick={onBtnClick}>
      {btnText || 'Get an Instant Quote Now'}
    </BaselaneButton>
  );
};
InstantQuoteButton.defaultProps = {
  btnVariant: 'primary',
  palette: 'tonal',
};
export default InstantQuoteButton;
