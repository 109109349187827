import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { ChakraProvider, useDisclosure, useToast } from '@chakra-ui/react';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import getBreakPoints from '@core/utils/getBreakPoints';
import habitatTheme from '@core/themeHabitat';
import {
  ADD_PAYEE,
  GET_PAYEES,
  GET_BANK_ACCOUNTS_ACTIVE,
  INITIATE_PAYEE_TRANSFER,
  GET_TRANSFERS,
  GET_TRANSACTIONS_SUMMARY,
  GET_TRANSACTIONS,
  GET_PAYEE,
  GET_SCHEDULED_PAYMENTS,
} from '@core/apollo/queries';
import { BaselaneDrawer, UnsavedChangesAlert } from '@shared/components';
import { newPayeeObject, setNewPayeeFormValuesHelper } from './helpers/addPayeeHelpers';
import { sendSegmentEvents } from './helpers/segmentEvents';
import Body from './components/Body';
import Footer from './components/Footer';
import {
  getFormInitialValues as getFundsFormInitialValues,
  transformRecipientPaymentMethods,
  isSameDay,
  steps,
} from './helpers/formHelpers';
import { handleFundsValidation } from './helpers/validation';
import { sendFundsMutationInput } from './helpers/sendFundsSubmitHelpers';
import {
  getMutationName,
  setPaymentMethodMutationValues,
  setFormValuesHelper,
} from './helpers/addOrUpdatePaymentMethodHelpers';
import {
  useRecipient,
  useUpdateRecipient,
  useSelectedPaymentMethod,
  useSetSelectedPaymentMethod,
  useSetIsSameDayPayment,
  useCurrentStep,
  useSetIsCurrentStepDisabled,
  useSetCurrentStep,
  useSetIsCurrentStepLoading,
  useSetTransferType,
  useServerRecipient,
  useUpdateServerRecipient,
} from './sendFundsStore';
import { GET_BANK_SUMMARY } from '../../../../components/NativeBankingPage/queries';
import useAddRecurringPayment from './components/FormSteps/PaymentDetailsStep/recurring-payment/useAddRecurringPayment';
import useValidDates from '../../../../hooks/useValidDates';
import useScheduledPayments from '../ScheduledPayments/helpers/useScheduledPayments';

const SendFunds = () => {
  const { isMax576 } = getBreakPoints();
  const navigate = useNavigate();
  const location = useLocation();
  const { recipientId } = useParams();
  const { pathname, search } = location ?? {};
  const { startDate, endDate } = useValidDates();

  // values from zustand store
  const recipient = useRecipient();
  const updateRecipient = useUpdateRecipient();
  const serverRecipient = useServerRecipient();
  const updateServerRecipient = useUpdateServerRecipient();
  const selectedPaymentMethod = useSelectedPaymentMethod();
  const setSelectedPaymentMethod = useSetSelectedPaymentMethod();
  const setIsSameDayPayment = useSetIsSameDayPayment();
  const currentStep = useCurrentStep();
  const setIsCurrentStepDisabled = useSetIsCurrentStepDisabled();
  const setCurrentStep = useSetCurrentStep();
  const setIsCurrentStepLoading = useSetIsCurrentStepLoading();
  const setTransferType = useSetTransferType();

  // Unsaved Alert State
  const { isOpen: isDrawerAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  // Every transaction needs a unique idempotency key. This is used to prevent duplicate transactions.
  // It changes every time a transaction finishes.
  const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());
  const { submitForm } = useAddRecurringPayment(xIdempotencyKey);

  const { bankAccountsById } = useBankAccountsActive({
    filterBySubType: ['checking'],
  });
  // Toast
  const toast = useToast();
  const showErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleResetStore = () => {
    setIsCurrentStepLoading(false);
    setCurrentStep(steps.SELECT_RECIPIENT);
    updateRecipient(undefined);
    setSelectedPaymentMethod('');
  };
  const handleMakeAnotherTransfer = () => {
    setXIdempotencyKey(uuidv4());
    handleResetStore();
  };
  const handleDrawerClose = ({ dirty = false, resetForm }) => {
    if (dirty) {
      onAlertOpen();
    } else {
      resetForm();
      handleResetStore();
      const previousRoute = pathname.slice(0, pathname.lastIndexOf('/'));
      navigate({ pathname: previousRoute, search });
      setXIdempotencyKey(uuidv4());
    }
  };
  const handleAlertContinue = ({ resetForm }) => {
    onAlertClose();
    handleDrawerClose({ resetForm });
  };

  const [initiatePayeeTransfer] = useMutation(INITIATE_PAYEE_TRANSFER, {
    refetchQueries: [
      GET_TRANSFERS,
      GET_TRANSACTIONS_SUMMARY,
      GET_TRANSACTIONS,
      GET_PAYEES,
      GET_SCHEDULED_PAYMENTS,
      GET_BANK_SUMMARY,
      GET_BANK_ACCOUNTS_ACTIVE,
    ],
  });

  const { refetch: paymentsDataRefetch } = useScheduledPayments();
  const [addPayee] = useMutation(ADD_PAYEE, {
    refetchQueries: [GET_PAYEES],
  });
  const showAddRecipientErrorToast = (message) =>
    toast({
      position: 'bottom-left',
      description: 'Error saving new recipient.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleAddPayee = async (values, setFieldValue, setErrors) => {
    setIsCurrentStepLoading(true);

    try {
      const res = await addPayee({
        variables: {
          input: newPayeeObject(values),
        },
      });

      setNewPayeeFormValuesHelper(
        res,
        setFieldValue,
        updateRecipient,
        setIsCurrentStepLoading,
        setIsCurrentStepDisabled,
        setSelectedPaymentMethod,
        setCurrentStep,
        currentStep
      );
      setIsCurrentStepLoading(false);

      if (res.errors && res.errors[0].message.includes('already exists.')) {
        // Handling specific logical error after successful response
        setErrors({
          name: `Recipient already exists, please use a different name.`,
        });
        showAddRecipientErrorToast(res.errors[0].message);
      }
    } catch (err) {
      setIsCurrentStepLoading(false);

      const { message } = err;
      if (message.includes('already exists.')) {
        // Handling error due to rejected promise
        setErrors({
          name: `Recipient already exists, please use a different name.`,
        });
        showAddRecipientErrorToast('Recipient already exists, please use a different name.');
      } else {
        // Handle other errors
        showAddRecipientErrorToast('An unexpected error occurred.');
      }
    }
  };

  const handleFundsSubmit = (values, { resetForm, isValid }) => {
    setTransferType(values?.paymentMethodType);
    setIsCurrentStepLoading(true);
    setIsSameDayPayment(isSameDay(values?.sendOnDate));
    initiatePayeeTransfer({
      context: {
        headers: {
          'x-idempotency-key': xIdempotencyKey,
        },
      },
      variables: {
        input: sendFundsMutationInput(values, bankAccountsById),
      },
    })
      .then(
        (dataResponse) => {
          setIsCurrentStepLoading(false);
          setSelectedPaymentMethod({});

          if (dataResponse?.data && !dataResponse?.errors) {
            if (values?.scheduledPaymentType === 'one-time')
              setCurrentStep(steps.ONE_TIME_SUCCESS_SCREEN);
            else {
              setCurrentStep(steps.RECCURING_SUCCESS_SCREEN);
            }
          } else {
            setCurrentStep(steps.SELECT_RECIPIENT);
            showErrorToast();
          }
        },
        (err) => {
          console.error(err);
          setCurrentStep(steps.SELECT_RECIPIENT);
          showErrorToast();
        }
      )
      .finally(() => {
        resetForm();
        setXIdempotencyKey(uuidv4());
        paymentsDataRefetch();
      });
  };

  const [addOrUpdatePaymentMethod] = useMutation(getMutationName(selectedPaymentMethod), {
    refetchQueries: [GET_PAYEE],
  });

  function handleUpdatePaymentMethodFunction(values, setFieldValue, setTouched) {
    setIsCurrentStepLoading(true);
    addOrUpdatePaymentMethod({
      variables: {
        payeeId: parseFloat(recipient.id),
        input: setPaymentMethodMutationValues(values, selectedPaymentMethod),
      },
      update: (cache, { data, errors }) => {
        cache.evict({
          id: cache.identify({ __typename: 'Payee', id: recipient.id }),
          fieldName: 'paymentMethods',
        });
      },
    })
      .then((res) => {
        setFormValuesHelper(
          selectedPaymentMethod,
          res,
          setFieldValue,
          startDate,
          endDate,
          updateRecipient,
          setSelectedPaymentMethod,
          setIsCurrentStepLoading,
          setCurrentStep,
          currentStep,
          recipient,
          serverRecipient,
          updateServerRecipient,
          setTouched
        );
      })
      .catch((err) => {
        console.log('err', err);
        showErrorToast();
        setIsCurrentStepLoading(false);
      });
  }

  const handleFormSubmit = (
    values,
    { resetForm, isValid, setFieldValue, setValues, setErrors, setTouched }
  ) => {
    if (currentStep === steps.ADD_RECIPIENT)
      return handleAddPayee(values, setFieldValue, setErrors);
    if (currentStep === steps.UPDATE_OR_ADD_PAYMENT_METHOD)
      return handleUpdatePaymentMethodFunction(values, setFieldValue, setTouched);
    return handleFundsSubmit(values, { resetForm });
  };

  const handleFormValidation = (values) => {
    return handleFundsValidation({ values, accountsMap: bankAccountsById, currentStep });
  };

  // Get payee
  const [getPayee] = useLazyQuery(GET_PAYEE, {
    onCompleted: (res) => {
      formikRef?.current?.setFieldValue('payeeId', recipientId);
      updateRecipient(transformRecipientPaymentMethods(res.payee));
    },
  });

  useEffect(() => {
    // Segment events
    sendSegmentEvents(pathname);
    if (pathname.includes('transfers_payments') && recipientId) {
      getPayee({
        variables: { id: recipientId },
      });
    }
  }, [pathname]);

  const addRecurringPayment = (values, helpers) => {
    setTransferType(values?.paymentMethodType);
    setIsCurrentStepLoading(true);
    submitForm(values)
      .then(() => {
        if (values?.scheduledPaymentType === 'one-time')
          setCurrentStep(steps.ONE_TIME_SUCCESS_SCREEN);
        else {
          setCurrentStep(steps.RECCURING_SUCCESS_SCREEN);
        }
        helpers.resetForm();
      })
      .catch((err) => {
        showErrorToast();
        console.error('Error submitting the form', err);
        setCurrentStep(steps.SELECT_RECIPIENT);
      })
      .finally(() => {
        setIsCurrentStepLoading(false);
        setXIdempotencyKey(uuidv4());
        paymentsDataRefetch();
      });
  };
  const formikRef = useRef(null);

  const initialValues = useMemo(() => getFundsFormInitialValues({ startDate, endDate }), [
    startDate.toString(),
    endDate.toString(),
    getFundsFormInitialValues,
  ]);

  const onSubmit = (values, helpers, setFieldValue) => {
    if (values.scheduledPaymentType === 'recurring') {
      return addRecurringPayment(values, helpers);
    }
    return handleFormSubmit(values, helpers);
  };
  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={formikRef}
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validate={handleFormValidation}
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          const { dirty, resetForm } = formikProps;

          return (
            <>
              <BaselaneDrawer
                isOpen
                size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
                title="Send money"
                footer={
                  <Footer
                    {...{
                      handleMakeAnotherTransfer,
                      handleDrawerClose,
                    }}
                  />
                }
                closeEvent={() => handleDrawerClose({ dirty, resetForm })}
                onOverlayClick={() => handleDrawerClose({ dirty, resetForm })}
                closeOnOverlayClick={false}
                newDesignDrawer
              >
                <Body />
              </BaselaneDrawer>
              <UnsavedChangesAlert
                {...{
                  isDrawerAlertOpen,
                  onAlertClose,
                  onAlertContinue: () => {
                    handleAlertContinue({ resetForm });
                  },
                }}
              />
            </>
          );
        }}
      </Formik>
    </ChakraProvider>
  );
};

export default SendFunds;
