import React, { useContext, useRef, useState } from 'react';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import { FormControl, Text } from '@chakra-ui/react';

import {
  BaselaneAlert,
  BaselaneButton,
  BaselaneInput,
  BaselaneFormLabel,
  BaselaneFormErrorMessage,
  BaselaneFormHelperText,
} from '@shared/components';
import useTwoFactor from '@shared/components/TwoFactorVerificationPopUp/hooks/useTwoFactor';
import { formatNumbers } from '@shared/components/BaselaneKYCForm/helpers/kycForm.helpers';
import UserContext from '@contexts/UserContext';
import useUserSignUpExperiment from '@core/hooks/useUserSignUpExperiment';
import { phoneMaskWithCountryCode } from '@core/utils/masks';
import TwoFactorPhonePopUp from './TwoFactorPhonePopUp';

type PhoneVerificationProps = {
  onCloseBtnClick: Function,
  onOTPSuccess: Function,
};

/**
 * 2024-07_sign_up_page_experiment
 * Two alerts for users to enter a phone number + complete the OTP
 */
const PhoneVerification = ({ onCloseBtnClick, onOTPSuccess }: PhoneVerificationProps) => {
  const { refetchUser } = useContext(UserContext);
  const { requiresPhoneNumber } = useUserSignUpExperiment();

  const formikRef = useRef(null);
  const cancelRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
  const [phoneNumberIsNotVoip, setPhoneNumberIsNotVoip] = useState(false);
  const [customPhoneError, setCustomPhoneError] = useState(null);
  const [submittedPhoneNumber, setSubmittedPhoneNumber] = useState(null);

  const initialFormValues = { phone: '' };

  const handleSendTextSuccess = () => {
    setOTPErrorCode(false);
    onOTPPopupOpen();
    setPhoneNumberIsNotVoip(true);
  };

  const handleSendTextFail = (error) => {
    setCustomPhoneError(error);
  };

  const handleVerifyOtpSuccess = () => {
    setPhoneNumberVerified(true);
    refetchUser().then(() => {
      onOTPSuccess(submittedPhoneNumber);
    });
  };

  const handleValidation = (values) => {
    const { phone } = values;
    const errors = {};

    const filterPhoneNumber = phone?.replace(/[^0-9]/g, '');

    if (filterPhoneNumber === '' || filterPhoneNumber?.length < 11) {
      errors.phone = 'Please enter phone number';
      setOTPErrorCode(false);
    } else if (OTPErrorCode === 400) {
      errors.phone = customPhoneError;
    }

    return errors;
  };

  const { states, stateFunctions } = useTwoFactor(true);
  const { OTPErrorCode } = states;
  const { onOTPPopupOpen, setOTPErrorCode, handleSendText, handleVerifyOtp } = stateFunctions;
  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP: () => handleSendText(submittedPhoneNumber, true, handleSendTextSuccess),
    phoneNumber: formikRef?.current?.values.phone,
    handleVerifyOnClick: (otpCode) =>
      handleVerifyOtp({ recipient: submittedPhoneNumber, code: otpCode }, handleVerifyOtpSuccess),
  };

  const handleFormSubmit = (e) => {
    const { phone } = e;
    const formattedPhoneNumber = phone
      ? formatNumbers(phone).numberWithoutSpaces
      : submittedPhoneNumber;
    setSubmittedPhoneNumber(formattedPhoneNumber);

    if (phoneNumberVerified && phoneNumberIsNotVoip) {
      setIsLoading(true);
      refetchUser().then(() => {
        setIsLoading(false);
        onCloseBtnClick();
      });
    } else {
      handleSendText(formattedPhoneNumber, false, handleSendTextSuccess, handleSendTextFail);
    }
  };

  return (
    <>
      <TwoFactorPhonePopUp {...twoFactorVerificationProps} />

      {requiresPhoneNumber && (
        <Formik
          innerRef={formikRef}
          initialValues={initialFormValues}
          validate={(values) => handleValidation(values)}
          onSubmit={handleFormSubmit}
          validateOnChange
          validateOnMount
        >
          {({ values, handleSubmit, isValid, handleBlur, handleChange, errors, touched }) => (
            <BaselaneAlert
              isOpen
              onClose={onCloseBtnClick}
              leastDestructiveRef={cancelRef}
              isCentered
              containerStyles={{ maxW: '416px', p: 3 }}
              header={<Text textStyle="headline-lg">Verify your phone number to continue</Text>}
              body={
                <FormControl
                  isInvalid={(errors.phone && touched.phone) || customPhoneError}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                >
                  <BaselaneFormLabel htmlFor="phone">
                    Mobile phone (no <abbr title="Voice Over Internet Protocol">VOIP</abbr>)
                  </BaselaneFormLabel>
                  <BaselaneInput
                    as={MaskedInput}
                    mask={phoneMaskWithCountryCode('1')}
                    id="phone"
                    name="phone"
                    value={values.phone}
                    onChange={(event) => {
                      if (customPhoneError) {
                        setCustomPhoneError(null);
                      }
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    placeholder="+1 (***) *** ****"
                    pattern="\d*"
                    inputMode="decimal"
                    autoFocus
                  />
                  <BaselaneFormErrorMessage
                    isInvalid={(errors.phone && touched.phone) || customPhoneError}
                    mt={0.75}
                  >
                    <Text textStyle="xs">{errors.phone || customPhoneError}</Text>
                  </BaselaneFormErrorMessage>
                  <BaselaneFormHelperText mb={0}>
                    We&apos;ll send you a verification code.
                  </BaselaneFormHelperText>
                </FormControl>
              }
              footer={
                <BaselaneButton
                  variant="filled"
                  palette="primary"
                  size="md"
                  onClick={handleSubmit}
                  isDisabled={!isValid}
                  isLoading={isLoading}
                  styles={{ w: '100%' }}
                >
                  Send code
                </BaselaneButton>
              }
              size="2xl"
              showCloseButton
            />
          )}
        </Formik>
      )}
    </>
  );
};

export default PhoneVerification;
