import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { HStack, Box, Text, useToast } from '@chakra-ui/react';
import { Icon16Check, Icon16Copy, Icon16ShareAndroid } from '@icons/16px';
import BaselaneButton from '../BaselaneButton';
import { shareLinkStyles } from './styles/baselaneShareLink.styles';

type BaselaneShareLinkProps = {
  link: String,
  text?: Object,
  type?: String,
  buttonProps?: Object,
  showButtonOnly?: Boolean,
  handleButtonClickOnMobile?: Function,
  handleButtonClickOnDesktop?: Function,
  sendMobileSegmentEvent?: Function,
  sendDesktopSegmentEvent?: Function,
};

const BaselaneShareLink = ({
  link,
  text = { default: '', copied: '' },
  type = 'share',
  buttonProps,
  showButtonOnly = false,
  handleButtonClickOnMobile,
  handleButtonClickOnDesktop,
  sendMobileSegmentEvent,
  sendDesktopSegmentEvent,
}: BaselaneShareLinkProps) => {
  const [copied, setCopied] = useState(false);

  const toast = useToast();
  const successToast = () =>
    toast({
      description: 'Link copied',
      status: 'success',
      duration: '3000',
      isClosable: true,
      position: 'bottom-left',
    });

  const handleCopyShareLink = () => {
    if (link && navigator && navigator.clipboard) {
      navigator.clipboard.writeText(link).then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
        successToast();
      });
    }
  };

  const handleShareLinkClick = () => {
    if (isMobile) {
      if (handleButtonClickOnMobile) {
        handleButtonClickOnMobile();
      } else {
        handleCopyShareLink();
      }

      if (sendMobileSegmentEvent) {
        sendMobileSegmentEvent();
      }
    }

    if (!isMobile) {
      if (handleButtonClickOnDesktop) {
        handleButtonClickOnDesktop();
      } else {
        handleCopyShareLink();
      }

      if (sendDesktopSegmentEvent) {
        sendDesktopSegmentEvent();
      }
    }
  };

  const defaultLeftIcon = copied ? (
    <Icon16Check w={20} h={20} color={buttonProps.icon.color} />
  ) : (
    <Icon16Copy w={20} h={20} color={buttonProps.icon.color} />
  );

  const shareIcon = isMobile ? (
    <Icon16ShareAndroid color={buttonProps.icon.color} />
  ) : (
    defaultLeftIcon
  );
  const copyIcon = defaultLeftIcon;

  return showButtonOnly ? (
    <BaselaneButton
      leftIcon={type === 'share' ? shareIcon : copyIcon}
      onClick={handleShareLinkClick}
      {...buttonProps}
    >
      {copied ? text.copied : text.default}
    </BaselaneButton>
  ) : (
    <Box {...shareLinkStyles.container}>
      <HStack {...shareLinkStyles.innerContainer}>
        <Text {...shareLinkStyles.text}>{link}</Text>
        <BaselaneButton
          size={buttonProps.size}
          leftIcon={type === 'share' ? shareIcon : copyIcon}
          onClick={handleShareLinkClick}
          styles={{ flexBasis: '160px' }}
          {...buttonProps}
        >
          {copied ? text.copied : text.default}
        </BaselaneButton>
      </HStack>
    </Box>
  );
};

BaselaneShareLink.defaultProps = {
  handleButtonClickOnMobile: null,
  handleButtonClickOnDesktop: null,
  sendMobileSegmentEvent: null,
  sendDesktopSegmentEvent: null,
  text: { default: '', copied: '' },
  type: 'share',
  buttonProps: { size: 'lg', variant: 'filled', palette: 'primary', icon: { color: '#fff' } },
  showButtonOnly: false,
};

export default BaselaneShareLink;
