import { gql, useMutation } from '@apollo/client';

const useGenerateRecurringPayment = () => {
  const GENERATE_RECURRING_PAYMENT = gql`
    mutation GenerateRecurringPayment($input: GenerateRecurringPatternInput!) {
      generateRecurringPayment(input: $input) {
        startDate
        endDate
        recurrencePattern {
          type
          interval
          monthlyByDate
          lastDayOfMonth
          daysOfMonth
          daysOfWeek
          numberOfPayments
        }
        transfers {
          transferDate
          amount
          actualDate
          isHoliday
          isWeekend
          state
        }
        nextPaymentDate
        lastPaymentDate
      }
    }
  `;

  const [generateRecurringPayment, { data, loading, error }] = useMutation(
    GENERATE_RECURRING_PAYMENT
  );

  return {
    generateRecurringPayment,
    data,
    loading,
    error,
  };
};

export default useGenerateRecurringPayment;
