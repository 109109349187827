import sendSegmentEvent from '@core/utils/sendSegmentEvent';

export const updateMultipleTrxSuccessEvent = ({
  uid,
  trxCount,
  merchant,
  category,
  hasPropertyChanged,
  hasNotesChanged,
  url,
  title,
}) => {
  sendSegmentEvent('edit_multiple_transaction', {
    landlord_uuid: uid,
    transaction_count: trxCount,
    merchant,
    category,
    property: hasPropertyChanged,
    notes: hasNotesChanged,
    url,
    title,
    orderId: `'edit_multiple_transaction_${uid}`,
  });
};

export const getEditedMultipleTrxSegmentData = ({
  values,
  initialValues,
  uid,
  trxCount,
  categoryMap,
  url,
  title,
}) => {
  const {
    merchant: editedMerchant,
    categoryId: editedCategoryId,
    propertyId: editedPropertyId,
    unitId: editedUnitId,
    notes: editedNotes,
  } = values;

  const {
    merchant: initialMerchant,
    categoryId: initialCategoryId,
    propertyId: initialPropertyId,
    unitId: initialUnitId,
    notes: initialNotes,
  } = initialValues;
  return {
    uid,
    trxCount,
    merchant: editedMerchant === initialMerchant ? null : editedMerchant,
    category: editedCategoryId === initialCategoryId ? null : categoryMap[editedCategoryId],
    hasPropertyChanged: editedPropertyId !== initialPropertyId || editedUnitId !== initialUnitId,
    hasNotesChanged: editedNotes !== initialNotes,
    url,
    title,
  };
};
