import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Box, Image, Stack, Text } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';

import { BaselaneButton } from '@shared/components';
import CenterContentWrapper from '@shared/layouts/CenterContentWrapper';
import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import { IconArrowRightTwo } from '@icons';
import { ANALYTICS_REPORTING, UNIFIED_BA_AUTOTAG } from '@routes';
import userContext from '@contexts/UserContext';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import { handleFinishLater } from '@core/components/OnboardingTriage/helpers/onboardingFunctions.helpers';
import {
  buttonStyles,
  contentContainerStyles,
  descriptionTextStyles,
  footerContainerStyles,
  leftSuccessBtnStyles,
  subTextStyles,
  titleTextStyles,
} from '@core/components/OnboardingTriage/styles/success.styles';

import HeaderContent from '../HeaderContent';
import bookkeepingSuccess from '../assets/bookkeepingSuccess.svg';
import cardCheck from '../assets/cardCheck.svg';

const BookkeepingAnalyticsSuccess = () => {
  const navigate = useNavigate();
  const { user, refetchUser } = useContext(userContext);
  const [updateUser] = useMutation(UPDATE_USER);

  return (
    <HeaderNavWrapper
      hideBackButton
      headerContent={<HeaderContent onboardingCompleted={user?.onboardingCompleted} />}
      // TODO: to be removed once flow is refactored to not use device detect
      isMobile={isMobile}
    >
      <CenterContentWrapper>
        <Stack {...contentContainerStyles()}>
          <Stack>
            <Box>
              <Image src={cardCheck} alt="card icon" h="48px" />
            </Box>
            <Text {...subTextStyles}>Accounts linked</Text>
            <Text {...titleTextStyles}>Try our auto-tagging feature</Text>
            <Text {...descriptionTextStyles}>
              Save countless hours by letting us auto-tag all your bank transactions to a property
            </Text>
          </Stack>
          <Image src={bookkeepingSuccess} alt="success" h={isMobile ? '160px' : '250px'} />
        </Stack>
        <Stack {...footerContainerStyles}>
          <BaselaneButton
            id="explore-button"
            variant="tonal"
            palette="primary"
            onClick={() => {
              handleFinishLater({
                user,
                refetchUser,
                updateUser,
                handleRouteChange: () => {
                  navigate(ANALYTICS_REPORTING);
                },
              });
            }}
            styles={{ ...buttonStyles, ...leftSuccessBtnStyles }}
          >
            Explore Analytics & Reporting
          </BaselaneButton>
          <BaselaneButton
            id="setup-autotagging-button"
            variant="filled"
            palette="primary"
            rightIcon={<IconArrowRightTwo />}
            onClick={() => {
              handleFinishLater({
                user,
                refetchUser,
                updateUser,
                handleRouteChange: () => {
                  navigate(UNIFIED_BA_AUTOTAG);
                },
                removeSelection: false,
              });
            }}
            styles={buttonStyles}
          >
            Set Up Auto-tagging
          </BaselaneButton>
        </Stack>
      </CenterContentWrapper>
    </HeaderNavWrapper>
  );
};

export default BookkeepingAnalyticsSuccess;
