import React, { useState, useRef, useEffect, useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';

import {
  BaselaneDivider,
  AgreeToTerms,
  BaselaneDrawer,
  BaselaneButton,
  TwoFactorVerificationPopUp,
  BaselaneAlertNew,
} from '@shared/components';
import BanksContext from '@contexts/BanksContext';
import { Icon16ChevronLeft, Icon16Info } from '@icons/16px';
import { CREATE_BASELANE_SUBACCOUNT } from '@core/components/NativeBankingPage/queries';
import getBreakPoints from '@core/utils/getBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import AccountFieldName from './AccountFieldName';
import AccountAutoTag from './AccountAutoTag';
import useOtpVerification from '../hooks/useOtpVerification';

type CreateAccountProps = {
  backEvent: Function,
  successEvent: Function,
  setIsDirty: Function,
  accountOption: 'checking' | 'savings',
  selectedBankAccount: Object | undefined,
};

const CreateAccount = ({
  backEvent,
  successEvent,
  setIsDirty,
  accountOption,
  selectedBankAccount,
}: CreateAccountProps) => {
  const { account, handleCreateSuccess, refetch } = useOutletContext() ?? {};
  const { phoneNumber, id, plaidItemId: itemId, bankId } = account ?? selectedBankAccount ?? {};

  const { refetchBankSummary } = useContext(BanksContext);

  const [createBaselaneSubAccount] = useMutation(CREATE_BASELANE_SUBACCOUNT);

  const formikRef = useRef(null);
  const { isMax576 } = getBreakPoints();

  const [termsChecked, setTermsChecked] = useState(false);
  const [hasSubmissionError, setSubmissionError] = useState(false);
  const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());
  const { DrawerBody, DrawerFooter } = BaselaneDrawer;

  const initialAccountValues = {
    plaidItemId: itemId,
    parentAccountId: Number(id),
    nickName: null,
    accountSubType: accountOption,
    autoTag: {
      enabled: false,
      propertyId: null,
      propertyUnitId: null,
    },
  };

  const handleValidation = (values) => {
    const errors = {};

    if (!values.nickName || values.nickName === '') {
      errors.nickName = 'Please enter a nickname.';
    }

    if (values.autoTag.enabled === true && !values.autoTag.propertyId) {
      errors.autoTag = 'Please select a property.';
    }

    return errors;
  };

  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(true);
    if (isTokenExpired) {
      handleOtpPopupOpen();
    } else {
      handleCreateBaselaneSubAccount(values, setSubmitting);
    }
  };

  /**
   * Work-around to allow dirty state to be set for parent from
   * within the Formik context below.
   */
  const handleDirty = (dirty) => {
    setTimeout(() => setIsDirty(dirty), 0);
  };

  const handleCreateBaselaneSubAccount = async (values, setSubmitting) => {
    setSubmissionError(false);
    const createAccount = await createBaselaneSubAccount({
      context: {
        headers: {
          'x-idempotency-key': xIdempotencyKey,
        },
      },
      variables: values,
    }).catch(() => {
      setSubmitting(false);
      setSubmissionError(true);
    });

    if (createAccount) {
      setSubmitting(false);
      if (createAccount.errors && createAccount.errors?.length) {
        setSubmissionError(true);
        setSubmitting(false);
      } else {
        setSubmissionError(false);
        sendSegmentEvent('baselane_banking_add_virtual_account_flow_success', {
          type: values.accountSubType,
        });
        successEvent(createAccount.data?.createBaselaneSubAccount?.id);
        refetchBankSummary();
        if (refetch) refetch();
        if (handleCreateSuccess) handleCreateSuccess();
      }
    }
  };

  const { props, isTokenExpired, handleOtpPopupOpen, handleOtpPopupClose } = useOtpVerification({
    bankId,
    phoneNumber,
    callback: formikRef?.current?.submitForm,
  });

  useEffect(() => {
    setXIdempotencyKey(uuidv4());
  }, []);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialAccountValues}
      validate={(values) => handleValidation(values)}
      onSubmit={(values, actions) => handleCreateBaselaneSubAccount(values, actions.setSubmitting)}
    >
      {({ dirty, values, isValid, isSubmitting, setSubmitting }) => {
        handleDirty(dirty);

        return (
          <>
            <TwoFactorVerificationPopUp
              {...props}
              onOTPPopupClose={() => {
                setSubmitting(false);
                handleOtpPopupClose();
              }}
            />

            <DrawerBody p={isMax576 ? 2 : 3} display="flex" flexDirection="column">
              {hasSubmissionError && (
                <Box mb={3}>
                  <BaselaneAlertNew
                    variant="danger"
                    title="Failed to create account"
                    body="Please try again, or contact support if the issue persists."
                    visual="icon"
                    iconName={Icon16Info}
                  />
                </Box>
              )}

              <AccountFieldName />
              <BaselaneDivider />
              <AccountAutoTag />
              <Box mt="auto">
                <AgreeToTerms
                  onChange={() => setTermsChecked(!termsChecked)}
                  isChecked={termsChecked}
                  isSavings={accountOption === 'savings'}
                />
              </Box>
            </DrawerBody>

            <DrawerFooter
              px={isMax576 ? 2 : 3}
              py={2}
              boxShadow="none"
              borderTop="1px solid"
              borderColor="brand.darkBlue.200"
            >
              <BaselaneButton
                size="md"
                variant="outline"
                palette="neutral"
                leftIcon={<Icon16ChevronLeft />}
                onClick={() => backEvent()}
              >
                Back
              </BaselaneButton>
              <BaselaneButton
                id="create-sub-account-button"
                size="md"
                ml="1.5"
                isFullWidth
                variant="filled"
                palette="primary"
                isDisabled={!isValid || !dirty || !termsChecked}
                isLoading={isSubmitting}
                onClick={() => handleSubmit(values, setSubmitting)}
              >
                Create account
              </BaselaneButton>
            </DrawerFooter>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateAccount;
