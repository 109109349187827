import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Stack, Spinner } from '@chakra-ui/react';
import { useShallow } from 'zustand/react/shallow';
import { useFormikContext } from 'formik';

import { Icon16ChevronRight, Icon16ChevronLeft, Icon16Check } from '@icons/16px';
import { BaselaneButton, TwoFactorVerificationPopUp } from '@shared/components';
import useCheckbookStore from '@store/Checkbook';
import useOtpVerification from './hooks/useOtpVerification';

const DrawerFooter = () => {
  const { account } = useOutletContext() ?? {};
  const { errors, values, submitForm, isSubmitting, setSubmitting } = useFormikContext();
  const [step, lastStep, firstStep, setStep] = useCheckbookStore(
    useShallow((state) => [state.step, state.lastStep, state.firstStep, state.setStep])
  );

  const isLastStep = step === lastStep;
  const continueBtnLabel = isLastStep ? 'Place order' : 'Next';
  const hasMailingError = Object.keys(errors).filter((error) => {
    return error !== 'feeFromBankAccountId' && error !== 'bankAccountId';
  });
  const continueDisabled =
    isSubmitting ||
    !values.bankAccountId ||
    (step === lastStep && errors.feeFromBankAccountId) ||
    (step === 3 && hasMailingError.length);

  const handleContinue = () => {
    setStep(1);
    if (isLastStep) handleSubmit();
  };

  const handleSubmit = () => {
    setSubmitting(true);
    if (isTokenExpired) {
      handleOtpPopupOpen();
    } else {
      submitForm();
    }
  };

  const { props, isTokenExpired, handleOtpPopupOpen, handleOtpPopupClose } = useOtpVerification({
    bankId: account?.bankId,
    phoneNumber: account?.phoneNumber,
    callback: submitForm,
  });

  const onOTPPopupClose = () => {
    setSubmitting(false);
    handleOtpPopupClose();
  };

  return (
    <>
      <Stack direction="row" w="100%">
        <BaselaneButton
          size="md"
          variant="outline"
          palette="neutral"
          leftIcon={<Icon16ChevronLeft />}
          onClick={() => setStep(-1)}
          isDisabled={step === firstStep || isSubmitting}
        >
          Back
        </BaselaneButton>
        <BaselaneButton
          size="md"
          variant="filled"
          palette="primary"
          leftIcon={isLastStep && !isSubmitting && <Icon16Check />}
          rightIcon={!isLastStep && !isSubmitting && <Icon16ChevronRight />}
          onClick={handleContinue}
          isDisabled={continueDisabled}
          isFullWidth
        >
          {isSubmitting ? <Spinner /> : continueBtnLabel}
        </BaselaneButton>
      </Stack>

      <TwoFactorVerificationPopUp {...props} onOTPPopupClose={onOTPPopupClose} />
    </>
  );
};

export default DrawerFooter;
