import React, { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Box, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import {
  BaselaneApplicationTypePopup,
  BaselaneButton,
  BaselaneButtonIcon,
} from '@shared/components';
import CreditCard from '@core/components/CreditCard';
import { IconX, IconRocket } from '@icons';
import StepIndicator from '@core/components/Dashboard/components/GuidedOnboardingTracker/components/StepIndicator';
import SlLoader from '@core/components/Loader';
import BanksContext from '@contexts/BanksContext';
import UserContext from '@contexts/UserContext';
import { NB_CARDS, UNIFIED_LB_PERSONAL_INFO, DASHBOARD, ADD_FUNDS_BANK_TRANSFER } from '@routes';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import historyStateStorage from '@core/storage/historyStateStorage';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import { getGuide, indicatorStepText } from './helpers/guides.helpers';
import { getLandlordBankingData } from './helpers/landlordBankingTracker.helpers';
import {
  trackerContainerStyles,
  trackerMessageContainerStyles,
  trackerMessageHeadingContainerStyles,
  trackerHeadingTextStyles,
  trackerMessageTextStyles,
  trackerSecondMessageTextStyles,
  trackerStepSectionHeadingTextStyles,
  trackerStepSectionMessageTextStyles,
  trackerMessageInnerContainerStyles,
  trackerSectionContainerStyles,
  creditCardStyles,
  slLoaderStyles,
  addFundsButtonStyles,
} from './styles/guidedOnboardingTracker.styles';

type GuidedOnboardingTrackerProps = {
  cashFlowRefetch: Function,
  setHasBA: Function,
};

const GuidedOnboardingTracker = ({ cashFlowRefetch, setHasBA }: GuidedOnboardingTrackerProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [updateUser] = useMutation(UPDATE_USER);

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const { user, refetchUser } = useContext(UserContext);
  const { metadata, firstName, lastName, userMetadata } = user || {};
  const { onboardingTriageSelection = null } = userMetadata?.metadata || {};

  const {
    baselaneAccounts,
    externalAccounts,
    rest,
    loading,
    oldestBaselaneBank,
    getKYCUrl,
  } = useContext(BanksContext);
  const { baselaneBankBalance = 0, transferInProgress = 0 } = rest || {};

  const { hasCompletedApplication, bankAccountNumber } = getLandlordBankingData(
    oldestBaselaneBank,
    baselaneAccounts
  );

  const landlordBankingInfo = {
    bankStatus: oldestBaselaneBank?.unitAccount?.unitApplicationStatus || null,
    hasCompletedApplication,
    // once total sum of any account is greater than 0 (positive balance)
    hasAddedFunds: baselaneBankBalance > 0,
    transferInProgress,
  };
  const rentCollectionInfo = {};
  const bookKeepingInfo = {};

  const guide = getGuide({
    onboardingTriageSelection,
    landlordBankingInfo,
    rentCollectionInfo,
    bookKeepingInfo,
  });

  const handleBaselaneApplicationPopupClick = (type) => {
    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write({ from: DASHBOARD });
      navigate(UNIFIED_LB_PERSONAL_INFO);
    }
  };

  const handleLandlordBankingCTAClick = () => {
    if (onboardingTriageSelection === 'banking') {
      if (guide.step.includes('first')) {
        if (guide.step === 'first_denied') {
          zendeskAPI('messenger', 'open');
        } else {
          if (guide.step.includes('first_awaiting_documents')) {
            getKYCUrl({ variables: { bankId: oldestBaselaneBank?.id } });
          } else {
            onBaselaneApplicationTypePopupOpen();
          }
          sendSegmentEvent('onboarding_tracker_banking_create_account', {});
        }
      } else {
        navigate(NB_CARDS);
        sendSegmentEvent('onboarding_tracker_banking_start_spending', {});
      }
    } else if (onboardingTriageSelection === 'rentCollection') {
      console.log('rent collection action');
    } else {
      console.log('bookkeeping action');
    }
  };

  const handleOnboardingTrackerClose = () => {
    updateUser({
      variables: {
        metadata: {
          ...metadata,
          onboardingTriageSelection,
        },
      },
    }).then(() => {
      refetchUser();
    });
  };

  useEffect(() => {
    // NOTE: set hasBA to true AND refetch CashFlow to update CashFlow widget in Dashboard
    if (
      onboardingTriageSelection === 'banking' &&
      hasCompletedApplication &&
      externalAccounts.length
    ) {
      setHasBA(true);
      cashFlowRefetch();
    }
  }, [externalAccounts]);

  return (
    <>
      {onboardingTriageSelection === 'banking' && (
        <Stack {...trackerContainerStyles}>
          {loading ? (
            <SlLoader className="" styles={slLoaderStyles} />
          ) : (
            <>
              <Stack {...trackerMessageContainerStyles}>
                {guide.showCloseBtn && (
                  <BaselaneButtonIcon icon={<IconX />} onClick={handleOnboardingTrackerClose} />
                )}
                <Stack {...trackerMessageInnerContainerStyles}>
                  <Stack direction="row" {...trackerMessageHeadingContainerStyles}>
                    <IconRocket
                      h={32}
                      w={32}
                      color="currentColor"
                      bgColor={isMobile ? '#FFFFFF' : '#E1F4FF'}
                      opacity="1"
                    />
                    <Text {...trackerHeadingTextStyles}>Your Guide To Landlord Banking</Text>
                  </Stack>
                  <Text {...trackerMessageTextStyles}>{guide.message}</Text>
                  {guide.secondMessage && (
                    <Text {...trackerSecondMessageTextStyles}>{guide.secondMessage}</Text>
                  )}
                </Stack>

                {/* // TODO: see what will need to be done for the other trackers */}
                <CreditCard
                  isGrayedOut={guide.step === 'first_denied'}
                  hasWallet={guide.step.includes('first')}
                  accountNumber={bankAccountNumber || ''}
                  value={baselaneBankBalance}
                  fullName={`${firstName ? ` ${firstName}` : ''}${lastName ? ` ${lastName}` : ''}`}
                  inTransfer={transferInProgress}
                  creditCardStyles={creditCardStyles}
                />
              </Stack>

              <Box {...trackerSectionContainerStyles}>
                <Box mb={1.5}>
                  <StepIndicator
                    {...{
                      isStepOneActive: guide.step.includes('first'),
                      isNextStepActive: guide.step.includes('next'),
                      isFinalStepActive: guide.step.includes('final'),
                      indicatorStatus: guide?.indicatorStatus,
                      stepText: indicatorStepText(
                        guide.step.includes('next'),
                        guide.step.includes('final'),
                        guide?.indicatorStatus
                      ),
                      stepTextHeading: isMobile ? guide.stepSectionHeading : null,
                    }}
                  />
                  {!isMobile && (
                    <Text {...trackerStepSectionHeadingTextStyles}>{guide.stepSectionHeading}</Text>
                  )}
                  <Text {...trackerStepSectionMessageTextStyles}>{guide.stepSectionMessage}</Text>
                </Box>
                {/* // TODO: update once we get info about other trackers */}
                {guide.step === 'next' ? (
                  <>
                    <BaselaneButton
                      variant="filled"
                      palette="primary"
                      size="md"
                      isFullWidth={isMobile}
                      onClick={() => {
                        navigate({
                          pathname: `${location.pathname}/${ADD_FUNDS_BANK_TRANSFER}`,
                        });
                      }}
                      {...{
                        ...addFundsButtonStyles,
                      }}
                    >
                      Add funds
                    </BaselaneButton>
                    <Outlet />
                  </>
                ) : (
                  <BaselaneButton
                    variant="filled"
                    palette="primary"
                    onClick={handleLandlordBankingCTAClick}
                    display={guide.ctaButtonText === '' ? 'none' : 'inline-flex'}
                  >
                    {guide.ctaButtonText}
                  </BaselaneButton>
                )}
              </Box>
            </>
          )}
        </Stack>
      )}
      <BaselaneApplicationTypePopup
        handleActionButton={handleBaselaneApplicationPopupClick}
        isOpen={isBaselaneApplicationTypePopupOpen}
        onClose={onBaselaneApplicationTypePopupClose}
      />
    </>
  );
};

export default GuidedOnboardingTracker;
