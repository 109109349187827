import { isMobile } from 'react-device-detect';

export const alertHeader = {
  fontSize: isMobile ? 'lg' : '2xl',
  lineHeight: isMobile ? '30px' : '32px',
  fontWeight: 'semibold',
  color: 'brand.neutral.700',
  p: '0',
};

export const alertMobileHeader = (showCloseButton) => {
  return {
    fontSize: 'lg',
    lineHeight: '26px',
    fontWeight: 'semibold',
    color: 'brand.neutral.700',
    p: '0',
    mt: showCloseButton && '32px',
  };
};

export const alertBody = {
  p: '0',
  m: isMobile ? '16px 0 0' : '32px 0 0',
  fontSize: 'sm',
  lineHeight: '20px',
  color: isMobile ? 'brand.neutral.700' : 'brand.neutral.600',
};

export const alertMobileBody = {
  p: '0',
  m: '16px 0 0',
  fontSize: 'sm',
  lineHeight: '20px',
  color: 'brand.neutral.600',
};

export const alertFooter = {
  p: '0',
  m: isMobile ? '16px 0 0' : '32px 0 0',
  justifyContent: isMobile ? 'space-between' : 'flex-end',
};

export const alertMobileFooter = {
  p: '0',
  m: '16px 0 0',
};

export const alertContainer = {
  p: isMobile ? '32px 16px 24px' : '56px 32px 32px',
};

export const alertMobileContainer = {
  p: '16px',
  m: '6px',
};

export const badgeIconBgStyles = {
  borderRadius: '50%',
  p: '6px',
  w: '32px',
  h: '32px',
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'yellow.100',
};

export const badgeIconContainerStyles = {
  bg: 'transparent',
  color: 'yellow.700',
};

export const titleTextStyles = {
  m: '16px',
  flexBasis: '90%',
};
