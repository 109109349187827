import { React } from 'react';
import { FormControl, FormErrorMessage, Heading } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import {
  BaselaneInput,
  BaselaneFormLabel,
  BaselaneRoutingNumberField,
  BaselaneDivider,
} from '@shared/components';
import { numbersOnlyMask } from '@core/utils/masks';
import PaymentAddressFields from './PaymentAddressFields';

const PaymentMethodFieldsWire = () => {
  const { values, handleChange, handleBlur, setFieldTouched, errors, touched } = useFormikContext();
  return (
    <>
      {/* Account Holder's Name */}
      <FormControl
        id="account-holder-name"
        isRequired
        isInvalid={errors.accountHolderName && touched.accountHolderName}
      >
        <BaselaneFormLabel textStyle="sm">Account holder&lsquo;s name</BaselaneFormLabel>
        <BaselaneInput
          size="lg"
          id="accountHolderName"
          name="accountHolderName"
          value={values.accountHolderName}
          type="text"
          onChange={(e) => {
            setFieldTouched('accountHolderName');
            handleChange(e);
          }}
          onBlur={handleBlur}
          maxLength={50}
        />
        <FormErrorMessage>{errors.accountHolderName}</FormErrorMessage>
      </FormControl>

      {/* Routing Number */}
      <FormControl
        id="routing-number"
        isRequired
        isInvalid={errors.routingNumber && touched.routingNumber}
      >
        <BaselaneFormLabel>Routing number</BaselaneFormLabel>
        <BaselaneRoutingNumberField
          id="routingNumber"
          routingNumberFieldName="routingNumber"
          bankNameFieldName="bankName"
        />
        <FormErrorMessage>{errors.routingNumber}</FormErrorMessage>
      </FormControl>

      {/* Account Number */}
      <FormControl
        id="account-number"
        isRequired
        isInvalid={errors.accountNumber && touched.accountNumber}
      >
        <BaselaneFormLabel>Account number</BaselaneFormLabel>
        <BaselaneInput
          size="lg"
          id="accountNumber"
          name="accountNumber"
          value={values.accountNumber}
          as={MaskedInput}
          mask={numbersOnlyMask}
          onChange={(e) => {
            setFieldTouched('accountNumber');
            handleChange(e);
          }}
          onBlur={handleBlur}
          maxLength={50}
        />
        <FormErrorMessage>{errors.accountNumber}</FormErrorMessage>
      </FormControl>
      <BaselaneDivider styles={{ my: 3 }} />
      <Heading as="h3" size="xs" fontWeight="medium" mb={3}>
        Recipient address
      </Heading>
      <PaymentAddressFields />
    </>
  );
};

export default PaymentMethodFieldsWire;
