import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Input,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Stack,
  Text,
  HStack,
  Box,
} from '@chakra-ui/react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  AddAccountManuallyDrawer,
  BaselaneDropdown,
  BaselaneMessageCard,
  BaselaneTooltip,
  BaselaneButton,
} from '@shared/components';
import { IconBank, IconExclamationCircle, IconInfo } from '@icons';
import stripCurrency from '@core/utils/stripCurrency';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';
import customTheme from '@core/theme';
import BanksContext from '@contexts/BanksContext';
import AddBankAccount from '@core/components/AddBankAccount';
import SlLoader from '@core/components/Loader';
import PlaceholderIcon from '@shared/components/BaselaneDropdown/components/PlaceholderIcon';
import { GET_UPDATE_PLAID_LINK_TOKEN } from '@core/apollo/queries';
import { useScrollToTop } from '@shared/hooks';
import { accountItemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { formLabelStyles, formInputStyles } from '@shared/styles/input.style';
import { resyncLinkStyles } from '@pages/LeasesPage/LeaseSection/styles/receivingBankAccount.styles';
import ConditionalAddAccount from './ConditionalAddAccount';
import useConnectedManualBank from '../../hooks/useConnectedBank';
import { GET_TRANSFERS_ACCOUNT_LIST, RESYNC_BANK_ACCOUNT_BALANCE } from '../../../queries';
import {
  ACCOUNT_TYPES,
  transferCheck,
  inputMaskOptions,
  getUpdateLinkTokenInput,
} from './helpers/transfers.helper';
import { formatAccount, menuOptionsConverter } from '../../helpers/accounts.shared.helpers';
import {
  input,
  errorContainer,
  errorText,
  transferDropdownErrorText,
} from './styles/bankTransfer.styles';

type BankTransferProps = {
  transferType: string,
  setIsValidBankTransfer: Function,
  setIsValidAccount: Function,
  setTransfer: Function,
  refetchBankSummary?: Function,
  transferAmount?: string,
  selectedAccountFrom?: Object,
  selectedAccountTo?: Object,
  description?: string,
  resetForm?: Boolean,
  hasBEError?: Boolean,
  bankTransferDrawerBodyRef?: any,
  setHasBEError?: Function,
  handleBaselaneBankAndRoutingNumClick?: Function,
};

function BankTransfer({
  transferType,
  setIsValidBankTransfer,
  setIsValidAccount,
  setTransfer,
  refetchBankSummary,
  transferAmount: tAmount,
  selectedAccountFrom: selectedAccFrom,
  selectedAccountTo: selectedAccTo,
  description: message,
  resetForm,
  hasBEError,
  bankTransferDrawerBodyRef,
  setHasBEError,
  handleBaselaneBankAndRoutingNumClick,
}: BankTransferProps) {
  const { pathname } = useLocation();
  const isUnifiedLB = pathname.includes('unified_landlord');
  const { isMinXL } = getBreakPoints();
  const addAccountManuallyDrawerRef = useRef();

  // State Vars
  const [transferDate, setTransferDate] = useState(new Date());
  const [transferAmount, setTransferAmount] = useState(tAmount);
  const [errors, setErrors] = useState();
  const [selectedAccountFrom, setSelectedAccountFrom] = useState(selectedAccFrom);
  const [selectedAccountTo, setSelectedAccountTo] = useState(selectedAccTo);
  const [validTransferAccount, setValidTransferAccount] = useState(false);
  const [description, setDescription] = useState(message);
  const [touchedTransferAmount, setTouchedTransferAmount] = useState(false);

  // Queries
  const { loading, error, data, refetch } = useQuery(GET_TRANSFERS_ACCOUNT_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      transferType,
    },
  });

  const [getUpdateLinkToken, { data: updateData }] = useLazyQuery(GET_UPDATE_PLAID_LINK_TOKEN);

  const { loading: bankLoading, banks } = useContext(BanksContext);

  const { refetchConnectedManualBank } = useConnectedManualBank();

  const currencyMask = createNumberMask(inputMaskOptions);

  // From Accounts Validation
  const isInstitutionAuthNotSupportedFrom =
    transferType === 'TRANSFER_IN' ? selectedAccountFrom?.isInstitutionAuthNotSupported : false;
  const isNameCheckFailedFrom =
    transferType === 'TRANSFER_IN' ? selectedAccountFrom?.isNameCheckFailed : false;
  const isOAuthNamePermissionMissingFrom =
    transferType === 'TRANSFER_IN' ? selectedAccountFrom?.isOAuthNamePermissionMissing : false;
  const isAccountDisconnectedFrom =
    transferType === 'TRANSFER_IN' ? selectedAccountFrom?.isAccountDisconnected : false;
  const isGenericErrorFrom =
    transferType === 'TRANSFER_IN' ? selectedAccountFrom?.isGenericError : false;

  // From Accounts Validation involving available balance
  // Note: These need to be calculated dynamically since unders some circumstances
  // the value may be updated after initialization (unlike all the other properties of the from account)
  const isBalanceUnavailableFrom = () => {
    return (
      selectedAccountFrom && (!selectedAccountFrom?.value || selectedAccountFrom?.value === null)
    );
  };
  const isInsufficientFundsFrom = () =>
    selectedAccountFrom &&
    selectedAccountFrom?.value &&
    stripCurrency(selectedAccountFrom.value) < 100;

  // To Accounts Validation
  const isInstitutionAuthNotSupportedTo =
    transferType === 'TRANSFER_OUT' ? selectedAccountTo?.isInstitutionAuthNotSupported : false;
  const isNameCheckFailedTo =
    transferType === 'TRANSFER_OUT' ? selectedAccountTo?.isNameCheckFailed : false;
  const isOAuthNamePermissionMissingTo =
    transferType === 'TRANSFER_OUT' ? selectedAccountTo?.isOAuthNamePermissionMissing : false;
  const isAccountDisconnectedTo =
    transferType === 'TRANSFER_OUT' ? selectedAccountTo?.isAccountDisconnected : false;
  const isGenericErrorTo =
    transferType === 'TRANSFER_OUT' ? selectedAccountTo?.isGenericError : false;

  const [menuOptionsFromAccount, setMenuOptionsFromAccount] = useState([]);

  const menuOptionsToAccount =
    data &&
    menuOptionsConverter(
      data?.transferAccountList?.toAccount,
      ACCOUNT_TYPES[transferType]?.to?.isExternal
    );

  const hasTransferLimits =
    transferType !== 'INTERNAL_TRANSFER' && transferType !== 'WIRE_TRANSFER';

  const handleAmountErrors = (e) => {
    const validTransferCheck = transferCheck(transferType, selectedAccountTo, selectedAccountFrom);

    setValidTransferAccount(validTransferCheck);

    const baselaneAccounts = banks.reduce((acc, b) => {
      const selectedId =
        transferType === 'TRANSFER_OUT'
          ? selectedAccountFrom?.bankAccountId
          : selectedAccountTo?.bankAccountId;

      // eslint-disable-next-line consistent-return
      b.bankAccounts.forEach((ba) => {
        if (ba.id === selectedId?.toString()) {
          acc.push(ba);
        }

        // eslint-disable-next-line consistent-return
        ba.subAccounts.forEach((sa) => {
          if (sa.id === selectedId?.toString()) {
            acc.push(sa);
          }
        });
      });

      return acc;
    }, []);

    const transferLimits = baselaneAccounts?.[0]?.limits;
    const dailyCreditTotal = transferLimits?.dailyCreditTotal;
    const monthlyCreditTotal = transferLimits?.monthlyCreditTotal;
    const dailyDebitTotal = transferLimits?.dailyDebitTotal;
    const monthlyDebitTotal = transferLimits?.monthlyDebitTotal;
    const dailyCreditLimit = transferLimits?.dailyCreditLimit;
    const monthlyCreditLimit = transferLimits?.monthlyCreditLimit;

    const dailyTransfer =
      transferType === 'TRANSFER_OUT'
        ? parseFloat(dailyCreditTotal, 10) + parseFloat(stripCurrency(e), 10)
        : parseFloat(dailyDebitTotal, 10) + parseFloat(stripCurrency(e), 10);

    const monthlyTransfer =
      transferType === 'TRANSFER_OUT'
        ? parseFloat(monthlyCreditTotal, 10) + parseFloat(stripCurrency(e), 10)
        : parseFloat(monthlyDebitTotal, 10) + parseFloat(stripCurrency(e), 10);

    let hasErrors = true;
    setIsValidBankTransfer(false);

    if (!stripCurrency(e) || stripCurrency(e) === 0) {
      setIsValidBankTransfer(false);
      if (transferType === 'WIRE_TRANSFER' && stripCurrency(e) < 100) {
        setErrors('Enter amount above the $100 minimum');
      } else {
        setErrors('Amount must be greater than $0.00');
      }
    } else if (transferType === 'WIRE_TRANSFER' && stripCurrency(e ?? 0) < 100) {
      setErrors('Enter amount above the $100 minimum');
    } else if (stripCurrency(e) > stripCurrency(selectedAccountFrom?.value)) {
      setErrors(
        'Insufficient funds to make this transfer, please edit the transfer amount to continue.'
      );
    } else if (
      dailyTransfer > dailyCreditLimit &&
      monthlyTransfer < monthlyCreditLimit &&
      hasTransferLimits
    ) {
      setErrors(
        `This amount cannot be transferred as it exceeds the ${
          formatCurrency(dailyCreditLimit).inDollars
        } daily limit. Initiate a transfer from your external account or contact us for an exception.`
      );
    } else if (
      dailyTransfer > dailyCreditLimit &&
      monthlyTransfer > monthlyCreditLimit &&
      hasTransferLimits
    ) {
      setErrors(
        `This amount cannot be transferred as it exceeds the ${
          formatCurrency(dailyCreditLimit).inDollars
        } daily limit. Initiate a transfer from your external account or contact us for an exception.`
      );
    } else if (
      dailyTransfer < dailyCreditLimit &&
      monthlyTransfer > monthlyCreditLimit &&
      hasTransferLimits
    ) {
      setIsValidBankTransfer(false);
      setErrors(
        `This amount cannot be transferred as it exceeds the ${
          formatCurrency(monthlyCreditLimit).inDollars
        } monthly limit. Initiate a transfer from your external account or contact us for an exception.`
      );
    } else {
      setIsValidBankTransfer(false);
      setErrors('');
      hasErrors = false;
    }

    if (
      (transferDate && transferAmount && selectedAccountFrom && selectedAccountTo) ||
      (transferType === 'WIRE_TRANSFER' && selectedAccountFrom && transferAmount)
    ) {
      if (!validTransferCheck) {
        setIsValidBankTransfer(false);
      } else if (!hasErrors) {
        setIsValidBankTransfer(true);
      }
    }
  };

  const handleAccountErrors = () => {
    if (
      isInstitutionAuthNotSupportedFrom ||
      isNameCheckFailedFrom ||
      isOAuthNamePermissionMissingFrom ||
      isAccountDisconnectedFrom ||
      isGenericErrorFrom ||
      isBalanceUnavailableFrom() ||
      isInstitutionAuthNotSupportedTo ||
      isNameCheckFailedTo ||
      isOAuthNamePermissionMissingTo ||
      isAccountDisconnectedTo ||
      isGenericErrorTo
    ) {
      setIsValidAccount(false);
    } else if (
      isInstitutionAuthNotSupportedFrom === false &&
      isNameCheckFailedFrom === false &&
      isOAuthNamePermissionMissingFrom === false &&
      isAccountDisconnectedFrom === false &&
      isGenericErrorFrom === false &&
      isBalanceUnavailableFrom() === false &&
      isInstitutionAuthNotSupportedTo === false &&
      isNameCheckFailedTo === false &&
      isOAuthNamePermissionMissingTo === false &&
      isAccountDisconnectedTo === false &&
      isGenericErrorTo === false
    ) {
      setIsValidAccount(true);
    }
  };

  // finds menu item that matches id
  const getMenuOption = (selectedFromId) => {
    const item = menuOptionsFromAccount.reduce((acc, group) => {
      const getSelectedItem = group.items.find((groupItem) => groupItem.id === selectedFromId);
      if (getSelectedItem) {
        return getSelectedItem;
      }
      return acc;
    }, []);
    return item;
  };

  // replaces menu item that matches id with supplied option
  const updateMenuOption = (newMenuOption) => {
    let groupIndex = null;
    let itemIndex = null;

    menuOptionsFromAccount.forEach((group, index) => {
      if (groupIndex && itemIndex) return;
      const subIndex = group.items.findIndex((menuOption) => {
        return newMenuOption.id === menuOption.id;
      });
      if (subIndex !== -1) {
        groupIndex = index;
        itemIndex = subIndex;
      }
    });

    if (groupIndex !== null && itemIndex !== null) {
      setMenuOptionsFromAccount((prevMenuOptions) => {
        const updatedMenuOptions = [...prevMenuOptions];
        updatedMenuOptions[groupIndex].items[itemIndex] = { ...newMenuOption };
        return updatedMenuOptions;
      });
    }
  };

  // applies changes to selected from account
  const updateSelectedFrom = (selectedItem) => {
    if (transferAmount) {
      setTouchedTransferAmount(true);
    }
    setSelectedAccountFrom(selectedItem);
  };

  // resyncs the available balance of the account specified
  const [resyncBankAccountBalance] = useMutation(RESYNC_BANK_ACCOUNT_BALANCE, {
    variables: { id: selectedAccountFrom?.bankAccountId },
    onCompleted: (res) => {
      if (res && res?.resyncBankAccountBalance && !res?.errors) {
        const { availableBalance } = res?.resyncBankAccountBalance || null;
        const selectedItem = getMenuOption(selectedAccountFrom?.id);
        // patch the menu option with the new balance received
        const modified = {
          ...selectedItem,
          value: formatCurrency(availableBalance).inDollars,
        };
        // replace legacy menu item with patched one
        updateMenuOption(modified);
        // select the patched one
        updateSelectedFrom(modified);
      }
    },
    onError: (err) => console.error(err),
  });

  const getErrorMsgContainer = (children) => {
    return (
      <HStack {...errorContainer}>
        <Box>
          <IconExclamationCircle w={13.33} h={13.33} color="#C93A3A" />
        </Box>
        {children}
      </HStack>
    );
  };

  const getTransferFromErrorMsg = () => {
    let errorMsg = null;

    if (isGenericErrorFrom) {
      errorMsg = (
        <FormErrorMessage {...transferDropdownErrorText} as="span">
          <Text as="span">
            This account can&apos;t be used to initiate a transfer from Baselane. Use a different
            account or initiate the transfer from your external account using your
          </Text>{' '}
          <BaselaneButton
            variant="link"
            palette="danger"
            onClick={() => handleBaselaneBankAndRoutingNumClick()}
          >
            Baselane Banking account & routing numbers.
          </BaselaneButton>
        </FormErrorMessage>
      );
    }
    if (isInstitutionAuthNotSupportedFrom) {
      errorMsg = (
        <FormErrorMessage {...transferDropdownErrorText} as="span">
          <Text as="span">
            This account can&apos;t be used to initiate a transfer from Baselane. Use a different
            account or initiate the transfer from your external account using your
          </Text>{' '}
          <BaselaneButton
            variant="link"
            palette="danger"
            onClick={() => handleBaselaneBankAndRoutingNumClick()}
          >
            Baselane Banking account & routing numbers.
          </BaselaneButton>
        </FormErrorMessage>
      );
    }
    if (isNameCheckFailedFrom) {
      errorMsg = (
        <FormErrorMessage {...transferDropdownErrorText} as="span">
          <Text as="span">
            This account can&apos;t be used to initiate a transfer from Baselane. Its legal name
            does not match your Baselane Banking account(s) name. Use a different account or
            initiate the transfer from your external account using your
          </Text>{' '}
          <BaselaneButton
            variant="link"
            palette="danger"
            onClick={() => handleBaselaneBankAndRoutingNumClick()}
          >
            Baselane Banking account & routing numbers.
          </BaselaneButton>
        </FormErrorMessage>
      );
    }
    if (isOAuthNamePermissionMissingFrom) {
      errorMsg = (
        <FormErrorMessage {...transferDropdownErrorText} as="span">
          <Text as="span">
            This account can&apos;t be used to initiate a transfer from Baselane. You did not grant
            sufficient permissions to verify the account.{' '}
          </Text>
          <ConditionalAddAccount
            refetchTransferAccountsList={refetch}
            transferType={transferType}
            accountProps={{
              isDirectToPlaid: true,
              hideButton: true,
              isDirectToPlaidButtonProps: {
                showCustomButton: true,
                hasIconLock: false,
                hasRightChevronIcon: false,
                titleText: 'Resync your account and update permissions.',
                containerStyles: { as: 'span' },
                type: 'link',
                variant: 'link',
                palette: 'danger',
                size: 'sm',
                styles: resyncLinkStyles,
              },
            }}
            styles={{ container: { as: 'span' } }}
          />
        </FormErrorMessage>
      );
    }
    if (isBalanceUnavailableFrom()) {
      errorMsg = (
        <FormErrorMessage {...transferDropdownErrorText} as="span">
          <Text as="span">We were unable to fetch the balance for this account.</Text>{' '}
          <BaselaneButton variant="link" palette="danger" onClick={resyncBankAccountBalance}>
            Click here to resync your account balance.
          </BaselaneButton>
        </FormErrorMessage>
      );
    }
    if (isAccountDisconnectedFrom) {
      errorMsg = (
        <FormErrorMessage {...transferDropdownErrorText} as="span">
          Account connection expired.{' '}
          <AddBankAccount
            bankAccId={selectedAccFrom?.bankAccountId}
            mode="RESYNC"
            titleText="Resync your account"
            size="sm"
            variant="link"
            palette="danger"
            styles={resyncLinkStyles}
            showCustomButton
            hasIconLock={false}
            hasRightChevronIcon={false}
            containerStyles={{ as: 'span' }}
            banks={banks}
            state={selectedAccFrom?.isBankCoonnected ? 'CONNECTED' : 'ITEM_LOGIN_REQUIRED'}
            handleSuccessFn={(res) => {
              const { connectionState } = res?.data?.reSyncExternalBankAccount ?? {};
              if (connectionState === 'CONNECTED') {
                refetch();
              }
            }}
            updateLinkToken={updateData && updateData.updatePlaidLinkToken}
          />{' '}
          to enable transfers.
        </FormErrorMessage>
      );
    }

    return errorMsg ? getErrorMsgContainer(errorMsg) : errorMsg;
  };

  const getDepositToErrorMsg = () => {
    let errorMsg = null;

    if (isGenericErrorTo) {
      errorMsg = (
        <FormErrorMessage {...transferDropdownErrorText} as="span">
          <Text as="span">This account does not support transfers.</Text>{' '}
          <BaselaneButton
            variant="link"
            palette="danger"
            onClick={() => addAccountManuallyDrawerRef.current?.open()}
          >
            Add this account manually
          </BaselaneButton>{' '}
          <Text as="span">to initiate a transfer.</Text>
        </FormErrorMessage>
      );
    }
    if (isInstitutionAuthNotSupportedTo) {
      errorMsg = (
        <FormErrorMessage {...transferDropdownErrorText} as="span">
          <Text as="span">
            This account was added using Plaid, which does not support transfers for this financial
            institution.
          </Text>
          <BaselaneButton
            variant="link"
            palette="danger"
            onClick={() => addAccountManuallyDrawerRef.current?.open()}
          >
            Add this account manually
          </BaselaneButton>{' '}
          <Text as="span">to initiate a transfer.</Text>
        </FormErrorMessage>
      );
    }
    if (isNameCheckFailedTo) {
      errorMsg = (
        <FormErrorMessage {...transferDropdownErrorText} as="span">
          <Text as="span">
            This account can&apos;t be used to initiate a transfer from Baselane. Its legal name
            does not match your Baselane Banking account(s) name.
          </Text>{' '}
          <BaselaneButton
            variant="link"
            palette="danger"
            onClick={() => addAccountManuallyDrawerRef.current?.open()}
          >
            Add this account manually
          </BaselaneButton>{' '}
          <Text as="span">to initiate a transfer.</Text>
        </FormErrorMessage>
      );
    }
    if (isOAuthNamePermissionMissingTo) {
      errorMsg = (
        <FormErrorMessage {...transferDropdownErrorText}>
          <Text as="span">
            This account does not support transfers, as you did not grant sufficient permission to
            verify the account.{' '}
          </Text>
          <ConditionalAddAccount
            refetchTransferAccountsList={refetch}
            transferType={transferType}
            accountProps={{
              isDirectToPlaid: true,
              hideButton: true,
              isDirectToPlaidButtonProps: {
                type: 'link',
                variant: 'link',
                palette: 'danger',
                size: 'sm',
                showCustomButton: true,
                hasIconLock: false,
                hasRightChevronIcon: false,
                titleText: 'Resync your account and update permissions.',
                containerStyles: { as: 'span' },
                styles: resyncLinkStyles,
              },
            }}
            styles={{ container: { as: 'span' } }}
          />
        </FormErrorMessage>
      );
    }
    if (isAccountDisconnectedTo) {
      errorMsg = (
        <FormErrorMessage {...transferDropdownErrorText}>
          Account connection expired.{' '}
          <AddBankAccount
            bankAccId={selectedAccountTo?.bankAccountId}
            mode="RESYNC"
            titleText="Resync your account"
            size="sm"
            variant="link"
            palette="danger"
            styles={resyncLinkStyles}
            showCustomButton
            hasIconLock={false}
            hasRightChevronIcon={false}
            containerStyles={{ as: 'span' }}
            banks={banks}
            state={selectedAccountTo?.isBankCoonnected ? 'CONNECTED' : 'ITEM_LOGIN_REQUIRED'}
            handleSuccessFn={(res) => {
              const { connectionState } = res?.data?.reSyncExternalBankAccount ?? {};
              if (connectionState === 'CONNECTED') {
                refetch();
              }
            }}
            updateLinkToken={updateData && updateData.updatePlaidLinkToken}
          />{' '}
          to enable transfers.
        </FormErrorMessage>
      );
    }

    return errorMsg ? getErrorMsgContainer(errorMsg) : errorMsg;
  };

  useEffect(() => {
    if (resetForm) {
      setSelectedAccountFrom(null);
      setSelectedAccountTo(null);
      setTransferDate(new Date());
      setTransferAmount(null);
      setErrors(null);
      setValidTransferAccount(false);
      setDescription(message);
      setTouchedTransferAmount(false);
    }
  }, [resetForm]);

  useEffect(() => {
    if (data) {
      const accountFrom = data?.transferAccountList?.fromAccount?.find(
        (acc) => acc?.bankAccountId === selectedAccountFrom?.bankAccountId
      );
      const froAccLst = data?.transferAccountList?.fromAccount || [];
      const firstAccountFrom = froAccLst[0];
      const accountTo = data?.transferAccountList?.toAccount?.find(
        (acc) => acc?.bankAccountId === selectedAccountTo?.bankAccountId
      );
      const toAccLst = data?.transferAccountList?.toAccount || [];
      // sort toAccLst according to last created baselane account first
      const lastAccountTo = toAccLst?.sort((b, a) => a.id - b.id)?.[0];

      setMenuOptionsFromAccount(
        menuOptionsConverter(
          data?.transferAccountList?.fromAccount,
          ACCOUNT_TYPES[transferType]?.from?.isExternal
        )
      );

      if (accountFrom) {
        const formattedAccountFrom = formatAccount(accountFrom, accountFrom?.isExternal);
        setSelectedAccountFrom(formattedAccountFrom);
      } else if (froAccLst?.length === 1 && firstAccountFrom) {
        // if there's one entry only, prefill
        const formattedAccountFrom = formatAccount(firstAccountFrom, firstAccountFrom?.isExternal);
        setSelectedAccountFrom(formattedAccountFrom);
      }
      if (accountTo) {
        const formattedAccountTo = formatAccount(accountTo, accountTo?.isExternal);
        setSelectedAccountTo(formattedAccountTo);
      } else if (lastAccountTo && (toAccLst?.length === 1 || isUnifiedLB)) {
        // if only one or on unified LB, always prefill with last created baselane account
        const formattedAccountTo = formatAccount(lastAccountTo, lastAccountTo?.isExternal);
        setSelectedAccountTo(formattedAccountTo);
      }
    }
  }, [data]);

  useEffect(() => {
    handleAmountErrors(transferAmount);
    handleAccountErrors();

    const transfer = { transferType };
    if (selectedAccountFrom) transfer.selectedAccountFrom = selectedAccountFrom;
    if (selectedAccountTo) transfer.selectedAccountTo = selectedAccountTo;
    if (transferDate) transfer.transferDate = formatDate(transferDate);
    if (transferAmount) transfer.transferAmount = transferAmount;

    setTransfer((prevState) => ({ ...prevState, ...transfer }));
    setHasBEError(null);
  }, [selectedAccountTo, selectedAccountFrom, transferAmount, transferDate]);

  useEffect(() => {
    if (
      (transferType === 'TRANSFER_IN' && selectedAccountFrom?.isAccountDisconnected) ||
      (transferType === 'TRANSFER_OUT' && selectedAccountTo?.isAccountDisconnected)
    ) {
      const queryInput = getUpdateLinkTokenInput({
        transferType,
        selectedAccountFrom,
        selectedAccountTo,
      });
      getUpdateLinkToken(queryInput);
    }
  }, [selectedAccountTo, selectedAccountFrom]);

  useScrollToTop(bankTransferDrawerBodyRef, hasBEError);

  if (error) {
    return null;
  }

  if (loading || bankLoading) {
    return <SlLoader />;
  }

  const handleAddAccountManuallySuccess = async (newAcc) => {
    const { data: updatedTransferAccListData } = await refetch();
    const { data: updatedConnectedManualBankData } = await refetchConnectedManualBank();
    refetchBankSummary();

    const depositToOptions =
      updatedTransferAccListData &&
      updatedConnectedManualBankData &&
      menuOptionsConverter(
        updatedTransferAccListData?.transferAccountList?.toAccount,
        ACCOUNT_TYPES[transferType]?.to?.isExternal
      );

    let selectedAccount = null;
    depositToOptions.forEach((opt) => {
      const acc = opt.items.find((item) => item.bankAccountId?.toString() === newAcc.id);
      if (acc) selectedAccount = acc;
    });

    // select the 'Deposit To' dropdown with the newly added manual acc
    setSelectedAccountTo(selectedAccount);
  };

  const classNames = [
    'input-form-xl',
    'fontSize-sm',
    'auto-width-dropdown',
    'disable-max-width',
    'auto-width',
  ];

  const toAccount = transferType === 'TRANSFER_OUT' ? 'Send To' : 'Deposit To';

  return (
    <Stack spacing={isMinXL ? 2 : 3}>
      {hasBEError && (
        <Box mb={isMinXL ? '8px' : '36px'}>
          <BaselaneMessageCard
            iconName="close"
            iconColor="red"
            borderColor="red"
            title="Failed Transfer"
            text="Failed to create transfer, try again. If problem persists, please contact us."
            iconContainerStyles={{ w: '20px' }}
            containerStyles={{ width: '100%' }}
          />
        </Box>
      )}

      {/* Transfer From */}
      <FormControl
        isInvalid={
          isInstitutionAuthNotSupportedFrom ||
          isNameCheckFailedFrom ||
          isOAuthNamePermissionMissingFrom ||
          isAccountDisconnectedFrom ||
          isGenericErrorFrom ||
          isBalanceUnavailableFrom() ||
          (transferType === 'WIRE_TRANSFER' && isInsufficientFundsFrom())
        }
      >
        <HStack mb="8px">
          <Text as="label" {...formLabelStyles.xs}>
            Transfer From
          </Text>
          <BaselaneTooltip
            innerContainerStyles={{ ml: 'initial' }}
            label={
              <Stack spacing="24px">
                <Text fontWeight="medium">
                  Don&apos;t see an external account I already connected?
                </Text>
                <Text>
                  Ensure that your external account&apos;s legal name matches your Baselane Banking
                  account(s) name. External accounts with different legal names can&apos;t be used
                  to add funds. Contact us if you need support.
                </Text>
                <Text fontWeight="medium">Add funds from account with different legal name?</Text>
                <Text>
                  To add funds from an external account with a different legal name, initiate a wire
                  transfer from your external account using your Baselane Banking account & routing
                  numbers.
                </Text>
              </Stack>
            }
            placement="auto"
          >
            <IconInfo width="16" height="16" color="#3A4B5B" />
          </BaselaneTooltip>
        </HStack>

        <BaselaneDropdown
          {...{
            isDisabled: menuOptionsFromAccount.length === 0,
            classNames,
            data: menuOptionsFromAccount,
            parentId: 'transfer-drawer',
            title: 'Transfer From',
            placeholder: <PlaceholderIcon icon={IconBank} text="Select Account" />,
            showValueByFields: ['name'],
            itemRenderer: accountItemRenderer,
            showSelectedRightElement: true,
            selectedRightElementValue: selectedAccountFrom?.value ?? '',
            hasError:
              isInstitutionAuthNotSupportedFrom ||
              isNameCheckFailedFrom ||
              isOAuthNamePermissionMissingFrom ||
              isAccountDisconnectedFrom ||
              isGenericErrorFrom ||
              isBalanceUnavailableFrom() ||
              (transferType === 'WIRE_TRANSFER' && isInsufficientFundsFrom()),
            handleSubmit: (item) => {
              const selectedItem = getMenuOption(item);
              updateSelectedFrom(selectedItem);
            },
            selectedItem: selectedAccountFrom,
            hasDropdownClearedExternally: resetForm,
            setHasDropdownClearedExternally: () =>
              setTransfer((prevState) => ({
                transferType: prevState?.transferType,
                transferDate: prevState?.transferType,
              })),
          }}
        />
        {/* Transfer From: Error Msgs */}
        {getTransferFromErrorMsg()}
        {transferType === 'WIRE_TRANSFER' && isInsufficientFundsFrom() && (
          <HStack {...errorContainer}>
            <Box>
              <IconExclamationCircle w={13.33} h={13.33} color="#C93A3A" />
            </Box>
            <FormErrorMessage {...errorText}>
              Selected account has insufficient balance to cover $100
            </FormErrorMessage>
          </HStack>
        )}
        {transferType !== 'TRANSFER_OUT' &&
          transferType !== 'WIRE_TRANSFER' &&
          transferType !== 'INTERNAL_TRANSFER' && (
            <ConditionalAddAccount
              refetchTransferAccountsList={refetch}
              addAccountManuallyDrawerRef={addAccountManuallyDrawerRef}
              transferType={transferType}
            />
          )}
      </FormControl>

      {/* Deposit To */}
      {transferType !== 'WIRE_TRANSFER' && (
        <FormControl
          isInvalid={
            isInstitutionAuthNotSupportedTo ||
            isNameCheckFailedTo ||
            isOAuthNamePermissionMissingTo ||
            isAccountDisconnectedTo ||
            isGenericErrorTo ||
            !validTransferAccount
          }
        >
          <FormLabel {...formLabelStyles.xs}>{toAccount}</FormLabel>
          <BaselaneDropdown
            {...{
              isDisabled: menuOptionsToAccount.length === 0,
              classNames,
              data: menuOptionsToAccount,
              title: 'Deposit To',
              parentId: 'transfer-drawer',
              placeholder: <PlaceholderIcon icon={IconBank} text="Select Account" />,
              showValueByFields: ['name'],
              itemRenderer: accountItemRenderer,
              showSelectedRightElement: true,
              selectedRightElementValue: selectedAccountTo?.value ?? '',
              hasError:
                isInstitutionAuthNotSupportedTo ||
                isNameCheckFailedTo ||
                isOAuthNamePermissionMissingTo ||
                isAccountDisconnectedTo ||
                isGenericErrorTo ||
                !validTransferAccount,
              handleSubmit: (selectedToId) => {
                const selectedItem = menuOptionsToAccount.reduce((acc, group) => {
                  const getSelectedItem = group.items.find(
                    (groupItem) => groupItem.id === selectedToId
                  );
                  if (getSelectedItem) {
                    return getSelectedItem;
                  }
                  return acc;
                }, []);
                setSelectedAccountTo(selectedItem);
              },
              selectedItem: selectedAccountTo,
              hasDropdownClearedExternally: resetForm,
              setHasDropdownClearedExternally: () =>
                setTransfer((prevState) => ({
                  transferType: prevState?.transferType,
                  transferDate: prevState?.transferType,
                })),
            }}
          />

          {/* Deposit To: Error Msgs */}
          {!validTransferAccount && (
            <HStack {...errorContainer}>
              <Box>
                <IconExclamationCircle w={13.33} h={13.33} color="#C93A3A" />
              </Box>
              <FormErrorMessage {...errorText}>
                Cannot transfer within the same account
              </FormErrorMessage>
            </HStack>
          )}
          {getDepositToErrorMsg()}
          {transferType === 'TRANSFER_OUT' && (
            <ConditionalAddAccount
              refetchTransferAccountsList={refetch}
              addAccountManuallyDrawerRef={addAccountManuallyDrawerRef}
              transferType={transferType}
              trigger="addAccountButton"
            />
          )}
        </FormControl>
      )}

      {/* Transfer Amount */}
      <FormControl {...{ ...input }} isInvalid={errors && touchedTransferAmount}>
        <FormLabel {...formLabelStyles.xs}>Transfer Amount</FormLabel>
        <Input
          placeholder="$"
          as={MaskedInput}
          value={transferAmount}
          name="transferAmount"
          onChange={(e) => {
            const newAmount = e.target.value;
            setTransferAmount(newAmount);
            handleAmountErrors(newAmount);
            setTouchedTransferAmount(true);
          }}
          onBlur={() => {
            setTouchedTransferAmount(true);
          }}
          mask={currencyMask}
          {...formInputStyles}
        />
        <FormErrorMessage {...errorText}>{errors}</FormErrorMessage>
      </FormControl>

      {transferType === 'WIRE_TRANSFER' && (
        <FormControl {...input}>
          <Stack direction="row" justifyContent="space-between">
            <FormLabel {...formLabelStyles.xs}>Message to Recipient (Optional)</FormLabel>
            <Text {...{ ...formLabelStyles.xs, color: customTheme.colors.brand.darkBlue['300'] }}>
              {40 - description.length} characters
            </Text>
          </Stack>
          <Input
            placeholder="Add notes"
            maxLength="40"
            value={description}
            onChange={(e) => {
              e.persist();
              setDescription(e.target.value);
              setTransfer((prevState) => ({ ...prevState, description: e.target.value }));
            }}
            {...formInputStyles}
          />
        </FormControl>
      )}

      <AddAccountManuallyDrawer
        {...{
          addAccountManuallyDrawerRef,
          handleAddAccountManuallySuccess,
          from: { section: 'add_funds' },
        }}
      />
    </Stack>
  );
}

BankTransfer.defaultProps = {
  transferAmount: '',
  selectedAccountFrom: null,
  selectedAccountTo: null,
  description: '',
  refetchBankSummary: () => {},
  resetForm: false,
  hasBEError: null,
  bankTransferDrawerBodyRef: null,
  setHasBEError: () => {},
  handleBaselaneBankAndRoutingNumClick: () => {},
};

export default BankTransfer;
