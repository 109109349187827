export const getLearnMoreDrawerConst = {
  drawerTitle: 'Learn About Payment Statuses',
  contentTitle: 'Rent Collection Statuses',
  description: 'Learn what each rent collection status means',
  statusList: [
    {
      key: 'COMPLETED',
      description:
        'This payment has been deposited into your bank account. Deposits can take up to 1 business day to reflect in your bank balance.',
    },
    {
      key: 'PROCESSING',
      description:
        'This payment has been made and is being processed. Generally, processing takes 2 business days. In some cases, processing will take 5 business days if it is your first time collecting rent on Baselane, or if our team has determined that there is a risk of insufficient funds or fraud associated with the payment.',
    },
    {
      key: 'SCHEDULED',
      description: 'This invoice is still not due, but it already has a scheduled payment.',
    },
    {
      key: 'UPCOMING',
      description: 'This invoice is still not due, no payment has been scheduled yet.',
    },
    {
      key: 'AWAITING_VERIFICATION',
      description:
        'Payments for this invoice will not be processed until you verify your identity on Baselane.',
    },
    {
      key: 'OVERDUE',
      description: 'This invoice is overdue, no payment was made by the due date.',
    },
    {
      key: 'FAILED',
      description:
        'This payment failed during processing. Please contact Baselane Support for additional information.',
    },
    {
      key: 'DELETED',
      description:
        'This invoice was deleted. You and your tenant see a record of deleted payments.',
    },
  ],
};
