import React from 'react';

type IconBanksAndCardsProps = {
  w?: Number,
  h?: Number,
  color?: String,
};

function IconBanksAndCards({ w = 20, h = 20, color = 'currentColor' }: IconBanksAndCardsProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 20 20" color={color}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon" transform="translate(0.000000, 3.000000)" fill={color} fillRule="nonzero">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1 1h18v3H1V1Zm0 4v7.333h18V5H1ZM0 1a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v11.333a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1Zm1.667 7.333h6.666v.834H1.667v-.834ZM8.333 10H1.667v.833h6.666V10Z"
            clipRule="evenodd"
          />
          <path
            d="M14.5,9.526 C14.5,9.24985763 14.2761424,9.026 14,9.026 C13.7238576,9.026 13.5,9.24985763 13.5,9.526 L14.5,9.526 Z M16.5,7.474 C16.5,7.75014237 16.7238576,7.974 17,7.974 C17.2761424,7.974 17.5,7.75014237 17.5,7.474 L16.5,7.474 Z M16,6 C16,5.72385763 15.7761424,5.5 15.5,5.5 C15.2238576,5.5 15,5.72385763 15,6 L16,6 Z M15,6.577 C15,6.85314237 15.2238576,7.077 15.5,7.077 C15.7761424,7.077 16,6.85314237 16,6.577 L15,6.577 Z M16,10.423 C16,10.1468576 15.7761424,9.923 15.5,9.923 C15.2238576,9.923 15,10.1468576 15,10.423 L16,10.423 Z M15,11 C15,11.2761424 15.2238576,11.5 15.5,11.5 C15.7761424,11.5 16,11.2761424 16,11 L15,11 Z M16,6.077 L15.071,6.077 L15.071,7.077 L16,7.077 L16,6.077 Z M15.071,9 L15.929,9 L15.929,8 L15.071,8 L15.071,9 Z M15.929,9.923 L15,9.923 L15,10.923 L15.929,10.923 L15.929,9.923 Z M15,9.923 C14.672,9.923 14.5,9.696 14.5,9.526 L13.5,9.526 C13.5,10.346 14.224,10.923 15,10.923 L15,9.923 Z M16.5,9.462 C16.5,9.667 16.296,9.923 15.929,9.923 L15.929,10.923 C16.744,10.923 17.5,10.318 17.5,9.462 L16.5,9.462 Z M15.929,9 C16.296,9 16.5,9.256 16.5,9.462 L17.5,9.462 C17.5,8.605 16.744,8 15.929,8 L15.929,9 Z M13.5,7.538 C13.5,8.395 14.256,9 15.071,9 L15.071,8 C14.704,8 14.5,7.744 14.5,7.538 L13.5,7.538 Z M15.071,6.077 C14.256,6.077 13.5,6.682 13.5,7.538 L14.5,7.538 C14.5,7.333 14.704,7.077 15.071,7.077 L15.071,6.077 Z M17.5,7.474 C17.5,6.654 16.776,6.077 16,6.077 L16,7.077 C16.328,7.077 16.5,7.304 16.5,7.474 L17.5,7.474 Z M15,6 L15,6.577 L16,6.577 L16,6 L15,6 Z M15,10.423 L15,11 L16,11 L16,10.423 L15,10.423 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
}

IconBanksAndCards.defaultProps = {
  w: 20,
  h: 20,
  color: 'currentColor',
};

export default IconBanksAndCards;
