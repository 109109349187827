import React from 'react';

type IconDotsCircleHorizontalProps = { w?: Number, h?: Number, color?: string };

function IconDotsCircleHorizontal({ w, h, color }: IconDotsCircleHorizontalProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 11.0506 2.20693 12.0909 2.60896 13.0615C3.011 14.0321 3.60028 14.914 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94942 18 10 18C11.0506 18 12.0909 17.7931 13.0615 17.391C14.0321 16.989 14.914 16.3997 15.6569 15.6569C16.3997 14.914 16.989 14.0321 17.391 13.0615C17.7931 12.0909 18 11.0506 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 11.3132 19.7413 12.6136 19.2388 13.8268C18.7362 15.0401 17.9997 16.1425 17.0711 17.0711C16.1425 17.9997 15.0401 18.7362 13.8268 19.2388C12.6136 19.7413 11.3132 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C2.00035 16.1425 1.26375 15.0401 0.761205 13.8268C0.258658 12.6136 0 11.3132 0 10C5.96046e-08 7.34784 1.05357 4.8043 2.92893 2.92893ZM5 10C5 9.44771 5.44772 9 6 9H6.01C6.56228 9 7.01 9.44771 7.01 10C7.01 10.5523 6.56228 11 6.01 11H6C5.44772 11 5 10.5523 5 10ZM9 10C9 9.44771 9.44771 9 10 9H10.01C10.5623 9 11.01 9.44771 11.01 10C11.01 10.5523 10.5623 11 10.01 11H10C9.44771 11 9 10.5523 9 10ZM13 10C13 9.44771 13.4477 9 14 9H14.01C14.5623 9 15.01 9.44771 15.01 10C15.01 10.5523 14.5623 11 14.01 11H14C13.4477 11 13 10.5523 13 10Z"
        fill={color}
      />
    </svg>
  );
}

IconDotsCircleHorizontal.defaultProps = {
  w: 20,
  h: 20,
  color: 'currentColor',
};

export default IconDotsCircleHorizontal;
