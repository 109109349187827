import React from 'react';
import BaselaneResponsiveCellAmountContent from '@shared/components/BaselaneResponsiveTable/BaslaneResponsiveCell/BaselaneResponsiveCellAmountContent';
import BaselaneResponsiveCell from './BaselaneResponsiveCell';

type BaselaneResponsiveCellAmountProps = {
  /**
   * Cell element on left of title and subtitle. Can be used to any type of element
   * at the moment most common case is image/icon
   */
  leftGraphicElement?: React.Node,
  /**
   * Cell element on left of title and subtitle. Can be used to any type of element
   * at the moment most common case is image/icon
   */
  rightGraphicElement?: React.Node,
  /**
   * Text style token to apply to the amount
   */
  textStyle?: String,
  /**
   * Text color style token to apply to the amount
   */
  color?: String,
  /**
   * Cell children
   */
  children?: React.Node,
};

/**
 * Renders a variation of BaselaneReponsiveCell to be used inside BaselaneResponsiveTableRow.
 *
 * @returns {JSX.Element}
 */
const BaselaneResponsiveCellAmount = ({
  leftGraphicElement,
  rightGraphicElement,
  children,
  color,
  textStyle,
  ...rest
}: BaselaneResponsiveCellAmountProps) => {
  return (
    <BaselaneResponsiveCell {...rest}>
      <BaselaneResponsiveCellAmountContent
        {...{
          leftGraphicElement,
          rightGraphicElement,
          children,
          color,
          textStyle,
        }}
      />
    </BaselaneResponsiveCell>
  );
};

BaselaneResponsiveCellAmount.defaultProps = {
  leftGraphicElement: null,
  rightGraphicElement: null,
  textStyle: 'data-md',
  color: 'brand.neutral.700',
  children: null,
};

export default BaselaneResponsiveCellAmount;
