import React from 'react';
import { Input, InputLeftAddon, InputRightAddon, Text, InputGroup } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import radii from '@core/theme/radius';

const CustomizableBaselaneInput = ({ prefix, suffix, size, inputGroupProps, ...props }) => {
  const inputRef = React.useRef(null);

  const hasInvalidHtmlAttribute = inputRef.current?.ariaInvalid ?? false;

  const isInvalid = props?.isInvalid ?? hasInvalidHtmlAttribute;

  // Note: border adds 2px, ends up making the height 50px, hence applying hard coded height
  const stylesBySize = {
    sm: {
      padding: '4px 12px',
      h: '32px',
    },
    md: {
      padding: '8px 12px',
      h: '40px',
    },
    lg: {
      padding: '12px 16px',
      h: '48px',
    },
  };

  const getBorderColor = () => {
    if (props?.isDisabled) {
      return 'brand.neutral.250';
    }
    if (isInvalid) {
      return 'red.500';
    }
    return 'brand.darkBlue.200';
  };

  const getHoverStyles = () => {
    if (props?.isDisabled) {
      return null;
    }

    return {
      borderColor: isInvalid || hasInvalidHtmlAttribute ? 'red.400' : 'brand.darkBlue.300',
    };
  };

  const getFocusWithinStyles = () => {
    if (props?.isDisabled) {
      return null;
    }
    return isInvalid
      ? { borderColor: 'red.700', boxShadow: '0px 0px 0px 2px #FCE3E2 inset' }
      : { borderColor: 'brand.blue.700', boxShadow: '0px 0px 0px 2px #E1F4FF inset' };
  };

  const handleInputFocus = () => {
    // Note: MaskedInput does not have inputRef.current.focus available
    if (inputRef?.current?.focus) {
      inputRef?.current?.focus();
    } else {
      inputRef?.current?.inputElement?.focus();
    }
  };

  return (
    <InputGroup
      gap={1}
      sx={{
        ...stylesBySize[size],
        overflow: 'hidden',
        alignItems: 'center',
        border: '1px solid',
        borderColor: getBorderColor(),
        background: props?.isDisabled ? 'brand.neutral.150' : 'brand.white',
        borderRadius: radii.sm,
        _hover: getHoverStyles(),
        _focusWithin: getFocusWithinStyles(),
      }}
      onClick={handleInputFocus}
      {...inputGroupProps}
    >
      {prefix ? (
        <InputLeftAddon
          sx={{
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '24px',
            color: 'brand.neutral.500',
            border: 'none',
            background: 'transparent',
            padding: 0,
            height: 'fit-content',
          }}
          onClick={handleInputFocus}
        >
          <Text>{prefix}</Text>
        </InputLeftAddon>
      ) : null}
      <Input
        variant="unstyled"
        size={size}
        {...props}
        ref={inputRef}
        sx={{
          color: 'brand.neutral.900',
          padding: 0,
          _placeholder: {
            color: props?.isDisabled ? 'brand.neutral.400' : 'brand.neutral.500',
          },
        }}
      />
      {suffix ? (
        <InputRightAddon
          sx={{
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '24px',
            color: 'brand.neutral.500',
            border: 'none',
            background: 'transparent',
            padding: 0,
            height: 'fit-content',
          }}
          onClick={handleInputFocus}
        >
          <Text>{suffix}</Text>
        </InputRightAddon>
      ) : null}
    </InputGroup>
  );
};

CustomizableBaselaneInput.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  inputGroupProps: PropTypes.shape(InputGroup.propTypes),
  ...Input.propTypes,
};

CustomizableBaselaneInput.defaultProps = {
  prefix: '',
  suffix: '',
  size: 'md',
  inputGroupProps: {},
  ...Input.defaultProps,
};

export default CustomizableBaselaneInput;
