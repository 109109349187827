/**
 * Cancel behaviour- navigates back to origin while replacing current history state, to not pollute it
 * @param {Navigate} navigate the useNavigate navigate function from react router dom
 * @param {string} from the pathname of the originating screen we are to return to
 */

export const handleCancel = (navigate, from = null) => {
  if (from && from?.length > 0) {
    navigate(
      {
        pathname: `${from}`,
      },
      {
        replace: true,
      }
    );
  } else {
    // fallback: navigate to last place in history, if all else fails
    navigate(-1);
  }
};

/**
 * Calculates the pathname "up" one segment from the current URL
 * @param {string} pathname useLocation pathname to manipulate
 */

export const popSegmentFromPathname = (pathname) => {
  const pathSegments = pathname?.split('/');
  pathSegments.pop();
  return pathSegments.join('/');
};
