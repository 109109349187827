import { gql } from '@apollo/client';

export const GET_TRANSFER_LIST = gql`
  query TransfersList($input: TransferQueryOption) {
    transfers(input: $input) {
      total
      data {
        id
        amount
        createdAt
        completedDate
        externalId
        fromTransferAccount {
          id
          accountName
          accountNumber
          isExternal
          name
          institution {
            id
            name
            plaidInstitutionName
          }
        }
        note
        metadata {
          name
          checkNumber
        }
        status
        toTransferAccount {
          id
          accountName
          accountNumber
          isExternal
          name
          institution {
            id
            name
            plaidInstitutionName
          }
        }
        transferDate
        type
        typeName
      }
    }
  }
`;
