export const noSubAccDescriptionStyles = {
  textStyle: 'xs',
};

export const triggerBtnWrapperStyles = { w: '64px', alignItems: 'center' };

export const triggerBtnLableStyles = {
  fontSize: '3xs',
  lineHeight: '14px',
  fontWeight: 'normal',
  color: 'brand.blue.800A',
  textAlign: 'center',
};
