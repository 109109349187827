import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { KYC_STATUS } from '../helpers/kycSubmitted.helpers';
import {
  iconContainerStyles,
  titleTextStyles,
  bodyTextStyles,
  containerCenterStyles,
  iconContainerCenterStyles,
  titleTextCenterStyles,
  bodyTextCenterStyles,
} from '../styles/kycSubmitted.styles';

type KYCFormSubmittedProps = {
  kycStatus: String,
  isCentered?: Boolean,
};
const KYCFormSubmitted = ({ kycStatus, isCentered }: KYCFormSubmittedProps) => {
  const { title, description, description2, icon, iconBg } = KYC_STATUS[kycStatus] ?? {};

  return isCentered ? (
    <Stack {...containerCenterStyles}>
      <Stack align="center" spacing={4}>
        <Stack {...iconContainerCenterStyles}>{icon}</Stack>
        <Stack align="center" spacing={0.4}>
          <Text {...titleTextCenterStyles}>{title}</Text>
          <Text {...bodyTextCenterStyles}>{description}</Text>
          <Text {...bodyTextCenterStyles} pt="24px">
            {description2}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <Box>
      <Stack {...iconContainerStyles(iconBg)}>{icon}</Stack>

      <Text {...titleTextStyles}>{title}</Text>
      <Text {...bodyTextStyles}>{description}</Text>
      <Box {...bodyTextStyles} pt="24px">
        {description2}
      </Box>
    </Box>
  );
};

KYCFormSubmitted.defaultProps = { isCentered: false };

export default KYCFormSubmitted;
