import React from 'react';
import { Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import useBankAccountsActive from '@hooks/useBankAccountsActive';
import AddBankAccount from '@core/components/AddBankAccount';
import ConditionalAddAccount from '@core/components/NativeBankingPage/MainContent/components/BankTransfer/ConditionalAddAccount';
import { resyncLinkStyles } from '@pages/LeasesPage/LeaseSection/styles/receivingBankAccount.styles';
import useBankSummary from '@core/hooks/useBankSummary';

const useDepositToAccountValidation = ({
  openEducationalDrawer,
  updatedLinkTokenData,
  updateLinkTokenIsLoading,
  openManualAccountDrawer,
}) => {
  const { values, validateForm } = useFormikContext();
  const { bankAccountsById, refetch: refetchActiveBankAccounts } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
    fetchPolicy: 'network-only',
  });
  const { banks } = useBankSummary();

  const validate = (value) => {
    let error;
    const { transferFromAccountId, depositToAccountId } = values;
    const transferFromAccount = bankAccountsById[transferFromAccountId];
    const depositToAccount = bankAccountsById[depositToAccountId];

    if (!depositToAccountId) {
      error = 'Please select account';
    }

    if (depositToAccount?.isExternal && depositToAccount?.errorCodes?.isGenericError) {
      error = (
        <Text as="span">
          <Text as="span">This account does not support transfers.</Text>{' '}
          <Text
            as="span"
            textDecoration="underline"
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={openManualAccountDrawer}
          >
            Add this account manually
          </Text>{' '}
          <Text as="span">to initiate a transfer.</Text>
        </Text>
      );
    }

    if (
      depositToAccount?.isExternal &&
      depositToAccount?.errorCodes?.isInstitutionAuthNotSupported
    ) {
      error = (
        <Text as="span">
          <Text as="span">
            This account was added using Plaid, which does not support transfers for this financial
            institution.
          </Text>{' '}
          <Text
            as="span"
            textDecoration="underline"
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={openManualAccountDrawer}
          >
            Add this account manually
          </Text>{' '}
          <Text as="span">to initiate a transfer.</Text>
        </Text>
      );
    }

    if (depositToAccount?.isExternal && depositToAccount?.errorCodes?.isNameCheckFailed) {
      error = (
        <Text as="span">
          <Text as="span">
            This account can&apos;t be used to initiate a transfer from Baselane. Its legal name
            does not match your Baselane Banking account(s) name.
          </Text>{' '}
          <Text
            as="span"
            textDecoration="underline"
            _hover={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={openManualAccountDrawer}
          >
            Add this account manually
          </Text>{' '}
          <Text as="span">to initiate a transfer.</Text>
        </Text>
      );
    }

    if (
      depositToAccount?.isExternal &&
      depositToAccount?.errorCodes?.isOAuthNamePermissionMissing
    ) {
      error = (
        <Text as="span">
          <Text as="span">
            This account does not support transfers, as you did not grant sufficient permission to
            verify the account.{' '}
          </Text>
          <ConditionalAddAccount
            refetchTransferAccountsList={() => {
              refetchActiveBankAccounts().then(() => validateForm());
            }}
            transferType="TRANSFER_OUT"
            accountProps={{
              isDirectToPlaid: true,
              hideButton: true,
              isDirectToPlaidButtonProps: {
                type: 'link',
                variant: 'link',
                palette: 'danger',
                size: 'sm',
                showCustomButton: true,
                hasIconLock: false,
                hasRightChevronIcon: false,
                titleText: 'Resync your account and update permissions.',
                containerStyles: { as: 'span' },
                styles: resyncLinkStyles,
              },
            }}
            styles={{ container: { as: 'span' } }}
          />
        </Text>
      );
    }

    if (depositToAccount?.isExternal && depositToAccount?.errorCodes?.isAccountDisconnected) {
      error = (
        <Text as="span">
          Account connection expired.{' '}
          <AddBankAccount
            bankAccId={depositToAccountId}
            mode="RESYNC"
            titleText="Resync your account"
            size="sm"
            variant="link"
            palette="danger"
            styles={resyncLinkStyles}
            showCustomButton
            hasIconLock={false}
            hasRightChevronIcon={false}
            containerStyles={{ as: 'span' }}
            banks={banks}
            state={transferFromAccount?.isBankConnected ? 'CONNECTED' : 'ITEM_LOGIN_REQUIRED'}
            isLoading={updateLinkTokenIsLoading}
            handleSuccessFn={(res) => {
              const { connectionState } = res?.data?.reSyncExternalBankAccount ?? {};
              if (connectionState === 'CONNECTED') {
                refetchActiveBankAccounts().then(() => validateForm());
              }
            }}
            updateLinkToken={updatedLinkTokenData && updatedLinkTokenData.updatePlaidLinkToken}
          />{' '}
          to enable transfers.
        </Text>
      );
    }

    if (
      transferFromAccountId !== '' &&
      depositToAccountId !== '' &&
      transferFromAccountId === depositToAccountId
    ) {
      error = 'Cannot transfer within the same account';
    }

    if (transferFromAccount?.isExternal && depositToAccount?.isExternal) {
      error = 'You cannot transfer between two external accounts';
    }

    return error;
  };

  return { validate };
};

export default useDepositToAccountValidation;
