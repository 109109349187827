export const formInputStyles = {
  h: '48px',
  p: '12px 16px',
  color: 'brand.neutral.700',
  borderColor: 'brand.darkBlue.150',
  borderRadius: '4px',
  fontSize: 'sm',
  lineHeight: '24px',
  _invalid: {
    borderColor: 'red.800AA',
    _focus: {
      borderColor: 'red.800AA',
      color: 'brand.neutral.700',
      boxShadow: 'none',
    },
    _focusVisible: {
      borderColor: 'red.800AA',
      color: 'brand.neutral.700',
      boxShadow: 'none',
    },
    _hover: {
      borderColor: 'red.800AA',
      color: 'brand.neutral.700',
      boxShadow: 'none',
    },
  },
  _focus: {
    borderColor: 'brand.darkBlue.250',
    color: 'brand.neutral.600',
    boxShadow: 'inset 0px 0px 1px 1px #E1F4FF',
  },
  _focusVisible: {
    borderColor: 'brand.darkBlue.250',
    color: 'brand.neutral.600',
    boxShadow: 'inset 0px 0px 1px 1px #E1F4FF',
  },
  _hover: { borderColor: 'brand.darkBlue.250', color: 'brand.neutral.600' },
  _disabled: { borderColor: 'brand.darkBlue.100', color: 'brand.darkBlue.300' },
  _placeholder: { color: 'brand.neutral.500' },
};
