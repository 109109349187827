// @flow
import React from 'react';
import BaselaneOverdueStatusBadge from './BaselaneOverdueStatusBadge';
import BaselaneCurrentStatusBadge from './BaselaneCurrentStatusBadge';

type Props = {
  isOverdue?: boolean,
  text?: string,
};

function BaselaneLeaseBadge({ text, isOverdue, ...styles }: Props): any {
  const badgeText = text !== '' && text;

  return isOverdue ? (
    <BaselaneOverdueStatusBadge text={badgeText} styles={styles} />
  ) : (
    <BaselaneCurrentStatusBadge text={badgeText} styles={styles} />
  );
}

BaselaneLeaseBadge.defaultProps = {
  isOverdue: false,
  text: undefined,
};

export default BaselaneLeaseBadge;
