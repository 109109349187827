import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Text, Stack, Box } from '@chakra-ui/react';

import { BaselaneCardStack, BaselaneCardNew } from '@shared/components';
import { truncatedText } from '@shared/styles/text.style';
import BankEntityContext from '@contexts/BankEntityContext';
import { lastFourDigits } from '@core/utils/formatAccountNumber';
import formatCurrency from '@core/utils/formatCurrency';
import getBreakPoints from '@core/utils/getBreakPoints';
import { GET_LEASE_DETAIL } from '@pages/BankEntity/queries';

import AutoTagChip from './AccountTags/AutoTagChip';
import CurrentApyChip from './AccountTags/CurrentApyChip';
import CardDetailsChip from './AccountTags/CardDetailsChip';
import RentCollectionChip from './AccountTags/RentCollectionChip';
import { getLinkedPropertiesAndUnits } from './AccountTags/tagList.helpers';
import {
  cardBalanceLabelStyles,
  cardBalanceValueStyles,
  cardContainerStyles,
  cardInnerContainerStyles,
  cardBalanceLabel2Styles,
  cardButtonStyles,
} from './styles/card.styles';

type AccountCardProps = {
  account: Object,
  isMainAccount?: Boolean,
  onCustomClick?: Function,
  updateActiveFilter: Function,
};

const AccountCard = ({
  account,
  isMainAccount,
  onCustomClick,
  updateActiveFilter,
}: AccountCardProps) => {
  const { data: leaseData } = useQuery(GET_LEASE_DETAIL);
  const { selectedBankAccountId, setSelectedAccountToView, editAccountRef } = useContext(
    BankEntityContext
  );

  const { leaseDetail } = leaseData || {};
  const { property: leaseDetailProperties = [] } = leaseDetail || {};

  const { isMin768 } = getBreakPoints();

  const attachedCards = account.cards?.filter((card) => card.cardStatus !== 'ClosedByCustomer');

  const activeRentCollection = getLinkedPropertiesAndUnits(
    'Rent Collection',
    account.linkedFor,
    leaseDetailProperties
  );

  const accountType = (
    <>
      <Text as="span" textTransform="capitalize">
        {account.accountSubType}
      </Text>{' '}
      account - {lastFourDigits(account.accountNumber)}
    </>
  );

  const handleAccountDetailsDrawerOpen = () => {
    setSelectedAccountToView({ isMainAccount, ...account });
    editAccountRef?.current?.open();
  };

  const handleCardClick = () => {
    if (onCustomClick) onCustomClick();
    // only on desktop can we click top portion of card to update filtering on page
    else if (isMin768) updateActiveFilter(account.id);
    else {
      // want to make whole card clickable to open the account details drawer
      handleAccountDetailsDrawerOpen();
    }
  };

  const items = [
    {
      id: `account-${account.id}-summary`,
      content: (
        <BaselaneCardNew
          variant="innerClickable"
          size="md"
          {...{
            flexDirection: 'column',
            alignItems: 'flex-start',
            py: 1.5,
          }}
          onClick={handleCardClick}
        >
          <Box {...cardInnerContainerStyles}>
            <Text {...cardBalanceLabelStyles} {...truncatedText}>
              {account.nickName}
            </Text>
            <Text {...cardBalanceLabel2Styles} {...truncatedText}>
              {accountType}
            </Text>
          </Box>
          <Stack w="100%" direction="row" justifyContent="space-between" alignItems="center" mt={1}>
            <Text
              {...cardBalanceValueStyles}
              {...truncatedText}
              color={account.availableBalance < 0 ? 'red.800AA' : 'brand.neutral.700'}
            >
              {formatCurrency(account.availableBalance).inDollars}
            </Text>
            <Stack direction="row" h="28px" gap={0.75}>
              {account.accountSubType === 'savings' ? (
                <CurrentApyChip />
              ) : (
                <>
                  {activeRentCollection.length > 0 && (
                    <RentCollectionChip activeRentCollection={activeRentCollection} />
                  )}
                  {account.autoTag && account.autoTag?.enabled && (
                    <AutoTagChip
                      autoTag={account.autoTag}
                      leaseDetailProperties={leaseDetailProperties}
                    />
                  )}
                  {attachedCards?.length > 0 && (
                    <CardDetailsChip cards={attachedCards} accountId={account.id} />
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </BaselaneCardNew>
      ),
    },
  ];

  if (isMin768) {
    items.push({
      id: `account-${account.id}-details`,
      content: (
        <BaselaneCardNew
          variant="innerClickable"
          size="md"
          onClick={() => handleAccountDetailsDrawerOpen()}
          py={1.5}
          id="link-button-account-details"
        >
          <Text {...cardButtonStyles}>Account details</Text>
        </BaselaneCardNew>
      ),
    });
  }

  return (
    <BaselaneCardStack
      id={`account-card-${account.id}`}
      items={items}
      size="md"
      {...{
        ...cardContainerStyles(isMin768),
        ...(selectedBankAccountId === account.id && { borderColor: 'brand.blue.800A' }),
        mr: isMainAccount && isMin768 ? 1.5 : 0,
      }}
    />
  );
};

export default AccountCard;

AccountCard.defaultProps = {
  isMainAccount: false,
  onCustomClick: null,
};
