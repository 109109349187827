import React from 'react';

type IconCalendarProps = {
  w: Number,
  h: Number,
  color: String,
};

function IconCalendar({ w = 16, h = 16, color = '#6C7884' }: IconCalendarProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33331 1.33331C5.7015 1.33331 5.99998 1.63179 5.99998 1.99998V2.66665H9.99998V1.99998C9.99998 1.63179 10.2985 1.33331 10.6666 1.33331C11.0348 1.33331 11.3333 1.63179 11.3333 1.99998V2.66665H12.6666C13.1971 2.66665 13.7058 2.87736 14.0809 3.25243C14.4559 3.62751 14.6666 4.13621 14.6666 4.66665V12.6666C14.6666 13.1971 14.4559 13.7058 14.0809 14.0809C13.7058 14.4559 13.1971 14.6666 12.6666 14.6666H3.33331C2.80288 14.6666 2.29417 14.4559 1.9191 14.0809C1.54403 13.7058 1.33331 13.1971 1.33331 12.6666V4.66665C1.33331 4.13621 1.54403 3.62751 1.9191 3.25243C2.29417 2.87736 2.80288 2.66665 3.33331 2.66665H4.66665V1.99998C4.66665 1.63179 4.96512 1.33331 5.33331 1.33331ZM4.66665 3.99998H3.33331C3.1565 3.99998 2.98693 4.07022 2.86191 4.19524C2.73688 4.32027 2.66665 4.48984 2.66665 4.66665V12.6666C2.66665 12.8435 2.73688 13.013 2.86191 13.1381C2.98693 13.2631 3.1565 13.3333 3.33331 13.3333H12.6666C12.8435 13.3333 13.013 13.2631 13.1381 13.1381C13.2631 13.013 13.3333 12.8435 13.3333 12.6666V4.66665C13.3333 4.48984 13.2631 4.32027 13.1381 4.19524C13.013 4.07022 12.8435 3.99998 12.6666 3.99998H11.3333V4.66665C11.3333 5.03484 11.0348 5.33331 10.6666 5.33331C10.2985 5.33331 9.99998 5.03484 9.99998 4.66665V3.99998H5.99998V4.66665C5.99998 5.03484 5.7015 5.33331 5.33331 5.33331C4.96512 5.33331 4.66665 5.03484 4.66665 4.66665V3.99998ZM3.99998 7.33331C3.99998 6.96512 4.29846 6.66665 4.66665 6.66665H11.3333C11.7015 6.66665 12 6.96512 12 7.33331C12 7.7015 11.7015 7.99998 11.3333 7.99998H4.66665C4.29846 7.99998 3.99998 7.7015 3.99998 7.33331Z"
        fill={color}
      />
    </svg>
  );
}

export default IconCalendar;
