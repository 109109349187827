import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { BaselaneDivider } from '@shared/components';
import GoogleButton from '@features/Authentication/components/GoogleButton';
import { dividerStyles, loginStackStyles } from '../styles/font.style';

function SignUpLoginButtons({ isLogin, isMobile }: { isLogin: boolean, isMobile: boolean }) {
  const text = isLogin ? 'sign in' : 'sign up';

  return (
    <Box w={isLogin ? '66%' : '100%'} m="0 !important">
      <Stack alignItems="center" spacing={0}>
        <GoogleButton isLogin={isLogin} text={text} isMobile={isMobile} />

        <Stack direction="row" {...{ ...loginStackStyles(isLogin), my: 4 }}>
          <BaselaneDivider
            styles={{
              ...dividerStyles,
              my: '0px !important',
              bg: 'brand.neutral.200',
              borderColor: 'brand.neutral.100',
            }}
          />
          {isLogin ? (
            <Text
              whiteSpace="nowrap"
              fontSize={{ xxs: 'sm', xs: 'sm', sm: 'sm', md: 'sm', lg: 'md', xl: 'md' }}
              color="brand.neutral.600"
            >
              Or {text} using email
            </Text>
          ) : (
            <Text
              whiteSpace="nowrap"
              fontSize={{ xxs: 'sm', xs: 'sm', sm: 'sm', md: 'sm', lg: 'md', xl: 'md' }}
              color="brand.neutral.600"
            >
              Or
            </Text>
          )}
          <BaselaneDivider
            styles={{ ...dividerStyles, my: '0px !important', borderColor: 'brand.neutral.100' }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default SignUpLoginButtons;
