import { isMobile } from 'react-device-detect';

export const containerPageStyles = {
  h: '100%',
  align: 'center',
  justifyContent: isMobile ? 'initial' : 'center',
  mt: isMobile ? '38px' : 'initial',
  p: isMobile ? '0 24px 24px 24px' : 'initial',
};

export const innerPageContainerStyles = {
  spacing: '80px',
  h: isMobile ? '100%' : 'auto',
  justifyContent: 'space-between',
};
