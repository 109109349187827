export const cashflowTaxPackageStyles = {
  container: {
    mt: '56px !important',
    color: 'brand.neutral.700',
    maxWidth: '642px',
  },
  title: {
    textStyle: 'headline-lg',
  },
  question: {
    textStyle: 'headline-sm',
    color: 'brand.neutral.700',
    m: '0 !important',
  },
  answer: {
    textStyle: 'xs',
    color: 'brand.neutral.600',
    m: '4px 0 0 0 !important',
  },
  disclaimer: {
    mt: '20px !important',
  },
  wrapper: {
    mt: '16px !important',
  },
  accordion: {
    border: 'none',
  },
  accordionbutton: {
    h: 'auto',
    padding: '16px 0',
    background: 'brand.neutral.white',
    justifyContent: 'space-between',
    textAlign: 'left',
    boxShadow: 'none !important',
    _hover: {
      background: 'brand.neutral.white',
    },
  },
  accordioncontent: {
    border: 'none',
    p: '0 0 16px',
  },
  iconcontainer: {
    m: '0 3px 0 24px !important',
  },
  divider: {
    m: '0 !important',
    borderColor: 'brand.darkBlue.100',
  },
};
