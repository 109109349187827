import React from 'react';
import { BaselaneButton, BaselaneDrawer } from '@shared/components';

type AddInvoiceFooterProps = {
  editState: boolean,
  handleCancelButton: Function,
  dirty: boolean,
  handleSubmit: Function,
  onClose: Function,
  loading: boolean,
  isValid: boolean,
};

const AddInvoiceFooter = ({
  editState,
  handleCancelButton,
  dirty,
  handleSubmit,
  onClose,
  loading,
  isValid,
}: AddInvoiceFooterProps) => {
  const { DrawerFooter } = BaselaneDrawer;

  return (
    <DrawerFooter gap={2} position="absolute" bottom="0px" left="0" width="100%">
      <BaselaneButton
        variant="outline"
        palette="transparent"
        size="md"
        onClick={() => {
          if (editState) {
            handleCancelButton({ dirty });
          } else {
            onClose();
          }
        }}
      >
        Cancel
      </BaselaneButton>
      <BaselaneButton
        variant="filled"
        palette="primary"
        size="md"
        onClick={() => {
          if (loading) return;
          handleSubmit();
        }}
        isLoading={loading}
        isDisabled={!isValid}
      >
        Create Invoice
      </BaselaneButton>
    </DrawerFooter>
  );
};

export default AddInvoiceFooter;
