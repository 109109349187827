import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const multiDigitConfig = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalSymbol: '',
  allowNegative: false,
  allowLeadingZeroes: false,
};

export default createNumberMask(multiDigitConfig);
