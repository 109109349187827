import React from 'react';
import type { IconPropsType } from './types/icon.types';

function IconUIArrowRight({ w = 18, h = 10, color = 'currentColor' }: IconPropsType) {
  return (
    <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3685 7.14645C14.5638 6.95118 14.8804 6.95118 15.0757 7.14645L19.5201 11.5909C19.7154 11.7862 19.7154 12.1027 19.5201 12.298L15.0757 16.7424C14.8804 16.9377 14.5638 16.9377 14.3685 16.7424C14.1733 16.5472 14.1733 16.2306 14.3685 16.0353L17.9594 12.4444H4.49988C4.22374 12.4444 3.99988 12.2206 3.99988 11.9444C3.99988 11.6683 4.22374 11.4444 4.49988 11.4444H17.9594L14.3685 7.85355C14.1733 7.65829 14.1733 7.34171 14.3685 7.14645Z"
        fill={color}
      />
    </svg>
  );
}

IconUIArrowRight.defaultProps = {
  w: '24',
  h: '24',
  color: 'currentColor',
};

export default IconUIArrowRight;
