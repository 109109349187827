import React from 'react';
import { Stack, Text, UnorderedList, ListItem, Box } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { IconClock } from '@icons';
import { getQueryParams } from '@core/Services/Firebase/firebase';
import beforeBeginningPointers from './helpers/pointers.helpers';
import {
  bodyMdTextMediumBoldStyles,
  bodyTextMediumBoldStyles,
  bodyTextSmallLightTextStyles,
  bodyTextStyles,
  headingTextStyles,
} from '../styles/fonts.styles';
import { contentContainerStyles } from '../styles/intro.styles';

const BeforeBeginningUI = () => {
  const selection = getQueryParams(window.location, 'selection');
  const { points, subMessage, sectionedPoints } = beforeBeginningPointers[selection] || {};
  const isBeforeYouBegin = true;

  return (
    <Stack {...contentContainerStyles(isBeforeYouBegin)}>
      <Stack spacing="4px">
        <Text {...headingTextStyles}>Before you begin</Text>
        <Stack direction="row" align="center" spacing="5px" {...bodyTextSmallLightTextStyles}>
          <IconClock w={15} h={15} />
          <Text>{subMessage}</Text>
        </Stack>
      </Stack>

      <Stack spacing={selection === 'landlord_banking' ? 6 : '18px'}>
        <Text {...bodyMdTextMediumBoldStyles}>Information you will need:</Text>
        {points && (
          <Box>
            <UnorderedList spacing="18px" ml="22px">
              {points.map(({ text, subText }) => (
                <ListItem className="list-item-checkmark" key={uuidv4()}>
                  <Text {...bodyTextStyles}>{text}</Text>
                  {subText && <Text {...bodyTextSmallLightTextStyles}>{subText}</Text>}
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}
        {sectionedPoints && (
          <Stack spacing={3}>
            {sectionedPoints.map(({ heading, innerPoints }) => (
              <Box key={uuidv4()}>
                <Text {...bodyTextMediumBoldStyles} color="brand.neutral.600" mb="18px">
                  {heading}
                </Text>
                <UnorderedList spacing="18px" ml="22px">
                  {innerPoints.map(({ text, subText }) => (
                    <ListItem className="list-item-checkmark" key={uuidv4()}>
                      <Text {...bodyTextStyles}>{text}</Text>
                      {subText && <Text {...bodyTextSmallLightTextStyles}>{subText}</Text>}
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default BeforeBeginningUI;
