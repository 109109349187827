import { cardContainerStyles } from '@pages/BankEntity/AccountCards/styles/card.styles';

export const addNewCardButtonStyles = {
  ...cardContainerStyles,
  color: 'brand.blue.800A',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  spacing: 0,
};
