import { useEffect, useRef } from 'react';

const useAddFundsOpen = () => {
  const addFundsRef = useRef();

  const triggerAddFunds = localStorage.getItem('component-trigger');
  useEffect(() => {
    if (triggerAddFunds && addFundsRef?.current && triggerAddFunds === 'add_funds') {
      addFundsRef.current.onOpenAddFundsDrawer();
      localStorage.removeItem('component-trigger');
    }
  }, [triggerAddFunds]);

  return { addFundsRef };
};
export default useAddFundsOpen;
