import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { BaselaneButtonIcon } from '@shared/components';
import { extraSmallText, smallText } from './transfers.styles';
import { getStatementPdf, getStatementPeriod } from './statement.helper';
import { IconDownload } from '../../../../../Icons';
import { getIsTokenExpired, getTokenData } from '../../../../helpers/otp.helpers';

const handleStatementDownload = (
  e,
  statements,
  setBankId,
  setUnitStatementId,
  unitAPINonSensitiveToken,
  showErrorToast
) => {
  const [bkId, statementId] = e.currentTarget.value.split(',');

  setBankId(Number(bkId));
  setUnitStatementId(Number(statementId));

  const { nonSensitive } = getTokenData(bkId);
  const isNonSensitiveTokenExpired = getIsTokenExpired(bkId, true);

  if (isNonSensitiveTokenExpired) {
    unitAPINonSensitiveToken({ variables: { bankId: Number(bkId) } });
  } else {
    getStatementPdf(Number(statementId), nonSensitive.token, statements, showErrorToast);
  }
};

const getGridItems = ({
  statements,
  setBankId,
  setUnitStatementId,
  unitAPINonSensitiveToken,
  showErrorToast,
}) =>
  statements.reduce((items, item, index) => {
    const isLastRow = index === statements.length - 1;
    const borderStyles = isLastRow
      ? { border: 'none' }
      : { borderBottomWidth: '1px', borderColor: 'brand.darkBlue.150' };

    return items.concat([
      {
        styles: {
          ...borderStyles,
        },
        element: (
          <Stack spacing={0}>
            <Text {...smallText}>{item?.masterAccountName}</Text>
            {item?.accountName && <Text {...extraSmallText}>{item.accountName}</Text>}
          </Stack>
        ),
      },
      {
        styles: {
          ...borderStyles,
        },
        element: <Text {...smallText}>{getStatementPeriod(item.period)}</Text>,
      },
      {
        styles: {
          justifyContent: 'flex-end',
          ...borderStyles,
        },
        element: (
          <Box pr="12px">
            <BaselaneButtonIcon
              variant="outline"
              palette="neutral"
              size="sm"
              value={[item.bankId, item.unitStatementId]}
              icon={<IconDownload />}
              onClick={(e) => {
                try {
                  handleStatementDownload(
                    e,
                    statements,
                    setBankId,
                    setUnitStatementId,
                    unitAPINonSensitiveToken,
                    showErrorToast
                  );
                } catch (error) {
                  showErrorToast(error);
                }
              }}
            />
          </Box>
        ),
      },
    ]);
  }, []);

export default getGridItems;
