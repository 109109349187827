// Chakra Example: https://github.com/chakra-ui/chakra-ui/blob/v1/packages/theme/src/foundations/spacing.ts
// Figma: https://www.figma.com/file/IzR58By56T8Dvo2tLLPCbP/*Baselane---Design-System-(Habitat)?mode=dev

const spacing = {
  space: {
    px: '1px',
    0: '0px',
    0.25: '2px',
    0.5: '4px',
    0.75: '6px',
    1: '8px',
    1.5: '12px',
    2: '16px',
    2.5: '20px',
    3: '24px',
    4: '32px',
    5: '40px',
    6: '48px',
    8: '64px',
    10: '80px',

    // these are spacings that exist in Chakra, but are not in the design system.
    // they are overridden with null string values
    3.5: '',
    9: '',
    7: '',
    12: '',
    14: '',
    16: '',
    20: '',
    24: '',
    28: '',
    32: '',
    36: '',
    40: '',
    44: '',
    48: '',
    52: '',
    56: '',
    60: '',
    64: '',
    72: '',
    80: '',
    96: '',
  },
};

export default spacing;
