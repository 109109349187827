export const getTrackerElementNodes = (box, isAdd) => {
  const header = box.querySelector('#onboarding-box-header');
  const body = box.querySelector('#onboarding-box-body');
  const timer = box.querySelector('#onboarding-box-time');

  if (isAdd) {
    header?.classList.add('hovered');
    body?.classList.add('hovered');
    timer?.classList.add('hovered');
  } else {
    header?.classList.remove('hovered');
    body?.classList.remove('hovered');
    timer?.classList.remove('hovered');
  }
};
