import React from 'react';
import { Text, Stack } from '@chakra-ui/react';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { DisplayInputDropdownOption } from '@shared/components';
import { lastYear } from '@shared/components/BaselaneDropdown-new/helpers/datepickerDropdown.helpers';
import { Highlighted } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { handlePopupClose } from '@shared/components/BaselaneDropdown-new/helpers/popup.shared.helpers';
import { menuItemTextStyles, menuItemSubAccountStyles } from '../styles/transactionsFilters.styles';

export const csvHeaders = [
  { label: 'Account', key: 'account' },
  { label: 'Date', key: 'date' },
  { label: 'Merchant', key: 'merchantName' },
  { label: 'Description', key: 'name' },
  { label: 'Amount', key: 'amount' },
  { label: 'Category', key: 'category' },
  { label: 'Property', key: 'property' },
  { label: 'Notes', key: 'note' },
];

export const csvDataMapper = (
  data,
  categoryMap,
  accountMap,
  propertyMap,
  showHiddenTransactions
) => {
  let formatedData = cloneDeep(data);
  if (!showHiddenTransactions) {
    formatedData = formatedData.filter((t) => t.hidden === false || t.hidden === undefined);
  }
  return formatedData.map((t) => {
    let account = accountMap()[t.bankAccountId];
    const manualPrefix = t.isManual ? 'Manually Added- ' : '';
    if (typeof account === 'object') {
      const { name, subAccountName, accountNumber } = account;

      const last4Digits = accountNumber
        ? `-${accountNumber.substring(accountNumber.length - 4, accountNumber.length)}`
        : '';

      account = `${manualPrefix}${name}-${subAccountName}${last4Digits}`;
    } else {
      account = `${manualPrefix}${account}`;
    }

    return {
      account,
      date: moment(t.date).format('MMMM DD, YYYY'),
      name: t.name,
      merchantName: t.merchantName,
      amount: t.amount,
      category: categoryMap[t.tagId],
      property: propertyMap[t.propertyId],
      note: t.note?.text,
    };
  });
};

export const renderAccountDropdownItem = ({ item, search }) => {
  return (
    <Stack gap={0}>
      <Text {...menuItemTextStyles} color="inherit">
        <Highlighted text={item?.bankName} highlight={search} />
      </Text>
      <Text {...menuItemSubAccountStyles}>
        <Highlighted text={item?.account} highlight={search} />
      </Text>
    </Stack>
  );
};

export const customDropdownOption = (
  onOpen,
  isMax576,
  setShowMobileDropdownPopup,
  setShowMobileDropdownPopupAnimation
) => {
  return (
    <DisplayInputDropdownOption
      key="customOption"
      onOpen={(e) => {
        onOpen(e);
        if (isMax576) {
          handlePopupClose({
            setShowMobileDropdownPopup,
            setShowMobileDropdownPopupAnimation,
          });
        }
      }}
      w="100%"
      text="Custom..."
      textStyle="xs"
    />
  );
};

// TODO: if this dataset is used again please bring up into shared file
export const dateData = (onOpen, isMax576) => [
  { id: 'this_month', name: 'This Month' },
  { id: 'last_month', name: 'Last Month' },
  { id: 'last_6_months', name: 'Last 6 Months' },
  { id: 'last_12_months', name: 'Last 12 Months' },
  {
    id: `${lastYear.format('YYYY')} (Jan-Dec)`,
    name: `${lastYear.format('YYYY')} (Jan-Dec)`,
  },
  {
    id: 'year_to_date',
    name: 'Year to date',
  },
  {
    id: 'custom',
    name: 'Custom',
    showDivider: true,
    customDropdownOption: (setShowMobileDropdownPopup, setShowMobileDropdownPopupAnimation) =>
      customDropdownOption(
        onOpen,
        isMax576,
        setShowMobileDropdownPopup,
        setShowMobileDropdownPopupAnimation
      ),
  },
];
