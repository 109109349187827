import { gql } from '@apollo/client';

const FRAGMENT_UNIT_ADDRESS = gql`
  fragment UnitAddressObj on UnitAddress {
    street
    unit
    city
    state
    postalCode
    country
  }
`;

const FRAGMENT_ACCOUNT_DATA = gql`
  fragment AccountDataObj on BankAccount {
    id
    accountNumber
    nickName
    accountName
    accountType
    accountSubType
    currentBalance
    availableBalance
    routingNumber
    isConnected
    rentCollectionSupported
    transferSupported
    transactionSupported
    connectionState
    closed
    accountStatus
    plaidAccountId
    userInstitutionId
    institutionName
    stripeBankAccountId
    isExternal
    createdAt
    autoTag {
      enabled
      propertyUnitId
      propertyId
    }
    cards {
      id
      isPhysical
      last4Digits
      cardStatus
    }
    linkedFor {
      linkFor
      property {
        id
        propertyUnitId
      }
    }
    checkBookAvailability {
      isAvailable
      latestOrderDate
      nextOrderDate
      lastCheckNumber
    }
  }
`;

export const GET_BANK_ENTITY_BY_ID = gql`
  query Bank(
    $accountId: [Float!]
    $isConnectedAccount: Boolean
    $accountStatus: BankAccountStatus
    $connectionState: BankAccountState
  ) {
    bank(
      input: {
        accountId: $accountId
        isConnectedAccount: $isConnectedAccount
        accountStatus: $accountStatus
        connectionState: $connectionState
      }
    ) {
      id
      plaidItemId
      name
      isConnected
      logo
      inTransitBalance
      pendingReceivedPayments
      unitAccount {
        id
        phoneNumber
        legalBusinessName
        legalAddress {
          ...UnitAddressObj
        }
        mailingAddress {
          ...UnitAddressObj
        }
        applicationType
      }
      bankAccounts {
        ...AccountDataObj
        subAccounts {
          ...AccountDataObj
        }
      }
    }
  }
  ${FRAGMENT_UNIT_ADDRESS}
  ${FRAGMENT_ACCOUNT_DATA}
`;

export const UPDATE_BANK_ENTITY = gql`
  mutation updateBank($id: ID!, $mailingAddress: UnitAddressInput, $name: String) {
    updateBank(input: { id: $id, mailingAddress: $mailingAddress, name: $name }) {
      id
      name
      unitAccount {
        id
        mailingAddress {
          ...UnitAddressObj
        }
      }
    }
  }
  ${FRAGMENT_UNIT_ADDRESS}
`;
