import { React, useEffect } from 'react';
import { Text, Box } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import customTheme from '@theme';
import { BaselaneCardNew } from '@shared/components';
import { Icon16Person, Icon16Property } from '@icons/16px';

type RecipientDetailsCardProps = { recipient: Object };

const RecipientDetailsCard = ({ recipient = {} }: RecipientDetailsCardProps) => {
  const { name, email, type } = recipient || {};

  const formik = useFormikContext();
  const { setFieldValue } = formik;

  useEffect(() => {
    setFieldValue('name', name);
    setFieldValue('email', email);
    setFieldValue('type', type);
  }, []);
  return (
    <Box mb={2}>
      <Text textStyle="xs" mb={0.75}>
        Recipient
      </Text>
      <BaselaneCardNew size="sm" palette="dark">
        <Box marginRight={1.5} py={0.5}>
          {type === 'INDIVIDUAL' ? (
            <Icon16Person color={customTheme.colors.brand.neutral['700']} />
          ) : (
            <Icon16Property color={customTheme.colors.brand.neutral['700']} />
          )}
        </Box>
        <Box>
          <Text textStyle="sm" color="brand.neutral.700">
            {name}
          </Text>
          <Text textStyle="xs" color="brand.neutral.600">
            {email}
          </Text>
        </Box>
      </BaselaneCardNew>
    </Box>
  );
};
export default RecipientDetailsCard;
