import { isMobile } from 'react-device-detect';
import customTheme from '@core/theme';

/**
 * Configuration
 */

// Controls the space between individual tabs
const tabSpacing = 16;

/**
 * Tab Container Styles
 *
 * Usage: Apply this to the container surrounding the <Tabs> element.
 */

export const tabContainerStyles = {
  bg: 'brand.neutral.white',
  borderRadius: '6px 6px 0 0 !important',
};

/**
 * Tab Nav Link Wrapper Styles (Non-Chakra)
 *
 * Usage: Apply to non-chakra elements like <NavLink> (react-router-dom)
 * when used to wrap <Tab>.
 */

export const tabWrapperVanillaJsStyles = (isMin768) => ({
  style: isMin768
    ? {
        marginRight: `${tabSpacing}px`,
      }
    : {
        flex: 1,
        alignItems: 'center',
      },
});

/**
 * Tab Nav Link Wrapper Styles (Chakra)
 *
 * Usage: Apply to elements used to wrap the <Tab> elements when needed.
 */

export const tabWrapperStyles = (isMin768) => ({
  flex: isMobile ? '1' : 'initial',
  alignItems: isMin768 ? 'initial' : 'center',
  mr: `${tabSpacing}px !important`,
});

/**
 * Tab Styles
 *
 * Usage: Apply this to the actual clickable <Tab> elements.
 */

export const tabStyles = (isMin768) => ({
  h: '50px',
  w: { base: '100%', md: '' },
  p: { base: '16px 8px 20px 8px', md: '16px 16px 24px 16px' },
  color: 'brand.neutral.500',
  fontWeight: 'semibold',
  fontSize: { base: 'xs', md: 'sm' },
  whiteSpace: 'nowrap',
  flex: { md: '0', xl: '0' },
  lineHeight: '24px',
  borderRadius: '0',
  position: 'relative',
  top: '2px',
  mr: `${tabSpacing}px !important`,
  _selected: {
    color: 'brand.darkBlue.800A',
    fontWeight: 'semibold',
    borderBottom: isMin768 ? 'none' : `solid 2px ${customTheme.colors.brand.darkBlue['800A']}`,
    mt: isMin768 ? '0' : '2px',
    boxShadow: isMin768 ? `0 -3px 0 ${customTheme.colors.brand.darkBlue['800A']} inset` : 'none',
  },
  _last: {
    mr: `0 !important`,
  },
  _disabled: { opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' },
});

/**
 * Tab List Styles (Static Position on Mobile)
 *
 * Usage: Apply this to the <TabList> elements, when the mobile
 * version is to remain statically positioned within the content
 * area of the page.
 */

export const tabListInternalStyles = () => ({
  p: { base: 0, md: '0 24px', xs: '0 16px' },
  borderBottom: `1.5px solid ${customTheme.colors.brand.blue['100']}`,
  w: '100%',
  direction: 'row',
  borderTop: '1px solid transparent',
  borderRadius: '6px 6px 0 0',
  bg: 'brand.neutral.white',
  display: 'flex',
  justifyContent: 'stretch',
});

/**
 * Tab List Styles (Fixed Position on Mobile)
 *
 * Usage: Apply this to the <TabList> elements, when the mobile
 * version is to be fixed to the top of the page header.
 */

export const tabListStyles = (isMin768) => ({
  ...tabListInternalStyles(isMin768),
  position: isMin768 ? 'static' : 'fixed',
  top: isMin768 ? 'unset' : '92px',
  left: isMin768 ? 'unset' : '80px',
});

/**
 * Tab Panel Styles
 *
 * Usage: Apply this to the <TabPanels> element, and override
 * inline for any page-specific style changes.
 */

export const tabPanelsStyles = {
  p: isMobile ? '16px' : '24px',
  bg: isMobile ? 'brand.neutral.white' : '',
};
