// @flow
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGate } from 'statsig-react';
import moment from 'moment';
import {
  Box,
  Flex,
  Stack,
  Tabs,
  TabPanels,
  TabPanel,
  Text,
  useToast,
  HStack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { LEASES_QUICKPAY_LEARN_MORE } from '@routes';
import { IconInfo } from '@icons';
import { Icon12Info, Icon12QuestionMark } from '@icons/12px';
import QuickPayIconOrBadge from '@pages/LeasesPage/components/QuickPayIconOrBadge';
import {
  BaselaneButtonToggle,
  BaselaneDivider,
  BaselaneList,
  BaselaneMessageCard,
  BaselaneDrawer,
  BaselaneLink,
  BaselaneToolpop,
  BaselaneTooltip,
  BaselaneSummaryCard,
  BaselaneSummaryCardListGroup,
  BaselaneSummaryCardListItem,
} from '@shared/components';
import { useUserContext } from '@core/contexts/UserContext';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import getBreakPoints from '@core/utils/getBreakPoints';
import { achFeeEnabledFeatureFlag } from '@core/constants/envVars';
import formatCurrency from '@core/utils/formatCurrency';
import { DELETE_INVOICE, UPDATE_INVOICE, MARK_AS_PAID } from '../../../queries';
import InvoiceDetailsActions from './InvoiceDetailsActions';
import InvoiceDetailsAlerts from './InvoiceDetailsAlerts';
import InvoiceDetailsHeader from './InvoiceDetailsHeader';
import AddNewInvoiceItem from './AddNewInvoiceItem';
import InvoiceTimeline from '../InvoiceTimeline';
import { UNPAID } from '../../../constants';

import {
  containerstyles,
  detailBox,
  textStyles2,
  textStyles,
  invoiceDetailsHeader,
} from '../../../styles/invoiceDetails.style';
import {
  getInitialFeesData,
  getGeneralInfoList,
  getInvoiceDetailsList,
  listItemRenderer,
  saveAlertItems,
  deleteAlertItems,
  formatValuesForInvoicesAPI,
  getTotalAmount,
  initialValues,
  markAsPaidAlertItems,
  getInvoiceChargeList,
} from '../../../helpers/invoiceDetails.helper';
import InvoiceDetailsFooter from './InvoiceDetailsFooter';

type InvoiceDetailBodyProps = {
  invoiceDetails: Object,
  setIsDirty: Function,
  editState: boolean,
  setEditState: Function,
  formikRef: any,
  onClose: Function,
  leaseTenant: string,
  setHasUpdatedInvoice: Function,
  isPropertyDeleted: boolean,
  isMultiUnit: boolean,
  refreshInvoiceDetails: Function,
  refetchLeaseInvoices: Function,
  handleSetInvoiceDetails: Function,
  isQuickPay: boolean,
};

function InvoiceDetailBody({
  invoiceDetails,
  setIsDirty,
  editState,
  setEditState,
  formikRef,
  onClose,
  leaseTenant,
  setHasUpdatedInvoice = () => {},
  isPropertyDeleted,
  isMultiUnit,
  refreshInvoiceDetails = () => {},
  refetchLeaseInvoices = () => {},
  handleSetInvoiceDetails = () => {},
  isQuickPay,
}: InvoiceDetailBodyProps): any {
  const { value: quickPayLearnMoreValue } = useGate('quick_pay_announce_banner_gate');
  const { user } = useUserContext();
  const { isMinXL } = getBreakPoints();
  const location = useLocation() || {};
  const { state: actualState } = location || {};

  const {
    id,
    amount,
    applicationFeeChargeToLandlord,
    state,
    description,
    items,
    timeline,
    propertyUnit,
    invoiceCharge,
    invoiceMarkedPaid: isMarkedAsPaid,
    payoutAmount,
    isQuickPaid,
    destinationBankAccount: bankingAccount,
  } = invoiceDetails || {};

  const { leaseId, invoiceId } = actualState || {};

  const originalState = {
    leaseId,
    invoiceId,
    noRefresh: true,
    noOpen: true,
  };
  const onButtonClick = (e) => {
    e.stopPropagation();
    sendSegmentEvent('quickpay_click_learn_more');
  };

  const { invoiceProcessingFeePaidBy: accountWideProcessingFeePaidBy } = user.userMetadata;
  const invoiceProcessingFeePaidBy = propertyUnit?.lease?.invoiceProcessingFeePaidByOverridden
    ? propertyUnit?.lease?.invoiceProcessingFeePaidBy
    : accountWideProcessingFeePaidBy;

  const { propertyName, unitName, propertyAddress } = propertyUnit || {};
  const [deleteInvoice] = useMutation(DELETE_INVOICE);
  const [updateInvoice] = useMutation(UPDATE_INVOICE);
  const [markAsPaidInvoice] = useMutation(MARK_AS_PAID);

  const [tabIndex, setTabIndex] = useState(0);
  const [lastTabIndex, setLastTabIndex] = useState(0);
  const invoiceState = state === 'AUTOPAY' ? 'modifyAutoPay' : 'modifyScheduled';

  // fee errors
  const [descriptionError, setDescriptionError] = useState(false);
  const [amountError, setAmountError] = useState(false);

  const [updatedInvoices, setUpdateInvoices] = useState([]);
  const [totalAmount, setTotalAmount] = useState(amount);

  // alert states
  const [alertType, setAlertType] = useState(invoiceState);
  const [isUnsavedChangesAlertOpen, setIsUnsavedChangesAlertOpen] = useState(false);
  const [isSaveAlertOpen, setIsSaveAlertOpen] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [isMarkAsPaidAlertOpen, setIsMarkAsPaidAlertOpen] = useState(false);
  const onUnsavedChangesAlertOpen = () => setIsUnsavedChangesAlertOpen(true);
  const onUnsavedChangesAlertClose = () => setIsUnsavedChangesAlertOpen(false);
  const onSaveAlertOpen = () => setIsSaveAlertOpen(true);
  const onSaveAlertClose = () => setIsSaveAlertOpen(false);
  const onDeleteAlertOpen = () => setIsDeleteAlertOpen(true);
  const onDeleteAlertClose = () => setIsDeleteAlertOpen(false);
  const onMarkAsPaidAlertOpen = () => setIsMarkAsPaidAlertOpen(true);
  const onMarkAsPaidAlertClose = () => setIsMarkAsPaidAlertOpen(false);

  const cancelAlertRef = useRef();
  const cancelSaveAlertRef = useRef();
  const cancelDeleteAlertRef = useRef();
  const cancelMarkAsPaidAlertRef = useRef();

  const toast = useToast();

  const showMarkAsPaidSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: `Invoice marked as paid successfully`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showMarkAsPaidErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: `Failed to mark invoice as paid`,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleOnSubmit = (values) => {
    const valuesFormattedForAPI = formatValuesForInvoicesAPI(id, values);
    setUpdateInvoices(valuesFormattedForAPI);

    if (state === 'AUTOPAY') {
      setAlertType('modifyAutoPay');
      onSaveAlertOpen();
    } else {
      setAlertType('modifyScheduled');
      onSaveAlertOpen();
    }
  };

  const handleSave = () => {
    setEditState(false);
    setTabIndex(lastTabIndex);
    onSaveAlertClose();

    updateInvoice({ variables: { ...updatedInvoices } })
      .then((res) => {
        const { dueDate: resDueDate, items: resItems } = res?.data?.updateInvoice || {};

        const formattedDueDate = moment(resDueDate, moment.defaultFormat).toDate();
        formikRef?.current?.resetForm({
          values: { dueDate: formattedDueDate, fees: getInitialFeesData(resItems) },
        });
        handleSetInvoiceDetails(res?.data?.updateInvoice);
        setHasUpdatedInvoice(true);
        refreshInvoiceDetails(res?.data?.updateInvoice);
        // refetchLeaseInvoices();
      })
      .catch(() => {
        console.log('was not able to update invoice');
        formikRef?.current?.resetForm({
          values: initialValues(invoiceDetails),
        });
      });
  };

  const handleCancelButton = ({ dirty }) => {
    if (dirty) {
      onUnsavedChangesAlertOpen();
    } else {
      onUnsavedChangesAlertClose();
      setEditState(false);
      setTabIndex(lastTabIndex);
      formikRef?.current.resetForm({
        values: initialValues(invoiceDetails),
      });
    }
  };

  const handleValidation = (values) => {
    const errors = {};
    const editMode = values?.fees?.filter((fee) => fee.edit);

    if (editMode?.length > 0) {
      errors.fees = 'Fill in all fields';
    }

    return errors;
  };

  const handleDeleteInvoice = () => {
    onDeleteAlertClose();
    deleteInvoice({ variables: { id } })
      .then(() => {
        setHasUpdatedInvoice(true);
        onClose(true);
      })
      .catch(() => {
        console.log('was not able to delete invoice');
        onClose(true);
      });
  };

  const handleContinueButtonClick = () => {
    setTotalAmount(amount);
    handleCancelButton({});
    onUnsavedChangesAlertClose();
  };

  const handleMarkAsPaidInvoice = () => {
    onMarkAsPaidAlertClose();
    markAsPaidInvoice({ variables: { id } })
      .then((res) => {
        if (res.errors?.length > 0) showMarkAsPaidErrorToast();
        else {
          setHasUpdatedInvoice(true);
          showMarkAsPaidSuccessToast();
          refreshInvoiceDetails(res.data.markInvoiceAsPaid);
          refetchLeaseInvoices();
        }
      })
      .catch(() => {
        showMarkAsPaidErrorToast();
      });
  };
  // List Info
  const generalInfoList = (dirty, values) =>
    getGeneralInfoList(
      invoiceDetails,
      editState,
      totalAmount,
      dirty,
      setIsDirty,
      values,
      formikRef,
      achFeeEnabledFeatureFlag ? invoiceCharge?.paymentMethod?.paymentType ?? undefined : undefined
    );

  const invoiceDetailsList = getInvoiceDetailsList(items);

  const invoiceChargesList = getInvoiceChargeList(
    totalAmount,
    achFeeEnabledFeatureFlag && invoiceProcessingFeePaidBy === 'LANDLORD',
    applicationFeeChargeToLandlord,
    invoiceCharge?.metadata?.applicationFeeWaivedChargedReason
  );

  const showPayoutTotal =
    !(state === 'OVERDUE' || state === 'UPCOMING' || state === 'AWAITING_VERIFICATION') &&
    !(state === 'FAILED' && invoiceCharge?.payoutStatus !== 'PAID');

  const showEditButton =
    editState || state === 'PROCESSING' || state === 'COMPLETED' || state === 'DELETED';

  const quickPaidToolTipText = (
    <>
      <Text textAlign="left" style={{ textWrap: 'wrap' }}>
        Payments processed with QuickPay are typically deposited in your bank account in 2-3
        business days.
      </Text>
      {quickPayLearnMoreValue && (
        <BaselaneLink
          variant="reverse"
          to={LEASES_QUICKPAY_LEARN_MORE}
          state={{ from: location.pathname, originalState }}
          size="md"
          onClick={(e) => onButtonClick(e)}
        >
          Learn more{' '}
          <Box as="span" fontSize="16px" lineHeight="20px" mb="2px">
            ›
          </Box>
        </BaselaneLink>
      )}
    </>
  );

  const quickPaidFailedToolTipText =
    "This typically occurs if we cannot verify the availability of funds for your tenant's payment method.";
  const showIsQuickPaid = isQuickPaid && state === 'PROCESSING';
  const labelIsQuickPaid = (
    <Flex mt="6px" alignItems="center">
      <QuickPayIconOrBadge isIconOnly isGreen isDisabled />
      <Text ml="8px" mr="8px" fontSize="12px">
        This payment is being processed faster with QuickPay
      </Text>
      <Box m="0 2px 0 1px">
        <BaselaneToolpop trigger={<Icon12QuestionMark color="#000619" />}>
          {quickPaidToolTipText}
        </BaselaneToolpop>
      </Box>
    </Flex>
  );
  const showWasQuickPaid = isQuickPaid && !isMarkedAsPaid && state === 'COMPLETED';
  const labelWasQuickPaid = (
    <Flex mt="6px" alignItems="center">
      <QuickPayIconOrBadge isIconOnly isGreen isDisabled />
      <Text ml="8px" mr="8px" fontSize="12px">
        This payment was processed faster with QuickPay
      </Text>
      <Box m="0 2px 0 1px">
        <BaselaneToolpop trigger={<Icon12QuestionMark color="#000619" />}>
          {quickPaidToolTipText}
        </BaselaneToolpop>
      </Box>
    </Flex>
  );
  const showWasNotQuickPaid =
    isQuickPay &&
    !isMarkedAsPaid &&
    !isQuickPaid &&
    (state === 'PROCESSING' || state === 'COMPLETED');
  const labelWasNotQuickPaid = (
    <Flex mt="6px" alignItems="center" fontSize="12px">
      <Box m="0 2px 0 1px">
        <Icon12Info color="#000619" />
      </Box>
      <Text ml="8px" mr="8px">
        This payment was not eligible for QuickPay
      </Text>
      <BaselaneTooltip label={quickPaidFailedToolTipText}>
        <Text as="u">Why?</Text>
      </BaselaneTooltip>
    </Flex>
  );
  const showWasMarkedAsPaid = isMarkedAsPaid;
  const labelWasMarkedAsPaid = (
    <Flex mt="6px" alignItems="center" fontSize="12px">
      <Box m="0 2px 0 1px">
        <Icon12Info color="#000619" />
      </Box>
      <Text ml="8px" mr="8px">
        You marked this invoice as paid
      </Text>
    </Flex>
  );

  // get alert elements
  const { saveAlertBodyText, saveAlertFooter, saveAlertHeader } = saveAlertItems(
    alertType,
    handleSave,
    cancelSaveAlertRef,
    onSaveAlertClose,
    handleDeleteInvoice
  );

  const { deleteAlertBodyText, deleteAlertFooter, deleteAlertHeader } = deleteAlertItems(
    alertType,
    cancelDeleteAlertRef,
    onDeleteAlertClose,
    handleDeleteInvoice
  );

  const {
    markAsPaidAlertBodyText,
    markAsPaidAlertFooter,
    markAsPaidAlertHeader,
  } = markAsPaidAlertItems(
    alertType,
    cancelMarkAsPaidAlertRef,
    onMarkAsPaidAlertClose,
    handleMarkAsPaidInvoice
  );

  const hasDelete = ['PROCESSING', 'DELETED', 'COMPLETED'].indexOf(state) === -1;
  const lateFeeAmount = (
    invoiceDetails && invoiceDetails?.items?.find((item) => item.paymentName === 'LATE_FEE')
  )?.amount;
  const isOverdue = invoiceDetails?.latePayment;
  const daysLate = Math.floor(
    moment.duration(moment(new Date()).diff(moment(invoiceDetails?.dueDate))).asDays()
  );

  return (
    <BaselaneDrawer.DrawerBody id="invoice-body" p="0">
      <Formik
        innerRef={formikRef}
        initialValues={initialValues(invoiceDetails)}
        validate={(values) => handleValidation(values)}
        onSubmit={handleOnSubmit}
        validateOnMount
        validateOnBlur
      >
        {({
          dirty,
          handleSubmit,
          isValid,
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          validateForm,
        }) => (
          <>
            <Box {...containerstyles({ isMinXL })}>
              <InvoiceDetailsHeader
                description={description}
                editState={editState}
                isOverdue={isOverdue}
                lateFeeAmount={lateFeeAmount}
                daysLate={daysLate}
                invoiceNumber={invoiceDetails?.id}
                leaseTenant={leaseTenant}
                propertyName={propertyName}
                unitName={unitName}
                propertyAddress={propertyAddress}
                isPropertyDeleted={isPropertyDeleted}
                isMultiUnit={isMultiUnit}
                isQuickPay={isQuickPay}
              />
              <Stack px={3} spacing={2}>
                {editState ? (
                  <Box my="0">
                    <BaselaneList
                      data={generalInfoList(dirty, values)}
                      itemRenderer={listItemRenderer}
                      listStyles={{ spacing: editState ? 3 : -1 }}
                    />

                    {showIsQuickPaid && (
                      <>
                        <BaselaneDivider styles={{ marginTop: '12px', marginBottom: '12px' }} />
                        labelIsQuickPaid
                      </>
                    )}
                    {showWasQuickPaid && (
                      <>
                        <BaselaneDivider styles={{ marginTop: '12px', marginBottom: '12px' }} />
                        labelWasQuickPaid
                      </>
                    )}
                    {showWasNotQuickPaid && (
                      <>
                        <BaselaneDivider styles={{ marginTop: '12px', marginBottom: '12px' }} />
                        labelWasNotQuickPaid
                      </>
                    )}
                    {showWasMarkedAsPaid && (
                      <>
                        <BaselaneDivider styles={{ marginTop: '12px', marginBottom: '12px' }} />
                        labelWasMarkedAsPaid
                      </>
                    )}
                    <BaselaneDivider styles={{ marginTop: '12px', marginBottom: '8px' }} />
                    <HStack justifyContent="space-between" alignItems="center" fontSize="12px">
                      <Text>Receiving account</Text>
                      <Stack textAlign="right" gap="0">
                        <Text fontSize="14px">{bankingAccount?.accountName}</Text>
                        <Text>
                          {`${bankingAccount?.institutionName} ${bankingAccount?.accountSubType} - ${bankingAccount?.accountNumber}`}
                        </Text>
                      </Stack>
                    </HStack>
                  </Box>
                ) : (
                  <BaselaneSummaryCard
                    isGroupDividerHidden={
                      !showIsQuickPaid &&
                      !showWasQuickPaid &&
                      !showWasNotQuickPaid &&
                      !showWasMarkedAsPaid
                    }
                    header={
                      <HStack justifyContent="space-between" alignItems="center" fontSize="12px">
                        {generalInfoList().map((item) => item.element)}
                      </HStack>
                    }
                    list={[
                      {
                        items: [
                          <BaselaneSummaryCardListGroup isDividerHidden>
                            {showIsQuickPaid && labelIsQuickPaid}
                            {showWasQuickPaid && labelWasQuickPaid}
                            {showWasNotQuickPaid && labelWasNotQuickPaid}
                            {showWasMarkedAsPaid && labelWasMarkedAsPaid}
                          </BaselaneSummaryCardListGroup>,
                        ],
                      },
                      {
                        items: [
                          <BaselaneSummaryCardListGroup isDividerHidden>
                            <BaselaneSummaryCardListItem
                              label="Receiving account"
                              value={bankingAccount?.accountName}
                              subtext={`${bankingAccount?.institutionName} ${bankingAccount?.accountSubType} - ${bankingAccount?.accountNumber}`}
                            />
                          </BaselaneSummaryCardListGroup>,
                        ],
                      },
                    ]}
                  />
                )}

                <InvoiceDetailsActions
                  showEditButton={showEditButton}
                  setEditState={setEditState}
                  editState={editState}
                  setTabIndex={setTabIndex}
                  tabIndex={tabIndex}
                  setLastTabIndex={setLastTabIndex}
                  onDeleteAlertOpen={onDeleteAlertOpen}
                  onMarkAsPaidOpen={onMarkAsPaidAlertOpen}
                  isMarkedAsPaid={isMarkedAsPaid}
                  hasDelete={hasDelete}
                />

                <Box mt="16px">
                  <Tabs
                    index={tabIndex}
                    orientation="horizontal"
                    variant="unstyled"
                    onChange={(index) => setTabIndex(index)}
                  >
                    {!editState && (
                      <BaselaneButtonToggle
                        firstLabel="Payment Timeline"
                        secondLabel="Invoice Breakdown"
                        activeButton={tabIndex}
                        onClick={() => setTabIndex(tabIndex === 0 ? 1 : 0)}
                        size="md"
                      />
                    )}
                    <TabPanels>
                      <TabPanel p="24px 0" key="invoice-panel-1">
                        {timeline?.length > 0 ? (
                          <InvoiceTimeline {...{ timeline }} />
                        ) : (
                          <BaselaneMessageCard
                            iconName="exclamationtriangle"
                            title="Timeline Unavailable"
                            text="There is no timeline for this invoice. If you have any questions, please contact support."
                            borderColor="warningyellow"
                            iconColor="warningyellow"
                            containerStyles={{ marginBottom: '0px', w: '100%' }}
                            isVertical
                          />
                        )}
                      </TabPanel>
                      <TabPanel p="24px 0" key="invoice-panel-2">
                        {editState ? (
                          <Text {...invoiceDetailsHeader}>Invoice Details</Text>
                        ) : (
                          <>
                            <BaselaneList
                              data={invoiceDetailsList}
                              itemRenderer={listItemRenderer}
                              listStyles={{ spacing: 3 }}
                            />
                            <BaselaneDivider styles={{ m: '14px 0' }} />
                            <BaselaneList
                              data={invoiceChargesList}
                              itemRenderer={listItemRenderer}
                              listStyles={{ spacing: 3 }}
                            />
                          </>
                        )}

                        <AddNewInvoiceItem
                          description={description}
                          editState={editState}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          dirty={dirty}
                          setIsDirty={setIsDirty}
                          amount={amount}
                          setTotalAmount={setTotalAmount}
                          getTotalAmount={getTotalAmount}
                          validateForm={validateForm}
                          descriptionError={descriptionError}
                          setDescriptionError={setDescriptionError}
                          amountError={amountError}
                          setAmountError={setAmountError}
                        />
                        {editState && (
                          <>
                            <BaselaneDivider styles={{ m: '14px 0' }} />
                            <Stack direction="row" justifyContent="space-between">
                              <Text {...textStyles}>Invoice total</Text>
                              <Text {...textStyles2}>{totalAmount}</Text>
                            </Stack>
                          </>
                        )}
                        {!editState && showPayoutTotal && (
                          <>
                            <BaselaneDivider styles={{ m: '14px 0' }} />
                            <Stack direction="row" justifyContent="space-between">
                              <Text {...textStyles}>Payout total</Text>
                              <Text {...textStyles2}>{formatCurrency(payoutAmount).inDollars}</Text>
                            </Stack>
                          </>
                        )}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                  {achFeeEnabledFeatureFlag &&
                    invoiceProcessingFeePaidBy === 'LANDLORD' &&
                    !invoiceCharge &&
                    !UNPAID?.includes(state) && (
                      <Box {...detailBox}>
                        <HStack gap="2">
                          <IconInfo color="#000000" />
                          <Text {...textStyles}>
                            $2 processing fee will be deducted from your payout if the tenant pays
                            using ACH.
                          </Text>
                        </HStack>
                      </Box>
                    )}
                </Box>
              </Stack>
            </Box>

            {editState && (
              <InvoiceDetailsFooter
                editState={editState}
                dirty={dirty}
                handleCancelButton={handleCancelButton}
                handleSubmit={handleSubmit}
                onDeleteAlertOpen={onDeleteAlertOpen}
                isValid={isValid}
                onClose={onClose}
              />
            )}
          </>
        )}
      </Formik>

      <InvoiceDetailsAlerts
        saveAlert={{
          isSaveAlertOpen,
          cancelSaveAlertRef,
          onSaveAlertClose,
          saveAlertHeader,
          saveAlertBodyText,
          saveAlertFooter,
        }}
        deleteAlert={{
          isDeleteAlertOpen,
          cancelDeleteAlertRef,
          onDeleteAlertClose,
          deleteAlertHeader,
          deleteAlertBodyText,
          deleteAlertFooter,
        }}
        unsavedChangesAlert={{
          isUnsavedChangesAlertOpen,
          cancelAlertRef,
          onUnsavedChangesAlertClose,
          handleContinueButtonClick,
        }}
        markAsPaidAlert={{
          isMarkAsPaidAlertOpen,
          cancelMarkAsPaidAlertRef,
          onMarkAsPaidAlertClose,
          markAsPaidAlertHeader,
          markAsPaidAlertBodyText,
          markAsPaidAlertFooter,
        }}
      />
    </BaselaneDrawer.DrawerBody>
  );
}

export default InvoiceDetailBody;
