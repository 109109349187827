import React from 'react';
import { Box, HStack, VStack, Text } from '@chakra-ui/react';
import BaselaneResponsiveCell from './BaselaneResponsiveCell';
import { subtextStyle, textStyle } from '../styles/cell.styles';
import { wFull } from '../styles/shared.styles';

type BaselaneResponsiveCellTextProps = {
  /**
   * Cell children
   */
  children?: any,
  /**
   * Cell text
   */
  text: String,
  /**
   * Cell text object
   */
  textObject: Object,
  /**
   * Cell subtext
   */
  subtext?: String,
  /**
   * Cell styles
   */
  styles?: Object,
  /**
   * Cell button
   */
  textButton?: any,
};

/**
 * Renders a variation of BaselaneReponsiveCell to be used inside BaselaneResponsiveTableRow
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveCellText = ({
  children,
  text,
  textObject,
  subtext,
  textButton,
  styles,
  ...rest
}: BaselaneResponsiveCellTextProps) => {
  if (subtext && textButton) {
    throw new Error('BaselaneResponsiveCell can not have both a subtext and textButton defined.');
  }

  return (
    <BaselaneResponsiveCell {...rest}>
      <HStack {...{ ...wFull, overflow: 'hidden', gap: 0 }}>
        <VStack {...{ ...wFull, overflow: 'hidden', alignItems: 'flex-start', gap: 0 }}>
          {textObject ? (
            <Box {...{ ...textStyle, ...styles?.text }}>{textObject}</Box>
          ) : (
            <Text {...{ ...textStyle, ...styles?.text }}>{text}</Text>
          )}
          {subtext ? (
            <Text {...{ ...subtextStyle, ...styles?.subtext }}>{subtext}</Text>
          ) : (
            textButton
          )}
        </VStack>
        {children}
      </HStack>
    </BaselaneResponsiveCell>
  );
};

BaselaneResponsiveCellText.defaultProps = {
  children: null,
  subtext: null,
  textButton: null,
  styles: {},
};

export default BaselaneResponsiveCellText;
