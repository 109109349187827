import React, { useRef } from 'react';
import AlertHeader from './AlertHeader';
import AlertFooter from './AlertFooter';
import BaselaneAlert from './BaselaneAlert';

type UnsavedChangesAlertProps = {
  isDrawerAlertOpen: boolean,
  onAlertClose: Function,
  onAlertContinue: Function,
};

const UnsavedChangesAlert = ({
  isDrawerAlertOpen,
  onAlertClose,
  onAlertContinue,
}: UnsavedChangesAlertProps) => {
  const alertCancelRef = useRef(null);
  const alertDrawerCloseFooter = (
    <AlertFooter
      cancelRef={alertCancelRef}
      leftButtonEvent={onAlertClose}
      rightButtonEvent={onAlertContinue}
    />
  );

  return (
    <BaselaneAlert
      isOpen={isDrawerAlertOpen}
      leastDestructiveRef={alertCancelRef}
      onClose={onAlertClose}
      header={<AlertHeader title="You Have Unsaved Changes" />}
      body="Are you sure you want to exit without saving?"
      footer={alertDrawerCloseFooter}
    />
  );
};

export default UnsavedChangesAlert;
