import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { Icon24Accounts } from '@icons/24px';
import { IconCarretDown, IconCarretRight } from '@icons';
import BaselaneAccordionWrapper from '../../BaselaneAccordionWrapper';
import BaselaneAccordion from '../../BaselaneAccordion';
import PropertyTable from './PropertyTable';
import { propertyListStyles } from './styles/propertyList.styles';
import { LeaseDataType } from '../type/types';

const { container, header, content, accordion } = propertyListStyles;
const {
  wrapper,
  header: accordionheader,
  containerStyles,
  buttonStyles,
  contentStyles,
  expandedContentStyles,
  iconContainerStyles,
} = accordion;

function PropertyList({ sortedLeases }: Array<LeaseDataType>) {
  return (
    <Stack {...container}>
      {/* Header */}
      <Stack {...header.container}>
        <Box {...header.icon.container}>
          <Icon24Accounts />
        </Box>
        <Text {...header.title}>Rent collected into external accounts</Text>
      </Stack>

      {/* Content */}
      <Stack {...content}>
        <BaselaneAccordionWrapper styles={wrapper} allowToggle defaultIndex={[-1]}>
          <BaselaneAccordion
            headerElements={
              <Text {...accordionheader}>{sortedLeases?.length} rent collection(s)</Text>
            }
            contentElements={<PropertyTable sortedLeases={sortedLeases} />}
            IconDown={IconCarretRight}
            IconUp={IconCarretDown}
            containerStyles={containerStyles}
            buttonStyles={buttonStyles}
            contentStyles={contentStyles}
            expandedContentStyles={expandedContentStyles}
            iconContainerStyles={iconContainerStyles}
          />
        </BaselaneAccordionWrapper>
      </Stack>
    </Stack>
  );
}

export default PropertyList;
