// @flow
import React, { useContext, useState } from 'react';
import { Box, HStack, Divider } from '@chakra-ui/react';
import TransactionContext from '@contexts/TransactionContext';

function TransactionNotification(): any {
  const {
    uncategorizedCount,
    totalUncategorizedHiddenTxCount,
    mtdUncategorizedUnhiddenTxCount,
    transactionsSummaryLoading,
    transactionsSummaryError,
  } = useContext(TransactionContext);
  const [isVisible] = useState(true);

  if (transactionsSummaryLoading || transactionsSummaryError) return null;

  const messageStyles = {
    textStyle: 'headline-xs',
  };

  const totalUncategorizedCount = uncategorizedCount - totalUncategorizedHiddenTxCount;

  const shouldShow = mtdUncategorizedUnhiddenTxCount + totalUncategorizedCount && isVisible;

  return shouldShow ? (
    <HStack
      spacing="10px"
      borderRadius="3px"
      maxHeight="40px"
      w="fit-content"
      bg="brand.neutral.700"
      color="brand.neutral.white"
      align="center"
      pl="20px"
      pr="20px"
      py="10px"
      {...messageStyles}
    >
      {mtdUncategorizedUnhiddenTxCount === 1 ? (
        <Box w="auto" h="auto">
          <strong>{mtdUncategorizedUnhiddenTxCount}</strong> MTD Uncategorized Transaction
        </Box>
      ) : (
        <Box w="auto" h="auto">
          <strong>{mtdUncategorizedUnhiddenTxCount ?? '0'}</strong> MTD Uncategorized Transactions
        </Box>
      )}
      <Divider
        orientation="vertical"
        m="0"
        px="0"
        py="10px"
        height="1px"
        borderColor="brand.neutral.600"
      />
      {totalUncategorizedCount === 1 ? (
        <Box w="auto" h="auto">
          <strong>{totalUncategorizedCount}</strong> Total Uncategorized Transaction
        </Box>
      ) : (
        <Box w="auto" h="auto">
          <strong>{totalUncategorizedCount}</strong> Total Uncategorized Transactions
        </Box>
      )}
    </HStack>
  ) : null;
}

export default TransactionNotification;
