/* eslint-disable no-return-assign */
// @flow
import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Text } from '@chakra-ui/react';

import { BaselaneButton } from '@shared/components';

import {
  textBoldStyle,
  textSmallStyle,
} from '@shared/components/BaselaneErrorCard/styles/errorcard.style';

import * as ROUTES from '@routes';
import UserContext from '@contexts/UserContext';

function VerificationCompletePage() {
  const { user } = useContext(UserContext);

  return (
    <>
      <Text {...textBoldStyle}>Email Verified</Text>
      <Text {...textSmallStyle} mb="24px">
        Thank you for verifying your email
      </Text>
      <Stack direction="row" spacing="28px">
        {user ? (
          <BaselaneButton
            variant="filled"
            palette="primary"
            size="lg"
            onClick={() => (window.location.href = ROUTES.DASHBOARD)}
          >
            Click here to Go to Dashboard
          </BaselaneButton>
        ) : (
          <RouterLink to={{ pathname: ROUTES.LOGIN }}>
            <BaselaneButton variant="filled" palette="primary">
              Sign In to Your Account
            </BaselaneButton>
          </RouterLink>
        )}
      </Stack>
    </>
  );
}

export default VerificationCompletePage;
