import React, { forwardRef, useState } from 'react';
import { Stack } from '@chakra-ui/react';

import BaselaneConditionalTooltip from '../BaselaneConditionalTooltip';
import { cardButtonStyles } from './styles/baselaneButtonCard.styles';

type BaselaneButtonCardProps = {
  children: any,
  onClick: Function,
  buttonStyles?: Object,
  type?: String,
  isDisabled?: boolean,
  id?: String,
  tooltipText?: string,
  onBlur?: Function,
};

const BaselaneButtonCard = forwardRef(
  (
    {
      children,
      onClick,
      buttonStyles,
      type,
      isDisabled,
      id,
      tooltipText,
      onBlur,
      ...rest
    }: BaselaneButtonCardProps,
    ref
  ) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const hasTooltip = tooltipText && tooltipText?.length;

    const styles = {
      ...cardButtonStyles(type, isDisabled),
      ...buttonStyles,
    };

    const clickHandler = (e) => {
      if (!isDisabled) {
        onClick?.(e);
      } else if (hasTooltip) {
        setIsTooltipOpen((prev) => !prev);
      }
    };

    const blurHandler = (e) => {
      if (!isDisabled) {
        onBlur?.(e);
      } else if (hasTooltip) {
        setIsTooltipOpen(false);
      }
    };

    // uses standard disabled html attribute when no tooltip,
    // if tooltip provided, uses aria-disabled
    const disabled = { [`aria-disabled`]: isDisabled };

    return (
      <BaselaneConditionalTooltip
        condition={!!tooltipText}
        tooltipText={tooltipText}
        isOpen={isTooltipOpen}
      >
        <Stack
          {...{
            id,
            ...disabled,
            ...styles,
            onClick: clickHandler,
            onBlur: blurHandler,
            ref,
            direction: 'row',
            ...rest,
          }}
        >
          {children}
        </Stack>
      </BaselaneConditionalTooltip>
    );
  }
);

BaselaneButtonCard.defaultProps = {
  buttonStyles: {},
  type: 'original',
  isDisabled: false,
  id: 'button-card',
  tooltipText: null,
  onBlur: null,
};

export default BaselaneButtonCard;
