import React from 'react';
import { Stack } from '@chakra-ui/react';
import { BaselaneChip } from '@shared/components';
import ResendInviteButton from '@features/Tenant/ResendInviteButton';
import { Icon16Check, Icon16Mail, Icon16Warning } from '@icons/16px';

function AccountStatus({ status, tenantId }: { status: string, tenantId: string }): any {
  const statusEnum = Object.freeze({
    ACTIVE: {
      label: 'Active',
      icon: Icon16Check,
      code: 'ACTIVE',
      variant: 'simple-success',
    },
    // to be phased out, b.e will be removing it their end,
    // but keep it incase there are still instances of this state for some users
    INVITE_PENDING: {
      label: 'No Invite Sent',
      icon: Icon16Warning,
      code: 'INVITE_PENDING',
      variant: 'simple-warning',
    },
    INVITE_SENT: {
      label: 'Invite Sent',
      icon: Icon16Mail,
      code: 'INVITE_SENT',
      variant: 'simple-primary',
    },
  });

  return (
    <Stack direction="row" spacing={1.5}>
      <BaselaneChip
        size="lg"
        leftIcon={statusEnum[status].icon}
        label={statusEnum[status].label}
        variant={statusEnum[status].variant}
      />

      {statusEnum[status].code === 'INVITE_SENT' && <ResendInviteButton tenantId={tenantId} />}
    </Stack>
  );
}

export default AccountStatus;
