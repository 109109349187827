import React, { useRef, useState, useEffect } from 'react';
import Select from 'react-dropdown-select';
import { Box, InputGroup, InputLeftElement, Input, Text } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { truncatedText } from '@shared/styles/text.style';
import FilterWrapper from '../FilterWrapper';
import {
  onHandleKeyDownFn,
  customDropdownHandleRenderer,
  getMultiSelectedItems,
} from '../helpers/dropdown.shared.helpers';
import '../styles/dropdown.styles.scss';
import {
  searchGroupStyles,
  searchStyles,
  listStyles,
  menuOptionStyles,
  dropdownContentContainerStyles,
} from '../styles/dropdown.styles';

type T1Props = {
  classNames?: Array<string>,
  data: Array<Object>,
  title: string,
  itemRenderer: Function,
  isMulti?: boolean,
  isDisabled?: boolean,
  searchTerm?: Array<string>,
  selectedItem?: Object,
  handleSubmit?: Function,
  showValueByFields: Array<string>,
  hasFilterWrapper?: boolean,
  filterCustomComponent?: any,
  hasActiveParentFilter?: boolean,
  setHasActiveParentFilter?: Function,
  optionStyles?: Object,
  parentId?: any,
  portalRef?: any,
  placeholder?: string,
  hasDropdownClearedExternally?: boolean,
  setHasDropdownClearedExternally?: Function,
  clearButtonText?: string,
  isClearSelectedAndStaged?: boolean,
  onClearClick?: Function,
  dropdownPosition?: string,
  hasError?: boolean,
  styles?: Object,
  additionalProps?: Object,
};

const T1 = ({
  classNames,
  data,
  title,
  itemRenderer,
  isMulti,
  isDisabled,
  searchTerm,
  selectedItem,
  handleSubmit,
  showValueByFields,
  hasFilterWrapper,
  filterCustomComponent,
  hasActiveParentFilter,
  setHasActiveParentFilter,
  optionStyles,
  parentId,
  portalRef,
  placeholder,
  hasDropdownClearedExternally,
  setHasDropdownClearedExternally,
  clearButtonText,
  isClearSelectedAndStaged,
  onClearClick,
  dropdownPosition,
  hasError,
  styles,
  additionalProps,
}: T1Props) => {
  const searchRef = useRef();
  const dropdownElementRef = useRef();

  let defaultValue = selectedItem ?? [];
  if (selectedItem) defaultValue = isMulti ? selectedItem : [selectedItem];

  const [selectedOptions, setSelectedOptions] = useState(defaultValue);
  const [selectedStagedOptions, setSelectedStagedOptions] = useState(defaultValue);

  const customContentRenderer = ({ state, methods }) => {
    const handleContentRendererClick = () => {
      if (state?.dropdown) {
        methods?.dropDown('close');
      }
    };

    const getValues = () =>
      selectedOptions.map((value) => value[showValueByFields[0]] || value[showValueByFields[1]]);

    const values =
      selectedOptions.length > 0 ? (
        <Text {...truncatedText}>{getValues().join(', ')}</Text>
      ) : (
        <Box className="placeholder" {...truncatedText}>
          {placeholder ?? title}
        </Box>
      );

    return (
      <Box
        {...{ ...dropdownContentContainerStyles, ...styles?.dropdownContentContainerStyles }}
        onClick={handleContentRendererClick}
      >
        {values}
      </Box>
    );
  };

  const handleBlurOnToggle = (id) => {
    const elem = Array.from(dropdownElementRef.current.childNodes).find((c) => c.id === id);
    elem.blur();
  };

  const handleListItemClick = (item, e) => {
    const itemToToggle = selectedStagedOptions.find((opt) => opt.id === item.id);

    if (hasActiveParentFilter) setHasActiveParentFilter(false);

    if (isMulti) {
      const itemsSelected = hasActiveParentFilter
        ? [item]
        : getMultiSelectedItems({ itemToToggle, item, selectedStagedOptions });
      setSelectedStagedOptions(itemsSelected);

      // NOTE: To fix updated styles not getting applied right away,
      //       blur the item to make it lose focus if user deselects it (only for click event)
      if (e?.type === 'click' && itemToToggle) handleBlurOnToggle(item.id);
    } else if (!itemToToggle) {
      setSelectedStagedOptions([item]);
      setSelectedOptions([item]);
      handleSubmit(item.id);
    }
  };

  const customDropdownRenderer = ({ props, state, methods }) => {
    const { options } = props ?? {};
    const { search } = state ?? {};
    const { setSearch, dropDown } = methods ?? {};

    const handleClearClick = () => {
      setSearch({ target: { value: '' } });
      setHasActiveParentFilter(false);

      setSelectedStagedOptions([]);
      if (isClearSelectedAndStaged) {
        setSelectedOptions(selectedStagedOptions);
      }

      if (onClearClick) {
        onClearClick();
      }
    };

    const handleApplyClick = () => {
      if (isMulti) setSelectedOptions(selectedStagedOptions);
      dropDown('close');
      handleSubmit(selectedStagedOptions);
    };

    const setIsSelectedItem = (item) => selectedStagedOptions.some((opt) => opt.id === item.id);

    const getCustomDropdownWithoutHeader = () => (
      <>
        {/* Search */}
        <InputGroup {...searchGroupStyles}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="#6C7884" />
          </InputLeftElement>
          <Input
            ref={searchRef}
            {...{ ...searchStyles, pl: '36px' }}
            placeholder={`Search ${title}`}
            value={search}
            onChange={setSearch}
          />
        </InputGroup>

        {/* List of Options */}
        <Box {...listStyles(hasFilterWrapper)} ref={dropdownElementRef}>
          {options
            ?.filter((item) => {
              return (
                item[searchTerm[0]].toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                (item[searchTerm[1]] &&
                  item[searchTerm[1]]?.toLowerCase().indexOf(search.toLowerCase()) !== -1)
              );
            })
            .map((item, index) => (
              <Box
                tabIndex="-1"
                className="element"
                key={item.id}
                id={item.id}
                onClick={(e) => handleListItemClick(item, e)}
                {...{ ...menuOptionStyles(setIsSelectedItem(item)), ...optionStyles }}
                mb={index === item.length - 1 && '16px'}
              >
                {itemRenderer({ item })}
              </Box>
            ))}
        </Box>
      </>
    );

    return hasFilterWrapper ? (
      <FilterWrapper
        {...{
          title,
          isMulti,
          selectedStagedOptions,
          filterCustomComponent,
          onClear: handleClearClick,
          onApply: handleApplyClick,
          clearButtonText,
        }}
      >
        {getCustomDropdownWithoutHeader()}
      </FilterWrapper>
    ) : (
      getCustomDropdownWithoutHeader()
    );
  };

  const handleClearAndApply = () => {
    setSelectedStagedOptions([]);
    setSelectedOptions([]);
  };

  const handleKeyDownFn = (eventResponse) => {
    const setOptions = (results, id) => {
      const option = results.reduce((acc, opt) => {
        if (opt.id === id) {
          acc.itemSelected = opt;
        }

        return acc;
      }, {});

      handleListItemClick(option.itemSelected);
    };

    const helpers = { dropdownElementRef, searchRef, setOptions };
    onHandleKeyDownFn(eventResponse, helpers);
  };

  const calculateDropdownSize = () => {
    const selectDropdown = document.querySelector('.react-dropdown-select-dropdown');
    const selectElem = selectDropdown.parentElement;
    const parentElem = document.querySelector(`#${parentId}`);

    // If there isn't enough space on the right, open the dropdown to the left
    const availableWidth = parentElem.clientWidth;
    const availableSpaceOnLeft = selectElem.offsetLeft;
    const availableSpaceForDropdown = availableWidth - availableSpaceOnLeft;
    const dropdownWidth = selectDropdown.clientWidth;

    // If there isn't enough space on the bottom/top, adjust the height of the dropdown
    const availableHeight = parentElem.clientHeight;
    const selectHeight = selectElem.offsetHeight;
    const availableSpaceOnTopForParent = parentElem.getBoundingClientRect().top;
    const availableSpaceOnTopForSelect = selectElem.getBoundingClientRect().top;
    const availableSpaceOnTop = availableSpaceOnTopForSelect - availableSpaceOnTopForParent;
    const availableSpaceBetweenSelectAndDropdown =
      selectDropdown.offsetTop - selectElem.clientHeight;

    const availableSpaceOnBottomForDropdown =
      availableHeight - availableSpaceOnTop - selectHeight - availableSpaceBetweenSelectAndDropdown;
    const availableSpaceOnTopForDropdown =
      availableHeight -
      availableSpaceOnBottomForDropdown -
      selectHeight -
      availableSpaceBetweenSelectAndDropdown;
    const dropdownHeight = selectDropdown.clientHeight;

    const positionName =
      Array.from(selectDropdown.classList).find((classname) => classname.includes('position')) ??
      '';
    const position = positionName.includes('top') ? 'top' : 'bottom';

    if (availableSpaceForDropdown < dropdownWidth) {
      selectDropdown.style.right = '0';
      selectDropdown.style.left = 'auto';
    }

    if (position === 'bottom' && availableSpaceOnBottomForDropdown < dropdownHeight) {
      selectDropdown.style.minHeight = `${availableSpaceOnBottomForDropdown}px`;
      selectDropdown.style.height = `${availableSpaceOnBottomForDropdown}px`;
    } else if (position === 'top' && availableSpaceOnTopForDropdown < dropdownHeight) {
      selectDropdown.style.minHeight = `${availableSpaceOnTopForDropdown}px`;
      selectDropdown.style.height = `${availableSpaceOnTopForDropdown}px`;
    }

    searchRef.current?.focus();
    setSelectedStagedOptions(selectedOptions);
  };

  useEffect(() => {
    if (hasDropdownClearedExternally) {
      handleClearAndApply();
      setHasDropdownClearedExternally(false);
    }
  }, [hasDropdownClearedExternally]);

  useEffect(() => {
    setSelectedStagedOptions(defaultValue);
    if (!hasActiveParentFilter) {
      setSelectedOptions(defaultValue);
    }
  }, [selectedItem]);

  return (
    <Select
      className={`tier1-dropdown ${hasError ? 'input-invalid' : ''} ${classNames.join(' ')}`}
      dropdownPosition={dropdownPosition}
      options={data}
      values={selectedOptions}
      disabled={isDisabled}
      dropdownGap={0}
      closeOnSelect={!isMulti}
      multi={isMulti}
      backspaceDelete={false}
      dropdownRenderer={customDropdownRenderer}
      contentRenderer={customContentRenderer}
      dropdownHandleRenderer={customDropdownHandleRenderer}
      onDropdownOpen={calculateDropdownSize}
      handleKeyDownFn={handleKeyDownFn}
      portal={portalRef}
      additionalProps={additionalProps}
    />
  );
};

T1.defaultProps = {
  classNames: ['fixed-width-dropdown'],
  isMulti: false,
  isDisabled: false,
  searchTerm: ['name'],
  selectedItem: null,
  handleSubmit: () => {},
  hasFilterWrapper: false,
  filterCustomComponent: null,
  hasActiveParentFilter: false,
  setHasActiveParentFilter: () => {},
  optionStyles: {},
  parentId: 'page-wrapper',
  portalRef: false,
  placeholder: null,
  hasDropdownClearedExternally: false,
  setHasDropdownClearedExternally: () => {},
  clearButtonText: 'Clear',
  isClearSelectedAndStaged: false,
  onClearClick: null,
  dropdownPosition: 'auto',
  hasError: false,
  styles: {},
  additionalProps: {},
};

export default T1;
