import React, { useContext, useState, useRef, useImperativeHandle } from 'react';
import { Formik } from 'formik';
import { forwardRef, useDisclosure } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneDrawer } from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import UserContext from '@contexts/UserContext';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { GET_TRANSFERS } from '@core/components/NativeBankingPage/queries';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';

import UnsavedChangesAlert from '../UnsavedChangesAlert';
import WireDrawerFooter from './WireDrawerFooter';
import handleFormViews from './helpers/views.helpers';
import { INITIATE_WIRE_TRANSFER } from './queries/transfers';
import { handleValidation } from './helpers/validation.helpers';
import { footerConfig } from './helpers/config.helpers';
import {
  addressFormInitialValues,
  formatDataForApi,
  propertyAndCategoryInitialValues,
  recipientFormInitialValues,
} from './helpers/data.helpers';
import { drawerBodyStyles } from './styles/main.styles';

type DomesticWireDrawerProps = {
  makeTransferOrPaymentDrawerRef: any,
  domesticWireDrawerRef: any,
  onCloseDomesticWireDrawer: Function,
  paymentMethod: string,
  setIsValidAccount: Function,
  setIsValidBankTransfer: Function,
  transfer: Object,
  setTransfer: Function,
  isValidBankTransfer: boolean,
  onAlertOpen: Function,
  checkToken: Function,
};

const DomesticWireDrawer = forwardRef(
  (
    {
      makeTransferOrPaymentDrawerRef,
      domesticWireDrawerRef,
      onCloseDomesticWireDrawer,
      paymentMethod,
      setIsValidAccount,
      setIsValidBankTransfer,
      transfer,
      setTransfer,
      isValidBankTransfer,
      checkToken,
    }: DomesticWireDrawerProps,
    ref
  ) => {
    const { isMinXL } = getBreakPoints();
    const [xIdempotencyKey, setXIdempotencyKey] = useState(uuidv4());

    // Alert State
    const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

    const {
      setShowMobileDropdownPopup,
      showMobileDropdownPopup,
      setShowMobileDropdownPopupAnimation,
    } = useContext(UserContext);

    const { propertiesData, categoryMap, categoryWithSubOptions, categoryIdsMap } = useContext(
      TransactionContext
    );
    const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);

    const [currentStep, setCurrentStep] = useState(1);
    const TOTAL_STEPS = 4;

    const [error, setError] = useState(null);

    const wireTransferFormikRef = useRef(null);

    const [initiateWireTransfer, { client }] = useMutation(INITIATE_WIRE_TRANSFER, {
      refetchQueries: [GET_TRANSFERS],
    });

    const handlePropertySubmit = (newPropertyId: string) => {
      const [newPId, newUId] = newPropertyId.split('-');
      wireTransferFormikRef?.current?.setFieldValue('propertyId', newPId);
      wireTransferFormikRef?.current?.setFieldValue('unitId', newUId);
      wireTransferFormikRef?.current?.setFieldValue('propertyUnitId', newPropertyId);
    };

    function handleCategorySubmit(newCategoryId: number, dropDown) {
      const [parentId, subId] = (newCategoryId ?? '').split('-');
      wireTransferFormikRef?.current?.setFieldValue('categoryId', subId ?? parentId);
      setTimeout(() => {
        dropDown('close');
      }, 10);
    }

    const propertyOptions = getPropertyData(propertiesData);

    const validate = (values, stepNumber) => {
      wireTransferFormikRef?.current?.validateForm({ ...values, stepNumber });
    };

    const reinitialize = () => {
      const initialValues = {
        ...recipientFormInitialValues,
        ...addressFormInitialValues,
        ...propertyAndCategoryInitialValues,
      };
      wireTransferFormikRef?.current?.resetForm({ values: initialValues });
      setIsValidBankTransfer(false);
      setTransfer({});
      validate(initialValues, 1);
      setCurrentStep(1);
      setXIdempotencyKey(uuidv4());
    };

    const closeAndReinitialize = () => {
      reinitialize();
      onCloseDomesticWireDrawer();
    };

    const handleOnDrawerClose = () => {
      if (wireTransferFormikRef?.current?.dirty) {
        onAlertOpen();
      } else {
        closeAndReinitialize();
      }
    };

    const validateNextClick = (nextStepNumber) => {
      const { values = {} } = wireTransferFormikRef?.current || {};
      wireTransferFormikRef?.current?.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          setCurrentStep(nextStepNumber);
          sendSegmentEvent(`baselane_banking_complete_wire_transfer_step${nextStepNumber - 1}`);

          // to validate next from
          validate(values, nextStepNumber);
        } else {
          wireTransferFormikRef?.current.setTouched(errors, true);
        }
      });
    };

    const handleErrorRedirect = (step) => {
      if (step) {
        if (step === 1) {
          wireTransferFormikRef?.current?.setFieldTouched('routingNumber', true);
          setTimeout(() => {
            wireTransferFormikRef?.current?.setFieldError(
              'routingNumber',
              'The counterparty routing number is not valid.'
            );
          }, 1);
        }

        setCurrentStep(step);
      }
    };

    const handleWireTransferSubmit = () => {
      const valuesToInitialize = {
        ...(wireTransferFormikRef?.current?.values ?? {}),
        transfer,
      };

      const variables = formatDataForApi(valuesToInitialize);

      initiateWireTransfer({
        context: {
          headers: {
            'x-idempotency-key': xIdempotencyKey,
          },
        },
        variables,
      })
        .then((res) => {
          if (res.errors) {
            setError(res.errors);
          } else {
            setCurrentStep(6);
            client.refetchQueries();
          }
        })
        .catch((err) => {
          setError(err);
        });
    };

    useImperativeHandle(ref, () => ({
      handleWireTransferSubmit,
    }));

    const onNextClicked = () => {
      switch (currentStep) {
        case 1:
          validateNextClick(2);
          return;
        case 2:
          validateNextClick(3);
          break;
        case 3:
          validateNextClick(4);
          break;
        case 4:
          setCurrentStep(5);
          sendSegmentEvent('baselane_banking_complete_wire_transfer_step4');
          break;
        default:
        // do nothing
      }
    };

    const onPrevClicked = () => {
      const { values = {} } = wireTransferFormikRef?.current || {};

      switch (currentStep) {
        case 2:
          setCurrentStep(1);
          validate(values, 1);
          return;
        case 3:
          setCurrentStep(2);
          validate(values, 2);
          break;
        case 4:
          setCurrentStep(3);
          validate(values, 3);
          break;
        case 5:
          setCurrentStep(4);
          break;
        default:
        // do nothing
      }
    };

    const resetApiErrors = () => {
      if (error) {
        setError(null);
      }
    };

    const { DrawerBody } = BaselaneDrawer;
    return (
      <>
        <UnsavedChangesAlert
          {...{
            isAlertOpen,
            onAlertClose,
            rightButtonEvent: () => {
              closeAndReinitialize();
              onAlertClose();
            },
          }}
        />

        <BaselaneDrawer
          ref={domesticWireDrawerRef}
          title="Domestic Wire Transfer"
          size="md"
          closeEvent={handleOnDrawerClose}
          onClose={handleOnDrawerClose}
          hideOverlay
        >
          <Formik
            innerRef={wireTransferFormikRef}
            initialValues={{
              ...recipientFormInitialValues,
              ...addressFormInitialValues,
              ...propertyAndCategoryInitialValues,
            }}
            validate={(values) => handleValidation(values, currentStep, resetApiErrors)}
            validateOnBlur
            enableReinitialize
            validateOnMount
          >
            {({ isValid, values, touched, errors, handleChange, handleBlur }) => (
              <>
                <DrawerBody {...drawerBodyStyles(isMinXL)} id="transfer-drawer">
                  {handleFormViews({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    totalSteps: TOTAL_STEPS,
                    currentStep,
                    paymentMethod,
                    setIsValidAccount,
                    setIsValidBankTransfer,
                    setTransfer,
                    propertyOptions,
                    categoryOptions,
                    categoryIdsMap,
                    categoryMap,
                    handlePropertySubmit,
                    handleCategorySubmit,
                    handleErrorRedirect,
                    transfer,
                    error,
                    setShowMobileDropdownPopup,
                    setShowMobileDropdownPopupAnimation,
                    showMobileDropdownPopup,
                  })}
                </DrawerBody>

                <WireDrawerFooter
                  {...{
                    isRightBtnDisabled:
                      !isValid ||
                      (currentStep === 3 && !isValidBankTransfer) ||
                      (currentStep === 5 && error),
                    ...footerConfig({
                      onPrevClicked,
                      onNextClicked,
                      onCloseDomesticWireDrawer,
                      makeTransferOrPaymentDrawerRef,
                      handleWireTransferSubmit,
                      reinitialize,
                      isValid,
                      handleOnDrawerClose,
                      checkToken,
                    })[currentStep],
                  }}
                />
              </>
            )}
          </Formik>
        </BaselaneDrawer>
      </>
    );
  }
);

export default DomesticWireDrawer;
