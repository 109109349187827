import React, { useState } from 'react';
import { Flex, FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import MaskedInput from 'react-text-mask';
import { BaselaneButton, BaselaneButtonGroup } from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import ToggleWrapper from '@pages/LeasesPage/LeaseSection/components/ToggleWrapper';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';

import DisplayLateFee from '../DepositsAndFees/DisplayLateFee';
import PAYMENT_TYPE from '../../../models/PaymentTypeEnum';
import { feeBox, fieldContainer } from '../../styles/shared.styles';

type LateFeeGroupProps = {
  errors: Object,
  touched: Object,
  values: Object,
  handleChange: Function,
  handleBlur: Function,
  setFieldTouched: Function,
  setFieldValue: Function,
  setFormVariables: Function,
  lateFeeId: string,
  lateFeeName: string,
  currencyMask: Function,
  daysMask: Function,
  unifiedRC: boolean,
  lease: Object,
  formVariables: Array,
  formValidation: Function,
};

function LateFeeGroup({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  setFieldTouched,
  setFieldValue,
  setFormVariables,
  lateFeeId,
  lateFeeName,
  currencyMask,
  daysMask,
  unifiedRC,
  lease,
  formValidation,
}: LateFeeGroupProps) {
  const feesExist = lease?.hasLateFee || values.hasLateFee;
  const [showAddFeeForm, setShowAddFeeForm] = useState(!feesExist);

  const lateFee = {
    amount: values.lateFeeAmount,
    dueDays: values.numberOfDaysForLateFee,
  };

  const handleChangeLateFee = (e) => {
    if (values.hasLateFee) {
      setFieldValue('hasLateFee', false);
      setFieldValue('numberOfDaysForLateFee', '');
      setFieldValue('lateFeeAmount', '');
      setShowAddFeeForm(false);
    } else {
      setFieldValue('hasLateFee', true);
      setFieldTouched('numberOfDaysForLateFee', false);
      setFieldTouched('lateFeeAmount', false);
      setShowAddFeeForm(true);
    }

    handleChange(e);
  };

  const handleFeeDelete = () => {
    setFieldValue('numberOfDaysForLateFee', null);
    setFieldValue('lateFeeAmount', null);
    setFieldValue('hasLateFee', false);
    setTimeout(() => {
      const itemsToBeDeleted = { amount: null, dueDays: null };
      setFormVariables({
        payments: [
          {
            id: lateFeeId,
            name: PAYMENT_TYPE[lateFeeName],
            enabled: false,
            ...itemsToBeDeleted,
          },
        ],
      });
    }, 100);
  };

  const handleSaveLateFee = () => {
    setFieldTouched('numberOfDaysForLateFee', true);
    setFieldTouched('lateFeeAmount', true);
    if (values.hasLateFee && (!values.lateFeeAmount || values.lateFeeAmount === '')) {
      formValidation(values);
    } else if (values.hasLateFee && stripCurrency(values.lateFeeAmount) < 0.5) {
      formValidation(values);
    } else {
      setFormVariables({
        payments: [
          {
            id: lateFeeId,
            name: PAYMENT_TYPE[lateFeeName],
            enabled: values.hasLateFee,
            amount: stripCurrency(values.lateFeeAmount),
            dueDays: Number(values.numberOfDaysForLateFee),
          },
        ],
      });
      setShowAddFeeForm(false);
    }
  };

  const handleChangeNumDays = (e) => {
    setFieldTouched('numberOfDaysForLateFee', true);
    const dueDaysValue = e.target.value === '' ? null : Number(e.target.value);
    setFormVariables({
      payments: [
        {
          id: lateFeeId,
          name: PAYMENT_TYPE[lateFeeName],
          dueDays: dueDaysValue,
          enabled: true,
        },
      ],
    });
    handleChange(e);
  };

  const handleChangeAmount = (e) => {
    setFieldTouched('lateFeeAmount', true);
    if (!errors.lateFeeAmount) {
      setFormVariables({
        payments: [
          {
            id: lateFeeId,
            name: PAYMENT_TYPE[lateFeeName],
            amount: stripCurrency(e.target.value),
            enabled: true,
          },
        ],
      });
    }
    handleChange(e);
  };

  return (
    <ToggleWrapper showBottomBorder={false} isNested>
      {!values.hasLateFee && (
        <ToggleWrapper.Toggle
          {...{
            title: 'Add late fee',
            htmlFor: 'hasLateFee',
            switchProps: {
              id: 'hasLateFee',
              onClick: handleChangeLateFee,
            },
            hideToggle: true,
          }}
        />
      )}

      <ToggleWrapper.Content
        direction={!isMobile ? 'row' : 'column'}
        styles={{ spacing: isMobile ? 0 : 3, mt: '0', mb: 1 }}
      >
        {!showAddFeeForm && values.hasLateFee && (
          <DisplayLateFee
            {...{
              lateFee,
              handleFeeDelete,
              unifiedRC,
            }}
          />
        )}

        {showAddFeeForm && values.hasLateFee && (
          <Flex {...feeBox} mb={1.5} id="late-fee-input-box">
            <Stack direction={!isMobile ? 'row' : 'column'} spacing={1.5} mb="0" id="late-fees-box">
              <FormControl
                {...fieldContainer}
                isInvalid={errors.numberOfDaysForLateFee && touched.numberOfDaysForLateFee}
              >
                <FormLabel {...formLabelStyles.xs} htmlFor="numberOfDaysForLateFee">
                  Number of days after due date
                </FormLabel>
                <Input
                  {...formInputStyles}
                  id="numberOfDaysForLateFee"
                  name="numberOfDaysForLateFee"
                  value={values.numberOfDaysForLateFee}
                  onChange={handleChangeNumDays}
                  onBlur={handleBlur}
                  placeholder="Enter a number of days"
                  as={MaskedInput}
                  mask={daysMask}
                  inputMode="numeric"
                  bg="white"
                />
                <FormErrorMessage {...formErrorStyles}>
                  {errors.numberOfDaysForLateFee}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                {...fieldContainer}
                isInvalid={errors.lateFeeAmount && touched.lateFeeAmount}
              >
                <FormLabel {...formLabelStyles.xs} htmlFor="lateFeeAmount">
                  Late fee amount
                </FormLabel>
                <Input
                  {...formInputStyles}
                  id="lateFeeAmount"
                  name="lateFeeAmount"
                  value={values.lateFeeAmount}
                  onChange={handleChangeAmount}
                  onBlur={handleBlur}
                  placeholder="$"
                  as={MaskedInput}
                  mask={currencyMask}
                  inputMode="decimal"
                  bg="white"
                />
                <FormErrorMessage {...formErrorStyles}>{errors.lateFeeAmount}</FormErrorMessage>
              </FormControl>
            </Stack>
            <BaselaneButtonGroup size="sm">
              <BaselaneButton
                variant="filled"
                palette="primary"
                size="sm"
                onClick={() => handleSaveLateFee()}
                id="save_fee_button"
              >
                Save Fee
              </BaselaneButton>
              <BaselaneButton
                variant="transparent"
                palette="danger"
                size="sm"
                onMouseDown={() => handleFeeDelete()}
                styles={{ marginLeft: 1.5 }}
                id="cancel_fee_button"
              >
                Cancel
              </BaselaneButton>
            </BaselaneButtonGroup>
          </Flex>
        )}
      </ToggleWrapper.Content>
    </ToggleWrapper>
  );
}

export default LateFeeGroup;
