export const creditCardStyles = (status, isGrayedOut) => {
  const baseStyles = { boxShadow: 'none', textShadow: 'none' };
  if (isGrayedOut) {
    return { ...baseStyles, visibility: 'hidden' };
  }

  if (status === 'IN_PROGRESS') {
    return baseStyles;
  }

  return {};
};
