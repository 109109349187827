import React from 'react';
import { BaselaneMessageCard } from '@shared/components';
import InvoiceTable from './InvoiceTable';
import RCTable from './RCTable';
import { accordionContentStyles } from '../styles/accordionContent.styles';

type AccordionContentProps = {
  payment: Object,
  tenantData: Object,
  propertiesData: Array<Object>,
};

function AccordionContent({ payment, tenantData, propertiesData }: AccordionContentProps) {
  const { msgCard } = accordionContentStyles ?? {};

  return (
    <>
      {payment.type === 'PROCESSING_PAYMENT' && (
        <BaselaneMessageCard
          iconName="info"
          iconColor="blue"
          borderColor="blue"
          text="These payments are already in transit and may fail. We will automatically re-process failed payments with the updated bank account."
          styles={msgCard}
          isVertical
        />
      )}

      {'invoices' in payment && <InvoiceTable {...{ payment, propertiesData }} />}
      {'leases' in payment && <RCTable {...{ payment, tenantData }} />}
    </>
  );
}

export default AccordionContent;
