export const textStyles = {
  fontSize: '14px',
  color: 'brand.neutral.700',
  fontWeight: 'normal',
  lineHeight: '20px',
};

export const titleSecondStyles = {
  textStyle: 'md-heavy',
  color: 'brand.neutral.900',
};

export const insuranceStyles = () => {
  const desktopStyles = {
    bottom: {
      container: {
        m: '0 0!important',
      },
      title: {
        fontSize: { base: 'lg', lg: '2xl', '2lg': '2xl' },
        lineHeight: { base: '26px', lg: '36px', '2lg': '36px' },
        fontWeight: 'semibold',
        color: '#000',
      },
      left: {
        description: {
          textStyle: 'md',
          lineHeight: '24px',
          color: '#000',
          mt: '8px !important',
        },
      },
      infobox: {
        container: {
          flexDir: { base: 'column', lg: 'row', '2lg': 'row' },
          m: '0!important',
          gap: '24px',
        },
        box: {
          m: '0 !important',
          w: { base: '100%', lg: '33.33%', '2lg': '33.33%' },
          h: '100%',
          display: 'flex',
          flexDir: { base: 'column' },
          gap: '16px',
        },
        titleContainer: {
          gap: '4px',
          flex: 1,
        },
        title: {
          textStyle: 'headline-sm',
          color: 'brand.neutral.700',
        },
        subtitle: {
          textStyles: 'headline-lg',
          color: 'brand.neutral.900',
        },
        description: {
          textStyle: 'sm',
          color: 'brand.neutral.700',
          m: '0 !important',
        },
      },
      disclaimer: {
        textStyle: 'sm',
        color: 'brand.neutral.500',
        m: '0 !important',
        lineHeight: '20px',
      },
      firstChar: {
        fontSize: '0.8em',
        fontWeight: '400',
        color: 'red',
      },
      buttonContainer: {
        my: { base: '16px', lg: '40px' },
        justifyContent: { base: 'center', sm: 'space-between' },
      },
    },
  };

  return desktopStyles;
};
