export const trackerContainerStyles = (isMobile, showSteps) => {
  return {
    bg: 'brand.neutral.white',
    borderRadius: '4px',
    borderWidth: '1px',
    borderColor: 'brand.darkBlue.150',
    boxShadow: '1px 2px 6px rgba(3, 24, 64, 0.04)',
    p: '16px',
    flex: '1',
    position: 'relative',
    m: isMobile && '24px 0 0 0 !important',
    minHeight: showSteps ? 'max-content' : '74px',
    transition: 'min-height 2s',
    gap: 0,
  };
};

export const trackerHeaderStyles = (isMobile, isDone) => {
  return { w: '100%', mb: (!isMobile || !isDone) && '12px', gap: 0 };
};

export const trackerHeaderRightStyles = {
  flex: 1,
  ml: '12px !important',
};

export const trackerHeaderTitleStyles = {
  textStyle: 'headline-sm',
};

export const TRACKER_BAR_COLOR = 'scheme.blue';
export const trackerBarStyles = {
  w: '100%',
  bg: 'brand.darkBlue.250',
  minH: '8px',
  maxH: '8px',
  borderRadius: '8px !important',
};

export const trackerBarTextStyles = {
  color: 'brand.neutral.600',
  textStyle: 'xs',
  ml: '16px',
  w: '32px',
  minW: '32px',
  textAlign: 'right',
};

export const trackerAllDoneTextStyles = {
  textStyle: 'xs',
  color: 'brand.blue.700',
};

export const spinnerStyles = {
  color: 'brand.darkBlue.150',
  alignSelf: 'center',
};
