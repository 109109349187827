import React from 'react';

function IconLocation({
  w = 20,
  h = 20,
  color = 'currentColor',
}: {
  w?: Number,
  h?: Number,
  color?: String,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 20 20"
      fill={color}
    >
      <path
        fillRule="evenodd"
        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
        clipRule="evenodd"
      />
    </svg>
  );
}

IconLocation.defaultProps = {
  w: 20,
  h: 20,
  color: 'currentColor',
};

export default IconLocation;
