import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { BaselaneChip, BaselaneTooltip } from '@shared/components';
import { Icon16Rents } from '@icons/16px';
import { getPropertiesUnits } from '@core/components/NativeBankingPage/MainContent/components/Accounts/helpers/linkedFor.helper';
import { generateList } from './tagList.helpers';
import { tooltipTitleText } from './accountTag.styles';

type AutoTagChipProps = {
  autoTag: Object,
  leaseDetailProperties: Array<Object>,
};

const AutoTagChip = ({ autoTag, leaseDetailProperties }: AutoTagChipProps) => {
  const autoTagData = {
    [autoTag.propertyId]: autoTag.propertyUnitId === null ? '' : [autoTag.propertyUnitId],
  };

  const transactionsAndBalancesAutoTag = getPropertiesUnits(autoTagData, leaseDetailProperties);
  const autoTagList = (
    <Stack>
      <Text {...tooltipTitleText}>Transactions Auto-Tagged To:</Text>
      {generateList(transactionsAndBalancesAutoTag)}
    </Stack>
  );

  return (
    <BaselaneTooltip label={autoTagList} placement="bottom-start">
      <BaselaneChip size="md" variant="simple-neutral" leftIcon={Icon16Rents} />
    </BaselaneTooltip>
  );
};

export default AutoTagChip;
