import IconCheck from '@icons/legacy/IconCheck';

const subAccList = [
  {
    label: IconCheck,
    value: 'To organize or separate funds within an existing bank account.',
  },
  {
    label: IconCheck,
    value: 'For security deposits, separate units or properties, remodeling savings, etc.',
  },
  {
    label: IconCheck,
    value: 'Virtual accounts have unique account numbers and can receive or make payments.',
  },
];

export default subAccList;
