export const trim = (values) =>
  Object.entries(values)
    .map((field) => {
      const fieldArr = field;
      fieldArr[1] = typeof fieldArr[1] === 'string' && fieldArr[1]?.trim();
      return fieldArr;
    })
    .reduce((accum, [key, value]) => {
      // eslint-disable-next-line no-param-reassign
      accum[key] = value;
      return accum;
    }, {});

export const isPaymentMethodDefined = (paymentMethod) => {
  if (!paymentMethod) return false;
  if (paymentMethod === {}) return false;
  return Object.keys(paymentMethod).length > 1;
};

export const hasAPaymentMethod = (methods) => {
  if (!methods) return false;
  const keys = Object.keys(methods);
  return keys?.reduce((previous, current) => {
    return previous || isPaymentMethodDefined(methods[current]);
  }, false);
};

export const hasSinglePaymentMethod = (methods) => {
  if (!methods) return null;
  const keys = Object.keys(methods);
  return (
    keys?.reduce((previous, current) => {
      const sum = isPaymentMethodDefined(methods[current]) && methods[current].id ? 1 : 0;
      return previous + sum;
    }, 0) === 1
  );
};

export const completePaymentMethods = (methods) => {
  const resultPaymentMethods = {};
  Object.keys(methods).forEach((key) => {
    if (isPaymentMethodDefined(methods[key])) {
      resultPaymentMethods[key] = methods[key];
    }
  });
  return resultPaymentMethods;
};

export const paymentAddressValidation = (values, existingErrors, checkLimitations = false) => {
  // remove leading and trailing spaces from each value
  const trimmedValues = trim(values);
  const errors = { ...existingErrors };

  const { address, city, state, unit, zipcode } = trimmedValues ?? {};

  if (address === '') {
    errors.address = 'Please enter address';
  }
  if (city === '') {
    errors.city = 'Please enter city';
  }
  if (state === '') {
    errors.state = 'Please enter state';
  }
  if (zipcode === '') {
    errors.zipcode = 'Please enter zipcode';
  }
  if (zipcode !== '' && zipcode?.length < 5) {
    errors.zipcode = 'Please enter 5-digit zip code';
  }
  if (unit?.length > 0 && unit?.length === 0) {
    errors.unit = 'Unit number cannot be left blank';
  }

  if (checkLimitations) {
    if (address?.length > 40) {
      errors.address = `The street address can't exceed 40 characters.`;
    }

    if (unit?.length > 10) {
      errors.unit = `The apartment, suite, unit or floor can't exceed 10 characters.`;
    }
  }

  return errors;
};

export const recipientACHPaymentValidation = (values) => {
  // remove leading and trailing spaces from each value
  const trimmedValues = trim(values);

  const { accountType, accountHolderName, routingNumber, accountNumber } = trimmedValues ?? {};

  const errors = {};

  if (!accountType || accountType === '') {
    errors.accountType = 'Please select account type';
  }

  if (!accountHolderName || accountHolderName === '') {
    errors.accountHolderName = "Please enter account holder's name";
  }

  if (!routingNumber || routingNumber === '') {
    errors.routingNumber = 'Please enter routing number';
  }

  if (routingNumber && routingNumber.length < 9) {
    errors.routingNumber = 'Enter a valid domestic routing number';
  }

  if (!accountNumber || accountNumber === '') {
    errors.accountNumber = 'Please enter account number';
  }

  return errors;
};

export const recipientWirePaymentValidation = (values) => {
  // remove leading and trailing spaces from each value
  const trimmedValues = trim(values);

  const { accountHolderName, routingNumber, accountNumber } = trimmedValues ?? {};

  const errors = {};

  if (!accountHolderName || accountHolderName === '') {
    errors.accountHolderName = "Please enter account holder's name";
  }

  if (!routingNumber || routingNumber === '') {
    errors.routingNumber = 'Please enter routing number';
  }

  if (routingNumber && routingNumber.length < 9) {
    errors.routingNumber = 'Enter a valid domestic routing number';
  }

  if (!accountNumber || accountNumber === '') {
    errors.accountNumber = 'Please enter account number';
  }

  const allErrors = paymentAddressValidation(values, errors);

  return allErrors;
};

export const recipientCheckPaymentValidation = (values) => {
  // remove leading and trailing spaces from each value
  const trimmedValues = trim(values);

  const { name } = trimmedValues ?? {};

  const errors = {};

  if (!name || name === '') {
    errors.name = "Please enter check recipient's name";
  } else if (name.length > 40) {
    errors.name = "The recipient's name can't exceed 40 characters.";
  }

  const allErrors = paymentAddressValidation(values, errors, true);

  return allErrors;
};

export const recipientValidation = (values) => {
  // remove leading and trailing spaces from each value
  const trimmedValues = trim(values);

  const { name, email, type } = trimmedValues ?? {};

  const errors = {};

  if (!name || name === '') {
    errors.name = "Please enter recipient's name";
  }

  if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = 'Invalid email address';
  }

  if (!type) {
    errors.type = 'Please select a recipient type';
  }

  return errors;
};
