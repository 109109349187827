import React from 'react';
import { ButtonGroup, Stack } from '@chakra-ui/react';

type BaselaneButtonGroupProps = (size: string, isReversed: boolean, styles: object) => {};

const BaselaneButtonGroup = ({ size, isReversed, children, styles }: BaselaneButtonGroupProps) => {
  const spacingMap = {
    sm: 0.5,
    md: 1,
    lg: 2,
  };
  const spacing = spacingMap[size];

  <Stack direction={isReversed ? 'row-reverse' : 'row'} gap={spacing} {...styles}>
    {children}
  </Stack>;

  return isReversed ? (
    <Stack direction="row-reverse" alignContent="flex-start" gap={spacing} {...styles}>
      {children}
    </Stack>
  ) : (
    <ButtonGroup gap={spacing} {...styles}>
      {children}
    </ButtonGroup>
  );
};

export default BaselaneButtonGroup;
