import React from 'react';
import { Box } from '@chakra-ui/react';

/**
 * Helper to add a container with padding around button if needed
 * @param children
 * @param rest
 * @returns {JSX.Element}
 */
const ResponsiveActionButtonWrapper = ({ children, ...rest }: { children: React.Node }) => {
  return (
    <Box p="4px" w="100%" {...rest}>
      {children}
    </Box>
  );
};

export default ResponsiveActionButtonWrapper;
