// @flow
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Box, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { IconDocumentCheckOutline, IconDownload } from '@icons';
import { Icon16Delete } from '@icons/16px';
import { BaselaneButtonIcon } from '@shared/components';
import { GET_DOCUMENT_DOWNLOAD_URL } from '@core/apollo/queries';
import { documentCardStyles } from './styles/documentCard.styles';

type DocumentCardProps = { doc: Object, handleDeleteDocAlertOpen: Function };

function DocumentCard({ doc, handleDeleteDocAlertOpen }: DocumentCardProps): any {
  // Destructure 'document' Object
  const { id, name, fileExtension } = doc ?? {};

  // Queries
  const [getDocumentDownloadUrl] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_URL, {
    fetchPolicy: 'cache-and-network',
  });

  // Destructure Imported Styles
  const { card, textcontainer, nameStyle, typeStyle } = documentCardStyles ?? {};

  // API Calls
  const getDownloadUrl = async () => {
    let result = null;

    try {
      const response = await getDocumentDownloadUrl({
        variables: {
          id: doc.id,
        },
      });

      if (response.errors?.length) {
        console.log('download error: ', response.errors);
      } else {
        result = response.data;
      }
    } catch (error) {
      console.log('download error: ', error);
    }

    return result;
  };

  const downloadDocument = ({ url }) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
  };

  // Handle Download
  const onDownloadDocument = async () => {
    const { generateDocumentDownloadUrl: url } = (await getDownloadUrl()) ?? {};

    if (url) {
      downloadDocument({ url });
    }
  };

  return (
    <Stack key={id} {...card}>
      <HStack>
        <Box>
          <IconDocumentCheckOutline />
        </Box>

        <Stack {...textcontainer}>
          <Text {...nameStyle}>{name}</Text>
          <Text {...typeStyle}>{fileExtension}</Text>
        </Stack>

        <Spacer />
        <BaselaneButtonIcon
          variant="outline"
          palette="neutral"
          icon={<IconDownload />}
          onClick={() => onDownloadDocument()}
        />
        <BaselaneButtonIcon
          variant="outline"
          palette="danger"
          icon={<Icon16Delete />}
          onClick={() => handleDeleteDocAlertOpen({ id: doc.id })}
        />
      </HStack>
    </Stack>
  );
}

export default DocumentCard;
