import React from 'react';
import { Stack } from '@chakra-ui/react';
import { Icon16Return } from '@icons/16px';
import BaselaneButton from '../../BaselaneButton';
import BaselalneDivider from '../../BaselaneDivider';
import { handleEscapeKeyDown } from '../helpers/shared.helpers';
import { datePickerStyles } from '../styles/datePicker.styles';
import { BackToTodayProps } from '../types/backToToday.types';

const BackToToday = ({ handleBackToTodayClick }: BackToTodayProps) => {
  const { header } = datePickerStyles ?? {};

  return (
    <Stack {...header.container}>
      <BaselalneDivider styles={header.divider} />
      <BaselaneButton
        variant="transparent"
        palette="primary"
        size="sm"
        isFullWidth
        rightIcon={<Icon16Return />}
        onClick={() => handleBackToTodayClick()}
        onKeyDown={handleEscapeKeyDown}
        styles={{ m: '0 !important' }}
      >
        Back to Today
      </BaselaneButton>
    </Stack>
  );
};

export default BackToToday;
