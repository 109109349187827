// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=4763%3A116979
import React, { useContext } from 'react';
import { useGate } from 'statsig-react';
import { Stack } from '@chakra-ui/react';

import { BaselaneButtonGroup } from '@shared/components';
import MoveMoneyMenu from '@core/components/Shared/components/MoveMoneyMenu';
import BanksContext from '@contexts/BanksContext';
import VirtualCardTriggerButton from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/AddVirtualCard/TriggerButton';
import getBreakPoints from '@core/utils/getBreakPoints';
import MakeTransferOrPaymentDrawer from '@core/components/NativeBankingPage/MainContent/components/MakeTransferOrPaymentDrawer';
import AddFundsDrawer from '@core/components/NativeBankingPage/MainContent/components/AddFundsDrawer';
import AddAccountTrigger from '@pages/BankEntity/AddAccount/AddAccountTrigger';
import AccountDetailsDrawer from '@pages/BankEntity/AccountDetailsDrawer';

import AccountCards from '../AccountCards';
import AccountTabs from '../AccountTabs';
import MoreMenu from '../MoreMenu';
import { baselaneHeaderCardContentStyles, mainAccountStyles } from './content.styles';

type MainContentProps = {
  account: Object,
};

const MainContent = ({ account }: MainContentProps) => {
  const { phoneNumber, plaidItemId: itemId, bankId } = account;

  const { isMin768: showDesktopView, isMax576: showMobileButton } = getBreakPoints();

  const { value: isTransfersPaymentsPageFeatureFlagged } = useGate('transfers_payments_page');

  const { banks, refetchBankSummary } = useContext(BanksContext);

  const buttonStyles = {
    variant: 'filled',
    palette: 'primary',
    size: 'lg',
  };

  return (
    <>
      <AccountCards />

      {!showDesktopView && (
        <Stack {...mainAccountStyles.mobileContainer}>
          <Stack
            {...baselaneHeaderCardContentStyles.mobileInnerContainer(
              isTransfersPaymentsPageFeatureFlagged
            )}
          >
            {isTransfersPaymentsPageFeatureFlagged ? (
              <BaselaneButtonGroup>
                <MoveMoneyMenu buttonSize="lg" styles={{ button: { flex: '1', width: '100%' } }} />
                <MoreMenu />
              </BaselaneButtonGroup>
            ) : (
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
                pt={1.5}
                px={0}
                mb={1.5}
              >
                <VirtualCardTriggerButton
                  size="lg"
                  variant={!showMobileButton ? 'outline' : 'tonal'}
                  palette={!showMobileButton ? 'neutral' : 'primary'}
                  showMobileVersion
                />
                <AddAccountTrigger
                  size="lg"
                  variant={!showMobileButton ? 'outline' : 'tonal'}
                  palette={!showMobileButton ? 'neutral' : 'primary'}
                  showMobileButton
                />
                <MakeTransferOrPaymentDrawer
                  showMobileButton
                  banks={banks}
                  refetchBankSummary={refetchBankSummary}
                  buttonProps={buttonStyles}
                />
                <AddFundsDrawer
                  showMobileButton
                  banks={banks}
                  refetchBankSummary={refetchBankSummary}
                  buttonProps={buttonStyles}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      )}

      <AccountDetailsDrawer phoneNumber={phoneNumber} itemId={itemId} bankId={bankId} />

      {/* Bottom Section - Transactions + Cards */}
      <AccountTabs />
    </>
  );
};

export default MainContent;
