import React from 'react';
import Info from './Info';
import AddProperty from './AddProperty';
import CreateDocument from './CreateDocument';
import Documents from './Documents';
import LoadingView from './LoadingView';

type FirstStepProps = {
  hasNoProperties: Boolean,
  sortedProperties: Array<Object>,
  setShowSelectProperty: Function,
  setSelectedPropertyDoc: Function,
  handleOpeniFrame: Function,
  documents: Array<Object>,
  propertyDocumentConfig: Object,
  loadingObj: Object,
};

const FirstStep = ({
  hasNoProperties,
  sortedProperties,
  setShowSelectProperty,
  setSelectedPropertyDoc,
  handleOpeniFrame,
  documents,
  propertyDocumentConfig,
  loadingObj,
}: FirstStepProps) => {
  const { max_no_of_document: maxDocument = 0 } = propertyDocumentConfig ?? {};

  return loadingObj?.showLoading ? (
    <LoadingView {...{ loadingObj }} />
  ) : (
    <>
      <Info />
      {hasNoProperties && documents?.length === 0 ? (
        <AddProperty />
      ) : (
        <>
          {documents?.length > 0 && (
            <Documents
              {...{
                documents,
                sortedProperties,
                setSelectedPropertyDoc,
                handleOpeniFrame,
              }}
            />
          )}
          <CreateDocument {...{ setShowSelectProperty, documents, maxDocument }} />
        </>
      )}
    </>
  );
};

export default FirstStep;
