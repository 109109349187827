import React, { useEffect, useState } from 'react';
import { FormControl, FormErrorMessage, HStack, Select } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { useFormikContext } from 'formik';
import {
  BaselaneAddress,
  BaselaneInput,
  BaselaneFormLabel,
  BaselaneAutoCompleteAddress,
} from '@shared/components';
import { zipcodeMask } from '@core/utils/masks';

import { formInputStyles } from '../../styles/form.styles';

type PaymentAddressFieldsProps = {
  showAddressFormImmediately?: Boolean,
  addressMaxLength?: Number,
};

const PaymentAddressFields = ({
  showAddressFormImmediately,
  addressMaxLength,
}: PaymentAddressFieldsProps) => {
  const [hasEnteredAddress, setHasEnteredAddress] = useState(false);

  const {
    values,
    errors,
    touched,
    setFieldTouched,
    isValid,
    handleChange,
    handleBlur,
  } = useFormikContext();

  // Note: this shows the address details immediately
  // if an address is pre-supplied (such as when editing)
  useEffect(() => {
    if (values?.address) {
      setHasEnteredAddress(true);
    }
  }, []);

  return (
    <>
      {/* Address */}
      <BaselaneAutoCompleteAddress
        values={values}
        errors={errors}
        touched={touched}
        isValid={isValid}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setHasEnteredAddress={setHasEnteredAddress}
        formInputStyles={formInputStyles}
        maxLength={addressMaxLength}
      />
      {hasEnteredAddress && (
        <>
          {/* Unit */}
          <FormControl id="unit-number" isInvalid={errors.unit && touched.unit}>
            <BaselaneFormLabel> Apartment, Unit, Suite, or Floor #</BaselaneFormLabel>
            <BaselaneInput
              size="lg"
              id="unit"
              name="unit"
              value={values.unit}
              placeholder="e.g. Floor 1"
              onChange={(e) => {
                setFieldTouched('unit');
                handleChange(e);
              }}
              maxLength={10}
              onBlur={handleBlur}
            />
            <FormErrorMessage>{errors.unit}</FormErrorMessage>
          </FormControl>
          <HStack alignItems="flex-start">
            {/* City */}
            <FormControl id="city" isInvalid={errors.city && touched.city} flex={2}>
              <BaselaneFormLabel>City</BaselaneFormLabel>
              <BaselaneInput
                isRequired
                size="lg"
                id="city"
                name="city"
                value={values.city}
                placeholder="e.g. Philadelphia"
                onChange={(e) => {
                  setFieldTouched('city');
                  handleChange(e);
                }}
                onBlur={handleBlur}
              />
              <FormErrorMessage>{errors.city}</FormErrorMessage>
            </FormControl>
            {/* State */}
            <FormControl id="state" isInvalid={errors.state && touched.state} flex={1}>
              <BaselaneFormLabel>State</BaselaneFormLabel>
              <Select
                {...{ ...formInputStyles, borderWidth: '1px' }}
                size="xs"
                variant="unstyled"
                id="state"
                name="state"
                value={values.state}
                placeholder="Select"
                onChange={(e) => {
                  setFieldTouched('state');
                  handleChange(e);
                }}
                onBlur={handleBlur}
              >
                <BaselaneAddress.StateOptions />
              </Select>
              <FormErrorMessage>{errors.state}</FormErrorMessage>
            </FormControl>
            {/* Zip code */}
            <FormControl id="zipcode" isInvalid={errors.zipcode && touched.zipcode} flex={1}>
              <BaselaneFormLabel>Zip code</BaselaneFormLabel>
              <BaselaneInput
                isRequired
                size="lg"
                id="zipcode"
                name="zipcode"
                as={MaskedInput}
                mask={zipcodeMask}
                value={values.zipcode}
                placeholder="e.g. 19123"
                onChange={(e) => {
                  // setFieldTouched('zipcode');
                  handleChange(e);
                }}
                onBlur={handleBlur}
              />
              <FormErrorMessage>{errors.zipcode}</FormErrorMessage>
            </FormControl>
          </HStack>
        </>
      )}
    </>
  );
};

PaymentAddressFields.defaultProps = {
  showAddressFormImmediately: false,
  addressMaxLength: null,
};

export default PaymentAddressFields;
