import React from 'react';
import { Input, InputLeftElement, InputRightElement, InputGroup } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import CustomizableBaselaneInput from './CustomizableBaselaneInput';

const BaselaneInput = ({
  size,
  iconBefore,
  iconAfter,
  prefix,
  suffix,
  inputGroupProps,
  ...props
}) => {
  if (prefix || suffix) {
    return (
      <CustomizableBaselaneInput
        size={size}
        prefix={prefix}
        suffix={suffix}
        inputGroupProps={inputGroupProps}
        {...props}
      />
    );
  }

  if (iconBefore || iconAfter) {
    return (
      <InputGroup size={size} {...inputGroupProps}>
        {iconBefore ? <InputLeftElement>{iconBefore}</InputLeftElement> : null}
        <Input size={size} {...props} />
        {iconAfter ? <InputRightElement>{iconAfter}</InputRightElement> : null}
      </InputGroup>
    );
  }

  return <Input size={size} {...props} />;
};

BaselaneInput.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  iconBefore: PropTypes.element,
  iconAfter: PropTypes.element,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  inputGroupProps: PropTypes.shape(InputGroup.propTypes),
  ...Input.propTypes,
};

BaselaneInput.defaultProps = {
  size: 'md',
  iconBefore: null,
  iconAfter: null,
  prefix: '',
  suffix: '',
  inputGroupProps: {},
  ...Input.defaultProps,
};

export default BaselaneInput;
