import React, { ReactNode } from 'react';
import { HStack } from '@chakra-ui/react';
import { BaselaneMenu } from '@shared/components';
import { Icon16Chatbot, Icon16Logout, Icon16Support } from '@core/components/Icons/16px';
import getBreakPoints from '@core/utils/getBreakPoints';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';

type DeferredSignupPageHeaderProps = {
  email: String,
  children: ReactNode,
};

const DeferredSignupPageHeader = ({ email, children }: DeferredSignupPageHeaderProps) => {
  const { isMin768 } = getBreakPoints();

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const exit = () => {
    window.location.href = 'https://www.baselane.com/';
  };

  return (
    <HStack
      w="full"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid"
      borderBottomColor="brand.darkBlue.150"
      py={2}
      px={isMin768 ? 4 : 2}
      h="72px"
    >
      <BaselaneFullLogo />
      {email && (
        <BaselaneMenu
          listItems={[
            {
              id: 'support',
              name: 'Support',
              as: 'a',
              href: 'https://support.baselane.com/hc/en-us',
              target: '_blank',
              icon: <Icon16Support />,
            },
            {
              id: 'support-messenger',
              name: 'Virtual assistant',
              onClick: () => {
                zendeskAPI('messenger', 'open');
              },
              icon: <Icon16Chatbot />,
              hasDivider: true,
            },
            {
              name: 'Exit',
              onClick: exit,
              icon: <Icon16Logout />,
            },
          ]}
          label={email}
          profilePhotoUrl={null}
          isProfile
        />
      )}
      {children}
    </HStack>
  );
};

export default DeferredSignupPageHeader;
