import React from 'react';

type IconLockProps = {
  w?: Number,
  h?: Number,
  color?: string,
};

function IconLock({ w, h, color }: IconLockProps) {
  return (
    <svg width={w} height={h} viewBox=" 0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.464 1.464A5 5 0 0114 5v3h1a3 3 0 013 3v6a3 3 0 01-3 3H3a3 3 0 01-3-3v-6a3 3 0 013-3h1V5a5 5 0 011.464-3.536zM6 8h6V5a3 3 0 00-6 0v3zm-3 2a1 1 0 00-1 1v6a1 1 0 001 1h12a1 1 0 001-1v-6a1 1 0 00-1-1H3zm6 2a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1z"
        fill={color}
      />
    </svg>
  );
}

IconLock.defaultProps = {
  w: 18,
  h: 20,
  color: 'currentColor',
};

export default IconLock;
