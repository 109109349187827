import React from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import {
  Icon16ChevronUp,
  Icon16ChevronDown,
  Icon16ChevronRight,
  Icon16ChevronLeft,
} from '@icons/16px';
import BaselaneButton from '../../BaselaneButton';
import { BaselaneButtonIcon } from '../../BaselaneButtonIcon';
import BaselalneDivider from '../../BaselaneDivider';
import { monthsObj, handleEscapeKeyDown } from '../helpers/shared.helpers';
import { datePickerStyles } from '../styles/datePicker.styles';
import { HeaderProps } from '../types/header.types';

const Header = ({
  increaseMonth,
  decreaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  parsedMonth,
  parsedYear,
  showMonthYearSelector,
  setShowMonthYearSelector,
  isFirstMonthAvailInCalendar,
  isLastMonthAvailInCalendar,
}: HeaderProps) => {
  const { header } = datePickerStyles ?? {};

  return (
    <Stack {...header.container}>
      <HStack {...header.innercontainer}>
        <BaselaneButtonIcon
          variant="transparent"
          palette="neutral"
          styles={header.monthnavbutton}
          icon={<Icon16ChevronLeft />}
          onClick={() => {
            decreaseMonth();
          }}
          onKeyDown={handleEscapeKeyDown}
          isDisabled={
            prevMonthButtonDisabled || isFirstMonthAvailInCalendar || showMonthYearSelector
          }
        />

        <BaselaneButton
          variant="transparent"
          palette="neutral"
          size="md"
          styles={header.monthyeardropdown}
          rightIcon={showMonthYearSelector ? <Icon16ChevronUp /> : <Icon16ChevronDown />}
          onClick={() => setShowMonthYearSelector(!showMonthYearSelector)}
          onKeyDown={handleEscapeKeyDown}
        >{`${monthsObj[parsedMonth]} ${parsedYear}`}</BaselaneButton>

        <BaselaneButtonIcon
          variant="transparent"
          palette="neutral"
          styles={header.monthnavbutton}
          icon={<Icon16ChevronRight />}
          onClick={() => {
            increaseMonth();
          }}
          onKeyDown={handleEscapeKeyDown}
          isDisabled={
            nextMonthButtonDisabled || isLastMonthAvailInCalendar || showMonthYearSelector
          }
        />
      </HStack>
      <BaselalneDivider styles={header.divider} />
    </Stack>
  );
};

export default Header;
