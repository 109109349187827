import React from 'react';

function IconEdit({
  width = 16,
  height = 16,
  color = 'currentColor',
}: {
  width?: string,
  height?: string,
  color?: string,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.333 2.666a1 1 0 00-.707.293l-.528.529 1.414 1.415.529-.53a1 1 0 00-.708-1.707zm1.65 2.65a2.334 2.334 0 10-3.3-3.3l-1 1-8.154 8.155a.667.667 0 00-.196.472v2.381c0 .368.299.667.667.667h2.333c.177 0 .347-.07.472-.196l9.178-9.178zm-2.414.53L10.155 4.43l-7.488 7.488v1.438h1.39l7.512-7.512z"
        fill={color}
      />
    </svg>
  );
}

IconEdit.defaultProps = {
  width: '16',
  height: '16',
  color: 'currentColor',
};

export default IconEdit;
