export const documentStyles = ({ isMinXL }) => {
  return {
    container: {
      gap: 0,
    },
    dropdowncontainer: {
      gap: 0,
      borderWidth: '1px',
      borderColor: 'brand.darkBlue.150',
      borderBottom: 'none',
      borderRadius: '4px',
      borderBottomRightRadius: 'none',
      borderBottomLeftRadius: 'none',
      sx: {
        '&:has(+ .doc-inner-container:focus), &:has(.card-header:focus), &:has(.card-header:focus-within)': {
          borderColor: 'brand.blue.250',
        },
        '&:has(.doc-inner-container:focus_visible), &:has(.card-header:focus_visible)': {
          outline: 'none',
        },
      },
    },
    innercontainer: {
      cursor: 'pointer',
      p: '14px 16px',
      gap: 0,
      flexDir: isMinXL ? 'column' : 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      background: 'brand.neutral.white',
      borderTop: 'none',
      borderWidth: '1px',
      borderColor: 'brand.darkBlue.150',
      borderRadius: '4px',
      borderTopRightRadius: 'none',
      borderTopLeftRadius: 'none',
      _hover: {
        background: 'brand.blue.50',
      },
      _focus: {
        borderColor: 'brand.blue.250',
      },
      _focusVisible: {
        outline: 'none',
      },
    },
    fileiconcontainer: {
      w: '24px',
      h: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'flex-start',
    },
    titlecontainer: {
      gap: '8px',
      ml: '16px !important',
    },
    title: {
      textStyle: 'headline-md',
    },
    timelapse: {
      textStyle: 'xs',
      color: 'brand.neutral.600',
    },
    actionBtn: {
      alignSelf: 'flex-start',
      borderRadius: '4px',
      m: isMinXL ? '16px 0 0 0 !important' : '0 !important',
      w: isMinXL ? '100%' : 'auto',
      pointerEvents: 'none',
    },
    badge: {
      width: 'fit-content',
      p: '2px 6px',
    },
    dropdown: {
      dropdownContentContainerStyles: {
        p: '6px 0 6px 16px !important',
      },
    },
  };
};
