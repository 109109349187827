import React from 'react';
import MaskedInput from 'react-text-mask';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneAddress, BaselaneAutoCompleteAddress } from '@shared/components';
import { zipcodeMask } from '@core/utils/masks';
import { formInputStyles, formErrorStyles, formLabelStyles } from '@shared/styles/input.style';
import DrawerHeaderWrapper from './DrawerHeaderWrapper';
import { title4 } from '../../styles/makeTransferOrPaymentDrawer.styles';

type AddressFormProps = {
  totalSteps: Number,
  currentStep: Number,
  values: Object,
  touched: Object,
  errors: Object,
  handleChange: Function,
  handleBlur: Function,
};

const AddressForm = ({
  totalSteps,
  currentStep,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}: AddressFormProps) => {
  const { isMinXL } = getBreakPoints();

  return (
    <>
      <DrawerHeaderWrapper {...{ totalSteps, currentStep }}>
        <Text {...title4}>Recipient’s Address</Text>
      </DrawerHeaderWrapper>
      <Stack spacing={isMinXL ? 2 : 3}>
        <BaselaneAutoCompleteAddress
          handleBlur={handleBlur}
          formLabelStyles={formLabelStyles.xs}
          cfieldContainer={{}}
          touched={touched}
          values={values}
          formInputStyles={formInputStyles}
          formErrorStyles={formErrorStyles}
          handleChange={handleChange}
          errors={errors}
          showIsRequired={false}
        />

        {/* Unit Number */}
        <FormControl isInvalid={errors.unit && touched.unit}>
          <FormLabel htmlFor="unitNumber" {...formLabelStyles.xs}>
            Apartment, Unit, Suite, or Floor # (Optional)
          </FormLabel>
          <Input
            {...formInputStyles}
            id="unit"
            name="unit"
            value={values.unit}
            placeholder="e.g. Floor 1"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage {...formErrorStyles}>{errors.unit}</FormErrorMessage>
        </FormControl>

        {/* City */}
        <FormControl isInvalid={errors.city && touched.city}>
          <FormLabel htmlFor="city" {...formLabelStyles.xs}>
            City
          </FormLabel>
          <Input
            {...formInputStyles}
            id="city"
            name="city"
            value={values.city}
            placeholder="e.g. Philadelphia"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage {...formErrorStyles}>{errors.city}</FormErrorMessage>
        </FormControl>
        <Stack direction="row" spacing={2}>
          {/* State */}
          <FormControl w="50%" isInvalid={errors.state && touched.state}>
            <FormLabel htmlFor="state" {...formLabelStyles.xs}>
              State
            </FormLabel>
            <Select
              {...formInputStyles}
              p="0"
              id="state"
              name="state"
              value={values.state}
              placeholder="Select state"
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <BaselaneAddress.StateOptions />
            </Select>
            <FormErrorMessage {...formErrorStyles}>{errors.state}</FormErrorMessage>
          </FormControl>

          {/* Zip Code */}
          <FormControl position="relative" w="50%" isInvalid={errors.zipcode && touched.zipcode}>
            <FormLabel htmlFor="zipcode" {...formLabelStyles.xs}>
              Zip Code
            </FormLabel>
            <Input
              {...formInputStyles}
              as={MaskedInput}
              mask={zipcodeMask}
              id="zipcode"
              name="zipcode"
              value={values.zipcode}
              placeholder="e.g. 19123"
              onChange={handleChange}
              type="text"
              onBlur={handleBlur}
            />
            <FormErrorMessage {...formErrorStyles}>{errors.zipcode}</FormErrorMessage>
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
};

export default AddressForm;
