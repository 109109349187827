import React from 'react';
import { Flex } from '@chakra-ui/react';
import BaselaneErrorCard from './BaselaneErrorCard';

class BaselaneErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log('Error from BaselaneErrorBoundary', error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log('Error from BaselaneErrorBoundary component', error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      // Custom fallback UI
      return (
        <Flex alignItems="center" h="100%">
          <BaselaneErrorCard
            btn1Label="Refresh screen"
            btn2Label="Go to Dashboard"
            handleBtn1Click={() => {
              window.location.reload();
            }}
            handleBtn2Click={() => {
              window.location.href = `/dashboard`;
            }}
          />
        </Flex>
      );
    }
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    return children;
  }
}
export default BaselaneErrorBoundary;
