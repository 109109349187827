// @flow
import React from 'react';
import { Tabs } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { tabsStyles } from '../styles/tabs.styles';

type TabsComponentProps = {
  children: any,
  tabIndex: Number,
  styles: Object,
  handleTabChange: Function,
};

function TabsComponent({
  children,
  tabIndex,
  styles = {},
  handleTabChange,
}: TabsComponentProps): any {
  const orientation = isMobile ? 'horizontal' : 'vertical';

  return (
    <Tabs
      orientation={orientation}
      variant="unstyled"
      index={tabIndex}
      onChange={handleTabChange}
      {...tabsStyles(isMobile)}
      {...styles}
    >
      {children}
    </Tabs>
  );
}

export default TabsComponent;
