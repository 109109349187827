export const modalStyles = {
  header: {
    p: '12px 32px',
    fontSize: 'lg',
    lineHeight: '24px',
    fontWeight: 'semibold',
    color: 'brand.neutral.white',
    background: 'brand.darkBlue.800A',
  },
  closeBtn: {
    color: 'brand.neutral.white',
  },
  content: {
    h: '100%',
    borderRadius: '0',
  },
  iframe: {
    width: '100%',
    height: 'calc(100% - 48px)',
    overflowY: 'auto',
  },
  rocketDocument: {
    height: 'fit-content',
  },
};
