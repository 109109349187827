import { deviceDetect } from 'react-device-detect';

export const getViewport = () => ({
  viewport: { width: window.innerWidth, height: window.innerHeight },
});

export const getDeviceType = () => {
  const deviceInfo = deviceDetect();
  return {
    os_name: deviceInfo?.osName ?? deviceInfo?.os,
    device_info: deviceInfo,
  };
};
