import { gql } from '@apollo/client';

export const GET_LEASE_SUMMARY = gql`
  query LeaseDetail {
    leaseDetail {
      summary {
        activeLease
        rentCollected
        rentPending
        rentOverdue
        monthlyRentAndFee
        isOverdue
        overdueLease
      }
    }
  }
`;

export const GET_ACTIVE_LEASES = gql`
  query LeaseDetail {
    leaseDetail {
      summary {
        activeLease
      }
    }
  }
`;
