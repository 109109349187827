import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useGate } from 'statsig-react';
import {
  Box,
  ChakraProvider,
  HStack,
  Input,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import {
  CREATE_OR_UPDATE_ONBOARDING_SURVEY,
  UPDATE_USER,
} from '@core/components/UserProfilePage/queries';
import userContext from '@contexts/UserContext';
import { ONBOARDING_ADD_PROPERTY, ONBOARDING_TRIAGE } from '@routes';
import SlLoader from '@core/components/Loader';
import { SaveChangesBtn } from '@core/components/NativeBankingPage/components/IndividualInputEditButtons';
import { BaselaneButtonCard } from '@shared/components';
import habitatTheme from '@core/themeHabitat';
import { formInputStyles } from '@shared/styles/input.style';
import {
  buttonCardStyles,
  otherBoxStyles,
  titleTextStyles,
  subTitleTextStyles,
} from './styles/propertySurvey.styles';
import { survey } from '../helpers/propertySurveyHelper';

const PropertySurvey = () => {
  const { value: showAddProperty } = useGate('onboarding-add-property-gate');
  const navigate = useNavigate();
  const [onboardingTriageSelection, setOnboardingTriageSelection] = useState();

  const { user, refetchUser, propertySurveyPanelIndex, setPropertySurveyPanelIndex } = useContext(
    userContext
  );

  const [updateSurvey] = useMutation(CREATE_OR_UPDATE_ONBOARDING_SURVEY);
  const [updateUser] = useMutation(UPDATE_USER);

  const surveyQuestions = survey.questions;
  const surveyQuestionsLength = survey.questions.length;

  const [questionnaire, setQuestionnaire] = useState([]);
  const [questionnaireObject, setQuestionnaireObject] = useState({});
  const [isOtherEditState, setIsOtherEditState] = useState(-1);
  const [otherEditValue, setOtherEditValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectionSave = () => {
    updateUser({
      variables: {
        metadata: {
          ...user?.metadata,
          onboardingTriageSelection,
        },
      },
    }).then(() => {
      const featureMap = {
        banking: 'landlord_banking',
        rentCollection: 'rent_collection',
        bookkeeping: 'bookkeeping',
      };
      sendSegmentEvent('onboarding_complete_path_selection', {
        feature: featureMap[onboardingTriageSelection],
      });
      sendSegmentEvent('onboarding_path_selection', {
        feature: featureMap[onboardingTriageSelection],
      });
      refetchUser();
    });
  };

  const setAnswer = (question, answer, newPage, eventString) => {
    if (eventString) {
      sendSegmentEvent(`onboarding_survey_${eventString}_continue`);
    }
    // updateSurvey call array
    const newQuestionnaire = questionnaire;
    newQuestionnaire.push({
      question,
      answer: answer.value || answer,
    });
    if (answer.type === 'text') {
      newQuestionnaire.push({
        question: 'otherSource',
        answer: otherEditValue,
      });
    }
    setQuestionnaire(newQuestionnaire);
    // segment event object
    const newQuestionnaireObject = questionnaireObject;
    newQuestionnaireObject[question] = answer;
    if (answer.type === 'text') {
      newQuestionnaireObject.otherSource = otherEditValue;
    }
    setQuestionnaireObject(newQuestionnaireObject);
    if (newPage) {
      setPropertySurveyPanelIndex(propertySurveyPanelIndex + 1);
    } else if (answer.type === 'text') {
      handleOnSubmit(survey.version.default, answer.type);
    }
  };

  const handleOnSubmit = (version, type = 'multi') => {
    if (type === 'text') {
      sendSegmentEvent('onboarding_complete_property_survey', {
        version,
        questionnaire: questionnaireObject,
        otherSource: otherEditValue,
      });
    } else {
      sendSegmentEvent('onboarding_complete_property_survey', {
        version,
        questionnaire: questionnaireObject,
      });
    }
    updateSurvey({ variables: { version, questionnaire } })
      .then(() => {
        // saves the onboarding selection and refreshes user
        handleSelectionSave();
      })
      .then(() => {
        if (showAddProperty) {
          navigate(ONBOARDING_ADD_PROPERTY);
        } else {
          navigate(ONBOARDING_TRIAGE);
        }
      });
  };

  const handleOnOtherChange = (e) => {
    setOtherEditValue(e.target.value);
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <Tabs index={propertySurveyPanelIndex} mt="0 !important">
        <TabPanels>
          {surveyQuestions.map((item, index) => (
            <TabPanel padding="0 0 40px 0 !important" overflow="auto">
              {isLoading ? (
                <SlLoader />
              ) : (
                <Box mt={isMobile || index !== 0 ? '0' : '40px'} pb="32px">
                  <Text {...titleTextStyles(index !== 0)} mb={item.subQuestion ? '8px' : '32px'}>
                    {item.question}
                  </Text>
                  {item.subQuestion && (
                    <Text {...subTitleTextStyles} mb="32px">
                      {item.subQuestion}
                    </Text>
                  )}
                  <Stack spacing={2}>
                    {item.answers.map((answer, answerIndex) =>
                      isOtherEditState !== answerIndex ? (
                        <BaselaneButtonCard
                          id={`question${index}-number-option${answerIndex}`}
                          key={uuidv4()}
                          type="clean"
                          buttonStyles={buttonCardStyles}
                          onClick={() => {
                            if (answer.type === 'text') {
                              setIsOtherEditState(answerIndex);
                            } else {
                              setIsOtherEditState(-1);
                              if (item.eventString === 'onboarding_triage_selection') {
                                setOnboardingTriageSelection(answer.value);
                              }
                              setAnswer(
                                item.question,
                                answer.value || answer,
                                index !== surveyQuestionsLength - 1,
                                item.eventString
                              );
                              if (index === surveyQuestionsLength - 1) {
                                setIsLoading(true);
                                handleOnSubmit(survey.version.default);
                              }
                            }
                          }}
                        >
                          <Text>{answer.label || answer}</Text>
                        </BaselaneButtonCard>
                      ) : (
                        <Box {...otherBoxStyles}>
                          <HStack spacing={1}>
                            <Text fontWeight="500">{answer.label || answer}</Text>
                            <Input
                              id="property-survey-other-answer"
                              name="other"
                              placeholder="Type your answer"
                              value={otherEditValue}
                              onChange={handleOnOtherChange}
                              {...formInputStyles}
                              borderColor="brand.blue.700 !important"
                              bg="brand.neutral.white"
                              w="100%"
                              isRequired
                              autoFocus
                              maxLength="150"
                            />
                            <SaveChangesBtn
                              variant="filled"
                              palette="primary"
                              size="lg"
                              isDisabled={!otherEditValue}
                              onClick={() => {
                                setIsOtherEditState(-1);
                                setAnswer(
                                  item.question,
                                  answer,
                                  index !== surveyQuestionsLength - 1,
                                  item.eventString
                                );
                              }}
                            />
                          </HStack>
                        </Box>
                      )
                    )}
                  </Stack>
                </Box>
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </ChakraProvider>
  );
};

export default PropertySurvey;
