import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { ApmRoutes } from '@elastic/apm-rum-react';
import { EmptyPage, PageWrapper } from '@shared/layouts';
import VerifiedUserPage from '@core/components/VerifiedUserPage';
import UnverifiedUserPage from '@core/components/UnverifiedUserPage';
import OnboardingFlowsRoutes from '@core/components/App/routes/OnboardingFlowsRoutes';
import AlternatePhonePage from '@core/components/PhoneVerification/AlternatePhonePage';
import TransactionsPage from '@core/components/Transactions';
import GetStartedPage from '@core/components/GetStartedPage';
import NativeBankingPage from '@core/components/NativeBankingPage';
import ExternalAccountsPage from '@core/components/ExternalAccountsPage';
import LeasesPage from '@pages/LeasesPage';
import { UpdateAllReceivingAccounts } from '@pages/LeasesPage/UpdateAllReceivingAccounts/UpdateAllReceivingAccounts';
import PropertiesPage from '@pages/PropertiesPage';
import PropertiesResponsivePage from '@pages/PropertiesResponsivePage';
import CashFlowNewPage from '@core/components/CashFlowPage';
import PortfolioKPIsPage from '@core/components/PortfolioKPIsPage';
import TenantsPage from '@pages/TenantsPage';
import UserProfilePage from '@core/components/UserProfilePage';
import InsurancePage from '@core/components/InsurancePage';
import LoansPage from '@core/components/LoansPage';
import { Referral } from '@core/pages';
import Accounts from '@core/components/NativeBankingPage/MainContent/components/Accounts';
import Cards from '@core/components/NativeBankingPage/MainContent/components/Cards';
import Statements from '@core/components/NativeBankingPage/MainContent/components/Statements';
import BankEntityPage from '@pages/BankEntity';
import MyAccountsPanel from '@core/components/ExternalAccountsPage/components/MyAccountsPanel';
import CardsTable from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsTable';
import EditMasterAccountDrawer from '@pages/BankEntity/EditEntityDrawer';
import EditMailingAddressDrawer from '@pages/BankEntity/EditEntityDrawer/EditMailingAddress';
import AddVirtualCard from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/AddVirtualCard';
import AddAccountDrawer from '@pages/BankEntity/AddAccount';
import CheckbookDrawer from '@pages/BankEntity/OrderCheckbook';
import Redeem from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsSummary/Redeem';
import AddBaselaneAccount from '@core/components/NativeBankingPage/components/AddBaselaneAccount';
import UpdateBankAccountDrawer from '@shared/components/UpdateBankAccountDrawer';
import QuickPayLearnMoreDrawer from '@shared/components/QuickPayLearnMoreDrawer';
import { TransfersPage } from '@core/pages/Transfers/Transfers';
import SendFunds from '@core/pages/Transfers/components/SendFunds';
import { Activity } from '@core/pages/Transfers/components/Activity/activity';
import { Recipients } from '@core/pages/Transfers/components/Recipients';
import { TransferDetails } from '@core/pages/Transfers/components/Activity/TransferDetails';
import RecipientDetails from '@core/pages/Transfers/components/Recipients/RecipientDetails';
import AddRecipient from '@core/pages/Transfers/components/Recipients/AddRecipient';
import { ScheduledPayments } from '@core/pages/Transfers/components/ScheduledPayments/index';
import ScheduledPaymentDetailsDrawer from '@core/pages/Transfers/components/ScheduledPayments/scheduled-payment-details/ScheduledPaymentDetailsDrawer';
import AddAccountsDrawer from '@shared/components/AddAccountsDrawer';
import LoginPage from '@core/pages/LoginPage';
import AddFunds from '@core/pages/Transfers/components/AddFunds';
import GenericAlert from '@core/pages/LoginPage/components/GenericAlert';
import InactivityTimeout from '@features/Authentication/components/InactivityTimeout';
import Partnerships from '@pages/Partnerships';
import PaymentMethodDetailsWire from '@pages/Transfers/components/Recipients/components/drawers/PaymentMethodDetailsWire';
import PaymentMethodDetailsCheck from '@pages/Transfers/components/Recipients/components/drawers/PaymentMethodDetailsCheck';
import PaymentMethodDetailsACH from '@pages/Transfers/components/Recipients/components/drawers/PaymentMethodDetailsACH';
import RecipientDetailsFormEdit from '@pages/Transfers/components/Recipients/components/RecipientDetailsFormEdit';
import BankTransfer from '@pages/Transfers/components/AddFunds/components/BankTransfer';
import CheckDepositDrawer from '@pages/Transfers/components/AddFunds/check-deposit/CheckDepositDrawer';
import TransferMethodSelector from '@pages/Transfers/components/AddFunds/components/TransferMethodSelector';
import DigitalWallet from '@pages/Transfers/components/AddFunds/components/DigitalWallet';
import RecipientDetailsDelete from '@pages/Transfers/components/Recipients/components/modals/RecipientDetailsDelete';
import PaymentMethodDelete from '@pages/Transfers/components/Recipients/components/modals/PaymentMethodDelete';
import { ForgotPassword, ResetPassword } from '@pages/ForgotPassword';
import SessionExpiredMessage from '@pages/LoginPage/components/SessionExpiredMessage';
import MaintenancePropertyDrawer from '@features/Property/MaintenancePropertyDrawer';
import TransferFundsFeatureGuard from '@shared/components/TransferFundsDrawer/TransferFundsFeatureGuard';
import ScheduledTransferDetails from '@core/pages/Transfers/components/ScheduledPayments/scheduled-transfer-details/ScheduledTransferDetails';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  LOGIN,
  SOMETHING_WENT_WRONG,
  SESSION_EXPIRED,
  SIGN_UP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFIED,
  UNVERIFIED,
  TRANSACTIONS,
  ADD_BASELANE_BANK_ACCOUNT,
  ADD_VIRTUAL_ACCOUNT,
  TRANSFER_FUNDS,
  ADD_FUNDS_BANK_TRANSFER,
  ADD_ACCOUNT,
  HOME,
  DASHBOARD,
  TRANSFERS_PAYMENTS,
  TRANSFERS_ACTIVITY,
  TRANSFERS_DETAILS,
  SEND_FUNDS,
  TRANSFERS_RECIPIENTS,
  TRANSFERS_RECIPIENT_ADD,
  TRANSFERS_PAYMENT_METHOD_ACH,
  TRANSFERS_PAYMENT_METHOD_WIRE,
  TRANSFERS_PAYMENT_METHOD_CHECK,
  TRANSFERS_RECIPIENTS_DETAILS,
  TRANSFERS_RECIPIENTS_DETAILS_EDIT,
  TRANSFERS_RECIPIENT_DELETE,
  TRANSFERS_PAYMENT_METHOD_DELETE,
  TRANSFERS_SCHEDULED,
  SCHEDULED_PAYMENT_DETAILS,
  NATIVE_BANK,
  NB_ACCOUNTS,
  ADD_FUNDS,
  ANALYTICS_REPORTING,
  ADD_FUNDS_CHECK_DEPOSIT,
  ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS,
  ADD_FUNDS_DIGITAL_WALLET,
  ADD_FUNDS_TRANSFER_METHOD_SELECTOR,
  REDEEM_CASHBACK,
  NB_CARDS,
  ADD_VIRTUAL_CARD,
  NB_STATEMENT_DOCUMENTS,
  NB_BANK_ACCOUNT,
  NB_BANK_ACCOUNT_CARDS,
  ORDER_CHECKBOOK,
  EDIT_ENTITY_ACCOUNT,
  EDIT_ENTITY_ADDRESS,
  EXTERNAL_ACCOUNTS,
  EA_MY_ACCOUNTS,
  LEASES,
  LEASES_UPDATE_ACCOUNTS,
  LEASES_QUICKPAY_LEARN_MORE,
  PROPERTIES,
  CASH_FLOW_STATEMENT,
  CASH_FLOW_TAX_PACKAGE,
  GET_STARTED,
  USER_PROFILE,
  USER_PROFILE_UPDATE_ACCOUNTS,
  KPIS,
  TENANTS,
  REFERRAL,
  INSURANCE,
  LOANS,
  PARTNERSHIPS,
  MAINTENANCE_REQUEST,
  EMAIL_EXISTS,
  SIGN_UP_AS_RENTER,
  SCHEDULED_TRANSFER_DETAILS,
  PHONE_REQUIRED,
} from '@routes';

import EmailValidation from '@pages/DeferredSignupPropertySurvey/components/EmailValidation';
import RenterSignUpInfo from '@pages/DeferredSignupPropertySurvey/components/RenterSignUpInfo';
import DeferredSignUpExperiment from '@pages/DeferredSignupPropertySurvey/components/DeferredSignUpExperiment';
import DashboardFeatureGateGuard from '@core/components/App/components/guards/DashboardFeatureGateGuard';
import UserWrapper from '../components/wrappers/UserWrapper';
import AuthenticationGuard from '../components/guards/AuthenticationGuard';
import CashFlowWrapper from '../components/wrappers/CashflowWrapper';
import BankWrapper from '../components/wrappers/BankWrapper';
import TransactionWrapper from '../components/wrappers/TransactionWrapper';
import InitializeAnonymously from '../components/wrappers/InitializeAnonymously';
import InitializeWithUser from '../components/wrappers/InitializeWithUser';
import OnboardingCompletedLogic from '../components/logic/OnboardingCompletedLogic';
import EmailInviteCodeLogic from '../components/logic/EmailInviteCodeLogic';
import LoginNavigationLogic from '../components/logic/LoginNavigationLogic';
import SignupTestRedirectLogic from '../components/logic/SignupTestRedirectLogic';
import FeatureGateGuard from '../components/guards/FeatureGateGuard';

/**
 * This is a react-router-dom routing file that defines the root of all the possible routes in the app.
 *
 * Note: This file should only contain nested <Routes> and <Route> components.
 * Display logic, business logic, data providers can be defined in various components
 * that are passed via the element property.
 *
 * See: App/components/guards, App/components/logic and App/components/wrappers
 */
const MainRoutes = () => {
  const { isMax767 } = getBreakPoints();

  return (
    <ApmRoutes>
      {/* Handle redirects for invite codes */}
      <Route element={<EmailInviteCodeLogic />}>
        {/* Public routes */}
        {/* Initialize third party scripts without user data */}
        <Route element={<InitializeAnonymously />}>
          <Route element={<EmptyPage />}>
            <Route exact path={LOGIN} element={<LoginPage />}>
              <Route exact path={SOMETHING_WENT_WRONG} element={<GenericAlert />} />
              <Route exact path={SESSION_EXPIRED} element={<SessionExpiredMessage />} />
            </Route>
            <Route path={SIGN_UP}>
              <Route exact path={SIGN_UP} element={<DeferredSignUpExperiment />} />
              <Route exact path={EMAIL_EXISTS} element={<EmailValidation />} />
              <Route exact path={SIGN_UP_AS_RENTER} element={<RenterSignUpInfo />} />
            </Route>
            <Route exact path={FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route exact path={RESET_PASSWORD} element={<ResetPassword />} />
            <Route exact path={VERIFIED} element={<VerifiedUserPage />} />
            <Route exact path={UNVERIFIED} element={<UnverifiedUserPage />} />
          </Route>
        </Route>
        {/* Authenticated routes */}
        <Route element={<UserWrapper />}>
          {/* Initialize third party scripts with user data */}
          <Route element={<InitializeWithUser />}>
            {/* Start FE inactivity timeout code */}
            <Route element={<InactivityTimeout />}>
              {/* Routes that require login for security */}
              <Route element={<AuthenticationGuard />}>
                {/* Logic for stored redirects */}
                <Route element={<LoginNavigationLogic />}>
                  {/* Routes for onboarding */}
                  <Route element={<EmptyPage />}>
                    <Route exact path={PHONE_REQUIRED} element={<AlternatePhonePage />} />
                    <Route element={<SignupTestRedirectLogic />}>
                      <Route path="*" element={<OnboardingFlowsRoutes />} />
                    </Route>
                  </Route>
                  {/* Main Application routes, with navbar */}
                  <Route element={<PageWrapper />}>
                    <Route element={<TransactionWrapper />}>
                      <Route element={<BankWrapper />}>
                        <Route element={<SignupTestRedirectLogic />}>
                          <Route exact path={TRANSACTIONS} element={<TransactionsPage />}>
                            <Route
                              path={ADD_BASELANE_BANK_ACCOUNT}
                              element={<AddBaselaneAccount from={TRANSACTIONS} />}
                            >
                              <Route
                                path={ADD_VIRTUAL_ACCOUNT}
                                element={
                                  <AddAccountDrawer
                                    from={`${TRANSACTIONS}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                  />
                                }
                              />
                            </Route>
                            <Route path={TRANSFER_FUNDS} element={<TransferFundsFeatureGuard />} />
                            <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                              <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                            </Route>
                            <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                          </Route>
                          <Route element={<CashFlowWrapper />}>
                            <Route path={HOME} element={<GetStartedPage />}>
                              <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                              <Route
                                path={ADD_BASELANE_BANK_ACCOUNT}
                                element={<AddBaselaneAccount from={HOME} />}
                              >
                                <Route
                                  path={ADD_VIRTUAL_ACCOUNT}
                                  element={
                                    <AddAccountDrawer
                                      from={`${HOME}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                    />
                                  }
                                />
                              </Route>
                              <Route
                                path={TRANSFER_FUNDS}
                                element={<TransferFundsFeatureGuard />}
                              />
                              <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                              </Route>
                            </Route>
                            <Route exact path={DASHBOARD} element={<DashboardFeatureGateGuard />}>
                              <Route
                                path={ADD_ACCOUNT}
                                element={<AddAccountsDrawer from={DASHBOARD} />}
                              />
                              <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                              </Route>
                            </Route>
                          </Route>
                          {/* Transfers */}
                          <Route path={TRANSFERS_PAYMENTS} element={<TransfersPage />}>
                            {/* Transfers / Activity */}
                            <Route path={TRANSFERS_ACTIVITY} element={<Activity />}>
                              <Route path={TRANSFERS_DETAILS} element={<TransferDetails />} />
                              {/* Move money menu routes 1 */}
                              <Route path={SEND_FUNDS} element={<SendFunds />} />
                              <Route
                                path={TRANSFER_FUNDS}
                                element={<TransferFundsFeatureGuard />}
                              />
                              <Route
                                path={ADD_FUNDS}
                                element={<AddFunds />}
                                from={TRANSFERS_ACTIVITY}
                              >
                                <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                  <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                </Route>
                                <Route
                                  path={ADD_FUNDS_CHECK_DEPOSIT}
                                  element={<CheckDepositDrawer />}
                                />
                                <Route
                                  path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                  element={<DigitalWallet id={parseInt('0', 10)} />}
                                />
                                <Route
                                  path={ADD_FUNDS_DIGITAL_WALLET}
                                  element={<DigitalWallet id={parseInt('1', 10)} />}
                                >
                                  <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                    <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                  </Route>
                                  <Route
                                    path={ADD_FUNDS_CHECK_DEPOSIT}
                                    element={<CheckDepositDrawer />}
                                  />
                                </Route>
                                <Route
                                  path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                  element={<TransferMethodSelector />}
                                />
                              </Route>
                              {/* end of Move money menu routes 1 */}
                            </Route>
                            <Route path={TRANSFERS_RECIPIENTS} element={<Recipients />}>
                              <Route path={SEND_FUNDS} element={<SendFunds />} />
                              <Route
                                path={TRANSFERS_RECIPIENT_ADD}
                                element={
                                  <AddRecipient
                                    from={`/${TRANSFERS_PAYMENTS}/${TRANSFERS_RECIPIENTS}`}
                                  />
                                }
                              >
                                <Route
                                  path={TRANSFERS_PAYMENT_METHOD_ACH}
                                  element={<PaymentMethodDetailsACH />}
                                />
                                <Route
                                  path={TRANSFERS_PAYMENT_METHOD_WIRE}
                                  element={<PaymentMethodDetailsWire />}
                                />
                                <Route
                                  path={TRANSFERS_PAYMENT_METHOD_CHECK}
                                  element={<PaymentMethodDetailsCheck />}
                                />
                              </Route>
                              <Route
                                path={TRANSFERS_RECIPIENTS_DETAILS}
                                element={
                                  <RecipientDetails
                                    from={`/${TRANSFERS_PAYMENTS}/${TRANSFERS_RECIPIENTS}`}
                                  />
                                }
                              >
                                <Route path={SEND_FUNDS} element={<SendFunds />} />
                                <Route
                                  path={TRANSFERS_RECIPIENTS_DETAILS_EDIT}
                                  element={<RecipientDetailsFormEdit />}
                                />
                                <Route
                                  path={TRANSFERS_RECIPIENT_DELETE}
                                  element={<RecipientDetailsDelete />}
                                />
                                <Route
                                  path={TRANSFERS_PAYMENT_METHOD_ACH}
                                  element={<PaymentMethodDetailsACH />}
                                >
                                  <Route
                                    path={TRANSFERS_PAYMENT_METHOD_DELETE}
                                    element={<PaymentMethodDelete />}
                                  />
                                  <Route
                                    path={TRANSFERS_RECIPIENT_DELETE}
                                    element={<RecipientDetailsDelete />}
                                  />
                                </Route>
                                <Route
                                  path={TRANSFERS_PAYMENT_METHOD_WIRE}
                                  element={<PaymentMethodDetailsWire />}
                                >
                                  <Route
                                    path={TRANSFERS_PAYMENT_METHOD_DELETE}
                                    element={<PaymentMethodDelete />}
                                  />
                                  <Route
                                    path={TRANSFERS_RECIPIENT_DELETE}
                                    element={<RecipientDetailsDelete />}
                                  />
                                </Route>
                                <Route
                                  path={TRANSFERS_PAYMENT_METHOD_CHECK}
                                  element={<PaymentMethodDetailsCheck />}
                                >
                                  <Route
                                    path={TRANSFERS_PAYMENT_METHOD_DELETE}
                                    element={<PaymentMethodDelete />}
                                  />
                                  <Route
                                    path={TRANSFERS_RECIPIENT_DELETE}
                                    element={<RecipientDetailsDelete />}
                                  />
                                </Route>
                              </Route>
                            </Route>
                            <Route path={TRANSFERS_SCHEDULED} element={<ScheduledPayments />}>
                              <Route
                                path={SCHEDULED_PAYMENT_DETAILS}
                                element={
                                  <ScheduledPaymentDetailsDrawer
                                    from={`/${TRANSFERS_PAYMENTS}/${TRANSFERS_SCHEDULED}`}
                                  />
                                }
                              >
                                <Route
                                  path={SCHEDULED_TRANSFER_DETAILS}
                                  element={<ScheduledTransferDetails />}
                                />
                              </Route>
                              {/* Move money routes */}
                              <Route path={SEND_FUNDS} element={<SendFunds />} />
                              <Route
                                path={TRANSFER_FUNDS}
                                element={<TransferFundsFeatureGuard />}
                              />
                              <Route
                                path={ADD_FUNDS}
                                element={<AddFunds />}
                                from={TRANSFERS_ACTIVITY}
                              >
                                <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                  <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                </Route>
                                <Route
                                  path={ADD_FUNDS_CHECK_DEPOSIT}
                                  element={<CheckDepositDrawer />}
                                />
                                <Route
                                  path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                  element={<DigitalWallet id={parseInt('0', 10)} />}
                                />
                                <Route
                                  path={ADD_FUNDS_DIGITAL_WALLET}
                                  element={<DigitalWallet id={parseInt('1', 10)} />}
                                >
                                  <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                    <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                                  </Route>
                                  <Route
                                    path={ADD_FUNDS_CHECK_DEPOSIT}
                                    element={<CheckDepositDrawer />}
                                  />
                                </Route>
                                <Route
                                  path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                  element={<TransferMethodSelector />}
                                />
                              </Route>
                            </Route>
                          </Route>

                          {/* Baselane banking page */}
                          <Route path={NATIVE_BANK} element={<NativeBankingPage />}>
                            <Route path={NATIVE_BANK} element={<Navigate to={NB_ACCOUNTS} />} />

                            <Route path={NB_ACCOUNTS} element={<Accounts />}>
                              <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                              </Route>
                              {/* Move money menu routes 2 */}
                              <Route path={SEND_FUNDS} element={<SendFunds />} />
                              <Route
                                path={TRANSFER_FUNDS}
                                element={<TransferFundsFeatureGuard />}
                              />
                              <Route path={ADD_FUNDS} element={<AddFunds />}>
                                <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                  <Route
                                    path={ADD_ACCOUNT}
                                    element={<AddAccountsDrawer from={ANALYTICS_REPORTING} />}
                                  />
                                </Route>
                                <Route
                                  path={ADD_FUNDS_CHECK_DEPOSIT}
                                  element={<CheckDepositDrawer />}
                                />
                                <Route
                                  path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                  element={<DigitalWallet id={parseInt('0', 10)} />}
                                />
                                <Route
                                  path={ADD_FUNDS_DIGITAL_WALLET}
                                  element={<DigitalWallet id={parseInt('1', 10)} />}
                                />
                                <Route
                                  path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                  element={<TransferMethodSelector />}
                                />
                              </Route>
                              {/* end of Move money menu routes 2 */}
                              <Route
                                path={REDEEM_CASHBACK}
                                element={<Redeem from={NB_ACCOUNTS} />}
                              />
                              <Route
                                path={ADD_BASELANE_BANK_ACCOUNT}
                                element={<AddBaselaneAccount from={NB_ACCOUNTS} />}
                              >
                                <Route
                                  path={ADD_VIRTUAL_ACCOUNT}
                                  element={
                                    <AddAccountDrawer
                                      from={`${NB_ACCOUNTS}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                    />
                                  }
                                />
                              </Route>
                            </Route>
                            <Route path={NB_CARDS} element={<Cards />}>
                              {/* Move money menu routes 3 */}
                              <Route path={SEND_FUNDS} element={<SendFunds />} />
                              <Route
                                path={TRANSFER_FUNDS}
                                element={<TransferFundsFeatureGuard />}
                              />
                              <Route path={ADD_FUNDS} element={<AddFunds />}>
                                <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                  <Route
                                    path={ADD_ACCOUNT}
                                    element={<AddAccountsDrawer from={`${ANALYTICS_REPORTING}`} />}
                                  />
                                </Route>
                                <Route
                                  path={ADD_FUNDS_CHECK_DEPOSIT}
                                  element={<CheckDepositDrawer />}
                                />
                                <Route
                                  path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                  element={<DigitalWallet id={parseInt('0', 10)} />}
                                />
                                <Route
                                  path={ADD_FUNDS_DIGITAL_WALLET}
                                  element={<DigitalWallet id={parseInt('1', 10)} />}
                                />
                                <Route
                                  path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                  element={<TransferMethodSelector />}
                                />
                              </Route>
                              {/* end of Move money menu routes 3 */}
                              <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                              </Route>
                              <Route path={ADD_VIRTUAL_CARD} element={<AddVirtualCard />} />
                              <Route path={REDEEM_CASHBACK} element={<Redeem from={NB_CARDS} />} />
                              <Route
                                path={ADD_BASELANE_BANK_ACCOUNT}
                                element={<AddBaselaneAccount from={NB_CARDS} />}
                              >
                                <Route
                                  path={ADD_VIRTUAL_ACCOUNT}
                                  element={
                                    <AddAccountDrawer
                                      from={`${NB_CARDS}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                    />
                                  }
                                />
                              </Route>
                            </Route>
                            <Route path={NB_STATEMENT_DOCUMENTS} element={<Statements />}>
                              {/* Move money menu routes 4 */}
                              <Route path={SEND_FUNDS} element={<SendFunds />} />
                              <Route
                                path={TRANSFER_FUNDS}
                                element={<TransferFundsFeatureGuard />}
                              />
                              <Route path={ADD_FUNDS} element={<AddFunds />}>
                                <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />} />
                                <Route
                                  path={ADD_FUNDS_CHECK_DEPOSIT}
                                  element={<CheckDepositDrawer />}
                                />
                                <Route
                                  path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                  element={<DigitalWallet id={parseInt('0', 10)} />}
                                >
                                  <Route
                                    path={ADD_FUNDS_BANK_TRANSFER}
                                    element={<BankTransfer />}
                                  />
                                  <Route
                                    path={ADD_FUNDS_CHECK_DEPOSIT}
                                    element={<CheckDepositDrawer />}
                                  />
                                </Route>
                                <Route
                                  path={ADD_FUNDS_DIGITAL_WALLET}
                                  element={<DigitalWallet id={parseInt('1', 10)} />}
                                >
                                  <Route
                                    path={ADD_FUNDS_BANK_TRANSFER}
                                    element={<BankTransfer />}
                                  />
                                  <Route
                                    path={ADD_FUNDS_CHECK_DEPOSIT}
                                    element={<CheckDepositDrawer />}
                                  />
                                </Route>
                                <Route
                                  path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                  element={<TransferMethodSelector />}
                                />
                              </Route>
                              {/* end of Move money menu routes 4 */}
                              <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                              </Route>
                              <Route
                                path={REDEEM_CASHBACK}
                                element={<Redeem from={NB_STATEMENT_DOCUMENTS} />}
                              />
                              <Route
                                path={ADD_BASELANE_BANK_ACCOUNT}
                                element={<AddBaselaneAccount from={NB_STATEMENT_DOCUMENTS} />}
                              >
                                <Route
                                  path={ADD_VIRTUAL_ACCOUNT}
                                  element={
                                    <AddAccountDrawer
                                      from={`${NB_STATEMENT_DOCUMENTS}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                    />
                                  }
                                />
                              </Route>
                            </Route>
                          </Route>
                          {/* Baselane entity page */}
                          <Route path={NB_BANK_ACCOUNT} element={<BankEntityPage />}>
                            {/* Move money menu routes 5 */}
                            <Route path={SEND_FUNDS} element={<SendFunds />} />
                            <Route path={ADD_FUNDS} element={<AddFunds />}>
                              <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />} />
                              <Route
                                path={ADD_FUNDS_CHECK_DEPOSIT}
                                element={<CheckDepositDrawer />}
                              />
                              <Route
                                path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                element={<DigitalWallet id={parseInt('0', 10)} />}
                              >
                                <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />} />
                                <Route
                                  path={ADD_FUNDS_CHECK_DEPOSIT}
                                  element={<CheckDepositDrawer />}
                                />
                              </Route>
                              <Route
                                path={ADD_FUNDS_DIGITAL_WALLET}
                                element={<DigitalWallet id={parseInt('1', 10)} />}
                              >
                                <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />} />
                                <Route
                                  path={ADD_FUNDS_CHECK_DEPOSIT}
                                  element={<CheckDepositDrawer />}
                                />
                              </Route>
                              <Route
                                path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                element={<TransferMethodSelector />}
                              />
                            </Route>
                            {/* end of Move money menu routes 5 */}
                            <Route path={NB_BANK_ACCOUNT_CARDS} element={<CardsTable />}>
                              {/* Move money menu routes 6 */}
                              <Route path={SEND_FUNDS} element={<SendFunds />} />
                              <Route path={ADD_FUNDS} element={<AddFunds />}>
                                <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />} />
                                <Route
                                  path={ADD_FUNDS_CHECK_DEPOSIT}
                                  element={<CheckDepositDrawer />}
                                />
                                <Route
                                  path={ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS}
                                  element={<DigitalWallet id={parseInt('0', 10)} />}
                                >
                                  <Route
                                    path={ADD_FUNDS_BANK_TRANSFER}
                                    element={<BankTransfer />}
                                  />
                                  <Route
                                    path={ADD_FUNDS_CHECK_DEPOSIT}
                                    element={<CheckDepositDrawer />}
                                  />
                                </Route>
                                <Route
                                  path={ADD_FUNDS_DIGITAL_WALLET}
                                  element={<DigitalWallet id={parseInt('1', 10)} />}
                                >
                                  <Route
                                    path={ADD_FUNDS_BANK_TRANSFER}
                                    element={<BankTransfer />}
                                  />
                                  <Route
                                    path={ADD_FUNDS_CHECK_DEPOSIT}
                                    element={<CheckDepositDrawer />}
                                  />
                                </Route>
                                <Route
                                  path={ADD_FUNDS_TRANSFER_METHOD_SELECTOR}
                                  element={<TransferMethodSelector />}
                                />
                              </Route>
                              {/* end of Move money menu routes 6 */}
                              <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                                <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                              </Route>
                              <Route
                                path={ORDER_CHECKBOOK}
                                element={<CheckbookDrawer from={NB_BANK_ACCOUNT} />}
                              />
                              <Route
                                path={EDIT_ENTITY_ACCOUNT}
                                element={<EditMasterAccountDrawer />}
                              >
                                <Route
                                  path={EDIT_ENTITY_ADDRESS}
                                  element={<EditMailingAddressDrawer />}
                                />
                              </Route>
                              <Route path={ADD_VIRTUAL_CARD} element={<AddVirtualCard />} />
                              <Route path={ADD_VIRTUAL_ACCOUNT} element={<AddAccountDrawer />} />
                            </Route>
                            <Route
                              path={ORDER_CHECKBOOK}
                              element={<CheckbookDrawer from={NB_BANK_ACCOUNT} />}
                            />
                            <Route path={EDIT_ENTITY_ACCOUNT} element={<EditMasterAccountDrawer />}>
                              <Route
                                path={EDIT_ENTITY_ADDRESS}
                                element={<EditMailingAddressDrawer />}
                              />
                            </Route>
                            <Route path={ADD_VIRTUAL_CARD} element={<AddVirtualCard />} />
                            <Route
                              path={ADD_VIRTUAL_ACCOUNT}
                              element={<AddAccountDrawer from={NB_BANK_ACCOUNT} />}
                            />
                            <Route path={TRANSFER_FUNDS} element={<TransferFundsFeatureGuard />} />
                          </Route>
                          <Route path={EXTERNAL_ACCOUNTS} element={<ExternalAccountsPage />}>
                            <Route exact path={EA_MY_ACCOUNTS} element={<MyAccountsPanel />} />
                          </Route>
                          <Route path={LEASES} element={<LeasesPage />}>
                            <Route
                              path={LEASES_UPDATE_ACCOUNTS}
                              element={<UpdateAllReceivingAccounts />}
                            />
                            <Route
                              path={LEASES_QUICKPAY_LEARN_MORE}
                              element={<QuickPayLearnMoreDrawer />}
                            />
                          </Route>
                          <Route
                            exact
                            path={PROPERTIES}
                            element={isMax767 ? <PropertiesResponsivePage /> : <PropertiesPage />}
                          >
                            <Route element={<FeatureGateGuard name="latchel-gate" />}>
                              <Route
                                path={MAINTENANCE_REQUEST}
                                element={<MaintenancePropertyDrawer from={`/${PROPERTIES}`} />}
                              />
                            </Route>
                          </Route>
                          <Route element={<CashFlowWrapper />}>
                            <Route path={ANALYTICS_REPORTING} element={<CashFlowNewPage />}>
                              <Route
                                path={ADD_ACCOUNT}
                                element={<AddAccountsDrawer from={`${ANALYTICS_REPORTING}`} />}
                              />
                            </Route>
                            <Route exact path={CASH_FLOW_STATEMENT} element={<CashFlowNewPage />} />
                            <Route
                              exact
                              path={CASH_FLOW_TAX_PACKAGE}
                              element={<CashFlowNewPage />}
                            />
                          </Route>
                          <Route exact path={GET_STARTED} element={<GetStartedPage />}>
                            <Route
                              path={ADD_BASELANE_BANK_ACCOUNT}
                              element={<AddBaselaneAccount from={GET_STARTED} />}
                            >
                              <Route
                                path={ADD_VIRTUAL_ACCOUNT}
                                element={
                                  <AddAccountDrawer
                                    from={`${GET_STARTED}/${ADD_BASELANE_BANK_ACCOUNT}`}
                                  />
                                }
                              />
                            </Route>
                            <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                            <Route path={ADD_FUNDS_BANK_TRANSFER} element={<BankTransfer />}>
                              <Route path={ADD_ACCOUNT} element={<AddAccountsDrawer />} />
                            </Route>
                          </Route>
                          <Route path={USER_PROFILE} element={<UserProfilePage />}>
                            <Route
                              path={USER_PROFILE_UPDATE_ACCOUNTS}
                              element={<UpdateBankAccountDrawer />}
                            />
                          </Route>
                        </Route>
                      </Route>
                      <Route element={<FeatureGateGuard name="property_metrics_page_gate" />}>
                        <Route exact path={KPIS} element={<PortfolioKPIsPage />} />
                      </Route>
                      <Route exact path={TENANTS} element={<TenantsPage />} />
                      <Route exact path={REFERRAL} element={<Referral />} />
                      <Route exact path={INSURANCE} element={<InsurancePage />} />
                      <Route exact path={LOANS} element={<LoansPage />} />
                      <Route element={<FeatureGateGuard name="partnerships-page" />}>
                        <Route exact path={PARTNERSHIPS} element={<Partnerships />}>
                          <Route element={<FeatureGateGuard name="latchel-gate" />}>
                            <Route
                              path={MAINTENANCE_REQUEST}
                              element={<MaintenancePropertyDrawer from={PARTNERSHIPS} />}
                            />
                          </Route>
                        </Route>
                      </Route>
                      {/**
                       * Catch-all
                       * This is where we end up if no specific route has been matched.
                       */}
                      <Route element={<OnboardingCompletedLogic />}>
                        <Route path="*" element={<Navigate to={LOGIN} />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </ApmRoutes>
  );
};

export default MainRoutes;
