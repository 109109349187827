import React from 'react';

function IconDuplexTriplex({
  w = 38,
  h = 42,
  color = 'currentColor',
}: {
  w?: string,
  h?: string,
  color?: string,
}) {
  return (
    <svg width={w} height={h} viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55559 0.93335H31.4445C34.5864 0.93335 37.1334 3.48035 37.1334 6.62224V36.1334C37.1334 39.2752 34.5864 41.8222 31.4445 41.8222H6.55559C3.4137 41.8222 0.866699 39.2752 0.866699 36.1334V6.62224C0.866699 3.48035 3.4137 0.93335 6.55559 0.93335ZM6.55559 3.77779C4.98465 3.77779 3.71114 5.0513 3.71114 6.62224V36.1334C3.71114 37.7043 4.98464 38.9778 6.55559 38.9778H31.4445C33.0154 38.9778 34.2889 37.7043 34.2889 36.1334V6.62224C34.2889 5.0513 33.0154 3.77779 31.4445 3.77779H6.55559Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3556 27.2444C18.5701 27.2444 17.9333 26.6076 17.9333 25.8222L17.9334 3.0666C17.9334 2.28113 18.5701 1.64438 19.3556 1.64438C20.141 1.64438 20.7778 2.28113 20.7778 3.0666L20.7778 25.8222C20.7778 26.6076 20.141 27.2444 19.3556 27.2444Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4001 41.8223H12.6001V30.0889C12.6001 27.7325 14.5103 25.8223 16.8668 25.8223H21.1334C23.4898 25.8223 25.4001 27.7325 25.4001 30.0889V41.8223ZM16.8668 28.6667C16.0813 28.6667 15.4445 29.3035 15.4445 30.0889V38.9778H22.5556V30.0889C22.5556 29.3035 21.9189 28.6667 21.1334 28.6667H16.8668Z"
        fill={color}
      />
    </svg>
  );
}

IconDuplexTriplex.defaultProps = {
  w: '38',
  h: '42',
  color: 'currentColor',
};

export default IconDuplexTriplex;
