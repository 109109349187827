import { gql } from '@apollo/client';

export const STRIPE_ON_BOARDING = gql`
  mutation stripeOnBoarding($refreshUrl: URL, $returnUrl: URL) {
    stripeOnBoarding(input: { refreshUrl: $refreshUrl, returnUrl: $returnUrl }) {
      created
      expires_at
      url
    }
  }
`;
