// @flow
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { HStack, Spacer, Box, Text } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import { NATIVE_BANK } from '@routes';
import TransactionContext from '@contexts/TransactionContext';
import { Icon16Clear } from '@icons/16px';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import getBreakPoints from '@core/utils/getBreakPoints';
import CreateManualTransactionDrawer from '../../CreateManualTransaction/CreateManualTransactionDrawer';
import TransactionsSearch from '../TransactionSearch';
import FilterDropdowns from './FilterDropdowns';
import TransactionsBulkActions from '../../TransactionsBulkActions';
import DownloadTrxs from './DownloadTrxs';
import Toggles from './Toggles';
import { getOptionsWithSubCategories } from '../../../CashFlowPage/helpers/cashflow.helpers';
import { compileSearchData } from '../TransactionSearch/helpers/search.helpers';

import {
  filtersFilterRowStyles,
  filterRowContainerStyles,
  filtersSecondRowStyles,
  filtersContainerStyles,
  bulkActionsTextStyles,
  filtersAndTogglesMobileContainerStyles,
} from './styles/transactionsFilters.styles';

type Props = {
  onToggleHiddenTransactions: Function,
  onToggleOnlyWithAttachments: Function,
  onShowUncategorizedTransactions: Function,
  onSearch: Function,
  isOutsideTrxPage?: boolean,
  showHiddenTransactions: boolean,
  showOnlyWithAttachments: boolean,
  showBulkActions: boolean,
  selectedTransactions: Array<Object>,
  handleDeselectTransactions: Function,
  setSelectedTransactions: Function,
  handleSelectedTransactions: Function,
  handleClear: Function,
  setInputValue: Function,
  inputValue: string,
  showingUncategorizedTransactions: string,
  setShowingUncategorizedTransactions: Function,
  currSort: Object,
  hasDropdownClearedExternally?: boolean,
  setHasDropdownClearedExternally?: Function,
  hideFilters?: boolean,
  styles?: Object,
  connectedAccountsFilterOptions: Array<Object>,
  refetch: Function,
  selectedFilters: Object,
  setSelectedFilters: Function,
  data: Array<Object>,
  transactionsLoading: boolean,
};

function TransactionsFilters({
  onToggleHiddenTransactions,
  onToggleOnlyWithAttachments,
  onShowUncategorizedTransactions,
  onSearch,
  isOutsideTrxPage,
  showHiddenTransactions,
  showOnlyWithAttachments,
  showBulkActions,
  handleDeselectTransactions,
  handleSelectedTransactions,
  selectedTransactions,
  setSelectedTransactions,
  handleClear,
  setInputValue,
  inputValue,
  showingUncategorizedTransactions,
  setShowingUncategorizedTransactions,
  currSort,
  hasDropdownClearedExternally,
  setHasDropdownClearedExternally,
  hideFilters,
  styles,
  connectedAccountsFilterOptions,
  refetch,
  selectedFilters,
  setSelectedFilters,
  data,
  transactionsLoading,
}: Props): any {
  const { isMin899 } = getBreakPoints();
  const { pathname } = useLocation();
  const { filters, setFilters, propertiesData, categoryWithSubOptions } = useContext(
    TransactionContext
  );

  const [selectedItem, setSelectedItem] = useState(null);
  const [hasActiveParentFilter, setHasActiveParentFilter] = useState(true);

  const propertyOptions = getPropertyData(propertiesData);
  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);
  const clientSearchableData = useMemo(
    () => compileSearchData(connectedAccountsFilterOptions, categoryOptions, propertyOptions),
    [connectedAccountsFilterOptions, categoryOptions, propertyOptions]
  );

  const handleUncategroziedChange = () => {
    setShowingUncategorizedTransactions(!showingUncategorizedTransactions);
    onShowUncategorizedTransactions(showingUncategorizedTransactions);
  };

  /**
   * Resets dropdown state to "unselected" default
   * when the clear button is clicked on.
   */
  const onClearClick = () => {
    setHasActiveParentFilter(false);
  };

  /**
   * Clear out the filter entirely if the global "clear" button is clicked
   */
  useEffect(() => {
    if (!hasDropdownClearedExternally) return;

    setSelectedItem(null);
    setSelectedFilters({
      ...selectedFilters,
      appliedPreset: null,
    });
  }, [hasDropdownClearedExternally]);

  const hasActiveFilter =
    selectedFilters?.accounts?.length > 0 ||
    selectedFilters?.appliedPreset ||
    (selectedFilters?.categories && selectedFilters?.categories?.length > 0) ||
    (selectedFilters?.dates?.start && selectedFilters?.dates?.end) ||
    selectedFilters?.timePeriod ||
    selectedFilters?.properties?.length > 0 ||
    showingUncategorizedTransactions;

  const filterDropdownAndTogglesProps = {
    hasDropdownClearedExternally,
    setHasDropdownClearedExternally,
    pathname,
    connectedAccountsFilterOptions,
    selectedItem,
    selectedFilters,
    hasActiveParentFilter,
    setHasActiveParentFilter,
    onClearClick,
    propertyOptions,
    showingUncategorizedTransactions,
    handleUncategroziedChange,
    showHiddenTransactions,
    onToggleHiddenTransactions,
    showOnlyWithAttachments,
    onToggleOnlyWithAttachments,
    categoryOptions,
    setSelectedFilters,
  };

  const displayActionsContainer =
    showBulkActions || (!showBulkActions && isMin899) || !isOutsideTrxPage;

  return (
    <Box {...{ ...filterRowContainerStyles, ...styles?.filterRowContainerStyles }}>
      {/* First row */}
      {displayActionsContainer && (
        <HStack {...filtersFilterRowStyles}>
          {showBulkActions && (
            <TransactionsBulkActions
              {...{
                handleDeselectTransactions,
                handleSelectedTransactions,
                selectedTransactions,
                setSelectedTransactions,
                refetch,
              }}
            />
          )}
          {!showBulkActions && isMin899 && (
            <Text {...bulkActionsTextStyles}>
              Tip: Use the checkboxes to select multiple transactions and edit them at once.
            </Text>
          )}

          <Spacer />

          {!isOutsideTrxPage && (
            <>
              <DownloadTrxs {...{ showHiddenTransactions }} />

              {/* Add Transaction */}
              {!pathname.includes(NATIVE_BANK) && <CreateManualTransactionDrawer />}
            </>
          )}
        </HStack>
      )}

      {/* TODO: remove redundant wrapper once global card filters are added + clean up styles */}
      {/* Second row */}
      {!hideFilters && (
        <HStack w="100%">
          <HStack {...filtersSecondRowStyles(isOutsideTrxPage)}>
            {/* Filter Dropdowns + Toggles  */}
            {isMin899 ? (
              <>
                <HStack {...filtersContainerStyles} gap={2} flex="1 1 auto">
                  {/* Search bar */}
                  <TransactionsSearch
                    {...{
                      inputValue,
                      setInputValue,
                      updateSearchText: onSearch,
                      setFilters,
                      filters,
                      showingUncategorizedTransactions,
                      currSort,
                      clientSearchableData,
                    }}
                  />
                  <FilterDropdowns {...filterDropdownAndTogglesProps} />
                </HStack>
                <Toggles {...filterDropdownAndTogglesProps} />
              </>
            ) : (
              <>
                <HStack {...filtersContainerStyles} w="100%">
                  {/* Search bar */}
                  <TransactionsSearch
                    {...{
                      inputValue,
                      setInputValue,
                      updateSearchText: onSearch,
                      setFilters,
                      filters,
                      showingUncategorizedTransactions,
                      currSort,
                      clientSearchableData,
                    }}
                  />
                </HStack>
                <HStack
                  {...filtersSecondRowStyles(isOutsideTrxPage)}
                  {...filtersAndTogglesMobileContainerStyles}
                >
                  <FilterDropdowns {...filterDropdownAndTogglesProps} />
                  <Toggles {...filterDropdownAndTogglesProps} />
                </HStack>
              </>
            )}
          </HStack>
        </HStack>
      )}

      {/* Third row */}
      {hasActiveFilter && !transactionsLoading && (
        <HStack {...{ pt: 2, px: 2, textStyle: 'sm', color: 'brand.neutral.600' }}>
          <Text>Showing {data?.transaction?.length || 0} transactions</Text>
          <Spacer />
          <BaselaneButton
            leftIcon={<Icon16Clear />}
            variant="transparent"
            palette="primary"
            size="sm"
            onClick={handleClear}
          >
            Clear filters
          </BaselaneButton>
        </HStack>
      )}
    </Box>
  );
}

TransactionsFilters.defaultProps = {
  isOutsideTrxPage: false,
  hasDropdownClearedExternally: false,
  setHasDropdownClearedExternally: () => {},
  hideFilters: false,
  styles: ({}: { ... }),
};

export default TransactionsFilters;
