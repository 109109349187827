import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_TAGS } from '@core/apollo/queries';
import { sortCategories } from '@core/utils/sort';

const buildCategoriesById = (tags, { buildForDropdown = false } = {}) => {
  const categoriesById = {};
  for (const tag of tags) {
    for (const tagSubType of tag.subType) {
      categoriesById[tagSubType.id] = tagSubType;
      for (const nestedTagSubType of tagSubType.subType) {
        // If we are building categories for dropdown, we need to add the parent category id to the nested category id.
        // like this: parentCategoryId-nestedCategoryId
        const nestedTagSubTypeId = buildForDropdown
          ? `${tagSubType.id}-${nestedTagSubType.id}`
          : nestedTagSubType.id;
        categoriesById[nestedTagSubTypeId] = {
          parentId: tagSubType.id,
          ...nestedTagSubType,
        };
      }
    }
  }
  return categoriesById;
};

const buildCategoriesForDropdown = (tags) => {
  const categories = tags.map((tag) => {
    const items = tag.subType.reduce((acc, tagSubType) => {
      const category = {
        id: tagSubType.id,
        name: tagSubType.name,
        hasChildren: tagSubType?.subType.length > 0,
        searchValues: [tagSubType.name],
      };

      // Almost all (?) of the [TagSubType] objects have a an additional subType property which is an array of [TagSubType] objects.
      const nestedTagSubTypes = tagSubType?.subType ?? [];

      const nestedCategories = nestedTagSubTypes.map((nestedTagSubType) => {
        category.searchValues = category.searchValues.concat(nestedTagSubType.name);
        return {
          ...nestedTagSubType,
          isChild: true,
          searchValues: [tagSubType.name, nestedTagSubType.name],
          parentId: tagSubType.id,
          id: `${tagSubType.id}-${nestedTagSubType.id}`,
        };
      });

      return acc.concat([category, ...nestedCategories]);
    }, []);

    return { title: tag.type, items };
  });

  const sortedCategories = sortCategories(categories);

  return sortedCategories;
};

// TODO: Move it to features/accounts/hooks/useCategories.js when it will be created.

/**
 * @typedef UseCategoriesResult
 * @type {Object}
 * @property {Error} error - The error, if any.
 * @property {boolean} loading - Indication that the query is loading.
 * @property {Array} tags - The list of tags (categories) as returned by the query.
 * @property {Array} categoriesForDropdown - The categories (after transformation) for BaselaneDropdown component.
 * @property {Object} categoriesById - The categories by id.
 * @property {Object} categoriesForDropdownById - The categories (after transformation) for BaselaneDropdown component by id. It is useful for finding the selected category (`categoriesForDropdownById[selectedvalue]`).
 */

/**
 * This hook fetches the categories and sub-categories. It returns the tags as is, and also returns the categories and sub-categories by id.
 * @returns {UseCategoriesResult} The result object as described by {@link UseCategoriesResult}.
 */
const useCategories = () => {
  const { loading, error, data } = useQuery(GET_TAGS);

  const tags = data?.tag ?? [];

  const categoriesForDropdown = useMemo(() => buildCategoriesForDropdown(tags), [tags]);

  const categoriesById = useMemo(() => buildCategoriesById(tags), [tags]);

  const categoriesForDropdownById = useMemo(
    () => buildCategoriesById(tags, { buildForDropdown: true }),
    [tags]
  );

  return {
    loading,
    error,
    tags,
    categoriesForDropdown,
    categoriesById,
    categoriesForDropdownById,
  };
};

export default useCategories;
