// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=2925%3A97142
import React, { useRef } from 'react';
import { Box, Text, Stack } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneButton, BaselaneGrid } from '@shared/components';
import {
  emptyStateCardStyles,
  sectionTitleStyles,
  buttonContainerStyles,
  noMarginStyles,
  darkDescriptionTextStyles,
  mobileEmptyStateHeaderStyles,
} from '../../../../../../NativeBankingPage/components/styles/emptyState.shared.styles';
import {
  secondAndThirdColumnStyles,
  secondColumnStyles,
  customButtonBorderStyles,
  mobileEmptyCardStyles,
  mobileEmptyCardContainerStyles,
} from '../styles/emptyState.styles';
import AddAccount from '../../../../../../NativeBankingPage/MainContent/components/Accounts/components/AddAccount';
import AddAccountMobile from '../../../../../../NativeBankingPage/MainContent/components/Accounts/components/AddAccountMobile';
import ConnectBankAccountList from '../../../../../../NativeBankingPage/components/EmptyStateExternalAccountList/ConnectBankAccountList';
import ConnectCreditCardList from '../../../../../../NativeBankingPage/components/EmptyStateExternalAccountList/ConnectCreditCardList';

type EmptyStateProps = {
  banks: Array<Object>,
  refetchBankSummary: Function,
  skipTitle: boolean,
  hasNoAccounts: boolean,
  addExternalAccountAction?: Function,
};

function EmptyState({
  banks,
  refetchBankSummary,
  skipTitle,
  hasNoAccounts,
  addExternalAccountAction,
}: EmptyStateProps): any {
  const { isMinXL } = getBreakPoints();
  const addExternalAccountDrawerRef = useRef(null);
  const gridItems = [
    {
      element: (
        <Stack pr="32px">
          <Text {...sectionTitleStyles} fontSize={{ base: 'sm' }}>
            Connect Your First External Account
          </Text>
        </Stack>
      ),
    },
    {
      element: (
        <Stack {...noMarginStyles} {...secondColumnStyles}>
          <ConnectBankAccountList />
        </Stack>
      ),
    },
    {
      element: (
        <Stack {...noMarginStyles} {...secondAndThirdColumnStyles}>
          <ConnectCreditCardList />
        </Stack>
      ),
    },
    {
      element: (
        <Box {...buttonContainerStyles}>
          {!addExternalAccountAction ? (
            <AddAccount
              {...{ banks, refetchBankSummary, addExternalAccountDrawerRef }}
              buttonStyles={customButtonBorderStyles}
            />
          ) : (
            <BaselaneButton
              onClick={addExternalAccountAction}
              variant="tonal"
              palette="primary"
              size="lg"
            >
              Connect External Account
            </BaselaneButton>
          )}
        </Box>
      ),
    },
  ];

  const emptyStateHeaderStyles = isMinXL
    ? { ...mobileEmptyStateHeaderStyles, mt: '16px !important' }
    : sectionTitleStyles;

  return isMinXL ? (
    <>
      {!skipTitle && <Text {...emptyStateHeaderStyles}>External Accounts</Text>}
      <Box {...mobileEmptyCardContainerStyles(hasNoAccounts)}>
        <Box {...mobileEmptyCardStyles}>
          {!addExternalAccountAction ? (
            <>
              <Stack pr="32px">
                <Text {...sectionTitleStyles} fontSize={{ base: 'sm' }}>
                  External Account
                </Text>
              </Stack>
              <Text {...darkDescriptionTextStyles} {...noMarginStyles}>
                Connect external accounts securely with Plaid.
              </Text>
              <Box {...buttonContainerStyles}>
                <AddAccountMobile
                  addExternalAccountDrawerRef={addExternalAccountDrawerRef}
                  buttonStyles={customButtonBorderStyles}
                />
              </Box>
            </>
          ) : (
            <BaselaneButton
              variant="tonal"
              palette="primary"
              size="lg"
              {...customButtonBorderStyles}
              onClick={addExternalAccountAction}
            >
              Connect External Account
            </BaselaneButton>
          )}
        </Box>
      </Box>
    </>
  ) : (
    <Box mt={{ lg: '32px', base: '16px' }} px="16px">
      {!skipTitle && <Text {...emptyStateHeaderStyles}>External Accounts</Text>}
      <BaselaneGrid
        gridItems={gridItems}
        templateColumns="repeat(1, 30% minmax(0, 1fr) minmax(0, 1fr)) min-content"
        templateRows="repeat(1, 1fr)"
        gridStyles={{ ...emptyStateCardStyles }}
        gridItemStyles={{ h: '100%' }}
      />
    </Box>
  );
}

EmptyState.defaultProps = {
  addExternalAccountAction: null,
};

export default EmptyState;
