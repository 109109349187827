import * as React from 'react';
import { useMutation } from '@apollo/client';
import { IllustrationBankingApplicationcanceled } from '@illustrations';
import customTheme from '@core/theme';
import { DELETE_DRAFT } from '@core/components/NativeBankingPage/queries';
import BaselaneResponsiveBanner from './BaselaneResponsiveBanner';

type BaselaneResponsiveAccountDeniedBannerProps = {
  /**
   * @prop {Object} - Unit application object that contains data used to populate banner
   */
  unitApplication: Object,
  /**
   * @prop {Function} - Function that reloads application banner data after a delete
   */
  refetchBankList: Function,
};

/**
 * Renders a responsive banner for accounts in the 'denied' state.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveAccountDeniedBanner = ({
  unitApplication,
  refetchBankList,
  ...rest
}: BaselaneResponsiveAccountDeniedBannerProps) => {
  const { id: bankId, name: bankName } = unitApplication;

  // TODO: This needs to be replaced with a "Soft Delete" Mutation once available
  // currently, it is not possible to delete a denied application
  const [deleteDraft] = useMutation(DELETE_DRAFT, {
    variables: { id: Number(bankId) },
    onCompleted: () => refetchBankList(),
    onError: (error) => console.error(error),
  });

  return (
    <BaselaneResponsiveBanner
      icon={<IllustrationBankingApplicationcanceled />}
      indicatorColor={customTheme.colors.red['100']}
      title={`Unable to open ${bankName}`}
      description="Based on the information provided, you are unable to open a Baselane Banking account at this time."
      onDismissClick={deleteDraft}
      {...rest}
    />
  );
};

export default BaselaneResponsiveAccountDeniedBanner;
