import React from 'react';
import { Box, Flex, HStack, Text, useMediaQuery } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import { IconArrowRight } from '@icons';
import { infoStyles } from '../styles/leaseResponsive.style';

type PaymentsWarningBannerProps = {
  type: string,
  count: number,
  amount: number,
  title: string,
  icon: any,
  action: Function,
};

const PaymentsWarningBanner = ({
  type,
  count,
  amount,
  title,
  icon,
  action,
}: PaymentsWarningBannerProps) => {
  const [isDesktop] = useMediaQuery('(min-width: 899px)', { ssr: false });
  return (
    <HStack {...infoStyles(isDesktop, type).container} onClick={action}>
      <Box {...infoStyles(isDesktop, type).iconContainer}>{icon}</Box>
      <Flex direction={isDesktop ? 'row' : 'column'}>
        <Box>
          <Text {...infoStyles(isDesktop, type).status}>
            {`${count} ${title}${count === 1 ? '' : 's'}`}
          </Text>
        </Box>
        <HStack ml={isDesktop ? '24px' : '0px'}>
          <Text {...infoStyles(isDesktop, type).amountAction}>
            {formatCurrency(amount).inDollars}
          </Text>
          <IconArrowRight color="#257ED0" w={13.33} h={6.67} />
        </HStack>
      </Flex>
    </HStack>
  );
};

export default PaymentsWarningBanner;
