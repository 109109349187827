import React from 'react';
import { IllustrationRentcollection } from '@illustrations';

export const getTrackerData = (userKycStatus) => {
  const trackerData = Object.freeze({
    title: 'Rent Collection',
    icon: <IllustrationRentcollection />,
    elements: {
      ADD_PROPERTY: {
        title: 'Add a property',
        not_started: {
          title: 'Add a property',
          description: 'Let’s get started by adding your first rental property.',
          linkText: 'Add Property',
          hasNotStarted: true,
        },
        in_progress: {
          title: 'Add a property',
          description: 'Let’s get started by adding your first rental property.',
          linkText: 'Add Property',
        },
        completed: {
          title: 'Add a property',
        },
      },
      SETUP_RENT_COLLECTION: {
        title: 'Set up rent collection terms',
        not_started: {
          title: 'Set up rent collection terms',
          description:
            'Provide rent & fee amounts, the timing of payments, and tenant information.',
          linkText: 'Set Up Rent Collection',
          hasNotStarted: true,
        },
        in_progress: {
          title: 'Set up rent collection terms',
          description:
            'Provide rent & fee amounts, the timing of payments, and tenant information.',
          linkText: 'Continue Set Up',
        },
        completed: {
          title: 'Set up rent collection terms',
        },
      },
      SETUP_TENANT: {
        title: 'Get your tenant set up',
        not_started: {
          title: 'Get your tenant set up',
          description:
            'Your tenant has not yet accepted their invitation. It takes them less ~2 minutes to set up their account.',
          hasNotStarted: true,
        },
        in_progress: {
          title: 'Get your tenant set up',
          description:
            'Your tenant has created their account! They have yet to add a payment method.',
        },
        completed: {
          title: 'Get your tenant set up',
        },
      },
      RECEIVE_FIRST_PAYMENT: {
        title: 'Receive your first rent payment',
        not_started: {
          title: 'Receive your first rent payment',
          description:
            userKycStatus !== 'APPROVED'
              ? 'Almost there! As a final step, we need to verify your identity before initiating any rent payments.'
              : 'Identity verified! Rent payments are deposited directly into your bank account within 2-5 days.',
          hasNotStarted: true,
          linkText: userKycStatus !== 'APPROVED' ? 'Verify Identity' : null,
        },
        in_progress: {
          title: 'Receive your first rent payment',
          description:
            userKycStatus !== 'APPROVED'
              ? 'We are verifying your identity. We will email you when verified or if we encounter any issues.'
              : 'Your payment is processing, it will be deposited directly into your bank account within 2-5 days.',
          linkText: null,
        },
        warning: {
          title: 'Receive your first rent payment',
          description:
            'Almost there! As a final step, we require documentation to verify your identity.',
          linkText: 'Upload Documentation',
          state: 'warning',
        },
        alert: {
          title: 'Receive your first rent payment',
          description:
            'Unfortunately, we were unable to verify your identity based on the information you provided.',
          linkText: 'Contact Support',
          state: 'alert',
        },
        completed: {
          title: 'Receive your first rent payment',
        },
      },
    },
    badge: null,
  });

  return trackerData;
};

export const getLinkStatus = (data) => {
  const { ADD_PROPERTY, SETUP_RENT_COLLECTION, SETUP_TENANT, RECEIVE_FIRST_PAYMENT } = data;

  const {
    not_started: propertyNotStartedLink,
    in_progress: propertyInProgLink,
    completed: propertyCompletedLink,
  } = ADD_PROPERTY;
  const {
    not_started: rcNotStartedLink,
    in_progress: rcInProgLink,
    completed: rcCompletedLink,
  } = SETUP_RENT_COLLECTION;
  const {
    not_started: tenantNotStartedLink,
    in_progress: tenantInProgLink,
    completed: tenantCompletedLink,
  } = SETUP_TENANT;
  const {
    not_started: psNotStartedLink,
    in_progress: psInProgLink,
    warning: psAwaitingDocLink,
    alert: psDeniedLink,
    completed: psCompletedLink,
  } = RECEIVE_FIRST_PAYMENT;

  const linkStatus = Object.freeze({
    ADD_PROPERTY: {
      not_started: propertyNotStartedLink,
      in_progress: propertyInProgLink,
      completed: propertyCompletedLink,
    },
    SETUP_RENT_COLLECTION: {
      not_started: rcNotStartedLink,
      in_progress: rcInProgLink,
      completed: rcCompletedLink,
    },
    SETUP_TENANT: {
      not_started: tenantNotStartedLink,
      in_progress: tenantInProgLink,
      completed: tenantCompletedLink,
    },
    RECEIVE_FIRST_PAYMENT: {
      not_started: psNotStartedLink,
      in_progress: psInProgLink,
      warning: psAwaitingDocLink,
      alert: psDeniedLink,
      completed: psCompletedLink,
    },
  });

  return linkStatus;
};
