import React from 'react';
import { Text, Stack } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

import { Icon24Property } from '@icons/24px';
import AddEditProperty from '@pages/PropertiesPage/AddEditProperty';
import MobileAddEditProperty from '@pages/PropertiesResponsivePage/AddEditProperty';

import { emptyStateStyles } from './styles/obieModal.styles';

function EmptyState() {
  // styles
  const { container, title, description } = emptyStateStyles ?? {};

  return (
    <Stack {...container}>
      <Icon24Property color="#6C7884" />
      <Text {...title}>No properties added</Text>
      <Text {...description}>To start a quote, add your property and come back to this page</Text>
      {isMobile ? <MobileAddEditProperty /> : <AddEditProperty />}
    </Stack>
  );
}

export default EmptyState;
