import React from 'react';
import { AlertDialogBody, AlertDialogHeader, Stack, Text } from '@chakra-ui/react';
import { BaselaneCardStack } from '@shared/components';
import IconWarningTriangleYellow from '@icons/legacy/IconWarningTriangleYellow';
import { getRentCollectionRemainingTimeFor } from '@pages/LeasesPage/helpers/unit.helpers';

const DeleteTenantAlertBody = ({ leases }: { leases: Array }) => {
  const leaseItems = leases.map((lease) => {
    const hasPropertyUnitName =
      lease.propertyUnitName && lease.propertyName !== lease.propertyUnitName;
    const propertyName = `${lease.propertyName}${
      hasPropertyUnitName ? `, ${lease.propertyUnitName}` : ''
    }`;

    return {
      id: lease.id,
      content: (
        <Stack>
          <Text textStyle="headline-sm">{propertyName}</Text>
          <Text textStyle="xs" color="color.brand.neutral.700">
            {lease.leaseType === 'MONTHLY'
              ? 'Month-to-month'
              : getRentCollectionRemainingTimeFor(lease)}
          </Text>
        </Stack>
      ),
    };
  });

  return !leaseItems.length ? (
    <Stack spacing={0.5}>
      <AlertDialogHeader p={0}>
        <Text textStyle="headline-lg">Delete tenant?</Text>
      </AlertDialogHeader>
      <AlertDialogBody p={0}>
        <Text textStyle="sm">
          You can re-invite them if you need to set up rent collection with them in the future.
        </Text>
      </AlertDialogBody>
    </Stack>
  ) : (
    <>
      <IconWarningTriangleYellow />
      <Stack spacing={2}>
        <AlertDialogHeader p={0}>
          <Text textStyle="headline-lg">Rent collection active for tenant</Text>
        </AlertDialogHeader>
        <AlertDialogBody p={0}>
          <Stack spacing={2}>
            <BaselaneCardStack id="active-rent-collections" items={leaseItems} />
            <Text textStyle="sm">
              If you delete the tenant, their rent collection will be ended and archived. Would you
              like to proceed?
            </Text>
          </Stack>
        </AlertDialogBody>
      </Stack>
    </>
  );
};

export default DeleteTenantAlertBody;
