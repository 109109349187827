import { formatDate } from '@core/utils/formatDate';
import formatCurrency from '@core/utils/formatCurrency';
import { getDepositAndFeesData } from './depositAndFeesForm.helper';

export const getDepositAndFeesDataFormatted = (lease) => {
  const { deposit, proratedRent, lastMonthRent, fees } = getDepositAndFeesData(lease);

  const data = {
    dEnabled: false,
    dAmount: null,
    dDueDate: null,
    dStatus: null,
    lEnabled: false,
    lDueDate: null,
    lStatus: null,
    pEnabled: false,
    pAmount: null,
    pDueDate: null,
    fees: [],
  };

  if (deposit.enabled) {
    const { enabled, amount, dueDate, paid } = deposit;
    data.dEnabled = enabled;
    data.dAmount = amount && formatCurrency(amount).inDollars;
    data.dDueDate = dueDate && formatDate(dueDate);
    data.dStatus = paid ? 'Yes' : 'No';
  }

  if (proratedRent.enabled) {
    const { enabled, amount, dueDate } = proratedRent;
    data.pEnabled = enabled;
    data.pAmount = amount && formatCurrency(amount).inDollars;
    data.pDueDate = dueDate && formatDate(dueDate);
  }

  if (lastMonthRent.enabled) {
    const { enabled, dueDate, paid } = lastMonthRent;
    data.lEnabled = enabled;
    data.lDueDate = dueDate && formatDate(dueDate);
    data.lStatus = paid ? 'Yes' : 'No';
  }

  if (fees.length) {
    fees.forEach((fee) => {
      const { enabled, description, amount, dueDate, frequency, id } = fee;
      if (enabled) {
        const feeObject = {
          description,
          amount: amount && formatCurrency(amount).inDollars,
          dueDate: dueDate ? formatDate(dueDate) : 'Monthly',
          frequency: frequency === 'FIXED' ? 'One time' : 'Recurring monthly',
        };
        if (id) {
          feeObject.id = id;
        }
        data.fees.push(feeObject);
      }
    });
  }

  return data;
};

export const statusFormat = (status) => {
  return status === 'Yes' ? 'Completed' : 'Incomplete';
};
