export const headerItems = [
  { key: 'card', label: 'Card' },
  {
    key: 'linkedAccount',
    label: 'Linked Account',
  },
  {
    key: 'spend-limit',
    label: 'Spend Limit',
  },
  {
    key: 'spend-this-month',
    label: 'Spent This Month',
  },
  {
    key: 'status',
    label: 'Status',
  },
];

export const cardTableConfig = {
  columns: [
    {
      styles: {
        width: { '2xl': 'auto', '2lg': '330px', md: 'auto' },
        maxWidth: { '2xl': 'none', '2lg': '330px', md: 'none' },
      },
    },
    {
      styles: {
        display: { '2lg': 'table-cell', base: 'none' },
      },
    },
    {
      styles: {
        display: { md: 'table-cell', base: 'none' },
      },
    },
    {
      styles: {
        display: { '2lg': 'table-cell', base: 'none' },
      },
    },
    {
      styles: {
        width: { '2xl': 'auto', base: '160px' },
        display: { sm: 'table-cell', base: 'none' },
      },
    },
  ],
};
