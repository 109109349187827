import { truncatedText } from '@core/components/Shared/styles/text.style';

export const drawerStyles = (isLeaseLevel, isMinXL) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'brand.darkBlue.800A',
      color: 'brand.neutral.white',
      minHeight: '80px',
      padding: '0 32px',
      button: {
        color: 'brand.neutral.white',
        marginTop: '15px',
      },
    },
    body: {
      container: {
        color: 'brand.neutral.700',
        p: '0',
      },
      bottomcontainer: {
        px: isMinXL || !isLeaseLevel ? '0' : '2',
        py: isMinXL || !isLeaseLevel ? '0' : '4',
        h: isLeaseLevel ? 'calc(100% - 90px)' : '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      },
      emptybody: {
        container: {
          m: isMinXL ? '88px auto' : '70px auto',
          gap: '24px',
          textAlign: 'center',
          maxW: isMinXL && '263px',
        },
        title: {
          textStyle: 'headline-md',
          m: '0 !important',
        },
        description: {
          textStyle: 'sm',
          color: '#000',
          m: '0 !important',
        },
      },
    },
  };
};

export const invoiceStyles = ({ isMax767 }) => {
  return {
    headerCard: {
      padding: '25px 16px 0',
      bg: 'brand.neutral.white',
      direction: 'column',
      rows: {
        justifyContent: 'space-between',
      },
      super: {
        textStyle: 'xs',
        color: 'brand.neutral.600',
        lineHeight: '20px',
        mb: '4px',
      },
      title: {
        textStyle: 'headline-md',
        color: 'brand.neutral.700',
      },
      titleMini: {
        textStyle: 'headline-xs',
        color: 'brand.neutral.700',
        mt: '3.5px',
      },
    },
    header: {
      px: '0',
      pt: isMax767 ? '16px' : '0',
      pb: isMax767 ? 'px' : '16px',
      main: {
        textStyle: 'sm',
        color: 'brand.neutral.600',
      },
      edit: {
        textStyle: 'headline-lg',
        color: 'brand.neutral.700',
        m: '0 !important',
      },
      invoice: {
        textStyle: 'sm',
        color: 'brand.neutral.700',
        marginTop: '12px',
        m: '0 !important',
      },
      unit: {
        paddingBottom: isMax767 ? '12px' : '0px',
      },
      address: {
        textStyle: 'headline-md',
        color: 'brand.neutral.900',
        my: '4px !important',
      },
      propertydeleted: {
        textStyle: 'sm',
        m: '0 !important',
      },
    },
  };
};

export const invoiceTotalStyles = {
  container: {
    pt: '24px',
    pb: '8px',
  },
  label: {
    textStyle: 'xs',
    color: 'brand.neutral.900',
  },
  value: {
    color: 'brand.neutral.900',
    textStyle: 'headline-2xl',
    fontWeight: '500',
    amount: {
      afterdecimal: {
        textStyle: 'md',
        fontWeight: '500',
      },
    },
    invoices: {
      textAlign: 'right',
    },
  },
};

export const invoiceFilterBarStyles = ({ isMinXL }) => {
  return {
    mobilecontainer: {
      pb: '16px',
      bg: 'brand.neutral.white',
    },
    container: {
      borderRadius: '6px',
      bg: 'brand.darkBlue.100',
      p: isMinXL ? '4px 8px' : '4px 4px 4px 16px',
      m: '0 !important',
    },
    filter: {
      container: {
        flexWrap: 'wrap',
        gap: '4px',
        columnGap: '16px',
        overflow: 'hidden',
      },
      datetypefilter: ({ filterType }) => {
        const isDateType = filterType === 'dateType';

        return {
          flex: isMinXL && isDateType && 1,
          maxHeight: isMinXL && isDateType && '20px',
          minWidth: isMinXL && isDateType && '32px',
          ...{ ...(isMinXL && isDateType && { ...truncatedText }) },
        };
      },
      icon: {
        w: '16px',
        h: '16px',
      },
      text: ({ filterType }) => {
        const isDateType = filterType === 'dateType';

        return {
          ml: '5px !important',
          textStyle: 'xs',
          color: 'brand.neutral.700',
          ...{ ...(isMinXL && isDateType && { ...truncatedText }) },
        };
      },
    },
    button: {
      w: '40px !important',
      minWidth: '40px !important',
      ml: '16px !important',
    },
  };
};

export const invoiceTableStyles = (isLeaseLevel = false, isDeleted = false) => ({
  container: {
    borderRadius: '6px',
    border: '1px solid',
    borderColor: 'brand.darkBlue.200',
    overflow: 'auto',
  },
  header: {
    container: {
      w: '100%',
      gridTemplateColumns: isLeaseLevel
        ? '156px 72px 104px 16px 156px 1fr'
        : '100px 100px 72px 104px 128px 1fr',
      textStyle: 'xs',
      borderBottom: '1px solid',
      borderColor: 'brand.darkBlue.100',
    },
    item: (key) => {
      let px = '12px';
      if (key === 'dueDate') px = '8px';
      if (key === 'spacer') px = '0';

      return {
        display: 'flex',
        justifyContent: key === 'amount' ? 'flex-end' : 'flex-start',
        alignItems: 'center',
        h: '40px',
        py: '12px',
        px,
        textStyle: 'xs',
        color: 'brand.neutral.600',
        fontWeight: '500',
        whiteSpace: 'nowrap',
      };
    },
  },
  row: {
    display: 'grid',
    alignItems: 'center',
    w: '100%',
    gridTemplateColumns: isLeaseLevel
      ? '156px 72px 104px 16px 156px 1fr'
      : '100px 100px 72px 104px 128px 1fr',
    textStyle: 'sm',
    color: isDeleted ? 'brand.neutral.500' : 'brand.neutral.700',
    borderBottom: '1px solid',
    borderColor: 'brand.darkBlue.100',
    cursor: 'pointer',
    sx: {
      '.icon-chevron-right': {
        color: isDeleted ? 'brand.neutral.500' : 'brand.neutral.600',
        display: 'inline-block',
      },
      _hover: {
        bg: 'brand.blue.50',
        borderColor: 'brand.blue.300',
      },
      _focus: {
        borderColor: 'brand.blue.800A',
      },
      _active: {
        bg: 'brand.blue.50',
        borderColor: 'brand.blue.800A',
      },
    },
    _last: {
      border: 'none',
    },
    item: (key) => {
      let px = '12px';
      if (key === 'dueDate') px = '8px';
      if (key === 'spacer') px = '0';

      let textAlign = 'left';
      if (key === 'amount') textAlign = 'right';
      if (key === 'details') textAlign = 'center';

      return {
        px,
        textAlign,
        py: '16px',
      };
    },
    subitem: {
      textStyle: 'xs',
      color: isDeleted ? 'brand.darkBlue.300' : 'brand.neutral.600',
    },
    latefee: {
      textStyle: 'xs',
      color: 'red.800AA',
    },
    truncated: { ...truncatedText },
    property: (isMultiUnit = false) => {
      return isMultiUnit
        ? {
            ...truncatedText,
          }
        : {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            sx: {
              '-webkit-box-orient': 'vertical',
              '-webkit-line-clamp': '2',
            },
          };
    },
  },
});

export const mobileInvoiceStyles = (isDeleted) => {
  return {
    row: {
      width: '100%',
      background: 'brand.neutral.white',
      borderBottomWidth: '1px',
      borderColor: 'brand.darkBlue.100',
      color: isDeleted ? 'brand.neutral.500' : 'brand.neutral.600',
      textStyle: 'sm',
      _last: {
        border: 'none',
      },
      _hover: {
        bg: 'brand.blue.50',
        borderColor: 'brand.blue.300',
      },
      _focus: {
        borderColor: 'brand.blue.800A',
      },
      _active: {
        bg: 'brand.blue.50',
        borderColor: 'brand.blue.800A',
      },
    },
    col: {
      flex: '1 1',
      p: '16px 12px',
      _first: {
        textAlign: 'left',
      },
      _last: {
        textAlign: 'right',
        flex: '0 1 128px',
      },
    },
    container: {
      justifyContent: 'space-between',
    },
    type: (isLeaseLevel) => {
      let color = 'brand.neutral.600';
      if (isDeleted) color = 'brand.neutral.500';
      else if (isLeaseLevel) color = 'brand.neutral.700';

      return {
        color,
        textStyle: isLeaseLevel ? 'sm' : 'xs',
      };
    },
    property: {
      container: {
        ...truncatedText,
      },
      propertytext: {
        ...truncatedText,
        color: 'brand.neutral.700',
      },
      unittext: {
        ...truncatedText,
        m: '0 !important',
      },
    },
    due: {
      textStyle: 'xs',
      color: isDeleted ? 'brand.neutral.500' : 'brand.neutral.600',
    },
    amount: {
      textStyle: 'xs',
    },
    paidlate: {
      textStyle: 'xs',
      color: 'red.800AA',
      ml: '4px',
    },
    paymentStatus: (color) => ({
      gap: 0.75,
      mt: 0.5,
      justifyContent: 'flex-end',
      color: isDeleted ? 'brand.neutral.500' : color,
    }),
  };
};

export const invoicePaymentDateStyles = (isDeleted) => {
  return {
    color: isDeleted ? 'brand.darkBlue.300' : 'brand.neutral.600',
    textStyle: 'xs',
  };
};

export const paymentTypeInvoiceStyles = (isDeleted) => {
  return {
    textStyle: 'xs',
    color: isDeleted ? 'brand.darkBlue.300' : 'brand.neutral.600',
  };
};

export const statusLabelStyles = (color, isDeleted) => {
  return {
    textStyle: 'sm',
    color: isDeleted ? 'brand.darkBlue.300' : color,
  };
};
