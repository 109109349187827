import React from 'react';
import { Box, Heading, HStack, Stack, VStack } from '@chakra-ui/react';
import BaselaneButtonCard from '../BaselaneButtonCard';

import {
  buttonCardStyles,
  containerStyles,
  headerRowStyles,
  primaryContentHeadingStyles,
  contentRowStyles,
  supportingContentTextStyles,
  buttonAndProviderRowStyles,
} from './styles/advertisingBanner.style';

type BaselaneAdvertisingBannerProps = {
  /* an SVG icon object to identify the ad. */
  icon: Function,
  /* the main text of the ad, either as a string or custom styled component. */
  primaryContent: String | Function,
  /* the secondary text of the ad, either as a string or custom styled component. */
  supportingContent: String | Function,
  /* top left content of the banner */
  topLeftContent: any,
  /* top right content of the banner */
  topRightContent: any,
  /* bottom right content of the banner */
  bottomRightContent: any,
  /* the click handler for the ad. */
  onClick?: Function,
  /* An optional fag that disables click function of the banner  */
  isBannerClickable?: Boolean,
  /* An optional flag that disables responsive layout change, when only a width narrower
     than breakpoint.md is expected */
  isNarrowOnly?: Boolean,
  /* An optional flag to apply styles */
  styles?: Object,
};

const BaselaneAdvertisingBanner = ({
  icon,
  primaryContent,
  supportingContent,
  topLeftContent,
  topRightContent,
  bottomRightContent,
  onClick,
  isBannerClickable,
  isNarrowOnly,
  styles,
  ...rest
}: BaselaneAdvertisingBannerProps) => {
  const Icon = () => icon;

  let PrimaryContent = null;
  if (typeof primaryContent === 'function') {
    PrimaryContent = () => primaryContent();
  }

  let SupportingContent = null;
  if (typeof supportingContent === 'function') {
    SupportingContent = () => supportingContent();
  }

  return (
    <BaselaneButtonCard
      {...{
        type: 'no-border',
        onClick,
        buttonStyles: {
          ...buttonCardStyles(isBannerClickable),
          ...styles?.buttoncard,
          ...rest,
        },
      }}
    >
      <VStack {...{ ...containerStyles, ...styles?.container }}>
        {/* Header Row */}
        <HStack {...{ ...headerRowStyles, ...styles?.headerrow }}>
          {/* Icon OR Top Left Content */}
          {topLeftContent || (
            <Box>
              <Icon />
            </Box>
          )}
          {/* Top Right Content */}
          {topRightContent}
        </HStack>
        {/* Primary Content */}
        {PrimaryContent ? (
          <PrimaryContent />
        ) : (
          <Heading {...{ ...primaryContentHeadingStyles(isNarrowOnly), ...styles?.primarycontent }}>
            {primaryContent}
          </Heading>
        )}
        {/* Supporting Content */}
        <Stack
          direction={!isNarrowOnly ? 'row' : 'column'}
          {...{ ...contentRowStyles, ...styles?.contentrows }}
        >
          {SupportingContent ? (
            <SupportingContent />
          ) : (
            <Box
              {...{ ...supportingContentTextStyles(isNarrowOnly), ...styles?.supportingcontent }}
            >
              {supportingContent}
            </Box>
          )}
          {/* Bottom Right Content */}
          <HStack
            {...{ ...buttonAndProviderRowStyles(isNarrowOnly), ...styles?.bottomrightcontent }}
          >
            {bottomRightContent}
          </HStack>
        </Stack>
      </VStack>
    </BaselaneButtonCard>
  );
};

BaselaneAdvertisingBanner.defaultProps = {
  isNarrowOnly: false,
  onClick: () => {},
  isBannerClickable: false,
  styles: {},
};

export default BaselaneAdvertisingBanner;
