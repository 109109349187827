import { create } from 'zustand';

import { initialAddressValues } from '@pages/BankEntity/OrderCheckbook/helpers/form.helper';

const useCheckbookStore = create((set, get) => ({
  // states
  step: 1,
  firstStep: 1,
  lastStep: 4,
  checkbookFee: 15,
  selectedAccount: null,
  selectedFeeAccount: null,
  selectedMailing: 'default',
  otherAddress: initialAddressValues,
  formSuccess: false,
  // setters
  setStep: (increase) =>
    set((state) => {
      if (
        (state.step <= state.firstStep && increase < 0) ||
        (state.step >= state.lastStep && increase > 0)
      )
        return { step: state.step };
      return { step: state.step + increase };
    }),
  setSelectedAccount: (account) => set(() => ({ selectedAccount: account })),
  setSelectedFeeAccount: (account) => set(() => ({ selectedFeeAccount: account })),
  setSelectedMailing: (option) => set(() => ({ selectedMailing: option })),
  setOtherAddress: (newAddress) =>
    set(() => {
      const previousAddress = get().otherAddress;
      return { otherAddress: { ...previousAddress, ...newAddress } };
    }),
  setFormSuccess: (bool) => set(() => ({ formSuccess: bool })),
  reset: () =>
    set(() => ({
      step: 1,
      selectedAccount: null,
      selectedFeeAccount: null,
      selectedMailing: 'default',
      otherAddress: initialAddressValues,
      formSuccess: false,
    })),
}));

export default useCheckbookStore;
