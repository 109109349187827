import React from 'react';

type IconTimerProps = {
  w?: Number,
  h?: Number,
  color?: string,
  viewBox?: string,
};

function IconTimer({
  w = 14,
  h = 14,
  color = 'currentColor',
  viewBox = '0 0 14 14',
}: IconTimerProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99967 1.66634C5.58519 1.66634 4.22863 2.22824 3.22844 3.22844C2.22824 4.22863 1.66634 5.58519 1.66634 6.99967C1.66634 7.70006 1.80429 8.39358 2.07232 9.04065C2.34034 9.68772 2.73319 10.2757 3.22844 10.7709C3.72368 11.2662 4.31163 11.659 4.9587 11.927C5.60577 12.1951 6.29929 12.333 6.99967 12.333C7.70006 12.333 8.39358 12.1951 9.04065 11.927C9.68772 11.659 10.2757 11.2662 10.7709 10.7709C11.2662 10.2757 11.659 9.68772 11.927 9.04065C12.1951 8.39358 12.333 7.70006 12.333 6.99967C12.333 5.58519 11.7711 4.22863 10.7709 3.22844C9.77072 2.22824 8.41416 1.66634 6.99967 1.66634ZM2.28563 2.28563C3.53587 1.03539 5.23156 0.333008 6.99967 0.333008C8.76779 0.333008 10.4635 1.03539 11.7137 2.28563C12.964 3.53587 13.6663 5.23156 13.6663 6.99967C13.6663 7.87515 13.4939 8.74206 13.1589 9.5509C12.8238 10.3597 12.3328 11.0947 11.7137 11.7137C11.0947 12.3328 10.3597 12.8238 9.5509 13.1589C8.74206 13.4939 7.87515 13.6663 6.99967 13.6663C6.1242 13.6663 5.25729 13.4939 4.44845 13.1589C3.63961 12.8238 2.90469 12.3328 2.28563 11.7137C1.66657 11.0947 1.17551 10.3597 0.840478 9.5509C0.505446 8.74206 0.333008 7.87515 0.333008 6.99967C0.333008 5.23156 1.03539 3.53587 2.28563 2.28563ZM6.99967 3.66634C7.36786 3.66634 7.66634 3.96482 7.66634 4.33301V6.72353L9.47108 8.52827C9.73143 8.78862 9.73143 9.21073 9.47108 9.47108C9.21073 9.73143 8.78862 9.73143 8.52827 9.47108L6.52827 7.47108C6.40325 7.34606 6.33301 7.17649 6.33301 6.99967V4.33301C6.33301 3.96482 6.63148 3.66634 6.99967 3.66634Z"
        fill={color}
      />
    </svg>
  );
}

IconTimer.defaultProps = {
  w: 14,
  h: 14,
  color: 'currentColor',
  viewBox: '0 0 14 14',
};

export default IconTimer;
