import { DateTime } from 'luxon';

const buildGenerateRecurringPatternInput = (values) => {
  const params = {
    amount: values?.amount ? parseFloat(values.amount.replace(/,/g, '')) : 0, // Einai string TODO: Fix
    startDate: DateTime.fromJSDate(values?.recurringTransfer?.startDate).toISODate(),
    recurrencePattern: {
      type: values?.recurringTransfer?.repeatEvery?.unit === 'month' ? 'MONTHLY' : 'WEEKLY',
      interval: values?.recurringTransfer?.repeatEvery?.value,
    },
  };

  // If the user selects an end date, we need to set the 'endDate' value.
  if (values?.recurringTransfer?.endBy === 'date') {
    params.endDate = DateTime.fromJSDate(values?.recurringTransfer?.endDate).toISODate();
  }

  // Otherwise, if the user selects a number of transfers, we need to set the 'numberOfPayments' value.
  if (values?.recurringTransfer?.endBy === 'number-of-transfers') {
    params.recurrencePattern.numberOfPayments = values?.recurringTransfer?.numberOfTransfers;
  }

  // If the user selects the last day of the month, we need to set the 'lastDayOfMonth' flag.
  if (
    values?.recurringTransfer?.repeatEvery?.unit === 'month' &&
    values?.recurringTransfer?.repeatOnMonthDate === 'last'
  ) {
    params.recurrencePattern.lastDayOfMonth = true;
  }

  // If the user selects a specific day of the month, we need to set the 'monthlyByDate' value.
  if (
    values?.recurringTransfer?.repeatEvery?.unit === 'month' &&
    values?.recurringTransfer?.repeatOnMonthDate !== 'last'
  ) {
    // Converting the string to a number. The form value is e.g. '1', '2', '3', ..., '28'.
    // NOTE: Form uses string values for the dropdown, because the last value is 'last' (a string).
    params.recurrencePattern.monthlyByDate = Number(values?.recurringTransfer?.repeatOnMonthDate);
  }

  if (values?.recurringTransfer?.repeatEvery?.unit === 'week') {
    params.recurrencePattern.daysOfWeek = values?.recurringTransfer?.repeatOnWeekday.toUpperCase();
  }

  return params;
};

export default buildGenerateRecurringPatternInput;
