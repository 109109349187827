import React from 'react';

type IconCircleDottedArrowProps = {
  w: Number,
  h: Number,
  color: String,
};

function IconCircleDottedArrow({ w = 24, h = 24, color = '#3A4B5B' }: IconCircleDottedArrowProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7937 3.12063C10.7937 2.50172 11.2954 2 11.9143 2C17.4845 2 22 6.51551 22 12.0857C22 13.0977 21.8506 14.0768 21.5719 15.0011C21.5568 15.0513 21.5385 15.0996 21.5172 15.1459C20.7827 17.4552 19.2181 19.5105 16.9534 20.8187C12.7206 23.264 7.45219 22.288 4.34615 18.7488L4.20339 19.2818C4.04327 19.8797 3.42882 20.2345 2.83099 20.0744C2.23315 19.9143 1.87831 19.2998 2.03843 18.702L2.81005 15.821C2.94975 15.2994 3.4416 14.9516 3.97996 14.9937L6.95894 15.2269C7.57596 15.2751 8.03701 15.8145 7.98872 16.4315C7.94043 17.0485 7.40109 17.5096 6.78407 17.4613L6.159 17.4124C8.58939 20.0467 12.5995 20.7456 15.8323 18.878C18.0878 17.575 19.4521 15.3204 19.7086 12.9088C19.7129 12.8686 19.7193 12.8291 19.7276 12.7904C19.7482 12.5584 19.7587 12.3234 19.7587 12.0857C19.7587 7.75332 16.2467 4.24126 11.9143 4.24126C11.2954 4.24126 10.7937 3.73954 10.7937 3.12063Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9649 4.23937L11.9165 4.24238C11.2989 4.28271 10.7655 3.81475 10.7252 3.19716C10.6849 2.57957 11.1529 2.04621 11.7704 2.00588L11.8324 2.00203C12.4502 1.96543 12.9807 2.4366 13.0173 3.05443C13.0539 3.67225 12.5827 4.20277 11.9649 4.23937Z"
        fill={color}
      />
      <path
        d="M8.79006 3.58253C9.13524 4.1804 8.9304 4.94489 8.33253 5.29006C7.73467 5.63524 6.97018 5.4304 6.625 4.83253C6.27982 4.23467 6.48467 3.47018 7.08253 3.125C7.6804 2.77982 8.44489 2.98467 8.79006 3.58253Z"
        fill={color}
      />
      <path
        d="M5.59011 6.83253C5.93529 7.4304 5.73045 8.19489 5.13258 8.54006C4.53471 8.88524 3.77023 8.6804 3.42505 8.08253C3.07987 7.48467 3.28471 6.72018 3.88258 6.375C4.48045 6.02982 5.24493 6.23467 5.59011 6.83253Z"
        fill={color}
      />
      <path
        d="M4.54006 11.3325C4.88524 11.9304 4.6804 12.6949 4.08253 13.0401C3.48467 13.3852 2.72018 13.1804 2.375 12.5825C2.02982 11.9847 2.23467 11.2202 2.83253 10.875C3.4304 10.5298 4.19489 10.7347 4.54006 11.3325Z"
        fill={color}
      />
    </svg>
  );
}

export default IconCircleDottedArrow;
