import React, { useEffect, useContext, useState, useMemo } from 'react';
import LogRocket from 'logrocket';
import { useQuery } from '@apollo/client';
import { useGate } from 'statsig-react';
import { identifyUser } from '@features/Authentication/helpers/tracking.helpers';
import { auth, getCustomClaims } from '@core/Services/Firebase/firebase';
import userStorage from '@core/storage/userStorage';
import { GET_USER, GET_USER_SAVINGS } from '@core/apollo/queries';
import { manageStorageAfterLogout } from '@core/storage/helpers/cleanup.helpers';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';

const UserContext = React.createContext({});

export const UserProvider = ({ children }: any) => {
  // Use gate for savings_account
  const { value: isSavingsGateEnabled } = useGate('savings_account_gate');

  const {
    loading,
    data,
    refetch: refetchUser,
    error,
    startPolling: startUserPolling,
    stopPolling: stopUserPolling,
  } = useQuery(isSavingsGateEnabled ? GET_USER_SAVINGS : GET_USER);

  const [propertySurveyPanelIndex, setPropertySurveyPanelIndex] = useState(0);

  const [triageCardsContinued, setTriageCardsContinued] = useState(false);
  const initialValueSkippedBankingFirst = localStorage.getItem('skippedBankingFirst');
  const [skippedBankingFirst, setSkippedBankingFirst] = useState(
    initialValueSkippedBankingFirst ?? false
  );

  const [emailVerified, setEmailVerified] = useState('');
  const [userPhotoUrl, setUserPhotoUrl] = useState('');
  const [onboardingBaselaneAccount, setOnboardingBaselaneAccount] = useState(false);
  const [onboardingConnectAccount, setOnboardingConnectAccount] = useState(false);
  const [kycShowPopup, setKycShowPopup] = useState(false);

  // if check deposit is open, don't reload component
  const [isDesktopCheckDepositOpen, setIsDesktopCheckDepositOpen] = useState(false);
  const [isTabletCheckDepositOpen, setIsTabletCheckDepositOpen] = useState(false);
  const [isMobileCheckDepositOpen, setIsMobileCheckDepositOpen] = useState(false);

  // mobile popup category
  const [showMobileDropdownPopup, setShowMobileDropdownPopup] = useState(false);
  const [showMobileDropdownPopupAnimation, setShowMobileDropdownPopupAnimation] = useState({
    slidein: false,
    slideout: false,
  });

  const state = useMemo(() => {
    const newState = {};
    newState.loading = loading;
    if (data) {
      newState.user = data.user;
      newState.refetchUser = refetchUser;
      newState.utm = data?.user?.utm;
      newState.startUserPolling = startUserPolling;
      newState.stopUserPolling = stopUserPolling;

      // For onboarding triage
      newState.onboardingBaselaneAccount = onboardingBaselaneAccount;
      newState.setOnboardingBaselaneAccount = setOnboardingBaselaneAccount;
      newState.onboardingConnectAccount = onboardingConnectAccount;
      newState.setOnboardingConnectAccount = setOnboardingConnectAccount;

      // For onboarding survey
      newState.propertySurveyPanelIndex = propertySurveyPanelIndex;
      newState.setPropertySurveyPanelIndex = setPropertySurveyPanelIndex;

      // For determining triage content step
      newState.triageCardsContinued = triageCardsContinued;
      newState.setTriageCardsContinued = setTriageCardsContinued;
      newState.skippedBankingFirst = skippedBankingFirst;
      newState.setSkippedBankingFirst = setSkippedBankingFirst;

      // For kyc popup
      newState.kycShowPopup = kycShowPopup;
      newState.setKycShowPopup = setKycShowPopup;

      // For stable check deposit
      newState.isDesktopCheckDepositOpen = isDesktopCheckDepositOpen;
      newState.setIsDesktopCheckDepositOpen = setIsDesktopCheckDepositOpen;
      newState.isTabletCheckDepositOpen = isTabletCheckDepositOpen;
      newState.setIsTabletCheckDepositOpen = setIsTabletCheckDepositOpen;
      newState.isMobileCheckDepositOpen = isMobileCheckDepositOpen;
      newState.setIsMobileCheckDepositOpen = setIsMobileCheckDepositOpen;
    }
    if (emailVerified) {
      newState.emailVerified = emailVerified;
    }
    if (userPhotoUrl) {
      newState.userPhotoUrl = userPhotoUrl;
    }
    if (error) {
      newState.error = error;
    }

    newState.setEmailVerified = setEmailVerified;
    // mobile popup
    newState.showMobileDropdownPopup = showMobileDropdownPopup;
    newState.setShowMobileDropdownPopup = setShowMobileDropdownPopup;
    newState.showMobileDropdownPopupAnimation = showMobileDropdownPopupAnimation;
    newState.setShowMobileDropdownPopupAnimation = setShowMobileDropdownPopupAnimation;

    return newState;
  }, [
    loading,
    data,
    refetchUser,
    startUserPolling,
    stopUserPolling,
    onboardingBaselaneAccount,
    onboardingConnectAccount,
    propertySurveyPanelIndex,
    setPropertySurveyPanelIndex,
    triageCardsContinued,
    setTriageCardsContinued,
    skippedBankingFirst,
    setSkippedBankingFirst,
    isDesktopCheckDepositOpen,
    isTabletCheckDepositOpen,
    isMobileCheckDepositOpen,
    emailVerified,
    userPhotoUrl,
    error,
    kycShowPopup,
    showMobileDropdownPopup,
    showMobileDropdownPopupAnimation,
  ]);

  const storeUserTraits = (user) => {
    if (!user) {
      userStorage.delete();
    } else if (user) {
      const { id, email, phoneNumber, utm } = user;
      const gclid = utm?.google?.gclId || utm?.signUp?.gclId;
      const userInfo = { id, email, phone_Number: phoneNumber, gclid, utm };
      userStorage.write(userInfo);
    }
  };

  useEffect(() => {
    const unlisten = auth.onIdTokenChanged((authUser) => {
      if (authUser !== null) {
        getCustomClaims(authUser).then((claims) => {
          LogRocket.log(`onIdTokenChanged claims: ${JSON.stringify(claims)}`);
          const { mfaEnabled, phoneNumber, phoneNumberVerified } = claims;
          if (mfaEnabled && (phoneNumber == null || phoneNumber === '') && phoneNumberVerified) {
            setEmailVerified(undefined);
            manageStorageAfterLogout();
            inActivityLogoutStorage.delete();
            localStorage.removeItem('redirectLogin');
            localStorage.clear();
            sessionStorage.clear();
          } else {
            setEmailVerified(authUser.emailVerified);
            setUserPhotoUrl(authUser.photoURL);
            identifyUser(authUser);
            storeUserTraits(data?.user);
          }
        });
      } else {
        setEmailVerified(undefined);
      }
    });
    return () => {
      unlisten();
    };
  });

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};
export const UserConsumer = UserContext.Consumer;

export const useUserContext = () => {
  const user = useContext(UserContext);

  if (!user) throw new Error('useUserContext must be used within a UserProvider');

  return user;
};

export default UserContext;
