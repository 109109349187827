import React from 'react';

function IconCheckDepositSuccess() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" fill="white" />
      <rect x="22" y="20" width="22" height="22" rx="11" fill="#6ADA9B" />
      <path
        d="M35.2027 26.6016L28.6027 34.3016L25.3027 30.4516"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="30.8008"
        cy="31"
        r="11"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M34.4767 11.7148H19.7148" stroke="black" strokeLinecap="round" />
      <path d="M30.6672 14.5703H19.7148" stroke="black" strokeLinecap="round" />
      <path d="M27.8101 17.4297H19.7148" stroke="black" strokeLinecap="round" />
      <path
        d="M8.30859 19.4518C8.30859 19.1757 8.08474 18.9518 7.80859 18.9518C7.53245 18.9518 7.30859 19.1757 7.30859 19.4518H8.30859ZM15.9753 14.1185C15.9753 14.3946 16.1991 14.6185 16.4753 14.6185C16.7514 14.6185 16.9753 14.3946 16.9753 14.1185H15.9753ZM12.6419 10.2852C12.6419 10.009 12.4181 9.78516 12.1419 9.78516C11.8658 9.78516 11.6419 10.009 11.6419 10.2852H12.6419ZM11.6419 11.7852C11.6419 12.0613 11.8658 12.2852 12.1419 12.2852C12.4181 12.2852 12.6419 12.0613 12.6419 11.7852H11.6419ZM12.6419 21.7852C12.6419 21.509 12.4181 21.2852 12.1419 21.2852C11.8658 21.2852 11.6419 21.509 11.6419 21.7852H12.6419ZM11.6419 23.2852C11.6419 23.5613 11.8658 23.7852 12.1419 23.7852C12.4181 23.7852 12.6419 23.5613 12.6419 23.2852H11.6419ZM13.5864 11.2852H10.9038V12.2852H13.5864V11.2852ZM10.9038 17.2852H13.38V16.2852H10.9038V17.2852ZM13.38 21.2852H10.6975V22.2852H13.38V21.2852ZM10.6975 21.2852C9.27311 21.2852 8.30859 20.3699 8.30859 19.4518H7.30859C7.30859 21.1111 8.93088 22.2852 10.6975 22.2852V21.2852ZM15.9753 19.2852C15.9753 20.2953 14.9184 21.2852 13.38 21.2852V22.2852C15.2606 22.2852 16.9753 21.0365 16.9753 19.2852H15.9753ZM13.38 17.2852C14.9184 17.2852 15.9753 18.275 15.9753 19.2852H16.9753C16.9753 17.5339 15.2606 16.2852 13.38 16.2852V17.2852ZM7.30859 14.2852C7.30859 16.0365 9.02326 17.2852 10.9038 17.2852V16.2852C9.3655 16.2852 8.30859 15.2953 8.30859 14.2852H7.30859ZM10.9038 11.2852C9.02326 11.2852 7.30859 12.5339 7.30859 14.2852H8.30859C8.30859 13.275 9.3655 12.2852 10.9038 12.2852V11.2852ZM16.9753 14.1185C16.9753 12.4592 15.353 11.2852 13.5864 11.2852V12.2852C15.0107 12.2852 15.9753 13.2004 15.9753 14.1185H16.9753ZM11.6419 10.2852V11.7852H12.6419V10.2852H11.6419ZM11.6419 21.7852V23.2852H12.6419V21.7852H11.6419Z"
        fill="#000619"
      />
      <path
        d="M39 18.913V10C39 7.79086 37.2091 6 35 6H8C5.79086 6 4 7.79086 4 10V24C4 26.2091 5.79086 28 8 28H17.125"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconCheckDepositSuccess;
