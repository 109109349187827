import { React, useEffect, useState } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { BaselaneSpinner } from '@shared/components';
import { GET_LEASES } from '../queries';
import LeasesResponsiveList from '../LeasesResponsiveList';
import ButtonLoadMore, { LEASES_PAGE_LIMIT } from './components/ButtonLoadMore';
import UpdateBankAccountDrawer from './components/UpdateBankAccountDrawer';

type LeasesArchivedProps = {
  id: string,
  sortedLeases: Array<Object>,
  setSortedLeases: Function,
  bankProps: Object,
  refetchInvoiceSummary: Function,
  tenantData: Object,
  invoiceListDrawerRef: Object,
  setSelectedLeaseId: Function,
  selectedLeaseId: String,
  setIsLeasesPageLoading: Function,
};

function LeasesArchived({
  id = 'archived-leases',
  sortedLeases,
  setSortedLeases,
  bankProps,
  refetchInvoiceSummary,
  tenantData,
  invoiceListDrawerRef,
  setSelectedLeaseId,
  selectedLeaseId,
  setIsLeasesPageLoading = () => {},
  ...rest
}: LeasesArchivedProps): any {
  const [isEmpty, setIsEmpty] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const { loading, data, refetch, fetchMore } = useQuery(GET_LEASES, {
    fetchPolicy: 'cache-first',
    variables: { input: { state: ['ENDED'], page: 1, pageLimit: LEASES_PAGE_LIMIT } },
  });

  useEffect(() => {
    if (data) {
      const {
        leases: { leases },
      } = data || {};
      setSortedLeases([...leases]);
      setIsEmpty(leases?.length === 0);
      setTotalCount(data?.leases?.totalCount || 0);
    }
  }, [data]);

  return (
    <>
      {/* main list loader */}
      {loading && <BaselaneSpinner mt={10} />}
      {/* regular empty state */}
      {isEmpty && !loading && (
        <VStack w="100%" h="174px" justifyContent="space-around" textAlign="center">
          <Text textStyle="headline-md" color="brand.neutral.700" mt="24px">
            No Archived Rent Collections
          </Text>
          <Text textStyle="sm" mb="24px">
            Once you have archived a rent collection, it will appear here.
          </Text>
        </VStack>
      )}
      {/* Non-Empty State */}
      {!isEmpty && !loading && (
        <>
          <LeasesResponsiveList
            sortedLeases={sortedLeases}
            bankProps={bankProps}
            refetchInvoiceSummary={refetchInvoiceSummary}
            tenantData={tenantData}
            invoiceListDrawerRef={invoiceListDrawerRef}
            setSelectedLeaseId={setSelectedLeaseId}
            selectedLeaseId={selectedLeaseId}
            tabName="Archived"
            refreshLeases={refetch}
            leasesAreArchived
          />
          <ButtonLoadMore
            totalCount={totalCount}
            currentCount={sortedLeases?.length}
            fetchMore={fetchMore}
            leaseState={['ENDED']}
          />
          {/* Update Receiving BA Drawer */}
          <UpdateBankAccountDrawer
            {...{
              tenantData,
              banksData: bankProps?.bankData || {},
              refreshLeases: refetch,
              refetchInvoiceSummary,
              refetchBankAccounts: bankProps?.refetchBankAccounts || {},
              setIsLeasesPageLoading,
            }}
          />
        </>
      )}
    </>
  );
}
export default LeasesArchived;
