// @flow
import React, { useContext, useRef, useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { Icon16PlusCircle } from '@icons/16px';
import BanksContext from '@contexts/BanksContext';
import BaselaneButton from '../../../../Shared/components/BaselaneButton';
import BaselaneExternalBankingTypePopup from '../../../../Shared/components/BaselaneExternalBankingTypePopup';
import AddAccount from '../Accounts/components/AddAccount';
import AddAccountMobile from '../Accounts/components/AddAccountMobile';
import { conditionalAddAccountButtonStyles } from './styles/conditionalAddAccount.styles';

function ConditionalAddAccount({
  refetchTransferAccountsList,
  addAccountManuallyDrawerRef,
  transferType,
  trigger,
  accountProps,
  styles,
}: {
  refetchTransferAccountsList: any,
  addAccountManuallyDrawerRef?: any,
  transferType: String,
  trigger?: string,
  accountProps?: Object,
  styles?: Object,
}) {
  const {
    isOpen: isExternalBankingTypePopupOpen,
    onOpen: onExternalBankingTypePopupOpen,
    onClose: onExternalBankingTypePopupClose,
  } = useDisclosure();

  const { isMinXL } = getBreakPoints();

  const { addExternalAccountDrawerMobileRef } = useContext(BanksContext);
  const addExternalAccountDrawerRef = useRef();

  const [isVisibilityHidden, setIsVisibilityHidden] = useState(!isExternalBankingTypePopupOpen);

  const isTransferOut = transferType === 'TRANSFER_OUT';

  const buttonProps = {
    variant: 'transparent',
    palette: 'primary',
    size: 'md',
    pullLeft: true,
    styles: { mt: '8px' },
    text: isTransferOut ? 'Add Account' : 'Connect External Account',
    leftIcon: <Icon16PlusCircle />,
  };

  const handleActionButton = (type) => {
    if (type === 'manual') {
      onExternalBankingTypePopupClose();
      addAccountManuallyDrawerRef?.current?.open();
    }
  };

  const handleOpenExternalBankingPopup = () => {
    onExternalBankingTypePopupOpen();
    setIsVisibilityHidden(false);
  };

  const addAccountProps = {
    isFromAddFunds: true,
    isDirectToPlaid: true,
    hideButton: true,
    helperFunction: () => {},
    handleAddAccountSuccessCallback: () => setIsVisibilityHidden(true),
    refetchTransferAccountsList,
    addExternalAccountDrawerRef,
  };

  const renderConnectExternalAccount = () => {
    if (isTransferOut && trigger === 'addAccountButton') {
      return (
        <>
          <BaselaneButton {...buttonProps} onClick={handleOpenExternalBankingPopup}>
            {isTransferOut ? 'Add Account' : 'Connect External Account'}
          </BaselaneButton>
          <BaselaneExternalBankingTypePopup
            handleActionButton={handleActionButton}
            isOpen={isExternalBankingTypePopupOpen}
            onClose={onExternalBankingTypePopupClose}
            overlayStyles={{ visibility: isVisibilityHidden && 'hidden' }}
            containerStyles={{ visibility: isVisibilityHidden && 'hidden' }}
            addAccountProps={addAccountProps}
          />
        </>
      );
    }
    // TODO: there is multiple drawer refs, using mobile ref for now as that one is in Context
    return isMinXL ? (
      <AddAccountMobile
        addExternalAccountDrawerRef={addExternalAccountDrawerMobileRef}
        buttonStyles={conditionalAddAccountButtonStyles}
        buttonProps={buttonProps}
        isFromAddFunds
        refetchTransferAccountsList={refetchTransferAccountsList}
        {...accountProps}
      />
    ) : (
      <AddAccount
        addExternalAccountDrawerRef={addExternalAccountDrawerMobileRef}
        buttonStyles={conditionalAddAccountButtonStyles}
        buttonProps={buttonProps}
        isFromAddFunds
        refetchTransferAccountsList={refetchTransferAccountsList}
        {...accountProps}
      />
    );
  };

  return <Box {...styles?.container}>{renderConnectExternalAccount()}</Box>;
}

ConditionalAddAccount.defaultProps = {
  addAccountManuallyDrawerRef: null,
  accountProps: ({}: { ... }),
  trigger: '',
  styles: ({}: { ... }),
};

export default ConditionalAddAccount;
