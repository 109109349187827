import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { sizes, baseStyle, mapVariants, parts } from './tagConfig';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Tag = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: mapVariants(),
});
