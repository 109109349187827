import { isMobile } from 'react-device-detect';

export const footerWrapperStyles = {
  spacing: '16px',
  m: isMobile ? '0' : '32px 0 24px 0',
  position: isMobile ? 'fixed' : 'relative',
  bottom: '0',
  background: isMobile ? 'brand.neutral.white' : 'transparent',
  w: '100%',
  p: isMobile ? '12px 24px 24px' : '0',
  boxShadow: isMobile ? '0 0 12px #ffffff' : 'none',
};
