export const config = {
  columns: [
    // 1. Name
    {
      isSortable: true,
    },
    // 2. Payment Methods
    {
      isSortable: false,
      styles: {
        px: 1.5,
      },
    },
    // 3. Last Paid
    {
      isSortable: true,
      styles: {
        display: { sm: 'table-cell', base: 'none' },
      },
    },
    // 4. Send Money
    {
      isSortable: false,
      styles: {
        w: '200px',
        display: { lg: 'table-cell', base: 'none' },
      },
    },
  ],
};

export const tableHeaderItems = [
  {
    key: 'name',
    label: 'Name',
    field: 'NAME',
    isSortable: true,
  },
  {
    key: 'paymentMethods',
    label: 'Payment Methods',
    field: 'TYPE',
    isSortable: true,
  },
  {
    key: 'lastPaid',
    label: 'Last Payment',
    field: 'LAST_PAYMENT',
    isSortable: true,
  },
  {
    key: 'sendMoney',
  },
];

export const PAYMENT_METHOD_TYPE = {
  ACH: 'ACH',
  CHECK_PAYMENT: 'Check',
  WIRE_TRANSFER: 'Wire',
};

export const DEFAULT_SORT_FIELD = 'NAME';
export const DEFAULT_SORT_DIR = 'ASC';

export const getPaymentTypeName = (typeName) => {
  switch (typeName) {
    case 'Wire Transfer':
      return 'Wire';

    case 'Check Payment':
      return 'Check';

    default:
      return typeName;
  }
};
