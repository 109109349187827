import React from 'react';

type IconAddFundsTooProps = { w?: number, h?: number };

const IconAddFundsToo = ({ w, h }: IconAddFundsTooProps) => {
  return (
    <svg width={w} height={h} viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99961 20.6H26.5996C27.7042 20.6 28.5996 19.7045 28.5996 18.6V15.5052V4.99998C28.5996 3.89541 27.7042 2.99998 26.5996 2.99998H4.99961C3.89504 2.99998 2.99961 3.8954 2.99961 4.99998V7.39998V15.5052V18.6C2.99961 19.7045 3.89504 20.6 4.99961 20.6Z"
        fill="#8CCFFF"
      />
      <path
        d="M21.5193 1.4H25C26.1046 1.4 27 2.29543 27 3.4V13.9053V17C27 18.1046 26.1046 19 25 19H3.4C2.29543 19 1.4 18.1046 1.4 17V13.9053V5.8V3.4C1.4 2.29543 2.29543 1.4 3.4 1.4H6.79062"
        stroke="#000619"
        strokeLinecap="round"
      />
      <path
        d="M1.40039 13.9346C4.43007 13.9346 6.88611 16.2025 6.88611 19M1.40039 6.46548C4.43007 6.46548 6.88611 4.1976 6.88611 1.40002M27.0004 13.9346C23.9707 13.9346 21.5147 16.2025 21.5147 19M27.0004 6.46548C23.9707 6.46548 21.5147 4.1976 21.5147 1.40002"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2008 1.40002V12.6M14.2008 12.6L11.8008 10.2M14.2008 12.6L16.6008 10.2"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconAddFundsToo.defaultProps = {
  w: 29,
  h: 21,
};

export default IconAddFundsToo;
