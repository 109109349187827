import React from 'react';
import { FormControl, Box } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { BaselaneFormLabel, BaselaneFormErrorMessage, T1Dropdown } from '@shared/components';
import { Highlighted } from '@core/components/Shared/components/BaselaneDropdown-new/helpers/itemRenderer.helpers';

const RepeatOnSelect = () => {
  const { values } = useFormikContext();
  const repeatEveryMonth = values?.recurringTransfer?.repeatEvery?.unit === 'month';

  const dropdownItemRenderer = ({ item }) => (
    <Box textStyle="sm" color="inherit">
      <Highlighted text={item?.name} />
    </Box>
  );

  // Creation of date dropdown options.
  const dates = {
    ...Array.from({ length: 28 }, (_, i) => i + 1).reduce((acc, day) => {
      if (day === 1 || day === 21) {
        acc[day] = { id: `${day}`, name: `${day}st of the month` };
      } else if (day === 2 || day === 22) {
        acc[day] = { id: `${day}`, name: `${day}nd of the month` };
      } else if (day === 3 || day === 23) {
        acc[day] = { id: `${day}`, name: `${day}rd of the month` };
      } else {
        acc[day] = { id: `${day}`, name: `${day}th of the month` };
      }
      return acc;
    }, {}),
    last: { id: 'last', name: 'Last day of the month' },
  };

  // Creation of weekdays dropdown options.
  const weekdays = {
    monday: { id: 'monday', name: 'Monday' },
    tuesday: { id: 'tuesday', name: 'Tuesday' },
    wednesday: { id: 'wednesday', name: 'Wednesday' },
    thursday: { id: 'thursday', name: 'Thursday' },
    friday: { id: 'friday', name: 'Friday' },
  };

  return repeatEveryMonth ? (
    <Field name="recurringTransfer.repeatOnMonthDate">
      {({ field, form }) => (
        <FormControl
          isRequired
          isInvalid={
            form.errors?.recurringTransfer?.repeatOnMonthDate &&
            form.touched?.recurringTransfer?.repeatOnMonthDate
          }
        >
          <BaselaneFormLabel textStyle="sm" htmlFor="recurringTransfer.repeatOnMonthDate">
            Repeat on
          </BaselaneFormLabel>
          <T1Dropdown
            classNames={['input-form-md', 'auto-width-dropdown', 'select-auto-width']}
            selectedItem={dates[form.values?.recurringTransfer?.repeatOnMonthDate ?? '28']}
            data={Object.values(dates)}
            handleSubmit={(selectedItemId) => {
              form.setFieldValue('recurringTransfer.repeatOnMonthDate', selectedItemId);
            }}
            showValueByFields={['name']}
            hideSearch
            itemRenderer={dropdownItemRenderer}
          />
          <BaselaneFormErrorMessage
            isInvalid={
              form.errors?.recurringTransfer?.repeatOnMonthDate &&
              form.touched?.recurringTransfer?.repeatOnMonthDate
            }
          >
            {form.errors?.recurringTransfer?.repeatOnMonthDate}
          </BaselaneFormErrorMessage>
        </FormControl>
      )}
    </Field>
  ) : (
    <Field name="recurringTransfer.repeatOnWeekday">
      {({ field, form }) => (
        <FormControl
          isRequired
          isInvalid={
            form.errors?.recurringTransfer?.repeatOnWeekday &&
            form.touched?.recurringTransfer?.repeatOnWeekday
          }
        >
          <BaselaneFormLabel textStyle="sm" htmlFor="recurringTransfer.repeatOnWeekday">
            Repeat on
          </BaselaneFormLabel>
          <T1Dropdown
            classNames={['input-form-md', 'auto-width-dropdown', 'select-auto-width']}
            selectedItem={weekdays[form.values?.recurringTransfer?.repeatOnWeekday ?? 'monday']}
            data={Object.values(weekdays)}
            handleSubmit={(selectedItemId) => {
              form.setFieldValue('recurringTransfer.repeatOnWeekday', selectedItemId);
            }}
            showValueByFields={['name']}
            hideSearch
            itemRenderer={dropdownItemRenderer}
          />
          <BaselaneFormErrorMessage
            isInvalid={
              form.errors?.recurringTransfer?.repeatOnWeekday &&
              form.touched?.recurringTransfer?.repeatOnWeekday
            }
          >
            {form.errors?.recurringTransfer?.repeatOnWeekday}
          </BaselaneFormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default RepeatOnSelect;
