// @flow
import React, { useContext, useRef } from 'react';
import { toNumber } from 'lodash';
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  ChakraProvider,
  useToast,
  Box,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { gql, useMutation } from '@apollo/client';
import {
  useIsAddTransactionDrawerOpen,
  useToggleAddTransactionDrawer,
  useIsDrawerAlertOpen,
  useOnAlertOpen,
  useOnAlertClose,
} from '@store/Transactions/createTransactionDrawerStore';
import useBulkActionsStore from '@store/Transactions/bulkActionsStore';
import habitatTheme from '@core/themeHabitat';
import {
  BaselaneButton,
  BaselaneDivider,
  T1WithTitleDropdown,
  T2WithTitleDropdown,
  T2Dropdown,
  BaselaneDrawer,
  UnsavedChangesAlert,
  BaselaneFormErrorMessage,
  BaselaneTextArea,
  NoPropertyTooltip,
  BaselaneCardNew,
} from '@shared/components';
import stripCurrency from '@core/utils/stripCurrency';
import { CREATE_TRANSACTION, GET_TRANSACTIONS_SUMMARY } from '@core/apollo/queries';
import TransactionContext from '@contexts/TransactionContext';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import BaselaneAmount from '@shared/components/BaselaneAmount';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  getPropertyData,
  renderPropertyDropdownParentItem,
} from '@shared/helpers/propertiesFilter.helpers';
import DateInputField from './components/DateInputField';

import {
  DisplayInputDefaultCategoryItem,
  DisplayInputDefaultPropertyItem,
  DisplayInputDefaultAccount,
} from '../DisplayInputVariations';
import { getOptionsWithSubCategories } from '../../CashFlowPage/helpers/cashflow.helpers';
import { renderAccountDropdownItem } from '../components/TransactionsFilters/helpers/transactionsFilters.helpers';

import { getInitialValues } from '../services/CreateTransactionFormService';

function CreateManualTransactionDrawer(): any {
  const { isMax768 } = getBreakPoints();

  const isAddTransactionDrawerOpen = useIsAddTransactionDrawerOpen();
  const toggleAddTransactionDrawer = useToggleAddTransactionDrawer();
  const isDrawerAlertOpen = useIsDrawerAlertOpen();
  const onAlertOpen = useOnAlertOpen();
  const onAlertClose = useOnAlertClose();

  const showBulkActions = useBulkActionsStore((state) => state.showBulkActions);

  const {
    categoryIdsMap,
    categoryMap,
    connectedAccountsFilterOptions,
    propertiesData,
    propertyIdsMap,
    summaryFilter,
    categoryWithSubOptions,
  } = useContext(TransactionContext);

  const formikRef = useRef();

  const propertyOptions = getPropertyData(propertiesData);
  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);
  const initialValues = getInitialValues();

  const accountOtherOption = {
    id: 'other_option',
    items: [
      {
        id: 'other',
        bankName: 'Manually Added',
      },
    ],
    title: 'Other',
  };

  const accountOptions = connectedAccountsFilterOptions
    ?.filter((item) => {
      return item.id === 'external_account';
    })
    ?.concat(accountOtherOption);
  // Toaster
  const toast = useToast();
  const showSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: `Your transaction has been added successfully.`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  const [createNewTransaction] = useMutation(CREATE_TRANSACTION, {
    update(cache, { data: { createTransaction } }) {
      cache.modify({
        fields: {
          transaction(existingTransactions = []) {
            const newTodoRef = cache.writeFragment({
              data: createTransaction,
              fragment: gql`
                fragment NewTransaction on Transactions {
                  id
                  amount
                  bankAccountId
                  date
                  id
                  merchantName
                  note
                  propertyId
                  tagId
                  unitId
                }
              `,
            });
            return [...existingTransactions, newTodoRef];
          },
        },
      });

      if (!createTransaction.tagId) {
        const res = cache.readQuery({
          query: GET_TRANSACTIONS_SUMMARY,
          variables: { input: summaryFilter },
        });

        if (res) {
          cache.writeQuery({
            query: GET_TRANSACTIONS_SUMMARY,
            data: {
              transactionSummary: {
                ...res.transactionSummary,
                totalUncategorized: {
                  ...res.transactionSummary.totalUncategorized,
                  count: res.transactionSummary.totalUncategorized.count + 1,
                  absoluteAmount:
                    res.transactionSummary.totalUncategorized.absoluteAmount +
                    Math.abs(createTransaction.amount),
                },
              },
            },
            variables: { input: summaryFilter },
          });
        }
      }
    },
  });

  const handleCloseDrawer = (dirty) => {
    if (dirty) {
      onAlertOpen();
    } else {
      formikRef?.current.resetForm({
        values: initialValues,
      });
      toggleAddTransactionDrawer();
    }
  };

  function submitForm(newValues, setSubmitting) {
    const { account, date: trxDate, description, amount, category, property, notes } = newValues;
    const [categoryId, subTagId] = category && category.id.split('-');
    const [propertyId, unitId] = property && property.id.split('-');
    const transformedTransaction = {
      note: notes,
      date: moment(trxDate).format('YYYY-MM-DD'),
      amount:
        newValues.amountSign === 'out'
          ? -toNumber(stripCurrency(amount))
          : +toNumber(stripCurrency(amount)),
      tagId: subTagId ? +subTagId : +categoryId,
      bankAccountId: account === null ? null : +account.id,
      propertyId: +propertyId,
      unitId: unitId && +unitId,
      // TODO: ask b.e to update name from merchantName to description
      merchantName: description,
    };
    setSubmitting(true);
    createNewTransaction({
      variables: { ...transformedTransaction },
    }).then(
      () => {
        showSuccessToast();
        setSubmitting(false);
        handleCloseDrawer();
      },
      (err) => {
        console.error(err);
        setSubmitting(false);
      }
    );
  }

  const getSelectedProperty = (values) => {
    const { property } = values;
    const { propertyId, unitId } = property || {};
    let selectedProperty = null;
    if (propertyId && unitId) {
      selectedProperty = {
        id: `${propertyId}-${unitId}`,
        showValueName: `${propertyIdsMap[`p-${propertyId}`]?.name}, ${
          propertyIdsMap[`u-${unitId}`]?.name
        }`,
      };
    } else if (propertyId && !unitId) {
      selectedProperty = {
        id: `${propertyId}`,
        showValueName: propertyIdsMap[`p-${propertyId}`]?.name,
      };
    }

    return selectedProperty;
  };
  const getSelectedCategory = (values) => {
    return values?.categoryId && values?.categoryId !== ''
      ? {
          id: categoryIdsMap[values.categoryId],
          name: categoryMap[values.categoryId],
        }
      : null;
  };
  const getSelectedAccount = (values) => {
    const { account } = values;
    const { id } = account || {};
    if (id === 'manual') {
      return initialValues.account;
    }
    const selectedAccount =
      connectedAccountsFilterOptions && connectedAccountsFilterOptions.filter((a) => a.id === id);
    return [selectedAccount];
  };
  const accountDropdownProps = (values: Object, setFieldValue: Function) => ({
    additionalProps: { id: 'account-dropdown' },
    classNames: ['input-form-md', 'is-full-width', 'account'],
    data: accountOptions,
    searchTerm: ['account', 'bankName'],
    title: 'Account',
    showValueByFields: ['bankName', 'account'],
    itemRenderer: renderAccountDropdownItem,
    handleSubmit: (selectedValue) => {
      setFieldValue('account', { id: selectedValue });
    },
    isMulti: false,
    hasFilterWrapper: false,
    showDivider: true,
    selectedItem: getSelectedAccount(values),
    CustomDisplayInput: DisplayInputDefaultAccount,
    parentId: 'drawer-body',
    isMobile: isMax768,
  });
  const categoryDropdownProps = (values: Object, setFieldValue: Function) => ({
    additionalProps: { id: 'category-dropdown' },
    classNames: ['input-form-md', 'is-full-width'],
    data: categoryOptions,
    title: 'Category',
    showValueByFields: ['name'],
    placeholder: 'Select Category',
    parentItemRenderer: itemRenderer,
    childItemRenderer: itemRenderer,
    handleSubmit: (selectedValue) => {
      setFieldValue('category', { id: selectedValue });
    },
    selectedItem: getSelectedCategory(values), // selectedItem ?? selectedFilters.categories,
    isMulti: false,
    hasFilterWrapper: false,
    CustomDisplayInput: DisplayInputDefaultCategoryItem,
    isMobile: isMax768,
  });
  const propertyDropdownProps = (values: Object, setFieldValue: Function) => ({
    additionalProps: { id: 'property-dropdown' },
    classNames: ['input-form-md', 'is-full-width'],
    data: propertyOptions,
    searchTerm: 'name',
    title: 'Property',
    placeholder: 'Select Property',
    showValueByFields: ['name'],
    parentItemRenderer: ({ item }) => renderPropertyDropdownParentItem(item),
    childItemRenderer: itemRenderer,
    handleSubmit: (selectedValue) => {
      setFieldValue('property', { id: selectedValue });
    },
    isMulti: false,
    hasFilterWrapper: false,
    selectedItem: getSelectedProperty(values),
    CustomDisplayInput: DisplayInputDefaultPropertyItem,
    isMobile: isMax768,
  });

  const handleValidation = (values) => {
    const errors = {};

    if (!values.description) {
      errors.description = 'Required';
    }
    if (!values.account || values.account.id.length === 0) {
      errors.account = 'Required';
    }
    if (!values.amount) {
      errors.amount = 'Required';
    }
    if (!values.date) {
      errors.date = 'Required';
    }

    return errors;
  };

  return (
    <ChakraProvider theme={habitatTheme}>
      <BaselaneButton
        size="md"
        variant="filled"
        palette="primary"
        onClick={toggleAddTransactionDrawer}
        isDisabled={showBulkActions}
        id="add-transaction-button"
      >
        Add Transaction
      </BaselaneButton>
      <Formik
        innerRef={formikRef}
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validate={(values) => handleValidation(values)}
        onSubmit={(values, rest) => {
          const { setSubmitting } = rest;
          submitForm(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          touched,
          dirty,
        }) => (
          <Form>
            <BaselaneDrawer
              title="Add Transaction"
              size={isMax768 ? 'newdrawerfull' : 'newdrawersm'}
              onClose={() => handleCloseDrawer(dirty)}
              isOpen={isAddTransactionDrawerOpen}
              footer={
                <HStack spacing={1.5}>
                  {/* Cancel */}
                  <BaselaneButton
                    size="md"
                    variant="outline"
                    palette="neutral"
                    onClick={() => {
                      handleCloseDrawer(dirty);
                    }}
                  >
                    Cancel
                  </BaselaneButton>
                  {/* Confirm */}
                  <BaselaneButton
                    id="save-transaction-button"
                    size="md"
                    variant="filled"
                    palette="primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                    isDisabled={isSubmitting}
                  >
                    Save
                  </BaselaneButton>
                </HStack>
              }
              newDesignDrawer
            >
              <BaselaneCardNew palette="dark">
                <Box width="100%">
                  {/* Description */}
                  <FormControl isInvalid={errors.description && touched.description} isRequired>
                    <FormLabel htmlFor="description">Short name</FormLabel>
                    <Input
                      id="description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      placeholder="Short name"
                    />
                    <BaselaneFormErrorMessage isInvalid={errors.description && touched.description}>
                      {errors.description}
                    </BaselaneFormErrorMessage>
                  </FormControl>

                  {/* Amount field */}
                  <FormControl isInvalid={errors.amount && touched.amount} isRequired>
                    <FormLabel htmlFor="amount">Amount</FormLabel>
                    <BaselaneAmount
                      isInvalid={errors.amount && touched.amount}
                      dropdownProps={{
                        id: 'amountSign',
                        name: 'amountSign',
                        value: values.amountSign,
                      }}
                      inputProps={{
                        id: 'amount',
                        name: 'amount',
                        value: values.amount,
                      }}
                      onChange={handleChange}
                    />
                    <BaselaneFormErrorMessage isInvalid={errors.amount && touched.amount}>
                      {errors.amount}
                    </BaselaneFormErrorMessage>
                  </FormControl>

                  {/* Account field */}
                  <FormControl isInvalid={errors.account && touched.account} isRequired>
                    <FormLabel htmlFor="Account">Account</FormLabel>
                    <T1WithTitleDropdown
                      hasError={errors.account && touched.account}
                      {...accountDropdownProps(values, setFieldValue)}
                    />
                    <BaselaneFormErrorMessage isInvalid={errors.account && touched.account}>
                      {errors.account}
                    </BaselaneFormErrorMessage>
                  </FormControl>

                  {/* Date field */}
                  <FormControl isInvalid={errors.date && touched.date} isRequired variant="isLast">
                    <FormLabel htmlFor="Date">Date</FormLabel>
                    <DateInputField
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                    />
                    <BaselaneFormErrorMessage isInvalid={errors.date && touched.date}>
                      {errors.date}
                    </BaselaneFormErrorMessage>
                  </FormControl>
                </Box>
              </BaselaneCardNew>

              <BaselaneDivider my={1.5} />

              {/* Category */}
              <FormControl>
                <FormLabel>Category</FormLabel>
                <T2WithTitleDropdown {...categoryDropdownProps(values, setFieldValue)} />
              </FormControl>

              {/* Property */}
              <FormControl>
                <FormLabel>Property</FormLabel>
                {propertyOptions?.length === 0 ? (
                  <NoPropertyTooltip>
                    <T2Dropdown {...propertyDropdownProps(values, setFieldValue)} isDisabled />
                  </NoPropertyTooltip>
                ) : (
                  <T2Dropdown {...propertyDropdownProps(values, setFieldValue)} />
                )}
              </FormControl>

              {/* Notes */}
              <FormControl>
                <FormLabel htmlFor="notes">Notes</FormLabel>
                <BaselaneTextArea
                  size="sm"
                  id="notes"
                  name="notes"
                  placeholder="Add Note..."
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </BaselaneDrawer>
          </Form>
        )}
      </Formik>
      <UnsavedChangesAlert
        {...{
          isDrawerAlertOpen,
          onAlertClose,
          onAlertOpen,
          onAlertContinue: (e) => {
            onAlertClose();
            handleCloseDrawer();
          },
        }}
      />
    </ChakraProvider>
  );
}

export default CreateManualTransactionDrawer;
