import customTheme from '@core/theme';

export const POSITIVE_AMOUNT_BAR_COLOR = 'scheme.blue';
export const NEGATIVE_AMOUNT_BAR_COLOR = 'scheme.lightBlue';
export const UNCATEGORIZED_AMOUNT_BAR_COLOR = 'scheme.grey';

export const titleStyles = {
  p: '0 !important',
  lineHeight: '24px',
  mb: '16px !important',
};

export const containerStyles = {
  position: 'relative',
  w: '100%',
  h: '100%',
  overflowX: 'hidden',
  borderRadius: '4px',
};

// Shared by T1 & T2
export const categoryNameStyles = {
  textStyle: 'xs',
  m: '0 !important',
  color: customTheme.colors.brand.neutral['700'],
};

export const categoryAmountStyles = {
  textAlign: 'right',
  textStyle: 'headline-sm',
  color: customTheme.colors.brand.neutral['700'],
};

const barStyles = {
  w: '100%',
  bg: 'transparent',
  mt: '4px !important',
};

// T1
export const t1ContainerStyles = {
  w: '100%',
  p: '8px',
  borderWidth: '1px',
  borderColor: customTheme.colors.brand.darkBlue['150'],
  borderRadius: '4px',
  boxShadow: '1px 2px 6px rgba(3, 24, 64, 0.04)',
  mb: '8px',
  _hover: {
    cursor: 'pointer',
  },
  _last: {
    mb: '0',
  },
};

export const t1BarStyles = {
  ...barStyles,
  minH: '12px',
};

// T2
export const t2ContainerStyles = (isOpen) => {
  return {
    position: 'absolute',
    w: '100%',
    top: '0',
    zIndex: 1,
    bgColor: customTheme.colors.brand.neutral.white,
    mt: '0 !important',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: customTheme.colors.brand.darkBlue['150'],
    left: isOpen ? 0 : '100%',
    transition: 'left 0.4s ease-out',
  };
};

export const t2HeaderStyles = {
  bgColor: customTheme.colors.brand.blue['50'],
  p: '8px 24px 8px 8px',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  borderBottomColor: customTheme.colors.brand.blue['100'],
  borderBottomWidth: '1px',
};

export const t2ItemContainerStyles = {
  my: '8px',
  h: '321px',
  overflowY: 'auto',
  css: {
    '&::-webkit-scrollbar': {
      width: '12px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 12px 12px transparent',
      border: 'solid 4px transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      borderRadius: '10px',
      boxShadow: 'inset 0 0 12px 12px #C8D0DA',
      border: 'solid 4px transparent',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      boxShadow: 'inset 0 0 12px 12px #ACB8C7',
      border: 'solid 4px transparent',
    },
  },
};

export const t2ItemStyles = (activeItem) => {
  return {
    display: 'flex',
    width: '100%',
    h: '56px',
    py: '12px',
    pl: '41px',
    pr: '24px',
    m: '0 !important',
    bgColor: activeItem && customTheme.colors.brand.blue['100'],
    _hover: {
      bgColor: activeItem
        ? customTheme.colors.brand.blue['100']
        : customTheme.colors.brand.blue['50'],
      cursor: 'pointer',
    },
  };
};

export const t2BarStyles = {
  ...barStyles,
  minH: '8px',
};

export const t2AmountStyles = {
  ...categoryAmountStyles,
  textStyle: 'xs',
};
