import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import {
  instructionListItemStyles,
  instructionListLineStyles,
  instructionListStyles,
  instructionTitleTextStyles,
} from '../styles/content.styles';
import '../../../../../../../../styles/bulletPoint.scss';

type InstructionsListProps = {
  instructionTitle: string,
  instructionPoints: Array,
};
const InstructionsList = ({ instructionTitle, instructionPoints }: InstructionsListProps) => {
  return (
    <>
      <Text {...instructionTitleTextStyles}>{instructionTitle}</Text>

      <UnorderedList {...instructionListStyles}>
        {instructionPoints.map((instruction, index) => (
          <ListItem key={uuidv4()} className="list-item-custom-md" {...instructionListItemStyles}>
            {instruction}
            {index < instructionPoints.length - 1 && <Box {...instructionListLineStyles} />}
          </ListItem>
        ))}
      </UnorderedList>
    </>
  );
};

export default InstructionsList;
