export const contentContainerStyles = {
  justifyContent: 'space-between',
  color: 'brand.neutral.700',
  spacing: 0,
  h: '100%',
  w: '100%',
  overflow: 'hidden',
};

export const selectedItemsContentContainerStyles = {
  h: '100%',
  justifyContent: 'center',
  overflow: 'hidden',
};

export const categoryAndSubCategoryContainerStyles = {
  color: 'brand.neutral.900',
  textStyle: 'sm',
  lineHeight: '16px',
  h: '100%',
  justifyContent: 'center',
  alignItems: 'flex-start',
  overflow: 'hidden',
};
