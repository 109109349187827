import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table, Thead, Tr, Td, Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import {
  highlightedRowStyle,
  highlightedDataCellStyle,
  totalFontStyle,
  tableStyles,
  DATA_CELL_TOTAL_WIDTH,
} from '../../../styles/cashflowStatement.styles';

import { stripDollars } from '../../../helpers/cashflow-statement.helpers';

type HighlightedRowDataColumnsProps = {
  columns: any[],
  scrollBarWidth: Number,
};

function HighlightedRowDataColumns({ columns, scrollBarWidth }: HighlightedRowDataColumnsProps) {
  return (
    <Table
      {...{ ...tableStyles(), w: `${DATA_CELL_TOTAL_WIDTH * columns.length}px`, minW: '100%' }}
    >
      <Thead>
        <Tr
          {...{
            ...highlightedRowStyle,
            w: `${scrollBarWidth} !important`,
          }}
        >
          {columns?.map((value) => {
            return (
              <Td key={uuidv4()} {...highlightedDataCellStyle}>
                <Text
                  {...{
                    ...totalFontStyle,
                    lineHeight: '48px !important',
                    color: 'brand.neutral.white',
                  }}
                >
                  {stripDollars(formatCurrency(value).rounded)}
                </Text>
              </Td>
            );
          })}
        </Tr>
      </Thead>
    </Table>
  );
}

export default HighlightedRowDataColumns;
