import React from 'react';

function IconSkeletonProgressBar({
  width = '100%',
  height = '32',
  borderRadius = '4',
}: {
  width?: string,
  height?: string,
  borderRadius?: string,
}) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx={borderRadius} fill="#F0F2F6" />
    </svg>
  );
}

IconSkeletonProgressBar.defaultProps = {
  width: '100%',
  height: '32',
  borderRadius: '4',
};

export default IconSkeletonProgressBar;
