export const CATEGORIZED_TRX_STATUS_COLOR = {
  'LET’S GET STARTED': 'brand.blue.400',
  'GREAT START': 'red.800AA',
  'MAKING PROGRESS': 'red.500A',
  'KEEP IT UP': 'yellow.700',
  'NEARLY DONE': 'green.800AA',
  BRAVO: 'brand.blue.400',
};

export const getStatus = (categorizedTxCountInPercentage) => {
  let status = 'LET’S GET STARTED';

  switch (true) {
    case categorizedTxCountInPercentage === 0:
      status = 'LET’S GET STARTED';
      break;
    case categorizedTxCountInPercentage <= 25:
      status = 'GREAT START';
      break;
    case categorizedTxCountInPercentage <= 50:
      status = 'MAKING PROGRESS';
      break;
    case categorizedTxCountInPercentage <= 75:
      status = 'KEEP IT UP';
      break;
    case categorizedTxCountInPercentage <= 99:
      status = 'NEARLY DONE';
      break;
    case categorizedTxCountInPercentage === 100:
      status = 'BRAVO';
      break;
    default:
      break;
  }

  return status;
};
