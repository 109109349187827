import React from 'react';

function IconOverdueCircle() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.08" cx="12" cy="12" r="12" fill="#C93A3A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2682 6.3332C11.0382 5.00026 12.9621 5.00026 13.7321 6.3332L18.3507 14.3332C19.1208 15.6664 18.1587 17.3333 16.6188 17.3333H7.38147C5.84164 17.3333 4.8795 15.6665 5.64945 14.3333C5.64947 14.3333 5.64942 14.3334 5.64945 14.3333L10.2682 6.3332ZM11.4227 7.00012L6.80416 15C6.54758 15.4442 6.86801 16 7.38147 16H16.6188C17.1323 16 17.4528 15.4443 17.1962 15.0001L12.5775 7.00012C12.3208 6.55573 11.6794 6.55573 11.4227 7.00012ZM12.0001 9.33333C12.3683 9.33333 12.6668 9.63181 12.6668 10V11.3333C12.6668 11.7015 12.3683 12 12.0001 12C11.6319 12 11.3335 11.7015 11.3335 11.3333V10C11.3335 9.63181 11.6319 9.33333 12.0001 9.33333ZM11.3335 14C11.3335 13.6318 11.6319 13.3333 12.0001 13.3333H12.0068C12.375 13.3333 12.6735 13.6318 12.6735 14C12.6735 14.3682 12.375 14.6667 12.0068 14.6667H12.0001C11.6319 14.6667 11.3335 14.3682 11.3335 14Z"
        fill="#C93A3A"
      />
    </svg>
  );
}

export default IconOverdueCircle;
