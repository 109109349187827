import { range } from 'lodash';

const CURRENT_YEAR = Number(new Date().getFullYear());
const DEFAULT_YEARS_ARR = range(CURRENT_YEAR, CURRENT_YEAR + 11, 1);
const DEFAULT_MONTHS_ARR = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const monthsObj = Object.freeze({
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
});

export const getYearsArr = ({ start, end }) => {
  let yearsArr = DEFAULT_YEARS_ARR;

  if (start || end) {
    yearsArr = range(start, end + 1, 1);
  }

  return yearsArr;
};

export const getMonthsArr = () => {
  return DEFAULT_MONTHS_ARR;
};

export const getParsedDate = (dateSelected) => {
  const newDate = dateSelected ? new Date(dateSelected) : new Date();

  const parsedYear = Number(newDate.getFullYear());
  const parsedMonth = Number(newDate.getMonth());
  const parsedDate = Number(newDate.getDate());

  return { year: parsedYear, month: parsedMonth, date: parsedDate };
};

export const getIsCurrentMonthYear = ({ newMonth, newYear }) => {
  const { month: currentMonth, year: currentYear } = getParsedDate();

  const isCurrentMonthYear = currentMonth === newMonth && currentYear === newYear;
  return isCurrentMonthYear;
};

export const checkIsCurrentMonthYear = ({ dateToBeChecked, setShowTodayButton }) => {
  const { month: newMonth, year: newYear } = getParsedDate(dateToBeChecked);
  const isCurrentMonthYear = getIsCurrentMonthYear({ newMonth, newYear });
  setShowTodayButton(!isCurrentMonthYear);
};

export const showYearMonthIntoView = ({ showMonthYearSelector }) => {
  if (showMonthYearSelector) {
    const selectedYear = document.querySelector('.selected-year');
    const selectedMonth = document.querySelector('.selected-month');
    selectedYear?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    selectedMonth?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  }
};

export const handleDefaultArrowKeyDown = ({ key, dateOnFocus }) => {
  switch (key) {
    case 'ArrowLeft':
      dateOnFocus.setDate(dateOnFocus.getDate() - 1); // Move left by one day
      break;
    case 'ArrowRight':
      dateOnFocus.setDate(dateOnFocus.getDate() + 1); // Move right by one day
      break;
    case 'ArrowUp':
      dateOnFocus.setDate(dateOnFocus.getDate() - 7); // Move up by one week
      break;
    case 'ArrowDown':
      dateOnFocus.setDate(dateOnFocus.getDate() + 7); // Move down by one week
      break;
    default:
      break;
  }
};

export const handleEscapeKeyDown = (e) => {
  const { key } = e;
  if (key === 'Escape') {
    e.preventDefault();
    e.stopPropagation();
  }
};
