import { useQuery } from '@apollo/client';
import { GET_TRANSACTIONS_SUMMARY } from '@core/apollo/queries';

const useTransactionsSummary = ({ input }) => {
  // queries
  const {
    loading: transactionsSummaryLoading,
    error: transactionsSummaryError,
    data: transactionsSummaryData,
    refetch: refetchTransactionsSummaryData,
    startPolling: startPollingTransactionsSummary,
    stopPolling: stopPollingTransactionsSummary,
  } = useQuery(GET_TRANSACTIONS_SUMMARY, { variables: { input } });

  // extract information to be used throughout the app
  const { transactionSummary = {} } = transactionsSummaryData || {};
  const { total = {}, totalUncategorized = {}, mtdUncategorized = {} } = transactionSummary;
  const {
    count: mtdUncategorizedCount,
    uncategorizedHiddenTxCount: mtdUncategorizedHiddenTxCount,
  } = mtdUncategorized || {};

  const {
    count: totalTransactionCount,
    uncategorizedHiddenTxCount: totalUncategorizedHiddenTxCount,
    categorizedHiddenTxCount,
  } = total || {};

  const {
    amount: uncategorizedAmount,
    count: uncategorizedCountData,
    absoluteAmount: uncategorizedAbsoluteAmount,
    uncategorizedHiddenTxCount,
  } = totalUncategorized || {};

  // Total categorized trx is total trx (ignoring hidden) - total uncategorized (ignoring hidden)
  const totalUnhidden =
    totalTransactionCount - totalUncategorizedHiddenTxCount - categorizedHiddenTxCount;
  const totalUncategorizedUnhiddenTxCount =
    uncategorizedCountData - totalUncategorizedHiddenTxCount;
  const mtdUncategorizedUnhiddenTxCount = mtdUncategorizedCount - mtdUncategorizedHiddenTxCount;
  const categorizedTxCount = totalUnhidden - totalUncategorizedUnhiddenTxCount;
  const categorizedTxCountInPercentage =
    totalTransactionCount !== 0 && totalUnhidden !== 0
      ? Math.round((categorizedTxCount * 100) / totalUnhidden)
      : 0;
  return {
    transactionsSummaryLoading,
    transactionsSummaryError,
    transactionsSummaryData,
    refetchTransactionsSummaryData,
    startPollingTransactionsSummary,
    stopPollingTransactionsSummary,
    mtdUncategorizedCount,
    mtdUncategorizedUnhiddenTxCount,
    uncategorizedAmount,
    uncategorizedAbsoluteAmount,
    uncategorizedHiddenTxCount,
    uncategorizedCountData,
    categorizedTxCount,
    categorizedTxCountInPercentage,
    categorizedHiddenTxCount,
    totalUncategorizedHiddenTxCount,
    totalUncategorizedUnhiddenTxCount,
    totalTransactionCount,
  };
};

export default useTransactionsSummary;
