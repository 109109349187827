// @flow
import React from 'react';
import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import { invoiceTotalStyles } from '../../../styles/invoice.styles';

type invoiceTotalProps = {
  invoiceTotal: Object,
};

function InvoiceTotal({ invoiceTotal }: invoiceTotalProps): any {
  // Destructure Imported Styles
  const { container, label, value } = invoiceTotalStyles;

  const getCurrencyDecimalSeparated = (amount) => {
    const [beforeDecimal, afterDecimal] = amount.split('.');

    return (
      <Text {...value}>
        {beforeDecimal}.
        <Text as="span" {...value.amount.afterdecimal}>
          {afterDecimal}
        </Text>
      </Text>
    );
  };

  const { amount, count } = invoiceTotal ?? {};
  const formattedAmount = formatCurrency(amount).inDollars;
  const decimalSeparatedAmount = getCurrencyDecimalSeparated(formattedAmount);

  return (
    <HStack {...container}>
      <Stack gap="4px">
        <Text {...label}>Total Amount</Text>
        {decimalSeparatedAmount}
      </Stack>
      <Spacer />
      <Stack gap="4px">
        <Text {...label}>Invoices</Text>
        <Text {...{ ...value, ...value.invoices }}>{count ?? 0}</Text>
      </Stack>
    </HStack>
  );
}

export default InvoiceTotal;
