import React from 'react';
import ListOfOptions from './ListOfOptions';
import ListOfOptionsWithCheckboxes from './ListOfOptionsWithCheckboxes';
import DropdownSearchWrapper from '../../DropdownSearch/DropdownSearchWrapper';

type CustomCategoryDropdownProps = {
  options: Array<any>,
  selectedStagedOptions: Array<any>,
  selectedOptions: Array<any>,
  optionStyles: any,
  itemRenderer: Function,
  showCustomCategories?: Boolean,
  dropdownElementRef: any,
  onListItemClick: Function,
  hasFilterWrapper: Boolean,
  title: String,
  showTitleInSearch?: Boolean,
  searchTerm: Array<String>,
  search: String,
  setSearch: Function,
  searchRef: any,
  animationVariant?: String,
  scrollTop?: Number,
  hideSearch?: Boolean,
  hasCheckboxes: Boolean,
};

const CustomCategoryDropdown = ({
  options,
  selectedStagedOptions,
  selectedOptions,
  optionStyles,
  itemRenderer,
  showCustomCategories,
  dropdownElementRef,
  onListItemClick,
  hasFilterWrapper,
  title,
  showTitleInSearch = true,
  searchTerm,
  search,
  setSearch,
  searchRef,
  animationVariant,
  scrollTop,
  hideSearch,
  hasCheckboxes,
}: CustomCategoryDropdownProps) => {
  return (
    <DropdownSearchWrapper
      {...{
        showCustomCategories,
        title,
        showTitleInSearch,
        search,
        setSearch,
        searchRef,
        animationVariant,
        scrollTop,
        hideSearch,
        dropdownElementRef,
        selectedOptions,
      }}
    >
      {hasCheckboxes ? (
        <ListOfOptionsWithCheckboxes
          ref={dropdownElementRef}
          {...{
            options,
            selectedStagedOptions,
            optionStyles,
            itemRenderer,
            onListItemClick,
            hasFilterWrapper,
            searchTerm,
            search,
            hideSearch,
          }}
        />
      ) : (
        <ListOfOptions
          ref={dropdownElementRef}
          {...{
            options,
            selectedStagedOptions,
            optionStyles,
            itemRenderer,
            onListItemClick,
            hasFilterWrapper,
            searchTerm,
            search,
            hideSearch,
          }}
        />
      )}
    </DropdownSearchWrapper>
  );
};

CustomCategoryDropdown.defaultProps = {
  showCustomCategories: null,
  animationVariant: null,
  scrollTop: 0,
  hideSearch: false,
  showTitleInSearch: true,
};

export default CustomCategoryDropdown;
