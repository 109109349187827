import React from 'react';
import MaskedInput from 'react-text-mask';
import { forwardRef, Input } from '@chakra-ui/react';
import { dateMask } from '@core/utils/masks';
import { clearedSearchStyles } from '../styles/maskedDateInput.styles';

type MaskedDateInputProps = {
  dateValue: String,
  onChange: Function,
};

const MaskedDateInput = forwardRef(
  ({ dateValue, onChange, ...rest }: MaskedDateInputProps, ref) => {
    const placeHolderChar = '\u2000';
    return (
      <MaskedInput
        ref={ref}
        defaultValue={dateValue}
        mask={dateMask}
        keepCharPositions
        placeholderChar={placeHolderChar}
        value={dateValue}
        onChange={onChange}
        {...clearedSearchStyles}
        {...rest}
        render={(inputRef, props) => (
          <Input
            ref={inputRef}
            placeholder="Mon DD, YYYY"
            title="Mon DD, YYYY"
            type="text"
            mb="8px"
            border="none"
            {...props}
          />
        )}
      />
    );
  }
);

export default MaskedDateInput;
