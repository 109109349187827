import { useStyleConfig } from '@chakra-ui/react';
import { useMemo } from 'react';
import habitatTheme from '../../../themeHabitat';

export const SUPPORTED_CUSTOM_PROPS = ['palette'];

export default function useBaselaneStyleConfig(component, props) {
  const chakraStyles = useStyleConfig(component, props);
  const styles = useMemo(() => {
    let baselaneStyles = {};

    SUPPORTED_CUSTOM_PROPS.forEach((prop) => {
      const propCategory = `${prop}s`;
      const propValue = props[prop] || null;
      const style = habitatTheme?.components[component]?.[propCategory][propValue];
      let baselaneStyle =
        propValue && habitatTheme?.components[component]?.[propCategory][propValue];
      if (typeof style === 'function') {
        baselaneStyle = baselaneStyle();
      }
      baselaneStyles = { ...baselaneStyles, ...baselaneStyle };
    });

    return { ...chakraStyles, ...baselaneStyles };
  }, [component, props, chakraStyles]);

  return styles;
}
