import React from 'react';

function IconCopy({ w, h, color }: { w?: string, h?: string, color?: string }) {
  return (
    <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4H20V16H15.8462V15.0769H19.0769V4.92308H8.92308V8.15385H8V4Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9H5V19H15V9ZM4 8V20H16V8H4Z"
        fill={color}
      />
    </svg>
  );
}

IconCopy.defaultProps = {
  w: '48',
  h: '40',
  color: '#3A4B5B',
};

export default IconCopy;
