import stripCurrency from '@core/utils/stripCurrency';
import {
  recipientValidation,
  recipientCheckPaymentValidation,
  recipientWirePaymentValidation,
  recipientACHPaymentValidation,
} from '../../Recipients/helpers/validation.helpers';
import { isFutureDate, isSameDay, steps } from './formHelpers';

const validateRecipientField = ({ values }) => {
  const errors = {};

  const { payeeId } = values;

  if (!payeeId) {
    errors.payeeId = 'Select recipient';
  }

  return errors;
};

const validateDescriptionField = ({ values }) => {
  const alphanumericRegex = /^$|^[a-zA-Z0-9\s]+$/;
  const errors = {};
  if (!alphanumericRegex.test(values.description)) {
    errors.description = 'Only alphanumeric characters, are allowed';
  }

  return errors;
};

const validateSendOnDateField = ({ values }) => {
  const errors = {};
  const { sendOnDate } = values;
  if (!sendOnDate) {
    errors.sendOnDate = 'Select date';
  }

  return errors;
};

const validateAmountField = ({ values, accountsMap }) => {
  const {
    amount = 0,
    fromTransferAccountId = -1,
    sendOnDate,
    scheduledPaymentType,
    paymentMethodType,
  } = values;
  const errors = {};
  const formattedAmount = stripCurrency(amount);
  const selectedFromAccObj =
    fromTransferAccountId !== '' ? accountsMap?.[fromTransferAccountId] : {};
  const { availableBalance } = selectedFromAccObj || {};

  // INVALID amount input
  if (!formattedAmount || formattedAmount === 0) {
    errors.amount = 'Amount must be greater than $0.00';
  }

  // If Wire amount should be greater than $100
  if (paymentMethodType === 'WIRE_TRANSFER') {
    if (formattedAmount < 100) {
      errors.amount = 'Amount must be greater than $100';
    }
  }
  // If Ach(TRANSFER_OUT) or Wire dispaly error for Insufficient funds
  if (
    formattedAmount !== null &&
    formattedAmount > availableBalance &&
    !isFutureDate(sendOnDate) &&
    scheduledPaymentType === 'one-time'
  ) {
    if (paymentMethodType === 'WIRE_TRANSFER' || paymentMethodType === 'ACH') {
      errors.amount =
        'Insufficient funds to make this transfer, please edit the transfer amount to continue.';
    }
  }
  if (
    formattedAmount !== null &&
    formattedAmount > availableBalance &&
    !isFutureDate(values?.recurringPayment?.startDate) &&
    scheduledPaymentType === 'recurring'
  ) {
    if (paymentMethodType === 'WIRE_TRANSFER' || paymentMethodType === 'ACH') {
      errors.amount =
        'Insufficient funds to make this transfer, please edit the transfer amount to continue.';
    }
  }

  // If Ach(TRANSFER_OUT) or CHECK_TRANSFER check account dailyDebitlimit and Monthly

  const {
    checkPaymentDailyTotal,
    checkPaymentMonthlyTotal,
    checkPaymentDailyLimit,
    checkPaymentMonthlyLimit,
    dailyCreditTotal,
    monthlyCreditTotal,
    dailyCreditLimit,
    monthlyCreditLimit,
  } = selectedFromAccObj?.limits ?? {};
  if (paymentMethodType === 'ACH') {
    const dailyTransfer = parseFloat(dailyCreditTotal, 10) + parseFloat(formattedAmount, 10);
    const monthlyTransfer = parseFloat(monthlyCreditTotal, 10) + parseFloat(formattedAmount, 10);

    if (dailyTransfer > dailyCreditLimit && monthlyTransfer < monthlyCreditLimit) {
      errors.amount = `Amount exceeds daily limit for this account`;
    } else if (dailyTransfer > dailyCreditLimit && monthlyTransfer > monthlyCreditLimit) {
      errors.amount = `Amount exceeds daily limit for this account`;
    } else if (dailyTransfer < dailyCreditLimit && monthlyTransfer > monthlyCreditLimit) {
      errors.amount = `Amount exceeds the monthly limit for this account`;
    }
  }

  if (paymentMethodType === 'CHECK_PAYMENT') {
    const dailyTransfer = parseFloat(checkPaymentDailyTotal, 10) + parseFloat(formattedAmount, 10);
    const monthlyTransfer =
      parseFloat(checkPaymentMonthlyTotal, 10) + parseFloat(formattedAmount, 10);

    if (dailyTransfer > checkPaymentDailyLimit && monthlyTransfer < checkPaymentMonthlyLimit) {
      errors.amount = `Amount exceeds daily limit for this account`;
    } else if (
      dailyTransfer > checkPaymentDailyLimit &&
      monthlyTransfer > checkPaymentMonthlyLimit
    ) {
      errors.amount = `Amount exceeds daily limit for this account`;
    } else if (
      dailyTransfer < checkPaymentDailyLimit &&
      monthlyTransfer > checkPaymentMonthlyLimit
    ) {
      errors.amount = `Amount exceeds the monthly limit for this account`;
    }
  }

  return errors;
};

const validateFromAccountField = ({ values, accountsMap }) => {
  const errors = {};

  const { fromTransferAccountId, paymentMethodType } = values;
  const selectedFromAccObj =
    fromTransferAccountId !== '' ? accountsMap[fromTransferAccountId] : null;
  const { availableBalance } = selectedFromAccObj || {};
  if (fromTransferAccountId === '') {
    errors.fromTransferAccountId = 'Please select account';
  }

  if (
    paymentMethodType === 'CHECK_PAYMENT' &&
    ((isSameDay(values?.sendOnDate) && values?.scheduledPaymentType === 'one-time') ||
      (isSameDay(values?.recurringPayment?.startDate) &&
        values?.scheduledPaymentType === 'recurring'))
  ) {
    if (availableBalance < 2) {
      errors.fromTransferAccountId = 'Minimum balance of $2 needed to cover the check fee';
    }
  }

  return errors;
};

const validatePaymentMethodField = (values) => {
  const errors = {};

  const { paymentMethodId } = values;

  if (!paymentMethodId) {
    errors.paymentMethodId = 'Select paymentMethod';
  }

  return errors;
};

export const handleRecipientValidation = (values) => {
  let errors = {
    ...recipientValidation(values),
    ...validatePaymentMethodField(values),
  };

  if (values?.paymentMethodId === 'achPaymentMethods') {
    errors = { ...errors, ...recipientACHPaymentValidation(values) };
  }
  if (values?.paymentMethodId === 'wirePaymentMethods') {
    errors = { ...errors, ...recipientWirePaymentValidation(values) };
  }
  if (values?.paymentMethodId === 'checkPaymentMethods') {
    errors = { ...errors, ...recipientCheckPaymentValidation(values) };
  }

  return errors;
};

export const handlePaymentMethodValidation = (values, selectedPaymentMethod) => {
  let errors = {};
  if (values?.paymentMethodType === 'ACH' || selectedPaymentMethod?.type === 'ACH') {
    errors = { ...errors, ...recipientACHPaymentValidation(values) };
  }
  if (values?.paymentMethodType === 'WIRE_TRANSFER' || selectedPaymentMethod?.type === 'Wire') {
    errors = { ...errors, ...recipientWirePaymentValidation(values) };
  }
  if (values?.paymentMethodType === 'CHECK_PAYMENT' || selectedPaymentMethod?.type === 'Check') {
    errors = { ...errors, ...recipientCheckPaymentValidation(values) };
  }

  return errors;
};

export const handleFundsValidation = ({ values, accountsMap, currentStep }) => {
  if (currentStep === steps.ADD_RECIPIENT) {
    return handleRecipientValidation(values);
  }
  if (currentStep === steps.UPDATE_OR_ADD_PAYMENT_METHOD) {
    return handlePaymentMethodValidation(values);
  }
  const errors = {
    ...validateRecipientField({ values }),
    ...validateDescriptionField({ values }),
    ...validateSendOnDateField({ values }),
    ...validateAmountField({ values, accountsMap }),
    ...validateFromAccountField({
      values,
      accountsMap,
    }),
  };

  // Validation for recurring payment.
  const recurringPaymentErrors = {};
  if (values?.scheduledPaymentType === 'recurring') {
    if (values?.recurringPayment?.endBy === 'date' && values?.recurringPayment?.endDate) {
      if (values?.recurringPayment?.startDate > values?.recurringPayment?.endDate) {
        recurringPaymentErrors.endDate = 'End date must be greater than start date';
      }
    }
    if (!values?.recurringPayment?.repeatEvery?.value) {
      recurringPaymentErrors.repeatEvery = {
        value: 'Value is required',
      };
    }
    if (values?.recurringPayment?.repeatEvery?.value < 1) {
      recurringPaymentErrors.repeatEvery = {
        value: 'Value must be greater than 0',
      };
    }
    if (values?.recurringPayment?.endBy === 'numberOfPayments') {
      if (!values?.recurringPayment?.numberOfPayments) {
        recurringPaymentErrors.numberOfPayments = 'Number of payments is required';
      }
      if (values?.recurringPayment?.numberOfPayments < 1) {
        recurringPaymentErrors.numberOfPayments = 'Number of payments must be greater than 0';
      }
    }
  }

  // If there are errors in recurring payment, add them to the main errors object.
  if (Object.keys(recurringPaymentErrors).length) {
    errors.recurringPayment = recurringPaymentErrors;
  }
  return errors;
};
