import process from 'process';
import React, { useEffect } from 'react';

import { init as initApm } from '@elastic/apm-rum';

import { useLocation } from 'react-router-dom';
import Analytics from 'analytics';
import LogRocket from 'logrocket';
import * as ROUTES from '@routes';
import { TITLE, TITLES_MAP } from '@core/constants/titles';
import sendSegmentPageEvent from '@core/utils/sendSegmentEvent/sendSegmentPageEvent';
import '@core/styles/customStyles.scss';

import MainRoutes from './routes/MainRoutes';
import AppWrapper from './components/wrappers/AppWrapper';
import GlobalLayoutWrapper from '../Shared/layouts/GlobalLayoutWrapper';

let apm;

if (process?.env?.REACT_APP_DATADOG_ENABLED !== 'true') {
  // eslint-disable-next-line no-unused-vars
  apm = initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'lanlord-portal-client',

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: process.env.REACT_APP_APM_SERVER_URL,

    // Set service version (required for sourcemap feature)
    serviceVersion: process.env.REACT_APP_APM_SERVICE_VERSION || 'default',

    environment: process.env.REACT_APP_NODE_ENV,

    // Set tracing through URLs
    distributedTracingOrigins: [
      `GET ${process.env.REACT_APP_ORCHESTRATION_URL}`,
      `POST ${process.env.REACT_APP_ORCHESTRATION_URL}`,
      process?.env?.REACT_APP_ORCHESTRATION_URL,
      process?.env?.REACT_APP_ORCHESTRATION_URL?.replace('/graphql', ''),
      'https://develop-orchestration.baselane.com',
      'http://localhost:4500/graphql',
      'http://localhost:4500',
    ],
  });
}

const App = () => {
  const location = useLocation();

  // Alpha 1306 track /login and /signup
  const env = process.env.REACT_APP_NODE_ENV;

  const analytics =
    env === 'production'
      ? Analytics({
          app: 'baselane-app',
        })
      : null;

  // Modify and push messageId (event id) to FB Pixel tracking only
  const DMW1 = ({ payload, integration, next }) => {
    // eslint-disable-next-line no-param-reassign
    payload.obj.messageId = payload.obj.userId ? `${payload.obj.event}_${payload.obj.userId}` : '';
    next(payload);
  };
  window.analytics.addDestinationMiddleware('Facebook Pixel', DMW1);

  // Logrocket Setup
  useEffect(() => {
    if (env === 'production') {
      LogRocket.init('oj1o2q/baselane');
    }
  }, []);

  /* Logic to execute based on location of the user in the application.
      - set browser tab text
      - analytics for login and sign up
   */
  useEffect(() => {
    // Set browser tab text
    const currentTitle = TITLES_MAP.find((item) => item.path === location.pathname);

    if (currentTitle?.title || currentTitle?.title === '') {
      document.title = currentTitle.title === '' ? TITLE : `${TITLE} | ${currentTitle.title}`;
    }

    // Google analytics for login and sign up
    if (analytics && (location.pathname === ROUTES.LOGIN || location.pathname === ROUTES.SIGN_UP)) {
      analytics.page();
    }

    // segment analytics
    if (location.pathname === ROUTES.LOGIN || location.pathname === ROUTES.SIGN_UP) {
      sendSegmentPageEvent();
    }
  }, [location]);

  useEffect(() => {
    const nodeEnv = process?.env?.REACT_APP_NODE_ENV;
    const RLSandbox = process?.env?.REACT_APP_RL_SANDBOX_URL;
    const RLDefault = process?.env?.REACT_APP_RL_URL;

    if (RLSandbox || RLDefault) {
      const script = document.createElement('script');
      script.src = nodeEnv === 'production' || nodeEnv === 'pre-prod' ? RLDefault : RLSandbox;
      script.defer = true;
      script.type = 'text/javascript';
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }

    throw new Error('RL URL is missing in environment variables, please check your configuration.');
  }, []);

  return (
    <AppWrapper>
      <GlobalLayoutWrapper>
        <MainRoutes />
      </GlobalLayoutWrapper>
    </AppWrapper>
  );
};

export default App;
