import React from 'react';

type IconArrowRightProps = {
  w?: string,
  h?: string,
  color?: string,
};

function IconArrowRight({ w = 18, h = 10, color = 'currentColor' }: IconArrowRightProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5768 1.07733C12.9022 0.751893 13.4298 0.751893 13.7553 1.07733L17.0886 4.41066C17.414 4.7361 17.414 5.26374 17.0886 5.58917L13.7553 8.92251C13.4298 9.24794 12.9022 9.24794 12.5768 8.92251C12.2513 8.59707 12.2513 8.06943 12.5768 7.744L14.4875 5.83325H1.49935C1.03911 5.83325 0.666016 5.46016 0.666016 4.99992C0.666016 4.53968 1.03911 4.16659 1.49935 4.16659H14.4875L12.5768 2.25584C12.2513 1.9304 12.2513 1.40277 12.5768 1.07733Z"
        fill={color}
      />
    </svg>
  );
}

IconArrowRight.defaultProps = {
  w: '18',
  h: '10',
  color: 'currentColor',
};

export default IconArrowRight;
