import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Image, Stack, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

import { BaselaneButton } from '@shared/components';
import { IconArrowRightTwo } from '@icons';
import getBreakPoints from '@core/utils/getBreakPoints';
import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import CenterContentWrapper from '@shared/layouts/CenterContentWrapper';
import userContext from '@contexts/UserContext';
import { ANALYTICS_REPORTING } from '@routes';
import {
  buttonStyles,
  contentContainerStyles,
  descriptionTextStyles,
  subTextStyles,
  titleTextStyles,
} from '@core/components/OnboardingTriage/styles/success.styles';

import bookkeepingAllSet from '../assets/bookkeepingAllSet.png';
import barchartCheck from '../assets/barchartCheck.svg';
import { imageContainerStyles } from './styles/allset.styles';
import HeaderContent from '../HeaderContent';

const BookkeepingAnalyticsAllSet = () => {
  const { isMin899 } = getBreakPoints();
  const navigate = useNavigate();
  const { user } = useContext(userContext);

  return (
    <HeaderNavWrapper
      hideBackButton
      hideFinishLater
      headerContent={<HeaderContent onboardingCompleted={user?.onboardingCompleted} />}
      // TODO: to be removed once flow is refactored to not use device detect
      isMobile={isMobile}
    >
      <CenterContentWrapper>
        <Stack {...contentContainerStyles(isMin899)}>
          <Stack>
            <Box>
              <Image src={barchartCheck} alt="barchart icon" />
            </Box>
            <Text {...subTextStyles}>All set!</Text>
            <Text {...titleTextStyles}>Your bookkeeping & analytics are ready to go</Text>
            <Text {...descriptionTextStyles}>
              Visit your Analytics & Reporting page to view cash flow & income statements; visit
              your Transactions page to manage and tag transactions
            </Text>
          </Stack>
          <Box {...imageContainerStyles}>
            <Image src={bookkeepingAllSet} alt="all set image" />
          </Box>
        </Stack>
        <Box>
          <BaselaneButton
            id="view-reporting-button"
            variant="filled"
            palette="primary"
            rightIcon={<IconArrowRightTwo />}
            styles={buttonStyles}
            onClick={() => navigate(ANALYTICS_REPORTING)}
          >
            View Analytics & Reporting
          </BaselaneButton>
        </Box>
      </CenterContentWrapper>
    </HeaderNavWrapper>
  );
};

export default BookkeepingAnalyticsAllSet;
