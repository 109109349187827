import React from 'react';

type IconChevronLeftProps = {
  w?: Number,
  h?: Number,
  color?: String,
  className: String,
  isBold?: Boolean,
};

function IconChevronLeft({
  w = 8,
  h = 14,
  color = 'currentColor',
  className,
  isBold = false,
}: IconChevronLeftProps) {
  return isBold ? (
    <svg
      className={className}
      width={w}
      height={h}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5296 3.46967C10.8225 3.76256 10.8225 4.23744 10.5296 4.53033L7.05997 8L10.5296 11.4697C10.8225 11.7626 10.8225 12.2374 10.5296 12.5303C10.2367 12.8232 9.76187 12.8232 9.46897 12.5303L5.5135 8.57485C5.19602 8.25737 5.19602 7.74263 5.5135 7.42515L9.46898 3.46967C9.76187 3.17678 10.2367 3.17678 10.5296 3.46967Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      className={className}
      width={w}
      height={h}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.35355.646447c-.19526-.195263-.51184-.195263-.7071 0L.646447 6.64645c-.195263.19526-.195263.51184 0 .7071L6.64645 13.3536c.19526.1952.51184.1952.7071 0 .19527-.1953.19527-.5119 0-.7072L1.70711 7l5.64644-5.64645c.19527-.19526.19527-.511841 0-.707103Z"
        fill={color}
      />
    </svg>
  );
}

IconChevronLeft.defaultProps = {
  w: 8,
  h: 14,
  color: 'currentColor',
  isBold: false,
};

export default IconChevronLeft;
