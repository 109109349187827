import moment from 'moment';
import formatCurrency from '@core/utils/formatCurrency';
import stripCurrency from '@core/utils/stripCurrency';
import {
  lastMonthRentInitialValues,
  proratedRentInitialValues,
  securityDepositInitialValues,
} from '@pages/LeasesPage/LeaseSection/forms/DepositsAndFees/helpers/initialValues.helpers';
import { formatDate } from '@core/utils/formatDate';
import {
  getDepositAndFeesData,
  formValidation as depositAndFeesFormValidation,
} from './depositAndFeesForm.helper';

const getIsValidDueDate = (number, startDate, endDate, isMonthToMonth) => {
  let currentDate = moment(startDate);
  const stopDate = isMonthToMonth ? moment(currentDate).add(2, 'years') : moment(endDate);
  let validDate = false;
  while (currentDate <= stopDate) {
    // gets complex as "Last day" is set as number 31 and 29, 30 and 31 are not in dropdown
    if (
      Number(number) === Number(moment(currentDate).format('DD')) ||
      (Number(number) === 31 && [29, 30, 31].indexOf(Number(moment(currentDate).format('DD'))) > -1)
    ) {
      validDate = true;
    }
    currentDate = moment(currentDate).add(1, 'days');
  }
  return validDate;
};

const getDueDateOptions = (short = false) => {
  let id = 1;
  const options = new Array(27);

  for (let i = 0; i < options.length; i += 1) {
    let label;
    id += 1;

    if (id % 10 === 1 && id !== 11) {
      label = short ? `${id}st` : `${id}st of every month`;
    } else if (id % 10 === 2 && id !== 12) {
      label = short ? `${id}nd` : `${id}nd of every month`;
    } else if (id % 10 === 3 && id !== 13) {
      label = short ? `${id}rd` : `${id}rd of every month`;
    } else {
      label = short ? `${id}th` : `${id}th of every month`;
    }

    options[i] = { id, label };
  }

  options.unshift({ id: 31, label: short ? 'Last' : 'Last of every month' });
  options.unshift({ id: 1, label: short ? '1st' : '1st of every month' });

  return options;
};

export const DUE_DATE_OPTIONS = getDueDateOptions();

export const SHORT_DUE_DATE_OPTIONS = getDueDateOptions(true); // short version

export const getOnDateOptions = (number, startDate, endDate, fullArray, isMonthToMonth) => {
  const options = [];
  const currentMomentDate = moment(new Date().toDateString());
  const year = currentMomentDate.format('YYYY');
  // use this year's first possible date as calcStartDate (for it has 31 days)
  const calcStartDate = moment(`${year}-01-${Number(number) < 10 ? '0' : ''}${number}`);
  for (let i = 0; i < 99; i += 1) {
    const dateValue = calcStartDate.clone().add(i, 'month');
    if (dateValue >= currentMomentDate && dateValue >= startDate) {
      const label = dateValue.format('MMM D, YYYY');
      if (isMonthToMonth || dateValue <= endDate) {
        options.push({ dateValue: formatDate(moment(dateValue), 'YYYY-MM-DD'), label });
      } else if (
        Number(number) === 31 &&
        Number(moment(endDate).format('MM')) === Number(dateValue.format('MM')) &&
        (Number(moment(endDate).format('DD')) === 30 || Number(moment(endDate).format('DD')) === 29)
      ) {
        options.push({
          dateValue: formatDate(moment(endDate), 'YYYY-MM-DD'),
          label: moment(endDate).format('MMM D, YYYY'),
        });
      }
      if (
        (options.length === 12 && !fullArray) ||
        (endDate && !isMonthToMonth && dateValue >= endDate)
      ) {
        break;
      }
    }
  }
  return options;
};

export const firstDateIsSmaller = (firstDate, secondDate) => {
  return moment(firstDate) < moment(secondDate);
};

export const validDueDay = (date, number, startDate, endDate, isMonthToMonth) => {
  const validDates = getOnDateOptions(number, startDate, endDate, false, isMonthToMonth).map(
    (object) => object.dateValue
  );
  return validDates.indexOf(date) > -1;
};

export const getRecurringDateText = (number = 1) => {
  let label;
  if (number % 10 === 1 && number !== 11) {
    label = `${number}st`;
  } else if (number % 10 === 2 && number !== 12) {
    label = `${number}nd`;
  } else if (number % 10 === 3 && number !== 13) {
    label = `${number}rd`;
  } else {
    label = `${number}th`;
  }
  return label;
};

export const getLastDateText = (startDate, endDate, number = 1) => {
  // last argument is get full array of dates (not max 12 as per select box)
  const validDates = getOnDateOptions(number, startDate, endDate, true).map(
    (object) => object.dateValue
  );
  return moment(validDates[validDates.length - 1]).format('MMM D, YYYY');
};

export const wordifyDate = (date) => {
  return moment(date).format('MMM D, YYYY');
};

export const wordifyDateDue = (date) => {
  return `${moment(date).format('MMM')} ${getRecurringDateText(moment(date).format('D'))}, ${moment(
    date
  ).format('YYYY')}`;
};

export const calculateMonths = (rcStartDate, endDate, unifiedRC, firstPossibleStartDate) => {
  // TODO: can't this whole function simply be replaced by rcStartOptions.length ? need more testing for that
  const formattedStartDate = unifiedRC
    ? moment(rcStartDate, moment.defaultFormat).toDate()
    : moment(firstPossibleStartDate, moment.defaultFormat).toDate();
  return (
    Math.floor(moment(new Date(endDate)).diff(new Date(formattedStartDate), 'months', true)) + 1
  );
};

export const formValidation = (values) => {
  const errors = { ...depositAndFeesFormValidation(values) };
  if (!values.monthlyRentAmount || values.monthlyRentAmount === '') {
    errors.monthlyRentAmount = 'Please enter monthly rent amount';
  }
  if (stripCurrency(values.monthlyRentAmount) <= 2) {
    errors.monthlyRentAmount = 'Amount must be greater than $2.00';
  }
  if (
    !values.dueDays ||
    values.dueDays === '' ||
    (values.name === 'RENT' && values.dueDays === '0')
  ) {
    errors.dueDays = 'Please select rent due date';
  }
  if (!values.startDate || values.startDate === '') {
    errors.startDate = 'Please select lease start date';
  }
  const validDueDate = getIsValidDueDate(
    values.dueDays,
    values.startDate,
    values.endDate,
    values.isMonthToMonth
  );

  if (!values.rentCollectionStartDate || !validDueDate) {
    errors.dueDays = 'No Rent Collection Start date within selected period and due day.';
  } else {
    delete errors.dueDays;
  }
  if (!values.isMonthToMonth && (!values.endDate || values.endDate === '')) {
    errors.endDate = 'Please select lease end date';
  }
  if (values.hasLateFee) {
    if (values.numberOfDaysForLateFee === null || values.numberOfDaysForLateFee === '') {
      errors.numberOfDaysForLateFee = 'Please enter number of days to assess late fees after';
    } else if (
      // split makes sure the weird suffix mask is removed
      Number(values.numberOfDaysForLateFee) < 1 ||
      Number(values.numberOfDaysForLateFee) > 31 ||
      /[^0-9]/g.test(values.numberOfDaysForLateFee)
    ) {
      errors.numberOfDaysForLateFee = 'Please enter number of days between 1 and 31';
    }
  }
  if (values.hasLateFee && (!values.lateFeeAmount || values.lateFeeAmount === '')) {
    errors.lateFeeAmount = 'Please enter late fee amount';
  }

  if (values.hasLateFee && stripCurrency(values.lateFeeAmount) < 0.5) {
    errors.lateFeeAmount = 'Amount must be greater than $0.50';
  }

  if ((!values.isMonthToMonth && values.endDate) || values.isMonthToMonth) {
    delete errors.endDate;
  }

  if (!values.hasLateFee) {
    delete errors.numberOfDaysForLateFee;
    delete errors.lateFeeAmount;
  }

  return errors;
};

export const feesFormInitialValues = {
  description: '',
  amount: '',
};

export const additionalFeesValidation = (values) => {
  const { description, amount, frequency } = values;
  const errors = {};

  if (description === '') {
    errors.description = 'Please enter description';
  }

  if (amount === '') {
    errors.amount = 'Please enter amount';
  }

  if (stripCurrency(amount) <= 2) {
    errors.amount = 'Amount must be greater than $2.00';
  }

  if (frequency === 'FIXED') {
    delete errors.dueDate;
  }

  return errors;
};

export const getFormInitialValues = (unit) => {
  const { lease } = unit || {};
  const recurringFeeData = getDepositAndFeesData(lease);
  const { fees } = recurringFeeData;
  const feesEnabled = fees.length > 0 ? fees[0].enabled : false;
  const isAdditionalFeesDb =
    fees.length > 0
      ? fees
          .sort((a, b) => a.id - b.id)
          .map((feeData) => {
            const feesData = { ...feeData };
            // eslint-disable-next-line no-underscore-dangle
            delete feesData.__typename;
            delete feesData.category;

            let { dueDate } = feesData;
            if (dueDate && dueDate !== null) {
              dueDate = new Date(dueDate);
            }
            return Object.assign(feesData, { dueDate });
          })
      : [];

  const payments = lease?.payments;
  const startDate = lease?.startDate
    ? moment(lease.startDate, moment.defaultFormat).toDate()
    : null;
  const rentCollectionStartDate = lease?.rentCollectionStartDate
    ? lease.rentCollectionStartDate
    : null;

  const endDate = lease?.endDate ? moment(lease.endDate, moment.defaultFormat).toDate() : null;

  const leaseType = lease?.leaseType;

  const isMonthToMonth = (lease && leaseType === 'MONTHLY') ?? false;

  const rentObj = payments && payments.filter((item) => item.name === 'RENT')[0];
  const monthlyRentAmount = !rentObj?.amount ? '' : formatCurrency(rentObj?.amount).inDollars;
  const dueDays = !rentObj?.dueDays ? 1 : rentObj?.dueDays;
  const rentId = rentObj?.id;
  const rentName = rentObj?.name;

  const data = getDepositAndFeesData(unit?.lease);
  const { deposit, lastMonthRent, proratedRent } = data;

  const {
    enabled: lastMonthEnabled = false,
    paid: lastMonthPaid = false,
    dueDate: lastMonthDueDate = null,
  } = lastMonthRent || {};

  const {
    enabled: proratedRentEnabled = false,
    id: proratedId,
    amount: proratedRentAmount = '',
    dueDate: proratedRentDueDate = null,
    name: proratedName = '',
  } = proratedRent || {};

  const lateFeesObj = payments && payments.filter((item) => item.name === 'LATE_FEE')[0];
  const hasLateFee = lateFeesObj?.enabled;
  const lateFeeAmount = !lateFeesObj?.amount ? '' : formatCurrency(lateFeesObj?.amount).inDollars;
  const numberOfDaysForLateFee = !lateFeesObj?.dueDays ? '' : lateFeesObj?.dueDays;
  const lateFeeId = lateFeesObj?.id;
  const lateFeeName = lateFeesObj?.name;

  const depositData = {
    ...securityDepositInitialValues(deposit.enabled, deposit.amount, deposit.dueDate, deposit.paid),
    ...lastMonthRentInitialValues(lastMonthEnabled, lastMonthDueDate, lastMonthPaid),
    ...proratedRentInitialValues(proratedRentEnabled, proratedRentAmount, proratedRentDueDate),
  };

  // initial form values
  const formInitialValues = {
    monthlyRentAmount,
    dueDays,
    startDate,
    rentCollectionStartDate,
    endDate,
    isMonthToMonth,
    hasLateFee,
    proratedRent,
    proratedRentAmount,
    proratedRentDueDate,
    numberOfDaysForLateFee,
    lateFeeAmount,
    additionalFees: feesEnabled,
    isAdditionalFeesData: fees.length > 0,
    additionalFeesFields: isAdditionalFeesDb,
    ...depositData,
  };

  const paymentData = {
    rentId,
    rentName,
    proratedId,
    proratedName,
    lateFeeId,
    lateFeeName,
  };

  return [formInitialValues, paymentData];
};
