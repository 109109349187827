import React from 'react';
import { HStack, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import { ReactComponent as ReferralIcon } from '../../assets/referral-icon.svg';

import { signupReferralGiftBoxStyles } from '../../styles/signup.styles';

type ReferralBoxProps = {
  referrer: String,
  signUpVariant: String,
};

const ReferralBox = ({ referrer, signUpVariant }: ReferralBoxProps) => {
  const [isDesktop] = useMediaQuery('(min-width: 1280px)', { ssr: false });

  return (
    <HStack {...signupReferralGiftBoxStyles(isDesktop, signUpVariant)}>
      <ReferralIcon />
      <Stack ml="0px !important" gap="0">
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          fontWeight="medium"
          lineHeight={{ base: '20px', md: '24px' }}
          color="white"
        >
          {referrer} invited you to join
        </Text>
        <Text textStyle="sm" color="brand.darkBlue.300" mt="0 !important">
          Claim $100 reward by signing up now
        </Text>
      </Stack>
    </HStack>
  );
};

export default ReferralBox;
