export const verifyEmailModalStyles = {
  modal: {
    content: {
      p: '32px',
      maxW: '520px',
      minH: '467px',
      borderWidth: '1px',
      borderColor: 'brand.darkBlue.150',
      textAlign: 'left',
    },
    body: { p: '0', mt: '25px' },
  },
  icon: {
    container: {
      w: '48px',
      h: '48px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mb: '8px',
    },
  },
  content: {
    container: {
      gap: '24px',
    },
    top: {
      container: {
        gap: '8px',
      },
      title: {
        fontSize: '2xl',
        color: 'brand.neutral.700',
        lineHeight: '32px',
        fontWeight: 'semibold',
      },
      description: {
        textStyle: 'sm',
        color: 'brand.neutral.600',
        m: '0 !important',
      },
      email: {
        textStyle: 'headline-sm',
        color: 'brand.neutral.600',
      },
      messagecard: {
        iconContainerStyles: {
          w: '24px',
          h: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        containerStyles: {
          py: '0px',
          mb: '24px',
          w: '100%',
        },
        styles: {
          innercontainer: {
            py: '8px',
          },
          titleContainer: {
            ml: '0 !important',
          },
          container: {
            m: '0 !important',
          },
          title: {
            flex: 'auto',
          },
        },
      },
    },
    bottom: {
      container: {
        m: '0 !important',
      },
      form: {
        control: {
          mb: '8px',
        },
      },
      button: {
        m: '0 !important',
        alignSelf: 'flex-start',
      },
    },
    actionbutton: {
      m: '0 !important',
      alignSelf: 'flex-start',
    },
    disclaimer: {
      textStyle: 'xs',
      color: 'brand.neutral.500',
      m: '0 !important',
    },
  },
  spinner: {
    container: {
      position: 'absolute',
      top: 'calc(50% - 25px)',
      left: '50%',
    },
    icon: {
      thickness: '3px',
      size: 'md',
      color: 'brand.neutral.500',
    },
  },
};
