import { gql } from '@apollo/client';

export const GET_PLAID_LINK_TOKEN = gql`
  query {
    plaidLinkToken
  }
`;

export const GET_UPDATE_PLAID_LINK_TOKEN = gql`
  query updatePlaidLinkToken($bankAccountId: ID) {
    updatePlaidLinkToken(bankAccountId: $bankAccountId)
  }
`;

export const LINK_BANK_WITH_USER = gql`
  mutation LinkBankWithUser($publicToken: String!) {
    linkBankWithUser(public_token: $publicToken) {
      id
      userId
      plaidInstitutionName
      isConnected
      rentCollectionSupported
      isExternal
      logo
      url
      bankAccounts {
        id
        availableBalance
        accountNumber
        nickName
        accountName
        accountType
        accountSubType
        updatedAt
        currentBalance
        isNewlyAdded
        isConnected
        isExternal
        connectionState
        revokedByUser
        stripeBankAccountId
        transferSupported
        rentCollectionSupported
        transactionSupported
        bankAccountMetadata {
          payoutFailedReason {
            failureCode
            failureMessage
          }
          lastPayoutFailed
        }
        subAccounts {
          id
          availableBalance
          accountNumber
          nickName
          accountName
          accountType
          accountSubType
          updatedAt
          currentBalance
          isConnected
          isExternal
          connectionState
          revokedByUser
          stripeBankAccountId
          updatedAt
          bankAccountMetadata {
            payoutFailedReason {
              failureCode
              failureMessage
            }
            lastPayoutFailed
          }
          autoTag {
            enabled
            propertyUnitId
            propertyId
          }
          importTransaction {
            enabled
            importTransactionType
            updatedAt
          }
          linkedFor {
            linkFor
            property {
              id
              propertyUnitId
            }
          }
        }
        updatedAt
        autoTag {
          enabled
          propertyUnitId
          propertyId
        }
        importTransaction {
          enabled
          importTransactionType
          updatedAt
        }
        linkedFor {
          linkFor
          property {
            id
            propertyUnitId
          }
        }
      }
    }
  }
`;

export const RESYNC_EXTERNAL_BANK_ACCOUNT = gql`
  mutation ReSyncExternalBankAccount($bankAccountId: Float!) {
    reSyncExternalBankAccount(bankAccountId: $bankAccountId) {
      id
      availableBalance
      accountNumber
      nickName
      accountName
      accountType
      accountSubType
      updatedAt
      currentBalance
      isNewlyAdded
      isConnected
      isExternal
      connectionState
      revokedByUser
      stripeBankAccountId
      transferSupported
      rentCollectionSupported
      transactionSupported
      bankAccountMetadata {
        payoutFailedReason {
          failureCode
          failureMessage
        }
        lastPayoutFailed
      }
      subAccounts {
        id
        availableBalance
        accountNumber
        nickName
        accountName
        accountType
        accountSubType
        updatedAt
        currentBalance
        isConnected
        isExternal
        connectionState
        revokedByUser
        stripeBankAccountId
        updatedAt
        bankAccountMetadata {
          payoutFailedReason {
            failureCode
            failureMessage
          }
          lastPayoutFailed
        }
        autoTag {
          enabled
          propertyUnitId
          propertyId
        }
        importTransaction {
          enabled
          importTransactionType
          updatedAt
        }
        linkedFor {
          linkFor
          property {
            id
            propertyUnitId
          }
        }
      }
      updatedAt
      autoTag {
        enabled
        propertyUnitId
        propertyId
      }
      importTransaction {
        enabled
        importTransactionType
        updatedAt
      }
      linkedFor {
        linkFor
        property {
          id
          propertyUnitId
        }
      }
    }
  }
`;
