import React from 'react';
// import getBreakPoints from '@core/utils/getBreakPoints';
import IconMobileCheckDeposit from '@icons/legacy/IconMobileCheckDeposit';
import SlLoader from '@core/components/Loader';
import RecipientDetails from '../RecipientDetails';
import TransferForm from '../TransferForm';
import ReviewMailCheckView from '../ReviewMailCheckView';
import SuccessView from '../SuccessView';
import PropertyAndCategoryTaggingForm from '../../../../PropertyAndCategoryTaggingForm';

// const handleError = (error) => {
//   const { message = '' } = error?.[0] ?? {};
//   const errorCode = message.replace(/\s/g, '');

//   const defaultMessages = {
//     title: 'We are unable to process this check payment',
//     text: 'Go back to review entered information and try again.',
//   };

//   const errorConfig = {
//     AccountFrozen: {
//       title: 'You entered an inactive routing number',
//       text: 'Go back to review entered information and try again.',
//     },
//     SuspectedFraud: defaultMessages,
//     InsufficientFunds: {
//       ...defaultMessages,
//       step: 3,
//     },
//     InvalidRoutingNumber: {
//       title: 'You entered an invalid routing number',
//       text: 'Go back to review entered information and try again.',
//       step: 1,
//     },
//     WireRejected: defaultMessages,
//     AccountClosed: {
//       title: 'You entered an inactive routing number',
//       text: 'Go back to review entered information and try again.',
//     },
//     ClientRequest: defaultMessages,
//   };

//   return { errorCode, errorConfig: errorConfig[errorCode] ?? defaultMessages };
// };

const handleFormViews = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  totalSteps,
  currentStep,
  paymentMethod,
  setIsValidAccount,
  setIsValidBankTransfer,
  setTransfer,
  propertyOptions,
  categoryOptions,
  categoryMap,
  categoryIdsMap,
  handlePropertySubmit,
  handleCategorySubmit,
  handleErrorRedirect,
  transfer,
  error,
  setShowMobileDropdownPopup,
  setShowMobileDropdownPopupAnimation,
  showMobileDropdownPopup,
}) => {
  const sharedProps = {
    totalSteps,
    currentStep,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
  };
  // const { errorConfig } = handleError(error);
  //  const { isMinXL } = getBreakPoints();

  const contentColumnTitleStyles = {
    mb: '1',
  };

  switch (currentStep) {
    case 1:
      return <RecipientDetails {...sharedProps} />;
    case 2:
      return (
        <TransferForm
          {...{
            ...sharedProps,
            paymentMethod,
            setIsValidAccount,
            setIsValidBankTransfer,
            setTransfer,
            transfer,
            handleChange,
          }}
        />
      );
    case 3:
      return (
        <PropertyAndCategoryTaggingForm
          {...{
            handlePropertySubmit,
            handleCategorySubmit,
            propertyOptions,
            categoryOptions,
            categoryIdsMap,
            categoryMap,
            setShowMobileDropdownPopup,
            setShowMobileDropdownPopupAnimation,
            showMobileDropdownPopup,
            isValidBankTransfer: true,
            renderCategoryDropdownItem: (item) => item.name,
            drawerTitle: 'Mail a check',
            transferTypeText: 'mail a check',
            ...sharedProps,
            titleStyles: { ...contentColumnTitleStyles },
          }}
        />
      );
    case 4:
      return (
        <ReviewMailCheckView
          {...{
            values,
            propertyOptions,
            categoryOptions,
            transfer,
            categoryIdsMap,
          }}
        />
      );
    case 5:
      return (
        <SuccessView
          {...{
            icon: <IconMobileCheckDeposit />,
            title: 'Your check payment is being processed',
            message:
              'The check should arrive in 5-8 business days. We will email you with further updates. You can also track the status on theTransfers & Payments page.',
          }}
        />
      );
    default:
      return <SlLoader className="" />;
  }
};

export default handleFormViews;
