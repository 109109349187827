import React from 'react';
import {
  useRecipient,
  useTransferType,
} from '@core/pages/Transfers/components/SendFunds/sendFundsStore';
import BaselaneFlowComplete from '@shared/components/BaselaneFlowComplete';
import TransferReferralSuccessCard from '@shared/components/TransferReferralSuccessCard';

const ReccuringSuccessScreen = () => {
  const recipient = useRecipient();
  const transferType = useTransferType();
  const toMessage = recipient ? `${recipient?.name}` : `external account`;
  const getDynamicSuccessCaption = () => {
    switch (transferType) {
      case 'TRANSFER_OUT':
        return {
          title: 'Scheduled ACH payment to',
          title2: toMessage,
          description1: 'The money should arrive in 3-5 business days from the payment date. ',
          description2: 'You can track the status on the Transfers & Payments page.',
        };
      case 'ACH':
        return {
          title: 'Scheduled ACH payment to',
          title2: toMessage,
          description1: 'The money should arrive in 3-5 business days from the payment date. ',
          description2: 'You can track the status on the Transfers & Payments page.',
        };
      case 'WIRE_TRANSFER':
        return {
          title: 'Scheduled wire payment to',
          title2: toMessage,
          description1: 'The money should arrive in 1 business day from the payment date.',
          description2: 'You can track the status on the Transfers & Payments page.',
        };
      case 'CHECK_PAYMENT':
        return {
          title: 'Scheduled check payment to',
          title2: toMessage,
          description1: 'The check should arrive in 5-8 business days from the payment date.',
          description2:
            'We will email you with further updates. You can also track the status on the Transfers & payments page. ',
        };

      default:
        return null;
    }
  };

  const getSource = () => {
    switch (transferType) {
      // Send Funds: ACH
      case 'TRANSFER_OUT':
        return 'send_funds';

      // Add Funds: Bank Transfer
      case 'TRANSFER_IN':
        return 'add_funds';

      // Send Funds: Wire
      case 'WIRE_TRANSFER':
        return 'send_funds';

      // Send Funds: Mail a check
      case 'CHECK_PAYMENT':
        return 'send_funds';

      default:
        return null;
    }
  };

  const { title, title2, description1, description2 } = getDynamicSuccessCaption();

  return (
    <BaselaneFlowComplete
      type="success"
      title={title}
      title2={title2}
      description1={description1}
      description2={description2}
    >
      <TransferReferralSuccessCard source={getSource()} mt={2} />
    </BaselaneFlowComplete>
  );
};

export default ReccuringSuccessScreen;
