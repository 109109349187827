import React from 'react';
import { useLocation } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { Box, Flex, Text } from '@chakra-ui/react';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { LEASES_QUICKPAY_LEARN_MORE } from '@routes';
import { BaselaneLink, BaselaneToolpop } from '@shared/components';
import { IconLightningOutline } from '@icons';

type QuickPayIconOrBadgeProps = {
  isIconOnly?: boolean,
  isGreen?: boolean,
  isDisabled?: boolean,
};

const QuickPayIconOrBadge = ({ isIconOnly, isGreen, isDisabled }: QuickPayIconOrBadgeProps) => {
  const { value: quickPayLearnMoreValue } = useGate('quick_pay_announce_banner_gate');
  const location = useLocation() || {};
  const { state } = location || {};

  const { leaseId, invoiceId } = state || {};

  const originalState = {
    leaseId,
    invoiceId,
    noRefresh: true,
    noOpen: true,
  };
  const onButtonClick = (e) => {
    e.stopPropagation();
    sendSegmentEvent('quickpay_click_learn_more');
  };

  const quickpayTooltipTrigger = isIconOnly ? (
    <Box
      bg={isGreen ? 'green.100' : 'brand.neutral.150'}
      borderRadius="50"
      w="16px"
      h="16px"
      padding="2px"
    >
      {isGreen ? (
        <IconLightningOutline w="12" h="12" color="#398311" />
      ) : (
        <IconLightningOutline w="12" h="12" />
      )}
    </Box>
  ) : (
    <Flex bg="brand.neutral.150" borderRadius="50" w="100%" h="20px" whiteSpace="nowrap">
      <Box padding="4px">
        <IconLightningOutline w="12" h="12" />
      </Box>
      <Box fontSize="14px" lineHeight="20px" padding="0 4px 4px">
        QuickPay eligible
      </Box>
    </Flex>
  );

  const quickpayTooltipText =
    isIconOnly && isGreen ? (
      <Text textAlign="left">Processed with QuickPay</Text>
    ) : (
      <>
        <Text as="b" fontWeight="500">
          QuickPay eligible
        </Text>
        <Text textAlign="left" style={{ textWrap: 'wrap' }}>
          This tenant has set up auto-pay with an eligible payment method.
          <br />
          Payments will be paid out in 2-3 business days if we can verify the availability of funds
          for your tenant&lsquo;s payment method.
        </Text>
        {quickPayLearnMoreValue && (
          <BaselaneLink
            variant="reverse"
            to={LEASES_QUICKPAY_LEARN_MORE}
            state={{ from: location.pathname, originalState }}
            size="md"
            onClick={(e) => onButtonClick(e)}
          >
            Learn more{' '}
            <Box as="span" fontSize="16px" lineHeight="20px" mb="2px">
              ›
            </Box>
          </BaselaneLink>
        )}
      </>
    );

  return (
    <BaselaneToolpop
      label="Quickpay eligible"
      trigger={quickpayTooltipTrigger}
      isDisabled={isDisabled}
    >
      {quickpayTooltipText}
    </BaselaneToolpop>
  );
};

QuickPayIconOrBadge.defaultProps = {
  isIconOnly: false,
  isGreen: false,
  isDisabled: false,
};

export default QuickPayIconOrBadge;
