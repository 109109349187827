import { isMobile } from 'react-device-detect';

export const textLabelStyle = {
  fontWeight: 'medium',
  fontSize: 'sm',
  lineHeight: '24px',
  color: 'brand.neutral.700',
};

export const generalFormContainerStyles = {
  p: '32px 0',
  overflowY: 'auto',
  h: '100%',
  position: 'relative',
  mr: '0',
};

export const unifiedLeaseFormContainerStyles = {
  p: '32px 0',
  overflowY: 'auto',
  h: '100%',
  position: 'relative',
  mr: '0',
};

export const fieldContainer = {
  w: '100%',
  position: 'relative',
  mb: '16px',
};

export const feeBox = {
  p: '16px',
  bg: 'brand.darkBlue.50',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: 'brand.darkBlue.200',
  w: '100%',
  direction: 'column',
};

export const subheadingTextStyles = {
  ...textLabelStyle,
  mb: '24px !important',
  mt: isMobile ? '8px' : '0',
};

export const titleStyles = {
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
};
