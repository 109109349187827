// @flow
import React, { useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useMutation } from '@apollo/client';
import { IconChevronRight, IconExclamationCircle, IconLock } from '@icons';
import { LINK_BANK_WITH_USER, RESYNC_EXTERNAL_BANK_ACCOUNT } from '@core/apollo/queries';

import NoCompatibleAccounts from './NoCompatibleAccounts';
import BaselaneButton from '../Shared/components/BaselaneButton';
import ConnectPlaidButton from '../Shared/components/BaselaneExternalBankingTypePopup/ConnectPlaidButton';

type AddBankAccountButtonProps = {
  type: string,
  variant?: string,
  palette?: string,
  state?: string,
  titleText: string,
  handleSuccessFn: Function,
  onExit: Function,
  linkToken?: string,
  setPlaidText: Function,
  refetchFunction?: Function,
  size: string,
  hasIconLock: boolean,
  leftIcon: any,
  hasRightChevronIcon: boolean,
  styles?: Object,
  onPlaidConfirmationClose: Function,
  mode: string,
  bankAccId?: any,
  isDisabled: boolean,
  setIsPlaidButtonLoading: Function,
  segmentEvent?: Function,
  showCustomButton?: boolean,
  pullLeft: bollean,
};

function AddBankAccountButton({
  type,
  variant,
  palette,
  state,
  linkToken,
  titleText,
  handleSuccessFn,
  onExit,
  setPlaidText,
  size,
  hasIconLock,
  leftIcon,
  hasRightChevronIcon,
  refetchFunction,
  onPlaidConfirmationClose,
  styles,
  mode,
  bankAccId,
  isDisabled,
  setIsPlaidButtonLoading,
  segmentEvent,
  showCustomButton,
  pullLeft,
}: AddBankAccountButtonProps): any {
  const [linkBankWithUser] = useMutation(LINK_BANK_WITH_USER);
  const [reSyncExternalBankAccount] = useMutation(RESYNC_EXTERNAL_BANK_ACCOUNT);
  const setNonCompatibleAccountsView = useCallback(
    () =>
      setPlaidText({
        title: 'No Compatible Accounts Found',
        message: <NoCompatibleAccounts />,
        icon: <IconExclamationCircle w={24} h={24} />,
        iconColor: 'red',
        error: true,
      }),
    [setPlaidText]
  );

  const onSuccess = useCallback(
    (token) => {
      onPlaidConfirmationClose();

      const variables = {
        publicToken: token,
      };
      linkBankWithUser({
        variables,
      })
        .then((res) => {
          const unsupportedBankAccountError = (res?.errors || []).find(
            (error) => error.extensions?.exception?.messageCode === 'UNSUPPORTED_BANK_ACCOUNT_TYPE'
          );

          if (unsupportedBankAccountError) {
            setNonCompatibleAccountsView();
          } else {
            handleSuccessFn(res);
            refetchFunction();
          }
        })
        .catch((err) => {
          console.log(err);
          const unsupportedBankAccountError = (err?.length || []).find(
            (error) => error.extensions?.exception?.messageCode === 'UNSUPPORTED_BANK_ACCOUNT_TYPE'
          );
          if (unsupportedBankAccountError) {
            setNonCompatibleAccountsView();
          } else {
            // TODO: do we still need this error?
            setPlaidText({
              title: 'Bank is already added',
              message: '',
              error: true,
            });
          }
        });
    },
    [
      linkBankWithUser,
      handleSuccessFn,
      onPlaidConfirmationClose,
      refetchFunction,
      setNonCompatibleAccountsView,
      setPlaidText,
    ]
  );

  const onResyncSuccess = () => {
    onPlaidConfirmationClose();
    const variables = {
      bankAccountId: parseFloat(bankAccId),
    };

    reSyncExternalBankAccount({
      variables,
    })
      .then((res) => {
        const unsupportedBankAccountError = (res?.errors || []).find(
          (error) => error.extensions?.exception?.messageCode === 'UNSUPPORTED_BANK_ACCOUNT_TYPE'
        );

        if (unsupportedBankAccountError) {
          setNonCompatibleAccountsView();
        } else {
          handleSuccessFn(res);
          refetchFunction(res);
        }
      })
      .catch((err) => {
        console.log(err);
        const unsupportedBankAccountError = (err?.length || []).find(
          (error) => error.extensions?.exception?.messageCode === 'UNSUPPORTED_BANK_ACCOUNT_TYPE'
        );
        if (unsupportedBankAccountError) {
          setNonCompatibleAccountsView();
        } else {
          // TODO: do we still need this error?
          setPlaidText({
            title: 'Bank is already added',
            message: '',
            error: true,
          });
        }
      });
  };

  const config = {
    token: linkToken,
    onSuccess: mode === 'RESYNC' ? onResyncSuccess : onSuccess,
    onExit,
  };

  const { open, error, ready } = usePlaidLink(config);

  if (error) {
    console.error('Error on adding Plaid Account --> ', error.message);
  }

  const handleReconnectBankClick = (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    segmentEvent();
    open();
  };

  const handleActionButton = (buttonType) => {
    if (buttonType === 'plaid') {
      handleReconnectBankClick();
    }
  };

  if (type === 'buttonCard') {
    return <ConnectPlaidButton {...{ handleActionButton, setIsPlaidButtonLoading }} />;
  }

  return (
    <BaselaneButton
      id="add-plaid-account-button"
      isLoading={showCustomButton ? false : !ready}
      onClick={(e) => handleReconnectBankClick(e)}
      variant={variant}
      palette={palette}
      size={size}
      pullLeft={pullLeft}
      styles={styles}
      leftIcon={hasIconLock ? <IconLock /> : leftIcon}
      rightIcon={hasRightChevronIcon ? <IconChevronRight /> : null}
      isDisabled={showCustomButton ? isDisabled || !ready || linkToken === '' : isDisabled}
    >
      {titleText || 'Add Account'}
    </BaselaneButton>
  );
}

AddBankAccountButton.defaultProps = {
  refetchFunction: () => {},
  state: '',
  variant: 'tonal',
  palette: 'primary',
  linkToken: '',
  styles: {},
  bankAccId: '',
  showCustomButton: false,
  segmentEvent: () => {},
};
export default AddBankAccountButton;
