import {
  IconInfo,
  IconCheck,
  IconExclamationCircle,
  IconExclamationTriangle,
  IconCheckCircle,
  IconCheckCircleOutline,
  IconEmail,
  IconScheduledOutline,
  IconWarningCircle,
  IconCurrentCircle,
  IconOverdueCircle,
  IconNoData,
  IconRefresh,
  IconKey,
  IconTag,
  IconClose,
  IconStar,
  IconBanksAndCards,
} from '@icons';

const icons = {
  exclamationtriangle: IconExclamationTriangle,
  check: IconCheck,
  checkcircle: IconCheckCircle,
  checkcircleoutline: IconCheckCircleOutline,
  exclamationcircle: IconExclamationCircle,
  info: IconInfo,
  email: IconEmail,
  scheduled: IconScheduledOutline,
  rentstatusnoactivelease: IconWarningCircle,
  rentstatuscurrent: IconCurrentCircle,
  rentstatusoverdue: IconOverdueCircle,
  nodata: IconNoData,
  refresh: IconRefresh,
  key: IconKey,
  tag: IconTag,
  star: IconStar,
  close: IconClose,
  card: IconBanksAndCards,
};
export default icons;
