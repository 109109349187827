import { sortWithString } from '@core/utils/sort';

export const getManualAccountsArray = (bankWithAccounts) => {
  const manualAccounts = [];
  bankWithAccounts?.forEach((bank) => {
    return bank.bankAccounts.forEach((acc) => manualAccounts.push({ ...acc }));
  });
  const sortedManualAccounts = sortWithString(manualAccounts, 'nickName');
  return sortedManualAccounts;
};
