import React from 'react';
import { isMobile } from 'react-device-detect';
import { Stack } from '@chakra-ui/react';
import AddAccount from '@core/components/NativeBankingPage/MainContent/components/Accounts/components/AddAccount';
import AddAccountMobile from '@core/components/NativeBankingPage/MainContent/components/Accounts/components/AddAccountMobile';
import AddManualAccountButton from './AddManualAccountButton';

type ExternalBankingButtonsProps = {
  handleActionButton: Function,
  isPlaidButtonLoading: boolean,
  setIsPlaidButtonLoading: Function,
  addAccountProps?: Object,
  buttonStyles?: Object,
  helperFunction?: Function,
  linkedAccountsDrawerOnClose?: Function,
};

const ExternalBankingButtons = ({
  handleActionButton,
  isPlaidButtonLoading,
  setIsPlaidButtonLoading,
  addAccountProps,
  buttonStyles,
  helperFunction,
  linkedAccountsDrawerOnClose,
}: ExternalBankingButtonsProps) => {
  return (
    <Stack spacing="8px">
      {/* External */}
      {/* AddAccount/AddAccountMobile is responsible for rendering the button which triggers open Plaid
       *** linked accounts drawer after successfully adding the account
       */}
      {isMobile ? (
        <AddAccountMobile
          {...{
            ...addAccountProps,
            isPlaidButtonLoading,
            setIsPlaidButtonLoading,
            helperFunction,
            linkedAccountsDrawerOnClose,
          }}
        />
      ) : (
        <AddAccount
          {...{
            ...addAccountProps,
            isPlaidButtonLoading,
            setIsPlaidButtonLoading,
            helperFunction,
            linkedAccountsDrawerOnClose,
          }}
        />
      )}
      {/* Manual */}
      <AddManualAccountButton {...{ handleActionButton, buttonStyles }} />
    </Stack>
  );
};

ExternalBankingButtons.defaultProps = {
  addAccountProps: {},
  buttonStyles: {},
  helperFunction: () => {},
  linkedAccountsDrawerOnClose: () => {},
};

export default ExternalBankingButtons;
