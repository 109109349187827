import React from 'react';
import { Stack } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneSimpleProgressBar } from '@shared/components';
import { drawerHeaderStyles } from './styles/main.styles';

const DrawerHeaderWrapper = ({
  children,
  currentStep,
  totalSteps,
}: {
  children: any,
  currentStep: Number,
  totalSteps: Number,
}) => {
  const { isMinXL } = getBreakPoints();
  return (
    <Stack {...drawerHeaderStyles(isMinXL)}>
      <BaselaneSimpleProgressBar
        {...{
          step: currentStep,
          totalSteps,
        }}
      />
      {children}
    </Stack>
  );
};

export default DrawerHeaderWrapper;
