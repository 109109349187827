import React, { useMemo } from 'react';
import { Tooltip } from '@chakra-ui/react';

import getBreakPoints from '@core/utils/getBreakPoints';

import { tooltipStyle } from './styles/conditionalTooltip.style';

type BaselaneConditionalTooltipProps = {
  condition: Boolean,
  tooltipText: String,
  children: React.Node,
  isOpen?: Boolean,
  styles?: Object,
};

const BaselaneConditionalTooltip = ({
  condition,
  tooltipText,
  children,
  isOpen = null,
  styles = {},
}: BaselaneConditionalTooltipProps) => {
  const { isMax576: isMobileUI } = getBreakPoints();

  const hasTooltip = tooltipText && tooltipText?.length;

  // only use the controlled mode when in mobileUI
  const open = useMemo(
    () => ({
      ...(hasTooltip && isMobileUI ? { isOpen } : {}),
    }),
    [hasTooltip, isMobileUI, isOpen]
  );

  return condition ? (
    <Tooltip
      hasArrow
      label={tooltipText}
      placement="top-start"
      {...open}
      {...{ ...tooltipStyle, ...styles }}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};

export default BaselaneConditionalTooltip;
