import { bodyBase } from '@core/theme/text';

export const radioLabelStyles = {
  ...bodyBase.sm,
  // the color is set in the theme file /src/themeHabitat/component/radio.js
};

export const radioDescriptionStyles = {
  ...bodyBase.xs,
  color: 'brand.neutral.600',
};
