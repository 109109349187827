import customTheme from '@core/theme';

export const emptyStateContainerStyles = {
  h: 'auto',
  m: {
    lg: '0 !important',
    md: '0 32px 0 32px !important',
    sm: '0 20px 0 20px !important',
    base: '0 16px 0 16px !important',
  },
  borderRadius: '8px',
  boxShadow: '1px 2px 6px rgba(3, 24, 64, 0.04)',
  spacing: 0,
};

export const leftElementContainerStyles = {
  minWidth: { '2lg': '408px', base: 'none' },
  width: { md: '50%', base: 'auto' },
  borderTopLeftRadius: { '2lg': '8px', md: '8px', base: '8px' },
  borderTopRightRadius: { md: '0', base: '8px' },
  borderBottomLeftRadius: { md: '8px', base: '0' },
  backgroundColor: 'brand.neutral.white',
  p: { lg: '24px 32px', '2xs': '24px', base: '16px' },
  spacing: '24px',
};

export const headingTextStyles = {
  textStyle: 'headline-lg',
  color: customTheme.colors.brand.neutral['700'],
};

export const subtextStyles = {
  textStyle: 'sm',
  color: customTheme.colors.brand.neutral['600'],
};

export const rightElementContainerStyles = {
  width: { md: '50%', base: 'auto' },
  borderTopRightRadius: { '2lg': '0', md: '8px', base: '0' },
  borderBottomRightRadius: { '2lg': '0', base: '8px' },
  borderBottomLeftRadius: { md: 0, base: '8px' },
  backgroundColor: customTheme.colors.brand.blue['100'],
  p: { lg: '24px 32px', '2xs': '24px', base: '16px' },
};

export const imageContainerStyles = {
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  justifyContent: 'center',
  backgroundColor: customTheme.colors.brand.blue['100'],
  padding: '24px 24px 9px 0px',
  display: { '2lg': 'flex', base: 'none' },
  minHeight: '100%',
};
