import process from 'process';
import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { logoutOnClick } from '@core/components/NavigationAuth/helpers/auth.helpers';

export const INACTIVITY_TIMEOUT_DELAY_DEFAULT_SECONDS = 60 * 45; // fourty-five minutes

const monitoredEvents = [
  { target: window, event: 'mousemove' },
  { target: window, event: 'keydown' },
  { target: window, event: 'click' },
  { target: window, event: 'scroll' },
  { target: window, event: 'touchstart' },
  { target: window.document, event: 'visibilitychange' },
];

const InactivityTimeout = () => {
  const inactivityTimeoutDelay =
    (process?.env?.REACT_APP_INACTIVITY_TIMEOUT_DELAY_SECONDS ||
      INACTIVITY_TIMEOUT_DELAY_DEFAULT_SECONDS) * 1000;

  const timerId = useRef();

  const timesUp = () => {
    logoutOnClick(true);
  };

  const restartTimer = () => {
    timerId?.current && clearTimeout(timerId.current);
    timerId.current = setTimeout(timesUp, inactivityTimeoutDelay);
  };

  useEffect(() => {
    restartTimer();

    monitoredEvents.forEach(({ target, event }) => {
      target.addEventListener(event, restartTimer);
    });

    return () => {
      monitoredEvents.forEach(({ target, event }) => {
        target.removeEventListener(event, restartTimer);
      });
    };
  }, []);

  return <Outlet />;
};

InactivityTimeout.defaultProps = {};

export default InactivityTimeout;
