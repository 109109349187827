// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { BaselaneHeaderCard } from '@shared/components';
import type { PresentationKPIsByProperty } from './types';
import KPIsSummaryHeader from './KPIsSummaryHeader';
import KPIsSummaryContent from './KPIsSummaryContent';
import type { PresentationData } from './types/Data';

const KPI = Object.freeze({
  PurchasePrice: 'purchasePrice',
  Appreciation: 'appreciation',
  MarketValue: 'assetValue',
});

const KpiNameMap = Object.freeze({
  [KPI.PurchasePrice]: 'Purchase Price',
  [KPI.Appreciation]: 'Appreciation',
  [KPI.MarketValue]: 'Market Value',
});

function getDataItem(data: PresentationData[], name: string): any {
  return data.find((dataItem) => dataItem.name === name);
}

function AssetValueSummaryWrapper({
  presentationKPIsByProperty,
}: {
  presentationKPIsByProperty: PresentationKPIsByProperty,
}): any {
  const { property, data } = presentationKPIsByProperty;

  const { name = '', address = {} } = property || {};

  const purchasePriceData = getDataItem(data, KPI.PurchasePrice);

  const appreciationData = getDataItem(data, KPI.Appreciation);

  const marketValueData = getDataItem(data, KPI.MarketValue);

  const isPurchasePriceSmaller =
    purchasePriceData.percentageValue < marketValueData.percentageValue;

  const appreciationBarLeft = isPurchasePriceSmaller
    ? purchasePriceData.percentageValue
    : marketValueData.percentageValue;

  const hasAppreciation =
    purchasePriceData.value !== 0 &&
    marketValueData.value !== 0 &&
    purchasePriceData.value !== marketValueData.value;

  return (
    <BaselaneHeaderCard
      headerLeft={<KPIsSummaryHeader name={name} address={address} />}
      containerStyles={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'brand.darkBlue.150',
        m: '0',
        boxShadow: 'none',
      }}
    >
      <Box color="brand.neutral.700">
        {/* Purchase Price */}
        <KPIsSummaryContent
          key={purchasePriceData.name}
          name={KpiNameMap[purchasePriceData.name]}
          amount={purchasePriceData.value}
          value={purchasePriceData.percentageValue}
          hasBorder={hasAppreciation}
        />
        {/* Appreciation */}
        <KPIsSummaryContent
          key={appreciationData.name}
          name={KpiNameMap[appreciationData.name]}
          amount={appreciationData.amount}
          color="brand.blue.250"
          left={appreciationBarLeft}
          value={appreciationData.percentageValue}
          hasBorder={isPurchasePriceSmaller}
          borderMargin={purchasePriceData.percentageValue}
        />
        {/* Market Value */}
        <KPIsSummaryContent
          key={marketValueData.name}
          name={KpiNameMap[marketValueData.name]}
          amount={marketValueData.value}
          value={marketValueData.percentageValue}
          hasBorder={hasAppreciation}
        />
      </Box>
    </BaselaneHeaderCard>
  );
}

export default AssetValueSummaryWrapper;
