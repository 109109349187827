import process from 'process';
import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ChakraProvider,
} from '@chakra-ui/react';

import { BaselaneButton, BaselaneButtonGroup, BaselaneRadioCardList } from '@shared/components';
import { sortWithString } from '@core/utils/sort';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserContext from '@contexts/UserContext';
import { GET_PROPERTIES_INSURANCE } from '@core/apollo/queries';
import habitatTheme from '@core/themeHabitat';
import { Icon16PlusCircle } from '@icons/16px';
import AddEditProperty from '@pages/PropertiesPage/AddEditProperty';
import MobileAddEditProperty from '@pages/PropertiesResponsivePage/AddEditProperty';

import EmptyState from './EmptyState';
import InfoCard from '../InfoCard';
import LoadingView from './LoadingView';
import { modalTextContent, formatAddress, readFragment } from './helpers/obieModal.helpers';
import { obieModalStyles } from './styles/obieModal.styles';

type ObieModalProps = {
  size: String,
  variant: String,
  palette: String,
  isFullWidth: boolean,
  leftIcon: Any,
  btnText: String,
};

function ObieModal({ size, variant, palette, isFullWidth, leftIcon, btnText }: ObieModalProps) {
  const { pathname } = window.location;
  const url = pathname;

  // styles
  const {
    modalTitleStyles,
    modalBodyStyles,
    modalSubtitleStyles,
    modalContentStyles,
    modalFooter,
  } = obieModalStyles ?? {};

  const { user, utm } = useContext(UserContext);
  const { cache } = useApolloClient();
  const { irClickId } = utm?.signUp || {};

  // data GET_PROPERTIES_DATA
  const { loading: propertiesLoading, data: propertiesData } = useQuery(GET_PROPERTIES_INSURANCE);
  const properties = propertiesData?.property || [];
  const sortedProperties = properties && sortWithString(properties, 'name');

  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [selectedProperty, setSelectedProperty] = useState();
  const [loadingObj, setLoadingObj] = useState({
    showLoading: false,
    caption: '',
  });

  // Helper Functions
  const showLoader = ({ caption }) => {
    setLoadingObj({ showLoading: true, caption });
  };

  const hideLoader = () => {
    setLoadingObj({ showLoading: false, caption: '' });
  };

  const cleanup = () => {
    setSelectedProperty(false);
    setSearch('');
  };

  const startQuoteFromObie = () => {
    setShowModal(true);
  };

  const onCloseBtnClick = () => {
    setShowModal(false);
    cleanup();
  };

  const handlePropertyClick = (property) => {
    const { id } = property ?? {};
    setSelectedProperty(id);
  };

  useEffect(() => {
    if (propertiesLoading) {
      showLoader({ caption: 'Loading...' });
    } else {
      hideLoader();
    }
  }, [propertiesLoading]);

  const formattedProperties = sortedProperties?.map((property) => {
    const { address: addressObj } = property ?? {};
    const propertyAddress = formatAddress(addressObj);

    return { ...property, propertyAddress };
  });

  const onOpenObiePopup = () => {
    setShowModal(false);
    const propertyObj = sortedProperties?.find((property) => property?.id === selectedProperty);
    const { address } = propertyObj ?? '';

    const cachedEstimate = readFragment(cache, propertyObj);
    const defaultInsuranceText = 'You can save up to 25% in 2-3 mins';
    const initialEstimate = cachedEstimate?.insurance?.estimate || defaultInsuranceText;
    const estimatedInsurance = initialEstimate;

    const segmentEstimatedInsuranceValue = estimatedInsurance.includes('Year')
      ? estimatedInsurance.substring(1, estimatedInsurance.length - 5)
      : null;

    sendSegmentEvent('obie_quote_started', {
      title: document.title,
      in_app: true,
      url,
      landlord_uuid: user?.id,
      property_uuid: propertyObj.id ?? null,
      estimatedInsurance: segmentEstimatedInsuranceValue,
      property_type: propertyObj?.type ?? null,
      property_state: address?.state ?? null,
      property_city: address?.city ?? null,
      property_insurance: propertyObj?.annualPropertyInsurance ?? null,
    });

    const sandbox =
      process?.env?.REACT_APP_NODE_ENV === 'development' ||
      process?.env?.REACT_APP_NODE_ENV === 'develop' ||
      process?.env?.REACT_APP_NODE_ENV === 'uat';
    const partnerId = process.env.REACT_APP_PARTNER_ID_KEY; // partnerId for dev environment provided by obie
    window.Obie.open({
      partnerId,
      sandbox,
      values: {
        person: {
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
        property: {
          addressLine1: address?.address,
          city: address?.city,
          state: address?.state,
          postalCode: address?.zipCode,
        },
      },
      metadata: {
        userId: user?.id,
        email: user?.email,
        uniqueCode: user?.gclid,
      },
      onQuoteCreated: () => {
        sendSegmentEvent('obie_quote_created', {
          title: document.title,
          url,
          in_app: true,
          partnerId,
          irClickId,
        });
      },
    });
  };

  const propertiesList = loadingObj?.showLoading ? (
    <LoadingView {...{ loadingObj }} />
  ) : (
    <>
      <ChakraProvider theme={habitatTheme}>
        <BaselaneRadioCardList
          {...{
            list: formattedProperties,
            onListItemClick: handlePropertyClick,
            searchBy: 'name',
            showValueBy: 'propertyAddress',
            selectedListItem: selectedProperty,
            name: 'Property',
            search,
            setSearch,
          }}
        />
      </ChakraProvider>
      {isMobile ? (
        <MobileAddEditProperty
          buttonStyles={{ mt: '12px' }}
          variant="transparent"
          palette="primary"
          leftIcon={<Icon16PlusCircle />}
        />
      ) : (
        <AddEditProperty from={{ page: 'insurance' }} />
      )}

      <InfoCard
        icon={modalTextContent?.infoCard?.icon}
        title={modalTextContent?.infoCard?.title}
        description={modalTextContent?.infoCard?.description}
      />
    </>
  );

  return (
    <>
      <BaselaneButton
        size={size}
        variant={variant}
        palette={palette}
        leftIcon={leftIcon}
        mt="0!important"
        isFullWidth={isFullWidth}
        onClick={startQuoteFromObie}
      >
        {btnText}
      </BaselaneButton>
      <Modal isCentered isOpen={showModal} onClose={onCloseBtnClick} padding="0" size="lg">
        <ModalOverlay />
        <ModalContent {...modalContentStyles}>
          <ModalHeader p={0}>
            <Text {...modalTitleStyles}>{modalTextContent.title}</Text>
            <Text {...modalSubtitleStyles}>{modalTextContent.subtitle}</Text>
          </ModalHeader>

          <ModalCloseButton mt={0.5} />
          <ModalBody {...modalBodyStyles}>
            {sortedProperties?.length === 0 ? <EmptyState /> : propertiesList}
          </ModalBody>
          <ModalFooter {...modalFooter}>
            <BaselaneButtonGroup size="md">
              <BaselaneButton
                size="md"
                variant="outline"
                palette="neutral"
                onClick={onCloseBtnClick}
              >
                {modalTextContent.cancelBtnText}
              </BaselaneButton>
              <BaselaneButton
                size="md"
                variant="filled"
                palette="primary"
                onClick={onOpenObiePopup}
                isDisabled={!selectedProperty}
              >
                {modalTextContent.startQuoteBtnText}
              </BaselaneButton>
            </BaselaneButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ObieModal;
