import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Stack, Text } from '@chakra-ui/react';
import {
  indicatorContainerStyles,
  indicatorStyles,
  stepTextContainerStyles,
  stepTextStyles,
} from '../../styles/stepIndicator.styles';

type StepIndicatorProps = {
  isStepOneActive?: boolean,
  isNextStepActive?: boolean,
  isFinalStepActive?: boolean,
  indicatorStatus?: string,
  stepText: string,
  stepTextHeading?: string,
};

const StepIndicator = ({
  isStepOneActive,
  isNextStepActive,
  isFinalStepActive,
  indicatorStatus,
  stepText,
  stepTextHeading,
}: StepIndicatorProps) => {
  const isActiveArray = [isStepOneActive, isNextStepActive, isFinalStepActive];
  return (
    <>
      <Stack direction="row" {...indicatorContainerStyles}>
        {isActiveArray.map((isActive, index) => (
          <Box key={uuidv4()} {...indicatorStyles(indicatorStatus, isActiveArray, index)} />
        ))}
      </Stack>
      <Stack direction="row" {...stepTextContainerStyles}>
        <Text {...stepTextStyles(indicatorStatus)}>{stepText}</Text>
        <Text {...stepTextStyles('heading')}>{stepTextHeading}</Text>
      </Stack>
    </>
  );
};

StepIndicator.defaultProps = {
  isStepOneActive: true,
  isNextStepActive: false,
  isFinalStepActive: false,
  indicatorStatus: 'active',
  stepTextHeading: '',
};

export default StepIndicator;
