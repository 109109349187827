export const stepContainerStyles = {
  p: '0 8px',
  alignItems: 'center',
  m: '0 !important',
  mb: '12px !important',
};

export const inProgStepContainerStyles = (status) => {
  const borderColor = 'transparent';
  let bg = 'brand.darkBlue.100';
  let hover = {
    bg: status.linkText && 'brand.blue.100',
    borderColor: status.linkText && 'brand.blue.800A',
  };

  switch (status?.state) {
    case 'warning':
      bg = 'yellow.100';
      hover = {
        bg: 'yellow.100',
        borderColor: 'yellow.700',
      };

      break;

    case 'alert':
      bg = 'red.200';
      hover = {
        bg: 'red.200',
        borderColor: 'red.800AA',
      };
      break;

    default:
      break;
  }
  return {
    borderRadius: '4px',
    border: '1px solid transparent',
    borderColor,
    bg,
    p: '12px 7px',
    alignItems: 'flex-start',
    m: '0 !important',
    mb: '12px !important',
    cursor: status.linkText && 'pointer',
    _hover: {
      ...hover,
    },
  };
};

export const stepIconContainerStyles = {
  minW: '16px',
  minH: '20px',
  alignItems: 'center',
  justifyContent: 'center',
};

export const stepTitleStyles = {
  ml: '6px !important',
  display: 'flex',
  flexDirection: 'column',
};

export const stepTextStyles = {
  textStyle: 'headline-xs',
  color: 'brand.blue.700',
};

export const completedStepTextStyles = {
  ...stepTextStyles,
  color: 'brand.neutral.700',
};

export const inactiveStepTextStyles = {
  ...stepTextStyles,
  color: 'brand.neutral.700',
  opacity: '0.5',
};

export const stepDescriptionTextStyles = {
  fontSize: '3xs',
  lineHeight: '14px',
  color: 'brand.neutral.700',
  my: '2px',
};

export const stepCircularProgressStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const stepCircularProgressLabelStyles = {
  w: '13px',
  h: '13px',
  borderRadius: '100%',
  bg: 'brand.neutral.white',
};

export const rightChevronContainerStyles = {
  ml: '8px !important',
  mr: '3px !important',
  alignSelf: 'center',
};
