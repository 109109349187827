import React from 'react';
import { Box, Stack, HStack, Text } from '@chakra-ui/react';
import { IconCheckCircleNoPadding } from '@icons';
import {
  stepContainerStyles,
  stepIconContainerStyles,
  stepTitleStyles,
  completedStepTextStyles,
} from '../../styles/steps.styles';

type CompletedProps = {
  status: Object,
};

const Completed = ({ status }: CompletedProps) => {
  return (
    <HStack {...stepContainerStyles}>
      <Stack {...stepIconContainerStyles}>
        <IconCheckCircleNoPadding />
      </Stack>
      <Box {...stepTitleStyles}>
        <Text {...completedStepTextStyles}>{status?.title}</Text>
      </Box>
    </HStack>
  );
};

export default Completed;
