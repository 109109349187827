export const backButtonStyles = {
  flex: '0 0 auto',
  _hover: { bg: 'brand.darkBlue.100' },
  _active: { bg: 'brand.darkBlue.100' },
};

export const largeTextStyles = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
};

export const headerRightStyles = {
  totalBalance: {
    label: {
      textStyle: 'xs',
      color: 'brand.neutral.700',
    },
    value: {
      textStyle: 'data-xl',
    },
  },
};
