import React from 'react';

import SuccessWrapper from '../SuccessWrapper';
import BankingFirstSuccessUI from './BankingFirstSuccessUI';

const BankingFirstSuccessFlow = () => {
  return <SuccessWrapper ChildComponent={BankingFirstSuccessUI} />;
};

export default BankingFirstSuccessFlow;
