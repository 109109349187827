import React from 'react';
import { Box, FormControl, FormErrorMessage, FormLabel, Text } from '@chakra-ui/react';
import { formErrorStyles, formLabelStyles } from '@shared/styles/input.style';
import { BaselaneDropdown } from '@shared/components';
import { Icon12Error } from '@icons/12px/index';

import { businessVerticalOptions } from './helpers/businessInformation.helpers';

const itemRenderer = ({ item }: { item: string }) => (
  <Text id={`${item.id}-text`}>{item.name}</Text>
);

type IndustrySelectProps = {
  errors: Object,
  touched: Object,
  values: Object,
  setFieldTouched: Function,
  setFieldValue: Function,
};

const IndustrySelect = ({
  errors,
  touched,
  values,
  setFieldTouched,
  setFieldValue,
}: IndustrySelectProps) => {
  const baseClassNames = ['input-form-xl', 'fontSize-sm', 'auto-width-dropdown'];
  const classNames =
    errors.businessVertical && touched.businessVertical
      ? [...baseClassNames, 'input-invalid']
      : baseClassNames;

  return (
    <FormControl isRequired isInvalid={errors.businessVertical && touched.businessVertical}>
      <FormLabel htmlFor="businessVertical" {...formLabelStyles.xs}>
        Industry
      </FormLabel>
      <BaselaneDropdown
        {...{
          classNames,
          type: 'tier1',
          data: businessVerticalOptions,
          title: 'Industry',
          placeholder: 'Select Industry',
          parentId: 'landlord-banking-panels',
          showValueByFields: ['name'],
          selectedItem:
            values.businessVertical === ''
              ? null
              : businessVerticalOptions.find((option) => option.id === values?.businessVertical),
          itemRenderer,
          handleSubmit: (businessVertical) => {
            setFieldTouched('businessVertical', true);
            setFieldValue('businessVertical', businessVertical);
          },
          hasDropdownClearedExternally: false,
          setHasDropdownClearedExternally: false,
          additionalProps: { id: 'industry-dropdown' },
        }}
      />
      <FormErrorMessage {...formErrorStyles} ml={0}>
        <Box as="span" mr={0.5}>
          <Icon12Error />
        </Box>
        {errors.businessVertical}
      </FormErrorMessage>
    </FormControl>
  );
};

export default IndustrySelect;
