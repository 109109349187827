import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useShallow } from 'zustand/react/shallow';
import { FormControl } from '@chakra-ui/react';

import {
  T1WithTitleDropdown,
  BaselaneFormLabel,
  BaselaneFormErrorMessage,
} from '@shared/components';
import useCheckbookStore from '@store/Checkbook';
import CustomDropdownDisplay from '../CustomDropdownDisplay';
import {
  accountItemRenderer,
  getCheckbookAccounts,
  getSelectedAccount,
} from '../helpers/accountItem.helper';
import { formErrorStyles } from '../styles/form.styles';

const SelectAccount = () => {
  const { account } = useOutletContext() ?? {};
  const { setValues, values } = useFormikContext();
  const accountData = getCheckbookAccounts(account);
  const [setSelectedAccount, selectedAccount] = useCheckbookStore(
    useShallow((state) => [state.setSelectedAccount, state.selectedAccount])
  );

  const handleSelection = (selectedValue) => {
    const selectedItem = getSelectedAccount(accountData, selectedValue);
    setSelectedAccount(selectedItem);
    setValues({
      ...values,
      bankAccountId: selectedValue,
      address: account.mailingAddress.street,
      unit: account.mailingAddress.unit,
      state: account.mailingAddress.state,
      zipcode: account.mailingAddress.postalCode,
      city: account.mailingAddress.city,
      country: account.mailingAddress.country,
    });
  };

  return (
    <FormControl isInvalid={!accountData || !accountData.length}>
      <BaselaneFormLabel
        htmlFor="checkbook-account-dropdown"
        tooltipText="You can't reorder checks for accounts for which you've ordered a checkbook in the last 15 days."
        textStyle="xs"
      >
        Order checkbook for
      </BaselaneFormLabel>
      <T1WithTitleDropdown
        hideSearch
        data={accountData}
        showValueByFields={['nickName', 'availableBalance']}
        placeholder="Select account"
        classNames={['input-form-lg', 'is-full-width', 'auto-width-dropdown']}
        dropdownPosition="bottom"
        itemRenderer={accountItemRenderer}
        selectedItem={selectedAccount}
        handleSubmit={(value) => handleSelection(value)}
        additionalProps={{ id: 'checkbook-account-dropdown' }}
        isDisabled={!accountData || !accountData.length}
        CustomDisplayInput={CustomDropdownDisplay}
      />
      <BaselaneFormErrorMessage
        {...formErrorStyles}
        alignItems="flex-start"
        sx={{ span: { mt: 0.25 } }}
      >
        No accounts available to order checkbooks at the moment. If you ordered a checkbook
        recently, you can reorder for the same account after 15 days.
      </BaselaneFormErrorMessage>
    </FormControl>
  );
};

export default SelectAccount;
