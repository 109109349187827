import { gql } from '@apollo/client';

// Fragments
export const FRAGMENT_TENANTS_CONTENTS = gql`
  fragment TenantsContentsObj on TenantProfile {
    id
    firstName
    lastName
    phoneNumber
    email
    status
    hasPaymentMethod
  }
`;

export const GET_TENANT_ACTIVE_PROFILES = gql`
  ${FRAGMENT_TENANTS_CONTENTS}
  query activeTenantProfiles {
    landLordTenantSummary {
      activeTenants {
        ...TenantsContentsObj
      }
    }
  }
`;

// TODO: come back to clean up tenant profile queries
export const GET_TENANT_PROFILE = gql`
  query TenantProfile {
    landlordTenant {
      id
      firstName
      lastName
      phoneNumber
      email
      status
      hasPaymentMethod
    }
  }
`;
