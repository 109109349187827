import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import BaselaneCard from '../BaselaneCard/BaselaneCard';
import icons from '../icons';
import { backgroundColors, darkBackgroundColors, fontColors } from '../colors';
import {
  cardStyles,
  contentBodyStyles,
  contentContainerStyles,
  contentStyles,
  contentTitleStyles,
  iconBackgroundStyles,
} from './styles/baselaneBanner.styles';

type BaselaneBannerProps = () => {
  title: string,
  body: any,
  rightElement: any,
  iconName: string,
  containerStyles?: Object,
};

const bannerStyles = (color) => {
  const textColor = color === 'yellow' ? 'warningyellow' : color;

  const iconBackgroundColor = darkBackgroundColors[color];
  const iconColor = 'white';
  const titleColor = fontColors[textColor];
  const bannerBackground = backgroundColors[color];
  const bannerBorder = darkBackgroundColors[color];

  return { iconBackgroundColor, iconColor, titleColor, bannerBackground, bannerBorder };
};

const BaselaneBanner = ({
  title,
  body,
  rightElement,
  iconName = 'info',
  color = 'green',
  containerStyles: cStyles,
}: BaselaneBannerProps) => {
  const Icon = icons[iconName];

  const {
    iconBackgroundColor,
    iconColor,
    titleColor,
    bannerBackground,
    bannerBorder,
  } = bannerStyles(color);

  return (
    <BaselaneCard styles={{ ...cardStyles(bannerBorder), ...cStyles }}>
      <Stack direction="row" spacing={0}>
        <Stack {...iconBackgroundStyles(iconBackgroundColor)}>
          <Icon color={iconColor} w={14} h={14} />
        </Stack>
        <Stack direction="row" {...contentContainerStyles(bannerBackground)}>
          <Box {...contentStyles}>
            <Box {...contentTitleStyles(titleColor)}>{title}</Box>
            <Box {...contentBodyStyles}>{body}</Box>
          </Box>
          {rightElement}
        </Stack>
      </Stack>
    </BaselaneCard>
  );
};

BaselaneBanner.defaultProps = {
  containerStyles: {},
};

export default BaselaneBanner;
