import { useFormikContext } from 'formik';
import useBankAccountsActive from '@hooks/useBankAccountsActive';

const useTransferFromAccountSubmit = ({ updateLinkToken }) => {
  const { setFieldValue, setFieldTouched, validateForm } = useFormikContext();

  const { bankAccountsById } = useBankAccountsActive({
    filterBySubType: ['checking', 'savings'],
  });

  const submitValue = async (value) => {
    // Safeguarding the value.
    const safeValue = Array.isArray(value) ? '' : value;

    // Manually setting the value of the field, marking it as touched, and validating the form.
    await setFieldValue('depositToAccountId', safeValue, true);
    await setFieldTouched('depositToAccountId', true);
    await validateForm();

    const selectedAccountIsExternal = bankAccountsById[safeValue]?.isExternal;

    const selectedAccountIsDisconnected =
      bankAccountsById[safeValue]?.errorCodes?.isAccountDisconnected;

    if (selectedAccountIsExternal && selectedAccountIsDisconnected) {
      const params = {
        variables: {
          bankAccountId: safeValue,
        },
        skip: !safeValue,
      };

      updateLinkToken(params);
    }
  };

  const resetValue = () => {
    setFieldValue('depositToAccountId', null);
    setFieldTouched('depositToAccountId', false);
  };

  return { submitValue, resetValue };
};

export default useTransferFromAccountSubmit;
