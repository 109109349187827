import spacing from '@core/theme/spacing';

export const getIconMarginValue = (size) => {
  const sizeMap = {
    sm: '1.5',
    md: '2',
    lg: '3',
  };
  return `${spacing.space[sizeMap[size]]}`;
};
