import React from 'react';

type IconSmallVenmoLogoProps = {
  w?: Number,
  h?: Number,
};

function IconSmallVenmoLogo({ w = 16, h = 16 }: IconSmallVenmoLogoProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9887 0c.9032 1.4408 1.3104 2.92483 1.3104 4.79947 0 5.97913-5.2846 13.74643-9.5736 19.20053H3.92895L0 1.30911 8.5779.522526 10.6552 16.6686c1.941-3.0541 4.3361-7.85355 4.3361-11.12578 0-1.79109-.3176-3.01106-.8141-4.01557L21.9887 0Z"
        fill="#008CFF"
      />
    </svg>
  );
}

IconSmallVenmoLogo.defaultProps = {
  w: 16,
  h: 16,
};

export default IconSmallVenmoLogo;
