import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { HStack } from '@chakra-ui/react';
import { DatePickerModal, DatePickerBase } from '../components';
import { DisplayInputDefault } from '../DisplayInputVariations';
import BaselaneDivider from '../../BaselaneDivider';
import {
  checkIsCurrentMonthYear,
  showYearMonthIntoView,
  handleDefaultArrowKeyDown,
} from '../helpers/shared.helpers';
import { datePickerStyles } from '../styles/datePicker.styles';
import {
  BaselaneRangeDatePickerProps,
  BaselaneSharedDatePickerProps,
} from '../types/datePicker.types';

const BaselaneRangeDatePicker = ({
  startDate,
  endDate,
  isOpen,
  onOpen,
  onClose,
  years,
  startDatePickerRef,
  endDatePickerRef,
  placement,
  placeholder,
  portalId,
  minDate,
  maxDate,
  isDisabled,
  calendarClassName,
  className,
  size,
  value,
  dateFormat,
  hideInputIcon,
  showInModal,
  handleChange,
  handleCalendarClose,
  handleKeyDown,
  handleFocus,
  modalTitle,
  hideTodayButton,
  CustomDisplayInput,
  customDisplayInputProps,
  ...rest
}: BaselaneRangeDatePickerProps & BaselaneSharedDatePickerProps) => {
  const startDateRef = useRef();
  const endDateRef = useRef();
  const startRef = startDatePickerRef ?? startDateRef;
  const endRef = endDatePickerRef ?? endDateRef;

  const [showMonthYearSelector, setShowMonthYearSelector] = useState(false);
  const [showTodayButton, setShowTodayButton] = useState(!hideTodayButton);
  const [sDate, setStartDate] = useState(startDate);
  const [eDate, setEndDate] = useState(endDate);
  const [isChangeFromCalendar, setIsChangeFromCalendar] = useState(false);

  const handleBackToTodayClick = (isStartDate) => {
    if (isStartDate) {
      startRef.current?.calendar?.instanceRef.changeMonthYear(new Date());
    } else {
      endRef.current?.calendar?.instanceRef.changeMonthYear(new Date());
    }
  };

  const onChange = (val, isStartDate, e) => {
    const isTriggeredFromCalendar = e.target.classList.contains('react-datepicker__day');

    showYearMonthIntoView({ showMonthYearSelector });
    setIsChangeFromCalendar(isTriggeredFromCalendar);

    if (isStartDate) {
      setStartDate(val);
    } else {
      setEndDate(val);
    }

    if (handleChange) {
      handleChange(val);
    }
  };

  const onKeyDown = (e, isStartDate) => {
    const { key } = e;
    const ref = isStartDate ? startRef : endRef;
    const { preSelection: focusedDate, minDate: minD, maxDate: maxD } =
      ref.current?.calendar?.props ?? {};

    // NOTE: React-datepicker does not trigger monthChange on keydown navigation
    // Below code is needed to show/hide today button + calculating popper position
    if (key === 'ArrowLeft' || key === 'ArrowRight' || key === 'ArrowUp' || key === 'ArrowDown') {
      const newFocusedDate = focusedDate ? new Date(focusedDate) : sDate;

      if (focusedDate) {
        handleDefaultArrowKeyDown({ key, dateOnFocus: newFocusedDate });
      }

      const formattedFocusedD = moment(newFocusedDate).startOf('day').toISOString();
      const formattedMinD = moment(minD).startOf('day').toISOString();
      const formattedMaxD = moment(maxD).startOf('day').toISOString();

      const isDateLessThanMaxD = formattedFocusedD <= formattedMaxD;
      const isDateGreaterThanMinD = formattedFocusedD >= formattedMinD;
      const isValidDate = isStartDate ? isDateLessThanMaxD : isDateGreaterThanMinD;

      if (isValidDate && !hideTodayButton) {
        checkIsCurrentMonthYear({
          dateToBeChecked: newFocusedDate,
          setShowTodayButton,
        });
      }
    }

    if (key === 'Escape') {
      ref.current?.setBlur(false);
      ref.current?.setFocus(false);
    }

    if (handleKeyDown) {
      handleKeyDown(e);
    }
  };

  const onFocus = (e) => {
    if (handleFocus) {
      handleFocus(e);
    }
  };

  const onBlur = (e, isStartDate) => {
    const ref = isStartDate ? startRef : endRef;
    ref.current?.setOpen(true);
  };

  const onMonthChange = (val) => {
    if (!hideTodayButton) {
      checkIsCurrentMonthYear({
        dateToBeChecked: val,
        setShowTodayButton,
      });
    }
  };

  const onYearChange = (val) => {
    if (!hideTodayButton) {
      checkIsCurrentMonthYear({
        dateToBeChecked: val,
        setShowTodayButton,
      });
    }
  };

  const onCalendarOpen = (dateToBeChecked) => {
    if (!hideTodayButton) {
      checkIsCurrentMonthYear({
        dateToBeChecked,
        setShowTodayButton,
      });
    }
  };

  const onCalendarClose = () => {
    setShowMonthYearSelector(false);

    if (handleCalendarClose && !showInModal) {
      handleCalendarClose({ startDate: sDate, endDate: eDate });
    }
  };

  const onCalendarCloseAndApplyInModal = () => {
    setShowMonthYearSelector(false);

    if (handleCalendarClose) {
      handleCalendarClose({ startDate: sDate, endDate: eDate, isFromApplyButton: true });
    }
  };

  const onModalClose = () => {
    setStartDate(startDate);
    setEndDate(endDate);
    onClose();
  };

  const onModalApplyAndClose = () => {
    onClose();
    onCalendarCloseAndApplyInModal();
  };

  const handleClearAndApply = () => {
    handleCalendarClose({ startDate: null, endDate: null, isFromApplyButton: true });
  };

  const { inputdivider, inputcontainer } = datePickerStyles ?? {};

  const getPopperProps = (isStartDate) => {
    return {
      popperPlacement: isStartDate ? placement : 'top-end',
    };
  };

  const sharedProps = {
    portalId: null,
    placeholderText: placeholder,
    onFocus,
    onMonthChange,
    onYearChange,
    onCalendarClose,
    startDate: sDate,
    endDate: eDate,
    fixedHeight: true,
    autoComplete: 'off',
    disabled: isDisabled,
    shouldCloseOnSelect: false,
    years,
    dateFormat,
    hideInputIcon,
    showInModal,
    showMonthYearSelector,
    setShowMonthYearSelector,
    showTodayButton,
    setShowTodayButton,
    hideTodayButton,
    className,
    calendarClassName: `baselane-datepicker-calendar ${
      showInModal ? 'range-datepicker-modal-calendar' : ''
    } ${calendarClassName}`,
  };

  const startDateProps = {
    size,
    selected: sDate,
    pickerRef: startRef,
    minDate,
    maxDate: eDate,
    onKeyDown: (e) => onKeyDown(e, true),
    onChange: (val, e) => onChange(val, true, e),
    onSelect: (val, e) => onChange(val, true, e),
    onClickOutside: () => startRef.current?.setOpen(showInModal),
    onCalendarOpen: () => onCalendarOpen(sDate),
    onInputClick: () => {
      endRef.current?.setOpen(false);
      startRef.current?.setOpen(true);
    },
    handleBackToTodayClick: () => handleBackToTodayClick(true),
    selectsStart: true,
    ...sharedProps,
    ...rest,
  };

  const endDateProps = {
    size,
    selected: eDate,
    pickerRef: endRef,
    minDate: sDate,
    maxDate,
    onKeyDown: (e) => onKeyDown(e, false),
    onChange: (val, e) => onChange(val, false, e),
    onSelect: (val, e) => onChange(val, false, e),
    onClickOutside: () => endRef.current?.setOpen(showInModal),
    onCalendarOpen: () => onCalendarOpen(eDate),
    onInputClick: () => {
      startRef.current?.setOpen(false);
      endRef.current?.setOpen(true);
    },
    handleBackToTodayClick: () => handleBackToTodayClick(false),
    dayClassName: (date) => {
      const formattedDate = moment(date).startOf('day').toISOString();
      const formattedSDate = moment(sDate).startOf('day').toISOString();
      return formattedDate === formattedSDate ? 'baselane-day-selected-start' : undefined;
    },
    selectsEnd: true,
    ...sharedProps,
    ...rest,
  };

  const displayInputProps = {
    onOpen,
    isDisabled,
  };

  useEffect(() => {
    setStartDate(startDate);
  }, [startDate]);

  useEffect(() => {
    setEndDate(endDate);
  }, [endDate]);

  useEffect(() => {
    if (showMonthYearSelector) {
      showYearMonthIntoView({ showMonthYearSelector });
    }
  }, [showMonthYearSelector]);

  useEffect(() => {
    // Sets focus on the end date as soon as the start date's selected
    if (sDate && isChangeFromCalendar) {
      startRef.current?.setOpen(false);
      endRef.current?.setOpen(true);
    }
  }, [sDate, isChangeFromCalendar]);

  useEffect(() => {
    // Sets focus on the start date as soon as the start date's selected
    if (eDate && isChangeFromCalendar && showInModal) {
      endRef.current?.setOpen(false);
      startRef.current?.setOpen(true);
    }
  }, [eDate, isChangeFromCalendar]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        startRef.current?.setOpen(true);
      }, 50);
    }
  }, [isOpen, startRef]);

  return showInModal ? (
    <>
      {CustomDisplayInput ? (
        <CustomDisplayInput
          {...{
            ...displayInputProps,
            ...customDisplayInputProps,
          }}
        />
      ) : (
        <DisplayInputDefault
          {...{
            ...displayInputProps,
            value,
            size,
            placeholder,
            className,
            onClick: onOpen,
            handleClearAndApply,
            hideInputIcon,
            readOnly: true,
          }}
        />
      )}
      <DatePickerModal
        {...{
          isOpen,
          isDisabled: (sDate && !eDate) || (!sDate && eDate),
          modalTitle,
          handleClose: onModalClose,
          handleApplyAndClose: onModalApplyAndClose,
        }}
      >
        <HStack px={2} py={0} m={0} gap={0}>
          <DatePickerBase
            {...{
              ...startDateProps,
              onBlur: (e) => onBlur(e, true),
              size: 'md',
              inputLabel: 'From',
              className: '',
            }}
          />
          <BaselaneDivider styles={inputdivider(showInModal)} />
          <DatePickerBase
            {...{
              ...endDateProps,
              onBlur: (e) => onBlur(e, false),
              size: 'md',
              inputLabel: 'To',
              className: '',
            }}
          />
        </HStack>
      </DatePickerModal>
    </>
  ) : (
    <HStack {...inputcontainer}>
      <DatePickerBase {...{ ...startDateProps, ...getPopperProps(true) }} />
      <BaselaneDivider styles={inputdivider(showInModal)} />
      <DatePickerBase
        {...{ ...endDateProps, ...getPopperProps(false), shouldCloseOnSelect: true }}
      />
    </HStack>
  );
};

BaselaneRangeDatePicker.defaultProps = {
  placement: 'top-start',
  portalId: 'root',
  years: {
    start: null,
    end: null,
  },
  placeholder: 'Select date',
  handleChange: () => {},
  handleCalendarClose: () => {},
  handleKeyDown: () => {},
  handleFocus: () => {},
  // setting min/maxDate to null disables arrow key navigation
  // seems to be a bug from the react-datepicker library
  // https://stackoverflow.com/questions/63558051/cant-make-keyboard-arrows-work-on-react-datepicker
  minDate: undefined,
  maxDate: undefined,
  isDisabled: false,
  startDatePickerRef: null,
  endDatePickerRef: null,
  className: '',
  calendarClassName: '',
  hideInputIcon: false,
  size: 'lg',
  value: '',
  showInModal: false,
  dateFormat: 'MMM dd, yyyy',
  modalTitle: 'Select custom range',
  hideTodayButton: false,
  CustomDisplayInput: null,
  customDisplayInputProps: {},
};

export default BaselaneRangeDatePicker;
