import React from 'react';
import {
  Box,
  useToast,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import SlLoader from '@core/components/Loader';
import { BaselaneAlertNew, BaselaneButton } from '@shared/components';
import { DELETE_PAYEE } from '@core/apollo/queries';
import * as ROUTES from '@routes';
import { Icon16Info } from '@core/components/Icons/16px';
import { handleCancel } from '../../helpers/navigation.helpers';

const RecipientDetailsDelete = () => {
  const location = useLocation() || {};
  const navigate = useNavigate();

  const { state } = location || {};
  const { from } = state || {};

  const isDeletingFromPaymentMethod =
    from?.includes(ROUTES.TRANSFERS_PAYMENT_METHOD_ACH) ||
    from?.includes(ROUTES.TRANSFERS_PAYMENT_METHOD_WIRE) ||
    from?.includes(ROUTES.TRANSFERS_PAYMENT_METHOD_CHECK);
  const { recipientId } = useParams();

  const toast = useToast();

  const showDeleteRecipientErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: `Error, recipient could not be deleted.`,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const [deletePayee, { loading: isRecipientDeleting, error }] = useMutation(DELETE_PAYEE);

  const deleteRecipient = () => {
    deletePayee({
      variables: {
        id: parseFloat(recipientId),
      },
    })
      .then((res) => {
        // close modal with "success" state
        if (!res?.errors) {
          navigate(
            isDeletingFromPaymentMethod
              ? `/${ROUTES.TRANSFERS_PAYMENTS}/${ROUTES.TRANSFERS_RECIPIENTS}`
              : from,
            { replace: true, state: { isDeleteRecipientSuccessful: true } }
          );
        } else {
          // generic error toast- TODO: this may need to be displayed differently
          showDeleteRecipientErrorToast();
        }
      })
      .catch((err) => {
        showDeleteRecipientErrorToast();
      });
  };

  return (
    <>
      {isRecipientDeleting && (
        <Box>
          <SlLoader styles={{ position: 'absolute !important' }} />
        </Box>
      )}
      {!error && (
        <Modal isOpen isCentered onClose={() => handleCancel(navigate, from)} w="352px">
          <ModalOverlay />
          <ModalContent p={4}>
            <ModalHeader p={0} textStyle="headline-lg">
              <Text textStyle="headline-lg">
                {isDeletingFromPaymentMethod ? `Cannot delete payment method` : `Delete recipient?`}
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody p={0}>
              {isDeletingFromPaymentMethod && (
                <Box my={1.5}>
                  <BaselaneAlertNew
                    variant="danger"
                    visual="icon"
                    iconName={Icon16Info}
                    hasCloseButton={false}
                    body="This is the only payment method for this recipient, and cannot be deleted. Would you like to delete the recipient instead?"
                  />
                </Box>
              )}
              <Text textStyle="sm" mt={0.5}>
                This will remove the recipient for all future payments, but won’t impact any past or
                processing payments.
              </Text>
            </ModalBody>
            <ModalFooter gap={2} p={0} mt={3}>
              <BaselaneButton
                variant="outline"
                palette="neutral"
                size="md"
                onClick={() => handleCancel(navigate, from)}
                styles={{
                  w: 'full',
                }}
              >
                Cancel
              </BaselaneButton>
              <BaselaneButton
                variant="filled"
                palette="danger"
                size="md"
                onClick={deleteRecipient}
                styles={{
                  w: 'full',
                }}
              >
                {isDeletingFromPaymentMethod ? `Delete recipient` : `Delete`}
              </BaselaneButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default RecipientDetailsDelete;
