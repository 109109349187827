const phoneMaskWithCountryCode = (countryCode) => [
  '+',
  countryCode,
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export default phoneMaskWithCountryCode;
