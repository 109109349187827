import radii from '@core/theme/radius';
import habitatTheme from '@core/themeHabitat';

// NOTE: The following styles are used in the [RepeatEveryField] component.
// The component is an input field with a dropdown connected to it.
// Had to extract the styles to a separate file to make it more readable.
export const inputGroupStyles = {
  height: '40px',
  border: '1px solid',
  background: 'white',
  borderColor: 'brand.darkBlue.200',
  zIndex: 1,
  padding: '0',
  flex: '0',
  gap: '0',
  width: '100%',
  justifyContent: 'right',
  _groupHover: { borderColor: 'brand.darkBlue.300' },
  _invalid: {
    borderColor: 'red.500',
    boxShadow: 'none',
    _hover: {
      borderColor: 'red.400',
      boxShadow: 'none',
    },
    _active: {
      boxShadow: `0px 0px 0px 2px  ${habitatTheme.colors.red['200']} inset`,
      borderColor: 'red.400',
    },
    _groupFocusWithin: {
      boxShadow: `0px 0px 0px 2px  ${habitatTheme.colors.red['200']} inset`,
      borderColor: 'red.400',
    },
    _groupFocus: {
      boxShadow: `0px 0px 0px 2px  ${habitatTheme.colors.red['200']} inset`,
      borderColor: 'red.400',
    },
    _groupfocusvisisble: {
      boxShadow: `0px 0px 0px 2px ${habitatTheme.colors.red['200']} inset`,
      borderColor: 'red.400',
    },
  },
  _groupFocusWithin: {
    borderColor: 'brand.blue.700',
    boxShadow: `0px 0px 0px 2px  ${habitatTheme.colors.brand.blue['100']} inset`,
  },
  _groupFocus: {
    borderColor: 'brand.blue.700',
  },
  _groupfocusvisisble: {
    borderColor: 'brand.blue.700',
  },
  _disabled: {
    color: 'brand.neutral.400',
    background: 'brand.neutral.150',
    borderColor: 'brand.neutral.250',
    cursor: 'not-allowed',
    opacity: '1',
    _hover: {
      borderColor: 'brand.neutral.250',
    },
    _active: {
      borderColor: 'brand.neutral.250',
    },
    _focus: {
      boxShadow: 'none',
    },
    _focusVisible: {
      boxShadow: 'none',
    },
  },
  borderRadius: radii.sm,
};

export const inputRightAddonStyles = {
  bg: 'transparent',
  padding: '0',
  height: '100%',
  borderLeft: '0',
  borderTop: '0',
  borderBottom: '0',
  borderRight: 0,
  with: '100%',
};

export const inputStyles = {
  bg: 'transparent',
  paddingLeft: 1.5,
  borderRight: `1px solid ${habitatTheme.colors.brand.darkBlue['200']}`,
};
