/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Spacer, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import formatCurrency from '@core/utils/formatCurrency';
import UpcomingTransferChip from './UpcomingTransferChip';

const UpcomingPayment = ({ upcomingPayment, ...rest }) => {
  return (
    <Box key={upcomingPayment.transferDate} display="flex" alignItems="center" {...rest}>
      <Text textStyle="xs">
        {DateTime.fromISO(upcomingPayment.transferDate).toFormat('MMM d, yyyy')}
      </Text>
      <UpcomingTransferChip transfer={upcomingPayment} />
      <Spacer />
      <Text textStyle="sm">{formatCurrency(upcomingPayment.amount).inDollars}</Text>
    </Box>
  );
};

export default UpcomingPayment;
