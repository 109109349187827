// @flow
// Figma: https://www.figma.com/file/gPZE7LMLnimcagPb8ZIEAx/Landlord-UI?node-id=1640%3A79334
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { HStack } from '@chakra-ui/react';
import {
  BaselaneResponsiveTableRow,
  BaselaneResponsiveCellAmount,
  BaselaneResponsiveCellTitle,
  BaselaneResponsiveCell,
} from '@shared/components';
import { GET_UPDATE_PLAID_LINK_TOKEN } from '@core/apollo/queries';
import { EditExternalDrawer } from '../../../../../EditAccountDrawer';
import {
  getLogo,
  getBalance,
  getAccountDetails,
  getAccountNickname,
  getAccountConnection,
  getResync,
} from './helpers/account.helpers';
import { accountStyles } from '../../styles/account.styles';

type AccountProps = {
  account: Object,
  banks: Array<Object>,
  refetchBankSummary: Function,
  isMinXL: String,
};

function Account({ account, banks, refetchBankSummary, isMinXL }: AccountProps): any {
  const editExternalAccDrawerRef = useRef(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isResyncSuccessfull, setIsResyncSuccessfull] = useState();
  const [localupdatedAt, setLocalUpdatedAt] = useState(account.updatedAt);
  const [accountConnectionState, setAccountConnectionState] = useState(account.connectionState);
  const { loading: updateLoading, data: updateData } = useQuery(GET_UPDATE_PLAID_LINK_TOKEN, {
    variables: { bankAccountId: account?.id },
    skip:
      account?.connectionState !== 'ITEM_LOGIN_REQUIRED' ||
      account?.id === undefined ||
      account?.id === null,
  });

  const updateLinkToken = updateData && updateData.updatePlaidLinkToken;

  useEffect(() => {
    setAccountConnectionState(account.connectionState);
  }, [account]);

  useEffect(() => {
    // opening and closing the drawer
    if (isDrawerOpen) {
      editExternalAccDrawerRef?.current?.open();
    } else {
      editExternalAccDrawerRef?.current?.close();
    }
  }, [isDrawerOpen]);

  const { chevron } = accountStyles ?? {};

  return (
    <>
      <BaselaneResponsiveTableRow
        id={account?.id}
        onClick={() => setIsDrawerOpen(true)}
        chevronCellStyles={chevron}
      >
        {/* Account  */}
        {isMinXL ? (
          <BaselaneResponsiveCellTitle
            title={getAccountNickname({ account })}
            subtitle={getAccountDetails({ account })}
            graphicElement={getLogo({ account, isMinXL })}
            rightElement={getBalance({ account, isResyncSuccessfull })}
            styles={{
              container: { alignItems: 'flex-start' },
              graphicElementContainer: { w: '40px' },
            }}
            isVertical
            configIndex={0}
          >
            <HStack gap="8px" ml="44px !important">
              {getAccountConnection({
                localupdatedAt,
                accountConnectionState,
                isMinXL,
                accountId: account.id,
              })}
              {getResync({
                account,
                banks,
                updateLoading,
                updateLinkToken,
                setAccountConnectionState,
                setLocalUpdatedAt,
                setIsResyncSuccessfull,
                refetchBankSummary,
                accountConnectionState,
                isMinXL,
              })}
            </HStack>
          </BaselaneResponsiveCellTitle>
        ) : (
          <BaselaneResponsiveCellTitle
            title={getAccountNickname({ account })}
            subtitle={getAccountDetails({ account })}
            graphicElement={getLogo({ account, isMinXL })}
            styles={{ graphicElementContainer: { w: '40px' } }}
            configIndex={0}
          />
        )}

        {/* Balance  */}
        <BaselaneResponsiveCellAmount configIndex={1}>
          {getBalance({ account, isResyncSuccessfull })}
        </BaselaneResponsiveCellAmount>
        {/* Empty Cell  */}
        <BaselaneResponsiveCell configIndex={2}> </BaselaneResponsiveCell>
        {/* Status  */}
        <BaselaneResponsiveCell configIndex={3}>
          {getAccountConnection({
            localupdatedAt,
            accountConnectionState,
            showLastUpdated: !isMinXL,
            accountId: account.id,
          })}
        </BaselaneResponsiveCell>
        {/* Action  */}
        <BaselaneResponsiveCell
          configIndex={4}
          contentContainerStyles={{ justifyContent: 'flex-end' }}
        >
          {getResync({
            account,
            banks,
            updateLoading,
            updateLinkToken,
            setAccountConnectionState,
            setLocalUpdatedAt,
            setIsResyncSuccessfull,
            refetchBankSummary,
            accountConnectionState,
            isMinXL,
          })}
        </BaselaneResponsiveCell>
      </BaselaneResponsiveTableRow>
      <EditExternalDrawer
        {...{
          account,
          refetchBankSummary,
          accountEditDrawerRef: editExternalAccDrawerRef,
          onClose: () => setIsDrawerOpen(false),
        }}
      />
    </>
  );
}

export default Account;
