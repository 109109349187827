const getTitleColor = ({ dotStatus, isExpanded, isError }) => {
  const { showCircleDot, showOutlineDot, showDashedDot } = dotStatus ?? {};
  let color;

  if (showCircleDot) {
    color = 'brand.neutral.700';
    if (isExpanded) {
      color = isError ? 'red.800AA' : 'brand.blue.700';
    }
  } else if (showOutlineDot) {
    color = 'brand.neutral.700';
    if (isExpanded) {
      color = isError ? 'red.800AA' : 'brand.blue.700';
    }
  } else if (showDashedDot) {
    color = 'brand.neutral.600';
  }

  return color;
};

export const invoiceTimelineStyles = {
  w: '100%',
  display: 'flex',
  flexDir: 'column',
};

export const accordionStyles = {
  w: '100%',
  h: 'auto',
};

export const accordionItemStyles = {
  border: 'none',
  w: '100%',
  display: 'grid',
  position: 'relative',
};

export const accordionButtonStyles = (isExpandable) => {
  return {
    w: '100%',
    h: 'calc(100% - 10px)',
    p: '0',
    alignItems: 'flex-start',
    cursor: isExpandable ? 'pointer' : 'default',
    pointerEvents: isExpandable ? 'auto' : 'none',
    _hover: {
      bg: isExpandable ? 'brand.blue.50' : 'transparent',
    },
    _focus: {
      bg: isExpandable ? 'brand.blue.50' : 'transparent',
    },
    _focusWithin: {
      bg: isExpandable ? 'brand.blue.50' : 'transparent',
    },
    _target: {
      bg: isExpandable ? 'brand.blue.50' : 'transparent',
    },
    _active: {
      bg: isExpandable ? 'brand.blue.100' : 'transparent',
    },
  };
};

export const gridStyles = {
  w: '100%',
  h: '100%',
  templateColumns: 'auto auto 1fr',
  p: '0',
  mb: '12px',
  _hover: {
    background: 'transparent',
  },
};

export const timeStyles = {
  container: {
    minWidth: '75px',
    maxWidth: '75px',
    alignSelf: 'flex-start',
    h: '100%',
    mt: '1px !important',
  },
  text: {
    textStyle: 'xs',
    color: 'brand.neutral.600',
    textAlign: 'left',
  },
};

export const dotStyles = {
  container: {
    m: '0 0 0 10px !important',
    position: 'relative',
    left: '5px',
    zIndex: 2,
    top: '5px',
    alignSelf: 'flex-start',
  },
};

export const mainContentStyles = {
  container: {
    w: '100%',
    h: '100%',
    display: 'flex',
    flexDir: 'column',
    alignSelf: 'flex-start',
    padding: '0px 8px 0 16px',
    m: '0 !important',
  },
  collapsed: {
    p: '0 !important',
    flex: 1,
    minH: '18px',
    textAlign: 'left',
  },
  expanded: {
    padding: 0,
    my: '2px !important',
    mr: '24px !important',
  },
  title: ({ dotStatus, isImportant, isExpanded, isError }) => {
    const color = getTitleColor({ dotStatus, isExpanded, isError });
    return {
      position: 'relative',
      top: '-1px',
      textStyle: 'xs',
      fontWeight: isImportant && !dotStatus.showDashedDot ? 'semibold' : 'normal',
      color,
    };
  },
  description: {
    fontSize: '3xs',
    lineHeight: '14px',
    color: 'brand.neutral.700',
    textAlign: 'left',
  },
};

export const connectorStyles = (showSolidConnector, hideConnector) => {
  return {
    borderLeftWidth: hideConnector ? '0' : '1px',
    borderColor: 'brand.darkBlue.250',
    borderStyle: showSolidConnector ? 'solid' : 'dashed',
    position: 'absolute',
    top: '10px',
    left: '93.3px',
    bottom: '-6px',
    zIndex: 1,
  };
};
