// @flow
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import {
  listItemContainerStyles,
  listItemLabelStyles,
  listItemValueStyles,
} from './styles/list.style';

function BaselaneListItem({
  label,
  value,
  labelStyle,
  valueStyle,
  containerStyle,
  itemAs,
  ...rest
}: {
  label: any,
  value: any,
  labelStyle: Object,
  valueStyle: Object,
  containerStyle: Object,
  itemAs?: 'li' | 'dd' | 'dt' | 'div',
}): any {
  const containerStyles = { ...listItemContainerStyles, ...containerStyle };
  const labelStyles = { ...listItemLabelStyles, ...labelStyle };
  const valueStyles = { ...listItemValueStyles, ...valueStyle };

  return (
    <Flex as={itemAs} {...containerStyles} {...rest}>
      {label && <Box {...labelStyles}>{label}</Box>}
      <Box {...valueStyles}>{value}</Box>
    </Flex>
  );
}

BaselaneListItem.defaultProps = {
  itemAs: 'div',
};

export default BaselaneListItem;
