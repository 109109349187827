import React from 'react';

type IconDefaultBankProps = {
  w?: Number,
  h?: Number,
};

function IconDefaultBank({ w, h }: IconDefaultBankProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#E1F4FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6615 7.73845c.2154-.09577.4614-.09577.6769 0l7.5 3.33335c.3596.1598.5584.5495.4767.9345s-.4216.6603-.8152.6603H8.49992c-.39357 0-.73348-.2753-.81518-.6603s.11709-.7747.47673-.9345l7.50003-3.33335ZM12.4268 11h7.1463l-3.5732-1.58811L12.4268 11Zm-4.76021 3.3333c0-.4602.37309-.8333.83333-.8333H23.4999c.4603 0 .8334.3731.8334.8333 0 .4602-.3731.8333-.8334.8333v7.5c.4603 0 .8334.3731.8334.8334 0 .4602-.3731.8333-.8334.8333H8.49992c-.46024 0-.83333-.3731-.83333-.8333 0-.4603.37309-.8334.83333-.8334v-7.5c-.46024 0-.83333-.3731-.83333-.8333Zm2.50001.8333v7.5h11.6667v-7.5H10.1666Zm2.5 1.6667c.4602 0 .8333.3731.8333.8333v2.5c0 .4603-.3731.8334-.8333.8334-.4603 0-.8333-.3731-.8333-.8334v-2.5c0-.4602.373-.8333.8333-.8333Zm3.3333 0c.4603 0 .8334.3731.8334.8333v2.5c0 .4603-.3731.8334-.8334.8334-.4602 0-.8333-.3731-.8333-.8334v-2.5c0-.4602.3731-.8333.8333-.8333Zm3.3334 0c.4602 0 .8333.3731.8333.8333v2.5c0 .4603-.3731.8334-.8333.8334-.4603 0-.8334-.3731-.8334-.8334v-2.5c0-.4602.3731-.8333.8334-.8333Z"
        fill="#06F"
      />
    </svg>
  );
}

IconDefaultBank.defaultProps = {
  w: 32,
  h: 32,
};

export default IconDefaultBank;
