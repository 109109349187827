import React, { Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Stack,
  Text,
} from '@chakra-ui/react';
import { GET_STARTED } from '@routes';
import { IconChevronDown, IconChevronUp } from '@icons';
import { BaselaneButton, BaselaneDrawer, BaselaneDivider } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';

import { rcDrawerComponents } from '../../helpers/howToGuidesDrawer';
import {
  dividerStyles,
  additionalTipsContainerStyles,
  additionalTipsStyles,
  mobileDividerStyles,
  mobileAccordionWrapperStyles,
  mobileAccordionItemStyles,
  mobileAccordionButtonStyles,
  mobileAccordionIconContainerStyles,
  mobileAccordionContentStyles,
} from '../../styles/howToGuidesDrawer.styles';

type RentCollectionProps = {
  howToRCDrawerRef: any,
};

const RentCollection = ({ howToRCDrawerRef }: RentCollectionProps) => {
  const { isMax768: isMobile, isMax767 } = getBreakPoints();
  const navigate = useNavigate();
  const location = useLocation();

  const onCloseHowToRCDrawer = () => {
    howToRCDrawerRef?.current.close();
  };

  const { q1, q2, q3, a1, a2, a3, banner } = rcDrawerComponents(isMobile, navigate, location);

  const deskTopBody = (
    <>
      {/* First Q&A */}
      {q1}
      {a1}

      <BaselaneDivider styles={dividerStyles} />

      {/* Second Q&A */}
      {q2}
      {a2}

      {/* Additional Tips */}
      <Stack {...additionalTipsContainerStyles.lastItem}>
        <Text {...additionalTipsStyles}>Additional Tips</Text>
        {/* Third Q&A */}
        {q3}
        {a3}
      </Stack>

      {/* Banner */}
      {banner}
    </>
  );

  const mobileBody = () => {
    const pairs = [
      { key: '1', header: q1, content: a1 },
      { key: '2', header: q2, content: a2 },
      { key: '3', header: q3, content: a3 },
    ];

    return (
      <>
        {/* Questions & Answers */}
        <Accordion {...mobileAccordionWrapperStyles} allowMultiple>
          {pairs.map((item) => (
            <Fragment key={item.key}>
              <AccordionItem {...mobileAccordionItemStyles}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton {...mobileAccordionButtonStyles}>
                      {item.header}
                      <Box {...mobileAccordionIconContainerStyles}>
                        {isExpanded ? (
                          <IconChevronUp color="#3A4B5B" />
                        ) : (
                          <IconChevronDown color="#3A4B5B" />
                        )}
                      </Box>
                    </AccordionButton>
                    <AccordionPanel {...mobileAccordionContentStyles}>
                      {item.content}
                    </AccordionPanel>
                    <BaselaneDivider
                      styles={mobileDividerStyles(item.key, pairs.length, isExpanded)}
                    />
                  </>
                )}
              </AccordionItem>
            </Fragment>
          ))}
        </Accordion>

        {/* Banner */}
        {banner}
      </>
    );
  };

  return (
    <BaselaneDrawer
      ref={howToRCDrawerRef}
      title="How to Guide - Rent Collection"
      closeEvent={onCloseHowToRCDrawer}
      showBackButton={isMobile}
      hasMobileShadow={false}
      actionLink={() => navigate(GET_STARTED)}
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      footer={
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          onClick={onCloseHowToRCDrawer}
          isFullWidth={isMax767}
        >
          Close
        </BaselaneButton>
      }
      newDesignDrawer
    >
      {isMobile ? mobileBody() : deskTopBody}
    </BaselaneDrawer>
  );
};

export default RentCollection;
