import React, { useEffect } from 'react';
import { Input } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { useFormikContext } from 'formik';
import { formInputStyles } from '@shared/styles/input.style';

type VGSEditInputProps = {
  inputProps: Object,
  isEdit: boolean,
  fieldName: string,
};

const VGSEditInput = ({ inputProps, isEdit, fieldName }: VGSEditInputProps) => {
  const { handleChange, values, handleBlur, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (isEdit) {
      setFieldValue(fieldName, '');
    }
  }, [isEdit]);

  return (
    <Input
      {...inputProps}
      as={MaskedInput}
      id={fieldName}
      name={fieldName}
      value={values[fieldName]}
      onChange={handleChange}
      onBlur={handleBlur}
      {...formInputStyles}
    />
  );
};

export default VGSEditInput;
