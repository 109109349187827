import React from 'react';
import { useFormikContext } from 'formik';
import { Icon16Check, Icon16ChevronRight, Icon16ChevronLeft } from '@icons/16px';
import {
  setTokenData,
  getIsTokenExpired,
  getIsTokenValid,
  getSensitiveToken,
  getUnitOTPVerification,
  handleOTPPopupClose,
  handleOTPPopupOpen,
  handleNBOnVerifyClick,
} from '@shared/helpers/otp.helpers';
import BaselaneButton from '../../BaselaneButton';
import TwoFactorVerificationPopUp from '../../TwoFactorVerificationPopUp';
import useTwoFactor from '../../TwoFactorVerificationPopUp/hooks/useTwoFactor';
import { getBankId, getPhoneNumber } from '../helpers';

type FooterProps = {
  currentStep: Number,
  totalSteps: Number,
  banks: Array<Object>,
  accountsMap: Object,
  transferType: String,
  handlePreviousStep: Function,
  handleNextStep: Function,
  handleMakeAnotherTransfer: Function,
  handleDrawerClose: Function,
};

const Footer = ({
  currentStep,
  totalSteps,
  banks,
  accountsMap,
  transferType,
  handlePreviousStep,
  handleNextStep,
  handleMakeAnotherTransfer,
  handleDrawerClose,
}: FooterProps) => {
  const { values, isValid, dirty, resetForm, isSubmitting, handleSubmit } = useFormikContext();

  // otp
  const { states, stateFunctions } = useTwoFactor();
  const { setOTPErrorCode } = stateFunctions;

  const bankId = getBankId({ values, transferType, accountsMap });
  const phoneNumber = getPhoneNumber({ banks, bankId });
  const isTokenExpired = getIsTokenExpired(bankId);

  const [getOTP] = getUnitOTPVerification(setOTPErrorCode);

  const onUserSensitiveTokenComplete = (unitAPISensitiveToken) => {
    const isTokenValid = getIsTokenValid(unitAPISensitiveToken);
    setTokenData(unitAPISensitiveToken, bankId);

    if (isTokenValid) {
      handleSubmit();
    }

    handleOTPPopupClose(states, stateFunctions);
  };

  const getSensitiveTokenProps = {
    onUserSensitiveTokenComplete,
    getOTP,
    bankId,
    states,
    stateFunctions,
  };

  const [getUserSensitiveTokenData] = getSensitiveToken(getSensitiveTokenProps);

  const checkToken = () => {
    if (isTokenExpired) {
      handleOTPPopupOpen(getOTP, bankId, stateFunctions);
    } else {
      handleSubmit();
    }
  };

  const twoFactorVerificationProps = {
    ...states,
    ...stateFunctions,
    getOTP,
    bankId,
    phoneNumber,
    handleVerifyOnClick: (otpCode) =>
      handleNBOnVerifyClick(otpCode, bankId, getUserSensitiveTokenData),
  };

  // footer
  const getFooterForCurrentStep = () => {
    const isReview = totalSteps - currentStep === 1;

    switch (true) {
      case currentStep === 1:
        return (
          <>
            <BaselaneButton
              size="md"
              variant="outline"
              palette="neutral"
              onClick={() => handleDrawerClose({ dirty, resetForm })}
            >
              Cancel
            </BaselaneButton>
            <BaselaneButton
              size="md"
              variant="filled"
              palette="primary"
              width="100%"
              ml={1.5}
              flex={1}
              onClick={handleNextStep}
              rightIcon={<Icon16ChevronRight />}
              isDisabled={!dirty || !isValid}
            >
              Next
            </BaselaneButton>
          </>
        );

      case currentStep > 1 && currentStep < totalSteps:
        return (
          <>
            <BaselaneButton
              size="md"
              variant="outline"
              palette="neutral"
              onClick={handlePreviousStep}
              leftIcon={<Icon16ChevronLeft />}
            >
              Back
            </BaselaneButton>
            <BaselaneButton
              size="md"
              variant="filled"
              palette="primary"
              width="100%"
              ml={1.5}
              flex={1}
              onClick={isReview ? checkToken : handleNextStep}
              leftIcon={isReview ? <Icon16Check /> : null}
              rightIcon={isReview ? null : <Icon16ChevronRight />}
              isLoading={isReview ? isSubmitting : false}
            >
              {isReview ? 'Transfer' : 'Next'}
            </BaselaneButton>
          </>
        );

      default:
        // Note: success screen
        return (
          <>
            <BaselaneButton
              size="md"
              variant="outline"
              palette="neutral"
              onClick={() => handleDrawerClose({ dirty, resetForm })}
            >
              Done
            </BaselaneButton>
            <BaselaneButton
              size="md"
              variant="tonal"
              palette="primary"
              width="100%"
              ml={1.5}
              flex={1}
              onClick={handleMakeAnotherTransfer}
            >
              Make another transfer
            </BaselaneButton>
          </>
        );
    }
  };

  return (
    <>
      {getFooterForCurrentStep()}
      <TwoFactorVerificationPopUp {...twoFactorVerificationProps} />
    </>
  );
};

export default Footer;
