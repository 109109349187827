export const handleValidation = (values) => {
  const errors = {};
  const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

  if (values.businessVertical === '') {
    errors.businessVertical = 'Please choose industry to continue';
  }

  if (values.website === '') {
    errors.website = 'Please enter your website or social media page address to continue';
  } else if (values.website !== 'N/A' && !urlRegex.test(values.website)) {
    // Check if the website doesn't match the regular expression
    errors.website = 'Please enter a valid URL';
  }

  const unmaskedEIN = values.ein.replace(/[-_]/g, '');
  if (unmaskedEIN.length < 9 && unmaskedEIN !== '') {
    errors.ein = 'Please enter in valid EIN';
  }

  return errors;
};

export const initialVariables = (formData) => {
  return {
    dba: formData?.dba ?? '',
    ein: formData?.ein ?? '',
    businessVertical: formData?.businessVertical ?? '',
    website: formData?.website ?? '',
  };
};

/**
 * All Business Vertical values, in human-readible form.
 */
const rawBusinessVertical = [
  'Construction',
  'Manufacturing',
  'Wholesale Trade',
  'Retail Trade',
  'Transportation or Warehousing',
  'Financial Services - Money Services Business or Currency Exchange',
  'Financial Services - Cryptocurrency',
  'Financial Services - Payday Lending',
  'Financial Services - Debit Collection or Consolidation',
  'Financial Services - Other',
  'Real Estate',
  'Legal, Accounting, Consulting or Computer Programming',
  'Business Support or Building Services',
  'Direct Marketing/Telemarketing',
  'Educational Services',
  'Health Care and Social Assistance',
  'Arts, Entertainment and Recreation',
  'Adult Entertainment, Dating or Escort Services',
  'Gaming/Gambling',
  'Hospitality, Accommodation or Food Services',
  'Repair and Maintenance',
  'Personal Care Services',
  'Religious, Civic and Social Organizations',
  'Public Administration',
  'Technology, Media or Telecom',
  'Cannabis',
  'Nutraceuticals',
  'Agriculture, Forestry, Fishing or Hunting',
  'Mining',
  'Utilities',
];

/**
 * Characters to strip from the human-legible values to create a unique id.
 */
const charsToOmit = ['-', ',', ' '];

/**
 * Removes chosen characters from the provided string.
 *
 * @param {String} text the text to process
 * @param {Array} charactersToStrip array of characters to remove
 * @returns original text, with selected characters removed
 */
export const stripChars = (text: String, charactersToStrip: Array) =>
  charactersToStrip
    .reduce((processedText, char) => processedText?.split(char).join(''), text)
    ?.trim();

/**
 * Capitalizes each word in the string.
 *
 * @param {String} text the text that should have each word capitalized
 * @returns text with every word starting with a capital letter
 */
export const capitalize = (text: String) => {
  const words = text.trim().split(' ');
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

/**
 * Processes a human-readible businessVertical string into an id.
 *
 * @param {String} name the text to process
 * @returns id consisting of original name, with selected characters removed, or null if no name available
 */
export const convertBusinessVertical = (name) => {
  if (!name || name?.length === 0) return null;
  return {
    id: stripChars(capitalize(name.split('/').join(' or ')), charsToOmit),
    name,
  };
};

export const businessVerticalOptions = rawBusinessVertical.map(convertBusinessVertical);

/* // test business vertical values

// backend test data
const unitBusinessVertical = [
  'AdultEntertainmentDatingOrEscortServices',
  'AgricultureForestryFishingOrHunting',
  'ArtsEntertainmentAndRecreation',
  'BusinessSupportOrBuildingServices',
  'Cannabis',
  'Construction',
  'DirectMarketingOrTelemarketing',
  'EducationalServices',
  'FinancialServicesCryptocurrency',
  'FinancialServicesDebitCollectionOrConsolidation',
  'FinancialServicesMoneyServicesBusinessOrCurrencyExchange',
  'FinancialServicesOther',
  'FinancialServicesPaydayLending',
  'GamingOrGambling',
  'HealthCareAndSocialAssistance',
  'HospitalityAccommodationOrFoodServices',
  'LegalAccountingConsultingOrComputerProgramming',
  'Manufacturing',
  'Mining',
  'Nutraceuticals',
  'PersonalCareServices',
  'PublicAdministration',
  'RealEstate',
  'ReligiousCivicAndSocialOrganizations',
  'RepairAndMaintenance',
  'RetailTrade',
  'TechnologyMediaOrTelecom',
  'TransportationOrWarehousing',
  'Utilities',
  'WholesaleTrade',
];

businessVerticalOptions.forEach((vertical) => {
  if (unitBusinessVertical.indexOf(vertical.id) === -1) {
    console.debug(`${vertical.id} is not in backend enum`);
  }
}); */
