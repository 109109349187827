import process from 'process';
import React, { useState, useRef, useEffect } from 'react';
import { BaselaneButton } from '@shared/components';
import customTheme from '@theme';
import { popupFormInputStyles } from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/styles/popup.styles';
import { IconRefresh } from '@icons';
import { toggleButtonStyles } from '../styles/useVGSShowField.styles';

export const useVGSShowField = (field, fieldName) => {
  const showFieldRef = useRef(null);
  const [isEdit, setIsEdit] = useState(!field);
  const [showInput, setShowInput] = useState({});

  const css = {
    ...popupFormInputStyles,
    display: 'block',
    backgroundColor: customTheme.colors.brand.darkBlue['100'],
  };

  const fieldToggleButton = (
    <BaselaneButton
      id={`reset-${fieldName}-button`}
      variant="transparent"
      palette="primary"
      size="sm"
      onClick={() => setIsEdit((prevState) => !prevState)}
      leftIcon={<IconRefresh />}
      styles={toggleButtonStyles(isEdit)}
    >
      Reset
    </BaselaneButton>
  );

  const showField = <div id="vgs-show" ref={showFieldRef} />;

  const setShow = () => {
    showInput.render(showFieldRef.current, css);
  };

  const vgsProxyObj = process.env.REACT_APP_VGS_PROXY;
  const [host] = vgsProxyObj.split('/');
  const [vaultId] = host.split('.');

  const enviroment =
    process.env.REACT_APP_NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === 'pre-prod'
      ? 'live'
      : 'sandbox';

  let cName = '';
  if (process.env.REACT_APP_NODE_ENV === 'pre-prod') cName = 'pre-prod.vgs.baselane.com';
  else if (process.env.REACT_APP_NODE_ENV === 'production') cName = 'prod.vgs.baselane.com';
  else if (process.env.REACT_APP_NODE_ENV === 'uat') cName = 'vgs-uat.baselane.com';

  const initShow = () => {
    const show = window.VGSShow.create(vaultId, () => {})
      .setEnvironment(enviroment)
      .setCname(cName);

    let serializers = [show.SERIALIZERS.replace('(\\d{4})-(\\d{2})-(\\d{2})', '$2/$3/$1')];
    if (fieldName === 'ein') {
      serializers = [show.SERIALIZERS.replace('(\\d{2})(\\d{7})', '$1-$2')];
    } else if (fieldName === 'ssn') {
      serializers = [show.SERIALIZERS.replace('(\\d{3})(\\d{2})(\\d{4})', '$1-$2-$3')];
    }

    // TOKEN STORAGE WAS HERE
    // Verify if this can work without token

    const input = show.request({
      name: 'sobShow',
      method: 'POST',
      path: '/api/echo',
      payload: {
        [fieldName]: field,
      },
      htmlWrapper: 'text',
      serializers,
      jsonPathSelector: fieldName,
      xhrResponseType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    setShowInput(input);
  };

  useEffect(() => {
    if (showInput && Object.keys(showInput).length && showFieldRef?.current && !isEdit) {
      setShow();
    }
  }, [field, showInput, isEdit]);

  useEffect(() => {
    if (field) {
      initShow();
      setIsEdit(!field);
    }
  }, [field]);

  return { showField, fieldToggleButton, isEdit, setShow };
};
