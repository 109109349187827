import React from 'react';
import { Stack, HStack, Spacer, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useCurrentStep, useTotalSteps } from '../../sendFundsStore';
import { steps, formStep } from '../../helpers/formHelpers';

const SharedHeader = () => {
  const currentStep = useCurrentStep();
  const totalSteps = useTotalSteps();

  const formik = useFormikContext();
  const { paymentMethodType = '' } = formik.values;

  const paymentDescription = () => {
    let description = '';
    if (paymentMethodType === 'ACH') {
      description =
        'Transfer requests received after 3 PM EST, on weekends, or on federal holidays will be processed the next business day.';
    }
    if (paymentMethodType === 'WIRE_TRANSFER') {
      description =
        'Domestic wire instructions received after 4:45 pm EST, on weekends, or on federal holidays will be processed the next business day.';
    }
    if (paymentMethodType === 'CHECK_PAYMENT') {
      description = 'Checks are printed and delivered in 5-8 business days.';
    }
    return description;
  };
  const getSharedHeaderForCurrentStep = () => {
    if (currentStep === steps.SELECT_RECIPIENT) {
      return {
        title: 'Select recipient',
        description: null,
      };
    }
    if (currentStep === steps.SELECT_PAYMENT_METHOD) {
      return {
        title: 'Recipient details',
        description: null,
      };
    }
    if (currentStep === steps.PAYMENT_DETAILS) {
      return {
        title: 'Payment details',
        description: null,
      };
    }
    if (currentStep === steps.BOOKKEPPING_DETAILS) {
      return {
        title: 'More details',
      };
    }
    if (currentStep === steps.REVIEW_PAYMENT) {
      return {
        title: 'Review and send payment',
        description: paymentDescription(),
      };
    }

    return null;
  };
  const { title, description } = getSharedHeaderForCurrentStep() ?? {};

  return (
    <Stack gap={0.5} mb={3}>
      <HStack>
        <Text textStyle="headline-md" color="brand.neutral.900">
          {title}
        </Text>
        <Spacer />
        <Text textStyle="xs" color="brand.neutral.600">
          Step {`${formStep(currentStep)} of ${totalSteps - 1}`}
        </Text>
      </HStack>
      {description && (
        <Text textStyle="sm" color="brand.neutral.700">
          {description}
        </Text>
      )}
    </Stack>
  );
};

export default SharedHeader;
