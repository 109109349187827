import React, { useRef, useState, useEffect } from 'react';
import { uniqBy } from 'lodash';
import Select from 'react-dropdown-select';
import { Box, Text } from '@chakra-ui/react';
import { truncatedText } from '@shared/styles/text.style';
import FilterWrapper from '../FilterWrapper';
import CustomDropdown from './components/CustomDropdown';
import {
  customDropdownHandleRenderer,
  getMultiSelectedItems,
  onHandleKeyDownFn,
} from '../helpers/dropdown.shared.helpers';
import { dropdownContentContainerStyles } from '../styles/dropdown.styles';
import '../styles/dropdown.styles.scss';

type T2WithTitleProps = {
  classNames?: Array<string>,
  data: Array<Object>,
  title: string,
  parentItemRenderer: Function,
  childItemRenderer: Function,
  isMulti?: boolean,
  isDisabled?: boolean,
  selectedItem?: Object,
  handleSubmit?: Function,
  showValueByFields: Array<string>,
  hasFilterWrapper?: boolean,
  filterCustomComponent?: any,
  hasActiveParentFilter?: boolean,
  setHasActiveParentFilter?: Function,
  optionStyles?: Object,
  parentId?: any,
  portalRef?: any,
  hasDropdownClearedExternally?: boolean,
  setHasDropdownClearedExternally?: Function,
  clearButtonText?: string,
  placeholder?: string,
  isClearSelectedAndStaged?: boolean,
  onClearClick?: Function,
  dropdownPosition?: string,
  hideSearch?: Boolean,
  hasError?: boolean,
  styles?: Object,
  showCustomCategories?: boolean,
  animationVariant?: String,
  hasCheckboxes?: boolean,
  onDropdownOpen?: Function,
  keepDropdownOpen?: Boolean,
  filterCustomHeaderComponentRenderer?: Function,
  handleDropdownClose?: Function,
  onClearRef?: any,
  additionalProps?: Object,
};

const T2WithTitle = ({
  classNames,
  data,
  title,
  parentItemRenderer,
  childItemRenderer,
  isMulti,
  isDisabled,
  selectedItem,
  handleSubmit,
  showValueByFields,
  hasFilterWrapper,
  optionStyles,
  filterCustomComponent,
  hasActiveParentFilter,
  setHasActiveParentFilter,
  parentId,
  portalRef,
  hasDropdownClearedExternally,
  setHasDropdownClearedExternally,
  clearButtonText,
  placeholder,
  isClearSelectedAndStaged,
  onClearClick,
  dropdownPosition,
  hideSearch,
  hasError,
  styles,
  showCustomCategories,
  animationVariant,
  hasCheckboxes,
  onDropdownOpen,
  keepDropdownOpen,
  filterCustomHeaderComponentRenderer,
  handleDropdownClose,
  onClearRef,
  additionalProps,
}: T2WithTitleProps) => {
  const searchRef = useRef();
  const dropdownElementRef = useRef();

  let defaultValue = selectedItem ?? [];
  if (selectedItem) defaultValue = isMulti ? selectedItem : [selectedItem];

  const [selectedOptions, setSelectedOptions] = useState(defaultValue);
  const [selectedStagedOptions, setSelectedStagedOptions] = useState(defaultValue);
  const [scrollTop, setScrollTop] = useState(0);
  const [calculatedHeight, setCalculatedHeight] = useState(0);
  const [hasExternalClearButton, setHasExternalClearButton] = useState(false);

  // new state for new dropdown design
  const [pId = null, cId = null] = selectedItem?.id?.split('-') ?? [];
  let initialChildrenSelectAllState = cId ? { [pId]: [selectedItem] } : {};
  if (isMulti) {
    initialChildrenSelectAllState = selectedItem?.reduce((acc, item) => {
      const { id } = item;
      const [parId, subId] = id.split('-');
      const keys = Object.keys(acc);
      if (keys.includes(parId) && subId) {
        return { ...acc, [parId]: [...acc[parId], item] };
      }
      return subId ? { ...acc, [parId]: [item] } : acc;
    }, {});
  }
  const [childrenSelectAllState, setChildrenSelectAllState] = useState(
    initialChildrenSelectAllState
  );
  const customContentRenderer = ({ state, methods }) => {
    const handleContentRendererClick = () => {
      if (state?.dropdown) {
        methods?.dropDown('close');
      }
    };

    const getValues = () =>
      selectedOptions.map((value) => value[showValueByFields[0]] || value[showValueByFields[1]]);

    const values =
      selectedOptions.length > 0 ? (
        <Text {...truncatedText}>{getValues().join(', ')}</Text>
      ) : (
        <Box className="placeholder" {...truncatedText}>
          {placeholder ?? title}
        </Box>
      );

    return (
      <Box
        {...{ ...dropdownContentContainerStyles, ...styles?.dropdownContentContainerStyles }}
        onClick={handleContentRendererClick}
      >
        {values}
      </Box>
    );
  };

  const handleBlurOnToggle = (id) => {
    const elem = Array.from(dropdownElementRef.current.childNodes).find((c) => c.id === id);
    const childElem = elem.querySelector('.element');
    elem.blur();
    childElem?.blur();
  };

  const handleListItemClick = (item, e, dropDown) => {
    const itemToToggle = selectedStagedOptions.find((opt) => opt.id === item.id);

    if (hasActiveParentFilter) setHasActiveParentFilter(false);

    if (isMulti) {
      const itemsSelected = hasActiveParentFilter
        ? [item]
        : getMultiSelectedItems({ itemToToggle, item, selectedStagedOptions });
      setSelectedStagedOptions(itemsSelected);

      // Update state of childrenSelectAllState to reflect on ui
      const [parentOptionId] = item.id.split('-');
      setChildrenSelectAllState((prevState) => {
        const prevStateClone = { ...prevState };
        return Object.keys(prevStateClone).length > 0
          ? Object.keys(prevStateClone).reduce((acc, key) => {
              if (key === parentOptionId) {
                const doesIdExist = acc[key].find((i) => i.id === item.id);
                if (doesIdExist) {
                  // remove individual child id from childrenSelectAllState[parentId]
                  const filteredItemList = acc[key].filter((i) => i.id !== item.id);
                  // delete key from object
                  const listWithoutItem = { ...acc };
                  delete listWithoutItem[key];

                  return filteredItemList.length > 0
                    ? { ...acc, [key]: acc[key].filter((i) => i.id !== item.id) }
                    : listWithoutItem;
                }
                // add individual child id to childrenSelectAllState if it doesn't exist
                return { ...acc, [key]: [...(acc?.[key] ?? []), item] };
              }
              // add as is
              return { ...acc, [key]: prevStateClone[key] };
            }, prevStateClone)
          : { ...prevStateClone, [parentOptionId]: [item] };
      });

      // NOTE: To fix updated styles not getting applied right away,
      //       blur the item to make it lose focus if user deselects it (only for click event)
      if (e?.type === 'click' && itemToToggle) handleBlurOnToggle(item.id);
    } else if (!itemToToggle) {
      const [parentOptionId, childOptionId] = item.id.split('-');
      setChildrenSelectAllState(childOptionId ? { [parentOptionId]: [item] } : {});
      setSelectedStagedOptions([item]);
      setSelectedOptions([item]);
      if (dropDown) dropDown('close');
      handleSubmit(item.id, item, dropDown);
    }
  };

  const customDropdownRenderer = ({ props, state, methods }) => {
    const { options } = props ?? {};
    const { search } = state ?? {};
    const { setSearch, dropDown } = methods ?? {};

    const handleClearClick = () => {
      setSearch({ target: { value: '' } });
      setHasActiveParentFilter(false);
      setChildrenSelectAllState({});

      setSelectedStagedOptions([]);
      if (isClearSelectedAndStaged) {
        setSelectedOptions(selectedStagedOptions);
      }

      if (onClearClick) {
        onClearClick();
      }
    };

    const handleApplyClick = () => {
      if (isMulti) setSelectedOptions(selectedStagedOptions);
      if (showCustomCategories) setScrollTop(dropdownElementRef.current.scrollTop);
      dropDown('close');
      handleSubmit(selectedStagedOptions, dropDown);
    };

    /**
     * A function to help with the logic of inner children select all. When selecting individual items as
     * as a child option or a parent with no children handleListItemClick will be called instead.
     *
     * @param parentChildren
     * @param parentItem
     * @param isParentItemChecked
     * @param isParentOnChange
     * @param isExpanded
     */
    const handleSelectAll = (
      parentChildren,
      parentItem,
      isParentOnChange,
      isExpanded = false,
      isParentItemChecked = false
    ) => {
      if (hasActiveParentFilter) setHasActiveParentFilter(false);

      const { id: parId } = parentItem;

      if (isParentOnChange) {
        /**
         *  Logic for if user is interacting with parent checkbox.
         *  1. update selectedStagedOptions depending on if parent is checked or not
         *  2. update state of childrenSelectAllState depending on if the parent is checked or not
         */
        const stagedOptions =
          childrenSelectAllState?.[parId] && isExpanded
            ? [parentItem]
            : [parentItem, ...parentChildren];
        // 1.
        setSelectedStagedOptions((prevState) => {
          if (!isParentItemChecked) {
            return prevState.filter((ps) => {
              const [prevParentId] = ps.id.split('-');
              if (isExpanded) {
                return ps.id !== parId;
              }

              return prevParentId !== parId;
            });
          }
          // 2.
          // only check all children elements if accordion is closed if it is
          // open only check the parent
          if (!isExpanded) {
            return uniqBy([...prevState, ...stagedOptions], 'id');
          }
          return [...prevState, parentItem];
        });

        // only check/uncheck all children elements if accordion is closed if it is
        // open only check/uncheck the parent
        const addToChildSelectAllState =
          isExpanded || (!isExpanded && !isParentItemChecked)
            ? childrenSelectAllState
            : {
                ...childrenSelectAllState,
                [parId]: parentChildren,
              };

        if (childrenSelectAllState?.[parId] && !isParentItemChecked && !isExpanded) {
          delete addToChildSelectAllState[parId];
        }

        setChildrenSelectAllState({
          ...addToChildSelectAllState,
        });
      } else if (!isParentOnChange && childrenSelectAllState[parId]) {
        /**
         *  Logic for child SELECT ALL option to remove all from childrenSelectAllState
         *  and removing the children from selectedStagedOptions.
         */
        const clonedState = { ...childrenSelectAllState };
        delete clonedState[parId];
        setChildrenSelectAllState(clonedState);
        setSelectedStagedOptions((prevState) =>
          prevState.filter((ps) => {
            return !parentChildren.find((pc) => pc.id === ps.id);
          })
        );
      } else {
        /**
         *  Base Case: where we add all options. When nothing is selected in the child section
         */
        setSelectedStagedOptions((prevState) => [...prevState, ...parentChildren]);
        setChildrenSelectAllState({ ...childrenSelectAllState, [parId]: parentChildren });
      }
    };

    const handleCheckboxListItemClick = (item, e) => {
      handleListItemClick(item, e);
    };

    const commonProps = {
      isMulti,
      options,
      selectedStagedOptions,
      optionStyles,
      parentItemRenderer,
      childItemRenderer,
      title,
      search,
      setSearch,
      searchRef,
      hasFilterWrapper,
      dropdownElementRef,
      handleListItemClick: hasCheckboxes ? handleCheckboxListItemClick : handleListItemClick,
      showCustomCategories,
      animationVariant,
      calculatedHeight,
      setScrollTop,
      scrollTop,
      hideSearch,
      hasCheckboxes,
      dropDown,
      handleSelectAll,
      childrenSelectAllState,
    };

    return hasFilterWrapper ? (
      <FilterWrapper
        ref={onClearRef}
        {...{
          title,
          isMulti,
          selectedStagedOptions,
          filterCustomComponent,
          onClear: handleClearClick,
          onApply: handleApplyClick,
          clearButtonText,
          animationVariant,
          dropDown,
          filterCustomHeaderComponentRenderer,
          handleDropdownClose,
          hideClearButton: hasExternalClearButton,
        }}
      >
        <CustomDropdown {...commonProps} />
      </FilterWrapper>
    ) : (
      <CustomDropdown {...commonProps} />
    );
  };

  const handleClearAndApply = () => {
    setSelectedStagedOptions([]);
    setSelectedOptions([]);
  };

  const handleKeyDownFn = (eventResponse) => {
    const setOptions = (results, id) => {
      const option = results.reduce((acc, opt) => {
        if (opt.id === id) {
          acc.itemSelected = opt;
        }

        return acc;
      }, {});

      handleListItemClick(option.itemSelected);
    };

    const helpers = { dropdownElementRef, searchRef, setOptions };
    onHandleKeyDownFn(eventResponse, helpers);
  };

  const calculateDropdownSize = () => {
    const selectDropdown = document.querySelector('.react-dropdown-select-dropdown');
    const selectElem = selectDropdown.parentElement;
    const parentElem = document.querySelector(`#${parentId}`);

    // If there isn't enough space on the right, open the dropdown to the left
    const availableWidth = parentElem?.clientWidth;
    const availableSpaceOnLeft = selectElem.offsetLeft;
    const availableSpaceForDropdown = availableWidth - availableSpaceOnLeft;
    const dropdownWidth = selectDropdown?.clientWidth;

    // If there isn't enough space on the bottom/top, adjust the height of the dropdown
    const availableHeight = parentElem?.clientHeight;
    const selectHeight = selectElem.offsetHeight;
    const availableSpaceOnTopForParent = parentElem?.getBoundingClientRect().top;
    const availableSpaceOnTopForSelect = selectElem?.getBoundingClientRect().top;
    const availableSpaceOnTop = availableSpaceOnTopForSelect - availableSpaceOnTopForParent;
    const availableSpaceBetweenSelectAndDropdown =
      selectDropdown.offsetTop - selectElem.clientHeight;

    const availableSpaceOnBottomForDropdown =
      availableHeight - availableSpaceOnTop - selectHeight - availableSpaceBetweenSelectAndDropdown;
    const availableSpaceOnTopForDropdown =
      availableHeight -
      availableSpaceOnBottomForDropdown -
      selectHeight -
      availableSpaceBetweenSelectAndDropdown;
    const dropdownHeight = selectDropdown.clientHeight;

    const positionName =
      Array.from(selectDropdown.classList).find((classname) => classname.includes('position')) ??
      '';
    const position = positionName.includes('top') ? 'top' : 'bottom';

    if (availableSpaceForDropdown < dropdownWidth) {
      selectDropdown.style.right = '0';
      selectDropdown.style.left = 'auto';
    }

    if (position === 'bottom' && availableSpaceOnBottomForDropdown < dropdownHeight) {
      selectDropdown.style.minHeight = `${availableSpaceOnBottomForDropdown}px`;
      selectDropdown.style.height = `${availableSpaceOnBottomForDropdown}px`;

      setCalculatedHeight(availableSpaceOnBottomForDropdown);
    } else if (position === 'top' && availableSpaceOnTopForDropdown < dropdownHeight) {
      selectDropdown.style.minHeight = `${availableSpaceOnTopForDropdown}px`;
      selectDropdown.style.height = `${availableSpaceOnTopForDropdown}px`;

      setCalculatedHeight(availableSpaceOnTopForDropdown);
    } else {
      setCalculatedHeight(dropdownHeight);
    }

    // focus on search if is not in mobile
    if (!portalRef) searchRef.current?.focus();

    setSelectedStagedOptions(selectedOptions);
    setChildrenSelectAllState(initialChildrenSelectAllState ?? {});
  };

  useEffect(() => {
    if (hasDropdownClearedExternally) {
      handleClearAndApply();
      setHasDropdownClearedExternally(false);
    }
  }, [hasDropdownClearedExternally]);

  useEffect(() => {
    setSelectedStagedOptions(defaultValue);
    if (!hasActiveParentFilter) {
      setSelectedOptions(defaultValue);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (onClearRef !== null) {
      setHasExternalClearButton(true);
    }
  }, [onClearRef]);

  return (
    <Select
      className={`tier2-dropdown ${hasError ? 'input-invalid' : ''} ${classNames.join(' ')}`}
      dropdownPosition={dropdownPosition}
      keepOpen={keepDropdownOpen}
      options={data}
      values={selectedOptions}
      disabled={isDisabled}
      dropdownGap={0}
      closeOnSelect={!isMulti}
      multi={isMulti}
      backspaceDelete={false}
      dropdownRenderer={customDropdownRenderer}
      contentRenderer={customContentRenderer}
      dropdownHandleRenderer={customDropdownHandleRenderer}
      onDropdownOpen={(e) => {
        onDropdownOpen(e);
        calculateDropdownSize();
      }}
      onDropdownClose={(event) => {
        handleDropdownClose(event);
      }}
      handleKeyDownFn={handleKeyDownFn}
      portal={portalRef}
      additionalProps={additionalProps}
    />
  );
};

T2WithTitle.defaultProps = {
  classNames: ['fixed-width-dropdown'],
  isMulti: false,
  isDisabled: false,
  selectedItem: null,
  handleSubmit: () => {},
  hasFilterWrapper: false,
  filterCustomComponent: null,
  hasActiveParentFilter: false,
  setHasActiveParentFilter: () => {},
  optionStyles: {},
  parentId: 'page-wrapper',
  portalRef: false,
  hasDropdownClearedExternally: false,
  setHasDropdownClearedExternally: () => {},
  clearButtonText: 'Clear',
  placeholder: null,
  isClearSelectedAndStaged: false,
  onClearClick: null,
  dropdownPosition: 'auto',
  hideSearch: false,
  hasError: false,
  styles: {},
  showCustomCategories: null,
  animationVariant: null,
  hasCheckboxes: false,
  onDropdownOpen: () => {},
  keepDropdownOpen: false,
  filterCustomHeaderComponentRenderer: null,
  handleDropdownClose: () => {},
  onClearRef: null,
  additionalProps: {},
};

export default T2WithTitle;
