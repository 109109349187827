import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { BaselaneButtonCard } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  onboardingCardTextStyles,
  onboardingCardTitleStyles,
} from '@core/components/OnboardingTriage/styles/onboardingTriage.styles';

interface CardOptionProps {
  card: Object;
  onClick: Function;
  styles: Object;
  isActive: Boolean;
}

const CardOption = ({ card, onClick, styles, isActive }: CardOptionProps) => {
  const { isMin899 } = getBreakPoints();
  const cardStyles = {
    ...styles,
    ...(isActive ? { borderWidth: '2px', borderColor: 'brand.blue.700' } : {}),
  };
  return (
    <BaselaneButtonCard id={card.id} onClick={onClick} buttonStyles={cardStyles}>
      <HStack spacing={0.5}>
        <Box>{card.image}</Box>
        <Box>
          <Text {...onboardingCardTitleStyles}>
            {isMin899 ? card.title : card?.titleMobile || card.title}
          </Text>
          <Text {...onboardingCardTextStyles}>{card.description}</Text>
        </Box>
      </HStack>
    </BaselaneButtonCard>
  );
};

CardOption.defaultProps = {
  isActive: false,
  showCarret: false,
};

export default CardOption;
