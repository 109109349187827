export const formLabelStyles = {
  xs: {
    fontSize: 'xs',
    lineHeight: '20px',
    fontWeight: 'normal',
    color: 'brand.neutral.700',
    _invalid: { color: 'red.800AA' },
  },
  sm: {
    fontSize: 'sm',
    lineHeight: '24px',
    fontWeight: 'normal',
    color: 'brand.neutral.700',
    _invalid: { color: 'red.800AA' },
  },
};

export const formInputStyles = {
  h: '48px',
  p: '12px 16px',
  color: 'brand.neutral.700',
  borderColor: 'brand.darkBlue.150',
  borderRadius: '4px',
  size: 'sm',
  _invalid: {
    borderColor: 'red.800AA',
    _focus: {
      borderColor: 'red.800AA',
      color: 'brand.neutral.700',
      boxShadow: 'none',
    },
  },
  _focus: {
    borderColor: 'brand.darkBlue.250',
    color: 'brand.neutral.600',
    boxShadow: 'inset 0px 0px 1px 1px #E1F4FF',
  },
  _hover: { borderColor: 'brand.darkBlue.250', color: 'brand.neutral.600' },
  _disabled: { borderColor: 'brand.darkBlue.100', color: 'brand.darkBlue.300' },
  _placeholder: { color: 'brand.neutral.500' },
};

export const formErrorStyles = {
  fontSize: 'xs',
  lineHeight: '20px',
  ml: '18px', // instead of 16px in the Figma, 18 aligns better with the input/placeholder
  mt: 1,
  color: 'red.800AA',
};

export const formSwitchRowStyles = {
  display: 'inline-flex',
  alignItems: 'center',
  h: '48px',
  mt: '32px',
};

export const formSwitchStyles = {
  alignSelf: 'center',
  mr: '3px !important',
};
