// @flow
import React from 'react';
import { Tbody } from '@chakra-ui/react';
import {
  BaselaneResponsiveTable,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableRows,
} from '@shared/components';
import Account from './Account';
import { tablesStyles } from '../../../../styles/tables.styles';

type ManuallyAddedProps = {
  selfOwned: Array<Object>,
  refetchConnectedSelfOwned: Function,
  isMinXL: String,
};

function ManuallyAdded({ selfOwned, refetchConnectedSelfOwned, isMinXL }: ManuallyAddedProps): any {
  const tableConfig = {
    columns: [
      {
        styles: {
          display: 'table-cell',
        },
      },
      {
        styles: {
          width: '48px',
        },
      },
    ],
  };

  const tableHeaderItems = [{ key: 'account', label: 'Account' }];

  const { container } = tablesStyles ?? {};

  return (
    <BaselaneResponsiveTable config={tableConfig} tableContainerStyles={container}>
      {!isMinXL && (
        <BaselaneResponsiveTableHeader
          items={tableHeaderItems}
          renderItem={(item, index) => (
            <BaselaneResponsiveTableHeading key={item.key} index={index} title={item.label} />
          )}
        />
      )}

      <Tbody>
        <BaselaneResponsiveTableRows
          customRow
          items={selfOwned}
          renderItem={(account) => <Account {...{ account, refetchConnectedSelfOwned }} />}
        />
      </Tbody>
    </BaselaneResponsiveTable>
  );
}

export default ManuallyAdded;
