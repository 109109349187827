import React from 'react';
import moment from 'moment';
import { FormControl, FormErrorMessage, HStack, Stack } from '@chakra-ui/react';
import { formErrorStyles } from '@shared/styles/input.style';
import { BaselaneRadioGroup, BaselaneRadio } from '@core/components/Shared/components';
import { firstDateIsSmaller, getOnDateOptions } from '../../formHelpers/leaseTermForm.helper';
import { fieldContainer } from '../../styles/shared.styles';

type MonthToMonthInputProps = {
  name: string,
  errors: Object,
  touched: Object,
  values: Object,
  handleChange: Function,
  isDisabled: Boolean,
  setFieldTouched: Function,
  setFieldValue: Function,
  setFormVariables: Function,
  setRCStartOptions: Function,
  setNoRCStartOptions: Function,
  invoicesForUnit: Array,
};

function MonthToMonthInput({
  name,
  errors,
  touched,
  values,
  handleChange,
  isDisabled,
  setFieldTouched,
  setFieldValue,
  setFormVariables,
  setRCStartOptions,
  setNoRCStartOptions,
  invoicesForUnit,
}: MonthToMonthInputProps) {
  const handleChangeIsMonthToMonth = (e) => {
    if (!errors.isMonthToMonth) {
      let leaseTypeField;
      let leaseEndDate;
      let data;
      if (values.isMonthToMonth) {
        setFieldValue('isMonthToMonth', false);
        setFieldValue('endDate', null);
        leaseTypeField = 'FIXED';
        leaseEndDate = null;
        data = { leaseType: leaseTypeField };
      } else {
        setFieldValue('isMonthToMonth', true);
        setFieldTouched('endDate', false);
        leaseTypeField = 'MONTHLY';
        leaseEndDate = null;
        data = { leaseType: leaseTypeField, endDate: leaseEndDate };
      }
      const sortedInvoices = invoicesForUnit
        ?.filter(
          (item) =>
            (item.name === 'RENT' || item.name === 'RENT_AND_FEE') &&
            (item.state === 'UPCOMING' || item.state === 'SCHEDULED' || item.state === 'FAILED')
        )
        ?.sort(
          (a, b) =>
            moment(a?.originalDueDate).format('YYYYMMDD') -
            moment(b?.originalDueDate).format('YYYYMMDD')
        );
      const firstUnpaidInvoiceDate = sortedInvoices && sortedInvoices[0]?.dueDate;
      const firstPossibleStartDate =
        firstUnpaidInvoiceDate && firstDateIsSmaller(values.startDate, firstUnpaidInvoiceDate)
          ? firstUnpaidInvoiceDate
          : values.startDate;

      const newRCStartOptions = getOnDateOptions(
        values.dueDays,
        moment(firstPossibleStartDate),
        leaseEndDate,
        false,
        !values.isMonthToMonth
      );

      setRCStartOptions(newRCStartOptions);
      setNoRCStartOptions(!newRCStartOptions || newRCStartOptions.length === 0);
      // if the current rc start option is not valid, as in, not in the array of options, we change that as well
      const valid = newRCStartOptions.find(
        (item) => item.dateValue === values.rentCollectionStartDate
      );
      if ((!valid || values?.rentCollectionStartDate === null) && newRCStartOptions[0]?.dateValue) {
        setFieldValue('rentCollectionStartDate', newRCStartOptions[0]?.dateValue);
        data.rentCollectionStartDate = newRCStartOptions[0]?.dateValue;
        setFormVariables(data);
      } else {
        setFormVariables(data);
      }
    }
    handleChange(e);
  };

  return (
    <FormControl {...fieldContainer} isInvalid={errors.isMonthToMonth && touched.isMonthToMonth}>
      <HStack align="center" justifyContent="space-between">
        <BaselaneRadioGroup
          className="primary-500 custom-radio"
          id={name}
          onChange={handleChangeIsMonthToMonth}
          value={values.isMonthToMonth ? '0' : '1'}
          isDisabled={isDisabled}
        >
          <Stack direction="row" ml="4px">
            <BaselaneRadio value="1" label="Fixed term" />
            <BaselaneRadio value="0" label="Month-to-month" />
          </Stack>
        </BaselaneRadioGroup>
      </HStack>
      <FormErrorMessage {...formErrorStyles}>{errors.isMonthToMonth}</FormErrorMessage>
    </FormControl>
  );
}

export default MonthToMonthInput;
