import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const numbersOnlyConfig = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: '',
  allowDecimal: false,
  decimalSymbol: '',
  integerLimit: null,
  allowNegative: false,
  allowLeadingZeroes: true,
};

export default createNumberMask(numbersOnlyConfig);
