import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

/**
 * This overrides Chakra's default <Textarea /> styles.
 *
 * see: node_modules/@chakra-ui/theme/src/components/textarea.ts for implementation details
 */

export const size = {
  lg: defineStyle((props) => {
    const { textStyles, radii } = props.theme;

    return {
      px: 2,
      py: 1.5,
      borderRadius: radii.sm,
      ...textStyles.sm,
      height: '88px', // TO DO: add var
    };
  }),
  md: defineStyle((props) => {
    const { textStyles, radii } = props.theme;

    return {
      px: 1.5,
      py: 1.5,
      borderRadius: radii.sm,
      ...textStyles.sm,
      height: '80px', // TO DO: add var
    };
  }),
  sm: defineStyle((props) => {
    const { textStyles, radii } = props.theme;

    return {
      px: 1.5,
      py: 0.75,
      borderRadius: radii.sm,
      ...textStyles.sm,
      height: '72px', // TO DO: add var
    };
  }),
};

export const Textarea = defineStyleConfig({
  sizes: size,
  defaultProps: {
    focusBorderColor: 'brand.blue.700',
    errorBorderColor: 'red.500',
  },
  variants: {
    outline: defineStyle((props) => {
      const { colors } = props.theme;
      return {
        color: 'brand.neutral.900',
        border: '1px solid',
        background: 'white',
        borderColor: 'brand.darkBlue.200',
        _invalid: {
          boxShadow: `none`,
          _hover: {
            borderColor: 'red.400',
            boxShadow: 'none',
          },
          _active: {
            boxShadow: `0px 0px 0px 2px  ${colors.red['200']} inset`,
            borderColor: 'red.400',
          },
          _focusVisible: {
            boxShadow: `0px 0px 0px 2px  ${colors.red['200']} inset`,
            borderColor: 'red.400',
          },
        },
        _placeholder: { color: 'brand.neutral.500' },
        _hover: {
          borderColor: 'brand.darkBlue.300',
        },
        _active: {
          borderColor: 'brand.blue.700',
        },
        _focus: {
          borderColor: 'brand.blue.700',
          boxShadow: `0px 0px 0px 2px ${colors.brand.blue[100]} inset`,
        },
        _focusVisible: {
          boxShadow: `0px 0px 0px 2px  ${colors.brand.blue['100']} inset`,
        },
        _disabled: {
          color: 'brand.neutral.400',
          background: 'brand.neutral.150',
          borderColor: 'brand.neutral.250',
          cursor: 'not-allowed',
          opacity: '1',
          _hover: {
            borderColor: 'brand.neutral.250',
          },
          _active: {
            borderColor: 'brand.neutral.250',
          },
          _focus: {
            boxShadow: 'none',
          },
          _focusVisible: {
            boxShadow: 'none',
          },
        },
      };
    }),
  },
});
