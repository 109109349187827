import React, { forwardRef } from 'react';
import { InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

import { searchGroupStyles, searchStyles } from './styles/dropdown.styles';

type SearchInputProps = {
  title: String,
  showTitleInSearch: Boolean,
  search: String,
  setSearch: Function,
};

const SearchInput = forwardRef(
  ({ title, showTitleInSearch = true, search, setSearch, ...rest }: SearchInputProps, ref) => {
    return (
      <InputGroup {...{ ...searchGroupStyles, ...rest }}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="#6C7884" />
        </InputLeftElement>
        <Input
          ref={ref}
          {...{ ...searchStyles, pl: '36px' }}
          placeholder={showTitleInSearch ? `Search ${title}` : 'Search'}
          value={search}
          onChange={setSearch}
        />
      </InputGroup>
    );
  }
);

export default SearchInput;
