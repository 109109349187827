import React from 'react';
import type { IconPropsType } from './types/icon.types';

function IconUpload({ w = 16, h = 16, color = 'currentColor' }: IconPropsType) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5286 2.19526C7.78895 1.93491 8.21106 1.93491 8.4714 2.19526L11.1381 4.86193C11.3984 5.12228 11.3984 5.54439 11.1381 5.80474C10.8777 6.06509 10.4556 6.06509 10.1953 5.80474L8.66667 4.27614V10.6667C8.66667 11.0349 8.36819 11.3333 8 11.3333C7.63181 11.3333 7.33333 11.0349 7.33333 10.6667V4.27614L5.80474 5.80474C5.54439 6.06509 5.12228 6.06509 4.86193 5.80474C4.60158 5.54439 4.60158 5.12228 4.86193 4.86193L7.5286 2.19526ZM2.66667 10C3.03486 10 3.33333 10.2985 3.33333 10.6667V11.3333C3.33333 11.687 3.47381 12.0261 3.72386 12.2761C3.97391 12.5262 4.31304 12.6667 4.66667 12.6667H11.3333C11.687 12.6667 12.0261 12.5262 12.2761 12.2761C12.5262 12.0261 12.6667 11.687 12.6667 11.3333V10.6667C12.6667 10.2985 12.9651 10 13.3333 10C13.7015 10 14 10.2985 14 10.6667V11.3333C14 12.0406 13.719 12.7189 13.219 13.219C12.7189 13.719 12.0406 14 11.3333 14H4.66667C3.95942 14 3.28115 13.719 2.78105 13.219C2.28095 12.7189 2 12.0406 2 11.3333V10.6667C2 10.2985 2.29848 10 2.66667 10Z"
        fill={color}
      />
    </svg>
  );
}

IconUpload.defaultProps = {
  w: 16,
  h: 16,
  color: 'currentColor',
};

export default IconUpload;
