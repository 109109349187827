import { ReactComponent as InsightsIconDarkDefault } from '@icons/32px/Insights Dark Default.svg';
import { ReactComponent as InsightsIconDarkSuccess } from '@icons/32px/Insights Dark Success.svg';
import { ReactComponent as InsightsIconDarkWarning } from '@icons/32px/Insights Dark Warning.svg';
import { ReactComponent as InsightsIconDarkDanger } from '@icons/32px/Insights Dark Danger.svg';
import { ReactComponent as InsightsIconLightNeutral } from '@icons/32px/Insights Light Neutral.svg';
import { ReactComponent as InsightsIconLightPrimary } from '@icons/32px/Insights Light Primary.svg';
import { ReactComponent as InsightsIconLightSuccess } from '@icons/32px/Insights Light Success.svg';
import { ReactComponent as InsightsIconLightWarning } from '@icons/32px/Insights Light Warning.svg';
import { ReactComponent as InsightsIconLightDanger } from '@icons/32px/Insights Light Danger.svg';

const bannerIconMap = {
  'neutral-medium': InsightsIconDarkDefault,
  'primary-medium': InsightsIconDarkDefault,
  'success-medium': InsightsIconDarkSuccess,
  'warning-medium': InsightsIconDarkWarning,
  'danger-medium': InsightsIconDarkDanger,
  'neutral-high': InsightsIconLightNeutral,
  'primary-high': InsightsIconLightPrimary,
  'success-high': InsightsIconLightSuccess,
  'warning-high': InsightsIconLightWarning,
  'danger-high': InsightsIconLightDanger,
};

export const getDynamicIcon = ({ variant }) => {
  return bannerIconMap[variant] ?? InsightsIconDarkDefault;
};

const chipVariantsMap = {
  'neutral-medium': 'simple-neutral',
  'primary-medium': 'simple-primary',
  'success-medium': 'simple-success',
  'warning-medium': 'simple-warning',
  'danger-medium': 'simple-danger',
  'neutral-high': 'simple-reversed',
  'primary-high': 'simple-reversed',
  'success-high': 'simple-reversed',
  'warning-high': 'simple-reversed',
  'danger-high': 'simple-reversed',
};

export const getDynamicChipVariant = ({ variant }) => {
  return chipVariantsMap[variant] ?? 'simple-neutral';
};
