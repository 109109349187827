import React from 'react';

type IconBankThinProps = { w?: Number, h?: Number, color?: string };

function IconBankThin({ w, h, color }: IconBankThinProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9622.058971c-.177-.078628-.3789-.078628-.5558 0L.406394 4.84844c-.295288.13124-.4585018.45118-.3914208.76728.0670811.3161.3461658.54218.6693048.54218H26.6843c.3231 0 .6022-.22608.6693-.54218.0671-.3161-.0961-.63604-.3914-.76728l-13-4.789469Zm-.2779 1.373979 10.46 3.35652H3.22422L13.6843 1.43295Z"
        fill={color}
      />
      <path
        d="M6.15796 10.2632c0-.37792-.30633-.68425-.68421-.68425s-.68421.30633-.68421.68425v12.3157c0 .3779.30633.6843.68421.6843s.68421-.3064.68421-.6843V10.2632ZM14.3685 10.2632c0-.37792-.3063-.68425-.6842-.68425s-.6842.30633-.6842.68425v12.3157c0 .3779.3063.6843.6842.6843s.6842-.3064.6842-.6843V10.2632ZM22.579 10.2632c0-.37792-.3063-.68425-.6842-.68425s-.6842.30633-.6842.68425v12.3157c0 .3779.3063.6843.6842.6843s.6842-.3064.6842-.6843V10.2632ZM9.57895 9.57895c.37788 0 .68425.30633.68425.68425v12.3157c0 .3779-.30637.6843-.68425.6843-.37788 0-.68421-.3064-.68421-.6843V10.2632c0-.37792.30633-.68425.68421-.68425ZM17.7895 9.57895c.3779 0 .6842.30633.6842.68425v12.3157c0 .3779-.3063.6843-.6842.6843s-.6842-.3064-.6842-.6843V10.2632c0-.37792.3063-.68425.6842-.68425Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.684278 6.84211c-.377879 0-.68421014.30633-.68421014.68421 0 .37787.30633114.68421.68421014.68421V24.6316c-.377879 0-.68421014.3063-.68421014.6842S.306399 26 .684278 26H26.6843c.3779 0 .6842-.3063.6842-.6842s-.3063-.6842-.6842-.6842V8.21053c.3779 0 .6842-.30634.6842-.68421 0-.37788-.3063-.68421-.6842-.68421H.684278ZM2.0527 24.6316V8.21053h23.2632V24.6316H2.0527Z"
        fill={color}
      />
    </svg>
  );
}

IconBankThin.defaultProps = {
  w: 28,
  h: 26,
  color: 'currentColor',
};

export default IconBankThin;
