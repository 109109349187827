export const getPropertyAndUnitId = (autoTag) => {
  let propertyAndUnitId =
    autoTag?.propertyId && autoTag?.propertyUnitId
      ? `${autoTag.propertyId}-${autoTag.propertyUnitId}`
      : null;

  if (autoTag?.propertyId && !autoTag?.propertyUnitId) {
    propertyAndUnitId = autoTag?.propertyId;
  }

  return propertyAndUnitId;
};

export const formatDataToSubmit = (data) => {
  const banksToAutoTag = sessionStorage.getItem('banksToAutoTag');
  const banksToAutoTagList = JSON.parse(banksToAutoTag) ?? [];

  const banksToUpdate = data.filter((autoTagItem) => {
    const propertyAndUnitId = getPropertyAndUnitId(autoTagItem?.autoTag);
    return autoTagItem.tagged !== propertyAndUnitId;
  });

  const banksToUpdateList = banksToUpdate.map((btu) => {
    const splitTaggedValue = btu?.tagged?.includes('-') ? btu.tagged.split('-') : btu.tagged;
    const propertyId =
      splitTaggedValue && btu?.tagged?.includes('-') ? splitTaggedValue[0] : splitTaggedValue;
    const propertyUnitId =
      btu?.tagged?.includes('-') && splitTaggedValue && splitTaggedValue.length > 1
        ? splitTaggedValue[1]
        : null;

    const isBankInList = banksToAutoTagList.find((bankToAT) => bankToAT.id === btu.id);

    return {
      id: btu.id,
      autoTag: {
        enabled: !!btu.tagged,
        propertyId,
        propertyUnitId,
        retroactivelyAutoTag: !!isBankInList,
      },
    };
  });

  sessionStorage.removeItem('banksToAutoTag');

  return banksToUpdateList;
};

export const updateBankList = (setAccountsAutoTaggedList, externalAccounts) => {
  let bankAccounts = [];
  externalAccounts.forEach((bank) => {
    if (bank?.bankAccounts?.length > 0) {
      const accounts = bank?.bankAccounts.map((dba) => {
        return {
          ...dba,
          nickName: dba?.nickName || `${dba?.accountName}-${dba?.accountNumber}`,
          logo: bank?.logo ?? null,
          plaidInstitutionName: bank.plaidInstitutionName,
          tagged: getPropertyAndUnitId(dba?.autoTag),
        };
      });
      bankAccounts = bankAccounts.concat(accounts);
    }
  });
  setAccountsAutoTaggedList(bankAccounts);
};
