// @flow
import React, { useState, useContext, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import {
  Box,
  Stack,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  FormLabel,
} from '@chakra-ui/react';
import {
  passwordValidator,
  initialChecklist,
} from '@features/Authentication/helpers/validation.helpers';
import { Icon16Hide, Icon16Unhide } from '@icons/16px';
import IconCheckCircleOutline from '@icons/legacy/IconCheckCircleOutline';
import IconExclamationCircle from '@icons/legacy/IconExclamationCircle';
import IconSmallGoogleLogo from '@icons/legacy/IconSmallGoogleLogo';
import {
  AlertHeader,
  BaselaneAlert,
  BaselaneButton,
  BaselaneButtonIcon,
  BaselaneDivider,
  BaselaneMessageCard,
} from '@shared/components';
import { auth, reauthenticate, updatePassword } from '@core/Services/Firebase/firebase';
import UserContext from '@contexts/UserContext';
import Checker from '@features/Authentication/components/Checker';
import {
  titleStyles,
  formLabelStyles,
  formInputStyles,
  passwordStyles,
  badgeIconBgSuccessStyles,
  badgeIconSuccessContainerStyles,
  badgeIconBgErrorStyles,
  badgeIconErrorContainerStyles,
  bodyTextStyles,
  profileDataContainerStyles,
} from '../../../styles/userProfileTabsPanels.styles';
import { errorStyles } from '../../../../../pages/LoginPage/styles/font.style';

type PasswordProps = {
  setIsDirty: Function,
  setIsTabChange: Function,
  setTabIndex: Function,
  isTabChange: boolean,
  tabIndex: number,
};

function Password({
  setIsDirty,
  setIsTabChange,
  isTabChange,
  tabIndex,
  setTabIndex,
}: PasswordProps) {
  const formikRef = useRef();

  // alert for tabs
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const alertOnClose = () => setAlertIsOpen(false);
  const alertCancelRef = useRef();
  const [isSuccessAlert, setIsSuccessAlert] = useState(true);
  const [alertErrorMessage, setAlertErrorMessage] = useState('');

  const { user } = useContext(UserContext);
  const { descriptionStyles } = passwordStyles;

  const [checklist, setChecklist] = useState(initialChecklist);
  const [isStrongPassword, setStrongPassword] = useState(false);

  const [showCurrPassword, setShowCurrPassword] = useState(false);
  const handleCurrPasswordVisibility = () => setShowCurrPassword(!showCurrPassword);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);
  const weakPassword = () => Object.keys(checklist).find((item) => !checklist[item].isValid);

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const strongPassword = <Text>Strong Password!</Text>;

  const cleanup = () => {
    setIsDirty(false);
    setShowCurrPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setStrongPassword(false);
    const updatedChecklist = passwordValidator('', initialChecklist);
    setChecklist(updatedChecklist);
    formikRef.current?.validateForm();
  };

  const handleValidation = (values) => {
    const { currentPassword, newPassword, confirmNewPassword } = values;
    const errors = {};

    if (weakPassword()) {
      setStrongPassword(false);
      errors.newPassword = 'Invalid password.';
    }

    if (currentPassword === '') {
      errors.currentPassword = 'Enter current password.';
    }

    if (newPassword === '') {
      errors.newPassword = 'Enter new password.';
    }

    if (confirmNewPassword === '') {
      errors.confirmNewPassword = 'Confirm new password.';
    }

    if (confirmNewPassword !== newPassword) {
      errors.confirmNewPassword = 'Please make sure your passwords match.';
    }

    if (currentPassword === newPassword) {
      errors.newPassword =
        'New password cannot be the same as your current password. Please choose a different one.';
    }

    return errors;
  };

  const changePassword = (currentPassword, newPassword) => {
    reauthenticate(currentPassword)
      .then(() => {
        updatePassword(auth.currentUser, newPassword)
          .then(() => {
            setIsSuccessAlert(true);
            setAlertIsOpen(true);
          })
          .catch((error) => {
            setIsSuccessAlert(false);
            setAlertErrorMessage(error.message);
            setAlertIsOpen(true);
          });
      })
      .catch((error) => {
        if (error.code === 'auth/wrong-password') {
          setIsDirty(true);
          setIsTabChange({ change: false, tab: tabIndex });
          formikRef.current?.setFieldError('currentPassword', error.message);
        } else if (error.code === 'auth/too-many-requests') {
          setIsSuccessAlert(false);
          setAlertErrorMessage(error.message);
          setAlertIsOpen(true);
        }
      });
  };

  const handleFormSubmit = (values) => {
    const { currentPassword, newPassword } = values;
    changePassword(currentPassword, newPassword);
  };

  const handleAlertOnClose = () => {
    if (alertIsOpen) alertOnClose();
  };

  const handleUpdateSuccess = () => {
    alertOnClose();
    formikRef.current?.resetForm({ values: { initialValues } });
    if (isSuccessAlert) {
      setTabIndex(isTabChange.tab);
      setIsTabChange({ change: false, tab: tabIndex });
    }
    cleanup();
  };

  const alertHeader = (
    <AlertHeader
      title={isSuccessAlert ? 'Password Updated' : 'Something Went Wrong'}
      icon={isSuccessAlert ? <IconCheckCircleOutline /> : <IconExclamationCircle />}
      iconBgStyles={{ ...(isSuccessAlert ? badgeIconBgSuccessStyles : badgeIconBgErrorStyles) }}
      iconContainerStyles={{
        ...(isSuccessAlert ? badgeIconSuccessContainerStyles : badgeIconErrorContainerStyles),
      }}
    />
  );

  const alertUpdateSuccessBody = (
    <Text {...bodyTextStyles}>
      {isSuccessAlert ? 'Your password has been changed successfully.' : alertErrorMessage}
    </Text>
  );

  const alertUpdateSuccessFooter = (
    <BaselaneButton
      variant="outline"
      palette="transparent"
      ref={alertCancelRef}
      onClick={handleUpdateSuccess}
    >
      OK
    </BaselaneButton>
  );

  return (
    <Stack spacing="32px" {...profileDataContainerStyles(isMobile)}>
      <Text {...titleStyles}>Password</Text>
      {isMobile && <BaselaneDivider />}
      {user.provider === 'password' ? (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validate={(values) => handleValidation(values)}
          validateOnChange
          validateOnMount
          enableReinitialize
        >
          {({
            resetForm,
            dirty,
            values,
            isValid,
            handleChange,
            errors,
            touched,
            setFieldTouched,
            handleBlur,
          }) => (
            <form style={{ width: '100%' }}>
              {/* Current Password */}
              <Box>
                <FormLabel htmlFor="currentPassword" {...formLabelStyles}>
                  Enter Current Password
                </FormLabel>
                <InputGroup>
                  <Input
                    {...formInputStyles}
                    id="currentPassword"
                    name="currentPassword"
                    value={values.currentPassword}
                    type={showCurrPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldTouched('currentPassword', true);
                      setIsDirty(isValid && dirty);
                    }}
                    placeholder="Current Password"
                  />
                  <InputRightElement>
                    <BaselaneButtonIcon
                      variant="transparent"
                      palette="neutral"
                      size="sm"
                      icon={showCurrPassword ? <Icon16Hide /> : <Icon16Unhide />}
                      isActive={showCurrPassword}
                      onClick={handleCurrPasswordVisibility}
                    />
                  </InputRightElement>
                </InputGroup>
                {errors.currentPassword && touched.currentPassword ? (
                  <Text {...errorStyles}>{errors.currentPassword}</Text>
                ) : null}
              </Box>

              {/* New Password */}
              <Box pt="32px" position="relative">
                <FormLabel htmlFor="newPassword" {...formLabelStyles}>
                  Enter New Password
                </FormLabel>
                <InputGroup>
                  <Input
                    {...formInputStyles}
                    id="newPassword"
                    name="newPassword"
                    value={values.newPassword}
                    type={showNewPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      const p = e.target.value;
                      const updatedChecklist = passwordValidator(p, checklist);
                      setChecklist(updatedChecklist);
                      if (!weakPassword()) {
                        setStrongPassword(true);
                      }
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldTouched('newPassword', true);
                      setIsDirty(isValid && dirty);
                    }}
                    placeholder="New Password"
                  />
                  <InputRightElement>
                    <BaselaneButtonIcon
                      variant="transparent"
                      palette="neutral"
                      size="sm"
                      icon={showNewPassword ? <Icon16Hide /> : <Icon16Unhide />}
                      isActive={showNewPassword}
                      onClick={handleNewPasswordVisibility}
                    />
                  </InputRightElement>
                </InputGroup>
                {errors.newPassword && touched.newPassword ? (
                  <Text {...errorStyles}>{errors.newPassword}</Text>
                ) : null}
              </Box>

              {/* Validation */}
              <Box pt="32px" position="relative">
                {isStrongPassword ? (
                  <BaselaneMessageCard
                    iconName="checkcircleoutline"
                    iconColor="green"
                    borderColor="green"
                    text={strongPassword}
                    iconContainerStyles={{ w: '18px' }}
                  />
                ) : (
                  <Checker checklist={checklist} />
                )}
              </Box>

              {/* Confirm Password */}
              <Box pt="32px" position="relative">
                <FormLabel htmlFor="confirmNewPassword" {...formLabelStyles}>
                  Confirm New Password
                </FormLabel>
                <InputGroup>
                  <Input
                    {...formInputStyles}
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    value={values.confirmNewPassword}
                    type={showConfirmPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldTouched('confirmNewPassword', true);
                      setIsDirty(isValid && dirty);
                    }}
                    placeholder="New Password"
                  />
                  <InputRightElement>
                    <BaselaneButtonIcon
                      variant="transparent"
                      palette="neutral"
                      size="sm"
                      icon={showConfirmPassword ? <Icon16Hide /> : <Icon16Unhide />}
                      isActive={showConfirmPassword}
                      onClick={handleConfirmPasswordVisibility}
                    />
                  </InputRightElement>
                </InputGroup>
                {errors.confirmNewPassword && touched.confirmNewPassword ? (
                  <Text {...errorStyles}>{errors.confirmNewPassword}</Text>
                ) : null}
              </Box>

              {/* Submit Button */}
              <BaselaneButton
                styles={{ mt: '32px' }}
                variant="filled"
                palette="primary"
                size="md"
                onClick={() => handleFormSubmit(values)}
                isDisabled={!isValid}
              >
                Change Password
              </BaselaneButton>
            </form>
          )}
        </Formik>
      ) : (
        // Add SSO
        <Stack direction="row">
          <IconSmallGoogleLogo />
          <Text {...descriptionStyles}>
            You are using your Google Account to log into Baselane. You do not have a Baselane
            password available to change.
          </Text>
        </Stack>
      )}
      <BaselaneAlert
        isOpen={alertIsOpen}
        leastDestructiveRef={alertCancelRef}
        onClose={handleAlertOnClose}
        isCentered
        header={alertHeader}
        body={alertUpdateSuccessBody}
        footer={alertUpdateSuccessFooter}
        headerStyles={{ lineHeight: '32px' }}
        footerStyles={{ px: '32px', pb: '32px' }}
      />
    </Stack>
  );
}

export default Password;
