import React from 'react';
import {
  Box,
  Popover,
  PopoverContent,
  PopoverBody,
  PopoverTrigger,
  useMediaQuery,
} from '@chakra-ui/react';
import Checker from '@features/Authentication/components/Checker';
import {
  popoverTriggerStyles,
  popoverContentStyles,
  checkerStyles,
} from '../../styles/passwordChecker.styles';

type PasswordCheckerProps = {
  initialPopoverFocusRef: any,
  showChecklist: boolean,
  checklist: any,
  // Custom styles for the popover content.
  contentStyles: any,
};

function PasswordChecker({
  initialPopoverFocusRef,
  showChecklist,
  checklist,
  contentStyles = {},
}: PasswordCheckerProps) {
  const [isLargerThan899] = useMediaQuery('(min-width: 899px)', { ssr: false });

  const checker = <Checker checklist={checklist} styles={checkerStyles} />;
  return (
    <>
      {showChecklist && !isLargerThan899 && (
        <Box width="100%" mt="16px">
          {checker}
        </Box>
      )}
      {isLargerThan899 && (
        <Popover
          isOpen={showChecklist}
          initialFocusRef={initialPopoverFocusRef}
          placement="bottom-end"
          offset={[0, 28]}
          returnFocusOnClose={false}
        >
          <PopoverTrigger>
            <Box {...popoverTriggerStyles} />
          </PopoverTrigger>
          <PopoverContent {...popoverContentStyles} {...contentStyles}>
            <PopoverBody p="8px">{checker}</PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
}

export default PasswordChecker;
