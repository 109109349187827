import React from 'react';

function IconSkeletonVerticalBar1({
  width = 40,
  height = 80,
  color = 'currentColor',
}: {
  width?: string,
  height?: string,
  color?: string,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 4a4 4 0 014-4h32a4 4 0 014 4v152H0V4z" fill={color} />
    </svg>
  );
}

IconSkeletonVerticalBar1.defaultProps = {
  width: '40',
  height: '80',
  color: '#F0F2F6',
};

export default IconSkeletonVerticalBar1;
