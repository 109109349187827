import { React } from 'react';
import { useFormikContext } from 'formik';
import { FormControl, Box, useDisclosure } from '@chakra-ui/react';
import {
  BaselaneFormLabel,
  BaselaneSingleDatePicker,
  BaselaneFormErrorMessage,
} from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import getBreakPoints from '@core/utils/getBreakPoints';
import useValidDates from '@hooks/useValidDates';

const OneTimePayment = () => {
  const { isMin768 } = getBreakPoints();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formik = useFormikContext();
  const { values, setFieldValue, errors, touched } = formik;

  const { today, excludedDatesWithoutToday } = useValidDates();

  const isWeekdayOrToday = (date) => {
    const day = date.getDay();

    const isWeekday = day !== 0 && day !== 6; // Not Sunday (0) or Saturday (6)
    const isToday = date.toDateString() === new Date().toDateString();

    return isWeekday || isToday;
  };

  return (
    <Box mt={2}>
      {/* Send on */}
      <FormControl isRequired isInvalid={errors.sendOnDate && touched.sendOnDate}>
        <BaselaneFormLabel textStyle="sm" htmlFor="paymentDate">
          Send on
        </BaselaneFormLabel>
        <BaselaneSingleDatePicker
          {...{
            id: 'sendOnDate',
            name: 'sendOnDate',
            minDate: today,
            date: values.sendOnDate,
            value: values.sendOnDate
              ? `${formatDate(values.sendOnDate, 'MMM DD, YYYY')}`
              : `${formatDate(new Date(), 'MMM DD, YYYY')}`,
            dateFormat: 'MMM dd, yyyy',
            handleCalendarClose: ({ date: sendOnDate, isFromApplyButton }) => {
              if (isMin768 || (!isMin768 && isFromApplyButton)) {
                setFieldValue('sendOnDate', sendOnDate);
              }
            },
            size: 'lg',
            years: { start: new Date().getFullYear(), end: new Date().getFullYear() + 5 },
            isOpen,
            onOpen,
            onClose,
            hideInputIcon: false,
            fixedHeight: true,
            placement: 'bottom-end',
            showInModal: !isMin768,
            filterDate: isWeekdayOrToday,
            excludeDates: excludedDatesWithoutToday,
          }}
        />
        <BaselaneFormErrorMessage isInvalid={errors.sendOnDate && touched.sendOnDate}>
          {errors.sendOnDate}
        </BaselaneFormErrorMessage>
      </FormControl>
    </Box>
  );
};

export default OneTimePayment;
