// @flow
import React from 'react';
import Details from './Details';
import Financials from './Financials/index';

type DrawerPanelProps = {
  isDirty: boolean,
  setIsDirty: Function,
  setIsValid: Function,
  setIsError: Function,
  onClose: Function,
  setDetailsComplete: Function,
  detailsComplete: boolean,
  property: Object,
  setShowZillow: Function,
  setShowMortgage: Function,
  setFormVariables: Function,
  setHideFooter: Function,
  initialValues: Object,
  variables: Object,
  financials: Boolean,
  propertyFormRef: Object,
};

function DrawerPanel({
  isDirty,
  setIsDirty,
  setIsValid,
  setIsError,
  onClose,
  setDetailsComplete,
  detailsComplete,
  property,
  setShowZillow,
  setShowMortgage,
  setFormVariables,
  setHideFooter,
  initialValues,
  variables,
  financials,
  propertyFormRef,
}: DrawerPanelProps): any {
  const { marketPrice, mortgageBalance } = variables;

  return financials ? (
    <Financials
      {...{
        isDirty,
        property,
        onClose,
        setDetailsComplete,
        detailsComplete,
        setShowZillow,
        setShowMortgage,
        setFormVariables,
        setIsDirty,
        setHideFooter,
        initialValues,
        marketPrice,
        mortgageBalance,
        propertyFormRef,
      }}
    />
  ) : (
    <Details
      {...{
        isDirty,
        setIsDirty,
        setIsValid,
        setIsError,
        onClose,
        setDetailsComplete,
        detailsComplete,
        property,
        setFormVariables,
        initialValues,
        propertyFormRef,
      }}
    />
  );
}

export default DrawerPanel;
