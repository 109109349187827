import React from 'react';
import { Stack, HStack, Spacer, Text } from '@chakra-ui/react';

type SharedHeaderProps = {
  currentStep: Number,
  totalSteps: Number,
};

const SharedHeader = ({ currentStep, totalSteps }: SharedHeaderProps) => {
  const getSharedHeaderForCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return { title: 'Transfer details' };

      case 2:
        return {
          title: 'Review and transfer',
          description: 'Your funds will be available within 3 business days.',
          description2:
            'Transfer requests received after 3:00 PM ET, on weekends, or on federal holidays will be processed the next business day.',
        };

      default:
        return null;
    }
  };

  const { title, description, description2 } = getSharedHeaderForCurrentStep() ?? {};

  return (
    <Stack gap={0.5} mb={3}>
      <HStack>
        <Text textStyle="headline-md" color="brand.neutral.900">
          {title}
        </Text>
        <Spacer />
        <Text textStyle="xs" color="brand.neutral.600">
          Step {`${currentStep} of ${totalSteps - 1}`}
        </Text>
      </HStack>
      {description && (
        <Text textStyle="sm" color="brand.neutral.600">
          {description}
        </Text>
      )}
      {description2 && (
        <Text textStyle="sm" color="brand.neutral.600">
          {description2}
        </Text>
      )}
    </Stack>
  );
};

export default SharedHeader;
