import React from 'react';
import BaselaneResponsiveCell from './BaselaneResponsiveCell';
import BaselaneDropdown from '../../BaselaneDropdown';

type BaselaneResponsiveDropdownCellProps = {
  /**
   * Cell children
   */
  children?: any,

  /**
   * Props for dropdown component
   */
  dropdownProps?: any,
};

/**
 * Renders a variation of BaselaneReponsiveCell to be used inside BaselaneResponsiveTableRow
 * For dropdpown elements
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveCellDropdown = ({
  children,
  dropdownProps,
  ...rest
}: BaselaneResponsiveDropdownCellProps) => {
  return (
    <BaselaneResponsiveCell stopEventPropagation {...{ overflow: 'initial' }} {...rest}>
      <BaselaneDropdown {...dropdownProps} />
      {children}
    </BaselaneResponsiveCell>
  );
};

BaselaneResponsiveCellDropdown.defaultProps = {
  children: null,
  dropdownProps: {},
};

export default BaselaneResponsiveCellDropdown;
