import React from 'react';
import { BaselaneButton } from '@shared/components';

type CloseAccountButtonProps = {
  buttonProps?: Object,
  handleCloseSubAccount: Function,
};

const CloseAccountButton = ({ buttonProps, handleCloseSubAccount }: CloseAccountButtonProps) => {
  return (
    <BaselaneButton
      size="sm"
      variant="outline"
      palette="danger"
      onClick={handleCloseSubAccount}
      {...buttonProps}
    >
      Close account
    </BaselaneButton>
  );
};

CloseAccountButton.defaultProps = {
  buttonProps: {},
};

export default CloseAccountButton;
