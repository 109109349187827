import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { FormControl, FormLabel, Spacer, Switch } from '@chakra-ui/react';
import AutoTagTable from './AutoTagTable';
import {
  formLabelStyles,
  formSwitchRowStyles,
  formSwitchStyles,
} from '../../styles/formControls.styles';

type AutoTagProps = {
  values: Object,
  handleFormUpdate: Function,
  handleBlur: Function,
  handleChange: Function,
  setFieldValue: Function,
  enableTransactionInfoMessage: any,
  dirty: boolean,
  readOnly?: boolean,
  label?: String,
};

const AutoTag = ({
  values,
  handleFormUpdate,
  handleBlur,
  handleChange,
  setFieldValue,
  enableTransactionInfoMessage,
  dirty,
  readOnly,
  label,
}: AutoTagProps) => {
  const resetControl = () => {
    setFieldValue('autoTag.propertyId', null);
    setFieldValue('autoTag.propertyUnitId', null);
    const newValues = {
      autoTag: {
        enabled: values.autoTag.enabled,
      },
    };
    handleFormUpdate('autoTag.propertyId', newValues, true);
  };

  useEffect(() => {
    if (!values.autoTag.enabled && values.autoTag?.propertyId) {
      resetControl();
    }
  }, [values.autoTag.enabled]);

  return (
    <>
      {/* Import Transactions Enabled */}
      {values.importTransaction.enabled && (
        <>
          {/* Auto-Tagging Switch */}
          <FormControl {...formSwitchRowStyles}>
            <FormLabel {...formLabelStyles.sm} fontWeight={isMobile ? 'medium' : 'normal'}>
              {label}
            </FormLabel>
            <Spacer />
            <Switch
              className="primary-500"
              {...formSwitchStyles}
              id="autoTag.enabled"
              name="autoTag.enabled"
              isChecked={!!values.autoTag.enabled}
              onChange={handleChange}
              onBlur={(e) => {
                handleFormUpdate('autoTag', values, dirty);
                handleBlur(e);
              }}
              isDisabled={readOnly}
            />
          </FormControl>

          {/* Disclaimer for Auto-Tagging */}
          {enableTransactionInfoMessage}

          {/* Auto-Tagging Enabled */}
          {values.autoTag.enabled && (
            <AutoTagTable
              {...{
                dirty,
                setFieldValue,
                handleChange,
                handleBlur,
                handleFormUpdate,
                readOnly,
              }}
              autoTag={values.autoTag}
            />
          )}
        </>
      )}
    </>
  );
};

AutoTag.defaultProps = {
  readOnly: false,
  label: `Enable Transaction Auto-Tagging to a Property or Unit (Optional)`,
};

export default AutoTag;
