import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Text,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Icon12Error } from '@icons/12px/index';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import { textStyles } from './styles/businessInformation.styles';

type WebsiteInputProps = {
  values: Object,
  errors: Object,
  touched: Object,
  handleChange: Function,
  handleBlur: Function,
  setFieldTouched: Function,
  setFieldValue: Function,
};

const WebsiteInput = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
}: WebsiteInputProps) => {
  const [hasNoBusinessWebsite, setHasNoBusinessWebsite] = useState(values?.website === `N/A`);
  return (
    <FormControl isRequired isInvalid={errors.website && touched.website}>
      <FormLabel htmlFor="website" {...formLabelStyles.xs}>
        Website or Social Media Page
      </FormLabel>
      <Input
        id="website"
        name="website"
        placeholder="e.g. https://example.com"
        value={values?.website}
        onChange={handleChange}
        onBlur={handleBlur}
        isDisabled={hasNoBusinessWebsite}
        {...formInputStyles}
      />
      <FormErrorMessage {...formErrorStyles} ml={0}>
        <Box as="span" mr={0.5}>
          <Icon12Error />
        </Box>
        {errors.website}
      </FormErrorMessage>
      <Checkbox
        {...{ mt: 1 }}
        id="has-no-website"
        isChecked={hasNoBusinessWebsite}
        onBlur={handleBlur}
        onChange={(e) => {
          setHasNoBusinessWebsite(e.target.checked);
          if (e.target.checked) {
            setFieldTouched('website', true);
            setFieldValue('website', `N/A`);
          } else {
            setFieldTouched('website', true);
            setFieldValue('website', '');
          }
        }}
      >
        <Text as="label" htmlFor="has-no-website" {...textStyles}>
          My business does not have a website.
        </Text>
      </Checkbox>
    </FormControl>
  );
};

export default WebsiteInput;
