const color = 'brand.neutral.700';
const color600 = 'brand.neutral.600';

export const iconContainerStyles = (iconBg) => {
  return {
    w: '48px',
    h: '48px',
    bg: iconBg,
    borderRadius: '50%',
    align: 'center',
    justify: 'center',
  };
};

export const titleTextStyles = {
  color,
  textStyle: 'headline-md',
  py: '24px',
};

export const bodyTextStyles = {
  color,
  textStyle: 'sm',
};

export const containerCenterStyles = { p: '52px 32px 26px' };
export const iconContainerCenterStyles = { p: '0', m: '12px 0!important' };
export const titleTextCenterStyles = {
  color,
  fontWeight: 'semibold',
  fontSize: '2xl',
  fontStyle: 'normal',
  lineHeight: '32px',
  p: '0!important',
  m: '12px 0!important',
  mt: '0!important',
};

export const bodyTextCenterStyles = {
  color600,
  textStyle: 'sm',
  fontStyle: 'normal',
};
