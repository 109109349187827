import { truncatedText } from '@core/components/Shared/styles/text.style';

export const documentCardStyles = {
  card: {
    p: '16px',
    borderRadius: '6px',
    bg: 'brand.neutral.white',
    borderWidth: '1px',
    borderColor: 'brand.darkBlue.150',
  },
  textcontainer: {
    overflow: 'hidden',
  },
  nameStyle: {
    fontSize: 'xs',
    lineHeight: '14px',
    ...truncatedText,
  },
  typeStyle: {
    fontSize: '3xs',
    lineHeight: '14px',
    mt: '4px !important',
    ...truncatedText,
  },
};
