import React from 'react';
import { Box } from '@chakra-ui/react';
import { dotButtonDarkModeStyles, dotButtonWhiteModeStyles } from './styles/button.styles';

interface SlideIndicatorButtonProps {
  index: Number;
  selectedIndex: Number;
  isDarkMode: Boolean;
  onClick: Boolean;
}

const SlideIndicatorButton = ({
  index,
  selectedIndex,
  isDarkMode,
  onClick,
}: SlideIndicatorButtonProps) => {
  const styles = isDarkMode
    ? dotButtonDarkModeStyles(index === selectedIndex)
    : dotButtonWhiteModeStyles(index === selectedIndex);
  return <Box {...styles} type="button" onClick={onClick} />;
};

export default SlideIndicatorButton;
