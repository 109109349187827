import React from 'react';
import { HStack, FormLabel, Box } from '@chakra-ui/react';
import { Icon12Info } from '@icons/12px';
import BaselaneConditionalTooltip from '../../BaselaneConditionalTooltip';

import {
  supportingTextStyles,
  labelWrapperStyles,
  tooltipIconStyles,
  labelTooltipWrapperStyles,
} from './styles/formLabel.styles';

function BaselaneFormLabel({
  children,
  tooltipText,
  tooltipIcon,
  supportingText,
  htmlFor,
  ...props
}: any) {
  const hasTooltip = tooltipText && tooltipText?.length;
  const hasSupportingText = supportingText && supportingText?.length;

  return hasTooltip ? (
    <>
      <HStack {...labelTooltipWrapperStyles}>
        <FormLabel {...props} htmlFor={htmlFor} mb={hasSupportingText && '0 !important'} mr={0}>
          <Box {...labelWrapperStyles}>{children}</Box>
        </FormLabel>
        <BaselaneConditionalTooltip condition={tooltipText} tooltipText={tooltipText}>
          <Box {...tooltipIconStyles(hasSupportingText)}>{tooltipIcon || <Icon12Info />}</Box>
        </BaselaneConditionalTooltip>
      </HStack>
      {hasSupportingText && <Box {...supportingTextStyles}>{supportingText}</Box>}
    </>
  ) : (
    <>
      <FormLabel {...props} mb={hasSupportingText && '0 !important'}>
        {children}
      </FormLabel>
      {hasSupportingText && <Box {...supportingTextStyles}>{supportingText}</Box>}
    </>
  );
}

export default BaselaneFormLabel;
