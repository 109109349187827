import React from 'react';
import { Stack, Text, Box } from '@chakra-ui/react';
import { infobox } from './styles/infoBoxList.styles';

type InfoBoxWithIconProps = { id: string, icon: string, title: string, description: string };

function InfoBoxWithIcon({ id, icon, title, description }: InfoBoxWithIconProps) {
  const { boxStyles, textContainerStyles, titleStyles, descriptionStyles } = infobox ?? {};

  return (
    <Box {...boxStyles} key={id}>
      <Box>{icon}</Box>
      <Stack {...textContainerStyles}>
        <Text {...titleStyles}>{title}</Text>
        <Text {...descriptionStyles}>{description}</Text>
      </Stack>
    </Box>
  );
}

export default InfoBoxWithIcon;
