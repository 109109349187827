import React from 'react';
import { useFormikContext } from 'formik';
import { FormControl, Switch } from '@chakra-ui/react';

import { BaselaneAutoTagPropertyField, BaselaneFormLabel } from '@shared/components';
import { fieldAutotagStyles } from '../styles/form.styles';

const AccountAutoTag = () => {
  const { values, handleChange, setFieldValue } = useFormikContext();

  const handleSwitch = (e) => {
    handleChange(e);
    if (!e.target.checked) {
      setFieldValue('autoTag.propertyId', null);
      setTimeout(() => {
        setFieldValue('autoTag.propertyUnitId', null);
      }, 0);
    }
  };

  return (
    <>
      <FormControl {...fieldAutotagStyles.container}>
        <BaselaneFormLabel htmlFor="autoTag.enabled">
          Auto-tag all transactions to a property or unit
        </BaselaneFormLabel>
        <Switch
          id="autoTag.enabled"
          name="autoTag.enabled"
          isChecked={values.autoTag.enabled}
          value={values.autoTag.enabled}
          onChange={handleSwitch}
        />
      </FormControl>
      {values.autoTag.enabled && <BaselaneAutoTagPropertyField isDisabled={false} />}
    </>
  );
};

export default AccountAutoTag;
