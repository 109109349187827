import { formErrorAnatomy as parts } from '@chakra-ui/anatomy';
import { cssVar, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

const $fg = cssVar('form-error-color');

const baseStyleText = defineStyle({
  color: `red.700`,
  fontSize: 'xs',
  mt: 0.75,
});

const baseStyleIcon = defineStyle({
  [$fg.variable]: `red.700`,
  color: $fg.reference,
});

const baseStyle = definePartsStyle({
  text: baseStyleText,
  icon: baseStyleIcon,
});

export const FormError = defineMultiStyleConfig({
  baseStyle,
});
