import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { Icon16Close } from '@icons/16px';
import { BaselaneButtonIcon } from '../../BaselaneButtonIcon';
import ClearAllButton from '../ClearAllButton';
import { filterWrapperHeaderStyles } from '../styles/filterWrapper.styles';

type PopupHeaderProps = {
  handlePopupClose: Function,
  selectedStagedOptions: Array<object>,
  title: string,
  handleClearClick: Function,
  hideClearButton: boolean,
};

const PopupHeader = ({
  handlePopupClose,
  selectedStagedOptions,
  title,
  handleClearClick,
  hideClearButton,
}: PopupHeaderProps) => {
  return (
    <Stack {...filterWrapperHeaderStyles} direction="row" spacing={0}>
      <BaselaneButtonIcon icon={<Icon16Close />} onClick={handlePopupClose} />
      <Text textStyle="headline-sm" textAlign="center" width="calc(100% - 80px)">
        Select {title}
      </Text>

      {!hideClearButton && (
        <ClearAllButton selectedStagedOptions={selectedStagedOptions} onClear={handleClearClick} />
      )}
    </Stack>
  );
};

export default PopupHeader;
