const categoryOrder = [
  'Revenue',
  'Operating Expenses',
  'Loan Payments & Capex',
  'Property Transactions',
  'Transfers & Other',
  'Non-Property Expense',
];

const sortItemsComparator = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const sortCategories = (categoryOptions) => {
  const sortedCategoryOptions = [];

  if (categoryOptions && categoryOptions.length > 0) {
    categoryOrder.forEach((cat) => {
      sortedCategoryOptions.push(categoryOptions.find((obj) => obj.title === cat));
    });
    sortedCategoryOptions.forEach((option) => {
      option?.items.sort(sortItemsComparator);
    });
  }

  return sortedCategoryOptions;
};
export default sortCategories;
