import React from 'react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { IconChevronRight, IconCheck, IconChevronLeft, IconMoneyCircle } from '@icons';

export const footerConfig = ({
  onPrevClicked,
  onNextClicked,
  onCloseMailCheckDrawer,
  handleMailCheckTransferSubmit,
  reinitialize,
  makeTransferOrPaymentDrawerRef,
  handleOnDrawerClose,
  checkToken,
}) => {
  const { isMinXL } = getBreakPoints();

  const defaultLeftConfig = {
    leftBtnClick: onPrevClicked,
    leftBtnType: 'secondary',
    leftBtnvariant: 'outline',
    leftBtnPalette: 'neutral',
    leftBtnText: 'Back',
    leftBtnLeftIcon: <IconChevronLeft />,
  };
  const defaultRightConfig = {
    rightBtnText: 'Next',
    rightBtnvariant: 'filled',
    rightBtnPalette: 'primary',
    rightBtnRightIcon: <IconChevronRight />,
    rightBtnClick: onNextClicked,
  };

  return {
    1: {
      leftBtnText: 'Close',
      leftBtnvariant: 'outline',
      leftBtnPalette: 'neutral',
      leftBtnClick: handleOnDrawerClose,
      ...defaultRightConfig,
    },
    2: { ...defaultLeftConfig, ...defaultRightConfig },
    3: {
      ...defaultLeftConfig,
      ...defaultRightConfig,
      rightBtnText: isMinXL ? 'Review' : 'Review Transfer',
    },
    4: {
      ...defaultLeftConfig,
      rightBtnText: 'Send check',
      rightBtnvariant: 'filled',
      rightBtnPalette: 'primary',
      rightBtnLeftIcon: <IconCheck />,
      rightBtnClick: () => {
        checkToken(handleMailCheckTransferSubmit);
      },
    },
    5: {
      leftBtnClick: () => {
        onCloseMailCheckDrawer();
        reinitialize();
        makeTransferOrPaymentDrawerRef?.current?.close();
      },
      leftBtnText: 'Close',
      leftBtnVariant: 'outline',
      leftBtnPalette: 'neutral',
      leftBtnStyles: { width: isMinXL ? '100%' : 'auto' },

      rightBtnText: 'Make another transfer',
      rightBtnvariant: 'filled',
      rightBtnPalette: 'primary',
      rightBtnLeftIcon: <IconMoneyCircle />,
      rightBtnClick: reinitialize,
      rightBtnStyles: {
        width: isMinXL ? '100%' : 'auto',
        mb: isMinXL ? '8px !important' : 'auto',
      },
      containerStyles: {
        spacing: isMinXL ? 0 : 4,
        direction: !isMinXL ? 'row' : 'column',
        flexDirection: isMinXL ? 'column-reverse' : 'row',
      },
    },
  };
};
