export const getPropertyName = (pId, uId, propertyOptions) => {
  if (!pId && !uId) return '-';
  if (pId && !uId) return propertyOptions.find(({ id }) => pId === id).name || '-';

  const propertyName = propertyOptions.find(({ id }) => pId === id).name;
  const unitName = propertyOptions.find(({ id }) => `${pId}-${uId}` === id).name;

  return `${propertyName} - ${unitName}`;
};

export const getPropertyAddress = (pId, propertyOptions) => {
  if (!pId) return '-';
  const { address, unit, city, state, zipCode } = propertyOptions.find(
    ({ id }) => pId === id
  ).address;
  return `${address}, ${unit ? `${unit},` : ''} ${city}, ${state} ${zipCode}`;
};

export const getCategoryName = (catId, categoryOptions, categoryIdsMap) => {
  if (!catId) return '-';
  const allSubcategories = categoryOptions.reduce((prev, current) => {
    return prev.concat(current.items);
  }, []);
  const categoryId = categoryIdsMap[catId];
  return allSubcategories.find(({ id }) => id === categoryId)?.name || '-';
};

export const getAutoTaggedProperty = (bankObject, banks) => {
  let propertyId = null;
  let unitId = null;

  banks?.forEach((bank) => {
    const isBankWithBankAccount = bank?.id === bankObject?.accountId;
    if (isBankWithBankAccount) {
      const { propertyId: pId = null, propertyUnitId: uid = null } =
        bank?.bankAccounts?.find((ba) => ba.id === bankObject?.bankAccountId)?.autoTag ?? {};
      propertyId = pId;
      unitId = uid;
    }
  });

  const formattedUnitId = unitId ? `-${unitId}` : unitId;
  const propertyUnitId = `${propertyId}${formattedUnitId}`;
  if (propertyId) {
    return propertyUnitId;
  }
  return null;
};
