export const buttonStyles = (isLogin) => {
  return {
    width: isLogin
      ? { xxs: '100%', xs: '50%', sm: '50%', md: '50%', lg: '50%', xl: '50%' }
      : { base: '100%' },
    justifyContent: 'center',
    fontSize: 'calc(14.25px + 2 * ((100vw - 600px) / 840))',
    mx: { base: '0' },
    borderColor: 'brand.darkBlue.200',
    lineHeight: '20px',
    minWidth: '250px',
    borderRadius: '4px!important',
    fontWeight: 'medium',
    color: '#012550',
    bg: 'brand.neutral.white',
    p: '10px 24px',
    h: '48px',
    _hover: {
      borderColor: 'brand.neutral.600',
      color: 'brand.neutral.600',
    },
  };
};

export const textStyles = {
  textStyle: 'md',
  my: '12px',
};
