// @flow
import React from 'react';
import { Text } from '@chakra-ui/react';
import { contentTextStyles } from '../styles/tenantInfo.style';

function PropertyDescription({
  propertyName,
  propertyUnitName,
}: {
  propertyName: String,
  propertyUnitName: String,
}): any {
  const hasPropertyUnitName = propertyUnitName && propertyName !== propertyUnitName;
  const name = `${propertyName}${hasPropertyUnitName ? `, ${propertyUnitName}` : ''}`;

  return <Text {...contentTextStyles}>{name}</Text>;
}

export default PropertyDescription;
