import React from 'react';

function IconOther({
  w = 32,
  h = 36,
  color = 'currentColor',
}: {
  w?: string,
  h?: string,
  color?: string,
}) {
  return (
    <svg width={w} height={h} viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.289 6.62224V29.7333C31.289 32.8752 28.742 35.4222 25.6001 35.4222H6.40007C3.25818 35.4222 0.711182 32.8752 0.711182 29.7333V6.62224C0.711182 3.48035 3.25818 0.93335 6.40007 0.93335H25.6001C28.742 0.93335 31.289 3.48035 31.289 6.62224ZM6.40007 3.77779C4.82913 3.77779 3.55563 5.0513 3.55563 6.62224V29.7333C3.55563 31.3043 4.82913 32.5778 6.40007 32.5778H25.6001C27.171 32.5778 28.4445 31.3043 28.4445 29.7333V6.62224C28.4445 5.0513 27.171 3.77779 25.6001 3.77779H6.40007Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6446 18C14.8592 18 14.2224 17.3632 14.2224 16.5778L14.2224 12.6667C14.2224 11.8812 14.8592 11.2444 15.6446 11.2444C16.4301 11.2444 17.0669 11.8812 17.0669 12.6667L17.0669 16.5778C17.0669 17.3632 16.4301 18 15.6446 18Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0445 35.4223H9.6001V27.9556C9.6001 25.5992 11.5103 23.689 13.8668 23.689H17.7779C20.1343 23.689 22.0445 25.5992 22.0445 27.9556V35.4223ZM13.8668 26.5334C13.0813 26.5334 12.4445 27.1702 12.4445 27.9556V32.5779H19.2001V27.9556C19.2001 27.1702 18.5633 26.5334 17.7779 26.5334H13.8668Z"
        fill={color}
      />
    </svg>
  );
}

IconOther.defaultProps = {
  w: '32',
  h: '36',
  color: 'currentColor',
};

export default IconOther;
