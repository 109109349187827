export const formStyles = {
  controlStyles: {},
  labelSubtextStyles: {
    color: 'brand.neutral.500',
  },
};

export const spinnerStyles = {
  color: 'brand.blue.700',
  size: 'xl',
  position: 'absolute',
  top: '45%',
  left: '45%',
};
