// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { checkFormCircleStyles } from '../../styles/tabs.styles';
import { isOptionDisabled } from '../helpers/tab.helpers';
import type { FlowPanelProps } from '../../types/FlowTabPanelProps';

type TabNumberProps = {
  menuOptions: Array<FlowPanelProps>,
  option: Object,
  unitState: string,
  index: Number,
};

function TabNumber({ menuOptions, option, unitState, index }: TabNumberProps): any {
  const bgColorDisabled = isMobile ? 'transparent' : 'brand.darkBlue.100';
  const bgColorEnabled = isMobile ? 'transparent' : 'brand.blue.100';
  const borderColorDisabled = isMobile ? '1px solid brand.darkBlue.100' : 'none';
  const borderColorEnabled = isMobile ? '1px solid brand.blue.700' : 'none';

  return (
    <Box
      {...checkFormCircleStyles(isMobile)}
      bg={
        isOptionDisabled({ option, index, unitState, menuOptions })
          ? { bgColorDisabled }
          : { bgColorEnabled }
      }
      borderColor={
        isOptionDisabled({ option, index, unitState, menuOptions })
          ? { borderColorDisabled }
          : { borderColorEnabled }
      }
    >
      {index + 1}
    </Box>
  );
}

export default TabNumber;
