// @flow
import React, { useState } from 'react';
import moment from 'moment';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Heading, Stack, Text } from '@chakra-ui/react';
import { USER_PROFILE_UPDATE_ACCOUNTS } from '@routes';
import { BaselaneChip } from '@shared/components';
import SlLoader from '@core/components/Loader';
import { Icon16Calendar } from '@icons/16px';
import { formatDate } from '@core/utils/formatDate';
import getBreakPoints from '@core/utils/getBreakPoints';
import { GET_USER_METADATA_PROMOTION } from '@core/apollo/queries';
import { GET_LEASE_METADATA, GET_LEASE_ACH_FEE } from '@pages/LeasesPage/queries';
import { getSortedLeaseData } from '@shared/helpers/leases.helpers';
import WhoPaysACH from './components/WhoPaysACH';
import UnitSpecificSettings from './components/UnitSpecificSettings';
import { ChangeSettingsAlerts, UpdateReceivingAccountsAlert } from './components/ACHAlert';
import { rentCollectionStyles } from './styles/rentCollection.styles';

function RentCollection(): any {
  const { isMax576 } = getBreakPoints();
  const navigate = useNavigate();

  const [changeSettings, setChangeSettings] = useState(false);

  const { loading: isUserLoading, error: hasUserError, data: userData } = useQuery(
    GET_USER_METADATA_PROMOTION
  );

  const {
    loading: isLeaseMetaDataLoading,
    error: hasLeaseMetaDataError,
    data: leaseMetaData,
  } = useQuery(GET_LEASE_METADATA, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: activeAndExpiredLeaseData,
    loading: isActiveAndExpiredLeaseDataLoading,
    error: hasActiveAndExpiredLeaseDataError,
  } = useQuery(GET_LEASE_ACH_FEE, {
    variables: {
      input: { state: ['SCHEDULED'] },
    },
    fetchPolicy: 'cache-and-network',
  });

  const hasActiveRC = activeAndExpiredLeaseData?.leases?.length > 0;
  const { leaseCollectingRentOutsideBaselane } = leaseMetaData?.leaseMetaData ?? {};
  const numberOfLeasesCollectingRentOutsideBaselane = leaseCollectingRentOutsideBaselane?.length;
  const hasLeaseCollectingRentOutsideBaselane = numberOfLeasesCollectingRentOutsideBaselane > 0;
  const showWhoPaysACH =
    hasLeaseCollectingRentOutsideBaselane ||
    (!hasLeaseCollectingRentOutsideBaselane && changeSettings);

  const { data: leaseData } = useQuery(GET_LEASE_ACH_FEE, {
    variables: {
      input: { invoiceProcessingFeePaidByOverridden: true, state: ['SCHEDULED', 'EXPIRED'] },
    },
    fetchPolicy: 'cache-and-network',
    skip: leaseMetaData && !showWhoPaysACH,
  });

  if (isUserLoading || isLeaseMetaDataLoading || isActiveAndExpiredLeaseDataLoading)
    return <SlLoader />;
  if (hasUserError || hasLeaseMetaDataError || hasActiveAndExpiredLeaseDataError) return null;

  const { user } = userData ?? {};

  const { userMetadata, userPromotion } = user ?? {};
  const achFeePromotion = userPromotion?.find(
    (p) => p.promotion.type === 'FEES_WAIVER' && p.promotion.feeType === 'ACH_FEE'
  );

  const todaysDate = moment(new Date().toDateString());
  const userAchFeePromoStartDate = moment(achFeePromotion?.startDate);
  const userAchFeePromoEndDate = moment(achFeePromotion?.endDate);

  const isUserEligibleForAchFeePromo =
    achFeePromotion?.promotion &&
    todaysDate < userAchFeePromoEndDate &&
    todaysDate >= userAchFeePromoStartDate;

  // filtering the data in case cached data is updated w/o calling the server
  const filteredLeaseData = leaseData?.leases?.leases?.filter(
    (lease) => lease?.invoiceProcessingFeePaidByOverridden
  );
  const sortedLeases = getSortedLeaseData({ data: filteredLeaseData });
  const showUnitSpecificSettings = filteredLeaseData?.length > 0;

  const handleUpdateReceivingAccountsClick = () => {
    navigate(
      {
        pathname: USER_PROFILE_UPDATE_ACCOUNTS,
        search: createSearchParams({ activeTabIndex: 3 }).toString(),
      },
      { state: { leaseIds: leaseCollectingRentOutsideBaselane } }
    );
  };

  const handleChangeSettingsClick = () => {
    setChangeSettings(true);
  };

  const { container, innercontainer, header, description } = rentCollectionStyles(isMax576);

  return (
    <Stack {...container}>
      <Stack {...innercontainer}>
        <Heading {...header}>Fee for tenant ACH payments</Heading>
        <Stack gap={1}>
          <Text {...description}>
            A $2 fee will be charged to tenant ACH payments. Fees will be waived for ACH payments
            deposited into a Baselane Banking account.
          </Text>
          <Text {...description}>
            If collecting rent into an external bank account, you can choose to cover this fee or
            pass it on to your tenant.
          </Text>
        </Stack>

        {isUserEligibleForAchFeePromo && (
          <BaselaneChip
            size="lg"
            label={`Beginning ${formatDate(userAchFeePromoEndDate, 'MMM D, YYYY')}`}
            variant="simple-primary"
            leftIcon={Icon16Calendar}
          />
        )}
      </Stack>

      {hasLeaseCollectingRentOutsideBaselane ? (
        <UpdateReceivingAccountsAlert
          {...{ handleUpdateReceivingAccountsClick, numberOfLeasesCollectingRentOutsideBaselane }}
        />
      ) : (
        hasActiveRC && <ChangeSettingsAlerts {...{ handleChangeSettingsClick }} />
      )}

      {(showWhoPaysACH || !hasActiveRC) && (
        <WhoPaysACH {...{ userMetadata, hasLeaseCollectingRentOutsideBaselane }} />
      )}

      {showWhoPaysACH && showUnitSpecificSettings && <UnitSpecificSettings {...{ sortedLeases }} />}
    </Stack>
  );
}

export default RentCollection;
