import React from 'react';
import { BaselaneButtonIcon } from '@shared/components/BaselaneButtonIcon';
import BaselaneButton from '@shared/components/BaselaneButton';

type CustomButtonHandlerProps = {
  buttonType: String,
  buttonProps: String,
  styles: Object,
  isDisabled: boolean,
};

const renderCustomButtonHandler = ({ buttonProps, buttonType, styles }) => {
  const {
    icon: Icon,
    iconProps = {},
    size = 'md',
    variant = 'outline',
    palette = 'neutral',
    label = 'Select Item',
    onClick = () => {},
  } = buttonProps ?? {};

  switch (buttonType) {
    case 'icon':
      return (
        <BaselaneButtonIcon
          icon={<Icon {...iconProps} />}
          {...{ size, variant, palette, styles, onClick }}
        />
      );

    default:
      return (
        <BaselaneButton {...{ size, buttonType, palette, styles, onClick }}>{label}</BaselaneButton>
      );
  }
};

const CustomButtonHandler = ({
  buttonType = null,
  buttonProps = {},
  styles = {},
  isDisabled = false,
}: CustomButtonHandlerProps) => {
  return isDisabled ? null : renderCustomButtonHandler({ buttonProps, buttonType, styles });
};

export default CustomButtonHandler;
