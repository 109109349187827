// @flow
import React, { useState } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { GET_PROPERTIES_DROPDOWN } from '@core/apollo/queries';
import { BaselaneErrorCard, BaselaneHeaderCard, BaselaneDivider } from '@shared/components';
import {
  RenderWarningMessage,
  showWarningInDesktop,
  showWarningInMobile,
} from '@core/components/App/RenderWarningMessage';
import FiltersSection from './FiltersSection';
import type { Property } from '../Properties/types';
import KPIsTabs from './KPIsTabs';
import { buildInitialYearCheckboxOptions } from './utilities';

function getDefaultYears(initialDateFilterCheckedOptions) {
  return Object.keys(initialDateFilterCheckedOptions)
    .filter((year) => initialDateFilterCheckedOptions[year])
    .map(Number);
}

function PortfolioKPIsPage(): any {
  const currLocation = useLocation();

  const initialDateFilterCheckedOptions = buildInitialYearCheckboxOptions();

  const defaultYears = getDefaultYears(initialDateFilterCheckedOptions);

  const [filters, setFilters] = useState({
    filter: {
      year: defaultYears,
    },
  });

  const [aggregatingProperties, setAggregatingProperties] = useState(false);

  const { loading, error, data } = useQuery(GET_PROPERTIES_DROPDOWN);

  if (showWarningInMobile(currLocation) || showWarningInDesktop(currLocation)) {
    return <RenderWarningMessage />;
  }

  if (loading) {
    return <div> Loading... </div>;
  }

  if (error) {
    if (error.message.includes('Failed to fetch')) {
      return <BaselaneErrorCard />;
    }
    return null;
  }

  const properties: Property[] = data?.property;

  function handleSelectProperties(propertyIds: string[], isAggregatingProperties: boolean) {
    setFilters({
      filter: {
        ...filters.filter,
        propertyIds,
      },
    });

    setAggregatingProperties(isAggregatingProperties);
  }

  function handleSetDate(years: string[]) {
    setFilters({
      filter: {
        ...filters.filter,
        year: years,
      },
    });
  }

  const cardHeader = <Text>Summary</Text>;
  return (
    <div>
      <BaselaneHeaderCard
        headerLeft={cardHeader}
        headerRight={
          <HStack spacing="36px">
            <HStack>
              <Text textStyle="xs" color="brand.neutral.600">
                Total Properties
              </Text>
              <Text fontSize="2xl" fontWeight="medium">
                {properties?.length}
              </Text>
            </HStack>
            <BaselaneDivider isVertical />
            <FiltersSection
              onSelectProperties={handleSelectProperties}
              onSetDate={handleSetDate}
              properties={properties}
              initialDateFilter={initialDateFilterCheckedOptions}
            />
          </HStack>
        }
      >
        <KPIsTabs
          filters={filters}
          aggregatingProperties={aggregatingProperties}
          properties={properties}
        />
      </BaselaneHeaderCard>
    </div>
  );
}

export default PortfolioKPIsPage;
