import React from 'react';

type IconTimerCircleProps = {
  w: Number,
  h: Number,
  color: String,
  bgColor: String,
};

function IconTimerCircle({
  w = 24,
  h = 24,
  color = '#257ED0',
  bgColor = '#0968BF',
}: IconTimerCircleProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${w}px`} height={`${h}px`} fill="none">
      <circle cx="12" cy="12" r="12" fill={bgColor} opacity=".08" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm-4.42.58a6.25 6.25 0 1 1 8.84 8.84 6.25 6.25 0 0 1-8.84-8.84ZM12 8.876c.345 0 .625.28.625.625v2.241l1.692 1.692a.625.625 0 1 1-.884.884l-1.875-1.875a.624.624 0 0 1-.183-.442V9.5c0-.345.28-.625.625-.625Z"
        fill={color}
      />
    </svg>
  );
}

export default IconTimerCircle;
