import React from 'react';

type IconWarningCircleProps = {
  w?: Number,
  h?: Number,
  color?: String,
  bgColor?: String,
  opacity?: Number,
};

function IconWarningCircle({
  w = 24,
  h = 24,
  color = '#92630C',
  bgColor = '#FEF8E9',
  opacity = 1,
}: IconWarningCircleProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" opacity={opacity} fill={bgColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 6.66668C10.5854 6.66668 9.22888 7.22858 8.22868 8.22877C7.22849 9.22897 6.66659 10.5855 6.66659 12C6.66659 12.7004 6.80454 13.3939 7.07256 14.041C7.34059 14.6881 7.73344 15.276 8.22868 15.7712C8.72393 16.2665 9.31187 16.6593 9.95894 16.9274C10.606 17.1954 11.2995 17.3333 11.9999 17.3333C12.7003 17.3333 13.3938 17.1954 14.0409 16.9274C14.688 16.6593 15.2759 16.2665 15.7712 15.7712C16.2664 15.276 16.6593 14.6881 16.9273 14.041C17.1953 13.3939 17.3333 12.7004 17.3333 12C17.3333 10.5855 16.7713 9.22897 15.7712 8.22877C14.771 7.22858 13.4144 6.66668 11.9999 6.66668ZM7.28587 7.28596C8.53612 6.03572 10.2318 5.33334 11.9999 5.33334C13.768 5.33334 15.4637 6.03572 16.714 7.28596C17.9642 8.53621 18.6666 10.2319 18.6666 12C18.6666 12.8755 18.4941 13.7424 18.1591 14.5512C17.8241 15.3601 17.333 16.095 16.714 16.7141C16.0949 17.3331 15.36 17.8242 14.5511 18.1592C13.7423 18.4942 12.8754 18.6667 11.9999 18.6667C11.1244 18.6667 10.2575 18.4942 9.4487 18.1592C8.63986 17.8242 7.90493 17.3331 7.28587 16.7141C6.66682 16.095 6.17575 15.3601 5.84072 14.5512C5.50569 13.7424 5.33325 12.8755 5.33325 12C5.33325 10.2319 6.03563 8.53621 7.28587 7.28596ZM11.9999 8.66668C12.3681 8.66668 12.6666 8.96515 12.6666 9.33334V12C12.6666 12.3682 12.3681 12.6667 11.9999 12.6667C11.6317 12.6667 11.3333 12.3682 11.3333 12V9.33334C11.3333 8.96515 11.6317 8.66668 11.9999 8.66668ZM11.3333 14.6667C11.3333 14.2985 11.6317 14 11.9999 14H12.0066C12.3748 14 12.6733 14.2985 12.6733 14.6667C12.6733 15.0349 12.3748 15.3333 12.0066 15.3333H11.9999C11.6317 15.3333 11.3333 15.0349 11.3333 14.6667Z"
        fill={color}
      />
    </svg>
  );
}

IconWarningCircle.defaultProps = {
  w: 24,
  h: 24,
  color: '#92630C',
  bgColor: '#FEF8E9',
  opacity: 1,
};

export default IconWarningCircle;
