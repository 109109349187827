import React from 'react';
import { isMobile } from 'react-device-detect';
import { IconExclamationCircle } from '@icons';
import {
  AlertHeader,
  AlertFooter,
  BaselaneAlert,
  BaselaneButton,
  BaselaneButtonGroup,
} from '@shared/components';
import customTheme from '@theme';

type DeleteImportedTransactionsAlertProps = {
  isDeleteImportedTransactionsAlertOpen: boolean,
  onDeleteImportedTransactionsAlertClose: Function,
  cancelAlert: Function,
  deleteImportedTransactionsRef: any,
  handleSaveAndClose: Function,
};

const DeleteImportedTransactionsAlert = ({
  isDeleteImportedTransactionsAlertOpen,
  onDeleteImportedTransactionsAlertClose,
  cancelAlert,
  deleteImportedTransactionsRef,
  handleSaveAndClose,
}: DeleteImportedTransactionsAlertProps) => {
  const handleYesOnAlertClick = () => {
    handleSaveAndClose(true);
  };

  const footer = (
    <BaselaneButtonGroup size="md">
      <BaselaneButton
        ref={cancelAlert}
        variant="outline"
        palette="neutral"
        size="md"
        onClick={onDeleteImportedTransactionsAlertClose}
      >
        No, Keep My Transactions
      </BaselaneButton>
      <BaselaneButton variant="filled" palette="danger" size="md" onClick={handleYesOnAlertClick}>
        Yes, Delete Transactions
      </BaselaneButton>
    </BaselaneButtonGroup>
  );

  const mobileFooter = (
    <AlertFooter
      {...{ justify: 'stretch' }}
      cancelRef={cancelAlert}
      leftButtonEvent={handleYesOnAlertClick}
      leftButtonText="Yes, Delete Transactions"
      rightButtonEvent={onDeleteImportedTransactionsAlertClose}
      rightButtonText="No, Keep My Transactions"
    />
  );

  const header = (
    <AlertHeader
      isHorizontalCenter
      icon={<IconExclamationCircle {...{ color: '#EA6868' }} />}
      iconBgStyles={{ bg: 'red.200' }}
      title="Do you want to delete all previously imported transactions from this account?"
    />
  );

  const mobileHeader = (
    <AlertHeader
      {...{ justify: 'stretch' }}
      icon={<IconExclamationCircle {...{ color: customTheme.colors.red['500A'] }} />}
      title="Do you want to delete all previously imported transactions from this account?"
    />
  );

  return (
    <BaselaneAlert
      showCloseButton
      isOpen={isDeleteImportedTransactionsAlertOpen}
      onClose={onDeleteImportedTransactionsAlertClose}
      leastDestructiveRef={cancelAlert}
      isCentered
      header={isMobile ? mobileHeader : header}
      body="You have imported transactions from this account. Do you want to permanently delete them from Baselane?"
      footer={isMobile ? mobileFooter : footer}
      finalFocusRef={deleteImportedTransactionsRef}
      size="xl"
    />
  );
};

export default DeleteImportedTransactionsAlert;
