import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FieldArray } from 'formik';
import { Input, Box, HStack, Text } from '@chakra-ui/react';
import { Icon16Delete } from '@icons/16px';
import { BaselaneButton, BaselaneButtonIcon } from '@shared/components';
import { formLabelStyles } from '@shared/styles/input.style';
import { formSubLabelStyles, formInputStyles, formErrorStyles } from '../../../styles/form.style';

type MultipleUnitsProps = {
  values: Object,
  errors: Object,
  touched: Object,
  isValid: boolean,
  dirty: boolean,
  handleChange: Function,
  handleBlur: Function,
  isDirty: boolean,
  setIsDirty: Function,
  setIsValid: Function,
  setFormVariables: Function,
  setFieldValue: Function,
  setFieldTouched: Function,
};

function MultipleUnits({
  values,
  errors,
  touched,
  isValid,
  dirty,
  handleChange,
  isDirty,
  setIsDirty,
  setIsValid,
  setFormVariables,
  setFieldValue,
  setFieldTouched,
}: MultipleUnitsProps) {
  const [hasPressedDelete, setHasPressedDelete] = useState(false);

  useEffect(() => {
    setFormVariables({ unitNames: values.unitNames });
    setIsValid(isValid);
    setIsDirty({ ...isDirty, ...{ details: dirty } });
  }, [values.unitNames]);

  return (
    <FieldArray name="unitNames">
      {({ remove, push }) => (
        <Box>
          <HStack>
            <Text {...formLabelStyles.xs}>Unit Number or Name</Text>
            <Text {...formSubLabelStyles}>
              The unit number or name will be visible to your Tenants
            </Text>
          </HStack>
          {values.unitType === 'multiple' &&
            values.unitNames.length > 0 &&
            values.unitNames.map((unitName, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <HStack key={i} py="8px">
                <Input
                  {...formInputStyles}
                  id={`unitNames.${i}.name`}
                  name={`unitNames.${i}.name`}
                  placeholder={i < 2 ? `e.g. Unit ${i === 0 ? 'A' : 'B'}` : ''} // Show placeholder only for the first couple
                  value={unitName.name}
                  onChange={(e) => {
                    setFieldValue(`unitNames.${i}.name`, unitName.name);
                    setFieldTouched(`unitNames`, true);
                    handleChange(e);
                  }}
                />
                <BaselaneButtonIcon
                  id={`unit-${i}-delete-button`}
                  variant="outline"
                  palette="neutral"
                  isDisabled={
                    unitName.hasLease ||
                    (unitName.id && !unitName.isUnitDeletable) ||
                    values.unitNames.length === 1
                  }
                  icon={<Icon16Delete />}
                  onClick={() => {
                    setHasPressedDelete(true);
                    remove(i);
                  }}
                />
              </HStack>
            ))}
          <HStack pt="8px" pb={isMobile ? '32px' : '0'} position="relative">
            <BaselaneButton
              variant="outline"
              palette="neutral"
              onClick={() => {
                push({
                  name:
                    values.unitNames.length < 26 && !hasPressedDelete
                      ? `Unit ${String.fromCharCode(65 + values.unitNames.length)}`
                      : '',
                });
              }}
              isDisabled={errors.unitNames}
            >
              Add New
            </BaselaneButton>
            <Text {...formErrorStyles} right="0">
              {errors.unitNames || (touched.unitNames && <Box as="span">{errors.unitNames}</Box>)}
            </Text>
          </HStack>
        </Box>
      )}
    </FieldArray>
  );
}

export default MultipleUnits;
