const baseThStyles = {
  backgroundColor: '#FFFFFF',
  color: 'brand.neutral.600',
  w: '33%',
  maxWidth: '320px',
  padding: '16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '16px',
  textTransform: 'capitalize',
};

export const firstThStyles = {
  ...baseThStyles,
  verticalAlign: 'middle',
  textAlign: 'left!important',
};

export const secondThStyles = {
  ...baseThStyles,
  textAlign: 'center!important',
};

export const thirdThStyles = {
  ...baseThStyles,
  alignSelf: 'center',
  textAlign: 'center!important',
};

export const tdStyles = {
  ...baseThStyles,
  width: '33%',
  position: 'relative',
};

export const textStyles = {
  fontSize: '14px',
  color: 'brand.neutral.700',
  fontWeight: 'normal',
  lineHeight: '20px',
};

export const notesTextStyles = {
  fontSize: '8px',
  color: 'brand.neutral.500',
  position: 'absolute',
  top: '-4px',
  right: '-6px',
};
