import React from 'react';
import ListOfOptionsWithCheckboxes from './ListOfOptionsWithCheckboxes';
import ListOfOptions from './ListOfOptions';
import DropdownSearchWrapper from '../../DropdownSearch/DropdownSearchWrapper';

type CustomCategoryDropdownProps = {
  isMulti: boolean,
  options: Array<any>,
  selectedStagedOptions: Array<any>,
  selectedOptions: Array<any>,
  itemRenderer: Function,
  showCustomCategories?: Boolean,
  dropdownElementRef: any,
  handleListItemClick: Function,
  hasFilterWrapper: Boolean,
  title: String,
  searchTerm: Array<String>,
  search: String,
  setSearch: Function,
  searchRef: any,
  animationVariant?: String,
  scrollTop?: Number,
  hideSearch?: Boolean,
  setShowMobileDropdownPopup?: Function,
  setShowMobileDropdownPopupAnimation?: Function,
};

const CustomCategoryDropdown = ({
  isMulti,
  options,
  selectedStagedOptions,
  selectedOptions,
  itemRenderer,
  showCustomCategories,
  dropdownElementRef,
  handleListItemClick,
  hasFilterWrapper,
  title,
  searchTerm,
  search,
  setSearch,
  searchRef,
  animationVariant,
  scrollTop,
  hideSearch,
  setShowMobileDropdownPopup,
  setShowMobileDropdownPopupAnimation,
}: CustomCategoryDropdownProps) => {
  return (
    <DropdownSearchWrapper
      {...{
        showCustomCategories,
        title,
        search,
        setSearch,
        searchRef,
        animationVariant,
        scrollTop,
        hideSearch,
        dropdownElementRef,
        selectedOptions,
      }}
    >
      {isMulti ? (
        <ListOfOptionsWithCheckboxes
          ref={dropdownElementRef}
          {...{
            options,
            selectedStagedOptions,
            itemRenderer,
            handleListItemClick,
            hasFilterWrapper,
            searchTerm,
            search,
          }}
        />
      ) : (
        <ListOfOptions
          {...{
            options,
            selectedStagedOptions,
            itemRenderer,
            handleListItemClick,
            hasFilterWrapper,
            searchTerm,
            search,
            setShowMobileDropdownPopup,
            setShowMobileDropdownPopupAnimation,
          }}
        />
      )}
    </DropdownSearchWrapper>
  );
};

CustomCategoryDropdown.defaultProps = {
  showCustomCategories: null,
  animationVariant: null,
  scrollTop: 0,
  hideSearch: false,
  setShowMobileDropdownPopup: () => {},
  setShowMobileDropdownPopupAnimation: () => {},
};

export default CustomCategoryDropdown;
