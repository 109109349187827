import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Downshift from 'downshift';
import {
  Input,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Stack,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import customTheme from '@core/theme';
import IconLocation from '@icons/legacy/IconLocation';
import {
  predictionItemStyles,
  predictionsContainerStyles,
  containerStyles,
} from './styles/autocomplete.styles';
import { getTempAddressObject, fetchOptions } from './helper';
import getEnvAppUrl from '../../../../constants/envAppUrl';

type BaselaneAutoCompleteAddressProps = {
  values: Object,
  errors: Object,
  touched: Object,
  isValid?: boolean,
  handleChange: Function,
  handleBlur: Function,
  setIsDirty?: Function,
  setFormVariables?: Function,
  setIsValid?: Function,
  formLabelStyles: Object,
  formInputStyles: Object,
  formErrorStyles: Object,
  formContainerStyles?: Object,
  showPredictionsAbove?: boolean,
  placeholder?: String,
  showFullAddress?: boolean,
  icon?: any,
  cfieldContainer?: any, // TODO: check if this is needed, looks like it is just overriding input styles
  setHasEnteredAddress?: Function,
  setValue?: Function,
  setTouched?: Function,
  showIsRequired?: boolean,
  validateWithoutTouched?: boolean,
  maxLength: Number,
};

function BaselaneAutoCompleteAddress({
  values,
  errors,
  touched,
  isValid,
  handleChange,
  handleBlur,
  setIsDirty,
  setFormVariables,
  setIsValid,
  formLabelStyles,
  formInputStyles,
  formErrorStyles,
  formContainerStyles,
  showPredictionsAbove,
  showFullAddress,
  placeholder,
  icon,
  cfieldContainer,
  setHasEnteredAddress,
  setValue,
  setTouched,
  showIsRequired,
  validateWithoutTouched, // Validate the field without having to touch it.
  maxLength,
}: BaselaneAutoCompleteAddressProps) {
  // addresses are for united states at the moment. See server.js.
  const [addressObject, setAddressObject] = useState({
    address: values.address,
    city: values.city,
    state: values.state,
    zipcode: values.zipcode,
  });
  const [addressComponents, setAddressComponents] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const { setFieldValue = setValue, setFieldTouched = setTouched } = useFormikContext() ?? {};

  useEffect(() => {
    if (addressComponents.length !== 0) {
      const tempAddressObject = getTempAddressObject(addressComponents, showFullAddress);
      setAddressObject(tempAddressObject);
    }
  }, [addressComponents]);

  useEffect(() => {
    Object.keys(addressObject).forEach((field) => {
      const addressObjectValue = addressObject[field];
      if (addressObjectValue !== '') setFieldValue(field, addressObjectValue, false);

      if (addressObjectValue !== '' && field === 'address') {
        setHasEnteredAddress(true);
      } else if (addressObjectValue === '' && field === 'address') {
        setHasEnteredAddress(false);
      }
    });
  }, [addressObject]);

  useEffect(() => {
    const { address: sAddress, city, state, zipcode } = values || {};
    setFormVariables({
      address: sAddress || '',
      city,
      state,
      zipcode,
    });

    if (addressObject.address) {
      setFieldTouched('city', true);
      setFieldTouched('state', true);
      setFieldTouched('zipcode', true);
      setIsDirty();
    }
  }, [values.address, values.city, values.state, values.zipcode]);

  function onPredictionClick(placeId) {
    const placesUrlRequest = `${getEnvAppUrl()}/place?${new URLSearchParams({
      place_id: placeId,
    })}`;

    const placeResponse = fetch(placesUrlRequest, fetchOptions);

    placeResponse
      .then((pRes) => pRes.json())
      .then((placeRes) => {
        setAddressComponents(placeRes?.result?.address_components || []);
      });
  }

  function handleAddressChange(e) {
    if (values.fullAddress) setFieldValue('fullAddress', '');
    const input = e.target.value;

    const urlRequest = `${getEnvAppUrl()}/autocomplete?${new URLSearchParams({
      input,
    })}`;

    const mapsResponse = fetch(urlRequest, fetchOptions);

    mapsResponse
      .then((res) => res.json())
      .then((res) => {
        const predictionsResFiltered = res.predictions.map((prediction) => ({
          description: prediction.description,
          placeId: prediction.place_id,
        }));

        setPredictions(predictionsResFiltered);
      });
  }
  const addressLength = values?.address?.length ?? 0;

  return (
    <FormControl
      position="relative"
      isInvalid={errors.address && (validateWithoutTouched || touched.address)}
      isRequired={showIsRequired}
      {...formContainerStyles}
    >
      <FormLabel htmlFor="address" {...formLabelStyles}>
        Street Address
      </FormLabel>
      <Downshift
        onChange={(selection) => onPredictionClick(selection.placeId)}
        itemToString={(item) => (item ? item.description : '')}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          selectedItem,
          getRootProps,
        }) => (
          <Box
            {...getRootProps({}, { suppressRefError: true })}
            {...containerStyles(showPredictionsAbove)}
          >
            <InputGroup>
              {icon && (
                <InputLeftElement h={formInputStyles.h} pointerEvents="none">
                  {icon}
                </InputLeftElement>
              )}
              <Input
                {...formInputStyles}
                {...cfieldContainer}
                placeholder={placeholder}
                {...getInputProps({
                  id: 'address',
                  name: 'address',
                  maxLength,
                  value:
                    showFullAddress && values.fullAddress !== ''
                      ? values.fullAddress
                      : values.address,
                  onChange: (e) => {
                    handleAddressChange(e);
                    handleChange(e);

                    if (e.target.value === '' || e.target.value === values.address) {
                      setHasEnteredAddress(false);
                    } else {
                      setFormVariables({ address: values.address });
                      if (setIsValid) setIsValid(isValid);
                      setIsDirty();
                    }
                  },
                  onBlur: (e) => {
                    handleBlur(e);
                    setFormVariables({ address: values.address });
                    if (setIsValid) setIsValid(isValid);
                    setIsDirty();

                    if (e.target.value !== '') {
                      setHasEnteredAddress(true);
                    }
                  },
                  onKeyPress: (e) => {
                    if (e?.key === 'Enter' && e.target.value !== '') {
                      setHasEnteredAddress(true);
                    }
                  },
                })}
              />
            </InputGroup>

            {isOpen && (
              <Stack {...getMenuProps()} {...predictionsContainerStyles}>
                {predictions.map((p, index) => (
                  <Stack
                    direction="row"
                    {...getItemProps({
                      key: index,
                      index,
                      item: p,
                      style: predictionItemStyles(highlightedIndex, selectedItem, p, index),
                    })}
                  >
                    <IconLocation />
                    <Text>{p.description}</Text>
                  </Stack>
                ))}
              </Stack>
            )}
          </Box>
        )}
      </Downshift>
      {maxLength && (
        <Text
          {...{ ...formLabelStyles, color: customTheme.colors.brand.darkBlue['300'] }}
          align="right"
          mt="0.75"
        >
          {40 - addressLength} characters
        </Text>
      )}
      <FormErrorMessage {...formErrorStyles}>
        <Text>{errors.address}</Text>
      </FormErrorMessage>
    </FormControl>
  );
}

BaselaneAutoCompleteAddress.defaultProps = {
  setIsValid: null,
  isValid: false,
  placeholder: 'Type to autocomplete address...',
  showFullAddress: false,
  setHasEnteredAddress: () => {},
  setValue: () => {},
  setTouched: () => {},
  showPredictionsAbove: false,
  setFormVariables: () => {},
  setIsDirty: () => {},
  formContainerStyles: {},
  icon: null,
  cfieldContainer: {},
  showIsRequired: true,
  validateWithoutTouched: false,
};

export default BaselaneAutoCompleteAddress;
