import React, { useState } from 'react';
import { Text, VStack, forwardRef } from '@chakra-ui/react';
import { BaselaneDrawer, BaselaneButton } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import AddEditProperty from './AddEditProperty';
import AddPropertyRentCollectionButton from './AddEditProperty/AddPropertyRentCollectionButton';

import {
  addPropertyHeaderTextStyles,
  propertyStepMenuContainerStyles,
} from './styles/responsive.style';

type PropertyStepMenuProps = {
  numProperties: Number,
  numUnits: Number,
  handleCreatePropertySuccess?: Function,
  handleDrawerClose?: Function,
};

const PropertyStepMenu = forwardRef(
  (
    {
      numProperties,
      numUnits,
      handleCreatePropertySuccess,
      handleDrawerClose,
    }: PropertyStepMenuProps,
    ref
  ) => {
    const [property, setProperty] = useState(null);
    const mobileAddPropertyOpen = () => {
      if (!property) {
        sendSegmentEvent('add_property_started', {
          title: document.title,
          properties_count: numProperties,
          units_count: numUnits,
          isMobile: true,
        });
      }
    };

    const cleanup = () => {
      if (property) handleDrawerClose();
      setProperty(null);
    };

    const onPropertyStepMenuClose = () => {
      cleanup();
    };

    const onClosePropertySelector = () => {
      cleanup();
      ref?.current?.close();
    };

    return (
      <BaselaneDrawer
        ref={ref}
        isMobileHeader
        showBackButton={false}
        hideBackText
        showCloseButton={false}
        scroll
        hasMobileShadow={false}
        closeEvent={onPropertyStepMenuClose}
        openEvent={mobileAddPropertyOpen}
        size="newdrawerfull"
        newDesignDrawer
        footer={
          <BaselaneButton
            variant="outline"
            palette="primary"
            size="md"
            onClick={onClosePropertySelector}
            isFullWidth
          >
            Back
          </BaselaneButton>
        }
      >
        <VStack {...propertyStepMenuContainerStyles}>
          <Text {...addPropertyHeaderTextStyles}>Let’s Set Up Your Property</Text>
          <AddEditProperty
            showTracker
            buttonLabel="Add Property"
            property={property}
            setProperty={setProperty}
            handleCreatePropertySuccess={handleCreatePropertySuccess}
            variant="transparent"
            palette="primary"
          />
          <AddEditProperty
            showTracker
            financials
            buttonLabel="Add Property Financials"
            setProperty={setProperty}
            property={property}
            variant="transparent"
            palette="primary"
          />

          <AddPropertyRentCollectionButton
            showTracker
            buttonLabel="Add Property"
            property={property}
            setProperty={setProperty}
            onClose={onClosePropertySelector}
            variant="transparent"
            palette="primary"
          />
        </VStack>
      </BaselaneDrawer>
    );
  }
);

PropertyStepMenu.defaultProps = {
  buttonLabel: '',
  buttonStyles: {},
  buttonVariant: 'primary',
  handleCreatePropertySuccess: () => {},
  handleDrawerClose: () => {},
};

export default PropertyStepMenu;
