import React, { useContext, useRef, useState } from 'react';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import UserContext from '@contexts/UserContext';
import { IllustrationBankingAddexternalAccount } from '@illustrations';
import { BaselaneButtonCard, BaselaneExternalBankingTypePopup } from '@shared/components';
import {
  cardBodyStyles,
  cardTextContainerStyles,
  cardTitleStyles,
  iconContainerStyles,
  typeCardStyles,
} from '@shared/styles/typePopupButtons.styles';

type ExternalBankingButtonCardProps = {
  addAccountManuallyDrawerRef: any,
  setShowReceivingBAEmptyState: Function,
  setHasConnectedBank: Function,
  refetchBankAccounts: Function,
  linkedAccountsDrawerOnClose?: Function,
  banksCount: Object,
};

const ExternalBankingButtonCard = ({
  addAccountManuallyDrawerRef,
  setShowReceivingBAEmptyState,
  setHasConnectedBank,
  refetchBankAccounts,
  linkedAccountsDrawerOnClose,
  banksCount,
}: ExternalBankingButtonCardProps) => {
  const { user } = useContext(UserContext);
  const {
    isOpen: isExternalBankingTypePopupOpen,
    onOpen: onExternalBankingTypePopupOpen,
    onClose: onExternalBankingTypePopupClose,
  } = useDisclosure();

  const [isVisibilityHidden, setIsVisibilityHidden] = useState(!isExternalBankingTypePopupOpen);
  const addExternalAccountDrawerRef = useRef();

  const handleActionButton = (type) => {
    if (type === 'manual') {
      onExternalBankingTypePopupClose();
      addAccountManuallyDrawerRef?.current?.open();
    }
  };

  const handleOpenExternalBankingPopup = () => {
    sendSegmentEvent(
      `${user.onboardingCompleted ? 'baselane' : 'onboarding'}_rc_click_connect_bank_account`,
      {
        landlord_uuid: user.id,
        landlord_name: user.firstName,
        landlord_email: user.email,
        bank_type: 'external',
        external_account_count: banksCount?.external,
      }
    );
    onExternalBankingTypePopupOpen();
    setIsVisibilityHidden(false);
  };

  const addAccountProps = {
    isFromLease: true,
    isDirectToPlaid: true,
    hideButton: true,
    helperFunction: () => {
      setHasConnectedBank(true);
      setShowReceivingBAEmptyState(false);
    },
    handleAddAccountSuccessCallback: () => setIsVisibilityHidden(true),
    refetchLeaseBankAccounts: refetchBankAccounts,
    addExternalAccountDrawerRef,
  };

  return (
    <>
      <BaselaneExternalBankingTypePopup
        handleActionButton={handleActionButton}
        isOpen={isExternalBankingTypePopupOpen}
        onClose={onExternalBankingTypePopupClose}
        overlayStyles={{ visibility: isVisibilityHidden && 'hidden' }}
        containerStyles={{ visibility: isVisibilityHidden && 'hidden' }}
        addAccountProps={addAccountProps}
        linkedAccountsDrawerOnClose={linkedAccountsDrawerOnClose}
      />

      <BaselaneButtonCard
        onClick={handleOpenExternalBankingPopup}
        type="clean"
        buttonStyles={typeCardStyles}
        id="external-account-button"
      >
        <Box {...iconContainerStyles}>
          <IllustrationBankingAddexternalAccount />
        </Box>
        <Box {...cardTextContainerStyles}>
          <Text {...cardTitleStyles}>External Account</Text>
          <Text {...cardBodyStyles}>
            Add an external account with bank credentials or manually using account/routing number.
          </Text>
        </Box>
      </BaselaneButtonCard>
    </>
  );
};

ExternalBankingButtonCard.defaultProps = {
  linkedAccountsDrawerOnClose: () => {},
};

export default ExternalBankingButtonCard;
