import React from 'react';
import { IconOrgChart, IconStockExchange, IconStackedCards, IconMoneySignRoundArrow } from '@icons';

export const cardArray = (isMobile) => [
  {
    icon: <IconStockExchange w={isMobile ? '24' : '32'} h={isMobile ? '24' : '32'} />,
    text: 'High-Yield Checking to Grow Savings',
  },
  {
    icon: <IconOrgChart w={isMobile ? '24' : '32'} h={isMobile ? '24' : '32'} />,
    text: 'Virtual Accounts to Keep Funds Organized',
  },
  {
    icon: <IconMoneySignRoundArrow w={isMobile ? '24' : '32'} h={isMobile ? '24' : '32'} />,
    text: '1% Unlimited',
    text2: 'Cashback Debit ',
  },
  {
    icon: <IconStackedCards w={isMobile ? '24' : '32'} h={isMobile ? '24' : '32'} />,
    text: 'Virtual Cards to Control Your Spend',
  },
];
