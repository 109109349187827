import React, { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { isMobile as isDeviceMobile } from 'react-device-detect';
import { HStack, Progress, Stack, Text } from '@chakra-ui/react';
import { progressBarStyles } from '@core/components/OnboardingTriage/PropertySurvey/styles/propertySurvey.styles';
import { headingTextStyles } from '@core/components/OnboardingTriage/styles/fonts.styles';
import { ONBOARDING_ADD_PROPERTY, ONBOARDING_PROPERTY_SURVEY, ONBOARDING_TRIAGE } from '@routes';
import {
  cardContainerStyles,
  headerTextContainerStyles,
  onboardingHeaderStyles,
  subTextStyles,
} from '@core/components/OnboardingTriage/styles/onboardingTriage.styles';
import getBreakPoints from '@core/utils/getBreakPoints';
import userContext from '@contexts/UserContext';
import {
  heading,
  text,
  supporting,
} from '@core/components/OnboardingTriage/helpers/textCopy.helper';
import useOnboardingStore from '@store/Onboarding';

const TriageContent = () => {
  const { isMin899, isMaxHeight720 } = getBreakPoints();
  const isMobile = isDeviceMobile || !isMin899;

  const { selection: onboardingTriageSelection } = useOnboardingStore();

  const { pathname = '' } = useLocation();
  const { user, propertySurveyPanelIndex, skippedBankingFirst } = useContext(userContext);
  const { bankingTracker = [] } = user?.userMetadata || {};
  const isBankingFinished = bankingTracker[0].state !== 'NOT_STARTED';

  const onboardingTriage = pathname === ONBOARDING_TRIAGE;
  const propertySurvey = pathname === ONBOARDING_PROPERTY_SURVEY;
  const addProperty = pathname === ONBOARDING_ADD_PROPERTY;
  const noDisplayHeader = propertySurvey && propertySurveyPanelIndex;

  const { beforeTitleText = '' } = text(user)?.[pathname] ?? {};
  const { title = '' } =
    heading(user, onboardingTriageSelection, skippedBankingFirst)[pathname] ?? {};
  const subtitle = supporting(skippedBankingFirst, onboardingTriageSelection, isBankingFinished);

  return (
    <Stack
      {...cardContainerStyles(pathname)}
      minHeight="100px"
      width={isMobile || onboardingTriage ? '100%' : { xxs: '100%', md: '728px', base: '100%' }}
      mt={isMobile ? '0 !important' : '40px'}
      alignItems={onboardingTriage ? 'center' : 'left'}
      overflowY="auto"
    >
      <Stack {...headerTextContainerStyles}>
        {propertySurvey && (
          <Progress
            value={(propertySurveyPanelIndex + 1) * 25}
            colorScheme="scheme.blue"
            {...progressBarStyles}
          />
        )}
        {!noDisplayHeader && (
          <>
            <HStack
              {...headingTextStyles}
              justifyContent={onboardingTriage ? 'center' : 'left'}
              pt={onboardingTriage ? '8px' : '0px'}
            >
              {beforeTitleText && <Text>{beforeTitleText}</Text>}
              {beforeTitleText && !addProperty && !onboardingTriage && <Text>&#128075;</Text>}
              {beforeTitleText && addProperty && <Text>&#127881;</Text>}
            </HStack>
            {(!isMobile || onboardingTriage || propertySurvey || addProperty) && (
              <Stack textAlign={onboardingTriage ? 'center' : 'left'} spacing={0}>
                <Text {...onboardingHeaderStyles()}>{title}</Text>
                <Text
                  {...subTextStyles(
                    pathname,
                    onboardingTriageSelection === 'bookkeeping',
                    isMaxHeight720
                  )}
                >
                  {subtitle}
                </Text>
              </Stack>
            )}
          </>
        )}
      </Stack>
      <Outlet />
    </Stack>
  );
};

export default TriageContent;
