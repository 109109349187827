import React from 'react';
import EducationalDrawerWrapper from '@core/components/NativeBankingPage/MainContent/components/AddFundsDrawer/components/EducationalDrawer/EducationDrawerWrapper';
import DrawerContent from '@core/components/NativeBankingPage/MainContent/components/AddFundsDrawer/components/EducationalDrawer/DrawerContent';

type EducationalDrawerProps = {
  children: React.ReactNode,
  educationalDrawerRef: null | HTMLDivElement,
  handleDrawerClose: Function,
  handleDrawerOpen: Function,
  buttonContent: Object,
  hideButton?: boolean,
  isInEmptyState?: boolean,
};

const EducationalDrawerWithOtherMethods = ({
  children,
  educationalDrawerRef,
  handleDrawerClose,
  handleDrawerOpen,
  buttonContent,
  hideButton,
  isInEmptyState,
}: EducationalDrawerProps) => {
  return (
    <EducationalDrawerWrapper
      {...{
        educationalDrawerRef,
        handleDrawerClose,
        handleDrawerOpen,
        buttonContent,
        hideButton,
        isInEmptyState,
      }}
    >
      <DrawerContent id={buttonContent.id} />
      {children}
    </EducationalDrawerWrapper>
  );
};

EducationalDrawerWithOtherMethods.defaultProps = {
  hideButton: false,
  isInEmptyState: false,
};

export default EducationalDrawerWithOtherMethods;
