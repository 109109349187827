// @flow
import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import PropertiesFilter from './PropertiesFilter';
// import DateFilter from './DateFilter';
import type { Property } from '../../Properties/types';

function FiltersSection({
  onSelectProperties,
  // onSetDate,
  // initialDateFilter,
  properties,
}: {
  onSelectProperties: Function,
  // onSetDate: Function,
  // initialDateFilter: any,
  properties: Property[],
}): any {
  return (
    <HStack spacing={4}>
      <HStack>
        <Text textStyle="xs" color="brand.neutral.600">
          Properties
        </Text>
        <PropertiesFilter onSelectProperties={onSelectProperties} initialProperties={properties} />
      </HStack>
    </HStack>
  );
}

// Readd this code in the HStack being returned to re-enable filters
/* <HStack>
  <Text textStyle="xs" color="brand.neutral.600">
    Years
  </Text>
  <DateFilter onSetDate={onSetDate} initialCheckedOptions={initialDateFilter} />
</HStack> */
export default FiltersSection;
