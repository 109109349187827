import React from 'react';

type IconKeyThinWithCrossProps = { w?: Number, h?: Number, color?: string };

function IconKeyThinWithCross({ w, h, color }: IconKeyThinWithCrossProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)" stroke={color}>
        <path
          d="M16.4444 9.33328c0-.45495-.1735-.90991-.5207-1.25703-.3471-.34713-.8021-.52069-1.257-.52069m0 7.11114c2.9455 0 5.3333-2.3878 5.3333-5.33337C20 6.38781 17.6122 4 14.6667 4c-2.9456 0-5.33337 2.38781-5.33337 5.33333 0 .24327.01629.48274.04783.71737.05189.3859.07783.5789.06037.7009-.01819.1272-.04135.1957-.10403.3079-.06017.1076-.1662.2136-.37827.4257l-4.54267 4.5427c-.15374.1537-.2306.2306-.28557.3203-.04874.0795-.08466.1662-.10643.2569C4 16.7074 4 16.8161 4 17.0335v1.5443c0 .4978 0 .7467.09688.9369.08522.1672.22121.3032.38846.3884C4.67549 20 4.9244 20 5.42222 20h2.13334v-1.7778h1.77777v-1.7778h1.77777l1.4037-1.4036c.2121-.2121.3181-.3181.4257-.3783.1122-.0627.1807-.0858.3079-.104.122-.0175.315.0085.7009.0603.2346.0316.4741.0479.7174.0479Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M.265625-.265625 22.5005 22.4995" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={color} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

IconKeyThinWithCross.defaultProps = {
  w: 24,
  h: 24,
  color: 'currentColor',
};

export default IconKeyThinWithCross;
