// @flow
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { orderBy } from 'lodash';
import { Text, Stack } from '@chakra-ui/react';
import { IconChevronLeft, IconGoEdit } from '@icons';
import { formatDate } from '@core/utils/formatDate';
import {
  BaselaneDivider,
  BaselaneList,
  BaselaneListItem,
  BaselaneMobileHeader,
  CurrencyText,
} from '@shared/components';
import AddEditProperty from './AddEditProperty';
import isNumber from '../../utils/validators/isNumber';
import { mortgageTermOptions } from './helpers/financials.helper';
import { fontTitleStyle1, fontListStyle1, textStyles } from './styles/responsive.style';

function PropertyFinancialDetails({
  hideFinancialDetails,
  property,
  setProperty,
}: {
  hideFinancialDetails: Function,
  property: Array<Object>,
  setProperty?: Function,
}): any {
  const {
    purchaseDate,
    purchasePrice,
    closingCosts,
    annualPropertyTaxes,
    annualPropertyInsurance,
    mortgageTerm,
    mortgageBalance: mortgageBalanceData,
    mortgageDownpayment,
    marketPrice,
  } = property;

  const dash = <div>&mdash;</div>;
  const [latestMarketValue] = orderBy(marketPrice, ['date'], ['desc']);
  const [latestMortgageBalance] = orderBy(mortgageBalanceData, ['date'], ['desc']);
  const marketValue = latestMarketValue ? latestMarketValue.price : null;
  const mortgageBalance = latestMortgageBalance ? latestMortgageBalance.balance : null;
  const mortgageTermLitteral = mortgageTermOptions.filter((o) => o.id === mortgageTerm)[0];

  const list = [
    {
      label: 'Purchase Date',
      value: purchaseDate ? formatDate(purchaseDate, 'YYYY - MM - DD') : dash,
    },
    {
      label: 'Purchase Price',
      value: purchasePrice,
    },
    {
      label: 'Closing Cost',
      value: closingCosts,
    },
  ];
  const list2 = [
    {
      label: 'Annual Property Taxes',
      value: annualPropertyTaxes,
    },
    {
      label: 'Annual Property Insurance',
      value: annualPropertyInsurance,
    },
  ];
  const list3 = [
    {
      label: 'Down Payment',
      value: mortgageDownpayment,
    },
    {
      label: 'Loan Terms',
      value: mortgageTermLitteral?.label,
    },
    {
      label: 'Current Principle Balance',
      value: mortgageBalance,
    },
  ];
  const list4 = [
    {
      label: 'Current Market Value',
      value: marketValue,
    },
  ];

  const listItemRenderer = (item) => {
    const key = uuidv4();
    const valueProps = {
      amount: item.value,
      isRounded: true,
      textStyle: 'headline-sm',
      color: 'brand.neutral.700',
    };

    const valueText = item.value ? <Text {...textStyles}>{item.value}</Text> : dash;
    const value = isNumber(item.value) ? <CurrencyText {...valueProps} /> : valueText;

    return (
      <BaselaneListItem
        key={key}
        label={item.label}
        value={value}
        containerStyle={{ _notFirst: { mt: '16px !important' } }}
        labelStyle={{ mr: '8px', fontSize: 'xs' }}
      />
    );
  };

  const propertyFinancialDetailsHeader = (
    <BaselaneMobileHeader
      title="Property Financials"
      shadow
      underLine={property?.name}
      stay
      onTop
      linkIcon={<IconChevronLeft w="16" h="16" />}
      actionLink={hideFinancialDetails}
    >
      <AddEditProperty
        buttonLabel="Edit"
        variant="outline"
        property={property}
        leftIcon={<IconGoEdit />}
        financials
        hasData
        setProperty={setProperty}
      />
    </BaselaneMobileHeader>
  );
  const propertyFinancialDetailsBody = (
    <Stack bg="brand.neutral.white" zIndex="12" pb="64px">
      <Stack>
        <Stack w="100%" p="64px 32px 16px">
          <Text {...fontTitleStyle1}>Purchase Details</Text>
          <BaselaneList data={list} itemRenderer={listItemRenderer} />
        </Stack>
        <BaselaneDivider styles={{}} />
        <Stack w="100%" p="16px 32px" mt="0">
          <Text {...fontTitleStyle1}>Annual Taxes & Insurance</Text>
          <BaselaneList
            listStyles={{ ...fontListStyle1 }}
            data={list2}
            itemRenderer={listItemRenderer}
          />
        </Stack>
        <BaselaneDivider styles={{}} />
        <Stack w="100%" p="16px 32px">
          <Text {...fontTitleStyle1}>Mortgage Details</Text>
          <BaselaneList data={list3} itemRenderer={listItemRenderer} />
        </Stack>
        <BaselaneDivider styles={{}} />
        <Stack w="100%" p="16px 32px">
          <Text {...fontTitleStyle1}>Market Value</Text>
          <BaselaneList data={list4} itemRenderer={listItemRenderer} />
        </Stack>
      </Stack>
      <BaselaneDivider styles={{}} />
    </Stack>
  );
  return (
    <>
      {propertyFinancialDetailsHeader}
      {propertyFinancialDetailsBody}
    </>
  );
}
PropertyFinancialDetails.defaultProps = { setProperty: null };

export default PropertyFinancialDetails;
