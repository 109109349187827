import React from 'react';

type IconTransfersPaymentsProps = { w?: number, h?: number };

const IconTransfersPayments = ({ w, h }: IconTransfersPaymentsProps) => {
  return (
    <svg width={w} height={h} viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15.8" cy="15.8" r="12.8" fill="#8CCFFF" />
      <path
        d="M26.9199 15.5444C26.5019 19.4739 24.2767 23.1562 20.5947 25.2832C14.4774 28.8172 6.65516 26.72 3.1233 20.599L2.74713 19.947M1.48009 12.8554C1.8981 8.92592 4.12324 5.24365 7.80522 3.11659C13.9226 -0.417363 21.7448 1.67984 25.2767 7.80082L25.6528 8.45277M1.40039 23.3329L2.50191 19.2195L6.61283 20.3217M21.7879 8.07812L25.8989 9.18029L27.0004 5.06693"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.40039 17.1538C9.40039 18.5813 10.8331 19.7385 12.6004 19.7385H15.5718C17.4654 19.7385 19.0004 18.4986 19.0004 16.9692C19.0004 15.4398 17.4654 14.2 15.5718 14.2H12.829C10.9354 14.2 9.40039 12.9602 9.40039 11.4308C9.40039 9.90137 10.9354 8.66154 12.829 8.66154H15.8004C17.5677 8.66154 19.0004 9.81871 19.0004 11.2462M14.2004 7V8.66154M14.2004 19.7385V21.4"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconTransfersPayments.defaultProps = {
  w: 29,
  h: 36,
};

export default IconTransfersPayments;
