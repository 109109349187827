import { useRef } from 'react';

const useDrawerReturnStatus = () => {
  const kycReturnStatusDrawerRef = useRef(null);

  const handleKYCReturnStatusDrawerClose = () => kycReturnStatusDrawerRef?.current?.close();
  const handleKYCReturnStatusDrawerOpen = () => kycReturnStatusDrawerRef?.current?.open();

  return {
    handleKYCReturnStatusDrawerOpen,
    handleKYCReturnStatusDrawerClose,
    kycReturnStatusDrawerRef,
  };
};

export default useDrawerReturnStatus;
