import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { RadioGroup } from '@chakra-ui/react';
import { BaselaneAccordionWrapper } from '@shared/components';
import { GET_PROPERTIES_SIMPLE } from '@core/apollo/queries';
import { getSortedPropertiesForAutoTag } from '@shared/helpers/autoTag.helpers';
import AutoTagTableHeader from './AutoTagTableHeader';

type AutoTagTableProps = {
  autoTag: Object,
  setFieldValue: Function,
  handleFormUpdate: Function,
  readOnly?: Boolean,
};

function AutoTagTable({
  autoTag,
  setFieldValue,
  handleFormUpdate,
  readOnly,
}: AutoTagTableProps): any {
  const { error, loading, data: properties } = useQuery(GET_PROPERTIES_SIMPLE);

  const getInitialAutoTagId = () => {
    const { propertyId, propertyUnitId } = autoTag;
    let initialId;
    if (propertyUnitId === null && propertyId === null) {
      initialId = null;
    } else if (propertyUnitId === null) {
      initialId = propertyId;
    } else {
      initialId = `${propertyId}-${propertyUnitId}`;
    }

    return initialId;
  };

  const initialAutoTagId = getInitialAutoTagId();
  const [autoTagId, setAutoTagId] = useState(initialAutoTagId);

  if (loading) return <div>Loading</div>;
  if (error) return <div>Error</div>;

  const { property = [] } = properties;
  const sortedProperties = getSortedPropertiesForAutoTag({ property });

  const handleAutoTagIdChange = (selectedRadioId) => {
    setAutoTagId(selectedRadioId);
    let [propertyId, propertyUnitId] = selectedRadioId.split('-');
    propertyId = propertyId ?? null;
    propertyUnitId = propertyUnitId ?? null;
    const values = {
      autoTag: {
        enabled: autoTag.enabled,
        propertyId,
        propertyUnitId,
      },
    };
    setFieldValue('autoTag.propertyId', propertyId);
    setFieldValue('autoTag.propertyUnitId', propertyUnitId);
    handleFormUpdate('autoTag.propertyId', values, true);
  };

  const activePropertyId =
    autoTag.enabled && autoTag.propertyId !== null ? autoTag.propertyId : null;

  const getDefaultIndexArr = () => {
    const { propertyId, propertyUnitId } = autoTag;

    if (propertyId !== null && sortedProperties.length > 0) {
      const propertiesWithUnits = sortedProperties.filter(
        (propertyObj) => propertyObj.units.length > 1
      );
      const propertyIndex = propertiesWithUnits.findIndex((propertyObj) =>
        propertyObj.units.find((unit) => unit.id === propertyUnitId)
      );

      if (propertyIndex > -1) {
        return [propertyIndex];
      }
    }

    return [];
  };

  return (
    <RadioGroup
      className="primary-500 custom-radio"
      id="autoTag.propertyId"
      name="autoTag.propertyId"
      value={autoTagId}
      onChange={(e) => {
        handleAutoTagIdChange(e);
      }}
      isDisabled={readOnly}
    >
      <BaselaneAccordionWrapper allowToggle allowMultiple defaultIndex={getDefaultIndexArr()}>
        <AutoTagTableHeader properties={sortedProperties} activePropertyId={activePropertyId} />
      </BaselaneAccordionWrapper>
    </RadioGroup>
  );
}

AutoTagTable.defaultProps = {
  readOnly: false,
};

export default AutoTagTable;
