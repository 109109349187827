import React from 'react';
import { Box, HStack, List, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react';
import { UNIFIED_RENT_COLLECTION } from '@routes';
import { IconCheck, IconLightBulb, IconKey } from '@icons';
import { BaselaneButton } from '@shared/components';
import { generalRedirectToURC } from '@pages/UnifiedRentCollection/UnifiedLeaseUI/helpers/unifiedLeaseFlow.helpers';

import {
  titleStyles,
  questionStyles,
  answerStyles,
  orderedListStyles,
  checkListStyles,
  checkListItemStyles,
  checkedListItemIconContainerStyles,
  checkListItemTextContainerStyles,
  checkListItemBoldStyles,
  bannerContainerStyles,
  bannerMessageStyles,
  bannerTitleStyles,
} from '../../styles/howToGuidesDrawer.styles';

const rcDrawerConst = {
  mobileTitle: 'How to Guide - Rent Collection',
  q1: 'How do I invite my tenants to start paying rent on Baselane?',
  q2: 'Why collect rent online with Baselane vs. Cash, Check or an App?',
  q3: 'How can I set up reminders and track late payments?',
  a1:
    'Tenants will receive an email and SMS invitation to Baselane. Setting up rent collection is free, takes a few minutes, and is easy for both Landlords and Tenants:',
  a1List: [
    { key: '1', text: 'Enter rent collection details and tenant information' },
    { key: '2', text: 'Baselane invites your tenant via email & SMS' },
    {
      key: '3',
      text: 'Tenant activates their account and adds a payment method (bank account or card)',
    },
    {
      key: '4',
      text: 'Tenant schedule payments, set up auto-pay, and manages their rent payments',
    },
    { key: '5', text: 'You get paid directly into your bank account!' },
    {
      key: '6',
      text: 'Baselane will automatically send invoices & payment reminders to your tenant.',
    },
  ],
  a2:
    'Take the headache out of rent collection. Methods like Cash and Checks are manual and slow. Apps like Zelle and Venmo are fast and convenient but have transfer limits and can lead to fraud. Most importantly, Cash, Check, or Zelle/Venmo are not automated and require you to chase down your tenant for late rent. They don’t provide your tenant flexibility and a dedicated platform to manage rent payments. With Baselane, you get:',
  a2List: [
    {
      key: '1',
      boldedText: 'Get Paid on Time',
      description:
        'Apply late fees without lifting a finger. We send automated payment reminders to your tenants, so you don’t have to.',
    },
    {
      key: '2',
      boldedText: 'Payment Tracking',
      description: 'Get real-time updates on rent payments.',
    },
    {
      key: '3',
      boldedText: 'Automated Payments',
      description: 'Tenants can easily enroll in auto-pay, to never miss a payment.',
    },
    {
      key: '4',
      boldedText: 'Easy & Flexibility for Tenants',
      description: 'Tenants can pay by bank transfer or debit/credit card.',
    },
    {
      key: '5',
      boldedText: 'Fast Payouts',
      description: 'Payments are deposited directly into your bank account within 2-5 days.',
    },
  ],
  a3:
    'Baselane will automatically notify your tenants when rent is due or overdue. Additionally, Baselane can automatically assess late fees for overdue rent.',
  banner: {
    boldedText:
      'Pro Tip: Include one-time fees, security deposits, last month’s rent, or pro-rated rent.',
    description:
      'Baselane’s rent collection is flexible to your specific needs and allows you to easily add one-time fees (e.g., cleaning, pet fees), deposits, last month’s rent, and even pro-rated rent.',
  },
};

const handleStartRentCollectionClick = (navigate, location) => {
  const state = { from: location?.pathname };
  const pathname = UNIFIED_RENT_COLLECTION;
  generalRedirectToURC({ navigate, pathname, state });
};

export const rcDrawerComponents = (isMobile, navigate, location) => {
  const { mobileTitle, q1, q2, q3, a1, a1List, a2, a2List, a3, banner } = rcDrawerConst;
  return {
    mobileTitle: <Text {...titleStyles}>{mobileTitle}</Text>,
    q1: <Text {...questionStyles(isMobile, true)}>{q1}</Text>,
    q2: <Text {...questionStyles(isMobile)}>{q2}</Text>,
    q3: <Text {...questionStyles(isMobile)}>{q3}</Text>,
    a1: (
      <Stack {...answerStyles(isMobile)} alignItems="start">
        <Text>{a1}</Text>
        <OrderedList {...orderedListStyles(isMobile)}>
          {a1List.map((item) => (
            <ListItem key={item.key}>{item.text}</ListItem>
          ))}
        </OrderedList>

        <BaselaneButton
          variant="filled"
          palette="primary"
          size="md"
          leftIcon={<IconKey />}
          onClick={() => handleStartRentCollectionClick(navigate)}
        >
          Set Up Rent Collection
        </BaselaneButton>
      </Stack>
    ),
    a2: (
      <Stack {...answerStyles(isMobile)}>
        <Text>{a2}</Text>
        <List {...checkListStyles(isMobile)} spacing={1}>
          {a2List.map((item) => (
            <ListItem key={item.key} {...checkListItemStyles}>
              <Box {...checkedListItemIconContainerStyles}>
                <IconCheck w={16} h={16} color="#257ED0" />
              </Box>

              <Box {...checkListItemTextContainerStyles}>
                <Box as="span" {...checkListItemBoldStyles}>
                  {item.boldedText} -&nbsp;
                </Box>
                {item.description}
              </Box>
            </ListItem>
          ))}
        </List>
      </Stack>
    ),
    a3: (
      <Stack {...answerStyles(isMobile)}>
        <Text>{a3}</Text>
      </Stack>
    ),
    banner: (
      <HStack {...bannerContainerStyles}>
        <IconLightBulb w={24} h={24} color="#3A4B5B" />
        <Box {...bannerMessageStyles}>
          <Text {...bannerTitleStyles}>{banner.boldedText}</Text>
          <Text>{banner.description}</Text>
        </Box>
      </HStack>
    ),
  };
};
