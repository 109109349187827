function formatCurrency(amount, isNewFormat = false, isMobileShort = false, showPositive = false) {
  const isNegative = amount < 0;

  const num = Math.abs(amount);
  // noinspection JSCheckFunctionSignatures
  const formattedAmount = num.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formattedAmountRounded = num.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const nFormatter = () => {
    if (num > 999999999) {
      return `${Math.abs(num / 1000000000)
        .toFixed(6)
        .slice(0, -4)}G`;
    }
    if (num > 999999) {
      return `${Math.abs(num / 1000000)
        .toFixed(6)
        .slice(0, -4)}M`;
    }
    if (num > 999) {
      return `${Math.abs(num / 1000)
        .toFixed(6)
        .slice(0, -4)}K`;
    }
    return formattedAmountRounded;
  };

  const defaultFormattedCurrency = {
    isNegative,
    rounded: isNegative ? `$(${formattedAmount})` : `$${formattedAmount}`,
    inDollars: isNegative ? `$(${formattedAmountRounded})` : `$${formattedAmountRounded}`,
    noDollar: isNegative ? `(${formattedAmountRounded})` : `${formattedAmountRounded}`,
    shortFormatInDollars: isNegative ? `$${nFormatter()}` : `$${nFormatter()}`,
  };

  const positivePrefix = showPositive ? `+` : ``;

  const newFormattedCurrency = {
    isNegative,
    rounded: isNegative ? `-$${formattedAmount}` : `${positivePrefix}$${formattedAmount}`,
    inDollars: isNegative
      ? `-$${formattedAmountRounded}`
      : `${positivePrefix}$${formattedAmountRounded}`,
    noDollar: isNegative
      ? `-${formattedAmountRounded}`
      : `${positivePrefix}${formattedAmountRounded}`,
    shortFormatInDollars: isNegative ? `-$${nFormatter()}` : `${positivePrefix}$${nFormatter()}`,
    noDecimal: isNegative
      ? `-$${Math.trunc(num).toLocaleString()}`
      : `${positivePrefix}$${Math.trunc(num).toLocaleString()}`,
  };

  const mobibeShortFormatedCurrency = defaultFormattedCurrency;

  if (isMobileShort) {
    return mobibeShortFormatedCurrency;
  }

  return isNewFormat ? newFormattedCurrency : defaultFormattedCurrency;
}

export default formatCurrency;
