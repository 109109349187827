import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { BaselaneButton, BaselaneMessageCard } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { baselanePromotionBannerStyles } from '../../styles/baselanePromotionBanner.styles';

type BaselanePromotionBannerProps = {
  handleTabsChange: Function,
  showBaselanePromotionBanner: boolean,
};

function BaselanePromotionBanner({
  handleTabsChange,
  showBaselanePromotionBanner,
}: BaselanePromotionBannerProps) {
  // Destructure Imported Style
  const { container, messagecard } = baselanePromotionBannerStyles ?? {};

  const messageCardText = showBaselanePromotionBanner ? (
    <>
      <Text mb={1.5}>
        Rent is being deposited into your external account. Switch rent collection into your
        Baselane Banking account for automated bookkeeping, 4.19% APY, virtual cards, and more.
      </Text>
      <BaselaneButton
        variant="filled"
        palette="primary"
        onClick={() => {
          sendSegmentEvent('rc_details_click_banner_switch_to_baselane');
          handleTabsChange(2);
        }}
      >
        Update Receiving Account
      </BaselaneButton>
    </>
  ) : null;

  return (
    <Box {...container}>
      <BaselaneMessageCard
        iconName=""
        title="Switch rent collection into your Baselane Banking account"
        text={messageCardText}
        borderColor="blue"
        iconColor="blue"
        containerStyles={messagecard.containerStyles}
        styles={messagecard.styles}
        isVertical
      />
    </Box>
  );
}

export default BaselanePromotionBanner;
