import React from 'react';
import { Box } from '@chakra-ui/react';
import ReferralWrapper from './components/Referral/ReferralWrapper';

const SignUpPage = () => {
  return (
    <Box bg="brand.neutral.white" height="100%">
      <ReferralWrapper />
    </Box>
  );
};

export default SignUpPage;
