import React from 'react';

type IconKeyProps = { w?: Number, h?: Number, color?: string };

function IconKey({ w, h, color }: IconKeyProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${w}px`} height={`${h}px`} fill="none">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.444 6.333c0-.455-.173-.91-.52-1.257a1.772 1.772 0 0 0-1.257-.52m0 7.11A5.333 5.333 0 1 0 6.38 7.052c.052.386.078.579.06.7a.758.758 0 0 1-.103.308c-.06.108-.167.214-.379.426l-4.542 4.543c-.154.154-.231.23-.286.32a.888.888 0 0 0-.106.257C1 13.707 1 13.816 1 14.033v1.545c0 .498 0 .747.097.937a.889.889 0 0 0 .388.388c.19.097.44.097.937.097h2.134v-1.778h1.777v-1.778h1.778l1.404-1.403c.212-.212.318-.318.426-.379a.758.758 0 0 1 .307-.104c.122-.017.315.009.701.06.235.032.474.049.718.049Z"
      />
    </svg>
  );
}

IconKey.defaultProps = {
  w: 18,
  h: 18,
  color: 'currentColor',
};

export default IconKey;
