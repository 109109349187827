import React from 'react';
import { isMobile } from 'react-device-detect';
import TooltipWithHover from '@shared/components/TooltipWithHover';
import {
  IconCreditCardImporting,
  IconCreditCardNotImporting,
  // IconDollarCirculate,
  // IconDollarNotCirculate,
  IconKeyThin,
  IconKeyThinWithCross,
} from '@icons';

type LinkedAccountStatusProps = {
  supportingTrx: boolean,
  // supportingTransfer: boolean,
  supportingRC: boolean,
};

const LinkedAccountStatus = ({
  supportingTrx,
  // supportingTransfer,
  supportingRC,
}: LinkedAccountStatusProps) => {
  return (
    !isMobile && (
      <>
        {supportingTrx ? (
          <TooltipWithHover tooltipText="This account is eligible to import transactions">
            <IconCreditCardImporting />
          </TooltipWithHover>
        ) : (
          <TooltipWithHover tooltipText="This account is NOT eligible to import transactions">
            <IconCreditCardNotImporting />
          </TooltipWithHover>
        )}
        {/* {supportingTransfer ? (
          <TooltipWithHover tooltipText="This account is eligible to transfer funds">
            <IconDollarCirculate />
          </TooltipWithHover>
        ) : (
          <TooltipWithHover tooltipText="This account is NOT eligible to transfer funds">
            <IconDollarNotCirculate />
          </TooltipWithHover>
        )} */}
        {supportingRC ? (
          <TooltipWithHover tooltipText="This account is eligible to collect rent">
            <IconKeyThin />
          </TooltipWithHover>
        ) : (
          <TooltipWithHover tooltipText="This account is NOT eligible to collect rent">
            <IconKeyThinWithCross />
          </TooltipWithHover>
        )}
      </>
    )
  );
};

export default LinkedAccountStatus;
