export const positionAbsoluteStyles = (styles) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  ...styles,
});

export const positionAbsoluteToLeftSideStyles = positionAbsoluteStyles({ left: 0 });

export const positionAbsoluteToRightSideStyles = positionAbsoluteStyles({ right: 0 });

// dot button styles
const baseDotButtonStyles = (styles = {}) => ({
  paddingInline: 0,
  padding: 0,
  minWidth: 'unset',
  width: '8px',
  height: '8px',
  display: 'flex',
  alignItems: 'center',
  marginRight: '5px',
  marginLeft: '5px',
  borderRadius: '50%',
  borderWidth: '1px',
  borderColor: 'transparent',
  borderStyle: 'solid',
  cursor: 'pointer',
  ...styles,
});

export const dotButtonWhiteModeStyles = (isSelected) =>
  baseDotButtonStyles({
    background: isSelected ? 'brand.neutral.900' : 'brand.neutral.300',
  });

export const dotButtonDarkModeStyles = (isSelected) =>
  baseDotButtonStyles({
    borderColor: isSelected ? 'transparent' : 'brand.darkBlue.200',
    background: isSelected ? 'brand.darkBlue.200' : 'brand.darkBlue.700',
  });
