import React from 'react';

type IconChatProps = { w?: Number, h?: Number, color?: String };

function IconChat({ w, h, color }: IconChatProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99968 2C4.39416 2 1.33302 4.61482 1.33302 8C1.33302 9.07102 1.64519 10.0738 2.18555 10.939L1.37547 13.0993C1.29047 13.3259 1.33501 13.5808 1.49182 13.7652C1.64863 13.9496 1.89307 14.0345 2.13043 13.9871L5.08301 13.3965C6.00252 13.7974 6.99609 14.0033 8.00082 14C11.6059 13.9995 14.6664 11.3848 14.6664 8C14.6664 4.61482 11.6052 2 7.99968 2ZM2.66634 8C2.66634 5.49452 4.97853 3.33333 7.99967 3.33333C11.0208 3.33333 13.333 5.49452 13.333 8C13.333 10.5055 11.0208 12.6667 7.99967 12.6667L7.9974 12.6667C7.11595 12.6697 6.24499 12.4754 5.44834 12.0982C5.31877 12.0368 5.17285 12.0188 5.03226 12.0469L3.04503 12.4444L3.55389 11.0874C3.63179 10.8797 3.60125 10.6468 3.47243 10.4662C2.95854 9.74572 2.66634 8.90085 2.66634 8ZM5.33301 7.33333C4.96482 7.33333 4.66634 7.63181 4.66634 8C4.66634 8.36819 4.96482 8.66667 5.33301 8.66667H5.33967C5.70786 8.66667 6.00634 8.36819 6.00634 8C6.00634 7.63181 5.70786 7.33333 5.33967 7.33333H5.33301ZM7.99967 7.33333C7.63148 7.33333 7.33301 7.63181 7.33301 8C7.33301 8.36819 7.63148 8.66667 7.99967 8.66667H8.00634C8.37453 8.66667 8.67301 8.36819 8.67301 8C8.67301 7.63181 8.37453 7.33333 8.00634 7.33333H7.99967ZM10.6663 7.33333C10.2982 7.33333 9.99967 7.63181 9.99967 8C9.99967 8.36819 10.2982 8.66667 10.6663 8.66667H10.673C11.0412 8.66667 11.3397 8.36819 11.3397 8C11.3397 7.63181 11.0412 7.33333 10.673 7.33333H10.6663Z"
        fill={color}
      />
    </svg>
  );
}

IconChat.defaultProps = {
  w: 16,
  h: 16,
  color: 'currentColor',
};

export default IconChat;
