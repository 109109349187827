import React from 'react';

function IconUnlock() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.33366C9.33696 3.33366 8.70107 3.59705 8.23223 4.06589C7.76339 4.53473 7.5 5.17062 7.5 5.83366V8.33366H15C15.663 8.33366 16.2989 8.59705 16.7678 9.06589C17.2366 9.53473 17.5 10.1706 17.5 10.8337V15.8337C17.5 16.4967 17.2366 17.1326 16.7678 17.6014C16.2989 18.0703 15.663 18.3337 15 18.3337H5C4.33696 18.3337 3.70107 18.0703 3.23223 17.6014C2.76339 17.1326 2.5 16.4967 2.5 15.8337V10.8337C2.5 10.1706 2.76339 9.53473 3.23223 9.06589C3.70107 8.59705 4.33696 8.33366 5 8.33366H5.83333V5.83366C5.83333 4.72859 6.27232 3.66878 7.05372 2.88738C7.83512 2.10598 8.89493 1.66699 10 1.66699C11.1051 1.66699 12.1649 2.10598 12.9463 2.88738C13.7277 3.66878 14.1667 4.72859 14.1667 5.83366C14.1667 6.2939 13.7936 6.66699 13.3333 6.66699C12.8731 6.66699 12.5 6.2939 12.5 5.83366C12.5 5.17062 12.2366 4.53473 11.7678 4.06589C11.2989 3.59705 10.663 3.33366 10 3.33366ZM5 10.0003C4.77899 10.0003 4.56702 10.0881 4.41074 10.2444C4.25446 10.4007 4.16667 10.6126 4.16667 10.8337V15.8337C4.16667 16.0547 4.25446 16.2666 4.41074 16.4229C4.56702 16.5792 4.77899 16.667 5 16.667H15C15.221 16.667 15.433 16.5792 15.5893 16.4229C15.7455 16.2666 15.8333 16.0547 15.8333 15.8337V10.8337C15.8333 10.6126 15.7455 10.4007 15.5893 10.2444C15.433 10.0881 15.221 10.0003 15 10.0003H5ZM10 11.667C10.4602 11.667 10.8333 12.0401 10.8333 12.5003V14.167C10.8333 14.6272 10.4602 15.0003 10 15.0003C9.53976 15.0003 9.16667 14.6272 9.16667 14.167V12.5003C9.16667 12.0401 9.53976 11.667 10 11.667Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconUnlock;
