import React, { useEffect } from 'react';
import { Flex, Stack, VStack, Heading, Text, Box } from '@chakra-ui/react';
import customTheme from '@theme';
import { BaselaneButton } from '@shared/components';
import IconChartWithBadge from '@icons/legacy/IconChartWithBadge';
import getBreakPoints from '@core/utils/getBreakPoints';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import IconAddFundDollarSign from '@icons/legacy/IconAddFundDollarSign';
import { buttonStyles } from '@core/components/OnboardingTriage/styles/success.styles';

import {
  outerContainerStyles,
  innerContainerStyles,
  iconContainerStyles,
  textContainerStyles,
  headingStyles,
  textStyles,
  apyButtonStyles,
  backToRCVuttonStyles,
} from './styles/apyPromotionsBanner.styles';

type APYPromotionBannerProps = {
  userPromoApy: String,
  buttonText: String,
  onRightButtonClick: Function,
  isFromRC: Boolean,
};

const APYPromotionBanner = ({
  userPromoApy,
  buttonText,
  onRightButtonClick,
  isFromRC,
}: APYPromotionBannerProps) => {
  const { isMin899 } = getBreakPoints();

  const buttonLabel = isMin899 ? 'Add Funds' : 'Fund Your Account';

  useEffect(() => {
    sendSegmentEvent('baselane_banking_approved_pageload', {});
  }, []);

  return (
    <>
      <Flex {...{ ...outerContainerStyles(isMin899) }}>
        <Flex {...{ ...innerContainerStyles(isMin899, isFromRC) }}>
          <Stack {...{ ...iconContainerStyles }}>
            <IconChartWithBadge
              {...{
                w: '48px',
                h: '48px',
                color: 'FFFFFF',
              }}
            />
          </Stack>
          <VStack {...textContainerStyles(isMin899)}>
            <Heading as="h4" {...{ ...headingStyles }}>
              You’ve unlocked{' '}
              <Text as="span" {...{ color: 'brand.blue.700' }}>
                {userPromoApy}%
              </Text>{' '}
              APY today
            </Heading>
            <Text {...{ ...textStyles(isMin899) }}>
              Make your savings grow faster with our leading interest rates.
            </Text>
          </VStack>
          {!isFromRC && (
            <BaselaneButton
              id={`${buttonText?.toLowerCase()?.replace(/\s/g, '')}-button`}
              variant="filled"
              palette="primary"
              leftIcon={
                !isMin899 && (
                  <Box {...{ mr: '6px' }}>
                    <IconAddFundDollarSign color={customTheme.colors.brand.neutral.white} />
                  </Box>
                )
              }
              styles={{ ...buttonStyles, ...apyButtonStyles(isMin899) }}
              onClick={onRightButtonClick}
            >
              {buttonLabel}
            </BaselaneButton>
          )}
        </Flex>
      </Flex>

      {isFromRC && (
        <BaselaneButton
          id="back-to-crent-collection-button"
          variant="filled"
          palette="primary"
          styles={{ ...buttonStyles, ...backToRCVuttonStyles(isMin899) }}
          onClick={onRightButtonClick}
        >
          Back to rent Collection
        </BaselaneButton>
      )}
    </>
  );
};

export default APYPromotionBanner;
