import React, { ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';
import { Icon16Check } from '@icons/16px';

import {
  completeActiveContainerStyles,
  completeContainerStyles,
  inactiveClickableContainerStyles,
  inactiveContainerStyles,
} from './stepNumber.styles';

type StepCompleteProps = {
  isComplete: boolean,
  isActive: boolean,
  variant: string,
  element: ReactElement | string | undefined,
};

const StepComplete = ({ isComplete, isActive, variant, element }: StepCompleteProps) => {
  let styles = isComplete && isActive ? completeActiveContainerStyles : completeContainerStyles;
  if (!isComplete) {
    styles = variant === 'clickable' ? inactiveClickableContainerStyles : inactiveContainerStyles;
  }

  return <Flex {...styles}>{isComplete ? <Icon16Check /> : element}</Flex>;
};

export default StepComplete;
