import React, { useState, useEffect, useRef } from 'react';
import { cloneDeep, unset } from 'lodash';
import { useMutation } from '@apollo/client';
import { useDisclosure, useToast } from '@chakra-ui/react';
import {
  AlertHeader,
  BaselaneAlert,
  BaselaneDrawer,
  AlertFooter,
  BaselaneButton,
  BaselaneButtonGroup,
} from '@shared/components';
import {
  GET_PROPERTY_SUMMARY,
  FRAGMENT_PROPERTY,
  CREATE_PROPERTY,
  UPDATE_PROPERTY,
  DELETE_PROPERTY,
} from '@core/apollo/queries';
import { getInitialValues, formatValuesForAPI } from '@pages/PropertiesPage/helpers/detail.helper';

import Body from './DrawerBody';
import AddEditPropertyButton from './AddEditPropertyButton';
import {
  mobileIconBgStyles,
  mobileIconContainerStyles,
  mobileTitleTextStyles,
  mobileIconSecondaryBgStyles,
  alertCreatePropertyBody,
  mobileAlertContainerStyles,
  mobileAlertheaderStyles,
  mobileBodyStyles,
} from '../styles/responsive.style';

type AddEditPropertyProps = {
  property?: Object,
  buttonLabel?: String,
  buttonStyles?: Object,
  variant?: String,
  palette?: String,
  showTracker: boolean,
  leftIcon: Function,
  financials: boolean,
  isDelete: boolean,
  hasData: boolean,
  setProperty?: Function,
  deselectProperty: Function,
  handleCreatePropertySuccess?: Function,
};

function AddEditProperty({
  property,
  buttonLabel,
  buttonStyles,
  variant,
  palette,
  leftIcon,
  showTracker = false,
  financials = false,
  isDelete,
  hasData = false,
  setProperty,
  deselectProperty,
  handleCreatePropertySuccess,
}: AddEditPropertyProps) {
  // drawer states
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  // alert for drawer
  const [isDrawerAlertOpen, setIsDrawerAlertOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onAlertIsClose = () => setIsDrawerAlertOpen(false);
  const cancelRef = useRef();

  // property states
  const isInitialDetailsForm = !!property;
  const initialValues = () => getInitialValues(property);

  // form states
  const [detailsComplete, setDetailsComplete] = useState(isInitialDetailsForm);
  const [isDirty, setIsDirty] = useState({
    details: false,
    financials: false,
    currentMarketValue: false,
    currentMortgageBalance: false,
  });
  const [isValid, setIsValid] = useState(detailsComplete);
  const propertyFormRef = React.useRef(null);

  // value/footer states
  const [variables, setVariables] = useState(initialValues());

  // market value / mortgage balance drawer states
  const [showZillow, toggleShowZillow] = useState(false);
  const [showMortgage, toggleShowMortgage] = useState(false);

  // query/mutation
  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    refetchQueries: [{ query: GET_PROPERTY_SUMMARY }],
    update: (cache, { data: { updateProperty: updatedProperty } }) => {
      cache.modify({
        id: cache.identify(updatedProperty),
        fields: {
          property(existingProperty = []) {
            const updatedPropertyRef = cache.writeFragment({
              data: updatedProperty,
              fragment: FRAGMENT_PROPERTY,
            });
            return [...existingProperty, updatedPropertyRef];
          },
        },
      });
    },
  });

  const [createProperty] = useMutation(CREATE_PROPERTY, {
    refetchQueries: [{ query: GET_PROPERTY_SUMMARY }],
    update: (cache, { data: { createProperty: newProperty } }) => {
      cache.modify({
        fields: {
          property(existingProperties = []) {
            const newPropertyRef = cache.writeFragment({
              data: newProperty,
              fragment: FRAGMENT_PROPERTY,
            });
            return [...existingProperties, newPropertyRef];
          },
        },
      });
    },
    onCompleted: () => handleCreatePropertySuccess(),
  });

  const [deleteProperty] = useMutation(DELETE_PROPERTY, {
    refetchQueries: [{ query: GET_PROPERTY_SUMMARY }],
  });

  // toaster
  const toast = useToast();

  // ref for children component so we can call back button function from drawer
  const zillowRef = useRef();
  const mortgageRef = useRef();

  const setShowZillow = (value) => {
    toggleShowZillow(value);
  };
  const setShowMortgage = (value) => {
    toggleShowMortgage(value);
  };

  const cleanUp = () => {
    onAlertIsClose();
    setIsDirty({
      details: false,
      financials: false,
      currentMarketValue: false,
      currentMortgageBalance: false,
    });
    setIsValid(detailsComplete);
    // setHideFooter(false);
  };

  const setFormVariables = (newVariables) => {
    setVariables({
      ...variables,
      ...newVariables,
    });
  };

  /**
   * Clean up property states.
   *
   * @param isSave a boolean to differentiate between save & close vs just closing drawer
   */
  const handleDrawerClose = (isSave = false) => {
    if (showZillow) {
      zillowRef.current.handleOnBackButtonClick();
      return;
    }
    if (showMortgage) {
      mortgageRef.current.handleOnBackButtonClick();
      return;
    }
    setShowMortgage(false);
    setShowZillow(false);

    // check if an event is passed in instead
    const checkIsSave = typeof isSave === 'boolean' && isSave;
    const {
      details: isDetailsDirty,
      financials: isFinancialsDirty,
      currentMarketValue: isCurrentMarketValueDirty,
      currentMortgageBalance: isCurentMortgageBalanceDirty,
    } = isDirty;

    const isFormDirty =
      isDetailsDirty ||
      isFinancialsDirty ||
      isCurrentMarketValueDirty ||
      isCurentMortgageBalanceDirty;

    if (!checkIsSave && isFormDirty) {
      setIsDrawerAlertOpen(true);
    } else {
      cleanUp();
      onClose();
    }
  };

  const handleContinueClick = () => {
    cleanUp();
    setVariables(initialValues());
    onClose();
  };

  const handleOnShowSuccessToast = (isAddNewPropertyForm) =>
    toast({
      position: 'bottom-left',
      description: isAddNewPropertyForm
        ? 'Property created successfully'
        : 'Property details updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const handleOnShowErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  let drawerTitle = 'Add Property';

  if (property) {
    drawerTitle = 'Edit Property';
    if (financials) {
      drawerTitle = hasData ? 'Edit Financials' : 'Add Financials';
    }
  }

  const handleUpdateProperty = (propertyWithUpdates) => {
    const updateParams = cloneDeep(propertyWithUpdates);

    const newProperty = {
      ...propertyWithUpdates,
      units: propertyWithUpdates.units.filter((unit) => !unit?.isDelete),
    };

    updateProperty({
      variables: updateParams,
      update(cache) {
        const allData = cloneDeep(cache.data.data);
        const oldProperty = allData[`Property:${newProperty.id}`];
        const updatedProperty = { ...oldProperty, ...newProperty };
        setVariables(variables);
        // eslint-disable-next-line no-param-reassign
        return (allData[`Property:${newProperty.id}`] = updatedProperty); // eslint-disable-line no-return-assign
      },
    })
      .then((res) => {
        if (!res.data?.updateProperty && res.errors?.length > 0) {
          handleOnShowErrorToast();
        } else {
          handleOnShowSuccessToast(false);
          onClose();
        }
      })
      .catch(() => handleOnShowErrorToast());

    setProperty(newProperty);
  };

  const handleSave = () => {
    if (property) {
      const newProperty = formatValuesForAPI(variables, property);
      handleUpdateProperty(newProperty);
    } else {
      const formattedVariables = formatValuesForAPI(variables);
      if (variables.id) {
        handleUpdateProperty(formattedVariables);
      } else {
        createProperty({ variables: formattedVariables })
          .then(({ data: { createProperty: createPropertyRes } }) => {
            setVariables((prevState) => ({
              ...prevState,
              id: createPropertyRes.id,
              units: createPropertyRes.units?.map((unit) => {
                const tempUnit = { ...unit };
                unset(tempUnit, '__typename');
                return tempUnit;
              }),
            }));
            setProperty(createPropertyRes);
            onClose();
          })
          .catch(() => handleOnShowErrorToast());
      }
    }
    cleanUp();
  };

  useEffect(() => {
    setIsValid(detailsComplete);
  }, [detailsComplete]);

  const alertDrawerCloseFooter = (
    <AlertFooter
      leftButtonText="Exit Without Saving"
      rightButtonText="Continue Editing"
      leftButtonProps={{ variant: 'outline', palette: 'neutral', size: 'md' }}
      rightButtonProps={{ variant: 'filled', palette: 'primary', size: 'md' }}
      cancelRef={cancelRef}
      leftButtonEvent={handleContinueClick}
      rightButtonEvent={onAlertIsClose}
    />
  );

  const closeDeletePropertyAlert = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteProperty = () => {
    const { id } = property;
    deleteProperty({
      variables: { id },
      update: (cache) => {
        cache.modify({
          fields: {
            property(existingProperty, { readField }) {
              return existingProperty.filter((p) => id !== readField('id', p));
            },
          },
        });
        cache.evict({ id: `Property:${id}` });
        cache.gc();
      },
    }).then(() => {
      closeDeletePropertyAlert();
      toast({
        position: 'bottom-left',
        description: 'Property deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      deselectProperty();
    });
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const canDeleteProperty = () => property?.units?.every((u) => u?.isUnitDeletable);
  const { details: isDetailsDirty, financials: isFinancialsDirty } = isDirty;

  const handlePropertySave = async () => {
    if (propertyFormRef.current) {
      // Marking fields as touched so that validation can be triggered.
      await propertyFormRef.current.setTouched({
        propertyName: true,
        type: true,
        address: true,
        city: true,
        state: true,
        zipcode: true,
      });

      // Manually triggering validation.
      await propertyFormRef.current.validateForm();
    }

    const formIsValid = propertyFormRef.current?.isValid;

    if (!formIsValid) {
      return;
    }

    if ((property && isValid) || (!property && isValid)) {
      handleSave();
    }
  };

  const isFormDirty = isDetailsDirty || isFinancialsDirty;

  const isRightButtonDisabled = property && (!isValid || !isFormDirty);

  const alertDeletePropertyFooter = (
    <AlertFooter
      leftButtonText="Cancel"
      rightButtonText="Delete Property"
      leftButtonProps={{ variant: 'outline', palette: 'neutral' }}
      rightButtonProps={{ variant: 'filled', palette: 'danger' }}
      cancelRef={cancelRef}
      leftButtonEvent={closeDeletePropertyAlert}
      rightButtonEvent={handleDeleteProperty}
    />
  );

  const alertExitPropertyHeader = (
    <AlertHeader
      titleTextStyles={mobileTitleTextStyles}
      iconBgStyles={mobileIconSecondaryBgStyles}
      iconContainerStyles={mobileIconContainerStyles}
      title="Are you sure you want to exit?"
      headerStyles={mobileTitleTextStyles}
    />
  );

  const alerDeletePropertyHeader = (
    <AlertHeader
      titleTextStyles={mobileTitleTextStyles}
      iconBgStyles={mobileIconSecondaryBgStyles}
      iconContainerStyles={mobileIconContainerStyles}
      title="Are you sure you want to delete this property?"
      headerStyles={mobileTitleTextStyles}
    />
  );

  const addMobilePropertyOnClick = (e) => {
    onOpen(e);
    setDetailsComplete(isInitialDetailsForm);
    setIsDirty({
      details: false,
      financials: false,
      currentMarketValue: false,
      currentMortgageBalance: false,
    });
    setVariables(initialValues());
  };

  return (
    <>
      <AddEditPropertyButton
        canDeleteProperty={canDeleteProperty()}
        {...{
          property,
          buttonLabel,
          buttonStyles,
          variant,
          palette,
          leftIcon,
          showTracker,
          financials,
          isDelete,
          hasData,
          handleDelete,
          addMobilePropertyOnClick,
        }}
      />

      {/* Original Drawer */}
      <BaselaneDrawer
        size="newdrawerfull"
        newDesignDrawer
        title={drawerTitle}
        isOpen={isOpen}
        onClose={handleDrawerClose}
        onOverlayClick={handleDrawerClose}
        finalFocusRef={btnRef}
        footer={
          <BaselaneButtonGroup styles={{ width: '100%' }} size="md">
            <BaselaneButton
              variant="outline"
              palette="primary"
              size="md"
              onClick={handleDrawerClose}
            >
              Close
            </BaselaneButton>
            <BaselaneButton
              variant="filled"
              palette="primary"
              size="md"
              buttonType="submit"
              onClick={handlePropertySave}
              isDisabled={isRightButtonDisabled}
              isFullWidth
              style={{ w: '100%' }}
            >
              Save
            </BaselaneButton>
          </BaselaneButtonGroup>
        }
      >
        <Body
          {...{
            setIsDirty,
            setIsValid,
            isDirty,
            detailsComplete,
            setDetailsComplete,
            property,
            setFormVariables,
            initialValues: initialValues(),
            variables,
            handleDrawerClose,
            setShowMortgage,
            setShowZillow,
            financials,
            propertyFormRef,
          }}
        />
      </BaselaneDrawer>

      <BaselaneAlert
        alertBody={alertCreatePropertyBody}
        isOpen={isDrawerAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertIsClose}
        isCentered
        containerStyles={mobileAlertContainerStyles}
        headerStyles={mobileAlertheaderStyles}
        bodyStyles={mobileBodyStyles}
        titleTextStyles={mobileTitleTextStyles}
        iconBgStyles={mobileIconBgStyles}
        iconContainerStyles={mobileIconContainerStyles}
        header={alertExitPropertyHeader}
        body="You will lose your progress if you exit now."
        footer={alertDrawerCloseFooter}
        showCloseButton
        closeButtonStyles={{ border: '0px' }}
      />
      <BaselaneAlert
        isOpen={showDeleteModal}
        onClose={closeDeletePropertyAlert}
        header={alerDeletePropertyHeader}
        body="Deleting a property will remove all property data from Baselane; this includes all property details, financial information, tagging of transactions, and work in progress leases. This action is permanent and can't be reversed."
        footer={alertDeletePropertyFooter}
        showCloseButton
        closeButtonStyles={{ border: '0px' }}
        containerStyles={mobileAlertContainerStyles}
        headerStyles={mobileAlertheaderStyles}
        bodyStyles={mobileBodyStyles}
        titleTextStyles={mobileTitleTextStyles}
        iconBgStyles={mobileIconBgStyles}
        iconContainerStyles={mobileIconContainerStyles}
      />
    </>
  );
}

AddEditProperty.defaultProps = {
  property: null,
  buttonLabel: 'Add Property',
  buttonStyles: {},
  variant: 'filled',
  palette: 'primary',
  setProperty: () => {},
  handleCreatePropertySuccess: () => {},
};

export default AddEditProperty;
