// @flow
import { Box, Text, Flex, Spacer } from '@chakra-ui/react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import type { HighlightedData } from './HighlightedData';
import type { HighlightedDataItem } from './HighlightedDataItem';
import {
  titleStyles,
  itemStyles,
  keyStyles,
  valueStyles,
  subValueStyles,
  mobileKeyStyles,
  mobileValueStyles,
} from './styles/baselaneHighlightedDataDisplay.style';

type BaselaneHighlightedDataDisplayProps = {
  configuration: HighlightedData,
  defaultDataDisplayPropStyles: Object,
  hasBorder: Boolean,
};

function BaselaneHighlightedDataDisplay({
  configuration,
  defaultDataDisplayPropStyles,
  hasBorder,
}: BaselaneHighlightedDataDisplayProps): any {
  function isHighlightedData(item: HighlightedDataItem): boolean %checks {
    return 'items' in item;
  }

  const defaultDataDisplayStyles = {
    bg: isMobile ? 'none' : 'brand.blue.50',
    borderRadius: '8px',
    p: isMobile ? '0' : '12px 16px',
    ...defaultDataDisplayPropStyles,
    ...configuration?.styles?.parent,
  };

  const nestedDataDisplayStyles = {
    ...defaultDataDisplayStyles,
    m: 0,
    p: 0,
    ...configuration?.styles?.nested,
  };

  const keyStyling = isMobile ? mobileKeyStyles : keyStyles;
  const valueStyling = isMobile ? mobileValueStyles : valueStyles;

  function buildHighlightedDisplay(data: HighlightedData, isNested = false): any {
    const styles = isNested ? nestedDataDisplayStyles : defaultDataDisplayStyles;
    const FALLBACK_VALUE = '-';
    return (
      <Box key={uuidv4()} {...styles} w="100%">
        {!isMobile && data?.title && <Text {...titleStyles}>{data?.title} </Text>}
        {isMobile && data?.hasMobileTitle && <Text {...titleStyles}>{data?.title} </Text>}

        {data.items.map((item: any) =>
          isHighlightedData(item) ? (
            buildHighlightedDisplay(item, true)
          ) : (
            <React.Fragment key={uuidv4()}>
              <Flex key={uuidv4()} {...{ ...itemStyles }}>
                <Box {...keyStyling}>{item.key}</Box>
                <Spacer />
                <Text id={item.id} {...valueStyling}>
                  {item.value || FALLBACK_VALUE}
                </Text>
              </Flex>
              {item.subValue && <Text {...subValueStyles}>{item.subValue}</Text>}
            </React.Fragment>
          )
        )}
        {hasBorder && <Spacer borderBottom="1px solid #F0F2F6" mt="8px" />}
      </Box>
    );
  }

  return buildHighlightedDisplay(configuration);
}

export default BaselaneHighlightedDataDisplay;
