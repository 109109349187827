// @flow
import React, { useRef } from 'react';
import { ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { BaselaneAlert, BaselaneButtonGroup, BaselaneButton } from '@shared/components';
import { getDynamicPopupCaptions } from '../../helpers/whoPaysACH.helpers';
import { sharedPopupStyles } from '../../styles/popup.shared.styles';

type WhoPaysACHPopupProps = {
  isOpen: boolean,
  onClose: Function,
  handleConfirmSaveClick: Function,
  isLoading: boolean,
  hasLeaseCollectingRentOutsideBaselane: boolean,
};

const { body, footer } = sharedPopupStyles;

function WhoPaysACHPopup({
  isOpen,
  onClose,
  handleConfirmSaveClick,
  isLoading,
  hasLeaseCollectingRentOutsideBaselane,
}: WhoPaysACHPopupProps): any {
  const cancelRef = useRef();

  const dynamicPopupCaptions = getDynamicPopupCaptions(hasLeaseCollectingRentOutsideBaselane);

  return (
    <BaselaneAlert
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      showCloseButton
      header={<Text>{dynamicPopupCaptions.title}</Text>}
      body={
        <UnorderedList {...body}>
          {dynamicPopupCaptions.list.map(({ id, caption }) => (
            <ListItem key={id}>{caption}</ListItem>
          ))}
        </UnorderedList>
      }
      footer={
        <BaselaneButtonGroup size="md" styles={footer?.buttongroup}>
          <BaselaneButton
            variant="outline"
            palette="neutral"
            size="md"
            ref={cancelRef}
            onClick={onClose}
            styles={footer?.button}
          >
            Cancel
          </BaselaneButton>
          <BaselaneButton
            variant="filled"
            palette="primary"
            size="md"
            onClick={handleConfirmSaveClick}
            styles={footer?.button}
            isLoading={isLoading}
          >
            Confirm
          </BaselaneButton>
        </BaselaneButtonGroup>
      }
    />
  );
}

export default WhoPaysACHPopup;
