import process from 'process';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { firebaseWebKey } from '@core/constants/envVars';

const { Buffer } = require('buffer');

const buff = Buffer.from(firebaseWebKey, 'base64');
const serviceAccount = JSON.parse(buff.toString('utf-8'));

const firebaseApp = initializeApp(serviceAccount);
export const auth = getAuth(firebaseApp);

// re-export updatePassword for convenience
export { updatePassword };

// verify email
const url =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://app.baselane.com/verified'
    : 'http://localhost:3000/verified';
export const doSendEmailVerification = () => auth.currentUser.sendEmailVerification({ url });

export const deleteUser = () => auth.currentUser.delete();

// google
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => signInWithPopup(auth, googleProvider);

// facebook
const facebookProvider = new FacebookAuthProvider();
facebookProvider.setCustomParameters({ prompt: 'consent', display: 'popup' });
export const signInWithFacebook = () => signInWithPopup(auth, facebookProvider);

// custom signup
export const signUpWithCustomEmail = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

// custom signin
export const signInWithCustomEmail = (email, password) =>
  signInWithEmailAndPassword(auth, email, password);

export const getCustomClaims = (socialAuthUserRaw) =>
  socialAuthUserRaw?.getIdTokenResult().then((idTokenResult) => idTokenResult?.claims);

export const checkEmail = (email = null) => fetchSignInMethodsForEmail(auth, email);

export const signOut = () => auth.signOut();

// forgot password
// action code is oobCode
export const resetPassword = async (actionCode, newPassword) => {
  await verifyPasswordResetCode(auth, actionCode);
  await confirmPasswordReset(auth, actionCode, newPassword);
};

// email verify
// action code is oobCode
export const emailVerify = async (actionCode) => {
  await applyActionCode(auth, actionCode);
};

// Note: renamed from 'useQueryParams' as it is a function not a React hook (to fix linter errors)
export function getQueryParams(location, param) {
  return new URLSearchParams(location.search).get(param);
}

export const reauthenticate = (currentPassword) => {
  const user = auth.currentUser;
  const cred = EmailAuthProvider.credential(user.email, currentPassword);
  return reauthenticateWithCredential(user, cred);
};

export const reload = async () => {
  await auth?.currentUser?.reload();
  const { emailVerified } = (await auth?.currentUser) ?? {};
  return emailVerified;
};
