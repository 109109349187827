import { gql } from '@apollo/client';

export const GET_DOCUMENTS = gql`
  query Documents($entityId: String!) {
    fetchDocuments(entityId: $entityId) {
      id
      userId
      documentEntityType
      documentEntityId
      name
      size
      fileExtension
      isDeleted
      isUploaded
      createdAt
      updatedAt
    }
  }
`;

export const GET_DOCUMENT_UPLOAD_URL = gql`
  query generateDocumentUploadUrl($input: DocumentUploadInput!) {
    generateDocumentUploadUrl(input: $input) {
      id
      userId
      documentEntityType
      documentEntityId
      name
      size
      fileExtension
      isDeleted
      isUploaded
      createdAt
      updatedAt
      url
    }
  }
`;

export const GET_DOCUMENT_DOWNLOAD_URL = gql`
  query generateDocumentDownloadUrl($id: ID!) {
    generateDocumentDownloadUrl(id: $id)
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($input: UserDocumentUpdate!) {
    updateUserDocument(input: $input) {
      id
      userId
      documentEntityType
      documentEntityId
      name
      size
      fileExtension
      isDeleted
      isUploaded
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: ID!) {
    deleteUserDocument(id: $id) {
      id
      userId
      documentEntityType
      documentEntityId
      name
      size
      fileExtension
      isDeleted
      isUploaded
      createdAt
      updatedAt
    }
  }
`;
