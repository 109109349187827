import React from 'react';

type IconDottedCircularProgressProps = {
  width: string,
  height: string,
  viewBox: string,
  bgColor: string,
  color: string,
};

function IconDottedCircularProgress({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  bgColor = '#FFF',
  color = '#C8D0DA',
}: IconDottedCircularProgressProps) {
  return (
    <svg {...{ width, height, viewBox }} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="9"
        cy="9"
        r="8"
        fill={bgColor}
        stroke={color}
        strokeLinecap="round"
        strokeDasharray="2 2"
      />
    </svg>
  );
}

export default IconDottedCircularProgress;
