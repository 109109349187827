export const getBank = (banks, bankAccountId) => {
  const bank = banks?.find((_bank) =>
    _bank.bankAccounts.find((bankAccount) => {
      if (Number(bankAccount.id) === bankAccountId) return true;
      return bankAccount.subAccounts.find((subAccount) => {
        return Number(subAccount.id) === bankAccountId;
      });
    })
  );
  return bank || '';
};
