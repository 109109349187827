import React from 'react';

type IconArrowDownCircleFilledProps = {
  w?: string,
  h?: string,
  color?: string,
};

function IconArrowDownCircleFilled({ w, h, color }: IconArrowDownCircleFilledProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        d="M7.9502 11.6852c-.24035 0-.43519-.1949-.43519-.4352v-7.5c0-.24035.19484-.43519.43519-.43519.24034 0 .43518.19484.43518.43519v7.5c0 .2403-.19484.4352-.43518.4352Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth=".62963"
      />
      <path
        d="m5 9.5 2.95548 2.9555c.02459.0246.06445.0246.08904 0L11 9.5"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

IconArrowDownCircleFilled.defaultProps = {
  w: '16',
  h: '16',
  color: 'currentColor',
};

export default IconArrowDownCircleFilled;
