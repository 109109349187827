import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import {
  Box,
  ChakraProvider,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';

import { BaselaneDivider, BaselaneDrawer, BaselaneSpinner, T1Dropdown } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import habitatTheme from '@core/themeHabitat';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { GET_PROPERTIES_DROPDOWN_WITH_LEASE_ID } from '@core/apollo/queries';
import { GET_TENANT_INFO_LEASES } from '@features/Property/MaintenancePropertyDrawer/queries';

import { latchelBorderStyles } from './styles';

type MaintenancePropertyDrawerProps = {
  from: string,
};

const MaintenancePropertyDrawer = ({ from }: MaintenancePropertyDrawerProps) => {
  const latchelRef = useRef(null);
  const navigate = useNavigate();
  const { isMax768 } = getBreakPoints();

  const [property, setProperty] = useState(null);
  const [propertyUnit, setPropertyUnit] = useState(null);

  const { data, loading } = useQuery(GET_PROPERTIES_DROPDOWN_WITH_LEASE_ID);

  const getPropertyUnitData = (immediatePropertyData) => {
    const savedPropertyUnitData = propertyUnit ?? property;
    return immediatePropertyData ?? savedPropertyUnitData;
  };

  const handleLatchStatusWidget = (tenantData, immediatePropertyData = null) => {
    // if property does not have an active lease this function will be called immediately from the
    // handleSubmit of the dropdowns. That means the data in the useStates will be updated in time before
    // coming to this function
    const propertyUnitData = getPropertyUnitData(immediatePropertyData);
    const unitId = propertyUnitData?.unit?.id ?? propertyUnitData?.units[0].id;
    // use property + unit id to create a unique reference for latchel sdk to store and use
    const referenceId = btoa(JSON.stringify(`${propertyUnitData.id}-${unitId}`));

    // mapping out address
    const { address: addressObj } = propertyUnitData;
    const {
      address: address1,
      unit: address2,
      city: cityLocality,
      state: stateProvince,
      zipCode: postalCode,
    } = addressObj;

    // mapping out status of the property lease
    const status = propertyUnitData?.unit?.status ?? propertyUnitData?.units[0]?.status;
    const propertyIsVacant = status === 'unoccupied';

    // mapping out tenant information
    const { firstName = '', lastName = '', phoneNumber: tenantPhone = '' } =
      tenantData?.leases?.leases?.[0]?.tenantProfile ?? {};

    const displayName = `${firstName || ''} ${lastName || ''}`;

    window.LatchelPartnerSDK.embedStatusWidget('latchel', {
      referenceId,
      postalCode,
      countryCode: 'US',
      address1,
      address2,
      cityLocality,
      stateProvince,
      propertyIsVacant,
      tenantName: displayName,
      tenantPhone: tenantPhone ?? '',
    });
  };

  const [getTenantInfo] = useLazyQuery(GET_TENANT_INFO_LEASES, {
    onCompleted: (tenantData) => {
      handleLatchStatusWidget(tenantData);
    },
  });

  const listOfProperties = data?.property ?? [];
  const listOfUnits = property?.units ?? [];

  return (
    <ChakraProvider theme={habitatTheme}>
      <BaselaneDrawer
        isOpen
        newDesignDrawer
        size={isMax768 ? 'newdrawerfull' : 'newdrawersm'}
        title="Maintenance requests"
        closeEvent={() => onDrawerClose(navigate, from)}
      >
        <Stack spacing={0.5}>
          <Heading size="headline-md">Book a vetted contractor instantly</Heading>
          <Text textStyle="sm">
            Submit your maintenance requirements and get matched with a vetted, licensed contractor
            instantly (contractor will confirm the appointment in 24 hours).
          </Text>
        </Stack>

        <BaselaneDivider styles={{ my: 3 }} />

        <Stack spacing={2}>
          <Heading size="headline-md">
            Select a property to submit a request or view existing ones
          </Heading>

          {loading ? (
            <BaselaneSpinner />
          ) : (
            <Box>
              <FormControl isRequired>
                <FormLabel>Property</FormLabel>
                <T1Dropdown
                  classNames={['auto-width-dropdown', 'auto-select-input-width', 'input-form-md']}
                  title="property"
                  placeholder="Select"
                  showValueByFields={['name']}
                  itemRenderer={itemRenderer}
                  selectedItem={property}
                  data={listOfProperties}
                  handleSubmit={(propertyId) => {
                    window.LatchelPartnerSDK.removeStatusWidget('latchel');
                    setPropertyUnit(null);

                    const propertyObj = listOfProperties.find((p) => p.id === propertyId);
                    setProperty(propertyObj);

                    if (propertyObj.units.length === 1 && propertyObj?.units?.[0]?.lease?.id) {
                      getTenantInfo({
                        variables: {
                          leaseId: propertyObj.units[0].lease.id,
                        },
                      });
                    } else if (propertyObj.units.length === 1) {
                      handleLatchStatusWidget(null, propertyObj);
                    }
                  }}
                  isMobile={isMax768}
                />
              </FormControl>

              {property && listOfUnits.length > 1 && (
                <FormControl isRequired>
                  <FormLabel>Unit</FormLabel>
                  <T1Dropdown
                    classNames={[
                      'auto-width-dropdown',
                      'auto-select-input-width',
                      'input-form-md',
                      'auto-height',
                      'dateFilter-width-dropdown',
                    ]}
                    placeholder="Select"
                    title="unit"
                    showValueByFields={['name']}
                    itemRenderer={itemRenderer}
                    selectedItem={propertyUnit?.unit ?? null}
                    data={listOfUnits}
                    handleSubmit={(unitId) => {
                      const foundUnit = listOfUnits.find((u) => u.id === unitId);
                      let propertyUnitObj = { ...property };
                      if (foundUnit) {
                        propertyUnitObj = { ...propertyUnitObj, unit: foundUnit };
                      }
                      setPropertyUnit(propertyUnitObj);

                      if (propertyUnitObj?.unit?.lease?.id) {
                        getTenantInfo({
                          variables: {
                            leaseId: propertyUnitObj?.unit?.lease?.id ?? null,
                          },
                        });
                      } else {
                        handleLatchStatusWidget(null, propertyUnitObj);
                      }
                    }}
                    isMobile={isMax768}
                  />
                </FormControl>
              )}
            </Box>
          )}

          <Stack className="latchel-wrapper" ref={latchelRef}>
            <Box
              id="latchel"
              {...latchelBorderStyles}
              borderColor={
                (property && listOfUnits.length === 1) ||
                (property && listOfUnits.length > 1 && propertyUnit)
                  ? 'brand.darkBlue.200'
                  : 'transparent'
              }
            />
          </Stack>
        </Stack>
      </BaselaneDrawer>
    </ChakraProvider>
  );
};

export default MaintenancePropertyDrawer;
