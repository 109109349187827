// @flow
import React, { useContext, useRef } from 'react';
import { Stack, Text, Box, useToast } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { NATIVE_BANK } from '@routes';
import { IconEye, IconEyeHide } from '@icons';
import { Icon16Delete } from '@icons/16px';
import { BaselaneButton, BaselaneDivider, CurrencyText } from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';

import {
  selectedText,
  selectedAmount,
  iconWrapper,
  dividerStyles,
} from './styles/transactionsBulkActions.style';
import {
  calculateTotalAmount,
  selectionTextHelper,
  actionHelper,
} from './helpers/transactionsBulkActions.helper';
import EditMultipleTransactionsDrawer from './EditMultipleTransactionsDrawer';

type TransactionsBulkActionsProps = {
  selectedTransactions: Array<Object>,
  handleDeselectTransactions: Function,
  setSelectedTransactions: Function,
  handleSelectedTransactions: Function,
  refetch: Function,
};

function TransactionsBulkActions({
  selectedTransactions,
  handleDeselectTransactions,
  setSelectedTransactions,
  handleSelectedTransactions,
  refetch,
}: TransactionsBulkActionsProps): any {
  const {
    startPollingTransactionsSummary,
    stopPollingTransactionsSummary,
    updateTransactions,
  } = useContext(TransactionContext);
  const editMultipleTrxRef = useRef(null);

  const totalSelectedTrxCount = selectedTransactions?.length || 0;
  const [selectedTrx] = selectedTransactions;
  const selectionText = selectionTextHelper(totalSelectedTrxCount, selectedTransactions);
  const totalAmount = calculateTotalAmount(selectedTransactions);
  const action = actionHelper(selectedTransactions);

  const currentUrl = useLocation();
  const { pathname } = currentUrl;

  const isNativeBanking = pathname.indexOf(NATIVE_BANK) > -1;

  const handleDeselect = () => {
    handleDeselectTransactions(true);
  };

  const handleActionClick = () => {
    const updatedTransactions = selectedTransactions.map((t) => ({
      id: Number(t.id),
      hidden: action === 'HIDE',
    }));

    updateTransactions({ variables: { input: updatedTransactions } }).then(
      (res) => {
        const updatedTrx = res?.data?.updateTransactions.map((t) => ({
          ...t,
          isChecked: true,
        }));
        // refetching trx summary only if bulk trxs are hidden/unhidden
        if (action === 'HIDE' || action === 'UNHIDE') {
          setSelectedTransactions(updatedTrx);
          startPollingTransactionsSummary(1500);
          setTimeout(() => {
            stopPollingTransactionsSummary();
          }, 4500);
        }
      },
      (error) => {
        throw new Error(error); // TODO: Better error handle
      }
    );
  };

  const toast = useToast();

  const showToastDeleteTrxSuccessful = () =>
    toast({
      position: 'bottom-left',
      description: `Your Transaction has been deleted!`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showToastDeleteTrxError = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleDeleteClick = () => {
    const updatedTransactions = selectedTransactions.map((t) => ({
      id: Number(t.id),
      isDeleted: true,
    }));

    updateTransactions({ variables: { input: updatedTransactions } }).then(
      (res) => {
        const updatedTrx = res?.data?.updateTransactions.map((t) => ({
          ...t,
          isChecked: true,
        }));
        // refetching trx summary only if bulk trxs are hidden/unhidden

        setSelectedTransactions(updatedTrx);
        startPollingTransactionsSummary(1500);
        setTimeout(() => {
          stopPollingTransactionsSummary();
        }, 4500);
        showToastDeleteTrxSuccessful();
        handleDeselect();
      },
      (error) => {
        showToastDeleteTrxError();
        throw new Error(error); // TODO: Better error handle
      }
    );
  };

  const onEditMultipleTrxClose = () => editMultipleTrxRef?.current?.close();

  return (
    <>
      <EditMultipleTransactionsDrawer
        {...{
          editMultipleTrxRef,
          selectedTransactions,
          totalSelectedTrxCount,
          selectedTrx,
          onEditMultipleTrxClose,
          handleSelectedTransactions,
          totalAmount,
          refetch,
        }}
      />

      <Stack direction="row" align="center">
        {/* TODO: Delete once checkbox indeterminate ticket is done */}
        {/* <BaselaneButton type="link" onClick={handleDeselect}>
          Deselect All
        </BaselaneButton> */}
        <BaselaneButton
          id="edit-transaction-button"
          size="md"
          variant="filled"
          palette="primary"
          onClick={() => editMultipleTrxRef?.current?.open()}
        >
          Edit Transaction(s)
        </BaselaneButton>

        {!pathname.includes(NATIVE_BANK) && (
          <BaselaneButton size="md" variant="outline" palette="neutral" onClick={handleActionClick}>
            <Box as="span" {...iconWrapper}>
              {action === 'HIDE' ? (
                <IconEyeHide width="16" height="16" />
              ) : (
                <IconEye width="16" height="16" />
              )}
            </Box>
            {action === 'HIDE' ? 'Hide' : 'Unhide'}
          </BaselaneButton>
        )}
        {!isNativeBanking && (
          <BaselaneButton
            id="delete-transaction-button"
            size="md"
            variant="outline"
            palette="neutral"
            onClick={handleDeleteClick}
          >
            <Box as="span" {...iconWrapper}>
              <Icon16Delete />
            </Box>
            Delete
          </BaselaneButton>
        )}
        <BaselaneDivider isVertical styles={dividerStyles} />
        <Text {...selectedText}>{selectionText}</Text>
        <CurrencyText
          {...selectedAmount}
          amount={totalAmount}
          isRounded={false}
          isPercentage={false}
          color="brand.neutral.700"
          negativeColor="red.800AA"
        />
      </Stack>
    </>
  );
}

export default TransactionsBulkActions;
