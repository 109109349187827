export const alertPopupIconBgStyles = {
  bg: 'red.200',
};

export const alertPopupIconContainerStyles = {
  color: 'red.800AA',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
