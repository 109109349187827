import { gql } from '@apollo/client';

export const CREATE_UPDATE_SPLIT_TX = gql`
  mutation createOrUpdateSplitTx(
    $parentTransactionId: ID!
    $transactionSplitInputs: [TransactionSplitInput!]!
  ) {
    createOrUpdateSplitTx(
      input: {
        parentTransactionId: $parentTransactionId
        transactionSplitInputs: $transactionSplitInputs
      }
    ) {
      id
      splitTransactions {
        id
        description
        bankAccountId
        amount
        tagId
        isCredit
        date
        authorizedDate
        accountOwner
        currencyCode
        location {
          address
          city
          country
          lat
          lon
          postal_code
          region
          store_number
        }
        merchantName
        name
        paymentChannel
        pending
        note
        createdAt
        updatedAt
        propertyId
        unitId
        time
        isDeleted
        isDocumentUploaded
        hidden
        isManual
        isSplit
        parentId
        originalTransaction
        parentTransaction {
          id
          isSplit
          hidden
          amount
          merchantName
          propertyId
          unitId
          tagId
          date
          isDeleted
          isDocumentUploaded
        }
      }
    }
  }
`;

export const GET_TRANSACTION_BY_ID = gql`
  query transactionById($id: ID!) {
    transactionById(id: $id) {
      id
      splitTransactions {
        id
        propertyId
        unitId
        amount
        merchantName
        date
        isDeleted
        isDocumentUploaded
        hidden
        tagId
      }
    }
  }
`;

export const MERGE_SPLIT_TRX = gql`
  mutation mergeSplitTransaction($id: ID!) {
    mergeSplitTransaction(id: $id) {
      id
      description
      bankAccountId
      amount
      tagId
      isCredit
      date
      authorizedDate
      accountOwner
      currencyCode
      location {
        address
        city
        country
        lat
        lon
        postal_code
        region
        store_number
      }
      merchantName
      name
      paymentChannel
      pending
      note
      createdAt
      updatedAt
      propertyId
      unitId
      time
      isDeleted
      isDocumentUploaded
      hidden
      isManual
      isSplit
      parentId
      originalTransaction
      parentTransaction {
        id
        isSplit
        hidden
        amount
        merchantName
        propertyId
        unitId
        tagId
        date
        isDeleted
        isDocumentUploaded
      }
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation UpdateTransactions($input: [UpdateTransaction]) {
    updateTransactions(input: $input) {
      id
      merchantName
      amount
      date
      bankAccountId
      hidden
      isDeleted
      tagId
    }
  }
`;
