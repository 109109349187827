const baseContainerStyles = {
  width: '24px',
  height: '24px',
  borderRadius: 'lg',
  justifyContent: 'center',
  alignItems: 'center',
  textStyle: 'headline-xs',
};

export const inactiveContainerStyles = {
  ...baseContainerStyles,
  bgColor: 'brand.neutral.150',
  color: 'brand.neutral.500',
};

export const inactiveClickableContainerStyles = {
  ...baseContainerStyles,
  bgColor: 'brand.blue.200',
  color: 'brand.blue.800A',
};

export const completeContainerStyles = {
  ...baseContainerStyles,
  bgColor: 'green.200',
  color: 'green.800AA',
};

export const completeActiveContainerStyles = {
  ...baseContainerStyles,
  bgColor: 'green.700',
  color: 'white',
};

export const activeContainerStyles = {
  ...baseContainerStyles,
  bgColor: 'brand.darkBlue.800A',
  color: 'white',
};
