import process from 'process';

export const getStatsigEnviroment = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return 'production';
    case 'pre-prod':
      return 'Pre-Prod';
    case 'uat':
      return 'UAT';
    default:
      return 'development';
  }
};

// If user signs up via the marketing site with URL param ?email=
// then encode the email provided and send it as a Custom User ID to Statsig
// This is currently being used for 2024-07_sign_up_page_experiment
export const getCustomEmailId = async (searchParams) => {
  let customUserId = null;
  const emailParam = searchParams.get('email');

  if (emailParam && emailParam?.length) {
    const encodedEmail = new TextEncoder().encode(emailParam);
    const encryptedEmail = await window.crypto.subtle.digest('SHA-256', encodedEmail);
    const hashArray = Array.from(new Uint8Array(encryptedEmail));
    customUserId = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  }

  return customUserId;
};
