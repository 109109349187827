import { defineStyleConfig } from '@chakra-ui/react';
import textStyles from '../text';

const sizes = {
  ...textStyles,
  // can only override xl + 2xl with this method
  '2xl': {
    fontSize: ['24px', null, null, null, null, null, '32px'],
    lineHeight: ['28px', null, null, null, null, null, '32px'],
    fontWeight: 'normal',
  },
  xl: {
    fontSize: ['20px', null, null, null, null, null, '24px'],
    lineHeight: ['28px', null, null, null, null, null, '32px'],
    fontWeight: 'normal',
  },
};

/**
 * This overrides Chakra's default <Heading /> styles.
 *
 * see: node_modules/@chakra-ui/theme/src/components/heading.ts for implementation details
 */

export const Heading = defineStyleConfig({
  baseStyle: { fontWeight: 'normal' },
  sizes,
});
