import { gql, useQuery } from '@apollo/client';

const GET_SCHEDULED_ONE_TIME_PAYMENT_DETAILS = gql`
  query scheduledOneTimePaymentDetails($transferId: ID) {
    scheduledOneTimePaymentDetails(transferId: $transferId) {
      id
      userId
      note
      fromTransferAccountId
      fromTransferAccount {
        id
        userId
        name
        accountName
        accountNumber
        permission
        amount
        isExternal
        provider
        institution {
          id
        }
        bankAccountId
        isBankConnected
        counterPartyConnectionHistoryCode
      }
      toTransferAccountId
      toTransferAccount {
        id
        userId
        name
        accountName
        accountNumber
        permission
        amount
        isExternal
        provider
        institution {
          id
        }
        bankAccountId
        isBankConnected
        counterPartyConnectionHistoryCode
      }
      payeePaymentMethod {
        achPaymentMethods {
          type
          accountHolderName
          routingNumber
          accountNumber
        }
        wirePaymentMethods {
          accountHolderName
          routingNumber
          accountNumber
          address {
            street
            unit
            city
            state
            postalCode
            country
          }
        }
        checkPaymentMethods {
          id
          name
        }
      }
      transferDate
      completedDate
      createdAt
      amount
      externalId
      type
      typeName
      bankPartner
      status
      metadata {
        routingNumber
        accountNumber
        name
        payeeName
        address {
          street
          unit
          city
          state
          postalCode
          country
        }
        tagId
        unitId
        propertyId
        checkNumber
        email
        bookKeepingNote
      }
      payeePaymentMethodId
      payeePaymentMethod {
        achPaymentMethods {
          id
          type
          accountHolderName
          routingNumber
          accountNumber
          notes
          accountType
          bankName
          isDeletable
        }
        wirePaymentMethods {
          id
          type
          accountHolderName
          routingNumber
          accountNumber
          notes
          address {
            street
            unit
            city
            state
            postalCode
            country
          }
          bankName
          isDeletable
        }
        checkPaymentMethods {
          id
          type
          name
          address {
            street
            unit
            city
            state
            postalCode
            country
          }
          notes
          isDeletable
        }
      }
    }
  }
`;

const useScheduledOneTimePaymentDetails = (transferId) => {
  const { data, loading, error } = useQuery(GET_SCHEDULED_ONE_TIME_PAYMENT_DETAILS, {
    variables: { transferId },
  });

  return {
    data: data?.scheduledOneTimePaymentDetails,
    loading,
    error,
  };
};

export default useScheduledOneTimePaymentDetails;
