export const noDataContainerStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(255,255,255,.7)',
  zIndex: '2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const noDataPopupStyles = {
  borderRadius: '4px',
  p: 5,
  zIndex: '2',
  backgroundColor: 'brand.neutral.white',
  w: '434px',
  textAlign: 'center',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
};

export const noDataPopupTitleStyles = {
  textStyle: 'headline-xl',
  color: 'brand.neutral.700',
};

export const noDataPopupDescriptionStyles = {
  textStyle: 'md',
  mt: '24px',
  mb: '32px',
  color: 'brand.neutral.700',
};
