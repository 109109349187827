import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_BANK_ACCOUNTS_ACTIVE } from '@core/apollo/queries';
import { getDropdownList } from '@shared/components/TransferFunds/helpers';

/**
 * @typedef {Object} UseActiveBankAccountsParams
 * @property {Object} [variables] - The variables for the query.
 * @property {string} [filterBySubType] - The 'subType' to filter by.
 * @property {string} [fetchPolicy='cache-first'] - The fetch policy.
 */

/**
 * @typedef {Object} UseActiveBankAccountsResult
 * @property {Error} error - The error, if any.
 * @property {boolean} loading - Indication that the query is loading.
 * @property {Array} bankAccounts - The list of bank accounts as returned by the query.
 * @property {Array} fromDropdownList - The list of bank accounts for the 'from' dropdown.
 * @property {Array} formattedPlaidAcc - The list of formatted Plaid accounts.
 * @property {Array} toDropdownList - The list of bank accounts for the 'to' dropdown.
 * @property {Object} accountsMap - The accounts by id.
 * @property {Function} refetch - The refetch function.
 */

/**
 * This hook fetches the active bank accounts.
 * @param {UseActiveBankAccountsParams} params - The parameters as described by {@link UseActiveBankAccountsParams}.
 * @returns {UseActiveBankAccountsResult} The result object as described by {@link UseActiveBankAccountsResult}.
 */
const useBankAccountsActive = ({
  variables = {
    // TODO: I should remove these defaults when they will passed when calling the hook.
    isConnectedAccount: true,
    accountStatus: 'Open',
    isTransferable: true,
  },
  filterBySubType,
  fetchPolicy = 'cache-first',
} = {}) => {
  const { loading, error, data, refetch } = useQuery(GET_BANK_ACCOUNTS_ACTIVE, {
    variables,
    fetchPolicy,
  });

  const bankAccounts = data?.bankAccounts || [];

  const filteredBankAccounts = useMemo(() => {
    if (!filterBySubType) {
      return bankAccounts;
    }
    return bankAccounts.filter((account) => {
      if (Array.isArray(filterBySubType)) {
        return filterBySubType.includes(account.accountSubType);
      }

      return account.accountSubType === filterBySubType;
    });
  }, [bankAccounts, variables?.filterBySubType]);

  const {
    fromDropdownList,
    formattedPlaidAcc,
    toDropdownList,
    formattedBaselaneAcc,
    accountsMap,
  } = useMemo(() => {
    return getDropdownList(filteredBankAccounts);
  }, [filteredBankAccounts]);

  return {
    loading,
    error,
    bankAccounts,
    fromDropdownList,
    formattedPlaidAcc,
    formattedBaselaneAcc,
    toDropdownList,
    bankAccountsById: accountsMap,
    refetch,
  };
};

export default useBankAccountsActive;
