import { useQuery } from '@apollo/client';
import { CASHFLOW_QUERY } from '@core/apollo/queries';
import { cashFlowDummyData, cashFlowSummaryDummyData } from '@shared/helpers/dummyData.helpers';

const useCashFlow = ({ input }) => {
  const {
    loading: cashFlowLoading,
    error: cashFlowError,
    data: cashFlowData,
    refetch: cashFlowRefetch,
  } = useQuery(CASHFLOW_QUERY, {
    variables: { input },
    onError: (error) => console.error(error),
  });

  return {
    cashFlowLoading,
    cashFlowError,
    cashFlowData,
    cashFlowDummyData,
    cashFlowSummaryDummyData,
    cashFlowRefetch,
  };
};

export default useCashFlow;
