// @flow
import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { BaselaneAlertNew } from '@shared/components';
import { ReactComponent as Celebrate } from '@icons/32px/Celebrate.svg';

type setChangeSettingsProps = {
  handleChangeSettingsClick: Function,
};

function ChangeSettingsAlert({ handleChangeSettingsClick }: setChangeSettingsProps): any {
  return (
    <BaselaneAlertNew
      variant="success"
      title="Free tenant ACH payments for all rent collection"
      body={
        <Stack>
          <Text>
            All fees for tenant ACH payments are being waived, as all rent is currently collected
            into Baselane Banking accounts.
          </Text>
          <Text>You can still change settings for future payments into an external account.</Text>
        </Stack>
      }
      visual="illustration"
      iconName={Celebrate}
      isVertical
      actionProps={{
        secondary: {
          label: 'Change settings anyway',
          onClick: handleChangeSettingsClick,
        },
      }}
    />
  );
}

export default ChangeSettingsAlert;
