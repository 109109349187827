import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import { FormDescription, FormTitle } from '@shared/components/BaselaneFlow';
import { BaselaneResponsiveCard } from '@shared/components/BaselaneResponsiveCard';
import { BaselaneButton } from '@shared/components';
import { Icon16PlusCircle } from '@icons/16px';
import getBreakPoints from '@core/utils/getBreakPoints';
import AddProperty from '@core/components/OnboardingTriage/AddProperty';
import {
  addressTextStyles,
  unitCardStyles,
} from '@shared/components/BaselaneResponsiveCard/styles/card.style';
import {
  addAnotherLinkStyles,
  panelDescriptionStyles,
  panelFormStyles,
  panelHeaderContainerStyles,
  panelHeaderStyles,
  tabPanelStyles,
} from '@core/components/OnboardingTriage/styles/unifiedFlow.styles';

import FooterWrapper from '../FooterWrapper';

const AddPropertyView = () => {
  const { isMax768: isMobile } = getBreakPoints();
  const {
    addingPropertyPanel,
    properties,
    footerVars,
    setAddingPropertyPanel,
    handleNavigation,
    handleAddedProperty,
  } = useOutletContext();

  return (
    <Box {...tabPanelStyles} pb="100px">
      {!addingPropertyPanel && (
        <>
          <FormTitle
            title="Add your properties"
            textStyles={panelHeaderStyles(isMobile)}
            containerStyles={panelHeaderContainerStyles(isMobile)}
          />
          <FormDescription styles={panelDescriptionStyles(isMobile)}>
            Whether you have one property or many, add them now
          </FormDescription>
          <Box {...panelFormStyles}>
            {properties?.map(
              (property) =>
                property && (
                  <BaselaneResponsiveCard
                    key={`property-card-${property.id}`}
                    {...unitCardStyles(true)}
                  >
                    <Text {...addressTextStyles}>
                      {`${property.address?.address}, ${property.address?.city}, ${property.address?.state} ${property.address?.zipCode}`}
                    </Text>
                  </BaselaneResponsiveCard>
                )
            )}
            <Box {...addAnotherLinkStyles}>
              <BaselaneButton
                id="bookkeeping-add-property-button"
                variant="transparent"
                palette="primary"
                leftIcon={<Icon16PlusCircle />}
                size="lg"
                onClick={() => setAddingPropertyPanel(true)}
                styles={addAnotherLinkStyles.button}
              >
                {properties?.length > 0 ? 'Add another property' : 'Add a property'}
              </BaselaneButton>
            </Box>
          </Box>
          <FooterWrapper {...footerVars} />
        </>
      )}
      {addingPropertyPanel && (
        <Box {...panelFormStyles}>
          <AddProperty
            isInUnifiedBAFlow
            handleFooterNavigation={handleNavigation}
            handleAddedProperty={handleAddedProperty}
            title={
              properties?.length > 0
                ? 'Enter your rental property address'
                : 'Add your first property'
            }
            description="Once an address is entered, select if the property is a single or multi-unit. If a multi-unit property, enter unit numbers or names."
          />
        </Box>
      )}
    </Box>
  );
};

export default AddPropertyView;
