import React from 'react';
import { ReactComponent as InsightsIconDarkDefault } from '@icons/32px/Insights Dark Default.svg';
import { ReactComponent as InsightsIconDarkSuccess } from '@icons/32px/Insights Dark Success.svg';
import { ReactComponent as InsightsIconDarkWarning } from '@icons/32px/Insights Dark Warning.svg';
import { ReactComponent as InsightsIconDarkDanger } from '@icons/32px/Insights Dark Danger.svg';
import ActionTypeLink from '../ActionTypes/ActionTypeLink';
import ActionTypeButtonGroup from '../ActionTypes/ActionTypeButtonGroup';

const bannerIconMap = {
  neutral: InsightsIconDarkDefault,
  primary: InsightsIconDarkDefault,
  success: InsightsIconDarkSuccess,
  warning: InsightsIconDarkWarning,
  danger: InsightsIconDarkDanger,
};

export const getDynamicIcon = (variant) => {
  return bannerIconMap[variant] ?? InsightsIconDarkDefault;
};

export const getActionTypeButton = ({ actionType, actionProps, variant, isVertical }) => {
  switch (actionType) {
    case 'link':
      return <ActionTypeLink {...{ isVertical, variant, ...actionProps }} />;

    case 'button':
      return <ActionTypeButtonGroup {...{ isVertical, ...actionProps }} />;

    default:
      return null;
  }
};
