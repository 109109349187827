export type MenuState = {
  label: string,
  id: number,
  isValid: boolean,
  name: string,
};

// This determines the order
// Instead of tabIndex = 3 we say tabIndex = LEASE_TABS.ACCOUNTS
// if we need to go to ACCOUNTS or are referring to ACCOUNTS
// However 0 can be 0 if it means the first tab
// or 1 can be 1 if it just means the second tab
// So make sure these match up with calculateTabIndex helper,
// initialMenuState, formRefs and unifiedLeaseSegmentEventNames options
// N.B. At the moment PROPERTY should always be the first entry
// or calls and init lease won't work correctly
export const LEASE_TABS = {
  PROPERTY: 0,
  TERMS: 1,
  DEPOSITS: 2,
  INVOICES: 3,
  ACCOUNTS: 4,
  TENANT: 5,
  REVIEW: 6,
};

// Make sure these match up with the LEASE_TABS in constants above,
// with calculateTabIndex, unifiedLeaseSegmentEventNames, and with
// formRefs in UnifiedLeaseFlow.
export const initialMenuState = (completedTags, isPropertyPanelValid) => [
  {
    label: 'Select rental property',
    id: 0,
    isValid: isPropertyPanelValid,
    name: 'PROPERTY',
  },
  {
    label: 'Add rent details',
    id: 1,
    isValid:
      (completedTags?.includes('LEASE_TERM') && completedTags?.includes('DEPOSITS_FEES')) || false,
    name: 'LEASE_TERM',
  },
  {
    label: 'Add fees & deposits',
    id: 2,
    isValid: completedTags?.includes('DEPOSITS_FEES') || false,
    name: 'DEPOSITS_FEES',
  },
  {
    label: 'Review payment invoices',
    id: 3,
    isValid: completedTags?.includes('INVOICES') || false,
    name: 'INVOICES',
  },
  {
    label: 'Select bank account(s)',
    id: 4,
    isValid: completedTags?.includes('BANK_ACCOUNT') || false,
    name: 'BANK_ACCOUNT',
  },
  {
    label: 'Add tenant details',
    id: 5,
    isValid: completedTags?.includes('TENANT') || false,
    name: 'TENANT',
  },
  {
    label: 'Review & invite tenant',
    id: 6,
    isValid: false,
    name: 'REVIEW_LEASE',
  },
];
