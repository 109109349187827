import React, { useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useGate } from 'statsig-react';
import { Box, Flex, Spinner, useDisclosure } from '@chakra-ui/react';

import * as ROUTES from '@routes';
import getBreakPoints from '@core/utils/getBreakPoints';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';
import BaselaneIconLogo from '@core/assets/BaselaneIconLogo';
import onNavMenuClickEvents from '@core/components/NavigationAuth/helpers/navEvent.helper';

import NavList from './NavList';
import {
  logoContainerStyles,
  logoInnerContainerStyles,
  navBarContainerStyles,
  navBarSettings,
  logoFlexStyles,
} from './styles/nav.styles';

function NavigationAuth() {
  const navElement = useRef(null);
  const { isOpen = false, onOpen, onClose } = useDisclosure();
  const { isLargerThan1537 } = getBreakPoints();
  const { value: showTransfersPage, isLoading } = useGate('transfers_payments_page');

  let timer;

  const openMenu = () => {
    timer = setTimeout(() => onOpen(), 100);
  };

  const closeMenu = () => {
    clearTimeout(timer);
    onClose();
  };

  useEffect(() => {
    const { current } = navElement;
    if (current !== null && !isLargerThan1537) {
      current.addEventListener('mouseenter', openMenu);
      current.addEventListener('mouseleave', closeMenu);
      return () => {
        current.removeEventListener('mouseenter', openMenu);
        current.removeEventListener('mouseleave', closeMenu);
      };
    }
    return () => {};
  }, [isLargerThan1537]);

  return (
    <Box {...navBarSettings(isOpen)} ref={navElement}>
      <Flex {...logoFlexStyles(isOpen)}>
        {/* Logo */}
        <RouterLink to={ROUTES.HOME}>
          <Flex {...logoContainerStyles}>
            <Flex {...logoInnerContainerStyles}>
              {isOpen || isLargerThan1537 ? <BaselaneFullLogo /> : <BaselaneIconLogo />}
            </Flex>
          </Flex>
        </RouterLink>
      </Flex>
      <Flex {...navBarContainerStyles(isOpen)}>
        {isLoading ? (
          <Spinner />
        ) : (
          <NavList
            isOpen={isOpen}
            showTransfersPage={showTransfersPage}
            onClick={(label) => {
              onNavMenuClickEvents(label);
            }}
          />
        )}
      </Flex>
    </Box>
  );
}

export default NavigationAuth;
