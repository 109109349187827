import React from 'react';

const RecurringIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: '4px' }}
    >
      <g id="12px/Reccuring">
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.72265 4.58403C7.49289 4.7372 7.4308 5.04764 7.58397 5.2774C7.73715 5.50717 8.04759 5.56925 8.27735 5.41608L11.2774 3.41608C11.4165 3.32335 11.5 3.16723 11.5 3.00005C11.5 2.83288 11.4165 2.67676 11.2774 2.58403L8.27735 0.584028C8.04759 0.430852 7.73715 0.492939 7.58398 0.722703C7.4308 0.952468 7.49289 1.2629 7.72265 1.41608L9.3486 2.50004L1 2.50005C0.723858 2.50005 0.5 2.72391 0.5 3.00005L0.500002 5.00005C0.5 5.27619 0.72386 5.50005 1 5.50005C1.27614 5.50005 1.5 5.27619 1.5 5.00005L1.5 3.50005L9.34863 3.50004L7.72265 4.58403ZM4.27735 7.41608C4.50712 7.2629 4.5692 6.95247 4.41603 6.7227C4.26285 6.49294 3.95242 6.43085 3.72265 6.58403L0.72265 8.58403C0.583551 8.67676 0.5 8.83288 0.5 9.00005C0.5 9.16723 0.58355 9.32335 0.72265 9.41608L3.72265 11.4161C3.95241 11.5693 4.26285 11.5072 4.41603 11.2774C4.5692 11.0476 4.50711 10.7372 4.27735 10.584L2.6514 9.50006L11 9.50006C11.2761 9.50006 11.5 9.2762 11.5 9.00006V7.00006C11.5 6.72391 11.2761 6.50006 11 6.50006C10.7239 6.50006 10.5 6.72391 10.5 7.00006V8.50006L2.65137 8.50006L4.27735 7.41608Z"
          fill="#6C7884"
        />
      </g>
    </svg>
  );
};
export default RecurringIcon;
