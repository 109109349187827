// @flow
import React from 'react';
import { Text } from '@chakra-ui/react';

import { IconApartment, IconChevronRight } from '@icons';
import { rentStatusHelper } from '@core/components/Properties/helpers/propertyList.helpers';
import { toDateRangeFormat } from '@shared/components/BaselaneResponsiveCard/helpers/format.helper';

import { addressTextStyles } from './styles/card.style';
import BaselaneResponsiveCard from './BaselaneResponsiveCard';
import BaselaneLeaseBadge from '../BaselaneLeaseBadge';

const Address = ({ currentProperty }: any) => (
  <Text {...addressTextStyles}>
    {currentProperty.address?.address}, {currentProperty.address?.city},{' '}
    {currentProperty.address?.state} {currentProperty.address?.zipCode}
  </Text>
);

const Description = ({ description }: any) => <Text {...addressTextStyles}>{description}</Text>;
const Status = ({ status }: any) => (
  <BaselaneLeaseBadge
    isOverdue={status.toLowerCase().includes('overdue')}
    text={status}
    maxWidth="100% !important"
  />
);
const LeaseDate = ({ lease }: any) => <Text {...addressTextStyles}>{lease}</Text>;

type Props = {
  property: Object,
  isUnit?: Boolean,
  onUnitClick?: any,
  parentProperty?: Object,
};
function BaselaneMobileRentCollectionCard({
  property,
  isUnit,
  onUnitClick,
  parentProperty,
  ...rest
}: Props) {
  const alphabeticallyByName = (unitA, unitB) => unitA.name.localeCompare(unitB.name);

  const units = property.units ? property.units.slice().sort(alphabeticallyByName) : [];
  const hasUnits = undefined !== property.isMultiUnit ? property.isMultiUnit : units.length > 1;
  const currentProperty = !hasUnits && !isUnit ? units[0] : property;

  const lease =
    currentProperty.lease && currentProperty.lease.startDate && currentProperty.lease.endDate
      ? `${toDateRangeFormat(new Date(currentProperty.lease.startDate))} 
          - ${toDateRangeFormat(new Date(currentProperty.lease.endDate))}`
      : null;

  const status = rentStatusHelper(currentProperty);
  const heading = currentProperty.name;
  const icon = isUnit && IconApartment;
  const action =
    !hasUnits || isUnit
      ? {
          label: 'Details',
          showLabel: false,
          icon: <IconChevronRight />,
          onClick: () => {
            onUnitClick(
              currentProperty,
              // if displaying unit, make sure to pass on parent property data for later use
              property === currentProperty ? parentProperty : property
            );
          },
        }
      : null;

  return !hasUnits || isUnit ? (
    <BaselaneResponsiveCard
      {...{ heading, icon, action, ...rest }}
      pr={isUnit ? '0 !important' : ''}
    >
      {currentProperty.description && <Description description={currentProperty.description} />}
      {currentProperty.address && <Address currentProperty={currentProperty} />}
      {lease && <LeaseDate lease={lease} />}
      {status && <Status status={status} />}
    </BaselaneResponsiveCard>
  ) : (
    <BaselaneResponsiveCard {...{ heading, icon, action, ...rest }}>
      {currentProperty.description ? (
        <Description description={currentProperty.description} />
      ) : (
        <Address currentProperty={currentProperty} />
      )}
      {units.map((unit) => (
        <BaselaneMobileRentCollectionCard
          key={`unit-${unit.id}`}
          {...{ property: unit, isUnit: true, onUnitClick, parentProperty: currentProperty }}
          {...{ paddingTop: '8px', paddingBottom: '8px' }}
        />
      ))}
    </BaselaneResponsiveCard>
  );
}
BaselaneMobileRentCollectionCard.defaultProps = {
  isUnit: false,
  onUnitClick: null,
  parentProperty: null,
};

export default BaselaneMobileRentCollectionCard;
