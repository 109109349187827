import React from 'react';
import { useGate } from 'statsig-react';
import TransferFundsDrawer from './TransferFundsDrawer';
import TransferFunds from '../TransferFunds';

/**
 * Component that renders the TransferFundsDrawer if the "recurring_scheduled_transfers_gate" is enabled, otherwise renders the TransferFunds component.
 * @returns {JSX.Element}
 */
const TransferFundsFeatureGuard = () => {
  const { value: isSchedulingTransfersEnabled } = useGate('recurring_scheduled_transfers_gate');

  return isSchedulingTransfersEnabled ? <TransferFundsDrawer /> : <TransferFunds />;
};

export default TransferFundsFeatureGuard;
