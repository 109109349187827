import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, Text } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { UPDATE_MULITPLE_BANK_ACCOUNTS } from '@core/components/NativeBankingPage/queries';
import { UNIFIED_BA_ALL_SET } from '@routes';
import BanksContext from '@contexts/BanksContext';
import TransactionContext from '@contexts/TransactionContext';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';
import SlLoader from '@core/components/Loader';
import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import CenterContentWrapper from '@shared/layouts/CenterContentWrapper';
import userContext from '@contexts/UserContext';

import HeaderContent from '../HeaderContent';
import BanksListView from './BanksListView';
import FooterButtons from './FooterButtons';
import {
  formatDataToSubmit,
  getPropertyAndUnitId,
  updateBankList,
} from './helpers/autotagging.helpers';
import {
  titleTextStyles,
  subTitleTextStyles,
  centerContentWrapperStyles,
} from './styles/autotagging.styles';

const AutotaggingSetup = () => {
  const navigate = useNavigate();
  const { externalAccounts, loading } = useContext(BanksContext);
  const { propertiesData } = useContext(TransactionContext);
  const { user } = useContext(userContext);

  const [accountsAutoTaggedList, setAccountsAutoTaggedList] = useState([]);
  const [hasUserAutotagged, setHasUserAutotagged] = useState(false);

  const [updateMultipleBankAccount, { loading: updateMultiBankAccountLoading }] = useMutation(
    UPDATE_MULITPLE_BANK_ACCOUNTS
  );

  const handleOnDoneClick = () => {
    const formattedDataToSubmit = formatDataToSubmit(accountsAutoTaggedList);

    updateMultipleBankAccount({
      variables: {
        input: formattedDataToSubmit,
      },
    })
      .then(() => {
        navigate(UNIFIED_BA_ALL_SET);
      })
      .catch(() => {
        console.log('show error toast');
      });
  };

  useEffect(() => {
    if (accountsAutoTaggedList.length === 0 && externalAccounts?.length > 0) {
      updateBankList(setAccountsAutoTaggedList, externalAccounts);
    } else if (accountsAutoTaggedList) {
      const userAutoTagged = accountsAutoTaggedList.some((item) => item.tagged);
      const userAutoTaggedDataChanged = accountsAutoTaggedList.some((item) => {
        const propertyAndUnitId = getPropertyAndUnitId(item?.autoTag);
        return item.tagged !== propertyAndUnitId;
      });
      setHasUserAutotagged(userAutoTagged || userAutoTaggedDataChanged);
    }
  }, [accountsAutoTaggedList, externalAccounts]);

  const propertyOptions = getPropertyData(propertiesData);

  if (loading) return <SlLoader className="" />;

  return (
    <HeaderNavWrapper
      hideBackButton
      headerContent={<HeaderContent onboardingCompleted={user?.onboardingCompleted} />}
    >
      <CenterContentWrapper {...centerContentWrapperStyles}>
        <Box height="100%" mb={isMobile ? '80px' : '0'}>
          <Stack mb="32px">
            <Text {...titleTextStyles}>Set up auto-tagging (Optional)</Text>
            <Text {...subTitleTextStyles}>
              Select the property you wish to auto-tag to your bank transactions
            </Text>
          </Stack>

          <BanksListView
            {...{ accountsAutoTaggedList, setAccountsAutoTaggedList, propertyOptions }}
          />
        </Box>
        <FooterButtons
          {...{ hasUserAutotagged, handleOnDoneClick, updateMultiBankAccountLoading }}
        />
      </CenterContentWrapper>
    </HeaderNavWrapper>
  );
};

export default AutotaggingSetup;
