import React from 'react';

function IconSmallGoogleLogo() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g clipPath="url(#clip0)">
        <path
          d="M23.9996 12.2756c0-.8157-.0675-1.6358-.2115-2.43832H12.2402v4.62102h6.613c-.2744 1.4903-1.1562 2.8087-2.4473 3.6465v2.9983h3.9453c2.3168-2.09 3.6484-5.1765 3.6484-8.8275z"
          fill="#4285F4"
        />
        <path
          d="M12.2404 24c3.302 0 6.0866-1.0627 8.1154-2.897l-3.9452-2.9983c-1.0977.7319-2.5147 1.1464-4.1657 1.1464-3.19402 0-5.90217-2.1121-6.87387-4.9517H1.2998v3.091C3.37815 21.4426 7.61133 24 12.2404 24z"
          fill="#34A853"
        />
        <path
          d="M5.36712 14.2995c-.51284-1.4904-.51284-3.1042 0-4.59454V6.61401H1.30039c-1.736457 3.39079-1.736457 7.38559 0 10.77639l4.06673-3.0909z"
          fill="#FBBC04"
        />
        <path
          d="M12.2404 4.74881c1.7454-.02646 3.4324.6173 4.6965 1.799l3.4954-3.42605C18.219 1.08465 15.2814-.0353205 12.2404-.00004583c-4.62907 0-8.86225 2.55741583-10.9406 6.61399583L5.36653 9.7049c.9672-2.84402 3.67985-4.95609 6.87387-4.95609z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconSmallGoogleLogo;
