import React, { useContext } from 'react';
import {
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveSortableTableHeading,
} from '@core/components/Shared/components';
import BaselaneResponsiveTableContext from '@shared/components/BaselaneResponsiveTable/BaselaneResponsiveTableContext';
import { tableHeaderItems } from '../helpers';

type TableHeaderProps = {
  searchParams: Object,
  setSearchParams: Function,
};

export function TableHeader({ searchParams, setSearchParams }: TableHeaderProps) {
  const { columns: headerItems } = useContext(BaselaneResponsiveTableContext);

  return (
    <BaselaneResponsiveTableHeader
      items={tableHeaderItems}
      renderItem={(item, index) => {
        return !headerItems?.[index]?.isSortable ? (
          <BaselaneResponsiveTableHeading key={item.key} index={index} title={item.label} />
        ) : (
          <BaselaneResponsiveSortableTableHeading
            key={item.key}
            index={index}
            title={item.label}
            field={item.field}
            filters={{
              sort: {
                field: searchParams.get('sort'),
                direction: searchParams.get('direction'),
              },
            }}
            onSort={(sort) => {
              setSearchParams({ sort: sort.field, direction: sort.direction }, { replace: true });
            }}
          />
        );
      }}
    />
  );
}
