import React from 'react';

type IconArrowRightAlternateProps = {
  w?: string,
  h?: string,
  color?: string,
};

function IconArrowRightAlternate({
  w = 24,
  h = 24,
  color = 'currentColor',
}: IconArrowRightAlternateProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox={`0 0 ${w} ${h}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2408 5.35118C12.6002 4.93185 13.2315 4.88329 13.6508 5.24271L20.6508 11.2427L21.5366 12.002L20.6508 12.7612L13.6508 18.7612C13.2315 19.1207 12.6002 19.0721 12.2408 18.6528C11.8813 18.2334 11.9299 17.6021 12.3492 17.2427L18.4634 12.002L12.3492 6.76123C11.9299 6.40181 11.8813 5.77051 12.2408 5.35118Z"
        fill={color}
      />
      <rect x="4" y="11" width="15" height="2" rx="1" fill={color} />
    </svg>
  );
}

IconArrowRightAlternate.defaultProps = {
  w: '24',
  h: '24',
  color: 'currentColor',
};

export default IconArrowRightAlternate;
