import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { BaselaneGrid } from '@shared/components';
import {
  columnTitleContainerStyles,
  columnTitleElementStyles,
  columnTitleGridItemStyles,
  columnTitleGridStyles,
} from '../../styles/columnTitle.style';

const ColumnTitleElements = () => {
  const columnTitleItems = [
    'Contact Name',
    'Contact Email',
    'Contact Number',
    'Tenant Platform Account Status',
    '',
  ];

  const columnTitleElements = columnTitleItems.map((title) => {
    return {
      element: <Text {...columnTitleElementStyles}>{title}</Text>,
    };
  });

  return (
    <Box {...columnTitleContainerStyles}>
      <BaselaneGrid
        gridItems={columnTitleElements}
        templateColumns="repeat(3, minmax(0, 1fr)) 230px"
        gap={0}
        gridStyles={{ ...columnTitleGridStyles }}
        gridItemStyles={{ ...columnTitleGridItemStyles }}
      />
    </Box>
  );
};

export default ColumnTitleElements;
