import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Stack,
  FormLabel,
  Input,
  Box,
  Text,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  passwordValidator,
  initialChecklist,
} from '@features/Authentication/helpers/validation.helpers';
import Checker from '@features/Authentication/components/Checker';
import { BaselaneHeaderLogoWrapper } from '@shared/layouts';
import {
  BaselaneButton,
  BaselaneMessageCard,
  BaselaneButtonIcon,
  BaselaneLink,
} from '@shared/components';
import { Icon16Hide, Icon16Unhide } from '@icons/16px';
import * as ROUTES from '@routes';

import { getQueryParams, resetPassword } from '@core/Services/Firebase/firebase';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { messages } from './helpers/errorMessages';

import {
  labelStyles,
  headingStyles,
  errorStyles,
  passwordTextStyles,
  resetButtonStyles,
} from '../LoginPage/styles/font.style';
import { inputContainerStyles, inputStyles } from '../SignUpPage/styles/createaccount.style';

const initialResetPasswordValues = Object.freeze({
  newPassword: '',
  confirmNewPassword: '',
});

function ResetPassword() {
  const location = useLocation();
  const [actionCode, setActionCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [continueUrl, setContinueUrl] = useState('https://www.baselane.com/login');

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [checklist, setChecklist] = useState(initialChecklist);
  const [isStrongPassword, setStrongPassword] = useState(false);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);
  const weakPassword = () => Object.keys(checklist).find((item) => !checklist[item].isValid);

  useEffect(() => {
    if (!weakPassword()) {
      setStrongPassword(true);
    }
  }, [checklist]);

  useEffect(() => {
    const oobCode = getQueryParams(location, 'oobCode');
    const continueURL = getQueryParams(location, 'continueUrl');

    setActionCode(oobCode);
    if (continueURL !== null) {
      setContinueUrl(continueURL);
    }
  }, []);

  const handleValidation = (values) => {
    const { newPassword, confirmNewPassword } = values;
    const errors = {};

    if (weakPassword()) {
      setStrongPassword(false);
      errors.newPassword = 'invalid password';
    }

    if (newPassword === '') {
      errors.newPassword = 'Enter new password';
    }

    if (confirmNewPassword === '') {
      errors.confirmNewPassword = 'Confirm new password';
    }

    if (confirmNewPassword !== newPassword) {
      errors.confirmNewPassword = 'Please make sure your passwords match';
    }

    return errors;
  };

  const handleFormSubmit = (e) => {
    const { newPassword } = e;
    resetPassword(actionCode, newPassword)
      .then(() => {
        setIsAlertOpen(true);
      })
      .catch((err) => {
        const code = err.code?.split('/')[1].split('-').join('');
        setErrorCode(code);
      });
  };

  const headStyles = { ...headingStyles };

  const strongPassword = <Text>Strong Password!</Text>;

  const errorMessage = (
    <Box>
      <Text>
        {messages.generic} Try resending{' '}
        <BaselaneLink to={ROUTES.FORGOT_PASSWORD} variant="primary">
          reset password instructions.
        </BaselaneLink>
      </Text>
    </Box>
  );

  return (
    <BaselaneHeaderLogoWrapper>
      <Box maxWidth="384px">
        <Text {...headStyles} textAlign="center">
          Reset Your Password
        </Text>

        <Formik
          validate={(values) => handleValidation(values)}
          initialValues={initialResetPasswordValues}
          onSubmit={handleFormSubmit}
          validateOnChange
          validateOnMount
        >
          {({
            values,
            handleSubmit,
            isValid,
            handleChange,
            errors,
            touched,
            setFieldTouched,
            handleBlur,
          }) => (
            <form style={{ w: '100%', mt: '0' }}>
              <Stack alignItems="center" spacing={2.5}>
                <Box {...inputContainerStyles}>
                  <Text style={passwordTextStyles}>
                    Your new password must be different from previous passwords
                  </Text>
                  <FormLabel htmlFor="newPassword" {...labelStyles}>
                    Enter New Password
                  </FormLabel>
                  <InputGroup>
                    <Input
                      {...inputStyles}
                      id="newPassword"
                      name="newPassword"
                      value={values.password}
                      type={showNewPassword ? 'text' : 'password'}
                      onChange={(e) => {
                        const p = e.target.value;
                        const updatedChecklist = passwordValidator(p, checklist);
                        setChecklist(updatedChecklist);
                        handleChange(e);
                      }}
                      placeholder="New password"
                      onBlur={(e) => {
                        setFieldTouched('newPassword', true);
                        handleBlur(e);
                      }}
                    />
                    <InputRightElement height="100%">
                      <BaselaneButtonIcon
                        variant="transparent"
                        palette="neutral"
                        size="sm"
                        icon={showNewPassword ? <Icon16Hide /> : <Icon16Unhide />}
                        isActive={showNewPassword}
                        onClick={handleNewPasswordVisibility}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.newPassword && touched.newPassword ? (
                    <Text {...errorStyles}>{errors.newPassword}</Text>
                  ) : null}
                </Box>

                {isStrongPassword ? (
                  <BaselaneMessageCard
                    iconName="checkcircleoutline"
                    iconColor="green"
                    borderColor="green"
                    text={strongPassword}
                    iconContainerStyles={{ w: '18px' }}
                    containerStyles={{ w: '100%' }}
                  />
                ) : (
                  <Checker checklist={checklist} />
                )}

                <Box {...inputContainerStyles}>
                  <FormLabel htmlFor="confirmNewPassword" {...labelStyles}>
                    Confirm New Password
                  </FormLabel>
                  <InputGroup>
                    <Input
                      {...inputStyles}
                      id="confirmNewPassword"
                      name="confirmNewPassword"
                      value={values.password}
                      type={showConfirmPassword ? 'text' : 'password'}
                      onChange={handleChange}
                      placeholder="New password"
                      onBlur={(e) => {
                        setFieldTouched('confirmNewPassword', true);
                        handleBlur(e);
                      }}
                    />
                    <InputRightElement height="100%">
                      <BaselaneButtonIcon
                        variant="transparent"
                        palette="neutral"
                        size="sm"
                        icon={showNewPassword ? <Icon16Hide /> : <Icon16Unhide />}
                        isActive={showConfirmPassword}
                        onClick={handleConfirmPasswordVisibility}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.confirmNewPassword && touched.confirmNewPassword ? (
                    <Text {...errorStyles}>{errors.confirmNewPassword}</Text>
                  ) : null}
                </Box>

                <BaselaneButton
                  variant="filled"
                  palette="primary"
                  onClick={handleSubmit}
                  isDisabled={!isValid}
                  styles={resetButtonStyles}
                >
                  Confirm
                </BaselaneButton>
                {errorCode !== '' && (
                  <Box {...inputContainerStyles}>
                    <BaselaneMessageCard
                      iconName="info"
                      iconColor="blue"
                      borderColor="blue"
                      text={errorMessage}
                    />
                  </Box>
                )}
              </Stack>
            </form>
          )}
        </Formik>
        <ResetPasswordSuccess
          continueUrl={continueUrl}
          isAlertOpen={isAlertOpen}
          setIsAlertOpen={setIsAlertOpen}
        />
      </Box>
    </BaselaneHeaderLogoWrapper>
  );
}

export default ResetPassword;
