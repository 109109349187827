import React, { useContext } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import userContext from '@contexts/UserContext';
import { IllustrationBankingAddExternalAccountManually2 } from '@illustrations';
import BaselaneButtonCard from '../BaselaneButtonCard';

import {
  cardBodyStyles,
  cardTextContainerStyles,
  cardTitleStyles,
  iconContainerStyles,
  typeCardStyles,
} from '../../styles/typePopupButtons.styles';

type AddManualAccountButtonProps = {
  handleActionButton: Function,
  buttonStyles?: Object,
};

const AddManualAccountButton = ({
  handleActionButton,
  buttonStyles,
}: AddManualAccountButtonProps) => {
  const { user } = useContext(userContext);
  const { pathname } = window.location;
  const url = pathname;

  return (
    <BaselaneButtonCard
      id="add-manual-account-button"
      type="clean"
      onClick={() => {
        sendSegmentEvent(
          `${user?.onboardingCompleted ? 'baselane' : 'onboarding'}_click_connect_bank_account`,
          {
            type: 'Manual',
            url,
          }
        );

        handleActionButton('manual');
      }}
      buttonStyles={{ ...typeCardStyles, ...buttonStyles }}
    >
      <Flex {...iconContainerStyles}>
        <IllustrationBankingAddExternalAccountManually2 />
      </Flex>
      <Box {...cardTextContainerStyles}>
        <Text {...cardTitleStyles}>Add manually</Text>
        <Text {...cardBodyStyles}>
          Enter the account & routing number to add the checking account manually
        </Text>
      </Box>
    </BaselaneButtonCard>
  );
};

AddManualAccountButton.defaultProps = {
  buttonStyles: {},
};

export default AddManualAccountButton;
