import { keyframes } from '@chakra-ui/react';

// keyframes
const slideInAnimation = keyframes`
  from {
    top: 100%;
  }
  to {
    top: 168px
  }
`;

const slideOutAnimation = keyframes`
  from {
    top: 168px
  }
  to {
    top: 100%;
  }
`;

const slideInAnimationMobile = keyframes`
  from {
    bottom: -100%;
  }
  to {
    bottom: 0px;
  }
`;

const slideOutAnimationMobile = keyframes`
  from {
    bottom: 0px;
  }
  to {
    bottom: -100%;
  }
`;

const desktopStyles = {
  top: '168px',
};

const mobileStyles = {
  bottom: '0px',
};

export const animationStyles = {
  slideInAnimation,
  slideOutAnimation,
  slideInAnimationMobile,
  slideOutAnimationMobile,
  desktopStyles,
  mobileStyles,
};
