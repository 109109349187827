import { gql } from '@apollo/client';

export const KPI_QUERY = gql`
  query {
    kpi {
      summary {
        capRate
        cashOnCashReturn
        currentMarketValue
        historicalPurchaseValue
        totalAppreciation
        netOperatingIncome
        returnOnEquity
      }
    }
  }
`;

export const CALCULATE_KPI = gql`
  mutation CalculateKpi {
    calculateKpi
  }
`;
