// Chakra Example: https://github.com/chakra-ui/chakra-ui/blob/v1/packages/theme/src/foundations/typography.ts
// Figma: https://www.figma.com/file/IzR58By56T8Dvo2tLLPCbP/*Baselane---Design-System-(Habitat)?node-id=444%3A5560&mode=dev
const typography = {
  fontSizes: {
    '3xs': '10px',
    '2xs': '11px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '48px',
    '6xl': '54px',
    '7xl': '0',
    '8xl': '0',
    '9xl': '0',
  },

  // These font weights are based on variable font Ease Variable
  // can be checked on this site ( https://www.axis-praxis.org/specimens/__DEFAULT__ ) and importing the .ttf file for Ease Variable
  // you can check the different font weight by going through the font instances this font has
  fontWeights: {
    normal: 100, // regular
    medium: 350,
    semibold: 500,
    bold: 695,
    black: 900,
  },

  fonts: {
    heading: '"Ease Variable", Helvetica, Arial',
    body: '"Ease Variable", Helvetica, Arial',
    mono: '"Ease Variable", Helvetica, Arial',
  },
};

export default typography;
