/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Text, useRadioGroup, Box, HStack, useMediaQuery } from '@chakra-ui/react';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { USER_TYPES } from '../../helper/userType.helpers';

const SignUpRadioCard = (props) => {
  return (
    <Box
      as="label"
      onClick={props.onClick}
      cursor="pointer"
      color={props.isChecked ? 'brand.darkBlue.800A' : 'brand.darkBlue.300'}
      borderBottom={props.isChecked ? '2px solid #012550' : 'none'}
      display="flex"
      alignItems="center"
      pt={0}
      pb={2}
      pr={0}
      position="relative"
      top="1px"
    >
      {props.children}
    </Box>
  );
};

type UserTypeProps = {
  userType: String,
  setUserType: Function,
};

function UserType({ userType, setUserType }: UserTypeProps) {
  const [isMin899] = useMediaQuery('(min-width: 899px)', { ssr: false });
  const { getRootProps } = useRadioGroup();
  const group = getRootProps();

  const handleUserTypeClick = (userTypeSelected) => {
    setUserType(userTypeSelected);
    if (userTypeSelected === 'renter') {
      sendSegmentEvent('signup_click_im_a_renter');
    }
  };

  return (
    <HStack
      {...group}
      spacing="40px"
      style={{
        marginBottom: isMin899 ? '16px' : '0',
        width: isMin899 ? 'calc(100% + 64px)' : 'calc(100% + 32px)',
        borderBottom: '1px solid #DADFE6',
        paddingLeft: isMin899 ? '32px' : '16px',
      }}
    >
      {USER_TYPES.map((option) => {
        const { value, label } = option;

        return (
          <SignUpRadioCard
            key={value}
            name="type"
            isChecked={value === userType}
            value={value}
            onClick={() => handleUserTypeClick(value)}
          >
            <Text textStyle="headline-sm">{label}</Text>
          </SignUpRadioCard>
        );
      })}
    </HStack>
  );
}

export default UserType;
