import isNotNil from '@core/utils/validators/isNotNil';
import isEmpty from '@core/utils/validators/isEmpty';

function getPresentationId(isUnit, id) {
  const prefix = isUnit ? 'u' : 'p';
  return id ? `${prefix}-${id}` : null;
}

function createPropertyIdsMap(properties) {
  const map = {};

  properties.forEach((property) => {
    map[property.id.toString()] = property;
    property.units.forEach((unit) => {
      map[unit.id.toString()] = unit;
    });
  });

  return map;
}

function getSeperatePropertyAndUnitId(value, propertyIdsMap) {
  let propertyId = null;
  let unitId = null;

  if (isEmpty(value)) {
    return { propertyId, unitId };
  }

  const valueParts = value.split('-');

  const [prefix, id] = valueParts;

  const isUnit = prefix === 'u';

  if (isUnit) {
    unitId = id;
    propertyId = propertyIdsMap[value].propertyId;
  } else {
    propertyId = id;
  }

  return { propertyId, unitId };
}

function buildPresentationProperties(properties) {
  const presentationProperties = [];

  properties.forEach((property) => {
    const presentationProperty = {
      id: getPresentationId(false, property.id),
      name: property.name,
      units: [],
    };

    property.units.forEach((unit) => {
      const presentationUnit = {
        id: getPresentationId(true, unit.id),
        name: unit.name,
        propertyId: property.id,
      };

      presentationProperty.units.push(presentationUnit);
    });

    presentationProperties.push(presentationProperty);
  });

  return presentationProperties;
}

function getPropertyName(propertyId, propertyIdsMap) {
  const selectedProperty = propertyIdsMap[propertyId];
  let name = selectedProperty ? selectedProperty.name : '';

  const selectedPropertyId = selectedProperty?.propertyId;
  if (selectedPropertyId) {
    const presentationId = getPresentationId(false, selectedPropertyId);
    const propertyName = propertyIdsMap[presentationId]?.name;
    name = propertyName ? `${propertyName} - ${name}` : name;
  }

  return name;
}

function getInitialValue(propertyId, unitId) {
  if (isNotNil(unitId)) {
    return getPresentationId(true, unitId);
  }

  if (isNotNil(propertyId)) {
    return getPresentationId(false, propertyId);
  }

  return '';
}

export {
  getPresentationId,
  createPropertyIdsMap,
  buildPresentationProperties,
  getPropertyName,
  getInitialValue,
  getSeperatePropertyAndUnitId,
};
