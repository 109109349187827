export const getLandlordBankingData = (oldestBaselaneBank, baselaneAccounts) => {
  const hasCompletedApplication = baselaneAccounts?.some(
    (bAccount) => bAccount?.unitAccount?.unitApplicationStatus === 'COMPLETED'
  );
  const bankAccountNumber = oldestBaselaneBank?.bankAccounts
    ? oldestBaselaneBank?.bankAccounts[0]?.accountNumber
    : '';

  return { oldestBaselaneBank, hasCompletedApplication, bankAccountNumber };
};
