import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { InProgress } from '@core/components/GetStartedPage/components/Steps';
import ActionBox from '@core/components/GetStartedPage/components/QuickActions/ActionBox';

import PropertyStepMenu from './PropertyStepMenu';
import { emptyStateCardButtonStyles } from './styles/responsive.style';

function PropertySetUp({
  sortedProperties,
  numProperties,
  numUnits,
  from = { page: '', section: '', configs: ({}: { ... }) },
  handleCreatePropertySuccess = () => {},
  handleDrawerClose = () => {},
  propertyStepMenuRef = null,
}: {
  sortedProperties: Object,
  numProperties: Number,
  numUnits: Number,
  from: Object,
  handleCreatePropertySuccess: Function,
  handleDrawerClose: Function,
  propertyStepMenuRef: any,
}): any {
  const getStartedStepRef = useRef();
  const { page, section, configs } = from ?? {};

  const { state } = useLocation();
  const startNewPropertyFlow = state?.startNewPropertyFlow;

  const [property, setProperty] = useState(null);

  const isGetStarted = page === 'get_started';
  const onOpenStepMenu = () => {
    if (isGetStarted) {
      getStartedStepRef?.current?.open();
    } else {
      propertyStepMenuRef?.current?.open();
    }
  };
  const getStartedButton = {
    rc_tracker: (
      <InProgress status={configs.status} handleTrackerInProgStepClick={(e) => onOpenStepMenu(e)} />
    ),
    bookkeeping_tracker: (
      <InProgress status={configs.status} handleTrackerInProgStepClick={(e) => onOpenStepMenu(e)} />
    ),
    quick_actions: <ActionBox action={{ ...configs, onClick: onOpenStepMenu }} />,
  };

  useEffect(() => {
    // catch all for property deletes
    if (property && !sortedProperties.some((item) => item.id === property?.id)) {
      setProperty(null);
    }
  }, [sortedProperties, property]);

  useEffect(() => {
    // starts new property flow, when coming from
    // rent collection "Start'" with no properties
    if (startNewPropertyFlow) onOpenStepMenu();
  }, [startNewPropertyFlow]);
  return (
    <>
      {isGetStarted && getStartedButton[section]}
      <PropertyStepMenu
        {...{
          ref: isGetStarted ? getStartedStepRef : propertyStepMenuRef,
          buttonLabel: 'Set Up Your First Property',
          buttonStyles: emptyStateCardButtonStyles,
          numProperties,
          numUnits,
          handleCreatePropertySuccess,
          handleDrawerClose,
        }}
      />
    </>
  );
}

export default PropertySetUp;
