import React from 'react';
import { Stack, Text, Box, Image } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import ObieModal from '../ObieModal';
import { card } from './styles/insuranceCard.styles';

type TopCardProps = {
  smallIcon: String,
  rightImage: obj,
  title: string,
  description: string,
  buttonProps: string,
  rightImage: string,
  children: any,
};

function TopCard({
  smallIcon,
  title,
  description,
  rightImage,
  buttonProps,
  children,
}: TopCardProps) {
  const { isMin899: isDesktop } = getBreakPoints();
  const { container, left, right } = card ?? {};

  const btnProps = {
    ...buttonProps,
    isFullWidth: !isDesktop,
  };

  return (
    <Stack {...container}>
      {/* Left Part */}
      <Stack {...left.container}>
        <Box>{smallIcon}</Box>
        <Text {...left.title}>{title}</Text>
        <Text {...left.description}>{description}</Text>

        {children}

        <Box {...left.buttonContainer}>
          <ObieModal {...btnProps} />
        </Box>
      </Stack>

      {/* Right Part */}
      <Box {...right}>
        <Image src={rightImage?.src} alt={rightImage?.alt} />
      </Box>
    </Stack>
  );
}

export default TopCard;
