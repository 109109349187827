import moment from 'moment';
import formatCurrency from '@core/utils/formatCurrency';

export const securityDepositInitialValues = (
  securityDepositEnabled,
  depositAmount,
  depositDueDate,
  securityPaid
) => ({
  securityDeposit: securityDepositEnabled,
  securityDepositAmount: depositAmount === null ? '' : depositAmount,
  securityDepositDueDate:
    depositDueDate === null ? null : moment(depositDueDate, moment.defaultFormat).toDate(),
  securityDepositPaid: securityPaid,
});

export const lastMonthRentInitialValues = (lastMonthEnabled, lastMonthDueDate, lastMonthPaid) => ({
  lastMonthRent: lastMonthEnabled,
  lastMonthRentDueDate:
    lastMonthDueDate === null ? null : moment(lastMonthDueDate, moment.defaultFormat).toDate(),
  lastMonthRentPaid: lastMonthPaid,
});

export const proratedRentInitialValues = (
  proratedRentEnabled,
  proratedRentAmount,
  proratedRentDueDate
) => ({
  proratedRent: proratedRentEnabled,
  proratedRentAmount:
    proratedRentAmount === null ? '' : formatCurrency(proratedRentAmount).inDollars,
  proratedRentDueDate:
    proratedRentDueDate === null
      ? null
      : moment(proratedRentDueDate, moment.defaultFormat).toDate(),
});
