import React from 'react';

type IconWarningTriangleOutlineProps = { w?: number, h?: number, color?: string };

function IconWarningTriangleOutline({ w, h, color }: IconWarningTriangleOutlineProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.26868 1.56611c.76997-1.332939 2.69392-1.332939 3.46389 0l4.61863 8c.7701 1.33319-.192 3.00009-1.7319 3.00009H2.38196c-1.539829 0-2.501974-1.6668-1.732025-2.99995.000026-.00005-.000026.00004 0 0L5.26868 1.56611Zm1.15455.66692L1.80464 10.2329c-.25657.4442.06386 1 .57732 1h9.23734c.5134 0 .834-.5557.5774-.9999L7.57801 2.23303c-.2567-.44439-.89808-.44439-1.15478 0Zm.57739 2.33321c.36819 0 .66667.29848.66667.66667v1.33333c0 .36819-.29848.66667-.66667.66667-.36819 0-.66666-.29848-.66666-.66667V5.23291c0-.36819.29847-.66667.66666-.66667Zm-.66666 4.66667c0-.36819.29847-.66667.66666-.66667h.00667c.36819 0 .66667.29848.66667.66667 0 .36819-.29848.66666-.66667.66666h-.00667c-.36819 0-.66666-.29847-.66666-.66666Z"
        fill={color}
      />
    </svg>
  );
}

IconWarningTriangleOutline.defaultProps = {
  w: 14,
  h: 13,
  color: 'currentColor',
};

export default IconWarningTriangleOutline;
