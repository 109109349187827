import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Heading, ChakraProvider } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import habitatTheme from '@core/themeHabitat';
import BaselaneDrawer from '@shared/components/BaselaneDrawer';

type TransferMethodSelectorProps = {
  isDigitalWalletOpen?: boolean,
  onDigitalWalletClose?: Function,
  from?: string,
};
const TransferMethodSelector = ({
  isDigitalWalletOpen,
  onDigitalWalletClose,
  from,
}: TransferMethodSelectorProps) => {
  const { isMax576 } = getBreakPoints();
  const navigate = useNavigate();

  return (
    <ChakraProvider theme={habitatTheme}>
      <BaselaneDrawer
        isOpen
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        title="Account & Routing Numbers"
        closeEvent={() => onDrawerClose(navigate, from)}
        onOverlayClick={() => onDrawerClose(navigate, from)}
        closeOnOverlayClick={false}
        newDesignDrawer
      >
        <Heading as="h3" size="headline-md" fontWeight="medium" mb={2}>
          Select bank transfer type
        </Heading>
        <Outlet />
      </BaselaneDrawer>
    </ChakraProvider>
  );
};

TransferMethodSelector.defaultProps = {
  isDigitalWalletOpen: null,
  onDigitalWalletClose: null,
  from: null,
};

export default TransferMethodSelector;
