export const containerStyles = {
  p: '32px 48px',
  minW: '405px',
  w: '50%',
  m: '0 !important',
  minH: '312px',
};

export const titleStyles = {
  textStyle: { xl: 'headline-md', base: 'headline-sm' },
};

export const itemStyles = {
  mt: '8px !important',
  ml: 1,
};

export const itemTextStyles = {
  textStyle: { xl: 'md', xs: 'sm', base: 'xs' },
  color: 'brand.neutral.600',
};
