import React from 'react';

function IconWarning() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 0C6.72632 0 0 6.72632 0 15C0 23.2737 6.72632 30 15 30C23.2737 30 30 23.2737 30 15C30 6.72632 23.2737 0 15 0ZM13.3579 6.85263C13.7684 6.37895 14.3684 6.12632 15 6.12632C16.2 6.12632 17.2105 7.10526 17.2105 8.33684C17.2105 8.46316 17.2105 8.52631 17.2105 8.62105L16.0737 17.7789C15.7263 17.6842 15.3789 17.6211 15 17.6211C14.6526 17.6211 14.3053 17.6526 13.9579 17.7474L12.7895 8.58947C12.7263 7.98947 12.9474 7.35789 13.3579 6.85263ZM15 23.8737C13.7684 23.8737 12.7895 22.8632 12.7895 21.6316C12.7895 20.4 13.7684 19.4211 15 19.4211C16.2316 19.4211 17.2421 20.4 17.2421 21.6316C17.2105 22.8632 16.2316 23.8737 15 23.8737Z"
        fill="#002E51"
      />
    </svg>
  );
}

export default IconWarning;
