import process from 'process';
import React from 'react';
import { Center, Text, Image, Flex, useBreakpointValue } from '@chakra-ui/react';
import { IconPoweredBySimplist } from '@icons';
import InstantQuoteButton from '../InstantQuoteButton';
import {
  titleFontStyles,
  subTextFontStyles,
  bannerBackgroundStyles,
} from './styles/bannerText.styles';

const Banner = () => {
  const bannerImageSrc = useBreakpointValue({
    base: `${process.env.PUBLIC_URL}/loans-mobile.svg`,
    sm: `${process.env.PUBLIC_URL}/loans.svg`,
    md: `${process.env.PUBLIC_URL}/loans-mobile.svg`,
    lg: `${process.env.PUBLIC_URL}/loans.svg`,
  });

  return (
    <Flex gap={4} {...bannerBackgroundStyles} m={{ base: 4, lg: 0 }} p={4}>
      {/* Left Part */}
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        gap={2}
        flex={{ base: '0 1 50%', '2lg': '0 1 60%' }}
      >
        <IconPoweredBySimplist />

        <Text {...titleFontStyles}>Painless Rental Property Loans </Text>
        <Text {...subTextFontStyles} mb={2}>
          We search 50,000 options from top lenders to match you with the best rental property
          loans, then do all the hard work for you.
        </Text>
        <InstantQuoteButton
          btnVariant="tonal"
          palette="primary"
          btnSize="lg"
          btnText="Get Started"
        />
      </Flex>
      {/* Right Part */}
      <Center>
        <Image src={bannerImageSrc} alt="Simplist process images" />
      </Center>
    </Flex>
  );
};

export default Banner;
