import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { BaselaneChip, BaselaneTooltip } from '@shared/components';
import { Icon16CreditCard } from '@icons/16px';
import { tooltipTitleText } from './accountTag.styles';

type CardDetailsChipProps = {
  cards: Array<Object>,
};

const CardDetailsChip = ({ cards }: CardDetailsChipProps) => {
  const attachedCardsList = (
    <Stack>
      <Text {...tooltipTitleText}>Attached to:</Text>
      {cards.map((card) => {
        const { id, isPhysical, last4Digits } = card;
        return (
          <Text key={id} pb="8px">{`${
            isPhysical ? 'Physical card' : 'Virtual card'
          } - ${last4Digits}`}</Text>
        );
      })}
    </Stack>
  );

  return (
    <BaselaneTooltip label={attachedCardsList} placement="bottom-start">
      <BaselaneChip size="md" variant="simple-neutral" leftIcon={Icon16CreditCard} />
    </BaselaneTooltip>
  );
};

export default CardDetailsChip;
