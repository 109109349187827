import sendSegmentEvent from '@core/utils/sendSegmentEvent';

const onNavMenuClickEvents = (label) => {
  if (label === 'Transfers & payments') {
    sendSegmentEvent('baselane_banking_transfers_payments_navigation_clicked', {
      target: 'nav',
    });
  } else if (label === 'All partners') {
    sendSegmentEvent('temp_partnerships_page_entry_point_clicked', {
      target: 'nav',
    });
  }
};

export default onNavMenuClickEvents;
