import React from 'react';
import { VStack, Heading, HStack, Box, Flex } from '@chakra-ui/react';
import { IconCamera, IconRefresh } from '@icons';
import BaselaneButton from '../../BaselaneButton';

import { getImageStyle } from '../helpers/mobileCheckUpload.helpers';

import {
  headingStyles,
  captureButtonStyles,
  iconFrameStyles,
} from '../styles/mobileCheckUploader.styles';

type ButtonCheckCaptureProps = {
  label: String,
  ariaButtonDescription: String,
  imageData: any,
  onCaptureClick: Function,
  onRetakeClick: Function,
  onImageRotation: Function,
  frontRotated180: boolean,
  backRotated180: boolean,
  isFront: boolean,
  isRequired?: boolean,
};

function ButtonCheckCapture({
  label,
  ariaButtonDescription,
  imageData,
  onCaptureClick,
  onRetakeClick,
  onImageRotation,
  frontRotated180,
  backRotated180,
  isFront,
  isRequired,
}: ButtonCheckCaptureProps): any {
  const imageStyle = getImageStyle(imageData, false);
  return (
    <VStack
      gap={0}
      alignItems="flex-start"
      {...{
        w: '100%',
        mt: 2,
      }}
    >
      <HStack
        gap={0}
        {...{
          w: '100%',
          alignContent: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <Heading {...headingStyles}>
          {label}
          {isRequired && (
            <Box as="span" color="red.500" ml={0.5}>
              *
            </Box>
          )}
        </Heading>
      </HStack>
      <BaselaneButton
        {...{
          variant: 'tonal',
          palette: 'primary',
          isDisabled: imageData,
          onClick: onCaptureClick,
          [`aria-label`]: ariaButtonDescription,
          ...captureButtonStyles(isFront ? frontRotated180 : backRotated180),
          ...imageStyle,
        }}
        // temporary solution for backgroundImage issue with chakra https://github.com/chakra-ui/chakra-ui/issues/7548
        style={{
          backgroundImage: imageStyle.backgroundImage,
          outline: 'none',
          border: imageData ? 'none' : '1px solid #DADFE6',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          borderBottomLeftRadius: imageData ? 0 : '5px',
          borderBottomRightRadius: imageData ? 0 : '5px',
        }}
      >
        {!imageData && (
          <Box {...iconFrameStyles}>
            <IconCamera {...{ w: '20', h: '20' }} />
          </Box>
        )}
      </BaselaneButton>
      {imageData ? (
        <Flex
          background="brand.darkBlue.50"
          width="100%"
          borderBottomLeftRadius="5px"
          borderBottomRightRadius="5px"
          borderLeft="1px solid"
          borderRight="1px solid"
          borderBottom="1px solid"
          borderRightColor="brand.darkBlue.200"
          borderLeftColor="brand.darkBlue.200"
          borderBottomColor="brand.darkBlue.200"
          paddingX={2}
          paddingY={0.5}
          gap={2}
        >
          <BaselaneButton
            variant="transparent"
            palette="neutral"
            size="sm"
            onClick={onImageRotation}
            leftIcon={<IconRefresh />}
          >
            Rotate
          </BaselaneButton>
          <BaselaneButton
            variant="transparent"
            palette="neutral"
            size="sm"
            onClick={onRetakeClick}
            leftIcon={<IconCamera />}
          >
            Retake Photo
          </BaselaneButton>
        </Flex>
      ) : null}
    </VStack>
  );
}

ButtonCheckCapture.defaultProps = {
  isRequired: false,
};

export default ButtonCheckCapture;
