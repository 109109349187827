export const redeemSuccessContainerStyles = {
  align: 'center',
  justify: 'center',
  h: '100%',
};

export const redeemSuccessIconContainerStyles = {
  align: 'center',
  justify: 'center',
  h: '96px',
  w: '96px',
  bg: 'green.100',
  borderRadius: '50%',
};

export const redeemSuccessMessageContainerStyles = {
  w: '420px',
  mt: '48px !important',
};

export const redeemSuccessMessageStyles = {
  fontSize: '4xl',
  lineHeight: '48px',
  fontWeight: 'semibold',
  textAlign: 'center',
  m: '0 !important',
};
