import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';

const staticPropertiesDesktopData = [
  {
    id: uuidv4(),
    name: 'San Bernardino Duplex',
    description: null,
    address: {
      address: '1900 Simond Ave',
      city: 'San Bernardino',
      state: 'CA',
      unit: '',
      zipCode: '90210',
    },
    units: [
      {
        id: uuidv4(),
        name: 'San Bernardino Duplex',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-10-31',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-11-30',
          startDate: '2022-10-30',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Los Angeles Apartment',
    description: null,
    address: {
      address: '238 Mayfield Ave',
      city: 'Los Angeles',
      state: 'CA',
      unit: '',
      zipCode: '90219',
    },
    units: [
      {
        id: uuidv4(),
        name: 'Unit A',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-07-15',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-07-15',
          startDate: '2022-07-15',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: 'Unit B',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-05-31',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-05-31',
          startDate: '2022-05-31',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: 'Unit C',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'unoccupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: null,
          latefee: null,
          leaseType: 'FIXED',
          newLease: null,
          paymentState: null,
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: null,
          startDate: null,
          state: null,
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: null,
          payments: [],
          tenantProfileMetadata: null,
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'West Village Townhome',
    description: null,
    address: {
      address: '140 Spirit Drive',
      city: 'Orange City',
      state: 'FL',
      unit: '',
      zipCode: '32763',
    },
    units: [
      {
        id: uuidv4(),
        name: '101',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-01-31',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-01-31',
          startDate: '2022-01-31',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '102',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-12-31',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-12-31',
          startDate: '2022-12-31',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '201',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'unoccupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: null,
          latefee: null,
          leaseType: 'FIXED',
          newLease: null,
          paymentState: null,
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: null,
          startDate: null,
          state: null,
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: null,
          payments: [],
          tenantProfileMetadata: null,
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'NYC AirBnB - 2',
    description: null,
    address: {
      address: '44 Beech St.',
      city: 'Brooklyn',
      state: 'NY',
      unit: '',
      zipCode: '11218',
    },
    units: [
      {
        id: uuidv4(),
        name: '1011',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-04-30',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'OVERDUE',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-04-30',
          startDate: '2022-04-30',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '1012',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-06-30',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-06-30',
          startDate: '2022-06-30',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '1013',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-06-28',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'OVERDUE',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-06-28',
          startDate: '2022-06-28',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '1014',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-09-30',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'OVERDUE',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-09-30',
          startDate: '2022-09-30',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '2011',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-11-07',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'OVERDUE',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-11-07',
          startDate: '2022-11-07',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '2012',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'unoccupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: null,
          latefee: null,
          leaseType: null,
          newLease: null,
          paymentState: null,
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: null,
          startDate: null,
          state: null,
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: null,
          payments: [],
          tenantProfileMetadata: null,
        },
      },
      {
        id: uuidv4(),
        name: '2013',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'unoccupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: null,
          latefee: null,
          leaseType: null,
          newLease: null,
          paymentState: null,
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: null,
          startDate: null,
          state: null,
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: null,
          payments: [],
          tenantProfileMetadata: null,
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Icon Home - 254',
    description: null,
    address: {
      address: '3830 Richland Avenue',
      city: 'Sugar Land',
      state: 'TX',
      unit: '',
      zipCode: '77487',
    },
    units: [],
  },
  {
    id: uuidv4(),
    name: 'NYC AirBnB',
    description: null,
    address: {
      address: '8647 E. Acacia Ave',
      city: 'New York',
      state: 'NY',
      unit: '',
      zipCode: '10023',
    },
    units: [],
  },
];

const staticPropertiesMobileData = [
  {
    id: uuidv4(),
    name: 'San Bernardino Duplex',
    description: null,
    address: {
      address: '1900 Simond Ave',
      city: 'San Bernardino',
      state: 'CA',
      unit: '',
      zipCode: '90210',
    },
    units: [
      {
        id: uuidv4(),
        name: 'San Bernardino Duplex',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-10-31',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-11-30',
          startDate: '2022-10-30',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Los Angeles Apartment',
    description: null,
    address: {
      address: '238 Mayfield Ave',
      city: 'Los Angeles',
      state: 'CA',
      unit: '',
      zipCode: '90219',
    },
    units: [
      {
        id: uuidv4(),
        name: 'Unit A',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-07-15',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-07-15',
          startDate: '2022-07-15',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: 'Unit B',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-05-31',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-05-31',
          startDate: '2022-05-31',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'West Village Townhome',
    description: null,
    address: {
      address: '140 Spirit Drive',
      city: 'Orange City',
      state: 'FL',
      unit: '',
      zipCode: '32763',
    },
    units: [
      {
        id: uuidv4(),
        name: '101',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-01-31',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-01-31',
          startDate: '2022-01-31',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '102',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-12-31',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-12-31',
          startDate: '2022-12-31',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'NYC AirBnB - 2',
    description: null,
    address: {
      address: '44 Beech St.',
      city: 'Brooklyn',
      state: 'NY',
      unit: '',
      zipCode: '11218',
    },
    units: [
      {
        id: uuidv4(),
        name: '1011',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-04-30',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'OVERDUE',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-04-30',
          startDate: '2022-04-30',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '1012',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-06-30',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'CURRENT',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-06-30',
          startDate: '2022-06-30',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '1013',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-06-28',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'OVERDUE',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-06-28',
          startDate: '2022-06-28',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '1014',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-09-30',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'OVERDUE',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-09-30',
          startDate: '2022-09-30',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
      {
        id: uuidv4(),
        name: '2011',
        description: null,
        archivedLease: [],
        rentStatus: null,
        status: 'occupied',
        lease: {
          id: uuidv4(),
          archived: false,
          isDummyData: true,
          cancellationNotice: null,
          completedTags: null,
          depositBankAccountId: null,
          endDate: '2023-11-07',
          latefee: 200,
          leaseType: 'FIXED',
          newLease: false,
          paymentState: 'OVERDUE',
          propertyUnitId: uuidv4(),
          rentAndFeesBankAccountId: '7513',
          rentCollectionStartDate: '2022-11-07',
          startDate: '2022-11-07',
          state: 'SCHEDULED',
          tenantAutoPayEnrolled: null,
          tenantProfileId: uuidv4(),
          totalOverdueAmount: 500.01,
          payments: [],
          tenantProfileMetadata: {
            email: 'test@test.com',
            firstName: 'test',
            lastName: 'test',
            phoneNumber: '',
          },
        },
      },
    ],
  },
];

export const staticPaymentSummaryData = {
  invoiceCompletedSummary: {
    amount: 8000,
    count: 4,
  },
  invoiceFailedSummary: {
    amount: 0,
    count: 0,
  },
  invoiceOutstandingSummary: {
    amount: 6000,
    count: 3,
  },
  invoiceOverdueSummary: {
    amount: 6000,
    count: 3,
  },
  invoiceProcessingSummary: {
    amount: 8000,
    count: 4,
  },
};

export const emptyStateStaticPropertiesData = isMobile
  ? staticPropertiesMobileData
  : staticPropertiesDesktopData;
