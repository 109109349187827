import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { accordionHeaderStyles } from '../styles/accordionHeader.styles';

type AccordionHeaderProps = {
  payment: Object,
};

function AccordionHeader({ payment }: AccordionHeaderProps) {
  const renderTitle = ({ type, numberOfData }) => {
    let title = '';
    const suffix = `${numberOfData === 1 ? '' : 's'}`;

    switch (type) {
      case 'FAILED_PAYMENT':
        title = 'failed payment';
        break;

      case 'PROCESSING_PAYMENT':
        title = 'processing payment';
        break;

      case 'RENT_COLLECTION':
        title = 'recurring rent collection';
        break;

      case 'UNPAID_INVOICE':
        title = 'other unpaid invoice';
        break;

      default:
        break;
    }

    return title + suffix;
  };

  const renderSubtitle = ({ type }) => {
    switch (type) {
      case 'PROCESSING_PAYMENT':
        return 'Will be re-processed with the updated account if they fail';

      default:
        return null;
    }
  };

  const { text, subtext } = accordionHeaderStyles ?? {};

  return (
    <Stack>
      <Text {...text}>
        {`${payment.numberOfData} ${renderTitle({
          type: payment.type,
          numberOfData: payment.numberOfData,
        })}`}
      </Text>
      <Text {...subtext}>{renderSubtitle({ type: payment.type })}</Text>
    </Stack>
  );
}

export default AccordionHeader;
