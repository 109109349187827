import React from 'react';
import { Box, Heading, Text, Stack } from '@chakra-ui/react';
import { iconContainerStyles, titleStyles, descriptionStyles } from './styles/main.styles';

type SuccessViewProps = {
  icon?: any,
  title: string,
  message: string,
};

const SuccessView = ({ icon, title, message }: SuccessViewProps) => {
  return (
    <Stack spacing="1">
      {icon && <Box {...iconContainerStyles}>{icon}</Box>}
      <Stack>
        <Heading {...titleStyles}>{title}</Heading>
        <Text {...descriptionStyles}>{message}</Text>
      </Stack>
    </Stack>
  );
};

SuccessView.defaultProps = {
  icon: null,
};

export default SuccessView;
