export const buttonCardStyles = {
  borderRadius: '4px',
  height: 'auto',
  minHeight: 'unset',
  borderColor: 'brand.neutral.500',
  fontSize: 'md',
  fontWeight: 'medium',
  display: 'block',
  lineHeight: '24px',
  sx: {
    '@media screen and (max-width: 700px)': {
      width: 'auto',
      padding: '12px',
    },
  },
};

export const titleTextStyles = (pageHeader) => ({
  mb: 4,
  fontWeight: 'medium',
  fontSize: '2xl',
  color: 'brand.neutral.900',
  sx: {
    '@media screen and (max-width: 700px)': {
      fontSize: pageHeader ? 'xl' : 'lg',
      mb: pageHeader ? '16px' : '16px',
    },
  },
});

export const subTitleTextStyles = {
  mb: 4,
  fontWeight: 'normal',
  fontSize: 'sm',
  color: 'brand.neutral.900',
};

export const otherBoxStyles = {
  p: '16px',
  border: '1px solid',
  borderColor: 'brand.blue.800A',
  borderRadius: '4px',
  bg: 'brand.blue.100',
};

export const progressBarStyles = {
  w: '100%',
  bg: 'brand.darkBlue.100',
  mb: '24px',
  minH: '6px',
  height: '6px',
  borderRadius: '24px',
};
