import customTheme from '@core/theme';

export const pageContainerStyles = {
  gap: 0,
  marginInlineStart: {
    lg: 0,
    sm: 4,
    base: 0,
  },
  marginInlineEnd: {
    lg: 0,
    sm: 4,
    base: 0,
  },
  my: {
    lg: 0,
    sm: 4,
    base: 3,
  },
};

export const tabContainerStyles = (isMax576: boolean) => ({
  d: 'flex',
  flexDir: 'row',
  justifyContent: isMax576 ? 'center' : 'flex-start',
  alignItems: 'flex-end',
  gap: isMax576 ? 2 : 4,
  px: isMax576 ? 2 : 4,
  h: '62px',
});

export const userProfileTabsListStyles = (isMax576) => ({
  color: 'brand.neutral.500',
  fontSize: isMax576 ? 'xs' : 'md',
  fontWeight: 'semibold',
  px: 0,
  py: 2,
  h: '100%',
  minW: isMax576 ? { base: '60px', xs: 'calc(25% - 16px)' } : 0,
  _selected: {
    color: 'brand.darkBlue.800A',
    borderColor: 'brand.darkBlue.800A',
  },
});

export const emptyStateViewStyles = {
  conatinerStyles: {
    h: '-webkit-fill-available',
    marginInlineStart: {
      lg: 0,
      base: 4,
    },
    marginInlineEnd: {
      lg: 0,
      base: 4,
    },
    my: {
      lg: 0,
      sm: 4,
      base: 3,
    },
  },
  titleTextStyles: {
    textStyle: 'headline-lg',
  },
  emptyStateContainerStyles: {
    h: 'auto',
    gap: 0,
    borderRadius: '6px',
    borderWidth: '1px',
    borderColor: 'brand.blue.100',
    overflow: 'hidden',
  },
  leftElementContainerStyles: {
    width: { md: '50%', base: 'auto' },
    backgroundColor: 'brand.neutral.white',
    p: { '2xs': 3, base: 2 },
    px: { lg: 4 },
    gap: 3,
    justifyContent: 'center',
  },
  headingTextStyles: {
    textStyle: 'headline-lg',
    color: customTheme.colors.brand.neutral['700'],
  },
  subtextStyles: {
    textStyle: 'sm',
    color: customTheme.colors.brand.neutral['600'],
  },
  rightElementContainerStyles: {
    width: { md: '50%', base: 'auto' },
    backgroundColor: customTheme.colors.brand.blue['100'],
    p: { '2xs': 3, base: 2 },
    px: { lg: 4 },
  },
  imageContainerStyles: {
    maxHeight: '232px',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: customTheme.colors.brand.blue['100'],
    pt: 3,
    pr: 3,
    pb: '9px',
    pl: 0,
    display: { '2lg': 'flex', base: 'none' },
    minHeight: '100%',
  },
  disclaimerStyles: {
    color: 'brand.neutral.500',
    textStyle: 'xs',
  },
};

export const spinnerStyles = {
  color: 'brand.blue.700',
  size: 'xl',
  position: 'absolute',
  top: '45%',
  left: '45%',
};
