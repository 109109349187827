import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Stack } from '@chakra-ui/react';

import { BaselaneButtonIcon } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import { Icon16ChevronLeft } from '@icons/16px';

import FinishLaterButton from './FinishLaterButton';
import { containerStyles, headerContainerStyles } from './styles/headerNavWrapper.styles';

type HeaderNavWrapperProps = {
  children: ReactElement,
  hideBackButton: boolean,
  onClickBack: Function,
  handleFinishEvent: Function | null,
  hideFinishLater: boolean,
  leftElementsContainerStyle: Object,
  isMobile: boolean,
  headerContent: ReactElement | null,
};

const HeaderNavWrapper = ({
  children,
  hideBackButton = false,
  onClickBack = null,
  handleFinishEvent = null,
  hideFinishLater = false,
  leftElementsContainerStyle = {},
  isMobile: isMobileView = null,
  headerContent = null,
}: HeaderNavWrapperProps) => {
  const { isMax767: isMobileBreakpoint } = getBreakPoints();
  const isMobile = isMobileView ?? isMobileBreakpoint;

  const navigate = useNavigate();

  const handleBackClick = () => {
    if (onClickBack) {
      onClickBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <Stack {...containerStyles}>
      <Stack
        {...{
          ...headerContainerStyles(isMobile),
          justifyContent: hideBackButton && hideFinishLater && isMobile ? 'center' : 'flex-start',
          ...leftElementsContainerStyle,
        }}
      >
        {isMobile && !hideBackButton && (
          <Flex flex="1">
            <BaselaneButtonIcon
              variant="transparent"
              palette="neutral"
              icon={<Icon16ChevronLeft />}
              onClick={handleBackClick}
              size="md"
            />
          </Flex>
        )}
        {headerContent}
        {!hideFinishLater && (
          <Flex direction="row" flex="1" justifyContent="flex-end" alignItems="center">
            <FinishLaterButton {...{ handleFinishEvent }} />
          </Flex>
        )}
      </Stack>
      {children}
    </Stack>
  );
};

export default HeaderNavWrapper;
