export const bodyTextStyles = {
  textStyle: 'sm',
};

export const badgeIconBgStyles = {
  borderRadius: '50%',
  p: '6px',
  w: '32px',
  h: '32px',
  alignItems: 'center',
  justifyContent: 'center',
};

export const badgeIconContainerStyles = {
  bg: 'transparent',
  w: '16px',
  h: '16px',
};

export const badgeIconBgSuccessStyles = {
  ...badgeIconBgStyles,
  bg: 'green.100',
};

export const badgeIconSuccessContainerStyles = {
  ...badgeIconContainerStyles,
  color: 'green.800AA',
  marginRight: '5px',
  marginBottom: '5px',
};
