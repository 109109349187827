import React from 'react';
import { Stack } from '@chakra-ui/react';
import { BaselaneButton, BaselaneButtonGroup, BaselaneDrawer } from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';

type FooterProps = {
  isFormValid: Boolean,
  isDirty: Boolean,
  isSubmitting: Boolean,
  onCloseWithoutSaving: Function,
  formikRef: any,
  isCheckboxChecked: Boolean,
};

const Footer = ({
  isFormValid,
  isDirty,
  isSubmitting,
  onCloseWithoutSaving,
  formikRef,
  isCheckboxChecked,
}: FooterProps) => {
  const { DrawerFooter } = BaselaneDrawer;
  const { isMax576 } = getBreakPoints();

  return (
    <DrawerFooter
      px={isMax576 ? 2 : 3}
      py="2"
      boxShadow="none"
      borderTop="1px solid"
      borderColor="brand.darkBlue.200"
    >
      <Stack spacing={2}>
        <BaselaneButtonGroup size="md">
          <BaselaneButton
            size="md"
            variant="outline"
            palette="neutral"
            onClick={onCloseWithoutSaving}
          >
            Cancel
          </BaselaneButton>
          <BaselaneButton
            id="create-virtual-account-button"
            size="md"
            variant="filled"
            palette="primary"
            isDisabled={!isFormValid || !isDirty || !isCheckboxChecked}
            isLoading={isSubmitting}
            onClick={(e) => formikRef.current.handleSubmit(e)}
          >
            Add Virtual Account
          </BaselaneButton>
        </BaselaneButtonGroup>
      </Stack>
    </DrawerFooter>
  );
};

export default Footer;
