import React, { useContext, useEffect, useState } from 'react';
import BaselaneResponsiveTableContext from '../BaselaneResponsiveTableContext';
import BaselaneSingleDatePicker from '../../BaselaneDatePicker/BaselaneSingleDatePicker';
import BaselaneResponsiveCell from './BaselaneResponsiveCell';

type BaselaneResponsiveCellDatePickerProps = {
  /**
   * Cell children
   */
  children?: React.Node,
  /**
   * The initial date value in the form of a formatted date string
   */
  selectedDate?: String,
  /**
   * The initial date value in the form of a formatted date string
   */
  dateFormat?: String,
  /**
   * Determines whether the datepicker is enabled or not
   */
  disabled?: Boolean,
  /**
   * The handler function to execute when a date is selected
   */
  onDateSubmit?: Function,
  /**
   * Index to be used to grab information specific in config
   */
  configIndex?: Number,
};

/**
 * Renders a generic cell that can be used within BaselaneResponsiveTableRow
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveCellDatePicker = ({
  children,
  selectedDate,
  dateFormat,
  disabled,
  onDateSubmit,
  configIndex,
  ...rest
}: BaselaneResponsiveCellDatePickerProps) => {
  const config = useContext(BaselaneResponsiveTableContext);
  const configStyles = configIndex !== null ? config?.columns[configIndex]?.styles : {};
  const years =
    configIndex !== null ? config?.columns[configIndex]?.years : { start: 2000, end: 2033 };

  const [selectedDateObject, setSelectedDateObject] = useState(
    new Date(selectedDate) || new Date(`1969-12-31`)
  );

  const handleCalendarClose = (newDate) => {
    if (newDate?.date) {
      setSelectedDateObject(newDate.date);
      onDateSubmit(newDate.date);
    }
  };

  useEffect(() => {
    setSelectedDateObject(new Date(selectedDate) || new Date(`1969-12-31`));
  }, [selectedDate]);

  return (
    <BaselaneResponsiveCell {...configIndex} {...{ ...configStyles }} {...rest}>
      <BaselaneSingleDatePicker
        {...{
          date: selectedDateObject,
          dateFormat,
          years,
          handleCalendarClose,
          disabled,
          size: 'md',
          hideInputIcon: true,
          fixedHeight: true,
          className: 'table-cell',
        }}
      />
      {children}
    </BaselaneResponsiveCell>
  );
};

BaselaneResponsiveCellDatePicker.defaultProps = {
  selectedDate: null,
  dateFormat: null,
  disabled: false,
  onDateSubmit: () => {},
  configIndex: null,
  children: null,
};

export default BaselaneResponsiveCellDatePicker;
