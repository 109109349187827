export const selectStatesStyles = (isPlaceholder) => {
  return {
    fontSize: 'sm',
    lineHeight: '24px',
    color: isPlaceholder ? 'brand.neutral.500' : 'brand.neutral.700',
    borderColor: 'brand.darkBlue.150',
    borderRadius: '4px',
    h: '48px',
    p: '12px 16px',
    _invalid: {
      boxShadow: 'none !important',
      borderColor: 'red.800AA',
      _focus: {
        borderColor: 'red.800AA',
        color: 'brand.neutral.700',
        boxShadow: 'none',
      },
    },
    _focus: {
      borderColor: 'brand.darkBlue.250',
      color: 'brand.neutral.600',
      boxShadow: 'inset 0px 0px 1px 1px #E1F4FF',
    },
    _hover: { borderColor: 'brand.darkBlue.250', color: 'brand.neutral.600' },
    _disabled: { borderColor: 'brand.darkBlue.100', color: 'brand.darkBlue.300' },
  };
};
