import React, { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Image, Stack } from '@chakra-ui/react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { BaselaneButton } from '@shared/components';
import { IconArrowLeftTwo } from '@icons';
import { Icon16ArrowForward } from '@icons/16px';
import UserContext from '@contexts/UserContext';
import { UPDATE_USER } from '@core/components/UserProfilePage/queries';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import { getQueryParams } from '@core/Services/Firebase/firebase';
import customTheme from '@theme';
import {
  UNIFIED_LANDLORD_BANKING,
  UNIFIED_RENT_COLLECTION,
  UNIFIED_BOOKKEEPING_ANALYTICS,
} from '@routes';
import HeaderNavWrapper from '@shared/layouts/HeaderNavWrapper';
import BaselaneFullLogo from '@core/assets/BaselaneFullLogo';

import flow from '../helpers/flow.helpers';
import {
  getCTABtnText,
  getImages,
  handleFinishLater,
  handleGetUserInfo,
  setSelection,
} from '../helpers/onboardingFunctions.helpers';
import { flowLocationPoints } from '../helpers/segmentEvents.helpers';
import {
  buttonContainerStyles,
  containerStyles,
  contentWrapperContainerStyles,
  imageContainerStyles,
  leftUIContainerStyles,
  leftUIInnerContainerStyles,
} from '../styles/intro.styles';

const OnboardingContentWrapper = () => {
  const navigate = useNavigate();
  const { user, refetchUser } = useContext(UserContext);

  const { onboardingTriageSelection = null } = user?.userMetadata?.metadata || {};

  const [updateUser] = useMutation(UPDATE_USER);

  const location = useLocation();
  const selection = getQueryParams(location, 'selection');
  const pathSections = location.pathname.split('/').reverse();
  const [lastPathSection] = pathSections;
  const path = flow[lastPathSection] || {};
  const { next } = path;

  const sendOnboardingSelectionSegmentEvent = () => {
    sendSegmentEvent('onboarding_path_selection', { feature: selection });
  };

  const handleOnNextClick = () => {
    if (
      ((selection === 'landlord_banking' || selection === 'bookkeeping') &&
        lastPathSection === 'before_you_begin') ||
      (selection === 'rent_collection' && lastPathSection === 'easy_steps')
    ) {
      const { next: nextPath } = path[selection];
      handleFinishLater({
        user:
          onboardingTriageSelection !== 'rentCollection'
            ? handleGetUserInfo(user, selection)
            : user,
        refetchUser,
        updateUser,
        isOnboardingCompleted: false,
        removeSelection: false,
        handleRouteChange: () => {
          sendOnboardingSelectionSegmentEvent();
          navigate(nextPath);
        },
      });
    } else {
      navigate({ pathname: next, search: window.location.search });
    }
  };

  const handleOnPrevClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (lastPathSection && flowLocationPoints?.[selection]?.[lastPathSection]) {
      sendSegmentEvent(flowLocationPoints[selection][lastPathSection], {});
    }
  }, [lastPathSection]);

  // adding search back onto url
  useEffect(() => {
    const conditionOne =
      !selection &&
      (lastPathSection === 'easy_steps' ||
        lastPathSection === 'before_you_begin' ||
        lastPathSection === 'getting_started');

    const conditionTwo =
      selection !== 'rent_collection' &&
      selection !== 'landlord_banking' &&
      selection !== 'bookkeeping';
    if (conditionOne || conditionTwo) {
      const search = setSelection(user?.metadata);
      navigate(location.pathname, { search: `?selection=${search}` });
    }
  }, []);

  // do not let users go back if user click on CTA to start the selected flow
  // e.g click on Set Up Rent Collection
  useEffect(() => {
    if (location?.pathname.includes('getting_started')) {
      if (onboardingTriageSelection === 'rentCollection') {
        navigate(UNIFIED_RENT_COLLECTION);
      } else if (onboardingTriageSelection === 'banking') {
        navigate(UNIFIED_LANDLORD_BANKING);
      } else if (onboardingTriageSelection === 'bookkeeping') {
        navigate(UNIFIED_BOOKKEEPING_ANALYTICS);
      }
    }
  }, [location.pathname]);

  const illustration = getImages(selection, 'rightIllustration');
  const CTAButtonText = getCTABtnText(selection);

  return (
    <HeaderNavWrapper
      headerContent={<BaselaneFullLogo />}
      // TODO: to be removed once flow is refactored to not use device detect
      isMobile={isMobile}
    >
      <Stack direction="row" {...containerStyles}>
        <Stack {...leftUIContainerStyles}>
          <Stack {...leftUIInnerContainerStyles}>
            <Box {...contentWrapperContainerStyles}>
              <Outlet />
            </Box>
            <Stack direction="row" {...buttonContainerStyles}>
              {!isMobile && (
                <BaselaneButton
                  id="previous-button"
                  variant="outline"
                  palette="neutral"
                  size="md"
                  leftIcon={<IconArrowLeftTwo color={customTheme.colors.brand.darkBlue['800A']} />}
                  onClick={handleOnPrevClick}
                >
                  {selection === 'rent_collection' && lastPathSection === 'easy_steps'
                    ? 'Back'
                    : 'Previous'}
                </BaselaneButton>
              )}
              <BaselaneButton
                id={lastPathSection === 'before_you_begin' ? `${selection}-button` : 'next-button'}
                variant="filled"
                palette="primary"
                size="md"
                rightIcon={!isMobile && <Icon16ArrowForward />}
                styles={{ width: isMobile ? '100%' : 'auto' }}
                onClick={handleOnNextClick}
              >
                {(selection !== 'rent_collection' && lastPathSection === 'before_you_begin') ||
                (selection === 'rent_collection' && lastPathSection === 'easy_steps')
                  ? CTAButtonText
                  : 'Next'}
              </BaselaneButton>
            </Stack>
          </Stack>
        </Stack>
        {!isMobile && (
          <Stack {...imageContainerStyles}>
            <Image src={illustration} maxWidth="100%" maxHeight="100%" />
          </Stack>
        )}
      </Stack>
    </HeaderNavWrapper>
  );
};

export default OnboardingContentWrapper;
