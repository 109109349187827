import React from 'react';
import { DisplayInputWrapperWithRightButton } from '@shared/components';
import DisplayInputTrxPropertyItemContent from './DisplayInputTrxPropertyItemContent';

type DisplayInputTrxPropertyItemProps = {
  selectedOptions: Array<Object>,
  showValueByFields: Array<string>,
  handleContentRendererClick: Function,
  placeholder: string,
  title: string,
  handleClearClick: Function,
  isDisabled: boolean,
  classNames: Array<string>,
};

const DisplayInputTrxPropertyItem = ({
  handleContentRendererClick,
  selectedOptions,
  placeholder,
  title,
  showValueByFields,
  handleClearClick,
  isDisabled,
  classNames,
}: DisplayInputTrxPropertyItemProps) => {
  return (
    <DisplayInputWrapperWithRightButton
      handleContentRendererClick={handleContentRendererClick}
      handleClearClick={handleClearClick}
      selectedOptions={selectedOptions}
      classNames={classNames}
    >
      <DisplayInputTrxPropertyItemContent
        isDisabled={isDisabled}
        selectedOptions={selectedOptions}
        placeholder={placeholder}
        title={title}
        showValueByFields={showValueByFields}
      />
    </DisplayInputWrapperWithRightButton>
  );
};

export default DisplayInputTrxPropertyItem;
