import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { IconBankTransfer } from '@icons';
import { BaselaneButtonCard } from '@shared/components';
import {
  bodyContainerStyles,
  bodyTextStyles,
  buttonInnerContainerStyles,
  buttonStyles,
  titleTextStyles,
} from './styles/buttonMethod.styles';

type ButtonMethodProps = {
  handleOnClick: Function,
  title: string,
  description: string,
  styles?: Object,
  icon?: any,
};

const ButtonMethod = ({ handleOnClick, title, description, styles, icon }: ButtonMethodProps) => (
  <BaselaneButtonCard
    type="clean"
    onClick={handleOnClick}
    buttonStyles={{ ...buttonStyles, ...styles }}
  >
    <Stack direction="row" {...buttonInnerContainerStyles}>
      <Box>{icon || <IconBankTransfer />}</Box>
      <Stack {...bodyContainerStyles}>
        <Text {...titleTextStyles}>{title}</Text>
        <Text
          className={title === 'Mobile Check Deposit (Coming Soon)' ? '' : 'hover-white'}
          {...bodyTextStyles}
        >
          {description}
        </Text>
      </Stack>
    </Stack>
  </BaselaneButtonCard>
);

ButtonMethod.defaultProps = {
  styles: {},
  icon: null,
};

export default ButtonMethod;
