import React from 'react';

function IconOrgChart({ w, h }: { w?: Number, h?: Number }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 33"
    >
      <path d="M17.25 7.43v6.6m9.46 7.33v-7.33H7.43v7.33" stroke="#192C3E" strokeWidth="2" />
      <path
        d="m4.75 18.14 2.68 3.22 2.68-3.22M24.04 18.14l2.67 3.22 2.68-3.22"
        stroke="#192C3E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.38 7.43h-9.55c-.62 0-1.12-.5-1.12-1.12v-4.2c0-.61.5-1.11 1.12-1.11h9.55c.62 0 1.12.5 1.12 1.12v4.2c0 .6-.5 1.1-1.12 1.1ZM11.67 31H2.12C1.5 31 1 30.5 1 29.88v-4.2c0-.6.5-1.1 1.12-1.1h9.55c.62 0 1.12.5 1.12 1.1v4.2c0 .62-.5 1.12-1.12 1.12ZM30.96 31H21.4c-.61 0-1.11-.5-1.11-1.12v-4.2c0-.6.5-1.1 1.11-1.1h9.56c.61 0 1.11.5 1.11 1.1v4.2c0 .62-.5 1.12-1.11 1.12Z"
        stroke="#192C3E"
        strokeWidth="2"
      />
    </svg>
  );
}

IconOrgChart.defaultProps = {
  w: 32,
  h: 32,
};

export default IconOrgChart;
