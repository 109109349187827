import React from 'react';
import { Spacer } from '@chakra-ui/react';
import BaselaneButtonGroup from '../../BaselaneButtonGroup';
import BaselaneButton from '../../BaselaneButton';
import { actionTypeButtonGroupStyles } from '../styles/actionTypes.styles';

type ActionTypeButtonGroupProps = {
  isVertical: boolean,
  primary?: {
    label: string,
    size: string,
    onClick: Function,
    styles: Object,
  },
  secondary?: {
    label: string,
    size: string,
    onClick: Function,
    styles: Object,
  },
};

const ActionTypeButtonGroup = ({ primary, secondary, isVertical }: ActionTypeButtonGroupProps) => {
  const { actionbtnprimary, actionbtnsecondary } = actionTypeButtonGroupStyles;

  return (
    <>
      {!isVertical && <Spacer />}
      <BaselaneButtonGroup size="md" isReversed={!isVertical}>
        {primary && (
          <BaselaneButton
            {...{ ...actionbtnprimary, ...primary?.styles }}
            size={primary?.size || 'sm'}
            onClick={primary?.onClick}
          >
            {primary?.label}
          </BaselaneButton>
        )}
        {secondary && (
          <BaselaneButton
            {...{ ...actionbtnsecondary, ...secondary?.styles }}
            size={secondary?.size || 'sm'}
            onClick={secondary?.onClick}
          >
            {secondary?.label}
          </BaselaneButton>
        )}
      </BaselaneButtonGroup>
    </>
  );
};

ActionTypeButtonGroup.defaultProps = {
  primary: null,
  secondary: null,
};

export default ActionTypeButtonGroup;
