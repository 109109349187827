import React from 'react';
import { HStack, useMultiStyleConfig } from '@chakra-ui/react';
import BaselaneCardNew from '../BaselaneCard-new/BaselaneCardNew';

type BaselaneCardStackItem = {
  id: String,
  content: React.ReactNode,
};

type BaselaneCardStackProps = {
  id: String,
  items: Array<BaselaneCardStackItem>,
  size?: 'sm' | 'md' | 'lg',
};

const BaselaneCardStack = ({ id, items, size, ...rest }: BaselaneCardStackProps) => {
  const { stackContainer, cardContainer } = useMultiStyleConfig('BaselaneCardStack', { size });

  return (
    <BaselaneCardNew
      id={id}
      className="stack-container"
      {...stackContainer}
      variant="normal"
      size={size}
      {...rest}
    >
      {items.map((item, index) => (
        <HStack className="card-container" key={`card-stack-item-${item?.id}`} {...cardContainer}>
          {item?.content}
        </HStack>
      ))}
    </BaselaneCardNew>
  );
};

BaselaneCardStack.defaultProps = {
  size: 'md',
};

export default BaselaneCardStack;
