import React from 'react';
import { Text, Box, Stack } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import formatCurrency from '@core/utils/formatCurrency';
import stripCurrency from '@core/utils/stripCurrency';
import { reviewSubText, getOptionsTagsReview } from './helpers/review.helpers';
import {
  rowStyles,
  labelStyles,
  helpTextStyles,
  valueText,
} from '../AddFundsDrawer/styles/reviewTransfer.styles';

type ReviewTransferProps = {
  transfer: Object,
  optionalTags?: Object,
  propertyOptions?: Array,
  categoryOptions?: Array,
  categoryIdsMap?: Object,
};

function ReviewTransfer({
  transfer,
  optionalTags,
  propertyOptions,
  categoryOptions,
  categoryIdsMap,
}: ReviewTransferProps) {
  const { isMinXL } = getBreakPoints();

  const {
    selectedAccountFrom,
    selectedAccountTo,
    transferDate,
    transferAmount,
    transferType,
  } = transfer;

  const { name: fromName = '', nickName: fromNickName = '' } = selectedAccountFrom ?? {};
  const { name: toName = '', nickName: toNickName = '' } = selectedAccountTo ?? {};

  const stripAmount = stripCurrency(transferAmount);
  const tAmount = Number(stripAmount);

  const fromLabelText = transferType === 'TRANSFER_OUT' ? 'Transfer From' : 'From';
  const toLabelText = transferType === 'TRANSFER_OUT' ? 'Transfer To' : 'To';
  const amountLabelText = transferType === 'TRANSFER_OUT' ? 'Transfer Amount' : 'Amount';

  return (
    <>
      <Box mb={isMinXL ? '16px' : '32px'}>{reviewSubText[transferType]}</Box>
      <Stack direction={isMinXL ? 'row' : 'column'} {...rowStyles(isMinXL)}>
        <Text {...labelStyles(isMinXL)}>{fromLabelText}</Text>
        <Box>
          <Text {...valueText(isMinXL)}>{fromName}</Text>
          <Text {...helpTextStyles}>{fromNickName}</Text>
        </Box>
      </Stack>
      <Stack direction={isMinXL ? 'row' : 'column'} {...rowStyles(isMinXL)}>
        <Text {...labelStyles(isMinXL)}>{toLabelText}</Text>
        <Box>
          <Text {...valueText(isMinXL)}>{toName}</Text>
          <Text {...helpTextStyles}>{toNickName}</Text>
        </Box>
      </Stack>
      <Stack direction={isMinXL ? 'row' : 'column'} {...rowStyles(isMinXL)}>
        <Text {...labelStyles(isMinXL)}>Amount</Text>
        <Text {...valueText(isMinXL)}>{formatCurrency(tAmount).inDollars}</Text>
      </Stack>
      {transferType === 'TRANSFER_OUT' &&
        getOptionsTagsReview({
          optionalTags,
          propertyOptions,
          categoryOptions,
          isMinXL,
          categoryIdsMap,
        })}
      {transferType !== 'INTERNAL_TRANSFER' &&
        transferType !== 'TRANSFER_OUT' &&
        transferType !== 'TRANSFER_IN' && (
          <Stack {...rowStyles(isMinXL)}>
            <Text {...labelStyles(isMinXL)}>{amountLabelText}</Text>
            <Text {...valueText(isMinXL)}>{formatDate(transferDate)}</Text>
          </Stack>
        )}
    </>
  );
}

ReviewTransfer.defaultProps = {
  optionalTags: {},
  propertyOptions: null,
  categoryOptions: null,
  categoryIdsMap: {},
};

export default ReviewTransfer;
