// @flow
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '@contexts/UserContext';
import { Icon16Person, Icon16Logout, Icon16Newwindow, Icon16Chatbot } from '@icons/16px';
import { logoutOnClick } from '@core/components/NavigationAuth/helpers/auth.helpers';

import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import BaselaneMenu from '../../../BaselaneMenu';

const Profiles = () => {
  const { user, userPhotoUrl } = useContext(UserContext);
  const { firstName, lastName, email } = user ?? {};

  const menuLabel = !firstName && !lastName ? email : `${firstName || ''} ${lastName || ''}`;

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const navigate = useNavigate();
  const handleProfilesClick = () => {
    navigate('/profile');
  };
  const handleLogoutClick = () => {
    zendeskAPI('messenger', 'logoutUser');
    logoutOnClick();
  };

  const menuItems = [
    {
      id: 'profile_setting',
      name: 'Profile & settings',
      onClick: handleProfilesClick,
      icon: <Icon16Person />,
    },
    {
      id: 'help_center',
      name: 'Help center',
      as: 'a',
      href: 'https://support.baselane.com/hc/en-us',
      target: '_blank',
      icon: <Icon16Newwindow />,
    },
    {
      id: 'support',
      name: 'Virtual assistant',
      onClick: () => {
        zendeskAPI('messenger', 'open');
      },
      icon: <Icon16Chatbot />,
      hasDivider: true,
    },
    { id: 'log_out', name: 'Log out', onClick: handleLogoutClick, icon: <Icon16Logout /> },
  ];
  return (
    <BaselaneMenu
      listItems={menuItems}
      label={menuLabel}
      profilePhotoUrl={userPhotoUrl}
      placement="bottom-end"
      isProfile
    />
  );
};

export default Profiles;
