export const wrapperContainerStyles = {
  width: '100%',
  display: 'flex',
  height: '100%',
  minWidth: '200px',
};

export const containerStyles = {
  height: '100%',
  backgroundColor: 'brand.neutral.white',
  overflowY: 'auto',
  p: { xxs: '25px', xs: '48px', sm: ' 48px', md: '48px', lg: '48px', xl: '48px' },
};
