/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Stack, VStack } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import { Highlighted } from '../../../BaselaneDropdown/components/helpers/itemRenderer.helpers';

const AccountDropdownItemWithBalanceAndAccountType = ({ item, search }) => {
  return (
    <Stack gap={0} direction="row" justifyContent="space-between" width="100%">
      <Text color="inherit">
        <VStack alignItems="flex-start" gap={0} as="span">
          <Highlighted textStyle="sm" text={item?.name ?? item?.nickName} highlight={search} />
          {item?.accountSubType === 'savings' && item?.institutionName === 'Baselane' && (
            <Text as="span" fontSize="12px" color="brand.neutral.600" m={0}>
              Savings account
            </Text>
          )}
        </VStack>
      </Text>
      {item?.provider !== 'MANUAL' && (
        <Text color="inherit">{formatCurrency(item?.availableBalance ?? 0).inDollars}</Text>
      )}
    </Stack>
  );
};

export default AccountDropdownItemWithBalanceAndAccountType;
