export const drawerBodyStyles = (isMobile) => ({
  p: isMobile && '16px 24px',
  m: isMobile && '0',
  bgColor: isMobile && 'brand.neutral.white',
  overflow: isMobile && 'unset',
});

export const headingStyles = (isMobile) => ({
  fontSize: isMobile ? 'lg' : '2xl',
  mb: '0',
  lineHeight: isMobile ? '26px' : '32px',
  textAlign: 'left',
  alignSelf: 'flex-start',
  fontWeight: 'medium',
});

export const messageTextStyles = (isMobile) => ({
  textStyle: isMobile ? 'xs' : 'sm',
  w: '100%',
  color: 'brand.neutral.600',
  textAlign: 'left',
  mb: '24px !important',
});

export const iconContainerStyles = { w: '100%', justifySelf: 'flex-start', mt: '54px' };
