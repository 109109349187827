import { backgroundColors, fontColors } from '@shared/components/colors';

export const plaidConfirmationStyles = (isMobile) => ({
  display: isMobile ? 'block' : 'flex',
  justifyContent: isMobile ? 'flex-start' : 'center',
  width: '100%',
  height: '100%',
  boxSizing: 'borderBox',
  p: isMobile ? '16px' : '65px',
});

export const circleStyles = (iconColor) => ({
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  textAlign: 'center',
  verticalAlign: 'middle',
  color: fontColors[iconColor] || 'brand.blue.800A',
  bg: backgroundColors[iconColor] || 'brand.blue.100',
  mb: '32px',
  lineHeight: '46px',
});

export const iconCardStyles = {
  display: 'inline-block',
  verticalAlign: 'middle',
  lineHeight: '48px',
};

export const textBoldStyles = {
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
};

export const textStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  mb: '16px',
};

export const textSmallStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.500',
};

export const mobilePlaidWarningStyles = {
  borderTop: 'solid 1pt #E5E9EF',
  pt: '20px',
};

export const titleTextStyles = {
  lineHeight: '32px',
};

export const headerStyles = {
  alignItems: 'top',
};

export const listItemStyles = {
  display: 'flex',
  direction: 'row',
  mt: '16px',
};

export const listItemTextStyles = {
  color: 'brand.neutral.900',
  ml: '16px',
};
