import React from 'react';
import { Stack } from '@chakra-ui/react';
import { getFAQComponent } from './helpers/faq.helpers';
import { faqStyles } from './styles/faq.styles';

type BaselaneFAQProps = {
  faqContent: Object,
};

function BaselaneFAQ({ faqContent }: BaselaneFAQProps) {
  // Destructure Imported Styles
  const { container } = faqStyles;

  const FAQs = getFAQComponent(faqContent);

  return <Stack {...container}>{FAQs}</Stack>;
}

export default BaselaneFAQ;
