// @flow
import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

function BaselaneGrid({
  gridItems,
  templateColumns,
  templateRows,
  gap,
  gridStyles,
  gridItemStyles,
}: {
  gridItems: any[],
  templateColumns: string,
  templateRows: string,
  gap: number,
  gridStyles: Object,
  gridItemStyles: Object,
}): any {
  return (
    <Grid templateColumns={templateColumns} templateRows={templateRows} gap={gap} {...gridStyles}>
      {gridItems.map((item) => {
        const key = uuidv4();
        const element = item === null ? null : item.element;
        const itemStyles = {
          ...gridItemStyles,
          ...(item !== null && item.styles ? item.styles : {}),
        };
        return (
          <GridItem key={key} {...itemStyles}>
            {element}
          </GridItem>
        );
      })}
    </Grid>
  );
}

export default BaselaneGrid;
