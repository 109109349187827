import { truncatedText } from '@shared/styles/text.style';

export const placeholderStyles = {
  color: 'brand.neutral.500',
};

export const menuButtonStyles = (isValid) => ({
  ...truncatedText,
  fontSize: 'sm',
  lineHeight: '24px',
  paddingLeft: '16px',
  paddingRight: '16px',
  width: '100%',
  h: '48px',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: isValid ? '#e6e9ef' : 'red.800AA',
  textAlign: 'left',
  bg: 'brand.neutral.white',
  color: 'brand.neutral.700',
  _hover: {
    color: 'brand.neutral.600',
    border: '1px solid #C8D0DA',
    borderColor: isValid ? '#e6e9ef' : 'red.800AA',
  },
  _focus: {
    color: 'brand.neutral.600',
    boxShadow: 'inset 0px 0px 1px 1px #E1F4FF',
    borderColor: isValid ? '#C8D0DA' : 'red.800AA',
  },
  _active: {
    color: 'brand.neutral.700',
    borderColor: isValid ? 'brand.neutral.500' : 'red.800AA',
    boxShadow: 'none',
  },
  _disabled: {
    color: 'brand.darkBlue.250',
    borderColor: 'brand.darkBlue.100',
    boxShadow: 'none',

    _hover: {
      color: 'brand.darkBlue.250',
      borderColor: 'brand.darkBlue.100',
      boxShadow: 'none',
      cursor: 'default',
    },
  },
});

export const menuStyles = {
  boxShadow: '0px 4px 12px rgba(4, 39, 101, 0.12)',
  borderRadius: '0px 0px 4px 4px',
  borderColor: 'transparent',
  p: '16px',
  maxH: '350px',
  overflowY: 'auto',
};

export const menuItemStyles = {
  ...truncatedText,
  mb: '8px',
  p: '8px 12px',
  fontSize: 'xs',
  color: 'brand.neutral.500',
  lineHeight: '20px',
  width: '100%',
  h: '44px',
  borderRadius: '4px',
  _hover: {
    bg: 'brand.darkBlue.50',
    color: 'brand.neutral.600',
  },
  _focus: {
    bg: 'transparent',
    color: 'brand.neutral.600',
  },
  _active: {
    bg: 'transparent',
    color: 'brand.neutral.600',
  },
  _last: {
    mb: '0',
  },
};

export const activeMenuItemStyles = {
  color: 'brand.blue.700',
  bg: 'brand.blue.50',

  _hover: {
    color: 'brand.blue.700',
    bg: 'brand.blue.50',
  },
  _focus: {
    color: 'brand.blue.700',
    bg: 'brand.blue.50',
  },
  _active: {
    color: 'brand.blue.700',
    bg: 'brand.blue.50',
  },
};

export const menuGroupItemStyles = {
  ...truncatedText,
  mb: '8px',
  p: '8px 12px',
  h: '44px',
  fontSize: 'xs',
  color: 'brand.neutral.700',
  lineHeight: '20px',
  fontWeight: 'normal',
  marginInlineStart: '0',
};

export const menuIconStyles = {
  maxWidth: '12px !important',
  maxHeight: '8px !important',
};

export const optionLabelStyles = {
  ...truncatedText,
  textTransform: 'capitalize',
};
