import React from 'react';

type IconEyeProps = {
  width?: Number,
  height?: Number,
  color?: string,
};

function IconEye({ width = 24, height = 24, color = 'currentColor' }: IconEyeProps) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10c-.5304 0-1.0391.2107-1.4142.5858S10 11.4696 10 12c0 .5304.2107 1.0391.5858 1.4142S11.4696 14 12 14c.5304 0 1.0391-.2107 1.4142-.5858S14 12.5304 14 12c0-.5304-.2107-1.0391-.5858-1.4142S12.5304 10 12 10zm-2.82843-.82843C9.92172 8.42143 10.9391 8 12 8s2.0783.42143 2.8284 1.17157C15.5786 9.92172 16 10.9391 16 12s-.4214 2.0783-1.1716 2.8284C14.0783 15.5786 13.0609 16 12 16c-1.0609 0-2.07828-.4214-2.82843-1.1716C8.42143 14.0783 8 13.0609 8 12c0-1.0609.42143-2.07828 1.17157-2.82843z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51201 12C4.74752 15.4964 8.08323 18 12 18c3.9177 0 7.2525-2.5035 8.488-6C19.2525 8.50354 15.9177 6 12 6c-3.91677 0-7.25248 2.50359-8.48799 6zm-2.00807-.2996C2.90509 7.23851 7.07378 4 12 4c4.9273 0 9.0949 3.23856 10.4961 7.7004.0612.195.0612.4042 0 .5992C21.0949 16.7614 16.9273 20 12 20c-4.92622 0-9.09491-3.2385-10.49606-7.7004-.06124-.195-.06124-.4042 0-.5992z"
        fill={color}
      />
    </svg>
  );
}

IconEye.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};

export default IconEye;
