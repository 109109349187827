import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams, Outlet } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { ChakraProvider, VStack, useToast } from '@chakra-ui/react';

import { useMutation } from '@apollo/client';
import { BaselaneButton, BaselaneDrawer } from '@core/components/Shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import habitatTheme from '@core/themeHabitat';
import {
  UPDATE_PAYEE_PAYMENT_METHOD_ACH,
  ADD_PAYEE_PAYMENT_METHOD_ACH,
  GET_PAYEES,
} from '@core/apollo/queries';
import { Icon16Delete } from '@core/components/Icons/16px';
import {
  TRANSFERS_PAYMENT_METHOD_DELETE,
  TRANSFERS_RECIPIENT_DELETE,
} from '@core/constants/routes';
import { recipientACHPaymentValidation } from '../../helpers/validation.helpers';
import { onSubmitPaymentMethodACH } from '../../helpers/submission.helpers';
import PaymentMethodFieldsACH from '../fields/PaymentMethodFieldsACH';

const PaymentMethodDetailsACH = () => {
  const { isMax576 } = getBreakPoints();

  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location || {};
  const { from, isOnlyPaymentMethod } = state || '';
  const { paymentMethods } = state || {};
  const { achPaymentMethods } = paymentMethods || {};
  const { id, isDeletable } = achPaymentMethods || {};
  const { recipientId } = useParams();

  const formikRef = useRef();

  const [updatePaymentMethod, { loading: isUpdateACHPaymentMethodSaving }] = useMutation(
    UPDATE_PAYEE_PAYMENT_METHOD_ACH,
    {
      refetchQueries: [GET_PAYEES],
    }
  );

  const [addPaymentMethod, { loading: isAddPaymentMethodSaving }] = useMutation(
    ADD_PAYEE_PAYMENT_METHOD_ACH,
    {
      refetchQueries: [GET_PAYEES],
    }
  );

  const toast = useToast();

  const showUpdatePaymentMethodErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Error saving payment method.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const onSubmit = onSubmitPaymentMethodACH(
    from,
    id,
    recipientId,
    paymentMethods,
    navigate,
    updatePaymentMethod,
    addPaymentMethod,
    showUpdatePaymentMethodErrorToast
  );

  /* Note: initial form state. State passed to the location is applied if available. */
  const initialValues = {
    id,
    accountType: achPaymentMethods?.accountType || '',
    accountHolderName: achPaymentMethods?.accountHolderName || '',
    routingNumber: achPaymentMethods?.routingNumber || '',
    accountNumber: achPaymentMethods?.accountNumber || '',
  };

  const handleDelete = () => {
    if (isOnlyPaymentMethod) {
      navigate(TRANSFERS_RECIPIENT_DELETE, { state: { from: location?.pathname } });
    } else {
      navigate(TRANSFERS_PAYMENT_METHOD_DELETE, {
        state: { from: location?.pathname, paymentId: id },
      });
    }
  };

  // If there is no "from" then the page was reloaded and
  // there is no way to restore or use the data reliably
  // go back to the last place in history
  useEffect(() => {
    if (!from) {
      onDrawerClose(navigate);
    }
  }, [from]);

  return (
    <ChakraProvider theme={habitatTheme}>
      <Outlet />
      <Formik
        innerRef={formikRef}
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validate={recipientACHPaymentValidation}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          errors,
          touched,
          dirty,
          isSubmitting,
          isValid,
        }) => (
          <Form>
            <BaselaneDrawer
              isOpen
              size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
              title="ACH Details"
              hideOverlay
              footer={
                <>
                  <BaselaneButton
                    size="md"
                    variant="outline"
                    palette="neutral"
                    onClick={() => onDrawerClose(navigate, from)}
                  >
                    Cancel
                  </BaselaneButton>
                  <BaselaneButton
                    size="md"
                    variant="filled"
                    palette="primary"
                    onClick={handleSubmit}
                    w="100%"
                    ml={1.5}
                    isDisabled={
                      !isValid ||
                      !dirty ||
                      isUpdateACHPaymentMethodSaving ||
                      isAddPaymentMethodSaving
                    }
                  >
                    Save
                  </BaselaneButton>
                </>
              }
              closeEvent={() => onDrawerClose(navigate, from)}
              onOverlayClick={() => onDrawerClose(navigate, from)}
              closeOnOverlayClick={false}
              newDesignDrawer
            >
              <VStack
                h="full"
                alignItems="flex-start"
                justifyContent={id ? 'space-between' : 'flex-start'}
                gap={0}
              >
                <PaymentMethodFieldsACH />
                {id && (
                  <BaselaneButton
                    variant="transparent"
                    palette="danger"
                    leftIcon={<Icon16Delete />}
                    onClick={handleDelete}
                    size="md"
                    styles={{ mt: 'auto' }}
                    isDisabled={!isDeletable}
                    tooltipText={
                      !isDeletable &&
                      'Payment method cannot be deleted as there is a payment scheduled for it'
                    }
                  >
                    Delete payment method
                  </BaselaneButton>
                )}
              </VStack>
            </BaselaneDrawer>
          </Form>
        )}
      </Formik>
    </ChakraProvider>
  );
};

export default PaymentMethodDetailsACH;
