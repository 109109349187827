export const drawerHeaderStyles = (isMobile) => ({
  direction: !isMobile ? 'row' : 'column',
  ...(!isMobile
    ? {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
      }
    : {}),
  spacing: 0,
});

export const drawerBodyStyles = (isMobile) => ({
  p: isMobile ? '16px 24px' : '32px',
  m: isMobile && '0',
  bgColor: isMobile && 'brand.neutral.white',
});
