import React from 'react';
import { Stack } from '@chakra-ui/react';
import useLeaseBanks from '@pages/LeasesPage/hooks/useLeaseBanks';
import type { Bank } from '@pages/LeasesPage/types';
import { getBankAccounts } from '../../formHelpers/receivingBank.helper';
import LandlordBankingButtonCard from './LandlordBankingButtonCard';
import ExternalBankingButtonCard from './ExternalBankingButtonCard';
import LandlordBankingCard from './LandlordBankingCard';
import ExternalBankingCard from './ExternalBankingCard';

type ReceivingBankAccountEmptyStateProps = {
  addAccountManuallyDrawerRef: any,
  setHasConnectedBank: Function,
  setShowReceivingBAEmptyState: Function,
  refetchBankAccounts: Function,
  linkedAccountsDrawerOnClose?: Function,
  failedBankAccountId: String,
  isFromUpdateAccount: boolean,
};

const ReceivingBankAccountEmptyState = ({
  addAccountManuallyDrawerRef,
  setHasConnectedBank,
  setShowReceivingBAEmptyState,
  refetchBankAccounts,
  linkedAccountsDrawerOnClose,
  failedBankAccountId,
  isFromUpdateAccount,
}: ReceivingBankAccountEmptyStateProps) => {
  // bank data
  const { bankProps } = useLeaseBanks();
  const { banksData } = bankProps || {};

  const banks: Bank[] = banksData ? banksData.bank : [];
  // get all accounts including unconnected ones (ITEM_LOGIN_REQUIRED)
  const accounts = getBankAccounts(banks, true);

  const baselaneAccounts = accounts
    .filter((item) => item.name.indexOf('Baselane') > -1)
    .map((item) => {
      return { id: item.id, bankName: item.nickName || item.name, account: item.number };
    });

  const externalAccounts = accounts
    .filter((item) => item.name.indexOf('Baselane') === -1)
    .map((item) => {
      return { id: item.id, bankName: item.nickName || item.name, account: item.number };
    });

  const banksCount = {
    baselane: baselaneAccounts?.length,
    external: externalAccounts?.length,
  };

  return isFromUpdateAccount ? (
    <Stack>
      <LandlordBankingButtonCard {...{ isFromUpdateAccount, failedBankAccountId, banksCount }} />
      <ExternalBankingButtonCard
        {...{
          addAccountManuallyDrawerRef,
          refetchBankAccounts,
          setHasConnectedBank,
          setShowReceivingBAEmptyState,
          linkedAccountsDrawerOnClose,
          banksCount,
        }}
      />
    </Stack>
  ) : (
    <Stack>
      <LandlordBankingCard {...{ isFromUpdateAccount, failedBankAccountId, banksCount }} />
      <ExternalBankingCard
        {...{
          addAccountManuallyDrawerRef,
          refetchBankAccounts,
          setHasConnectedBank,
          setShowReceivingBAEmptyState,
          linkedAccountsDrawerOnClose,
          banksCount,
        }}
      />
    </Stack>
  );
};

ReceivingBankAccountEmptyState.defaultProps = {
  linkedAccountsDrawerOnClose: () => {},
};

export default ReceivingBankAccountEmptyState;
