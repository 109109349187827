/**
 * These are global overrides for Chakra components,
 * useful for workarounds.
 */
const styles = {
  global: {
    body: {
      bg: 'brand.neutral.white',
      color: 'brand.neutral.700' /* From theme variable -> neutral.700 */,
      fontFamily: '"Ease Variable", Helvetica, Arial',
      fontWeight: '100',
      fontVariantNumeric: 'normal',
    },
    /**
     * Classes to override Collapse's overflow:hidden
     * as it causes issues with scrollable child elements.
     */
    '.chakra-overflow-auto-override.chakra-collapse': {
      overflow: 'auto !important',
    },
    '.chakra-overflow-initial-override.chakra-collapse': {
      overflow: 'initial !important',
    },
    '.disclaimerlink': {
      _hover: {
        textDecoration: 'underline',
      },
    },
  },
};

export default styles;
