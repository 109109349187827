export const emptyStateCardStyles = {
  p: { lg: '36px 24px', base: '16px' },
  alignItems: 'flex-start',
  borderWidth: '1px',
  borderColor: 'brand.darkBlue.150',
  borderRadius: '4px',
  mt: { lg: '21px', base: '8px' },
};

export const sectionTitleStyles = {
  textStyle: { lg: 'headline-lg', base: 'headline-md' },
  color: 'brand.neutral.700',
};

export const mobileEmptyStateHeaderStyles = {
  textStyle: { lg: 'headline-lg', base: 'headline-md' },
  color: 'brand.neutral.700',
  background: 'brand.neutral.white',
  p: '20px 16px',
  borderBottomWidth: '1px',
  borderColor: 'brand.darkBlue.150',
};

export const sectionSubTitleStyles = {
  textStyle: 'headline-xs',
  color: 'brand.neutral.700',
  textTransform: 'uppercase',
};

export const buttonContainerStyles = {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: { lg: 'flex-end', base: 'space-around' },
  pt: { lg: '0', base: '8px' },
  h: '100% !important',
};

export const noMarginStyles = {
  m: '0 !important',
};

export const smLightTitleStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.500',
};

export const listStyles = {
  borderLeftWidth: '1px',
  borderLeftColor: 'brand.darkBlue.150',
  pl: '36px',
};

export const listItemStyles = {
  containerStyle: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    _notFirst: { mt: '11px !important' },
    minH: '24px',
  },
  labelStyle: {
    position: 'relative',
    color: 'brand.blue.700',
    mr: '24px',
  },
  valueStyle: {
    fontSize: 'sm',
    lineHeight: '20px',
    color: 'brand.neutral.700',
    fontWeight: 'normal',
  },
};

export const darkDescriptionTextStyles = {
  textStyle: 'sm',
  paddingTop: { lg: '0px', base: '8px' },
  color: 'brand.neutral.700',
};

export const listItemTextStyles = {
  fontSize: 'sm',
  lineHeight: '20px',
  color: 'brand.neutral.700',
  _notFirst: {
    mt: '11px',
  },
};

export const boldTextStyles = {
  fontWeight: 'semibold',
};

export const sectionSmTitleStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.700',
  mb: '10px',
  ml: '-18px',
};

export const drawerBodyStyles = (isMin768) => ({
  p: isMin768 ? '68px 64px 32px 64px' : '32px',
  display: 'flex',
  flexDirection: 'column',
});

export const containerStyle = (isMin768) => ({
  marginTop: isMin768 ? '62px' : '0',
  p: { md: '24px', base: '16px' },
});
