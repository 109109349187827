import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { Box, Image, Spinner, Stack, Text } from '@chakra-ui/react';
import { UNIFIED_RENT_COLLECTION } from '@routes';
import { BaselaneBadge, BaselaneButtonCard } from '@shared/components';
import { IconCheck } from '@icons';
import { sendRentCollectionStartedSegmentEvent } from '@pages/LeasesPage/hooks/useCreateLease';
import { generalRedirectToURC } from '@pages/UnifiedRentCollection/UnifiedLeaseUI/helpers/unifiedLeaseFlow.helpers';
import historyStateStorage from '@core/storage/historyStateStorage';
import houseImg from './assets/house.svg';
import {
  badgeBackgroundStyles,
  badgeFontStyles,
  buttonCardContentContainerStyles,
  buttonCardContentTextBodyStyles,
  buttonCardContentTextContainerStyles,
  buttonCardContentTextHeaderStyles,
  iconBackgroundStyles,
  nextStepsTextStyles,
  successMessageContainerStyles,
  successMessageTextStyles,
  spinnerStyles,
} from './styles/drawerBody.styles';

type SuccessDrawerBodyProps = {
  property: Object,
  handleCreateLeaseSuccess: Function,
  handleDrawerClose?: Function,
};

const SuccessDrawerBody = ({ property }: SuccessDrawerBodyProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { units = [] } = property || {};
  const hasManyUnits = units?.length && units?.length > 1;
  const { cache } = useApolloClient();

  const handleOnClick = () => {
    const state = { from: location.pathname };
    const pathname = UNIFIED_RENT_COLLECTION;

    if (!hasManyUnits) {
      historyStateStorage.write(state);

      navigate(pathname, {
        search: `?unit=${units[0].id}`,
      });
    } else {
      generalRedirectToURC({ navigate, pathname, state });
      sendRentCollectionStartedSegmentEvent(cache);
    }
  };

  return units.length > 0 ? (
    <>
      {' '}
      <Stack direction="row">
        <Stack direction="row" {...successMessageContainerStyles}>
          <Stack {...iconBackgroundStyles}>
            <IconCheck />
          </Stack>
          <Text {...successMessageTextStyles}>Your property has been successfully added</Text>
        </Stack>
      </Stack>
      <Text {...nextStepsTextStyles}>This is what you can do with your property:</Text>
      <BaselaneButtonCard onClick={handleOnClick}>
        <Stack direction="row" {...buttonCardContentContainerStyles}>
          <Image src={houseImg} alt="house" />
          <Box {...buttonCardContentTextContainerStyles}>
            <Text {...buttonCardContentTextHeaderStyles}>
              Set Up Rent Collection For This Property
            </Text>
            <Text {...buttonCardContentTextBodyStyles}>
              Get paid on time, let tenants pay on any device, and save hours every month with
              automated invoicing and bookkeeping.
            </Text>
          </Box>
          <BaselaneBadge
            type="badge"
            iconName="star"
            text="RECOMMENDED"
            backgroundStyles={badgeBackgroundStyles}
            fontStyles={badgeFontStyles}
          />
        </Stack>
      </BaselaneButtonCard>
    </>
  ) : (
    <Spinner {...spinnerStyles} />
  );
};

SuccessDrawerBody.defaultProps = {
  handleDrawerClose: () => {},
};

export default SuccessDrawerBody;
