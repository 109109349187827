// BaseLane: Responsive styles for dashboard widgets
export const dashboardPaddingStyles = (isMobile) => ({
  padding: !isMobile ? '0px' : '16px',
});

export const dashboardHeaderStyles = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
  pb: '16px',
};

export const oneUnitPaddingRight = {
  pr: '8px',
};

export const cashFlowContainerHeader = (isMobile, invertStyle) => ({
  containerStyles: {
    backgroundColor: !isMobile || !invertStyle ? 'brand.neutral.white' : 'brand.blue.900',
    color: 'brand.neutral.white',
  },
  customHeaderStyles: {
    borderBottomColor: !isMobile || !invertStyle ? 'brand.darkBlue.150' : 'brand.blue.400',
  },
});

export const cashFlowHeaderIcon = (noBankData) => ({
  mt: '4px',
  color: !noBankData ? 'brand.blue.250' : 'brand.neutral.500',
});

export const cashFlowTitle = (isMobile, invertStyle) => ({
  fontSize: !isMobile ? 'lg' : 'md',
  color: !isMobile || !invertStyle ? 'brand.neutral.700' : 'brand.neutral.white',
});

export const cashFlowSubtitle = (noBankData) => ({
  marginTop: '1px',
  textStyle: 'xs',
  color: !noBankData ? 'brand.darkBlue.300' : 'brand.neutral.600',
});

export const cashFlowDetailsLink = (isMobile, noBankData) => {
  let color;
  if (isMobile) {
    color = !noBankData ? 'brand.blue.250' : 'brand.blue.800A';
  } else {
    color = 'brand.blue.700';
  }

  return {
    fontSize: !isMobile ? 'sm' : 'xs',
    fontWeight: 'normal',
    color,
  };
};

export const generalHeaderIconStyles = {
  mt: '2px',
  color: 'brand.neutral.500',
  pr: '8px',
};

export const generalTitleStyles = (isMobile) => ({
  fontSize: !isMobile ? 'lg' : 'md',
  color: 'brand.neutral.700',
});

export const generalDetailsLinkStyles = (isMobile) => ({
  fontSize: !isMobile ? 'sm' : 'xs',
  fontWeight: 'normal',
  color: 'brand.blue.800A',
});

export const buttonStyles = {
  height: '32px',
  fontSize: 'xs',
  fontWeight: 'normal',
  width: '149px',
  lineHeight: '45px',
};

export const rocketLawyerBannerStyles = {
  m: '0 0 24px 0 !important',
  p: { base: '18px 24px', lg: '18px 16px', xl: '18px 24px' },
  fontSize: { lg: 'md' },
  bannerStyles: {
    rocketlawyerlogocontainer: {
      ml: '0 !important',
    },
    arrowiconcontainer: {
      display: 'none',
    },
  },
  styles: {
    buttoncard: {
      borderRadius: '4px',
    },
    primarycontent: {
      fontSize: { base: '2xl', lg: 'md', xl: '2xl' },
      lineHeight: {
        base: '32px',
        lg: '24px',
        xl: '32px',
      },
    },
    supportingcontent: {
      mb: { base: '16px', lg: '8px', xl: '16px' },
    },
    bottomrightcontent: {
      flexDirection: { base: 'row', lg: 'column', xl: 'row' },
      alignItems: { base: 'center', lg: 'flex-start', xl: 'center' },
      gap: { base: '4px', lg: '16px', xl: '4px' },
    },
  },
};
