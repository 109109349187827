import customTheme from '@core/theme';

export const sectionTitleTextStyles = {
  textStyle: 'headline-sm',
  color: customTheme.colors.brand.neutral['700'],
};

export const sectionBodyTextSmStyles = {
  textStyle: 'sm',
  color: customTheme.colors.brand.neutral['700'],
};

export const sectionBodyTextXsStyles = {
  textStyle: 'xs',
  color: customTheme.colors.brand.neutral['600'],
};
