export const stepTextStyles = (indicatorStatus) => {
  let color = 'brand.blue.400';
  if (indicatorStatus === 'caution') {
    color = 'red.800AA';
  } else if (indicatorStatus === 'warning') {
    color = 'yellow.900';
  } else if (indicatorStatus === 'heading') {
    color = 'brand.neutral.700';
  }

  return {
    color,
    textStyle: 'headline-xs',
    textTransform: 'uppercase',
  };
};

export const stepTextContainerStyles = {
  alignItems: 'center',
  mt: '16px',
  mb: '8px',
  spacing: '4px',
};

export const indicatorStyles = (indicatorStatus, isActiveArray, position) => {
  const isActive = isActiveArray[position];
  const activePosition = isActiveArray.findIndex((active) => active);

  let bg = 'brand.blue.700';
  if (indicatorStatus === 'warning' && isActive) {
    bg = 'yellow.700';
  } else if (indicatorStatus === 'caution' && isActive) {
    bg = 'red.800AA';
  } else if (!isActive && activePosition < position) {
    bg = 'brand.blue.250';
  }

  let borderRadius = 'initial';
  if (position === 0) {
    borderRadius = '4px 0 0 4px';
  } else if (position === 2 && isActive) {
    borderRadius = '0 4px 4px 0';
  }

  return {
    bg,
    h: isActive ? '8px' : '5px',
    width: '100%',
    flexBasis: '33.33%',
    borderRadius,
  };
};

export const indicatorContainerStyles = {
  w: '100%',
  h: '8px',
  alignItems: 'center',
  spacing: '4px',
};
