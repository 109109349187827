import React from 'react';
import { Box, Flex, ListItem, List, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { BaselaneButton, BaselaneDrawer } from '@shared/components';
import { IllustrationIdentityverification } from '@illustrations';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  vouchedVerificationDrawerDescription,
  vouchedVerificationSteps,
} from './constants/vouchedVerification.constants';

import {
  containerStyles,
  successIconContainerStyles,
  paragraphContainerStyles,
  textHeadingStyles,
  textStyles,
  orderedListStyles,
  bulletOrderedListStyles,
  listTextStyles,
  noteTextStyles,
} from './styles/vouchedDrawer.styles';

type VouchedInfoDrawerProps = {
  vouchedDrawerRef: any,
  handleVouchedDrawerClose: Function,
  clickVouched: Function,
};

const VouchedInfoDrawer = ({
  vouchedDrawerRef,
  handleVouchedDrawerClose,
  clickVouched,
}: VouchedInfoDrawerProps) => {
  const { isMax576, isMax767 } = getBreakPoints();
  const { isOpen } = useDisclosure();
  const { DrawerBody } = BaselaneDrawer;

  return (
    <BaselaneDrawer
      title={vouchedVerificationDrawerDescription.drawerTitle}
      isOpen={isOpen}
      size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
      onClose={handleVouchedDrawerClose}
      ref={vouchedDrawerRef}
      newDesignDrawer
      footer={
        <BaselaneButton
          variant="filled"
          palette="primary"
          size="md"
          onClick={clickVouched}
          isFullWidth
        >
          Verify Identity
        </BaselaneButton>
      }
    >
      <DrawerBody {...containerStyles}>
        <Stack>
          <Stack align="left" spacing={4}>
            <Stack {...successIconContainerStyles}>
              <IllustrationIdentityverification />
            </Stack>
            <Stack align="left" {...paragraphContainerStyles}>
              <Text {...textHeadingStyles}>{vouchedVerificationDrawerDescription.title}</Text>
              <Text {...textStyles}>{vouchedVerificationDrawerDescription.text}</Text>
            </Stack>

            <List>
              {vouchedVerificationSteps.map((e) => (
                <ListItem key={e.key} {...orderedListStyles}>
                  <Stack direction="row" spacing={1}>
                    <Box {...bulletOrderedListStyles}>{e.key}</Box>
                    <Flex {...listTextStyles(isMax576)}>{e.text}</Flex>
                  </Stack>
                </ListItem>
              ))}
            </List>
          </Stack>
          <Spacer />
          <Stack>
            <Text {...noteTextStyles}>{vouchedVerificationDrawerDescription.note}</Text>
          </Stack>
        </Stack>
      </DrawerBody>
    </BaselaneDrawer>
  );
};

export default VouchedInfoDrawer;
