export const pageContainerStyles = (
  user,
  isHideScroll,
  isShowHorizontalScrollOnDesktop,
  hidePadding,
  isUserinMobileMode,
  vhCalcMobile,
  isPropertiesMobilePage
) => {
  const calculatedMobileHeight = vhCalcMobile ?? '100% ';
  const height = isUserinMobileMode ? calculatedMobileHeight : 'calc(100% - 64px)';

  return {
    p:
      !hidePadding && !isPropertiesMobilePage
        ? { '3xl': '24px 32px', lg: '24px 32px', base: '0px' }
        : '0px',
    height: user ? height : '100%',
    position: 'relative',
    overflowX: isShowHorizontalScrollOnDesktop ? 'auto' : 'hidden',
    overflowY: isHideScroll ? 'hidden' : 'auto',
    overscrollBehavior: 'contain',
    spacing: 0,
  };
};

export const pageSettings = (user, isDesktop) => {
  return {
    w: '100%',
    h: '100%',
    boxSizing: 'border-box',
    pl: isDesktop && user ? { '3xl': '260px', xs: '80px' } : 0,
    pt: !isDesktop && user ? '64px !important' : '',
    spacing: 0,
  };
};
