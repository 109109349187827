import React from 'react';
import { AlertHeader, BaselaneAlert } from '@shared/components';
import { unsavedDetailsAlertFooter } from '../../../helpers/invoiceDetails.helper';

type AddInvoiceAlertsProp = {
  unsavedChangesAlert: Object,
};

const AddInvoiceAlerts = ({ unsavedChangesAlert }: AddInvoiceAlertsProp) => {
  return (
    <BaselaneAlert
      isOpen={unsavedChangesAlert.isUnsavedChangesAlertOpen}
      leastDestructiveRef={unsavedChangesAlert.cancelAlertRef}
      onClose={unsavedChangesAlert.onUnsavedChangesAlertClose}
      isCentered
      header={<AlertHeader title="You Have Unsaved Changes" />}
      body="Are you sure you want to exit without saving?"
      footer={unsavedDetailsAlertFooter(
        unsavedChangesAlert.cancelAlertRef,
        unsavedChangesAlert.onUnsavedChangesAlertClose,
        unsavedChangesAlert.handleContinueButtonClick
      )}
    />
  );
};

export default AddInvoiceAlerts;
