// @flow
const formatBytes = (bytes: number): Object => {
  if (!+bytes) return 0;

  const marker = 1024;
  const kiloBytes = marker;
  const megaBytes = marker * marker;

  return {
    kiloBytes: parseFloat((bytes / kiloBytes).toFixed(0)),
    megaBytes: parseFloat((bytes / megaBytes).toFixed(0)),
  };
};

export default formatBytes;
