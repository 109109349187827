import { defineStyleConfig } from '@chakra-ui/react';
import { size } from './input';

/**
 * This overrides Chakra's default <Textarea /> styles.
 *
 * see: node_modules/@chakra-ui/theme/src/components/textarea.ts for implementation details
 */

export const Textarea = defineStyleConfig({
  baseStyle: {
    paddingInlineStart: 1,
  },
  sizes: size,
});
