export const titleStyles = {
  textStyle: 'md-heavy',
  color: 'brand.neutral.900',
};

export const descriptionStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
};

export const buttonContainer = {
  my: { base: '16px', lg: '40px' },
  justifyContent: { base: 'center', sm: 'space-between' },
};
