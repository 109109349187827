import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

/**
 * This overrides Chakra's default <Drawer /> styles.
 *
 * see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/drawer.ts for implementation details
 */

const parts = ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'];
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Drawer = defineMultiStyleConfig({
  baseStyle: {
    header: {
      px: 3,
      py: 2,
    },
    closeButton: {
      top: 1,
      insetEnd: 1.5,
    },
    body: {
      px: 3,
      py: 1,
    },
    footer: {
      px: 3,
      py: 2,
    },
  },
  sizes: {
    xs: {
      dialog: { maxWidth: '248px' },
    },
    sm: {
      dialog: { maxWidth: '448px' },
    },
    newdawerxs: {
      dialog: { maxWidth: '312px' },
    },
    newdrawersm: {
      dialog: { maxWidth: '480px' },
    },
    newdrawermd: {
      dialog: { maxWidth: '600px' },
    },
    // size is universally used for mobile only
    newdrawerfull: {
      dialog: {
        maxWidth: '100%',
        height: 'calc(100% - 20px)',
        border: '1pt solid white',
        borderTopRadius: '12px',
      },
    },
    smd: {
      dialog: { maxWidth: '635px' },
    },
    md: {
      dialog: { maxWidth: '778px' },
    },
    lg: {
      dialog: { maxWidth: '896px' },
    },
    '2lg': {
      dialog: { maxWidth: '1108px' },
    },
    xl: {
      dialog: { maxWidth: '1344px' },
    },
    '2xl': {
      dialog: { maxWidth: '1792px' },
    },
  },
});
