import React from 'react';
import { Flex, VStack, Stack, Text, Heading, useMediaQuery } from '@chakra-ui/react';
import Features from '../Features';
import FormWrapper from '../Form/FormWrapper';
import ReferralBox from '../Referral/ReferralBox';

import {
  signupColumnContainerStyles,
  signupPrimaryColumnStyles,
  signupHeadingStyles,
  signupTextStyles,
  signupFinePrintContainerStyles,
} from '../../styles/signup.styles';
import { signupTestsSecondaryColumnStyles } from '../../styles/signupTests.styles';

type SignUpAProps = {
  referrer: String,
  isReferral: Boolean,
  signUpVariant: String,
};

const SignUpA = ({ referrer, isReferral, signUpVariant }: SignUpAProps) => {
  // this breakpoint is determined by the minimum height of the form, making sure that the
  // footer doesn't overlap it
  const [isDesktop] = useMediaQuery('(min-width: 1280px)', { ssr: false });

  const leftBlockHeight = isReferral ? '642px' : '690px';

  return (
    <Stack
      {...signupColumnContainerStyles}
      pt={{ base: 1.5, lg: 2, xl: 8 }}
      pb={{ base: 3, lg: 4, xl: 10 }}
      justifyContent="space-between"
      gap={0}
    >
      <Flex
        direction="column"
        {...signupPrimaryColumnStyles}
        justifyContent="center"
        minH={{ base: '100%', xl: leftBlockHeight }}
      >
        <Stack mb={{ base: '40px', xl: '0' }} gap={0}>
          {isReferral && <ReferralBox referrer={referrer} />}
          <Heading {...signupHeadingStyles}>Set up your rental finances for growth</Heading>
          <Text {...signupTextStyles} mt={2}>
            Get started in minutes.
          </Text>
        </Stack>
        {isDesktop && (
          <VStack {...signupFinePrintContainerStyles(isDesktop)} mt="40px">
            <Features isDesktop={isDesktop} />
          </VStack>
        )}
      </Flex>
      <VStack
        {...signupTestsSecondaryColumnStyles}
        minH={{ base: '100%', xl: leftBlockHeight }}
        justifyContent="space-between"
      >
        <FormWrapper isReferral={isReferral} signUpVariant={signUpVariant} />
      </VStack>
      {!isDesktop && (
        <VStack w="100%" mt="40px !important">
          {/* FDIC insured etc */}
          <VStack {...signupFinePrintContainerStyles(isDesktop)}>
            <Features isDesktop={isDesktop} />
          </VStack>
        </VStack>
      )}
    </Stack>
  );
};

export default SignUpA;
