import React, { useRef, useState } from 'react';
import { Box, HStack, Stack, Text, useDisclosure, Heading } from '@chakra-ui/react';
import { Icon16Checklist } from '@icons/16px';
import getBreakPoints from '@core/utils/getBreakPoints';
import { iconContainerTopStyles } from '@shared/styles/typePopupButtons.styles';
import { IllustrationBankingConnectExternalAccountWithPlaid } from '@illustrations';
import BaselaneExternalBankingTypePopup from '../BaselaneExternalBankingTypePopup';
import BaselaneButton from '../BaselaneButton';
import { BaselaneCardNew } from '../BaselaneCard-new';

type GeneralExternalBankingCardProps = {
  addAccountManuallyDrawerRef: any,
  handleExternalAccountConnectDrawerClose: Function,
};

const GeneralExternalBankingCard = ({
  addAccountManuallyDrawerRef,
  handleExternalAccountConnectDrawerClose,
}: GeneralExternalBankingCardProps) => {
  const {
    isOpen: isExternalBankingTypePopupOpen,
    onOpen: onExternalBankingTypePopupOpen,
    onClose: onExternalBankingTypePopupClose,
  } = useDisclosure();

  const [isVisibilityHidden, setIsVisibilityHidden] = useState(!isExternalBankingTypePopupOpen);
  const addExternalAccountDrawerRef = useRef();

  const handleActionButton = (type) => {
    if (type === 'manual') {
      onExternalBankingTypePopupClose();
      addAccountManuallyDrawerRef?.current?.open();
    }
  };

  const handleOpenExternalBankingPopup = () => {
    onExternalBankingTypePopupOpen();
    setIsVisibilityHidden(false);
  };

  const { isMax576 } = getBreakPoints();

  const item = {
    id: 1,
    text: 'Import transactions from your external bank accounts',
    icon: <Icon16Checklist />,
  };

  const addAccountProps = {
    isDirectToPlaid: true,
    hideButton: true,
    handleAddAccountSuccessCallback: () => setIsVisibilityHidden(true),
    addExternalAccountDrawerRef,
    handleDrawerCloseExternally: () => {
      handleExternalAccountConnectDrawerClose();
    },
  };

  return (
    <>
      <BaselaneExternalBankingTypePopup
        handleActionButton={handleActionButton}
        isOpen={isExternalBankingTypePopupOpen}
        onClose={onExternalBankingTypePopupClose}
        overlayStyles={{ visibility: isVisibilityHidden && 'hidden' }}
        containerStyles={{ visibility: isVisibilityHidden && 'hidden' }}
        addAccountProps={addAccountProps}
      />

      <BaselaneCardNew size="lg">
        <HStack spacing={2}>
          <Box {...iconContainerTopStyles}>
            <IllustrationBankingConnectExternalAccountWithPlaid />
          </Box>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Heading size="headline-md">Connect your external account</Heading>
              <HStack alignItems="flex-start">
                <Box {...{ pt: 0.25, w: '16px', h: '16px', color: 'brand.neutral.700' }}>
                  {item.icon}
                </Box>
                <Text textStyle="sm">{item.text}</Text>
              </HStack>
            </Stack>
            <Box w={isMax576 ? 'full' : 'auto'}>
              <BaselaneButton
                variant="tonal"
                palette="primary"
                size="md"
                onClick={handleOpenExternalBankingPopup}
              >
                Connect external account
              </BaselaneButton>
            </Box>
          </Stack>
        </HStack>
      </BaselaneCardNew>
    </>
  );
};

export default GeneralExternalBankingCard;
