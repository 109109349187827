import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Stack, Text } from '@chakra-ui/react';
import { getPropertiesUnits } from '@core/components/NativeBankingPage/MainContent/components/Accounts/helpers/linkedFor.helper';

export const getLinkedPropertiesAndUnits = (linkFor, linkedFor, leaseDetailProperties) => {
  const linkedData = linkedFor?.filter((link) => link.linkFor === linkFor) || [];
  const { property: properties = [] } = linkedData[0] || [];
  const groupedData =
    properties.length > 0
      ? properties.reduce((acc, property) => {
          const idExists = acc[property.id];
          if (!idExists) {
            acc[property.id] = property.propertyUnitId === null ? [] : [property.propertyUnitId];
            return acc;
          }

          if (property.propertyUnitId !== null) {
            acc[property.id] = acc[property.id].concat(property.propertyUnitId);
          }

          return acc;
        }, {})
      : [];

  const propertyUnitsObject = getPropertiesUnits(groupedData, leaseDetailProperties);
  if (Object.values(propertyUnitsObject).flat().length === 0) {
    return [];
  }
  return propertyUnitsObject;
};

const getUnitDescription = (unit) =>
  unit?.description === '' || !unit?.description ? '' : `, ${unit?.description}`;

export const generateList = (propertiesAndUnits) =>
  propertiesAndUnits.length
    ? propertiesAndUnits
        .map((item) => {
          const { property, units } = item;
          const propertyName = property?.name;

          if (units.length > 0 && property.units.length > 1) {
            return units.map((unit) => {
              const uniqId = uuidv4();

              return (
                <Stack direction="row" key={uniqId}>
                  <Text>
                    {propertyName}
                    {`, ${unit?.name}` || ''}
                    {getUnitDescription(unit)}
                  </Text>
                </Stack>
              );
            });
          }

          // if only one unit show property name and description if any
          if (units.length === 1 && property.units.length === 1) {
            const [oneUnit] = units;
            const uniqId = uuidv4();

            return (
              <Stack direction="row" key={uniqId}>
                <Text>
                  {propertyName}
                  {getUnitDescription(oneUnit)}
                </Text>
              </Stack>
            );
          }
          const uniqId = uuidv4();

          return (
            <Stack direction="row" key={uniqId}>
              <Text>{propertyName}</Text>
            </Stack>
          );
        })
        .flat()
    : '';
