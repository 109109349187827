// @flow
import React from 'react';
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';

import { IconSkeletonBar1 } from '@icons';
import { BaselaneHeaderCard, BaselaneOverview, EmptyText } from '@shared/components';

import isNumber from '../../utils/validators/isNumber';
import {
  summaryContainerStyles,
  customFontStyles,
  customBorderStyles,
  firstItemStyles,
  summaryContentStyles,
  summaryCardTitleStyles,
  summaryCardSubtitleStyles,
  currencyTextStyle,
  elementStyles,
} from './styles/responsive.style';

function PropertySummary({
  numProperties,
  numUnits,
  numVacantUnits,
}: {
  numProperties: Number,
  numUnits: Number,
  numVacantUnits: Number,
}): any {
  const [isDesktop] = useMediaQuery('(min-width: 899px)', { ssr: false });
  const summaryCardTitle = (
    <Flex direction="row">
      <Text {...summaryCardTitleStyles}>Summary</Text>
      <Box>
        <Text {...summaryCardSubtitleStyles}>| Your Properties in Baselane</Text>
      </Box>
    </Flex>
  );
  const emptyAmountBox = (
    <Box>
      <Box display={isDesktop ? 'block' : 'none'}>
        <EmptyText />
      </Box>
      <Box pt="8px">
        <IconSkeletonBar1 height="20" width="72" borderRadius="12px" />
      </Box>
    </Box>
  );
  const numberOfProperties = isNumber(numProperties) ? (
    <Text {...currencyTextStyle(isDesktop)}>{numProperties}</Text>
  ) : (
    emptyAmountBox
  );
  const numberOfUnits = isNumber(numUnits) ? (
    <Text {...currencyTextStyle(isDesktop)}>{numUnits}</Text>
  ) : (
    emptyAmountBox
  );
  const numberOfVacantUnits = isNumber(numVacantUnits) ? (
    <Text {...currencyTextStyle(isDesktop)}>{numVacantUnits}</Text>
  ) : (
    emptyAmountBox
  );

  const overview = [
    { title: 'Total Properties', content: numberOfProperties, style: elementStyles(isDesktop) },
    { title: 'Total Units', content: numberOfUnits },
    { title: 'Vacant Units', content: numberOfVacantUnits },
  ];
  return (
    <BaselaneHeaderCard
      containerStyles={summaryContainerStyles(isDesktop)}
      headerLeft={summaryCardTitle}
    >
      <BaselaneOverview
        customFontStyles={customFontStyles(isDesktop)}
        customBorderStyles={customBorderStyles(isDesktop)}
        elements={overview}
        firstItemStyles={firstItemStyles(isDesktop)}
        orientation={isDesktop ? 'horizontal' : 'vertical'}
        spacing="0"
        position="left"
        styles={summaryContentStyles(isDesktop)}
      />
    </BaselaneHeaderCard>
  );
}

export default PropertySummary;
