import React from 'react';

type IconArrowUpCircleFilledProps = {
  w?: string,
  h?: string,
  color?: string,
};

function IconArrowUpCircleFilled({ w, h, color }: IconArrowUpCircleFilledProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill={color} />
      <path
        d="M8.0498 4.31481c.24035 0 .43519.19484.43519.43519v7.5c0 .2403-.19484.4352-.43518.4352-.24035 0-.43519-.1949-.43519-.4352v-7.5c0-.24035.19484-.43519.43518-.43519Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth=".62963"
      />
      <path
        d="M11 6.5 8.04452 3.54452c-.02459-.02459-.06445-.02459-.08904 0L5 6.5"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

IconArrowUpCircleFilled.defaultProps = {
  w: '16',
  h: '16',
  color: 'currentColor',
};

export default IconArrowUpCircleFilled;
