// @flow
import React, { useContext, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import BanksContext from '@contexts/BanksContext';
import StatementTableHeader from './StatementTableHeader';
import {
  DEFAULT_FILTERS,
  getAccounts,
  getMonths,
  getYears,
  getLastDay,
  getYearFromTo,
} from './helpers/statement.helper';
import Filters from './Filters';
import StatementTableBody from './StatementTableBody';

function Statements() {
  const { banks } = useContext(BanksContext);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const baselaneAccounts = banks.filter((bank) => !bank.isExternal);
  const accountOptions = getAccounts(baselaneAccounts);
  const monthOptions = getMonths();
  const yearOptions = getYears();

  const [account, setAccount] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();

  const onSort = (sort) => {
    const updatedFilter = { ...filters, page: DEFAULT_FILTERS.page, sort };
    setFilters(updatedFilter);
  };

  const onLoadMore = () => {
    const newFilters = {
      ...filters,
      page: filters.page + 1,
    };

    setFilters(newFilters);
  };

  const onYearChange = (newYear) => {
    let { fromDate, toDate } = filters.filter;
    const { label: updatedYear } = newYear;
    const { id: mon } = month || {};
    const currentMonth = mon && mon !== 0 ? mon.toString().padStart(2, '0') : mon;

    if (!currentMonth && !updatedYear) {
      fromDate = '';
      toDate = '';
    } else if (!currentMonth && updatedYear) {
      fromDate = `${getYearFromTo(updatedYear, yearOptions, true)}-01-01`;
      toDate = `${getYearFromTo(updatedYear, yearOptions)}-12-31`;
    } else if (currentMonth && updatedYear) {
      const toYear = getYearFromTo(updatedYear, yearOptions);
      fromDate = `${getYearFromTo(updatedYear, yearOptions, true)}-${currentMonth}-01`;
      toDate = `${toYear}-${currentMonth}-${getLastDay(toYear, currentMonth)}`;
    }

    if (updatedYear === 'All') {
      fromDate = `${getYearFromTo(updatedYear, yearOptions, true)}-01-01`;
      toDate = `${getYearFromTo(updatedYear, yearOptions)}-12-31`;
    }

    const updatedFilter = { ...filters, filter: { ...filters.filter, fromDate, toDate } };
    setFilters(updatedFilter);

    if (updatedYear === 'All') setMonth('All');
    setYear(newYear);
  };

  const onMonthChange = (newMonth) => {
    let { fromDate, toDate } = filters.filter;
    const { id: mon } = newMonth;
    const { label: currentYear } = year || {};
    const updatedMonth = mon !== 0 ? mon.toString().padStart(2, '0') : mon;

    if (!updatedMonth && !currentYear) {
      fromDate = '';
      toDate = '';
    } else if (!updatedMonth && currentYear) {
      fromDate = `${getYearFromTo(currentYear, yearOptions, true)}-01-01`;
      toDate = `${getYearFromTo(currentYear, yearOptions)}-12-31`;
    } else if (updatedMonth && currentYear) {
      const toYear = getYearFromTo(currentYear, yearOptions);
      fromDate = `${getYearFromTo(currentYear, yearOptions, true)}-${updatedMonth}-01`;
      toDate = `${toYear}-${updatedMonth}-${getLastDay(toYear, updatedMonth)}`;
    } else if (updatedMonth && !currentYear) {
      const toYear = getYearFromTo(currentYear, yearOptions);
      fromDate = `${getYearFromTo(currentYear, yearOptions, true)}-${updatedMonth}-01`;
      toDate = `${toYear}-${updatedMonth}-${getLastDay(toYear, updatedMonth)}`;
    }

    const updatedFilter = { ...filters, filter: { ...filters.filter, fromDate, toDate } };
    setFilters(updatedFilter);
    setMonth(newMonth);
  };

  const onAccountChange = (newAccounts) => {
    const {
      group: { groupId },
      id,
      label,
    } = newAccounts;

    let bankAccountId =
      groupId === id
        ? accountOptions.filter((o) => o.group.groupId === groupId).map((acc) => acc.id)
        : [id];

    if (label === 'All') bankAccountId = [];

    const updatedFilter = { ...filters, filter: { ...filters.filter, bankAccountId } };
    setFilters(updatedFilter);
    setAccount(newAccounts);
  };

  const filterFunctions = {
    onYearChange,
    onMonthChange,
    onAccountChange,
  };

  const filterValues = {
    account,
    month,
    year,
  };

  const filterOptions = { accountOptions, monthOptions, yearOptions };

  return (
    <>
      <Box w="100%" h="100%">
        <Filters {...{ filterFunctions, filterValues, filterOptions }} />
        <StatementTableHeader {...{ onSort, filters }} />
        <StatementTableBody
          {...{
            accountOptions,
            filters,
            banks,
            onLoadMore,
          }}
        />
      </Box>
      <Outlet />
    </>
  );
}

export default Statements;
