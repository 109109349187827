import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { CurrencyText } from '@shared/components';
import { footerLabelTextStyles, footerAmountStyles } from '../styles/splitTrxFooter.styles';

const Footer = ({
  totalAmount,
  remainingAmount,
}: {
  totalAmount: Number,
  remainingAmount: Number,
}) => {
  return (
    <Stack spacing={0} borderTopWidth={1} borderTopColor="brand.darkBlue.250">
      <Stack direction="row" py="10px" justifyContent="space-between">
        <Text {...footerLabelTextStyles}>Total</Text>
        <CurrencyText
          isRounded={false}
          textStyle="sm"
          negativeColor="brand.neutral.700"
          amount={totalAmount}
          {...footerAmountStyles('total')}
        />
      </Stack>
      <Stack direction="row" py="10px" justifyContent="space-between">
        <Text {...footerLabelTextStyles}>Remaining to Split</Text>
        <CurrencyText
          isRounded={false}
          textStyle="sm"
          negativeColor="red.700"
          amount={remainingAmount}
          {...footerAmountStyles(remainingAmount)}
        />
      </Stack>
    </Stack>
  );
};

export default Footer;
