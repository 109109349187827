import React from 'react';
import { Box } from '@chakra-ui/react';
import { parentListItemStyles } from './styles/parentListItem.styles';
import BaselaneDivider from '../BaselaneDivider';

type ParentListItemProps = {
  title: String,
  listItem: React.Node,
  children?: React.Node,
  styles?: Object,
  showDivider?: Boolean,
  index?: number,
};

const ParentListItem = ({
  title,
  listItem,
  children,
  showDivider,
  index,
  styles,
}: ParentListItemProps) => {
  return (
    <>
      {showDivider && index !== 0 && children && children?.length > 0 && (
        <BaselaneDivider styles={{ my: 1, color: 'brand.darkBlue.100' }} />
      )}
      {title ? (
        <Box
          key={`parent-list-${title}`}
          {...parentListItemStyles(children?.length > 0)}
          {...styles}
        >
          {listItem}
        </Box>
      ) : null}
      {children}
    </>
  );
};

ParentListItem.defaultProps = {
  children: null,
  styles: {},
  showDivider: false,
  index: null,
};

export default ParentListItem;
