import React, { useEffect } from 'react';
import moment from 'moment';
import { FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import PAYMENT_TYPE from '../../../models/PaymentTypeEnum';
import {
  DUE_DATE_OPTIONS,
  firstDateIsSmaller,
  getOnDateOptions,
} from '../../formHelpers/leaseTermForm.helper';
import { fieldContainer } from '../../styles/shared.styles';

type RentDueDateInputProps = {
  name: string,
  errors: Object,
  touched: Object,
  values: Object,
  formInitialValues: Object,
  handleChange: Function,
  handleBlur: Function,
  setFieldTouched: Function,
  setFieldValue: Function,
  setFormVariables: Function,
  rentId: string,
  rentName: string,
  isDisabled: boolean,
  noRCStartOptions: boolean,
  setRCStartOptions: Function,
  setNoRCStartOptions: Function,
  invoicesForUnit: Array,
};

function RentDueDateInput({
  name,
  errors,
  touched,
  values,
  formInitialValues,
  handleChange,
  handleBlur,
  setFieldTouched,
  setFieldValue,
  setFormVariables,
  rentId,
  rentName,
  isDisabled,
  noRCStartOptions,
  setRCStartOptions,
  setNoRCStartOptions,
  invoicesForUnit,
}: RentDueDateInputProps) {
  const handleChangeDueDays = (e) => {
    let dueDaysInNum = Number(e.target.value);
    if (!dueDaysInNum) {
      dueDaysInNum = Number(moment(values.startDate).format('DD'));
    }
    const sortedInvoices = invoicesForUnit
      ?.filter(
        (item) =>
          (item.name === 'RENT' || item.name === 'RENT_AND_FEE') &&
          (item.state === 'UPCOMING' || item.state === 'SCHEDULED' || item.state === 'FAILED')
      )
      ?.sort(
        (a, b) =>
          moment(a?.originalDueDate).format('YYYYMMDD') -
          moment(b?.originalDueDate).format('YYYYMMDD')
      );
    const firstUnpaidInvoiceDate = sortedInvoices && sortedInvoices[0]?.dueDate;
    const firstPossibleStartDate =
      firstUnpaidInvoiceDate && firstDateIsSmaller(values.startDate, firstUnpaidInvoiceDate)
        ? firstUnpaidInvoiceDate
        : values.startDate;
    const newRCStartOptions = getOnDateOptions(
      dueDaysInNum,
      moment(firstPossibleStartDate),
      values.endDate,
      false,
      values.isMonthToMonth
    );

    setRCStartOptions(newRCStartOptions);
    setNoRCStartOptions(!newRCStartOptions || newRCStartOptions.length === 0);
    // if the current rc start option is not valid, as in, not in the array of options, we change that as well
    const valid = newRCStartOptions.find(
      (item) => item.dateValue === values.rentCollectionStartDate
    );
    setFieldTouched('dueDays', true);
    if ((!valid || values?.rentCollectionStartDate === null) && newRCStartOptions[0]?.dateValue) {
      setFieldValue('rentCollectionStartDate', newRCStartOptions[0]?.dateValue);
      setFormVariables({
        payments: [{ id: rentId, name: PAYMENT_TYPE[rentName], dueDays: dueDaysInNum }],
        rentCollectionStartDate: newRCStartOptions[0]?.dateValue,
      });
    } else {
      setFormVariables({
        payments: [{ id: rentId, name: PAYMENT_TYPE[rentName], dueDays: dueDaysInNum }],
      });
    }
    handleChange(e);
  };

  useEffect(() => {
    const { endDate, dueDays, isMonthToMonth, startDate } = formInitialValues;
    let dueDaysInNum = Number(dueDays);
    if (!dueDaysInNum) {
      dueDaysInNum = Number(moment(startDate).format('DD'));
    }
    const sortedInvoices = invoicesForUnit
      ?.filter(
        (item) =>
          (item.name === 'RENT' || item.name === 'RENT_AND_FEE') &&
          (item.state === 'UPCOMING' || item.state === 'SCHEDULED' || item.state === 'FAILED')
      )
      ?.sort(
        (a, b) =>
          moment(a?.originalDueDate).format('YYYYMMDD') -
          moment(b?.originalDueDate).format('YYYYMMDD')
      );
    const firstUnpaidInvoiceDate = sortedInvoices && sortedInvoices[0]?.dueDate;
    const firstPossibleStartDate =
      firstUnpaidInvoiceDate && firstDateIsSmaller(startDate, firstUnpaidInvoiceDate)
        ? firstUnpaidInvoiceDate
        : startDate;
    const newRCStartOptions = getOnDateOptions(
      dueDaysInNum,
      moment(firstPossibleStartDate),
      endDate,
      false,
      isMonthToMonth
    );

    setRCStartOptions(newRCStartOptions);
    setNoRCStartOptions(!newRCStartOptions || newRCStartOptions.length === 0);
    if (!formInitialValues.rentCollectionStartDate) {
      setFormVariables({
        rentCollectionStartDate: newRCStartOptions[0]?.dateValue,
      });
      setFieldValue('rentCollectionStartDate', newRCStartOptions[0]?.dateValue);
    }
    setFieldValue('dueDays', dueDaysInNum);
    setFieldTouched('dueDays', true);
  }, [formInitialValues?.dueDays]);

  return (
    <FormControl
      {...fieldContainer}
      mb="0px"
      isInvalid={touched.dueDays && (noRCStartOptions || errors.dueDays)}
    >
      <FormLabel {...formLabelStyles.xs} htmlFor={name}>
        Rent is due on
      </FormLabel>
      <Select
        {...formInputStyles}
        id={name}
        {...{ name }}
        value={values.dueDays || 1}
        onChange={handleChangeDueDays}
        onBlur={handleBlur}
        p="0"
        isDisabled={isDisabled}
        color={values.dueDays ? 'brand.neutral.700' : 'brand.neutral.500'}
      >
        {DUE_DATE_OPTIONS.map((o) => (
          <option key={o.id} value={o.id}>
            {o.label}
          </option>
        ))}
      </Select>
      {!noRCStartOptions && (
        <FormErrorMessage {...formErrorStyles}>{errors.dueDays}</FormErrorMessage>
      )}
      {noRCStartOptions && (
        <FormErrorMessage {...formErrorStyles}>
          No Rent Collection Start date within selected period and due day.
        </FormErrorMessage>
      )}
    </FormControl>
  );
}

export default RentDueDateInput;
