import React from 'react';

function IconXCircle() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.08" cx="12" cy="12" r="12" fill="#C93A3A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.52729 7.52827C7.78764 7.26792 8.20975 7.26792 8.4701 7.52827L11.9987 11.0569L15.5273 7.52827C15.7876 7.26792 16.2098 7.26792 16.4701 7.52827C16.7305 7.78862 16.7305 8.21073 16.4701 8.47108L12.9415 11.9997L16.4701 15.5283C16.7305 15.7886 16.7305 16.2107 16.4701 16.4711C16.2098 16.7314 15.7876 16.7314 15.5273 16.4711L11.9987 12.9425L8.4701 16.4711C8.20975 16.7314 7.78764 16.7314 7.52729 16.4711C7.26694 16.2107 7.26694 15.7886 7.52729 15.5283L11.0559 11.9997L7.52729 8.47108C7.26694 8.21073 7.26694 7.78862 7.52729 7.52827Z"
        fill="#C93A3A"
      />
    </svg>
  );
}

export default IconXCircle;
