const colors = {
  brand: {
    blue: {
      900: '#0056AF',
      '800A': '#0968BF', // accent color
      700: '#257ED0',
      600: '#348AD8',
      500: '#50A0E8',
      400: '#77BEFE',
      300: '#8CCFFF',
      250: '#9FD8FF',
      200: '#BEE5FF',
      100: '#E1F4FF',
      50: '#ECF9FF',
    },
    darkBlue: {
      900: '#001843',
      '800A': '#012550', // accent color
      700: '#10325B',
      600: '#1D3D63',
      500: '#3A5678',
      400: '#566E8B',
      300: '#ACB8C7',
      250: '#C8D0DA',
      200: '#DADFE6',
      150: '#E5E9EF',
      100: '#F0F2F6',
      50: '#FAFBFD',
    },
    neutral: {
      900: '#000619',
      800: '#091A2D',
      700: '#192C3E',
      600: '#3A4B5B',
      500: '#6C7884',
      400: '#9AA3AB',
      300: '#C4C9CE',
      250: '#D4D8DC',
      200: '#E7E9EB',
      150: '#F0F1F3',
      100: '#F5F5F7',
      50: '#FCFCFD',
      white: '#FFFFFF',
    },
  },
  green: {
    900: '#306E0E',
    '800AA': '#398311', // accessible accent
    700: '#449B14',
    600: '#4EB317',
    '500A': '#56C719', // accent color
    400: '#70D03C',
    300: '#8AD95F',
    250: '#A5E384',
    200: '#BEEBA6',
    100: '#D9F5CA',
    50: '#F1FDEA',
  },
  red: {
    900: '#C41205',
    '800AA': '#C93A3A', // accessible accent
    700: '#E04141',
    600: '#E75757',
    '500A': '#EA6868', // accent color
    400: '#F09393',
    300: '#F4B0AF',
    250: '#F7C5C4',
    200: '#FCE3E2',
    100: '#FDEEED',
    50: '#FEF7F6',
  },
  yellow: {
    900: '#92630C',
    '800AA': '#986D1D', // accessible accent
    700: '#CD9800',
    600: '#F9BC3B',
    '500A': '#FAC748', // accent color
    400: '#FCD87F',
    300: '#FCE3A3',
    250: '#FDE9B6',
    200: '#FEF4DA',
    100: '#FEF8E9',
    50: '#FFFCF6',
  },

  // used at the moment for progress bar chakra component
  scheme: {
    blue: {
      500: '#0968BF', // blue.800A
    },
    lightBlue: {
      500: '#9FD8FF', // blue.250
    },
    grey: {
      500: '#C4C9CE', // neutral.300
    },
  },

  // only used at the moment in sign up page
  marketing: {
    midnight: {
      100: '#0B2033',
      90: '#182D40',
    },
  },
};

export default colors;
