/**
 * Drawer close behaviour - navigates back to origin while replacing current history state, to not pollute it
 * @param {Navigate} navigate the useNavigate function from react-router-dom
 * @param {string} [from] the pathname of the originating screen we are to return to
 */

const onDrawerClose = (navigate, from = null) => {
  if (from && from?.length > 0) {
    navigate(
      {
        pathname: `${from}`,
      },
      {
        replace: true,
      }
    );
  } else {
    // fallback: navigate to last place in history, if all else fails
    navigate(-1);
  }
};

export default onDrawerClose;
