import { gql, useMutation } from '@apollo/client';

const useGenerateRecurringPayments = () => {
  const GENERATE_RECURRING_PAYMENTS = gql`
    mutation GenerateRecurringPayments($input: GenerateRecurringPatternInput!) {
      generateRecurringPayment(input: $input) {
        startDate
        endDate
        recurrencePattern {
          type
          interval
          monthlyByDate
          lastDayOfMonth
          daysOfMonth
          daysOfWeek
          numberOfPayments
        }
        transfers {
          transferDate
          amount
          actualDate
          isHoliday
          isWeekend
          state
        }
        nextPaymentDate
        lastPaymentDate
      }
    }
  `;

  const [generateRecurringPayments, { data, loading, error }] = useMutation(
    GENERATE_RECURRING_PAYMENTS
  );

  if (error) {
    return null;
  }
  return {
    generateRecurringPayments,
    data,
    loading,
    error,
  };
};

export default useGenerateRecurringPayments;
