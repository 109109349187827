/* eslint-disable no-nested-ternary */
import React from 'react';
import { Stack, HStack, Text, Spacer, Spinner, Progress } from '@chakra-ui/react';
import { IconCheckCircle } from '@icons';
import getBreakPoints from '@core/utils/getBreakPoints';
import DisableMoveMoney from '@core/components/DisableMoveMoney';
import useUserSignUpExperiment from '@core/hooks/useUserSignUpExperiment';

import { Completed, Inactive } from './Steps';
import StepsToggler from './StepsToggler';
import { STATE_ENUM } from '../helpers/trackers.helpers';
import {
  trackerContainerStyles,
  TRACKER_BAR_COLOR,
  trackerBarStyles,
  trackerBarTextStyles,
  trackerAllDoneTextStyles,
  trackerHeaderStyles,
  trackerHeaderRightStyles,
  trackerHeaderTitleStyles,
  spinnerStyles,
} from '../styles/tracker.styles';

type TrackerProps = {
  tracker: Object,
  steps: Object,
  isLoading: boolean,
  showSteps: boolean,
  setShowSteps: Function,
};

const Tracker = ({ tracker, steps, isLoading, showSteps, setShowSteps }: TrackerProps) => {
  const { data, getLinkTo } = tracker;
  const { isMax768: isMobile } = getBreakPoints();
  const { isUserEmailUnverified } = useUserSignUpExperiment();

  const stepsCompleted = steps.filter((step) => STATE_ENUM[step.state] === 'completed');
  const isDone = stepsCompleted.length === steps.length;
  const showProgressBar = (isMobile && !isDone) || !isMobile;
  const progress = stepsCompleted.length === 0 ? 0 : 100 / (steps.length / stepsCompleted.length);

  const getSteps = () => {
    const inProgStepFirstIndex = steps.findIndex((step) => STATE_ENUM[step.state] !== 'completed');

    return steps.map((step, index) => {
      const { state, name } = step;
      const isMoveMoneySteps = name === 'FUND_YOUR_ACCOUNT' || name === 'ACTIVATE_DEBIT_CARD';
      const isDisabled =
        STATE_ENUM[state] === 'disabled' || (isMoveMoneySteps && isUserEmailUnverified);

      return isMobile && !showSteps
        ? {
            ...step,
            show: index === inProgStepFirstIndex,
            isDisabled,
          }
        : {
            ...step,
            show: true,
            isDisabled,
          };
    });
  };

  return (
    <Stack {...trackerContainerStyles(isMobile, showSteps)}>
      {isLoading ? (
        <Spinner {...spinnerStyles} />
      ) : (
        <>
          {/* Header */}
          <HStack {...trackerHeaderStyles(isMobile, isDone)}>
            {data.icon}
            <Stack {...trackerHeaderRightStyles}>
              <HStack>
                <Text {...trackerHeaderTitleStyles}>{data.title}</Text>
                {isDone && isMobile && (
                  <>
                    <Spacer />
                    <Text {...trackerAllDoneTextStyles}>All done!</Text>
                    <IconCheckCircle color="#257ED0" />
                  </>
                )}
              </HStack>

              {showProgressBar && (
                <HStack m="0 !important">
                  <Progress
                    value={progress}
                    colorScheme={TRACKER_BAR_COLOR}
                    {...trackerBarStyles}
                  />
                  <Text {...trackerBarTextStyles}>{Math.round(progress)}%</Text>
                </HStack>
              )}
            </Stack>
          </HStack>

          {/* Steps */}
          {getSteps(steps).map((element) => {
            const { name, state, show, isDisabled } = element;
            if (!show) return null;

            const stepState = STATE_ENUM[state];
            const step = data.elements?.[name];
            const status = step?.[stepState];
            const link = getLinkTo(name, stepState);
            const activeStep =
              stepState === 'completed' ? <Completed {...{ key: name, status }} /> : link && link;
            const inactiveElement =
              name === 'FUND_YOUR_ACCOUNT' || name === 'ACTIVATE_DEBIT_CARD' ? (
                <DisableMoveMoney
                  key={name}
                  tooltipText={`Verify your email to ${
                    name === 'FUND_YOUR_ACCOUNT' ? 'add funds' : 'activate card'
                  }`}
                  styles={{ flex: 0 }}
                  renderItem={(disabled) => <Inactive step={step} isDisabled={disabled} />}
                />
              ) : (
                <Inactive key={name} step={step} />
              );
            return isDisabled ? inactiveElement : activeStep;
          })}

          {/* StepsTogger - Show only on Mobile */}
          {isMobile && !isDone && <StepsToggler {...{ showSteps, setShowSteps }} />}
        </>
      )}
    </Stack>
  );
};

export default Tracker;
