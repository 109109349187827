// @flow

import * as React from 'react';
import { VStack, HStack, Stack, Heading, Text } from '@chakra-ui/react';
import BannerChevron from './components/BannerChevron';
import BaselaneButton from '../BaselaneButton';

import {
  defaultOuterContainerStyles,
  innerContainerStyles,
  contentContainerStyles,
  iconContainerStyles,
  textContainerStyles,
  buttonContainerStyles,
  textStyles,
  headingStyles,
  indicatorDiscStyles,
} from './styles/baselaneResponsiveBanner.style';

type BaselaneResponsiveBannerProps = {
  /**
   * @prop {any} - Icon component to show on the left side of the banner
   */
  icon: any,
  /**
   * @prop {string} - Title text to display
   */
  title: String,
  /**
   * @prop {String} - Description text to display
   */
  description: String,
  /**
   * @prop {String} - Color of the indicator graphic behind the icon
   * @default 'brand.blue.50'
   */
  indicatorColor?: String,
  /**
   * @prop {Function} - Handler function that makes the whole banner clickable, and displays a chevron if supplied
   * @default null
   */
  onBannerClick?: Function,
  /**
   * @prop {Function} - Handler function that displays a dismiss button if supplied
   * @default null
   */
  onDismissClick?: Function,
  /**
   * @prop {String} - Text to customize the dismiss button label (default is 'Dismiss')
   * @default 'Dismiss'
   */
  dismissLabel?: String,
  /**
   * @prop {String} - Text to customize the call to action button
   * @default null
   */
  callToActionButtonType?: String,
  /**
   * @prop {any} - Icon string prop to determine button type
   * @default null
   */
  callToActionLabel?: String,
  /**
   * @prop {any} - Icon component to show on the left side of the call to action button
   * @default null
   */
  callToActionLeftIcon?: any,
  /**
   * @prop {any} - Icon component to show on the right side of the call to action button
   * @default null
   */
  callToActionRightIcon?: any,
  /**
   * @prop {Function} - Handler function that displays a call to action button if supplied
   * @default null
   */
  onCallToActionClick?: Function,
  styles?: Object,
};

/**
 * Renders a generic, configurable responsive banner.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveBanner = ({
  icon,
  title,
  description,
  indicatorColor,
  onBannerClick,
  onDismissClick,
  dismissLabel,
  callToActionButtonType,
  callToActionLabel,
  callToActionLeftIcon,
  callToActionRightIcon,
  onCallToActionClick,
  styles,
  ...rest
}: BaselaneResponsiveBannerProps) => {
  const hasDismissButton = !!onDismissClick;
  const hasCallToActionButton = !!(callToActionLabel && onCallToActionClick);

  return (
    <Stack
      {...{
        ...defaultOuterContainerStyles,
        ...indicatorDiscStyles(indicatorColor),
        onClick: onBannerClick || (() => {}),
        cursor: onBannerClick ? 'pointer' : 'normal',
        ...rest,
      }}
    >
      <Stack {...innerContainerStyles}>
        <Stack {...contentContainerStyles}>
          <Stack {...iconContainerStyles}>{icon}</Stack>
          <VStack {...textContainerStyles}>
            <Heading as="h4" {...{ ...headingStyles, ...styles?.title }}>
              {title}
            </Heading>
            <Text {...{ ...textStyles, ...styles?.description }}>{description}</Text>
          </VStack>
        </Stack>
        {(hasDismissButton || hasCallToActionButton) && (
          <HStack {...buttonContainerStyles}>
            {hasDismissButton && (
              <BaselaneButton variant="tonal" palette="primary" size="sm" onClick={onDismissClick}>
                {dismissLabel}
              </BaselaneButton>
            )}
            {hasCallToActionButton && (
              <BaselaneButton
                variant={callToActionButtonType || 'filled'}
                palette="primary"
                onClick={onCallToActionClick}
                leftIcon={callToActionLeftIcon}
                rightIcon={callToActionRightIcon}
                size="sm"
              >
                {callToActionLabel}
              </BaselaneButton>
            )}
          </HStack>
        )}
      </Stack>
      {onBannerClick && <BannerChevron />}
    </Stack>
  );
};

BaselaneResponsiveBanner.defaultProps = {
  indicatorColor: 'brand.blue.50',
  onBannerClick: null,
  onDismissClick: null,
  dismissLabel: 'Dismiss',
  onCallToActionClick: null,
  callToActionButtonType: null,
  callToActionLabel: null,
  callToActionLeftIcon: null,
  callToActionRightIcon: null,
  styles: ({}: { ... }),
};

export default BaselaneResponsiveBanner;
