import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Box, HStack, Text, Stack, useDisclosure, Heading } from '@chakra-ui/react';
import { Icon16Sparkle, Icon16Accounts, Icon16LifetimeInterest, Icon16Plus } from '@icons/16px';
import { DASHBOARD, UNIFIED_LB_PERSONAL_INFO } from '@routes';
import historyStateStorage from '@core/storage/historyStateStorage';
import { GET_KYC_URL } from '@core/components/NativeBankingPage/queries';
import { IllustrationBankingNewaccount } from '@illustrations';
import { iconContainerTopStyles } from '@shared/styles/typePopupButtons.styles';
import getBreakPoints from '@core/utils/getBreakPoints';

import BaselaneApplicationTypePopup from '../BaselaneApplicationTypePopup';
import BaselaneButton from '../BaselaneButton';
import { BaselaneCardNew } from '../BaselaneCard-new';

const GeneralLandlordBankingCard = ({ from }: { from: string }) => {
  const {
    isOpen: isBaselaneApplicationTypePopupOpen,
    onOpen: onBaselaneApplicationTypePopupOpen,
    onClose: onBaselaneApplicationTypePopupClose,
  } = useDisclosure();

  const navigate = useNavigate();

  const [getKYCUrl] = useLazyQuery(GET_KYC_URL, {
    onCompleted: ({ unitOnBoarding }) => window.open(unitOnBoarding, '_self'),
    onError: (kycUrlError) => console.error(kycUrlError),
  });

  const handleActionButton = (type) => {
    const pathname = UNIFIED_LB_PERSONAL_INFO;

    // if user opened lb flow from another flow from state will be an object
    const state = {
      from: from ?? DASHBOARD,
    };

    if (type === 'business') {
      getKYCUrl();
    } else {
      historyStateStorage.write(state);
      navigate(pathname);
    }
  };

  const handleOpenLandlordBankingPopup = () => {
    onBaselaneApplicationTypePopupOpen();
  };

  const { isMax576 } = getBreakPoints();

  const landLordBankingSummary = [
    {
      id: 1,
      text: 'Auto-tag transactions with tax categories and rental properties',
      icon: <Icon16Sparkle />,
    },
    {
      id: 2,
      text: 'Unlimited checking accounts and virtual cards to organize your finances',
      icon: <Icon16Accounts />,
    },
    {
      id: 3,
      text: 'Up to 4.19% APY on savings accounts',
      icon: <Icon16LifetimeInterest />,
    },
  ];

  return (
    <>
      <BaselaneApplicationTypePopup
        handleActionButton={handleActionButton}
        isOpen={isBaselaneApplicationTypePopupOpen}
        onClose={onBaselaneApplicationTypePopupClose}
      />

      <BaselaneCardNew size="lg">
        <HStack spacing={2}>
          <Box {...iconContainerTopStyles}>
            <IllustrationBankingNewaccount />
          </Box>
          <Stack spacing={2}>
            <Stack spacing={1.5}>
              <Heading size="headline-md">
                Automate your bookkeeping with a Baselane Banking acount
              </Heading>
              {landLordBankingSummary.map((item) => (
                <HStack key={item.id} alignItems="flex-start">
                  <Box {...{ pt: 0.25, w: '16px', h: '16px', color: 'brand.neutral.700' }}>
                    {item.icon}
                  </Box>
                  <Text textStyle="sm">{item.text}</Text>
                </HStack>
              ))}
            </Stack>

            <Box w={isMax576 ? 'full' : 'auto'}>
              <BaselaneButton
                id="baselane-banking-button"
                size="md"
                variant="filled"
                palette="primary"
                w={isMax576 ? 'full' : 'auto'}
                leftIcon={<Icon16Plus />}
                onClick={handleOpenLandlordBankingPopup}
              >
                Create account
              </BaselaneButton>
            </Box>
          </Stack>
        </HStack>
      </BaselaneCardNew>
    </>
  );
};

export default GeneralLandlordBankingCard;
