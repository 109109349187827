// @flow
import { React } from 'react';
import { Box, Stack, useRadio } from '@chakra-ui/react';

// 1. Create a component that consumes the `useRadio` hook
function BaselaneRadioCard({
  styles,
  containerStyles,
  checkedStyles,
  focusedStyles,
  activeStyles,
  disabledStyles,
  onClick,
  isDisabled,
  id,
  ...rest
}: {
  styles?: Object,
  containerStyles?: Object,
  checkedStyles?: Object,
  focusedStyles?: Object,
  activeStyles?: Object,
  disabledStyles?: Object,
  isDisabled?: boolean,
  onClick?: Function,
  id: string,
}): any {
  const { getInputProps, getRadioProps } = useRadio(rest);
  const { children } = rest;

  const rcStyles = {
    h: '140px',
    w: '104px',
    cursor: 'pointer',
    borderWidth: '1px',
    borderRadius: '4px',
    justifyContent: 'center',
    color: 'brand.neutral.500',
    borderColor: 'brand.neutral.500',
    p: '4px',
    my: '8px',
  };

  const rcCheckedStyles = {
    bg: 'brand.blue.50',
    color: 'brand.blue.800A',
    borderColor: 'brand.blue.800A',
  };

  const rcFocusedStyles = {
    boxShadow: 'outline',
  };

  const radioCardStyles = { ...rcStyles, ...styles };
  const radioCardCheckedStyles = { ...rcCheckedStyles, ...checkedStyles };
  const radioCardFocusedStyles = { ...rcFocusedStyles, ...focusedStyles };
  const radioCardActiveStyles = { ...activeStyles };
  const radioCardDisabledStyles = { ...disabledStyles };

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box id={id} as="label" _notLast={{ mr: '24px' }} {...containerStyles}>
      <input {...input} disabled={isDisabled} />
      <Stack
        tabIndex="0" /* Workaround for enabling focus/blur events in Safari */
        {...checkbox}
        {...radioCardStyles}
        {...{ onClick }}
        _checked={radioCardCheckedStyles}
        _focus={radioCardFocusedStyles}
        _focusVisible={radioCardFocusedStyles}
        _active={radioCardActiveStyles}
        _disabled={radioCardDisabledStyles}
        disabled={isDisabled}
      >
        {children}
      </Stack>
    </Box>
  );
}

BaselaneRadioCard.defaultProps = {
  styles: ({}: { ... }),
  containerStyles: ({}: { ... }),
  checkedStyles: ({}: { ... }),
  focusedStyles: ({}: { ... }),
  activeStyles: ({}: { ... }),
  disabledStyles: ({}: { ... }),
  isDisabled: false,
  onClick: () => {},
};

export default BaselaneRadioCard;
