export const creditCardContainerStyles = {
  position: 'relative',
  width: '276px;',
  height: '165px',
  background:
    'radial-gradient(60.3% 60.3% at 20.47% 39.7%, #0968BF 1.09%, #0968BF 45.51%, #0968BF 100%)',
  boxShadow: '0px 1.5px 0px #77BEFE, 0px 15px 15px rgba(2, 93, 228, 0.35), 0px 1.75px 0px #77BEFE',
  borderRadius: '8px',
};

export const creditCardPromotionalContainerStyles = {
  overflow: 'visible',
  position: 'relative',
  width: '276px;',
  height: '165px',
  background:
    'linear-gradient(169deg, rgba(13,87,242,1) 0%, rgba(13,87,242,1) 50%, rgba(10,66,184,1) 50%, rgba(10,66,184,1) 100%);',
  borderRadius: '8px',
  zIndex: '2',
};

export const creditCardSharpShadows = {
  _before: {
    content: "''",
    bg: 'rgba(13, 87, 242, 0.30000001192092896)',
    position: 'absolute',
    width: '85%',
    height: '85%',
    bottom: '-8px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '8px',
    zIndex: '1',
  },
  _after: {
    content: "''",
    bg: 'rgba(13, 87, 242, 0.10000000149011612)',
    position: 'absolute',
    width: '75%',
    height: '75%',
    bottom: '-16px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '8px',
    zIndex: '1',
  },
};

export const creditCardIconStyles = {
  position: 'absolute',
  right: '18px',
  top: '30px',
};

export const creditCardTextStyles = {
  position: 'absolute',
  left: '16px',
  top: '26px',
  textStyle: 'sm',
  fontStyle: 'normal',
  color: '#FFFFFF',
  textShadow:
    '0px 2px 1px rgba(10, 66, 150, 0.2), 0px 2px 6px rgba(0, 61, 152, 0.4), 0px 1px 0px #8CCFFF',
};

export const creditCardAccountStyles = {
  position: 'absolute',
  left: '16px',
  top: '74px',
  fontStyle: 'normal',
  textStyle: 'sm',
  color: 'brand.neutral.white',
  textShadow:
    '0px 2px 1px rgba(10, 66, 150, 0.2), 0px 2px 6px rgba(0, 61, 152, 0.4), 0px 1px 0px #8CCFFF',
};

export const cardNumberEachDigitStyles = {
  display: 'inline-block',
  w: '10px',
  textAlign: 'center',
};

export const creditCardValueStyles = {
  position: 'absolute',
  left: '16px',
  top: '94px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '2xl',
  lineHeight: '32px',
  color: 'brand.neutral.white',
  textShadow:
    '0px 2px 1px rgba(10, 66, 150, 0.4), 0px 2px 6px rgba(0, 61, 152, 0.4), 0px 1.5px 0px #8CCFFF',
};

export const creditCardInTransferStyles = {
  position: 'absolute',
  right: '17px',
  top: '102px',
  fontStyle: 'normal',
  textStyle: 'sm',
  color: 'brand.neutral.white',
  textShadow:
    '0px 2px 1px rgba(10, 66, 150, 0.4), 0px 2px 6px rgba(0, 61, 152, 0.4), 0px 1.5px 0px #8CCFFF',
};

export const creditCardSubtitleLeftStyles = {
  position: 'absolute',
  left: '16px',
  top: '126px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '3xs',
  lineHeight: '14px',
  color: 'brand.blue.250',
};

export const creditCardSubtitleRightStyles = {
  position: 'absolute',
  left: '196px',
  top: '126px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '3xs',
  lineHeight: '14px',
  color: 'brand.blue.250',
};

export const creditCardWalletStyles = {
  position: 'absolute',
  width: '288px',
  height: '121px',
  left: '-6px',
  top: '56px',
  background: 'linear-gradient(180deg, #0056AF 0%, #192C3E 100%)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderTopLeftRadius: '2px',
  borderTopRightRadius: '2px',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
};

export const creditCardWalletThreadsStyles = {
  position: 'absolute',
  width: '280px',
  height: '109px',
  left: '4px',
  top: '3px',
  background: 'transparent',
  border: '1px dashed #192C3E',
  borderTopLeftRadius: '2px',
  borderTopRightRadius: '2px',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
};

export const creditCardWalletCircleStyles = {
  position: 'absolute',
  width: '36px',
  height: '10px',
  left: '126px',
  top: '-4px',
  background: 'brand.blue.800A',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
};

export const creditCardWalletCircleThreadsStyles = {
  position: 'absolute',
  width: '44px',
  height: '18px',
  left: '122px',
  top: '-8px',
  background: 'transparent',
  borderBottom: '1px dashed #192C3E',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
};

export const creditCardWalletTitleStyles = {
  position: 'absolute',
  width: '100%',
  textAlign: 'center',
  top: '32px',
  textStyle: 'headline-lg',
  color: 'brand.blue.300',
};

export const creditCardWalletSubtitleStyles = {
  position: 'absolute',
  width: '100%',
  textAlign: 'center',
  top: '68px',
  textStyle: 'xs',
  color: 'brand.neutral.500',
};

export const creditCardGrayedOutStyles = {
  background: 'brand.neutral.500',
};

export const creditCardGrayedOutWalletCircleStyles = {
  background: 'brand.neutral.500',
};
