export const predictionsContainerStyles = {
  bg: 'brand.neutral.white',
  mt: '4px',
  zIndex: '1',
  w: '100%',
  borderRadius: '4px',
  boxShadow: '0px 3px 5px rgba(0, 46, 81, 0.05)',
  p: '12px',
};

export const containerStyles = (showPredictionsAbove) => ({
  display: 'flex',
  flexDirection: showPredictionsAbove ? 'column-reverse' : 'column',
  bottom: showPredictionsAbove ? '-20px' : 'initial',
  position: showPredictionsAbove ? 'absolute' : 'initial',
  width: '100%',
});

export const predictionItemStyles = (highlightedIndex, selectedItem, p, index) => ({
  backgroundColor: highlightedIndex === index ? 'brand.darkBlue.50' : '#ffffff',
  color:
    selectedItem === p || highlightedIndex === index ? 'brand.neutral.600' : 'brand.neutral.500',
  padding: '12px',
  textStyle: 'xs',
  cursor: 'pointer',
});
