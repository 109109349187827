export const autoSaveStyles = {
  text: ({ isNoteUpdated }) => {
    return {
      color: 'brand.neutral.500',
      textStyle: 'xs',
      mb: '8px !important',
      visibility: isNoteUpdated ? 'visible' : 'hidden',
    };
  },
  spinner: {
    size: 'xs',
    color: 'brand.darkBlue.150',
  },
};
