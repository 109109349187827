export const getLearnMoreDrawerStyles = (isMinXL) => {
  return {
    drawer: {
      p: '0',
      mb: { base: '25%', sm: '25%', md: '25%', lg: 0, xl: 0 }, // In mobile and tablet, browser's bottom bar overlaps the drawer. So, we need to add some margin to the bottom of the drawer.
    },
    content: {
      titlesection: {
        container: {
          gap: '8px',
          m: isMinXL ? '32px 0 18px !important' : '0 0 18px 0 !important',
        },
        title: {
          textStyle: 'headline-lg',
          color: 'brand.neutral.900',
          m: '0 !important',
        },
        description: {
          textStyle: 'sm',
          color: 'brand.neutral.700',
          m: '0 !important',
        },
        imageSubText: {
          textStyle: 'xs',
          color: 'brand.neutral.700',
          m: '8px 0 0 !important',
          textAlign: 'center',
        },
      },
      bodysection: {
        container: {
          mt: '32px',
          gap: '8px',
        },
        bodytext: {
          textStyle: 'sm',
          color: 'brand.neutral.700',
          m: '4px 0 0 !important',
          fontWeight: 'normal',
        },
        bodytitle: {
          textStyle: 'sm',
          color: 'brand.neutral.700',
          m: '0 !important',
          fontWeight: 'semibold',
        },
      },
      statuslist: {
        titlecontainer: {
          gap: '8px',
        },
        title: {
          textStyle: 'headline-sm',
          color: 'brand.neutral.700',
          m: '0 !important',
        },
        description: {
          textStyle: 'sm',
          color: 'brand.neutral.600',
          m: '0 !important',
        },
      },
    },
  };
};
