import process from 'process';

async function fetchBaselaneBankCustomerData(bank) {
  const { unitCustomerId: customerId = '' } = bank?.unitAccount ?? {};

  return {
    bank,
    response: await fetch(
      `${process?.env?.REACT_APP_UNIT_SERVICE_URL}/data-collection/customer/${customerId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: `Bearer ${process?.env?.REACT_APP_UNIT_TOKEN}`,
        },
        body: JSON.stringify({
          data: {
            type: 'dataCollection',
            attributes: {
              lang: 'en',
            },
          },
        }),
      }
    ),
  };
}

async function fetchData({
  bankData,
  setBanksThatNeedDataCollection,
  setIsLoadingDataCollectionList,
}) {
  setIsLoadingDataCollectionList(true);
  const baselaneUnitAccountBanks = bankData.filter(
    (b) => b?.unitAccount?.unitCustomerId && b?.unitAccount?.unitCustomerId !== null
  );

  const responses = await Promise.all(
    baselaneUnitAccountBanks.map((bank) => fetchBaselaneBankCustomerData(bank))
  );

  const results = await Promise.all(
    responses.map(async ({ bank, response }) => ({
      bank,
      ...(await response.json()),
    }))
  );
  const filteredResults = results.filter(
    (result) => result?.data?.attributes?.isRequired && result?.data?.attributes?.url
  );
  setBanksThatNeedDataCollection(filteredResults);
  setIsLoadingDataCollectionList(false);
  const doesDCPopupFlagExist = localStorage.getItem('DCPopupFlag');
  if (doesDCPopupFlagExist === null) {
    localStorage.setItem('DCPopupFlag', filteredResults.length > 0);
  }
}

export { fetchData, fetchBaselaneBankCustomerData };
