export const TITLE = 'Baselane';

export const TITLES_MAP = [
  {
    path: '/',
    title: '',
  },
  {
    path: '/login',
    title: 'Sign In',
  },
  {
    path: '/signin',
    title: 'Sign In',
  },
  {
    path: '/signup',
    title: 'Sign Up',
  },
  {
    path: '/phone',
    title: 'Secure Account',
  },
  {
    path: '/signup/create_account',
    title: 'Create Account',
  },
  {
    path: '/forgot_password',
    title: 'Forgot Password',
  },
  {
    path: '/reset_password',
    title: 'Reset Password',
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
  },
  {
    path: '/transactions',
    title: 'Transactions',
  },
  {
    path: '/get_started',
    title: 'Get Started',
  },
  {
    path: '/transfers_payments/activity',
    title: 'Transfer & Payments | Activity',
  },
  {
    path: '/transfers_payments/recipients',
    title: 'Transfer & Payments | Recipients',
  },
  {
    path: '/cashflow',
    title: 'Analytics & Reporting',
  },
  {
    path: '/cashflow/statement',
    title: 'Analytics & Reporting',
  },
  {
    path: '/cashflow/tax_package',
    title: 'Analytics & Reporting',
  },
  {
    path: '/kpis',
    title: 'Property Metrics',
  },
  {
    path: '/leases',
    title: 'Rent Collection',
  },
  {
    path: '/properties',
    title: 'Properties',
  },
  {
    path: '/tenants',
    title: 'Tenants',
  },
  {
    path: '/bankcards',
    title: 'Banks & Cards',
  },
  {
    path: '/referral',
    title: 'Referral',
  },
  {
    path: '/profile',
    title: 'Profile & Settings',
  },
  {
    path: '/profile/update_accounts',
    title: 'Profile & Settings',
  },
  {
    path: '/banking',
    title: 'Baselane Banking',
  },
  {
    path: '/banking/accounts',
    title: 'Baselane Banking',
  },
  {
    path: '/banking/cards',
    title: 'Baselane Banking',
  },
  {
    path: '/banking/transfer_payments',
    title: 'Baselane Banking',
  },
  {
    path: '/banking/statements',
    title: 'Baselane Banking',
  },
  {
    path: '/externalaccounts',
    title: 'External Accounts',
  },
  {
    path: '/externalaccounts/my_accounts',
    title: 'External Accounts',
  },
  {
    path: '/externalaccounts/third_party_accounts',
    title: 'External Accounts',
  },
  {
    path: '/insurance',
    title: 'Rental Property Insurance',
  },
  {
    path: '/loans',
    title: 'Loans',
  },
];
