import React from 'react';
import { Text, Spacer, HStack, Radio } from '@chakra-ui/react';
import { BaselaneAccordion } from '@shared/components';

function AutoTagTableHeader({ properties }: { properties: any }): any {
  return (
    properties.length > 0 &&
    properties.map((p) => {
      const headerElements = (
        <HStack w="100%" key={p.id}>
          <Text>{p.name}</Text>
          <Spacer />
          <Radio value={p.id} />
        </HStack>
      );
      const contentElements =
        p.units.length > 1 &&
        p.units.map((u) => {
          return (
            <HStack
              key={u.id}
              w="auto"
              h="48px"
              ml="48px"
              _hover={{ bg: 'brand.blue.50' }}
              _notLast={{ borderBottomWidth: '1px', borderBottomColor: 'brand.darkBlue.150' }}
            >
              <Radio
                name={u.id}
                value={`${p.id}-${u.id}`}
                h="48px"
                pr="24px"
                flexDirection="row-reverse"
                justifyContent="space-between"
                isFullWidth
              >
                {u.name}
              </Radio>
            </HStack>
          );
        });
      return p.units.length > 1 ? (
        <BaselaneAccordion
          key={p.id}
          headerElements={headerElements}
          contentElements={contentElements}
          containerStyles={{ my: '8px' }}
          contentStyles={{ p: 0, pt: '8px !important' }}
          buttonStyles={{ bg: 'brand.blue.50', h: '48px', borderRadius: '4px' }}
        />
      ) : (
        <HStack key={p.id} borderRadius="4px" p="12px 24px" my="8px" bg="brand.blue.50">
          {headerElements}
        </HStack>
      );
    })
  );
}

export default AutoTagTableHeader;
