// @flow
import React from 'react';
import { Thead } from '@chakra-ui/react';
import BaselaneResponsiveTableRow from './BaselaneResponsiveTableRow';

type BaselaneResponsiveTableHeaderProps = {
  /**
   * Collection of data that is used for each heading of the table.
   */
  items: Object,
  /**
   * Render function that defines how one item from the data is applied to the contents of a heading.
   */
  renderItem: any,
};

/**
 * Renders a set of BaselaneResponsiveTableHeading, based on supplied items and the renderItem.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BaselaneResponsiveTableHeader = ({
  items,
  renderItem,
  ...rest
}: BaselaneResponsiveTableHeaderProps) => {
  return (
    <Thead>
      <BaselaneResponsiveTableRow isHeader {...rest}>
        {items.map((item, index) => {
          return renderItem(item, index);
        })}
      </BaselaneResponsiveTableRow>
    </Thead>
  );
};
export default BaselaneResponsiveTableHeader;
