import React, { useEffect, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { TwoFactorVerificationPopUp, useTwoFactor } from '@shared/components';
import { manageStorageAfterLogout } from '@core/storage/helpers/cleanup.helpers';
import { OTPProvider } from '@core/contexts/OTPContext';
import formatPhoneNumber from '@core/utils/formatPhoneNumber';
import * as ROUTES from '@routes';
import { trackPreLoginOTP } from '@features/Authentication/helpers/tracking.helpers';
import NoPhoneNumberPopup from './NoPhoneNumberPopup';

type LoginOTPProps = {
  setIsLoading: Function,
  setIsAlertOpen: Function,
  user: Object,
  claims: Object,
  handleSuccess: Function,
};

const LoginOTP = ({ setIsLoading, setIsAlertOpen, user, claims, handleSuccess }: LoginOTPProps) => {
  const [userPhoneNumber, setUserPhoneNumber] = useState(null);
  const { states, stateFunctions } = useTwoFactor(false);
  const { isVerifyLoading } = states;
  const navigate = useNavigate();

  const {
    onOTPPopupOpen,
    setOTPErrorCode,
    setIsGetOTPSuccessful,
    handleSendText,
    handleVerifyOtp,
    onOTPPopupClose,
  } = stateFunctions;

  const {
    isOpen: isOpenNoPhoneNumberPopup,
    onOpen: onOpenNoPhoneNumberPopup,
    onClose: onCloseNoPhoneNumberPopup,
  } = useDisclosure();

  useEffect(() => {
    if (user && claims) {
      const { mfaEnabled, phoneNumber, phoneNumberVerified } = claims;
      if (mfaEnabled) {
        setUserPhoneNumber(phoneNumber);
        if (phoneNumber && phoneNumberVerified) {
          handleSendText(null, true, handleSendTextSuccess, handleSendTextFail, true);
          trackPreLoginOTP(user);
        } else if (phoneNumber && !phoneNumberVerified) {
          handleSendText(null, true, handleSendTextSuccess, handleSendTextFail, true);
          trackPreLoginOTP(user);
        } else if ((phoneNumber == null || phoneNumber === '') && phoneNumberVerified) {
          onOpenNoPhoneNumberPopup();
        }
      } else {
        trackPreLoginOTP(user);
        // continue without OTP as mfa is disabled in firebase
        handleSuccess();
      }
    }
  }, [user, claims]);

  const handleSendTextSuccess = () => {
    onOTPPopupOpen();
    // suppress resend checkmark

    setIsGetOTPSuccessful(false);
    setOTPErrorCode(false);
  };

  const handleReSendTextSuccess = () => {
    setOTPErrorCode(false);
  };

  const handleSendTextFail = (error) => {
    if (error === 'Error on phone confirmation. Please try again later') {
      navigate(ROUTES.SOMETHING_WENT_WRONG, { replace: true });
    }
    setIsLoading(false);
    setOTPErrorCode(error);
  };

  const handleOTPPopupClosed = () => {
    setIsLoading(false);
    onOTPPopupClose();
  };

  const handleNoPhoneNumberPopupClosed = () => {
    setIsLoading(false);
    onCloseNoPhoneNumberPopup();
    manageStorageAfterLogout();
  };

  return (
    <>
      <OTPProvider>
        <TwoFactorVerificationPopUp
          {...states}
          {...stateFunctions}
          onOTPPopupClose={handleOTPPopupClosed}
          getOTP={() => handleSendText(null, true, handleReSendTextSuccess)}
          handleVerifyOnClick={(otpCode) => handleVerifyOtp({ code: otpCode }, handleSuccess)}
          isVerifyLoading={isVerifyLoading}
          customContent={{
            title: '2-step verification',
            description: `To verify your identity, please enter the 6-digit code sent to your phone number ${
              formatPhoneNumber(userPhoneNumber) === null
                ? userPhoneNumber
                : formatPhoneNumber(userPhoneNumber, true)
            }`,
          }}
        />
      </OTPProvider>

      <NoPhoneNumberPopup
        {...{ isOpen: isOpenNoPhoneNumberPopup, onClose: handleNoPhoneNumberPopupClosed }}
      />
    </>
  );
};

export default LoginOTP;
