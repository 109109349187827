import variables from './dropdowns.styles.scss';

const getHeight = (classNames) => {
  let height = '32px';
  if (classNames.includes('input-form-lg')) {
    height = '48px';
  } else if (classNames.includes('input-form-md')) {
    height = '40px';
  }
  return height;
};

export const base = (isMobile, classNames) => ({
  background: 'white',
  display: isMobile ? 'flex' : 'none',
  width: '100%',
  height: getHeight(classNames),
  borderRadius: 'sm',
  borderWidth: '1px',
  borderColor: variables.borderColor,
  color: variables.color,
  direction: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  padding: 0,
  _hover: {
    borderColor: variables.borderOnHover,
  },
  _focus: { borderColor: variables.focusBorderColor, boxShadow: variables.focusBoxShadow },
  _focusWithin: { borderColor: variables.focusBorderColor, boxShadow: variables.focusBoxShadow },
  _active: {
    borderColor: variables.borderOnHover,
    boxShadow: 'none',
  },
  _disabled: {
    cursor: 'default',
    pointerEvents: 'none',
    background: variables.disabledBackground,
    color: variables.disabledColor,
    borderColor: variables.disabledBorderColor,
    _hover: {
      borderColor: variables.disabledBorderColor,
      background: variables.disabledBackground,
      color: variables.disabledColor,
    },
    _focus: {
      borderColor: variables.disabledBorderColor,
      background: variables.disabledBackground,
      color: variables.disabledColor,
    },
    _focusWithin: {
      borderColor: variables.disabledBorderColor,
      background: variables.disabledBackground,
      color: variables.disabledColor,
    },
  },
  _invalid: {
    border: variables.invalidBorder,
    boxShadow: 'none',
    _hover: {
      border: variables.invalidActionBorder,
      boxShadow: 'none',
    },
    _focus: {
      border: variables.invalidActionBorder,
      boxShadow: variables.invalidBoxShadow,
    },
    _focusWithin: {
      border: variables.invalidActionBorder,
      boxShadow: variables.invalidBoxShadow,
    },
  },
});
