export const badgeIconBgStyles = {
  borderRadius: '50%',
  w: '35px',
  h: '35px',
  bg: 'red.200',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  flexShrink: 0,
};

export const badgeIconContainerStyles = {
  bg: 'transparent',
  color: 'red.800AA',
  w: '16px',
  h: '16px',
  position: 'absolute',
  top: '50%',
  marginTop: '-9px',
  left: '50%',
  marginLeft: '-9px',
};

export const titleTextStyles = {
  ml: '16px',
  lineHeight: '32px',
};

export const headerStyles = {
  alignItems: 'top',
};
