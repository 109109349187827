// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { BaselaneTitle, BaselaneProgressBar } from '@shared/components';
import { currentMonth } from '@shared/components/BaselaneResponsiveCard/helpers/format.helper';

import PropertyLegend from './PropertyLegend';
import {
  rentCollectionSummaryBoxStyle,
  titleStyles,
  progressBarContainerStyles,
  progressBarStyles,
} from './styles/responsive.style';

function RentCollectionSummary({
  rentCollected,
  rentPending,
  rentOverdue,
  activeLease,
  hasNoData,
  isLeasePage,
}: {
  rentCollected: number,
  rentPending: number,
  rentOverdue: number,
  activeLease: number,
  hasNoData: boolean,
  isLeasePage?: boolean,
}): any {
  const progressBarItems = [
    { id: 1, variant: 'filled', palette: 'primary', value: rentCollected },
    { id: 2, type: 'secondary', value: rentPending },
    { id: 3, type: 'red', value: rentOverdue },
  ];

  const legendSizing = isLeasePage ? { minWidth: { xl: '300px', lg: '300px' } } : {};

  // Note: current month name, in default locale on this computer
  const month = currentMonth();

  return (
    <Box {...rentCollectionSummaryBoxStyle(isMobile)}>
      {activeLease !== '' && (
        <BaselaneTitle
          title={isMobile ? `${month} Rent Collection Summary` : `Monthly Rent Collection Summary`}
          titleStyles={titleStyles(isMobile)}
        />
      )}
      <BaselaneProgressBar
        progressBarItems={progressBarItems}
        hasNoData={hasNoData}
        containerStyles={progressBarContainerStyles(isMobile)}
        barStyles={progressBarStyles(isMobile)}
      />
      {activeLease !== '' && (
        <Box {...legendSizing}>
          <PropertyLegend
            rentCollected={rentCollected}
            rentPending={rentPending}
            rentOverdue={rentOverdue}
            isLeasePage={isLeasePage}
          />
        </Box>
      )}
    </Box>
  );
}

RentCollectionSummary.defaultProps = {
  isLeasePage: false,
};

export default RentCollectionSummary;
