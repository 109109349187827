// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneResponsiveTable } from '@shared/components';
import { config } from '@core/components/Transactions/helpers/transactionsTable.helpers';
import TransacactionsListHeader from '@core/components/Transactions/TransactionsListHeader';
import SlLoader from '../../Loader';
import BaselaneResponsiveTableLoader from '../../Shared/components/BaselaneResponsiveTable/BaselaneResponsiveTableLoader';

type TransactionsDummyLoaderProps = {
  // externalFilters?: Object,
  filters: Object,
  onSort?: Function,
  onSelectAll?: Function,
  actionDeselectAll?: boolean,
  setActionDeselectAll?: Function,
  selectedTransactions?: Array<Object>,
  totalVisible?: number,
};

const TransactionsDummyLoader = ({
  // externalFilters,
  filters,
  onSort,
  onSelectAll,
  actionDeselectAll = false,
  setActionDeselectAll,
  selectedTransactions = [],
  totalVisible = 0,
}: TransactionsDummyLoaderProps): any => {
  const { isMin899, isMin1150 } = getBreakPoints();

  const loader = <SlLoader styles={{ position: 'absolute' }} />;

  // Dummy transactions loader that shows so that transactions
  // don't disappear or glitch out animations.
  return (
    <Box h="100%" position="relative">
      {!isMin899 ? (
        loader
      ) : (
        <BaselaneResponsiveTable
          id="trx-list"
          config={config(isMin1150)}
          tableContainerStyles={{ overflowY: 'initial', overflowX: 'initial' }}
        >
          <TransacactionsListHeader
            {...{
              onSelectAll,
              actionDeselectAll,
              setActionDeselectAll,
              selectedTransactions,
              totalVisible,
              filters,
              onSort,
            }}
          />
          <BaselaneResponsiveTableLoader isLoading>{loader}</BaselaneResponsiveTableLoader>
        </BaselaneResponsiveTable>
      )}
    </Box>
  );
};

TransactionsDummyLoader.defaultProps = {
  // externalFilters: ({}: { ... }),
  onSort: () => {},
  onSelectAll: () => {},
  actionDeselectAll: false,
  setActionDeselectAll: () => {},
  selectedTransactions: ([]: Array<empty>),
  totalVisible: 0,
};

export default TransactionsDummyLoader;
