const actionTypeSharedStyles = {
  m: '0 !important',
  w: 'fit-content',
  minW: 'fit-content',
};

export const actionTypeLinkStyles = ({ variant }) => {
  return {
    size: 'md',
    variant: 'link',
    palette: variant,
    styles: {
      ...actionTypeSharedStyles,
      // Delete below once link button component style is fixed
      lineHeight: '20px',
      fontWeight: 'normal',
      textDecoration: 'underline',
    },
  };
};

export const actionTypeButtonGroupStyles = {
  actionbtnprimary: {
    size: 'sm',
    variant: 'filled',
    palette: 'primary',
    ...actionTypeSharedStyles,
  },
  actionbtnsecondary: {
    size: 'sm',
    variant: 'outline',
    palette: 'neutral',
    ...actionTypeSharedStyles,
  },
};
