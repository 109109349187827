import React, { useContext, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Text, Stack, Box, FormLabel, Textarea, Input, Spacer, useToast } from '@chakra-ui/react';
import {
  BaselaneDrawer,
  NoPropertyTooltip,
  CurrencyText,
  T2WithTitleDropdown,
  T2Dropdown,
} from '@shared/components';
import TransactionContext from '@contexts/TransactionContext';
import UserContext from '@contexts/UserContext';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  DisplayInputDefaultCategoryItem,
  DisplayInputDefaultPropertyItem,
} from '@core/components/Transactions/DisplayInputVariations';
import {
  getPropertyData,
  renderPropertyDropdownParentItem,
} from '@core/components/Shared/helpers/propertiesFilter.helpers';
import { GET_TRANSACTIONS_SUMMARY } from '@core/apollo/queries';
import { getOptionsWithSubCategories } from '@core/components/CashFlowPage/helpers/cashflow.helpers';
import { itemRenderer } from '@shared/components/BaselaneDropdown/components/helpers/itemRenderer.helpers';
import { inputTextStyles, inputLabelTextStyles } from '../styles/editMultipleTransactions.style';
import {
  placeholderText,
  formatMultipleTransactions,
  getEditTransactionsInitialValues,
} from '../helpers/editMultipleTransactions.helper';
import EditMultipleTransactionsDrawerFooter from './EditMultipleTransactionsDrawerFooter';
import {
  getEditedMultipleTrxSegmentData,
  updateMultipleTrxSuccessEvent,
} from '../helpers/segmentEvents.helper';
import { selectedAmount } from '../styles/transactionsBulkActions.style';

type EditMultipleTransactionsDrawerProps = {
  editMultipleTrxRef: any,
  selectedTransactions: Array<Object>,
  totalSelectedTrxCount: Number,
  selectedTrx: Object,
  onEditMultipleTrxClose: Function,
  handleSelectedTransactions: Function,
  totalAmount: Number,
  refetch: Function,
};

const EditMultipleTransactionsDrawer = ({
  editMultipleTrxRef,
  selectedTransactions,
  totalSelectedTrxCount,
  selectedTrx,
  onEditMultipleTrxClose,
  handleSelectedTransactions,
  totalAmount,
  refetch,
}: EditMultipleTransactionsDrawerProps) => {
  const { isMax768 } = getBreakPoints();
  const formikRef = useRef(null);

  // Contexts
  const { user } = useContext(UserContext);
  const {
    categoryMap,
    propertyIdsMap,
    propertiesData,
    refetchTransactionsSummaryData,
    updateTransactions,
    categoryWithSubOptions,
    categoryIdsMap,
    summaryFilter,
  } = useContext(TransactionContext);

  const categoryOptions = getOptionsWithSubCategories(categoryWithSubOptions);

  // State Vars
  const [hasMerchantChanged, setHasMerchantChanged] = useState(false);
  const [hasNotesChanged, setHasNotesChanged] = useState(false);

  // Toaster
  const toast = useToast();
  const showToastUpdateTrxSuccessful = () => {
    toast({
      position: 'bottom-left',
      description: 'Edit Transactions Complete',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const getInitialValues = () => {
    const defaultValues = {
      merchant: selectedTrx?.merchantName,
      categoryId: selectedTrx?.tagId,
      notes: selectedTrx?.note?.text,
      propertyId: selectedTrx?.propertyId?.toString() ?? null,
      unitId: selectedTrx?.unitId?.toString() ?? null,
    };

    const initialVal =
      totalSelectedTrxCount === 1
        ? defaultValues
        : getEditTransactionsInitialValues(selectedTransactions);

    const formattedInitialVal = {
      ...initialVal,
      propertyId: initialVal.propertyId?.toString() ?? null,
      unitId: initialVal.unitId?.toString() ?? null,
    };

    return formattedInitialVal;
  };

  const initialValues = getInitialValues();

  async function updateMultipleTransactions(input, segmentData) {
    await updateTransactions({
      variables: { input },
      update: (cache, { data: { updateTransactions: update } }) => {
        showToastUpdateTrxSuccessful();
        refetchTransactionsSummaryData();
        // refetch transactions
        refetch();

        handleSelectedTransactions(
          update.map((t) => ({
            ...t,
            isChecked: true,
          }))
        );
        updateMultipleTrxSuccessEvent(segmentData);

        // category has been updated
        if (selectedTransactions.some((t) => !t.tagId) && update.every((t) => t.tagId)) {
          const res = cache.readQuery({
            query: GET_TRANSACTIONS_SUMMARY,
            variables: { input: summaryFilter },
          });

          if (res) {
            const transactionsWithoutCategory = selectedTransactions.filter((t) => !t.tagId);
            const uncategorizedTransactionsSum = transactionsWithoutCategory.reduce((acc, curr) => {
              // eslint-disable-next-line
              return (acc += Math.abs(curr.amount));
            }, 0);

            cache.writeQuery({
              query: GET_TRANSACTIONS_SUMMARY,
              data: {
                transactionSummary: {
                  ...res.transactionSummary,
                  totalUncategorized: {
                    ...res.transactionSummary.totalUncategorized,
                    count:
                      res.transactionSummary.totalUncategorized.count -
                      transactionsWithoutCategory.length,
                    absoluteAmount:
                      res.transactionSummary.totalUncategorized.absoluteAmount -
                      uncategorizedTransactionsSum,
                  },
                },
              },
              variables: { input: summaryFilter },
            });
          }
        }

        setHasMerchantChanged(false);
        setHasNotesChanged(false);
      },
      onError: (error) => console.error(error),
    });
  }

  const handleOnSubmit = (values) => {
    const editedTransactions = formatMultipleTransactions(
      values,
      selectedTransactions,
      propertyIdsMap,
      hasMerchantChanged,
      hasNotesChanged
    );

    const { id } = user;
    const segmentData = getEditedMultipleTrxSegmentData({
      values,
      initialValues,
      uid: id,
      trxCount: editedTransactions.length,
      categoryMap,
      title: document.title,
      url: window.location.href,
    });

    updateMultipleTransactions(editedTransactions, segmentData);
  };

  const handleOnCancel = (e) => {
    onEditMultipleTrxClose(e);
  };

  const propertyOptions = getPropertyData(propertiesData);
  const hasNoPropertyOptions = propertyOptions?.length === 0;

  return (
    <BaselaneDrawer
      showCloseButton={false}
      title="Edit Multiple Transactions"
      ref={editMultipleTrxRef}
      size="newdrawersm"
      onOverlayClick={handleOnCancel}
      newDesignDrawer
      footer={
        <EditMultipleTransactionsDrawerFooter
          onCancel={handleOnCancel}
          onSaveAndClose={(e) => {
            formikRef?.current?.handleSubmit(e);
            onEditMultipleTrxClose();
          }}
        />
      }
    >
      <Stack direction="row" alignItems="center">
        <Text textStyle="headline-lg">
          {totalSelectedTrxCount} {totalSelectedTrxCount === 1 ? 'Transaction' : 'Transactions'}{' '}
          Selected
        </Text>
        <Spacer />
        <CurrencyText
          {...selectedAmount}
          amount={totalAmount}
          isRounded={false}
          isPercentage={false}
          color="brand.neutral.700"
          negativeColor="red.800AA"
        />
      </Stack>

      <Text textStyle="xs" color="brand.neutral.700" mt="16px" mb="24px">
        Enter changes to apply to selected transactions. Field not edited will remain unchanged.
      </Text>

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleOnSubmit}
      >
        {({ values, handleChange, setFieldTouched, setFieldValue }) => {
          // Category and Property Dropdown Functions
          const getCategoryPlaceholder = () => {
            return !values?.categoryId || values?.categoryId === ''
              ? placeholderText(values.categoryId, 'Categories', selectedTransactions)
              : '';
          };

          const handleCategoryDropdownSubmit = (selectedValue) => {
            const [parentId, subId] = selectedValue?.length > 0 ? selectedValue.split('-') : [];
            setFieldTouched('categoryId', true);
            setFieldValue('categoryId', subId ?? parentId);
          };

          const getSelectedProperty = (vals) => {
            const { property } = vals;
            const { propertyId, unitId } = property || {};
            let selectedProperty = null;
            if (propertyId && unitId) {
              selectedProperty = {
                id: `${propertyId}-${unitId}`,
                showValueName: `${propertyIdsMap[`p-${propertyId}`]?.name}, ${
                  propertyIdsMap[`u-${unitId}`]?.name
                }`,
              };
            } else if (propertyId && !unitId) {
              selectedProperty = {
                id: `${propertyId}`,
                showValueName: propertyIdsMap[`p-${propertyId}`]?.name,
              };
            }
            return selectedProperty;
          };

          const getPropertyPlaceholder = () => {
            const { propertyId, unitId } = values;
            let showValueName =
              values?.propertyId === '' ? 'Multiple properties' : 'Select Property';
            if (propertyId && unitId) {
              showValueName = `${propertyIdsMap[`p-${propertyId}`]?.name}, ${
                propertyIdsMap[`u-${unitId}`]?.name
              }`;
            } else if (propertyId && !unitId) {
              showValueName = propertyIdsMap[`p-${propertyId}`]?.name;
            }
            return showValueName;
          };

          const handlePropertyDropdownSubmit = (selectedValue) => {
            const [newPId, newUId] = selectedValue?.length > 0 ? selectedValue.split('-') : [];
            setFieldTouched('propertyId', true);

            if (newPId && newUId) {
              setFieldValue('propertyId', newPId);
              setFieldValue('unitId', newUId);
            } else if (newPId) {
              setFieldValue('propertyId', selectedValue);
              setFieldValue('unitId', null);
            } else {
              setFieldValue('propertyId', null);
              setFieldValue('unitId', null);
            }
          };

          return (
            <>
              {/* Form */}
              <Stack spacing={2.5}>
                {/* Merchants */}
                <Box>
                  <FormLabel {...inputLabelTextStyles}>Merchant</FormLabel>
                  <Input
                    id="merchant"
                    placeholder={placeholderText(
                      values.merchant,
                      'Merchants',
                      selectedTransactions
                    )}
                    onChange={handleChange}
                    value={values.merchant}
                    onBlur={(e) => {
                      const { value } = e.target;
                      setHasMerchantChanged(value !== initialValues.merchant);
                    }}
                    {...inputTextStyles}
                  />
                </Box>

                {/* Category */}
                <Box>
                  <FormLabel {...inputLabelTextStyles}>Category</FormLabel>
                  <T2WithTitleDropdown
                    additionalProps={{ id: 'category-dropdown' }}
                    classNames={['input-form-lg', 'is-full-width']}
                    data={categoryOptions}
                    title="Category"
                    placeholder={getCategoryPlaceholder()}
                    showValueByFields={['name']}
                    parentItemRenderer={itemRenderer}
                    childItemRenderer={itemRenderer}
                    handleSubmit={(selectedValue) => handleCategoryDropdownSubmit(selectedValue)}
                    isMulti={false}
                    hasFilterWrapper={false}
                    selectedItem={
                      values?.categoryId
                        ? {
                            id: categoryIdsMap[values?.categoryId],
                            name: categoryMap[values?.categoryId],
                          }
                        : null
                    }
                    CustomDisplayInput={DisplayInputDefaultCategoryItem}
                    isMobile={isMax768}
                  />
                </Box>

                {/* Property */}
                <Box>
                  <FormLabel {...inputLabelTextStyles}>Property</FormLabel>
                  {hasNoPropertyOptions ? (
                    <NoPropertyTooltip>
                      <T2Dropdown
                        additionalProps={{ id: 'property-dropdown' }}
                        classNames={['input-form-lg', 'is-full-width']}
                        data={propertyOptions}
                        searchTerm="name"
                        title="Property"
                        placeholder={getPropertyPlaceholder()}
                        showValueByFields={['showValueName']}
                        parentItemRenderer={({ item }) => renderPropertyDropdownParentItem(item)}
                        childItemRenderer={itemRenderer}
                        handleSubmit={(selectedValue) =>
                          handlePropertyDropdownSubmit(selectedValue)
                        }
                        isMulti={false}
                        hasFilterWrapper={false}
                        selectedItem={getSelectedProperty(values)}
                        CustomDisplayInput={DisplayInputDefaultPropertyItem}
                        isDisabled={hasNoPropertyOptions}
                        isMobile={isMax768}
                      />
                    </NoPropertyTooltip>
                  ) : (
                    <T2Dropdown
                      additionalProps={{ id: 'property-dropdown' }}
                      classNames={['input-form-lg', 'is-full-width']}
                      data={propertyOptions}
                      searchTerm="name"
                      title="Property"
                      placeholder={getPropertyPlaceholder()}
                      showValueByFields={['showValueName']}
                      parentItemRenderer={({ item }) => renderPropertyDropdownParentItem(item)}
                      childItemRenderer={itemRenderer}
                      handleSubmit={(selectedValue) => handlePropertyDropdownSubmit(selectedValue)}
                      isMulti={false}
                      hasFilterWrapper={false}
                      selectedItem={getSelectedProperty(values)}
                      CustomDisplayInput={DisplayInputDefaultPropertyItem}
                      isDisabled={hasNoPropertyOptions}
                      isMobile={isMax768}
                    />
                  )}
                </Box>

                {/* Notes */}
                <Box>
                  <FormLabel {...inputLabelTextStyles}>Notes</FormLabel>
                  <Textarea
                    id="notes"
                    onChange={handleChange}
                    value={values.notes}
                    placeholder="Add Note..."
                    resize="vertical"
                    {...inputTextStyles}
                    onBlur={(e) => {
                      const { value } = e.target;
                      setHasNotesChanged(value !== initialValues.notes);
                    }}
                  />
                </Box>
              </Stack>
            </>
          );
        }}
      </Formik>
    </BaselaneDrawer>
  );
};
export default EditMultipleTransactionsDrawer;
