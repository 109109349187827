import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Stack, Text } from '@chakra-ui/react';
import {
  BaselaneButton,
  BaselaneButtonCard,
  BaselaneButtonIcon,
  BaselaneEmptyStateCard,
  BaselaneCardNew,
} from '@shared/components';
import { Icon12Plus } from '@icons/12px';
import { IconAddDottedCircle, IconArrowRight, IconBankPlus, IconBankWeb } from '@icons';
import { ADD_VIRTUAL_ACCOUNT } from '@routes';
import getBreakPoints from '@core/utils/getBreakPoints';
import { largeTextStyles } from '@pages/BankEntity/styles/text.styles';
import { addNewCardButtonStyles } from './styles/button.styles';
import {
  noSubAccDescriptionStyles,
  triggerBtnWrapperStyles,
  triggerBtnLableStyles,
} from './styles/noSubAccounts.styles';

type TriggerButtonProps = {
  type?: string,
  styles?: Object,
  isDisabled?: Boolean,
  variant?: string,
  palette?: string,
  size?: string,
  showMobileButton?: Boolean,
};

const TriggerButton = ({
  type,
  styles,
  isDisabled,
  variant,
  palette,
  size,
  showMobileButton,
}: TriggerButtonProps) => {
  const navigate = useNavigate();
  const currLocation = useLocation();
  const { isMin768, isMinXL } = getBreakPoints();

  switch (type) {
    case 'card':
      return (
        <BaselaneCardNew
          variant="clickable"
          palette="empty"
          id="card-add-virtual-account-button"
          {...{
            flexDirection: isMin768 ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: isMin768 ? 'center' : 'flex-start',
            alignSelf: 'stretch',
            whiteSpace: 'normal',
            minW: '312px',
            maxW: isMin768 ? '312px' : '100%',
            h: '100%',
          }}
          onClick={() => navigate({ pathname: `${currLocation.pathname}/${ADD_VIRTUAL_ACCOUNT}` })}
        >
          <Box
            flex="0 0 24px"
            w="24px"
            h="24px"
            p="5px"
            border="1px dashed #3A4B5B"
            borderRadius="50%"
            mr={isMin768 ? 0 : 2}
          >
            <Icon12Plus color="#3A4B5B" />
          </Box>

          <Box textAlign={isMin768 ? 'center' : 'left'} mt={isMin768 ? 1 : 0}>
            <Text textStyle="headline-sm" color="brand.neutral.900">
              Add virtual account
            </Text>
            <Text textStyle="xs" color="brand.neutral.600" mt={isMin768 ? 0.5 : 0}>
              Accounts to organize your property&apos;s OpEx, profits, security deposits, savings,
              and more.
            </Text>
          </Box>
        </BaselaneCardNew>
      );
    case 'emptyState':
      return (
        <BaselaneButtonCard
          id="empty-state-add-virtual-account-button"
          type="clean"
          onClick={() => navigate({ pathname: `${currLocation.pathname}/${ADD_VIRTUAL_ACCOUNT}` })}
        >
          {!isMinXL && (
            <Box alignSelf="start">
              <IconBankWeb />
            </Box>
          )}
          <Stack spacing="4px">
            <Text {...largeTextStyles}>Add account</Text>
            <BaselaneEmptyStateCard.BulletPoints
              type="check"
              iconMarginRight="0"
              iconWidth="16"
              iconHeight="16"
              spacing="0"
            >
              <Text {...noSubAccDescriptionStyles}>
                {isMinXL
                  ? 'For security deposits, properties & more'
                  : 'For security deposits, properties/units, savings buffers & more'}
              </Text>
              <Text {...noSubAccDescriptionStyles}>Auto-tag all transactions to properties</Text>
            </BaselaneEmptyStateCard.BulletPoints>
            <Stack direction="row" align="center" mt="8px" textStyle="sm">
              <Text>Add account</Text>
              <Box ml="8px">
                <IconArrowRight />
              </Box>
            </Stack>
          </Stack>
        </BaselaneButtonCard>
      );
    case 'addNewCard':
      return (
        <BaselaneButtonCard
          id="card-add-virtual-account-button"
          type="clean"
          isDisabled={isDisabled}
          buttonStyles={{
            ...addNewCardButtonStyles,
            ...styles,
          }}
          onClick={() => navigate({ pathname: `${currLocation.pathname}/${ADD_VIRTUAL_ACCOUNT}` })}
        >
          <IconAddDottedCircle />
          <Text mt="8px">Add account</Text>
        </BaselaneButtonCard>
      );
    default:
      return showMobileButton ? (
        <Stack {...triggerBtnWrapperStyles}>
          <BaselaneButtonIcon
            id="add-virtual-account-button"
            variant={variant}
            palette={palette}
            size={size}
            icon={<IconBankPlus />}
            isDisabled={isDisabled}
            onClick={() =>
              navigate({ pathname: `${currLocation.pathname}/${ADD_VIRTUAL_ACCOUNT}` })
            }
          />
          <Text {...triggerBtnLableStyles}>Add account</Text>
        </Stack>
      ) : (
        <BaselaneButton
          id="add-virtual-account-button"
          variant={variant}
          palette={palette}
          size={size}
          isDisabled={isDisabled}
          onClick={() => navigate({ pathname: `${currLocation.pathname}/${ADD_VIRTUAL_ACCOUNT}` })}
        >
          Add virtual account
        </BaselaneButton>
      );
  }
};

TriggerButton.defaultProps = {
  type: '',
  styles: {},
  isDisabled: false,
  variant: 'outline',
  palette: 'neutral',
  size: 'sm',
  showMobileButton: false,
};

export default TriggerButton;
