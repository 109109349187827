/* eslint-disable react/prop-types */
import { React } from 'react';
import {
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { BaselaneCardNew, BaselaneButton } from '@shared/components';
import SlLoader from '@core/components/Loader';
import checkIsTransfer from '@core/utils/checkIsTransfer.helper';
import useScheduledRecurringPaymentDetails from '../scheduled-payments/useScheduledRecurringPaymentDetails.hook';
import ScheduledPaymentsHeader from '../scheduled-payments/ScheduledPaymentsHeader';
import ScheduledPaymentsBookeeping from '../scheduled-payments/ScheduledPaymentsBookeeping';
import ScheduledPayments from '../scheduled-payments/ScheduledPayments';
import ScheduledPaymentsPaymentMethodDetails from '../scheduled-payments/ScheduledPaymentsPaymentMethodDetails';
import ScheduledPaymentsPaymentPayeeDetails from '../scheduled-payments/ScheduledPaymentsPaymentPayeeDetails';
import CancelPaymentModal from '../CancelPaymentModal';
import CancelTransferModal from '../CancelTransferModal';

const RecurringPaymentDetails = ({ from, recurringPaymentId }) => {
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();

  const handleCancelPayment = () => {
    onDeleteAlertOpen();
  };

  const { data, loading } = useScheduledRecurringPaymentDetails(recurringPaymentId);

  if (loading) {
    return (
      <Box>
        <SlLoader styles={{ position: 'absolute !important' }} />
      </Box>
    );
  }

  const isTransfer = checkIsTransfer(data);

  return (
    <>
      <BaselaneCardNew palette="dark">
        <Stack w="100%" gap={1}>
          <ScheduledPaymentsHeader isOneTimePayment={false} details={data} />
          <ScheduledPaymentsPaymentPayeeDetails
            isOneTimePayment={false}
            startDate={data?.startDate}
            details={data}
            noP={data?.recurrencePattern?.numberOfPayments}
          />
          <Accordion allowToggle mt={1.5}>
            <AccordionItem border={0}>
              <AccordionButton p="0 0 8px 0" _hover={{ bg: 'transparent' }}>
                <Text textStyle="sm-heavy" textAlign="left">
                  Show details
                </Text>
                <AccordionIcon ml={1} height="16px" width="16px" />
              </AccordionButton>

              <AccordionPanel p="0" display="flex" flexDirection="column" gap={1}>
                {!isTransfer && (
                  <ScheduledPaymentsPaymentMethodDetails isOneTimePayment={false} details={data} />
                )}
                <ScheduledPaymentsBookeeping isOneTimePayment={false} details={data} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Stack>
      </BaselaneCardNew>

      <Box w="100%" my="2">
        <BaselaneButton
          variant="outline"
          palette="danger"
          isFullWidth
          size="md"
          onClick={handleCancelPayment}
        >
          {isTransfer ? 'Cancel transfers' : 'Cancel payments'}
        </BaselaneButton>
      </Box>
      <ScheduledPayments recurringPaymentId={recurringPaymentId} />
      {isDeleteAlertOpen && isTransfer ? (
        <CancelTransferModal
          handleClose={onDeleteAlertClose}
          navigateFrom={from}
          recurringPaymentId={recurringPaymentId}
        />
      ) : null}
      {isDeleteAlertOpen && !isTransfer ? (
        <CancelPaymentModal
          handleClose={onDeleteAlertClose}
          navigateFrom={from}
          recurringPaymentId={recurringPaymentId}
        />
      ) : null}
    </>
  );
};

export default RecurringPaymentDetails;
