import { BA_ERROR_ENUMS } from '@pages/LeasesPage/helpers/sharedBAErrors.helpers';

const getStripeBankAccountId = (accounts, value) => {
  const [acc] = accounts.filter((a) => a?.id === value);
  return acc?.stripeBankAccountId;
};

const checkForPayoutFailedError = (id, accounts) => {
  const selectedAccount = accounts.filter((account) => account?.id === id);
  const errCode = selectedAccount[0]?.bankAccountMetadata?.payoutFailedReason?.failureCode;
  if (errCode && selectedAccount[0]?.bankAccountMetadata?.lastPayoutFailed) {
    return BA_ERROR_ENUMS[errCode];
  }
  return null;
};

export const getFlattenedAccounts = (accounts) => {
  const flattenedAccounts = [];
  accounts?.forEach((account) => {
    flattenedAccounts.push(...account.items);
  });

  return flattenedAccounts;
};

export const validateFormik = (accounts, values) => {
  const connectedAccountsArr = getFlattenedAccounts(accounts);
  const { rentAndFeesBankAccountId } = values ?? {};

  const selectedAccount = connectedAccountsArr.find(
    (account) => account?.id === rentAndFeesBankAccountId
  );

  const hasRentAndFeesStripeBankAccountId = getStripeBankAccountId(
    connectedAccountsArr,
    rentAndFeesBankAccountId
  );

  const errors = {};
  if (
    !rentAndFeesBankAccountId ||
    rentAndFeesBankAccountId === 'Select account' ||
    rentAndFeesBankAccountId === ''
  ) {
    errors.rentAndFeesBankAccountId = 'Please select receiving account';
  } else if (rentAndFeesBankAccountId && !hasRentAndFeesStripeBankAccountId) {
    errors.rentAndFeesBankAccountId =
      'Cannot collect rent using this bank account, please try another bank account or contact support';
  } else if (
    rentAndFeesBankAccountId &&
    selectedAccount?.connectionState === 'ITEM_LOGIN_REQUIRED'
  ) {
    errors.rentAndFeesBankAccountId = 'Disconnected';
  }

  const payoutFailedRentAndFeesError = checkForPayoutFailedError(
    rentAndFeesBankAccountId,
    connectedAccountsArr
  );
  if (payoutFailedRentAndFeesError) {
    errors.rentAndFeesBankAccountId = payoutFailedRentAndFeesError;
  }

  return errors;
};
