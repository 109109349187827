export const addressFormValidation = (values) => {
  const errors = {};

  if (values.address?.trim() === '') {
    errors.address = 'Please enter address';
  }

  if (values.city?.trim() === '') {
    errors.city = 'Please enter city';
  }

  if (values.state === '') {
    errors.state = 'Please enter state';
  }

  if (values.zipcode === '') {
    errors.zipcode = 'Please enter zipcode';
  }

  if (values.zipcode !== '' && values.zipcode?.length < 5) {
    errors.zipcode = 'Please enter 5-digit zip code';
  }
  return errors;
};
