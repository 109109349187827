import React from 'react';
import customTheme from '@core/theme';
import { BaselaneResponsiveBanner } from '@shared/components';
import { IconUIArrowRight, IconBankError } from '@core/components/Icons';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';

function StripeErrorBanner() {
  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  return (
    <BaselaneResponsiveBanner
      icon={
        <IconBankError
          {...{
            w: '40',
            h: '40',
          }}
        />
      }
      indicatorColor={customTheme.colors.red['100']}
      title="There is an issue paying out your rent collection"
      description="Please contact support for more details"
      callToActionLabel="Contact support"
      callToActionRightIcon={<IconUIArrowRight w="18" />}
      onCallToActionClick={() => {
        zendeskAPI('messenger', 'open');
      }}
      styles={{
        title: { color: customTheme.colors.brand.neutral['700'] },
        description: { color: customTheme.colors.brand.neutral['600'] },
      }}
      borderColor={customTheme.colors.brand.darkBlue['150']}
      m="16px"
    />
  );
}

export default StripeErrorBanner;
