export const noBankAccountsHeaderStyles = {
  fontSize: '2xl',
  lineHeight: '32px',
  fontWeight: 'semibold',
  color: 'brand.neutral.700',
  mb: '8px',
  textAlign: 'center',
};

export const menuContainerStyles = {
  position: 'fixed',
  top: '56px',
  left: '1px',
  background: 'brand.neutral.white',
  zIndex: '10',
  height: 'calc(100vh - 56px)',
  width: '100%',
  overflow: 'scroll',
};

export const menuInnerContainerStyles = {
  direction: 'column',
  justifyContent: 'flex-end',
  sx: {
    '@media screen and (min-height: 500px)': {
      justifyContent: 'center',
      height: '100%',
      marginTop: '0px',
    },
  },
  padding: '0 0 32px',
  width: '100%',
  overflow: 'scroll',
  maxHeight: '100vh',
  marginTop: '56px',
};
