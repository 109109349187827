import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { containerStyles, textStyles } from '../styles/fdicElement.styles';

const FDICElement = () => (
  <HStack {...containerStyles}>
    <Text {...textStyles}>
      FDIC insured up to $3,000,000 per account. Banking services provided by Thread Bank; Member
      FDIC.
    </Text>
  </HStack>
);

export default FDICElement;
