// @flow
import React from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';

import UpdatedItem from './UpdatedItem';
import { getTransactionDataUpdates } from './helpers/transactionDataUpdate.helper';
import { titleStyles, descriptionStyles } from './styles/transactionDataUpdate.styles';

type TransactionDataUpdateProps = {
  transaction: Object,
  handleMerchantSubmit: Function,
  handleDateSubmit: Function,
};

function TransactionDataUpdate({
  transaction,
  handleMerchantSubmit,
  handleDateSubmit,
}: TransactionDataUpdateProps): any {
  const { isMinXL } = getBreakPoints();

  const { originalTransaction, merchantName, date, pending } = transaction;
  const { merchantName: originalMerchantName = null, date: originalDate } = originalTransaction;

  const transactionUpdates = getTransactionDataUpdates({
    merchantName,
    originalMerchantName,
    date,
    originalDate,
  });

  const [{ isUpdated: isMerchantUpdated }, { isUpdated: isTrxDateUpdated }] = transactionUpdates;

  return (
    (isMerchantUpdated || isTrxDateUpdated) && (
      <Stack my={isMinXL ? '24px' : '32px'}>
        <Box mb="16px">
          <Text {...titleStyles}>Transaction Data</Text>
          <Text {...descriptionStyles}>
            You made changes to this transaction&apos;s data. You can reset changes below.
          </Text>
        </Box>

        <Stack gap="16px" m="0 !important">
          {transactionUpdates.map((updatedData) => {
            const { title, original, updated, isUpdated, isOriginalNull } = updatedData;

            return (
              isUpdated && (
                <UpdatedItem
                  key={title}
                  {...{
                    title,
                    original,
                    updated,
                    pending,
                    handleMerchantSubmit,
                    handleDateSubmit,
                    isOriginalNull,
                  }}
                />
              )
            );
          })}
        </Stack>
      </Stack>
    )
  );
}

export default TransactionDataUpdate;
