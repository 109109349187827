import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Box } from '@chakra-ui/react';
import { getIconMarginValue } from './helpers/link.helpers';
import { linkStyles } from './styles/link.styles';

type BaselaneLinkProps = {
  variant: string,
  size?: string,
  leftIcon: any,
  rightIcon: any,
  children: any,
  onClick: Function,
  to?: LocationDescriptor,
  state?: Object,
  href?: string,
  isExternal?: boolean,
  target?: string,
};

function BaselaneLink({
  variant,
  size,
  leftIcon,
  rightIcon,
  children,
  onClick,
  to,
  state,
  href,
  isExternal,
  target,
}: BaselaneLinkProps) {
  return (
    <Link
      as={to && RouterLink}
      {...{
        variant,
        size,
        to,
        state,
        onClick,
        href,
        isExternal,
        target,
      }}
      fontWeight="normal"
      textDecoration="underline"
      _hover={{
        textDecoration: 'underline',
      }}
    >
      {leftIcon && (
        <Box as="span" {...{ mr: getIconMarginValue(size), ...linkStyles }}>
          {leftIcon}
        </Box>
      )}
      <Box as="span">{children}</Box>
      {rightIcon && (
        <Box as="span" {...{ ml: getIconMarginValue(size), ...linkStyles }}>
          {rightIcon}
        </Box>
      )}
    </Link>
  );
}

BaselaneLink.defaultProps = {
  to: '',
  state: {},
  size: 'sm',
  href: '',
  isExternal: false,
  target: '',
};
export default BaselaneLink;
