import React, { useState } from 'react';
import { isTablet } from 'react-device-detect';
import { HStack, Text } from '@chakra-ui/react';
import BaselaneConditionalTooltip from '../../../Shared/components/BaselaneConditionalTooltip';

import { actionBoxContainerStyles, actionBoxTitleStyles } from '../../styles/actionBox.styles';

type ActionBoxProps = {
  action: Object,
};

const ActionBox = ({ action }: ActionBoxProps) => {
  const { icon: Icon, title, onClick, tooltipText, isDisabled } = action;

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const hasTooltip = tooltipText && tooltipText?.length;

  const clickHandler = (e) => {
    if (!isDisabled) {
      onClick?.(e);
    } else if (hasTooltip) {
      setIsTooltipOpen((prev) => !prev);
    }
  };

  const blurHandler = () => {
    if (hasTooltip) {
      setIsTooltipOpen(false);
    }
  };

  // uses standard disabled html attribute when no tooltip,
  // if tooltip provided, uses aria-disabled
  const disabled = { [`aria-disabled`]: isDisabled };

  return (
    <BaselaneConditionalTooltip
      condition={tooltipText}
      tooltipText={tooltipText}
      isOpen={isTooltipOpen}
    >
      <HStack
        {...disabled}
        {...{ onClick: clickHandler, onBlur: blurHandler }}
        {...actionBoxContainerStyles(isTablet)}
      >
        {/* Icon */}
        <Icon />

        {/* Title */}
        <Text {...actionBoxTitleStyles}>{title}</Text>
      </HStack>
    </BaselaneConditionalTooltip>
  );
};

export default ActionBox;
