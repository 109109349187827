import { gql } from '@apollo/client';

export const APY_TIERS = gql`
  query tiers {
    tiers {
      name
      minValue
      maxValue
      product {
        type
        value
      }
      bonus {
        type
        value
      }
    }
  }
`;
