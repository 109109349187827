export const titleStyles = (isMobileOnly) => {
  return {
    textStyle: 'headline-lg',
    m: '0 !important',
    mb: isMobileOnly ? '7px !important' : '16px !important',
  };
};

export const listStyles = { m: '0 !important' };

export const listItemContainerStyles = {
  w: '100%',
  m: '0 !important',
};

export const listItemStyles = (isMobileOnly) => {
  return {
    containerStyle: {
      py: '16px',
      m: '0 !important',
      _notLast: { borderBottomWidth: '1px', borderColor: 'brand.darkBlue.150' },
      _hover: {
        cursor: 'pointer',
      },
    },
    labelStyle: {
      w: '100%',
      fontSize: isMobileOnly ? 'xs' : 'sm',
      lineHeight: '20px',
      color: 'brand.neutral.700',
    },
  };
};
