import { isMobile } from 'react-device-detect';
import { truncatedText } from '@shared/styles/text.style';
import customTheme from '@core/theme';

export const drawerBodyStyles = {
  p: isMobile ? '35px 24px' : '35px 32px',
};

export const drawerSectionStyles = {
  pb: '32px',
  px: '0',
  m: '0 !important',
  w: '100%',
};

export const drawerSection2Styles = {
  pb: isMobile ? '20px' : '24px',
  px: '0',
  m: '0 !important',
  w: '100%',
};

export const drawerCardNumberStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
};

export const drawerCardNameStyles = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
  mt: '2px !important',
  w: 'contentWidth',
  ...truncatedText,
};

export const drawerLabelStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
};

export const drawerLabel2Styles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
};

export const drawerLabel3Styles = {
  textStyle: 'sm',
  color: 'brand.neutral.600',
};

export const spendingLimitTextStyles = (hasEnoughFunds) => ({
  color: hasEnoughFunds ? customTheme.colors.brand.neutral['700'] : customTheme.colors.red['500A'],
});

export const moneySpentTextStyles = (isExceeded90, isPhysicalCard, colorNumber = '900') => ({
  color:
    !isPhysicalCard && isExceeded90
      ? customTheme.colors.red['500A']
      : customTheme.colors.brand.neutral[colorNumber],
});

export const disabledTextStyles = (cardStatus) => {
  return cardStatus === 'Frozen' || cardStatus === 'SuspectedFraud'
    ? {
        color: customTheme.colors.brand.darkBlue['300'],
      }
    : {};
};

export const progressBarStyles = (progressPercent, isExceeded90, cardStatus) => {
  let colorScheme = isExceeded90 ? 'red.500A' : 'scheme.blue';
  if (cardStatus === 'Frozen' || cardStatus === 'SuspectedFraud') {
    colorScheme = 'scheme.grey';
  }

  return {
    w: '100%',
    h: '4px',
    borderRadius: '8px',
    value: progressPercent,
    min: 0,
    size: 'lg',
    my: '12px',
    colorScheme,
  };
};

export const drawerValueStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
};

export const drawerValue2Styles = {
  textStyle: 'xs',
  color: 'brand.neutral.700',
};

export const drawerValue3Styles = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
  textAlign: 'right',
};

export const drawerValue4Styles = {
  textStyle: 'headline-xs',
  color: 'brand.neutral.700',
};

export const mtdSpentTextStyles = {
  color: 'brand.neutral.700',
  textStyle: 'headline-2xl',
};

export const drawerOpenDetailsStyles = {
  fontSize: 'sm',
  fontWeight: 'normal',
  color: 'brand.blue.700',
  lineHeight: '20px',
  height: '20px',
  border: 'none',
};
