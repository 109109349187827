import React from 'react';
import { Stack, Text, Box } from '@chakra-ui/react';
import TableRow from '../components/TableRow';
import { tableHeaderStyles, titleTextStyles } from '../styles/table.styles';
import TransactionSplitsArray from './TransactionSplitsArray';

type TransactionSplitsProps = {
  totalAmount: Number,
  splitTransactions: Array<Object>,
  formikRef: any,
  setSplitTrxDirty: Function,
  setSplitTrxValid: Function,
  handleOnSaveAndClose: Function,
  setDeletedSplitTransactions: Function,
  propertyOptions: Array<Object>,
};

const TransactionSplits = ({
  totalAmount,
  splitTransactions,
  formikRef,
  setSplitTrxDirty,
  setSplitTrxValid,
  handleOnSaveAndClose,
  setDeletedSplitTransactions,
  propertyOptions,
}: TransactionSplitsProps) => {
  const headerItems = [
    { element: <Text>Category</Text> },
    { element: <Text>Property / Unit</Text> },
    { element: <Text>Amount</Text> },
  ];

  return (
    <Stack spacing="28px">
      <Text {...titleTextStyles}>Transaction Splits</Text>

      <Box w="100%">
        <TableRow {...{ items: headerItems }} {...tableHeaderStyles} />
        <TransactionSplitsArray
          {...{
            totalAmount,
            splitTransactions,
            formikRef,
            setSplitTrxDirty,
            setSplitTrxValid,
            handleOnSaveAndClose,
            setDeletedSplitTransactions,
            propertyOptions,
          }}
        />
      </Box>
    </Stack>
  );
};

export default TransactionSplits;
