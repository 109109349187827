// @flow
import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, HStack, Text } from '@chakra-ui/react';
import moment from 'moment';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';
import InvoiceContext from '@contexts/InvoiceContext';
import QuickPayIconOrBadge from '@pages/LeasesPage/components/QuickPayIconOrBadge';

import { INVOICE_STATUS_TYPES } from '../../../constants';
import InvoiceDetails from '../InvoiceDetails';
import type { Invoices as InvoicesType } from '../../../types';
import { mobileInvoiceStyles } from '../../../styles/invoice.styles';

type ListItemMobileProps = {
  invoice: InvoicesType,
  refetchLeaseInvoices: Function,
  isLeaseLevel: boolean,
  hasUpdatedInvoice: boolean,
  setHasUpdatedInvoice: Function,
  sortedLeases: Array<Object>,
  hasMultipleUnits: boolean,
};

function ListItemMobile({
  invoice,
  refetchLeaseInvoices,
  isLeaseLevel,
  hasUpdatedInvoice,
  setHasUpdatedInvoice,
  sortedLeases,
  hasMultipleUnits,
}: ListItemMobileProps): any {
  const navigate = useNavigate();
  const invoiceDetailsDrawerRef = useRef();
  const invoiceDetailsDrawerActionBtnRef = useRef();

  const {
    setInvoiceList,
    selectedInvoiceId,
    setSelectedInvoiceId = () => {},
    leaseDetailOpen,
  } = useContext(InvoiceContext);
  const filters = JSON.parse(localStorage.getItem('filters')) || {};

  const {
    id,
    state,
    dueDate,
    originalDueDate,
    description,
    amount,
    isQuickPay,
    propertyUnit: { propertyId, propertyName, unitId, unitName },
  } = invoice;

  const paymentDate = invoice?.invoiceMarkedPaid
    ? new Date(Number(invoice?.invoiceMarkedPaidTimestamp)).toISOString()
    : invoice?.invoiceCharge?.paymentDate;
  const paymentTriggerType = invoice?.invoiceCharge?.paymentTriggerType;

  const pDate = moment(paymentDate).startOf('day').toISOString();
  const dDate = moment(dueDate).startOf('day').toISOString();

  const isDeleted = state === 'DELETED';
  const isCompleted = state === 'COMPLETED';
  const isScheduled = state === 'SCHEDULED';
  const isAutoPay = paymentTriggerType === 'autoPay';
  const isPropertyDeleted = !propertyId && !unitId;
  const isMultiUnit = isLeaseLevel
    ? hasMultipleUnits
    : sortedLeases?.find((lease) => lease.id === invoice.leaseId && lease.totalUnits > 1);
  const lease = sortedLeases.find((l) => l.id === invoice.leaseId);

  const latePayment = isCompleted && pDate > dDate;
  const showAutoPayLabel = isScheduled && isAutoPay;

  // Format Data
  const { icon: statusIcon, label: statusLabel, color: statusColor } =
    INVOICE_STATUS_TYPES[state] ?? {};
  const formattedInvoiceDetails = {
    ...invoice,
    amount: formatCurrency(amount).inDollars,
    dueDate: moment(dueDate, moment.defaultFormat).toDate(),
    formattedDueDate: formatDate(dueDate),
    originalDueDate: moment(originalDueDate, moment.defaultFormat).toDate(),
    formattedOriginalDueDate: formatDate(originalDueDate),
    paymentType: description,
    paymentDate: formatDate(paymentDate, 'MMM D, YYYY'),
    state: showAutoPayLabel ? 'AUTOPAY' : state,
    latePayment,
  };

  const handleInvoiceDetailsDrawerOpen = () => {
    setSelectedInvoiceId(id);
  };

  const handleInvoiceDetailsDrawerClose = (isFromDeleteInvoice) => {
    localStorage.removeItem('invoice');
    setSelectedInvoiceId(null);
    // when editing Next Payment invoices, we're working on an invoiceList inside NextPayment prop
    if (!leaseDetailOpen && (hasUpdatedInvoice || isFromDeleteInvoice)) {
      refetchLeaseInvoices();
    }
    const { date, dateType, state: filterState, leaseId } = filters;
    if (date || dateType || filterState || leaseId) {
      delete filters?.invoiceId;
      navigate('/leases', { state: { ...filters, noRefresh: true } });
    } else {
      navigate('/leases');
    }
  };

  const {
    container,
    type,
    property,
    due,
    amount: amountStyles,
    paidlate,
    col,
    row,
    paymentStatus,
  } = mobileInvoiceStyles(isDeleted);

  useEffect(() => {
    if (
      selectedInvoiceId &&
      selectedInvoiceId === id &&
      invoiceDetailsDrawerRef.current &&
      invoiceDetailsDrawerRef.current.isOpen === false
    ) {
      invoiceDetailsDrawerRef.current?.open();
      filters.invoiceId = id;
      filters.noRefresh = true;
      navigate('/leases', { state: filters });
    } else if (!selectedInvoiceId && invoiceDetailsDrawerRef?.current?.isOpen) {
      invoiceDetailsDrawerRef.current?.close();
    }
  }, [selectedInvoiceId]);

  return (
    <>
      <Box {...row} as="button" onClick={handleInvoiceDetailsDrawerOpen}>
        <HStack {...container} ref={invoiceDetailsDrawerActionBtnRef}>
          <Box {...{ ...col, ...property.container }}>
            <Box {...property.container}>
              {!isLeaseLevel && (
                <Text {...property.propertytext}>
                  {isPropertyDeleted ? 'Property Deleted' : propertyName}{' '}
                  {isMultiUnit && !isPropertyDeleted && <Text as="span">&#183; {unitName}</Text>}
                </Text>
              )}
            </Box>
            <HStack>
              <Text {...type(isLeaseLevel)}>{formattedInvoiceDetails.description}</Text>
              {isQuickPay && <QuickPayIconOrBadge isIconOnly isGreen />}
            </HStack>
            <Text {...due}>
              Due {formattedInvoiceDetails.formattedDueDate}{' '}
              {latePayment && (
                <>
                  &#183;
                  <Text as="span" {...paidlate}>
                    Paid late
                  </Text>
                </>
              )}
            </Text>
          </Box>
          <Box {...col}>
            <Text {...amountStyles}>{formattedInvoiceDetails.amount}</Text>
            <HStack {...paymentStatus(statusColor)}>
              <Box>{statusIcon}</Box>
              <Text flex="0">{statusLabel}</Text>
            </HStack>
          </Box>
        </HStack>
      </Box>

      <InvoiceDetails
        invoiceDetails={formattedInvoiceDetails}
        {...{
          invoiceDetailsDrawerRef,
          invoiceDetailsDrawerActionBtnRef,
          handleInvoiceDetailsDrawerClose,
          lease,
          setHasUpdatedInvoice,
          isPropertyDeleted,
          isMultiUnit,
          refetchLeaseInvoices,
          setInvoiceList,
        }}
      />
    </>
  );
}

export default ListItemMobile;
