import React, { useState } from 'react';
import { Box, Tag, TagLabel, TagLeftIcon, useMultiStyleConfig } from '@chakra-ui/react';
import { Icon12Close } from '@icons/12px';
import { BaselaneButtonIcon } from '../BaselaneButtonIcon';

type BaselaneChipProps = {
  id: string,
  label: string,
  size: string,
  variant: string,
  leftIcon: React.Node,
  iconColor: String,
  onClick: Function,
  isDeletable: boolean,
  onDeleteClick: Function,
  isSelectable: boolean,
  isSelected: boolean,
  isDisabled: boolean,
};

const BaselaneChip = ({
  id = null,
  label,
  size = 'lg',
  variant = 'simple-neutral',
  leftIcon = null,
  iconColor = 'inherit',
  onClick = null,
  isDeletable = false,
  onDeleteClick = null,
  isSelectable = false,
  isSelected = false,
  isDisabled = false,
}: BaselaneChipProps) => {
  const variantArr = variant.split('-');
  const type = variantArr[0];
  const chipColor = variantArr[1];
  const [showChip, setShowChip] = useState(true);

  const styles = useMultiStyleConfig(`Tag`, {
    variant,
    size,
    isClickable: !!onClick,
    isDeletable,
    isDisabled,
    isSelectable,
    isSelected,
    chipColor,
    type,
  });

  const { chip, closeButton, indicator, label: chiplabel } = styles ?? {};

  return (
    showChip && (
      <Tag
        {...{
          size,
          variant,
          ...chip,
        }}
        onClick={
          onClick
            ? (e) => {
                if (!isDisabled) {
                  e.stopPropagation();
                  onClick();
                }
              }
            : null
        }
        {...(isSelectable ? { minWidth: 'none' } : {})}
      >
        {/* Left Icon - Optional */}
        {type === 'simple' && leftIcon && (
          <TagLeftIcon as={leftIcon} mr={label ? 0.5 : 0} color={iconColor} />
        )}

        {/* Chip Status Indicator (Dot) - Optional */}
        {type === 'status' && (
          <Box {...indicator?.container}>
            <Box {...indicator?.dot} />
          </Box>
        )}

        {/* Chip Label - Optional */}
        {label && <TagLabel {...{ id, ...chiplabel }}>{label}</TagLabel>}

        {/* Delete Button - Optional */}
        {isDeletable && (
          <BaselaneButtonIcon
            {...{
              className: 'delete-chip',
              variant: 'transparent',
              palette: 'neutral',
              size: 'xs',
              styles: closeButton,
              icon: <Icon12Close />,
              isDisabled,
              onClick: (e) => {
                if (!isDisabled) {
                  e.stopPropagation();
                  setShowChip(false);

                  if (onDeleteClick) {
                    onDeleteClick();
                  }
                }
              },
            }}
          />
        )}
      </Tag>
    )
  );
};

export default BaselaneChip;
