export const drawerFooterStyle = (isMobile) => ({
  h: '80px',
  py: '20px',
  px: isMobile ? '16px' : '24px',
  bg: 'brand.neutral.white',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  zIndex: 1,
  position: 'fixed',
  bottom: 0,
  left: 0,
  w: '100%',
  justifyContent: 'flex-start',
});

export const resyncErrorText = {
  color: 'red.800AA',
  lineHeight: '12px',
  fontSize: 'xs',
  ml: '8px',
  display: 'block',
  mt: 1,
};

export const resyncLinkStyles = {
  cursor: 'pointer',
  textDecoration: 'underline',
  p: '0 !important',
  display: 'inline',
  whiteSpace: 'inherit',
  as: 'span',
  lineHeight: '12px',
  fontWeight: 'normal',
};
