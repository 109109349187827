import { formatMaskedCurrency } from './transactionSplits.helper';

export const formatDataForAPI = (transactions) =>
  transactions.map((splitTrx) => {
    const trx = { ...splitTrx };
    trx.amount = formatMaskedCurrency(splitTrx.amount);
    trx.date = splitTrx.date;

    if (!splitTrx.tagId || splitTrx.tagId === '') {
      trx.tagId = null;
    } else {
      trx.tagId = +splitTrx.tagId;
    }

    if (!splitTrx.propertyId || splitTrx.propertyId === '') {
      trx.propertyId = null;
      trx.propertyUnitId = null;
    } else {
      trx.propertyId =
        typeof splitTrx.propertyId === 'string'
          ? splitTrx.propertyId.split('-')[0]
          : +splitTrx.propertyId;
      trx.propertyUnitId = !splitTrx.unitId ? null : +splitTrx.unitId;
    }

    delete trx.unitId;

    return trx;
  });
