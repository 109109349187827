import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useBaselaneStyleConfig } from '@core/components/Shared/hooks';

type BaselaneCardProps = {
  id: String,
  size: 'sm' | 'md' | 'lg',
  variant: 'normal' | 'dashed' | 'inner' | 'clickable' | 'innerClickable',
  palette: 'dark' | 'light' | 'empty' | 'primary',
  onClick: Function,
  children: React.ReactNode,
};

const BaselaneCardNew = ({
  id,
  variant,
  size,
  palette,
  onClick = () => {},
  children = null,
  ...rest
}: BaselaneCardProps) => {
  const styles = useBaselaneStyleConfig('BaselaneCard', { variant, size, palette });
  const isClickable = ['clickable', 'innerClickable'].includes(variant);
  const ContainingElement = isClickable ? Button : Box;
  return (
    <ContainingElement
      id={id}
      __css={{ ...styles, appearance: 'none' }}
      size={size}
      {...rest}
      {...(isClickable && { onClick })}
    >
      {children}
    </ContainingElement>
  );
};

export default BaselaneCardNew;
