/* eslint-disable react/prop-types */
import React from 'react';
import { FormControl, FormLabel, Text, HStack } from '@chakra-ui/react';
import { Field, useField } from 'formik';
import { T2Dropdown, NoPropertyTooltip, BaselaneFormErrorMessage } from '@shared/components';
import useProperties from '@features/Property/useProperties';
import { itemRenderer } from '@core/components/Shared/components/BaselaneDropdown-new/helpers/itemRenderer.helpers';
import {
  DisplayInputWrapperWithRightButton,
  Placeholder,
} from '@shared/components/BaselaneDropdown-new/DisplayInputVariations';

const DropDownDisplayInput = ({
  selectedOptions,
  handleContentRendererClick,
  handleClearClick,
  placeholder,
  title,
  classNames,
}) => {
  return (
    <DisplayInputWrapperWithRightButton
      handleContentRendererClick={handleContentRendererClick}
      handleClearClick={handleClearClick}
      selectedOptions={selectedOptions}
      classNames={classNames}
    >
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder placeholder={placeholder} title={title} />
      ) : (
        <HStack color="brand.neutral.500" overflow="hidden">
          <Text
            color="brand.neutral.900"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {selectedOptions[0].showValueName ?? selectedOptions[0].name}
          </Text>
        </HStack>
      )}
    </DisplayInputWrapperWithRightButton>
  );
};

const Property = () => {
  const { propertiesForDropdown, propertiesByIdForDropdownById } = useProperties();
  const setPropertyId = useField('propertyId')[2].setValue;

  return (
    <Field name="propertyId">
      {({ field, form }) => (
        <FormControl isInvalid={form.errors.propertyId && form.touched.propertyId} mt={3}>
          <FormLabel display="flex">
            Property
            <Text color="brand.neutral.500" ml={0.5}>
              (optional)
            </Text>
          </FormLabel>
          {propertiesForDropdown.length > 0 ? (
            <T2Dropdown
              additionalProps={{
                id: 'propertyId',
              }}
              classNames={['input-form-lg', 'is-full-width']}
              data={propertiesForDropdown}
              searchTerm={['name']}
              title="Property"
              showValueByFields={['name']}
              placeholder="Select"
              //  The following renderers are copied from "create manual transaction feature".
              parentItemRenderer={itemRenderer}
              childItemRenderer={itemRenderer}
              handleSubmit={(value) => {
                // For some reason, clicking clear makes dropdown return an empty array instead of null.
                const newValue = Array.isArray(value) && value.length === 0 ? null : value;
                setPropertyId(newValue);
              }}
              selectedItem={propertiesByIdForDropdownById[field.value]}
              isMulti={false}
              hasFilterWrapper={false}
              CustomDisplayInput={DropDownDisplayInput}
            />
          ) : (
            <NoPropertyTooltip>
              <T2Dropdown
                isDisabled
                additionalProps={{
                  id: 'propertyId',
                }}
                classNames={['input-form-lg', 'is-full-width']}
                data={propertiesForDropdown}
                title="Property"
                placeholder="Select"
                CustomDisplayInput={DropDownDisplayInput}
              />
            </NoPropertyTooltip>
          )}
          <BaselaneFormErrorMessage isInvalid={form.errors.propertyId && form.touched.propertyId}>
            {form.errors.propertyId}
          </BaselaneFormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default Property;
