import React from 'react';

function IconSkeletonCircle1({
  width = 24,
  height = 24,
  cWidth = 12,
  cHeight = 12,
  color = 'currentColor',
  cBorderRadius = 12,
}: {
  width?: string,
  height?: string,
  cWidth?: string,
  cHeight?: string,
  color?: string,
  cBorderRadius?: string,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={cWidth} cy={cHeight} r={cBorderRadius} fill={color} />
    </svg>
  );
}

IconSkeletonCircle1.defaultProps = {
  width: '24',
  height: '24',
  cWidth: '12',
  cHeight: '12',
  color: '#F0F2F6',
  cBorderRadius: '12',
};

export default IconSkeletonCircle1;
