// @flow
import React, { useEffect, useState } from 'react';
import { Text, HStack, Spinner, Box } from '@chakra-ui/react';
import { IconChevronRight } from '@icons';
import AddBankAccount from '../../../AddBankAccount';
import { balanceTextStyles } from './styles/card.style';
import BaselaneResponsiveCard from './BaselaneResponsiveCard';
import BaselaneBadge from '../BaselaneBadge';

import { IconLogo, formatAccount } from './helpers/account.helper';

type Props = {
  account: Object,
  accountRef?: any,
  refetchBankSummary?: Function,
  refetchFunction?: Function,
  updateLinkToken: string,
  updateLoading: boolean,
  isResyncSuccessfull: boolean,
};

function BaselaneMobileBankAccountCard({
  account,
  accountRef,
  refetchBankSummary,
  updateLinkToken,
  updateLoading,
  isResyncSuccessfull,
  refetchFunction,
  ...rest
}: Props): any {
  const formattedAccount = formatAccount(account, account.isExternal);

  const action = {
    label: 'Details',
    showLabel: true,
    icon: <IconChevronRight />,
    onClick: () => {
      accountRef.current?.open();
    },
  };

  const [accountConnectionState, setAccountConnectionState] = useState(account.connectionState);
  const handleSuccessFn = (res) => {
    const { connectionState } = res?.data?.reSyncExternalBankAccount || {};
    if (connectionState === 'CONNECTED') {
      setAccountConnectionState(connectionState);
      setTimeout(() => {
        refetchBankSummary();
      }, '3000');
    }
  };

  useEffect(() => {
    setAccountConnectionState(account.connectionState);
  }, [account]);

  const getAccountStatus = () => {
    return (
      <HStack ml="32px">
        {account.isBankExternal && (
          <HStack>
            <HStack {...{ mr: '8px' }}>
              {accountConnectionState === 'CONNECTED' ? (
                <BaselaneBadge
                  type="badge"
                  color="green"
                  text="connected"
                  backgroundStyles={{ w: 'fit-content' }}
                />
              ) : (
                <BaselaneBadge
                  type="badge"
                  color="red"
                  text="disconnected"
                  backgroundStyles={{ w: 'fit-content' }}
                />
              )}
            </HStack>
            {accountConnectionState === 'ITEM_LOGIN_REQUIRED' && (
              <AddBankAccount
                bankAccId={account.id}
                mode="RESYNC"
                titleText="Resync"
                variant="tonal"
                palette="primary"
                refetchFunction={refetchFunction}
                handleSuccessFn={handleSuccessFn}
                updateLinkToken={updateLinkToken}
                isLoading={updateLoading}
              />
            )}
          </HStack>
        )}
      </HStack>
    );
  };

  return (
    <BaselaneResponsiveCard
      {...{
        heading: formattedAccount.nickName,
        icon: IconLogo(account, {
          w: '24px !important',
          h: '24px !important',
          display: 'block',
        }),
        iconSize: 24,
        action,
        p: '0 !important',
        ...rest,
      }}
    >
      {formattedAccount.value &&
        (isResyncSuccessfull ? (
          <Box marginLeft="24px">
            <Spinner size="xs" />
          </Box>
        ) : (
          <Text {...balanceTextStyles}>{formattedAccount.value}</Text>
        ))}
      {getAccountStatus(account)}
    </BaselaneResponsiveCard>
  );
}

BaselaneMobileBankAccountCard.defaultProps = {
  accountRef: null,
  refetchBankSummary: null,
  refetchFunction: null,
};

export default BaselaneMobileBankAccountCard;
