// @flow

import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-dropdown-select';
import { Box, Text } from '@chakra-ui/react';
import formatDateTwoDigitDay from '@core/utils/formatDateTwoDigitDay';
import { truncatedText } from '@shared/styles/text.style';

import FilterWrapper from '../FilterWrapper';
import DropdownWithNoHeader from './components/DropdownWithNoHeader';

import { customDropdownHandleRenderer } from '../helpers/dropdown.shared.helpers';
import { isSameDay, DATE_OPTIONS, getCustomDateOption } from './helpers/datepickerDropdown.helpers';

import '../styles/dropdown.styles.scss';
import { dropdownContentContainerStyles } from '../styles/dropdown.styles';

type DatePickerDropdownProps = {
  classNames?: Array<string>,
  title: string,
  selectedStartDate?: string,
  selectedEndDate?: string,
  handleSubmit: Function,
  isMulti?: boolean,
  isDisabled?: boolean,
  parentId?: any,
  hasFilterWrapper?: boolean,
  hasDropdownClearedExternally: Boolean,
  setHasDropdownClearedExternally: Function,
  defaultTimePeriod?: string,
  defaultTimePeriodDate?: Object,
  clearButtonText?: string,
  placeholder?: string,
  onClearClick?: Function,
  dropdownPosition?: string,
};

const DatePickerDropdown = ({
  classNames,
  title,
  selectedStartDate,
  selectedEndDate,
  handleSubmit,
  isMulti,
  isDisabled,
  parentId,
  hasFilterWrapper,
  hasDropdownClearedExternally,
  setHasDropdownClearedExternally,
  defaultTimePeriod,
  defaultTimePeriodDate,
  clearButtonText,
  placeholder,
  onClearClick,
  dropdownPosition,
}: DatePickerDropdownProps) => {
  const [selectedDateOption, setSelectedDateOption] = useState();

  // used to set selectedDateOption, but only after the apply button is clicked
  const [deferredSelectedDateOption, setDeferredSelectedDateOption] = useState();

  const [finalDate, setFinalDate] = useState({ start: selectedStartDate, end: selectedEndDate });

  const [selectedStagedDate, setSelectedStagedDate] = useState({
    start: finalDate.start,
    end: finalDate.end,
  });

  const [inputDate, setInputDate] = useState({ start: null, end: null });
  const [isInvalidDate, setIsInvalidDate] = useState(false);

  const startInputRef = useRef(null);
  const endInputRef = useRef(null);
  const dateInputRef = useRef(null);

  const handleLabelClick = (option, from, to) => {
    setDeferredSelectedDateOption(option); // use this to update start and end date
    setSelectedStagedDate({ start: from, end: to });
    setIsInvalidDate(false);
    setInputDate({
      start: formatDateTwoDigitDay(option.from),
      end: formatDateTwoDigitDay(option.to),
    });
  };

  const handleClearClick = () => {
    // seems like this component is by default clearing both selected and staged data
    if (Object.keys(defaultTimePeriodDate).length > 0) {
      const timePeriod = DATE_OPTIONS.find((option) => option.label === defaultTimePeriodDate.name);
      handleLabelClick(timePeriod, timePeriod.from, timePeriod.to);
    } else {
      setSelectedDateOption(null);
      setSelectedStagedDate({ start: null, end: null });
      setInputDate({ start: null, end: null });
      setFinalDate({ start: selectedStartDate, end: selectedEndDate });
    }

    if (onClearClick) {
      onClearClick();
    }
  };

  const onDatePickerValueChange = (dates) => {
    const [start, end] = dates;
    setSelectedStagedDate({ start, end });
    setDeferredSelectedDateOption(null);
    setInputDate({
      start: formatDateTwoDigitDay(dates[0]),
      end: dates[1] ? formatDateTwoDigitDay(dates[1]) : formatDateTwoDigitDay(dates[0]),
    });
    setIsInvalidDate(false);
  };

  const getDateInput = () => {
    let dateInput = `${finalDate.start ? formatDateTwoDigitDay(finalDate.start) : ''} ${
      finalDate.end ? `- ${formatDateTwoDigitDay(finalDate.end)}` : ''
    }`;

    if (finalDate.start && !finalDate.end)
      dateInput = `${formatDateTwoDigitDay(finalDate.start)} - ${formatDateTwoDigitDay(
        finalDate.start
      )}`;

    return dateInput;
  };

  /*  
      Note: These functions are unused while the inputs are read-only. 

      const toDateObject = (dateString: string): Date | null => {
        const dateObject = new Date(dateString);
        return dateObject.toString() === 'Invalid Date' ? null : dateObject;
      };

      const onDatePickerInputChange = ({ startDate = null, endDate = null }) => {
      const start = startDate || inputDate.start;
      const end = endDate || inputDate.end;

      const startFormatted = toDateObject(start);
      const endFormatted = toDateObject(end);

      setInputDate({ start, end });
      setSelectedStagedDate({ start: startFormatted, end: endFormatted });
      setDeferredSelectedDateOption(null);
    };

    const handleKeyPress = (e) => {
      const wasTabPressed = e?.keyCode === 9;
      const wasShiftPressed = e?.shiftKey;
      const startInput = startInputRef.current.inputElement;
      const endInput = endInputRef.current.inputElement;

      if (e.target.name === startInputRef.current.inputElement.name) {
        // start date input
        if (!wasShiftPressed && wasTabPressed) {
          endInput.setSelectionRange(0, 0);
          endInput.focus();
        }
      } else if (e.target.name === endInputRef.current.inputElement.name) {
        // end date input
        if (wasShiftPressed && wasTabPressed) {
          startInput.setSelectionRange(startInput.value.length, startInput.value.length);
          startInput.focus();
        }
      }
    }; */

  const handleClearAndApply = () => {
    setFinalDate({ start: null, end: null });
    handleClearClick();
  };

  const calculateDropdownSize = () => {
    const selectDropdown = document.querySelector('.react-dropdown-select-dropdown');
    const selectElem = selectDropdown.parentElement;
    const parentElem = document.querySelector(`#${parentId}`);

    // If there isn't enough space on the right, open the dropdown to the left
    const availableWidth = parentElem.clientWidth;
    const availableSpaceOnLeft = selectElem.offsetLeft;
    const availableSpaceForDropdown = availableWidth - availableSpaceOnLeft;
    const dropdownWidth = selectDropdown.clientWidth;

    // If there isn't enough space on the bottom/top, adjust the height of the dropdown
    const availableHeight = parentElem.clientHeight;
    const selectHeight = selectElem.offsetHeight;
    const availableSpaceOnTopForParent = parentElem.getBoundingClientRect().top;
    const availableSpaceOnTopForSelect = selectElem.getBoundingClientRect().top;
    const availableSpaceOnTop = availableSpaceOnTopForSelect - availableSpaceOnTopForParent;
    const availableSpaceBetweenSelectAndDropdown =
      selectDropdown.offsetTop - selectElem.clientHeight;

    const availableSpaceOnBottomForDropdown =
      availableHeight - availableSpaceOnTop - selectHeight - availableSpaceBetweenSelectAndDropdown;
    const availableSpaceOnTopForDropdown =
      availableHeight -
      availableSpaceOnBottomForDropdown -
      selectHeight -
      availableSpaceBetweenSelectAndDropdown;
    const dropdownHeight = selectDropdown.clientHeight;

    const positionName =
      Array.from(selectDropdown.classList).find((classname) => classname.includes('position')) ??
      '';
    const position = positionName.includes('top') ? 'top' : 'bottom';

    if (availableSpaceForDropdown < dropdownWidth) {
      selectDropdown.style.right = '0';
      selectDropdown.style.left = 'auto';
    }

    if (position === 'bottom' && availableSpaceOnBottomForDropdown < dropdownHeight) {
      selectDropdown.style.minHeight = `${availableSpaceOnBottomForDropdown}px`;
      selectDropdown.style.height = `${availableSpaceOnBottomForDropdown}px`;
    } else if (position === 'top' && availableSpaceOnTopForDropdown < dropdownHeight) {
      selectDropdown.style.minHeight = `${availableSpaceOnTopForDropdown}px`;
      selectDropdown.style.height = `${availableSpaceOnTopForDropdown}px`;
    }
  };

  const selectInputRenderer = ({ state, methods }: { state: Object, methods: Object }) => {
    const selectClick = () => {
      if (state?.dropdown) {
        methods?.dropDown('close');
      }
    };

    let inputValues = title;
    if (finalDate.end && finalDate.start && !selectedDateOption) {
      inputValues = getDateInput();
    } else if (finalDate.end && finalDate.start && selectedDateOption) {
      inputValues = selectedDateOption?.label;
    }

    const values =
      inputValues === title ? (
        // when select shows generic title
        <Box className="placeholder" {...truncatedText}>
          {placeholder ?? title}
        </Box>
      ) : (
        // specific selected item
        <Text {...truncatedText}>{inputValues}</Text>
      );

    return (
      <Box {...dropdownContentContainerStyles} onClick={selectClick}>
        {values}
      </Box>
    );
  };

  const selectDropdownContentRenderer = ({ methods }: { methods: Object }) => {
    const { dropDown } = methods ?? {};

    const handleApplyClick = () => {
      setSelectedDateOption(deferredSelectedDateOption);

      if (isMulti) {
        setFinalDate({
          start: selectedStagedDate.start,
          end: selectedStagedDate.end || selectedStagedDate.start,
        });
      }

      dropDown('close');
      // if there is no staged end date selected use the selected staged startDate to get that days transactions
      handleSubmit(
        selectedStagedDate.start,
        selectedStagedDate.end || selectedStagedDate.start,
        deferredSelectedDateOption?.label || 'Custom'
      );
    };

    return hasFilterWrapper ? (
      <FilterWrapper
        {...{
          title: 'date',
          isMulti,
          onClear: handleClearClick,
          onApply: handleApplyClick,
          isInvalidInput: isInvalidDate,
          clearButtonText,
        }}
      >
        <DropdownWithNoHeader
          {...{
            hasFilterWrapper,
            methods,
            inputDate,
            deferredSelectedDateOption,
            selectedStagedDate,
            selectedDateOption,
            startInputRef,
            endInputRef,
            dateInputRef,
            onDatePickerValueChange,
            handleLabelClick,
            handleApplyClick,
          }}
        />
      </FilterWrapper>
    ) : (
      <DropdownWithNoHeader
        {...{
          hasFilterWrapper,
          methods,
          inputDate,
          deferredSelectedDateOption,
          selectedStagedDate,
          selectedDateOption,
          startInputRef,
          endInputRef,
          dateInputRef,
          onDatePickerValueChange,
          handleLabelClick,
          handleApplyClick,
        }}
      />
    );
  };

  useEffect(() => {
    if (hasDropdownClearedExternally) {
      setHasDropdownClearedExternally(false);
      handleClearAndApply();
    }
  }, [hasDropdownClearedExternally]);

  /**
   * Configures the initial state of the picker
   * when rendering.
   */
  useEffect(() => {
    let timePeriod;

    if (selectedStartDate !== finalDate.start && selectedEndDate !== finalDate.end) {
      /** Custom date selection - generate a label to match selection. */
      timePeriod = getCustomDateOption(finalDate.start, finalDate.end);
    } else if (selectedStartDate && selectedEndDate) {
      /** Apply the pre-selected value to the picker if supplied, used to persist the value displayed. */
      [timePeriod] = DATE_OPTIONS.filter((option) => {
        return isSameDay(selectedStartDate, option.from) && isSameDay(selectedEndDate, option.to);
      });
    } else if (defaultTimePeriod) {
      /** Fall back to default value if it is supplied.
       */
      [timePeriod] = DATE_OPTIONS.filter((option) => {
        return option.label === defaultTimePeriodDate?.name;
      });
    }

    if (!timePeriod) return; // end if a timePeriod could not be determined

    /** Manually set the datepicker up based on the above timePeriod */
    setSelectedDateOption(timePeriod);
    setFinalDate({ start: timePeriod?.from, end: timePeriod?.to });
    setInputDate({
      start: formatDateTwoDigitDay(timePeriod?.from),
      end: formatDateTwoDigitDay(timePeriod?.to),
    });
  }, [defaultTimePeriod, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    startInputRef?.current?.inputElement?.focus();
  }, []);

  return (
    <Select
      className={`date-dropdown ${classNames.join(' ')}`}
      dropdownPosition={dropdownPosition}
      closeOnSelect={!isMulti}
      multi={isMulti}
      disabled={isDisabled}
      closeOnScroll
      dropdownGap={0}
      dropdownRenderer={selectDropdownContentRenderer}
      contentRenderer={selectInputRenderer}
      dropdownHandleRenderer={customDropdownHandleRenderer}
      onDropdownOpen={calculateDropdownSize}
    />
  );
};

DatePickerDropdown.defaultProps = {
  classNames: ['fixed-width-dropdown'],
  isMulti: false,
  isDisabled: false,
  hasFilterWrapper: false,
  selectedStartDate: null,
  selectedEndDate: null,
  parentId: 'page-wrapper',
  defaultTimePeriod: null,
  defaultTimePeriodDate: {},
  clearButtonText: 'Clear',
  placeholder: null,
  onClearClick: null,
  dropdownPosition: 'auto',
};

export default DatePickerDropdown;
