export const disclaimerText = {
  banksAndCards: {
    showPrivacyPolicy: true,
  },
  addAccountDrawer: {
    showPrivacyPolicy: true,
  },
  addFunds: {
    text:
      'By clicking the ‘Add Funds’ button, I authorize Baselane to credit the above amount to my bank account.  Once I authorize this payment, I understand there cannot be any changes or corrections.',
    showPrivacyPolicy: false,
  },
  makeTransferInternal: {
    text:
      'By clicking the ‘Make Transfer’ button, I authorize Baselane to make a transfer between my accounts.',
    showPrivacyPolicy: false,
  },
  makeTransferExternal: {
    text:
      'By clicking the ‘Make Transfer’ button, I authorize Baselane to debit the above amount from my bank account on the date shown.  If this transaction is for a future date, I acknowledge that this authorization will remain valid unless canceled at least 1 day before the transfer date.  Once I authorize this payment, I understand there cannot be any changes or corrections.',
    showPrivacyPolicy: false,
  },
};
