import React from 'react';
import { Input, Stack, Text } from '@chakra-ui/react';
import { sortWithString } from '@core/utils/sort';
import BaselaneRadioCardList from '../../../BaselaneRadioCardList';
import { formatAddress } from '../../helpers/shared.helpers';
import { selectPropertyStyles, searchStyles } from './styles/selectProperty.styles';

type SelectPropertyProps = {
  sortedProperties: Array,
  selectedProperty: String,
  selectedUnit: String,
  setSelectedUnit: Function,
};

const SelectUnit = ({
  sortedProperties,
  selectedProperty,
  selectedUnit,
  setSelectedUnit,
}: SelectPropertyProps) => {
  const { textcontainer, title, description } = selectPropertyStyles ?? {};

  const propertyObj = sortedProperties.find((property) => property.id === selectedProperty);
  const { address: addressObj, units } = propertyObj ?? {};
  const propertyAddress = formatAddress(addressObj);
  const sortedUnits = sortWithString(units, 'name');

  const handleUnitClick = (unit) => {
    setSelectedUnit(unit?.id);
  };

  return (
    <>
      <Stack {...textcontainer}>
        <Text {...title}>Select the unit to create lease document for</Text>
        <Text {...description}>
          To add or edit units, go to the Properties page, and then return to the lease creation.
        </Text>
      </Stack>

      {/* Selected Property Address */}
      <Input {...{ ...searchStyles, mb: '8px' }} value={propertyAddress} disabled />

      {/* List of Units */}
      <BaselaneRadioCardList
        {...{
          list: sortedUnits,
          onListItemClick: handleUnitClick,
          showValueBy: 'name',
          selectedListItem: selectedUnit,
          name: 'Unit',
          search: '',
          showSearch: false,
        }}
      />
    </>
  );
};

export default SelectUnit;
