import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, ChakraProvider, HStack, Heading } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import {
  BaselaneButton,
  BaselaneDivider,
  BaselaneDrawer,
  BaselaneSummaryCard,
  BaselaneSummaryCardDefaultHeader,
} from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import SlLoader from '@core/components/Loader';
import DisableMoveMoney from '@core/components/DisableMoveMoney';
import getBreakPoints from '@core/utils/getBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import habitatTheme from '@core/themeHabitat';

import * as ROUTES from '@routes';

import { GET_BANK_INFO, GET_PAYEE } from '@core/apollo/queries';
import { Icon16Delete, Icon16Edit, Icon16Send } from '@icons/16px';
import clearLocationState from '@core/utils/clearLocationState';

import PaymentMethodStack from './components/PaymentMethodStack';
import {
  restructureForForm,
  patchNameOntoPaymentMethods,
  patchBankNamesOntoPaymentMethods,
} from './helpers/content.helpers';

type RecipientDetailsProps = {
  from?: string,
};

const RecipientDetails = ({ from = null }: RecipientDetailsProps) => {
  const initialPaymentMethods = {
    achPaymentMethods: null,
    wirePaymentMethods: null,
    checkPaymentMethods: null,
  };

  const { isMax576 } = getBreakPoints();

  const actionButtonSize = isMax576 ? 'md' : 'lg';

  const navigate = useNavigate();
  const { recipientId } = useParams();

  // load payee dataPayee
  const { error, loading: isLoading, data: dataPayee } = useQuery(GET_PAYEE, {
    skip: !recipientId,
    variables: { id: recipientId },
    fetchPolicy: 'network-only',
  });

  // load bankname for ach if present
  const { data: dataACHBankName } = useQuery(GET_BANK_INFO, {
    skip: !recipientId || !dataPayee?.payee?.paymentMethods?.achPaymentMethods,
    variables: {
      routingNumber: dataPayee?.payee?.paymentMethods?.achPaymentMethods?.routingNumber,
    },
    fetchPolicy: 'network-only',
  });

  // load bankname for wire if present
  const { data: dataWireBankName } = useQuery(GET_BANK_INFO, {
    skip: !recipientId || !dataPayee?.payee?.paymentMethods?.wirePaymentMethods,
    variables: {
      routingNumber: dataPayee?.payee?.paymentMethods?.wirePaymentMethods?.routingNumber,
    },
    fetchPolicy: 'network-only',
  });

  const [paymentMethods, setPaymentMethods] = useState(initialPaymentMethods);

  const { pathname, state } = useLocation() || {};
  const {
    isDeleteRecipientSuccessful,
    isAddPaymentMethodSuccessful,
    isUpdatePaymentMethodSuccessful,
    isDeletePaymentMethodSuccessful,
  } = state || {};
  const isEditing = pathname.includes('edit');

  // prefill form with loaded data
  useEffect(() => {
    if (dataPayee && dataPayee?.payee) {
      const { paymentMethods: existingPaymentMethods } = dataPayee.payee;
      const formFormattedExistingPaymentMethods = dataPayee?.payee?.name
        ? patchNameOntoPaymentMethods(
            dataPayee?.payee?.name,
            restructureForForm(existingPaymentMethods)
          )
        : restructureForForm(existingPaymentMethods);
      setPaymentMethods(
        patchBankNamesOntoPaymentMethods(
          formFormattedExistingPaymentMethods,
          dataACHBankName?.bankInfo?.name,
          dataWireBankName?.bankInfo?.name
        )
      );
    }
  }, [dataPayee]);

  useEffect(() => {
    setPaymentMethods((existingPaymentMethods) =>
      patchBankNamesOntoPaymentMethods(
        existingPaymentMethods,
        dataACHBankName?.bankInfo?.name,
        dataWireBankName?.bankInfo?.name
      )
    );
  }, [dataACHBankName?.bankInfo?.name, dataWireBankName?.bankInfo?.name]);

  // if the payee id doesn't exist close drawer
  useEffect(() => {
    if (error?.message?.includes('not found')) {
      navigate(from, { replace: true });
    }
  }, [error]);

  useEffect(() => {
    // The recipient was deleted, go to previous screen with success state
    if (isDeleteRecipientSuccessful) {
      navigate(from, { replace: true, state: { isDeleteRecipientSuccessful } });
    }

    // A payment method was added or edited successfully, update the payment methods
    // also prefill the bankName if available for ACH or Wire
    if (isAddPaymentMethodSuccessful || isUpdatePaymentMethodSuccessful) {
      const { paymentMethod } = state || {};
      setPaymentMethods((oldPaymentMethods) => {
        const updatedPaymentMethods = {
          ...oldPaymentMethods,
          ...restructureForForm({ ...paymentMethod }),
        };
        return patchBankNamesOntoPaymentMethods(
          updatedPaymentMethods,
          dataACHBankName?.bankInfo?.name,
          dataWireBankName?.bankInfo?.name
        );
      });
    }

    // A payment method was deleted successfully, update the payment methods
    if (isDeletePaymentMethodSuccessful) {
      const { deletedPaymentMethodId } = state || {};
      setPaymentMethods((oldPaymentMethods) => {
        const filteredPaymentMethods = {};
        Object.keys(oldPaymentMethods).forEach((key) => {
          if (oldPaymentMethods[key]?.id !== deletedPaymentMethodId) {
            filteredPaymentMethods[key] = oldPaymentMethods[key];
          }
        });
        return patchBankNamesOntoPaymentMethods(
          filteredPaymentMethods,
          dataACHBankName?.bankInfo?.name,
          dataWireBankName?.bankInfo?.name
        );
      });
    }

    clearLocationState();
  }, [state]);

  const handleDeleteRecipientClick = () => {
    navigate(ROUTES.TRANSFERS_RECIPIENT_DELETE);
  };

  const capitalizeFirstChar = (string) =>
    string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';

  const header = (
    <BaselaneSummaryCardDefaultHeader
      {...{
        title: dataPayee?.payee?.name,
        subtitle: dataPayee?.payee?.email,
      }}
    />
  );
  const list = [
    {
      id: 'recipient-type-group',
      items: [
        {
          id: 'recipient-type',
          label: 'Recipient type',
          value: capitalizeFirstChar(dataPayee?.payee?.type),
        },
      ],
    },
  ];
  return (
    <ChakraProvider theme={habitatTheme}>
      <BaselaneDrawer
        isOpen
        size={isMax576 ? 'newdrawerfull' : 'newdrawersm'}
        title="Recipient Details"
        closeEvent={() => onDrawerClose(navigate, from)}
        onOverlayClick={() => onDrawerClose(navigate, from)}
        closeOnOverlayClick={false}
        newDesignDrawer
      >
        {isLoading && (
          <Box>
            <SlLoader styles={{ position: 'absolute !important' }} />
          </Box>
        )}
        {!isLoading && !error && (
          <>
            <Outlet />
            {!isEditing && (
              <>
                <BaselaneSummaryCard isCustom header={header} list={list} />

                <HStack mt={2} gap={2}>
                  <DisableMoveMoney
                    tooltipText="Verify your email to send money"
                    renderItem={(isDisabled) => (
                      <BaselaneButton
                        variant="filled"
                        palette="primary"
                        styles={{ fontWeight: 'medium' }}
                        size={actionButtonSize}
                        leftIcon={<Icon16Send />}
                        isDisabled={isDisabled}
                        onClick={() => {
                          navigate(ROUTES.SEND_FUNDS);
                          sendSegmentEvent('baselane_banking_send_funds_started', {
                            entry_point: 'recipient_details',
                          });
                        }}
                      >
                        Send money
                      </BaselaneButton>
                    )}
                  />
                  <BaselaneButton
                    variant="outline"
                    palette="primary"
                    styles={{ fontWeight: 'medium' }}
                    size={actionButtonSize}
                    leftIcon={<Icon16Edit />}
                    onClick={() =>
                      navigate(ROUTES.TRANSFERS_RECIPIENTS_DETAILS_EDIT, {
                        state: {
                          name: dataPayee?.payee?.name,
                          type: dataPayee?.payee?.type,
                          email: dataPayee?.payee?.email,
                          from: pathname,
                          isDeletable: dataPayee?.payee?.isDeletable,
                        },
                      })
                    }
                  >
                    Edit
                  </BaselaneButton>
                  <BaselaneButton
                    variant="outline"
                    palette="danger"
                    styles={{ fontWeight: 'medium' }}
                    size={actionButtonSize}
                    leftIcon={<Icon16Delete />}
                    onClick={handleDeleteRecipientClick}
                    isDisabled={!dataPayee?.payee?.isDeletable}
                    tooltipText={
                      !dataPayee?.payee?.isDeletable &&
                      'Payment method cannot be deleted as there is a payment scheduled for it'
                    }
                  >
                    Delete
                  </BaselaneButton>
                </HStack>
              </>
            )}
            <BaselaneDivider styles={{ my: 3 }} />
            <Heading as="h3" size="headline-md" fontWeight="medium" mb={2}>
              Payment details
            </Heading>
            <PaymentMethodStack
              paymentMethods={paymentMethods}
              setPaymentMethods={setPaymentMethods}
            />
          </>
        )}
      </BaselaneDrawer>
    </ChakraProvider>
  );
};

export default RecipientDetails;
