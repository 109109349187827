export const headerWrapper = {
  mb: '24px',
  alignItems: 'center',
};

export const mobileHeaderWrapper = {
  mb: '8px',
  alignItems: 'center',
};

export const smallText = {
  textStyle: 'xs',
  color: 'brand.neutral.700',
};

export const smallGreyText = {
  fontSize: 'sm',
  color: 'brand.neutral.700',
  lineHeight: '20px',
};

export const currencyText = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
};

export const smallGrey2Text = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
};

export const extraSmallText = {
  fontSize: '2xs',
  color: 'brand.neutral.500',
  lineHeight: '12px',
};

export const sortHeaderContainer = {
  bg: 'brand.blue.50',
  h: '48px',
  w: '100%',
  alignItems: 'center',
  borderRadius: '4px',
  justifyContent: 'space-between',
  paddingRight: '80px', // space for row arrow
};

export const normalText = {
  color: 'brand.neutral.700',
  fontSize: 'sm',
  lineHeight: '24px',
};

export const labelText = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
  mb: '8px',
};

export const drawerBodyStyles = {
  p: '64px 76px',
};
