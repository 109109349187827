import React from 'react';
import { Spacer } from '@chakra-ui/react';
import BaselaneButton from '../../BaselaneButton';
import { actionTypeLinkStyles } from '../styles/actionTypes.styles';

type ActionTypeLinkProps = {
  isVertical: boolean,
  variant: string,
  link: {
    label: string,
    onClick: Function,
    styles?: Object,
  },
};

const ActionTypeLink = ({ isVertical, variant, link }: ActionTypeLinkProps) => {
  const defaultStyles = actionTypeLinkStyles({ variant });

  return (
    link && (
      <>
        {!isVertical && <Spacer />}
        <BaselaneButton {...{ ...defaultStyles, ...link?.styles }} onClick={link?.onClick}>
          {link?.label}
        </BaselaneButton>
      </>
    )
  );
};

export default ActionTypeLink;
