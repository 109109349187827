import React from 'react';
import { Text } from '@chakra-ui/react';
import { formatDate } from '@core/utils/formatDate';
import { CurrencyText } from '@shared/components';
import { truncatedText } from '@shared/styles/text.style';

export const headerItems = [
  { element: <Text>Merchant</Text> },
  { element: <Text>Date</Text> },
  { element: <Text>Amount</Text> },
];

export const parentTransactionItems = (transaction) => [
  {
    element: transaction.merchantName ? (
      <Text {...truncatedText}>{transaction.merchantName}</Text>
    ) : (
      <div>&mdash;</div>
    ),
  },
  { element: <Text>{formatDate(transaction.date)}</Text> },
  {
    element: (
      <CurrencyText
        isRounded={false}
        textStyle="xs"
        color="brand.neutral.700"
        negativeColor="brand.neutral.700"
        amount={transaction.amount}
      />
    ),
  },
];
