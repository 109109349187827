export const containerStyles = {
  px: '24px',
  mb: '24px',
  h: '40px',
  mt: '16px',
};

export const customComponentWrapperStyles = {
  p: '16px 16px 0',
};

export const customComponentTitleStyles = {
  textStyle: 'headline-xs',
  color: 'brand.neutral.600',
  mb: '16px',
};

export const customComponentGroupStyles = {
  color: 'brand.neutral.700',
  display: 'flex',
  flexDir: 'column',
};

export const customComponentRadioStyles = {
  p: '8px 8px 8px 0',
  w: 'fit-content',
};

export const customComponentLabelStyles = {
  textStyle: 'xs',
};

export const centerAlignStyles = {
  display: 'flex',
  alignItems: 'center',
};
