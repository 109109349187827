import React from 'react';
import { Stack } from '@chakra-ui/react';
import SharedHeader from './SharedHeader';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Success from './Success';

type BodyProps = {
  currentStep: Number,
  totalSteps: Number,
  unsplitTagId: Object,
  setUnsplitTagId: Function,
  transferType: String,
  setTransferType: Function,
  getUpdateLinkToken: Function,
  fromDropdownList: Array<Object>,
  toDropdownList: Array<Object>,
  accountsMap: Object,
  propertyIdsMap: Object,
  categoryMap: Object,
  categoryIdsMap: Object,
  categoryOptions: Array<Object>,
  propertyOptions: Array<Object>,
};

const Body = ({
  currentStep,
  totalSteps,
  unsplitTagId,
  setUnsplitTagId,
  transferType,
  setTransferType,
  getUpdateLinkToken,
  fromDropdownList,
  toDropdownList,
  accountsMap,
  propertyIdsMap,
  categoryMap,
  categoryIdsMap,
  categoryOptions,
  propertyOptions,
}: BodyProps) => {
  const getBodyForCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            {...{
              transferType,
              setTransferType,
              getUpdateLinkToken,
              fromDropdownList,
              toDropdownList,
              accountsMap,
            }}
          />
        );

      case 2:
        return (
          <Step2
            {...{
              setUnsplitTagId,
              propertyOptions,
              propertyIdsMap,
              categoryOptions,
              categoryMap,
              categoryIdsMap,
            }}
          />
        );

      case 3:
        return (
          <Step3
            {...{
              transferType,
              unsplitTagId,
              accountsMap,
              propertyIdsMap,
              categoryMap,
            }}
          />
        );

      default:
        return <Success {...{ transferType }} />;
    }
  };

  return (
    <Stack gap={3} height="100%">
      {currentStep !== totalSteps && (
        <SharedHeader {...{ currentStep, totalSteps, transferType }} />
      )}
      <Stack gap={3} height="100%">
        {getBodyForCurrentStep()}
      </Stack>
    </Stack>
  );
};

export default Body;
