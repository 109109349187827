import isValidSSN from '@core/utils/isValidSSN';

export const handleValidation = (values) => {
  const errors = {};

  if (values.ssn === '') {
    errors.ssn = 'Please enter in your Social Security Number';
  }

  if (!values.agreeToTerms) {
    errors.agreeToTerms = 'Please agree to our terms of use before submitting your application';
  }

  const SSNTestNumbers = [
    // PendingReview
    '000000004',
    // AwaitingDocuments - Address Verification
    '000000002',
    // AwaitingDocuments - Id Document
    '000000003',
    // AwaitingDocuments - Social Security Card
    '000000006',
    // Denied
    '000000001',
  ];

  if (values.ssn && values.ssn.includes('tok')) {
    return errors;
  }

  const unmaskedSSN = values.ssn.replace(/-/g, '');
  const validSSN = isValidSSN(values.ssn) || SSNTestNumbers.includes(unmaskedSSN);
  if (!validSSN) {
    errors.ssn = 'Please enter valid Social Security Number';
  }

  return errors;
};

export const initialVariables = (formData) => ({
  ssn: formData?.ssn ?? '',
  agreeToTerms: formData?.agreeToTerms ?? false,
});
