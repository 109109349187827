export const sortHeaderContainer = {
  bg: 'brand.blue.50',
  h: '48px',
  w: '100%',
  alignItems: 'center',
  borderRadius: '4px',
  justifyContent: 'space-between',
  paddingRight: '80px', // space for row arrow
};

export const controlStyles = (isMax576) => ({
  padding: !isMax576 ? '24px 0' : '24px 12px',
  justifyContent: !isMax576 ? 'space-between' : '',
  gap: '12px',
  flexDirection: !isMax576 ? 'row' : 'column',
});

export const labelText = {
  textStyle: 'headline-sm',
  color: 'brand.neutral.700',
  mb: '8px',
};
