import React from 'react';

type IllustrationTransferScheduledProps = { w?: number, h?: number };

function IllustrationTransferScheduled({ w, h }: IllustrationTransferScheduledProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.36778 19.6922L2.74983 20.3492C4.32874 23.0646 6.75189 24.9942 9.5 26M1.08094 12.5451C1.50548 8.58488 3.76539 4.87381 7.50491 2.73011C13.7178 -0.831478 21.6623 1.28213 25.2493 7.45098L25.6314 8.10802M1 23.1045L2.11873 18.959L6.29389 20.0698M21.7061 7.73045L25.8813 8.84124L27 4.6957"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 16.5769C10 18.0638 11.1939 19.2692 12.6667 19.2692H15.1429C16.7208 19.2692 18 17.9777 18 16.3846C18 14.7915 16.7208 13.5 15.1429 13.5H12.8571C11.2792 13.5 10 12.2085 10 10.6154C10 9.02226 11.2792 7.73077 12.8571 7.73077H15.3333C16.8061 7.73077 18 8.93616 18 10.4231M14 6V7.73077M14 19.2692V21"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="27" cy="27" r="8" fill="#8CCFFF" />
      <path
        d="M25 20.2V25L28.2 26.6M33 25C33 29.4183 29.4183 33 25 33C20.5817 33 17 29.4183 17 25C17 20.5817 20.5817 17 25 17C29.4183 17 33 20.5817 33 25Z"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IllustrationTransferScheduled.defaultProps = {
  w: 35,
  h: 35,
};

export default IllustrationTransferScheduled;
