// @flow
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import {
  legendItemContainerStyles,
  legendLabelContainerStyles,
  legendItemIndicatorStyles,
  legendItemLabelStyles,
  legendItemValueStyles,
  legendItemHorizontalContainerStyles,
  getLegendItemValueStylesMobile,
  getLegendItemLabelStylesMobile,
} from './styles/legend.style';

function BaselaneLegendItem({
  label,
  value,
  indicatorColor,
  labelStyle,
  valueStyle,
  indicatorStyle,
  containerStyle,
  labelContainerStyle,
  isHorizontal,
}: {
  label: any,
  value: any,
  indicatorColor: string,
  labelStyle: Object,
  valueStyle: Object,
  indicatorStyle: Object,
  containerStyle: Object,
  labelContainerStyle: Object,
  isHorizontal: boolean,
}): any {
  const horizontalStyles = isHorizontal ? legendItemHorizontalContainerStyles : {};
  const containerStyles = { ...legendItemContainerStyles, ...horizontalStyles, ...containerStyle };
  const indicatorStyles = {
    ...legendItemIndicatorStyles,
    ...{ bg: indicatorColor },
    ...indicatorStyle,
  };
  const labelContainerStyles = { ...legendLabelContainerStyles, ...labelContainerStyle };
  const labelStyles = { ...legendItemLabelStyles, ...labelStyle };
  const valueStyles = { ...legendItemValueStyles, ...valueStyle };

  return (
    <Flex {...containerStyles}>
      <Box {...labelContainerStyles}>
        <Box {...indicatorStyles} />
        <Box {...{ ...labelStyles, ...getLegendItemLabelStylesMobile() }}>{label}</Box>
      </Box>
      <Box {...{ ...valueStyles, ...getLegendItemValueStylesMobile() }}>{value}</Box>
    </Flex>
  );
}

export default BaselaneLegendItem;
