import React from 'react';
import { BaselaneEmptyStateCard } from '@shared/components';
import { LEASES } from '@routes';

const ActiveEmptyState = () => {
  return (
    <BaselaneEmptyStateCard styles={{ boxShadow: 'none' }} title="Your Tenants Page">
      <BaselaneEmptyStateCard.Paragraph>
        The Tenant page is your directory of current and past tenants - quickly access their contact
        information and track if they’ve set up their account on Baselane’s Renters Platform.
      </BaselaneEmptyStateCard.Paragraph>
      <BaselaneEmptyStateCard.Paragraph>
        Once you set up rent collection, your tenant(s) information will appear here.
      </BaselaneEmptyStateCard.Paragraph>
      <BaselaneEmptyStateCard.Button route={LEASES} variant="filled" palette="primary">
        Set Up Rent Collection
      </BaselaneEmptyStateCard.Button>
    </BaselaneEmptyStateCard>
  );
};

export default ActiveEmptyState;
