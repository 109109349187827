// @flow
import React, { useContext, useEffect } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';
import { NavLink, Outlet, useLocation, useParams } from 'react-router-dom';
import getBreakPoints from '@core/utils/getBreakPoints';
import TransactionsPage from '@core/components/Transactions';
import TransactionContext from '@core/contexts/TransactionContext';
import BankEntityContext from '@contexts/BankEntityContext';
import { NB_ACCOUNTS, NB_BANK_ACCOUNT, NB_BANK_ACCOUNT_CARDS } from '@routes';
import {
  tabContainerStyles,
  tabListInternalStyles,
  tabStyles,
  tabPanelsStyles,
  tabWrapperVanillaJsStyles,
} from '@shared/styles/tab.styles';
import useEntityByIdQuery from './shared/useEntityByIdQuery';

const AccountTabs = () => {
  const { setSummaryFilter } = useContext(TransactionContext);
  const { account, activeFilter } = useContext(BankEntityContext);
  const { refetchEntity } = useEntityByIdQuery();

  const location = useLocation();
  const { bankaccount } = useParams();
  const { isMin768 } = getBreakPoints();

  const tabsList = [
    {
      index: 0,
      label: 'Transactions',
      path: '',
    },
    { index: 1, label: 'Cards', path: '/cards' },
  ];
  const tabsIndex = Object.freeze({ [NB_BANK_ACCOUNT]: 0, [NB_BANK_ACCOUNT_CARDS]: 1 });

  useEffect(() => {
    setSummaryFilter({ bankAccountId: activeFilter.bankAccountId });
  }, [activeFilter.bankAccountId?.toString()]);

  const splitPathname = location?.pathname?.split('/');
  const pathname =
    bankaccount && splitPathname.includes('cards')
      ? `${NB_BANK_ACCOUNT}cards`
      : `${NB_BANK_ACCOUNT}`;

  return (
    <Box
      {...tabContainerStyles}
      m={{ base: '16px 16px 24px 16px', md: '24px 32px 24px 32px' }}
      position="relative"
      zIndex="0"
    >
      <Tabs
        isLazy
        variant="unstyled"
        index={bankaccount && tabsIndex[pathname] ? tabsIndex[pathname] : 0}
      >
        <TabList {...tabListInternalStyles()}>
          {tabsList.map((tab) => (
            <NavLink
              key={tab.index}
              to={`${NB_ACCOUNTS}/${bankaccount}${tab.path}`}
              {...tabWrapperVanillaJsStyles(isMin768)}
            >
              <Tab key={tab.index} {...tabStyles(isMin768)}>
                {tab.label}
              </Tab>
            </NavLink>
          ))}
        </TabList>
        <TabPanels {...tabPanelsStyles} p="0">
          {/* Transactions */}
          <TabPanel p={isMin768 && 0} mt={{ lg: 4, base: 0 }}>
            <TransactionsPage
              isEmbeddedInPage
              externalFilters={activeFilter}
              styles={{
                transactionsListContainerStyles: {
                  px: '0 !important',
                  mx: '0 !important',
                },
              }}
            />
            <Outlet context={{ account, refetch: refetchEntity }} />
          </TabPanel>
          {/* Cards */}
          <TabPanel p={isMin768 && 0}>
            <Outlet context={{ account, refetch: refetchEntity }} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default AccountTabs;
