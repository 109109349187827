import process from 'process';
import moment from 'moment';
import { getBank } from '../../../helpers/banks.shared';

export const DEFAULT_FILTERS = {
  filter: { bankAccountId: [], fromDate: '', toDate: '' },
  page: 1,
  pageSize: 50,
  sort: { direction: 'DESC', field: 'period' },
};

const getMonthAndYear = (date, returnValue) => {
  const monthAndYearArray = date?.split('-');
  if (returnValue === 'year') return monthAndYearArray[0] || '';
  if (returnValue === 'month') return monthAndYearArray[1] || '';
  return monthAndYearArray || '';
};

export const getStatementPeriod = (period) => {
  const startDate = `${period}-01`;

  const monthAndYearArray = getMonthAndYear(period);
  const endDate = new Date(monthAndYearArray[0], parseInt(monthAndYearArray[1], 10), 0);

  const formattedStartDate = moment(startDate).format('MMM D');
  const formattedEndDate = moment(endDate).format('MMM DD, YYYY');

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getMonths = () => [
  { id: 0, label: 'All' },
  { id: 1, label: 'Jan' },
  { id: 2, label: 'Feb' },
  { id: 3, label: 'Mar' },
  { id: 4, label: 'Apr' },
  { id: 5, label: 'May' },
  { id: 6, label: 'Jun' },
  { id: 7, label: 'Jul' },
  { id: 8, label: 'Aug' },
  { id: 9, label: 'Sept' },
  { id: 10, label: 'Oct' },
  { id: 11, label: 'Nov' },
  { id: 12, label: 'Dec' },
];

export const getAccountLabel = (status, name) => {
  const isClosedAcc = status.toLowerCase() === 'closed';
  return isClosedAcc ? `(C) ${name}` : name;
};

export const getAccounts = (banks) => {
  const items = (banks || []).reduce((bankAccountsArr, bank) => {
    // default option
    if (bankAccountsArr.length === 0) {
      bankAccountsArr.push({ group: { groupId: 0 }, id: 0, label: 'All' });
    }

    // bank
    const { id, name, bankAccounts } = bank || {};
    const groupItem = { groupId: Number(id), groupName: name || `Baselane ${id}` };
    const bankItem = {
      group: { ...groupItem },
      id: Number(id),
      label: name || `Baselane ${id}`,
      styles: {
        color: 'brand.neutral.700',
        _hover: { color: 'brand.neutral.700', bg: 'brand.darkBlue.50' },
      },
    };
    bankAccountsArr.push(bankItem);

    // accounts
    (bankAccounts || []).forEach((bankAccount) => {
      const { id: mainAccId, nickName: mainAccName, accountStatus: mainAccStatus, subAccounts } =
        bankAccount || {};

      const mainAccountName = mainAccName || 'Main Account';
      const mainAccountLabel = getAccountLabel(mainAccStatus, mainAccountName);

      const mainAccountItem = {
        group: { ...groupItem },
        id: Number(mainAccId),
        label: mainAccountLabel,
        listLabel: mainAccountName,
        styles: { ml: '15px', w: 'calc(100% - 15px)' },
      };

      bankAccountsArr.push(mainAccountItem);

      (subAccounts || []).forEach((subAccount, index) => {
        const { id: subAccId, nickName: subAccName, accountStatus: subAccStatus, accountSubType } =
          subAccount || {};
        const subAccSubType = accountSubType.charAt(0) + accountSubType.slice(1);
        const subAccountName = subAccName || `${subAccSubType} account ${index + 1}`;
        const subAccountLabel = getAccountLabel(subAccStatus, subAccountName);

        const subAccountItem = {
          group: { ...groupItem },
          id: Number(subAccId),
          label: subAccountLabel,
          listLabel: subAccountName,
          styles: { ml: '15px', w: 'calc(100% - 15px)' },
        };

        bankAccountsArr.push(subAccountItem);
      });
    });

    return bankAccountsArr;
  }, []);
  return items;
};

export const getYearFromTo = (year, yearOptions, isFrom) => {
  if (isFrom) return year === 'All' || !year ? yearOptions[yearOptions.length - 1].label : year;
  return year === 'All' || !year ? yearOptions[1].label : year;
};

export const getYears = () => {
  const thisYear = new Date().getFullYear();
  const years = [
    { id: 0, label: 'All' },
    { id: 1, label: thisYear },
  ];

  let yearCounter = thisYear;
  let yearId = 2;
  do {
    const yearToAdd = yearCounter - 1;
    years.push({ id: yearId, label: yearToAdd });
    yearCounter = yearToAdd;
    yearId += 1;
  } while (yearCounter > 2021);

  return years;
};

export const getLastDay = (y, m) => {
  return new Date(y, m, 0).getDate();
};

export const getAccountNameInfo = (accountOptions, bankAccountId) => {
  const account = accountOptions.find((o) => o.id === bankAccountId);

  return { masterAccountName: account?.group?.groupName, accountName: account?.listLabel };
};

const statementsPDFName = (statements, id) => {
  const statement = statements?.find((st) => Number(st.unitStatementId) === id);

  const months = getMonths();
  const monthNumber = getMonthAndYear(statement?.period, 'month');
  const monthText = months.find((m) => m.id === Number(monthNumber))?.label || '';
  const year = getMonthAndYear(statement?.period, 'year');

  return `BASELANE_${statement?.masterAccountName.toUpperCase()}_${statement?.accountName.toUpperCase()}_${monthText.toUpperCase()}_${year}_STATEMENT`;
};

export async function getStatementPdf(id, token, statements, showErrorToast) {
  const response = await fetch(`${process?.env?.REACT_APP_UNIT_SERVICE_URL}/statements/${id}/pdf`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'blob',
      Authorization: `Bearer ${token}`,
    }),
  });

  if (response.status === 200) {
    response.blob().then((blob) => {
      // ref: https://stackoverflow.com/questions/60586001/download-pdf-from-rest-api
      // create a download anchor tag
      const downloadLink = document.createElement('a');
      downloadLink.target = '_blank';
      downloadLink.download = statementsPDFName(statements, id);

      // create an object URL from the Blob
      const URL = window.URL || window.webkitURL;
      const downloadUrl = URL.createObjectURL(blob);

      // set object URL as the anchor's href
      downloadLink.href = downloadUrl;

      // append the anchor to document body
      document.body.append(downloadLink);

      // fire a click event on the anchor
      downloadLink.click();

      // cleanup: remove element and revoke object URL
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(downloadUrl);
    });
  } else {
    showErrorToast('Failed to download statement');
  }
}

export const generateStatementList = (statements, accountOptions, banks) =>
  statements.map((item) => {
    const { masterAccountName: rawMasterAccountName, accountName } = getAccountNameInfo(
      accountOptions,
      item.bankAccountId
    );
    const period = item.period.split('-');
    const year = Number(period[0]);
    const month = Number(period[1]);

    const masterAccountName =
      item?.bankPartner === 'BRB' ? `[OLD] ${rawMasterAccountName}` : rawMasterAccountName;

    return {
      ...item,
      masterAccountName,
      accountName,
      year,
      month,
      bankId: getBank(banks, item.bankAccountId)?.id || '',
    };
  });
