import React from 'react';
import { Textarea, Stack, Text, Box } from '@chakra-ui/react';
import { counterStyles } from './styles/textarea.styles';

function BaselaneTextArea({
  baselaneFormHelperTextComponent,
  hasCounter,
  maxChars,
  value,
  onChange,
  name,
  ...props
}: any) {
  const charactersLength = value?.length ?? 0;
  return (
    <>
      <Textarea name={name} value={value} onChange={onChange} maxLength={maxChars} {...props} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>{baselaneFormHelperTextComponent}</Box>
        <Box>
          {hasCounter && maxChars ? (
            <Text {...counterStyles}>
              {charactersLength}/{maxChars}
            </Text>
          ) : null}
        </Box>
      </Stack>
    </>
  );
}

export default BaselaneTextArea;
