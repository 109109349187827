import React from 'react';

function IconInsurance({ w, h, color }: { w?: Number, h?: Number, color?: String }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2 34V22.16C16.2 21.2639 16.2 20.8159 16.3744 20.4736C16.5278 20.1726 16.7726 19.9278 17.0736 19.7744C17.4159 19.6 17.8639 19.6 18.76 19.6H23.24C24.1361 19.6 24.5841 19.6 24.9264 19.7744C25.2274 19.9278 25.4722 20.1726 25.6256 20.4736C25.8 20.8159 25.8 21.2639 25.8 22.16M17.928 5.904L8.20001 13.2V28.88C8.20001 30.6722 8.20001 31.5683 8.54878 32.2528C8.85558 32.8549 9.34512 33.3444 9.94724 33.6512C10.6318 34 11.5278 34 13.32 34H21M24.072 5.904L33.8 13.2V20.04M5 15.6L19.464 4.752C20.0148 4.33889 20.2902 4.13233 20.5927 4.05271C20.8597 3.98243 21.1403 3.98243 21.4073 4.05271C21.7098 4.13233 21.9852 4.33889 22.536 4.752L37 15.6"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.7"
        d="M34.2148 44.7258C34.4638 44.8693 34.5884 44.9411 34.7641 44.9783C34.9005 45.0072 35.0995 45.0072 35.2359 44.9783C35.4116 44.9411 35.5362 44.8693 35.7852 44.7258C37.9768 43.4626 44 39.4947 44 34.0391V28.7236C44 27.835 44 27.3907 43.8529 27.0087C43.7229 26.6713 43.5118 26.3703 43.2376 26.1316C42.9273 25.8614 42.5062 25.7054 41.664 25.3934L35.632 23.1586C35.3981 23.0719 35.2812 23.0286 35.1609 23.0114C35.0542 22.9962 34.9458 22.9962 34.8391 23.0114C34.7188 23.0286 34.6019 23.0719 34.368 23.1586L28.336 25.3934C27.4938 25.7054 27.0727 25.8614 26.7624 26.1316C26.4882 26.3703 26.2771 26.6713 26.1471 27.0087C26 27.3907 26 27.835 26 28.7236V34.0391C26 39.4947 32.0232 43.4626 34.2148 44.7258Z"
        fill={color}
      />
      <path
        d="M29.625 33.4834L31.875 35.7063L36.9375 30.7047M42 34.0391C42 39.4947 35.9768 43.4626 33.7852 44.7258C33.5362 44.8693 33.4116 44.9411 33.2359 44.9783C33.0995 45.0072 32.9005 45.0072 32.7641 44.9783C32.5884 44.9411 32.4638 44.8693 32.2148 44.7258C30.0232 43.4626 24 39.4947 24 34.0391V28.7236C24 27.835 24 27.3907 24.1471 27.0087C24.2771 26.6713 24.4882 26.3703 24.7624 26.1316C25.0727 25.8614 25.4938 25.7054 26.336 25.3934L32.368 23.1586C32.6019 23.0719 32.7188 23.0286 32.8391 23.0114C32.9458 22.9962 33.0542 22.9962 33.1609 23.0114C33.2812 23.0286 33.3981 23.0719 33.632 23.1586L39.664 25.3934C40.5062 25.7054 40.9273 25.8614 41.2376 26.1316C41.5118 26.3703 41.7229 26.6713 41.8529 27.0087C42 27.3907 42 27.835 42 28.7236V34.0391Z"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconInsurance.defaultProps = {
  w: 48,
  h: 48,
  color: '#FF6700',
};

export default IconInsurance;
