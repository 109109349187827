import React, { useContext } from 'react';
import { orderBy } from 'lodash';
import { useQuery } from '@apollo/client';
import UserContext from '@contexts/UserContext';
import { BaselaneErrorCard } from '@shared/components';
import SlLoader from '@core/components/Loader';

import ActiveEmptyState from './ActiveEmptyState';
import TenantInfo from '../components/TenantInfo';
import ColumnTitleElements from '../components/ColumnTitleElements';
import { GET_TENANT_ACTIVE_PROFILES } from '../queries';

function ActiveTenants() {
  const { user } = useContext(UserContext);
  const { loading: tenantLoading, error: tenantError, data: tenants } = useQuery(
    GET_TENANT_ACTIVE_PROFILES
  );
  const { activeTenants = [] } = tenants?.landLordTenantSummary ?? {};
  const sortedActiveTenants = orderBy(activeTenants, ['firstName', 'lastName']);
  const existingEmails = sortedActiveTenants.map((item) => item.email);

  const loadingComponent = <SlLoader />;
  const errorComponent = <BaselaneErrorCard />;

  if (tenantLoading) return loadingComponent;
  if (tenantError && tenantError.message.includes('Failed to fetch')) {
    return errorComponent;
  }

  return sortedActiveTenants?.length > 0 ? (
    <>
      <ColumnTitleElements />
      {sortedActiveTenants.map((t) => (
        <TenantInfo
          key={t.id}
          tenant={t}
          existingEmails={existingEmails}
          landlordEmail={user.email}
        />
      ))}
    </>
  ) : (
    <ActiveEmptyState />
  );
}

export default ActiveTenants;
