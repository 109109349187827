import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

/**
 * This overrides Chakra's default <Modal /> styles.
 *
 * see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/modal.ts for implementation details
 */

const parts = ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'];
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

export const Modal = defineMultiStyleConfig({
  baseStyle: {
    header: {
      px: 3,
      py: 2,
    },
    closeButton: {
      top: 1,
      insetEnd: 1.5,
    },
    body: {
      px: 3,
      py: 1,
    },
    footer: {
      px: 3,
      py: 2,
    },
  },
});
