import React from 'react';

type IconCarretDownProps = {
  width: string,
  height: string,
  viewBox: string,
  color: string,
};

function IconCarretDown({
  width = 12,
  height = 8,
  viewBox = '0 0 12 8',
  color = '#3A4B5B',
}: IconCarretDownProps) {
  return (
    <svg {...{ width, height, viewBox }} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 .75c0-.42-.33-.75-.75-.75H.75a.743.743 0 00-.555 1.245l5.25 6c.135.15.33.255.555.255.225 0 .42-.105.555-.255l5.25-6A.73.73 0 0012 .75z"
        fill={color}
      />
    </svg>
  );
}

export default IconCarretDown;
