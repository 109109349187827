import React, { useContext } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ADD_ACCOUNT, EXTERNAL_ACCOUNTS, DASHBOARD, ANALYTICS_REPORTING } from '@routes';
import { BaselaneAlertNew, BaselaneButton, BaselaneLink } from '@shared/components';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import BanksContext from '@contexts/BanksContext';
import { Icon16Info, Icon16Plus } from '@icons/16px';
import SlLoader from '@core/components/Loader';
import {
  noDataContainerStyles,
  noDataPopupStyles,
  noDataPopupTitleStyles,
  noDataPopupDescriptionStyles,
} from '../styles/emptyState.styles';

type EmptyStateProps = {
  totalTransactionCount: Number,
  isConnectBALoading: boolean,
  handleAccountConnectDrawerClose: Function,
  setIsConnectBALoading: Function,
};

function EmptyState({
  totalTransactionCount,
  isConnectBALoading,
  handleAccountConnectDrawerClose,
  setIsConnectBALoading,
}: EmptyStateProps) {
  const {
    baselaneConnectedAccounts,
    externalAccounts: externalBankAccounts,
    connectedManualBanksWithConnectedAccounts,
    loading,
    error,
    refetchBankSummary,
  } = useContext(BanksContext);

  const externalAccounts = (externalBankAccounts ?? []).concat(
    connectedManualBanksWithConnectedAccounts ?? []
  );

  const externalAccountsCount = externalAccounts?.length ?? 0;
  const baselaneAccountsCount = baselaneConnectedAccounts?.length ?? 0;

  const currLocation = useLocation();
  const { pathname } = currLocation;

  const navigate = useNavigate();

  const handleAccountConnectDrawerOpen = () => {
    if (pathname.includes(DASHBOARD)) {
      sendSegmentEvent('dashboard_cashflow_emptystate_click_add_account');
    } else if (pathname.includes(ANALYTICS_REPORTING)) {
      sendSegmentEvent('analytics_reporting_cashflow_emptystate_click_add_account');
    }

    navigate({
      pathname: ADD_ACCOUNT,
    });
  };

  const showConnectedAccountNoTransactions =
    totalTransactionCount === 0 && externalAccountsCount === 0 && baselaneAccountsCount > 0;

  const showAlert =
    totalTransactionCount === 0 && baselaneAccountsCount >= 0 && externalAccountsCount > 0;

  return (
    <Box {...noDataContainerStyles}>
      {isConnectBALoading || loading ? (
        <Box {...noDataPopupStyles}>
          <SlLoader className="sl-center" styles={{ display: 'block', left: '45%' }} />
        </Box>
      ) : (
        <Box {...noDataPopupStyles}>
          {showConnectedAccountNoTransactions || showAlert ? (
            <>
              <Text {...noDataPopupTitleStyles}>No transactions yet</Text>
              <Text {...noDataPopupDescriptionStyles}>
                You can view cash flow insights and more, once there are transactions on your
                accounts.
              </Text>
            </>
          ) : (
            <>
              <Text {...noDataPopupTitleStyles}>
                Want to see how your
                <br />
                property is performing?
              </Text>
              <Text {...noDataPopupDescriptionStyles}>
                Connect your bank accounts and import transactions to unlock cash flow insights &
                reporting.
              </Text>
            </>
          )}

          {showAlert && (
            <Box pb={4}>
              <BaselaneAlertNew
                variant="neutral"
                visual="icon"
                iconName={Icon16Info}
                body={
                  <Stack spacing={0.5} alignItems="flex-start">
                    <Text textAlign="left">
                      Make sure the “Import transactions” setting is enabled for all your connected
                      accounts.
                    </Text>
                    <BaselaneLink variant="neutral" to={EXTERNAL_ACCOUNTS}>
                      View connected accounts
                    </BaselaneLink>
                  </Stack>
                }
              />
            </Box>
          )}

          <BaselaneButton
            variant={showConnectedAccountNoTransactions || showAlert ? 'tonal' : 'filled'}
            palette="primary"
            size="lg"
            leftIcon={(showConnectedAccountNoTransactions || showAlert) && <Icon16Plus />}
            onClick={handleAccountConnectDrawerOpen}
            isDisabled={error}
            isLoading={isConnectBALoading || loading}
          >
            Add {showConnectedAccountNoTransactions || showAlert ? 'another' : ''} account
          </BaselaneButton>

          <Outlet
            context={{
              handleExternalAccountConnectDrawerClose: handleAccountConnectDrawerClose,
              handleAddAccountManuallyOnSubmit: () => {
                setIsConnectBALoading(true);
              },
              handleAddAccountManuallySuccess: () => {
                refetchBankSummary();
                setIsConnectBALoading(false);
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default EmptyState;
