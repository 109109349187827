export const popUpCardTextStyles = (isMobile) => ({
  textTransform: isMobile ? 'none' : 'uppercase',
  color: 'brand.neutral.700',
  textStyle: 'headline-xs',
  ml: isMobile ? '0px' : '17px',
  flexBasis: isMobile ? 'initial' : '150px',
});

export const popUpCardStyles = {
  backgroundColor: 'brand.neutral.white',
  pr: '15px',
  pl: '24px',
  py: '18px',
  borderRadius: '12px',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  width: '238px !important',
};

export const popupMobileImageContainerStyles = {
  mt: '50px',
  bg: 'brand.blue.300',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  borderRadius: '12px',
  mx: '16px',
};

export const popUpTitleTextStyles = (isMobile) => ({
  fontSize: isMobile ? 'lg' : '2xl',
  lineHeight: isMobile ? '26px' : '32px',
  fontWeight: 'semibold',
  color: 'brand.neutral.700',
  mt: isMobile ? '24px' : 'opx',
  mb: isMobile ? '0px' : '20px',
  mx: isMobile ? '0px' : '30px',
});

export const popUpCardStackStyles = (isMobile) => ({
  alignItems: isMobile ? 'initial' : 'center',
  justifyContent: 'center',
  mx: isMobile ? '16px' : '35px',
  my: isMobile ? '16px' : '58px',
  spacing: '16px',
});

export const modalBodyStyles = (isMobile) => ({
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  p: '0px',
});

export const modalContentStyles = {
  p: '0px !important',
};

export const popUpCardContainerStyles = (isMobile) => ({
  alignItems: 'center',
  mt: isMobile ? '16px' : '0px',
});

export const mobilePopUpIconContainer = {
  mr: '16px',
};

export const desktopContainerStyles = {
  backgroundColor: 'brand.blue.50',
  px: 'initial',
  m: '0px',
};

export const rightPopUpContentContainer = {
  bg: 'brand.neutral.white',
  mt: '58px',
  flex: '1 0 auto',
};

export const rightPopUpContentContainerWithoutButton = {
  flexDirection: 'column',
};
export const popupDesktopImageContainerStyles = {
  alignSelf: 'center',
};
