import React from 'react';
import { HStack, Text, Box } from '@chakra-ui/react';
import { truncatedText } from '@shared/styles/text.style';
import DisplayInputWrapperWithRightButton from '@shared/components/BaselaneDropdown-new/DisplayInputVariations/DisplayInputWrapperWithRightButton';
import Placeholder from '@shared/components/BaselaneDropdown-new/DisplayInputVariations/Placeholders/Placeholder';

type DisplayInputDefaultAccountContentProps = {
  selectedOptions: Array<Object>,
  handleContentRendererClick: Function,
  handleClearClick: Function,
  placeholder: string,
  title: string,
  classNames: Array<string>,
  /**
   * Icon to be used in the input
   */
  children: React.Node,
};

const DisplayInputDefaultAccountContent = ({
  selectedOptions,
  handleContentRendererClick,
  handleClearClick,
  placeholder,
  title,
  classNames,
  children,
}: DisplayInputDefaultAccountContentProps) => {
  return (
    <DisplayInputWrapperWithRightButton
      handleContentRendererClick={handleContentRendererClick}
      handleClearClick={handleClearClick}
      selectedOptions={selectedOptions}
      classNames={classNames}
    >
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder placeholder={placeholder} title={title}>
          {children}
        </Placeholder>
      ) : (
        <HStack color="brand.neutral.500" overflow="hidden">
          {children && <Box>{children}</Box>}
          <Text color="brand.neutral.900" {...truncatedText}>
            {selectedOptions[0].bankName}
          </Text>
        </HStack>
      )}
    </DisplayInputWrapperWithRightButton>
  );
};

export default DisplayInputDefaultAccountContent;
