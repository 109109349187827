import React from 'react';
import { HStack, Text, Box } from '@chakra-ui/react';
import { BaselaneAtoms, BaselaneButton } from '@shared/components';
import { cardSummaryOverviewValueStyles } from '@core/components/NativeBankingPage/Summary/styles/newsummary.styles';
import PromoTooltip from '@core/components/NativeBankingPage/Summary/PromoTooltip';
import formatCurrency from '@core/utils/formatCurrency';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

type NextMonthAPYProps = {
  config: Array<Object>,
  isEmptyState: Boolean,
  isUserEligibleForPromo: Boolean,
  isLargerThan899: Boolean,
  learnMore: any,
  userPromoApy: String,
  maxTier: String,
  formattedPromoEndDate: Function,
  hasSavingsAccount: Boolean,
  showNewSavingAccountUI: Boolean,
  setShowLearnMoreModal: Function,
};

const NextMonthAPY = ({
  config,
  isEmptyState,
  isUserEligibleForPromo,
  isLargerThan899,
  learnMore,
  userPromoApy,
  maxTier,
  formattedPromoEndDate,
  hasSavingsAccount,
  showNewSavingAccountUI,
  setShowLearnMoreModal,
}: NextMonthAPYProps) => {
  if (isLargerThan899) {
    return !isEmptyState || (isEmptyState && !isUserEligibleForPromo) ? (
      <BaselaneAtoms config={config} />
    ) : null;
  }

  if (!isLargerThan899) {
    if (isEmptyState) {
      return (
        <HStack {...cardSummaryOverviewValueStyles(!isLargerThan899)}>
          <HStack>
            {isUserEligibleForPromo ? (
              <Text>{userPromoApy}%</Text>
            ) : (
              <Text>Up to {maxTier?.product?.value ?? '--'}%</Text>
            )}
            {isUserEligibleForPromo && (
              <PromoTooltip
                text={
                  <Text>
                    {!isEmptyState ? (
                      'Introductory APY valid through '
                    ) : (
                      <>
                        <Box as="span">Promo {userPromoApy}</Box>% Base APY through{' '}
                      </>
                    )}
                    {formattedPromoEndDate()}
                  </Text>
                }
              />
            )}
          </HStack>
          {learnMore}
        </HStack>
      );
    }
  }

  const activeAtom = config.find((a) => a.isActive);
  let value = activeAtom?.value;
  if (!activeAtom) {
    if (maxTier?.product?.value === undefined || maxTier?.product?.value === null) {
      value = null;
    } else {
      value = `${formatCurrency(maxTier?.product?.value, true).noDollar}%`;
    }
  }

  return showNewSavingAccountUI && !hasSavingsAccount ? (
    <Box
      {...cardSummaryOverviewValueStyles(!isLargerThan899)}
      textStyle="sm"
      fontWeight="normal"
      sx={{ textWrap: 'wrap' }}
    >
      Add a savings account to earn this APY.
      <BaselaneButton
        variant="link"
        palette="neutral"
        size="sm"
        onClick={() => {
          setShowLearnMoreModal(true);
          sendSegmentEvent('baselane_banking_home_apy_click_earn_more');
        }}
        styles={{ ml: 1 }}
      >
        Learn more
      </BaselaneButton>
    </Box>
  ) : (
    <HStack {...cardSummaryOverviewValueStyles(!isLargerThan899)}>
      {value && <Text>{value}</Text>}
      {learnMore}
    </HStack>
  );
};

export default NextMonthAPY;
