import React from 'react';

import * as ROUTES from '@routes';
import { BaselaneMobileHeader } from '@shared/components';
import { Icon16ChevronLeft } from '@icons/16px';

type PropertyHeaderProps = {
  title: String,
};

function PropertyHeader({ title }: PropertyHeaderProps): any {
  return (
    <BaselaneMobileHeader
      title={title}
      shadow
      stay
      icon={<Icon16ChevronLeft />}
      routerLinkTo={{ pathname: ROUTES.DASHBOARD, state: { showUncategorizedFromLink: true } }}
    />
  );
}

export default PropertyHeader;
