import React from 'react';
import { Outlet } from 'react-router-dom';
import { VerifyEmailModal } from '@shared/components';
import useUserSignUpExperiment from '@core/hooks/useUserSignUpExperiment';

import PhoneVerification from '@core/components/PhoneVerification';
import type { ApplicationSection } from '../types/ApplicationSection';

type TabRoutesProps = {
  formRefs: Array,
  tabIndex: number,
  setTabIndex: Function,
  applicationMenuOptions: Array<ApplicationSection>,
  handleSolePropContinue: Function,
  formData: Object,
  submitLBDataLoading: boolean,
  isRefetchUserLoading: boolean,
  showVerifyPopup: boolean,
  setShowVerifyPopup: Function,
  onVerifyOtpSuccess: Function,
  setHasSubmitErrors: Function,
  setSubmitErrors: Function,
  setSubmitErrorsTitle: String,
};

const TabRoutes = ({
  formRefs,
  tabIndex,
  setTabIndex,
  applicationMenuOptions,
  handleSolePropContinue,
  formData,
  submitLBDataLoading,
  isRefetchUserLoading,
  showVerifyPopup,
  setShowVerifyPopup,
  onVerifyOtpSuccess,
  setHasSubmitErrors,
  setSubmitErrors,
  setSubmitErrorsTitle,
}: TabRoutesProps): any => {
  const footerWrapperProps = {
    handleSolePropContinue,
    applicationMenuOptions,
    tabIndex,
    setTabIndex,
    formRefs,
    formData,
    submitLBDataLoading,
    isRefetchUserLoading,
    showVerifyPopup,
    setHasSubmitErrors,
    setSubmitErrors,
    setSubmitErrorsTitle,
  };

  const { isTestVariant } = useUserSignUpExperiment();

  return (
    <>
      <Outlet context={footerWrapperProps} />

      {/* 2024-07_sign_up_page_experiment */}
      {/* A/B test should toggle Phone Verification instead of Email Verification */}
      {isTestVariant && showVerifyPopup && (
        <PhoneVerification
          onCloseBtnClick={() => setShowVerifyPopup(false)}
          onOTPSuccess={(phone) => {
            onVerifyOtpSuccess(phone);
            setShowVerifyPopup(false);
          }}
        />
      )}
      {!isTestVariant && showVerifyPopup && (
        <VerifyEmailModal
          email={formData?.email}
          onCloseBtnClick={() => setShowVerifyPopup(false)}
          onVerifyEmailOtpSuccess={onVerifyOtpSuccess}
        />
      )}
    </>
  );
};

export default TabRoutes;
