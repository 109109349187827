import React from 'react';
import { Text } from '@chakra-ui/react';
import BankTransfer from '@core/components/NativeBankingPage/MainContent/components/BankTransfer';

import DrawerHeaderWrapper from './DrawerHeaderWrapper';
import { title4 } from '../../styles/makeTransferOrPaymentDrawer.styles';

type TransferFormProps = {
  totalSteps: Number,
  currentStep: Number,
  paymentMethod: string,
  setIsValidAccount: Function,
  setIsValidBankTransfer: Function,
  setTransfer: Function,
  transfer: Object,
};

const TransferForm = ({
  totalSteps,
  currentStep,
  paymentMethod,
  setIsValidAccount,
  setIsValidBankTransfer,
  setTransfer,
  transfer,
}: TransferFormProps) => {
  return (
    <>
      <DrawerHeaderWrapper {...{ totalSteps, currentStep }}>
        <Text {...title4}>Transfer Account & Amount</Text>
      </DrawerHeaderWrapper>
      <BankTransfer
        transferType="WIRE_TRANSFER"
        {...{
          setIsValidAccount,
          setIsValidBankTransfer,
          setTransfer,
          ...transfer,
        }}
      />
    </>
  );
};

export default TransferForm;
