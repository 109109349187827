import { formInputStyles } from '@shared/styles/input.style';

export const listStyles = (hasFilterWrapper) => {
  return {
    overflowY: 'auto',
    top: hasFilterWrapper ? '136px' : '72px',
    flex: 1,
    mt: 0,
    px: 2,
    css: {
      '&::-webkit-scrollbar': {
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#E5E9EF',
        backgroundClip: 'padding-box',
        border: '4px solid transparent',
        borderRadius: '8px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#C8D0DA',
        backgroundClip: 'padding-box',
        border: '4px solid transparent',
        borderRadius: '8px',
      },
    },
  };
};

export const searchGroupStyles = {
  w: 'calc(100% - 32px)',
  alignSelf: 'center',
  mt: '8px',
};

export const searchStyles = {
  ...formInputStyles,
  _focus: {
    borderColor: 'brand.blue.700',
    color: 'brand.neutral.600',
    boxShadow: 'inset 0px 0px 1px 1px #E1F4FF',
  },
  // do not show invalid styles for search input
  _invalid: {
    borderColor: 'brand.blue.700',
    _focus: {
      borderColor: 'brand.blue.700',
      boxShadow: 'inset 0px 0px 1px 1px #E1F4FF',

      color: 'brand.neutral.700',
    },
  },
  h: '40px',
};

export const dropdownIconContainerStyles = {
  h: '100%',
  m: '0 !important',
  p: '6px 12px 6px 0 !important', // to enable click to toggle open/close possible from anywhere within icon container
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'brand.neutral.500',
};

const getDropdownLeftPadding = (classNames) => {
  let paddingLeft = 1.5;
  if (classNames?.includes('input-form-lg')) {
    paddingLeft = 2;
  }

  return paddingLeft;
};

export const dropdownContentContainerStyles = (classNames) => ({
  // to enable click to toggle open/close possible from anywhere within icon container
  py: 0.75,
  pl: getDropdownLeftPadding(classNames),
  pr: 1,
  //-------
  h: '100%',
  w: '100%',
  justifyContent: 'center',
  overflow: 'hidden',
  textStyle: 'sm',
});
