import React, { useContext, useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import UserContext from '@contexts/UserContext';
import * as ROUTES from '@routes';
import SlLoader from '../../../Loader';

/**
 * Note: This wrapper allows the provider to be used as a route element.
 */

type OnboardingCompletedLogicProps = {
  isPublicRoute: Boolean,
};

const OnboardingCompletedLogic = ({ isPublicRoute }: OnboardingCompletedLogicProps) => {
  const { loading, user } = useContext(UserContext);
  const [userCompletedOnboarding, setUserCompletedOnboarding] = useState(false);

  useEffect(() => {
    if (user) {
      const { onboardingCompleted } = user || {};

      if (onboardingCompleted && localStorage.getItem('search-storage')) {
        localStorage.removeItem('search-storage');

        if (isPublicRoute) {
          setUserCompletedOnboarding(true);
        }
      }
    }
  }, [user]);

  return loading ? (
    <SlLoader />
  ) : (
    <>
      {userCompletedOnboarding && <Navigate to={ROUTES.HOME} />}
      <Outlet />
    </>
  );
};

export default OnboardingCompletedLogic;
