import React, { useState, useRef } from 'react';
import { Flex, HStack, Text, Checkbox, Box, Spacer } from '@chakra-ui/react';
import { IconExclamationCircle } from '@icons';
import {
  BaselaneAlert,
  BaselaneButton,
  AlertHeader,
  BaselaneButtonGroup,
} from '@shared/components';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  alertPopupHeaderStyles,
  alertPopupIconBgStyles,
  alertPopupIconContainerStyles,
} from '../styles/deleteAccount.styles';

type DeleteExternalAccountAlertProps = {
  handleTerminateAccount: Function,
  id: Number,
};

function DeleteExternalAccountAlert({
  handleTerminateAccount,
  id,
}: DeleteExternalAccountAlertProps) {
  const { isMinXL } = getBreakPoints();
  const cancelRef = useRef();

  // alert for Archive
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onAlertIsClose = () => setIsAlertOpen(false);
  const handleAlertIsOpen = () => setIsAlertOpen(true);

  const [deleteImportedTrx, setDeleteImportedTrx] = useState();
  const handleAlertContinue = () => {
    handleTerminateAccount(id, deleteImportedTrx);
  };

  const onChange = (e) => {
    setDeleteImportedTrx(e.target.checked);
  };

  const header = (
    <AlertHeader
      icon={<IconExclamationCircle />}
      title="Are you sure you want to remove this account?"
      iconBgStyles={alertPopupIconBgStyles}
      iconContainerStyles={alertPopupIconContainerStyles}
    />
  );

  const footer = (
    <Flex direction="row" minWidth="max-content" alignItems="center" gap="3">
      {!isMinXL && (
        <>
          <Box>
            <Checkbox
              id="DeleteAllImportedTransactions"
              name="DeleteAllImportedTransactions"
              size="md"
              className="primary-500"
              onChange={(e) => onChange(e)}
              h="16px"
              mt="4px"
            />
          </Box>
          <Box>
            <Text fontSize="sm" color="brand.neutral.700">
              Delete all imported transactions for this account
            </Text>
          </Box>
        </>
      )}
      <Spacer />
      <BaselaneButtonGroup size="md">
        <BaselaneButton
          variant="outline"
          palette="neutral"
          size="md"
          ref={cancelRef}
          onClick={onAlertIsClose}
        >
          Cancel
        </BaselaneButton>
        <BaselaneButton
          variant="filled"
          palette="danger"
          onClick={(e) => handleAlertContinue(e)}
          size="md"
          id="popup-remove-external-account-button"
        >
          Yes, remove
        </BaselaneButton>
      </BaselaneButtonGroup>
    </Flex>
  );

  const message = (
    <>
      <Text lineHeight="20px">
        Balance & transaction importing will be stopped, if applicable. Any imported transactions
        will remain on Baselane unless the option to delete them is selected below. Existing rent
        collection to this account will remain active.
      </Text>
      {isMinXL && (
        <HStack mt="16px">
          <Checkbox
            id="DeleteAllImportedTransactions"
            name="DeleteAllImportedTransactions"
            size="md"
            className="primary-500"
            onChange={(e) => onChange(e)}
            h="16px"
          />
          <Box>
            <Text textStyle="sm" color="brand.neutral.700">
              Delete all imported transactions for this account
            </Text>
          </Box>
        </HStack>
      )}
    </>
  );

  return (
    <Box mt={1.5}>
      <BaselaneAlert
        showCloseButton
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertIsClose}
        isCentered
        header={header}
        body={message}
        footer={footer}
        headerStyles={alertPopupHeaderStyles}
        bodyStyles={{ m: '0' }}
        footerStyles={{ m: '0', flex: 'none' }}
        containerStyles={{
          maxWidth: '672px',
          gap: { xs: '32px', base: '16px' },
          m: { xs: '16px', base: '8px' },
          p: { xs: '40px 32px 32px', base: '40px 16px 16px' },
        }}
      />
      <BaselaneButton
        id="remove-external-account-button"
        onClick={handleAlertIsOpen}
        variant="filled"
        palette="danger"
        size="md"
      >
        Remove External Account
      </BaselaneButton>
    </Box>
  );
}

export default DeleteExternalAccountAlert;
