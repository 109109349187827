export const propertyListStyles = {
  container: {
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: 'brand.darkBlue.200',
    gap: 0,
  },
  header: {
    container: {
      px: 2,
      py: 1.5,
      gap: 1.5,
      flexDir: 'row',
      alignItems: 'center',
    },
    icon: {
      container: {
        w: '24px',
        h: '24px',
      },
    },
    title: {
      textStyle: 'headline-md',
    },
  },
  content: {
    m: '0 !important',
  },
  accordion: {
    wrapper: {
      my: 0,
      h: '100%',
    },
    header: {
      textStyle: 'sm',
      color: 'brand.neutral.900',
    },
    containerStyles: {
      m: '0 !important',
    },
    buttonStyles: {
      mx: 1.5,
      mb: 1.5,
      px: 2,
      py: 1.5,
      gap: 1,
      w: 'calc(100% - 24px)',
      h: 'auto',
      maxH: 'none',
      borderRadius: '4px !important',
      background: 'brand.blue.50',
    },
    contentStyles: {
      border: 'none',
      p: 0,
    },
    expandedContentStyles: {
      border: 'none',
      p: 0,
    },
    iconContainerStyles: {
      minW: '24px',
      minH: '24px',
      alignItems: 'center',
      justifyContent: 'center',
      m: 0,
    },
  },
};
