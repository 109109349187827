import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import customTheme from '@theme';
import { Icon16Person, Icon16Property } from '@icons/16px';
import { Placeholder, DisplayInputWrapperWithRightButton } from '@shared/components';
import { truncatedText } from '@core/components/Shared/styles/text.style';

type DropDownDisplayInputProps = {
  selectedOptions: Array<Object>,
  handleContentRendererClick: Function,
  handleClearClick: Function,
  placeholder: string,
  title: string,
  children: React.Node,
};

const DropDownDisplayInput = ({
  selectedOptions,
  handleContentRendererClick,
  handleClearClick,
  placeholder,
  title,
}: DropDownDisplayInputProps) => {
  // eslint-disable-next-line no-underscore-dangle
  const isRecipientsDropDown = selectedOptions?.every((option) => option?.__typename === 'Payee');

  return (
    <DisplayInputWrapperWithRightButton
      {...{ handleContentRendererClick, handleClearClick, selectedOptions }}
    >
      {selectedOptions?.length === 0 || !selectedOptions ? (
        <Placeholder {...{ placeholder, title }} />
      ) : (
        <HStack color="brand.neutral.500" overflow="hidden" gap={1.5}>
          {isRecipientsDropDown &&
            (selectedOptions[0].type === 'INDIVIDUAL' ? (
              <Icon16Person color={customTheme.colors.brand.neutral['700']} />
            ) : (
              <Icon16Property color={customTheme.colors.brand.neutral['700']} />
            ))}
          <Text color="brand.neutral.900" {...truncatedText}>
            {selectedOptions[0].showValueName ?? selectedOptions[0].name}
          </Text>
        </HStack>
      )}
    </DisplayInputWrapperWithRightButton>
  );
};

export default DropDownDisplayInput;
