import React from 'react';
import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';

const NoCompatibleAccounts = () => {
  const textStyles = {
    color: 'brand.neutral.700',
    textStyle: 'sm',
  };

  return (
    <Box textStyle="sm" color="brand.neutral.700" pt="16px">
      <Text {...textStyles}>
        We weren’t able to find any compatible accounts in this bank account to connect with
        Baselane. Account types that you are able to connect with Baselane are:
      </Text>
      <UnorderedList {...textStyles} pl="12px">
        <ListItem>Savings</ListItem>
        <ListItem>Checking</ListItem>
        <ListItem>Credit</ListItem>
        <ListItem>Paypal</ListItem>
        <ListItem>Cash Management</ListItem>
      </UnorderedList>
      <Text {...textStyles} mt="24px">
        Please add a compatible bank account or reach out to customer support if you continue to
        have issues.
      </Text>
    </Box>
  );
};
export default NoCompatibleAccounts;
