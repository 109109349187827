import React from 'react';
import { Outlet } from 'react-router-dom';
import { UserProvider } from '@core/contexts/UserContext';
import { MobileDropdownPortalPopup } from '../../../Shared/components';

const UserWrapper = (): any => {
  return (
    <UserProvider>
      {/* to contain mobile dropdowns */}
      <MobileDropdownPortalPopup />
      <Outlet />
    </UserProvider>
  );
};

export default UserWrapper;
