import React from 'react';
import BaselaneCardStackLayoutClickable from '@shared/components/BaselaneCardStack/components/BaselaneCardStackLayoutClickable';

type TransferCardProps = {
  icon: Object,
  title: String,
  description: Object,
  onClick: Function,
};

const TransferCard = ({ icon, title, description, onClick }: TransferCardProps) => {
  return (
    <BaselaneCardStackLayoutClickable
      title={title}
      description={description}
      icon={icon}
      onClick={onClick}
    />
  );
};

export default TransferCard;
