// BaseLane: Styles for PortfolioKPIs dashboard widget elements
export const portfolioMetricsWidget = (isMobile) => ({
  pt: !isMobile ? '36px' : '16px',
  pb: !isMobile ? '36px' : '16px',
  borderBottomLeftRadius: !isMobile ? '0' : '8px',
  borderBottomRightRadius: !isMobile ? '0' : '8px',
});

export const currencyTextStyle = {
  isRounded: true,
  textStyle: 'headline-md',
  negativeColor: 'red.500A',
  color: 'brand.neutral.700',
  marginTop: '0px',
};

export const containerStyles = (isMobile) => ({
  px: !isMobile ? '24px' : '16px',
});

export const listItemStyles = {
  fontWeight: '400',
  _notFirst: {
    mt: '16px !important',
  },
};
