import moment from 'moment';
import { DateTime } from 'luxon';

export const formatDate = (date, format = 'MMM D, YYYY') =>
  moment(date || ''.toString()).format(format);

export const dateFormatter = (d) => {
  return DateTime.fromISO(d).toFormat('MMM dd, yyyy');
};
export const getOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
