import React from 'react';
import { isMobile } from 'react-device-detect';
import { FormControl, FormLabel, Spacer, Switch } from '@chakra-ui/react';
import {
  formLabelStyles,
  formSwitchRowStyles,
  formSwitchStyles,
} from '../styles/formControls.styles';

type ImportTrxsProps = {
  values: Object,
  handleFormUpdate: Function,
  handleBlur: Function,
  handleChange: Function,
  setFieldValue: Function,
  setIsImportingAlertOpen: Function,
  setIsCheckedDuplicateTransactions: Function,
  setIsImportDisabledAlertOpen?: Function,
  importTransactionLabelRef: any,
  dirty: boolean,
  isInitialImportTransactionTypeNull?: boolean,
  readOnly?: Boolean,
  setIsDeleteImportedTransactionsAlertOpen?: Function,
};

const ImportTrxs = ({
  values,
  handleFormUpdate,
  handleBlur,
  handleChange,
  setFieldValue,
  setIsImportingAlertOpen,
  setIsCheckedDuplicateTransactions,
  setIsImportDisabledAlertOpen,
  importTransactionLabelRef,
  dirty,
  isInitialImportTransactionTypeNull,
  readOnly,
  setIsDeleteImportedTransactionsAlertOpen,
}: ImportTrxsProps) => {
  const handleImportTrxChange = (e) => {
    handleChange(e);
    const isImportTrxEnabled = e.target.checked;

    // If:   it's enabled, set the type to 'ALL' and open duplicated trx warning alert if user has imported trxs before
    // else: when user toggles off import trxs, set any changes made to the autoTag switch and table back to null
    if (isImportTrxEnabled) {
      setFieldValue('importTransaction.importTransactionType', 'ALL');
      if (!isInitialImportTransactionTypeNull) {
        setIsImportingAlertOpen(true);
        if (setIsCheckedDuplicateTransactions) setIsCheckedDuplicateTransactions(true);
      }
    } else {
      setFieldValue('importTransaction.deleted', true);
      setIsDeleteImportedTransactionsAlertOpen(true);
    }
  };
  const importTransactionsLabel = isMobile
    ? 'Would you like to import transactions into Baselane?'
    : 'Import Transactions';

  // In mobile mode, we open confirmation modal if importing is switched off
  const confirmChangeHook = (e) => {
    if (isMobile) {
      if (!e.target.checked) {
        setIsImportDisabledAlertOpen(true);
        return;
      }
    }
    handleImportTrxChange(e);
  };

  return (
    <>
      {/* Import Transactions Switch */}
      <FormControl {...formSwitchRowStyles}>
        <FormLabel
          ref={importTransactionLabelRef}
          {...formLabelStyles.sm}
          m="0 !important"
          fontWeight={isMobile ? 'medium' : 'normal'}
        >
          {readOnly ? `Importing transactions into Baselane` : importTransactionsLabel}
        </FormLabel>
        <Spacer />
        <Switch
          as="span"
          className="primary-500"
          {...formSwitchStyles}
          name="importTransaction.enabled"
          id="importTransaction.enabled"
          {...formSwitchStyles}
          isChecked={!!values.importTransaction.enabled}
          onChange={(e) => confirmChangeHook(e)}
          onBlur={(e) => {
            handleFormUpdate('importTransaction', values, dirty);
            handleBlur(e);
          }}
          isDisabled={readOnly}
        />
      </FormControl>

      {/* NOTE: Not deleting below comment as per John's request as we may bring this back in the future.
                Comment out below if you want to bring back import trx type toggle */}
      {/* Import Transactions Enabled */}
      {/* {values.importTransaction.enabled && (
        <Box mt="4px">
          <BaselaneButtonToggle
            firstLabel="Import All Transactions"
            secondLabel="Import Only New Transactions Starting Today"
            activeButton={setTransactionType(values)}
            onClick={(buttonIndex) => {
              handleTransactionTypeChange(
                buttonIndex,
                values,
                setFieldValue,
                setIsImportingAlertOpen,
                setIsCheckedDuplicateTransactions
              );
            }}
            onBlur={(e) => {
              handleFormUpdate('importTransactionType', values, dirty);
              handleBlur(e);
            }}
            id="importTransaction.importTransactionType"
            name="importTransaction.importTransactionType"
          />
        </Box>
      )} */}
    </>
  );
};

ImportTrxs.defaultProps = {
  isInitialImportTransactionTypeNull: false,
  readOnly: false,
  setIsImportDisabledAlertOpen: () => {},
  setIsDeleteImportedTransactionsAlertOpen: () => {},
};

export default ImportTrxs;
