import React from 'react';
import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';

function PlaidMessage() {
  return (
    <Box>
      <Text>
        You can connect{' '}
        <Box as="span" fontWeight="medium">
          Bank Accounts
        </Box>{' '}
        to:
      </Text>
      <UnorderedList ml="25px" mb="15px">
        <ListItem>Collect rent, fees & security deposits</ListItem>
        <ListItem>Import transactions</ListItem>
        <ListItem>Fund a Baselane Banking account</ListItem>
      </UnorderedList>
      <Text>
        You can connect{' '}
        <Box as="span" fontWeight="medium">
          Credit Cards
        </Box>{' '}
        to:
      </Text>
      <UnorderedList ml="25px" mb="15px">
        <ListItem>Import transactions</ListItem>
      </UnorderedList>
      <Text>You will be able to manage how each account is used during the account set up.</Text>
    </Box>
  );
}

export default PlaidMessage;
