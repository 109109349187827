import React from 'react';

type IconDownloadProps = {
  w?: Number,
  h?: Number,
  color?: String,
};

function IconDownload({ w = 16, h = 16, color = 'currentColor' }: IconDownloadProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.5C8.46024 0.5 8.83333 0.873096 8.83333 1.33333V9.32149L10.7441 7.41074C11.0695 7.08531 11.5972 7.08531 11.9226 7.41074C12.248 7.73618 12.248 8.26382 11.9226 8.58926L8.58926 11.9226C8.26382 12.248 7.73618 12.248 7.41074 11.9226L4.07741 8.58926C3.75197 8.26382 3.75197 7.73618 4.07741 7.41074C4.40285 7.08531 4.93049 7.08531 5.25592 7.41074L7.16667 9.32149V1.33333C7.16667 0.873096 7.53976 0.5 8 0.5ZM1.33333 10.5C1.79357 10.5 2.16667 10.8731 2.16667 11.3333V12.1667C2.16667 12.6087 2.34226 13.0326 2.65482 13.3452C2.96738 13.6577 3.39131 13.8333 3.83333 13.8333H12.1667C12.6087 13.8333 13.0326 13.6577 13.3452 13.3452C13.6577 13.0326 13.8333 12.6087 13.8333 12.1667V11.3333C13.8333 10.8731 14.2064 10.5 14.6667 10.5C15.1269 10.5 15.5 10.8731 15.5 11.3333V12.1667C15.5 13.0507 15.1488 13.8986 14.5237 14.5237C13.8986 15.1488 13.0507 15.5 12.1667 15.5H3.83333C2.94928 15.5 2.10143 15.1488 1.47631 14.5237C0.851189 13.8986 0.5 13.0507 0.5 12.1667V11.3333C0.5 10.8731 0.873096 10.5 1.33333 10.5Z"
        fill={color}
      />
    </svg>
  );
}

IconDownload.defaultProps = {
  w: 16,
  h: 16,
  color: 'currentColor',
};

export default IconDownload;
