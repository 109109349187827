// @flow
import React from 'react';
import { Button } from '@chakra-ui/react';
import SortDirectionEnum from '../../models/SortDirectionEnum';
import type { Sort, SortField, SortsAndFilters } from '../../../../types/Sort';
import { IconListDown, IconListUp } from '../../../../../Icons';

function SortHeaderItem({
  title,
  field,
  filters,
  onSort,
}: {
  title: string,
  field: SortField,
  filters: SortsAndFilters,
  onSort: Function,
}): any {
  function sort() {
    const itsAscending = (filters && filters?.sort?.direction) === SortDirectionEnum.Asc;

    const newSortObject: Sort = {
      field,
      direction: itsAscending ? SortDirectionEnum.Desc : SortDirectionEnum.Asc,
    };

    onSort(newSortObject);
  }

  const chevronDownIcon = <IconListDown />;

  const chevronUpIcon = <IconListUp />;

  function getIcon() {
    let icon = null;

    const itsTheActiveField = (filters && filters?.sort?.field) === field;

    const itsAscending = (filters && filters?.sort?.direction) === SortDirectionEnum.Asc;

    if (itsTheActiveField && itsAscending) {
      icon = chevronUpIcon;
    }

    if (itsTheActiveField && !itsAscending) {
      icon = chevronDownIcon;
    }

    return icon;
  }

  return (
    <Button onClick={() => sort()} rightIcon={getIcon()} variant="transparent" palette="neutral">
      {title}
    </Button>
  );
}

export default SortHeaderItem;
