// @flow
import React, { useRef, useState } from 'react';
import moment from 'moment';
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spacer,
  Stack,
  Text,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  BaselaneDivider,
  BaselaneDrawer,
  BaselaneDropdown,
  BaselaneSingleDatePicker,
} from '@shared/components';
import AddBankAccount from '@core/components/AddBankAccount';
import getBreakPoints from '@core/utils/getBreakPoints';
import { formatDate } from '@core/utils/formatDate';
import { GET_UPDATE_PLAID_LINK_TOKEN } from '@core/apollo/queries';
import { IconWarningCircleOutline } from '@icons';
import { formErrorStyles, formLabelStyles } from '@shared/styles/input.style';
import { renderAccountDropdownItem } from '@core/components/Transactions/components/TransactionsFilters/helpers/transactionsFilters.helpers';
import type { Bank } from '../../../types';
import { CREATE_INVOICE } from '../../../queries';
import AddInvoiceAlerts from './AddInvoiceAlerts';
import AddInvoiceHeader from './AddInvoiceHeader';
import AddNewInvoiceItem from '../InvoiceDetails/AddNewInvoiceItem';
import FAQ from './FAQ';
import DisplayFee from '../../../LeaseSection/forms/DepositsAndFees/DisplayFee';
import FormLabelWithTooltip from '../../../LeaseSection/components/FormLabelWithTooltip';
import {
  getBankAccounts,
  receivingBankFormValidation,
} from '../../../LeaseSection/formHelpers/receivingBank.helper';
import { fieldContainer } from '../../../LeaseSection/styles/shared.styles';
import {
  resyncErrorText,
  resyncLinkStyles,
} from '../../../LeaseSection/styles/receivingBankAccount.styles';
import {
  containerstyles,
  textStyles2,
  textStyles,
  invoiceDetailsHeader,
} from '../../../styles/invoiceDetails.style';
import {
  formatValuesForInvoicesAPI,
  getTotalAmount,
  initialValuesAddInvoice,
} from '../../../helpers/invoiceDetails.helper';
import AddInvoiceFooter from './AddInvoiceFooter';

type AddInvoiceBodyProps = {
  setIsDirty: Function,
  formikRef: any,
  onClose: Function,
  leaseTenant: string,
  isPropertyDeleted: boolean,
  isMultiUnit: boolean,
  propertyName: string,
  unitName: string,
  bankProps: Object,
  receivingAccountId: string,
  leaseId: string,
  refetchLeaseInvoices: Function,
};

function AddInvoiceBody({
  setIsDirty,
  formikRef,
  onClose,
  leaseTenant,
  isPropertyDeleted,
  isMultiUnit,
  propertyName,
  unitName,
  bankProps,
  receivingAccountId,
  leaseId,
  refetchLeaseInvoices,
}: AddInvoiceBodyProps): any {
  const { isMinXL, isMin768 } = getBreakPoints();
  const { isOpen, onOpen, onClose: onDatePickerClose } = useDisclosure();

  const [createInvoice, { loading }] = useMutation(CREATE_INVOICE);

  const [totalAmount, setTotalAmount] = useState(0);

  // alert states
  const [isUnsavedChangesAlertOpen, setIsUnsavedChangesAlertOpen] = useState(false);
  const onUnsavedChangesAlertOpen = () => setIsUnsavedChangesAlertOpen(true);
  const onUnsavedChangesAlertClose = () => setIsUnsavedChangesAlertOpen(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [amountError, setAmountError] = useState(false);

  const [isAccountsTouched, setIsAccountsTouched] = useState(false);

  const cancelAlertRef = useRef();

  const toast = useToast();

  const showAddInvoiceSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: `Invoice added successfully`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showAddInvoiceErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: `Failed to add invoice`,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const handleSave = (valuesFormattedForAPI) => {
    const errorState = () => {
      formikRef?.current?.resetForm({
        values: initialValuesAddInvoice(receivingAccountId),
      });
      showAddInvoiceErrorToast();
    };

    createInvoice({ variables: { ...valuesFormattedForAPI } })
      .then((res) => {
        if (!res.errors) {
          showAddInvoiceSuccessToast();
          onClose();
          setTimeout(() => {
            refetchLeaseInvoices();
          }, 500);
        } else {
          errorState();
        }
      })
      .catch(() => {
        errorState();
      });
  };

  const handleOnSubmit = (values) => {
    // TODO: id === null as invoice does not exist yet, depends on API
    const valuesFormattedForAPI = formatValuesForInvoicesAPI(
      null,
      values,
      leaseId,
      receivingAccountId
    );
    handleSave(valuesFormattedForAPI);
  };

  const handleCancelButton = ({ dirty }) => {
    if (dirty) {
      onUnsavedChangesAlertOpen();
    } else {
      onUnsavedChangesAlertClose();
      formikRef?.current.resetForm({
        values: initialValuesAddInvoice(receivingAccountId),
      });
    }
  };

  const { banksData, refetchBankAccounts } = bankProps || {};

  const banks: Bank[] = banksData ? banksData.bank : [];
  // get all accounts including unconnected ones (ITEM_LOGIN_REQUIRED)
  const accounts = getBankAccounts(banks, true);

  const accountsWithoutSavings = accounts.filter(
    (account) =>
      account.name.indexOf('Baselane') === -1 ||
      (account.name.indexOf('Baselane') > -1 && account.accountSubType !== 'savings')
  );
  const connectedAccountsFilterOptionsClean = accountsWithoutSavings.map((item) => {
    return {
      id: item.id,
      bankName: item.nickName || item.name,
      account: item.number,
      connectionState: item.connectionState,
    };
  });

  const [selectedAccount, setSelectedAccount] = useState(null);

  const [receivingPlaidLinkToken, setReceivingPlaidLinkToken] = useState(null);
  // for resync purposes
  const [getResyncToken] = useLazyQuery(GET_UPDATE_PLAID_LINK_TOKEN);

  const baselaneAccounts = accounts
    .filter((item) => item.name.indexOf('Baselane') > -1 && item.accountSubType !== 'savings')
    .map((item) => {
      return { id: item.id, bankName: item.nickName || item.name, account: item.number };
    });

  const externalAccounts = accounts
    .filter((item) => item.name.indexOf('Baselane') === -1)
    .map((item) => {
      return { id: item.id, bankName: item.nickName || item.name, account: item.number };
    });

  const connectedAccountsFilterOptions = [
    {
      id: 'baselane_account',
      items: baselaneAccounts,
      title: 'Baselane Accounts',
    },
    {
      id: 'external_account',
      items: externalAccounts,
      title: 'External Accounts',
    },
  ];

  const handleValidation = (values) => {
    const isFirstLoad = false;
    const isAddInvoice = true;
    const errors = receivingBankFormValidation(
      accounts,
      false,
      {
        rentAndFeesBankAccountId: values?.destinationBankAcctId,
      },
      isFirstLoad,
      isAddInvoice
    );
    if (errors.rentAndFeesBankAccountId) {
      errors.destinationBankAcctId = errors.rentAndFeesBankAccountId;
      return errors;
    }

    // get fees that are unfinished
    const invalidFees = values?.fees?.filter((fee) => {
      if (
        fee.description.replace(/\s/g, '') &&
        fee.amount &&
        Number(fee.amount?.replace(/$|,/g, '')) > 0.49
      ) {
        return false;
      }
      return true;
    });

    if (invalidFees?.length > 0) {
      errors.fees = 'Fill in all fields';
    } else {
      // all good, update total even with fees under edit as they're validated now
      setTotalAmount(getTotalAmount(values.fees, true));
    }
    return errors;
  };

  const handleContinueButtonClick = () => {
    handleCancelButton({});
    onUnsavedChangesAlertClose();
  };

  const { DrawerBody } = BaselaneDrawer;

  return (
    <DrawerBody id="invoice-body" p="0">
      <Formik
        innerRef={formikRef}
        initialValues={initialValuesAddInvoice(null)}
        validate={handleValidation}
        onSubmit={handleOnSubmit}
        validateOnBlur
      >
        {({
          dirty,
          handleSubmit,
          isValid,
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          validateForm,
          setFieldError,
        }) => {
          const hasDateError =
            (errors.dueDate && touched.dueDate) || (touched.dueDate && !values.dueDate);

          return (
            <>
              <Box {...containerstyles({ isMinXL })}>
                <AddInvoiceHeader
                  leaseTenant={leaseTenant}
                  {...{ propertyName, unitName, isPropertyDeleted, isMultiUnit }}
                />

                <Stack px={3} spacing={4}>
                  <Box>
                    <Stack spacing={isMinXL ? '24px' : '16px'}>
                      {/* Fee Due Date */}
                      <FormControl
                        {...fieldContainer}
                        w={isMinXL ? 'calc(50% - 18px)' : '100%'}
                        isInvalid={
                          (errors.dueDate && touched.dueDate) ||
                          (touched.dueDate && !values.dueDate)
                        }
                      >
                        <FormLabel {...formLabelStyles.xs} htmlFor="dueDate">
                          Due Date
                        </FormLabel>
                        <Spacer />
                        <BaselaneSingleDatePicker
                          {...{
                            id: 'dueDate',
                            name: 'dueDate',
                            minDate: moment().toDate(),
                            portalId: 'invoice-body',
                            date: values.dueDate,
                            value: values.dueDate
                              ? `${formatDate(values.dueDate, 'MMM DD, YYYY')}`
                              : '',
                            dateFormat: 'MMM dd, yyyy',
                            handleCalendarClose: ({ date: dueDate, isFromApplyButton }) => {
                              if (isMin768 || (!isMin768 && isFromApplyButton)) {
                                formikRef.current?.setFieldValue('dueDate', dueDate);
                              }
                            },
                            size: 'lg',
                            years: { start: new Date().getFullYear(), end: 2043 },
                            isOpen,
                            onOpen,
                            onClose: onDatePickerClose,
                            hideInputIcon: true,
                            fixedHeight: true,
                            placement: 'bottom-start',
                            showInModal: !isMin768,
                            className: `form-datepicker ${hasDateError ? 'has-error' : ''}`,
                          }}
                        />
                        <FormErrorMessage {...formErrorStyles} ml="0">
                          <IconWarningCircleOutline color="#C93A3A" />
                          <Text ml="8px">Enter due date</Text>
                        </FormErrorMessage>
                      </FormControl>
                    </Stack>
                    <Stack mt="8px" verticalAlign="middle">
                      <FormControl
                        {...fieldContainer}
                        mb="24px"
                        isInvalid={errors.destinationBankAcctId && isAccountsTouched}
                      >
                        <FormLabelWithTooltip
                          htmlFor="destinationBankAcctId"
                          text="Receiving Bank Account"
                          tooltipText="Choose account to receive invoice amount."
                        />
                        <BaselaneDropdown
                          {...{
                            isDisabled: connectedAccountsFilterOptionsClean.length === 0,
                            hasError: errors?.destinationBankAcctId,
                            handleSubmit: (id) => {
                              const acc = connectedAccountsFilterOptionsClean.find(
                                (option) => option.id === id
                              );
                              setSelectedAccount(acc);
                              setFieldValue('destinationBankAcctId', id);
                              setIsAccountsTouched(true);
                              // if connectionState === 'ITEM_LOGIN_REQUIRED'
                              if (acc.connectionState === 'ITEM_LOGIN_REQUIRED') {
                                getResyncToken({
                                  variables: { bankAccountId: acc?.id },
                                }).then((res) => {
                                  setReceivingPlaidLinkToken(res?.data?.updatePlaidLinkToken);
                                });
                              }
                            },
                            data: connectedAccountsFilterOptions,
                            searchTerm: ['account', 'bankName'],
                            showValueByFields: ['bankName', 'account'],
                            title: selectedAccount?.bankName || (
                              <Text fontSize="14px">Select Account</Text>
                            ),
                            showTitleInSearch: false,
                            itemRenderer: renderAccountDropdownItem,
                            selectedItem: selectedAccount,
                            dropdownPosition: 'bottom',
                            optionStyles: { h: '44px' },
                            styles: {
                              height: '48px',
                              width: '100%',
                              minWidth: '100%',
                              fontSize: '14px',
                              color: '#192C3E',
                            },
                          }}
                        />
                        {errors.destinationBankAcctId !== 'Disconnected' && (
                          <FormErrorMessage {...formErrorStyles}>
                            {errors.destinationBankAcctId}
                          </FormErrorMessage>
                        )}
                        {errors.destinationBankAcctId === 'Disconnected' && (
                          <FormErrorMessage {...resyncErrorText}>
                            Account connection expired.{' '}
                            {receivingPlaidLinkToken && (
                              <AddBankAccount
                                bankAccId={selectedAccount?.id}
                                mode="RESYNC"
                                titleText="Resync your account"
                                size="sm"
                                variant="link"
                                palette="danger"
                                showCustomButton
                                hasIconLock={false}
                                hasRightChevronIcon={false}
                                styles={resyncLinkStyles}
                                containerStyles={{ as: 'span' }}
                                banks={banks}
                                state="ITEM_LOGIN_REQUIRED"
                                handleSuccessFn={(res) => {
                                  const { connectionState } =
                                    res?.data?.reSyncExternalBankAccount ?? {};
                                  if (connectionState === 'CONNECTED') {
                                    refetchBankAccounts().then(() => {
                                      handleValidation(values);
                                    });
                                  }
                                }}
                                updateLinkToken={receivingPlaidLinkToken}
                              />
                            )}{' '}
                            {!receivingPlaidLinkToken && 'Resync '}
                            to receive payments.
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Stack>
                    <Text {...invoiceDetailsHeader}>Invoice Items</Text>
                    <DisplayFee
                      {...{
                        dueDate: values.dueDate,
                        additionalFeesFields: values.fees?.filter((item) => !item.edit),
                        handleFeeDelete: ({ description, amount }) => {
                          const feeIndex = values.fees.findIndex(
                            (item) => item.description === description && item.amount === amount
                          );
                          const clonedFees = [...values.fees];
                          clonedFees.splice(feeIndex, 1);
                          setFieldValue(
                            'fees',
                            clonedFees.length === 0
                              ? [
                                  {
                                    description: '',
                                    amount: undefined,
                                    newFee: true,
                                    edit: true,
                                    dueDate: values?.dueDate,
                                    paymentType: 'ONE_TIME_FEE',
                                  },
                                ]
                              : clonedFees
                          );
                          const total = getTotalAmount(clonedFees, true, amount);
                          setTotalAmount(total);
                        },
                      }}
                    />
                    <AddNewInvoiceItem
                      editState
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      dirty={dirty}
                      setIsDirty={setIsDirty}
                      setTotalAmount={setTotalAmount}
                      getTotalAmount={getTotalAmount}
                      validateForm={validateForm}
                      isFromAddInvoice
                      setFieldError={setFieldError}
                      descriptionError={descriptionError}
                      setDescriptionError={setDescriptionError}
                      amountError={amountError}
                      setAmountError={setAmountError}
                    />

                    <Box>
                      <BaselaneDivider styles={{ m: '24px 0 16px' }} isVertical={false} />
                    </Box>

                    <Stack direction="row" justifyContent="space-between">
                      <Text {...textStyles}>Total</Text>
                      <Text {...textStyles2}>{totalAmount}</Text>
                    </Stack>
                    <Stack py="24px">
                      <FAQ />
                    </Stack>
                  </Box>
                </Stack>
              </Box>

              <AddInvoiceFooter
                dirty={dirty}
                handleCancelButton={handleCancelButton}
                handleSubmit={(submittedValues) => {
                  // formikRef?.current?.validateForm();
                  setIsDirty(true);
                  formikRef?.current?.values?.fees
                    .filter((fee) => fee.newFee)
                    .forEach((fee) => {
                      setDescriptionError(!fee.description.replace(/\s/g, ''));
                      setAmountError(!fee.amount || Number(fee.amount?.replace(/\$|,/g, '')) < 0.5);
                    });
                  setIsAccountsTouched(true);
                  handleSubmit(submittedValues);
                }}
                isValid={isValid}
                onClose={onClose}
                loading={loading}
              />
            </>
          );
        }}
      </Formik>

      <AddInvoiceAlerts
        unsavedChangesAlert={{
          isUnsavedChangesAlertOpen,
          cancelAlertRef,
          onUnsavedChangesAlertClose,
          handleContinueButtonClick,
        }}
      />
    </DrawerBody>
  );
}

export default AddInvoiceBody;
