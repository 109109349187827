// @flow
import React from 'react';
import { isMobile } from 'react-device-detect';
import BaselaneBadge from '../BaselaneBadge';
import {
  overdueBackgroundStyles,
  mobileContainerStyles,
  mobileFontStyles,
} from './styles/leaseBadge.style';

type Props = {
  text?: string,
  styles?: Object,
};

function OverdueStatusBadge({ text, styles }: Props): any {
  return isMobile ? (
    <BaselaneBadge
      backgroundStyles={{ ...overdueBackgroundStyles, ...mobileContainerStyles, ...styles }}
      fontStyles={{ ...mobileFontStyles, ...styles }}
      type="master"
      color="red"
      isMobile
      text={text?.toUpperCase()}
    />
  ) : (
    <BaselaneBadge
      backgroundStyles={{ ...overdueBackgroundStyles, ...styles }}
      type="master"
      color="red"
      text={text}
      iconName="exclamationtriangle"
      iconClassName="icon-md"
    />
  );
}

OverdueStatusBadge.defaultProps = {
  text: 'Overdue',
  styles: {},
};

export default OverdueStatusBadge;
