const sortWithString = (dataset, key, key2) =>
  [...dataset].sort((a, b) => {
    let aStripped = a;
    let bStripped = b;

    if (key) {
      // remove space and set to lowercase
      aStripped =
        a[key] && a[key] !== null
          ? a[key]?.toLowerCase().replace(/\s/g, '')
          : a[key2]?.toLowerCase().replace(/\s/g, '');
      bStripped =
        b[key] && b[key] !== null
          ? b[key]?.toLowerCase().replace(/\s/g, '')
          : b[key2]?.toLowerCase().replace(/\s/g, '');
    }

    if (aStripped < bStripped) {
      return -1;
    }

    if (aStripped > bStripped) {
      return 1;
    }
    return 0;
  });

export default sortWithString;
