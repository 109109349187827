import React, { useState } from 'react';
import { Text } from '@chakra-ui/react';
import { BaselaneAlert, BaselaneButton } from '../../../../../../Shared/components';
import EmptyStateFeatureList from '../../../../../components/EmptyStateFeatureList';
import {
  mobileAlertContainerStyles,
  mobileAlertheaderStyles,
  mobileBodyStyles,
  mobileCloseButtonStyles,
  mobileTitleTextStyles,
} from '../styles/responsive.styles';

const MobileEmptyStateWarningBanner = ({ isMobile }: { isMobile?: boolean }) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onAlertClose = () => setIsAlertOpen(false);
  const onAlertOpen = () => setIsAlertOpen(true);

  const mobileSetUpAccountAlertHeader = (
    <Text styles={mobileTitleTextStyles}>Why Baselane Account</Text>
  );

  return (
    <>
      <BaselaneAlert
        isOpen={isAlertOpen}
        onClose={onAlertClose}
        closeOnOverlayClick
        showCloseButton
        closeButtonStyles={mobileCloseButtonStyles}
        containerStyles={mobileAlertContainerStyles}
        header={mobileSetUpAccountAlertHeader}
        headerStyles={mobileAlertheaderStyles}
        bodyStyles={mobileBodyStyles}
        body={<EmptyStateFeatureList isMobile={isMobile} />}
      />
      <BaselaneButton variant="outline" palette="primary" size="sm" onClick={onAlertOpen}>
        Learn More
      </BaselaneButton>
    </>
  );
};

MobileEmptyStateWarningBanner.defaultProps = {
  isMobile: false,
};

export default MobileEmptyStateWarningBanner;
