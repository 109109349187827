export const mobileBodyStyles = {
  p: '0',
  m: '16px 0 0',
  fontSize: 'sm',
  lineHeight: '20px',
  fontWeight: 'normal',
  color: 'brand.neutral.600',
};

export const mobileAlertContainerStyles = {
  p: '32px 16px 24px',
  m: '6px',
  justifyContent: 'left',
  borderRadius: '12px',
};

export const mobileAlertheaderStyles = {
  fontSize: 'lg',
  lineHeight: '26px',
  fontWeight: 'semibold',
  color: 'brand.neutral.700',
  pl: '0',
};

export const mobileCloseButtonStyles = {
  border: 'none !important',
  top: '20px',
  right: '20px',
};

export const mobileTitleTextStyles = {
  lineHeight: '32px',
  fontWeight: 'medium',
  color: 'brand.neutral.700',
  p: '0',
  mt: '2px',
  ml: '8px!important',
};

export const buttonStyles = {
  height: '32px',
  fontSize: 'xs',
  fontWeight: 'normal',
  lineHeight: '45px',
};
