export const tenantFormHeadingStyles = {
  fontWeight: 'semibold',
  fontSize: { base: 'xl', md: '2xl' },
  lineHeight: { base: '28px', md: '32px' },
  color: 'brand.darkBlue.900',
};

export const tenantFormTextStyles = {
  fontWeight: 'semibold',
  fontSize: { base: 'sm', md: 'md' },
  lineHeight: { base: '20px', md: '24px' },
  color: 'brand.darkBlue.900',
  mt: '16px',
};

export const tenantFormPointStyles = {
  fontWeight: 'normal',
  fontSize: { base: 'sm', md: 'md' },
  lineHeight: '24px',
  color: 'brand.darkBlue.900',
  mt: '8px',
  display: 'flex',
};

export const hiddenContainerStyles = {
  display: 'none',
  appearance: 'hidden',
  [`aria-hidden`]: 'true',
};
