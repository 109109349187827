import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, HStack, Text } from '@chakra-ui/react';
import customTheme from '@core/theme';
import { IconQuestionMark, IconStar } from '@icons';
import BaselaneTooltip from '@shared/components/BaselaneTooltip';
import { badgeContainerStyles } from '@shared/components/IncentiveBadge/styles/incentiveBadge.styles';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

type IncentiveBadgeProps = {
  label: string,
  segmentEventName?: string,
  icon?: any,
  tooltipHoverElement?: any,
  containerStyles?: Object,
  labelStyles?: Object,
  tooltipProps?: Object,
  showTooltip?: boolean,
  size?: string,
};

const IncentiveBadge = ({
  label,
  icon,
  tooltipHoverElement,
  containerStyles,
  labelStyles,
  tooltipProps,
  segmentEventName,
  showTooltip,
  size,
}: IncentiveBadgeProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HStack
      {...{
        ...badgeContainerStyles(size),
        ...containerStyles,
      }}
    >
      {icon}
      <Text {...{ textStyle: 'xs', ...labelStyles }}>{label}</Text>
      {showTooltip && (
        <Box>
          <BaselaneTooltip placement="top" {...tooltipProps} isOpen={isOpen}>
            <Box
              onMouseEnter={() => {
                if (segmentEventName) sendSegmentEvent(segmentEventName);
                setIsOpen((prevState) => !prevState);
              }}
              onMouseLeave={() => {
                if (isOpen) {
                  setIsOpen(false);
                }
              }}
              onClick={() => {
                if (!isOpen) {
                  if (segmentEventName) sendSegmentEvent(segmentEventName);
                }
                setIsOpen((prevState) => !prevState);
              }}
            >
              {tooltipHoverElement}
            </Box>
          </BaselaneTooltip>
        </Box>
      )}
    </HStack>
  );
};

IncentiveBadge.defaultProps = {
  containerStyles: {},
  labelStyles: {},
  tooltipProps: {},
  icon: <IconStar />,
  tooltipHoverElement: (
    <IconQuestionMark width="14" height="14" color={customTheme.colors.yellow['900']} />
  ),
  showTooltip: true,
  segmentEventName: null,
  size: isMobile ? 'sm' : 'lg',
};

export default IncentiveBadge;
