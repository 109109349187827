import React from 'react';

function IconRocketLawyer() {
  return (
    <svg
      width="120"
      height="10"
      viewBox="0 0 120 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_261_22324)">
        <path
          d="M0.0644531 0.330078H6.24471C7.68677 0.330078 8.66531 0.689288 9.28334 1.35639C9.84986 1.86955 10.1074 2.58797 10.1074 3.51166V3.56297C10.1074 4.99981 9.33484 5.9235 8.20179 6.38534L10.4164 9.61824H7.42926L5.57518 6.79587H5.52368H4.44214V9.61824H1.86703V1.92087L0.0644531 0.330078ZM6.14171 4.74324C7.01724 4.74324 7.53226 4.33271 7.53226 3.6656V3.61429C7.53226 2.89587 7.01724 2.53666 6.14171 2.53666H4.44214V4.74324H6.14171Z"
          fill="#990000"
        />
        <path
          d="M10.7251 4.94868C10.7251 2.28026 12.8882 0.125 15.7208 0.125C18.6049 0.125 20.7165 2.22895 20.7165 4.89737V4.94868C20.7165 7.61711 18.5534 9.77237 15.7208 9.77237C12.8882 9.72105 10.7251 7.61711 10.7251 4.94868ZM18.0899 4.94868C18.0899 3.61447 17.1114 2.43421 15.7208 2.43421C14.3302 2.43421 13.3517 3.56316 13.3517 4.89737V4.94868C13.3517 6.2829 14.3302 7.46316 15.7208 7.46316C17.1628 7.41184 18.0899 6.2829 18.0899 4.94868Z"
          fill="#990000"
        />
        <path
          d="M21.438 4.94868C21.438 2.22895 23.4981 0.125 26.3307 0.125C28.2363 0.125 29.4208 0.894737 30.2449 2.075L28.2878 3.56316C27.7728 2.89605 27.1547 2.48553 26.2792 2.48553C24.9916 2.48553 24.0646 3.56316 24.0646 4.94868V5C24.0646 6.38553 24.9916 7.46316 26.2792 7.46316C27.2577 7.46316 27.8243 7.00132 28.3908 6.33421L30.3479 7.71974C29.4723 8.9 28.2878 9.82369 26.2277 9.82369C23.5496 9.72105 21.438 7.71974 21.438 4.94868Z"
          fill="#990000"
        />
        <path
          d="M31.2749 0.330078H33.85V4.07613L37.0431 0.330078H40.0818L36.5796 4.28139L40.1848 9.56692H37.0946L34.777 6.12876L33.85 7.15508V9.56692H31.2749V0.330078Z"
          fill="#990000"
        />
        <path
          d="M41.0088 0.330078H48.4766V2.48534H43.5324V3.87087H48.0131V5.9235H43.5324V7.36034H48.5281V9.56692H41.0088V0.330078Z"
          fill="#990000"
        />
        <path
          d="M51.9784 2.53666H49.1973V0.330078H57.3347V2.53666H54.5535V9.56692H51.9784V2.53666Z"
          fill="#990000"
        />
        <path
          d="M58.8799 9.56692V0.330078H59.5494V8.89981H65.0086V9.56692H58.8799Z"
          fill="#990000"
        />
        <path
          d="M74.691 9.56745L73.5064 7.00166H68.0472L66.8626 9.56745H66.1416L70.4678 0.279297H71.1373L75.4635 9.56745H74.691ZM70.7768 0.997716L68.3047 6.33456H73.1974L70.7768 0.997716Z"
          fill="#990000"
        />
        <path
          d="M85.3003 9.61877H84.7337L82.0041 1.61351L79.2745 9.61877H78.7595L75.3604 0.330613H76.1329L79.0685 8.54114L81.7466 0.279297H82.3131L85.0428 8.54114L87.9269 0.330613H88.6479L85.3003 9.61877Z"
          fill="#990000"
        />
        <path
          d="M94.0041 5.87218V9.56692H93.3346V5.87218L89.3174 0.330078H90.1929L93.6436 5.25639L97.1457 0.330078H97.9697L94.0041 5.87218Z"
          fill="#990000"
        />
        <path
          d="M99.4121 9.56692V0.330078H106.056V0.945867H100.133V4.58929H105.438V5.20508H100.133V8.89981H106.107V9.56692H99.4121Z"
          fill="#990000"
        />
        <path
          d="M114.863 9.56692L111.927 5.71824H108.888V9.56692H108.219V0.330078H112.133C114.142 0.330078 115.429 1.40771 115.429 2.94718V2.9985C115.429 4.53797 114.296 5.41034 112.699 5.6156L115.738 9.56692H114.863ZM114.708 2.94718C114.708 1.7156 113.729 0.945867 112.03 0.945867H108.888V5.10245H112.03C113.523 5.10245 114.708 4.33271 114.708 2.94718Z"
          fill="#990000"
        />
        <path
          d="M118.29 0.310547C118.559 0.310547 118.801 0.372742 119.017 0.497133C119.237 0.621524 119.409 0.79324 119.533 1.01228C119.657 1.22861 119.72 1.46927 119.72 1.73428C119.72 1.99929 119.656 2.24266 119.529 2.4644C119.402 2.68344 119.228 2.8565 119.009 2.9836C118.79 3.11069 118.548 3.17424 118.282 3.17424C118.017 3.17424 117.775 3.11069 117.555 2.9836C117.339 2.8565 117.167 2.68344 117.04 2.4644C116.915 2.24536 116.854 2.0047 116.854 1.74239C116.854 1.48009 116.915 1.23943 117.04 1.02039C117.167 0.801353 117.341 0.628284 117.56 0.501189C117.779 0.374094 118.023 0.310547 118.29 0.310547ZM118.282 2.99171C118.515 2.99171 118.726 2.93627 118.916 2.82541C119.105 2.71453 119.254 2.5631 119.362 2.37111C119.473 2.17912 119.529 1.96684 119.529 1.73428C119.529 1.50173 119.475 1.2908 119.366 1.10151C119.261 0.912221 119.113 0.763492 118.924 0.655328C118.737 0.547159 118.526 0.493077 118.29 0.493077C118.058 0.493077 117.845 0.548512 117.653 0.659382C117.464 0.770255 117.315 0.921688 117.206 1.11368C117.098 1.30297 117.044 1.51254 117.044 1.74239C117.044 1.97225 117.098 2.18317 117.206 2.37516C117.315 2.56446 117.464 2.71453 117.653 2.82541C117.842 2.93627 118.052 2.99171 118.282 2.99171ZM119.017 1.4828C119.017 1.60448 118.988 1.70859 118.928 1.79512C118.868 1.88166 118.784 1.94385 118.676 1.98171L119.046 2.55364H118.766L118.428 2.03444H117.986V2.55364H117.71V0.931151H118.359C118.562 0.931151 118.723 0.981179 118.838 1.08123C118.958 1.18128 119.017 1.31514 119.017 1.4828ZM118.339 1.80729C118.469 1.80729 118.57 1.7789 118.64 1.72211C118.713 1.66533 118.749 1.58556 118.749 1.4828C118.749 1.38004 118.713 1.30162 118.64 1.24753C118.57 1.19075 118.469 1.16235 118.339 1.16235H117.982V1.80729H118.339Z"
          fill="#990000"
        />
      </g>
      <defs>
        <clipPath id="clip0_261_22324">
          <rect width="120" height="9.75" fill="white" transform="translate(0 0.125)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconRocketLawyer;
