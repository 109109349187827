export const titleTextStyles = {
  textStyle: 'headline-lg',
};

export const instructionTitleTextStyles = {
  textStyle: 'headline-md',
  mb: '8px',
};

export const instructionListStyles = {
  spacing: '16px',
  position: 'relative',
};

export const instructionListItemStyles = {
  textStyle: 'sm',
  position: 'relative',
};

export const instructionListLineStyles = {
  position: 'absolute',
  w: '1px',
  h: '100%',
  bg: 'brand.darkBlue.250',
  top: '20px',
  left: '-8px',
};

export const informationNeededContainerStyles = {
  mt: '40px',
  spacing: '8px',
};

export const informationNeededTitleTextStyles = {
  textStyle: 'headline-md',
};
