import React from 'react';
import { groupBy } from 'lodash';
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  Button,
  Stack,
  Text,
  Spacer,
} from '@chakra-ui/react';
import IconCarretDown from '@icons/legacy/IconCarretDown';
import {
  placeholderStyles,
  menuButtonStyles,
  menuStyles,
  menuItemStyles,
  activeMenuItemStyles,
  menuGroupItemStyles,
  menuIconStyles,
  optionLabelStyles,
} from './styles/baselaneSelect.style';

import BaselaneDivider from '../BaselaneDivider';

type BaselaneSelectProps = {
  menuOptions: Array,
  selectedOption: Object,
  setSelectedOption: Function,
  placeholder?: string,
  isDisabled?: boolean,
  inputStyles?: Object,
  listStyles?: Object,
  groupItemStyles?: Object,
  itemStyles?: Object,
  iconStyles?: Object,
  isValid?: boolean,
  strategy?: 'absolute' | 'fixed',
};

function BaselaneSelect({
  menuOptions,
  selectedOption,
  setSelectedOption,
  placeholder,
  isDisabled,
  inputStyles,
  listStyles,
  groupItemStyles,
  itemStyles,
  iconStyles,
  isValid,
  strategy,
}: BaselaneSelectProps) {
  const optionsByGroupId = groupBy(menuOptions, 'groupId');
  const groupedOptions = [];
  let options = [];
  Object.keys(optionsByGroupId)?.forEach((group) => {
    if (group !== 'undefined') {
      const [commonGroup] = optionsByGroupId[group];
      groupedOptions.push({
        groupName: commonGroup?.group?.name,
        groupId: commonGroup?.group?.id,
        options: optionsByGroupId[group],
      });
      // No group found
    } else if (group === 'undefined') {
      const option = optionsByGroupId[group];
      options = options.concat(option);
    }
  });

  const handleSelectOption = ({ g: group, o: option }) => {
    if (group) {
      const groupedOption = {
        ...option,
        label: group.groupName ? `${group.groupName}: ${option.label}` : option.label,
      };
      setSelectedOption(groupedOption);
    } else {
      setSelectedOption(option);
    }
  };

  return (
    <Menu closeOnSelect matchWidth autoSelect={false} strategy={strategy}>
      <MenuButton
        as={Button}
        {...{
          isDisabled,
          ...menuButtonStyles(isValid),
          ...(!selectedOption?.label && placeholderStyles),
          ...inputStyles,
        }}
      >
        <Stack direction="row" alignItems="center" spacing="24px" width="100%">
          <Text {...optionLabelStyles}>{selectedOption?.label || placeholder}</Text>
          <Spacer />
          {selectedOption?.value && <Text textAlign="right">{selectedOption?.value || ''}</Text>}
          <Box {...{ ...menuIconStyles, ...iconStyles }}>
            <IconCarretDown />
          </Box>
        </Stack>
      </MenuButton>

      <MenuList
        {...{
          ...menuStyles,
          ...listStyles,
        }}
      >
        {groupedOptions?.map((g) => (
          <MenuOptionGroup
            title={g.groupName}
            key={g.groupId}
            {...{ ...menuGroupItemStyles, ...groupItemStyles }}
          >
            {g.options?.map((o) => (
              <MenuItem
                {...{ ...menuItemStyles, ...itemStyles, ...o.styles }}
                {...(o.id === selectedOption?.id && activeMenuItemStyles)}
                key={o.id}
                value={o.label}
                onClick={() => handleSelectOption({ g, o })}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Text {...optionLabelStyles}>{o.label}</Text>
                  {o?.value && (
                    <>
                      <Spacer />
                      <Text textAlign="right">{o?.value || ''}</Text>
                    </>
                  )}
                </Stack>
              </MenuItem>
            ))}
          </MenuOptionGroup>
        ))}
        {/* Add divider if there are grouped and ungroup options in the same list */}
        {groupedOptions?.length > 0 && options?.length > 0 && <BaselaneDivider />}
        {options?.map((o) => (
          <MenuItem
            {...{ ...menuItemStyles, ...itemStyles, ...o.styles }}
            {...(o.id === selectedOption?.id && activeMenuItemStyles)}
            key={o.id}
            value={o.label}
            onClick={() => handleSelectOption({ o })}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <Text {...optionLabelStyles}>{o.label}</Text>
              {o?.value && (
                <>
                  <Spacer />
                  <Text textAlign="right">{o?.value || ''}</Text>
                </>
              )}
            </Stack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

BaselaneSelect.defaultProps = {
  placeholder: 'Select Item',
  isDisabled: false,
  inputStyles: {},
  listStyles: {},
  groupItemStyles: {},
  itemStyles: {},
  iconStyles: {},
  isValid: true,
  strategy: 'absolute',
};

export default BaselaneSelect;
