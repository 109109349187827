import moment from 'moment';
import { getLeaseRemainingTime } from '@pages/LeasesPage/helpers/unit.helpers';

export const getIsRentExternalAcc = ({ rentAndFeesBankAccountId, bankProps }) => {
  const { banksData } = bankProps || {};
  const banks = banksData ? banksData.bank : [];
  const accounts = [];

  banks.forEach((item) =>
    item?.bankAccounts?.forEach((a) => {
      accounts.push(a);
      a?.subAccounts?.forEach((sa) => {
        accounts.push(sa);
      });
    })
  );

  const isRentExternalAcc = !!(
    accounts?.find((acc) => acc.id === rentAndFeesBankAccountId && acc.isExternal) ?? null
  );

  return isRentExternalAcc;
};

export const getLeaseStatusType = ({ lease }) => {
  const leaseRemainingTime = lease?.archived ? 'Archived' : getLeaseRemainingTime(lease);
  let leaseStatusType = lease?.archived ? 'Archived' : 'Active';
  if (leaseRemainingTime === 'Lease expired') {
    leaseStatusType = 'Expired';
  }

  return leaseStatusType;
};

export const getRemainingDays = ({ lease }) => {
  const currentMomentDate = moment(new Date().toDateString());
  const endMomentDate = moment(lease?.endDate);
  const remainingDays = endMomentDate.diff(currentMomentDate, 'days');

  return remainingDays;
};
