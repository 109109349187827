import React from 'react';
import { FormControl, FormErrorMessage, FormLabel, Input, Text } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  nickNameRowStyles,
  formErrorStyles,
  formInputStyles,
  formLabelStyles,
  accountNickNameLabelStyles,
} from '../styles/formControls.styles';

type NickNameProps = {
  values: Object,
  label: string,
  handleFormUpdate: Function,
  handleBlur: Function,
  handleChange: Function,
  dirty: boolean,
  errors?: Object,
};

const NickName = ({
  values,
  label,
  handleFormUpdate,
  handleBlur,
  handleChange,
  dirty,
  errors,
}: NickNameProps) => {
  const { isMinXL } = getBreakPoints();

  return (
    <FormControl isInvalid={errors.nickName} isRequired {...nickNameRowStyles({ isMinXL })}>
      <FormLabel
        htmlFor="nickName"
        {...{
          ...formLabelStyles.xs,
          ...accountNickNameLabelStyles,
        }}
      >
        {label}
      </FormLabel>
      <Input
        id="nickName"
        name="nickName"
        value={values.nickName}
        onChange={handleChange}
        onBlur={(e) => {
          handleBlur(e);
          handleFormUpdate('nickName', values, dirty);
        }}
        {...formInputStyles}
      />
      <FormErrorMessage {...formErrorStyles}>
        {errors.nickName ? <Text>{errors.nickName}</Text> : null}
      </FormErrorMessage>
    </FormControl>
  );
};

NickName.defaultProps = {
  errors: { nickName: null },
};

export default NickName;
