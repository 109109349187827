import React from 'react';
import { Stack } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import InfoBoxWithIcon from './infoBoxWithIcon';
import { listContainerStyles } from './styles/infoBoxList.styles';

type InfoBoxListProps = { listContent: Object };

function InfoBoxList({ listContent }: InfoBoxListProps) {
  return (
    !!listContent && (
      <Stack {...listContainerStyles}>
        {listContent.map((item) => (
          <InfoBoxWithIcon
            id={item.id}
            key={uuidv4()}
            icon={item.icon}
            title={item.title}
            description={item.description}
          />
        ))}
      </Stack>
    )
  );
}

export default InfoBoxList;
