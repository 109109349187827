import React from 'react';

type IconCheckCircleNoPaddingProps = {
  width: string,
  height: string,
  viewBox: string,
  bgColor: string,
  color: string,
};

function IconCheckCircleNoPadding({
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  bgColor = '#257ED0',
  color = '#FFF',
}: IconCheckCircleNoPaddingProps) {
  return (
    <svg {...{ width, height, viewBox }} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill={bgColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8536 5.14645C12.0488 5.34171 12.0488 5.65829 11.8536 5.85355L6.85355 10.8536C6.65829 11.0488 6.34171 11.0488 6.14645 10.8536L4.14645 8.85355C3.95118 8.65829 3.95118 8.34171 4.14645 8.14645C4.34171 7.95118 4.65829 7.95118 4.85355 8.14645L6.5 9.79289L11.1464 5.14645C11.3417 4.95118 11.6583 4.95118 11.8536 5.14645Z"
        fill={color}
      />
    </svg>
  );
}

export default IconCheckCircleNoPadding;
