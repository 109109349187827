/* eslint-disable react/prop-types */
import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { BaselaneChip } from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import checkIsTransfer from '@core/utils/checkIsTransfer.helper';
import RecurringIcon from '../../SendFunds/components/FormSteps/PaymentDetailsStep/recurring-payment/review/RecurringIcon';
import { typeOfTransferFormatter, getPayee, getRecurrance } from '../helpers/helpers';

const ScheduledPaymentsHeader = ({ details, isOneTimePayment, recurringPaymentId }) => {
  if (!details) {
    return null;
  }
  const stackContainerStyles = {
    justifyContent: 'space-between',
  };

  const isTransfer = checkIsTransfer(details);

  return (
    <Box gap={0}>
      <HStack sx={stackContainerStyles}>
        <Text textStyle="xs">{`${typeOfTransferFormatter(details)}`}</Text>

        {isOneTimePayment ? (
          <BaselaneChip size="sm" label="Scheduled" variant="status-warning" />
        ) : (
          <BaselaneChip size="sm" label="Recurring" leftIcon={RecurringIcon} />
        )}
      </HStack>

      <HStack sx={stackContainerStyles}>
        <Box>
          <Text textStyle="headline-sm">
            {isTransfer ? 'Your accounts' : getPayee(details, isOneTimePayment)}
          </Text>

          <Text textStyle="xs" color="brand.neutral.600">
            {getRecurrance(details, isOneTimePayment)}
          </Text>
        </Box>
        <Text textStyle="data-lg" color="brand.neutral.900">
          {details?.amount && !isNaN(parseFloat(details.amount))
            ? formatCurrency(parseFloat(details.amount)).inDollars
            : 'Invalid amount'}
        </Text>
      </HStack>
    </Box>
  );
};

export default ScheduledPaymentsHeader;
