import React from 'react';
import IconPropsType from './types/icon.types';

function IconChartWithBadge({ w, h, color }: IconPropsType) {
  return (
    <svg width={w} height={h} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12592_291868)">
        <rect width="48" height="48" fill={color} />
        <g clipPath="url(#clip1_12592_291868)">
          <rect
            x="1.28516"
            y="29.3564"
            width="7.71429"
            height="12.8571"
            rx="1.28571"
            fill="#8CCFFF"
          />
          <rect x="1.28516" y="29.3564" width="7.71429" height="2.57143" fill="#0968BF" />
        </g>
        <g clipPath="url(#clip2_12592_291868)">
          <rect
            x="11.5703"
            y="20.7852"
            width="7.71429"
            height="21.4286"
            rx="1.28571"
            fill="#8CCFFF"
          />
          <rect x="11.5703" y="20.7852" width="7.71429" height="2.57143" fill="#0968BF" />
        </g>
        <g clipPath="url(#clip3_12592_291868)">
          <rect
            x="21.8555"
            y="8.78516"
            width="7.71429"
            height="33.4286"
            rx="1.28571"
            fill="#8CCFFF"
          />
          <rect x="21.8555" y="8.78516" width="7.71429" height="2.57143" fill="#0968BF" />
        </g>
        <g clipPath="url(#clip4_12592_291868)">
          <rect
            x="32.1406"
            y="1.07031"
            width="7.71429"
            height="41.1429"
            rx="1.28571"
            fill="#8CCFFF"
          />
          <rect x="32.1406" y="1.07031" width="7.71429" height="2.57143" fill="#0968BF" />
        </g>
        <mask
          id="path-9-outside-1_12592_291868"
          maskUnits="userSpaceOnUse"
          x="20.8555"
          y="18.2852"
          width="27"
          height="32"
          fill="black"
        >
          <rect fill="white" x="20.8555" y="18.2852" width="27" height="32" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.8555 22.8773C36.8555 22.3223 36.1855 22.0734 35.8475 22.5025L24.9852 35.0331C24.6813 35.4188 24.9455 35.9989 25.4247 35.9989L31.7129 35.9992L31.7129 45.693C31.7129 46.248 32.3828 46.4969 32.7209 46.0678L43.5833 33.5372C43.8873 33.1515 43.623 32.5714 43.1438 32.5714L36.8555 32.5711V22.8773Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.8555 22.8773C36.8555 22.3223 36.1855 22.0734 35.8475 22.5025L24.9852 35.0331C24.6813 35.4188 24.9455 35.9989 25.4247 35.9989L31.7129 35.9992L31.7129 45.693C31.7129 46.248 32.3828 46.4969 32.7209 46.0678L43.5833 33.5372C43.8873 33.1515 43.623 32.5714 43.1438 32.5714L36.8555 32.5711V22.8773Z"
          fill="white"
        />
        <path
          d="M35.8475 22.5025L38.4382 24.7482L38.4908 24.6875L38.5406 24.6244L35.8475 22.5025ZM24.9852 35.0331L22.3945 32.7873L22.3418 32.8481L22.2921 32.9112L24.9852 35.0331ZM25.4247 35.9989L25.4248 32.5703H25.4247V35.9989ZM31.7129 35.9992H35.1415V32.5708L31.7131 32.5706L31.7129 35.9992ZM31.7129 45.693H28.2843H31.7129ZM32.7209 46.0678L30.1302 43.8221L30.0776 43.8828L30.0278 43.9459L32.7209 46.0678ZM43.5833 33.5372L46.174 35.783L46.2267 35.7223L46.2764 35.6591L43.5833 33.5372ZM43.1438 32.5714L43.1437 36H43.1438V32.5714ZM36.8555 32.5711H33.4269V35.9995L36.8553 35.9997L36.8555 32.5711ZM38.5406 24.6244C37.7108 25.6775 36.3484 25.9304 35.2836 25.5346C34.2349 25.1447 33.4269 24.1208 33.4269 22.8773H40.284C40.284 19.3803 35.6976 17.1528 33.1544 20.3806L38.5406 24.6244ZM27.5759 37.2789L38.4382 24.7482L33.2568 20.2567L22.3945 32.7873L27.5759 37.2789ZM25.4247 32.5703C28.0638 32.5703 28.9864 35.4947 27.6782 37.155L22.2921 32.9112C20.3761 35.3429 21.8272 39.4275 25.4247 39.4275V32.5703ZM31.7131 32.5706L25.4248 32.5703L25.4245 39.4275L31.7127 39.4278L31.7131 32.5706ZM35.1415 45.693L35.1415 35.9992H28.2843L28.2843 45.693H35.1415ZM30.0278 43.9459C30.8576 42.8928 32.22 42.6399 33.2848 43.0357C34.3334 43.4256 35.1415 44.4495 35.1415 45.693H28.2843C28.2843 49.1901 32.8708 51.4174 35.4139 48.1898L30.0278 43.9459ZM40.9927 31.2914L30.1302 43.8221L35.3116 48.3136L46.174 35.783L40.9927 31.2914ZM43.1438 36C40.5047 36 39.5821 33.0756 40.8903 31.4153L46.2764 35.6591C48.1924 33.2274 46.7413 29.1428 43.1438 29.1428V36ZM36.8553 35.9997L43.1437 36L43.144 29.1428L36.8556 29.1425L36.8553 35.9997ZM33.4269 22.8773V32.5711H40.284V22.8773H33.4269Z"
          fill="#ECF9FF"
          mask="url(#path-9-outside-1_12592_291868)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.8555 22.8773C36.8555 22.3223 36.1855 22.0734 35.8475 22.5025L24.9852 35.0331C24.6813 35.4188 24.9455 35.9989 25.4247 35.9989L31.7129 35.9992L31.7129 45.693C31.7129 46.248 32.3828 46.4969 32.7209 46.0678L43.5833 33.5372C43.8873 33.1515 43.623 32.5714 43.1438 32.5714L36.8555 32.5711V22.8773Z"
          fill="#0968BF"
        />
      </g>
      <defs>
        <clipPath id="clip0_12592_291868">
          <rect width="48" height="48" fill="white" />
        </clipPath>
        <clipPath id="clip1_12592_291868">
          <rect
            x="1.28516"
            y="29.3564"
            width="7.71429"
            height="12.8571"
            rx="1.28571"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip2_12592_291868">
          <rect
            x="11.5703"
            y="20.7852"
            width="7.71429"
            height="21.4286"
            rx="1.28571"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip3_12592_291868">
          <rect
            x="21.8555"
            y="8.78516"
            width="7.71429"
            height="33.4286"
            rx="1.28571"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip4_12592_291868">
          <rect
            x="32.1406"
            y="1.07031"
            width="7.71429"
            height="41.1429"
            rx="1.28571"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

IconChartWithBadge.defaultProps = {
  w: 48,
  h: 48,
  // color: '#0D57F2',
};

export default IconChartWithBadge;
