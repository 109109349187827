import React from 'react';

type IconWarningCircleOutlineProps = { w?: number, h?: number, color?: string };

function IconWarningCircleOutline({ w, h, color }: IconWarningCircleOutlineProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.6C6.30262 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 8.84046 1.76554 9.67269 2.08717 10.4492C2.4088 11.2257 2.88022 11.9312 3.47452 12.5255C4.06881 13.1198 4.77434 13.5912 5.55083 13.9128C6.32731 14.2345 7.15954 14.4 8 14.4C8.84046 14.4 9.67269 14.2345 10.4492 13.9128C11.2257 13.5912 11.9312 13.1198 12.5255 12.5255C13.1198 11.9312 13.5912 11.2257 13.9128 10.4492C14.2345 9.67269 14.4 8.84046 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6ZM2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 9.05057 15.7931 10.0909 15.391 11.0615C14.989 12.0321 14.3997 12.914 13.6569 13.6569C12.914 14.3997 12.0321 14.989 11.0615 15.391C10.0909 15.7931 9.05058 16 8 16C6.94943 16 5.90914 15.7931 4.93853 15.391C3.96793 14.989 3.08601 14.3997 2.34315 13.6569C1.60028 12.914 1.011 12.0321 0.608964 11.0615C0.206926 10.0909 0 9.05058 0 8C4.76837e-08 5.87827 0.842855 3.84344 2.34315 2.34315ZM8 4C8.44183 4 8.8 4.35817 8.8 4.8V8C8.8 8.44183 8.44183 8.8 8 8.8C7.55817 8.8 7.2 8.44183 7.2 8V4.8C7.2 4.35817 7.55817 4 8 4ZM7.2 11.2C7.2 10.7582 7.55817 10.4 8 10.4H8.008C8.44983 10.4 8.808 10.7582 8.808 11.2C8.808 11.6418 8.44983 12 8.008 12H8C7.55817 12 7.2 11.6418 7.2 11.2Z"
        fill={color}
      />
    </svg>
  );
}

IconWarningCircleOutline.defaultProps = {
  w: 16,
  h: 16,
  color: 'currentColor',
};

export default IconWarningCircleOutline;
