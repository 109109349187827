import React from 'react';

function IconInternalBankTransfer({ w, h }: { w?: number, h?: number }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2459 31H8c-.55229 0-1-.4477-1-1V13.2345c0-.2557.09791-.5016.27361-.6873l7.36599-7.78507c.3925-.41486 1.0522-.41726 1.4477-.00527L26.3935 15.4916c.1788.1862.2786.4344.2786.6926v5.9613c0 .5522-.4477 1-1 1h-7.836c-.5523 0-1-.4478-1-1v-5.9613c0-.2582.0998-.5064.2786-.6926L27.4414 4.73553c.3877-.40389 1.0315-.41073 1.4278-.01519l7.8373 7.82306c.1879.1875.2935.4422.2935.7077v8.8944"
        stroke="#000"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M35 44c4.9706 0 9-4.0294 9-9s-4.0294-9-9-9-9 4.0294-9 9 4.0294 9 9 9Z"
        fill="#FEEB68"
      />
      <path
        d="M41.9434 34.9454c-.2939 2.7629-1.8585 5.352-4.4474 6.8476-4.3012 2.4848-9.8012 1.0102-12.2846-3.2936l-.2645-.4584m-.8909-4.9864c.2939-2.7629 1.8585-5.352 4.4474-6.8476 4.3013-2.4848 9.8013-1.0102 12.2846 3.2936l.2645.4584M24 40.4216l.7745-2.8922 2.8905.775m10.67-8.6088 2.8905.775L42 27.5784"
        stroke="#000619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5 36.3462c0-.2762-.2239-.5-.5-.5s-.5.2238-.5.5h1Zm5-3.6924c0 .2762.2239.5.5.5s.5-.2238.5-.5h-1ZM33.5 30c0-.2761-.2239-.5-.5-.5s-.5.2239-.5.5h1Zm-1 1.0385c0 .2761.2239.5.5.5s.5-.2239.5-.5h-1Zm1 6.923c0-.2761-.2239-.5-.5-.5s-.5.2239-.5.5h1ZM32.5 39c0 .2761.2239.5.5.5s.5-.2239.5-.5h-1Zm1.5-8.4615h-1.8571v1H34v-1ZM32.1429 35h1.7142v-1h-1.7142v1Zm1.7142 2.4615H32v1h1.8571v-1Zm-1.8571 0c-.9335 0-1.5-.5938-1.5-1.1153h-1c0 1.2627 1.2243 2.1153 2.5 2.1153v-1Zm3.5-1.2307c0 .5853-.6305 1.2307-1.6429 1.2307v1c1.3546 0 2.6429-.9043 2.6429-2.2307h-1ZM33.8571 35c1.0124 0 1.6429.6455 1.6429 1.2308h1C36.5 34.9043 35.2117 34 33.8571 34v1ZM29.5 32.7692C29.5 34.0957 30.7883 35 32.1429 35v-1c-1.0124 0-1.6429-.6455-1.6429-1.2308h-1Zm2.6429-2.2307c-1.3546 0-2.6429.9043-2.6429 2.2307h1c0-.5853.6305-1.2307 1.6429-1.2307v-1ZM36.5 32.6538c0-1.2627-1.2243-2.1153-2.5-2.1153v1c.9335 0 1.5.5938 1.5 1.1153h1ZM32.5 30v1.0385h1V30h-1Zm0 7.9615V39h1v-1.0385h-1Z"
        fill="#000619"
      />
    </svg>
  );
}

IconInternalBankTransfer.defaultProps = {
  w: 48,
  h: 48,
};

export default IconInternalBankTransfer;
