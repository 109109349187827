import React from 'react';

type IconEyeHideProps = {
  width?: Number,
  height?: Number,
  color?: string,
};

function IconEyeHide({ width = 24, height = 24, color = 'currentColor' }: IconEyeHideProps) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        fill={color}
        clipRule="evenodd"
        d="M2.293 2.293a1 1 0 011.414 0l3.041 3.04A10.953 10.953 0 0112.001 4c4.927 0 9.094 3.239 10.496 7.7a1 1 0 010 .601 11.025 11.025 0 01-3.552 5.23l2.762 2.762a1 1 0 11-1.414 1.414l-3.587-3.587h-.001l-3.291-3.29-4.243-4.244a1.095 1.095 0 01-.026-.027L2.293 3.707a1 1 0 010-1.414zm5.944 4.53l1.731 1.731a4 4 0 015.478 5.478l2.076 2.076a9.026 9.026 0 002.967-4.109A9.006 9.006 0 0012 6h-.001a8.953 8.953 0 00-3.762.823zm5.695 5.695a1.998 1.998 0 00-2.45-2.45l2.45 2.45zM4.624 8.174a1 1 0 01.193 1.4A8.971 8.971 0 003.511 12a9.006 9.006 0 0010.178 5.842 1 1 0 11.372 1.966c-.68.128-1.37.192-2.062.192-4.927 0-9.094-3.239-10.496-7.7a1 1 0 010-.6 10.97 10.97 0 011.72-3.333 1 1 0 011.4-.193z"
      />
    </svg>
  );
}

IconEyeHide.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};

export default IconEyeHide;
