// @flow
import React, { useState } from 'react';
import { Flex, Spacer } from '@chakra-ui/react';
import { BaselaneButtonToggle, BaselaneTable } from '@shared/components';
import PropertyListRow from './PropertyListRow';

type PropertyListProps = { properties: Array, hasNoData: boolean };

function PropertyList({ properties, hasNoData }: PropertyListProps): any {
  const [activeButton, setActiveButton] = useState(0);
  const overdueProperties = [];
  properties.forEach((p) => {
    return p.units.forEach((u) => {
      if (u.lease && u.lease.paymentState === 'OVERDUE') {
        overdueProperties.push(p);
      }
      return overdueProperties;
    });
  });
  const visibleProperties = activeButton === 0 ? properties : overdueProperties;
  const tableHeader = [
    { title: 'Property & Units', width: '250px' },
    { title: 'Occupancy Status' },
    { title: 'Rent Status' },
  ];
  const tableOverflow = { enabled: true, maxHeight: '250px' };
  const propertyTableRow = (item, customGrid) => {
    return <PropertyListRow key={item.id} property={item} columns={customGrid} />;
  };

  return (
    <>
      <Flex align="center" mb="16px">
        <Spacer />
        <BaselaneButtonToggle
          firstLabel="All"
          secondLabel="Overdue"
          activeButton={activeButton}
          onClick={setActiveButton}
          id="toggle_button"
          hasNoData={hasNoData}
        />
      </Flex>
      <BaselaneTable
        tableHeader={tableHeader}
        tableContent={visibleProperties}
        tableRow={propertyTableRow}
        rowWithoutPadding
        overflow={tableOverflow}
        hasNoData={hasNoData}
      />
    </>
  );
}

export default PropertyList;
