export const getBottomPartStyles = (isMinXL) => {
  return {
    container: {
      gap: 0,
    },
    timeStatus: {
      textStyle: 'xs',
      color: 'brand.neutral.600',
    },
    amount: {
      container: {
        m: '0 !important',
        color: 'brand.neutral.700',
        fontSize: isMinXL ? 'md' : '2xl',
        lineHeight: isMinXL ? '24px' : '32px',
        fontWeight: 'medium',
      },
      beforedecimal: {
        m: '0 !important',
      },
      afterdecimal: {
        fontSize: isMinXL ? 'xs' : 'md',
        lineHeight: isMinXL ? '20px' : '24px',
        m: '0 !important',
      },
    },
    numInvoice: {
      color: 'brand.neutral.700',
      textStyle: 'xs',
      display: 'flex',
      alignItems: 'center',
      m: '0 !important',
      gap: '8px',
    },
    rightArrowContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      h: '100%',
      m: '2px 0 0 0 !important ',
    },
  };
};

export const getPaymentSummaryStyles = (isMinXL) => {
  return {
    paymentSummaryContainerStyles: {
      gap: '8px',
      m: isMinXL ? '0 0 16px 0 !important' : '0 0 24px 0 !important',
    },
    titleStyles: {
      color: 'brand.neutral.700',
      textStyle: 'headline-lg',
      mb: '3px !important',
    },
    paymentItemContainerStyles: {
      display: 'grid',
      gridTemplateColumns: isMinXL ? '1fr 1fr' : '1fr 1fr 1fr 1fr',
      columnGap: '8px',
      rowGap: '8px',
      alignItems: 'stretch',
    },
    summaryItemStyles: {
      p: '16px',
      borderWidth: '1px',
      borderRadius: '6px',
      borderColor: 'brand.darkBlue.150',
      background: 'brand.neutral.white',
      w: '100%',
      minW: '100%',
      m: '0 !important',
      boxShadow: '1px 2px 6px rgba(3, 24, 64, 0.04)',
      cursor: 'pointer',
      _hover: {
        borderColor: 'brand.blue.800A',
        bg: 'brand.blue.50',
      },
      _focus: {
        borderColor: 'brand.blue.700',
        bg: 'brand.neutral.white',
      },
      _focusWithin: {
        borderColor: 'brand.blue.700',
        bg: 'brand.neutral.white',
      },
      _target: {
        borderColor: 'brand.blue.700',
        bg: 'brand.neutral.white',
      },
      _active: {
        borderColor: 'brand.blue.800A',
        bg: 'brand.blue.100',
      },
    },
    topPartStyles: {
      container: {
        flex: 1,
        gap: 0,
      },
      status: {
        color: 'brand.neutral.700',
        textStyle: 'xs',
        mt: '4px !important',
      },
    },
    bottomPartStyles: getBottomPartStyles(isMinXL),
    failedStyles: {
      container: {
        p: '8px 16px',
        background: 'red.200',
        borderColor: 'red.300',
        borderWidth: '1px',
        borderRadius: '6px',
        m: '0 !important',
        gap: 0,
        cursor: 'pointer',
        _hover: {
          borderColor: 'red.900',
        },
        _focus: {
          boxShadow: '0 0 0 2px #F4B0AF',
        },
        _active: {
          background: 'red.300',
          borderColor: 'red.900',
        },
      },
      iconContainer: {
        w: '24px',
        h: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      status: {
        textStyle: 'headline-sm',
        color: 'brand.neutral.700',
      },
      amount: {
        color: 'brand.neutral.700',
        textStyle: 'sm',
        m: isMinXL ? '0 0 0 8px !important' : '0 8px 0 24px !important',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      },
    },
  };
};
