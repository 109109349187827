import { gql } from '@apollo/client';

export const FRAGMENT_INSURANCE_ESTIMATE = gql`
  fragment InsuranceEstimate on Property {
    insurance @client {
      estimate
      addressWithEstimate
    }
  }
`;
