import React from 'react';

type IconBankTransferProps = {
  w?: Number,
  h?: Number,
  color?: Number,
};

function IconBankTransfer({ w, h, color }: IconBankTransferProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 84 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M75.1287 7.8875c.1968.00153.3913.04279.5718.1213.1808.07865.3436.19304.4788.33624l-1.0506-.45754Zm0 0c-.196.00133-.3896.04192-.5695.11937-.1803.07761-.343.19064-.4785.33232l-.0001.00005-4.4888 4.69056-.1444.1509-.1445-.1508-4.4951-4.69337c-.0002-.00011-.0003-.00022-.0004-.00033-.1365-.14105-.2999-.25343-.4806-.33051-.1805-.07693-.3745-.11712-.5707-.11818-.1971.00229-.3917.04421-.5721.12324-.1809.07922-.3438.19409-.479.33769 0 .00001 0 .00002-.0001.00003l-6.6897 7.10863-.0006.0006c-.2522.2658-.3929.6175-.3936.9833v14.9174c.0008.3833.154.7508.4261 1.0217.2721.271.6409.4232 1.0255.4232H81.822c.3846 0 .7534-.1522 1.0256-.4232.272-.2708.4252-.6382.426-1.0215V16.4412c-.002-.3658-.1427-.7174-.394-.984h-.0001l-6.7001-7.11204-1.0507-.45766ZM66.118 19.23v-.0804l.0556-.058 3.1293-3.2646.1444-.1507.1444.1507 3.1293 3.2646.0556.058v5.2173H66.118V19.23Zm-8.5691 11.8362h-.2V16.5451l.0545-.0578 6.217-6.59175.1444-.1532.1456.15219 4.2072 4.39916.1324.1384-.1326.1382-3.3275 3.4694-.0004.0004c-.259.2684-.4034.6265-.4029.9988v5.6067c0 .3839.1528.7522.425 1.0237.2722.2716.6414.4243 1.0266.4243h7.2148c.3851 0 .7544-.1527 1.0266-.4243.2722-.2715.425-.6398.425-1.0237v-5.5925c-.0002-.3725-.1446-.7305-.4031-.9993l-.0002-.0002-3.3275-3.4694-.1327-.1383.1325-.1384 4.2072-4.39567.1456-.15207.1444.15314 6.2169 6.5917.0545.0578V31.0662H57.5489Z"
        fill={color}
        stroke="#fff"
        strokeWidth=".4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9622 7.05897c-.177-.07863-.3789-.07863-.5558 0L.406394 11.8484c-.295288.1313-.4585018.4512-.3914208.7673.0670811.3161.3461658.5422.6693048.5422H26.6843c.3231 0 .6022-.2261.6693-.5422.0671-.3161-.0961-.636-.3914-.7673l-13-4.78943Zm-.2779 1.37398 10.46 3.35655H3.22421L13.6843 8.43295Z"
        fill={color}
      />
      <path
        d="M6.15796 17.2632c0-.3779-.30633-.6843-.68421-.6843s-.68421.3064-.68421.6843v12.3157c0 .3779.30633.6843.68421.6843s.68421-.3064.68421-.6843V17.2632ZM14.3685 17.2632c0-.3779-.3063-.6843-.6842-.6843s-.6842.3064-.6842.6843v12.3157c0 .3779.3063.6843.6842.6843s.6842-.3064.6842-.6843V17.2632ZM22.579 17.2632c0-.3779-.3063-.6843-.6842-.6843s-.6842.3064-.6842.6843v12.3157c0 .3779.3063.6843.6842.6843s.6842-.3064.6842-.6843V17.2632ZM9.57895 16.5789c.37788 0 .68425.3064.68425.6843v12.3157c0 .3779-.30637.6843-.68425.6843-.37788 0-.68421-.3064-.68421-.6843V17.2632c0-.3779.30633-.6843.68421-.6843ZM17.7895 16.5789c.3779 0 .6842.3064.6842.6843v12.3157c0 .3779-.3063.6843-.6842.6843s-.6842-.3064-.6842-.6843V17.2632c0-.3779.3063-.6843.6842-.6843Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.684278 13.8421c-.377879 0-.68421014.3063-.68421014.6842s.30633114.6842.68421014.6842v16.4211c-.377879 0-.68421014.3063-.68421014.6842S.306399 33 .684278 33H26.6843c.3779 0 .6842-.3063.6842-.6842s-.3063-.6842-.6842-.6842V15.2105c.3779 0 .6842-.3063.6842-.6842s-.3063-.6842-.6842-.6842H.684278ZM2.0527 31.6316V15.2105h23.2632v16.4211H2.0527Z"
        fill={color}
      />
      <path
        d="M37.2891 22.1679h7.8684m0 0-3.5351 3.5351m3.5351-3.5351-3.5351-3.5351"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconBankTransfer.defaultProps = {
  w: 84,
  h: 40,
  color: 'currentColor',
};

export default IconBankTransfer;
