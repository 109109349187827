import React, { Fragment } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Stack,
  Text,
} from '@chakra-ui/react';
import { IconChevronUp, IconChevronDown } from '@icons';
import BaselaneDivider from '../../BaselaneDivider';
import { faqStyles } from '../styles/faq.styles';

// Destructure Imported Styles
const {
  title,
  question,
  answer,
  wrapper,
  accordion,
  accordionbutton,
  accordioncontent,
  iconcontainer,
  divider,
} = faqStyles;

const faqListComponents = (faqContent) => {
  // FAQ Const
  const { title: titleText, questionsAndAnswers } = faqContent;

  const faqObject = {
    title: <Text {...title}>{titleText}</Text>,
  };

  questionsAndAnswers.forEach((faqItem, index) => {
    faqObject[`q${index + 1}`] = <Text {...question}>{faqItem.question}</Text>;
    faqObject[`a${index + 1}`] = (
      <Stack {...answer} gap="8px">
        {faqItem.answerItems.map((answerItem) => (
          <Text>{answerItem}</Text>
        ))}
      </Stack>
    );
  });

  return faqObject;
};

export const getFAQComponent = (faqContent) => {
  const { title: titleText, q1, q2, q3, q4, q5, a1, a2, a3, a4, a5 } = faqListComponents(
    faqContent
  );
  const pairs = [
    { key: '1', header: q1, content: a1 },
    { key: '2', header: q2, content: a2 },
    { key: '3', header: q3, content: a3 },
    { key: '4', header: q4, content: a4 },
    { key: '5', header: q5, content: a5 },
  ];

  return (
    <>
      {/* Title for Mobile  */}
      {titleText}

      {/* Questions & Answers */}
      <Accordion {...wrapper} allowMultiple>
        {pairs.map(
          (item) =>
            item.header && (
              <Fragment key={item.key}>
                <AccordionItem {...accordion}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton {...accordionbutton}>
                        {item.header}
                        <Box {...iconcontainer}>
                          {isExpanded ? (
                            <IconChevronUp color="#3A4B5B" />
                          ) : (
                            <IconChevronDown color="#3A4B5B" />
                          )}
                        </Box>
                      </AccordionButton>
                      <AccordionPanel {...accordioncontent}>{item.content}</AccordionPanel>
                      <BaselaneDivider styles={divider} />
                    </>
                  )}
                </AccordionItem>
              </Fragment>
            )
        )}
      </Accordion>
    </>
  );
};
