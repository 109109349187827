export const containerStyles = (isMobile) => {
  return {
    p: isMobile ? '16px' : '24px 16px',
    borderRadius: !isMobile && 'md',
    w: isMobile ? '100%' : '50%',
    borderColor: isMobile ? 'transparent' : 'brand.darkBlue.200',
    bg: 'brand.neutral.white',
    border: '1px solid #DADFE6',
    boxShadow: 'none',
    m: '0 !important',
  };
};
