import { gql } from '@apollo/client';

export const INITIATE_WIRE_TRANSFER = gql`
  mutation InitiateWireTransfer(
    $accountNumber: String!
    $amount: Float!
    $fromTransferAccountId: ID!
    $name: String!
    $propertyId: ID
    $recipientAddress: UnitAddressInput
    $routingNumber: String!
    $tagId: ID
    $unitId: ID
    $description: String
  ) {
    initiateWireTransfer(
      input: {
        accountNumber: $accountNumber
        amount: $amount
        fromTransferAccountId: $fromTransferAccountId
        name: $name
        propertyId: $propertyId
        recipientAddress: $recipientAddress
        routingNumber: $routingNumber
        tagId: $tagId
        unitId: $unitId
        description: $description
      }
    ) {
      id
      amount
      completedDate
      createdAt
      externalId
      type
      fromTransferAccount {
        accountName
        accountNumber
        amount
        id
        name
        userId
        institution {
          id
          name
          plaidInstitutionName
        }
      }
    }
  }
`;

export const INITIATE_CHECK_PAYMENT = gql`
  mutation initiateCheckPayment(
    $fromTransferAccountId: ID!
    $amount: Float!
    $memo: String
    $tagId: ID
    $propertyId: ID
    $unitId: ID
    $recipientName: String!
    $recipientEmail: String!
    $recipientAddress: UnitAddressInput!
  ) {
    initiateCheckPayment(
      input: {
        fromTransferAccountId: $fromTransferAccountId
        amount: $amount
        memo: $memo
        tagId: $tagId
        propertyId: $propertyId
        unitId: $unitId
        recipientName: $recipientName
        recipientEmail: $recipientEmail
        recipientAddress: $recipientAddress
      }
    ) {
      id
      amount
      completedDate
      createdAt
      externalId
      type
      fromTransferAccount {
        accountName
        accountNumber
        amount
        id
        name
        userId
        institution {
          id
          name
          plaidInstitutionName
        }
      }
    }
  }
`;
