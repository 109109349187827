import process from 'process';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { StatsigProvider } from 'statsig-react';
import LogRocket from 'logrocket';

import ServiceBell from '@servicebell/widget';

import { publicIpv4 } from 'public-ip';
import { latchelPartnerKey, serviceBellApiKey, statsigClientKey } from '@core/constants/envVars';
import UserContext from '@contexts/UserContext';
import { AnalyticsProvider } from '@contexts/AnalyticsContext';
import useUTMParams from '@shared/hooks/useUTMParams';
import { getStatsigEnviroment } from '../../helpers/statsig.helpers';
import SlLoader from '../../../Loader';
import ZendeskWrapper from './ZendeskWrapper';

/**
 * Note: The purpose of this component is to intialize any scripts or providers
 * that behave differently when the user is logged in.
 *
 * Current Scripts: Zendesk, Statsig and Reddit
 */

const InitializeWithUser = () => {
  const { loading, user } = useContext(UserContext);

  const { email, firstName, lastName, id: userId } = user || {};
  const displayName = `${firstName || ''} ${lastName || ''}`;

  const [ipAddress, setIpAddress] = useState(null);

  const {
    // eslint-disable-next-line camelcase
    utm_campaign,
    // eslint-disable-next-line camelcase
    utm_source,
    irclickid,
    partnernid,
  } = useUTMParams();

  // ensures the useEffect below only executes once
  const [initializedWithUser, setInitializedWithUser] = useState(false);

  useEffect(() => {
    if (user && !initializedWithUser) {
      LogRocket.identify(userId, {
        name: displayName,
        email,
        role: 'LANDLORD',
      });

      // OMEGA-685: Service Bell
      ServiceBell('init', serviceBellApiKey);
      window.addEventListener('sb:initialized', () => {
        ServiceBell('identify', userId, {
          displayName,
          email,
        });
      });

      /**
       * Reddit tracking initialization
       */
      if (window.rdt && process?.env?.REACT_APP_REDDIT_PIXEL_ID) {
        // reddit tracking with basic info
        window.rdt('init', process.env.REACT_APP_REDDIT_PIXEL_ID, {
          email,
          externalId: userId,
        });
      }

      setInitializedWithUser(true);
    }
  }, [user]);

  useEffect(() => {
    (async function getIP() {
      const newIpAddress = await publicIpv4();
      setIpAddress(newIpAddress);
    })();
  }, []);

  // Initialize Latchel SDK with key. Initializing here so it only happens one time.
  useEffect(() => {
    const nodeEnv = process?.env?.REACT_APP_NODE_ENV;
    const LatchelSandbox = 'https://staging-sdk.lvn-stage.com/demo/bundle.js';
    const LatchelDefault = 'https://sdk.latchel-cd.app/bundle.js';

    const script = document.createElement('script');
    script.src = nodeEnv === 'production' ? LatchelDefault : LatchelSandbox;
    script.type = 'text/javascript';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (window.LatchelPartnerSDK) {
      window.LatchelPartnerSDK.init(latchelPartnerKey);
    }
  }, [window.LatchelPartnerSDK]);

  return loading ? (
    <SlLoader />
  ) : (
    <ZendeskWrapper user={user}>
      <StatsigProvider
        sdkKey={statsigClientKey}
        // waitForInitialization
        user={{
          userID: userId,
          userAgent: navigator.userAgent,
          ip: ipAddress,
          custom: {
            // eslint-disable-next-line camelcase
            utm_source,
            // eslint-disable-next-line camelcase
            utm_campaign,
            irclickid,
            partnernid,
          },
        }}
        options={{
          environment: { tier: getStatsigEnviroment() },
        }}
      >
        <AnalyticsProvider>
          <Outlet />
        </AnalyticsProvider>
      </StatsigProvider>
    </ZendeskWrapper>
  );
};

export default InitializeWithUser;
