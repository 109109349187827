import React from 'react';
import { MenuButton, Text, HStack, Avatar } from '@chakra-ui/react';
import { Icon16ChevronDown, Icon16ChevronUp, Icon16Person } from '@icons/16px';
import BaselaneButton from '../BaselaneButton';
import { menuStyles } from './menu.styles';

const BaselaneMenuProfileButton = ({
  isOpen,
  profilePhotoUrl,
  label,
}: {
  isOpen: Boolean,
  profilePhotoUrl: String,
  label: String,
}) => {
  const { profileButton, username } = menuStyles ?? {};
  return (
    <MenuButton
      className={`profiles-button ${isOpen ? 'open' : 'closed'}`}
      isActive={isOpen}
      as={BaselaneButton}
      size="md"
      variant="outline"
      palette="neutral"
      rightIcon={isOpen ? <Icon16ChevronUp /> : <Icon16ChevronDown />}
      styles={profileButton}
      onMouseDown={() => document.querySelector('.profiles-button')?.classList?.add('pressed')} // Override active state to apply pressed styles while the button is active
    >
      <HStack>
        <Avatar
          size="sm"
          src={profilePhotoUrl}
          bg="brand.neutral.200"
          icon={<Icon16Person color="#6C7884" />}
        />
        <Text {...username}>{label}</Text>
      </HStack>
    </MenuButton>
  );
};

export default BaselaneMenuProfileButton;
