import { React } from 'react';
import { Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from '@chakra-ui/react';
import RenderWarningPage from '@core/components/RenderWarningPage';
import * as ROUTES from '@routes';

export const onboardingRoutes = (currLocation) =>
  !currLocation?.pathname.includes(ROUTES.LOGIN) &&
  !currLocation?.pathname.includes(ROUTES.SIGN_UP) &&
  !currLocation?.pathname.includes(ROUTES.VERIFIED) &&
  !currLocation?.pathname.includes(ROUTES.UNVERIFIED) &&
  !currLocation?.pathname.includes(ROUTES.PHONE) &&
  !currLocation?.pathname.includes(ROUTES.ONBOARDING_GETTING_STARTED) &&
  !currLocation?.pathname.includes(ROUTES.ONBOARDING) &&
  !currLocation?.pathname.includes(ROUTES.RESET_PASSWORD);

export const unifiedFlowRoutes = (currLocation) =>
  !currLocation?.pathname.includes(ROUTES.UNIFIED_RENT_COLLECTION) &&
  !currLocation?.pathname.includes(ROUTES.UNIFIED_RENT_COLLECTION_SUCCESS) &&
  !currLocation?.pathname.includes(ROUTES.UNIFIED_LANDLORD_BANKING) &&
  !currLocation?.pathname.includes(ROUTES.UNIFIED_LANDLORD_BANKING_SUCCESS) &&
  !currLocation?.pathname.includes(ROUTES.UNIFIED_BA_AUTOTAG) &&
  !currLocation?.pathname.includes(ROUTES.UNIFIED_BOOKKEEPING_ANALYTICS) &&
  !currLocation?.pathname.includes(ROUTES.UNIFIED_BA_ALL_SET) &&
  !currLocation?.pathname.includes(ROUTES.UNIFIED_BA_SUCCESS) &&
  !currLocation?.pathname.includes(ROUTES.FORGOT_PASSWORD);

export const notAResponsiveRoute = (currLocation) =>
  !currLocation?.pathname.includes(ROUTES.TRANSACTIONS) &&
  !currLocation?.pathname.includes(ROUTES.REFERRAL) &&
  !currLocation?.pathname.includes(ROUTES.LEASES);

export const showWarningInMobile = (currLocation) =>
  isMobile &&
  (currLocation?.pathname.includes(ROUTES.ANALYTICS_REPORTING) ||
    currLocation?.pathname.includes(ROUTES.CASH_FLOW_STATEMENT) ||
    currLocation?.pathname.includes(ROUTES.CASH_FLOW_TAX_PACKAGE) ||
    currLocation?.pathname.includes(ROUTES.TENANTS) ||
    currLocation?.pathname.includes(ROUTES.KPIS));

export const showWarningInDesktop = (currLocation) => {
  const [isDesktop] = useMediaQuery('(min-width: 899px)', { ssr: false });

  return (
    !isDesktop &&
    !isMobile &&
    notAResponsiveRoute(currLocation) &&
    onboardingRoutes(currLocation) &&
    unifiedFlowRoutes(currLocation) &&
    !currLocation?.pathname.includes(ROUTES.GET_STARTED) &&
    !currLocation?.pathname.includes('/banking') &&
    !currLocation?.pathname.includes(ROUTES.EXTERNAL_ACCOUNTS) &&
    currLocation?.pathname !== ROUTES.HOME
  );
};

// Some pages are not ready for mobile. If it is not ready we show this warning message UI.
export const RenderWarningMessage = () => {
  return (
    <Routes>
      <Route path="*" element={<RenderWarningPage />} />
    </Routes>
  );
};
