import { v4 as uuidv4 } from 'uuid';

export const staticLeasesData = [
  {
    id: uuidv4(),
    archived: false,
    isDummyData: true,
    cancellationNotice: null,
    completedTags: null,
    depositBankAccountId: null,
    endDate: '2023-10-31',
    latefee: 200,
    leaseType: 'FIXED',
    newLease: false,
    paymentState: 'CURRENT',
    propertyName: 'San Bernardino Duplex',
    propertyAddress: '1900 Simond Ave',
    propertyUnitName: 'San Bernardino Duplex',
    propertyUnitId: uuidv4(),
    rentAndFeesBankAccountId: '7513',
    rentCollectionStartDate: '2022-11-30',
    startDate: '2022-10-30',
    state: 'SCHEDULED',
    tenantAutoPayEnrolled: null,
    tenantProfileId: uuidv4(),
    totalOverdueAmount: 500.01,
    payments: [],
    tenantProfileMetadata: {
      email: 'johnsmith@tenants.com',
      firstName: 'John',
      lastName: 'Smith',
      phoneNumber: '',
    },
  },
  {
    id: uuidv4(),
    archived: false,
    isDummyData: true,
    cancellationNotice: null,
    completedTags: null,
    depositBankAccountId: null,
    endDate: '2023-07-15',
    latefee: 200,
    leaseType: 'FIXED',
    newLease: false,
    paymentState: 'CURRENT',
    propertyUnitId: uuidv4(),
    propertyName: 'Los Angeles Apartment',
    propertyAddress: '238 Mayfield Ave',
    propertyUnitName: 'Unit A',
    rentAndFeesBankAccountId: '7513',
    rentCollectionStartDate: '2022-07-15',
    startDate: '2022-07-15',
    state: 'SCHEDULED',
    tenantAutoPayEnrolled: null,
    tenantProfileId: uuidv4(),
    totalOverdueAmount: 500.01,
    payments: [],
    tenantProfileMetadata: {
      email: 'johnsmith@tenants.com',
      firstName: 'John',
      lastName: 'Smith',
      phoneNumber: '',
    },
  },
  {
    id: uuidv4(),
    archived: false,
    isDummyData: true,
    cancellationNotice: null,
    completedTags: null,
    depositBankAccountId: null,
    endDate: '2023-05-31',
    latefee: 200,
    leaseType: 'FIXED',
    newLease: false,
    paymentState: 'CURRENT',
    propertyName: 'Los Angeles Apartment',
    propertyAddress: '238 Mayfield Ave',
    propertyUnitName: 'Unit B',
    propertyUnitId: uuidv4(),
    rentAndFeesBankAccountId: '7513',
    rentCollectionStartDate: '2022-05-31',
    startDate: '2022-05-31',
    state: 'SCHEDULED',
    tenantAutoPayEnrolled: null,
    tenantProfileId: uuidv4(),
    totalOverdueAmount: 500.01,
    payments: [],
    tenantProfileMetadata: {
      email: 'johnsmith@tenants.com',
      firstName: 'John',
      lastName: 'Smith',
      phoneNumber: '',
    },
  },
  {
    id: uuidv4(),
    archived: false,
    isDummyData: true,
    cancellationNotice: null,
    completedTags: null,
    depositBankAccountId: null,
    endDate: null,
    latefee: null,
    leaseType: 'FIXED',
    newLease: null,
    paymentState: null,
    propertyName: 'Los Angeles Apartment',
    propertyAddress: '238 Mayfield Ave',
    propertyUnitName: 'Unit C',
    propertyUnitId: uuidv4(),
    rentAndFeesBankAccountId: '7513',
    rentCollectionStartDate: null,
    startDate: null,
    state: null,
    tenantAutoPayEnrolled: null,
    tenantProfileId: uuidv4(),
    totalOverdueAmount: null,
    payments: [],
    tenantProfileMetadata: null,
  },
  {
    id: uuidv4(),
    archived: false,
    isDummyData: true,
    cancellationNotice: null,
    completedTags: null,
    depositBankAccountId: null,
    endDate: '2023-01-31',
    latefee: 200,
    leaseType: 'FIXED',
    newLease: false,
    paymentState: 'CURRENT',
    propertyName: 'West Village Townhome',
    propertyAddress: '140 Spirit Drive',
    propertyUnitName: '101',
    propertyUnitId: uuidv4(),
    rentAndFeesBankAccountId: '7513',
    rentCollectionStartDate: '2022-01-31',
    startDate: '2022-01-31',
    state: 'SCHEDULED',
    tenantAutoPayEnrolled: null,
    tenantProfileId: uuidv4(),
    totalOverdueAmount: 500.01,
    payments: [],
    tenantProfileMetadata: {
      email: 'johnsmith@tenants.com',
      firstName: 'John',
      lastName: 'Smith',
      phoneNumber: '',
    },
  },
  {
    id: uuidv4(),
    archived: false,
    isDummyData: true,
    cancellationNotice: null,
    completedTags: null,
    depositBankAccountId: null,
    endDate: '2023-12-31',
    latefee: 200,
    leaseType: 'FIXED',
    newLease: false,
    paymentState: 'CURRENT',
    propertyName: 'West Village Townhome',
    propertyAddress: '140 Spirit Drive',
    propertyUnitName: '102',
    propertyUnitId: uuidv4(),
    rentAndFeesBankAccountId: '7513',
    rentCollectionStartDate: '2022-12-31',
    startDate: '2022-12-31',
    state: 'SCHEDULED',
    tenantAutoPayEnrolled: null,
    tenantProfileId: uuidv4(),
    totalOverdueAmount: 500.01,
    payments: [],
    tenantProfileMetadata: {
      email: 'johnsmith@tenants.com',
      firstName: 'John',
      lastName: 'Smith',
      phoneNumber: '',
    },
  },
  {
    id: uuidv4(),
    archived: false,
    isDummyData: true,
    cancellationNotice: null,
    completedTags: null,
    depositBankAccountId: null,
    endDate: null,
    latefee: null,
    leaseType: 'FIXED',
    newLease: null,
    paymentState: null,
    propertyName: 'West Village Townhome',
    propertyAddress: '140 Spirit Drive',
    propertyUnitName: '201',
    propertyUnitId: uuidv4(),
    rentAndFeesBankAccountId: '7513',
    rentCollectionStartDate: null,
    startDate: null,
    state: null,
    tenantAutoPayEnrolled: null,
    tenantProfileId: uuidv4(),
    totalOverdueAmount: null,
    payments: [],
    tenantProfileMetadata: null,
  },
  {
    id: uuidv4(),
    archived: false,
    isDummyData: true,
    cancellationNotice: null,
    completedTags: null,
    depositBankAccountId: null,
    endDate: '2023-04-30',
    latefee: 200,
    leaseType: 'FIXED',
    newLease: false,
    paymentState: 'OVERDUE',
    propertyName: 'East Village Townhome',
    propertyAddress: '20 Spirit Drive',
    propertyUnitName: '31',
    propertyUnitId: uuidv4(),
    rentAndFeesBankAccountId: '7513',
    rentCollectionStartDate: '2022-04-30',
    startDate: '2022-04-30',
    state: 'SCHEDULED',
    tenantAutoPayEnrolled: null,
    tenantProfileId: uuidv4(),
    totalOverdueAmount: 500.01,
    payments: [],
    tenantProfileMetadata: {
      email: 'janesmith@tenants.com',
      firstName: 'Jane',
      lastName: 'Smith',
      phoneNumber: '',
    },
  },
  {
    id: uuidv4(),
    archived: false,
    isDummyData: true,
    cancellationNotice: null,
    completedTags: null,
    depositBankAccountId: null,
    endDate: '2023-06-30',
    latefee: 200,
    leaseType: 'FIXED',
    newLease: false,
    paymentState: 'CURRENT',
    propertyName: 'East Village Townhome',
    propertyAddress: '20 Spirit Drive',
    propertyUnitName: '31',
    propertyUnitId: uuidv4(),
    rentAndFeesBankAccountId: '7513',
    rentCollectionStartDate: '2022-06-30',
    startDate: '2022-06-30',
    state: 'SCHEDULED',
    tenantAutoPayEnrolled: null,
    tenantProfileId: uuidv4(),
    totalOverdueAmount: 500.01,
    payments: [],
    tenantProfileMetadata: {
      email: 'janesmith@tenants.com',
      firstName: 'Jane',
      lastName: 'Smith',
      phoneNumber: '',
    },
  },
  {
    id: uuidv4(),
    archived: false,
    isDummyData: true,
    cancellationNotice: null,
    completedTags: null,
    depositBankAccountId: null,
    endDate: '2023-06-28',
    latefee: 200,
    leaseType: 'FIXED',
    newLease: false,
    paymentState: 'OVERDUE',
    propertyName: 'East Village Townhome',
    propertyAddress: '20 Spirit Drive',
    propertyUnitName: '31',
    propertyUnitId: uuidv4(),
    rentAndFeesBankAccountId: '7513',
    rentCollectionStartDate: '2022-06-28',
    startDate: '2022-06-28',
    state: 'SCHEDULED',
    tenantAutoPayEnrolled: null,
    tenantProfileId: uuidv4(),
    totalOverdueAmount: 500.01,
    payments: [],
    tenantProfileMetadata: {
      email: 'janesmith@tenants.com',
      firstName: 'Jane',
      lastName: 'Smith',
      phoneNumber: '',
    },
  },
  {
    id: uuidv4(),
    archived: false,
    isDummyData: true,
    cancellationNotice: null,
    completedTags: null,
    depositBankAccountId: null,
    endDate: '2023-09-30',
    latefee: 200,
    leaseType: 'FIXED',
    newLease: false,
    paymentState: 'OVERDUE',
    propertyName: 'East Village Townhome',
    propertyAddress: '20 Spirit Drive',
    propertyUnitName: '31',
    propertyUnitId: uuidv4(),
    rentAndFeesBankAccountId: '7513',
    rentCollectionStartDate: '2022-09-30',
    startDate: '2022-09-30',
    state: 'SCHEDULED',
    tenantAutoPayEnrolled: null,
    tenantProfileId: uuidv4(),
    totalOverdueAmount: 500.01,
    payments: [],
    tenantProfileMetadata: {
      email: 'janesmith@tenants.com',
      firstName: 'Jane',
      lastName: 'Smith',
      phoneNumber: '',
    },
  },
];

export const staticPaymentSummaryData = {
  invoiceCompletedSummary: {
    amount: 8000,
    count: 4,
  },
  invoiceFailedSummary: {
    amount: 0,
    count: 0,
  },
  invoiceOutstandingSummary: {
    amount: 6000,
    count: 3,
  },
  invoiceOverdueSummary: {
    amount: 6000,
    count: 3,
  },
  invoiceProcessingSummary: {
    amount: 8000,
    count: 4,
  },
};
