export const stackStyles = {
  display: 'flex',
  flexDirection: 'column',
  width: '33%',
  minWidth: ' 248px',
  height: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: 'auto' },
  mx: 'auto',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const loginPageContainerStyles = {
  minHeight: '100%',
  bg: 'brand.neutral.white',
  p: '48px',
};
