import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { SignJWT } from 'jose';
import { LOGIN, SIGN_UP } from '@core/constants/routes';
import getBreakPoints from '@core/utils/getBreakPoints';
import { zendeskUserKey } from '@core/constants/envVars';

/**
 * Authenticates zendesk client with user data
 * @param {*} user Baselane user data
 * @returns zendesk token
 */
const authenticateZenDesk = async (user) => {
  const payload = {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    email_verified: true,
    scope: 'user',
    external_id: user.id,
  };
  const encryptedZendeskKey = new TextEncoder().encode(zendeskUserKey);
  return new SignJWT(payload) // details to encode in the token
    .setProtectedHeader({
      alg: 'HS256',
      typ: 'JWT',
      kid: 'app_664fa7a8edd6bd94be567e7f',
    }) // algorithm
    .sign(encryptedZendeskKey); // secretKey generated from previous step
};

export const ZendeskContext = createContext();

/**
 * custom use hook for accessing the zendesk API
 * @returns
 */
export const useZendeskAPI = () => {
  const zendeskAPI = useContext(ZendeskContext);
  return zendeskAPI;
};

type ZendeskProviderProps = {
  user?: Object,
};

export const ZendeskProvider = ({
  user = null,
  children,
}: PropsWithChildren<ZendeskProviderProps>) => {
  const { isMax768: isMobile } = getBreakPoints();
  const [isZendeskLoggedIn, setIsZendeskLoggedIn] = useState(false);
  const [zendeskToken, setZendeskToken] = useState(null);
  const location = useLocation();

  // Note: Exceptions for messenger bubble
  // Mobile hides the bubble on all routes except the following
  const alwaysShowOnMobileRoutes = [LOGIN];
  const isAlwaysShowOnMobile = alwaysShowOnMobileRoutes.includes(location.pathname);
  // Desktop shows the bubble on all routes except the following
  const alwaysHideOnDesktopRoutes = [SIGN_UP];
  const isAlwaysHideOnDesktop = alwaysHideOnDesktopRoutes.includes(location.pathname);

  const hideMessengerLauncher =
    (!isAlwaysShowOnMobile && isMobile) || (isAlwaysHideOnDesktop && !isMobile);

  const hideZendeskMessenger = () => {
    zendeskAPI('messenger', 'hide');
    zendeskAPI('messenger:on', 'open', () => {
      zendeskAPI('messenger', 'show');
    });
    zendeskAPI('messenger:on', 'close', () => {
      if (!hideMessengerLauncher) {
        zendeskAPI('messenger', 'show');
      } else {
        zendeskAPI('messenger', 'hide');
      }
    });
  };

  // https://developer.zendesk.com/api-reference/widget-messaging/web/core/
  const zendeskAPI = useMemo(() => {
    if (window?.zE) {
      return (...args) => {
        window.zE(...args);
      };
    }

    return () => {
      console.warn('Zendesk is not initialized yet.');
    };
  }, [window.zE]);

  useEffect(() => {
    if (window.zE && !isZendeskLoggedIn && user?.id && !zendeskToken) {
      setZendeskToken(authenticateZenDesk(user));
    }
  }, [window.zE, user]);

  useEffect(() => {
    if (zendeskToken && !isZendeskLoggedIn) {
      zendeskAPI('messenger', 'loginUser', (callback) => {
        callback(zendeskToken);
        setIsZendeskLoggedIn(true);
      });
    }
  }, [zendeskToken]);

  useEffect(() => {
    if (hideMessengerLauncher) {
      hideZendeskMessenger();
    } else {
      zendeskAPI('messenger', 'show');
      zendeskAPI('messenger:on', 'close', () => {
        zendeskAPI('messenger', 'show');
      });
    }
  }, [hideMessengerLauncher]);

  return <ZendeskContext.Provider value={zendeskAPI}>{children}</ZendeskContext.Provider>;
};
