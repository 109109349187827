const storageKey = 'baselane-state';

export default {
  write: (state) => {
    const stringifyState = JSON.stringify(state);
    localStorage.setItem(storageKey, stringifyState);
  },
  read: () => {
    const data = localStorage.getItem(storageKey) || null;
    return data && data !== 'null' && data !== '' ? JSON.parse(data) : null;
  },
  delete: () => localStorage.removeItem(storageKey),
};
