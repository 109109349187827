// Specific styles for A/B test variants
// For default/Control sign up view, see ./signup.styles.js

export const signupTestsHeadingStyles = {
  color: 'white',
  w: { base: '100%', xl: '480px' },
  fontWeight: '500',
  mb: '40px',
  mt: 0,
  // not within our DS system
  fontSize: '4xl',
  lineHeight: '36px',
};

export const signupTestsPrimaryColumnStyles = (singleColumn = false) => ({
  h: '100%',
  maxW: singleColumn ? { base: '100%', xl: '560px' } : { base: '100%', xl: '480px' },
  w: '100%',
  boxSizing: 'border-box',
});

export const signupTestsSecondaryColumnStyles = {
  ...signupTestsPrimaryColumnStyles,
  px: '0px',
  pb: '0px',
  m: { base: '0', xl: '0 0 0 8px' },
  alignItems: { base: 'flex-start', xl: 'flex-end' },
  w: { base: '100%', xl: '540px' },
  maxW: { base: '100%', xl: '50%' },
  gap: '40px',
};

export const signUpTestsFooterNote = {
  textStyle: 'xs',
  color: 'brand.darkBlue.400',
  alignItems: 'flex-start',
};

export const signinTestsLinkTextStyles = {
  color: 'white',
  textStyle: 'md',
  textDecoration: 'underline',
  ml: '0',
};

export const signUpTestsHeaderStyles = {
  m: '0',
  alignSelf: 'center',
  textStyle: 'md',
  color: 'white',
};

export const signupTestsHeaderContainerStyles = {
  py: {
    base: '12px',
    lg: '16px',
  },
  px: {
    base: '16px',
    md: '32px',
  },
  alignItems: 'center',
  w: '100%',
  maxW: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderColor: 'marketing.midnight.90',
  borderBottom: '1px solid',
};
