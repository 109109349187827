// @flow
import React, { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import AddPropertyFormLayout from '@pages/PropertiesPage/AddPropertyFormLayout';
import { detailsContainerStyles, drawerMenuContentTitleStyle } from '../../../styles/drawer.style';

type DetailsProps = {
  setFormVariables: Function,
  setIsValid: Function,
  setDetailsComplete: Function,
  formRef: any,
  setIsDirty: Function,
  isDirty: boolean,
  property: Object,
  initialValues: Object,
  shortForm?: Boolean,
};

function Details({
  setFormVariables,
  setIsValid,
  setDetailsComplete,
  formRef,
  setIsDirty,
  isDirty,
  property,
  initialValues,
  shortForm,
}: DetailsProps): any {
  const [hasEnteredAddress, setHasEnteredAddress] = useState(false);

  return (
    <>
      <Text {...drawerMenuContentTitleStyle}>
        {shortForm ? "What's the address of the rental property?" : 'Property Details'}
      </Text>

      <Box {...detailsContainerStyles}>
        <AddPropertyFormLayout
          {...{
            shortForm,
            setFormVariables,
            setIsValid,
            setDetailsComplete,
            formRef,
            setIsDirty,
            isDirty,
            property,
            initialValues,
            hasEnteredAddress,
            setHasEnteredAddress,
          }}
        />
      </Box>
    </>
  );
}

Details.defaultProps = {
  shortForm: false,
};

export default Details;
