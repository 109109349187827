import { isMobile } from 'react-device-detect';
import { fieldContainer } from '@pages/PropertiesPage/styles/form.style';

export const fieldsStyles = ({
  hasEnteredAddress,
  shortForm,
  isAddProperty,
  multiUnitPt,
  multiUnitMt,
}) => {
  const hideField = {
    ...(shortForm && !isAddProperty
      ? {
          height: '0',
          visibility: 'hidden',
        }
      : {}),
  };

  return {
    addressFields: {
      height: hasEnteredAddress ? 'auto' : '0px',
      minHeight: hasEnteredAddress ? 'auto' : '0px',
      visibility: hasEnteredAddress ? 'visible' : 'hidden',
      opacity: hasEnteredAddress ? '1' : '0',
      transition: 'visibility 0s, opacity 0.5s linear',
      marginTop: '0px !important',
      width: '100%',
    },
    cspContainer: {
      direction: 'row',
      spacing: isMobile ? '8px' : '24px',
      display: hasEnteredAddress || !shortForm ? 'flex' : 'none',
    },
    cFormField: {
      position: 'relative',
      w: '50%',
    },

    nickname: {
      height: hasEnteredAddress ? '110px' : '0px',
      minHeight: hasEnteredAddress ? '110px' : '0px',
      visibility: hasEnteredAddress ? 'visible' : 'hidden',
      opacity: hasEnteredAddress ? '1' : '0',
      transition: 'visibility 0s, opacity 0.5s linear',
    },
    unitsToggle: {
      pt: hasEnteredAddress ? multiUnitPt : '0',
      mt: multiUnitMt,
      height: hasEnteredAddress ? 'initial' : '0px',
      visibility: hasEnteredAddress ? 'visible' : 'hidden',
      opacity: hasEnteredAddress ? '1' : '0',
      transition: 'visibility 0s, opacity 0.5s linear',
      ...hideField,
    },
  };
};

export const dynamicFieldContainer = (isAddProperty, minHeight = '90px') => ({
  ...fieldContainer,
  minHeight: isAddProperty ? minHeight : '110px',
});
