import process from 'process';
import React, { useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { StatsigProvider } from 'statsig-react';
import { publicIpv4 } from 'public-ip';
import { statsigClientKey } from '@core/constants/envVars';
import useUTMParams from '@shared/hooks/useUTMParams';
import { getStatsigEnviroment, getCustomEmailId } from '../../helpers/statsig.helpers';
import ZendeskWrapper from './ZendeskWrapper';

/**
 * Note: The purpose of this component is to intialize any scripts or providers
 * that behave differently when the user is not logged in.
 *
 * Current Scripts: Zendesk, Statsig and Reddit
 */

const InitializeAnonymously = () => {
  const [searchParams] = useSearchParams();

  const [ipAddress, setIpAddress] = useState(null);
  const [customId, setCustomId] = useState(null);

  const {
    // eslint-disable-next-line camelcase
    utm_campaign,
    // eslint-disable-next-line camelcase
    utm_source,
    irclickid,
    partnernid,
  } = useUTMParams();

  useEffect(() => {
    (async function getIpAddress() {
      const newIpAddress = await publicIpv4();
      setIpAddress(newIpAddress);
    })();
    /**
     * Reddit tracking initialization (anonymous)
     */
    if (window.rdt && process?.env?.REACT_APP_REDDIT_PIXEL_ID) {
      window.rdt('init', process.env.REACT_APP_REDDIT_PIXEL_ID);
    }
    getCustomEmailId(searchParams).then((res) => {
      setCustomId(res);
    });
  }, []);

  return (
    <ZendeskWrapper>
      <StatsigProvider
        sdkKey={statsigClientKey}
        waitForInitialization
        user={{
          userAgent: navigator.userAgent,
          ip: ipAddress,
          custom: {
            // eslint-disable-next-line camelcase
            utm_source,
            // eslint-disable-next-line camelcase
            utm_campaign,
            irclickid,
            partnernid,
          },
          ...(customId ? { customIDs: { 'Custom User ID': customId } } : {}),
        }}
        options={{
          environment: { tier: getStatsigEnviroment() },
        }}
      >
        <Outlet />
      </StatsigProvider>
    </ZendeskWrapper>
  );
};

InitializeAnonymously.defaultProps = {
  userId: null,
};

export default InitializeAnonymously;
