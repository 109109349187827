import React from 'react';

function IconFile({
  w = 12,
  h = 14,
  color = 'currentColor',
}: {
  w?: Number,
  h?: Number,
  color?: String,
}) {
  return (
    <svg
      fill="none"
      width={`${w}px`}
      height={`${h}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66797 1.66683c-.17681 0-.34638.07024-.47141.19526-.12502.12503-.19526.2946-.19526.47141v9.3333c0 .1768.07024.3464.19526.4714.12503.1251.2946.1953.47141.1953h6.66666c.17682 0 .34638-.0702.47141-.1953.12502-.125.19526-.2946.19526-.4714V5.27631L6.3919 1.6669l-3.72393-.00007ZM1.25376.919282C1.62883.54421 2.13754.333496 2.66797.333496h3.724c.35356.000076.69275.140574.94274.390596m0 0L10.944 4.33335c0 .00003-.0001-.00002 0 0 .25.24999.3906.58911.3906.94267v6.39078c0 .5305-.2107 1.0392-.5858 1.4142-.375.3751-.88373.5858-1.41417.5858H2.66797c-.53043 0-1.03914-.2107-1.41421-.5858-.375078-.375-.585791-.8837-.585791-1.4142V2.3335c0-.53044.210713-1.03914.585791-1.414218M3.33464 7.00016c0-.36819.29847-.66666.66666-.66666h4c.36819 0 .66667.29847.66667.66666s-.29848.66667-.66667.66667h-4c-.36819 0-.66666-.29848-.66666-.66667Zm0 2.66667c0-.36819.29847-.66667.66666-.66667h4c.36819 0 .66667.29848.66667.66667 0 .36817-.29848.66667-.66667.66667h-4c-.36819 0-.66666-.2985-.66666-.66667Z"
        fill={color}
      />
    </svg>
  );
}

IconFile.defaultProps = {
  w: 12,
  h: 14,
  color: 'currentColor',
};

export default IconFile;
