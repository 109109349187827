import { PayeePaymentMethodKey, PayeePaymentMethodType } from './constants.helpers';

export const lastFourDigits = (account) => (account ? account.substring(account.length - 4) : null);

export const generateDescription = (bankName, accountType, accountNumber) => {
  let description = null;
  if (bankName) {
    description = `${bankName} - ${lastFourDigits(accountNumber)}`;
  } else if (accountType && accountType !== "I'm not sure") {
    description = `${accountType} - ${lastFourDigits(accountNumber)}`;
  } else if (accountNumber) {
    description = `Account - ${lastFourDigits(accountNumber)}`;
  }

  return description;
};

/**
 * Reformats queried payment method data into a structure that matches the forms associated with it
 * @param {Object} paymentMethods The object representing the defined payment methods in query format.
 * @returns the same data but in a form-compatible format
 */
export const restructureForForm = (paymentMethods) => {
  const cleanedUpCompletePaymentMethods = {};
  Object.keys(paymentMethods).forEach((paymentMethodKey) => {
    const paymentMethod = paymentMethods[paymentMethodKey];
    if (!paymentMethod) {
      cleanedUpCompletePaymentMethods[paymentMethodKey] = {};
      return;
    }
    cleanedUpCompletePaymentMethods[paymentMethodKey] = {
      id: paymentMethod?.id,
      isDeletable: paymentMethod?.isDeletable,
      // WIRE_TRANSFER & ACH specific fields
      ...((paymentMethodKey === PayeePaymentMethodKey.WIRE_TRANSFER ||
        paymentMethodKey === PayeePaymentMethodKey.ACH) && {
        accountHolderName: paymentMethod?.accountHolderName,
        routingNumber: paymentMethod?.routingNumber,
        accountNumber: paymentMethod?.accountNumber,
      }),
      // ACH specific fields
      ...(paymentMethodKey === PayeePaymentMethodKey.ACH && {
        accountType: paymentMethod?.accountType,
      }),
      // CHECK_PAYMENT specific fields
      ...(paymentMethodKey === PayeePaymentMethodKey.CHECK_PAYMENT && {
        name: paymentMethod?.name,
      }),
      // WIRE_TRANSFER & CHECK_PAYMENT specific fields
      ...((paymentMethodKey === PayeePaymentMethodKey.WIRE_TRANSFER ||
        paymentMethodKey === PayeePaymentMethodKey.CHECK_PAYMENT) && {
        ...{
          address: paymentMethod?.address?.street,
          unit: paymentMethod?.address?.unit,
          city: paymentMethod?.address?.city,
          zipcode: paymentMethod?.address?.postalCode,
          state: paymentMethod?.address?.state,
        },
      }),
    };
  });
  return cleanedUpCompletePaymentMethods;
};

/**
 * Reformats payment method form data into a structure that matches the queries associated with it
 * @param {Object} paymentMethods The object representing the defined payment methods in form value format.
 * @returns the same data but in a query-compatible format
 */
export const restructureForQuery = (paymentMethods) => {
  // rename address component data, as BaselaneAutoCompleteAddress fields
  // do not match DB address schema

  const cleanedUpCompletePaymentMethods = {};
  Object.keys(paymentMethods).forEach((paymentMethodKey) => {
    const paymentMethod = paymentMethods[paymentMethodKey];
    cleanedUpCompletePaymentMethods[paymentMethodKey] = {
      ...(paymentMethod?.id && {
        id: parseFloat(paymentMethod?.id),
        isDeletable: paymentMethod?.isDeletable,
      }),
      // type that describes payment method: WIRE_TRANSFER | ACH | CHECK_PAYMENT (if supplied)
      ...(paymentMethod?.type && { type: paymentMethod?.type }),
      // WIRE_TRANSFER & ACH specific fields
      ...((paymentMethodKey === PayeePaymentMethodKey.WIRE_TRANSFER ||
        paymentMethodKey === PayeePaymentMethodKey.ACH) && {
        accountHolderName: paymentMethod?.accountHolderName,
        routingNumber: paymentMethod?.routingNumber,
        accountNumber: paymentMethod?.accountNumber,
      }),
      // ACH specific fields
      ...(paymentMethodKey === PayeePaymentMethodKey.ACH && {
        accountType: paymentMethod?.accountType,
      }),
      // CHECK_PAYMENT specific fields
      ...(paymentMethodKey === PayeePaymentMethodKey.CHECK_PAYMENT && {
        name: paymentMethod?.name,
      }),
      // WIRE_TRANSFER & CHECK_PAYMENT specific fields
      ...((paymentMethodKey === PayeePaymentMethodKey.WIRE_TRANSFER ||
        paymentMethodKey === PayeePaymentMethodKey.CHECK_PAYMENT) && {
        address: {
          street: paymentMethod?.address,
          unit: paymentMethod?.unit || '',
          city: paymentMethod?.city,
          state: paymentMethod?.state,
          postalCode: paymentMethod?.zipcode,
          country: 'US',
        },
      }),
    };
  });

  return cleanedUpCompletePaymentMethods;
};

/**
 * Applies name to all undefined payment methods
 * @param {string} name the name to set as the default for payment methods
 * @param {Object} oldPaymentMethods the object representing the payment method data
 * @returns patched paymentMethods object
 */
export const patchNameOntoPaymentMethods = (name, oldPaymentMethods) => {
  const newPaymentMethods = {};
  Object.keys(oldPaymentMethods).forEach((key) => {
    if (
      !oldPaymentMethods[key] ||
      oldPaymentMethods[key] === {} ||
      Object.keys(oldPaymentMethods[key]).length <= 1
    ) {
      // payment type doesn't exist, or only has a name defined
      if (key === PayeePaymentMethodKey.CHECK_PAYMENT) {
        newPaymentMethods[key] = { name };
      } else {
        newPaymentMethods[key] = { accountHolderName: name };
      }
    } else {
      // payment type already filled in, so leave it unchanged
      newPaymentMethods[key] = oldPaymentMethods[key];
    }
  });
  return newPaymentMethods;
};

/**
 * When a user types in Recipient Name in the form,
 * all payment methods that have not been set will have the
 * name prefilled.
 * @param {string} name the name to set as the default for payment methods
 * @param {function} setMethods the setter function for storing the payment methods object
 */
export const prefillName = (name, setMethods) => {
  setMethods((oldPaymentMethods) => patchNameOntoPaymentMethods(name, oldPaymentMethods));
};

/**
 * Reformats payment method to include the type field for each payement
 * @param {Object} paymentMethods The object representing the defined payment methods in form value format.
 * @returns the same data but with type field generated appropriately.
 */
export const addPaymentTypeFields = (paymentMethods) => {
  // rename address component data, as BaselaneAutoCompleteAddress fields
  // do not match DB address schema

  const finalPaymentMethods = {};
  Object.keys(paymentMethods).forEach((paymentMethodKey) => {
    const paymentMethod = { ...paymentMethods[paymentMethodKey] };
    if (paymentMethodKey.toLowerCase().includes('ach')) {
      paymentMethod.type = PayeePaymentMethodType.ACH;
    } else if (paymentMethodKey.toLowerCase().includes('wire')) {
      paymentMethod.type = PayeePaymentMethodType.WIRE_TRANSFER;
    } else if (paymentMethodKey.toLowerCase().includes('check')) {
      paymentMethod.type = PayeePaymentMethodType.CHECK_PAYMENT;
    }
    finalPaymentMethods[paymentMethodKey] = paymentMethod;
  });

  return finalPaymentMethods;
};

/**
 * Applies bankNames to all applicable payment methods
 * Assumption: bank names provided correspond to the payment methods supplied, they
 * can be null, but no validation against routing number occurs here.
 *
 * @param {Object} oldPaymentMethods the object representing the payment method data
 * @param {string} achBankName the bank name to associate with the ACH account
 * @param {string} wireBankName the bank name to associate with the wire account
 * @returns patched paymentMethods object
 */
export const patchBankNamesOntoPaymentMethods = (oldPaymentMethods, achBankName, wireBankName) => {
  const newPaymentMethods = {};
  Object.keys(oldPaymentMethods).forEach((key) => {
    // a complete payment method, copy data and add bankName if available
    newPaymentMethods[key] = {
      ...oldPaymentMethods[key],
      ...(key === 'achPaymentMethods' &&
        achBankName && {
          bankName: achBankName,
        }),
      ...(key === 'wirePaymentMethods' &&
        wireBankName && {
          bankName: wireBankName,
        }),
    };
  });
  return newPaymentMethods;
};
