export const currentBackgroundStyles = {
  bg: 'green.100',
  color: 'green.800AA',
  maxWidth: '91px',
};

export const overdueBackgroundStyles = {
  bg: 'red.200',
  color: 'red.800AA',
  maxWidth: '109px',
};

export const mobileContainerStyles = {
  maxWidth: '100% !important',
  padding: '2px 6px !important',
  width: 'auto',
  height: 'auto',
  m: '4px 0 0 0 !important',
  borderRadius: '2px',
};

export const mobileFontStyles = {
  fontSize: '3xs',
};
