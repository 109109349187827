import React from 'react';
import { Box } from '@chakra-ui/react';
import { BaselaneConditionalTooltip } from '@shared/components';
import useUserSignUpExperiment from '@core/hooks/useUserSignUpExperiment';

type DisableMoveMoneyProps = {
  tooltipText: String,
  renderItem: Function,
  styles?: Object,
};

/**
 * Conditionally enables a tooltip if the user's email is unverified
 * This is part of the 2024-07_sign_up_page_experiment
 */
const DisableMoveMoney = ({ tooltipText, renderItem, styles = {} }: DisableMoveMoneyProps) => {
  const { isUserEmailUnverified } = useUserSignUpExperiment();

  return (
    <Box flex="1" {...styles}>
      <BaselaneConditionalTooltip
        tooltipText={tooltipText}
        condition={isUserEmailUnverified}
        styles={{ textStyle: 'xs' }}
      >
        <Box>{renderItem(isUserEmailUnverified)}</Box>
      </BaselaneConditionalTooltip>
    </Box>
  );
};

export default DisableMoveMoney;
