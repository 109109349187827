import React from 'react';
import { Box } from '@chakra-ui/react';
import { IconCarretDown, IconCarretUp } from '@icons';
import { dropdownIconContainerStyles } from '@shared/components/BaselaneDropdown/components/styles/dropdown.styles';

/**
 * Helper Functions
 */

export const onHandleKeyDownFn = (e, helpers) => {
  const { dropdownElementRef, searchRef, setOptions } = helpers;

  const dropdownElements = dropdownElementRef.current;
  const nodes = Array.from(dropdownElements?.getElementsByClassName('element') ?? []);

  if (
    e.event.key === 'ArrowUp' ||
    e.event.key === 'ArrowDown' ||
    e.event.key === 'Tab' ||
    e.event.key === 'Enter'
  ) {
    const currentIndex = nodes.indexOf(e.event.target);
    let newIndex = currentIndex;

    switch (e.event.key) {
      case 'ArrowDown':
      case 'Tab':
        newIndex = currentIndex + 1;
        break;
      case 'ArrowUp':
        newIndex = currentIndex - 1;
        break;

      default:
    }

    if (nodes[newIndex]) {
      nodes[newIndex].focus();
    } else if (newIndex < 0 && e.event.key === 'ArrowUp') {
      searchRef.current?.focus();
    } else {
      nodes[0]?.focus();
    }

    if (e.event.key === 'Enter' && nodes[currentIndex]?.id) {
      // NOTE: e.state.searchResults doesn't update properly when search is active
      setOptions(e.props.options, nodes[currentIndex]?.id);
    }
  }
};

export const getMultiSelectedItems = ({ itemToToggle, item, selectedStagedOptions }) =>
  itemToToggle
    ? selectedStagedOptions.filter((opt) => opt.id !== item.id)
    : [...selectedStagedOptions, item];

export const setIsSelectedItem = (item, selectedStagedOptions) =>
  selectedStagedOptions.some((opt) => opt.id === item.id);

export const getChildCounterVisualBgColor = (hasChildrenSelected, isExpanded) => {
  if (hasChildrenSelected && !isExpanded) {
    return 'brand.blue.100';
  }

  if (hasChildrenSelected && isExpanded) {
    return 'brand.blue.50';
  }
  return 'inherit';
};

/**
 * Helper Components
 */

export const customDropdownHandleRenderer = ({ state }) => {
  const icon = state.dropdown ? (
    <IconCarretUp width="16" height="16" viewBox="-2 -6 16 16" color="#6C7884" />
  ) : (
    <IconCarretDown width="16" height="16" viewBox="-2 -6 16 16" color="#6C7884" />
  );

  return (
    <Box className="icon-carret" {...dropdownIconContainerStyles}>
      {icon}
    </Box>
  );
};
