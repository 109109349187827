import React from 'react';
import { HStack, Box, Text } from '@chakra-ui/react';

import {
  boxContainerStyles,
  iconContainerStyles,
  titleContainerStyles,
  titleStyles,
  descriptionStyles,
} from './styles/infoCard.styles';

type InfoCardProps = {
  icon: string,
  title: string,
  description: string,
  buttonProps: string,
};

function InfoCard({ icon, title, description }: InfoCardProps) {
  return (
    <HStack {...boxContainerStyles}>
      <Box {...iconContainerStyles}>{icon}</Box>
      <Box {...titleContainerStyles}>
        <Text {...titleStyles}>{title}</Text>
        <Text {...descriptionStyles}>{description}</Text>
      </Box>
    </HStack>
  );
}

export default InfoCard;
