export const drawerHeaderStyle = {
  bg: 'brand.darkBlue.800A',
  p: '27px 32px',
  color: 'brand.neutral.white',
  fontSize: 'lg',
  fontWeight: 'medium',
  lineHeight: '26px',
};

export const drawerHeaderCloseButtonStyle = {
  right: '24px',
  top: '24px',
};

export const drawerFooterStyle = {
  h: '80px',
  p: '20px 32px',
  bg: 'brand.neutral.white',
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  zIndex: 1,
  position: 'absolute',
  bottom: 0,
  w: '100%',
  left: 0,
  justifyContent: 'flex-start',
};

export const drawerBodyStyle = {
  bg: 'brand.neutral.white',
};

export const drawerSubFormBodyStyle = {
  p: '32px 0px 32px 32px',
  bg: 'brand.neutral.white',
  overflow: 'hidden',
};

export const drawerMenuListItemContainerStyle = {
  _notFirst: {
    mt: '24px !important',
  },
};

export const tabItemStyles = {
  p: '8px',
  h: '40px',
  fontSize: 'sm',
  lineHeight: '20px',
  w: '264px',
  boxShadow: 'none !important',
  borderRadius: '4px',
};

export const drawerMenuListItemContainerStyleColors = (isValidEnabled) => {
  if (isValidEnabled) {
    return {
      color: 'green.800AA',
      _hover: {
        bg: 'brand.blue.50',
        cursor: 'pointer',

        color: 'green.800AA',
      },
      _selected: {
        bg: 'brand.blue.50',
        cursor: 'pointer',

        color: 'brand.blue.700',
      },
    };
  }

  return {
    color: 'brand.neutral.500',
    _hover: {
      bg: 'brand.blue.50',
      cursor: 'pointer',
      color: 'brand.blue.700',
    },
    _selected: {
      bg: 'brand.blue.50',
      cursor: 'pointer',
      color: 'brand.blue.700',
    },
  };
};

export const tabListStyles = {
  w: '280px',
  minW: '280px',
  h: 'calc(100% - 32px)',
};

export const drawerMenuContentTitleStyle = {
  textStyle: 'headline-lg',
  color: 'brand.neutral.700',
  pl: '16px',
  pr: '32px',
};

export const drawerSmallTitle = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
};

export const drawerDescriptionStyles = {
  textStyle: 'sm',
  color: 'brand.neutral.700',
  pl: '16px',
  pr: '32px',
};

export const zillowPrice = {
  textStyle: 'headline-xs',
  color: 'brand.neutral.600',
  display: 'inline-block',
};

export const errorStyles = {
  textStyle: 'xs',
  color: 'red.800AA',
  mb: '10px',
};

export const detailsContainerStyles = {
  overflow: 'auto',
  h: 'calc(100% - 46px)',
  pl: '16px',
  pr: '32px',
  mt: '28px',
};

export const financialsContainerStyles = {
  overflow: 'auto',
  h: 'calc(100% - 136px)',
  pl: '16px',
  pr: '32px',
  mt: '48px',
};

export const checkFormCircleStyles = (isMobile) => {
  return isMobile
    ? {
        width: '32px !important',
        height: '32px',
        borderRadius: '50%',
        textAlign: 'center',
        align: 'center',
        fontSize: 'xs',
        lineHeight: '30px',
        fontWeight: 'normal',
        border: '1px solid',
      }
    : {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        fontSize: 'xs',
        fontWeight: 'medium',
        border: '2px solid',
      };
};
