import React from 'react';
import { isMobile } from 'react-device-detect';
import { Formik } from 'formik';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import { BaselaneAddress, BaselaneAutoCompleteAddress } from '@shared/components';
import { formErrorStyles, formInputStyles, formLabelStyles } from '@shared/styles/input.style';
import { handleValidation, initialVariables } from './helpers/homeAddress.helpers';

type HomeAddressFormProps = { homeAddressRef: any, formData: Object };

const HomeAddressForm = ({ homeAddressRef, formData }: HomeAddressFormProps) => {
  return (
    <Box h="100%">
      <Formik
        innerRef={homeAddressRef}
        validate={(values) => handleValidation(values)}
        initialValues={initialVariables(formData)}
        validateOnBlur
        enableReinitialize
      >
        {({ values, handleChange, handleBlur, errors, touched, isValid }) => (
          <Stack h="100%" spacing="16px">
            <BaselaneAutoCompleteAddress
              {...{
                placeholder: 'Type to autocomplete address...',
                values,
                errors,
                touched,
                isValid,
                handleChange,
                handleBlur,
                setFormVariables: () => {},
                formInputStyles,
                formErrorStyles,
                setIsDirty: () => {},
                formLabelStyles: formLabelStyles.xs,
              }}
            />
            <Stack direction={!isMobile ? 'row' : 'column'} spacing={isMobile ? '16px' : '8px'}>
              <FormControl>
                <FormLabel htmlFor="unit" {...formLabelStyles.xs}>
                  Apartment, unit, suite, or floor # (Optional)
                </FormLabel>
                <Input
                  id="unit"
                  name="unit"
                  placeholder="e.g Floor 1"
                  value={values.unit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  {...formInputStyles}
                />
              </FormControl>

              <FormControl isRequired isInvalid={errors.city && touched.city}>
                <FormLabel htmlFor="city" {...formLabelStyles.xs}>
                  City
                </FormLabel>
                <Input
                  id="city"
                  name="city"
                  placeholder="e.g. Philadelphia"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  {...formInputStyles}
                />
                <FormErrorMessage {...formErrorStyles}>{errors.city}</FormErrorMessage>
              </FormControl>
            </Stack>

            <Stack direction="row">
              <FormControl isRequired isInvalid={errors.state && touched.state}>
                <FormLabel htmlFor="state" {...formLabelStyles.xs}>
                  State
                </FormLabel>
                <Select
                  id="state"
                  name="state"
                  placeholder="Select State"
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  {...formInputStyles}
                >
                  <BaselaneAddress.StateOptions />
                </Select>
                <FormErrorMessage {...formErrorStyles}>{errors.state}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={errors.zipcode && touched.zipcode}>
                <FormLabel htmlFor="zipcode" {...formLabelStyles.xs}>
                  Zip Code
                </FormLabel>
                <Input
                  id="zipcode"
                  name="zipcode"
                  placeholder="e.g. 19123"
                  maxLength={5}
                  value={values.zipcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  {...formInputStyles}
                />
                <FormErrorMessage {...formErrorStyles}>{errors.zipcode}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        )}
      </Formik>
    </Box>
  );
};

export default HomeAddressForm;
