import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { BaselaneButton } from '@core/components/Shared/components';
import { IllustrationBankingAddexternalAccount } from '@illustrations';

import { noRecipientStyles } from './styles';

type NoRecipientProps = {
  handleAddRecipientClick: Function,
};

export function NoRecipient({ handleAddRecipientClick }: NoRecipientProps) {
  const { containerStyles, textContainerStyles } = noRecipientStyles;

  return (
    <Stack {...containerStyles}>
      <IllustrationBankingAddexternalAccount />
      <Stack {...textContainerStyles}>
        <Text textStyle="headline-sm">Add your first recipient</Text>
        <Text textStyle="sm">Save ACH, wire and check payment details to send money.</Text>
      </Stack>
      <BaselaneButton
        variant="filled"
        palette="primary"
        size="md"
        onClick={handleAddRecipientClick}
      >
        Add recipient
      </BaselaneButton>
    </Stack>
  );
}
