export const HOME = '/';
export const LOGIN = '/login';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_UP_AS_RENTER = 'renter_info';
export const EMAIL_EXISTS = 'email_exists';

export const PHONE = '/phone';
export const PHONE_REQUIRED = '/phone_required';
export const FORGOT_PASSWORD = '/forgot_password';
export const RESET_PASSWORD = '/reset_password';

export const DASHBOARD = '/dashboard';
export const TRANSACTIONS = '/transactions';
export const GET_STARTED = '/get_started';
export const ANALYTICS_REPORTING = '/cashflow';
export const CASH_FLOW_STATEMENT = `${ANALYTICS_REPORTING}/statement`;
export const CASH_FLOW_TAX_PACKAGE = `${ANALYTICS_REPORTING}/tax_package`;
export const KPIS = '/kpis';
export const LEASES = '/leases';
export const LEASES_UPDATE_ACCOUNTS = '/leases/updateaccounts';
export const LEASES_QUICKPAY_LEARN_MORE = '/leases/quickpay_learnmore';
export const PROPERTIES = 'properties';
export const MAINTENANCE_REQUEST = 'maintenance_request';

export const TENANTS = 'tenants';

export const ADD_ACCOUNT = 'add_account';

export const NATIVE_BANK = '/banking';
export const NB_ACCOUNTS = `${NATIVE_BANK}/accounts`;
export const NB_CARDS = `${NATIVE_BANK}/cards`;
export const NB_STATEMENT_DOCUMENTS = `${NATIVE_BANK}/statements`;
export const NB_BANK_ACCOUNT = `${NB_ACCOUNTS}/:bankaccount/`;
export const NB_BANK_ACCOUNT_CARDS = `${NB_ACCOUNTS}/:bankaccount/cards`;

export const ORDER_CHECKBOOK = 'order_checkbook';
export const EDIT_ENTITY_ACCOUNT = 'edit_entity';
export const EDIT_ENTITY_ADDRESS = 'edit_entity_address';
export const ADD_VIRTUAL_CARD = 'add_virtual_card';
export const ADD_VIRTUAL_ACCOUNT = 'add_virtual_account';
export const ADD_BASELANE_BANK_ACCOUNT = 'add_baselane_bank_account';
export const REDEEM_CASHBACK = 'redeem_cashback';

export const EXTERNAL_ACCOUNTS = '/externalaccounts';
export const EA_MY_ACCOUNTS = `${EXTERNAL_ACCOUNTS}/my_accounts`;

export const INSURANCE = '/insurance';
export const LOANS = '/loans';
export const PARTNERSHIPS = '/partners';

export const USER_PROFILE = '/profile';
export const USER_PROFILE_UPDATE_ACCOUNTS = '/profile/update_accounts';
export const REFERRAL = '/referral';
export const UNVERIFIED = '/unverified';
export const VERIFIED = '/verified';

export const ONBOARDING = '/onboarding';
export const ONBOARDING_PROPERTY_SURVEY = `${ONBOARDING}/property_survey`;
export const ONBOARDING_ADD_PROPERTY = `${ONBOARDING}/add_property`;
export const ONBOARDING_TRIAGE = `${ONBOARDING}/selection`;
export const ONBOARDING_GETTING_STARTED = `/getting_started`;
export const ONBOARDING_GS_EASY_STEPS = `${ONBOARDING_GETTING_STARTED}/easy_steps`;
export const ONBOARDING_GS_BEFORE_BEGINNING = `${ONBOARDING_GETTING_STARTED}/before_you_begin`;

export const TRANSFERS_PAYMENTS = `transfers_payments`;
export const TRANSFERS_ACTIVITY = `activity`;
export const TRANSFERS_DETAILS = `:transferId`;
export const TRANSFERS_PAYMENT_METHOD_ACH = `ach`;
export const TRANSFERS_PAYMENT_METHOD_WIRE = `wire`;
export const TRANSFERS_PAYMENT_METHOD_CHECK = `check`;
export const TRANSFERS_PAYMENT_METHOD_DELETE = `deletePaymentMethod`;
export const TRANSFERS_RECIPIENTS = `recipients`;
export const TRANSFERS_RECIPIENT_DELETE = `delete`;
export const TRANSFERS_RECIPIENT_ADD = `add`;
export const TRANSFERS_SCHEDULED = `scheduled_payments`;
export const SCHEDULED_PAYMENT_DETAILS = `:scheduledPaymentId`;
export const SCHEDULED_TRANSFER_DETAILS = `:transferId`;
export const TRANSFERS_RULES = `transfer_rules`;
export const TRANSFERS_RECIPIENTS_DETAILS = `:recipientId`;
export const TRANSFERS_RECIPIENTS_DETAILS_EDIT = `edit`;

export const TRANSFER_FUNDS = 'transfer_funds';
export const SEND_FUNDS = 'send_funds';
export const ADD_FUNDS = 'add_funds';
export const ADD_FUNDS_BANK_TRANSFER = 'bank_transfer';
export const ADD_FUNDS_CHECK_DEPOSIT = 'check_deposit';
export const ADD_FUNDS_TRANSFER_METHOD_SELECTOR = 'transfer_method_selector';
export const ADD_FUNDS_DIGITAL_WALLET = 'digital_wallet';
export const ADD_FUNDS_ACCOUNT_ROUTING_NUMBERS = 'account_routing_numbers';

export const UNIFIED_RENT_COLLECTION = '/unified_rent_collection';
export const UNIFIED_RENT_COLLECTION_SUCCESS = `${UNIFIED_RENT_COLLECTION}_success`;

export const UNIFIED_LANDLORD_BANKING = '/unified_landlord_banking';
export const UNIFIED_LANDLORD_BANKING_SUCCESS = `${UNIFIED_LANDLORD_BANKING}_success`;
export const UNIFIED_LANDLORD_BANKING_FIRST_SUCCESS = `${UNIFIED_LANDLORD_BANKING}_first_success`;
export const UNIFIED_LB_PERSONAL_INFO = `${UNIFIED_LANDLORD_BANKING}/personal_details`;
export const UNIFIED_LB_HOME_ADDRESS = `${UNIFIED_LANDLORD_BANKING}/home_address`;
export const UNIFIED_LB_BUSINESS_INFO = `${UNIFIED_LANDLORD_BANKING}/business_information`;
export const UNIFIED_LB_SSN = `${UNIFIED_LANDLORD_BANKING}/social_security_number`;

export const UNIFIED_BOOKKEEPING_ANALYTICS = '/unified_bookkeeping_analytics';
export const UNIFIED_BA_ACCOUNTS = `${UNIFIED_BOOKKEEPING_ANALYTICS}/add_accounts`;
export const UNIFIED_BA_AUTOTAG = `${UNIFIED_BOOKKEEPING_ANALYTICS}_autotag`;
export const UNIFIED_BA_SUCCESS = `${UNIFIED_BOOKKEEPING_ANALYTICS}_success`;
export const UNIFIED_BA_ALL_SET = `${UNIFIED_BOOKKEEPING_ANALYTICS}_all_set`;

export const PUBLIC_ROUTES = Object.freeze([
  LOGIN,
  SIGN_UP,
  SIGN_IN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
]);

export const SOMETHING_WENT_WRONG = 'something-went-wrong';
export const SESSION_EXPIRED = 'session-expired';
