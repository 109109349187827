import isNil from '../validators/isNil';

function formatPercentage(amount) {
  if (isNil(amount)) {
    throw new Error('amount argument is not a number!');
  }

  const isNegative = amount < 0;

  // noinspection JSCheckFunctionSignatures
  const formattedAmount = Math.abs(amount);

  return {
    isNegative,
    isPercentage: isNegative ? `(${formattedAmount})%` : `${formattedAmount}%`,
  };
}

export default formatPercentage;
