import process from 'process';
import { React, useEffect, useRef, useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { publicIpv4 } from 'public-ip';
import { Formik } from 'formik';
import LogRocket from 'logrocket';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  passwordValidator,
  initialChecklist,
  PW_ERROR,
} from '@features/Authentication/helpers/validation.helpers';
import {
  BaselaneButton,
  BaselaneButtonIcon,
  BaselaneDivider,
  BaselaneSpinner,
} from '@core/components/Shared/components';
import { Icon16ArrowForward, Icon16Hide, Icon16Unhide } from '@core/components/Icons/16px';
import PasswordChecker from '@pages/SignUpPage/components/Form/PasswordChecker';
import { IconExclamationCircleFilled } from '@core/components/Icons';
import GoogleButton from '@core/features/Authentication/components/GoogleButton';
import { dividerStyles } from '@pages/LoginPage/styles/font.style';
import PrivacyTerms from '@pages/SignUpPage/components/PrivacyTerms';
import { signUpTestsFooterNote } from '@pages/SignUpPage/styles/signupTests.styles';

import {
  cleanupRedirects,
  clearUrlUtmData,
  deleteUtmCookies,
  getUtmInputs,
  saveSearch,
  trackUtmUser,
} from '@core/features/Authentication/helpers/tracking.helpers';
import { firebaseEmailSignUp } from '@core/features/Authentication/helpers/firebase.helpers';
import { attemptToCreateUser } from '@core/features/Authentication/helpers/signup.helpers';
import { CREATE_USER_RETRY_COUNT_DEFAULT } from '@features/Authentication/helpers/constant.helpers';
import { CREATE_USER_DEFERRED } from '@core/apollo/queries';
import { emailVerificaton } from '@core/Services/CreateAccount';
import { ONBOARDING_TRIAGE } from '@core/constants/routes';
import {
  AuthenticationError,
  emailAlreadyInUse,
  errorMessages,
} from '@core/features/Authentication/helpers/error.helpers';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';
import {
  formlabelCustomized,
  formErrorStylesCustomized,
  formInputCustomized,
} from '../../SignUpPage/styles/createaccount.style';

type DeferredPasswordSignUpFormProps = {
  id: string,
  email: String,
  onUserCreated: Function,
};

const DeferredPasswordSignUpForm = ({
  id = 'deferredPasswordSignup',
  email,
  onUserCreated,
  ...rest
}: DeferredPasswordSignUpFormProps): any => {
  // FingerprintJS
  const { getData: getDataFingerprint } = useVisitorData({
    extendedResult: true,
    tag: {
      env: process?.env?.REACT_APP_NODE_ENV,
    },
  });

  const formikRef = useRef();
  const initialPopoverFocusRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const client = useApolloClient();

  const [createUser] = useMutation(CREATE_USER_DEFERRED);

  const [userIpAddress, setUserIpAddress] = useState(null);
  const [showChecklist, setShowChecklist] = useState(false);
  const [checklist, setChecklist] = useState(initialChecklist);
  const [isStrongPassword, setStrongPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordVisibility = () => setShow(!show);
  const weakPassword = () => Object.keys(checklist).find((item) => !checklist[item].isValid);

  const togglePasswordChecklistVisibility = () =>
    setShowChecklist((prevState) => {
      return !prevState;
    });

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      formikRef.current.handleSubmit();
    }
  };

  // Fallback IPAddress (in case fingerprint fails)
  useEffect(() => {
    publicIpv4().then((ip) => {
      setUserIpAddress(ip);
    });
  }, []);

  const validateSignUp = (values, weakPasswordv, setStrongPasswordv) => {
    const { password } = values;
    const errors = {};

    if (weakPasswordv()) {
      setStrongPasswordv(false);
      errors.password = PW_ERROR.weakPw;
    }

    if (password === '') {
      errors.password = PW_ERROR.pwRequired;
    }

    return errors;
  };

  const handleFormSubmit = (e) => {
    const { password } = e;

    sendSegmentEvent('sign_up_clicked', {
      enteredEmail: email,
      event_source: 'landlord_portal',
    });

    setIsLoading(true);
    // attempt to retrieve user's device fingerprint
    let requestId = '';
    getDataFingerprint()
      .then((data) => {
        requestId = data?.requestId;
        setUserIpAddress(data?.ip);
      })
      .catch((error) => {
        LogRocket.log(`getDataFingerprint error: ${JSON.stringify(error)}`);
      })
      .finally(() => {
        const utmInputs = getUtmInputs(userIpAddress, location, 'account_created');
        // attempt to log in with google account
        // this should happen with or without fingerprint data
        firebaseEmailSignUp({
          email,
          password,
          handleSuccess: (credential) => {
            attemptToCreateUser({
              credential,
              CREATE_USER_RETRY_COUNT_DEFAULT,
              requestId,
              utmInputs,
              location,
              // inviteCode,
              createUser,
              setIsLoading,
              onSuccess: async () => {
                const bodyParam = { email };
                // force refresh of token to ensure we have the latest claims
                emailVerificaton(bodyParam)
                  .then((data) => {
                    const { result } = data;
                    if (result === 'success') {
                      // createUser success

                      // tracking event
                      trackUtmUser(credential.user, 'account_created', userIpAddress, utmInputs);

                      // reddit tracking
                      window?.rdt('track', 'SignUp');

                      client.resetStore();
                    }

                    // delete utm cookie data, as it has been consumed
                    deleteUtmCookies();
                    // delete utm data from storage, as it has been consumed
                    clearUrlUtmData();
                  })
                  .then((data) => {
                    // store search parameters in local storage
                    saveSearch();
                    // cleanup redirect data
                    cleanupRedirects();
                    onUserCreated(data).then(() => {
                      navigate(ONBOARDING_TRIAGE); // skip phone page and go straight to onboarding triage
                    });
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
                client.resetStore();
              },
              onQueryFail: (error) => {
                formikRef?.current?.setErrors({
                  ...formikRef?.current?.errors,
                  signUp: errorMessages[AuthenticationError.generic],
                });

                LogRocket.log(
                  `CreateUser (Email SignUp) error: ${JSON.stringify(
                    error
                  )}, requestId:${requestId}, email: ${credential?.user?.email}`
                );
              },
              onLastAttemptFail: (responseErrors) => {
                if (emailAlreadyInUse(responseErrors[0]?.message)) {
                  formikRef?.current?.setErrors({
                    ...formikRef?.current?.errors,
                    email: errorMessages[AuthenticationError.emailalreadyinuse],
                  });
                }
                LogRocket.log(
                  `CreateUser (Email SignUp) error: ${JSON.stringify(
                    responseErrors
                  )}, requestId:${requestId}, email: ${credential?.user?.email}`
                );
                sendSegmentEvent('baselane_signup_failed', {
                  enteredEmail: credential?.user?.email,
                  idNotFound: true,
                  event_source: 'landlord_portal',
                });
              },
            });
          },
          handleError: (error) => {
            setIsLoading(false);

            LogRocket.log(
              `signUpWithCustomEmail error: ${JSON.stringify(error)}, requestId:${requestId}`
            );

            inActivityLogoutStorage.delete();
            localStorage.removeItem('redirectLogin');

            const { code } = error || {};
            const requestErrors = {};

            switch (code) {
              case AuthenticationError.invalidemail:
              case AuthenticationError.emailalreadyinuse:
                requestErrors.email = errorMessages[code];
                break;
              case AuthenticationError.tenant:
                requestErrors.signUp = errorMessages[code];
                break;
              default:
                // do nothing - unhandled error
                break;
            }

            // set login form errors
            formikRef?.current?.setErrors({ ...formikRef?.current?.errors, ...requestErrors });
          },
        });
      });
  };

  return (
    <HStack id={id} w="full" justifyContent="center">
      <Formik
        innerRef={formikRef}
        validate={(values) => validateSignUp(values, weakPassword, setStrongPassword)}
        initialValues={{ password: '' }}
        onSubmit={handleFormSubmit}
      >
        {({ isValid, dirty, values, handleSubmit, handleBlur, handleChange, errors, touched }) => (
          <>
            {isLoading && <BaselaneSpinner />}
            {!isLoading && (
              <VStack maxW="480px" w="full" gap={3} alignItems="flex-start">
                <Text textStyle="xl-heavy" alignSelf="flex-start">
                  Now, create a password for your account
                </Text>
                <FormControl isInvalid={errors.password && touched.password}>
                  <FormLabel htmlFor="password" {...formlabelCustomized}>
                    Password
                  </FormLabel>
                  <InputGroup>
                    <Input
                      {...formInputCustomized}
                      ref={initialPopoverFocusRef}
                      id="password"
                      name="password"
                      value={values?.password}
                      type={show ? 'text' : 'password'}
                      onFocus={togglePasswordChecklistVisibility}
                      onChange={(e) => {
                        const p = e.target.value;
                        const updatedChecklist = passwordValidator(p, checklist);
                        setChecklist(updatedChecklist);
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        togglePasswordChecklistVisibility();
                      }}
                      onKeyDown={handleOnKeyDown}
                    />
                    <InputRightElement h="100%">
                      <BaselaneButtonIcon
                        variant="transparent"
                        palette="neutral"
                        size="sm"
                        icon={show ? <Icon16Hide /> : <Icon16Unhide />}
                        isActive={show}
                        onClick={handlePasswordVisibility}
                      />
                    </InputRightElement>
                    {!isMobile && (
                      <PasswordChecker
                        {...{
                          initialPopoverFocusRef,
                          showChecklist,
                          checklist,
                          isStrongPassword,
                        }}
                      />
                    )}
                  </InputGroup>
                  <FormErrorMessage {...formErrorStylesCustomized}>
                    <Text as="span" {...{ mr: '8px' }}>
                      <IconExclamationCircleFilled />
                    </Text>
                    {errors.password}
                  </FormErrorMessage>
                  {isMobile && (
                    <PasswordChecker
                      {...{
                        initialPopoverFocusRef,
                        showChecklist,
                        checklist,
                        isStrongPassword,
                      }}
                    />
                  )}
                </FormControl>
                <BaselaneButton
                  size="md"
                  palette="primary"
                  variant="filled"
                  onClick={handleSubmit}
                  rightIcon={<Icon16ArrowForward />}
                  isDisabled={!isValid || !dirty}
                >
                  Continue
                </BaselaneButton>
                <HStack w="full">
                  <BaselaneDivider
                    styles={{
                      ...dividerStyles,
                      my: '0px !important',
                      bg: 'brand.neutral.200',
                      borderColor: 'brand.neutral.200',
                    }}
                  />
                  <Text
                    whiteSpace="nowrap"
                    fontSize={{ xxs: 'sm', xs: 'sm', sm: 'sm', md: 'sm', lg: 'md', xl: 'md' }}
                    color="brand.neutral.700"
                  >
                    Or
                  </Text>
                  <BaselaneDivider styles={{ ...dividerStyles, my: '0px !important' }} />
                </HStack>
                <GoogleButton
                  isLogin={false}
                  text="Sign in"
                  isMobile={isMobile}
                  onUserCreated={onUserCreated}
                />
                <PrivacyTerms styles={signUpTestsFooterNote} />
              </VStack>
            )}
          </>
        )}
      </Formik>
    </HStack>
  );
};

export default DeferredPasswordSignUpForm;
