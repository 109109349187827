import React, { useContext } from 'react';
import { HStack } from '@chakra-ui/react';

import BanksContext from '@contexts/BanksContext';
import AddFundsDrawer from '@core/components/NativeBankingPage/MainContent/components/AddFundsDrawer';
import MakeTransferOrPaymentDrawer from '@core/components/NativeBankingPage/MainContent/components/MakeTransferOrPaymentDrawer';
import VirtualCardTriggerButton from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/AddVirtualCard/TriggerButton';
import AddAccountTrigger from '@pages/BankEntity/AddAccount/AddAccountTrigger';
import getBreakPoints from '@core/utils/getBreakPoints';
import { largeTextStyles } from '../header.styles';

const HeaderRight = () => {
  const { banks, refetchBankSummary } = useContext(BanksContext);
  const { isMin1150 } = getBreakPoints();

  const buttonStyles = {
    variant: 'filled',
    palette: 'primary',
    size: 'md',
  };

  return (
    <HStack
      id="action-group-buttons"
      {...largeTextStyles}
      mt={!isMin1150 ? '24px !important' : '0'}
      ml={!isMin1150 ? '0 !important' : '24px'}
    >
      <AddFundsDrawer
        banks={banks}
        refetchBankSummary={refetchBankSummary}
        buttonProps={buttonStyles}
      />
      <MakeTransferOrPaymentDrawer
        banks={banks}
        refetchBankSummary={refetchBankSummary}
        buttonProps={buttonStyles}
      />
      <AddAccountTrigger size="md" />
      <VirtualCardTriggerButton />
    </HStack>
  );
};

export default HeaderRight;
