import React, { useState } from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import { IconCheck, IconPencil } from '@icons';
import { currencyMask, daysMask } from '@core/utils/masks/index';
import { BaselaneButton, BaselaneButtonIcon, BaselaneDivider } from '@shared/components';
import MonthlyRentAmountInput from './MonthlyRentAmountInput';
import RentDueDateInput from './RentDueDateInput';
import LeaseStartDateInput from './LeaseStartDateInput';
import RentCollectionStartInput from './RentCollectionStartInput';
import LeaseEndDateInput from './LeaseEndDateInput';
import { RecurringMonthlyFees, sectionData } from '../DepositsAndFees';
import MonthToMonthInput from './MonthToMonthInput';
import LateFeeGroup from './LateFeeGroup';
import {
  formValidation,
  getFormInitialValues,
  getRecurringDateText,
  wordifyDate,
  calculateMonths,
  getLastDateText,
} from '../../formHelpers/leaseTermForm.helper';
import { getDepositAndFeesData } from '../../formHelpers/depositAndFeesForm.helper';
import type { PropertyUnitType } from '../../../types';

import {
  generalFormContainerStyles,
  unifiedLeaseFormContainerStyles,
  subheadingTextStyles,
} from '../../styles/shared.styles';
import {
  leaseTermsInputWidthContainer,
  leaseTermsDirectionContainerStyles,
  verticalDashedLine,
  stepCircle,
  paymentsBox,
  paymentsBoxInApp,
} from '../../styles/leaseTerms.style';

type LeaseTermsFormProps = {
  unit: PropertyUnitType,
  lease: Object,
  formVariables?: Object,
  setFormVariables: Function,
  leaseTermsRef: any,
  unifiedRC: boolean,
  isStartDateDisabled: boolean,
  invoicesForUnit: Array,
  monthlyFeeFormRef: any,
};

function LeaseTermsForm({
  unit,
  lease,
  formVariables,
  setFormVariables,
  leaseTermsRef,
  unifiedRC = true,
  isStartDateDisabled = false,
  invoicesForUnit = [],
  monthlyFeeFormRef = null,
}: LeaseTermsFormProps) {
  const [formInitialValues, paymentData] = getFormInitialValues(unit || { lease });
  const { rentId, rentName, lateFeeId, lateFeeName } = paymentData;
  const [editRCStartDate, setEditRCStartDate] = useState(false);

  const [rcStartOptions, setRCStartOptions] = useState([]);
  const [noRCStartOptions, setNoRCStartOptions] = useState(true);

  const recurringFeeData = getDepositAndFeesData(unit?.lease, formVariables);
  const { fees } = recurringFeeData;
  const isAdditionalFeesDb =
    fees.length > 0
      ? fees
          .sort((a, b) => a.description - b.description)
          .map((feeData) => {
            const feesData = { ...feeData };
            // eslint-disable-next-line no-underscore-dangle
            delete feesData.__typename;
            delete feesData.category;

            let { dueDate } = feesData;
            if (dueDate && dueDate !== null) {
              dueDate = new Date(dueDate);
              return Object.assign(feesData, { dueDate });
            }
            return feesData;
          })
      : [];

  const subTitleObject = unifiedRC
    ? {
        text: 'First rent payment',
        id: 'first-rent-payment',
      }
    : {
        text: 'Next rent payment',
        id: 'next-rent-payment',
      };

  return (
    <Box
      {...(unifiedRC ? unifiedLeaseFormContainerStyles : generalFormContainerStyles)}
      pt="0px"
      overflowY="none"
    >
      <Formik
        innerRef={leaseTermsRef}
        validate={(values) => formValidation(values)}
        initialValues={formInitialValues}
        validateOnBlur
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldTouched,
          setFieldValue,
          errors,
          touched,
        }) => (
          <>
            {unifiedRC && <Text {...subheadingTextStyles}>Lease term</Text>}

            <MonthToMonthInput
              {...{
                name: 'isMonthToMonth',
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                setFormVariables,
                unifiedRC,
                isDisabled: !unifiedRC,
                setRCStartOptions,
                setNoRCStartOptions,
                invoicesForUnit,
              }}
            />

            <Stack {...leaseTermsDirectionContainerStyles}>
              <Box {...leaseTermsInputWidthContainer}>
                <LeaseStartDateInput
                  {...{
                    name: 'startDate',
                    errors,
                    touched,
                    values,
                    setFormVariables,
                    setFieldTouched,
                    setFieldValue,
                    isDisabled: isStartDateDisabled,
                    unifiedRC,
                    rcStartOptions,
                    setRCStartOptions,
                    setNoRCStartOptions,
                    invoicesForUnit,
                  }}
                />
              </Box>

              {/* End date and Month-to-month */}
              <Box {...leaseTermsInputWidthContainer}>
                <LeaseEndDateInput
                  {...{
                    name: 'endDate',
                    errors,
                    touched,
                    values,
                    setFormVariables,
                    setFieldTouched,
                    setFieldValue,
                    unifiedRC,
                    rcStartOptions,
                    setRCStartOptions,
                    setNoRCStartOptions,
                    invoicesForUnit,
                  }}
                />
              </Box>
            </Stack>

            <BaselaneDivider
              styles={{
                mt: '16px',
                mb: '32px',
              }}
            />

            <MonthlyRentAmountInput
              {...{
                name: 'monthlyRentAmount',
                errors,
                touched,
                values,
                rentId,
                rentName,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFormVariables,
                currencyMask: currencyMask(),
              }}
            />

            <RecurringMonthlyFees
              {...{
                sectionInfo: sectionData.recurringMonthlyFees,
                sectionId: 'recurringMonthlyFees',
                handleChange,
                setFieldValue,
                setFormVariables,
                isAdditionalFeesDb,
                values,
                monthlyFeeFormRef,
                unifiedRC,
              }}
            />

            <RentDueDateInput
              {...{
                name: 'dueDays',
                errors,
                touched,
                values,
                formInitialValues,
                rentId,
                rentName,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                setFormVariables,
                currencyMask: currencyMask(),
                isDisabled: !unifiedRC,
                rcStartOptions,
                noRCStartOptions,
                setRCStartOptions,
                setNoRCStartOptions,
                invoicesForUnit,
                setEditRCStartDate,
              }}
            />

            <LateFeeGroup
              {...{
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                setFormVariables,
                lateFeeId,
                lateFeeName,
                currencyMask: currencyMask(),
                daysMask,
                unifiedRC,
                lease,
                formValidation,
              }}
            />

            {!unifiedRC && (
              <RentCollectionStartInput
                {...{
                  name: 'rentCollectionStartDate',
                  errors,
                  touched,
                  values,
                  formVariables,
                  handleChange,
                  handleBlur,
                  setFieldTouched,
                  setFieldValue,
                  setFormVariables,
                  rcStartOptions,
                  setEditRCStartDate,
                  startDate: values.startDate,
                  endDate: values.endDate,
                  dueDays: values.dueDays,
                  unifiedRC,
                }}
              />
            )}

            {!noRCStartOptions &&
              values.dueDays &&
              values.startDate &&
              (values.endDate || values.isMonthToMonth) && (
                <Stack {...(unifiedRC ? paymentsBox : paymentsBoxInApp)} fontSize="xs">
                  <Flex mt="0">
                    <Box {...stepCircle(true)} />
                    {editRCStartDate ? (
                      <Box>Recurring rent starts on</Box>
                    ) : (
                      <Flex justifyContent="space-between" w="100%" h="32px">
                        <Box id={subTitleObject.id} fontWeight="medium">
                          {values.rentCollectionStartDate && unifiedRC
                            ? wordifyDate(values.rentCollectionStartDate)
                            : rcStartOptions[0].label}
                        </Box>
                        {unifiedRC && (
                          <Box>
                            <BaselaneButton
                              onClick={() => setEditRCStartDate(!editRCStartDate)}
                              leftIcon={<IconPencil w="16" h="16" />}
                              variant="transparent"
                              palette="primary"
                            >
                              Edit
                            </BaselaneButton>
                          </Box>
                        )}
                      </Flex>
                    )}
                  </Flex>
                  <Flex position="relative" top="-10px" mt="0 !important">
                    <Box {...verticalDashedLine(editRCStartDate)} />
                    {editRCStartDate ? (
                      <Flex justifyContent="space-between" w="100%" position="relative" top="14px">
                        <RentCollectionStartInput
                          {...{
                            name: 'rentCollectionStartDate',
                            errors,
                            touched,
                            values,
                            formVariables,
                            handleChange,
                            handleBlur,
                            setFieldTouched,
                            setFieldValue,
                            setFormVariables,
                            rcStartOptions,
                            setEditRCStartDate,
                            startDate: values.startDate,
                            endDate: values.endDate,
                            dueDays: values.dueDays,
                            unifiedRC,
                          }}
                        />
                        <Box pl="8px">
                          <BaselaneButtonIcon
                            variant="filled"
                            palette="primary"
                            size="sm"
                            icon={<IconCheck w={16} h={16} color="#fff" />}
                            id="delete_fee_button"
                            onClick={() => setEditRCStartDate(!editRCStartDate)}
                          />
                        </Box>
                      </Flex>
                    ) : (
                      <Box position="relative" top="-2px">
                        {subTitleObject.text}
                      </Box>
                    )}
                  </Flex>
                  <Flex position="relative" top="-24px">
                    <Box {...stepCircle(values.endDate)} />
                    {values.endDate ? (
                      <Box id="last-rent-payment" fontWeight="medium">
                        {getLastDateText(values.startDate, values.endDate, values.dueDays)}
                      </Box>
                    ) : (
                      <Box>
                        <span>Rent Invoices due on the </span>
                        <span>
                          <strong>
                            {values.dueDays === '31'
                              ? 'last day'
                              : getRecurringDateText(Number(values.dueDays))}
                          </strong>{' '}
                          of every month
                        </span>
                      </Box>
                    )}
                  </Flex>
                  {values.endDate && (
                    <Box position="relative" top="-30px" left="22px" w="100%" pr="16px">
                      <span>Last Rent Payment</span>
                    </Box>
                  )}
                  {unifiedRC && (
                    <Box
                      position="relative"
                      top="-32px"
                      left="22px"
                      w="100%"
                      mb="8px"
                      pr="16px"
                      lineHeight="14px"
                      fontSize="3xs"
                    >
                      If you wish to collect last month’s rent in advance, you can do so in the next
                      step
                    </Box>
                  )}
                  {values.rentCollectionStartDate && !values.isMonthToMonth && (
                    <Flex position="relative" top="-12px" left="22px" w="100%" mb="8px" pr="16px">
                      <Box id="total-payments" fontWeight="medium" mr="4px">{` ${calculateMonths(
                        values.rentCollectionStartDate,
                        values.endDate,
                        unifiedRC,
                        rcStartOptions[0]?.dateValue
                      )}`}</Box>
                      {unifiedRC ? (
                        <Box fontWeight="medium">Total Rent Payments</Box>
                      ) : (
                        <Box fontWeight="medium">Total Remaining Rent Payments</Box>
                      )}
                    </Flex>
                  )}
                </Stack>
              )}
          </>
        )}
      </Formik>
    </Box>
  );
}

LeaseTermsForm.defaultProps = {
  formVariables: {},
};

export default LeaseTermsForm;
