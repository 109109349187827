import React from 'react';
import { Box, Text, Stack } from '@chakra-ui/react';
import getBreakPoints from '@core/utils/getBreakPoints';
import { BaselaneOverview } from '@shared/components';
import {
  learnMoreModalStyles,
  customFontStyles,
  customBorderStyles,
} from '../styles/learnMoreModal.styles';

type MonthlyOverviewProps = {
  content: Object,
};
const MonthlyOverview = ({ content: montlyContent }: MonthlyOverviewProps) => {
  const { isMinXL } = getBreakPoints();

  const { overview } = learnMoreModalStyles ?? {};
  const { title, left, center, right } = montlyContent ?? {};

  const mobileOuterContainer = isMinXL
    ? overview.card.mobileOuterContainer
    : overview.card.outerContainer;
  const overviewContainerStyles = isMinXL ? overview.mobileContainer : overview.container;
  const lineAStyles = isMinXL ? overview.card.footer.lineAMobile : overview.card.footer.lineA;

  const overViewContent = (text1, text2) => {
    return (
      <Box {...overview.card.footer.container}>
        {text1 && <Box {...lineAStyles}>{text1}</Box>}
        {text2 && <Box {...overview.card.footer.lineB}>{text2}</Box>}
      </Box>
    );
  };

  const elements = [
    {
      title: left?.title,
      content: overViewContent(left?.lineA, left?.lineB),
    },
    {
      title: center?.title,
      content: overViewContent(center?.lineA, center?.lineB),
    },
    {
      title: right?.title,
      content: overViewContent(right?.lineA, right?.lineB),
    },
  ];

  return (
    <Stack {...overviewContainerStyles}>
      <Box m="0!important">
        <Box {...overview.borderTitle} />
        <Text {...overview.title}>{title}</Text>
      </Box>

      <BaselaneOverview
        styles={mobileOuterContainer}
        customContentContainerStyles={overview.card.container}
        customContentStyles={overview.card.content}
        customFontStyles={customFontStyles}
        customBorderStyles={customBorderStyles}
        elements={elements}
        firstItemStyles={{ ...overview.card.firstItem }}
      />
    </Stack>
  );
};

export default MonthlyOverview;
