import React from 'react';
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import PropTypes from 'prop-types';
import { T1Dropdown } from '@shared/components';
import AmountOption from './AmountOption';
import {
  inputGroupStyles,
  inputLeftAddonStyles,
  inputAmountStyles,
} from './styles/baselaneAmountStyles';

const BaselaneAmount = ({
  hasError,
  isInvalid,
  isDisabled,
  inputProps,
  dropdownProps,
  onChange,
}) => {
  const dropdownOptions = {
    in: {
      name: 'Money In',
      id: 'in',
      icon: { name: 'Icon16PlusCircle', style: { color: 'green.700' } },
    },
    out: {
      name: 'Money Out',
      id: 'out',
      icon: { name: 'Icon16Minus', style: { color: 'red.700' } },
    },
  };

  const currencyMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  });

  const invalidAttribute = isInvalid ? { 'data-invalid': true } : {};
  const disabledAttribute = isDisabled ? { 'data-disabled': true } : {};

  return (
    <InputGroup role="group" {...invalidAttribute} {...disabledAttribute} {...inputGroupStyles}>
      <InputLeftAddon {...inputLeftAddonStyles}>
        <T1Dropdown
          hideSearch
          {...{
            classNames: ['amountInput'],
            type: 'tier1',
            data: Object.values(dropdownOptions),
            handleSubmit: (item) => {
              onChange({
                target: {
                  name: dropdownProps.name,
                  value: item,
                },
              });
            },
            showValueByFields: ['name'],
            itemRenderer: (item) => AmountOption(item),
            selectedItem: dropdownOptions[dropdownProps.value],
            // eslint-disable-next-line react/no-unstable-nested-components
            CustomDisplayInput: (item) => <AmountOption item={item?.selectedOptions[0]} />,
          }}
        />
      </InputLeftAddon>
      <Input
        as={MaskedInput}
        mask={currencyMask}
        placeholder="$0.00"
        variant="unstyled"
        {...inputAmountStyles}
        {...inputProps}
        onChange={onChange}
      />
    </InputGroup>
  );
};

BaselaneAmount.propTypes = {
  hasError: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  inputProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  dropdownProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOf(['in', 'out']),
  }),
  onChange: PropTypes.func,
};

BaselaneAmount.defaultProps = {
  hasError: false,
  isInvalid: false,
  isDisabled: false,
  inputProps: {
    name: '',
    value: 0,
  },
  dropdownProps: {
    name: '',
    value: 'in',
  },
  onChange: () => {},
};

export default BaselaneAmount;
