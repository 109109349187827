import React from 'react';

type IconSmallPaypalLogoProps = {
  w?: Number,
  h?: Number,
};

function IconSmallPaypalLogo({ w = 16, h = 16 }: IconSmallPaypalLogoProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m6.47122 24.5336.42905-2.7247-.95563-.0222H1.38135L4.55263 1.68075c.00968-.0607.04179-.11729.08855-.15748.04675-.04019.10673-.06233.16907-.06233h7.69445c2.5545 0 4.3172.5315 5.2377 1.58054.4314.49215.7062 1.00641.8392 1.57236.1393.59385.1419 1.30333.0057 2.16866l-.01.06316v.55445l.4317.24444c.3634.19274.652.41338.8735.66601.3693.42077.608.95555.7088 1.58957.1042.65207.0697 1.42797-.1008 2.30647-.1969 1.0105-.5152 1.8906-.945 2.6107-.3955.6636-.8992 1.2138-1.4971 1.6405-.571.4051-1.2494.7128-2.0164.9097-.7433.1934-1.5905.2911-2.52.2911h-.5988c-.4281 0-.8442.1542-1.1705.4307-.3273.2821-.5441.6675-.6104 1.0891l-.0451.2454-.758 4.8021-.0345.1764c-.009.056-.0246.0838-.0475.1027-.0205.017-.05.0286-.0788.0286H6.47122Z"
        fill="#253B80"
      />
      <path
        d="M19.4156 6.91406c-.0229.14681-.0491.29691-.0786.45111-1.0149 5.20923-4.4863 7.00873-8.9202 7.00873H8.15935c-.54217 0-.99908.3937-1.08362.9285l-1.15588 7.3295-.32728 2.0775c-.05502.3511.21583.6677.57003.6677h4.0041c.4742 0 .8768-.3444.9514-.8119l.0394-.2036.754-4.7835.0484-.2623c.0737-.4691.4773-.8136.9514-.8136h.5988c3.8793 0 6.9162-1.5748 7.8038-6.1321.3707-1.9037.1788-3.49326-.8024-4.61122-.2968-.33712-.6652-.61681-1.0959-.84482Z"
        fill="#179BD7"
      />
      <path
        d="M18.3522 6.48917c-.1549-.04512-.315-.08613-.4791-.12303-.1648-.03608-.3339-.06807-.5077-.09596-.6088-.09843-1.2756-.14518-1.9901-.14518H9.34439c-.14852 0-.2895.03362-.41583.09432-.27816.13369-.48478.397-.53484.71933L7.11057 15.0637l-.03684.237c.08454-.5347.54146-.9284 1.08362-.9284h2.25745c4.4339 0 7.9055-1.8004 8.9202-7.00878.0302-.1542.0557-.30429.0786-.4511-.2567-.13617-.5348-.25264-.8342-.3519-.0737-.0246-.15-.04838-.2272-.07135Z"
        fill="#222D65"
      />
      <path
        d="M8.39711 6.93984c.05006-.32235.25691-.58564.53484-.71852.12728-.0607.26754-.09432.41583-.09432h6.03112c.7145 0 1.3814.04675 1.9899.14518.174.02788.3431.05988.5079.09595.1641.03693.324.07794.4791.12304.077.02297.1533.04675.2279.07055.2994.09923.5776.21653.8343.35187.302-1.92505-.0024-3.23575-1.0433-4.42261C17.2271 1.18438 15.1557.625 12.5054.625H4.8107c-.54145 0-1.0031.39368-1.08692.92929L.518966 21.8669c-.063284.4019.24676.7646.651964.7646h4.75055l1.19271-7.5666 1.28292-8.12506Z"
        fill="#253B80"
      />
    </svg>
  );
}

IconSmallPaypalLogo.defaultProps = {
  w: 16,
  h: 16,
};

export default IconSmallPaypalLogo;
