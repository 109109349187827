import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, generatePath, useLocation } from 'react-router-dom';
import { ChakraProvider, useDisclosure } from '@chakra-ui/react';

import { BaselaneDrawer, UnsavedChangesAlert } from '@shared/components';
import habitatTheme from '@core/themeHabitat';
import getBreakPoints from '@core/utils/getBreakPoints';
import onDrawerClose from '@core/utils/onDrawerClose';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';

import { getFlatAccounts } from '@core/components/NativeBankingPage/MainContent/components/Accounts/helpers/bank.helpers';
import BanksContext from '@contexts/BanksContext';
import AccountOptions from './AccountOptions';
import CreateAccount from './CreateAccount';
import AccountSuccess from './AccountSuccess';

type AddAccountNewProps = {
  from?: String,
};

const AddAccountNew = ({ from }: AddAccountNewProps) => {
  const [step, setStep] = useState(1);
  const [accountOption, setAccountOption] = useState(null);
  const [formSuccess, setFormSuccess] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [newAccountId, setNewAccountId] = useState(null);

  const { baselaneConnectedAccounts } = useContext(BanksContext);

  const { search } = useLocation();

  // Alert State
  const { isOpen: isDrawerAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  const params = useParams();
  const navigate = useNavigate();

  const { isMax767 } = getBreakPoints();

  const bankAccounts = getFlatAccounts(baselaneConnectedAccounts);
  const getSelectedEntity = () => {
    const searchParams = new URLSearchParams(search);
    const bankId = searchParams.get('entityId');
    return bankAccounts.find((ba) => ba.bankId === bankId);
  };

  const title =
    step > 1 && accountOption ? `Create ${accountOption} account` : 'Add virtual account';

  const handleDrawerClose = () => {
    if (from) {
      const redirectUrl = generatePath(from, params);
      onDrawerClose(navigate, redirectUrl);
    } else {
      onDrawerClose(navigate, null);
    }
  };

  const onCloseWithoutSaving = () => {
    if (isDirty && step === 2) {
      onAlertOpen();
    } else {
      handleDrawerClose();
    }
  };

  const handleAlertContinueClick = () => {
    onAlertClose();
    handleDrawerClose();
  };

  useEffect(() => {
    sendSegmentEvent('baselane_banking_start_add_virtual_account');
  }, []);

  return (
    <ChakraProvider theme={habitatTheme}>
      <UnsavedChangesAlert
        isDrawerAlertOpen={isDrawerAlertOpen}
        onAlertClose={onAlertClose}
        onAlertOpen={onAlertOpen}
        onAlertContinue={handleAlertContinueClick}
      />

      <BaselaneDrawer
        isOpen
        newDesignDrawer
        newDrawerUseDefaultBodyFooter
        title={title}
        size={isMax767 ? 'newdrawerfull' : 'newdrawersm'}
        closeOnOverlayClick={false}
        closeEvent={onCloseWithoutSaving}
        onOverlayClick={onCloseWithoutSaving}
      >
        {formSuccess && accountOption && (
          <AccountSuccess
            accountOption={accountOption}
            newAccountId={newAccountId}
            closeEvent={handleDrawerClose}
            selectedBankAccount={getSelectedEntity()}
            from={from}
          />
        )}
        {!formSuccess && step > 1 && accountOption && (
          <CreateAccount
            accountOption={accountOption}
            setIsDirty={setIsDirty}
            backEvent={() => {
              setStep(1);
              setAccountOption(null);
              setIsDirty(false);
            }}
            successEvent={(id) => {
              setStep(3);
              setFormSuccess(true);
              setNewAccountId(id);
            }}
            selectedBankAccount={getSelectedEntity()}
          />
        )}
        {!formSuccess && step === 1 && (
          <AccountOptions
            selectOption={(option) => {
              setStep(2);
              setAccountOption(option);
            }}
            selectedBankAccount={getSelectedEntity()}
          />
        )}
      </BaselaneDrawer>
    </ChakraProvider>
  );
};

export default AddAccountNew;

AddAccountNew.defaultProps = {
  from: null,
};
