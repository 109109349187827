import React, { ReactElement } from 'react';
import { Box, Text } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import BaselaneIconLogo from '@core/assets/BaselaneIconLogo';
import {
  creditCardContainerStyles,
  creditCardPromotionalContainerStyles,
  creditCardIconStyles,
  creditCardTextStyles,
  creditCardAccountStyles,
  cardNumberEachDigitStyles,
  creditCardValueStyles,
  creditCardInTransferStyles,
  creditCardSubtitleLeftStyles,
  creditCardSubtitleRightStyles,
  creditCardWalletStyles,
  creditCardWalletThreadsStyles,
  creditCardWalletCircleStyles,
  creditCardWalletCircleThreadsStyles,
  creditCardWalletTitleStyles,
  creditCardWalletSubtitleStyles,
  creditCardGrayedOutStyles,
  creditCardGrayedOutWalletCircleStyles,
  creditCardSharpShadows,
} from './styles/creditCard.styles';

type BaselaneCreditCardProps = {
  fullName: String,
  accountNumber: String,
  value: Number,
  inTransfer: Number,
  icon: ReactElement,
  hasWallet: boolean,
  walletTitle: String,
  walletSubtitle: String,
  isGrayedOut: boolean,
  maskAllAccountNumbers: boolean,
  containerStyles?: Object,
  iconStyles: Object,
  textStyles: Object,
  accountStyles: Object,
  valueStyles: Object,
  inTransferStyles: Object,
  subtitleLeftStyles: Object,
  subtitleRightStyles: Object,
  walletStyles: Object,
  isPromotionalCard: Boolean,
};

function BaselaneCreditCard({
  fullName = '',
  accountNumber,
  value,
  inTransfer = null,
  icon = <BaselaneIconLogo color="white" />,
  hasWallet = false,
  walletTitle = null,
  walletSubtitle = null,
  isGrayedOut = false,
  maskAllAccountNumbers = false,
  containerStyles = null,
  iconStyles = null,
  textStyles = null,
  accountStyles = null,
  valueStyles = null,
  inTransferStyles = null,
  subtitleLeftStyles = null,
  subtitleRightStyles = null,
  walletStyles = null,
  isPromotionalCard = false,
  ...rest
}: BaselaneCreditCardProps): any {
  const outerContainerStyle = {
    ...(isPromotionalCard && creditCardSharpShadows),
    ...rest,
  };

  const creditCardContainerStyle = {
    ...(isPromotionalCard ? creditCardPromotionalContainerStyles : creditCardContainerStyles),
    ...containerStyles,
    ...(isGrayedOut ? creditCardGrayedOutStyles : null),
  };
  const creditCardIconStyle = { ...creditCardIconStyles, ...iconStyles };
  const creditCardTextStyle = { ...creditCardTextStyles, ...textStyles };
  const creditCardAccountStyle = { ...creditCardAccountStyles, ...accountStyles };
  const creditCardValueStyle = { ...creditCardValueStyles, ...valueStyles };
  const creditCardInTransferStyle = { ...creditCardInTransferStyles, ...inTransferStyles };
  const creditCardSubtitleLeftStyle = { ...creditCardSubtitleLeftStyles, ...subtitleLeftStyles };
  const creditCardSubtitleRightStyle = { ...creditCardSubtitleRightStyles, ...subtitleRightStyles };
  const creditCardWalletStyle = { ...creditCardWalletStyles, ...walletStyles };
  const creditCardWalletCircleStyle = {
    ...creditCardWalletCircleStyles,
    ...(isGrayedOut ? creditCardGrayedOutWalletCircleStyles : null),
  };

  const maskedAccountNumber = maskAllAccountNumbers
    ? accountNumber.replace(/\d(?=\d{0})/g, '•')
    : accountNumber.replace(/\d(?=\d{4})/g, '•');

  const getAccountNumber = () => {
    const accountNum = maskedAccountNumber;
    return accountNum.split('').map((number, index) => (
      <Box
        as="span"
        // eslint-disable-next-line react/no-array-index-key
        key={`${index}-${number}`}
        {...cardNumberEachDigitStyles}
        ml={(index === 3 || index === accountNum.length - 4) && '8px !important'}
      >
        {number}
      </Box>
    ));
  };

  return (
    <Box position="relative" {...outerContainerStyle}>
      <Box {...creditCardContainerStyle}>
        <Box {...creditCardIconStyle}>{icon}</Box>
        <Text {...creditCardTextStyle}>{fullName}</Text>
        <Text {...creditCardAccountStyle}>{getAccountNumber(accountNumber)}</Text>
        <Text {...creditCardValueStyle}>{formatCurrency(value).inDollars}</Text>
        {inTransfer > 0 && (
          <Text {...creditCardInTransferStyle}>{formatCurrency(inTransfer).inDollars}</Text>
        )}
        <Text {...creditCardSubtitleLeftStyle}>TOTAL BALANCE</Text>
        {inTransfer > 0 ? <Text {...creditCardSubtitleRightStyle}>IN TRANSFER</Text> : null}
      </Box>
      {hasWallet && (
        <Box {...creditCardWalletStyle}>
          <Box {...creditCardWalletThreadsStyles} />
          <Box {...creditCardWalletCircleStyle} />
          <Box {...creditCardWalletCircleThreadsStyles} />
          <Box {...creditCardWalletTitleStyles}>{walletTitle}</Box>
          <Box {...creditCardWalletSubtitleStyles}>{walletSubtitle}</Box>
        </Box>
      )}
    </Box>
  );
}

export default BaselaneCreditCard;
