import React from 'react';
import DisplayInputWrapperWithRightButton from '@shared/components/BaselaneDropdown-new/DisplayInputVariations/DisplayInputWrapperWithRightButton';
import DisplayInputDefaultPropertyItemContent from './DisplayInputDefaultPropertyItemContent';

type DisplayInputDefaultPropertyItemProps = {
  selectedOptions: Array<Object>,
  showValueByFields: Array<string>,
  handleContentRendererClick: Function,
  placeholder: string,
  title: string,
  handleClearClick: Function,
  classNames: Array<string>,
};

const DisplayInputDefaultPropertyItem = ({
  handleContentRendererClick,
  selectedOptions,
  placeholder,
  title,
  showValueByFields,
  handleClearClick,
  classNames,
}: DisplayInputDefaultPropertyItemProps) => {
  return (
    <DisplayInputWrapperWithRightButton
      handleContentRendererClick={handleContentRendererClick}
      handleClearClick={handleClearClick}
      selectedOptions={selectedOptions}
      classNames={classNames}
    >
      <DisplayInputDefaultPropertyItemContent
        selectedOptions={selectedOptions}
        placeholder={placeholder}
        title={title}
        showValueByFields={showValueByFields}
      />
    </DisplayInputWrapperWithRightButton>
  );
};

export default DisplayInputDefaultPropertyItem;
