import { useToast } from '@chakra-ui/react';

const toasts = (name) => {
  const toast = useToast();

  const showCreateLeaseSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: `${name}'s rent collection has been set up!`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showEditLeaseSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Rent Collection Terms Updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showDeleteLeaseSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Draft deleted successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showCreateLeaseErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const showEditLeaseErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Could Not Update Rent Collection Terms',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const showDeleteLeaseErrorToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Something went wrong. Please try again',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  const showLeaseReplacePropertyToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Saved setup replaced with current details',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showLeaseResumePropertyToast = () =>
    toast({
      position: 'bottom-left',
      description: 'Resumed with saved setup details',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  return {
    showCreateLeaseSuccessToast,
    showEditLeaseSuccessToast,
    showDeleteLeaseSuccessToast,
    showCreateLeaseErrorToast,
    showEditLeaseErrorToast,
    showDeleteLeaseErrorToast,
    showLeaseReplacePropertyToast,
    showLeaseResumePropertyToast,
  };
};

export default toasts;
