import React from 'react';
import { BaselaneTooltip } from '@shared/components';
import { IconLightningOutline } from '@icons';

const PromoTooltip = ({ text }: { text: String }) => {
  return (
    <BaselaneTooltip
      label={text}
      placement="auto"
      id="promo-tooltip"
      innerContainerStyles={{ marginLeft: '0' }}
    >
      <IconLightningOutline color="#0968BF" />
    </BaselaneTooltip>
  );
};

export default PromoTooltip;
