import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import PropertyAndCategoryTaggingForm from '@core/components/NativeBankingPage/MainContent/components/PropertyAndCategoryTaggingForm';
import {
  drawerLabelStyles,
  drawerSectionStyles,
} from '@core/components/NativeBankingPage/MainContent/components/Cards/CardsGrid/CardDetails/styles/cardDetailsDrawer.styles';

type TaggingDropdownsProps = {
  propertyOptions: Array<Object>,
  categoryOptions: Array<Object>,
  categoryIdsMap: Object,
  categoryMap: Object,
  setShowMobileDropdownPopup: Function,
  setShowMobileDropdownPopupAnimation: Function,
  showMobileDropdownPopup: Boolean,
  values: Object,
  handlePropertySubmit: Function,
  handleCategorySubmit: Function,
  isCardStatusLocked: boolean,
  bankAccountAutoTag: Object,
};

const TaggingDropdowns = ({
  propertyOptions,
  categoryOptions,
  categoryIdsMap,
  categoryMap,
  setShowMobileDropdownPopup,
  setShowMobileDropdownPopupAnimation,
  showMobileDropdownPopup,
  values,
  handlePropertySubmit,
  handleCategorySubmit,
  isCardStatusLocked,
  bankAccountAutoTag,
}: TaggingDropdownsProps) => {
  const formattedUnitId = bankAccountAutoTag?.propertyUnitId
    ? `-${bankAccountAutoTag.propertyUnitId}`
    : '';
  const propertyAndUnitId = bankAccountAutoTag?.propertyId
    ? `${bankAccountAutoTag?.propertyId}${formattedUnitId}`
    : '';
  const propertySelectedItem = propertyOptions?.find((pm) => pm.id === propertyAndUnitId) ?? null;

  return (
    <Box {...drawerSectionStyles}>
      <PropertyAndCategoryTaggingForm
        showFieldsOnly
        {...{
          handlePropertySubmit,
          handleCategorySubmit,
          propertyOptions,
          propertyOptionsTooltipText:
            'All transactions for this card will be automatically tagged to the selected property to streamline bookkeeping. You can change the property at any time for future transactions.',
          categoryOptions,
          categoryIdsMap,
          categoryOptionsTooltipText:
            'All transactions for this card will be automatically tagged to the selected category to streamline bookkeeping. You can change the category at any time for future transactions.',
          categoryMap,
          setShowMobileDropdownPopup,
          setShowMobileDropdownPopupAnimation,
          showMobileDropdownPopup,
          isCategoryDropdownDisabled: isCardStatusLocked,
          isPropertyDropdownDisabled: isCardStatusLocked,
          values,
          parentId: 'tagging',
          elementBelowPropertyDropdown: propertySelectedItem && (
            <Text {...drawerLabelStyles} mt="8px">
              Linked banking account has auto-tagging to {propertySelectedItem.showValueName}; you
              can override tagging above.
            </Text>
          ),
        }}
      />
    </Box>
  );
};

export default TaggingDropdowns;
