// @flow
import * as React from 'react';
import { Box } from '@chakra-ui/react';
import IconChevronRight from '@icons/legacy/IconChevronRight';
import customTheme from '@core/theme';

import { bannerChevronStyles } from '../styles/baselaneResponsiveBanner.style';

const BannerChevron = () => {
  return (
    <Box {...bannerChevronStyles}>
      <IconChevronRight
        {...{
          w: '5',
          h: '9',
          color: customTheme.colors.brand.neutral['600'],
        }}
      />
    </Box>
  );
};

export default BannerChevron;
