export const filterWrapperHeaderStyles = { p: '24px 16px 8px', mb: '0', alignItems: 'center' };

export const filterByTextStyles = {
  textStyle: 'headline-xs',
  color: 'brand.neutral.500',
  textTransform: 'uppercase',
};

export const footerContainerStyles = {
  boxShadow: '-24px 0px 64px rgba(4, 39, 101, 0.08)',
  borderRadius: '0px 0px 4px 4px',
  p: '8px 16px',
  minH: '48px',
};

export const footerSelectedTextStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
};
