import { gql } from '@apollo/client';

export const FRAGMENT_RECEIVING_BA = gql`
  fragment ReceivingBankAccounts on Lease {
    depositBankAccountId
    rentAndFeesBankAccountId
  }
`;

export const FRAGMENT_INVOICE = gql`
  fragment Invoices on LeaseInvoice {
    id
    state
    dueDate
    originalDueDate
    amount
    payoutAmount
    name
    leaseId
    type
    description
    editable
    isHidden
    leaseTenantProfileId
    isQuickPay
    destinationBankAccount {
      id
      accountNumber
      nickName
      accountName
      accountType
      accountSubType
      accountStatus
      institutionName
    }
    propertyUnit {
      unitId: id
      unitName: name
      propertyId
      propertyName
      propertyAddress: address
      lease {
        id
        invoiceProcessingFeePaidBy
        invoiceProcessingFeePaidByOverridden
      }
    }
    applicationFeeChargeToLandlord
    invoiceCharge {
      status
      paymentDate
      payoutStatus
      paymentTriggerType
      paymentMethod {
        id
        paymentType
      }
      metadata {
        applicationFeeWaivedChargedReason
      }
    }
    invoiceNumber
    invoiceStateChangeDate
    invoiceMarkedPaid
    invoiceMarkedPaidTimestamp
    items {
      itemName
      description
      dueDate
      amount
      paymentName
      leaseId
    }
    lateFeeDetails {
      lateFeeAmount
      lateFeeCharged
    }
    timeline {
      date
      isError
      isExpandable
      isImportant
      metadata
      state
      status
    }
  }
`;

export const FRAGMENT_PAYMENTS = gql`
  fragment Payments on Payment {
    id
    name
    description
    dueDate
    amount
    dueDays
    enabled
    leaseId
    frequency
    category
    paid
  }
`;

export const GET_LEASE_DETAIL = gql`
  query LeaseDetail {
    leaseDetail {
      isEmpty
      summary {
        activeLease
      }
      property {
        id
        name
        address
        description
        units {
          id
          name
          description
          status
          rentStatus
          isUnitDeletable
          archivedLease {
            id
            propertyUnitId
            tenantProfileId
            tenantProfileMetadata
            leaseType
            startDate
            rentCollectionStartDate
            endDate
            cancellationNotice
            state
            paymentState
            latefee
            totalOverdueAmount
            payments {
              ...Payments
            }
            invoice {
              ...Invoices
            }
            completedTags
            ...ReceivingBankAccounts
            archived
          }
          lease {
            id
            propertyUnitId
            tenantProfileId
            tenantProfileMetadata
            leaseType
            startDate
            rentCollectionStartDate
            endDate
            cancellationNotice
            state
            paymentState
            tenantAutoPayEnrolled
            latefee
            totalOverdueAmount
            ...ReceivingBankAccounts
            completedTags
            payments {
              ...Payments
            }
            invoice {
              ...Invoices
            }
            completedTags
            archived
          }
        }
      }
    }
  }
  ${FRAGMENT_RECEIVING_BA}
  ${FRAGMENT_PAYMENTS}
  ${FRAGMENT_INVOICE}
`;

export const GET_LEASE = gql`
  query Leases {
    leaseDetail {
      isEmpty
      summary {
        activeLease
      }
      property {
        id
        name
        address
        description
        units {
          id
          name
          description
          status
          rentStatus
          isUnitDeletable
          archivedLease {
            id
            propertyUnitId
            tenantProfileId
            tenantProfileMetadata
            leaseType
            startDate
            rentCollectionStartDate
            endDate
            cancellationNotice
            state
            paymentState
            latefee
            totalOverdueAmount
            payments {
              ...Payments
            }
            completedTags
            ...ReceivingBankAccounts
            archived
          }
          lease {
            id
            propertyUnitId
            tenantProfileId
            tenantProfileMetadata
            leaseType
            startDate
            rentCollectionStartDate
            endDate
            cancellationNotice
            state
            paymentState
            tenantAutoPayEnrolled
            latefee
            totalOverdueAmount
            ...ReceivingBankAccounts
            completedTags
            newLease
            rentAndFeesBankAccount {
              id
              accountNumber
              nickName
              accountName
              accountType
              accountSubType
              updatedAt
              currentBalance
              availableBalance
              routingNumber
              isConnected
              revokedByUser
              rentCollectionSupported
              transferSupported
              transactionSupported
              isNewlyAdded
              connectionState
              closed
              accountStatus
              isExternal
              institutionName
            }
            depositBankAccount {
              id
              accountNumber
              nickName
              accountName
              accountType
              accountSubType
              updatedAt
              currentBalance
              availableBalance
              routingNumber
              isConnected
              revokedByUser
              rentCollectionSupported
              transferSupported
              transactionSupported
              isNewlyAdded
              connectionState
              closed
              accountStatus
              isExternal
              institutionName
            }
            payments {
              ...Payments
            }
            completedTags
            archived
          }
        }
      }
    }
  }
  ${FRAGMENT_RECEIVING_BA}
  ${FRAGMENT_PAYMENTS}
`;

export const GET_PROPERTIES_AND_LEASES = gql`
  query Leases($input: LeaseFilter!) {
    leaseDetail {
      isEmpty
    }
    property {
      id
      name
      address
      description
      units {
        id
        name
        description
        status
        rentStatus
        isUnitDeletable
      }
    }
    leases(input: $input) {
      leases {
        id
        propertyUnitId
        tenantProfileId
        tenantProfileMetadata
        leaseType
        startDate
        rentCollectionStartDate
        endDate
        cancellationNotice
        state
        paymentState
        tenantAutoPayEnrolled
        latefee
        totalOverdueAmount
        ...ReceivingBankAccounts
        completedTags
        newLease
        rentAndFeesBankAccount {
          id
          accountNumber
          nickName
          accountName
          accountType
          accountSubType
          updatedAt
          currentBalance
          availableBalance
          routingNumber
          isConnected
          revokedByUser
          rentCollectionSupported
          transferSupported
          transactionSupported
          isNewlyAdded
          connectionState
          closed
          accountStatus
          isExternal
          institutionName
        }
        depositBankAccount {
          id
          accountNumber
          nickName
          accountName
          accountType
          accountSubType
          updatedAt
          currentBalance
          availableBalance
          routingNumber
          isConnected
          revokedByUser
          rentCollectionSupported
          transferSupported
          transactionSupported
          isNewlyAdded
          connectionState
          closed
          accountStatus
          isExternal
          institutionName
        }
        payments {
          ...Payments
        }
        completedTags
        archived
      }
    }
  }
  ${FRAGMENT_RECEIVING_BA}
  ${FRAGMENT_PAYMENTS}
`;

export const GET_LEASES_DRAFT_DATA = gql`
  query Leases($input: LeaseFilter!) {
    leases(input: $input) {
      totalCount
      leases {
        id
        propertyUnitId
      }
    }
  }
`;

export const GET_LEASES = gql`
  query Leases($input: LeaseFilter!) {
    leaseDetail {
      isEmpty
    }
    leases(input: $input) {
      totalCount
      leases {
        id
        state
        startDate
        endDate
        rentCollectionStartDate
        propertyName
        propertyAddress
        propertyUnitName
        propertyUnitId
        tenantProfileId
        leaseType
        paymentState
        totalUnits
        rentAmount
        isQuickPay
        invoiceProcessingFeePaidBy
        invoiceProcessingFeePaidByOverridden
        rentAndFeesBankAccount {
          accountNumber
          accountName
          accountType
          accountSubType
          isExternal
          institutionName
        }
        rentAndFeesBankAccountId
        rentAndFeesBankAccount {
          isExternal
        }
      }
    }
  }
`;

export const GET_LEASE_DETAIL_BASIC = gql`
  query Leases {
    leaseDetail {
      isEmpty
    }
  }
`;

export const GET_SINGLE_LEASE = gql`
  query Leases($input: LeaseFilter!) {
    leases(input: $input) {
      leases {
        id
        state
        startDate
        endDate
        propertyName
        propertyAddress
        propertyUnitName
        propertyUnitId
        tenantProfileId
        tenantProfileMetadata
        tenantAutoPayEnrolled
        invoiceProcessingFeePaidBy
        invoiceProcessingFeePaidByOverridden
        isQuickPay
        tenantProfile {
          id
          tenantId
          status
          inviteLink
          landlordInfo
          email
          firstName
          lastName
          phoneNumber
        }
        leaseType
        rentAndFeesBankAccountId
        depositBankAccountId
        rentAndFeesBankAccount {
          id
          accountNumber
          nickName
          accountName
          accountType
          accountSubType
          updatedAt
          currentBalance
          availableBalance
          routingNumber
          isConnected
          revokedByUser
          rentCollectionSupported
          transferSupported
          transactionSupported
          isNewlyAdded
          connectionState
          closed
          accountStatus
          isExternal
          institutionName
        }
        depositBankAccount {
          id
          accountNumber
          nickName
          accountName
          accountType
          accountSubType
          updatedAt
          currentBalance
          availableBalance
          routingNumber
          isConnected
          revokedByUser
          rentCollectionSupported
          transferSupported
          transactionSupported
          isNewlyAdded
          connectionState
          closed
          accountStatus
          isExternal
          institutionName
        }
        rentCollectionStartDate
        cancellationNotice
        paymentState
        totalUnits
        latefee
        totalOverdueAmount
        completedTags
        newLease
        invoiceDetails {
          invoiceOverdueSummary {
            count
            amount
          }
          invoiceFailedSummary {
            count
            amount
          }
          invoiceProcessingSummary {
            count
            amount
          }
          nextPayment {
            id
            state
            name
            leaseId
            dueDate
            amount
          }
          lastCompletedInvoice {
            id
            state
            name
            amount
            leaseId
            invoiceCharge {
              payoutArrivalDate
            }
          }
        }
        payments {
          ...Payments
        }
        archived
      }
    }
  }
  ${FRAGMENT_PAYMENTS}
`;

export const GET_LEASE_ACH_FEE = gql`
  query Leases($input: LeaseFilter!) {
    leases(input: $input) {
      leases {
        id
        totalUnits
        propertyName
        propertyUnitName
        invoiceProcessingFeePaidBy
        invoiceProcessingFeePaidByOverridden
        tenantProfile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_LEASE_RECEIVING_BA_ACH = gql`
  query Leases($input: LeaseFilter!) {
    leases(input: $input) {
      leases {
        id
        totalUnits
        propertyName
        propertyUnitName
        rentAndFeesBankAccount {
          id
          accountName
          nickName
        }
      }
    }
  }
`;

export const GET_LEASE_METADATA = gql`
  query LeaseMetaData {
    leaseMetaData {
      leaseCollectingRentOutsideBaselane
    }
  }
`;

export const GET_INVOICE_LIST = gql`
  query InvoiceList($input: InvoiceListInput!) {
    invoiceList(input: $input) {
      invoices {
        ...Invoices
      }
      count
      amount
    }
  }
  ${FRAGMENT_INVOICE}
`;

export const GET_INVOICE_SUMMARY = gql`
  query InvoiceSummary {
    invoiceSummary {
      invoiceCompletedSummary {
        amount
        count
      }
      invoiceFailedSummary {
        amount
        count
      }
      invoiceOutstandingSummary {
        amount
        count
      }
      invoiceOverdueSummary {
        amount
        count
      }
      invoiceProcessingSummary {
        amount
        count
      }
      payoutFailedBankAccounts {
        id
        nickName
        payoutFailedInvoice {
          count
          amount
        }
      }
    }
  }
`;

export const CREATE_OR_UPDATE_LEASE = gql`
  mutation createOrUpdateLease(
    $id: ID
    $propertyUnitId: ID!
    $tenantProfileId: ID
    $leaseType: LeaseType
    $startDate: String
    $rentCollectionStartDate: String
    $endDate: String
    $payments: [PaymentInput]
    $cancellationNotice: Int
    $invoicesConfirmed: Boolean
    $rentAndFeesBankAccount: LeaseBankAccountInput
    $depositBankAccount: LeaseBankAccountInput
    $action: LandLordLeaseAction
    $tenantProfileMetadata: JSON
  ) {
    createOrUpdateLease(
      input: {
        id: $id
        propertyUnitId: $propertyUnitId
        tenantProfileId: $tenantProfileId
        leaseType: $leaseType
        startDate: $startDate
        rentCollectionStartDate: $rentCollectionStartDate
        endDate: $endDate
        payments: $payments
        cancellationNotice: $cancellationNotice
        invoicesConfirmed: $invoicesConfirmed
        rentAndFeesBankAccount: $rentAndFeesBankAccount
        depositBankAccount: $depositBankAccount
        action: $action
        tenantProfileMetadata: $tenantProfileMetadata
      }
    ) {
      id
      propertyUnitId
      tenantProfileId
      tenantProfileMetadata
      tenantProfile {
        id
        tenantId
        status
        inviteLink
        landlordInfo
        email
      }
      leaseType
      startDate
      rentCollectionStartDate
      endDate
      ...ReceivingBankAccounts
      completedTags
      state
      tenantProfile {
        inviteLink
      }
      rentAndFeesBankAccount {
        id
        accountNumber
        nickName
        accountName
        accountType
        accountSubType
        updatedAt
        currentBalance
        availableBalance
        routingNumber
        isConnected
        revokedByUser
        rentCollectionSupported
        transferSupported
        transactionSupported
        isNewlyAdded
        connectionState
        closed
        accountStatus
        isExternal
        institutionName
      }
      depositBankAccount {
        id
        accountNumber
        nickName
        accountName
        accountType
        accountSubType
        updatedAt
        currentBalance
        availableBalance
        routingNumber
        isConnected
        revokedByUser
        rentCollectionSupported
        transferSupported
        transactionSupported
        isNewlyAdded
        connectionState
        closed
        accountStatus
        isExternal
        institutionName
      }
      invoice {
        id
        name
        amount
        dueDate
        timeline {
          date
          isError
          isExpandable
          isImportant
          metadata
          state
          status
        }
      }
      payments {
        id
        name
        description
        dueDate
        dueDays
        leaseId
        frequency
        paid
        amount
        enabled
      }
    }
  }
  ${FRAGMENT_RECEIVING_BA}
`;

export const DELETE_LEASE = gql`
  mutation deleteLease($id: ID!) {
    deleteLease(id: $id)
  }
`;

export const DELETE_INVOICE = gql`
  mutation deleteInvoice($id: ID!) {
    deleteInvoice(id: $id) {
      id
      state
      leaseId
      timeline {
        date
        isError
        isExpandable
        isImportant
        metadata
        state
        status
      }
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation manualInvoice(
    $leaseId: ID!
    $dueDate: String!
    $items: [AddInvoiceItemsInput]
    $destinationBankAcctId: ID
  ) {
    createInvoice(
      input: {
        leaseId: $leaseId
        dueDate: $dueDate
        items: $items
        destinationBankAcctId: $destinationBankAcctId
      }
    ) {
      id
      state
      dueDate
      amount
      leaseId
      type
      description
      editable
      invoiceStateChangeDate
      items {
        itemName
        description
        dueDate
        amount
        paymentName
        leaseId
      }
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation updateInvoice($id: ID, $dueDate: String, $items: [InvoiceItems]) {
    updateInvoice(input: { id: $id, dueDate: $dueDate, items: $items }) {
      id
      state
      dueDate
      amount
      payoutAmount
      leaseId
      type
      description
      editable
      invoiceStateChangeDate
      invoiceMarkedPaid
      invoiceMarkedPaidTimestamp
      invoiceStateChangeDate
      isHidden
      leaseTenantProfileId
      originalDueDate
      propertyUnit {
        unitId: id
        unitName: name
        propertyId
        propertyName
        propertyAddress: address
      }
      invoiceCharge {
        status
        paymentDate
        payoutStatus
        paymentTriggerType
      }
      lateFeeDetails {
        lateFeeAmount
        lateFeeCharged
      }
      items {
        itemName
        description
        dueDate
        amount
        paymentName
        leaseId
      }
      timeline {
        date
        isError
        isExpandable
        isImportant
        metadata
        state
        status
      }
    }
  }
`;

export const MARK_AS_PAID = gql`
  mutation markInvoiceAsPaid($id: ID!) {
    markInvoiceAsPaid(id: $id) {
      id
      state
      dueDate
      amount
      payoutAmount
      leaseId
      type
      description
      editable
      invoiceStateChangeDate
      invoiceMarkedPaid
      invoiceMarkedPaidTimestamp
      invoiceStateChangeDate
      isHidden
      leaseTenantProfileId
      originalDueDate
      destinationBankAccount {
        id
        accountNumber
        nickName
        accountName
        accountType
        accountSubType
        accountStatus
        institutionName
      }
      propertyUnit {
        unitId: id
        unitName: name
        propertyId
        propertyName
        propertyAddress: address
      }
      invoiceCharge {
        status
        paymentDate
        payoutStatus
        paymentTriggerType
      }
      lateFeeDetails {
        lateFeeAmount
        lateFeeCharged
      }
      items {
        itemName
        description
        dueDate
        amount
        paymentName
        leaseId
      }
      timeline {
        date
        isError
        isExpandable
        isImportant
        metadata
        state
        status
      }
    }
  }
`;

export const ARCHIVE_LEASE = gql`
  mutation archiveLease($id: ID!) {
    archiveLease(id: $id) {
      id
    }
  }
`;

export const MODIFY_LEASE = gql`
  mutation modifyLease($id: ID!, $input: ModifyLeaseInput) {
    modifyLease(id: $id, input: $input) {
      id
      state
      startDate
      endDate
      propertyName
      propertyAddress
      propertyUnitName
      propertyUnitId
      tenantProfileId
      tenantProfileMetadata
      tenantAutoPayEnrolled
      tenantProfile {
        id
        tenantId
        status
        inviteLink
        landlordInfo
        email
        firstName
        lastName
        phoneNumber
      }
      leaseType
      rentAndFeesBankAccountId
      depositBankAccountId
      rentCollectionStartDate
      cancellationNotice
      paymentState
      totalUnits
      latefee
      totalOverdueAmount
      completedTags
      newLease
      rentAndFeesBankAccount {
        id
        accountNumber
        nickName
        accountName
        accountType
        accountSubType
        updatedAt
        currentBalance
        availableBalance
        routingNumber
        isConnected
        revokedByUser
        rentCollectionSupported
        transferSupported
        transactionSupported
        isNewlyAdded
        connectionState
        closed
        accountStatus
        isExternal
        institutionName
      }
      depositBankAccount {
        id
        accountNumber
        nickName
        accountName
        accountType
        accountSubType
        updatedAt
        currentBalance
        availableBalance
        routingNumber
        isConnected
        revokedByUser
        rentCollectionSupported
        transferSupported
        transactionSupported
        isNewlyAdded
        connectionState
        closed
        accountStatus
        isExternal
        institutionName
      }
      invoiceDetails {
        invoiceOverdueSummary {
          count
          amount
        }
        invoiceFailedSummary {
          count
          amount
        }
        invoiceProcessingSummary {
          count
          amount
        }
        nextPayment {
          id
          state
          name
          leaseId
          dueDate
          amount
        }
        lastCompletedInvoice {
          id
          state
          name
          amount
          leaseId
          invoiceCharge {
            payoutArrivalDate
          }
        }
      }
      payments {
        ...Payments
      }
      archived
    }
  }
  ${FRAGMENT_PAYMENTS}
`;
