import customTheme from '@core/theme';

export const inputRightElementStyles = {
  w: 'auto',
  color: customTheme.colors.brand.neutral['500'],
  fontSize: 'xs',
  lineHeight: '24px',
  p: '16px',
  h: '100%',
};

export const spinnerStyles = {
  color: customTheme.colors.brand.darkBlue['150'],
  w: '16px',
  h: '16px',
};
