import React from 'react';
import {
  IconCelebration,
  IconCheckCircleOutline,
  IconClock,
  IconExclamationCircle,
  IconExclamationTriangle,
  IconMoneyCircle,
  IconChat,
  IconUpload,
  IconChevronRight,
} from '@icons';
import customTheme from '@core/theme';
import {
  HOME,
  LEASES,
  NATIVE_BANK,
  TRANSFERS_PAYMENTS,
  UNIFIED_RENT_COLLECTION,
  USER_PROFILE_UPDATE_ACCOUNTS,
} from '@routes';
import BaselaneIconLogo from '@core/assets/BaselaneIconLogo';

export const successConfig = (isOnboardingCompleted, fromState) => {
  const mapBtnText = {
    [UNIFIED_RENT_COLLECTION]: 'Back to rent collection settings',
    [LEASES]: 'Back to update receiving account',
    [USER_PROFILE_UPDATE_ACCOUNTS]: 'Back to rent collection settings',
  };

  const mapBtnSubText = {
    [UNIFIED_RENT_COLLECTION]: 'Back to rent collection',
  };

  const mapEntryPoint = {
    [UNIFIED_RENT_COLLECTION]: 'rent_collection',
  };

  const stateRightBtn = {
    text: mapBtnText[fromState],
    subText: mapBtnSubText[fromState],
    rightIcon: <IconChevronRight />,
    pathname: fromState,
  };

  const segmentEventEntryPoint = mapEntryPoint?.[fromState] ?? '';

  const showBtnWithStateDetails =
    fromState &&
    (fromState.includes(UNIFIED_RENT_COLLECTION) ||
      fromState.includes(LEASES) ||
      fromState.includes(USER_PROFILE_UPDATE_ACCOUNTS));

  const bankingRedirectionPage = fromState?.includes(TRANSFERS_PAYMENTS)
    ? `/${TRANSFERS_PAYMENTS}`
    : NATIVE_BANK;
  const backToBankingText = fromState?.includes(TRANSFERS_PAYMENTS)
    ? 'Return to Transfers & Payments'
    : 'Explore Baselane Banking';

  return {
    EMPTY_STATE: {
      icon: null,
      subText: null,
      title: 'Continue to explore Baselane',
      description:
        'Your Baselane Visa Debit Card will be delivered to your home address within 10 business days.',
      leftBtn: showBtnWithStateDetails
        ? null
        : {
            text: backToBankingText,
            pathname: bankingRedirectionPage,
            segmentEvent: null,
          },
      rightBtn: showBtnWithStateDetails
        ? {
            ...stateRightBtn,
            segmentEvent: null,
            segmentEventAttributes: null,
          }
        : {
            leftIcon: <IconMoneyCircle color={customTheme.colors.brand.neutral.white} />,
            text: 'Fund your account',
            subText: 'Add funds',
            pathname: NATIVE_BANK,
            segmentEvent: null,
          },
      segmentEventOnLoad: null,
    },
    COMPLETED: {
      icon: <IconCheckCircleOutline w={40} h={40} color={customTheme.colors.green['800AA']} />,
      subText: 'All set!',
      title: 'Your account is approved',
      description:
        'Your Baselane Visa Debit Card will be delivered to your home address within 10 business days.',
      leftBtn: showBtnWithStateDetails
        ? null
        : {
            text: backToBankingText,
            pathname: bankingRedirectionPage,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_approved_click_explore_banking`,
          },
      rightBtn: showBtnWithStateDetails
        ? {
            ...stateRightBtn,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_approved_click_primary`,
            segmentEventAttributes: {
              action: stateRightBtn.text,
              entry_point: segmentEventEntryPoint,
            },
          }
        : {
            leftIcon: <IconMoneyCircle color={customTheme.colors.brand.neutral.white} />,
            text: 'Fund your account',
            pathname: NATIVE_BANK,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_approved_click_add_funds`,
          },
      segmentEventOnLoad: `${
        isOnboardingCompleted ? 'baselane' : 'onboarding'
      }_banking_approved_pageload`,
    },
    COMPLETED_B: {
      icon: <IconCelebration />,
      title: `Your account is approved`,
      description:
        'Your Baselane Visa Debit Card will be delivered to your home address within 10 business days.',
      leftBtn: showBtnWithStateDetails
        ? null
        : {
            text: backToBankingText,
            pathname: bankingRedirectionPage,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_approved_click_explore_banking`,
          },
      rightBtn: showBtnWithStateDetails
        ? {
            ...stateRightBtn,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_approved_click_primary`,
            segmentEventAttributes: {
              action: stateRightBtn.text,
              entry_point: segmentEventEntryPoint,
            },
          }
        : {
            leftIcon: <IconMoneyCircle color={customTheme.colors.brand.neutral.white} />,
            text: 'Fund your account',
            pathname: NATIVE_BANK,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_approved_click_add_funds`,
          },
      segmentEventOnLoad: `${
        isOnboardingCompleted ? 'baselane' : 'onboarding'
      }_banking_approved_pageload`,
    },
    DENIED: {
      icon: <IconExclamationCircle w={40} h={40} color={customTheme.colors.red['500A']} />,
      subText: 'We’re sorry!',
      title: 'Application denied',
      description:
        'Based on the information provided, we are unable to open a Baselane Banking account at this time. ',
      leftBtn: showBtnWithStateDetails
        ? {
            text: 'Close',
            pathname: HOME,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_denied_click_secondary`,
            segmentEventAttributes: {
              action: 'Close',
              entry_point: segmentEventEntryPoint,
            },
          }
        : {
            text: 'Back to dashboard',
            pathname: HOME,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_approved_click_back_dashboard`,
          },
      rightBtn: showBtnWithStateDetails
        ? {
            ...stateRightBtn,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_denied_click_primary`,
            segmentEventAttributes: {
              action: stateRightBtn.text,
              entry_point: segmentEventEntryPoint,
            },
          }
        : {
            leftIcon: <IconChat w={20} h={21} />,
            text: 'Contact support',
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_denied_click_contact_support`,
          },
      segmentEventOnLoad: `${
        isOnboardingCompleted ? 'baselane' : 'onboarding'
      }_banking_denied_pageload`,
    },
    IN_PROGRESS: {
      icon: <IconClock w={40} h={40} color={customTheme.colors.brand.darkBlue['250']} />,
      subText: 'Almost there!',
      title: 'Your application is in review',
      description:
        'It may take up to 2 business hours to review.  We’ll send updates to your email.',
      leftBtn: showBtnWithStateDetails
        ? {
            text: 'Close',
            pathname: HOME,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_pendingreview_click_secondary`,
            segmentEventAttributes: {
              action: 'Close',
              entry_point: segmentEventEntryPoint,
            },
          }
        : null,
      rightBtn: showBtnWithStateDetails
        ? {
            ...stateRightBtn,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_pendingreview_click_primary`,
            segmentEventAttributes: {
              action: stateRightBtn.text,
              entry_point: segmentEventEntryPoint,
            },
          }
        : {
            leftIcon: <BaselaneIconLogo />,
            text: 'Explore baselane',
            pathname: HOME,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_approved_click_explore_baselane`,
          },
      segmentEventOnLoad: `${
        isOnboardingCompleted ? 'baselane' : 'onboarding'
      }_banking_pendingreview_pageload`,
    },
    AWAITING_DOCUMENTS: {
      icon: (
        <IconExclamationTriangle w={40} h={40} color={customTheme.colors.brand.darkBlue['250']} />
      ),
      subText: 'Almost there!',
      title: 'Additional documents required',
      description:
        'As a final step, we require you to upload documents to approve your application.',
      leftBtn: showBtnWithStateDetails
        ? {
            text: mapBtnText[fromState],
            pathname: fromState,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_awaitingdocuments_click_secondary`,
            segmentEventAttributes: {
              action: mapBtnText[fromState],
              entry_point: segmentEventEntryPoint,
            },
          }
        : {
            text: 'Do it later',
            pathname: HOME,
            segmentEvent: `${
              isOnboardingCompleted ? 'baselane' : 'onboarding'
            }_banking_documentsrequired_click_do_later`,
          },
      rightBtn: {
        leftIcon: <IconUpload w={20} h={20} />,
        text: 'Upload documents',
        segmentEvent: `${
          isOnboardingCompleted ? 'baselane' : 'onboarding'
        }_banking_documentsrequired_click_upload_documents`,
        segmentEventAttributes: {
          action: 'Upload documents',
          entry_point: segmentEventEntryPoint,
        },
      },
      segmentEventOnLoad: `${
        isOnboardingCompleted ? 'baselane' : 'onboarding'
      }_banking_documentsrequired_pageload`,
    },
  };
};
