export const titleTextStyles = {
  lineHeight: { base: '26px', md: '32px' },
  fontWeight: 'semibold',
  fontSize: { base: 'lg', md: '2xl' },
  color: 'brand.neutral.700',
  p: '0',
  mt: '2px',
  ml: '8px!important',
};

export const iconBgStyles = { bg: 'brand.blue.100' };

export const iconContainerStyles = {
  ml: '-1px',
  mt: '-1px',
  color: 'red.500A',
};

export const alertContainerStyles = {
  p: { base: '32px 16px 24px 16px', md: '56px 32px 32px 32px' },
  m: '6px',
  justifyContent: 'left',
  borderRadius: '12px',
  w: 'fit-content !important',
  maxW: { base: '348px', md: '100%' },
};

export const alertHeaderStyles = {
  fontSize: 'lg',
  lineHeight: '26px',
  fontWeight: 'semibold',
  color: 'brand.neutral.700',
  pl: '0',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  mt: '0px !important',
};

export const bodyStyles = {
  p: '0',
  m: '16px 0 0',
  fontSize: 'sm',
  lineHeight: '20px',
  fontWeight: 'normal',
  color: 'brand.neutral.600',
};

export const closeButtonStyles = {
  border: '0px',
  top: { base: '0px !important', md: '16px !important' },
  right: { base: '0px !important', md: '16px !important' },
  width: { base: '10px !important', md: '14px !important' },
  height: { base: '10px !important', md: '14px !important' },
  minWidth: '10px !important',
  padding: { base: '16px !important', md: '16px !important' },
  boxSizing: 'content-box !important',
  _active: { bg: 'transparent !important' },
  _hover: { background: 'transparent !important' },
};

export const headerContainerStyles = {
  justifyContent: 'flex-start',
  alignItems: 'center',
  w: '100%',
};

export const headerIconContainerStyles = {
  borderRadius: '50%',
  padding: '6px',
  bg: 'red.200',
};

export const buttonContainerStyles = {
  flexDirection: { base: 'column', md: 'row' },
  justifyContent: 'flex-end',
  alignItems: 'center',
  w: '100%',
  mt: { base: '16px !important', md: '32px !important' },
};

export const cancelButtonStyles = {
  order: { base: '2', md: '1' },
  w: { base: '100%', md: 'auto' },
  m: { base: '8px 0 0 0 !important', md: '0 !important' },
  borderRadius: '4px',
};

export const confirmButtonStyles = {
  order: { base: '1', md: '2' },
  w: { base: '100%', md: 'auto' },
  m: { base: '0 !important', md: '0 0 0 8px !important' },
  borderRadius: '4px',
};
