import { boldedText } from '@core/components/Shared/styles/text.style';

// Shared (Mobile + Desktop) Styles
export const drawerStyles = (isMobile) => {
  return {
    p: isMobile ? '13px 24px 48px 24px' : '42px 74px',
    bgColor: 'brand.neutral.white',
    mb: { base: '25%', sm: '25%', md: '25%', lg: 0, xl: 0 }, // In mobile and tablet, browser's bottom bar overlaps the drawer. So, we need to add some margin to the bottom of the drawer.
  };
};

export const titleStyles = {
  textStyle: 'headline-lg',
  mt: '13px',
  mb: '16px',
};

export const questionStyles = (isMobile, isFirstQuestion) => {
  return {
    fontSize: !isMobile && isFirstQuestion ? 'md' : 'sm',
    lineHeight: '24px',
    color: 'brand.neutral.700',
    mb: !isMobile && '4px !important',
    ...boldedText,
  };
};

export const answerStyles = (isMobile) => {
  return {
    fontSize: isMobile ? 'xs' : 'sm',
    lineHeight: '20px',
    color: 'brand.neutral.600',
    m: '0 !important',
  };
};

export const orderedListStyles = (isMobile) => {
  return {
    my: '24px !important',
    pl: '20px',
    lineHeight: isMobile ? '20px' : '25px',
    color: 'brand.neutral.700',
    fontSize: isMobile ? 'xs' : 'sm',
  };
};

export const checkListStyles = (isMobile) => {
  return {
    mt: '24px !important',
    pl: isMobile ? '0' : '24px',
    lineHeight: '20px',
    color: 'brand.neutral.700',
  };
};

export const checkListItemStyles = {
  display: 'flex',
  flexDir: 'row',
  alignItems: 'flex-start',
};

export const checkedListItemIconContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  h: '20px',
};

export const checkListItemTextContainerStyles = {
  pl: '8px',
  flex: '1',
};

export const checkListItemBoldStyles = {
  ...boldedText,
};

export const dividerStyles = {
  my: '24px !important',
};

export const additionalTipsContainerStyles = {
  lastItem: {
    my: '48px !important',
  },
  midItem: {
    m: '48px 0 0 0 !important',
  },
};

export const additionalTipsStyles = {
  textStyle: 'headline-md',
  color: 'brand.neutral.700',
};

export const bannerContainerStyles = {
  p: '8px',
  bgColor: 'brand.blue.50',
  borderRadius: '4px',
  m: '0 !important',
};

export const bannerMessageStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.600',
  ml: '12px !important',
  flex: '1',
};

export const bannerTitleStyles = {
  ...boldedText,
};

// Mobile Styles
export const mobileDividerStyles = (key, pairsLength, isExpanded) => {
  const isLastItem = Number(key) === pairsLength;

  return {
    m: isLastItem && !isExpanded ? '0 0 32px !important' : '0 !important',
    borderColor: 'brand.darkBlue.100',
    display: isLastItem && isExpanded && 'none',
  };
};

export const mobileAccordionWrapperStyles = {
  my: '0px',
};

export const mobileAccordionItemStyles = {
  border: 'none',
};

export const mobileAccordionButtonStyles = {
  h: 'auto',
  padding: '16px 0',
  background: 'brand.neutral.white',
  justifyContent: 'space-between',
  textAlign: 'left',
  boxShadow: 'none !important',
  _hover: {
    background: 'brand.neutral.white',
  },
};

export const mobileAccordionIconContainerStyles = {
  m: '0 3px 0 24px !important',
};

export const mobileAccordionContentStyles = {
  border: 'none',
  p: '0 0 24px',
};
