import React from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { TabList } from '@chakra-ui/react';

import { TABS } from '../helpers/cashflow.helpers';
import BaselaneButtonTab from './BaselaneButtonTab';
import { tabListStyles } from '../styles/tabs.styles';

function CashFlowTabList() {
  const currentLocation = useLocation();

  return (
    <TabList {...tabListStyles(isMobile)}>
      {TABS.map((tab) => {
        const { to, segmentEventName } = tab;
        return (
          <BaselaneButtonTab
            key={tab.key}
            to={to}
            isDisabled={tab.isDisabled && to.pathname === currentLocation.pathname}
            segmentEventName={segmentEventName}
          >
            {tab.title}
          </BaselaneButtonTab>
        );
      })}
    </TabList>
  );
}

export default CashFlowTabList;
