import { useEffect } from 'react';

const scrollToTop = (element) => {
  element?.current?.scrollTo({
    top: 0,
    behavior: 'smooth',
    /* you can also use 'auto' behaviour
       in place of 'smooth' */
  });
};

const useScrollToTop = (element, shouldScroll) => {
  useEffect(() => {
    if (shouldScroll) {
      scrollToTop(element);
    }
  }, [shouldScroll]);
};

export default useScrollToTop;
