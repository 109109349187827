import React, { useRef, useState, useEffect } from 'react';
import { uniqBy } from 'lodash';
import Select from 'react-dropdown-select';
import FilterWrapper from '../FilterWrapper';
import CustomDropdown from './components/CustomDropdown';
import { DisplayInputDefault } from '../DisplayInputVariations';
import MobilePopup from '../MobilePopup';

import {
  customDropdownHandleRenderer,
  getDropdownSize,
  getMultiSelectedItems,
  onHandleKeyDownFn,
} from '../helpers/dropdown.shared.helpers';
import { handlePopupClose } from '../helpers/popup.shared.helpers';
import customContentRenderHelperHelper from '../helpers/customContentRenderHelper.helper';
import '../styles/dropdowns.styles.scss';
import { T2TitleProps } from '../types/dropdown.types';

const T2WithTitle = ({
  classNames,
  data,
  title,
  parentItemRenderer,
  childItemRenderer,
  isMulti,
  isDisabled,
  selectedItem,
  handleSubmit,
  showValueByFields,
  hasFilterWrapper,
  filterCustomComponent,
  hasActiveParentFilter,
  setHasActiveParentFilter,
  parentId,
  portalRef,
  hasDropdownClearedExternally,
  setHasDropdownClearedExternally,
  clearButtonText,
  placeholder,
  onClearClick,
  dropdownPosition,
  hideSearch,
  hasError,
  showCustomCategories,
  animationVariant,
  onClearRef,
  hideClearButton,
  showDivider,
  CustomDisplayInput,
  isMobile,
  additionalProps,
}: T2TitleProps) => {
  const searchRef = useRef();
  const dropdownElementRef = useRef();

  let defaultValue = selectedItem ?? [];
  if (selectedItem) defaultValue = isMulti ? selectedItem : [selectedItem];

  const [selectedOptions, setSelectedOptions] = useState(defaultValue);
  const [selectedStagedOptions, setSelectedStagedOptions] = useState(defaultValue);
  const [scrollTop, setScrollTop] = useState(0);
  const [hasExternalClearButton, setHasExternalClearButton] = useState(false);

  // mobile popup states
  const [search, setSearch] = useState({ target: { value: '' } });
  const [showMobileDropdownPopup, setShowMobileDropdownPopup] = useState(false);
  const [showMobileDropdownPopupAnimation, setShowMobileDropdownPopupAnimation] = useState({
    slidein: false,
    slideout: false,
  });
  // ---

  // new state for new dropdown design
  const [pId = null, cId = null] = selectedItem?.id?.split('-') ?? [];
  let initialChildrenSelectAllState = cId ? { [pId]: [selectedItem] } : {};
  if (isMulti) {
    initialChildrenSelectAllState = selectedItem?.reduce((acc, item) => {
      const { id } = item;
      const [parId, subId] = id.split('-');
      const keys = Object.keys(acc);
      if (keys.includes(parId) && subId) {
        return { ...acc, [parId]: [...acc[parId], item] };
      }
      return subId ? { ...acc, [parId]: [item] } : acc;
    }, {});
  }
  const [childrenSelectAllState, setChildrenSelectAllState] = useState(
    initialChildrenSelectAllState
  );

  const handleClearClick = (_setSearch) => {
    _setSearch({ target: { value: '' } });
    setHasActiveParentFilter(false);
    setChildrenSelectAllState({});

    setSelectedStagedOptions([]);

    if (onClearClick) {
      onClearClick();
    }
  };

  const handleExternalClear = ({ dropDown = null, setSearchParam } = {}) => {
    handleClearClick(setSearchParam);
    setSelectedOptions([]);
    handleSubmit([], dropDown);
  };

  const customContentRenderer = ({ state, methods }) => {
    return customContentRenderHelperHelper({
      state,
      methods,
      CustomDisplayInput,
      selectedOptions,
      placeholder,
      title,
      showValueByFields,
      handleExternalClear,
      isDisabled,
      classNames,
    });
  };

  const handleBlurOnToggle = (id) => {
    const elem = Array.from(dropdownElementRef.current.childNodes).find((c) => c.id === id);
    const childElem = elem.querySelector('.element');
    elem.blur();
    childElem?.blur();
  };

  const handleListItemClick = (item, e, dropDown) => {
    const itemToToggle = selectedStagedOptions.find((opt) => opt.id === item.id);

    if (hasActiveParentFilter) setHasActiveParentFilter(false);

    if (isMulti) {
      const itemsSelected = hasActiveParentFilter
        ? [item]
        : getMultiSelectedItems({ itemToToggle, item, selectedStagedOptions });
      setSelectedStagedOptions(itemsSelected);

      // Update state of childrenSelectAllState to reflect on ui
      const [parentOptionId] = item.id.split('-');
      setChildrenSelectAllState((prevState) => {
        const prevStateClone = { ...prevState };
        return Object.keys(prevStateClone).length > 0
          ? Object.keys(prevStateClone).reduce((acc, key) => {
              if (key === parentOptionId) {
                const doesIdExist = acc[key].find((i) => i.id === item.id);
                if (doesIdExist) {
                  // remove individual child id from childrenSelectAllState[parentId]
                  const filteredItemList = acc[key].filter((i) => i.id !== item.id);
                  // delete key from object
                  const listWithoutItem = { ...acc };
                  delete listWithoutItem[key];

                  return filteredItemList.length > 0
                    ? { ...acc, [key]: acc[key].filter((i) => i.id !== item.id) }
                    : listWithoutItem;
                }
                // add individual child id to childrenSelectAllState if it doesn't exist
                return { ...acc, [key]: [...(acc?.[key] ?? []), item] };
              }
              // add as is
              return { ...acc, [key]: prevStateClone[key] };
            }, prevStateClone)
          : { ...prevStateClone, [parentOptionId]: [item] };
      });

      // NOTE: To fix updated styles not getting applied right away,
      //       blur the item to make it lose focus if user deselects it (only for click event)
      if (e?.type === 'click' && itemToToggle) handleBlurOnToggle(item.id);
    } else if (!itemToToggle) {
      const [parentOptionId, childOptionId] = item.id.split('-');
      setChildrenSelectAllState(childOptionId ? { [parentOptionId]: [item] } : {});
      setSelectedStagedOptions([item]);
      setSelectedOptions([item]);
      if (dropDown && !isMobile) dropDown('close');
      if (isMobile) {
        handlePopupClose({
          setShowMobileDropdownPopup,
          setShowMobileDropdownPopupAnimation,
        });
      }
      handleSubmit(item.id, item, dropDown);
    }
  };

  const handleApplyClick = ({ dropDown = null } = {}) => {
    if (isMulti) setSelectedOptions(selectedStagedOptions);
    if (showCustomCategories) setScrollTop(dropdownElementRef.current.scrollTop);
    if (dropDown) dropDown('close');
    handleSubmit(selectedStagedOptions, dropDown);
  };

  /**
   * A function to help with the logic of inner children select all. When selecting individual items as
   * as a child option or a parent with no children handleListItemClick will be called instead.
   *
   * @param parentChildren
   * @param parentItem
   * @param isParentItemChecked
   * @param isParentOnChange
   * @param isExpanded
   */
  const handleSelectAll = (
    parentChildren,
    parentItem,
    isParentOnChange,
    isExpanded = false,
    isParentItemChecked = false
  ) => {
    if (hasActiveParentFilter) setHasActiveParentFilter(false);

    const { id: parId } = parentItem;

    if (isParentOnChange) {
      /**
       *  Logic for if user is interacting with parent checkbox.
       *  1. update selectedStagedOptions depending on if parent is checked or not
       *  2. update state of childrenSelectAllState depending on if the parent is checked or not
       */
      const stagedOptions =
        childrenSelectAllState?.[parId] && isExpanded
          ? [parentItem]
          : [parentItem, ...parentChildren];
      // 1.
      setSelectedStagedOptions((prevState) => {
        if (!isParentItemChecked) {
          return prevState.filter((ps) => {
            const [prevParentId] = ps.id.split('-');
            if (isExpanded) {
              return ps.id !== parId;
            }

            return prevParentId !== parId;
          });
        }
        // 2.
        // only check all children elements if accordion is closed if it is
        // open only check the parent
        if (!isExpanded) {
          return uniqBy([...prevState, ...stagedOptions], 'id');
        }
        return [...prevState, parentItem];
      });

      // only check/uncheck all children elements if accordion is closed if it is
      // open only check/uncheck the parent
      const addToChildSelectAllState =
        isExpanded || (!isExpanded && !isParentItemChecked)
          ? childrenSelectAllState
          : {
              ...childrenSelectAllState,
              [parId]: parentChildren,
            };

      if (childrenSelectAllState?.[parId] && !isParentItemChecked && !isExpanded) {
        delete addToChildSelectAllState[parId];
      }

      setChildrenSelectAllState({
        ...addToChildSelectAllState,
      });
    } else if (!isParentOnChange && childrenSelectAllState[parId]) {
      /**
       *  Logic for child SELECT ALL option to remove all from childrenSelectAllState
       *  and removing the children from selectedStagedOptions.
       */
      const clonedState = { ...childrenSelectAllState };
      delete clonedState[parId];
      setChildrenSelectAllState(clonedState);
      setSelectedStagedOptions((prevState) =>
        prevState.filter((ps) => {
          return !parentChildren.find((pc) => pc.id === ps.id);
        })
      );
    } else {
      /**
       *  Base Case: where we add all options. When nothing is selected in the child section
       */
      setSelectedStagedOptions((prevState) => [...prevState, ...parentChildren]);
      setChildrenSelectAllState({ ...childrenSelectAllState, [parId]: parentChildren });
    }
  };

  const customDropdownRenderer = ({ props, state, methods }) => {
    const { options } = props ?? {};
    const { search: desktopSearch } = state ?? {};
    const { setSearch: desktopSetSearch, dropDown } = methods ?? {};

    return hasFilterWrapper ? (
      <FilterWrapper
        ref={onClearRef}
        title={title}
        isMulti={isMulti}
        selectedStagedOptions={selectedStagedOptions}
        filterCustomComponent={filterCustomComponent}
        onApply={() => handleApplyClick({ dropDown })}
        onClear={() => handleClearClick(desktopSetSearch)}
        clearButtonText={clearButtonText}
        animationVariant={animationVariant}
        hideClearButton={hasExternalClearButton || hideClearButton}
        isMobile={isMobile}
        dropDown={dropDown}
      >
        <CustomDropdown
          isMulti={isMulti}
          options={options}
          selectedStagedOptions={selectedStagedOptions}
          parentItemRenderer={parentItemRenderer}
          childItemRenderer={childItemRenderer}
          title={title}
          search={desktopSearch}
          setSearch={desktopSetSearch}
          searchRef={searchRef}
          hasFilterWrapper={hasFilterWrapper}
          dropdownElementRef={dropdownElementRef}
          handleListItemClick={handleListItemClick}
          showCustomCategories={showCustomCategories}
          animationVariant={animationVariant}
          setScrollTop={setScrollTop}
          scrollTop={scrollTop}
          hideSearch={hideSearch}
          dropDown={dropDown}
          handleSelectAll={handleSelectAll}
          childrenSelectAllState={childrenSelectAllState}
          showDivider={showDivider}
        />
      </FilterWrapper>
    ) : (
      <CustomDropdown
        isMulti={isMulti}
        options={options}
        selectedStagedOptions={selectedStagedOptions}
        parentItemRenderer={parentItemRenderer}
        childItemRenderer={childItemRenderer}
        title={title}
        search={desktopSearch}
        setSearch={desktopSetSearch}
        searchRef={searchRef}
        hasFilterWrapper={hasFilterWrapper}
        dropdownElementRef={dropdownElementRef}
        handleListItemClick={handleListItemClick}
        showCustomCategories={showCustomCategories}
        animationVariant={animationVariant}
        setScrollTop={setScrollTop}
        scrollTop={scrollTop}
        hideSearch={hideSearch}
        dropDown={dropDown}
        handleSelectAll={handleSelectAll}
        childrenSelectAllState={childrenSelectAllState}
        showDivider={showDivider}
      />
    );
  };

  const handleClearAndApply = () => {
    setSelectedStagedOptions([]);
    setSelectedOptions([]);
    setChildrenSelectAllState({});
  };

  const handleKeyDownFn = (eventResponse) => {
    const setOptions = (results, id) => {
      const option = results.reduce((acc, opt) => {
        if (opt.id === id) {
          acc.itemSelected = opt;
        }

        return acc;
      }, {});

      handleListItemClick(option.itemSelected);
    };

    const helpers = { dropdownElementRef, searchRef, setOptions };
    onHandleKeyDownFn(eventResponse, helpers);
  };

  const calculateDropdownSize = () => {
    const selectDropdown = document.querySelector('.react-dropdown-select-dropdown');
    if (!selectDropdown) return;
    const selectElem = selectDropdown?.parentElement;
    const parentElem = document.querySelector(`#${parentId}`);
    if (!parentElem) return;

    getDropdownSize({
      selectElem,
      parentElem,
      selectDropdown,
    });

    // focus on search if is not in mobile
    if (!portalRef) searchRef.current?.focus();

    setSelectedStagedOptions(selectedOptions);
    setChildrenSelectAllState(initialChildrenSelectAllState ?? {});
  };

  useEffect(() => {
    if (hasDropdownClearedExternally) {
      handleClearAndApply();
      setHasDropdownClearedExternally(false);
    }
  }, [hasDropdownClearedExternally]);

  useEffect(() => {
    setSelectedStagedOptions(defaultValue);
    if (!hasActiveParentFilter) {
      setSelectedOptions(defaultValue);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (onClearRef !== null) {
      setHasExternalClearButton(true);
    }
  }, [onClearRef]);

  // to fix case where if users resizes browser if user has opened mobile popup before
  // slideout would be set to true so when going back from a bigger screen to smaller screen
  // we could briefly see the dropdown for a second.
  useEffect(() => {
    if (!isMobile) {
      setShowMobileDropdownPopupAnimation({ slidein: false, slideout: false });
    }
  }, [isMobile]);

  return isMobile ? (
    <MobilePopup
      DisplayInput={CustomDisplayInput ?? DisplayInputDefault}
      selectedOptions={selectedOptions}
      selectedStagedOptions={selectedStagedOptions}
      placeholder={placeholder}
      title={title}
      hideClearButton={hasExternalClearButton || hideClearButton}
      isDisabled={isDisabled}
      isMobile={isMobile}
      isMulti={isMulti}
      hasError={hasError}
      showValueByFields={showValueByFields}
      showMobileDropdownPopup={showMobileDropdownPopup}
      showMobileDropdownPopupAnimation={showMobileDropdownPopupAnimation}
      handleClearClick={handleClearClick}
      handleExternalClear={handleExternalClear}
      onApply={handleApplyClick}
      setShowMobileDropdownPopup={setShowMobileDropdownPopup}
      setShowMobileDropdownPopupAnimation={setShowMobileDropdownPopupAnimation}
      setSelectedStagedOptions={setSelectedStagedOptions}
      dropdownElementRef={dropdownElementRef}
      searchRef={searchRef}
      setSearch={setSearch}
      classNames={classNames}
    >
      <CustomDropdown
        search={search.target.value}
        hasFilterWrapper={false}
        setSearch={setSearch}
        isMulti={isMulti}
        options={data ?? []}
        selectedStagedOptions={selectedStagedOptions}
        selectedOptions={selectedOptions}
        showCustomCategories={showCustomCategories}
        handleListItemClick={handleListItemClick}
        title={title}
        dropdownElementRef={dropdownElementRef}
        searchRef={searchRef}
        hideSearch={hideSearch}
        showDivider={showDivider}
        parentItemRenderer={parentItemRenderer}
        childItemRenderer={childItemRenderer}
        handleSelectAll={handleSelectAll}
        childrenSelectAllState={childrenSelectAllState}
      />
    </MobilePopup>
  ) : (
    <Select
      className={`new tier2-title-dropdown ${hasError ? 'input-invalid' : ''} ${classNames.join(
        ' '
      )}`}
      dropdownPosition={dropdownPosition}
      keepOpen={false}
      options={data}
      values={selectedOptions}
      disabled={isDisabled}
      dropdownGap={0}
      closeOnSelect={!isMulti}
      multi={isMulti}
      backspaceDelete={false}
      dropdownRenderer={customDropdownRenderer}
      contentRenderer={customContentRenderer}
      dropdownHandleRenderer={({ state }) => customDropdownHandleRenderer({ state, isMobile })}
      onDropdownOpen={() => {
        calculateDropdownSize();
      }}
      handleKeyDownFn={handleKeyDownFn}
      portal={portalRef}
      additionalProps={additionalProps}
    />
  );
};

T2WithTitle.defaultProps = {
  classNames: ['fixed-width-dropdown'],
  isMulti: false,
  isDisabled: false,
  selectedItem: null,
  handleSubmit: () => {},
  hasFilterWrapper: false,
  filterCustomComponent: null,
  hasActiveParentFilter: false,
  setHasActiveParentFilter: () => {},
  parentId: 'page-wrapper',
  portalRef: false,
  hasDropdownClearedExternally: false,
  setHasDropdownClearedExternally: () => {},
  clearButtonText: 'Clear all',
  placeholder: null,
  onClearClick: null,
  dropdownPosition: 'auto',
  hideSearch: false,
  hasError: false,
  styles: {},
  showCustomCategories: null,
  animationVariant: null,
  onClearRef: null,
  hideClearButton: false,
  showDivider: false,
  CustomDisplayInput: null,
  isMobile: false,
  additionalProps: {},
};

export default T2WithTitle;
