import process from 'process';

export const getEnv = () => {
  const orchURL = process?.env?.REACT_APP_ORCHESTRATION_URL?.replace('https://', '');
  let env = 'develop';
  if (orchURL === 'orchestration.baselane.com/graphql') {
    env = 'production';
  } else if (orchURL === 'uat-orchestration.baselane.com/graphql') {
    env = 'uat';
  } else if (orchURL === 'pre-prod-orchestration.baselane.com/graphql') {
    env = 'pre-prod';
  }

  return env;
};
