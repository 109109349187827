export const rangepickerStyles = {
  value: {
    m: '0 !important',
    p: '10px 0',
    flex: '1',
  },
  calendaricon: {
    color: 'brand.neutral.500',
    p: '12px 8px 12px 12px',
  },
  closeicon: (isDisabled) => {
    return {
      display: 'flex',
      minW: '24px',
      h: '24px',
      mx: '8px !important',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      color: isDisabled ? 'brand.neutral.400' : 'brand.neutral.700',
      _hover: {
        backgroundColor: isDisabled ? 'transparent' : 'brand.darkBlue.200',
      },
      _active: {
        backgroundColor: isDisabled ? 'transparent' : 'brand.darkBlue.250',
      },
    };
  },
  chevronicon: {
    color: 'brand.neutral.500',
    p: '10px 12px 10px 0',
  },
};
