import hasOnlyWhitespace from '@core/utils/hasOnlyWhitespace';

const emailExists = (em, emails) => {
  return emails.filter((item) => item === em).length;
};

export const handleValidation = ({
  values,
  status,
  landlordEmail,
  otherTenantEmails,
  setApiErrors,
}) => {
  const validateEmail = status === 'INVITE_SENT';
  const filterPhoneNumber = values?.phoneNumber?.replace(/[^0-9]/g, '');
  setApiErrors(null);
  const errors = {};
  if (!/^[A-Z0-9'-\s]+$/i.test(values.lastName)) {
    errors.lastName = "Only alphanumeric characters, - and ' allowed";
  }
  if (!values.lastName || values.lastName === '' || hasOnlyWhitespace(values.lastName)) {
    errors.lastName = 'Please enter last name';
  }
  if (!/^[A-Z0-9'-\s]+$/i.test(values.firstName)) {
    errors.firstName = "Only alphanumeric characters, - and ' allowed";
  }
  if (!values.firstName || values.firstName === '' || hasOnlyWhitespace(values.firstName)) {
    errors.firstName = 'Please enter first name';
  }
  if (values.phoneNumber && values.phoneNumber !== '' && filterPhoneNumber.length < 10) {
    errors.phoneNumber = 'Invalid phone number';
  }

  if (validateEmail) {
    if (!values.email) {
      errors.email = 'Please enter Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    } else if (values.email === landlordEmail) {
      errors.email = 'You cannot invite yourself as a Tenant';
    } else if (emailExists(values.email, otherTenantEmails)) {
      errors.email = 'A Tenant with this email already exists';
    }
  }

  return errors;
};
