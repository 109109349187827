import React from 'react';

type IconTransferProps = { w?: number, h?: number, color?: string };

function IconTransfer({ w, h, color }: IconTransferProps) {
  return (
    <svg width={w} height={h} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M.666748 10.3333 3.33341 7.66667.666748 10.3333Z" fill={color} />
      <path
        d="M3.33341 3.66667h7.99999m0 0L8.66675 1m2.66665 2.66667L8.66675 6.33333m0 3.99997H.666748m0 0L3.33341 13M.666748 10.3333 3.33341 7.66667"
        stroke="#192C3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconTransfer.defaultProps = {
  w: 12,
  h: 14,
  color: 'currentColor',
};

export default IconTransfer;
