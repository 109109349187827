/**
 * Initializes and provides the API for the Zendesk Messenger UI
 */

import React from 'react';
import { ZendeskProvider } from '@core/contexts/ZendeskContext';

const ZendeskWrapper = ({ user = null, children }: any) => {
  return <ZendeskProvider user={user}>{children}</ZendeskProvider>;
};

export default ZendeskWrapper;
