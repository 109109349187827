import React from 'react';

type IconStarProps = {
  w?: Number,
  h?: Number,
  color?: String,
};

function IconStar({ w = 15, h = 14, color = '#CD9800' }: IconStarProps) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 15 14"
    >
      <path
        d="M6.86606 1.45141C7.06606 0.837406 7.93473 0.837406 8.13406 1.45141L9.14673 4.56741C9.19031 4.70106 9.27503 4.81753 9.38878 4.90014C9.50253 4.98276 9.63948 5.0273 9.78006 5.02741H13.0567C13.7027 5.02741 13.9707 5.85407 13.4487 6.23407L10.7981 8.15941C10.6842 8.24218 10.5995 8.35887 10.556 8.49276C10.5125 8.62665 10.5125 8.77086 10.5561 8.90474L11.5681 12.0207C11.7681 12.6354 11.0647 13.1461 10.5427 12.7661L7.89206 10.8407C7.77814 10.7579 7.64091 10.7133 7.50006 10.7133C7.35922 10.7133 7.22199 10.7579 7.10806 10.8407L4.4574 12.7661C3.9354 13.1461 3.23206 12.6347 3.43206 12.0207L4.44406 8.90474C4.48758 8.77086 4.48761 8.62665 4.44414 8.49276C4.40066 8.35887 4.31593 8.24218 4.20206 8.15941L1.5514 6.23407C1.02873 5.85407 1.29806 5.02741 1.9434 5.02741H5.2194C5.3601 5.02744 5.4972 4.98296 5.61108 4.90034C5.72496 4.81771 5.80978 4.70117 5.8534 4.56741L6.86606 1.45141Z"
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IconStar.defaultProps = {
  w: 15,
  h: 14,
  color: '#CD9800',
};

export default IconStar;
