import React from 'react';

function IconCanceled() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.08" cx="12" cy="12" r="12" fill="#C93A3A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.80806 7.80806C8.05214 7.56398 8.44786 7.56398 8.69194 7.80806L12 11.1161L15.3081 7.80806C15.5521 7.56398 15.9479 7.56398 16.1919 7.80806C16.436 8.05214 16.436 8.44786 16.1919 8.69194L12.8839 12L16.1919 15.3081C16.436 15.5521 16.436 15.9479 16.1919 16.1919C15.9479 16.436 15.5521 16.436 15.3081 16.1919L12 12.8839L8.69194 16.1919C8.44786 16.436 8.05214 16.436 7.80806 16.1919C7.56398 15.9479 7.56398 15.5521 7.80806 15.3081L11.1161 12L7.80806 8.69194C7.56398 8.44786 7.56398 8.05214 7.80806 7.80806Z"
        fill="#C93A3A"
      />
    </svg>
  );
}

export default IconCanceled;
