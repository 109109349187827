export const dividerTitleStyles = {
  mb: '24px',
  spacing: 0,
};

export const fieldContainer = {
  minHeight: '110px',
  position: 'relative',
};

export const formSubLabelStyles = {
  textStyle: 'xs',
  color: 'brand.neutral.500',
  ml: '20px !important',
};

export const formInputStyles = {
  size: 'sm',
  color: 'brand.neutral.700',
  borderRadius: '4px',
  h: '48px',
};

export const formInputComponentStyles = {
  borderColor: 'inherit !important',
  w: '250px',
  color: 'brand.darkBlue.300',
};

export const formSectionTitleStyles = {
  textStyle: 'headline-sm',
  minWidth: 'fit-content',
  mr: '24px',
  color: 'brand.neutral.700',
};

export const formErrorStyles = {
  position: 'absolute',
  color: 'red.500A',
  fontSize: '3xs',
};

export const zillowAddressLinkStyles = {
  color: 'brand.blue.700',
  lineHeight: '20px',
  cursor: 'pointer',
  _hover: { textDecoration: 'underline' },
};

export const zillowMarketValueStyles = {
  textStyle: 'headline-xs',
  color: 'brand.neutral.600',
};

export const zillowDisclaimerWrapperStyles = {
  color: 'brand.neutral.600',
  textStyle: 'xs',
  mr: '96px',
  justifyContent: 'flex-end',
};

export const zillowDisclaimerStyles = {
  color: 'brand.blue.700',
  m: '0 !important',
  ml: '4px !important',
};
