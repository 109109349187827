import React from 'react';
import { useGate } from 'statsig-react';

import AddAccountDrawer from './AddAccountDrawer';
import AddVirtualAccountDrawer from './AddVirtualAccountDrawer';

type AddAccountFeatureProps = {
  from?: String,
};

const AddAccountFeature = ({ from }: AddAccountFeatureProps) => {
  const { value: savingsEnabled } = useGate('savings_account_gate');

  return savingsEnabled ? (
    <AddAccountDrawer from={from} />
  ) : (
    <AddVirtualAccountDrawer from={from} />
  );
};

export default AddAccountFeature;

AddAccountFeature.defaultProps = {
  from: null,
};
