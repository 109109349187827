// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { titleContainerStyles } from './styles/title.style';

function BaselaneTitle({ title, titleStyles }: { title: any, titleStyles: Object }): any {
  const titleStyle = {
    ...titleContainerStyles,
    ...titleStyles,
  };
  return (
    <Box {...titleStyle}>
      <Box>{title}</Box>
    </Box>
  );
}

export default BaselaneTitle;
