import React from 'react';
import { IconArrowRight } from '@icons';

export const STATUS_BADGE = {
  DRAFT: {
    color: 'yellow',
    text: 'Draft',
  },
  READY_TO_SIGN: {
    color: 'blue',
    text: 'Ready To Sign',
  },
  SIGNING_IN_PROGRESS: {
    color: 'blue',
    text: 'Signing In Progress',
  },
  SIGNING_COMPLETE: {
    color: 'green',
    text: 'Signing Complete',
  },
};

export const ACTION_BUTTON = {
  DRAFT: {
    variant: 'filled',
    palette: 'primary',
    text: 'Edit Draft',
    icon: <IconArrowRight w={10.44} h={6.59} />,
  },
  READY_TO_SIGN: {
    variant: 'filled',
    palette: 'primary',
    text: 'Start Signing',
    icon: <IconArrowRight w={10.44} h={6.59} />,
  },
  SIGNING_IN_PROGRESS: {
    variant: 'filled',
    palette: 'primary',

    text: 'View Details',
  },
  SIGNING_COMPLETE: {
    variant: 'tonal',
    palette: 'primary',
    text: 'View Document',
  },
};
