export const customHeaderStyles = {
  minH: { xl: '80px', lg: '72px', sm: '64px' },
  justifyContent: 'center',
  borderColor: 'brand.blue.200',
};

export const hideStyles = {
  display: 'none',
};

export const headerLeftStyles = {
  fontSize: { xl: '2xl', lg: 'xl', sm: 'md' },
  padding: '24px',
};

export const containerStyles = {
  h: 'auto',
};

export const contentContainerStyles = {
  alignItems: 'center',
  justifyContent: 'center',
  flexDir: { xl: 'row', lg: 'row', xxs: 'column' },
  marginTop: '-4px',
};

export const bottomContentContainerStyles = {
  p: '24px 32px',
  alignItems: 'center',
  justifyContent: 'center',
  flexDir: 'column',
};

export const bottomInnerContentContainerStyles = { my: '16px!important' };

export const iconContainer = {
  pt: '4px',
  alignSelf: 'flex-start',
};

export const bottomTextStyles = {
  fontSize: 'sm',
  lineHeight: '20px',
  fontWeight: 'normal',
  color: 'brand.neutral.600',
  mb: '24px',
};
