import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import { BaselaneSingleDatePicker } from '@shared/components';
import { formatDate } from '@core/utils/formatDate';
import getBreakPoints from '@core/utils/getBreakPoints';

const DateInputField = ({
  id = 'manual-trx-date',
  values,
  setFieldValue,
  errors,
  touched,
  ...props
}: {
  id: string,
  values: object,
  setFieldValue: Function,
  errors: Array,
  touched: Array,
  props: object,
}) => {
  const { isOpen: isDateOpen, onOpen: onDateOpen, onClose: onDateClose } = useDisclosure();
  const years = { start: 2000, end: moment().year() };
  const minDate = moment('2000-01-01').toDate();
  const maxDate = moment().toDate();

  const applyDate = ({ date, isFromApplyButton }) => {
    if (isMin768 || (!isMin768 && isFromApplyButton)) {
      setFieldValue('date', date);
    }
  };
  const { isMin768 } = getBreakPoints();
  return (
    <BaselaneSingleDatePicker
      isInsideForm={isMin768}
      {...{
        id,
        date: values.date,
        value: values.date ? `${formatDate(values.date, 'MMM DD, YYYY')}` : '',
        years,
        isOpen: isDateOpen,
        onOpen: onDateOpen,
        onClose: onDateClose,
        minDate,
        maxDate,
        dateFormat: 'MMM dd, yyyy',
        size: isMin768 ? 'lg' : 'md',
        handleCalendarClose: applyDate,
        hideInputIcon: !isMin768,
        showInModal: !isMin768,
        placement: 'bottom-end',
        className: `${errors?.date && touched.date ? 'has-error' : ''}`,
      }}
      {...props}
    />
  );
};

export default DateInputField;
