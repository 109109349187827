import React from 'react';

function IconCrossCircle({ w, h, color, ...rest }: { w?: Number, h?: Number, color?: String }) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox={`0 0 ${w} ${h}`}
      fill="none"
      style={{ ...rest }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={color} />
      <path d="M11 5L5 11" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 5L11 11" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

IconCrossCircle.defaultProps = {
  w: 16,
  h: 16,
  color: 'currentColor',
};

export default IconCrossCircle;
