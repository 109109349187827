import banking from '../assets/banking.png';
import banking2x from '../assets/banking@2x.png';
import rentCollection from '../assets/rent-collection.png';
import rentCollection2x from '../assets/rent-collection@2x.png';
import yellowReceipt from '../assets/yellow-receipt.png';

export const referralHowToArray = [
  {
    id: 1,
    text: 'Sign up and open a free Baselane Banking Account.',
  },
  {
    id: 2,
    text:
      'Collect a minimum $500 of rent into your Baselane Banking account within 45 days of account creation.',
  },
  {
    id: 3,
    text:
      'Baselane will deposit $100 reward directly into your Baselane Banking account within 30 days.',
  },
];

export const referralOnePlatformArray = [
  {
    id: 1,
    icon: banking,
    iconLarge: banking2x,
    alt: 'Blue dollar icon',
    bg: 'brand.blue.50',
    title: 'Banking',
    subtext: 'Automate your banking. 4.19% APY, organized accounts, and powerful spend management.',
  },
  {
    id: 2,
    icon: rentCollection,
    iconLarge: rentCollection2x,
    alt: 'Green key icon',
    bg: 'green.50',
    title: 'Rent Collection',
    subtext:
      'Never chase down checks or late rent again. Fast payments, simple to set up, easy for tenants.',
  },
  {
    id: 3,
    icon: yellowReceipt,
    iconLarge: yellowReceipt,
    alt: 'Yellow receipt icon',
    bg: 'yellow.50',
    title: 'Accounting & Reporting',
    subtext: 'See your cash flow clear as day. Maximize returns and make tax time a breeze.',
  },
];
