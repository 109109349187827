import type { BaseModel, GroupedBaseModel } from '@shared/types';
import { getAccountModel } from '@shared/helpers/bankAccount.helper';
import turnBaseModelArrayToMap from '@core/utils/turnBaseModelArrayToMap';

function turnAccountsArrayToMap(arr): { [id: string]: string } {
  const result = {};

  for (let i = 0; i < arr.length; i += 1) {
    const { id, ...rest } = arr[i] ?? {};
    result[arr[i]?.id] = {
      ...rest,
    };
  }

  return result;
}

const formatAccountData = (acc, bank) => {
  const key = bank.isExternal ? 'external_account' : 'baselane_account';
  const baselaneBankName = !bank.isExternal && (bank.name || `Baselane ${bank.id}`);
  const { items } = acc.find((item) => item.id === key);

  bank.bankAccounts.forEach((mainAccount) => {
    const mainAccItem = {
      id: mainAccount.id,
      bankName: baselaneBankName || mainAccount.nickName,
      account: `${
        mainAccount.isExternal
          ? mainAccount.accountName
          : mainAccount.nickName || mainAccount.accountName
      } - ${mainAccount.accountNumber}`,
      accountSubType: mainAccount.accountSubType,
      connectionState: mainAccount?.connectionState,
      stripeBankAccountId: mainAccount?.stripeBankAccountId,
      bankAccountMetadata: mainAccount?.bankAccountMetadata,
      isExternal: mainAccount.isExternal,
    };
    items.push(mainAccItem);

    mainAccount.subAccounts.forEach((subAcc) => {
      const subAccItem = {
        id: subAcc.id,
        bankName: baselaneBankName || subAcc.nickName,
        account: `${
          subAcc.isExternal ? subAcc.accountName : subAcc.nickName || subAcc.accountName
        } - ${subAcc.accountNumber}`,
        accountSubType: subAcc.accountSubType,
        connectionState: subAcc?.connectionState,
        stripeBankAccountId: subAcc?.stripeBankAccountId,
        bankAccountMetadata: subAcc?.bankAccountMetadata,
        isExternal: subAcc.isExternal,
      };
      items.push(subAccItem);
    });
  }, []);
  return acc;
};

const formatEntityAccountData = (bank, account) => {
  const output = { id: 'baselane_account', title: 'Baselane Accounts', items: [] };

  if (!bank || !account) return [output];

  const baselaneBankName = bank.name || `Baselane ${bank.id}`;

  const mainAccountItem = {
    id: account.id,
    bankName: baselaneBankName || account.nickName,
    account: `${account.nickName || account.accountName} - ${account.accountNumber}`,
  };
  if (account.accountStatus === 'Open') output.items.push(mainAccountItem);

  account.subAccounts.forEach((subAccount) => {
    const subAccountItem = {
      id: subAccount.id,
      bankName: baselaneBankName || subAccount.nickName,
      account: `${subAccount.accountName} - ${subAccount.accountNumber}`,
    };
    if (subAccount.accountStatus === 'Open') output.items.push(subAccountItem);
  });

  return [output];
};

const getConnectedAccountsFilterData = (connectedAccountsData, entityId = null) => {
  if (!entityId) {
    return connectedAccountsData?.bank?.reduce(formatAccountData, [
      { id: 'baselane_account', title: 'Baselane accounts', items: [] },
      { id: 'external_account', title: 'External accounts', items: [] },
    ]);
  }

  let account = null;
  const bank = connectedAccountsData?.bank?.find((currentBank) => {
    account = currentBank?.bankAccounts?.find((currentAccount) => {
      return currentAccount.id === entityId;
    });
    return !!account;
  });
  return formatEntityAccountData(bank, account);
};

export const getAccountMap = (accountsData) => {
  const accountOptions: Array<GroupedBaseModel> = () =>
    (accountsData?.bank || []).map(
      ({ id, isExternal, plaidInstitutionName, bankAccounts, name: bankName }) => {
        const items: Array<BaseModel> = bankAccounts?.reduce((bankAccountsAcc, bankAccount) => {
          const bankAccountItems = !isExternal
            ? getAccountModel(bankAccount, null, id, bankName)
            : getAccountModel(bankAccount);

          bankAccountsAcc.push(bankAccountItems);

          const subAccountItems = (bankAccount?.subAccounts || []).map((subAccount, index) =>
            getAccountModel(subAccount, bankAccountItems, id, bankName, index)
          );

          return bankAccountsAcc.concat(subAccountItems);
        }, []);

        return { title: plaidInstitutionName, items };
      }
    );
  const allAccounts = accountOptions().reduce((acc, { items }) => acc.concat(items), []);

  const accountMap = () => turnBaseModelArrayToMap(allAccounts);

  return accountMap;
};

export const getAllAccountsMap = (accountsData) => {
  const accountOptions: Array<GroupedBaseModel> = () =>
    (accountsData?.bank || []).map(({ plaidInstitutionName, bankAccounts }) => {
      const items: Array<BaseModel> = bankAccounts?.reduce((bankAccountsAcc, bankAccount) => {
        bankAccountsAcc.push({ plaidInstitutionName, ...bankAccount });
        const subAccountItems = (bankAccount?.subAccounts || []).map((subAccount) => subAccount);
        return bankAccountsAcc.concat({ plaidInstitutionName, ...subAccountItems });
      }, []);

      return items;
    });

  const allAccounts = accountOptions().flat(2);
  const allAccountsMap = () => turnAccountsArrayToMap(allAccounts);
  return allAccountsMap;
};

export const getConnectedAccountOptions = (connectedAccountsData) => {
  const accountOptions = (connectedAccountsData?.bank || []).reduce(
    (connectedAccounts, { isExternal, plaidInstitutionName, bankAccounts, unitAccount }) => {
      if (unitAccount && unitAccount.unitApplicationStatus === 'IN_PROGRESS') {
        return connectedAccounts;
      }
      const items: Array<BaseModel> = (bankAccounts || []).reduce(
        (bankAccountsAcc, bankAccount) => {
          // TODO: bring back when we want to show unit accounts in add accounts list when adding a manual trx ( will need to add id + name to line 145 )
          // const bankAccountName = bankAccount.nickName || bankName || bankAccount.name;
          // const bankAccountItems =
          //   plaidInstitutionId.toLowerCase() === 'unit'
          //     ? getAccountModel(bankAccount, null, id, bankAccountName)
          //     : getAccountModel(bankAccount);

          if (isExternal) {
            const bankAccountItems = getAccountModel(bankAccount);
            bankAccountsAcc.push(bankAccountItems);
          }

          // TODO: bring back when we want to show unit accounts in add accounts list when adding a manual trx
          // const subAccountItems = (bankAccount?.subAccounts || []).map((subAccount, index) =>
          //   getAccountModel(
          //     subAccount,
          //     bankAccountItems,
          //     id,
          //     subAccount.nickName || bankName || subAccount.name,
          //     index
          //   )
          // );
          // return bankAccountsAcc.concat(subAccountItems);

          return bankAccountsAcc;
        },
        []
      );

      return connectedAccounts.concat([{ title: plaidInstitutionName, items }]);
    },
    []
  );

  return [{ title: null, items: [{ id: 'manual', name: 'Manually Added' }] }].concat(
    accountOptions
  );
};

export const getConnectedAccountsFilterOptions = (connectedAccountsData, entityId = null) => {
  const connectedAccountsFilterData = getConnectedAccountsFilterData(
    connectedAccountsData,
    entityId
  );
  return connectedAccountsFilterData?.map((bank) => {
    const sortedBank = {
      ...bank,
      items: bank.items.sort((a, b) => {
        const compareName = a.bankName?.localeCompare(b.bankName);
        const compareAccount = a.account?.localeCompare(b.account);

        return compareName || compareAccount;
      }),
    };
    return sortedBank;
  });
};

export const getConnectedExtManualAccOptions = (connectedAccountsData) => {
  const connectedAccountsFilterData = getConnectedAccountsFilterData(connectedAccountsData);
  const connectedExternalAcc = connectedAccountsFilterData?.filter(
    (item) => item.id === 'external_account'
  );

  const connectedExtManualAccData = connectedExternalAcc?.map((acc) => {
    acc.items.unshift({
      id: 'manual',
      bankName: 'Manually Added',
      account: 'Manually Added',
    });

    return acc;
  });

  return connectedExtManualAccData?.map((bank) => {
    const sortedBank = {
      ...bank,
      items: bank.items.sort((a, b) => {
        const compareName = a.bankName?.localeCompare(b.bankName);
        const compareAccount = a.account?.localeCompare(b.account);

        return compareName || compareAccount;
      }),
    };

    return sortedBank;
  });
};
