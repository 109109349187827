/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
// @flow
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  useDisclosure,
  useToast,
  Tabs,
  TabPanels,
  TabPanel,
  useMediaQuery,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import useCreateLeaseVariables from '@pages/LeasesPage/LeaseSection/hooks/useCreateLeaseVariables';
import { achFeeEnabledFeatureFlag } from '@core/constants/envVars';
import getBreakPoints from '@core/utils/getBreakPoints';
import LeaseResponsiveReview from '@pages/LeasesPage/LeaseSection/forms/LeaseResponsiveReview';
import {
  AlertHeader,
  AlertFooter,
  BaselaneAlert,
  BaselaneDrawer,
  BaselaneSpinner,
} from '@shared/components';
import { FormTitle } from '@shared/components/BaselaneFlow';
import historyStateStorage from '@core/storage/historyStateStorage';
import { getFormInitialValues } from '../LeaseSection/formHelpers/leaseTermForm.helper';
import {
  GET_LEASE_DETAIL,
  GET_LEASE,
  ARCHIVE_LEASE,
  MODIFY_LEASE,
  FRAGMENT_RECEIVING_BA,
  GET_INVOICE_SUMMARY,
} from '../queries/leaseDetail';
import LeaseResponsivePropertyAddress from './LeaseResponsivePropertyAddress';
import ArchiveLease from '../LeaseSection/forms/LeaseResponsiveReview/ArchiveLease';
import LeaseTerms from '../LeaseSection/forms/LeaseResponsiveReview/LeaseTermsInApp';
import ReceivingBankAccounts from '../LeaseSection/forms/LeaseResponsiveReview/ReceivingBankAccounts';
import ReceivingBankAccountForm from '../LeaseSection/forms/ReceivingBankAccountForm';
import { ProcessingFeeForm } from '../LeaseSection/forms/LeaseResponsiveReview/ProcessingFeeForm';
import {
  viewModeTabsStyles,
  viewModeTabPanelStyles,
  drawerFooterStyle,
} from './styles/leaseResponsiveDetail.styles';

type LeaseResponsiveDetailProps = {
  setSelectedLeaseId: Function,
  bankProps: Object,
  hasBaselaneBank: boolean,
  invoiceListDrawerRef: any,
  lease: Object,
  leaseId: string,
  unit: Object,
  property: Object,
  setLease: Function,
  refetchSingleLease: Function,
  refreshLeases: Function,
  tenantData: Object,
  leaseDetailsDrawerRef: any,
  externalAccountCollectionCount: number,
};

function LeaseResponsiveDetail({
  setSelectedLeaseId,
  bankProps,
  hasBaselaneBank,
  invoiceListDrawerRef,
  lease,
  leaseId,
  unit,
  property,
  setLease,
  refetchSingleLease,
  refreshLeases,
  tenantData,
  leaseDetailsDrawerRef,
  externalAccountCollectionCount,
}: LeaseResponsiveDetailProps): any {
  const [isDesktop] = useMediaQuery('(min-width: 899px)', { ssr: false });
  const [isTablet] = useMediaQuery('(min-width: 769px)', { ssr: false });
  const { isMax767, isMinXL } = getBreakPoints();

  const { onClose } = useDisclosure();

  const historyStateData = historyStateStorage.read('baselane-state');

  // use hook for variables and states TODO: Move away and/or replace by context
  const { variablesAndStates, helperFunctions } = useCreateLeaseVariables(
    unit,
    () => {},
    {},
    true,
    true
  );

  const {
    setHasConnectedBank,
    showReceivingBAEmptyState,
    setShowReceivingBAEmptyState,
  } = variablesAndStates;

  // needed for closing drawer and resetting edit lease panel
  const { setLeaseVariables } = variablesAndStates;
  const [formInitialValues] = getFormInitialValues({ lease });

  const leaseBtnRef = useRef();
  const bankAccountsRef = useRef(null);

  const [tabIndex, setTabIndex] = useState(0);
  const [tabIndexToGo, setTabIndexToGo] = useState(null);
  const [returnToProcessingFee, setReturnToProcessingFee] = useState(false);
  const [variables, setVariables] = useState({});
  const [isReceivingBAEdit, setIsReceivingBAEdit] = useState(true);
  const [isLeaseEdit, setIsLeaseEdit] = useState(false);
  const [isAccountsEdit, setIsAccountsEdit] = useState(false);

  const [isBaselaneAccountBannerClick, setIsBaselaneAccountBannerClick] = useState(false);

  const tenantProfileId = lease?.tenantProfileId;

  // graphQL mutations
  const [archiveLease] = useMutation(ARCHIVE_LEASE);
  const [modifyLease] = useMutation(MODIFY_LEASE);

  // alert for drawer
  const cancelDrawerRef = useRef();
  const [isDrawerAlertOpen, setIsDrawerAlertOpen] = useState(false);
  const onDrawerAlertIsClose = () => setIsDrawerAlertOpen(false);

  // alert for tab
  const cancelTabRef = useRef();
  const [isTabAlertOpen, setIsTabAlertOpen] = useState(false);
  const onTabAlertIsClose = (isEdit) => {
    if (!isEdit) {
      // on coming from edit this is also used for the Cancel button
      // on the quit editing alert, so do not set accounts edit to false then
      setIsAccountsEdit(false);
    }
    setIsTabAlertOpen(false);
  };

  const toast = useToast();

  const showCancelLeaseSuccessToast = () =>
    toast({
      position: 'bottom-left',
      description: `Your lease has been cancelled`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

  const showModifyLeaseToast = (isModifySuccessful = true) =>
    toast({
      position: 'bottom-left',
      description: isModifySuccessful
        ? 'Your rent collection has been updated successfully'
        : 'Something went wrong. Please try again',
      status: isModifySuccessful ? 'success' : 'error',
      duration: 3000,
      isClosable: true,
    });

  const initView = () => {
    onClose();
    setTabIndexToGo(null);
    setTabIndex(0);
    setVariables({});
    setIsDrawerAlertOpen(false);
    setIsTabAlertOpen(false);
  };

  const initTabView = () => {
    setTabIndex(tabIndexToGo);
    setTabIndexToGo(null);
    setVariables({});
    setIsTabAlertOpen(false);
    setIsAccountsEdit(false);
    bankAccountsRef?.current?.resetForm();
  };

  const handleDrawerClose = () => {
    leaseDetailsDrawerRef?.current?.close();
  };

  const handleClose = () => {
    setIsAccountsEdit(false);
    if (tabIndex === 0) {
      localStorage.removeItem('filters');
      localStorage.removeItem('leaseTerms');
      setSelectedLeaseId(null);
      setLease(null);
      handleDrawerClose();
      if (bankAccountsRef?.current?.dirty) {
        setIsDrawerAlertOpen(true);
      } else {
        initView();
      }
    } else if (tabIndex === 1 && isLeaseEdit) {
      setIsLeaseEdit(false);
    } else {
      setIsLeaseEdit(false);
      setLeaseVariables({
        id: variables.id,
        propertyUnitId: variables.propertyUnitId,
        payments: formInitialValues.additionalFeesFields,
      });
      setTabIndex(0);
      localStorage.removeItem('leaseTerms');
    }
  };

  async function handleArchiveLease() {
    // TODO: refetch only one query + consider using only one of them in the app in general unless there's a specific reason for keeping 2 different ones
    try {
      await archiveLease({
        variables: { id: leaseId },
        // eslint-disable-next-line
        update: (cache, { data: archiveLease }) => {
          cache.modify({
            id: cache.identify(archiveLease),
            fields: {
              state: () => 'ENDED',
            },
          });
        },
        refetchQueries: [
          { query: GET_LEASE_DETAIL },
          { query: GET_LEASE },
          { query: GET_INVOICE_SUMMARY },
        ],
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSelectedLeaseId(null);
      showCancelLeaseSuccessToast();
      refreshLeases();
      initView();
      handleClose();
    }
  }

  function setFormVariables(newVariables) {
    setVariables({ ...variables, ...newVariables });
  }

  async function handleModifyReceivingBankAccounts() {
    modifyLease({
      variables: {
        id: leaseId,
        input: variables,
      },
      update: (cache, { data, errors }) => {
        if (errors) {
          console.error(errors);
          showModifyLeaseToast(false);
        } else {
          cache.writeFragment({
            id: cache.identify(lease),
            fragment: FRAGMENT_RECEIVING_BA,
            data: {
              __typename: 'Lease',
              depositBankAccountId: data.modifyLease.depositBankAccountId,
              rentAndFeesBankAccountId: data.modifyLease.rentAndFeesBankAccountId,
            },
          });
          setLease(data?.modifyLease);
          setVariables({});
          showModifyLeaseToast(true);
          setIsAccountsEdit(false);
          setTabIndexToGo(null);

          if (isBaselaneAccountBannerClick) {
            setIsBaselaneAccountBannerClick(false);
          }

          if (returnToProcessingFee) {
            setTabIndex(3);
            setReturnToProcessingFee(false);
          } else {
            setTabIndex(0);
          }
        }
      },
      onError: (error) => {
        console.error(error);
        showModifyLeaseToast(false);
      },
    });
  }

  const handleTabsChange = (index) => {
    if (isReceivingBAEdit && bankAccountsRef?.current?.dirty) {
      setIsTabAlertOpen(true);
      setTabIndexToGo(index);
    } else if (index === 2) {
      setIsAccountsEdit(true);
      setTabIndexToGo(null);
      setTabIndex(2);
    } else {
      setIsAccountsEdit(false);
      setTabIndexToGo(null);
      setTabIndex(index);
      setVariables({});
    }
  };

  const handleBackClick = () => {
    historyStateStorage.write({ from: '/leases' });
    setTabIndexToGo(0);
    setShowReceivingBAEmptyState(false);
    if (isBaselaneAccountBannerClick) {
      setIsBaselaneAccountBannerClick(false);
    }
    if (bankAccountsRef?.current?.dirty) {
      setIsTabAlertOpen(true);
    } else {
      setIsAccountsEdit(false);
      setTabIndex(0);
      setVariables({});
    }
  };

  const handleUpdateRecievingAccClick = () => {
    setIsBaselaneAccountBannerClick(true);
    setIsAccountsEdit(true);
    setTabIndex(2);
  };

  useEffect(() => {
    if (localStorage.getItem('leaseTerms')) {
      setTabIndex(1);
    }
  }, []);

  useEffect(() => {
    if (tabIndex === 1) {
      localStorage.setItem('leaseTerms', 'true');
    } else {
      localStorage.removeItem('leaseTerms');
    }
  }, [tabIndex]);

  useEffect(() => {
    if (historyStateData?.params?.isFromUpdateAccount) {
      handleUpdateRecievingAccClick();
    }
  }, [historyStateData?.from]);

  const alertDrawerCloseFooter = (
    <AlertFooter
      cancelRef={cancelDrawerRef}
      leftButtonText="Continue Editing"
      leftButtonEvent={onDrawerAlertIsClose}
      rightButtonEvent={initView}
    />
  );

  const alertTabCloseFooter = (
    <AlertFooter
      cancelRef={cancelTabRef}
      leftButtonText="Continue Editing"
      rightButtonText="Proceed Without Saving"
      leftButtonEvent={() => onTabAlertIsClose(true)}
      rightButtonEvent={initTabView}
    />
  );

  return (
    <>
      <BaselaneDrawer
        ref={leaseDetailsDrawerRef}
        finalFocusRef={leaseBtnRef}
        title="Rent Collection Details"
        onClose={handleClose}
        showBackButton={isMinXL}
        hideBackText
        size={isMax767 ? 'newdrawerfull' : 'newdrawermd'}
        contentStyles={isMax767 && { top: 'auto !important' }}
        newDesignDrawer
      >
        {lease ? (
          <Tabs
            orientation="vertical"
            variant="unstyled"
            {...viewModeTabsStyles}
            index={tabIndex}
            onChange={(index) => handleTabsChange(index)}
          >
            <TabPanels>
              {[LeaseResponsiveReview, LeaseTerms, ReceivingBankAccounts].map((Component, i) => {
                return (
                  // eslint-disable-next-line
                  <TabPanel key={i} {...viewModeTabPanelStyles}>
                    {i === 0 && (
                      <LeaseResponsivePropertyAddress lease={lease} isDesktop={isDesktop} />
                    )}
                    {isReceivingBAEdit && i === 2 && isAccountsEdit ? (
                      <Box pt="12px">
                        <FormTitle
                          title="Update receiving bank accounts"
                          textStyles={{
                            mt: 0,
                            p: '0 0 12px',
                            textStyle: 'headline-lg',
                            color: 'brand.neutral.700',
                          }}
                        />

                        <ReceivingBankAccountForm
                          {...{
                            isFromResponsiveMode: true,
                            setHasConnectedBank,
                            showReceivingBAEmptyState,
                            setShowReceivingBAEmptyState,
                            setFormVariables,
                            handleBackClick,
                            lease,
                            bankAccountsRef,
                            bankProps,
                            handleModifyReceivingBankAccounts,
                            isAccountsEdit,
                            setIsAccountsEdit,
                            hideExternalAccounts: isBaselaneAccountBannerClick,
                          }}
                        />
                      </Box>
                    ) : (
                      <Component
                        {...{
                          tenantProfileId,
                          lease,
                          setLease,
                          property,
                          id: i,
                          leaseCreated: true,
                          bankProps,
                          isReceivingBAEdit,
                          setIsReceivingBAEdit,
                          setTabIndex,
                          viewMode: false,
                          variablesAndStates,
                          helperFunctions,
                          invoiceListDrawerRef,
                          isAllowEdit: !lease?.archived,
                          refetchSingleLease,
                          hasBaselaneBank,
                          handleTabsChange,
                          handleArchiveLease,
                          isTablet,
                          isLeaseEdit,
                          setIsLeaseEdit,
                          setIsAccountsEdit,
                          tenantData,
                          handleUpdateRecievingAccClick,
                        }}
                      />
                    )}
                    {i === 0 && !lease.archived && (
                      <Box {...drawerFooterStyle}>
                        <ArchiveLease {...{ handleArchiveLease, isDesktop, isTablet }} />
                      </Box>
                    )}
                  </TabPanel>
                );
              })}
              {achFeeEnabledFeatureFlag && (
                <TabPanel>
                  <ProcessingFeeForm
                    externalAccountCollectionCount={externalAccountCollectionCount}
                    handleOpenRecievingBankAccount={() => {
                      setIsBaselaneAccountBannerClick(true);
                      setIsAccountsEdit(true);
                      setReturnToProcessingFee(true);
                      setTabIndex(2);
                    }}
                    invoiceProcessingFeePaidBy={lease.invoiceProcessingFeePaidBy}
                    invoiceProcessingFeePaidByOverridden={
                      lease.invoiceProcessingFeePaidByOverridden
                    }
                    isExternalBankAccount={lease.rentAndFeesBankAccount.isExternal}
                    isOpen={tabIndex === 3}
                    leaseId={lease.id}
                    onClose={() => setTabIndex(0)}
                    refetchSingleLease={refetchSingleLease}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        ) : (
          <BaselaneSpinner />
        )}
      </BaselaneDrawer>

      <BaselaneAlert
        isOpen={isDrawerAlertOpen}
        leastDestructiveRef={cancelDrawerRef}
        onClose={onDrawerAlertIsClose}
        isCentered
        header={<AlertHeader title="You have unsaved changes" />}
        body="Are you sure you want to exit without saving?"
        footer={alertDrawerCloseFooter}
      />

      <BaselaneAlert
        isOpen={isTabAlertOpen}
        leastDestructiveRef={cancelTabRef}
        onClose={onTabAlertIsClose}
        isCentered
        header={<AlertHeader title="You have unsaved changes" />}
        body="Are you sure you want to continue without saving?"
        footer={alertTabCloseFooter}
      />
    </>
  );
}

export default LeaseResponsiveDetail;
