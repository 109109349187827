import React, { useContext } from 'react';
import {
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveSortableTableHeading,
} from '@core/components/Shared/components';
import BaselaneResponsiveTableContext from '@shared/components/BaselaneResponsiveTable/BaselaneResponsiveTableContext';
import { tableHeaderItems } from '../helpers/helpers';

type TableHeaderProps = {
  sortOptions: Object,
  setSortOptions: Function,
};

export function TableHeader({ sortOptions, setSortOptions }: TableHeaderProps) {
  const { columns: headerItems } = useContext(BaselaneResponsiveTableContext);
  return (
    <BaselaneResponsiveTableHeader
      items={tableHeaderItems}
      renderItem={(item, index) => {
        return !headerItems?.[index]?.isSortable ? (
          <BaselaneResponsiveTableHeading key={item.key} index={index} title={item.label} />
        ) : (
          <BaselaneResponsiveSortableTableHeading
            key={item.key}
            index={index}
            title={item.label}
            field={item.field}
            filters={{
              sort: {
                field: sortOptions?.sort?.field,
                direction: sortOptions?.sort?.direction,
              },
            }}
            onSort={(sort) => {
              setSortOptions({ sort });
            }}
          />
        );
      }}
    />
  );
}
