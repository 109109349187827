import React from 'react';

type IconScheduledOutlineProps = {
  w: Number,
  h: Number,
  color: String,
};

function IconScheduledOutline({ w = 24, h = 24, color = '#257ED0' }: IconScheduledOutlineProps) {
  return (
    <svg
      width={`${w}px`}
      height={`${h}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 5.75C9.84518 5.75 10.125 6.02982 10.125 6.375V7H13.875V6.375C13.875 6.02982 14.1548 5.75 14.5 5.75C14.8452 5.75 15.125 6.02982 15.125 6.375V7H16.375C16.8723 7 17.3492 7.19754 17.7008 7.54917C18.0525 7.90081 18.25 8.37772 18.25 8.875V16.375C18.25 16.8723 18.0525 17.3492 17.7008 17.7008C17.3492 18.0525 16.8723 18.25 16.375 18.25H7.625C7.12772 18.25 6.65081 18.0525 6.29917 17.7008C5.94754 17.3492 5.75 16.8723 5.75 16.375V8.875C5.75 8.37772 5.94754 7.90081 6.29917 7.54917C6.65081 7.19754 7.12772 7 7.625 7H8.875V6.375C8.875 6.02982 9.15482 5.75 9.5 5.75ZM8.875 8.25H7.625C7.45924 8.25 7.30027 8.31585 7.18306 8.43306C7.06585 8.55027 7 8.70924 7 8.875V16.375C7 16.5408 7.06585 16.6997 7.18306 16.8169C7.30027 16.9342 7.45924 17 7.625 17H16.375C16.5408 17 16.6997 16.9342 16.8169 16.8169C16.9342 16.6997 17 16.5408 17 16.375V8.875C17 8.70924 16.9342 8.55027 16.8169 8.43306C16.6997 8.31585 16.5408 8.25 16.375 8.25H15.125V8.875C15.125 9.22018 14.8452 9.5 14.5 9.5C14.1548 9.5 13.875 9.22018 13.875 8.875V8.25H10.125V8.875C10.125 9.22018 9.84518 9.5 9.5 9.5C9.15482 9.5 8.875 9.22018 8.875 8.875V8.25ZM8.25 11.375C8.25 11.0298 8.52982 10.75 8.875 10.75H15.125C15.4702 10.75 15.75 11.0298 15.75 11.375C15.75 11.7202 15.4702 12 15.125 12H8.875C8.52982 12 8.25 11.7202 8.25 11.375Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
      />
    </svg>
  );
}

export default IconScheduledOutline;
