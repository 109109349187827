import React from 'react';
import { Stack, Tbody, Text } from '@chakra-ui/react';
import {
  BaselaneResponsiveTable,
  BaselaneResponsiveCell,
  BaselaneResponsiveCellText,
  BaselaneResponsiveTableRows,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableHeader,
  BaselaneResponsiveTableRow,
} from '@shared/components';
import formatCurrency from '@core/utils/formatCurrency';
import { formatDate } from '@core/utils/formatDate';
import getBreakPoints from '@core/utils/getBreakPoints';
import {
  getLeaseDuration,
  getLeaseRemainingTime,
  getTenantDetails,
} from '@pages/LeasesPage/helpers/unit.helpers';
import { RCTableHeaderItems, RCTableConfig } from '../helpers/RCTable.helpers';
import { tableStyles } from '../styles/tables.styles';

type RCTableProps = {
  payment: Object,
  tenantData: Object,
};

function RCTable({ payment, tenantData }: RCTableProps) {
  const { isMinXL } = getBreakPoints();

  const { cell, chevroncell, custominvoicecell } = tableStyles ?? {};

  return (
    <BaselaneResponsiveTable config={RCTableConfig}>
      <BaselaneResponsiveTableHeader
        items={RCTableHeaderItems}
        renderItem={(item, index) => (
          <BaselaneResponsiveTableHeading key={item.key} index={index} title={item.label} />
        )}
        chevronCellStyles={chevroncell}
      />

      <Tbody>
        <BaselaneResponsiveTableRows
          customRow
          items={payment?.leases}
          renderItem={(leaseData) => {
            const {
              id,
              propertyName,
              propertyUnitName,
              tenantProfileId,
              startDate,
              totalUnits,
              rentAmount,
            } = leaseData;

            const isMultiUnit = totalUnits > 1;
            const { firstName = '', lastName = '' } = tenantData
              ? getTenantDetails(tenantData?.landlordTenant, tenantProfileId, leaseData) || {}
              : {};
            const tenantFullName = `${firstName} ${lastName}`;
            const leaseDuration = leaseData && getLeaseDuration(leaseData);
            const leaseRemainingTime = getLeaseRemainingTime(leaseData);

            const propertyColumnText = propertyName;
            const propertyColumnSubtext = isMultiUnit ? propertyUnitName : null;
            const tenantColumnText = tenantFullName;
            const leaseDurationColumnText =
              (leaseRemainingTime ?? leaseRemainingTime) || 'Month-to-month';
            const leaseDurationColumnSubtext =
              leaseDuration === 'Month-to-month'
                ? `${formatDate(startDate)} - ongoing`
                : leaseDuration;
            const amountColumn = formatCurrency(rentAmount).inDollars;

            return (
              <BaselaneResponsiveTableRow id={id} chevronCellStyles={chevroncell}>
                {/* Property  */}
                <BaselaneResponsiveCell configIndex={0}>
                  <Stack {...cell.text.text}>
                    {isMinXL ? (
                      <>
                        <Text {...custominvoicecell.text}>{propertyColumnText}</Text>
                        <Text {...custominvoicecell.lighttext}>{propertyColumnSubtext}</Text>
                        <Text {...{ ...custominvoicecell.text, ...custominvoicecell.lighttext }}>
                          {leaseDurationColumnSubtext}
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text {...custominvoicecell.text}>{propertyColumnText}</Text>
                        <Text
                          {...{
                            ...custominvoicecell.text,
                            ...custominvoicecell.lighttext,
                          }}
                        >
                          {propertyColumnSubtext}
                        </Text>
                      </>
                    )}
                  </Stack>
                </BaselaneResponsiveCell>
                {/* Tenant  */}
                <BaselaneResponsiveCellText
                  text={tenantColumnText}
                  configIndex={1}
                  styles={cell.text}
                />
                {/* Lease Duration  */}
                <BaselaneResponsiveCellText
                  text={leaseDurationColumnText}
                  subtext={leaseDurationColumnSubtext}
                  configIndex={2}
                  styles={cell.text}
                />
                {/* Amount  */}
                <BaselaneResponsiveCellText
                  text={amountColumn}
                  configIndex={3}
                  styles={cell.text}
                />
              </BaselaneResponsiveTableRow>
            );
          }}
        />
      </Tbody>
    </BaselaneResponsiveTable>
  );
}

export default RCTable;
