export const BA_ERROR_ENUMS = {
  account_closed: 'This bank account has been closed. Please use another account.',
  declined:
    'Your bank has declined a transfer into this account. Please contact your bank or use another account.',
  invalid_account_number:
    'The account number is incorrect. Please ensure the account number is correct or use another account.',
  no_account:
    'No bank account could be located with the entered details. Please ensure the account number and routing number are correct or use another account.',
  error:
    'There is an issue depositing funds into this account. Please contact support or use another account.',
};
