import { UNIFIED_BOOKKEEPING_ANALYTICS, UNIFIED_BA_ACCOUNTS } from '@routes';

export type MenuState = {
  label: string,
  id: number,
  isValid: boolean,
  name: string,
};

export const initialMenuState = (hasAccounts) => [
  {
    label: 'Add Properties',
    id: 1,
    isValid: true,
    name: 'BA_ADD_PROPERTY',
    route: UNIFIED_BOOKKEEPING_ANALYTICS,
  },
  {
    label: 'Link Bank Accounts',
    id: 2,
    isValid: hasAccounts,
    name: 'BA_ADD_ACCOUNT',
    route: UNIFIED_BA_ACCOUNTS,
  },
];
