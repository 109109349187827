import process from 'process';
import React, { useEffect, useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { Text, Box, Stack, ChakraProvider } from '@chakra-ui/react';
import LogRocket from 'logrocket';

import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { useApolloClient, useMutation } from '@apollo/client';
import { publicIpv4 } from 'public-ip';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateLogin } from '@features/Authentication/helpers/validation.helpers';
import {
  firebaseEmailSignIn,
  generateSessionCookie,
} from '@features/Authentication/helpers/firebase.helpers';
import { AuthenticationError, errorMessages } from '@features/Authentication/helpers/error.helpers';
import {
  clearUrlUtmData,
  deleteUtmCookies,
  getUtmInputs,
  identifyUser,
  storeUrlUtmData,
  trackUtmUser,
} from '@features/Authentication/helpers/tracking.helpers';
import { BaselaneButton, BaselaneLink } from '@shared/components';
import * as ROUTES from '@routes';

import habitatTheme from '@core/themeHabitat';
import { LANDLORD_LOGIN } from '@core/apollo/queries';
import inActivityLogoutStorage from '@core/storage/inActivityLogoutStorage';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import { stackStyles } from '../styles/login.style';
import LoginFields from './fields/LoginFields';

import LoginOTP from './LoginOTP';

const LoginForm = () => {
  const initialFormValues = {
    email: '',
    password: '',
  };

  const formikRef = useRef();
  const client = useApolloClient();
  const location = useLocation();
  const navigate = useNavigate();

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const { state } = location || {};
  const { shouldShowMessenger } = state || {};

  const [isLoading, setIsLoading] = useState(null);

  const [userCredential, setUserCredential] = useState(null);
  const [userClaims, setUserClaims] = useState(null);
  const [userIpAddress, setUserIpAddress] = useState(null);

  const [landlordLogin, { loading: isLandlordLoginLoading }] = useMutation(LANDLORD_LOGIN);

  // FingerprintJS
  const { error: fingerprintError, getData: getDataFingerprint } = useVisitorData({
    extendedResult: true,
    tag: {
      env: process?.env?.REACT_APP_NODE_ENV,
    },
  });

  const [fingerprint, setFingerprint] = useState(null);

  /**
   * Handler that executes when login form is submitted.
   * @param {Object} values the submitted form values.
   */
  const handleFormSubmit = async (values) => {
    const { email, password } = values;

    // attempt to retrieve user's device fingerprint
    getDataFingerprint()
      .then((data) => {
        setFingerprint({ visitorId: data?.visitorId, requestId: data?.requestId, error: false });
        setUserIpAddress(data?.ip);
      })
      .catch((error) => {
        setFingerprint({ error });
        LogRocket.log(`getDataFingerprint error: ${JSON.stringify(error)}`);
      })
      .finally(() => {
        // attempt to log in with user's email + password
        firebaseEmailSignIn({
          email,
          password,
          handleSuccess: (credential, claims) => {
            credential.user
              .getIdToken()
              .then((token) => {
                generateSessionCookie({
                  token,
                  handleSuccess: (response) => {
                    identifyUser(credential.user);
                    setUserCredential(credential);
                    setUserClaims(claims);
                  },
                  handleError: (error) => {
                    // TODO: Login should not progress from here once SessionCookie generation works
                    // but there is a legitimate error, this should show a generic error message for now
                    navigate('something-went-wrong');
                  },
                });
              })
              .catch((error) => {
                LogRocket.log(
                  `landlordLogin (Custom Email) error: ${JSON.stringify(error)}, requestId:${
                    fingerprint?.requestId
                  }`
                );
              });
          },
          handleError: (error) => {
            LogRocket.log(
              `signInWithCustomEmail error: ${JSON.stringify(error)}, requestId:${
                fingerprint?.requestId
              }`
            );

            inActivityLogoutStorage.delete();
            localStorage.removeItem('redirectLogin');

            const { code } = error || {};
            const requestErrors = {};

            switch (code) {
              case AuthenticationError.invalidemail:
                requestErrors.email = errorMessages[code];
                break;
              case AuthenticationError.usernotfound:
              case AuthenticationError.wrongpassword:
              case AuthenticationError.toomanyrequests:
              case AuthenticationError.userdisabled:
              case AuthenticationError.tenant:
                requestErrors.password = errorMessages[code];
                break;
              default:
                // do nothing - unhandled error
                break;
            }

            // set login form errors
            formikRef?.current?.setErrors({ ...formikRef?.current?.errors, ...requestErrors });
          },
        });
      });
  };

  /**
   * Handler that executes when the OTP request succeeds.
   */
  const handleOTPSuccess = () => {
    setIsLoading(false);

    const utmInputs = getUtmInputs(userIpAddress, location, 'landlord_logged_in');

    landlordLogin({
      variables: {
        requestId: fingerprint?.requestId,
        utmInputs,
      },
    })
      .then((response) => {
        if (!response?.error && response?.data?.landlordLogin?.id) {
          // login success
          // tracking event
          trackUtmUser(userCredential.user, 'landlord_logged_in', userIpAddress, utmInputs);
          // delete utm data from storage, as it has been consumed
          clearUrlUtmData();
          // delete utm cookie data, as it has been consumed
          deleteUtmCookies();

          // Note: the following will reset the apollo client, and re-fetch the full user data,
          client.resetStore();
          navigate(ROUTES.HOME);
        } else if (response?.error) {
          LogRocket.log(
            `landlordLogin (Custom Email) error: ${JSON.stringify(response?.error)}, requestId:${
              fingerprint?.requestId
            }`
          );
        }
      })
      .catch((error) => {
        LogRocket.log(
          `landlordLogin (Custom Email) error: ${JSON.stringify(error)}, requestId:${
            fingerprint?.requestId
          }`
        );
      });
  };

  // Submit form when hitting 'enter'
  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      formikRef?.current?.handleSubmit();
    }
  };

  // Start with messenger open (user clicked on "contact" link)
  useEffect(() => {
    if (shouldShowMessenger) {
      zendeskAPI('messenger', 'open');

      // erase state so that messenger doesn't keep opening. This method
      // of clearing state will not trigger a re-render
      // https://stackoverflow.com/a/66359848
    }
  }, [shouldShowMessenger]);

  // save url utm data, in case its lost before logging in
  useEffect(() => {
    if (userIpAddress) {
      storeUrlUtmData(userIpAddress, location);
    }
  }, [userIpAddress]);

  // Log fingerprint error
  useEffect(() => {
    if (fingerprintError) {
      LogRocket.log(`fingerprint API error: ${JSON.stringify(fingerprintError)}`);
    }
  }, [fingerprintError]);

  // Fallback IPAddress (in case fingerprint fails)
  useEffect(() => {
    publicIpv4().then((ip) => {
      setUserIpAddress(ip);
    });
  }, []);

  return (
    <ChakraProvider theme={habitatTheme}>
      <Formik
        innerRef={formikRef}
        validate={validateLogin}
        validateOnChange
        initialValues={initialFormValues}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, handleSubmit, isValid }) => (
          <Form style={stackStyles}>
            <Stack w="100%" alignItems="flex-start" spacing={0.5} mb={1}>
              <LoginFields handleOnKeyDown={handleOnKeyDown} />

              <Box color="brand.blue.800A">
                <BaselaneLink variant="primary" size="sm" to={ROUTES.FORGOT_PASSWORD} styles={{}}>
                  <Text as="span" textStyle="sm">
                    Forgot Your Password?
                  </Text>
                </BaselaneLink>
              </Box>
            </Stack>

            <Stack w="100%" alignItems="center">
              <BaselaneButton
                id="signInButton" // add for consultants for growth team
                variant="filled"
                palette="primary"
                isFullWidth
                size="lg"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
                isDisabled={!isValid}
                isLoading={isLandlordLoginLoading || isLoading}
              >
                Sign In
              </BaselaneButton>
            </Stack>
          </Form>
        )}
      </Formik>
      <LoginOTP
        setIsLoading={setIsLoading}
        user={userCredential?.user}
        claims={userClaims}
        handleSuccess={handleOTPSuccess}
      />
    </ChakraProvider>
  );
};

export default LoginForm;
