export const clearedSearchStyles = {
  bg: 'none',
  fontSize: 'sm',
  color: 'brand.neutral.700',
  textAlign: 'center',
  m: '0 !important',
  p: '0',
  w: 'auto',
  maxWidth: '100px',
  _hover: {
    bg: 'none',
  },
  _focus: {
    bg: 'none',
  },
  _active: {
    bg: 'none',
  },
};
