import React from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { BaselaneButton } from '@shared/components';
import { LEASES, UNIFIED_RENT_COLLECTION } from '@core/constants/routes';
import { getActiveBonusObject } from '@core/components/NativeBankingPage/Summary/helpers/util.helpers';
import formatCurrency from '@core/utils/formatCurrency';
import { IllustrationRentcollection } from '@illustrations';

import {
  buttonRentCollectionCTA,
  buttonChangeAccountCTA,
  bannerContainerStyles,
  bannerTextContainerStyles,
  bannerHeadingStyles,
  bannerTextStyles,
} from '../../styles/setup-rent-collection-banner.styles';

type SetUpRentCollectionBannerProps = {
  isRentCollectionStarted: Boolean,
  hasCollectedRentWithinNeededPeriod: Boolean,
  activeConfig: Boolean,
};

const SetUpRentCollectionBanner = ({
  isRentCollectionStarted,
  hasCollectedRentWithinNeededPeriod,
  activeConfig,
}: SetUpRentCollectionBannerProps) => {
  const startedRentCollectionButNoDepositsYet =
    isRentCollectionStarted && !hasCollectedRentWithinNeededPeriod;

  const activeConfigBonus = getActiveBonusObject(activeConfig);

  return (
    <HStack {...bannerContainerStyles(hasCollectedRentWithinNeededPeriod)}>
      <IllustrationRentcollection />
      <VStack {...bannerTextContainerStyles}>
        <Text {...bannerHeadingStyles}>
          {!isRentCollectionStarted && `Unlock `}Bonus +
          {formatCurrency(activeConfigBonus?.value ?? 0, true).noDollar}% APY
        </Text>
        <Text {...bannerTextStyles}>Start collecting rent into Baselane Banking</Text>
      </VStack>
      {startedRentCollectionButNoDepositsYet ? (
        <BaselaneButton
          variant="transparent"
          palette="neutral"
          styles={buttonChangeAccountCTA}
          to={LEASES}
        >
          Change Deposit Account
        </BaselaneButton>
      ) : (
        <BaselaneButton
          variant="filled"
          palette="primary"
          styles={buttonRentCollectionCTA}
          to={UNIFIED_RENT_COLLECTION}
        >
          Set Up Rent Collection
        </BaselaneButton>
      )}
    </HStack>
  );
};

export default SetUpRentCollectionBanner;
