// @flow
import React from 'react';
import { VStack } from '@chakra-ui/react';
import { containerStyles, BaselaneMobileContentWrapperType } from './styles/widget.style';

type Props = {
  contentType?: BaselaneMobileContentWrapperType,
  children?: any,
  styles?: Object,
};
function BaselaneMobileContentWrapper({ contentType, children, styles }: Props): any {
  const combinedStyles = { ...containerStyles(contentType), ...styles };
  return <VStack {...combinedStyles}>{children}</VStack>;
}

BaselaneMobileContentWrapper.defaultProps = {
  contentType: 'widget',
  children: null,
  styles: {},
};

export default BaselaneMobileContentWrapper;
