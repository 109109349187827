export const tabsStyles = {
  position: 'relative',
  borderRadius: '4px',
  bg: 'brand.neutral.white',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 1px',
};

export const tabListStyles = (isMobile) => {
  return isMobile
    ? {
        h: '38px',
        px: '24px',
        m: '0 !important',
        boxShadow: 'inset 0px -1px 0px #F0F2F6',
      }
    : {
        boxShadow: 'inset 0px -1px 0px 0px #E1F4FF',
      };
};

export const tabStyles = (isMobile) => {
  return isMobile
    ? {
        h: '100%',
        p: '5px 8px 24px 8px',
        paddingBottom: '24px',
        color: 'brand.neutral.500',
        fontSize: 'sm',
        lineHeight: '24px',
        mr: '8px !important',
        borderRadius: '0',
        _selected: {
          color: 'brand.darkBlue.800A',
          fontWeight: 'semibold',
          borderBottom: 'solid 2px',
          borderBottomColor: 'brand.darkBlue.800A',
          mt: '2px',
        },
        _active: {
          bg: 'brand.neutral.white !important',
        },
        _focusVisible: {
          outlineWidth: '0',
        },
        _focus: {
          outlineWidth: '0',
        },
        _notLast: { mr: '32px' },
        _disabled: { opacity: '0.5', cursor: 'default' },
      }
    : {
        height: '42px',
        borderRadius: 0,
        paddingTop: '8px',
        paddingBottom: '20px',
        paddingInline: '8px',
        marginLeft: '16px',
        fontSize: 'sm',
        fontWeight: 'semibold',
        lineHeight: '24px',
        color: 'brand.neutral.500',
        _hover: { color: 'brand.neutral.600' },
        _selected: {
          color: 'brand.darkBlue.800A',
          boxShadow: 'inset 0px -2px 0px 0px #012550',
        },
        _focusVisible: {
          outlineWidth: '0',
        },
        _focus: {
          outlineWidth: '0',
        },
        _active: {
          bg: 'brand.neutral.white !important',
        },
      };
};

export const tabPanelsStyles = {
  p: '0 24px',
  overflowX: 'hidden',
  overflowY: 'auto',
  flex: '1 1 1px',
};

export const tabPanelStyles = {
  p: '0',
};
