import React from 'react';
import { Outlet } from 'react-router-dom';
import { OTPProvider } from '@contexts/OTPContext';

/**
 * Note: This wrapper allows the provider to be used as a route element.
 */

const TransactionWrapper = () => {
  return (
    <OTPProvider>
      <Outlet />
    </OTPProvider>
  );
};

export default TransactionWrapper;
