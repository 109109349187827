// Chakra Example: https://github.com/chakra-ui/chakra-ui/blob/v1/packages/theme/src/foundations/radius.ts
// Figma: https://www.figma.com/file/IzR58By56T8Dvo2tLLPCbP/*Baselane---Design-System-(Habitat)?node-id=552%3A14181&mode=dev
const radii = {
  none: '0',
  base: '0',
  sm: '6px',
  md: '8px',
  lg: '50%',
  xl: '0',
  '2xl': '0',
  '3xl': '0',
  full: '9999px',
};

export default radii;
