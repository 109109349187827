import React from 'react';
import moment from 'moment';
import { Stack, Spacer, Box, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { Icon16Delete } from '@icons/16px';
import {
  BaselaneButtonIcon,
  BaselaneAccordion,
  BaselaneAccordionWrapper,
  CurrencyText,
} from '@shared/components';

import {
  feeBoxHeaderStyles,
  feeBoxContainerStyles,
  feeBoxStyles,
  feeBoxTitleStyles,
  feeBoxValueStyles,
} from '../../styles/depositsAndFees.styles';

type DisplayFeeProps = {
  additionalFeesFields: Array,
  handleFeeDelete: Function,
  unifiedRC: boolean,
  dueDate: any,
};

function DisplayFee({
  additionalFeesFields,
  handleFeeDelete,
  unifiedRC,
  dueDate: invoiceDueDate,
}: DisplayFeeProps) {
  const containerStyles = {
    m: isMobile ? '0' : '16px 0',
    border: '1px solid #9FD8FF',
    borderRadius: '4px',
    bg: 'brand.blue.100',
  };

  const headerEl = (fee, index) => {
    const { id, name, amount, dueDate, description } = fee;
    return (
      <Stack direction="row" {...feeBoxHeaderStyles(unifiedRC)} w="100%">
        <Text {...feeBoxValueStyles} ml="0 !important">
          {description}
        </Text>
        <Spacer />
        <Box pt={isMobile ? '0' : '16px'}>
          <BaselaneButtonIcon
            variant="transparent"
            palette="neutral"
            icon={<Icon16Delete />}
            id={`delete_fee_button_${index}`}
            onClick={() => handleFeeDelete({ id, name, description, amount, dueDate })}
          />
        </Box>
      </Stack>
    );
  };
  const contentEl = (amount, dueDate) => (
    <Stack direction={!isMobile ? 'row' : 'column'} align={isMobile ? 'flex-start' : 'center'}>
      {/* Fee Amount */}
      <Stack direction="row" ml="0!important">
        <Text lineHeight="24px">
          <CurrencyText
            amount={amount}
            textStyle="headline-sm"
            styles={{ as: 'span', verticalAlign: 'baseline' }}
          />
          {!dueDate && !invoiceDueDate && (
            <Text
              as="span"
              {...{
                ...feeBoxTitleStyles,
                as: 'span',
                verticalAlign: 'baseline',
                ml: 1,
              }}
            >
              per month
            </Text>
          )}
        </Text>
      </Stack>
      {/* Fee Due Date */}
      {(dueDate || invoiceDueDate) && (
        <Stack direction="row" ml={isMobile ? '0' : ' 8px!important'}>
          <Text {...feeBoxTitleStyles}>due on</Text>
          <Text
            as="span"
            {...{
              ...feeBoxTitleStyles,
              as: 'span',
              verticalAlign: 'baseline',
            }}
          >
            {moment(dueDate || invoiceDueDate)
              .utc()
              .format('MMMM DD, YYYY')}
          </Text>
        </Stack>
      )}
    </Stack>
  );

  return (
    additionalFeesFields.length > 0 &&
    additionalFeesFields?.map(
      (fee, index) =>
        !fee.delete && (
          <Box
            key={fee.id || `${fee.description}-${fee.amount}-${index}`}
            {...feeBoxContainerStyles}
          >
            {unifiedRC && isMobile ? (
              <BaselaneAccordionWrapper allowToggle styles={{ my: '0' }}>
                <BaselaneAccordion
                  containerStyles={containerStyles}
                  contentStyles={{
                    p: '24px 0 24px 54px',
                  }}
                  buttonStyles={{ bg: 'brand.blue.100' }}
                  headerElements={headerEl(fee)}
                  contentElements={contentEl(
                    fee.amount,
                    fee.frequency === 'MONTHLY' ? null : fee.dueDate
                  )}
                />
              </BaselaneAccordionWrapper>
            ) : (
              <Box {...feeBoxStyles}>
                {headerEl(fee, index)}
                {contentEl(fee.amount, fee.frequency === 'MONTHLY' ? null : fee.dueDate)}
              </Box>
            )}
          </Box>
        )
    )
  );
}

export default DisplayFee;
