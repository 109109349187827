import React from 'react';
import { Text } from '@chakra-ui/react';

import { BaselaneAlert, BaselaneButton } from '@shared/components';

type InviteCodeAlertProps = {
  isOpen: boolean,
  onClose: Function,
};

const InviteCodeAlert = ({ isOpen, onClose }: InviteCodeAlertProps) => {
  return (
    <BaselaneAlert
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick
      isCentered
      header="Invalid Invite Code"
      body={
        <Text>
          The invite code entered is invalid. If you have been invited to a 1-year trial, please
          ensure the code matches the one provided to you by Baselane. Otherwise, you may sign up
          without an invite code for a 2-month trial.
        </Text>
      }
      footer={
        <BaselaneButton palette="primary" variand="filled" onClick={onClose}>
          Close
        </BaselaneButton>
      }
    />
  );
};

export default InviteCodeAlert;
