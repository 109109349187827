export const headerBarStyles = {
  width: '100%',
  backgroundColor: 'brand.neutral.white',
  padding: '0 16px 0 16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minH: '48px',
};

export const headerLogoBarStyles = {
  width: '100%',
  height: '48px',
  p: '8px 16px',
  backgroundColor: 'brand.neutral.white',
  mt: '11px',
};

export const headerBarShadowStyles = {
  boxShadow: '24px 0px 64px rgba(4, 39, 101, 0.08)',
};

export const headerStyles = {
  padding: '8px 0 8px 0',
  width: '100%',
  align: 'center',
};

export const headerTextStyles = {
  size: 'headline-lg',
  padding: '0 0 0 6px',
  color: 'brand.neutral.700',
  fontWeight: 'semibold',
};

export const headerBackTextStyles = {
  size: 'headline-xs',
  color: 'brand.blue.800A',
};

export const underlineStyles = {
  size: 'xs',
  paddingLeft: '6px',
  color: 'brand.neutral.600',
};

export const buttonStyles = { mt: '0px', alignSelf: 'flex-start' };

export const iconStyles = { w: '16px', h: '16px' };

export const backButtonContainerStyles = {
  alignSelf: 'flex-start',
  justify: 'center',
  height: '100%',
};

export const mobileLogoButtonStyles = {
  ...buttonStyles,
  h: '100%',
  zIndex: '1',
};

export const mobileLogoContainerStyles = {
  position: 'absolute',
  left: '0',
  right: '0',
  m: '0 !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'brand.darkBlue.800A',
};
