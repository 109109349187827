export const getInitialValues = (splitTransactions) => ({
  splitTransactions:
    splitTransactions.length === 0
      ? [
          { tagId: '', propertyId: '', unitId: '', amount: '' },
          { tagId: '', propertyId: '', unitId: '', amount: '' },
        ]
      : splitTransactions.map((splitTrx) => {
          let { amount } = splitTrx;
          if (typeof amount === 'number') {
            amount = amount.toString();
            const isNegative = amount.charAt(0) === '-';
            amount = isNegative ? `${amount.slice(0, 1)}$${amount.slice(1)}` : `$${amount}`;
          }

          return {
            id: splitTrx.id,
            tagId: splitTrx.tagId,
            propertyId: splitTrx.propertyId,
            unitId: splitTrx.unitId,
            date: splitTrx.date,
            amount,
          };
        }),
});

export const formatMaskedCurrency = (maskedCurrency) => {
  if (maskedCurrency === '') return 0;
  if (typeof maskedCurrency === 'number') return maskedCurrency;

  const isNegative = maskedCurrency.charAt(0) === '-';
  const formattedAmount = maskedCurrency.replace(/\$|,|-|_/g, '');
  const amount = isNegative && formattedAmount.length > 0 ? `-${formattedAmount}` : formattedAmount;

  return Number(amount);
};

export const getTotalSplitTransactionAmount = (splitTransactions) =>
  splitTransactions.reduce((remainingAmount, splitTrx) => {
    const total = Number(remainingAmount) + formatMaskedCurrency(splitTrx.amount);
    return parseFloat(total.toFixed(2));
  }, 0);

export const getRemainingAmount = (splitTransactions, totalAmount) => {
  const totalSplitTransactionAmount = getTotalSplitTransactionAmount(splitTransactions);
  return totalAmount - totalSplitTransactionAmount;
};

export const handleValidation = ({ values, setSplitTrxValid, totalAmount }) => {
  const errors = {};

  const areAmountsNotValid = values.splitTransactions.some((splitTrx) => {
    const { amount = '' } = splitTrx;
    if (amount === '') return true;

    return /(\$0\.)$|(-\$_)$|(-\$0)$|(-\$0\.)$|(-\$0\.0)$|(-\$0\.00)$|(\$0)$|(\$0\.0)$|(\$0\.00)$|(\$_)$|(\$)$/g.test(
      amount
    );
  });

  if (areAmountsNotValid) {
    errors.splitTransactions = 'Amounts cannot be $0 or empty';
  }

  const remainingToSplit = getRemainingAmount(values.splitTransactions, totalAmount);
  if (remainingToSplit !== 0) {
    errors.splitTransactions = 'Please split remaining amount.';
  }

  if (Object.keys(errors).length === 0) setSplitTrxValid(true);
  else setSplitTrxValid(false);

  return errors;
};
