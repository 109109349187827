import React from 'react';

type IconChevronDownProps = {
  w: Number,
  h: Number,
  color: String,
  isBold: Boolean,
};

function IconChevronDown({
  w = 11,
  h = 7,
  color = '#257ED0',
  isBold = false,
}: IconChevronDownProps) {
  return isBold ? (
    <svg width={w} height={h} viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.96967 0.469627C1.26256 0.176734 1.73744 0.176734 2.03033 0.469627L5.5 3.9393L8.96967 0.469631C9.26256 0.176738 9.73744 0.176738 10.0303 0.469631C10.3232 0.762525 10.3232 1.2374 10.0303 1.53029L6.07485 5.48577C5.75737 5.80325 5.24263 5.80325 4.92515 5.48577L0.96967 1.53029C0.676777 1.23739 0.676777 0.762521 0.96967 0.469627Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg width={w} height={h} viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.361766 0.528636C0.622116 0.268287 1.04423 0.268287 1.30458 0.528636L5.49984 4.7239L9.6951 0.528636C9.95545 0.268287 10.3776 0.268287 10.6379 0.528636C10.8983 0.788986 10.8983 1.2111 10.6379 1.47145L5.97124 6.13811C5.71089 6.39846 5.28878 6.39846 5.02843 6.13811L0.361766 1.47145C0.101417 1.2111 0.101417 0.788986 0.361766 0.528636Z"
        fill={color}
      />
    </svg>
  );
}

export default IconChevronDown;
