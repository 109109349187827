export const barChartContainerStyles = (isDashboard) => {
  return {
    pr: '24px',
    pb: isDashboard ? 0 : '24px',
    pt: isDashboard && '52px',
    h: isDashboard ? 'calc(100% - 150px)' : '310px',
    minH: isDashboard && '284px',
    m: '0 !important',
    overflowX: 'auto',
  };
};
