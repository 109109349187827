import React from 'react';
import ListOfOptions from './ListOfOptions';
import ListOfOptionsWithCheckboxes from './ListOfOptionsWithCheckboxes';
import DropdownSearchWrapper from '../../DropdownSearch/DropdownSearchWrapper';

type CustomCategoryDropdownProps = {
  isMulti: boolean,
  options: Array<any>,
  selectedStagedOptions: Array<any>,
  selectedOptions: Array<any>,
  itemRenderer: Function,
  showCustomCategories?: boolean,
  dropdownElementRef: any,
  handleListItemClick: Function,
  hasFilterWrapper: boolean,
  title: String,
  searchTerm: Array<String>,
  search: String,
  setSearch: Function,
  searchRef: any,
  animationVariant?: String,
  scrollTop?: Number,
  hideSearch?: boolean,
  showDivider: boolean,
};

const CustomCategoryDropdown = ({
  isMulti,
  options,
  selectedStagedOptions,
  selectedOptions,
  itemRenderer,
  showCustomCategories,
  dropdownElementRef,
  handleListItemClick,
  hasFilterWrapper,
  title,
  searchTerm,
  search,
  setSearch,
  searchRef,
  animationVariant,
  scrollTop,
  hideSearch,
  showDivider,
}: CustomCategoryDropdownProps) => {
  return (
    <DropdownSearchWrapper
      {...{
        showCustomCategories,
        title,
        search,
        setSearch,
        searchRef,
        animationVariant,
        scrollTop,
        hideSearch,
        dropdownElementRef,
        selectedOptions,
      }}
    >
      {isMulti ? (
        <ListOfOptionsWithCheckboxes
          ref={dropdownElementRef}
          {...{
            options,
            selectedStagedOptions,
            itemRenderer,
            handleListItemClick,
            hasFilterWrapper,
            searchTerm,
            search,
            showDivider,
          }}
        />
      ) : (
        <ListOfOptions
          ref={dropdownElementRef}
          {...{
            options,
            selectedStagedOptions,
            itemRenderer,
            handleListItemClick,
            hasFilterWrapper,
            searchTerm,
            search,
            showDivider,
          }}
        />
      )}
    </DropdownSearchWrapper>
  );
};

CustomCategoryDropdown.defaultProps = {
  showCustomCategories: null,
  animationVariant: null,
  scrollTop: 0,
  hideSearch: false,
};

export default CustomCategoryDropdown;
