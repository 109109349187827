import React from 'react';
import { groupBy, orderBy } from 'lodash';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Stack, Text, VStack } from '@chakra-ui/react';
import formatCurrency from '@core/utils/formatCurrency';
import { Highlighted } from '../BaselaneDropdown/components/helpers/itemRenderer.helpers';

const specialErrorCodes = [
  'AUTH_NOT_SUPPORTED',
  'NAME_VERIFICATION_FAILED',
  'IDENTITY_PERMISSION_NOT_GIVEN',
  'COUNTER_PARTY_CREATED',
];

const formatErrorCodes = ({ id, errorCode, isBankConnected }) => {
  const errorCodes = {
    isInstitutionAuthNotSupported: errorCode === 'AUTH_NOT_SUPPORTED',
    isNameCheckFailed: errorCode === 'NAME_VERIFICATION_FAILED',
    isOAuthNamePermissionMissing: errorCode === 'IDENTITY_PERMISSION_NOT_GIVEN',
    isAccountDisconnected: !isBankConnected,
    isGenericError:
      (errorCode && !specialErrorCodes.find((code) => code === errorCode)) ||
      (id === null && !errorCode),
  };

  return errorCodes;
};

export const getName = (nickName, accountNumber) =>
  accountNumber && accountNumber?.length > 0
    ? `${nickName} - ${accountNumber.slice(-4)}`
    : nickName;

const formatExternalAccountData = (account) => {
  const { nickName, accountNumber } = account;
  const accountItem = {
    name: getName(nickName, accountNumber),
    ...account,
  };

  return accountItem;
};

const getFormattedBaselaneAcc = (baselaneAcc) => {
  // Step 1: Group accounts by accountName
  const mainAccountGroups = groupBy(baselaneAcc, 'accountName');

  // Step 2: Format and sort each group
  const formattedBaselaneAccGroups = Object.entries(mainAccountGroups).map(([groupName, group]) => {
    // Format and sort the accounts within the current group
    const formattedAndSortedGroup = orderBy(
      group
        .map((account) => {
          const { nickName, accountName, accountNumber, subAccounts, ...rest } = account;
          // Main account item with formatted name
          const mainAccountItem = {
            ...rest,
            name: getName(nickName, accountNumber),
            title: accountName, // Adding title to each item
            errorCodes: formatErrorCodes({
              id: account.id,
              errorCode: account.counterPartyConnectionHistoryCode,
              isBankConnected: account.isBankConnected,
            }),
          };

          return [mainAccountItem];
        })
        .flat(), // Flatten the array of arrays
      [(acc) => acc.name?.toLowerCase()],
      ['asc']
    );

    // Return formatted group
    return {
      id: groupName,
      title: groupName,
      items: formattedAndSortedGroup,
    };
  });

  return formattedBaselaneAccGroups;
};

const getFormattedPlaidAcc = (plaidAcc) => {
  const institutionGroups = groupBy(plaidAcc, (acc) => acc.institutionName);
  let formattedPlaidAccounts = [];

  Object.keys(institutionGroups).forEach((group) => {
    const formattedPlaidAcc = {
      id: group,
      title: group,
      items: orderBy(
        institutionGroups[group]?.map((acc) => formatExternalAccountData(acc)),
        [(acc) => acc.name?.toLowerCase()],
        ['asc']
      ),
    };

    // Add errorCodes field to output.items
    formattedPlaidAcc.items = formattedPlaidAcc.items?.map((item) => {
      return {
        ...item,
        errorCodes: formatErrorCodes({
          id: item.id,
          errorCode: item.counterPartyConnectionHistoryCode,
          isBankConnected: item.isBankConnected,
        }),
      };
    });

    formattedPlaidAccounts.push(formattedPlaidAcc);
  });

  formattedPlaidAccounts = orderBy(
    formattedPlaidAccounts,
    [(acc) => acc.title?.toLowerCase()],
    ['asc']
  );

  return formattedPlaidAccounts;
};

const getFormattedManualAcc = (manualAcc) => {
  const formattedManualAcc = {
    id: 'manual_acc',
    title: 'Manually added accounts',
    items: orderBy(
      manualAcc?.map((acc) => formatExternalAccountData(acc)),
      [(acc) => acc.name?.toLowerCase()],
      ['asc']
    ),
  };

  // Add errorCodes field to output.items
  formattedManualAcc.items = formattedManualAcc.items?.map((item) => {
    return {
      ...item,
      errorCodes: formatErrorCodes({
        id: item.id,
        errorCode: item.counterPartyConnectionHistoryCode,
        isBankConnected: item.isBankConnected,
      }),
    };
  });

  return formattedManualAcc;
};

const getFlattenedAccounts = (accounts) => {
  const flattenedAccounts = [];
  accounts?.forEach((account) => {
    flattenedAccounts.push(...account.items);
  });
  return flattenedAccounts;
};

const getAccountsMap = (accounts) => {
  return Object.assign({}, ...accounts.map((account) => ({ [account.id]: account })));
};

const getPropertyId = (unsplitPropertyId) => {
  const [propId, unitId] = unsplitPropertyId.split('-');
  return { propId, unitId };
};

const getTagId = (unsplitTagId) => {
  const [parentId, subId] = unsplitTagId.split('-');
  return { parentId, subId };
};

export const getDropdownList = (accounts) => {
  const { UNIT, PLAID, MANUAL } = groupBy(accounts, (acc) => acc.provider);

  const formattedBaselaneAcc = getFormattedBaselaneAcc(UNIT);
  const formattedPlaidAcc = getFormattedPlaidAcc(PLAID);
  const formattedManualAcc = getFormattedManualAcc(MANUAL);

  const fromDropdownList = [...formattedBaselaneAcc, ...formattedPlaidAcc];
  const toDropdownList = [...formattedBaselaneAcc, ...formattedPlaidAcc, formattedManualAcc];
  const flattenedAccounts = getFlattenedAccounts(toDropdownList); // pass all accounts
  const accountsMap = getAccountsMap(flattenedAccounts);

  return { formattedBaselaneAcc, fromDropdownList, formattedPlaidAcc, toDropdownList, accountsMap };
};

export const renderAccountDropdownItem = ({ item, search }) => {
  return (
    <Stack gap={0} direction="row" justifyContent="space-between" width="100%">
      <Text color="inherit">
        <Highlighted text={item?.name ?? item?.nickName} highlight={search} />
      </Text>
    </Stack>
  );
};

export const renderAccountDropdownItemWithBalance = ({ item, search }) => {
  return (
    <Stack gap={0} direction="row" justifyContent="space-between" width="100%">
      <Text color="inherit">
        <Highlighted text={item?.name ?? item?.nickName} highlight={search} />
      </Text>
      <Text color="inherit">{formatCurrency(item?.availableBalance ?? 0).inDollars}</Text>
    </Stack>
  );
};

export const renderAccountDropdownItemWithBalanceAndAccountType = ({ item, search }) => {
  return (
    <Stack gap={0} direction="row" justifyContent="space-between" width="100%">
      <Text color="inherit">
        <VStack alignItems="flex-start" gap={0} as="span">
          <Highlighted textStyle="sm" text={item?.name ?? item?.nickName} highlight={search} />
          {item?.accountSubType === 'savings' && item?.institutionName === 'Baselane' && (
            <Text as="span" fontSize="12px" color="brand.neutral.600" m={0}>
              Savings account
            </Text>
          )}
        </VStack>
      </Text>
      {item?.provider !== 'MANUAL' && (
        <Text color="inherit">{formatCurrency(item?.availableBalance ?? 0).inDollars}</Text>
      )}
    </Stack>
  );
};

export const getSplitPropertyUnitId = (unsplitPropertyId) => {
  const propertyIdObj =
    Array.isArray(unsplitPropertyId) || !unsplitPropertyId
      ? null
      : getPropertyId(unsplitPropertyId);
  const propertyId = propertyIdObj?.propId ?? null;
  const unitId = propertyIdObj?.unitId ?? null;

  return { propertyId, unitId };
};

export const getSplitTagId = (unsplitTagId) => {
  const tagIdObj = Array.isArray(unsplitTagId) || !unsplitTagId ? null : getTagId(unsplitTagId);
  const parentTagId = tagIdObj?.parentId ?? null;
  const subTagId = tagIdObj?.subId ?? null;

  return { parentTagId, subTagId };
};

export const getTransferType = (isFromAccExternal, isToAccExternal) => {
  let transferType = '';

  if (!isFromAccExternal && !isToAccExternal) {
    transferType = 'INTERNAL';
  } else if (!isFromAccExternal && isToAccExternal) {
    transferType = 'TRANSFER_OUT';
  } else if (isFromAccExternal && !isToAccExternal) {
    transferType = 'TRANSFER_IN';
  }

  return transferType;
};

export const getBankId = ({ values, transferType, accountsMap }) => {
  const { fromTransferAccountId, toTransferAccountId } = values;
  const selectedFromAccObj =
    fromTransferAccountId && accountsMap && accountsMap[fromTransferAccountId];
  const selectedToAccObj = toTransferAccountId && accountsMap && accountsMap[toTransferAccountId];

  if (transferType === 'TRANSFER_IN') {
    return selectedToAccObj?.userInstitutionId;
  }

  return selectedFromAccObj?.userInstitutionId;
};

export const getPhoneNumber = ({ banks, bankId }) => {
  const phoneNumber = banks?.find((b) => b.id === bankId)?.unitAccount?.phoneNumber || '';
  return phoneNumber;
};

export const getUpdateLinkTokenInput = ({ type, fromTransferAccountId, toTransferAccountId }) => {
  let bankAccountIdVar = {};

  switch (type) {
    case 'TRANSFER_IN':
      bankAccountIdVar = {
        variables: { bankAccountId: fromTransferAccountId },
        skip:
          !fromTransferAccountId ||
          fromTransferAccountId === undefined ||
          fromTransferAccountId === null,
      };
      break;

    case 'TRANSFER_OUT':
      bankAccountIdVar = {
        variables: { bankAccountId: toTransferAccountId },
        skip:
          !toTransferAccountId || toTransferAccountId === undefined || toTransferAccountId === null,
      };
      break;

    default:
      bankAccountIdVar = {
        skip: true,
      };
      break;
  }

  return bankAccountIdVar;
};

const triggerGetUpdateLinkToken = ({
  type,
  fromTransferAccountId,
  toTransferAccountId,
  getUpdateLinkToken,
}) => {
  const queryInput = getUpdateLinkTokenInput({
    type,
    fromTransferAccountId,
    toTransferAccountId,
  });
  getUpdateLinkToken(queryInput);
};

const checkTransferType = ({
  from,
  to,
  values,
  accountsMap,
  setTransferType,
  getUpdateLinkToken,
}) => {
  const selectedFromAccObj = accountsMap[from ?? values.fromTransferAccountId];
  const selectedToAccObj = accountsMap[to ?? values.toTransferAccountId];

  const isFromExternal = selectedFromAccObj?.isExternal;
  const isToExternal = selectedToAccObj?.isExternal;

  const isFromAccountDisconnected = selectedFromAccObj?.errorCodes?.isAccountDisconnected;
  const isToAccountDisconnected = selectedToAccObj?.errorCodes?.isAccountDisconnected;

  // set transfer type
  const type = getTransferType(isFromExternal, isToExternal);
  setTransferType(type);

  // if account is disconnected, get update token for resync
  if ((isFromExternal && isFromAccountDisconnected) || (isToExternal && isToAccountDisconnected)) {
    const fromTransferAccountId = selectedFromAccObj?.id;
    const toTransferAccountId = selectedToAccObj?.id;
    triggerGetUpdateLinkToken({
      type,
      fromTransferAccountId,
      toTransferAccountId,
      getUpdateLinkToken,
    });
  }
};

export const handleFromAccountHandleSubmit = ({
  values,
  selectedValue,
  setFieldTouched,
  setFieldValue,
  accountsMap,
  setTransferType,
  getUpdateLinkToken,
}) => {
  checkTransferType({
    from: selectedValue,
    values,
    accountsMap,
    setTransferType,
    getUpdateLinkToken,
  });
  setFieldTouched('fromTransferAccountId');
  setFieldValue('fromTransferAccountId', Array.isArray(selectedValue) ? '' : selectedValue);
};

export const handleToAccountHandleSubmit = ({
  values,
  selectedValue,
  setFieldTouched,
  setFieldValue,
  accountsMap,
  setTransferType,
  getUpdateLinkToken,
}) => {
  checkTransferType({
    to: selectedValue,
    values,
    accountsMap,
    setTransferType,
    getUpdateLinkToken,
  });
  setFieldTouched('toTransferAccountId');
  setFieldValue('toTransferAccountId', Array.isArray(selectedValue) ? '' : selectedValue);
};

const inputMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
  allowDecimal: true,
};

export const currencyMask = createNumberMask(inputMaskOptions);

export const initialValues = {
  amount: '',
  fromTransferAccountId: '',
  toTransferAccountId: '',
  tagId: null,
  propertyId: null,
  unitId: null,
  note: '',
};

export const onlyCheckingAccounts = (item) => {
  return item?.accountSubType === 'checking';
};

export const onlyBaselaneAccounts = (item) => {
  return item?.institutionName === 'Baselane';
};

export const isInAccountList = (item, list) => {
  let wasFound = false;
  list?.forEach((listItem) => {
    if (listItem?.items.some((value) => value?.id === item?.id)) {
      wasFound = true;
    }
  });
  return wasFound;
};

export const stripDollars = (value) => {
  let result = value;
  if (typeof value !== 'string') {
    result = String(value);
  }
  return result.split('$').join('');
};
