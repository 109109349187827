// @flow
import React from 'react';
import { Stack, Box, Spacer, Text } from '@chakra-ui/react';
import CurrencyText from '@shared/components/CurrencyText';
import { truncatedText } from '@shared/styles/text.style';
import ConfigurableProgress from './ConfigurableProgress';

function KPIsSummaryContent({
  name,
  value,
  amount,
  color,
  left,
  hasBorder,
  borderMargin,
  hasPercentages,
}: {
  name: string,
  value: number,
  amount: number,
  color?: string,
  left?: number,
  hasBorder?: boolean,
  borderMargin?: string,
  hasPercentages?: boolean,
}): any {
  return (
    <Stack
      direction="row"
      align="center"
      borderBottom="1px"
      borderColor="brand.darkBlue.150"
      _last={{ borderColor: 'transparent' }}
      py="5px"
      position="relative"
    >
      <Text pl="20px" fontSize="sm" w="165px" lineHeight="45px" {...truncatedText}>
        {name}
      </Text>
      <ConfigurableProgress
        value={value}
        color={color}
        left={left}
        name={name}
        hasBorder={hasBorder}
        margin={borderMargin}
      />
      <Spacer />
      <Box w="150px" maxW="150px" minW="150px" lineHeight="45px" textAlign="right" pr="20px">
        <CurrencyText isRounded textStyle="md" amount={amount} isPercentage={hasPercentages} />
      </Box>
    </Stack>
  );
}

KPIsSummaryContent.defaultProps = {
  color: 'brand.blue.700',
  left: 0,
  borderMargin: '',
  hasPercentages: false,
  hasBorder: false,
};

export default KPIsSummaryContent;
