import { gql } from '@apollo/client';

export const GET_BANK_SUMMARY = gql`
  query BankSummary($isExternal: Boolean) {
    bankSummary(input: { isExternal: $isExternal }) {
      bank {
        id
        isExternal
        name
        unitAccount {
          id
          unitCustomerId
          applicationType
        }
      }
    }
  }
`;
