import customTheme from '@core/theme';

export const existingDraftContentStyles = (isMobile) => ({
  p: isMobile ? '16px 0 0 0' : '16px',
});

export const existingDraftBodyStyles = (isMobile) => ({
  p: isMobile ? '24px 8px 0' : '32px 16px 16px',
  w: '100%',
  m: '0',
});

export const existingDraftContainerStyles = (isMobile) => ({
  p: isMobile ? '0 8px 24px 8px' : '0px',
  spacing: isMobile ? '16px' : '32px',
  h: '100%',
  display: 'flex',
  justifyContent: 'center',
});

export const existingDraftCardStyles = (isMobile) => ({
  width: 'auto',
  spacing: isMobile ? '8px' : '20px',
  color: customTheme.colors.brand.neutral['600'],
  h: isMobile ? '120px' : '72px',
  minHeight: isMobile ? '92px' : '72px',
  borderColor: customTheme.colors.brand.darkBlue['150'],
  textAlign: 'left',
  m: '0',
  p: isMobile ? '8px 0 8px 8px' : '16px',
});

export const existingDraftTitleStyles = (isMobile) => ({
  fontSize: isMobile ? 'lg' : '2xl',
  fontWeight: 'semibold',
  lineHeight: isMobile ? '26px' : '32px',
  color: customTheme.colors.brand.neutral['700'],
  textAlign: 'left',
});

export const existingDraftCardTitleStyles = {
  textStyle: 'headline-xs',
  color: customTheme.colors.brand.neutral['700'],
  textTransform: 'uppercase',
};

export const existingDraftCardBodyStyles = {
  textStyle: 'xs',
  color: customTheme.colors.brand.neutral['600'],
};

export const leftHeaderContentStyles = {
  textStyle: 'headline-lg',
  color: customTheme.colors.brand.neutral['700'],
};
