// @flow
import React from 'react';
import { Tbody } from '@chakra-ui/react';
import {
  BaselaneResponsiveTable,
  BaselaneResponsiveTableHeading,
  BaselaneResponsiveTableHeader,
} from '@shared/components';
import Accounts from './components/Accounts';
import EmptyState from './components/EmptyState';
import { tablesStyles } from '../../../../styles/tables.styles';

type ExternalAccountsProps = {
  banks: Array<Object>,
  refetchBankSummary: Function,
  addExternalAccountAction?: Function,
  isMinXL: String,
};

function ExternalAccounts({
  banks,
  refetchBankSummary,
  addExternalAccountAction,
  isMinXL,
}: ExternalAccountsProps): any {
  const hasNoBanks = banks?.length === 0;

  const tableConfig = {
    columns: [
      {
        styles: {
          display: 'table-cell',
          overflow: { base: 'visible', lg: 'hidden' },
        },
      },
      {
        styles: {
          display: { lg: 'table-cell', base: 'none' },
          width: { '2lg': '200px', lg: '144px' },
          justifyContent: isMinXL ? 'flex-end' : 'flex-start',
        },
      },
      {
        styles: {
          display: { lg: 'table-cell', base: 'none' },
          width: { '2lg': '48px', base: '16px' },
          p: '0',
        },
      },
      {
        styles: {
          display: { lg: 'table-cell', base: 'none' },
          width: { '2lg': '240px', base: '200px' },
          p: '12px',
        },
      },
      {
        styles: {
          display: { lg: 'table-cell', base: 'none' },
          width: { '2lg': '200px', base: '104px' },
        },
      },
      {
        styles: {
          width: '48px',
        },
      },
    ],
  };

  const tableHeaderItems = [
    { key: 'account', label: 'Account' },
    {
      key: 'balance',
      label: 'Balance',
    },
    {
      key: 'emptyheader',
      label: '',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'action',
      label: '',
    },
  ];

  const { container, chevron } = tablesStyles ?? {};

  return hasNoBanks ? (
    <EmptyState {...{ banks, refetchBankSummary, addExternalAccountAction }} />
  ) : (
    <BaselaneResponsiveTable config={tableConfig} tableContainerStyles={container}>
      {!isMinXL && (
        <BaselaneResponsiveTableHeader
          items={tableHeaderItems}
          renderItem={(item, index) => (
            <BaselaneResponsiveTableHeading key={item.key} index={index} title={item.label} />
          )}
          chevronCellStyles={chevron}
        />
      )}

      <Tbody>
        <Accounts
          {...{
            banks,
            refetchBankSummary,
            isMinXL,
          }}
        />
      </Tbody>
    </BaselaneResponsiveTable>
  );
}

ExternalAccounts.defaultProps = {
  addExternalAccountAction: null,
};

export default ExternalAccounts;
