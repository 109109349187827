import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PROPERTIES_DROPDOWN } from '@core/apollo/queries';
import { getPropertyData } from '@shared/helpers/propertiesFilter.helpers';

const buildPropertiesById = (properties, { buildForDropdown = false } = {}) => {
  const propertiesById = {};
  for (const property of properties) {
    propertiesById[property.id] = property;
    for (const unit of property.units) {
      const unitId = buildForDropdown ? `${property.id}-${unit.id}` : unit.id;
      propertiesById[unitId] = unit;
    }
  }
  return propertiesById;
};

// TODO: Move it to features/accounts/hooks/useProperties.js when it will be created.

/**
 * @typedef UsePropertiesResult
 * @type {Object}
 * @property {Error} error - The error, if any.
 * @property {boolean} loading - Indication that the query is loading.
 * @property {Array} properties - The list of properties as returned by the query.
 * @property {Array} propertiesForDropdown - The properties (after transformation) for BaselaneDropdown component.
 * @property {Object} propertiesById - The properties by id.
 * @property {Object} propertiesByIdForDropdownById - The properties (after transformation) for BaselaneDropdown component by id. It is useful for finding the selected property (`propertiesByIdForDropdownById[selectedvalue]`).
 */

/**
 * This hook fetches the properties and their units. It returns the properties as is and by id.
 * @returns {UsePropertiesResult} The result object as described by {@link UsePropertiesResult}.
 */
const useProperties = () => {
  const { loading, error, data } = useQuery(GET_PROPERTIES_DROPDOWN);

  const properties = data?.property ?? [];

  const propertiesForDropdown = useMemo(() => getPropertyData(properties), [properties]);

  const propertiesById = useMemo(() => buildPropertiesById(properties), [properties]);

  const propertiesByIdForDropdownById = useMemo(
    () => buildPropertiesById(properties, { buildForDropdown: true }),
    [properties]
  );

  return {
    loading,
    error,
    properties,
    propertiesForDropdown,
    propertiesById,
    propertiesByIdForDropdownById,
  };
};

export default useProperties;
