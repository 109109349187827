// @flow
import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Text } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import {
  BaselaneButton,
  BaselaneCard,
  BaselaneTitle,
  BaselaneOverview,
  BaselaneDivider,
  CurrencyText,
  EmptyText,
} from '@shared/components';
import isNumber from '@core/utils/validators/isNumber';
import { cardSkeletonStyles } from '@shared/components/BaselaneCard/styles/card.style';
import * as ROUTES from '@routes';
import { GET_PROPERTIES_LEASE } from '@core/apollo/queries';
import PropertyList from './PropertyList';
import { GET_LEASE_SUMMARY } from './queries';
import RentStatus from './RentStatus';
import RentCollectionSummary from './RentCollectionSummary';
import {
  customBorderStyles,
  customContentStyles,
  customFontStyles,
  firstItemStyles,
  propertiesWidget,
} from './styles/responsive.style';

function Properties(): any {
  const { loading, error, data } = useQuery(GET_PROPERTIES_LEASE);
  const {
    loading: leaseSummaryloading,
    error: leaseSummaryError,
    data: leaseSummaryData,
  } = useQuery(GET_LEASE_SUMMARY);

  const {
    activeLease = 0,
    rentCollected,
    rentPending,
    rentOverdue,
    monthlyRentAndFee,
    isOverdue,
    overdueLease,
  } = leaseSummaryData?.leaseDetail?.summary || {};
  if (loading || error || leaseSummaryloading || leaseSummaryError) return null;

  const properties = data?.property || [];

  const formatActiveLease = (
    <Text textStyle={isMobile ? 'headline-md' : 'headline-xl'}>{activeLease}</Text>
  );
  const LeaseStatus = (
    <RentStatus isOverdue={isOverdue} activeLease={activeLease} overdueLease={overdueLease} />
  );
  const formatMonthRentAndFee = isNumber(monthlyRentAndFee) ? (
    <CurrencyText isRounded amount={monthlyRentAndFee} {...customContentStyles(isMobile)} />
  ) : (
    <EmptyText />
  );
  const noData = !properties.length;

  const propertiesOverview = !isMobile
    ? [
        { title: 'Monthly Rent & Fees', content: formatMonthRentAndFee },
        { title: 'Active Rent Collection', content: formatActiveLease },
        { title: 'Rent Collection Status', content: LeaseStatus },
      ]
    : [
        { title: 'Monthly Rent & Fees', content: formatMonthRentAndFee },
        { title: 'Rent Collection Status', content: LeaseStatus },
      ];

  const overallRentSum = rentCollected + rentPending + rentOverdue;

  return (
    <Box {...propertiesWidget(isMobile)}>
      {noData && (
        <BaselaneCard styles={{ ...cardSkeletonStyles.container }}>
          <Text {...cardSkeletonStyles.title}>Add Your Properties & Start Collecting Rent</Text>
          <Text {...cardSkeletonStyles.description}>
            Add a property and start collecting rent. We use the leading payment technology —Stripe™
            and Plaid™—to make sure you receive payments with peace of mind directly into your bank
            account within 2-5 business days.
          </Text>

          <BaselaneButton
            variant="filled"
            palette="primary"
            onClick={() => {
              window.location.href = ROUTES.PROPERTIES;
            }}
            size="sm"
          >
            Add Property
          </BaselaneButton>
        </BaselaneCard>
      )}
      <Box display={!isMobile ? 'block' : 'none'}>
        <BaselaneTitle title="Overview" />
      </Box>
      <BaselaneOverview
        elements={propertiesOverview}
        hasNoData={noData}
        firstItemStyles={firstItemStyles(isMobile)}
        customFontStyles={customFontStyles}
        customBorderStyles={customBorderStyles(isMobile)}
        styles={{ pr: '16px', justifyContent: 'flex-start' }}
      />
      <RentCollectionSummary
        rentCollected={rentCollected}
        rentPending={rentPending}
        rentOverdue={rentOverdue}
        activeLease={noData ? '' : activeLease}
        hasNoData={noData || activeLease === 0 || overallRentSum === 0}
      />
      <Box display={!isMobile ? 'block' : 'none'}>
        <BaselaneDivider styles={{ m: '36px 0 16px 0' }} />
        <Box my="16px" mx="24px">
          <PropertyList properties={properties} hasNoData={noData} />
        </Box>
      </Box>
    </Box>
  );
}

export default Properties;
