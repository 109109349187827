/* eslint-disable react/prop-types */
import { React } from 'react';
import {
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { BaselaneCardNew, BaselaneButton } from '@shared/components';
import SlLoader from '@core/components/Loader';
import checkIsTransfer from '@core/utils/checkIsTransfer.helper';
import useScheduledOneTimePaymentDetails from '../scheduled-payments/useScheduledOneTimePaymentDetails.hook';
import ScheduledPaymentsHeader from '../scheduled-payments/ScheduledPaymentsHeader';
import ScheduledPaymentsBookeeping from '../scheduled-payments/ScheduledPaymentsBookeeping';
import ScheduledPaymentsPaymentMethodDetails from '../scheduled-payments/ScheduledPaymentsPaymentMethodDetails';
import ScheduledPaymentsPaymentPayeeDetails from '../scheduled-payments/ScheduledPaymentsPaymentPayeeDetails';
import CancelPaymentModal from '../CancelPaymentModal';
import CancelTransferModal from '../CancelTransferModal';

const OneTimePaymentDetails = ({ from, transferId }) => {
  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();

  const handleCancelPayment = () => {
    onDeleteAlertOpen();
  };

  const { data, loading } = useScheduledOneTimePaymentDetails(transferId);

  if (loading) {
    return (
      <Box>
        <SlLoader styles={{ position: 'absolute !important' }} />
      </Box>
    );
  }

  const isTransfer = checkIsTransfer(data);

  return (
    <>
      <BaselaneCardNew palette="dark">
        <Stack w="100%" gap={1}>
          <ScheduledPaymentsHeader isOneTimePayment details={data} />

          <ScheduledPaymentsPaymentPayeeDetails
            isOneTimePayment
            startDate={data?.startDate}
            details={data}
            noP={data?.recurrencePattern?.numberOfPayments}
          />

          <Accordion allowToggle mt={1.5}>
            <AccordionItem border={0}>
              <AccordionButton p="0 0 8px 0" _hover={{ bg: 'transparent' }}>
                <Text textStyle="sm-heavy" textAlign="left">
                  Show details
                </Text>
                <AccordionIcon ml={1} height="16px" width="16px" />
              </AccordionButton>

              <AccordionPanel p="0" display="flex" flexDirection="column" gap={1}>
                {!isTransfer && <ScheduledPaymentsPaymentMethodDetails details={data} />}
                <ScheduledPaymentsBookeeping details={data.metadata} />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Stack>
      </BaselaneCardNew>
      <Box w="100%" my="2">
        <BaselaneButton
          variant="outline"
          palette="danger"
          isFullWidth
          size="md"
          onClick={handleCancelPayment}
        >
          {isTransfer ? 'Cancel transfer' : 'Cancel payment'}
        </BaselaneButton>
      </Box>
      {isDeleteAlertOpen && isTransfer ? (
        <CancelTransferModal
          handleClose={onDeleteAlertClose}
          navigateFrom={from}
          transferId={transferId}
        />
      ) : null}
      {isDeleteAlertOpen && !isTransfer ? (
        <CancelPaymentModal
          handleClose={onDeleteAlertClose}
          navigateFrom={from}
          transferId={transferId}
        />
      ) : null}
    </>
  );
};

export default OneTimePaymentDetails;
